
export const initialViewState = {
  longitude: 105.8342,
  latitude: 21.0278,
  zoom: 16,
  bearing: 0,
  pitch:60
}
export const MAPBOX_TOKEN = 'pk.eyJ1IjoidHJhbnR1YW5hbiIsImEiOiJjbGpwajY2bWswZ3luM2pveDN6aGZ3NDFrIn0.wywRyoHzwqqRw2FU0HfLIg'; // Set your mapbox token here
export const buildingLayer = {
  'id': 'add-3d-buildings',
  'source': 'composite',
  'source-layer': 'building',
  'filter': ['==', 'extrude', 'true'],
  'type': 'fill-extrusion',
  'minzoom': 15,
  'paint': {
    'fill-extrusion-color': '#aaa',

    // Use an 'interpolate' expression to
    // add a smooth transition effect to
    // the buildings as the user zooms in.
    'fill-extrusion-height': [
      'interpolate',
      ['linear'],
      ['zoom'],
      15,
      0,
      15.05,
      ['get', 'height']
    ],
    'fill-extrusion-base': [
      'interpolate',
      ['linear'],
      ['zoom'],
      15,
      0,
      15.05,
      ['get', 'min_height']
    ],
    // 'fill-extrusion-opacity': 0.6
  }
}

export const layerElectricRouteStyle = {
  id: 'ellectricP',
  type: 'line',
  paint: {
    'line-color': [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      "yellow",
      "#61C0BF"
    ],
    'line-width': {
      'base': 1.75,
      'stops': [
        [0, 1],
        [23, 180]
      ]
    },
    'line-emissive-strength': 2,

  },
  source: 'ellectricP'
};

export const layerServiceWaterLineStyle = {
  id: 'serviceWaterLine',
  type: 'line',
  slot: 'bottom',

  paint: {
    'line-color': [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      "yellow",
      "#09BF30"
    ],
    'line-width': {
      'base': 1.75,
      'stops': [
        [0, 1],
        [22, 180]
      ]
    },
    'line-emissive-strength': 1,

  },
  source: 'serviceWaterLine'
};
export const layerDistributionWaterLineStyle = {
  id: 'distributionWaterLine',
  type: 'line',
  slot: 'bottom',

  paint: {
    'line-color': [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      "yellow",
      "#C8A1E1"
    ],
    'line-width': {
      'base': 1.75,
      'stops': [
        [0, 1],
        [22, 180]
      ]
    },
    'line-emissive-strength': 1,

  },
  source: 'distributionWaterLine'
};

export const layerTrafficStatus = {
  id: 'trafficStatus',
  type: 'line',
  paint: {
    'line-color': [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      "yellow",
      ['get', 'color']
    ],
    'line-width': {
      'base': 1.75,
      'stops': [
        [0, 1],
        [22, 180]
      ]
    },
    'line-emissive-strength': 1,

  },
  source: 'ellectricP'
};

export const layerLightStyle = {
  id: 'lightP',
  type: 'circle',
  
  paint: {
    'circle-color': '#F5F5F5',
    'circle-stroke-color': 'yellow',
    'circle-stroke-width': [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      5,
      0
    ],
    'circle-opacity': 0.9,
    'circle-radius': {
      'base': 1.75,
      'stops': [
        [0, 1],
        [22, 180]
      ]
    },
    'circle-emissive-strength': 1
  },
  source: 'lightP',
};

export const layerLightStationStyle = {
  id: 'lightStationP',
  type: 'circle',
  paint: {
    'circle-color': '#DA0C81',
    'circle-stroke-color': 'yellow',
    'circle-stroke-width': [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      5,
      0
    ],
    'circle-radius': {
      'base': 1.75,
      'stops': [
        [0, 1],
        [22, 230]
      ]
    },
    'circle-opacity': 0.9,
    'circle-emissive-strength': 1


  },
  source: 'lightStationP',
};

export const layerFloodStyle = {
  id: 'floodP',
  type: 'circle',
  paint: {
    'circle-color': ['get', 'color'],
    'circle-stroke-color': 'yellow',
    'circle-stroke-width': [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      5,
      0
    ],
    'circle-opacity': 0.9,
    'circle-radius': {
      'base': 1.75,
      'stops': [
        [0, 1],
        [22, 180]
      ]
    },
    'circle-emissive-strength': 0.8
  },
  source: 'floodP',
};

export const   dataElectricBroken = [
  [{
    title: 'Mất điện khiến hệ thống chiếu sáng không hoạt động',
    color: '#EC7874',
  },
  {
    title: 'Thời điểm báo cáo:',
    content: '12:00 - 12/11/2023',
    color: '#65CF8E'
  },
  {
    title: 'Vị trí:',
    content: '10 Phố Quốc Tử Giám',
    color: 'white'
  },
  {
    title: 'Người báo cáo:',
    content: 'Người dân - 0913900344',
    color: 'white'
  },
  {
    title: 'Tình trạng:',
    content: 'Chưa xử lý',
    color: '#EC7874'
  }],
  [{
    title: 'Chập điện làm cháy bóng đèn cả tuyến phố',
    color: '#EC7874',
  },
  {
    title: 'Thời điểm báo cáo:',
    content: '18:00 - 21/10/2023',
    color: '#65CF8E'
  },
  {
    title: 'Vị trí:',
    content: '62 Nguyễn Chí Thanh',
    color: 'white'
  },
  {
    title: 'Người báo cáo:',
    content: 'Người dân - 0913900344',
    color: 'white'
  },
  {
    title: 'Tình trạng:',
    content: 'Đã xử lý',
    color: '#65CF8E'
  }],
  [{
    title: 'Hỏng trạm điều khiển đèn',
    color: '#EC7874',
  },
  {
    title: 'Thời điểm báo cáo:',
    content: '23:00 - 30/10/2023',
    color: '#65CF8E'
  },
  {
    title: 'Vị trí:',
    content: '238 Phố Hoàng Tích Trí',
    color: 'white'
  },
  {
    title: 'Người báo cáo:',
    content: 'Nhân viên - 0913900876',
    color: 'white'
  },
  {
    title: 'Tình trạng:',
    content: 'Chưa xử lý',
    color: '#EC7874'
  }]
  ,  [{
    title: 'Điểm tiêu thụ điện cao bất thường',
    color: '#EC7874',
  },
  {
    title: 'Thời điểm báo cáo:',
    content: '21:00 - 29/10/2023',
    color: '#65CF8E'
  },
  {
    title: 'Vị trí:',
    content: '01 Đường Láng',
    color: 'white'
  },
  {
    title: 'Người báo cáo::',
    content: 'Nhân viên - 0913900876',
    color: 'white'
  },
  {
    title: 'Tình trạng:',
    content: 'Chưa xử lý',
    color: '#EC7874'
  }]
];

