import React from 'react';
import { MAPBOX_TOKEN, initialViewState } from '../../constant';
import Map, { Marker } from 'react-map-gl';
import '../../constant/pulsing.css';
import "mapbox-gl/dist/mapbox-gl.css";
import { Collapse } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import YouTube from 'react-youtube';

export default function CCTVSystem() {

  const mapRef = React.useRef(null);
  const [newsInfo, setNewsInfo] = React.useState({
    title: null,
    activeContent: 1,
    isOpen: false
  })
  const onPlayerReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  const opts = {
    height: '240',
    width: '320',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  const items = [
    {
      key: '1',
      label: <div style={{
        display: 'flex', flexDirection: 'row', alignItems: 'center', height: 24, color: "white",
      }}>
        <FontAwesomeIcon style={{ marginRight: 8 }} icon='video'></FontAwesomeIcon>
        {newsInfo?.title && <p>{newsInfo?.title}</p>}
      </div>,
      children: <div style={{ color: "white" }}>
       
          <div>
          <div
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingRight: 16 }}
            >
              <h4>Thông tin dự đoán:</h4>
              <h4 style={{ color: 'red' }}>{newsInfo.content}</h4>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingRight: 16 }}
            >
              <h4 >Toạ độ:</h4>
              <h4 style={{ color: 'white' }}>{newsInfo.latitude ? newsInfo.latitude.toFixed(3) : ''}, {newsInfo.longitude ? newsInfo.longitude.toFixed(3) :''}</h4>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingRight: 16 }}
            >
              <h4 >Trạng thái:</h4>
              <h4 style={{ color: '#A2FF86' }}>{newsInfo.status}</h4>
            </div>
          
          <YouTube onPlay={(event) => event.target.mute() } videoId={newsInfo.videoId} opts={opts} onReady={onPlayerReady} />
          </div>
      </div>,
    },

  ];
  const onClick = (event => {
  
  
    setNewsInfo({ ...newsInfo, isOpen: false });
    const newMarker = {
      lat: event.lngLat.lat,
      lon: event.lngLat.lng,
  };
  console.log('newMarker', newMarker);
  });
  const dataCCTV = [
    {
      title: 'CCTV1',
      latitude: 21.022287314003506,
      longitude: 105.81784394755789,
      content: 'Phát hiện đám đông tụ tập',
      status: 'online',
      videoId: 'yHtgSyKwLNc',
    },
    {
      title: 'CCTV2',
      latitude: initialViewState.latitude - 0.007,
      longitude: initialViewState.longitude - 0.026,
      content: 'Cảnh báo khói/lửa',
      status: 'online',
      videoId: 'yHtgSyKwLNc',
    }, {
      title: 'CCTV3',
      latitude: 21.020412303776183,
      longitude: 105.81317654875693,
      content: 'Mật độ giao thông cao',
      status: 'online',
      videoId: 'yHtgSyKwLNc',
    }
    , {
      title: 'CCTV4',
      latitude: 21.017293866941728,
      longitude: 105.80742853573622,
      content: 'Phát hiện ngập lụt',
      status: 'online',
      videoId: 'yHtgSyKwLNc',
    },
    , {
      title: 'CCTV5',
      latitude: 21.018297221815956,
      longitude: 105.81797716452917,
      content: 'Phát hiện tai nạn giao thông',
      status: 'online',
      videoId: 'yHtgSyKwLNc',
    }
  ]
  const accidentRefs = dataCCTV.map(() => React.createRef());

  return (
    <div style={{ overflowY: 'hidden', width: '100vw', height: '100vh' }}>

      <Map
        initialViewState={{ ...initialViewState, pitch:0, latitude: 21.020865214854254 ,longitude: 105.81362751498659, zoom: 16 }}
        mapStyle={`mapbox://styles/mapbox/standard-beta`}
        ref={mapRef}
        mapboxAccessToken={MAPBOX_TOKEN}
        attributionControl={false}
        minZoom={5}
        onClick={onClick}
        onZoom={(e) => {
          const scale = (e.viewState.zoom + 2) * 0.05;
          dataCCTV.forEach((item, index) => {
            const markerRef = accidentRefs[index];
            if (markerRef.current) {
              markerRef.current.style.transform = `scale(${scale})`
              markerRef.current.style.transformOrigin = 'bottom';
            }
          })
        }}
        // pitch={60}
        interactiveLayerIds={['ellectricP', 'lightP', 'lightStationP']}
        onLoad={() => {
          mapRef.current?.setConfigProperty('basemap', 'lightPreset', 'dusk')
          mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);

        }}
        style={{ width: '100vw', height: '100vh' }}
      >
        {
          dataCCTV.map((item, index) => {
            return <Marker latitude={item.latitude} longitude={item.longitude} anchor='bottom'

              onClick={(e) => {
                e.originalEvent.stopPropagation();
                setNewsInfo({ ...item, isOpen: true });

              }}>
              <div key={item.latitude} ref={(el) => { accidentRefs[index].current = el }}>

                <div className='disappearing-appearing-div' style={{ animationDelay: `${index*2}s` }}  >
                  <div style={{
                    width: 128, height: 32, backgroundColor: '#ff4081', opacity: 0.8, alignItems: 'center', display: 'flex',
                    justifyContent: "center", border: "1px solid white",
                    borderRadius: 8,
                  }}>
                    <h3 style={{ color: 'white' }}>{item.title}</h3>
                  </div>
                  <div style={{ height: 64, width: 2, backgroundColor: "#ff4081", marginBottom: -20, marginLeft: 64 }}></div>
                  <div className='pulsing-dot' />
                </div>
              </div>

            </Marker>

          })
        }

      </Map>
      {newsInfo.isOpen && <Collapse
        activeKey={['1']}
        expandIconPosition='end'
        style={{
          position: 'absolute', right: 20, top: 40, zIndex: 100,
          backgroundColor: '#1E2028', opacity: 0.8,
        }} items={items} defaultActiveKey={['1']} />}
    </div>
  );
}

