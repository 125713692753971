import React, { useState } from 'react';
import { MAPBOX_TOKEN, initialViewState, layerElectricRouteStyle, layerLightStyle, layerLightStationStyle, dataElectricBroken } from '../../constant';
import Map, { Layer, Marker, Source } from 'react-map-gl';
import '../../constant/pulsing.css';
import "mapbox-gl/dist/mapbox-gl.css";
import { Collapse, Row, Switch } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ellectricData } from '../../data/ellectricData';
import { lightData } from '../../data/lightData';
import { lightStationData } from '../../data/lightStationData';

const PulsingDot = ({ latitude, longitude, title, onClickMarker, activeContent }) => {

  return (
    <Marker latitude={latitude} longitude={longitude} anchor='bottom'

      onClick={(e) => {
        onClickMarker({ title, activeContent });
        e.originalEvent.stopPropagation();

      }}>
      <div className='disappearing-appearing-div' style={{ animationDelay: `${activeContent}s` }}  >
        <div style={{
          width: 128, height: 32, backgroundColor: '#ff4081', opacity: 0.8, alignItems: 'center', display: 'flex',
          justifyContent: "center", border: "1px solid white",
          borderRadius: 8,
        }}>
          <h3 style={{ color: 'white' }}>{title}</h3>
        </div>
        <div style={{ height: 64, width: 2, backgroundColor: "#ff4081", marginBottom: -20, marginLeft: 64 }}></div>
        <div className='pulsing-dot' />
      </div>
    </Marker>
  );
};

export default function LightSysten() {

  const mapRef = React.useRef(null);
  const [hoverInfo, setHoverInfo] = useState();
  const [isClick, setIsClick] = useState(false);
  const [newsInfo, setNewsInfo] = React.useState({
    title: null,
    activeContent: 1,
    isOpen: false
  })
  const [activeKey, setActiveKey] = React.useState({
    lightStation: true,
    light: true,
    ellectric: true,
  });
  const [activeFeatureId, setActiveFeatureId] = useState(null);

  const mapInstance = mapRef?.current; const items = [
    {
      key: '1',
      label: <div style={{
        display: 'flex', flexDirection: 'row', alignItems: 'center', height: 24, color: "white",
      }}>
        <FontAwesomeIcon style={{ marginRight: 8 }} icon='lightbulb'></FontAwesomeIcon>
        {newsInfo?.title && <p>{newsInfo?.title}</p>}
      </div>,
      children: <div style={{ color: "white" }}>
        {
          dataElectricBroken[newsInfo.activeContent].map((item, index) => {
            return <div
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingRight: 16 }}
            >
              <h4>{item.title}</h4>
              <h4 style={{ color: item.color }}>{item.content}</h4>
            </div>
          })
        }

      </div>,
    },

  ];
  const onClickMarker = ({ title, activeContent }) => {
    setNewsInfo({ title: title, activeContent: activeContent, isOpen: true });

  }

  const onHover = (event => {
    const {
      features,
      point: { x, y }
    } = event;
    const hoveredFeature = features && features[0];
    if (!isClick) {
      hoveredFeature && setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y, layer: hoveredFeature.layer.id });
      if (hoveredFeature && mapInstance) {
        mapInstance?.setFeatureState(
          { source: "lightP", id: activeFeatureId },
          { hover: false }
        );
        mapInstance?.setFeatureState(
          { source: "ellectricP", id: activeFeatureId },
          { hover: false }
        );
        mapInstance?.setFeatureState(
          { source: "lightStationP", id: activeFeatureId },
          { hover: false }
        )
        setActiveFeatureId(hoveredFeature.id);
        if (hoveredFeature?.layer?.id === 'lightP') {
          mapInstance?.setFeatureState(
            { source: "lightP", id: hoveredFeature.id },
            { hover: true })
        }
        else if (hoveredFeature?.layer?.id === 'ellectricP') {
          mapInstance?.setFeatureState(
            { source: "ellectricP", id: hoveredFeature.id },
            { hover: true }
          );
        } else if (hoveredFeature?.layer?.id === 'lightStationP') {
          mapInstance?.setFeatureState(
            { source: "lightStationP", id: hoveredFeature.id },
            { hover: true }
          );
        }
      } else {

        mapInstance?.setFeatureState(
          { source: 'ellectricP', id: activeFeatureId },
          { hover: false }
        );
        mapInstance?.setFeatureState(
          { source: 'lightP', id: activeFeatureId },
          { hover: false }
        );
        mapInstance?.setFeatureState(
          { source: 'lightStationP', id: activeFeatureId },
          { hover: false }
        )
        setActiveFeatureId(null);
        setHoverInfo(null);
      }
    }
  });
  const onClick = (event => {
    const {
      features,
      point: { x, y }, layerId
    } = event;
    const hoveredFeature = features && features[0];
    setNewsInfo({ ...newsInfo, isOpen: false });
    const newMarker = {
      lat: event.lngLat.lat,
      lon: event.lngLat.lng,
  };
  console.log('newMarker', newMarker);
    if (hoveredFeature) {
      setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y, layer: hoveredFeature.layer.id });
      setIsClick(true);
    } else {
      setHoverInfo(null);
      setIsClick(false);
    }
  });
  const dataAccident = [
    {
      title: 'Mất điện',
      latitude: initialViewState.latitude,
      longitude: initialViewState.longitude
    },
    {
      title: 'Cháy bóng đèn',
      latitude: initialViewState.latitude - 0.007,
      longitude: initialViewState.longitude - 0.026
    }, {
      title: 'Hỏng trạm',
      latitude: initialViewState.latitude - 0.02,
      longitude: initialViewState.longitude + 0.0016
    }
    , {
      title: 'Tiêu thụ cao',
      latitude: initialViewState.latitude - 0.025,
      longitude: initialViewState.longitude - 0.015
    }
  ]
  const accidentRefs = dataAccident.map(() => React.createRef());

  return (
    <div style={{ overflowY: 'hidden', width: '100vw', height: '100vh' }}>

      <Map
        initialViewState={{ ...initialViewState, pitch:0, latitude: 21.017718245945105, longitude: 105.82082299926839, zoom: 14.3 }}
        mapStyle={`mapbox://styles/mapbox/standard-beta`}
        ref={mapRef}
        mapboxAccessToken={MAPBOX_TOKEN}
        attributionControl={false}
        minZoom={5}
        onClick={onClick}
        onMouseMove={onHover}
        onZoom={(e) => {
          const scale = (e.viewState.zoom + 2) * 0.05;
          dataAccident.forEach((item, index) => {
            const markerRef = accidentRefs[index];
            if (markerRef.current) {
              markerRef.current.style.transform = `scale(${scale})`
              markerRef.current.style.transformOrigin = 'bottom';
            }
          })
        }}
        // pitch={60}
        interactiveLayerIds={['ellectricP', 'lightP', 'lightStationP']}
        onLoad={() => {
          mapRef.current?.setConfigProperty('basemap', 'lightPreset', 'night')
          mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);

        }}
        style={{ width: '100vw', height: '100vh' }}
      >
        {/* <Layer {...buildingLayer} /> */}
        {ellectricData && activeKey.ellectric && <Source id='ellectricP' type="geojson" data={ellectricData}>
          <Layer {...layerElectricRouteStyle} >
          </Layer>
        </Source>}
        {lightData && activeKey.light && <Source id="lightP" type="geojson" data={lightData}>
          <Layer {...layerLightStyle} >
          </Layer>
        </Source>}
        {lightStationData && activeKey.lightStation && <Source id="lightStationP" type="geojson" data={lightStationData}>
          <Layer {...layerLightStationStyle} >
          </Layer>
        </Source>}
        {hoverInfo &&
          (<div className="tooltip" style={{
            left: hoverInfo.x, top: hoverInfo.y, position: 'absolute',
            margin: '8px',
            padding: '12px',
            background: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            maxWidth: '300px',
            fontSize: '14px',
            zIndex: '9',
            pointerEvents: 'none'
          }}>
            <h3>{hoverInfo.layer === 'lightP'
              ? 'Điểm chiếu sáng'
              : hoverInfo.layer === 'ellectricP'
                ? 'Cáp chiếu sáng'
                : 'Tủ điều khiển'}</h3>

            {hoverInfo.feature.properties.tenDoiTuon && <div>Tên đối tượng: {hoverInfo.feature.properties.tenDoiTuon}</div>}
            {hoverInfo.feature.properties.loaiCap && <div>Loại cáp: {hoverInfo.feature.properties.loaiCap}</div>}
            {hoverInfo.feature.properties.chieuDaiQL && <div>Chiều dài: {hoverInfo.feature.properties.chieuDaiQL}</div>}
            {hoverInfo.feature.properties.chuDauTu && <div>Chủ đầu tư: {hoverInfo.feature.properties.chuDauTu}</div>}
            {hoverInfo.feature.properties.tietDien && <div>Tiết diện: {hoverInfo.feature.properties.tietDien}</div>}
            {hoverInfo.feature.properties.doiQuanLy && <div>Đội quản lý: {hoverInfo.feature.properties.doiQuanLy}</div>}
            {hoverInfo.feature.properties.tenDuongPh && <div>Tên đường: {hoverInfo.feature.properties.tenDuongPh}</div>}
            {hoverInfo.feature.properties.loaiCot && <div>Loại cột: {hoverInfo.feature.properties.loaiCot}</div>}
            {hoverInfo.feature.properties.phanLoaiTu && <div>Phân loại tủ: {hoverInfo.feature.properties.phanLoaiTu}</div>}
            {hoverInfo.feature.properties.ngayLapDat && <div>Ngày lắp đặt: {hoverInfo.feature.properties.ngayLapDat}</div>}

          </div>)
        }
        {
          dataAccident.map((item, index) => {
            return <Marker latitude={item.latitude} longitude={item.longitude} anchor='bottom'

              onClick={(e) => {
                onClickMarker({ title: item.title, activeContent: index });
                e.originalEvent.stopPropagation();

              }}>
              <div key={item.latitude} ref={(el) => { accidentRefs[index].current = el }}>

                <div className='disappearing-appearing-div' style={{ animationDelay: `${index*2}s` }}  >
                  <div style={{
                    width: 128, height: 32, backgroundColor: '#ff4081', opacity: 0.8, alignItems: 'center', display: 'flex',
                    justifyContent: "center", border: "1px solid white",
                    borderRadius: 8,
                  }}>
                    <h3 style={{ color: 'white' }}>{item.title}</h3>
                  </div>
                  <div style={{ height: 64, width: 2, backgroundColor: "#ff4081", marginBottom: -20, marginLeft: 64 }}></div>
                  <div className='pulsing-dot' />
                </div>
              </div>

            </Marker>

          })
        }

      </Map>
      <Row className='tabbar-hover' style={{
        position: 'absolute', bottom: 10, zIndex: 20,
        left: '50%', alignItems: 'center',
        transform: 'translate(-50%, -50%)', borderRadius: 8,
      }}>
        <Row style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 8, paddingRight: 8,
        }}>
          <p style={{ color: 'white', fontSize: 16, fontWeight: 'bold', paddingRight: 8 }}>Tủ điều khiển</p>
          <Switch defaultChecked onChange={(checked) => setActiveKey({ ...activeKey, lightStation: checked })} ></Switch>
        </Row>
        <div style={{ width: 1.5, backgroundColor: 'white', height: 32 }}></div>
        <Row style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 8, paddingRight: 8,
        }}>
          <p style={{ color: 'white', fontSize: 16, fontWeight: 'bold', paddingRight: 8 }}>Điểm sáng</p>
          <Switch defaultChecked onChange={(checked) => setActiveKey({ ...activeKey, light: checked })} ></Switch>
        </Row>
        <div style={{ width: 1.5, backgroundColor: 'white', height: 32 }}></div>
        <Row style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 8, paddingRight: 8,
        }}>
          <p style={{ color: 'white', fontSize: 16, fontWeight: 'bold', paddingRight: 8 }}>Cáp chiếu sáng</p>
          <Switch defaultChecked onChange={(checked) => setActiveKey({ ...activeKey, ellectric: checked })} ></Switch>
        </Row>
      </Row>
      {newsInfo.isOpen && <Collapse
        activeKey={['1']}
        expandIconPosition='end'
        style={{
          position: 'absolute', right: 20, top: 40, zIndex: 100,
          backgroundColor: '#1E2028', opacity: 0.8,
        }} items={items} defaultActiveKey={['1']} />}
    </div>
  );
}

