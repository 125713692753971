export const floodData = [
  {
    "id": 14303,
    "type": "Traffic Bulletin",
    "traffic_type": "Flood",
    "created_at": 1696333328,
    "updated_at": 1696333328,
    "lat": 20.98839,
    "lon": 105.77984,
    "osm_combination_id": "way709292815",
    "ai_road_id": "ai_road_id_other",
    "name": "Phố Tố Hữu",
    "sta_cty": "Hà Nội",
    "content": "Đoạn đường này đang xảy ra ngập lụt",
    "forever": true,
    "audio_link": "admin/text_to_speech/fixed_audio/flood.mp3",
    "expire_time": null,
    "image": [],
    "geometry": null,
    "grid_lat_center": null,
    "grid_lon_center": null,
    "grid_zoom": null,
    "congestion_level": null,
    "updated_by": null,
    "bearing": 51.698128
  },
  {
    "id": 14263,
    "type": "Traffic Bulletin",
    "traffic_type": "Flood",
    "created_at": 1696307863,
    "updated_at": 1696307863,
    "lat": 20.976822,
    "lon": 105.78214,
    "osm_combination_id": "way684901851",
    "ai_road_id": "ai_road_id_other",
    "name": "Ngõ 140",
    "sta_cty": "Hà Nội",
    "content": "Đoạn đường này đang xảy ra ngập lụt",
    "forever": true,
    "audio_link": "admin/text_to_speech/fixed_audio/flood.mp3",
    "expire_time": null,
    "image": [],
    "geometry": null,
    "grid_lat_center": null,
    "grid_lon_center": null,
    "grid_zoom": null,
    "congestion_level": null,
    "updated_by": null,
    "bearing": 29.377804
  },
  {
    "id": 14264,
    "type": "Traffic Bulletin",
    "traffic_type": "Flood",
    "created_at": 1696307870,
    "updated_at": 1696307870,
    "lat": 20.975302,
    "lon": 105.783195,
    "osm_combination_id": "way600126494",
    "ai_road_id": "ai_road_id_other",
    "name": "Ngõ 217",
    "sta_cty": "Hà Nội",
    "content": "Đoạn đường này đang xảy ra ngập lụt",
    "forever": true,
    "audio_link": "admin/text_to_speech/fixed_audio/flood.mp3",
    "expire_time": null,
    "image": [],
    "geometry": null,
    "grid_lat_center": null,
    "grid_lon_center": null,
    "grid_zoom": null,
    "congestion_level": null,
    "updated_by": null,
    "bearing": 102.76313
  },
  {
    "id": 14265,
    "type": "Traffic Bulletin",
    "traffic_type": "Flood",
    "created_at": 1696307879,
    "updated_at": 1696307879,
    "lat": 20.980028,
    "lon": 105.789795,
    "osm_combination_id": "way708920946",
    "ai_road_id": "ai_road_id_other",
    "name": "Ngõ 44 Đại An",
    "sta_cty": "Hà Nội",
    "content": "Đoạn đường này đang xảy ra ngập lụt",
    "forever": true,
    "audio_link": "admin/text_to_speech/fixed_audio/flood.mp3",
    "expire_time": null,
    "image": [],
    "geometry": null,
    "grid_lat_center": null,
    "grid_lon_center": null,
    "grid_zoom": null,
    "congestion_level": null,
    "updated_by": null,
    "bearing": 33.786392
  },
  {
    "id": 14266,
    "type": "Traffic Bulletin",
    "traffic_type": "Flood",
    "created_at": 1696307890,
    "updated_at": 1696307890,
    "lat": 20.98186,
    "lon": 105.78757,
    "osm_combination_id": "way250707327",
    "ai_road_id": "ai_road_id_other",
    "name": "Ngõ 5 ao sen",
    "sta_cty": "Hà Nội",
    "content": "Đoạn đường này đang xảy ra ngập lụt",
    "forever": true,
    "audio_link": "admin/text_to_speech/fixed_audio/flood.mp3",
    "expire_time": null,
    "image": [],
    "geometry": null,
    "grid_lat_center": null,
    "grid_lon_center": null,
    "grid_zoom": null,
    "congestion_level": null,
    "updated_by": null,
    "bearing": 43.06031
  },
  {
    "id": 14267,
    "type": "Traffic Bulletin",
    "traffic_type": "Flood",
    "created_at": 1696307898,
    "updated_at": 1696307898,
    "lat": 20.976198,
    "lon": 105.78759,
    "osm_combination_id": "way220004617",
    "ai_road_id": "ai_road_id_other",
    "name": "Phố Dương Lâm",
    "sta_cty": "Hà Nội",
    "content": "Đoạn đường này đang xảy ra ngập lụt",
    "forever": true,
    "audio_link": "admin/text_to_speech/fixed_audio/flood.mp3",
    "expire_time": null,
    "image": [],
    "geometry": null,
    "grid_lat_center": null,
    "grid_lon_center": null,
    "grid_zoom": null,
    "congestion_level": null,
    "updated_by": null,
    "bearing": 338.1414
  }
]