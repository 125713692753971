import React from 'react';
import { Radio } from 'antd';
import LightSysten from './screens/LightSystem/LightSystem';
import './App.css'
import { TrafficSystem } from './screens/TrafficSystem/TrafficSystem';
import WaterSystem from './screens/WaterSystem/WaterSystem';
import CCTVSystem from './screens/CCTVSystem/CCTVSystem';

export default function App() {
    const [activeTab, setActiveTab] = React.useState('LightSystem');
    return (
        <div>
            <Radio.Group
                className="button-hover"
                defaultValue="a"
                size="large"
                onChange={(e) => { setActiveTab(e.target.value) }}
                style={{
                    position: 'absolute',
                    top: 30,
                    zIndex: 20,
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                <Radio.Button value="LightSystem">HT chiếu sáng</Radio.Button>
                <Radio.Button value="CCTVSystem">HT CCTV</Radio.Button>
                <Radio.Button value="TrafficSystem">HT Giao Thông</Radio.Button>
                <Radio.Button value="WaterSystem">HT Cung cấp nước</Radio.Button>
            </Radio.Group>
            {activeTab == 'LightSystem' && <LightSysten></LightSysten>
            }
            {activeTab == 'CCTVSystem' && <CCTVSystem></CCTVSystem>
            }
             {activeTab == 'TrafficSystem' && <TrafficSystem></TrafficSystem>
            }   {activeTab == 'WaterSystem' && <WaterSystem></WaterSystem>
        }
        </div>
    );
}