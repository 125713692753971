import React, { useState } from 'react';
import { MAPBOX_TOKEN, initialViewState, layerTrafficStatus } from '../../constant';
import Map, { Layer, Marker, Source } from 'react-map-gl';
import '../../constant/pulsing.css';
import "mapbox-gl/dist/mapbox-gl.css";
import { Collapse, Row, Switch } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { floodData } from '../../data/floodData';
import { restrictedRoad } from '../../data/restrictedRoad';
import { accidentData } from '../../data/accidentData';

import moment from 'moment';
import { trafficData } from '../../data/trafficData';



export function TrafficSystem() {

    const mapRef = React.useRef(null);
    const [hoverInfo, setHoverInfo] = useState();
    const [isClick, setIsClick] = useState(false);
    const [newsInfo, setNewsInfo] = React.useState({
        title: null,
        activeContent: 1,
        isOpen: false
    })
    const [activeKey, setActiveKey] = React.useState({
        showTrafficStatus: true,
        showFlood: true,
        showRestrictedRoad: true,
        showAccident: true,
    });

    const mapInstance = mapRef?.current;
    const floodRefs = floodData.map(() => React.createRef());
    const restrictedRoadRefs = restrictedRoad.map(() => React.createRef());
    const accidentRefs = accidentData.map(() => React.createRef());

    const items = [
        {
            key: '1',
            label: <div style={{
                display: 'flex', flexDirection: 'row', alignItems: 'center', height: 24, color: "white",
            }}>
                <FontAwesomeIcon style={{ marginRight: 8 }} icon='road'></FontAwesomeIcon>
                {newsInfo?.content && <p>{newsInfo?.content}</p>}
            </div>,
            children: <div style={{ color: "white" }}>
                <div
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingRight: 16 }}
                >
                    <h4>Vị trí: </h4>
                    <h4 style={{ color: 'red' }}>{newsInfo.name}</h4>
                </div>
                {newsInfo.updated_by && <div
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingRight: 16 }}
                >
                    <h4>Đăng bởi: </h4>
                    <h4 style={{ color: 'red' }}>{newsInfo.updated_by}</h4>
                </div>}
                <div
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingRight: 16 }}
                >
                    <h4>Thời điểm: </h4>
                    <h4 style={{ color: 'red' }}>{moment.unix(newsInfo.created_at).format('HH:mm DD/MM/YYYY')}</h4>
                </div>

            </div>,
        },

    ];
    const onClickMarker = ({ name, content, created_at, updated_by, traffic_type }) => {
        setNewsInfo({ isOpen: true, name, content, created_at, updated_by, traffic_type });
    }

    const onHover = (event => {
        const {
            features,
            point: { x, y }
        } = event;
        const hoveredFeature = features && features[0];
        if (!isClick ) {
            hoveredFeature ? setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y, layer: hoveredFeature.layer.id }) : setHoverInfo(null);
        } 
        
    });
    const onClick = (event => {
        const {
            features,
            point: { x, y }, layerId
        } = event;
        const hoveredFeature = features && features[0];
        const newMarker = {
            lat: event.lngLat.lat,
            lon: event.lngLat.lng,
        };
        setNewsInfo({ ...newsInfo, isOpen: false });
        if (hoveredFeature) {
            setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y, layer: hoveredFeature.layer.id });
            setIsClick(true);
        } else {
            setHoverInfo(null);
            setIsClick(false);
        }
    });

    return (
        <div style={{ overflowY: 'hidden', width: '100vw', height: '100vh' }}>
            <div style={{ overflowY: 'hidden', width: '100vw', height: '100vh' }}>
                <Map
                    initialViewState={{ ...initialViewState, zoom: 16.3, pitch: 60, latitude:  
                        20.979852392292997, longitude: 
                        105.78388864660269}}
                    mapStyle={`mapbox://styles/mapbox/standard-beta`}
                    // mapStyle={`mapbox://styles/mapbox/dark-v9`}
                    ref={mapRef}
                    mapboxAccessToken={MAPBOX_TOKEN}
                    attributionControl={false}
                    minZoom={5}
                    onClick={onClick}
                    onMouseMove={onHover}
                    onZoom={(e) => {
                        const scale = (e.viewState.zoom + 2) * 0.05;


                        floodData.forEach((item, index) => {
                            const markerRef = floodRefs[index];
                            if (markerRef.current) {
                                markerRef.current.style.transform = `scale(${scale})`
                                markerRef.current.style.transformOrigin = 'bottom';
                            }
                        })
                        restrictedRoad.forEach((item, index) => {
                            const markerRef = restrictedRoadRefs[index];
                            if (markerRef.current) {
                                markerRef.current.style.transform = `scale(${scale})`
                                markerRef.current.style.transformOrigin = 'bottom';
                            }

                        })
                        accidentData.forEach((item, index) => {
                            const markerRef = accidentRefs[index];
                            if (markerRef.current) {
                                markerRef.current.style.transform = `scale(${scale})`
                                markerRef.current.style.transformOrigin = 'bottom';
                            }
                        })
                    }}
                    // pitch={60}
                    interactiveLayerIds={['trafficStatus']}
                    onLoad={() => {
                        mapRef.current?.setConfigProperty('basemap', 'lightPreset', 'night')
                        mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
                    }}
                >
                    {activeKey.showTrafficStatus && trafficData && <Source id='trafficStatus' type="geojson" data={trafficData}>
                        <Layer {...layerTrafficStatus} >
                        </Layer>
                    </Source>}
                    {activeKey.showFlood && floodData && floodData.map((item, index) => {
                        return (
                            <Marker latitude={item.lat} longitude={item.lon} anchor='bottom'

                                onClick={(e) => {
                                    onClickMarker(
                                        {
                                            name: item.name,
                                            content: item.content,
                                            created_at: item.created_at,
                                            traffic_type: item.traffic_type,
                                            updated_by: item.updated_by,
                                        }
                                    );
                                    e.originalEvent.stopPropagation();
                                }}>
                                <div key={item.id} ref={(el) => { floodRefs[index].current = el }}>
                                    <div className='disappearing-appearing-div' style={{ animationDelay: `${index + Math.random() * 4}s` }}  >
                                        <div style={{
                                            width: 128, height: 32, backgroundColor: '#08D9D6', opacity: 0.9, alignItems: 'center', display: 'flex',
                                            justifyContent: "center", border: "1px solid white",
                                            borderRadius: 8,
                                        }}>
                                            <h3 style={{ color: 'white' }}>{item.traffic_type == 'Flood'
                                                ? 'Ngập lụt' :
                                                item.traffic_type == 'Traffic Jam' ? 'Ùn tắc'
                                                    : item.traffic_type == 'Restricted Road'
                                                        ? 'Đường cấm' : 'Tai nạn'} </h3>
                                        </div>
                                        <div style={{ height: 64, width: 2, backgroundColor: "#08D9D6", marginBottom: -20, marginLeft: 64 }}></div>
                                        <div className='pulsing-dot'  style={{backgroundColor:"#08D9D6" }}/>
                                    </div>
                                </div>

                            </Marker>
                        );
                    })}
                    {activeKey.showRestrictedRoad && restrictedRoad && restrictedRoad.map((item, index) => {
                        return (
                            <Marker latitude={item.lat} longitude={item.lon} anchor='bottom'

                                onClick={(e) => {
                                    onClickMarker(
                                        {
                                            name: item.name,
                                            content: item.content,
                                            created_at: item.created_at,
                                            traffic_type: item.traffic_type,
                                            updated_by: item.updated_by,
                                        }
                                    );
                                    e.originalEvent.stopPropagation();
                                }}>
                                <div key={item.id} ref={(el) => { restrictedRoadRefs[index].current = el }}>
                                <div className='disappearing-appearing-div' style={{ animationDelay: `${index + Math.random() * 4}s` }}  >
                                        <div style={{
                                            width: 128, height: 32, backgroundColor: '#2F58CD', opacity: 0.9, alignItems: 'center', display: 'flex',
                                            justifyContent: "center", border: "1px solid white",
                                            borderRadius: 8,
                                        }}>
                                            <h3 style={{ color: 'white' }}>Đường cấm </h3>
                                        </div>
                                        <div style={{ height: 64, width: 2, backgroundColor: "#2F58CD", marginBottom: -20, marginLeft: 64 }}></div>
                                        <div className='pulsing-dot' style={{backgroundColor:"#2F58CD"}} />
                                    </div>
                                </div>

                            </Marker>
                        );
                    })}
                    {activeKey.showAccident && accidentData && accidentData.map((item, index) => {
                        return (
                            <Marker latitude={item.lat} longitude={item.lon} anchor='bottom'

                                onClick={(e) => {
                                    onClickMarker(
                                        {
                                            name: item.name,
                                            content: item.content,
                                            created_at: item.created_at,
                                            traffic_type: item.traffic_type,
                                            updated_by: item.updated_by,
                                        }
                                    );
                                    e.originalEvent.stopPropagation();
                                }}>
                                <div key={item.id} ref={(el) => { accidentRefs[index].current = el }}>
                                <div className='disappearing-appearing-div' style={{ animationDelay: `${index + Math.random() * 4}s` }}  >
                                        <div style={{
                                            width: 128, height: 32, backgroundColor: '#C84B31', opacity: 0.9, alignItems: 'center', display: 'flex',
                                            justifyContent: "center", border: "1px solid white",
                                            borderRadius: 8,
                                        }}>
                                            <h3 style={{ color: 'white' }}>Tai nạn</h3>
                                        </div>
                                        <div style={{ height: 64, width: 2, backgroundColor: "#C84B31", marginBottom: -20, marginLeft: 64 }}></div>
                                        <div className='pulsing-dot' style={{backgroundColor:"#C84B31"}} />
                                    </div>
                                </div>

                            </Marker>
                        );
                    })}

                </Map>
            </div>

            <Row className='tabbar-hover' style={{
                position: 'absolute', bottom: 10, zIndex: 20,
                left: '50%', alignItems: 'center',
                transform: 'translate(-50%, -50%)', borderRadius: 8,
                alignItems: 'center',
                justifyContent: 'center',
                width: 750,
            }}>
                <Row style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 8, paddingRight: 8,
                }}>
                    <p style={{ color: 'white', fontSize: 16, fontWeight: 'bold', paddingRight: 8 }}>Trạng thái giao thông</p>
                    <Switch defaultChecked onChange={(checked) => setActiveKey({ ...activeKey, showTrafficStatus: checked })} ></Switch>
                </Row>
                <div style={{ width: 1.5, backgroundColor: 'white', height: 32 }}></div>
                <Row style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 8, paddingRight: 8,
                }}>
                    <p style={{ color: 'white', fontSize: 16, fontWeight: 'bold', paddingRight: 8 }}>Điểm ngập lụt</p>
                    <Switch defaultChecked onChange={(checked) => setActiveKey({ ...activeKey, showFlood: checked })} ></Switch>
                </Row>
                <div style={{ width: 1.5, backgroundColor: 'white', height: 32 }}></div>
                <Row style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 8, paddingRight: 8,
                }}>
                    <p style={{ color: 'white', fontSize: 16, fontWeight: 'bold', paddingRight: 8 }}>Cấm đường</p>
                    <Switch defaultChecked onChange={(checked) => setActiveKey({ ...activeKey, showRestrictedRoad: checked })} ></Switch>
                </Row>
                <div style={{ width: 1.5, backgroundColor: 'white', height: 32 }}></div>
                <Row style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 8, paddingRight: 8,
                }}>
                    <p style={{ color: 'white', fontSize: 16, fontWeight: 'bold', paddingRight: 8 }}>Điểm tai nạn</p>
                    <Switch defaultChecked onChange={(checked) => setActiveKey({ ...activeKey, showAccident: checked })} ></Switch>
                </Row>

            </Row>
            {hoverInfo &&
                (<div className="tooltip" style={{
                    left: hoverInfo.x, top: hoverInfo.y, position: 'absolute',
                    margin: '8px',
                    padding: '12px',
                    background: 'rgba(0, 0, 0, 0.8)',
                    color: '#fff',
                    maxWidth: '300px',
                    fontSize: '14px',
                    zIndex: '9',
                    pointerEvents: 'none'
                }}>
                    <h3>Tình trạng giao thông:</h3>
                    {hoverInfo.feature.properties.content && <div>{hoverInfo.feature.properties.content}</div>}

                </div>)
            }
            {newsInfo.isOpen && <Collapse
                activeKey={['1']}
                expandIconPosition='end'
                style={{
                    position: 'absolute', right: 20, top: 40, zIndex: 100,
                    backgroundColor: '#1E2028', opacity: 0.8,
                }} items={items} defaultActiveKey={['1']} />}
        </div>
    );
}

