export const ellectricData ={
  "type": "FeatureCollection",
  "features": [
    {
      "id": 0,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83246626492384,
          21.025504702996077,
          105.83266200671146,
          21.025585189134294
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83266200671146,
            21.025504702996077
          ],
          [
            105.83246626492384,
            21.025585189134294
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phan Văn Trị",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025504702996077,
        "to_latitude": 21.025585189134294,
        "to_longitude": 105.83246626492384,
        "from_longitude": 105.83266200671146,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83223783242303,
          21.025585189134294,
          105.83246626492384,
          21.025674133363502
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83246626492384,
            21.025585189134294
          ],
          [
            105.83223783242303,
            21.025674133363502
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phan Văn Trị",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025585189134294,
        "to_latitude": 21.025674133363502,
        "to_longitude": 105.83223783242303,
        "from_longitude": 105.83246626492384,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83181136118544,
          21.025674133363502,
          105.83223783242303,
          21.025721702266413
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83223783242303,
            21.025674133363502
          ],
          [
            105.83181136118544,
            21.025721702266413
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phan Văn Trị",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025674133363502,
        "to_latitude": 21.025721702266413,
        "to_longitude": 105.83181136118544,
        "from_longitude": 105.83223783242303,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83388808695993,
          21.027340600681775,
          105.83402986086448,
          21.027648543025517
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83402986086448,
            21.027648543025517
          ],
          [
            105.83388808695993,
            21.027340600681775
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "9",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.027648543025517,
        "to_latitude": 21.027340600681775,
        "to_longitude": 105.83388808695993,
        "from_longitude": 105.83402986086448,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83374631305541,
          21.027032657701863,
          105.83388808695993,
          21.027340600681775
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83388808695993,
            21.027340600681775
          ],
          [
            105.83374631305541,
            21.027032657701863
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x35+1x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.027340600681775,
        "to_latitude": 21.027032657701863,
        "to_longitude": 105.83374631305541,
        "from_longitude": 105.83388808695993,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83360453915088,
          21.02672471408567,
          105.83374631305541,
          21.027032657701863
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83374631305541,
            21.027032657701863
          ],
          [
            105.83360453915088,
            21.02672471408567
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.027032657701863,
        "to_latitude": 21.02672471408567,
        "to_longitude": 105.83360453915088,
        "from_longitude": 105.83374631305541,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83346276524638,
          21.026416769833315,
          105.83360453915088,
          21.02672471408567
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83360453915088,
            21.02672471408567
          ],
          [
            105.83346276524638,
            21.026416769833315
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.02672471408567,
        "to_latitude": 21.026416769833315,
        "to_longitude": 105.83346276524638,
        "from_longitude": 105.83360453915088,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83332099134185,
          21.02610882494471,
          105.83346276524638,
          21.026416769833315
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83346276524638,
            21.026416769833315
          ],
          [
            105.83332099134185,
            21.02610882494471
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.026416769833315,
        "to_latitude": 21.02610882494471,
        "to_longitude": 105.83332099134185,
        "from_longitude": 105.83346276524638,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83317921743733,
          21.025800879419933,
          105.83332099134185,
          21.02610882494471
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83332099134185,
            21.02610882494471
          ],
          [
            105.83317921743733,
            21.025800879419933
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.02610882494471,
        "to_latitude": 21.025800879419933,
        "to_longitude": 105.83317921743733,
        "from_longitude": 105.83332099134185,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 9,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8330374435328,
          21.025492933258953,
          105.83317921743733,
          21.025800879419933
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83317921743733,
            21.025800879419933
          ],
          [
            105.8330374435328,
            21.025492933258953
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025800879419933,
        "to_latitude": 21.025492933258953,
        "to_longitude": 105.8330374435328,
        "from_longitude": 105.83317921743733,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 10,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83266200671146,
          21.025418763205,
          105.8330374435328,
          21.025504702996077
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8330374435328,
            21.025492933258953
          ],
          [
            105.8329727576857,
            21.02543378499074
          ],
          [
            105.83293252455161,
            21.025418763205
          ],
          [
            105.8328574226989,
            21.025431281360163
          ],
          [
            105.83266200671146,
            21.025504702996077
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phan Văn Trị",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025492933258953,
        "to_latitude": 21.025504702996077,
        "to_longitude": 105.83266200671146,
        "from_longitude": 105.8330374435328,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 11,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83322119732378,
          21.025427838867124,
          105.83336297122831,
          21.02573578516259
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83322119732378,
            21.025427838867124
          ],
          [
            105.83336297122831,
            21.02573578516259
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025427838867124,
        "to_latitude": 21.02573578516259,
        "to_longitude": 105.83336297122831,
        "from_longitude": 105.83322119732378,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 12,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83336297122831,
          21.02573578516259,
          105.83350474513283,
          21.026043730821815
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83336297122831,
            21.02573578516259
          ],
          [
            105.83350474513283,
            21.026043730821815
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.02573578516259,
        "to_latitude": 21.026043730821815,
        "to_longitude": 105.83350474513283,
        "from_longitude": 105.83336297122831,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 13,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83350474513283,
          21.026043730821815,
          105.83364651903736,
          21.026351675844907
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83350474513283,
            21.026043730821815
          ],
          [
            105.83364651903736,
            21.026351675844907
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.026043730821815,
        "to_latitude": 21.026351675844907,
        "to_longitude": 105.83364651903736,
        "from_longitude": 105.83350474513283,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 14,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83364651903736,
          21.026351675844907,
          105.83378829294189,
          21.026659620231765
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83364651903736,
            21.026351675844907
          ],
          [
            105.83378829294189,
            21.026659620231765
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.026351675844907,
        "to_latitude": 21.026659620231765,
        "to_longitude": 105.83378829294189,
        "from_longitude": 105.83364651903736,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 15,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83378829294189,
          21.026659620231765,
          105.8339300668464,
          21.026967563982414
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83378829294189,
            21.026659620231765
          ],
          [
            105.8339300668464,
            21.026967563982414
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.026659620231765,
        "to_latitude": 21.026967563982414,
        "to_longitude": 105.8339300668464,
        "from_longitude": 105.83378829294189,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 16,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8339300668464,
          21.026967563982414,
          105.83407184075092,
          21.027275507096853
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8339300668464,
            21.026967563982414
          ],
          [
            105.83407184075092,
            21.027275507096853
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.026967563982414,
        "to_latitude": 21.027275507096853,
        "to_longitude": 105.83407184075092,
        "from_longitude": 105.8339300668464,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 17,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83407184075092,
          21.027275507096853,
          105.83421361465547,
          21.027583449575054
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83407184075092,
            21.027275507096853
          ],
          [
            105.83421361465547,
            21.027583449575054
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x35+1x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.027275507096853,
        "to_latitude": 21.027583449575054,
        "to_longitude": 105.83421361465547,
        "from_longitude": 105.83407184075092,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 18,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83402986086448,
          21.027648543025517,
          105.83405637013449,
          21.027809711780737
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83402986086448,
            21.027648543025517
          ],
          [
            105.83405637013449,
            21.027809711780737
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "09",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.027648543025517,
        "to_latitude": 21.027809711780737,
        "to_longitude": 105.83405637013449,
        "from_longitude": 105.83402986086448,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 19,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83228284743986,
          21.025168399879085,
          105.83322119732378,
          21.02552656249164
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83322119732378,
            21.025427838867124
          ],
          [
            105.83311491476788,
            21.025168399879085
          ],
          [
            105.83228284743986,
            21.025503723998472
          ],
          [
            105.83229035672905,
            21.02552656249164
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "Phố Tôn Đức Thăng",
        "chieuDaiQL": 100,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "tủ điện",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phố Tôn Đức Thăng",
        "from_latitude": 21.025427838867124,
        "to_latitude": 21.02552656249164,
        "to_longitude": 105.83229035672905,
        "from_longitude": 105.83322119732378,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 20,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83229035672905,
          21.025276056160706,
          105.8330374435328,
          21.02552656249164
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8330374435328,
            21.025492933258953
          ],
          [
            105.83291643129618,
            21.025276056160706
          ],
          [
            105.83229035672905,
            21.02552656249164
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "Phố Tôn Đức Thăng",
        "chieuDaiQL": 85,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "tủ điện",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phố Tôn Đức Thăng",
        "from_latitude": 21.025492933258953,
        "to_latitude": 21.02552656249164,
        "to_longitude": 105.83229035672905,
        "from_longitude": 105.8330374435328,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 21,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83266200671146,
          21.025504702996077,
          105.832763545383,
          21.02561654993416
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83266200671146,
            21.025504702996077
          ],
          [
            105.8327099012102,
            21.025521412046892
          ],
          [
            105.832763545383,
            21.02561654993416
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+18+62 Phan Văn Trị",
        "diemDau": "17",
        "diemCuoi": "17-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025504702996077,
        "to_latitude": 21.02561654993416,
        "to_longitude": 105.832763545383,
        "from_longitude": 105.83266200671146,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 22,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83246626492384,
          21.025576491884017,
          105.83261065946985,
          21.025666622482937
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83246626492384,
            21.025585189134294
          ],
          [
            105.83256506192026,
            21.025576491884017
          ],
          [
            105.83261065946985,
            21.025666622482937
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+18+62 Phan Văn Trị",
        "diemDau": "18",
        "diemCuoi": "18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025585189134294,
        "to_latitude": 21.025666622482937,
        "to_longitude": 105.83261065946985,
        "from_longitude": 105.83246626492384,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 23,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83181136118544,
          21.025721702266413,
          105.83199375140174,
          21.025751745776176
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83181136118544,
            21.025721702266413
          ],
          [
            105.83199375140174,
            21.025751745776176
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+18+62 Phan Văn Trị",
        "diemDau": "20",
        "diemCuoi": "20-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025721702266413,
        "to_latitude": 21.025751745776176,
        "to_longitude": 105.83199375140174,
        "from_longitude": 105.83181136118544,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 24,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83163433539362,
          21.025721702266413,
          105.83181136118544,
          21.02583686902081
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83181136118544,
            21.025721702266413
          ],
          [
            105.83163433539362,
            21.02583686902081
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+18+62 Phan Văn Trị",
        "diemDau": "20",
        "diemCuoi": "20-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025721702266413,
        "to_latitude": 21.02583686902081,
        "to_longitude": 105.83163433539362,
        "from_longitude": 105.83181136118544,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 25,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83322119732378,
          21.02532112155823,
          105.83327584730428,
          21.025427838867124
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83322119732378,
            21.025427838867124
          ],
          [
            105.83325975404884,
            21.025373697837832
          ],
          [
            105.83327584730428,
            21.02532112155823
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 83 Tôn Đức Thắng",
        "diemDau": "01",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025427838867124,
        "to_latitude": 21.02532112155823,
        "to_longitude": 105.83327584730428,
        "from_longitude": 105.83322119732378,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 26,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83327584730428,
          21.025296085228334,
          105.83347433076702,
          21.02532112155823
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83327584730428,
            21.02532112155823
          ],
          [
            105.83347433076702,
            21.025296085228334
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 83 Tôn Đức Thắng",
        "diemDau": "1-1",
        "diemCuoi": "1-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.02532112155823,
        "to_latitude": 21.025296085228334,
        "to_longitude": 105.83347433076702,
        "from_longitude": 105.83327584730428,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 27,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83350474513283,
          21.0259870863938,
          105.83369963632514,
          21.026043730821815
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83350474513283,
            21.026043730821815
          ],
          [
            105.83369963632514,
            21.0259870863938
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 61 Tôn Đức Thắng",
        "diemDau": "03",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.026043730821815,
        "to_latitude": 21.0259870863938,
        "to_longitude": 105.83369963632514,
        "from_longitude": 105.83350474513283,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 28,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83369963632514,
          21.025916672018912,
          105.83393265322827,
          21.0259870863938
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83369963632514,
            21.0259870863938
          ],
          [
            105.83382536487369,
            21.025929190132274
          ],
          [
            105.83393265322827,
            21.025916672018912
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 61 Tôn Đức Thắng",
        "diemDau": "3-1",
        "diemCuoi": "3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.0259870863938,
        "to_latitude": 21.025916672018912,
        "to_longitude": 105.83393265322827,
        "from_longitude": 105.83369963632514,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 29,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83336297122831,
          21.025703863929294,
          105.83348472432876,
          21.02573578516259
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83336297122831,
            21.02573578516259
          ],
          [
            105.83348472432876,
            21.025703863929294
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 69 Tôn Đức Thắng",
        "diemDau": "02",
        "diemCuoi": "2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.02573578516259,
        "to_latitude": 21.025703863929294,
        "to_longitude": 105.83348472432876,
        "from_longitude": 105.83336297122831,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 30,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83348472432876,
          21.02570136030325,
          105.83363492803417,
          21.025748929197455
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83348472432876,
            21.025703863929294
          ],
          [
            105.83352227525513,
            21.025748929197455
          ],
          [
            105.83363492803417,
            21.02570136030325
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 69 Tôn Đức Thắng",
        "diemDau": "2-1",
        "diemCuoi": "2-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025703863929294,
        "to_latitude": 21.02570136030325,
        "to_longitude": 105.83363492803417,
        "from_longitude": 105.83348472432876,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 31,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83363492803417,
          21.025626251491698,
          105.8337583096442,
          21.02570136030325
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83363492803417,
            21.02570136030325
          ],
          [
            105.83365102128063,
            21.025658798648312
          ],
          [
            105.8337583096442,
            21.025626251491698
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 69 Tôn Đức Thắng",
        "diemDau": "2-1",
        "diemCuoi": "2-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.02570136030325,
        "to_latitude": 21.025626251491698,
        "to_longitude": 105.8337583096442,
        "from_longitude": 105.83363492803417,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 32,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83381237523808,
          21.02671192886363,
          105.83388169126322,
          21.026757886901212
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83381237523808,
            21.02671192886363
          ],
          [
            105.83388169126322,
            21.026757886901212
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Giám",
        "diemDau": "05",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.02671192886363,
        "to_latitude": 21.026757886901212,
        "to_longitude": 105.83388169126322,
        "from_longitude": 105.83381237523808,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 33,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83388169126322,
          21.02667777140282,
          105.83409760908975,
          21.026757886901212
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83388169126322,
            21.026757886901212
          ],
          [
            105.83409760908975,
            21.02667777140282
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Giám",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.026757886901212,
        "to_latitude": 21.02667777140282,
        "to_longitude": 105.83409760908975,
        "from_longitude": 105.83388169126322,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 34,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83409760908975,
          21.026597655861323,
          105.83431352691629,
          21.02667777140282
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83409760908975,
            21.02667777140282
          ],
          [
            105.83431352691629,
            21.026597655861323
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Giám",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.02667777140282,
        "to_latitude": 21.026597655861323,
        "to_longitude": 105.83431352691629,
        "from_longitude": 105.83409760908975,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 35,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83431352691629,
          21.026597655861323,
          105.83444763736628,
          21.026827987927536
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83431352691629,
            21.026597655861323
          ],
          [
            105.83444763736628,
            21.026827987927536
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 7+15+30 Hồ Giám",
        "diemDau": "24",
        "diemCuoi": "24-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.026597655861323,
        "to_latitude": 21.026827987927536,
        "to_longitude": 105.83444763736628,
        "from_longitude": 105.83431352691629,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 36,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83409760908975,
          21.02667777140282,
          105.83424915390353,
          21.026893081708035
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83409760908975,
            21.02667777140282
          ],
          [
            105.83416332321087,
            21.02671031832983
          ],
          [
            105.83424915390353,
            21.026893081708035
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 7+15+30 Hồ Giám",
        "diemDau": "23",
        "diemCuoi": "23-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.02667777140282,
        "to_latitude": 21.026893081708035,
        "to_longitude": 105.83424915390353,
        "from_longitude": 105.83409760908975,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 37,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83424915390353,
          21.026893081708035,
          105.83432693796398,
          21.027048305223556
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83424915390353,
            21.026893081708035
          ],
          [
            105.83432693796398,
            21.027048305223556
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 7+15+30 Hồ Giám",
        "diemDau": "23-1",
        "diemCuoi": "23-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.026893081708035,
        "to_latitude": 21.027048305223556,
        "to_longitude": 105.83432693796398,
        "from_longitude": 105.83424915390353,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 38,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83431352691629,
          21.02647497885409,
          105.83442081527987,
          21.026597655861323
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83431352691629,
            21.026597655861323
          ],
          [
            105.83442081527987,
            21.02647497885409
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Giám",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.026597655861323,
        "to_latitude": 21.02647497885409,
        "to_longitude": 105.83442081527987,
        "from_longitude": 105.83431352691629,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 39,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83442081527987,
          21.026387352359333,
          105.83459247665621,
          21.02647497885409
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83442081527987,
            21.02647497885409
          ],
          [
            105.83459247665621,
            21.026387352359333
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 7+15+30 Hồ Giám",
        "diemDau": "25",
        "diemCuoi": "25-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.02647497885409,
        "to_latitude": 21.026387352359333,
        "to_longitude": 105.83459247665621,
        "from_longitude": 105.83442081527987,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 40,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83432425575624,
          21.026272186030255,
          105.83442081527987,
          21.02647497885409
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83442081527987,
            21.02647497885409
          ],
          [
            105.83432425575624,
            21.026272186030255
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Giám",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.02647497885409,
        "to_latitude": 21.026272186030255,
        "to_longitude": 105.83432425575624,
        "from_longitude": 105.83442081527987,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 41,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83422233180815,
          21.026026831379884,
          105.83432425575624,
          21.026272186030255
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83432425575624,
            21.026272186030255
          ],
          [
            105.83422233180815,
            21.026026831379884
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Giám",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.026272186030255,
        "to_latitude": 21.026026831379884,
        "to_longitude": 105.83422233180815,
        "from_longitude": 105.83432425575624,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 42,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83422233180815,
          21.02592919013058,
          105.83427329379118,
          21.026026831379884
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83422233180815,
            21.026026831379884
          ],
          [
            105.83427329379118,
            21.02592919013058
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thông Phong",
        "diemDau": "27",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.026026831379884,
        "to_latitude": 21.02592919013058,
        "to_longitude": 105.83427329379118,
        "from_longitude": 105.83422233180815,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 43,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83401580172219,
          21.02592919013058,
          105.83427329379118,
          21.026069392929738
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83427329379118,
            21.02592919013058
          ],
          [
            105.83401580172219,
            21.026069392929738
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thông Phong",
        "diemDau": "30",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.02592919013058,
        "to_latitude": 21.026069392929738,
        "to_longitude": 105.83401580172219,
        "from_longitude": 105.83427329379118,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 44,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83377440291761,
          21.026069392929738,
          105.83401580172219,
          21.026164530467234
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83401580172219,
            21.026069392929738
          ],
          [
            105.83377440291761,
            21.026164530467234
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thông Phong",
        "diemDau": "29",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.026069392929738,
        "to_latitude": 21.026164530467234,
        "to_longitude": 105.83377440291761,
        "from_longitude": 105.83401580172219,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 45,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83427329379118,
          21.025844066938685,
          105.83463539200703,
          21.02592919013058
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83427329379118,
            21.02592919013058
          ],
          [
            105.83463539200703,
            21.025844066938685
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 52,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thông Phong",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.02592919013058,
        "to_latitude": 21.025844066938685,
        "to_longitude": 105.83463539200703,
        "from_longitude": 105.83427329379118,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 46,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83463539200703,
          21.025628755118124,
          105.83502967673375,
          21.025844066938685
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83463539200703,
            21.025844066938685
          ],
          [
            105.83502967673375,
            21.025628755118124
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thông Phong",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025844066938685,
        "to_latitude": 21.025628755118124,
        "to_longitude": 105.83502967673375,
        "from_longitude": 105.83463539200703,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 47,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83502967673375,
          21.025486048274907,
          105.83526571112283,
          21.025628755118124
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83502967673375,
            21.025628755118124
          ],
          [
            105.83526571112283,
            21.025486048274907
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thông Phong",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025628755118124,
        "to_latitude": 21.025486048274907,
        "to_longitude": 105.83526571112283,
        "from_longitude": 105.83502967673375,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 48,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83526571112283,
          21.025375888513405,
          105.83550710993642,
          21.025486048274907
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83526571112283,
            21.025486048274907
          ],
          [
            105.83550710993642,
            21.025375888513405
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thông Phong",
        "diemDau": "33",
        "diemCuoi": "34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025486048274907,
        "to_latitude": 21.025375888513405,
        "to_longitude": 105.83550710993642,
        "from_longitude": 105.83526571112283,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 49,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83550710993642,
          21.025305786804203,
          105.8357029112004,
          21.025375888513405
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83550710993642,
            21.025375888513405
          ],
          [
            105.8357029112004,
            21.025305786804203
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thông Phong",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025375888513405,
        "to_latitude": 21.025305786804203,
        "to_longitude": 105.8357029112004,
        "from_longitude": 105.83550710993642,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 50,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8357029112004,
          21.025208145083454,
          105.83579678851629,
          21.025305786804203
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8357029112004,
            21.025305786804203
          ],
          [
            105.83579678851629,
            21.025208145083454
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thông Phong",
        "diemDau": "35",
        "diemCuoi": "36",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025305786804203,
        "to_latitude": 21.025208145083454,
        "to_longitude": 105.83579678851629,
        "from_longitude": 105.8357029112004,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 51,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83569754677593,
          21.024977810514184,
          105.83579678851629,
          21.025208145083454
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83579678851629,
            21.025208145083454
          ],
          [
            105.83569754677593,
            21.024977810514184
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thông Phong",
        "diemDau": "36",
        "diemCuoi": "37",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025208145083454,
        "to_latitude": 21.024977810514184,
        "to_longitude": 105.83569754677593,
        "from_longitude": 105.83579678851629,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 52,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83427329379118,
          21.02565879864749,
          105.83452273922796,
          21.02592919013058
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83427329379118,
            21.02592919013058
          ],
          [
            105.83442617971333,
            21.025841563314167
          ],
          [
            105.83452273922796,
            21.02565879864749
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 57,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 30 Thông Phong",
        "diemDau": "30",
        "diemCuoi": "30-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.02592919013058,
        "to_latitude": 21.02565879864749,
        "to_longitude": 105.83452273922796,
        "from_longitude": 105.83427329379118,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 53,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83442886192107,
          21.02546101197271,
          105.83452273922796,
          21.02565879864749
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83452273922796,
            21.02565879864749
          ],
          [
            105.83442886192107,
            21.02546101197271
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 30 Thông Phong",
        "diemDau": "30-1",
        "diemCuoi": "30-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.02565879864749,
        "to_latitude": 21.02546101197271,
        "to_longitude": 105.83442886192107,
        "from_longitude": 105.83452273922796,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 54,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83461661655285,
          21.025844066938685,
          105.83463539200703,
          21.025944211864896
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83463539200703,
            21.025844066938685
          ],
          [
            105.83461661655285,
            21.025944211864896
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 23+25 Thông Phong",
        "diemDau": "31",
        "diemCuoi": "31-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025844066938685,
        "to_latitude": 21.025944211864896,
        "to_longitude": 105.83461661655285,
        "from_longitude": 105.83463539200703,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 55,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83461661655285,
          21.025944211864896,
          105.83472122270867,
          21.026086918269517
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83461661655285,
            21.025944211864896
          ],
          [
            105.83472122270867,
            21.026086918269517
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 23+25 Thông Phong",
        "diemDau": "31-1",
        "diemCuoi": "31-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025944211864896,
        "to_latitude": 21.026086918269517,
        "to_longitude": 105.83472122270867,
        "from_longitude": 105.83461661655285,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 56,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83472122270867,
          21.026086918269517,
          105.83472122270867,
          21.026154515992122
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83472122270867,
            21.026086918269517
          ],
          [
            105.83472122270867,
            21.026154515992122
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 23+25 Thông Phong",
        "diemDau": "31-2",
        "diemCuoi": "31-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.026086918269517,
        "to_latitude": 21.026154515992122,
        "to_longitude": 105.83472122270867,
        "from_longitude": 105.83472122270867,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 57,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83472122270867,
          21.026079407409735,
          105.83485533315866,
          21.026119465324705
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83472122270867,
            21.026086918269517
          ],
          [
            105.83477486689046,
            21.026079407409735
          ],
          [
            105.83479632456138,
            21.026114458086557
          ],
          [
            105.83485533315866,
            21.026119465324705
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 23+25 Thông Phong",
        "diemDau": "31-2",
        "diemCuoi": "31-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.026086918269517,
        "to_latitude": 21.026119465324705,
        "to_longitude": 105.83485533315866,
        "from_longitude": 105.83472122270867,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 58,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83461661655285,
          21.025916672017235,
          105.83470512945324,
          21.025944211864896
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83461661655285,
            21.025944211864896
          ],
          [
            105.83470512945324,
            21.025916672017235
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 23+25 Thông Phong",
        "diemDau": "31-1",
        "diemCuoi": "31-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025944211864896,
        "to_latitude": 21.025916672017235,
        "to_longitude": 105.83470512945324,
        "from_longitude": 105.83461661655285,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 59,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83470512945324,
          21.025914168393925,
          105.8348177822323,
          21.025916672017235
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83470512945324,
            21.025916672017235
          ],
          [
            105.8348177822323,
            21.025914168393925
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 23+25 Thông Phong",
        "diemDau": "31-5",
        "diemCuoi": "31-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025916672017235,
        "to_latitude": 21.025914168393925,
        "to_longitude": 105.8348177822323,
        "from_longitude": 105.83470512945324,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 60,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8348177822323,
          21.025886628540714,
          105.83493311721911,
          21.025926686507486
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8348177822323,
            21.025914168393925
          ],
          [
            105.8348848374528,
            21.025886628540714
          ],
          [
            105.83493311721911,
            21.025926686507486
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 23+25 Thông Phong",
        "diemDau": "31-6",
        "diemCuoi": "31-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025914168393925,
        "to_latitude": 21.025926686507486,
        "to_longitude": 105.83493311721911,
        "from_longitude": 105.8348177822323,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 61,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83463539200703,
          21.025576178928517,
          105.83473195153964,
          21.025844066938685
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83463539200703,
            21.025844066938685
          ],
          [
            105.83473195153964,
            21.025758943697326
          ],
          [
            105.83465953190365,
            21.025576178928517
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 42 Thông Phong",
        "diemDau": "31",
        "diemCuoi": "31-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025844066938685,
        "to_latitude": 21.025576178928517,
        "to_longitude": 105.83465953190365,
        "from_longitude": 105.83463539200703,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 62,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83462466318504,
          21.025385903040053,
          105.83465953190365,
          21.025576178928517
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83465953190365,
            21.025576178928517
          ],
          [
            105.83462466318504,
            21.025385903040053
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 42 Thông Phong",
        "diemDau": "31-8",
        "diemCuoi": "31-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025576178928517,
        "to_latitude": 21.025385903040053,
        "to_longitude": 105.83462466318504,
        "from_longitude": 105.83465953190365,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 63,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83462466318504,
          21.02534584492793,
          105.83472122270867,
          21.025385903040053
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83462466318504,
            21.025385903040053
          ],
          [
            105.83472122270867,
            21.02534584492793
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 42 Thông Phong",
        "diemDau": "31-9",
        "diemCuoi": "31-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025385903040053,
        "to_latitude": 21.02534584492793,
        "to_longitude": 105.83472122270867,
        "from_longitude": 105.83462466318504,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 64,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8346622141114,
          21.025100488752788,
          105.83472122270867,
          21.02534584492793
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83472122270867,
            21.02534584492793
          ],
          [
            105.8346622141114,
            21.025100488752788
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 42 Thông Phong",
        "diemDau": "31-12",
        "diemCuoi": "31-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.02534584492793,
        "to_latitude": 21.025100488752788,
        "to_longitude": 105.8346622141114,
        "from_longitude": 105.83472122270867,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 65,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83460320551413,
          21.024982817790512,
          105.8346622141114,
          21.025100488752788
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8346622141114,
            21.025100488752788
          ],
          [
            105.83460320551413,
            21.024982817790512
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 42 Thông Phong",
        "diemDau": "31-12",
        "diemCuoi": "31-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025100488752788,
        "to_latitude": 21.024982817790512,
        "to_longitude": 105.83460320551413,
        "from_longitude": 105.8346622141114,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 66,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83446909506414,
          21.025092977842462,
          105.8346622141114,
          21.025128028751247
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8346622141114,
            21.025100488752788
          ],
          [
            105.83455492574782,
            21.025128028751247
          ],
          [
            105.83453615028463,
            21.025092977842462
          ],
          [
            105.83446909506414,
            21.025107999661877
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 42 Thông Phong",
        "diemDau": "31-12",
        "diemCuoi": "31-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025100488752788,
        "to_latitude": 21.025107999661877,
        "to_longitude": 105.83446909506414,
        "from_longitude": 105.8346622141114,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 67,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8345066459905,
          21.025340837662952,
          105.83462466318504,
          21.025395917566794
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83462466318504,
            21.025385903040053
          ],
          [
            105.83459515888191,
            21.025340837662952
          ],
          [
            105.8345066459905,
            21.02536337035361
          ],
          [
            105.8345066459905,
            21.025395917566794
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "9/29/2015 12:00:00 AM",
        "tuDien": "PVT",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 42 Thông Phong",
        "diemDau": "31-9",
        "diemCuoi": "31-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025385903040053,
        "to_latitude": 21.025395917566794,
        "to_longitude": 105.8345066459905,
        "from_longitude": 105.83462466318504,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 68,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8357029112004,
          21.025305786804203,
          105.83576124921429,
          21.025415320711655
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8357029112004,
            21.025305786804203
          ],
          [
            105.83576124921429,
            21.025415320711655
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 53 Thông Phong",
        "diemDau": "35",
        "diemCuoi": "35-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025305786804203,
        "to_latitude": 21.025415320711655,
        "to_longitude": 105.83576124921429,
        "from_longitude": 105.8357029112004,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 69,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83576124921429,
          21.025404054369417,
          105.83588999524879,
          21.025532991332515
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83576124921429,
            21.025415320711655
          ],
          [
            105.83583366887721,
            21.025404054369417
          ],
          [
            105.83588999524879,
            21.025532991332515
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 53 Thông Phong",
        "diemDau": "35-1",
        "diemCuoi": "35-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025415320711655,
        "to_latitude": 21.025532991332515,
        "to_longitude": 105.83588999524879,
        "from_longitude": 105.83576124921429,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 70,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83588999524879,
          21.025532991332515,
          105.83597046151698,
          21.025720763406657
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83588999524879,
            21.025532991332515
          ],
          [
            105.83597046151698,
            21.025720763406657
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 53 Thông Phong",
        "diemDau": "35-2",
        "diemCuoi": "35-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025532991332515,
        "to_latitude": 21.025720763406657,
        "to_longitude": 105.83597046151698,
        "from_longitude": 105.83588999524879,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 71,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83594095721385,
          21.025720763406657,
          105.8360026480458,
          21.025813397543367
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83597046151698,
            21.025720763406657
          ],
          [
            105.8360026480458,
            21.025792116731743
          ],
          [
            105.83594095721385,
            21.025813397543367
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 53 Thông Phong",
        "diemDau": "35-3",
        "diemCuoi": "35-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025720763406657,
        "to_latitude": 21.025813397543367,
        "to_longitude": 105.83594095721385,
        "from_longitude": 105.83597046151698,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 72,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83580952897161,
          21.025708245275794,
          105.83594095721385,
          21.025813397543367
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83594095721385,
            21.025813397543367
          ],
          [
            105.83587390201131,
            21.025799627606
          ],
          [
            105.83580952897161,
            21.025708245275794
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 53 Thông Phong",
        "diemDau": "35-4",
        "diemCuoi": "35-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025813397543367,
        "to_latitude": 21.025708245275794,
        "to_longitude": 105.83580952897161,
        "from_longitude": 105.83594095721385,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 73,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83571565166471,
          21.02549293325908,
          105.83580952897161,
          21.025708245275794
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83580952897161,
            21.025708245275794
          ],
          [
            105.83571565166471,
            21.02549293325908
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 53 Thông Phong",
        "diemDau": "35-6",
        "diemCuoi": "35-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025708245275794,
        "to_latitude": 21.02549293325908,
        "to_longitude": 105.83571565166471,
        "from_longitude": 105.83580952897161,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 74,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83549571052207,
          21.025114884663505,
          105.83555203692954,
          21.025375888513405
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83550710993642,
            21.025375888513405
          ],
          [
            105.83555203692954,
            21.02532143451009
          ],
          [
            105.83549571052207,
            21.025114884663505
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 68 Thông Phong",
        "diemDau": "34",
        "diemCuoi": "34-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025375888513405,
        "to_latitude": 21.025114884663505,
        "to_longitude": 105.83549571052207,
        "from_longitude": 105.83550710993642,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 75,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83541524425388,
          21.02502976100663,
          105.83549571052207,
          21.025114884663505
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83549571052207,
            21.025114884663505
          ],
          [
            105.83544474856598,
            21.02511112920751
          ],
          [
            105.83541524425388,
            21.02502976100663
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 68 Thông Phong",
        "diemDau": "34-1",
        "diemCuoi": "34-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025114884663505,
        "to_latitude": 21.02502976100663,
        "to_longitude": 105.83541524425388,
        "from_longitude": 105.83549571052207,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 76,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83541524425388,
          21.024937126383712,
          105.83564323201973,
          21.02502976100663
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83541524425388,
            21.02502976100663
          ],
          [
            105.83564323201973,
            21.024937126383712
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 68 Thông Phong",
        "diemDau": "34-2",
        "diemCuoi": "34-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.02502976100663,
        "to_latitude": 21.024937126383712,
        "to_longitude": 105.83564323201973,
        "from_longitude": 105.83541524425388,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 77,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83564323201973,
          21.024786907953462,
          105.83569419400277,
          21.024937126383712
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83564323201973,
            21.024937126383712
          ],
          [
            105.83569419400277,
            21.02492335636539
          ],
          [
            105.83564323201973,
            21.024786907953462
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 68 Thông Phong",
        "diemDau": "34-3",
        "diemCuoi": "34-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.024937126383712,
        "to_latitude": 21.024786907953462,
        "to_longitude": 105.83564323201973,
        "from_longitude": 105.83564323201973,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 78,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83502967673375,
          21.025512962297586,
          105.83505582824574,
          21.025628755118124
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83502967673375,
            21.025628755118124
          ],
          [
            105.83505582824574,
            21.025512962297586
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 66 Thông Phong",
        "diemDau": "32",
        "diemCuoi": "38",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025628755118124,
        "to_latitude": 21.025512962297586,
        "to_longitude": 105.83505582824574,
        "from_longitude": 105.83502967673375,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 79,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83495658650541,
          21.025250080960546,
          105.83505582824574,
          21.025512962297586
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83505582824574,
            21.025512962297586
          ],
          [
            105.83495658650541,
            21.025250080960546
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 66 Thông Phong",
        "diemDau": "38",
        "diemCuoi": "39",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025512962297586,
        "to_latitude": 21.025250080960546,
        "to_longitude": 105.83495658650541,
        "from_longitude": 105.83505582824574,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 80,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83488148465271,
          21.025044782832378,
          105.83495658650541,
          21.025250080960546
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83495658650541,
            21.025250080960546
          ],
          [
            105.83488148465271,
            21.025044782832378
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 66 Thông Phong",
        "diemDau": "39",
        "diemCuoi": "40",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025250080960546,
        "to_latitude": 21.025044782832378,
        "to_longitude": 105.83488148465271,
        "from_longitude": 105.83495658650541,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 81,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83488148465271,
          21.0249621627739,
          105.83507728591667,
          21.025044782832378
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83488148465271,
            21.025044782832378
          ],
          [
            105.83507728591667,
            21.0249621627739
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 66 Thông Phong",
        "diemDau": "40",
        "diemCuoi": "41",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025044782832378,
        "to_latitude": 21.0249621627739,
        "to_longitude": 105.83507728591667,
        "from_longitude": 105.83488148465271,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 82,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83507728591667,
          21.02488454995013,
          105.83528918042711,
          21.0249621627739
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83507728591667,
            21.0249621627739
          ],
          [
            105.83528918042711,
            21.02488454995013
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 66 Thông Phong",
        "diemDau": "41",
        "diemCuoi": "42",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.0249621627739,
        "to_latitude": 21.02488454995013,
        "to_longitude": 105.83528918042711,
        "from_longitude": 105.83507728591667,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 83,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83495658650541,
          21.025167461015855,
          105.83513093009847,
          21.025250080960546
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83495658650541,
            21.025250080960546
          ],
          [
            105.83513093009847,
            21.025167461015855
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 66 Thông Phong",
        "diemDau": "39",
        "diemCuoi": "43",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025250080960546,
        "to_latitude": 21.025167461015855,
        "to_longitude": 105.83513093009847,
        "from_longitude": 105.83495658650541,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 84,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83513093009847,
          21.02508734466262,
          105.8353294135612,
          21.025167461015855
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83513093009847,
            21.025167461015855
          ],
          [
            105.8353294135612,
            21.02508734466262
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 66 Thông Phong",
        "diemDau": "43",
        "diemCuoi": "44",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025167461015855,
        "to_latitude": 21.02508734466262,
        "to_longitude": 105.8353294135612,
        "from_longitude": 105.83513093009847,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 85,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83486539140624,
          21.025237562790153,
          105.83495658650541,
          21.025250080960546
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83495658650541,
            21.025250080960546
          ],
          [
            105.83486539140624,
            21.025237562790153
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 64 Thông Phong",
        "diemDau": "39",
        "diemCuoi": "46",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025250080960546,
        "to_latitude": 21.025237562790153,
        "to_longitude": 105.83486539140624,
        "from_longitude": 105.83495658650541,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 86,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83486539140624,
          21.025237562790153,
          105.83488148465271,
          21.02540530618704
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83486539140624,
            21.025237562790153
          ],
          [
            105.83488148465271,
            21.02540530618704
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 64 Thông Phong",
        "diemDau": "46",
        "diemCuoi": "45",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025237562790153,
        "to_latitude": 21.02540530618704,
        "to_longitude": 105.83488148465271,
        "from_longitude": 105.83486539140624,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 87,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83475810304267,
          21.024954651857485,
          105.83486539140624,
          21.025237562790153
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83486539140624,
            21.025237562790153
          ],
          [
            105.83475810304267,
            21.024954651857485
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 64 Thông Phong",
        "diemDau": "46",
        "diemCuoi": "47",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025237562790153,
        "to_latitude": 21.024954651857485,
        "to_longitude": 105.83475810304267,
        "from_longitude": 105.83486539140624,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 88,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83475810304267,
          21.024794418878354,
          105.83498609080853,
          21.024954651857485
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83475810304267,
            21.024954651857485
          ],
          [
            105.83476346747612,
            21.024913341806897
          ],
          [
            105.83498609080853,
            21.024794418878354
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 64 Thông Phong",
        "diemDau": "47",
        "diemCuoi": "48",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.024954651857485,
        "to_latitude": 21.024794418878354,
        "to_longitude": 105.83498609080853,
        "from_longitude": 105.83475810304267,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 89,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83498609080853,
          21.024766878817427,
          105.83517116323256,
          21.024794418878354
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83498609080853,
            21.024794418878354
          ],
          [
            105.83517116323256,
            21.024766878817427
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 64 Thông Phong",
        "diemDau": "48",
        "diemCuoi": "49",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.024794418878354,
        "to_latitude": 21.024766878817427,
        "to_longitude": 105.83517116323256,
        "from_longitude": 105.83498609080853,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 90,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83491903557903,
          21.02470178410926,
          105.83498609080853,
          21.024794418878354
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83498609080853,
            21.024794418878354
          ],
          [
            105.83491903557903,
            21.02470178410926
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 64 Thông Phong",
        "diemDau": "48",
        "diemCuoi": "48-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.024794418878354,
        "to_latitude": 21.02470178410926,
        "to_longitude": 105.83491903557903,
        "from_longitude": 105.83498609080853,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 91,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83486270918952,
          21.024624171149874,
          105.83491903557903,
          21.02470178410926
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83491903557903,
            21.02470178410926
          ],
          [
            105.83486270918952,
            21.024624171149874
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 64 Thông Phong",
        "diemDau": "48-1",
        "diemCuoi": "48-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.02470178410926,
        "to_latitude": 21.024624171149874,
        "to_longitude": 105.83486270918952,
        "from_longitude": 105.83491903557903,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 92,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83486270918952,
          21.024559076378566,
          105.83504778161355,
          21.024624171149874
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83486270918952,
            21.024624171149874
          ],
          [
            105.83504778161355,
            21.024559076378566
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 64 Thông Phong",
        "diemDau": "48-2",
        "diemCuoi": "48-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.024624171149874,
        "to_latitude": 21.024559076378566,
        "to_longitude": 105.83504778161355,
        "from_longitude": 105.83486270918952,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 93,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83504778161355,
          21.02449147793161,
          105.83514434113718,
          21.024559076378566
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83504778161355,
            21.024559076378566
          ],
          [
            105.83514434113718,
            21.02449147793161
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 64 Thông Phong",
        "diemDau": "48-3",
        "diemCuoi": "48-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.024559076378566,
        "to_latitude": 21.02449147793161,
        "to_longitude": 105.83514434113718,
        "from_longitude": 105.83504778161355,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 94,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83502095954513,
          21.025628755118124,
          105.83511148410582,
          21.02577271363678
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83502967673375,
            21.025628755118124
          ],
          [
            105.83502095954513,
            21.025681957195644
          ],
          [
            105.83511148410582,
            21.02577271363678
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 41 Thông Phong",
        "diemDau": "32",
        "diemCuoi": "32-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025628755118124,
        "to_latitude": 21.02577271363678,
        "to_longitude": 105.83511148410582,
        "from_longitude": 105.83502967673375,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 95,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83511148410582,
          21.02577271363678,
          105.83526504054844,
          21.025918549735934
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83511148410582,
            21.02577271363678
          ],
          [
            105.83526504054844,
            21.025918549735934
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 41 Thông Phong",
        "diemDau": "31-1",
        "diemCuoi": "31-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.02577271363678,
        "to_latitude": 21.025918549735934,
        "to_longitude": 105.83526504054844,
        "from_longitude": 105.83511148410582,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 96,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83526504054844,
          21.025918549735934,
          105.83533678966394,
          21.026013061463036
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83526504054844,
            21.025918549735934
          ],
          [
            105.83533678966394,
            21.026013061463036
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 41 Thông Phong",
        "diemDau": "32-2",
        "diemCuoi": "32-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025918549735934,
        "to_latitude": 21.026013061463036,
        "to_longitude": 105.83533678966394,
        "from_longitude": 105.83526504054844,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 97,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83533678966394,
          21.026013061463036,
          105.83548699336936,
          21.026133235230876
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83533678966394,
            21.026013061463036
          ],
          [
            105.83548699336936,
            21.026133235230876
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 41 Thông Phong",
        "diemDau": "32-3",
        "diemCuoi": "32-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.026013061463036,
        "to_latitude": 21.026133235230876,
        "to_longitude": 105.83548699336936,
        "from_longitude": 105.83533678966394,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 98,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83526504054844,
          21.025807764385736,
          105.83546553569843,
          21.025918549735934
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83526504054844,
            21.025918549735934
          ],
          [
            105.83546553569843,
            21.025807764385736
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 41 Thông Phong",
        "diemDau": "32-2",
        "diemCuoi": "32-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025918549735934,
        "to_latitude": 21.025807764385736,
        "to_longitude": 105.83546553569843,
        "from_longitude": 105.83526504054844,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 99,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83546553569843,
          21.025757691885225,
          105.8355734946005,
          21.025827167476116
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83546553569843,
            21.025807764385736
          ],
          [
            105.83549839274777,
            21.025827167476116
          ],
          [
            105.8355734946005,
            21.025789613106348
          ],
          [
            105.83557282405302,
            21.025757691885225
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 41 Thông Phong",
        "diemDau": "32-5",
        "diemCuoi": "32-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025807764385736,
        "to_latitude": 21.025757691885225,
        "to_longitude": 105.83557282405302,
        "from_longitude": 105.83546553569843,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 100,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83557282405302,
          21.0257432960399,
          105.83570224061701,
          21.025757691885225
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83557282405302,
            21.025757691885225
          ],
          [
            105.83570224061701,
            21.0257432960399
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 41 Thông Phong",
        "diemDau": "32-6",
        "diemCuoi": "32-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025757691885225,
        "to_latitude": 21.0257432960399,
        "to_longitude": 105.83570224061701,
        "from_longitude": 105.83557282405302,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 101,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83557282405302,
          21.025757691885225,
          105.83572302775843,
          21.02595798178724
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83557282405302,
            21.025757691885225
          ],
          [
            105.83572302775843,
            21.02595798178724
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 41 Thông Phong",
        "diemDau": "32-6",
        "diemCuoi": "32-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025757691885225,
        "to_latitude": 21.02595798178724,
        "to_longitude": 105.83572302775843,
        "from_longitude": 105.83557282405302,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 102,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8356271387643,
          21.02595798178724,
          105.83572302775843,
          21.02599616202135
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83572302775843,
            21.02595798178724
          ],
          [
            105.8356271387643,
            21.02599616202135
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 41 Thông Phong",
        "diemDau": "32-8",
        "diemCuoi": "32-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.02595798178724,
        "to_latitude": 21.02599616202135,
        "to_longitude": 105.8356271387643,
        "from_longitude": 105.83572302775843,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 103,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83437172139737,
          21.0275781294331,
          105.83449155850681,
          21.027866042566686
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83449155850681,
            21.0275781294331
          ],
          [
            105.83437172139737,
            21.02778811830578
          ],
          [
            105.83438427015223,
            21.027866042566686
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "Tủ điện",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.0275781294331,
        "to_latitude": 21.027866042566686,
        "to_longitude": 105.83438427015223,
        "from_longitude": 105.83449155850681,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 104,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83438427015223,
          21.027866042566686,
          105.83456964059735,
          21.028132535580237
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83438427015223,
            21.027866042566686
          ],
          [
            105.83456964059735,
            21.028132535580237
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.027866042566686,
        "to_latitude": 21.028132535580237,
        "to_longitude": 105.83456964059735,
        "from_longitude": 105.83438427015223,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 105,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83456964059735,
          21.028132535580237,
          105.83475501104245,
          21.02839902811728
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83456964059735,
            21.028132535580237
          ],
          [
            105.83475501104245,
            21.02839902811728
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.028132535580237,
        "to_latitude": 21.02839902811728,
        "to_longitude": 105.83475501104245,
        "from_longitude": 105.83456964059735,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 106,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83475501104245,
          21.02839902811728,
          105.83494038148757,
          21.028665520177814
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83475501104245,
            21.02839902811728
          ],
          [
            105.83494038148757,
            21.028665520177814
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.02839902811728,
        "to_latitude": 21.028665520177814,
        "to_longitude": 105.83494038148757,
        "from_longitude": 105.83475501104245,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 107,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83494038148757,
          21.028665520177814,
          105.83512575193268,
          21.028932011761835
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83494038148757,
            21.028665520177814
          ],
          [
            105.83512575193268,
            21.028932011761835
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.028665520177814,
        "to_latitude": 21.028932011761835,
        "to_longitude": 105.83512575193268,
        "from_longitude": 105.83494038148757,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 108,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83512575193268,
          21.028932011761835,
          105.83531112237782,
          21.029198502869345
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83512575193268,
            21.028932011761835
          ],
          [
            105.83531112237782,
            21.029198502869345
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.028932011761835,
        "to_latitude": 21.029198502869345,
        "to_longitude": 105.83531112237782,
        "from_longitude": 105.83512575193268,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 109,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83531112237782,
          21.029198502869345,
          105.83548934026297,
          21.029468331584386
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83531112237782,
            21.029198502869345
          ],
          [
            105.83548934026297,
            21.029468331584386
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.029198502869345,
        "to_latitude": 21.029468331584386,
        "to_longitude": 105.83548934026297,
        "from_longitude": 105.83531112237782,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 110,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83548934026297,
          21.029468331584386,
          105.83567709489472,
          21.029738716157325
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83548934026297,
            21.029468331584386
          ],
          [
            105.83567709489472,
            21.029738716157325
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.029468331584386,
        "to_latitude": 21.029738716157325,
        "to_longitude": 105.83567709489472,
        "from_longitude": 105.83548934026297,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 111,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83567709489472,
          21.029738716157325,
          105.83586216731878,
          21.029999086022933
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83567709489472,
            21.029738716157325
          ],
          [
            105.83586216731878,
            21.029999086022933
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.029738716157325,
        "to_latitude": 21.029999086022933,
        "to_longitude": 105.83586216731878,
        "from_longitude": 105.83567709489472,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 112,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83586216731878,
          21.029999086022933,
          105.83605260415828,
          21.030264462534166
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83586216731878,
            21.029999086022933
          ],
          [
            105.83605260415828,
            21.030264462534166
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.029999086022933,
        "to_latitude": 21.030264462534166,
        "to_longitude": 105.83605260415828,
        "from_longitude": 105.83586216731878,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 113,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83449155850681,
          21.0275781294331,
          105.83476782603898,
          21.027682967432952
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83449155850681,
            21.0275781294331
          ],
          [
            105.83453533613252,
            21.027682967432952
          ],
          [
            105.83476782603898,
            21.027625697728343
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quốc Tử Giám",
        "diemDau": "Tủ điện",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.0275781294331,
        "to_latitude": 21.027625697728343,
        "to_longitude": 105.83476782603898,
        "from_longitude": 105.83449155850681,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 114,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83443791433402,
          21.027625697728343,
          105.83476782603898,
          21.02775087738018
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83476782603898,
            21.027625697728343
          ],
          [
            105.83443791433402,
            21.02775087738018
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quốc Tử Giám",
        "diemDau": "12",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.027625697728343,
        "to_latitude": 21.02775087738018,
        "to_longitude": 105.83443791433402,
        "from_longitude": 105.83476782603898,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 115,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83476782603898,
          21.027508028759577,
          105.83507628007305,
          21.027625697728343
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83476782603898,
            21.027625697728343
          ],
          [
            105.83507628007305,
            21.027508028759577
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quốc Tử Giám",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.027625697728343,
        "to_latitude": 21.027508028759577,
        "to_longitude": 105.83507628007305,
        "from_longitude": 105.83476782603898,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 116,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83507628007305,
          21.02742040287218,
          105.83535522981298,
          21.027508028759577
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83507628007305,
            21.027508028759577
          ],
          [
            105.83535522981298,
            21.02742040287218
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quốc Tử Giám",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.027508028759577,
        "to_latitude": 21.02742040287218,
        "to_longitude": 105.83535522981298,
        "from_longitude": 105.83507628007305,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 117,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83535522981298,
          21.02733528053188,
          105.8356073574575,
          21.02742040287218
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83535522981298,
            21.02742040287218
          ],
          [
            105.8356073574575,
            21.02733528053188
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quốc Tử Giám",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.02742040287218,
        "to_latitude": 21.02733528053188,
        "to_longitude": 105.8356073574575,
        "from_longitude": 105.83535522981298,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 118,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8356073574575,
          21.027265179744166,
          105.83591312928382,
          21.02733528053188
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8356073574575,
            21.02733528053188
          ],
          [
            105.83591312928382,
            21.027265179744166
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quốc Tử Giám",
        "diemDau": "15",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.02733528053188,
        "to_latitude": 21.027265179744166,
        "to_longitude": 105.83591312928382,
        "from_longitude": 105.8356073574575,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 119,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83591312928382,
          21.027265179744166,
          105.83602309985334,
          21.027538572628742
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83591312928382,
            21.027265179744166
          ],
          [
            105.83602309985334,
            21.027538572628742
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Văn Miếu",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.027265179744166,
        "to_latitude": 21.027538572628742,
        "to_longitude": 105.83602309985334,
        "from_longitude": 105.83591312928382,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 120,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83602309985334,
          21.027538572628742,
          105.8361330704229,
          21.027811965011853
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83602309985334,
            21.027538572628742
          ],
          [
            105.8361330704229,
            21.027811965011853
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Văn Miếu",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.027538572628742,
        "to_latitude": 21.027811965011853,
        "to_longitude": 105.8361330704229,
        "from_longitude": 105.83602309985334,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 121,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8361330704229,
          21.027811965011853,
          105.8362430409924,
          21.028085356893488
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8361330704229,
            21.027811965011853
          ],
          [
            105.8362430409924,
            21.028085356893488
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Văn Miếu",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.027811965011853,
        "to_latitude": 21.028085356893488,
        "to_longitude": 105.8362430409924,
        "from_longitude": 105.8361330704229,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 122,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83552420898157,
          21.02733528053188,
          105.8356073574575,
          21.02739286329643
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8356073574575,
            21.02733528053188
          ],
          [
            105.83552420898157,
            21.02739286329643
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quốc Tử Giám",
        "diemDau": "15",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.02733528053188,
        "to_latitude": 21.02739286329643,
        "to_longitude": 105.83552420898157,
        "from_longitude": 105.8356073574575,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 123,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83507628007305,
          21.027508028759577,
          105.83514869971802,
          21.027520546740142
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83507628007305,
            21.027508028759577
          ],
          [
            105.83514869971802,
            21.027520546740142
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quốc Tử Giám",
        "diemDau": "13",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.027508028759577,
        "to_latitude": 21.027520546740142,
        "to_longitude": 105.83514869971802,
        "from_longitude": 105.83507628007305,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 124,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8362430409924,
          21.028085356893488,
          105.83635301156191,
          21.02835874827364
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8362430409924,
            21.028085356893488
          ],
          [
            105.83635301156191,
            21.02835874827364
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Văn Miếu",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.028085356893488,
        "to_latitude": 21.02835874827364,
        "to_longitude": 105.83635301156191,
        "from_longitude": 105.8362430409924,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 125,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83635301156191,
          21.02835874827364,
          105.83646298213144,
          21.028632139152283
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83635301156191,
            21.02835874827364
          ],
          [
            105.83646298213144,
            21.028632139152283
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Văn Miếu",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.02835874827364,
        "to_latitude": 21.028632139152283,
        "to_longitude": 105.83646298213144,
        "from_longitude": 105.83635301156191,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 126,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83646298213144,
          21.028632139152283,
          105.83657295270096,
          21.02890552952943
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83646298213144,
            21.028632139152283
          ],
          [
            105.83657295270096,
            21.02890552952943
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Văn Miếu",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.028632139152283,
        "to_latitude": 21.02890552952943,
        "to_longitude": 105.83657295270096,
        "from_longitude": 105.83646298213144,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 127,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83657295270096,
          21.02890552952943,
          105.83668292327049,
          21.029178919405076
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83657295270096,
            21.02890552952943
          ],
          [
            105.83668292327049,
            21.029178919405076
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Văn Miếu",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.02890552952943,
        "to_latitude": 21.029178919405076,
        "to_longitude": 105.83668292327049,
        "from_longitude": 105.83657295270096,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 128,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83668292327049,
          21.029178919405076,
          105.83679289384001,
          21.029452308779202
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83668292327049,
            21.029178919405076
          ],
          [
            105.83679289384001,
            21.029452308779202
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Văn Miếu",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.029178919405076,
        "to_latitude": 21.029452308779202,
        "to_longitude": 105.83679289384001,
        "from_longitude": 105.83668292327049,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 129,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83679289384001,
          21.029452308779202,
          105.83690286440954,
          21.029725697651802
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83679289384001,
            21.029452308779202
          ],
          [
            105.83690286440954,
            21.029725697651802
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Văn Miếu",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.029452308779202,
        "to_latitude": 21.029725697651802,
        "to_longitude": 105.83690286440954,
        "from_longitude": 105.83679289384001,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 130,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83690286440954,
          21.029725697651802,
          105.83701283497906,
          21.029999086022933
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83690286440954,
            21.029725697651802
          ],
          [
            105.83701283497906,
            21.029999086022933
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Văn Miếu",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.029725697651802,
        "to_latitude": 21.029999086022933,
        "to_longitude": 105.83701283497906,
        "from_longitude": 105.83690286440954,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 131,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83690286440954,
          21.02961823235291,
          105.83691981994703,
          21.029725697651802
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83690286440954,
            21.029725697651802
          ],
          [
            105.83691981994703,
            21.02961823235291
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thanh Miến",
        "diemDau": "27",
        "diemCuoi": "27-1",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.029725697651802,
        "to_latitude": 21.02961823235291,
        "to_longitude": 105.83691981994703,
        "from_longitude": 105.83690286440954,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 132,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83691981994703,
          21.0295268523606,
          105.83708693105103,
          21.02961823235291
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83691981994703,
            21.02961823235291
          ],
          [
            105.83708693105103,
            21.0295268523606
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thanh Miến",
        "diemDau": "27",
        "diemCuoi": "27-1",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.02961823235291,
        "to_latitude": 21.0295268523606,
        "to_longitude": 105.83708693105103,
        "from_longitude": 105.83691981994703,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 133,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83708693105103,
          21.02941919914923,
          105.83739270287735,
          21.0295268523606
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83708693105103,
            21.0295268523606
          ],
          [
            105.83739270287735,
            21.02941919914923
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thanh Miến",
        "diemDau": "27-1",
        "diemCuoi": "27-2",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.0295268523606,
        "to_latitude": 21.02941919914923,
        "to_longitude": 105.83739270287735,
        "from_longitude": 105.83708693105103,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 134,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83739270287735,
          21.029321560123137,
          105.83751258814549,
          21.02941919914923
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83739270287735,
            21.02941919914923
          ],
          [
            105.83751258814549,
            21.02938289746957
          ],
          [
            105.83750803786415,
            21.029321560123137
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thanh Miến",
        "diemDau": "27-2",
        "diemCuoi": "27-3",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.02941919914923,
        "to_latitude": 21.029321560123137,
        "to_longitude": 105.83750803786415,
        "from_longitude": 105.83739270287735,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 135,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83750803786415,
          21.02921641033054,
          105.83785404282457,
          21.029321560123137
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83750803786415,
            21.029321560123137
          ],
          [
            105.83785404282457,
            21.02921641033054
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thanh Miến",
        "diemDau": "27-3",
        "diemCuoi": "27-4",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.029321560123137,
        "to_latitude": 21.02921641033054,
        "to_longitude": 105.83785404282457,
        "from_longitude": 105.83750803786415,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 136,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83785404282457,
          21.02919012287247,
          105.8380525262963,
          21.02921641033054
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83785404282457,
            21.02921641033054
          ],
          [
            105.83797661030044,
            21.02919012287247
          ],
          [
            105.8380525262963,
            21.029213906763513
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thanh Miến",
        "diemDau": "27-5",
        "diemCuoi": "27-5",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.02921641033054,
        "to_latitude": 21.029213906763513,
        "to_longitude": 105.8380525262963,
        "from_longitude": 105.83785404282457,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 137,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8380525262963,
          21.029213906763513,
          105.8382349165036,
          21.029316552991727
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8380525262963,
            21.029213906763513
          ],
          [
            105.8382349165036,
            21.029316552991727
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thanh Miến",
        "diemDau": "27-5",
        "diemCuoi": "27-6",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.029213906763513,
        "to_latitude": 21.029316552991727,
        "to_longitude": 105.8382349165036,
        "from_longitude": 105.8380525262963,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 138,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83700646477386,
          21.029421702712835,
          105.83708693105103,
          21.0295268523606
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83708693105103,
            21.0295268523606
          ],
          [
            105.8370458837738,
            21.029515586332476
          ],
          [
            105.83700646477386,
            21.029421702712835
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thanh Miến",
        "diemDau": "27-1",
        "diemCuoi": "27-10",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.0295268523606,
        "to_latitude": 21.029421702712835,
        "to_longitude": 105.83700646477386,
        "from_longitude": 105.83708693105103,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 139,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83708693105103,
          21.029386652814615,
          105.83713171445747,
          21.0295268523606
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83708693105103,
            21.0295268523606
          ],
          [
            105.83713171445747,
            21.02948304001804
          ],
          [
            105.83710838872194,
            21.029386652814615
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thanh Miến",
        "diemDau": "27-1",
        "diemCuoi": "27-11",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.0295268523606,
        "to_latitude": 21.029386652814615,
        "to_longitude": 105.83710838872194,
        "from_longitude": 105.83708693105103,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 140,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83707807028466,
          21.029276495935154,
          105.8371244819774,
          21.029386652814615
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83710838872194,
            21.029386652814615
          ],
          [
            105.83707807028466,
            21.029292769117905
          ],
          [
            105.8371244819774,
            21.029276495935154
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thanh Miến",
        "diemDau": "27-11",
        "diemCuoi": "27-12",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.029386652814615,
        "to_latitude": 21.029276495935154,
        "to_longitude": 105.8371244819774,
        "from_longitude": 105.83710838872194,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 141,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83780844527499,
          21.029088728340504,
          105.83785404282457,
          21.02921641033054
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83785404282457,
            21.02921641033054
          ],
          [
            105.83780844527499,
            21.029088728340504
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thanh Miến",
        "diemDau": "27-4",
        "diemCuoi": "27-8",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.02921641033054,
        "to_latitude": 21.029088728340504,
        "to_longitude": 105.83780844527499,
        "from_longitude": 105.83785404282457,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 142,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83773334342227,
          21.029088728340504,
          105.83780844527499,
          21.02911626760292
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83780844527499,
            21.029088728340504
          ],
          [
            105.83773334342227,
            21.02911626760292
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thanh Miến",
        "diemDau": "27-8",
        "diemCuoi": "27-7",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.029088728340504,
        "to_latitude": 21.02911626760292,
        "to_longitude": 105.83773334342227,
        "from_longitude": 105.83780844527499,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 143,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83780844527499,
          21.029086224771316,
          105.83796133118815,
          21.029122526526606
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83780844527499,
            21.029088728340504
          ],
          [
            105.83786127531364,
            21.029122526526606
          ],
          [
            105.83796133118815,
            21.029086224771316
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thanh Miến",
        "diemDau": "27-8",
        "diemCuoi": "27-9",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.029088728340504,
        "to_latitude": 21.029086224771316,
        "to_longitude": 105.83796133118815,
        "from_longitude": 105.83780844527499,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 144,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83646298213144,
          21.028632139152283,
          105.8366795705189,
          21.028684401317914
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83646298213144,
            21.028632139152283
          ],
          [
            105.83656040393892,
            21.028684401317914
          ],
          [
            105.8366795705189,
            21.028662807968868
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Khuyến",
        "diemDau": "23",
        "diemCuoi": "29",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.028632139152283,
        "to_latitude": 21.028662807968868,
        "to_longitude": 105.8366795705189,
        "from_longitude": 105.83646298213144,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 145,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8366795705189,
          21.02860772927221,
          105.8370175288471,
          21.028662807968868
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8366795705189,
            21.028662807968868
          ],
          [
            105.8370175288471,
            21.02860772927221
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Khuyến",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.028662807968868,
        "to_latitude": 21.02860772927221,
        "to_longitude": 105.8370175288471,
        "from_longitude": 105.8366795705189,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 146,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8370175288471,
          21.028562664869227,
          105.83727233870836,
          21.02860772927221
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8370175288471,
            21.02860772927221
          ],
          [
            105.83727233870836,
            21.028562664869227
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Khuyến",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.02860772927221,
        "to_latitude": 21.028562664869227,
        "to_longitude": 105.83727233870836,
        "from_longitude": 105.8370175288471,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 147,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83727233870836,
          21.02852010403134,
          105.83752178414514,
          21.028562664869227
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83727233870836,
            21.028562664869227
          ],
          [
            105.83752178414514,
            21.02852010403134
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Khuyến",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.028562664869227,
        "to_latitude": 21.02852010403134,
        "to_longitude": 105.83752178414514,
        "from_longitude": 105.83727233870836,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 148,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83752178414514,
          21.028460018121763,
          105.83779536946061,
          21.02852010403134
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83752178414514,
            21.02852010403134
          ],
          [
            105.83779536946061,
            21.028460018121763
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Khuyến",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.02852010403134,
        "to_latitude": 21.028460018121763,
        "to_longitude": 105.83779536946061,
        "from_longitude": 105.83752178414514,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 149,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83779536946061,
          21.028392421443918,
          105.83809577687143,
          21.028460018121763
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83779536946061,
            21.028460018121763
          ],
          [
            105.83809577687143,
            21.028392421443918
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Khuyến",
        "diemDau": "33",
        "diemCuoi": "34",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.028460018121763,
        "to_latitude": 21.028392421443918,
        "to_longitude": 105.83809577687143,
        "from_longitude": 105.83779536946061,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 150,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83809577687143,
          21.028327328319058,
          105.83839081986677,
          21.028392421443918
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83809577687143,
            21.028392421443918
          ],
          [
            105.83839081986677,
            21.028327328319058
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Khuyến",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.028392421443918,
        "to_latitude": 21.028327328319058,
        "to_longitude": 105.83839081986677,
        "from_longitude": 105.83809577687143,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 151,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83699372429157,
          21.028370828055063,
          105.8370175288471,
          21.02860772927221
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8370175288471,
            21.02860772927221
          ],
          [
            105.83699372429157,
            21.028370828055063
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Nguyễn Khuyến",
        "diemDau": "30",
        "diemCuoi": "30-1",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.02860772927221,
        "to_latitude": 21.028370828055063,
        "to_longitude": 105.83699372429157,
        "from_longitude": 105.8370175288471,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 152,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83699372429157,
          21.028120469687725,
          105.83701249975474,
          21.028370828055063
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83699372429157,
            21.028370828055063
          ],
          [
            105.83701249975474,
            21.028120469687725
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Nguyễn Khuyến",
        "diemDau": "30-1",
        "diemCuoi": "30-2",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.028370828055063,
        "to_latitude": 21.028120469687725,
        "to_longitude": 105.83701249975474,
        "from_longitude": 105.83699372429157,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 153,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83684620280289,
          21.028120469687725,
          105.83701249975474,
          21.02813298761596
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83701249975474,
            21.028120469687725
          ],
          [
            105.83684620280289,
            21.02813298761596
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Nguyễn Khuyến",
        "diemDau": "30-2",
        "diemCuoi": "30-3",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.028120469687725,
        "to_latitude": 21.02813298761596,
        "to_longitude": 105.83684620280289,
        "from_longitude": 105.83701249975474,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 154,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83681133408426,
          21.02813298761596,
          105.83684620280289,
          21.028250656091338
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83684620280289,
            21.02813298761596
          ],
          [
            105.83681133408426,
            21.028250656091338
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Nguyễn Khuyến",
        "diemDau": "30-3",
        "diemCuoi": "30-4",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.02813298761596,
        "to_latitude": 21.028250656091338,
        "to_longitude": 105.83681133408426,
        "from_longitude": 105.83684620280289,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 155,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83686081135873,
          21.02860772927221,
          105.8370175288471,
          21.028854644409677
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8370175288471,
            21.02860772927221
          ],
          [
            105.83686081135873,
            21.028734472836078
          ],
          [
            105.83689568006838,
            21.028854644409677
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 144 Nguyễn Khuyến",
        "diemDau": "30",
        "diemCuoi": "30-5",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.02860772927221,
        "to_latitude": 21.028854644409677,
        "to_longitude": 105.83689568006838,
        "from_longitude": 105.8370175288471,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 156,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83689568006838,
          21.028854644409677,
          105.836930548787,
          21.028977319458285
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83689568006838,
            21.028854644409677
          ],
          [
            105.836930548787,
            21.028977319458285
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 144 Nguyễn Khuyến",
        "diemDau": "30-5",
        "diemCuoi": "30-6",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.028854644409677,
        "to_latitude": 21.028977319458285,
        "to_longitude": 105.836930548787,
        "from_longitude": 105.83689568006838,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 157,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83689568006838,
          21.028854644409677,
          105.83690640890832,
          21.02904741944071
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83689568006838,
            21.028854644409677
          ],
          [
            105.83690640890832,
            21.02904741944071
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 144 Nguyễn Khuyến",
        "diemDau": "30-5",
        "diemCuoi": "30-7",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.028854644409677,
        "to_latitude": 21.02904741944071,
        "to_longitude": 105.83690640890832,
        "from_longitude": 105.83689568006838,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 158,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83690640890832,
          21.02901487302404,
          105.83705661261374,
          21.029057433720627
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83690640890832,
            21.02904741944071
          ],
          [
            105.8369439598347,
            21.029057433720627
          ],
          [
            105.83705661261374,
            21.029044915870006
          ],
          [
            105.83704856598152,
            21.02901487302404
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 144 Nguyễn Khuyến",
        "diemDau": "30-7",
        "diemCuoi": "30-8",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.02904741944071,
        "to_latitude": 21.02901487302404,
        "to_longitude": 105.83704856598152,
        "from_longitude": 105.83690640890832,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 159,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83727233870836,
          21.028562664869227,
          105.83740410227372,
          21.028716321910608
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83727233870836,
            21.028562664869227
          ],
          [
            105.83740261757416,
            21.028634329784612
          ],
          [
            105.83740410227372,
            21.028716321910608
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chùa Bà Ngô",
        "diemDau": "31",
        "diemCuoi": "31-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.028562664869227,
        "to_latitude": 21.028716321910608,
        "to_longitude": 105.83740410227372,
        "from_longitude": 105.83727233870836,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 160,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8361330704229,
          21.027811965011853,
          105.83624505265807,
          21.027886697182577
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8361330704229,
            21.027811965011853
          ],
          [
            105.83619025909987,
            21.02787324038995
          ],
          [
            105.83624505265807,
            21.027886697182577
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngô Tất Tố",
        "diemDau": "20",
        "diemCuoi": "36",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.027811965011853,
        "to_latitude": 21.027886697182577,
        "to_longitude": 105.83624505265807,
        "from_longitude": 105.8361330704229,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 161,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83624505265807,
          21.027733978132947,
          105.83666884167896,
          21.027886697182577
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83624505265807,
            21.027886697182577
          ],
          [
            105.83666884167896,
            21.027733978132947
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngô Tất Tố",
        "diemDau": "36",
        "diemCuoi": "37",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.027886697182577,
        "to_latitude": 21.027733978132947,
        "to_longitude": 105.83666884167896,
        "from_longitude": 105.83624505265807,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 162,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83666884167896,
          21.027616309249673,
          105.83699607117619,
          21.027733978132947
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83666884167896,
            21.027733978132947
          ],
          [
            105.83699607117619,
            21.027616309249673
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngô Tất Tố",
        "diemDau": "37",
        "diemCuoi": "38",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.027733978132947,
        "to_latitude": 21.027616309249673,
        "to_longitude": 105.83699607117619,
        "from_longitude": 105.83666884167896,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 163,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83699607117619,
          21.027553719380823,
          105.83717309697698,
          21.027616309249673
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83699607117619,
            21.027616309249673
          ],
          [
            105.83717309697698,
            21.027553719380823
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngô Tất Tố",
        "diemDau": "38",
        "diemCuoi": "39",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.027616309249673,
        "to_latitude": 21.027553719380823,
        "to_longitude": 105.83717309697698,
        "from_longitude": 105.83699607117619,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 164,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83717309697698,
          21.027448568340986,
          105.83758079274241,
          21.027553719380823
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83717309697698,
            21.027553719380823
          ],
          [
            105.83758079274241,
            21.027448568340986
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngô Tất Tố",
        "diemDau": "39",
        "diemCuoi": "40",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.027553719380823,
        "to_latitude": 21.027448568340986,
        "to_longitude": 105.83758079274241,
        "from_longitude": 105.83717309697698,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 165,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83758079274241,
          21.027448568340986,
          105.83765321238738,
          21.02761130206097
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83758079274241,
            21.027448568340986
          ],
          [
            105.83765321238738,
            21.02761130206097
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 18 Ngô Tất Tố",
        "diemDau": "40",
        "diemCuoi": "40-3",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.027448568340986,
        "to_latitude": 21.02761130206097,
        "to_longitude": 105.83765321238738,
        "from_longitude": 105.83758079274241,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 166,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83758079274241,
          21.027448568340986,
          105.83773636086333,
          21.027678899093097
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83758079274241,
            21.027448568340986
          ],
          [
            105.83773636086333,
            21.027678899093097
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 18 Ngô Tất Tố",
        "diemDau": "40",
        "diemCuoi": "40-4",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.027448568340986,
        "to_latitude": 21.027678899093097,
        "to_longitude": 105.83773636086333,
        "from_longitude": 105.83758079274241,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 167,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83746545775561,
          21.02727582004351,
          105.83758079274241,
          21.027448568340986
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83758079274241,
            21.027448568340986
          ],
          [
            105.83746545775561,
            21.02727582004351
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 18 Ngô Tất Tố",
        "diemDau": "40",
        "diemCuoi": "40-1",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.027448568340986,
        "to_latitude": 21.02727582004351,
        "to_longitude": 105.83746545775561,
        "from_longitude": 105.83758079274241,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 168,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83733671172111,
          21.027030467046036,
          105.83746545775561,
          21.02727582004351
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83746545775561,
            21.02727582004351
          ],
          [
            105.83733671172111,
            21.027030467046036
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 18 Ngô Tất Tố",
        "diemDau": "40-1",
        "diemCuoi": "40-2",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.02727582004351,
        "to_latitude": 21.027030467046036,
        "to_longitude": 105.83733671172111,
        "from_longitude": 105.83746545775561,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 169,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8371596859293,
          21.02745858272917,
          105.83724252092289,
          21.027553719380823
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83717309697698,
            21.027553719380823
          ],
          [
            105.83724252092289,
            21.027535813877304
          ],
          [
            105.8371596859293,
            21.02745858272917
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Ngô Tất Tố",
        "diemDau": "39",
        "diemCuoi": "39-1",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.027553719380823,
        "to_latitude": 21.02745858272917,
        "to_longitude": 105.8371596859293,
        "from_longitude": 105.83717309697698,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 170,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8370255754793,
          21.02725078404194,
          105.8371596859293,
          21.02745858272917
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8371596859293,
            21.02745858272917
          ],
          [
            105.8370255754793,
            21.02725078404194
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Ngô Tất Tố",
        "diemDau": "39-1",
        "diemCuoi": "39-2",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.02745858272917,
        "to_latitude": 21.02725078404194,
        "to_longitude": 105.8370255754793,
        "from_longitude": 105.8371596859293,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 171,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83699607117619,
          21.027616309249673,
          105.83714091046613,
          21.02764384878414
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83699607117619,
            21.027616309249673
          ],
          [
            105.83714091046613,
            21.02764384878414
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 115 Nguyễn Khuyến",
        "diemDau": "38",
        "diemCuoi": "38-1",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.027616309249673,
        "to_latitude": 21.02764384878414,
        "to_longitude": 105.83714091046613,
        "from_longitude": 105.83699607117619,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 172,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83714091046613,
          21.02764384878414,
          105.83725356324518,
          21.028011876615167
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83714091046613,
            21.02764384878414
          ],
          [
            105.83725356324518,
            21.028011876615167
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 115 Nguyễn Khuyến",
        "diemDau": "38-1",
        "diemCuoi": "38-2",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.02764384878414,
        "to_latitude": 21.028011876615167,
        "to_longitude": 105.83725356324518,
        "from_longitude": 105.83714091046613,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 173,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83725356324518,
          21.028011876615167,
          105.83731793625795,
          21.02829728532845
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83725356324518,
            21.028011876615167
          ],
          [
            105.83731793625795,
            21.02829728532845
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 115 Nguyễn Khuyến",
        "diemDau": "38-2",
        "diemCuoi": "38-3",
        "chuDauTu": "",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.028011876615167,
        "to_latitude": 21.02829728532845,
        "to_longitude": 105.83731793625795,
        "from_longitude": 105.83725356324518,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 174,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83449155850681,
          21.02741257913213,
          105.8349483963314,
          21.0275781294331
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83449155850681,
            21.0275781294331
          ],
          [
            105.8349483963314,
            21.02741257913213
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 60,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 63+65+67 Quốc Tử Giám",
        "diemDau": "tủ điện",
        "diemCuoi": "12-8",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.0275781294331,
        "to_latitude": 21.02741257913213,
        "to_longitude": 105.8349483963314,
        "from_longitude": 105.83449155850681,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 175,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83450396376482,
          21.027373773360722,
          105.83457288706785,
          21.027559989348955
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8345416162876,
            21.027559989348955
          ],
          [
            105.83457288706785,
            21.027477672656488
          ],
          [
            105.83450396376482,
            21.027373773360722
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 63+65+67 Quốc Tử Giám",
        "diemDau": "tủ điện",
        "diemCuoi": "12-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.027559989348955,
        "to_latitude": 21.027373773360722,
        "to_longitude": 105.83450396376482,
        "from_longitude": 105.8345416162876,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 176,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83450396376482,
          21.027278636594204,
          105.83462198095933,
          21.027373773360722
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83450396376482,
            21.027373773360722
          ],
          [
            105.83462198095933,
            21.027278636594204
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 63+65+67 Quốc Tử Giám",
        "diemDau": "12-1",
        "diemCuoi": "12-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.027373773360722,
        "to_latitude": 21.027278636594204,
        "to_longitude": 105.83462198095933,
        "from_longitude": 105.83450396376482,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 177,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83468367177333,
          21.027348737375625,
          105.83474876313683,
          21.027484922848007
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83474876313683,
            21.027484922848007
          ],
          [
            105.83468367177333,
            21.027348737375625
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 63+65+67 Quốc Tử Giám",
        "diemDau": "tủ điện",
        "diemCuoi": "12-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.027484922848007,
        "to_latitude": 21.027348737375625,
        "to_longitude": 105.83468367177333,
        "from_longitude": 105.83474876313683,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 178,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83468367177333,
          21.02726361499439,
          105.83475340920158,
          21.027348737375625
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83468367177333,
            21.027348737375625
          ],
          [
            105.83475340920158,
            21.02726361499439
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 63+65+67 Quốc Tử Giám",
        "diemDau": "12-4",
        "diemCuoi": "12-5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.027348737375625,
        "to_latitude": 21.02726361499439,
        "to_longitude": 105.83475340920158,
        "from_longitude": 105.83468367177333,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 179,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83475340920158,
          21.027206032179095,
          105.83482582884653,
          21.02726361499439
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83475340920158,
            21.02726361499439
          ],
          [
            105.83482582884653,
            21.027206032179095
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 63+65+67 Quốc Tử Giám",
        "diemDau": "12-5",
        "diemCuoi": "12-6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.02726361499439,
        "to_latitude": 21.027206032179095,
        "to_longitude": 105.83482582884653,
        "from_longitude": 105.83475340920158,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 180,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8346809895656,
          21.02714093853523,
          105.83475340920158,
          21.02726361499439
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83475340920158,
            21.02726361499439
          ],
          [
            105.8346809895656,
            21.02714093853523
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 63+65+67 Quốc Tử Giám",
        "diemDau": "12-5",
        "diemCuoi": "12-7",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.02726361499439,
        "to_latitude": 21.02714093853523,
        "to_longitude": 105.8346809895656,
        "from_longitude": 105.83475340920158,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 181,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8349331172101,
          21.02726361499439,
          105.8349483963314,
          21.02741257913213
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8349483963314,
            21.02741257913213
          ],
          [
            105.8349331172101,
            21.02726361499439
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 63+65+67 Quốc Tử Giám",
        "diemDau": "tủ điện",
        "diemCuoi": "12-8",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.02741257913213,
        "to_latitude": 21.02726361499439,
        "to_longitude": 105.8349331172101,
        "from_longitude": 105.8349483963314,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 182,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83441813308112,
          21.02731118339001,
          105.83450396376482,
          21.02739161149968
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83450396376482,
            21.027373773360722
          ],
          [
            105.83446740918686,
            21.02739161149968
          ],
          [
            105.83441813308112,
            21.02731118339001
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QTG",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 63+65+67 Quốc Tử Giám",
        "diemDau": "12-1",
        "diemCuoi": "12-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Quốc Tử Giám",
        "from_latitude": 21.027373773360722,
        "to_latitude": 21.02731118339001,
        "to_longitude": 105.83441813308112,
        "from_longitude": 105.83450396376482,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 183,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8295779432075,
          21.03157412624473,
          105.82958062541525,
          21.031691792002245
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8295779432075,
            21.03157412624473
          ],
          [
            105.82958062541525,
            21.031691792002245
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TBLLB",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Giảng Võ",
        "diemDau": "Tủ điện",
        "diemCuoi": "01",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TBL Long Biên",
        "from_latitude": 21.03157412624473,
        "to_latitude": 21.031691792002245,
        "to_longitude": 105.82958062541525,
        "from_longitude": 105.8295779432075,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 184,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82924534929478,
          21.031691792002245,
          105.82958062541525,
          21.031784422427762
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82958062541525,
            21.031691792002245
          ],
          [
            105.82924534929478,
            21.031784422427762
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TBLLB",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Giảng Võ",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TBL Long Biên",
        "from_latitude": 21.031691792002245,
        "to_latitude": 21.031784422427762,
        "to_longitude": 105.82924534929478,
        "from_longitude": 105.82958062541525,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 185,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82893421304398,
          21.031784422427762,
          105.82924534929478,
          21.031882059840193
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82924534929478,
            21.031784422427762
          ],
          [
            105.82893421304398,
            21.031882059840193
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TBLLB",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Giảng Võ",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TBL Long Biên",
        "from_latitude": 21.031784422427762,
        "to_latitude": 21.031882059840193,
        "to_longitude": 105.82893421304398,
        "from_longitude": 105.82924534929478,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 186,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82901736152891,
          21.031491509805672,
          105.8295779432075,
          21.031694295528474
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8295779432075,
            21.03157412624473
          ],
          [
            105.82954307448887,
            21.03161167915608
          ],
          [
            105.82922389162384,
            21.031694295528474
          ],
          [
            105.82901736152891,
            21.031491509805672
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TBLLB",
        "chieuDaiQL": 80,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Giảng Võ",
        "diemDau": "Tủ điện",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TBL Long Biên",
        "from_latitude": 21.03157412624473,
        "to_latitude": 21.031491509805672,
        "to_longitude": 105.82901736152891,
        "from_longitude": 105.8295779432075,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 187,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82901736152891,
          21.031306248533784,
          105.82911928546801,
          21.031491509805672
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82901736152891,
            21.031491509805672
          ],
          [
            105.82911928546801,
            21.031306248533784
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TBLLB",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Giảng Võ",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TBL Long Biên",
        "from_latitude": 21.031491509805672,
        "to_latitude": 21.031306248533784,
        "to_longitude": 105.82911928546801,
        "from_longitude": 105.82901736152891,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 188,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82901736152891,
          21.03119609307414,
          105.82911928546801,
          21.031306248533784
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82911928546801,
            21.031306248533784
          ],
          [
            105.82901736152891,
            21.03119609307414
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TBLLB",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Giảng Võ",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TBL Long Biên",
        "from_latitude": 21.031306248533784,
        "to_latitude": 21.03119609307414,
        "to_longitude": 105.82901736152891,
        "from_longitude": 105.82911928546801,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 189,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82886984003123,
          21.03119609307414,
          105.82901736152891,
          21.031336290917825
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82886984003123,
            21.031336290917825
          ],
          [
            105.82901736152891,
            21.03119609307414
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TBLLB",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Giảng Võ",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TBL Long Biên",
        "from_latitude": 21.031336290917825,
        "to_latitude": 21.03119609307414,
        "to_longitude": 105.82901736152891,
        "from_longitude": 105.82886984003123,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 190,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82865258109635,
          21.031193589539512,
          105.82886984003123,
          21.031336290917825
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82886984003123,
            21.031336290917825
          ],
          [
            105.82865258109635,
            21.031193589539512
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TBLLB",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Giảng Võ",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TBL Long Biên",
        "from_latitude": 21.031336290917825,
        "to_latitude": 21.031193589539512,
        "to_longitude": 105.82865258109635,
        "from_longitude": 105.82886984003123,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 191,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8283333982313,
          21.031105965819073,
          105.82865258109635,
          21.031193589539512
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82865258109635,
            21.031193589539512
          ],
          [
            105.8283333982313,
            21.031105965819073
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TBLLB",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Giảng Võ",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TBL Long Biên",
        "from_latitude": 21.031193589539512,
        "to_latitude": 21.031105965819073,
        "to_longitude": 105.8283333982313,
        "from_longitude": 105.82865258109635,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 192,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82898249281028,
          21.031491509805672,
          105.82901736152891,
          21.031619189737075
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82901736152891,
            21.031491509805672
          ],
          [
            105.82898249281028,
            21.031619189737075
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TBLLB",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Giảng Võ",
        "diemDau": "4",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TBL Long Biên",
        "from_latitude": 21.031491509805672,
        "to_latitude": 21.031619189737075,
        "to_longitude": 105.82898249281028,
        "from_longitude": 105.82901736152891,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 193,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83569047402928,
          21.0300611532905,
          105.8358769769625,
          21.03032423477068
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8358769769625,
            21.03032423477068
          ],
          [
            105.83569047402928,
            21.0300611532905
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.03032423477068,
        "to_latitude": 21.0300611532905,
        "to_longitude": 105.83569047402928,
        "from_longitude": 105.8358769769625,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 194,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83550397109605,
          21.0297980713459,
          105.83569047402928,
          21.0300611532905
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83569047402928,
            21.0300611532905
          ],
          [
            105.83550397109605,
            21.0297980713459
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.0300611532905,
        "to_latitude": 21.0297980713459,
        "to_longitude": 105.83550397109605,
        "from_longitude": 105.83569047402928,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 195,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83531746816281,
          21.02953498893683,
          105.83550397109605,
          21.0297980713459
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83550397109605,
            21.0297980713459
          ],
          [
            105.83531746816281,
            21.02953498893683
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.0297980713459,
        "to_latitude": 21.02953498893683,
        "to_longitude": 105.83531746816281,
        "from_longitude": 105.83550397109605,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 196,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83515868138424,
          21.02931342353524,
          105.83531746816281,
          21.02953498893683
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83531746816281,
            21.02953498893683
          ],
          [
            105.83515868138424,
            21.02931342353524
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.02953498893683,
        "to_latitude": 21.02931342353524,
        "to_longitude": 105.83515868138424,
        "from_longitude": 105.83531746816281,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 197,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83494446229636,
          21.0290088227255,
          105.83515868138424,
          21.02931342353524
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83515868138424,
            21.02931342353524
          ],
          [
            105.83494446229636,
            21.0290088227255
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.02931342353524,
        "to_latitude": 21.0290088227255,
        "to_longitude": 105.83494446229636,
        "from_longitude": 105.83515868138424,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 198,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83475795936315,
          21.028745738923195,
          105.83494446229636,
          21.0290088227255
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83494446229636,
            21.0290088227255
          ],
          [
            105.83475795936315,
            21.028745738923195
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.0290088227255,
        "to_latitude": 21.028745738923195,
        "to_longitude": 105.83475795936315,
        "from_longitude": 105.83494446229636,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 199,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8345714564299,
          21.02848265465654,
          105.83475795936315,
          21.028745738923195
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83475795936315,
            21.028745738923195
          ],
          [
            105.8345714564299,
            21.02848265465654
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.028745738923195,
        "to_latitude": 21.02848265465654,
        "to_longitude": 105.8345714564299,
        "from_longitude": 105.83475795936315,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 200,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83438495349667,
          21.028219569925458,
          105.8345714564299,
          21.02848265465654
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8345714564299,
            21.02848265465654
          ],
          [
            105.83438495349667,
            21.028219569925458
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.02848265465654,
        "to_latitude": 21.028219569925458,
        "to_longitude": 105.83438495349667,
        "from_longitude": 105.8345714564299,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 201,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83419845056345,
          21.02795648472999,
          105.83438495349667,
          21.028219569925458
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83438495349667,
            21.028219569925458
          ],
          [
            105.83419845056345,
            21.02795648472999
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.028219569925458,
        "to_latitude": 21.02795648472999,
        "to_longitude": 105.83419845056345,
        "from_longitude": 105.83438495349667,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 202,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83387899948842,
          21.02792706756326,
          105.83419845056345,
          21.028009685975793
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83419845056345,
            21.02795648472999
          ],
          [
            105.83413863465921,
            21.02792706756326
          ],
          [
            105.83387899948842,
            21.028009685975793
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.02795648472999,
        "to_latitude": 21.028009685975793,
        "to_longitude": 105.83387899948842,
        "from_longitude": 105.83419845056345,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 203,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83349812580938,
          21.028009685975793,
          105.83387899948842,
          21.028157397572535
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83387899948842,
            21.028009685975793
          ],
          [
            105.83349812580938,
            21.028157397572535
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.028009685975793,
        "to_latitude": 21.028157397572535,
        "to_longitude": 105.83349812580938,
        "from_longitude": 105.83387899948842,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 204,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83324868036361,
          21.028157397572535,
          105.83349812580938,
          21.02824752661083
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83349812580938,
            21.028157397572535
          ],
          [
            105.83324868036361,
            21.02824752661083
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.028157397572535,
        "to_latitude": 21.02824752661083,
        "to_longitude": 105.83324868036361,
        "from_longitude": 105.83349812580938,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 205,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83298743320557,
          21.02824752661083,
          105.83324868036361,
          21.028347669922844
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83324868036361,
            21.02824752661083
          ],
          [
            105.83298743320557,
            21.028347669922844
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.02824752661083,
        "to_latitude": 21.028347669922844,
        "to_longitude": 105.83298743320557,
        "from_longitude": 105.83324868036361,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 206,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83272618604752,
          21.028347669922844,
          105.83298743320557,
          21.02844781316759
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83298743320557,
            21.028347669922844
          ],
          [
            105.83272618604752,
            21.02844781316759
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.028347669922844,
        "to_latitude": 21.02844781316759,
        "to_longitude": 105.83272618604752,
        "from_longitude": 105.83298743320557,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 207,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8324649388895,
          21.02844781316759,
          105.83272618604752,
          21.02854795634508
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83272618604752,
            21.02844781316759
          ],
          [
            105.8324649388895,
            21.02854795634508
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.02844781316759,
        "to_latitude": 21.02854795634508,
        "to_longitude": 105.8324649388895,
        "from_longitude": 105.83272618604752,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 208,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83220369173146,
          21.02854795634508,
          105.8324649388895,
          21.028648099455232
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8324649388895,
            21.02854795634508
          ],
          [
            105.83220369173146,
            21.028648099455232
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.02854795634508,
        "to_latitude": 21.028648099455232,
        "to_longitude": 105.83220369173146,
        "from_longitude": 105.8324649388895,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 209,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83194244457341,
          21.028648099455232,
          105.83220369173146,
          21.02874824249812
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83220369173146,
            21.028648099455232
          ],
          [
            105.83194244457341,
            21.02874824249812
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.028648099455232,
        "to_latitude": 21.02874824249812,
        "to_longitude": 105.83194244457341,
        "from_longitude": 105.83220369173146,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 210,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83272618604752,
          21.02844781316759,
          105.83276320053773,
          21.02850038834465
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83272618604752,
            21.02844781316759
          ],
          [
            105.83276320053773,
            21.02850038834465
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 8,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu vực xung quanh chùa Bích Câu",
        "diemDau": "15",
        "diemCuoi": "41",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.02844781316759,
        "to_latitude": 21.02850038834465,
        "to_longitude": 105.83276320053773,
        "from_longitude": 105.83272618604752,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 211,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83276320053773,
          21.028465338228877,
          105.83286512447685,
          21.02850038834465
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83276320053773,
            21.02850038834465
          ],
          [
            105.83286512447685,
            21.028465338228877
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu vực xung quanh chùa Bích Câu",
        "diemDau": "41",
        "diemCuoi": "42",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.02850038834465,
        "to_latitude": 21.028465338228877,
        "to_longitude": 105.83286512447685,
        "from_longitude": 105.83276320053773,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 212,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83294827296177,
          21.028775781822585,
          105.83307433677953,
          21.02893100337738
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83307433677953,
            21.02893100337738
          ],
          [
            105.8329879669899,
            21.028925996235348
          ],
          [
            105.83294827296177,
            21.028775781822585
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu vực xung quanh chùa Bích Câu",
        "diemDau": "34",
        "diemCuoi": "44",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.02893100337738,
        "to_latitude": 21.028775781822585,
        "to_longitude": 105.83294827296177,
        "from_longitude": 105.83307433677953,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 213,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83285171342915,
          21.028775781822585,
          105.83294827296177,
          21.028798313993995
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83294827296177,
            21.028775781822585
          ],
          [
            105.83285171342915,
            21.028798313993995
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu vực xung quanh chùa Bích Câu",
        "diemDau": "43",
        "diemCuoi": "44",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.028775781822585,
        "to_latitude": 21.028798313993995,
        "to_longitude": 105.83285171342915,
        "from_longitude": 105.83294827296177,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 214,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8326585943819,
          21.02893100337738,
          105.83307433677953,
          21.029063692642612
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83307433677953,
            21.02893100337738
          ],
          [
            105.8326585943819,
            21.029063692642612
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.02893100337738,
        "to_latitude": 21.029063692642612,
        "to_longitude": 105.8326585943819,
        "from_longitude": 105.83307433677953,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 215,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83224016977651,
          21.029063692642612,
          105.8326585943819,
          21.029173849679346
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8326585943819,
            21.029063692642612
          ],
          [
            105.83224016977651,
            21.029173849679346
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 49,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "diemDau": "35",
        "diemCuoi": "36",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.029063692642612,
        "to_latitude": 21.029173849679346,
        "to_longitude": 105.83224016977651,
        "from_longitude": 105.8326585943819,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 216,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83307433677953,
          21.028920989088952,
          105.8333693797749,
          21.02893100337738
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8333693797749,
            21.028920989088952
          ],
          [
            105.83307433677953,
            21.02893100337738
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "diemDau": "33",
        "diemCuoi": "34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.028920989088952,
        "to_latitude": 21.02893100337738,
        "to_longitude": 105.83307433677953,
        "from_longitude": 105.8333693797749,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 217,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8333693797749,
          21.028920989088952,
          105.83348739696943,
          21.02917635324705
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8333693797749,
            21.028920989088952
          ],
          [
            105.83348739696943,
            21.02917635324705
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.028920989088952,
        "to_latitude": 21.02917635324705,
        "to_longitude": 105.83348739696943,
        "from_longitude": 105.8333693797749,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 218,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83348739696943,
          21.02917635324705,
          105.8335866387053,
          21.029456752603643
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83348739696943,
            21.02917635324705
          ],
          [
            105.8335866387053,
            21.029456752603643
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.02917635324705,
        "to_latitude": 21.029456752603643,
        "to_longitude": 105.8335866387053,
        "from_longitude": 105.83348739696943,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 219,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8335866387053,
          21.029456752603643,
          105.83366978718573,
          21.029541873732295
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8335866387053,
            21.029456752603643
          ],
          [
            105.83366978718573,
            21.029541873732295
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "diemDau": "28",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.029456752603643,
        "to_latitude": 21.029541873732295,
        "to_longitude": 105.83366978718573,
        "from_longitude": 105.8335866387053,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 220,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83366978718573,
          21.029541873732295,
          105.833728795783,
          21.02976719413304
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83366978718573,
            21.029541873732295
          ],
          [
            105.833728795783,
            21.02976719413304
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.029541873732295,
        "to_latitude": 21.02976719413304,
        "to_longitude": 105.833728795783,
        "from_longitude": 105.83366978718573,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 221,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.833728795783,
          21.02976719413304,
          105.8338038976357,
          21.029992514192283
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.833728795783,
            21.02976719413304
          ],
          [
            105.8338038976357,
            21.029992514192283
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.02976719413304,
        "to_latitude": 21.029992514192283,
        "to_longitude": 105.8338038976357,
        "from_longitude": 105.833728795783,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 222,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8338038976357,
          21.029854818640676,
          105.83424914432749,
          21.029992514192283
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8338038976357,
            21.029992514192283
          ],
          [
            105.83424914432749,
            21.029854818640676
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 53,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "diemDau": "30",
        "diemCuoi": "30-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.029992514192283,
        "to_latitude": 21.029854818640676,
        "to_longitude": 105.83424914432749,
        "from_longitude": 105.8338038976357,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 223,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83366978718573,
          21.0294842917972,
          105.83378243996479,
          21.029541873732295
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83378243996479,
            21.0294842917972
          ],
          [
            105.83366978718573,
            21.029541873732295
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.0294842917972,
        "to_latitude": 21.029541873732295,
        "to_longitude": 105.83366978718573,
        "from_longitude": 105.83378243996479,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 224,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83378243996479,
          21.029371631426407,
          105.83400774552291,
          21.0294842917972
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83400774552291,
            21.029371631426407
          ],
          [
            105.83378243996479,
            21.0294842917972
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.029371631426407,
        "to_latitude": 21.0294842917972,
        "to_longitude": 105.83378243996479,
        "from_longitude": 105.83400774552291,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 225,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83400774552291,
          21.02919137465495,
          105.834182089107,
          21.029371631426407
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83400774552291,
            21.029371631426407
          ],
          [
            105.834182089107,
            21.02919137465495
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.029371631426407,
        "to_latitude": 21.02919137465495,
        "to_longitude": 105.834182089107,
        "from_longitude": 105.83400774552291,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 226,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.834182089107,
          21.028968556955636,
          105.83454955173832,
          21.02919137465495
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.834182089107,
            21.02919137465495
          ],
          [
            105.83454955173832,
            21.028968556955636
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 49,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.02919137465495,
        "to_latitude": 21.028968556955636,
        "to_longitude": 105.83454955173832,
        "from_longitude": 105.834182089107,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 227,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8343488924149,
          21.029090230454255,
          105.83441275908059,
          21.02941919914923
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8343488924149,
            21.029090230454255
          ],
          [
            105.83441275908059,
            21.02941919914923
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "diemDau": "24",
        "diemCuoi": "24-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.029090230454255,
        "to_latitude": 21.02941919914923,
        "to_longitude": 105.83441275908059,
        "from_longitude": 105.8343488924149,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 228,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83421964003335,
          21.02903865694137,
          105.83434955326867,
          21.0290898297335
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83434955326867,
            21.0290898297335
          ],
          [
            105.83421964003335,
            21.02903865694137
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phan Phù Tiên",
        "diemDau": "24",
        "diemCuoi": "37",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.0290898297335,
        "to_latitude": 21.02903865694137,
        "to_longitude": 105.83421964003335,
        "from_longitude": 105.83434955326867,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 229,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83405513121399,
          21.028763263948594,
          105.83421964003335,
          21.02903865694137
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83421964003335,
            21.02903865694137
          ],
          [
            105.83405513121399,
            21.028763263948594
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phan Phù Tiên",
        "diemDau": "37",
        "diemCuoi": "38",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.02903865694137,
        "to_latitude": 21.028763263948594,
        "to_longitude": 105.83405513121399,
        "from_longitude": 105.83421964003335,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 230,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83389062239462,
          21.028487870446952,
          105.83405513121399,
          21.028763263948594
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83405513121399,
            21.028763263948594
          ],
          [
            105.83389062239462,
            21.028487870446952
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phan Phù Tiên",
        "diemDau": "38",
        "diemCuoi": "39",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.028763263948594,
        "to_latitude": 21.028487870446952,
        "to_longitude": 105.83389062239462,
        "from_longitude": 105.83405513121399,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 231,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83372611357525,
          21.028212476436437,
          105.83389062239462,
          21.028487870446952
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83389062239462,
            21.028487870446952
          ],
          [
            105.83372611357525,
            21.028212476436437
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phan Phù Tiên",
        "diemDau": "39",
        "diemCuoi": "40",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.028487870446952,
        "to_latitude": 21.028212476436437,
        "to_longitude": 105.83372611357525,
        "from_longitude": 105.83389062239462,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 232,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83360809637173,
          21.028212476436437,
          105.83372611357525,
          21.028270058862063
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83372611357525,
            21.028212476436437
          ],
          [
            105.83360809637173,
            21.028270058862063
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 3 Phan Phù Tiên",
        "diemDau": "40",
        "diemCuoi": "40-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.028212476436437,
        "to_latitude": 21.028270058862063,
        "to_longitude": 105.83360809637173,
        "from_longitude": 105.83372611357525,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 233,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83360809637173,
          21.028270058862063,
          105.83369338796743,
          21.028580502863363
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83360809637173,
            21.028270058862063
          ],
          [
            105.83369338796743,
            21.02844280600968
          ],
          [
            105.83366924807977,
            21.02846283465092
          ],
          [
            105.83367997691973,
            21.028487870448913
          ],
          [
            105.83364510820111,
            21.02849538118816
          ],
          [
            105.83360809637173,
            21.028580502863363
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 3 Phan Phù Tiên",
        "diemDau": "40-1",
        "diemCuoi": "40-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.028270058862063,
        "to_latitude": 21.028580502863363,
        "to_longitude": 105.83360809637173,
        "from_longitude": 105.83360809637173,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 234,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83360809637173,
          21.028580502863363,
          105.83366442276127,
          21.02886841406063
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83360809637173,
            21.028580502863363
          ],
          [
            105.83366442276127,
            21.02886841406063
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 3 Phan Phù Tiên",
        "diemDau": "40-2",
        "diemCuoi": "40-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.028580502863363,
        "to_latitude": 21.02886841406063,
        "to_longitude": 105.83366442276127,
        "from_longitude": 105.83360809637173,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 235,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83385217739304,
          21.028487870446952,
          105.83389062239462,
          21.028532934875347
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83389062239462,
            21.028487870446952
          ],
          [
            105.83388114259918,
            21.028532934875347
          ],
          [
            105.83385217739304,
            21.02852292055683
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 23 Phan Phù Tiên",
        "diemDau": "39",
        "diemCuoi": "39-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.028487870446952,
        "to_latitude": 21.02852292055683,
        "to_longitude": 105.83385217739304,
        "from_longitude": 105.83389062239462,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 236,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83487530653872,
          21.02934909934166,
          105.83506775503407,
          21.02968144724287
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83506775503407,
            21.02934909934166
          ],
          [
            105.83487530653872,
            21.02968144724287
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hàng Cháo",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.02934909934166,
        "to_latitude": 21.02968144724287,
        "to_longitude": 105.83487530653872,
        "from_longitude": 105.83506775503407,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 237,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83468285804342,
          21.02968144724287,
          105.83487530653872,
          21.030013794402922
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83487530653872,
            21.02968144724287
          ],
          [
            105.83468285804342,
            21.030013794402922
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hàng Cháo",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.02968144724287,
        "to_latitude": 21.030013794402922,
        "to_longitude": 105.83468285804342,
        "from_longitude": 105.83487530653872,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 238,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83449040954811,
          21.030013794402922,
          105.83468285804342,
          21.030346140821816
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83468285804342,
            21.030013794402922
          ],
          [
            105.83449040954811,
            21.030346140821816
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hàng Cháo",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.030013794402922,
        "to_latitude": 21.030346140821816,
        "to_longitude": 105.83449040954811,
        "from_longitude": 105.83468285804342,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 239,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83429796105278,
          21.030346140821816,
          105.83449040954811,
          21.0306784864995
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83449040954811,
            21.030346140821816
          ],
          [
            105.83429796105278,
            21.0306784864995
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hàng Cháo",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.030346140821816,
        "to_latitude": 21.0306784864995,
        "to_longitude": 105.83429796105278,
        "from_longitude": 105.83449040954811,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 240,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8341740424748,
          21.030346140821816,
          105.83449040954811,
          21.030448160385696
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83449040954811,
            21.030346140821816
          ],
          [
            105.83431834266774,
            21.030448160385696
          ],
          [
            105.8341740424748,
            21.030403096535846
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Hàng Cháo",
        "diemDau": "22",
        "diemCuoi": "22-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.030346140821816,
        "to_latitude": 21.030403096535846,
        "to_longitude": 105.8341740424748,
        "from_longitude": 105.83449040954811,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 241,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83506775503407,
          21.029311545862658,
          105.83515868138424,
          21.02934909934166
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83515868138424,
            21.02931342353524
          ],
          [
            105.83512300536763,
            21.029311545862658
          ],
          [
            105.83506775503407,
            21.02934909934166
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hàng Cháo",
        "diemDau": "05",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.02931342353524,
        "to_latitude": 21.02934909934166,
        "to_longitude": 105.83506775503407,
        "from_longitude": 105.83515868138424,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 242,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83474776697311,
          21.028745738923195,
          105.83478088960038,
          21.028847133686025
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83474776697311,
            21.028847133686025
          ],
          [
            105.83478088960038,
            21.028826479205755
          ],
          [
            105.83475795936315,
            21.028745738923195
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "10/13/2015 12:00:00 AM",
        "tuDien": "HC",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "tủ điện",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.028847133686025,
        "to_latitude": 21.028745738923195,
        "to_longitude": 105.83475795936315,
        "from_longitude": 105.83474776697311,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 243,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83454955173832,
          21.028745738923195,
          105.83475795936315,
          21.028968556955636
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83475795936315,
            21.028745738923195
          ],
          [
            105.83473260984304,
            21.028816464910317
          ],
          [
            105.83454955173832,
            21.028968556955636
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "diemDau": "07",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.028745738923195,
        "to_latitude": 21.028968556955636,
        "to_longitude": 105.83454955173832,
        "from_longitude": 105.83475795936315,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 244,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83487530653872,
          21.02968144724287,
          105.83504576040416,
          21.029824775957984
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83487530653872,
            21.02968144724287
          ],
          [
            105.83504576040416,
            21.029824775957984
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 17 Hàng Cháo",
        "diemDau": "20",
        "diemCuoi": "20-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.02968144724287,
        "to_latitude": 21.029824775957984,
        "to_longitude": 105.83504576040416,
        "from_longitude": 105.83487530653872,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 245,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83504576040416,
          21.029824775957984,
          105.83523083282819,
          21.03001254262526
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83504576040416,
            21.029824775957984
          ],
          [
            105.83523083282819,
            21.03001254262526
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 17 Hàng Cháo",
        "diemDau": "20-1",
        "diemCuoi": "20-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.029824775957984,
        "to_latitude": 21.03001254262526,
        "to_longitude": 105.83523083282819,
        "from_longitude": 105.83504576040416,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 246,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83270151927283,
          21.007478227886455,
          105.83287251013765,
          21.00765788492685
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83287251013765,
            21.007478227886455
          ],
          [
            105.83270151927283,
            21.00765788492685
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.007478227886455,
        "to_latitude": 21.00765788492685,
        "to_longitude": 105.83270151927283,
        "from_longitude": 105.83287251013765,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 247,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83247084929923,
          21.00765788492685,
          105.83270151927283,
          21.007895758025033
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83270151927283,
            21.00765788492685
          ],
          [
            105.83247084929923,
            21.007895758025033
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.00765788492685,
        "to_latitude": 21.007895758025033,
        "to_longitude": 105.83247084929923,
        "from_longitude": 105.83270151927283,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 248,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83222609776641,
          21.007895758025033,
          105.83247084929923,
          21.008161799852427
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83247084929923,
            21.007895758025033
          ],
          [
            105.83222609776641,
            21.008161799852427
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.007895758025033,
        "to_latitude": 21.008161799852427,
        "to_longitude": 105.83222609776641,
        "from_longitude": 105.83247084929923,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 249,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83265323950653,
          21.007304830472602,
          105.83287251013765,
          21.007478227886455
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83287251013765,
            21.007478227886455
          ],
          [
            105.83265323950653,
            21.007304830472602
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.007478227886455,
        "to_latitude": 21.007304830472602,
        "to_longitude": 105.83265323950653,
        "from_longitude": 105.83287251013765,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 250,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8321583719805,
          21.006896688459985,
          105.83239038303103,
          21.00708448400615
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83239038303103,
            21.00708448400615
          ],
          [
            105.8321583719805,
            21.006896688459985
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.00708448400615,
        "to_latitude": 21.006896688459985,
        "to_longitude": 105.8321583719805,
        "from_longitude": 105.83239038303103,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 251,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83239038303103,
          21.00708448400615,
          105.83265323950653,
          21.007304830472602
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83239038303103,
            21.00708448400615
          ],
          [
            105.83265323950653,
            21.007304830472602
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.00708448400615,
        "to_latitude": 21.007304830472602,
        "to_longitude": 105.83265323950653,
        "from_longitude": 105.83239038303103,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 252,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83137919025415,
          21.006102936696326,
          105.8314046712367,
          21.00628071742926
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8314046712367,
            21.00628071742926
          ],
          [
            105.83137919025415,
            21.00618055929474
          ],
          [
            105.83140064792508,
            21.006102936696326
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đông Tác",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.00628071742926,
        "to_latitude": 21.006102936696326,
        "to_longitude": 105.83140064792508,
        "from_longitude": 105.8314046712367,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 253,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8314046712367,
          21.00628071742926,
          105.83167825656112,
          21.006513584825885
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83167825656112,
            21.006513584825885
          ],
          [
            105.8314046712367,
            21.00628071742926
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.006513584825885,
        "to_latitude": 21.00628071742926,
        "to_longitude": 105.8314046712367,
        "from_longitude": 105.83167825656112,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 254,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8321583719805,
          21.00673393213542,
          105.83245341496685,
          21.00689668846
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8321583719805,
            21.00689668846
          ],
          [
            105.83245341496685,
            21.00673393213542
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 103 Phạm Ngọc Thạch",
        "diemDau": "07",
        "diemCuoi": "7-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.00689668846,
        "to_latitude": 21.00673393213542,
        "to_longitude": 105.83245341496685,
        "from_longitude": 105.8321583719805,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 255,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83236490206647,
          21.00673393213542,
          105.83245341496685,
          21.006981822469175
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83245341496685,
            21.00673393213542
          ],
          [
            105.83236490206647,
            21.006981822469175
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 101 Phạm Ngọc Thạch",
        "diemDau": "7-2",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.00673393213542,
        "to_latitude": 21.006981822469175,
        "to_longitude": 105.83236490206647,
        "from_longitude": 105.83245341496685,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 256,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83245341496685,
          21.006513584825885,
          105.83252315239511,
          21.00673393213542
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83245341496685,
            21.00673393213542
          ],
          [
            105.83252315239511,
            21.006513584825885
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 101 Phạm Ngọc Thạch",
        "diemDau": "7-2",
        "diemCuoi": "7-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.00673393213542,
        "to_latitude": 21.006513584825885,
        "to_longitude": 105.83252315239511,
        "from_longitude": 105.83245341496685,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 257,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83281819539046,
          21.00617805534345,
          105.83287988619547,
          21.006420938700458
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83287988619547,
            21.00617805534345
          ],
          [
            105.83281819539046,
            21.006420938700458
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 26 Đông Tác",
        "diemDau": "7-6",
        "diemCuoi": "7-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.00617805534345,
        "to_latitude": 21.006420938700458,
        "to_longitude": 105.83281819539046,
        "from_longitude": 105.83287988619547,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 258,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83281819539046,
          21.006420938700458,
          105.83300326781449,
          21.006425946600718
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83281819539046,
            21.006420938700458
          ],
          [
            105.83300326781449,
            21.006425946600718
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 26 Đông Tác",
        "diemDau": "7-8",
        "diemCuoi": "7-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.006420938700458,
        "to_latitude": 21.006425946600718,
        "to_longitude": 105.83300326781449,
        "from_longitude": 105.83281819539046,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 259,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83287988619547,
          21.00617805534345,
          105.83300326781449,
          21.006220622558292
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83287988619547,
            21.00617805534345
          ],
          [
            105.83300326781449,
            21.006220622558292
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 26 Đông Tác",
        "diemDau": "7-6",
        "diemCuoi": "7-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.00617805534345,
        "to_latitude": 21.006220622558292,
        "to_longitude": 105.83300326781449,
        "from_longitude": 105.83287988619547,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 260,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83221134560587,
          21.00708448400615,
          105.83239038303103,
          21.007189023414476
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83239038303103,
            21.00708448400615
          ],
          [
            105.83221134560587,
            21.007189023414476
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 80 Phạm Ngọc Thạch",
        "diemDau": "06",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.00708448400615,
        "to_latitude": 21.007189023414476,
        "to_longitude": 105.83221134560587,
        "from_longitude": 105.83239038303103,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 261,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83208259957136,
          21.007189023414476,
          105.83221134560587,
          21.0073567871133
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83221134560587,
            21.007189023414476
          ],
          [
            105.83208259957136,
            21.0073567871133
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 80 Phạm Ngọc Thạch",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.007189023414476,
        "to_latitude": 21.0073567871133,
        "to_longitude": 105.83208259957136,
        "from_longitude": 105.83221134560587,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 262,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83198872225546,
          21.0073567871133,
          105.83208259957136,
          21.007594660690675
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83208259957136,
            21.0073567871133
          ],
          [
            105.83198872225546,
            21.007594660690675
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 80 Phạm Ngọc Thạch",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.0073567871133,
        "to_latitude": 21.007594660690675,
        "to_longitude": 105.83198872225546,
        "from_longitude": 105.83208259957136,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 263,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83179023878375,
          21.007594660690675,
          105.83198872225546,
          21.0078125024773
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83198872225546,
            21.007594660690675
          ],
          [
            105.83179023878375,
            21.0078125024773
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 80 Phạm Ngọc Thạch",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.007594660690675,
        "to_latitude": 21.0078125024773,
        "to_longitude": 105.83179023878375,
        "from_longitude": 105.83198872225546,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 264,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83316822366248,
          21.02824752661083,
          105.83324868036361,
          21.02849287760649
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83324868036361,
            21.02824752661083
          ],
          [
            105.83316822366248,
            21.028332648431768
          ],
          [
            105.83323259668424,
            21.02849287760649
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 12 Cát Linh",
        "diemDau": "13",
        "diemCuoi": "13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.02824752661083,
        "to_latitude": 21.02849287760649,
        "to_longitude": 105.83323259668424,
        "from_longitude": 105.83324868036361,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 265,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8330609353079,
          21.02849287760649,
          105.83323259668424,
          21.02855797065988
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83323259668424,
            21.02849287760649
          ],
          [
            105.8330609353079,
            21.02855797065988
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 12 Cát Linh",
        "diemDau": "13-1",
        "diemCuoi": "13-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Cháo",
        "from_latitude": 21.02849287760649,
        "to_latitude": 21.02855797065988,
        "to_longitude": 105.8330609353079,
        "from_longitude": 105.83323259668424,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 266,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83193038420565,
          21.00670889268415,
          105.8321583719805,
          21.00689668846
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8321583719805,
            21.00689668846
          ],
          [
            105.83193038420565,
            21.00670889268415
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.00689668846,
        "to_latitude": 21.00670889268415,
        "to_longitude": 105.83193038420565,
        "from_longitude": 105.8321583719805,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 267,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83167825656112,
          21.006513584825885,
          105.83193038420565,
          21.00670889268415
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83193038420565,
            21.00670889268415
          ],
          [
            105.83167825656112,
            21.006513584825885
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.00670889268415,
        "to_latitude": 21.006513584825885,
        "to_longitude": 105.83167825656112,
        "from_longitude": 105.83193038420565,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 268,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83364651903736,
          21.026351675844907,
          105.83386325106927,
          21.02644900386448
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83364651903736,
            21.026351675844907
          ],
          [
            105.83372109398708,
            21.02644900386448
          ],
          [
            105.83386325106927,
            21.02639142073364
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 56,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 47 Tôn Đức Thắng",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.026351675844907,
        "to_latitude": 21.02639142073364,
        "to_longitude": 105.83386325106927,
        "from_longitude": 105.83364651903736,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 269,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83168630316638,
          21.0085918476485,
          105.83184187128727,
          21.0088823013509
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83168630316638,
            21.0088823013509
          ],
          [
            105.83184187128727,
            21.0085918476485
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.0088823013509,
        "to_latitude": 21.0085918476485,
        "to_longitude": 105.83184187128727,
        "from_longitude": 105.83168630316638,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 270,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83140467121872,
          21.008301393379824,
          105.83158437922727,
          21.008456636248788
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83158437922727,
            21.008456636248788
          ],
          [
            105.83140467121872,
            21.008301393379824
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 69 Đặng Văn Ngữ",
        "diemDau": "21-1",
        "diemCuoi": "21-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.008456636248788,
        "to_latitude": 21.008301393379824,
        "to_longitude": 105.83140467121872,
        "from_longitude": 105.83158437922727,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 271,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83158437922727,
          21.008456636248788,
          105.83184187128727,
          21.0085918476485
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83184187128727,
            21.0085918476485
          ],
          [
            105.83173726514042,
            21.008574320251743
          ],
          [
            105.83158437922727,
            21.008456636248788
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 69 Đặng Văn Ngữ",
        "diemDau": "21",
        "diemCuoi": "21-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.0085918476485,
        "to_latitude": 21.008456636248788,
        "to_longitude": 105.83158437922727,
        "from_longitude": 105.83184187128727,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 272,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83184187128727,
          21.008374006999816,
          105.83204303696675,
          21.0085918476485
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83184187128727,
            21.0085918476485
          ],
          [
            105.83204303696675,
            21.008374006999816
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.0085918476485,
        "to_latitude": 21.008374006999816,
        "to_longitude": 105.83204303696675,
        "from_longitude": 105.83184187128727,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 273,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83183918907953,
          21.007993411081497,
          105.83195452406633,
          21.00810107976227
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83183918907953,
            21.00810107976227
          ],
          [
            105.83195452406633,
            21.007993411081497
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 47 Đặng Văn Ngữ",
        "diemDau": "22-1",
        "diemCuoi": "22-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.00810107976227,
        "to_latitude": 21.007993411081497,
        "to_longitude": 105.83195452406633,
        "from_longitude": 105.83183918907953,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 274,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83162997677684,
          21.00810107976227,
          105.83183918907953,
          21.0082588267586
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83183918907953,
            21.00810107976227
          ],
          [
            105.8317694516423,
            21.00810107976227
          ],
          [
            105.83162997677684,
            21.0082588267586
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 47 Đặng Văn Ngữ",
        "diemDau": "22-1",
        "diemCuoi": "22-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.00810107976227,
        "to_latitude": 21.0082588267586,
        "to_longitude": 105.83162997677684,
        "from_longitude": 105.83183918907953,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 275,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83183918907953,
          21.00810107976227,
          105.83204303696675,
          21.0083740069998
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83204303696675,
            21.0083740069998
          ],
          [
            105.83200280383264,
            21.00829137770587
          ],
          [
            105.83183918907953,
            21.00810107976227
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 47 Đặng Văn Ngữ",
        "diemDau": "22",
        "diemCuoi": "22-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.0083740069998,
        "to_latitude": 21.00810107976227,
        "to_longitude": 105.83183918907953,
        "from_longitude": 105.83204303696675,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 276,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83230857565897,
          21.008651941564413,
          105.83251510575391,
          21.00882721534744
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83230857565897,
            21.008651941564413
          ],
          [
            105.83251510575391,
            21.00882721534744
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Đặng Văn Ngữ",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.008651941564413,
        "to_latitude": 21.00882721534744,
        "to_longitude": 105.83251510575391,
        "from_longitude": 105.83230857565897,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 277,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83251510575391,
          21.00882721534744,
          105.83273236467983,
          21.00901000064445
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83251510575391,
            21.00882721534744
          ],
          [
            105.83273236467983,
            21.00901000064445
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Đặng Văn Ngữ",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.00882721534744,
        "to_latitude": 21.00901000064445,
        "to_longitude": 105.83273236467983,
        "from_longitude": 105.83251510575391,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 278,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83273236467983,
          21.008817199707952,
          105.83292548372708,
          21.00901000064445
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83273236467983,
            21.00901000064445
          ],
          [
            105.83292548372708,
            21.008817199707952
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Đặng Văn Ngữ",
        "diemDau": "25",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.00901000064445,
        "to_latitude": 21.008817199707952,
        "to_longitude": 105.83292548372708,
        "from_longitude": 105.83273236467983,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 279,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83292548372708,
          21.00863942200067,
          105.83309178068792,
          21.008817199707952
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83292548372708,
            21.008817199707952
          ],
          [
            105.83309178068792,
            21.00863942200067
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Đặng Văn Ngữ",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.008817199707952,
        "to_latitude": 21.00863942200067,
        "to_longitude": 105.83309178068792,
        "from_longitude": 105.83292548372708,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 280,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83309178068792,
          21.00842158142142,
          105.83321516230694,
          21.00863942200067
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83309178068792,
            21.00863942200067
          ],
          [
            105.83321516230694,
            21.00842158142142
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Đặng Văn Ngữ",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.00863942200067,
        "to_latitude": 21.00842158142142,
        "to_longitude": 105.83321516230694,
        "from_longitude": 105.83309178068792,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 281,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83204303696675,
          21.008374006999816,
          105.83234612658532,
          21.008651941564413
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83204303696675,
            21.008374006999816
          ],
          [
            105.83234612658532,
            21.008571816337273
          ],
          [
            105.83230857565897,
            21.008651941564413
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Đặng Văn Ngữ",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.008374006999816,
        "to_latitude": 21.008651941564413,
        "to_longitude": 105.83230857565897,
        "from_longitude": 105.83204303696675,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 282,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83273236467983,
          21.00901000064445,
          105.83307032301703,
          21.00921782475196
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83273236467983,
            21.00901000064445
          ],
          [
            105.83297912790887,
            21.009207809138694
          ],
          [
            105.83307032301703,
            21.00921782475196
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Đặng Văn Ngữ",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.00901000064445,
        "to_latitude": 21.00921782475196,
        "to_longitude": 105.83307032301703,
        "from_longitude": 105.83273236467983,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 283,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82593805144937,
          21.020510000553934,
          105.82608959626764,
          21.02058511194489
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82593805144937,
            21.02058511194489
          ],
          [
            105.82608959626764,
            21.020510000553934
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "tủ điện",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.02058511194489,
        "to_latitude": 21.020510000553934,
        "to_longitude": 105.82608959626764,
        "from_longitude": 105.82593805144937,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 284,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82608959626764,
          21.020239599240934,
          105.82656166505481,
          21.020510000553934
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82608959626764,
            21.020510000553934
          ],
          [
            105.82656166505481,
            21.020239599240934
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 62,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.020510000553934,
        "to_latitude": 21.020239599240934,
        "to_longitude": 105.82656166505481,
        "from_longitude": 105.82608959626764,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 285,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82656166505481,
          21.01996919743752,
          105.82703373384199,
          21.020239599240934
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82656166505481,
            21.020239599240934
          ],
          [
            105.82703373384199,
            21.01996919743752
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 62,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.020239599240934,
        "to_latitude": 21.01996919743752,
        "to_longitude": 105.82703373384199,
        "from_longitude": 105.82656166505481,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 286,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82582137536768,
          21.02058511194489,
          105.82593805144937,
          21.020645201027005
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82593805144937,
            21.02058511194489
          ],
          [
            105.82582137536768,
            21.020645201027005
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "Tủ điện",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.02058511194489,
        "to_latitude": 21.020645201027005,
        "to_longitude": 105.82582137536768,
        "from_longitude": 105.82593805144937,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 287,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82669577550479,
          21.019697543280277,
          105.82716784429196,
          21.019956678823693
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82669577550479,
            21.019956678823693
          ],
          [
            105.82716784429196,
            21.019697543280277
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 61,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.019956678823693,
        "to_latitude": 21.019697543280277,
        "to_longitude": 105.82716784429196,
        "from_longitude": 105.82669577550479,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 288,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82716784429196,
          21.01943840728654,
          105.82763991307914,
          21.019697543280277
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82716784429196,
            21.019697543280277
          ],
          [
            105.82763991307914,
            21.01943840728654
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 61,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.019697543280277,
        "to_latitude": 21.01943840728654,
        "to_longitude": 105.82763991307914,
        "from_longitude": 105.82716784429196,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 289,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82763991307914,
          21.01930320571925,
          105.82786521863726,
          21.01943840728654
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82763991307914,
            21.01943840728654
          ],
          [
            105.82786521863726,
            21.01930320571925
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.01943840728654,
        "to_latitude": 21.01930320571925,
        "to_longitude": 105.82786521863726,
        "from_longitude": 105.82763991307914,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 290,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82786521863726,
          21.019145470403,
          105.82813343953873,
          21.01930320571925
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82786521863726,
            21.01930320571925
          ],
          [
            105.82813343953873,
            21.019145470403
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.01930320571925,
        "to_latitude": 21.019145470403,
        "to_longitude": 105.82813343953873,
        "from_longitude": 105.82786521863726,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 291,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82813359778444,
          21.01898773491991,
          105.82840166044018,
          21.019142586581896
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82813359778444,
            21.019142586581896
          ],
          [
            105.82840166044018,
            21.01898773491991
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.019142586581896,
        "to_latitude": 21.01898773491991,
        "to_longitude": 105.82840166044018,
        "from_longitude": 105.82813359778444,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 292,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82840166044018,
          21.01882999926997,
          105.82866988134164,
          21.01898773491991
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82840166044018,
            21.01898773491991
          ],
          [
            105.82866988134164,
            21.01882999926997
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.01898773491991,
        "to_latitude": 21.01882999926997,
        "to_longitude": 105.82866988134164,
        "from_longitude": 105.82840166044018,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 293,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82866988134164,
          21.018672263453162,
          105.8289381022431,
          21.01882999926997
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82866988134164,
            21.01882999926997
          ],
          [
            105.8289381022431,
            21.018672263453162
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.01882999926997,
        "to_latitude": 21.018672263453162,
        "to_longitude": 105.8289381022431,
        "from_longitude": 105.82866988134164,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 294,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82593805144937,
          21.02058511194489,
          105.82605902868261,
          21.020805438464862
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82593805144937,
            21.02058511194489
          ],
          [
            105.82605902868261,
            21.02071280121964
          ],
          [
            105.82598767232851,
            21.020805438464862
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "Tủ điện",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.02058511194489,
        "to_latitude": 21.020805438464862,
        "to_longitude": 105.82598767232851,
        "from_longitude": 105.82593805144937,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 295,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82598767232851,
          21.020542548826946,
          105.82645035338186,
          21.020805438464862
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82598767232851,
            21.020805438464862
          ],
          [
            105.82645035338186,
            21.020542548826946
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 62,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "24",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.020805438464862,
        "to_latitude": 21.020542548826946,
        "to_longitude": 105.82645035338186,
        "from_longitude": 105.82598767232851,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 296,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82645035338186,
          21.020279658725507,
          105.82691303443521,
          21.020542548826946
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82645035338186,
            21.020542548826946
          ],
          [
            105.82691303443521,
            21.020279658725507
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 61,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "23",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.020542548826946,
        "to_latitude": 21.020279658725507,
        "to_longitude": 105.82691303443521,
        "from_longitude": 105.82645035338186,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 297,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82691303443521,
          21.020016768160545,
          105.82737571548853,
          21.020279658725507
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82691303443521,
            21.020279658725507
          ],
          [
            105.82737571548853,
            21.020016768160545
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 63,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "22",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.020279658725507,
        "to_latitude": 21.020016768160545,
        "to_longitude": 105.82737571548853,
        "from_longitude": 105.82691303443521,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 298,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82737571548853,
          21.01975387713208,
          105.82783839654188,
          21.020016768160545
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82737571548853,
            21.020016768160545
          ],
          [
            105.82783839654188,
            21.01975387713208
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 63,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "21",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.020016768160545,
        "to_latitude": 21.01975387713208,
        "to_longitude": 105.82783839654188,
        "from_longitude": 105.82737571548853,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 299,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82783839654188,
          21.019608660936836,
          105.82807979535544,
          21.01975387713208
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82783839654188,
            21.01975387713208
          ],
          [
            105.82807979535544,
            21.019608660936836
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "20",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.01975387713208,
        "to_latitude": 21.019608660936836,
        "to_longitude": 105.82807979535544,
        "from_longitude": 105.82783839654188,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 300,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82807979535544,
          21.019463444600152,
          105.8283426518399,
          21.019608660936836
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82807979535544,
            21.019608660936836
          ],
          [
            105.8283426518399,
            21.019463444600152
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "19",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.019608660936836,
        "to_latitude": 21.019463444600152,
        "to_longitude": 105.8283426518399,
        "from_longitude": 105.82807979535544,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 301,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8283426518399,
          21.019323235589294,
          105.82859477948443,
          21.019463444600152
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8283426518399,
            21.019463444600152
          ],
          [
            105.82859477948443,
            21.019323235589294
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "18",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.019463444600152,
        "to_latitude": 21.019323235589294,
        "to_longitude": 105.82859477948443,
        "from_longitude": 105.8283426518399,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 302,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82859477948443,
          21.019178018973786,
          105.82887372922437,
          21.019323235589294
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82859477948443,
            21.019323235589294
          ],
          [
            105.82887372922437,
            21.019178018973786
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "17",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.019323235589294,
        "to_latitude": 21.019178018973786,
        "to_longitude": 105.82887372922437,
        "from_longitude": 105.82859477948443,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 303,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82887372922437,
          21.01900275735391,
          105.82917413663519,
          21.019178018973786
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82887372922437,
            21.019178018973786
          ],
          [
            105.82917413663519,
            21.01900275735391
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "16",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.019178018973786,
        "to_latitude": 21.01900275735391,
        "to_longitude": 105.82917413663519,
        "from_longitude": 105.82887372922437,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 304,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82917413663519,
          21.018862547909926,
          105.82953891705878,
          21.01900275735391
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82917413663519,
            21.01900275735391
          ],
          [
            105.82940106111916,
            21.018870059135775
          ],
          [
            105.82953891705878,
            21.018862547909926
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "15",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.01900275735391,
        "to_latitude": 21.018862547909926,
        "to_longitude": 105.82953891705878,
        "from_longitude": 105.82917413663519,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 305,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8289381022431,
          21.018486986248387,
          105.82913122129335,
          21.018672263453162
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8289381022431,
            21.018672263453162
          ],
          [
            105.82904164511105,
            21.018579624882353
          ],
          [
            105.82913122129335,
            21.018486986248387
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.018672263453162,
        "to_latitude": 21.018486986248387,
        "to_longitude": 105.82913122129335,
        "from_longitude": 105.8289381022431,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 306,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82913122129335,
          21.018156491204216,
          105.82918486547516,
          21.018486986248387
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82913122129335,
            21.018486986248387
          ],
          [
            105.82917039114555,
            21.01833425757186
          ],
          [
            105.82918486547516,
            21.018156491204216
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.018486986248387,
        "to_latitude": 21.018156491204216,
        "to_longitude": 105.82918486547516,
        "from_longitude": 105.82913122129335,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 307,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8289381022431,
          21.01854206868524,
          105.8293887133534,
          21.018789939398427
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8289381022431,
            21.018672263453162
          ],
          [
            105.82904700952653,
            21.018789939398427
          ],
          [
            105.8293887133534,
            21.01854206868524
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 70,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "10",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.018672263453162,
        "to_latitude": 21.01854206868524,
        "to_longitude": 105.8293887133534,
        "from_longitude": 105.8289381022431,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 308,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82575163793044,
          21.020442400274405,
          105.82597319799893,
          21.02058511194489
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82575163793044,
            21.020474948559425
          ],
          [
            105.82591955381713,
            21.020442400274405
          ],
          [
            105.82597319799893,
            21.02050749684321
          ],
          [
            105.82593805144937,
            21.02058511194489
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "tủ điện",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.020474948559425,
        "to_latitude": 21.02058511194489,
        "to_longitude": 105.82593805144937,
        "from_longitude": 105.82575163793044,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 309,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82626141588221,
          21.019956678823693,
          105.82669577550479,
          21.02021042639752
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82669577550479,
            21.019956678823693
          ],
          [
            105.82626141588221,
            21.02021042639752
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 57,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.019956678823693,
        "to_latitude": 21.02021042639752,
        "to_longitude": 105.82626141588221,
        "from_longitude": 105.82669577550479,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 310,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82575163793044,
          21.02021042639752,
          105.82626141588221,
          21.020474948559425
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82626141588221,
            21.02021042639752
          ],
          [
            105.82575163793044,
            21.020474948559425
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 64,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ô Chợ Dừa",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.02021042639752,
        "to_latitude": 21.020474948559425,
        "to_longitude": 105.82575163793044,
        "from_longitude": 105.82626141588221,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 311,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8307743521029,
          21.007784225999917,
          105.83121423436124,
          21.00799090715223
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8307743521029,
            21.00799090715223
          ],
          [
            105.83093528462133,
            21.007866855574704
          ],
          [
            105.83121423436124,
            21.007784225999917
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 56,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.00799090715223,
        "to_latitude": 21.007784225999917,
        "to_longitude": 105.83121423436124,
        "from_longitude": 105.8307743521029,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 312,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83058927966091,
          21.00799090715223,
          105.8307743521029,
          21.008027106742343
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8307743521029,
            21.00799090715223
          ],
          [
            105.83058927966091,
            21.008027106742343
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "diemDau": "23",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.00799090715223,
        "to_latitude": 21.008027106742343,
        "to_longitude": 105.83058927966091,
        "from_longitude": 105.8307743521029,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 313,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8307743521029,
          21.00799090715223,
          105.83091650915814,
          21.008179845976496
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8307743521029,
            21.00799090715223
          ],
          [
            105.83091650915814,
            21.008179845976496
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "diemDau": "21",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.00799090715223,
        "to_latitude": 21.008179845976496,
        "to_longitude": 105.83091650915814,
        "from_longitude": 105.8307743521029,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 314,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83091650915814,
          21.008079689119334,
          105.83117131901942,
          21.008179845976496
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83091650915814,
            21.008179845976496
          ],
          [
            105.83117131901942,
            21.008079689119334
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.008179845976496,
        "to_latitude": 21.008079689119334,
        "to_longitude": 105.83117131901942,
        "from_longitude": 105.83091650915814,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 315,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83117131901942,
          21.007924445858134,
          105.83146099759027,
          21.008079689119334
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83117131901942,
            21.008079689119334
          ],
          [
            105.83146099759027,
            21.007924445858134
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.008079689119334,
        "to_latitude": 21.007924445858134,
        "to_longitude": 105.83146099759027,
        "from_longitude": 105.83117131901942,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 316,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83146099759027,
          21.007751674942,
          105.83164070559883,
          21.007924445858134
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83146099759027,
            21.007924445858134
          ],
          [
            105.83164070559883,
            21.007751674942
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.007924445858134,
        "to_latitude": 21.007751674942,
        "to_longitude": 105.83164070559883,
        "from_longitude": 105.83146099759027,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 317,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83202694369334,
          21.00689668846,
          105.8321583719805,
          21.007193298004918
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8321583719805,
            21.00689668846
          ],
          [
            105.83202694369334,
            21.007193298004918
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "diemDau": "07",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.00689668846,
        "to_latitude": 21.007193298004918,
        "to_longitude": 105.83202694369334,
        "from_longitude": 105.8321583719805,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 318,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83188746882787,
          21.007193298004918,
          105.83202694369334,
          21.0073911089078
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83202694369334,
            21.007193298004918
          ],
          [
            105.83188746882787,
            21.0073911089078
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.007193298004918,
        "to_latitude": 21.0073911089078,
        "to_longitude": 105.83188746882787,
        "from_longitude": 105.83202694369334,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 319,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83164070559883,
          21.0073911089078,
          105.83188746882787,
          21.007751674942
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83188746882787,
            21.0073911089078
          ],
          [
            105.83164070559883,
            21.007751674942
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.0073911089078,
        "to_latitude": 21.007751674942,
        "to_longitude": 105.83164070559883,
        "from_longitude": 105.83188746882787,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 320,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8313671202744,
          21.0076114549214,
          105.83164070559883,
          21.007751674942
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83164070559883,
            21.007751674942
          ],
          [
            105.8313671202744,
            21.0076114549214
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "diemDau": "18",
        "diemCuoi": "18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.007751674942,
        "to_latitude": 21.0076114549214,
        "to_longitude": 105.8313671202744,
        "from_longitude": 105.83164070559883,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 321,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83054099990359,
          21.008179845976496,
          105.83091650915814,
          21.008315057626238
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83091650915814,
            21.008179845976496
          ],
          [
            105.83054099990359,
            21.008315057626238
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "diemDau": "21",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.008179845976496,
        "to_latitude": 21.008315057626238,
        "to_longitude": 105.83054099990359,
        "from_longitude": 105.83091650915814,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 322,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83018158389548,
          21.008315057626238,
          105.83054099990359,
          21.008462788733876
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83054099990359,
            21.008315057626238
          ],
          [
            105.83018158389548,
            21.008462788733876
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.008315057626238,
        "to_latitude": 21.008462788733876,
        "to_longitude": 105.83018158389548,
        "from_longitude": 105.83054099990359,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 323,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83013866854466,
          21.00824745181669,
          105.83022449923733,
          21.008462788733876
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83018158389548,
            21.008462788733876
          ],
          [
            105.83022449923733,
            21.008387671239564
          ],
          [
            105.83013866854466,
            21.00824745181669
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "diemDau": "26",
        "diemCuoi": "26-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.008462788733876,
        "to_latitude": 21.00824745181669,
        "to_longitude": 105.83013866854466,
        "from_longitude": 105.83018158389548,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 324,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83003138019005,
          21.008462788733876,
          105.83018158389548,
          21.008570457075933
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83018158389548,
            21.008462788733876
          ],
          [
            105.83003138019005,
            21.008570457075933
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.008462788733876,
        "to_latitude": 21.008570457075933,
        "to_longitude": 105.83003138019005,
        "from_longitude": 105.83018158389548,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 325,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82995359612063,
          21.008400190824425,
          105.83003138019005,
          21.008570457075933
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83003138019005,
            21.008570457075933
          ],
          [
            105.82995359612063,
            21.008400190824425
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "diemDau": "",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.008570457075933,
        "to_latitude": 21.008400190824425,
        "to_longitude": 105.82995359612063,
        "from_longitude": 105.83003138019005,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 326,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83156996229104,
          21.0088823013509,
          105.83168630316638,
          21.009156058822207
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83168630316638,
            21.0088823013509
          ],
          [
            105.83156996229104,
            21.009156058822207
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.0088823013509,
        "to_latitude": 21.009156058822207,
        "to_longitude": 105.83156996229104,
        "from_longitude": 105.83168630316638,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 327,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83112471559924,
          21.00904839090361,
          105.83144926288874,
          21.009183601766193
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83144926288874,
            21.009183601766193
          ],
          [
            105.83112471559924,
            21.00904839090361
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 75 Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "19-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.009183601766193,
        "to_latitude": 21.00904839090361,
        "to_longitude": 105.83112471559924,
        "from_longitude": 105.83144926288874,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 328,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83093696096748,
          21.008765449255975,
          105.83112471559924,
          21.00904839090361
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83112471559924,
            21.00904839090361
          ],
          [
            105.83093696096748,
            21.008765449255975
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 75 Đặng Văn Ngữ",
        "diemDau": "19-1",
        "diemCuoi": "19-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.00904839090361,
        "to_latitude": 21.008765449255975,
        "to_longitude": 105.83093696096748,
        "from_longitude": 105.83112471559924,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 329,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83094768979845,
          21.008923195550686,
          105.83112471559924,
          21.00904839090361
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83112471559924,
            21.00904839090361
          ],
          [
            105.83094768979845,
            21.008923195550686
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 75 Đặng Văn Ngữ",
        "diemDau": "19-1",
        "diemCuoi": "19-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.00904839090361,
        "to_latitude": 21.008923195550686,
        "to_longitude": 105.83094768979845,
        "from_longitude": 105.83112471559924,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 330,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83131783464648,
          21.00915856272687,
          105.83144926288874,
          21.009183601766193
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83144926288874,
            21.009183601766193
          ],
          [
            105.83131783464648,
            21.00915856272687
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 75 Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "19-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.009183601766193,
        "to_latitude": 21.00915856272687,
        "to_longitude": 105.83131783464648,
        "from_longitude": 105.83144926288874,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 331,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83168630316638,
          21.0088823013509,
          105.83196290587794,
          21.00890942405697
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83168630316638,
            21.0088823013509
          ],
          [
            105.83196290587794,
            21.00890942405697
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4A Đặng Văn Ngữ",
        "diemDau": "20",
        "diemCuoi": "20-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.0088823013509,
        "to_latitude": 21.00890942405697,
        "to_longitude": 105.83196290587794,
        "from_longitude": 105.83168630316638,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 332,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83196290587794,
          21.00890942405697,
          105.83208360528023,
          21.009017092076686
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83196290587794,
            21.00890942405697
          ],
          [
            105.83208360528023,
            21.009017092076686
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4A Đặng Văn Ngữ",
        "diemDau": "20-1",
        "diemCuoi": "20-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.00890942405697,
        "to_latitude": 21.009017092076686,
        "to_longitude": 105.83208360528023,
        "from_longitude": 105.83196290587794,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 333,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83144926288874,
          21.009156058822207,
          105.83156996229104,
          21.009183601766193
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83144926288874,
            21.009183601766193
          ],
          [
            105.83156996229104,
            21.009156058822207
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 75 Đặng Văn Ngữ",
        "diemDau": "19",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.009183601766193,
        "to_latitude": 21.009156058822207,
        "to_longitude": 105.83156996229104,
        "from_longitude": 105.83144926288874,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 334,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83148010825083,
          21.009628044018566,
          105.83169736718573,
          21.00969564920334
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83148010825083,
            21.009628044018566
          ],
          [
            105.83169736718573,
            21.00969564920334
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "diemDau": "18-1",
        "diemCuoi": "18-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.009628044018566,
        "to_latitude": 21.00969564920334,
        "to_longitude": 105.83169736718573,
        "from_longitude": 105.83148010825083,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 335,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83169736718573,
          21.00969564920334,
          105.83187439297754,
          21.009760750462966
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83169736718573,
            21.00969564920334
          ],
          [
            105.83187439297754,
            21.009760750462966
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "diemDau": "18-2",
        "diemCuoi": "18-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.00969564920334,
        "to_latitude": 21.009760750462966,
        "to_longitude": 105.83187439297754,
        "from_longitude": 105.83169736718573,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 336,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83143953982055,
          21.009156058822207,
          105.83156996229104,
          21.0094605958655
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83156996229104,
            21.009156058822207
          ],
          [
            105.83143953982055,
            21.0094605958655
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.009156058822207,
        "to_latitude": 21.0094605958655,
        "to_longitude": 105.83143953982055,
        "from_longitude": 105.83156996229104,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 337,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83143953982055,
          21.0094605958655,
          105.83148010825083,
          21.00962804401858
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83143953982055,
            21.0094605958655
          ],
          [
            105.83148010825083,
            21.00962804401858
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "diemDau": "18",
        "diemCuoi": "18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.0094605958655,
        "to_latitude": 21.00962804401858,
        "to_longitude": 105.83148010825083,
        "from_longitude": 105.83143953982055,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 338,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83108280602018,
          21.010339461856,
          105.8314945251152,
          21.01054978799027
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8314945251152,
            21.01054978799027
          ],
          [
            105.83108280602018,
            21.010339461856
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 52,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4C Đặng Văn Ngữ",
        "diemDau": "15",
        "diemCuoi": "15-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.01054978799027,
        "to_latitude": 21.010339461856,
        "to_longitude": 105.83108280602018,
        "from_longitude": 105.8314945251152,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 339,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83132420483375,
          21.0094605958655,
          105.83143953982055,
          21.009753551771233
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83143953982055,
            21.0094605958655
          ],
          [
            105.83132420483375,
            21.009753551771233
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.0094605958655,
        "to_latitude": 21.009753551771233,
        "to_longitude": 105.83132420483375,
        "from_longitude": 105.83143953982055,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 340,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83128665390738,
          21.009753551771233,
          105.83132420483375,
          21.009841188040955
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83132420483375,
            21.009753551771233
          ],
          [
            105.83128665390738,
            21.009841188040955
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.009753551771233,
        "to_latitude": 21.009841188040955,
        "to_longitude": 105.83128665390738,
        "from_longitude": 105.83132420483375,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 341,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83128665390738,
          21.009841188040955,
          105.83145831528373,
          21.009931328150877
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83128665390738,
            21.009841188040955
          ],
          [
            105.83145831528373,
            21.009931328150877
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà D5 TT Trung Tự",
        "diemDau": "",
        "diemCuoi": "17-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.009841188040955,
        "to_latitude": 21.009931328150877,
        "to_longitude": 105.83145831528373,
        "from_longitude": 105.83128665390738,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 342,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83145831528373,
          21.009931328150877,
          105.83178286257323,
          21.01005151487939
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83145831528373,
            21.009931328150877
          ],
          [
            105.83178286257323,
            21.01005151487939
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà D5 TT Trung Tự",
        "diemDau": "17-1",
        "diemCuoi": "17-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.009931328150877,
        "to_latitude": 21.01005151487939,
        "to_longitude": 105.83178286257323,
        "from_longitude": 105.83145831528373,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 343,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83108280602018,
          21.010061530436023,
          105.83119814100698,
          21.010339461856
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83108280602018,
            21.010339461856
          ],
          [
            105.83119814100698,
            21.010061530436023
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.010339461856,
        "to_latitude": 21.010061530436023,
        "to_longitude": 105.83119814100698,
        "from_longitude": 105.83108280602018,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 344,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83119814100698,
          21.009753551771233,
          105.83132420483375,
          21.010061530436023
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83119814100698,
            21.010061530436023
          ],
          [
            105.83132420483375,
            21.009753551771233
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.010061530436023,
        "to_latitude": 21.009753551771233,
        "to_longitude": 105.83132420483375,
        "from_longitude": 105.83119814100698,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 345,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8310211151972,
          21.010339461856,
          105.83108280602018,
          21.010479679315146
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83108280602018,
            21.010339461856
          ],
          [
            105.8310211151972,
            21.010479679315146
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.010339461856,
        "to_latitude": 21.010479679315146,
        "to_longitude": 105.8310211151972,
        "from_longitude": 105.83108280602018,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 346,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83081726731898,
          21.01029188804847,
          105.8310211151972,
          21.010479679315146
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8310211151972,
            21.010479679315146
          ],
          [
            105.83081726731898,
            21.01029188804847
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 81 Phố Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "15-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.010479679315146,
        "to_latitude": 21.01029188804847,
        "to_longitude": 105.83081726731898,
        "from_longitude": 105.8310211151972,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 347,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83074752988176,
          21.010099088768612,
          105.83081726731898,
          21.01029188804847
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83081726731898,
            21.01029188804847
          ],
          [
            105.83074752988176,
            21.010099088768612
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 81 Phố Đặng Văn Ngữ",
        "diemDau": "15-1",
        "diemCuoi": "15-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.01029188804847,
        "to_latitude": 21.010099088768612,
        "to_longitude": 105.83074752988176,
        "from_longitude": 105.83081726731898,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 348,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83065901698134,
          21.009878746429013,
          105.83074752988176,
          21.010099088768612
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83074752988176,
            21.010099088768612
          ],
          [
            105.83065901698134,
            21.009878746429013
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 81 Phố Đặng Văn Ngữ",
        "diemDau": "15-2",
        "diemCuoi": "15-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.010099088768612,
        "to_latitude": 21.009878746429013,
        "to_longitude": 105.83065901698134,
        "from_longitude": 105.83074752988176,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 349,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83096478879868,
          21.010339461856,
          105.83108280602018,
          21.01062740827932
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83108280602018,
            21.010339461856
          ],
          [
            105.83096478879868,
            21.01062740827932
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 81 Phố Đặng Văn Ngữ",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.010339461856,
        "to_latitude": 21.01062740827932,
        "to_longitude": 105.83096478879868,
        "from_longitude": 105.83108280602018,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 350,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8308843225305,
          21.01062740827932,
          105.83096478879868,
          21.01092036189373
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83096478879868,
            21.01062740827932
          ],
          [
            105.8308843225305,
            21.01092036189373
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.01062740827932,
        "to_latitude": 21.01092036189373,
        "to_longitude": 105.8308843225305,
        "from_longitude": 105.83096478879868,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 351,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8308843225305,
          21.010920361893756,
          105.83105598390684,
          21.011218322672207
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8308843225305,
            21.010920361893756
          ],
          [
            105.83089505137045,
            21.011068090421492
          ],
          [
            105.83105598390684,
            21.011218322672207
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.010920361893756,
        "to_latitude": 21.011218322672207,
        "to_longitude": 105.83105598390684,
        "from_longitude": 105.8308843225305,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 352,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83287720383503,
          21.0127256445467,
          105.83291743696913,
          21.012755690675064
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83291743696913,
            21.012755690675064
          ],
          [
            105.83287720383503,
            21.0127256445467
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 5,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012755690675064,
        "to_latitude": 21.0127256445467,
        "to_longitude": 105.83287720383503,
        "from_longitude": 105.83291743696913,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 353,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83263044059699,
          21.0125328484115,
          105.83287720383503,
          21.012725644546684
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83287720383503,
            21.012725644546684
          ],
          [
            105.83263044059699,
            21.0125328484115
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012725644546684,
        "to_latitude": 21.0125328484115,
        "to_longitude": 105.83263044059699,
        "from_longitude": 105.83287720383503,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 354,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83237026632924,
          21.012347563580672,
          105.83263044059699,
          21.0125328484115
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83263044059699,
            21.0125328484115
          ],
          [
            105.83237026632924,
            21.012347563580672
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.0125328484115,
        "to_latitude": 21.012347563580672,
        "to_longitude": 105.83237026632924,
        "from_longitude": 105.83263044059699,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 355,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83210204542927,
          21.012117209144296,
          105.83237026632924,
          21.012347563580672
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83237026632924,
            21.012347563580672
          ],
          [
            105.83210204542927,
            21.012117209144296
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012347563580672,
        "to_latitude": 21.012117209144296,
        "to_longitude": 105.83210204542927,
        "from_longitude": 105.83237026632924,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 356,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83184187115253,
          21.011874335060757,
          105.83210204542927,
          21.012117209144296
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83210204542927,
            21.012117209144296
          ],
          [
            105.83184187115253,
            21.011874335060757
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012117209144296,
        "to_latitude": 21.011874335060757,
        "to_longitude": 105.83184187115253,
        "from_longitude": 105.83210204542927,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 357,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83159779013123,
          21.011661506929713,
          105.83184187115253,
          21.011874335060757
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83184187115253,
            21.011874335060757
          ],
          [
            105.83159779013123,
            21.011661506929713
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.011874335060757,
        "to_latitude": 21.011661506929713,
        "to_longitude": 105.83159779013123,
        "from_longitude": 105.83184187115253,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 358,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83134432137385,
          21.01144867849669,
          105.83159779013123,
          21.011661506929713
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83159779013123,
            21.011661506929713
          ],
          [
            105.83134432137385,
            21.01144867849669
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.011661506929713,
        "to_latitude": 21.01144867849669,
        "to_longitude": 105.83134432137385,
        "from_longitude": 105.83159779013123,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 359,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83105598390684,
          21.011218322672207,
          105.83134432137385,
          21.01144867849669
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83134432137385,
            21.01144867849669
          ],
          [
            105.83105598390684,
            21.011218322672207
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.01144867849669,
        "to_latitude": 21.011218322672207,
        "to_longitude": 105.83105598390684,
        "from_longitude": 105.83134432137385,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 360,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83291743696913,
          21.012754751735347,
          105.83295197039156,
          21.012755690675064
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83291743696913,
            21.012755690675064
          ],
          [
            105.83295197039156,
            21.012754751735347
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 4,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012755690675064,
        "to_latitude": 21.012754751735347,
        "to_longitude": 105.83295197039156,
        "from_longitude": 105.83291743696913,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 361,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83287720383503,
          21.0127256445467,
          105.8329184427768,
          21.012829867029847
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83287720383503,
            21.0127256445467
          ],
          [
            105.8329184427768,
            21.012829867029847
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.0127256445467,
        "to_latitude": 21.012829867029847,
        "to_longitude": 105.8329184427768,
        "from_longitude": 105.83287720383503,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 362,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8329184427768,
          21.012829867029847,
          105.8330874219454,
          21.012970082146417
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8329184427768,
            21.012829867029847
          ],
          [
            105.8330874219454,
            21.012970082146417
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012829867029847,
        "to_latitude": 21.012970082146417,
        "to_longitude": 105.8330874219454,
        "from_longitude": 105.8329184427768,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 363,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8330874219454,
          21.012970082146417,
          105.83327115326557,
          21.013117808643393
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8330874219454,
            21.012970082146417
          ],
          [
            105.83327115326557,
            21.013117808643393
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012970082146417,
        "to_latitude": 21.013117808643393,
        "to_longitude": 105.83327115326557,
        "from_longitude": 105.8330874219454,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 364,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83349243551208,
          21.013286817592732,
          105.83380625396161,
          21.013558483428245
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83380625396161,
            21.013558483428245
          ],
          [
            105.83349243551208,
            21.013286817592732
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.013558483428245,
        "to_latitude": 21.013286817592732,
        "to_longitude": 105.83349243551208,
        "from_longitude": 105.83380625396161,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 365,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83327115326557,
          21.013117808643393,
          105.83349243551208,
          21.013286817592732
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83349243551208,
            21.013286817592732
          ],
          [
            105.83327115326557,
            21.013117808643393
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.013286817592732,
        "to_latitude": 21.013117808643393,
        "to_longitude": 105.83327115326557,
        "from_longitude": 105.83349243551208,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 366,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83380625396161,
          21.01355848342823,
          105.83403290060564,
          21.01376504928615
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83380625396161,
            21.01355848342823
          ],
          [
            105.83403290060564,
            21.01376504928615
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 68 Phố Đặng Văn Ngữ",
        "diemDau": "01",
        "diemCuoi": "1-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.01355848342823,
        "to_latitude": 21.01376504928615,
        "to_longitude": 105.83403290060564,
        "from_longitude": 105.83380625396161,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 367,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83380625396161,
          21.013429536114398,
          105.83401479567193,
          21.01355848342823
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83380625396161,
            21.01355848342823
          ],
          [
            105.83401479567193,
            21.013429536114398
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Phố Đặng Văn Ngữ",
        "diemDau": "01",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.01355848342823,
        "to_latitude": 21.013429536114398,
        "to_longitude": 105.83401479567193,
        "from_longitude": 105.83380625396161,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 368,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83380625396161,
          21.01351091064736,
          105.83385386313557,
          21.01355848342823
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83380625396161,
            21.01355848342823
          ],
          [
            105.83385386313557,
            21.01351091064736
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Phố Đặng Văn Ngữ",
        "diemDau": "01",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.01355848342823,
        "to_latitude": 21.01351091064736,
        "to_longitude": 105.83385386313557,
        "from_longitude": 105.83380625396161,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 369,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83385386313557,
          21.013366940289078,
          105.83399602020879,
          21.01351091064736
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83385386313557,
            21.01351091064736
          ],
          [
            105.83399602020879,
            21.013366940289078
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Phố Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "1-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.01351091064736,
        "to_latitude": 21.013366940289078,
        "to_longitude": 105.83399602020879,
        "from_longitude": 105.83385386313557,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 370,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83399602020879,
          21.013119060564996,
          105.83423607791846,
          21.013366940289078
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83399602020879,
            21.013366940289078
          ],
          [
            105.83423607791846,
            21.013119060564996
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Phố Đặng Văn Ngữ",
        "diemDau": "1-2",
        "diemCuoi": "1-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.013366940289078,
        "to_latitude": 21.013119060564996,
        "to_longitude": 105.83423607791846,
        "from_longitude": 105.83399602020879,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 371,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83423607791846,
          21.012921257257105,
          105.83439701045485,
          21.013119060564996
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83423607791846,
            21.013119060564996
          ],
          [
            105.83439701045485,
            21.012921257257105
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Phố Đặng Văn Ngữ",
        "diemDau": "1-3",
        "diemCuoi": "1-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.013119060564996,
        "to_latitude": 21.012921257257105,
        "to_longitude": 105.83439701045485,
        "from_longitude": 105.83423607791846,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 372,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83295197039156,
          21.012693407554536,
          105.83308406912734,
          21.012754751735347
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83295197039156,
            21.012754751735347
          ],
          [
            105.83308406912734,
            21.012693407554536
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 7,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "5-1",
        "diemCuoi": "5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012754751735347,
        "to_latitude": 21.012693407554536,
        "to_longitude": 105.83308406912734,
        "from_longitude": 105.83295197039156,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 373,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83308406912734,
          21.012693407554536,
          105.8332758470707,
          21.012886203482196
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83308406912734,
            21.012693407554536
          ],
          [
            105.8332758470707,
            21.01285740930141
          ],
          [
            105.83324366055984,
            21.012886203482196
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "5-2",
        "diemCuoi": "5-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012693407554536,
        "to_latitude": 21.012886203482196,
        "to_longitude": 105.83324366055984,
        "from_longitude": 105.83308406912734,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 374,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83324366055984,
          21.012886203482196,
          105.83348505937342,
          21.013084006836575
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83324366055984,
            21.012886203482196
          ],
          [
            105.83348505937342,
            21.013084006836575
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "5-3",
        "diemCuoi": "5-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012886203482196,
        "to_latitude": 21.013084006836575,
        "to_longitude": 105.83348505937342,
        "from_longitude": 105.83324366055984,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 375,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83295197039156,
          21.012370098242396,
          105.83334659033359,
          21.012754751735347
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83295197039156,
            21.012754751735347
          ],
          [
            105.83334659033359,
            21.012370098242396
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 51,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "5-1",
        "diemCuoi": "5-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012754751735347,
        "to_latitude": 21.012370098242396,
        "to_longitude": 105.83334659033359,
        "from_longitude": 105.83295197039156,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 376,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83245050925827,
          21.02435096069195,
          105.8325617091561,
          21.024507918542575
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83255098031616,
            21.02435096069195
          ],
          [
            105.8325617091561,
            21.024463624858544
          ],
          [
            105.83245050925827,
            21.024507918542575
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "diemDau": "14",
        "diemCuoi": "14-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02435096069195,
        "to_latitude": 21.024507918542575,
        "to_longitude": 105.83245050925827,
        "from_longitude": 105.83255098031616,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 377,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83234992641854,
          21.02439337667324,
          105.83245050925827,
          21.024507918542575
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83245050925827,
            21.024507918542575
          ],
          [
            105.83234992641854,
            21.02439337667324
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "diemDau": "14-1",
        "diemCuoi": "14-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.024507918542575,
        "to_latitude": 21.02439337667324,
        "to_longitude": 105.83234992641854,
        "from_longitude": 105.83245050925827,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 378,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83197978157497,
          21.02439337667324,
          105.83234992641854,
          21.024506666719102
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83234992641854,
            21.02439337667324
          ],
          [
            105.83197978157497,
            21.024506666719102
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "diemDau": "14-2",
        "diemCuoi": "14-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02439337667324,
        "to_latitude": 21.024506666719102,
        "to_longitude": 105.83197978157497,
        "from_longitude": 105.83234992641854,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 379,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83197978157497,
          21.024506666719102,
          105.83201621489832,
          21.024611027013563
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83197978157497,
            21.024506666719102
          ],
          [
            105.83201621489832,
            21.024611027013563
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "diemDau": "14-3",
        "diemCuoi": "14-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.024506666719102,
        "to_latitude": 21.024611027013563,
        "to_longitude": 105.83201621489832,
        "from_longitude": 105.83197978157497,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 380,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83201621489832,
          21.024611027013563,
          105.83206684159414,
          21.024749040366025
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83201621489832,
            21.024611027013563
          ],
          [
            105.83206684159414,
            21.024749040366025
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "diemDau": "14-4",
        "diemCuoi": "14-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.024611027013563,
        "to_latitude": 21.024749040366025,
        "to_longitude": 105.83206684159414,
        "from_longitude": 105.83201621489832,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 381,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83176140503704,
          21.024611027013563,
          105.83201621489832,
          21.02468863997973
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83201621489832,
            21.024611027013563
          ],
          [
            105.83176140503704,
            21.02468863997973
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "diemDau": "14-4",
        "diemCuoi": "14-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.024611027013563,
        "to_latitude": 21.02468863997973,
        "to_longitude": 105.83176140503704,
        "from_longitude": 105.83201621489832,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 382,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83156023936657,
          21.02468863997973,
          105.83176140503704,
          21.024743720125407
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83176140503704,
            21.02468863997973
          ],
          [
            105.83156023936657,
            21.024743720125407
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "diemDau": "14-6",
        "diemCuoi": "14-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02468863997973,
        "to_latitude": 21.024743720125407,
        "to_longitude": 105.83156023936657,
        "from_longitude": 105.83176140503704,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 383,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83136086182559,
          21.024743720125407,
          105.83156023936657,
          21.024826340304113
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83156023936657,
            21.024743720125407
          ],
          [
            105.83136086182559,
            21.024826340304113
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "diemDau": "14-7",
        "diemCuoi": "14-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.024743720125407,
        "to_latitude": 21.024826340304113,
        "to_longitude": 105.83136086182559,
        "from_longitude": 105.83156023936657,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 384,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83116863684758,
          21.0247687565481,
          105.83136086182559,
          21.024826653260362
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83136086182559,
            21.024826340304113
          ],
          [
            105.8313413040496,
            21.02481538687446
          ],
          [
            105.83130241201937,
            21.024826653260362
          ],
          [
            105.83116863684758,
            21.0247687565481
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "diemDau": "14-8",
        "diemCuoi": "14-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.024826340304113,
        "to_latitude": 21.0247687565481,
        "to_longitude": 105.83116863684758,
        "from_longitude": 105.83136086182559,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 385,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8317409531918,
          21.02402047864686,
          105.83199308084531,
          21.024096840093602
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8317409531918,
            21.024076810864848
          ],
          [
            105.83177045749491,
            21.024096840093602
          ],
          [
            105.83199308084531,
            21.02402047864686
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "diemDau": "14-11",
        "diemCuoi": "14-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.024076810864848,
        "to_latitude": 21.02402047864686,
        "to_longitude": 105.83199308084531,
        "from_longitude": 105.8317409531918,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 386,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83190356211925,
          21.024325611242656,
          105.83197978157497,
          21.024506666719102
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83197978157497,
            21.024506666719102
          ],
          [
            105.83195016549449,
            21.024473639447265
          ],
          [
            105.83190356211925,
            21.024325611242656
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "diemDau": "14-3",
        "diemCuoi": "14-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.024506666719102,
        "to_latitude": 21.024325611242656,
        "to_longitude": 105.83190356211925,
        "from_longitude": 105.83197978157497,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 387,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8317409531918,
          21.024076810864848,
          105.83190356211925,
          21.024325611242656
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83190356211925,
            21.024325611242656
          ],
          [
            105.83186165260307,
            21.024303391352465
          ],
          [
            105.8317409531918,
            21.024076810864848
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "diemDau": "14-10",
        "diemCuoi": "14-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.024325611242656,
        "to_latitude": 21.024076810864848,
        "to_longitude": 105.8317409531918,
        "from_longitude": 105.83190356211925,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 388,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8328057901774,
          21.024472867488008,
          105.83300706311572,
          21.02458724238721
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83282221872902,
            21.02458724238721
          ],
          [
            105.8328057901774,
            21.024536230610302
          ],
          [
            105.83300706311572,
            21.024472867488008
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 105 Tôn Đức Thắng",
        "diemDau": "08",
        "diemCuoi": "8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02458724238721,
        "to_latitude": 21.024472867488008,
        "to_longitude": 105.83300706311572,
        "from_longitude": 105.83282221872902,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 389,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83258350212321,
          21.023890847346014,
          105.83289183656454,
          21.02405146136895
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83258350212321,
            21.02405146136895
          ],
          [
            105.83258584903476,
            21.02401046402771
          ],
          [
            105.83289183656454,
            21.023890847346014
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 107A Tôn Đức Thắng",
        "diemDau": "06",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02405146136895,
        "to_latitude": 21.023890847346014,
        "to_longitude": 105.83289183656454,
        "from_longitude": 105.83258350212321,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 390,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83289183656454,
          21.0238733217472,
          105.8331385997936,
          21.023912821458474
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83289183656454,
            21.023890847346014
          ],
          [
            105.83292648957968,
            21.02388027392063
          ],
          [
            105.83294526504287,
            21.02390280683207
          ],
          [
            105.83299622701692,
            21.02388027392063
          ],
          [
            105.83301232026336,
            21.023912821458474
          ],
          [
            105.8331385997936,
            21.0238733217472
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 107A Tôn Đức Thắng",
        "diemDau": "6-1",
        "diemCuoi": "6-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.023890847346014,
        "to_latitude": 21.0238733217472,
        "to_longitude": 105.8331385997936,
        "from_longitude": 105.83289183656454,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 391,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8331385997936,
          21.02379070103947,
          105.83331830780213,
          21.0238733217472
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8331385997936,
            21.0238733217472
          ],
          [
            105.83331830780213,
            21.02379070103947
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 107A Tôn Đức Thắng",
        "diemDau": "6-2",
        "diemCuoi": "6-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.0238733217472,
        "to_latitude": 21.02379070103947,
        "to_longitude": 105.83331830780213,
        "from_longitude": 105.8331385997936,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 392,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83201085048282,
          21.022748616664888,
          105.8323176281348,
          21.022898837149064
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83201085048282,
            21.02282842131457
          ],
          [
            105.83206013606579,
            21.022898837149064
          ],
          [
            105.8323176281348,
            21.022748616664888
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Huy Văn",
        "diemDau": "02",
        "diemCuoi": "2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02282842131457,
        "to_latitude": 21.022748616664888,
        "to_longitude": 105.8323176281348,
        "from_longitude": 105.83201085048282,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 393,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8325566800278,
          21.02240321329539,
          105.83278645593816,
          21.022596831228856
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8325566800278,
            21.022596831228856
          ],
          [
            105.83278645593816,
            21.02240321329539
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Huy Văn",
        "diemDau": "2-2",
        "diemCuoi": "2-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.022596831228856,
        "to_latitude": 21.02240321329539,
        "to_longitude": 105.83278645593816,
        "from_longitude": 105.8325566800278,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 394,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83266128618367,
          21.02226884897887,
          105.83278645593816,
          21.02240321329539
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83278645593816,
            21.02240321329539
          ],
          [
            105.83266128618367,
            21.02226884897887
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Huy Văn",
        "diemDau": "2-3",
        "diemCuoi": "2-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02240321329539,
        "to_latitude": 21.02226884897887,
        "to_longitude": 105.83266128618367,
        "from_longitude": 105.83278645593816,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 395,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8323176281348,
          21.022596831228856,
          105.8325566800278,
          21.022748616664888
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8323176281348,
            21.022748616664888
          ],
          [
            105.8325566800278,
            21.022596831228856
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Huy Văn",
        "diemDau": "2-1",
        "diemCuoi": "2-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.022748616664888,
        "to_latitude": 21.022596831228856,
        "to_longitude": 105.8325566800278,
        "from_longitude": 105.8323176281348,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 396,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83226431924021,
          21.022748616664888,
          105.8323176281348,
          21.022869731941213
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8323176281348,
            21.022748616664888
          ],
          [
            105.83226431924021,
            21.022803384566025
          ],
          [
            105.83230187017554,
            21.022869731941213
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Huy Văn",
        "diemDau": "2-1",
        "diemCuoi": "2-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.022748616664888,
        "to_latitude": 21.022869731941213,
        "to_longitude": 105.83230187017554,
        "from_longitude": 105.8323176281348,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 397,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83189149219338,
          21.022510454293844,
          105.83208327012781,
          21.022564283404535
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83189149219338,
            21.02253924661164
          ],
          [
            105.8319049032321,
            21.022564283404535
          ],
          [
            105.83208327012781,
            21.022510454293844
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ163 Tôn Đức Thắng",
        "diemDau": "01",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02253924661164,
        "to_latitude": 21.022510454293844,
        "to_longitude": 105.83208327012781,
        "from_longitude": 105.83189149219338,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 398,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8320779057123,
          21.022410307060852,
          105.83222006278552,
          21.022510454293844
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83208327012781,
            21.022510454293844
          ],
          [
            105.8320779057123,
            21.022474150929902
          ],
          [
            105.83222006278552,
            21.022410307060852
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ163 Tôn Đức Thắng",
        "diemDau": "1-1",
        "diemCuoi": "1-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.022510454293844,
        "to_latitude": 21.022410307060852,
        "to_longitude": 105.83222006278552,
        "from_longitude": 105.83208327012781,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 399,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83222006278552,
          21.022400292333522,
          105.83229516463823,
          21.022422825468656
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83222006278552,
            21.022410307060852
          ],
          [
            105.83224152046542,
            21.022421573627664
          ],
          [
            105.83228175359952,
            21.022400292333522
          ],
          [
            105.83229516463823,
            21.022422825468656
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ163 Tôn Đức Thắng",
        "diemDau": "1-2",
        "diemCuoi": "1-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.022410307060852,
        "to_latitude": 21.022422825468656,
        "to_longitude": 105.83229516463823,
        "from_longitude": 105.83222006278552,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 400,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8320644946646,
          21.02233895211539,
          105.83214361984689,
          21.022510454293844
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83208327012781,
            21.022510454293844
          ],
          [
            105.8320644946646,
            21.022464136206874
          ],
          [
            105.83210741001542,
            21.02244160307796
          ],
          [
            105.83208327012781,
            21.022376507354444
          ],
          [
            105.83214361984689,
            21.02233895211539
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ163 Tôn Đức Thắng",
        "diemDau": "1-1",
        "diemCuoi": "1-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.022510454293844,
        "to_latitude": 21.02233895211539,
        "to_longitude": 105.83214361984689,
        "from_longitude": 105.83208327012781,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 401,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83214361984689,
          21.022256330558157,
          105.83233137447866,
          21.02233895211539
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83214361984689,
            21.02233895211539
          ],
          [
            105.83233137447866,
            21.022256330558157
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ163 Tôn Đức Thắng",
        "diemDau": "1-4",
        "diemCuoi": "1-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02233895211539,
        "to_latitude": 21.022256330558157,
        "to_longitude": 105.83233137447866,
        "from_longitude": 105.83214361984689,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 402,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83189149219338,
          21.02253924661164,
          105.83201085048282,
          21.02282842131457
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83189149219338,
            21.02253924661164
          ],
          [
            105.83201085048282,
            21.02282842131457
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02253924661164,
        "to_latitude": 21.02282842131457,
        "to_longitude": 105.83201085048282,
        "from_longitude": 105.83189149219338,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 403,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83201085048282,
          21.02282842131457,
          105.83215852100301,
          21.02311787364309
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83201085048282,
            21.02282842131457
          ],
          [
            105.83215852100301,
            21.02311787364309
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02282842131457,
        "to_latitude": 21.02311787364309,
        "to_longitude": 105.83215852100301,
        "from_longitude": 105.83201085048282,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 404,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83215852100301,
          21.02311787364309,
          105.83227236587241,
          21.02336045123002
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83215852100301,
            21.02311787364309
          ],
          [
            105.83227236587241,
            21.02336045123002
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02311787364309,
        "to_latitude": 21.02336045123002,
        "to_longitude": 105.83227236587241,
        "from_longitude": 105.83215852100301,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 405,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83227236587241,
          21.02336045123002,
          105.83242554981264,
          21.02369649842878
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83227236587241,
            21.02336045123002
          ],
          [
            105.83242554981264,
            21.02369649842878
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02336045123002,
        "to_latitude": 21.02369649842878,
        "to_longitude": 105.83242554981264,
        "from_longitude": 105.83227236587241,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 406,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83242554981264,
          21.02369649842878,
          105.83258350212321,
          21.02405146136895
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83242554981264,
            21.02369649842878
          ],
          [
            105.83258350212321,
            21.02405146136895
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02369649842878,
        "to_latitude": 21.02405146136895,
        "to_longitude": 105.83258350212321,
        "from_longitude": 105.83242554981264,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 407,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83228175359052,
          21.02376354086893,
          105.83241016434681,
          21.024043011536275
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83241016434681,
            21.024043011536275
          ],
          [
            105.83228175359052,
            21.02376354086893
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "16",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.024043011536275,
        "to_latitude": 21.02376354086893,
        "to_longitude": 105.83228175359052,
        "from_longitude": 105.83241016434681,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 408,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83212216217598,
          21.02341302820115,
          105.83228175359052,
          21.02376354086893
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83228175359052,
            21.02376354086893
          ],
          [
            105.83212216217598,
            21.02341302820115
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "17",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02376354086893,
        "to_latitude": 21.02341302820115,
        "to_longitude": 105.83212216217598,
        "from_longitude": 105.83228175359052,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 409,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83258350212321,
          21.02405146136895,
          105.83271493036543,
          21.024334374015726
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83258350212321,
            21.02405146136895
          ],
          [
            105.83271493036543,
            21.024334374015726
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02405146136895,
        "to_latitude": 21.024334374015726,
        "to_longitude": 105.83271493036543,
        "from_longitude": 105.83258350212321,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 410,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83241016434681,
          21.024043011536275,
          105.83255098031616,
          21.024350960691965
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83255098031616,
            21.024350960691965
          ],
          [
            105.83241016434681,
            21.024043011536275
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "15",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.024350960691965,
        "to_latitude": 21.024043011536275,
        "to_longitude": 105.83241016434681,
        "from_longitude": 105.83255098031616,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 411,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83187510092286,
          21.022899219653805,
          105.83200623114101,
          21.02318658545383
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83187510092286,
            21.022899219653805
          ],
          [
            105.83200623114101,
            21.02318658545383
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "19",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.022899219653805,
        "to_latitude": 21.02318658545383,
        "to_longitude": 105.83200623114101,
        "from_longitude": 105.83187510092286,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 412,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83200623114101,
          21.02318658545383,
          105.83212216217598,
          21.02341302820115
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83200623114101,
            21.02318658545383
          ],
          [
            105.83212216217598,
            21.02341302820115
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "18",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02318658545383,
        "to_latitude": 21.02341302820115,
        "to_longitude": 105.83212216217598,
        "from_longitude": 105.83200623114101,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 413,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83271493036543,
          21.024334374015726,
          105.83282221872902,
          21.02458724238721
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83271493036543,
            21.024334374015726
          ],
          [
            105.83282221872902,
            21.02458724238721
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.024334374015726,
        "to_latitude": 21.02458724238721,
        "to_longitude": 105.83282221872902,
        "from_longitude": 105.83271493036543,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 414,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83282221872902,
          21.02458724238721,
          105.8329596074319,
          21.0248537412624
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83282221872902,
            21.02458724238721
          ],
          [
            105.8329596074319,
            21.0248537412624
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 355,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02458724238721,
        "to_latitude": 21.0248537412624,
        "to_longitude": 105.8329596074319,
        "from_longitude": 105.83282221872902,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 415,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8329596074319,
          21.0248537412624,
          105.83309312183673,
          21.025143050567124
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8329596074319,
            21.0248537412624
          ],
          [
            105.83309312183673,
            21.025143050567124
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.0248537412624,
        "to_latitude": 21.025143050567124,
        "to_longitude": 105.83309312183673,
        "from_longitude": 105.8329596074319,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 416,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83279301244997,
          21.0249107686216,
          105.8329241426681,
          21.025198130544084
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8329241426681,
            21.025198130544084
          ],
          [
            105.83279301244997,
            21.0249107686216
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "12",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.025198130544084,
        "to_latitude": 21.0249107686216,
        "to_longitude": 105.83279301244997,
        "from_longitude": 105.8329241426681,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 417,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8327122481577,
          21.024709920947224,
          105.83279301244997,
          21.0249107686216
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83279301244997,
            21.0249107686216
          ],
          [
            105.8327122481577,
            21.024709920947224
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "13",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.0249107686216,
        "to_latitude": 21.024709920947224,
        "to_longitude": 105.8327122481577,
        "from_longitude": 105.83279301244997,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 418,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83255098031616,
          21.024350960691965,
          105.8327122481577,
          21.024709920947224
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8327122481577,
            21.024709920947224
          ],
          [
            105.83255098031616,
            21.024350960691965
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "14",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.024709920947224,
        "to_latitude": 21.024350960691965,
        "to_longitude": 105.83255098031616,
        "from_longitude": 105.8327122481577,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 419,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83174397070471,
          21.02262437169067,
          105.83187510092286,
          21.022899219653805
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83187510092286,
            21.022899219653805
          ],
          [
            105.83174397070471,
            21.02262437169067
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "20",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.022899219653805,
        "to_latitude": 21.02262437169067,
        "to_longitude": 105.83174397070471,
        "from_longitude": 105.83187510092286,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 420,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83174397070471,
          21.02253924661164,
          105.83189149219338,
          21.02262437169067
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83189149219338,
            21.02253924661164
          ],
          [
            105.83174397070471,
            21.02262437169067
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "01",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02253924661164,
        "to_latitude": 21.02262437169067,
        "to_longitude": 105.83174397070471,
        "from_longitude": 105.83189149219338,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 421,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83242554981264,
          21.023668401796524,
          105.83244670942953,
          21.02369649842878
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8324359806255,
            21.023668401796524
          ],
          [
            105.83244670942953,
            21.023688431085173
          ],
          [
            105.83242554981264,
            21.02369649842878
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "tủ điện",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.023668401796524,
        "to_latitude": 21.02369649842878,
        "to_longitude": 105.83242554981264,
        "from_longitude": 105.8324359806255,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 422,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83189953878967,
          21.023589536474702,
          105.83228175359051,
          21.023763540868917
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83228175359051,
            21.023763540868917
          ],
          [
            105.83216775968066,
            21.023589536474702
          ],
          [
            105.83189953878967,
            21.023684675594065
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 60,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 162 B Tôn Đức Thắng",
        "diemDau": "16",
        "diemCuoi": "16-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.023763540868917,
        "to_latitude": 21.023684675594065,
        "to_longitude": 105.83189953878967,
        "from_longitude": 105.83228175359051,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 423,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83189953878967,
          21.023684675594065,
          105.8319692762179,
          21.02385867988229
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83189953878967,
            21.023684675594065
          ],
          [
            105.8319692762179,
            21.02385867988229
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 162 B Tôn Đức Thắng",
        "diemDau": "16-1",
        "diemCuoi": "16-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.023684675594065,
        "to_latitude": 21.02385867988229,
        "to_longitude": 105.8319692762179,
        "from_longitude": 105.83189953878967,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 424,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83164472892841,
          21.023684675594065,
          105.83189953878967,
          21.023791081118578
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83189953878967,
            21.023684675594065
          ],
          [
            105.83164472892841,
            21.023791081118578
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 162 B Tôn Đức Thắng",
        "diemDau": "16-1",
        "diemCuoi": "16-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.023684675594065,
        "to_latitude": 21.023791081118578,
        "to_longitude": 105.83164472892841,
        "from_longitude": 105.83189953878967,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 425,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8314314933141,
          21.023791081118578,
          105.83164472892841,
          21.023877457311567
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83164472892841,
            21.023791081118578
          ],
          [
            105.8314314933141,
            21.023877457311567
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 162 B Tôn Đức Thắng",
        "diemDau": "16-3",
        "diemCuoi": "16-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.023791081118578,
        "to_latitude": 21.023877457311567,
        "to_longitude": 105.8314314933141,
        "from_longitude": 105.83164472892841,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 426,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8314314933141,
          21.023877457311567,
          105.83144624546567,
          21.02397134442095
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8314314933141,
            21.023877457311567
          ],
          [
            105.83144624546567,
            21.02397134442095
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 162 B Tôn Đức Thắng",
        "diemDau": "16-4",
        "diemCuoi": "16-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.023877457311567,
        "to_latitude": 21.02397134442095,
        "to_longitude": 105.83144624546567,
        "from_longitude": 105.8314314933141,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 427,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83141975867545,
          21.02262437169067,
          105.83174397070471,
          21.022845634077488
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83174397070471,
            21.02262437169067
          ],
          [
            105.8317342476904,
            21.022670554111173
          ],
          [
            105.83141975867545,
            21.022845634077488
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "diemDau": "20",
        "diemCuoi": "20-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02262437169067,
        "to_latitude": 21.022845634077488,
        "to_longitude": 105.83141975867545,
        "from_longitude": 105.83174397070471,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 428,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83118505644302,
          21.022845634077488,
          105.83141975867545,
          21.02296017713434
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83141975867545,
            21.022845634077488
          ],
          [
            105.83118505644302,
            21.02296017713434
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "diemDau": "20-1",
        "diemCuoi": "20-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.022845634077488,
        "to_latitude": 21.02296017713434,
        "to_longitude": 105.83118505644302,
        "from_longitude": 105.83141975867545,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 429,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83090846258882,
          21.02296017713434,
          105.83118505644302,
          21.023090055089238
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83118505644302,
            21.02296017713434
          ],
          [
            105.83090846258882,
            21.023090055089238
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "diemDau": "20-2",
        "diemCuoi": "20-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02296017713434,
        "to_latitude": 21.023090055089238,
        "to_longitude": 105.83090846258882,
        "from_longitude": 105.83118505644302,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 430,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83090476559921,
          21.023090055089238,
          105.83090846258882,
          21.023171737306082
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83090846258882,
            21.023090055089238
          ],
          [
            105.83090476559921,
            21.023171737306082
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "diemDau": "20-3",
        "diemCuoi": "20-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.023090055089238,
        "to_latitude": 21.023171737306082,
        "to_longitude": 105.83090476559921,
        "from_longitude": 105.83090846258882,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 431,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83090476559921,
          21.023171737306082,
          105.83101809788192,
          21.023391611432494
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83090476559921,
            21.023171737306082
          ],
          [
            105.83101809788192,
            21.023391611432494
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "diemDau": "20-4",
        "diemCuoi": "20-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.023171737306082,
        "to_latitude": 21.023391611432494,
        "to_longitude": 105.83101809788192,
        "from_longitude": 105.83090476559921,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 432,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83058826493296,
          21.023171737306082,
          105.83090476559921,
          21.023234329015764
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83090476559921,
            21.023171737306082
          ],
          [
            105.8308276610424,
            21.023216354765623
          ],
          [
            105.83071500826335,
            21.02317629606967
          ],
          [
            105.83058826493296,
            21.023234329015764
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "diemDau": "20-4",
        "diemCuoi": "20-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.023171737306082,
        "to_latitude": 21.023234329015764,
        "to_longitude": 105.83058826493296,
        "from_longitude": 105.83090476559921,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 433,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8304300146043,
          21.023234329015764,
          105.83058826493296,
          21.023266876693796
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83058826493296,
            21.023234329015764
          ],
          [
            105.8304300146043,
            21.023266876693796
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "diemDau": "20-6",
        "diemCuoi": "20-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.023234329015764,
        "to_latitude": 21.023266876693796,
        "to_longitude": 105.8304300146043,
        "from_longitude": 105.83058826493296,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 434,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83030463028119,
          21.023266876693796,
          105.8304300146043,
          21.023329019789678
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8304300146043,
            21.023266876693796
          ],
          [
            105.83042264747573,
            21.023293968457917
          ],
          [
            105.83030463028119,
            21.023329019789678
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "diemDau": "20-7",
        "diemCuoi": "20-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.023266876693796,
        "to_latitude": 21.023329019789678,
        "to_longitude": 105.83030463028119,
        "from_longitude": 105.8304300146043,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 435,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83021543788612,
          21.02328395378996,
          105.83030463028119,
          21.023329019789678
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83030463028119,
            21.023329019789678
          ],
          [
            105.83027512598706,
            21.02328395378996
          ],
          [
            105.83021543788612,
            21.02330568353184
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "diemDau": "20-8",
        "diemCuoi": "20-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.023329019789678,
        "to_latitude": 21.02330568353184,
        "to_longitude": 105.83021543788612,
        "from_longitude": 105.83030463028119,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 436,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83013196307022,
          21.023142632153196,
          105.83021543788612,
          21.02330568353184
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83021543788612,
            21.02330568353184
          ],
          [
            105.83013196307022,
            21.023142632153196
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "diemDau": "20-9",
        "diemCuoi": "20-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02330568353184,
        "to_latitude": 21.023142632153196,
        "to_longitude": 105.83013196307022,
        "from_longitude": 105.83021543788612,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 437,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83023153114156,
          21.02293889591707,
          105.83032944070732,
          21.023054690740466
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83032944070732,
            21.023054690740466
          ],
          [
            105.83030463028119,
            21.02295847672377
          ],
          [
            105.83026171493937,
            21.02295847672377
          ],
          [
            105.83023153114156,
            21.02293889591707
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "diemDau": "20-11",
        "diemCuoi": "20-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.023054690740466,
        "to_latitude": 21.02293889591707,
        "to_longitude": 105.83023153114156,
        "from_longitude": 105.83032944070732,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 438,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83032944070732,
          21.022982397226407,
          105.83054502326011,
          21.023054690740466
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83032944070732,
            21.023054690740466
          ],
          [
            105.83054502326011,
            21.022982397226407
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "diemDau": "20-13",
        "diemCuoi": "20-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.023054690740466,
        "to_latitude": 21.022982397226407,
        "to_longitude": 105.83054502326011,
        "from_longitude": 105.83032944070732,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 439,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83054502326011,
          21.02280074527469,
          105.83097685891319,
          21.022982397226407
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83054502326011,
            21.022982397226407
          ],
          [
            105.83060771989977,
            21.022981009774554
          ],
          [
            105.83093226718927,
            21.02280074527469
          ],
          [
            105.83097685891319,
            21.022867228266733
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 60,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "diemDau": "20-16",
        "diemCuoi": "20-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.022982397226407,
        "to_latitude": 21.022867228266733,
        "to_longitude": 105.83097685891319,
        "from_longitude": 105.83054502326011,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 440,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83097685891319,
          21.022860833465526,
          105.83118505644302,
          21.022963484068782
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83097685891319,
            21.022867228266733
          ],
          [
            105.83102078008967,
            21.022860833465526
          ],
          [
            105.83105564880827,
            21.022863337139267
          ],
          [
            105.83111465740555,
            21.022963484068782
          ],
          [
            105.83118505644302,
            21.02296017713434
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "diemDau": "20-2",
        "diemCuoi": "20-16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.022867228266733,
        "to_latitude": 21.02296017713434,
        "to_longitude": 105.83118505644302,
        "from_longitude": 105.83097685891319,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 441,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83097685891319,
          21.022833293047388,
          105.83108983802325,
          21.022893829800225
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83097685891319,
            21.022867228266733
          ],
          [
            105.83105296660054,
            21.022833293047388
          ],
          [
            105.83108983802325,
            21.022893829800225
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "diemDau": "20-16",
        "diemCuoi": "20-17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.022867228266733,
        "to_latitude": 21.022893829800225,
        "to_longitude": 105.83108983802325,
        "from_longitude": 105.83097685891319,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 442,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83081223833443,
          21.02280074527469,
          105.83093226718927,
          21.022834367541883
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83093226718927,
            21.02280074527469
          ],
          [
            105.83081223833443,
            21.022834367541883
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "diemDau": "20-16",
        "diemCuoi": "20-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02280074527469,
        "to_latitude": 21.022834367541883,
        "to_longitude": 105.83081223833443,
        "from_longitude": 105.83093226718927,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 443,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83066069352513,
          21.022808256299673,
          105.83081223833443,
          21.022871922656172
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83081223833443,
            21.022834367541883
          ],
          [
            105.83078474569157,
            21.022808256299673
          ],
          [
            105.83066069352513,
            21.022871922656172
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "diemDau": "20-15",
        "diemCuoi": "20-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.022834367541883,
        "to_latitude": 21.022871922656172,
        "to_longitude": 105.83066069352513,
        "from_longitude": 105.83081223833443,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 444,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83184589459889,
          21.02341302820115,
          105.83212216217598,
          21.023536959565817
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83212216217598,
            21.02341302820115
          ],
          [
            105.83209902811399,
            21.02344669205657
          ],
          [
            105.83184589459889,
            21.023536959565817
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "diemDau": "17",
        "diemCuoi": "17-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02341302820115,
        "to_latitude": 21.023536959565817,
        "to_longitude": 105.83184589459889,
        "from_longitude": 105.83212216217598,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 445,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83156962707571,
          21.023536959565817,
          105.83184589459889,
          21.02365713532979
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83184589459889,
            21.023536959565817
          ],
          [
            105.83156962707571,
            21.02365713532979
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "diemDau": "17-1",
        "diemCuoi": "17-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.023536959565817,
        "to_latitude": 21.02365713532979,
        "to_longitude": 105.83156962707571,
        "from_longitude": 105.83184589459889,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 446,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83118070676447,
          21.02365713532979,
          105.83156962707571,
          21.023789829289925
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83156962707571,
            21.02365713532979
          ],
          [
            105.83118070676447,
            21.023789829289925
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "diemDau": "17-2",
        "diemCuoi": "17-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02365713532979,
        "to_latitude": 21.023789829289925,
        "to_longitude": 105.83118070676447,
        "from_longitude": 105.83156962707571,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 447,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83094574525488,
          21.023789829289925,
          105.83118070676447,
          21.023859430980675
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83118070676447,
            21.023789829289925
          ],
          [
            105.83094574525488,
            21.023859430980675
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "diemDau": "17-3",
        "diemCuoi": "17-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.023789829289925,
        "to_latitude": 21.023859430980675,
        "to_longitude": 105.83094574525488,
        "from_longitude": 105.83118070676447,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 448,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83066357686747,
          21.023859430980675,
          105.83094574525488,
          21.023956072122736
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83094574525488,
            21.023859430980675
          ],
          [
            105.83066357686747,
            21.023956072122736
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "diemDau": "17-4",
        "diemCuoi": "17-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.023859430980675,
        "to_latitude": 21.023956072122736,
        "to_longitude": 105.83066357686747,
        "from_longitude": 105.83094574525488,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 449,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8304994256746,
          21.023956072122736,
          105.83066357686747,
          21.023980107215674
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83066357686747,
            21.023956072122736
          ],
          [
            105.8304994256746,
            21.023980107215674
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "diemDau": "17-5",
        "diemCuoi": "17-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.023956072122736,
        "to_latitude": 21.023980107215674,
        "to_longitude": 105.8304994256746,
        "from_longitude": 105.83066357686747,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 450,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83066357686747,
          21.023956072122736,
          105.83074618890365,
          21.02404520223981
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83066357686747,
            21.023956072122736
          ],
          [
            105.83074618890365,
            21.02404520223981
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "diemDau": "17-5-1",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.023956072122736,
        "to_latitude": 21.02404520223981,
        "to_longitude": 105.83074618890365,
        "from_longitude": 105.83066357686747,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 451,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83074618890365,
          21.02404520223981,
          105.83084006621954,
          21.024255509046206
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83074618890365,
            21.02404520223981
          ],
          [
            105.83084006621954,
            21.024255509046206
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "diemDau": "17-5-1",
        "diemCuoi": "17-5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02404520223981,
        "to_latitude": 21.024255509046206,
        "to_longitude": 105.83084006621954,
        "from_longitude": 105.83074618890365,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 452,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83118070676447,
          21.023789829289925,
          105.83125312640942,
          21.024027676659152
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83118070676447,
            21.023789829289925
          ],
          [
            105.83125312640942,
            21.024027676659152
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "diemDau": "17-3",
        "diemCuoi": "17-3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.023789829289925,
        "to_latitude": 21.024027676659152,
        "to_longitude": 105.83125312640942,
        "from_longitude": 105.83118070676447,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 453,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83087761714587,
          21.02369719389654,
          105.83094574525488,
          21.023859430980675
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83094574525488,
            21.023859430980675
          ],
          [
            105.83087761714587,
            21.02369719389654
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "diemDau": "17-4",
        "diemCuoi": "17-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.023859430980675,
        "to_latitude": 21.02369719389654,
        "to_longitude": 105.83087761714587,
        "from_longitude": 105.83094574525488,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 454,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83087761714587,
          21.023597047459933,
          105.83102513864355,
          21.02369719389654
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83087761714587,
            21.02369719389654
          ],
          [
            105.83102513864355,
            21.023597047459933
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "diemDau": "17-7",
        "diemCuoi": "17-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02369719389654,
        "to_latitude": 21.023597047459933,
        "to_longitude": 105.83102513864355,
        "from_longitude": 105.83087761714587,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 455,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83156962707571,
          21.023494261670265,
          105.83168328571637,
          21.02365713532979
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83156962707571,
            21.02365713532979
          ],
          [
            105.83168328571637,
            21.02357938620399
          ],
          [
            105.83162964153458,
            21.023494261670265
          ],
          [
            105.83160181357758,
            21.023514426599064
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "diemDau": "17-2",
        "diemCuoi": "17-2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 1",
        "from_latitude": 21.02365713532979,
        "to_latitude": 21.023514426599064,
        "to_longitude": 105.83160181357758,
        "from_longitude": 105.83156962707571,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 456,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83334659033359,
          21.01237009824241,
          105.83358664824091,
          21.012554238402497
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83334659033359,
            21.01237009824241
          ],
          [
            105.83358664824091,
            21.012554238402497
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "5.5",
        "diemCuoi": "5.6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.01237009824241,
        "to_latitude": 21.012554238402497,
        "to_longitude": 105.83358664824091,
        "from_longitude": 105.83334659033359,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 457,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83358664824091,
          21.012554238402497,
          105.83386559798083,
          21.012716988379076
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83358664824091,
            21.012554238402497
          ],
          [
            105.83386559798083,
            21.012716988379076
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "5-6",
        "diemCuoi": "5-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012554238402497,
        "to_latitude": 21.012716988379076,
        "to_longitude": 105.83386559798083,
        "from_longitude": 105.83358664824091,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 458,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83386559798083,
          21.012651888409597,
          105.83396215750444,
          21.012716988379076
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83386559798083,
            21.012716988379076
          ],
          [
            105.83396215750444,
            21.012651888409597
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "5-7",
        "diemCuoi": "5-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012716988379076,
        "to_latitude": 21.012651888409597,
        "to_longitude": 105.83396215750444,
        "from_longitude": 105.83386559798083,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 459,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83396215750444,
          21.012651888409597,
          105.83408285690675,
          21.01275704988484
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83396215750444,
            21.012651888409597
          ],
          [
            105.83408285690675,
            21.01275704988484
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "5-8",
        "diemCuoi": "5-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012651888409597,
        "to_latitude": 21.01275704988484,
        "to_longitude": 105.83408285690675,
        "from_longitude": 105.83396215750444,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 460,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83377708508043,
          21.012716988379076,
          105.83386559798083,
          21.012859707443447
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83386559798083,
            21.012716988379076
          ],
          [
            105.83377708508043,
            21.012859707443447
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "5-7",
        "diemCuoi": "5-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012716988379076,
        "to_latitude": 21.012859707443447,
        "to_longitude": 105.83377708508043,
        "from_longitude": 105.83386559798083,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 461,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8334900887173,
          21.013135129812923,
          105.83359737708086,
          21.013286817592732
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83349243551208,
            21.013286817592732
          ],
          [
            105.8334900887173,
            21.01324780245319
          ],
          [
            105.83359737708086,
            21.013135129812923
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Đặng Văn Ngữ",
        "diemDau": "02",
        "diemCuoi": "2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.013286817592732,
        "to_latitude": 21.013135129812923,
        "to_longitude": 105.83359737708086,
        "from_longitude": 105.83349243551208,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 462,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83359737708086,
          21.013135129812923,
          105.83370734764321,
          21.01322526793244
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83359737708086,
            21.013135129812923
          ],
          [
            105.83370734764321,
            21.01322526793244
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Đặng Văn Ngữ",
        "diemDau": "2-1",
        "diemCuoi": "2-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.013135129812923,
        "to_latitude": 21.01322526793244,
        "to_longitude": 105.83370734764321,
        "from_longitude": 105.83359737708086,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 463,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83367784334907,
          21.01309006073233,
          105.8337475807773,
          21.01322526793244
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83370734764321,
            21.01322526793244
          ],
          [
            105.8337475807773,
            21.013177695042756
          ],
          [
            105.83367784334907,
            21.01309006073233
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Đặng Văn Ngữ",
        "diemDau": "2-2",
        "diemCuoi": "2-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.01322526793244,
        "to_latitude": 21.01309006073233,
        "to_longitude": 105.83367784334907,
        "from_longitude": 105.83370734764321,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 464,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83364833904592,
          21.013007434049747,
          105.83367784334907,
          21.01309006073233
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83367784334907,
            21.01309006073233
          ],
          [
            105.83364833904592,
            21.013007434049747
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Đặng Văn Ngữ",
        "diemDau": "2-3",
        "diemCuoi": "2-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.01309006073233,
        "to_latitude": 21.013007434049747,
        "to_longitude": 105.83364833904592,
        "from_longitude": 105.83367784334907,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 465,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83334659033359,
          21.012173653148004,
          105.83354641510685,
          21.01237009824241
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83334659033359,
            21.01237009824241
          ],
          [
            105.83354641510685,
            21.012173653148004
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "5-5",
        "diemCuoi": "5-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.01237009824241,
        "to_latitude": 21.012173653148004,
        "to_longitude": 105.83354641510685,
        "from_longitude": 105.83334659033359,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 466,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83354641510685,
          21.012035941139995,
          105.83368320776454,
          21.012173653148004
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83354641510685,
            21.012173653148004
          ],
          [
            105.83368320776454,
            21.012035941139995
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012173653148004,
        "to_latitude": 21.012035941139995,
        "to_longitude": 105.83368320776454,
        "from_longitude": 105.83354641510685,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 467,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83368320776454,
          21.0119533138735,
          105.83373416973859,
          21.012035941139995
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83368320776454,
            21.012035941139995
          ],
          [
            105.83373416973859,
            21.0119533138735
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012035941139995,
        "to_latitude": 21.0119533138735,
        "to_longitude": 105.83373416973859,
        "from_longitude": 105.83368320776454,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 468,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83368320776454,
          21.01202091800702,
          105.83388437343504,
          21.012035941139995
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83368320776454,
            21.012035941139995
          ],
          [
            105.83388437343504,
            21.01202091800702
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "5-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012035941139995,
        "to_latitude": 21.01202091800702,
        "to_longitude": 105.83388437343504,
        "from_longitude": 105.83368320776454,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 469,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83388437343504,
          21.01202091800702,
          105.8340721280668,
          21.012108552944728
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83388437343504,
            21.01202091800702
          ],
          [
            105.8340721280668,
            21.012108552944728
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "5-15",
        "diemCuoi": "5-16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.01202091800702,
        "to_latitude": 21.012108552944728,
        "to_longitude": 105.8340721280668,
        "from_longitude": 105.83388437343504,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 470,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8340721280668,
          21.012063483554797,
          105.83426256490633,
          21.012151118468346
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8340721280668,
            21.012108552944728
          ],
          [
            105.83413381888082,
            21.012063483554797
          ],
          [
            105.83426256490633,
            21.012151118468346
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "5-16",
        "diemCuoi": "5-17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012108552944728,
        "to_latitude": 21.012151118468346,
        "to_longitude": 105.83426256490633,
        "from_longitude": 105.8340721280668,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 471,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8340721280668,
          21.012023421862743,
          105.8341257722486,
          21.012108552944728
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8340721280668,
            21.012108552944728
          ],
          [
            105.8341257722486,
            21.012023421862743
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012108552944728,
        "to_latitude": 21.012023421862743,
        "to_longitude": 105.8341257722486,
        "from_longitude": 105.8340721280668,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 472,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83395947528773,
          21.012108552944728,
          105.8340721280668,
          21.0122500206657
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8340721280668,
            21.012108552944728
          ],
          [
            105.83395947528773,
            21.0122500206657
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "5-16",
        "diemCuoi": "5-18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012108552944728,
        "to_latitude": 21.0122500206657,
        "to_longitude": 105.83395947528773,
        "from_longitude": 105.8340721280668,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 473,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8337837905998,
          21.0122500206657,
          105.83395947528773,
          21.01239649595154
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83395947528773,
            21.0122500206657
          ],
          [
            105.8337837905998,
            21.01239649595154
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "5-18",
        "diemCuoi": "5-19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.0122500206657,
        "to_latitude": 21.01239649595154,
        "to_longitude": 105.8337837905998,
        "from_longitude": 105.83395947528773,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 474,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83395947528773,
          21.0122500206657,
          105.83409492685057,
          21.012347670871964
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83395947528773,
            21.0122500206657
          ],
          [
            105.83409492685057,
            21.012347670871964
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.0122500206657,
        "to_latitude": 21.012347670871964,
        "to_longitude": 105.83409492685057,
        "from_longitude": 105.83395947528773,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 475,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83409492685057,
          21.012347670871964,
          105.8341566176556,
          21.01240901519997
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83409492685057,
            21.012347670871964
          ],
          [
            105.8341566176556,
            21.01240901519997
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012347670871964,
        "to_latitude": 21.01240901519997,
        "to_longitude": 105.8341566176556,
        "from_longitude": 105.83409492685057,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 476,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83405201149975,
          21.012347670871964,
          105.83409492685057,
          21.01245784025951
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83409492685057,
            21.012347670871964
          ],
          [
            105.83405201149975,
            21.012406511349887
          ],
          [
            105.83408151580286,
            21.01245784025951
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012347670871964,
        "to_latitude": 21.01245784025951,
        "to_longitude": 105.83408151580286,
        "from_longitude": 105.83409492685057,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 477,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8341566176556,
          21.01232138043796,
          105.83430548025713,
          21.01240901519997
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8341566176556,
            21.01240901519997
          ],
          [
            105.83422367287609,
            21.01232138043796
          ],
          [
            105.83430548025713,
            21.012383976702047
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "5-21",
        "diemCuoi": "5-22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.01240901519997,
        "to_latitude": 21.012383976702047,
        "to_longitude": 105.83430548025713,
        "from_longitude": 105.8341566176556,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 478,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83423440171603,
          21.012383976702047,
          105.83430548025713,
          21.01253545955065
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83430548025713,
            21.012383976702047
          ],
          [
            105.83423440171603,
            21.0124615960325
          ],
          [
            105.83428670479394,
            21.01253545955065
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012383976702047,
        "to_latitude": 21.01253545955065,
        "to_longitude": 105.83428670479394,
        "from_longitude": 105.83430548025713,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 479,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83430548025713,
          21.012383976702047,
          105.83436180664667,
          21.0124615960325
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83430548025713,
            21.012383976702047
          ],
          [
            105.83436180664667,
            21.0124615960325
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012383976702047,
        "to_latitude": 21.0124615960325,
        "to_longitude": 105.83436180664667,
        "from_longitude": 105.83430548025713,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 480,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83373416973859,
          21.011642835254786,
          105.83385352802804,
          21.0119533138735
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83373416973859,
            21.0119533138735
          ],
          [
            105.83385352802804,
            21.011642835254786
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "5-12",
        "diemCuoi": "5-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.0119533138735,
        "to_latitude": 21.011642835254786,
        "to_longitude": 105.83385352802804,
        "from_longitude": 105.83373416973859,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 481,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83385352802804,
          21.011476328308483,
          105.83395411086327,
          21.011642835254786
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83385352802804,
            21.011642835254786
          ],
          [
            105.83395411086327,
            21.011476328308483
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "diemDau": "5-13",
        "diemCuoi": "5-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.011642835254786,
        "to_latitude": 21.011476328308483,
        "to_longitude": 105.83395411086327,
        "from_longitude": 105.83385352802804,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 482,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8331876696192,
          21.010841597120542,
          105.83332580338082,
          21.010973050547445
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8331876696192,
            21.010841597120542
          ],
          [
            105.83332580338082,
            21.010973050547445
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.010841597120542,
        "to_latitude": 21.010973050547445,
        "to_longitude": 105.83332580338082,
        "from_longitude": 105.8331876696192,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 483,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83332580338082,
          21.010973050547445,
          105.83346930156686,
          21.01109323643671
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83332580338082,
            21.010973050547445
          ],
          [
            105.83346930156686,
            21.01109323643671
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.010973050547445,
        "to_latitude": 21.01109323643671,
        "to_longitude": 105.83346930156686,
        "from_longitude": 105.83332580338082,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 484,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83346930156686,
          21.01109323643671,
          105.83359536539362,
          21.01124096479328
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83346930156686,
            21.01109323643671
          ],
          [
            105.83359536539362,
            21.01124096479328
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.01109323643671,
        "to_latitude": 21.01124096479328,
        "to_longitude": 105.83359536539362,
        "from_longitude": 105.83346930156686,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 485,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83359536539362,
          21.0112109183598,
          105.83383810531106,
          21.01124096479328
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83359536539362,
            21.01124096479328
          ],
          [
            105.83383810531106,
            21.0112109183598
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.01124096479328,
        "to_latitude": 21.0112109183598,
        "to_longitude": 105.83383810531106,
        "from_longitude": 105.83359536539362,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 486,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83383810531106,
          21.0112109183598,
          105.83404463539702,
          21.011341119527273
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83383810531106,
            21.0112109183598
          ],
          [
            105.83404463539702,
            21.011341119527273
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.0112109183598,
        "to_latitude": 21.011341119527273,
        "to_longitude": 105.83404463539702,
        "from_longitude": 105.83383810531106,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 487,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83315816532505,
          21.01074770174454,
          105.8331876696192,
          21.010841597120542
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8331876696192,
            21.010841597120542
          ],
          [
            105.83315816532505,
            21.01074770174454
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.010841597120542,
        "to_latitude": 21.01074770174454,
        "to_longitude": 105.83315816532505,
        "from_longitude": 105.8331876696192,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 488,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83296504626882,
          21.01059997289948,
          105.83315816532505,
          21.01074770174454
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83315816532505,
            21.01074770174454
          ],
          [
            105.83296504626882,
            21.01059997289948
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4D Đặng Văn Ngữ",
        "diemDau": "11",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.01074770174454,
        "to_latitude": 21.01059997289948,
        "to_longitude": 105.83296504626882,
        "from_longitude": 105.83315816532505,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 489,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83277326832543,
          21.0104297089651,
          105.83296504626882,
          21.01059997289948
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83296504626882,
            21.01059997289948
          ],
          [
            105.83277326832543,
            21.0104297089651
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.01059997289948,
        "to_latitude": 21.0104297089651,
        "to_longitude": 105.83277326832543,
        "from_longitude": 105.83296504626882,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 490,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83280009042083,
          21.01059997289948,
          105.83296504626882,
          21.010768984701585
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83296504626882,
            21.01059997289948
          ],
          [
            105.83280009042083,
            21.010768984701585
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4D Đặng Văn Ngữ",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.01059997289948,
        "to_latitude": 21.010768984701585,
        "to_longitude": 105.83280009042083,
        "from_longitude": 105.83296504626882,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 491,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83260428915685,
          21.010768984701585,
          105.83280009042083,
          21.01097931023196
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83280009042083,
            21.010768984701585
          ],
          [
            105.83260428915685,
            21.01097931023196
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4D Đặng Văn Ngữ",
        "diemDau": "19",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.010768984701585,
        "to_latitude": 21.01097931023196,
        "to_longitude": 105.83260428915685,
        "from_longitude": 105.83280009042083,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 492,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83240848790184,
          21.01097931023196,
          105.83260428915685,
          21.011179619985736
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83260428915685,
            21.01097931023196
          ],
          [
            105.83240848790184,
            21.011179619985736
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4D Đặng Văn Ngữ",
        "diemDau": "23",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.01097931023196,
        "to_latitude": 21.011179619985736,
        "to_longitude": 105.83240848790184,
        "from_longitude": 105.83260428915685,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 493,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83220732223137,
          21.011179619985736,
          105.83240848790184,
          21.011392448803477
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83240848790184,
            21.011179619985736
          ],
          [
            105.83220732223137,
            21.011392448803477
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4D Đặng Văn Ngữ",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.011179619985736,
        "to_latitude": 21.011392448803477,
        "to_longitude": 105.83220732223137,
        "from_longitude": 105.83240848790184,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 494,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83200079213643,
          21.011392448803477,
          105.83220732223137,
          21.01161028504464
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83220732223137,
            21.011392448803477
          ],
          [
            105.83200079213643,
            21.01161028504464
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4D Đặng Văn Ngữ",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.011392448803477,
        "to_latitude": 21.01161028504464,
        "to_longitude": 105.83200079213643,
        "from_longitude": 105.83220732223137,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 495,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8323387504736,
          21.010768984702427,
          105.83260428915685,
          21.01097931023196
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83260428915685,
            21.01097931023196
          ],
          [
            105.8323387504736,
            21.010768984702427
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4D Đặng Văn Ngữ",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.01097931023196,
        "to_latitude": 21.010768984702427,
        "to_longitude": 105.8323387504736,
        "from_longitude": 105.83260428915685,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 496,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83215636025733,
          21.01061124035943,
          105.8323387504736,
          21.010768984702427
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8323387504736,
            21.010768984702427
          ],
          [
            105.83215636025733,
            21.01061124035943
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4D Đặng Văn Ngữ",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.010768984702427,
        "to_latitude": 21.01061124035943,
        "to_longitude": 105.83215636025733,
        "from_longitude": 105.8323387504736,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 497,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8325345517286,
          21.010235657920894,
          105.83277326832543,
          21.0104297089651
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83277326832543,
            21.0104297089651
          ],
          [
            105.8325345517286,
            21.010235657920894
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.0104297089651,
        "to_latitude": 21.010235657920894,
        "to_longitude": 105.8325345517286,
        "from_longitude": 105.83277326832543,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 498,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83229315291504,
          21.010027835231302,
          105.8325345517286,
          21.010235657920894
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8325345517286,
            21.010235657920894
          ],
          [
            105.83229315291504,
            21.010027835231302
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.010235657920894,
        "to_latitude": 21.010027835231302,
        "to_longitude": 105.83229315291504,
        "from_longitude": 105.8325345517286,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 499,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83205980073369,
          21.009867586212703,
          105.83229315291504,
          21.010027835231302
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83229315291504,
            21.010027835231302
          ],
          [
            105.83205980073369,
            21.009867586212703
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.010027835231302,
        "to_latitude": 21.009867586212703,
        "to_longitude": 105.83205980073369,
        "from_longitude": 105.83229315291504,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 500,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83205980073369,
          21.009789965533347,
          105.83207589398913,
          21.009867586212703
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83205980073369,
            21.009867586212703
          ],
          [
            105.83207589398913,
            21.009789965533347
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.009867586212703,
        "to_latitude": 21.009789965533347,
        "to_longitude": 105.83207589398913,
        "from_longitude": 105.83205980073369,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 501,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83177012216281,
          21.00968730586135,
          105.83207589398913,
          21.009789965533347
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83207589398913,
            21.009789965533347
          ],
          [
            105.83177012216281,
            21.00968730586135
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.009789965533347,
        "to_latitude": 21.00968730586135,
        "to_longitude": 105.83177012216281,
        "from_longitude": 105.83207589398913,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 502,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83196592341781,
          21.009867586212703,
          105.83205980073369,
          21.01014051021944
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83205980073369,
            21.009867586212703
          ],
          [
            105.83196592341781,
            21.01014051021944
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.009867586212703,
        "to_latitude": 21.01014051021944,
        "to_longitude": 105.83196592341781,
        "from_longitude": 105.83205980073369,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 503,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83190959702829,
          21.01014051021944,
          105.83196592341781,
          21.01028823951865
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83196592341781,
            21.01014051021944
          ],
          [
            105.83190959702829,
            21.01028823951865
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.01014051021944,
        "to_latitude": 21.01028823951865,
        "to_longitude": 105.83190959702829,
        "from_longitude": 105.83196592341781,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 504,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83186668168644,
          21.01028823951865,
          105.83190959702829,
          21.010395906543273
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83190959702829,
            21.01028823951865
          ],
          [
            105.83186668168644,
            21.010395906543273
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.01028823951865,
        "to_latitude": 21.010395906543273,
        "to_longitude": 105.83186668168644,
        "from_longitude": 105.83190959702829,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 505,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83179426204147,
          21.010395906543273,
          105.83186668168644,
          21.010558658875524
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83186668168644,
            21.010395906543273
          ],
          [
            105.83179426204147,
            21.010558658875524
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.010395906543273,
        "to_latitude": 21.010558658875524,
        "to_longitude": 105.83179426204147,
        "from_longitude": 105.83186668168644,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 506,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83170776086425,
          21.010335813318957,
          105.83186668168644,
          21.010395906543273
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83186668168644,
            21.010395906543273
          ],
          [
            105.83170776086425,
            21.010335813318957
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "37",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.010395906543273,
        "to_latitude": 21.010335813318957,
        "to_longitude": 105.83170776086425,
        "from_longitude": 105.83186668168644,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 507,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8313724847348,
          21.01021312293338,
          105.83170776086425,
          21.010335813318957
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83170776086425,
            21.010335813318957
          ],
          [
            105.8313724847348,
            21.01021312293338
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "diemDau": "37",
        "diemCuoi": "38",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.010335813318957,
        "to_latitude": 21.01021312293338,
        "to_longitude": 105.8313724847348,
        "from_longitude": 105.83170776086425,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 508,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83315816532505,
          21.010558658863808,
          105.83339487033346,
          21.01074770174454
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83315816532505,
            21.01074770174454
          ],
          [
            105.83339487033346,
            21.010558658863808
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "diemDau": "8",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.01074770174454,
        "to_latitude": 21.010558658863808,
        "to_longitude": 105.83339487033346,
        "from_longitude": 105.83315816532505,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 509,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83339487033346,
          21.010558658863808,
          105.83353702741564,
          21.01076397693623
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83339487033346,
            21.010558658863808
          ],
          [
            105.83353702741564,
            21.01076397693623
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.010558658863808,
        "to_latitude": 21.01076397693623,
        "to_longitude": 105.83353702741564,
        "from_longitude": 105.83339487033346,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 510,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83353702741564,
          21.01076397693623,
          105.83366309123343,
          21.010861628116114
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83353702741564,
            21.01076397693623
          ],
          [
            105.83366309123343,
            21.010861628116114
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.01076397693623,
        "to_latitude": 21.010861628116114,
        "to_longitude": 105.83366309123343,
        "from_longitude": 105.83353702741564,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 511,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83339487033346,
          21.010409364906558,
          105.83353199826941,
          21.010558658863808
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83339487033346,
            21.010558658863808
          ],
          [
            105.83353199826941,
            21.010409364906558
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "diemDau": "08",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.010558658863808,
        "to_latitude": 21.010409364906558,
        "to_longitude": 105.83353199826941,
        "from_longitude": 105.83339487033346,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 512,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83353199826941,
          21.010409364906558,
          105.83364431577024,
          21.010581193788205
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83353199826941,
            21.010409364906558
          ],
          [
            105.83364431577024,
            21.010581193788205
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "diemDau": "",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.010409364906558,
        "to_latitude": 21.010581193788205,
        "to_longitude": 105.83364431577024,
        "from_longitude": 105.83353199826941,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 513,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83360359173234,
          21.010181511364692,
          105.83378680813067,
          21.010333712971875
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83378680813067,
            21.010181511364692
          ],
          [
            105.83360359173234,
            21.010333712971875
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.010181511364692,
        "to_latitude": 21.010333712971875,
        "to_longitude": 105.83360359173234,
        "from_longitude": 105.83378680813067,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 514,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83352797495779,
          21.010333712971875,
          105.83360359173234,
          21.010413120731155
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83352797495779,
            21.010413120731155
          ],
          [
            105.83360359173234,
            21.010333712971875
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.010413120731155,
        "to_latitude": 21.010333712971875,
        "to_longitude": 105.83360359173234,
        "from_longitude": 105.83352797495779,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 515,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83378680813067,
          21.010181511364692,
          105.83389007317365,
          21.010403105198137
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83378680813067,
            21.010181511364692
          ],
          [
            105.83389007317365,
            21.010403105198137
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "diemDau": "",
        "diemCuoi": "01",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.010181511364692,
        "to_latitude": 21.010403105198137,
        "to_longitude": 105.83389007317365,
        "from_longitude": 105.83378680813067,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 516,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83389007317365,
          21.010403105198137,
          105.8341059909957,
          21.01052829930913
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83389007317365,
            21.010403105198137
          ],
          [
            105.8341059909957,
            21.01052829930913
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.010403105198137,
        "to_latitude": 21.01052829930913,
        "to_longitude": 105.8341059909957,
        "from_longitude": 105.83389007317365,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 517,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83343007433028,
          21.010321728970617,
          105.83353199826941,
          21.010409364906558
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83353199826941,
            21.010409364906558
          ],
          [
            105.83343007433028,
            21.010321728970617
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Sân Con Voi",
        "diemDau": "",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.010409364906558,
        "to_latitude": 21.010321728970617,
        "to_longitude": 105.83343007433028,
        "from_longitude": 105.83353199826941,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 518,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83338447677174,
          21.010409364906558,
          105.83353199826941,
          21.010443167325317
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83353199826941,
            21.010409364906558
          ],
          [
            105.83338447677174,
            21.010443167325317
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Sân Con Voi",
        "diemDau": "",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.010409364906558,
        "to_latitude": 21.010443167325317,
        "to_longitude": 105.83338447677174,
        "from_longitude": 105.83353199826941,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 519,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83277326832543,
          21.010247864355467,
          105.83294325339156,
          21.0104297089651
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83277326832543,
            21.0104297089651
          ],
          [
            105.83294325339156,
            21.010247864355467
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Sân Con Voi",
        "diemDau": "29",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.0104297089651,
        "to_latitude": 21.010247864355467,
        "to_longitude": 105.83294325339156,
        "from_longitude": 105.83277326832543,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 520,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8331725822613,
          21.010321728970617,
          105.83343007433028,
          21.010334248393193
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83343007433028,
            21.010321728970617
          ],
          [
            105.8331725822613,
            21.010334248393193
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Sân Con Voi",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.010321728970617,
        "to_latitude": 21.010334248393193,
        "to_longitude": 105.8331725822613,
        "from_longitude": 105.83343007433028,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 521,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83022952842849,
          21.02411516891977,
          105.8302831726103,
          21.02413519814253
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83022952842849,
            21.02413519814253
          ],
          [
            105.8302831726103,
            21.02411516891977
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 6,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "diemDau": "tủ điện",
        "diemCuoi": "01",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.02413519814253,
        "to_latitude": 21.02411516891977,
        "to_longitude": 105.8302831726103,
        "from_longitude": 105.83022952842849,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 522,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8302831726103,
          21.02411516891977,
          105.8302912192335,
          21.024280409928938
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8302831726103,
            21.02411516891977
          ],
          [
            105.8302912192335,
            21.024280409928938
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách  74 Thịnh Hào 1",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.02411516891977,
        "to_latitude": 21.024280409928938,
        "to_longitude": 105.8302912192335,
        "from_longitude": 105.8302831726103,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 523,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82996398973629,
          21.024280409928938,
          105.8302912192335,
          21.02434800847074
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8302912192335,
            21.024280409928938
          ],
          [
            105.82996398973629,
            21.02434800847074
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách  76/11 Thịnh Hào 1",
        "diemDau": "02",
        "diemCuoi": "2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.024280409928938,
        "to_latitude": 21.02434800847074,
        "to_longitude": 105.82996398973629,
        "from_longitude": 105.8302912192335,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 524,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83018694835587,
          21.0238399024456,
          105.8302831726103,
          21.02411516891977
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8302831726103,
            21.02411516891977
          ],
          [
            105.83018694835587,
            21.0238399024456
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "diemDau": "01",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.02411516891977,
        "to_latitude": 21.0238399024456,
        "to_longitude": 105.83018694835587,
        "from_longitude": 105.8302831726103,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 525,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83001260477181,
          21.023574514496424,
          105.83018694835587,
          21.0238399024456
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83018694835587,
            21.0238399024456
          ],
          [
            105.83001260477181,
            21.023574514496424
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.0238399024456,
        "to_latitude": 21.023574514496424,
        "to_longitude": 105.83001260477181,
        "from_longitude": 105.83018694835587,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 526,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8299026341915,
          21.023326651739865,
          105.83001260477181,
          21.023574514496424
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83001260477181,
            21.023574514496424
          ],
          [
            105.8299026341915,
            21.023326651739865
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.023574514496424,
        "to_latitude": 21.023326651739865,
        "to_longitude": 105.8299026341915,
        "from_longitude": 105.83001260477181,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 527,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82970951515323,
          21.023121350961972,
          105.8299026341915,
          21.023326651739865
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8299026341915,
            21.023326651739865
          ],
          [
            105.82987312989735,
            21.023326651739865
          ],
          [
            105.8297685237415,
            21.023132617475884
          ],
          [
            105.82970951515323,
            21.023121350961972
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.023326651739865,
        "to_latitude": 21.023121350961972,
        "to_longitude": 105.82970951515323,
        "from_longitude": 105.8299026341915,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 528,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82987581211408,
          21.023116343623116,
          105.82992811518302,
          21.023326651739865
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8299026341915,
            21.023326651739865
          ],
          [
            105.82992811518302,
            21.02330912607474
          ],
          [
            105.82987581211408,
            21.023116343623116
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.023326651739865,
        "to_latitude": 21.023116343623116,
        "to_longitude": 105.82987581211408,
        "from_longitude": 105.8299026341915,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 529,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82964648323535,
          21.022893516842444,
          105.82970951515323,
          21.023121350961972
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82970951515323,
            21.023121350961972
          ],
          [
            105.82964648323535,
            21.022893516842444
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "diemDau": "10-1",
        "diemCuoi": "10-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.023121350961972,
        "to_latitude": 21.022893516842444,
        "to_longitude": 105.82964648323535,
        "from_longitude": 105.82970951515323,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 530,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82964648323535,
          21.022835932338975,
          105.82977925258146,
          21.022893516842444
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82964648323535,
            21.022893516842444
          ],
          [
            105.82977925258146,
            21.022835932338975
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 75 Thịnh Hào 1",
        "diemDau": "10-2",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.022893516842444,
        "to_latitude": 21.022835932338975,
        "to_longitude": 105.82977925258146,
        "from_longitude": 105.82964648323535,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 531,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82977925258146,
          21.022743296352022,
          105.83001260477181,
          21.022835932338975
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82977925258146,
            21.022835932338975
          ],
          [
            105.83001260477181,
            21.022743296352022
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 75 Thịnh Hào 1",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.022835932338975,
        "to_latitude": 21.022743296352022,
        "to_longitude": 105.83001260477181,
        "from_longitude": 105.82977925258146,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 532,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83001260477181,
          21.02265566766367,
          105.83031032997489,
          21.02276207392265
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83001260477181,
            21.022743296352022
          ],
          [
            105.83010648207869,
            21.02274329635286
          ],
          [
            105.83012525754188,
            21.02276207392265
          ],
          [
            105.83018024282754,
            21.02274079267627
          ],
          [
            105.83031032997489,
            21.02265566766367
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 75 Thịnh Hào 1",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.022743296352022,
        "to_latitude": 21.02265566766367,
        "to_longitude": 105.83031032997489,
        "from_longitude": 105.83001260477181,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 533,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83018963056362,
          21.02265566766367,
          105.83031032997489,
          21.02277834781328
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83031032997489,
            21.02265566766367
          ],
          [
            105.83018963056362,
            21.02277834781328
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 75 Thịnh Hào 1",
        "diemDau": "14",
        "diemCuoi": "14-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.02265566766367,
        "to_latitude": 21.02277834781328,
        "to_longitude": 105.83018963056362,
        "from_longitude": 105.83031032997489,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 534,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82981143909231,
          21.02249543221351,
          105.83001260477181,
          21.02276207392265
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83001260477181,
            21.022743296352022
          ],
          [
            105.8299200685508,
            21.02276207392265
          ],
          [
            105.82981143909231,
            21.02249543221351
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 75 Thịnh Hào 1",
        "diemDau": "13",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.022743296352022,
        "to_latitude": 21.02249543221351,
        "to_longitude": 105.82981143909231,
        "from_longitude": 105.83001260477181,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 535,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82966391760364,
          21.02264815663106,
          105.82977925258146,
          21.02283718417648
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82977925258146,
            21.022835932338975
          ],
          [
            105.82972426729582,
            21.02283718417648
          ],
          [
            105.82966391760364,
            21.02264815663106
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 75 Thịnh Hào 1",
        "diemDau": "12",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.022835932338975,
        "to_latitude": 21.02264815663106,
        "to_longitude": 105.82966391760364,
        "from_longitude": 105.82977925258146,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 536,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82959686236518,
          21.02275205921896,
          105.82964648323535,
          21.022893516842444
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82964648323535,
            21.022893516842444
          ],
          [
            105.82959686236518,
            21.02275205921896
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 148 Thịnh Hào 1",
        "diemDau": "10-2",
        "diemCuoi": "10-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.022893516842444,
        "to_latitude": 21.02275205921896,
        "to_longitude": 105.82959686236518,
        "from_longitude": 105.82964648323535,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 537,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82954724150397,
          21.02260559410438,
          105.82959686236518,
          21.02275205921896
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82959686236518,
            21.02275205921896
          ],
          [
            105.82954724150397,
            21.02260559410438
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 148 Thịnh Hào 1",
        "diemDau": "10-3",
        "diemCuoi": "10-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.02275205921896,
        "to_latitude": 21.02260559410438,
        "to_longitude": 105.82954724150397,
        "from_longitude": 105.82959686236518,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 538,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82947884517961,
          21.022725770619154,
          105.82959686236518,
          21.02275205921896
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82959686236518,
            21.02275205921896
          ],
          [
            105.8295686991749,
            21.022725770619154
          ],
          [
            105.82947884517961,
            21.02274079267628
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 148 Thịnh Hào 1",
        "diemDau": "10-3",
        "diemCuoi": "10-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.02275205921896,
        "to_latitude": 21.02274079267628,
        "to_longitude": 105.82947884517961,
        "from_longitude": 105.82959686236518,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 539,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.829774446966,
          21.02434800847074,
          105.82996398973629,
          21.02450156553887
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82996398973629,
            21.02434800847074
          ],
          [
            105.82988385872831,
            21.024390706116773
          ],
          [
            105.82985703663293,
            21.024465815550045
          ],
          [
            105.829774446966,
            21.02450156553887
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách  76/11 Thịnh Hào 1",
        "diemDau": "2-1",
        "diemCuoi": "2-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.02434800847074,
        "to_latitude": 21.02450156553887,
        "to_longitude": 105.829774446966,
        "from_longitude": 105.82996398973629,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 540,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82958490419573,
          21.02450156553887,
          105.829774446966,
          21.024655122448785
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.829774446966,
            21.02450156553887
          ],
          [
            105.82958490419573,
            21.024655122448785
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách  76/11 Thịnh Hào 1",
        "diemDau": "2-2",
        "diemCuoi": "2-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.02450156553887,
        "to_latitude": 21.024655122448785,
        "to_longitude": 105.82958490419573,
        "from_longitude": 105.829774446966,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 541,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82950801419554,
          21.024480701815083,
          105.82958490419573,
          21.024655122448785
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82958490419573,
            21.024655122448785
          ],
          [
            105.82950801419554,
            21.024480701815083
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách  76/11 Thịnh Hào 1",
        "diemDau": "2-3",
        "diemCuoi": "2-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.024655122448785,
        "to_latitude": 21.024480701815083,
        "to_longitude": 105.82950801419554,
        "from_longitude": 105.82958490419573,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 542,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.829647489061,
          21.024378052256562,
          105.829774446966,
          21.02450156553887
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.829774446966,
            21.02450156553887
          ],
          [
            105.829647489061,
            21.024378052256562
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách  76/11 Thịnh Hào 1",
        "diemDau": "2-2",
        "diemCuoi": "2-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.02450156553887,
        "to_latitude": 21.024378052256562,
        "to_longitude": 105.829647489061,
        "from_longitude": 105.829774446966,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 543,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8302912192335,
          21.024280409928938,
          105.83031267691341,
          21.024590862239773
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8302912192335,
            21.024280409928938
          ],
          [
            105.83031267691341,
            21.024590862239773
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách  74 Thịnh Hào 1",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.024280409928938,
        "to_latitude": 21.024590862239773,
        "to_longitude": 105.83031267691341,
        "from_longitude": 105.8302912192335,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 544,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83029423671046,
          21.024590862239773,
          105.83033413458433,
          21.025001459463635
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83031267691341,
            21.024590862239773
          ],
          [
            105.83029423671046,
            21.024891434959585
          ],
          [
            105.83033413458433,
            21.025001459463635
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách  74 Thịnh Hào 1",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.024590862239773,
        "to_latitude": 21.025001459463635,
        "to_longitude": 105.83033413458433,
        "from_longitude": 105.83031267691341,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 545,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83033145236759,
          21.025001459463635,
          105.83033413458433,
          21.02521426855606
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83033413458433,
            21.025001459463635
          ],
          [
            105.83033145236759,
            21.02521426855606
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách  74 Thịnh Hào 1",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.025001459463635,
        "to_latitude": 21.02521426855606,
        "to_longitude": 105.83033145236759,
        "from_longitude": 105.83033413458433,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 546,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8299613075285,
          21.024590862239773,
          105.83031267691341,
          21.024703526225036
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83031267691341,
            21.024590862239773
          ],
          [
            105.8299613075285,
            21.024703526225036
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách  74 Thịnh Hào 1",
        "diemDau": "03",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.024590862239773,
        "to_latitude": 21.024703526225036,
        "to_longitude": 105.8299613075285,
        "from_longitude": 105.83031267691341,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 547,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8296206669836,
          21.024703526225036,
          105.8299613075285,
          21.02481368648339
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8299613075285,
            21.024703526225036
          ],
          [
            105.8296206669836,
            21.02481368648339
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách  74 Thịnh Hào 1",
        "diemDau": "3-1",
        "diemCuoi": "3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.024703526225036,
        "to_latitude": 21.02481368648339,
        "to_longitude": 105.8296206669836,
        "from_longitude": 105.8299613075285,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 548,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82959418015744,
          21.02481368648339,
          105.82967967558086,
          21.02524431216723
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8296206669836,
            21.02481368648339
          ],
          [
            105.82959418015744,
            21.024818829381655
          ],
          [
            105.82967967558086,
            21.02524431216723
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 55,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách  74 Thịnh Hào 1",
        "diemDau": "3-2",
        "diemCuoi": "3-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.02481368648339,
        "to_latitude": 21.02524431216723,
        "to_longitude": 105.82967967558086,
        "from_longitude": 105.8296206669836,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 549,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82967967558086,
          21.02524431216723,
          105.82973868417814,
          21.02545962454307
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82967967558086,
            21.02524431216723
          ],
          [
            105.82973868417814,
            21.02545962454307
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách  74 Thịnh Hào 1",
        "diemDau": "3-3",
        "diemCuoi": "3-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.02524431216723,
        "to_latitude": 21.02545962454307,
        "to_longitude": 105.82973868417814,
        "from_longitude": 105.82967967558086,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 550,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83011117593776,
          21.02521426855606,
          105.83033145236759,
          21.025300153629892
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83033145236759,
            21.02521426855606
          ],
          [
            105.83011117593776,
            21.025300153629892
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách  74 Thịnh Hào 1",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.02521426855606,
        "to_latitude": 21.025300153629892,
        "to_longitude": 105.83011117593776,
        "from_longitude": 105.83033145236759,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 551,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8298429550378,
          21.025300153629892,
          105.83011117593776,
          21.025365248077613
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83011117593776,
            21.025300153629892
          ],
          [
            105.8298429550378,
            21.025365248077613
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATL",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách  74 Thịnh Hào 1",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "ATEC Thăng Long",
        "from_latitude": 21.025300153629892,
        "to_latitude": 21.025365248077613,
        "to_longitude": 105.8298429550378,
        "from_longitude": 105.83011117593776,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 552,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83317130819513,
          21.027676082550112,
          105.83328959361222,
          21.02795147755188
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83328959361222,
            21.02795147755188
          ],
          [
            105.83317130819513,
            21.027676082550112
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đặng Trần Côn",
        "diemDau": "02",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02795147755188,
        "to_latitude": 21.027676082550112,
        "to_longitude": 105.83317130819513,
        "from_longitude": 105.83328959361222,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 553,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83307635799794,
          21.027443248197557,
          105.83317130819513,
          21.027676082550112
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83317130819513,
            21.027676082550112
          ],
          [
            105.83307635799794,
            21.027443248197557
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đặng Trần Côn",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.027676082550112,
        "to_latitude": 21.027443248197557,
        "to_longitude": 105.83307635799794,
        "from_longitude": 105.83317130819513,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 554,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83296772853046,
          21.027201650876627,
          105.83307635799794,
          21.027443248197557
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83307635799794,
            21.027443248197557
          ],
          [
            105.83296772853046,
            21.027201650876627
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đặng Trần Côn",
        "diemDau": "04",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.027443248197557,
        "to_latitude": 21.027201650876627,
        "to_longitude": 105.83296772853046,
        "from_longitude": 105.83307635799794,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 555,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83285239354367,
          21.02691248465631,
          105.83296772853046,
          21.027201650876627
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83296772853046,
            21.027201650876627
          ],
          [
            105.83285239354367,
            21.02691248465631
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đặng Trần Côn",
        "diemDau": "05",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.027201650876627,
        "to_latitude": 21.02691248465631,
        "to_longitude": 105.83285239354367,
        "from_longitude": 105.83296772853046,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 556,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83271425978207,
          21.026620814264415,
          105.83285239354367,
          21.02691248465631
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83285239354367,
            21.02691248465631
          ],
          [
            105.83271425978207,
            21.026620814264415
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đặng Trần Côn",
        "diemDau": "06",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02691248465631,
        "to_latitude": 21.026620814264415,
        "to_longitude": 105.83271425978207,
        "from_longitude": 105.83285239354367,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 557,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83246347325938,
          21.026250279380974,
          105.83271425978207,
          21.026620814264415
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83271425978207,
            21.026620814264415
          ],
          [
            105.83257947877564,
            21.026317251129136
          ],
          [
            105.83246347325938,
            21.026250279380974
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 54,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đặng Trần Côn",
        "diemDau": "14",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.026620814264415,
        "to_latitude": 21.026250279380974,
        "to_longitude": 105.83246347325938,
        "from_longitude": 105.83271425978207,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 558,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83257880824618,
          21.026620814264415,
          105.83272431807457,
          21.0267109442319
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83271425978207,
            21.026620814264415
          ],
          [
            105.83272431807457,
            21.02667777140699
          ],
          [
            105.83266799168504,
            21.02670656291905
          ],
          [
            105.83257880824618,
            21.0267109442319
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 32 Đặng Trần Côn",
        "diemDau": "06",
        "diemCuoi": "6-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.026620814264415,
        "to_latitude": 21.0267109442319,
        "to_longitude": 105.83257880824618,
        "from_longitude": 105.83271425978207,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 559,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83254930394305,
          21.028005304697764,
          105.83263245242796,
          21.028233131005138
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83263245242796,
            21.028233131005138
          ],
          [
            105.83254930394305,
            21.028005304697764
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Bích Câu",
        "diemDau": "08",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.028233131005138,
        "to_latitude": 21.028005304697764,
        "to_longitude": 105.83254930394305,
        "from_longitude": 105.83263245242796,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 560,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83195251244197,
          21.026455575849646,
          105.8320235909741,
          21.02663333231869
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83195251244197,
            21.026455575849646
          ],
          [
            105.8320235909741,
            21.02663333231869
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Bích Câu",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.026455575849646,
        "to_latitude": 21.02663333231869,
        "to_longitude": 105.8320235909741,
        "from_longitude": 105.83195251244197,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 561,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8320235909741,
          21.02663333231869,
          105.83211411554377,
          21.02689934072344
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8320235909741,
            21.02663333231869
          ],
          [
            105.83211411554377,
            21.02689934072344
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Bích Câu",
        "diemDau": "12",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02663333231869,
        "to_latitude": 21.02689934072344,
        "to_longitude": 105.83211411554377,
        "from_longitude": 105.8320235909741,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 562,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83211411554377,
          21.02689934072344,
          105.83227571861862,
          21.027279262493895
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83211411554377,
            21.02689934072344
          ],
          [
            105.83227571861862,
            21.027279262493895
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 49,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Bích Câu",
        "diemDau": "11",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02689934072344,
        "to_latitude": 21.027279262493895,
        "to_longitude": 105.83227571861862,
        "from_longitude": 105.83211411554377,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 563,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83227571861862,
          21.027279262493895,
          105.83244738000394,
          21.027729909795323
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83227571861862,
            21.027279262493895
          ],
          [
            105.83244738000394,
            21.027729909795323
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 57,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Bích Câu",
        "diemDau": "10",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.027279262493895,
        "to_latitude": 21.027729909795323,
        "to_longitude": 105.83244738000394,
        "from_longitude": 105.83227571861862,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 564,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83244738000394,
          21.027729909795323,
          105.83254930394305,
          21.028005304697764
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83244738000394,
            21.027729909795323
          ],
          [
            105.83254930394305,
            21.028005304697764
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Bích Câu",
        "diemDau": "09",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.027729909795323,
        "to_latitude": 21.028005304697764,
        "to_longitude": 105.83254930394305,
        "from_longitude": 105.83244738000394,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 565,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83214160817762,
          21.027729909795323,
          105.83244738000394,
          21.027905161155527
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83244738000394,
            21.027729909795323
          ],
          [
            105.83236624316135,
            21.02783193115242
          ],
          [
            105.83214160817762,
            21.027905161155527
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 10 Bích Câu",
        "diemDau": "09",
        "diemCuoi": "9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.027729909795323,
        "to_latitude": 21.027905161155527,
        "to_longitude": 105.83214160817762,
        "from_longitude": 105.83244738000394,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 566,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83203431981403,
          21.02781690960753,
          105.83214160817762,
          21.027905161155527
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83214160817762,
            21.027905161155527
          ],
          [
            105.83210875109235,
            21.02781690960753
          ],
          [
            105.83203431981403,
            21.027845074998062
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 10 Bích Câu",
        "diemDau": "9-1",
        "diemCuoi": "9-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.027905161155527,
        "to_latitude": 21.027845074998062,
        "to_longitude": 105.83203431981403,
        "from_longitude": 105.83214160817762,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 567,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83207991737261,
          21.027209161679835,
          105.83227571861862,
          21.027279262493895
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83227571861862,
            21.027279262493895
          ],
          [
            105.83207991737261,
            21.027209161679835
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 38+44 Bích Câu",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.027279262493895,
        "to_latitude": 21.027209161679835,
        "to_longitude": 105.83207991737261,
        "from_longitude": 105.83227571861862,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 568,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83183382469998,
          21.026830491539677,
          105.83211411554377,
          21.02689934072344
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83211411554377,
            21.02689934072344
          ],
          [
            105.83198000505786,
            21.026830491539677
          ],
          [
            105.83183382469998,
            21.026853024000122
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 38+44 Bích Câu",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02689934072344,
        "to_latitude": 21.026853024000122,
        "to_longitude": 105.83183382469998,
        "from_longitude": 105.83211411554377,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 569,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83246347325938,
          21.0261269761833,
          105.83276723343438,
          21.026250279380974
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83276723343438,
            21.0261269761833
          ],
          [
            105.83246347325938,
            21.026250279380974
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đoàn Thị Điểm",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.0261269761833,
        "to_latitude": 21.026250279380974,
        "to_longitude": 105.83246347325938,
        "from_longitude": 105.83276723343438,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 570,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8322314621819,
          21.026250279380974,
          105.83246347325938,
          21.02634541680388
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83246347325938,
            21.026250279380974
          ],
          [
            105.8322314621819,
            21.02634541680388
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đoàn Thị Điểm",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.026250279380974,
        "to_latitude": 21.02634541680388,
        "to_longitude": 105.8322314621819,
        "from_longitude": 105.83246347325938,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 571,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83195251244197,
          21.02634541680388,
          105.8322314621819,
          21.026455575849646
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8322314621819,
            21.02634541680388
          ],
          [
            105.83195251244197,
            21.026455575849646
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đoàn Thị Điểm",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02634541680388,
        "to_latitude": 21.026455575849646,
        "to_longitude": 105.83195251244197,
        "from_longitude": 105.8322314621819,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 572,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83174017089992,
          21.026455575849646,
          105.83195251244197,
          21.02653861234861
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83195251244197,
            21.026455575849646
          ],
          [
            105.83174017089992,
            21.02653861234861
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đoàn Thị Điểm",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.026455575849646,
        "to_latitude": 21.02653861234861,
        "to_longitude": 105.83174017089992,
        "from_longitude": 105.83195251244197,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 573,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83143529314134,
          21.02653861234861,
          105.83174017089992,
          21.02666170657141
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83174017089992,
            21.02653861234861
          ],
          [
            105.83143529314134,
            21.02666170657141
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đoàn Thị Điểm",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02653861234861,
        "to_latitude": 21.02666170657141,
        "to_longitude": 105.83143529314134,
        "from_longitude": 105.83174017089992,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 574,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83123233932189,
          21.02666170657141,
          105.83143529314134,
          21.026838628258954
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83143529314134,
            21.02666170657141
          ],
          [
            105.83123233932189,
            21.026838628258954
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đoàn Thị Điểm",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02666170657141,
        "to_latitude": 21.026838628258954,
        "to_longitude": 105.83123233932189,
        "from_longitude": 105.83143529314134,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 575,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83235819653815,
          21.02589413941441,
          105.83246347325938,
          21.026250279380974
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83246347325938,
            21.026250279380974
          ],
          [
            105.83238635975535,
            21.026053119395865
          ],
          [
            105.83240781743524,
            21.02601806870462
          ],
          [
            105.83235819653815,
            21.02589413941441
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 23 Đoàn Thị Điểm",
        "diemDau": "14",
        "diemCuoi": "14-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.026250279380974,
        "to_latitude": 21.02589413941441,
        "to_longitude": 105.83235819653815,
        "from_longitude": 105.83246347325938,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 576,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83163870906941,
          21.026579577516657,
          105.83167959768295,
          21.026764145923142
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83163870906941,
            21.026579577516657
          ],
          [
            105.83164338786047,
            21.026661497936196
          ],
          [
            105.83167959768295,
            21.026764145923142
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 40 Đoàn Thị Điểm",
        "diemDau": "17",
        "diemCuoi": "17-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.026579577516657,
        "to_latitude": 21.026764145923142,
        "to_longitude": 105.83167959768295,
        "from_longitude": 105.83163870906941,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 577,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83153039981218,
          21.026397366817957,
          105.83163870906941,
          21.02657957751667
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83163870906941,
            21.02657957751667
          ],
          [
            105.83160684276876,
            21.026578565822955
          ],
          [
            105.83153039981218,
            21.02640331290292
          ],
          [
            105.83154951054459,
            21.026397366817957
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 Đoàn Thị Điểm",
        "diemDau": "17",
        "diemCuoi": "17-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02657957751667,
        "to_latitude": 21.026397366817957,
        "to_longitude": 105.83154951054459,
        "from_longitude": 105.83163870906941,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 578,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8314475865965,
          21.026336967100416,
          105.83154951054459,
          21.026397366817957
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83154951054459,
            21.026397366817957
          ],
          [
            105.83152503538771,
            21.026336967100416
          ],
          [
            105.8314475865965,
            21.026363568015576
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 Đoàn Thị Điểm",
        "diemDau": "17-2",
        "diemCuoi": "17-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.026397366817957,
        "to_latitude": 21.026363568015576,
        "to_longitude": 105.8314475865965,
        "from_longitude": 105.83154951054459,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 579,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83143529314134,
          21.02666170657141,
          105.83158706147094,
          21.0268780600685
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83143529314134,
            21.02666170657141
          ],
          [
            105.83149955440518,
            21.026720019815336
          ],
          [
            105.83158706147094,
            21.0268780600685
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 52 Đoàn Thị Điểm",
        "diemDau": "18",
        "diemCuoi": "18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02666170657141,
        "to_latitude": 21.0268780600685,
        "to_longitude": 105.83158706147094,
        "from_longitude": 105.83143529314134,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 580,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83150123077827,
          21.0268780600685,
          105.83164305258225,
          21.027065830449086
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83158706147094,
            21.0268780600685
          ],
          [
            105.83164305258225,
            21.027009186408158
          ],
          [
            105.83150123077827,
            21.027065830449086
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 52 Đoàn Thị Điểm",
        "diemDau": "18-1",
        "diemCuoi": "18-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.0268780600685,
        "to_latitude": 21.027065830449086,
        "to_longitude": 105.83150123077827,
        "from_longitude": 105.83158706147094,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 581,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8311981411597,
          21.026838628258954,
          105.83123233932189,
          21.027020765579078
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83123233932189,
            21.026838628258954
          ],
          [
            105.83121389914591,
            21.026855214655658
          ],
          [
            105.8311981411597,
            21.027020765579078
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 68 Đoàn Thị Điểm",
        "diemDau": "19",
        "diemCuoi": "19-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.026838628258954,
        "to_latitude": 21.027020765579078,
        "to_longitude": 105.8311981411597,
        "from_longitude": 105.83123233932189,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 582,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8311981411597,
          21.027020765579078,
          105.83135773260119,
          21.027052999479615
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8311981411597,
            21.027020765579078
          ],
          [
            105.83122462797688,
            21.027052999479615
          ],
          [
            105.83135773260119,
            21.027044549817976
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 68 Đoàn Thị Điểm",
        "diemDau": "19-1",
        "diemCuoi": "19-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.027020765579078,
        "to_latitude": 21.027044549817976,
        "to_longitude": 105.83135773260119,
        "from_longitude": 105.8311981411597,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 583,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83116729576169,
          21.026591396832433,
          105.83123233932189,
          21.026838628258954
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83123233932189,
            21.026838628258954
          ],
          [
            105.83116729576169,
            21.026591396832433
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ An Trạch 1",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.026838628258954,
        "to_latitude": 21.026591396832433,
        "to_longitude": 105.83116729576169,
        "from_longitude": 105.83123233932189,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 584,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83092455584425,
          21.026591396832433,
          105.83116729576169,
          21.02674161344421
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83116729576169,
            21.026591396832433
          ],
          [
            105.83092455584425,
            21.02674161344421
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ An Trạch 1",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.026591396832433,
        "to_latitude": 21.02674161344421,
        "to_longitude": 105.83092455584425,
        "from_longitude": 105.83116729576169,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 585,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83071802574932,
          21.02674161344421,
          105.83092455584425,
          21.026839254160464
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83092455584425,
            21.02674161344421
          ],
          [
            105.83071802574932,
            21.026839254160464
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ An Trạch 1",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02674161344421,
        "to_latitude": 21.026839254160464,
        "to_longitude": 105.83071802574932,
        "from_longitude": 105.83092455584425,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 586,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83045651036869,
          21.026839254160464,
          105.83071802574932,
          21.02690184433019
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83071802574932,
            21.026839254160464
          ],
          [
            105.83045651036869,
            21.02690184433019
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ An Trạch 1",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.026839254160464,
        "to_latitude": 21.02690184433019,
        "to_longitude": 105.83045651036869,
        "from_longitude": 105.83071802574932,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 587,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83028216676665,
          21.02688431908707,
          105.83045651036869,
          21.02690184433019
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83045651036869,
            21.02690184433019
          ],
          [
            105.83028216676665,
            21.02688431908707
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ An Trạch 1",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02690184433019,
        "to_latitude": 21.02688431908707,
        "to_longitude": 105.83028216676665,
        "from_longitude": 105.83045651036869,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 588,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83006759004847,
          21.02685177219802,
          105.83028216676665,
          21.02688431908707
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83028216676665,
            21.02688431908707
          ],
          [
            105.83006759004847,
            21.02685177219802
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ An Trạch 1",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02688431908707,
        "to_latitude": 21.02685177219802,
        "to_longitude": 105.83006759004847,
        "from_longitude": 105.83028216676665,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 589,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82979400472404,
          21.02685177219802,
          105.83006759004847,
          21.02690935515018
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83006759004847,
            21.02685177219802
          ],
          [
            105.82979400472404,
            21.02690935515018
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ An Trạch 1",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02685177219802,
        "to_latitude": 21.02690935515018,
        "to_longitude": 105.82979400472404,
        "from_longitude": 105.83006759004847,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 590,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82957406359039,
          21.02690935515018,
          105.82979400472404,
          21.027142190337432
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82979400472404,
            21.02690935515018
          ],
          [
            105.82957406359039,
            21.027142190337432
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ An Trạch 1",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02690935515018,
        "to_latitude": 21.027142190337432,
        "to_longitude": 105.82957406359039,
        "from_longitude": 105.82979400472404,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 591,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82936216907994,
          21.027142190337432,
          105.82957406359039,
          21.027277384794864
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82957406359039,
            21.027142190337432
          ],
          [
            105.82936216907994,
            21.027277384794864
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ An Trạch 1",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.027142190337432,
        "to_latitude": 21.027277384794864,
        "to_longitude": 105.82936216907994,
        "from_longitude": 105.82957406359039,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 592,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8291610034005,
          21.027277384794864,
          105.82936216907994,
          21.027385039553245
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82936216907994,
            21.027277384794864
          ],
          [
            105.8291610034005,
            21.027385039553245
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ An Trạch 1",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.027277384794864,
        "to_latitude": 21.027385039553245,
        "to_longitude": 105.8291610034005,
        "from_longitude": 105.82936216907994,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 593,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83077837543249,
          21.02659890766808,
          105.83092455584425,
          21.02674161344421
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83092455584425,
            21.02674161344421
          ],
          [
            105.8308585064494,
            21.026736293275547
          ],
          [
            105.83077837543249,
            21.02659890766808
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 11  An Trạch 1",
        "diemDau": "21",
        "diemCuoi": "21-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02674161344421,
        "to_latitude": 21.02659890766808,
        "to_longitude": 105.83077837543249,
        "from_longitude": 105.83092455584425,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 594,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83045651036869,
          21.02690184433019,
          105.8305101545505,
          21.027078348465924
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83045651036869,
            21.02690184433019
          ],
          [
            105.8305101545505,
            21.027078348465924
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 29  An Trạch 1",
        "diemDau": "23",
        "diemCuoi": "23-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02690184433019,
        "to_latitude": 21.027078348465924,
        "to_longitude": 105.8305101545505,
        "from_longitude": 105.83045651036869,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 595,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8304028661779,
          21.02670906652654,
          105.83045651036869,
          21.02690184433019
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83045651036869,
            21.02690184433019
          ],
          [
            105.83041057754089,
            21.026856466460263
          ],
          [
            105.8304028661779,
            21.02670906652654
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 29  An Trạch 1",
        "diemDau": "23",
        "diemCuoi": "23-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02690184433019,
        "to_latitude": 21.02670906652654,
        "to_longitude": 105.8304028661779,
        "from_longitude": 105.83045651036869,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 596,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83020170049846,
          21.026646476275822,
          105.83028216676665,
          21.02688431908707
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83028216676665,
            21.02688431908707
          ],
          [
            105.83020170049846,
            21.026646476275822
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 33 An Trạch 1",
        "diemDau": "24",
        "diemCuoi": "24-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02688431908707,
        "to_latitude": 21.026646476275822,
        "to_longitude": 105.83020170049846,
        "from_longitude": 105.83028216676665,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 597,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83013732747672,
          21.026468719822518,
          105.83020170049846,
          21.026646476275822
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83020170049846,
            21.026646476275822
          ],
          [
            105.83013732747672,
            21.026468719822518
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 33 An Trạch 1",
        "diemDau": "24-1",
        "diemCuoi": "24-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.026646476275822,
        "to_latitude": 21.026468719822518,
        "to_longitude": 105.83013732747672,
        "from_longitude": 105.83020170049846,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 598,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83011586980581,
          21.026248401669783,
          105.83013732747672,
          21.026468719822518
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83013732747672,
            21.026468719822518
          ],
          [
            105.83011586980581,
            21.026248401669783
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 33 An Trạch 1",
        "diemDau": "24-2",
        "diemCuoi": "24-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.026468719822518,
        "to_latitude": 21.026248401669783,
        "to_longitude": 105.83011586980581,
        "from_longitude": 105.83013732747672,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 599,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83006457258048,
          21.026037784720504,
          105.83011586980581,
          21.026248401669783
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83011586980581,
            21.026248401669783
          ],
          [
            105.83006457258048,
            21.026037784720504
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 33 An Trạch 1",
        "diemDau": "24-3",
        "diemCuoi": "24-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.026248401669783,
        "to_latitude": 21.026037784720504,
        "to_longitude": 105.83006457258048,
        "from_longitude": 105.83011586980581,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 600,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83011586980581,
          21.02624589805291,
          105.83028753118212,
          21.026288146586186
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83011586980581,
            21.026248401669783
          ],
          [
            105.83014772105642,
            21.026288146586186
          ],
          [
            105.83028753118212,
            21.02624589805291
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 33 An Trạch 1",
        "diemDau": "24-3",
        "diemCuoi": "24-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.026248401669783,
        "to_latitude": 21.02624589805291,
        "to_longitude": 105.83028753118212,
        "from_longitude": 105.83011586980581,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 601,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82997103052485,
          21.02651378485864,
          105.83006759004847,
          21.02685177219802
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83006759004847,
            21.02685177219802
          ],
          [
            105.83005116153278,
            21.026661184985482
          ],
          [
            105.82997103052485,
            21.02651378485864
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 39 An Trạch 1",
        "diemDau": "25",
        "diemCuoi": "25-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02685177219802,
        "to_latitude": 21.02651378485864,
        "to_longitude": 105.82997103052485,
        "from_longitude": 105.83006759004847,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 602,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82970817404036,
          21.026486245116203,
          105.82997103052485,
          21.02651378485864
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82997103052485,
            21.02651378485864
          ],
          [
            105.82970817404036,
            21.026486245116203
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 39 An Trạch 1",
        "diemDau": "25-1",
        "diemCuoi": "25-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02651378485864,
        "to_latitude": 21.026486245116203,
        "to_longitude": 105.82970817404036,
        "from_longitude": 105.82997103052485,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 603,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82957406359039,
          21.027142190337432,
          105.82970012740815,
          21.027339974780585
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82970012740815,
            21.027339974780585
          ],
          [
            105.82957406359039,
            21.027142190337432
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 6  An Trạch 1",
        "diemDau": "27",
        "diemCuoi": "27-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.027339974780585,
        "to_latitude": 21.027142190337432,
        "to_longitude": 105.82957406359039,
        "from_longitude": 105.82970012740815,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 604,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8291610034005,
          21.027385039553245,
          105.82926259207936,
          21.027547460392068
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8291610034005,
            21.027385039553245
          ],
          [
            105.82926259207936,
            21.027547460392068
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 34  An Trạch 1",
        "diemDau": "29",
        "diemCuoi": "29-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.027385039553245,
        "to_latitude": 21.027547460392068,
        "to_longitude": 105.82926259207936,
        "from_longitude": 105.8291610034005,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 605,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82905606198439,
          21.027385039553245,
          105.8291610034005,
          21.027442309347812
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8291610034005,
            21.027385039553245
          ],
          [
            105.82905606198439,
            21.027442309347812
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 34  An Trạch 1",
        "diemDau": "29",
        "diemCuoi": "29-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.027385039553245,
        "to_latitude": 21.027442309347812,
        "to_longitude": 105.82905606198439,
        "from_longitude": 105.8291610034005,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 606,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8051669673935,
          21.02327501361513,
          105.80516830849739,
          21.02351912096306
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80516830849739,
            21.02351912096306
          ],
          [
            105.8051669673935,
            21.02327501361513
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Chùa Láng",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02351912096306,
        "to_latitude": 21.02327501361513,
        "to_longitude": 105.8051669673935,
        "from_longitude": 105.80516830849739,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 607,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80515221524195,
          21.02310226048092,
          105.8051669673935,
          21.02327501361513
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8051669673935,
            21.02327501361513
          ],
          [
            105.80515221524195,
            21.02310226048092
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Chùa Láng",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02327501361513,
        "to_latitude": 21.02310226048092,
        "to_longitude": 105.80515221524195,
        "from_longitude": 105.8051669673935,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 608,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80512539315555,
          21.02276802015345,
          105.80515221524195,
          21.02310226048092
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80515221524195,
            21.02310226048092
          ],
          [
            105.80512539315555,
            21.02276802015345
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Chùa Láng",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02310226048092,
        "to_latitude": 21.02276802015345,
        "to_longitude": 105.80512539315555,
        "from_longitude": 105.80515221524195,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 609,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80480203621138,
          21.02276802015345,
          105.80512539315555,
          21.022772559005244
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80512539315555,
            21.02276802015345
          ],
          [
            105.80480203621138,
            21.022772559005244
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Chùa Láng",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02276802015345,
        "to_latitude": 21.022772559005244,
        "to_longitude": 105.80480203621138,
        "from_longitude": 105.80512539315555,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 610,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80447869428782,
          21.022772559005244,
          105.80480203621138,
          21.022777950189315
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80480203621138,
            21.022772559005244
          ],
          [
            105.80447869428782,
            21.022777950189315
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Chùa Láng",
        "diemDau": "05",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.022772559005244,
        "to_latitude": 21.022777950189315,
        "to_longitude": 105.80447869428782,
        "from_longitude": 105.80480203621138,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 611,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.804954690868,
          21.02309624820927,
          105.80515221524195,
          21.02310226048092
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80515221524195,
            21.02310226048092
          ],
          [
            105.804954690868,
            21.02309624820927
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Chùa Láng",
        "diemDau": "03",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02310226048092,
        "to_latitude": 21.02309624820927,
        "to_longitude": 105.804954690868,
        "from_longitude": 105.80515221524195,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 612,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80475793051525,
          21.02309624820927,
          105.804954690868,
          21.023108519651302
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.804954690868,
            21.02309624820927
          ],
          [
            105.80475793051525,
            21.023108519651302
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Chùa Láng",
        "diemDau": "3-1",
        "diemCuoi": "3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02309624820927,
        "to_latitude": 21.023108519651302,
        "to_longitude": 105.80475793051525,
        "from_longitude": 105.804954690868,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 613,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80449641513461,
          21.02312228983977,
          105.80449909735134,
          21.023308813117815
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80449909735134,
            21.023308813117815
          ],
          [
            105.80449641513461,
            21.02312228983977
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "diemDau": "07",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023308813117815,
        "to_latitude": 21.02312228983977,
        "to_longitude": 105.80449641513461,
        "from_longitude": 105.80449909735134,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 614,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80447869428782,
          21.022777950189315,
          105.80449641513461,
          21.02312228983977
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80449641513461,
            21.02312228983977
          ],
          [
            105.80447869428782,
            21.022777950189315
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "diemDau": "10",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02312228983977,
        "to_latitude": 21.022777950189315,
        "to_longitude": 105.80447869428782,
        "from_longitude": 105.80449641513461,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 615,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80447361635083,
          21.02261905137676,
          105.80447869428782,
          21.022777950189315
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80447869428782,
            21.022777950189315
          ],
          [
            105.80447361635083,
            21.02261905137676
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.022777950189315,
        "to_latitude": 21.02261905137676,
        "to_longitude": 105.80447361635083,
        "from_longitude": 105.80447869428782,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 616,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80445215867992,
          21.022299832068303,
          105.80447361635083,
          21.02261905137676
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80447361635083,
            21.02261905137676
          ],
          [
            105.80445215867992,
            21.022299832068303
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "diemDau": "11",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02261905137676,
        "to_latitude": 21.022299832068303,
        "to_longitude": 105.80445215867992,
        "from_longitude": 105.80447361635083,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 617,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8044447585685,
          21.022141469607547,
          105.80445215867992,
          21.022299832068303
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80445215867992,
            21.022299832068303
          ],
          [
            105.8044447585685,
            21.022141469607547
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "diemDau": "14",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.022299832068303,
        "to_latitude": 21.022141469607547,
        "to_longitude": 105.8044447585685,
        "from_longitude": 105.80445215867992,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 618,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80419198440319,
          21.022053219035783,
          105.8044447585685,
          21.022141469607547
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8044447585685,
            21.022141469607547
          ],
          [
            105.80419198440319,
            21.022053219035783
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.022141469607547,
        "to_latitude": 21.022053219035783,
        "to_longitude": 105.80419198440319,
        "from_longitude": 105.8044447585685,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 619,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80392912791872,
          21.02199688605229,
          105.80419198440319,
          21.022053219035783
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80419198440319,
            21.022053219035783
          ],
          [
            105.80392912791872,
            21.02199688605229
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.022053219035783,
        "to_latitude": 21.02199688605229,
        "to_longitude": 105.80392912791872,
        "from_longitude": 105.80419198440319,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 620,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80418125557225,
          21.022299832068303,
          105.80445215867992,
          21.022344898364725
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80445215867992,
            21.022299832068303
          ],
          [
            105.80418125557225,
            21.022344898364725
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.022299832068303,
        "to_latitude": 21.022344898364725,
        "to_longitude": 105.80418125557225,
        "from_longitude": 105.80445215867992,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 621,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80394119786253,
          21.022318609693148,
          105.80418125557225,
          21.022344898364725
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80418125557225,
            21.022344898364725
          ],
          [
            105.80394119786253,
            21.022318609693148
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.022344898364725,
        "to_latitude": 21.022318609693148,
        "to_longitude": 105.80394119786253,
        "from_longitude": 105.80418125557225,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 622,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80419617474628,
          21.02261905137676,
          105.80447361635083,
          21.022641454038137
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80447361635083,
            21.02261905137676
          ],
          [
            105.80419617474628,
            21.022641454038137
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02261905137676,
        "to_latitude": 21.022641454038137,
        "to_longitude": 105.80419617474628,
        "from_longitude": 105.80447361635083,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 623,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8039559500141,
          21.022641454038137,
          105.80419617474628,
          21.02265159918999
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80419617474628,
            21.022641454038137
          ],
          [
            105.8039559500141,
            21.02265159918999
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "diemDau": "13",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.022641454038137,
        "to_latitude": 21.02265159918999,
        "to_longitude": 105.8039559500141,
        "from_longitude": 105.80419617474628,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 624,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80425391589735,
          21.02312228983977,
          105.80449641513461,
          21.023126693681466
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80449641513461,
            21.02312228983977
          ],
          [
            105.80425391589735,
            21.023126693681466
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02312228983977,
        "to_latitude": 21.023126693681466,
        "to_longitude": 105.80425391589735,
        "from_longitude": 105.80449641513461,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 625,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80401137760595,
          21.023124993976836,
          105.80425391589735,
          21.023126693681466
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80425391589735,
            21.023126693681466
          ],
          [
            105.80401137760595,
            21.023124993976836
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023126693681466,
        "to_latitude": 21.023124993976836,
        "to_longitude": 105.80401137760595,
        "from_longitude": 105.80425391589735,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 626,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80266312529854,
          21.023357634606974,
          105.80272134308461,
          21.023588107265624
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80266312529854,
            21.023357634606974
          ],
          [
            105.80266848972302,
            21.023392685923785
          ],
          [
            105.80272134308461,
            21.023588107265624
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chùa Láng",
        "diemDau": "09",
        "diemCuoi": "9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.023357634606974,
        "to_latitude": 21.023588107265624,
        "to_longitude": 105.80272134308461,
        "from_longitude": 105.80266312529854,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 627,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80272134308461,
          21.023588107265624,
          105.8027579636845,
          21.02387830268204
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80272134308461,
            21.023588107265624
          ],
          [
            105.8027579636845,
            21.02387830268204
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chùa Láng",
        "diemDau": "9-1",
        "diemCuoi": "9-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.023588107265624,
        "to_latitude": 21.02387830268204,
        "to_longitude": 105.8027579636845,
        "from_longitude": 105.80272134308461,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 628,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8027579636845,
          21.02387830268204,
          105.80278626202114,
          21.024196990233186
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8027579636845,
            21.02387830268204
          ],
          [
            105.80278626202114,
            21.024196990233186
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chùa Láng",
        "diemDau": "9-2",
        "diemCuoi": "9-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.02387830268204,
        "to_latitude": 21.024196990233186,
        "to_longitude": 105.80278626202114,
        "from_longitude": 105.8027579636845,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 629,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80278626202114,
          21.024196990233186,
          105.80319587902731,
          21.024313092998995
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80278626202114,
            21.024196990233186
          ],
          [
            105.80319587902731,
            21.024313092998995
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 57,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chùa Láng",
        "diemDau": "9-3",
        "diemCuoi": "9-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.024196990233186,
        "to_latitude": 21.024313092998995,
        "to_longitude": 105.80319587902731,
        "from_longitude": 105.80278626202114,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 630,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80252800898157,
          21.024196990233186,
          105.80278626202114,
          21.024483341083084
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80278626202114,
            21.024196990233186
          ],
          [
            105.80252800898157,
            21.024483341083084
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 52,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chùa Láng",
        "diemDau": "9-3",
        "diemCuoi": "9-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.024196990233186,
        "to_latitude": 21.024483341083084,
        "to_longitude": 105.80252800898157,
        "from_longitude": 105.80278626202114,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 631,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80113948967208,
          21.02297141516538,
          105.80124138685603,
          21.023065800785233
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80124138685603,
            21.023065800785233
          ],
          [
            105.8012368605996,
            21.023029028118327
          ],
          [
            105.80113948967208,
            21.02297141516538
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.023065800785233,
        "to_latitude": 21.02297141516538,
        "to_longitude": 105.80113948967208,
        "from_longitude": 105.80124138685603,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 632,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8008055278953,
          21.022847981277977,
          105.80113948967208,
          21.02297141516538
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80113948967208,
            21.02297141516538
          ],
          [
            105.8008055278953,
            21.022847981277977
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "04",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.02297141516538,
        "to_latitude": 21.022847981277977,
        "to_longitude": 105.8008055278953,
        "from_longitude": 105.80113948967208,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 633,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80113948967208,
          21.02297141516538,
          105.80143852921886,
          21.023060793444728
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80113948967208,
            21.02297141516538
          ],
          [
            105.80143852921886,
            21.023060793444728
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.02297141516538,
        "to_latitude": 21.023060793444728,
        "to_longitude": 105.80143852921886,
        "from_longitude": 105.80113948967208,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 634,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80143852921886,
          21.023060793444728,
          105.8017210274889,
          21.023155933198417
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80143852921886,
            21.023060793444728
          ],
          [
            105.8017210274889,
            21.023155933198417
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.023060793444728,
        "to_latitude": 21.023155933198417,
        "to_longitude": 105.8017210274889,
        "from_longitude": 105.80143852921886,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 635,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8017210274889,
          21.023155933198417,
          105.80203042288463,
          21.023224544237156
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8017210274889,
            21.023155933198417
          ],
          [
            105.80203042288463,
            21.023224544237156
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.023155933198417,
        "to_latitude": 21.023224544237156,
        "to_longitude": 105.80203042288463,
        "from_longitude": 105.8017210274889,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 636,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80203042288463,
          21.023224544237156,
          105.80235735347223,
          21.0233075612826
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80203042288463,
            21.023224544237156
          ],
          [
            105.80235735347223,
            21.0233075612826
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.023224544237156,
        "to_latitude": 21.0233075612826,
        "to_longitude": 105.80235735347223,
        "from_longitude": 105.80203042288463,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 637,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80235735347223,
          21.0233075612826,
          105.80266312529854,
          21.023357634606974
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80235735347223,
            21.0233075612826
          ],
          [
            105.80266312529854,
            21.023357634606974
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.0233075612826,
        "to_latitude": 21.023357634606974,
        "to_longitude": 105.80266312529854,
        "from_longitude": 105.80235735347223,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 638,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80031954511482,
          21.022650973274143,
          105.80055155620131,
          21.022749868507915
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80031954511482,
            21.022650973274143
          ],
          [
            105.80055155620131,
            21.022749868507915
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "02",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.022650973274143,
        "to_latitude": 21.022749868507915,
        "to_longitude": 105.80055155620131,
        "from_longitude": 105.80031954511482,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 639,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80055155620131,
          21.022749868507915,
          105.8008055278953,
          21.022847981277977
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80055155620131,
            21.022749868507915
          ],
          [
            105.80067896108385,
            21.022803697533227
          ],
          [
            105.8008055278953,
            21.022847981277977
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.022749868507915,
        "to_latitude": 21.022847981277977,
        "to_longitude": 105.8008055278953,
        "from_longitude": 105.80055155620131,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 640,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80072187646869,
          21.022847981277977,
          105.8008055278953,
          21.023014006089596
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8008055278953,
            21.022847981277977
          ],
          [
            105.80072187646869,
            21.023014006089596
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 994 Phố Chùa Láng",
        "diemDau": "03",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.022847981277977,
        "to_latitude": 21.023014006089596,
        "to_longitude": 105.80072187646869,
        "from_longitude": 105.8008055278953,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 641,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80060385927405,
          21.023014006089596,
          105.80072187646869,
          21.023253106530213
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80072187646869,
            21.023014006089596
          ],
          [
            105.80060385927405,
            21.023253106530213
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 994 Phố Chùa Láng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.023014006089596,
        "to_latitude": 21.023253106530213,
        "to_longitude": 105.80060385927405,
        "from_longitude": 105.80072187646869,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 642,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80044158562681,
          21.023140441448955,
          105.80060385927405,
          21.023253106530213
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80060385927405,
            21.023253106530213
          ],
          [
            105.80058642487182,
            21.023224314352174
          ],
          [
            105.80044158562681,
            21.023140441448955
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 994 Phố Chùa Láng",
        "diemDau": "36",
        "diemCuoi": "38",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.023253106530213,
        "to_latitude": 21.023140441448955,
        "to_longitude": 105.80044158562681,
        "from_longitude": 105.80060385927405,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 643,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80042415127207,
          21.02322306251795,
          105.80060385927405,
          21.023334475702555
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80060385927405,
            21.023253106530213
          ],
          [
            105.80052741627455,
            21.02322306251795
          ],
          [
            105.80045767883733,
            21.02332320920576
          ],
          [
            105.80042415127207,
            21.023334475702555
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 994 Phố Chùa Láng",
        "diemDau": "36",
        "diemCuoi": "37",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.023253106530213,
        "to_latitude": 21.023334475702555,
        "to_longitude": 105.80042415127207,
        "from_longitude": 105.80060385927405,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 644,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80124138685603,
          21.023065800785233,
          105.80125429490502,
          21.023139189616526
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80124138685603,
            21.023065800785233
          ],
          [
            105.80125429490502,
            21.023139189616526
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194 Phố Chùa Láng",
        "diemDau": "",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.023065800785233,
        "to_latitude": 21.023139189616526,
        "to_longitude": 105.80125429490502,
        "from_longitude": 105.80124138685603,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 645,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80120959142168,
          21.023139189616526,
          105.80125429490502,
          21.023565647367672
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80125429490502,
            21.023139189616526
          ],
          [
            105.80120959142168,
            21.023565647367672
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194 Phố Chùa Láng",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.023139189616526,
        "to_latitude": 21.023565647367672,
        "to_longitude": 105.80120959142168,
        "from_longitude": 105.80125429490502,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 646,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80116488793836,
          21.023565647367672,
          105.80120959142168,
          21.023992103898912
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80120959142168,
            21.023565647367672
          ],
          [
            105.80116488793836,
            21.023992103898912
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194 Phố Chùa Láng",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.023565647367672,
        "to_latitude": 21.023992103898912,
        "to_longitude": 105.80116488793836,
        "from_longitude": 105.80120959142168,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 647,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80112018445503,
          21.023992103898912,
          105.80116488793836,
          21.024418559210165
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80116488793836,
            21.023992103898912
          ],
          [
            105.80112018445503,
            21.024418559210165
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194 Phố Chùa Láng",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.023992103898912,
        "to_latitude": 21.024418559210165,
        "to_longitude": 105.80112018445503,
        "from_longitude": 105.80116488793836,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 648,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80112018445503,
          21.024418559210165,
          105.80141120412979,
          21.024446099335453
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80112018445503,
            21.024418559210165
          ],
          [
            105.80141120412979,
            21.024446099335453
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194/141 Phố Chùa Láng",
        "diemDau": "18",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.024418559210165,
        "to_latitude": 21.024446099335453,
        "to_longitude": 105.80141120412979,
        "from_longitude": 105.80112018445503,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 649,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80141120412979,
          21.024446099335453,
          105.80167406060518,
          21.024469883984445
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80141120412979,
            21.024446099335453
          ],
          [
            105.80167406060518,
            21.024469883984445
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194/141 Phố Chùa Láng",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.024446099335453,
        "to_latitude": 21.024469883984445,
        "to_longitude": 105.80167406060518,
        "from_longitude": 105.80141120412979,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 650,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80167406060518,
          21.024469883984445,
          105.80199860790377,
          21.024511194155284
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80167406060518,
            21.024469883984445
          ],
          [
            105.80199860790377,
            21.024511194155284
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194/141 Phố Chùa Láng",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.024469883984445,
        "to_latitude": 21.024511194155284,
        "to_longitude": 105.80199860790377,
        "from_longitude": 105.80167406060518,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 651,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80166433759997,
          21.024469883984445,
          105.80167406060518,
          21.024728072367296
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80167406060518,
            21.024469883984445
          ],
          [
            105.80166433759997,
            21.024728072367296
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194/141/17 Phố Chùa Láng",
        "diemDau": "26",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.024469883984445,
        "to_latitude": 21.024728072367296,
        "to_longitude": 105.80166433759997,
        "from_longitude": 105.80167406060518,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 652,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80164824435352,
          21.024728072367296,
          105.80166433759997,
          21.025041027382972
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80166433759997,
            21.024728072367296
          ],
          [
            105.80164824435352,
            21.025041027382972
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194/141/17 Phố Chùa Láng",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.024728072367296,
        "to_latitude": 21.025041027382972,
        "to_longitude": 105.80164824435352,
        "from_longitude": 105.80166433759997,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 653,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80151011058294,
          21.025041027382972,
          105.80164824435352,
          21.02509376023628
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80164824435352,
            21.025041027382972
          ],
          [
            105.80162192519792,
            21.02509376023628
          ],
          [
            105.80151011058294,
            21.02508734466931
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194/141/17 Phố Chùa Láng",
        "diemDau": "29",
        "diemCuoi": "29-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.025041027382972,
        "to_latitude": 21.02508734466931,
        "to_longitude": 105.80151011058294,
        "from_longitude": 105.80164824435352,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 654,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80111180254461,
          21.024418559210165,
          105.80112018445503,
          21.02459037195214
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80112018445503,
            21.024418559210165
          ],
          [
            105.80111180254461,
            21.02459037195214
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194 Phố Chùa Láng",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.024418559210165,
        "to_latitude": 21.02459037195214,
        "to_longitude": 105.80111180254461,
        "from_longitude": 105.80112018445503,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 655,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80110107371364,
          21.02459037195214,
          105.80111180254461,
          21.024743094223478
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80111180254461,
            21.02459037195214
          ],
          [
            105.80110107371364,
            21.024743094223478
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194 Phố Chùa Láng",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.02459037195214,
        "to_latitude": 21.024743094223478,
        "to_longitude": 105.80110107371364,
        "from_longitude": 105.80111180254461,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 656,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80106218167442,
          21.024743094223478,
          105.80110107371364,
          21.025066063755716
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80110107371364,
            21.024743094223478
          ],
          [
            105.80106218167442,
            21.025066063755716
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194 Phố Chùa Láng",
        "diemDau": "20",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.024743094223478,
        "to_latitude": 21.025066063755716,
        "to_longitude": 105.80106218167442,
        "from_longitude": 105.80110107371364,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 657,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80106218167442,
          21.024977184613604,
          105.80108632156144,
          21.025066063755716
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80108632156144,
            21.024977184613604
          ],
          [
            105.80106218167442,
            21.025066063755716
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194 Phố Chùa Láng",
        "diemDau": "22",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.024977184613604,
        "to_latitude": 21.025066063755716,
        "to_longitude": 105.80106218167442,
        "from_longitude": 105.80108632156144,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 658,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80101658411586,
          21.025066063755716,
          105.80106218167442,
          21.025474156040804
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80106218167442,
            21.025066063755716
          ],
          [
            105.80101658411586,
            21.025474156040804
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194 Phố Chùa Láng",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.025066063755716,
        "to_latitude": 21.025474156040804,
        "to_longitude": 105.80101658411586,
        "from_longitude": 105.80106218167442,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 659,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80080971873372,
          21.025474156040804,
          105.80101658411586,
          21.025478850349803
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80101658411586,
            21.025474156040804
          ],
          [
            105.80080971873372,
            21.025478850349803
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194/112 Phố Chùa Láng",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.025474156040804,
        "to_latitude": 21.025478850349803,
        "to_longitude": 105.80080971873372,
        "from_longitude": 105.80101658411586,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 660,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80077652641516,
          21.025013174415122,
          105.80106218167442,
          21.025066063755716
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80106218167442,
            21.025066063755716
          ],
          [
            105.80102697765965,
            21.025013174415122
          ],
          [
            105.80077652641516,
            21.0250172428244
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194/... Phố Chùa Láng",
        "diemDau": "22",
        "diemCuoi": "22-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.025066063755716,
        "to_latitude": 21.0250172428244,
        "to_longitude": 105.80077652641516,
        "from_longitude": 105.80106218167442,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 661,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80081374205432,
          21.02459037195214,
          105.80111180254461,
          21.024591310819847
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80111180254461,
            21.02459037195214
          ],
          [
            105.80081374205432,
            21.024591310819847
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194/36 Phố Chùa Láng",
        "diemDau": "19",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.02459037195214,
        "to_latitude": 21.024591310819847,
        "to_longitude": 105.80081374205432,
        "from_longitude": 105.80111180254461,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 662,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80079764879888,
          21.024230785496524,
          105.80081374205432,
          21.024591310819847
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80081374205432,
            21.024591310819847
          ],
          [
            105.80079764879888,
            21.024230785496524
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194/36 Phố Chùa Láng",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.024591310819847,
        "to_latitude": 21.024230785496524,
        "to_longitude": 105.80079764879888,
        "from_longitude": 105.80081374205432,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 663,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80054686225822,
          21.02422703001992,
          105.80079764879888,
          21.024230785496524
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80079764879888,
            21.024230785496524
          ],
          [
            105.80054686225822,
            21.02422703001992
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194/36 Phố Chùa Láng",
        "diemDau": "31",
        "diemCuoi": "31-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.024230785496524,
        "to_latitude": 21.02422703001992,
        "to_longitude": 105.80054686225822,
        "from_longitude": 105.80079764879888,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 664,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.800507970228,
          21.024591310819847,
          105.80081374205432,
          21.02463637642437
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80081374205432,
            21.024591310819847
          ],
          [
            105.800507970228,
            21.02463637642437
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194/36 Phố Chùa Láng",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.024591310819847,
        "to_latitude": 21.02463637642437,
        "to_longitude": 105.800507970228,
        "from_longitude": 105.80081374205432,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 665,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80018610514625,
          21.024617599090682,
          105.800507970228,
          21.02463637642437
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.800507970228,
            21.02463637642437
          ],
          [
            105.80018610514625,
            21.024617599090682
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194/36 Phố Chùa Láng",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.02463637642437,
        "to_latitude": 21.024617599090682,
        "to_longitude": 105.80018610514625,
        "from_longitude": 105.800507970228,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 666,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80045432604622,
          21.02463637642437,
          105.800507970228,
          21.02477157315537
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.800507970228,
            21.02463637642437
          ],
          [
            105.80045432604622,
            21.02477157315537
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194/36 Phố Chùa Láng",
        "diemDau": "32",
        "diemCuoi": "34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.02463637642437,
        "to_latitude": 21.02477157315537,
        "to_longitude": 105.80045432604622,
        "from_longitude": 105.800507970228,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 667,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80044493831912,
          21.02477157315537,
          105.80045432604622,
          21.025054484435536
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80045432604622,
            21.02477157315537
          ],
          [
            105.80044493831912,
            21.025054484435536
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1194/36 Phố Chùa Láng",
        "diemDau": "34",
        "diemCuoi": "34-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.02477157315537,
        "to_latitude": 21.025054484435536,
        "to_longitude": 105.80044493831912,
        "from_longitude": 105.80045432604622,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 668,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80266312529854,
          21.023357634606974,
          105.80298230817255,
          21.023400196918914
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80266312529854,
            21.023357634606974
          ],
          [
            105.80298230817255,
            21.023400196918914
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.023357634606974,
        "to_latitude": 21.023400196918914,
        "to_longitude": 105.80298230817255,
        "from_longitude": 105.80266312529854,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 669,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80298230817255,
          21.023400196918914,
          105.80332555090276,
          21.023439802795156
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80298230817255,
            21.023400196918914
          ],
          [
            105.80332555090276,
            21.023439802795156
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.023400196918914,
        "to_latitude": 21.023439802795156,
        "to_longitude": 105.80332555090276,
        "from_longitude": 105.80298230817255,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 670,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83113242702512,
          21.02637858970862,
          105.83116729576169,
          21.026591396832433
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83116729576169,
            21.026591396832433
          ],
          [
            105.83113242702512,
            21.02637858970862
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ  An Trạch 2",
        "diemDau": "20",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.026591396832433,
        "to_latitude": 21.02637858970862,
        "to_longitude": 105.83113242702512,
        "from_longitude": 105.83116729576169,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 671,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83106000738013,
          21.026060630260314,
          105.83113242702512,
          21.02637858970862
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83113242702512,
            21.02637858970862
          ],
          [
            105.83106000738013,
            21.026060630260314
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ  An Trạch 2",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02637858970862,
        "to_latitude": 21.026060630260314,
        "to_longitude": 105.83106000738013,
        "from_longitude": 105.83113242702512,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 672,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83100636319834,
          21.025818989667762,
          105.83106000738013,
          21.026060630260314
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83106000738013,
            21.026060630260314
          ],
          [
            105.83100636319834,
            21.025818989667762
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ  An Trạch 2",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.026060630260314,
        "to_latitude": 21.025818989667762,
        "to_longitude": 105.83100636319834,
        "from_longitude": 105.83106000738013,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 673,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83100636319834,
          21.025797749889147,
          105.83124239759643,
          21.025818989667762
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83100636319834,
            21.025818989667762
          ],
          [
            105.83124239759643,
            21.025797749889147
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ  An Trạch 2",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.025818989667762,
        "to_latitude": 21.025797749889147,
        "to_longitude": 105.83124239759643,
        "from_longitude": 105.83100636319834,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 674,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83124239759643,
          21.025499818241283,
          105.8312531264274,
          21.025797749889147
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83124239759643,
            21.025797749889147
          ],
          [
            105.8312531264274,
            21.025499818241283
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ  An Trạch 2",
        "diemDau": "33",
        "diemCuoi": "34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.025797749889147,
        "to_latitude": 21.025499818241283,
        "to_longitude": 105.8312531264274,
        "from_longitude": 105.83124239759643,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 675,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83115388469602,
          21.025271987755968,
          105.8312531264274,
          21.025499818241283
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8312531264274,
            21.025499818241283
          ],
          [
            105.8312366979117,
            21.02537741225405
          ],
          [
            105.83115388469602,
            21.025271987755968
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ  An Trạch 2",
        "diemDau": "34",
        "diemCuoi": "34-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.025499818241283,
        "to_latitude": 21.025271987755968,
        "to_longitude": 105.83115388469602,
        "from_longitude": 105.8312531264274,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 676,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80217127518661,
          21.02177812609865,
          105.80239389853699,
          21.021803163019374
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80239389853699,
            21.021803163019374
          ],
          [
            105.80217127518661,
            21.02177812609865
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 185 Phố Chùa Láng",
        "diemDau": "45",
        "diemCuoi": "46",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.021803163019374,
        "to_latitude": 21.02177812609865,
        "to_longitude": 105.80217127518661,
        "from_longitude": 105.80239389853699,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 677,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80191110091886,
          21.021716316185845,
          105.80217127518661,
          21.02177812609865
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80217127518661,
            21.02177812609865
          ],
          [
            105.80194647250539,
            21.021716316185845
          ],
          [
            105.80191110091886,
            21.0217706150218
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 185 Phố Chùa Láng",
        "diemDau": "44",
        "diemCuoi": "45",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.02177812609865,
        "to_latitude": 21.0217706150218,
        "to_longitude": 105.80191110091886,
        "from_longitude": 105.80217127518661,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 678,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80156107263784,
          21.021699259770166,
          105.80172871070256,
          21.021978421347544
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80172871070256,
            21.021699259770166
          ],
          [
            105.80156107263784,
            21.021978421347544
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 185 Phố Chùa Láng",
        "diemDau": "41",
        "diemCuoi": "42",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.021699259770166,
        "to_latitude": 21.021978421347544,
        "to_longitude": 105.80156107263784,
        "from_longitude": 105.80172871070256,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 679,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80172871070256,
          21.02144513465095,
          105.80189500766342,
          21.021699259770166
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80189500766342,
            21.02144513465095
          ],
          [
            105.80172871070256,
            21.021699259770166
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 185 Phố Chùa Láng",
        "diemDau": "42",
        "diemCuoi": "43",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.02144513465095,
        "to_latitude": 21.021699259770166,
        "to_longitude": 105.80172871070256,
        "from_longitude": 105.80189500766342,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 680,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80142159777238,
          21.021978421347544,
          105.80156107263784,
          21.022278863716725
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80156107263784,
            21.021978421347544
          ],
          [
            105.80142159777238,
            21.022278863716725
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 185 Phố Chùa Láng",
        "diemDau": "40",
        "diemCuoi": "41",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.021978421347544,
        "to_latitude": 21.022278863716725,
        "to_longitude": 105.80142159777238,
        "from_longitude": 105.80156107263784,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 681,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80142159777238,
          21.022158686841685,
          105.80168981867234,
          21.022278863716725
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80142159777238,
            21.022278863716725
          ],
          [
            105.8014953585212,
            21.022158686841685
          ],
          [
            105.80168981867234,
            21.022228790030834
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 185 Phố Chùa Láng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.022278863716725,
        "to_latitude": 21.022228790030834,
        "to_longitude": 105.80168981867234,
        "from_longitude": 105.80142159777238,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 682,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80168981867234,
          21.022193738440397,
          105.80192987638202,
          21.02230515239532
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80168981867234,
            21.022228790030834
          ],
          [
            105.80187891440798,
            21.02230515239532
          ],
          [
            105.80192987638202,
            21.022193738440397
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 185 Phố Chùa Láng",
        "diemDau": "40-1",
        "diemCuoi": "40-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.022228790030834,
        "to_latitude": 21.022193738440397,
        "to_longitude": 105.80192987638202,
        "from_longitude": 105.80168981867234,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 683,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80172871070256,
          21.021699259770166,
          105.80191110091886,
          21.0217706150218
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80191110091886,
            21.0217706150218
          ],
          [
            105.80172871070256,
            21.021699259770166
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 185 Phố Chùa Láng",
        "diemDau": "42",
        "diemCuoi": "44",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.0217706150218,
        "to_latitude": 21.021699259770166,
        "to_longitude": 105.80172871070256,
        "from_longitude": 105.80191110091886,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 684,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80192987638202,
          21.022147420255003,
          105.80207471566298,
          21.02222378266118
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80192987638202,
            21.022193738440397
          ],
          [
            105.80203850584051,
            21.02222378266118
          ],
          [
            105.80207471566298,
            21.022147420255003
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 60,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 185 Phố Chùa Láng",
        "diemDau": "42",
        "diemCuoi": "43",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.022193738440397,
        "to_latitude": 21.022147420255003,
        "to_longitude": 105.80207471566298,
        "from_longitude": 105.80192987638202,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 685,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80131699161655,
          21.022278863716725,
          105.80142159777238,
          21.02250294325555
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80142159777238,
            21.022278863716725
          ],
          [
            105.80131699161655,
            21.02250294325555
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 185 Phố Chùa Láng",
        "diemDau": "39",
        "diemCuoi": "40",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.022278863716725,
        "to_latitude": 21.02250294325555,
        "to_longitude": 105.80131699161655,
        "from_longitude": 105.80142159777238,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 686,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83094165490738,
          21.02637858970862,
          105.83113242702512,
          21.026446459219766
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83113242702512,
            21.02637858970862
          ],
          [
            105.83094165490738,
            21.026446459219766
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 2  An Trạch 2",
        "diemDau": "30",
        "diemCuoi": "30-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02637858970862,
        "to_latitude": 21.026446459219766,
        "to_longitude": 105.83094165490738,
        "from_longitude": 105.83113242702512,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 687,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83100636319834,
          21.025818989667762,
          105.83116193132824,
          21.02590790933886
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83100636319834,
            21.025818989667762
          ],
          [
            105.83111767487352,
            21.02583401141317
          ],
          [
            105.83116193132824,
            21.02590790933886
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 59  An Trạch 2",
        "diemDau": "32",
        "diemCuoi": "32-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.025818989667762,
        "to_latitude": 21.02590790933886,
        "to_longitude": 105.83116193132824,
        "from_longitude": 105.83100636319834,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 688,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83116461353598,
          21.025499818241283,
          105.8312531264274,
          21.025572423487496
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8312531264274,
            21.025499818241283
          ],
          [
            105.83123569206809,
            21.025568627014064
          ],
          [
            105.83116461353598,
            21.025572423487496
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 68  An Trạch 2",
        "diemDau": "34",
        "diemCuoi": "34-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.025499818241283,
        "to_latitude": 21.025572423487496,
        "to_longitude": 105.83116461353598,
        "from_longitude": 105.8312531264274,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 689,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83085615949291,
          21.025484796462987,
          105.83094199017663,
          21.025589907855927
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83094199017663,
            21.025589907855927
          ],
          [
            105.83085615949291,
            21.025484796462987
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 68  An Trạch 2",
        "diemDau": "43",
        "diemCuoi": "42",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.025589907855927,
        "to_latitude": 21.025484796462987,
        "to_longitude": 105.83085615949291,
        "from_longitude": 105.83094199017663,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 690,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83077301100802,
          21.025338334033332,
          105.83085615949291,
          21.025484796462987
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83085615949291,
            21.025484796462987
          ],
          [
            105.83077301100802,
            21.025338334033332
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 68  An Trạch 2",
        "diemDau": "43",
        "diemCuoi": "44",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.025484796462987,
        "to_latitude": 21.025338334033332,
        "to_longitude": 105.83077301100802,
        "from_longitude": 105.83085615949291,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 691,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83067913370112,
          21.0251304913587,
          105.83077301100802,
          21.025338334033332
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83077301100802,
            21.025338334033332
          ],
          [
            105.83067913370112,
            21.0251304913587
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 68  An Trạch 2",
        "diemDau": "44",
        "diemCuoi": "45",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.025338334033332,
        "to_latitude": 21.0251304913587,
        "to_longitude": 105.83067913370112,
        "from_longitude": 105.83077301100802,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 692,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83061476067938,
          21.025484796462987,
          105.83085615949291,
          21.02557868256084
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83085615949291,
            21.025484796462987
          ],
          [
            105.83061476067938,
            21.02557868256084
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 68  An Trạch 2",
        "diemDau": "41",
        "diemCuoi": "43",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.025484796462987,
        "to_latitude": 21.02557868256084,
        "to_longitude": 105.83061476067938,
        "from_longitude": 105.83085615949291,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 693,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80468433733812,
          21.023589692958716,
          105.80487361158394,
          21.023600673621154
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80487361158394,
            21.023600673621154
          ],
          [
            105.80468433733812,
            21.023589692958716
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "36",
        "diemCuoi": "35",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023600673621154,
        "to_latitude": 21.023589692958716,
        "to_longitude": 105.80468433733812,
        "from_longitude": 105.80487361158394,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 694,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80453011032108,
          21.023582181972273,
          105.80468433733812,
          21.023589692958716
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80468433733812,
            21.023589692958716
          ],
          [
            105.80453011032108,
            21.023582181972273
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "35",
        "diemCuoi": "34",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023589692958716,
        "to_latitude": 21.023582181972273,
        "to_longitude": 105.80453011032108,
        "from_longitude": 105.80468433733812,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 695,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80444427963741,
          21.023575922817603,
          105.80453011032108,
          21.023582181972273
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80453011032108,
            21.023582181972273
          ],
          [
            105.80444427963741,
            21.023575922817603
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 106 Chùa Láng",
        "diemDau": "34",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023582181972273,
        "to_latitude": 21.023575922817603,
        "to_longitude": 105.80444427963741,
        "from_longitude": 105.80453011032108,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 696,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80436515446412,
          21.023575922817603,
          105.80444427963741,
          21.023603463097732
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80444427963741,
            21.023575922817603
          ],
          [
            105.80436515446412,
            21.023603463097732
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 106 Chùa Láng",
        "diemDau": "",
        "diemCuoi": "51-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023575922817603,
        "to_latitude": 21.023603463097732,
        "to_longitude": 105.80436515446412,
        "from_longitude": 105.80444427963741,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 697,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80433449326057,
          21.023561197969833,
          105.80436515446412,
          21.023603463097732
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80436515446412,
            21.023603463097732
          ],
          [
            105.80433449326057,
            21.023561197969833
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 6,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 106 Chùa Láng",
        "diemDau": "51-1",
        "diemCuoi": "51",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023603463097732,
        "to_latitude": 21.023561197969833,
        "to_longitude": 105.80433449326057,
        "from_longitude": 105.80436515446412,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 698,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80405651472128,
          21.023545640625542,
          105.80433449326057,
          21.023561197969833
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80433449326057,
            21.023561197969833
          ],
          [
            105.80405651472128,
            21.023545640625542
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "51",
        "diemCuoi": "52",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023561197969833,
        "to_latitude": 21.023545640625542,
        "to_longitude": 105.80405651472128,
        "from_longitude": 105.80433449326057,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 699,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80378802940305,
          21.023523890123386,
          105.80405651472128,
          21.023545640625542
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80405651472128,
            21.023545640625542
          ],
          [
            105.80378802940305,
            21.023523890123386
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "52",
        "diemCuoi": "53",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023545640625542,
        "to_latitude": 21.023523890123386,
        "to_longitude": 105.80378802940305,
        "from_longitude": 105.80405651472128,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 700,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80436515446412,
          21.023603463097732,
          105.80436649555901,
          21.02398276735591
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80436515446412,
            21.023603463097732
          ],
          [
            105.80436649555901,
            21.02398276735591
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 106 Chùa Láng",
        "diemDau": "51-1",
        "diemCuoi": "51-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023603463097732,
        "to_latitude": 21.02398276735591,
        "to_longitude": 105.80436649555901,
        "from_longitude": 105.80436515446412,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 701,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80435595665793,
          21.02398276735591,
          105.80436649555901,
          21.02430125072568
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80436649555901,
            21.02398276735591
          ],
          [
            105.80435595665793,
            21.02430125072568
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 106 Chùa Láng",
        "diemDau": "51-2",
        "diemCuoi": "51-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02398276735591,
        "to_latitude": 21.02430125072568,
        "to_longitude": 105.80435595665793,
        "from_longitude": 105.80436649555901,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 702,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80435595665793,
          21.02430125072568,
          105.80436423298016,
          21.02452751720654
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80435595665793,
            21.02430125072568
          ],
          [
            105.80436423298016,
            21.02452751720654
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 106 Chùa Láng",
        "diemDau": "51-5",
        "diemCuoi": "51-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02430125072568,
        "to_latitude": 21.02452751720654,
        "to_longitude": 105.80436423298016,
        "from_longitude": 105.80435595665793,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 703,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80426393933939,
          21.02430125072568,
          105.80435595665793,
          21.024334869103985
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80435595665793,
            21.02430125072568
          ],
          [
            105.80426393933939,
            21.024334869103985
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 106 Chùa Láng",
        "diemDau": "51-5",
        "diemCuoi": "51-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02430125072568,
        "to_latitude": 21.024334869103985,
        "to_longitude": 105.80426393933939,
        "from_longitude": 105.80435595665793,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 704,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80392929549045,
          21.0243207604342,
          105.80426393933939,
          21.024334869103985
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80426393933939,
            21.024334869103985
          ],
          [
            105.80392929549045,
            21.0243207604342
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 106 Chùa Láng",
        "diemDau": "51-7",
        "diemCuoi": "51-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.024334869103985,
        "to_latitude": 21.0243207604342,
        "to_longitude": 105.80392929549045,
        "from_longitude": 105.80426393933939,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 705,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80414640452251,
          21.023941069202714,
          105.80436649555901,
          21.02398276735591
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80436649555901,
            21.02398276735591
          ],
          [
            105.80414640452251,
            21.023941069202714
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 106 Chùa Láng",
        "diemDau": "51-2",
        "diemCuoi": "51-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02398276735591,
        "to_latitude": 21.023941069202714,
        "to_longitude": 105.80414640452251,
        "from_longitude": 105.80436649555901,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 706,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80412611972056,
          21.023941069202714,
          105.80414640452251,
          21.02416667491238
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80414640452251,
            21.023941069202714
          ],
          [
            105.80412611972056,
            21.02416667491238
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 106 Chùa Láng",
        "diemDau": "51-3",
        "diemCuoi": "51-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023941069202714,
        "to_latitude": 21.02416667491238,
        "to_longitude": 105.80412611972056,
        "from_longitude": 105.80414640452251,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 707,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83082397299106,
          21.026060630260314,
          105.83106000738013,
          21.026115709898225
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83106000738013,
            21.026060630260314
          ],
          [
            105.83082397299106,
            21.026115709898225
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "diemDau": "31",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.026060630260314,
        "to_latitude": 21.026115709898225,
        "to_longitude": 105.83082397299106,
        "from_longitude": 105.83106000738013,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 708,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83063353615152,
          21.026105695420643,
          105.83082397299106,
          21.026115709898225
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83082397299106,
            21.026115709898225
          ],
          [
            105.83063353615152,
            21.026105695420643
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "diemDau": "35",
        "diemCuoi": "36",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.026115709898225,
        "to_latitude": 21.026105695420643,
        "to_longitude": 105.83063353615152,
        "from_longitude": 105.83082397299106,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 709,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83057452754527,
          21.026105695420643,
          105.83063353615152,
          21.02624589805291
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83063353615152,
            21.026105695420643
          ],
          [
            105.83057452754527,
            21.02624589805291
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "diemDau": "36",
        "diemCuoi": "36-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.026105695420643,
        "to_latitude": 21.02624589805291,
        "to_longitude": 105.83057452754527,
        "from_longitude": 105.83063353615152,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 710,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83038677292248,
          21.02624589805291,
          105.83057452754527,
          21.026341035478616
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83057452754527,
            21.02624589805291
          ],
          [
            105.83057050423365,
            21.026268389575524
          ],
          [
            105.83038677292248,
            21.026341035478616
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "diemDau": "36-1",
        "diemCuoi": "36-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02624589805291,
        "to_latitude": 21.026341035478616,
        "to_longitude": 105.83038677292248,
        "from_longitude": 105.83057452754527,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 711,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83038677292248,
          21.026341035478616,
          105.83048333244612,
          21.0265062740212
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83038677292248,
            21.026341035478616
          ],
          [
            105.83048333244612,
            21.0265062740212
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "diemDau": "36-2",
        "diemCuoi": "36-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.026341035478616,
        "to_latitude": 21.0265062740212,
        "to_longitude": 105.83048333244612,
        "from_longitude": 105.83038677292248,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 712,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83056111649759,
          21.025994284307,
          105.83063353615152,
          21.026115668867746
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83063353615152,
            21.026105695420643
          ],
          [
            105.83060403184841,
            21.026115668867746
          ],
          [
            105.83056111649759,
            21.02601927948744
          ],
          [
            105.83057586864915,
            21.025994284307
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "diemDau": "36",
        "diemCuoi": "37",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.026105695420643,
        "to_latitude": 21.025994284307,
        "to_longitude": 105.83057586864915,
        "from_longitude": 105.83063353615152,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 713,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83046321587008,
          21.025775217265817,
          105.83057586864915,
          21.025994284307
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83057586864915,
            21.025994284307
          ],
          [
            105.8305289299957,
            21.025885335699968
          ],
          [
            105.83046321587008,
            21.025775217265817
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "diemDau": "37",
        "diemCuoi": "38",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.025994284307,
        "to_latitude": 21.025775217265817,
        "to_longitude": 105.83046321587008,
        "from_longitude": 105.83057586864915,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 714,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83034653977941,
          21.025622496051934,
          105.83046321587008,
          21.025775217265817
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83046321587008,
            21.025775217265817
          ],
          [
            105.83034653977941,
            21.025622496051934
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "diemDau": "38",
        "diemCuoi": "39",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.025775217265817,
        "to_latitude": 21.025622496051934,
        "to_longitude": 105.83034653977941,
        "from_longitude": 105.83046321587008,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 715,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82999382929064,
          21.025622496051934,
          105.83034653977941,
          21.025693849425707
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83034653977941,
            21.025622496051934
          ],
          [
            105.82999382929064,
            21.025693849425707
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "diemDau": "39",
        "diemCuoi": "40",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.025622496051934,
        "to_latitude": 21.025693849425707,
        "to_longitude": 105.82999382929064,
        "from_longitude": 105.83034653977941,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 716,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83034653977941,
          21.02557868256084,
          105.83061476067938,
          21.025622496051934
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83034653977941,
            21.025622496051934
          ],
          [
            105.8304176183205,
            21.02560117418244
          ],
          [
            105.83048601464488,
            21.02560117418244
          ],
          [
            105.83061476067938,
            21.02557868256084
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "diemDau": "39",
        "diemCuoi": "41",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.025622496051934,
        "to_latitude": 21.02557868256084,
        "to_longitude": 105.83061476067938,
        "from_longitude": 105.83034653977941,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 717,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80536159508941,
          21.023863843683333,
          105.80538841718482,
          21.024059128822817
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80538841718482,
            21.023863843683333
          ],
          [
            105.80536159508941,
            21.024059128822817
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023863843683333,
        "to_latitude": 21.024059128822817,
        "to_longitude": 105.80536159508941,
        "from_longitude": 105.80538841718482,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 718,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80536159508941,
          21.024059128822817,
          105.80544206136659,
          21.024324515909292
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80536159508941,
            21.024059128822817
          ],
          [
            105.80544206136659,
            21.024324515909292
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.024059128822817,
        "to_latitude": 21.024324515909292,
        "to_longitude": 105.80544206136659,
        "from_longitude": 105.80536159508941,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 719,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80604555839602,
          21.02463246448343,
          105.80627622836963,
          21.024637471772227
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80604555839602,
            21.024637471772227
          ],
          [
            105.80627622836963,
            21.02463246448343
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.024637471772227,
        "to_latitude": 21.02463246448343,
        "to_longitude": 105.80627622836963,
        "from_longitude": 105.80604555839602,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 720,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80627622836963,
          21.024454705628376,
          105.8062815927851,
          21.02463246448343
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80627622836963,
            21.02463246448343
          ],
          [
            105.8062815927851,
            21.024454705628376
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02463246448343,
        "to_latitude": 21.024454705628376,
        "to_longitude": 105.8062815927851,
        "from_longitude": 105.80627622836963,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 721,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8062815927851,
          21.024241895451627,
          105.8062815927851,
          21.024454705628376
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8062815927851,
            21.024454705628376
          ],
          [
            105.8062815927851,
            21.024241895451627
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.024454705628376,
        "to_latitude": 21.024241895451627,
        "to_longitude": 105.8062815927851,
        "from_longitude": 105.8062815927851,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 722,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80626013511419,
          21.02416177860095,
          105.8062815927851,
          21.024241895451627
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8062815927851,
            21.024241895451627
          ],
          [
            105.80626013511419,
            21.02416177860095
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 8,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "diemDau": "29",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.024241895451627,
        "to_latitude": 21.02416177860095,
        "to_longitude": 105.80626013511419,
        "from_longitude": 105.8062815927851,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 723,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8059570454956,
          21.02416177860095,
          105.80626013511419,
          21.024196829728684
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80626013511419,
            21.02416177860095
          ],
          [
            105.8059570454956,
            21.024196829728684
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "diemDau": "",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02416177860095,
        "to_latitude": 21.024196829728684,
        "to_longitude": 105.8059570454956,
        "from_longitude": 105.80626013511419,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 724,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80536159508941,
          21.0240215740076,
          105.80571832888978,
          21.024059128822817
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80536159508941,
            21.024059128822817
          ],
          [
            105.80571832888978,
            21.0240215740076
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "diemDau": "21",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.024059128822817,
        "to_latitude": 21.0240215740076,
        "to_longitude": 105.80571832888978,
        "from_longitude": 105.80536159508941,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 725,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80571832888978,
          21.023991530149136,
          105.8060643338592,
          21.0240215740076
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80571832888978,
            21.0240215740076
          ],
          [
            105.8060643338592,
            21.023991530149136
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "diemDau": "33",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.0240215740076,
        "to_latitude": 21.023991530149136,
        "to_longitude": 105.8060643338592,
        "from_longitude": 105.80571832888978,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 726,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8060643338592,
          21.02396899725196,
          105.80631377929596,
          21.023991530149136
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8060643338592,
            21.023991530149136
          ],
          [
            105.80631377929596,
            21.02396899725196
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "diemDau": "32",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023991530149136,
        "to_latitude": 21.02396899725196,
        "to_longitude": 105.80631377929596,
        "from_longitude": 105.8060643338592,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 727,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80538841718482,
          21.02384381442411,
          105.8055439853057,
          21.023863843683333
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80538841718482,
            21.023863843683333
          ],
          [
            105.8055439853057,
            21.02384381442411
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "diemDau": "20",
        "diemCuoi": "20-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023863843683333,
        "to_latitude": 21.02384381442411,
        "to_longitude": 105.8055439853057,
        "from_longitude": 105.80538841718482,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 728,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8055439853057,
          21.02382378516222,
          105.80569955342663,
          21.02384381442411
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8055439853057,
            21.02384381442411
          ],
          [
            105.80569955342663,
            21.02382378516222
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "diemDau": "20-1",
        "diemCuoi": "20-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02384381442411,
        "to_latitude": 21.02382378516222,
        "to_longitude": 105.80569955342663,
        "from_longitude": 105.8055439853057,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 729,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80487361158394,
          21.023600673621154,
          105.80559360615794,
          21.023644537513114
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80487361158394,
            21.023600673621154
          ],
          [
            105.80540977592446,
            21.023644537513114
          ],
          [
            105.80559360615794,
            21.02361097408645
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 70,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "36",
        "diemCuoi": "38",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023600673621154,
        "to_latitude": 21.02361097408645,
        "to_longitude": 105.80559360615794,
        "from_longitude": 105.80487361158394,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 730,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80559360615794,
          21.023513331254733,
          105.80604421727418,
          21.02361097408645
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80559360615794,
            21.02361097408645
          ],
          [
            105.80604421727418,
            21.023513331254733
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "38",
        "diemCuoi": "39",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02361097408645,
        "to_latitude": 21.023513331254733,
        "to_longitude": 105.80604421727418,
        "from_longitude": 105.80559360615794,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 731,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80604421727418,
          21.02346075431982,
          105.8063902222346,
          21.023513331254733
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80604421727418,
            21.023513331254733
          ],
          [
            105.8063902222346,
            21.02346075431982
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "39",
        "diemCuoi": "40",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023513331254733,
        "to_latitude": 21.02346075431982,
        "to_longitude": 105.8063902222346,
        "from_longitude": 105.80604421727418,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 732,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8063902222346,
          21.02340317003547,
          105.80675768487492,
          21.02346075431982
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8063902222346,
            21.02346075431982
          ],
          [
            105.80675768487492,
            21.02340317003547
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "40",
        "diemCuoi": "41",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02346075431982,
        "to_latitude": 21.02340317003547,
        "to_longitude": 105.80675768487492,
        "from_longitude": 105.8063902222346,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 733,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80675768487492,
          21.02332305273403,
          105.80717074505583,
          21.02340317003547
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80675768487492,
            21.02340317003547
          ],
          [
            105.80717074505583,
            21.02332305273403
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "41",
        "diemCuoi": "42",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02340317003547,
        "to_latitude": 21.02332305273403,
        "to_longitude": 105.80717074505583,
        "from_longitude": 105.80675768487492,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 734,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80717074505583,
          21.023257957394545,
          105.80748724572209,
          21.02332305273403
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80717074505583,
            21.02332305273403
          ],
          [
            105.80748724572209,
            21.023257957394545
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "42",
        "diemCuoi": "43",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02332305273403,
        "to_latitude": 21.023257957394545,
        "to_longitude": 105.80748724572209,
        "from_longitude": 105.80717074505583,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 735,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80748724572209,
          21.02315280332436,
          105.80795395009378,
          21.023257957394545
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80748724572209,
            21.023257957394545
          ],
          [
            105.80795395009378,
            21.02315280332436
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "43",
        "diemCuoi": "44",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023257957394545,
        "to_latitude": 21.02315280332436,
        "to_longitude": 105.80795395009378,
        "from_longitude": 105.80748724572209,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 736,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80795395009378,
          21.02311024094181,
          105.80818462006738,
          21.02315280332436
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80795395009378,
            21.02315280332436
          ],
          [
            105.80818462006738,
            21.02311024094181
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "44",
        "diemCuoi": "45",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02315280332436,
        "to_latitude": 21.02311024094181,
        "to_longitude": 105.80818462006738,
        "from_longitude": 105.80795395009378,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 737,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80818462006738,
          21.023065174876752,
          105.80839919678556,
          21.02311024094181
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80818462006738,
            21.02311024094181
          ],
          [
            105.80839919678556,
            21.023065174876752
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "45",
        "diemCuoi": "46",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02311024094181,
        "to_latitude": 21.023065174876752,
        "to_longitude": 105.80839919678556,
        "from_longitude": 105.80818462006738,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 738,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80839919678556,
          21.022994262299044,
          105.80872424952902,
          21.023065174876752
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80839919678556,
            21.023065174876752
          ],
          [
            105.80872424952902,
            21.022994262299044
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "46",
        "diemCuoi": "47",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023065174876752,
        "to_latitude": 21.022994262299044,
        "to_longitude": 105.80872424952902,
        "from_longitude": 105.80839919678556,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 739,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80872424952902,
          21.022940147751218,
          105.80900252628815,
          21.022994262299044
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80872424952902,
            21.022994262299044
          ],
          [
            105.80900252628815,
            21.022940147751218
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "47",
        "diemCuoi": "48",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.022994262299044,
        "to_latitude": 21.022940147751218,
        "to_longitude": 105.80900252628815,
        "from_longitude": 105.80872424952902,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 740,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80900252628815,
          21.02288131142945,
          105.80930159259513,
          21.022940147751218
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80900252628815,
            21.022940147751218
          ],
          [
            105.80930159259513,
            21.02288131142945
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "48",
        "diemCuoi": "49",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.022940147751218,
        "to_latitude": 21.02288131142945,
        "to_longitude": 105.80930159259513,
        "from_longitude": 105.80900252628815,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 741,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80930159259513,
          21.022832489782626,
          105.80955506134352,
          21.02288131142945
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80930159259513,
            21.02288131142945
          ],
          [
            105.80955506134352,
            21.022832489782626
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "49",
        "diemCuoi": "50",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02288131142945,
        "to_latitude": 21.022832489782626,
        "to_longitude": 105.80955506134352,
        "from_longitude": 105.80930159259513,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 742,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8017210274889,
          21.023069399811195,
          105.80184873953944,
          21.023155933198417
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8017210274889,
            21.023155933198417
          ],
          [
            105.80184873953944,
            21.023069399811195
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 159A Phố Chùa Láng",
        "diemDau": "06",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.023155933198417,
        "to_latitude": 21.023069399811195,
        "to_longitude": 105.80184873953944,
        "from_longitude": 105.8017210274889,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 743,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80184873953944,
          21.0227977513022,
          105.80187422052197,
          21.023069399811195
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80184873953944,
            21.023069399811195
          ],
          [
            105.80187422052197,
            21.0227977513022
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 159A Phố Chùa Láng",
        "diemDau": "6-1",
        "diemCuoi": "6-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.023069399811195,
        "to_latitude": 21.0227977513022,
        "to_longitude": 105.80187422052197,
        "from_longitude": 105.80184873953944,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 744,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80187422052197,
          21.02256490932903,
          105.80193457022312,
          21.0227977513022
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80187422052197,
            21.0227977513022
          ],
          [
            105.80193457022312,
            21.02256490932903
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 159A Phố Chùa Láng",
        "diemDau": "6-2",
        "diemCuoi": "6-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.0227977513022,
        "to_latitude": 21.02256490932903,
        "to_longitude": 105.80193457022312,
        "from_longitude": 105.80187422052197,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 745,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83173055964802,
          21.02650752582538,
          105.83174017089992,
          21.02653861234861
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83173055964802,
            21.02650752582538
          ],
          [
            105.83174017089992,
            21.02653861234861
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐTĐ",
        "chieuDaiQL": 6,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đoàn Thị Điểm",
        "diemDau": "Tủ điện",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đoàn Thị Điểm",
        "from_latitude": 21.02650752582538,
        "to_latitude": 21.02653861234861,
        "to_longitude": 105.83174017089992,
        "from_longitude": 105.83173055964802,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 746,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80223967143911,
          21.019518213719795,
          105.80231477329181,
          21.01967845236967
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80231477329181,
            21.019518213719795
          ],
          [
            105.80223967143911,
            21.01967845236967
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "850 Đường Láng",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.019518213719795,
        "to_latitude": 21.01967845236967,
        "to_longitude": 105.80223967143911,
        "from_longitude": 105.80231477329181,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 747,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80212968924059,
          21.01967845236967,
          105.80223967143911,
          21.019888990259943
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80223967143911,
            21.01967845236967
          ],
          [
            105.80212968924059,
            21.019888990259943
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.01967845236967,
        "to_latitude": 21.019888990259943,
        "to_longitude": 105.80212968924059,
        "from_longitude": 105.80223967143911,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 748,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8020197070421,
          21.019888990259943,
          105.80212968924059,
          21.020099527852985
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80212968924059,
            21.019888990259943
          ],
          [
            105.8020197070421,
            21.020099527852985
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.019888990259943,
        "to_latitude": 21.020099527852985,
        "to_longitude": 105.8020197070421,
        "from_longitude": 105.80212968924059,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 749,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80190972484357,
          21.020099527852985,
          105.8020197070421,
          21.020310065148696
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8020197070421,
            21.020099527852985
          ],
          [
            105.80190972484357,
            21.020310065148696
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.020099527852985,
        "to_latitude": 21.020310065148696,
        "to_longitude": 105.80190972484357,
        "from_longitude": 105.8020197070421,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 750,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80179003126244,
          21.020310065148696,
          105.80190972484357,
          21.020516621655016
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80190972484357,
            21.020310065148696
          ],
          [
            105.80179003126244,
            21.020516621655016
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.020310065148696,
        "to_latitude": 21.020516621655016,
        "to_longitude": 105.80179003126244,
        "from_longitude": 105.80190972484357,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 751,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8016723493416,
          21.020516621655016,
          105.80179003126244,
          21.0207031481928
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80179003126244,
            21.020516621655016
          ],
          [
            105.8016723493416,
            21.0207031481928
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.020516621655016,
        "to_latitude": 21.0207031481928,
        "to_longitude": 105.8016723493416,
        "from_longitude": 105.80179003126244,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 752,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80157244275517,
          21.0207031481928,
          105.8016723493416,
          21.020888540244403
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8016723493416,
            21.0207031481928
          ],
          [
            105.80157244275517,
            21.020888540244403
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.0207031481928,
        "to_latitude": 21.020888540244403,
        "to_longitude": 105.80157244275517,
        "from_longitude": 105.8016723493416,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 753,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80145877842214,
          21.020888540244403,
          105.80157244275517,
          21.021071506131122
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80157244275517,
            21.020888540244403
          ],
          [
            105.80145877842214,
            21.021071506131122
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.020888540244403,
        "to_latitude": 21.021071506131122,
        "to_longitude": 105.80145877842214,
        "from_longitude": 105.80157244275517,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 754,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80132366217344,
          21.021071506131122,
          105.80145877842214,
          21.021300907839862
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80145877842214,
            21.021071506131122
          ],
          [
            105.80132366217344,
            21.021300907839862
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.021071506131122,
        "to_latitude": 21.021300907839862,
        "to_longitude": 105.80132366217344,
        "from_longitude": 105.80145877842214,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 755,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80119491613895,
          21.021300907839862,
          105.80132366217344,
          21.02152185922846
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80132366217344,
            21.021300907839862
          ],
          [
            105.80119491613895,
            21.02152185922846
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.021300907839862,
        "to_latitude": 21.02152185922846,
        "to_longitude": 105.80119491613895,
        "from_longitude": 105.80132366217344,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 756,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80108896888825,
          21.02152185922846,
          105.80119491613895,
          21.021704628968692
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80119491613895,
            21.02152185922846
          ],
          [
            105.80108896888825,
            21.021704628968692
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 7,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.02152185922846,
        "to_latitude": 21.021704628968692,
        "to_longitude": 105.80108896888825,
        "from_longitude": 105.80119491613895,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 757,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8009709516847,
          21.021704628968692,
          105.80108896888825,
          21.02187738372241
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80108896888825,
            21.021704628968692
          ],
          [
            105.8009709516847,
            21.02187738372241
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.021704628968692,
        "to_latitude": 21.02187738372241,
        "to_longitude": 105.8009709516847,
        "from_longitude": 105.80108896888825,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 758,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80087170994436,
          21.02187738372241,
          105.8009709516847,
          21.022011331111077
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8009709516847,
            21.02187738372241
          ],
          [
            105.80087170994436,
            21.022011331111077
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.02187738372241,
        "to_latitude": 21.022011331111077,
        "to_longitude": 105.80087170994436,
        "from_longitude": 105.8009709516847,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 759,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80075503385368,
          21.022011331111077,
          105.80087170994436,
          21.022136515477662
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80087170994436,
            21.022011331111077
          ],
          [
            105.80075503385368,
            21.022136515477662
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.022011331111077,
        "to_latitude": 21.022136515477662,
        "to_longitude": 105.80075503385368,
        "from_longitude": 105.80087170994436,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 760,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80063969886687,
          21.022136515477662,
          105.80075503385368,
          21.022260447896763
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80075503385368,
            21.022136515477662
          ],
          [
            105.80063969886687,
            21.022260447896763
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.022136515477662,
        "to_latitude": 21.022260447896763,
        "to_longitude": 105.80063969886687,
        "from_longitude": 105.80075503385368,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 761,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80049586542057,
          21.022260447896763,
          105.80063969886687,
          21.02239595974302
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80063969886687,
            21.022260447896763
          ],
          [
            105.80049586542057,
            21.02239595974302
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.022260447896763,
        "to_latitude": 21.02239595974302,
        "to_longitude": 105.80049586542057,
        "from_longitude": 105.80063969886687,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 762,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8003698015938,
          21.02239595974302,
          105.80049586542057,
          21.022528654825674
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80049586542057,
            21.02239595974302
          ],
          [
            105.8003698015938,
            21.022528654825674
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.02239595974302,
        "to_latitude": 21.022528654825674,
        "to_longitude": 105.8003698015938,
        "from_longitude": 105.80049586542057,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 763,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80013276137008,
          21.022528654825674,
          105.8003698015938,
          21.022738024893773
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8003698015938,
            21.022528654825674
          ],
          [
            105.80013276137008,
            21.022738024893773
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.022528654825674,
        "to_latitude": 21.022738024893773,
        "to_longitude": 105.80013276137008,
        "from_longitude": 105.8003698015938,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 764,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.7999624410976,
          21.022738024893773,
          105.80013276137008,
          21.022888245388657
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80013276137008,
            21.022738024893773
          ],
          [
            105.7999624410976,
            21.022888245388657
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.022738024893773,
        "to_latitude": 21.022888245388657,
        "to_longitude": 105.7999624410976,
        "from_longitude": 105.80013276137008,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 765,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80190972484357,
          21.020310065148696,
          105.80216151719192,
          21.020377978462246
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80190972484357,
            21.020310065148696
          ],
          [
            105.80216151719192,
            21.020377978462246
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 886 đường láng",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.020310065148696,
        "to_latitude": 21.020377978462246,
        "to_longitude": 105.80216151719192,
        "from_longitude": 105.80190972484357,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 766,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80216151719192,
          21.020002997692167,
          105.8023590298094,
          21.020377978462246
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80216151719192,
            21.020377978462246
          ],
          [
            105.8023590298094,
            21.020002997692167
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 886 đường láng",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.020377978462246,
        "to_latitude": 21.020002997692167,
        "to_longitude": 105.8023590298094,
        "from_longitude": 105.80216151719192,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 767,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80819819883953,
          21.022535491093265,
          105.80843155102087,
          21.022535491093265
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80843155102087,
            21.022535491093265
          ],
          [
            105.80819819883953,
            21.022535491093265
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 Chùa Láng",
        "diemDau": "47-1",
        "diemCuoi": "47-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.022535491093265,
        "to_latitude": 21.022535491093265,
        "to_longitude": 105.80819819883953,
        "from_longitude": 105.80843155102087,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 768,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80788438038101,
          21.02315280332436,
          105.80795395009378,
          21.023236519726986
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80795395009378,
            21.02315280332436
          ],
          [
            105.80788438038101,
            21.023236519726986
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tổ 77 Chùa Láng",
        "diemDau": "44",
        "diemCuoi": "44-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02315280332436,
        "to_latitude": 21.023236519726986,
        "to_longitude": 105.80788438038101,
        "from_longitude": 105.80795395009378,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 769,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80788438038101,
          21.023236519726986,
          105.80794204787439,
          21.023509419268375
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80788438038101,
            21.023236519726986
          ],
          [
            105.80794204787439,
            21.023509419268375
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tổ 77 Chùa Láng",
        "diemDau": "44-1",
        "diemCuoi": "44-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023236519726986,
        "to_latitude": 21.023509419268375,
        "to_longitude": 105.80794204787439,
        "from_longitude": 105.80788438038101,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 770,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80794204787439,
          21.023435561184876,
          105.80834303812048,
          21.023509419268375
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80794204787439,
            21.023509419268375
          ],
          [
            105.80834303812048,
            21.023435561184876
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tổ 77 Chùa Láng",
        "diemDau": "44-2",
        "diemCuoi": "44-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023509419268375,
        "to_latitude": 21.023435561184876,
        "to_longitude": 105.80834303812048,
        "from_longitude": 105.80794204787439,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 771,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80831621603407,
          21.02318143902527,
          105.80834303812048,
          21.023435561184876
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80834303812048,
            21.023435561184876
          ],
          [
            105.80831621603407,
            21.02318143902527
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tổ 77 Chùa Láng",
        "diemDau": "44-3",
        "diemCuoi": "44-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023435561184876,
        "to_latitude": 21.02318143902527,
        "to_longitude": 105.80831621603407,
        "from_longitude": 105.80834303812048,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 772,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80831621603407,
          21.023117595459095,
          105.80853079275225,
          21.02318143902527
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80831621603407,
            21.02318143902527
          ],
          [
            105.80853079275225,
            21.023117595459095
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tổ 77 Chùa Láng",
        "diemDau": "44-4",
        "diemCuoi": "44-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02318143902527,
        "to_latitude": 21.023117595459095,
        "to_longitude": 105.80853079275225,
        "from_longitude": 105.80831621603407,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 773,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80715750173258,
          21.02320397204319,
          105.80717074505583,
          21.02332305273403
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80717074505583,
            21.02332305273403
          ],
          [
            105.80715750173258,
            21.02320397204319
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "diemDau": "42",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02332305273403,
        "to_latitude": 21.02320397204319,
        "to_longitude": 105.80715750173258,
        "from_longitude": 105.80717074505583,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 774,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80715750173258,
          21.022894768681134,
          105.8071655483558,
          21.02320397204319
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80715750173258,
            21.02320397204319
          ],
          [
            105.8071655483558,
            21.022894768681134
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "diemDau": "42",
        "diemCuoi": "42-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02320397204319,
        "to_latitude": 21.022894768681134,
        "to_longitude": 105.8071655483558,
        "from_longitude": 105.80715750173258,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 775,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8071655483558,
          21.022740792678775,
          105.80719505265893,
          21.022894768681134
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8071655483558,
            21.022894768681134
          ],
          [
            105.80719505265893,
            21.022740792678775
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "diemDau": "42-1",
        "diemCuoi": "42-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.022894768681134,
        "to_latitude": 21.022740792678775,
        "to_longitude": 105.80719505265893,
        "from_longitude": 105.8071655483558,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 776,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8071655483558,
          21.022894768681134,
          105.80727686003098,
          21.022897272354328
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8071655483558,
            21.022894768681134
          ],
          [
            105.80727686003098,
            21.022897272354328
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "diemDau": "42-1",
        "diemCuoi": "42-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.022894768681134,
        "to_latitude": 21.022897272354328,
        "to_longitude": 105.80727686003098,
        "from_longitude": 105.8071655483558,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 777,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80727686003098,
          21.022897272354328,
          105.80754642203482,
          21.022902279701366
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80727686003098,
            21.022897272354328
          ],
          [
            105.80754642203482,
            21.022902279701366
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "diemDau": "42-2",
        "diemCuoi": "42-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.022897272354328,
        "to_latitude": 21.022902279701366,
        "to_longitude": 105.80754642203482,
        "from_longitude": 105.80727686003098,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 778,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80755178645032,
          21.022767081275095,
          105.80761481836818,
          21.02286347275831
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80755178645032,
            21.02286347275831
          ],
          [
            105.80761481836818,
            21.022767081275095
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "diemDau": "42-7",
        "diemCuoi": "42-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02286347275831,
        "to_latitude": 21.022767081275095,
        "to_longitude": 105.80761481836818,
        "from_longitude": 105.80755178645032,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 779,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80761213615146,
          21.02233895211791,
          105.80761481836818,
          21.022767081275095
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80761481836818,
            21.022767081275095
          ],
          [
            105.80761213615146,
            21.02233895211791
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "diemDau": "42-7",
        "diemCuoi": "42-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.022767081275095,
        "to_latitude": 21.02233895211791,
        "to_longitude": 105.80761213615146,
        "from_longitude": 105.80761481836818,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 780,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80761213615146,
          21.022248819507034,
          105.8076148183592,
          21.02233895211791
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80761213615146,
            21.02233895211791
          ],
          [
            105.8076148183592,
            21.022248819507034
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "diemDau": "42-8",
        "diemCuoi": "42-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02233895211791,
        "to_latitude": 21.022248819507034,
        "to_longitude": 105.8076148183592,
        "from_longitude": 105.80761213615146,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 781,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80757994964061,
          21.02212488707827,
          105.8076148183592,
          21.022248819507034
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8076148183592,
            21.022248819507034
          ],
          [
            105.80757994964061,
            21.02212488707827
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "diemDau": "42-10",
        "diemCuoi": "42-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.022248819507034,
        "to_latitude": 21.02212488707827,
        "to_longitude": 105.80757994964061,
        "from_longitude": 105.8076148183592,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 782,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80735732629019,
          21.02212488707827,
          105.80757994964061,
          21.022127390765192
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80757994964061,
            21.02212488707827
          ],
          [
            105.80735732629019,
            21.022127390765192
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "diemDau": "42-11",
        "diemCuoi": "42-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02212488707827,
        "to_latitude": 21.022127390765192,
        "to_longitude": 105.80735732629019,
        "from_longitude": 105.80757994964061,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 783,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80714811398751,
          21.02211362048902,
          105.80735732629019,
          21.022127390765192
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80735732629019,
            21.022127390765192
          ],
          [
            105.80714811398751,
            21.02211362048902
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "diemDau": "42-12",
        "diemCuoi": "42-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.022127390765192,
        "to_latitude": 21.02211362048902,
        "to_longitude": 105.80714811398751,
        "from_longitude": 105.80735732629019,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 784,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80714811398751,
          21.02211362048902,
          105.80715750172357,
          21.022225034503812
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80714811398751,
            21.02211362048902
          ],
          [
            105.80715750172357,
            21.022225034503812
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "diemDau": "42-13",
        "diemCuoi": "42-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02211362048902,
        "to_latitude": 21.022225034503812,
        "to_longitude": 105.80715750172357,
        "from_longitude": 105.80714811398751,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 785,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80731977536387,
          21.02230515239532,
          105.80761213615146,
          21.02233895211791
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80761213615146,
            21.02233895211791
          ],
          [
            105.80731977536387,
            21.02230515239532
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "diemDau": "42-8",
        "diemCuoi": "42-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02233895211791,
        "to_latitude": 21.02230515239532,
        "to_longitude": 105.80731977536387,
        "from_longitude": 105.80761213615146,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 786,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80733452752439,
          21.02274830370677,
          105.80761481836818,
          21.022767081275095
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80761481836818,
            21.022767081275095
          ],
          [
            105.80733452752439,
            21.02274830370677
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "diemDau": "42-5",
        "diemCuoi": "42-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.022767081275095,
        "to_latitude": 21.02274830370677,
        "to_longitude": 105.80733452752439,
        "from_longitude": 105.80761481836818,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 787,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80157244275517,
          21.020888540244403,
          105.80169954166867,
          21.020929684956545
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80157244275517,
            21.020888540244403
          ],
          [
            105.80169954166867,
            21.020929684956545
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "UBND phường Láng Thượng",
        "diemDau": "07",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.020888540244403,
        "to_latitude": 21.020929684956545,
        "to_longitude": 105.80169954166867,
        "from_longitude": 105.80157244275517,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 788,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8016579674307,
          21.020929684956545,
          105.80169954166867,
          21.02101856651211
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80169954166867,
            21.020929684956545
          ],
          [
            105.8016579674307,
            21.02101856651211
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "UBND phường Láng Thượng",
        "diemDau": "7-1",
        "diemCuoi": "7-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.020929684956545,
        "to_latitude": 21.02101856651211,
        "to_longitude": 105.8016579674307,
        "from_longitude": 105.80169954166867,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 789,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80145877842214,
          21.021071506131122,
          105.80168408398028,
          21.021185424626587
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80145877842214,
            21.021071506131122
          ],
          [
            105.80168408398028,
            21.021185424626587
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "08",
        "diemCuoi": "8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.021071506131122,
        "to_latitude": 21.021185424626587,
        "to_longitude": 105.80168408398028,
        "from_longitude": 105.80145877842214,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 790,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80168408398028,
          21.021185424626587,
          105.80200231873641,
          21.02134739535843
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80168408398028,
            21.021185424626587
          ],
          [
            105.80200231873641,
            21.02134739535843
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-1",
        "diemCuoi": "8-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.021185424626587,
        "to_latitude": 21.02134739535843,
        "to_longitude": 105.80200231873641,
        "from_longitude": 105.80168408398028,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 791,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80200231873641,
          21.02134739535843,
          105.80228126847634,
          21.021437528514234
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80200231873641,
            21.02134739535843
          ],
          [
            105.80228126847634,
            21.021437528514234
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-2",
        "diemCuoi": "8-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.02134739535843,
        "to_latitude": 21.021437528514234,
        "to_longitude": 105.80228126847634,
        "from_longitude": 105.80200231873641,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 792,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80228126847634,
          21.021437528514234,
          105.80253568535159,
          21.021529683289412
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80228126847634,
            21.021437528514234
          ],
          [
            105.80253568535159,
            21.021529683289412
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-3",
        "diemCuoi": "8-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.021437528514234,
        "to_latitude": 21.021529683289412,
        "to_longitude": 105.80253568535159,
        "from_longitude": 105.80228126847634,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 793,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80228126847634,
          21.021345830547368,
          105.80252903753102,
          21.02148535029746
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80228126847634,
            21.021437528514234
          ],
          [
            105.80247587350773,
            21.02148535029746
          ],
          [
            105.80252903753102,
            21.021345830547368
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-3",
        "diemCuoi": "8-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.021437528514234,
        "to_latitude": 21.021345830547368,
        "to_longitude": 105.80252903753102,
        "from_longitude": 105.80228126847634,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 794,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80253568535159,
          21.021529683289412,
          105.8028025651387,
          21.021648608825636
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80253568535159,
            21.021529683289412
          ],
          [
            105.8028025651387,
            21.021648608825636
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-5",
        "diemCuoi": "8-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.021529683289412,
        "to_latitude": 21.021648608825636,
        "to_longitude": 105.8028025651387,
        "from_longitude": 105.80253568535159,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 795,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8028025651387,
          21.02139698731679,
          105.80289241914298,
          21.021648608825636
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8028025651387,
            21.021648608825636
          ],
          [
            105.80289241914298,
            21.02139698731679
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-8",
        "diemCuoi": "8-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.021648608825636,
        "to_latitude": 21.02139698731679,
        "to_longitude": 105.80289241914298,
        "from_longitude": 105.8028025651387,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 796,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8028025651387,
          21.021648608825636,
          105.80317941550612,
          21.021750008417772
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8028025651387,
            21.021648608825636
          ],
          [
            105.80317941550612,
            21.021750008417772
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-8",
        "diemCuoi": "8-17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.021648608825636,
        "to_latitude": 21.021750008417772,
        "to_longitude": 105.80317941550612,
        "from_longitude": 105.8028025651387,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 797,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80301982406462,
          21.021696179013098,
          105.80317941550612,
          21.021750008417772
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80317941550612,
            21.021750008417772
          ],
          [
            105.80301982406462,
            21.021696179013098
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-17",
        "diemCuoi": "8-17-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.021750008417772,
        "to_latitude": 21.021696179013098,
        "to_longitude": 105.80301982406462,
        "from_longitude": 105.80317941550612,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 798,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8031130247149,
          21.021750008417772,
          105.80317941550612,
          21.021993813429184
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80317941550612,
            21.021750008417772
          ],
          [
            105.8031130247149,
            21.021993813429184
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-17",
        "diemCuoi": "8-16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.021750008417772,
        "to_latitude": 21.021993813429184,
        "to_longitude": 105.8031130247149,
        "from_longitude": 105.80317941550612,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 799,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80302699240147,
          21.021993813429184,
          105.8031130247149,
          21.022258617460484
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8031130247149,
            21.021993813429184
          ],
          [
            105.80302699240147,
            21.022258617460484
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-16",
        "diemCuoi": "8-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.021993813429184,
        "to_latitude": 21.022258617460484,
        "to_longitude": 105.80302699240147,
        "from_longitude": 105.8031130247149,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 800,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80293606277596,
          21.022258617460484,
          105.80302699240147,
          21.022510672210046
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80302699240147,
            21.022258617460484
          ],
          [
            105.80293606277596,
            21.022510672210046
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-15",
        "diemCuoi": "8-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.022258617460484,
        "to_latitude": 21.022510672210046,
        "to_longitude": 105.80293606277596,
        "from_longitude": 105.80302699240147,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 801,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80260650974624,
          21.022412736703444,
          105.80293606277596,
          21.022510672210046
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80293606277596,
            21.022510672210046
          ],
          [
            105.80260650974624,
            21.022412736703444
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-14",
        "diemCuoi": "8-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.022510672210046,
        "to_latitude": 21.022412736703444,
        "to_longitude": 105.80260650974624,
        "from_longitude": 105.80293606277596,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 802,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80239404280245,
          21.022359145577237,
          105.80260650974624,
          21.022412736703444
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80260650974624,
            21.022412736703444
          ],
          [
            105.80239404280245,
            21.022359145577237
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-12",
        "diemCuoi": "8-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.022412736703444,
        "to_latitude": 21.022359145577237,
        "to_longitude": 105.80239404280245,
        "from_longitude": 105.80260650974624,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 803,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80286698965386,
          21.022510672210046,
          105.80293606277596,
          21.022788837375007
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80293606277596,
            21.022510672210046
          ],
          [
            105.80286698965386,
            21.022788837375007
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-14",
        "diemCuoi": "8-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.022510672210046,
        "to_latitude": 21.022788837375007,
        "to_longitude": 105.80286698965386,
        "from_longitude": 105.80293606277596,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 804,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80289241914298,
          21.02113103559487,
          105.80295939423802,
          21.02139698731679
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80289241914298,
            21.02139698731679
          ],
          [
            105.80295939423802,
            21.02113103559487
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-7",
        "diemCuoi": "8-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.02139698731679,
        "to_latitude": 21.02113103559487,
        "to_longitude": 105.80295939423802,
        "from_longitude": 105.80289241914298,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 805,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80317941550612,
          21.021497198626868,
          105.80324420861088,
          21.021750008417772
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80317941550612,
            21.021750008417772
          ],
          [
            105.80324420861088,
            21.021497198626868
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-17",
        "diemCuoi": "8-18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.021750008417772,
        "to_latitude": 21.021497198626868,
        "to_longitude": 105.80324420861088,
        "from_longitude": 105.80317941550612,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 806,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80324420861088,
          21.02095883940357,
          105.80342483763128,
          21.021497198626868
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80324420861088,
            21.021497198626868
          ],
          [
            105.80342483763128,
            21.02095883940357
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-18",
        "diemCuoi": "8-20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.021497198626868,
        "to_latitude": 21.02095883940357,
        "to_longitude": 105.80342483763128,
        "from_longitude": 105.80324420861088,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 807,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80342483763128,
          21.02078733544025,
          105.80351737383432,
          21.02095883940357
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80342483763128,
            21.02095883940357
          ],
          [
            105.80351737383432,
            21.02078733544025
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-20",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.02095883940357,
        "to_latitude": 21.02078733544025,
        "to_longitude": 105.80351737383432,
        "from_longitude": 105.80342483763128,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 808,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80340874437586,
          21.020709720443058,
          105.80351737383432,
          21.02078733544025
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80351737383432,
            21.02078733544025
          ],
          [
            105.80340874437586,
            21.020709720443058
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "",
        "diemCuoi": "8-27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.02078733544025,
        "to_latitude": 21.020709720443058,
        "to_longitude": 105.80340874437586,
        "from_longitude": 105.80351737383432,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 809,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80340874437586,
          21.02066308883329,
          105.80342718456082,
          21.020709720443058
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80340874437586,
            21.020709720443058
          ],
          [
            105.80342718456082,
            21.02066308883329
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-27",
        "diemCuoi": "8-28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.020709720443058,
        "to_latitude": 21.02066308883329,
        "to_longitude": 105.80342718456082,
        "from_longitude": 105.80340874437586,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 810,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80339352422897,
          21.02061426646008,
          105.80342718456082,
          21.02066308883329
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80342718456082,
            21.02066308883329
          ],
          [
            105.80339352422897,
            21.02063753342439
          ],
          [
            105.80340170356929,
            21.02061426646008
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-28",
        "diemCuoi": "8-29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.02066308883329,
        "to_latitude": 21.02061426646008,
        "to_longitude": 105.80340170356929,
        "from_longitude": 105.80342718456082,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 811,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80340170356929,
          21.020579948049576,
          105.80349558088518,
          21.02061426646008
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80340170356929,
            21.02061426646008
          ],
          [
            105.80342705184371,
            21.020579948049576
          ],
          [
            105.80349558088518,
            21.020600496044153
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-29",
        "diemCuoi": "8-30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.02061426646008,
        "to_latitude": 21.020600496044153,
        "to_longitude": 105.80349558088518,
        "from_longitude": 105.80340170356929,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 812,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80351737383432,
          21.02078733544025,
          105.8036750113772,
          21.020844463860602
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80351737383432,
            21.02078733544025
          ],
          [
            105.8036750113772,
            21.020844463860602
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "",
        "diemCuoi": "8-21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.02078733544025,
        "to_latitude": 21.020844463860602,
        "to_longitude": 105.8036750113772,
        "from_longitude": 105.80351737383432,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 813,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8036750113772,
          21.020844463860602,
          105.80381314513879,
          21.020909560253852
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8036750113772,
            21.020844463860602
          ],
          [
            105.80381314513879,
            21.020909560253852
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-21",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.020844463860602,
        "to_latitude": 21.020909560253852,
        "to_longitude": 105.80381314513879,
        "from_longitude": 105.8036750113772,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 814,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80381314513879,
          21.020792417634073,
          105.80391247312006,
          21.020909560253852
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80381314513879,
            21.020909560253852
          ],
          [
            105.80391247312006,
            21.020792417634073
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "",
        "diemCuoi": "8-22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.020909560253852,
        "to_latitude": 21.020792417634073,
        "to_longitude": 105.80391247312006,
        "from_longitude": 105.80381314513879,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 815,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80391247312006,
          21.020592249101135,
          105.8040439351961,
          21.020792417634073
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80391247312006,
            21.020792417634073
          ],
          [
            105.8040439351961,
            21.020592249101135
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-22",
        "diemCuoi": "8-24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.020792417634073,
        "to_latitude": 21.020592249101135,
        "to_longitude": 105.8040439351961,
        "from_longitude": 105.80391247312006,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 816,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80402235744147,
          21.020601603989586,
          105.80403442105433,
          21.020606735659126
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80402235744147,
            21.020601603989586
          ],
          [
            105.80403442105433,
            21.020606735659126
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-23",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.020601603989586,
        "to_latitude": 21.020606735659126,
        "to_longitude": 105.80403442105433,
        "from_longitude": 105.80402235744147,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 817,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80391247312006,
          21.020792417634073,
          105.80397541877903,
          21.020932093615986
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80391247312006,
            21.020792417634073
          ],
          [
            105.80397541877903,
            21.020932093615986
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-22",
        "diemCuoi": "8-25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.020792417634073,
        "to_latitude": 21.020932093615986,
        "to_longitude": 105.80397541877903,
        "from_longitude": 105.80391247312006,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 818,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80391641017279,
          21.020932093615986,
          105.80397541877903,
          21.02104476036626
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80397541877903,
            21.020932093615986
          ],
          [
            105.80391641017279,
            21.02104476036626
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-25",
        "diemCuoi": "8-16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.020932093615986,
        "to_latitude": 21.02104476036626,
        "to_longitude": 105.80391641017279,
        "from_longitude": 105.80397541877903,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 819,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83167180252511,
          21.028811692472217,
          105.83169300873064,
          21.028837119392
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83169300873064,
            21.028837119392
          ],
          [
            105.83167180252511,
            21.028811692472217
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 4,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "Tủ điện",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.028837119392,
        "to_latitude": 21.028811692472217,
        "to_longitude": 105.83167180252511,
        "from_longitude": 105.83169300873064,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 820,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83138346504913,
          21.028811692472217,
          105.83167180252511,
          21.028868022880342
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83167180252511,
            21.028811692472217
          ],
          [
            105.83138346504913,
            21.028868022880342
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.028811692472217,
        "to_latitude": 21.028868022880342,
        "to_longitude": 105.83138346504913,
        "from_longitude": 105.83167180252511,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 821,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83109646868601,
          21.028868022880342,
          105.83138346504913,
          21.02892560505351
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83138346504913,
            21.028868022880342
          ],
          [
            105.83109646868601,
            21.02892560505351
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.028868022880342,
        "to_latitude": 21.02892560505351,
        "to_longitude": 105.83109646868601,
        "from_longitude": 105.83138346504913,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 822,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83081081342672,
          21.02892560505351,
          105.83109646868601,
          21.02897818006085
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83109646868601,
            21.02892560505351
          ],
          [
            105.83081081342672,
            21.02897818006085
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.02892560505351,
        "to_latitude": 21.02897818006085,
        "to_longitude": 105.83081081342672,
        "from_longitude": 105.83109646868601,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 823,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83052515816746,
          21.02897818006085,
          105.83081081342672,
          21.02903075505047
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83081081342672,
            21.02897818006085
          ],
          [
            105.83052515816746,
            21.02903075505047
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.02897818006085,
        "to_latitude": 21.02903075505047,
        "to_longitude": 105.83052515816746,
        "from_longitude": 105.83081081342672,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 824,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8302381618043,
          21.02903075505047,
          105.83052515816746,
          21.029084581806572
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83052515816746,
            21.02903075505047
          ],
          [
            105.8302381618043,
            21.029084581806572
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.02903075505047,
        "to_latitude": 21.029084581806572,
        "to_longitude": 105.8302381618043,
        "from_longitude": 105.83052515816746,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 825,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82995110556332,
          21.029084581806572,
          105.8302381618043,
          21.029137547937754
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8302381618043,
            21.029084581806572
          ],
          [
            105.82995110556332,
            21.029137547937754
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.029084581806572,
        "to_latitude": 21.029137547937754,
        "to_longitude": 105.82995110556332,
        "from_longitude": 105.8302381618043,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 826,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8296652587165,
          21.029137547937754,
          105.82995110556332,
          21.029195130006457
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82995110556332,
            21.029137547937754
          ],
          [
            105.8296652587165,
            21.029195130006457
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.029137547937754,
        "to_latitude": 21.029195130006457,
        "to_longitude": 105.8296652587165,
        "from_longitude": 105.82995110556332,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 827,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8293794118697,
          21.029195130006457,
          105.8296652587165,
          21.02925271205291
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8296652587165,
            21.029195130006457
          ],
          [
            105.8293794118697,
            21.02925271205291
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.029195130006457,
        "to_latitude": 21.02925271205291,
        "to_longitude": 105.8293794118697,
        "from_longitude": 105.8296652587165,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 828,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8290928585505,
          21.02925271205291,
          105.8293794118697,
          21.0293023922005
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8293794118697,
            21.02925271205291
          ],
          [
            105.8290928585505,
            21.0293023922005
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.02925271205291,
        "to_latitude": 21.0293023922005,
        "to_longitude": 105.8290928585505,
        "from_longitude": 105.8293794118697,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 829,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8288045210835,
          21.0293023922005,
          105.8290928585505,
          21.029356218857657
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8290928585505,
            21.0293023922005
          ],
          [
            105.8288045210835,
            21.029356218857657
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.0293023922005,
        "to_latitude": 21.029356218857657,
        "to_longitude": 105.8288045210835,
        "from_longitude": 105.8290928585505,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 830,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82851618361649,
          21.029356218857657,
          105.8288045210835,
          21.029410045496228
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8288045210835,
            21.029356218857657
          ],
          [
            105.82851618361649,
            21.029410045496228
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.029356218857657,
        "to_latitude": 21.029410045496228,
        "to_longitude": 105.82851618361649,
        "from_longitude": 105.8288045210835,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 831,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82823321056495,
          21.029410045496228,
          105.82851618361649,
          21.02946762745916
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82851618361649,
            21.029410045496228
          ],
          [
            105.82823321056495,
            21.02946762745916
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.029410045496228,
        "to_latitude": 21.02946762745916,
        "to_longitude": 105.82823321056495,
        "from_longitude": 105.82851618361649,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 832,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82794353199407,
          21.02946762745916,
          105.82823321056495,
          21.029527712962476
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82823321056495,
            21.02946762745916
          ],
          [
            105.82794353199407,
            21.029527712962476
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.02946762745916,
        "to_latitude": 21.029527712962476,
        "to_longitude": 105.82794353199407,
        "from_longitude": 105.82823321056495,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 833,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82766433078874,
          21.029527712962476,
          105.82794353199407,
          21.029598203864424
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82794353199407,
            21.029527712962476
          ],
          [
            105.82766433078874,
            21.029598203864424
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cát Linh",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.029527712962476,
        "to_latitude": 21.029598203864424,
        "to_longitude": 105.82766433078874,
        "from_longitude": 105.82794353199407,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 834,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82762409765465,
          21.029598203864424,
          105.82766433078874,
          21.02968582847226
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82766433078874,
            21.029598203864424
          ],
          [
            105.82762409765465,
            21.02968582847226
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.029598203864424,
        "to_latitude": 21.02968582847226,
        "to_longitude": 105.82762409765465,
        "from_longitude": 105.82766433078874,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 835,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82762409765465,
          21.02968582847226,
          105.82788427192894,
          21.02988247155969
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82762409765465,
            21.02968582847226
          ],
          [
            105.82788427192894,
            21.02988247155969
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.02968582847226,
        "to_latitude": 21.02988247155969,
        "to_longitude": 105.82788427192894,
        "from_longitude": 105.82762409765465,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 836,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82788427192894,
          21.02988247155969,
          105.82814444620323,
          21.03007911438768
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82788427192894,
            21.02988247155969
          ],
          [
            105.82814444620323,
            21.03007911438768
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.02988247155969,
        "to_latitude": 21.03007911438768,
        "to_longitude": 105.82814444620323,
        "from_longitude": 105.82788427192894,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 837,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82814444620323,
          21.03007911438768,
          105.82842071373132,
          21.030296695733195
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82814444620323,
            21.03007911438768
          ],
          [
            105.82842071373132,
            21.030296695733195
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.03007911438768,
        "to_latitude": 21.030296695733195,
        "to_longitude": 105.82842071373132,
        "from_longitude": 105.82814444620323,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 838,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82842071373132,
          21.030296695733195,
          105.82866479475182,
          21.030472399265204
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82842071373132,
            21.030296695733195
          ],
          [
            105.82866479475182,
            21.030472399265204
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.030296695733195,
        "to_latitude": 21.030472399265204,
        "to_longitude": 105.82866479475182,
        "from_longitude": 105.82842071373132,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 839,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82866479475182,
          21.030472399265204,
          105.82893838007705,
          21.030677234727882
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82866479475182,
            21.030472399265204
          ],
          [
            105.82893838007705,
            21.030677234727882
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.030472399265204,
        "to_latitude": 21.030677234727882,
        "to_longitude": 105.82893838007705,
        "from_longitude": 105.82866479475182,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 840,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82918514330039,
          21.03086568310484,
          105.82944531757467,
          21.031062324635403
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82918514330039,
            21.03086568310484
          ],
          [
            105.82944531757467,
            21.031062324635403
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.03086568310484,
        "to_latitude": 21.031062324635403,
        "to_longitude": 105.82944531757467,
        "from_longitude": 105.82918514330039,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 841,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82944531757467,
          21.031062324635403,
          105.82965209912521,
          21.031235132558393
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82944531757467,
            21.031062324635403
          ],
          [
            105.82965209912521,
            21.031235132558393
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.031062324635403,
        "to_latitude": 21.031235132558393,
        "to_longitude": 105.82965209912521,
        "from_longitude": 105.82944531757467,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 842,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82965209912521,
          21.031235132558393,
          105.82983180712478,
          21.031417890387857
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82965209912521,
            21.031235132558393
          ],
          [
            105.82983180712478,
            21.031417890387857
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.031235132558393,
        "to_latitude": 21.031417890387857,
        "to_longitude": 105.82983180712478,
        "from_longitude": 105.82965209912521,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 843,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82983180712478,
          21.031417890387857,
          105.82993104885615,
          21.031690774949876
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82983180712478,
            21.031417890387857
          ],
          [
            105.82993104885615,
            21.031690774949876
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.031417890387857,
        "to_latitude": 21.031690774949876,
        "to_longitude": 105.82993104885615,
        "from_longitude": 105.82983180712478,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 844,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82993104885615,
          21.031690774949876,
          105.83002333362073,
          21.032036652281537
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82993104885615,
            21.031690774949876
          ],
          [
            105.83002333362073,
            21.032036652281537
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.031690774949876,
        "to_latitude": 21.032036652281537,
        "to_longitude": 105.83002333362073,
        "from_longitude": 105.82993104885615,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 845,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82893838007705,
          21.030677234727882,
          105.82918514330039,
          21.03086568310484
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82918514330039,
            21.03086568310484
          ],
          [
            105.82893838007705,
            21.030677234727882
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.03086568310484,
        "to_latitude": 21.030677234727882,
        "to_longitude": 105.82893838007705,
        "from_longitude": 105.82918514330039,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 846,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83169300873064,
          21.028819594373893,
          105.83181504924579,
          21.02885589619664
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83169300873064,
            21.028837119392
          ],
          [
            105.83177615718863,
            21.028819594373893
          ],
          [
            105.83181504924579,
            21.02885589619664
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trịnh Hoài Đức",
        "diemDau": "Tủ điện",
        "diemCuoi": "28",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.028837119392,
        "to_latitude": 21.02885589619664,
        "to_longitude": 105.83181504924579,
        "from_longitude": 105.83169300873064,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 847,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83181504924579,
          21.02885589619664,
          105.83193344961543,
          21.029201925406937
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83181504924579,
            21.02885589619664
          ],
          [
            105.83193344961543,
            21.029201925406937
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trịnh Hoài Đức",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.02885589619664,
        "to_latitude": 21.029201925406937,
        "to_longitude": 105.83193344961543,
        "from_longitude": 105.83181504924579,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 848,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83193344961543,
          21.029201925406937,
          105.8320518499851,
          21.0295479538138
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83193344961543,
            21.029201925406937
          ],
          [
            105.8320518499851,
            21.0295479538138
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trịnh Hoài Đức",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.029201925406937,
        "to_latitude": 21.0295479538138,
        "to_longitude": 105.8320518499851,
        "from_longitude": 105.83193344961543,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 849,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8320518499851,
          21.0295479538138,
          105.83217025035474,
          21.02989398141727
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8320518499851,
            21.0295479538138
          ],
          [
            105.83217025035474,
            21.02989398141727
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trịnh Hoài Đức",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.0295479538138,
        "to_latitude": 21.02989398141727,
        "to_longitude": 105.83217025035474,
        "from_longitude": 105.8320518499851,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 850,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83217025035474,
          21.02989398141727,
          105.83228865072437,
          21.030240008217266
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83217025035474,
            21.02989398141727
          ],
          [
            105.83228865072437,
            21.030240008217266
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trịnh Hoài Đức",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.02989398141727,
        "to_latitude": 21.030240008217266,
        "to_longitude": 105.83228865072437,
        "from_longitude": 105.83217025035474,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 851,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83228865072437,
          21.030240008217266,
          105.83240705109404,
          21.03058603421383
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83228865072437,
            21.030240008217266
          ],
          [
            105.83240705109404,
            21.03058603421383
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trịnh Hoài Đức",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.030240008217266,
        "to_latitude": 21.03058603421383,
        "to_longitude": 105.83240705109404,
        "from_longitude": 105.83228865072437,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 852,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83240705109404,
          21.03058603421383,
          105.83252545146368,
          21.030932059406933
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83240705109404,
            21.03058603421383
          ],
          [
            105.83252545146368,
            21.030932059406933
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trịnh Hoài Đức",
        "diemDau": "33",
        "diemCuoi": "34",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.03058603421383,
        "to_latitude": 21.030932059406933,
        "to_longitude": 105.83252545146368,
        "from_longitude": 105.83240705109404,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 853,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83252545146368,
          21.030932059406933,
          105.83264385183332,
          21.031278083796554
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83252545146368,
            21.030932059406933
          ],
          [
            105.83264385183332,
            21.031278083796554
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trịnh Hoài Đức",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.030932059406933,
        "to_latitude": 21.031278083796554,
        "to_longitude": 105.83264385183332,
        "from_longitude": 105.83252545146368,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 854,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83134189080218,
          21.028811692472217,
          105.83180700256865,
          21.029047028259203
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83167180252511,
            21.028811692472217
          ],
          [
            105.83178420379387,
            21.028854644409677
          ],
          [
            105.83180700256865,
            21.02892098909147
          ],
          [
            105.83134189080218,
            21.029047028259203
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 75,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Trịnh Hoài Đức",
        "diemDau": "01",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.028811692472217,
        "to_latitude": 21.029047028259203,
        "to_longitude": 105.83134189080218,
        "from_longitude": 105.83167180252511,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 855,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83125337790179,
          21.028691520864815,
          105.83138346504913,
          21.028868022880342
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83138346504913,
            21.028868022880342
          ],
          [
            105.83134163930083,
            21.02871694780676
          ],
          [
            105.83125337790179,
            21.028691520864815
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 23 Cát Linh",
        "diemDau": "02",
        "diemCuoi": "2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.028868022880342,
        "to_latitude": 21.028691520864815,
        "to_longitude": 105.83125337790179,
        "from_longitude": 105.83138346504913,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 856,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83119705151223,
          21.02827843022307,
          105.83125337790179,
          21.028691520864815
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83125337790179,
            21.028691520864815
          ],
          [
            105.83119705151223,
            21.02827843022307
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 23 Cát Linh",
        "diemDau": "2-1",
        "diemCuoi": "2-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.028691520864815,
        "to_latitude": 21.02827843022307,
        "to_longitude": 105.83119705151223,
        "from_longitude": 105.83125337790179,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 857,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83112463186727,
          21.027910403049418,
          105.83119705151223,
          21.02827843022307
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83119705151223,
            21.02827843022307
          ],
          [
            105.83112463186727,
            21.027910403049418
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 23 Cát Linh",
        "diemDau": "2-2",
        "diemCuoi": "2-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.02827843022307,
        "to_latitude": 21.027910403049418,
        "to_longitude": 105.83112463186727,
        "from_longitude": 105.83119705151223,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 858,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83134163930083,
          21.02869652801724,
          105.83142772148585,
          21.02871694780676
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83134163930083,
            21.02871694780676
          ],
          [
            105.83142772148585,
            21.02869652801724
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 21 Cát Linh",
        "diemDau": "02",
        "diemCuoi": "2-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.02871694780676,
        "to_latitude": 21.02869652801724,
        "to_longitude": 105.83142772148585,
        "from_longitude": 105.83134163930083,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 859,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83133895709314,
          21.028238764071133,
          105.83142772148585,
          21.02869652801724
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83142772148585,
            21.02869652801724
          ],
          [
            105.83133895709314,
            21.028238764071133
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 55,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 21 Cát Linh",
        "diemDau": "2-4",
        "diemCuoi": "2-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.02869652801724,
        "to_latitude": 21.028238764071133,
        "to_longitude": 105.83133895709314,
        "from_longitude": 105.83142772148585,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 860,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83126142453398,
          21.02794294970726,
          105.83133895709314,
          21.028238764071133
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83133895709314,
            21.028238764071133
          ],
          [
            105.83126142453398,
            21.02794294970726
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 21 Cát Linh",
        "diemDau": "2-5",
        "diemCuoi": "2-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.028238764071133,
        "to_latitude": 21.02794294970726,
        "to_longitude": 105.83126142453398,
        "from_longitude": 105.83133895709314,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 861,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83062037657821,
          21.02854380979743,
          105.83081081342672,
          21.02897818006085
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83081081342672,
            21.02897818006085
          ],
          [
            105.83066840484321,
            21.02883461582024
          ],
          [
            105.83062037657821,
            21.02854380979743
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 58,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 25 B Cát Linh",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.02897818006085,
        "to_latitude": 21.02854380979743,
        "to_longitude": 105.83062037657821,
        "from_longitude": 105.83081081342672,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 862,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83058550785961,
          21.028210833462904,
          105.83062037657821,
          21.02854380979743
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83062037657821,
            21.02854380979743
          ],
          [
            105.83058550785961,
            21.028210833462904
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 25 B Cát Linh",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.02854380979743,
        "to_latitude": 21.028210833462904,
        "to_longitude": 105.83058550785961,
        "from_longitude": 105.83062037657821,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 863,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83054393362163,
          21.028014301969417,
          105.83058550785961,
          21.028210833462904
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83058550785961,
            21.028210833462904
          ],
          [
            105.83054393362163,
            21.028014301969417
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 25 B Cát Linh",
        "diemDau": "4-2",
        "diemCuoi": "4-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.028210833462904,
        "to_latitude": 21.028014301969417,
        "to_longitude": 105.83054393362163,
        "from_longitude": 105.83058550785961,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 864,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83054393362163,
          21.02794044611801,
          105.83075180482047,
          21.028014301969417
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83054393362163,
            21.028014301969417
          ],
          [
            105.83075180482047,
            21.02794044611801
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 25 B Cát Linh",
        "diemDau": "4-4",
        "diemCuoi": "4-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.028014301969417,
        "to_latitude": 21.02794044611801,
        "to_longitude": 105.83075180482047,
        "from_longitude": 105.83054393362163,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 865,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83037093114143,
          21.028014301969417,
          105.83054393362163,
          21.028030575287488
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83054393362163,
            21.028014301969417
          ],
          [
            105.83037093114143,
            21.028030575287488
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 25 B Cát Linh",
        "diemDau": "4-3",
        "diemCuoi": "4-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.028014301969417,
        "to_latitude": 21.028030575287488,
        "to_longitude": 105.83037093114143,
        "from_longitude": 105.83054393362163,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 866,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83081081342672,
          21.02897818006085,
          105.83089002235893,
          21.029134653190273
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83081081342672,
            21.02897818006085
          ],
          [
            105.83089002235893,
            21.028990776152913
          ],
          [
            105.8308885974782,
            21.029134653190273
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 20 Cát Linh",
        "diemDau": "04",
        "diemCuoi": "4-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.02897818006085,
        "to_latitude": 21.029134653190273,
        "to_longitude": 105.8308885974782,
        "from_longitude": 105.83081081342672,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 867,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83085104655181,
          21.029134653190273,
          105.8308885974782,
          21.02915718530741
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8308885974782,
            21.029134653190273
          ],
          [
            105.83085104655181,
            21.02915718530741
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 20 Cát Linh",
        "diemDau": "4-6",
        "diemCuoi": "4-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.029134653190273,
        "to_latitude": 21.02915718530741,
        "to_longitude": 105.83085104655181,
        "from_longitude": 105.8308885974782,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 868,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83085104655181,
          21.02915718530741,
          105.83088591527043,
          21.02932492429311
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83085104655181,
            21.02915718530741
          ],
          [
            105.83088591527043,
            21.02932492429311
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 20 Cát Linh",
        "diemDau": "4-7",
        "diemCuoi": "4-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.02915718530741,
        "to_latitude": 21.02932492429311,
        "to_longitude": 105.83088591527043,
        "from_longitude": 105.83085104655181,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 869,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83088591527043,
          21.02932492429311,
          105.8309771103786,
          21.02960281980884
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83088591527043,
            21.02932492429311
          ],
          [
            105.8309771103786,
            21.02960281980884
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 3,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 20 Cát Linh",
        "diemDau": "4-8",
        "diemCuoi": "4-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.02932492429311,
        "to_latitude": 21.02960281980884,
        "to_longitude": 105.8309771103786,
        "from_longitude": 105.83088591527043,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 870,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8309771103786,
          21.02960281980884,
          105.8310602588545,
          21.029700458650705
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8309771103786,
            21.02960281980884
          ],
          [
            105.83098926409929,
            21.029625430089013
          ],
          [
            105.83100803956248,
            21.029636696111325
          ],
          [
            105.83102681502564,
            21.02964921391225
          ],
          [
            105.83105229601718,
            21.029656724592144
          ],
          [
            105.8310602588545,
            21.029700458650705
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 20 Cát Linh",
        "diemDau": "4-9",
        "diemCuoi": "4-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.02960281980884,
        "to_latitude": 21.029700458650705,
        "to_longitude": 105.8310602588545,
        "from_longitude": 105.8309771103786,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 871,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83094760607545,
          21.029700458650705,
          105.8310602588545,
          21.029760544060146
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8310602588545,
            21.029700458650705
          ],
          [
            105.83094760607545,
            21.029760544060146
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 20 Cát Linh",
        "diemDau": "4-10",
        "diemCuoi": "4-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.029700458650705,
        "to_latitude": 21.029760544060146,
        "to_longitude": 105.83094760607545,
        "from_longitude": 105.8310602588545,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 872,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83052381705458,
          21.02903075505047,
          105.83060570820355,
          21.029374995595237
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83052515816746,
            21.02903075505047
          ],
          [
            105.83057888611712,
            21.02921860095385
          ],
          [
            105.83060570820355,
            21.029356297093297
          ],
          [
            105.83052381705458,
            21.029374995595237
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 49,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 30 Cát Linh",
        "diemDau": "05",
        "diemCuoi": "5-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.02903075505047,
        "to_latitude": 21.029374995595237,
        "to_longitude": 105.83052381705458,
        "from_longitude": 105.83052515816746,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 873,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83052515816746,
          21.02903075505047,
          105.83055332134873,
          21.029244810173715
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83052515816746,
            21.02903075505047
          ],
          [
            105.83055332134873,
            21.029244810173715
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 30 Cát Linh",
        "diemDau": "05",
        "diemCuoi": "5-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.02903075505047,
        "to_latitude": 21.029244810173715,
        "to_longitude": 105.83055332134873,
        "from_longitude": 105.83052515816746,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 874,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82994714211159,
          21.0289719211336,
          105.82995110556332,
          21.029137547937754
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82995110556332,
            21.029137547937754
          ],
          [
            105.82994714211159,
            21.0289719211336
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 27 Cát Linh",
        "diemDau": "07",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.029137547937754,
        "to_latitude": 21.0289719211336,
        "to_longitude": 105.82994714211159,
        "from_longitude": 105.82995110556332,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 875,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82991227339296,
          21.028526284745674,
          105.82994714211159,
          21.0289719211336
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82994714211159,
            21.0289719211336
          ],
          [
            105.82994588478459,
            21.02871788664681
          ],
          [
            105.82991227339296,
            21.028526284745674
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 53,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 27 Cát Linh",
        "diemDau": "7-1",
        "diemCuoi": "7-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.0289719211336,
        "to_latitude": 21.028526284745674,
        "to_longitude": 105.82991227339296,
        "from_longitude": 105.82994714211159,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 876,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82985594700345,
          21.028260905140183,
          105.82991227339296,
          21.028526284745674
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82991227339296,
            21.028526284745674
          ],
          [
            105.82985594700345,
            21.028260905140183
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 27 Cát Linh",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.028526284745674,
        "to_latitude": 21.028260905140183,
        "to_longitude": 105.82985594700345,
        "from_longitude": 105.82991227339296,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 877,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8296494169085,
          21.028088157784225,
          105.82985594700345,
          21.028260905140183
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82985594700345,
            21.028260905140183
          ],
          [
            105.8296494169085,
            21.028088157784225
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 27 Cát Linh",
        "diemDau": "5-3",
        "diemCuoi": "5-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.028260905140183,
        "to_latitude": 21.028088157784225,
        "to_longitude": 105.8296494169085,
        "from_longitude": 105.82985594700345,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 878,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82970574329802,
          21.02786033125523,
          105.82985594700345,
          21.028260905140183
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82985594700345,
            21.028260905140183
          ],
          [
            105.82976081236956,
            21.02808448063943
          ],
          [
            105.82970574329802,
            21.02786033125523
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 51,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 27 Cát Linh",
        "diemDau": "7-3",
        "diemCuoi": "7-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.028260905140183,
        "to_latitude": 21.02786033125523,
        "to_longitude": 105.82970574329802,
        "from_longitude": 105.82985594700345,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 879,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82903787325584,
          21.02763000078436,
          105.8296494169085,
          21.028088157784225
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8296494169085,
            21.028088157784225
          ],
          [
            105.8295636700017,
            21.02781409306616
          ],
          [
            105.82903787325584,
            21.02763000078436
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 96,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 27 Cát Linh",
        "diemDau": "7-5",
        "diemCuoi": "7-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.028088157784225,
        "to_latitude": 21.02763000078436,
        "to_longitude": 105.82903787325584,
        "from_longitude": 105.8296494169085,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 880,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82903787325584,
          21.02763000078436,
          105.82908078859768,
          21.027947956883985
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82903787325584,
            21.02763000078436
          ],
          [
            105.82908078859768,
            21.027947956883985
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 27 Cát Linh",
        "diemDau": "7-6",
        "diemCuoi": "7-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.02763000078436,
        "to_latitude": 21.027947956883985,
        "to_longitude": 105.82908078859768,
        "from_longitude": 105.82903787325584,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 881,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82893838007705,
          21.030569191172518,
          105.8290941996454,
          21.030694446586082
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82893838007705,
            21.030677234727882
          ],
          [
            105.82898431285095,
            21.030694446586082
          ],
          [
            105.8290941996454,
            21.030569191172518
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 59 Giảng Võ",
        "diemDau": "21",
        "diemCuoi": "21-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.030677234727882,
        "to_latitude": 21.030569191172518,
        "to_longitude": 105.8290941996454,
        "from_longitude": 105.82893838007705,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 882,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8290941996454,
          21.030445265661523,
          105.82921221684443,
          21.030569191172518
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8290941996454,
            21.030569191172518
          ],
          [
            105.82921221684443,
            21.030445265661523
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 59 Giảng Võ",
        "diemDau": "21-1",
        "diemCuoi": "21-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.030569191172518,
        "to_latitude": 21.030445265661523,
        "to_longitude": 105.82921221684443,
        "from_longitude": 105.8290941996454,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 883,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82921221684443,
          21.030288793909012,
          105.82935437392213,
          21.030445265661523
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82921221684443,
            21.030445265661523
          ],
          [
            105.82935437392213,
            21.030288793909012
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 59 Giảng Võ",
        "diemDau": "21-2",
        "diemCuoi": "21-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.030445265661523,
        "to_latitude": 21.030288793909012,
        "to_longitude": 105.82935437392213,
        "from_longitude": 105.82921221684443,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 884,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82954757674626,
          21.031027339136827,
          105.82965209912521,
          21.031235132558393
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82965209912521,
            21.031235132558393
          ],
          [
            105.82954757674626,
            21.03110878229986
          ],
          [
            105.8296091837744,
            21.031027339136827
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Giảng Võ",
        "diemDau": "24",
        "diemCuoi": "24-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.031235132558393,
        "to_latitude": 21.031027339136827,
        "to_longitude": 105.8296091837744,
        "from_longitude": 105.82965209912521,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 885,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8296091837744,
          21.0309009887021,
          105.82978897555981,
          21.031027339136827
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8296091837744,
            21.031027339136827
          ],
          [
            105.82978897555981,
            21.0309009887021
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Giảng Võ",
        "diemDau": "24-1",
        "diemCuoi": "24-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.031027339136827,
        "to_latitude": 21.0309009887021,
        "to_longitude": 105.82978897555981,
        "from_longitude": 105.8296091837744,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 886,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82978897555981,
          21.0309009887021,
          105.82984253596473,
          21.03101732498929
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82978897555981,
            21.0309009887021
          ],
          [
            105.82984253596473,
            21.03101732498929
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Giảng Võ",
        "diemDau": "24-2",
        "diemCuoi": "24-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.0309009887021,
        "to_latitude": 21.03101732498929,
        "to_longitude": 105.82984253596473,
        "from_longitude": 105.82978897555981,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 887,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82984253596473,
          21.03101732498929,
          105.82990422676974,
          21.031187565415195
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82984253596473,
            21.03101732498929
          ],
          [
            105.82990422676974,
            21.031187565415195
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Giảng Võ",
        "diemDau": "24-3",
        "diemCuoi": "24-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.03101732498929,
        "to_latitude": 21.031187565415195,
        "to_longitude": 105.82990422676974,
        "from_longitude": 105.82984253596473,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 888,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82940494070033,
          21.030897155159554,
          105.82949384878759,
          21.031062324635403
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82944531757467,
            21.031062324635403
          ],
          [
            105.82940494070033,
            21.031011323201508
          ],
          [
            105.82949384878759,
            21.030897155159554
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Giảng Võ",
        "diemDau": "23",
        "diemCuoi": "23-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.031062324635403,
        "to_latitude": 21.030897155159554,
        "to_longitude": 105.82949384878759,
        "from_longitude": 105.82944531757467,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 889,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82894494185699,
          21.030804781238437,
          105.82918514330039,
          21.03086568310484
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82918514330039,
            21.03086568310484
          ],
          [
            105.82894494185699,
            21.030804781238437
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 26,34,36,56 Giảng Võ",
        "diemDau": "22",
        "diemCuoi": "22-0",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.03086568310484,
        "to_latitude": 21.030804781238437,
        "to_longitude": 105.82894494185699,
        "from_longitude": 105.82918514330039,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 890,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82886889408726,
          21.030804781238427,
          105.82894494185699,
          21.030912176393162
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82894494185699,
            21.030804781238427
          ],
          [
            105.82886889408726,
            21.030912176393162
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 26,34,36,56 Giảng Võ",
        "diemDau": "22-0",
        "diemCuoi": "22-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.030804781238427,
        "to_latitude": 21.030912176393162,
        "to_longitude": 105.82886889408726,
        "from_longitude": 105.82894494185699,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 891,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82894494185699,
          21.030804781238427,
          105.82899590383103,
          21.030859602067284
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82894494185699,
            21.030804781238427
          ],
          [
            105.82899590383103,
            21.030839830803977
          ],
          [
            105.8289788646586,
            21.030859602067284
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 26,34,36,56 Giảng Võ",
        "diemDau": "22-0",
        "diemCuoi": "22-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.030804781238427,
        "to_latitude": 21.030859602067284,
        "to_longitude": 105.8289788646586,
        "from_longitude": 105.82894494185699,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 892,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82874014805276,
          21.03069212186503,
          105.82894494185699,
          21.030804781238427
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82894494185699,
            21.030804781238427
          ],
          [
            105.82879205594382,
            21.03069212186503
          ],
          [
            105.82874014805276,
            21.03076697106776
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 26,34,36,56 Giảng Võ",
        "diemDau": "22-0",
        "diemCuoi": "22-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.030804781238427,
        "to_latitude": 21.03076697106776,
        "to_longitude": 105.82874014805276,
        "from_longitude": 105.82894494185699,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 893,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82866772841679,
          21.03067334529424,
          105.82879205594382,
          21.030792006478666
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82879205594382,
            21.03069212186503
          ],
          [
            105.82876121053684,
            21.03067334529424
          ],
          [
            105.82866772841679,
            21.030792006478666
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐ",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 26,34,36,56 Giảng Võ",
        "diemDau": "22-3",
        "diemCuoi": "22-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Đẫy",
        "from_latitude": 21.03069212186503,
        "to_latitude": 21.030792006478666,
        "to_longitude": 105.82866772841679,
        "from_longitude": 105.82879205594382,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 894,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83867063173784,
          21.028242385323356,
          105.8386972622675,
          21.02824971724836
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83867063173784,
            21.028242385323356
          ],
          [
            105.8386972622675,
            21.02824971724836
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 3,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Khuyến",
        "diemDau": "Tủ điện",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.028242385323356,
        "to_latitude": 21.02824971724836,
        "to_longitude": 105.8386972622675,
        "from_longitude": 105.83867063173784,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 895,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8386972622675,
          21.028179616890746,
          105.83897084759194,
          21.02824971724836
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8386972622675,
            21.02824971724836
          ],
          [
            105.83897084759194,
            21.028179616890746
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Khuyến",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.02824971724836,
        "to_latitude": 21.028179616890746,
        "to_longitude": 105.83897084759194,
        "from_longitude": 105.8386972622675,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 896,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83897084759194,
          21.02808448063943,
          105.83924577401125,
          21.028179616890746
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83897084759194,
            21.028179616890746
          ],
          [
            105.83924577401125,
            21.02808448063943
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Khuyến",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.028179616890746,
        "to_latitude": 21.02808448063943,
        "to_longitude": 105.83924577401125,
        "from_longitude": 105.83897084759194,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 897,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83924577401125,
          21.02801312841003,
          105.83949991331609,
          21.02808448063943
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83924577401125,
            21.02808448063943
          ],
          [
            105.83949991331609,
            21.02801312841003
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Khuyến",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.02808448063943,
        "to_latitude": 21.02801312841003,
        "to_longitude": 105.83949991331609,
        "from_longitude": 105.83924577401125,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 898,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83949991331609,
          21.027934265380484,
          105.83976746366561,
          21.02801312841003
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83949991331609,
            21.02801312841003
          ],
          [
            105.83976746366561,
            21.027934265380484
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Khuyến",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.02801312841003,
        "to_latitude": 21.027934265380484,
        "to_longitude": 105.83976746366561,
        "from_longitude": 105.83949991331609,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 899,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83976746366561,
          21.027855402309182,
          105.84003501401513,
          21.027934265380484
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83976746366561,
            21.027934265380484
          ],
          [
            105.84003501401513,
            21.027855402309182
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Khuyến",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027934265380484,
        "to_latitude": 21.027855402309182,
        "to_longitude": 105.84003501401513,
        "from_longitude": 105.83976746366561,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 900,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84003501401513,
          21.027776539196157,
          105.84030256436463,
          21.027855402309182
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84003501401513,
            21.027855402309182
          ],
          [
            105.84030256436463,
            21.027776539196157
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Khuyến",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027855402309182,
        "to_latitude": 21.027776539196157,
        "to_longitude": 105.84030256436463,
        "from_longitude": 105.84003501401513,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 901,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84030256436463,
          21.027776539196157,
          105.84052403816791,
          21.02782053086831
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84030256436463,
            21.027776539196157
          ],
          [
            105.84040348248709,
            21.027798132669457
          ],
          [
            105.84052403816791,
            21.02782053086831
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Khuyến",
        "diemDau": "07",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027776539196157,
        "to_latitude": 21.02782053086831,
        "to_longitude": 105.84052403816791,
        "from_longitude": 105.84030256436463,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 902,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84052403816791,
          21.027785480592687,
          105.84074934372605,
          21.02782053086831
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84052403816791,
            21.02782053086831
          ],
          [
            105.84074934372605,
            21.027785480592687
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Khuyến",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.02782053086831,
        "to_latitude": 21.027785480592687,
        "to_longitude": 105.84074934372605,
        "from_longitude": 105.84052403816791,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 903,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84074934372605,
          21.027751682105883,
          105.84104304559057,
          21.027785480592687
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84074934372605,
            21.027785480592687
          ],
          [
            105.84104304559057,
            21.027751682105883
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Khuyến",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027785480592687,
        "to_latitude": 21.027751682105883,
        "to_longitude": 105.84104304559057,
        "from_longitude": 105.84074934372605,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 904,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84030256436463,
          21.02760021471378,
          105.84058036455744,
          21.027776539196157
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84030256436463,
            21.027776539196157
          ],
          [
            105.84043969230959,
            21.027685471023187
          ],
          [
            105.84058036455744,
            21.02760021471378
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Khuyến",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027776539196157,
        "to_latitude": 21.02760021471378,
        "to_longitude": 105.84058036455744,
        "from_longitude": 105.84030256436463,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 905,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8386972622675,
          21.02824971724836,
          105.83886945045185,
          21.028649530071565
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8386972622675,
            21.02824971724836
          ],
          [
            105.83878879265384,
            21.02832826716744
          ],
          [
            105.83886945045185,
            21.028649530071565
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 54,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102 Nguyễn Khuyến",
        "diemDau": "01",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.02824971724836,
        "to_latitude": 21.028649530071565,
        "to_longitude": 105.83886945045185,
        "from_longitude": 105.8386972622675,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 906,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84104304559057,
          21.027748686741973,
          105.84114530479891,
          21.027897203318137
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84104304559057,
            21.027751682105883
          ],
          [
            105.84106867031252,
            21.027748686741973
          ],
          [
            105.84114530479891,
            21.027897203318137
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8 Nguyễn Khuyến",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027751682105883,
        "to_latitude": 21.027897203318137,
        "to_longitude": 105.84114530479891,
        "from_longitude": 105.84104304559057,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 907,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84074934372605,
          21.027785480592687,
          105.84080332315013,
          21.028012368391618
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84074934372605,
            21.027785480592687
          ],
          [
            105.84076960400556,
            21.027797506778647
          ],
          [
            105.84080332315013,
            21.028012368391618
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14 Nguyễn Khuyến",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027785480592687,
        "to_latitude": 21.028012368391618,
        "to_longitude": 105.84080332315013,
        "from_longitude": 105.84074934372605,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 908,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84070005810712,
          21.028012368391618,
          105.84080332315013,
          21.028295273520634
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84080332315013,
            21.028012368391618
          ],
          [
            105.8407454641269,
            21.028155519889584
          ],
          [
            105.84070005810712,
            21.028295273520634
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14 Nguyễn Khuyến",
        "diemDau": "10-1",
        "diemCuoi": "10-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.028012368391618,
        "to_latitude": 21.028295273520634,
        "to_longitude": 105.84070005810712,
        "from_longitude": 105.84080332315013,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 909,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84070005810712,
          21.028295273520634,
          105.84074297344897,
          21.028440481254318
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84070005810712,
            21.028295273520634
          ],
          [
            105.84074297344897,
            21.028440481254318
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14 Nguyễn Khuyến",
        "diemDau": "10-2",
        "diemCuoi": "10-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.028295273520634,
        "to_latitude": 21.028440481254318,
        "to_longitude": 105.84074297344897,
        "from_longitude": 105.84070005810712,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 910,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84024106509841,
          21.027471279541384,
          105.84033642726658,
          21.027776539196157
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84030256436463,
            21.027776539196157
          ],
          [
            105.84033642726658,
            21.027734291080584
          ],
          [
            105.84028412418867,
            21.027599097036777
          ],
          [
            105.84024106509841,
            21.027471279541384
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "07",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027776539196157,
        "to_latitude": 21.027471279541384,
        "to_longitude": 105.84024106509841,
        "from_longitude": 105.84030256436463,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 911,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84005331046666,
          21.02722592686485,
          105.84024106509841,
          21.027471279541384
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84024106509841,
            21.027471279541384
          ],
          [
            105.84009905176464,
            21.02735374457062
          ],
          [
            105.84005331046666,
            21.02722592686485
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027471279541384,
        "to_latitude": 21.02722592686485,
        "to_longitude": 105.84005331046666,
        "from_longitude": 105.84024106509841,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 912,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83996211535852,
          21.027005609832166,
          105.84005331046666,
          21.02722592686485
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84005331046666,
            21.02722592686485
          ],
          [
            105.84000517445774,
            21.027110895305494
          ],
          [
            105.83996211535852,
            21.027005609832166
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.02722592686485,
        "to_latitude": 21.027005609832166,
        "to_longitude": 105.83996211535852,
        "from_longitude": 105.84005331046666,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 913,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83986555583488,
          21.026772774431553,
          105.83996211535852,
          21.027005609832166
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83996211535852,
            21.027005609832166
          ],
          [
            105.8399139793496,
            21.026889326296835
          ],
          [
            105.83986555583488,
            21.026772774431553
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027005609832166,
        "to_latitude": 21.026772774431553,
        "to_longitude": 105.83986555583488,
        "from_longitude": 105.83996211535852,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 914,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83976094968801,
          21.026529924217364,
          105.83986555583488,
          21.026772774431553
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83986555583488,
            21.026772774431553
          ],
          [
            105.83980937319376,
            21.026640217246502
          ],
          [
            105.83976094968801,
            21.026529924217364
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.026772774431553,
        "to_latitude": 21.026529924217364,
        "to_longitude": 105.83976094968801,
        "from_longitude": 105.83986555583488,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 915,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83969389444955,
          21.02640474354012,
          105.83976094968801,
          21.026529924217364
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83976094968801,
            21.026529924217364
          ],
          [
            105.83972622471781,
            21.026464964398965
          ],
          [
            105.83969389444955,
            21.02640474354012
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Như Đổ",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.026529924217364,
        "to_latitude": 21.02640474354012,
        "to_longitude": 105.83969389444955,
        "from_longitude": 105.83976094968801,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 916,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8391990268876,
          21.02640474354012,
          105.83969389444955,
          21.026529924217364
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83969389444955,
            21.02640474354012
          ],
          [
            105.83943788724181,
            21.02646997162617
          ],
          [
            105.8391990268876,
            21.026529924217364
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 57,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Như Đổ",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.02640474354012,
        "to_latitude": 21.026529924217364,
        "to_longitude": 105.8391990268876,
        "from_longitude": 105.83969389444955,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 917,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83855697310617,
          21.026615359969227,
          105.83870415932563,
          21.026655104789437
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83870415932563,
            21.026655104789437
          ],
          [
            105.83864663558961,
            21.026651483494465
          ],
          [
            105.83855697310617,
            21.026615359969227
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngô Sỹ Liên",
        "diemDau": "19",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.026655104789437,
        "to_latitude": 21.026615359969227,
        "to_longitude": 105.83855697310617,
        "from_longitude": 105.83870415932563,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 918,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.838404087193,
          21.026302408258836,
          105.83855697310617,
          21.026615359969227
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83855697310617,
            21.026615359969227
          ],
          [
            105.83848570304426,
            21.026467468013
          ],
          [
            105.838404087193,
            21.026302408258836
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngô Sỹ Liên",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.026615359969227,
        "to_latitude": 21.026302408258836,
        "to_longitude": 105.838404087193,
        "from_longitude": 105.83855697310617,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 919,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8384387643908,
          21.026655104789437,
          105.83870415932563,
          21.027019692606437
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83870415932563,
            21.026655104789437
          ],
          [
            105.8384387643908,
            21.02673660622527
          ],
          [
            105.83856636083325,
            21.027019692606437
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 67,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngô Sỹ Liên",
        "diemDau": "19",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.026655104789437,
        "to_latitude": 21.027019692606437,
        "to_longitude": 105.83856636083325,
        "from_longitude": 105.83870415932563,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 920,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83856636083325,
          21.027019692606437,
          105.83876484430499,
          21.02740775076252
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83856636083325,
            21.027019692606437
          ],
          [
            105.83866809326055,
            21.027203528575757
          ],
          [
            105.83876484430499,
            21.02740775076252
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 51,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngô Sỹ Liên",
        "diemDau": "25",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027019692606437,
        "to_latitude": 21.02740775076252,
        "to_longitude": 105.83876484430499,
        "from_longitude": 105.83856636083325,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 921,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83876484430499,
          21.02740775076252,
          105.83892175352977,
          21.027681894463402
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83876484430499,
            21.02740775076252
          ],
          [
            105.8388341058076,
            21.0275287609481
          ],
          [
            105.83892175352977,
            21.027681894463402
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngô Sỹ Liên",
        "diemDau": "24",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.02740775076252,
        "to_latitude": 21.027681894463402,
        "to_longitude": 105.83892175352977,
        "from_longitude": 105.83876484430499,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 922,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83870415932563,
          21.026529924217364,
          105.8391990268876,
          21.026655104789437
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8391990268876,
            21.026529924217364
          ],
          [
            105.83894804877222,
            21.026598594717225
          ],
          [
            105.83870415932563,
            21.026655104789437
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 57,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Như Đổ",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.026529924217364,
        "to_latitude": 21.026655104789437,
        "to_longitude": 105.83870415932563,
        "from_longitude": 105.8391990268876,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 923,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83902904189334,
          21.02759802406916,
          105.83935224807895,
          21.027681894463402
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83902904189334,
            21.027681894463402
          ],
          [
            105.83918307738956,
            21.027641658137664
          ],
          [
            105.83935224807895,
            21.02759802406916
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Ngô Sỹ Liên",
        "diemDau": "22",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027681894463402,
        "to_latitude": 21.02759802406916,
        "to_longitude": 105.83935224807895,
        "from_longitude": 105.83902904189334,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 924,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83892175352977,
          21.027681894463402,
          105.83902904189334,
          21.027681894463402
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83892175352977,
            21.027681894463402
          ],
          [
            105.83902904189334,
            21.027681894463402
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Ngô Sỹ Liên",
        "diemDau": "23",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027681894463402,
        "to_latitude": 21.027681894463402,
        "to_longitude": 105.83902904189334,
        "from_longitude": 105.83892175352977,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 925,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8398310223855,
          21.02738128415361,
          105.84024106509841,
          21.027471592491246
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84024106509841,
            21.027471279541384
          ],
          [
            105.84008832293367,
            21.02738128415361
          ],
          [
            105.8398310223855,
            21.027471592491246
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Ngô Sỹ Liên",
        "diemDau": "12",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027471279541384,
        "to_latitude": 21.027471592491246,
        "to_longitude": 105.8398310223855,
        "from_longitude": 105.84024106509841,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 926,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84005498683975,
          21.02740775076252,
          105.84024106509841,
          21.027471279541384
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84024106509841,
            21.027471279541384
          ],
          [
            105.84013257937939,
            21.027432607907635
          ],
          [
            105.84005498683975,
            21.02740775076252
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Ngô Sỹ Liên",
        "diemDau": "12",
        "diemCuoi": "12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027471279541384,
        "to_latitude": 21.02740775076252,
        "to_longitude": 105.84005498683975,
        "from_longitude": 105.84024106509841,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 927,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8386101242486,
          21.027105255234645,
          105.83878764307975,
          21.027146124568514
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83878764307975,
            21.027146124568514
          ],
          [
            105.8386101242486,
            21.027105255234645
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Y Miếu",
        "diemDau": "25",
        "diemCuoi": "28",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027146124568514,
        "to_latitude": 21.027105255234645,
        "to_longitude": 105.8386101242486,
        "from_longitude": 105.83878764307975,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 928,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83878764307975,
          21.027083534501394,
          105.83899417317474,
          21.027146124568514
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83878764307975,
            21.027146124568514
          ],
          [
            105.83899417317474,
            21.027083534501394
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Y Miếu",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027146124568514,
        "to_latitude": 21.027083534501394,
        "to_longitude": 105.83899417317474,
        "from_longitude": 105.83878764307975,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 929,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83899417317474,
          21.027018440804046,
          105.83919533884522,
          21.027083534501394
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83899417317474,
            21.027083534501394
          ],
          [
            105.83919533884522,
            21.027018440804046
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Y Miếu",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027083534501394,
        "to_latitude": 21.027018440804046,
        "to_longitude": 105.83919533884522,
        "from_longitude": 105.83899417317474,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 930,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83919533884522,
          21.027018440804046,
          105.83932542598357,
          21.027338901808584
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83919533884522,
            21.027018440804046
          ],
          [
            105.83932542598357,
            21.027338901808584
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Y Miếu",
        "diemDau": "30",
        "diemCuoi": "32",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027018440804046,
        "to_latitude": 21.027338901808584,
        "to_longitude": 105.83932542598357,
        "from_longitude": 105.83919533884522,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 931,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83932542598357,
          21.027338901808584,
          105.83939382231692,
          21.027537937787855
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83932542598357,
            21.027338901808584
          ],
          [
            105.83939382231692,
            21.027537937787855
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Y Miếu",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027338901808584,
        "to_latitude": 21.027537937787855,
        "to_longitude": 105.83939382231692,
        "from_longitude": 105.83932542598357,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 932,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83939382231692,
          21.027482858675526,
          105.83958828246807,
          21.027537937787855
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83939382231692,
            21.027537937787855
          ],
          [
            105.8394955547442,
            21.02751147120207
          ],
          [
            105.83958828246807,
            21.027482858675526
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Y Miếu",
        "diemDau": "33",
        "diemCuoi": "34",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027537937787855,
        "to_latitude": 21.027482858675526,
        "to_longitude": 105.83958828246807,
        "from_longitude": 105.83939382231692,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 933,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83948569497558,
          21.027199432032422,
          105.83958828246807,
          21.027482858675526
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83958828246807,
            21.027482858675526
          ],
          [
            105.83948569497558,
            21.027199432032422
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Y Miếu",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027482858675526,
        "to_latitude": 21.027199432032422,
        "to_longitude": 105.83948569497558,
        "from_longitude": 105.83958828246807,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 934,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83938692527676,
          21.026884319086207,
          105.83948569497558,
          21.027199432032422
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83948569497558,
            21.027199432032422
          ],
          [
            105.83938692527676,
            21.026884319086207
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Y Miếu",
        "diemDau": "35",
        "diemCuoi": "36",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027199432032422,
        "to_latitude": 21.026884319086207,
        "to_longitude": 105.83938692527676,
        "from_longitude": 105.83948569497558,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 935,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83917790449489,
          21.02679937526809,
          105.83922599274037,
          21.027018440804046
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83919533884522,
            21.027018440804046
          ],
          [
            105.83922599274037,
            21.027012002964742
          ],
          [
            105.83917790449489,
            21.02679937526809
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Y Miếu",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027018440804046,
        "to_latitude": 21.02679937526809,
        "to_longitude": 105.83917790449489,
        "from_longitude": 105.83919533884522,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 936,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83831825650034,
          21.026475157684466,
          105.83849699816341,
          21.02649259359688
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83849699816341,
            21.02649259359688
          ],
          [
            105.83831825650034,
            21.026475157684466
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Phổ Giác",
        "diemDau": "27",
        "diemCuoi": "27-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.02649259359688,
        "to_latitude": 21.026475157684466,
        "to_longitude": 105.83831825650034,
        "from_longitude": 105.83849699816341,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 937,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83809697425384,
          21.026475157684466,
          105.83831825650034,
          21.026590323856716
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83831825650034,
            21.026475157684466
          ],
          [
            105.83809697425384,
            21.026590323856716
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Phổ Giác",
        "diemDau": "27-1",
        "diemCuoi": "27-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.026475157684466,
        "to_latitude": 21.026590323856716,
        "to_longitude": 105.83809697425384,
        "from_longitude": 105.83831825650034,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 938,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83785691655314,
          21.026590323856716,
          105.83809697425384,
          21.026697979111376
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83809697425384,
            21.026590323856716
          ],
          [
            105.83785691655314,
            21.026697979111376
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Phổ Giác",
        "diemDau": "27-2",
        "diemCuoi": "27-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.026590323856716,
        "to_latitude": 21.026697979111376,
        "to_longitude": 105.83785691655314,
        "from_longitude": 105.83809697425384,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 939,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8376892784884,
          21.026697979111376,
          105.83785691655314,
          21.026803130680747
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83785691655314,
            21.026697979111376
          ],
          [
            105.8376892784884,
            21.026803130680747
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Phổ Giác",
        "diemDau": "27-3",
        "diemCuoi": "27-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.026697979111376,
        "to_latitude": 21.026803130680747,
        "to_longitude": 105.8376892784884,
        "from_longitude": 105.83785691655314,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 940,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83772932011065,
          21.026488748728216,
          105.83785691655314,
          21.026697979111376
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83785691655314,
            21.026697979111376
          ],
          [
            105.83772932011065,
            21.026488748728216
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Phổ Giác",
        "diemDau": "27-3",
        "diemCuoi": "27-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.026697979111376,
        "to_latitude": 21.026488748728216,
        "to_longitude": 105.83772932011065,
        "from_longitude": 105.83785691655314,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 941,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83759540117252,
          21.02667294301273,
          105.8376892784884,
          21.026803130680747
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8376892784884,
            21.026803130680747
          ],
          [
            105.83759540117252,
            21.02667294301273
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Phổ Giác",
        "diemDau": "27-4",
        "diemCuoi": "27-4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.026803130680747,
        "to_latitude": 21.02667294301273,
        "to_longitude": 105.83759540117252,
        "from_longitude": 105.8376892784884,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 942,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8382603974951,
          21.026475157684466,
          105.83836117184218,
          21.026734281447528
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83831825650034,
            21.026475157684466
          ],
          [
            105.8382603974951,
            21.02655634626859
          ],
          [
            105.83836117184218,
            21.026734281447528
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Phổ Giác",
        "diemDau": "27-1",
        "diemCuoi": "27-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.026475157684466,
        "to_latitude": 21.026734281447528,
        "to_longitude": 105.83836117184218,
        "from_longitude": 105.83831825650034,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 943,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83812379634924,
          21.026475157684466,
          105.83831825650034,
          21.02673803686094
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83831825650034,
            21.026475157684466
          ],
          [
            105.83822821098423,
            21.02655634626859
          ],
          [
            105.83827917295828,
            21.02666650515849
          ],
          [
            105.83812379634924,
            21.02673803686094
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Phổ Giác",
        "diemDau": "27-1",
        "diemCuoi": "27-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.026475157684466,
        "to_latitude": 21.02673803686094,
        "to_longitude": 105.83812379634924,
        "from_longitude": 105.83831825650034,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 944,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83819755709807,
          21.026405056492234,
          105.83831825650034,
          21.026475157684466
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83831825650034,
            21.026475157684466
          ],
          [
            105.83824966865515,
            21.026471223434836
          ],
          [
            105.83819755709807,
            21.026405056492234
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Phổ Giác",
        "diemDau": "27-1",
        "diemCuoi": "27-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.026475157684466,
        "to_latitude": 21.026405056492234,
        "to_longitude": 105.83819755709807,
        "from_longitude": 105.83831825650034,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 945,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83844297922325,
          21.027019692606437,
          105.83856636083325,
          21.02707477188992
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83856636083325,
            21.027019692606437
          ],
          [
            105.83855745214179,
            21.027041420312905
          ],
          [
            105.83844297922325,
            21.02707477188992
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Ngô Sỹ Liên",
        "diemDau": "25",
        "diemCuoi": "25-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027019692606437,
        "to_latitude": 21.02707477188992,
        "to_longitude": 105.83844297922325,
        "from_longitude": 105.83856636083325,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 946,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83834373748287,
          21.027002167375485,
          105.83844297922325,
          21.02707477188992
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83844297922325,
            21.02707477188992
          ],
          [
            105.83839786070028,
            21.02702890229296
          ],
          [
            105.83834373748287,
            21.027002167375485
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Ngô Sỹ Liên",
        "diemDau": "25-1",
        "diemCuoi": "25-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.02707477188992,
        "to_latitude": 21.027002167375485,
        "to_longitude": 105.83834373748287,
        "from_longitude": 105.83844297922325,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 947,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8383247704899,
          21.026891204006642,
          105.83834373748287,
          21.027002167375485
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83834373748287,
            21.027002167375485
          ],
          [
            105.8383247704899,
            21.026891204006642
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Ngô Sỹ Liên",
        "diemDau": "25-2",
        "diemCuoi": "25-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027002167375485,
        "to_latitude": 21.026891204006642,
        "to_longitude": 105.8383247704899,
        "from_longitude": 105.83834373748287,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 948,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83807800726085,
          21.027002167375485,
          105.83834373748287,
          21.02708648518005
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83834373748287,
            21.027002167375485
          ],
          [
            105.83817255514215,
            21.027047679322912
          ],
          [
            105.83807800726085,
            21.02708648518005
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Ngô Sỹ Liên",
        "diemDau": "25-2",
        "diemCuoi": "25-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027002167375485,
        "to_latitude": 21.02708648518005,
        "to_longitude": 105.83807800726085,
        "from_longitude": 105.83834373748287,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 949,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83785270170273,
          21.02708648518005,
          105.83807800726085,
          21.02715408244935
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83807800726085,
            21.02708648518005
          ],
          [
            105.83785270170273,
            21.02715408244935
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Ngô Sỹ Liên",
        "diemDau": "24-4",
        "diemCuoi": "24-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.02708648518005,
        "to_latitude": 21.02715408244935,
        "to_longitude": 105.83785270170273,
        "from_longitude": 105.83807800726085,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 950,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83761130288917,
          21.02715408244935,
          105.83785270170273,
          21.027219176087453
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83785270170273,
            21.02715408244935
          ],
          [
            105.83761130288917,
            21.027219176087453
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Ngô Sỹ Liên",
        "diemDau": "24-5",
        "diemCuoi": "24-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.02715408244935,
        "to_latitude": 21.027219176087453,
        "to_longitude": 105.83761130288917,
        "from_longitude": 105.83785270170273,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 951,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83851386624349,
          21.02740775076252,
          105.83876484430499,
          21.027470161864304
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83876484430499,
            21.02740775076252
          ],
          [
            105.83851386624349,
            21.027470161864304
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 38 Ngô Sỹ Liên",
        "diemDau": "24",
        "diemCuoi": "24-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.02740775076252,
        "to_latitude": 21.027470161864304,
        "to_longitude": 105.83851386624349,
        "from_longitude": 105.83876484430499,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 952,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83826039749063,
          21.027470161864304,
          105.83851386624349,
          21.027550902835745
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83851386624349,
            21.027470161864304
          ],
          [
            105.83826039749063,
            21.027550902835745
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 38 Ngô Sỹ Liên",
        "diemDau": "24-8",
        "diemCuoi": "24-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027470161864304,
        "to_latitude": 21.027550902835745,
        "to_longitude": 105.83826039749063,
        "from_longitude": 105.83851386624349,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 953,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83800692873773,
          21.027550902835745,
          105.83826039749063,
          21.027631643763478
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83826039749063,
            21.027550902835745
          ],
          [
            105.83800692873773,
            21.027631643763478
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 38 Ngô Sỹ Liên",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027550902835745,
        "to_latitude": 21.027631643763478,
        "to_longitude": 105.83800692873773,
        "from_longitude": 105.83826039749063,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 954,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83819468336952,
          21.0273712697619,
          105.83826039749063,
          21.027550902835745
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83826039749063,
            21.027550902835745
          ],
          [
            105.83819468336952,
            21.0273712697619
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 38 Ngô Sỹ Liên",
        "diemDau": "24-8",
        "diemCuoi": "24-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027550902835745,
        "to_latitude": 21.0273712697619,
        "to_longitude": 105.83819468336952,
        "from_longitude": 105.83826039749063,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 955,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83803643303189,
          21.0273712697619,
          105.83819468336952,
          21.027385039552392
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83819468336952,
            21.0273712697619
          ],
          [
            105.83803643303189,
            21.027385039552392
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 38 Ngô Sỹ Liên",
        "diemDau": "24-10",
        "diemCuoi": "24-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.0273712697619,
        "to_latitude": 21.027385039552392,
        "to_longitude": 105.83803643303189,
        "from_longitude": 105.83819468336952,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 956,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83844412880629,
          21.027242334390657,
          105.83851386624349,
          21.027470161864304
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83851386624349,
            21.027470161864304
          ],
          [
            105.83844412880629,
            21.027242334390657
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 38 Ngô Sỹ Liên",
        "diemDau": "24-7",
        "diemCuoi": "24-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027470161864304,
        "to_latitude": 21.027242334390657,
        "to_longitude": 105.83844412880629,
        "from_longitude": 105.83851386624349,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 957,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83857958036015,
          21.02740775076252,
          105.83877336996382,
          21.027527744577565
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83876484430499,
            21.02740775076252
          ],
          [
            105.83877336996382,
            21.027469535965402
          ],
          [
            105.83857958036015,
            21.027527744577565
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Ngô Sỹ Liên",
        "diemDau": "24",
        "diemCuoi": "24-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.02740775076252,
        "to_latitude": 21.027527744577565,
        "to_longitude": 105.83857958036015,
        "from_longitude": 105.83876484430499,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 958,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83839450793612,
          21.027527744577565,
          105.83857958036015,
          21.02760911141655
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83857958036015,
            21.027527744577565
          ],
          [
            105.83839450793612,
            21.02760911141655
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Ngô Sỹ Liên",
        "diemDau": "24-13",
        "diemCuoi": "24-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027527744577565,
        "to_latitude": 21.02760911141655,
        "to_longitude": 105.83839450793612,
        "from_longitude": 105.83857958036015,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 959,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83819468336952,
          21.02760911141655,
          105.83839450793612,
          21.027674204855916
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83839450793612,
            21.02760911141655
          ],
          [
            105.83819468336952,
            21.027674204855916
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Ngô Sỹ Liên",
        "diemDau": "24-14",
        "diemCuoi": "24-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.02760911141655,
        "to_latitude": 21.027674204855916,
        "to_longitude": 105.83819468336952,
        "from_longitude": 105.83839450793612,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 960,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83799351769002,
          21.027674204855916,
          105.83819468336952,
          21.02773679467415
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83819468336952,
            21.027674204855916
          ],
          [
            105.83799351769002,
            21.02773679467415
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Ngô Sỹ Liên",
        "diemDau": "24-15",
        "diemCuoi": "14-16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027674204855916,
        "to_latitude": 21.02773679467415,
        "to_longitude": 105.83799351769002,
        "from_longitude": 105.83819468336952,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 961,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83876733499191,
          21.027653550209724,
          105.83892175352977,
          21.02770299617549
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83892175352977,
            21.027681894463402
          ],
          [
            105.83888870495062,
            21.027653550209724
          ],
          [
            105.83876733499191,
            21.02770299617549
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28 Ngô Sỹ Liên",
        "diemDau": "23",
        "diemCuoi": "23-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027681894463402,
        "to_latitude": 21.02770299617549,
        "to_longitude": 105.83876733499191,
        "from_longitude": 105.83892175352977,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 962,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83862718957002,
          21.02770299617549,
          105.8387787343793,
          21.027876995772168
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83876733499191,
            21.02770299617549
          ],
          [
            105.8387787343793,
            21.02773241338727
          ],
          [
            105.83862718957002,
            21.027792499590166
          ],
          [
            105.83864395338189,
            21.027876995772168
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28 Ngô Sỹ Liên",
        "diemDau": "23-1",
        "diemCuoi": "23-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.02770299617549,
        "to_latitude": 21.027876995772168,
        "to_longitude": 105.83864395338189,
        "from_longitude": 105.83876733499191,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 963,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83881427365435,
          21.0275287609481,
          105.8388341058076,
          21.027539010757582
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8388341058076,
            21.0275287609481
          ],
          [
            105.83881427365435,
            21.027539010757582
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 3,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Ngô Sỹ Liên",
        "diemDau": "24",
        "diemCuoi": "24-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.0275287609481,
        "to_latitude": 21.027539010757582,
        "to_longitude": 105.83881427365435,
        "from_longitude": 105.8388341058076,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 964,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83858259785059,
          21.027539010757582,
          105.83881427365435,
          21.027615057452724
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83881427365435,
            21.027539010757582
          ],
          [
            105.83869893865857,
            21.027577816481784
          ],
          [
            105.83858259785059,
            21.027615057452724
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Ngô Sỹ Liên",
        "diemDau": "24-1",
        "diemCuoi": "24-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027539010757582,
        "to_latitude": 21.027615057452724,
        "to_longitude": 105.83858259785059,
        "from_longitude": 105.83881427365435,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 965,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83823508414496,
          21.027615057452724,
          105.83858259785059,
          21.027729127422656
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83858259785059,
            21.027615057452724
          ],
          [
            105.83842602389954,
            21.027694859498357
          ],
          [
            105.83823508414496,
            21.027729127422656
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Ngô Sỹ Liên",
        "diemDau": "24-2",
        "diemCuoi": "24-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027615057452724,
        "to_latitude": 21.027729127422656,
        "to_longitude": 105.83823508414496,
        "from_longitude": 105.83858259785059,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 966,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83823508414496,
          21.027729127422656,
          105.83828051405318,
          21.027882002951948
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83823508414496,
            21.027729127422656
          ],
          [
            105.83826039748612,
            21.02780188805608
          ],
          [
            105.83828051405318,
            21.027882002951948
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Ngô Sỹ Liên",
        "diemDau": "24-3",
        "diemCuoi": "24-3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027729127422656,
        "to_latitude": 21.027882002951948,
        "to_longitude": 105.83828051405318,
        "from_longitude": 105.83823508414496,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 967,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83842602389954,
          21.027694859498357,
          105.83846960978882,
          21.02781690960334
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83842602389954,
            21.027694859498357
          ],
          [
            105.83845351653338,
            21.027755571613238
          ],
          [
            105.83846960978882,
            21.02781690960334
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Ngô Sỹ Liên",
        "diemDau": "24-2",
        "diemCuoi": "24-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027694859498357,
        "to_latitude": 21.02781690960334,
        "to_longitude": 105.83846960978882,
        "from_longitude": 105.83842602389954,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 968,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8378875704393,
          21.027729127422656,
          105.83823508414496,
          21.027843197305288
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83823508414496,
            21.027729127422656
          ],
          [
            105.83806325512727,
            21.02778811830409
          ],
          [
            105.8378875704393,
            21.027843197305288
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Ngô Sỹ Liên",
        "diemDau": "24-3",
        "diemCuoi": "24-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027729127422656,
        "to_latitude": 21.027843197305288,
        "to_longitude": 105.8378875704393,
        "from_longitude": 105.83823508414496,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 969,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8378875704393,
          21.027843197305288,
          105.8379908354823,
          21.028087297172323
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8378875704393,
            21.027843197305288
          ],
          [
            105.83793450909278,
            21.027960866007767
          ],
          [
            105.8379908354823,
            21.028087297172323
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Ngô Sỹ Liên",
        "diemDau": "24-4",
        "diemCuoi": "24-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027843197305288,
        "to_latitude": 21.028087297172323,
        "to_longitude": 105.8379908354823,
        "from_longitude": 105.8378875704393,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 970,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83776150661255,
          21.02781753550159,
          105.8378875704393,
          21.027848204486187
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8378875704393,
            21.027843197305288
          ],
          [
            105.83786812441971,
            21.02781753550159
          ],
          [
            105.83776150661255,
            21.027848204486187
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Ngô Sỹ Liên",
        "diemDau": "24-4",
        "diemCuoi": "24-4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027843197305288,
        "to_latitude": 21.027848204486187,
        "to_longitude": 105.83776150661255,
        "from_longitude": 105.8378875704393,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 971,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.838096782742,
          21.027729127422656,
          105.83823508414496,
          21.02795460703778
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83823508414496,
            21.027729127422656
          ],
          [
            105.838096782742,
            21.02780313985104
          ],
          [
            105.83814640360319,
            21.02795460703778
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Ngô Sỹ Liên",
        "diemDau": "24-3",
        "diemCuoi": "24-3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.027729127422656,
        "to_latitude": 21.02795460703778,
        "to_longitude": 105.83814640360319,
        "from_longitude": 105.83823508414496,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 972,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82267575721443,
          21.01187099955225,
          105.82281523207992,
          21.012041261842402
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82267575721443,
            21.01187099955225
          ],
          [
            105.82281523207992,
            21.012041261842402
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "26",
        "diemCuoi": "26-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01187099955225,
        "to_latitude": 21.012041261842402,
        "to_longitude": 105.82281523207992,
        "from_longitude": 105.82267575721443,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 973,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82293104432365,
          21.011866627098176,
          105.82296859525002,
          21.011959269841046
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82293104432365,
            21.011866627098176
          ],
          [
            105.82296859525002,
            21.011959269841046
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "27",
        "diemCuoi": "28-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011866627098176,
        "to_latitude": 21.011959269841046,
        "to_longitude": 105.82296859525002,
        "from_longitude": 105.82293104432365,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 974,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82173209688804,
          21.01113218642365,
          105.82217830506258,
          21.01124817271818
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82173209688804,
            21.01124817271818
          ],
          [
            105.82206099617285,
            21.01113218642365
          ],
          [
            105.82217830506258,
            21.011226051347883
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 75 Đặng Văn Ngữ",
        "diemDau": "19-1",
        "diemCuoi": "19-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01124817271818,
        "to_latitude": 21.011226051347883,
        "to_longitude": 105.82217830506258,
        "from_longitude": 105.82173209688804,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 975,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83589703602838,
          21.025813397543367,
          105.83594095721385,
          21.025933571472144
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83594095721385,
            21.025813397543367
          ],
          [
            105.83589703602838,
            21.025841250364238
          ],
          [
            105.8359382750061,
            21.025933571472144
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "PVT",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 53 Thông Phong",
        "diemDau": "35-4",
        "diemCuoi": "35-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Phan Văn Trị",
        "from_latitude": 21.025813397543367,
        "to_latitude": 21.025933571472144,
        "to_longitude": 105.8359382750061,
        "from_longitude": 105.83594095721385,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 976,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83900250095247,
          21.026544007037387,
          105.83906256949909,
          21.026583695976928
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83900250095247,
            21.026583695976928
          ],
          [
            105.83906256949909,
            21.026544007037387
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 8,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 Nguyễn Như Đổ",
        "diemDau": "19",
        "diemCuoi": "19-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.026583695976928,
        "to_latitude": 21.026544007037387,
        "to_longitude": 105.83906256949909,
        "from_longitude": 105.83900250095247,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 977,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83888822591501,
          21.02607958624061,
          105.83906256949909,
          21.026544007037387
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83906256949909,
            21.026544007037387
          ],
          [
            105.83888822591501,
            21.02607958624061
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 58,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 Nguyễn Như Đổ",
        "diemDau": "19-1",
        "diemCuoi": "19-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.026544007037387,
        "to_latitude": 21.02607958624061,
        "to_longitude": 105.83888822591501,
        "from_longitude": 105.83906256949909,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 978,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83888822591501,
          21.02596692329536,
          105.83896869218322,
          21.02607958624061
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83888822591501,
            21.02607958624061
          ],
          [
            105.83888937550705,
            21.026004298790355
          ],
          [
            105.83896869218322,
            21.02596692329536
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 Nguyễn Như Đổ",
        "diemDau": "19-3",
        "diemCuoi": "19-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.02607958624061,
        "to_latitude": 21.02596692329536,
        "to_longitude": 105.83896869218322,
        "from_longitude": 105.83888822591501,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 979,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83874875104955,
          21.025628933949587,
          105.83896869218322,
          21.02596692329536
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83896869218322,
            21.02596692329536
          ],
          [
            105.83889742213027,
            21.025718885686306
          ],
          [
            105.83879281598342,
            21.025738914693576
          ],
          [
            105.83874875104955,
            21.025628933949587
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 57,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 Nguyễn Như Đổ",
        "diemDau": "19-4",
        "diemCuoi": "19-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.02596692329536,
        "to_latitude": 21.025628933949587,
        "to_longitude": 105.83874875104955,
        "from_longitude": 105.83896869218322,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 980,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83888822591501,
          21.02607958624061,
          105.83897405660767,
          21.026256091350053
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83888822591501,
            21.02607958624061
          ],
          [
            105.83891854453196,
            21.026099749322146
          ],
          [
            105.83897405660767,
            21.026256091350053
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NK",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 Nguyễn Như Đổ",
        "diemDau": "19-3",
        "diemCuoi": "19-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Nguyễn Khuyến",
        "from_latitude": 21.02607958624061,
        "to_latitude": 21.026256091350053,
        "to_longitude": 105.83897405660767,
        "from_longitude": 105.83888822591501,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 981,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82210008814202,
          21.006075393187835,
          105.82223285748812,
          21.006209354768448
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82223285748812,
            21.006075393187835
          ],
          [
            105.82210008814202,
            21.006209354768448
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.006075393187835,
        "to_latitude": 21.006209354768448,
        "to_longitude": 105.82210008814202,
        "from_longitude": 105.82223285748812,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 982,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82192221890011,
          21.006209354768433,
          105.82210008814202,
          21.00641230728793
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82210008814202,
            21.006209354768433
          ],
          [
            105.82200218750552,
            21.006312016832716
          ],
          [
            105.82192221890011,
            21.00641230728793
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.006209354768433,
        "to_latitude": 21.00641230728793,
        "to_longitude": 105.82192221890011,
        "from_longitude": 105.82210008814202,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 983,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82176738329419,
          21.00641230728793,
          105.82192221890011,
          21.006591620936813
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82192221890011,
            21.00641230728793
          ],
          [
            105.82176738329419,
            21.006591620936813
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.00641230728793,
        "to_latitude": 21.006591620936813,
        "to_longitude": 105.82176738329419,
        "from_longitude": 105.82192221890011,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 984,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82163338378827,
          21.006591620936813,
          105.82176738329419,
          21.00674206995879
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82176738329419,
            21.006591620936813
          ],
          [
            105.82163338378827,
            21.00674206995879
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.006591620936813,
        "to_latitude": 21.00674206995879,
        "to_longitude": 105.82163338378827,
        "from_longitude": 105.82176738329419,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 985,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82128871993173,
          21.007023274797,
          105.82139946716333,
          21.00716836593246
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82139946716333,
            21.007023274797
          ],
          [
            105.82128871993173,
            21.00716836593246
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.007023274797,
        "to_latitude": 21.00716836593246,
        "to_longitude": 105.82128871993173,
        "from_longitude": 105.82139946716333,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 986,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8210515120105,
          21.00716836593246,
          105.82128871993173,
          21.007464299765104
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82128871993173,
            21.00716836593246
          ],
          [
            105.82121395334825,
            21.00726445450705
          ],
          [
            105.8210515120105,
            21.007464299765104
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.00716836593246,
        "to_latitude": 21.007464299765104,
        "to_longitude": 105.8210515120105,
        "from_longitude": 105.82128871993173,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 987,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82150757482182,
          21.00674206995879,
          105.82163338378827,
          21.006894026742586
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82163338378827,
            21.00674206995879
          ],
          [
            105.82150757482182,
            21.006894026742586
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.00674206995879,
        "to_latitude": 21.006894026742586,
        "to_longitude": 105.82150757482182,
        "from_longitude": 105.82163338378827,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 988,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82139946716333,
          21.0068940267426,
          105.82150757482182,
          21.007023274797
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82150757482182,
            21.0068940267426
          ],
          [
            105.82139946716333,
            21.007023274797
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.0068940267426,
        "to_latitude": 21.007023274797,
        "to_longitude": 105.82139946716333,
        "from_longitude": 105.82150757482182,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 989,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82072984490594,
          21.007464299765104,
          105.8210515120105,
          21.00784718734363
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8210515120105,
            21.007464299765104
          ],
          [
            105.82072984490594,
            21.00784718734363
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.007464299765104,
        "to_latitude": 21.00784718734363,
        "to_longitude": 105.82072984490594,
        "from_longitude": 105.8210515120105,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 990,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82044266719488,
          21.00784718734363,
          105.82072984490594,
          21.008185927463934
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82072984490594,
            21.00784718734363
          ],
          [
            105.82062135271255,
            21.007984177862923
          ],
          [
            105.82044266719488,
            21.008185927463934
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.00784718734363,
        "to_latitude": 21.008185927463934,
        "to_longitude": 105.82044266719488,
        "from_longitude": 105.82072984490594,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 991,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82012510888973,
          21.008185927463934,
          105.82044266719488,
          21.008573116423246
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82044266719488,
            21.008185927463934
          ],
          [
            105.82028607658312,
            21.008372285557645
          ],
          [
            105.82012510888973,
            21.008573116423246
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.008185927463934,
        "to_latitude": 21.008573116423246,
        "to_longitude": 105.82012510888973,
        "from_longitude": 105.82044266719488,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 992,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81972050808271,
          21.008573116423246,
          105.82012510888973,
          21.009055018925377
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82012510888973,
            21.008573116423246
          ],
          [
            105.81995884708587,
            21.008775415706634
          ],
          [
            105.81972050808271,
            21.009055018925377
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 49,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.008573116423246,
        "to_latitude": 21.009055018925377,
        "to_longitude": 105.81972050808271,
        "from_longitude": 105.82012510888973,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 993,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81948920612389,
          21.009055018925388,
          105.81972050808271,
          21.00933431986466
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81972050808271,
            21.009055018925388
          ],
          [
            105.81948920612389,
            21.00933431986466
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.009055018925388,
        "to_latitude": 21.00933431986466,
        "to_longitude": 105.81948920612389,
        "from_longitude": 105.81972050808271,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 994,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81924744704881,
          21.00933431986466,
          105.81948920612389,
          21.009623583608082
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81948920612389,
            21.00933431986466
          ],
          [
            105.81939239815084,
            21.009445679764873
          ],
          [
            105.81924744704881,
            21.009623583608082
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.00933431986466,
        "to_latitude": 21.009623583608082,
        "to_longitude": 105.81924744704881,
        "from_longitude": 105.81948920612389,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 995,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81905608599693,
          21.009623583608082,
          105.81924744704881,
          21.00985163842507
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81924744704881,
            21.009623583608082
          ],
          [
            105.81905608599693,
            21.00985163842507
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.009623583608082,
        "to_latitude": 21.00985163842507,
        "to_longitude": 105.81905608599693,
        "from_longitude": 105.81924744704881,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 996,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8188009710383,
          21.00985163842507,
          105.81905608599693,
          21.010175564637088
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81905608599693,
            21.00985163842507
          ],
          [
            105.8188009710383,
            21.010175564637088
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.00985163842507,
        "to_latitude": 21.010175564637088,
        "to_longitude": 105.8188009710383,
        "from_longitude": 105.81905608599693,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 997,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81858002411492,
          21.010175564637088,
          105.8188009710383,
          21.010425014178153
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8188009710383,
            21.010175564637088
          ],
          [
            105.81858002411492,
            21.010425014178153
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.010175564637088,
        "to_latitude": 21.010425014178153,
        "to_longitude": 105.81858002411492,
        "from_longitude": 105.8188009710383,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 998,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81837349401998,
          21.010425014178153,
          105.81858002411492,
          21.01065537122765
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81858002411492,
            21.010425014178153
          ],
          [
            105.81837349401998,
            21.01065537122765
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.010425014178153,
        "to_latitude": 21.01065537122765,
        "to_longitude": 105.81837349401998,
        "from_longitude": 105.81858002411492,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 999,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81819076848954,
          21.01065537122765,
          105.81837349401998,
          21.01088291106109
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81837349401998,
            21.01065537122765
          ],
          [
            105.8183398069563,
            21.01069709398595
          ],
          [
            105.81827715450076,
            21.010774691494625
          ],
          [
            105.81820015622561,
            21.010872895560464
          ],
          [
            105.81819076848954,
            21.01088291106109
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.01065537122765,
        "to_latitude": 21.01088291106109,
        "to_longitude": 105.81819076848954,
        "from_longitude": 105.81837349401998,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1000,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81797619181627,
          21.01088291106109,
          105.81819076848954,
          21.011132046432742
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81819076848954,
            21.01088291106109
          ],
          [
            105.81797619181627,
            21.011132046432742
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.01088291106109,
        "to_latitude": 21.011132046432742,
        "to_longitude": 105.81797619181627,
        "from_longitude": 105.81819076848954,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1001,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81775356847487,
          21.011132046432742,
          105.81797619181627,
          21.011387441059036
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81797619181627,
            21.011132046432742
          ],
          [
            105.81775356847487,
            21.011387441059036
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.011132046432742,
        "to_latitude": 21.011387441059036,
        "to_longitude": 105.81775356847487,
        "from_longitude": 105.81797619181627,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1002,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81755910831474,
          21.011387441059053,
          105.81775356847487,
          21.011632819798457
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81775356847487,
            21.011387441059053
          ],
          [
            105.81765515502518,
            21.0114958474469
          ],
          [
            105.81755910831474,
            21.011632819798457
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.011387441059053,
        "to_latitude": 21.011632819798457,
        "to_longitude": 105.81755910831474,
        "from_longitude": 105.81775356847487,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1003,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81725199538457,
          21.011632819798457,
          105.81755910831475,
          21.011766776389035
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81755910831475,
            21.011632819798457
          ],
          [
            105.81725199538457,
            21.011766776389035
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.011632819798457,
        "to_latitude": 21.011766776389035,
        "to_longitude": 105.81725199538457,
        "from_longitude": 105.81755910831475,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1004,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82301955722404,
          21.011772732366556,
          105.8231301757412,
          21.01187519551132
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82301955722404,
            21.011772732366556
          ],
          [
            105.8231301757412,
            21.01187519551132
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "28",
        "diemCuoi": "28-1",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011772732366556,
        "to_latitude": 21.01187519551132,
        "to_longitude": 105.8231301757412,
        "from_longitude": 105.82301955722404,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1005,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82091978100907,
          21.010904431954163,
          105.82108071354546,
          21.0110196101528
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82091978100907,
            21.0110196101528
          ],
          [
            105.82108071354546,
            21.010904431954163
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "20-1",
        "diemCuoi": "",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.0110196101528,
        "to_latitude": 21.010904431954163,
        "to_longitude": 105.82108071354546,
        "from_longitude": 105.82091978100907,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1006,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82108071354546,
          21.01071856999609,
          105.82122355824308,
          21.010904431954163
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82108071354546,
            21.010904431954163
          ],
          [
            105.82122355824308,
            21.01071856999609
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "20",
        "diemCuoi": "20-1",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010904431954163,
        "to_latitude": 21.01071856999609,
        "to_longitude": 105.82122355824308,
        "from_longitude": 105.82108071354546,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1007,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82122355824308,
          21.01071856999609,
          105.82140863065813,
          21.01090385685115
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82122355824308,
            21.01071856999609
          ],
          [
            105.82140863065813,
            21.01090385685115
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01071856999609,
        "to_latitude": 21.01090385685115,
        "to_longitude": 105.82140863065813,
        "from_longitude": 105.82122355824308,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1008,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82028270499923,
          21.0109237948532,
          105.82038999336282,
          21.011006422689952
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82028270499923,
            21.011006422689952
          ],
          [
            105.82038999336282,
            21.0109237948532
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011006422689952,
        "to_latitude": 21.0109237948532,
        "to_longitude": 105.82038999336282,
        "from_longitude": 105.82028270499923,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1009,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81973413877267,
          21.010331352708235,
          105.81988166027033,
          21.01044152358405
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81973413877267,
            21.01044152358405
          ],
          [
            105.81988166027033,
            21.010331352708235
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "13-2",
        "diemCuoi": "13-3",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01044152358405,
        "to_latitude": 21.010331352708235,
        "to_longitude": 105.81988166027033,
        "from_longitude": 105.81973413877267,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1010,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82194720221986,
          21.006127976252653,
          105.82210008814202,
          21.006209354768433
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82210008814202,
            21.006209354768433
          ],
          [
            105.82197854983468,
            21.006172268183906
          ],
          [
            105.82194720221986,
            21.006127976252653
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15",
        "diemDau": "02",
        "diemCuoi": "2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.006209354768433,
        "to_latitude": 21.006127976252653,
        "to_longitude": 105.82194720221986,
        "from_longitude": 105.82210008814202,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1011,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8365414367663,
          21.026551651997746,
          105.83677467222952,
          21.02675754740719
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83677467222952,
            21.02675754740719
          ],
          [
            105.83666280673398,
            21.02663051575662
          ],
          [
            105.8365414367663,
            21.026551651997746
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "diemDau": "03",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02675754740719,
        "to_latitude": 21.026551651997746,
        "to_longitude": 105.8365414367663,
        "from_longitude": 105.83677467222952,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1012,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82192221890011,
          21.006307008928637,
          105.8220969964367,
          21.00641230728793
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82192221890011,
            21.00641230728793
          ],
          [
            105.82199011757068,
            21.006334552398588
          ],
          [
            105.82204107953575,
            21.006307008928637
          ],
          [
            105.8220969964367,
            21.006393094299188
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 44",
        "diemDau": "03",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.00641230728793,
        "to_latitude": 21.006393094299188,
        "to_longitude": 105.8220969964367,
        "from_longitude": 105.82192221890011,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1013,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82186819092838,
          21.006489449067185,
          105.82201962186483,
          21.006670081529098
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82186819092838,
            21.006489449067185
          ],
          [
            105.82201962186483,
            21.006670081529098
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.006489449067185,
        "to_latitude": 21.006670081529098,
        "to_longitude": 105.82201962186483,
        "from_longitude": 105.82186819092838,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1014,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82022766523076,
          21.011006422689952,
          105.82028270499923,
          21.01113760132939
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82028270499923,
            21.011006422689952
          ],
          [
            105.82022766523076,
            21.01113760132939
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "11",
        "diemCuoi": "cột",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011006422689952,
        "to_latitude": 21.01113760132939,
        "to_longitude": 105.82022766523076,
        "from_longitude": 105.82028270499923,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1015,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82003163643913,
          21.01113760132939,
          105.82022766523076,
          21.011283214762006
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82022766523076,
            21.01113760132939
          ],
          [
            105.82003163643913,
            21.011283214762006
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "cột",
        "diemCuoi": "11-2",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01113760132939,
        "to_latitude": 21.011283214762006,
        "to_longitude": 105.82003163643913,
        "from_longitude": 105.82022766523076,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1016,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81983033656483,
          21.011283214762006,
          105.82003163643913,
          21.011436357687234
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82003163643913,
            21.011283214762006
          ],
          [
            105.81983033656483,
            21.011436357687234
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "11-2",
        "diemCuoi": "11-3",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011283214762006,
        "to_latitude": 21.011436357687234,
        "to_longitude": 105.81983033656483,
        "from_longitude": 105.82003163643913,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1017,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8197248055284,
          21.011436357687234,
          105.81983033656483,
          21.011519715800475
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81983033656483,
            21.011436357687234
          ],
          [
            105.8197248055284,
            21.011519715800475
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "11-3",
        "diemCuoi": "11-4",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011436357687234,
        "to_latitude": 21.011519715800475,
        "to_longitude": 105.8197248055284,
        "from_longitude": 105.81983033656483,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1018,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82176738329419,
          21.006489449067185,
          105.82186819092838,
          21.006591620936813
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82176738329419,
            21.006591620936813
          ],
          [
            105.82186819092838,
            21.006489449067185
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.006591620936813,
        "to_latitude": 21.006489449067185,
        "to_longitude": 105.82186819092838,
        "from_longitude": 105.82176738329419,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1019,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83894167855802,
          21.019036557830166,
          105.83923940375213,
          21.01904782464941
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83923940375213,
            21.019036557830166
          ],
          [
            105.83894167855802,
            21.01904782464941
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.019036557830166,
        "to_latitude": 21.01904782464941,
        "to_longitude": 105.83894167855802,
        "from_longitude": 105.83923940375213,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1020,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82086569564666,
          21.01034856692118,
          105.82122355824308,
          21.01071856999609
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82122355824308,
            21.01071856999609
          ],
          [
            105.82086569564666,
            21.01034856692118
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01071856999609,
        "to_latitude": 21.01034856692118,
        "to_longitude": 105.82086569564666,
        "from_longitude": 105.82122355824308,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1021,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82069939868582,
          21.01018080658652,
          105.82086569564666,
          21.01034856692118
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82086569564666,
            21.01034856692118
          ],
          [
            105.82069939868582,
            21.01018080658652
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01034856692118,
        "to_latitude": 21.01018080658652,
        "to_longitude": 105.82069939868582,
        "from_longitude": 105.82086569564666,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1022,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82059445155032,
          21.009969268621926,
          105.82069939868582,
          21.01018080658652
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82069939868582,
            21.01018080658652
          ],
          [
            105.82059445155032,
            21.009969268621926
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01018080658652,
        "to_latitude": 21.009969268621926,
        "to_longitude": 105.82059445155032,
        "from_longitude": 105.82069939868582,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1023,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82059445155032,
          21.009857804813777,
          105.8207584072831,
          21.009969268621926
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82059445155032,
            21.009969268621926
          ],
          [
            105.8207584072831,
            21.009857804813777
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "23",
        "diemCuoi": "23-3",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.009969268621926,
        "to_latitude": 21.009857804813777,
        "to_longitude": 105.8207584072831,
        "from_longitude": 105.82059445155032,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1024,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82060015695447,
          21.009612423154643,
          105.8207584072831,
          21.009857804813777
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8207584072831,
            21.009857804813777
          ],
          [
            105.82060015695447,
            21.009612423154643
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "23-3",
        "diemCuoi": "23-4",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.009857804813777,
        "to_latitude": 21.009612423154643,
        "to_longitude": 105.82060015695447,
        "from_longitude": 105.8207584072831,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1025,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82038289801956,
          21.009942937131637,
          105.82059445155032,
          21.010030573290955
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82059445155032,
            21.009969268621926
          ],
          [
            105.82052505510174,
            21.009942937131637
          ],
          [
            105.82038289801956,
            21.010030573290955
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "23",
        "diemCuoi": "23-3",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.009969268621926,
        "to_latitude": 21.010030573290955,
        "to_longitude": 105.82038289801956,
        "from_longitude": 105.82059445155032,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1026,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82045799987227,
          21.009969268621926,
          105.82059445155032,
          21.010148256051473
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82059445155032,
            21.009969268621926
          ],
          [
            105.82045799987227,
            21.010148256051473
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "23",
        "diemCuoi": "23-1",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.009969268621926,
        "to_latitude": 21.010148256051473,
        "to_longitude": 105.82045799987227,
        "from_longitude": 105.82059445155032,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1027,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82031428937836,
          21.010148256051473,
          105.82045799987227,
          21.0102127857883
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82045799987227,
            21.010148256051473
          ],
          [
            105.82031428937836,
            21.0102127857883
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "23-1",
        "diemCuoi": "23-2",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010148256051473,
        "to_latitude": 21.0102127857883,
        "to_longitude": 105.82031428937836,
        "from_longitude": 105.82045799987227,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1028,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83867345765806,
          21.01904782464941,
          105.83894167855802,
          21.01907536576679
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83894167855802,
            21.01904782464941
          ],
          [
            105.83867345765806,
            21.01907536576679
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "08",
        "diemCuoi": "08+1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01904782464941,
        "to_latitude": 21.01907536576679,
        "to_longitude": 105.83867345765806,
        "from_longitude": 105.83894167855802,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1029,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82086569564666,
          21.0101582716016,
          105.82118219630395,
          21.01034856692118
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82086569564666,
            21.01034856692118
          ],
          [
            105.82118219630395,
            21.0101582716016
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "21",
        "diemCuoi": "21-1",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01034856692118,
        "to_latitude": 21.0101582716016,
        "to_longitude": 105.82118219630395,
        "from_longitude": 105.82086569564666,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1030,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82153548314282,
          21.00674206995879,
          105.82165064313915,
          21.00700686281974
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82163338378827,
            21.00674206995879
          ],
          [
            105.82153548314282,
            21.006885420722845
          ],
          [
            105.82165064313915,
            21.00700686281974
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 88",
        "diemDau": "05",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.00674206995879,
        "to_latitude": 21.00700686281974,
        "to_longitude": 105.82165064313915,
        "from_longitude": 105.82163338378827,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1031,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83919380618458,
          21.018499504992313,
          105.8392890246223,
          21.018702308389937
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8392890246223,
            21.018702308389937
          ],
          [
            105.83919380618458,
            21.018499504992313
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chợ Khâm Thiên",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018702308389937,
        "to_latitude": 21.018499504992313,
        "to_longitude": 105.83919380618458,
        "from_longitude": 105.8392890246223,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1032,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83907847119778,
          21.018284182564614,
          105.83919380618458,
          21.018499504992313
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83919380618458,
            21.018499504992313
          ],
          [
            105.83907847119778,
            21.018284182564614
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chợ Khâm Thiên",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018499504992313,
        "to_latitude": 21.018284182564614,
        "to_longitude": 105.83907847119778,
        "from_longitude": 105.83919380618458,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1033,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82267575721443,
          21.011793144611342,
          105.82275346782863,
          21.01187099955225
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82267575721443,
            21.01187099955225
          ],
          [
            105.82275346782863,
            21.011793144611342
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "26",
        "diemCuoi": "cột",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01187099955225,
        "to_latitude": 21.011793144611342,
        "to_longitude": 105.82275346782863,
        "from_longitude": 105.82267575721443,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1034,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82275346782863,
          21.011793144611342,
          105.82293104432365,
          21.011866627098176
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82275346782863,
            21.011793144611342
          ],
          [
            105.82293104432365,
            21.011866627098176
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "cột",
        "diemCuoi": "27",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011793144611342,
        "to_latitude": 21.011866627098176,
        "to_longitude": 105.82293104432365,
        "from_longitude": 105.82275346782863,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1035,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82193824400424,
          21.011553869298545,
          105.82224133362284,
          21.011711612643914
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82224133362284,
            21.011711612643914
          ],
          [
            105.82193824400424,
            21.011553869298545
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "ACD-25",
        "diemCuoi": "ACD-24",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011711612643914,
        "to_latitude": 21.011553869298545,
        "to_longitude": 105.82193824400424,
        "from_longitude": 105.82224133362284,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1036,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82243548764606,
          21.01187099955225,
          105.82267575721443,
          21.012052701377815
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82243548764606,
            21.012052701377815
          ],
          [
            105.82267575721443,
            21.01187099955225
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "tủ điện",
        "diemCuoi": "ACD 26",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.012052701377815,
        "to_latitude": 21.01187099955225,
        "to_longitude": 105.82267575721443,
        "from_longitude": 105.82243548764606,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1037,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82224133362284,
          21.011711612643914,
          105.82245892783138,
          21.01203497494599
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82245892783138,
            21.01203497494599
          ],
          [
            105.82224133362284,
            21.011711612643914
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "ACD-25",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01203497494599,
        "to_latitude": 21.011711612643914,
        "to_longitude": 105.82224133362284,
        "from_longitude": 105.82245892783138,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1038,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82173209688804,
          21.01124817271818,
          105.8217959192066,
          21.011416313216003
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82173209688804,
            21.01124817271818
          ],
          [
            105.8217959192066,
            21.011416313216003
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "cột",
        "diemCuoi": "ACD-04",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01124817271818,
        "to_latitude": 21.011416313216003,
        "to_longitude": 105.8217959192066,
        "from_longitude": 105.82173209688804,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1039,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8217959192066,
          21.011416313216003,
          105.82224133362284,
          21.011711612643914
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8217959192066,
            21.011416313216003
          ],
          [
            105.82224133362284,
            21.011711612643914
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "ACD-25",
        "diemCuoi": "cột",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011416313216003,
        "to_latitude": 21.011711612643914,
        "to_longitude": 105.82224133362284,
        "from_longitude": 105.8217959192066,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1040,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82173209688804,
          21.01124817271818,
          105.82187906768252,
          21.011301135323652
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82173209688804,
            21.01124817271818
          ],
          [
            105.82187906768252,
            21.011301135323652
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "ACD-04",
        "diemCuoi": "cột",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01124817271818,
        "to_latitude": 21.011301135323652,
        "to_longitude": 105.82187906768252,
        "from_longitude": 105.82173209688804,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1041,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82187906768252,
          21.011301135323652,
          105.82206737301749,
          21.01145974950511
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82187906768252,
            21.011301135323652
          ],
          [
            105.82206737301749,
            21.01145974950511
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "cột",
        "diemCuoi": "ACD 4-1",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011301135323652,
        "to_latitude": 21.01145974950511,
        "to_longitude": 105.82206737301749,
        "from_longitude": 105.82187906768252,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1042,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82206737301749,
          21.01145974950511,
          105.82222929748262,
          21.01157804780474
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82206737301749,
            21.01145974950511
          ],
          [
            105.82222929748262,
            21.01157804780474
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "ACD 4-1",
        "diemCuoi": "ACD 4-2",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01145974950511,
        "to_latitude": 21.01157804780474,
        "to_longitude": 105.82222929748262,
        "from_longitude": 105.82206737301749,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1043,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82222929748262,
          21.01150144464444,
          105.82244307261938,
          21.01157804780474
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82222929748262,
            21.01157804780474
          ],
          [
            105.82235381868641,
            21.01150144464444
          ],
          [
            105.82244307261938,
            21.01154568352903
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "ACD 4-2",
        "diemCuoi": "ACD 4-3",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01157804780474,
        "to_latitude": 21.01154568352903,
        "to_longitude": 105.82244307261938,
        "from_longitude": 105.82222929748262,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1044,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82293104432365,
          21.011772732366556,
          105.82301955722404,
          21.011866627098176
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82293104432365,
            21.011866627098176
          ],
          [
            105.82301955722404,
            21.011772732366556
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011866627098176,
        "to_latitude": 21.011772732366556,
        "to_longitude": 105.82301955722404,
        "from_longitude": 105.82293104432365,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1045,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8231067290116,
          21.011686349162634,
          105.82326900266084,
          21.011816549915153
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8231067290116,
            21.011686349162634
          ],
          [
            105.82326900266084,
            21.011816549915153
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "29",
        "diemCuoi": "29-1",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011686349162634,
        "to_latitude": 21.011816549915153,
        "to_longitude": 105.82326900266084,
        "from_longitude": 105.8231067290116,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1046,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82204854981786,
          21.01084934291532,
          105.82208672750059,
          21.01092946691843
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82204854981786,
            21.01092946691843
          ],
          [
            105.82208672750059,
            21.010880879954136
          ],
          [
            105.82205927864882,
            21.01084934291532
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "03",
        "diemCuoi": "3-1",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01092946691843,
        "to_latitude": 21.01084934291532,
        "to_longitude": 105.82205927864882,
        "from_longitude": 105.82204854981786,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1047,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82173209688804,
          21.01092946691843,
          105.82204854981786,
          21.01124817271818
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82173209688804,
            21.01124817271818
          ],
          [
            105.82204854981786,
            21.01092946691843
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "04",
        "diemCuoi": "03",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01124817271818,
        "to_latitude": 21.01092946691843,
        "to_longitude": 105.82204854981786,
        "from_longitude": 105.82173209688804,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1048,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82204854981786,
          21.010676575386405,
          105.8222953130469,
          21.01092946691843
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82204854981786,
            21.01092946691843
          ],
          [
            105.8222953130469,
            21.010676575386405
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01092946691843,
        "to_latitude": 21.010676575386405,
        "to_longitude": 105.8222953130469,
        "from_longitude": 105.82204854981786,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1049,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82207000748876,
          21.009970479928665,
          105.82220411793875,
          21.010140744387204
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82207000748876,
            21.009970479928665
          ],
          [
            105.82220411793875,
            21.010140744387204
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "1-1-1",
        "diemCuoi": "1-1",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.009970479928665,
        "to_latitude": 21.010140744387204,
        "to_longitude": 105.82220411793875,
        "from_longitude": 105.82207000748876,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1050,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82207000748876,
          21.009970479928665,
          105.82222775486866,
          21.010121808742575
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82207000748876,
            21.009970479928665
          ],
          [
            105.82222775486866,
            21.010121808742575
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "1-1",
        "diemCuoi": "1-1-1",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.009970479928665,
        "to_latitude": 21.010121808742575,
        "to_longitude": 105.82222775486866,
        "from_longitude": 105.82207000748876,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1051,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82222775486866,
          21.010121808742575,
          105.82245306042677,
          21.010342150723186
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82222775486866,
            21.010121808742575
          ],
          [
            105.82245306042677,
            21.010342150723186
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "cột",
        "diemCuoi": "1-1",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010121808742575,
        "to_latitude": 21.010342150723186,
        "to_longitude": 105.82245306042677,
        "from_longitude": 105.82222775486866,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1052,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82245306042677,
          21.010342150723186,
          105.82248256472091,
          21.010469848767162
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82245306042677,
            21.010342150723186
          ],
          [
            105.82248256472091,
            21.010469848767162
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "cột",
        "diemCuoi": "cột -1",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010342150723186,
        "to_latitude": 21.010469848767162,
        "to_longitude": 105.82248256472091,
        "from_longitude": 105.82245306042677,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1053,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8222953130469,
          21.010469848767162,
          105.82248256472091,
          21.010676575386405
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8222953130469,
            21.010676575386405
          ],
          [
            105.82248256472091,
            21.010469848767162
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "02",
        "diemCuoi": "cột",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010676575386405,
        "to_latitude": 21.010469848767162,
        "to_longitude": 105.82248256472091,
        "from_longitude": 105.8222953130469,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1054,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82248256472091,
          21.010318520306292,
          105.82261449591192,
          21.010469848767162
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82248256472091,
            21.010469848767162
          ],
          [
            105.82261449591192,
            21.010318520306292
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "cột",
        "diemCuoi": "01",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010469848767162,
        "to_latitude": 21.010318520306292,
        "to_longitude": 105.82261449591192,
        "from_longitude": 105.82248256472091,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1055,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82194394366202,
          21.00983526977843,
          105.82207000748876,
          21.009970479928665
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82207000748876,
            21.009970479928665
          ],
          [
            105.82194394366202,
            21.00983526977843
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "1-1-1",
        "diemCuoi": "1-4",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.009970479928665,
        "to_latitude": 21.00983526977843,
        "to_longitude": 105.82194394366202,
        "from_longitude": 105.82207000748876,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1056,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82184201972291,
          21.00971758677013,
          105.82194394366202,
          21.00983526977843
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82194394366202,
            21.00983526977843
          ],
          [
            105.82184201972291,
            21.00971758677013
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "1-4",
        "diemCuoi": "1-4-1",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.00983526977843,
        "to_latitude": 21.00971758677013,
        "to_longitude": 105.82184201972291,
        "from_longitude": 105.82194394366202,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1057,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82172132031162,
          21.009587384185636,
          105.82184201972291,
          21.00971758677013
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82184201972291,
            21.00971758677013
          ],
          [
            105.82172132031162,
            21.009587384185636
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "1-4-1",
        "diemCuoi": "1-5",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.00971758677013,
        "to_latitude": 21.009587384185636,
        "to_longitude": 105.82172132031162,
        "from_longitude": 105.82184201972291,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1058,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82152015464115,
          21.009499747765982,
          105.82172132031162,
          21.009587384185636
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82172132031162,
            21.009587384185636
          ],
          [
            105.82152015464115,
            21.009499747765982
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "1-8",
        "diemCuoi": "1-9",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.009587384185636,
        "to_latitude": 21.009499747765982,
        "to_longitude": 105.82152015464115,
        "from_longitude": 105.82172132031162,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1059,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82182005909424,
          21.00971758677013,
          105.82194394366202,
          21.00985389248683
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82184201972291,
            21.00971758677013
          ],
          [
            105.82182005909424,
            21.009733705599075
          ],
          [
            105.82192198304234,
            21.00985389248683
          ],
          [
            105.82194394366202,
            21.00983526977843
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "1-4",
        "diemCuoi": "1-4-1",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.00971758677013,
        "to_latitude": 21.00983526977843,
        "to_longitude": 105.82194394366202,
        "from_longitude": 105.82184201972291,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1060,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82130557791399,
          21.009587384185636,
          105.82172132031162,
          21.009820246421253
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82172132031162,
            21.009587384185636
          ],
          [
            105.82130557791399,
            21.009820246421253
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "1-5",
        "diemCuoi": "1-6",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.009587384185636,
        "to_latitude": 21.009820246421253,
        "to_longitude": 105.82130557791399,
        "from_longitude": 105.82172132031162,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1061,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82112050548996,
          21.009612423152976,
          105.82130557791399,
          21.009820246421253
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82130557791399,
            21.009820246421253
          ],
          [
            105.82112050548996,
            21.009612423152976
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "1-6",
        "diemCuoi": "1-7",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.009820246421253,
        "to_latitude": 21.009612423152976,
        "to_longitude": 105.82112050548996,
        "from_longitude": 105.82130557791399,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1062,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82126802698762,
          21.00925937332309,
          105.82154915598655,
          21.009438245755298
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82154915598655,
            21.009438245755298
          ],
          [
            105.82126802698762,
            21.00925937332309
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "1-9",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.009438245755298,
        "to_latitude": 21.00925937332309,
        "to_longitude": 105.82126802698762,
        "from_longitude": 105.82154915598655,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1063,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82109610124597,
          21.009258978449044,
          105.82126802698762,
          21.009325570236744
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82126802698762,
            21.00925937332309
          ],
          [
            105.82116023568427,
            21.009325570236744
          ],
          [
            105.82109610124597,
            21.009258978449044
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "1-9",
        "diemCuoi": "1-10",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.00925937332309,
        "to_latitude": 21.009258978449044,
        "to_longitude": 105.82109610124597,
        "from_longitude": 105.82126802698762,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1064,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83505515772526,
          21.01145880124726,
          105.8351785393353,
          21.011531413330395
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83505515772526,
            21.01145880124726
          ],
          [
            105.8351785393353,
            21.011531413330395
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.01145880124726,
        "to_latitude": 21.011531413330395,
        "to_longitude": 105.8351785393353,
        "from_longitude": 105.83505515772526,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1065,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8351785393353,
          21.01153141333041,
          105.83531801420077,
          21.011649094907813
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8351785393353,
            21.01153141333041
          ],
          [
            105.83531801420077,
            21.011649094907813
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.01153141333041,
        "to_latitude": 21.011649094907813,
        "to_longitude": 105.83531801420077,
        "from_longitude": 105.8351785393353,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1066,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83531801420077,
          21.011649094907813,
          105.8354762645384,
          21.011781799553482
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83531801420077,
            21.011649094907813
          ],
          [
            105.8354762645384,
            21.011781799553482
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.011649094907813,
        "to_latitude": 21.011781799553482,
        "to_longitude": 105.8354762645384,
        "from_longitude": 105.83531801420077,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1067,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8354762645384,
          21.011538924923002,
          105.83561305719611,
          21.011781799553482
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8354762645384,
            21.011781799553482
          ],
          [
            105.83548967558609,
            21.01165911035634
          ],
          [
            105.83561305719611,
            21.011538924923002
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "diemDau": "07",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.011781799553482,
        "to_latitude": 21.011538924923002,
        "to_longitude": 105.83561305719611,
        "from_longitude": 105.8354762645384,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1068,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8354762645384,
          21.01170668373036,
          105.83558623510972,
          21.011781799553482
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8354762645384,
            21.011781799553482
          ],
          [
            105.83558623510972,
            21.01170668373036
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.011781799553482,
        "to_latitude": 21.01170668373036,
        "to_longitude": 105.83558623510972,
        "from_longitude": 105.8354762645384,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1069,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83558623510972,
          21.01155394810788,
          105.83572570997518,
          21.01170668373036
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83558623510972,
            21.01170668373036
          ],
          [
            105.83572570997518,
            21.01155394810788
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.01170668373036,
        "to_latitude": 21.01155394810788,
        "to_longitude": 105.83572570997518,
        "from_longitude": 105.83558623510972,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1070,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8213034720833,
          21.006894026742625,
          105.82150757482182,
          21.00700247998118
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82150757482182,
            21.006894026742625
          ],
          [
            105.82140941934301,
            21.00700247998118
          ],
          [
            105.8213034720833,
            21.006969928753573
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 27",
        "diemDau": "06",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.006894026742625,
        "to_latitude": 21.006969928753573,
        "to_longitude": 105.8213034720833,
        "from_longitude": 105.82150757482182,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1071,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8349542396118,
          21.01145880124726,
          105.83505515772526,
          21.011572414100062
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83505515772526,
            21.01145880124726
          ],
          [
            105.8349542396118,
            21.011572414100062
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.01145880124726,
        "to_latitude": 21.011572414100062,
        "to_longitude": 105.8349542396118,
        "from_longitude": 105.83505515772526,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1072,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83482012916184,
          21.011572414100062,
          105.8349542396118,
          21.011738920939944
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8349542396118,
            21.011572414100062
          ],
          [
            105.83482012916184,
            21.011738920939944
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "diemDau": "02",
        "diemCuoi": "01",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.011572414100062,
        "to_latitude": 21.011738920939944,
        "to_longitude": 105.83482012916184,
        "from_longitude": 105.8349542396118,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1073,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83475843835681,
          21.011738920939944,
          105.83482012916184,
          21.011785242358243
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83482012916184,
            21.011738920939944
          ],
          [
            105.83475843835681,
            21.011785242358243
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 81 Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.011738920939944,
        "to_latitude": 21.011785242358243,
        "to_longitude": 105.83475843835681,
        "from_longitude": 105.83482012916184,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1074,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83475843835681,
          21.011785242358243,
          105.83489120770292,
          21.011859106212132
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83475843835681,
            21.011785242358243
          ],
          [
            105.83489120770292,
            21.011859106212132
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 81 Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.011785242358243,
        "to_latitude": 21.011859106212132,
        "to_longitude": 105.83489120770292,
        "from_longitude": 105.83475843835681,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1075,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83477184939552,
          21.011859106212132,
          105.83489120770292,
          21.012039383938877
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83489120770292,
            21.011859106212132
          ],
          [
            105.83477184939552,
            21.012039383938877
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6/31 Đặng Văn Ngữ",
        "diemDau": "16",
        "diemCuoi": "16-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.011859106212132,
        "to_latitude": 21.012039383938877,
        "to_longitude": 105.83477184939552,
        "from_longitude": 105.83489120770292,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1076,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83454252052579,
          21.011891656373017,
          105.83477184939552,
          21.012039383938877
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83477184939552,
            21.012039383938877
          ],
          [
            105.83454252052579,
            21.011891656373017
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6/31 Đặng Văn Ngữ",
        "diemDau": "16-1",
        "diemCuoi": "16-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.012039383938877,
        "to_latitude": 21.011891656373017,
        "to_longitude": 105.83454252052579,
        "from_longitude": 105.83477184939552,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1077,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83429843951346,
          21.011731409357427,
          105.83454252052579,
          21.011891656373017
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83454252052579,
            21.011891656373017
          ],
          [
            105.83429843951346,
            21.011731409357427
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6/31 Đặng Văn Ngữ",
        "diemDau": "16-3",
        "diemCuoi": "16-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.011891656373017,
        "to_latitude": 21.011731409357427,
        "to_longitude": 105.83429843951346,
        "from_longitude": 105.83454252052579,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1078,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83465517330485,
          21.012039383938877,
          105.83477184939552,
          21.012225921079956
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83477184939552,
            21.012039383938877
          ],
          [
            105.83465517330485,
            21.012225921079956
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6/31 Đặng Văn Ngữ",
        "diemDau": "16-1",
        "diemCuoi": "16-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.012039383938877,
        "to_latitude": 21.012225921079956,
        "to_longitude": 105.83465517330485,
        "from_longitude": 105.83477184939552,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1079,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82112979889395,
          21.00697180672846,
          105.82128871993173,
          21.00716836593246
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82128871993173,
            21.00716836593246
          ],
          [
            105.82122769952146,
            21.007069460389634
          ],
          [
            105.82112979889395,
            21.00697180672846
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà C5 Thái Thịnh",
        "diemDau": "08",
        "diemCuoi": "8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.00716836593246,
        "to_latitude": 21.00697180672846,
        "to_longitude": 105.82112979889395,
        "from_longitude": 105.82128871993173,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1080,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82091924548737,
          21.006809050484136,
          105.82112979889395,
          21.00697180672846
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82112979889395,
            21.00697180672846
          ],
          [
            105.82091924548737,
            21.006809050484136
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà C5 Thái Thịnh",
        "diemDau": "8-1",
        "diemCuoi": "8-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.00697180672846,
        "to_latitude": 21.006809050484136,
        "to_longitude": 105.82091924548737,
        "from_longitude": 105.82112979889395,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1081,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82079049945288,
          21.006676341414277,
          105.82091924548737,
          21.006809050484136
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82091924548737,
            21.006809050484136
          ],
          [
            105.82079988717997,
            21.00671139665168
          ],
          [
            105.82079049945288,
            21.006676341414277
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà C5 Thái Thịnh",
        "diemDau": "8-2",
        "diemCuoi": "8-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.006809050484136,
        "to_latitude": 21.006676341414277,
        "to_longitude": 105.82079049945288,
        "from_longitude": 105.82091924548737,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1082,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83489120770292,
          21.011859106212132,
          105.83508566785407,
          21.012035628154393
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83489120770292,
            21.011859106212132
          ],
          [
            105.83508566785407,
            21.012035628154393
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 81 Xã Đàn",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.011859106212132,
        "to_latitude": 21.012035628154393,
        "to_longitude": 105.83508566785407,
        "from_longitude": 105.83489120770292,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1083,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83508566785407,
          21.012035628154393,
          105.83526805807035,
          21.01215080547963
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83508566785407,
            21.012035628154393
          ],
          [
            105.83526805807035,
            21.01215080547963
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 81 Xã Đàn",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.012035628154393,
        "to_latitude": 21.01215080547963,
        "to_longitude": 105.83526805807035,
        "from_longitude": 105.83508566785407,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1084,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83526805807035,
          21.012109804867272,
          105.83547760563327,
          21.01221747057719
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83526805807035,
            21.01215080547963
          ],
          [
            105.83534349518331,
            21.012109804867272
          ],
          [
            105.83547760563327,
            21.01221747057719
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 81 Xã Đàn",
        "diemDau": "18",
        "diemCuoi": "18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.01215080547963,
        "to_latitude": 21.01221747057719,
        "to_longitude": 105.83547760563327,
        "from_longitude": 105.83526805807035,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1085,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83547760563327,
          21.012092277883834,
          105.83560098724331,
          21.01221747057719
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83547760563327,
            21.01221747057719
          ],
          [
            105.83560098724331,
            21.012092277883834
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 81 Xã Đàn",
        "diemDau": "18-1",
        "diemCuoi": "18-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.01221747057719,
        "to_latitude": 21.012092277883834,
        "to_longitude": 105.83560098724331,
        "from_longitude": 105.83547760563327,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1086,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83526571112283,
          21.01215080547963,
          105.83526805807035,
          21.01233014391144
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83526805807035,
            21.01215080547963
          ],
          [
            105.83526571112283,
            21.01233014391144
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 81 Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.01215080547963,
        "to_latitude": 21.01233014391144,
        "to_longitude": 105.83526571112283,
        "from_longitude": 105.83526805807035,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1087,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83502967672477,
          21.012154874244054,
          105.83526571112283,
          21.01233014391144
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83526571112283,
            21.01233014391144
          ],
          [
            105.83502967672477,
            21.012154874244054
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 81 Xã Đàn",
        "diemDau": "19",
        "diemCuoi": "19-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.01233014391144,
        "to_latitude": 21.012154874244054,
        "to_longitude": 105.83502967672477,
        "from_longitude": 105.83526571112283,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1088,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83526571112283,
          21.01233014391144,
          105.83541591482825,
          21.012653140330922
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83526571112283,
            21.01233014391144
          ],
          [
            105.83541591482825,
            21.012653140330922
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 81 Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.01233014391144,
        "to_latitude": 21.012653140330922,
        "to_longitude": 105.83541591482825,
        "from_longitude": 105.83526571112283,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1089,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82128503188038,
          21.00716836593246,
          105.8213990257633,
          21.00729324976823
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82128871993173,
            21.00716836593246
          ],
          [
            105.82128503188038,
            21.007196848221476
          ],
          [
            105.8213990257633,
            21.00729324976823
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà E6 Thái Thịnh",
        "diemDau": "08",
        "diemCuoi": "8-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.00716836593246,
        "to_latitude": 21.00729324976823,
        "to_longitude": 105.8213990257633,
        "from_longitude": 105.82128871993173,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1090,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83470680579939,
          21.011514199264028,
          105.8349542396118,
          21.011572414100062
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8349542396118,
            21.011572414100062
          ],
          [
            105.83470680579939,
            21.011514199264028
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46/23 Phạm Ngọc Thạch",
        "diemDau": "02",
        "diemCuoi": "2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.011572414100062,
        "to_latitude": 21.011514199264028,
        "to_longitude": 105.83470680579939,
        "from_longitude": 105.8349542396118,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1091,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8349542396118,
          21.011572414100062,
          105.83512254819703,
          21.01171951602129
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8349542396118,
            21.011572414100062
          ],
          [
            105.83512254819703,
            21.01171951602129
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.011572414100062,
        "to_latitude": 21.01171951602129,
        "to_longitude": 105.83512254819703,
        "from_longitude": 105.8349542396118,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1092,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83512254819703,
          21.01171951602129,
          105.83528884515788,
          21.011852220604315
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83512254819703,
            21.01171951602129
          ],
          [
            105.83528884515788,
            21.011852220604315
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.01171951602129,
        "to_latitude": 21.011852220604315,
        "to_longitude": 105.83528884515788,
        "from_longitude": 105.83512254819703,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1093,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8213762269885,
          21.00729324976823,
          105.82157873377187,
          21.007467273180882
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8213990257633,
            21.00729324976823
          ],
          [
            105.8213990257633,
            21.00729324976823
          ],
          [
            105.8213762269885,
            21.007313281250283
          ],
          [
            105.821412436811,
            21.007344580436293
          ],
          [
            105.82143121227418,
            21.007328304860707
          ],
          [
            105.82157873377187,
            21.007467273180882
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà E6 Thái Thịnh",
        "diemDau": "8-4",
        "diemCuoi": "8-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.00729324976823,
        "to_latitude": 21.007467273180882,
        "to_longitude": 105.82157873377187,
        "from_longitude": 105.8213990257633,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1094,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82146742209665,
          21.007467273180882,
          105.82157873377187,
          21.00759622565103
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82157873377187,
            21.007467273180882
          ],
          [
            105.82146742209665,
            21.00759622565103
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà E6 Thái Thịnh",
        "diemDau": "8-5",
        "diemCuoi": "8-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.007467273180882,
        "to_latitude": 21.00759622565103,
        "to_longitude": 105.82146742209665,
        "from_longitude": 105.82157873377187,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1095,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83505515772526,
          21.011233766162906,
          105.83514400586795,
          21.01145880124726
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83505515772526,
            21.01145880124726
          ],
          [
            105.83514400586795,
            21.011233766162906
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Phạm Ngọc Thạch",
        "diemDau": "",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.01145880124726,
        "to_latitude": 21.011233766162906,
        "to_longitude": 105.83514400586795,
        "from_longitude": 105.83505515772526,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1096,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83514400586795,
          21.010970859651867,
          105.83536662921833,
          21.011233766162906
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83514400586795,
            21.011233766162906
          ],
          [
            105.83536662921833,
            21.010970859651867
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Phạm Ngọc Thạch",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.011233766162906,
        "to_latitude": 21.010970859651867,
        "to_longitude": 105.83536662921833,
        "from_longitude": 105.83514400586795,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1097,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83513595924472,
          21.010813115523153,
          105.83536662921833,
          21.010970859651867
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83536662921833,
            21.010970859651867
          ],
          [
            105.83513595924472,
            21.010813115523153
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46/15 Phạm Ngọc Thạch",
        "diemDau": "12",
        "diemCuoi": "12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.010970859651867,
        "to_latitude": 21.010813115523153,
        "to_longitude": 105.83513595924472,
        "from_longitude": 105.83536662921833,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1098,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83497502670834,
          21.010695433286255,
          105.83513595924472,
          21.010813115523153
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83513595924472,
            21.010813115523153
          ],
          [
            105.83497502670834,
            21.010695433286255
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46/15 Phạm Ngọc Thạch",
        "diemDau": "12-1",
        "diemCuoi": "12-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.010813115523153,
        "to_latitude": 21.010695433286255,
        "to_longitude": 105.83497502670834,
        "from_longitude": 105.83513595924472,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1099,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83487846717573,
          21.010695433286255,
          105.83497502670834,
          21.010843162036757
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83497502670834,
            21.010695433286255
          ],
          [
            105.83487846717573,
            21.010843162036757
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46/15 Phạm Ngọc Thạch",
        "diemDau": "12-2",
        "diemCuoi": "12-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.010695433286255,
        "to_latitude": 21.010843162036757,
        "to_longitude": 105.83487846717573,
        "from_longitude": 105.83497502670834,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1100,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83483018741839,
          21.010647859589742,
          105.83497502670834,
          21.01076053410958
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83497502670834,
            21.010695433286255
          ],
          [
            105.83490797147887,
            21.010647859589742
          ],
          [
            105.83483018741839,
            21.01076053410958
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46/15 Phạm Ngọc Thạch",
        "diemDau": "12-2",
        "diemCuoi": "12-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.010695433286255,
        "to_latitude": 21.01076053410958,
        "to_longitude": 105.83483018741839,
        "from_longitude": 105.83497502670834,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1101,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82092058675295,
          21.007243484038163,
          105.82114052789562,
          21.00746429976513
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8210515120105,
            21.00746429976513
          ],
          [
            105.82114052789562,
            21.007372436701715
          ],
          [
            105.82104530947583,
            21.007286050949048
          ],
          [
            105.82092058675295,
            21.007243484038163
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35",
        "diemDau": "09",
        "diemCuoi": "9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.00746429976513,
        "to_latitude": 21.007243484038163,
        "to_longitude": 105.82092058675295,
        "from_longitude": 105.8210515120105,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1102,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82066845910845,
          21.007065704454874,
          105.82092058675295,
          21.007243484038163
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82092058675295,
            21.007243484038163
          ],
          [
            105.82066845910845,
            21.007065704454874
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35",
        "diemDau": "9-1",
        "diemCuoi": "9-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.007243484038163,
        "to_latitude": 21.007065704454874,
        "to_longitude": 105.82066845910845,
        "from_longitude": 105.82092058675295,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1103,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83536662921833,
          21.01072547982355,
          105.83561875687184,
          21.010970859651867
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83536662921833,
            21.010970859651867
          ],
          [
            105.83561875687184,
            21.01072547982355
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Phạm Ngọc Thạch",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.010970859651867,
        "to_latitude": 21.01072547982355,
        "to_longitude": 105.83561875687184,
        "from_longitude": 105.83536662921833,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1104,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8354873286296,
          21.01060278975846,
          105.83561875687184,
          21.01072547982355
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83561875687184,
            21.01072547982355
          ],
          [
            105.8354873286296,
            21.01060278975846
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Phạm Ngọc Thạch",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.01072547982355,
        "to_latitude": 21.01060278975846,
        "to_longitude": 105.8354873286296,
        "from_longitude": 105.83561875687184,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1105,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8354873286296,
          21.010472587945735,
          105.83559729919195,
          21.01060278975846
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8354873286296,
            21.01060278975846
          ],
          [
            105.83559729919195,
            21.010472587945735
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Phạm Ngọc Thạch",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.01060278975846,
        "to_latitude": 21.010472587945735,
        "to_longitude": 105.83559729919195,
        "from_longitude": 105.8354873286296,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1106,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83561875687184,
          21.01072547982355,
          105.83587088451637,
          21.010848169788567
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83561875687184,
            21.01072547982355
          ],
          [
            105.83587088451637,
            21.010848169788567
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Phạm Ngọc Thạch",
        "diemDau": "13",
        "diemCuoi": "13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.01072547982355,
        "to_latitude": 21.010848169788567,
        "to_longitude": 105.83587088451637,
        "from_longitude": 105.83561875687184,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1107,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83587088451637,
          21.010848169788567,
          105.83608277902682,
          21.01099089064014
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83587088451637,
            21.010848169788567
          ],
          [
            105.83608277902682,
            21.01099089064014
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Phạm Ngọc Thạch",
        "diemDau": "13-1",
        "diemCuoi": "13-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.010848169788567,
        "to_latitude": 21.01099089064014,
        "to_longitude": 105.83608277902682,
        "from_longitude": 105.83587088451637,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1108,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83608277902682,
          21.01099089064014,
          105.83636441097448,
          21.011181184897605
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83608277902682,
            21.01099089064014
          ],
          [
            105.83636441097448,
            21.011181184897605
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Phạm Ngọc Thạch",
        "diemDau": "13-3",
        "diemCuoi": "13-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.01099089064014,
        "to_latitude": 21.011181184897605,
        "to_longitude": 105.83636441097448,
        "from_longitude": 105.83608277902682,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1109,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83514400586795,
          21.01117116941616,
          105.83546855316642,
          21.011293859014618
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83514400586795,
            21.011233766162906
          ],
          [
            105.83523788318385,
            21.01117116941616
          ],
          [
            105.83546855316642,
            21.011293859014618
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Phạm Ngọc Thạch",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.011233766162906,
        "to_latitude": 21.011293859014618,
        "to_longitude": 105.83546855316642,
        "from_longitude": 105.83514400586795,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1110,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83495390430663,
          21.01139996039635,
          105.83505515772526,
          21.01145880124726
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83505515772526,
            21.01145880124726
          ],
          [
            105.83495390430663,
            21.01139996039635
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46/21 Phạm Ngọc Thạch",
        "diemDau": "",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.01145880124726,
        "to_latitude": 21.01139996039635,
        "to_longitude": 105.83495390430663,
        "from_longitude": 105.83505515772526,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1111,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83475139752332,
          21.01124346865624,
          105.83495390430663,
          21.01139996039635
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83495390430663,
            21.01139996039635
          ],
          [
            105.83475139752332,
            21.01124346865624
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46/21 Phạm Ngọc Thạch",
        "diemDau": "5-1",
        "diemCuoi": "5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.01139996039635,
        "to_latitude": 21.01124346865624,
        "to_longitude": 105.83475139752332,
        "from_longitude": 105.83495390430663,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1112,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83580416456512,
          21.010848169788567,
          105.83592084065579,
          21.011058182222158
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83587088451637,
            21.010848169788567
          ],
          [
            105.83592084065579,
            21.01091796530929
          ],
          [
            105.83580416456512,
            21.011058182222158
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Phạm Ngọc Thạch",
        "diemDau": "13-1",
        "diemCuoi": "13-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.010848169788567,
        "to_latitude": 21.011058182222158,
        "to_longitude": 105.83580416456512,
        "from_longitude": 105.83587088451637,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1113,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83572570997518,
          21.011298553763307,
          105.8359563799488,
          21.011553948107892
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83572570997518,
            21.011553948107892
          ],
          [
            105.8359563799488,
            21.011298553763307
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.011553948107892,
        "to_latitude": 21.011298553763307,
        "to_longitude": 105.8359563799488,
        "from_longitude": 105.83572570997518,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1114,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83589468914379,
          21.01122093382768,
          105.83598856645966,
          21.011298553763307
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8359563799488,
            21.011298553763307
          ],
          [
            105.83598856645966,
            21.011263499603707
          ],
          [
            105.83592955786239,
            21.01122093382768
          ],
          [
            105.83589468914379,
            21.011248476390428
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.011298553763307,
        "to_latitude": 21.011248476390428,
        "to_longitude": 105.83589468914379,
        "from_longitude": 105.8359563799488,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1115,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8359563799488,
          21.011298553763307,
          105.83609853703098,
          21.011408723925655
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8359563799488,
            21.011298553763307
          ],
          [
            105.83609853703098,
            21.011408723925655
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.011298553763307,
        "to_latitude": 21.011408723925655,
        "to_longitude": 105.83609853703098,
        "from_longitude": 105.8359563799488,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1116,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83609853703098,
          21.011408723925655,
          105.83623801189646,
          21.011516390140724
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83609853703098,
            21.011408723925655
          ],
          [
            105.83623801189646,
            21.011516390140724
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.011408723925655,
        "to_latitude": 21.011516390140724,
        "to_longitude": 105.83623801189646,
        "from_longitude": 105.83609853703098,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1117,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83623801189646,
          21.01149635922302,
          105.8364043088573,
          21.01158399446976
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83623801189646,
            21.011516390140724
          ],
          [
            105.83632116038137,
            21.01158399446976
          ],
          [
            105.8364043088573,
            21.01149635922302
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.011516390140724,
        "to_latitude": 21.01149635922302,
        "to_longitude": 105.8364043088573,
        "from_longitude": 105.83623801189646,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1118,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81972050808271,
          21.009055018925388,
          105.81986899307262,
          21.00914974986986
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81972050808271,
            21.009055018925388
          ],
          [
            105.81986899307262,
            21.00914974986986
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà G1.3",
        "diemDau": "13",
        "diemCuoi": "13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.009055018925388,
        "to_latitude": 21.00914974986986,
        "to_longitude": 105.81986899307262,
        "from_longitude": 105.81972050808271,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1119,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81986899307262,
          21.00914974986986,
          105.82002590229739,
          21.009256165765485
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81986899307262,
            21.00914974986986
          ],
          [
            105.82002590229739,
            21.009256165765485
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà G1.3",
        "diemDau": "14-1",
        "diemCuoi": "14-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.00914974986986,
        "to_latitude": 21.009256165765485,
        "to_longitude": 105.82002590229739,
        "from_longitude": 105.81986899307262,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1120,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81940983251913,
          21.008901080662618,
          105.81972050808271,
          21.009055018925388
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81972050808271,
            21.009055018925388
          ],
          [
            105.81960295156637,
            21.009042551424475
          ],
          [
            105.81956271843228,
            21.009016260407982
          ],
          [
            105.81951309756211,
            21.00897870180529
          ],
          [
            105.81945811228545,
            21.008936135377315
          ],
          [
            105.81940983251913,
            21.008901080662618
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51",
        "diemDau": "13",
        "diemCuoi": "13-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.009055018925388,
        "to_latitude": 21.008901080662618,
        "to_longitude": 105.81940983251913,
        "from_longitude": 105.81972050808271,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1121,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81914965824238,
          21.008703271761696,
          105.81940983251913,
          21.008901080662604
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81940983251913,
            21.008901080662604
          ],
          [
            105.81914965824238,
            21.008703271761696
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51",
        "diemDau": "13-3",
        "diemCuoi": "13-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.008901080662604,
        "to_latitude": 21.008703271761696,
        "to_longitude": 105.81914965824238,
        "from_longitude": 105.81940983251913,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1122,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8189002128056,
          21.008522990003367,
          105.81914965824238,
          21.008703271761696
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81914965824238,
            21.008703271761696
          ],
          [
            105.8189002128056,
            21.008522990003367
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51",
        "diemDau": "13-4",
        "diemCuoi": "13-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.008703271761696,
        "to_latitude": 21.008522990003367,
        "to_longitude": 105.8189002128056,
        "from_longitude": 105.81914965824238,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1123,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.818669542832,
          21.00827134635094,
          105.8189002128056,
          21.008522990003367
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8189002128056,
            21.008522990003367
          ],
          [
            105.8188479097277,
            21.008454132329838
          ],
          [
            105.81878756002654,
            21.0083902824588
          ],
          [
            105.81872855142927,
            21.00833018843751
          ],
          [
            105.818669542832,
            21.00827134635094
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51",
        "diemDau": "13-5",
        "diemCuoi": "13-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.008522990003367,
        "to_latitude": 21.00827134635094,
        "to_longitude": 105.818669542832,
        "from_longitude": 105.8189002128056,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1124,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81867624833339,
          21.0096572591099,
          105.81905608599693,
          21.00985163842507
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81905608599693,
            21.00985163842507
          ],
          [
            105.81892301156242,
            21.00981750835442
          ],
          [
            105.81885863854966,
            21.0097549110134
          ],
          [
            105.81876207902604,
            21.0096572591099
          ],
          [
            105.8187191636842,
            21.009689809751215
          ],
          [
            105.81867624833339,
            21.0096572591099
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 51,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 63",
        "diemDau": "16",
        "diemCuoi": "16-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.00985163842507,
        "to_latitude": 21.0096572591099,
        "to_longitude": 105.81867624833339,
        "from_longitude": 105.81905608599693,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1125,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83635368213453,
          21.0263363411993,
          105.8365414367663,
          21.026551651997746
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8365414367663,
            21.026551651997746
          ],
          [
            105.83635368213453,
            21.0263363411993
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "diemDau": "3-1",
        "diemCuoi": "3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.026551651997746,
        "to_latitude": 21.0263363411993,
        "to_longitude": 105.83635368213453,
        "from_longitude": 105.8365414367663,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1126,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83619811400463,
          21.026196138649574,
          105.83635368213453,
          21.0263363411993
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83635368213453,
            21.0263363411993
          ],
          [
            105.83622426556155,
            21.026196138649574
          ],
          [
            105.83619811400463,
            21.026208656741993
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "diemDau": "3-2",
        "diemCuoi": "3-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.0263363411993,
        "to_latitude": 21.026208656741993,
        "to_longitude": 105.83619811400463,
        "from_longitude": 105.83635368213453,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1127,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83615251645504,
          21.02610100113391,
          105.83619811400463,
          21.026208656741993
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83619811400463,
            21.026208656741993
          ],
          [
            105.83619342015456,
            21.026154828944733
          ],
          [
            105.83615251645504,
            21.02610100113391
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "diemDau": "3-4",
        "diemCuoi": "3-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.026208656741993,
        "to_latitude": 21.02610100113391,
        "to_longitude": 105.83615251645504,
        "from_longitude": 105.83619811400463,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1128,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83603986367598,
          21.025885689684507,
          105.83615251645504,
          21.02610100113391
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83615251645504,
            21.02610100113391
          ],
          [
            105.83603986367598,
            21.025885689684507
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "diemDau": "3-6",
        "diemCuoi": "3-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02610100113391,
        "to_latitude": 21.025885689684507,
        "to_longitude": 105.83603986367598,
        "from_longitude": 105.83615251645504,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1129,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83603986367598,
          21.025780537467938,
          105.83620616063685,
          21.025885689684507
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83603986367598,
            21.025885689684507
          ],
          [
            105.83620616063685,
            21.025780537467938
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "diemDau": "3-7",
        "diemCuoi": "3-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025885689684507,
        "to_latitude": 21.025780537467938,
        "to_longitude": 105.83620616063685,
        "from_longitude": 105.83603986367598,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1130,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83586015567643,
          21.026159836182373,
          105.83619811400463,
          21.02623118930146
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83619811400463,
            21.026208656741993
          ],
          [
            105.83607406185614,
            21.026159836182373
          ],
          [
            105.83586015567643,
            21.02623118930146
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "diemDau": "3-4",
        "diemCuoi": "3-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.026208656741993,
        "to_latitude": 21.02623118930146,
        "to_longitude": 105.83586015567643,
        "from_longitude": 105.83619811400463,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1131,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83609082565003,
          21.026148569900577,
          105.83619811400463,
          21.026208656741993
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83619811400463,
            21.026208656741993
          ],
          [
            105.83609082565003,
            21.026148569900577
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "diemDau": "3-4",
        "diemCuoi": "3-4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.026208656741993,
        "to_latitude": 21.026148569900577,
        "to_longitude": 105.83609082565003,
        "from_longitude": 105.83619811400463,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1132,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83620951341,
          21.02632632673321,
          105.83635368213453,
          21.026435233985815
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83635368213453,
            21.0263363411993
          ],
          [
            105.83632350729292,
            21.02632632673321
          ],
          [
            105.83620951341,
            21.026435233985815
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "diemDau": "3-2",
        "diemCuoi": "3-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.0263363411993,
        "to_latitude": 21.026435233985815,
        "to_longitude": 105.83620951341,
        "from_longitude": 105.83635368213453,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1133,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83653674290723,
          21.026456514706503,
          105.83659776314684,
          21.026551651997746
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8365414367663,
            21.026551651997746
          ],
          [
            105.83653674290723,
            21.02650408335718
          ],
          [
            105.83659776314684,
            21.026456514706503
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "diemDau": "3-1",
        "diemCuoi": "3-1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.026551651997746,
        "to_latitude": 21.026456514706503,
        "to_longitude": 105.83659776314684,
        "from_longitude": 105.8365414367663,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1134,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83599761889957,
          21.025885689684507,
          105.83605126307236,
          21.026018381660894
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83603986367598,
            21.025885689684507
          ],
          [
            105.83605126307236,
            21.025943273007382
          ],
          [
            105.83599761889957,
            21.025970812850133
          ],
          [
            105.83601572379732,
            21.026018381660894
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "diemDau": "3-7",
        "diemCuoi": "3-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025885689684507,
        "to_latitude": 21.026018381660894,
        "to_longitude": 105.83601572379732,
        "from_longitude": 105.83603986367598,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1135,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83590038881051,
          21.026018381660894,
          105.83601572379732,
          21.026053432349546
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83601572379732,
            21.026018381660894
          ],
          [
            105.83592519925459,
            21.026053432349546
          ],
          [
            105.83590038881051,
            21.026035907007504
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "diemDau": "3-10",
        "diemCuoi": "3-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.026018381660894,
        "to_latitude": 21.026035907007504,
        "to_longitude": 105.83590038881051,
        "from_longitude": 105.83601572379732,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1136,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83569184706425,
          21.026035907007504,
          105.83590038881051,
          21.026157332563987
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83590038881051,
            21.026035907007504
          ],
          [
            105.83569184706425,
            21.026157332563987
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "diemDau": "3-11",
        "diemCuoi": "3-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.026035907007504,
        "to_latitude": 21.026157332563987,
        "to_longitude": 105.83569184706425,
        "from_longitude": 105.83590038881051,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1137,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8361954317969,
          21.025650348907615,
          105.83620616063685,
          21.025780537467938
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83620616063685,
            21.025780537467938
          ],
          [
            105.8361954317969,
            21.025650348907615
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "diemDau": "3-8",
        "diemCuoi": "3-8-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025780537467938,
        "to_latitude": 21.025650348907615,
        "to_longitude": 105.8361954317969,
        "from_longitude": 105.83620616063685,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1138,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83620616063685,
          21.025689468078777,
          105.83626416340846,
          21.025780537467938
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83620616063685,
            21.025780537467938
          ],
          [
            105.83626416340846,
            21.025689468078777
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "diemDau": "3-8",
        "diemCuoi": "3-9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025780537467938,
        "to_latitude": 21.025689468078777,
        "to_longitude": 105.83626416340846,
        "from_longitude": 105.83620616063685,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1139,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83623298272323,
          21.025617801749146,
          105.83626416340846,
          21.025689468078777
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83626416340846,
            21.025689468078777
          ],
          [
            105.83623298272323,
            21.025617801749146
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "diemDau": "3-9-1",
        "diemCuoi": "3-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025689468078777,
        "to_latitude": 21.025617801749146,
        "to_longitude": 105.83623298272323,
        "from_longitude": 105.83626416340846,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1140,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83623298272323,
          21.025502634825525,
          105.83629534409367,
          21.025617801749146
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83623298272323,
            21.025617801749146
          ],
          [
            105.83629534409367,
            21.025563973739363
          ],
          [
            105.83626516923412,
            21.025502634825525
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "diemDau": "3-9",
        "diemCuoi": "3-9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025617801749146,
        "to_latitude": 21.025502634825525,
        "to_longitude": 105.83626516923412,
        "from_longitude": 105.83623298272323,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1141,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83609652534372,
          21.025595582051583,
          105.83620616063685,
          21.025780537467938
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83620616063685,
            21.025780537467938
          ],
          [
            105.83618269131459,
            21.025776782027858
          ],
          [
            105.83609652534372,
            21.025595582051583
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "diemDau": "3-8",
        "diemCuoi": "3-8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025780537467938,
        "to_latitude": 21.025595582051583,
        "to_longitude": 105.83609652534372,
        "from_longitude": 105.83620616063685,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1142,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8356814535205,
          21.02695598480526,
          105.83605662749683,
          21.02711120825527
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83605662749683,
            21.02695598480526
          ],
          [
            105.83575622008598,
            21.02711120825527
          ],
          [
            105.8356814535205,
            21.02699322592945
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 54,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 59 Quốc Tử Giám",
        "diemDau": "01",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02695598480526,
        "to_latitude": 21.02699322592945,
        "to_longitude": 105.8356814535205,
        "from_longitude": 105.83605662749683,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1143,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83582361060267,
          21.02674787246597,
          105.83605662749683,
          21.026971006436067
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83605662749683,
            21.02695598480526
          ],
          [
            105.83596275018095,
            21.026971006436067
          ],
          [
            105.83582361060267,
            21.02674787246597
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51 Quốc Tủ Giám",
        "diemDau": "01",
        "diemCuoi": "1-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02695598480526,
        "to_latitude": 21.02674787246597,
        "to_longitude": 105.83582361060267,
        "from_longitude": 105.83605662749683,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1144,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83595202134099,
          21.02660266308324,
          105.83602745848091,
          21.02677040494394
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83602745848091,
            21.02677040494394
          ],
          [
            105.83595202134099,
            21.026633019367054
          ],
          [
            105.83598454313906,
            21.02660266308324
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Quốc Tử Giám",
        "diemDau": "1-3",
        "diemCuoi": "1-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02677040494394,
        "to_latitude": 21.02660266308324,
        "to_longitude": 105.83598454313906,
        "from_longitude": 105.83602745848091,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1145,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83582897501816,
          21.026432417420033,
          105.83598454313906,
          21.02660266308324
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83598454313906,
            21.02660266308324
          ],
          [
            105.83582897501816,
            21.026432417420033
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Quốc Tử Giám",
        "diemDau": "1-4",
        "diemCuoi": "1-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02660266308324,
        "to_latitude": 21.026432417420033,
        "to_longitude": 105.83582897501816,
        "from_longitude": 105.83598454313906,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1146,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83602745848091,
          21.02677040494394,
          105.83614748733574,
          21.02709399598572
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83614748733574,
            21.02709399598572
          ],
          [
            105.83605662749683,
            21.02695598480526
          ],
          [
            105.83610490725415,
            21.026923437931856
          ],
          [
            105.83602745848091,
            21.02677040494394
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Quốc Tử Giám",
        "diemDau": "01",
        "diemCuoi": "1-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02709399598572,
        "to_latitude": 21.02677040494394,
        "to_longitude": 105.83602745848091,
        "from_longitude": 105.83614748733574,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1147,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83614748733574,
          21.02693126170093,
          105.83643180149114,
          21.02709399598572
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83614748733574,
            21.02709399598572
          ],
          [
            105.83643180149114,
            21.02693126170093
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quốc Tử Giám",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02709399598572,
        "to_latitude": 21.02693126170093,
        "to_longitude": 105.83643180149114,
        "from_longitude": 105.83614748733574,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1148,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83643180149114,
          21.02675754740719,
          105.83677467222952,
          21.02693126170093
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83643180149114,
            21.02693126170093
          ],
          [
            105.83677467222952,
            21.02675754740719
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quốc Tử Giám",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02693126170093,
        "to_latitude": 21.02675754740719,
        "to_longitude": 105.83677467222952,
        "from_longitude": 105.83643180149114,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1149,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83677467222952,
          21.026635835929888,
          105.83703529852056,
          21.02675754740719
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83677467222952,
            21.02675754740719
          ],
          [
            105.83703529852056,
            21.026635835929888
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quốc Tử Giám",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02675754740719,
        "to_latitude": 21.026635835929888,
        "to_longitude": 105.83703529852056,
        "from_longitude": 105.83677467222952,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1150,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83703529852056,
          21.026480611984816,
          105.83730083721278,
          21.026635835929888
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83703529852056,
            21.026635835929888
          ],
          [
            105.83730083721278,
            21.026480611984816
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quốc Tử Giám",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.026635835929888,
        "to_latitude": 21.026480611984816,
        "to_longitude": 105.83730083721278,
        "from_longitude": 105.83703529852056,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1151,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83730083721278,
          21.026360796156244,
          105.83754606775425,
          21.026480611984816
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83730083721278,
            21.026480611984816
          ],
          [
            105.83754606775425,
            21.026360796156244
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quốc Tử Giám",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.026480611984816,
        "to_latitude": 21.026360796156244,
        "to_longitude": 105.83754606775425,
        "from_longitude": 105.83730083721278,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1152,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83754606775425,
          21.026214155757557,
          105.83782578383796,
          21.026360796156244
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83754606775425,
            21.026360796156244
          ],
          [
            105.83782578383796,
            21.026214155757557
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quốc Tử Giám",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.026360796156244,
        "to_latitude": 21.026214155757557,
        "to_longitude": 105.83782578383796,
        "from_longitude": 105.83754606775425,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1153,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83782578383796,
          21.026089734841825,
          105.83811622875261,
          21.026214155757557
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83782578383796,
            21.026214155757557
          ],
          [
            105.83806828425556,
            21.026089734841825
          ],
          [
            105.83811622875261,
            21.026102565894185
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quốc Tử Giám",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.026214155757557,
        "to_latitude": 21.026102565894185,
        "to_longitude": 105.83811622875261,
        "from_longitude": 105.83782578383796,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1154,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83666280673398,
          21.02623118930063,
          105.83736755716406,
          21.02663051575662
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83666280673398,
            21.02663051575662
          ],
          [
            105.83700042979297,
            21.02642428067358
          ],
          [
            105.83736755716406,
            21.02623118930063
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 90,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 23+25 Quốc Tử Giám",
        "diemDau": "03",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02663051575662,
        "to_latitude": 21.02623118930063,
        "to_longitude": 105.83736755716406,
        "from_longitude": 105.83666280673398,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1155,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83688710647736,
          21.026269682414554,
          105.83700042979297,
          21.026424280673567
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83700042979297,
            21.026424280673567
          ],
          [
            105.83688710647736,
            21.026269682414554
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "diemDau": "4",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.026424280673567,
        "to_latitude": 21.026269682414554,
        "to_longitude": 105.83688710647736,
        "from_longitude": 105.83700042979297,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1156,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83677043038476,
          21.026124472566057,
          105.83688710647736,
          21.026269682414554
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83688710647736,
            21.026269682414554
          ],
          [
            105.83677043038476,
            21.026124472566057
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.026269682414554,
        "to_latitude": 21.026124472566057,
        "to_longitude": 105.83677043038476,
        "from_longitude": 105.83688710647736,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1157,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83666582423791,
          21.02601180965474,
          105.83677043038476,
          21.026124472566057
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83677043038476,
            21.026124472566057
          ],
          [
            105.83666582423791,
            21.02601180965474
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "diemDau": "4-2",
        "diemCuoi": "4-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.026124472566057,
        "to_latitude": 21.02601180965474,
        "to_longitude": 105.83666582423791,
        "from_longitude": 105.83677043038476,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1158,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83657194692202,
          21.025871606802276,
          105.83666582423791,
          21.02601180965474
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83666582423791,
            21.02601180965474
          ],
          [
            105.83657194692202,
            21.025871606802276
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "diemDau": "4-3",
        "diemCuoi": "4-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02601180965474,
        "to_latitude": 21.025871606802276,
        "to_longitude": 105.83657194692202,
        "from_longitude": 105.83666582423791,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1159,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83645124751077,
          21.02571638206134,
          105.83657194692202,
          21.025871606802276
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83657194692202,
            21.025871606802276
          ],
          [
            105.83645124751077,
            21.02571638206134
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "diemDau": "4-4",
        "diemCuoi": "4-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025871606802276,
        "to_latitude": 21.02571638206134,
        "to_longitude": 105.83645124751077,
        "from_longitude": 105.83657194692202,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1160,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83636273461931,
          21.02561373335372,
          105.83645124751077,
          21.02572232817095
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83645124751077,
            21.02571638206134
          ],
          [
            105.83642677233591,
            21.02572232817095
          ],
          [
            105.83636273461931,
            21.02561373335372
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "diemDau": "4-5",
        "diemCuoi": "4-5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02571638206134,
        "to_latitude": 21.02561373335372,
        "to_longitude": 105.83636273461931,
        "from_longitude": 105.83645124751077,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1161,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83645124751077,
          21.025631258747925,
          105.8365960868007,
          21.02571638206134
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83645124751077,
            21.02571638206134
          ],
          [
            105.83649248646152,
            21.025672255641712
          ],
          [
            105.8365960868007,
            21.025631258747925
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "diemDau": "4-5",
        "diemCuoi": "4-5-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02571638206134,
        "to_latitude": 21.025631258747925,
        "to_longitude": 105.8365960868007,
        "from_longitude": 105.83645124751077,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1162,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83642945455263,
          21.025583689816177,
          105.83650757390028,
          21.02571638206134
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83645124751077,
            21.02571638206134
          ],
          [
            105.83645359443129,
            21.025688529215483
          ],
          [
            105.83642945455263,
            21.02565097481163
          ],
          [
            105.83650757390028,
            21.025583689816177
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "diemDau": "4-5",
        "diemCuoi": "4-5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02571638206134,
        "to_latitude": 21.025583689816177,
        "to_longitude": 105.83650757390028,
        "from_longitude": 105.83645124751077,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1163,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83654881285105,
          21.02578147632731,
          105.83657194692202,
          21.025871606802276
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83657194692202,
            21.025871606802276
          ],
          [
            105.83654881285105,
            21.025813710494198
          ],
          [
            105.83655317145885,
            21.02578147632731
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "diemDau": "4-4",
        "diemCuoi": "4-4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025871606802276,
        "to_latitude": 21.02578147632731,
        "to_longitude": 105.83655317145885,
        "from_longitude": 105.83657194692202,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1164,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83655317145885,
          21.025736411068984,
          105.83669264632431,
          21.02578147632731
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83655317145885,
            21.02578147632731
          ],
          [
            105.83669264632431,
            21.025736411068984
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "diemDau": "4-4-1",
        "diemCuoi": "4-4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02578147632731,
        "to_latitude": 21.025736411068984,
        "to_longitude": 105.83669264632431,
        "from_longitude": 105.83655317145885,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1165,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83637882787477,
          21.025871606802276,
          105.83657194692202,
          21.025969248088465
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83657194692202,
            21.025871606802276
          ],
          [
            105.83637882787477,
            21.025969248088465
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "diemDau": "4-4",
        "diemCuoi": "4-4-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025871606802276,
        "to_latitude": 21.025969248088465,
        "to_longitude": 105.83637882787477,
        "from_longitude": 105.83657194692202,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1166,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83651830274023,
          21.025871606802276,
          105.83658099936193,
          21.025969248088465
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83657194692202,
            21.025871606802276
          ],
          [
            105.83658099936193,
            21.025927625366784
          ],
          [
            105.83651830274023,
            21.025969248088465
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "diemDau": "4-4",
        "diemCuoi": "4-4-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025871606802276,
        "to_latitude": 21.025969248088465,
        "to_longitude": 105.83651830274023,
        "from_longitude": 105.83657194692202,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1167,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83657731133749,
          21.02601180965474,
          105.83666582423791,
          21.026101939990436
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83666582423791,
            21.02601180965474
          ],
          [
            105.83665744231851,
            21.02604529558238
          ],
          [
            105.83657731133749,
            21.026101939990436
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "diemDau": "4-3",
        "diemCuoi": "4-3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02601180965474,
        "to_latitude": 21.026101939990436,
        "to_longitude": 105.83657731133749,
        "from_longitude": 105.83666582423791,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1168,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83677043038476,
          21.02607440017108,
          105.83683212119875,
          21.026124472566057
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83677043038476,
            21.026124472566057
          ],
          [
            105.83677411840918,
            21.02610037522594
          ],
          [
            105.83683212119875,
            21.02607440017108
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "diemDau": "4-2",
        "diemCuoi": "4-2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.026124472566057,
        "to_latitude": 21.02607440017108,
        "to_longitude": 105.83683212119875,
        "from_longitude": 105.83677043038476,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1169,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83668728190881,
          21.026180491035532,
          105.83688710647736,
          21.026269682414554
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83688710647736,
            21.026269682414554
          ],
          [
            105.83677143620143,
            21.026180491035532
          ],
          [
            105.83668728190881,
            21.026217106451032
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "diemDau": "4-1",
        "diemCuoi": "4-1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.026269682414554,
        "to_latitude": 21.026217106451032,
        "to_longitude": 105.83668728190881,
        "from_longitude": 105.83688710647736,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1170,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83716773259744,
          21.026132296376236,
          105.83723036982376,
          21.026303343285118
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83723036982376,
            21.026303343285118
          ],
          [
            105.83717510865526,
            21.026191757317413
          ],
          [
            105.83716773259744,
            21.026132296376236
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 23+25 Quốc Tử Giám",
        "diemDau": "5-1",
        "diemCuoi": "5-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.026303343285118,
        "to_latitude": 21.026132296376236,
        "to_longitude": 105.83716773259744,
        "from_longitude": 105.83723036982376,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1171,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83736755716406,
          21.026044669679198,
          105.83746881053776,
          21.02623118930063
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83736755716406,
            21.02623118930063
          ],
          [
            105.83746881053776,
            21.026179239226103
          ],
          [
            105.8373823093156,
            21.026044669679198
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 17 Quốc Tử Giám",
        "diemDau": "5-1",
        "diemCuoi": "5-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02623118930063,
        "to_latitude": 21.026044669679198,
        "to_longitude": 105.8373823093156,
        "from_longitude": 105.83736755716406,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1172,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83724551665789,
          21.02594702844245,
          105.83736755716406,
          21.02623118930063
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83736755716406,
            21.02623118930063
          ],
          [
            105.83734945223934,
            21.02616546932419
          ],
          [
            105.83725959823506,
            21.026012748510972
          ],
          [
            105.83724551665789,
            21.02594702844245
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 23+25 Quốc Tử Giám",
        "diemDau": "5-1",
        "diemCuoi": "5-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02623118930063,
        "to_latitude": 21.02594702844245,
        "to_longitude": 105.83724551665789,
        "from_longitude": 105.83736755716406,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1173,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83719991910831,
          21.025745486712594,
          105.83724551665789,
          21.02594702844245
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83724551665789,
            21.02594702844245
          ],
          [
            105.83719991910831,
            21.025745486712594
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 23+25 Quốc Tử Giám",
        "diemDau": "5-2",
        "diemCuoi": "5-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02594702844245,
        "to_latitude": 21.025745486712594,
        "to_longitude": 105.83719991910831,
        "from_longitude": 105.83724551665789,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1174,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83696120250248,
          21.025741105365498,
          105.83719991910831,
          21.025824350899686
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83719991910831,
            21.025745486712594
          ],
          [
            105.83715096877658,
            21.025741105365498
          ],
          [
            105.83696120250248,
            21.025824350899686
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 23+25 Quốc Tử Giám",
        "diemDau": "5-3",
        "diemCuoi": "5-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025745486712594,
        "to_latitude": 21.025824350899686,
        "to_longitude": 105.83696120250248,
        "from_longitude": 105.83719991910831,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1175,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83807700145316,
          21.025891948742213,
          105.83811622875261,
          21.026102565894185
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83811622875261,
            21.026102565894185
          ],
          [
            105.83807700145316,
            21.025891948742213
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.026102565894185,
        "to_latitude": 21.025891948742213,
        "to_longitude": 105.83807700145316,
        "from_longitude": 105.83811622875261,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1176,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83792813885162,
          21.025646593465932,
          105.83807700145316,
          21.025891948742213
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83807700145316,
            21.025891948742213
          ],
          [
            105.83792813885162,
            21.025646593465932
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025891948742213,
        "to_latitude": 21.025646593465932,
        "to_longitude": 105.83792813885162,
        "from_longitude": 105.83807700145316,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1177,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83777491764228,
          21.025446616097916,
          105.83792813885162,
          21.025646593465932
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83792813885162,
            21.025646593465932
          ],
          [
            105.83777491764228,
            21.025446616097916
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025646593465932,
        "to_latitude": 21.025446616097916,
        "to_longitude": 105.83777491764228,
        "from_longitude": 105.83792813885162,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1178,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83747752773537,
          21.02544004406827,
          105.83777491764228,
          21.025446616097916
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83777491764228,
            21.025446616097916
          ],
          [
            105.83766058849012,
            21.025443173604398
          ],
          [
            105.83747752773537,
            21.02544004406827
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025446616097916,
        "to_latitude": 21.02544004406827,
        "to_longitude": 105.83747752773537,
        "from_longitude": 105.83777491764228,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1179,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83725222217726,
          21.02544004406827,
          105.83747752773537,
          21.02544379951431
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83747752773537,
            21.02544004406827
          ],
          [
            105.83725222217726,
            21.02544379951431
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02544004406827,
        "to_latitude": 21.02544379951431,
        "to_longitude": 105.83725222217726,
        "from_longitude": 105.83747752773537,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1180,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83719857799545,
          21.025190932595834,
          105.83725222217726,
          21.02544379951431
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83725222217726,
            21.02544379951431
          ],
          [
            105.83719857799545,
            21.025190932595834
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02544379951431,
        "to_latitude": 21.025190932595834,
        "to_longitude": 105.83719857799545,
        "from_longitude": 105.83725222217726,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1181,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83707251417768,
          21.024923043411864,
          105.83719857799545,
          21.025190932595834
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83719857799545,
            21.025190932595834
          ],
          [
            105.83707251417768,
            21.024923043411864
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "14",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025190932595834,
        "to_latitude": 21.024923043411864,
        "to_longitude": 105.83707251417768,
        "from_longitude": 105.83719857799545,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1182,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83688475954591,
          21.024861704232578,
          105.83707251417768,
          21.024923043411864
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83707251417768,
            21.024923043411864
          ],
          [
            105.83688475954591,
            21.024861704232578
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "16",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.024923043411864,
        "to_latitude": 21.024861704232578,
        "to_longitude": 105.83688475954591,
        "from_longitude": 105.83707251417768,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1183,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83651059138624,
          21.024861704232578,
          105.83688475954591,
          21.024979375289583
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83688475954591,
            21.024861704232578
          ],
          [
            105.83651059138624,
            21.024979375289583
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.024861704232578,
        "to_latitude": 21.024979375289583,
        "to_longitude": 105.83651059138624,
        "from_longitude": 105.83688475954591,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1184,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83642207848582,
          21.024692708402075,
          105.83651059138624,
          21.024979375289583
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83651059138624,
            21.024979375289583
          ],
          [
            105.83642207848582,
            21.024692708402075
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.024979375289583,
        "to_latitude": 21.024692708402075,
        "to_longitude": 105.83642207848582,
        "from_longitude": 105.83651059138624,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1185,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83683480339751,
          21.02477251201188,
          105.83688475954591,
          21.024861704232578
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83688475954591,
            21.024861704232578
          ],
          [
            105.83683480339751,
            21.02477251201188
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "22",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.024861704232578,
        "to_latitude": 21.02477251201188,
        "to_longitude": 105.83683480339751,
        "from_longitude": 105.83688475954591,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1186,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83675433712932,
          21.024577227806947,
          105.83683480339751,
          21.02477251201188
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83683480339751,
            21.02477251201188
          ],
          [
            105.83675433712932,
            21.024577227806947
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02477251201188,
        "to_latitude": 21.024577227806947,
        "to_longitude": 105.83675433712932,
        "from_longitude": 105.83683480339751,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1187,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83671812728889,
          21.024431390398046,
          105.83675433712932,
          21.024577227806947
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83675433712932,
            21.024577227806947
          ],
          [
            105.83671812728889,
            21.024500240694938
          ],
          [
            105.83672080949661,
            21.024431390398046
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.024577227806947,
        "to_latitude": 21.024431390398046,
        "to_longitude": 105.83672080949661,
        "from_longitude": 105.83675433712932,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1188,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83707251417768,
          21.024852628534312,
          105.8371244819684,
          21.024923043411864
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83707251417768,
            21.024923043411864
          ],
          [
            105.8371244819684,
            21.024852628534312
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.024923043411864,
        "to_latitude": 21.024852628534312,
        "to_longitude": 105.8371244819684,
        "from_longitude": 105.83707251417768,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1189,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8371244819684,
          21.024659848078752,
          105.83714593963931,
          21.024852628534312
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8371244819684,
            21.024852628534312
          ],
          [
            105.83714593963931,
            21.024659848078752
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.024852628534312,
        "to_latitude": 21.024659848078752,
        "to_longitude": 105.83714593963931,
        "from_longitude": 105.8371244819684,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1190,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83714593963931,
          21.024294315621056,
          105.83733637647885,
          21.024659848078752
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83714593963931,
            21.024659848078752
          ],
          [
            105.83727334455199,
            21.02448021152038
          ],
          [
            105.83733637647885,
            21.024294315621056
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.024659848078752,
        "to_latitude": 21.024294315621056,
        "to_longitude": 105.83733637647885,
        "from_longitude": 105.83714593963931,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1191,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83698232487723,
          21.024507125722963,
          105.83707251417768,
          21.024923043411864
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83707251417768,
            21.024923043411864
          ],
          [
            105.83698232487723,
            21.024872031748227
          ],
          [
            105.83703328686026,
            21.024507125722963
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 55,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "16",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.024923043411864,
        "to_latitude": 21.024507125722963,
        "to_longitude": 105.83703328686026,
        "from_longitude": 105.83707251417768,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1192,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83699439482106,
          21.024507125722963,
          105.83703328686026,
          21.024662351723116
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83703328686026,
            21.024507125722963
          ],
          [
            105.83701182918035,
            21.024507751634278
          ],
          [
            105.83699439482106,
            21.024655466701414
          ],
          [
            105.8370091469816,
            21.024662351723116
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "31",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.024507125722963,
        "to_latitude": 21.024662351723116,
        "to_longitude": 105.8370091469816,
        "from_longitude": 105.83703328686026,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1193,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83652634935449,
          21.0243869506442,
          105.83672080949661,
          21.024431390398046
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83672080949661,
            21.024431390398046
          ],
          [
            105.83652634935449,
            21.0243869506442
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.024431390398046,
        "to_latitude": 21.0243869506442,
        "to_longitude": 105.83652634935449,
        "from_longitude": 105.83672080949661,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1194,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83672080949661,
          21.024264271817536,
          105.836778477008,
          21.024431390398046
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83672080949661,
            21.024431390398046
          ],
          [
            105.83675299600749,
            21.024423879454
          ],
          [
            105.836778477008,
            21.024264271817536
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "28",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.024431390398046,
        "to_latitude": 21.024264271817536,
        "to_longitude": 105.836778477008,
        "from_longitude": 105.83672080949661,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1195,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83733637647885,
          21.02424924991394,
          105.83747316913656,
          21.024299948833356
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83733637647885,
            21.024294315621056
          ],
          [
            105.83744098261671,
            21.024299948833356
          ],
          [
            105.83747316913656,
            21.02424924991394
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "19",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.024294315621056,
        "to_latitude": 21.02424924991394,
        "to_longitude": 105.83747316913656,
        "from_longitude": 105.83733637647885,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1196,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8373202832234,
          21.024136585585328,
          105.83733637647885,
          21.024294315621056
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83733637647885,
            21.024294315621056
          ],
          [
            105.8373202832234,
            21.024136585585328
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.024294315621056,
        "to_latitude": 21.024136585585328,
        "to_longitude": 105.8373202832234,
        "from_longitude": 105.83733637647885,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1197,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83633222449055,
          21.02443232926676,
          105.83642207848582,
          21.024692708402075
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83642207848582,
            21.024692708402075
          ],
          [
            105.83633222449055,
            21.02443232926676
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.024692708402075,
        "to_latitude": 21.02443232926676,
        "to_longitude": 105.83633222449055,
        "from_longitude": 105.83642207848582,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1198,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83707251417768,
          21.02488267221926,
          105.8372183592843,
          21.024923043411864
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83707251417768,
            21.024923043411864
          ],
          [
            105.83713789299811,
            21.024909586348322
          ],
          [
            105.83721701816245,
            21.024909586348322
          ],
          [
            105.8372183592843,
            21.02488267221926
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "16",
        "diemCuoi": "16-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.024923043411864,
        "to_latitude": 21.02488267221926,
        "to_longitude": 105.8372183592843,
        "from_longitude": 105.83707251417768,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1199,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83714593963931,
          21.02453153627332,
          105.83729614334473,
          21.024659848078752
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83714593963931,
            21.024659848078752
          ],
          [
            105.83725993351328,
            21.02453153627332
          ],
          [
            105.83729614334473,
            21.024552191352104
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "18",
        "diemCuoi": "18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.024659848078752,
        "to_latitude": 21.024552191352104,
        "to_longitude": 105.83729614334473,
        "from_longitude": 105.83714593963931,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1200,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8376451658001,
          21.0252948334115,
          105.83777491764228,
          21.025446616097916
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83777491764228,
            21.025446616097916
          ],
          [
            105.8376451658001,
            21.0252948334115
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "11",
        "diemCuoi": "11-5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025446616097916,
        "to_latitude": 21.0252948334115,
        "to_longitude": 105.8376451658001,
        "from_longitude": 105.83777491764228,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1201,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83750703203849,
          21.025175910784796,
          105.8376451658001,
          21.025305160897087
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8376451658001,
            21.0252948334115
          ],
          [
            105.83760727957755,
            21.025305160897087
          ],
          [
            105.83750703203849,
            21.025175910784796
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "11-5",
        "diemCuoi": "11-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.0252948334115,
        "to_latitude": 21.025175910784796,
        "to_longitude": 105.83750703203849,
        "from_longitude": 105.8376451658001,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1202,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83739169705169,
          21.025009418948997,
          105.83750703203849,
          21.025175910784796
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83750703203849,
            21.025175910784796
          ],
          [
            105.83739169705169,
            21.025009418948997
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "11-3",
        "diemCuoi": "11-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025175910784796,
        "to_latitude": 21.025009418948997,
        "to_longitude": 105.83739169705169,
        "from_longitude": 105.83750703203849,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1203,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83719857799545,
          21.025181856918444,
          105.83728675560866,
          21.025190932595834
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83719857799545,
            21.025190932595834
          ],
          [
            105.83728675560866,
            21.025181856918444
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "14",
        "diemCuoi": "14-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025190932595834,
        "to_latitude": 21.025181856918444,
        "to_longitude": 105.83728675560866,
        "from_longitude": 105.83719857799545,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1204,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.837120793935,
          21.025133348979804,
          105.83719857799545,
          21.025190932595834
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83719857799545,
            21.025190932595834
          ],
          [
            105.837120793935,
            21.025133348979804
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025190932595834,
        "to_latitude": 21.025133348979804,
        "to_longitude": 105.837120793935,
        "from_longitude": 105.83719857799545,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1205,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8367972524532,
          21.025133348979804,
          105.837120793935,
          21.0252087709935
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.837120793935,
            21.025133348979804
          ],
          [
            105.8367972524532,
            21.0252087709935
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "15",
        "diemCuoi": "15-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025133348979804,
        "to_latitude": 21.0252087709935,
        "to_longitude": 105.8367972524532,
        "from_longitude": 105.837120793935,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1206,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83642576651025,
          21.0252087709935,
          105.8367972524532,
          21.02532393814419
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8367972524532,
            21.0252087709935
          ],
          [
            105.83642576651025,
            21.02532393814419
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "15-1",
        "diemCuoi": "15-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.0252087709935,
        "to_latitude": 21.02532393814419,
        "to_longitude": 105.83642576651025,
        "from_longitude": 105.8367972524532,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1207,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83701618778815,
          21.025190932595834,
          105.83719857799545,
          21.02538746781205
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83719857799545,
            21.025190932595834
          ],
          [
            105.8371834905567,
            21.02531517542927
          ],
          [
            105.83701618778815,
            21.02538746781205
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "14",
        "diemCuoi": "14-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025190932595834,
        "to_latitude": 21.02538746781205,
        "to_longitude": 105.83701618778815,
        "from_longitude": 105.83719857799545,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1208,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83681468682148,
          21.02538746781205,
          105.83701618778815,
          21.025438479298476
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83701618778815,
            21.02538746781205
          ],
          [
            105.83681468682148,
            21.025438479298476
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "14-1",
        "diemCuoi": "14-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02538746781205,
        "to_latitude": 21.025438479298476,
        "to_longitude": 105.83681468682148,
        "from_longitude": 105.83701618778815,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1209,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83711811172726,
          21.02544379951431,
          105.83725222217726,
          21.025495123935453
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83725222217726,
            21.02544379951431
          ],
          [
            105.83711811172726,
            21.025495123935453
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "13",
        "diemCuoi": "13-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02544379951431,
        "to_latitude": 21.025495123935453,
        "to_longitude": 105.83711811172726,
        "from_longitude": 105.83725222217726,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1210,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83698634818884,
          21.025495123935453,
          105.83711811172726,
          21.025690406937947
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83711811172726,
            21.025495123935453
          ],
          [
            105.83698634818884,
            21.025550516735343
          ],
          [
            105.83702959883584,
            21.025690406937947
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "13-1",
        "diemCuoi": "13-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025495123935453,
        "to_latitude": 21.025690406937947,
        "to_longitude": 105.83702959883584,
        "from_longitude": 105.83711811172726,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1211,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83683379757186,
          21.025495123935453,
          105.83711811172726,
          21.025585254583568
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83711811172726,
            21.025495123935453
          ],
          [
            105.83697964266949,
            21.025536746775355
          ],
          [
            105.83695147947023,
            21.025530487702756
          ],
          [
            105.83683379757186,
            21.025585254583568
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "13-1",
        "diemCuoi": "13-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025495123935453,
        "to_latitude": 21.025585254583568,
        "to_longitude": 105.83683379757186,
        "from_longitude": 105.83711811172726,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1212,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83722506479467,
          21.02544379951431,
          105.83727367984817,
          21.025645341652023
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83725222217726,
            21.02544379951431
          ],
          [
            105.83722506479467,
            21.0254678969572
          ],
          [
            105.83727367984817,
            21.025645341652023
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "13",
        "diemCuoi": "13-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.02544379951431,
        "to_latitude": 21.025645341652023,
        "to_longitude": 105.83727367984817,
        "from_longitude": 105.83725222217726,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1213,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83768003451871,
          21.025504852154423,
          105.8378195376653,
          21.025581499141083
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8378195376653,
            21.025504852154423
          ],
          [
            105.83768003451871,
            21.025581499141083
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "11",
        "diemCuoi": "11-2-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025504852154423,
        "to_latitude": 21.025581499141083,
        "to_longitude": 105.83768003451871,
        "from_longitude": 105.8378195376653,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1214,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83751239645399,
          21.025504852154423,
          105.8378195376653,
          21.025645341652023
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8378195376653,
            21.025504852154423
          ],
          [
            105.83766897038258,
            21.02556178306485
          ],
          [
            105.83751239645399,
            21.025645341652023
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "11",
        "diemCuoi": "11-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025504852154423,
        "to_latitude": 21.025645341652023,
        "to_longitude": 105.83751239645399,
        "from_longitude": 105.8378195376653,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1215,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83751239645399,
          21.02563689190889,
          105.83761163818536,
          21.025725457705363
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83751239645399,
            21.025645341652023
          ],
          [
            105.83756436423572,
            21.02563689190889
          ],
          [
            105.83761163818536,
            21.025725457705363
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "11-2",
        "diemCuoi": "11-2-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025645341652023,
        "to_latitude": 21.025725457705363,
        "to_longitude": 105.83761163818536,
        "from_longitude": 105.83751239645399,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1216,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83774306642759,
          21.025504852154423,
          105.83782051519185,
          21.025650348906787
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8378195376653,
            21.025504852154423
          ],
          [
            105.83782051519185,
            21.02560058930547
          ],
          [
            105.83774306642759,
            21.025650348906787
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025504852154423,
        "to_latitude": 21.025650348906787,
        "to_longitude": 105.83774306642759,
        "from_longitude": 105.8378195376653,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1217,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83777257073075,
          21.025646593465932,
          105.83792813885162,
          21.025721702266413
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83792813885162,
            21.025646593465932
          ],
          [
            105.83777257073075,
            21.025721702266413
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LSB",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Lương Sử B",
        "from_latitude": 21.025646593465932,
        "to_latitude": 21.025721702266413,
        "to_longitude": 105.83777257073075,
        "from_longitude": 105.83792813885162,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1218,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81802681849413,
          21.009096698382358,
          105.8182293252775,
          21.0092957587128
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8182293252775,
            21.0092957587128
          ],
          [
            105.81802681849413,
            21.009096698382358
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 63",
        "diemDau": "16-3",
        "diemCuoi": "16-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.0092957587128,
        "to_latitude": 21.009096698382358,
        "to_longitude": 105.81802681849413,
        "from_longitude": 105.8182293252775,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1219,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81844658420339,
          21.009472283688293,
          105.81867624833339,
          21.0096572591099
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81867624833339,
            21.0096572591099
          ],
          [
            105.81844658420339,
            21.009472283688293
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 63",
        "diemDau": "16-1",
        "diemCuoi": "16-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.0096572591099,
        "to_latitude": 21.009472283688293,
        "to_longitude": 105.81844658420339,
        "from_longitude": 105.81867624833339,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1220,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8182293252775,
          21.0092957587128,
          105.81844658420339,
          21.009472283688293
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81844658420339,
            21.009472283688293
          ],
          [
            105.8182293252775,
            21.0092957587128
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 63",
        "diemDau": "16-2",
        "diemCuoi": "16-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.009472283688293,
        "to_latitude": 21.0092957587128,
        "to_longitude": 105.8182293252775,
        "from_longitude": 105.81844658420339,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1221,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81808884457739,
          21.008856010301965,
          105.81826452926532,
          21.009006244780377
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81826452926532,
            21.009006244780377
          ],
          [
            105.81808884457739,
            21.008856010301965
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 61",
        "diemDau": "16-8",
        "diemCuoi": "16-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.009006244780377,
        "to_latitude": 21.008856010301965,
        "to_longitude": 105.81808884457739,
        "from_longitude": 105.81826452926532,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1222,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81799094394987,
          21.008666965035005,
          105.81808884457739,
          21.008856010301965
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81808884457739,
            21.008856010301965
          ],
          [
            105.81800301388472,
            21.008830971207658
          ],
          [
            105.81799094394987,
            21.00880092428879
          ],
          [
            105.81802313046074,
            21.00872330305361
          ],
          [
            105.81804861144326,
            21.008666965035005
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 61",
        "diemDau": "16-9",
        "diemCuoi": "16-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.008856010301965,
        "to_latitude": 21.008666965035005,
        "to_longitude": 105.81804861144326,
        "from_longitude": 105.81808884457739,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1223,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81867624833339,
          21.009441923911755,
          105.81888546065403,
          21.0096572591099
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81867624833339,
            21.0096572591099
          ],
          [
            105.81888546065403,
            21.009441923911755
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 61",
        "diemDau": "16-1",
        "diemCuoi": "16-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.0096572591099,
        "to_latitude": 21.009441923911755,
        "to_longitude": 105.81888546065403,
        "from_longitude": 105.81867624833339,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1224,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81864808516107,
          21.009331752297737,
          105.81888546065403,
          21.009441923911755
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81888546065403,
            21.009441923911755
          ],
          [
            105.81864808516107,
            21.009331752297737
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 61",
        "diemDau": "16-5",
        "diemCuoi": "16-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.009441923911755,
        "to_latitude": 21.009331752297737,
        "to_longitude": 105.81864808516107,
        "from_longitude": 105.81888546065403,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1225,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81845094279322,
          21.00916023496358,
          105.81864808516107,
          21.009331752297737
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81864808516107,
            21.009331752297737
          ],
          [
            105.81845094279322,
            21.00916023496358
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 61",
        "diemDau": "16-6",
        "diemCuoi": "16-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.009331752297737,
        "to_latitude": 21.00916023496358,
        "to_longitude": 105.81845094279322,
        "from_longitude": 105.81864808516107,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1226,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81826452926532,
          21.009006244780377,
          105.81845094279322,
          21.00916023496358
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81845094279322,
            21.00916023496358
          ],
          [
            105.81826452926532,
            21.009006244780377
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 61",
        "diemDau": "16-7",
        "diemCuoi": "16-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.00916023496358,
        "to_latitude": 21.009006244780377,
        "to_longitude": 105.81826452926532,
        "from_longitude": 105.81845094279322,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1227,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81835572438246,
          21.008792160603786,
          105.81854168402336,
          21.008857363092122
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81854168402336,
            21.008857363092122
          ],
          [
            105.81851263361621,
            21.00883347511863
          ],
          [
            105.81844557838673,
            21.008808436019706
          ],
          [
            105.81835572438246,
            21.008792160603786
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 61",
        "diemDau": "16-14",
        "diemCuoi": "16-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.008857363092122,
        "to_latitude": 21.008792160603786,
        "to_longitude": 105.81835572438246,
        "from_longitude": 105.81854168402336,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1228,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81834231334375,
          21.0086882482889,
          105.81837584094052,
          21.008792160603786
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81835572438246,
            21.008792160603786
          ],
          [
            105.81834231334375,
            21.008772129320285
          ],
          [
            105.81837584094052,
            21.0086882482889
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 61",
        "diemDau": "16-15",
        "diemCuoi": "16-16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.008792160603786,
        "to_latitude": 21.0086882482889,
        "to_longitude": 105.81837584094052,
        "from_longitude": 105.81835572438246,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1229,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81888546065403,
          21.009156479107446,
          105.81906114535997,
          21.009441923911755
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81888546065403,
            21.009441923911755
          ],
          [
            105.81906114535997,
            21.009261643047658
          ],
          [
            105.81893642262808,
            21.009156479107446
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 61",
        "diemDau": "16-5",
        "diemCuoi": "16-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.009441923911755,
        "to_latitude": 21.009156479107446,
        "to_longitude": 105.81893642262808,
        "from_longitude": 105.81888546065403,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1230,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81888546065403,
          21.009158983012966,
          105.81916709261067,
          21.009441923911755
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81888546065403,
            21.009441923911755
          ],
          [
            105.81906114535997,
            21.009261643047658
          ],
          [
            105.81916709261067,
            21.009158983012966
          ],
          [
            105.81916709261067,
            21.009158983012966
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 61",
        "diemDau": "16-5",
        "diemCuoi": "16-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.009441923911755,
        "to_latitude": 21.009158983012966,
        "to_longitude": 105.81916709261067,
        "from_longitude": 105.81888546065403,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1231,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81876342014787,
          21.00900749673395,
          105.81893642262808,
          21.009156479107446
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81893642262808,
            21.009156479107446
          ],
          [
            105.81876342014787,
            21.00900749673395
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 61",
        "diemDau": "16-12",
        "diemCuoi": "16-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.009156479107446,
        "to_latitude": 21.00900749673395,
        "to_longitude": 105.81876342014787,
        "from_longitude": 105.81893642262808,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1232,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81854168402336,
          21.008857363092122,
          105.81876342014787,
          21.00900749673395
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81876342014787,
            21.00900749673395
          ],
          [
            105.8186873124695,
            21.008940204227752
          ],
          [
            105.81854168402336,
            21.008857363092122
          ],
          [
            105.81854168402336,
            21.008857363092122
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 61",
        "diemDau": "16-13",
        "diemCuoi": "16-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.00900749673395,
        "to_latitude": 21.008857363092122,
        "to_longitude": 105.81854168402336,
        "from_longitude": 105.81876342014787,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1233,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81830878568408,
          21.01065537122765,
          105.81837349401998,
          21.01084785680467
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81837047649809,
            21.01084785680467
          ],
          [
            105.81830878568408,
            21.01076648081808
          ],
          [
            105.81837349401998,
            21.01065537122765
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ K",
        "diemDau": "19",
        "diemCuoi": "19-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.01084785680467,
        "to_latitude": 21.01065537122765,
        "to_longitude": 105.81837349401998,
        "from_longitude": 105.81837047649809,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1234,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81811566663681,
          21.01088291106109,
          105.81819076848954,
          21.011029387689337
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81819076848954,
            21.01088291106109
          ],
          [
            105.81811566663681,
            21.01099057765654
          ],
          [
            105.81813310099612,
            21.011029387689337
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "20",
        "diemCuoi": "20-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.01088291106109,
        "to_latitude": 21.011029387689337,
        "to_longitude": 105.81813310099612,
        "from_longitude": 105.81819076848954,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1235,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81813310099612,
          21.011029387689337,
          105.81822563720814,
          21.01109323643083
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81813310099612,
            21.011029387689337
          ],
          [
            105.81822563720814,
            21.01109323643083
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "20-1",
        "diemCuoi": "20-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.011029387689337,
        "to_latitude": 21.01109323643083,
        "to_longitude": 105.81822563720814,
        "from_longitude": 105.81813310099612,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1236,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81996102646163,
          21.023502534194293,
          105.82013268783798,
          21.023615199002087
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81996102646163,
            21.023502534194293
          ],
          [
            105.82004817525299,
            21.023559731706417
          ],
          [
            105.82013268783798,
            21.023615199002087
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.023502534194293,
        "to_latitude": 21.023615199002087,
        "to_longitude": 105.82013268783798,
        "from_longitude": 105.81996102646163,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1237,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82013268783798,
          21.023615199002087,
          105.82037408664254,
          21.0238355210467
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82013268783798,
            21.023615199002087
          ],
          [
            105.82037408664254,
            21.0238355210467
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.023615199002087,
        "to_latitude": 21.0238355210467,
        "to_longitude": 105.82037408664254,
        "from_longitude": 105.82013268783798,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1238,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82037408664254,
          21.0238355210467,
          105.82061280324837,
          21.024035813531427
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82037408664254,
            21.0238355210467
          ],
          [
            105.82061280324837,
            21.024035813531427
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.0238355210467,
        "to_latitude": 21.024035813531427,
        "to_longitude": 105.82061280324837,
        "from_longitude": 105.82037408664254,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1239,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82061280324837,
          21.024035813531427,
          105.82085956647744,
          21.02424486852538
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82061280324837,
            21.024035813531427
          ],
          [
            105.82085956647744,
            21.02424486852538
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.024035813531427,
        "to_latitude": 21.02424486852538,
        "to_longitude": 105.82085956647744,
        "from_longitude": 105.82061280324837,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1240,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82085956647744,
          21.02424486852538,
          105.82110767081033,
          21.02444891593204
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82085956647744,
            21.02424486852538
          ],
          [
            105.82110767081033,
            21.02444891593204
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02424486852538,
        "to_latitude": 21.02444891593204,
        "to_longitude": 105.82110767081033,
        "from_longitude": 105.82085956647744,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1241,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82110767081033,
          21.02444891593204,
          105.82135443403938,
          21.024662977634613
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82110767081033,
            21.02444891593204
          ],
          [
            105.82135443403938,
            21.024662977634613
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02444891593204,
        "to_latitude": 21.024662977634613,
        "to_longitude": 105.82135443403938,
        "from_longitude": 105.82110767081033,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1242,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82135443403938,
          21.024662977634613,
          105.82158644511685,
          21.024868276288593
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82135443403938,
            21.024662977634613
          ],
          [
            105.82158644511685,
            21.024868276288593
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.024662977634613,
        "to_latitude": 21.024868276288593,
        "to_longitude": 105.82158644511685,
        "from_longitude": 105.82135443403938,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1243,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82158644511685,
          21.024868276288593,
          105.82184393718586,
          21.02507858193378
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82158644511685,
            21.024868276288593
          ],
          [
            105.82169658184816,
            21.024958230071558
          ],
          [
            105.82184393718586,
            21.02507858193378
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.024868276288593,
        "to_latitude": 21.02507858193378,
        "to_longitude": 105.82184393718586,
        "from_longitude": 105.82158644511685,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1244,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82184393718586,
          21.02507858193378,
          105.82207326605558,
          21.025278872748334
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82184393718586,
            21.02507858193378
          ],
          [
            105.82207326605558,
            21.025278872748334
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02507858193378,
        "to_latitude": 21.025278872748334,
        "to_longitude": 105.82207326605558,
        "from_longitude": 105.82184393718586,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1245,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82207326605558,
          21.025278872748334,
          105.82232941702071,
          21.02549418507429
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82207326605558,
            21.025278872748334
          ],
          [
            105.82232941702071,
            21.02549418507429
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.025278872748334,
        "to_latitude": 21.02549418507429,
        "to_longitude": 105.82232941702071,
        "from_longitude": 105.82207326605558,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1246,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82232941702071,
          21.02549418507429,
          105.82256679251368,
          21.0256907198915
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82232941702071,
            21.02549418507429
          ],
          [
            105.82256679251368,
            21.0256907198915
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02549418507429,
        "to_latitude": 21.0256907198915,
        "to_longitude": 105.82256679251368,
        "from_longitude": 105.82232941702071,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1247,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82256679251368,
          21.0256907198915,
          105.82282160237494,
          21.025912290679337
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82256679251368,
            21.0256907198915
          ],
          [
            105.82282160237494,
            21.025912290679337
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.0256907198915,
        "to_latitude": 21.025912290679337,
        "to_longitude": 105.82282160237494,
        "from_longitude": 105.82256679251368,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1248,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82282160237494,
          21.025912290679337,
          105.82306836560399,
          21.026112580373674
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82282160237494,
            21.025912290679337
          ],
          [
            105.82306836560399,
            21.026112580373674
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.025912290679337,
        "to_latitude": 21.026112580373674,
        "to_longitude": 105.82306836560399,
        "from_longitude": 105.82282160237494,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1249,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82306836560399,
          21.026112580373674,
          105.82331915214465,
          21.02631662522372
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82306836560399,
            21.026112580373674
          ],
          [
            105.82331915214465,
            21.02631662522372
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.026112580373674,
        "to_latitude": 21.02631662522372,
        "to_longitude": 105.82331915214465,
        "from_longitude": 105.82306836560399,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1250,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82331915214465,
          21.02631662522372,
          105.82357127978916,
          21.0265331878563
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82331915214465,
            21.02631662522372
          ],
          [
            105.82357127978916,
            21.0265331878563
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02631662522372,
        "to_latitude": 21.0265331878563,
        "to_longitude": 105.82357127978916,
        "from_longitude": 105.82331915214465,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1251,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82357127978916,
          21.0265331878563,
          105.82367990929255,
          21.026606418500837
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82357127978916,
            21.0265331878563
          ],
          [
            105.8236557694139,
            21.026606418500837
          ],
          [
            105.82367990929255,
            21.026595152249463
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "17",
        "diemCuoi": "tủ điện",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.0265331878563,
        "to_latitude": 21.026595152249463,
        "to_longitude": 105.82367990929255,
        "from_longitude": 105.82357127978916,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1252,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82367454487706,
          21.026595152249463,
          105.82380999639498,
          21.026742239348657
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82380999639498,
            21.026742239348657
          ],
          [
            105.82367454487706,
            21.02661893655626
          ],
          [
            105.82367990929255,
            21.026595152249463
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "tủ điện",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.026742239348657,
        "to_latitude": 21.026595152249463,
        "to_longitude": 105.82367990929255,
        "from_longitude": 105.82380999639498,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1253,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82380999639498,
          21.026742239348657,
          105.82405810072792,
          21.026942527927762
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82380999639498,
            21.026742239348657
          ],
          [
            105.82405810072792,
            21.026942527927762
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.026742239348657,
        "to_latitude": 21.026942527927762,
        "to_longitude": 105.82405810072792,
        "from_longitude": 105.82380999639498,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1254,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82405810072792,
          21.026942527927762,
          105.82430620506084,
          21.027147823442128
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82405810072792,
            21.026942527927762
          ],
          [
            105.82430620506084,
            21.027147823442128
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.026942527927762,
        "to_latitude": 21.027147823442128,
        "to_longitude": 105.82430620506084,
        "from_longitude": 105.82405810072792,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1255,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82430620506084,
          21.027147823442128,
          105.82455699160147,
          21.027353118673766
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82430620506084,
            21.027147823442128
          ],
          [
            105.82455699160147,
            21.027353118673766
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.027147823442128,
        "to_latitude": 21.027353118673766,
        "to_longitude": 105.82455699160147,
        "from_longitude": 105.82430620506084,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1256,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82685296252457,
          21.029099368512185,
          105.82712520673616,
          21.02928212896087
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82712520673616,
            21.02928212896087
          ],
          [
            105.82685296252457,
            21.029099368512185
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02928212896087,
        "to_latitude": 21.029099368512185,
        "to_longitude": 105.82685296252457,
        "from_longitude": 105.82712520673616,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1257,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8265860827285,
          21.028910348907885,
          105.82685296252457,
          21.029099368512185
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82685296252457,
            21.029099368512185
          ],
          [
            105.8265860827285,
            21.028910348907885
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.029099368512185,
        "to_latitude": 21.028910348907885,
        "to_longitude": 105.8265860827285,
        "from_longitude": 105.82685296252457,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1258,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82633395507497,
          21.028730091578517,
          105.8265860827285,
          21.028910348907885
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8265860827285,
            21.028910348907885
          ],
          [
            105.82633395507497,
            21.028730091578517
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.028910348907885,
        "to_latitude": 21.028730091578517,
        "to_longitude": 105.82633395507497,
        "from_longitude": 105.8265860827285,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1259,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82607512191109,
          21.028541071505863,
          105.82633395507497,
          21.028730091578517
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82633395507497,
            21.028730091578517
          ],
          [
            105.82607512191109,
            21.028541071505863
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.028730091578517,
        "to_latitude": 21.028541071505863,
        "to_longitude": 105.82607512191109,
        "from_longitude": 105.82633395507497,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1260,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82582433536145,
          21.02836582089339,
          105.82607512191109,
          21.028541071505863
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82607512191109,
            21.028541071505863
          ],
          [
            105.82582433536145,
            21.02836582089339
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.028541071505863,
        "to_latitude": 21.02836582089339,
        "to_longitude": 105.82582433536145,
        "from_longitude": 105.82607512191109,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1261,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82555209114987,
          21.02816803781159,
          105.82582433536145,
          21.02836582089339
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82582433536145,
            21.02836582089339
          ],
          [
            105.82555209114987,
            21.02816803781159
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02836582089339,
        "to_latitude": 21.02816803781159,
        "to_longitude": 105.82555209114987,
        "from_longitude": 105.82582433536145,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1262,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82530532792083,
          21.02798152061386,
          105.82555209114987,
          21.02816803781159
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82555209114987,
            21.02816803781159
          ],
          [
            105.82530532792083,
            21.02798152061386
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02816803781159,
        "to_latitude": 21.02798152061386,
        "to_longitude": 105.82530532792083,
        "from_longitude": 105.82555209114987,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1263,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82504381254022,
          21.02777497445177,
          105.82530532792083,
          21.02798152061386
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82530532792083,
            21.02798152061386
          ],
          [
            105.82504381254022,
            21.02777497445177
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02798152061386,
        "to_latitude": 21.02777497445177,
        "to_longitude": 105.82504381254022,
        "from_longitude": 105.82530532792083,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1264,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82477022722476,
          21.027552154634908,
          105.82504381254022,
          21.02777497445177
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82504381254022,
            21.02777497445177
          ],
          [
            105.82477022722476,
            21.027552154634908
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02777497445177,
        "to_latitude": 21.027552154634908,
        "to_longitude": 105.82477022722476,
        "from_longitude": 105.82504381254022,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1265,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82455699160147,
          21.027353118673766,
          105.82477022722476,
          21.027552154634908
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82477022722476,
            21.027552154634908
          ],
          [
            105.82455699160147,
            21.027353118673766
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.027552154634908,
        "to_latitude": 21.027353118673766,
        "to_longitude": 105.82455699160147,
        "from_longitude": 105.82477022722476,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1266,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82672019316051,
          21.029171972007518,
          105.82695086313413,
          21.029334703845773
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82695086313413,
            21.029334703845773
          ],
          [
            105.82672019316051,
            21.029171972007518
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.029334703845773,
        "to_latitude": 21.029171972007518,
        "to_longitude": 105.82672019316051,
        "from_longitude": 105.82695086313413,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1267,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8264358790051,
          21.028974189995488,
          105.82672019316051,
          21.029171972007518
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8264358790051,
            21.028974189995488
          ],
          [
            105.82672019316051,
            21.029171972007518
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "33",
        "diemCuoi": "34",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.028974189995488,
        "to_latitude": 21.029171972007518,
        "to_longitude": 105.82672019316051,
        "from_longitude": 105.8264358790051,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1268,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82587797953425,
          21.02854858224328,
          105.8264358790051,
          21.028974189995488
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8264358790051,
            21.028974189995488
          ],
          [
            105.82587797953425,
            21.02854858224328
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 80,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "34",
        "diemCuoi": "36",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.028974189995488,
        "to_latitude": 21.02854858224328,
        "to_longitude": 105.82587797953425,
        "from_longitude": 105.8264358790051,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1269,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82569827152571,
          21.02840838177702,
          105.82587797953425,
          21.02854858224328
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82587797953425,
            21.02854858224328
          ],
          [
            105.82569827152571,
            21.02840838177702
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "36",
        "diemCuoi": "37",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02854858224328,
        "to_latitude": 21.02840838177702,
        "to_longitude": 105.82569827152571,
        "from_longitude": 105.82587797953425,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1270,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82550247027072,
          21.028265677595037,
          105.82569827152571,
          21.02840838177702
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82569827152571,
            21.02840838177702
          ],
          [
            105.82550247027072,
            21.028265677595037
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "37",
        "diemCuoi": "38",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02840838177702,
        "to_latitude": 21.028265677595037,
        "to_longitude": 105.82550247027072,
        "from_longitude": 105.82569827152571,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1271,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82530666901572,
          21.028117966105608,
          105.82550247027072,
          21.028265677595037
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82530666901572,
            21.028117966105608
          ],
          [
            105.82550247027072,
            21.028265677595037
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "38",
        "diemCuoi": "39",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.028117966105608,
        "to_latitude": 21.028265677595037,
        "to_longitude": 105.82550247027072,
        "from_longitude": 105.82530666901572,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1272,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82508672787307,
          21.027955232938833,
          105.82530666901572,
          21.028117966105608
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82530666901572,
            21.028117966105608
          ],
          [
            105.82508672787307,
            21.027955232938833
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "39",
        "diemCuoi": "40",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.028117966105608,
        "to_latitude": 21.027955232938833,
        "to_longitude": 105.82508672787307,
        "from_longitude": 105.82530666901572,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1273,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8248641045227,
          21.027787492411573,
          105.82508672787307,
          21.027955232938833
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82508672787307,
            21.027955232938833
          ],
          [
            105.8248641045227,
            21.027787492411573
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "40",
        "diemCuoi": "41",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.027955232938833,
        "to_latitude": 21.027787492411573,
        "to_longitude": 105.8248641045227,
        "from_longitude": 105.82508672787307,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1274,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82466562105995,
          21.027624758883956,
          105.8248641045227,
          21.027787492411573
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8248641045227,
            21.027787492411573
          ],
          [
            105.82466562105995,
            21.027624758883956
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "41",
        "diemCuoi": "42",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.027787492411573,
        "to_latitude": 21.027624758883956,
        "to_longitude": 105.82466562105995,
        "from_longitude": 105.8248641045227,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1275,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82444031550182,
          21.02742947841577,
          105.82466562105995,
          21.027624758883956
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82466562105995,
            21.027624758883956
          ],
          [
            105.82444031550182,
            21.02742947841577
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "42",
        "diemCuoi": "43",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.027624758883956,
        "to_latitude": 21.02742947841577,
        "to_longitude": 105.82444031550182,
        "from_longitude": 105.82466562105995,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1276,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82423646761461,
          21.02726674449726,
          105.82444031550182,
          21.02742947841577
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82444031550182,
            21.02742947841577
          ],
          [
            105.82423646761461,
            21.02726674449726
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "43",
        "diemCuoi": "44",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02742947841577,
        "to_latitude": 21.02726674449726,
        "to_longitude": 105.82423646761461,
        "from_longitude": 105.82444031550182,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1277,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82404871298284,
          21.027106514003588,
          105.82423646761461,
          21.02726674449726
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82423646761461,
            21.02726674449726
          ],
          [
            105.82404871298284,
            21.027106514003588
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "44",
        "diemCuoi": "45",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02726674449726,
        "to_latitude": 21.027106514003588,
        "to_longitude": 105.82404871298284,
        "from_longitude": 105.82423646761461,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1278,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82384486510463,
          21.026941276127225,
          105.82404871298284,
          21.027106514003588
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82404871298284,
            21.027106514003588
          ],
          [
            105.82384486510463,
            21.026941276127225
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "45",
        "diemCuoi": "46",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.027106514003588,
        "to_latitude": 21.026941276127225,
        "to_longitude": 105.82384486510463,
        "from_longitude": 105.82404871298284,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1279,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82364101721741,
          21.026768527241817,
          105.82384486510463,
          21.026941276127225
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82384486510463,
            21.026941276127225
          ],
          [
            105.82364101721741,
            21.026768527241817
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "46",
        "diemCuoi": "47",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.026941276127225,
        "to_latitude": 21.026768527241817,
        "to_longitude": 105.82364101721741,
        "from_longitude": 105.82384486510463,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1280,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82322795702751,
          21.026408007184312,
          105.82343180491475,
          21.02658826732207
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82343180491475,
            21.02658826732207
          ],
          [
            105.82322795702751,
            21.026408007184312
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "48",
        "diemCuoi": "49",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02658826732207,
        "to_latitude": 21.026408007184312,
        "to_longitude": 105.82322795702751,
        "from_longitude": 105.82343180491475,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1281,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82304556681122,
          21.02626780470452,
          105.82322795702751,
          21.026408007184312
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82322795702751,
            21.026408007184312
          ],
          [
            105.82304556681122,
            21.02626780470452
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "49",
        "diemCuoi": "50",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.026408007184312,
        "to_latitude": 21.02626780470452,
        "to_longitude": 105.82304556681122,
        "from_longitude": 105.82322795702751,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1282,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82283635450854,
          21.02609004779876,
          105.82304556681122,
          21.02626780470452
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82304556681122,
            21.02626780470452
          ],
          [
            105.82283635450854,
            21.02609004779876
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "50",
        "diemCuoi": "51",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02626780470452,
        "to_latitude": 21.02609004779876,
        "to_longitude": 105.82283635450854,
        "from_longitude": 105.82304556681122,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1283,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82262177779036,
          21.025904779812457,
          105.82283635450854,
          21.02609004779876
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82283635450854,
            21.02609004779876
          ],
          [
            105.82262177779036,
            21.025904779812457
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "51",
        "diemCuoi": "51",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02609004779876,
        "to_latitude": 21.025904779812457,
        "to_longitude": 105.82262177779036,
        "from_longitude": 105.82283635450854,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1284,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82241792990317,
          21.025737036977652,
          105.82262177779036,
          21.025904779812457
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82262177779036,
            21.025904779812457
          ],
          [
            105.82241792990317,
            21.025737036977652
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "52",
        "diemCuoi": "53",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.025904779812457,
        "to_latitude": 21.025737036977652,
        "to_longitude": 105.82241792990317,
        "from_longitude": 105.82262177779036,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1285,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82220871760045,
          21.025556775810454,
          105.82241792990317,
          21.025737036977652
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82241792990317,
            21.025737036977652
          ],
          [
            105.82220871760045,
            21.025556775810454
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "53",
        "diemCuoi": "54",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.025737036977652,
        "to_latitude": 21.025556775810454,
        "to_longitude": 105.82220871760045,
        "from_longitude": 105.82241792990317,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1286,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82201023413774,
          21.02538402532053,
          105.82220871760045,
          21.025556775810454
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82220871760045,
            21.025556775810454
          ],
          [
            105.82201023413774,
            21.02538402532053
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "54",
        "diemCuoi": "55",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.025556775810454,
        "to_latitude": 21.02538402532053,
        "to_longitude": 105.82201023413774,
        "from_longitude": 105.82220871760045,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1287,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82181711508149,
          21.025223792803768,
          105.82201023413774,
          21.02538402532053
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82201023413774,
            21.02538402532053
          ],
          [
            105.82181711508149,
            21.025223792803768
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "55",
        "diemCuoi": "56",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02538402532053,
        "to_latitude": 21.025223792803768,
        "to_longitude": 105.82181711508149,
        "from_longitude": 105.82201023413774,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1288,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82161863161875,
          21.025046034653002,
          105.82181711508149,
          21.025223792803768
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82181711508149,
            21.025223792803768
          ],
          [
            105.82161863161875,
            21.025046034653002
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "56",
        "diemCuoi": "57",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.025223792803768,
        "to_latitude": 21.025046034653002,
        "to_longitude": 105.82161863161875,
        "from_longitude": 105.82181711508149,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1289,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82141478373156,
          21.02487578721192,
          105.82161863161875,
          21.025046034653002
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82161863161875,
            21.025046034653002
          ],
          [
            105.82141478373156,
            21.02487578721192
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "57",
        "diemCuoi": "58",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.025046034653002,
        "to_latitude": 21.02487578721192,
        "to_longitude": 105.82141478373156,
        "from_longitude": 105.82161863161875,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1290,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8212163002688,
          21.024705539575574,
          105.82141478373156,
          21.02487578721192
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82141478373156,
            21.02487578721192
          ],
          [
            105.8212163002688,
            21.024705539575574
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "58",
        "diemCuoi": "59",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02487578721192,
        "to_latitude": 21.024705539575574,
        "to_longitude": 105.8212163002688,
        "from_longitude": 105.82141478373156,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1291,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8209319861134,
          21.024475204229688,
          105.8212163002688,
          21.024705539575574
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8212163002688,
            21.024705539575574
          ],
          [
            105.8209319861134,
            21.024475204229688
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "59",
        "diemCuoi": "60",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.024705539575574,
        "to_latitude": 21.024475204229688,
        "to_longitude": 105.8209319861134,
        "from_longitude": 105.8212163002688,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1292,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82055111243437,
          21.024159744373325,
          105.8209319861134,
          21.024475204229688
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8209319861134,
            21.024475204229688
          ],
          [
            105.82055111243437,
            21.024159744373325
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 57,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "60",
        "diemCuoi": "62",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.024475204229688,
        "to_latitude": 21.024159744373325,
        "to_longitude": 105.82055111243437,
        "from_longitude": 105.8209319861134,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1293,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82034726454715,
          21.02399199957482,
          105.82055111243437,
          21.024159744373325
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82055111243437,
            21.024159744373325
          ],
          [
            105.82034726454715,
            21.02399199957482
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "62",
        "diemCuoi": "63",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.024159744373325,
        "to_latitude": 21.02399199957482,
        "to_longitude": 105.82034726454715,
        "from_longitude": 105.82055111243437,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1294,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82014878107545,
          21.023819247272314,
          105.82034726454715,
          21.02399199957482
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82034726454715,
            21.02399199957482
          ],
          [
            105.82014878107545,
            21.023819247272314
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "63",
        "diemCuoi": "64",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02399199957482,
        "to_latitude": 21.023819247272314,
        "to_longitude": 105.82014878107545,
        "from_longitude": 105.82034726454715,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1295,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82343180491475,
          21.02658826732207,
          105.82367990929255,
          21.026702494550836
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82367990929255,
            21.026595152249463
          ],
          [
            105.82356088626338,
            21.026702494550836
          ],
          [
            105.82343180491475,
            21.02658826732207
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "Tủ điện",
        "diemCuoi": "48",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.026595152249463,
        "to_latitude": 21.02658826732207,
        "to_longitude": 105.82343180491475,
        "from_longitude": 105.82367990929255,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1296,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82356088626338,
          21.026702494550836,
          105.82364101721741,
          21.026768527241817
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82356088626338,
            21.026702494550836
          ],
          [
            105.82364101721741,
            21.026768527241817
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giảng Võ",
        "diemDau": "47",
        "diemCuoi": "tủ điện",
        "chuDauTu": "SoXayDung",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.026702494550836,
        "to_latitude": 21.026768527241817,
        "to_longitude": 105.82364101721741,
        "from_longitude": 105.82356088626338,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1297,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81763785122317,
          21.011387441059053,
          105.81775356847487,
          21.011590254127793
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81775356847487,
            21.011387441059053
          ],
          [
            105.81763785122317,
            21.0115432926644
          ],
          [
            105.8177441807388,
            21.011590254127793
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Thịnh Liệt",
        "diemDau": "20",
        "diemCuoi": "20-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.011387441059053,
        "to_latitude": 21.011590254127793,
        "to_longitude": 105.8177441807388,
        "from_longitude": 105.81775356847487,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1298,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8177441807388,
          21.011590254127793,
          105.81792657095507,
          21.011726714617303
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8177441807388,
            21.011590254127793
          ],
          [
            105.81792657095507,
            21.011726714617303
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Thịnh Liệt",
        "diemDau": "20-1",
        "diemCuoi": "20-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.011590254127793,
        "to_latitude": 21.011726714617303,
        "to_longitude": 105.81792657095507,
        "from_longitude": 105.8177441807388,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1299,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82037408664254,
          21.023676538698457,
          105.82069528118585,
          21.023866816768823
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82037408664254,
            21.0238355210467
          ],
          [
            105.82047265783545,
            21.023866816768823
          ],
          [
            105.82069528118585,
            21.023676538698457
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 325 Giảng Võ",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.0238355210467,
        "to_latitude": 21.023676538698457,
        "to_longitude": 105.82069528118585,
        "from_longitude": 105.82037408664254,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1300,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82069528118585,
          21.023498778703527,
          105.82099837080442,
          21.023676538698457
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82069528118585,
            21.023676538698457
          ],
          [
            105.82099837080442,
            21.023498778703527
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 325 Giảng Võ",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.023676538698457,
        "to_latitude": 21.023498778703527,
        "to_longitude": 105.82099837080442,
        "from_longitude": 105.82069528118585,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1301,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82099837080442,
          21.023416157788233,
          105.8211727143885,
          21.023498778703527
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82099837080442,
            21.023498778703527
          ],
          [
            105.8211727143885,
            21.023416157788233
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 325 Giảng Võ",
        "diemDau": "4-2",
        "diemCuoi": "4-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.023498778703527,
        "to_latitude": 21.023416157788233,
        "to_longitude": 105.8211727143885,
        "from_longitude": 105.82099837080442,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1302,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81782164719435,
          21.011510130479618,
          105.8179359586822,
          21.011648212901235
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8179359586822,
            21.011510130479618
          ],
          [
            105.81782164719435,
            21.011648212901235
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Thịnh Liệt",
        "diemDau": "22-1",
        "diemCuoi": "22-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.011510130479618,
        "to_latitude": 21.011648212901235,
        "to_longitude": 105.81782164719435,
        "from_longitude": 105.8179359586822,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1303,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82061280324837,
          21.02397697764546,
          105.82081061617265,
          21.024097153054722
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82061280324837,
            21.024035813531427
          ],
          [
            105.82075428978311,
            21.024097153054722
          ],
          [
            105.82081061617265,
            21.024029554399146
          ],
          [
            105.82079720512493,
            21.02397697764546
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 307 Giảng Võ",
        "diemDau": "05",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.024035813531427,
        "to_latitude": 21.02397697764546,
        "to_longitude": 105.82079720512493,
        "from_longitude": 105.82061280324837,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1304,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82079720512493,
          21.023861809454147,
          105.82090449348853,
          21.02397697764546
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82079720512493,
            21.02397697764546
          ],
          [
            105.82090449348853,
            21.023861809454147
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 307 Giảng Võ",
        "diemDau": "5-1",
        "diemCuoi": "5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02397697764546,
        "to_latitude": 21.023861809454147,
        "to_longitude": 105.82090449348853,
        "from_longitude": 105.82079720512493,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1305,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82080793396491,
          21.023724108238827,
          105.82090449348853,
          21.023861809454147
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82090449348853,
            21.023861809454147
          ],
          [
            105.82080793396491,
            21.023724108238827
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 307 Giảng Võ",
        "diemDau": "5-2",
        "diemCuoi": "5-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.023861809454147,
        "to_latitude": 21.023724108238827,
        "to_longitude": 105.82080793396491,
        "from_longitude": 105.82090449348853,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1306,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82090449348853,
          21.023739130196148,
          105.82106810824163,
          21.023861809454147
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82090449348853,
            21.023861809454147
          ],
          [
            105.82106810824163,
            21.023739130196148
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 307 Giảng Võ",
        "diemDau": "5-2",
        "diemCuoi": "5-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.023861809454147,
        "to_latitude": 21.023739130196148,
        "to_longitude": 105.82106810824163,
        "from_longitude": 105.82090449348853,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1307,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82106810824163,
          21.023739130196148,
          105.82143825308076,
          21.02404207267099
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82106810824163,
            21.023739130196148
          ],
          [
            105.82143825308076,
            21.02404207267099
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 55,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 307 Giảng Võ",
        "diemDau": "5-3",
        "diemCuoi": "5-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.023739130196148,
        "to_latitude": 21.02404207267099,
        "to_longitude": 105.82143825308076,
        "from_longitude": 105.82106810824163,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1308,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82106810824163,
          21.02363898378771,
          105.82115930334979,
          21.023739130196148
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82106810824163,
            21.023739130196148
          ],
          [
            105.82115930334979,
            21.02363898378771
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 307 Giảng Võ",
        "diemDau": "5-3",
        "diemCuoi": "5-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.023739130196148,
        "to_latitude": 21.02363898378771,
        "to_longitude": 105.82115930334979,
        "from_longitude": 105.82106810824163,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1309,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82143825308076,
          21.023954444746064,
          105.82150262610247,
          21.02404207267099
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82143825308076,
            21.02404207267099
          ],
          [
            105.82150262610247,
            21.023954444746064
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 307 Giảng Võ",
        "diemDau": "5-4",
        "diemCuoi": "5-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02404207267099,
        "to_latitude": 21.023954444746064,
        "to_longitude": 105.82150262610247,
        "from_longitude": 105.82143825308076,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1310,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82079720512493,
          21.023971970335356,
          105.82089376465757,
          21.024017036126263
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82079720512493,
            21.02397697764546
          ],
          [
            105.82084280268353,
            21.024017036126263
          ],
          [
            105.82089376465757,
            21.023971970335356
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 307 Giảng Võ",
        "diemDau": "5-1",
        "diemCuoi": "5-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02397697764546,
        "to_latitude": 21.023971970335356,
        "to_longitude": 105.82089376465757,
        "from_longitude": 105.82079720512493,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1311,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81797216850465,
          21.011299805696826,
          105.81807275133991,
          21.01140872392479
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81807275133991,
            21.011299805696826
          ],
          [
            105.81797216850465,
            21.01140872392479
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Thịnh Liệt",
        "diemDau": "22-4",
        "diemCuoi": "22-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.011299805696826,
        "to_latitude": 21.01140872392479,
        "to_longitude": 105.81797216850465,
        "from_longitude": 105.81807275133991,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1312,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81827391701937,
          21.011134550303396,
          105.81837047654298,
          21.011349883053317
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81827391701937,
            21.011134550303396
          ],
          [
            105.81837047654298,
            21.011232201239867
          ],
          [
            105.81827525812324,
            21.011349883053317
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Thịnh Liệt",
        "diemDau": "22-5",
        "diemCuoi": "22-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.011134550303396,
        "to_latitude": 21.011349883053317,
        "to_longitude": 105.81827525812324,
        "from_longitude": 105.81827391701937,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1313,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81791852432286,
          21.011299805696826,
          105.81807275133991,
          21.011510130479618
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8179359586822,
            21.011510130479618
          ],
          [
            105.81791852432286,
            21.011458801243915
          ],
          [
            105.81807275133991,
            21.011299805696826
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Thịnh Liệt",
        "diemDau": "22-3",
        "diemCuoi": "22-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.011510130479618,
        "to_latitude": 21.011299805696826,
        "to_longitude": 105.81807275133991,
        "from_longitude": 105.8179359586822,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1314,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82110767081033,
          21.024247372180078,
          105.82145434633615,
          21.02445517505428
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82110767081033,
            21.02444891593204
          ],
          [
            105.8211727143885,
            21.02445517505428
          ],
          [
            105.82145434633615,
            21.024247372180078
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 279 Giảng Võ",
        "diemDau": "07",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02444891593204,
        "to_latitude": 21.024247372180078,
        "to_longitude": 105.82145434633615,
        "from_longitude": 105.82110767081033,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1315,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82145434633615,
          21.024247372180078,
          105.8216742874788,
          21.024517766225078
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82145434633615,
            21.024247372180078
          ],
          [
            105.8216742874788,
            21.024517766225078
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 279 Giảng Võ",
        "diemDau": "7-1",
        "diemCuoi": "7-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.024247372180078,
        "to_latitude": 21.024517766225078,
        "to_longitude": 105.8216742874788,
        "from_longitude": 105.82145434633615,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1316,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81807275133991,
          21.011134550303396,
          105.81827123481165,
          21.011299805696826
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81807275133991,
            21.011299805696826
          ],
          [
            105.81819479185506,
            21.011168352557835
          ],
          [
            105.81827123481165,
            21.011134550303396
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH7",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Thịnh Liệt",
        "diemDau": "22-4",
        "diemCuoi": "22-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 7",
        "from_latitude": 21.011299805696826,
        "to_latitude": 21.011134550303396,
        "to_longitude": 105.81827123481165,
        "from_longitude": 105.81807275133991,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1317,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82232941702071,
          21.02548667418882,
          105.82239311949502,
          21.02549418507429
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82232941702071,
            21.02549418507429
          ],
          [
            105.82239311949502,
            21.02548667418882
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 7,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 189 Giảng Võ",
        "diemDau": "12",
        "diemCuoi": "12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02549418507429,
        "to_latitude": 21.02548667418882,
        "to_longitude": 105.82239311949502,
        "from_longitude": 105.82232941702071,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1318,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82239311949502,
          21.02536649989975,
          105.8225004078496,
          21.02548667418882
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82239311949502,
            21.02548667418882
          ],
          [
            105.8225004078496,
            21.02536649989975
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 189 Giảng Võ",
        "diemDau": "12-1",
        "diemCuoi": "12-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02548667418882,
        "to_latitude": 21.02536649989975,
        "to_longitude": 105.8225004078496,
        "from_longitude": 105.82239311949502,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1319,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82244139925236,
          21.02525133278196,
          105.8225004078496,
          21.02536649989975
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8225004078496,
            21.02536649989975
          ],
          [
            105.82244139925236,
            21.02525133278196
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 189 Giảng Võ",
        "diemDau": "12-2",
        "diemCuoi": "12-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02536649989975,
        "to_latitude": 21.02525133278196,
        "to_longitude": 105.82244139925236,
        "from_longitude": 105.8225004078496,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1320,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82230460659463,
          21.025096107395118,
          105.8225004078496,
          21.02536649989975
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8225004078496,
            21.02536649989975
          ],
          [
            105.82230460659463,
            21.025096107395118
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 189 Giảng Võ",
        "diemDau": "12-2",
        "diemCuoi": "12-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02536649989975,
        "to_latitude": 21.025096107395118,
        "to_longitude": 105.82230460659463,
        "from_longitude": 105.8225004078496,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1321,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8221946360233,
          21.024943385485447,
          105.82230460659463,
          21.025096107395118
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82230460659463,
            21.025096107395118
          ],
          [
            105.8221946360233,
            21.024943385485447
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 189 Giảng Võ",
        "diemDau": "12-3",
        "diemCuoi": "12-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.025096107395118,
        "to_latitude": 21.024943385485447,
        "to_longitude": 105.8221946360233,
        "from_longitude": 105.82230460659463,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1322,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82196128384194,
          21.024775641568745,
          105.8221946360233,
          21.024943385485447
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8221946360233,
            21.024943385485447
          ],
          [
            105.82196128384194,
            21.024775641568745
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 189 Giảng Võ",
        "diemDau": "12-4",
        "diemCuoi": "12-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.024943385485447,
        "to_latitude": 21.024775641568745,
        "to_longitude": 105.82196128384194,
        "from_longitude": 105.8221946360233,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1323,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8225004078496,
          21.02514367648249,
          105.82272839561548,
          21.02536649989975
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8225004078496,
            21.02536649989975
          ],
          [
            105.8225674630791,
            21.025356485371013
          ],
          [
            105.82272839561548,
            21.02519124555467
          ],
          [
            105.82272303120001,
            21.02514367648249
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 189 Giảng Võ",
        "diemDau": "12-2",
        "diemCuoi": "12-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02536649989975,
        "to_latitude": 21.02514367648249,
        "to_longitude": 105.82272303120001,
        "from_longitude": 105.8225004078496,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1324,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82244944588454,
          21.024923356371282,
          105.82272303120001,
          21.02514367648249
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82272303120001,
            21.02514367648249
          ],
          [
            105.82244944588454,
            21.024923356371282
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 189 Giảng Võ",
        "diemDau": "12-6",
        "diemCuoi": "12-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02514367648249,
        "to_latitude": 21.024923356371282,
        "to_longitude": 105.82244944588454,
        "from_longitude": 105.82272303120001,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1325,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82272303120001,
          21.025096107395118,
          105.82288932816084,
          21.025153691024673
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82272303120001,
            21.02514367648249
          ],
          [
            105.82276594654184,
            21.025153691024673
          ],
          [
            105.82288932816084,
            21.025096107395118
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 189 Giảng Võ",
        "diemDau": "12-6",
        "diemCuoi": "12-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02514367648249,
        "to_latitude": 21.025096107395118,
        "to_longitude": 105.82288932816084,
        "from_longitude": 105.82272303120001,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1326,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82262378946864,
          21.024838232604978,
          105.82288932816084,
          21.025096107395118
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82288932816084,
            21.025096107395118
          ],
          [
            105.82262378946864,
            21.024838232604978
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 189 Giảng Võ",
        "diemDau": "12-8",
        "diemCuoi": "12-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.025096107395118,
        "to_latitude": 21.024838232604978,
        "to_longitude": 105.82262378946864,
        "from_longitude": 105.82288932816084,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1327,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82239043728728,
          21.02471305050708,
          105.82262378946864,
          21.024838232604978
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82262378946864,
            21.024838232604978
          ],
          [
            105.82239043728728,
            21.02471305050708
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 189 Giảng Võ",
        "diemDau": "12-9",
        "diemCuoi": "12-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.024838232604978,
        "to_latitude": 21.02471305050708,
        "to_longitude": 105.82239043728728,
        "from_longitude": 105.82262378946864,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1328,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82305026069724,
          21.025802131236244,
          105.82318437115622,
          21.026112580373674
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82306836560399,
            21.026112580373674
          ],
          [
            105.82305026069724,
            21.026047486256502
          ],
          [
            105.82318437115622,
            21.025802131236244
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 187 Giảng Võ",
        "diemDau": "15",
        "diemCuoi": "15-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.026112580373674,
        "to_latitude": 21.025802131236244,
        "to_longitude": 105.82318437115622,
        "from_longitude": 105.82306836560399,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1329,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82582433536145,
          21.028258166846754,
          105.8260275127102,
          21.02839336029272
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82582433536145,
            21.02836582089339
          ],
          [
            105.82592290655435,
            21.02839336029272
          ],
          [
            105.8260275127102,
            21.028258166846754
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 149 Giảng Võ",
        "diemDau": "26",
        "diemCuoi": "26-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.02836582089339,
        "to_latitude": 21.028258166846754,
        "to_longitude": 105.8260275127102,
        "from_longitude": 105.82582433536145,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1330,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82633395507497,
          21.02865122893224,
          105.82655322567915,
          21.028748868397493
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82633395507497,
            21.028730091578517
          ],
          [
            105.8264405729001,
            21.028748868397493
          ],
          [
            105.82652103916828,
            21.02865122893224
          ],
          [
            105.82655322567915,
            21.028653732508726
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 129 Giảng Võ",
        "diemDau": "28",
        "diemCuoi": "28-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.028730091578517,
        "to_latitude": 21.028653732508726,
        "to_longitude": 105.82655322567915,
        "from_longitude": 105.82633395507497,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1331,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82655322567915,
          21.028636207469912,
          105.82673561588646,
          21.028698796885006
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82655322567915,
            21.028653732508726
          ],
          [
            105.82658809438878,
            21.028636207469912
          ],
          [
            105.82673561588646,
            21.028698796885006
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 129 Giảng Võ",
        "diemDau": "28-1",
        "diemCuoi": "28-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.028653732508726,
        "to_latitude": 21.028698796885006,
        "to_longitude": 105.82673561588646,
        "from_longitude": 105.82655322567915,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1332,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82673561588646,
          21.028698796885006,
          105.82689386622411,
          21.028806450616727
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82673561588646,
            21.028698796885006
          ],
          [
            105.82689386622411,
            21.028806450616727
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 129 Giảng Võ",
        "diemDau": "28-2",
        "diemCuoi": "28-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.028698796885006,
        "to_latitude": 21.028806450616727,
        "to_longitude": 105.82689386622411,
        "from_longitude": 105.82673561588646,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1333,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82673561588646,
          21.02867125754547,
          105.82689118400735,
          21.0287138183402
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82673561588646,
            21.028698796885006
          ],
          [
            105.82680535332369,
            21.02867125754547
          ],
          [
            105.82689118400735,
            21.0287138183402
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "K1HN",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 129 Giảng Võ",
        "diemDau": "28-2",
        "diemCuoi": "28-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "K1 Hào Nam",
        "from_latitude": 21.028698796885006,
        "to_latitude": 21.0287138183402,
        "to_longitude": 105.82689118400735,
        "from_longitude": 105.82673561588646,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1334,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81686971652483,
          21.012223511576334,
          105.81701992023027,
          21.012401285013315
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81701992023027,
            21.012223511576334
          ],
          [
            105.81686971652483,
            21.012401285013315
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.012223511576334,
        "to_latitude": 21.012401285013315,
        "to_longitude": 105.81686971652483,
        "from_longitude": 105.81701992023027,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1335,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81663904655123,
          21.012401285013315,
          105.81686971652483,
          21.01260910439548
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81686971652483,
            21.012401285013315
          ],
          [
            105.81663904655123,
            21.01260910439548
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.012401285013315,
        "to_latitude": 21.01260910439548,
        "to_longitude": 105.81663904655123,
        "from_longitude": 105.81686971652483,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1336,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81639898884154,
          21.01260910439548,
          105.81663904655123,
          21.012806908117334
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81663904655123,
            21.01260910439548
          ],
          [
            105.81639898884154,
            21.012806908117334
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.01260910439548,
        "to_latitude": 21.012806908117334,
        "to_longitude": 105.81639898884154,
        "from_longitude": 105.81663904655123,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1337,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81613345014932,
          21.012806908117334,
          105.81639898884154,
          21.01300721541693
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81639898884154,
            21.012806908117334
          ],
          [
            105.81613345014932,
            21.01300721541693
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.012806908117334,
        "to_latitude": 21.01300721541693,
        "to_longitude": 105.81613345014932,
        "from_longitude": 105.81639898884154,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1338,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81590009795899,
          21.01300721541693,
          105.81613345014932,
          21.01317246873573
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81613345014932,
            21.01300721541693
          ],
          [
            105.81590009795899,
            21.01317246873573
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.01300721541693,
        "to_latitude": 21.01317246873573,
        "to_longitude": 105.81590009795899,
        "from_longitude": 105.81613345014932,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1339,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81566674577763,
          21.01317246873573,
          105.81590009795899,
          21.013327706535623
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81590009795899,
            21.01317246873573
          ],
          [
            105.81566674577763,
            21.013327706535623
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.01317246873573,
        "to_latitude": 21.013327706535623,
        "to_longitude": 105.81566674577763,
        "from_longitude": 105.81590009795899,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1340,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81541730034084,
          21.013327706535623,
          105.81566674577763,
          21.013528013135
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81566674577763,
            21.013327706535623
          ],
          [
            105.81541730034084,
            21.013528013135
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013327706535623,
        "to_latitude": 21.013528013135,
        "to_longitude": 105.81541730034084,
        "from_longitude": 105.81566674577763,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1341,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81504313217219,
          21.013528013135,
          105.81541730034084,
          21.01380468618712
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81541730034084,
            21.013528013135
          ],
          [
            105.81504313217219,
            21.01380468618712
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013528013135,
        "to_latitude": 21.01380468618712,
        "to_longitude": 105.81504313217219,
        "from_longitude": 105.81541730034084,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1342,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81467030512538,
          21.01380468618712,
          105.81504313217219,
          21.014072595346224
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81504313217219,
            21.01380468618712
          ],
          [
            105.81467030512538,
            21.014072595346224
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "SoXayDung",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.01380468618712,
        "to_latitude": 21.014072595346224,
        "to_longitude": 105.81467030512538,
        "from_longitude": 105.81504313217219,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1343,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81424919830327,
          21.01407259534621,
          105.81467030512538,
          21.014388076543725
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81467030512538,
            21.01407259534621
          ],
          [
            105.81424919830327,
            21.014388076543725
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "SoXayDung",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.01407259534621,
        "to_latitude": 21.014388076543725,
        "to_longitude": 105.81424919830327,
        "from_longitude": 105.81467030512538,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1344,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81391123997506,
          21.014388076543725,
          105.81424919830327,
          21.014618427472218
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81424919830327,
            21.014388076543725
          ],
          [
            105.81391123997506,
            21.014618427472218
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.014388076543725,
        "to_latitude": 21.014618427472218,
        "to_longitude": 105.81391123997506,
        "from_longitude": 105.81424919830327,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1345,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81702132534808,
          21.0122237302162,
          105.81708429324303,
          21.01234870416374
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81702132534808,
            21.0122237302162
          ],
          [
            105.81708429324303,
            21.01234870416374
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156",
        "diemDau": "19",
        "diemCuoi": "19-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.0122237302162,
        "to_latitude": 21.01234870416374,
        "to_longitude": 105.81708429324303,
        "from_longitude": 105.81702132534808,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1346,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81687112164266,
          21.012402755578,
          105.8170642407528,
          21.012547978783235
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81687112164266,
            21.012402755578
          ],
          [
            105.8170642407528,
            21.012547978783235
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 158",
        "diemDau": "20",
        "diemCuoi": "20-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.012402755578,
        "to_latitude": 21.012547978783235,
        "to_longitude": 105.8170642407528,
        "from_longitude": 105.81687112164266,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1347,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8170642407528,
          21.012547978783235,
          105.81725199538457,
          21.01279210369262
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8170642407528,
            21.012547978783235
          ],
          [
            105.81725199538457,
            21.01279210369262
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 158",
        "diemDau": "20-1",
        "diemCuoi": "20-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.012547978783235,
        "to_latitude": 21.01279210369262,
        "to_longitude": 105.81725199538457,
        "from_longitude": 105.8170642407528,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1348,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81663911056519,
          21.012610574958114,
          105.8168416174114,
          21.012827157492147
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81663911056519,
            21.012610574958114
          ],
          [
            105.8168416174114,
            21.012827157492147
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 160",
        "diemDau": "21",
        "diemCuoi": "21-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.012610574958114,
        "to_latitude": 21.012827157492147,
        "to_longitude": 105.8168416174114,
        "from_longitude": 105.81663911056519,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1349,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8168416174114,
          21.012827157492147,
          105.81693013030282,
          21.013014945567992
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8168416174114,
            21.012827157492147
          ],
          [
            105.81693013030282,
            21.013014945567992
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 160",
        "diemDau": "21-1",
        "diemCuoi": "21-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.012827157492147,
        "to_latitude": 21.013014945567992,
        "to_longitude": 105.81693013030282,
        "from_longitude": 105.8168416174114,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1350,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81693013030282,
          21.013014945567992,
          105.817072287385,
          21.01319772573385
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81693013030282,
            21.013014945567992
          ],
          [
            105.817072287385,
            21.01319772573385
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 160",
        "diemDau": "21-2",
        "diemCuoi": "21-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013014945567992,
        "to_latitude": 21.01319772573385,
        "to_longitude": 105.817072287385,
        "from_longitude": 105.81693013030282,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1351,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8168416174114,
          21.012827157492147,
          105.8169261069912,
          21.01293482268426
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8168416174114,
            21.012827157492147
          ],
          [
            105.8169261069912,
            21.01293482268426
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 160",
        "diemDau": "21-1",
        "diemCuoi": "21-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.012827157492147,
        "to_latitude": 21.01293482268426,
        "to_longitude": 105.8169261069912,
        "from_longitude": 105.8168416174114,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1352,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8163990528465,
          21.012808378678837,
          105.81642051058031,
          21.012962364930583
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8163990528465,
            21.012808378678837
          ],
          [
            105.81642051058031,
            21.012962364930583
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 172",
        "diemDau": "22",
        "diemCuoi": "22-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.012808378678837,
        "to_latitude": 21.012962364930583,
        "to_longitude": 105.81642051058031,
        "from_longitude": 105.8163990528465,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1353,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81642051058031,
          21.012962364930583,
          105.81655327992642,
          21.013112595273498
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81642051058031,
            21.012962364930583
          ],
          [
            105.81655327992642,
            21.013112595273498
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 172",
        "diemDau": "22-1",
        "diemCuoi": "22-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.012962364930583,
        "to_latitude": 21.013112595273498,
        "to_longitude": 105.81655327992642,
        "from_longitude": 105.81642051058031,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1354,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81655327992642,
          21.013112595273498,
          105.81668470816865,
          21.013270336971342
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81655327992642,
            21.013112595273498
          ],
          [
            105.81668470816865,
            21.013270336971342
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 172",
        "diemDau": "22-2",
        "diemCuoi": "22-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013112595273498,
        "to_latitude": 21.013270336971342,
        "to_longitude": 105.81668470816865,
        "from_longitude": 105.81655327992642,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1355,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81668470816865,
          21.013270336971342,
          105.81680540757995,
          21.013419315087525
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81668470816865,
            21.013270336971342
          ],
          [
            105.81680540757995,
            21.013419315087525
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 172",
        "diemDau": "22-3",
        "diemCuoi": "22-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013270336971342,
        "to_latitude": 21.013419315087525,
        "to_longitude": 105.81680540757995,
        "from_longitude": 105.81668470816865,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1356,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81613217305042,
          21.013005556176832,
          105.81613485532105,
          21.013156412428508
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81613217305042,
            21.013005556176832
          ],
          [
            105.81613485532105,
            21.013156412428508
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 180",
        "diemDau": "23",
        "diemCuoi": "23-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013005556176832,
        "to_latitude": 21.013156412428508,
        "to_longitude": 105.81613485532105,
        "from_longitude": 105.81613217305042,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1357,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81613485532105,
          21.013156412428508,
          105.8162609191478,
          21.01330789449351
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81613485532105,
            21.013156412428508
          ],
          [
            105.8162609191478,
            21.01330789449351
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 180",
        "diemDau": "23-1",
        "diemCuoi": "23-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013156412428508,
        "to_latitude": 21.01330789449351,
        "to_longitude": 105.8162609191478,
        "from_longitude": 105.81613485532105,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1358,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8162609191478,
          21.01330789449351,
          105.81638430075783,
          21.013456872572167
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8162609191478,
            21.01330789449351
          ],
          [
            105.81638430075783,
            21.013456872572167
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 180",
        "diemDau": "23-2",
        "diemCuoi": "23-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.01330789449351,
        "to_latitude": 21.013456872572167,
        "to_longitude": 105.81638430075783,
        "from_longitude": 105.8162609191478,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1359,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81638430075783,
          21.013456872572167,
          105.81651841120782,
          21.013617117734942
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81638430075783,
            21.013456872572167
          ],
          [
            105.81651841120782,
            21.013617117734942
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 180",
        "diemDau": "23-3",
        "diemCuoi": "23-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013456872572167,
        "to_latitude": 21.013617117734942,
        "to_longitude": 105.81651841120782,
        "from_longitude": 105.81638430075783,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1360,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81538786010556,
          21.0135301096552,
          105.81541199988541,
          21.013734797666853
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81541199988541,
            21.0135301096552
          ],
          [
            105.81538786010556,
            21.013734797666853
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 196",
        "diemDau": "26",
        "diemCuoi": "26-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.0135301096552,
        "to_latitude": 21.013734797666853,
        "to_longitude": 105.81538786010556,
        "from_longitude": 105.81541199988541,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1361,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81538786010556,
          21.013734797666853,
          105.81555683925617,
          21.013936355635103
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81538786010556,
            21.013734797666853
          ],
          [
            105.81555683925617,
            21.013936355635103
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 196",
        "diemDau": "26-1",
        "diemCuoi": "26-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013734797666853,
        "to_latitude": 21.013936355635103,
        "to_longitude": 105.81555683925617,
        "from_longitude": 105.81538786010556,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1362,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81428010776912,
          21.01381867586306,
          105.81440885379466,
          21.014021485625307
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81440885379466,
            21.014021485625307
          ],
          [
            105.81428010776912,
            21.01381867586306
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "28-1",
        "diemCuoi": "28-1-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.014021485625307,
        "to_latitude": 21.01381867586306,
        "to_longitude": 105.81428010776912,
        "from_longitude": 105.81440885379466,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1363,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81440885379466,
          21.014021485625307,
          105.81467204545852,
          21.01407437888077
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81467204545852,
            21.01407437888077
          ],
          [
            105.81440885379466,
            21.014021485625307
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Thịnh",
        "diemDau": "28",
        "diemCuoi": "28-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.01407437888077,
        "to_latitude": 21.014021485625307,
        "to_longitude": 105.81440885379466,
        "from_longitude": 105.81467204545852,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1364,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81424825642867,
          21.01431443257391,
          105.81447322681639,
          21.01438234862467
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81424825642867,
            21.01438234862467
          ],
          [
            105.81441690042685,
            21.014354493650604
          ],
          [
            105.81447322681639,
            21.01431443257391
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 200",
        "diemDau": "29",
        "diemCuoi": "29-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.01438234862467,
        "to_latitude": 21.01431443257391,
        "to_longitude": 105.81447322681639,
        "from_longitude": 105.81424825642867,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1365,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81447322681639,
          21.01431443257391,
          105.81457246854777,
          21.014464661555014
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81447322681639,
            21.01431443257391
          ],
          [
            105.81457246854777,
            21.014464661555014
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 200",
        "diemDau": "29-1",
        "diemCuoi": "29-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.01431443257391,
        "to_latitude": 21.014464661555014,
        "to_longitude": 105.81457246854777,
        "from_longitude": 105.81447322681639,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1366,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81457246854777,
          21.014464661555014,
          105.81466902807136,
          21.01460988275945
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81457246854777,
            21.014464661555014
          ],
          [
            105.81466902807136,
            21.01460988275945
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 200",
        "diemDau": "29-2",
        "diemCuoi": "29-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.014464661555014,
        "to_latitude": 21.01460988275945,
        "to_longitude": 105.81466902807136,
        "from_longitude": 105.81457246854777,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1367,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81467204545852,
          21.014074378880757,
          105.81470255568614,
          21.014200508829393
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81467204545852,
            21.014074378880757
          ],
          [
            105.81470255568614,
            21.014200508829393
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 198",
        "diemDau": "28",
        "diemCuoi": "28-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.014074378880757,
        "to_latitude": 21.014200508829393,
        "to_longitude": 105.81470255568614,
        "from_longitude": 105.81467204545852,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1368,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81470255568614,
          21.014200508829393,
          105.81487958148693,
          21.01441333333686
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81470255568614,
            21.014200508829393
          ],
          [
            105.81487958148693,
            21.01441333333686
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 198",
        "diemDau": "28-4",
        "diemCuoi": "28-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.014200508829393,
        "to_latitude": 21.01441333333686,
        "to_longitude": 105.81487958148693,
        "from_longitude": 105.81470255568614,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1369,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81467204545852,
          21.014074378880757,
          105.8148809225818,
          21.014179226361946
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81467204545852,
            21.014074378880757
          ],
          [
            105.8148809225818,
            21.014179226361946
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 198",
        "diemDau": "28",
        "diemCuoi": "28-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.014074378880757,
        "to_latitude": 21.014179226361946,
        "to_longitude": 105.8148809225818,
        "from_longitude": 105.81467204545852,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1370,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8148809225818,
          21.014179226361946,
          105.81507270053417,
          21.014395806624087
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8148809225818,
            21.014179226361946
          ],
          [
            105.81507270053417,
            21.014395806624087
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 198",
        "diemDau": "28-2",
        "diemCuoi": "28-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.014179226361946,
        "to_latitude": 21.014395806624087,
        "to_longitude": 105.81507270053417,
        "from_longitude": 105.8148809225818,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1371,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82165263977825,
          21.011152728226648,
          105.82173209688804,
          21.01124817271818
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82173209688804,
            21.01124817271818
          ],
          [
            105.82165263977825,
            21.011152728226648
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "04",
        "diemCuoi": "18",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01124817271818,
        "to_latitude": 21.011152728226648,
        "to_longitude": 105.82165263977825,
        "from_longitude": 105.82173209688804,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1372,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82140863065813,
          21.01090385685115,
          105.82165263977825,
          21.011152728226648
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82165263977825,
            21.011152728226648
          ],
          [
            105.82140863065813,
            21.01090385685115
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011152728226648,
        "to_latitude": 21.01090385685115,
        "to_longitude": 105.82140863065813,
        "from_longitude": 105.82165263977825,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1373,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82151012147452,
          21.01124817271818,
          105.82173209688804,
          21.011491165712027
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82173209688804,
            21.01124817271818
          ],
          [
            105.82151012147452,
            21.011491165712027
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01124817271818,
        "to_latitude": 21.011491165712027,
        "to_longitude": 105.82151012147452,
        "from_longitude": 105.82173209688804,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1374,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82087980235869,
          21.01167144388341,
          105.82106219257498,
          21.011889279717156
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82106219257498,
            21.011889279717156
          ],
          [
            105.82087980235869,
            21.01167144388341
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011889279717156,
        "to_latitude": 21.01167144388341,
        "to_longitude": 105.82087980235869,
        "from_longitude": 105.82106219257498,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1375,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82068131889596,
          21.0114511038671,
          105.82087980235869,
          21.01167144388341
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82087980235869,
            21.01167144388341
          ],
          [
            105.82068131889596,
            21.0114511038671
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01167144388341,
        "to_latitude": 21.0114511038671,
        "to_longitude": 105.82068131889596,
        "from_longitude": 105.82087980235869,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1376,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82051476743341,
          21.01128251657878,
          105.82068131889596,
          21.0114511038671
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82068131889596,
            21.0114511038671
          ],
          [
            105.82051476743341,
            21.01128251657878
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.0114511038671,
        "to_latitude": 21.01128251657878,
        "to_longitude": 105.82051476743341,
        "from_longitude": 105.82068131889596,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1377,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82051476743341,
          21.01120740050423,
          105.82064619567565,
          21.01128251657878
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82051476743341,
            21.01128251657878
          ],
          [
            105.82064619567565,
            21.01120740050423
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01128251657878,
        "to_latitude": 21.01120740050423,
        "to_longitude": 105.82064619567565,
        "from_longitude": 105.82051476743341,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1378,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82076689509589,
          21.0110196101528,
          105.82091978100907,
          21.011147307616845
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82076689509589,
            21.011147307616845
          ],
          [
            105.82091978100907,
            21.0110196101528
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "20-2",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011147307616845,
        "to_latitude": 21.0110196101528,
        "to_longitude": 105.82091978100907,
        "from_longitude": 105.82076689509589,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1379,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82091978100907,
          21.0110196101528,
          105.82099048856313,
          21.011130785186708
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82091978100907,
            21.0110196101528
          ],
          [
            105.82099048856313,
            21.011130785186708
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "20-4",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.0110196101528,
        "to_latitude": 21.011130785186708,
        "to_longitude": 105.82099048856313,
        "from_longitude": 105.82091978100907,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1380,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82026392953605,
          21.01128251657878,
          105.82051476743341,
          21.011409545720948
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82051476743341,
            21.01128251657878
          ],
          [
            105.82026392953605,
            21.011409545720948
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "10",
        "diemCuoi": "17",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01128251657878,
        "to_latitude": 21.011409545720948,
        "to_longitude": 105.82026392953605,
        "from_longitude": 105.82051476743341,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1381,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82011123018502,
          21.011409545720948,
          105.82026392953605,
          21.01158649833534
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82026392953605,
            21.011409545720948
          ],
          [
            105.82011123018502,
            21.01158649833534
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "10-2",
        "diemCuoi": "cột",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011409545720948,
        "to_latitude": 21.01158649833534,
        "to_longitude": 105.82011123018502,
        "from_longitude": 105.82026392953605,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1382,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81985483818393,
          21.010376422621498,
          105.82001845292808,
          21.010551694378503
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81985483818393,
            21.010551694378503
          ],
          [
            105.82001845292808,
            21.010376422621498
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "13",
        "diemCuoi": "13-1",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010551694378503,
        "to_latitude": 21.010376422621498,
        "to_longitude": 105.82001845292808,
        "from_longitude": 105.81985483818393,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1383,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8196348970413,
          21.010551694378503,
          105.81985483818393,
          21.010661865092434
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81985483818393,
            21.010551694378503
          ],
          [
            105.8196348970413,
            21.010661865092434
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010551694378503,
        "to_latitude": 21.010661865092434,
        "to_longitude": 105.8196348970413,
        "from_longitude": 105.81985483818393,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1384,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81944714240953,
          21.010554198259754,
          105.8196348970413,
          21.010661865092434
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8196348970413,
            21.010661865092434
          ],
          [
            105.81953187303428,
            21.01057994185033
          ],
          [
            105.81944714240953,
            21.010554198259754
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010661865092434,
        "to_latitude": 21.010554198259754,
        "to_longitude": 105.81944714240953,
        "from_longitude": 105.8196348970413,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1385,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81888119630646,
          21.01024371672648,
          105.81900726013323,
          21.010386438156306
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81900726013323,
            21.01024371672648
          ],
          [
            105.81888119630646,
            21.010386438156306
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "17",
        "diemCuoi": "17-1",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01024371672648,
        "to_latitude": 21.010386438156306,
        "to_longitude": 105.81888119630646,
        "from_longitude": 105.81900726013323,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1386,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81875513248869,
          21.010386438156306,
          105.81888119630646,
          21.01050912840017
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81888119630646,
            21.010386438156306
          ],
          [
            105.81875513248869,
            21.01050912840017
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "17-1",
        "diemCuoi": "17-2",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010386438156306,
        "to_latitude": 21.01050912840017,
        "to_longitude": 105.81875513248869,
        "from_longitude": 105.81888119630646,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1387,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81866393738055,
          21.01050912840017,
          105.81875513248869,
          21.010596764226044
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81875513248869,
            21.01050912840017
          ],
          [
            105.81866393738055,
            21.010596764226044
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "17-2",
        "diemCuoi": "17-3",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01050912840017,
        "to_latitude": 21.010596764226044,
        "to_longitude": 105.81866393738055,
        "from_longitude": 105.81875513248869,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1388,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81860224657554,
          21.010596764226044,
          105.81866393738055,
          21.010676888365655
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81866393738055,
            21.010596764226044
          ],
          [
            105.81860224657554,
            21.010676888365655
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "17-3",
        "diemCuoi": "17-4",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010596764226044,
        "to_latitude": 21.010676888365655,
        "to_longitude": 105.81860224657554,
        "from_longitude": 105.81866393738055,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1389,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8206917932432,
          21.010919455202092,
          105.82080981043774,
          21.011022114026193
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82080981043774,
            21.011022114026193
          ],
          [
            105.8206917932432,
            21.010919455202092
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "cột",
        "diemCuoi": "20-3",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011022114026193,
        "to_latitude": 21.010919455202092,
        "to_longitude": 105.8206917932432,
        "from_longitude": 105.82080981043774,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1390,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82231140630233,
          21.010856854543917,
          105.82244819896005,
          21.01097203277932
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82231140630233,
            21.010856854543917
          ],
          [
            105.82244819896005,
            21.01097203277932
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "2-3",
        "diemCuoi": "2-4",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010856854543917,
        "to_latitude": 21.01097203277932,
        "to_longitude": 105.82244819896005,
        "from_longitude": 105.82231140630233,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1391,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82244819896005,
          21.01097203277932,
          105.82261449592092,
          21.011107241899253
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82244819896005,
            21.01097203277932
          ],
          [
            105.82261449592092,
            21.011107241899253
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "2-4",
        "diemCuoi": "2-5",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01097203277932,
        "to_latitude": 21.011107241899253,
        "to_longitude": 105.82261449592092,
        "from_longitude": 105.82244819896005,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1392,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82261449592092,
          21.011107241899253,
          105.82278079288176,
          21.011237443158066
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82261449592092,
            21.011107241899253
          ],
          [
            105.82278079288176,
            21.011237443158066
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "2-5",
        "diemCuoi": "2-6",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011107241899253,
        "to_latitude": 21.011237443158066,
        "to_longitude": 105.82278079288176,
        "from_longitude": 105.82261449592092,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1393,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8222953130469,
          21.010676575386405,
          105.82236897953551,
          21.010795275413408
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8222953130469,
            21.010676575386405
          ],
          [
            105.82236897953551,
            21.010795275413408
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "2",
        "diemCuoi": "",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010676575386405,
        "to_latitude": 21.010795275413408,
        "to_longitude": 105.82236897953551,
        "from_longitude": 105.8222953130469,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1394,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82124190057455,
          21.011491165712027,
          105.82151012147452,
          21.01176659060841
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82151012147452,
            21.011491165712027
          ],
          [
            105.82124190057455,
            21.01176659060841
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011491165712027,
        "to_latitude": 21.01176659060841,
        "to_longitude": 105.82124190057455,
        "from_longitude": 105.82151012147452,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1395,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82106219257498,
          21.01176659060841,
          105.82124190057455,
          21.011912000222683
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82124190057455,
            21.01176659060841
          ],
          [
            105.82109828310121,
            21.011912000222683
          ],
          [
            105.82106219257498,
            21.011889279717156
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01176659060841,
        "to_latitude": 21.011889279717156,
        "to_longitude": 105.82106219257498,
        "from_longitude": 105.82124190057455,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1396,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81900726013323,
          21.01024371672648,
          105.81928889208088,
          21.01040897310675
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81928889208088,
            21.01040897310675
          ],
          [
            105.81900726013323,
            21.01024371672648
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01040897310675,
        "to_latitude": 21.01024371672648,
        "to_longitude": 105.81900726013323,
        "from_longitude": 105.81928889208088,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1397,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82231140630233,
          21.010795275413408,
          105.82236897953551,
          21.010856854543917
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82236897953551,
            21.010795275413408
          ],
          [
            105.82231140630233,
            21.010856854543917
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 1,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "2-3",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010795275413408,
        "to_latitude": 21.010856854543917,
        "to_longitude": 105.82231140630233,
        "from_longitude": 105.82236897953551,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1398,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81998866782057,
          21.01158649833534,
          105.82011123018502,
          21.011588885043956
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82011123018502,
            21.01158649833534
          ],
          [
            105.81998866782057,
            21.011588885043956
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "10-3",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01158649833534,
        "to_latitude": 21.011588885043956,
        "to_longitude": 105.81998866782057,
        "from_longitude": 105.82011123018502,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1399,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82026392953605,
          21.011409545720948,
          105.82036820041368,
          21.011477463080954
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82026392953605,
            21.011409545720948
          ],
          [
            105.82036820041368,
            21.011477463080954
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "10-2",
        "diemCuoi": "10-4",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011409545720948,
        "to_latitude": 21.011477463080954,
        "to_longitude": 105.82036820041368,
        "from_longitude": 105.82026392953605,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1400,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81939618043549,
          21.010844648141596,
          105.81953833751767,
          21.010967338008612
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81953833751767,
            21.010844648141596
          ],
          [
            105.81939618043549,
            21.010967338008612
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "14-1",
        "diemCuoi": "14-2",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010844648141596,
        "to_latitude": 21.010967338008612,
        "to_longitude": 105.81939618043549,
        "from_longitude": 105.81953833751767,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1401,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81939618043549,
          21.010967338008612,
          105.81955174856536,
          21.011072500670597
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81939618043549,
            21.010967338008612
          ],
          [
            105.81955174856536,
            21.011072500670597
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "14-2",
        "diemCuoi": "14-3",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010967338008612,
        "to_latitude": 21.011072500670597,
        "to_longitude": 105.81955174856536,
        "from_longitude": 105.81939618043549,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1402,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81944982461727,
          21.011212717439054,
          105.8196080749549,
          21.01128282577303
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8196080749549,
            21.011212717439054
          ],
          [
            105.81944982461727,
            21.01128282577303
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "14-4",
        "diemCuoi": "14-5",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011212717439054,
        "to_latitude": 21.01128282577303,
        "to_longitude": 105.81944982461727,
        "from_longitude": 105.8196080749549,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1403,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81955174856536,
          21.011072500670597,
          105.81968241190113,
          21.011212717439054
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81955174856536,
            21.011072500670597
          ],
          [
            105.81968241190113,
            21.01115865008309
          ],
          [
            105.8196080749549,
            21.011212717439054
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "14-3",
        "diemCuoi": "14-4",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011072500670597,
        "to_latitude": 21.011212717439054,
        "to_longitude": 105.8196080749549,
        "from_longitude": 105.81955174856536,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1404,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81961937998325,
          21.010661865092434,
          105.8196348970413,
          21.010731739536908
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8196348970413,
            21.010661865092434
          ],
          [
            105.81961937998325,
            21.010731739536908
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 8,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "14",
        "diemCuoi": "",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010661865092434,
        "to_latitude": 21.010731739536908,
        "to_longitude": 105.81961937998325,
        "from_longitude": 105.8196348970413,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1405,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81953833751767,
          21.010731739536908,
          105.81961937998325,
          21.010844648141596
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81961937998325,
            21.010731739536908
          ],
          [
            105.81953833751767,
            21.010844648141596
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "14-1",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010731739536908,
        "to_latitude": 21.010844648141596,
        "to_longitude": 105.81953833751767,
        "from_longitude": 105.81961937998325,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1406,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81973413877267,
          21.01044152358405,
          105.81985483818393,
          21.010551694378503
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81985483818393,
            21.010551694378503
          ],
          [
            105.81984200333363,
            21.01049261902019
          ],
          [
            105.81973413877267,
            21.01044152358405
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "13",
        "diemCuoi": "13-2",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010551694378503,
        "to_latitude": 21.01044152358405,
        "to_longitude": 105.81973413877267,
        "from_longitude": 105.81985483818393,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1407,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81985483818393,
          21.010551694378503,
          105.81997285537851,
          21.010661865092434
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81997285537851,
            21.010661865092434
          ],
          [
            105.81985483818393,
            21.010551694378503
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010661865092434,
        "to_latitude": 21.010551694378503,
        "to_longitude": 105.81985483818393,
        "from_longitude": 105.81997285537851,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1408,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81997285537851,
          21.010576733184852,
          105.82011769466844,
          21.010661865092434
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81997285537851,
            21.010661865092434
          ],
          [
            105.82002305243708,
            21.010652867368226
          ],
          [
            105.82011769466844,
            21.010576733184852
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "12",
        "diemCuoi": "12-1",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010661865092434,
        "to_latitude": 21.010576733184852,
        "to_longitude": 105.82011769466844,
        "from_longitude": 105.81997285537851,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1409,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82011769466844,
          21.010456546879432,
          105.82027058058159,
          21.010576733184852
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82011769466844,
            21.010576733184852
          ],
          [
            105.82027058058159,
            21.010456546879432
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "12-1",
        "diemCuoi": "12-2",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010576733184852,
        "to_latitude": 21.010456546879432,
        "to_longitude": 105.82027058058159,
        "from_longitude": 105.82011769466844,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1410,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81997285537851,
          21.010661865092434,
          105.82028270499923,
          21.011006422689952
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82028270499923,
            21.011006422689952
          ],
          [
            105.81997285537851,
            21.010661865092434
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011006422689952,
        "to_latitude": 21.010661865092434,
        "to_longitude": 105.81997285537851,
        "from_longitude": 105.82028270499923,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1411,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82301955722404,
          21.011686349162634,
          105.8231067290116,
          21.011772732366556
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82301955722404,
            21.011772732366556
          ],
          [
            105.8231067290116,
            21.011686349162634
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.011772732366556,
        "to_latitude": 21.011686349162634,
        "to_longitude": 105.8231067290116,
        "from_longitude": 105.82301955722404,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1412,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82173804674491,
          21.009970479928665,
          105.82207000748876,
          21.010239617942737
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82207000748876,
            21.009970479928665
          ],
          [
            105.82173804674491,
            21.010239617942737
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "1-1-1",
        "diemCuoi": "1-2",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.009970479928665,
        "to_latitude": 21.010239617942737,
        "to_longitude": 105.82173804674491,
        "from_longitude": 105.82207000748876,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1413,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8215577055675,
          21.010239617942737,
          105.82173804674491,
          21.010358582455343
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82173804674491,
            21.010239617942737
          ],
          [
            105.8215577055675,
            21.010358582455343
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "1-2",
        "diemCuoi": "1-3",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010239617942737,
        "to_latitude": 21.010358582455343,
        "to_longitude": 105.8215577055675,
        "from_longitude": 105.82173804674491,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1414,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81860224657554,
          21.010676888365655,
          105.81880073003829,
          21.01079206673998
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81860224657554,
            21.010676888365655
          ],
          [
            105.81880073003829,
            21.01079206673998
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 225,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "17-4",
        "diemCuoi": "17-5",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010676888365655,
        "to_latitude": 21.01079206673998,
        "to_longitude": 105.81880073003829,
        "from_longitude": 105.81860224657554,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1415,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82028270499923,
          21.011006422689952,
          105.82051476743341,
          21.01128251657878
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82051476743341,
            21.01128251657878
          ],
          [
            105.82028270499923,
            21.011006422689952
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01128251657878,
        "to_latitude": 21.011006422689952,
        "to_longitude": 105.82028270499923,
        "from_longitude": 105.82051476743341,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1416,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82028270499923,
          21.011006422689952,
          105.82051476743341,
          21.01128251657878
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82051476743341,
            21.01128251657878
          ],
          [
            105.82028270499923,
            21.011006422689952
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01128251657878,
        "to_latitude": 21.011006422689952,
        "to_longitude": 105.82028270499923,
        "from_longitude": 105.82051476743341,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1417,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81928889208088,
          21.01040897310675,
          105.81944714240953,
          21.010554198259754
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81944714240953,
            21.010554198259754
          ],
          [
            105.81928889208088,
            21.01040897310675
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.010554198259754,
        "to_latitude": 21.01040897310675,
        "to_longitude": 105.81928889208088,
        "from_longitude": 105.81944714240953,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1418,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82028270499923,
          21.011006422689952,
          105.82051476743341,
          21.01128251657878
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82051476743341,
            21.01128251657878
          ],
          [
            105.82028270499923,
            21.011006422689952
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01128251657878,
        "to_latitude": 21.011006422689952,
        "to_longitude": 105.82028270499923,
        "from_longitude": 105.82051476743341,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1419,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82028270499923,
          21.011006422689952,
          105.82051476743341,
          21.01128251657878
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82051476743341,
            21.01128251657878
          ],
          [
            105.82028270499923,
            21.011006422689952
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01128251657878,
        "to_latitude": 21.011006422689952,
        "to_longitude": 105.82028270499923,
        "from_longitude": 105.82051476743341,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1420,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82028270499923,
          21.011006422689952,
          105.82051476743341,
          21.01128251657878
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82051476743341,
            21.01128251657878
          ],
          [
            105.82028270499923,
            21.011006422689952
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01128251657878,
        "to_latitude": 21.011006422689952,
        "to_longitude": 105.82028270499923,
        "from_longitude": 105.82051476743341,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1421,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82028270499923,
          21.011006422689952,
          105.82051476743341,
          21.01128251657878
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82051476743341,
            21.01128251657878
          ],
          [
            105.82028270499923,
            21.011006422689952
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01128251657878,
        "to_latitude": 21.011006422689952,
        "to_longitude": 105.82028270499923,
        "from_longitude": 105.82051476743341,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1422,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82028270499923,
          21.011006422689952,
          105.82051476743341,
          21.01128251657878
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82051476743341,
            21.01128251657878
          ],
          [
            105.82028270499923,
            21.011006422689952
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01128251657878,
        "to_latitude": 21.011006422689952,
        "to_longitude": 105.82028270499923,
        "from_longitude": 105.82051476743341,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1423,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82028270499923,
          21.011006422689952,
          105.82051476743341,
          21.01128251657878
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82051476743341,
            21.01128251657878
          ],
          [
            105.82028270499923,
            21.011006422689952
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ACD",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "",
        "tuDien2": "Ao Cây Dừa",
        "from_latitude": 21.01128251657878,
        "to_latitude": 21.011006422689952,
        "to_longitude": 105.82028270499923,
        "from_longitude": 105.82051476743341,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1424,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83826408552852,
          21.018760833386786,
          105.83827213216072,
          21.018913561629766
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83827213216072,
            21.018913561629766
          ],
          [
            105.83826408552852,
            21.018760833386786
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Sân Quần",
        "diemDau": "1-1",
        "diemCuoi": "1-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018913561629766,
        "to_latitude": 21.018760833386786,
        "to_longitude": 105.83826408552852,
        "from_longitude": 105.83827213216072,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1425,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83826408552852,
          21.01857430441991,
          105.838269449944,
          21.018760833386786
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83826408552852,
            21.018760833386786
          ],
          [
            105.838269449944,
            21.01857430441991
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Sân Quần",
        "diemDau": "1-2",
        "diemCuoi": "1-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018760833386786,
        "to_latitude": 21.01857430441991,
        "to_longitude": 105.838269449944,
        "from_longitude": 105.83826408552852,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1426,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.838269449944,
          21.018464139550524,
          105.83829627203939,
          21.01857430441991
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.838269449944,
            21.01857430441991
          ],
          [
            105.83829627203939,
            21.018464139550524
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Sân Quần",
        "diemDau": "1-3",
        "diemCuoi": "1-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01857430441991,
        "to_latitude": 21.018464139550524,
        "to_longitude": 105.83829627203939,
        "from_longitude": 105.838269449944,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1427,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.838042803282,
          21.01857430441991,
          105.838269449944,
          21.018606853115234
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.838269449944,
            21.01857430441991
          ],
          [
            105.838042803282,
            21.018606853115234
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Sân Quần",
        "diemDau": "1-3",
        "diemCuoi": "1-3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01857430441991,
        "to_latitude": 21.018606853115234,
        "to_longitude": 105.838042803282,
        "from_longitude": 105.838269449944,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1428,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83804146217814,
          21.01848917702855,
          105.838042803282,
          21.018606853115234
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.838042803282,
            21.018606853115234
          ],
          [
            105.83804146217814,
            21.01848917702855
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Sân Quần",
        "diemDau": "1-3-1",
        "diemCuoi": "1-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018606853115234,
        "to_latitude": 21.01848917702855,
        "to_longitude": 105.83804146217814,
        "from_longitude": 105.838042803282,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1429,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83804146217814,
          21.018351470851105,
          105.8380602376413,
          21.01848917702855
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83804146217814,
            21.01848917702855
          ],
          [
            105.8380602376413,
            21.018351470851105
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Sân Quần",
        "diemDau": "1-5",
        "diemCuoi": "1-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01848917702855,
        "to_latitude": 21.018351470851105,
        "to_longitude": 105.8380602376413,
        "from_longitude": 105.83804146217814,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1430,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83772747609396,
          21.01887365826168,
          105.83827213216072,
          21.01897005226117
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83827213216072,
            21.018913561629766
          ],
          [
            105.83776502702032,
            21.01897005226117
          ],
          [
            105.83772747609396,
            21.01887365826168
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tô Tiền",
        "diemDau": "1-1",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018913561629766,
        "to_latitude": 21.01887365826168,
        "to_longitude": 105.83772747609396,
        "from_longitude": 105.83827213216072,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1431,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83769663068696,
          21.018773508585067,
          105.83772747609396,
          21.01887365826168
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83772747609396,
            21.01887365826168
          ],
          [
            105.83769663068696,
            21.018773508585067
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tô Tiền",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01887365826168,
        "to_latitude": 21.018773508585067,
        "to_longitude": 105.83769663068696,
        "from_longitude": 105.83772747609396,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1432,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83768321963927,
          21.018277766697103,
          105.83783074113694,
          21.018370405457652
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83768321963927,
            21.018370405457652
          ],
          [
            105.83783074113694,
            21.018277766697103
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tô TIền",
        "diemDau": "15",
        "diemCuoi": "15-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018370405457652,
        "to_latitude": 21.018277766697103,
        "to_longitude": 105.83783074113694,
        "from_longitude": 105.83768321963927,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1433,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83766578527997,
          21.018555682806994,
          105.83769663068696,
          21.018773508585067
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83769663068696,
            21.018773508585067
          ],
          [
            105.83766578527997,
            21.018555682806994
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tô Tiền",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018773508585067,
        "to_latitude": 21.018555682806994,
        "to_longitude": 105.83766578527997,
        "from_longitude": 105.83769663068696,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1434,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83766578527997,
          21.018370405457652,
          105.83768321963927,
          21.018555682806994
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83766578527997,
            21.018555682806994
          ],
          [
            105.83768321963927,
            21.018370405457652
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tô Tiền",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018555682806994,
        "to_latitude": 21.018370405457652,
        "to_longitude": 105.83768321963927,
        "from_longitude": 105.83766578527997,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1435,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83766176196836,
          21.018260240438465,
          105.83768321963927,
          21.018370405457652
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83768321963927,
            21.018370405457652
          ],
          [
            105.83766176196836,
            21.018260240438465
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tô Tiền",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018370405457652,
        "to_latitude": 21.018260240438465,
        "to_longitude": 105.83766176196836,
        "from_longitude": 105.83768321963927,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1436,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83749261516515,
          21.018260240438465,
          105.83766176196836,
          21.018321425850896
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83766176196836,
            21.018260240438465
          ],
          [
            105.83749261516515,
            21.018321425850896
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Khâm Đức",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018260240438465,
        "to_latitude": 21.018321425850896,
        "to_longitude": 105.83749261516515,
        "from_longitude": 105.83766176196836,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1437,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83731022495785,
          21.018321425850896,
          105.83749261516515,
          21.018394034592998
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83749261516515,
            21.018321425850896
          ],
          [
            105.83731022495785,
            21.018394034592998
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Khâm Đức",
        "diemDau": "17",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018321425850896,
        "to_latitude": 21.018394034592998,
        "to_longitude": 105.83731022495785,
        "from_longitude": 105.83749261516515,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1438,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83723109979353,
          21.018718269751275,
          105.83733838814811,
          21.018777107716648
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83723109979353,
            21.018777107716648
          ],
          [
            105.83733838814811,
            21.018737047827983
          ],
          [
            105.83732497710042,
            21.018718269751275
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Cống Trắng",
        "diemDau": "18",
        "diemCuoi": "18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018777107716648,
        "to_latitude": 21.018718269751275,
        "to_longitude": 105.83732497710042,
        "from_longitude": 105.83723109979353,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1439,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83728474396632,
          21.018394034592998,
          105.83732497710042,
          21.018718269751275
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83732497710042,
            21.018718269751275
          ],
          [
            105.83728474396632,
            21.018484169534126
          ],
          [
            105.83731022495785,
            21.018394034592998
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Cống Trắng",
        "diemDau": "19",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018718269751275,
        "to_latitude": 21.018394034592998,
        "to_longitude": 105.83731022495785,
        "from_longitude": 105.83732497710042,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1440,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8372190298497,
          21.018394034592998,
          105.83731022495785,
          21.01849919201858
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83731022495785,
            21.018394034592998
          ],
          [
            105.83728474396632,
            21.018484169534126
          ],
          [
            105.8372190298497,
            21.01849919201858
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Cống Trắng",
        "diemDau": "19",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018394034592998,
        "to_latitude": 21.01849919201858,
        "to_longitude": 105.8372190298497,
        "from_longitude": 105.83731022495785,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1441,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83728474396632,
          21.018394034592998,
          105.83751541394892,
          21.018484169534126
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83731022495785,
            21.018394034592998
          ],
          [
            105.83728474396632,
            21.018484169534126
          ],
          [
            105.83751541394892,
            21.01842908707673
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Cống Trắng",
        "diemDau": "19",
        "diemCuoi": "19-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018394034592998,
        "to_latitude": 21.01842908707673,
        "to_longitude": 105.83751541394892,
        "from_longitude": 105.83731022495785,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1442,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8372190298497,
          21.018484169534126,
          105.83728474396632,
          21.018573052547428
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8372190298497,
            21.01849919201858
          ],
          [
            105.83728474396632,
            21.018484169534126
          ],
          [
            105.8372498752567,
            21.018573052547428
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Cống Trắng",
        "diemDau": "26",
        "diemCuoi": "26-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01849919201858,
        "to_latitude": 21.018573052547428,
        "to_longitude": 105.8372498752567,
        "from_longitude": 105.8372190298497,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1443,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8372190298497,
          21.018484169534126,
          105.83737996238611,
          21.01857806003974
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8372190298497,
            21.01849919201858
          ],
          [
            105.83728474396632,
            21.018484169534126
          ],
          [
            105.83729968795826,
            21.018571122578326
          ],
          [
            105.83737996238611,
            21.01857806003974
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Cống Trắng",
        "diemDau": "26",
        "diemCuoi": "26-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01849919201858,
        "to_latitude": 21.01857806003974,
        "to_longitude": 105.83737996238611,
        "from_longitude": 105.8372190298497,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1444,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83698567766834,
          21.01849919201858,
          105.8372190298497,
          21.01851546637534
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8372190298497,
            21.01849919201858
          ],
          [
            105.83698567766834,
            21.01851546637534
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Cống Trắng",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01849919201858,
        "to_latitude": 21.01851546637534,
        "to_longitude": 105.83698567766834,
        "from_longitude": 105.8372190298497,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1445,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81612747922729,
          21.0137898818648,
          105.81647147252741,
          21.014056539136725
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81612747922729,
            21.014056539136725
          ],
          [
            105.81620013056428,
            21.0140002211978
          ],
          [
            105.81647147252741,
            21.0137898818648
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.014056539136725,
        "to_latitude": 21.0137898818648,
        "to_longitude": 105.81647147252741,
        "from_longitude": 105.81612747922729,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1446,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81647147252741,
          21.0134718955706,
          105.81684429958321,
          21.0137898818648
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81647147252741,
            21.0137898818648
          ],
          [
            105.8166270406034,
            21.01363589646235
          ],
          [
            105.81684429958321,
            21.0134718955706
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 52,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.0137898818648,
        "to_latitude": 21.0134718955706,
        "to_longitude": 105.81684429958321,
        "from_longitude": 105.81647147252741,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1447,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81684429958321,
          21.01325781780192,
          105.81710715605873,
          21.0134718955706
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81684429958321,
            21.0134718955706
          ],
          [
            105.81710715605873,
            21.01325781780192
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.0134718955706,
        "to_latitude": 21.01325781780192,
        "to_longitude": 105.81710715605873,
        "from_longitude": 105.81684429958321,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1448,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81647147252741,
          21.013770790199114,
          105.81663341085351,
          21.0137898818648
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81647147252741,
            21.0137898818648
          ],
          [
            105.81663341085351,
            21.013770790199114
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "02",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.0137898818648,
        "to_latitude": 21.013770790199114,
        "to_longitude": 105.81663341085351,
        "from_longitude": 105.81647147252741,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1449,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81663341085351,
          21.013706942603733,
          105.816834576533,
          21.013770790199114
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81663341085351,
            21.013770790199114
          ],
          [
            105.816834576533,
            21.013706942603733
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013770790199114,
        "to_latitude": 21.013706942603733,
        "to_longitude": 105.816834576533,
        "from_longitude": 105.81663341085351,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1450,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.816834576533,
          21.01370193494787,
          105.8172422723074,
          21.013706942603733
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.816834576533,
            21.013706942603733
          ],
          [
            105.8172422723074,
            21.01370193494787
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013706942603733,
        "to_latitude": 21.01370193494787,
        "to_longitude": 105.8172422723074,
        "from_longitude": 105.816834576533,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1451,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8172422723074,
          21.013689415807942,
          105.81759230057942,
          21.01370193494787
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8172422723074,
            21.01370193494787
          ],
          [
            105.81759230057942,
            21.013689415807942
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.01370193494787,
        "to_latitude": 21.013689415807942,
        "to_longitude": 105.81759230057942,
        "from_longitude": 105.8172422723074,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1452,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81596252337928,
          21.013822431600968,
          105.81612747922729,
          21.014056539136725
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81612747922729,
            21.014056539136725
          ],
          [
            105.81609462211507,
            21.01398267637603
          ],
          [
            105.81596252337928,
            21.013822431600968
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 62,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 92 Thái Thịnh",
        "diemDau": "01",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.014056539136725,
        "to_latitude": 21.013822431600968,
        "to_longitude": 105.81596252337928,
        "from_longitude": 105.81612747922729,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1453,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81587401047891,
          21.013776110815332,
          105.81596252337928,
          21.013822431600968
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81596252337928,
            21.013822431600968
          ],
          [
            105.81587401047891,
            21.013776110815332
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 92 Thái Thịnh",
        "diemDau": "1-1",
        "diemCuoi": "1-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013822431600968,
        "to_latitude": 21.013776110815332,
        "to_longitude": 105.81587401047891,
        "from_longitude": 105.81596252337928,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1454,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81573453561343,
          21.013776110815332,
          105.81587401047891,
          21.01387501193523
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81587401047891,
            21.013776110815332
          ],
          [
            105.81573453561343,
            21.01387501193523
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 92 Thái Thịnh",
        "diemDau": "1-1",
        "diemCuoi": "1-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013776110815332,
        "to_latitude": 21.01387501193523,
        "to_longitude": 105.81573453561343,
        "from_longitude": 105.81587401047891,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1455,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81620258108,
          21.0137898818648,
          105.81647147252741,
          21.013912569311007
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81647147252741,
            21.0137898818648
          ],
          [
            105.81628237674686,
            21.013912569311007
          ],
          [
            105.81620258108,
            21.0137973933403
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 88 Thái Thịnh",
        "diemDau": "02",
        "diemCuoi": "2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.0137898818648,
        "to_latitude": 21.0137973933403,
        "to_longitude": 105.81620258108,
        "from_longitude": 105.81647147252741,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1456,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81605351140286,
          21.01360520311383,
          105.81620258108,
          21.0137973933403
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81620258108,
            21.0137973933403
          ],
          [
            105.81605351140286,
            21.01360520311383
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 88 Thái Thịnh",
        "diemDau": "2-1",
        "diemCuoi": "2-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.0137973933403,
        "to_latitude": 21.01360520311383,
        "to_longitude": 105.81605351140286,
        "from_longitude": 105.81620258108,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1457,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81589879237555,
          21.01340955112374,
          105.81605351140286,
          21.01360520311383
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81605351140286,
            21.01360520311383
          ],
          [
            105.81589879237555,
            21.01340955112374
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 88 Thái Thịnh",
        "diemDau": "2-2",
        "diemCuoi": "2-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.01360520311383,
        "to_latitude": 21.01340955112374,
        "to_longitude": 105.81589879237555,
        "from_longitude": 105.81605351140286,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1458,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81759230057942,
          21.013544457153525,
          105.81768081350677,
          21.013696301322582
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81759230057942,
            21.013689415807942
          ],
          [
            105.81768081350677,
            21.013696301322582
          ],
          [
            105.81766740245908,
            21.01367877452555
          ],
          [
            105.81765137787087,
            21.013544457153525
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "08",
        "diemCuoi": "8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013689415807942,
        "to_latitude": 21.013544457153525,
        "to_longitude": 105.81765137787087,
        "from_longitude": 105.81759230057942,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1459,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.817454235512,
          21.013310348814027,
          105.81765137787087,
          21.013544457153525
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81765137787087,
            21.013544457153525
          ],
          [
            105.817454235512,
            21.013310348814027
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "8-1",
        "diemCuoi": "8-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013544457153525,
        "to_latitude": 21.013310348814027,
        "to_longitude": 105.817454235512,
        "from_longitude": 105.81765137787087,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1460,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81719406123526,
          21.013494430056603,
          105.81765137787087,
          21.013544457153525
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81765137787087,
            21.013544457153525
          ],
          [
            105.81755776713905,
            21.013494430056603
          ],
          [
            105.81719406123526,
            21.013504395859258
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "8-1",
        "diemCuoi": "8-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013544457153525,
        "to_latitude": 21.013504395859258,
        "to_longitude": 105.81719406123526,
        "from_longitude": 105.81765137787087,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1461,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81766203804361,
          21.013696301322568,
          105.81781901593558,
          21.013771416142937
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81768081350677,
            21.013696301322568
          ],
          [
            105.81766203804361,
            21.013771416142937
          ],
          [
            105.81781901593558,
            21.013718473274054
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "08",
        "diemCuoi": "8-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013696301322568,
        "to_latitude": 21.013718473274054,
        "to_longitude": 105.81781901593558,
        "from_longitude": 105.81768081350677,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1462,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81781901593558,
          21.013565739711556,
          105.8180456625976,
          21.013718473274054
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81781901593558,
            21.013718473274054
          ],
          [
            105.8180456625976,
            21.013565739711556
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "8-4",
        "diemCuoi": "8-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013718473274054,
        "to_latitude": 21.013565739711556,
        "to_longitude": 105.8180456625976,
        "from_longitude": 105.81781901593558,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1463,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81791289325147,
          21.01343929623343,
          105.8180456625976,
          21.013565739711556
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8180456625976,
            21.013565739711556
          ],
          [
            105.81791289325147,
            21.01343929623343
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "8-5",
        "diemCuoi": "8-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013565739711556,
        "to_latitude": 21.01343929623343,
        "to_longitude": 105.81791289325147,
        "from_longitude": 105.8180456625976,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1464,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81773318524293,
          21.013235233761467,
          105.81791289325147,
          21.01343929623343
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81791289325147,
            21.01343929623343
          ],
          [
            105.81773318524293,
            21.013235233761467
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "8-6",
        "diemCuoi": "8-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.01343929623343,
        "to_latitude": 21.013235233761467,
        "to_longitude": 105.81773318524293,
        "from_longitude": 105.81791289325147,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1465,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81772916193133,
          21.013451815394347,
          105.81790816182165,
          21.01365839930512
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81790816182165,
            21.01365839930512
          ],
          [
            105.81772916193133,
            21.013451815394347
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "8-4",
        "diemCuoi": "8-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.01365839930512,
        "to_latitude": 21.013451815394347,
        "to_longitude": 105.81772916193133,
        "from_longitude": 105.81790816182165,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1466,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81753336067632,
          21.013227722253465,
          105.81772916193133,
          21.013451815394347
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81772916193133,
            21.013451815394347
          ],
          [
            105.81753336067632,
            21.013227722253465
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "8-8",
        "diemCuoi": "8-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013451815394347,
        "to_latitude": 21.013227722253465,
        "to_longitude": 105.81753336067632,
        "from_longitude": 105.81772916193133,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1467,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81743948336043,
          21.01304494212436,
          105.81753336067632,
          21.013227722253465
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81753336067632,
            21.013227722253465
          ],
          [
            105.81743948336043,
            21.01304494212436
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "8-9",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013227722253465,
        "to_latitude": 21.01304494212436,
        "to_longitude": 105.81743948336043,
        "from_longitude": 105.81753336067632,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1468,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83827213216072,
          21.01891356162978,
          105.83831881573755,
          21.019085770390568
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83827213216072,
            21.01891356162978
          ],
          [
            105.83831881573755,
            21.019085770390568
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "1-1",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01891356162978,
        "to_latitude": 21.019085770390568,
        "to_longitude": 105.83831881573755,
        "from_longitude": 105.83827213216072,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1469,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8379917585491,
          21.019085770390568,
          105.83831881573755,
          21.019107506612052
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83831881573755,
            21.019085770390568
          ],
          [
            105.8379917585491,
            21.019107506612052
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019085770390568,
        "to_latitude": 21.019107506612052,
        "to_longitude": 105.8379917585491,
        "from_longitude": 105.83831881573755,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1470,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8377721309205,
          21.019107506612052,
          105.8379917585491,
          21.019120776866927
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8379917585491,
            21.019107506612052
          ],
          [
            105.8377721309205,
            21.019120776866927
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "02",
        "diemCuoi": "2+1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019107506612052,
        "to_latitude": 21.019120776866927,
        "to_longitude": 105.8377721309205,
        "from_longitude": 105.8379917585491,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1471,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8375164314028,
          21.019120776866927,
          105.8377721309205,
          21.019132728202184
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8377721309205,
            21.019120776866927
          ],
          [
            105.8375164314028,
            21.019132728202184
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "2+1",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019120776866927,
        "to_latitude": 21.019132728202184,
        "to_longitude": 105.8375164314028,
        "from_longitude": 105.8377721309205,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1472,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8372474622605,
          21.019132728202184,
          105.8375164314028,
          21.01914842955395
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8375164314028,
            21.019132728202184
          ],
          [
            105.8372474622605,
            21.01914842955395
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019132728202184,
        "to_latitude": 21.01914842955395,
        "to_longitude": 105.8372474622605,
        "from_longitude": 105.8375164314028,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1473,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83692713595248,
          21.01914842955395,
          105.8372474622605,
          21.019170941948627
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8372474622605,
            21.01914842955395
          ],
          [
            105.83692713595248,
            21.019170941948627
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01914842955395,
        "to_latitude": 21.019170941948627,
        "to_longitude": 105.83692713595248,
        "from_longitude": 105.8372474622605,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1474,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8365092363495,
          21.019170941948627,
          105.83692713595248,
          21.019190866103887
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83692713595248,
            21.019170941948627
          ],
          [
            105.8365092363495,
            21.019190866103887
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019170941948627,
        "to_latitude": 21.019190866103887,
        "to_longitude": 105.8365092363495,
        "from_longitude": 105.83692713595248,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1475,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83628124858362,
          21.019190866103887,
          105.8365092363495,
          21.019208392253116
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8365092363495,
            21.019190866103887
          ],
          [
            105.83628124858362,
            21.019208392253116
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "06",
        "diemCuoi": "6+1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019190866103887,
        "to_latitude": 21.019208392253116,
        "to_longitude": 105.83628124858362,
        "from_longitude": 105.8365092363495,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1476,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83605057861001,
          21.019208392253116,
          105.83628124858362,
          21.019218407194867
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83628124858362,
            21.019208392253116
          ],
          [
            105.83605057861001,
            21.019218407194867
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "6+1",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019208392253116,
        "to_latitude": 21.019218407194867,
        "to_longitude": 105.83605057861001,
        "from_longitude": 105.83628124858362,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1477,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83577298305715,
          21.019218407194867,
          105.83605057861001,
          21.019239082012003
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83605057861001,
            21.019218407194867
          ],
          [
            105.83577298305715,
            21.019239082012003
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019218407194867,
        "to_latitude": 21.019239082012003,
        "to_longitude": 105.83577298305715,
        "from_longitude": 105.83605057861001,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1478,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83539258566059,
          21.019239082012003,
          105.83577298305715,
          21.01926942793389
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83577298305715,
            21.019239082012003
          ],
          [
            105.83539258566059,
            21.01926942793389
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019239082012003,
        "to_latitude": 21.01926942793389,
        "to_longitude": 105.83539258566059,
        "from_longitude": 105.83577298305715,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1479,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8350382349299,
          21.01926942793389,
          105.83539258566059,
          21.01928671858532
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83539258566059,
            21.01926942793389
          ],
          [
            105.8350382349299,
            21.01928671858532
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01926942793389,
        "to_latitude": 21.01928671858532,
        "to_longitude": 105.8350382349299,
        "from_longitude": 105.83539258566059,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1480,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83470670644154,
          21.01928671858532,
          105.8350382349299,
          21.01930886937503
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8350382349299,
            21.01928671858532
          ],
          [
            105.83470670644154,
            21.01930886937503
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01928671858532,
        "to_latitude": 21.01930886937503,
        "to_longitude": 105.83470670644154,
        "from_longitude": 105.8350382349299,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1481,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81612747922729,
          21.01405653913674,
          105.81630372040088,
          21.014289397753263
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81612747922729,
            21.01405653913674
          ],
          [
            105.81630372040088,
            21.014289397753263
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Thái Hà",
        "diemDau": "01",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.01405653913674,
        "to_latitude": 21.014289397753263,
        "to_longitude": 105.81630372040088,
        "from_longitude": 105.81612747922729,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1482,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81630372040088,
          21.014210524106606,
          105.81647482530057,
          21.01431568448798
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81630372040088,
            21.014289397753263
          ],
          [
            105.81633199761704,
            21.01431568448798
          ],
          [
            105.81647482530057,
            21.014210524106606
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Thái Hà",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.014289397753263,
        "to_latitude": 21.014210524106606,
        "to_longitude": 105.81647482530057,
        "from_longitude": 105.81630372040088,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1483,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81632462159514,
          21.01408408117593,
          105.81647482530057,
          21.014210524106606
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81647482530057,
            21.014210524106606
          ],
          [
            105.81632462159514,
            21.01408408117593
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Thái Hà",
        "diemDau": "11-1",
        "diemCuoi": "11-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.014210524106606,
        "to_latitude": 21.01408408117593,
        "to_longitude": 105.81632462159514,
        "from_longitude": 105.81647482530057,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1484,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81632462159514,
          21.013897546358653,
          105.81657004372032,
          21.01408408117593
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81632462159514,
            21.01408408117593
          ],
          [
            105.81657004372032,
            21.013897546358653
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Thái Hà",
        "diemDau": "11-2",
        "diemCuoi": "11-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.01408408117593,
        "to_latitude": 21.013897546358653,
        "to_longitude": 105.81657004372032,
        "from_longitude": 105.81632462159514,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1485,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81630372040088,
          21.014289397753263,
          105.81635939557596,
          21.014448855556395
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81630372040088,
            21.014289397753263
          ],
          [
            105.81635939557596,
            21.014448855556395
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Thái Hà",
        "diemDau": "11",
        "diemCuoi": "11-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.014289397753263,
        "to_latitude": 21.014448855556395,
        "to_longitude": 105.81635939557596,
        "from_longitude": 105.81630372040088,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1486,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81612747922729,
          21.01405653913674,
          105.81630372040088,
          21.014289397753263
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81612747922729,
            21.01405653913674
          ],
          [
            105.81630372040088,
            21.014289397753263
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Thái Hà",
        "diemDau": "01",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.01405653913674,
        "to_latitude": 21.014289397753263,
        "to_longitude": 105.81630372040088,
        "from_longitude": 105.81612747922729,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1487,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81632462159514,
          21.01408408117593,
          105.81647482530057,
          21.014210524106606
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81647482530057,
            21.014210524106606
          ],
          [
            105.81632462159514,
            21.01408408117593
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Thái Hà",
        "diemDau": "11-1",
        "diemCuoi": "11-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.014210524106606,
        "to_latitude": 21.01408408117593,
        "to_longitude": 105.81632462159514,
        "from_longitude": 105.81647482530057,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1488,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8166549372186,
          21.014638676617867,
          105.81666660317208,
          21.014795115263475
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81666660317208,
            21.014638676617867
          ],
          [
            105.8166549372186,
            21.014795115263475
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165",
        "diemDau": "14",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.014638676617867,
        "to_latitude": 21.014795115263475,
        "to_longitude": 105.8166549372186,
        "from_longitude": 105.81666660317208,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1489,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8166549372186,
          21.014795115263475,
          105.81685104305294,
          21.014928347960137
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8166549372186,
            21.014795115263475
          ],
          [
            105.81685104305294,
            21.014928347960137
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.014795115263475,
        "to_latitude": 21.014928347960137,
        "to_longitude": 105.81685104305294,
        "from_longitude": 105.8166549372186,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1490,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81647923489484,
          21.014795115263475,
          105.8166549372186,
          21.014925684566737
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8166549372186,
            21.014795115263475
          ],
          [
            105.81647923489484,
            21.014925684566737
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165",
        "diemDau": "12",
        "diemCuoi": "12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.014795115263475,
        "to_latitude": 21.014925684566737,
        "to_longitude": 105.81647923489484,
        "from_longitude": 105.8166549372186,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1491,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81666660317208,
          21.014432111970724,
          105.81691336641909,
          21.014638676617867
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81666660317208,
            21.014638676617867
          ],
          [
            105.81691336641909,
            21.014432111970724
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 151B",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.014638676617867,
        "to_latitude": 21.014432111970724,
        "to_longitude": 105.81691336641909,
        "from_longitude": 105.81666660317208,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1492,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81691336641909,
          21.01429440204703,
          105.81708502779543,
          21.014432111970724
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81691336641909,
            21.014432111970724
          ],
          [
            105.81708502779543,
            21.01429440204703
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 151B",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.014432111970724,
        "to_latitude": 21.01429440204703,
        "to_longitude": 105.81708502779543,
        "from_longitude": 105.81691336641909,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1493,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81708502779543,
          21.014101607940432,
          105.81733849654384,
          21.01429440204703
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81708502779543,
            21.01429440204703
          ],
          [
            105.81733849654384,
            21.014101607940432
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 151B",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.01429440204703,
        "to_latitude": 21.014101607940432,
        "to_longitude": 105.81733849654384,
        "from_longitude": 105.81708502779543,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1494,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81733849654384,
          21.014101607940432,
          105.81749849195772,
          21.014296856332027
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81733849654384,
            21.014101607940432
          ],
          [
            105.81749849195772,
            21.014296856332027
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 151B",
        "diemDau": "17",
        "diemCuoi": "17-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.014101607940432,
        "to_latitude": 21.014296856332027,
        "to_longitude": 105.81749849195772,
        "from_longitude": 105.81733849654384,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1495,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81749849195772,
          21.014137863810078,
          105.81778548832085,
          21.014296856332027
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81749849195772,
            21.014296856332027
          ],
          [
            105.81778548832085,
            21.014137863810078
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 161",
        "diemDau": "17-1",
        "diemCuoi": "17-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.014296856332027,
        "to_latitude": 21.014137863810078,
        "to_longitude": 105.81778548832085,
        "from_longitude": 105.81749849195772,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1496,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81690706487211,
          21.014122840886284,
          105.81708502779543,
          21.01429440204703
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81708502779543,
            21.01429440204703
          ],
          [
            105.81690706487211,
            21.014122840886284
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 151B",
        "diemDau": "16",
        "diemCuoi": "16-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.01429440204703,
        "to_latitude": 21.014122840886284,
        "to_longitude": 105.81690706487211,
        "from_longitude": 105.81708502779543,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1497,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81733849654384,
          21.01388001960036,
          105.81759196528324,
          21.014101607940432
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81733849654384,
            21.014101607940432
          ],
          [
            105.81759196528324,
            21.01388001960036
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 151B",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.014101607940432,
        "to_latitude": 21.01388001960036,
        "to_longitude": 105.81759196528324,
        "from_longitude": 105.81733849654384,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1498,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81782492897918,
          21.013933491096683,
          105.81797781489233,
          21.014126285421433
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81782492897918,
            21.013933491096683
          ],
          [
            105.81797781489233,
            21.014126285421433
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 161",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013933491096683,
        "to_latitude": 21.014126285421433,
        "to_longitude": 105.81797781489233,
        "from_longitude": 105.81782492897918,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1499,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81782492897918,
          21.0139000006661,
          105.81797592516037,
          21.01395388240689
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81782492897918,
            21.013933491096683
          ],
          [
            105.81790779541107,
            21.01395388240689
          ],
          [
            105.81797592516037,
            21.0139000006661
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 161",
        "diemDau": "09",
        "diemCuoi": "9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013933491096683,
        "to_latitude": 21.0139000006661,
        "to_longitude": 105.81797592516037,
        "from_longitude": 105.81782492897918,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1500,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.817969219641,
          21.01378232086453,
          105.81802447151071,
          21.0139000006661
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81797592516037,
            21.0139000006661
          ],
          [
            105.81802447151071,
            21.01386124090285
          ],
          [
            105.817969219641,
            21.01378232086453
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 161",
        "diemDau": "9-1",
        "diemCuoi": "9-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.0139000006661,
        "to_latitude": 21.01378232086453,
        "to_longitude": 105.817969219641,
        "from_longitude": 105.81797592516037,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1501,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.817969219641,
          21.013708457962977,
          105.81807382578786,
          21.01378232086453
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.817969219641,
            21.01378232086453
          ],
          [
            105.81807382578786,
            21.013708457962977
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 161",
        "diemDau": "9-2",
        "diemCuoi": "9-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.01378232086453,
        "to_latitude": 21.013708457962977,
        "to_longitude": 105.81807382578786,
        "from_longitude": 105.817969219641,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1502,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81759196528324,
          21.013771416144618,
          105.81766337912052,
          21.01388001960036
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81759196528324,
            21.01388001960036
          ],
          [
            105.81766337912052,
            21.013771416144618
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 151A",
        "diemDau": "08",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.01388001960036,
        "to_latitude": 21.013771416144618,
        "to_longitude": 105.81766337912052,
        "from_longitude": 105.81759196528324,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1503,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81766337912052,
          21.013771416144618,
          105.81782492897918,
          21.013933491096683
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81766337912052,
            21.013771416144618
          ],
          [
            105.81782492897918,
            21.013933491096683
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 161",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013771416144618,
        "to_latitude": 21.013933491096683,
        "to_longitude": 105.81782492897918,
        "from_longitude": 105.81766337912052,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1504,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83665455492134,
          21.01918393776598,
          105.83677288407007,
          21.019339863145937
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83665455492134,
            21.01918393776598
          ],
          [
            105.83677288407007,
            21.019339863145937
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Liên Hoa",
        "diemDau": "06",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01918393776598,
        "to_latitude": 21.019339863145937,
        "to_longitude": 105.83677288407007,
        "from_longitude": 105.83665455492134,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1505,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83677288407007,
          21.019339863145937,
          105.83691235893555,
          21.01964531828845
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83677288407007,
            21.019339863145937
          ],
          [
            105.83691235893555,
            21.01964531828845
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Liên Hoa",
        "diemDau": "6-1",
        "diemCuoi": "6-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019339863145937,
        "to_latitude": 21.01964531828845,
        "to_longitude": 105.83691235893555,
        "from_longitude": 105.83677288407007,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1506,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83691235893555,
          21.01964531828845,
          105.83700087183595,
          21.019722933839745
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83691235893555,
            21.01964531828845
          ],
          [
            105.83700087183595,
            21.019722933839745
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Liên Hoa",
        "diemDau": "6-2",
        "diemCuoi": "6-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01964531828845,
        "to_latitude": 21.019722933839745,
        "to_longitude": 105.83700087183595,
        "from_longitude": 105.83691235893555,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1507,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83684798592279,
          21.019722933839745,
          105.83700087183595,
          21.019818075428212
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83700087183595,
            21.019722933839745
          ],
          [
            105.83684798592279,
            21.019818075428212
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Liên Hoa",
        "diemDau": "6-3",
        "diemCuoi": "6-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019722933839745,
        "to_latitude": 21.019818075428212,
        "to_longitude": 105.83684798592279,
        "from_longitude": 105.83700087183595,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1508,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83700087183595,
          21.019722933839745,
          105.83710547798279,
          21.019745467379522
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83700087183595,
            21.019722933839745
          ],
          [
            105.83710547798279,
            21.019745467379522
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Liên Hoa",
        "diemDau": "6-3",
        "diemCuoi": "6-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019722933839745,
        "to_latitude": 21.019745467379522,
        "to_longitude": 105.83710547798279,
        "from_longitude": 105.83700087183595,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1509,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83710547798279,
          21.019745467379522,
          105.83718326205222,
          21.019928239296473
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83710547798279,
            21.019745467379522
          ],
          [
            105.83718326205222,
            21.019928239296473
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Liên Hoa",
        "diemDau": "6-5",
        "diemCuoi": "6-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019745467379522,
        "to_latitude": 21.019928239296473,
        "to_longitude": 105.83718326205222,
        "from_longitude": 105.83710547798279,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1510,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83701428288364,
          21.019928239296473,
          105.83718326205222,
          21.02001837331016
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83718326205222,
            21.019928239296473
          ],
          [
            105.83701428288364,
            21.02001837331016
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Liên Hoa",
        "diemDau": "6-6",
        "diemCuoi": "6-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019928239296473,
        "to_latitude": 21.02001837331016,
        "to_longitude": 105.83701428288364,
        "from_longitude": 105.83718326205222,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1511,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8366521846588,
          21.019339863145937,
          105.83677288407007,
          21.019435004978835
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83677288407007,
            21.019339863145937
          ],
          [
            105.83674874419142,
            21.019392441534908
          ],
          [
            105.8366521846588,
            21.019435004978835
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Liên Hoa",
        "diemDau": "6-1",
        "diemCuoi": "6-1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019339863145937,
        "to_latitude": 21.019435004978835,
        "to_longitude": 105.8366521846588,
        "from_longitude": 105.83677288407007,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1512,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81566815085054,
          21.013326047307505,
          105.81587401047891,
          21.013776110815332
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81587401047891,
            21.013776110815332
          ],
          [
            105.81567619750969,
            21.013448109169065
          ],
          [
            105.81566815085054,
            21.013326047307505
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 192 Thái Thịnh",
        "diemDau": "1-2",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.013776110815332,
        "to_latitude": 21.013326047307505,
        "to_longitude": 105.81566815085054,
        "from_longitude": 105.81587401047891,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1513,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8368444962609,
          21.019174881952694,
          105.8368694435937,
          21.019264751130237
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8368444962609,
            21.019174881952694
          ],
          [
            105.8368694435937,
            21.019264751130237
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 132",
        "diemDau": "05",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019174881952694,
        "to_latitude": 21.019264751130237,
        "to_longitude": 105.8368694435937,
        "from_longitude": 105.8368444962609,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1514,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8368694435937,
          21.019264751130237,
          105.83719667309096,
          21.019595243718097
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8368694435937,
            21.019264751130237
          ],
          [
            105.83719667309096,
            21.019595243718097
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 132",
        "diemDau": "5-1",
        "diemCuoi": "5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019264751130237,
        "to_latitude": 21.019595243718097,
        "to_longitude": 105.83719667309096,
        "from_longitude": 105.8368694435937,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1515,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81605573008486,
          21.014012722251184,
          105.81612747922729,
          21.01405653913674
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81605573008486,
            21.014012722251184
          ],
          [
            105.81612747922729,
            21.01405653913674
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 8,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 151B Thái Hà",
        "diemDau": "",
        "diemCuoi": "01",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.014012722251184,
        "to_latitude": 21.01405653913674,
        "to_longitude": 105.81612747922729,
        "from_longitude": 105.81605573008486,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1516,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81605573008486,
          21.01398267637603,
          105.81609462211507,
          21.014012722251184
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81605573008486,
            21.014012722251184
          ],
          [
            105.81609462211507,
            21.01398267637603
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "I17TC",
        "chieuDaiQL": 8,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 151B Thái Hà",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "I 17 Thành Công",
        "from_latitude": 21.014012722251184,
        "to_latitude": 21.01398267637603,
        "to_longitude": 105.81609462211507,
        "from_longitude": 105.81605573008486,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1517,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83630081529189,
          21.019299803410114,
          105.83637859935234,
          21.019457538563803
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83637859935234,
            21.019457538563803
          ],
          [
            105.83630081529189,
            21.019299803410114
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đại Đồng",
        "diemDau": "6-8-1",
        "diemCuoi": "6-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019457538563803,
        "to_latitude": 21.019299803410114,
        "to_longitude": 105.83630081529189,
        "from_longitude": 105.83637859935234,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1518,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83628124858362,
          21.019208392253116,
          105.83637859935234,
          21.019457538563803
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83637859935234,
            21.019457538563803
          ],
          [
            105.83628124858362,
            21.019208392253116
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đại Đồng",
        "diemDau": "6+1",
        "diemCuoi": "6-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019457538563803,
        "to_latitude": 21.019208392253116,
        "to_longitude": 105.83628124858362,
        "from_longitude": 105.83637859935234,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1519,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83634104843499,
          21.019457538563803,
          105.83637859935234,
          21.01968537793477
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83637859935234,
            21.019457538563803
          ],
          [
            105.83634104843499,
            21.01968537793477
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đại Đồng",
        "diemDau": "6-8",
        "diemCuoi": "6-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019457538563803,
        "to_latitude": 21.01968537793477,
        "to_longitude": 105.83634104843499,
        "from_longitude": 105.83637859935234,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1520,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83616670484192,
          21.01968537793477,
          105.83634104843499,
          21.01987065365023
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83634104843499,
            21.01968537793477
          ],
          [
            105.83616670484192,
            21.01987065365023
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 51,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đại Đồng",
        "diemDau": "6-9",
        "diemCuoi": "6-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01968537793477,
        "to_latitude": 21.01987065365023,
        "to_longitude": 105.83616670484192,
        "from_longitude": 105.83634104843499,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1521,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83616670484192,
          21.01987065365023,
          105.8362337600714,
          21.020073455182168
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83616670484192,
            21.01987065365023
          ],
          [
            105.8362337600714,
            21.020073455182168
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đại Đồng",
        "diemDau": "6-10",
        "diemCuoi": "6-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01987065365023,
        "to_latitude": 21.020073455182168,
        "to_longitude": 105.8362337600714,
        "from_longitude": 105.83616670484192,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1522,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83608087415823,
          21.020073455182168,
          105.8362337600714,
          21.02015107051061
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8362337600714,
            21.020073455182168
          ],
          [
            105.83608087415823,
            21.02015107051061
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đại Đồng",
        "diemDau": "6-11",
        "diemCuoi": "6-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.020073455182168,
        "to_latitude": 21.02015107051061,
        "to_longitude": 105.83608087415823,
        "from_longitude": 105.8362337600714,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1523,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8362337600714,
          21.020073455182168,
          105.83629813308416,
          21.02020865605104
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8362337600714,
            21.020073455182168
          ],
          [
            105.83629813308416,
            21.02020865605104
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đại Đồng",
        "diemDau": "6-11",
        "diemCuoi": "6-11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.020073455182168,
        "to_latitude": 21.02020865605104,
        "to_longitude": 105.83629813308416,
        "from_longitude": 105.8362337600714,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1524,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83605057861001,
          21.019218407194867,
          105.83609428519694,
          21.01965282947406
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83605057861001,
            21.019218407194867
          ],
          [
            105.83609428519694,
            21.01965282947406
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 176",
        "diemDau": "07",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019218407194867,
        "to_latitude": 21.01965282947406,
        "to_longitude": 105.83609428519694,
        "from_longitude": 105.83605057861001,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1525,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80093880014525,
          21.02187738372241,
          105.80116345789229,
          21.02208097751236
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8009709516847,
            21.02187738372241
          ],
          [
            105.80093880014525,
            21.021974665813357
          ],
          [
            105.80116345789229,
            21.02208097751236
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 942 đường láng",
        "diemDau": "12",
        "diemCuoi": "12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.02187738372241,
        "to_latitude": 21.02208097751236,
        "to_longitude": 105.80116345789229,
        "from_longitude": 105.8009709516847,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1526,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82735990003934,
          21.015281216018337,
          105.82752049727057,
          21.015517825055564
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82735990003934,
            21.015294673942527
          ],
          [
            105.82738504564485,
            21.015281216018337
          ],
          [
            105.82752049727057,
            21.015517825055564
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "TỦ",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.015294673942527,
        "to_latitude": 21.015517825055564,
        "to_longitude": 105.82752049727057,
        "from_longitude": 105.82735990003934,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1527,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82746417088104,
          21.01533504773209,
          105.82752049727057,
          21.015517825055564
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82752049727057,
            21.015517825055564
          ],
          [
            105.82746417088104,
            21.01533504773209
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.015517825055564,
        "to_latitude": 21.01533504773209,
        "to_longitude": 105.82746417088104,
        "from_longitude": 105.82752049727057,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1528,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82752049727057,
          21.015517825055564,
          105.82753390830928,
          21.01567806800401
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82752049727057,
            21.015517825055564
          ],
          [
            105.82753390830928,
            21.01567806800401
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.015517825055564,
        "to_latitude": 21.01567806800401,
        "to_longitude": 105.82753390830928,
        "from_longitude": 105.82752049727057,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1529,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82779944700151,
          21.015718128713605,
          105.82789064210965,
          21.015863348697536
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82779944700151,
            21.015718128713605
          ],
          [
            105.82789064210965,
            21.015863348697536
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.015718128713605,
        "to_latitude": 21.015863348697536,
        "to_longitude": 105.82789064210965,
        "from_longitude": 105.82779944700151,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1530,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82789064210965,
          21.015863348697536,
          105.82797915501007,
          21.016006064751206
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82789064210965,
            21.015863348697536
          ],
          [
            105.82797915501007,
            21.016006064751206
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.015863348697536,
        "to_latitude": 21.016006064751206,
        "to_longitude": 105.82797915501007,
        "from_longitude": 105.82789064210965,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1531,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82738504564485,
          21.015163537302385,
          105.82764924329608,
          21.015281216018337
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82738504564485,
            21.015281216018337
          ],
          [
            105.82764924329608,
            21.015163537302385
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "TỦ",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.015281216018337,
        "to_latitude": 21.015163537302385,
        "to_longitude": 105.82764924329608,
        "from_longitude": 105.82738504564485,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1532,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82735151810199,
          21.015137247363022,
          105.82738504564485,
          21.015281216018337
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82738504564485,
            21.015281216018337
          ],
          [
            105.82735151810199,
            21.015137247363022
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "TỦ",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.015281216018337,
        "to_latitude": 21.015137247363022,
        "to_longitude": 105.82735151810199,
        "from_longitude": 105.82738504564485,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1533,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82719326776436,
          21.014866836307498,
          105.82735151810199,
          21.015137247363022
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82735151810199,
            21.015137247363022
          ],
          [
            105.82719326776436,
            21.014866836307498
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.015137247363022,
        "to_latitude": 21.014866836307498,
        "to_longitude": 105.82719326776436,
        "from_longitude": 105.82735151810199,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1534,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8269867376604,
          21.014866836307498,
          105.82719326776436,
          21.014908149142425
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82719326776436,
            21.014866836307498
          ],
          [
            105.8269867376604,
            21.014908149142425
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "12",
        "diemCuoi": "12-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.014866836307498,
        "to_latitude": 21.014908149142425,
        "to_longitude": 105.8269867376604,
        "from_longitude": 105.82719326776436,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1535,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82680702966087,
          21.014908149142425,
          105.8269867376604,
          21.01500579760843
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8269867376604,
            21.014908149142425
          ],
          [
            105.82680702966087,
            21.01500579760843
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "12-1",
        "diemCuoi": "12-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.014908149142425,
        "to_latitude": 21.01500579760843,
        "to_longitude": 105.82680702966087,
        "from_longitude": 105.8269867376604,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1536,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82660586398137,
          21.01500579760843,
          105.82680702966087,
          21.01511095742406
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82680702966087,
            21.01500579760843
          ],
          [
            105.82660586398137,
            21.01511095742406
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "12-2",
        "diemCuoi": "12-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.01500579760843,
        "to_latitude": 21.01511095742406,
        "to_longitude": 105.82660586398137,
        "from_longitude": 105.82680702966087,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1537,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82647175353141,
          21.0149006377195,
          105.82660586398137,
          21.01511095742406
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82660586398137,
            21.01511095742406
          ],
          [
            105.82647175353141,
            21.0149006377195
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "12-3",
        "diemCuoi": "12-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.01511095742406,
        "to_latitude": 21.0149006377195,
        "to_longitude": 105.82647175353141,
        "from_longitude": 105.82660586398137,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1538,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8263295964582,
          21.01472036345098,
          105.82647175353141,
          21.0149006377195
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82647175353141,
            21.0149006377195
          ],
          [
            105.8263295964582,
            21.01472036345098
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "12-4",
        "diemCuoi": "12-5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.0149006377195,
        "to_latitude": 21.01472036345098,
        "to_longitude": 105.8263295964582,
        "from_longitude": 105.82647175353141,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1539,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82705647510662,
          21.01456637900415,
          105.82719326776436,
          21.014866836307498
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82719326776436,
            21.014866836307498
          ],
          [
            105.82705647510662,
            21.01456637900415
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.014866836307498,
        "to_latitude": 21.01456637900415,
        "to_longitude": 105.82705647510662,
        "from_longitude": 105.82719326776436,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1540,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82692504686439,
          21.014278440187073,
          105.82705647510662,
          21.01456637900415
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82705647510662,
            21.01456637900415
          ],
          [
            105.82692504686439,
            21.014278440187073
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.01456637900415,
        "to_latitude": 21.014278440187073,
        "to_longitude": 105.82692504686439,
        "from_longitude": 105.82705647510662,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1541,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82676813757674,
          21.0140318138951,
          105.82692504686439,
          21.014278440187073
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82692504686439,
            21.014278440187073
          ],
          [
            105.82676813757674,
            21.0140318138951
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "14",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.014278440187073,
        "to_latitude": 21.0140318138951,
        "to_longitude": 105.82676813757674,
        "from_longitude": 105.82692504686439,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1542,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82676813757674,
          21.0140318138951,
          105.82684189838845,
          21.014068119308554
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82676813757674,
            21.0140318138951
          ],
          [
            105.82684189838845,
            21.014068119308554
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 8,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.0140318138951,
        "to_latitude": 21.014068119308554,
        "to_longitude": 105.82684189838845,
        "from_longitude": 105.82676813757674,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1543,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82653344435441,
          21.0140318138951,
          105.82676813757674,
          21.014178287425324
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82676813757674,
            21.0140318138951
          ],
          [
            105.82653344435441,
            21.014178287425324
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "15",
        "diemCuoi": "15-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.0140318138951,
        "to_latitude": 21.014178287425324,
        "to_longitude": 105.82653344435441,
        "from_longitude": 105.82676813757674,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1544,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82642615599082,
          21.01392540140127,
          105.82653344435441,
          21.014178287425324
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82653344435441,
            21.014178287425324
          ],
          [
            105.82642615599082,
            21.01392540140127
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "15-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.014178287425324,
        "to_latitude": 21.01392540140127,
        "to_longitude": 105.82642615599082,
        "from_longitude": 105.82653344435441,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1545,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82663268608576,
          21.01376265274322,
          105.82676813757674,
          21.0140318138951
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82676813757674,
            21.0140318138951
          ],
          [
            105.82663268608576,
            21.01376265274322
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.0140318138951,
        "to_latitude": 21.01376265274322,
        "to_longitude": 105.82663268608576,
        "from_longitude": 105.82676813757674,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1546,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82649589342803,
          21.013564850288812,
          105.82663268608576,
          21.01376265274322
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82663268608576,
            21.01376265274322
          ],
          [
            105.82649589342803,
            21.013564850288812
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.01376265274322,
        "to_latitude": 21.013564850288812,
        "to_longitude": 105.82649589342803,
        "from_longitude": 105.82663268608576,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1547,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82634032529816,
          21.01332698623016,
          105.82649589342803,
          21.013564850288812
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82649589342803,
            21.013564850288812
          ],
          [
            105.82634032529816,
            21.01332698623016
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.013564850288812,
        "to_latitude": 21.01332698623016,
        "to_longitude": 105.82634032529816,
        "from_longitude": 105.82649589342803,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1548,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8262799755341,
          21.013198038717874,
          105.82634032529816,
          21.01332698623016
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82634032529816,
            21.01332698623016
          ],
          [
            105.8262799755341,
            21.01321806940874
          ],
          [
            105.82631216208992,
            21.013198038717874
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.01332698623016,
        "to_latitude": 21.013198038717874,
        "to_longitude": 105.82631216208992,
        "from_longitude": 105.82634032529816,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1549,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82625851790813,
          21.013110404419358,
          105.82631216208992,
          21.013198038717874
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82631216208992,
            21.013198038717874
          ],
          [
            105.82625851790813,
            21.013110404419358
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.013198038717874,
        "to_latitude": 21.013110404419358,
        "to_longitude": 105.82625851790813,
        "from_longitude": 105.82631216208992,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1550,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82622633139725,
          21.013198038717874,
          105.82631216208992,
          21.013210557899047
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82631216208992,
            21.013198038717874
          ],
          [
            105.82622633139725,
            21.013210557899047
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "19",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.013198038717874,
        "to_latitude": 21.013210557899047,
        "to_longitude": 105.82622633139725,
        "from_longitude": 105.82631216208992,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1551,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82619012159272,
          21.01332698623016,
          105.82634032529816,
          21.013404605069926
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82634032529816,
            21.01332698623016
          ],
          [
            105.82619012159272,
            21.013404605069926
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "18",
        "diemCuoi": "18-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.01332698623016,
        "to_latitude": 21.013404605069926,
        "to_longitude": 105.82619012159272,
        "from_longitude": 105.82634032529816,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1552,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82598895592224,
          21.013404605069926,
          105.82619012159272,
          21.013484727700455
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82619012159272,
            21.013404605069926
          ],
          [
            105.82598895592224,
            21.013484727700455
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "18-1",
        "diemCuoi": "18-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.013404605069926,
        "to_latitude": 21.013484727700455,
        "to_longitude": 105.82598895592224,
        "from_longitude": 105.82619012159272,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1553,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82574755710868,
          21.013484727700455,
          105.82598895592224,
          21.013592392417937
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82598895592224,
            21.013484727700455
          ],
          [
            105.82574755710868,
            21.013592392417937
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "18-2",
        "diemCuoi": "18-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.013484727700455,
        "to_latitude": 21.013592392417937,
        "to_longitude": 105.82574755710868,
        "from_longitude": 105.82598895592224,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1554,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82558662456331,
          21.013592392417937,
          105.82574755710868,
          21.01368002643324
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82574755710868,
            21.013592392417937
          ],
          [
            105.82558662456331,
            21.01368002643324
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "18-3",
        "diemCuoi": "18-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.013592392417937,
        "to_latitude": 21.01368002643324,
        "to_longitude": 105.82558662456331,
        "from_longitude": 105.82574755710868,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1555,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82541362202022,
          21.01368002643324,
          105.82558662456331,
          21.01380772161976
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82558662456331,
            21.01368002643324
          ],
          [
            105.82541362202022,
            21.013771416142937
          ],
          [
            105.82542837423469,
            21.01380772161976
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "18-4",
        "diemCuoi": "18-5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.01368002643324,
        "to_latitude": 21.01380772161976,
        "to_longitude": 105.82542837423469,
        "from_longitude": 105.82558662456331,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1556,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82634568972261,
          21.013564850288812,
          105.82649589342803,
          21.01363495751728
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82649589342803,
            21.013564850288812
          ],
          [
            105.82634568972261,
            21.01363495751728
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "17",
        "diemCuoi": "17-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.013564850288812,
        "to_latitude": 21.01363495751728,
        "to_longitude": 105.82634568972261,
        "from_longitude": 105.82649589342803,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1557,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8260050491777,
          21.01363495751728,
          105.82634568972261,
          21.013817736924157
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82634568972261,
            21.01363495751728
          ],
          [
            105.8260050491777,
            21.013817736924157
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "17-1",
        "diemCuoi": "17-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.01363495751728,
        "to_latitude": 21.013817736924157,
        "to_longitude": 105.8260050491777,
        "from_longitude": 105.82634568972261,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1558,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82596749825133,
          21.013817736924157,
          105.82602516568186,
          21.01394042434496
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8260050491777,
            21.013817736924157
          ],
          [
            105.82602516568186,
            21.01390662272457
          ],
          [
            105.82596749825133,
            21.01394042434496
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "17-2",
        "diemCuoi": "17-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.013817736924157,
        "to_latitude": 21.01394042434496,
        "to_longitude": 105.82596749825133,
        "from_longitude": 105.8260050491777,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1559,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82586557430322,
          21.01378017952955,
          105.82596749825133,
          21.01394042434496
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82596749825133,
            21.01394042434496
          ],
          [
            105.82586557430322,
            21.01378017952955
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "17-3",
        "diemCuoi": "17-6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.01394042434496,
        "to_latitude": 21.01378017952955,
        "to_longitude": 105.82586557430322,
        "from_longitude": 105.82596749825133,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1560,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8259245829005,
          21.01394042434496,
          105.82596749825133,
          21.01408814988174
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82596749825133,
            21.01394042434496
          ],
          [
            105.8259245829005,
            21.01408814988174
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "17-3",
        "diemCuoi": "17-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.01394042434496,
        "to_latitude": 21.01408814988174,
        "to_longitude": 105.8259245829005,
        "from_longitude": 105.82596749825133,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1561,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8258856908074,
          21.01408814988174,
          105.8259245829005,
          21.014200821802927
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8259245829005,
            21.01408814988174
          ],
          [
            105.8258856908074,
            21.014129462934868
          ],
          [
            105.8258977608141,
            21.014200821802927
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "17-4",
        "diemCuoi": "17-5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.01408814988174,
        "to_latitude": 21.014200821802927,
        "to_longitude": 105.8258977608141,
        "from_longitude": 105.8259245829005,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1562,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82804621023054,
          21.01612875037269,
          105.82818836731275,
          21.016151284454764
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82804621023054,
            21.01612875037269
          ],
          [
            105.82818836731275,
            21.016151284454764
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "08",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.01612875037269,
        "to_latitude": 21.016151284454764,
        "to_longitude": 105.82818836731275,
        "from_longitude": 105.82804621023054,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1563,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82627192889294,
          21.01472036345098,
          105.8263295964582,
          21.014730378699998
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8263295964582,
            21.01472036345098
          ],
          [
            105.82627192889294,
            21.014730378699998
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 8,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "12-5",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.01472036345098,
        "to_latitude": 21.014730378699998,
        "to_longitude": 105.82627192889294,
        "from_longitude": 105.8263295964582,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1564,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82627192889294,
          21.014730378699998,
          105.82628131669188,
          21.014800485374742
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82627192889294,
            21.014730378699998
          ],
          [
            105.82628131669188,
            21.014800485374742
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "12-6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.014730378699998,
        "to_latitude": 21.014800485374742,
        "to_longitude": 105.82628131669188,
        "from_longitude": 105.82627192889294,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1565,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82621962588688,
          21.014652760543484,
          105.82627192889294,
          21.014730378699998
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82627192889294,
            21.014730378699998
          ],
          [
            105.82621962588688,
            21.014652760543484
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "12-7",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.014730378699998,
        "to_latitude": 21.014652760543484,
        "to_longitude": 105.82621962588688,
        "from_longitude": 105.82627192889294,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1566,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8265267387452,
          21.014178287425324,
          105.82653344435441,
          21.014274684469026
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82653344435441,
            21.014178287425324
          ],
          [
            105.8265267387452,
            21.014274684469026
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "15-1",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.014178287425324,
        "to_latitude": 21.014274684469026,
        "to_longitude": 105.8265267387452,
        "from_longitude": 105.82653344435441,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1567,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8263376430904,
          21.014274684469026,
          105.8265267387452,
          21.01435605853167
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8265267387452,
            21.014274684469026
          ],
          [
            105.8263376430904,
            21.01435605853167
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "15-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.014274684469026,
        "to_latitude": 21.01435605853167,
        "to_longitude": 105.8263376430904,
        "from_longitude": 105.8265267387452,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1568,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82797915501007,
          21.016006064751206,
          105.82804621023054,
          21.01612875037269
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82797915501007,
            21.016006064751206
          ],
          [
            105.82804621023054,
            21.01612875037269
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.016006064751206,
        "to_latitude": 21.01612875037269,
        "to_longitude": 105.82804621023054,
        "from_longitude": 105.82797915501007,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1569,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82804621023054,
          21.01612875037269,
          105.82805962127823,
          21.01618633746564
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82804621023054,
            21.01612875037269
          ],
          [
            105.82805962127823,
            21.01618633746564
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.01612875037269,
        "to_latitude": 21.01618633746564,
        "to_longitude": 105.82805962127823,
        "from_longitude": 105.82804621023054,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1570,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82753390830928,
          21.01567806800401,
          105.82779944700151,
          21.015771960285612
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82753390830928,
            21.01567806800401
          ],
          [
            105.82764924322422,
            21.015771960285612
          ],
          [
            105.82779944700151,
            21.015718128713605
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND2",
        "from_latitude": 21.01567806800401,
        "to_latitude": 21.015718128713605,
        "to_longitude": 105.82779944700151,
        "from_longitude": 105.82753390830928,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1571,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84092349576235,
          21.018897680493485,
          105.84119549883916,
          21.01890730228243
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84119549883916,
            21.018897680493485
          ],
          [
            105.84092349576235,
            21.01890730228243
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018897680493485,
        "to_latitude": 21.01890730228243,
        "to_longitude": 105.84092349576235,
        "from_longitude": 105.84119549883916,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1572,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83539258566059,
          21.01926942793389,
          105.83540431528724,
          21.019414775305485
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83539258566059,
            21.01926942793389
          ],
          [
            105.83540431528724,
            21.019414775305485
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hòa Bình",
        "diemDau": "09",
        "diemCuoi": "9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01926942793389,
        "to_latitude": 21.019414775305485,
        "to_longitude": 105.83540431528724,
        "from_longitude": 105.83539258566059,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1573,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83533726006674,
          21.019414775305485,
          105.8354194307714,
          21.01968517811431
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83540431528724,
            21.019414775305485
          ],
          [
            105.8354194307714,
            21.019615579217476
          ],
          [
            105.83533726006674,
            21.01968517811431
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hòa Bình",
        "diemDau": "9-1",
        "diemCuoi": "9-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019414775305485,
        "to_latitude": 21.01968517811431,
        "to_longitude": 105.83533726006674,
        "from_longitude": 105.83540431528724,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1574,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83533726006674,
          21.019540467340672,
          105.83565814736824,
          21.01968517811431
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83533726006674,
            21.01968517811431
          ],
          [
            105.83543650179811,
            21.019660140837946
          ],
          [
            105.83563668969731,
            21.019613075489193
          ],
          [
            105.83565814736824,
            21.019540467340672
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hòa Bình",
        "diemDau": "9-2",
        "diemCuoi": "9-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01968517811431,
        "to_latitude": 21.019540467340672,
        "to_longitude": 105.83565814736824,
        "from_longitude": 105.83533726006674,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1575,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83533726006674,
          21.01968517811431,
          105.8354284551749,
          21.01996058787865
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83533726006674,
            21.01968517811431
          ],
          [
            105.8354284551749,
            21.01996058787865
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hòa Bình",
        "diemDau": "9-2",
        "diemCuoi": "9-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01968517811431,
        "to_latitude": 21.01996058787865,
        "to_longitude": 105.8354284551749,
        "from_longitude": 105.83533726006674,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1576,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8354284551749,
          21.019928039478838,
          105.83559206991902,
          21.01996058787865
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8354284551749,
            21.01996058787865
          ],
          [
            105.83559206991902,
            21.019928039478838
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hòa Bình",
        "diemDau": "9-9",
        "diemCuoi": "9-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01996058787865,
        "to_latitude": 21.019928039478838,
        "to_longitude": 105.83559206991902,
        "from_longitude": 105.8354284551749,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1577,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83537919763731,
          21.019946071027725,
          105.8354284551749,
          21.01996058787865
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8354284551749,
            21.01996058787865
          ],
          [
            105.83537919763731,
            21.019946071027725
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hòa Bình",
        "diemDau": "9-9",
        "diemCuoi": "9-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01996058787865,
        "to_latitude": 21.019946071027725,
        "to_longitude": 105.83537919763731,
        "from_longitude": 105.8354284551749,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1578,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8353667643609,
          21.019946071027725,
          105.83537919763731,
          21.020168396727996
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83537919763731,
            21.019946071027725
          ],
          [
            105.8353667643609,
            21.020168396727996
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hòa Bình",
        "diemDau": "9-4",
        "diemCuoi": "9-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019946071027725,
        "to_latitude": 21.020168396727996,
        "to_longitude": 105.8353667643609,
        "from_longitude": 105.83537919763731,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1579,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83537919763731,
          21.019946071027725,
          105.83545795946904,
          21.02036619042374
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83537919763731,
            21.019946071027725
          ],
          [
            105.83545795946904,
            21.02036619042374
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hòa Bình",
        "diemDau": "9-4",
        "diemCuoi": "9-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019946071027725,
        "to_latitude": 21.02036619042374,
        "to_longitude": 105.83545795946904,
        "from_longitude": 105.83537919763731,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1580,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8351977851923,
          21.02036619042374,
          105.83545795946904,
          21.020411257305437
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83545795946904,
            21.02036619042374
          ],
          [
            105.8351977851923,
            21.020411257305437
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hòa Bình",
        "diemDau": "9-5",
        "diemCuoi": "9-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.02036619042374,
        "to_latitude": 21.020411257305437,
        "to_longitude": 105.8351977851923,
        "from_longitude": 105.83545795946904,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1581,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83545795946904,
          21.02036619042374,
          105.83557468211839,
          21.020369635616397
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83545795946904,
            21.02036619042374
          ],
          [
            105.83557468211839,
            21.020369635616397
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hòa Bình",
        "diemDau": "9-5",
        "diemCuoi": "9-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.02036619042374,
        "to_latitude": 21.020369635616397,
        "to_longitude": 105.83557468211839,
        "from_longitude": 105.83545795946904,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1582,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83557468211839,
          21.020369635616397,
          105.83579899282181,
          21.020379876203542
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83557468211839,
            21.020369635616397
          ],
          [
            105.83579899282181,
            21.020379876203542
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hòa Bình",
        "diemDau": "9-11",
        "diemCuoi": "9-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.020369635616397,
        "to_latitude": 21.020379876203542,
        "to_longitude": 105.83579899282181,
        "from_longitude": 105.83557468211839,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1583,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84074354661902,
          21.01890730228243,
          105.84092349576235,
          21.018918962287593
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84092349576235,
            21.01890730228243
          ],
          [
            105.84074354661902,
            21.018918962287593
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "02",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01890730228243,
        "to_latitude": 21.018918962287593,
        "to_longitude": 105.84074354661902,
        "from_longitude": 105.84092349576235,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1584,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84049678338998,
          21.018918962287593,
          105.84074354661902,
          21.018930229118236
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84049678338998,
            21.018930229118236
          ],
          [
            105.84074354661902,
            21.018918962287593
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018930229118236,
        "to_latitude": 21.018918962287593,
        "to_longitude": 105.84074354661902,
        "from_longitude": 105.84049678338998,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1585,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8402071048191,
          21.018930229118236,
          105.84049678338998,
          21.0189477552981
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84049678338998,
            21.018930229118236
          ],
          [
            105.8402071048191,
            21.0189477552981
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018930229118236,
        "to_latitude": 21.0189477552981,
        "to_longitude": 105.8402071048191,
        "from_longitude": 105.84049678338998,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1586,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83981147898854,
          21.0189477552981,
          105.8402071048191,
          21.018982807651632
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8402071048191,
            21.0189477552981
          ],
          [
            105.83981147898854,
            21.018982807651632
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "05",
        "diemCuoi": "05+1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.0189477552981,
        "to_latitude": 21.018982807651632,
        "to_longitude": 105.83981147898854,
        "from_longitude": 105.8402071048191,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1587,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83954325808855,
          21.018982807651632,
          105.83981147898854,
          21.018995326347223
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83981147898854,
            21.018982807651632
          ],
          [
            105.83954325808855,
            21.018995326347223
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "05+1",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018982807651632,
        "to_latitude": 21.018995326347223,
        "to_longitude": 105.83954325808855,
        "from_longitude": 105.83981147898854,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1588,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83923940375213,
          21.018995326347223,
          105.83954325808855,
          21.019036557830166
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83954325808855,
            21.018995326347223
          ],
          [
            105.83923940375213,
            21.019036557830166
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018995326347223,
        "to_latitude": 21.019036557830166,
        "to_longitude": 105.83923940375213,
        "from_longitude": 105.83954325808855,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1589,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83831881573755,
          21.01907536576679,
          105.83867345765806,
          21.019085770390568
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83867345765806,
            21.01907536576679
          ],
          [
            105.83831881573755,
            21.019085770390568
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "08+1",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01907536576679,
        "to_latitude": 21.019085770390568,
        "to_longitude": 105.83831881573755,
        "from_longitude": 105.83867345765806,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1590,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84069316108491,
          21.018918962287593,
          105.84074354661902,
          21.01903780969706
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84074354661902,
            21.018918962287593
          ],
          [
            105.84069316108491,
            21.01903780969706
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2b",
        "diemDau": "03",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018918962287593,
        "to_latitude": 21.01903780969706,
        "to_longitude": 105.84069316108491,
        "from_longitude": 105.84074354661902,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1591,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84069316108491,
          21.01903780969706,
          105.8406945021888,
          21.019225589951972
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84069316108491,
            21.01903780969706
          ],
          [
            105.8406945021888,
            21.019225589951972
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2b",
        "diemDau": "3-1",
        "diemCuoi": "3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01903780969706,
        "to_latitude": 21.019225589951972,
        "to_longitude": 105.8406945021888,
        "from_longitude": 105.84069316108491,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1592,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84058319051361,
          21.019225589951972,
          105.8406945021888,
          21.019295694519357
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8406945021888,
            21.019225589951972
          ],
          [
            105.84058319051361,
            21.019295694519357
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2b",
        "diemDau": "3-2",
        "diemCuoi": "3-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.019225589951972,
        "to_latitude": 21.019295694519357,
        "to_longitude": 105.84058319051361,
        "from_longitude": 105.8406945021888,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1593,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8402071048191,
          21.0189477552981,
          105.84041555243091,
          21.019228093686753
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8402071048191,
            21.0189477552981
          ],
          [
            105.84041018801543,
            21.018983979312356
          ],
          [
            105.84041555243091,
            21.019174263370747
          ],
          [
            105.84032033401115,
            21.019228093686753
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2E",
        "diemDau": "05",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.0189477552981,
        "to_latitude": 21.019228093686753,
        "to_longitude": 105.84032033401115,
        "from_longitude": 105.8402071048191,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1594,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80274392683597,
          21.021648608825636,
          105.8028025651387,
          21.02191582910976
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8028025651387,
            21.021648608825636
          ],
          [
            105.80274392683597,
            21.02191582910976
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-8",
        "diemCuoi": "8-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.021648608825636,
        "to_latitude": 21.02191582910976,
        "to_longitude": 105.80274392683597,
        "from_longitude": 105.8028025651387,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1595,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80263932068911,
          21.02191582910976,
          105.80274392683597,
          21.02218372369767
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80274392683597,
            21.02191582910976
          ],
          [
            105.80263932068911,
            21.02218372369767
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "850ĐL",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "diemDau": "8-9",
        "diemCuoi": "8-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "850 Đường Láng",
        "from_latitude": 21.02191582910976,
        "to_latitude": 21.02218372369767,
        "to_longitude": 105.80263932068911,
        "from_longitude": 105.80274392683597,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1596,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83980669100399,
          21.018982807651632,
          105.83981147898854,
          21.019157989088413
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83981147898854,
            21.018982807651632
          ],
          [
            105.83980669100399,
            21.019157989088413
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thiên Hùng",
        "diemDau": "5+1",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018982807651632,
        "to_latitude": 21.019157989088413,
        "to_longitude": 105.83980669100399,
        "from_longitude": 105.83981147898854,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1597,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83980669100399,
          21.019157989088413,
          105.83981205541946,
          21.019442162887117
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83981205541946,
            21.019442162887117
          ],
          [
            105.83980669100399,
            21.019157989088413
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thiên Hùng",
        "diemDau": "6-1",
        "diemCuoi": "6-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.019442162887117,
        "to_latitude": 21.019157989088413,
        "to_longitude": 105.83980669100399,
        "from_longitude": 105.83981205541946,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1598,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83981205541946,
          21.019442162887117,
          105.83981741983496,
          21.019641209410565
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83981205541946,
            21.019442162887117
          ],
          [
            105.83981741983496,
            21.019641209410565
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thiên Hùng",
        "diemDau": "6-2",
        "diemCuoi": "6-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.019442162887117,
        "to_latitude": 21.019641209410565,
        "to_longitude": 105.83981741983496,
        "from_longitude": 105.83981205541946,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1599,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83980937321172,
          21.019641209410565,
          105.83981741983496,
          21.01978892929951
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83981741983496,
            21.019641209410565
          ],
          [
            105.83980937321172,
            21.01978892929951
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thiên Hùng",
        "diemDau": "6-3",
        "diemCuoi": "6-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.019641209410565,
        "to_latitude": 21.01978892929951,
        "to_longitude": 105.83980937321172,
        "from_longitude": 105.83981741983496,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1600,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83968465048885,
          21.01978892929951,
          105.83980937321172,
          21.019800196065255
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83968465048885,
            21.019800196065255
          ],
          [
            105.83980937321172,
            21.01978892929951
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thiên Hùng",
        "diemDau": "6-4",
        "diemCuoi": "6-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.019800196065255,
        "to_latitude": 21.01978892929951,
        "to_longitude": 105.83980937321172,
        "from_longitude": 105.83968465048885,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1601,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83957467991752,
          21.019157989088413,
          105.83980669100399,
          21.019318228125382
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83980669100399,
            21.019157989088413
          ],
          [
            105.8397101314624,
            21.019181774579256
          ],
          [
            105.83957467991752,
            21.019318228125382
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thiên Hùng",
        "diemDau": "6-1",
        "diemCuoi": "6-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.019157989088413,
        "to_latitude": 21.019318228125382,
        "to_longitude": 105.83957467991752,
        "from_longitude": 105.83980669100399,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1602,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83962698299543,
          21.01962994263029,
          105.83981741983496,
          21.019705054465366
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83981741983496,
            21.019641209410565
          ],
          [
            105.8397584112197,
            21.01962994263029
          ],
          [
            105.83962698299543,
            21.019705054465366
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thiên Hùng",
        "diemDau": "6-3",
        "diemCuoi": "6-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.019641209410565,
        "to_latitude": 21.019705054465366,
        "to_longitude": 105.83962698299543,
        "from_longitude": 105.83981741983496,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1603,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8368463659639,
          21.01851546637534,
          105.83698567766834,
          21.01855149076152
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83698567766834,
            21.01851546637534
          ],
          [
            105.8368463659639,
            21.01855149076152
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cống Trắng",
        "diemDau": "27",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01851546637534,
        "to_latitude": 21.01855149076152,
        "to_longitude": 105.8368463659639,
        "from_longitude": 105.83698567766834,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1604,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8368463659639,
          21.01855149076152,
          105.83688391689024,
          21.01867417428808
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8368463659639,
            21.01855149076152
          ],
          [
            105.83688391689024,
            21.01867417428808
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cống Trắng",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01855149076152,
        "to_latitude": 21.01867417428808,
        "to_longitude": 105.83688391689024,
        "from_longitude": 105.8368463659639,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1605,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83657009843171,
          21.01867417428808,
          105.83688391689024,
          21.018771820289
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83688391689024,
            21.01867417428808
          ],
          [
            105.83657009843171,
            21.018771820289
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cống Trắng",
        "diemDau": "33",
        "diemCuoi": "34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01867417428808,
        "to_latitude": 21.018771820289,
        "to_longitude": 105.83657009843171,
        "from_longitude": 105.83688391689024,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1606,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83866105246297,
          21.01907536576679,
          105.83867345765806,
          21.019136394360267
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83867345765806,
            21.01907536576679
          ],
          [
            105.83866105246297,
            21.019136394360267
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 6,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tiền",
        "diemDau": "08+1",
        "diemCuoi": "8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01907536576679,
        "to_latitude": 21.019136394360267,
        "to_longitude": 105.83866105246297,
        "from_longitude": 105.83867345765806,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1607,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83851755428589,
          21.019136394360267,
          105.83866105246297,
          21.01947314656064
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83866105246297,
            21.019136394360267
          ],
          [
            105.83851755428589,
            21.01947314656064
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tiền",
        "diemDau": "8-1",
        "diemCuoi": "8-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.019136394360267,
        "to_latitude": 21.01947314656064,
        "to_longitude": 105.83851755428589,
        "from_longitude": 105.83866105246297,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1608,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83841563033779,
          21.01947314656064,
          105.83851755428589,
          21.01956453271442
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83851755428589,
            21.01947314656064
          ],
          [
            105.83841563033779,
            21.01956453271442
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tiền",
        "diemDau": "8-3",
        "diemCuoi": "8-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01947314656064,
        "to_latitude": 21.01956453271442,
        "to_longitude": 105.83841563033779,
        "from_longitude": 105.83851755428589,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1609,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83802268671496,
          21.0196333852589,
          105.83824933337695,
          21.01976858652598
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83824933337695,
            21.0196333852589
          ],
          [
            105.83802268671496,
            21.01976858652598
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tiền",
        "diemDau": "8-5",
        "diemCuoi": "8-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.0196333852589,
        "to_latitude": 21.01976858652598,
        "to_longitude": 105.83802268671496,
        "from_longitude": 105.83824933337695,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1610,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83801464009173,
          21.01976858652598,
          105.83802268671496,
          21.019943847245678
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83802268671496,
            21.01976858652598
          ],
          [
            105.83801464009173,
            21.019943847245678
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tiền",
        "diemDau": "8-6",
        "diemCuoi": "8-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01976858652598,
        "to_latitude": 21.019943847245678,
        "to_longitude": 105.83801464009173,
        "from_longitude": 105.83802268671496,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1611,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83824933337695,
          21.01956453271442,
          105.83841563033779,
          21.0196333852589
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83841563033779,
            21.01956453271442
          ],
          [
            105.83824933337695,
            21.0196333852589
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tiền",
        "diemDau": "8-4",
        "diemCuoi": "8-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01956453271442,
        "to_latitude": 21.0196333852589,
        "to_longitude": 105.83824933337695,
        "from_longitude": 105.83841563033779,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1612,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83920084702706,
          21.019036557830166,
          105.83923940375213,
          21.01914014996674
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83923940375213,
            21.019036557830166
          ],
          [
            105.83920084702706,
            21.01914014996674
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "diemDau": "07",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.019036557830166,
        "to_latitude": 21.01914014996674,
        "to_longitude": 105.83920084702706,
        "from_longitude": 105.83923940375213,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1613,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83920084702706,
          21.01914014996674,
          105.83920487033868,
          21.01941180513989
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83920084702706,
            21.01914014996674
          ],
          [
            105.83920487033868,
            21.01941180513989
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "diemDau": "7-1",
        "diemCuoi": "7-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01914014996674,
        "to_latitude": 21.01941180513989,
        "to_longitude": 105.83920487033868,
        "from_longitude": 105.83920084702706,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1614,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83920487033868,
          21.019394279014577,
          105.83927594887977,
          21.01941180513989
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83920487033868,
            21.01941180513989
          ],
          [
            105.83927594887977,
            21.019394279014577
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "diemDau": "7-2",
        "diemCuoi": "7-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01941180513989,
        "to_latitude": 21.019394279014577,
        "to_longitude": 105.83927594887977,
        "from_longitude": 105.83920487033868,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1615,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83895676600577,
          21.01941180513989,
          105.83920487033868,
          21.019544502878436
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83920487033868,
            21.01941180513989
          ],
          [
            105.83895676600577,
            21.019544502878436
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "diemDau": "7-2",
        "diemCuoi": "7-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01941180513989,
        "to_latitude": 21.019544502878436,
        "to_longitude": 105.83895676600577,
        "from_longitude": 105.83920487033868,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1616,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83879315125266,
          21.019544502878436,
          105.83895676600577,
          21.019708497090576
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83895676600577,
            21.019544502878436
          ],
          [
            105.83879315125266,
            21.019708497090576
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "diemDau": "7-8",
        "diemCuoi": "7-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.019544502878436,
        "to_latitude": 21.019708497090576,
        "to_longitude": 105.83879315125266,
        "from_longitude": 105.83895676600577,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1617,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83865099417945,
          21.019708497090576,
          105.83879315125266,
          21.019832431527913
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83879315125266,
            21.019708497090576
          ],
          [
            105.83865099417945,
            21.019832431527913
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 7,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "diemDau": "7-9",
        "diemCuoi": "7-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.019708497090576,
        "to_latitude": 21.019832431527913,
        "to_longitude": 105.83865099417945,
        "from_longitude": 105.83879315125266,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1618,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83861746656471,
          21.019832431527913,
          105.83865099417945,
          21.01993258049334
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83865099417945,
            21.019832431527913
          ],
          [
            105.83861746656471,
            21.01993258049334
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "diemDau": "7-10",
        "diemCuoi": "7-10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.019832431527913,
        "to_latitude": 21.01993258049334,
        "to_longitude": 105.83861746656471,
        "from_longitude": 105.83865099417945,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1619,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83920487033868,
          21.01941180513989,
          105.8392933832391,
          21.019541999148984
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83920487033868,
            21.01941180513989
          ],
          [
            105.8392933832391,
            21.019541999148984
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "diemDau": "7-2",
        "diemCuoi": "7-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01941180513989,
        "to_latitude": 21.019541999148984,
        "to_longitude": 105.8392933832391,
        "from_longitude": 105.83920487033868,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1620,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83913747984894,
          21.019541999148984,
          105.8392933832391,
          21.019714756408455
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8392933832391,
            21.019541999148984
          ],
          [
            105.83913747984894,
            21.019603653482513
          ],
          [
            105.83919682371545,
            21.019714756408455
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "diemDau": "7-4",
        "diemCuoi": "7-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.019541999148984,
        "to_latitude": 21.019714756408455,
        "to_longitude": 105.83919682371545,
        "from_longitude": 105.8392933832391,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1621,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83911870438578,
          21.019714756408455,
          105.83919682371545,
          21.019789868197446
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83919682371545,
            21.019714756408455
          ],
          [
            105.83911870438578,
            21.01975387713545
          ],
          [
            105.83912172186275,
            21.019789868197446
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "diemDau": "7-5",
        "diemCuoi": "7-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.019714756408455,
        "to_latitude": 21.019789868197446,
        "to_longitude": 105.83912172186275,
        "from_longitude": 105.83919682371545,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1622,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8392933832391,
          21.019541999148984,
          105.8393510507325,
          21.019693474727937
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8392933832391,
            21.019541999148984
          ],
          [
            105.8393510507325,
            21.019693474727937
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "diemDau": "7-4",
        "diemCuoi": "7-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.019541999148984,
        "to_latitude": 21.019693474727937,
        "to_longitude": 105.8393510507325,
        "from_longitude": 105.8392933832391,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1623,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83879315125266,
          21.019708497090576,
          105.8389151917678,
          21.019834935253357
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83879315125266,
            21.019708497090576
          ],
          [
            105.8389151917678,
            21.019834935253357
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "diemDau": "7-9",
        "diemCuoi": "7-9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.019708497090576,
        "to_latitude": 21.019834935253357,
        "to_longitude": 105.8389151917678,
        "from_longitude": 105.83879315125266,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1624,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8392890246223,
          21.018702308389937,
          105.83954325808855,
          21.018995326347223
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83954325808855,
            21.018995326347223
          ],
          [
            105.83946739155394,
            21.01885503668867
          ],
          [
            105.8392890246223,
            21.018702308389937
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "06",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018995326347223,
        "to_latitude": 21.018702308389937,
        "to_longitude": 105.8392890246223,
        "from_longitude": 105.83954325808855,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1625,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83605261098826,
          21.018247587281323,
          105.83615969681652,
          21.018333664659124
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83605261098826,
            21.018247587281323
          ],
          [
            105.83610607627674,
            21.018333664659124
          ],
          [
            105.83615969681652,
            21.018309148247546
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "37",
        "diemCuoi": "37-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018247587281323,
        "to_latitude": 21.018309148247546,
        "to_longitude": 105.83615969681652,
        "from_longitude": 105.83605261098826,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1626,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83605261098826,
          21.01820565160925,
          105.83617964880386,
          21.018247587281323
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83605261098826,
            21.018247587281323
          ],
          [
            105.83617964880386,
            21.01820565160925
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "37",
        "diemCuoi": "37-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018247587281323,
        "to_latitude": 21.01820565160925,
        "to_longitude": 105.83617964880386,
        "from_longitude": 105.83605261098826,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1627,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.835784211195,
          21.018247587281323,
          105.83605261098826,
          21.018333664659124
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83605261098826,
            21.018247587281323
          ],
          [
            105.835784211195,
            21.018333664659124
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "37",
        "diemCuoi": "45",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018247587281323,
        "to_latitude": 21.018333664659124,
        "to_longitude": 105.835784211195,
        "from_longitude": 105.83605261098826,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1628,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83596432844327,
          21.01805482730564,
          105.83605261098826,
          21.018247587281323
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83605261098826,
            21.018247587281323
          ],
          [
            105.83596432844327,
            21.01805482730564
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "37",
        "diemCuoi": "38",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018247587281323,
        "to_latitude": 21.01805482730564,
        "to_longitude": 105.83596432844327,
        "from_longitude": 105.83605261098826,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1629,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83596432844327,
          21.01779492052949,
          105.8359694142882,
          21.01805482730564
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83596432844327,
            21.01805482730564
          ],
          [
            105.8359694142882,
            21.01779492052949
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "38",
        "diemCuoi": "39",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01805482730564,
        "to_latitude": 21.01779492052949,
        "to_longitude": 105.8359694142882,
        "from_longitude": 105.83596432844327,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1630,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83615691046542,
          21.01868588629176,
          105.83625627998215,
          21.01888949615261
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83615691046542,
            21.01868588629176
          ],
          [
            105.83625627998215,
            21.01888949615261
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "35",
        "diemCuoi": "35-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01868588629176,
        "to_latitude": 21.01888949615261,
        "to_longitude": 105.83625627998215,
        "from_longitude": 105.83615691046542,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1631,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83615691046542,
          21.01864794423549,
          105.83638988474027,
          21.01868588629176
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83615691046542,
            21.01868588629176
          ],
          [
            105.83638988474027,
            21.01864794423549
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "35",
        "diemCuoi": "35-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01868588629176,
        "to_latitude": 21.01864794423549,
        "to_longitude": 105.83638988474027,
        "from_longitude": 105.83615691046542,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1632,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83638988474027,
          21.018626603136486,
          105.83652986529762,
          21.01864794423549
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83638988474027,
            21.01864794423549
          ],
          [
            105.83652986529762,
            21.018626603136486
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "35-1",
        "diemCuoi": "35-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01864794423549,
        "to_latitude": 21.018626603136486,
        "to_longitude": 105.83652986529762,
        "from_longitude": 105.83638988474027,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1633,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83615691046542,
          21.01868588629176,
          105.83628124858362,
          21.019208392253116
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83628124858362,
            21.019208392253116
          ],
          [
            105.83615691046542,
            21.01868588629176
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "6-1",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019208392253116,
        "to_latitude": 21.01868588629176,
        "to_longitude": 105.83615691046542,
        "from_longitude": 105.83628124858362,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1634,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83611412289997,
          21.018483889591522,
          105.83615691046542,
          21.01868588629176
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83615691046542,
            21.01868588629176
          ],
          [
            105.83611412289997,
            21.018483889591522
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "35",
        "diemCuoi": "36",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01868588629176,
        "to_latitude": 21.018483889591522,
        "to_longitude": 105.83611412289997,
        "from_longitude": 105.83615691046542,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1635,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83605261098826,
          21.018247587281323,
          105.83611412289997,
          21.018483889591522
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83611412289997,
            21.018483889591522
          ],
          [
            105.83605261098826,
            21.018247587281323
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "36",
        "diemCuoi": "37",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018483889591522,
        "to_latitude": 21.018247587281323,
        "to_longitude": 105.83605261098826,
        "from_longitude": 105.83611412289997,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1636,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83572081617068,
          21.01779492052949,
          105.8359694142882,
          21.017867618412414
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8359694142882,
            21.01779492052949
          ],
          [
            105.83572081617068,
            21.017867618412414
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "39",
        "diemCuoi": "39-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01779492052949,
        "to_latitude": 21.017867618412414,
        "to_longitude": 105.83572081617068,
        "from_longitude": 105.8359694142882,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1637,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83551330808729,
          21.017867618412414,
          105.83572081617068,
          21.017915537800096
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83572081617068,
            21.017867618412414
          ],
          [
            105.83551330808729,
            21.017915537800096
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "39-1",
        "diemCuoi": "39-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.017867618412414,
        "to_latitude": 21.017915537800096,
        "to_longitude": 105.83551330808729,
        "from_longitude": 105.83572081617068,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1638,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82444031555572,
          21.010293247279034,
          105.82457174379796,
          21.010443480310066
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82457174379796,
            21.010443480310066
          ],
          [
            105.82444031555572,
            21.010293247279034
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "17",
        "diemCuoi": "16-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.010443480310066,
        "to_latitude": 21.010293247279034,
        "to_longitude": 105.82444031555572,
        "from_longitude": 105.82457174379796,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1639,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82444031555572,
          21.01020060350065,
          105.82452480509062,
          21.010293247279034
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82444031555572,
            21.010293247279034
          ],
          [
            105.82452480509062,
            21.010246925395766
          ],
          [
            105.82449395972853,
            21.01020060350065
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-1",
        "diemCuoi": "16-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.010293247279034,
        "to_latitude": 21.01020060350065,
        "to_longitude": 105.82449395972853,
        "from_longitude": 105.82444031555572,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1640,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82445774988808,
          21.01012423493962,
          105.82449395972853,
          21.01020060350065
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82449395972853,
            21.01020060350065
          ],
          [
            105.82445774988808,
            21.01012423493962
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-2",
        "diemCuoi": "16-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01020060350065,
        "to_latitude": 21.01012423493962,
        "to_longitude": 105.82445774988808,
        "from_longitude": 105.82449395972853,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1641,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82432766277667,
          21.010280727853022,
          105.82444031555572,
          21.010322041953952
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82444031555572,
            21.010293247279034
          ],
          [
            105.8243692369697,
            21.010322041953952
          ],
          [
            105.82432766277667,
            21.010280727853022
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-1",
        "diemCuoi": "16-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.010293247279034,
        "to_latitude": 21.010280727853022,
        "to_longitude": 105.82432766277667,
        "from_longitude": 105.82444031555572,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1642,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82431425172896,
          21.010443480310066,
          105.82457174379796,
          21.01061624810896
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82457174379796,
            21.010443480310066
          ],
          [
            105.82431425172896,
            21.01061624810896
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.010443480310066,
        "to_latitude": 21.01061624810896,
        "to_longitude": 105.82431425172896,
        "from_longitude": 105.82457174379796,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1643,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82431425172896,
          21.01061624810896,
          105.82445372659446,
          21.010703883871894
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82431425172896,
            21.01061624810896
          ],
          [
            105.82440678789605,
            21.010627515565993
          ],
          [
            105.82445372659446,
            21.010703883871894
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "17",
        "diemCuoi": "17-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01061624810896,
        "to_latitude": 21.010703883871894,
        "to_longitude": 105.82445372659446,
        "from_longitude": 105.82431425172896,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1644,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82445372659446,
          21.010703883871894,
          105.82457442600571,
          21.01087915524415
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82445372659446,
            21.010703883871894
          ],
          [
            105.82457442600571,
            21.01087915524415
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "17-3",
        "diemCuoi": "17-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.010703883871894,
        "to_latitude": 21.01087915524415,
        "to_longitude": 105.82457442600571,
        "from_longitude": 105.82445372659446,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1645,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82408626396311,
          21.010380883231708,
          105.82431425172896,
          21.01061624810896
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82431425172896,
            21.01061624810896
          ],
          [
            105.82408626396311,
            21.010380883231708
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "17",
        "diemCuoi": "17-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01061624810896,
        "to_latitude": 21.010380883231708,
        "to_longitude": 105.82408626396311,
        "from_longitude": 105.82431425172896,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1646,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82408358175536,
          21.010303262818578,
          105.82408626396311,
          21.010380883231708
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82408626396311,
            21.010380883231708
          ],
          [
            105.82408358175536,
            21.010303262818578
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "17-1",
        "diemCuoi": "17-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.010380883231708,
        "to_latitude": 21.010303262818578,
        "to_longitude": 105.82408358175536,
        "from_longitude": 105.82408626396311,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1647,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8241747768635,
          21.01061624810896,
          105.82431425172896,
          21.010794023460548
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82431425172896,
            21.01061624810896
          ],
          [
            105.8241747768635,
            21.010794023460548
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01061624810896,
        "to_latitude": 21.010794023460548,
        "to_longitude": 105.8241747768635,
        "from_longitude": 105.82431425172896,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1648,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8240312786505,
          21.0106175000499,
          105.8241747768635,
          21.010794023460548
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8241747768635,
            21.010794023460548
          ],
          [
            105.8240312786505,
            21.0106175000499
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "18",
        "diemCuoi": "18-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.010794023460548,
        "to_latitude": 21.0106175000499,
        "to_longitude": 105.8240312786505,
        "from_longitude": 105.8241747768635,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1649,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82390521482374,
          21.010474778840255,
          105.8240312786505,
          21.0106175000499
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8240312786505,
            21.0106175000499
          ],
          [
            105.82390521482374,
            21.010474778840255
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "18-1",
        "diemCuoi": "18-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.0106175000499,
        "to_latitude": 21.010474778840255,
        "to_longitude": 105.82390521482374,
        "from_longitude": 105.8240312786505,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1650,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8241747768635,
          21.010794023460548,
          105.82427938301035,
          21.010926728866902
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8241747768635,
            21.010794023460548
          ],
          [
            105.82427938301035,
            21.010926728866902
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.010794023460548,
        "to_latitude": 21.010926728866902,
        "to_longitude": 105.82427938301035,
        "from_longitude": 105.8241747768635,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1651,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82390521482374,
          21.011050670603705,
          105.82408089954761,
          21.011247224456476
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82408089954761,
            21.011247224456476
          ],
          [
            105.82390521482374,
            21.011050670603705
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "20",
        "diemCuoi": "20-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.011247224456476,
        "to_latitude": 21.011050670603705,
        "to_longitude": 105.82390521482374,
        "from_longitude": 105.82408089954761,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1652,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82369600252105,
          21.010807794782817,
          105.82390521482374,
          21.011050670603705
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82390521482374,
            21.011050670603705
          ],
          [
            105.82369600252105,
            21.010807794782817
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "20-1",
        "diemCuoi": "20-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.011050670603705,
        "to_latitude": 21.010807794782817,
        "to_longitude": 105.82369600252105,
        "from_longitude": 105.82390521482374,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1653,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82408089954761,
          21.011247224456476,
          105.82426060754716,
          21.011409975859088
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82408089954761,
            21.011247224456476
          ],
          [
            105.82426060754716,
            21.011409975859088
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "20",
        "diemCuoi": "20-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.011247224456476,
        "to_latitude": 21.011409975859088,
        "to_longitude": 105.82426060754716,
        "from_longitude": 105.82408089954761,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1654,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82392533141775,
          21.011247224456476,
          105.82408089954761,
          21.011379929459665
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82408089954761,
            21.011247224456476
          ],
          [
            105.82392533141775,
            21.011379929459665
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.011247224456476,
        "to_latitude": 21.011379929459665,
        "to_longitude": 105.82392533141775,
        "from_longitude": 105.82408089954761,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1655,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82377512771232,
          21.011379929459665,
          105.82392533141775,
          21.011517642074118
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82392533141775,
            21.011379929459665
          ],
          [
            105.82377512771232,
            21.011517642074118
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.011379929459665,
        "to_latitude": 21.011517642074118,
        "to_longitude": 105.82377512771232,
        "from_longitude": 105.82392533141775,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1656,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82377512771232,
          21.011517642074118,
          105.82394276575008,
          21.011694164422117
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82377512771232,
            21.011517642074118
          ],
          [
            105.82394276575008,
            21.011694164422117
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "22",
        "diemCuoi": "22-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.011517642074118,
        "to_latitude": 21.011694164422117,
        "to_longitude": 105.82394276575008,
        "from_longitude": 105.82377512771232,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1657,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82394276575008,
          21.011694164422117,
          105.82404334859991,
          21.01177053218437
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82394276575008,
            21.011694164422117
          ],
          [
            105.82404334859991,
            21.01177053218437
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "4",
        "diemCuoi": "22-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.011694164422117,
        "to_latitude": 21.01177053218437,
        "to_longitude": 105.82404334859991,
        "from_longitude": 105.82394276575008,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1658,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82396958781852,
          21.01177053218437,
          105.82404334859991,
          21.011826869026045
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82404334859991,
            21.01177053218437
          ],
          [
            105.82396958781852,
            21.011826869026045
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "04",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01177053218437,
        "to_latitude": 21.011826869026045,
        "to_longitude": 105.82396958781852,
        "from_longitude": 105.82404334859991,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1659,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8239628823441,
          21.01172546268738,
          105.82396958781852,
          21.011826869026045
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82396958781852,
            21.011826869026045
          ],
          [
            105.8239628823441,
            21.01172546268738
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "4-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.011826869026045,
        "to_latitude": 21.01172546268738,
        "to_longitude": 105.8239628823441,
        "from_longitude": 105.82396958781852,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1660,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82375635223566,
          21.01177053218437,
          105.82404334859991,
          21.012089774033026
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82404334859991,
            21.01177053218437
          ],
          [
            105.82375635223566,
            21.012089774033026
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "4",
        "diemCuoi": "5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01177053218437,
        "to_latitude": 21.012089774033026,
        "to_longitude": 105.82375635223566,
        "from_longitude": 105.82404334859991,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1661,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82365442831002,
          21.011517642074118,
          105.82377512771232,
          21.0116002695811
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82377512771232,
            21.011517642074118
          ],
          [
            105.82365442831002,
            21.0116002695811
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.011517642074118,
        "to_latitude": 21.0116002695811,
        "to_longitude": 105.82365442831002,
        "from_longitude": 105.82377512771232,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1662,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82359541970376,
          21.0116002695811,
          105.82365442831002,
          21.011666621940833
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82365442831002,
            21.0116002695811
          ],
          [
            105.82359541970376,
            21.011666621940833
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.0116002695811,
        "to_latitude": 21.011666621940833,
        "to_longitude": 105.82359541970376,
        "from_longitude": 105.82365442831002,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1663,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82359541970376,
          21.011666621940833,
          105.8237553894947,
          21.011824572168226
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82359541970376,
            21.011666621940833
          ],
          [
            105.8237553894947,
            21.011824572168226
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.011666621940833,
        "to_latitude": 21.011824572168226,
        "to_longitude": 105.8237553894947,
        "from_longitude": 105.82359541970376,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1664,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82349751903136,
          21.011666621940833,
          105.82359541970376,
          21.0118456479721
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82359541970376,
            21.011666621940833
          ],
          [
            105.82349751903136,
            21.011786807270443
          ],
          [
            105.82354848105032,
            21.0118456479721
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.011666621940833,
        "to_latitude": 21.0118456479721,
        "to_longitude": 105.82354848105032,
        "from_longitude": 105.82359541970376,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1665,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82338084298559,
          21.011666621940833,
          105.82359541970376,
          21.01186317498453
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82359541970376,
            21.011666621940833
          ],
          [
            105.82338084298559,
            21.01186317498453
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.011666621940833,
        "to_latitude": 21.01186317498453,
        "to_longitude": 105.82338084298559,
        "from_longitude": 105.82359541970376,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1666,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82321991044921,
          21.01186317498453,
          105.82338084298559,
          21.012002139080472
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82338084298559,
            21.01186317498453
          ],
          [
            105.82321991044921,
            21.012002139080472
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01186317498453,
        "to_latitude": 21.012002139080472,
        "to_longitude": 105.82321991044921,
        "from_longitude": 105.82338084298559,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1667,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82310859877401,
          21.011967085087072,
          105.82321991044921,
          21.012002139080472
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82321991044921,
            21.012002139080472
          ],
          [
            105.82310859877401,
            21.011967085087072
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "26",
        "diemCuoi": "26-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.012002139080472,
        "to_latitude": 21.011967085087072,
        "to_longitude": 105.82310859877401,
        "from_longitude": 105.82321991044921,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1668,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82304422573431,
          21.011967085087072,
          105.82310859877401,
          21.011997131365725
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82310859877401,
            21.011967085087072
          ],
          [
            105.82308177663373,
            21.011997131365725
          ],
          [
            105.82304422573431,
            21.011979604370737
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "26-2",
        "diemCuoi": "26-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.011967085087072,
        "to_latitude": 21.011979604370737,
        "to_longitude": 105.82304422573431,
        "from_longitude": 105.82310859877401,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1669,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82321991044921,
          21.012002139080472,
          105.82339023072166,
          21.012128583776864
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82321991044921,
            21.012002139080472
          ],
          [
            105.82339023072166,
            21.012128583776864
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "26",
        "diemCuoi": "26-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.012002139080472,
        "to_latitude": 21.012128583776864,
        "to_longitude": 105.82339023072166,
        "from_longitude": 105.82321991044921,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1670,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82353104669102,
          21.011558955834992,
          105.82359541970376,
          21.011666621940833
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82359541970376,
            21.011666621940833
          ],
          [
            105.82353104669102,
            21.011558955834992
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "24",
        "diemCuoi": "24-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.011666621940833,
        "to_latitude": 21.011558955834992,
        "to_longitude": 105.82353104669102,
        "from_longitude": 105.82359541970376,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1671,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82350690680336,
          21.011244720586863,
          105.82377512771232,
          21.011517642074118
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82377512771232,
            21.011517642074118
          ],
          [
            105.82350690680336,
            21.011244720586863
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "22",
        "diemCuoi": "22-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.011517642074118,
        "to_latitude": 21.011244720586863,
        "to_longitude": 105.82350690680336,
        "from_longitude": 105.82377512771232,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1672,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82330037670843,
          21.011244720586863,
          105.82350690680336,
          21.01140371619265
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82350690680336,
            21.011244720586863
          ],
          [
            105.82330037670843,
            21.01140371619265
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "22-2",
        "diemCuoi": "22-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.011244720586863,
        "to_latitude": 21.01140371619265,
        "to_longitude": 105.82330037670843,
        "from_longitude": 105.82350690680336,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1673,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82306970673481,
          21.011247224456476,
          105.82330037670843,
          21.011431258720823
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82330037670843,
            21.01140371619265
          ],
          [
            105.82326148464226,
            21.011431258720823
          ],
          [
            105.82306970673481,
            21.011247224456476
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "22-3",
        "diemCuoi": "22-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01140371619265,
        "to_latitude": 21.011247224456476,
        "to_longitude": 105.82306970673481,
        "from_longitude": 105.82330037670843,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1674,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82332317549219,
          21.01087164361833,
          105.82347203809374,
          21.011025631876063
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82347203809374,
            21.011025631876063
          ],
          [
            105.82332317549219,
            21.01087164361833
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "22-5",
        "diemCuoi": "22-6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.011025631876063,
        "to_latitude": 21.01087164361833,
        "to_longitude": 105.82332317549219,
        "from_longitude": 105.82347203809374,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1675,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82337681962906,
          21.011120779012142,
          105.82350690680336,
          21.011244720586863
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82350690680336,
            21.011244720586863
          ],
          [
            105.82337681962906,
            21.011120779012142
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "22-2",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.011244720586863,
        "to_latitude": 21.011120779012142,
        "to_longitude": 105.82337681962906,
        "from_longitude": 105.82350690680336,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1676,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82337681962906,
          21.011025631876063,
          105.82347203809374,
          21.011120779012142
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82337681962906,
            21.011120779012142
          ],
          [
            105.82347203809374,
            21.011025631876063
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "22-5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.011120779012142,
        "to_latitude": 21.011025631876063,
        "to_longitude": 105.82347203809374,
        "from_longitude": 105.82337681962906,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1677,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82404334859991,
          21.011451289652506,
          105.8243303449642,
          21.01177053218437
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82404334859991,
            21.01177053218437
          ],
          [
            105.8243303449642,
            21.011451289652506
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01177053218437,
        "to_latitude": 21.011451289652506,
        "to_longitude": 105.8243303449642,
        "from_longitude": 105.82404334859991,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1678,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8243303449642,
          21.01113204643746,
          105.82461734132846,
          21.011451289652506
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8243303449642,
            21.011451289652506
          ],
          [
            105.82461734132846,
            21.01113204643746
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.011451289652506,
        "to_latitude": 21.01113204643746,
        "to_longitude": 105.82461734132846,
        "from_longitude": 105.8243303449642,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1679,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82461734132846,
          21.010812802539167,
          105.82490433769273,
          21.01113204643746
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82461734132846,
            21.01113204643746
          ],
          [
            105.82490433769273,
            21.010812802539167
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 51,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01113204643746,
        "to_latitude": 21.010812802539167,
        "to_longitude": 105.82490433769273,
        "from_longitude": 105.82461734132846,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1680,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82432498053298,
          21.011409071285353,
          105.8243303449642,
          21.011451289652506
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82432498053298,
            21.011409071285353
          ],
          [
            105.8243303449642,
            21.011451289652506
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.011409071285353,
        "to_latitude": 21.011451289652506,
        "to_longitude": 105.8243303449642,
        "from_longitude": 105.82432498053298,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1681,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8234693558714,
          21.012089774033026,
          105.82375635223566,
          21.012409015198422
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82375635223566,
            21.012089774033026
          ],
          [
            105.8234693558714,
            21.012409015198422
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.012089774033026,
        "to_latitude": 21.012409015198422,
        "to_longitude": 105.8234693558714,
        "from_longitude": 105.82375635223566,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1682,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82311262207665,
          21.0124302979175,
          105.8232735545771,
          21.01272825568058
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82318235950713,
            21.01272825568058
          ],
          [
            105.8232735545771,
            21.012584284563722
          ],
          [
            105.82311262207665,
            21.0124302979175
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "07",
        "diemCuoi": "7-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01272825568058,
        "to_latitude": 21.0124302979175,
        "to_longitude": 105.82311262207665,
        "from_longitude": 105.82318235950713,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1683,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.822892680934,
          21.01224000525352,
          105.82311262207665,
          21.0124302979175
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82311262207665,
            21.0124302979175
          ],
          [
            105.822892680934,
            21.01224000525352
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "7-1",
        "diemCuoi": "7-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.0124302979175,
        "to_latitude": 21.01224000525352,
        "to_longitude": 105.822892680934,
        "from_longitude": 105.82311262207665,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1684,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82318235950713,
          21.012409015198422,
          105.8234693558714,
          21.01272825568058
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8234693558714,
            21.012409015198422
          ],
          [
            105.82318235950713,
            21.01272825568058
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.012409015198422,
        "to_latitude": 21.01272825568058,
        "to_longitude": 105.82318235950713,
        "from_longitude": 105.8234693558714,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1685,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82289536314285,
          21.01272825568058,
          105.82318235950713,
          21.013047495479494
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82318235950713,
            21.01272825568058
          ],
          [
            105.82289536314285,
            21.013047495479494
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 54,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01272825568058,
        "to_latitude": 21.013047495479494,
        "to_longitude": 105.82289536314285,
        "from_longitude": 105.82318235950713,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1686,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82247157412087,
          21.01279085177078,
          105.82261641341083,
          21.012941082286424
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82261641341083,
            21.012941082286424
          ],
          [
            105.82247157412087,
            21.01279085177078
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "8-1",
        "diemCuoi": "8-1-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.012941082286424,
        "to_latitude": 21.01279085177078,
        "to_longitude": 105.82247157412087,
        "from_longitude": 105.82261641341083,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1687,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82234819251086,
          21.012690698010182,
          105.82247157412087,
          21.01279085177078
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82247157412087,
            21.01279085177078
          ],
          [
            105.82234819251086,
            21.012690698010182
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "8-1-1",
        "diemCuoi": "8-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01279085177078,
        "to_latitude": 21.012690698010182,
        "to_longitude": 105.82234819251086,
        "from_longitude": 105.82247157412087,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1688,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82234819251086,
          21.01248037489278,
          105.82257349805998,
          21.012690698010182
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82234819251086,
            21.012690698010182
          ],
          [
            105.82257349805998,
            21.01248037489278
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "8-2",
        "diemCuoi": "8-2-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.012690698010182,
        "to_latitude": 21.01248037489278,
        "to_longitude": 105.82257349805998,
        "from_longitude": 105.82234819251086,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1689,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82247157412087,
          21.01261558264473,
          105.82260568457086,
          21.01279085177078
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82247157412087,
            21.01279085177078
          ],
          [
            105.82260568457086,
            21.01261558264473
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01279085177078,
        "to_latitude": 21.01261558264473,
        "to_longitude": 105.82260568457086,
        "from_longitude": 105.82247157412087,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1690,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82260568457086,
          21.01243530561436,
          105.8227773459472,
          21.01261558264473
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82260568457086,
            21.01261558264473
          ],
          [
            105.8227773459472,
            21.01243530561436
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "8-3",
        "diemCuoi": "8-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01261558264473,
        "to_latitude": 21.01243530561436,
        "to_longitude": 105.8227773459472,
        "from_longitude": 105.82260568457086,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1691,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82268078642356,
          21.012330143913122,
          105.8227773459472,
          21.01243530561436
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8227773459472,
            21.01243530561436
          ],
          [
            105.82268078642356,
            21.012330143913122
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "8-4",
        "diemCuoi": "8-5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01243530561436,
        "to_latitude": 21.012330143913122,
        "to_longitude": 105.82268078642356,
        "from_longitude": 105.8227773459472,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1692,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8224930317918,
          21.012179912782127,
          105.82268078642356,
          21.012330143913122
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82268078642356,
            21.012330143913122
          ],
          [
            105.8224930317918,
            21.012179912782127
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "8-5",
        "diemCuoi": "8-6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.012330143913122,
        "to_latitude": 21.012179912782127,
        "to_longitude": 105.8224930317918,
        "from_longitude": 105.82268078642356,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1693,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82260836677861,
          21.013366734595152,
          105.82269419747126,
          21.013617117736626
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82260836677861,
            21.013366734595152
          ],
          [
            105.82269419747126,
            21.013617117736626
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.013366734595152,
        "to_latitude": 21.013617117736626,
        "to_longitude": 105.82269419747126,
        "from_longitude": 105.82260836677861,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1694,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82269419747126,
          21.013617117736626,
          105.82299549895134,
          21.01385831976614
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82269419747126,
            21.013617117736626
          ],
          [
            105.82299549895134,
            21.01385831976614
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.013617117736626,
        "to_latitude": 21.01385831976614,
        "to_longitude": 105.82299549895134,
        "from_longitude": 105.82269419747126,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1695,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82299549895134,
          21.01385831976614,
          105.82329680043144,
          21.01409952140556
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82299549895134,
            21.01385831976614
          ],
          [
            105.82329680043144,
            21.01409952140556
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01385831976614,
        "to_latitude": 21.01409952140556,
        "to_longitude": 105.82329680043144,
        "from_longitude": 105.82299549895134,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1696,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8231313975488,
          21.01409952140556,
          105.82329680043144,
          21.01438829517283
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82329680043144,
            21.01409952140556
          ],
          [
            105.82328428341705,
            21.014264356208493
          ],
          [
            105.8231313975488,
            21.01438829517283
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "12",
        "diemCuoi": "12-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01409952140556,
        "to_latitude": 21.01438829517283,
        "to_longitude": 105.8231313975488,
        "from_longitude": 105.82329680043144,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1697,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82299728708986,
          21.01438829517283,
          105.8231313975488,
          21.01450847831033
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8231313975488,
            21.01438829517283
          ],
          [
            105.82299728708986,
            21.01450847831033
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "12-1",
        "diemCuoi": "12-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01438829517283,
        "to_latitude": 21.01450847831033,
        "to_longitude": 105.82299728708986,
        "from_longitude": 105.8231313975488,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1698,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8229490072876,
          21.01450847831033,
          105.82300533372202,
          21.01463116516377
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82299728708986,
            21.01450847831033
          ],
          [
            105.8229490072876,
            21.014577333186725
          ],
          [
            105.82300533372202,
            21.01463116516377
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "12-2",
        "diemCuoi": "12-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01450847831033,
        "to_latitude": 21.01463116516377,
        "to_longitude": 105.82300533372202,
        "from_longitude": 105.82299728708986,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1699,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82292754961668,
          21.01463116516377,
          105.82301069813754,
          21.014803928112308
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82300533372202,
            21.01463116516377
          ],
          [
            105.82292754961668,
            21.01474008095582
          ],
          [
            105.82301069813754,
            21.014803928112308
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "12-3",
        "diemCuoi": "12-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01463116516377,
        "to_latitude": 21.014803928112308,
        "to_longitude": 105.82301069813754,
        "from_longitude": 105.82300533372202,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1700,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82329680043144,
          21.01409952140556,
          105.8235981019115,
          21.014340722654964
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82329680043144,
            21.01409952140556
          ],
          [
            105.8235981019115,
            21.014340722654964
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01409952140556,
        "to_latitude": 21.014340722654964,
        "to_longitude": 105.8235981019115,
        "from_longitude": 105.82329680043144,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1701,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8235981019115,
          21.01431192875577,
          105.82371209578545,
          21.014377027995682
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8235981019115,
            21.014340722654964
          ],
          [
            105.82365174604837,
            21.014377027995682
          ],
          [
            105.82371209578545,
            21.01431192875577
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.014340722654964,
        "to_latitude": 21.01431192875577,
        "to_longitude": 105.82371209578545,
        "from_longitude": 105.8235981019115,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1702,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82348142580285,
          21.01431192875577,
          105.82371209578545,
          21.014542279801866
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82371209578545,
            21.01431192875577
          ],
          [
            105.82348142580285,
            21.014542279801866
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01431192875577,
        "to_latitude": 21.014542279801866,
        "to_longitude": 105.82348142580285,
        "from_longitude": 105.82371209578545,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1703,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84014330823732,
          21.018659744732687,
          105.8402071048191,
          21.0189477552981
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8402071048191,
            21.0189477552981
          ],
          [
            105.84014330823732,
            21.018659744732687
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 66,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "05",
        "diemCuoi": "9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.0189477552981,
        "to_latitude": 21.018659744732687,
        "to_longitude": 105.84014330823732,
        "from_longitude": 105.8402071048191,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1704,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84013526160508,
          21.018344272571483,
          105.84014330823732,
          21.018659744732687
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84014330823732,
            21.018659744732687
          ],
          [
            105.84013526160508,
            21.018344272571483
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "9",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018659744732687,
        "to_latitude": 21.018344272571483,
        "to_longitude": 105.84013526160508,
        "from_longitude": 105.84014330823732,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1705,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84012721498186,
          21.018071363576848,
          105.84013526160508,
          21.018344272571483
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84013526160508,
            21.018344272571483
          ],
          [
            105.84012721498186,
            21.018071363576848
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018344272571483,
        "to_latitude": 21.018071363576848,
        "to_longitude": 105.84012721498186,
        "from_longitude": 105.84013526160508,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1706,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84011648615092,
          21.01782098791252,
          105.84012721498186,
          21.018071363576848
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84012721498186,
            21.018071363576848
          ],
          [
            105.84011648615092,
            21.01782098791252
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018071363576848,
        "to_latitude": 21.01782098791252,
        "to_longitude": 105.84011648615092,
        "from_longitude": 105.84012721498186,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1707,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84004674871366,
          21.017437912332703,
          105.84011648615092,
          21.01782098791252
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84011648615092,
            21.01782098791252
          ],
          [
            105.84004674871366,
            21.017437912332703
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01782098791252,
        "to_latitude": 21.017437912332703,
        "to_longitude": 105.84004674871366,
        "from_longitude": 105.84011648615092,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1708,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84004674871366,
          21.017437912332703,
          105.84021572788227,
          21.017445423627837
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84004674871366,
            21.017437912332703
          ],
          [
            105.84021572788227,
            21.017445423627837
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "13",
        "diemCuoi": "13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.017437912332703,
        "to_latitude": 21.017445423627837,
        "to_longitude": 105.84021572788227,
        "from_longitude": 105.84004674871366,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1709,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84021572788227,
          21.017445423627837,
          105.84040080030631,
          21.01745293492339
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84021572788227,
            21.017445423627837
          ],
          [
            105.84040080030631,
            21.01745293492339
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "13-1",
        "diemCuoi": "13-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.017445423627837,
        "to_latitude": 21.01745293492339,
        "to_longitude": 105.84040080030631,
        "from_longitude": 105.84021572788227,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1710,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84040080030631,
          21.017445423627837,
          105.84056441505042,
          21.01745293492339
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84040080030631,
            21.01745293492339
          ],
          [
            105.84056441505042,
            21.017445423627837
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "13-2",
        "diemCuoi": "13-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01745293492339,
        "to_latitude": 21.017445423627837,
        "to_longitude": 105.84056441505042,
        "from_longitude": 105.84040080030631,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1711,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84056441505042,
          21.017437912332703,
          105.84084872920582,
          21.017445423627837
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84056441505042,
            21.017445423627837
          ],
          [
            105.84084872920582,
            21.017437912332703
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "13-3",
        "diemCuoi": "13-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.017445423627837,
        "to_latitude": 21.017437912332703,
        "to_longitude": 105.84084872920582,
        "from_longitude": 105.84056441505042,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1712,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84004138429819,
          21.017107414963483,
          105.84004674871366,
          21.017437912332703
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84004674871366,
            21.017437912332703
          ],
          [
            105.84004138429819,
            21.017107414963483
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.017437912332703,
        "to_latitude": 21.017107414963483,
        "to_longitude": 105.84004138429819,
        "from_longitude": 105.84004674871366,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1713,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84004138429819,
          21.01706860651216,
          105.84023450333645,
          21.017107414963483
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84004138429819,
            21.017107414963483
          ],
          [
            105.84023450333645,
            21.01706860651216
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "14",
        "diemCuoi": "14-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.017107414963483,
        "to_latitude": 21.01706860651216,
        "to_longitude": 105.84023450333645,
        "from_longitude": 105.84004138429819,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1714,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84023450333645,
          21.01706860651216,
          105.84056575610938,
          21.017101155536988
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84023450333645,
            21.01706860651216
          ],
          [
            105.84056575610938,
            21.017101155536988
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "14-1",
        "diemCuoi": "14-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01706860651216,
        "to_latitude": 21.017101155536988,
        "to_longitude": 105.84056575610938,
        "from_longitude": 105.84023450333645,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1715,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84056575610938,
          21.017101155536988,
          105.84060598925245,
          21.017196298797423
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84056575610938,
            21.017101155536988
          ],
          [
            105.84060598925245,
            21.017196298797423
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.017101155536988,
        "to_latitude": 21.017196298797423,
        "to_longitude": 105.84060598925245,
        "from_longitude": 105.84056575610938,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1716,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84071730091867,
          21.017192543143622,
          105.84089030340786,
          21.017196298797423
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84071730091867,
            21.017196298797423
          ],
          [
            105.84089030340786,
            21.017192543143622
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "14-3",
        "diemCuoi": "14-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.017196298797423,
        "to_latitude": 21.017192543143622,
        "to_longitude": 105.84089030340786,
        "from_longitude": 105.84071730091867,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1717,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84004138429819,
          21.016721833773772,
          105.84004540756489,
          21.017107414963483
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84004138429819,
            21.017107414963483
          ],
          [
            105.84004540756489,
            21.016721833773772
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.017107414963483,
        "to_latitude": 21.016721833773772,
        "to_longitude": 105.84004540756489,
        "from_longitude": 105.84004138429819,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1718,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84004540756489,
          21.016714009472278,
          105.8404406981442,
          21.016721833773772
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84004540756489,
            21.016721833773772
          ],
          [
            105.8404406981442,
            21.016714009472278
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "15",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.016721833773772,
        "to_latitude": 21.016714009472278,
        "to_longitude": 105.8404406981442,
        "from_longitude": 105.84004540756489,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1719,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8404406981442,
          21.016707750028452,
          105.84081352519102,
          21.016714009472278
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8404406981442,
            21.016714009472278
          ],
          [
            105.84081352519102,
            21.016707750028452
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.016714009472278,
        "to_latitude": 21.016707750028452,
        "to_longitude": 105.84081352519102,
        "from_longitude": 105.8404406981442,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1720,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84081352519102,
          21.016707750028452,
          105.84119741633805,
          21.016760642315322
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84081352519102,
            21.016707750028452
          ],
          [
            105.84119741633805,
            21.016760642315322
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.016707750028452,
        "to_latitude": 21.016760642315322,
        "to_longitude": 105.84119741633805,
        "from_longitude": 105.84081352519102,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1721,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84081352519102,
          21.016707750028452,
          105.84093858316518,
          21.017003508442095
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84081352519102,
            21.016707750028452
          ],
          [
            105.84093187768175,
            21.01676690175692
          ],
          [
            105.84093858316518,
            21.017003508442095
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "20",
        "diemCuoi": "20-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.016707750028452,
        "to_latitude": 21.017003508442095,
        "to_longitude": 105.84093858316518,
        "from_longitude": 105.84081352519102,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1722,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84111829117371,
          21.016760642315322,
          105.84119741633805,
          21.017011019759924
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84119741633805,
            21.016760642315322
          ],
          [
            105.84111829117371,
            21.017011019759924
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.016760642315322,
        "to_latitude": 21.017011019759924,
        "to_longitude": 105.84111829117371,
        "from_longitude": 105.84119741633805,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1723,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84102709606559,
          21.017011019759924,
          105.84111829117371,
          21.017273915623445
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84111829117371,
            21.017011019759924
          ],
          [
            105.84102709606559,
            21.017273915623445
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.017011019759924,
        "to_latitude": 21.017273915623445,
        "to_longitude": 105.84102709606559,
        "from_longitude": 105.84111829117371,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1724,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84089164451173,
          21.017273915623445,
          105.84102709606559,
          21.017709570606918
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84102709606559,
            21.017273915623445
          ],
          [
            105.84089164451173,
            21.017709570606918
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.017273915623445,
        "to_latitude": 21.017709570606918,
        "to_longitude": 105.84089164451173,
        "from_longitude": 105.84102709606559,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1725,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83996997046091,
          21.01663138479736,
          105.84004540756489,
          21.016721833773772
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84004540756489,
            21.016721833773772
          ],
          [
            105.83996997046091,
            21.01663138479736
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "15",
        "diemCuoi": "15-1-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.016721833773772,
        "to_latitude": 21.01663138479736,
        "to_longitude": 105.83996997046091,
        "from_longitude": 105.84004540756489,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1726,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84004239010588,
          21.016484913670496,
          105.84004540756489,
          21.016721833773772
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84004540756489,
            21.016721833773772
          ],
          [
            105.84004239010588,
            21.016484913670496
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.016721833773772,
        "to_latitude": 21.016484913670496,
        "to_longitude": 105.84004239010588,
        "from_longitude": 105.84004540756489,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1727,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84000752138725,
          21.0162595731941,
          105.84004239010588,
          21.016484913670496
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84004239010588,
            21.016484913670496
          ],
          [
            105.84000752138725,
            21.0162595731941
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.016484913670496,
        "to_latitude": 21.0162595731941,
        "to_longitude": 105.84000752138725,
        "from_longitude": 105.84004239010588,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1728,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83983317780319,
          21.016217008843817,
          105.84000752138725,
          21.0162595731941
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84000752138725,
            21.0162595731941
          ],
          [
            105.83983317780319,
            21.016217008843817
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "17",
        "diemCuoi": "17-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.0162595731941,
        "to_latitude": 21.016217008843817,
        "to_longitude": 105.83983317780319,
        "from_longitude": 105.84000752138725,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1729,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83983317780319,
          21.015907790992706,
          105.84001959133109,
          21.016217008843817
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83983317780319,
            21.016217008843817
          ],
          [
            105.84001959133109,
            21.015907790992706
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "17-1",
        "diemCuoi": "17-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.016217008843817,
        "to_latitude": 21.015907790992706,
        "to_longitude": 105.84001959133109,
        "from_longitude": 105.83983317780319,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1730,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84001959133109,
          21.015731273632248,
          105.84009335207097,
          21.015907790992706
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84001959133109,
            21.015907790992706
          ],
          [
            105.84009335207097,
            21.015731273632248
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "17-2",
        "diemCuoi": "17-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.015907790992706,
        "to_latitude": 21.015731273632248,
        "to_longitude": 105.84009335207097,
        "from_longitude": 105.84001959133109,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1731,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84000752138725,
          21.016020461539224,
          105.84016845392365,
          21.0162595731941
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84000752138725,
            21.0162595731941
          ],
          [
            105.84016845392365,
            21.016020461539224
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.0162595731941,
        "to_latitude": 21.016020461539224,
        "to_longitude": 105.84016845392365,
        "from_longitude": 105.84000752138725,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1732,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84016845392365,
          21.015803883636316,
          105.84023685025701,
          21.016020461539224
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84016845392365,
            21.016020461539224
          ],
          [
            105.84023685025701,
            21.015803883636316
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "18",
        "diemCuoi": "18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.016020461539224,
        "to_latitude": 21.015803883636316,
        "to_longitude": 105.84023685025701,
        "from_longitude": 105.84016845392365,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1733,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83975137042214,
          21.016598835670017,
          105.84004540756489,
          21.016721833773772
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84004540756489,
            21.016721833773772
          ],
          [
            105.83997701126746,
            21.016606659979225
          ],
          [
            105.83975137042214,
            21.016598835670017
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "15",
        "diemCuoi": "15-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.016721833773772,
        "to_latitude": 21.016598835670017,
        "to_longitude": 105.83975137042214,
        "from_longitude": 105.84004540756489,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1734,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83971784281637,
          21.016434838038855,
          105.83976243454931,
          21.016598835670017
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83975137042214,
            21.016598835670017
          ],
          [
            105.83976243454931,
            21.016453929373572
          ],
          [
            105.83971784281637,
            21.016434838038855
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "15-1",
        "diemCuoi": "15-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.016598835670017,
        "to_latitude": 21.016434838038855,
        "to_longitude": 105.83971784281637,
        "from_longitude": 105.83975137042214,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1735,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84056575610938,
          21.017101155536988,
          105.84071730091867,
          21.017196298797423
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84056575610938,
            21.017101155536988
          ],
          [
            105.84062342364768,
            21.017162497909897
          ],
          [
            105.84071730091867,
            21.017196298797423
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "14-2",
        "diemCuoi": "14-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.017101155536988,
        "to_latitude": 21.017196298797423,
        "to_longitude": 105.84071730091867,
        "from_longitude": 105.84056575610938,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1736,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8356183553111,
          21.018333664659124,
          105.835784211195,
          21.01839685784479
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.835784211195,
            21.018333664659124
          ],
          [
            105.8356183553111,
            21.018366845573507
          ],
          [
            105.83563440013832,
            21.01839685784479
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "45",
        "diemCuoi": "45-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018333664659124,
        "to_latitude": 21.01839685784479,
        "to_longitude": 105.83563440013832,
        "from_longitude": 105.835784211195,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1737,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83550856138878,
          21.01832970474288,
          105.835784211195,
          21.018366845573507
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.835784211195,
            21.018333664659124
          ],
          [
            105.8356183553111,
            21.018366845573507
          ],
          [
            105.83550856138878,
            21.01832970474288
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "45",
        "diemCuoi": "59",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018333664659124,
        "to_latitude": 21.01832970474288,
        "to_longitude": 105.83550856138878,
        "from_longitude": 105.835784211195,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1738,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83557473679316,
          21.018178326656642,
          105.835784211195,
          21.018366845573507
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.835784211195,
            21.018333664659124
          ],
          [
            105.8356183553111,
            21.018366845573507
          ],
          [
            105.83557473679316,
            21.018178326656642
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "45-1",
        "diemCuoi": "46",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018333664659124,
        "to_latitude": 21.018178326656642,
        "to_longitude": 105.83557473679316,
        "from_longitude": 105.835784211195,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1739,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.835557365883,
          21.018036698760064,
          105.83557473679316,
          21.018178326656642
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83557473679316,
            21.018178326656642
          ],
          [
            105.835557365883,
            21.018036698760064
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "46",
        "diemCuoi": "47",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018178326656642,
        "to_latitude": 21.018036698760064,
        "to_longitude": 105.835557365883,
        "from_longitude": 105.83557473679316,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1740,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83563440013832,
          21.018393424997267,
          105.8358230104991,
          21.01839685784479
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83563440013832,
            21.01839685784479
          ],
          [
            105.8358230104991,
            21.018393424997267
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "45-1",
        "diemCuoi": "45-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01839685784479,
        "to_latitude": 21.018393424997267,
        "to_longitude": 105.8358230104991,
        "from_longitude": 105.83563440013832,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1741,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83542391502553,
          21.018047049477527,
          105.83550856138878,
          21.01832970474289
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83550856138878,
            21.01832970474289
          ],
          [
            105.83542391502553,
            21.018047049477527
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "diemDau": "59",
        "diemCuoi": "60",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01832970474289,
        "to_latitude": 21.018047049477527,
        "to_longitude": 105.83542391502553,
        "from_longitude": 105.83550856138878,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1742,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83530683951217,
          21.018047049477527,
          105.83542391502553,
          21.01808992747601
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83542391502553,
            21.018047049477527
          ],
          [
            105.83530683951217,
            21.01808992747601
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "diemDau": "60",
        "diemCuoi": "60-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018047049477527,
        "to_latitude": 21.01808992747601,
        "to_longitude": 105.83530683951217,
        "from_longitude": 105.83542391502553,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1743,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84004540756489,
          21.016519027684406,
          105.84023182111973,
          21.016721833773772
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84004540756489,
            21.016721833773772
          ],
          [
            105.84021841008102,
            21.016698047890564
          ],
          [
            105.84023182111973,
            21.016519027684406
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "15",
        "diemCuoi": "15-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.016721833773772,
        "to_latitude": 21.016519027684406,
        "to_longitude": 105.84023182111973,
        "from_longitude": 105.84004540756489,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1744,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84038202482515,
          21.016509012562214,
          105.8404406981442,
          21.016714009472278
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8404406981442,
            21.016714009472278
          ],
          [
            105.84038202482515,
            21.016698047890564
          ],
          [
            105.84039275366511,
            21.016509012562214
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "19",
        "diemCuoi": "19-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.016714009472278,
        "to_latitude": 21.016509012562214,
        "to_longitude": 105.84039275366511,
        "from_longitude": 105.8404406981442,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1745,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84039275366511,
          21.016417624535876,
          105.8404048236089,
          21.016509012562214
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84039275366511,
            21.016509012562214
          ],
          [
            105.8404048236089,
            21.016417624535876
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "diemDau": "19-1",
        "diemCuoi": "19-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.016509012562214,
        "to_latitude": 21.016417624535876,
        "to_longitude": 105.8404048236089,
        "from_longitude": 105.84039275366511,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1746,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83536042217412,
          21.01801819180807,
          105.835557365883,
          21.018036698760064
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.835557365883,
            21.018036698760064
          ],
          [
            105.83536042217412,
            21.01801819180807
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "47",
        "diemCuoi": "48",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018036698760064,
        "to_latitude": 21.01801819180807,
        "to_longitude": 105.83536042217412,
        "from_longitude": 105.835557365883,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1747,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8351587768087,
          21.01832970474289,
          105.83550856138878,
          21.018492325294442
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83550856138878,
            21.01832970474289
          ],
          [
            105.8351587768087,
            21.018492325294442
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "diemDau": "59",
        "diemCuoi": "58",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01832970474289,
        "to_latitude": 21.018492325294442,
        "to_longitude": 105.8351587768087,
        "from_longitude": 105.83550856138878,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1748,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83919380618458,
          21.018414064586025,
          105.8393664734315,
          21.018499504992313
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83919380618458,
            21.018499504992313
          ],
          [
            105.8393664734315,
            21.018414064586025
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Tượng Thuận",
        "diemDau": "26",
        "diemCuoi": "26-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018499504992313,
        "to_latitude": 21.018414064586025,
        "to_longitude": 105.8393664734315,
        "from_longitude": 105.83919380618458,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1749,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8393664734315,
          21.01823415224326,
          105.83954158336962,
          21.018414064586025
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8393664734315,
            21.018414064586025
          ],
          [
            105.83954158336962,
            21.01823415224326
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Tượng Thuận",
        "diemDau": "26-1",
        "diemCuoi": "26-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018414064586025,
        "to_latitude": 21.01823415224326,
        "to_longitude": 105.83954158336962,
        "from_longitude": 105.8393664734315,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1750,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83954158336962,
          21.018073554362275,
          105.83963603544433,
          21.01823415224326
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83954158336962,
            21.01823415224326
          ],
          [
            105.83963603544433,
            21.018073554362275
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Tượng Thuận",
        "diemDau": "26-2",
        "diemCuoi": "26-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01823415224326,
        "to_latitude": 21.018073554362275,
        "to_longitude": 105.83963603544433,
        "from_longitude": 105.83954158336962,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1751,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83963603544433,
          21.01796088536694,
          105.83978355693301,
          21.018073554362275
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83963603544433,
            21.018073554362275
          ],
          [
            105.83978355693301,
            21.01796088536694
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Tượng Thuận",
        "diemDau": "26-3",
        "diemCuoi": "26-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018073554362275,
        "to_latitude": 21.01796088536694,
        "to_longitude": 105.83978355693301,
        "from_longitude": 105.83963603544433,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1752,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83978355693301,
          21.017872001988696,
          105.83983183669932,
          21.01796088536694
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83978355693301,
            21.01796088536694
          ],
          [
            105.83983183669932,
            21.017872001988696
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Tượng Thuận",
        "diemDau": "26-4",
        "diemCuoi": "26-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01796088536694,
        "to_latitude": 21.017872001988696,
        "to_longitude": 105.83983183669932,
        "from_longitude": 105.83978355693301,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1753,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83978355693301,
          21.01796088536694,
          105.83979965018847,
          21.018406553338856
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83978355693301,
            21.01796088536694
          ],
          [
            105.83979965018847,
            21.018406553338856
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 51,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Tượng Thuận",
        "diemDau": "26-4",
        "diemCuoi": "26-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01796088536694,
        "to_latitude": 21.018406553338856,
        "to_longitude": 105.83979965018847,
        "from_longitude": 105.83978355693301,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1754,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83975539374273,
          21.017636648561847,
          105.83983183669932,
          21.017872001988696
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83983183669932,
            21.017872001988696
          ],
          [
            105.83975539374273,
            21.017636648561847
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Tượng Thuận",
        "diemDau": "26-5",
        "diemCuoi": "26-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.017872001988696,
        "to_latitude": 21.017636648561847,
        "to_longitude": 105.83975539374273,
        "from_longitude": 105.83983183669932,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1755,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83946437406797,
          21.017636648561847,
          105.83975539374273,
          21.017794385473998
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83975539374273,
            21.017636648561847
          ],
          [
            105.83946437406797,
            21.017794385473998
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Tượng Thuận",
        "diemDau": "26-6",
        "diemCuoi": "26-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.017636648561847,
        "to_latitude": 21.017794385473998,
        "to_longitude": 105.83946437406797,
        "from_longitude": 105.83975539374273,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1756,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83884478378314,
          21.018422827706555,
          105.83896011876995,
          21.018553022578292
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83896011876995,
            21.018553022578292
          ],
          [
            105.83884478378314,
            21.018422827706555
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Tượng Thuận",
        "diemDau": "7-11",
        "diemCuoi": "7-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018553022578292,
        "to_latitude": 21.018422827706555,
        "to_longitude": 105.83884478378314,
        "from_longitude": 105.83896011876995,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1757,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83873481321181,
          21.018422827706555,
          105.83884478378314,
          21.018494184525327
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83884478378314,
            21.018422827706555
          ],
          [
            105.83873481321181,
            21.018494184525327
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Tượng Thuận",
        "diemDau": "7-12",
        "diemCuoi": "7-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018422827706555,
        "to_latitude": 21.018494184525327,
        "to_longitude": 105.83873481321181,
        "from_longitude": 105.83884478378314,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1758,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83895643073653,
          21.018553022578292,
          105.83923940375213,
          21.019036557830166
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83923940375213,
            21.019036557830166
          ],
          [
            105.83917100745471,
            21.018877570361283
          ],
          [
            105.83895643073653,
            21.018887585325217
          ],
          [
            105.83896011876995,
            21.018553022578292
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 68,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hồ Dài",
        "diemDau": "07",
        "diemCuoi": "7-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.019036557830166,
        "to_latitude": 21.018553022578292,
        "to_longitude": 105.83896011876995,
        "from_longitude": 105.83923940375213,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1759,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83896011876995,
          21.018553022578292,
          105.83898157644087,
          21.018857227461744
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83896011876995,
            21.018553022578292
          ],
          [
            105.83898157644087,
            21.018857227461744
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hồ Dài",
        "diemDau": "7-11",
        "diemCuoi": "7-11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018553022578292,
        "to_latitude": 21.018857227461744,
        "to_longitude": 105.83898157644087,
        "from_longitude": 105.83896011876995,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1760,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83907847119778,
          21.018065104192512,
          105.83931584674464,
          21.018284182564614
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83907847119778,
            21.018284182564614
          ],
          [
            105.83910395223421,
            21.01821407751915
          ],
          [
            105.83931584674464,
            21.018065104192512
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Miếu Chợ 37",
        "diemDau": "27",
        "diemCuoi": "27-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018284182564614,
        "to_latitude": 21.018065104192512,
        "to_longitude": 105.83931584674464,
        "from_longitude": 105.83907847119778,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1761,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83916564303922,
          21.01781973603542,
          105.83931584674464,
          21.018065104192512
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83931584674464,
            21.018065104192512
          ],
          [
            105.83916564303922,
            21.01781973603542
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Miếu Chợ 37",
        "diemDau": "27-1",
        "diemCuoi": "27-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018065104192512,
        "to_latitude": 21.01781973603542,
        "to_longitude": 105.83916564303922,
        "from_longitude": 105.83931584674464,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1762,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82290340977396,
          21.01443336386117,
          105.82299728708986,
          21.01450847831033
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82299728708986,
            21.01450847831033
          ],
          [
            105.82290340977396,
            21.01443336386117
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "12-2",
        "diemCuoi": "12-5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01450847831033,
        "to_latitude": 21.01443336386117,
        "to_longitude": 105.82290340977396,
        "from_longitude": 105.82299728708986,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1763,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82276125270074,
          21.01443336386117,
          105.82290340977396,
          21.014591104161347
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82290340977396,
            21.01443336386117
          ],
          [
            105.82276125270074,
            21.014591104161347
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "12-5",
        "diemCuoi": "12-6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01443336386117,
        "to_latitude": 21.014591104161347,
        "to_longitude": 105.82276125270074,
        "from_longitude": 105.82290340977396,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1764,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82413756119739,
          21.010926728866902,
          105.82427938301035,
          21.011121091996305
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82427938301035,
            21.010926728866902
          ],
          [
            105.82413756119739,
            21.011121091996305
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "19",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.010926728866902,
        "to_latitude": 21.011121091996305,
        "to_longitude": 105.82413756119739,
        "from_longitude": 105.82427938301035,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1765,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82408089954761,
          21.011121091996305,
          105.82413756119739,
          21.011247224456476
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82413756119739,
            21.011121091996305
          ],
          [
            105.82408089954761,
            21.011247224456476
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.011121091996305,
        "to_latitude": 21.011247224456476,
        "to_longitude": 105.82408089954761,
        "from_longitude": 105.82413756119739,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1766,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80112705766288,
          21.02250294325555,
          105.80131699161655,
          21.023065800785233
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80124138685603,
            21.023065800785233
          ],
          [
            105.80128799019927,
            21.022924343324885
          ],
          [
            105.80112705766288,
            21.022884284551314
          ],
          [
            105.80131699161655,
            21.02250294325555
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG3",
        "chieuDaiQL": 86,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 185 Phố Chùa Láng",
        "diemDau": "Tủ điện",
        "diemCuoi": "39",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Thượng 3",
        "from_latitude": 21.023065800785233,
        "to_latitude": 21.02250294325555,
        "to_longitude": 105.80131699161655,
        "from_longitude": 105.80124138685603,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1767,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82282931375589,
          21.013047495479494,
          105.82289536314285,
          21.01311353421012
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82289536314285,
            21.013047495479494
          ],
          [
            105.82282931375589,
            21.01311353421012
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "08",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.013047495479494,
        "to_latitude": 21.01311353421012,
        "to_longitude": 105.82282931375589,
        "from_longitude": 105.82289536314285,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1768,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82261641341083,
          21.012941082286424,
          105.82282931375589,
          21.01311353421012
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82282931375589,
            21.01311353421012
          ],
          [
            105.82261641341083,
            21.012941082286424
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "8-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01311353421012,
        "to_latitude": 21.012941082286424,
        "to_longitude": 105.82261641341083,
        "from_longitude": 105.82282931375589,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1769,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82260836677861,
          21.01311353421012,
          105.82282931375589,
          21.013366734595152
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82282931375589,
            21.01311353421012
          ],
          [
            105.82260836677861,
            21.013366734595152
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH6",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH6",
        "from_latitude": 21.01311353421012,
        "to_latitude": 21.013366734595152,
        "to_longitude": 105.82260836677861,
        "from_longitude": 105.82282931375589,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1770,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82071137441434,
          21.006332152211808,
          105.82086769794815,
          21.00658502561732
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82072084580153,
            21.00658502561732
          ],
          [
            105.82071137441434,
            21.006515462807346
          ],
          [
            105.82086769794815,
            21.006332152211808
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vĩnh Hồ",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00658502561732,
        "to_latitude": 21.006332152211808,
        "to_longitude": 105.82086769794815,
        "from_longitude": 105.82072084580153,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1771,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82086769794815,
          21.006127350261657,
          105.82105737941966,
          21.006332152211808
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82086769794815,
            21.006332152211808
          ],
          [
            105.82105737941966,
            21.006127350261657
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vĩnh Hồ",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.006332152211808,
        "to_latitude": 21.006127350261657,
        "to_longitude": 105.82105737941966,
        "from_longitude": 105.82086769794815,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1772,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82105737941966,
          21.005923070586267,
          105.82123837312973,
          21.006127350261657
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82105737941966,
            21.006127350261657
          ],
          [
            105.82123837312973,
            21.005923070586267
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vĩnh Hồ",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.006127350261657,
        "to_latitude": 21.005923070586267,
        "to_longitude": 105.82123837312973,
        "from_longitude": 105.82105737941966,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1773,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82123837312973,
          21.005687905505418,
          105.82143020646647,
          21.005923070586267
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82123837312973,
            21.005923070586267
          ],
          [
            105.82143020646647,
            21.005687905505418
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vĩnh Hồ",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005923070586267,
        "to_latitude": 21.005687905505418,
        "to_longitude": 105.82143020646647,
        "from_longitude": 105.82123837312973,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1774,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82143020646647,
          21.00544001302203,
          105.8216689230723,
          21.005687905505418
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82143020646647,
            21.005687905505418
          ],
          [
            105.8216689230723,
            21.00544001302203
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vĩnh Hồ",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005687905505418,
        "to_latitude": 21.00544001302203,
        "to_longitude": 105.8216689230723,
        "from_longitude": 105.82143020646647,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1775,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8216689230723,
          21.00525597136811,
          105.8218379022409,
          21.00544001302203
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8216689230723,
            21.00544001302203
          ],
          [
            105.8218379022409,
            21.00525597136811
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vĩnh Hồ",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00544001302203,
        "to_latitude": 21.00525597136811,
        "to_longitude": 105.8218379022409,
        "from_longitude": 105.8216689230723,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1776,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83905567246792,
          21.018111423647735,
          105.83910395223421,
          21.018284182564614
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83907847119778,
            21.018284182564614
          ],
          [
            105.83910395223421,
            21.01821407751915
          ],
          [
            105.83905567246792,
            21.018111423647735
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chợ Khâm Thiên",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018284182564614,
        "to_latitude": 21.018111423647735,
        "to_longitude": 105.83905567246792,
        "from_longitude": 105.83907847119778,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1777,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8389443608017,
          21.01789860441677,
          105.83905567246792,
          21.018111423647735
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83905567246792,
            21.018111423647735
          ],
          [
            105.8389443608017,
            21.01789860441677
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chợ Khâm Thiên",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018111423647735,
        "to_latitude": 21.01789860441677,
        "to_longitude": 105.8389443608017,
        "from_longitude": 105.83905567246792,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1778,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83885718900518,
          21.017729600693517,
          105.8389443608017,
          21.01789860441677
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8389443608017,
            21.01789860441677
          ],
          [
            105.83885718900518,
            21.017729600693517
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chợ Khâm Thiên",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01789860441677,
        "to_latitude": 21.017729600693517,
        "to_longitude": 105.83885718900518,
        "from_longitude": 105.8389443608017,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1779,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83874855953772,
          21.017516780918488,
          105.83885718900518,
          21.017729600693517
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83885718900518,
            21.017729600693517
          ],
          [
            105.83874855953772,
            21.017516780918488
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chợ Khâm Thiên",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.017729600693517,
        "to_latitude": 21.017516780918488,
        "to_longitude": 105.83874855953772,
        "from_longitude": 105.83885718900518,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1780,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82055044190489,
          21.00638776135776,
          105.82071137441434,
          21.006515462807346
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82071137441434,
            21.006515462807346
          ],
          [
            105.82055044190489,
            21.00638776135776
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 107 Vĩnh Hồ",
        "diemDau": "01",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.006515462807346,
        "to_latitude": 21.00638776135776,
        "to_longitude": 105.82055044190489,
        "from_longitude": 105.82071137441434,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1781,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82043108360648,
          21.00624879203216,
          105.82055044190489,
          21.00638776135776
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82055044190489,
            21.00638776135776
          ],
          [
            105.82043108360648,
            21.00624879203216
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 107 Vĩnh Hồ",
        "diemDau": "1-1",
        "diemCuoi": "1-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00638776135776,
        "to_latitude": 21.00624879203216,
        "to_longitude": 105.82043108360648,
        "from_longitude": 105.82055044190489,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1782,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82067867867352,
          21.006198082228643,
          105.82081195728551,
          21.00629761910692
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82081195728551,
            21.00629761910692
          ],
          [
            105.82067867867352,
            21.006198082228643
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 107 Vĩnh Hồ",
        "diemDau": "1-3",
        "diemCuoi": "1-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00629761910692,
        "to_latitude": 21.006198082228643,
        "to_longitude": 105.82067867867352,
        "from_longitude": 105.82081195728551,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1783,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83868820983659,
          21.017729600693517,
          105.83885718900518,
          21.01785729241361
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83885718900518,
            21.017729600693517
          ],
          [
            105.83868820983659,
            21.01785729241361
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Khâm Đức",
        "diemDau": "30",
        "diemCuoi": "30-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.017729600693517,
        "to_latitude": 21.01785729241361,
        "to_longitude": 105.83868820983659,
        "from_longitude": 105.83885718900518,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1784,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83844144660752,
          21.01785729241361,
          105.83868820983659,
          21.01797872463302
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83868820983659,
            21.01785729241361
          ],
          [
            105.83844144660752,
            21.01797872463302
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Khâm Đức",
        "diemDau": "30-1",
        "diemCuoi": "30-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01785729241361,
        "to_latitude": 21.01797872463302,
        "to_longitude": 105.83844144660752,
        "from_longitude": 105.83868820983659,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1785,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83797474223584,
          21.018058844807047,
          105.83812762814901,
          21.018145224320993
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83797474223584,
            21.018145224320993
          ],
          [
            105.83812762814901,
            21.018058844807047
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Khâm Đức",
        "diemDau": "30-6",
        "diemCuoi": "30-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018145224320993,
        "to_latitude": 21.018058844807047,
        "to_longitude": 105.83812762814901,
        "from_longitude": 105.83797474223584,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1786,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83862517792768,
          21.017684533002154,
          105.83868820983659,
          21.01785729241361
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83868820983659,
            21.01785729241361
          ],
          [
            105.83862517792768,
            21.017684533002154
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Khâm Đức",
        "diemDau": "30-1",
        "diemCuoi": "30-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01785729241361,
        "to_latitude": 21.017684533002154,
        "to_longitude": 105.83862517792768,
        "from_longitude": 105.83868820983659,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1787,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83849643189319,
          21.017684533002154,
          105.83862517792768,
          21.017762149574065
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83862517792768,
            21.017684533002154
          ],
          [
            105.83849643189319,
            21.017762149574065
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Khâm Đức",
        "diemDau": "30-2",
        "diemCuoi": "30-2-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.017684533002154,
        "to_latitude": 21.017762149574065,
        "to_longitude": 105.83849643189319,
        "from_longitude": 105.83862517792768,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1788,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83860908467226,
          21.01750426209963,
          105.83874855953772,
          21.017543070436698
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83874855953772,
            21.017516780918488
          ],
          [
            105.83868686873271,
            21.01750426209963
          ],
          [
            105.83860908467226,
            21.017543070436698
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Khâm Đức",
        "diemDau": "31",
        "diemCuoi": "31-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.017516780918488,
        "to_latitude": 21.017543070436698,
        "to_longitude": 105.83860908467226,
        "from_longitude": 105.83874855953772,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1789,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83883841354199,
          21.018111423647735,
          105.83905567246792,
          21.018206566263572
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83905567246792,
            21.018111423647735
          ],
          [
            105.83883841354199,
            21.018206566263572
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Cây Sữa",
        "diemDau": "28",
        "diemCuoi": "28-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.018111423647735,
        "to_latitude": 21.018206566263572,
        "to_longitude": 105.83883841354199,
        "from_longitude": 105.83905567246792,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1790,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83827112633506,
          21.017961198340082,
          105.83844144660752,
          21.018023792231283
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83844144660752,
            21.01797872463302
          ],
          [
            105.83835427480201,
            21.018023792231283
          ],
          [
            105.83829124289312,
            21.018006265942823
          ],
          [
            105.83827112633506,
            21.017961198340082
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Khâm Đức",
        "diemDau": "30-3",
        "diemCuoi": "30-1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01797872463302,
        "to_latitude": 21.017961198340082,
        "to_longitude": 105.83827112633506,
        "from_longitude": 105.83844144660752,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1791,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83797474223584,
          21.01797872463302,
          105.83844144660752,
          21.018145224320993
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83844144660752,
            21.01797872463302
          ],
          [
            105.83797474223584,
            21.018145224320993
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Khâm Đức",
        "diemDau": "30-3",
        "diemCuoi": "30-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01797872463302,
        "to_latitude": 21.018145224320993,
        "to_longitude": 105.83797474223584,
        "from_longitude": 105.83844144660752,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1792,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83852191287572,
          21.017636961529828,
          105.83862517792768,
          21.017684533002154
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83862517792768,
            21.017684533002154
          ],
          [
            105.83857958036015,
            21.017636961529828
          ],
          [
            105.83852191287572,
            21.01765699162789
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Khâm Đức",
        "diemDau": "30-2",
        "diemCuoi": "30-2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.017684533002154,
        "to_latitude": 21.01765699162789,
        "to_longitude": 105.83852191287572,
        "from_longitude": 105.83862517792768,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1793,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83827917295828,
          21.017868559324466,
          105.83844144660752,
          21.018006067725008
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83844144660752,
            21.01797872463302
          ],
          [
            105.83836480301277,
            21.018006067725008
          ],
          [
            105.83827917295828,
            21.017868559324466
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Khâm Đức",
        "diemDau": "30-3",
        "diemCuoi": "30-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01797872463302,
        "to_latitude": 21.017868559324466,
        "to_longitude": 105.83827917295828,
        "from_longitude": 105.83844144660752,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1794,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81192512829367,
          21.015362276557383,
          105.81204582769598,
          21.015422367745813
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81204582769598,
            21.015362276557383
          ],
          [
            105.81192512829367,
            21.015422367745813
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHH",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "diemDau": "Tủ điện",
        "diemCuoi": "01",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Viện Hóa Học",
        "from_latitude": 21.015362276557383,
        "to_latitude": 21.015422367745813,
        "to_longitude": 105.81192512829367,
        "from_longitude": 105.81204582769598,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1795,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81171591599097,
          21.015422367745813,
          105.81192512829367,
          21.015545053846633
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81192512829367,
            21.015422367745813
          ],
          [
            105.81171591599097,
            21.015545053846633
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHH",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Viện Hóa Học",
        "from_latitude": 21.015422367745813,
        "to_latitude": 21.015545053846633,
        "to_longitude": 105.81171591599097,
        "from_longitude": 105.81192512829367,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1796,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81171055157552,
          21.015545053846633,
          105.81171591599097,
          21.015642701895644
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81171591599097,
            21.015545053846633
          ],
          [
            105.81171055157552,
            21.015642701895644
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHH",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "diemDau": "02",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Viện Hóa Học",
        "from_latitude": 21.015545053846633,
        "to_latitude": 21.015642701895644,
        "to_longitude": 105.81171055157552,
        "from_longitude": 105.81171591599097,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1797,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81149597484834,
          21.015540046252518,
          105.81171591599097,
          21.015638946203573
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81171591599097,
            21.015545053846633
          ],
          [
            105.81157241780491,
            21.015638946203573
          ],
          [
            105.81149597484834,
            21.015540046252518
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHH",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "diemDau": "02",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Viện Hóa Học",
        "from_latitude": 21.015545053846633,
        "to_latitude": 21.015540046252518,
        "to_longitude": 105.81149597484834,
        "from_longitude": 105.81171591599097,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1798,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81157241780491,
          21.015638946203573,
          105.81163813193054,
          21.015727830912724
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81163813193054,
            21.015727830912724
          ],
          [
            105.81157241780491,
            21.015638946203573
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHH",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "diemDau": "cột đỡ",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Viện Hóa Học",
        "from_latitude": 21.015727830912724,
        "to_latitude": 21.015638946203573,
        "to_longitude": 105.81157241780491,
        "from_longitude": 105.81163813193054,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1799,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81204582769598,
          21.015362276557383,
          105.81207801420683,
          21.015404841151625
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81204582769598,
            21.015362276557383
          ],
          [
            105.81207801420683,
            21.015404841151625
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHH",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "diemDau": "Tủ điện",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Viện Hóa Học",
        "from_latitude": 21.015362276557383,
        "to_latitude": 21.015404841151625,
        "to_longitude": 105.81207801420683,
        "from_longitude": 105.81204582769598,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1800,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81122775394837,
          21.015540046252518,
          105.81149597484834,
          21.015660228461893
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81149597484834,
            21.015540046252518
          ],
          [
            105.81122775394837,
            21.015660228461893
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHH",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Viện Hóa Học",
        "from_latitude": 21.015540046252518,
        "to_latitude": 21.015660228461893,
        "to_longitude": 105.81122775394837,
        "from_longitude": 105.81149597484834,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1801,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8111338766325,
          21.015660228461893,
          105.81122775394837,
          21.015760380229327
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81122775394837,
            21.015660228461893
          ],
          [
            105.8111338766325,
            21.015760380229327
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHH",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Viện Hóa Học",
        "from_latitude": 21.015660228461893,
        "to_latitude": 21.015760380229327,
        "to_longitude": 105.8111338766325,
        "from_longitude": 105.81122775394837,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1802,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81127066929022,
          21.01534725375608,
          105.81149597484834,
          21.015540046252518
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81149597484834,
            21.015540046252518
          ],
          [
            105.81137661654991,
            21.015369787957894
          ],
          [
            105.81127066929022,
            21.01534725375608
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHH",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "diemDau": "06",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Viện Hóa Học",
        "from_latitude": 21.015540046252518,
        "to_latitude": 21.01534725375608,
        "to_longitude": 105.81127066929022,
        "from_longitude": 105.81149597484834,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1803,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81174005586965,
          21.014828966198987,
          105.81204582769598,
          21.015362276557383
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81204582769598,
            21.015362276557383
          ],
          [
            105.81194658596459,
            21.015079346887447
          ],
          [
            105.81189428288667,
            21.014990461790983
          ],
          [
            105.81174005586965,
            21.014828966198987
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHH",
        "chieuDaiQL": 75,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "diemDau": "Tủ điện",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Viện Hóa Học",
        "from_latitude": 21.015362276557383,
        "to_latitude": 21.014828966198987,
        "to_longitude": 105.81174005586965,
        "from_longitude": 105.81204582769598,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1804,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81156839449329,
          21.01455104314403,
          105.81174005586965,
          21.014828966198987
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81174005586965,
            21.014828966198987
          ],
          [
            105.81156839449329,
            21.01455104314403
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHH",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Viện Hóa Học",
        "from_latitude": 21.014828966198987,
        "to_latitude": 21.01455104314403,
        "to_longitude": 105.81156839449329,
        "from_longitude": 105.81174005586965,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1805,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81146378833746,
          21.014258096660143,
          105.81156839449329,
          21.01455104314403
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81156839449329,
            21.01455104314403
          ],
          [
            105.81146378833746,
            21.014394554709842
          ],
          [
            105.81146915276194,
            21.014258096660143
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHH",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Vũ Ngọc Phan",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Viện Hóa Học",
        "from_latitude": 21.01455104314403,
        "to_latitude": 21.014258096660143,
        "to_longitude": 105.81146915276194,
        "from_longitude": 105.81156839449329,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1806,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81137661654991,
          21.01535726895712,
          105.81145305950649,
          21.015369787957894
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81137661654991,
            21.015369787957894
          ],
          [
            105.81145305950649,
            21.01535726895712
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VHH",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "diemDau": "cột đỡ",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Viện Hóa Học",
        "from_latitude": 21.015369787957894,
        "to_latitude": 21.01535726895712,
        "to_longitude": 105.81145305950649,
        "from_longitude": 105.81137661654991,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1807,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81130017359334,
          21.01514945340318,
          105.81145305950649,
          21.01535726895712
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81145305950649,
            21.01535726895712
          ],
          [
            105.81130017359334,
            21.01514945340318
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VHH",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "diemDau": "14",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Viện Hóa Học",
        "from_latitude": 21.01535726895712,
        "to_latitude": 21.01514945340318,
        "to_longitude": 105.81130017359334,
        "from_longitude": 105.81145305950649,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1808,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81145305950649,
          21.01519201805817,
          105.81173200924643,
          21.01535726895712
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81145305950649,
            21.01535726895712
          ],
          [
            105.81173200924643,
            21.01519201805817
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VHH",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Viện Hóa Học",
        "from_latitude": 21.01535726895712,
        "to_latitude": 21.01519201805817,
        "to_longitude": 105.81173200924643,
        "from_longitude": 105.81145305950649,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1809,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81173200924643,
          21.01508435449538,
          105.81190367062275,
          21.01519201805817
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81173200924643,
            21.01519201805817
          ],
          [
            105.81190367062275,
            21.01508435449538
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VHH",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Viện Hóa Học",
        "from_latitude": 21.01519201805817,
        "to_latitude": 21.01508435449538,
        "to_longitude": 105.81190367062275,
        "from_longitude": 105.81173200924643,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1810,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81158180554098,
          21.014916599486888,
          105.81173200924643,
          21.01519201805817
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81173200924643,
            21.01519201805817
          ],
          [
            105.81158180554098,
            21.014916599486888
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VHH",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "diemDau": "15",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Viện Hóa Học",
        "from_latitude": 21.01519201805817,
        "to_latitude": 21.014916599486888,
        "to_longitude": 105.81158180554098,
        "from_longitude": 105.81173200924643,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1811,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81140746195693,
          21.014671226150362,
          105.81158180554098,
          21.014916599486888
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81158180554098,
            21.014916599486888
          ],
          [
            105.81140746195693,
            21.014671226150362
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VHH",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Viện Hóa Học",
        "from_latitude": 21.014916599486888,
        "to_latitude": 21.014671226150362,
        "to_longitude": 105.81140746195693,
        "from_longitude": 105.81158180554098,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1812,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81112314780152,
          21.014671226150362,
          105.81140746195693,
          21.014818950962475
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81140746195693,
            21.014671226150362
          ],
          [
            105.81112314780152,
            21.014818950962475
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VHH",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Viện Hóa Học",
        "from_latitude": 21.014671226150362,
        "to_latitude": 21.014818950962475,
        "to_longitude": 105.81112314780152,
        "from_longitude": 105.81140746195693,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1813,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83338011818192,
          21.007923301195802,
          105.83363895135477,
          21.008123615052877
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83338011818192,
            21.007923301195802
          ],
          [
            105.83363895135477,
            21.008123615052877
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.007923301195802,
        "to_latitude": 21.008123615052877,
        "to_longitude": 105.83363895135477,
        "from_longitude": 105.83338011818192,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1814,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83313033744895,
          21.007720796134013,
          105.83338011818192,
          21.00792330119582
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83338011818192,
            21.00792330119582
          ],
          [
            105.83313033744895,
            21.007720796134013
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.00792330119582,
        "to_latitude": 21.007720796134013,
        "to_longitude": 105.83313033744895,
        "from_longitude": 105.83338011818192,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1815,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83297342822415,
          21.007588087875924,
          105.83313033744895,
          21.007720796134002
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83313033744895,
            21.007720796134002
          ],
          [
            105.83297342822415,
            21.007588087875924
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.007720796134002,
        "to_latitude": 21.007588087875924,
        "to_longitude": 105.83297342822415,
        "from_longitude": 105.83313033744895,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1816,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83297342822415,
          21.007389025267035,
          105.83316922947915,
          21.007588087875924
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83297342822415,
            21.007588087875924
          ],
          [
            105.83316922947915,
            21.007389025267035
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Phạm Ngọc Thạch",
        "diemDau": "1-1",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.007588087875924,
        "to_latitude": 21.007389025267035,
        "to_longitude": 105.83316922947915,
        "from_longitude": 105.83297342822415,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1817,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83510712548006,
          21.00681374535721,
          105.83531097336726,
          21.007031588283162
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83510712548006,
            21.007031588283162
          ],
          [
            105.83531097336726,
            21.00681374535721
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương Đình Của",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.007031588283162,
        "to_latitude": 21.00681374535721,
        "to_longitude": 105.83531097336726,
        "from_longitude": 105.83510712548006,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1818,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83531097336726,
          21.006533303420486,
          105.8354826347436,
          21.00681374535721
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83531097336726,
            21.00681374535721
          ],
          [
            105.8354826347436,
            21.006533303420486
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương Đình Của",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.00681374535721,
        "to_latitude": 21.006533303420486,
        "to_longitude": 105.8354826347436,
        "from_longitude": 105.83531097336726,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1819,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83512590094323,
          21.006625949476145,
          105.83531097336726,
          21.0068137453572
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83531097336726,
            21.0068137453572
          ],
          [
            105.8352466003545,
            21.00672360336369
          ],
          [
            105.83512590094323,
            21.006625949476145
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B15 - B19",
        "diemDau": "09",
        "diemCuoi": "9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.0068137453572,
        "to_latitude": 21.006625949476145,
        "to_longitude": 105.83512590094323,
        "from_longitude": 105.83531097336726,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1820,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83497569723782,
          21.006500752090506,
          105.83512590094323,
          21.006625949476145
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83512590094323,
            21.006625949476145
          ],
          [
            105.83497569723782,
            21.006500752090506
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B15 - B19",
        "diemDau": "9-1",
        "diemCuoi": "9-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.006625949476145,
        "to_latitude": 21.006500752090506,
        "to_longitude": 105.83497569723782,
        "from_longitude": 105.83512590094323,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1821,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83477721377507,
          21.006350515089263,
          105.83497569723782,
          21.006500752090506
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83497569723782,
            21.006500752090506
          ],
          [
            105.83477721377507,
            21.006350515089263
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B15 - B19",
        "diemDau": "9-2",
        "diemCuoi": "9-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.006500752090506,
        "to_latitude": 21.006350515089263,
        "to_longitude": 105.83477721377507,
        "from_longitude": 105.83497569723782,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1822,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83462432785294,
          21.006285412342415,
          105.83477721377507,
          21.006350515089263
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83477721377507,
            21.006350515089263
          ],
          [
            105.83462432785294,
            21.006285412342415
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B15 - B19",
        "diemDau": "9-3",
        "diemCuoi": "9-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.006350515089263,
        "to_latitude": 21.006285412342415,
        "to_longitude": 105.83462432785294,
        "from_longitude": 105.83477721377507,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1823,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83484695120332,
          21.006816249301185,
          105.83510712548006,
          21.007031588283148
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83510712548006,
            21.007031588283148
          ],
          [
            105.83500788373969,
            21.006963981892895
          ],
          [
            105.83484695120332,
            21.006816249301185
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B15 - B19",
        "diemDau": "08",
        "diemCuoi": "8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.007031588283148,
        "to_latitude": 21.006816249301185,
        "to_longitude": 105.83484695120332,
        "from_longitude": 105.83510712548006,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1824,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83470479412112,
          21.0066860441847,
          105.83484695120332,
          21.006816249301185
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83484695120332,
            21.006816249301185
          ],
          [
            105.83470479412112,
            21.0066860441847
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B15 - B19",
        "diemDau": "8-1",
        "diemCuoi": "8-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.006816249301185,
        "to_latitude": 21.0066860441847,
        "to_longitude": 105.83470479412112,
        "from_longitude": 105.83484695120332,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1825,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83452508612156,
          21.006488232347085,
          105.83470479412112,
          21.0066860441847
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83470479412112,
            21.0066860441847
          ],
          [
            105.83452508612156,
            21.006488232347085
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B15 - B19",
        "diemDau": "8-2",
        "diemCuoi": "8-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.0066860441847,
        "to_latitude": 21.006488232347085,
        "to_longitude": 105.83452508612156,
        "from_longitude": 105.83470479412112,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1826,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83481476469244,
          21.00716179309977,
          105.834860362251,
          21.007307021412835
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.834860362251,
            21.007307021412835
          ],
          [
            105.83481476469244,
            21.00716179309977
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B15 - B19",
        "diemDau": "07",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.007307021412835,
        "to_latitude": 21.00716179309977,
        "to_longitude": 105.83481476469244,
        "from_longitude": 105.834860362251,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1827,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83430782719564,
          21.006681036294044,
          105.83462701006067,
          21.006931430656014
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83462701006067,
            21.006931430656014
          ],
          [
            105.83447412415649,
            21.00681875324596
          ],
          [
            105.83430782719564,
            21.006681036294044
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B15 - B19",
        "diemDau": "7-2",
        "diemCuoi": "7-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.006931430656014,
        "to_latitude": 21.006681036294044,
        "to_longitude": 105.83430782719564,
        "from_longitude": 105.83462701006067,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1828,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83408252163753,
          21.00648072050104,
          105.83430782719564,
          21.006681036294044
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83430782719564,
            21.006681036294044
          ],
          [
            105.83408252163753,
            21.00648072050104
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B15 - B19",
        "diemDau": "7-3",
        "diemCuoi": "7-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.006681036294044,
        "to_latitude": 21.00648072050104,
        "to_longitude": 105.83408252163753,
        "from_longitude": 105.83430782719564,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1829,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83395645781077,
          21.006681036294044,
          105.83430782719564,
          21.006891367587517
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83430782719564,
            21.006681036294044
          ],
          [
            105.8340476529189,
            21.006891367587517
          ],
          [
            105.83395645781077,
            21.006823761132864
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 51,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B15 - B19",
        "diemDau": "7-3",
        "diemCuoi": "7-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.006681036294044,
        "to_latitude": 21.006823761132864,
        "to_longitude": 105.83395645781077,
        "from_longitude": 105.83430782719564,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1830,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83373651667709,
          21.006623445530646,
          105.83395645781077,
          21.006823761132864
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83395645781077,
            21.006823761132864
          ],
          [
            105.83373651667709,
            21.006623445530646
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B15 - B19",
        "diemDau": "7-8",
        "diemCuoi": "7-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.006823761132864,
        "to_latitude": 21.006623445530646,
        "to_longitude": 105.83373651667709,
        "from_longitude": 105.83395645781077,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1831,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83463773890064,
          21.007307021412835,
          105.834860362251,
          21.00754489507204
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.834860362251,
            21.007307021412835
          ],
          [
            105.83463773890064,
            21.00754489507204
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương Đình Của",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.007307021412835,
        "to_latitude": 21.00754489507204,
        "to_longitude": 105.83463773890064,
        "from_longitude": 105.834860362251,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1832,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83442718551203,
          21.007342076505413,
          105.83463773890064,
          21.00754489507204
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83463773890064,
            21.00754489507204
          ],
          [
            105.83452374503567,
            21.00746226531979
          ],
          [
            105.83442718551203,
            21.007342076505413
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B15 - B19",
        "diemDau": "06",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.00754489507204,
        "to_latitude": 21.007342076505413,
        "to_longitude": 105.83442718551203,
        "from_longitude": 105.83463773890064,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1833,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83430648610077,
          21.007229399403766,
          105.83442718551203,
          21.007342076505413
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83442718551203,
            21.007342076505413
          ],
          [
            105.83430648610077,
            21.007229399403766
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B15 - B19",
        "diemDau": "6-1",
        "diemCuoi": "6-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.007342076505413,
        "to_latitude": 21.007229399403766,
        "to_longitude": 105.83430648610077,
        "from_longitude": 105.83442718551203,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1834,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83433062597943,
          21.00754489507204,
          105.83463773890064,
          21.007870405779407
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83463773890064,
            21.00754489507204
          ],
          [
            105.83433062597943,
            21.007870405779407
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương Đình Của",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.00754489507204,
        "to_latitude": 21.007870405779407,
        "to_longitude": 105.83433062597943,
        "from_longitude": 105.83463773890064,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1835,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83405704066396,
          21.007870405779407,
          105.83433062597943,
          21.008160860886314
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83433062597943,
            21.007870405779407
          ],
          [
            105.83405704066396,
            21.008160860886314
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương Đình Của",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.007870405779407,
        "to_latitude": 21.008160860886314,
        "to_longitude": 105.83405704066396,
        "from_longitude": 105.83433062597943,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1836,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83427966401437,
          21.007577446175915,
          105.83438427016121,
          21.007870405779407
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83433062597943,
            21.007870405779407
          ],
          [
            105.83438427016121,
            21.00766257979499
          ],
          [
            105.83427966401437,
            21.007577446175915
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B15 - B19",
        "diemDau": "05",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.007870405779407,
        "to_latitude": 21.007577446175915,
        "to_longitude": 105.83427966401437,
        "from_longitude": 105.83433062597943,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1837,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83412677810121,
          21.0074572574543,
          105.83427966401437,
          21.007577446175915
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83427966401437,
            21.007577446175915
          ],
          [
            105.83412677810121,
            21.0074572574543
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B15 - B19",
        "diemDau": "5-1",
        "diemCuoi": "5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.007577446175915,
        "to_latitude": 21.0074572574543,
        "to_longitude": 105.83412677810121,
        "from_longitude": 105.83427966401437,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1838,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83398730322675,
          21.007317037156174,
          105.83412677810121,
          21.0074572574543
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83412677810121,
            21.0074572574543
          ],
          [
            105.83398730322675,
            21.007317037156174
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B15 - B19",
        "diemDau": "5-2",
        "diemCuoi": "5-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.0074572574543,
        "to_latitude": 21.007317037156174,
        "to_longitude": 105.83398730322675,
        "from_longitude": 105.83412677810121,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1839,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83363895135477,
          21.008123615052888,
          105.8339219244063,
          21.008351471737786
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83363895135477,
            21.008123615052888
          ],
          [
            105.8339219244063,
            21.008351471737786
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 49,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "03",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.008123615052888,
        "to_latitude": 21.008351471737786,
        "to_longitude": 105.8339219244063,
        "from_longitude": 105.83363895135477,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1840,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8339219244063,
          21.0081608608863,
          105.83405704066396,
          21.008351471737786
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8339219244063,
            21.008351471737786
          ],
          [
            105.83405704066396,
            21.0081608608863
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.008351471737786,
        "to_latitude": 21.0081608608863,
        "to_longitude": 105.83405704066396,
        "from_longitude": 105.8339219244063,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1841,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82084594487189,
          21.006003734660524,
          105.82105737941966,
          21.00612735026167
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82105737941966,
            21.00612735026167
          ],
          [
            105.82102854567295,
            21.006070385273297
          ],
          [
            105.82098965364274,
            21.00604534571067
          ],
          [
            105.82084594487189,
            21.006003734660524
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà A6 Vĩnh Hồ",
        "diemDau": "03",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00612735026167,
        "to_latitude": 21.006003734660524,
        "to_longitude": 105.82084594487189,
        "from_longitude": 105.82105737941966,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1842,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82070838818487,
          21.005899996175142,
          105.82098965364274,
          21.00604534571067
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82098965364274,
            21.00604534571067
          ],
          [
            105.82070838818487,
            21.005899996175142
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà A6 Vĩnh Hồ",
        "diemDau": "03",
        "diemCuoi": "3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00604534571067,
        "to_latitude": 21.005899996175142,
        "to_longitude": 105.82070838818487,
        "from_longitude": 105.82098965364274,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1843,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82081195728551,
          21.00604534571067,
          105.8209950180672,
          21.00629761910692
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82098965364274,
            21.00604534571067
          ],
          [
            105.8209950180672,
            21.00608040109634
          ],
          [
            105.82081195728551,
            21.00629761910692
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà A6 Vĩnh Hồ",
        "diemDau": "03",
        "diemCuoi": "1-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00604534571067,
        "to_latitude": 21.00629761910692,
        "to_longitude": 105.82081195728551,
        "from_longitude": 105.82098965364274,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1844,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82104799168359,
          21.005780552088513,
          105.82123837312973,
          21.005923070586267
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82123837312973,
            21.005923070586267
          ],
          [
            105.82104799168359,
            21.005780552088513
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Vĩnh Hồ",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005923070586267,
        "to_latitude": 21.005780552088513,
        "to_longitude": 105.82104799168359,
        "from_longitude": 105.82123837312973,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1845,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82117941992583,
          21.005687905505418,
          105.82143020646647,
          21.005812477584147
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82143020646647,
            21.005687905505418
          ],
          [
            105.82130883651675,
            21.005812477584147
          ],
          [
            105.82117941992583,
            21.00570668522507
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 45 Vĩnh Hồ",
        "diemDau": "05",
        "diemCuoi": "51",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005687905505418,
        "to_latitude": 21.00570668522507,
        "to_longitude": 105.82117941992583,
        "from_longitude": 105.82143020646647,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1846,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82132560031064,
          21.005659109937806,
          105.82143020646647,
          21.005706059232345
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82143020646647,
            21.005687905505418
          ],
          [
            105.82139198500165,
            21.005706059232345
          ],
          [
            105.82132560031064,
            21.005659109937806
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 37 Vĩnh Hồ",
        "diemDau": "05",
        "diemCuoi": "5-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005687905505418,
        "to_latitude": 21.005659109937806,
        "to_longitude": 105.82132560031064,
        "from_longitude": 105.82143020646647,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1847,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82119947377075,
          21.00552814882358,
          105.82132560031064,
          21.005659109937806
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82132560031064,
            21.005659109937806
          ],
          [
            105.82119947377075,
            21.00552814882358
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 37 Vĩnh Hồ",
        "diemDau": "5-3",
        "diemCuoi": "5-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005659109937806,
        "to_latitude": 21.00552814882358,
        "to_longitude": 105.82119947377075,
        "from_longitude": 105.82132560031064,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1848,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82107282227774,
          21.005434304269635,
          105.82119947377075,
          21.00552814882358
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82119947377075,
            21.00552814882358
          ],
          [
            105.82107282227774,
            21.005434304269635
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 37 Vĩnh Hồ",
        "diemDau": "5-4",
        "diemCuoi": "5-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00552814882358,
        "to_latitude": 21.005434304269635,
        "to_longitude": 105.82107282227774,
        "from_longitude": 105.82119947377075,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1849,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82096484319865,
          21.005537667688262,
          105.82117941992583,
          21.00570668522507
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82117941992583,
            21.00570668522507
          ],
          [
            105.82096484319865,
            21.005537667688262
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 45 Vĩnh Hồ",
        "diemDau": "5-1",
        "diemCuoi": "5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00570668522507,
        "to_latitude": 21.005537667688262,
        "to_longitude": 105.82096484319865,
        "from_longitude": 105.82117941992583,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1850,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8216689230723,
          21.00544001302203,
          105.82172860222599,
          21.00558086107655
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8216689230723,
            21.00544001302203
          ],
          [
            105.82172860222599,
            21.00558086107655
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 7 Vĩnh Hồ",
        "diemDau": "06",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00544001302203,
        "to_latitude": 21.00558086107655,
        "to_longitude": 105.82172860222599,
        "from_longitude": 105.8216689230723,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1851,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82172860222599,
          21.00558086107655,
          105.82199816422983,
          21.00583876916604
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82172860222599,
            21.00558086107655
          ],
          [
            105.82199816422983,
            21.00583876916604
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 7 Vĩnh Hồ",
        "diemDau": "6-1",
        "diemCuoi": "6-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00558086107655,
        "to_latitude": 21.00583876916604,
        "to_longitude": 105.82199816422983,
        "from_longitude": 105.82172860222599,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1852,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82187344150695,
          21.00583876916604,
          105.82199816422983,
          21.005910132004928
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82199816422983,
            21.00583876916604
          ],
          [
            105.82187344150695,
            21.005910132004928
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 7 Vĩnh Hồ",
        "diemDau": "6-2",
        "diemCuoi": "6-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00583876916604,
        "to_latitude": 21.005910132004928,
        "to_longitude": 105.82187344150695,
        "from_longitude": 105.82199816422983,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1853,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82199816422983,
          21.00583876916604,
          105.82202833907144,
          21.005955829242144
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82199816422983,
            21.00583876916604
          ],
          [
            105.82202833907144,
            21.005955829242144
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 7 Vĩnh Hồ",
        "diemDau": "6-2",
        "diemCuoi": "6-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00583876916604,
        "to_latitude": 21.005955829242144,
        "to_longitude": 105.82202833907144,
        "from_longitude": 105.82199816422983,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1854,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82178224639881,
          21.00583876916604,
          105.82199816422983,
          21.006014046253796
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82199816422983,
            21.00583876916604
          ],
          [
            105.82178224639881,
            21.006014046253796
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 7 Vĩnh Hồ",
        "diemDau": "6-2",
        "diemCuoi": "6-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00583876916604,
        "to_latitude": 21.006014046253796,
        "to_longitude": 105.82178224639881,
        "from_longitude": 105.82199816422983,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1855,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82141813649571,
          21.00517208836255,
          105.8216689230723,
          21.00544001302203
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8216689230723,
            21.00544001302203
          ],
          [
            105.82141813649571,
            21.00517208836255
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Vĩnh Hồ A1 đến A5",
        "diemDau": "06",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00544001302203,
        "to_latitude": 21.00517208836255,
        "to_longitude": 105.82141813649571,
        "from_longitude": 105.8216689230723,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1856,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82117137325767,
          21.00489539930472,
          105.82141813649571,
          21.00517208836255
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82141813649571,
            21.00517208836255
          ],
          [
            105.82117137325767,
            21.00489539930472
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Vĩnh Hồ A1 đến A5",
        "diemDau": "08",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00517208836255,
        "to_latitude": 21.00489539930472,
        "to_longitude": 105.82117137325767,
        "from_longitude": 105.82141813649571,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1857,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82117137325767,
          21.004762378400645,
          105.82127306938895,
          21.00489539930472
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82117137325767,
            21.00489539930472
          ],
          [
            105.82127306938895,
            21.004762378400645
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Vĩnh Hồ A1 đến A5",
        "diemDau": "10",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00489539930472,
        "to_latitude": 21.004762378400645,
        "to_longitude": 105.82127306938895,
        "from_longitude": 105.82117137325767,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1858,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82127306938895,
          21.00469085349629,
          105.8214649514483,
          21.004762378400645
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82127306938895,
            21.004762378400645
          ],
          [
            105.8214649514483,
            21.00469085349629
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Vĩnh Hồ A1 đến A5",
        "diemDau": "09",
        "diemCuoi": "9-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.004762378400645,
        "to_latitude": 21.00469085349629,
        "to_longitude": 105.8214649514483,
        "from_longitude": 105.82127306938895,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1859,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82101848734453,
          21.00489539930472,
          105.82117137325767,
          21.005093213253954
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82117137325767,
            21.00489539930472
          ],
          [
            105.82101848734453,
            21.005093213253954
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Vĩnh Hồ A1 đến A5",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00489539930472,
        "to_latitude": 21.005093213253954,
        "to_longitude": 105.82101848734453,
        "from_longitude": 105.82117137325767,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1860,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82085123142564,
          21.005093213253954,
          105.82101848734453,
          21.005271945416883
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82101848734453,
            21.005093213253954
          ],
          [
            105.82085123142564,
            21.005271945416883
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Vĩnh Hồ A1 đến A5",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005093213253954,
        "to_latitude": 21.005271945416883,
        "to_longitude": 105.82085123142564,
        "from_longitude": 105.82101848734453,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1861,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82065940666853,
          21.005271945416883,
          105.82085123142564,
          21.00549729124872
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82085123142564,
            21.005271945416883
          ],
          [
            105.82065940666853,
            21.00549729124872
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Vĩnh Hồ A1 đến A5",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005271945416883,
        "to_latitude": 21.00549729124872,
        "to_longitude": 105.82065940666853,
        "from_longitude": 105.82085123142564,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1862,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82054138947399,
          21.00549729124872,
          105.82065940666853,
          21.005630001366406
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82065940666853,
            21.00549729124872
          ],
          [
            105.82054138947399,
            21.005630001366406
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "20.7",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00549729124872,
        "to_latitude": 21.005630001366406,
        "to_longitude": 105.82054138947399,
        "from_longitude": 105.82065940666853,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1863,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8204126434395,
          21.005630001366406,
          105.82054138947399,
          21.005680080626014
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82054138947399,
            21.005630001366406
          ],
          [
            105.8204126434395,
            21.005680080626014
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Vĩnh Hồ A1 đến A5",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005630001366406,
        "to_latitude": 21.005680080626014,
        "to_longitude": 105.8204126434395,
        "from_longitude": 105.82054138947399,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1864,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82024634647863,
          21.005680080626014,
          105.8204126434395,
          21.00591044500086
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8204126434395,
            21.005680080626014
          ],
          [
            105.82024634647863,
            21.00591044500086
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Vĩnh Hồ A1 đến A5",
        "diemDau": "15",
        "diemCuoi": "15-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005680080626014,
        "to_latitude": 21.00591044500086,
        "to_longitude": 105.82024634647863,
        "from_longitude": 105.8204126434395,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1865,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82019270229684,
          21.005519826937636,
          105.8204126434395,
          21.005680080626014
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8204126434395,
            21.005680080626014
          ],
          [
            105.82019270229684,
            21.005519826937636
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Vĩnh Hồ A1 đến A5",
        "diemDau": "15",
        "diemCuoi": "15-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005680080626014,
        "to_latitude": 21.005519826937636,
        "to_longitude": 105.82019270229684,
        "from_longitude": 105.8204126434395,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1866,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8200210409205,
          21.005519826937636,
          105.82019270229684,
          21.00572765590661
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82019270229684,
            21.005519826937636
          ],
          [
            105.8200210409205,
            21.00572765590661
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Vĩnh Hồ A1 đến A5",
        "diemDau": "15-2",
        "diemCuoi": "15-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005519826937636,
        "to_latitude": 21.00572765590661,
        "to_longitude": 105.8200210409205,
        "from_longitude": 105.82019270229684,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1867,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82049310970768,
          21.005437196062243,
          105.82065940666853,
          21.00549729124872
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82065940666853,
            21.00549729124872
          ],
          [
            105.82049310970768,
            21.005437196062243
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Vĩnh Hồ A1 đến A5",
        "diemDau": "13",
        "diemCuoi": "13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00549729124872,
        "to_latitude": 21.005437196062243,
        "to_longitude": 105.82049310970768,
        "from_longitude": 105.82065940666853,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1868,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82025171089411,
          21.005256910357556,
          105.82049310970768,
          21.005437196062243
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82049310970768,
            21.005437196062243
          ],
          [
            105.82025171089411,
            21.005256910357556
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Vĩnh Hồ A1 đến A5",
        "diemDau": "13-1",
        "diemCuoi": "13-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005437196062243,
        "to_latitude": 21.005256910357556,
        "to_longitude": 105.82025171089411,
        "from_longitude": 105.82049310970768,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1869,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82073350918314,
          21.005244751956816,
          105.82085123142564,
          21.005271945416883
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82085123142564,
            21.005271945416883
          ],
          [
            105.82073350918314,
            21.005244751956816
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Vĩnh Hồ A1 đến A5",
        "diemDau": "12",
        "diemCuoi": "12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005271945416883,
        "to_latitude": 21.005244751956816,
        "to_longitude": 105.82073350918314,
        "from_longitude": 105.82085123142564,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1870,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82045694168704,
          21.005041262608312,
          105.82073350918314,
          21.005244751956816
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82073350918314,
            21.005244751956816
          ],
          [
            105.82045694168704,
            21.005041262608312
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Vĩnh Hồ A1 đến A5",
        "diemDau": "12-1",
        "diemCuoi": "12-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005244751956816,
        "to_latitude": 21.005041262608312,
        "to_longitude": 105.82045694168704,
        "from_longitude": 105.82073350918314,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1871,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82088437689454,
          21.005030613930813,
          105.82101848734453,
          21.005093213253954
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82101848734453,
            21.005093213253954
          ],
          [
            105.82088437689454,
            21.005030613930813
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Vĩnh Hồ A1 đến A5",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005093213253954,
        "to_latitude": 21.005030613930813,
        "to_longitude": 105.82088437689454,
        "from_longitude": 105.82101848734453,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1872,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82063917432006,
          21.004830746442757,
          105.82088437689454,
          21.005030613930813
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82088437689454,
            21.005030613930813
          ],
          [
            105.82063917432006,
            21.004830746442757
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Vĩnh Hồ A1 đến A5",
        "diemDau": "11-1",
        "diemCuoi": "11-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005030613930813,
        "to_latitude": 21.004830746442757,
        "to_longitude": 105.82063917432006,
        "from_longitude": 105.82088437689454,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1873,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82086917823926,
          21.00475711388515,
          105.82117137325767,
          21.00489539930472
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82117137325767,
            21.00489539930472
          ],
          [
            105.82086917823926,
            21.00475711388515
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Vĩnh Hồ A1 đến A5",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00489539930472,
        "to_latitude": 21.00475711388515,
        "to_longitude": 105.82086917823926,
        "from_longitude": 105.82117137325767,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1874,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8206493548373,
          21.004584188961754,
          105.82086917823926,
          21.00475711388515
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82086917823926,
            21.00475711388515
          ],
          [
            105.8206493548373,
            21.004584188961754
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Vĩnh Hồ A1 đến A5",
        "diemDau": "10-1",
        "diemCuoi": "10-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00475711388515,
        "to_latitude": 21.004584188961754,
        "to_longitude": 105.8206493548373,
        "from_longitude": 105.82086917823926,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1875,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82050718422825,
          21.004370458767767,
          105.8206493548373,
          21.004584188961754
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8206493548373,
            21.004584188961754
          ],
          [
            105.82050718422825,
            21.004370458767767
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Vĩnh Hồ A1 đến A5",
        "diemDau": "10-2",
        "diemCuoi": "10-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.004584188961754,
        "to_latitude": 21.004370458767767,
        "to_longitude": 105.82050718422825,
        "from_longitude": 105.8206493548373,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1876,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82112553355996,
          21.004595514511035,
          105.82127306938895,
          21.004762378400645
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82127306938895,
            21.004762378400645
          ],
          [
            105.82112553355996,
            21.004595514511035
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Vĩnh Hồ A1 đến A5",
        "diemDau": "09",
        "diemCuoi": "9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.004762378400645,
        "to_latitude": 21.004595514511035,
        "to_longitude": 105.82112553355996,
        "from_longitude": 105.82127306938895,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1877,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82091929869006,
          21.00440856861934,
          105.82112553355996,
          21.004595514511035
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82112553355996,
            21.004595514511035
          ],
          [
            105.82091929869006,
            21.00440856861934
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Vĩnh Hồ A1 đến A5",
        "diemDau": "9-1",
        "diemCuoi": "9-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.004595514511035,
        "to_latitude": 21.00440856861934,
        "to_longitude": 105.82091929869006,
        "from_longitude": 105.82112553355996,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1878,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82112553355996,
          21.00458083702549,
          105.82121730613937,
          21.004595514511035
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82112553355996,
            21.004595514511035
          ],
          [
            105.82121730613937,
            21.00458083702549
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chùa Phúc Khánh",
        "diemDau": "9-1",
        "diemCuoi": "9-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.004595514511035,
        "to_latitude": 21.00458083702549,
        "to_longitude": 105.82121730613937,
        "from_longitude": 105.82112553355996,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1879,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82112879324798,
          21.004443118008098,
          105.82121730613937,
          21.00458083702549
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82121730613937,
            21.00458083702549
          ],
          [
            105.82112879324798,
            21.004443118008098
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chùa Phúc Khánh",
        "diemDau": "9-3",
        "diemCuoi": "9-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00458083702549,
        "to_latitude": 21.004443118008098,
        "to_longitude": 105.82112879324798,
        "from_longitude": 105.82121730613937,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1880,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82112879324798,
          21.004330438717343,
          105.82127095032116,
          21.004443118008098
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82112879324798,
            21.004443118008098
          ],
          [
            105.82127095032116,
            21.004330438717343
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chùa Phúc Khánh",
        "diemDau": "9-4",
        "diemCuoi": "9-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.004443118008098,
        "to_latitude": 21.004330438717343,
        "to_longitude": 105.82127095032116,
        "from_longitude": 105.82112879324798,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1881,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82041598653403,
          21.004584188961754,
          105.8206493548373,
          21.00479538022274
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8206493548373,
            21.004584188961754
          ],
          [
            105.82041598653403,
            21.00479538022274
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "10-2",
        "diemCuoi": "19-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.004584188961754,
        "to_latitude": 21.00479538022274,
        "to_longitude": 105.82041598653403,
        "from_longitude": 105.8206493548373,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1882,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8203204774489,
          21.00473264580425,
          105.82041598653403,
          21.00479538022274
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82041598653403,
            21.00479538022274
          ],
          [
            105.8203204774489,
            21.00473264580425
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "19",
        "diemCuoi": "19-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00479538022274,
        "to_latitude": 21.00473264580425,
        "to_longitude": 105.8203204774489,
        "from_longitude": 105.82041598653403,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1883,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82009547219882,
          21.00473264580425,
          105.8203204774489,
          21.00517177537009
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8203204774489,
            21.00473264580425
          ],
          [
            105.82026847460715,
            21.004913240141754
          ],
          [
            105.82022958257693,
            21.005004635216114
          ],
          [
            105.82014777520487,
            21.0051047941366
          ],
          [
            105.82009547219882,
            21.00517177537009
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 49,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00473264580425,
        "to_latitude": 21.00517177537009,
        "to_longitude": 105.82009547219882,
        "from_longitude": 105.8203204774489,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1884,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81993722187018,
          21.00517177537009,
          105.82009547219882,
          21.005288209979124
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82009547219882,
            21.00517177537009
          ],
          [
            105.81993722187018,
            21.005288209979124
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00517177537009,
        "to_latitude": 21.005288209979124,
        "to_longitude": 105.81993722187018,
        "from_longitude": 105.82009547219882,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1885,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8198245690911,
          21.005288209979124,
          105.81993722187018,
          21.005494787287635
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81993722187018,
            21.005288209979124
          ],
          [
            105.8198245690911,
            21.005494787287635
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005288209979124,
        "to_latitude": 21.005494787287635,
        "to_longitude": 105.8198245690911,
        "from_longitude": 105.81993722187018,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1886,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81972331570842,
          21.005494787287635,
          105.8198245690911,
          21.005705746243514
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8198245690911,
            21.005494787287635
          ],
          [
            105.81972331570842,
            21.005705746243514
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005494787287635,
        "to_latitude": 21.005705746243514,
        "to_longitude": 105.81972331570842,
        "from_longitude": 105.8198245690911,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1887,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81954226659599,
          21.005705746243514,
          105.81972331570842,
          21.005921086828156
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81972331570842,
            21.005705746243514
          ],
          [
            105.81954226659599,
            21.005921086828156
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005705746243514,
        "to_latitude": 21.005921086828156,
        "to_longitude": 105.81954226659599,
        "from_longitude": 105.81972331570842,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1888,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8203204774489,
          21.004438110045495,
          105.82035564647556,
          21.00473264580425
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8203204774489,
            21.00473264580425
          ],
          [
            105.82035564647556,
            21.004438110045495
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "19",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00473264580425,
        "to_latitude": 21.004438110045495,
        "to_longitude": 105.82035564647556,
        "from_longitude": 105.8203204774489,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1889,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82035564647556,
          21.004102270958096,
          105.82046336880568,
          21.004438110045495
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82035564647556,
            21.004438110045495
          ],
          [
            105.82046336880568,
            21.004102270958096
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "18",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.004438110045495,
        "to_latitude": 21.004102270958096,
        "to_longitude": 105.82046336880568,
        "from_longitude": 105.82035564647556,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1890,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82046336880568,
          21.003856558771485,
          105.82052127288895,
          21.004102270958096
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82046336880568,
            21.004102270958096
          ],
          [
            105.82052127288895,
            21.003856558771485
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "17",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.004102270958096,
        "to_latitude": 21.003856558771485,
        "to_longitude": 105.82052127288895,
        "from_longitude": 105.82046336880568,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1891,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82052127288895,
          21.0037128921013,
          105.82069058733576,
          21.003856558771485
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82052127288895,
            21.003856558771485
          ],
          [
            105.82055781792678,
            21.003730420069132
          ],
          [
            105.82059805106984,
            21.0037128921013
          ],
          [
            105.82069058733576,
            21.003789263946445
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 438 Tây Sơn",
        "diemDau": "16",
        "diemCuoi": "16-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.003856558771485,
        "to_latitude": 21.003789263946445,
        "to_longitude": 105.82069058733576,
        "from_longitude": 105.82052127288895,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1892,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82064767199394,
          21.003789263946445,
          105.82069997500896,
          21.003909455622527
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82069058733576,
            21.003789263946445
          ],
          [
            105.82069997500896,
            21.003829327848667
          ],
          [
            105.8206758351303,
            21.003870643737667
          ],
          [
            105.82064767199394,
            21.003909455622527
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 438 Tây Sơn",
        "diemDau": "16-6",
        "diemCuoi": "16-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.003789263946445,
        "to_latitude": 21.003909455622527,
        "to_longitude": 105.82064767199394,
        "from_longitude": 105.82069058733576,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1893,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82056586461289,
          21.003909455622527,
          105.82064767199394,
          21.004103514895412
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82064767199394,
            21.003909455622527
          ],
          [
            105.82064096641169,
            21.00396329144566
          ],
          [
            105.82063694310007,
            21.00401712725021
          ],
          [
            105.82059134554153,
            21.004084734976487
          ],
          [
            105.82056586461289,
            21.004103514895412
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 438 Tây Sơn",
        "diemDau": "16-7",
        "diemCuoi": "16-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.003909455622527,
        "to_latitude": 21.004103514895412,
        "to_longitude": 105.82056586461289,
        "from_longitude": 105.82064767199394,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1894,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82038448023123,
          21.003849046791284,
          105.82052127288895,
          21.003856558771485
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82052127288895,
            21.003856558771485
          ],
          [
            105.82038448023123,
            21.003849046791284
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 17 Thịnh Quang",
        "diemDau": "16",
        "diemCuoi": "16-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.003856558771485,
        "to_latitude": 21.003849046791284,
        "to_longitude": 105.82038448023123,
        "from_longitude": 105.82052127288895,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1895,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8202651219328,
          21.003849046791284,
          105.82038448023123,
          21.004035594172706
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82038448023123,
            21.003849046791284
          ],
          [
            105.8202651219328,
            21.004035594172706
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 17 Thịnh Quang",
        "diemDau": "16-1",
        "diemCuoi": "16-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.003849046791284,
        "to_latitude": 21.004035594172706,
        "to_longitude": 105.8202651219328,
        "from_longitude": 105.82038448023123,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1896,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8201551513615,
          21.003756399012374,
          105.82038448023123,
          21.003849046791284
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82038448023123,
            21.003849046791284
          ],
          [
            105.8201551513615,
            21.003756399012374
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 17 Thịnh Quang",
        "diemDau": "16-1",
        "diemCuoi": "16-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.003849046791284,
        "to_latitude": 21.003756399012374,
        "to_longitude": 105.8201551513615,
        "from_longitude": 105.82038448023123,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1897,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82013400432356,
          21.003756399012374,
          105.8201551513615,
          21.003887483901757
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8201551513615,
            21.003756399012374
          ],
          [
            105.82013400432356,
            21.003887483901757
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 17 Thịnh Quang",
        "diemDau": "16-3",
        "diemCuoi": "16-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.003756399012374,
        "to_latitude": 21.003887483901757,
        "to_longitude": 105.82013400432356,
        "from_longitude": 105.8201551513615,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1898,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8199551020883,
          21.003887483901757,
          105.82013400432356,
          21.003902903247315
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82013400432356,
            21.003887483901757
          ],
          [
            105.8199551020883,
            21.003902903247315
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 17 Thịnh Quang",
        "diemDau": "16-4",
        "diemCuoi": "16-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.003887483901757,
        "to_latitude": 21.003902903247315,
        "to_longitude": 105.8199551020883,
        "from_longitude": 105.82013400432356,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1899,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81982523964753,
          21.003902903247315,
          105.8199551020883,
          21.003929174554546
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8199551020883,
            21.003902903247315
          ],
          [
            105.81982523964753,
            21.003929174554546
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 17 Thịnh Quang",
        "diemDau": "16-5",
        "diemCuoi": "16-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.003902903247315,
        "to_latitude": 21.003929174554546,
        "to_longitude": 105.81982523964753,
        "from_longitude": 105.8199551020883,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1900,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81968040036658,
          21.003891614670493,
          105.81982523964753,
          21.003929174554546
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81982523964753,
            21.003929174554546
          ],
          [
            105.81968040036658,
            21.003891614670493
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 17 Thịnh Quang",
        "diemDau": "16-6",
        "diemCuoi": "16-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.003929174554546,
        "to_latitude": 21.003891614670493,
        "to_longitude": 105.81968040036658,
        "from_longitude": 105.81982523964753,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1901,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82001232372288,
          21.004424338136108,
          105.82035564647556,
          21.004438110045495
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82035564647556,
            21.004438110045495
          ],
          [
            105.82001232372288,
            21.004424338136108
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 41 Thịnh Quang",
        "diemDau": "18",
        "diemCuoi": "18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.004438110045495,
        "to_latitude": 21.004424338136108,
        "to_longitude": 105.82001232372288,
        "from_longitude": 105.82035564647556,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1902,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81974276171006,
          21.00442058216075,
          105.82001232372288,
          21.004424338136108
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82001232372288,
            21.004424338136108
          ],
          [
            105.81974276171006,
            21.00442058216075
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 41 Thịnh Quang",
        "diemDau": "18-1",
        "diemCuoi": "18-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.004424338136108,
        "to_latitude": 21.00442058216075,
        "to_longitude": 105.81974276171006,
        "from_longitude": 105.82001232372288,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1903,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81957914696594,
          21.004336698683897,
          105.81974276171006,
          21.00442058216075
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81974276171006,
            21.00442058216075
          ],
          [
            105.81957914696594,
            21.004336698683897
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 41 Thịnh Quang",
        "diemDau": "18-2",
        "diemCuoi": "18-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00442058216075,
        "to_latitude": 21.004336698683897,
        "to_longitude": 105.81957914696594,
        "from_longitude": 105.81974276171006,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1904,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81943296657214,
          21.004336698683897,
          105.81957914696594,
          21.00453451337373
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81957914696594,
            21.004336698683897
          ],
          [
            105.81943296657214,
            21.00453451337373
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 41 Thịnh Quang",
        "diemDau": "18-3",
        "diemCuoi": "18-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.004336698683897,
        "to_latitude": 21.00453451337373,
        "to_longitude": 105.81943296657214,
        "from_longitude": 105.81957914696594,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1905,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8193766401826,
          21.004251563167248,
          105.81957914696594,
          21.004336698683897
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81957914696594,
            21.004336698683897
          ],
          [
            105.8193766401826,
            21.004251563167248
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 41 Thịnh Quang",
        "diemDau": "18-3",
        "diemCuoi": "18-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.004336698683897,
        "to_latitude": 21.004251563167248,
        "to_longitude": 105.8193766401826,
        "from_longitude": 105.81957914696594,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1906,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81927739845122,
          21.004251563167248,
          105.8193766401826,
          21.00443435406967
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8193766401826,
            21.004251563167248
          ],
          [
            105.81927739845122,
            21.00443435406967
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 41 Thịnh Quang",
        "diemDau": "18-4",
        "diemCuoi": "18-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.004251563167248,
        "to_latitude": 21.00443435406967,
        "to_longitude": 105.81927739845122,
        "from_longitude": 105.8193766401826,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1907,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81997527035419,
          21.004676849030364,
          105.8203204774489,
          21.00478772832164
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8203204774489,
            21.00473264580425
          ],
          [
            105.82027752706502,
            21.00478772832164
          ],
          [
            105.8201581687666,
            21.004751420642986
          ],
          [
            105.81997527035419,
            21.004676849030364
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 65 Thịnh Quang",
        "diemDau": "19",
        "diemCuoi": "19-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00473264580425,
        "to_latitude": 21.004676849030364,
        "to_longitude": 105.81997527035419,
        "from_longitude": 105.8203204774489,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1908,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82013944957436,
          21.004628412659905,
          105.82017459736313,
          21.00474378842782
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82013944957436,
            21.00474378842782
          ],
          [
            105.82017459736313,
            21.004628412659905
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 65 Thịnh Quang",
        "diemDau": "19",
        "diemCuoi": "19-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00474378842782,
        "to_latitude": 21.004628412659905,
        "to_longitude": 105.82017459736313,
        "from_longitude": 105.82013944957436,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1909,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82009547219882,
          21.005046576764325,
          105.82015045748449,
          21.00517177537009
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82009547219882,
            21.00517177537009
          ],
          [
            105.82015045748449,
            21.005046576764325
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 89 Thịnh Quang",
        "diemDau": "20",
        "diemCuoi": "20-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00517177537009,
        "to_latitude": 21.005046576764325,
        "to_longitude": 105.82015045748449,
        "from_longitude": 105.82009547219882,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1910,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81999232825785,
          21.004986605575652,
          105.82015045748449,
          21.005046576764325
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82015045748449,
            21.005046576764325
          ],
          [
            105.81999232825785,
            21.004986605575652
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 89 Thịnh Quang",
        "diemDau": "20-1",
        "diemCuoi": "20-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005046576764325,
        "to_latitude": 21.004986605575652,
        "to_longitude": 105.81999232825785,
        "from_longitude": 105.82015045748449,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1911,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81973355403773,
          21.004958566294317,
          105.81999232825785,
          21.004986605575652
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81999232825785,
            21.004986605575652
          ],
          [
            105.81973355403773,
            21.004958566294317
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 89 Thịnh Quang",
        "diemDau": "20-2",
        "diemCuoi": "20-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.004986605575652,
        "to_latitude": 21.004958566294317,
        "to_longitude": 105.81973355403773,
        "from_longitude": 105.81999232825785,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1912,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81922128675629,
          21.005812625031787,
          105.81954226659599,
          21.005921086828156
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81954226659599,
            21.005921086828156
          ],
          [
            105.81922128675629,
            21.005812625031787
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 122 Đường Láng",
        "diemDau": "24",
        "diemCuoi": "24-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005921086828156,
        "to_latitude": 21.005812625031787,
        "to_longitude": 105.81922128675629,
        "from_longitude": 105.81954226659599,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1913,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8190520928931,
          21.005765215331873,
          105.81922128675629,
          21.005812625031787
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81922128675629,
            21.005812625031787
          ],
          [
            105.8190520928931,
            21.005765215331873
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 122 Đường Láng",
        "diemDau": "241",
        "diemCuoi": "24-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005812625031787,
        "to_latitude": 21.005765215331873,
        "to_longitude": 105.8190520928931,
        "from_longitude": 105.81922128675629,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1914,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81890008109144,
          21.005765215331873,
          105.8190520928931,
          21.006041150724347
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8190520928931,
            21.005765215331873
          ],
          [
            105.81890008109144,
            21.006041150724347
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 122 Đường Láng",
        "diemDau": "24-3",
        "diemCuoi": "24-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005765215331873,
        "to_latitude": 21.006041150724347,
        "to_longitude": 105.81890008109144,
        "from_longitude": 105.8190520928931,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1915,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8190520928931,
          21.005567402274348,
          105.81905879842144,
          21.005765215331873
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8190520928931,
            21.005765215331873
          ],
          [
            105.81905879842144,
            21.005567402274348
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 122 Đường Láng",
        "diemDau": "24-3",
        "diemCuoi": "24-3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005765215331873,
        "to_latitude": 21.005567402274348,
        "to_longitude": 105.81905879842144,
        "from_longitude": 105.8190520928931,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1916,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81873961554743,
          21.005472251596622,
          105.81905879842144,
          21.005567402274348
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81905879842144,
            21.005567402274348
          ],
          [
            105.81873961554743,
            21.005472251596622
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 122 Đường Láng",
        "diemDau": "24-3-1",
        "diemCuoi": "244",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005567402274348,
        "to_latitude": 21.005472251596622,
        "to_longitude": 105.81873961554743,
        "from_longitude": 105.81905879842144,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1917,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81861623392841,
          21.005472251596622,
          105.81873961554743,
          21.005740175719733
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81873961554743,
            21.005472251596622
          ],
          [
            105.81861623392841,
            21.005740175719733
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 122 Đường Láng",
        "diemDau": "24-4",
        "diemCuoi": "24-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005472251596622,
        "to_latitude": 21.005740175719733,
        "to_longitude": 105.81861623392841,
        "from_longitude": 105.81873961554743,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1918,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81905879842144,
          21.005249398454545,
          105.81917681561599,
          21.005567402274348
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81905879842144,
            21.005567402274348
          ],
          [
            105.81917681561599,
            21.005249398454545
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 122 Đường Láng",
        "diemDau": "243-1",
        "diemCuoi": "24-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005567402274348,
        "to_latitude": 21.005249398454545,
        "to_longitude": 105.81917681561599,
        "from_longitude": 105.81905879842144,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1919,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81897028552103,
          21.00517928728454,
          105.81917681561599,
          21.005249398454545
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81917681561599,
            21.005249398454545
          ],
          [
            105.81897028552103,
            21.00517928728454
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 122 Đường Láng",
        "diemDau": "24-6",
        "diemCuoi": "24-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.005249398454545,
        "to_latitude": 21.00517928728454,
        "to_longitude": 105.81897028552103,
        "from_longitude": 105.81917681561599,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1920,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81869670019663,
          21.00510166416528,
          105.81897028552103,
          21.00517928728454
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81897028552103,
            21.00517928728454
          ],
          [
            105.81869670019663,
            21.00510166416528
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 122 Đường Láng",
        "diemDau": "24-7",
        "diemCuoi": "24-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00517928728454,
        "to_latitude": 21.00510166416528,
        "to_longitude": 105.81869670019663,
        "from_longitude": 105.81897028552103,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1921,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81831750281881,
          21.004896025310092,
          105.81869670019663,
          21.00510166416528
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81869670019663,
            21.00510166416528
          ],
          [
            105.81831750281881,
            21.004896025310092
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 53,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 122 Đường Láng",
        "diemDau": "24-8",
        "diemCuoi": "24-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.00510166416528,
        "to_latitude": 21.004896025310092,
        "to_longitude": 105.81831750281881,
        "from_longitude": 105.81869670019663,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1922,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81831750281881,
          21.004645248261877,
          105.81844009368841,
          21.004896025310092
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81831750281881,
            21.004896025310092
          ],
          [
            105.81844009368841,
            21.004645248261877
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHO1",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 122 Đường Láng",
        "diemDau": "24-9",
        "diemCuoi": "24-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 1",
        "from_latitude": 21.004896025310092,
        "to_latitude": 21.004645248261877,
        "to_longitude": 105.81844009368841,
        "from_longitude": 105.81831750281881,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1923,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83316922947915,
          21.007247552940623,
          105.83336167796101,
          21.007389025267035
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83316922947915,
            21.007389025267035
          ],
          [
            105.83336167796101,
            21.007247552940623
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Phạm Ngọc Thạch",
        "diemDau": "1-1",
        "diemCuoi": "1-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.007389025267035,
        "to_latitude": 21.007247552940623,
        "to_longitude": 105.83336167796101,
        "from_longitude": 105.83316922947915,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1924,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8331444190261,
          21.00710232456887,
          105.83336167796101,
          21.007247552940623
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83336167796101,
            21.007247552940623
          ],
          [
            105.8331444190261,
            21.00710232456887
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Phạm Ngọc Thạch",
        "diemDau": "1-2",
        "diemCuoi": "1-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.007247552940623,
        "to_latitude": 21.00710232456887,
        "to_longitude": 105.8331444190261,
        "from_longitude": 105.83336167796101,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1925,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83293788893116,
          21.006934560583925,
          105.8331444190261,
          21.00710232456887
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8331444190261,
            21.00710232456887
          ],
          [
            105.83293788893116,
            21.006934560583925
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Phạm Ngọc Thạch",
        "diemDau": "1-3",
        "diemCuoi": "1-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.00710232456887,
        "to_latitude": 21.006934560583925,
        "to_longitude": 105.83293788893116,
        "from_longitude": 105.8331444190261,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1926,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83336167796101,
          21.006972119701196,
          105.833495788411,
          21.007247552940623
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83336167796101,
            21.007247552940623
          ],
          [
            105.833495788411,
            21.006972119701196
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 5,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Phạm Ngọc Thạch",
        "diemDau": "1-2",
        "diemCuoi": "1-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.007247552940623,
        "to_latitude": 21.006972119701196,
        "to_longitude": 105.833495788411,
        "from_longitude": 105.83336167796101,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1927,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83310150368428,
          21.006651614927595,
          105.833495788411,
          21.006972119701196
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.833495788411,
            21.006972119701196
          ],
          [
            105.83310150368428,
            21.006651614927595
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 58,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Phạm Ngọc Thạch",
        "diemDau": "1-5",
        "diemCuoi": "1-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.006972119701196,
        "to_latitude": 21.006651614927595,
        "to_longitude": 105.83310150368428,
        "from_longitude": 105.833495788411,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1928,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8351587768087,
          21.018492325294442,
          105.83521138996332,
          21.018670147695058
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8351587768087,
            21.018492325294442
          ],
          [
            105.83521138996332,
            21.018670147695058
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "diemDau": "58",
        "diemCuoi": "55",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018492325294442,
        "to_latitude": 21.018670147695058,
        "to_longitude": 105.83521138996332,
        "from_longitude": 105.8351587768087,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1929,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83511443830174,
          21.01843542696622,
          105.83521138996332,
          21.018670147695058
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83521138996332,
            21.018670147695058
          ],
          [
            105.83511443830174,
            21.01843542696622
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "diemDau": "55",
        "diemCuoi": "56",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018670147695058,
        "to_latitude": 21.01843542696622,
        "to_longitude": 105.83511443830174,
        "from_longitude": 105.83521138996332,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1930,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83500368836478,
          21.01819345458679,
          105.83511443830174,
          21.01843542696622
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83511443830174,
            21.01843542696622
          ],
          [
            105.83500368836478,
            21.01819345458679
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "diemDau": "56",
        "diemCuoi": "57",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01843542696622,
        "to_latitude": 21.01819345458679,
        "to_longitude": 105.83500368836478,
        "from_longitude": 105.83511443830174,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1931,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83497686627838,
          21.018670147695058,
          105.83521138996332,
          21.018764309061535
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83521138996332,
            21.018670147695058
          ],
          [
            105.83497686627838,
            21.018764309061535
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "diemDau": "55",
        "diemCuoi": "54",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018670147695058,
        "to_latitude": 21.018764309061535,
        "to_longitude": 105.83497686627838,
        "from_longitude": 105.83521138996332,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1932,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83497686627838,
          21.018654144333354,
          105.83498088959,
          21.018764309061535
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83497686627838,
            21.018764309061535
          ],
          [
            105.83498088959,
            21.018654144333354
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "diemDau": "54",
        "diemCuoi": "54-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018764309061535,
        "to_latitude": 21.018654144333354,
        "to_longitude": 105.83498088959,
        "from_longitude": 105.83497686627838,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1933,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83479531032623,
          21.018654144333354,
          105.83498088959,
          21.018718900593903
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83498088959,
            21.018654144333354
          ],
          [
            105.83479531032623,
            21.018718900593903
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "diemDau": "54-1",
        "diemCuoi": "54-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018654144333354,
        "to_latitude": 21.018718900593903,
        "to_longitude": 105.83479531032623,
        "from_longitude": 105.83498088959,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1934,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83497686627838,
          21.018764309061535,
          105.83505226532284,
          21.019027590457696
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83497686627838,
            21.018764309061535
          ],
          [
            105.83505226532284,
            21.019027590457696
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "diemDau": "54",
        "diemCuoi": "53",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018764309061535,
        "to_latitude": 21.019027590457696,
        "to_longitude": 105.83505226532284,
        "from_longitude": 105.83497686627838,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1935,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83505226532284,
          21.01894833405128,
          105.83515925649465,
          21.019027590457696
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83505226532284,
            21.019027590457696
          ],
          [
            105.83515925649465,
            21.01894833405128
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "diemDau": "53",
        "diemCuoi": "53-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019027590457696,
        "to_latitude": 21.01894833405128,
        "to_longitude": 105.83515925649465,
        "from_longitude": 105.83505226532284,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1936,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83515925649465,
          21.018930807871513,
          105.83527727368921,
          21.01894833405128
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83515925649465,
            21.01894833405128
          ],
          [
            105.83527727368921,
            21.018930807871513
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "diemDau": "53-1",
        "diemCuoi": "53-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01894833405128,
        "to_latitude": 21.018930807871513,
        "to_longitude": 105.83527727368921,
        "from_longitude": 105.83515925649465,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1937,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83527727368921,
          21.01886070313171,
          105.83544088844232,
          21.018930807871513
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83527727368921,
            21.018930807871513
          ],
          [
            105.83544088844232,
            21.01886070313171
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "diemDau": "53-2",
        "diemCuoi": "53-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018930807871513,
        "to_latitude": 21.01886070313171,
        "to_longitude": 105.83544088844232,
        "from_longitude": 105.83527727368921,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1938,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83544088844232,
          21.018811880169192,
          105.83549319151126,
          21.01886070313171
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83544088844232,
            21.01886070313171
          ],
          [
            105.83549319151126,
            21.018811880169192
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "diemDau": "53-3",
        "diemCuoi": "53-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01886070313171,
        "to_latitude": 21.018811880169192,
        "to_longitude": 105.83549319151126,
        "from_longitude": 105.83544088844232,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1939,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83488256826853,
          21.019081367437607,
          105.8350382349299,
          21.01928671858532
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8350382349299,
            21.01928671858532
          ],
          [
            105.83488256826853,
            21.019081367437607
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 185KT",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01928671858532,
        "to_latitude": 21.019081367437607,
        "to_longitude": 105.83488256826853,
        "from_longitude": 105.8350382349299,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1940,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83366309123343,
          21.010861628116114,
          105.83376400929298,
          21.010980875147595
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83366309123343,
            21.010861628116114
          ],
          [
            105.83371572953567,
            21.010905758921155
          ],
          [
            105.83369427186476,
            21.01092829379315
          ],
          [
            105.83376400929298,
            21.010980875147595
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.010861628116114,
        "to_latitude": 21.010980875147595,
        "to_longitude": 105.83376400929298,
        "from_longitude": 105.83366309123343,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1941,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83577298305715,
          21.018836571595628,
          105.83579041826614,
          21.019239082012003
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83577298305715,
            21.019239082012003
          ],
          [
            105.83579041826614,
            21.019084441816766
          ],
          [
            105.83579041826614,
            21.018836571595628
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 151",
        "diemDau": "08",
        "diemCuoi": "8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.019239082012003,
        "to_latitude": 21.018836571595628,
        "to_longitude": 105.83579041826614,
        "from_longitude": 105.83577298305715,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1942,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83404463539702,
          21.011341119527273,
          105.83420120936155,
          21.01143908330699
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83404463539702,
            21.011341119527273
          ],
          [
            105.83420120936155,
            21.01143908330699
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "diemDau": "17",
        "diemCuoi": "17-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.011341119527273,
        "to_latitude": 21.01143908330699,
        "to_longitude": 105.83420120936155,
        "from_longitude": 105.83404463539702,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1943,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83690638205233,
          21.01824882957433,
          105.83698567766834,
          21.01851546637534
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83698567766834,
            21.01851546637534
          ],
          [
            105.83690638205233,
            21.01824882957433
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Cống Thắng",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01851546637534,
        "to_latitude": 21.01824882957433,
        "to_longitude": 105.83690638205233,
        "from_longitude": 105.83698567766834,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1944,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83679088222107,
          21.01801784581548,
          105.83690638205233,
          21.01824882957433
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83690638205233,
            21.01824882957433
          ],
          [
            105.83679088222107,
            21.01801784581548
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Cống Thắng",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01824882957433,
        "to_latitude": 21.01801784581548,
        "to_longitude": 105.83679088222107,
        "from_longitude": 105.83690638205233,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1945,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83668627607418,
          21.01801784581548,
          105.83679088222107,
          21.0180954622139
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83679088222107,
            21.01801784581548
          ],
          [
            105.83668627607418,
            21.0180954622139
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Cống Thắng",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01801784581548,
        "to_latitude": 21.0180954622139,
        "to_longitude": 105.83668627607418,
        "from_longitude": 105.83679088222107,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1946,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83655484783195,
          21.0180954622139,
          105.83668627607418,
          21.018128011013744
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83668627607418,
            21.0180954622139
          ],
          [
            105.83655484783195,
            21.018128011013744
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Cống Thắng",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.0180954622139,
        "to_latitude": 21.018128011013744,
        "to_longitude": 105.83655484783195,
        "from_longitude": 105.83668627607418,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1947,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83720529409558,
          21.018099639175038,
          105.83731022495785,
          21.018394034592998
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83731022495785,
            21.018394034592998
          ],
          [
            105.83720529409558,
            21.018099639175038
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018394034592998,
        "to_latitude": 21.018099639175038,
        "to_longitude": 105.83720529409558,
        "from_longitude": 105.83731022495785,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1948,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8371087239822,
          21.01794148125444,
          105.83720529409558,
          21.018099639175038
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83720529409558,
            21.018099639175038
          ],
          [
            105.8371087239822,
            21.01794148125444
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018099639175038,
        "to_latitude": 21.01794148125444,
        "to_longitude": 105.8371087239822,
        "from_longitude": 105.83720529409558,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1949,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83706806924445,
          21.017772044465627,
          105.8371087239822,
          21.01794148125444
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8371087239822,
            21.01794148125444
          ],
          [
            105.83706806924445,
            21.017772044465627
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01794148125444,
        "to_latitude": 21.017772044465627,
        "to_longitude": 105.83706806924445,
        "from_longitude": 105.8371087239822,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1950,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83706806924445,
          21.01763424784434,
          105.83713406061483,
          21.017772044465627
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83706806924445,
            21.017772044465627
          ],
          [
            105.83713406061483,
            21.01763424784434
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.017772044465627,
        "to_latitude": 21.01763424784434,
        "to_longitude": 105.83713406061483,
        "from_longitude": 105.83706806924445,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1951,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83713406061483,
          21.01763424784434,
          105.83728785214143,
          21.017763042637817
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83713406061483,
            21.01763424784434
          ],
          [
            105.83728785214143,
            21.017763042637817
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01763424784434,
        "to_latitude": 21.017763042637817,
        "to_longitude": 105.83728785214143,
        "from_longitude": 105.83713406061483,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1952,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83728785214143,
          21.017763042637817,
          105.83736579266179,
          21.017875771200185
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83728785214143,
            21.017763042637817
          ],
          [
            105.83736579266179,
            21.017875771200185
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.017763042637817,
        "to_latitude": 21.017875771200185,
        "to_longitude": 105.83736579266179,
        "from_longitude": 105.83728785214143,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1953,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83766176196836,
          21.018145224320993,
          105.83797474223584,
          21.018260240438465
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83766176196836,
            21.018260240438465
          ],
          [
            105.83797474223584,
            21.018145224320993
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Khâm Đức",
        "diemDau": "16",
        "diemCuoi": "30-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.018260240438465,
        "to_latitude": 21.018145224320993,
        "to_longitude": 105.83797474223584,
        "from_longitude": 105.83766176196836,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1954,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83427731713871,
          21.01919053765466,
          105.83430682143286,
          21.01933325052331
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83430682143286,
            21.01919053765466
          ],
          [
            105.83427731713871,
            21.01933325052331
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "00",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.01919053765466,
        "to_latitude": 21.01933325052331,
        "to_longitude": 105.83427731713871,
        "from_longitude": 105.83430682143286,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1955,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8338749857798,
          21.01933325052331,
          105.83427731713871,
          21.019353280389343
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83427731713871,
            21.01933325052331
          ],
          [
            105.8338749857798,
            21.019353280389343
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.01933325052331,
        "to_latitude": 21.019353280389343,
        "to_longitude": 105.8338749857798,
        "from_longitude": 105.83427731713871,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1956,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8334699722221,
          21.019353280389343,
          105.8338749857798,
          21.019380821449445
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8338749857798,
            21.019353280389343
          ],
          [
            105.8334699722221,
            21.019380821449445
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019353280389343,
        "to_latitude": 21.019380821449445,
        "to_longitude": 105.8334699722221,
        "from_longitude": 105.8338749857798,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1957,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83306764087216,
          21.019380821449445,
          105.8334699722221,
          21.019393340111606
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8334699722221,
            21.019380821449445
          ],
          [
            105.83306764087216,
            21.019393340111606
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "03",
        "diemCuoi": "03+1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019380821449445,
        "to_latitude": 21.019393340111606,
        "to_longitude": 105.83306764087216,
        "from_longitude": 105.8334699722221,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1958,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8327994199722,
          21.019393340111606,
          105.83306764087216,
          21.019420881165193
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83306764087216,
            21.019393340111606
          ],
          [
            105.8327994199722,
            21.019420881165193
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "3+1",
        "diemCuoi": "4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019393340111606,
        "to_latitude": 21.019420881165193,
        "to_longitude": 105.8327994199722,
        "from_longitude": 105.83306764087216,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1959,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8325204702323,
          21.019420881165193,
          105.8327994199722,
          21.01944091101857
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8327994199722,
            21.019420881165193
          ],
          [
            105.8325204702323,
            21.01944091101857
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "4",
        "diemCuoi": "5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019420881165193,
        "to_latitude": 21.01944091101857,
        "to_longitude": 105.8325204702323,
        "from_longitude": 105.8327994199722,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1960,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83219324073504,
          21.01944091101857,
          105.8325204702323,
          21.019453429675703
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8325204702323,
            21.01944091101857
          ],
          [
            105.83219324073504,
            21.019453429675703
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "5",
        "diemCuoi": "6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.01944091101857,
        "to_latitude": 21.019453429675703,
        "to_longitude": 105.83219324073504,
        "from_longitude": 105.8325204702323,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1961,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83185260019012,
          21.019453429675703,
          105.83219324073504,
          21.019488481910408
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83219324073504,
            21.019453429675703
          ],
          [
            105.83185260019012,
            21.019488481910408
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "6",
        "diemCuoi": "7",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019453429675703,
        "to_latitude": 21.019488481910408,
        "to_longitude": 105.83185260019012,
        "from_longitude": 105.83219324073504,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1962,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83159242591337,
          21.019478466987625,
          105.83185260019012,
          21.019488481910408
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83185260019012,
            21.019488481910408
          ],
          [
            105.83159242591337,
            21.019478466987625
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "7",
        "diemCuoi": "8",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019488481910408,
        "to_latitude": 21.019478466987625,
        "to_longitude": 105.83159242591337,
        "from_longitude": 105.83185260019012,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1963,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83122228106532,
          21.019393340111606,
          105.83159242591337,
          21.019478466987625
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83159242591337,
            21.019478466987625
          ],
          [
            105.83122228106532,
            21.019393340111606
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "8",
        "diemCuoi": "9",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019478466987625,
        "to_latitude": 21.019393340111606,
        "to_longitude": 105.83122228106532,
        "from_longitude": 105.83159242591337,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1964,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83099697550719,
          21.01930070198653,
          105.83122228106532,
          21.019393340111606
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83122228106532,
            21.019393340111606
          ],
          [
            105.83099697550719,
            21.01930070198653
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "9",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019393340111606,
        "to_latitude": 21.01930070198653,
        "to_longitude": 105.83099697550719,
        "from_longitude": 105.83122228106532,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1965,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82752217367961,
          21.01465870709514,
          105.82768847066741,
          21.01490908806526
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82768847066741,
            21.01490908806526
          ],
          [
            105.82752217367961,
            21.01465870709514
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.01490908806526,
        "to_latitude": 21.01465870709514,
        "to_longitude": 105.82752217367961,
        "from_longitude": 105.82768847066741,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1966,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82768847066741,
          21.014859011903678,
          105.82782124001353,
          21.01490908806526
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82768847066741,
            21.01490908806526
          ],
          [
            105.82782124001353,
            21.014859011903678
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.01490908806526,
        "to_latitude": 21.014859011903678,
        "to_longitude": 105.82782124001353,
        "from_longitude": 105.82768847066741,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1967,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82782124001353,
          21.01478890525309,
          105.82800094798613,
          21.01488029427581
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82782124001353,
            21.014859011903678
          ],
          [
            105.82783867435485,
            21.01488029427581
          ],
          [
            105.82800094798613,
            21.01478890525309
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "1-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.014859011903678,
        "to_latitude": 21.01478890525309,
        "to_longitude": 105.82800094798613,
        "from_longitude": 105.82782124001353,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1968,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82790707067022,
          21.014596111785703,
          105.82800094798613,
          21.01478890525309
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82800094798613,
            21.01478890525309
          ],
          [
            105.82790707067022,
            21.014596111785703
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "1-1",
        "diemCuoi": "1-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.01478890525309,
        "to_latitude": 21.014596111785703,
        "to_longitude": 105.82790707067022,
        "from_longitude": 105.82800094798613,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1969,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8273909370279,
          21.01444528678826,
          105.82752217367961,
          21.01465870709514
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82752217367961,
            21.01465870709514
          ],
          [
            105.8273909370279,
            21.01444528678826
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.01465870709514,
        "to_latitude": 21.01444528678826,
        "to_longitude": 105.8273909370279,
        "from_longitude": 105.82752217367961,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1970,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82765494305266,
          21.014588600343693,
          105.82782124001353,
          21.014859011903678
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82782124001353,
            21.014859011903678
          ],
          [
            105.82765494305266,
            21.014588600343693
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.014859011903678,
        "to_latitude": 21.014588600343693,
        "to_longitude": 105.82765494305266,
        "from_longitude": 105.82782124001353,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1971,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82752887922591,
          21.01437327226925,
          105.82765494305266,
          21.014588600343693
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82765494305266,
            21.014588600343693
          ],
          [
            105.82752887922591,
            21.01437327226925
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.014588600343693,
        "to_latitude": 21.01437327226925,
        "to_longitude": 105.82752887922591,
        "from_longitude": 105.82765494305266,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1972,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82722540641427,
          21.014175828314347,
          105.8273909370279,
          21.01444528678826
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8273909370279,
            21.01444528678826
          ],
          [
            105.82722540641427,
            21.014175828314347
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.01444528678826,
        "to_latitude": 21.014175828314347,
        "to_longitude": 105.82722540641427,
        "from_longitude": 105.8273909370279,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1973,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82705987580063,
          21.013906369353606,
          105.82722540641427,
          21.014175828314347
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82722540641427,
            21.014175828314347
          ],
          [
            105.82705987580063,
            21.013906369353606
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.014175828314347,
        "to_latitude": 21.013906369353606,
        "to_longitude": 105.82705987580063,
        "from_longitude": 105.82722540641427,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1974,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82689434518699,
          21.013636909906065,
          105.82705987580063,
          21.013906369353606
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82705987580063,
            21.013906369353606
          ],
          [
            105.82689434518699,
            21.013636909906065
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.013906369353606,
        "to_latitude": 21.013636909906065,
        "to_longitude": 105.82689434518699,
        "from_longitude": 105.82705987580063,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1975,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82736258227403,
          21.014107867470994,
          105.82752887922591,
          21.01437327226925
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82752887922591,
            21.01437327226925
          ],
          [
            105.82736258227403,
            21.014107867470994
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.01437327226925,
        "to_latitude": 21.014107867470994,
        "to_longitude": 105.82736258227403,
        "from_longitude": 105.82752887922591,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1976,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82672881457333,
          21.013367449971742,
          105.82689434518699,
          21.013636909906065
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82689434518699,
            21.013636909906065
          ],
          [
            105.82672881457333,
            21.013367449971742
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.013636909906065,
        "to_latitude": 21.013367449971742,
        "to_longitude": 105.82672881457333,
        "from_longitude": 105.82689434518699,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1977,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82656328395969,
          21.01309798955062,
          105.82672881457333,
          21.013367449971742
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82672881457333,
            21.013367449971742
          ],
          [
            105.82656328395969,
            21.01309798955062
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.013367449971742,
        "to_latitude": 21.01309798955062,
        "to_longitude": 105.82656328395969,
        "from_longitude": 105.82672881457333,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1978,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82639775334604,
          21.012828528642693,
          105.82656328395969,
          21.01309798955062
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82656328395969,
            21.01309798955062
          ],
          [
            105.82639775334604,
            21.012828528642693
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.01309798955062,
        "to_latitude": 21.012828528642693,
        "to_longitude": 105.82639775334604,
        "from_longitude": 105.82656328395969,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1979,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8262322227324,
          21.012559067247988,
          105.82639775334604,
          21.012828528642693
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82639775334604,
            21.012828528642693
          ],
          [
            105.8262322227324,
            21.012559067247988
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.012828528642693,
        "to_latitude": 21.012559067247988,
        "to_longitude": 105.8262322227324,
        "from_longitude": 105.82639775334604,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1980,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82606669211877,
          21.012289605366494,
          105.8262322227324,
          21.012559067247988
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8262322227324,
            21.012559067247988
          ],
          [
            105.82606669211877,
            21.012289605366494
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.012559067247988,
        "to_latitude": 21.012289605366494,
        "to_longitude": 105.82606669211877,
        "from_longitude": 105.8262322227324,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1981,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82590116150512,
          21.012020142998235,
          105.82606669211877,
          21.012289605366494
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82606669211877,
            21.012289605366494
          ],
          [
            105.82590116150512,
            21.012020142998235
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.012289605366494,
        "to_latitude": 21.012020142998235,
        "to_longitude": 105.82590116150512,
        "from_longitude": 105.82606669211877,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1982,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82573563089146,
          21.011750680143187,
          105.82590116150512,
          21.012020142998235
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82590116150512,
            21.012020142998235
          ],
          [
            105.82573563089146,
            21.011750680143187
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.012020142998235,
        "to_latitude": 21.011750680143187,
        "to_longitude": 105.82573563089146,
        "from_longitude": 105.82590116150512,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1983,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82554404453563,
          21.011511382413037,
          105.82573563089146,
          21.011750680143187
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82573563089146,
            21.011750680143187
          ],
          [
            105.82554404453563,
            21.011511382413037
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.011750680143187,
        "to_latitude": 21.011511382413037,
        "to_longitude": 105.82554404453563,
        "from_longitude": 105.82573563089146,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1984,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82535628990388,
          21.01128102668622,
          105.82554404453563,
          21.011511382413037
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82554404453563,
            21.011511382413037
          ],
          [
            105.82535628990388,
            21.01128102668622
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.011511382413037,
        "to_latitude": 21.01128102668622,
        "to_longitude": 105.82535628990388,
        "from_longitude": 105.82554404453563,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1985,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82518462852752,
          21.011000593146743,
          105.82535628990388,
          21.01128102668622
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82535628990388,
            21.01128102668622
          ],
          [
            105.82518462852752,
            21.011000593146743
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.01128102668622,
        "to_latitude": 21.011000593146743,
        "to_longitude": 105.82518462852752,
        "from_longitude": 105.82535628990388,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1986,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82499687389574,
          21.010715151323968,
          105.82518462852752,
          21.011000593146743
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82518462852752,
            21.011000593146743
          ],
          [
            105.82499687389574,
            21.010715151323968
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.011000593146743,
        "to_latitude": 21.010715151323968,
        "to_longitude": 105.82499687389574,
        "from_longitude": 105.82518462852752,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1987,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82488958554114,
          21.01045975554668,
          105.82499687389574,
          21.010715151323968
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82499687389574,
            21.010715151323968
          ],
          [
            105.82488958554114,
            21.01045975554668
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.010715151323968,
        "to_latitude": 21.01045975554668,
        "to_longitude": 105.82488958554114,
        "from_longitude": 105.82499687389574,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1988,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82472865299577,
          21.010164297140857,
          105.82488958554114,
          21.01045975554668
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82488958554114,
            21.01045975554668
          ],
          [
            105.82472865299577,
            21.010164297140857
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.01045975554668,
        "to_latitude": 21.010164297140857,
        "to_longitude": 105.82472865299577,
        "from_longitude": 105.82488958554114,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1989,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82457691659596,
          21.009864426528832,
          105.82472865299577,
          21.010164297140857
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82472865299577,
            21.010164297140857
          ],
          [
            105.82457691659596,
            21.009864426528832
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.010164297140857,
        "to_latitude": 21.009864426528832,
        "to_longitude": 105.82457691659596,
        "from_longitude": 105.82472865299577,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1990,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8242351265377,
          21.009758666850278,
          105.82457691659596,
          21.009864426528832
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82457691659596,
            21.009864426528832
          ],
          [
            105.8242351265377,
            21.009758666850278
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.009864426528832,
        "to_latitude": 21.009758666850278,
        "to_longitude": 105.8242351265377,
        "from_longitude": 105.82457691659596,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1991,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8239266725216,
          21.00967103052956,
          105.8242351265377,
          21.009758666850278
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8242351265377,
            21.009758666850278
          ],
          [
            105.8239266725216,
            21.00967103052956
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.009758666850278,
        "to_latitude": 21.00967103052956,
        "to_longitude": 105.8239266725216,
        "from_longitude": 105.8242351265377,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1992,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82411710935214,
          21.009687305847926,
          105.8242351265377,
          21.009758666850278
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8242351265377,
            21.009758666850278
          ],
          [
            105.82411710935214,
            21.009687305847926
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.009758666850278,
        "to_latitude": 21.009687305847926,
        "to_longitude": 105.82411710935214,
        "from_longitude": 105.8242351265377,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1993,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8257237525352,
          21.012020142998235,
          105.82590116150512,
          21.01208476631909
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82590116150512,
            21.012020142998235
          ],
          [
            105.82582701759614,
            21.01202342185857
          ],
          [
            105.8257237525352,
            21.01208476631909
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16",
        "diemCuoi": "16-11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.012020142998235,
        "to_latitude": 21.01208476631909,
        "to_longitude": 105.8257237525352,
        "from_longitude": 105.82590116150512,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1994,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82764924329608,
          21.01490908806526,
          105.82777966576657,
          21.015163537302385
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82764924329608,
            21.015163537302385
          ],
          [
            105.82777966576657,
            21.015093117814082
          ],
          [
            105.82768847066741,
            21.01490908806526
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 70,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.015163537302385,
        "to_latitude": 21.01490908806526,
        "to_longitude": 105.82768847066741,
        "from_longitude": 105.82764924329608,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1995,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82556281998983,
          21.01208476631909,
          105.8257237525352,
          21.01216488965946
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8257237525352,
            21.01208476631909
          ],
          [
            105.82556281998983,
            21.01216488965946
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-10",
        "diemCuoi": "16-11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.01208476631909,
        "to_latitude": 21.01216488965946,
        "to_longitude": 105.82556281998983,
        "from_longitude": 105.8257237525352,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1996,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82512293771353,
          21.012415274818355,
          105.82534824328961,
          21.012772072945356
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82512293771353,
            21.012415274818355
          ],
          [
            105.82534824328961,
            21.012772072945356
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-4",
        "diemCuoi": "16-8",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.012415274818355,
        "to_latitude": 21.012772072945356,
        "to_longitude": 105.82534824328961,
        "from_longitude": 105.82512293771353,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1997,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82534824328961,
          21.012772072945356,
          105.82542200403846,
          21.0128997689092
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82534824328961,
            21.012772072945356
          ],
          [
            105.82542200403846,
            21.0128997689092
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-4",
        "diemCuoi": "16-4-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.012772072945356,
        "to_latitude": 21.0128997689092,
        "to_longitude": 105.82542200403846,
        "from_longitude": 105.82534824328961,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1998,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82542200403846,
          21.0128997689092,
          105.82548771813711,
          21.01294108228561
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82542200403846,
            21.0128997689092
          ],
          [
            105.82548771813711,
            21.01294108228561
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-4",
        "diemCuoi": "16-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.0128997689092,
        "to_latitude": 21.01294108228561,
        "to_longitude": 105.82548771813711,
        "from_longitude": 105.82542200403846,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 1999,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82548771813711,
          21.012815890199587,
          105.82580958321887,
          21.01294108228561
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82548771813711,
            21.01294108228561
          ],
          [
            105.82580958321887,
            21.012815890199587
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-3",
        "diemCuoi": "16-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.01294108228561,
        "to_latitude": 21.012815890199587,
        "to_longitude": 105.82580958321887,
        "from_longitude": 105.82548771813711,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2000,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82580958321887,
          21.01276581333608,
          105.82593296483789,
          21.012815890199587
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82580958321887,
            21.012815890199587
          ],
          [
            105.82593296483789,
            21.01276581333608
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-2",
        "diemCuoi": "16-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.012815890199587,
        "to_latitude": 21.01276581333608,
        "to_longitude": 105.82593296483789,
        "from_longitude": 105.82580958321887,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2001,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82534824328961,
          21.012715736455796,
          105.82546894268293,
          21.012772072945356
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82534824328961,
            21.012772072945356
          ],
          [
            105.82546894268293,
            21.012715736455796
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-4",
        "diemCuoi": "16-4-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.012772072945356,
        "to_latitude": 21.012715736455796,
        "to_longitude": 105.82546894268293,
        "from_longitude": 105.82534824328961,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2002,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82534824328961,
          21.012593048026798,
          105.82565133290818,
          21.012772072945356
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82534824328961,
            21.012772072945356
          ],
          [
            105.82565133290818,
            21.012593048026798
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-5",
        "diemCuoi": "16-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.012772072945356,
        "to_latitude": 21.012593048026798,
        "to_longitude": 105.82565133290818,
        "from_longitude": 105.82534824328961,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2003,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82565133290818,
          21.012593048026798,
          105.82571704702482,
          21.01268193455113
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82565133290818,
            21.012593048026798
          ],
          [
            105.82571704702482,
            21.01268193455113
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-5",
        "diemCuoi": "16-5-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.012593048026798,
        "to_latitude": 21.01268193455113,
        "to_longitude": 105.82571704702482,
        "from_longitude": 105.82565133290818,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2004,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82565133290818,
          21.012507917221328,
          105.82581360655743,
          21.012593048026798
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82565133290818,
            21.012593048026798
          ],
          [
            105.82581360655743,
            21.012507917221328
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-5",
        "diemCuoi": "16-6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.012593048026798,
        "to_latitude": 21.012507917221328,
        "to_longitude": 105.82581360655743,
        "from_longitude": 105.82565133290818,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2005,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82581360655743,
          21.012425290216264,
          105.82597453909382,
          21.012507917221328
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82581360655743,
            21.012507917221328
          ],
          [
            105.82597453909382,
            21.012425290216264
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-6",
        "diemCuoi": "16-7",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.012507917221328,
        "to_latitude": 21.012425290216264,
        "to_longitude": 105.82597453909382,
        "from_longitude": 105.82581360655743,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2006,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82512293771353,
          21.012202447459956,
          105.82544748501202,
          21.012415274818355
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82512293771353,
            21.012415274818355
          ],
          [
            105.82544748501202,
            21.012202447459956
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-9",
        "diemCuoi": "16-10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.012415274818355,
        "to_latitude": 21.012202447459956,
        "to_longitude": 105.82544748501202,
        "from_longitude": 105.82512293771353,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2007,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82544748501202,
          21.01216488965946,
          105.82556281998983,
          21.012202447459956
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82544748501202,
            21.012202447459956
          ],
          [
            105.82556281998983,
            21.01216488965946
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-9",
        "diemCuoi": "16-10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.012202447459956,
        "to_latitude": 21.01216488965946,
        "to_longitude": 105.82556281998983,
        "from_longitude": 105.82544748501202,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2008,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82496736959263,
          21.012149866536095,
          105.82512293771353,
          21.012415274818355
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82512293771353,
            21.012415274818355
          ],
          [
            105.82496736959263,
            21.012149866536095
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-8",
        "diemCuoi": "16-13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.012415274818355,
        "to_latitude": 21.012149866536095,
        "to_longitude": 105.82496736959263,
        "from_longitude": 105.82512293771353,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2009,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82496736959263,
          21.01198461208439,
          105.8252570481635,
          21.012149866536095
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82496736959263,
            21.012149866536095
          ],
          [
            105.8252570481635,
            21.01198461208439
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-12",
        "diemCuoi": "16-13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.012149866536095,
        "to_latitude": 21.01198461208439,
        "to_longitude": 105.8252570481635,
        "from_longitude": 105.82496736959263,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2010,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82516585305537,
          21.011829372886687,
          105.8252570481635,
          21.01198461208439
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8252570481635,
            21.01198461208439
          ],
          [
            105.82516585305537,
            21.011829372886687
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-12",
        "diemCuoi": "16-12-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.01198461208439,
        "to_latitude": 21.011829372886687,
        "to_longitude": 105.82516585305537,
        "from_longitude": 105.8252570481635,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2011,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82470451310814,
          21.012149866536095,
          105.82496736959263,
          21.01233014391144
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82496736959263,
            21.012149866536095
          ],
          [
            105.82470451310814,
            21.01233014391144
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-13",
        "diemCuoi": "16-14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.012149866536095,
        "to_latitude": 21.01233014391144,
        "to_longitude": 105.82470451310814,
        "from_longitude": 105.82496736959263,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2012,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82453285173182,
          21.01233014391144,
          105.82470451310814,
          21.012570513406306
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82470451310814,
            21.01233014391144
          ],
          [
            105.82453285173182,
            21.012570513406306
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-14",
        "diemCuoi": "16-15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.01233014391144,
        "to_latitude": 21.012570513406306,
        "to_longitude": 105.82453285173182,
        "from_longitude": 105.82470451310814,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2013,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82453285173182,
          21.012570513406306,
          105.82470719532485,
          21.012735767209755
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82453285173182,
            21.012570513406306
          ],
          [
            105.82470719532485,
            21.012719492222303
          ],
          [
            105.82469378426819,
            21.012735767209755
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-15",
        "diemCuoi": "16-16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.012570513406306,
        "to_latitude": 21.012735767209755,
        "to_longitude": 105.82469378426819,
        "from_longitude": 105.82453285173182,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2014,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82462404683994,
          21.012735767209755,
          105.82469378426819,
          21.012810882513822
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82469378426819,
            21.012735767209755
          ],
          [
            105.82462404683994,
            21.012810882513822
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-16",
        "diemCuoi": "16-17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.012735767209755,
        "to_latitude": 21.012810882513822,
        "to_longitude": 105.82462404683994,
        "from_longitude": 105.82469378426819,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2015,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82428608850276,
          21.012570513406306,
          105.82453285173182,
          21.012820897885188
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82453285173182,
            21.012570513406306
          ],
          [
            105.82428608850276,
            21.012820897885188
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-15",
        "diemCuoi": "16-18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.012570513406306,
        "to_latitude": 21.012820897885188,
        "to_longitude": 105.82428608850276,
        "from_longitude": 105.82453285173182,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2016,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82413588479734,
          21.012820897885188,
          105.82428608850276,
          21.01297112837059
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82428608850276,
            21.012820897885188
          ],
          [
            105.82413588479734,
            21.01297112837059
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-18",
        "diemCuoi": "16-19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.012820897885188,
        "to_latitude": 21.01297112837059,
        "to_longitude": 105.82413588479734,
        "from_longitude": 105.82428608850276,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2017,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82403396084925,
          21.01297112837059,
          105.82413588479734,
          21.013076289619928
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82413588479734,
            21.01297112837059
          ],
          [
            105.82403396084925,
            21.013076289619928
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-19",
        "diemCuoi": "16-20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.01297112837059,
        "to_latitude": 21.013076289619928,
        "to_longitude": 105.82403396084925,
        "from_longitude": 105.82413588479734,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2018,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.823840841802,
          21.013076289619928,
          105.82403396084925,
          21.013291619566825
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82403396084925,
            21.013076289619928
          ],
          [
            105.823840841802,
            21.013291619566825
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-20",
        "diemCuoi": "16-21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.013076289619928,
        "to_latitude": 21.013291619566825,
        "to_longitude": 105.823840841802,
        "from_longitude": 105.82403396084925,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2019,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82369063810555,
          21.013291619566825,
          105.823840841802,
          21.01347064364694
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.823840841802,
            21.013291619566825
          ],
          [
            105.82369063810555,
            21.01347064364694
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-21",
        "diemCuoi": "16-22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.013291619566825,
        "to_latitude": 21.01347064364694,
        "to_longitude": 105.82369063810555,
        "from_longitude": 105.823840841802,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2020,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82369063810555,
          21.013359223091246,
          105.82384084181098,
          21.01347064364694
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82369063810555,
            21.01347064364694
          ],
          [
            105.82384084181098,
            21.013359223091246
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-22",
        "diemCuoi": "16-25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.01347064364694,
        "to_latitude": 21.013359223091246,
        "to_longitude": 105.82384084181098,
        "from_longitude": 105.82369063810555,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2021,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82351227120985,
          21.01347064364694,
          105.82369063810555,
          21.013673453883243
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82369063810555,
            21.01347064364694
          ],
          [
            105.82351227120985,
            21.013673453883243
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-22",
        "diemCuoi": "16-23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.01347064364694,
        "to_latitude": 21.013673453883243,
        "to_longitude": 105.82351227120985,
        "from_longitude": 105.82369063810555,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2022,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82333926872069,
          21.013673453883243,
          105.82351227120985,
          21.013880019581073
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82351227120985,
            21.013673453883243
          ],
          [
            105.82333926872069,
            21.013880019581073
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-23",
        "diemCuoi": "16-24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.013673453883243,
        "to_latitude": 21.013880019581073,
        "to_longitude": 105.82333926872069,
        "from_longitude": 105.82351227120985,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2023,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82736258227403,
          21.014107867470994,
          105.82762543874055,
          21.014185485906886
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82736258227403,
            21.014107867470994
          ],
          [
            105.82744975403462,
            21.014185485906886
          ],
          [
            105.82762543874055,
            21.01411287511237
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.014107867470994,
        "to_latitude": 21.01411287511237,
        "to_longitude": 105.82762543874055,
        "from_longitude": 105.82736258227403,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2024,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82762543874055,
          21.013977668717708,
          105.8278453798832,
          21.01411287511237
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82762543874055,
            21.01411287511237
          ],
          [
            105.8278453798832,
            21.013977668717708
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.01411287511237,
        "to_latitude": 21.013977668717708,
        "to_longitude": 105.8278453798832,
        "from_longitude": 105.82762543874055,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2025,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8278453798832,
          21.01385748515251,
          105.8280492277704,
          21.013977668717708
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8278453798832,
            21.013977668717708
          ],
          [
            105.8280492277704,
            21.01385748515251
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "4-2",
        "diemCuoi": "4-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.013977668717708,
        "to_latitude": 21.01385748515251,
        "to_longitude": 105.8280492277704,
        "from_longitude": 105.8278453798832,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2026,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8278453798832,
          21.013977668717708,
          105.82795266824678,
          21.014132905678714
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8278453798832,
            21.013977668717708
          ],
          [
            105.82795266824678,
            21.014132905678714
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "4-2",
        "diemCuoi": "4-2-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.013977668717708,
        "to_latitude": 21.014132905678714,
        "to_longitude": 105.82795266824678,
        "from_longitude": 105.8278453798832,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2027,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82795266824678,
          21.014132905678714,
          105.8280545921859,
          21.014198005001806
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82795266824678,
            21.014132905678714
          ],
          [
            105.8280545921859,
            21.014198005001806
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "4-2-1",
        "diemCuoi": "4-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.014132905678714,
        "to_latitude": 21.014198005001806,
        "to_longitude": 105.8280545921859,
        "from_longitude": 105.82795266824678,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2028,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8280545921859,
          21.014087836908825,
          105.82830001427513,
          21.014198005001806
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8280545921859,
            21.014198005001806
          ],
          [
            105.82830001427513,
            21.014087836908825
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "4-4",
        "diemCuoi": "4-5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.014198005001806,
        "to_latitude": 21.014087836908825,
        "to_longitude": 105.82830001427513,
        "from_longitude": 105.8280545921859,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2029,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82830001427513,
          21.01399769930219,
          105.82847301679128,
          21.014087836908825
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82830001427513,
            21.014087836908825
          ],
          [
            105.82847301679128,
            21.01399769930219
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BVNĐ",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "4-5",
        "diemCuoi": "4-6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BVND",
        "from_latitude": 21.014087836908825,
        "to_latitude": 21.01399769930219,
        "to_longitude": 105.82847301679128,
        "from_longitude": 105.82830001427513,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2030,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83427731713871,
          21.01933168569196,
          105.83440338092954,
          21.019620713579275
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83427731713871,
            21.01933325052331
          ],
          [
            105.8343366609693,
            21.01933168569196
          ],
          [
            105.83440338092954,
            21.019620713579275
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "01",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01933325052331,
        "to_latitude": 21.019620713579275,
        "to_longitude": 105.83440338092954,
        "from_longitude": 105.83427731713871,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2031,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83410967904705,
          21.019620713579275,
          105.83440338092954,
          21.01963323222133
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83440338092954,
            21.019620713579275
          ],
          [
            105.83410967904705,
            21.01963323222133
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "1-1",
        "diemCuoi": "1-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.019620713579275,
        "to_latitude": 21.01963323222133,
        "to_longitude": 105.83410967904705,
        "from_longitude": 105.83440338092954,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2032,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83440338092954,
          21.019620713579275,
          105.83451100455338,
          21.019920061874746
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83440338092954,
            21.019620713579275
          ],
          [
            105.83451100455338,
            21.019920061874746
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "1-1",
        "diemCuoi": "1-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.019620713579275,
        "to_latitude": 21.019920061874746,
        "to_longitude": 105.83451100455338,
        "from_longitude": 105.83440338092954,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2033,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83451100455338,
          21.01985981946135,
          105.83488349634895,
          21.019920061874746
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83451100455338,
            21.019920061874746
          ],
          [
            105.83488349634895,
            21.01985981946135
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "1-4",
        "diemCuoi": "1-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.019920061874746,
        "to_latitude": 21.01985981946135,
        "to_longitude": 105.83488349634895,
        "from_longitude": 105.83451100455338,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2034,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83488349634895,
          21.01985981946135,
          105.83495323377717,
          21.0201264658832
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83488349634895,
            21.01985981946135
          ],
          [
            105.83495323377717,
            21.0201264658832
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "1-5",
        "diemCuoi": "1-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.01985981946135,
        "to_latitude": 21.0201264658832,
        "to_longitude": 105.83495323377717,
        "from_longitude": 105.83488349634895,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2035,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83471854049195,
          21.0201264658832,
          105.83495323377717,
          21.02015651052056
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83495323377717,
            21.0201264658832
          ],
          [
            105.83471854049195,
            21.02015651052056
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "1-6",
        "diemCuoi": "1-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.0201264658832,
        "to_latitude": 21.02015651052056,
        "to_longitude": 105.83471854049195,
        "from_longitude": 105.83495323377717,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2036,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83481164817623,
          21.020026598155816,
          105.83495323377717,
          21.020126618920298
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83495323377717,
            21.0201264658832
          ],
          [
            105.83483823405061,
            21.020126618920298
          ],
          [
            105.83481164817623,
            21.020026598155816
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "1-6",
        "diemCuoi": "1-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.0201264658832,
        "to_latitude": 21.020026598155816,
        "to_longitude": 105.83481164817623,
        "from_longitude": 105.83495323377717,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2037,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83438494072662,
          21.019620713579275,
          105.83448418245797,
          21.01992506932179
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83440338092954,
            21.019620713579275
          ],
          [
            105.83448418245797,
            21.019915054427532
          ],
          [
            105.83438494072662,
            21.01992506932179
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTG",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "1-1",
        "diemCuoi": "1-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ga",
        "from_latitude": 21.019620713579275,
        "to_latitude": 21.01992506932179,
        "to_longitude": 105.83438494072662,
        "from_longitude": 105.83440338092954,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2038,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83427731713871,
          21.01930886937503,
          105.83470670644154,
          21.01933325052331
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83427731713871,
            21.01933325052331
          ],
          [
            105.83470670644154,
            21.01930886937503
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01933325052331,
        "to_latitude": 21.01930886937503,
        "to_longitude": 105.83470670644154,
        "from_longitude": 105.83427731713871,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2039,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83254326897112,
          21.01056241468797,
          105.83280009042083,
          21.010768984701585
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83280009042083,
            21.010768984701585
          ],
          [
            105.83254326897112,
            21.01056241468797
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4D Đặng Văn Ngữ",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.010768984701585,
        "to_latitude": 21.01056241468797,
        "to_longitude": 105.83254326897112,
        "from_longitude": 105.83280009042083,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2040,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8324252517766,
          21.01047227495936,
          105.83254326897112,
          21.01056241468797
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83254326897112,
            21.01056241468797
          ],
          [
            105.8324252517766,
            21.01047227495936
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4D Đặng Văn Ngữ",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.01056241468797,
        "to_latitude": 21.01047227495936,
        "to_longitude": 105.8324252517766,
        "from_longitude": 105.83254326897112,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2041,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8322428615693,
          21.010289491453943,
          105.8324252517766,
          21.01047227495936
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8324252517766,
            21.01047227495936
          ],
          [
            105.8322428615693,
            21.010289491453943
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTCT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4D Đặng Văn Ngữ",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng",
        "from_latitude": 21.01047227495936,
        "to_latitude": 21.010289491453943,
        "to_longitude": 105.8322428615693,
        "from_longitude": 105.8324252517766,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2042,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8409597055489,
          21.007195596255183,
          105.841123320302,
          21.007360856012664
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84100262089073,
            21.007360856012664
          ],
          [
            105.8409597055489,
            21.00730576944747
          ],
          [
            105.84106431169576,
            21.007195596255183
          ],
          [
            105.841123320302,
            21.007225643497364
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "04",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.007360856012664,
        "to_latitude": 21.007225643497364,
        "to_longitude": 105.841123320302,
        "from_longitude": 105.84100262089073,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2043,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.841123320302,
          21.007180572632254,
          105.84117428226706,
          21.007225643497364
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.841123320302,
            21.007225643497364
          ],
          [
            105.84117428226706,
            21.007180572632254
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 8,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.007225643497364,
        "to_latitude": 21.007180572632254,
        "to_longitude": 105.84117428226706,
        "from_longitude": 105.841123320302,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2044,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84093187759191,
          21.007360856012678,
          105.84100262089073,
          21.00736116900554
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84093187759191,
            21.00736116900554
          ],
          [
            105.84100262089073,
            21.007360856012678
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 7,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.00736116900554,
        "to_latitude": 21.007360856012678,
        "to_longitude": 105.84100262089073,
        "from_longitude": 105.84093187759191,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2045,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84093187759191,
          21.00736116900554,
          105.84112231443144,
          21.007463830277345
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84093187759191,
            21.00736116900554
          ],
          [
            105.84095601747957,
            21.007423767350943
          ],
          [
            105.84112231443144,
            21.007463830277345
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.00736116900554,
        "to_latitude": 21.007463830277345,
        "to_longitude": 105.84112231443144,
        "from_longitude": 105.84093187759191,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2046,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84017013018895,
          21.00794208064502,
          105.84048126643076,
          21.0080622689769
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84048126643076,
            21.00794208064502
          ],
          [
            105.84017013018895,
            21.0080622689769
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.00794208064502,
        "to_latitude": 21.0080622689769,
        "to_longitude": 105.84017013018895,
        "from_longitude": 105.84048126643076,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2047,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8398750871936,
          21.0080622689769,
          105.84017013018895,
          21.008212504254573
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84017013018895,
            21.0080622689769
          ],
          [
            105.8398750871936,
            21.008212504254573
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.0080622689769,
        "to_latitude": 21.008212504254573,
        "to_longitude": 105.8398750871936,
        "from_longitude": 105.84017013018895,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2048,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83955858652733,
          21.008212504254573,
          105.8398750871936,
          21.008367747216028
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8398750871936,
            21.008212504254573
          ],
          [
            105.83955858652733,
            21.008367747216028
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.008212504254573,
        "to_latitude": 21.008367747216028,
        "to_longitude": 105.83955858652733,
        "from_longitude": 105.8398750871936,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2049,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83930109446732,
          21.008367747216028,
          105.83955858652733,
          21.008573068303733
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83955858652733,
            21.008367747216028
          ],
          [
            105.83930109446732,
            21.008573068303733
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.008367747216028,
        "to_latitude": 21.008573068303733,
        "to_longitude": 105.83930109446732,
        "from_longitude": 105.83955858652733,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2050,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83904360239832,
          21.008573068303733,
          105.83930109446732,
          21.00878840475096
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83930109446732,
            21.008573068303733
          ],
          [
            105.83904360239832,
            21.00878840475096
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.008573068303733,
        "to_latitude": 21.00878840475096,
        "to_longitude": 105.83904360239832,
        "from_longitude": 105.83930109446732,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2051,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83878611032932,
          21.00878840475096,
          105.83904360239832,
          21.00900374088654
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83904360239832,
            21.00878840475096
          ],
          [
            105.83878611032932,
            21.00900374088654
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.00878840475096,
        "to_latitude": 21.00900374088654,
        "to_longitude": 105.83878611032932,
        "from_longitude": 105.83904360239832,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2052,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83852861826932,
          21.009003740886556,
          105.83878611032932,
          21.009249123546812
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83878611032932,
            21.009003740886556
          ],
          [
            105.83852861826932,
            21.009249123546812
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.009003740886556,
        "to_latitude": 21.009249123546812,
        "to_longitude": 105.83852861826932,
        "from_longitude": 105.83878611032932,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2053,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83830867712666,
          21.009249123546812,
          105.83852861826932,
          21.009489498006232
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83852861826932,
            21.009249123546812
          ],
          [
            105.83830867712666,
            21.009489498006232
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.009249123546812,
        "to_latitude": 21.009489498006232,
        "to_longitude": 105.83830867712666,
        "from_longitude": 105.83852861826932,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2054,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83806191389762,
          21.009489498006232,
          105.83830867712666,
          21.00972987207834
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83830867712666,
            21.009489498006232
          ],
          [
            105.83806191389762,
            21.00972987207834
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.009489498006232,
        "to_latitude": 21.00972987207834,
        "to_longitude": 105.83806191389762,
        "from_longitude": 105.83830867712666,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2055,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83783660833949,
          21.00972987207835,
          105.83806191389762,
          21.009990276886636
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83806191389762,
            21.00972987207835
          ],
          [
            105.83783660833949,
            21.009990276886636
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.00972987207835,
        "to_latitude": 21.009990276886636,
        "to_longitude": 105.83783660833949,
        "from_longitude": 105.83806191389762,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2056,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83762203162132,
          21.01003033912385,
          105.83779905741315,
          21.010305766717405
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83779905741315,
            21.01003033912385
          ],
          [
            105.83762203162132,
            21.010305766717405
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.01003033912385,
        "to_latitude": 21.010305766717405,
        "to_longitude": 105.83762203162132,
        "from_longitude": 105.83779905741315,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2057,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83743964140503,
          21.010305766717405,
          105.83762203162132,
          21.01057618604168
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83762203162132,
            21.010305766717405
          ],
          [
            105.83743964140503,
            21.01057618604168
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.010305766717405,
        "to_latitude": 21.01057618604168,
        "to_longitude": 105.83743964140503,
        "from_longitude": 105.83762203162132,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2058,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83726261561323,
          21.01057618604168,
          105.83743964140503,
          21.01082657386809
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83743964140503,
            21.01057618604168
          ],
          [
            105.83726261561323,
            21.01082657386809
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.01057618604168,
        "to_latitude": 21.01082657386809,
        "to_longitude": 105.83726261561323,
        "from_longitude": 105.83743964140503,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2059,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84107269357025,
          21.008016572376444,
          105.84113170216752,
          21.008021580223147
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84113170216752,
            21.008021580223147
          ],
          [
            105.84107269357025,
            21.008016572376444
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT4",
        "chieuDaiQL": 6,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Kim Liên Tủ 4",
        "from_latitude": 21.008021580223147,
        "to_latitude": 21.008016572376444,
        "to_longitude": 105.84107269357025,
        "from_longitude": 105.84113170216752,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2060,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84095735856548,
          21.008016572376444,
          105.84107269357025,
          21.008121737119325
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84107269357025,
            21.008016572376444
          ],
          [
            105.84095735856548,
            21.008121737119325
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT4",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Kim Liên Tủ 4",
        "from_latitude": 21.008016572376444,
        "to_latitude": 21.008121737119325,
        "to_longitude": 105.84095735856548,
        "from_longitude": 105.84107269357025,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2061,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84095735856548,
          21.008121737119325,
          105.84098954506737,
          21.008154288096506
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84095735856548,
            21.008121737119325
          ],
          [
            105.84098954506737,
            21.008154288096506
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT4",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Kim Liên Tủ 4",
        "from_latitude": 21.008121737119325,
        "to_latitude": 21.008154288096506,
        "to_longitude": 105.84098954506737,
        "from_longitude": 105.84095735856548,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2062,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84098954506737,
          21.008154288096506,
          105.84111560889411,
          21.008261956660352
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84098954506737,
            21.008154288096506
          ],
          [
            105.84111560889411,
            21.008261956660352
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT4",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Kim Liên Tủ 4",
        "from_latitude": 21.008154288096506,
        "to_latitude": 21.008261956660352,
        "to_longitude": 105.84111560889411,
        "from_longitude": 105.84098954506737,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2063,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8407079131287,
          21.008049123376576,
          105.84095735856548,
          21.008121737119325
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84095735856548,
            21.008121737119325
          ],
          [
            105.84085811683411,
            21.008049123376576
          ],
          [
            105.8407079131287,
            21.00809669790179
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Kim Liên Tủ 4",
        "from_latitude": 21.008121737119325,
        "to_latitude": 21.00809669790179,
        "to_longitude": 105.8407079131287,
        "from_longitude": 105.84095735856548,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2064,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84041823454882,
          21.00809669790179,
          105.8407079131287,
          21.00820436650804
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8407079131287,
            21.00809669790179
          ],
          [
            105.84041823454882,
            21.00820436650804
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT4",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Kim Liên Tủ 4",
        "from_latitude": 21.00809669790179,
        "to_latitude": 21.00820436650804,
        "to_longitude": 105.84041823454882,
        "from_longitude": 105.8407079131287,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2065,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84012855597793,
          21.00820436650804,
          105.84041823454882,
          21.008317042873372
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84041823454882,
            21.00820436650804
          ],
          [
            105.84012855597793,
            21.008317042873372
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT4",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Kim Liên Tủ 4",
        "from_latitude": 21.00820436650804,
        "to_latitude": 21.008317042873372,
        "to_longitude": 105.84012855597793,
        "from_longitude": 105.84041823454882,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2066,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83984424182252,
          21.008317042873372,
          105.84012855597793,
          21.00847228572521
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84012855597793,
            21.008317042873372
          ],
          [
            105.83984424182252,
            21.00847228572521
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT4",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Kim Liên Tủ 4",
        "from_latitude": 21.008317042873372,
        "to_latitude": 21.00847228572521,
        "to_longitude": 105.83984424182252,
        "from_longitude": 105.84012855597793,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2067,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8395706564981,
          21.008472285725222,
          105.83984424182252,
          21.00863754407026
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83984424182252,
            21.008472285725222
          ],
          [
            105.8395706564981,
            21.00863754407026
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT4",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Kim Liên Tủ 4",
        "from_latitude": 21.008472285725222,
        "to_latitude": 21.00863754407026,
        "to_longitude": 105.8395706564981,
        "from_longitude": 105.83984424182252,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2068,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8334699722221,
          21.019380821449445,
          105.83349679429055,
          21.019507259891775
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8334699722221,
            21.019380821449445
          ],
          [
            105.83349679429055,
            21.019494741238343
          ],
          [
            105.8334766777235,
            21.019507259891775
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 292 Khâm Thiên",
        "diemDau": "03",
        "diemCuoi": "3-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019380821449445,
        "to_latitude": 21.019507259891775,
        "to_longitude": 105.8334766777235,
        "from_longitude": 105.8334699722221,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2069,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83344717342037,
          21.019525571864886,
          105.83348472434672,
          21.019634484085696
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83348472434672,
            21.019525571864886
          ],
          [
            105.83344717342037,
            21.019548571437813
          ],
          [
            105.83347935993125,
            21.019634484085696
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 292 Khâm Thiên",
        "diemDau": "3-12",
        "diemCuoi": "3-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019525571864886,
        "to_latitude": 21.019634484085696,
        "to_longitude": 105.83347935993125,
        "from_longitude": 105.83348472434672,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2070,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81954416182568,
          21.007637676532465,
          105.81977265895425,
          21.007740337613143
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81954416182568,
            21.007637676532465
          ],
          [
            105.81977265895425,
            21.007740337613143
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vĩnh Hồ",
        "diemDau": "09",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007637676532465,
        "to_latitude": 21.007740337613143,
        "to_longitude": 105.81977265895425,
        "from_longitude": 105.81954416182568,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2071,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81977265895425,
          21.007740337613143,
          105.81999611875972,
          21.007912061609026
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81977265895425,
            21.007740337613143
          ],
          [
            105.81999611875972,
            21.007912061609026
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vĩnh Hồ",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007740337613143,
        "to_latitude": 21.007912061609026,
        "to_longitude": 105.81999611875972,
        "from_longitude": 105.81977265895425,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2072,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81999611875972,
          21.007912061609026,
          105.8202058357202,
          21.008163500835067
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81999611875972,
            21.007912061609026
          ],
          [
            105.8202058357202,
            21.008163500835067
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vĩnh Hồ",
        "diemDau": "02",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007912061609026,
        "to_latitude": 21.008163500835067,
        "to_longitude": 105.8202058357202,
        "from_longitude": 105.81999611875972,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2073,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81977265895425,
          21.007585093998834,
          105.81984776080697,
          21.007740337613143
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81977265895425,
            21.007740337613143
          ],
          [
            105.81984776080697,
            21.007585093998834
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vĩnh Hồ",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007740337613143,
        "to_latitude": 21.007585093998834,
        "to_longitude": 105.81984776080697,
        "from_longitude": 105.81977265895425,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2074,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81984776080697,
          21.00742233842355,
          105.81998187125696,
          21.007585093998834
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81984776080697,
            21.007585093998834
          ],
          [
            105.81998187125696,
            21.00742233842355
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vĩnh Hồ",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007585093998834,
        "to_latitude": 21.00742233842355,
        "to_longitude": 105.81998187125696,
        "from_longitude": 105.81984776080697,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2075,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81998187125696,
          21.007220771655533,
          105.8201749903132,
          21.00742233842355
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81998187125696,
            21.00742233842355
          ],
          [
            105.8201749903132,
            21.007220771655533
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vĩnh Hồ",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.00742233842355,
        "to_latitude": 21.007220771655533,
        "to_longitude": 105.8201749903132,
        "from_longitude": 105.81998187125696,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2076,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8201749903132,
          21.00698540142235,
          105.82037347378493,
          21.007220771655533
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8201749903132,
            21.007220771655533
          ],
          [
            105.82037347378493,
            21.00698540142235
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vĩnh Hồ",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007220771655533,
        "to_latitude": 21.00698540142235,
        "to_longitude": 105.82037347378493,
        "from_longitude": 105.8201749903132,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2077,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82037347378493,
          21.00672624334291,
          105.82059743823918,
          21.00698540142235
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82037347378493,
            21.00698540142235
          ],
          [
            105.82059743823918,
            21.00672624334291
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vĩnh Hồ",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.00698540142235,
        "to_latitude": 21.00672624334291,
        "to_longitude": 105.82059743823918,
        "from_longitude": 105.82037347378493,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2078,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83306764087216,
          21.019393340111606,
          105.8331923635591,
          21.01973713691029
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83306764087216,
            21.019393340111606
          ],
          [
            105.8331923635591,
            21.01973713691029
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tiến Bộ",
        "diemDau": "3+1",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019393340111606,
        "to_latitude": 21.01973713691029,
        "to_longitude": 105.8331923635591,
        "from_longitude": 105.83306764087216,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2079,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83299656230413,
          21.01973713691029,
          105.8331923635591,
          21.019762174173742
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8331923635591,
            21.01973713691029
          ],
          [
            105.83299656230413,
            21.019762174173742
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tiến Bộ",
        "diemDau": "3-1",
        "diemCuoi": "3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.01973713691029,
        "to_latitude": 21.019762174173742,
        "to_longitude": 105.83299656230413,
        "from_longitude": 105.8331923635591,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2080,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8331923635591,
          21.01973713691029,
          105.8332151623429,
          21.019939938623814
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8331923635591,
            21.01973713691029
          ],
          [
            105.8332151623429,
            21.019939938623814
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tiến Bộ",
        "diemDau": "3-1",
        "diemCuoi": "3-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.01973713691029,
        "to_latitude": 21.019939938623814,
        "to_longitude": 105.8332151623429,
        "from_longitude": 105.8331923635591,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2081,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83307836967619,
          21.019939938623814,
          105.8332151623429,
          21.019958716546643
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8332151623429,
            21.019939938623814
          ],
          [
            105.83307836967619,
            21.019958716546643
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tiến Bộ",
        "diemDau": "3-3",
        "diemCuoi": "3-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019939938623814,
        "to_latitude": 21.019958716546643,
        "to_longitude": 105.83307836967619,
        "from_longitude": 105.8332151623429,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2082,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83307836967619,
          21.019958716546643,
          105.8331963868797,
          21.02013147332334
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83307836967619,
            21.019958716546643
          ],
          [
            105.8331963868797,
            21.02013147332334
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tiến Bộ",
        "diemDau": "3-4",
        "diemCuoi": "3-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019958716546643,
        "to_latitude": 21.02013147332334,
        "to_longitude": 105.8331963868797,
        "from_longitude": 105.83307836967619,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2083,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83323259670222,
          21.020216599777097,
          105.83331976848973,
          21.020262918562302
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83331976848973,
            21.020216599777097
          ],
          [
            105.83323259670222,
            21.020262918562302
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tiến Bộ",
        "diemDau": "3-6",
        "diemCuoi": "3-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.020216599777097,
        "to_latitude": 21.020262918562302,
        "to_longitude": 105.83323259670222,
        "from_longitude": 105.83331976848973,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2084,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83306495863746,
          21.02024163696003,
          105.83323259670222,
          21.020262918562302
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83323259670222,
            21.020262918562302
          ],
          [
            105.83306495863746,
            21.02024163696003
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tiến Bộ",
        "diemDau": "3-9",
        "diemCuoi": "3-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.020262918562302,
        "to_latitude": 21.02024163696003,
        "to_longitude": 105.83306495863746,
        "from_longitude": 105.83323259670222,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2085,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83306495863746,
          21.02024163696003,
          105.83308909851613,
          21.020329267068053
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83306495863746,
            21.02024163696003
          ],
          [
            105.83308909851613,
            21.020329267068053
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tiến Bộ",
        "diemDau": "3-7",
        "diemCuoi": "3-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.02024163696003,
        "to_latitude": 21.020329267068053,
        "to_longitude": 105.83308909851613,
        "from_longitude": 105.83306495863746,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2086,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83323259670222,
          21.020262918562302,
          105.83337207156765,
          21.020383096965407
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83323259670222,
            21.020262918562302
          ],
          [
            105.83337207156765,
            21.020383096965407
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tiến Bộ",
        "diemDau": "3-9",
        "diemCuoi": "3-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.020262918562302,
        "to_latitude": 21.020383096965407,
        "to_longitude": 105.83337207156765,
        "from_longitude": 105.83323259670222,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2087,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8331963868797,
          21.02013147332334,
          105.83331976848973,
          21.020216599777097
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8331963868797,
            21.02013147332334
          ],
          [
            105.83329294640333,
            21.020136946764776
          ],
          [
            105.83331976848973,
            21.020216599777097
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tiến Bộ",
        "diemDau": "3-5",
        "diemCuoi": "3-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.02013147332334,
        "to_latitude": 21.020216599777097,
        "to_longitude": 105.83331976848973,
        "from_longitude": 105.8331963868797,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2088,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81984776080697,
          21.007517038828766,
          105.82000940588712,
          21.007585093998834
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81984776080697,
            21.007585093998834
          ],
          [
            105.81992951051618,
            21.007517038828766
          ],
          [
            105.82000940588712,
            21.00758127821229
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "C1 Tập Thể Vĩnh Hồ",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007585093998834,
        "to_latitude": 21.00758127821229,
        "to_longitude": 105.82000940588712,
        "from_longitude": 105.81984776080697,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2089,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82000940588712,
          21.00758127821229,
          105.82016285074624,
          21.00769754721982
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82000940588712,
            21.00758127821229
          ],
          [
            105.82016285074624,
            21.00769754721982
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "C1 Tập Thể Vĩnh Hồ",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.00758127821229,
        "to_latitude": 21.00769754721982,
        "to_longitude": 105.82016285074624,
        "from_longitude": 105.82000940588712,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2090,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82016285074624,
          21.00769754721982,
          105.82027888180197,
          21.007790236355714
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82016285074624,
            21.00769754721982
          ],
          [
            105.82027888180197,
            21.007790236355714
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "C1 Tập Thể Vĩnh Hồ",
        "diemDau": "4-2",
        "diemCuoi": "4-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.00769754721982,
        "to_latitude": 21.007790236355714,
        "to_longitude": 105.82027888180197,
        "from_longitude": 105.82016285074624,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2091,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82059743823918,
          21.00672624334291,
          105.82064974302676,
          21.006814236832486
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82059743823918,
            21.00672624334291
          ],
          [
            105.82064974302676,
            21.006814236832486
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 Thái Thịnh",
        "diemDau": "08",
        "diemCuoi": "8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.00672624334291,
        "to_latitude": 21.006814236832486,
        "to_longitude": 105.82064974302676,
        "from_longitude": 105.82059743823918,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2092,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82064974302676,
          21.006814236832486,
          105.82076725036106,
          21.006905291296015
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82064974302676,
            21.006814236832486
          ],
          [
            105.82076725036106,
            21.006905291296015
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 Thái Thịnh",
        "diemDau": "8-1",
        "diemCuoi": "8-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.006814236832486,
        "to_latitude": 21.006905291296015,
        "to_longitude": 105.82076725036106,
        "from_longitude": 105.82064974302676,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2093,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82076725036106,
          21.006905291296015,
          105.82095729207214,
          21.007059114311836
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82076725036106,
            21.006905291296015
          ],
          [
            105.82095729207214,
            21.007059114311836
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 Thái Thịnh",
        "diemDau": "8-2",
        "diemCuoi": "8-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.006905291296015,
        "to_latitude": 21.007059114311836,
        "to_longitude": 105.82095729207214,
        "from_longitude": 105.82076725036106,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2094,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81979889476754,
          21.0074123232099,
          105.81998187125696,
          21.00742233842355
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81998187125696,
            21.00742233842355
          ],
          [
            105.81979889476754,
            21.0074123232099
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "B1,B2,B3 Tập Thể Vĩnh Hồ",
        "diemDau": "05",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.00742233842355,
        "to_latitude": 21.0074123232099,
        "to_longitude": 105.81979889476754,
        "from_longitude": 105.81998187125696,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2095,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8196521434166,
          21.00730166321591,
          105.81979889476754,
          21.0074123232099
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81979889476754,
            21.0074123232099
          ],
          [
            105.8196521434166,
            21.00730166321591
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "B1,B2,B3 Tập Thể Vĩnh Hồ",
        "diemDau": "5-1",
        "diemCuoi": "5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.0074123232099,
        "to_latitude": 21.00730166321591,
        "to_longitude": 105.8196521434166,
        "from_longitude": 105.81979889476754,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2096,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82002136498055,
          21.007125972114817,
          105.8201749903132,
          21.007220771655533
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8201749903132,
            21.007220771655533
          ],
          [
            105.82002136498055,
            21.007125972114817
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "B1,B2,B3 Tập Thể Vĩnh Hồ",
        "diemDau": "06",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007220771655533,
        "to_latitude": 21.007125972114817,
        "to_longitude": 105.82002136498055,
        "from_longitude": 105.8201749903132,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2097,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81965118828236,
          21.006833309059694,
          105.82002136498055,
          21.007125972114817
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82002136498055,
            21.007125972114817
          ],
          [
            105.81965118828236,
            21.006833309059694
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "B1,B2,B3 Tập Thể Vĩnh Hồ",
        "diemDau": "6-1",
        "diemCuoi": "6-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007125972114817,
        "to_latitude": 21.006833309059694,
        "to_longitude": 105.81965118828236,
        "from_longitude": 105.82002136498055,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2098,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82015541021855,
          21.006935268423938,
          105.82037347378493,
          21.00698540142235
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82037347378493,
            21.00698540142235
          ],
          [
            105.82015541021855,
            21.006935268423938
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "B1,B2,B3 Tập Thể Vĩnh Hồ",
        "diemDau": "07",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.00698540142235,
        "to_latitude": 21.006935268423938,
        "to_longitude": 105.82015541021855,
        "from_longitude": 105.82037347378493,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2099,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81994969705718,
          21.00676632517261,
          105.82015541021855,
          21.006935268423938
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82015541021855,
            21.006935268423938
          ],
          [
            105.81994969705718,
            21.00676632517261
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "B1,B2,B3 Tập Thể Vĩnh Hồ",
        "diemDau": "7-1",
        "diemCuoi": "7-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.006935268423938,
        "to_latitude": 21.00676632517261,
        "to_longitude": 105.81994969705718,
        "from_longitude": 105.82015541021855,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2100,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84071864195066,
          21.00736116900554,
          105.84093187759191,
          21.007638479479045
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84093187759191,
            21.00736116900554
          ],
          [
            105.84092248983788,
            21.007438164970644
          ],
          [
            105.84071864195066,
            21.007638479479045
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.00736116900554,
        "to_latitude": 21.007638479479045,
        "to_longitude": 105.84071864195066,
        "from_longitude": 105.84093187759191,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2101,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8404235989643,
          21.007638479479045,
          105.84071864195066,
          21.007728620919845
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84071864195066,
            21.007638479479045
          ],
          [
            105.8404235989643,
            21.007728620919845
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.007638479479045,
        "to_latitude": 21.007728620919845,
        "to_longitude": 105.8404235989643,
        "from_longitude": 105.84071864195066,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2102,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8400963694581,
          21.007728620919845,
          105.8404235989643,
          21.00787384868199
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8404235989643,
            21.007728620919845
          ],
          [
            105.8400963694581,
            21.00787384868199
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.007728620919845,
        "to_latitude": 21.00787384868199,
        "to_longitude": 105.8400963694581,
        "from_longitude": 105.8404235989643,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2103,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83979327983951,
          21.00787384868199,
          105.8400963694581,
          21.007994037068002
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8400963694581,
            21.00787384868199
          ],
          [
            105.83979327983951,
            21.007994037068002
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.00787384868199,
        "to_latitude": 21.007994037068002,
        "to_longitude": 105.83979327983951,
        "from_longitude": 105.8400963694581,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2104,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83953310557176,
          21.007994037068002,
          105.83979327983951,
          21.008134256729036
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83979327983951,
            21.007994037068002
          ],
          [
            105.83953310557176,
            21.008134256729036
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "09",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.007994037068002,
        "to_latitude": 21.008134256729036,
        "to_longitude": 105.83953310557176,
        "from_longitude": 105.83979327983951,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2105,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83926488467179,
          21.008134256729036,
          105.83953310557176,
          21.00829701152774
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83953310557176,
            21.008134256729036
          ],
          [
            105.83926488467179,
            21.00829701152774
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.008134256729036,
        "to_latitude": 21.00829701152774,
        "to_longitude": 105.83926488467179,
        "from_longitude": 105.83953310557176,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2106,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83906908340781,
          21.00829701152774,
          105.83926488467179,
          21.008532379692635
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83926488467179,
            21.00829701152774
          ],
          [
            105.83906908340781,
            21.008532379692635
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.00829701152774,
        "to_latitude": 21.008532379692635,
        "to_longitude": 105.83906908340781,
        "from_longitude": 105.83926488467179,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2107,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81931131735531,
          21.007511008846276,
          105.81954416182568,
          21.007637676532465
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81954416182568,
            21.007637676532465
          ],
          [
            105.81931131735531,
            21.007511008846276
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007637676532465,
        "to_latitude": 21.007511008846276,
        "to_longitude": 105.81931131735531,
        "from_longitude": 105.81954416182568,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2108,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81908989582493,
          21.007367597143364,
          105.81931131735531,
          21.007511008846276
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81931131735531,
            21.007511008846276
          ],
          [
            105.81908989582493,
            21.007367597143364
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007511008846276,
        "to_latitude": 21.007367597143364,
        "to_longitude": 105.81908989582493,
        "from_longitude": 105.81931131735531,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2109,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81877570890333,
          21.007189023415307,
          105.81908989582493,
          21.007367597143364
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81908989582493,
            21.007367597143364
          ],
          [
            105.81884858028413,
            21.007189023415307
          ],
          [
            105.81877570890333,
            21.00722577953567
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "11",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007367597143364,
        "to_latitude": 21.00722577953567,
        "to_longitude": 105.81877570890333,
        "from_longitude": 105.81908989582493,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2110,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81908989582493,
          21.007305905480646,
          105.8191471948912,
          21.007367597143364
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81908989582493,
            21.007367597143364
          ],
          [
            105.8191471948912,
            21.007305905480646
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Binh Đoàn 12",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007367597143364,
        "to_latitude": 21.007305905480646,
        "to_longitude": 105.8191471948912,
        "from_longitude": 105.81908989582493,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2111,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8191471948912,
          21.007173822838027,
          105.81926521209024,
          21.007305905480646
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8191471948912,
            21.007305905480646
          ],
          [
            105.81926521209024,
            21.007173822838027
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Binh Đoàn 12",
        "diemDau": "11-1",
        "diemCuoi": "11-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007305905480646,
        "to_latitude": 21.007173822838027,
        "to_longitude": 105.81926521209024,
        "from_longitude": 105.8191471948912,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2112,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81926521209024,
          21.007041740078456,
          105.81938322928927,
          21.007173822838027
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81926521209024,
            21.007173822838027
          ],
          [
            105.81938322928927,
            21.007041740078456
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Binh Đoàn 12",
        "diemDau": "11-2",
        "diemCuoi": "11-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007173822838027,
        "to_latitude": 21.007041740078456,
        "to_longitude": 105.81938322928927,
        "from_longitude": 105.81926521209024,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2113,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81885536306808,
          21.007367597143364,
          105.81913691775112,
          21.00782062876362
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81908989582493,
            21.007367597143364
          ],
          [
            105.81913691775112,
            21.007450684624278
          ],
          [
            105.81885536306808,
            21.00782062876362
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 60,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Binh Đoàn 12",
        "diemDau": "11",
        "diemCuoi": "11-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007367597143364,
        "to_latitude": 21.00782062876362,
        "to_longitude": 105.81885536306808,
        "from_longitude": 105.81908989582493,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2114,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81877570890333,
          21.00699622012561,
          105.81887003795504,
          21.00722577953567
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81877570890333,
            21.00722577953567
          ],
          [
            105.81887003795504,
            21.00699622012561
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "16",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.00722577953567,
        "to_latitude": 21.00699622012561,
        "to_longitude": 105.81887003795504,
        "from_longitude": 105.81877570890333,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2115,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81887003795504,
          21.00676216524287,
          105.81900513402267,
          21.00699622012561
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81887003795504,
            21.00699622012561
          ],
          [
            105.81900513402267,
            21.00676216524287
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "15",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.00699622012561,
        "to_latitude": 21.00676216524287,
        "to_longitude": 105.81900513402267,
        "from_longitude": 105.81887003795504,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2116,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81900513402267,
          21.006534691434613,
          105.81914987706301,
          21.00676216524287
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81900513402267,
            21.00676216524287
          ],
          [
            105.81914987706301,
            21.006534691434613
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "14",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.00676216524287,
        "to_latitude": 21.006534691434613,
        "to_longitude": 105.81914987706301,
        "from_longitude": 105.81900513402267,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2117,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81914987706301,
          21.00626031097636,
          105.81933124436311,
          21.006534691434613
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81914987706301,
            21.006534691434613
          ],
          [
            105.81933124436311,
            21.00626031097636
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "13",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.006534691434613,
        "to_latitude": 21.00626031097636,
        "to_longitude": 105.81933124436311,
        "from_longitude": 105.81914987706301,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2118,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83220497535574,
          21.01969097099927,
          105.83232031034254,
          21.019998929145206
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83220497535574,
            21.01969097099927
          ],
          [
            105.83232031034254,
            21.019998929145206
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Kiến Thiết",
        "diemDau": "6-2",
        "diemCuoi": "6-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.01969097099927,
        "to_latitude": 21.019998929145206,
        "to_longitude": 105.83232031034254,
        "from_longitude": 105.83220497535574,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2119,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83232031034254,
          21.019972640059972,
          105.832490630615,
          21.019998929145206
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83232031034254,
            21.019998929145206
          ],
          [
            105.832490630615,
            21.019972640059972
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Kiến Thiết",
        "diemDau": "6-5",
        "diemCuoi": "6-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019998929145206,
        "to_latitude": 21.019972640059972,
        "to_longitude": 105.832490630615,
        "from_longitude": 105.83232031034254,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2120,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83214194343786,
          21.019998929145206,
          105.83232031034254,
          21.020076544511607
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83232031034254,
            21.019998929145206
          ],
          [
            105.83214194343786,
            21.020076544511607
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Kiến Thiết",
        "diemDau": "6-5",
        "diemCuoi": "6-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019998929145206,
        "to_latitude": 21.020076544511607,
        "to_longitude": 105.83214194343786,
        "from_longitude": 105.83232031034254,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2121,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83210975693599,
          21.020076544511607,
          105.83220296371341,
          21.020392638936464
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83214194343786,
            21.020076544511607
          ],
          [
            105.83220296371341,
            21.020392638936464
          ],
          [
            105.83210975693599,
            21.02038825743454
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Kiến Thiết",
        "diemDau": "6-7",
        "diemCuoi": "6-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.020076544511607,
        "to_latitude": 21.02038825743454,
        "to_longitude": 105.83210975693599,
        "from_longitude": 105.83214194343786,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2122,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.832490630615,
          21.019972640059972,
          105.83258987234638,
          21.020028347876462
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.832490630615,
            21.019972640059972
          ],
          [
            105.83253958094672,
            21.020028347876462
          ],
          [
            105.83258987234638,
            21.020006440311324
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Kiến Thiết",
        "diemDau": "6-6",
        "diemCuoi": "6-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019972640059972,
        "to_latitude": 21.020006440311324,
        "to_longitude": 105.83258987234638,
        "from_longitude": 105.832490630615,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2123,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83210171030377,
          21.019575173564245,
          105.83220497535574,
          21.01969097099927
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83220497535574,
            21.01969097099927
          ],
          [
            105.83218418825022,
            21.019575173564245
          ],
          [
            105.83210171030377,
            21.019605844245397
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Kiến Thiết",
        "diemDau": "6-2",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.01969097099927,
        "to_latitude": 21.019605844245397,
        "to_longitude": 105.83210171030377,
        "from_longitude": 105.83220497535574,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2124,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83219324073504,
          21.019453429675703,
          105.83220497535574,
          21.01969097099927
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83220497535574,
            21.01969097099927
          ],
          [
            105.83219324073504,
            21.019453429675703
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Kiến Thiết",
        "diemDau": "6",
        "diemCuoi": "6-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.01969097099927,
        "to_latitude": 21.019453429675703,
        "to_longitude": 105.83219324073504,
        "from_longitude": 105.83220497535574,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2125,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84041421122822,
          21.00736116900554,
          105.84093187759191,
          21.007708589495223
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84093187759191,
            21.00736116900554
          ],
          [
            105.84090639658243,
            21.007428149240415
          ],
          [
            105.84070791311072,
            21.007619700007563
          ],
          [
            105.84041421122822,
            21.007708589495223
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.00736116900554,
        "to_latitude": 21.007708589495223,
        "to_longitude": 105.84041421122822,
        "from_longitude": 105.84093187759191,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2126,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83978120989569,
          21.007708589495223,
          105.84041421122822,
          21.00797650960267
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84041421122822,
            21.007708589495223
          ],
          [
            105.84008698172201,
            21.00785506924011
          ],
          [
            105.83978120989569,
            21.00797650960267
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.007708589495223,
        "to_latitude": 21.00797650960267,
        "to_longitude": 105.83978120989569,
        "from_longitude": 105.84041421122822,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2127,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83925281471898,
          21.00797650960267,
          105.83978120989569,
          21.008284491936735
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83978120989569,
            21.00797650960267
          ],
          [
            105.83952371783566,
            21.008120485163364
          ],
          [
            105.83925281471898,
            21.008284491936735
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.00797650960267,
        "to_latitude": 21.008284491936735,
        "to_longitude": 105.83925281471898,
        "from_longitude": 105.83978120989569,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2128,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83903957910468,
          21.008284491936735,
          105.83925281471898,
          21.008549907096018
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83925281471898,
            21.008284491936735
          ],
          [
            105.83905433125622,
            21.008521112078213
          ],
          [
            105.83903957910468,
            21.008549907096018
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.008284491936735,
        "to_latitude": 21.008549907096018,
        "to_longitude": 105.83903957910468,
        "from_longitude": 105.83925281471898,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2129,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8386747986811,
          21.008549907096018,
          105.83903957910468,
          21.00889169101587
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83903957910468,
            21.008549907096018
          ],
          [
            105.8386747986811,
            21.00889169101587
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.008549907096018,
        "to_latitude": 21.00889169101587,
        "to_longitude": 105.8386747986811,
        "from_longitude": 105.83903957910468,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2130,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83823357529192,
          21.00889169101587,
          105.8386747986811,
          21.009333630235332
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8386747986811,
            21.00889169101587
          ],
          [
            105.83823357529192,
            21.009333630235332
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.00889169101587,
        "to_latitude": 21.009333630235332,
        "to_longitude": 105.83823357529192,
        "from_longitude": 105.8386747986811,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2131,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83789293474699,
          21.009333630235332,
          105.83823357529192,
          21.009692939627982
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83823357529192,
            21.009333630235332
          ],
          [
            105.83789293474699,
            21.009692939627982
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.009333630235332,
        "to_latitude": 21.009692939627982,
        "to_longitude": 105.83789293474699,
        "from_longitude": 105.83823357529192,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2132,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8375858218168,
          21.009692939627982,
          105.83789293474699,
          21.01005975982148
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83789293474699,
            21.009692939627982
          ],
          [
            105.8375858218168,
            21.01005975982148
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.009692939627982,
        "to_latitude": 21.01005975982148,
        "to_longitude": 105.8375858218168,
        "from_longitude": 105.83789293474699,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2133,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83719019598624,
          21.01005975982148,
          105.8375858218168,
          21.010635653187048
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8375858218168,
            21.01005975982148
          ],
          [
            105.83719019598624,
            21.010635653187048
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.01005975982148,
        "to_latitude": 21.010635653187048,
        "to_longitude": 105.83719019598624,
        "from_longitude": 105.8375858218168,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2134,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8371016830948,
          21.010635653187048,
          105.83726261561323,
          21.01082657386809
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83719019598624,
            21.010635653187048
          ],
          [
            105.8371016830948,
            21.01076335098064
          ],
          [
            105.83726261561323,
            21.01082657386809
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NKLT5",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "from_latitude": 21.010635653187048,
        "to_latitude": 21.01082657386809,
        "to_longitude": 105.83726261561323,
        "from_longitude": 105.83719019598624,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2135,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81902503780807,
          21.006534691434613,
          105.81914987706301,
          21.006548903919192
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81914987706301,
            21.006534691434613
          ],
          [
            105.81902503780807,
            21.006548903919192
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 141 Thịnh Quang",
        "diemDau": "13",
        "diemCuoi": "13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.006534691434613,
        "to_latitude": 21.006548903919192,
        "to_longitude": 105.81902503780807,
        "from_longitude": 105.81914987706301,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2136,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8188583162317,
          21.006514787606147,
          105.81902503780807,
          21.006548903919192
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81902503780807,
            21.006548903919192
          ],
          [
            105.8188583162317,
            21.006514787606147
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 141 Thịnh Quang",
        "diemDau": "13-1",
        "diemCuoi": "13-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.006548903919192,
        "to_latitude": 21.006514787606147,
        "to_longitude": 105.8188583162317,
        "from_longitude": 105.81902503780807,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2137,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81862877963822,
          21.00644163064738,
          105.8188583162317,
          21.006514787606147
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8188583162317,
            21.006514787606147
          ],
          [
            105.81862877963822,
            21.00644163064738
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 141 Thịnh Quang",
        "diemDau": "13-2",
        "diemCuoi": "13-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.006514787606147,
        "to_latitude": 21.00644163064738,
        "to_longitude": 105.81862877963822,
        "from_longitude": 105.8188583162317,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2138,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81852451674149,
          21.00613818561187,
          105.81862877963822,
          21.00644163064738
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81862877963822,
            21.00644163064738
          ],
          [
            105.81852451674149,
            21.00613818561187
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 141 Thịnh Quang",
        "diemDau": "13-3",
        "diemCuoi": "13-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.00644163064738,
        "to_latitude": 21.00613818561187,
        "to_longitude": 105.81852451674149,
        "from_longitude": 105.81862877963822,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2139,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81861490116708,
          21.006859518466715,
          105.81887003795504,
          21.00699622012561
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81887003795504,
            21.00699622012561
          ],
          [
            105.81886333243567,
            21.006952401161225
          ],
          [
            105.81861490116708,
            21.006859518466715
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 161 Thịnh Quang",
        "diemDau": "15",
        "diemCuoi": "15-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.00699622012561,
        "to_latitude": 21.006859518466715,
        "to_longitude": 105.81861490116708,
        "from_longitude": 105.81887003795504,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2140,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83180264400579,
          21.019488481910408,
          105.83185260019012,
          21.019580806955723
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83185260019012,
            21.019488481910408
          ],
          [
            105.83181002009054,
            21.019513832183822
          ],
          [
            105.83180264400579,
            21.019580806955723
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Vạn Anh",
        "diemDau": "7",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019488481910408,
        "to_latitude": 21.019580806955723,
        "to_longitude": 105.83180264400579,
        "from_longitude": 105.83185260019012,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2141,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83180264400579,
          21.019580806955723,
          105.83180264400579,
          21.01981240172628
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83180264400579,
            21.019580806955723
          ],
          [
            105.83180264400579,
            21.01981240172628
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Vạn Anh",
        "diemDau": "7-1",
        "diemCuoi": "7-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019580806955723,
        "to_latitude": 21.01981240172628,
        "to_longitude": 105.83180264400579,
        "from_longitude": 105.83180264400579,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2142,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83180264400579,
          21.01981240172628,
          105.83181203173288,
          21.019910046980968
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83180264400579,
            21.01981240172628
          ],
          [
            105.83181203173288,
            21.019910046980968
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Vạn Anh",
        "diemDau": "7-2",
        "diemCuoi": "7-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.01981240172628,
        "to_latitude": 21.019910046980968,
        "to_longitude": 105.83181203173288,
        "from_longitude": 105.83180264400579,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2143,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83181203173288,
          21.019910046980968,
          105.83198771642084,
          21.01991505442837
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83181203173288,
            21.019910046980968
          ],
          [
            105.83198771642084,
            21.01991505442837
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Vạn Anh",
        "diemDau": "7-3",
        "diemCuoi": "7-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019910046980968,
        "to_latitude": 21.01991505442837,
        "to_longitude": 105.83198771642084,
        "from_longitude": 105.83181203173288,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2144,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83137617276819,
          21.019504443195945,
          105.83142311142164,
          21.019659674404792
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83137617276819,
            21.019504443195945
          ],
          [
            105.83142311142164,
            21.019659674404792
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "diemDau": "8-1",
        "diemCuoi": "8-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019504443195945,
        "to_latitude": 21.019659674404792,
        "to_longitude": 105.83142311142164,
        "from_longitude": 105.83137617276819,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2145,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83142311142164,
          21.019659674404792,
          105.83151698873753,
          21.019851209463482
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83142311142164,
            21.019659674404792
          ],
          [
            105.83151698873753,
            21.019851209463482
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "diemDau": "8-2",
        "diemCuoi": "8-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019659674404792,
        "to_latitude": 21.019851209463482,
        "to_longitude": 105.83151698873753,
        "from_longitude": 105.83142311142164,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2146,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83151698873753,
          21.019851209463482,
          105.83155051635228,
          21.019986410533047
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83151698873753,
            21.019851209463482
          ],
          [
            105.83155051635228,
            21.019986410533047
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "diemDau": "8-3",
        "diemCuoi": "8-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019851209463482,
        "to_latitude": 21.019986410533047,
        "to_longitude": 105.83155051635228,
        "from_longitude": 105.83151698873753,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2147,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83155051635228,
          21.019986410533047,
          105.83163500594105,
          21.020182952611265
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83155051635228,
            21.019986410533047
          ],
          [
            105.83163500594105,
            21.020182952611265
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "diemDau": "8-4",
        "diemCuoi": "8-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019986410533047,
        "to_latitude": 21.020182952611265,
        "to_longitude": 105.83163500594105,
        "from_longitude": 105.83155051635228,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2148,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83144859241317,
          21.020182952611265,
          105.83163500594105,
          21.020238034420778
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83163500594105,
            21.020182952611265
          ],
          [
            105.83144859241317,
            21.020238034420778
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "diemDau": "8-5",
        "diemCuoi": "8-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.020182952611265,
        "to_latitude": 21.020238034420778,
        "to_longitude": 105.83144859241317,
        "from_longitude": 105.83163500594105,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2149,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83125949667753,
          21.020238034420778,
          105.83144859241317,
          21.020334427538252
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83144859241317,
            21.020238034420778
          ],
          [
            105.83125949667753,
            21.020334427538252
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "diemDau": "8-6",
        "diemCuoi": "8-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.020238034420778,
        "to_latitude": 21.020334427538252,
        "to_longitude": 105.83125949667753,
        "from_longitude": 105.83144859241317,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2150,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83117098377711,
          21.020144144960383,
          105.83125949667753,
          21.020334427538252
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83125949667753,
            21.020334427538252
          ],
          [
            105.83117098377711,
            21.020144144960383
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "diemDau": "8-7",
        "diemCuoi": "8-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.020334427538252,
        "to_latitude": 21.020144144960383,
        "to_longitude": 105.83117098377711,
        "from_longitude": 105.83125949667753,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2151,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83100066350465,
          21.020144144960383,
          105.83117098377711,
          21.020199226784236
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83117098377711,
            21.020144144960383
          ],
          [
            105.83100066350465,
            21.020199226784236
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "diemDau": "8-8",
        "diemCuoi": "8-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.020144144960383,
        "to_latitude": 21.020199226784236,
        "to_longitude": 105.83100066350465,
        "from_longitude": 105.83117098377711,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2152,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83163500594105,
          21.020182952611265,
          105.83176106975884,
          21.02038325000309
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83163500594105,
            21.020182952611265
          ],
          [
            105.83176106975884,
            21.02038325000309
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "diemDau": "8-5",
        "diemCuoi": "8-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.020182952611265,
        "to_latitude": 21.02038325000309,
        "to_longitude": 105.83176106975884,
        "from_longitude": 105.83163500594105,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2153,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83167859184832,
          21.02038325000309,
          105.83176106975884,
          21.02052345801715
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83176106975884,
            21.02038325000309
          ],
          [
            105.83167859184832,
            21.020388883362333
          ],
          [
            105.83170072005768,
            21.02052345801715
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "diemDau": "8-12",
        "diemCuoi": "8-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.02038325000309,
        "to_latitude": 21.02052345801715,
        "to_longitude": 105.83170072005768,
        "from_longitude": 105.83176106975884,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2154,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83137617276819,
          21.019477528090242,
          105.83159242591337,
          21.019504443195945
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83159242591337,
            21.019478466987625
          ],
          [
            105.83141305315608,
            21.019477528090242
          ],
          [
            105.83137617276819,
            21.019504443195945
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "diemDau": "8",
        "diemCuoi": "8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019478466987625,
        "to_latitude": 21.019504443195945,
        "to_longitude": 105.83137617276819,
        "from_longitude": 105.83159242591337,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2155,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83125949667753,
          21.020334427538252,
          105.83130107091547,
          21.020532221013724
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83125949667753,
            21.020334427538252
          ],
          [
            105.83130107091547,
            21.020532221013724
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "diemDau": "8-7",
        "diemCuoi": "8-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.020334427538252,
        "to_latitude": 21.020532221013724,
        "to_longitude": 105.83130107091547,
        "from_longitude": 105.83125949667753,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2156,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83130107091547,
          21.020532221013724,
          105.83132386969028,
          21.020623606518416
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83130107091547,
            21.020532221013724
          ],
          [
            105.83132386969028,
            21.020623606518416
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "diemDau": "8-10",
        "diemCuoi": "8-10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.020532221013724,
        "to_latitude": 21.020623606518416,
        "to_longitude": 105.83132386969028,
        "from_longitude": 105.83130107091547,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2157,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83132386969028,
          21.0206048286793,
          105.83144054578095,
          21.020623606518416
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83132386969028,
            21.020623606518416
          ],
          [
            105.83144054578095,
            21.0206048286793
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "8-10-1",
        "diemCuoi": "8-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kham Thien O",
        "from_latitude": 21.020623606518416,
        "to_latitude": 21.0206048286793,
        "to_longitude": 105.83144054578095,
        "from_longitude": 105.83132386969028,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2158,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83144054578095,
          21.0206048286793,
          105.8315263764736,
          21.020632369509052
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83144054578095,
            21.0206048286793
          ],
          [
            105.8315263764736,
            21.020632369509052
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "diemDau": "8-11",
        "diemCuoi": "8-11-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.0206048286793,
        "to_latitude": 21.020632369509052,
        "to_longitude": 105.8315263764736,
        "from_longitude": 105.83144054578095,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2159,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83143719302576,
          21.020473383741724,
          105.83150357769883,
          21.0206048286793
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83144054578095,
            21.0206048286793
          ],
          [
            105.83143719302576,
            21.020498420883232
          ],
          [
            105.83150357769883,
            21.020473383741724
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "diemDau": "8-11",
        "diemCuoi": "8-11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.0206048286793,
        "to_latitude": 21.020473383741724,
        "to_longitude": 105.83150357769883,
        "from_longitude": 105.83144054578095,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2160,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81853262507813,
          21.0074901215607,
          105.81870038825696,
          21.00775265271826
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81870038825696,
            21.0074901215607
          ],
          [
            105.81853262507813,
            21.00775265271826
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 88 Thịnh Quang",
        "diemDau": "17",
        "diemCuoi": "17-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.0074901215607,
        "to_latitude": 21.00775265271826,
        "to_longitude": 105.81853262507813,
        "from_longitude": 105.81870038825696,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2161,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81850064621685,
          21.00775265271826,
          105.81853262507813,
          21.007900620228757
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81853262507813,
            21.00775265271826
          ],
          [
            105.81850064621685,
            21.007900620228757
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 88 Thịnh Quang",
        "diemDau": "17-1",
        "diemCuoi": "17-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.00775265271826,
        "to_latitude": 21.007900620228757,
        "to_longitude": 105.81850064621685,
        "from_longitude": 105.81853262507813,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2162,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81825514155618,
          21.007135464329533,
          105.81836533700255,
          21.00727979111127
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81825514155618,
            21.00727979111127
          ],
          [
            105.81836533700255,
            21.007135464329533
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 175 Thịnh Quang",
        "diemDau": "18",
        "diemCuoi": "18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.00727979111127,
        "to_latitude": 21.007135464329533,
        "to_longitude": 105.81836533700255,
        "from_longitude": 105.81825514155618,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2163,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81836533700255,
          21.00691578101798,
          105.81843250259926,
          21.007135464329533
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81836533700255,
            21.007135464329533
          ],
          [
            105.81843250259926,
            21.00691578101798
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 175 Thịnh Quang",
        "diemDau": "18-1",
        "diemCuoi": "18-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007135464329533,
        "to_latitude": 21.00691578101798,
        "to_longitude": 105.81843250259926,
        "from_longitude": 105.81836533700255,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2164,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81805162892024,
          21.007045985934813,
          105.81836533700255,
          21.007135464329533
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81836533700255,
            21.007135464329533
          ],
          [
            105.81805162892024,
            21.007045985934813
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 175 Thịnh Quang",
        "diemDau": "18-1",
        "diemCuoi": "18-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007135464329533,
        "to_latitude": 21.007045985934813,
        "to_longitude": 105.81805162892024,
        "from_longitude": 105.81836533700255,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2165,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81793327647445,
          21.007045985934813,
          105.81805162892024,
          21.007232216325555
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81805162892024,
            21.007045985934813
          ],
          [
            105.81796214846591,
            21.007193712006693
          ],
          [
            105.81793327647445,
            21.007232216325555
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 175 Thịnh Quang",
        "diemDau": "18-3",
        "diemCuoi": "18-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007045985934813,
        "to_latitude": 21.007232216325555,
        "to_longitude": 105.81793327647445,
        "from_longitude": 105.81805162892024,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2166,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81770655998659,
          21.007232216325555,
          105.81793327647445,
          21.007258820649557
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81793327647445,
            21.007232216325555
          ],
          [
            105.8178799675439,
            21.00725506474555
          ],
          [
            105.81779413686024,
            21.007258820649557
          ],
          [
            105.81770655998659,
            21.007237699321326
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 175 Thịnh Quang",
        "diemDau": "18-4",
        "diemCuoi": "18-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007232216325555,
        "to_latitude": 21.007237699321326,
        "to_longitude": 105.81770655998659,
        "from_longitude": 105.81793327647445,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2167,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81750596554706,
          21.007237699321326,
          105.81770655998659,
          21.007379364085814
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81770655998659,
            21.007237699321326
          ],
          [
            105.81768953070441,
            21.007268836393646
          ],
          [
            105.81750596554706,
            21.007379364085814
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 175 Thịnh Quang",
        "diemDau": "18-5",
        "diemCuoi": "18-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007237699321326,
        "to_latitude": 21.007379364085814,
        "to_longitude": 105.81750596554706,
        "from_longitude": 105.81770655998659,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2168,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81825514155618,
          21.00727979111127,
          105.81870038825696,
          21.0074901215607
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81870038825696,
            21.0074901215607
          ],
          [
            105.81832253203693,
            21.00732642690708
          ],
          [
            105.81825514155618,
            21.00727979111127
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 175 Thịnh Quang",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.0074901215607,
        "to_latitude": 21.00727979111127,
        "to_longitude": 105.81825514155618,
        "from_longitude": 105.81870038825696,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2169,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83099697550719,
          21.018977719963807,
          105.83111499271072,
          21.01930070198653
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83099697550719,
            21.01930070198653
          ],
          [
            105.8310362027797,
            21.019165187331282
          ],
          [
            105.83111499271072,
            21.018977719963807
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chiến Thắng",
        "diemDau": "10",
        "diemCuoi": "10+1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.01930070198653,
        "to_latitude": 21.018977719963807,
        "to_longitude": 105.83111499271072,
        "from_longitude": 105.83099697550719,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2170,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83111499271072,
          21.018833754883772,
          105.83118607125184,
          21.018977719963807
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83111499271072,
            21.018977719963807
          ],
          [
            105.83118607125184,
            21.018833754883772
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chiến Thắng",
        "diemDau": "10-1",
        "diemCuoi": "10-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.018977719963807,
        "to_latitude": 21.018833754883772,
        "to_longitude": 105.83118607125184,
        "from_longitude": 105.83111499271072,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2171,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83098222336461,
          21.018768657584253,
          105.83118607125184,
          21.018833754883772
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83118607125184,
            21.018833754883772
          ],
          [
            105.83098222336461,
            21.018768657584253
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chiến Thắng",
        "diemDau": "10-2",
        "diemCuoi": "10-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.018833754883772,
        "to_latitude": 21.018768657584253,
        "to_longitude": 105.83098222336461,
        "from_longitude": 105.83118607125184,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2172,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83098222336461,
          21.018648477879946,
          105.8310184331871,
          21.018768657584253
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83098222336461,
            21.018768657584253
          ],
          [
            105.8310184331871,
            21.018648477879946
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chiến Thắng",
        "diemDau": "10-3",
        "diemCuoi": "10-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.018768657584253,
        "to_latitude": 21.018648477879946,
        "to_longitude": 105.8310184331871,
        "from_longitude": 105.83098222336461,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2173,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83118607125184,
          21.018659744731018,
          105.83124373874521,
          21.018833754883772
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83118607125184,
            21.018833754883772
          ],
          [
            105.83124373874521,
            21.018659744731018
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chiến Thắng",
        "diemDau": "10-2",
        "diemCuoi": "10-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.018833754883772,
        "to_latitude": 21.018659744731018,
        "to_longitude": 105.83124373874521,
        "from_longitude": 105.83118607125184,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2174,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83124373874521,
          21.018659744731018,
          105.83134834489208,
          21.01872984956528
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83124373874521,
            21.018659744731018
          ],
          [
            105.83134834489208,
            21.01872984956528
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chiến Thắng",
        "diemDau": "10-5",
        "diemCuoi": "10-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.018659744731018,
        "to_latitude": 21.01872984956528,
        "to_longitude": 105.83134834489208,
        "from_longitude": 105.83124373874521,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2175,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83134834489208,
          21.01872984956528,
          105.83149050197426,
          21.018804961850403
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83134834489208,
            21.01872984956528
          ],
          [
            105.83149050197426,
            21.018804961850403
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chiến Thắng",
        "diemDau": "10-6",
        "diemCuoi": "10-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.01872984956528,
        "to_latitude": 21.018804961850403,
        "to_longitude": 105.83149050197426,
        "from_longitude": 105.83134834489208,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2176,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8310362027797,
          21.019061595207067,
          105.8314408810951,
          21.019239047530256
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8310362027797,
            21.019165187331282
          ],
          [
            105.83120115863667,
            21.019239047530256
          ],
          [
            105.8314408810951,
            21.019061595207067
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 68,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 261",
        "diemDau": "10",
        "diemCuoi": "10-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019165187331282,
        "to_latitude": 21.019061595207067,
        "to_longitude": 105.8314408810951,
        "from_longitude": 105.8310362027797,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2177,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81806202249096,
          21.00727979111127,
          105.81825514155618,
          21.007636601521767
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81825514155618,
            21.00727979111127
          ],
          [
            105.81806202249096,
            21.007636601521767
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.00727979111127,
        "to_latitude": 21.007636601521767,
        "to_longitude": 105.81806202249096,
        "from_longitude": 105.81825514155618,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2178,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81783269361223,
          21.007636601521767,
          105.81806202249096,
          21.007953348293103
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81806202249096,
            21.007636601521767
          ],
          [
            105.81783269361223,
            21.007953348293103
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007636601521767,
        "to_latitude": 21.007953348293103,
        "to_longitude": 105.81783269361223,
        "from_longitude": 105.81806202249096,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2179,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81773297965249,
          21.007953348293103,
          105.81783269361223,
          21.008154442483022
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81783269361223,
            21.007953348293103
          ],
          [
            105.81773297965249,
            21.008154442483022
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007953348293103,
        "to_latitude": 21.008154442483022,
        "to_longitude": 105.81773297965249,
        "from_longitude": 105.81783269361223,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2180,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8176488286185,
          21.008154442483022,
          105.81773297965249,
          21.00842853189763
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81773297965249,
            21.008154442483022
          ],
          [
            105.8176488286185,
            21.00842853189763
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.008154442483022,
        "to_latitude": 21.00842853189763,
        "to_longitude": 105.8176488286185,
        "from_longitude": 105.81773297965249,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2181,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81757386044836,
          21.00842853189763,
          105.8176488286185,
          21.008683240465416
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8176488286185,
            21.00842853189763
          ],
          [
            105.81757386044836,
            21.008683240465416
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.00842853189763,
        "to_latitude": 21.008683240465416,
        "to_longitude": 105.81757386044836,
        "from_longitude": 105.8176488286185,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2182,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8174375152603,
          21.00866400985537,
          105.81757386044836,
          21.008683240465416
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81757386044836,
            21.008683240465416
          ],
          [
            105.8174375152603,
            21.00866400985537
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.008683240465416,
        "to_latitude": 21.00866400985537,
        "to_longitude": 105.8174375152603,
        "from_longitude": 105.81757386044836,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2183,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81730966805165,
          21.00866400985537,
          105.8174375152603,
          21.00876957145476
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8174375152603,
            21.00866400985537
          ],
          [
            105.81730966805165,
            21.00876957145476
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.00866400985537,
        "to_latitude": 21.00876957145476,
        "to_longitude": 105.81730966805165,
        "from_longitude": 105.8174375152603,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2184,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81712459044496,
          21.00876957145476,
          105.81730966805165,
          21.008917356066668
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81730966805165,
            21.00876957145476
          ],
          [
            105.81712459044496,
            21.008917356066668
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.00876957145476,
        "to_latitude": 21.008917356066668,
        "to_longitude": 105.81712459044496,
        "from_longitude": 105.81730966805165,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2185,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81724792202924,
          21.008551636931113,
          105.8174375152603,
          21.00866400985537
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8174375152603,
            21.00866400985537
          ],
          [
            105.81724792202924,
            21.008551636931113
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "24",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.00866400985537,
        "to_latitude": 21.008551636931113,
        "to_longitude": 105.81724792202924,
        "from_longitude": 105.8174375152603,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2186,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81701557113333,
          21.0084416577999,
          105.81724792202924,
          21.008551636931113
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81724792202924,
            21.008551636931113
          ],
          [
            105.81701557113333,
            21.0084416577999
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.008551636931113,
        "to_latitude": 21.0084416577999,
        "to_longitude": 105.81701557113333,
        "from_longitude": 105.81724792202924,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2187,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81676251170398,
          21.008355145112095,
          105.81701557113333,
          21.0084416577999
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81701557113333,
            21.0084416577999
          ],
          [
            105.81676251170398,
            21.008355145112095
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.0084416577999,
        "to_latitude": 21.008355145112095,
        "to_longitude": 105.81676251170398,
        "from_longitude": 105.81701557113333,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2188,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81689086302302,
          21.0084416577999,
          105.81701557113333,
          21.008626012450932
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81701557113333,
            21.0084416577999
          ],
          [
            105.81689086302302,
            21.008626012450932
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "28",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.0084416577999,
        "to_latitude": 21.008626012450932,
        "to_longitude": 105.81689086302302,
        "from_longitude": 105.81701557113333,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2189,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81701557113333,
          21.00826633851261,
          105.817144707012,
          21.0084416577999
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81701557113333,
            21.0084416577999
          ],
          [
            105.817144707012,
            21.00826633851261
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "28",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.0084416577999,
        "to_latitude": 21.00826633851261,
        "to_longitude": 105.817144707012,
        "from_longitude": 105.81701557113333,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2190,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81690639938904,
          21.008194854976075,
          105.817144707012,
          21.00826633851261
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.817144707012,
            21.00826633851261
          ],
          [
            105.81690639938904,
            21.008194854976075
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.00826633851261,
        "to_latitude": 21.008194854976075,
        "to_longitude": 105.81690639938904,
        "from_longitude": 105.817144707012,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2191,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81783269361223,
          21.007953348293103,
          105.81795122052043,
          21.007992615276805
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81783269361223,
            21.007953348293103
          ],
          [
            105.81795122052043,
            21.007992615276805
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51 Thái Thịnh",
        "diemDau": "20",
        "diemCuoi": "20-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007953348293103,
        "to_latitude": 21.007992615276805,
        "to_longitude": 105.81795122052043,
        "from_longitude": 105.81783269361223,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2192,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81795122052043,
          21.007992615276805,
          105.81816350258734,
          21.008102138936597
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81795122052043,
            21.007992615276805
          ],
          [
            105.81816350258734,
            21.008102138936597
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51 Thái Thịnh",
        "diemDau": "20-1",
        "diemCuoi": "20-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007992615276805,
        "to_latitude": 21.008102138936597,
        "to_longitude": 105.81816350258734,
        "from_longitude": 105.81795122052043,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2193,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81782330588514,
          21.007636601521767,
          105.81806202249096,
          21.007768370764772
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81806202249096,
            21.007636601521767
          ],
          [
            105.81794836387726,
            21.007768370764772
          ],
          [
            105.81782330588514,
            21.007724239035845
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 209 Thịnh Quang",
        "diemDau": "19",
        "diemCuoi": "19-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007636601521767,
        "to_latitude": 21.007724239035845,
        "to_longitude": 105.81782330588514,
        "from_longitude": 105.81806202249096,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2194,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81806202249096,
          21.007408743745128,
          105.81814148292452,
          21.007636601521767
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81806202249096,
            21.007636601521767
          ],
          [
            105.81814148292452,
            21.00745287556737
          ],
          [
            105.81807945684128,
            21.007408743745128
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 209 Thịnh Quang",
        "diemDau": "19",
        "diemCuoi": "19-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007636601521767,
        "to_latitude": 21.007408743745128,
        "to_longitude": 105.81807945684128,
        "from_longitude": 105.81806202249096,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2195,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83185260019012,
          21.019176767106384,
          105.83200984464817,
          21.019488481910408
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83185260019012,
            21.019488481910408
          ],
          [
            105.83200984464817,
            21.019327930093628
          ],
          [
            105.83196122964858,
            21.019176767106384
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 251 Khâm Thiên",
        "diemDau": "7",
        "diemCuoi": "7-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019488481910408,
        "to_latitude": 21.019176767106384,
        "to_longitude": 105.83196122964858,
        "from_longitude": 105.83185260019012,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2196,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83190490325903,
          21.019027794741532,
          105.83196122964858,
          21.019176767106384
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83196122964858,
            21.019176767106384
          ],
          [
            105.83190490325903,
            21.019027794741532
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 251 Khâm Thiên",
        "diemDau": "7-15",
        "diemCuoi": "7-16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019176767106384,
        "to_latitude": 21.019027794741532,
        "to_longitude": 105.83190490325903,
        "from_longitude": 105.83196122964858,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2197,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83190490325903,
          21.01898272744251,
          105.83204437812452,
          21.019027794741532
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83190490325903,
            21.019027794741532
          ],
          [
            105.83204437812452,
            21.01898272744251
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 251 Khâm Thiên",
        "diemDau": "7-16",
        "diemCuoi": "7-17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019027794741532,
        "to_latitude": 21.01898272744251,
        "to_longitude": 105.83204437812452,
        "from_longitude": 105.83190490325903,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2198,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83229114135355,
          21.019119181168254,
          105.83235014995083,
          21.01928818331661
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83235014995083,
            21.01928818331661
          ],
          [
            105.83229114135355,
            21.019119181168254
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Khâm Thiên",
        "diemDau": "7-5",
        "diemCuoi": "7-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.01928818331661,
        "to_latitude": 21.019119181168254,
        "to_longitude": 105.83229114135355,
        "from_longitude": 105.83235014995083,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2199,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83223213275629,
          21.019035305956567,
          105.83229114135355,
          21.019119181168254
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83229114135355,
            21.019119181168254
          ],
          [
            105.83225660787723,
            21.019050015423005
          ],
          [
            105.83223213275629,
            21.019035305956567
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Khâm Thiên",
        "diemDau": "7-6",
        "diemCuoi": "7-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019119181168254,
        "to_latitude": 21.019035305956567,
        "to_longitude": 105.83223213275629,
        "from_longitude": 105.83229114135355,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2200,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83216507750883,
          21.019022321261037,
          105.83223213275629,
          21.019035305956567
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83223213275629,
            21.019035305956567
          ],
          [
            105.83216507750883,
            21.019022321261037
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Khâm Thiên",
        "diemDau": "7-7",
        "diemCuoi": "7-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019035305956567,
        "to_latitude": 21.019022321261037,
        "to_longitude": 105.83216507750883,
        "from_longitude": 105.83223213275629,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2201,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83216507750883,
          21.018906050411083,
          105.83221302197896,
          21.019022321261037
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83216507750883,
            21.019022321261037
          ],
          [
            105.83221302197896,
            21.018906050411083
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Khâm Thiên",
        "diemDau": "7-13",
        "diemCuoi": "7-13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019022321261037,
        "to_latitude": 21.018906050411083,
        "to_longitude": 105.83221302197896,
        "from_longitude": 105.83216507750883,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2202,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83223213275629,
          21.018923576593807,
          105.83233640359798,
          21.019035305956567
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83223213275629,
            21.019035305956567
          ],
          [
            105.83233640359798,
            21.018923576593807
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Khâm Thiên",
        "diemDau": "7-7",
        "diemCuoi": "7-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019035305956567,
        "to_latitude": 21.018923576593807,
        "to_longitude": 105.83233640359798,
        "from_longitude": 105.83223213275629,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2203,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83231695757837,
          21.01880965636855,
          105.83233640359798,
          21.018923576593807
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83233640359798,
            21.018923576593807
          ],
          [
            105.83231695757837,
            21.01880965636855
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Khâm Thiên",
        "diemDau": "7-8",
        "diemCuoi": "7-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.018923576593807,
        "to_latitude": 21.01880965636855,
        "to_longitude": 105.83231695757837,
        "from_longitude": 105.83233640359798,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2204,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83219894038383,
          21.01871576600877,
          105.83231695757837,
          21.01880965636855
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83231695757837,
            21.01880965636855
          ],
          [
            105.83219894038383,
            21.01871576600877
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Khâm Thiên",
        "diemDau": "7-9",
        "diemCuoi": "7-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.01880965636855,
        "to_latitude": 21.01871576600877,
        "to_longitude": 105.83219894038383,
        "from_longitude": 105.83231695757837,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2205,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83219894038383,
          21.018549266958676,
          105.83221503363927,
          21.01871576600877
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83219894038383,
            21.01871576600877
          ],
          [
            105.83221503363927,
            21.018549266958676
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Khâm Thiên",
        "diemDau": "7-10",
        "diemCuoi": "7-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.01871576600877,
        "to_latitude": 21.018549266958676,
        "to_longitude": 105.83221503363927,
        "from_longitude": 105.83219894038383,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2206,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83221503363927,
          21.018476658291316,
          105.83227001892492,
          21.018549266958676
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83221503363927,
            21.018549266958676
          ],
          [
            105.83227001892492,
            21.018476658291316
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Khâm Thiên",
        "diemDau": "7-11",
        "diemCuoi": "7-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.018549266958676,
        "to_latitude": 21.018476658291316,
        "to_longitude": 105.83227001892492,
        "from_longitude": 105.83221503363927,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2207,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83245096658868,
          21.01919670622028,
          105.8327994199722,
          21.019420881165193
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8327994199722,
            21.019420881165193
          ],
          [
            105.83279036747838,
            21.01928661848646
          ],
          [
            105.83258249627957,
            21.019297885289326
          ],
          [
            105.83247487265574,
            21.01930399158397
          ],
          [
            105.83245096658868,
            21.01919670622028
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 84,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "4",
        "diemCuoi": "7-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019420881165193,
        "to_latitude": 21.01919670622028,
        "to_longitude": 105.83245096658868,
        "from_longitude": 105.8327994199722,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2208,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83252583462081,
          21.019102440873535,
          105.8327994199722,
          21.019420881165193
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8327994199722,
            21.019420881165193
          ],
          [
            105.83279036747838,
            21.01928661848646
          ],
          [
            105.83258249627957,
            21.019297885289326
          ],
          [
            105.83252583462081,
            21.019102440873535
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 64,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 231 Khâm Thiên",
        "diemDau": "4",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019420881165193,
        "to_latitude": 21.019102440873535,
        "to_longitude": 105.83252583462081,
        "from_longitude": 105.8327994199722,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2209,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83242525178558,
          21.01878196216446,
          105.83252583462081,
          21.019102440873535
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83252583462081,
            21.019102440873535
          ],
          [
            105.83242525178558,
            21.01878196216446
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Khâm Thiên",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019102440873535,
        "to_latitude": 21.01878196216446,
        "to_longitude": 105.83242525178558,
        "from_longitude": 105.83252583462081,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2210,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83244594058239,
          21.01881325894401,
          105.83252583462081,
          21.019102440873535
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83252583462081,
            21.019102440873535
          ],
          [
            105.83244594058239,
            21.018847881210586
          ],
          [
            105.83251644689373,
            21.01881325894401
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Khâm Thiên",
        "diemDau": "4-1",
        "diemCuoi": "4-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019102440873535,
        "to_latitude": 21.01881325894401,
        "to_longitude": 105.83251644689373,
        "from_longitude": 105.83252583462081,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2211,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83185260019012,
          21.01928818331661,
          105.83235014995083,
          21.019488481910408
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83185260019012,
            21.019488481910408
          ],
          [
            105.83200984464817,
            21.019327930093628
          ],
          [
            105.83235014995083,
            21.01928818331661
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 55,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khâm Thiên",
        "diemDau": "7",
        "diemCuoi": "7-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019488481910408,
        "to_latitude": 21.01928818331661,
        "to_longitude": 105.83235014995083,
        "from_longitude": 105.83185260019012,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2212,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81749104724167,
          21.007957417170335,
          105.8177337871501,
          21.008154442483022
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81773297965249,
            21.008154442483022
          ],
          [
            105.8177337871501,
            21.008072597644215
          ],
          [
            105.81749104724167,
            21.007957417170335
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 221 Thịnh Quang",
        "diemDau": "21",
        "diemCuoi": "21-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.008154442483022,
        "to_latitude": 21.007957417170335,
        "to_longitude": 105.81749104724167,
        "from_longitude": 105.81773297965249,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2213,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81749104724167,
          21.007821701843486,
          105.81756929494725,
          21.007957417170335
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81749104724167,
            21.007957417170335
          ],
          [
            105.81756929494725,
            21.007821701843486
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 221 Thịnh Quang",
        "diemDau": "21-1",
        "diemCuoi": "21-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.007957417170335,
        "to_latitude": 21.007821701843486,
        "to_longitude": 105.81756929494725,
        "from_longitude": 105.81749104724167,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2214,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8176488286185,
          21.00842853189763,
          105.81789258207819,
          21.008486461220297
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8176488286185,
            21.00842853189763
          ],
          [
            105.81789258207819,
            21.008486461220297
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 224 Thịnh Quang",
        "diemDau": "22",
        "diemCuoi": "22-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.00842853189763,
        "to_latitude": 21.008486461220297,
        "to_longitude": 105.81789258207819,
        "from_longitude": 105.8176488286185,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2215,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81757386044836,
          21.008683240465416,
          105.81768550739278,
          21.00876470766936
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81757386044836,
            21.008683240465416
          ],
          [
            105.81768550739278,
            21.008743647333684
          ],
          [
            105.81767700040879,
            21.00876470766936
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 224 Thịnh Quang",
        "diemDau": "23",
        "diemCuoi": "23-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.008683240465416,
        "to_latitude": 21.00876470766936,
        "to_longitude": 105.81767700040879,
        "from_longitude": 105.81757386044836,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2216,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81730966805165,
          21.00876957145476,
          105.8174382497844,
          21.008886711392886
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81730966805165,
            21.00876957145476
          ],
          [
            105.8174382497844,
            21.008886711392886
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 224 Thịnh Quang",
        "diemDau": "25",
        "diemCuoi": "25-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.00876957145476,
        "to_latitude": 21.008886711392886,
        "to_longitude": 105.8174382497844,
        "from_longitude": 105.81730966805165,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2217,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81870038825696,
          21.00722577953567,
          105.81877570890333,
          21.0074901215607
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81877570890333,
            21.00722577953567
          ],
          [
            105.81870038825696,
            21.0074901215607
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VH5",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Vĩnh Hồ 5",
        "from_latitude": 21.00722577953567,
        "to_latitude": 21.0074901215607,
        "to_longitude": 105.81870038825696,
        "from_longitude": 105.81877570890333,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2218,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83299388009638,
          21.019033588083868,
          105.83306764087216,
          21.019393340111606
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83306764087216,
            21.019393340111606
          ],
          [
            105.83301399666342,
            21.019270657177586
          ],
          [
            105.83299388009638,
            21.019033588083868
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 53,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Khâm Thiên",
        "diemDau": "3+1",
        "diemCuoi": "3-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.019393340111606,
        "to_latitude": 21.019033588083868,
        "to_longitude": 105.83299388009638,
        "from_longitude": 105.83306764087216,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2219,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83220497535574,
          21.01961961475323,
          105.83250270055883,
          21.01969097099927
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83220497535574,
            21.01969097099927
          ],
          [
            105.83250270055883,
            21.01961961475323
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngo Kien Thiet",
        "diemDau": "6-2",
        "diemCuoi": "6-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.01969097099927,
        "to_latitude": 21.01961961475323,
        "to_longitude": 105.83250270055883,
        "from_longitude": 105.83220497535574,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2220,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83250270055883,
          21.0196111646677,
          105.83266095088749,
          21.019683459817276
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83250270055883,
            21.01961961475323
          ],
          [
            105.83264653403208,
            21.0196111646677
          ],
          [
            105.83266095088749,
            21.019683459817276
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngo Kien Thiet",
        "diemDau": "6-3",
        "diemCuoi": "6-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.01961961475323,
        "to_latitude": 21.019683459817276,
        "to_longitude": 105.83266095088749,
        "from_longitude": 105.83250270055883,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2221,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83588207001075,
          21.01755693458324,
          105.8359694142882,
          21.01779492052949
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8359694142882,
            21.01779492052949
          ],
          [
            105.83588207001075,
            21.01755693458324
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "39",
        "diemCuoi": "40",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01779492052949,
        "to_latitude": 21.01755693458324,
        "to_longitude": 105.83588207001075,
        "from_longitude": 105.8359694142882,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2222,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83566133695344,
          21.01755693458324,
          105.83588207001075,
          21.017593145692
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83588207001075,
            21.01755693458324
          ],
          [
            105.83566133695344,
            21.017593145692
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "40",
        "diemCuoi": "40-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01755693458324,
        "to_latitude": 21.017593145692,
        "to_longitude": 105.83566133695344,
        "from_longitude": 105.83588207001075,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2223,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83550040441705,
          21.017528047850885,
          105.83566133695344,
          21.017608168267078
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83566133695344,
            21.017593145692
          ],
          [
            105.83554331975888,
            21.017608168267078
          ],
          [
            105.83550040441705,
            21.017528047850885
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "40-1",
        "diemCuoi": "40-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.017593145692,
        "to_latitude": 21.017528047850885,
        "to_longitude": 105.83550040441705,
        "from_longitude": 105.83566133695344,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2224,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83585425368562,
          21.017348910990044,
          105.83588207001075,
          21.01755693458324
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83588207001075,
            21.01755693458324
          ],
          [
            105.83585425368562,
            21.017348910990044
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "40",
        "diemCuoi": "41",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01755693458324,
        "to_latitude": 21.017348910990044,
        "to_longitude": 105.83585425368562,
        "from_longitude": 105.83588207001075,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2225,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83585425368562,
          21.017187535604783,
          105.83596979098749,
          21.017348910990044
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83585425368562,
            21.017348910990044
          ],
          [
            105.83596979098749,
            21.017187535604783
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "41",
        "diemCuoi": "42",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.017348910990044,
        "to_latitude": 21.017187535604783,
        "to_longitude": 105.83596979098749,
        "from_longitude": 105.83585425368562,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2226,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8358356805375,
          21.01691963204038,
          105.83596979098749,
          21.017187535604783
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83596979098749,
            21.017187535604783
          ],
          [
            105.8358356805375,
            21.01691963204038
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "42",
        "diemCuoi": "43",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.017187535604783,
        "to_latitude": 21.01691963204038,
        "to_longitude": 105.8358356805375,
        "from_longitude": 105.83596979098749,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2227,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83574180322162,
          21.016751879096276,
          105.8358356805375,
          21.01691963204038
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8358356805375,
            21.01691963204038
          ],
          [
            105.83574180322162,
            21.016751879096276
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "43",
        "diemCuoi": "44",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01691963204038,
        "to_latitude": 21.016751879096276,
        "to_longitude": 105.83574180322162,
        "from_longitude": 105.8358356805375,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2228,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8352429123211,
          21.017775920248774,
          105.83536042217412,
          21.01801819180807
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83536042217412,
            21.01801819180807
          ],
          [
            105.8352429123211,
            21.017775920248774
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "48",
        "diemCuoi": "49",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01801819180807,
        "to_latitude": 21.017775920248774,
        "to_longitude": 105.8352429123211,
        "from_longitude": 105.83536042217412,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2229,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83514635279748,
          21.01769830368403,
          105.8352429123211,
          21.017775920248774
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8352429123211,
            21.017775920248774
          ],
          [
            105.83514635279748,
            21.01769830368403
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "49",
        "diemCuoi": "50",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.017775920248774,
        "to_latitude": 21.01769830368403,
        "to_longitude": 105.83514635279748,
        "from_longitude": 105.8352429123211,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2230,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83514635279748,
          21.017666547335953,
          105.83526897395579,
          21.01769830368403
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83514635279748,
            21.01769830368403
          ],
          [
            105.83526897395579,
            21.017666547335953
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "50",
        "diemCuoi": "51",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01769830368403,
        "to_latitude": 21.017666547335953,
        "to_longitude": 105.83526897395579,
        "from_longitude": 105.83514635279748,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2231,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.835140988382,
          21.017412874678985,
          105.83526897395579,
          21.017666547335953
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83526897395579,
            21.017666547335953
          ],
          [
            105.835140988382,
            21.017412874678985
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "51",
        "diemCuoi": "52",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.017666547335953,
        "to_latitude": 21.017412874678985,
        "to_longitude": 105.835140988382,
        "from_longitude": 105.83526897395579,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2232,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83497561603811,
          21.01769830368403,
          105.83514635279748,
          21.017842023236692
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83514635279748,
            21.01769830368403
          ],
          [
            105.83497561603811,
            21.017842023236692
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "50",
        "diemCuoi": "50-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01769830368403,
        "to_latitude": 21.017842023236692,
        "to_longitude": 105.83497561603811,
        "from_longitude": 105.83514635279748,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2233,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83481107666802,
          21.017842023236692,
          105.83497561603811,
          21.01792364198557
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83497561603811,
            21.017842023236692
          ],
          [
            105.83481107666802,
            21.01792364198557
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "50-1",
        "diemCuoi": "50-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.017842023236692,
        "to_latitude": 21.01792364198557,
        "to_longitude": 105.83481107666802,
        "from_longitude": 105.83497561603811,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2234,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83473329260758,
          21.01776340144998,
          105.83481107666802,
          21.01792364198557
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83481107666802,
            21.01792364198557
          ],
          [
            105.83473329260758,
            21.01776340144998
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH1",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "diemDau": "50-2",
        "diemCuoi": "50-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên 1",
        "from_latitude": 21.01792364198557,
        "to_latitude": 21.01776340144998,
        "to_longitude": 105.83473329260758,
        "from_longitude": 105.83481107666802,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2235,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82418550568549,
          21.016944252482066,
          105.82448591309631,
          21.01708112536847
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82418550568549,
            21.01708112536847
          ],
          [
            105.82448591309631,
            21.016944252482066
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "AP",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xung quanh sân VĐ Hoàng Cầu",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Ao Phe",
        "from_latitude": 21.01708112536847,
        "to_latitude": 21.016944252482066,
        "to_longitude": 105.82448591309631,
        "from_longitude": 105.82418550568549,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2236,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82448591309631,
          21.01680737947007,
          105.82478632050716,
          21.016944252482066
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82448591309631,
            21.016944252482066
          ],
          [
            105.82478632050716,
            21.01680737947007
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "AP",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xung quanh sân VĐ Hoàng Cầu",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Ao Phe",
        "from_latitude": 21.016944252482066,
        "to_latitude": 21.01680737947007,
        "to_longitude": 105.82478632050716,
        "from_longitude": 105.82448591309631,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2237,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82478632050716,
          21.01667050633244,
          105.82508672791799,
          21.01680737947007
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82478632050716,
            21.01680737947007
          ],
          [
            105.82508672791799,
            21.01667050633244
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "AP",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xung quanh sân VĐ Hoàng Cầu",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Ao Phe",
        "from_latitude": 21.01680737947007,
        "to_latitude": 21.01667050633244,
        "to_longitude": 105.82508672791799,
        "from_longitude": 105.82478632050716,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2238,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82508672791799,
          21.01667050633244,
          105.82519669848932,
          21.01671807810888
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82508672791799,
            21.01667050633244
          ],
          [
            105.82513768988305,
            21.01668052144546
          ],
          [
            105.82519669848932,
            21.01671807810888
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "AP",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xung quanh sân VĐ Hoàng Cầu",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Ao Phe",
        "from_latitude": 21.01667050633244,
        "to_latitude": 21.01671807810888,
        "to_longitude": 105.82519669848932,
        "from_longitude": 105.82508672791799,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2239,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82519669848932,
          21.01671807810888,
          105.82537640648887,
          21.016989320398014
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82519669848932,
            21.01671807810888
          ],
          [
            105.82537640648887,
            21.016989320398014
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "AP",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xung quanh sân VĐ Hoàng Cầu",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Ao Phe",
        "from_latitude": 21.01671807810888,
        "to_latitude": 21.016989320398014,
        "to_longitude": 105.82537640648887,
        "from_longitude": 105.82519669848932,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2240,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82537640648887,
          21.016989320398014,
          105.82553197460979,
          21.01723802827935
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82537640648887,
            21.016989320398014
          ],
          [
            105.82553197460979,
            21.01723802827935
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "AP",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xung quanh sân VĐ Hoàng Cầu",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Ao Phe",
        "from_latitude": 21.016989320398014,
        "to_latitude": 21.01723802827935,
        "to_longitude": 105.82553197460979,
        "from_longitude": 105.82537640648887,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2241,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82553197460979,
          21.01723802827935,
          105.82568754273068,
          21.01748673574591
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82553197460979,
            21.01723802827935
          ],
          [
            105.82568754273068,
            21.01748673574591
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "AP",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xung quanh sân VĐ Hoàng Cầu",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Ao Phe",
        "from_latitude": 21.01723802827935,
        "to_latitude": 21.01748673574591,
        "to_longitude": 105.82568754273068,
        "from_longitude": 105.82553197460979,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2242,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82564462738884,
          21.01748673574591,
          105.82568754273068,
          21.017646976578774
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82568754273068,
            21.01748673574591
          ],
          [
            105.82567949610744,
            21.0175668561843
          ],
          [
            105.82564462738884,
            21.017646976578774
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "AP",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xung quanh sân VĐ Hoàng Cầu",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Ao Phe",
        "from_latitude": 21.01748673574591,
        "to_latitude": 21.017646976578774,
        "to_longitude": 105.82564462738884,
        "from_longitude": 105.82568754273068,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2243,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82538177090434,
          21.017646976578774,
          105.82564462738884,
          21.017752134531186
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82564462738884,
            21.017646976578774
          ],
          [
            105.82538177090434,
            21.017752134531186
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "AP",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xung quanh sân VĐ Hoàng Cầu",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Ao Phe",
        "from_latitude": 21.017646976578774,
        "to_latitude": 21.017752134531186,
        "to_longitude": 105.82538177090434,
        "from_longitude": 105.82564462738884,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2244,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82510282117343,
          21.017752134531186,
          105.82538177090434,
          21.017902360049415
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82538177090434,
            21.017752134531186
          ],
          [
            105.82510282117343,
            21.017902360049415
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "AP",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xung quanh sân VĐ Hoàng Cầu",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Ao Phe",
        "from_latitude": 21.017752134531186,
        "to_latitude": 21.017902360049415,
        "to_longitude": 105.82510282117343,
        "from_longitude": 105.82538177090434,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2245,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82485605794437,
          21.017902360049415,
          105.82510282117343,
          21.018057592926095
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82510282117343,
            21.017902360049415
          ],
          [
            105.82485605794437,
            21.018057592926095
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "AP",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xung quanh sân VĐ Hoàng Cầu",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Ao Phe",
        "from_latitude": 21.017902360049415,
        "to_latitude": 21.018057592926095,
        "to_longitude": 105.82485605794437,
        "from_longitude": 105.82510282117343,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2246,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82517255860165,
          21.01671807810888,
          105.82519669848932,
          21.016773161198277
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82519669848932,
            21.016773161198277
          ],
          [
            105.82517255860165,
            21.016735604548735
          ],
          [
            105.82519669848932,
            21.01671807810888
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "AP",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xung quanh sân VĐ Hoàng Cầu",
        "diemDau": "Tủ điện",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Ao Phe",
        "from_latitude": 21.016773161198277,
        "to_latitude": 21.01671807810888,
        "to_longitude": 105.82519669848932,
        "from_longitude": 105.82519669848932,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2247,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82519669848932,
          21.016773161198277,
          105.82521010952803,
          21.016825740491416
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82521010952803,
            21.016825740491416
          ],
          [
            105.82519669848932,
            21.016773161198277
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "AP",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xung quanh sân VĐ Hoàng Cầu",
        "diemDau": "TBA",
        "diemCuoi": "tủ điện",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Ao Phe",
        "from_latitude": 21.016825740491416,
        "to_latitude": 21.016773161198277,
        "to_longitude": 105.82519669848932,
        "from_longitude": 105.82521010952803,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2248,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81461739558584,
          21.008550012363866,
          105.81473241306746,
          21.008678858617866
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81461739558584,
            21.008678858617866
          ],
          [
            105.81473241306746,
            21.008550012363866
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "01",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.008678858617866,
        "to_latitude": 21.008550012363866,
        "to_longitude": 105.81473241306746,
        "from_longitude": 105.81461739558584,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2249,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81473241306746,
          21.008317761422425,
          105.81493902294825,
          21.008550012363866
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81473241306746,
            21.008550012363866
          ],
          [
            105.81493902294825,
            21.008317761422425
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "02",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.008550012363866,
        "to_latitude": 21.008317761422425,
        "to_longitude": 105.81493902294825,
        "from_longitude": 105.81473241306746,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2250,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81510728467941,
          21.007974719268326,
          105.81522737718767,
          21.00812124232172
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81510728467941,
            21.00812124232172
          ],
          [
            105.81522737718767,
            21.007974719268326
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00812124232172,
        "to_latitude": 21.007974719268326,
        "to_longitude": 105.81522737718767,
        "from_longitude": 105.81510728467941,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2251,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81522737718767,
          21.00775976704494,
          105.81539946848582,
          21.007974719268326
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81522737718767,
            21.007974719268326
          ],
          [
            105.81539946848582,
            21.00775976704494
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.007974719268326,
        "to_latitude": 21.00775976704494,
        "to_longitude": 105.81539946848582,
        "from_longitude": 105.81522737718767,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2252,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81539946848582,
          21.00758330408431,
          105.815536654266,
          21.00775976704494
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81539946848582,
            21.00775976704494
          ],
          [
            105.815536654266,
            21.00758330408431
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00775976704494,
        "to_latitude": 21.00758330408431,
        "to_longitude": 105.815536654266,
        "from_longitude": 105.81539946848582,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2253,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.815536654266,
          21.007414986701807,
          105.81566286760506,
          21.00758330408431
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.815536654266,
            21.00758330408431
          ],
          [
            105.81566286760506,
            21.007414986701807
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00758330408431,
        "to_latitude": 21.007414986701807,
        "to_longitude": 105.81566286760506,
        "from_longitude": 105.815536654266,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2254,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81566286760506,
          21.00722908394661,
          105.81580133047395,
          21.007414986701807
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81566286760506,
            21.007414986701807
          ],
          [
            105.81580133047395,
            21.00722908394661
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.007414986701807,
        "to_latitude": 21.00722908394661,
        "to_longitude": 105.81580133047395,
        "from_longitude": 105.81566286760506,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2255,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81580133047395,
          21.00703436314667,
          105.81594189759736,
          21.00722908394661
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81580133047395,
            21.00722908394661
          ],
          [
            105.81594189759736,
            21.00703436314667
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00722908394661,
        "to_latitude": 21.00703436314667,
        "to_longitude": 105.81594189759736,
        "from_longitude": 105.81580133047395,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2256,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81594189759736,
          21.00683936072422,
          105.81608201644235,
          21.00703436314667
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81594189759736,
            21.00703436314667
          ],
          [
            105.81608201644235,
            21.00683936072422
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00703436314667,
        "to_latitude": 21.00683936072422,
        "to_longitude": 105.81608201644235,
        "from_longitude": 105.81594189759736,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2257,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81608201644235,
          21.006649893431053,
          105.81621482063635,
          21.00683936072422
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81608201644235,
            21.00683936072422
          ],
          [
            105.81621482063635,
            21.006649893431053
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00683936072422,
        "to_latitude": 21.006649893431053,
        "to_longitude": 105.81621482063635,
        "from_longitude": 105.81608201644235,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2258,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81621482063635,
          21.006450849402647,
          105.81634826016048,
          21.006649893431053
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81621482063635,
            21.006649893431053
          ],
          [
            105.81634826016048,
            21.006450849402647
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.006649893431053,
        "to_latitude": 21.006450849402647,
        "to_longitude": 105.81634826016048,
        "from_longitude": 105.81621482063635,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2259,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81634826016048,
          21.006260751630208,
          105.81648002728431,
          21.006450849402647
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81634826016048,
            21.006450849402647
          ],
          [
            105.81648002728431,
            21.006260751630208
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.006450849402647,
        "to_latitude": 21.006260751630208,
        "to_longitude": 105.81648002728431,
        "from_longitude": 105.81634826016048,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2260,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81648002728431,
          21.006047223679577,
          105.81661832350458,
          21.006260751630208
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81648002728431,
            21.006260751630208
          ],
          [
            105.81661832350458,
            21.006047223679577
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.006260751630208,
        "to_latitude": 21.006047223679577,
        "to_longitude": 105.81661832350458,
        "from_longitude": 105.81648002728431,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2261,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81661832350458,
          21.005830631295886,
          105.81677120941774,
          21.006047223679577
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81661832350458,
            21.006047223679577
          ],
          [
            105.81677120941774,
            21.005830631295886
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.006047223679577,
        "to_latitude": 21.005830631295886,
        "to_longitude": 105.81677120941774,
        "from_longitude": 105.81661832350458,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2262,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81677120941774,
          21.00568600164012,
          105.81686871532327,
          21.005830631295886
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81677120941774,
            21.005830631295886
          ],
          [
            105.81686871532327,
            21.00568600164012
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.005830631295886,
        "to_latitude": 21.00568600164012,
        "to_longitude": 105.81686871532327,
        "from_longitude": 105.81677120941774,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2263,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81686871532327,
          21.005497184595523,
          105.81700258031465,
          21.00568600164012
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81686871532327,
            21.00568600164012
          ],
          [
            105.81700258031465,
            21.005497184595523
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00568600164012,
        "to_latitude": 21.005497184595523,
        "to_longitude": 105.81700258031465,
        "from_longitude": 105.81686871532327,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2264,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81700258031465,
          21.005301042814576,
          105.81714940088006,
          21.005497184595523
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81700258031465,
            21.005497184595523
          ],
          [
            105.81714940088006,
            21.005301042814576
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.005497184595523,
        "to_latitude": 21.005301042814576,
        "to_longitude": 105.81714940088006,
        "from_longitude": 105.81700258031465,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2265,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81714940088006,
          21.00508797705573,
          105.81729002730722,
          21.005301042814576
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81714940088006,
            21.005301042814576
          ],
          [
            105.81729002730722,
            21.00508797705573
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.005301042814576,
        "to_latitude": 21.00508797705573,
        "to_longitude": 105.81729002730722,
        "from_longitude": 105.81714940088006,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2266,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81493902294825,
          21.008202131377104,
          105.81503802664206,
          21.008317761422425
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81493902294825,
            21.008317761422425
          ],
          [
            105.81503802664206,
            21.008202131377104
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "21",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.008317761422425,
        "to_latitude": 21.008202131377104,
        "to_longitude": 105.81503802664206,
        "from_longitude": 105.81493902294825,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2267,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81503802664206,
          21.00812124232172,
          105.81537109631168,
          21.00852611989624
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81537109631168,
            21.00852611989624
          ],
          [
            105.81503802664206,
            21.008202131377104
          ],
          [
            105.81510728467941,
            21.00812124232172
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "21",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00852611989624,
        "to_latitude": 21.00812124232172,
        "to_longitude": 105.81510728467941,
        "from_longitude": 105.81537109631168,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2268,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81686871532327,
          21.00568600164012,
          105.8169468941057,
          21.005699173338012
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81686871532327,
            21.00568600164012
          ],
          [
            105.8169468941057,
            21.005699173338012
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 210 Đường Láng",
        "diemDau": "16",
        "diemCuoi": "16-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00568600164012,
        "to_latitude": 21.005699173338012,
        "to_longitude": 105.8169468941057,
        "from_longitude": 105.81686871532327,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2269,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81677120941774,
          21.005800583773635,
          105.81684094685497,
          21.005830631295886
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81677120941774,
            21.005830631295886
          ],
          [
            105.81684094685497,
            21.005800583773635
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 5,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 218 Đường Láng",
        "diemDau": "15",
        "diemCuoi": "15-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.005830631295886,
        "to_latitude": 21.005800583773635,
        "to_longitude": 105.81684094685497,
        "from_longitude": 105.81677120941774,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2270,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81684094685497,
          21.005800583773635,
          105.8170662524131,
          21.00591200997471
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81684094685497,
            21.005800583773635
          ],
          [
            105.8170662524131,
            21.00591200997471
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 218 Đường Láng",
        "diemDau": "15-1",
        "diemCuoi": "15-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.005800583773635,
        "to_latitude": 21.00591200997471,
        "to_longitude": 105.8170662524131,
        "from_longitude": 105.81684094685497,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2271,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8170662524131,
          21.00591200997471,
          105.81728753465065,
          21.006013420264846
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8170662524131,
            21.00591200997471
          ],
          [
            105.81728753465065,
            21.006013420264846
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 218 Đường Láng",
        "diemDau": "15-2",
        "diemCuoi": "15-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00591200997471,
        "to_latitude": 21.006013420264846,
        "to_longitude": 105.81728753465065,
        "from_longitude": 105.8170662524131,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2272,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81728753465065,
          21.006013420264846,
          105.81750345248167,
          21.00614112201386
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81728753465065,
            21.006013420264846
          ],
          [
            105.81750345248167,
            21.00614112201386
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 218 Đường Láng",
        "diemDau": "15-3",
        "diemCuoi": "15-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.006013420264846,
        "to_latitude": 21.00614112201386,
        "to_longitude": 105.81750345248167,
        "from_longitude": 105.81728753465065,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2273,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81750345248167,
          21.00614112201386,
          105.81772071140757,
          21.00626882365441
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81750345248167,
            21.00614112201386
          ],
          [
            105.81772071140757,
            21.00626882365441
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 218 Đường Láng",
        "diemDau": "15-4",
        "diemCuoi": "15-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00614112201386,
        "to_latitude": 21.00626882365441,
        "to_longitude": 105.81772071140757,
        "from_longitude": 105.81750345248167,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2274,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81772071140757,
          21.00626882365441,
          105.8178923727839,
          21.006426572587912
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81772071140757,
            21.00626882365441
          ],
          [
            105.8178923727839,
            21.006426572587912
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 218 Đường Láng",
        "diemDau": "15-5",
        "diemCuoi": "15-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00626882365441,
        "to_latitude": 21.006426572587912,
        "to_longitude": 105.8178923727839,
        "from_longitude": 105.81772071140757,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2275,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81634826016048,
          21.006420938702135,
          105.81647379258527,
          21.00645797306455
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81634826016048,
            21.006450849402647
          ],
          [
            105.81638832406944,
            21.006420938702135
          ],
          [
            105.81647379258527,
            21.00645797306455
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 212 Đường Láng",
        "diemDau": "12",
        "diemCuoi": "12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.006450849402647,
        "to_latitude": 21.00645797306455,
        "to_longitude": 105.81647379258527,
        "from_longitude": 105.81634826016048,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2276,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81621482063635,
          21.006583695369788,
          105.81651774064241,
          21.006712022614206
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81621482063635,
            21.006649893431053
          ],
          [
            105.81629444675356,
            21.006583695369788
          ],
          [
            105.81651774064241,
            21.006712022614206
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 242 Đường Láng",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.006649893431053,
        "to_latitude": 21.006712022614206,
        "to_longitude": 105.81651774064241,
        "from_longitude": 105.81621482063635,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2277,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81651774064241,
          21.006712022614206,
          105.81674706951213,
          21.00688479473423
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81651774064241,
            21.006712022614206
          ],
          [
            105.81662972287403,
            21.006786514968983
          ],
          [
            105.81671823577443,
            21.006839097783192
          ],
          [
            105.81674706951213,
            21.00688479473423
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 242 Đường Láng",
        "diemDau": "11-1",
        "diemCuoi": "11-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.006712022614206,
        "to_latitude": 21.00688479473423,
        "to_longitude": 105.81674706951213,
        "from_longitude": 105.81651774064241,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2278,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81663575784593,
          21.00688479473423,
          105.81674706951213,
          21.00698996027399
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81674706951213,
            21.00688479473423
          ],
          [
            105.81671823577443,
            21.006890428607512
          ],
          [
            105.81663575784593,
            21.00698996027399
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 242 Đường Láng",
        "diemDau": "11-2",
        "diemCuoi": "11-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00688479473423,
        "to_latitude": 21.00698996027399,
        "to_longitude": 105.81663575784593,
        "from_longitude": 105.81674706951213,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2279,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81663575784593,
          21.00698057050025,
          105.81680741922226,
          21.007132684817325
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81663575784593,
            21.00698996027399
          ],
          [
            105.81668739036742,
            21.00698057050025
          ],
          [
            105.81680741922226,
            21.007132684817325
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 242 Đường Láng",
        "diemDau": "11-3",
        "diemCuoi": "11-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00698996027399,
        "to_latitude": 21.007132684817325,
        "to_longitude": 105.81680741922226,
        "from_longitude": 105.81663575784593,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2280,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81680741922226,
          21.007132684817325,
          105.81697798318409,
          21.007327467929294
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81680741922226,
            21.007132684817325
          ],
          [
            105.81693147138871,
            21.007301074566392
          ],
          [
            105.81697798318409,
            21.007327467929294
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 242 Đường Láng",
        "diemDau": "11-4",
        "diemCuoi": "11-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.007132684817325,
        "to_latitude": 21.007327467929294,
        "to_longitude": 105.81697798318409,
        "from_longitude": 105.81680741922226,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2281,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81684608485128,
          21.007301074566392,
          105.81693147138871,
          21.007418856498617
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81693147138871,
            21.007301074566392
          ],
          [
            105.81684608485128,
            21.007418856498617
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 242 Đường Láng",
        "diemDau": "11-4",
        "diemCuoi": "11-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.007301074566392,
        "to_latitude": 21.007418856498617,
        "to_longitude": 105.81684608485128,
        "from_longitude": 105.81693147138871,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2282,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81684608485128,
          21.007418856498617,
          105.8169912317954,
          21.00753134539563
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81684608485128,
            21.007418856498617
          ],
          [
            105.8169912317954,
            21.00753134539563
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 242 Đường Láng",
        "diemDau": "11-8",
        "diemCuoi": "11-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.007418856498617,
        "to_latitude": 21.00753134539563,
        "to_longitude": 105.8169912317954,
        "from_longitude": 105.81684608485128,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2283,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81697798318409,
          21.007131432848006,
          105.81707832232996,
          21.007327467929294
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81697798318409,
            21.007327467929294
          ],
          [
            105.81707832232996,
            21.007131432848006
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 242 Đường Láng",
        "diemDau": "11-6",
        "diemCuoi": "11-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.007327467929294,
        "to_latitude": 21.007131432848006,
        "to_longitude": 105.81707832232996,
        "from_longitude": 105.81697798318409,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2284,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81693147138871,
          21.00708267556627,
          105.81697052027687,
          21.007301074566392
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81693147138871,
            21.007301074566392
          ],
          [
            105.81697052027687,
            21.00708267556627
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 242 Đường Láng",
        "diemDau": "11-4",
        "diemCuoi": "11-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.007301074566392,
        "to_latitude": 21.00708267556627,
        "to_longitude": 105.81697052027687,
        "from_longitude": 105.81693147138871,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2285,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8178923727839,
          21.006426572587912,
          105.81810426730333,
          21.006546762139738
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8178923727839,
            21.006426572587912
          ],
          [
            105.81810426730333,
            21.006546762139738
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 218 Đường Láng",
        "diemDau": "15-6",
        "diemCuoi": "15-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.006426572587912,
        "to_latitude": 21.006546762139738,
        "to_longitude": 105.81810426730333,
        "from_longitude": 105.8178923727839,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2286,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81566286760506,
          21.007414986701807,
          105.81583780064742,
          21.007519542764292
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81566286760506,
            21.007414986701807
          ],
          [
            105.81583780064742,
            21.007519542764292
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "diemDau": "07",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.007414986701807,
        "to_latitude": 21.007519542764292,
        "to_longitude": 105.81583780064742,
        "from_longitude": 105.81566286760506,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2287,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81583780064742,
          21.007519542764292,
          105.81595179453036,
          21.007598416591172
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81583780064742,
            21.007519542764292
          ],
          [
            105.81595179453036,
            21.007598416591172
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "diemDau": "7-1",
        "diemCuoi": "7-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.007519542764292,
        "to_latitude": 21.007598416591172,
        "to_longitude": 105.81595179453036,
        "from_longitude": 105.81583780064742,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2288,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81611750573889,
          21.007229726505443,
          105.8162715358456,
          21.00737055175817
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81611750573889,
            21.00737055175817
          ],
          [
            105.8162715358456,
            21.007229726505443
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "diemDau": "7-3",
        "diemCuoi": "7-3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00737055175817,
        "to_latitude": 21.007229726505443,
        "to_longitude": 105.8162715358456,
        "from_longitude": 105.81611750573889,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2289,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81595179453036,
          21.00737055175817,
          105.81611750573889,
          21.007598416591172
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81595179453036,
            21.007598416591172
          ],
          [
            105.81604734825528,
            21.00749168652468
          ],
          [
            105.81611750573889,
            21.00737055175817
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "diemDau": "7-2",
        "diemCuoi": "7-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.007598416591172,
        "to_latitude": 21.00737055175817,
        "to_longitude": 105.81611750573889,
        "from_longitude": 105.81595179453036,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2290,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81604734825528,
          21.00749168652468,
          105.81622269764705,
          21.007643487330043
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81604734825528,
            21.00749168652468
          ],
          [
            105.81622269764705,
            21.007643487330043
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "diemDau": "7-2",
        "diemCuoi": "7-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00749168652468,
        "to_latitude": 21.007643487330043,
        "to_longitude": 105.81622269764705,
        "from_longitude": 105.81604734825528,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2291,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81622269764705,
          21.007531308833386,
          105.81630155627283,
          21.007643487330043
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81622269764705,
            21.007643487330043
          ],
          [
            105.81630155627283,
            21.007531308833386
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "diemDau": "7-4",
        "diemCuoi": "7-4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.007643487330043,
        "to_latitude": 21.007531308833386,
        "to_longitude": 105.81630155627283,
        "from_longitude": 105.81622269764705,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2292,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81613016143504,
          21.007643487330043,
          105.81622269764705,
          21.007712345377744
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81622269764705,
            21.007643487330043
          ],
          [
            105.81617475317692,
            21.007693252925016
          ],
          [
            105.81613016143504,
            21.007712345377744
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "diemDau": "7-4",
        "diemCuoi": "7-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.007643487330043,
        "to_latitude": 21.007712345377744,
        "to_longitude": 105.81613016143504,
        "from_longitude": 105.81622269764705,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2293,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81613016143504,
          21.007712345377744,
          105.81637424245633,
          21.0078287780043
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81613016143504,
            21.007712345377744
          ],
          [
            105.81637424245633,
            21.0078287780043
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "diemDau": "7-5",
        "diemCuoi": "7-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.007712345377744,
        "to_latitude": 21.0078287780043,
        "to_longitude": 105.81637424245633,
        "from_longitude": 105.81613016143504,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2294,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81637424245633,
          21.00763489893448,
          105.81647565162912,
          21.0078287780043
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81637424245633,
            21.0078287780043
          ],
          [
            105.81647565162912,
            21.00763489893448
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "diemDau": "7-6",
        "diemCuoi": "7-6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.0078287780043,
        "to_latitude": 21.00763489893448,
        "to_longitude": 105.81647565162912,
        "from_longitude": 105.81637424245633,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2295,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81637424245633,
          21.0078287780043,
          105.81663039341247,
          21.007890124190528
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81637424245633,
            21.0078287780043
          ],
          [
            105.81663039341247,
            21.007890124190528
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "diemDau": "7-6",
        "diemCuoi": "7-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.0078287780043,
        "to_latitude": 21.007890124190528,
        "to_longitude": 105.81663039341247,
        "from_longitude": 105.81637424245633,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2296,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81663039341247,
          21.00772555609598,
          105.81670779445905,
          21.007890124190528
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81663039341247,
            21.007890124190528
          ],
          [
            105.81670779445905,
            21.00772555609598
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "diemDau": "7-7",
        "diemCuoi": "7-7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.007890124190528,
        "to_latitude": 21.00772555609598,
        "to_longitude": 105.81670779445905,
        "from_longitude": 105.81663039341247,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2297,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81663039341247,
          21.007884803350958,
          105.81683155909194,
          21.007919858304454
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81663039341247,
            21.007890124190528
          ],
          [
            105.81667230295561,
            21.00791485045433
          ],
          [
            105.81671655940131,
            21.007919858304454
          ],
          [
            105.81680507230172,
            21.007884803350958
          ],
          [
            105.81683155909194,
            21.007901391854283
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "diemDau": "7-7",
        "diemCuoi": "7-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.007890124190528,
        "to_latitude": 21.007901391854283,
        "to_longitude": 105.81683155909194,
        "from_longitude": 105.81663039341247,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2298,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81683155909194,
          21.007804990701292,
          105.81689459100083,
          21.007901391854283
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81683155909194,
            21.007901391854283
          ],
          [
            105.81689459100083,
            21.007804990701292
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "diemDau": "7-8",
        "diemCuoi": "7-8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.007901391854283,
        "to_latitude": 21.007804990701292,
        "to_longitude": 105.81689459100083,
        "from_longitude": 105.81683155909194,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2299,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81539825366733,
          21.00775976704494,
          105.81547631399842,
          21.007882965654353
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81539946848582,
            21.00775976704494
          ],
          [
            105.81539825366733,
            21.00780968556564
          ],
          [
            105.81547631399842,
            21.007882965654353
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 318 Đường Láng",
        "diemDau": "05",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00775976704494,
        "to_latitude": 21.007882965654353,
        "to_longitude": 105.81547631399842,
        "from_longitude": 105.81539946848582,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2300,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81522737718767,
          21.007968684832374,
          105.81535614406715,
          21.00803433036558
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81522737718767,
            21.007974719268326
          ],
          [
            105.8152614610096,
            21.007968684832374
          ],
          [
            105.81535614406715,
            21.00803433036558
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 326 Đường Láng",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.007974719268326,
        "to_latitude": 21.00803433036558,
        "to_longitude": 105.81535614406715,
        "from_longitude": 105.81522737718767,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2301,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81515882269761,
          21.008408775044746,
          105.81537109631168,
          21.00852611989624
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81537109631168,
            21.00852611989624
          ],
          [
            105.81515882269761,
            21.008408775044746
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "diemDau": "21",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00852611989624,
        "to_latitude": 21.008408775044746,
        "to_longitude": 105.81515882269761,
        "from_longitude": 105.81537109631168,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2302,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81537109631168,
          21.00852611989624,
          105.81554275768801,
          21.008693882090775
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81537109631168,
            21.00852611989624
          ],
          [
            105.81554275768801,
            21.008693882090775
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00852611989624,
        "to_latitude": 21.008693882090775,
        "to_longitude": 105.81554275768801,
        "from_longitude": 105.81537109631168,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2303,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81554275768801,
          21.008693882090775,
          105.8157247668694,
          21.008873689984725
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81554275768801,
            21.008693882090775
          ],
          [
            105.81568122671882,
            21.00882502442619
          ],
          [
            105.8157247668694,
            21.008873689984725
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.008693882090775,
        "to_latitude": 21.008873689984725,
        "to_longitude": 105.8157247668694,
        "from_longitude": 105.81554275768801,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2304,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8157247668694,
          21.008873689984725,
          105.81581153402898,
          21.009006007064386
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8157247668694,
            21.008873689984725
          ],
          [
            105.81581153402898,
            21.009006007064386
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.008873689984725,
        "to_latitude": 21.009006007064386,
        "to_longitude": 105.81581153402898,
        "from_longitude": 105.8157247668694,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2305,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8157247668694,
          21.00861375688697,
          105.81594911234957,
          21.008873689984725
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8157247668694,
            21.008873689984725
          ],
          [
            105.81589446234211,
            21.008704836709608
          ],
          [
            105.81594911234957,
            21.00861375688697
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "diemDau": "23",
        "diemCuoi": "23-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.008873689984725,
        "to_latitude": 21.00861375688697,
        "to_longitude": 105.81594911234957,
        "from_longitude": 105.8157247668694,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2306,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81594911234957,
          21.00861375688697,
          105.81623376178318,
          21.008880733146647
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81594911234957,
            21.00861375688697
          ],
          [
            105.81623376178318,
            21.00887134674928
          ],
          [
            105.81621777035762,
            21.008880733146647
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "diemDau": "23-1",
        "diemCuoi": "23-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00861375688697,
        "to_latitude": 21.008880733146647,
        "to_longitude": 105.81621777035762,
        "from_longitude": 105.81594911234957,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2307,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81579433504388,
          21.00849635975455,
          105.81594911234957,
          21.00861375688697
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81594911234957,
            21.00861375688697
          ],
          [
            105.81589446234211,
            21.008594664551282
          ],
          [
            105.81579433504388,
            21.00849635975455
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "diemDau": "23-1",
        "diemCuoi": "23-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00861375688697,
        "to_latitude": 21.00849635975455,
        "to_longitude": 105.81579433504388,
        "from_longitude": 105.81594911234957,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2308,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81563831137699,
          21.00833676122544,
          105.81579433504388,
          21.00849635975455
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81579433504388,
            21.00849635975455
          ],
          [
            105.81577108072312,
            21.008451941406463
          ],
          [
            105.81563831137699,
            21.00833676122544
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "diemDau": "23-3",
        "diemCuoi": "23-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00849635975455,
        "to_latitude": 21.00833676122544,
        "to_longitude": 105.81563831137699,
        "from_longitude": 105.81579433504388,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2309,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81566947383155,
          21.008179014310667,
          105.81588909791765,
          21.00836379531367
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81588909791765,
            21.008179014310667
          ],
          [
            105.81566947383155,
            21.00836379531367
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "diemDau": "23-4",
        "diemCuoi": "23-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.008179014310667,
        "to_latitude": 21.00836379531367,
        "to_longitude": 105.81566947383155,
        "from_longitude": 105.81588909791765,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2310,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81564322439468,
          21.008100632147404,
          105.8157844917708,
          21.00836223140933
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81566767110377,
            21.00836223140933
          ],
          [
            105.8157844917708,
            21.00824787211119
          ],
          [
            105.81564322439468,
            21.008100632147404
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "diemDau": "23-4",
        "diemCuoi": "23-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00836223140933,
        "to_latitude": 21.008100632147404,
        "to_longitude": 105.81564322439468,
        "from_longitude": 105.81566767110377,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2311,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81537109631168,
          21.008303378892684,
          105.81553370523014,
          21.00852611989624
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81537109631168,
            21.00852611989624
          ],
          [
            105.81553370523014,
            21.008366808237792
          ],
          [
            105.81545955234309,
            21.008303378892684
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "diemDau": "21",
        "diemCuoi": "21-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00852611989624,
        "to_latitude": 21.008303378892684,
        "to_longitude": 105.81545955234309,
        "from_longitude": 105.81537109631168,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2312,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81527889536889,
          21.00835460163507,
          105.81537109631168,
          21.00852611989624
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81537109631168,
            21.00852611989624
          ],
          [
            105.81527889536889,
            21.008410626786798
          ],
          [
            105.81534561532014,
            21.00835460163507
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "diemDau": "21",
        "diemCuoi": "21-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Thịnh Quang 2",
        "from_latitude": 21.00852611989624,
        "to_latitude": 21.00835460163507,
        "to_longitude": 105.81534561532014,
        "from_longitude": 105.81537109631168,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2313,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82654593888692,
          21.02216369420937,
          105.82660385239296,
          21.02244692339906
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82660385239296,
            21.02244692339906
          ],
          [
            105.82654593888692,
            21.02216369420937
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "06",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.02244692339906,
        "to_latitude": 21.02216369420937,
        "to_longitude": 105.82654593888692,
        "from_longitude": 105.82660385239296,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2314,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82638902966215,
          21.02187451821627,
          105.82654593888692,
          21.02216369420937
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82654593888692,
            21.02216369420937
          ],
          [
            105.82638902966215,
            21.02187451821627
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "05",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.02216369420937,
        "to_latitude": 21.02187451821627,
        "to_longitude": 105.82638902966215,
        "from_longitude": 105.82654593888692,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2315,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82624553147609,
          21.021624148938034,
          105.82638902966215,
          21.02187451821627
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82638902966215,
            21.02187451821627
          ],
          [
            105.82630990449783,
            21.02176435578605
          ],
          [
            105.82624553147609,
            21.021624148938034
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "04",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.02187451821627,
        "to_latitude": 21.021624148938034,
        "to_longitude": 105.82624553147609,
        "from_longitude": 105.82638902966215,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2316,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82614360753699,
          21.02133747559863,
          105.82624553147609,
          21.021624148938034
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82624553147609,
            21.021624148938034
          ],
          [
            105.82618920508658,
            21.021487697504703
          ],
          [
            105.82614360753699,
            21.02133747559863
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.021624148938034,
        "to_latitude": 21.02133747559863,
        "to_longitude": 105.82614360753699,
        "from_longitude": 105.82624553147609,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2317,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82607521120363,
          21.021116858215407,
          105.82614360753699,
          21.02133747559863
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82614360753699,
            21.02133747559863
          ],
          [
            105.82611812654547,
            21.02124609053148
          ],
          [
            105.82607521120363,
            21.02114594244919
          ],
          [
            105.82613434124416,
            21.021116858215407
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "02",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.02133747559863,
        "to_latitude": 21.021116858215407,
        "to_longitude": 105.82613434124416,
        "from_longitude": 105.82614360753699,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2318,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82660385239296,
          21.02244692339906,
          105.82669614259234,
          21.022645652950324
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82660385239296,
            21.02244692339906
          ],
          [
            105.82669614259234,
            21.022645652950324
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.02244692339906,
        "to_latitude": 21.022645652950324,
        "to_longitude": 105.82669614259234,
        "from_longitude": 105.82660385239296,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2319,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82669614259234,
          21.022645652950324,
          105.82679672542758,
          21.022957360502048
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82669614259234,
            21.022645652950324
          ],
          [
            105.82679672542758,
            21.022957360502048
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.022645652950324,
        "to_latitude": 21.022957360502048,
        "to_longitude": 105.82679672542758,
        "from_longitude": 105.82669614259234,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2320,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82679672542758,
          21.022957360502048,
          105.82688926163961,
          21.02324528256074
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82679672542758,
            21.022957360502048
          ],
          [
            105.82688926163961,
            21.02324528256074
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.022957360502048,
        "to_latitude": 21.02324528256074,
        "to_longitude": 105.82688926163961,
        "from_longitude": 105.82679672542758,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2321,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82688926163961,
          21.02324528256074,
          105.8269737512284,
          21.023539463219826
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82688926163961,
            21.02324528256074
          ],
          [
            105.8269737512284,
            21.023539463219826
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.02324528256074,
        "to_latitude": 21.023539463219826,
        "to_longitude": 105.8269737512284,
        "from_longitude": 105.82688926163961,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2322,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8269737512284,
          21.023539463219826,
          105.8270488530811,
          21.02385367255981
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8269737512284,
            21.023539463219826
          ],
          [
            105.8270488530811,
            21.02385367255981
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.023539463219826,
        "to_latitude": 21.02385367255981,
        "to_longitude": 105.8270488530811,
        "from_longitude": 105.8269737512284,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2323,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8270488530811,
          21.02385367255981,
          105.82717223469115,
          21.024242990783083
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8270488530811,
            21.02385367255981
          ],
          [
            105.82717223469115,
            21.024242990783083
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.02385367255981,
        "to_latitude": 21.024242990783083,
        "to_longitude": 105.82717223469115,
        "from_longitude": 105.8270488530811,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2324,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82717223469115,
          21.024242990783083,
          105.82725940648766,
          21.024545932234798
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82717223469115,
            21.024242990783083
          ],
          [
            105.82725940648766,
            21.024545932234798
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.024242990783083,
        "to_latitude": 21.024545932234798,
        "to_longitude": 105.82725940648766,
        "from_longitude": 105.82717223469115,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2325,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82725940648766,
          21.024545932234798,
          105.82734657827521,
          21.02485638399266
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82725940648766,
            21.024545932234798
          ],
          [
            105.82734657827521,
            21.02485638399266
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.024545932234798,
        "to_latitude": 21.02485638399266,
        "to_longitude": 105.82734657827521,
        "from_longitude": 105.82725940648766,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2326,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82734657827521,
          21.02485638399266,
          105.82743643227948,
          21.0251768496446
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82734657827521,
            21.02485638399266
          ],
          [
            105.82743643227948,
            21.0251768496446
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.02485638399266,
        "to_latitude": 21.0251768496446,
        "to_longitude": 105.82743643227948,
        "from_longitude": 105.82734657827521,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2327,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82743643227948,
          21.0251768496446,
          105.82755042616242,
          21.025473530122657
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82743643227948,
            21.0251768496446
          ],
          [
            105.82755042616242,
            21.025473530122657
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.0251768496446,
        "to_latitude": 21.025473530122657,
        "to_longitude": 105.82755042616242,
        "from_longitude": 105.82743643227948,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2328,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82755042616242,
          21.025473530122657,
          105.82765905562988,
          21.025879117668328
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82755042616242,
            21.025473530122657
          ],
          [
            105.82765905562988,
            21.025879117668328
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.025473530122657,
        "to_latitude": 21.025879117668328,
        "to_longitude": 105.82765905562988,
        "from_longitude": 105.82755042616242,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2329,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82765905562988,
          21.025879117668328,
          105.82771269981167,
          21.0261983293131
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82765905562988,
            21.025879117668328
          ],
          [
            105.82771269981167,
            21.0261983293131
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.025879117668328,
        "to_latitude": 21.0261983293131,
        "to_longitude": 105.82771269981167,
        "from_longitude": 105.82765905562988,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2330,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82771269981167,
          21.0261983293131,
          105.82780523602368,
          21.026476230658492
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82771269981167,
            21.0261983293131
          ],
          [
            105.82780523602368,
            21.026476230658492
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.0261983293131,
        "to_latitude": 21.026476230658492,
        "to_longitude": 105.82780523602368,
        "from_longitude": 105.82771269981167,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2331,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82780523602368,
          21.026476230658492,
          105.8278977722267,
          21.0267190809602
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82780523602368,
            21.026476230658492
          ],
          [
            105.8278977722267,
            21.0267190809602
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.026476230658492,
        "to_latitude": 21.0267190809602,
        "to_longitude": 105.8278977722267,
        "from_longitude": 105.82780523602368,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2332,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8278977722267,
          21.0267190809602,
          105.82797823850389,
          21.026928132191006
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8278977722267,
            21.0267190809602
          ],
          [
            105.82797823850389,
            21.026928132191006
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.0267190809602,
        "to_latitude": 21.026928132191006,
        "to_longitude": 105.82797823850389,
        "from_longitude": 105.8278977722267,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2333,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82637363578394,
          21.02244692339906,
          105.82660385239296,
          21.02251818619776
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82660385239296,
            21.02244692339906
          ],
          [
            105.82637363578394,
            21.02251818619776
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "06",
        "diemCuoi": "35",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.02244692339906,
        "to_latitude": 21.02251818619776,
        "to_longitude": 105.82637363578394,
        "from_longitude": 105.82660385239296,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2334,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82626303415546,
          21.02222561326595,
          105.82637363578394,
          21.02251818619776
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82637363578394,
            21.02251818619776
          ],
          [
            105.82626303415546,
            21.02222561326595
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "35",
        "diemCuoi": "36",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.02251818619776,
        "to_latitude": 21.02222561326595,
        "to_longitude": 105.82626303415546,
        "from_longitude": 105.82637363578394,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2335,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82614710270664,
          21.0219170119741,
          105.82626303415546,
          21.02222561326595
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82626303415546,
            21.02222561326595
          ],
          [
            105.82614710270664,
            21.0219170119741
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "36",
        "diemCuoi": "37",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.02222561326595,
        "to_latitude": 21.0219170119741,
        "to_longitude": 105.82614710270664,
        "from_longitude": 105.82626303415546,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2336,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82602180598421,
          21.021638720213737,
          105.82614710270664,
          21.0219170119741
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82614710270664,
            21.0219170119741
          ],
          [
            105.82602180598421,
            21.021638720213737
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "38",
        "diemCuoi": "38",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.0219170119741,
        "to_latitude": 21.021638720213737,
        "to_longitude": 105.82602180598421,
        "from_longitude": 105.82614710270664,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2337,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82581630865388,
          21.021265282069177,
          105.82602180598421,
          21.021638720213737
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82602180598421,
            21.021638720213737
          ],
          [
            105.82581630865388,
            21.021265282069177
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "38",
        "diemCuoi": "39",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.021638720213737,
        "to_latitude": 21.021265282069177,
        "to_longitude": 105.82581630865388,
        "from_longitude": 105.82602180598421,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2338,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82637363578394,
          21.02251818619776,
          105.82648244764492,
          21.02288159411798
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82637363578394,
            21.02251818619776
          ],
          [
            105.82648244764492,
            21.02288159411798
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "35",
        "diemCuoi": "36",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.02251818619776,
        "to_latitude": 21.02288159411798,
        "to_longitude": 105.82648244764492,
        "from_longitude": 105.82637363578394,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2339,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82648244764492,
          21.02288159411798,
          105.82654337087168,
          21.023083363316317
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82648244764492,
            21.02288159411798
          ],
          [
            105.82654337087168,
            21.023083363316317
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "34",
        "diemCuoi": "33",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.02288159411798,
        "to_latitude": 21.023083363316317,
        "to_longitude": 105.82654337087168,
        "from_longitude": 105.82648244764492,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2340,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82654337087168,
          21.023083363316317,
          105.82662802668139,
          21.02337470386223
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82654337087168,
            21.023083363316317
          ],
          [
            105.82662802668139,
            21.02337470386223
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "33",
        "diemCuoi": "32",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.023083363316317,
        "to_latitude": 21.02337470386223,
        "to_longitude": 105.82662802668139,
        "from_longitude": 105.82654337087168,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2341,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82662802668139,
          21.02337470386223,
          105.82674365072356,
          21.023674505403434
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82662802668139,
            21.02337470386223
          ],
          [
            105.82674365072356,
            21.023674505403434
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "32",
        "diemCuoi": "31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.02337470386223,
        "to_latitude": 21.023674505403434,
        "to_longitude": 105.82674365072356,
        "from_longitude": 105.82662802668139,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2342,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82674365072356,
          21.023674505403434,
          105.82683199833981,
          21.02398228477998
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82674365072356,
            21.023674505403434
          ],
          [
            105.82683199833981,
            21.02398228477998
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "31",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.023674505403434,
        "to_latitude": 21.02398228477998,
        "to_longitude": 105.82683199833981,
        "from_longitude": 105.82674365072356,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2343,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82683199833981,
          21.02398228477998,
          105.8269378271852,
          21.024285220764387
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82683199833981,
            21.02398228477998
          ],
          [
            105.8269378271852,
            21.024285220764387
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "30",
        "diemCuoi": "39",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.02398228477998,
        "to_latitude": 21.024285220764387,
        "to_longitude": 105.8269378271852,
        "from_longitude": 105.82683199833981,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2344,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8269378271852,
          21.024285220764387,
          105.82701374733301,
          21.02458749967852
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8269378271852,
            21.024285220764387
          ],
          [
            105.82701374733301,
            21.02458749967852
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "29",
        "diemCuoi": "28",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.024285220764387,
        "to_latitude": 21.02458749967852,
        "to_longitude": 105.82701374733301,
        "from_longitude": 105.8269378271852,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2345,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82701374733301,
          21.02458749967852,
          105.8270952266284,
          21.024927068521826
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82701374733301,
            21.02458749967852
          ],
          [
            105.8270952266284,
            21.024927068521826
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "28",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.02458749967852,
        "to_latitude": 21.024927068521826,
        "to_longitude": 105.8270952266284,
        "from_longitude": 105.82701374733301,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2346,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8270952266284,
          21.024927068521826,
          105.8271076246489,
          21.025252215974465
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8270952266284,
            21.024927068521826
          ],
          [
            105.8271076246489,
            21.025252215974465
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "27",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.024927068521826,
        "to_latitude": 21.025252215974465,
        "to_longitude": 105.8271076246489,
        "from_longitude": 105.8270952266284,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2347,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8271076246489,
          21.025252215974465,
          105.82720418417254,
          21.025607731571416
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8271076246489,
            21.025252215974465
          ],
          [
            105.82720418417254,
            21.025607731571416
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "26",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.025252215974465,
        "to_latitude": 21.025607731571416,
        "to_longitude": 105.82720418417254,
        "from_longitude": 105.8271076246489,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2348,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82720418417254,
          21.025607731571416,
          105.82730744921551,
          21.025949476398598
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82720418417254,
            21.025607731571416
          ],
          [
            105.82730744921551,
            21.025949476398598
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "25",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.025607731571416,
        "to_latitude": 21.025949476398598,
        "to_longitude": 105.82730744921551,
        "from_longitude": 105.82720418417254,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2349,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82730744921551,
          21.025949476398598,
          105.82736243450118,
          21.026144758805806
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82730744921551,
            21.025949476398598
          ],
          [
            105.82736243450118,
            21.026144758805806
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hào Nam",
        "diemDau": "24",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.025949476398598,
        "to_latitude": 21.026144758805806,
        "to_longitude": 105.82736243450118,
        "from_longitude": 105.82730744921551,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2350,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8300964684684,
          21.018509519986658,
          105.83028498034402,
          21.01878505453441
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8300964684684,
            21.018509519986658
          ],
          [
            105.83028498034402,
            21.01878505453441
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.018509519986658,
        "to_latitude": 21.01878505453441,
        "to_longitude": 105.83028498034402,
        "from_longitude": 105.8300964684684,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2351,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83028498034402,
          21.01878505453441,
          105.8305304024692,
          21.018934027141757
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83028498034402,
            21.01878505453441
          ],
          [
            105.8305304024692,
            21.018934027141757
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.01878505453441,
        "to_latitude": 21.018934027141757,
        "to_longitude": 105.8305304024692,
        "from_longitude": 105.83028498034402,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2352,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83043639380165,
          21.018934027141757,
          105.8305304024692,
          21.019129196116836
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8305304024692,
            21.018934027141757
          ],
          [
            105.83043639380165,
            21.019129196116836
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "01",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.018934027141757,
        "to_latitude": 21.019129196116836,
        "to_longitude": 105.83043639380165,
        "from_longitude": 105.8305304024692,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2353,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83037470299662,
          21.019129196116836,
          105.83043639380165,
          21.019194293258987
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83043639380165,
            21.019129196116836
          ],
          [
            105.83037470299662,
            21.019194293258987
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "01",
        "diemCuoi": "025",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.019129196116836,
        "to_latitude": 21.019194293258987,
        "to_longitude": 105.83037470299662,
        "from_longitude": 105.83043639380165,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2354,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83034859610395,
          21.019194293258987,
          105.83042164165906,
          21.01939959944356
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83037470299662,
            21.019194293258987
          ],
          [
            105.83034859610395,
            21.0192531310449
          ],
          [
            105.83042164165906,
            21.01939959944356
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "02",
        "diemCuoi": "2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.019194293258987,
        "to_latitude": 21.01939959944356,
        "to_longitude": 105.83042164165906,
        "from_longitude": 105.83037470299662,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2355,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83042164165906,
          21.01939959944356,
          105.83060269076249,
          21.019771403217682
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83042164165906,
            21.01939959944356
          ],
          [
            105.83060269076249,
            21.019771403217682
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "2",
        "diemCuoi": "3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.01939959944356,
        "to_latitude": 21.019771403217682,
        "to_longitude": 105.83060269076249,
        "from_longitude": 105.83042164165906,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2356,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83060269076249,
          21.019771403217682,
          105.83073009569313,
          21.020034294214575
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83060269076249,
            21.019771403217682
          ],
          [
            105.83073009569313,
            21.020034294214575
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "3",
        "diemCuoi": "4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.019771403217682,
        "to_latitude": 21.020034294214575,
        "to_longitude": 105.83073009569313,
        "from_longitude": 105.83060269076249,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2357,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83073009569313,
          21.020034294214575,
          105.83083906041183,
          21.020265575319144
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83073009569313,
            21.020034294214575
          ],
          [
            105.83083906041183,
            21.020265575319144
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "4",
        "diemCuoi": "5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.020034294214575,
        "to_latitude": 21.020265575319144,
        "to_longitude": 105.83083906041183,
        "from_longitude": 105.83073009569313,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2358,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83083906041183,
          21.020265575319144,
          105.83095439539865,
          21.020513443165598
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83083906041183,
            21.020265575319144
          ],
          [
            105.83095439539865,
            21.020513443165598
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "5",
        "diemCuoi": "6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.020265575319144,
        "to_latitude": 21.020513443165598,
        "to_longitude": 105.83095439539865,
        "from_longitude": 105.83083906041183,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2359,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83095439539865,
          21.020513443165598,
          105.8311310859392,
          21.020873038584867
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83095439539865,
            21.020513443165598
          ],
          [
            105.8311310859392,
            21.020873038584867
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "6",
        "diemCuoi": "7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.020513443165598,
        "to_latitude": 21.020873038584867,
        "to_longitude": 105.8311310859392,
        "from_longitude": 105.83095439539865,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2360,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8311310859392,
          21.020873038584867,
          105.83123569209504,
          21.021102127644955
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8311310859392,
            21.020873038584867
          ],
          [
            105.83123569209504,
            21.021102127644955
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "7",
        "diemCuoi": "9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.020873038584867,
        "to_latitude": 21.021102127644955,
        "to_longitude": 105.83123569209504,
        "from_longitude": 105.8311310859392,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2361,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83123569209504,
          21.021102127644955,
          105.83136175591281,
          21.021363764446583
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83123569209504,
            21.021102127644955
          ],
          [
            105.83136175591281,
            21.021363764446583
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "9",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.021102127644955,
        "to_latitude": 21.021363764446583,
        "to_longitude": 105.83136175591281,
        "from_longitude": 105.83123569209504,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2362,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83136175591281,
          21.021363764446583,
          105.83148379642796,
          21.021646682193825
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83136175591281,
            21.021363764446583
          ],
          [
            105.83148379642796,
            21.021646682193825
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.021363764446583,
        "to_latitude": 21.021646682193825,
        "to_longitude": 105.83148379642796,
        "from_longitude": 105.83136175591281,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2363,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83148379642796,
          21.021646682193825,
          105.83162997681278,
          21.02195213261049
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83148379642796,
            21.021646682193825
          ],
          [
            105.83162997681278,
            21.02195213261049
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.021646682193825,
        "to_latitude": 21.02195213261049,
        "to_longitude": 105.83162997681278,
        "from_longitude": 105.83148379642796,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2364,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83162997681278,
          21.02195213261049,
          105.83174397069571,
          21.022196242124505
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83162997681278,
            21.02195213261049
          ],
          [
            105.83174397069571,
            21.022196242124505
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.02195213261049,
        "to_latitude": 21.022196242124505,
        "to_longitude": 105.83174397069571,
        "from_longitude": 105.83162997681278,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2365,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83025825035291,
          21.01856910658445,
          105.83041372636954,
          21.01878505453441
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83028498034402,
            21.01878505453441
          ],
          [
            105.83025825035291,
            21.018716078984536
          ],
          [
            105.83041372636954,
            21.01856910658445
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "15",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.01878505453441,
        "to_latitude": 21.01856910658445,
        "to_longitude": 105.83041372636954,
        "from_longitude": 105.83028498034402,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2366,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83041372636954,
          21.018274167565167,
          105.8308246882207,
          21.01856910658445
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83041372636954,
            21.01856910658445
          ],
          [
            105.8306444884474,
            21.018403110691978
          ],
          [
            105.8308246882207,
            21.018274167565167
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 52,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.01856910658445,
        "to_latitude": 21.018274167565167,
        "to_longitude": 105.8308246882207,
        "from_longitude": 105.83041372636954,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2367,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8308246882207,
          21.018021288485496,
          105.83110900237608,
          21.018274167565167
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8308246882207,
            21.018274167565167
          ],
          [
            105.83110900237608,
            21.018021288485496
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.018274167565167,
        "to_latitude": 21.018021288485496,
        "to_longitude": 105.83110900237608,
        "from_longitude": 105.8308246882207,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2368,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83110900237608,
          21.017628198373192,
          105.83143086745784,
          21.018021288485496
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83110900237608,
            21.018021288485496
          ],
          [
            105.83143086745784,
            21.017628198373192
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.018021288485496,
        "to_latitude": 21.017628198373192,
        "to_longitude": 105.83143086745784,
        "from_longitude": 105.83110900237608,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2369,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83143086745784,
          21.017415378453425,
          105.83164007976056,
          21.017628198373192
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83143086745784,
            21.017628198373192
          ],
          [
            105.83164007976056,
            21.017415378453425
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 58,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "20",
        "diemCuoi": "21-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.017628198373192,
        "to_latitude": 21.017415378453425,
        "to_longitude": 105.83164007976056,
        "from_longitude": 105.83143086745784,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2370,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83164007976056,
          21.017293945774448,
          105.83175407364345,
          21.017415378453425
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83164007976056,
            21.017415378453425
          ],
          [
            105.83175407364345,
            21.017293945774448
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "21-1",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.017415378453425,
        "to_latitude": 21.017293945774448,
        "to_longitude": 105.83175407364345,
        "from_longitude": 105.83164007976056,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2371,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83175407364345,
          21.01713495644762,
          105.8319163472927,
          21.017293945774448
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83175407364345,
            21.017293945774448
          ],
          [
            105.8319163472927,
            21.01713495644762
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.017293945774448,
        "to_latitude": 21.01713495644762,
        "to_longitude": 105.8319163472927,
        "from_longitude": 105.83175407364345,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2372,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8319163472927,
          21.01688992334331,
          105.83215940303086,
          21.01713495644762
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8319163472927,
            21.01713495644762
          ],
          [
            105.83215940303086,
            21.01688992334331
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.01713495644762,
        "to_latitude": 21.01688992334331,
        "to_longitude": 105.83215940303086,
        "from_longitude": 105.8319163472927,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2373,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83215940303086,
          21.016652064587703,
          105.8324142128921,
          21.01688992334331
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83215940303086,
            21.01688992334331
          ],
          [
            105.8324142128921,
            21.016652064587703
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.01688992334331,
        "to_latitude": 21.016652064587703,
        "to_longitude": 105.8324142128921,
        "from_longitude": 105.83215940303086,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2374,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8324142128921,
          21.016480555670856,
          105.83259794420327,
          21.016652064587703
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8324142128921,
            21.016652064587703
          ],
          [
            105.83259794420327,
            21.016480555670856
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.016652064587703,
        "to_latitude": 21.016480555670856,
        "to_longitude": 105.83259794420327,
        "from_longitude": 105.8324142128921,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2375,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83259794420327,
          21.01615881942764,
          105.83291444491445,
          21.016480555670856
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83259794420327,
            21.016480555670856
          ],
          [
            105.83291444491445,
            21.01615881942764
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 52,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.016480555670856,
        "to_latitude": 21.01615881942764,
        "to_longitude": 105.83291444491445,
        "from_longitude": 105.83259794420327,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2376,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82671442366373,
          21.02458749967852,
          105.82701374733301,
          21.024685877298406
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82701374733301,
            21.02458749967852
          ],
          [
            105.82671442366373,
            21.024685877298406
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 101",
        "diemDau": "28",
        "diemCuoi": "28-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.02458749967852,
        "to_latitude": 21.024685877298406,
        "to_longitude": 105.82671442366373,
        "from_longitude": 105.82701374733301,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2377,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82664920393889,
          21.024490852016868,
          105.82671442366373,
          21.024685877298406
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82671442366373,
            21.024685877298406
          ],
          [
            105.82668004933691,
            21.024588494207475
          ],
          [
            105.82664920393889,
            21.024490852016868
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 101",
        "diemDau": "28-1",
        "diemCuoi": "28-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.024685877298406,
        "to_latitude": 21.024490852016868,
        "to_longitude": 105.82664920393889,
        "from_longitude": 105.82671442366373,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2378,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82671442366373,
          21.024685877298406,
          105.82674576346254,
          21.024852628530965
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82671442366373,
            21.024685877298406
          ],
          [
            105.82672564689548,
            21.02477376382978
          ],
          [
            105.82674576346254,
            21.024852628530965
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 101",
        "diemDau": "28-1",
        "diemCuoi": "28-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.024685877298406,
        "to_latitude": 21.024852628530965,
        "to_longitude": 105.82674576346254,
        "from_longitude": 105.82671442366373,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2379,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8324142128921,
          21.016652064587703,
          105.83256946518333,
          21.01674687155273
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8324142128921,
            21.016652064587703
          ],
          [
            105.83256946518333,
            21.01674687155273
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đê La Thành",
        "diemDau": "24",
        "diemCuoi": "24-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.016652064587703,
        "to_latitude": 21.01674687155273,
        "to_longitude": 105.83256946518333,
        "from_longitude": 105.8324142128921,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2380,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83215940303086,
          21.01688992334331,
          105.83227576329185,
          21.016927143371685
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83215940303086,
            21.01688992334331
          ],
          [
            105.83227576329185,
            21.016927143371685
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đê La Thành",
        "diemDau": "23",
        "diemCuoi": "23-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.01688992334331,
        "to_latitude": 21.016927143371685,
        "to_longitude": 105.83227576329185,
        "from_longitude": 105.83215940303086,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2381,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83215940303086,
          21.01688992334331,
          105.83218590928756,
          21.01696845563333
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83215940303086,
            21.01688992334331
          ],
          [
            105.83218590928756,
            21.01696845563333
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đê La Thành",
        "diemDau": "23",
        "diemCuoi": "23-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.01688992334331,
        "to_latitude": 21.01696845563333,
        "to_longitude": 105.83218590928756,
        "from_longitude": 105.83215940303086,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2382,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8319163472927,
          21.01713495644762,
          105.83201156570348,
          21.01716374980342
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8319163472927,
            21.01713495644762
          ],
          [
            105.83201156570348,
            21.01716374980342
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 6,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 268 Đê La Thành",
        "diemDau": "22",
        "diemCuoi": "22-1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.01713495644762,
        "to_latitude": 21.01716374980342,
        "to_longitude": 105.83201156570348,
        "from_longitude": 105.8319163472927,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2383,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83201156570348,
          21.01716374980342,
          105.83212958289805,
          21.01722384026596
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83201156570348,
            21.01716374980342
          ],
          [
            105.83212958289805,
            21.01722384026596
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 6,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 268 Đê La Thành",
        "diemDau": "22-1-1",
        "diemCuoi": "22-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.01716374980342,
        "to_latitude": 21.01722384026596,
        "to_longitude": 105.83212958289805,
        "from_longitude": 105.83201156570348,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2384,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83212958289805,
          21.01722384026596,
          105.83234952404067,
          21.017534307270356
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83212958289805,
            21.01722384026596
          ],
          [
            105.83234952404067,
            21.017534307270356
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 268 Đê La Thành",
        "diemDau": "22-1",
        "diemCuoi": "22-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.01722384026596,
        "to_latitude": 21.017534307270356,
        "to_longitude": 105.83234952404067,
        "from_longitude": 105.83212958289805,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2385,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83234952404067,
          21.017534307270356,
          105.83243133141275,
          21.017673266083285
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83234952404067,
            21.017534307270356
          ],
          [
            105.83243133141275,
            21.017673266083285
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 268 Đê La Thành",
        "diemDau": "22-2",
        "diemCuoi": "22-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.017534307270356,
        "to_latitude": 21.017673266083285,
        "to_longitude": 105.83243133141275,
        "from_longitude": 105.83234952404067,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2386,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83243133141275,
          21.017673266083285,
          105.83251179768992,
          21.01781347664554
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83243133141275,
            21.017673266083285
          ],
          [
            105.83251179768992,
            21.01781347664554
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 268 Đê La Thành",
        "diemDau": "22-3",
        "diemCuoi": "22-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.017673266083285,
        "to_latitude": 21.01781347664554,
        "to_longitude": 105.83251179768992,
        "from_longitude": 105.83243133141275,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2387,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83238305165541,
          21.01781347664554,
          105.83251179768992,
          21.01793991641435
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83251179768992,
            21.01781347664554
          ],
          [
            105.83238305165541,
            21.01793991641435
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 268 Đê La Thành",
        "diemDau": "22-4",
        "diemCuoi": "22-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.01781347664554,
        "to_latitude": 21.01793991641435,
        "to_longitude": 105.83238305165541,
        "from_longitude": 105.83251179768992,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2388,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83251179768992,
          21.01781347664554,
          105.83263115598834,
          21.017969961492266
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83251179768992,
            21.01781347664554
          ],
          [
            105.83263115598834,
            21.017969961492266
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 268 Đê La Thành",
        "diemDau": "22-4",
        "diemCuoi": "22-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.01781347664554,
        "to_latitude": 21.017969961492266,
        "to_longitude": 105.83263115598834,
        "from_longitude": 105.83251179768992,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2389,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83250240995383,
          21.017969961492266,
          105.83263115598834,
          21.018041318528624
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83263115598834,
            21.017969961492266
          ],
          [
            105.83250240995383,
            21.018041318528624
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 268 Đê La Thành",
        "diemDau": "22-6",
        "diemCuoi": "22-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.017969961492266,
        "to_latitude": 21.018041318528624,
        "to_longitude": 105.83250240995383,
        "from_longitude": 105.83263115598834,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2390,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83143086745784,
          21.017628198373192,
          105.83158509447487,
          21.01770456309467
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83143086745784,
            21.017628198373192
          ],
          [
            105.83158509447487,
            21.01770456309467
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 298 Đê La Thành",
        "diemDau": "20",
        "diemCuoi": "20-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.017628198373192,
        "to_latitude": 21.01770456309467,
        "to_longitude": 105.83158509447487,
        "from_longitude": 105.83143086745784,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2391,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83158509447487,
          21.01770456309467,
          105.83171115829265,
          21.01778969093394
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83158509447487,
            21.01770456309467
          ],
          [
            105.83171115829265,
            21.01778969093394
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 298 Đê La Thành",
        "diemDau": "20-3",
        "diemCuoi": "20-4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.01770456309467,
        "to_latitude": 21.01778969093394,
        "to_longitude": 105.83171115829265,
        "from_longitude": 105.83158509447487,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2392,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83171115829265,
          21.01778969093394,
          105.83182381107171,
          21.017839766111447
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83171115829265,
            21.01778969093394
          ],
          [
            105.83182381107171,
            21.017839766111447
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 298 Đê La Thành",
        "diemDau": "20-4-1",
        "diemCuoi": "20-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.01778969093394,
        "to_latitude": 21.017839766111447,
        "to_longitude": 105.83182381107171,
        "from_longitude": 105.83171115829265,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2393,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83124664437295,
          21.017628198373192,
          105.83143086745784,
          21.017994999053276
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83143086745784,
            21.017628198373192
          ],
          [
            105.83124664437295,
            21.01790361193673
          ],
          [
            105.83131821467877,
            21.017994999053276
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 314  Đê La Thành",
        "diemDau": "20",
        "diemCuoi": "20-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.017628198373192,
        "to_latitude": 21.017994999053276,
        "to_longitude": 105.83131821467877,
        "from_longitude": 105.83143086745784,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2394,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83131821467877,
          21.017937412657925,
          105.83141611530627,
          21.017994999053276
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83131821467877,
            21.017994999053276
          ],
          [
            105.83141611530627,
            21.017937412657925
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 314  Đê La Thành",
        "diemDau": "20-1",
        "diemCuoi": "20-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.017994999053276,
        "to_latitude": 21.017937412657925,
        "to_longitude": 105.83141611530627,
        "from_longitude": 105.83131821467877,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2395,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8313316257175,
          21.01819029187899,
          105.8314429373927,
          21.01830796820237
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8313316257175,
            21.01819029187899
          ],
          [
            105.8314429373927,
            21.01830796820237
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 316 Đê La Thành",
        "diemDau": "19-2",
        "diemCuoi": "19-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.01819029187899,
        "to_latitude": 21.01830796820237,
        "to_longitude": 105.8314429373927,
        "from_longitude": 105.8313316257175,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2396,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8314429373927,
          21.01830796820237,
          105.83153815581244,
          21.01840811819083
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8314429373927,
            21.01830796820237
          ],
          [
            105.83153815581244,
            21.01840811819083
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 316 Đê La Thành",
        "diemDau": "19-2",
        "diemCuoi": "19-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.01830796820237,
        "to_latitude": 21.01840811819083,
        "to_longitude": 105.83153815581244,
        "from_longitude": 105.8314429373927,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2397,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83110900237608,
          21.018021288485496,
          105.83122970177838,
          21.018088889866885
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83110900237608,
            21.018021288485496
          ],
          [
            105.83122970177838,
            21.018088889866885
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 316 Đê La Thành",
        "diemDau": "19",
        "diemCuoi": "19-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.018021288485496,
        "to_latitude": 21.018088889866885,
        "to_longitude": 105.83122970177838,
        "from_longitude": 105.83110900237608,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2398,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83122970177838,
          21.018088889866885,
          105.8313316257175,
          21.01819029187899
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83122970177838,
            21.018088889866885
          ],
          [
            105.8313316257175,
            21.01819029187899
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 316 Đê La Thành",
        "diemDau": "19-1",
        "diemCuoi": "19-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.018088889866885,
        "to_latitude": 21.01819029187899,
        "to_longitude": 105.8313316257175,
        "from_longitude": 105.83122970177838,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2399,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83202765895894,
          21.016791939528307,
          105.83215940303086,
          21.01688992334331
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83215940303086,
            21.01688992334331
          ],
          [
            105.83202765895894,
            21.016791939528307
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đê La Thành",
        "diemDau": "23",
        "diemCuoi": "23-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.01688992334331,
        "to_latitude": 21.016791939528307,
        "to_longitude": 105.83202765895894,
        "from_longitude": 105.83215940303086,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2400,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83182381107171,
          21.017839766111447,
          105.83191902949146,
          21.018056341057672
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83182381107171,
            21.017839766111447
          ],
          [
            105.83191902949146,
            21.018056341057672
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 298 Đê La Thành",
        "diemDau": "20-4",
        "diemCuoi": "20-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.017839766111447,
        "to_latitude": 21.018056341057672,
        "to_longitude": 105.83191902949146,
        "from_longitude": 105.83182381107171,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2401,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83191902949146,
          21.018056341057672,
          105.83208398534845,
          21.01827041193937
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83191902949146,
            21.018056341057672
          ],
          [
            105.83208398534845,
            21.01827041193937
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 298 Đê La Thành",
        "diemDau": "20-5",
        "diemCuoi": "20-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.018056341057672,
        "to_latitude": 21.01827041193937,
        "to_longitude": 105.83208398534845,
        "from_longitude": 105.83191902949146,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2402,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83053769184858,
          21.018711071498547,
          105.83059535934197,
          21.018798702506068
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83053769184858,
            21.018798702506068
          ],
          [
            105.83059535934197,
            21.018711071498547
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 295 Khâm Thiên",
        "diemDau": "14-1",
        "diemCuoi": "14-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.018798702506068,
        "to_latitude": 21.018711071498547,
        "to_longitude": 105.83059535934197,
        "from_longitude": 105.83053769184858,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2403,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83136175591281,
          21.02132998812261,
          105.83159580384833,
          21.021363764446583
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83136175591281,
            21.021363764446583
          ],
          [
            105.83159580384833,
            21.02132998812261
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.021363764446583,
        "to_latitude": 21.02132998812261,
        "to_longitude": 105.83159580384833,
        "from_longitude": 105.83136175591281,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2404,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83159580384833,
          21.02120730678042,
          105.83175673639371,
          21.02132998812261
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83159580384833,
            21.02132998812261
          ],
          [
            105.83175673639371,
            21.02120730678042
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-1",
        "diemCuoi": "10-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.02132998812261,
        "to_latitude": 21.02120730678042,
        "to_longitude": 105.83175673639371,
        "from_longitude": 105.83159580384833,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2405,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83175673639371,
          21.02120730678042,
          105.83194046770488,
          21.021226084543663
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83175673639371,
            21.02120730678042
          ],
          [
            105.83194046770488,
            21.021226084543663
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-2",
        "diemCuoi": "10-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.02120730678042,
        "to_latitude": 21.021226084543663,
        "to_longitude": 105.83194046770488,
        "from_longitude": 105.83175673639371,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2406,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83194046770488,
          21.021226084543663,
          105.83217247879134,
          21.02134125477107
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83194046770488,
            21.021226084543663
          ],
          [
            105.83217247879134,
            21.02134125477107
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-5",
        "diemCuoi": "10-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.021226084543663,
        "to_latitude": 21.02134125477107,
        "to_longitude": 105.83217247879134,
        "from_longitude": 105.83194046770488,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2407,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83217247879134,
          21.02134125477107,
          105.83236023341412,
          21.021461432305003
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83217247879134,
            21.02134125477107
          ],
          [
            105.83236023341412,
            21.021461432305003
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-6",
        "diemCuoi": "10-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.02134125477107,
        "to_latitude": 21.021461432305003,
        "to_longitude": 105.83236023341412,
        "from_longitude": 105.83217247879134,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2408,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83174332534601,
          21.021088380893833,
          105.83179381501283,
          21.02120730678042
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83175673639371,
            21.02120730678042
          ],
          [
            105.83179381501283,
            21.02115800195989
          ],
          [
            105.83174332534601,
            21.021088380893833
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-2",
        "diemCuoi": "10-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.02120730678042,
        "to_latitude": 21.021088380893833,
        "to_longitude": 105.83174332534601,
        "from_longitude": 105.83175673639371,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2409,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83166151797394,
          21.020993240115935,
          105.83174332534601,
          21.021088380893833
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83174332534601,
            21.021088380893833
          ],
          [
            105.83166151797394,
            21.020993240115935
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-3",
        "diemCuoi": "10-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.021088380893833,
        "to_latitude": 21.020993240115935,
        "to_longitude": 105.83166151797394,
        "from_longitude": 105.83174332534601,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2410,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83220198308548,
          21.021461432305003,
          105.83236023341412,
          21.02155532093586
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83236023341412,
            21.021461432305003
          ],
          [
            105.83220198308548,
            21.02155532093586
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-7",
        "diemCuoi": "10-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.021461432305003,
        "to_latitude": 21.02155532093586,
        "to_longitude": 105.83220198308548,
        "from_longitude": 105.83236023341412,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2411,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83197935973512,
          21.02155532093586,
          105.83220198308548,
          21.02170429077628
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83220198308548,
            21.02155532093586
          ],
          [
            105.83197935973512,
            21.02170429077628
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-9",
        "diemCuoi": "10-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.02155532093586,
        "to_latitude": 21.02170429077628,
        "to_longitude": 105.83197935973512,
        "from_longitude": 105.83220198308548,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2412,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83220198308548,
          21.02155532093586,
          105.83232938801613,
          21.021720564783205
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83220198308548,
            21.02155532093586
          ],
          [
            105.83232938801613,
            21.021720564783205
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-10",
        "diemCuoi": "10-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.02155532093586,
        "to_latitude": 21.021720564783205,
        "to_longitude": 105.83232938801613,
        "from_longitude": 105.83220198308548,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2413,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83232938801613,
          21.021720564783205,
          105.83246483956998,
          21.021879549220678
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83232938801613,
            21.021720564783205
          ],
          [
            105.83246483956998,
            21.021879549220678
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-11",
        "diemCuoi": "10-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.021720564783205,
        "to_latitude": 21.021879549220678,
        "to_longitude": 105.83246483956998,
        "from_longitude": 105.83232938801613,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2414,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83219527756614,
          21.021879549220678,
          105.83246483956998,
          21.022027266891975
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83246483956998,
            21.021879549220678
          ],
          [
            105.83219527756614,
            21.022027266891975
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-12",
        "diemCuoi": "10-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.021879549220678,
        "to_latitude": 21.022027266891975,
        "to_longitude": 105.83219527756614,
        "from_longitude": 105.83246483956998,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2415,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83236023341412,
          21.021461432305003,
          105.83252384816726,
          21.0215277802774
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83236023341412,
            21.021461432305003
          ],
          [
            105.83252384816726,
            21.0215277802774
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-7",
        "diemCuoi": "10-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.021461432305003,
        "to_latitude": 21.0215277802774,
        "to_longitude": 105.83252384816726,
        "from_longitude": 105.83236023341412,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2416,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83217247879134,
          21.021217321587873,
          105.83233207022388,
          21.02134125477107
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83217247879134,
            21.02134125477107
          ],
          [
            105.83233207022388,
            21.021217321587873
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-6",
        "diemCuoi": "10-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.02134125477107,
        "to_latitude": 21.021217321587873,
        "to_longitude": 105.83233207022388,
        "from_longitude": 105.83217247879134,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2417,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83233207022388,
          21.021067099409372,
          105.83271428500676,
          21.021217321587873
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83233207022388,
            21.021217321587873
          ],
          [
            105.832461685055,
            21.021078342466332
          ],
          [
            105.83267089735769,
            21.021160964677232
          ],
          [
            105.83271428500676,
            21.021067099409372
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-14",
        "diemCuoi": "10-16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.021217321587873,
        "to_latitude": 21.021067099409372,
        "to_longitude": 105.83271428500676,
        "from_longitude": 105.83233207022388,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2418,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83233207022388,
          21.020918128933154,
          105.83261188876041,
          21.021217321587873
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83233207022388,
            21.021217321587873
          ],
          [
            105.832461685055,
            21.021078342466332
          ],
          [
            105.83254183498443,
            21.02110999531572
          ],
          [
            105.83261188876041,
            21.021010742441955
          ],
          [
            105.83241119538818,
            21.020918128933154
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 70,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-14",
        "diemCuoi": "10-21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.021217321587873,
        "to_latitude": 21.020918128933154,
        "to_longitude": 105.83241119538818,
        "from_longitude": 105.83233207022388,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2419,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83271428500676,
          21.021067099409372,
          105.83292752062106,
          21.021176010504178
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83271428500676,
            21.021067099409372
          ],
          [
            105.83292752062106,
            21.021176010504178
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-16",
        "diemCuoi": "10-17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.021067099409372,
        "to_latitude": 21.021176010504178,
        "to_longitude": 105.83292752062106,
        "from_longitude": 105.83271428500676,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2420,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83292752062106,
          21.021058336444266,
          105.83298518812346,
          21.021176010504178
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83292752062106,
            21.021176010504178
          ],
          [
            105.83298518812346,
            21.021058336444266
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-17",
        "diemCuoi": "10-18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.021176010504178,
        "to_latitude": 21.021058336444266,
        "to_longitude": 105.83298518812346,
        "from_longitude": 105.83292752062106,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2421,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83271428500676,
          21.020985728999523,
          105.83286180650444,
          21.021109488091827
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83271428500676,
            21.021067099409372
          ],
          [
            105.83279727723766,
            21.021109488091827
          ],
          [
            105.83286180650444,
            21.020985728999523
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-16",
        "diemCuoi": "10-18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.021067099409372,
        "to_latitude": 21.020985728999523,
        "to_longitude": 105.83286180650444,
        "from_longitude": 105.83271428500676,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2422,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83286180650444,
          21.020834254733376,
          105.83295300161258,
          21.020985728999523
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83286180650444,
            21.020985728999523
          ],
          [
            105.83295300161258,
            21.020834254733376
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-19",
        "diemCuoi": "10-20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.020985728999523,
        "to_latitude": 21.020834254733376,
        "to_longitude": 105.83295300161258,
        "from_longitude": 105.83286180650444,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2423,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83216845547074,
          21.020849276981913,
          105.83241119538818,
          21.020918128933154
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83241119538818,
            21.020918128933154
          ],
          [
            105.83216845547074,
            21.020849276981913
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-21",
        "diemCuoi": "10-22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.020918128933154,
        "to_latitude": 21.020849276981913,
        "to_longitude": 105.83216845547074,
        "from_longitude": 105.83241119538818,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2424,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83241119538818,
          21.020533809453163,
          105.83246886288161,
          21.020918128933154
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83241119538818,
            21.020918128933154
          ],
          [
            105.83246886288161,
            21.020533809453163
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 53,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-21",
        "diemCuoi": "10-23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.020918128933154,
        "to_latitude": 21.020533809453163,
        "to_longitude": 105.83246886288161,
        "from_longitude": 105.83241119538818,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2425,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83239644323662,
          21.02036230500093,
          105.83246886288161,
          21.020533809453163
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83246886288161,
            21.020533809453163
          ],
          [
            105.83239644323662,
            21.02036230500093
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-23",
        "diemCuoi": "10-24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.020533809453163,
        "to_latitude": 21.02036230500093,
        "to_longitude": 105.83239644323662,
        "from_longitude": 105.83246886288161,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2426,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83246886288161,
          21.02050877231926,
          105.83271830831836,
          21.020533809453163
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83246886288161,
            21.020533809453163
          ],
          [
            105.83271830831836,
            21.02050877231926
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-23",
        "diemCuoi": "10-25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.020533809453163,
        "to_latitude": 21.02050877231926,
        "to_longitude": 105.83271830831836,
        "from_longitude": 105.83246886288161,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2427,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8327035561758,
          21.020396105163965,
          105.83271830831836,
          21.02050877231926
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83271830831836,
            21.02050877231926
          ],
          [
            105.8327035561758,
            21.020396105163965
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-25",
        "diemCuoi": "10-26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.02050877231926,
        "to_latitude": 21.020396105163965,
        "to_longitude": 105.8327035561758,
        "from_longitude": 105.83271830831836,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2428,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83267405187266,
          21.020304719519835,
          105.8327035561758,
          21.020396105163965
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8327035561758,
            21.020396105163965
          ],
          [
            105.83267405187266,
            21.020304719519835
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-26",
        "diemCuoi": "10-27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.020396105163965,
        "to_latitude": 21.020304719519835,
        "to_longitude": 105.83267405187266,
        "from_longitude": 105.8327035561758,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2429,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83271830831836,
          21.020431157177036,
          105.83284303104125,
          21.02050877231926
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83271830831836,
            21.02050877231926
          ],
          [
            105.83284303104125,
            21.020431157177036
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-25",
        "diemCuoi": "10-28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.02050877231926,
        "to_latitude": 21.020431157177036,
        "to_longitude": 105.83284303104125,
        "from_longitude": 105.83271830831836,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2430,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83284303104125,
          21.02039109773273,
          105.83300798689824,
          21.020431157177036
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83284303104125,
            21.020431157177036
          ],
          [
            105.83300798689824,
            21.02039109773273
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "diemDau": "10-28",
        "diemCuoi": "10-29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.020431157177036,
        "to_latitude": 21.02039109773273,
        "to_longitude": 105.83300798689824,
        "from_longitude": 105.83284303104125,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2431,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83233207022388,
          21.021078342466332,
          105.83282827888969,
          21.02124611415453
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83233207022388,
            21.021217321587873
          ],
          [
            105.832461685055,
            21.021078342466332
          ],
          [
            105.83267089735769,
            21.021160964677232
          ],
          [
            105.83282827888969,
            21.02124611415453
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 81,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "10-14",
        "diemCuoi": "10-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.021217321587873,
        "to_latitude": 21.02124611415453,
        "to_longitude": 105.83282827888969,
        "from_longitude": 105.83233207022388,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2432,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83453440124683,
          21.02122480907204,
          105.83486614579346,
          21.021283646049202
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83486614579346,
            21.02122480907204
          ],
          [
            105.83453440124683,
            21.021283646049202
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.02122480907204,
        "to_latitude": 21.021283646049202,
        "to_longitude": 105.83453440124683,
        "from_longitude": 105.83486614579346,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2433,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83427959139455,
          21.021281142348084,
          105.83453440124683,
          21.021283646049202
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83453440124683,
            21.021283646049202
          ],
          [
            105.83427959139455,
            21.021281142348084
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.021283646049202,
        "to_latitude": 21.021281142348084,
        "to_longitude": 105.83427959139455,
        "from_longitude": 105.83453440124683,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2434,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83412134105691,
          21.021267371993744,
          105.83427959139455,
          21.021308683052958
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83427959139455,
            21.021281142348084
          ],
          [
            105.83414194936177,
            21.021267371993744
          ],
          [
            105.83412134105691,
            21.021308683052958
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.021281142348084,
        "to_latitude": 21.021308683052958,
        "to_longitude": 105.83412134105691,
        "from_longitude": 105.83427959139455,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2435,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83407976681896,
          21.021308683052958,
          105.83412134105691,
          21.021569067643945
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83412134105691,
            21.021308683052958
          ],
          [
            105.83407976681896,
            21.021569067643945
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.021308683052958,
        "to_latitude": 21.021569067643945,
        "to_longitude": 105.83407976681896,
        "from_longitude": 105.83412134105691,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2436,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83401807601395,
          21.021569067643945,
          105.83407976681896,
          21.021859496075223
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83407976681896,
            21.021569067643945
          ],
          [
            105.83401807601395,
            21.021859496075223
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.021569067643945,
        "to_latitude": 21.021859496075223,
        "to_longitude": 105.83401807601395,
        "from_longitude": 105.83407976681896,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2437,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83389201218716,
          21.021859496075223,
          105.83401807601395,
          21.022084828088186
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83401807601395,
            21.021859496075223
          ],
          [
            105.83389201218716,
            21.022084828088186
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.021859496075223,
        "to_latitude": 21.022084828088186,
        "to_longitude": 105.83389201218716,
        "from_longitude": 105.83401807601395,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2438,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83356075937834,
          21.022084828088186,
          105.83389201218716,
          21.022287626609245
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83389201218716,
            21.022084828088186
          ],
          [
            105.83373157138858,
            21.022238804767188
          ],
          [
            105.83356075937834,
            21.022287626609245
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.022084828088186,
        "to_latitude": 21.022287626609245,
        "to_longitude": 105.83356075937834,
        "from_longitude": 105.83389201218716,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2439,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8332375531927,
          21.022270100821963,
          105.83356075937834,
          21.022287626609245
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83356075937834,
            21.022287626609245
          ],
          [
            105.8332375531927,
            21.022270100821963
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.022287626609245,
        "to_latitude": 21.022270100821963,
        "to_longitude": 105.8332375531927,
        "from_longitude": 105.83356075937834,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2440,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83300420100238,
          21.022091087306304,
          105.8332375531927,
          21.022270100821963
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8332375531927,
            21.022270100821963
          ],
          [
            105.83306638354517,
            21.022186227382786
          ],
          [
            105.83300420100238,
            21.022091087306304
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.022270100821963,
        "to_latitude": 21.022091087306304,
        "to_longitude": 105.83300420100238,
        "from_longitude": 105.8332375531927,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2441,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83296396786827,
          21.021811925939872,
          105.83300420100238,
          21.022091087306304
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83300420100238,
            21.022091087306304
          ],
          [
            105.83296396786827,
            21.021811925939872
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.022091087306304,
        "to_latitude": 21.021811925939872,
        "to_longitude": 105.83296396786827,
        "from_longitude": 105.83300420100238,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2442,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83255090767838,
          21.02151148262977,
          105.83294251019736,
          21.02162414894222
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83294251019736,
            21.02162414894222
          ],
          [
            105.83255090767838,
            21.02151148262977
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "14",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.02162414894222,
        "to_latitude": 21.02151148262977,
        "to_longitude": 105.83255090767838,
        "from_longitude": 105.83294251019736,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2443,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8323001211377,
          21.021405075479077,
          105.83255090767838,
          21.02151148262977
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83255090767838,
            21.02151148262977
          ],
          [
            105.8323001211377,
            21.021405075479077
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "13",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.02151148262977,
        "to_latitude": 21.021405075479077,
        "to_longitude": 105.8323001211377,
        "from_longitude": 105.83255090767838,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2444,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83294251019736,
          21.02153526774682,
          105.83301358873845,
          21.021642926651293
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83294251019736,
            21.02162414894222
          ],
          [
            105.83298055286149,
            21.021642926651293
          ],
          [
            105.83301358873845,
            21.02153526774682
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "15",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.02162414894222,
        "to_latitude": 21.02153526774682,
        "to_longitude": 105.83301358873845,
        "from_longitude": 105.83294251019736,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2445,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83301358873845,
          21.02123857943115,
          105.83313831145236,
          21.02153526774682
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83301358873845,
            21.02153526774682
          ],
          [
            105.83313831145236,
            21.02123857943115
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "16",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.02153526774682,
        "to_latitude": 21.02123857943115,
        "to_longitude": 105.83313831145236,
        "from_longitude": 105.83301358873845,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2446,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83313831145236,
          21.02094439423191,
          105.8332697396946,
          21.02123857943115
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83313831145236,
            21.02123857943115
          ],
          [
            105.8332697396946,
            21.02094439423191
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "17",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.02123857943115,
        "to_latitude": 21.02094439423191,
        "to_longitude": 105.8332697396946,
        "from_longitude": 105.83313831145236,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2447,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8332697396946,
          21.020715304929464,
          105.83349772746047,
          21.02094439423191
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8332697396946,
            21.02094439423191
          ],
          [
            105.83349772746047,
            21.020715304929464
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.02094439423191,
        "to_latitude": 21.020715304929464,
        "to_longitude": 105.83349772746047,
        "from_longitude": 105.8332697396946,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2448,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83349772746047,
          21.02064144546173,
          105.8338330035899,
          21.020715304929464
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83349772746047,
            21.020715304929464
          ],
          [
            105.8338330035899,
            21.02064144546173
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.020715304929464,
        "to_latitude": 21.02064144546173,
        "to_longitude": 105.8338330035899,
        "from_longitude": 105.83349772746047,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2449,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83403953368486,
          21.02083423111361,
          105.83411195332982,
          21.021043290597884
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83403953368486,
            21.02083423111361
          ],
          [
            105.83411195332982,
            21.021043290597884
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.02083423111361,
        "to_latitude": 21.021043290597884,
        "to_longitude": 105.83411195332982,
        "from_longitude": 105.83403953368486,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2450,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83411195332982,
          21.021043290597884,
          105.83427959139455,
          21.021281142348084
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83411195332982,
            21.021043290597884
          ],
          [
            105.83413676375594,
            21.021254853488923
          ],
          [
            105.83427959139455,
            21.021281142348084
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "03",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.021043290597884,
        "to_latitude": 21.021281142348084,
        "to_longitude": 105.83427959139455,
        "from_longitude": 105.83411195332982,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2451,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8338330035899,
          21.02064144546173,
          105.83403953368486,
          21.02083423111361
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8338330035899,
            21.02064144546173
          ],
          [
            105.83397046680406,
            21.020721564205914
          ],
          [
            105.83403953368486,
            21.02083423111361
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "20",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.02064144546173,
        "to_latitude": 21.02083423111361,
        "to_longitude": 105.83403953368486,
        "from_longitude": 105.8338330035899,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2452,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83356075937834,
          21.022201249493424,
          105.83364305848818,
          21.022287626609245
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83356075937834,
            21.022287626609245
          ],
          [
            105.83364305848818,
            21.022201249493424
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "08",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.022287626609245,
        "to_latitude": 21.022201249493424,
        "to_longitude": 105.83364305848818,
        "from_longitude": 105.83356075937834,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2453,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83364305848818,
          21.02203725802393,
          105.8338120376568,
          21.022201249493424
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83364305848818,
            21.022201249493424
          ],
          [
            105.8338120376568,
            21.02203725802393
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "23",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.022201249493424,
        "to_latitude": 21.02203725802393,
        "to_longitude": 105.8338120376568,
        "from_longitude": 105.83364305848818,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2454,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83349388296747,
          21.022099850209052,
          105.83364305848818,
          21.022261963849996
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83364305848818,
            21.022201249493424
          ],
          [
            105.8335381394132,
            21.022261963849996
          ],
          [
            105.83349388296747,
            21.02223442332205
          ],
          [
            105.83352906460524,
            21.022099850209052
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.022201249493424,
        "to_latitude": 21.022099850209052,
        "to_longitude": 105.83352906460524,
        "from_longitude": 105.83364305848818,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2455,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83324206824211,
          21.02206479858827,
          105.83352906460524,
          21.022099850209052
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83352906460524,
            21.022099850209052
          ],
          [
            105.83324206824211,
            21.02206479858827
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.022099850209052,
        "to_latitude": 21.02206479858827,
        "to_longitude": 105.83324206824211,
        "from_longitude": 105.83352906460524,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2456,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83306906575291,
          21.022003458232103,
          105.83324206824211,
          21.02206479858827
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83324206824211,
            21.02206479858827
          ],
          [
            105.8330982571369,
            21.02205791345081
          ],
          [
            105.83306906575291,
            21.022003458232103
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.02206479858827,
        "to_latitude": 21.022003458232103,
        "to_longitude": 105.83306906575291,
        "from_longitude": 105.83324206824211,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2457,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83306906575291,
          21.021848229464254,
          105.8330999111599,
          21.022003458232103
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83306906575291,
            21.022003458232103
          ],
          [
            105.8330999111599,
            21.021848229464254
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.022003458232103,
        "to_latitude": 21.021848229464254,
        "to_longitude": 105.8330999111599,
        "from_longitude": 105.83306906575291,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2458,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8330999111599,
          21.021698007921426,
          105.83311600441534,
          21.021848229464254
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8330999111599,
            21.021848229464254
          ],
          [
            105.83311600441534,
            21.021698007921426
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.021848229464254,
        "to_latitude": 21.021698007921426,
        "to_longitude": 105.83311600441534,
        "from_longitude": 105.8330999111599,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2459,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83309588784829,
          21.02158534166479,
          105.83311600441534,
          21.021698007921426
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83311600441534,
            21.021698007921426
          ],
          [
            105.83309588784829,
            21.02158534166479
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VC1B",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Văn Chương",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1B",
        "from_latitude": 21.021698007921426,
        "to_latitude": 21.02158534166479,
        "to_longitude": 105.83309588784829,
        "from_longitude": 105.83311600441534,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2460,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8182739170014,
          21.00984630311446,
          105.81853064055517,
          21.010016872429357
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81853064055517,
            21.010016872429357
          ],
          [
            105.8182739170014,
            21.00984630311446
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 67 Thái Thịnh",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.010016872429357,
        "to_latitude": 21.00984630311446,
        "to_longitude": 105.8182739170014,
        "from_longitude": 105.81853064055517,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2461,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81798021510991,
          21.00956837077573,
          105.8182739170014,
          21.00984630311446
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8182739170014,
            21.00984630311446
          ],
          [
            105.81798021510991,
            21.00956837077573
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 67 Thái Thịnh",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.00984630311446,
        "to_latitude": 21.00956837077573,
        "to_longitude": 105.81798021510991,
        "from_longitude": 105.8182739170014,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2462,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81765945342195,
          21.009293129870336,
          105.81798021510991,
          21.00956837077573
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81798021510991,
            21.00956837077573
          ],
          [
            105.81765945342195,
            21.009293129870336
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 67 Thái Thịnh",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.00956837077573,
        "to_latitude": 21.009293129870336,
        "to_longitude": 105.81765945342195,
        "from_longitude": 105.81798021510991,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2463,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81765945342195,
          21.009167890860343,
          105.8176720476674,
          21.009293129870336
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81765945342195,
            21.009293129870336
          ],
          [
            105.8176720476674,
            21.009167890860343
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 67 Thái Thịnh",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009293129870336,
        "to_latitude": 21.009167890860343,
        "to_longitude": 105.8176720476674,
        "from_longitude": 105.81765945342195,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2464,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81748505936227,
          21.009293129870336,
          105.81765945342195,
          21.009375257577997
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81765945342195,
            21.009293129870336
          ],
          [
            105.81756279631223,
            21.009375257577997
          ],
          [
            105.81748505936227,
            21.009360279278074
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 67 Thái Thịnh",
        "diemDau": "04",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009293129870336,
        "to_latitude": 21.009360279278074,
        "to_longitude": 105.81748505936227,
        "from_longitude": 105.81765945342195,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2465,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81750646994966,
          21.009148654412453,
          105.81765945342195,
          21.009293129870336
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81765945342195,
            21.009293129870336
          ],
          [
            105.81750646994966,
            21.009148654412453
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 67 Thái Thịnh",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009293129870336,
        "to_latitude": 21.009148654412453,
        "to_longitude": 105.81750646994966,
        "from_longitude": 105.81765945342195,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2466,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81733212636557,
          21.009268841771515,
          105.81748505936227,
          21.009360279278074
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81748505936227,
            21.009360279278074
          ],
          [
            105.81733212636557,
            21.009268841771515
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 67 Thái Thịnh",
        "diemDau": "5-1",
        "diemCuoi": "5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009360279278074,
        "to_latitude": 21.009268841771515,
        "to_longitude": 105.81733212636557,
        "from_longitude": 105.81748505936227,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2467,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81720708436411,
          21.00888063174718,
          105.81750646994966,
          21.009148654412453
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81750646994966,
            21.009148654412453
          ],
          [
            105.81720708436411,
            21.00888063174718
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 67 Thái Thịnh",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009148654412453,
        "to_latitude": 21.00888063174718,
        "to_longitude": 105.81720708436411,
        "from_longitude": 105.81750646994966,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2468,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81816662863783,
          21.00984630311446,
          105.8182739170014,
          21.00995897823972
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8182739170014,
            21.00984630311446
          ],
          [
            105.81816662863783,
            21.00995897823972
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 67 Thái Thịnh",
        "diemDau": "02",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.00984630311446,
        "to_latitude": 21.00995897823972,
        "to_longitude": 105.81816662863783,
        "from_longitude": 105.8182739170014,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2469,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81803796933328,
          21.010082943273854,
          105.8182035648726,
          21.01019828778358
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8182035648726,
            21.01019828778358
          ],
          [
            105.81803796933328,
            21.010082943273854
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 79 Thái Thịnh",
        "diemDau": "08",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.01019828778358,
        "to_latitude": 21.010082943273854,
        "to_longitude": 105.81803796933328,
        "from_longitude": 105.8182035648726,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2470,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81803796933328,
          21.00995897823972,
          105.81816662863783,
          21.010082943273854
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81803796933328,
            21.010082943273854
          ],
          [
            105.81816662863783,
            21.00995897823972
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 79 Thái Thịnh",
        "diemDau": "09",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.010082943273854,
        "to_latitude": 21.00995897823972,
        "to_longitude": 105.81816662863783,
        "from_longitude": 105.81803796933328,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2471,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81777770832659,
          21.00956837077573,
          105.81798021510991,
          21.009822516133138
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81798021510991,
            21.00956837077573
          ],
          [
            105.81777770832659,
            21.009822516133138
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 79 Thái Thịnh",
        "diemDau": "03",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.00956837077573,
        "to_latitude": 21.009822516133138,
        "to_longitude": 105.81777770832659,
        "from_longitude": 105.81798021510991,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2472,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81746237067803,
          21.009360279278074,
          105.81756816071872,
          21.009573069842165
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81746237067803,
            21.009573069842165
          ],
          [
            105.81756816071872,
            21.009436603128353
          ],
          [
            105.81748505936227,
            21.009360279278074
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 79 Thái Thịnh",
        "diemDau": "5-1",
        "diemCuoi": "17-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009573069842165,
        "to_latitude": 21.009360279278074,
        "to_longitude": 105.81748505936227,
        "from_longitude": 105.81746237067803,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2473,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81777770832659,
          21.009822516133138,
          105.81792026651891,
          21.009935212999178
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81777770832659,
            21.009822516133138
          ],
          [
            105.81792026651891,
            21.009935212999178
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 79 Thái Thịnh",
        "diemDau": "10",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009822516133138,
        "to_latitude": 21.009935212999178,
        "to_longitude": 105.81792026651891,
        "from_longitude": 105.81777770832659,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2474,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81764699846997,
          21.009695535789696,
          105.81777770832659,
          21.009822516133138
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81777770832659,
            21.009822516133138
          ],
          [
            105.81764699846997,
            21.009695535789696
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 79 Thái Thịnh",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009822516133138,
        "to_latitude": 21.009695535789696,
        "to_longitude": 105.81764699846997,
        "from_longitude": 105.81777770832659,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2475,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82671080546933,
          21.013026212849727,
          105.8268771024302,
          21.013296627242312
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8268771024302,
            21.013296627242312
          ],
          [
            105.82671080546933,
            21.013026212849727
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 1076,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.013296627242312,
        "to_latitude": 21.013026212849727,
        "to_longitude": 105.82671080546933,
        "from_longitude": 105.8268771024302,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2476,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82653377966854,
          21.012745782591185,
          105.82671080546933,
          21.013026212849727
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82671080546933,
            21.013026212849727
          ],
          [
            105.82653377966854,
            21.012745782591185
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 1076,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.013026212849727,
        "to_latitude": 21.012745782591185,
        "to_longitude": 105.82653377966854,
        "from_longitude": 105.82671080546933,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2477,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82638357596313,
          21.01248538259384,
          105.82653377966854,
          21.012745782591185
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82653377966854,
            21.012745782591185
          ],
          [
            105.82638357596313,
            21.01248538259384
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 1076,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.012745782591185,
        "to_latitude": 21.01248538259384,
        "to_longitude": 105.82638357596313,
        "from_longitude": 105.82653377966854,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2478,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82620923237904,
          21.012209959025437,
          105.82638357596313,
          21.01248538259384
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82638357596313,
            21.01248538259384
          ],
          [
            105.82620923237904,
            21.012209959025437
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 1076,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.01248538259384,
        "to_latitude": 21.012209959025437,
        "to_longitude": 105.82620923237904,
        "from_longitude": 105.82638357596313,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2479,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82604561762594,
          21.011944550377017,
          105.82620923237904,
          21.012209959025437
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82620923237904,
            21.012209959025437
          ],
          [
            105.82604561762594,
            21.011944550377017
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 1076,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.012209959025437,
        "to_latitude": 21.011944550377017,
        "to_longitude": 105.82604561762594,
        "from_longitude": 105.82620923237904,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2480,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82587932067406,
          21.011691660566733,
          105.82604561762594,
          21.011944550377017
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82604561762594,
            21.011944550377017
          ],
          [
            105.82587932067406,
            21.011691660566733
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 1076,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.011944550377017,
        "to_latitude": 21.011691660566733,
        "to_longitude": 105.82587932067406,
        "from_longitude": 105.82604561762594,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2481,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82571302371322,
          21.01143376259569,
          105.82587932067406,
          21.011691660566733
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82587932067406,
            21.011691660566733
          ],
          [
            105.82571302371322,
            21.01143376259569
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 1076,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.011691660566733,
        "to_latitude": 21.01143376259569,
        "to_longitude": 105.82571302371322,
        "from_longitude": 105.82587932067406,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2482,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82556013780004,
          21.011198399008265,
          105.82571302371322,
          21.01143376259569
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82571302371322,
            21.01143376259569
          ],
          [
            105.82556013780004,
            21.011198399008265
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 1076,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.01143376259569,
        "to_latitude": 21.011198399008265,
        "to_longitude": 105.82556013780004,
        "from_longitude": 105.82571302371322,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2483,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82536433653605,
          21.01090544593151,
          105.82556013780004,
          21.011198399008265
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82556013780004,
            21.011198399008265
          ],
          [
            105.82536433653605,
            21.01090544593151
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 1076,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.011198399008265,
        "to_latitude": 21.01090544593151,
        "to_longitude": 105.82536433653605,
        "from_longitude": 105.82556013780004,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2484,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8251980395842,
          21.010549895281194,
          105.82536433653605,
          21.01090544593151
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82536433653605,
            21.01090544593151
          ],
          [
            105.8251980395842,
            21.010549895281194
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 1076,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.01090544593151,
        "to_latitude": 21.010549895281194,
        "to_longitude": 105.8251980395842,
        "from_longitude": 105.82536433653605,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2485,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82503174263233,
          21.010229398203517,
          105.8251980395842,
          21.010549895281194
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8251980395842,
            21.010549895281194
          ],
          [
            105.82503174263233,
            21.010229398203517
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 1076,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.010549895281194,
        "to_latitude": 21.010229398203517,
        "to_longitude": 105.82503174263233,
        "from_longitude": 105.8251980395842,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2486,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82486008124701,
          21.009906396536827,
          105.82503174263233,
          21.010229398203517
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82503174263233,
            21.010229398203517
          ],
          [
            105.82486008124701,
            21.009906396536827
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 1076,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.010229398203517,
        "to_latitude": 21.009906396536827,
        "to_longitude": 105.82486008124701,
        "from_longitude": 105.82503174263233,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2487,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82469378428617,
          21.009598417550105,
          105.82486008124701,
          21.009906396536827
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82486008124701,
            21.009906396536827
          ],
          [
            105.82469378428617,
            21.009598417550105
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 1076,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.009906396536827,
        "to_latitude": 21.009598417550105,
        "to_longitude": 105.82469378428617,
        "from_longitude": 105.82486008124701,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2488,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82460258918701,
          21.009363051067854,
          105.82469378428617,
          21.009598417550105
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82469378428617,
            21.009598417550105
          ],
          [
            105.82460258918701,
            21.009363051067854
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 1076,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.009598417550105,
        "to_latitude": 21.009363051067854,
        "to_longitude": 105.82460258918701,
        "from_longitude": 105.82469378428617,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2489,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82439337685734,
          21.00876962541918,
          105.82460258918701,
          21.009363051067854
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82460258918701,
            21.009363051067854
          ],
          [
            105.82439337685734,
            21.00876962541918
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 1076,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.009363051067854,
        "to_latitude": 21.00876962541918,
        "to_longitude": 105.82439337685734,
        "from_longitude": 105.82460258918701,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2490,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82415734246827,
          21.00852924979993,
          105.82439337685734,
          21.00876962541918
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82439337685734,
            21.00876962541918
          ],
          [
            105.82415734246827,
            21.00852924979993
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 1076,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.00876962541918,
        "to_latitude": 21.00852924979993,
        "to_longitude": 105.82415734246827,
        "from_longitude": 105.82439337685734,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2491,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8239964099229,
          21.008228779731205,
          105.82415734246827,
          21.00852924979993
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82415734246827,
            21.00852924979993
          ],
          [
            105.8239964099229,
            21.008228779731205
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 1076,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.00852924979993,
        "to_latitude": 21.008228779731205,
        "to_longitude": 105.8239964099229,
        "from_longitude": 105.82415734246827,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2492,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82385157063295,
          21.00793832475656,
          105.8239964099229,
          21.008228779731205
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8239964099229,
            21.008228779731205
          ],
          [
            105.82385157063295,
            21.00793832475656
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 1076,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.008228779731205,
        "to_latitude": 21.00793832475656,
        "to_longitude": 105.82385157063295,
        "from_longitude": 105.8239964099229,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2493,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82371746018298,
          21.00770796351171,
          105.82385157063295,
          21.00793832475656
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82385157063295,
            21.00793832475656
          ],
          [
            105.82371746018298,
            21.00770796351171
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 1076,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.00793832475656,
        "to_latitude": 21.00770796351171,
        "to_longitude": 105.82371746018298,
        "from_longitude": 105.82385157063295,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2494,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82349483685054,
          21.007278539144853,
          105.82371746018298,
          21.00770796351171
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82371746018298,
            21.00770796351171
          ],
          [
            105.82349483685054,
            21.007278539144853
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 1076,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.00770796351171,
        "to_latitude": 21.007278539144853,
        "to_longitude": 105.82349483685054,
        "from_longitude": 105.82371746018298,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2495,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82329903559555,
          21.006889176633635,
          105.82349483685054,
          21.007278539144853
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82349483685054,
            21.007278539144853
          ],
          [
            105.82329903559555,
            21.006889176633635
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 1076,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "01",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.007278539144853,
        "to_latitude": 21.006889176633635,
        "to_longitude": 105.82329903559555,
        "from_longitude": 105.82349483685054,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2496,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82322963343655,
          21.006708892687506,
          105.82329903559555,
          21.006889176633635
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82329903559555,
            21.006889176633635
          ],
          [
            105.82322963343655,
            21.006708892687506
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.006889176633635,
        "to_latitude": 21.006708892687506,
        "to_longitude": 105.82322963343655,
        "from_longitude": 105.82329903559555,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2497,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82671080546933,
          21.01302370901586,
          105.8269280643773,
          21.013083801138908
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82671080546933,
            21.013026212849727
          ],
          [
            105.82681842910215,
            21.013083801138908
          ],
          [
            105.8269280643773,
            21.01302370901586
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "02",
        "diemCuoi": "2-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.013026212849727,
        "to_latitude": 21.01302370901586,
        "to_longitude": 105.8269280643773,
        "from_longitude": 105.82671080546933,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2498,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82653377966854,
          21.012720744155647,
          105.82676444962419,
          21.01277833255691
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82653377966854,
            21.012745782591185
          ],
          [
            105.82661994563044,
            21.01277833255691
          ],
          [
            105.82676444962419,
            21.012720744155647
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "3",
        "diemCuoi": "3-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.012745782591185,
        "to_latitude": 21.012720744155647,
        "to_longitude": 105.82676444962419,
        "from_longitude": 105.82653377966854,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2499,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82676444962419,
          21.012623094192783,
          105.82696561530365,
          21.012720744155647
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82676444962419,
            21.012720744155647
          ],
          [
            105.82696561530365,
            21.012623094192783
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "3-1",
        "diemCuoi": "3-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.012720744155647,
        "to_latitude": 21.012623094192783,
        "to_longitude": 105.82696561530365,
        "from_longitude": 105.82676444962419,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2500,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82644526675017,
          21.01214235498517,
          105.82674030974552,
          21.012312616965623
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82674030974552,
            21.01214235498517
          ],
          [
            105.82644526675017,
            21.012312616965623
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.01214235498517,
        "to_latitude": 21.012312616965623,
        "to_longitude": 105.82644526675017,
        "from_longitude": 105.82674030974552,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2501,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82634367809825,
          21.012312616965623,
          105.82644526675017,
          21.01248538259384
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82638357596313,
            21.01248538259384
          ],
          [
            105.82634367809825,
            21.012365197825403
          ],
          [
            105.82644526675017,
            21.012312616965623
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "4",
        "diemCuoi": "4-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.01248538259384,
        "to_latitude": 21.012312616965623,
        "to_longitude": 105.82644526675017,
        "from_longitude": 105.82638357596313,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2502,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.826549872906,
          21.01177428798235,
          105.82674030974552,
          21.01214235498517
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82674030974552,
            21.01214235498517
          ],
          [
            105.826549872906,
            21.01177428798235
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "4-2",
        "diemCuoi": "4-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.01214235498517,
        "to_latitude": 21.01177428798235,
        "to_longitude": 105.826549872906,
        "from_longitude": 105.82674030974552,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2503,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82647208884555,
          21.011513886289073,
          105.826549872906,
          21.01177428798235
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.826549872906,
            21.01177428798235
          ],
          [
            105.82649388180369,
            21.01169666828753
          ],
          [
            105.82651265726685,
            21.011596513792277
          ],
          [
            105.82647208884555,
            21.011513886289073
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "4-3",
        "diemCuoi": "4-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.01177428798235,
        "to_latitude": 21.011513886289073,
        "to_longitude": 105.82647208884555,
        "from_longitude": 105.826549872906,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2504,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.826549872906,
          21.01170417987852,
          105.82665716126061,
          21.01177428798235
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82665716126061,
            21.01170417987852
          ],
          [
            105.826549872906,
            21.01177428798235
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "4-3",
        "diemCuoi": "4-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.01170417987852,
        "to_latitude": 21.01177428798235,
        "to_longitude": 105.826549872906,
        "from_longitude": 105.82665716126061,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2505,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82665716126061,
          21.011626560153942,
          105.82679663613504,
          21.01170417987852
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82665716126061,
            21.01170417987852
          ],
          [
            105.82679663613504,
            21.011626560153942
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "4-5",
        "diemCuoi": "4-6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.01170417987852,
        "to_latitude": 21.011626560153942,
        "to_longitude": 105.82679663613504,
        "from_longitude": 105.82665716126061,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2506,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82679663613504,
          21.011551444253495,
          105.82693074658503,
          21.011626560153942
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82679663613504,
            21.011626560153942
          ],
          [
            105.82693074658503,
            21.011551444253495
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "4-6",
        "diemCuoi": "4-7",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.011626560153942,
        "to_latitude": 21.011551444253495,
        "to_longitude": 105.82693074658503,
        "from_longitude": 105.82679663613504,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2507,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82693074658503,
          21.011458801257337,
          105.82710240796138,
          21.011551444253495
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82693074658503,
            21.011551444253495
          ],
          [
            105.82710240796138,
            21.011458801257337
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "4-7",
        "diemCuoi": "4-8",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.011551444253495,
        "to_latitude": 21.011458801257337,
        "to_longitude": 105.82710240796138,
        "from_longitude": 105.82693074658503,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2508,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82569022492943,
          21.01125598800984,
          105.8258524985607,
          21.01143376259569
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82571302371322,
            21.01143376259569
          ],
          [
            105.82569022492943,
            21.011373043830524
          ],
          [
            105.8258524985607,
            21.01125598800984
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "08",
        "diemCuoi": "8-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.01143376259569,
        "to_latitude": 21.01125598800984,
        "to_longitude": 105.8258524985607,
        "from_longitude": 105.82571302371322,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2509,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8258524985607,
          21.011005600904177,
          105.82595978694225,
          21.01125598800984
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8258524985607,
            21.01125598800984
          ],
          [
            105.82595978694225,
            21.011190261428812
          ],
          [
            105.8258524985607,
            21.011005600904177
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "8-1",
        "diemCuoi": "8-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.01125598800984,
        "to_latitude": 21.011005600904177,
        "to_longitude": 105.8258524985607,
        "from_longitude": 105.8258524985607,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2510,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8258524985607,
          21.01080028316431,
          105.82599733785064,
          21.011005600904177
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8258524985607,
            21.011005600904177
          ],
          [
            105.82592894153525,
            21.010834711457342
          ],
          [
            105.82599733785064,
            21.01080028316431
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 58,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "8-2",
        "diemCuoi": "8-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.011005600904177,
        "to_latitude": 21.01080028316431,
        "to_longitude": 105.82599733785064,
        "from_longitude": 105.8258524985607,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2511,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82552091044671,
          21.010861315143966,
          105.8256496564812,
          21.010936431392768
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8256496564812,
            21.010861315143966
          ],
          [
            105.82552091044671,
            21.010936431392768
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "10-1",
        "diemCuoi": "10-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.010861315143966,
        "to_latitude": 21.010936431392768,
        "to_longitude": 105.82552091044671,
        "from_longitude": 105.8256496564812,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2512,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82536433653605,
          21.01090544593151,
          105.82552091044671,
          21.01096616489034
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82552091044671,
            21.010936431392768
          ],
          [
            105.82542736845394,
            21.01096616489034
          ],
          [
            105.82536433653605,
            21.01090544593151
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.010936431392768,
        "to_latitude": 21.01090544593151,
        "to_longitude": 105.82536433653605,
        "from_longitude": 105.82552091044671,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2513,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82469378428617,
          21.009566805854487,
          105.82479134959053,
          21.009598417550105
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82469378428617,
            21.009598417550105
          ],
          [
            105.82479134959053,
            21.009566805854487
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "15",
        "diemCuoi": "15-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.009598417550105,
        "to_latitude": 21.009566805854487,
        "to_longitude": 105.82479134959053,
        "from_longitude": 105.82469378428617,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2514,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82495664077062,
          21.00932142371673,
          105.82506493491496,
          21.009459138231513
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82506493491496,
            21.00932142371673
          ],
          [
            105.82495664077062,
            21.009362425090433
          ],
          [
            105.82496837539132,
            21.009459138231513
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "15-2",
        "diemCuoi": "15-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.00932142371673,
        "to_latitude": 21.009459138231513,
        "to_longitude": 105.82496837539132,
        "from_longitude": 105.82506493491496,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2515,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82479134959053,
          21.009459138231513,
          105.82496837539132,
          21.009566805854487
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82496837539132,
            21.009459138231513
          ],
          [
            105.82479134959053,
            21.009566805854487
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "15-1",
        "diemCuoi": "15-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.009459138231513,
        "to_latitude": 21.009566805854487,
        "to_longitude": 105.82479134959053,
        "from_longitude": 105.82496837539132,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2516,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82439337685734,
          21.00876962541918,
          105.82468305542824,
          21.00888981308358
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82439337685734,
            21.00876962541918
          ],
          [
            105.82468305542824,
            21.00888981308358
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 98,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "17",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.00876962541918,
        "to_latitude": 21.00888981308358,
        "to_longitude": 105.82468305542824,
        "from_longitude": 105.82439337685734,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2517,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82468305542824,
          21.00888981308358,
          105.82496200517714,
          21.008946151006306
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82468305542824,
            21.00888981308358
          ],
          [
            105.82496200517714,
            21.008946151006306
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 98,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "17",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.00888981308358,
        "to_latitude": 21.008946151006306,
        "to_longitude": 105.82496200517714,
        "from_longitude": 105.82468305542824,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2518,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82317297178678,
          21.006435962395685,
          105.82322963343655,
          21.006708892687506
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82317297178678,
            21.006435962395685
          ],
          [
            105.82322963343655,
            21.006708892687506
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 98,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "17",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.006435962395685,
        "to_latitude": 21.006708892687506,
        "to_longitude": 105.82322963343655,
        "from_longitude": 105.82317297178678,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2519,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8245274873253,
          21.008842238797016,
          105.82468305542824,
          21.00888981308358
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82468305542824,
            21.00888981308358
          ],
          [
            105.8245274873253,
            21.008842238797016
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 98,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "17",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.00888981308358,
        "to_latitude": 21.008842238797016,
        "to_longitude": 105.8245274873253,
        "from_longitude": 105.82468305542824,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2520,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83523579890372,
          21.02088555712091,
          105.83558985048737,
          21.021023260958025
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83523579890372,
            21.021023260958025
          ],
          [
            105.83558985048737,
            21.02088555712091
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.021023260958025,
        "to_latitude": 21.02088555712091,
        "to_longitude": 105.83558985048737,
        "from_longitude": 105.83523579890372,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2521,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83558985048737,
          21.020802934757395,
          105.83583124930094,
          21.02088555712091
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83558985048737,
            21.02088555712091
          ],
          [
            105.83583124930094,
            21.020802934757395
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.02088555712091,
        "to_latitude": 21.020802934757395,
        "to_longitude": 105.83583124930094,
        "from_longitude": 105.83558985048737,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2522,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83583124930094,
          21.02068651225859,
          105.83612495119242,
          21.020802934757395
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83583124930094,
            21.020802934757395
          ],
          [
            105.83612495119242,
            21.02068651225859
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.020802934757395,
        "to_latitude": 21.02068651225859,
        "to_longitude": 105.83612495119242,
        "from_longitude": 105.83583124930094,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2523,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83612495119242,
          21.02058886751237,
          105.8363703733086,
          21.02068651225859
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83612495119242,
            21.02068651225859
          ],
          [
            105.8363703733086,
            21.02058886751237
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.02068651225859,
        "to_latitude": 21.02058886751237,
        "to_longitude": 105.8363703733086,
        "from_longitude": 105.83612495119242,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2524,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8363703733086,
          21.02047870413138,
          105.83664261752917,
          21.02058886751237
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8363703733086,
            21.02058886751237
          ],
          [
            105.83664261752917,
            21.02047870413138
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.02058886751237,
        "to_latitude": 21.02047870413138,
        "to_longitude": 105.83664261752917,
        "from_longitude": 105.8363703733086,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2525,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83664261752917,
          21.020314710767227,
          105.83698594028183,
          21.02047870413138
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83664261752917,
            21.02047870413138
          ],
          [
            105.83698594028183,
            21.020314710767227
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.02047870413138,
        "to_latitude": 21.020314710767227,
        "to_longitude": 105.83698594028183,
        "from_longitude": 105.83664261752917,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2526,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83698594028183,
          21.02019828788719,
          105.83722331578379,
          21.020314710767227
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83698594028183,
            21.020314710767227
          ],
          [
            105.83722331578379,
            21.02019828788719
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.020314710767227,
        "to_latitude": 21.02019828788719,
        "to_longitude": 105.83722331578379,
        "from_longitude": 105.83698594028183,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2527,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83722331578379,
          21.020078109335135,
          105.83745666796514,
          21.02019828788719
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83722331578379,
            21.02019828788719
          ],
          [
            105.83745666796514,
            21.020078109335135
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.02019828788719,
        "to_latitude": 21.020078109335135,
        "to_longitude": 105.83745666796514,
        "from_longitude": 105.83722331578379,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2528,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83745666796514,
          21.01972758800448,
          105.83759857916046,
          21.020078109335135
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83745666796514,
            21.020078109335135
          ],
          [
            105.83759857916046,
            21.019979838260145
          ],
          [
            105.8375261595155,
            21.01981459248443
          ],
          [
            105.8375559096965,
            21.01972758800448
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.020078109335135,
        "to_latitude": 21.01972758800448,
        "to_longitude": 105.8375559096965,
        "from_longitude": 105.83745666796514,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2529,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83735876733765,
          21.01949599310223,
          105.8375559096965,
          21.01972758800448
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8375559096965,
            21.01972758800448
          ],
          [
            105.83735876733765,
            21.01949599310223
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.01972758800448,
        "to_latitude": 21.01949599310223,
        "to_longitude": 105.83735876733765,
        "from_longitude": 105.8375559096965,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2530,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83716028386591,
          21.01925688663674,
          105.83735876733765,
          21.01949599310223
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83735876733765,
            21.01949599310223
          ],
          [
            105.83720965885821,
            21.019373936185524
          ],
          [
            105.83716028386591,
            21.01925688663674
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.01949599310223,
        "to_latitude": 21.01925688663674,
        "to_longitude": 105.83716028386591,
        "from_longitude": 105.83735876733765,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2531,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83612495119242,
          21.020599508289944,
          105.83613141083389,
          21.02068651225859
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83612495119242,
            21.02068651225859
          ],
          [
            105.83613141083389,
            21.020599508289944
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 8,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ H1 TT Văn Chương8",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.02068651225859,
        "to_latitude": 21.020599508289944,
        "to_longitude": 105.83613141083389,
        "from_longitude": 105.83612495119242,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2532,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8363703733086,
          21.02049059677403,
          105.83638085627068,
          21.02058886751237
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8363703733086,
            21.02058886751237
          ],
          [
            105.83638085627068,
            21.02049059677403
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 8,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ H1 TT Văn Chương",
        "diemDau": "05",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.02058886751237,
        "to_latitude": 21.02049059677403,
        "to_longitude": 105.83638085627068,
        "from_longitude": 105.8363703733086,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2533,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83638085627068,
          21.02040421861877,
          105.83656458759084,
          21.02049059677403
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83638085627068,
            21.02049059677403
          ],
          [
            105.83656458759084,
            21.02040421861877
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ H1 TT Văn Chương",
        "diemDau": "5-1",
        "diemCuoi": "5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.02049059677403,
        "to_latitude": 21.02040421861877,
        "to_longitude": 105.83656458759084,
        "from_longitude": 105.83638085627068,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2534,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83660079741331,
          21.02047870413138,
          105.83664261752917,
          21.020705916015565
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83664261752917,
            21.02047870413138
          ],
          [
            105.83660079741331,
            21.02058323416067
          ],
          [
            105.83663834833966,
            21.020705916015565
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 275 Văn Chương",
        "diemDau": "06",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.02047870413138,
        "to_latitude": 21.020705916015565,
        "to_longitude": 105.83663834833966,
        "from_longitude": 105.83664261752917,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2535,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8366050666028,
          21.020705916015565,
          105.83663834833966,
          21.020946897936174
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83663834833966,
            21.020705916015565
          ],
          [
            105.8366050666028,
            21.020946897936174
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 275 Văn Chương",
        "diemDau": "6-1",
        "diemCuoi": "6-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.020705916015565,
        "to_latitude": 21.020946897936174,
        "to_longitude": 105.8366050666028,
        "from_longitude": 105.83663834833966,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2536,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83654203469392,
          21.020946897936174,
          105.8366050666028,
          21.02104704615301
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8366050666028,
            21.020946897936174
          ],
          [
            105.83656056427922,
            21.020948775717475
          ],
          [
            105.83654203469392,
            21.02104704615301
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 275 Văn Chương",
        "diemDau": "6-2",
        "diemCuoi": "6-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.020946897936174,
        "to_latitude": 21.02104704615301,
        "to_longitude": 105.83654203469392,
        "from_longitude": 105.8366050666028,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2537,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83698594028183,
          21.020314710767227,
          105.83705140852956,
          21.020474322632854
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83698594028183,
            21.020314710767227
          ],
          [
            105.83705140852956,
            21.020474322632854
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 297 Văn Chương",
        "diemDau": "7",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.020314710767227,
        "to_latitude": 21.020474322632854,
        "to_longitude": 105.83705140852956,
        "from_longitude": 105.83698594028183,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2538,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83612495119242,
          21.02068651225859,
          105.83613141083389,
          21.020961294247996
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83612495119242,
            21.02068651225859
          ],
          [
            105.83613141083389,
            21.020961294247996
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 253 Văn Chương",
        "diemDau": "04",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.02068651225859,
        "to_latitude": 21.020961294247996,
        "to_longitude": 105.83613141083389,
        "from_longitude": 105.83612495119242,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2539,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83603216909354,
          21.020961294247996,
          105.83613141083389,
          21.021104005421808
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83613141083389,
            21.020961294247996
          ],
          [
            105.83603216909354,
            21.021104005421808
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 253 Văn Chương",
        "diemDau": "4-2",
        "diemCuoi": "44-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.020961294247996,
        "to_latitude": 21.021104005421808,
        "to_longitude": 105.83603216909354,
        "from_longitude": 105.83613141083389,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2540,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83595170282534,
          21.021104005421808,
          105.83603216909354,
          21.021260486816104
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83603216909354,
            21.021104005421808
          ],
          [
            105.83595170282534,
            21.021260486816104
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 253 Văn Chương",
        "diemDau": "4-5",
        "diemCuoi": "4-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.021104005421808,
        "to_latitude": 21.021260486816104,
        "to_longitude": 105.83595170282534,
        "from_longitude": 105.83603216909354,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2541,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83574114941878,
          21.021260486816104,
          105.83595170282534,
          21.021410708799735
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83595170282534,
            21.021260486816104
          ],
          [
            105.83574114941878,
            21.021410708799735
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 253 Văn Chương",
        "diemDau": "4-3",
        "diemCuoi": "4-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.021260486816104,
        "to_latitude": 21.021410708799735,
        "to_longitude": 105.83574114941878,
        "from_longitude": 105.83595170282534,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2542,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83558985048737,
          21.02088555712091,
          105.83569823407694,
          21.021041412818604
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83558985048737,
            21.02088555712091
          ],
          [
            105.83565397763124,
            21.02093375347979
          ],
          [
            105.83569823407694,
            21.021041412818604
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 8,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ H1 TT Văn Chương",
        "diemDau": "02",
        "diemCuoi": "2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.02088555712091,
        "to_latitude": 21.021041412818604,
        "to_longitude": 105.83569823407694,
        "from_longitude": 105.83558985048737,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2543,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8349305188242,
          21.021023260958025,
          105.83523579890372,
          21.021216046117054
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83523579890372,
            21.021023260958025
          ],
          [
            105.8349305188242,
            21.021216046117054
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ  Văn Chương",
        "diemDau": "01",
        "diemCuoi": "00",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.021023260958025,
        "to_latitude": 21.021216046117054,
        "to_longitude": 105.8349305188242,
        "from_longitude": 105.83523579890372,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2544,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81781891540066,
          21.010082943273854,
          105.81803796933328,
          21.010348840882177
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81803796933328,
            21.010082943273854
          ],
          [
            105.81781891540066,
            21.010348840882177
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Thái Thịnh",
        "diemDau": "08",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.010082943273854,
        "to_latitude": 21.010348840882177,
        "to_longitude": 105.81781891540066,
        "from_longitude": 105.81803796933328,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2545,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81781891540066,
          21.010348840882177,
          105.81796900517365,
          21.010481392790766
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81781891540066,
            21.010348840882177
          ],
          [
            105.81796900517365,
            21.010481392790766
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Thái Thịnh",
        "diemDau": "13",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.010348840882177,
        "to_latitude": 21.010481392790766,
        "to_longitude": 105.81796900517365,
        "from_longitude": 105.81781891540066,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2546,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81754301504137,
          21.010064141613384,
          105.81768453908836,
          21.010175574284663
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81754301504137,
            21.010064141613384
          ],
          [
            105.81768453908836,
            21.010175574284663
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Thái Thịnh",
        "diemDau": "15",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.010064141613384,
        "to_latitude": 21.010175574284663,
        "to_longitude": 105.81768453908836,
        "from_longitude": 105.81754301504137,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2547,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81741556775452,
          21.009946915723976,
          105.81754301504137,
          21.010064141613384
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81754301504137,
            21.010064141613384
          ],
          [
            105.81741556775452,
            21.009946915723976
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Thái Thịnh",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.010064141613384,
        "to_latitude": 21.009946915723976,
        "to_longitude": 105.81741556775452,
        "from_longitude": 105.81754301504137,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2548,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8171984639318,
          21.009573069842165,
          105.81746237067803,
          21.009788171576517
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81746237067803,
            21.009573069842165
          ],
          [
            105.8171984639318,
            21.009788171576517
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Thái Thịnh",
        "diemDau": "17-1",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009573069842165,
        "to_latitude": 21.009788171576517,
        "to_longitude": 105.8171984639318,
        "from_longitude": 105.81746237067803,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2549,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8170434536383,
          21.009649121445527,
          105.8171984639318,
          21.009788171576517
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8171984639318,
            21.009788171576517
          ],
          [
            105.8170434536383,
            21.009649121445527
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Thái Thịnh",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009788171576517,
        "to_latitude": 21.009649121445527,
        "to_longitude": 105.8170434536383,
        "from_longitude": 105.8171984639318,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2550,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8169240953309,
          21.009536446087107,
          105.8170434536383,
          21.009649121445527
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8170434536383,
            21.009649121445527
          ],
          [
            105.8169240953309,
            21.009536446087107
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Thái Thịnh",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009649121445527,
        "to_latitude": 21.009536446087107,
        "to_longitude": 105.8169240953309,
        "from_longitude": 105.8170434536383,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2551,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81678462046546,
          21.00941751089293,
          105.8169240953309,
          21.009536446087107
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8169240953309,
            21.009536446087107
          ],
          [
            105.81678462046546,
            21.00941751089293
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Thái Thịnh",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009536446087107,
        "to_latitude": 21.00941751089293,
        "to_longitude": 105.81678462046546,
        "from_longitude": 105.8169240953309,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2552,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81669610756506,
          21.009319858768613,
          105.81678462046546,
          21.00941751089293
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81678462046546,
            21.00941751089293
          ],
          [
            105.81669610756506,
            21.009319858768613
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Thái Thịnh",
        "diemDau": "20",
        "diemCuoi": "20-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.00941751089293,
        "to_latitude": 21.009319858768613,
        "to_longitude": 105.81669610756506,
        "from_longitude": 105.81678462046546,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2553,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81654053944415,
          21.009182144125273,
          105.81669610756506,
          21.009319858768613
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81669610756506,
            21.009319858768613
          ],
          [
            105.81654053944415,
            21.009182144125273
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Thái Thịnh",
        "diemDau": "20-1",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009319858768613,
        "to_latitude": 21.009182144125273,
        "to_longitude": 105.81654053944415,
        "from_longitude": 105.81669610756506,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2554,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81634742040586,
          21.008826589367803,
          105.81647348421465,
          21.009056948884588
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81634742040586,
            21.009056948884588
          ],
          [
            105.81646208481827,
            21.008949906867734
          ],
          [
            105.81647348421465,
            21.008826589367803
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Thái Thịnh",
        "diemDau": "74",
        "diemCuoi": "75",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009056948884588,
        "to_latitude": 21.008826589367803,
        "to_longitude": 105.81647348421465,
        "from_longitude": 105.81634742040586,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2555,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81754301504137,
          21.009822516133138,
          105.81777770832659,
          21.010064141613384
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81777770832659,
            21.009822516133138
          ],
          [
            105.81754301504137,
            21.010064141613384
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Thái Thịnh",
        "diemDau": "10",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009822516133138,
        "to_latitude": 21.010064141613384,
        "to_longitude": 105.81754301504137,
        "from_longitude": 105.81777770832659,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2556,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81761409358245,
          21.010348840882177,
          105.81781891540066,
          21.010558658862955
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81781891540066,
            21.010348840882177
          ],
          [
            105.81761409358245,
            21.010558658862955
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thái Thinh 1",
        "diemDau": "13",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.010348840882177,
        "to_latitude": 21.010558658862955,
        "to_longitude": 105.81761409358245,
        "from_longitude": 105.81781891540066,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2557,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81732575611545,
          21.010064141613384,
          105.81754301504137,
          21.01028072785049
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81754301504137,
            21.010064141613384
          ],
          [
            105.81732575611545,
            21.01028072785049
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thái Thinh 1",
        "diemDau": "15",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.010064141613384,
        "to_latitude": 21.01028072785049,
        "to_longitude": 105.81732575611545,
        "from_longitude": 105.81754301504137,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2558,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81696332257647,
          21.009788171576517,
          105.8171984639318,
          21.010021262520386
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8171984639318,
            21.009788171576517
          ],
          [
            105.81696332257647,
            21.010021262520386
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thái Thinh 1",
        "diemDau": "17",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009788171576517,
        "to_latitude": 21.010021262520386,
        "to_longitude": 105.81696332257647,
        "from_longitude": 105.8171984639318,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2559,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8167031483087,
          21.009536446087107,
          105.8169240953309,
          21.00979841617279
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8169240953309,
            21.009536446087107
          ],
          [
            105.8167031483087,
            21.00979841617279
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thái Thinh 1",
        "diemDau": "19",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009536446087107,
        "to_latitude": 21.00979841617279,
        "to_longitude": 105.8167031483087,
        "from_longitude": 105.8169240953309,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2560,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81644431513584,
          21.009418293369013,
          105.81652897235027,
          21.009496696710702
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81644431513584,
            21.009496696710702
          ],
          [
            105.81652897235027,
            21.009418293369013
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thái Thinh 1",
        "diemDau": "27",
        "diemCuoi": "21-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009496696710702,
        "to_latitude": 21.009418293369013,
        "to_longitude": 105.81652897235027,
        "from_longitude": 105.81644431513584,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2561,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81612647337468,
          21.009056948884588,
          105.81634742040586,
          21.009223771526152
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81612647337468,
            21.009223771526152
          ],
          [
            105.81623275593057,
            21.00916148692008
          ],
          [
            105.81634742040586,
            21.009056948884588
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thái Thinh 1",
        "diemDau": "73",
        "diemCuoi": "74",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009223771526152,
        "to_latitude": 21.009056948884588,
        "to_longitude": 105.81634742040586,
        "from_longitude": 105.81612647337468,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2562,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81736565395335,
          21.010558658862955,
          105.81761409358245,
          21.010793710477376
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81761409358245,
            21.010558658862955
          ],
          [
            105.81736565395335,
            21.010793710477376
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "diemDau": "23",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.010558658862955,
        "to_latitude": 21.010793710477376,
        "to_longitude": 105.81736565395335,
        "from_longitude": 105.81761409358245,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2563,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81736565395335,
          21.010793710477376,
          105.81767328039525,
          21.011080353206232
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81736565395335,
            21.010793710477376
          ],
          [
            105.81767328039525,
            21.011080353206232
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "diemDau": "30",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.010793710477376,
        "to_latitude": 21.011080353206232,
        "to_longitude": 105.81767328039525,
        "from_longitude": 105.81736565395335,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2564,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81713632508362,
          21.010793710477376,
          105.81736565395335,
          21.011019059210795
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81736565395335,
            21.010793710477376
          ],
          [
            105.81713632508362,
            21.011019059210795
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 113 Thái Thinh 1",
        "diemDau": "30",
        "diemCuoi": "40",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.010793710477376,
        "to_latitude": 21.011019059210795,
        "to_longitude": 105.81713632508362,
        "from_longitude": 105.81736565395335,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2565,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81745460534604,
          21.011080353206232,
          105.81767328039525,
          21.01130276503445
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81767328039525,
            21.011080353206232
          ],
          [
            105.81745460534604,
            21.01130276503445
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 113 Thái Thinh 1",
        "diemDau": "28",
        "diemCuoi": "39",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.011080353206232,
        "to_latitude": 21.01130276503445,
        "to_longitude": 105.81745460534604,
        "from_longitude": 105.81767328039525,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2566,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81767328039525,
          21.01085662036306,
          105.81788365558631,
          21.011080353206232
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81767328039525,
            21.011080353206232
          ],
          [
            105.81788365558631,
            21.01085662036306
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thái Thịnh 1",
        "diemDau": "28",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.011080353206232,
        "to_latitude": 21.01085662036306,
        "to_longitude": 105.81788365558631,
        "from_longitude": 105.81767328039525,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2567,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81721410914407,
          21.010691051496945,
          105.81736565395335,
          21.010793710477376
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81736565395335,
            21.010793710477376
          ],
          [
            105.81721410914407,
            21.010691051496945
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.010793710477376,
        "to_latitude": 21.010691051496945,
        "to_longitude": 105.81721410914407,
        "from_longitude": 105.81736565395335,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2568,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81706289963095,
          21.01028072785049,
          105.81732575611545,
          21.010522352588627
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81732575611545,
            21.01028072785049
          ],
          [
            105.81706289963095,
            21.010522352588627
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "diemDau": "24",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.01028072785049,
        "to_latitude": 21.010522352588627,
        "to_longitude": 105.81706289963095,
        "from_longitude": 105.81732575611545,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2569,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81690029068554,
          21.010418128497406,
          105.81706289963095,
          21.010522352588627
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81706289963095,
            21.010522352588627
          ],
          [
            105.81690029068554,
            21.010418128497406
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.010522352588627,
        "to_latitude": 21.010418128497406,
        "to_longitude": 105.81690029068554,
        "from_longitude": 105.81706289963095,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2570,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81674472256464,
          21.010021262520386,
          105.81696332257647,
          21.010281666810815
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81696332257647,
            21.010021262520386
          ],
          [
            105.81674472256464,
            21.010281666810815
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "diemDau": "25",
        "diemCuoi": "34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.010021262520386,
        "to_latitude": 21.010281666810815,
        "to_longitude": 105.81674472256464,
        "from_longitude": 105.81696332257647,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2571,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81659083085276,
          21.010146769927974,
          105.81674472256464,
          21.010281666810815
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81674472256464,
            21.010281666810815
          ],
          [
            105.81659083085276,
            21.010146769927974
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.010281666810815,
        "to_latitude": 21.010146769927974,
        "to_longitude": 105.81659083085276,
        "from_longitude": 105.81674472256464,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2572,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81643358634982,
          21.00979841617279,
          105.8167031483087,
          21.010009995006673
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8167031483087,
            21.00979841617279
          ],
          [
            105.81643358634982,
            21.010009995006673
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "diemDau": "26",
        "diemCuoi": "36",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.00979841617279,
        "to_latitude": 21.010009995006673,
        "to_longitude": 105.81643358634982,
        "from_longitude": 105.8167031483087,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2573,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81621900959568,
          21.009496696710702,
          105.81644431513584,
          21.00975334607569
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81644431513584,
            21.009496696710702
          ],
          [
            105.81621900959568,
            21.00975334607569
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "diemDau": "27",
        "diemCuoi": "38",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009496696710702,
        "to_latitude": 21.00975334607569,
        "to_longitude": 105.81621900959568,
        "from_longitude": 105.81644431513584,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2574,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81621900959568,
          21.00975334607569,
          105.81643358634982,
          21.010009995006673
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81643358634982,
            21.010009995006673
          ],
          [
            105.81621900959568,
            21.00975334607569
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "diemDau": "36",
        "diemCuoi": "38",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.010009995006673,
        "to_latitude": 21.00975334607569,
        "to_longitude": 105.81621900959568,
        "from_longitude": 105.81643358634982,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2575,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81589446233312,
          21.009223771526152,
          105.81612647337468,
          21.009522987633318
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81612647337468,
            21.009223771526152
          ],
          [
            105.81589446233312,
            21.009522987633318
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "diemDau": "73",
        "diemCuoi": "57",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009223771526152,
        "to_latitude": 21.009522987633318,
        "to_longitude": 105.81589446233312,
        "from_longitude": 105.81612647337468,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2576,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8156503812849,
          21.009302644443665,
          105.81589446233312,
          21.009522987633318
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81589446233312,
            21.009522987633318
          ],
          [
            105.8156503812849,
            21.009302644443665
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "diemDau": "57",
        "diemCuoi": "58",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009522987633318,
        "to_latitude": 21.009302644443665,
        "to_longitude": 105.8156503812849,
        "from_longitude": 105.81589446233312,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2577,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81547335549305,
          21.009091064596554,
          105.8156503812849,
          21.009302644443665
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8156503812849,
            21.009302644443665
          ],
          [
            105.81547335549305,
            21.009091064596554
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "diemDau": "58",
        "diemCuoi": "59",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009302644443665,
        "to_latitude": 21.009091064596554,
        "to_longitude": 105.81547335549305,
        "from_longitude": 105.8156503812849,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2578,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81526884924325,
          21.009091064596554,
          105.81547335549305,
          21.009227766521605
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81547335549305,
            21.009091064596554
          ],
          [
            105.81526884924325,
            21.009227766521605
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "diemDau": "59",
        "diemCuoi": "60",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.009091064596554,
        "to_latitude": 21.009227766521605,
        "to_longitude": 105.81526884924325,
        "from_longitude": 105.81547335549305,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2579,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81648354244429,
          21.010489802135666,
          105.81679736090283,
          21.01076022112633
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81679736090283,
            21.01076022112633
          ],
          [
            105.81648354244429,
            21.010489802135666
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 113 Thái Thịnh 1",
        "diemDau": "43",
        "diemCuoi": "42",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.01076022112633,
        "to_latitude": 21.010489802135666,
        "to_longitude": 105.81648354244429,
        "from_longitude": 105.81679736090283,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2580,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81621263933658,
          21.010226894313472,
          105.81648354244429,
          21.010489802135666
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81648354244429,
            21.010489802135666
          ],
          [
            105.81621263933658,
            21.010226894313472
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 113 Thái Thịnh 1",
        "diemDau": "44",
        "diemCuoi": "43",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.010489802135666,
        "to_latitude": 21.010226894313472,
        "to_longitude": 105.81621263933658,
        "from_longitude": 105.81648354244429,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2581,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81596855831525,
          21.010009056053015,
          105.81621263933658,
          21.010226894313472
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81621263933658,
            21.010226894313472
          ],
          [
            105.81596855831525,
            21.010009056053015
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 113 Thái Thịnh 1",
        "diemDau": "44",
        "diemCuoi": "45",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.010226894313472,
        "to_latitude": 21.010009056053015,
        "to_longitude": 105.81596855831525,
        "from_longitude": 105.81621263933658,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2582,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81621263933658,
          21.010009995006673,
          105.81643358634982,
          21.010226894313472
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81643358634982,
            21.010009995006673
          ],
          [
            105.81621263933658,
            21.010226894313472
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 113 Thái Thịnh 1",
        "diemDau": "36",
        "diemCuoi": "44",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.010009995006673,
        "to_latitude": 21.010226894313472,
        "to_longitude": 105.81621263933658,
        "from_longitude": 105.81643358634982,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2583,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81589446233312,
          21.009522987633318,
          105.81621900959568,
          21.00975334607569
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81621900959568,
            21.00975334607569
          ],
          [
            105.81589446233312,
            21.009522987633318
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐX",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N2/Thái Thinh 1",
        "diemDau": "38",
        "diemCuoi": "57",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cánh Đồng Xi",
        "from_latitude": 21.00975334607569,
        "to_latitude": 21.009522987633318,
        "to_longitude": 105.81589446233312,
        "from_longitude": 105.81621900959568,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2584,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82625348881577,
          21.0216279044844,
          105.82638902966215,
          21.02187451821627
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82638902966215,
            21.02187451821627
          ],
          [
            105.82625348881577,
            21.021652941430357
          ],
          [
            105.82631249741304,
            21.0216279044844
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 20",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.02187451821627,
        "to_latitude": 21.0216279044844,
        "to_longitude": 105.82631249741304,
        "from_longitude": 105.82638902966215,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2585,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82631249741304,
          21.021599111991442,
          105.82639966920956,
          21.0216279044844
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82631249741304,
            21.0216279044844
          ],
          [
            105.82639966920956,
            21.021599111991442
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 20",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.0216279044844,
        "to_latitude": 21.021599111991442,
        "to_longitude": 105.82639966920956,
        "from_longitude": 105.82631249741304,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2586,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82639966920956,
          21.021570319492103,
          105.82649354652546,
          21.021599111991442
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82639966920956,
            21.021599111991442
          ],
          [
            105.82649354652546,
            21.021570319492103
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 20",
        "diemDau": "4-2",
        "diemCuoi": "4-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.021599111991442,
        "to_latitude": 21.021570319492103,
        "to_longitude": 105.82649354652546,
        "from_longitude": 105.82639966920956,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2587,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82713861779284,
          21.025423457513206,
          105.82720418417254,
          21.025607731571416
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82720418417254,
            21.025607731571416
          ],
          [
            105.82713861779284,
            21.025423457513206
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 137",
        "diemDau": "25",
        "diemCuoi": "25-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.025607731571416,
        "to_latitude": 21.025423457513206,
        "to_longitude": 105.82713861779284,
        "from_longitude": 105.82720418417254,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2588,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82688514904444,
          21.025423457513206,
          105.82713861779284,
          21.025481041017155
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82713861779284,
            21.025423457513206
          ],
          [
            105.82688514904444,
            21.025481041017155
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 137",
        "diemDau": "25-1",
        "diemCuoi": "25-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.025423457513206,
        "to_latitude": 21.025481041017155,
        "to_longitude": 105.82688514904444,
        "from_longitude": 105.82713861779284,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2589,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82667996005337,
          21.025481041017155,
          105.82688514904444,
          21.025513588204653
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82688514904444,
            21.025481041017155
          ],
          [
            105.82667996005337,
            21.025513588204653
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN2",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 137",
        "diemDau": "23-2",
        "diemCuoi": "25-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam 2",
        "from_latitude": 21.025481041017155,
        "to_latitude": 21.025513588204653,
        "to_longitude": 105.82667996005337,
        "from_longitude": 105.82688514904444,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2590,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81533991550968,
          21.010321103022417,
          105.81559338425807,
          21.01050388648908
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81559338425807,
            21.010321103022417
          ],
          [
            105.81533991550968,
            21.01050388648908
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "33",
        "diemCuoi": "34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.010321103022417,
        "to_latitude": 21.01050388648908,
        "to_longitude": 105.81533991550968,
        "from_longitude": 105.81559338425807,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2591,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81508376454454,
          21.01050388648908,
          105.81533991550968,
          21.010691677488897
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81533991550968,
            21.01050388648908
          ],
          [
            105.81508376454454,
            21.010691677488897
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.01050388648908,
        "to_latitude": 21.010691677488897,
        "to_longitude": 105.81508376454454,
        "from_longitude": 105.81533991550968,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2592,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81508376454454,
          21.010691677488897,
          105.81517764186043,
          21.010814367481725
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81508376454454,
            21.010691677488897
          ],
          [
            105.81517764186043,
            21.010814367481725
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "35",
        "diemCuoi": "36",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.010691677488897,
        "to_latitude": 21.010814367481725,
        "to_longitude": 105.81517764186043,
        "from_longitude": 105.81508376454454,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2593,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81517764186043,
          21.010814367481725,
          105.8153016940269,
          21.010965851924702
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81517764186043,
            21.010814367481725
          ],
          [
            105.8153016940269,
            21.010965851924702
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "36",
        "diemCuoi": "37",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.010814367481725,
        "to_latitude": 21.010965851924702,
        "to_longitude": 105.8153016940269,
        "from_longitude": 105.81517764186043,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2594,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8153016940269,
          21.010965851924702,
          105.81542574619334,
          21.01111733621387
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8153016940269,
            21.010965851924702
          ],
          [
            105.81542574619334,
            21.01111733621387
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "37",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.010965851924702,
        "to_latitude": 21.01111733621387,
        "to_longitude": 105.81542574619334,
        "from_longitude": 105.8153016940269,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2595,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81542574619334,
          21.01111733621387,
          105.81551828242334,
          21.011203719745584
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81542574619334,
            21.01111733621387
          ],
          [
            105.81551828242334,
            21.011203719745584
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "32",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.01111733621387,
        "to_latitude": 21.011203719745584,
        "to_longitude": 105.81551828242334,
        "from_longitude": 105.81542574619334,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2596,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81551828242334,
          21.011028448754672,
          105.8157771155962,
          21.011203719745584
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81551828242334,
            21.011203719745584
          ],
          [
            105.8157771155962,
            21.011028448754672
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "31",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.011203719745584,
        "to_latitude": 21.011028448754672,
        "to_longitude": 105.8157771155962,
        "from_longitude": 105.81551828242334,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2597,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8157771155962,
          21.010811863602342,
          105.81606411195934,
          21.011028448754672
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8157771155962,
            21.011028448754672
          ],
          [
            105.81606411195934,
            21.010811863602342
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 51,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "30",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.011028448754672,
        "to_latitude": 21.010811863602342,
        "to_longitude": 105.81606411195934,
        "from_longitude": 105.8157771155962,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2598,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81551828242334,
          21.011203719745584,
          105.81565589192735,
          21.011400538681606
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81551828242334,
            21.011203719745584
          ],
          [
            105.81555683926517,
            21.011274767014378
          ],
          [
            105.81565589192735,
            21.011400538681606
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "31",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.011203719745584,
        "to_latitude": 21.011400538681606,
        "to_longitude": 105.81565589192735,
        "from_longitude": 105.81551828242334,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2599,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81565589192735,
          21.011400538681606,
          105.81575666378687,
          21.011523901752536
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81565589192735,
            21.011400538681606
          ],
          [
            105.81575666378687,
            21.011523901752536
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "24",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.011400538681606,
        "to_latitude": 21.011523901752536,
        "to_longitude": 105.81575666378687,
        "from_longitude": 105.81565589192735,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2600,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81575666378687,
          21.011341119534826,
          105.8160396368384,
          21.011523901752536
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81575666378687,
            21.011523901752536
          ],
          [
            105.8160396368384,
            21.011341119534826
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "16",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.011523901752536,
        "to_latitude": 21.011341119534826,
        "to_longitude": 105.8160396368384,
        "from_longitude": 105.81575666378687,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2601,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8160396368384,
          21.011169604511725,
          105.81627835344423,
          21.011341119534826
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8160396368384,
            21.011341119534826
          ],
          [
            105.81627835344423,
            21.011169604511725
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "15",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.011341119534826,
        "to_latitude": 21.011169604511725,
        "to_longitude": 105.81627835344423,
        "from_longitude": 105.8160396368384,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2602,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8159055306956,
          21.011229510026272,
          105.8160396368384,
          21.011341119534826
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8160396368384,
            21.011341119534826
          ],
          [
            105.8159055306956,
            21.011229510026272
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "15",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.011341119534826,
        "to_latitude": 21.011229510026272,
        "to_longitude": 105.8159055306956,
        "from_longitude": 105.8160396368384,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2603,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8160396368384,
          21.011341119534826,
          105.81624866803628,
          21.011667051089344
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8160396368384,
            21.011341119534826
          ],
          [
            105.81603963689228,
            21.01139745652764
          ],
          [
            105.81610937432055,
            21.011502618886414
          ],
          [
            105.81624866803628,
            21.011667051089344
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "15",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.011341119534826,
        "to_latitude": 21.011667051089344,
        "to_longitude": 105.81624866803628,
        "from_longitude": 105.8160396368384,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2604,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81575666378687,
          21.011523901752536,
          105.81592251164204,
          21.011788914336826
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81575666378687,
            21.011523901752536
          ],
          [
            105.81577141598336,
            21.01159526185536
          ],
          [
            105.81592251164204,
            21.011788914336826
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "16",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.011523901752536,
        "to_latitude": 21.011788914336826,
        "to_longitude": 105.81592251164204,
        "from_longitude": 105.81575666378687,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2605,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81553135822875,
          21.011523901752536,
          105.81575666378687,
          21.011706683745437
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81575666378687,
            21.011523901752536
          ],
          [
            105.815733865057,
            21.011562711629807
          ],
          [
            105.81553135822875,
            21.011706683745437
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.011523901752536,
        "to_latitude": 21.011706683745437,
        "to_longitude": 105.81553135822875,
        "from_longitude": 105.81575666378687,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2606,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81515987228579,
          21.011706683745437,
          105.81553135822875,
          21.01193578687
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81553135822875,
            21.011706683745437
          ],
          [
            105.81521083430476,
            21.01193578687
          ],
          [
            105.81515987228579,
            21.011923267598625
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.011706683745437,
        "to_latitude": 21.011923267598625,
        "to_longitude": 105.81515987228579,
        "from_longitude": 105.81553135822875,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2607,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81546220206818,
          21.011756115007266,
          105.81559835287413,
          21.011928651980103
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81546220206818,
            21.011756115007266
          ],
          [
            105.81559835287413,
            21.011928651980103
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "17",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.011756115007266,
        "to_latitude": 21.011928651980103,
        "to_longitude": 105.81559835287413,
        "from_longitude": 105.81546220206818,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2608,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81515987228579,
          21.011923267598625,
          105.81530134644716,
          21.012092514633853
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81515987228579,
            21.011923267598625
          ],
          [
            105.81530134644716,
            21.012092514633853
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "18",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.011923267598625,
        "to_latitude": 21.012092514633853,
        "to_longitude": 105.81530134644716,
        "from_longitude": 105.81515987228579,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2609,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81530134644716,
          21.012092514633853,
          105.81535179006637,
          21.012270891647255
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81530134644716,
            21.012092514633853
          ],
          [
            105.81535179006637,
            21.012270891647255
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "22",
        "diemCuoi": "22-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.012092514633853,
        "to_latitude": 21.012270891647255,
        "to_longitude": 105.81535179006637,
        "from_longitude": 105.81530134644716,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2610,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81500296305204,
          21.011923267598625,
          105.81515987228579,
          21.012035941154256
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81515987228579,
            21.011923267598625
          ],
          [
            105.81500296305204,
            21.012035941154256
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "18",
        "diemCuoi": "60",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.011923267598625,
        "to_latitude": 21.012035941154256,
        "to_longitude": 105.81500296305204,
        "from_longitude": 105.81515987228579,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2611,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81463717678483,
          21.011596826788857,
          105.81500296305204,
          21.012035941154256
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81500296305204,
            21.012035941154256
          ],
          [
            105.81498016433116,
            21.01197835244375
          ],
          [
            105.81481923179476,
            21.011783051482944
          ],
          [
            105.81472803668662,
            21.011657858424993
          ],
          [
            105.81463717678483,
            21.011596826788857
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "60",
        "diemCuoi": "61",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.012035941154256,
        "to_latitude": 21.011596826788857,
        "to_longitude": 105.81463717678483,
        "from_longitude": 105.81500296305204,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2612,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81463717678483,
          21.011596826788857,
          105.81475117066776,
          21.011738294994302
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81463717678483,
            21.011596826788857
          ],
          [
            105.81475117066776,
            21.011738294994302
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "61",
        "diemCuoi": "61-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.011596826788857,
        "to_latitude": 21.011738294994302,
        "to_longitude": 105.81475117066776,
        "from_longitude": 105.81463717678483,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2613,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81475117066776,
          21.011738294994302,
          105.81487723449452,
          21.011897290074014
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81475117066776,
            21.011738294994302
          ],
          [
            105.81487723449452,
            21.011897290074014
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "61-1",
        "diemCuoi": "62",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.011738294994302,
        "to_latitude": 21.011897290074014,
        "to_longitude": 105.81487723449452,
        "from_longitude": 105.81475117066776,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2614,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81510770967179,
          21.01181184897933,
          105.81515987228579,
          21.011923267598625
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81515987228579,
            21.011923267598625
          ],
          [
            105.81510770967179,
            21.01181184897933
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 2806,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "18",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.011923267598625,
        "to_latitude": 21.01181184897933,
        "to_longitude": 105.81510770967179,
        "from_longitude": 105.81515987228579,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2615,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81501425379066,
          21.011699101436676,
          105.81510770967179,
          21.01181184897933
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81510770967179,
            21.01181184897933
          ],
          [
            105.81501425379066,
            21.011699101436676
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.01181184897933,
        "to_latitude": 21.011699101436676,
        "to_longitude": 105.81501425379066,
        "from_longitude": 105.81510770967179,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2616,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81527567209673,
          21.011523212662492,
          105.81546069513432,
          21.011757192129373
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81546069513432,
            21.011757192129373
          ],
          [
            105.81527567209673,
            21.011523212662492
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "17",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.011757192129373,
        "to_latitude": 21.011523212662492,
        "to_longitude": 105.81527567209673,
        "from_longitude": 105.81546069513432,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2617,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81498854611581,
          21.010965851924702,
          105.8153016940269,
          21.01117492524251
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8153016940269,
            21.010965851924702
          ],
          [
            105.81503917292842,
            21.011174612236665
          ],
          [
            105.81498854611581,
            21.01117492524251
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "37",
        "diemCuoi": "38",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.010965851924702,
        "to_latitude": 21.01117492524251,
        "to_longitude": 105.81498854611581,
        "from_longitude": 105.8153016940269,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2618,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81476115794216,
          21.011174612236665,
          105.81503917292842,
          21.011372939331032
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81503917292842,
            21.011174612236665
          ],
          [
            105.81476115794216,
            21.011372939331032
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "37",
        "diemCuoi": "42",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.011174612236665,
        "to_latitude": 21.011372939331032,
        "to_longitude": 105.81476115794216,
        "from_longitude": 105.81503917292842,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2619,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81476115794216,
          21.011372939331032,
          105.8148486392315,
          21.011500881752813
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81476115794216,
            21.011372939331032
          ],
          [
            105.8148486392315,
            21.011500881752813
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "42",
        "diemCuoi": "43",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.011372939331032,
        "to_latitude": 21.011500881752813,
        "to_longitude": 105.8148486392315,
        "from_longitude": 105.81476115794216,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2620,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81473413697161,
          21.011339446073144,
          105.81476115794216,
          21.011372939331032
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81476115794216,
            21.011372939331032
          ],
          [
            105.81473413697161,
            21.011339446073144
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 5,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "42",
        "diemCuoi": "44",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.011372939331032,
        "to_latitude": 21.011339446073144,
        "to_longitude": 105.81473413697161,
        "from_longitude": 105.81476115794216,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2621,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81453257059306,
          21.0110597471638,
          105.81473413697161,
          21.011339446073144
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81473413697161,
            21.011339446073144
          ],
          [
            105.81453257059306,
            21.0110597471638
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "44",
        "diemCuoi": "45",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.011339446073144,
        "to_latitude": 21.0110597471638,
        "to_longitude": 105.81453257059306,
        "from_longitude": 105.81473413697161,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2622,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82657401279364,
          21.02231982823701,
          105.82666663458481,
          21.022459441803797
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82660519349683,
            21.022459441803797
          ],
          [
            105.82657401279364,
            21.022372751834496
          ],
          [
            105.82666663458481,
            21.02231982823701
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 32",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.022459441803797,
        "to_latitude": 21.02231982823701,
        "to_longitude": 105.82666663458481,
        "from_longitude": 105.82660519349683,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2623,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82666663458481,
          21.02222096486088,
          105.82683348733207,
          21.02231982823701
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82666663458481,
            21.02231982823701
          ],
          [
            105.82683348733207,
            21.02222096486088
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 32 Tổ 75",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02231982823701,
        "to_latitude": 21.02222096486088,
        "to_longitude": 105.82683348733207,
        "from_longitude": 105.82666663458481,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2624,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82683348733207,
          21.022125809375034,
          105.82700279623545,
          21.02222096486088
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82683348733207,
            21.02222096486088
          ],
          [
            105.82700279623545,
            21.022125809375034
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 32 Tổ 75",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02222096486088,
        "to_latitude": 21.022125809375034,
        "to_longitude": 105.82700279623545,
        "from_longitude": 105.82683348733207,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2625,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82700279623545,
          21.02202592585841,
          105.82717954051678,
          21.022125809375034
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82700279623545,
            21.022125809375034
          ],
          [
            105.82717954051678,
            21.02202592585841
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 32 Tổ 75",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.022125809375034,
        "to_latitude": 21.02202592585841,
        "to_longitude": 105.82717954051678,
        "from_longitude": 105.82700279623545,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2626,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82705651550975,
          21.02181157701128,
          105.82717954051678,
          21.02202592585841
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82717954051678,
            21.02202592585841
          ],
          [
            105.82705651550975,
            21.02181157701128
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 32 Tổ 75",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02202592585841,
        "to_latitude": 21.02181157701128,
        "to_longitude": 105.82705651550975,
        "from_longitude": 105.82717954051678,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2627,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82717954051678,
          21.021943023957835,
          105.82732529329702,
          21.02202592585841
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82717954051678,
            21.02202592585841
          ],
          [
            105.82732529329702,
            21.021943023957835
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 32 Tổ 75",
        "diemDau": "05",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02202592585841,
        "to_latitude": 21.021943023957835,
        "to_longitude": 105.82732529329702,
        "from_longitude": 105.82717954051678,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2628,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8147753105105,
          21.01085442949761,
          105.81498854611581,
          21.01117492524251
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81498854611581,
            21.01117492524251
          ],
          [
            105.8147753105105,
            21.01085442949761
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "38",
        "diemCuoi": "41",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.01117492524251,
        "to_latitude": 21.01085442949761,
        "to_longitude": 105.8147753105105,
        "from_longitude": 105.81498854611581,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2629,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8146867976101,
          21.01074300699024,
          105.8147753105105,
          21.01085442949761
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8147753105105,
            21.01085442949761
          ],
          [
            105.8146867976101,
            21.01074300699024
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "41",
        "diemCuoi": "58",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.01085442949761,
        "to_latitude": 21.01074300699024,
        "to_longitude": 105.8146867976101,
        "from_longitude": 105.8147753105105,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2630,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81454195832015,
          21.010613431270375,
          105.8146867976101,
          21.01074300699024
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8146867976101,
            21.01074300699024
          ],
          [
            105.81454195832015,
            21.010613431270375
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 185,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "58",
        "diemCuoi": "51",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.01074300699024,
        "to_latitude": 21.010613431270375,
        "to_longitude": 105.81454195832015,
        "from_longitude": 105.8146867976101,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2631,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81438907240701,
          21.010426266142478,
          105.81454195832015,
          21.010613431270375
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81454195832015,
            21.010613431270375
          ],
          [
            105.81438907240701,
            21.010426266142478
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "51",
        "diemCuoi": "57",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.010613431270375,
        "to_latitude": 21.010426266142478,
        "to_longitude": 105.81438907240701,
        "from_longitude": 105.81454195832015,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2632,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81443198774883,
          21.010613431270375,
          105.81454195832015,
          21.010697937186873
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81454195832015,
            21.010613431270375
          ],
          [
            105.81443198774883,
            21.010697937186873
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "51",
        "diemCuoi": "52",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.010613431270375,
        "to_latitude": 21.010697937186873,
        "to_longitude": 105.81443198774883,
        "from_longitude": 105.81454195832015,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2633,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8143314049136,
          21.010697937186873,
          105.81443198774883,
          21.010806855854312
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81443198774883,
            21.010697937186873
          ],
          [
            105.8143314049136,
            21.010806855854312
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "52",
        "diemCuoi": "47",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.010697937186873,
        "to_latitude": 21.010806855854312,
        "to_longitude": 105.8143314049136,
        "from_longitude": 105.81443198774883,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2634,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81423082207834,
          21.01072923566284,
          105.8143314049136,
          21.010806855854312
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8143314049136,
            21.010806855854312
          ],
          [
            105.81423082207834,
            21.01072923566284
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "47",
        "diemCuoi": "47-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.010806855854312,
        "to_latitude": 21.01072923566284,
        "to_longitude": 105.81423082207834,
        "from_longitude": 105.8143314049136,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2635,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81496342711729,
          21.010598556324133,
          105.81508376454454,
          21.010691677488897
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81508376454454,
            21.010691677488897
          ],
          [
            105.81496342711729,
            21.010598556324133
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "35",
        "diemCuoi": "56",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.010691677488897,
        "to_latitude": 21.010598556324133,
        "to_longitude": 105.81496342711729,
        "from_longitude": 105.81508376454454,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2636,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81481554364458,
          21.010414998668573,
          105.81496342711729,
          21.010598556324133
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81496342711729,
            21.010598556324133
          ],
          [
            105.81481554364458,
            21.010414998668573
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "56",
        "diemCuoi": "50",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.010598556324133,
        "to_latitude": 21.010414998668573,
        "to_longitude": 105.81481554364458,
        "from_longitude": 105.81496342711729,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2637,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81469249730378,
          21.01021813073319,
          105.81481554364458,
          21.010414998668573
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81481554364458,
            21.010414998668573
          ],
          [
            105.81469249730378,
            21.01021813073319
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "50",
        "diemCuoi": "55",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.010414998668573,
        "to_latitude": 21.01021813073319,
        "to_longitude": 105.81469249730378,
        "from_longitude": 105.81481554364458,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2638,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81513874982124,
          21.010222199546973,
          105.81533991550968,
          21.01050388648908
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81533991550968,
            21.01050388648908
          ],
          [
            105.81513874982124,
            21.010222199546973
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "34",
        "diemCuoi": "49",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.01050388648908,
        "to_latitude": 21.010222199546973,
        "to_longitude": 105.81513874982124,
        "from_longitude": 105.81533991550968,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2639,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81513874982124,
          21.010064454627457,
          105.81536807869995,
          21.010222199546973
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81513874982124,
            21.010222199546973
          ],
          [
            105.81536807869995,
            21.010064454627457
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "49",
        "diemCuoi": "48",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.010222199546973,
        "to_latitude": 21.010064454627457,
        "to_longitude": 105.81536807869995,
        "from_longitude": 105.81513874982124,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2640,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81498574309171,
          21.010087645434496,
          105.81513874982124,
          21.010222199546973
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81513874982124,
            21.010222199546973
          ],
          [
            105.81498574309171,
            21.010087645434496
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "diemDau": "49",
        "diemCuoi": "54",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.010222199546973,
        "to_latitude": 21.010087645434496,
        "to_longitude": 105.81498574309171,
        "from_longitude": 105.81513874982124,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2641,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81485175345809,
          21.009971497696927,
          105.81498574309171,
          21.010087645434496
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81498574309171,
            21.010087645434496
          ],
          [
            105.81489165143076,
            21.009971497696927
          ],
          [
            105.81485175345809,
            21.009981826268124
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 88 Thái Thịnh 2",
        "diemDau": "54",
        "diemCuoi": "54-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.010087645434496,
        "to_latitude": 21.009981826268124,
        "to_longitude": 105.81485175345809,
        "from_longitude": 105.81498574309171,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2642,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81489165143076,
          21.00987134206769,
          105.81505861884023,
          21.009971497696927
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81489165143076,
            21.009971497696927
          ],
          [
            105.81505861884023,
            21.00987134206769
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 88 Thái Thịnh 2",
        "diemDau": "54-1",
        "diemCuoi": "59",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.009971497696927,
        "to_latitude": 21.00987134206769,
        "to_longitude": 105.81505861884023,
        "from_longitude": 105.81489165143076,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2643,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81466567521737,
          21.009981826268124,
          105.81485175345809,
          21.010080416919067
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81485175345809,
            21.009981826268124
          ],
          [
            105.81466567521737,
            21.010080416919067
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 88 Thái Thịnh 2",
        "diemDau": "54-1",
        "diemCuoi": "54-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.009981826268124,
        "to_latitude": 21.010080416919067,
        "to_longitude": 105.81466567521737,
        "from_longitude": 105.81485175345809,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2644,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81455436354219,
          21.009782453867782,
          105.81485175345809,
          21.009981826268124
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81485175345809,
            21.009981826268124
          ],
          [
            105.81455436354219,
            21.009782453867782
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 88 Thái Thịnh 2",
        "diemDau": "54-1",
        "diemCuoi": "54-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.009981826268124,
        "to_latitude": 21.009782453867782,
        "to_longitude": 105.81455436354219,
        "from_longitude": 105.81485175345809,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2645,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81446182733019,
          21.009940199087147,
          105.81466567521737,
          21.010080416919067
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81466567521737,
            21.010080416919067
          ],
          [
            105.81446182733019,
            21.009940199087147
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YL3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 88 Thái Thịnh 2",
        "diemDau": "54-2",
        "diemCuoi": "54-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập Thể Yên Lãng 3",
        "from_latitude": 21.010080416919067,
        "to_latitude": 21.009940199087147,
        "to_longitude": 105.81446182733019,
        "from_longitude": 105.81466567521737,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2646,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82660519349683,
          21.022459441803797,
          105.82661692811752,
          21.022484165658124
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82660519349683,
            21.022459441803797
          ],
          [
            105.82661692811752,
            21.022484165658124
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 32A Tổ 75 Hào Nam",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.022459441803797,
        "to_latitude": 21.022484165658124,
        "to_longitude": 105.82661692811752,
        "from_longitude": 105.82660519349683,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2647,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82661692811752,
          21.022484165658124,
          105.8267778606539,
          21.022593075716973
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82661692811752,
            21.022484165658124
          ],
          [
            105.82665179683613,
            21.02258180916309
          ],
          [
            105.8267778606539,
            21.022593075716973
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 32A Tổ 75 Hào Nam",
        "diemDau": "01",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.022484165658124,
        "to_latitude": 21.022593075716973,
        "to_longitude": 105.8267778606539,
        "from_longitude": 105.82661692811752,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2648,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8267778606539,
          21.022494180379006,
          105.82704339934612,
          21.022593075716973
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8267778606539,
            21.022593075716973
          ],
          [
            105.82704339934612,
            21.022494180379006
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 32A Tổ 75 Hào Nam",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.022593075716973,
        "to_latitude": 21.022494180379006,
        "to_longitude": 105.82704339934612,
        "from_longitude": 105.8267778606539,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2649,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82693559204203,
          21.02232965557901,
          105.82704339934612,
          21.022494180379006
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82704339934612,
            21.022494180379006
          ],
          [
            105.82693559204203,
            21.02232965557901
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 32A Tổ 75 Hào Nam",
        "diemDau": "14",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.022494180379006,
        "to_latitude": 21.02232965557901,
        "to_longitude": 105.82693559204203,
        "from_longitude": 105.82704339934612,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2650,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82704339934612,
          21.02237650736031,
          105.8273599000124,
          21.022494180379006
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82704339934612,
            21.022494180379006
          ],
          [
            105.8273599000124,
            21.02237650736031
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 32A Tổ 75 Hào Nam",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.022494180379006,
        "to_latitude": 21.02237650736031,
        "to_longitude": 105.8273599000124,
        "from_longitude": 105.82704339934612,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2651,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82726581889091,
          21.02220480122623,
          105.8273599000124,
          21.02237650736031
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8273599000124,
            21.02237650736031
          ],
          [
            105.82726581889091,
            21.02220480122623
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 32A Tổ 75 Hào Nam",
        "diemDau": "15",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02237650736031,
        "to_latitude": 21.02220480122623,
        "to_longitude": 105.82726581889091,
        "from_longitude": 105.8273599000124,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2652,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8273599000124,
          21.022271352668415,
          105.8275463135403,
          21.02237650736031
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8273599000124,
            21.02237650736031
          ],
          [
            105.8275463135403,
            21.022271352668415
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 32A Tổ 75 Hào Nam",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02237650736031,
        "to_latitude": 21.022271352668415,
        "to_longitude": 105.8275463135403,
        "from_longitude": 105.8273599000124,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2653,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82740731419436,
          21.022109072599605,
          105.8275463135403,
          21.022271352668415
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8275463135403,
            21.022271352668415
          ],
          [
            105.82740731419436,
            21.022109072599605
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 32A Tổ 75 Hào Nam",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.022271352668415,
        "to_latitude": 21.022109072599605,
        "to_longitude": 105.82740731419436,
        "from_longitude": 105.8275463135403,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2654,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82669614259234,
          21.022645652950324,
          105.82692135883099,
          21.02284093968558
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82669614259234,
            21.022645652950324
          ],
          [
            105.82673637572645,
            21.02284093968558
          ],
          [
            105.82692135883099,
            21.02278711068481
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hào Nam",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.022645652950324,
        "to_latitude": 21.02278711068481,
        "to_longitude": 105.82692135883099,
        "from_longitude": 105.82669614259234,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2655,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82692135883099,
          21.022767081280946,
          105.82711984229373,
          21.02278711068482
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82692135883099,
            21.02278711068482
          ],
          [
            105.82711984229373,
            21.022767081280946
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hào Nam",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02278711068482,
        "to_latitude": 21.022767081280946,
        "to_longitude": 105.82711984229373,
        "from_longitude": 105.82692135883099,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2656,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82711984229373,
          21.022694667257642,
          105.82738641261297,
          21.022767081280946
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82711984229373,
            21.022767081280946
          ],
          [
            105.82738641261297,
            21.022694667257642
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hào Nam",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.022767081280946,
        "to_latitude": 21.022694667257642,
        "to_longitude": 105.82738641261297,
        "from_longitude": 105.82711984229373,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2657,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82737263623956,
          21.022636890088055,
          105.82761336876081,
          21.022698409620478
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82737263623956,
            21.022698409620478
          ],
          [
            105.82761336876081,
            21.022636890088055
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hào Nam",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.022698409620478,
        "to_latitude": 21.022636890088055,
        "to_longitude": 105.82761336876081,
        "from_longitude": 105.82737263623956,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2658,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82673637572645,
          21.02284093968559,
          105.82691934724254,
          21.02316829476087
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82673637572645,
            21.02284093968559
          ],
          [
            105.82680401225574,
            21.022847824791086
          ],
          [
            105.82691934724254,
            21.02316829476087
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngâ44+ngách 55+27HN",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02284093968559,
        "to_latitude": 21.02316829476087,
        "to_longitude": 105.82691934724254,
        "from_longitude": 105.82673637572645,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2659,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82735444700985,
          21.022973982278923,
          105.82763627909019,
          21.02304511308552
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82735444700985,
            21.02304511308552
          ],
          [
            105.82763627909019,
            21.022973982278923
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngâ44+ngách 55+27HN",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02304511308552,
        "to_latitude": 21.022973982278923,
        "to_longitude": 105.82763627909019,
        "from_longitude": 105.82735444700985,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2660,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82763627909019,
          21.022906203390242,
          105.82788646637466,
          21.022973982278923
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82763627909019,
            21.022973982278923
          ],
          [
            105.82788646637466,
            21.022906203390242
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngâ44+ngách 55+27HN",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.022973982278923,
        "to_latitude": 21.022906203390242,
        "to_longitude": 105.82788646637466,
        "from_longitude": 105.82763627909019,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2661,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82788646637466,
          21.022829673156327,
          105.82818467932425,
          21.022906203390242
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82788646637466,
            21.022906203390242
          ],
          [
            105.82818467932425,
            21.022829673156327
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngâ44+ngách 55+27HN",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.022906203390242,
        "to_latitude": 21.022829673156327,
        "to_longitude": 105.82818467932425,
        "from_longitude": 105.82788646637466,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2662,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82818467932425,
          21.022829673156327,
          105.82825240538654,
          21.023064482871323
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82818467932425,
            21.022829673156327
          ],
          [
            105.82825240538654,
            21.023064482871323
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngâ44+ngách 55+27HN",
        "diemDau": "26",
        "diemCuoi": "26-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.022829673156327,
        "to_latitude": 21.023064482871323,
        "to_longitude": 105.82825240538654,
        "from_longitude": 105.82818467932425,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2663,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82818467932425,
          21.022774429617474,
          105.82843432394496,
          21.022829673156327
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82818467932425,
            21.022829673156327
          ],
          [
            105.82843432394496,
            21.022774429617474
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngâ44+ngách 55+27HN",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.022829673156327,
        "to_latitude": 21.022774429617474,
        "to_longitude": 105.82843432394496,
        "from_longitude": 105.82818467932425,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2664,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.828341588567,
          21.022774429617474,
          105.82843432394496,
          21.022922309083725
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82843432394496,
            21.022774429617474
          ],
          [
            105.828341588567,
            21.02280839191539
          ],
          [
            105.82837109287011,
            21.022922309083725
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngâ44+ngách 55+27HN",
        "diemDau": "27",
        "diemCuoi": "27-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.022774429617474,
        "to_latitude": 21.022922309083725,
        "to_longitude": 105.82837109287011,
        "from_longitude": 105.82843432394496,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2665,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81960362211383,
          21.01317832100163,
          105.81989598289245,
          21.013240916905268
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81989598289245,
            21.013240916905268
          ],
          [
            105.81960362211383,
            21.01317832100163
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Hà",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.013240916905268,
        "to_latitude": 21.01317832100163,
        "to_longitude": 105.81960362211383,
        "from_longitude": 105.81989598289245,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2666,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81960362211383,
          21.012917921759414,
          105.81991475835564,
          21.01317832100163
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81960362211383,
            21.01317832100163
          ],
          [
            105.81991475835564,
            21.012917921759414
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Hà",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01317832100163,
        "to_latitude": 21.012917921759414,
        "to_longitude": 105.81991475835564,
        "from_longitude": 105.81960362211383,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2667,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81991475835564,
          21.012624972068,
          105.82028490320369,
          21.012917921759414
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81991475835564,
            21.012917921759414
          ],
          [
            105.82028490320369,
            21.012624972068
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Hà",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.012917921759414,
        "to_latitude": 21.012624972068,
        "to_longitude": 105.82028490320369,
        "from_longitude": 105.81991475835564,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2668,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82028490320369,
          21.01238710651053,
          105.8205692173591,
          21.012624972068
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82028490320369,
            21.012624972068
          ],
          [
            105.8205692173591,
            21.01238710651053
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Hà",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.012624972068,
        "to_latitude": 21.01238710651053,
        "to_longitude": 105.8205692173591,
        "from_longitude": 105.82028490320369,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2669,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8205692173591,
          21.01212920974098,
          105.82081329838039,
          21.01238710651053
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8205692173591,
            21.01238710651053
          ],
          [
            105.82081329838039,
            21.01212920974098
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Hà",
        "diemDau": "02",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01238710651053,
        "to_latitude": 21.01212920974098,
        "to_longitude": 105.82081329838039,
        "from_longitude": 105.8205692173591,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2670,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81931818860045,
          21.01317832100163,
          105.81960362211383,
          21.013382077972842
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81960362211383,
            21.01317832100163
          ],
          [
            105.81931818860045,
            21.013382077972842
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Hà",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01317832100163,
        "to_latitude": 21.013382077972842,
        "to_longitude": 105.81931818860045,
        "from_longitude": 105.81960362211383,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2671,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81902644529315,
          21.013382077972842,
          105.81931818860045,
          21.013605406843652
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81931818860045,
            21.013382077972842
          ],
          [
            105.81902644529315,
            21.013605406843652
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Thái Hà",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.013382077972842,
        "to_latitude": 21.013605406843652,
        "to_longitude": 105.81902644529315,
        "from_longitude": 105.81931818860045,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2672,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.813125584342,
          21.014961511539248,
          105.81341526292186,
          21.015134274104323
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81341526292186,
            21.014961511539248
          ],
          [
            105.813125584342,
            21.015134274104323
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Ngọc Phách",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014961511539248,
        "to_latitude": 21.015134274104323,
        "to_longitude": 105.813125584342,
        "from_longitude": 105.81341526292186,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2673,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8128278591389,
          21.015134274104323,
          105.813125584342,
          21.015329570677135
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.813125584342,
            21.015134274104323
          ],
          [
            105.8128278591389,
            21.015329570677135
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Ngọc Phách",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.015134274104323,
        "to_latitude": 21.015329570677135,
        "to_longitude": 105.8128278591389,
        "from_longitude": 105.813125584342,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2674,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8126401045161,
          21.015329570677135,
          105.8128278591389,
          21.015467279645033
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8128278591389,
            21.015329570677135
          ],
          [
            105.8126401045161,
            21.015467279645033
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Ngọc Phách",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.015329570677135,
        "to_latitude": 21.015467279645033,
        "to_longitude": 105.8126401045161,
        "from_longitude": 105.8128278591389,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2675,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.812353108144,
          21.015467279645033,
          105.8126401045161,
          21.015645049214996
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8126401045161,
            21.015467279645033
          ],
          [
            105.812353108144,
            21.015645049214996
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Ngọc Phách",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.015467279645033,
        "to_latitude": 21.015645049214996,
        "to_longitude": 105.812353108144,
        "from_longitude": 105.8126401045161,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2676,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81214657804904,
          21.015645049214996,
          105.812353108144,
          21.01572767443637
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.812353108144,
            21.015645049214996
          ],
          [
            105.81214657804904,
            21.01572767443637
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Ngọc Phách",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.015645049214996,
        "to_latitude": 21.01572767443637,
        "to_longitude": 105.81214657804904,
        "from_longitude": 105.812353108144,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2677,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81193468353862,
          21.01572767443637,
          105.81214657804904,
          21.01583032995035
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81214657804904,
            21.01572767443637
          ],
          [
            105.81193468353862,
            21.01583032995035
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Ngọc Phách",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01572767443637,
        "to_latitude": 21.01583032995035,
        "to_longitude": 105.81193468353862,
        "from_longitude": 105.81214657804904,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2678,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81186226389363,
          21.01583032995035,
          105.81193468353862,
          21.01596303087594
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81193468353862,
            21.01583032995035
          ],
          [
            105.81186226389363,
            21.01596303087594
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Ngọc Phách",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01583032995035,
        "to_latitude": 21.01596303087594,
        "to_longitude": 105.81186226389363,
        "from_longitude": 105.81193468353862,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2679,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81160047925616,
          21.01596303087594,
          105.81186226389363,
          21.016128775439025
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81186226389363,
            21.01596303087594
          ],
          [
            105.81160047925616,
            21.016128775439025
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Ngọc Phách",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01596303087594,
        "to_latitude": 21.016128775439025,
        "to_longitude": 105.81160047925616,
        "from_longitude": 105.81186226389363,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2680,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81131777547049,
          21.016128775439025,
          105.81160047925616,
          21.016276004289498
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81160047925616,
            21.016128775439025
          ],
          [
            105.81131777547049,
            21.016276004289498
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Ngọc Phách",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.016128775439025,
        "to_latitude": 21.016276004289498,
        "to_longitude": 105.81131777547049,
        "from_longitude": 105.81160047925616,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2681,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8110200502674,
          21.016276004289498,
          105.81131777547049,
          21.016443757768904
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81131777547049,
            21.016276004289498
          ],
          [
            105.8110200502674,
            21.016443757768904
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Ngọc Phách",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.016276004289498,
        "to_latitude": 21.016443757768904,
        "to_longitude": 105.8110200502674,
        "from_longitude": 105.81131777547049,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2682,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81160047925616,
          21.01601039668214,
          105.81161837484115,
          21.016128775439025
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81161837484115,
            21.01601039668214
          ],
          [
            105.81160047925616,
            21.016128775439025
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Ngọc Phách",
        "diemDau": "tủ điện",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01601039668214,
        "to_latitude": 21.016128775439025,
        "to_longitude": 105.81160047925616,
        "from_longitude": 105.81161837484115,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2683,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81292961554222,
          21.014801424298213,
          105.813125584342,
          21.015134274104323
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.813125584342,
            21.015134274104323
          ],
          [
            105.81292961554222,
            21.014801424298213
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Hoàng Ngọc Phách",
        "diemDau": "01",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.015134274104323,
        "to_latitude": 21.014801424298213,
        "to_longitude": 105.81292961554222,
        "from_longitude": 105.813125584342,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2684,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8128278591389,
          21.01504413713931,
          105.81296331079157,
          21.015329570677135
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8128278591389,
            21.015329570677135
          ],
          [
            105.81296331079157,
            21.015223159104163
          ],
          [
            105.81283590576211,
            21.01504413713931
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 9 Hoàng Ngọc Phách",
        "diemDau": "03",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.015329570677135,
        "to_latitude": 21.01504413713931,
        "to_longitude": 105.81283590576211,
        "from_longitude": 105.8128278591389,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2685,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81279030821253,
          21.014896412549415,
          105.81283590576211,
          21.01504413713931
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81283590576211,
            21.01504413713931
          ],
          [
            105.81279030821253,
            21.014896412549415
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 9 Hoàng Ngọc Phách",
        "diemDau": "3-1",
        "diemCuoi": "3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01504413713931,
        "to_latitude": 21.014896412549415,
        "to_longitude": 105.81279030821253,
        "from_longitude": 105.81283590576211,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2686,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81266960880127,
          21.01472114589756,
          105.81279030821253,
          21.014896412549415
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81279030821253,
            21.014896412549415
          ],
          [
            105.81266960880127,
            21.01472114589756
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 9 Hoàng Ngọc Phách",
        "diemDau": "3-2",
        "diemCuoi": "3-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014896412549415,
        "to_latitude": 21.01472114589756,
        "to_longitude": 105.81266960880127,
        "from_longitude": 105.81279030821253,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2687,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81266960880127,
          21.01462850486926,
          105.81283054134664,
          21.01472114589756
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81266960880127,
            21.01472114589756
          ],
          [
            105.81283054134664,
            21.01462850486926
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 9 Hoàng Ngọc Phách",
        "diemDau": "3-3",
        "diemCuoi": "3-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01472114589756,
        "to_latitude": 21.01462850486926,
        "to_longitude": 105.81283054134664,
        "from_longitude": 105.81266960880127,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2688,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81283054134664,
          21.014525848528017,
          105.81301293156294,
          21.01462850486926
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81283054134664,
            21.01462850486926
          ],
          [
            105.81301293156294,
            21.014525848528017
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 9 Hoàng Ngọc Phách",
        "diemDau": "3-4",
        "diemCuoi": "3-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01462850486926,
        "to_latitude": 21.014525848528017,
        "to_longitude": 105.81301293156294,
        "from_longitude": 105.81283054134664,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2689,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81283590576211,
          21.01504413713931,
          105.8129619695889,
          21.015196869187914
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81283590576211,
            21.01504413713931
          ],
          [
            105.8129619695889,
            21.015196869187914
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 9 Hoàng Ngọc Phách",
        "diemDau": "3-1",
        "diemCuoi": "3-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01504413713931,
        "to_latitude": 21.015196869187914,
        "to_longitude": 105.8129619695889,
        "from_longitude": 105.81283590576211,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2690,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8126293756672,
          21.015467279645033,
          105.8126401045161,
          21.015602484690138
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8126401045161,
            21.015467279645033
          ],
          [
            105.8126293756672,
            21.015602484690138
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8A Hoàng Ngọc Phách",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.015467279645033,
        "to_latitude": 21.015602484690138,
        "to_longitude": 105.8126293756672,
        "from_longitude": 105.8126401045161,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2691,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81253465828752,
          21.015311499288778,
          105.8126401045161,
          21.015467279645033
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8126401045161,
            21.015467279645033
          ],
          [
            105.81253465828752,
            21.015311499288778
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Hoàng Ngọc Phách",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.015467279645033,
        "to_latitude": 21.015311499288778,
        "to_longitude": 105.81253465828752,
        "from_longitude": 105.8126401045161,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2692,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81242401915827,
          21.015140690089474,
          105.81253465828752,
          21.015311499288778
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81253465828752,
            21.015311499288778
          ],
          [
            105.81242401915827,
            21.015140690089474
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Hoàng Ngọc Phách",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.015311499288778,
        "to_latitude": 21.015140690089474,
        "to_longitude": 105.81242401915827,
        "from_longitude": 105.81253465828752,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2693,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81230868417147,
          21.014967927531853,
          105.81242401915827,
          21.015140690089474
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81242401915827,
            21.015140690089474
          ],
          [
            105.81230868417147,
            21.014967927531853
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Hoàng Ngọc Phách",
        "diemDau": "4-2",
        "diemCuoi": "4-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.015140690089474,
        "to_latitude": 21.014967927531853,
        "to_longitude": 105.81230868417147,
        "from_longitude": 105.81242401915827,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2694,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81229259091602,
          21.015080598787563,
          105.81242401915827,
          21.015140690089474
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81242401915827,
            21.015140690089474
          ],
          [
            105.81229259091602,
            21.015080598787563
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Hoàng Ngọc Phách",
        "diemDau": "4-3",
        "diemCuoi": "4-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.015140690089474,
        "to_latitude": 21.015080598787563,
        "to_longitude": 105.81229259091602,
        "from_longitude": 105.81242401915827,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2695,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81193468353862,
          21.01583032995035,
          105.81203241664826,
          21.01587430278233
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81193468353862,
            21.01583032995035
          ],
          [
            105.81203241664826,
            21.01587430278233
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8C Hoàng Ngọc Phách",
        "diemDau": "07",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01583032995035,
        "to_latitude": 21.01587430278233,
        "to_longitude": 105.81203241664826,
        "from_longitude": 105.81193468353862,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2696,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81203241664826,
          21.01587430278233,
          105.81213434058738,
          21.01601952261419
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81203241664826,
            21.01587430278233
          ],
          [
            105.81213434058738,
            21.01601952261419
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8C Hoàng Ngọc Phách",
        "diemDau": "7-1",
        "diemCuoi": "7-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01587430278233,
        "to_latitude": 21.01601952261419,
        "to_longitude": 105.81213434058738,
        "from_longitude": 105.81203241664826,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2697,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81213434058738,
          21.01601952261419,
          105.81223894673424,
          21.01619729152588
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81213434058738,
            21.01601952261419
          ],
          [
            105.81223894673424,
            21.01619729152588
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8C Hoàng Ngọc Phách",
        "diemDau": "7-2",
        "diemCuoi": "7-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01601952261419,
        "to_latitude": 21.01619729152588,
        "to_longitude": 105.81223894673424,
        "from_longitude": 105.81213434058738,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2698,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81143160182658,
          21.016092132477095,
          105.81160047925616,
          21.016128775439025
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81160047925616,
            21.016128775439025
          ],
          [
            105.81143160182658,
            21.016092132477095
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT C7 Hoàng Ngọc Phách",
        "diemDau": "09",
        "diemCuoi": "9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.016128775439025,
        "to_latitude": 21.016092132477095,
        "to_longitude": 105.81143160182658,
        "from_longitude": 105.81160047925616,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2699,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81123311835486,
          21.015779158677635,
          105.81143160182658,
          21.016092132477095
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81143160182658,
            21.016092132477095
          ],
          [
            105.81123311835486,
            21.015779158677635
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT C7 Hoàng Ngọc Phách",
        "diemDau": "9-1",
        "diemCuoi": "9-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.016092132477095,
        "to_latitude": 21.015779158677635,
        "to_longitude": 105.81123311835486,
        "from_longitude": 105.81143160182658,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2700,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81107218581849,
          21.016084621113794,
          105.81131777547049,
          21.016276004289498
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81131777547049,
            21.016276004289498
          ],
          [
            105.81118634731807,
            21.01627099670986
          ],
          [
            105.81107218581849,
            21.016084621113794
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 21 Hoàng Ngọc Phách",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.016276004289498,
        "to_latitude": 21.016084621113794,
        "to_longitude": 105.81107218581849,
        "from_longitude": 105.81131777547049,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2701,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81099708396579,
          21.016134696862988,
          105.8111313620324,
          21.016443757768904
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8110200502674,
            21.016443757768904
          ],
          [
            105.8111313620324,
            21.016366140500747
          ],
          [
            105.81099708396579,
            21.016134696862988
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 23 Hoàng Ngọc Phách",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.016443757768904,
        "to_latitude": 21.016134696862988,
        "to_longitude": 105.81099708396579,
        "from_longitude": 105.8110200502674,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2702,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8106686808825,
          21.016396185905826,
          105.8110200502674,
          21.016477558810095
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8110200502674,
            21.016443757768904
          ],
          [
            105.81081620247002,
            21.016477558810095
          ],
          [
            105.8106686808825,
            21.016396185905826
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyên Hồng",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.016443757768904,
        "to_latitude": 21.016396185905826,
        "to_longitude": 105.8106686808825,
        "from_longitude": 105.8110200502674,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2703,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81039836727084,
          21.01619605369228,
          105.8106686808825,
          21.016396185905826
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8106686808825,
            21.016396185905826
          ],
          [
            105.81039836727084,
            21.01619605369228
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyên Hồng",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.016396185905826,
        "to_latitude": 21.01619605369228,
        "to_longitude": 105.81039836727084,
        "from_longitude": 105.8106686808825,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2704,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81013189304622,
          21.01600772251115,
          105.81039836727084,
          21.01619605369228
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81039836727084,
            21.01619605369228
          ],
          [
            105.81013189304622,
            21.01600772251115
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyên Hồng",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01619605369228,
        "to_latitude": 21.01600772251115,
        "to_longitude": 105.81013189304622,
        "from_longitude": 105.81039836727084,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2705,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80985362769937,
          21.015790800473983,
          105.81013189304622,
          21.01600772251115
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81013189304622,
            21.01600772251115
          ],
          [
            105.80985362769937,
            21.015790800473983
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyên Hồng",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01600772251115,
        "to_latitude": 21.015790800473983,
        "to_longitude": 105.80985362769937,
        "from_longitude": 105.81013189304622,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2706,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80955641721337,
          21.01555281528325,
          105.80985362769937,
          21.015790800473983
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80985362769937,
            21.015790800473983
          ],
          [
            105.80955641721337,
            21.01555281528325
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyên Hồng",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.015790800473983,
        "to_latitude": 21.01555281528325,
        "to_longitude": 105.80955641721337,
        "from_longitude": 105.80985362769937,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2707,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80933830521366,
          21.015189357778688,
          105.80955641721337,
          21.01555281528325
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80955641721337,
            21.01555281528325
          ],
          [
            105.8093999961175,
            21.015403432764966
          ],
          [
            105.80937049181436,
            21.015344593468477
          ],
          [
            105.80933830521366,
            21.015189357778688
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 52,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyên Hồng",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01555281528325,
        "to_latitude": 21.015189357778688,
        "to_longitude": 105.80933830521366,
        "from_longitude": 105.80955641721337,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2708,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81050355750769,
          21.016396185905826,
          105.8106686808825,
          21.016470203951048
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8106686808825,
            21.016396185905826
          ],
          [
            105.81050355750769,
            21.016470203951048
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT Công An",
        "diemDau": "12",
        "diemCuoi": "12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.016396185905826,
        "to_latitude": 21.016470203951048,
        "to_longitude": 105.81050355750769,
        "from_longitude": 105.8106686808825,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2709,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81039836727084,
          21.016127185501862,
          105.81057597715267,
          21.01619605369228
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81039836727084,
            21.01619605369228
          ],
          [
            105.81057597715267,
            21.016127185501862
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 71 Nguyên Hồng",
        "diemDau": "13",
        "diemCuoi": "13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01619605369228,
        "to_latitude": 21.016127185501862,
        "to_longitude": 105.81057597715267,
        "from_longitude": 105.81039836727084,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2710,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81057597715267,
          21.01599448472235,
          105.81074495632124,
          21.016127185501862
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81057597715267,
            21.016127185501862
          ],
          [
            105.81074495632124,
            21.01599448472235
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 71 Nguyên Hồng",
        "diemDau": "13-1",
        "diemCuoi": "13-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.016127185501862,
        "to_latitude": 21.01599448472235,
        "to_longitude": 105.81074495632124,
        "from_longitude": 105.81057597715267,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2711,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80933830521366,
          21.014986549604515,
          105.80935708067683,
          21.015189357778688
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80933830521366,
            21.015189357778688
          ],
          [
            105.80935708067683,
            21.014986549604515
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Vũ Ngọc Phan",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.015189357778688,
        "to_latitude": 21.014986549604515,
        "to_longitude": 105.80935708067683,
        "from_longitude": 105.80933830521366,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2712,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80935708067683,
          21.014845951182828,
          105.80959889213567,
          21.014986549604515
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80935708067683,
            21.014986549604515
          ],
          [
            105.80959889213567,
            21.014845951182828
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Vũ Ngọc Phan",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014986549604515,
        "to_latitude": 21.014845951182828,
        "to_longitude": 105.80959889213567,
        "from_longitude": 105.80935708067683,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2713,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80959889213567,
          21.01469000473499,
          105.80985982734417,
          21.014845951182828
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80959889213567,
            21.014845951182828
          ],
          [
            105.80985982734417,
            21.01469000473499
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Vũ Ngọc Phan",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014845951182828,
        "to_latitude": 21.01469000473499,
        "to_longitude": 105.80985982734417,
        "from_longitude": 105.80959889213567,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2714,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80985982734417,
          21.014542279795155,
          105.81012000162092,
          21.01469000473499
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80985982734417,
            21.01469000473499
          ],
          [
            105.81012000162092,
            21.014542279795155
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Vũ Ngọc Phan",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01469000473499,
        "to_latitude": 21.014542279795155,
        "to_longitude": 105.81012000162092,
        "from_longitude": 105.80985982734417,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2715,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81012000162092,
          21.01439455470816,
          105.8103614004255,
          21.014542279795155
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81012000162092,
            21.014542279795155
          ],
          [
            105.8103614004255,
            21.01439455470816
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Vũ Ngọc Phan",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014542279795155,
        "to_latitude": 21.01439455470816,
        "to_longitude": 105.8103614004255,
        "from_longitude": 105.81012000162092,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2716,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8103614004255,
          21.014261852386984,
          105.81061084586229,
          21.01439455470816
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8103614004255,
            21.01439455470816
          ],
          [
            105.81061084586229,
            21.014261852386984
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Vũ Ngọc Phan",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01439455470816,
        "to_latitude": 21.014261852386984,
        "to_longitude": 105.81061084586229,
        "from_longitude": 105.8103614004255,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2717,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81061084586229,
          21.014142957086264,
          105.81080794942983,
          21.014261852386984
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81061084586229,
            21.014261852386984
          ],
          [
            105.81080794942983,
            21.014142957086264
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Vũ Ngọc Phan",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014261852386984,
        "to_latitude": 21.014142957086264,
        "to_longitude": 105.81080794942983,
        "from_longitude": 105.81061084586229,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2718,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81080794942983,
          21.01398893592167,
          105.81107486802624,
          21.014142957086264
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81080794942983,
            21.014142957086264
          ],
          [
            105.81107486802624,
            21.01398893592167
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Vũ Ngọc Phan",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014142957086264,
        "to_latitude": 21.01398893592167,
        "to_longitude": 105.81107486802624,
        "from_longitude": 105.81080794942983,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2719,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81107486802624,
          21.01386175851985,
          105.81129157234751,
          21.01398893592167
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81107486802624,
            21.01398893592167
          ],
          [
            105.81129157234751,
            21.01386175851985
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Vũ Ngọc Phan",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01398893592167,
        "to_latitude": 21.01386175851985,
        "to_longitude": 105.81129157234751,
        "from_longitude": 105.81107486802624,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2720,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81129157234751,
          21.01372115903785,
          105.81153338380633,
          21.01386175851985
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81129157234751,
            21.01386175851985
          ],
          [
            105.81153338380633,
            21.01372115903785
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Vũ Ngọc Phan",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01386175851985,
        "to_latitude": 21.01372115903785,
        "to_longitude": 105.81153338380633,
        "from_longitude": 105.81129157234751,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2721,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81153338380633,
          21.013580559423296,
          105.81177519526517,
          21.01372115903785
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81153338380633,
            21.01372115903785
          ],
          [
            105.81177519526517,
            21.013580559423296
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Vũ Ngọc Phan",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01372115903785,
        "to_latitude": 21.013580559423296,
        "to_longitude": 105.81177519526517,
        "from_longitude": 105.81153338380633,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2722,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81177519526517,
          21.013439959676234,
          105.812017006724,
          21.013580559423296
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81177519526517,
            21.013580559423296
          ],
          [
            105.812017006724,
            21.013439959676234
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Vũ Ngọc Phan",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.013580559423296,
        "to_latitude": 21.013439959676234,
        "to_longitude": 105.812017006724,
        "from_longitude": 105.81177519526517,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2723,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.812017006724,
          21.01329935979663,
          105.81225881818283,
          21.013439959676234
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.812017006724,
            21.013439959676234
          ],
          [
            105.81225881818283,
            21.01329935979663
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Vũ Ngọc Phan",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.013439959676234,
        "to_latitude": 21.01329935979663,
        "to_longitude": 105.81225881818283,
        "from_longitude": 105.812017006724,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2724,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81225881818283,
          21.01315875978446,
          105.81250062964169,
          21.01329935979663
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81225881818283,
            21.01329935979663
          ],
          [
            105.81250062964169,
            21.01315875978446
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Vũ Ngọc Phan",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01329935979663,
        "to_latitude": 21.01315875978446,
        "to_longitude": 105.81250062964169,
        "from_longitude": 105.81225881818283,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2725,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81196804363552,
          21.013143893239445,
          105.81212646157182,
          21.013439959676234
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.812017006724,
            21.013439959676234
          ],
          [
            105.81212646157182,
            21.013350303181763
          ],
          [
            105.81196804363552,
            21.013143893239445
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 11 Vũ Ngọc Phan",
        "diemDau": "29",
        "diemCuoi": "29-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.013439959676234,
        "to_latitude": 21.013143893239445,
        "to_longitude": 105.81196804363552,
        "from_longitude": 105.812017006724,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2726,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8107999416069,
          21.014142957086264,
          105.81080794942983,
          21.014353241734344
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81080794942983,
            21.014142957086264
          ],
          [
            105.8107999416069,
            21.014353241734344
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT C7 Vũ Ngọc Phan",
        "diemDau": "24",
        "diemCuoi": "24-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014142957086264,
        "to_latitude": 21.014353241734344,
        "to_longitude": 105.8107999416069,
        "from_longitude": 105.81080794942983,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2727,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8107999416069,
          21.014353241734344,
          105.81092466432979,
          21.01453226453939
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8107999416069,
            21.014353241734344
          ],
          [
            105.81092466432979,
            21.01453226453939
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT C7 Vũ Ngọc Phan",
        "diemDau": "24-1",
        "diemCuoi": "24-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014353241734344,
        "to_latitude": 21.01453226453939,
        "to_longitude": 105.81092466432979,
        "from_longitude": 105.8107999416069,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2728,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81092466432979,
          21.01453226453939,
          105.81103865821272,
          21.01464118040701
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81092466432979,
            21.01453226453939
          ],
          [
            105.81103865821272,
            21.01464118040701
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT C7 Vũ Ngọc Phan",
        "diemDau": "24-2",
        "diemCuoi": "24-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01453226453939,
        "to_latitude": 21.01464118040701,
        "to_longitude": 105.81103865821272,
        "from_longitude": 105.81092466432979,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2729,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8105382958089,
          21.014261852386984,
          105.81061084586229,
          21.014469669177704
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81058536488874,
            21.014469669177704
          ],
          [
            105.8105382958089,
            21.014368716718277
          ],
          [
            105.81061084586229,
            21.014261852386984
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Vũ Ngọc Phan",
        "diemDau": "23",
        "diemCuoi": "24-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014469669177704,
        "to_latitude": 21.014261852386984,
        "to_longitude": 105.81061084586229,
        "from_longitude": 105.81058536488874,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2730,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81058536488874,
          21.014469669177704,
          105.81070338208329,
          21.014647439937587
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81058536488874,
            21.014469669177704
          ],
          [
            105.81070338208329,
            21.014647439937587
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Vũ Ngọc Phan",
        "diemDau": "24-1",
        "diemCuoi": "24-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014469669177704,
        "to_latitude": 21.014647439937587,
        "to_longitude": 105.81070338208329,
        "from_longitude": 105.81058536488874,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2731,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81070338208329,
          21.014647439937587,
          105.81084151584487,
          21.0148565080947
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81070338208329,
            21.014647439937587
          ],
          [
            105.81084151584487,
            21.0148565080947
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Vũ Ngọc Phan",
        "diemDau": "24-2",
        "diemCuoi": "24-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014647439937587,
        "to_latitude": 21.0148565080947,
        "to_longitude": 105.81084151584487,
        "from_longitude": 105.81070338208329,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2732,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81058536488874,
          21.014469669177704,
          105.81069131213948,
          21.014522249283793
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81058536488874,
            21.014469669177704
          ],
          [
            105.81069131213948,
            21.014522249283793
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Vũ Ngọc Phan",
        "diemDau": "24-1",
        "diemCuoi": "24-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014469669177704,
        "to_latitude": 21.014522249283793,
        "to_longitude": 105.81069131213948,
        "from_longitude": 105.81058536488874,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2733,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81069131213948,
          21.014522249283793,
          105.81082408148556,
          21.014722554278926
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81069131213948,
            21.014522249283793
          ],
          [
            105.81082408148556,
            21.014722554278926
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Vũ Ngọc Phan",
        "diemDau": "24-4",
        "diemCuoi": "24-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014522249283793,
        "to_latitude": 21.014722554278926,
        "to_longitude": 105.81082408148556,
        "from_longitude": 105.81069131213948,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2734,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81012000162092,
          21.014542279795155,
          105.81032116729139,
          21.014727561900514
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81012000162092,
            21.014542279795155
          ],
          [
            105.81032116729139,
            21.014727561900514
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 42 Vũ Ngọc Phan",
        "diemDau": "21",
        "diemCuoi": "21-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014542279795155,
        "to_latitude": 21.014727561900514,
        "to_longitude": 105.81032116729139,
        "from_longitude": 105.81012000162092,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2735,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81032116729139,
          21.014727561900514,
          105.81047405320457,
          21.014955408497343
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81032116729139,
            21.014727561900514
          ],
          [
            105.81047405320457,
            21.014955408497343
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 42 Vũ Ngọc Phan",
        "diemDau": "21-1",
        "diemCuoi": "21-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014727561900514,
        "to_latitude": 21.014955408497343,
        "to_longitude": 105.81047405320457,
        "from_longitude": 105.81032116729139,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2736,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81047405320457,
          21.014955408497343,
          105.81062425690997,
          21.015228323195043
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81047405320457,
            21.014955408497343
          ],
          [
            105.81062425690997,
            21.015228323195043
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 42 Vũ Ngọc Phan",
        "diemDau": "21-2",
        "diemCuoi": "21-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014955408497343,
        "to_latitude": 21.015228323195043,
        "to_longitude": 105.81062425690997,
        "from_longitude": 105.81047405320457,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2737,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81062425690997,
          21.01501549984969,
          105.81072349864137,
          21.015228323195043
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81062425690997,
            21.015228323195043
          ],
          [
            105.81062814980417,
            21.01510859271874
          ],
          [
            105.81072349864137,
            21.01501549984969
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 42 Vũ Ngọc Phan",
        "diemDau": "21-3",
        "diemCuoi": "21-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.015228323195043,
        "to_latitude": 21.01501549984969,
        "to_longitude": 105.81072349864137,
        "from_longitude": 105.81062425690997,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2738,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81072349864137,
          21.01493287423459,
          105.81091125327313,
          21.01501549984969
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81072349864137,
            21.01501549984969
          ],
          [
            105.81091125327313,
            21.01493287423459
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 42 Vũ Ngọc Phan",
        "diemDau": "21-7",
        "diemCuoi": "21-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01501549984969,
        "to_latitude": 21.01493287423459,
        "to_longitude": 105.81091125327313,
        "from_longitude": 105.81072349864137,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2739,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81091125327313,
          21.01493287423459,
          105.81104268151536,
          21.015118155854736
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81091125327313,
            21.01493287423459
          ],
          [
            105.81104268151536,
            21.015118155854736
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 42 Vũ Ngọc Phan",
        "diemDau": "21-8",
        "diemCuoi": "21-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01493287423459,
        "to_latitude": 21.015118155854736,
        "to_longitude": 105.81104268151536,
        "from_longitude": 105.81091125327313,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2740,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81049014646,
          21.015228323195043,
          105.81062425690997,
          21.015358520856573
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81062425690997,
            21.015228323195043
          ],
          [
            105.81049014646,
            21.015358520856573
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 42 Vũ Ngọc Phan",
        "diemDau": "21-3",
        "diemCuoi": "21-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.015228323195043,
        "to_latitude": 21.015358520856573,
        "to_longitude": 105.81049014646,
        "from_longitude": 105.81062425690997,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2741,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81039090472864,
          21.015358520856573,
          105.81049014646,
          21.01559888547103
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81049014646,
            21.015358520856573
          ],
          [
            105.81039090472864,
            21.01559888547103
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 42 Vũ Ngọc Phan",
        "diemDau": "21-5",
        "diemCuoi": "21-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.015358520856573,
        "to_latitude": 21.01559888547103,
        "to_longitude": 105.81039090472864,
        "from_longitude": 105.81049014646,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2742,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8102594764864,
          21.01559888547103,
          105.81039090472864,
          21.01590184448497
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81039090472864,
            21.01559888547103
          ],
          [
            105.8102594764864,
            21.01590184448497
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 42 Vũ Ngọc Phan",
        "diemDau": "21-5",
        "diemCuoi": "21-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01559888547103,
        "to_latitude": 21.01590184448497,
        "to_longitude": 105.8102594764864,
        "from_longitude": 105.81039090472864,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2743,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81046332437359,
          21.014081577346392,
          105.81061084586229,
          21.014261852386984
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81061084586229,
            21.014261852386984
          ],
          [
            105.81046332437359,
            21.014081577346392
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "diemDau": "23",
        "diemCuoi": "23-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014261852386984,
        "to_latitude": 21.014081577346392,
        "to_longitude": 105.81046332437359,
        "from_longitude": 105.81061084586229,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2744,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81042845565497,
          21.01389128678907,
          105.81046332437359,
          21.014081577346392
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81046332437359,
            21.014081577346392
          ],
          [
            105.81042845565497,
            21.01389128678907
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "diemDau": "23-1",
        "diemCuoi": "23-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014081577346392,
        "to_latitude": 21.01389128678907,
        "to_longitude": 105.81042845565497,
        "from_longitude": 105.81046332437359,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2745,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81020045724408,
          21.01389128678907,
          105.81042845565497,
          21.014064994495474
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81042845565497,
            21.01389128678907
          ],
          [
            105.81020045724408,
            21.014064994495474
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "diemDau": "23-2",
        "diemCuoi": "23-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01389128678907,
        "to_latitude": 21.014064994495474,
        "to_longitude": 105.81020045724408,
        "from_longitude": 105.81042845565497,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2746,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80999393779418,
          21.014064994495474,
          105.81020045724408,
          21.014214279827936
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81020045724408,
            21.014064994495474
          ],
          [
            105.80999393779418,
            21.014214279827936
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "diemDau": "23-5",
        "diemCuoi": "23-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014064994495474,
        "to_latitude": 21.014214279827936,
        "to_longitude": 105.80999393779418,
        "from_longitude": 105.81020045724408,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2747,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80982495862557,
          21.014214279827936,
          105.80999393779418,
          21.01434197455705
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80999393779418,
            21.014214279827936
          ],
          [
            105.80982495862557,
            21.01434197455705
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "diemDau": "23-6",
        "diemCuoi": "23-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014214279827936,
        "to_latitude": 21.01434197455705,
        "to_longitude": 105.80982495862557,
        "from_longitude": 105.80999393779418,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2748,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80961038190742,
          21.01434197455705,
          105.80982495862557,
          21.01448969969612
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80982495862557,
            21.01434197455705
          ],
          [
            105.80961038190742,
            21.01448969969612
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "diemDau": "23-7",
        "diemCuoi": "23-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01434197455705,
        "to_latitude": 21.01448969969612,
        "to_longitude": 105.80961038190742,
        "from_longitude": 105.80982495862557,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2749,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80932338553527,
          21.01448969969612,
          105.80961038190742,
          21.014707531413197
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80961038190742,
            21.01448969969612
          ],
          [
            105.80932338553527,
            21.014707531413197
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "diemDau": "23-8",
        "diemCuoi": "23-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01448969969612,
        "to_latitude": 21.014707531413197,
        "to_longitude": 105.80932338553527,
        "from_longitude": 105.80961038190742,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2750,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80961038190742,
          21.01448969969612,
          105.8097525389806,
          21.01455574276123
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80961038190742,
            21.01448969969612
          ],
          [
            105.80968279090735,
            21.01455574276123
          ],
          [
            105.8097525389806,
            21.014534768352974
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "diemDau": "23-8",
        "diemCuoi": "23-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01448969969612,
        "to_latitude": 21.014534768352974,
        "to_longitude": 105.8097525389806,
        "from_longitude": 105.80961038190742,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2751,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8097525389806,
          21.014364508909914,
          105.81003148872051,
          21.014534768352974
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8097525389806,
            21.014534768352974
          ],
          [
            105.81003148872051,
            21.014364508909914
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "diemDau": "23-12",
        "diemCuoi": "23-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014534768352974,
        "to_latitude": 21.014364508909914,
        "to_longitude": 105.81003148872051,
        "from_longitude": 105.8097525389806,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2752,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80952186900699,
          21.014534768352974,
          105.8097525389806,
          21.01466747043213
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8097525389806,
            21.014534768352974
          ],
          [
            105.80952186900699,
            21.01466747043213
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "diemDau": "23-12",
        "diemCuoi": "23-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014534768352974,
        "to_latitude": 21.01466747043213,
        "to_longitude": 105.80952186900699,
        "from_longitude": 105.8097525389806,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2753,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80952455121475,
          21.014367012726336,
          105.80961038190742,
          21.01448969969612
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80961038190742,
            21.01448969969612
          ],
          [
            105.80952455121475,
            21.014367012726336
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "diemDau": "23-8",
        "diemCuoi": "23-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01448969969612,
        "to_latitude": 21.014367012726336,
        "to_longitude": 105.80952455121475,
        "from_longitude": 105.80961038190742,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2754,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80988396722286,
          21.014011470327016,
          105.80999393779418,
          21.014214279827936
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80999393779418,
            21.014214279827936
          ],
          [
            105.80999392714912,
            21.01417015497359
          ],
          [
            105.80988396722286,
            21.014011470327016
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "diemDau": "23-6",
        "diemCuoi": "23-6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.014214279827936,
        "to_latitude": 21.014011470327016,
        "to_longitude": 105.80988396722286,
        "from_longitude": 105.80999393779418,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2755,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81027556974182,
          21.01374856871258,
          105.81042845565497,
          21.01389128678907
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81042845565497,
            21.01389128678907
          ],
          [
            105.8103506609495,
            21.01380459680038
          ],
          [
            105.81027556974182,
            21.01374856871258
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "diemDau": "23-2",
        "diemCuoi": "23-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01389128678907,
        "to_latitude": 21.01374856871258,
        "to_longitude": 105.81027556974182,
        "from_longitude": 105.81042845565497,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2756,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81010659057323,
          21.013614305890528,
          105.81027556974182,
          21.01374856871258
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81027556974182,
            21.01374856871258
          ],
          [
            105.8102433725949,
            21.0137319858238
          ],
          [
            105.81017095294993,
            21.013614305890528
          ],
          [
            105.81010659057323,
            21.013653423251853
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "diemDau": "23-3",
        "diemCuoi": "23-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.01374856871258,
        "to_latitude": 21.013653423251853,
        "to_longitude": 105.81010659057323,
        "from_longitude": 105.81027556974182,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2757,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80902029591668,
          21.0149403856559,
          105.80933830521366,
          21.015189357778688
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80933830521366,
            21.015189357778688
          ],
          [
            105.80902029591668,
            21.0149403856559
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NHNgoaiThuong",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "diemDau": "17",
        "diemCuoi": "17-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NHNgoaiThuong",
        "from_latitude": 21.015189357778688,
        "to_latitude": 21.0149403856559,
        "to_longitude": 105.80902029591668,
        "from_longitude": 105.80933830521366,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2758,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81157106605602,
          21.017644164812847,
          105.81172126976143,
          21.017913318953653
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81172126976143,
            21.017913318953653
          ],
          [
            105.81157106605602,
            21.017644164812847
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyên Hồng",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.017913318953653,
        "to_latitude": 21.017644164812847,
        "to_longitude": 105.81157106605602,
        "from_longitude": 105.81172126976143,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2759,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81142354455834,
          21.017381269601227,
          105.81157106605602,
          21.017644164812847
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81157106605602,
            21.017644164812847
          ],
          [
            105.81142354455834,
            21.017381269601227
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyên Hồng",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.017644164812847,
        "to_latitude": 21.017381269601227,
        "to_longitude": 105.81142354455834,
        "from_longitude": 105.81157106605602,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2760,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8112746819568,
          21.017117122040634,
          105.81142354455834,
          21.017381269601227
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81142354455834,
            21.017381269601227
          ],
          [
            105.8112746819568,
            21.017117122040634
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyên Hồng",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.017381269601227,
        "to_latitude": 21.017117122040634,
        "to_longitude": 105.8112746819568,
        "from_longitude": 105.81142354455834,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2761,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81112447825136,
          21.016850470237483,
          105.8112746819568,
          21.017117122040634
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8112746819568,
            21.017117122040634
          ],
          [
            105.81112447825136,
            21.016850470237483
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyên Hồng",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.017117122040634,
        "to_latitude": 21.016850470237483,
        "to_longitude": 105.81112447825136,
        "from_longitude": 105.8112746819568,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2762,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8109756156588,
          21.016583817958363,
          105.81112447825136,
          21.016850470237483
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81112447825136,
            21.016850470237483
          ],
          [
            105.8109756156588,
            21.016583817958363
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyên Hồng",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.016850470237483,
        "to_latitude": 21.016583817958363,
        "to_longitude": 105.8109756156588,
        "from_longitude": 105.81112447825136,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2763,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81093940583634,
          21.01680790605583,
          105.81112447825136,
          21.01685047023747
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81112447825136,
            21.01685047023747
          ],
          [
            105.81093940583634,
            21.01680790605583
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28 Nguyên Hồng",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.01685047023747,
        "to_latitude": 21.01680790605583,
        "to_longitude": 105.81093940583634,
        "from_longitude": 105.81112447825136,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2764,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81127388791795,
          21.017042316909492,
          105.81145976499892,
          21.017127754375185
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81145976499892,
            21.017042316909492
          ],
          [
            105.81131711300789,
            21.017127754375185
          ],
          [
            105.81127388791795,
            21.01711617816112
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 47 Nguyên Hồng",
        "diemDau": "13",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.017042316909492,
        "to_latitude": 21.01711617816112,
        "to_longitude": 105.81127388791795,
        "from_longitude": 105.81145976499892,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2765,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83456524665382,
          21.02101449799118,
          105.83467586913213,
          21.021258555834834
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83467586913213,
            21.021258555834834
          ],
          [
            105.83456524665382,
            21.02101449799118
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "00",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.021258555834834,
        "to_latitude": 21.02101449799118,
        "to_longitude": 105.83456524665382,
        "from_longitude": 105.83467586913213,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2766,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83456524665382,
          21.020910594193186,
          105.8346108442034,
          21.02101449799118
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83456524665382,
            21.02101449799118
          ],
          [
            105.8346108442034,
            21.020910594193186
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "13",
        "diemCuoi": "13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.02101449799118,
        "to_latitude": 21.020910594193186,
        "to_longitude": 105.8346108442034,
        "from_longitude": 105.83456524665382,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2767,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83456524665382,
          21.020786660652,
          105.83459475094794,
          21.02101449799118
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83456524665382,
            21.02101449799118
          ],
          [
            105.83459475094794,
            21.020786660652
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.02101449799118,
        "to_latitude": 21.020786660652,
        "to_longitude": 105.83459475094794,
        "from_longitude": 105.83456524665382,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2768,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83449685032046,
          21.020910594193186,
          105.8346108442034,
          21.02093062384648
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8346108442034,
            21.020910594193186
          ],
          [
            105.83449685032046,
            21.02093062384648
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "13-1",
        "diemCuoi": "13-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.020910594193186,
        "to_latitude": 21.02093062384648,
        "to_longitude": 105.83449685032046,
        "from_longitude": 105.8346108442034,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2769,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8346108442034,
          21.020883053414796,
          105.83477580006037,
          21.020910594193186
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8346108442034,
            21.020910594193186
          ],
          [
            105.83477580006037,
            21.020883053414796
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "13-1",
        "diemCuoi": "13-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.020910594193186,
        "to_latitude": 21.020883053414796,
        "to_longitude": 105.83477580006037,
        "from_longitude": 105.8346108442034,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2770,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83457865769252,
          21.02064269731767,
          105.83459475094794,
          21.020786660652
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83459475094794,
            21.020786660652
          ],
          [
            105.83457865769252,
            21.02064269731767
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.020786660652,
        "to_latitude": 21.02064269731767,
        "to_longitude": 105.83457865769252,
        "from_longitude": 105.83459475094794,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2771,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83457865769252,
          21.02064269731767,
          105.83465912396971,
          21.020681050760473
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83457865769252,
            21.02064269731767
          ],
          [
            105.8345829451143,
            21.020681050760473
          ],
          [
            105.83465912396971,
            21.020677749272778
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "15",
        "diemCuoi": "15-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.02064269731767,
        "to_latitude": 21.020677749272778,
        "to_longitude": 105.83465912396971,
        "from_longitude": 105.83457865769252,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2772,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83436005766275,
          21.02064269731767,
          105.83457865769252,
          21.020658971440504
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83457865769252,
            21.02064269731767
          ],
          [
            105.83436005766275,
            21.020658971440504
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "15",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.02064269731767,
        "to_latitude": 21.020658971440504,
        "to_longitude": 105.83436005766275,
        "from_longitude": 105.83457865769252,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2773,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83411329443369,
          21.020658971440504,
          105.83436005766275,
          21.02073408275468
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83436005766275,
            21.020658971440504
          ],
          [
            105.83411329443369,
            21.02073408275468
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.020658971440504,
        "to_latitude": 21.02073408275468,
        "to_longitude": 105.83411329443369,
        "from_longitude": 105.83436005766275,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2774,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83453842455843,
          21.020427377984877,
          105.83457865769252,
          21.02064269731767
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83457865769252,
            21.02064269731767
          ],
          [
            105.83453842455843,
            21.020427377984877
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.02064269731767,
        "to_latitude": 21.020427377984877,
        "to_longitude": 105.83453842455843,
        "from_longitude": 105.83457865769252,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2775,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83448343927277,
          21.020220821355966,
          105.83453842455843,
          21.020427377984877
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83453842455843,
            21.020427377984877
          ],
          [
            105.83448343927277,
            21.020220821355966
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.020427377984877,
        "to_latitude": 21.020220821355966,
        "to_longitude": 105.83448343927277,
        "from_longitude": 105.83453842455843,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2776,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83399166184353,
          21.020473696703764,
          105.83411329443369,
          21.02073408275468
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83411329443369,
            21.02073408275468
          ],
          [
            105.83399166184353,
            21.020473696703764
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.02073408275468,
        "to_latitude": 21.020473696703764,
        "to_longitude": 105.83399166184353,
        "from_longitude": 105.83411329443369,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2777,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83352361636796,
          21.020448659562305,
          105.83399166184353,
          21.020625171337045
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83399166184353,
            21.020473696703764
          ],
          [
            105.83397422750218,
            21.020448659562305
          ],
          [
            105.83352361636796,
            21.020625171337045
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "20",
        "diemCuoi": "20-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.020473696703764,
        "to_latitude": 21.020625171337045,
        "to_longitude": 105.83352361636796,
        "from_longitude": 105.83399166184353,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2778,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83394297416123,
          21.020373548103503,
          105.83399166184353,
          21.020473696703764
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83399166184353,
            21.020473696703764
          ],
          [
            105.83394297416123,
            21.020373548103503
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.020473696703764,
        "to_latitude": 21.020373548103503,
        "to_longitude": 105.83394297416123,
        "from_longitude": 105.83399166184353,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2779,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8337042575554,
          21.020373548103503,
          105.83394297416123,
          21.020458674419057
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83394297416123,
            21.020373548103503
          ],
          [
            105.8337042575554,
            21.020458674419057
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "21",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.020373548103503,
        "to_latitude": 21.020458674419057,
        "to_longitude": 105.8337042575554,
        "from_longitude": 105.83394297416123,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2780,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83394297416123,
          21.020302192183248,
          105.83394565636897,
          21.020373548103503
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83394297416123,
            21.020373548103503
          ],
          [
            105.83394565636897,
            21.020302192183248
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 4,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.020373548103503,
        "to_latitude": 21.020302192183248,
        "to_longitude": 105.83394565636897,
        "from_longitude": 105.83394297416123,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2781,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83394565636897,
          21.020268391998943,
          105.83404489810034,
          21.020302192183248
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83394565636897,
            21.020302192183248
          ],
          [
            105.83404489810034,
            21.020268391998943
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.020302192183248,
        "to_latitude": 21.020268391998943,
        "to_longitude": 105.83404489810034,
        "from_longitude": 105.83394565636897,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2782,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.833615744664,
          21.020302192183248,
          105.83394565636897,
          21.02030845147621
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83394565636897,
            21.020302192183248
          ],
          [
            105.833615744664,
            21.02030845147621
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "22",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.020302192183248,
        "to_latitude": 21.02030845147621,
        "to_longitude": 105.833615744664,
        "from_longitude": 105.83394565636897,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2783,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83350175077207,
          21.020279658728445,
          105.833615744664,
          21.02030845147621
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.833615744664,
            21.02030845147621
          ],
          [
            105.83350175077207,
            21.020279658728445
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "24",
        "diemCuoi": "24-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.02030845147621,
        "to_latitude": 21.020279658728445,
        "to_longitude": 105.83350175077207,
        "from_longitude": 105.833615744664,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2784,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83360099251243,
          21.02008186491792,
          105.833615744664,
          21.02030845147621
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.833615744664,
            21.02030845147621
          ],
          [
            105.83360099251243,
            21.02008186491792
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.02030845147621,
        "to_latitude": 21.02008186491792,
        "to_longitude": 105.83360099251243,
        "from_longitude": 105.833615744664,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2785,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83360099251243,
          21.020049316543755,
          105.83365102130757,
          21.020084368640006
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83360099251243,
            21.02008186491792
          ],
          [
            105.83365102130757,
            21.020084368640006
          ],
          [
            105.8336425667504,
            21.020049316543755
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Trung Tả",
        "diemDau": "25",
        "diemCuoi": "25-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Chương 1",
        "from_latitude": 21.02008186491792,
        "to_latitude": 21.020049316543755,
        "to_longitude": 105.8336425667504,
        "from_longitude": 105.83360099251243,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2786,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82049713299233,
          21.012044391652225,
          105.82081329838039,
          21.012200882552108
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82081329838039,
            21.01212920974098
          ],
          [
            105.82072646186208,
            21.012200882552108
          ],
          [
            105.82049713299233,
            21.012044391652225
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 89 Thái Hà",
        "diemDau": "01",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01212920974098,
        "to_latitude": 21.012044391652225,
        "to_longitude": 105.82049713299233,
        "from_longitude": 105.82081329838039,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2787,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82032949492762,
          21.011902923736223,
          105.82049713299233,
          21.012044391652225
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82049713299233,
            21.012044391652225
          ],
          [
            105.82032949492762,
            21.011902923736223
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 89 Thái Hà",
        "diemDau": "1-1",
        "diemCuoi": "1-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.012044391652225,
        "to_latitude": 21.011902923736223,
        "to_longitude": 105.82032949492762,
        "from_longitude": 105.82049713299233,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2788,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82023286919211,
          21.012567208593893,
          105.82028490320369,
          21.012624972068
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82028490320369,
            21.012624972068
          ],
          [
            105.82023286919211,
            21.012567208593893
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "diemDau": "03",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.012624972068,
        "to_latitude": 21.012567208593893,
        "to_longitude": 105.82023286919211,
        "from_longitude": 105.82028490320369,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2789,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81993620177394,
          21.012252393820066,
          105.82023286919211,
          21.012567208593893
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82023286919211,
            21.012567208593893
          ],
          [
            105.81993620177394,
            21.012252393820066
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "diemDau": "3-1",
        "diemCuoi": "3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.012567208593893,
        "to_latitude": 21.012252393820066,
        "to_longitude": 105.81993620177394,
        "from_longitude": 105.82023286919211,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2790,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81974488359134,
          21.012041008292847,
          105.81993620177394,
          21.012252393820066
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81993620177394,
            21.012252393820066
          ],
          [
            105.81974488359134,
            21.012041008292847
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "diemDau": "3-2",
        "diemCuoi": "3-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.012252393820066,
        "to_latitude": 21.012041008292847,
        "to_longitude": 105.81974488359134,
        "from_longitude": 105.81993620177394,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2791,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81974488359134,
          21.01186782388677,
          105.8199644648252,
          21.012041008292847
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81974488359134,
            21.012041008292847
          ],
          [
            105.8199644648252,
            21.01186782388677
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "diemDau": "3-3",
        "diemCuoi": "3-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.012041008292847,
        "to_latitude": 21.01186782388677,
        "to_longitude": 105.8199644648252,
        "from_longitude": 105.81974488359134,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2792,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81960353918274,
          21.011889035276962,
          105.81974488359134,
          21.012041008292847
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81974488359134,
            21.012041008292847
          ],
          [
            105.81960353918274,
            21.011889035276962
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "diemDau": "3-3",
        "diemCuoi": "3-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.012041008292847,
        "to_latitude": 21.011889035276962,
        "to_longitude": 105.81960353918274,
        "from_longitude": 105.81974488359134,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2793,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81951725632135,
          21.011844075453425,
          105.81960353918274,
          21.011889035276962
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81960353918274,
            21.011889035276962
          ],
          [
            105.81951725632135,
            21.011844075453425
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "diemDau": "3-5",
        "diemCuoi": "3-5-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.011889035276962,
        "to_latitude": 21.011844075453425,
        "to_longitude": 105.81951725632135,
        "from_longitude": 105.81960353918274,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2794,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81942376259626,
          21.011701617202295,
          105.81960353918274,
          21.011889035276962
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81960353918274,
            21.011889035276962
          ],
          [
            105.81942376259626,
            21.011701617202295
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "diemDau": "3-5",
        "diemCuoi": "3-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.011889035276962,
        "to_latitude": 21.011701617202295,
        "to_longitude": 105.81942376259626,
        "from_longitude": 105.81960353918274,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2795,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81927438093834,
          21.011495733260443,
          105.81942376259626,
          21.011701617202295
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81942376259626,
            21.011701617202295
          ],
          [
            105.81927438093834,
            21.011495733260443
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "diemDau": "3-8",
        "diemCuoi": "3-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.011701617202295,
        "to_latitude": 21.011495733260443,
        "to_longitude": 105.81927438093834,
        "from_longitude": 105.81942376259626,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2796,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81927438093834,
          21.01139007429799,
          105.81941311747408,
          21.011495733260443
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81927438093834,
            21.011495733260443
          ],
          [
            105.81941311747408,
            21.01139007429799
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "diemDau": "3-9",
        "diemCuoi": "3-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.011495733260443,
        "to_latitude": 21.01139007429799,
        "to_longitude": 105.81941311747408,
        "from_longitude": 105.81927438093834,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2797,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81928100858238,
          21.011773907641178,
          105.81960353918274,
          21.011924668343507
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81960353918274,
            21.011889035276962
          ],
          [
            105.81945377583008,
            21.011773907641178
          ],
          [
            105.81928100858238,
            21.011924668343507
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "diemDau": "3-5",
        "diemCuoi": "3-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.011889035276962,
        "to_latitude": 21.011924668343507,
        "to_longitude": 105.81928100858238,
        "from_longitude": 105.81960353918274,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2798,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81906490234086,
          21.011924668343507,
          105.81928100858238,
          21.012101620801364
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81928100858238,
            21.011924668343507
          ],
          [
            105.81906490234086,
            21.012101620801364
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "diemDau": "3-6",
        "diemCuoi": "3-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.011924668343507,
        "to_latitude": 21.012101620801364,
        "to_longitude": 105.81906490234086,
        "from_longitude": 105.81928100858238,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2799,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81907007148983,
          21.011495733260443,
          105.81927438093834,
          21.011577166785468
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81927438093834,
            21.011495733260443
          ],
          [
            105.81907007148983,
            21.011577166785468
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "diemDau": "3-9",
        "diemCuoi": "3-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.011495733260443,
        "to_latitude": 21.011577166785468,
        "to_longitude": 105.81907007148983,
        "from_longitude": 105.81927438093834,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2800,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81901220056923,
          21.011577166785468,
          105.81907007148983,
          21.011641305361216
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81907007148983,
            21.011577166785468
          ],
          [
            105.81901220056923,
            21.011641305361216
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "diemDau": "3-11",
        "diemCuoi": "3-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.011577166785468,
        "to_latitude": 21.011641305361216,
        "to_longitude": 105.81901220056923,
        "from_longitude": 105.81907007148983,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2801,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81901220056923,
          21.011641305361216,
          105.81922164328853,
          21.011837147958605
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81901220056923,
            21.011641305361216
          ],
          [
            105.81922164328853,
            21.011837147958605
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "diemDau": "3-15",
        "diemCuoi": "3-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.011641305361216,
        "to_latitude": 21.011837147958605,
        "to_longitude": 105.81922164328853,
        "from_longitude": 105.81901220056923,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2802,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81880564292484,
          21.01149311512191,
          105.81901220056923,
          21.011641305361216
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81901220056923,
            21.011641305361216
          ],
          [
            105.81880564292484,
            21.01149311512191
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "diemDau": "3-15",
        "diemCuoi": "3-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.011641305361216,
        "to_latitude": 21.01149311512191,
        "to_longitude": 105.81880564292484,
        "from_longitude": 105.81901220056923,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2803,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81860446758974,
          21.01149311512191,
          105.81880564292484,
          21.01172948224529
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81880564292484,
            21.01149311512191
          ],
          [
            105.81860446758974,
            21.01172948224529
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "diemDau": "3-13",
        "diemCuoi": "3-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01149311512191,
        "to_latitude": 21.01172948224529,
        "to_longitude": 105.81860446758974,
        "from_longitude": 105.81880564292484,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2804,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82654819654189,
          21.002614573017674,
          105.82660184072368,
          21.002726001597715
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82654819654189,
            21.002614573017674
          ],
          [
            105.82660184072368,
            21.002726001597715
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "diemDau": "",
        "diemCuoi": "01",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002614573017674,
        "to_latitude": 21.002726001597715,
        "to_longitude": 105.82660184072368,
        "from_longitude": 105.82654819654189,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2805,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82660184072368,
          21.002726001597715,
          105.82695857452404,
          21.002791105898268
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82660184072368,
            21.002726001597715
          ],
          [
            105.82695857452404,
            21.002791105898268
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002726001597715,
        "to_latitude": 21.002791105898268,
        "to_longitude": 105.82695857452404,
        "from_longitude": 105.82660184072368,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2806,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8269518690047,
          21.002791105898268,
          105.82695857452404,
          21.00296137855026
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82695857452404,
            21.002791105898268
          ],
          [
            105.8269518690047,
            21.00296137855026
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/99 Trường Chinh",
        "diemDau": "02",
        "diemCuoi": "2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002791105898268,
        "to_latitude": 21.00296137855026,
        "to_longitude": 105.8269518690047,
        "from_longitude": 105.82695857452404,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2807,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82689554259719,
          21.00296137855026,
          105.8269518690047,
          21.003209275151104
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8269518690047,
            21.00296137855026
          ],
          [
            105.82689554259719,
            21.003209275151104
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/99 Trường Chinh",
        "diemDau": "2-1",
        "diemCuoi": "2-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00296137855026,
        "to_latitude": 21.003209275151104,
        "to_longitude": 105.82689554259719,
        "from_longitude": 105.8269518690047,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2808,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82670108244608,
          21.002912550382995,
          105.8269518690047,
          21.00296137855026
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8269518690047,
            21.00296137855026
          ],
          [
            105.82670108244608,
            21.002912550382995
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/99 Trường Chinh",
        "diemDau": "2-1",
        "diemCuoi": "2-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00296137855026,
        "to_latitude": 21.002912550382995,
        "to_longitude": 105.82670108244608,
        "from_longitude": 105.8269518690047,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2809,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82663134500883,
          21.002912550382995,
          105.82670108244608,
          21.00307531087725
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82670108244608,
            21.002912550382995
          ],
          [
            105.82663134500883,
            21.00307531087725
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/99 Trường Chinh",
        "diemDau": "2-3",
        "diemCuoi": "2-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002912550382995,
        "to_latitude": 21.00307531087725,
        "to_longitude": 105.82663134500883,
        "from_longitude": 105.82670108244608,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2810,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82622096703567,
          21.003010206700697,
          105.82663134500883,
          21.00307531087725
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82663134500883,
            21.00307531087725
          ],
          [
            105.82622096703567,
            21.003010206700697
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/99 Trường Chinh",
        "diemDau": "2-4",
        "diemCuoi": "2-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00307531087725,
        "to_latitude": 21.003010206700697,
        "to_longitude": 105.82622096703567,
        "from_longitude": 105.82663134500883,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2811,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82653076217359,
          21.00307531087725,
          105.82663134500883,
          21.003387059328134
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82663134500883,
            21.00307531087725
          ],
          [
            105.82661122844179,
            21.003089082911124
          ],
          [
            105.82653076217359,
            21.003387059328134
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/99 Trường Chinh",
        "diemDau": "2-4",
        "diemCuoi": "2-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00307531087725,
        "to_latitude": 21.003387059328134,
        "to_longitude": 105.82653076217359,
        "from_longitude": 105.82663134500883,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2812,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8262102382047,
          21.00329691526542,
          105.82653076217359,
          21.003387059328134
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82653076217359,
            21.003387059328134
          ],
          [
            105.8262102382047,
            21.00329691526542
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/99 Trường Chinh",
        "diemDau": "2-6",
        "diemCuoi": "2-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.003387059328134,
        "to_latitude": 21.00329691526542,
        "to_longitude": 105.8262102382047,
        "from_longitude": 105.82653076217359,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2813,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82644627259378,
          21.003387059328134,
          105.82653076217359,
          21.00367501916721
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82653076217359,
            21.003387059328134
          ],
          [
            105.82649991677557,
            21.00345216334113
          ],
          [
            105.82644627259378,
            21.00367501916721
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/99 Trường Chinh",
        "diemDau": "2-6",
        "diemCuoi": "2-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.003387059328134,
        "to_latitude": 21.00367501916721,
        "to_longitude": 105.82644627259378,
        "from_longitude": 105.82653076217359,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2814,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82634971307016,
          21.00367501916721,
          105.82644627259378,
          21.00386532275561
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82644627259378,
            21.00367501916721
          ],
          [
            105.82634971307016,
            21.00386532275561
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/99 Trường Chinh",
        "diemDau": "2-9",
        "diemCuoi": "2-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00367501916721,
        "to_latitude": 21.00386532275561,
        "to_longitude": 105.82634971307016,
        "from_longitude": 105.82644627259378,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2815,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8260680811225,
          21.00329691526542,
          105.8262102382047,
          21.00339957933344
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8262102382047,
            21.00329691526542
          ],
          [
            105.8261834161093,
            21.00339957933344
          ],
          [
            105.8260680811225,
            21.00337453932345
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/99 Trường Chinh",
        "diemDau": "2-7",
        "diemCuoi": "2-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00329691526542,
        "to_latitude": 21.00337453932345,
        "to_longitude": 105.8260680811225,
        "from_longitude": 105.8262102382047,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2816,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82695857452404,
          21.002791105898268,
          105.8272053377441,
          21.002823658040818
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82695857452404,
            21.002791105898268
          ],
          [
            105.8272053377441,
            21.002823658040818
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002791105898268,
        "to_latitude": 21.002823658040818,
        "to_longitude": 105.8272053377441,
        "from_longitude": 105.82695857452404,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2817,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8272053377441,
          21.002823658040818,
          105.82747892305956,
          21.002886258288893
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82747892305956,
            21.002886258288893
          ],
          [
            105.8272053377441,
            21.002823658040818
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002886258288893,
        "to_latitude": 21.002823658040818,
        "to_longitude": 105.8272053377441,
        "from_longitude": 105.82747892305956,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2818,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82747892305956,
          21.002879998265687,
          105.82779944703744,
          21.002886258288893
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82747892305956,
            21.002886258288893
          ],
          [
            105.82779944703744,
            21.002879998265687
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002886258288893,
        "to_latitude": 21.002879998265687,
        "to_longitude": 105.82779944703744,
        "from_longitude": 105.82747892305956,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2819,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82779944703744,
          21.002879998265687,
          105.82787588999402,
          21.002883754279807
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82779944703744,
            21.002879998265687
          ],
          [
            105.82787588999402,
            21.002883754279807
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002879998265687,
        "to_latitude": 21.002883754279807,
        "to_longitude": 105.82787588999402,
        "from_longitude": 105.82779944703744,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2820,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82787588999402,
          21.002878746260684,
          105.82800061270792,
          21.002883754279807
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82787588999402,
            21.002883754279807
          ],
          [
            105.82800061270792,
            21.002878746260684
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "diemDau": "",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002883754279807,
        "to_latitude": 21.002878746260684,
        "to_longitude": 105.82800061270792,
        "from_longitude": 105.82787588999402,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2821,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82787588999402,
          21.002669661317224,
          105.82790539428817,
          21.002883754279807
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82787588999402,
            21.002883754279807
          ],
          [
            105.82790539428817,
            21.002669661317224
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 22 Tôn Thất Tùng",
        "diemDau": "",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002883754279807,
        "to_latitude": 21.002669661317224,
        "to_longitude": 105.82790539428817,
        "from_longitude": 105.82787588999402,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2822,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82800061270792,
          21.002855271171747,
          105.82836304618401,
          21.002878746260684
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82800061270792,
            21.002878746260684
          ],
          [
            105.82836304618401,
            21.002855271171747
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002878746260684,
        "to_latitude": 21.002855271171747,
        "to_longitude": 105.82836304618401,
        "from_longitude": 105.82800061270792,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2823,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81281428057336,
          21.01295547936863,
          105.81295777872349,
          21.013051251233993
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81295777872349,
            21.01295547936863
          ],
          [
            105.81281428057336,
            21.013051251233993
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "diemDau": "02",
        "diemCuoi": "01",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.01295547936863,
        "to_latitude": 21.013051251233993,
        "to_longitude": 105.81281428057336,
        "from_longitude": 105.81295777872349,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2824,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81295777872349,
          21.012798989260432,
          105.81324879840726,
          21.01295547936863
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81295777872349,
            21.01295547936863
          ],
          [
            105.81324879840726,
            21.012798989260432
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.01295547936863,
        "to_latitude": 21.012798989260432,
        "to_longitude": 105.81324879840726,
        "from_longitude": 105.81295777872349,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2825,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81324879840726,
          21.01259836870011,
          105.81359983250492,
          21.012798989260432
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81324879840726,
            21.012798989260432
          ],
          [
            105.81359983250492,
            21.01259836870011
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.012798989260432,
        "to_latitude": 21.01259836870011,
        "to_longitude": 105.81359983250492,
        "from_longitude": 105.81324879840726,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2826,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81359983250492,
          21.012446885914546,
          105.81387207672549,
          21.01259836870011
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81359983250492,
            21.01259836870011
          ],
          [
            105.81387207672549,
            21.012446885914546
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.01259836870011,
        "to_latitude": 21.012446885914546,
        "to_longitude": 105.81387207672549,
        "from_longitude": 105.81359983250492,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2827,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81387207672549,
          21.012340472296664,
          105.81405580803667,
          21.012446885914546
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81387207672549,
            21.012446885914546
          ],
          [
            105.81405580803667,
            21.012340472296664
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.012446885914546,
        "to_latitude": 21.012340472296664,
        "to_longitude": 105.81405580803667,
        "from_longitude": 105.81387207672549,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2828,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81405580803667,
          21.01228789142148,
          105.81421674058201,
          21.012340472296664
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81405580803667,
            21.012340472296664
          ],
          [
            105.81413158044579,
            21.01229853279052
          ],
          [
            105.81421674058201,
            21.01228789142148
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.012340472296664,
        "to_latitude": 21.01228789142148,
        "to_longitude": 105.81421674058201,
        "from_longitude": 105.81405580803667,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2829,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81367359325375,
          21.01213766024794,
          105.81387207672549,
          21.012446885914546
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81387207672549,
            21.012446885914546
          ],
          [
            105.81367359325375,
            21.01213766024794
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "diemDau": "05",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.012446885914546,
        "to_latitude": 21.01213766024794,
        "to_longitude": 105.81367359325375,
        "from_longitude": 105.81387207672549,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2830,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81349254415932,
          21.01236551080193,
          105.81359983250492,
          21.01259836870011
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81359983250492,
            21.01259836870011
          ],
          [
            105.81356563434274,
            21.01247630613808
          ],
          [
            105.81349254415932,
            21.01236551080193
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "diemDau": "04",
        "diemCuoi": "9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.01259836870011,
        "to_latitude": 21.01236551080193,
        "to_longitude": 105.81349254415932,
        "from_longitude": 105.81359983250492,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2831,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81329271958371,
          21.012230302823326,
          105.81349254415932,
          21.01236551080193
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81349254415932,
            21.01236551080193
          ],
          [
            105.81329271958371,
            21.012230302823326
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "diemDau": "9-1",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.01236551080193,
        "to_latitude": 21.012230302823326,
        "to_longitude": 105.81329271958371,
        "from_longitude": 105.81349254415932,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2832,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8129064814802,
          21.01238303775326,
          105.81324879840726,
          21.012798989260432
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81324879840726,
            21.012798989260432
          ],
          [
            105.81315793856832,
            21.012674110036038
          ],
          [
            105.8129064814802,
            21.01238303775326
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 51,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "diemDau": "03",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.012798989260432,
        "to_latitude": 21.01238303775326,
        "to_longitude": 105.8129064814802,
        "from_longitude": 105.81324879840726,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2833,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8129064814802,
          21.01216895842182,
          105.81305132077014,
          21.01238366371549
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8129064814802,
            21.01238303775326
          ],
          [
            105.81295945510558,
            21.01238366371549
          ],
          [
            105.81304528578926,
            21.012281005829248
          ],
          [
            105.81305132077014,
            21.01216895842182
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.01238303775326,
        "to_latitude": 21.01216895842182,
        "to_longitude": 105.81305132077014,
        "from_longitude": 105.8129064814802,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2834,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81288616751911,
          21.012014787064974,
          105.81305132077014,
          21.01216895842182
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81305132077014,
            21.01216895842182
          ],
          [
            105.81288616751911,
            21.012014787064974
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "diemDau": "10-1",
        "diemCuoi": "10-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.01216895842182,
        "to_latitude": 21.012014787064974,
        "to_longitude": 105.81288616751911,
        "from_longitude": 105.81305132077014,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2835,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81262484954154,
          21.0120800715918,
          105.8129064814802,
          21.01238303775326
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8129064814802,
            21.01238303775326
          ],
          [
            105.81262484954154,
            21.0120800715918
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.01238303775326,
        "to_latitude": 21.0120800715918,
        "to_longitude": 105.81262484954154,
        "from_longitude": 105.8129064814802,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2836,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81262484954154,
          21.01195112299735,
          105.81277102992638,
          21.0120800715918
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81262484954154,
            21.0120800715918
          ],
          [
            105.81277102992638,
            21.01195112299735
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.0120800715918,
        "to_latitude": 21.01195112299735,
        "to_longitude": 105.81277102992638,
        "from_longitude": 105.81262484954154,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2837,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81276633605833,
          21.011837197447782,
          105.81285015509069,
          21.01195112299735
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81277102992638,
            21.01195112299735
          ],
          [
            105.81276633605833,
            21.0119104353101
          ],
          [
            105.81285015509069,
            21.011837197447782
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.01195112299735,
        "to_latitude": 21.011837197447782,
        "to_longitude": 105.81285015509069,
        "from_longitude": 105.81277102992638,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2838,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81285015509069,
          21.011837197447782,
          105.81318409011627,
          21.01203625412058
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81285015509069,
            21.011837197447782
          ],
          [
            105.81318409011627,
            21.01203625412058
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "diemDau": "13",
        "diemCuoi": "13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.011837197447782,
        "to_latitude": 21.01203625412058,
        "to_longitude": 105.81318409011627,
        "from_longitude": 105.81285015509069,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2839,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81280254589879,
          21.012737958071035,
          105.81295777872349,
          21.01295547936863
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81295777872349,
            21.01295547936863
          ],
          [
            105.81280254589879,
            21.012737958071035
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "diemDau": "02",
        "diemCuoi": "2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.01295547936863,
        "to_latitude": 21.012737958071035,
        "to_longitude": 105.81280254589879,
        "from_longitude": 105.81295777872349,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2840,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8127086685829,
          21.012605254276025,
          105.81280254589879,
          21.012737958071035
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81280254589879,
            21.012737958071035
          ],
          [
            105.8127086685829,
            21.012605254276025
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "diemDau": "2-1",
        "diemCuoi": "2-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.012737958071035,
        "to_latitude": 21.012605254276025,
        "to_longitude": 105.8127086685829,
        "from_longitude": 105.81280254589879,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2841,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82836304618401,
          21.002795174921296,
          105.8287895174216,
          21.002855271171747
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82836304618401,
            21.002855271171747
          ],
          [
            105.8287895174216,
            21.002795174921296
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002855271171747,
        "to_latitude": 21.002795174921296,
        "to_longitude": 105.8287895174216,
        "from_longitude": 105.82836304618401,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2842,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8287895174216,
          21.0027526067293,
          105.8290389628584,
          21.002795174921296
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8287895174216,
            21.002795174921296
          ],
          [
            105.8290389628584,
            21.0027526067293
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002795174921296,
        "to_latitude": 21.0027526067293,
        "to_longitude": 105.8290389628584,
        "from_longitude": 105.8287895174216,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2843,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8290389628584,
          21.002707534513178,
          105.8293152303816,
          21.0027526067293
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8290389628584,
            21.0027526067293
          ],
          [
            105.8293152303816,
            21.002707534513178
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.0027526067293,
        "to_latitude": 21.002707534513178,
        "to_longitude": 105.8293152303816,
        "from_longitude": 105.8290389628584,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2844,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8293152303816,
          21.00260987799658,
          105.82938496781881,
          21.002707534513178
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8293152303816,
            21.002707534513178
          ],
          [
            105.82938496781881,
            21.00260987799658
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Sân D22 Tôn Thất Tùng",
        "diemDau": "10",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002707534513178,
        "to_latitude": 21.00260987799658,
        "to_longitude": 105.82938496781881,
        "from_longitude": 105.8293152303816,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2845,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82938496781881,
          21.002584837854123,
          105.82953517152423,
          21.00260987799658
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82938496781881,
            21.00260987799658
          ],
          [
            105.82953517152423,
            21.002584837854123
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Sân D22 Tôn Thất Tùng",
        "diemDau": "14",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00260987799658,
        "to_latitude": 21.002584837854123,
        "to_longitude": 105.82953517152423,
        "from_longitude": 105.82938496781881,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2846,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82917575551613,
          21.00260987799658,
          105.82938496781881,
          21.002642430176135
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82938496781881,
            21.00260987799658
          ],
          [
            105.82917575551613,
            21.002642430176135
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Sân D22 Tôn Thất Tùng",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00260987799658,
        "to_latitude": 21.002642430176135,
        "to_longitude": 105.82917575551613,
        "from_longitude": 105.82938496781881,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2847,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82900409413979,
          21.002522237479628,
          105.8290389628584,
          21.0027526067293
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8290389628584,
            21.0027526067293
          ],
          [
            105.82900409413979,
            21.002522237479628
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "diemDau": "09",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.0027526067293,
        "to_latitude": 21.002522237479628,
        "to_longitude": 105.82900409413979,
        "from_longitude": 105.8290389628584,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2848,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82900409413979,
          21.002459637078015,
          105.82930718375836,
          21.002522237479628
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82900409413979,
            21.002522237479628
          ],
          [
            105.82930718375836,
            21.002459637078015
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002522237479628,
        "to_latitude": 21.002459637078015,
        "to_longitude": 105.82930718375836,
        "from_longitude": 105.82900409413979,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2849,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8125380130412,
          21.012665659556987,
          105.81295777872349,
          21.01297957883234
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81295777872349,
            21.01295547936863
          ],
          [
            105.81273951395394,
            21.01297957883234
          ],
          [
            105.8125380130412,
            21.012665659556987
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "02",
        "diemCuoi": "42",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.01295547936863,
        "to_latitude": 21.012665659556987,
        "to_longitude": 105.8125380130412,
        "from_longitude": 105.81295777872349,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2850,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81237439829707,
          21.012665659556987,
          105.8125380130412,
          21.012863463204667
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8125380130412,
            21.012665659556987
          ],
          [
            105.81237439829707,
            21.012863463204667
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "42",
        "diemCuoi": "31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.012665659556987,
        "to_latitude": 21.012863463204667,
        "to_longitude": 105.81237439829707,
        "from_longitude": 105.8125380130412,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2851,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81236366944815,
          21.012427794064386,
          105.8125380130412,
          21.012665659556987
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8125380130412,
            21.012665659556987
          ],
          [
            105.81236366944815,
            21.012427794064386
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "42",
        "diemCuoi": "41",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.012665659556987,
        "to_latitude": 21.012427794064386,
        "to_longitude": 105.81236366944815,
        "from_longitude": 105.8125380130412,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2852,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81212528811155,
          21.012120759229408,
          105.81236366944815,
          21.012427794064386
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81236366944815,
            21.012427794064386
          ],
          [
            105.81212528811155,
            21.012120759229408
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "41",
        "diemCuoi": "40",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.012427794064386,
        "to_latitude": 21.012120759229408,
        "to_longitude": 105.81212528811155,
        "from_longitude": 105.81236366944815,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2853,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81189428287769,
          21.01181184586862,
          105.81212528811155,
          21.012120759229408
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81212528811155,
            21.012120759229408
          ],
          [
            105.81189428287769,
            21.01181184586862
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "40",
        "diemCuoi": "39",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.012120759229408,
        "to_latitude": 21.01181184586862,
        "to_longitude": 105.81189428287769,
        "from_longitude": 105.81212528811155,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2854,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81217859704205,
          21.01259054417978,
          105.81237439829707,
          21.012863463204667
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81237439829707,
            21.012863463204667
          ],
          [
            105.81217859704205,
            21.01259054417978
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.012863463204667,
        "to_latitude": 21.01259054417978,
        "to_longitude": 105.81217859704205,
        "from_longitude": 105.81237439829707,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2855,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81195329148396,
          21.012297593845425,
          105.81217859704205,
          21.01259054417978
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81217859704205,
            21.01259054417978
          ],
          [
            105.81195329148396,
            21.012297593845425
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.01259054417978,
        "to_latitude": 21.012297593845425,
        "to_longitude": 105.81195329148396,
        "from_longitude": 105.81217859704205,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2856,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81173066813356,
          21.011994627510386,
          105.81195329148396,
          21.012297593845425
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81195329148396,
            21.012297593845425
          ],
          [
            105.81173066813356,
            21.011994627510386
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "33",
        "diemCuoi": "34",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.012297593845425,
        "to_latitude": 21.011994627510386,
        "to_longitude": 105.81173066813356,
        "from_longitude": 105.81195329148396,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2857,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81162941473292,
          21.01145692334394,
          105.81189428287769,
          21.01181184586862
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81189428287769,
            21.01181184586862
          ],
          [
            105.81162941473292,
            21.01145692334394
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "39",
        "diemCuoi": "38",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.01181184586862,
        "to_latitude": 21.01145692334394,
        "to_longitude": 105.81162941473292,
        "from_longitude": 105.81189428287769,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2858,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81142422574185,
          21.01115520723621,
          105.81162941473292,
          21.01145692334394
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81162941473292,
            21.01145692334394
          ],
          [
            105.81142422574185,
            21.01115520723621
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "38",
        "diemCuoi": "37",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.01145692334394,
        "to_latitude": 21.01115520723621,
        "to_longitude": 105.81142422574185,
        "from_longitude": 105.81162941473292,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2859,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81125859934639,
          21.011352386928706,
          105.81152313219499,
          21.011703866882996
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81152313219499,
            21.011703866882996
          ],
          [
            105.81125859934639,
            21.011352386928706
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "35",
        "diemCuoi": "36",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.011703866882996,
        "to_latitude": 21.011352386928706,
        "to_longitude": 105.81125859934639,
        "from_longitude": 105.81152313219499,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2860,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81152313219499,
          21.011703866882996,
          105.81173066813356,
          21.011994627510386
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81173066813356,
            21.011994627510386
          ],
          [
            105.81152313219499,
            21.011703866882996
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.011994627510386,
        "to_latitude": 21.011703866882996,
        "to_longitude": 105.81152313219499,
        "from_longitude": 105.81173066813356,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2861,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81256751734433,
          21.012846875252286,
          105.81281428057336,
          21.013051251233993
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81281428057336,
            21.013051251233993
          ],
          [
            105.81268586977217,
            21.012846875252286
          ],
          [
            105.81256751734433,
            21.01294608996616
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "01",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.013051251233993,
        "to_latitude": 21.01294608996616,
        "to_longitude": 105.81256751734433,
        "from_longitude": 105.81281428057336,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2862,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81256751734433,
          21.01294608996616,
          105.8127552719761,
          21.01322401601173
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81256751734433,
            21.01294608996616
          ],
          [
            105.8127552719761,
            21.01322401601173
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "30",
        "diemCuoi": "29",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.01294608996616,
        "to_latitude": 21.01322401601173,
        "to_longitude": 105.8127552719761,
        "from_longitude": 105.81256751734433,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2863,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8127552719761,
          21.01322401601173,
          105.81302262085161,
          21.013641477227075
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8127552719761,
            21.01322401601173
          ],
          [
            105.81302262085161,
            21.013641477227075
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 58,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "29",
        "diemCuoi": "28",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.01322401601173,
        "to_latitude": 21.013641477227075,
        "to_longitude": 105.81302262085161,
        "from_longitude": 105.8127552719761,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2864,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81302262085161,
          21.013641477227075,
          105.81320588309235,
          21.013947622838838
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81302262085161,
            21.013641477227075
          ],
          [
            105.81320588309235,
            21.013947622838838
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "28",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.013641477227075,
        "to_latitude": 21.013947622838838,
        "to_longitude": 105.81320588309235,
        "from_longitude": 105.81302262085161,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2865,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81320588309235,
          21.013947622838838,
          105.8134124131873,
          21.014260600482544
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81320588309235,
            21.013947622838838
          ],
          [
            105.8134124131873,
            21.014260600482544
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "27",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.013947622838838,
        "to_latitude": 21.014260600482544,
        "to_longitude": 105.8134124131873,
        "from_longitude": 105.81320588309235,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2866,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8134124131873,
          21.014260600482544,
          105.81354920584504,
          21.014475928718827
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8134124131873,
            21.014260600482544
          ],
          [
            105.81354920584504,
            21.014475928718827
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "26",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.014260600482544,
        "to_latitude": 21.014475928718827,
        "to_longitude": 105.81354920584504,
        "from_longitude": 105.8134124131873,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2867,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81354920584504,
          21.014475928718827,
          105.81369940955045,
          21.014713790944345
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81354920584504,
            21.014475928718827
          ],
          [
            105.81369940955045,
            21.014713790944345
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "25",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.014475928718827,
        "to_latitude": 21.014713790944345,
        "to_longitude": 105.81369940955045,
        "from_longitude": 105.81354920584504,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2868,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81369940955045,
          21.014713790944345,
          105.81388045865387,
          21.014977942764233
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81369940955045,
            21.014713790944345
          ],
          [
            105.81388045865387,
            21.014977942764233
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "24",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.014713790944345,
        "to_latitude": 21.014977942764233,
        "to_longitude": 105.81388045865387,
        "from_longitude": 105.81369940955045,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2869,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81388045865387,
          21.014977942764233,
          105.81419427710343,
          21.015248353618297
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81388045865387,
            21.014977942764233
          ],
          [
            105.81404574973517,
            21.015027705907382
          ],
          [
            105.81419427710343,
            21.015248353618297
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "23",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.014977942764233,
        "to_latitude": 21.015248353618297,
        "to_longitude": 105.81419427710343,
        "from_longitude": 105.81388045865387,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2870,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81384726627242,
          21.014977942764233,
          105.81400652247166,
          21.015406093057376
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81388045865387,
            21.014977942764233
          ],
          [
            105.81384726627242,
            21.01515289613691
          ],
          [
            105.81400652247166,
            21.015406093057376
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "23",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.014977942764233,
        "to_latitude": 21.015406093057376,
        "to_longitude": 105.81400652247166,
        "from_longitude": 105.81388045865387,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2871,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81419427710343,
          21.015248353618297,
          105.81439276057515,
          21.01555632093807
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81419427710343,
            21.015248353618297
          ],
          [
            105.81439276057515,
            21.01555632093807
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "22",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.015248353618297,
        "to_latitude": 21.01555632093807,
        "to_longitude": 105.81439276057515,
        "from_longitude": 105.81419427710343,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2872,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81439276057515,
          21.01555632093807,
          105.81460733729332,
          21.01586929520512
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81439276057515,
            21.01555632093807
          ],
          [
            105.81460733729332,
            21.01586929520512
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "21",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.01555632093807,
        "to_latitude": 21.01586929520512,
        "to_longitude": 105.81460733729332,
        "from_longitude": 105.81439276057515,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2873,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81400652247166,
          21.015406093057376,
          105.81420500594336,
          21.015716563845995
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81400652247166,
            21.015406093057376
          ],
          [
            105.81420500594336,
            21.015716563845995
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "18",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.015406093057376,
        "to_latitude": 21.015716563845995,
        "to_longitude": 105.81420500594336,
        "from_longitude": 105.81400652247166,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2874,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81420500594336,
          21.015716563845995,
          105.81440617161385,
          21.016034545353637
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81420500594336,
            21.015716563845995
          ],
          [
            105.81440617161385,
            21.016034545353637
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "17",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.015716563845995,
        "to_latitude": 21.016034545353637,
        "to_longitude": 105.81440617161385,
        "from_longitude": 105.81420500594336,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2875,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81460733729332,
          21.01586929520512,
          105.81482727843596,
          21.01619228396035
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81460733729332,
            21.01586929520512
          ],
          [
            105.81482727843596,
            21.01619228396035
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "20",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.01586929520512,
        "to_latitude": 21.01619228396035,
        "to_longitude": 105.81482727843596,
        "from_longitude": 105.81460733729332,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2876,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81440617161385,
          21.016034545353637,
          105.81460733729332,
          21.016337503483424
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81440617161385,
            21.016034545353637
          ],
          [
            105.81460733729332,
            21.016337503483424
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "16",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.016034545353637,
        "to_latitude": 21.016337503483424,
        "to_longitude": 105.81460733729332,
        "from_longitude": 105.81440617161385,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2877,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81460733729332,
          21.016337503483424,
          105.81465561705963,
          21.01647771530244
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81460733729332,
            21.016337503483424
          ],
          [
            105.81465561705963,
            21.01647771530244
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Láng Hạ",
        "diemDau": "15",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.016337503483424,
        "to_latitude": 21.01647771530244,
        "to_longitude": 105.81465561705963,
        "from_longitude": 105.81460733729332,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2878,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81377585249804,
          21.015946599746908,
          105.81440617161385,
          21.016254878601565
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81440617161385,
            21.016034545353637
          ],
          [
            105.81430860621066,
            21.015946599746908
          ],
          [
            105.81377585249804,
            21.016254878601565
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 55,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 22 Láng Hã",
        "diemDau": "16",
        "diemCuoi": "16-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.016034545353637,
        "to_latitude": 21.016254878601565,
        "to_longitude": 105.81377585249804,
        "from_longitude": 105.81440617161385,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2879,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8136792929744,
          21.016107155211735,
          105.81377585249804,
          21.016254878601565
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81377585249804,
            21.016254878601565
          ],
          [
            105.8136792929744,
            21.016107155211735
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 22 Láng Hã",
        "diemDau": "16-5",
        "diemCuoi": "16-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.016254878601565,
        "to_latitude": 21.016107155211735,
        "to_longitude": 105.8136792929744,
        "from_longitude": 105.81377585249804,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2880,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8135827334508,
          21.015956927885092,
          105.8136792929744,
          21.016107155211735
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8136792929744,
            21.016107155211735
          ],
          [
            105.8135827334508,
            21.015956927885092
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 22 Láng Hã",
        "diemDau": "16-6",
        "diemCuoi": "16-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.016107155211735,
        "to_latitude": 21.015956927885092,
        "to_longitude": 105.8135827334508,
        "from_longitude": 105.8136792929744,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2881,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81459023814932,
          21.015623923437666,
          105.81483800726693,
          21.01586929520512
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81460733729332,
            21.01586929520512
          ],
          [
            105.81459023814932,
            21.015796372258528
          ],
          [
            105.81483800726693,
            21.015623923437666
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 93 Láng Hã",
        "diemDau": "20",
        "diemCuoi": "20-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.01586929520512,
        "to_latitude": 21.015623923437666,
        "to_longitude": 105.81483800726693,
        "from_longitude": 105.81460733729332,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2882,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81483800726693,
          21.015393574062163,
          105.81501134497144,
          21.015623923437666
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81483800726693,
            21.015623923437666
          ],
          [
            105.81501134497144,
            21.015478390242226
          ],
          [
            105.81495334225376,
            21.015393574062163
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 93 Láng Hã",
        "diemDau": "20-1",
        "diemCuoi": "20-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.015623923437666,
        "to_latitude": 21.015393574062163,
        "to_longitude": 105.81495334225376,
        "from_longitude": 105.81483800726693,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2883,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8148584590583,
          21.01522081179663,
          105.81495334225376,
          21.015393574062163
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81495334225376,
            21.015393574062163
          ],
          [
            105.8148584590583,
            21.015253048245043
          ],
          [
            105.8149104269119,
            21.01522081179663
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 93 Láng Hã",
        "diemDau": "20-2",
        "diemCuoi": "20-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.015393574062163,
        "to_latitude": 21.01522081179663,
        "to_longitude": 105.8149104269119,
        "from_longitude": 105.81495334225376,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2884,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81474949436652,
          21.015000477022678,
          105.8149104269119,
          21.01522081179663
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8149104269119,
            21.01522081179663
          ],
          [
            105.81474949436652,
            21.015000477022678
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 93 Láng Hã",
        "diemDau": "20-3",
        "diemCuoi": "20-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.01522081179663,
        "to_latitude": 21.015000477022678,
        "to_longitude": 105.81474949436652,
        "from_longitude": 105.8149104269119,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2885,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81151139753837,
          21.011703866882996,
          105.81154056649142,
          21.011796196744207
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81152313219499,
            21.011703866882996
          ],
          [
            105.81154056649142,
            21.011777730780548
          ],
          [
            105.81151139753837,
            21.011796196744207
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 90 Phố Láng Hạ",
        "diemDau": "35",
        "diemCuoi": "35-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.011703866882996,
        "to_latitude": 21.011796196744207,
        "to_longitude": 105.81151139753837,
        "from_longitude": 105.81152313219499,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2886,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8112834097725,
          21.011796196744207,
          105.81151139753837,
          21.011946428261627
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81151139753837,
            21.011796196744207
          ],
          [
            105.8112834097725,
            21.011946428261627
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 90 Phố Láng Hạ",
        "diemDau": "35-1",
        "diemCuoi": "35-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.011796196744207,
        "to_latitude": 21.011946428261627,
        "to_longitude": 105.8112834097725,
        "from_longitude": 105.81151139753837,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2887,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81110638397168,
          21.011946428261627,
          105.8112834097725,
          21.01201904010744
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8112834097725,
            21.011946428261627
          ],
          [
            105.81110638397168,
            21.01201904010744
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 90 Phố Láng Hạ",
        "diemDau": "35-2",
        "diemCuoi": "35-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.011946428261627,
        "to_latitude": 21.01201904010744,
        "to_longitude": 105.81110638397168,
        "from_longitude": 105.8112834097725,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2888,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81206627946038,
          21.01184283112821,
          105.81225939856154,
          21.012120759229408
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81212528811155,
            21.012120759229408
          ],
          [
            105.81206627946038,
            21.011968024034267
          ],
          [
            105.81225939856154,
            21.01184283112821
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 113 Phố Láng Hạ",
        "diemDau": "40",
        "diemCuoi": "40-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.012120759229408,
        "to_latitude": 21.01184283112821,
        "to_longitude": 105.81225939856154,
        "from_longitude": 105.81212528811155,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2889,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81225939856154,
          21.01184283112821,
          105.81240960226694,
          21.012065674421763
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81225939856154,
            21.01184283112821
          ],
          [
            105.81240960226694,
            21.012065674421763
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 113 Phố Láng Hạ",
        "diemDau": "40-1",
        "diemCuoi": "40-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.01184283112821,
        "to_latitude": 21.012065674421763,
        "to_longitude": 105.81240960226694,
        "from_longitude": 105.81225939856154,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2890,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81240960226694,
          21.012060666714163,
          105.81257321702006,
          21.012225921079104
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81240960226694,
            21.012065674421763
          ],
          [
            105.81247129302704,
            21.012060666714163
          ],
          [
            105.81255444150301,
            21.01215831704437
          ],
          [
            105.81257321702006,
            21.012225921079104
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 113 Phố Láng Hạ",
        "diemDau": "40-2",
        "diemCuoi": "40-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.012065674421763,
        "to_latitude": 21.012225921079104,
        "to_longitude": 105.81257321702006,
        "from_longitude": 105.81240960226694,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2891,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81257321702006,
          21.012225921079104,
          105.81272342067157,
          21.01247630613558
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81257321702006,
            21.012225921079104
          ],
          [
            105.81272342067157,
            21.012461283047767
          ],
          [
            105.8126992808468,
            21.01247630613558
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CBĐ",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 113 Phố Láng Hạ",
        "diemDau": "40-3",
        "diemCuoi": "40-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cục Bản Đồ",
        "from_latitude": 21.012225921079104,
        "to_latitude": 21.01247630613558,
        "to_longitude": 105.8126992808468,
        "from_longitude": 105.81257321702006,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2892,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82874123765531,
          21.00256480573731,
          105.8287895174216,
          21.002795174921296
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8287895174216,
            21.002795174921296
          ],
          [
            105.82874123765531,
            21.00256480573731
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 22 Tôn Thất Tùng",
        "diemDau": "08",
        "diemCuoi": "8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002795174921296,
        "to_latitude": 21.00256480573731,
        "to_longitude": 105.82874123765531,
        "from_longitude": 105.8287895174216,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2893,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82836304618401,
          21.002855271171747,
          105.82843814803672,
          21.003075623883333
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82836304618401,
            21.002855271171747
          ],
          [
            105.82843814803672,
            21.003075623883333
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 22 Tôn Thất Tùng",
        "diemDau": "07",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002855271171747,
        "to_latitude": 21.003075623883333,
        "to_longitude": 105.82843814803672,
        "from_longitude": 105.82836304618401,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2894,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82799826576043,
          21.002878746260684,
          105.82800061270792,
          21.00311819198317
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82800061270792,
            21.002878746260684
          ],
          [
            105.82799826576043,
            21.00311819198317
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "diemDau": "06",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002878746260684,
        "to_latitude": 21.00311819198317,
        "to_longitude": 105.82799826576043,
        "from_longitude": 105.82800061270792,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2895,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82764153196004,
          21.00311819198317,
          105.82799826576043,
          21.00313321601596
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82799826576043,
            21.00311819198317
          ],
          [
            105.82764153196004,
            21.00313321601596
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "diemDau": "6-1",
        "diemCuoi": "6-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00311819198317,
        "to_latitude": 21.00313321601596,
        "to_longitude": 105.82764153196004,
        "from_longitude": 105.82799826576043,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2896,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82764153196004,
          21.00313321601596,
          105.82768008871207,
          21.003440269345877
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82764153196004,
            21.00313321601596
          ],
          [
            105.82768008871207,
            21.003440269345877
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "diemDau": "6-2",
        "diemCuoi": "6-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00313321601596,
        "to_latitude": 21.003440269345877,
        "to_longitude": 105.82768008871207,
        "from_longitude": 105.82764153196004,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2897,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82799826576043,
          21.00311819198317,
          105.82803279920081,
          21.00330380127732
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82799826576043,
            21.00311819198317
          ],
          [
            105.82803279920081,
            21.00330380127732
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "diemDau": "6-1",
        "diemCuoi": "6-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00311819198317,
        "to_latitude": 21.00330380127732,
        "to_longitude": 105.82803279920081,
        "from_longitude": 105.82799826576043,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2898,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82803279920081,
          21.00330380127732,
          105.82807303233491,
          21.00351789332932
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82803279920081,
            21.00330380127732
          ],
          [
            105.82807303233491,
            21.00351789332932
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "diemDau": "6-4",
        "diemCuoi": "6-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00330380127732,
        "to_latitude": 21.00351789332932,
        "to_longitude": 105.82807303233491,
        "from_longitude": 105.82803279920081,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2899,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82807303233491,
          21.00351789332932,
          105.82832113666785,
          21.003526657324958
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82807303233491,
            21.00351789332932
          ],
          [
            105.82832113666785,
            21.003526657324958
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "diemDau": "6-5",
        "diemCuoi": "6-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00351789332932,
        "to_latitude": 21.003526657324958,
        "to_longitude": 105.82832113666785,
        "from_longitude": 105.82807303233491,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2900,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82832113666785,
          21.00351413733118,
          105.82863629623023,
          21.003526657324958
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82832113666785,
            21.003526657324958
          ],
          [
            105.82863629623023,
            21.00351413733118
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "diemDau": "6-6",
        "diemCuoi": "6-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.003526657324958,
        "to_latitude": 21.00351413733118,
        "to_longitude": 105.82863629623023,
        "from_longitude": 105.82832113666785,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2901,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82863629623023,
          21.003486593340654,
          105.82890719933796,
          21.00351413733118
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82863629623023,
            21.00351413733118
          ],
          [
            105.82890719933796,
            21.003486593340654
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "diemDau": "6-7",
        "diemCuoi": "6-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00351413733118,
        "to_latitude": 21.003486593340654,
        "to_longitude": 105.82890719933796,
        "from_longitude": 105.82863629623023,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2902,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82890719933796,
          21.003486593340654,
          105.82895413800038,
          21.003730733077187
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82890719933796,
            21.003486593340654
          ],
          [
            105.82895413800038,
            21.003730733077187
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "diemDau": "6-8",
        "diemCuoi": "6-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.003486593340654,
        "to_latitude": 21.003730733077187,
        "to_longitude": 105.82895413800038,
        "from_longitude": 105.82890719933796,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2903,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82895413800038,
          21.003719465097678,
          105.82907349629879,
          21.003830892852722
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82895413800038,
            21.003730733077187
          ],
          [
            105.82904667421239,
            21.003719465097678
          ],
          [
            105.82907349629879,
            21.003830892852722
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "diemDau": "6-9",
        "diemCuoi": "6-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.003730733077187,
        "to_latitude": 21.003830892852722,
        "to_longitude": 105.82907349629879,
        "from_longitude": 105.82895413800038,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2904,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82904399199568,
          21.003830892852722,
          105.82907349629879,
          21.003957344474365
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82907349629879,
            21.003830892852722
          ],
          [
            105.82904399199568,
            21.003844664816828
          ],
          [
            105.8290600852511,
            21.003957344474365
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "diemDau": "6-10",
        "diemCuoi": "6-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.003830892852722,
        "to_latitude": 21.003957344474365,
        "to_longitude": 105.8290600852511,
        "from_longitude": 105.82907349629879,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2905,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8290600852511,
          21.003957344474365,
          105.82916603251083,
          21.004127615794868
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8290600852511,
            21.003957344474365
          ],
          [
            105.8291271404806,
            21.00396360445242
          ],
          [
            105.82916603251083,
            21.004127615794868
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "diemDau": "6-11",
        "diemCuoi": "6-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.003957344474365,
        "to_latitude": 21.004127615794868,
        "to_longitude": 105.82916603251083,
        "from_longitude": 105.8290600852511,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2906,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82807303233491,
          21.00351789332932,
          105.82808778448648,
          21.00373448906991
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82807303233491,
            21.00351789332932
          ],
          [
            105.82808778448648,
            21.00373448906991
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "diemDau": "6-5",
        "diemCuoi": "6-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00351789332932,
        "to_latitude": 21.00373448906991,
        "to_longitude": 105.82808778448648,
        "from_longitude": 105.82807303233491,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2907,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82808778448648,
          21.00373448906991,
          105.82818434401011,
          21.00373448906991
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82808778448648,
            21.00373448906991
          ],
          [
            105.82818434401011,
            21.00373448906991
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "diemDau": "6-13",
        "diemCuoi": "6-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00373448906991,
        "to_latitude": 21.00373448906991,
        "to_longitude": 105.82818434401011,
        "from_longitude": 105.82808778448648,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2908,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82818434401011,
          21.00373448906991,
          105.8281897084256,
          21.00387721672711
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82818434401011,
            21.00373448906991
          ],
          [
            105.8281897084256,
            21.00387721672711
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "diemDau": "6-14",
        "diemCuoi": "6-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00373448906991,
        "to_latitude": 21.00387721672711,
        "to_longitude": 105.8281897084256,
        "from_longitude": 105.82818434401011,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2909,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8281897084256,
          21.00387721672711,
          105.82838819189732,
          21.003889736690407
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8281897084256,
            21.00387721672711
          ],
          [
            105.82838819189732,
            21.003889736690407
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "diemDau": "6-15",
        "diemCuoi": "6-18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00387721672711,
        "to_latitude": 21.003889736690407,
        "to_longitude": 105.82838819189732,
        "from_longitude": 105.8281897084256,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2910,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82838819189732,
          21.00383715283686,
          105.82853303118725,
          21.003889736690407
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82838819189732,
            21.003889736690407
          ],
          [
            105.82853303118725,
            21.003887232697323
          ],
          [
            105.82853034897053,
            21.00383715283686
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "diemDau": "6-18",
        "diemCuoi": "6-20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.003889736690407,
        "to_latitude": 21.00383715283686,
        "to_longitude": 105.82853034897053,
        "from_longitude": 105.82838819189732,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2911,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82838819189732,
          21.003889736690407,
          105.82839892072829,
          21.00407002404683
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82838819189732,
            21.003889736690407
          ],
          [
            105.82839892072829,
            21.00407002404683
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "diemDau": "6-18",
        "diemCuoi": "6-19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.003889736690407,
        "to_latitude": 21.00407002404683,
        "to_longitude": 105.82839892072829,
        "from_longitude": 105.82838819189732,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2912,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82804889245627,
          21.00387721672711,
          105.8281897084256,
          21.00392729657493
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8281897084256,
            21.00387721672711
          ],
          [
            105.82816959186754,
            21.00392729657493
          ],
          [
            105.82804889245627,
            21.003912272622966
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "diemDau": "6-15",
        "diemCuoi": "6-16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00387721672711,
        "to_latitude": 21.003912272622966,
        "to_longitude": 105.82804889245627,
        "from_longitude": 105.8281897084256,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2913,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82804889245627,
          21.003912272622966,
          105.82804889245627,
          21.004080040004958
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82804889245627,
            21.003912272622966
          ],
          [
            105.82804889245627,
            21.004080040004958
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "diemDau": "6-16",
        "diemCuoi": "6-17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.003912272622966,
        "to_latitude": 21.004080040004958,
        "to_longitude": 105.82804889245627,
        "from_longitude": 105.82804889245627,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2914,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82715169353537,
          21.002823658040818,
          105.8272053377441,
          21.003052148825365
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8272053377441,
            21.002823658040818
          ],
          [
            105.82715169353537,
            21.003052148825365
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 22 Tôn Thất Tùng",
        "diemDau": "03",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002823658040818,
        "to_latitude": 21.003052148825365,
        "to_longitude": 105.82715169353537,
        "from_longitude": 105.8272053377441,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2915,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82747892305956,
          21.002886258288893,
          105.82748428745707,
          21.003217413170887
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82747892305956,
            21.002886258288893
          ],
          [
            105.82748428745707,
            21.003217413170887
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 49,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/137 Trường Chinh",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002886258288893,
        "to_latitude": 21.003217413170887,
        "to_longitude": 105.82748428745707,
        "from_longitude": 105.82747892305956,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2916,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82748428745707,
          21.003217413170887,
          105.82749903960865,
          21.00349660933962
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82748428745707,
            21.003217413170887
          ],
          [
            105.82749903960865,
            21.00349660933962
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/137 Trường Chinh",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.003217413170887,
        "to_latitude": 21.00349660933962,
        "to_longitude": 105.82749903960865,
        "from_longitude": 105.82748428745707,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2917,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82712084813734,
          21.00349660933962,
          105.82749903960865,
          21.003506625336236
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82749903960865,
            21.00349660933962
          ],
          [
            105.82712084813734,
            21.003506625336236
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/137 Trường Chinh",
        "diemDau": "4-2",
        "diemCuoi": "4-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00349660933962,
        "to_latitude": 21.003506625336236,
        "to_longitude": 105.82712084813734,
        "from_longitude": 105.82749903960865,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2918,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82707022146846,
          21.003506625336236,
          105.82712084813734,
          21.003781751954442
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82712084813734,
            21.003506625336236
          ],
          [
            105.82707022146846,
            21.003781751954442
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/137 Trường Chinh",
        "diemDau": "4-3",
        "diemCuoi": "4-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.003506625336236,
        "to_latitude": 21.003781751954442,
        "to_longitude": 105.82707022146846,
        "from_longitude": 105.82712084813734,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2919,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82691197113085,
          21.003756712008634,
          105.82707022146846,
          21.003781751954442
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82707022146846,
            21.003781751954442
          ],
          [
            105.82691197113085,
            21.003756712008634
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/137 Trường Chinh",
        "diemDau": "4-4",
        "diemCuoi": "4-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.003781751954442,
        "to_latitude": 21.003756712008634,
        "to_longitude": 105.82691197113085,
        "from_longitude": 105.82707022146846,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2920,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82749669269707,
          21.00349660933962,
          105.82749903960865,
          21.003791767931947
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82749903960865,
            21.00349660933962
          ],
          [
            105.82749669269707,
            21.003791767931947
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/137 Trường Chinh",
        "diemDau": "4-2",
        "diemCuoi": "4-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00349660933962,
        "to_latitude": 21.003791767931947,
        "to_longitude": 105.82749669269707,
        "from_longitude": 105.82749903960865,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2921,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82749669269707,
          21.003791767931947,
          105.8275664301343,
          21.004034655173108
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82749669269707,
            21.003791767931947
          ],
          [
            105.8275664301343,
            21.00379677592041
          ],
          [
            105.82752887920795,
            21.004034655173108
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/137 Trường Chinh",
        "diemDau": "4-6",
        "diemCuoi": "4-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.003791767931947,
        "to_latitude": 21.004034655173108,
        "to_longitude": 105.82752887920795,
        "from_longitude": 105.82749669269707,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2922,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82734112457618,
          21.003741688039476,
          105.82749669269707,
          21.003791767931947
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82749669269707,
            21.003791767931947
          ],
          [
            105.82734112457618,
            21.003741688039476
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/137 Trường Chinh",
        "diemDau": "4-6",
        "diemCuoi": "4-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.003791767931947,
        "to_latitude": 21.003741688039476,
        "to_longitude": 105.82734112457618,
        "from_longitude": 105.82749669269707,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2923,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8272821159789,
          21.003741688039476,
          105.82734112457618,
          21.003929487550465
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82734112457618,
            21.003741688039476
          ],
          [
            105.8272821159789,
            21.003929487550465
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/137 Trường Chinh",
        "diemDau": "4-8",
        "diemCuoi": "4-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.003741688039476,
        "to_latitude": 21.003929487550465,
        "to_longitude": 105.8272821159789,
        "from_longitude": 105.82734112457618,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2924,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82712923006575,
          21.003929487550465,
          105.8272821159789,
          21.004282549991938
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8272821159789,
            21.003929487550465
          ],
          [
            105.82712923006575,
            21.004282549991938
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/137 Trường Chinh",
        "diemDau": "4-9",
        "diemCuoi": "4-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.003929487550465,
        "to_latitude": 21.004282549991938,
        "to_longitude": 105.82712923006575,
        "from_longitude": 105.8272821159789,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2925,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82582299427554,
          21.00460431184382,
          105.82602147774726,
          21.004644375528066
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82582299427554,
            21.004644375528066
          ],
          [
            105.82602147774726,
            21.00460431184382
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 123 Khương Thượng",
        "diemDau": "01",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004644375528066,
        "to_latitude": 21.00460431184382,
        "to_longitude": 105.82602147774726,
        "from_longitude": 105.82582299427554,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2926,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82602147774726,
          21.004516359501782,
          105.82610563205786,
          21.00460431184382
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82602147774726,
            21.00460431184382
          ],
          [
            105.82610563205786,
            21.004516359501782
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 123 Khương Thượng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.00460431184382,
        "to_latitude": 21.004516359501782,
        "to_longitude": 105.82610563205786,
        "from_longitude": 105.82602147774726,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2927,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82606405781988,
          21.004516359501782,
          105.82610563205786,
          21.00452637542995
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82610563205786,
            21.004516359501782
          ],
          [
            105.82606405781988,
            21.00452637542995
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 5,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 123 Khương Thượng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004516359501782,
        "to_latitude": 21.00452637542995,
        "to_longitude": 105.82606405781988,
        "from_longitude": 105.82610563205786,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2928,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82601711915744,
          21.004426216120407,
          105.82606405781988,
          21.00452637542995
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82606405781988,
            21.00452637542995
          ],
          [
            105.82601711915744,
            21.004426216120407
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 123 Khương Thượng",
        "diemDau": "",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.00452637542995,
        "to_latitude": 21.004426216120407,
        "to_longitude": 105.82601711915744,
        "from_longitude": 105.82606405781988,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2929,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82610563205786,
          21.004460019894708,
          105.8262719290187,
          21.004516359501782
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82610563205786,
            21.004516359501782
          ],
          [
            105.8262719290187,
            21.004460019894708
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 123 Khương Thượng",
        "diemDau": "",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004516359501782,
        "to_latitude": 21.004460019894708,
        "to_longitude": 105.8262719290187,
        "from_longitude": 105.82610563205786,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2930,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8262719290187,
          21.004396168314504,
          105.82641676829965,
          21.004460019894708
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8262719290187,
            21.004460019894708
          ],
          [
            105.82641676829965,
            21.004396168314504
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 123 Khương Thượng",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004460019894708,
        "to_latitude": 21.004396168314504,
        "to_longitude": 105.82641676829965,
        "from_longitude": 105.8262719290187,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2931,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82610563205786,
          21.004516359501782,
          105.82615927623964,
          21.004711669974224
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82610563205786,
            21.004516359501782
          ],
          [
            105.82615927623964,
            21.004711669974224
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 123 Khương Thượng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004516359501782,
        "to_latitude": 21.004711669974224,
        "to_longitude": 105.82615927623964,
        "from_longitude": 105.82610563205786,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2932,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82615927623964,
          21.004679118246834,
          105.82630277441672,
          21.004711669974224
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82615927623964,
            21.004711669974224
          ],
          [
            105.82630277441672,
            21.004679118246834
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 123 Khương Thượng",
        "diemDau": "",
        "diemCuoi": "1-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004711669974224,
        "to_latitude": 21.004679118246834,
        "to_longitude": 105.82630277441672,
        "from_longitude": 105.82615927623964,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2933,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81990355178303,
          21.01278415783879,
          105.81991475835564,
          21.012917921759414
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81991475835564,
            21.012917921759414
          ],
          [
            105.81990355178303,
            21.01278415783879
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.012917921759414,
        "to_latitude": 21.01278415783879,
        "to_longitude": 105.81990355178303,
        "from_longitude": 105.81991475835564,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2934,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8195984533065,
          21.01247223738661,
          105.81990355178303,
          21.01278415783879
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81990355178303,
            21.01278415783879
          ],
          [
            105.8195984533065,
            21.01247223738661
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "diemDau": "4-1",
        "diemCuoi": "10-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01278415783879,
        "to_latitude": 21.01247223738661,
        "to_longitude": 105.8195984533065,
        "from_longitude": 105.81990355178303,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2935,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81943361532188,
          21.01234008428809,
          105.8195984533065,
          21.01247223738661
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8195984533065,
            21.01247223738661
          ],
          [
            105.81943361532188,
            21.01234008428809
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "diemDau": "10-2",
        "diemCuoi": "4-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01247223738661,
        "to_latitude": 21.01234008428809,
        "to_longitude": 105.81943361532188,
        "from_longitude": 105.8195984533065,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2936,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81925781276156,
          21.01218429452687,
          105.81943361532188,
          21.01234008428809
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81943361532188,
            21.01234008428809
          ],
          [
            105.81925781276156,
            21.01218429452687
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "diemDau": "4-3",
        "diemCuoi": "4-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01234008428809,
        "to_latitude": 21.01218429452687,
        "to_longitude": 105.81925781276156,
        "from_longitude": 105.81943361532188,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2937,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81907641381666,
          21.01218429452687,
          105.81925781276156,
          21.01231629933597
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81925781276156,
            21.01218429452687
          ],
          [
            105.81907641381666,
            21.01231629933597
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "diemDau": "4-4",
        "diemCuoi": "4-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01218429452687,
        "to_latitude": 21.01231629933597,
        "to_longitude": 105.81907641381666,
        "from_longitude": 105.81925781276156,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2938,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81942568233136,
          21.01247223738661,
          105.8195984533065,
          21.012631147622276
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8195984533065,
            21.01247223738661
          ],
          [
            105.81942568233136,
            21.012631147622276
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "diemDau": "10-2",
        "diemCuoi": "4-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01247223738661,
        "to_latitude": 21.012631147622276,
        "to_longitude": 105.81942568233136,
        "from_longitude": 105.8195984533065,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2939,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81920148637202,
          21.012631147622276,
          105.81942568233136,
          21.01283279114021
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81942568233136,
            21.012631147622276
          ],
          [
            105.81920148637202,
            21.01283279114021
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "diemDau": "4-6",
        "diemCuoi": "4-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.012631147622276,
        "to_latitude": 21.01283279114021,
        "to_longitude": 105.81920148637202,
        "from_longitude": 105.81942568233136,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2940,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81920148637202,
          21.01283279114021,
          105.81936464070603,
          21.013011495094432
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81920148637202,
            21.01283279114021
          ],
          [
            105.81936464070603,
            21.013011495094432
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "diemDau": "4-8",
        "diemCuoi": "4-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01283279114021,
        "to_latitude": 21.013011495094432,
        "to_longitude": 105.81936464070603,
        "from_longitude": 105.81920148637202,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2941,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8190123534271,
          21.012630709259643,
          105.81920148637202,
          21.01283279114021
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81920148637202,
            21.01283279114021
          ],
          [
            105.8190123534271,
            21.012630709259643
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "diemDau": "4-8",
        "diemCuoi": "4-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01283279114021,
        "to_latitude": 21.012630709259643,
        "to_longitude": 105.8190123534271,
        "from_longitude": 105.81920148637202,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2942,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8187870457818,
          21.012402600790722,
          105.8190123534271,
          21.012630709259643
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8190123534271,
            21.012630709259643
          ],
          [
            105.8187870457818,
            21.012402600790722
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "diemDau": "4-9",
        "diemCuoi": "4-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.012630709259643,
        "to_latitude": 21.012402600790722,
        "to_longitude": 105.8187870457818,
        "from_longitude": 105.8190123534271,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2943,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81871868874491,
          21.012236875438596,
          105.8187870457818,
          21.012402600790722
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8187870457818,
            21.012402600790722
          ],
          [
            105.81871868874491,
            21.012236875438596
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "diemDau": "4-10",
        "diemCuoi": "4-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.012402600790722,
        "to_latitude": 21.012236875438596,
        "to_longitude": 105.81871868874491,
        "from_longitude": 105.8187870457818,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2944,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8185899427104,
          21.01221183691091,
          105.8187870457818,
          21.012402600790722
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8187870457818,
            21.012402600790722
          ],
          [
            105.8185899427104,
            21.01221183691091
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "diemDau": "4-10",
        "diemCuoi": "4-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.012402600790722,
        "to_latitude": 21.01221183691091,
        "to_longitude": 105.8185899427104,
        "from_longitude": 105.8187870457818,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2945,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8184452967067,
          21.012062595770335,
          105.8185899427104,
          21.01221183691091
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8185899427104,
            21.01221183691091
          ],
          [
            105.8184452967067,
            21.012062595770335
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "diemDau": "4-11",
        "diemCuoi": "4-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01221183691091,
        "to_latitude": 21.012062595770335,
        "to_longitude": 105.8184452967067,
        "from_longitude": 105.8185899427104,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2946,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81915204432948,
          21.013292245528547,
          105.81931818860045,
          21.01338989505251
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81931818860045,
            21.013382077972842
          ],
          [
            105.81922982838994,
            21.01338989505251
          ],
          [
            105.81915204432948,
            21.013292245528547
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 133 Thái Hà",
        "diemDau": "06",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.013382077972842,
        "to_latitude": 21.013292245528547,
        "to_longitude": 105.81915204432948,
        "from_longitude": 105.81931818860045,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2947,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8189537701989,
          21.013075374163467,
          105.81915204432948,
          21.013292245528547
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81915204432948,
            21.013292245528547
          ],
          [
            105.8189537701989,
            21.013075374163467
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 133 Thái Hà",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.013292245528547,
        "to_latitude": 21.013075374163467,
        "to_longitude": 105.8189537701989,
        "from_longitude": 105.81915204432948,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2948,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8187691255118,
          21.01284825910584,
          105.8189537701989,
          21.013075374163467
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8189537701989,
            21.013075374163467
          ],
          [
            105.8187691255118,
            21.01284825910584
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 133 Thái Hà",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.013075374163467,
        "to_latitude": 21.01284825910584,
        "to_longitude": 105.8187691255118,
        "from_longitude": 105.8189537701989,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2949,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81858421933873,
          21.012665242891437,
          105.8187691255118,
          21.01284825910584
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8187691255118,
            21.01284825910584
          ],
          [
            105.81858421933873,
            21.012665242891437
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 133 Thái Hà",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01284825910584,
        "to_latitude": 21.012665242891437,
        "to_longitude": 105.81858421933873,
        "from_longitude": 105.8187691255118,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2950,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81844513542812,
          21.01251146380395,
          105.81858421933873,
          21.012665242891437
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81858421933873,
            21.012665242891437
          ],
          [
            105.81844513542812,
            21.01251146380395
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 133 Thái Hà",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.012665242891437,
        "to_latitude": 21.01251146380395,
        "to_longitude": 105.81844513542812,
        "from_longitude": 105.81858421933873,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2951,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81813816931792,
          21.01216050791833,
          105.81844513542812,
          21.01251146380395
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81844513542812,
            21.01251146380395
          ],
          [
            105.81813816931792,
            21.01216050791833
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 133 Thái Hà",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01251146380395,
        "to_latitude": 21.01216050791833,
        "to_longitude": 105.81813816931792,
        "from_longitude": 105.81844513542812,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2952,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81801746991562,
          21.01216050791833,
          105.81813816931792,
          21.01231574677057
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81813816931792,
            21.01216050791833
          ],
          [
            105.81801746991562,
            21.01231574677057
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 133 Thái Hà",
        "diemDau": "13",
        "diemCuoi": "13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01216050791833,
        "to_latitude": 21.01231574677057,
        "to_longitude": 105.81801746991562,
        "from_longitude": 105.81813816931792,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2953,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81795577911062,
          21.01199525347758,
          105.81813816931792,
          21.01216050791833
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81813816931792,
            21.01216050791833
          ],
          [
            105.81795577911062,
            21.01199525347758
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 133 Thái Hà",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01216050791833,
        "to_latitude": 21.01199525347758,
        "to_longitude": 105.81795577911062,
        "from_longitude": 105.81813816931792,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2954,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81788604167338,
          21.011955191767232,
          105.81795577911062,
          21.01199525347758
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81795577911062,
            21.01199525347758
          ],
          [
            105.81788604167338,
            21.011955191767232
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 133 Thái Hà",
        "diemDau": "14",
        "diemCuoi": "14-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01199525347758,
        "to_latitude": 21.011955191767232,
        "to_longitude": 105.81788604167338,
        "from_longitude": 105.81795577911062,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2955,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81795577911062,
          21.011860045163345,
          105.81809525397608,
          21.01199525347758
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81795577911062,
            21.01199525347758
          ],
          [
            105.81809525397608,
            21.011860045163345
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 133 Thái Hà",
        "diemDau": "14",
        "diemCuoi": "14-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01199525347758,
        "to_latitude": 21.011860045163345,
        "to_longitude": 105.81809525397608,
        "from_longitude": 105.81795577911062,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2956,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81809525397608,
          21.011769906218856,
          105.81819717791518,
          21.011860045163345
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81809525397608,
            21.011860045163345
          ],
          [
            105.81819717791518,
            21.011769906218856
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 133 Thái Hà",
        "diemDau": "14-2",
        "diemCuoi": "14-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.011860045163345,
        "to_latitude": 21.011769906218856,
        "to_longitude": 105.81819717791518,
        "from_longitude": 105.81809525397608,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2957,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81931818860045,
          21.013382077972842,
          105.81946586278804,
          21.01358769773893
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81931818860045,
            21.013382077972842
          ],
          [
            105.81946586278804,
            21.0134812849341
          ],
          [
            105.81940685418175,
            21.01358769773893
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 218 Thái Hà",
        "diemDau": "06",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.013382077972842,
        "to_latitude": 21.01358769773893,
        "to_longitude": 105.81940685418175,
        "from_longitude": 105.81931818860045,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2958,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81940685418175,
          21.01358769773893,
          105.81968899117157,
          21.013808702362525
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81940685418175,
            21.01358769773893
          ],
          [
            105.81968899117157,
            21.013808702362525
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 218 Thái Hà",
        "diemDau": "6-1",
        "diemCuoi": "6-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01358769773893,
        "to_latitude": 21.013808702362525,
        "to_longitude": 105.81968899117157,
        "from_longitude": 105.81940685418175,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2959,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81991416509982,
          21.013269848700396,
          105.8200843846353,
          21.01348086221182
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81991416509982,
            21.013269848700396
          ],
          [
            105.8200843846353,
            21.01348086221182
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.013269848700396,
        "to_latitude": 21.01348086221182,
        "to_longitude": 105.8200843846353,
        "from_longitude": 105.81991416509982,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2960,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81989598289245,
          21.013240916905268,
          105.81991416509982,
          21.013269848700396
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81989598289245,
            21.013240916905268
          ],
          [
            105.81991416509982,
            21.013269848700396
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 4,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.013240916905268,
        "to_latitude": 21.013269848700396,
        "to_longitude": 105.81991416509982,
        "from_longitude": 105.81989598289245,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2961,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81986819413797,
          21.01348086221182,
          105.8200843846353,
          21.013639026241222
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8200843846353,
            21.01348086221182
          ],
          [
            105.81986819413797,
            21.013639026241222
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "diemDau": "16",
        "diemCuoi": "16-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01348086221182,
        "to_latitude": 21.013639026241222,
        "to_longitude": 105.81986819413797,
        "from_longitude": 105.8200843846353,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2962,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81973944810348,
          21.013639026241222,
          105.81986819413797,
          21.013761713809842
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81986819413797,
            21.013639026241222
          ],
          [
            105.81973944810348,
            21.013761713809842
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "diemDau": "16-1",
        "diemCuoi": "16-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.013639026241222,
        "to_latitude": 21.013761713809842,
        "to_longitude": 105.81973944810348,
        "from_longitude": 105.81986819413797,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2963,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81973944810348,
          21.013761713809842,
          105.81997431079381,
          21.014015573891452
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81973944810348,
            21.013761713809842
          ],
          [
            105.81997431079381,
            21.014015573891452
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "diemDau": "16-4",
        "diemCuoi": "16-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.013761713809842,
        "to_latitude": 21.014015573891452,
        "to_longitude": 105.81997431079381,
        "from_longitude": 105.81973944810348,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2964,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81986819413797,
          21.013639026241222,
          105.82007440716792,
          21.013860581366288
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81986819413797,
            21.013639026241222
          ],
          [
            105.82007440716792,
            21.013860581366288
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "diemDau": "16-1",
        "diemCuoi": "16-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.013639026241222,
        "to_latitude": 21.013860581366288,
        "to_longitude": 105.82007440716792,
        "from_longitude": 105.81986819413797,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2965,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82007440716792,
          21.013860581366288,
          105.82020883468289,
          21.014067180377925
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82007440716792,
            21.013860581366288
          ],
          [
            105.82020883468289,
            21.014067180377925
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "diemDau": "16-2",
        "diemCuoi": "16-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.013860581366288,
        "to_latitude": 21.014067180377925,
        "to_longitude": 105.82020883468289,
        "from_longitude": 105.82007440716792,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2966,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8200843846353,
          21.01348086221182,
          105.82025421402692,
          21.013665269952597
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8200843846353,
            21.01348086221182
          ],
          [
            105.82025421402692,
            21.013665269952597
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01348086221182,
        "to_latitude": 21.013665269952597,
        "to_longitude": 105.82025421402692,
        "from_longitude": 105.8200843846353,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2967,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82025421402692,
          21.013665269952597,
          105.82042838717689,
          21.01383466816742
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82025421402692,
            21.013665269952597
          ],
          [
            105.82042838717689,
            21.01383466816742
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.013665269952597,
        "to_latitude": 21.01383466816742,
        "to_longitude": 105.82042838717689,
        "from_longitude": 105.82025421402692,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2968,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82042838717689,
          21.01383466816742,
          105.82069699672549,
          21.014119760625892
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82042838717689,
            21.01383466816742
          ],
          [
            105.82069699672549,
            21.014119760625892
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01383466816742,
        "to_latitude": 21.014119760625892,
        "to_longitude": 105.82069699672549,
        "from_longitude": 105.82042838717689,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2969,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82053338197238,
          21.014119760625892,
          105.82069699672549,
          21.014237440160404
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82069699672549,
            21.014119760625892
          ],
          [
            105.82053338197238,
            21.014237440160404
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "diemDau": "19",
        "diemCuoi": "19-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.014119760625892,
        "to_latitude": 21.014237440160404,
        "to_longitude": 105.82053338197238,
        "from_longitude": 105.82069699672549,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2970,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82069699672549,
          21.014119760625892,
          105.82085792926188,
          21.014295027984442
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82069699672549,
            21.014119760625892
          ],
          [
            105.82085792926188,
            21.014295027984442
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.014119760625892,
        "to_latitude": 21.014295027984442,
        "to_longitude": 105.82085792926188,
        "from_longitude": 105.82069699672549,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2971,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82071660118517,
          21.014295027984442,
          105.82085792926188,
          21.01447903339663
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82085792926188,
            21.014295027984442
          ],
          [
            105.82071660118517,
            21.01447903339663
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "diemDau": "20",
        "diemCuoi": "20-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.014295027984442,
        "to_latitude": 21.01447903339663,
        "to_longitude": 105.82071660118517,
        "from_longitude": 105.82085792926188,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2972,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82085792926188,
          21.014295027984442,
          105.82098123459429,
          21.01448019584994
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82085792926188,
            21.014295027984442
          ],
          [
            105.82086865810183,
            21.014352615785423
          ],
          [
            105.82098123459429,
            21.01448019584994
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "diemDau": "20",
        "diemCuoi": "20-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.014295027984442,
        "to_latitude": 21.01448019584994,
        "to_longitude": 105.82098123459429,
        "from_longitude": 105.82085792926188,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2973,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82085792926188,
          21.014146670520503,
          105.8210290819566,
          21.014295027984442
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82085792926188,
            21.014295027984442
          ],
          [
            105.8210290819566,
            21.014146670520503
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "diemDau": "20",
        "diemCuoi": "20-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.014295027984442,
        "to_latitude": 21.014146670520503,
        "to_longitude": 105.8210290819566,
        "from_longitude": 105.82085792926188,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2974,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82087697566247,
          21.013959668530326,
          105.8210290819566,
          21.014146670520503
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8210290819566,
            21.014146670520503
          ],
          [
            105.82087697566247,
            21.013959668530326
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "diemDau": "20-3",
        "diemCuoi": "20-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.014146670520503,
        "to_latitude": 21.013959668530326,
        "to_longitude": 105.82087697566247,
        "from_longitude": 105.8210290819566,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2975,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8210290819566,
          21.01401880356695,
          105.82119557159731,
          21.014146670520503
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8210290819566,
            21.014146670520503
          ],
          [
            105.82119557159731,
            21.01401880356695
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "diemDau": "20-3",
        "diemCuoi": "20-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.014146670520503,
        "to_latitude": 21.01401880356695,
        "to_longitude": 105.82119557159731,
        "from_longitude": 105.8210290819566,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2976,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82101794199083,
          21.013840915091098,
          105.82119557159731,
          21.01401880356695
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82119557159731,
            21.01401880356695
          ],
          [
            105.82101794199083,
            21.013840915091098
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "diemDau": "20-5",
        "diemCuoi": "20-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.01401880356695,
        "to_latitude": 21.013840915091098,
        "to_longitude": 105.82101794199083,
        "from_longitude": 105.82119557159731,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2977,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82041606413593,
          21.014237440160404,
          105.82053338197238,
          21.014328702785736
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82053338197238,
            21.014237440160404
          ],
          [
            105.82041606413593,
            21.014328702785736
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "diemDau": "19-1",
        "diemCuoi": "19-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.014237440160404,
        "to_latitude": 21.014328702785736,
        "to_longitude": 105.82041606413593,
        "from_longitude": 105.82053338197238,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2978,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82021523159062,
          21.014160950424536,
          105.82041606413593,
          21.014328702785736
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82041606413593,
            21.014328702785736
          ],
          [
            105.82021523159062,
            21.014160950424536
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XDN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "19-2",
        "diemCuoi": "19-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xây Dựng Nhà",
        "from_latitude": 21.014328702785736,
        "to_latitude": 21.014160950424536,
        "to_longitude": 105.82021523159062,
        "from_longitude": 105.82041606413593,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2979,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83781246860457,
          21.01991912298014,
          105.83800826985957,
          21.02008937608237
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83781246860457,
            21.01991912298014
          ],
          [
            105.83800826985957,
            21.02008937608237
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "3",
        "diemCuoi": "4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.01991912298014,
        "to_latitude": 21.02008937608237,
        "to_longitude": 105.83800826985957,
        "from_longitude": 105.83781246860457,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2980,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83800826985957,
          21.02008937608237,
          105.83816115577271,
          21.02026964386052
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83800826985957,
            21.02008937608237
          ],
          [
            105.83816115577271,
            21.02026964386052
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "4",
        "diemCuoi": "5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02008937608237,
        "to_latitude": 21.02026964386052,
        "to_longitude": 105.83816115577271,
        "from_longitude": 105.83800826985957,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2981,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83816115577271,
          21.02026964386052,
          105.83831672389363,
          21.020444903991287
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83816115577271,
            21.02026964386052
          ],
          [
            105.83831672389363,
            21.020444903991287
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "5",
        "diemCuoi": "6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02026964386052,
        "to_latitude": 21.020444903991287,
        "to_longitude": 105.83831672389363,
        "from_longitude": 105.83816115577271,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2982,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83831672389363,
          21.020444903991287,
          105.83845351655135,
          21.020602637932736
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83831672389363,
            21.020444903991287
          ],
          [
            105.83845351655135,
            21.020602637932736
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "6",
        "diemCuoi": "7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.020444903991287,
        "to_latitude": 21.020602637932736,
        "to_longitude": 105.83845351655135,
        "from_longitude": 105.83831672389363,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2983,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83845351655135,
          21.020602637932736,
          105.83860103804903,
          21.020760371707347
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83845351655135,
            21.020602637932736
          ],
          [
            105.83860103804903,
            21.020760371707347
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "7",
        "diemCuoi": "8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.020602637932736,
        "to_latitude": 21.020760371707347,
        "to_longitude": 105.83860103804903,
        "from_longitude": 105.83845351655135,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2984,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83776150662153,
          21.019654979910918,
          105.83781246860457,
          21.01991912298014
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83781246860457,
            21.01991912298014
          ],
          [
            105.83781246859559,
            21.019767647622864
          ],
          [
            105.83776150662153,
            21.019654979910918
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "2",
        "diemCuoi": "3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.01991912298014,
        "to_latitude": 21.019654979910918,
        "to_longitude": 105.83776150662153,
        "from_longitude": 105.83781246860457,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2985,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83761398512385,
          21.019349524788186,
          105.83776150662153,
          21.019654979910918
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83776150662153,
            21.019654979910918
          ],
          [
            105.83761398512385,
            21.019349524788186
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "1",
        "diemCuoi": "2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.019654979910918,
        "to_latitude": 21.019349524788186,
        "to_longitude": 105.83761398512385,
        "from_longitude": 105.83776150662153,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2986,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83860103804903,
          21.020760371707347,
          105.8387485595467,
          21.020832979261804
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83860103804903,
            21.020760371707347
          ],
          [
            105.8387485595467,
            21.020832979261804
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "8",
        "diemCuoi": "9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.020760371707347,
        "to_latitude": 21.020832979261804,
        "to_longitude": 105.8387485595467,
        "from_longitude": 105.83860103804903,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2987,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8387485595467,
          21.020832979261804,
          105.83906506021297,
          21.021183497993807
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8387485595467,
            21.020832979261804
          ],
          [
            105.83906506021297,
            21.021183497993807
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "9",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.020832979261804,
        "to_latitude": 21.021183497993807,
        "to_longitude": 105.83906506021297,
        "from_longitude": 105.8387485595467,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2988,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83906506021297,
          21.021183497993807,
          105.83921526391839,
          21.0213988162347
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83906506021297,
            21.021183497993807
          ],
          [
            105.83921526391839,
            21.0213988162347
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.021183497993807,
        "to_latitude": 21.0213988162347,
        "to_longitude": 105.83921526391839,
        "from_longitude": 105.83906506021297,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2989,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83914552648116,
          21.0213988162347,
          105.83921526391839,
          21.022019732210577
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83921526391839,
            21.0213988162347
          ],
          [
            105.83914552648116,
            21.022019732210577
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 65,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.0213988162347,
        "to_latitude": 21.022019732210577,
        "to_longitude": 105.83914552648116,
        "from_longitude": 105.83921526391839,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2990,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83910797555481,
          21.022019732210577,
          105.83914552648116,
          21.022332692911863
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83914552648116,
            21.022019732210577
          ],
          [
            105.83910797555481,
            21.022332692911863
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.022019732210577,
        "to_latitude": 21.022332692911863,
        "to_longitude": 105.83910797555481,
        "from_longitude": 105.83914552648116,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2991,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83910797555481,
          21.022332692911863,
          105.83918039519978,
          21.02267820076359
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83910797555481,
            21.022332692911863
          ],
          [
            105.83918039519978,
            21.02267820076359
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "13",
        "diemCuoi": "14-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.022332692911863,
        "to_latitude": 21.02267820076359,
        "to_longitude": 105.83918039519978,
        "from_longitude": 105.83910797555481,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2992,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83918039519978,
          21.02267820076359,
          105.83930780012142,
          21.022962367847914
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83918039519978,
            21.02267820076359
          ],
          [
            105.83930780012142,
            21.022962367847914
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "14-4",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02267820076359,
        "to_latitude": 21.022962367847914,
        "to_longitude": 105.83930780012142,
        "from_longitude": 105.83918039519978,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2993,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83930780012142,
          21.022962367847914,
          105.83945263940237,
          21.023237771558613
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83930780012142,
            21.022962367847914
          ],
          [
            105.83945263940237,
            21.023237771558613
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.022962367847914,
        "to_latitude": 21.023237771558613,
        "to_longitude": 105.83945263940237,
        "from_longitude": 105.83930780012142,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2994,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83945263940237,
          21.023237771558613,
          105.83950628358417,
          21.023575766326015
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83945263940237,
            21.023237771558613
          ],
          [
            105.83950628358417,
            21.023575766326015
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.023237771558613,
        "to_latitude": 21.023575766326015,
        "to_longitude": 105.83950628358417,
        "from_longitude": 105.83945263940237,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2995,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83950628358417,
          21.023575766326015,
          105.83951164799066,
          21.02387119816178
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83950628358417,
            21.023575766326015
          ],
          [
            105.83951164799066,
            21.02387119816178
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.023575766326015,
        "to_latitude": 21.02387119816178,
        "to_longitude": 105.83951164799066,
        "from_longitude": 105.83950628358417,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2996,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83951164799066,
          21.02387119816178,
          105.83953310567956,
          21.024299322919482
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83951164799066,
            21.02387119816178
          ],
          [
            105.83953310567956,
            21.024299322919482
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02387119816178,
        "to_latitude": 21.024299322919482,
        "to_longitude": 105.83953310567956,
        "from_longitude": 105.83951164799066,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2997,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83953310567956,
          21.024299322919482,
          105.83987911063996,
          21.024301826569076
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83953310567956,
            21.024299322919482
          ],
          [
            105.83987911063996,
            21.024301826569076
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.024299322919482,
        "to_latitude": 21.024301826569076,
        "to_longitude": 105.83987911063996,
        "from_longitude": 105.83953310567956,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2998,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83987374622448,
          21.024301826569076,
          105.83987911063996,
          21.024634811636112
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83987911063996,
            21.024301826569076
          ],
          [
            105.83987374622448,
            21.024634811636112
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.024301826569076,
        "to_latitude": 21.024634811636112,
        "to_longitude": 105.83987374622448,
        "from_longitude": 105.83987911063996,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 2999,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83987374622448,
          21.024634811636112,
          105.83988715726319,
          21.02502537963846
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83987374622448,
            21.024634811636112
          ],
          [
            105.83988715726319,
            21.02502537963846
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.024634811636112,
        "to_latitude": 21.02502537963846,
        "to_longitude": 105.83988715726319,
        "from_longitude": 105.83987374622448,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3000,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83988179283872,
          21.02502537963846,
          105.83988715726319,
          21.02534334129504
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83988715726319,
            21.02502537963846
          ],
          [
            105.83988179283872,
            21.02534334129504
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02502537963846,
        "to_latitude": 21.02534334129504,
        "to_longitude": 105.83988179283872,
        "from_longitude": 105.83988715726319,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3001,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83988179283872,
          21.02534334129504,
          105.83988715726319,
          21.025703863927614
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83988179283872,
            21.02534334129504
          ],
          [
            105.83988715726319,
            21.025703863927614
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02534334129504,
        "to_latitude": 21.025703863927614,
        "to_longitude": 105.83988715726319,
        "from_longitude": 105.83988179283872,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3002,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83988715726319,
          21.025703863927614,
          105.83988715726319,
          21.026064385688194
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83988715726319,
            21.025703863927614
          ],
          [
            105.83988715726319,
            21.026064385688194
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Quý Cáp",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.025703863927614,
        "to_latitude": 21.026064385688194,
        "to_longitude": 105.83988715726319,
        "from_longitude": 105.83988715726319,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3003,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83848838526097,
          21.020399837116372,
          105.8387485595467,
          21.020832979261804
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8387485595467,
            21.020832979261804
          ],
          [
            105.83852459510143,
            21.020507496837347
          ],
          [
            105.83848838526097,
            21.020399837116372
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 78 Trung Tiền",
        "diemDau": "9",
        "diemCuoi": "9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.020832979261804,
        "to_latitude": 21.020399837116372,
        "to_longitude": 105.83848838526097,
        "from_longitude": 105.8387485595467,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3004,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83848838526097,
          21.020369792528058,
          105.83878342825632,
          21.020399837116372
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83848838526097,
            21.020399837116372
          ],
          [
            105.83878342825632,
            21.020369792528058
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 78 Trung Tiền",
        "diemDau": "9-1",
        "diemCuoi": "9-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.020399837116372,
        "to_latitude": 21.020369792528058,
        "to_longitude": 105.83878342825632,
        "from_longitude": 105.83848838526097,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3005,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83878342825632,
          21.0203046958991,
          105.83892826753727,
          21.020369792528058
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83878342825632,
            21.020369792528058
          ],
          [
            105.83892826753727,
            21.0203046958991
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 78 Trung Tiền",
        "diemDau": "9-2",
        "diemCuoi": "9-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.020369792528058,
        "to_latitude": 21.0203046958991,
        "to_longitude": 105.83892826753727,
        "from_longitude": 105.83878342825632,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3006,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83892826753727,
          21.020049316542902,
          105.83893899637724,
          21.0203046958991
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83892826753727,
            21.0203046958991
          ],
          [
            105.83893899637724,
            21.020049316542902
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 78 Trung Tiền",
        "diemDau": "9-3",
        "diemCuoi": "9-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.0203046958991,
        "to_latitude": 21.020049316542902,
        "to_longitude": 105.83893899637724,
        "from_longitude": 105.83892826753727,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3007,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83893899637724,
          21.019976708606805,
          105.83919917065397,
          21.020049316542902
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83893899637724,
            21.020049316542902
          ],
          [
            105.83919917065397,
            21.019976708606805
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 78 Trung Tiền",
        "diemDau": "9-4",
        "diemCuoi": "9-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.020049316542902,
        "to_latitude": 21.019976708606805,
        "to_longitude": 105.83919917065397,
        "from_longitude": 105.83893899637724,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3008,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83919917065397,
          21.019976708606805,
          105.83954249340664,
          21.02000925699602
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83919917065397,
            21.019976708606805
          ],
          [
            105.83954249340664,
            21.02000925699602
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 78 Trung Tiền",
        "diemDau": "9-5",
        "diemCuoi": "9-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.019976708606805,
        "to_latitude": 21.02000925699602,
        "to_longitude": 105.83954249340664,
        "from_longitude": 105.83919917065397,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3009,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83954249340664,
          21.02000925699602,
          105.83954517561439,
          21.02029217731347
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83954249340664,
            21.02000925699602
          ],
          [
            105.83954517561439,
            21.02029217731347
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 78 Trung Tiền",
        "diemDau": "9-6",
        "diemCuoi": "9-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02000925699602,
        "to_latitude": 21.02029217731347,
        "to_longitude": 105.83954517561439,
        "from_longitude": 105.83954249340664,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3010,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83954517561439,
          21.02029217731347,
          105.83954785782213,
          21.020567585956623
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83954517561439,
            21.02029217731347
          ],
          [
            105.83954785782213,
            21.020567585956623
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 78 Trung Tiền",
        "diemDau": "9-7",
        "diemCuoi": "9-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02029217731347,
        "to_latitude": 21.020567585956623,
        "to_longitude": 105.83954785782213,
        "from_longitude": 105.83954517561439,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3011,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8392072172772,
          21.020567585956623,
          105.83954785782213,
          21.020617660200394
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83954785782213,
            21.020567585956623
          ],
          [
            105.8392072172772,
            21.020617660200394
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 78 Trung Tiền",
        "diemDau": "9-8",
        "diemCuoi": "9-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.020567585956623,
        "to_latitude": 21.020617660200394,
        "to_longitude": 105.8392072172772,
        "from_longitude": 105.83954785782213,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3012,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83895240742493,
          21.020585111944026,
          105.8392072172772,
          21.020617660200394
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8392072172772,
            21.020617660200394
          ],
          [
            105.83895240742493,
            21.020585111944026
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 78 Trung Tiền",
        "diemDau": "9-9",
        "diemCuoi": "9-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.020617660200394,
        "to_latitude": 21.020585111944026,
        "to_longitude": 105.83895240742493,
        "from_longitude": 105.8392072172772,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3013,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83906506021297,
          21.02108835727324,
          105.839188441814,
          21.021183497993807
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83906506021297,
            21.021183497993807
          ],
          [
            105.839188441814,
            21.02108835727324
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 78 Trung Tiền",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.021183497993807,
        "to_latitude": 21.02108835727324,
        "to_longitude": 105.839188441814,
        "from_longitude": 105.83906506021297,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3014,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83906506021297,
          21.020935631257917,
          105.83922331053263,
          21.021183497993807
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83906506021297,
            21.021183497993807
          ],
          [
            105.83910127004444,
            21.02097569055172
          ],
          [
            105.83922331053263,
            21.020935631257917
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 59,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 88/105 Trần Quý Cáp",
        "diemDau": "10",
        "diemCuoi": "10-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.021183497993807,
        "to_latitude": 21.020935631257917,
        "to_longitude": 105.83922331053263,
        "from_longitude": 105.83906506021297,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3015,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83922331053263,
          21.020935631257917,
          105.83927427250667,
          21.021080846160814
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83922331053263,
            21.020935631257917
          ],
          [
            105.83927427250667,
            21.021080846160814
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 78 Trung Tiền",
        "diemDau": "10-2",
        "diemCuoi": "10-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.020935631257917,
        "to_latitude": 21.021080846160814,
        "to_longitude": 105.83927427250667,
        "from_longitude": 105.83922331053263,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3016,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82339519247631,
          21.01544317877366,
          105.82350194917231,
          21.015457130558925
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82339519247631,
            21.01544317877366
          ],
          [
            105.82350194917231,
            21.015457130558925
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 20/1 Võ Văn Dũng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.01544317877366,
        "to_latitude": 21.015457130558925,
        "to_longitude": 105.82350194917231,
        "from_longitude": 105.82339519247631,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3017,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82350194917231,
          21.015349589093447,
          105.82372047852961,
          21.015457130558925
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82350194917231,
            21.015457130558925
          ],
          [
            105.82372047852961,
            21.015349589093447
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 20/1 Võ Văn Dũng",
        "diemDau": "HC1 02",
        "diemCuoi": "HC1 03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.015457130558925,
        "to_latitude": 21.015349589093447,
        "to_longitude": 105.82372047852961,
        "from_longitude": 105.82350194917231,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3018,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82372047852961,
          21.015349589093447,
          105.82383030518616,
          21.015527628959465
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82372047852961,
            21.015349589093447
          ],
          [
            105.82383030518616,
            21.015527628959465
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 20/1 Võ Văn Dũng",
        "diemDau": "HC1 03",
        "diemCuoi": "HC1 04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.015349589093447,
        "to_latitude": 21.015527628959465,
        "to_longitude": 105.82383030518616,
        "from_longitude": 105.82372047852961,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3019,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82350194917231,
          21.015457130558925,
          105.82359784452547,
          21.015633628261046
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82350194917231,
            21.015457130558925
          ],
          [
            105.82359784452547,
            21.015633628261046
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 20/1 Võ Văn Dũng",
        "diemDau": "HC1 02",
        "diemCuoi": "HC1 32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.015457130558925,
        "to_latitude": 21.015633628261046,
        "to_longitude": 105.82359784452547,
        "from_longitude": 105.82350194917231,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3020,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82339625877295,
          21.015633628261046,
          105.82359784452547,
          21.015766924540177
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82359784452547,
            21.015633628261046
          ],
          [
            105.82339625877295,
            21.015766924540177
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 20/1 Võ Văn Dũng",
        "diemDau": "HC1 32",
        "diemCuoi": "HC1 33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.015633628261046,
        "to_latitude": 21.015766924540177,
        "to_longitude": 105.82339625877295,
        "from_longitude": 105.82359784452547,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3021,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82318648980501,
          21.015766924540177,
          105.82339625877295,
          21.01587198360708
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82339625877295,
            21.015766924540177
          ],
          [
            105.82318648980501,
            21.01587198360708
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 20/1 Võ Văn Dũng",
        "diemDau": "HC1 33",
        "diemCuoi": "HC1 34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.015766924540177,
        "to_latitude": 21.01587198360708,
        "to_longitude": 105.82318648980501,
        "from_longitude": 105.82339625877295,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3022,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82383030518616,
          21.015527628959465,
          105.82397356618743,
          21.015796839918014
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82383030518616,
            21.015527628959465
          ],
          [
            105.82397356618743,
            21.015796839918014
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Võ Văn Dũng",
        "diemDau": "HC1 04",
        "diemCuoi": "HC1 05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.015527628959465,
        "to_latitude": 21.015796839918014,
        "to_longitude": 105.82397356618743,
        "from_longitude": 105.82383030518616,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3023,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82359784452547,
          21.015633628261046,
          105.8237522839409,
          21.01590199917585
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82359784452547,
            21.015633628261046
          ],
          [
            105.8237522839409,
            21.01590199917585
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Võ Văn Dũng",
        "diemDau": "HC1 32",
        "diemCuoi": "HC1 06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.015633628261046,
        "to_latitude": 21.01590199917585,
        "to_longitude": 105.8237522839409,
        "from_longitude": 105.82359784452547,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3024,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82354144185786,
          21.01590199917585,
          105.8237522839409,
          21.0160139147168
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8237522839409,
            21.01590199917585
          ],
          [
            105.82354144185786,
            21.0160139147168
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Võ Văn Dũng",
        "diemDau": "HC1 06",
        "diemCuoi": "HC1 07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.01590199917585,
        "to_latitude": 21.0160139147168,
        "to_longitude": 105.82354144185786,
        "from_longitude": 105.8237522839409,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3025,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82334516678979,
          21.0160139147168,
          105.82354144185786,
          21.016115795590434
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82354144185786,
            21.0160139147168
          ],
          [
            105.82334516678979,
            21.016115795590434
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Võ Văn Dũng",
        "diemDau": "HC1 07",
        "diemCuoi": "HC1 08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.0160139147168,
        "to_latitude": 21.016115795590434,
        "to_longitude": 105.82334516678979,
        "from_longitude": 105.82354144185786,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3026,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8130538281298,
          21.009599628582812,
          105.8133260723414,
          21.009759877889216
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8130538281298,
            21.009759877889216
          ],
          [
            105.8133260723414,
            21.009599628582812
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "07",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.009759877889216,
        "to_latitude": 21.009599628582812,
        "to_longitude": 105.8133260723414,
        "from_longitude": 105.8130538281298,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3027,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8133260723414,
          21.009471929793925,
          105.81355003679565,
          21.009599628582812
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8133260723414,
            21.009599628582812
          ],
          [
            105.81355003679565,
            21.009471929793925
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "06",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.009599628582812,
        "to_latitude": 21.009471929793925,
        "to_longitude": 105.81355003679565,
        "from_longitude": 105.8133260723414,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3028,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81355003679565,
          21.009347986747173,
          105.81376461351381,
          21.009471929793925
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81355003679565,
            21.009471929793925
          ],
          [
            105.81376461351381,
            21.009347986747173
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "05",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.009471929793925,
        "to_latitude": 21.009347986747173,
        "to_longitude": 105.81376461351381,
        "from_longitude": 105.81355003679565,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3029,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81376461351381,
          21.00923155530621,
          105.81395236815457,
          21.009347986747173
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81376461351381,
            21.009347986747173
          ],
          [
            105.81395236815457,
            21.00923155530621
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "04",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.009347986747173,
        "to_latitude": 21.00923155530621,
        "to_longitude": 105.81395236815457,
        "from_longitude": 105.81376461351381,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3030,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81395236815457,
          21.0091138718217,
          105.81414414608898,
          21.00923155530621
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81395236815457,
            21.00923155530621
          ],
          [
            105.81414414608898,
            21.0091138718217
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.00923155530621,
        "to_latitude": 21.0091138718217,
        "to_longitude": 105.81414414608898,
        "from_longitude": 105.81395236815457,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3031,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81414414608898,
          21.009007183742828,
          105.81433207563174,
          21.0091138718217
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81414414608898,
            21.0091138718217
          ],
          [
            105.81433207563174,
            21.009007183742828
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "02",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.0091138718217,
        "to_latitude": 21.009007183742828,
        "to_longitude": 105.81433207563174,
        "from_longitude": 105.81414414608898,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3032,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81278694833371,
          21.009759877889216,
          105.8130538281298,
          21.00992513480548
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8130538281298,
            21.009759877889216
          ],
          [
            105.81278694833371,
            21.00992513480548
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.009759877889216,
        "to_latitude": 21.00992513480548,
        "to_longitude": 105.81278694833371,
        "from_longitude": 105.8130538281298,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3033,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81252275074537,
          21.00992513480548,
          105.81278694833371,
          21.01008162792941
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81278694833371,
            21.00992513480548
          ],
          [
            105.81252275074537,
            21.01008162792941
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.00992513480548,
        "to_latitude": 21.01008162792941,
        "to_longitude": 105.81252275074537,
        "from_longitude": 105.81278694833371,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3034,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81224245990158,
          21.01008162792941,
          105.81252275074537,
          21.010250640317945
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81252275074537,
            21.01008162792941
          ],
          [
            105.81224245990158,
            21.010250640317945
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.01008162792941,
        "to_latitude": 21.010250640317945,
        "to_longitude": 105.81224245990158,
        "from_longitude": 105.81252275074537,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3035,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81197021569001,
          21.010250640317945,
          105.81224245990158,
          21.01040963698324
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81224245990158,
            21.010250640317945
          ],
          [
            105.81197021569001,
            21.01040963698324
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.010250640317945,
        "to_latitude": 21.01040963698324,
        "to_longitude": 105.81197021569001,
        "from_longitude": 105.81224245990158,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3036,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81166578495856,
          21.01040963698324,
          105.81197021569001,
          21.010591168401998
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81197021569001,
            21.01040963698324
          ],
          [
            105.81166578495856,
            21.010591168401998
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.01040963698324,
        "to_latitude": 21.010591168401998,
        "to_longitude": 105.81166578495856,
        "from_longitude": 105.81197021569001,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3037,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81137208307607,
          21.010591168401998,
          105.81166578495856,
          21.01078772286107
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81166578495856,
            21.010591168401998
          ],
          [
            105.81137208307607,
            21.01078772286107
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.010591168401998,
        "to_latitude": 21.01078772286107,
        "to_longitude": 105.81137208307607,
        "from_longitude": 105.81166578495856,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3038,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81133319104585,
          21.01078772286107,
          105.81137208307607,
          21.01087160271114
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81137208307607,
            21.01078772286107
          ],
          [
            105.81133319104585,
            21.01087160271114
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.01078772286107,
        "to_latitude": 21.01087160271114,
        "to_longitude": 105.81133319104585,
        "from_longitude": 105.81137208307607,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3039,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81137208307607,
          21.01078772286107,
          105.81155849661296,
          21.010900397275286
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81137208307607,
            21.01078772286107
          ],
          [
            105.81155849661296,
            21.010900397275286
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 528 Đường Láng",
        "diemDau": "13",
        "diemCuoi": "13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.01078772286107,
        "to_latitude": 21.010900397275286,
        "to_longitude": 105.81155849661296,
        "from_longitude": 105.81137208307607,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3040,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81191463701518,
          21.010442778449164,
          105.8120184954653,
          21.010628726598714
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81191463701518,
            21.010442778449164
          ],
          [
            105.8120184954653,
            21.010628726598714
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 516 Đường Láng",
        "diemDau": "12",
        "diemCuoi": "12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.010442778449164,
        "to_latitude": 21.010628726598714,
        "to_longitude": 105.8120184954653,
        "from_longitude": 105.81191463701518,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3041,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82232937206004,
          21.01574426026211,
          105.82233741868328,
          21.015919525711606
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82232937206004,
            21.015919525711606
          ],
          [
            105.82233741868328,
            21.01574426026211
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 86 Hoàng Cầu",
        "diemDau": "HC1 13",
        "diemCuoi": "HC1 14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.015919525711606,
        "to_latitude": 21.01574426026211,
        "to_longitude": 105.82233741868328,
        "from_longitude": 105.82232937206004,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3042,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82233741868328,
          21.01536117934773,
          105.82234010089101,
          21.01574426026211
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82233741868328,
            21.01574426026211
          ],
          [
            105.82234010089101,
            21.01536117934773
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 86 Hoàng Cầu",
        "diemDau": "HC1 14",
        "diemCuoi": "HC1 15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.01574426026211,
        "to_latitude": 21.01536117934773,
        "to_longitude": 105.82234010089101,
        "from_longitude": 105.82233741868328,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3043,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82234010089101,
          21.01508200538189,
          105.82234814752321,
          21.01536117934773
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82234010089101,
            21.01536117934773
          ],
          [
            105.82234814752321,
            21.01508200538189
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 86 Hoàng Cầu",
        "diemDau": "HC1 15",
        "diemCuoi": "HC1 16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.01536117934773,
        "to_latitude": 21.01508200538189,
        "to_longitude": 105.82234814752321,
        "from_longitude": 105.82234010089101,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3044,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82234814752321,
          21.014831624698747,
          105.82248494018094,
          21.01508200538189
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82234814752321,
            21.01508200538189
          ],
          [
            105.82242324937592,
            21.0148954718127
          ],
          [
            105.82248494018094,
            21.014831624698747
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 86 Hoàng Cầu",
        "diemDau": "HC1 16",
        "diemCuoi": "HC1 17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.01508200538189,
        "to_latitude": 21.014831624698747,
        "to_longitude": 105.82248494018094,
        "from_longitude": 105.82234814752321,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3045,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81218060870236,
          21.010538001846662,
          105.81226509828218,
          21.010712021474554
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81218060870236,
            21.010538001846662
          ],
          [
            105.81226509828218,
            21.010712021474554
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 508 Đường Láng",
        "diemDau": "11-10-1",
        "diemCuoi": "11-10-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.010538001846662,
        "to_latitude": 21.010712021474554,
        "to_longitude": 105.81226509828218,
        "from_longitude": 105.81218060870236,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3046,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81226509828218,
          21.010712021474554,
          105.8124193252992,
          21.010878529273946
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81226509828218,
            21.010712021474554
          ],
          [
            105.8124193252992,
            21.010878529273946
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 508 Đường Láng",
        "diemDau": "11-1-1",
        "diemCuoi": "11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.010712021474554,
        "to_latitude": 21.010878529273946,
        "to_longitude": 105.8124193252992,
        "from_longitude": 105.81226509828218,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3047,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8124193252992,
          21.010878529273946,
          105.81267916429773,
          21.011035960349123
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8124193252992,
            21.010878529273946
          ],
          [
            105.81267916429773,
            21.011035960349123
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 508 Đường Láng",
        "diemDau": "11-1",
        "diemCuoi": "11-10-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.010878529273946,
        "to_latitude": 21.011035960349123,
        "to_longitude": 105.81267916429773,
        "from_longitude": 105.8124193252992,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3048,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81267916429773,
          21.011035960349123,
          105.8129071520636,
          21.01113235941521
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81267916429773,
            21.011035960349123
          ],
          [
            105.8129071520636,
            21.01113235941521
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 508 Đường Láng",
        "diemDau": "11-10-2",
        "diemCuoi": "11-10-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.011035960349123,
        "to_latitude": 21.01113235941521,
        "to_longitude": 105.8129071520636,
        "from_longitude": 105.81267916429773,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3049,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81310965884693,
          21.01122750648404,
          105.81331618894188,
          21.01129761481106
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81310965884693,
            21.01122750648404
          ],
          [
            105.81331618894188,
            21.01129761481106
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 508 Đường Láng",
        "diemDau": "11-10-4",
        "diemCuoi": "11-10-4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.01122750648404,
        "to_latitude": 21.01129761481106,
        "to_longitude": 105.81331618894188,
        "from_longitude": 105.81310965884693,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3050,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81331618894188,
          21.01129761481106,
          105.81365951169455,
          21.011482900946906
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81331618894188,
            21.01129761481106
          ],
          [
            105.81365951169455,
            21.011482900946906
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 508 Đường Láng",
        "diemDau": "11-10-4",
        "diemCuoi": "11-10-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.01129761481106,
        "to_latitude": 21.011482900946906,
        "to_longitude": 105.81365951169455,
        "from_longitude": 105.81331618894188,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3051,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81343018281584,
          21.011482900946906,
          105.81365951169455,
          21.011795883763124
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81365951169455,
            21.011482900946906
          ],
          [
            105.81343018281584,
            21.011795883763124
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 508 Đường Láng",
        "diemDau": "11-10-5",
        "diemCuoi": "11-10-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.011482900946906,
        "to_latitude": 21.011795883763124,
        "to_longitude": 105.81343018281584,
        "from_longitude": 105.81365951169455,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3052,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81343018281584,
          21.011795883763124,
          105.8136984037158,
          21.01192984020728
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81343018281584,
            21.011795883763124
          ],
          [
            105.8136984037158,
            21.01192984020728
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 508 Đường Láng",
        "diemDau": "11-10-7",
        "diemCuoi": "11-10-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.011795883763124,
        "to_latitude": 21.01192984020728,
        "to_longitude": 105.8136984037158,
        "from_longitude": 105.81343018281584,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3053,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81344895827901,
          21.011860324724,
          105.81355921287934,
          21.012012467485924
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81344895827901,
            21.012012467485924
          ],
          [
            105.81355921287934,
            21.011860324724
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 508 Đường Láng",
        "diemDau": "11-10-7",
        "diemCuoi": "11-10-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.012012467485924,
        "to_latitude": 21.011860324724,
        "to_longitude": 105.81355921287934,
        "from_longitude": 105.81344895827901,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3054,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81319280732286,
          21.01129761481106,
          105.81331618894188,
          21.011517955055787
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81331618894188,
            21.01129761481106
          ],
          [
            105.81319280732286,
            21.011517955055787
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 508 Đường Láng",
        "diemDau": "11-10-4",
        "diemCuoi": "11-10-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.01129761481106,
        "to_latitude": 21.011517955055787,
        "to_longitude": 105.81319280732286,
        "from_longitude": 105.81331618894188,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3055,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8129071520636,
          21.01113235941521,
          105.81331618894188,
          21.01129761481106
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8129071520636,
            21.01113235941521
          ],
          [
            105.81316330302872,
            21.01120997939945
          ],
          [
            105.81331618894188,
            21.01129761481106
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 508 Đường Láng",
        "diemDau": "11-10-3",
        "diemCuoi": "11-10-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.01113235941521,
        "to_latitude": 21.01129761481106,
        "to_longitude": 105.81331618894188,
        "from_longitude": 105.8129071520636,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3056,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8126828523401,
          21.01113235941521,
          105.8129071520636,
          21.011412479722573
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8129071520636,
            21.01113235941521
          ],
          [
            105.8126828523401,
            21.011412479722573
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 151 Đường Láng",
        "diemDau": "11-10-3",
        "diemCuoi": "11-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.01113235941521,
        "to_latitude": 21.011412479722573,
        "to_longitude": 105.8126828523401,
        "from_longitude": 105.8129071520636,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3057,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8126828523401,
          21.011412479722573,
          105.81283070910702,
          21.011532665258628
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8126828523401,
            21.011412479722573
          ],
          [
            105.81283070910702,
            21.011512947328963
          ],
          [
            105.81280891615788,
            21.011532665258628
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 151 Đường Láng",
        "diemDau": "11-2",
        "diemCuoi": "11-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.011412479722573,
        "to_latitude": 21.011532665258628,
        "to_longitude": 105.81280891615788,
        "from_longitude": 105.8126828523401,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3058,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81258361060874,
          21.011412479722573,
          105.8126828523401,
          21.01153016139381
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8126828523401,
            21.011412479722573
          ],
          [
            105.81258361060874,
            21.01153016139381
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 151 Đường Láng",
        "diemDau": "11-3",
        "diemCuoi": "11-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.011412479722573,
        "to_latitude": 21.01153016139381,
        "to_longitude": 105.81258361060874,
        "from_longitude": 105.8126828523401,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3059,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8120184954653,
          21.010628726598704,
          105.81216467585011,
          21.010879114336113
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8120184954653,
            21.010628726598704
          ],
          [
            105.81216467585011,
            21.010879114336113
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 151 Đường Láng",
        "diemDau": "12-1",
        "diemCuoi": "11-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.010628726598704,
        "to_latitude": 21.010879114336113,
        "to_longitude": 105.81216467585011,
        "from_longitude": 105.8120184954653,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3060,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81216467585011,
          21.010712021474554,
          105.81226509828218,
          21.010879114336113
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81216467585011,
            21.010879114336113
          ],
          [
            105.81217926758055,
            21.01079590136634
          ],
          [
            105.81219536082699,
            21.010753335456847
          ],
          [
            105.81226509828218,
            21.010712021474554
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 151 Đường Láng",
        "diemDau": "11-3",
        "diemCuoi": "11-10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.010879114336113,
        "to_latitude": 21.010712021474554,
        "to_longitude": 105.81226509828218,
        "from_longitude": 105.81216467585011,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3061,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81204616299213,
          21.010879114336113,
          105.81216467585011,
          21.010980875141705
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81216467585011,
            21.010879114336113
          ],
          [
            105.81204616299213,
            21.010980875141705
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 151 Đường Láng",
        "diemDau": "11-3",
        "diemCuoi": "11-2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.010879114336113,
        "to_latitude": 21.010980875141705,
        "to_longitude": 105.81204616299213,
        "from_longitude": 105.81216467585011,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3062,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8119496034685,
          21.010980875141705,
          105.81204616299213,
          21.01107101456301
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81204616299213,
            21.010980875141705
          ],
          [
            105.8119496034685,
            21.01107101456301
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 151 Đường Láng",
        "diemDau": "11-2-1",
        "diemCuoi": "11-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.010980875141705,
        "to_latitude": 21.01107101456301,
        "to_longitude": 105.8119496034685,
        "from_longitude": 105.81204616299213,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3063,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81170820466393,
          21.01107101456301,
          105.8119496034685,
          21.011317645756332
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8119496034685,
            21.01107101456301
          ],
          [
            105.81170820466393,
            21.011317645756332
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 151 Đường Láng",
        "diemDau": "11-2",
        "diemCuoi": "11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.01107101456301,
        "to_latitude": 21.011317645756332,
        "to_longitude": 105.81170820466393,
        "from_longitude": 105.8119496034685,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3064,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81170820466393,
          21.011317645756332,
          105.81200157124127,
          21.011570223218328
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81170820466393,
            21.011317645756332
          ],
          [
            105.81177693623057,
            21.011342997416953
          ],
          [
            105.81200157124127,
            21.011570223218328
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 151 Đường Láng",
        "diemDau": "11-1",
        "diemCuoi": "11-3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.011317645756332,
        "to_latitude": 21.011570223218328,
        "to_longitude": 105.81200157124127,
        "from_longitude": 105.81170820466393,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3065,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81170820466393,
          21.011317645756318,
          105.81211187711774,
          21.011706996709115
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81170820466393,
            21.011317645756318
          ],
          [
            105.81200894730807,
            21.011604651341184
          ],
          [
            105.81205722706538,
            21.0116547285945
          ],
          [
            105.81211187711774,
            21.011706996709115
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 151 Đường Láng",
        "diemDau": "11-1",
        "diemCuoi": "11-3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.011317645756318,
        "to_latitude": 21.011706996709115,
        "to_longitude": 105.81211187711774,
        "from_longitude": 105.81170820466393,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3066,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8119496034685,
          21.01107101456301,
          105.81206728536688,
          21.011230949304107
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8119496034685,
            21.01107101456301
          ],
          [
            105.81197273748558,
            21.01113392433428
          ],
          [
            105.81206728536688,
            21.011230949304107
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 151 Đường Láng",
        "diemDau": "11-2",
        "diemCuoi": "11-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.01107101456301,
        "to_latitude": 21.011230949304107,
        "to_longitude": 105.81206728536688,
        "from_longitude": 105.8119496034685,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3067,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81206728536688,
          21.011230949304107,
          105.81230734307657,
          21.011445029981708
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81206728536688,
            21.011230949304107
          ],
          [
            105.81230734307657,
            21.011445029981708
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 151 Đường Láng",
        "diemDau": "11-4",
        "diemCuoi": "11-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.011230949304107,
        "to_latitude": 21.011445029981708,
        "to_longitude": 105.81230734307657,
        "from_longitude": 105.81206728536688,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3068,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81230734307657,
          21.011445029981708,
          105.81250180322769,
          21.011592757989895
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81230734307657,
            21.011445029981708
          ],
          [
            105.81245553510372,
            21.011584620433645
          ],
          [
            105.81250180322769,
            21.011592757989895
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 151 Đường Láng",
        "diemDau": "11-5",
        "diemCuoi": "11-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.011445029981708,
        "to_latitude": 21.011592757989895,
        "to_longitude": 105.81250180322769,
        "from_longitude": 105.81230734307657,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3069,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82339519247631,
          21.01544317877366,
          105.82341821206812,
          21.015515525606894
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82339519247631,
            21.01544317877366
          ],
          [
            105.82341821206812,
            21.015515525606894
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.01544317877366,
        "to_latitude": 21.015515525606894,
        "to_longitude": 105.82341821206812,
        "from_longitude": 105.82339519247631,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3070,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82319068952637,
          21.015515525606894,
          105.82341821206812,
          21.01563397021256
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82341821206812,
            21.015515525606894
          ],
          [
            105.82319068952637,
            21.01563397021256
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "HC1 1",
        "diemCuoi": "HC1 30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.015515525606894,
        "to_latitude": 21.01563397021256,
        "to_longitude": 105.82319068952637,
        "from_longitude": 105.82341821206812,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3071,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82311411680664,
          21.015349310567025,
          105.82319068952637,
          21.01563397021256
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82319068952637,
            21.01563397021256
          ],
          [
            105.82311411680664,
            21.015349310567025
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "HC1 30",
        "diemCuoi": "HC1 31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.01563397021256,
        "to_latitude": 21.015349310567025,
        "to_longitude": 105.82311411680664,
        "from_longitude": 105.82319068952637,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3072,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82296773780806,
          21.01563397021256,
          105.82319068952637,
          21.01564285658594
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82319068952637,
            21.01563397021256
          ],
          [
            105.82296773780806,
            21.01564285658594
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "HC1 30",
        "diemCuoi": "HC1 22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.01563397021256,
        "to_latitude": 21.01564285658594,
        "to_longitude": 105.82296773780806,
        "from_longitude": 105.82319068952637,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3073,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82295508694199,
          21.01564285658594,
          105.82296773780806,
          21.01587735649159
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82296773780806,
            21.01564285658594
          ],
          [
            105.82295508694199,
            21.01587735649159
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "HC1 22",
        "diemCuoi": "HC1 23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.01564285658594,
        "to_latitude": 21.01587735649159,
        "to_longitude": 105.82295508694199,
        "from_longitude": 105.82296773780806,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3074,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82294351964467,
          21.01545553939633,
          105.82296773780806,
          21.01564285658594
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82296773780806,
            21.01564285658594
          ],
          [
            105.82294351964467,
            21.01545553939633
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "HC1 22",
        "diemCuoi": "HC1 21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.01564285658594,
        "to_latitude": 21.01545553939633,
        "to_longitude": 105.82294351964467,
        "from_longitude": 105.82296773780806,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3075,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82294351964467,
          21.01523051380647,
          105.82297032077688,
          21.01545553939633
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82294351964467,
            21.01545553939633
          ],
          [
            105.82297032077688,
            21.01523051380647
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "HC1 21",
        "diemCuoi": "HC1 20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.01545553939633,
        "to_latitude": 21.01523051380647,
        "to_longitude": 105.82297032077688,
        "from_longitude": 105.82294351964467,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3076,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82257747639295,
          21.015632841404923,
          105.82296773780806,
          21.01564285658594
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82296773780806,
            21.01564285658594
          ],
          [
            105.82257747639295,
            21.015632841404923
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "HC1 22",
        "diemCuoi": "HC1 27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.01564285658594,
        "to_latitude": 21.015632841404923,
        "to_longitude": 105.82257747639295,
        "from_longitude": 105.82296773780806,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3077,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82270361002792,
          21.015872611586115,
          105.82295508694199,
          21.01587735649159
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82295508694199,
            21.01587735649159
          ],
          [
            105.82270361002792,
            21.015872611586115
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "HC1 23",
        "diemCuoi": "HC1 24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.01587735649159,
        "to_latitude": 21.015872611586115,
        "to_longitude": 105.82270361002792,
        "from_longitude": 105.82295508694199,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3078,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82247903700078,
          21.015872611586115,
          105.82270361002792,
          21.01587411746002
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82270361002792,
            21.015872611586115
          ],
          [
            105.82247903700078,
            21.01587411746002
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "HC1 24",
        "diemCuoi": "HC1 25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.015872611586115,
        "to_latitude": 21.01587411746002,
        "to_longitude": 105.82247903700078,
        "from_longitude": 105.82270361002792,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3079,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82237055587177,
          21.015630868680944,
          105.82257747639295,
          21.015632841404923
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82257747639295,
            21.015632841404923
          ],
          [
            105.82237055587177,
            21.015630868680944
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "HC1 27",
        "diemCuoi": "HC1 26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.015632841404923,
        "to_latitude": 21.015630868680944,
        "to_longitude": 105.82237055587177,
        "from_longitude": 105.82257747639295,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3080,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82257747639295,
          21.015402093382512,
          105.82263949806548,
          21.015632841404923
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82257747639295,
            21.015632841404923
          ],
          [
            105.82263949806548,
            21.015402093382512
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "HC1 27",
        "diemCuoi": "HC1 28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.015632841404923,
        "to_latitude": 21.015402093382512,
        "to_longitude": 105.82263949806548,
        "from_longitude": 105.82257747639295,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3081,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82263949806548,
          21.015175701484257,
          105.82264274452208,
          21.015402093382512
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82263949806548,
            21.015402093382512
          ],
          [
            105.82264274452208,
            21.015175701484257
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "HC1 28",
        "diemCuoi": "HC1 29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.015402093382512,
        "to_latitude": 21.015175701484257,
        "to_longitude": 105.82264274452208,
        "from_longitude": 105.82263949806548,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3082,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82268342365268,
          21.015078249674858,
          105.82297032077688,
          21.01523051380647
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82297032077688,
            21.01523051380647
          ],
          [
            105.82268342365268,
            21.015078249674858
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "HC1 20",
        "diemCuoi": "HC1 19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.01523051380647,
        "to_latitude": 21.015078249674858,
        "to_longitude": 105.82268342365268,
        "from_longitude": 105.82297032077688,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3083,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82250148505506,
          21.01491541475733,
          105.82268342365268,
          21.015078249674858
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82268342365268,
            21.015078249674858
          ],
          [
            105.82250148505506,
            21.01491541475733
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "HC1 19",
        "diemCuoi": "HC1 18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 1",
        "from_latitude": 21.015078249674858,
        "to_latitude": 21.01491541475733,
        "to_longitude": 105.82250148505506,
        "from_longitude": 105.82268342365268,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3084,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81395236815457,
          21.00923155530621,
          105.81413929185368,
          21.009393097863448
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81395236815457,
            21.00923155530621
          ],
          [
            105.8139843942443,
            21.009311095110725
          ],
          [
            105.81413929185368,
            21.009393097863448
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 414 Đường Láng",
        "diemDau": "03",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.00923155530621,
        "to_latitude": 21.009393097863448,
        "to_longitude": 105.81413929185368,
        "from_longitude": 105.81395236815457,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3085,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81413929185368,
          21.009393097863448,
          105.81432771699697,
          21.009559607134943
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81413929185368,
            21.009393097863448
          ],
          [
            105.81432771699697,
            21.009483864221014
          ],
          [
            105.81427474340754,
            21.009559607134943
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 414 Đường Láng",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.009393097863448,
        "to_latitude": 21.009559607134943,
        "to_longitude": 105.81427474340754,
        "from_longitude": 105.81413929185368,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3086,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81355003679565,
          21.009471929793925,
          105.8136383892839,
          21.009566179867026
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81355003679565,
            21.009471929793925
          ],
          [
            105.8136383892839,
            21.009481360321807
          ],
          [
            105.81360452643584,
            21.009566179867026
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 426 Đường Láng",
        "diemDau": "05",
        "diemCuoi": "5-1-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.009471929793925,
        "to_latitude": 21.009566179867026,
        "to_longitude": 105.81360452643584,
        "from_longitude": 105.81355003679565,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3087,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81350930800713,
          21.009566179867026,
          105.81360452643584,
          21.009637540923137
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81360452643584,
            21.009566179867026
          ],
          [
            105.8135579230157,
            21.00957901234127
          ],
          [
            105.81350930800713,
            21.009637540923137
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 426 Đường Láng",
        "diemDau": "5-1-6",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.009566179867026,
        "to_latitude": 21.009637540923137,
        "to_longitude": 105.81350930800713,
        "from_longitude": 105.81360452643584,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3088,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81331350675211,
          21.009637540923137,
          105.81350930800713,
          21.009825333013513
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81350930800713,
            21.009637540923137
          ],
          [
            105.81331350675211,
            21.009825333013513
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 426 Đường Láng",
        "diemDau": "5-1",
        "diemCuoi": "5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.009637540923137,
        "to_latitude": 21.009825333013513,
        "to_longitude": 105.81331350675211,
        "from_longitude": 105.81350930800713,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3089,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81311368218552,
          21.009825333013513,
          105.81331350675211,
          21.009995597638536
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81331350675211,
            21.009825333013513
          ],
          [
            105.81311368218552,
            21.009995597638536
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 426 Đường Láng",
        "diemDau": "5-2",
        "diemCuoi": "5-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.009825333013513,
        "to_latitude": 21.009995597638536,
        "to_longitude": 105.81311368218552,
        "from_longitude": 105.81331350675211,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3090,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81294604412078,
          21.009995597638536,
          105.81311368218552,
          21.01014583096933
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81311368218552,
            21.009995597638536
          ],
          [
            105.81294604412078,
            21.01014583096933
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 426 Đường Láng",
        "diemDau": "5-3",
        "diemCuoi": "5-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.009995597638536,
        "to_latitude": 21.01014583096933,
        "to_longitude": 105.81294604412078,
        "from_longitude": 105.81311368218552,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3091,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81277438274444,
          21.01014583096933,
          105.81294604412078,
          21.010364920972247
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81294604412078,
            21.01014583096933
          ],
          [
            105.81277438274444,
            21.010364920972247
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 426 Đường Láng",
        "diemDau": "5-4",
        "diemCuoi": "5-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.01014583096933,
        "to_latitude": 21.010364920972247,
        "to_longitude": 105.81277438274444,
        "from_longitude": 105.81294604412078,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3092,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8128565570002,
          21.010069424484012,
          105.81294604412078,
          21.01014583096933
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81294604412078,
            21.01014583096933
          ],
          [
            105.8128565570002,
            21.010069424484012
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 426 Đường Láng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.01014583096933,
        "to_latitude": 21.010069424484012,
        "to_longitude": 105.8128565570002,
        "from_longitude": 105.81294604412078,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3093,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81272107379596,
          21.010364920972247,
          105.81277438274444,
          21.010425327160434
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81277438274444,
            21.010364920972247
          ],
          [
            105.81272107379596,
            21.010425327160434
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 426 Đường Láng",
        "diemDau": "5-5",
        "diemCuoi": "Tủ Phân Đoạn",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.010364920972247,
        "to_latitude": 21.010425327160434,
        "to_longitude": 105.81272107379596,
        "from_longitude": 105.81277438274444,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3094,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81251152625995,
          21.010425327160434,
          105.81272107379596,
          21.010568987370863
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81272107379596,
            21.010425327160434
          ],
          [
            105.81251152625995,
            21.010568987370863
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 426 Đường Láng",
        "diemDau": "5-5",
        "diemCuoi": "5-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.010425327160434,
        "to_latitude": 21.010568987370863,
        "to_longitude": 105.81251152625995,
        "from_longitude": 105.81272107379596,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3095,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81251152625995,
          21.010487924219316,
          105.8127452136836,
          21.010568987370863
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81251152625995,
            21.010568987370863
          ],
          [
            105.81267815845413,
            21.010487924219316
          ],
          [
            105.8127452136836,
            21.010550521251936
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/56",
        "diemDau": "5-7",
        "diemCuoi": "5-7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.010568987370863,
        "to_latitude": 21.010550521251936,
        "to_longitude": 105.8127452136836,
        "from_longitude": 105.81251152625995,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3096,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81293296831537,
          21.01014583096933,
          105.81301075237582,
          21.010275094111098
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81294604412078,
            21.01014583096933
          ],
          [
            105.81293296831537,
            21.01022251250788
          ],
          [
            105.81301075237582,
            21.010275094111098
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/44",
        "diemDau": "5-4",
        "diemCuoi": "5-4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.01014583096933,
        "to_latitude": 21.010275094111098,
        "to_longitude": 105.81301075237582,
        "from_longitude": 105.81294604412078,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3097,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81272107379596,
          21.010425327160448,
          105.81300203524107,
          21.010557406921546
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81272107379596,
            21.010425327160448
          ],
          [
            105.81300203524107,
            21.010557406921546
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/48",
        "diemDau": "5-5",
        "diemCuoi": "5-5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.010425327160448,
        "to_latitude": 21.010557406921546,
        "to_longitude": 105.81300203524107,
        "from_longitude": 105.81272107379596,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3098,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81300203524107,
          21.010557406921546,
          105.81324075183791,
          21.01076522887338
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81300203524107,
            21.010557406921546
          ],
          [
            105.81324075183791,
            21.01076522887338
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/48",
        "diemDau": "5-5-1",
        "diemCuoi": "5-5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.010557406921546,
        "to_latitude": 21.01076522887338,
        "to_longitude": 105.81324075183791,
        "from_longitude": 105.81300203524107,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3099,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81324075183791,
          21.01076522887338,
          105.81345532855607,
          21.010910453680403
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81324075183791,
            21.01076522887338
          ],
          [
            105.81331652418417,
            21.010848482768306
          ],
          [
            105.81345532855607,
            21.010910453680403
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/48",
        "diemDau": "5-5-2",
        "diemCuoi": "5-5-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.01076522887338,
        "to_latitude": 21.010910453680403,
        "to_longitude": 105.81345532855607,
        "from_longitude": 105.81324075183791,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3100,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81345532855607,
          21.010910453680403,
          105.8136457653956,
          21.0110106086363
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81345532855607,
            21.010910453680403
          ],
          [
            105.81356060520548,
            21.010968668757066
          ],
          [
            105.8136457653956,
            21.0110106086363
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/48",
        "diemDau": "5-5-3",
        "diemCuoi": "5-5-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.010910453680403,
        "to_latitude": 21.0110106086363,
        "to_longitude": 105.8136457653956,
        "from_longitude": 105.81345532855607,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3101,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8136457653956,
          21.0110106086363,
          105.81389252862465,
          21.01109386239342
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8136457653956,
            21.0110106086363
          ],
          [
            105.81383419052992,
            21.01109386239342
          ],
          [
            105.81389252862465,
            21.0110894806175
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/48",
        "diemDau": "5-5-4",
        "diemCuoi": "5-5-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.0110106086363,
        "to_latitude": 21.0110894806175,
        "to_longitude": 105.81389252862465,
        "from_longitude": 105.8136457653956,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3102,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81378255805333,
          21.011030639620078,
          105.81389252862465,
          21.0110894806175
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81389252862465,
            21.0110894806175
          ],
          [
            105.81378255805333,
            21.011030639620078
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/48",
        "diemDau": "5-5-5",
        "diemCuoi": "5-5-5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.0110894806175,
        "to_latitude": 21.011030639620078,
        "to_longitude": 105.81378255805333,
        "from_longitude": 105.81389252862465,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3103,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81376780591074,
          21.010910453680403,
          105.81389252862465,
          21.0110894806175
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81389252862465,
            21.0110894806175
          ],
          [
            105.81383955494537,
            21.01102876171557
          ],
          [
            105.81386637704077,
            21.01097117263131
          ],
          [
            105.81376780591074,
            21.010910453680403
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/48",
        "diemDau": "5-5-5",
        "diemCuoi": "5-5-5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.0110894806175,
        "to_latitude": 21.010910453680403,
        "to_longitude": 105.81376780591074,
        "from_longitude": 105.81389252862465,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3104,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81376110038241,
          21.0110894806175,
          105.81389252862465,
          21.011264751536704
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81389252862465,
            21.0110894806175
          ],
          [
            105.81376110038241,
            21.011264751536704
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/48",
        "diemDau": "5-5-5",
        "diemCuoi": "5-5-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.0110894806175,
        "to_latitude": 21.011264751536704,
        "to_longitude": 105.81376110038241,
        "from_longitude": 105.81389252862465,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3105,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81389252862465,
          21.0110894806175,
          105.81408296546415,
          21.011228445434213
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81389252862465,
            21.0110894806175
          ],
          [
            105.81390392796713,
            21.011136428205727
          ],
          [
            105.81408296546415,
            21.011228445434213
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/48",
        "diemDau": "5-5-5",
        "diemCuoi": "5-5-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.0110894806175,
        "to_latitude": 21.011228445434213,
        "to_longitude": 105.81408296546415,
        "from_longitude": 105.81389252862465,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3106,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81389252862465,
          21.010965538914625,
          105.81405480227389,
          21.011116397236993
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81389252862465,
            21.0110894806175
          ],
          [
            105.8139629365644,
            21.011116397236993
          ],
          [
            105.81405480227389,
            21.010965538914625
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/48",
        "diemDau": "5-5-5",
        "diemCuoi": "5-5-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.0110894806175,
        "to_latitude": 21.010965538914625,
        "to_longitude": 105.81405480227389,
        "from_longitude": 105.81389252862465,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3107,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81408296546415,
          21.011228445434213,
          105.81430692991842,
          21.011333607912157
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81408296546415,
            21.011228445434213
          ],
          [
            105.81430692991842,
            21.011333607912157
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/48",
        "diemDau": "5-5-8",
        "diemCuoi": "5-5-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.011228445434213,
        "to_latitude": 21.011333607912157,
        "to_longitude": 105.81430692991842,
        "from_longitude": 105.81408296546415,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3108,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81430692991842,
          21.011333607912157,
          105.81454162320367,
          21.011409975855727
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81430692991842,
            21.011333607912157
          ],
          [
            105.81438136116978,
            21.011344249350934
          ],
          [
            105.81454162320367,
            21.011409975855727
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/48",
        "diemDau": "5-5-9",
        "diemCuoi": "5-5-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.011333607912157,
        "to_latitude": 21.011409975855727,
        "to_longitude": 105.81454162320367,
        "from_longitude": 105.81430692991842,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3109,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81444104037739,
          21.011409975855727,
          105.81454162320367,
          21.01158900219503
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81454162320367,
            21.011409975855727
          ],
          [
            105.81444104037739,
            21.01158900219503
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/48",
        "diemDau": "5-5-10",
        "diemCuoi": "5-5-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.011409975855727,
        "to_latitude": 21.01158900219503,
        "to_longitude": 105.81444104037739,
        "from_longitude": 105.81454162320367,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3110,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81434112803566,
          21.01158900219503,
          105.81444104037739,
          21.01181434972704
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81444104037739,
            21.01158900219503
          ],
          [
            105.81434112803566,
            21.01177992165424
          ],
          [
            105.8143471630615,
            21.01181434972704
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/48",
        "diemDau": "5-5-11",
        "diemCuoi": "5-5-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.01158900219503,
        "to_latitude": 21.01181434972704,
        "to_longitude": 105.8143471630615,
        "from_longitude": 105.81444104037739,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3111,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81411649308788,
          21.011714195309924,
          105.8143471630615,
          21.01181434972704
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8143471630615,
            21.01181434972704
          ],
          [
            105.81411649308788,
            21.011714195309924
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/48",
        "diemDau": "5-5-13",
        "diemCuoi": "5-5-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.01181434972704,
        "to_latitude": 21.011714195309924,
        "to_longitude": 105.81411649308788,
        "from_longitude": 105.8143471630615,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3112,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81422646365023,
          21.01148383989629,
          105.81444104037739,
          21.01158900219503
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81444104037739,
            21.01158900219503
          ],
          [
            105.81422646365023,
            21.01148383989629
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/48",
        "diemDau": "5-5-11",
        "diemCuoi": "5-5-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.01158900219503,
        "to_latitude": 21.01148383989629,
        "to_longitude": 105.81422646365023,
        "from_longitude": 105.81444104037739,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3113,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81311368218552,
          21.009995597638536,
          105.81334437223953,
          21.0101256531775
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81311368218552,
            21.009995597638536
          ],
          [
            105.81334437223953,
            21.0101256531775
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/20",
        "diemDau": "5-3",
        "diemCuoi": "5-3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.009995597638536,
        "to_latitude": 21.0101256531775,
        "to_longitude": 105.81334437223953,
        "from_longitude": 105.81311368218552,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3114,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81334437223953,
          21.0101256531775,
          105.81358744094449,
          21.010268914888385
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81334437223953,
            21.0101256531775
          ],
          [
            105.81358744094449,
            21.010268914888385
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/20",
        "diemDau": "5-3-1",
        "diemCuoi": "5-3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.0101256531775,
        "to_latitude": 21.010268914888385,
        "to_longitude": 105.81358744094449,
        "from_longitude": 105.81334437223953,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3115,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81358744094449,
          21.010268914888385,
          105.81380401570627,
          21.010425953128326
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81358744094449,
            21.010268914888385
          ],
          [
            105.81380401570627,
            21.010425953128326
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/20",
        "diemDau": "5-3-2",
        "diemCuoi": "5-3-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.010268914888385,
        "to_latitude": 21.010425953128326,
        "to_longitude": 105.81380401570627,
        "from_longitude": 105.81358744094449,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3116,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81350930800713,
          21.009637540923137,
          105.81366185865105,
          21.00977369021194
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81350930800713,
            21.009637540923137
          ],
          [
            105.81354853526166,
            21.009654129233237
          ],
          [
            105.81366185865105,
            21.00977369021194
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/6",
        "diemDau": "5-1",
        "diemCuoi": "5-1-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.009637540923137,
        "to_latitude": 21.00977369021194,
        "to_longitude": 105.81366185865105,
        "from_longitude": 105.81350930800713,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3117,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8136611880407,
          21.00977369021194,
          105.8137476893437,
          21.009895129013586
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81366185865105,
            21.00977369021194
          ],
          [
            105.8136611880407,
            21.00982439405283
          ],
          [
            105.8137476893437,
            21.009895129013586
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/6",
        "diemDau": "5-1-5",
        "diemCuoi": "5-1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.00977369021194,
        "to_latitude": 21.009895129013586,
        "to_longitude": 105.8137476893437,
        "from_longitude": 105.81366185865105,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3118,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8137476893437,
          21.009895129013586,
          105.81390459856844,
          21.00999528465117
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8137476893437,
            21.009895129013586
          ],
          [
            105.81390459856844,
            21.00999528465117
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/6",
        "diemDau": "5-1-1",
        "diemCuoi": "5-1-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.009895129013586,
        "to_latitude": 21.00999528465117,
        "to_longitude": 105.81390459856844,
        "from_longitude": 105.8137476893437,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3119,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81390459856844,
          21.009879479688085,
          105.81409503540797,
          21.00999528465117
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81390459856844,
            21.00999528465117
          ],
          [
            105.8140259684643,
            21.009879479688085
          ],
          [
            105.81409503540797,
            21.009907648471962
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/6",
        "diemDau": "5-1-2",
        "diemCuoi": "5-1-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.00999528465117,
        "to_latitude": 21.009907648471962,
        "to_longitude": 105.81409503540797,
        "from_longitude": 105.81390459856844,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3120,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81390459856844,
          21.009802484987958,
          105.81403736791458,
          21.00999528465117
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81390459856844,
            21.00999528465117
          ],
          [
            105.81403736791458,
            21.009802484987958
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/6",
        "diemDau": "5-1-2",
        "diemCuoi": "5-1-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.00999528465117,
        "to_latitude": 21.009802484987958,
        "to_longitude": 105.81403736791458,
        "from_longitude": 105.81390459856844,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3121,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81390459856844,
          21.00999528465117,
          105.81419226542516,
          21.0102116745229
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81390459856844,
            21.00999528465117
          ],
          [
            105.81419226542516,
            21.010189961980277
          ],
          [
            105.81417866470939,
            21.0102116745229
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH4",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 426/6",
        "diemDau": "5-1-2",
        "diemCuoi": "5-1-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Hạ 4",
        "from_latitude": 21.00999528465117,
        "to_latitude": 21.0102116745229,
        "to_longitude": 105.81417866470939,
        "from_longitude": 105.81390459856844,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3122,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80514048058532,
          21.015038034097444,
          105.80538992602212,
          21.01522331558692
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80514048058532,
            21.01522331558692
          ],
          [
            105.80538992602212,
            21.015038034097444
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "02",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.01522331558692,
        "to_latitude": 21.015038034097444,
        "to_longitude": 105.80538992602212,
        "from_longitude": 105.80514048058532,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3123,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80538992602212,
          21.01480518000803,
          105.80562864261896,
          21.015038034097444
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80538992602212,
            21.015038034097444
          ],
          [
            105.80562864261896,
            21.01480518000803
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.015038034097444,
        "to_latitude": 21.01480518000803,
        "to_longitude": 105.80562864261896,
        "from_longitude": 105.80538992602212,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3124,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80562864261896,
          21.014614890373895,
          105.80582176167518,
          21.01480518000803
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80562864261896,
            21.01480518000803
          ],
          [
            105.80582176167518,
            21.014614890373895
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "04",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.01480518000803,
        "to_latitude": 21.014614890373895,
        "to_longitude": 105.80582176167518,
        "from_longitude": 105.80562864261896,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3125,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8057493420392,
          21.014440875298398,
          105.80589082854699,
          21.014614890373895
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80582176167518,
            21.014614890373895
          ],
          [
            105.80589082854699,
            21.014550417186722
          ],
          [
            105.8057493420392,
            21.014440875298398
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.014614890373895,
        "to_latitude": 21.014440875298398,
        "to_longitude": 105.8057493420392,
        "from_longitude": 105.80582176167518,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3126,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80559913834279,
          21.014440875298398,
          105.8057493420392,
          21.014573577461096
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8057493420392,
            21.014440875298398
          ],
          [
            105.80559913834279,
            21.014573577461096
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.014440875298398,
        "to_latitude": 21.014573577461096,
        "to_longitude": 105.80559913834279,
        "from_longitude": 105.8057493420392,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3127,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80537919720012,
          21.014573577461096,
          105.80559913834279,
          21.014773882387324
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80559913834279,
            21.014573577461096
          ],
          [
            105.80537919720012,
            21.014773882387324
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.014573577461096,
        "to_latitude": 21.014773882387324,
        "to_longitude": 105.80537919720012,
        "from_longitude": 105.80559913834279,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3128,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80514852722652,
          21.014773882387324,
          105.80537919720012,
          21.015014247944016
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80537919720012,
            21.014773882387324
          ],
          [
            105.80514852722652,
            21.015014247944016
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "02",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.014773882387324,
        "to_latitude": 21.015014247944016,
        "to_longitude": 105.80514852722652,
        "from_longitude": 105.80537919720012,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3129,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8057493420392,
          21.014379531806863,
          105.80607925373519,
          21.014530386675606
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8057493420392,
            21.014440875298398
          ],
          [
            105.80591228621792,
            21.014530386675606
          ],
          [
            105.80607925373519,
            21.014379531806863
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.014440875298398,
        "to_latitude": 21.014379531806863,
        "to_longitude": 105.80607925373519,
        "from_longitude": 105.8057493420392,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3130,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80607925373519,
          21.014144172868434,
          105.80634479242742,
          21.014379531806863
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80607925373519,
            21.014379531806863
          ],
          [
            105.80634479242742,
            21.014144172868434
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.014379531806863,
        "to_latitude": 21.014144172868434,
        "to_longitude": 105.80634479242742,
        "from_longitude": 105.80607925373519,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3131,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80634479242742,
          21.01394136327218,
          105.8065942378642,
          21.014144172868434
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80634479242742,
            21.014144172868434
          ],
          [
            105.8065942378642,
            21.01394136327218
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.014144172868434,
        "to_latitude": 21.01394136327218,
        "to_longitude": 105.8065942378642,
        "from_longitude": 105.80634479242742,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3132,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8065942378642,
          21.013753576362674,
          105.80682222563007,
          21.01394136327218
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8065942378642,
            21.01394136327218
          ],
          [
            105.80682222563007,
            21.013753576362674
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.01394136327218,
        "to_latitude": 21.013753576362674,
        "to_longitude": 105.80682222563007,
        "from_longitude": 105.8065942378642,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3133,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80682222563007,
          21.013560781556997,
          105.80709312874677,
          21.013753576362674
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80682222563007,
            21.013753576362674
          ],
          [
            105.80709312874677,
            21.013560781556997
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.013753576362674,
        "to_latitude": 21.013560781556997,
        "to_longitude": 105.80709312874677,
        "from_longitude": 105.80682222563007,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3134,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80709312874677,
          21.013375498003022,
          105.80734793859905,
          21.013560781556997
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80709312874677,
            21.013560781556997
          ],
          [
            105.80734793859905,
            21.013375498003022
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.013560781556997,
        "to_latitude": 21.013375498003022,
        "to_longitude": 105.80734793859905,
        "from_longitude": 105.80709312874677,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3135,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80734793859905,
          21.013195221890268,
          105.80760006624358,
          21.013375498003022
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80734793859905,
            21.013375498003022
          ],
          [
            105.80760006624358,
            21.013195221890268
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.013375498003022,
        "to_latitude": 21.013195221890268,
        "to_longitude": 105.80760006624358,
        "from_longitude": 105.80734793859905,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3136,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80760006624358,
          21.013007434042205,
          105.80786560493578,
          21.013195221890268
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80760006624358,
            21.013195221890268
          ],
          [
            105.80786560493578,
            21.013007434042205
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.013195221890268,
        "to_latitude": 21.013007434042205,
        "to_longitude": 105.80786560493578,
        "from_longitude": 105.80760006624358,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3137,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80786560493578,
          21.012809630586265,
          105.80813650805246,
          21.013007434042205
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80786560493578,
            21.013007434042205
          ],
          [
            105.80813650805246,
            21.012809630586265
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.013007434042205,
        "to_latitude": 21.012809630586265,
        "to_longitude": 105.80813650805246,
        "from_longitude": 105.80786560493578,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3138,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80813650805246,
          21.012620590329494,
          105.8083926590176,
          21.012809630586265
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80813650805246,
            21.012809630586265
          ],
          [
            105.8083926590176,
            21.012620590329494
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.012809630586265,
        "to_latitude": 21.012620590329494,
        "to_longitude": 105.8083926590176,
        "from_longitude": 105.80813650805246,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3139,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8057493420392,
          21.014365760815192,
          105.80582444390089,
          21.014440875298398
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8057493420392,
            21.014440875298398
          ],
          [
            105.80582444390089,
            21.014365760815192
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.014440875298398,
        "to_latitude": 21.014365760815192,
        "to_longitude": 105.80582444390089,
        "from_longitude": 105.8057493420392,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3140,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80582444390089,
          21.014127898033824,
          105.80607925375317,
          21.014365760815192
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80582444390089,
            21.014365760815192
          ],
          [
            105.80607925375317,
            21.014127898033824
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.014365760815192,
        "to_latitude": 21.014127898033824,
        "to_longitude": 105.80607925375317,
        "from_longitude": 105.80582444390089,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3141,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80607925375317,
          21.013892538698304,
          105.80633138139768,
          21.014127898033824
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80607925375317,
            21.014127898033824
          ],
          [
            105.80633138139768,
            21.013892538698304
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.014127898033824,
        "to_latitude": 21.013892538698304,
        "to_longitude": 105.80633138139768,
        "from_longitude": 105.80607925375317,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3142,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80633138139768,
          21.013682217275594,
          105.80656741579577,
          21.013892538698304
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80633138139768,
            21.013892538698304
          ],
          [
            105.80656741579577,
            21.013682217275594
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.013892538698304,
        "to_latitude": 21.013682217275594,
        "to_longitude": 105.80656741579577,
        "from_longitude": 105.80633138139768,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3143,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80656741579577,
          21.013481910883307,
          105.80681417902481,
          21.013682217275594
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80656741579577,
            21.013682217275594
          ],
          [
            105.80681417902481,
            21.013481910883307
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.013682217275594,
        "to_latitude": 21.013481910883307,
        "to_longitude": 105.80681417902481,
        "from_longitude": 105.80656741579577,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3144,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80681417902481,
          21.01327033696381,
          105.80706630667832,
          21.013481910883307
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80681417902481,
            21.013481910883307
          ],
          [
            105.80706630667832,
            21.01327033696381
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.013481910883307,
        "to_latitude": 21.01327033696381,
        "to_longitude": 105.80706630667832,
        "from_longitude": 105.80681417902481,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3145,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80706630667832,
          21.013072533856523,
          105.8073130699074,
          21.01327033696381
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80706630667832,
            21.01327033696381
          ],
          [
            105.8073130699074,
            21.013072533856523
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.01327033696381,
        "to_latitude": 21.013072533856523,
        "to_longitude": 105.8073130699074,
        "from_longitude": 105.80706630667832,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3146,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8073130699074,
          21.01287222664462,
          105.80755983313642,
          21.013072533856523
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8073130699074,
            21.013072533856523
          ],
          [
            105.80755983313642,
            21.01287222664462
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.013072533856523,
        "to_latitude": 21.01287222664462,
        "to_longitude": 105.80755983313642,
        "from_longitude": 105.8073130699074,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3147,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80755983313642,
          21.012669415319728,
          105.80783878287633,
          21.01287222664462
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80755983313642,
            21.01287222664462
          ],
          [
            105.80783878287633,
            21.012669415319728
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.01287222664462,
        "to_latitude": 21.012669415319728,
        "to_longitude": 105.80783878287633,
        "from_longitude": 105.80755983313642,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3148,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80783878287633,
          21.012489138354418,
          105.80809895714408,
          21.012669415319728
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80783878287633,
            21.012669415319728
          ],
          [
            105.80809895714408,
            21.012489138354418
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.012669415319728,
        "to_latitude": 21.012489138354418,
        "to_longitude": 105.80809895714408,
        "from_longitude": 105.80783878287633,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3149,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80809895714408,
          21.012303853468477,
          105.80838327129949,
          21.012489138354418
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80809895714408,
            21.012489138354418
          ],
          [
            105.80838327129949,
            21.012303853468477
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.012489138354418,
        "to_latitude": 21.012303853468477,
        "to_longitude": 105.80838327129949,
        "from_longitude": 105.80809895714408,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3150,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80838327129949,
          21.012143606894835,
          105.80863271674527,
          21.012303853468477
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80838327129949,
            21.012303853468477
          ],
          [
            105.80863271674527,
            21.012143606894835
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.012303853468477,
        "to_latitude": 21.012143606894835,
        "to_longitude": 105.80863271674527,
        "from_longitude": 105.80838327129949,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3151,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80863271674527,
          21.01197584857886,
          105.80887411554984,
          21.012143606894835
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80863271674527,
            21.012143606894835
          ],
          [
            105.80887411554984,
            21.01197584857886
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.012143606894835,
        "to_latitude": 21.01197584857886,
        "to_longitude": 105.80887411554984,
        "from_longitude": 105.80863271674527,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3152,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80887411554984,
          21.011810593934374,
          105.80912624320337,
          21.01197584857886
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80887411554984,
            21.01197584857886
          ],
          [
            105.80912624320337,
            21.011810593934374
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.01197584857886,
        "to_latitude": 21.011810593934374,
        "to_longitude": 105.80912624320337,
        "from_longitude": 105.80887411554984,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3153,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80912624320337,
          21.011622804342302,
          105.80939982851879,
          21.011810593934374
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80912624320337,
            21.011810593934374
          ],
          [
            105.80939982851879,
            21.011622804342302
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.011810593934374,
        "to_latitude": 21.011622804342302,
        "to_longitude": 105.80939982851879,
        "from_longitude": 105.80912624320337,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3154,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80939982851879,
          21.0114450299784,
          105.8096841426742,
          21.011622804342302
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80939982851879,
            21.011622804342302
          ],
          [
            105.8096841426742,
            21.0114450299784
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.011622804342302,
        "to_latitude": 21.0114450299784,
        "to_longitude": 105.8096841426742,
        "from_longitude": 105.80939982851879,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3155,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8096841426742,
          21.01125723992611,
          105.80996309241412,
          21.0114450299784
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8096841426742,
            21.0114450299784
          ],
          [
            105.80996309241412,
            21.01125723992611
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.0114450299784,
        "to_latitude": 21.01125723992611,
        "to_longitude": 105.80996309241412,
        "from_longitude": 105.8096841426742,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3156,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8083926590176,
          21.012445321003387,
          105.80865551550208,
          21.012620590329494
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8083926590176,
            21.012620590329494
          ],
          [
            105.80865551550208,
            21.012445321003387
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.012620590329494,
        "to_latitude": 21.012445321003387,
        "to_longitude": 105.80865551550208,
        "from_longitude": 105.8083926590176,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3157,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80865551550208,
          21.012270051470495,
          105.80890496093888,
          21.012445321003387
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80865551550208,
            21.012445321003387
          ],
          [
            105.80890496093888,
            21.012270051470495
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.012445321003387,
        "to_latitude": 21.012270051470495,
        "to_longitude": 105.80890496093888,
        "from_longitude": 105.80865551550208,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3158,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80890496093888,
          21.012103545225173,
          105.80915306527177,
          21.012270051470495
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80890496093888,
            21.012270051470495
          ],
          [
            105.80915306527177,
            21.012103545225173
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.012270051470495,
        "to_latitude": 21.012103545225173,
        "to_longitude": 105.80915306527177,
        "from_longitude": 105.80890496093888,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3159,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80915306527177,
          21.011950810008212,
          105.80938105304665,
          21.012103545225173
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80915306527177,
            21.012103545225173
          ],
          [
            105.80938105304665,
            21.011950810008212
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.012103545225173,
        "to_latitude": 21.011950810008212,
        "to_longitude": 105.80938105304665,
        "from_longitude": 105.80915306527177,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3160,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80938105304665,
          21.011745493569297,
          105.809676096033,
          21.011950810008212
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80938105304665,
            21.011950810008212
          ],
          [
            105.809676096033,
            21.011745493569297
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.011950810008212,
        "to_latitude": 21.011745493569297,
        "to_longitude": 105.809676096033,
        "from_longitude": 105.80938105304665,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3161,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.809676096033,
          21.01156271162393,
          105.80993761141362,
          21.011745493569297
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.809676096033,
            21.011745493569297
          ],
          [
            105.80993761141362,
            21.01156271162393
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.011745493569297,
        "to_latitude": 21.01156271162393,
        "to_longitude": 105.80993761141362,
        "from_longitude": 105.809676096033,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3162,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80993761141362,
          21.01138493718758,
          105.81019778569036,
          21.01156271162393
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80993761141362,
            21.01156271162393
          ],
          [
            105.81019778569036,
            21.01138493718758
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.01156271162393,
        "to_latitude": 21.01138493718758,
        "to_longitude": 105.81019778569036,
        "from_longitude": 105.80993761141362,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3163,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81019778569036,
          21.011200902865244,
          105.81045527775935,
          21.01138493718758
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81019778569036,
            21.01138493718758
          ],
          [
            105.81045527775935,
            21.011200902865244
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.01138493718758,
        "to_latitude": 21.011200902865244,
        "to_longitude": 105.81045527775935,
        "from_longitude": 105.81019778569036,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3164,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81045527775935,
          21.011026883807663,
          105.81070740540389,
          21.011200902865244
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81045527775935,
            21.011200902865244
          ],
          [
            105.81070740540389,
            21.011026883807663
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.011200902865244,
        "to_latitude": 21.011026883807663,
        "to_longitude": 105.81070740540389,
        "from_longitude": 105.81045527775935,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3165,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80996309241412,
          21.011081968998084,
          105.81021790226639,
          21.01125723992611
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80996309241412,
            21.01125723992611
          ],
          [
            105.81021790226639,
            21.011081968998084
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.01125723992611,
        "to_latitude": 21.011081968998084,
        "to_longitude": 105.81021790226639,
        "from_longitude": 105.80996309241412,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3166,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81021790226639,
          21.01081655834356,
          105.81062023362531,
          21.011081968998084
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81021790226639,
            21.011081968998084
          ],
          [
            105.81062023362531,
            21.01081655834356
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.011081968998084,
        "to_latitude": 21.01081655834356,
        "to_longitude": 105.81062023362531,
        "from_longitude": 105.81021790226639,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3167,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81062023362531,
          21.010390898761187,
          105.81128005703528,
          21.01081655834356
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81062023362531,
            21.01081655834356
          ],
          [
            105.81128005703528,
            21.010390898761187
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 90,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.01081655834356,
        "to_latitude": 21.010390898761187,
        "to_longitude": 105.81128005703528,
        "from_longitude": 105.81062023362531,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3168,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81070740540389,
          21.01056178871058,
          105.8112800570263,
          21.011026883807663
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81070740540389,
            21.011026883807663
          ],
          [
            105.81071344035783,
            21.01083721532208
          ],
          [
            105.81115868704961,
            21.01056178871058
          ],
          [
            105.8112800570263,
            21.01062751556097
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 90,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.011026883807663,
        "to_latitude": 21.01062751556097,
        "to_longitude": 105.8112800570263,
        "from_longitude": 105.81070740540389,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3169,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8112800570263,
          21.010469492859848,
          105.81154201944054,
          21.01062751556097
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8112800570263,
            21.01062751556097
          ],
          [
            105.81154201944054,
            21.010469492859848
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.01062751556097,
        "to_latitude": 21.010469492859848,
        "to_longitude": 105.81154201944054,
        "from_longitude": 105.8112800570263,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3170,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81128005703528,
          21.010208115155212,
          105.8115590067662,
          21.010390898761187
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81128005703528,
            21.010390898761187
          ],
          [
            105.8115590067662,
            21.010208115155212
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.010390898761187,
        "to_latitude": 21.010208115155212,
        "to_longitude": 105.8115590067662,
        "from_longitude": 105.81128005703528,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3171,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8115590067662,
          21.01004035466261,
          105.81181918103397,
          21.010208115155212
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8115590067662,
            21.010208115155212
          ],
          [
            105.81181918103397,
            21.01004035466261
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.010208115155212,
        "to_latitude": 21.01004035466261,
        "to_longitude": 105.81181918103397,
        "from_longitude": 105.8115590067662,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3172,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81181918103397,
          21.009882609549127,
          105.81207667310296,
          21.01004035466261
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81181918103397,
            21.01004035466261
          ],
          [
            105.81207667310296,
            21.009882609549127
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.01004035466261,
        "to_latitude": 21.009882609549127,
        "to_longitude": 105.81207667310296,
        "from_longitude": 105.81181918103397,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3173,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81207667310296,
          21.009717352585774,
          105.81234489400292,
          21.009882609549127
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81207667310296,
            21.009882609549127
          ],
          [
            105.81234489400292,
            21.009717352585774
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.009882609549127,
        "to_latitude": 21.009717352585774,
        "to_longitude": 105.81234489400292,
        "from_longitude": 105.81207667310296,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3174,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81234489400292,
          21.009554599336532,
          105.81260506827967,
          21.009717352585774
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81234489400292,
            21.009717352585774
          ],
          [
            105.81260506827967,
            21.009554599336532
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.009717352585774,
        "to_latitude": 21.009554599336532,
        "to_longitude": 105.81260506827967,
        "from_longitude": 105.81234489400292,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3175,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81260506827967,
          21.009406869310002,
          105.81285451371646,
          21.009554599336532
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81260506827967,
            21.009554599336532
          ],
          [
            105.81285451371646,
            21.009406869310002
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.009554599336532,
        "to_latitude": 21.009406869310002,
        "to_longitude": 105.81285451371646,
        "from_longitude": 105.81260506827967,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3176,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81285451371646,
          21.009244115722044,
          105.81311200578546,
          21.009406869310002
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81285451371646,
            21.009406869310002
          ],
          [
            105.81311200578546,
            21.009244115722044
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.009406869310002,
        "to_latitude": 21.009244115722044,
        "to_longitude": 105.81311200578546,
        "from_longitude": 105.81285451371646,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3177,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81311200578546,
          21.009086369767473,
          105.81337486226994,
          21.009244115722044
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81311200578546,
            21.009244115722044
          ],
          [
            105.81337486226994,
            21.009086369767473
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.009244115722044,
        "to_latitude": 21.009086369767473,
        "to_longitude": 105.81337486226994,
        "from_longitude": 105.81311200578546,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3178,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81154201944054,
          21.010311469991315,
          105.81180398185477,
          21.010469492859848
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81154201944054,
            21.010469492859848
          ],
          [
            105.81180398185477,
            21.010311469991315
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.010469492859848,
        "to_latitude": 21.010311469991315,
        "to_longitude": 105.81180398185477,
        "from_longitude": 105.81154201944054,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3179,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81180398185477,
          21.010153446955382,
          105.812065944269,
          21.010311469991315
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81180398185477,
            21.010311469991315
          ],
          [
            105.812065944269,
            21.010153446955382
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.010311469991315,
        "to_latitude": 21.010153446955382,
        "to_longitude": 105.812065944269,
        "from_longitude": 105.81180398185477,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3180,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.812065944269,
          21.00999542375209,
          105.81232790668324,
          21.010153446955382
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.812065944269,
            21.010153446955382
          ],
          [
            105.81232790668324,
            21.00999542375209
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.010153446955382,
        "to_latitude": 21.00999542375209,
        "to_longitude": 105.81232790668324,
        "from_longitude": 105.812065944269,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3181,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81232790668324,
          21.00983740038141,
          105.81258986909747,
          21.00999542375209
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81232790668324,
            21.00999542375209
          ],
          [
            105.81258986909747,
            21.00983740038141
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.00999542375209,
        "to_latitude": 21.00983740038141,
        "to_longitude": 105.81258986909747,
        "from_longitude": 105.81232790668324,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3182,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81258986909747,
          21.00967937684332,
          105.81285183151171,
          21.00983740038141
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81258986909747,
            21.00983740038141
          ],
          [
            105.81285183151171,
            21.00967937684332
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.00983740038141,
        "to_latitude": 21.00967937684332,
        "to_longitude": 105.81285183151171,
        "from_longitude": 105.81258986909747,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3183,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81285183151171,
          21.009521353137856,
          105.81311379392594,
          21.00967937684332
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81285183151171,
            21.00967937684332
          ],
          [
            105.81311379392594,
            21.009521353137856
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.00967937684332,
        "to_latitude": 21.009521353137856,
        "to_longitude": 105.81311379392594,
        "from_longitude": 105.81285183151171,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3184,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81311379392594,
          21.009363329265017,
          105.81337575634016,
          21.009521353137856
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81311379392594,
            21.009521353137856
          ],
          [
            105.81337575634016,
            21.009363329265017
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.009521353137856,
        "to_latitude": 21.009363329265017,
        "to_longitude": 105.81337575634016,
        "from_longitude": 105.81311379392594,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3185,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81337575634016,
          21.0092053052248,
          105.8136377187544,
          21.009363329265017
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81337575634016,
            21.009363329265017
          ],
          [
            105.8136377187544,
            21.0092053052248
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.009363329265017,
        "to_latitude": 21.0092053052248,
        "to_longitude": 105.8136377187544,
        "from_longitude": 105.81337575634016,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3186,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82408998083241,
          21.014999443314068,
          105.82437161278006,
          21.01516344252363
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82437161278006,
            21.014999443314068
          ],
          [
            105.82408998083241,
            21.01516344252363
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.014999443314068,
        "to_latitude": 21.01516344252363,
        "to_longitude": 105.82408998083241,
        "from_longitude": 105.82437161278006,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3187,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82384992313172,
          21.01516344252363,
          105.82408998083241,
          21.015276113632492
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82408998083241,
            21.01516344252363
          ],
          [
            105.82384992313172,
            21.015276113632492
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.01516344252363,
        "to_latitude": 21.015276113632492,
        "to_longitude": 105.82384992313172,
        "from_longitude": 105.82408998083241,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3188,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8237225182011,
          21.015251075615847,
          105.82384992313172,
          21.015276113632492
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82384992313172,
            21.015276113632492
          ],
          [
            105.8237225182011,
            21.015251075615847
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.015276113632492,
        "to_latitude": 21.015251075615847,
        "to_longitude": 105.8237225182011,
        "from_longitude": 105.82384992313172,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3189,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82359645437433,
          21.01503825230381,
          105.8237225182011,
          21.015251075615847
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8237225182011,
            21.015251075615847
          ],
          [
            105.82359645437433,
            21.01503825230381
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.015251075615847,
        "to_latitude": 21.01503825230381,
        "to_longitude": 105.82359645437433,
        "from_longitude": 105.8237225182011,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3190,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82359645437433,
          21.014852609219883,
          105.82377832747312,
          21.01503825230381
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82359645437433,
            21.01503825230381
          ],
          [
            105.82377832747312,
            21.014852609219883
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.01503825230381,
        "to_latitude": 21.014852609219883,
        "to_longitude": 105.82377832747312,
        "from_longitude": 105.82359645437433,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3191,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82349375989601,
          21.0149216610353,
          105.82359645437433,
          21.01503825230381
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82359645437433,
            21.01503825230381
          ],
          [
            105.82349375989601,
            21.0149216610353
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "21",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.01503825230381,
        "to_latitude": 21.0149216610353,
        "to_longitude": 105.82349375989601,
        "from_longitude": 105.82359645437433,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3192,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82326117824486,
          21.014767841068817,
          105.82349375989601,
          21.0149216610353
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82349375989601,
            21.0149216610353
          ],
          [
            105.82326117824486,
            21.014767841068817
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.0149216610353,
        "to_latitude": 21.014767841068817,
        "to_longitude": 105.82326117824486,
        "from_longitude": 105.82349375989601,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3193,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82311231565232,
          21.014767841068817,
          105.82326117824486,
          21.014921825304306
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82326117824486,
            21.014767841068817
          ],
          [
            105.82311231565232,
            21.014921825304306
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.014767841068817,
        "to_latitude": 21.014921825304306,
        "to_longitude": 105.82311231565232,
        "from_longitude": 105.82326117824486,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3194,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82309085797242,
          21.014921825304306,
          105.82311231565232,
          21.015044511816868
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82311231565232,
            21.014921825304306
          ],
          [
            105.82309085797242,
            21.015044511816868
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.014921825304306,
        "to_latitude": 21.015044511816868,
        "to_longitude": 105.82309085797242,
        "from_longitude": 105.82311231565232,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3195,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82309085797242,
          21.015044511816868,
          105.82327861260418,
          21.01519098435841
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82309085797242,
            21.015044511816868
          ],
          [
            105.82327861260418,
            21.01519098435841
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.015044511816868,
        "to_latitude": 21.01519098435841,
        "to_longitude": 105.82327861260418,
        "from_longitude": 105.82309085797242,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3196,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82327861260418,
          21.01519098435841,
          105.82336846660846,
          21.01530866304852
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82327861260418,
            21.01519098435841
          ],
          [
            105.82336846660846,
            21.01530866304852
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.01519098435841,
        "to_latitude": 21.01530866304852,
        "to_longitude": 105.82336846660846,
        "from_longitude": 105.82327861260418,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3197,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82408998083241,
          21.01516344252363,
          105.82420785727892,
          21.01521455228174
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82408998083241,
            21.01516344252363
          ],
          [
            105.82415421309715,
            21.015175743337874
          ],
          [
            105.82420785727892,
            21.01521455228174
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "18",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.01516344252363,
        "to_latitude": 21.01521455228174,
        "to_longitude": 105.82420785727892,
        "from_longitude": 105.82408998083241,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3198,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82420785727892,
          21.01521455228174,
          105.82431240801307,
          21.015390559663796
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82420785727892,
            21.01521455228174
          ],
          [
            105.82431240801307,
            21.015390559663796
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "07",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.01521455228174,
        "to_latitude": 21.015390559663796,
        "to_longitude": 105.82431240801307,
        "from_longitude": 105.82420785727892,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3199,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82431240801307,
          21.015390559663796,
          105.82441335608857,
          21.01557782756755
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82431240801307,
            21.015390559663796
          ],
          [
            105.82441335608857,
            21.01557782756755
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "06",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.015390559663796,
        "to_latitude": 21.01557782756755,
        "to_longitude": 105.82441335608857,
        "from_longitude": 105.82431240801307,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3200,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82433526220956,
          21.01557782756755,
          105.82447339597115,
          21.01572657901466
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82441335608857,
            21.01557782756755
          ],
          [
            105.82447339597115,
            21.015658976564122
          ],
          [
            105.82433526220956,
            21.01572657901466
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "05",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.01557782756755,
        "to_latitude": 21.01572657901466,
        "to_longitude": 105.82433526220956,
        "from_longitude": 105.82441335608857,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3201,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82441335608857,
          21.01549912940455,
          105.82455133561858,
          21.01557782756755
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82441335608857,
            21.01557782756755
          ],
          [
            105.82455133561858,
            21.01549912940455
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "05",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.01557782756755,
        "to_latitude": 21.01549912940455,
        "to_longitude": 105.82455133561858,
        "from_longitude": 105.82441335608857,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3202,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82455133561858,
          21.01537968842695,
          105.82479563967881,
          21.01549912940455
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82455133561858,
            21.01549912940455
          ],
          [
            105.82479563967881,
            21.01537968842695
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.01549912940455,
        "to_latitude": 21.01537968842695,
        "to_longitude": 105.82479563967881,
        "from_longitude": 105.82455133561858,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3203,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82479563967881,
          21.01523225647875,
          105.82505706197132,
          21.01537968842695
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82479563967881,
            21.01537968842695
          ],
          [
            105.82505706197132,
            21.01523225647875
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.01537968842695,
        "to_latitude": 21.01523225647875,
        "to_longitude": 105.82505706197132,
        "from_longitude": 105.82479563967881,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3204,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82405899467737,
          21.015002980820874,
          105.82420785727892,
          21.01521455228174
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82420785727892,
            21.01521455228174
          ],
          [
            105.82405899467737,
            21.015002980820874
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.01521455228174,
        "to_latitude": 21.015002980820874,
        "to_longitude": 105.82405899467737,
        "from_longitude": 105.82420785727892,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3205,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82395036521892,
          21.0148051800114,
          105.82405899467737,
          21.015002980820874
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82405899467737,
            21.015002980820874
          ],
          [
            105.82395036521892,
            21.0148051800114
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.015002980820874,
        "to_latitude": 21.0148051800114,
        "to_longitude": 105.82395036521892,
        "from_longitude": 105.82405899467737,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3206,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8237395193566,
          21.01459086253541,
          105.82395036521892,
          21.0148051800114
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82395036521892,
            21.0148051800114
          ],
          [
            105.8237395193566,
            21.01459086253541
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.0148051800114,
        "to_latitude": 21.01459086253541,
        "to_longitude": 105.8237395193566,
        "from_longitude": 105.82395036521892,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3207,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8260975406088,
          21.020916540495307,
          105.82652338611577,
          21.02113495930773
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8260975406088,
            21.02113495930773
          ],
          [
            105.82652338611577,
            21.020916540495307
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 55,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.02113495930773,
        "to_latitude": 21.020916540495307,
        "to_longitude": 105.82652338611577,
        "from_longitude": 105.8260975406088,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3208,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82652338611577,
          21.020731266079498,
          105.82694181072117,
          21.020916540495307
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82652338611577,
            21.020916540495307
          ],
          [
            105.82694181072117,
            21.020731266079498
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.020916540495307,
        "to_latitude": 21.020731266079498,
        "to_longitude": 105.82694181072117,
        "from_longitude": 105.82652338611577,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3209,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82694181072117,
          21.020550998859484,
          105.8273173199847,
          21.020731266079498
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82694181072117,
            21.020731266079498
          ],
          [
            105.8273173199847,
            21.020550998859484
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.020731266079498,
        "to_latitude": 21.020550998859484,
        "to_longitude": 105.8273173199847,
        "from_longitude": 105.82694181072117,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3210,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8273173199847,
          21.020385753716752,
          105.82759626972462,
          21.020550998859484
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8273173199847,
            21.020550998859484
          ],
          [
            105.82759626972462,
            21.020385753716752
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.020550998859484,
        "to_latitude": 21.020385753716752,
        "to_longitude": 105.82759626972462,
        "from_longitude": 105.8273173199847,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3211,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82759626972462,
          21.02020298235977,
          105.827891312711,
          21.020385753716752
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82759626972462,
            21.020385753716752
          ],
          [
            105.827891312711,
            21.02020298235977
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "9",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.020385753716752,
        "to_latitude": 21.02020298235977,
        "to_longitude": 105.827891312711,
        "from_longitude": 105.82759626972462,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3212,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.827891312711,
          21.020045247995522,
          105.82815953361096,
          21.02020298235977
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.827891312711,
            21.02020298235977
          ],
          [
            105.82815953361096,
            21.020045247995522
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 212 Đê La Thành",
        "diemDau": "8",
        "diemCuoi": "9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.02020298235977,
        "to_latitude": 21.020045247995522,
        "to_longitude": 105.82815953361096,
        "from_longitude": 105.827891312711,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3213,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82815953361096,
          21.019950106552013,
          105.82833924161949,
          21.020045247995522
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82815953361096,
            21.020045247995522
          ],
          [
            105.82833924161949,
            21.019950106552013
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "7",
        "diemCuoi": "8",
        "chuDauTu": "SoXayDung",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.020045247995522,
        "to_latitude": 21.019950106552013,
        "to_longitude": 105.82833924161949,
        "from_longitude": 105.82815953361096,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3214,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82833924161949,
          21.019857468772855,
          105.8285135852036,
          21.019950106552013
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82833924161949,
            21.019950106552013
          ],
          [
            105.8285135852036,
            21.019857468772855
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "6",
        "diemCuoi": "7",
        "chuDauTu": "SoXayDung",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.019950106552013,
        "to_latitude": 21.019857468772855,
        "to_longitude": 105.8285135852036,
        "from_longitude": 105.82833924161949,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3215,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8285135852036,
          21.019731036380872,
          105.82873789055051,
          21.019857468772855
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8285135852036,
            21.019857468772855
          ],
          [
            105.82873789055051,
            21.019731036380872
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "5",
        "diemCuoi": "6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.019857468772855,
        "to_latitude": 21.019731036380872,
        "to_longitude": 105.82873789055051,
        "from_longitude": 105.8285135852036,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3216,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82873789055051,
          21.01957705135948,
          105.82896687852757,
          21.019731036380872
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82873789055051,
            21.019731036380872
          ],
          [
            105.82896687852757,
            21.01957705135948
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "5",
        "diemCuoi": "4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.019731036380872,
        "to_latitude": 21.01957705135948,
        "to_longitude": 105.82896687852757,
        "from_longitude": 105.82873789055051,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3217,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82896687852757,
          21.019426827528395,
          105.82922973501206,
          21.01957705135948
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82896687852757,
            21.01957705135948
          ],
          [
            105.82922973501206,
            21.019426827528395
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "4",
        "diemCuoi": "3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.01957705135948,
        "to_latitude": 21.019426827528395,
        "to_longitude": 105.82922973501206,
        "from_longitude": 105.82896687852757,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3218,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82922973501206,
          21.01928661848227,
          105.82948722708105,
          21.019426827528395
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82922973501206,
            21.019426827528395
          ],
          [
            105.82948722708105,
            21.01928661848227
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "3",
        "diemCuoi": "2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.019426827528395,
        "to_latitude": 21.01928661848227,
        "to_longitude": 105.82948722708105,
        "from_longitude": 105.82922973501206,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3219,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82948722708105,
          21.019178018974625,
          105.82974572497571,
          21.01928661848227
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82948722708105,
            21.01928661848227
          ],
          [
            105.82974572497571,
            21.019178018974625
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "2",
        "diemCuoi": "1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.01928661848227,
        "to_latitude": 21.019178018974625,
        "to_longitude": 105.82974572497571,
        "from_longitude": 105.82948722708105,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3220,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82652338611577,
          21.02086534798684,
          105.82670007663837,
          21.021019505399806
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82652338611577,
            21.020916540495307
          ],
          [
            105.82663899956782,
            21.02086534798684
          ],
          [
            105.82670007663837,
            21.021019505399806
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 212 Đê La Thành",
        "diemDau": "14",
        "diemCuoi": "14-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.020916540495307,
        "to_latitude": 21.021019505399806,
        "to_longitude": 105.82670007663837,
        "from_longitude": 105.82652338611577,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3221,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82659815269925,
          21.021019505399806,
          105.82670007663837,
          21.021092112828104
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82670007663837,
            21.021019505399806
          ],
          [
            105.82659949380313,
            21.02105205356136
          ],
          [
            105.82659815269925,
            21.021092112828104
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 212 Đê La Thành",
        "diemDau": "14-1",
        "diemCuoi": "14-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.021019505399806,
        "to_latitude": 21.021092112828104,
        "to_longitude": 105.82659815269925,
        "from_longitude": 105.82670007663837,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3222,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82670007663837,
          21.021019505399806,
          105.82689721899723,
          21.021179742436253
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82670007663837,
            21.021019505399806
          ],
          [
            105.82678188401943,
            21.021022009104477
          ],
          [
            105.82689721899723,
            21.021179742436253
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 212 Đê La Thành",
        "diemDau": "14-1",
        "diemCuoi": "14-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.021019505399806,
        "to_latitude": 21.021179742436253,
        "to_longitude": 105.82689721899723,
        "from_longitude": 105.82670007663837,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3223,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82691331226165,
          21.020453666988246,
          105.82694181072117,
          21.020731266079498
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82694181072117,
            21.020731266079498
          ],
          [
            105.82691331226165,
            21.020583860086774
          ],
          [
            105.82693611102748,
            21.020453666988246
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 212 Đê La Thành",
        "diemDau": "13",
        "diemCuoi": "13-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.020731266079498,
        "to_latitude": 21.020453666988246,
        "to_longitude": 105.82693611102748,
        "from_longitude": 105.82694181072117,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3224,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82680468278524,
          21.020406096404386,
          105.82694181072117,
          21.020731266079498
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82694181072117,
            21.020731266079498
          ],
          [
            105.82691331226165,
            21.020583860086774
          ],
          [
            105.82680468278524,
            21.020406096404386
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 55,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 212 Đê La Thành",
        "diemDau": "13",
        "diemCuoi": "13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.020731266079498,
        "to_latitude": 21.020406096404386,
        "to_longitude": 105.82680468278524,
        "from_longitude": 105.82694181072117,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3225,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82716812211393,
          21.020319718200184,
          105.8273173199847,
          21.020550998859484
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8273173199847,
            21.020550998859484
          ],
          [
            105.82716812211393,
            21.020319718200184
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 212 Đê La Thành",
        "diemDau": "11",
        "diemCuoi": "11-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.020550998859484,
        "to_latitude": 21.020319718200184,
        "to_longitude": 105.82716812211393,
        "from_longitude": 105.8273173199847,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3226,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82716812211393,
          21.02026213270346,
          105.82726602274143,
          21.020319718200184
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82716812211393,
            21.020319718200184
          ],
          [
            105.82726602274143,
            21.02026213270346
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 212 Đê La Thành",
        "diemDau": "11-4",
        "diemCuoi": "11-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.020319718200184,
        "to_latitude": 21.02026213270346,
        "to_longitude": 105.82726602274143,
        "from_longitude": 105.82716812211393,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3227,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8273173199847,
          21.020550998859484,
          105.82744573074099,
          21.020581356374752
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8273173199847,
            21.020550998859484
          ],
          [
            105.82744573074099,
            21.020581356374752
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 212 Đê La Thành",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.020550998859484,
        "to_latitude": 21.020581356374752,
        "to_longitude": 105.82744573074099,
        "from_longitude": 105.8273173199847,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3228,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82744573074099,
          21.020581356374752,
          105.82753290253753,
          21.020706541941998
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82744573074099,
            21.020581356374752
          ],
          [
            105.82753290253753,
            21.020706541941998
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 212 Đê La Thành",
        "diemDau": "11-1",
        "diemCuoi": "11-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.020581356374752,
        "to_latitude": 21.020706541941998,
        "to_longitude": 105.82753290253753,
        "from_longitude": 105.82744573074099,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3229,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82747657614799,
          21.020706541941998,
          105.82753290253753,
          21.020757867994078
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82753290253753,
            21.020706541941998
          ],
          [
            105.82747657614799,
            21.020757867994078
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 212 Đê La Thành",
        "diemDau": "11-2",
        "diemCuoi": "11-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.020706541941998,
        "to_latitude": 21.020757867994078,
        "to_longitude": 105.82747657614799,
        "from_longitude": 105.82753290253753,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3230,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8285135852036,
          21.019857468772855,
          105.82857494073936,
          21.020108153981386
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8285135852036,
            21.019857468772855
          ],
          [
            105.82857494073936,
            21.020108153981386
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 96 Đê La Thành",
        "diemDau": "6",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.019857468772855,
        "to_latitude": 21.020108153981386,
        "to_longitude": 105.82857494073936,
        "from_longitude": 105.8285135852036,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3231,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82857494073936,
          21.019981716051127,
          105.8288659604141,
          21.020108153981386
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82857494073936,
            21.020108153981386
          ],
          [
            105.8288659604141,
            21.019981716051127
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 96 Đê La Thành",
        "diemDau": "6-1",
        "diemCuoi": "6-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.020108153981386,
        "to_latitude": 21.019981716051127,
        "to_longitude": 105.8288659604141,
        "from_longitude": 105.82857494073936,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3232,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8284891100467,
          21.020108153981386,
          105.82864065486498,
          21.020287169878774
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82857494073936,
            21.020108153981386
          ],
          [
            105.82864065486498,
            21.02024585853652
          ],
          [
            105.8284891100467,
            21.020287169878774
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 96 Đê La Thành",
        "diemDau": "6-1",
        "diemCuoi": "6-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.020108153981386,
        "to_latitude": 21.020287169878774,
        "to_longitude": 105.8284891100467,
        "from_longitude": 105.82857494073936,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3233,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82857494073936,
          21.020108153981386,
          105.82875733095564,
          21.020552563679704
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82857494073936,
            21.020108153981386
          ],
          [
            105.82875733095564,
            21.020552563679704
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 96 Đê La Thành",
        "diemDau": "6-1",
        "diemCuoi": "6-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.020108153981386,
        "to_latitude": 21.020552563679704,
        "to_longitude": 105.82875733095564,
        "from_longitude": 105.82857494073936,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3234,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82858969289094,
          21.020552563679704,
          105.82875733095564,
          21.0206139046328
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82875733095564,
            21.020552563679704
          ],
          [
            105.82858969289094,
            21.0206139046328
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 96 Đê La Thành",
        "diemDau": "6-4",
        "diemCuoi": "6-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.020552563679704,
        "to_latitude": 21.0206139046328,
        "to_longitude": 105.82858969289094,
        "from_longitude": 105.82875733095564,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3235,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82868759351842,
          21.02078415694088,
          105.82872514444479,
          21.020954409054536
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82868759351842,
            21.02078415694088
          ],
          [
            105.82872514444479,
            21.020954409054536
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 96 Đê La Thành",
        "diemDau": "6-7",
        "diemCuoi": "6-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.02078415694088,
        "to_latitude": 21.020954409054536,
        "to_longitude": 105.82872514444479,
        "from_longitude": 105.82868759351842,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3236,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82872514444479,
          21.020954409054536,
          105.82877476531495,
          21.02096692758542
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82872514444479,
            21.020954409054536
          ],
          [
            105.82877476531495,
            21.02096692758542
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 96 Đê La Thành",
        "diemDau": "6-9",
        "diemCuoi": "6-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.020954409054536,
        "to_latitude": 21.02096692758542,
        "to_longitude": 105.82877476531495,
        "from_longitude": 105.82872514444479,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3237,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82875733095564,
          21.020552563679704,
          105.8288659604141,
          21.020842994090213
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82875733095564,
            21.020552563679704
          ],
          [
            105.8288659604141,
            21.020842994090213
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 96 Đê La Thành",
        "diemDau": "6-4",
        "diemCuoi": "6-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.020552563679704,
        "to_latitude": 21.020842994090213,
        "to_longitude": 105.8288659604141,
        "from_longitude": 105.82875733095564,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3238,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8288659604141,
          21.020842994090213,
          105.82897593098545,
          21.021087105421064
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8288659604141,
            21.020842994090213
          ],
          [
            105.82897593098545,
            21.021087105421064
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 96 Đê La Thành",
        "diemDau": "6-8",
        "diemCuoi": "6-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.020842994090213,
        "to_latitude": 21.021087105421064,
        "to_longitude": 105.82897593098545,
        "from_longitude": 105.8288659604141,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3239,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82877476531495,
          21.020842994090213,
          105.82890466846398,
          21.02096692758542
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8288659604141,
            21.020842994090213
          ],
          [
            105.82890466846398,
            21.020928917792048
          ],
          [
            105.82877476531495,
            21.02096692758542
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 96 Đê La Thành",
        "diemDau": "6-8",
        "diemCuoi": "6-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.020842994090213,
        "to_latitude": 21.02096692758542,
        "to_longitude": 105.82877476531495,
        "from_longitude": 105.8288659604141,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3240,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82897593098545,
          21.02102326095721,
          105.82918648439201,
          21.021087105421064
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82897593098545,
            21.021087105421064
          ],
          [
            105.82918648439201,
            21.02102326095721
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 96 Đê La Thành",
        "diemDau": "6-10",
        "diemCuoi": "6-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.021087105421064,
        "to_latitude": 21.02102326095721,
        "to_longitude": 105.82918648439201,
        "from_longitude": 105.82897593098545,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3241,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82918648439201,
          21.020955660907838,
          105.8293956966947,
          21.02102326095721
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82918648439201,
            21.02102326095721
          ],
          [
            105.8293956966947,
            21.020955660907838
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 96 Đê La Thành",
        "diemDau": "6-11",
        "diemCuoi": "6-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.02102326095721,
        "to_latitude": 21.020955660907838,
        "to_longitude": 105.8293956966947,
        "from_longitude": 105.82918648439201,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3242,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82922973501206,
          21.019412118102473,
          105.82931188513675,
          21.019489032824588
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82922973501206,
            21.019426827528395
          ],
          [
            105.82925488072536,
            21.019412118102473
          ],
          [
            105.82931188513675,
            21.019489032824588
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 54 Đê La Thành",
        "diemDau": "3",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.019426827528395,
        "to_latitude": 21.019489032824588,
        "to_longitude": 105.82931188513675,
        "from_longitude": 105.82922973501206,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3243,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82931188513675,
          21.019489032824588,
          105.82947079854739,
          21.019614920258896
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82931188513675,
            21.019489032824588
          ],
          [
            105.82937289792889,
            21.019492237505506
          ],
          [
            105.82947079854739,
            21.019614920258896
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 54 Đê La Thành",
        "diemDau": "3",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.019489032824588,
        "to_latitude": 21.019614920258896,
        "to_longitude": 105.82947079854739,
        "from_longitude": 105.82931188513675,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3244,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82931188513675,
          21.019489032824588,
          105.82947348075514,
          21.01951101548559
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82931188513675,
            21.019489032824588
          ],
          [
            105.82937289792889,
            21.019492237505506
          ],
          [
            105.82947348075514,
            21.01951101548559
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 54 Đê La Thành",
        "diemDau": "3-1",
        "diemCuoi": "3-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.019489032824588,
        "to_latitude": 21.01951101548559,
        "to_longitude": 105.82947348075514,
        "from_longitude": 105.82931188513675,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3245,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82921196538352,
          21.019489032824588,
          105.82931188513675,
          21.019626187037797
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82931188513675,
            21.019489032824588
          ],
          [
            105.82921196538352,
            21.019626187037797
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 54 Đê La Thành",
        "diemDau": "3-1",
        "diemCuoi": "3-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.019489032824588,
        "to_latitude": 21.019626187037797,
        "to_longitude": 105.82921196538352,
        "from_longitude": 105.82931188513675,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3246,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82905639725361,
          21.019626187037797,
          105.82921196538352,
          21.019736351047822
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82921196538352,
            21.019626187037797
          ],
          [
            105.82905639725361,
            21.019736351047822
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 54 Đê La Thành",
        "diemDau": "3-4",
        "diemCuoi": "3-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.019626187037797,
        "to_latitude": 21.019736351047822,
        "to_longitude": 105.82905639725361,
        "from_longitude": 105.82921196538352,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3247,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82902018743114,
          21.019736351047822,
          105.82905639725361,
          21.01981146282679
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82905639725361,
            21.019736351047822
          ],
          [
            105.82902018743114,
            21.01981146282679
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 54 Đê La Thành",
        "diemDau": "3-5",
        "diemCuoi": "3-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.019736351047822,
        "to_latitude": 21.01981146282679,
        "to_longitude": 105.82902018743114,
        "from_longitude": 105.82905639725361,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3248,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82900275308083,
          21.019667498550888,
          105.82905639725361,
          21.019736351047822
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82905639725361,
            21.019736351047822
          ],
          [
            105.82900275308083,
            21.019667498550888
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1B",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 54 Đê La Thành",
        "diemDau": "3-5",
        "diemCuoi": "3-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 1B",
        "from_latitude": 21.019736351047822,
        "to_latitude": 21.019667498550888,
        "to_longitude": 105.82900275308083,
        "from_longitude": 105.82905639725361,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3249,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83146329495077,
          21.02205853936906,
          105.83159472321098,
          21.022296389499605
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83159472321098,
            21.022296389499605
          ],
          [
            105.83146329495077,
            21.02205853936906
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "14",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.022296389499605,
        "to_latitude": 21.02205853936906,
        "to_longitude": 105.83146329495077,
        "from_longitude": 105.83159472321098,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3250,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83134527775623,
          21.021795651940444,
          105.83146329495077,
          21.02205853936906
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83146329495077,
            21.02205853936906
          ],
          [
            105.83134527775623,
            21.021795651940444
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "13",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02205853936906,
        "to_latitude": 21.021795651940444,
        "to_longitude": 105.83134527775623,
        "from_longitude": 105.83146329495077,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3251,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8312084850985,
          21.02150271968907,
          105.83134527775623,
          21.021795651940444
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83134527775623,
            21.021795651940444
          ],
          [
            105.8312084850985,
            21.02150271968907
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "12",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.021795651940444,
        "to_latitude": 21.02150271968907,
        "to_longitude": 105.8312084850985,
        "from_longitude": 105.83134527775623,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3252,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83106364580858,
          21.021157209113237,
          105.8312084850985,
          21.02150271968907
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8312084850985,
            21.02150271968907
          ],
          [
            105.83106364580858,
            21.021157209113237
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "11",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02150271968907,
        "to_latitude": 21.021157209113237,
        "to_longitude": 105.83106364580858,
        "from_longitude": 105.8312084850985,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3253,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83091344210314,
          21.020861771899348,
          105.83106364580858,
          21.021157209113237
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83106364580858,
            21.021157209113237
          ],
          [
            105.83091344210314,
            21.020861771899348
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "10",
        "diemCuoi": "9",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.021157209113237,
        "to_latitude": 21.020861771899348,
        "to_longitude": 105.83091344210314,
        "from_longitude": 105.83106364580858,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3254,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83079274270087,
          21.02060639349644,
          105.83091344210314,
          21.020861771899348
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83091344210314,
            21.020861771899348
          ],
          [
            105.83079274270087,
            21.02060639349644
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "9",
        "diemCuoi": "8",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.020861771899348,
        "to_latitude": 21.02060639349644,
        "to_longitude": 105.83079274270087,
        "from_longitude": 105.83091344210314,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3255,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83069081876175,
          21.020413607550022,
          105.83079274270087,
          21.02060639349644
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83079274270087,
            21.02060639349644
          ],
          [
            105.83069081876175,
            21.020413607550022
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "8",
        "diemCuoi": "7",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02060639349644,
        "to_latitude": 21.020413607550022,
        "to_longitude": 105.83069081876175,
        "from_longitude": 105.83079274270087,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3256,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83056743714273,
          21.020160732099445,
          105.83069081876175,
          21.020413607550022
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83069081876175,
            21.020413607550022
          ],
          [
            105.83056743714273,
            21.020160732099445
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "7",
        "diemCuoi": "6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.020413607550022,
        "to_latitude": 21.020160732099445,
        "to_longitude": 105.83056743714273,
        "from_longitude": 105.83069081876175,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3257,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83044405553271,
          21.019865292911074,
          105.83056743714273,
          21.020160732099445
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83056743714273,
            21.020160732099445
          ],
          [
            105.83044405553271,
            21.019865292911074
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "6",
        "diemCuoi": "5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.020160732099445,
        "to_latitude": 21.019865292911074,
        "to_longitude": 105.83044405553271,
        "from_longitude": 105.83056743714273,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3258,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83032335612143,
          21.019587379242022,
          105.83044405553271,
          21.019865292911074
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83044405553271,
            21.019865292911074
          ],
          [
            105.83032335612143,
            21.019587379242022
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "5",
        "diemCuoi": "4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.019865292911074,
        "to_latitude": 21.019587379242022,
        "to_longitude": 105.83032335612143,
        "from_longitude": 105.83044405553271,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3259,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8302053389269,
          21.019324487456267,
          105.83032335612143,
          21.019587379242022
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83032335612143,
            21.019587379242022
          ],
          [
            105.8302053389269,
            21.019324487456267
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "4",
        "diemCuoi": "3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.019587379242022,
        "to_latitude": 21.019324487456267,
        "to_longitude": 105.8302053389269,
        "from_longitude": 105.83032335612143,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3260,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83014096591411,
          21.019231849287618,
          105.8302053389269,
          21.019324487456267
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8302053389269,
            21.019324487456267
          ],
          [
            105.83014096591411,
            21.019231849287618
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "3",
        "diemCuoi": "2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.019324487456267,
        "to_latitude": 21.019231849287618,
        "to_longitude": 105.83014096591411,
        "from_longitude": 105.8302053389269,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3261,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83159472321098,
          21.022296389499605,
          105.83164166186444,
          21.022401544173867
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83159472321098,
            21.022296389499605
          ],
          [
            105.83164166186444,
            21.022401544173867
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.022296389499605,
        "to_latitude": 21.022401544173867,
        "to_longitude": 105.83164166186444,
        "from_longitude": 105.83159472321098,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3262,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83279908466703,
          21.014799233463055,
          105.83309815096501,
          21.015067140836532
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83309815096501,
            21.014799233463055
          ],
          [
            105.83293721842864,
            21.0149482100521
          ],
          [
            105.83279908466703,
            21.015067140836532
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QKTĐ",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "1",
        "diemCuoi": "2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập thể Quân Khu Thủ Đô",
        "from_latitude": 21.014799233463055,
        "to_latitude": 21.015067140836532,
        "to_longitude": 105.83279908466703,
        "from_longitude": 105.83309815096501,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3263,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83265290427323,
          21.015067140836532,
          105.83279908466703,
          21.015198590539566
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83279908466703,
            21.015067140836532
          ],
          [
            105.83265290427323,
            21.015198590539566
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QKTĐ",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "2",
        "diemCuoi": "3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập thể Quân Khu Thủ Đô",
        "from_latitude": 21.015067140836532,
        "to_latitude": 21.015198590539566,
        "to_longitude": 105.83265290427323,
        "from_longitude": 105.83279908466703,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3264,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83248794841624,
          21.015198590539566,
          105.83265290427323,
          21.015338803428484
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83265290427323,
            21.015198590539566
          ],
          [
            105.83248794841624,
            21.015338803428484
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QKTĐ",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "3",
        "diemCuoi": "4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập thể Quân Khu Thủ Đô",
        "from_latitude": 21.015198590539566,
        "to_latitude": 21.015338803428484,
        "to_longitude": 105.83248794841624,
        "from_longitude": 105.83265290427323,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3265,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8323296980876,
          21.015338803428484,
          105.83248794841624,
          21.01547275669075
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83248794841624,
            21.015338803428484
          ],
          [
            105.8323296980876,
            21.01547275669075
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QKTĐ",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "4",
        "diemCuoi": "5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập thể Quân Khu Thủ Đô",
        "from_latitude": 21.015338803428484,
        "to_latitude": 21.01547275669075,
        "to_longitude": 105.8323296980876,
        "from_longitude": 105.83248794841624,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3266,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83222106862016,
          21.01544521490885,
          105.8323296980876,
          21.015507496886976
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8323296980876,
            21.01547275669075
          ],
          [
            105.83229248243049,
            21.015507496886976
          ],
          [
            105.83222106862016,
            21.01544521490885
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QKTĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "5",
        "diemCuoi": "6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập thể Quân Khu Thủ Đô",
        "from_latitude": 21.01547275669075,
        "to_latitude": 21.01544521490885,
        "to_longitude": 105.83222106862016,
        "from_longitude": 105.8323296980876,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3267,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8327293472298,
          21.014598615594835,
          105.83309815096501,
          21.014799233463055
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83309815096501,
            21.014799233463055
          ],
          [
            105.83290670829086,
            21.014598615594835
          ],
          [
            105.8327293472298,
            21.014740393929042
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QKTĐ",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "1",
        "diemCuoi": "7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập thể Quân Khu Thủ Đô",
        "from_latitude": 21.014799233463055,
        "to_latitude": 21.014740393929042,
        "to_longitude": 105.8327293472298,
        "from_longitude": 105.83309815096501,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3268,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8325737791089,
          21.014740393929042,
          105.8327293472298,
          21.014879355344412
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8327293472298,
            21.014740393929042
          ],
          [
            105.8325737791089,
            21.014879355344412
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QKTĐ",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "7",
        "diemCuoi": "8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập thể Quân Khu Thủ Đô",
        "from_latitude": 21.014740393929042,
        "to_latitude": 21.014879355344412,
        "to_longitude": 105.8325737791089,
        "from_longitude": 105.8327293472298,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3269,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83240748214804,
          21.014879355344412,
          105.8325737791089,
          21.015018316631163
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8325737791089,
            21.014879355344412
          ],
          [
            105.83240748214804,
            21.015018316631163
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QKTĐ",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "8",
        "diemCuoi": "9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập thể Quân Khu Thủ Đô",
        "from_latitude": 21.014879355344412,
        "to_latitude": 21.015018316631163,
        "to_longitude": 105.83240748214804,
        "from_longitude": 105.8325737791089,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3270,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83222375082788,
          21.015018316631163,
          105.83240748214804,
          21.015184819623503
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83240748214804,
            21.015018316631163
          ],
          [
            105.83222375082788,
            21.015184819623503
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "QKTĐ",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "9",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tập thể Quân Khu Thủ Đô",
        "from_latitude": 21.015018316631163,
        "to_latitude": 21.015184819623503,
        "to_longitude": 105.83222375082788,
        "from_longitude": 105.83240748214804,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3271,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83187003452245,
          21.015345375903415,
          105.83225761372982,
          21.015700915122867
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83225761372982,
            21.015700915122867
          ],
          [
            105.83187003452245,
            21.015345375903415
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x2,5",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 115 ngõ xã Đàn 2",
        "diemDau": "14-1",
        "diemCuoi": "14-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.015700915122867,
        "to_latitude": 21.015345375903415,
        "to_longitude": 105.83187003452245,
        "from_longitude": 105.83225761372982,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3272,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83173343345615,
          21.015153656213577,
          105.83187003452245,
          21.015345375903415
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83187003452245,
            21.015345375903415
          ],
          [
            105.83173343345615,
            21.015153656213577
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x2,5",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 150 ngõ xã Đàn 2",
        "diemDau": "14",
        "diemCuoi": "14-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.015345375903415,
        "to_latitude": 21.015153656213577,
        "to_longitude": 105.83173343345615,
        "from_longitude": 105.83187003452245,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3273,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83154816944072,
          21.014934752088283,
          105.83173343345615,
          21.015153656213577
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83173343345615,
            21.015153656213577
          ],
          [
            105.83154816944072,
            21.014934752088283
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 150 ngõ xã Đàn 2",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.015153656213577,
        "to_latitude": 21.014934752088283,
        "to_longitude": 105.83154816944072,
        "from_longitude": 105.83173343345615,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3274,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83131481725935,
          21.01470940926969,
          105.83154816944072,
          21.014934752088283
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83154816944072,
            21.014934752088283
          ],
          [
            105.83131481725935,
            21.01470940926969
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 150 ngõ xã Đàn 2",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.014934752088283,
        "to_latitude": 21.01470940926969,
        "to_longitude": 105.83131481725935,
        "from_longitude": 105.83154816944072,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3275,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83118070680938,
          21.014664340664794,
          105.83131481725935,
          21.01470940926969
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83131481725935,
            21.01470940926969
          ],
          [
            105.83118070680938,
            21.014664340664794
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 150 ngõ xã Đàn 2",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.01470940926969,
        "to_latitude": 21.014664340664794,
        "to_longitude": 105.83118070680938,
        "from_longitude": 105.83131481725935,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3276,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83108414728574,
          21.014664340664794,
          105.83118070680938,
          21.014706905459047
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83118070680938,
            21.014664340664794
          ],
          [
            105.83108414728574,
            21.014706905459047
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 150 ngõ xã Đàn 2",
        "diemDau": "17",
        "diemCuoi": "16-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.014664340664794,
        "to_latitude": 21.014706905459047,
        "to_longitude": 105.83108414728574,
        "from_longitude": 105.83118070680938,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3277,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83067913372805,
          21.01470440164751,
          105.83108414728574,
          21.014706905459047
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83108414728574,
            21.014706905459047
          ],
          [
            105.83067913372805,
            21.01470440164751
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 150 ngõ xã Đàn 2",
        "diemDau": "16-1",
        "diemCuoi": "16-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.014706905459047,
        "to_latitude": 21.01470440164751,
        "to_longitude": 105.83067913372805,
        "from_longitude": 105.83108414728574,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3278,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83115388472298,
          21.014531638583673,
          105.83118070680938,
          21.014664340664794
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83118070680938,
            21.014664340664794
          ],
          [
            105.83115388472298,
            21.014531638583673
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 150 ngõ xã Đàn 2",
        "diemDau": "17",
        "diemCuoi": "16-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.014664340664794,
        "to_latitude": 21.014531638583673,
        "to_longitude": 105.83115388472298,
        "from_longitude": 105.83118070680938,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3279,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83115388472298,
          21.014504096627988,
          105.83126117307759,
          21.014531638583673
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83115388472298,
            21.014531638583673
          ],
          [
            105.83126117307759,
            21.014504096627988
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 150 ngõ xã Đàn 2",
        "diemDau": "16-3",
        "diemCuoi": "16-3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.014531638583673,
        "to_latitude": 21.014504096627988,
        "to_longitude": 105.83126117307759,
        "from_longitude": 105.83115388472298,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3280,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83086688835084,
          21.01441395928132,
          105.83115388472298,
          21.014531638583673
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83115388472298,
            21.014531638583673
          ],
          [
            105.83086688835084,
            21.01441395928132
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 150 ngõ xã Đàn 2",
        "diemDau": "16-3",
        "diemCuoi": "16-3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.014531638583673,
        "to_latitude": 21.01441395928132,
        "to_longitude": 105.83086688835084,
        "from_longitude": 105.83115388472298,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3281,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83086688835084,
          21.01438140967001,
          105.83112974483532,
          21.01441395928132
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83086688835084,
            21.01441395928132
          ],
          [
            105.83112974483532,
            21.01438140967001
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 150 ngõ xã Đàn 2",
        "diemDau": "16-3-1",
        "diemCuoi": "13-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.01441395928132,
        "to_latitude": 21.01438140967001,
        "to_longitude": 105.83112974483532,
        "from_longitude": 105.83086688835084,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3282,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83112438041985,
          21.014213653870883,
          105.83112974483532,
          21.01438140967001
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83112974483532,
            21.01438140967001
          ],
          [
            105.83112438041985,
            21.014213653870883
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 150 ngõ xã Đàn 2",
        "diemDau": "16-5",
        "diemCuoi": "16-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.01438140967001,
        "to_latitude": 21.014213653870883,
        "to_longitude": 105.83112438041985,
        "from_longitude": 105.83112974483532,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3283,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83085347731213,
          21.014276249340874,
          105.83086688835084,
          21.01441395928132
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83086688835084,
            21.01441395928132
          ],
          [
            105.83085347731213,
            21.014276249340874
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 150 ngõ xã Đàn 2",
        "diemDau": "16-3-1",
        "diemCuoi": "16-3-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.01441395928132,
        "to_latitude": 21.014276249340874,
        "to_longitude": 105.83085347731213,
        "from_longitude": 105.83086688835084,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3284,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83118070680938,
          21.014444005069592,
          105.8313872369043,
          21.014664340664794
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83118070680938,
            21.014664340664794
          ],
          [
            105.8313872369043,
            21.014444005069592
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 150 ngõ xã Đàn 2",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.014664340664794,
        "to_latitude": 21.014444005069592,
        "to_longitude": 105.8313872369043,
        "from_longitude": 105.83118070680938,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3285,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8313872369043,
          21.014248707337163,
          105.83159108479153,
          21.014444005069592
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8313872369043,
            21.014444005069592
          ],
          [
            105.83159108479153,
            21.014248707337163
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 150 ngõ xã Đàn 2",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.014444005069592,
        "to_latitude": 21.014248707337163,
        "to_longitude": 105.83159108479153,
        "from_longitude": 105.8313872369043,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3286,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83159108479153,
          21.014058416993922,
          105.83179761488648,
          21.014248707337163
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83159108479153,
            21.014248707337163
          ],
          [
            105.83179761488648,
            21.014058416993922
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 150 ngõ xã Đàn 2",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.014248707337163,
        "to_latitude": 21.014058416993922,
        "to_longitude": 105.83179761488648,
        "from_longitude": 105.83159108479153,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3287,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8314998896834,
          21.015153656213577,
          105.83173343345615,
          21.015360398704058
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83173343345615,
            21.015153656213577
          ],
          [
            105.8314998896834,
            21.015360398704058
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.015153656213577,
        "to_latitude": 21.015360398704058,
        "to_longitude": 105.8314998896834,
        "from_longitude": 105.83173343345615,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3288,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83131079394774,
          21.015360398704058,
          105.8314998896834,
          21.015555694979934
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8314998896834,
            21.015360398704058
          ],
          [
            105.83131079394774,
            21.015555694979934
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.015360398704058,
        "to_latitude": 21.015555694979934,
        "to_longitude": 105.83131079394774,
        "from_longitude": 105.8314998896834,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3289,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8310600074071,
          21.015555694979934,
          105.83131079394774,
          21.015680884767114
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83131079394774,
            21.015555694979934
          ],
          [
            105.83119277675323,
            21.015660854407802
          ],
          [
            105.8310600074071,
            21.015680884767114
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.015555694979934,
        "to_latitude": 21.015680884767114,
        "to_longitude": 105.8310600074071,
        "from_longitude": 105.83131079394774,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3290,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83089371044623,
          21.015680884767114,
          105.8310600074071,
          21.015863661665886
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8310600074071,
            21.015680884767114
          ],
          [
            105.83089371044623,
            21.015863661665886
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "11",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.015680884767114,
        "to_latitude": 21.015863661665886,
        "to_longitude": 105.83089371044623,
        "from_longitude": 105.8310600074071,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3291,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83072473127764,
          21.015863661665886,
          105.83089371044623,
          21.016033919398367
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83089371044623,
            21.015863661665886
          ],
          [
            105.83072473127764,
            21.016033919398367
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "10",
        "diemCuoi": "9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.015863661665886,
        "to_latitude": 21.016033919398367,
        "to_longitude": 105.83072473127764,
        "from_longitude": 105.83089371044623,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3292,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83056111652455,
          21.016033919398367,
          105.83072473127764,
          21.016236726148257
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83072473127764,
            21.016033919398367
          ],
          [
            105.83056111652455,
            21.016236726148257
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "9",
        "diemCuoi": "8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.016033919398367,
        "to_latitude": 21.016236726148257,
        "to_longitude": 105.83056111652455,
        "from_longitude": 105.83072473127764,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3293,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83044578153773,
          21.016236726148257,
          105.83056111652455,
          21.016444540187162
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83056111652455,
            21.016236726148257
          ],
          [
            105.83044578153773,
            21.016444540187162
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "8",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.016236726148257,
        "to_latitude": 21.016444540187162,
        "to_longitude": 105.83044578153773,
        "from_longitude": 105.83056111652455,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3294,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8303492220141,
          21.016444540187162,
          105.83044578153773,
          21.01662230859306
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83044578153773,
            21.016444540187162
          ],
          [
            105.8303492220141,
            21.01662230859306
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "4-1",
        "diemCuoi": "4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.016444540187162,
        "to_latitude": 21.01662230859306,
        "to_longitude": 105.8303492220141,
        "from_longitude": 105.83044578153773,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3295,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8303492220141,
          21.01662230859306,
          105.83058793861993,
          21.01696282212975
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8303492220141,
            21.01662230859306
          ],
          [
            105.83050344902213,
            21.01679694706782
          ],
          [
            105.83058793861993,
            21.01696282212975
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "1",
        "diemCuoi": "4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.01662230859306,
        "to_latitude": 21.01696282212975,
        "to_longitude": 105.83058793861993,
        "from_longitude": 105.8303492220141,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3296,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83127458412527,
          21.01534036830258,
          105.83135857143853,
          21.015555694979934
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83131079394774,
            21.015555694979934
          ],
          [
            105.83135857143853,
            21.01550635086638
          ],
          [
            105.83127458412527,
            21.01534036830258
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 111 ngõ xã Đàn 2",
        "diemDau": "12",
        "diemCuoi": "12-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.015555694979934,
        "to_latitude": 21.01534036830258,
        "to_longitude": 105.83127458412527,
        "from_longitude": 105.83131079394774,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3297,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83117802460163,
          21.015215178230402,
          105.83127458412527,
          21.01534036830258
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83127458412527,
            21.01534036830258
          ],
          [
            105.83117802460163,
            21.015215178230402
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 111 ngõ xã Đàn 2",
        "diemDau": "12-4",
        "diemCuoi": "12-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.01534036830258,
        "to_latitude": 21.015215178230402,
        "to_longitude": 105.83117802460163,
        "from_longitude": 105.83127458412527,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3298,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83131079394774,
          21.015555694979934,
          105.83182175476514,
          21.016036423186748
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83131079394774,
            21.015555694979934
          ],
          [
            105.83182175476514,
            21.016036423186748
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 56,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 111 ngõ xã Đàn 2",
        "diemDau": "12",
        "diemCuoi": "12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.015555694979934,
        "to_latitude": 21.016036423186748,
        "to_longitude": 105.83182175476514,
        "from_longitude": 105.83131079394774,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3299,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83182175476514,
          21.016036423186748,
          105.83196659405507,
          21.01614909363608
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83182175476514,
            21.016036423186748
          ],
          [
            105.83196659405507,
            21.01614909363608
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 111 ngõ xã Đàn 2",
        "diemDau": "12-1",
        "diemCuoi": "12-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.016036423186748,
        "to_latitude": 21.01614909363608,
        "to_longitude": 105.83196659405507,
        "from_longitude": 105.83182175476514,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3300,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83195184188554,
          21.016025782085226,
          105.83203901369106,
          21.01614909363608
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83196659405507,
            21.01614909363608
          ],
          [
            105.83195184188554,
            21.016025782085226
          ],
          [
            105.83203901369106,
            21.01606146107155
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 111 ngõ xã Đàn 2",
        "diemDau": "12-2",
        "diemCuoi": "12-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.01614909363608,
        "to_latitude": 21.01606146107155,
        "to_longitude": 105.83203901369106,
        "from_longitude": 105.83196659405507,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3301,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8310600074071,
          21.015680884767114,
          105.8313040884284,
          21.015928760233543
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8310600074071,
            21.015680884767114
          ],
          [
            105.8313040884284,
            21.015928760233543
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 111 ngõ xã Đàn 2",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.015680884767114,
        "to_latitude": 21.015928760233543,
        "to_longitude": 105.8313040884284,
        "from_longitude": 105.8310600074071,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3302,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83105732519934,
          21.015568213964052,
          105.8310600074071,
          21.015680884767114
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8310600074071,
            21.015680884767114
          ],
          [
            105.83105732519934,
            21.015568213964052
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 106 ngõ xã Đàn 2",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.015680884767114,
        "to_latitude": 21.015568213964052,
        "to_longitude": 105.83105732519934,
        "from_longitude": 105.8310600074071,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3303,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83085884172762,
          21.015332856901423,
          105.83105732519934,
          21.015568213964052
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83105732519934,
            21.015568213964052
          ],
          [
            105.83085884172762,
            21.015332856901423
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 106 ngõ xã Đàn 2",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.015568213964052,
        "to_latitude": 21.015332856901423,
        "to_longitude": 105.83085884172762,
        "from_longitude": 105.83105732519934,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3304,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83068718035129,
          21.015297803689954,
          105.83085884172762,
          21.015332856901423
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83085884172762,
            21.015332856901423
          ],
          [
            105.83068718035129,
            21.015297803689954
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 106 ngõ xã Đàn 2",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.015332856901423,
        "to_latitude": 21.015297803689954,
        "to_longitude": 105.83068718035129,
        "from_longitude": 105.83085884172762,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3305,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83067108709584,
          21.015049927174836,
          105.83068718035129,
          21.015297803689954
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83068718035129,
            21.015297803689954
          ],
          [
            105.83067108709584,
            21.015049927174836
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 106 ngõ xã Đàn 2",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.015297803689954,
        "to_latitude": 21.015049927174836,
        "to_longitude": 105.83067108709584,
        "from_longitude": 105.83068718035129,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3306,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83045919258542,
          21.014934752088283,
          105.83067108709584,
          21.015049927174836
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83067108709584,
            21.015049927174836
          ],
          [
            105.83045919258542,
            21.014934752088283
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 106 ngõ xã Đàn 2",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.015049927174836,
        "to_latitude": 21.014934752088283,
        "to_longitude": 105.83045919258542,
        "from_longitude": 105.83067108709584,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3307,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83089371044623,
          21.015863661665886,
          105.83105464298262,
          21.015988851193725
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83089371044623,
            21.015863661665886
          ],
          [
            105.83105464298262,
            21.015988851193725
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 103 ngõ xã Đàn 2",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.015863661665886,
        "to_latitude": 21.015988851193725,
        "to_longitude": 105.83105464298262,
        "from_longitude": 105.83089371044623,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3308,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83105464298262,
          21.015940653239056,
          105.83123435099115,
          21.016033919398367
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83105464298262,
            21.015988851193725
          ],
          [
            105.83109889941935,
            21.015940653239056
          ],
          [
            105.83123435099115,
            21.016033919398367
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 103 ngõ xã Đàn 2",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.015988851193725,
        "to_latitude": 21.016033919398367,
        "to_longitude": 105.83123435099115,
        "from_longitude": 105.83105464298262,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3309,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83123435099115,
          21.016033919398367,
          105.83141405899073,
          21.016186650433333
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83123435099115,
            21.016033919398367
          ],
          [
            105.83141405899073,
            21.016186650433333
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 103 ngõ xã Đàn 2",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.016033919398367,
        "to_latitude": 21.016186650433333,
        "to_longitude": 105.83141405899073,
        "from_longitude": 105.83123435099115,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3310,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83072473127764,
          21.016033919398367,
          105.83098758776212,
          21.01624423750474
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83072473127764,
            21.016033919398367
          ],
          [
            105.83098758776212,
            21.01624423750474
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 103 ngõ xã Đàn 2",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.016033919398367,
        "to_latitude": 21.01624423750474,
        "to_longitude": 105.83098758776212,
        "from_longitude": 105.83072473127764,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3311,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83098758776212,
          21.01624423750474,
          105.83123703319892,
          21.01645205153231
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83098758776212,
            21.01624423750474
          ],
          [
            105.83123703319892,
            21.01645205153231
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 103 ngõ xã Đàn 2",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.01624423750474,
        "to_latitude": 21.01645205153231,
        "to_longitude": 105.83123703319892,
        "from_longitude": 105.83098758776212,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3312,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83123703319892,
          21.01645205153231,
          105.83134968597797,
          21.01655470654767
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83123703319892,
            21.01645205153231
          ],
          [
            105.83134968597797,
            21.01655470654767
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 103 ngõ xã Đàn 2",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.01645205153231,
        "to_latitude": 21.01655470654767,
        "to_longitude": 105.83134968597797,
        "from_longitude": 105.83123703319892,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3313,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83134968597797,
          21.016427013713084,
          105.83151866514658,
          21.01655470654767
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83134968597797,
            21.01655470654767
          ],
          [
            105.83151866514658,
            21.016427013713084
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 103 ngõ xã Đàn 2",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.01655470654767,
        "to_latitude": 21.016427013713084,
        "to_longitude": 105.83151866514658,
        "from_longitude": 105.83134968597797,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3314,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83151866514658,
          21.016286801847247,
          105.83167691547521,
          21.016427013713084
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83151866514658,
            21.016427013713084
          ],
          [
            105.83167691547521,
            21.016286801847247
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách 103 ngõ xã Đàn 2",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.016427013713084,
        "to_latitude": 21.016286801847247,
        "to_longitude": 105.83167691547521,
        "from_longitude": 105.83151866514658,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3315,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83040823061138,
          21.016131567127285,
          105.83056111652455,
          21.016236726148257
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83056111652455,
            21.016236726148257
          ],
          [
            105.83040823061138,
            21.016131567127285
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách ngõ xã Đàn 2",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.016236726148257,
        "to_latitude": 21.016131567127285,
        "to_longitude": 105.83040823061138,
        "from_longitude": 105.83056111652455,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3316,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83024729807498,
          21.016041430764197,
          105.83040823061138,
          21.016131567127285
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83040823061138,
            21.016131567127285
          ],
          [
            105.83024729807498,
            21.016041430764197
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách ngõ xã Đàn 2",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.016131567127285,
        "to_latitude": 21.016041430764197,
        "to_longitude": 105.83024729807498,
        "from_longitude": 105.83040823061138,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3317,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8302955778323,
          21.016386953193077,
          105.83044578153773,
          21.016444540187162
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83044578153773,
            21.016444540187162
          ],
          [
            105.8302955778323,
            21.016386953193077
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách ngõ xã Đàn 2",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.016444540187162,
        "to_latitude": 21.016386953193077,
        "to_longitude": 105.8302955778323,
        "from_longitude": 105.83044578153773,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3318,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8301534207591,
          21.016301824553057,
          105.8302955778323,
          21.016386953193077
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8302955778323,
            21.016386953193077
          ],
          [
            105.8301534207591,
            21.016301824553057
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách ngõ xã Đàn 2",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.016386953193077,
        "to_latitude": 21.016301824553057,
        "to_longitude": 105.8301534207591,
        "from_longitude": 105.8302955778323,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3319,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8301534207591,
          21.016033919398367,
          105.83017487843003,
          21.016301824553057
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8301534207591,
            21.016301824553057
          ],
          [
            105.83017487843003,
            21.016033919398367
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách ngõ xã Đàn 2",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.016301824553057,
        "to_latitude": 21.016033919398367,
        "to_longitude": 105.83017487843003,
        "from_longitude": 105.8301534207591,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3320,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83056111652455,
          21.016221077490624,
          105.83078105766718,
          21.016354404013217
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83056111652455,
            21.016236726148257
          ],
          [
            105.83066438155852,
            21.016221077490624
          ],
          [
            105.83078105766718,
            21.016354404013217
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách ngõ xã Đàn 2",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.016236726148257,
        "to_latitude": 21.016354404013217,
        "to_longitude": 105.83078105766718,
        "from_longitude": 105.83056111652455,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3321,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83050344902213,
          21.01660728591868,
          105.83063621837725,
          21.01696282212975
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83058793861993,
            21.01696282212975
          ],
          [
            105.83050344902213,
            21.01679694706782
          ],
          [
            105.83063621837725,
            21.01660728591868
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách ngõ xã Đàn 2",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.01696282212975,
        "to_latitude": 21.01660728591868,
        "to_longitude": 105.83063621837725,
        "from_longitude": 105.83058793861993,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3322,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83063621837725,
          21.016499623377573,
          105.83069254476678,
          21.01660728591868
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83063621837725,
            21.01660728591868
          ],
          [
            105.83069254476678,
            21.016499623377573
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "XĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngách ngõ xã Đàn 2",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Xã Đàn 2",
        "from_latitude": 21.01660728591868,
        "to_latitude": 21.016499623377573,
        "to_longitude": 105.83069254476678,
        "from_longitude": 105.83063621837725,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3323,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8086300345465,
          21.012557994170557,
          105.80880437813057,
          21.012680682627543
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80880437813057,
            21.012557994170557
          ],
          [
            105.8086300345465,
            21.012680682627543
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.012557994170557,
        "to_latitude": 21.012680682627543,
        "to_longitude": 105.8086300345465,
        "from_longitude": 105.80880437813057,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3324,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8084355743864,
          21.012680682627543,
          105.8086300345465,
          21.01281463827711
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8086300345465,
            21.012680682627543
          ],
          [
            105.8084355743864,
            21.01281463827711
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.012680682627543,
        "to_latitude": 21.01281463827711,
        "to_longitude": 105.8084355743864,
        "from_longitude": 105.8086300345465,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3325,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80821697436558,
          21.01281463827711,
          105.8084355743864,
          21.01296862452991
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8084355743864,
            21.01281463827711
          ],
          [
            105.80821697436558,
            21.01296862452991
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.01281463827711,
        "to_latitude": 21.01296862452991,
        "to_longitude": 105.80821697436558,
        "from_longitude": 105.8084355743864,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3326,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80799703322295,
          21.01296862452991,
          105.80821697436558,
          21.013130122135376
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80821697436558,
            21.01296862452991
          ],
          [
            105.80799703322295,
            21.013130122135376
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.01296862452991,
        "to_latitude": 21.013130122135376,
        "to_longitude": 105.80799703322295,
        "from_longitude": 105.80821697436558,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3327,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80780659638343,
          21.013130122135376,
          105.80799703322295,
          21.013261573546394
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80799703322295,
            21.013130122135376
          ],
          [
            105.80780659638343,
            21.013261573546394
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.013130122135376,
        "to_latitude": 21.013261573546394,
        "to_longitude": 105.80780659638343,
        "from_longitude": 105.80799703322295,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3328,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80759067856138,
          21.013261573546394,
          105.80780659638343,
          21.01341681125356
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80780659638343,
            21.013261573546394
          ],
          [
            105.80759067856138,
            21.01341681125356
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.013261573546394,
        "to_latitude": 21.01341681125356,
        "to_longitude": 105.80759067856138,
        "from_longitude": 105.80780659638343,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3329,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80734257422847,
          21.01341681125356,
          105.80759067856138,
          21.013594583267487
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80759067856138,
            21.01341681125356
          ],
          [
            105.80734257422847,
            21.013594583267487
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.01341681125356,
        "to_latitude": 21.013594583267487,
        "to_longitude": 105.80734257422847,
        "from_longitude": 105.80759067856138,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3330,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80713872634126,
          21.013594583267487,
          105.80734257422847,
          21.01374856871426
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80734257422847,
            21.013594583267487
          ],
          [
            105.80713872634126,
            21.01374856871426
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.013594583267487,
        "to_latitude": 21.01374856871426,
        "to_longitude": 105.80713872634126,
        "from_longitude": 105.80734257422847,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3331,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80690671526379,
          21.01374856871426,
          105.80713872634126,
          21.013916325036497
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80713872634126,
            21.01374856871426
          ],
          [
            105.80690671526379,
            21.013916325036497
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.01374856871426,
        "to_latitude": 21.013916325036497,
        "to_longitude": 105.80690671526379,
        "from_longitude": 105.80713872634126,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3332,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80670689068819,
          21.013916325036497,
          105.80690671526379,
          21.014077821616667
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80690671526379,
            21.013916325036497
          ],
          [
            105.80670689068819,
            21.014077821616667
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.013916325036497,
        "to_latitude": 21.014077821616667,
        "to_longitude": 105.80670689068819,
        "from_longitude": 105.80690671526379,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3333,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80652986489638,
          21.014077821616667,
          105.80670689068819,
          21.01422554701653
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80670689068819,
            21.014077821616667
          ],
          [
            105.80652986489638,
            21.01422554701653
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.014077821616667,
        "to_latitude": 21.01422554701653,
        "to_longitude": 105.80652986489638,
        "from_longitude": 105.80670689068819,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3334,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80632869921689,
          21.01422554701653,
          105.80652986489638,
          21.0143870432619
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80652986489638,
            21.01422554701653
          ],
          [
            105.80632869921689,
            21.0143870432619
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.01422554701653,
        "to_latitude": 21.0143870432619,
        "to_longitude": 105.80632869921689,
        "from_longitude": 105.80652986489638,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3335,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80611278138588,
          21.0143870432619,
          105.80632869921689,
          21.014584844625098
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80632869921689,
            21.0143870432619
          ],
          [
            105.80611278138588,
            21.014584844625098
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.0143870432619,
        "to_latitude": 21.014584844625098,
        "to_longitude": 105.80611278138588,
        "from_longitude": 105.80632869921689,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3336,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80594246111342,
          21.014584844625098,
          105.80611278138588,
          21.01474258476591
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80611278138588,
            21.014584844625098
          ],
          [
            105.80594246111342,
            21.01474258476591
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.014584844625098,
        "to_latitude": 21.01474258476591,
        "to_longitude": 105.80594246111342,
        "from_longitude": 105.80611278138588,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3337,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80574934206615,
          21.01474258476591,
          105.80594246111342,
          21.01492285900758
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80594246111342,
            21.01474258476591
          ],
          [
            105.80574934206615,
            21.01492285900758
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "25",
        "diemCuoi": "LH2 26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.01474258476591,
        "to_latitude": 21.01492285900758,
        "to_longitude": 105.80574934206615,
        "from_longitude": 105.80594246111342,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3338,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80880437813057,
          21.01240651134401,
          105.80902029595264,
          21.012557994170557
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80880437813057,
            21.012557994170557
          ],
          [
            105.80902029595264,
            21.01240651134401
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "11",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.012557994170557,
        "to_latitude": 21.01240651134401,
        "to_longitude": 105.80902029595264,
        "from_longitude": 105.80880437813057,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3339,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80902029595264,
          21.012281318810153,
          105.80920268616892,
          21.01240651134401
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80902029595264,
            21.01240651134401
          ],
          [
            105.80920268616892,
            21.012281318810153
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "10",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.01240651134401,
        "to_latitude": 21.012281318810153,
        "to_longitude": 105.80920268616892,
        "from_longitude": 105.80902029595264,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3340,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80920268616892,
          21.01213609533858,
          105.80941189847161,
          21.012281318810153
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80920268616892,
            21.012281318810153
          ],
          [
            105.80941189847161,
            21.01213609533858
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "09",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.012281318810153,
        "to_latitude": 21.01213609533858,
        "to_longitude": 105.80941189847161,
        "from_longitude": 105.80920268616892,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3341,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80941189847161,
          21.011959573514357,
          105.80965732059677,
          21.01213609533858
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80941189847161,
            21.01213609533858
          ],
          [
            105.80965732059677,
            21.011959573514357
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "08",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.01213609533858,
        "to_latitude": 21.011959573514357,
        "to_longitude": 105.80965732059677,
        "from_longitude": 105.80941189847161,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3342,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80965732059677,
          21.011825617096925,
          105.80986116848399,
          21.011959573514357
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80965732059677,
            21.011959573514357
          ],
          [
            105.80986116848399,
            21.011825617096925
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "07",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.011959573514357,
        "to_latitude": 21.011825617096925,
        "to_longitude": 105.80986116848399,
        "from_longitude": 105.80965732059677,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3343,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80986116848399,
          21.01170668372867,
          105.81003148874747,
          21.011825617096925
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80986116848399,
            21.011825617096925
          ],
          [
            105.81003148874747,
            21.01170668372867
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "06",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.011825617096925,
        "to_latitude": 21.01170668372867,
        "to_longitude": 105.81003148874747,
        "from_longitude": 105.80986116848399,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3344,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81003148874747,
          21.011540176852833,
          105.81027691087264,
          21.01170668372867
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81003148874747,
            21.01170668372867
          ],
          [
            105.81027691087264,
            21.011540176852833
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "05",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.01170668372867,
        "to_latitude": 21.011540176852833,
        "to_longitude": 105.81027691087264,
        "from_longitude": 105.81003148874747,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3345,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81027691087264,
          21.01140121232651,
          105.81047807655212,
          21.011540176852833
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81027691087264,
            21.011540176852833
          ],
          [
            105.81047807655212,
            21.01140121232651
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "04",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.011540176852833,
        "to_latitude": 21.01140121232651,
        "to_longitude": 105.81047807655212,
        "from_longitude": 105.81027691087264,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3346,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81047807655212,
          21.011278522816326,
          105.81065912566453,
          21.01140121232651
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81047807655212,
            21.01140121232651
          ],
          [
            105.81065912566453,
            21.011278522816326
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.01140121232651,
        "to_latitude": 21.011278522816326,
        "to_longitude": 105.81065912566453,
        "from_longitude": 105.81047807655212,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3347,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81065912566453,
          21.011088228683885,
          105.81099574288886,
          21.011278522816326
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81065912566453,
            21.011278522816326
          ],
          [
            105.81088309007387,
            21.01112203094546
          ],
          [
            105.81094075756727,
            21.011089480616654
          ],
          [
            105.81099574288886,
            21.011088228683885
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "02",
        "diemCuoi": "LH2 01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LH2",
        "from_latitude": 21.011278522816326,
        "to_latitude": 21.011088228683885,
        "to_longitude": 105.81099574288886,
        "from_longitude": 105.81065912566453,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3348,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81063900906156,
          21.011278522816326,
          105.8107530029355,
          21.01146380897505
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81065912566453,
            21.011278522816326
          ],
          [
            105.81063900906156,
            21.0113373637143
          ],
          [
            105.8107530029355,
            21.01146380897505
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 530 Đường Láng",
        "diemDau": "02",
        "diemCuoi": "2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.011278522816326,
        "to_latitude": 21.01146380897505,
        "to_longitude": 105.8107530029355,
        "from_longitude": 105.81065912566453,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3349,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81003148874747,
          21.01170668372867,
          105.81020180898399,
          21.011937038798166
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81003148874747,
            21.01170668372867
          ],
          [
            105.81020180898399,
            21.011937038798166
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "NGõ 538 Đường Láng",
        "diemDau": "05",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.01170668372867,
        "to_latitude": 21.011937038798166,
        "to_longitude": 105.81020180898399,
        "from_longitude": 105.81003148874747,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3350,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81020180898399,
          21.011937038798166,
          105.8104190679099,
          21.012229989840638
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81020180898399,
            21.011937038798166
          ],
          [
            105.8104190679099,
            21.012229989840638
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "NGõ 538 Đường Láng",
        "diemDau": "5-1",
        "diemCuoi": "5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.011937038798166,
        "to_latitude": 21.012229989840638,
        "to_longitude": 105.8104190679099,
        "from_longitude": 105.81020180898399,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3351,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8104190679099,
          21.012229989840638,
          105.81060949071436,
          21.012494462440863
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8104190679099,
            21.012229989840638
          ],
          [
            105.81060949071436,
            21.012494462440863
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "NGõ 538 Đường Láng",
        "diemDau": "5-2",
        "diemCuoi": "5-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.012229989840638,
        "to_latitude": 21.012494462440863,
        "to_longitude": 105.81060949071436,
        "from_longitude": 105.8104190679099,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3352,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81060949071436,
          21.012494462440863,
          105.8108009246228,
          21.012768367164078
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81060949071436,
            21.012494462440863
          ],
          [
            105.8108009246228,
            21.012768367164078
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "NGõ 538 Đường Láng",
        "diemDau": "5-3",
        "diemCuoi": "5-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.012494462440863,
        "to_latitude": 21.012768367164078,
        "to_longitude": 105.8108009246228,
        "from_longitude": 105.81060949071436,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3353,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8108009246228,
          21.012768367164078,
          105.81099858140824,
          21.013043455667486
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8108009246228,
            21.012768367164078
          ],
          [
            105.81099858140824,
            21.013043455667486
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "NGõ 538 Đường Láng",
        "diemDau": "5-4",
        "diemCuoi": "5-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.012768367164078,
        "to_latitude": 21.013043455667486,
        "to_longitude": 105.81099858140824,
        "from_longitude": 105.8108009246228,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3354,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81056390720883,
          21.012338907389438,
          105.81064973791946,
          21.012494462440863
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81060949071436,
            21.012494462440863
          ],
          [
            105.81064973791946,
            21.012469107569853
          ],
          [
            105.81056390720883,
            21.012338907389438
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "NGõ 538 Đường Láng",
        "diemDau": "5-3",
        "diemCuoi": "5-3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.012494462440863,
        "to_latitude": 21.012338907389438,
        "to_longitude": 105.81056390720883,
        "from_longitude": 105.81060949071436,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3355,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8104190679099,
          21.012108142402624,
          105.8106127530135,
          21.012229989840638
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8104190679099,
            21.012229989840638
          ],
          [
            105.8106127530135,
            21.012108142402624
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "NGõ 538 Đường Láng",
        "diemDau": "5-2",
        "diemCuoi": "5-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.012229989840638,
        "to_latitude": 21.012108142402624,
        "to_longitude": 105.8106127530135,
        "from_longitude": 105.8104190679099,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3356,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83921526391839,
          21.021341231150565,
          105.83926890808219,
          21.0213988162347
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83921526391839,
            21.0213988162347
          ],
          [
            105.83926890808219,
            21.021341231150565
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 8,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 88/97 Trần Quý Cáp",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.0213988162347,
        "to_latitude": 21.021341231150565,
        "to_longitude": 105.83926890808219,
        "from_longitude": 105.83921526391839,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3357,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83926890808219,
          21.021328712651925,
          105.83941374737213,
          21.021341231150565
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83926890808219,
            21.021341231150565
          ],
          [
            105.83941374737213,
            21.021328712651925
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 88/97 Trần Quý Cáp",
        "diemDau": "11-1",
        "diemCuoi": "11-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.021341231150565,
        "to_latitude": 21.021328712651925,
        "to_longitude": 105.83941374737213,
        "from_longitude": 105.83926890808219,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3358,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83941374737213,
          21.021328712651925,
          105.8394164295799,
          21.02146891977693
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83941374737213,
            21.021328712651925
          ],
          [
            105.8394164295799,
            21.02146891977693
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 88/97 Trần Quý Cáp",
        "diemDau": "11-2",
        "diemCuoi": "11-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.021328712651925,
        "to_latitude": 21.02146891977693,
        "to_longitude": 105.8394164295799,
        "from_longitude": 105.83941374737213,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3359,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83941508849398,
          21.02146891977693,
          105.83948080260163,
          21.021555297314517
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8394164295799,
            21.02146891977693
          ],
          [
            105.83941508849398,
            21.021555297314517
          ],
          [
            105.83948080260163,
            21.021549038074735
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 88/97 Trần Quý Cáp",
        "diemDau": "11-3",
        "diemCuoi": "11-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02146891977693,
        "to_latitude": 21.021549038074735,
        "to_longitude": 105.83948080260163,
        "from_longitude": 105.8394164295799,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3360,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83914552648116,
          21.022019732210577,
          105.83933059889621,
          21.02204727277482
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83914552648116,
            21.022019732210577
          ],
          [
            105.83933059889621,
            21.02204727277482
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 88/97 Trần Quý Cáp",
        "diemDau": "12",
        "diemCuoi": "12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.022019732210577,
        "to_latitude": 21.02204727277482,
        "to_longitude": 105.83933059889621,
        "from_longitude": 105.83914552648116,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3361,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83914552648116,
          21.022019732210577,
          105.8393010945931,
          21.022167449739626
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83914552648116,
            21.022019732210577
          ],
          [
            105.83916027863275,
            21.022166197896485
          ],
          [
            105.8393010945931,
            21.022167449739626
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 88/97 Trần Quý Cáp",
        "diemDau": "12",
        "diemCuoi": "12-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.022019732210577,
        "to_latitude": 21.022167449739626,
        "to_longitude": 105.8393010945931,
        "from_longitude": 105.83914552648116,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3362,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83910797555481,
          21.022332692911863,
          105.83922331052365,
          21.022377759195805
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83910797555481,
            21.022332692911863
          ],
          [
            105.83915491421723,
            21.022371499988886
          ],
          [
            105.83922331052365,
            21.022377759195805
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 88/97 Trần Quý Cáp",
        "diemDau": "13",
        "diemCuoi": "13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.022332692911863,
        "to_latitude": 21.022377759195805,
        "to_longitude": 105.83922331052365,
        "from_longitude": 105.83910797555481,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3363,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83910797555481,
          21.022246416624906,
          105.83923672158035,
          21.022332692911863
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83910797555481,
            21.022332692911863
          ],
          [
            105.83911832751578,
            21.022246416624906
          ],
          [
            105.83923672158035,
            21.02226509345284
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 88/97 Trần Quý Cáp",
        "diemDau": "13",
        "diemCuoi": "13-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.022332692911863,
        "to_latitude": 21.02226509345284,
        "to_longitude": 105.83923672158035,
        "from_longitude": 105.83910797555481,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3364,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83889339881867,
          21.02267820076359,
          105.83918039519978,
          21.02270949672196
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83918039519978,
            21.02267820076359
          ],
          [
            105.83889339881867,
            21.02270949672196
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 88/97 Trần Quý Cáp",
        "diemDau": "14-4",
        "diemCuoi": "14-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02267820076359,
        "to_latitude": 21.02270949672196,
        "to_longitude": 105.83889339881867,
        "from_longitude": 105.83918039519978,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3365,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83918039519978,
          21.02267820076359,
          105.83948884922486,
          21.022688215468058
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83918039519978,
            21.02267820076359
          ],
          [
            105.83948884922486,
            21.022688215468058
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 88/97 Trần Quý Cáp",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02267820076359,
        "to_latitude": 21.022688215468058,
        "to_longitude": 105.83948884922486,
        "from_longitude": 105.83918039519978,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3366,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83930780012142,
          21.022962367847914,
          105.83946202712949,
          21.02301494496113
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83930780012142,
            21.022962367847914
          ],
          [
            105.83946202712949,
            21.02301494496113
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 88/97 Trần Quý Cáp",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.022962367847914,
        "to_latitude": 21.02301494496113,
        "to_longitude": 105.83946202712949,
        "from_longitude": 105.83930780012142,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3367,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83945263939339,
          21.02253298741318,
          105.83948884922486,
          21.022688215468058
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83948884922486,
            21.022688215468058
          ],
          [
            105.83945263939339,
            21.02253298741318
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 88/97 Trần Quý Cáp",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.022688215468058,
        "to_latitude": 21.02253298741318,
        "to_longitude": 105.83945263939339,
        "from_longitude": 105.83948884922486,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3368,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83944995718566,
          21.02231892265213,
          105.83945263939339,
          21.02253298741318
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83945263939339,
            21.02253298741318
          ],
          [
            105.83944995718566,
            21.02231892265213
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 88/97 Trần Quý Cáp",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02253298741318,
        "to_latitude": 21.02231892265213,
        "to_longitude": 105.83944995718566,
        "from_longitude": 105.83945263939339,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3369,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83948884922486,
          21.022688215468058,
          105.83949019031974,
          21.022788362515204
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83948884922486,
            21.022688215468058
          ],
          [
            105.83949019031974,
            21.022788362515204
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 88/97 Trần Quý Cáp",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.022688215468058,
        "to_latitude": 21.022788362515204,
        "to_longitude": 105.83949019031974,
        "from_longitude": 105.83948884922486,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3370,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82395036521892,
          21.0144960064646,
          105.8240897221265,
          21.0148051800114
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82395036521892,
            21.0148051800114
          ],
          [
            105.8240897221265,
            21.0144960064646
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 71 Trần Quang Diệu",
        "diemDau": "09",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.0148051800114,
        "to_latitude": 21.0144960064646,
        "to_longitude": 105.8240897221265,
        "from_longitude": 105.82395036521892,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3371,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82437161278006,
          21.01487716451124,
          105.8246740249791,
          21.014999443314068
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82437161278006,
            21.014999443314068
          ],
          [
            105.8246740249791,
            21.01487716451124
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT VP Chính Phủ",
        "diemDau": "17",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.014999443314068,
        "to_latitude": 21.01487716451124,
        "to_longitude": 105.8246740249791,
        "from_longitude": 105.82437161278006,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3372,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8246740249791,
          21.01483960738337,
          105.82470352927324,
          21.01487716451124
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8246740249791,
            21.01487716451124
          ],
          [
            105.82470352927324,
            21.01483960738337
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 6,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT VP Chính Phủ",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.01487716451124,
        "to_latitude": 21.01483960738337,
        "to_longitude": 105.82470352927324,
        "from_longitude": 105.8246740249791,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3373,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8246740249791,
          21.01487716451124,
          105.82478593081397,
          21.015092116415016
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8246740249791,
            21.01487716451124
          ],
          [
            105.82478593081397,
            21.015092116415016
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT VP Chính Phủ",
        "diemDau": "12",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.01487716451124,
        "to_latitude": 21.015092116415016,
        "to_longitude": 105.82478593081397,
        "from_longitude": 105.8246740249791,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3374,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82441485135423,
          21.014455238108546,
          105.8246740249791,
          21.01487716451124
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8246740249791,
            21.01487716451124
          ],
          [
            105.82441485135423,
            21.014455238108546
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HCA2",
        "chieuDaiQL": 58,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT VP Chính Phủ",
        "diemDau": "12",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Cá 2",
        "from_latitude": 21.01487716451124,
        "to_latitude": 21.014455238108546,
        "to_longitude": 105.82441485135423,
        "from_longitude": 105.8246740249791,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3375,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80920268616892,
          21.01220933304911,
          105.80952991564818,
          21.01242904598843
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80920268616892,
            21.012281318810153
          ],
          [
            105.8093468549114,
            21.01220933304911
          ],
          [
            105.80952991564818,
            21.01242904598843
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "diemDau": "09",
        "diemCuoi": "9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.012281318810153,
        "to_latitude": 21.01242904598843,
        "to_longitude": 105.80952991564818,
        "from_longitude": 105.80920268616892,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3376,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80952991564818,
          21.01242904598843,
          105.80980350096364,
          21.012662529745118
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80952991564818,
            21.01242904598843
          ],
          [
            105.80968481324858,
            21.012619964368277
          ],
          [
            105.8096982242873,
            21.012662529745118
          ],
          [
            105.80979076049931,
            21.012599933597922
          ],
          [
            105.80980350096364,
            21.012615582642198
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "diemDau": "9-1",
        "diemCuoi": "9-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.01242904598843,
        "to_latitude": 21.012615582642198,
        "to_longitude": 105.80980350096364,
        "from_longitude": 105.80952991564818,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3377,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80980350096364,
          21.012615582642198,
          105.81001941879467,
          21.012882242016893
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80980350096364,
            21.012615582642198
          ],
          [
            105.81001941879467,
            21.012882242016893
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "diemDau": "9-2",
        "diemCuoi": "9-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.012615582642198,
        "to_latitude": 21.012882242016893,
        "to_longitude": 105.81001941879467,
        "from_longitude": 105.80980350096364,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3378,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81001941879467,
          21.012882242016893,
          105.81022326667288,
          21.013102579919217
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81001941879467,
            21.012882242016893
          ],
          [
            105.81022326667288,
            21.013102579919217
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "diemDau": "9-3",
        "diemCuoi": "9-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.012882242016893,
        "to_latitude": 21.013102579919217,
        "to_longitude": 105.81022326667288,
        "from_longitude": 105.81001941879467,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3379,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81022326667288,
          21.013102579919217,
          105.81045930107096,
          21.013341696252944
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81022326667288,
            21.013102579919217
          ],
          [
            105.81045930107096,
            21.013341696252944
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "diemDau": "9-5",
        "diemCuoi": "9-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.013102579919217,
        "to_latitude": 21.013341696252944,
        "to_longitude": 105.81045930107096,
        "from_longitude": 105.81022326667288,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3380,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81045930107096,
          21.013341696252944,
          105.81064973791047,
          21.013535743256572
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81045930107096,
            21.013341696252944
          ],
          [
            105.81064973791047,
            21.013535743256572
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "diemDau": "9-6",
        "diemCuoi": "9-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.013341696252944,
        "to_latitude": 21.013535743256572,
        "to_longitude": 105.81064973791047,
        "from_longitude": 105.81045930107096,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3381,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81013877709307,
          21.013102579919217,
          105.81022326667288,
          21.01317519120218
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81022326667288,
            21.013102579919217
          ],
          [
            105.81013877709307,
            21.01317519120218
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "diemDau": "9-5",
        "diemCuoi": "9-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.013102579919217,
        "to_latitude": 21.01317519120218,
        "to_longitude": 105.81013877709307,
        "from_longitude": 105.81022326667288,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3382,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80964256842725,
          21.012662529745118,
          105.8096982242873,
          21.01274828642637
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8096982242873,
            21.012662529745118
          ],
          [
            105.80964256842725,
            21.01274828642637
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "diemDau": "9-1",
        "diemCuoi": "9-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.012662529745118,
        "to_latitude": 21.01274828642637,
        "to_longitude": 105.80964256842725,
        "from_longitude": 105.8096982242873,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3383,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8093810530646,
          21.01274828642637,
          105.80964256842725,
          21.012953601485016
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80964256842725,
            21.01274828642637
          ],
          [
            105.8093810530646,
            21.012953601485016
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "diemDau": "9-8",
        "diemCuoi": "9-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.01274828642637,
        "to_latitude": 21.012953601485016,
        "to_longitude": 105.8093810530646,
        "from_longitude": 105.80964256842725,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3384,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80921911472953,
          21.012953601485016,
          105.8093810530646,
          21.01311290824929
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8093810530646,
            21.012953601485016
          ],
          [
            105.80921911472953,
            21.01311290824929
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "diemDau": "9-9",
        "diemCuoi": "9-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.012953601485016,
        "to_latitude": 21.01311290824929,
        "to_longitude": 105.80921911472953,
        "from_longitude": 105.8093810530646,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3385,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80899380917138,
          21.01311290824929,
          105.80921911472953,
          21.013321978558224
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80921911472953,
            21.01311290824929
          ],
          [
            105.80899380917138,
            21.013321978558224
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "diemDau": "9-13",
        "diemCuoi": "9-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.01311290824929,
        "to_latitude": 21.013321978558224,
        "to_longitude": 105.80899380917138,
        "from_longitude": 105.80921911472953,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3386,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80878057354809,
          21.013321978558224,
          105.80899380917138,
          21.013516025588338
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80899380917138,
            21.013321978558224
          ],
          [
            105.80878057354809,
            21.013516025588338
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "diemDau": "9-14",
        "diemCuoi": "9-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.013321978558224,
        "to_latitude": 21.013516025588338,
        "to_longitude": 105.80878057354809,
        "from_longitude": 105.80899380917138,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3387,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80852308148809,
          21.013516025588338,
          105.80878057354809,
          21.01374011829523
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80878057354809,
            21.013516025588338
          ],
          [
            105.80852308148809,
            21.01374011829523
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "diemDau": "9-15",
        "diemCuoi": "9-16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.013516025588338,
        "to_latitude": 21.01374011829523,
        "to_longitude": 105.80852308148809,
        "from_longitude": 105.80878057354809,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3388,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80829107041062,
          21.01374011829523,
          105.80852308148809,
          21.013931660957684
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80852308148809,
            21.01374011829523
          ],
          [
            105.80829107041062,
            21.013931660957684
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "diemDau": "9-16",
        "diemCuoi": "9-17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.01374011829523,
        "to_latitude": 21.013931660957684,
        "to_longitude": 105.80829107041062,
        "from_longitude": 105.80852308148809,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3389,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80806308264474,
          21.013914447162435,
          105.80829107041062,
          21.01407062312657
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80829107041062,
            21.013931660957684
          ],
          [
            105.80826056025487,
            21.013914447162435
          ],
          [
            105.80806308264474,
            21.01407062312657
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "diemDau": "9-17",
        "diemCuoi": "9-18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.013931660957684,
        "to_latitude": 21.01407062312657,
        "to_longitude": 105.80806308264474,
        "from_longitude": 105.80829107041062,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3390,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8077211010049,
          21.01407062312657,
          105.80806475899988,
          21.014432424925307
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80806308264474,
            21.01407062312657
          ],
          [
            105.80806475899988,
            21.01411475297469
          ],
          [
            105.8077211010049,
            21.014432424925307
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "diemDau": "9-18",
        "diemCuoi": "9-19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.01407062312657,
        "to_latitude": 21.014432424925307,
        "to_longitude": 105.8077211010049,
        "from_longitude": 105.80806308264474,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3391,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80749043102232,
          21.014432424925307,
          105.8077211010049,
          21.014684058183757
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8077211010049,
            21.014432424925307
          ],
          [
            105.80749043102232,
            21.014684058183757
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "diemDau": "9-19",
        "diemCuoi": "9-20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.014432424925307,
        "to_latitude": 21.014684058183757,
        "to_longitude": 105.80749043102232,
        "from_longitude": 105.8077211010049,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3392,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80731876965498,
          21.014684058183757,
          105.80749043102232,
          21.01484305012425
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80749043102232,
            21.014684058183757
          ],
          [
            105.80731876965498,
            21.01484305012425
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "diemDau": "9-20",
        "diemCuoi": "Tủ phân đoạn",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.014684058183757,
        "to_latitude": 21.01484305012425,
        "to_longitude": 105.80731876965498,
        "from_longitude": 105.80749043102232,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3393,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80711894508836,
          21.01484305012425,
          105.80731876965498,
          21.015063385129928
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80731876965498,
            21.01484305012425
          ],
          [
            105.80711894508836,
            21.015063385129928
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "diemDau": "Tủ phân đoạn",
        "diemCuoi": "9-21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.01484305012425,
        "to_latitude": 21.015063385129928,
        "to_longitude": 105.80711894508836,
        "from_longitude": 105.80731876965498,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3394,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8069043683612,
          21.015063385129928,
          105.80711894508836,
          21.015286223611938
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80711894508836,
            21.015063385129928
          ],
          [
            105.8069043683612,
            21.015286223611938
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "diemDau": "9-21",
        "diemCuoi": "9-22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.015063385129928,
        "to_latitude": 21.015286223611938,
        "to_longitude": 105.8069043683612,
        "from_longitude": 105.80711894508836,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3395,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80670320269071,
          21.015286223611938,
          105.8069043683612,
          21.01549904657096
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8069043683612,
            21.015286223611938
          ],
          [
            105.80670320269071,
            21.01549904657096
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "diemDau": "9-22",
        "diemCuoi": "9-23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.015286223611938,
        "to_latitude": 21.01549904657096,
        "to_longitude": 105.80670320269071,
        "from_longitude": 105.8069043683612,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3396,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80644839282947,
          21.01549904657096,
          105.80670320269071,
          21.015789486824076
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80670320269071,
            21.01549904657096
          ],
          [
            105.80644839282947,
            21.015789486824076
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "diemDau": "9-23",
        "diemCuoi": "9-24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.01549904657096,
        "to_latitude": 21.015789486824076,
        "to_longitude": 105.80644839282947,
        "from_longitude": 105.80670320269071,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3397,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8061533498431,
          21.015789486824076,
          105.80644839282947,
          21.01611497953921
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80644839282947,
            21.015789486824076
          ],
          [
            105.8061533498431,
            21.01611497953921
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "diemDau": "9-24",
        "diemCuoi": "LH2 9-25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.015789486824076,
        "to_latitude": 21.01611497953921,
        "to_longitude": 105.8061533498431,
        "from_longitude": 105.80644839282947,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3398,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83916832522902,
          21.02385617621939,
          105.83951164799066,
          21.02387119816178
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83951164799066,
            21.02387119816178
          ],
          [
            105.83916832522902,
            21.02385617621939
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51 Trần Quý Cáp",
        "diemDau": "17",
        "diemCuoi": "17-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02387119816178,
        "to_latitude": 21.02385617621939,
        "to_longitude": 105.83916832522902,
        "from_longitude": 105.83951164799066,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3399,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83908249453634,
          21.02385617621939,
          105.83916832522902,
          21.023933789577747
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83916832522902,
            21.02385617621939
          ],
          [
            105.83911736329088,
            21.023933789577747
          ],
          [
            105.83908249453634,
            21.023923774953605
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51 Trần Quý Cáp",
        "diemDau": "17-1",
        "diemCuoi": "17-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02385617621939,
        "to_latitude": 21.023923774953605,
        "to_longitude": 105.83908249453634,
        "from_longitude": 105.83916832522902,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3400,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8389778883895,
          21.02371597133861,
          105.83916832522902,
          21.02385617621939
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83916832522902,
            21.02385617621939
          ],
          [
            105.8389778883895,
            21.02371597133861
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51 Trần Quý Cáp",
        "diemDau": "17-1",
        "diemCuoi": "17-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02385617621939,
        "to_latitude": 21.02371597133861,
        "to_longitude": 105.8389778883895,
        "from_longitude": 105.83916832522902,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3401,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8388089092209,
          21.023580773651123,
          105.8389778883895,
          21.02371597133861
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8389778883895,
            21.02371597133861
          ],
          [
            105.8388089092209,
            21.023580773651123
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51 Trần Quý Cáp",
        "diemDau": "17-5",
        "diemCuoi": "17-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02371597133861,
        "to_latitude": 21.023580773651123,
        "to_longitude": 105.8388089092209,
        "from_longitude": 105.8389778883895,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3402,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8388089092209,
          21.023498152781283,
          105.8388598712219,
          21.023580773651123
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8388089092209,
            21.023580773651123
          ],
          [
            105.8388598712219,
            21.02355573702808
          ],
          [
            105.83882500247633,
            21.023498152781283
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51 Trần Quý Cáp",
        "diemDau": "17-6",
        "diemCuoi": "17-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.023580773651123,
        "to_latitude": 21.023498152781283,
        "to_longitude": 105.83882500247633,
        "from_longitude": 105.8388089092209,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3403,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8385782392473,
          21.023580773651123,
          105.8388089092209,
          21.023608313930335
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8388089092209,
            21.023580773651123
          ],
          [
            105.8385782392473,
            21.023608313930335
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51 Trần Quý Cáp",
        "diemDau": "17-6",
        "diemCuoi": "17-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.023580773651123,
        "to_latitude": 21.023608313930335,
        "to_longitude": 105.8385782392473,
        "from_longitude": 105.8388089092209,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3404,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83845485762828,
          21.023608313930335,
          105.8385782392473,
          21.023650876170738
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8385782392473,
            21.023608313930335
          ],
          [
            105.83845485762828,
            21.023650876170738
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51 Trần Quý Cáp",
        "diemDau": "17-8",
        "diemCuoi": "17-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.023608313930335,
        "to_latitude": 21.023650876170738,
        "to_longitude": 105.83845485762828,
        "from_longitude": 105.8385782392473,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3405,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83827246741198,
          21.023650876170738,
          105.83845485762828,
          21.023873701820214
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83845485762828,
            21.023650876170738
          ],
          [
            105.83827246741198,
            21.023873701820214
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51 Trần Quý Cáp",
        "diemDau": "17-9",
        "diemCuoi": "17-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.023650876170738,
        "to_latitude": 21.023873701820214,
        "to_longitude": 105.83827246741198,
        "from_longitude": 105.83845485762828,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3406,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83845485762828,
          21.023518182086928,
          105.8385111840178,
          21.023650876170738
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83845485762828,
            21.023650876170738
          ],
          [
            105.83850313742153,
            21.023603306606997
          ],
          [
            105.8385111840178,
            21.023518182086928
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51 Trần Quý Cáp",
        "diemDau": "17-9",
        "diemCuoi": "17-17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.023650876170738,
        "to_latitude": 21.023518182086928,
        "to_longitude": 105.8385111840178,
        "from_longitude": 105.83845485762828,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3407,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83813031033878,
          21.023873701820214,
          105.83827246741198,
          21.023933789578596
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83827246741198,
            21.023873701820214
          ],
          [
            105.83813031033878,
            21.023933789578596
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51 Trần Quý Cáp",
        "diemDau": "17-10",
        "diemCuoi": "17-16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.023873701820214,
        "to_latitude": 21.023933789578596,
        "to_longitude": 105.83813031033878,
        "from_longitude": 105.83827246741198,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3408,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83827246741198,
          21.023873701820214,
          105.8383690269446,
          21.024091519828875
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83827246741198,
            21.023873701820214
          ],
          [
            105.8383690269446,
            21.024091519828875
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51 Trần Quý Cáp",
        "diemDau": "17-10",
        "diemCuoi": "17-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.023873701820214,
        "to_latitude": 21.024091519828875,
        "to_longitude": 105.8383690269446,
        "from_longitude": 105.83827246741198,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3409,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8383690269446,
          21.024033935788175,
          105.83859969691821,
          21.02412657097312
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8383690269446,
            21.024091519828875
          ],
          [
            105.83848436195835,
            21.024033935788175
          ],
          [
            105.83859969691821,
            21.02412657097312
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51 Trần Quý Cáp",
        "diemDau": "17-11",
        "diemCuoi": "17-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.024091519828875,
        "to_latitude": 21.02412657097312,
        "to_longitude": 105.83859969691821,
        "from_longitude": 105.8383690269446,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3410,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83859969691821,
          21.02412657097312,
          105.8386640699669,
          21.024249249912273
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83859969691821,
            21.02412657097312
          ],
          [
            105.8386640699669,
            21.02414910384652
          ],
          [
            105.83864261226005,
            21.024249249912273
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51 Trần Quý Cáp",
        "diemDau": "17-12",
        "diemCuoi": "17-12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02412657097312,
        "to_latitude": 21.024249249912273,
        "to_longitude": 105.83864261226005,
        "from_longitude": 105.83859969691821,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3411,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83791305140389,
          21.023741007933936,
          105.83827246741198,
          21.023873701820214
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83827246741198,
            21.023873701820214
          ],
          [
            105.83791305140389,
            21.023741007933936
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51 Trần Quý Cáp",
        "diemDau": "17-10",
        "diemCuoi": "17-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.023873701820214,
        "to_latitude": 21.023741007933936,
        "to_longitude": 105.83791305140389,
        "from_longitude": 105.83827246741198,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3412,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83770115689343,
          21.023741007933936,
          105.83791305140389,
          21.023771051842893
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83791305140389,
            21.023741007933936
          ],
          [
            105.83770115689343,
            21.023771051842893
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51 Trần Quý Cáp",
        "diemDau": "17-13",
        "diemCuoi": "17-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.023741007933936,
        "to_latitude": 21.023771051842893,
        "to_longitude": 105.83770115689343,
        "from_longitude": 105.83791305140389,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3413,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83770115689343,
          21.023633350544547,
          105.83781380970844,
          21.023771051842893
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83770115689343,
            21.023771051842893
          ],
          [
            105.83781380970844,
            21.023725985977585
          ],
          [
            105.83780039863379,
            21.023633350544547
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51 Trần Quý Cáp",
        "diemDau": "17-14",
        "diemCuoi": "17-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.023771051842893,
        "to_latitude": 21.023633350544547,
        "to_longitude": 105.83780039863379,
        "from_longitude": 105.83770115689343,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3414,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83913882092588,
          21.023593291960644,
          105.83916832522902,
          21.02385617621939
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83916832522902,
            21.02385617621939
          ],
          [
            105.83913882092588,
            21.023593291960644
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51 Trần Quý Cáp",
        "diemDau": "17-1",
        "diemCuoi": "17-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02385617621939,
        "to_latitude": 21.023593291960644,
        "to_longitude": 105.83913882092588,
        "from_longitude": 105.83916832522902,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3415,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83913882092588,
          21.02337046589286,
          105.83917637185225,
          21.023593291960644
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83913882092588,
            21.023593291960644
          ],
          [
            105.83917637185225,
            21.02337046589286
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51 Trần Quý Cáp",
        "diemDau": "17-2",
        "diemCuoi": "17-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.023593291960644,
        "to_latitude": 21.02337046589286,
        "to_longitude": 105.83917637185225,
        "from_longitude": 105.83913882092588,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3416,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83917637185225,
          21.023250289897792,
          105.83918710069219,
          21.02337046589286
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83917637185225,
            21.02337046589286
          ],
          [
            105.83918710069219,
            21.023250289897792
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51 Trần Quý Cáp",
        "diemDau": "17-3",
        "diemCuoi": "17-3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02337046589286,
        "to_latitude": 21.023250289897792,
        "to_longitude": 105.83918710069219,
        "from_longitude": 105.83917637185225,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3417,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83907176570537,
          21.02328784490645,
          105.83917637185225,
          21.023398006210932
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83917637185225,
            21.02337046589286
          ],
          [
            105.83912272770638,
            21.023398006210932
          ],
          [
            105.83907176570537,
            21.02328784490645
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51 Trần Quý Cáp",
        "diemDau": "17-3",
        "diemCuoi": "17-3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02337046589286,
        "to_latitude": 21.02328784490645,
        "to_longitude": 105.83907176570537,
        "from_longitude": 105.83917637185225,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3418,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80670320269071,
          21.01549904657096,
          105.8069674003779,
          21.015659289532902
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80670320269071,
            21.01549904657096
          ],
          [
            105.80677327536124,
            21.01550311523568
          ],
          [
            105.8069674003779,
            21.015659289532902
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Cơ Yếu",
        "diemDau": "9-23",
        "diemCuoi": "9-27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.01549904657096,
        "to_latitude": 21.015659289532902,
        "to_longitude": 105.8069674003779,
        "from_longitude": 105.80670320269071,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3419,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8069674003779,
          21.01559804451161,
          105.80709528265874,
          21.015659289532902
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8069674003779,
            21.015659289532902
          ],
          [
            105.80709528265874,
            21.01559804451161
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Cơ Yếu",
        "diemDau": "9-27",
        "diemCuoi": "9-28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.015659289532902,
        "to_latitude": 21.01559804451161,
        "to_longitude": 105.80709528265874,
        "from_longitude": 105.8069674003779,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3420,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80682256108796,
          21.015659289532902,
          105.8069674003779,
          21.015759441300986
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8069674003779,
            21.015659289532902
          ],
          [
            105.80694493673758,
            21.015702167016926
          ],
          [
            105.80682256108796,
            21.015759441300986
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Cơ Yếu",
        "diemDau": "9-27",
        "diemCuoi": "9-26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.015659289532902,
        "to_latitude": 21.015759441300986,
        "to_longitude": 105.80682256108796,
        "from_longitude": 105.8069674003779,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3421,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80594246111342,
          21.014668096379744,
          105.80621202309929,
          21.014810187627667
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80594246111342,
            21.01474258476591
          ],
          [
            105.80604907892057,
            21.014668096379744
          ],
          [
            105.80621202309929,
            21.014810187627667
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 640 Đường Láng",
        "diemDau": "25",
        "diemCuoi": "25-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.01474258476591,
        "to_latitude": 21.014810187627667,
        "to_longitude": 105.80621202309929,
        "from_longitude": 105.80594246111342,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3422,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80621202309929,
          21.014810187627667,
          105.80644135196901,
          21.015075591176995
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80621202309929,
            21.014810187627667
          ],
          [
            105.80644135196901,
            21.015075591176995
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 640 Đường Láng",
        "diemDau": "25-1",
        "diemCuoi": "25-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.014810187627667,
        "to_latitude": 21.015075591176995,
        "to_longitude": 105.80644135196901,
        "from_longitude": 105.80621202309929,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3423,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80644135196901,
          21.015075591176995,
          105.80651846549999,
          21.015217056083035
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80644135196901,
            21.015075591176995
          ],
          [
            105.80651846549999,
            21.015161346464147
          ],
          [
            105.80647219737601,
            21.015217056083035
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 640 Đường Láng",
        "diemDau": "25-2",
        "diemCuoi": "25-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.015075591176995,
        "to_latitude": 21.015217056083035,
        "to_longitude": 105.80647219737601,
        "from_longitude": 105.80644135196901,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3424,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8071169333652,
          21.014651508629722,
          105.80731876965498,
          21.01484305012425
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80731876965498,
            21.01484305012425
          ],
          [
            105.80725204966777,
            21.014839607380875
          ],
          [
            105.80724132082781,
            21.01478452357733
          ],
          [
            105.8071169333652,
            21.014651508629722
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "NGõ 594 Đường Láng",
        "diemDau": "Tủ phân đoạn",
        "diemCuoi": "9-29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.01484305012425,
        "to_latitude": 21.014651508629722,
        "to_longitude": 105.8071169333652,
        "from_longitude": 105.80731876965498,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3425,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80695197750521,
          21.014528404490864,
          105.8071169333652,
          21.014651508629722
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8071169333652,
            21.014651508629722
          ],
          [
            105.80696907661624,
            21.014564188158843
          ],
          [
            105.80695197750521,
            21.014528404490864
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "NGõ 594 Đường Láng",
        "diemDau": "9-29",
        "diemCuoi": "9-30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.014651508629722,
        "to_latitude": 21.014528404490864,
        "to_longitude": 105.80695197750521,
        "from_longitude": 105.8071169333652,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3426,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80695197750521,
          21.014297218816957,
          105.8071518020748,
          21.014528404490864
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80695197750521,
            21.014528404490864
          ],
          [
            105.80700260423097,
            21.014440249343174
          ],
          [
            105.8071518020748,
            21.014297218816957
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "NGõ 594 Đường Láng",
        "diemDau": "9-30",
        "diemCuoi": "9-32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.014528404490864,
        "to_latitude": 21.014297218816957,
        "to_longitude": 105.8071518020748,
        "from_longitude": 105.80695197750521,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3427,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80669314433533,
          21.014528404490864,
          105.80695197750521,
          21.014807996795145
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80695197750521,
            21.014528404490864
          ],
          [
            105.80693689010538,
            21.014557928625603
          ],
          [
            105.8068242373263,
            21.014690630683333
          ],
          [
            105.80669314433533,
            21.014807996795145
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "NGõ 594 Đường Láng",
        "diemDau": "9-30",
        "diemCuoi": "9-31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.014528404490864,
        "to_latitude": 21.014807996795145,
        "to_longitude": 105.80669314433533,
        "from_longitude": 105.80695197750521,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3428,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80731876965498,
          21.01484305012425,
          105.80739890073478,
          21.014917851387523
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80731876965498,
            21.01484305012425
          ],
          [
            105.80739890073478,
            21.014917851387523
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 65/25 Vũ Ngọc Phan",
        "diemDau": "Tủ phân đoạn",
        "diemCuoi": "9-48",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.01484305012425,
        "to_latitude": 21.014917851387523,
        "to_longitude": 105.80739890073478,
        "from_longitude": 105.80731876965498,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3429,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80739890073478,
          21.014917851387523,
          105.80757324431887,
          21.015098125417364
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80739890073478,
            21.014917851387523
          ],
          [
            105.80757324431887,
            21.015098125417364
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 65/25 Vũ Ngọc Phan",
        "diemDau": "9-48",
        "diemCuoi": "9-49",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.014917851387523,
        "to_latitude": 21.015098125417364,
        "to_longitude": 105.80757324431887,
        "from_longitude": 105.80739890073478,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3430,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80757324431887,
          21.015098125417364,
          105.80773954127972,
          21.015265880221644
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80757324431887,
            21.015098125417364
          ],
          [
            105.80773954127972,
            21.015265880221644
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 65/25 Vũ Ngọc Phan",
        "diemDau": "9-49",
        "diemCuoi": "9-50",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.015098125417364,
        "to_latitude": 21.015265880221644,
        "to_longitude": 105.80773954127972,
        "from_longitude": 105.80757324431887,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3431,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80773954127972,
          21.015265880221644,
          105.80790583824056,
          21.015451161428064
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80773954127972,
            21.015265880221644
          ],
          [
            105.80790583824056,
            21.015451161428064
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 65/25 Vũ Ngọc Phan",
        "diemDau": "9-50",
        "diemCuoi": "9-51",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.015265880221644,
        "to_latitude": 21.015451161428064,
        "to_longitude": 105.80790583824056,
        "from_longitude": 105.80773954127972,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3432,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80790583824056,
          21.015451161428064,
          105.80807213518345,
          21.01557822914605
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80790583824056,
            21.015451161428064
          ],
          [
            105.808032572489,
            21.01557822914605
          ],
          [
            105.80807213518345,
            21.015556320928834
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 65/25 Vũ Ngọc Phan",
        "diemDau": "9-51",
        "diemCuoi": "9-52",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.015451161428064,
        "to_latitude": 21.015556320928834,
        "to_longitude": 105.80807213518345,
        "from_longitude": 105.80790583824056,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3433,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80807213518345,
          21.015382306951743,
          105.80834303830014,
          21.015556320928834
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80807213518345,
            21.015556320928834
          ],
          [
            105.80834303830014,
            21.015382306951743
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 65/25 Vũ Ngọc Phan",
        "diemDau": "9-52",
        "diemCuoi": "9-53",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.015556320928834,
        "to_latitude": 21.015382306951743,
        "to_longitude": 105.80834303830014,
        "from_longitude": 105.80807213518345,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3434,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83954383450153,
          21.026064385688194,
          105.83988715726319,
          21.02615952322976
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83988715726319,
            21.026064385688194
          ],
          [
            105.83954383450153,
            21.02615952322976
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Trần Quý Cáp",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.026064385688194,
        "to_latitude": 21.02615952322976,
        "to_longitude": 105.83954383450153,
        "from_longitude": 105.83988715726319,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3435,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83931048230221,
          21.02615952322976,
          105.83954383450153,
          21.026182055796667
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83954383450153,
            21.02615952322976
          ],
          [
            105.83931048230221,
            21.026182055796667
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Trần Quý Cáp",
        "diemDau": "25",
        "diemCuoi": "25.1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02615952322976,
        "to_latitude": 21.026182055796667,
        "to_longitude": 105.83931048230221,
        "from_longitude": 105.83954383450153,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3436,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83907713012087,
          21.026147005135766,
          105.83931048230221,
          21.02619207026995
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83931048230221,
            21.026182055796667
          ],
          [
            105.8392997534982,
            21.026147005135766
          ],
          [
            105.83907713012087,
            21.02619207026995
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Trần Quý Cáp",
        "diemDau": "25.1",
        "diemCuoi": "25.2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.026182055796667,
        "to_latitude": 21.02619207026995,
        "to_longitude": 105.83907713012087,
        "from_longitude": 105.83931048230221,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3437,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8397409768694,
          21.02598301800429,
          105.83988715726319,
          21.026064385688194
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83988715726319,
            21.026064385688194
          ],
          [
            105.83976109345441,
            21.02603183862097
          ],
          [
            105.8397409768694,
            21.02598301800429
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Trần Quý Cáp",
        "diemDau": "24",
        "diemCuoi": "24-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.026064385688194,
        "to_latitude": 21.02598301800429,
        "to_longitude": 105.8397409768694,
        "from_longitude": 105.83988715726319,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3438,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83932657556663,
          21.025551142640605,
          105.83954383450153,
          21.02615952322976
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83954383450153,
            21.02615952322976
          ],
          [
            105.83932657556663,
            21.025551142640605
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 61,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Trần Quý Cáp",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02615952322976,
        "to_latitude": 21.025551142640605,
        "to_longitude": 105.83932657556663,
        "from_longitude": 105.83954383450153,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3439,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8391549141903,
          21.025551142640605,
          105.83932657556663,
          21.025626251490017
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83932657556663,
            21.025551142640605
          ],
          [
            105.8391549141903,
            21.025626251490017
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Trần Quý Cáp",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.025551142640605,
        "to_latitude": 21.025626251490017,
        "to_longitude": 105.8391549141903,
        "from_longitude": 105.83932657556663,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3440,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83924610929843,
          21.025328319499327,
          105.83932657556663,
          21.025551142640605
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83932657556663,
            21.025551142640605
          ],
          [
            105.83924610929843,
            21.025328319499327
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Trần Quý Cáp",
        "diemDau": "26",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.025551142640605,
        "to_latitude": 21.025328319499327,
        "to_longitude": 105.83924610929843,
        "from_longitude": 105.83932657556663,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3441,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83924610929843,
          21.02529326863767,
          105.83936144428525,
          21.025328319499327
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83924610929843,
            21.025328319499327
          ],
          [
            105.83936144428525,
            21.02529326863767
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Trần Quý Cáp",
        "diemDau": "28",
        "diemCuoi": "28-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.025328319499327,
        "to_latitude": 21.02529326863767,
        "to_longitude": 105.83936144428525,
        "from_longitude": 105.83924610929843,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3442,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83909322338528,
          21.024905204977507,
          105.83924610929843,
          21.025328319499327
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83924610929843,
            21.025328319499327
          ],
          [
            105.83909322338528,
            21.024905204977507
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Trần Quý Cáp",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.025328319499327,
        "to_latitude": 21.024905204977507,
        "to_longitude": 105.83909322338528,
        "from_longitude": 105.83924610929843,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3443,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83900202827714,
          21.024810066635396,
          105.83909322338528,
          21.024905204977507
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83909322338528,
            21.024905204977507
          ],
          [
            105.83900202827714,
            21.024810066635396
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Trần Quý Cáp",
        "diemDau": "29",
        "diemCuoi": "21-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.024905204977507,
        "to_latitude": 21.024810066635396,
        "to_longitude": 105.83900202827714,
        "from_longitude": 105.83909322338528,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3444,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83891619759346,
          21.02446957101894,
          105.83900202827714,
          21.024810066635396
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83900202827714,
            21.024810066635396
          ],
          [
            105.83891619759346,
            21.02446957101894
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Trần Quý Cáp",
        "diemDau": "21-4",
        "diemCuoi": "21-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.024810066635396,
        "to_latitude": 21.02446957101894,
        "to_longitude": 105.83891619759346,
        "from_longitude": 105.83900202827714,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3445,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8389591129353,
          21.02556115715628,
          105.8391549141903,
          21.025626251490017
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8391549141903,
            21.025626251490017
          ],
          [
            105.83906103690137,
            21.02556115715628
          ],
          [
            105.8389591129353,
            21.02557617892768
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Trần Quý Cáp",
        "diemDau": "27",
        "diemCuoi": "27-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.025626251490017,
        "to_latitude": 21.02557617892768,
        "to_longitude": 105.8389591129353,
        "from_longitude": 105.8391549141903,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3446,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83904226141122,
          21.025458508341753,
          105.8391549141903,
          21.025626251490017
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8391549141903,
            21.025626251490017
          ],
          [
            105.83906103690137,
            21.02556115715628
          ],
          [
            105.83904226141122,
            21.025458508341753
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Trần Quý Cáp",
        "diemDau": "23-1",
        "diemCuoi": "27-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.025626251490017,
        "to_latitude": 21.025458508341753,
        "to_longitude": 105.83904226141122,
        "from_longitude": 105.8391549141903,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3447,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83892156200893,
          21.02509798511575,
          105.83904226141122,
          21.025458508341753
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83904226141122,
            21.025458508341753
          ],
          [
            105.83896715958547,
            21.02524069233052
          ],
          [
            105.83892156200893,
            21.02509798511575
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Trần Quý Cáp",
        "diemDau": "27-2",
        "diemCuoi": "27-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.025458508341753,
        "to_latitude": 21.02509798511575,
        "to_longitude": 105.83892156200893,
        "from_longitude": 105.83904226141122,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3448,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83887328224264,
          21.024905204977507,
          105.83909322338528,
          21.024925234094102
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83909322338528,
            21.024905204977507
          ],
          [
            105.83887328224264,
            21.024925234094102
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Trần Quý Cáp",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.024905204977507,
        "to_latitude": 21.024925234094102,
        "to_longitude": 105.83887328224264,
        "from_longitude": 105.83909322338528,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3449,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83878208714347,
          21.02470992094637,
          105.83887328224264,
          21.024925234094102
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83887328224264,
            21.024925234094102
          ],
          [
            105.83878208714347,
            21.02470992094637
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Trần Quý Cáp",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.024925234094102,
        "to_latitude": 21.02470992094637,
        "to_longitude": 105.83878208714347,
        "from_longitude": 105.83887328224264,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3450,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83876331168031,
          21.02450211842727,
          105.83878208714347,
          21.02470992094637
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83878208714347,
            21.02470992094637
          ],
          [
            105.83876331168031,
            21.02450211842727
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Trần Quý Cáp",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02470992094637,
        "to_latitude": 21.02450211842727,
        "to_longitude": 105.83876331168031,
        "from_longitude": 105.83878208714347,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3451,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83876331168031,
          21.02440197253224,
          105.83891083316898,
          21.02450211842727
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83876331168031,
            21.02450211842727
          ],
          [
            105.83891083316898,
            21.02440197253224
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Trần Quý Cáp",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02450211842727,
        "to_latitude": 21.02440197253224,
        "to_longitude": 105.83891083316898,
        "from_longitude": 105.83876331168031,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3452,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8077211010049,
          21.014432424925307,
          105.80780890153747,
          21.014559176558166
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8077211010049,
            21.014432424925307
          ],
          [
            105.80773384146923,
            21.01448250122424
          ],
          [
            105.80780890153747,
            21.014559176558166
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 107/51 Vũ Ngọc Phan",
        "diemDau": "9-19",
        "diemCuoi": "9-33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.014432424925307,
        "to_latitude": 21.014559176558166,
        "to_longitude": 105.80780890153747,
        "from_longitude": 105.8077211010049,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3453,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80780890153747,
          21.014559176558166,
          105.80792242849915,
          21.014678148833017
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80780890153747,
            21.014559176558166
          ],
          [
            105.80792242849915,
            21.014678148833017
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 107/51 Vũ Ngọc Phan",
        "diemDau": "9-33",
        "diemCuoi": "Lh2 9-34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.014559176558166,
        "to_latitude": 21.014678148833017,
        "to_longitude": 105.80792242849915,
        "from_longitude": 105.80780890153747,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3454,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80806308264474,
          21.01407062312657,
          105.80822300934649,
          21.014254966883374
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80806308264474,
            21.01407062312657
          ],
          [
            105.80822300934649,
            21.014254966883374
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25/73 Vũ Ngọc Phan",
        "diemDau": "9-18",
        "diemCuoi": "9-35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.01407062312657,
        "to_latitude": 21.014254966883374,
        "to_longitude": 105.80822300934649,
        "from_longitude": 105.80806308264474,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3455,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8081599774376,
          21.014254966883374,
          105.808313198611,
          21.01449408136967
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80822300934649,
            21.014254966883374
          ],
          [
            105.808313198611,
            21.01434353943827
          ],
          [
            105.8081599774376,
            21.01449408136967
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25/73 Vũ Ngọc Phan",
        "diemDau": "9-35",
        "diemCuoi": "9-36",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.014254966883374,
        "to_latitude": 21.01449408136967,
        "to_longitude": 105.8081599774376,
        "from_longitude": 105.80822300934649,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3456,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8081599774376,
          21.01449408136967,
          105.80832225107787,
          21.014675607816876
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8081599774376,
            21.01449408136967
          ],
          [
            105.80832225107787,
            21.014675607816876
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25/73 Vũ Ngọc Phan",
        "diemDau": "9-36",
        "diemCuoi": "9-37",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.01449408136967,
        "to_latitude": 21.014675607816876,
        "to_longitude": 105.80832225107787,
        "from_longitude": 105.8081599774376,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3457,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80832225107787,
          21.014675607816876,
          105.80853951001276,
          21.014854630235828
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80832225107787,
            21.014675607816876
          ],
          [
            105.808374889425,
            21.01469031770749
          ],
          [
            105.80841646366297,
            21.01474665345042
          ],
          [
            105.80845133238158,
            21.014782958696564
          ],
          [
            105.80853951001276,
            21.014854630235828
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25/73 Vũ Ngọc Phan",
        "diemDau": "9-37",
        "diemCuoi": "9-38",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.014675607816876,
        "to_latitude": 21.014854630235828,
        "to_longitude": 105.80853951001276,
        "from_longitude": 105.80832225107787,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3458,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80846038484843,
          21.014854630235828,
          105.80853951001276,
          21.015004858672825
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80853951001276,
            21.014854630235828
          ],
          [
            105.80846038484843,
            21.015004858672825
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25/73 Vũ Ngọc Phan",
        "diemDau": "9-38",
        "diemCuoi": "9-39",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.014854630235828,
        "to_latitude": 21.015004858672825,
        "to_longitude": 105.80846038484843,
        "from_longitude": 105.80853951001276,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3459,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80846038484843,
          21.015004858672825,
          105.80857571983523,
          21.01510751468434
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80846038484843,
            21.015004858672825
          ],
          [
            105.80857571983523,
            21.01510751468434
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25/73 Vũ Ngọc Phan",
        "diemDau": "9-39",
        "diemCuoi": "LH2 9-40",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.015004858672825,
        "to_latitude": 21.01510751468434,
        "to_longitude": 105.80857571983523,
        "from_longitude": 105.80846038484843,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3460,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80829107041062,
          21.013931660957684,
          105.80853816889092,
          21.014173592755526
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80829107041062,
            21.013931660957684
          ],
          [
            105.80835611396182,
            21.013964210662095
          ],
          [
            105.80850765877112,
            21.014150745395153
          ],
          [
            105.80853816889092,
            21.014173592755526
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25/59 Vũ Ngọc Phan",
        "diemDau": "9-17",
        "diemCuoi": "9-41",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.013931660957684,
        "to_latitude": 21.014173592755526,
        "to_longitude": 105.80853816889092,
        "from_longitude": 105.80829107041062,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3461,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80853816889092,
          21.014173592755526,
          105.80873397015492,
          21.01438516539378
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80853816889092,
            21.014173592755526
          ],
          [
            105.80873397015492,
            21.01438516539378
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25/59 Vũ Ngọc Phan",
        "diemDau": "9-41",
        "diemCuoi": "9-42",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.014173592755526,
        "to_latitude": 21.01438516539378,
        "to_longitude": 105.80873397015492,
        "from_longitude": 105.80853816889092,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3462,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80873397015492,
          21.01438516539378,
          105.80898341559167,
          21.01466308875785
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80873397015492,
            21.01438516539378
          ],
          [
            105.80897704535055,
            21.014621462878207
          ],
          [
            105.80898341559167,
            21.01466308875785
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25/59 Vũ Ngọc Phan",
        "diemDau": "9-42",
        "diemCuoi": "Lh2 9-43",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.01438516539378,
        "to_latitude": 21.01466308875785,
        "to_longitude": 105.80898341559167,
        "from_longitude": 105.80873397015492,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3463,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80878057354809,
          21.013516025588338,
          105.80894988794101,
          21.013717896826762
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80878057354809,
            21.013516025588338
          ],
          [
            105.80894988794101,
            21.013717896826762
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25/43 Vũ Ngoc Phan",
        "diemDau": "9-15",
        "diemCuoi": "9-44",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.013516025588338,
        "to_latitude": 21.013717896826762,
        "to_longitude": 105.80894988794101,
        "from_longitude": 105.80878057354809,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3464,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80894988794101,
          21.013717896826762,
          105.80917787570688,
          21.01388909593717
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80894988794101,
            21.013717896826762
          ],
          [
            105.80909103923346,
            21.01388909593717
          ],
          [
            105.80916748219005,
            21.013839019433984
          ],
          [
            105.80917787570688,
            21.013849347719784
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25/43 Vũ Ngoc Phan",
        "diemDau": "9-44",
        "diemCuoi": "9-45",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.013717896826762,
        "to_latitude": 21.013849347719784,
        "to_longitude": 105.80917787570688,
        "from_longitude": 105.80894988794101,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3465,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80917787570688,
          21.013849347719784,
          105.8093535604038,
          21.014090966676523
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80917787570688,
            21.013849347719784
          ],
          [
            105.80922917299507,
            21.01394042433575
          ],
          [
            105.8093535604038,
            21.014090966676523
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25/43 Vũ Ngoc Phan",
        "diemDau": "9-45",
        "diemCuoi": "9-46",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.013849347719784,
        "to_latitude": 21.014090966676523,
        "to_longitude": 105.8093535604038,
        "from_longitude": 105.80917787570688,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3466,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8093535604038,
          21.014090966676523,
          105.80950912852471,
          21.014286264614636
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8093535604038,
            21.014090966676523
          ],
          [
            105.80942497425904,
            21.01416827213588
          ],
          [
            105.80948264175247,
            21.014239630990392
          ],
          [
            105.80950912852471,
            21.014286264614636
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25/43 Vũ Ngoc Phan",
        "diemDau": "9-46",
        "diemCuoi": "LH2 9-47",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.014090966676523,
        "to_latitude": 21.014286264614636,
        "to_longitude": 105.80950912852471,
        "from_longitude": 105.8093535604038,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3467,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8093810530646,
          21.012953601485016,
          105.80953259793677,
          21.013130122131177
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8093810530646,
            21.012953601485016
          ],
          [
            105.80953259793677,
            21.013130122131177
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25/7 Vũ Ngọc Phan",
        "diemDau": "9-9",
        "diemCuoi": "9-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.012953601485016,
        "to_latitude": 21.013130122131177,
        "to_longitude": 105.80953259793677,
        "from_longitude": 105.8093810530646,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3468,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80953259793677,
          21.013130122131177,
          105.809694871595,
          21.01326783312982
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80953259793677,
            21.013130122131177
          ],
          [
            105.80958590679543,
            21.013150465806053
          ],
          [
            105.809694871595,
            21.01326783312982
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25/7 Vũ Ngọc Phan",
        "diemDau": "9-10",
        "diemCuoi": "9-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.013130122131177,
        "to_latitude": 21.01326783312982,
        "to_longitude": 105.809694871595,
        "from_longitude": 105.80953259793677,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3469,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80958892433529,
          21.01326783312982,
          105.809694871595,
          21.013340444332375
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.809694871595,
            21.01326783312982
          ],
          [
            105.80958892433529,
            21.013340444332375
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25/7 Vũ Ngọc Phan",
        "diemDau": "9-13",
        "diemCuoi": "9-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.01326783312982,
        "to_latitude": 21.013340444332375,
        "to_longitude": 105.80958892433529,
        "from_longitude": 105.809694871595,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3470,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80958892433529,
          21.013340444332375,
          105.80968146054731,
          21.01339928441947
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80958892433529,
            21.013340444332375
          ],
          [
            105.80968146054731,
            21.01339928441947
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25/7 Vũ Ngọc Phan",
        "diemDau": "9-11",
        "diemCuoi": "9-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.013340444332375,
        "to_latitude": 21.01339928441947,
        "to_longitude": 105.80968146054731,
        "from_longitude": 105.80958892433529,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3471,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80945883719693,
          21.01339928441947,
          105.80968146054731,
          21.013559529643274
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80968146054731,
            21.01339928441947
          ],
          [
            105.80945883719693,
            21.013559529643274
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25/7 Vũ Ngọc Phan",
        "diemDau": "9-12",
        "diemCuoi": "9-12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.01339928441947,
        "to_latitude": 21.013559529643274,
        "to_longitude": 105.80945883719693,
        "from_longitude": 105.80968146054731,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3472,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80945883719693,
          21.013559529643274,
          105.8096320454889,
          21.013794363739855
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80945883719693,
            21.013559529643274
          ],
          [
            105.8096320454889,
            21.013794363739855
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LH2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25/7 Vũ Ngọc Phan",
        "diemDau": "9-12-1",
        "diemCuoi": "Lh2 9-12-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LH2",
        "from_latitude": 21.013559529643274,
        "to_latitude": 21.013794363739855,
        "to_longitude": 105.8096320454889,
        "from_longitude": 105.80945883719693,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3473,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83956529217245,
          21.02502537963846,
          105.83988715726319,
          21.025082963295343
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83988715726319,
            21.02502537963846
          ],
          [
            105.83988631667155,
            21.025075203464073
          ],
          [
            105.83956529217245,
            21.025082963295343
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 80 Trần Quý Cáp",
        "diemDau": "21",
        "diemCuoi": "21-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02502537963846,
        "to_latitude": 21.025082963295343,
        "to_longitude": 105.83956529217245,
        "from_longitude": 105.83988715726319,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3474,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83956529217245,
          21.02488016857688,
          105.83958406763561,
          21.025082963295343
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83956529217245,
            21.025082963295343
          ],
          [
            105.83958406763561,
            21.02488016857688
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 80 Trần Quý Cáp",
        "diemDau": "21-1",
        "diemCuoi": "21-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.025082963295343,
        "to_latitude": 21.02488016857688,
        "to_longitude": 105.83958406763561,
        "from_longitude": 105.83956529217245,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3475,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83934266882207,
          21.02488016857688,
          105.83958406763561,
          21.02488517585734
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83958406763561,
            21.02488016857688
          ],
          [
            105.83934266882207,
            21.02488517585734
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 80 Trần Quý Cáp",
        "diemDau": "21-2",
        "diemCuoi": "21-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.02488016857688,
        "to_latitude": 21.02488517585734,
        "to_longitude": 105.83934266882207,
        "from_longitude": 105.83958406763561,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3476,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83928366022481,
          21.024299322919482,
          105.83953310567956,
          21.024334374014874
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83953310567956,
            21.024299322919482
          ],
          [
            105.83928366022481,
            21.024334374014874
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 80 Trần Quý Cáp",
        "diemDau": "18",
        "diemCuoi": "18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.024299322919482,
        "to_latitude": 21.024334374014874,
        "to_longitude": 105.83928366022481,
        "from_longitude": 105.83953310567956,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3477,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83922196941977,
          21.024334374014874,
          105.83928366022481,
          21.02453466582937
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83928366022481,
            21.024334374014874
          ],
          [
            105.83922196941977,
            21.02453466582937
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQC3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 80 Trần Quý Cáp",
        "diemDau": "18-1",
        "diemCuoi": "18-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trần Quý Cáp 3",
        "from_latitude": 21.024334374014874,
        "to_latitude": 21.02453466582937,
        "to_longitude": 105.83922196941977,
        "from_longitude": 105.83928366022481,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3478,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82698170859501,
          21.026636461830595,
          105.82722042519184,
          21.026721584569973
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82698170859501,
            21.026721584569973
          ],
          [
            105.82722042519184,
            21.026636461830595
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HHN",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Hào Nam",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Hào Nam",
        "from_latitude": 21.026721584569973,
        "to_latitude": 21.026636461830595,
        "to_longitude": 105.82722042519184,
        "from_longitude": 105.82698170859501,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3479,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82722042519184,
          21.026570116131886,
          105.82733039576317,
          21.026636461830595
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82722042519184,
            21.026636461830595
          ],
          [
            105.82733039576317,
            21.026570116131886
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HHN",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Hào Nam",
        "diemDau": "02",
        "diemCuoi": "2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Hào Nam",
        "from_latitude": 21.026636461830595,
        "to_latitude": 21.026570116131886,
        "to_longitude": 105.82733039576317,
        "from_longitude": 105.82722042519184,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3480,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82733039576317,
          21.026570116131886,
          105.82748730498794,
          21.02669780027956
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82733039576317,
            21.026570116131886
          ],
          [
            105.82742427307906,
            21.02659515224777
          ],
          [
            105.82748730498794,
            21.02669780027956
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HHN",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Hào Nam",
        "diemDau": "2",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Hào Nam",
        "from_latitude": 21.026570116131886,
        "to_latitude": 21.02669780027956,
        "to_longitude": 105.82748730498794,
        "from_longitude": 105.82733039576317,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3481,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82748730498794,
          21.02669780027956,
          105.82751680929105,
          21.026971945286267
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82748730498794,
            21.02669780027956
          ],
          [
            105.82751680929105,
            21.026781671180377
          ],
          [
            105.82750071603563,
            21.026971945286267
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HHN",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Hào Nam",
        "diemDau": "03",
        "diemCuoi": "2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Hào Nam",
        "from_latitude": 21.02669780027956,
        "to_latitude": 21.026971945286267,
        "to_longitude": 105.82750071603563,
        "from_longitude": 105.82748730498794,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3482,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82747925836475,
          21.026971945286267,
          105.82750071603563,
          21.027253600590598
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82750071603563,
            21.026971945286267
          ],
          [
            105.82747925836475,
            21.027253600590598
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HHN",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Hào Nam",
        "diemDau": "2",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Hào Nam",
        "from_latitude": 21.026971945286267,
        "to_latitude": 21.027253600590598,
        "to_longitude": 105.82747925836475,
        "from_longitude": 105.82750071603563,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3483,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82745377738219,
          21.027253600590598,
          105.82747925836475,
          21.027453888482526
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82747925836475,
            21.027253600590598
          ],
          [
            105.82745377738219,
            21.027453888482526
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HHN",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Hào Nam",
        "diemDau": "04",
        "diemCuoi": "3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Hào Nam",
        "from_latitude": 21.027253600590598,
        "to_latitude": 21.027453888482526,
        "to_longitude": 105.82745377738219,
        "from_longitude": 105.82747925836475,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3484,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82724724728723,
          21.027622881182573,
          105.82737331110502,
          21.027798132668618
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82737331110502,
            21.027622881182573
          ],
          [
            105.82724724728723,
            21.027798132668618
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HHN",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Hào Nam",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Hào Nam",
        "from_latitude": 21.027622881182573,
        "to_latitude": 21.027798132668618,
        "to_longitude": 105.82724724728723,
        "from_longitude": 105.82737331110502,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3485,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82715471107521,
          21.027411327328,
          105.82716007549071,
          21.02769423359792
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82715471107521,
            21.02769423359792
          ],
          [
            105.82716007549071,
            21.027411327328
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HHN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Hào Nam",
        "diemDau": "4",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Hào Nam",
        "from_latitude": 21.02769423359792,
        "to_latitude": 21.027411327328,
        "to_longitude": 105.82716007549071,
        "from_longitude": 105.82715471107521,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3486,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82714800554687,
          21.02769423359792,
          105.82724724728723,
          21.027798132668618
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82724724728723,
            21.027798132668618
          ],
          [
            105.82714800554687,
            21.027719269525015
          ],
          [
            105.82715471107521,
            21.02769423359792
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HHN",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Hào Nam",
        "diemDau": "06",
        "diemCuoi": "4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Hào Nam",
        "from_latitude": 21.027798132668618,
        "to_latitude": 21.02769423359792,
        "to_longitude": 105.82715471107521,
        "from_longitude": 105.82724724728723,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3487,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82704339940003,
          21.02718850696753,
          105.82716007549071,
          21.027411327328
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82716007549071,
            21.027411327328
          ],
          [
            105.82704474050391,
            21.02727112579197
          ],
          [
            105.82704339940003,
            21.02718850696753
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HHN",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Hào Nam",
        "diemDau": "07",
        "diemCuoi": "8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Hào Nam",
        "from_latitude": 21.027411327328,
        "to_latitude": 21.02718850696753,
        "to_longitude": 105.82704339940003,
        "from_longitude": 105.82716007549071,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3488,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82698975521824,
          21.026807959064914,
          105.82707960922252,
          21.02697444889181
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82707960922252,
            21.02697444889181
          ],
          [
            105.82698975521824,
            21.026807959064914
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HHN",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Hào Nam",
        "diemDau": "08",
        "diemCuoi": "6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Hào Nam",
        "from_latitude": 21.02697444889181,
        "to_latitude": 21.026807959064914,
        "to_longitude": 105.82698975521824,
        "from_longitude": 105.82707960922252,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3489,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82688246685466,
          21.026721584569973,
          105.82698170859501,
          21.02673034720221
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82698170859501,
            21.026721584569973
          ],
          [
            105.82688246685466,
            21.02673034720221
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HHN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Hào Nam",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Hào Nam",
        "from_latitude": 21.026721584569973,
        "to_latitude": 21.02673034720221,
        "to_longitude": 105.82688246685466,
        "from_longitude": 105.82698170859501,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3490,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82704339940003,
          21.02697444889181,
          105.82707960922252,
          21.02718850696753
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82704339940003,
            21.02718850696753
          ],
          [
            105.82707960922252,
            21.02697444889181
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HHN",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Hào Nam",
        "diemDau": "5",
        "diemCuoi": "8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Hào Nam",
        "from_latitude": 21.02718850696753,
        "to_latitude": 21.02697444889181,
        "to_longitude": 105.82707960922252,
        "from_longitude": 105.82704339940003,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3491,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82737331110502,
          21.027453888482526,
          105.82746852953375,
          21.027622881182573
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82737331110502,
            21.027622881182573
          ],
          [
            105.82746852953375,
            21.027508967605556
          ],
          [
            105.82745377738219,
            21.027453888482526
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HHN",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Hào Nam",
        "diemDau": "3",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hồ Hào Nam",
        "from_latitude": 21.027622881182573,
        "to_latitude": 21.027453888482526,
        "to_longitude": 105.82745377738219,
        "from_longitude": 105.82737331110502,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3492,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8414914750478,
          21.014934299184965,
          105.84149232490051,
          21.015118781802947
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84149232490051,
            21.015118781802947
          ],
          [
            105.8414914750478,
            21.014934299184965
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.015118781802947,
        "to_latitude": 21.014934299184965,
        "to_longitude": 105.8414914750478,
        "from_longitude": 105.84149232490051,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3493,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84148528551779,
          21.014643373997956,
          105.8414914750478,
          21.014934299184965
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8414914750478,
            21.014934299184965
          ],
          [
            105.84148528551779,
            21.014643373997956
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "CV1",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.014934299184965,
        "to_latitude": 21.014643373997956,
        "to_longitude": 105.84148528551779,
        "from_longitude": 105.8414914750478,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3494,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84148528551779,
          21.01435828173611,
          105.84148898183355,
          21.014643373997956
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84148528551779,
            21.014643373997956
          ],
          [
            105.84148898183355,
            21.01435828173611
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "03",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.014643373997956,
        "to_latitude": 21.01435828173611,
        "to_longitude": 105.84148898183355,
        "from_longitude": 105.84148528551779,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3495,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84148123166405,
          21.01408164834162,
          105.84148898183355,
          21.01435828173611
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84148898183355,
            21.01435828173611
          ],
          [
            105.84148123166405,
            21.01408164834162
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "05",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.01435828173611,
        "to_latitude": 21.01408164834162,
        "to_longitude": 105.84148123166405,
        "from_longitude": 105.84148898183355,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3496,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84148123166405,
          21.013805003419336,
          105.84148851669177,
          21.01408164834162
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84148123166405,
            21.01408164834162
          ],
          [
            105.84148851669177,
            21.013805003419336
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "07",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.01408164834162,
        "to_latitude": 21.013805003419336,
        "to_longitude": 105.84148851669177,
        "from_longitude": 105.84148123166405,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3497,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84148208805887,
          21.013470643643593,
          105.84148851669177,
          21.013805003419336
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84148851669177,
            21.013805003419336
          ],
          [
            105.84148208805887,
            21.013470643643593
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "09",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.013805003419336,
        "to_latitude": 21.013470643643593,
        "to_longitude": 105.84148208805887,
        "from_longitude": 105.84148851669177,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3498,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84147357987175,
          21.013118531772665,
          105.84148208805887,
          21.013470643643593
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84148208805887,
            21.013470643643593
          ],
          [
            105.84147357987175,
            21.013118531772665
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "11",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.013470643643593,
        "to_latitude": 21.013118531772665,
        "to_longitude": 105.84147357987175,
        "from_longitude": 105.84148208805887,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3499,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8414716350558,
          21.012850193405434,
          105.84147357987175,
          21.013118531772665
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84147357987175,
            21.013118531772665
          ],
          [
            105.8414716350558,
            21.012850193405434
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "13",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.013118531772665,
        "to_latitude": 21.012850193405434,
        "to_longitude": 105.8414716350558,
        "from_longitude": 105.84147357987175,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3500,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84146146528505,
          21.012565234931767,
          105.8414716350558,
          21.012850193405434
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8414716350558,
            21.012850193405434
          ],
          [
            105.84146146528505,
            21.012565234931767
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "15",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.012850193405434,
        "to_latitude": 21.012565234931767,
        "to_longitude": 105.84146146528505,
        "from_longitude": 105.8414716350558,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3501,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84146146528505,
          21.012255031882326,
          105.84146859954812,
          21.012565234931767
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84146146528505,
            21.012565234931767
          ],
          [
            105.84146859954812,
            21.012255031882326
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "17",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.012565234931767,
        "to_latitude": 21.012255031882326,
        "to_longitude": 105.84146859954812,
        "from_longitude": 105.84146146528505,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3502,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84146859954812,
          21.01196991996103,
          105.84147054529903,
          21.012255031882326
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84146859954812,
            21.012255031882326
          ],
          [
            105.84147054529903,
            21.01196991996103
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "19",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.012255031882326,
        "to_latitude": 21.01196991996103,
        "to_longitude": 105.84147054529903,
        "from_longitude": 105.84146859954812,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3503,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84147054529903,
          21.011676640345527,
          105.84148283966417,
          21.01196991996103
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84147054529903,
            21.01196991996103
          ],
          [
            105.84148283966417,
            21.011676640345527
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "21",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.01196991996103,
        "to_latitude": 21.011676640345527,
        "to_longitude": 105.84148283966417,
        "from_longitude": 105.84147054529903,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3504,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84148283966417,
          21.011401864169297,
          105.84151803046267,
          21.011676640345527
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84148283966417,
            21.011676640345527
          ],
          [
            105.84151803046267,
            21.011401864169297
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "23",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.011676640345527,
        "to_latitude": 21.011401864169297,
        "to_longitude": 105.84151803046267,
        "from_longitude": 105.84148283966417,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3505,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84151257595435,
          21.010839093232363,
          105.84151803046267,
          21.011401864169297
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84151803046267,
            21.011401864169297
          ],
          [
            105.84151257595435,
            21.010839093232363
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "25",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.011401864169297,
        "to_latitude": 21.010839093232363,
        "to_longitude": 105.84151257595435,
        "from_longitude": 105.84151803046267,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3506,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84146416196582,
          21.01056992632448,
          105.84147933836856,
          21.010770711366902
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84147933836856,
            21.010770711366902
          ],
          [
            105.84146416196582,
            21.01056992632448
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "27",
        "diemCuoi": "29",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.010770711366902,
        "to_latitude": 21.01056992632448,
        "to_longitude": 105.84146416196582,
        "from_longitude": 105.84147933836856,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3507,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8414562913909,
          21.01028154461065,
          105.84146416196582,
          21.01056992632448
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84146416196582,
            21.01056992632448
          ],
          [
            105.8414562913909,
            21.01028154461065
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "29",
        "diemCuoi": "31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.01056992632448,
        "to_latitude": 21.01028154461065,
        "to_longitude": 105.8414562913909,
        "from_longitude": 105.84146416196582,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3508,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84144054823219,
          21.010019476911214,
          105.8414562913909,
          21.01028154461065
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8414562913909,
            21.01028154461065
          ],
          [
            105.84144054823219,
            21.010019476911214
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "31",
        "diemCuoi": "33",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.01028154461065,
        "to_latitude": 21.010019476911214,
        "to_longitude": 105.84144054823219,
        "from_longitude": 105.8414562913909,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3509,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84144054823219,
          21.00975240711202,
          105.84144552073384,
          21.0100194769112
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84144054823219,
            21.0100194769112
          ],
          [
            105.84144552073384,
            21.00975240711202
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "33",
        "diemCuoi": "35",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.0100194769112,
        "to_latitude": 21.00975240711202,
        "to_longitude": 105.84144552073384,
        "from_longitude": 105.84144054823219,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3510,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84134156267655,
          21.014464035592297,
          105.84149232490051,
          21.015120033706076
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84149232490051,
            21.015118781802947
          ],
          [
            105.84135095041263,
            21.015120033706076
          ],
          [
            105.84134156267655,
            21.014464035592297
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 304 Lê Duẩn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV3",
        "from_latitude": 21.015118781802947,
        "to_latitude": 21.014464035592297,
        "to_longitude": 105.84134156267655,
        "from_longitude": 105.84149232490051,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3511,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84133325401675,
          21.014156378469494,
          105.84134156267655,
          21.014464035592297
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84134156267655,
            21.014464035592297
          ],
          [
            105.84133325401675,
            21.014156378469494
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "26",
        "diemCuoi": "28",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.014464035592297,
        "to_latitude": 21.014156378469494,
        "to_longitude": 105.84133325401675,
        "from_longitude": 105.84134156267655,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3512,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84132106029146,
          21.013871491707473,
          105.84133325401675,
          21.014156378469494
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84133325401675,
            21.014156378469494
          ],
          [
            105.84132106029146,
            21.013871491707473
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "28",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.014156378469494,
        "to_latitude": 21.013871491707473,
        "to_longitude": 105.84132106029146,
        "from_longitude": 105.84133325401675,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3513,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8413161075711,
          21.013578027774667,
          105.84132106029146,
          21.013871491707473
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84132106029146,
            21.013871491707473
          ],
          [
            105.8413161075711,
            21.013578027774667
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "30",
        "diemCuoi": "32",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.013871491707473,
        "to_latitude": 21.013578027774667,
        "to_longitude": 105.8413161075711,
        "from_longitude": 105.84132106029146,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3514,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84131038682042,
          21.013318124698575,
          105.8413161075711,
          21.013578027774667
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8413161075711,
            21.013578027774667
          ],
          [
            105.84131038682042,
            21.013318124698575
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "32",
        "diemCuoi": "34",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.013578027774667,
        "to_latitude": 21.013318124698575,
        "to_longitude": 105.84131038682042,
        "from_longitude": 105.8413161075711,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3515,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84130785670204,
          21.012999474720285,
          105.84131038682042,
          21.013318124698575
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84131038682042,
            21.013318124698575
          ],
          [
            105.84130785670204,
            21.012999474720285
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "34",
        "diemCuoi": "36",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.013318124698575,
        "to_latitude": 21.012999474720285,
        "to_longitude": 105.84130785670204,
        "from_longitude": 105.84131038682042,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3516,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84130083098316,
          21.012714433878728,
          105.84130785670204,
          21.012999474720285
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84130785670204,
            21.012999474720285
          ],
          [
            105.84130083098316,
            21.012714433878728
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "36",
        "diemCuoi": "38",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.012999474720285,
        "to_latitude": 21.012714433878728,
        "to_longitude": 105.84130083098316,
        "from_longitude": 105.84130785670204,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3517,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84129745050423,
          21.01241507782658,
          105.84130083098316,
          21.012714433878728
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84130083098316,
            21.012714433878728
          ],
          [
            105.84129745050423,
            21.01241507782658
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "38",
        "diemCuoi": "40",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.012714433878728,
        "to_latitude": 21.01241507782658,
        "to_longitude": 105.84129745050423,
        "from_longitude": 105.84130083098316,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3518,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84129745050423,
          21.012104810527315,
          105.84129976853741,
          21.01241507782658
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84129745050423,
            21.01241507782658
          ],
          [
            105.84129976853741,
            21.012104810527315
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "40",
        "diemCuoi": "42",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.01241507782658,
        "to_latitude": 21.012104810527315,
        "to_longitude": 105.84129976853741,
        "from_longitude": 105.84129745050423,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3519,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84129692617111,
          21.011823897979728,
          105.84129976853741,
          21.012104810527315
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84129976853741,
            21.012104810527315
          ],
          [
            105.84129692617111,
            21.011823897979728
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "42",
        "diemCuoi": "44",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.012104810527315,
        "to_latitude": 21.011823897979728,
        "to_longitude": 105.84129692617111,
        "from_longitude": 105.84129976853741,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3520,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84129442802885,
          21.01153711176067,
          105.84129692617111,
          21.011823897979728
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84129692617111,
            21.011823897979728
          ],
          [
            105.84129442802885,
            21.01153711176067
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "44",
        "diemCuoi": "46",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.011823897979728,
        "to_latitude": 21.01153711176067,
        "to_longitude": 105.84129442802885,
        "from_longitude": 105.84129692617111,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3521,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8412924365142,
          21.011212584443804,
          105.84129442802885,
          21.01153711176067
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84129442802885,
            21.01153711176067
          ],
          [
            105.8412924365142,
            21.011212584443804
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "46",
        "diemCuoi": "48",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.01153711176067,
        "to_latitude": 21.011212584443804,
        "to_longitude": 105.8412924365142,
        "from_longitude": 105.84129442802885,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3522,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8412859770982,
          21.010934238756487,
          105.8412924365142,
          21.011212584443804
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8412924365142,
            21.011212584443804
          ],
          [
            105.8412859770982,
            21.010934238756487
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "48",
        "diemCuoi": "50",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.011212584443804,
        "to_latitude": 21.010934238756487,
        "to_longitude": 105.8412859770982,
        "from_longitude": 105.8412924365142,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3523,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82503710707476,
          21.027077096663184,
          105.82536701878873,
          21.027378780555754
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82503710707476,
            21.027378780555754
          ],
          [
            105.82522486167957,
            21.0271747371597
          ],
          [
            105.82536701878873,
            21.027077096663184
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vũ Thạnh",
        "diemDau": "1",
        "diemCuoi": "2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.027378780555754,
        "to_latitude": 21.027077096663184,
        "to_longitude": 105.82536701878873,
        "from_longitude": 105.82503710707476,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3524,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82536701878873,
          21.02692938399498,
          105.82556818446818,
          21.027077096663184
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82536701878873,
            21.027077096663184
          ],
          [
            105.82556818446818,
            21.02692938399498
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vũ Thạnh",
        "diemDau": "2",
        "diemCuoi": "3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.027077096663184,
        "to_latitude": 21.02692938399498,
        "to_longitude": 105.82556818446818,
        "from_longitude": 105.82536701878873,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3525,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82556818446818,
          21.026765397725956,
          105.82588066181387,
          21.02692938399498
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82556818446818,
            21.02692938399498
          ],
          [
            105.82576130347951,
            21.02678918200475
          ],
          [
            105.8258364053502,
            21.02677165674674
          ],
          [
            105.82588066181387,
            21.026765397725956
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vũ Thạnh",
        "diemDau": "3",
        "diemCuoi": "4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02692938399498,
        "to_latitude": 21.026765397725956,
        "to_longitude": 105.82588066181387,
        "from_longitude": 105.82556818446818,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3526,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82588066181387,
          21.02666024612996,
          105.826136812779,
          21.026765397725956
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82588066181387,
            21.026765397725956
          ],
          [
            105.826136812779,
            21.02666024612996
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vũ Thạnh",
        "diemDau": "4",
        "diemCuoi": "7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026765397725956,
        "to_latitude": 21.02666024612996,
        "to_longitude": 105.826136812779,
        "from_longitude": 105.82588066181387,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3527,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.826136812779,
          21.026575123355546,
          105.82634200176109,
          21.02666024612996
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.826136812779,
            21.02666024612996
          ],
          [
            105.82634200176109,
            21.026575123355546
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vũ Thạnh",
        "diemDau": "7",
        "diemCuoi": "8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02666024612996,
        "to_latitude": 21.026575123355546,
        "to_longitude": 105.82634200176109,
        "from_longitude": 105.826136812779,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3528,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82655121406377,
          21.026363568013885,
          105.82685028035277,
          21.026479986079273
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82655121406377,
            21.026479986079273
          ],
          [
            105.82685028035277,
            21.026363568013885
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vũ Thạnh",
        "diemDau": "9",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026479986079273,
        "to_latitude": 21.026363568013885,
        "to_longitude": 105.82685028035277,
        "from_longitude": 105.82655121406377,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3529,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82685028035277,
          21.026274689645042,
          105.827074244825,
          21.026363568013885
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82685028035277,
            21.026363568013885
          ],
          [
            105.827074244825,
            21.026274689645042
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vũ Thạnh",
        "diemDau": "10",
        "diemCuoi": "10-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026363568013885,
        "to_latitude": 21.026274689645042,
        "to_longitude": 105.827074244825,
        "from_longitude": 105.82685028035277,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3530,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82634200176109,
          21.026479986079273,
          105.82655121406377,
          21.026575123355546
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82634200176109,
            21.026575123355546
          ],
          [
            105.82646940666476,
            21.02651628846849
          ],
          [
            105.82655121406377,
            21.026479986079273
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vũ Thạnh",
        "diemDau": "8",
        "diemCuoi": "9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026575123355546,
        "to_latitude": 21.026479986079273,
        "to_longitude": 105.82655121406377,
        "from_longitude": 105.82634200176109,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3531,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84136436948228,
          21.015118781802947,
          105.84149232490051,
          21.015185574044484
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84149232490051,
            21.015118781802947
          ],
          [
            105.84136436948228,
            21.015185574044484
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.015118781802947,
        "to_latitude": 21.015185574044484,
        "to_longitude": 105.84136436948228,
        "from_longitude": 105.84149232490051,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3532,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84136436948228,
          21.015185574044484,
          105.84137352651257,
          21.01548398020163
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84136436948228,
            21.015185574044484
          ],
          [
            105.84137352651257,
            21.01548398020163
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "24",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.015185574044484,
        "to_latitude": 21.01548398020163,
        "to_longitude": 105.84137352651257,
        "from_longitude": 105.84136436948228,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3533,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84137142969693,
          21.01548398020163,
          105.84137352651257,
          21.01577747027349
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84137352651257,
            21.01548398020163
          ],
          [
            105.84137142969693,
            21.01577747027349
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "22",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.01548398020163,
        "to_latitude": 21.01577747027349,
        "to_longitude": 105.84137142969693,
        "from_longitude": 105.84137352651257,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3534,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8413668528614,
          21.01577747027349,
          105.84137142969693,
          21.016054162126153
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84137142969693,
            21.01577747027349
          ],
          [
            105.8413668528614,
            21.016054162126153
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "20",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.01577747027349,
        "to_latitude": 21.016054162126153,
        "to_longitude": 105.8413668528614,
        "from_longitude": 105.84137142969693,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3535,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84136533042503,
          21.016054162126153,
          105.8413668528614,
          21.01635603983414
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8413668528614,
            21.016054162126153
          ],
          [
            105.84136533042503,
            21.01635603983414
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "18",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.016054162126153,
        "to_latitude": 21.01635603983414,
        "to_longitude": 105.84136533042503,
        "from_longitude": 105.8413668528614,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3536,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84134455957313,
          21.01635603983414,
          105.84136533042503,
          21.016770685186824
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84136533042503,
            21.01635603983414
          ],
          [
            105.84134455957313,
            21.016770685186824
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "16",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.01635603983414,
        "to_latitude": 21.016770685186824,
        "to_longitude": 105.84134455957313,
        "from_longitude": 105.84136533042503,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3537,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84134455957313,
          21.016770685186824,
          105.8413499239886,
          21.01706112296426
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84134455957313,
            21.016770685186824
          ],
          [
            105.8413499239886,
            21.01706112296426
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "14",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.016770685186824,
        "to_latitude": 21.01706112296426,
        "to_longitude": 105.8413499239886,
        "from_longitude": 105.84134455957313,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3538,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84134493458228,
          21.01706112296426,
          105.8413499239886,
          21.01727070949057
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8413499239886,
            21.01706112296426
          ],
          [
            105.84134493458228,
            21.01727070949057
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "12",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.01706112296426,
        "to_latitude": 21.01727070949057,
        "to_longitude": 105.84134493458228,
        "from_longitude": 105.8413499239886,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3539,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84134493458228,
          21.01727070949057,
          105.84135196392613,
          21.017668967339645
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84134493458228,
            21.01727070949057
          ],
          [
            105.84135196392613,
            21.017668967339645
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "10",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.01727070949057,
        "to_latitude": 21.017668967339645,
        "to_longitude": 105.84135196392613,
        "from_longitude": 105.84134493458228,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3540,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8413424671286,
          21.017668967339645,
          105.84135196392613,
          21.017950580460912
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84135196392613,
            21.017668967339645
          ],
          [
            105.8413424671286,
            21.017950580460912
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "08",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.017668967339645,
        "to_latitude": 21.017950580460912,
        "to_longitude": 105.8413424671286,
        "from_longitude": 105.84135196392613,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3541,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8413424671286,
          21.017950580460912,
          105.84135270147638,
          21.018331161182314
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8413424671286,
            21.017950580460912
          ],
          [
            105.84135270147638,
            21.018331161182314
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "06",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.017950580460912,
        "to_latitude": 21.018331161182314,
        "to_longitude": 105.84135270147638,
        "from_longitude": 105.8413424671286,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3542,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84135270147638,
          21.018331161182314,
          105.8413559652145,
          21.01864308528602
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84135270147638,
            21.018331161182314
          ],
          [
            105.8413559652145,
            21.01864308528602
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lê Duẩn",
        "diemDau": "04",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV3",
        "from_latitude": 21.018331161182314,
        "to_latitude": 21.01864308528602,
        "to_longitude": 105.8413559652145,
        "from_longitude": 105.84135270147638,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3543,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82397092895835,
          21.009195289616564,
          105.82398031668542,
          21.009224084501728
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82397092895835,
            21.009224084501728
          ],
          [
            105.82398031668542,
            21.009195289616564
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.009224084501728,
        "to_latitude": 21.009195289616564,
        "to_longitude": 105.82398031668542,
        "from_longitude": 105.82397092895835,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3544,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82398031668542,
          21.008914852157094,
          105.82403396085824,
          21.009195289616564
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82398031668542,
            21.009195289616564
          ],
          [
            105.82403396085824,
            21.008914852157094
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.009195289616564,
        "to_latitude": 21.008914852157094,
        "to_longitude": 105.82403396085824,
        "from_longitude": 105.82398031668542,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3545,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8239052148327,
          21.008634414169663,
          105.82403396085824,
          21.008914852157094
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82403396085824,
            21.008914852157094
          ],
          [
            105.8239052148327,
            21.008634414169663
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008914852157094,
        "to_latitude": 21.008634414169663,
        "to_longitude": 105.8239052148327,
        "from_longitude": 105.82403396085824,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3546,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82374428228735,
          21.008313912967736,
          105.8239052148327,
          21.008634414169663
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8239052148327,
            21.008634414169663
          ],
          [
            105.82374428228735,
            21.008313912967736
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008634414169663,
        "to_latitude": 21.008313912967736,
        "to_longitude": 105.82374428228735,
        "from_longitude": 105.8239052148327,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3547,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82359407858193,
          21.008038481696858,
          105.82374428228735,
          21.008313912967736
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82374428228735,
            21.008313912967736
          ],
          [
            105.82359407858193,
            21.008038481696858
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008313912967736,
        "to_latitude": 21.008038481696858,
        "to_longitude": 105.82359407858193,
        "from_longitude": 105.82374428228735,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3548,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82347069697191,
          21.0077830813365,
          105.82359407858193,
          21.008038481696858
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82359407858193,
            21.008038481696858
          ],
          [
            105.82347069697191,
            21.0077830813365
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008038481696858,
        "to_latitude": 21.0077830813365,
        "to_longitude": 105.82347069697191,
        "from_longitude": 105.82359407858193,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3549,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82325995197651,
          21.007353120639273,
          105.82347069697191,
          21.0077830813365
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82347069697191,
            21.0077830813365
          ],
          [
            105.82325995197651,
            21.007353120639273
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.0077830813365,
        "to_latitude": 21.007353120639273,
        "to_longitude": 105.82325995197651,
        "from_longitude": 105.82347069697191,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3550,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82307296369109,
          21.006979676233016,
          105.82325995197651,
          21.007353120639273
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82325995197651,
            21.007353120639273
          ],
          [
            105.82307296369109,
            21.006979676233016
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.007353120639273,
        "to_latitude": 21.006979676233016,
        "to_longitude": 105.82307296369109,
        "from_longitude": 105.82325995197651,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3551,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82296107727635,
          21.0067401919936,
          105.82307296369109,
          21.006979676233016
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82307296369109,
            21.006979676233016
          ],
          [
            105.82296107727635,
            21.0067401919936
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.006979676233016,
        "to_latitude": 21.0067401919936,
        "to_longitude": 105.82296107727635,
        "from_longitude": 105.82307296369109,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3552,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82352165891899,
          21.00952267465755,
          105.82377646877127,
          21.009647869507386
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82377646877127,
            21.00952267465755
          ],
          [
            105.82352165891899,
            21.009647869507386
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.00952267465755,
        "to_latitude": 21.009647869507386,
        "to_longitude": 105.82352165891899,
        "from_longitude": 105.82377646877127,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3553,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8232909889364,
          21.009647869507386,
          105.82352165891899,
          21.00977056035782
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82352165891899,
            21.009647869507386
          ],
          [
            105.8232909889364,
            21.00977056035782
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.009647869507386,
        "to_latitude": 21.00977056035782,
        "to_longitude": 105.8232909889364,
        "from_longitude": 105.82352165891899,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3554,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82312066871783,
          21.00977056035782,
          105.8232909889364,
          21.009865082307353
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8232909889364,
            21.00977056035782
          ],
          [
            105.82312066871783,
            21.009865082307353
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.00977056035782,
        "to_latitude": 21.009865082307353,
        "to_longitude": 105.82312066871783,
        "from_longitude": 105.8232909889364,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3555,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82287792874651,
          21.009865082307353,
          105.82312066871783,
          21.0100635156532
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82312066871783,
            21.009865082307353
          ],
          [
            105.82287792874651,
            21.0100635156532
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.009865082307353,
        "to_latitude": 21.0100635156532,
        "to_longitude": 105.82287792874651,
        "from_longitude": 105.82312066871783,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3556,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82301203920548,
          21.00966539677803,
          105.82312066871783,
          21.009865082307353
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82312066871783,
            21.009865082307353
          ],
          [
            105.82301203920548,
            21.00966539677803
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.009865082307353,
        "to_latitude": 21.00966539677803,
        "to_longitude": 105.82301203920548,
        "from_longitude": 105.82312066871783,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3557,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82301203920548,
          21.009499413214733,
          105.82311884468865,
          21.00966539677803
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82301203920548,
            21.00966539677803
          ],
          [
            105.82301874477874,
            21.009589653900207
          ],
          [
            105.82311884468865,
            21.009499413214733
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.00966539677803,
        "to_latitude": 21.009499413214733,
        "to_longitude": 105.82311884468865,
        "from_longitude": 105.82301203920548,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3558,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8227974624783,
          21.009415007002683,
          105.82301203920548,
          21.00966539677803
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82301203920548,
            21.00966539677803
          ],
          [
            105.8227974624783,
            21.009415007002683
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.00966539677803,
        "to_latitude": 21.009415007002683,
        "to_longitude": 105.8227974624783,
        "from_longitude": 105.82301203920548,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3559,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82262580110198,
          21.009257261228825,
          105.8227974624783,
          21.009415007002683
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8227974624783,
            21.009415007002683
          ],
          [
            105.82262580110198,
            21.009257261228825
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.009415007002683,
        "to_latitude": 21.009257261228825,
        "to_longitude": 105.82262580110198,
        "from_longitude": 105.8227974624783,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3560,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82254533483378,
          21.009257261228825,
          105.82262580110198,
          21.009322362678912
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82262580110198,
            21.009257261228825
          ],
          [
            105.82254533483378,
            21.009322362678912
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.009257261228825,
        "to_latitude": 21.009322362678912,
        "to_longitude": 105.82254533483378,
        "from_longitude": 105.82262580110198,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3561,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82240854217605,
          21.009322362678912,
          105.82254533483378,
          21.00936242509631
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82254533483378,
            21.009322362678912
          ],
          [
            105.82240854217605,
            21.00936242509631
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.009322362678912,
        "to_latitude": 21.00936242509631,
        "to_longitude": 105.82240854217605,
        "from_longitude": 105.82254533483378,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3562,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82202766849704,
          21.00936242509631,
          105.82240854217605,
          21.009655381194822
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82240854217605,
            21.00936242509631
          ],
          [
            105.82202766849704,
            21.009655381194822
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 49,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.00936242509631,
        "to_latitude": 21.009655381194822,
        "to_longitude": 105.82202766849704,
        "from_longitude": 105.82240854217605,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3563,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82178358747574,
          21.00937744850038,
          105.82202766849704,
          21.009655381194822
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82202766849704,
            21.009655381194822
          ],
          [
            105.82178358747574,
            21.00937744850038
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.009655381194822,
        "to_latitude": 21.00937744850038,
        "to_longitude": 105.82178358747574,
        "from_longitude": 105.82202766849704,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3564,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82173262550168,
          21.009324866580776,
          105.82178358747574,
          21.00937744850038
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82178358747574,
            21.00937744850038
          ],
          [
            105.82173262550168,
            21.009324866580776
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.00937744850038,
        "to_latitude": 21.009324866580776,
        "to_longitude": 105.82173262550168,
        "from_longitude": 105.82178358747574,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3565,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82159583284395,
          21.00914208166869,
          105.82173262550168,
          21.009324866580776
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82173262550168,
            21.009324866580776
          ],
          [
            105.82159583284395,
            21.00914208166869
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.009324866580776,
        "to_latitude": 21.00914208166869,
        "to_longitude": 105.82159583284395,
        "from_longitude": 105.82173262550168,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3566,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82225029184742,
          21.008974319977305,
          105.82254533483378,
          21.009322362678912
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82254533483378,
            21.009322362678912
          ],
          [
            105.82225029184742,
            21.008974319977305
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 55,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.009322362678912,
        "to_latitude": 21.008974319977305,
        "to_longitude": 105.82225029184742,
        "from_longitude": 105.82254533483378,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3567,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82210813476523,
          21.008974319977305,
          105.82225029184742,
          21.009094507476807
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82225029184742,
            21.008974319977305
          ],
          [
            105.82210813476523,
            21.009094507476807
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008974319977305,
        "to_latitude": 21.009094507476807,
        "to_longitude": 105.82210813476523,
        "from_longitude": 105.82225029184742,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3568,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82201425744934,
          21.008751472065867,
          105.82225029184742,
          21.008974319977305
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82225029184742,
            21.008974319977305
          ],
          [
            105.82201425744934,
            21.008751472065867
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008974319977305,
        "to_latitude": 21.008751472065867,
        "to_longitude": 105.82201425744934,
        "from_longitude": 105.82225029184742,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3569,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82180504514665,
          21.008751472065867,
          105.82201425744934,
          21.008944273087312
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82201425744934,
            21.008751472065867
          ],
          [
            105.82180504514665,
            21.008944273087312
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008751472065867,
        "to_latitude": 21.008944273087312,
        "to_longitude": 105.82180504514665,
        "from_longitude": 105.82201425744934,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3570,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82180772735438,
          21.008511096417337,
          105.82201425744934,
          21.008751472065867
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82201425744934,
            21.008751472065867
          ],
          [
            105.82180772735438,
            21.008511096417337
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008751472065867,
        "to_latitude": 21.008511096417337,
        "to_longitude": 105.82180772735438,
        "from_longitude": 105.82201425744934,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3571,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82173094920942,
          21.008511096417337,
          105.82180772735438,
          21.008586839806696
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82180772735438,
            21.008511096417337
          ],
          [
            105.82173094920942,
            21.008586839806696
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008511096417337,
        "to_latitude": 21.008586839806696,
        "to_longitude": 105.82173094920942,
        "from_longitude": 105.82180772735438,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3572,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82172759640034,
          21.008351158746112,
          105.82182784397534,
          21.008511096417337
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82180772735438,
            21.008511096417337
          ],
          [
            105.82182784397534,
            21.008442864702783
          ],
          [
            105.82172759640034,
            21.008351158746112
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008511096417337,
        "to_latitude": 21.008351158746112,
        "to_longitude": 105.82172759640034,
        "from_longitude": 105.82180772735438,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3573,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82149390890484,
          21.008380892779915,
          105.82180772735438,
          21.008564304586887
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82180772735438,
            21.008511096417337
          ],
          [
            105.82172189671564,
            21.008564304586887
          ],
          [
            105.82149390890484,
            21.008380892779915
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008511096417337,
        "to_latitude": 21.008380892779915,
        "to_longitude": 105.82149390890484,
        "from_longitude": 105.82180772735438,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3574,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8216414304115,
          21.008511096417337,
          105.82180772735438,
          21.008684492422564
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82180772735438,
            21.008511096417337
          ],
          [
            105.8216414304115,
            21.008684492422564
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008511096417337,
        "to_latitude": 21.008684492422564,
        "to_longitude": 105.8216414304115,
        "from_longitude": 105.82180772735438,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3575,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82130347207432,
          21.00861187895371,
          105.8216414304115,
          21.00881219188041
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8216414304115,
            21.008684492422564
          ],
          [
            105.82151938993229,
            21.00881219188041
          ],
          [
            105.82130347207432,
            21.00861187895371
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008684492422564,
        "to_latitude": 21.00861187895371,
        "to_longitude": 105.82130347207432,
        "from_longitude": 105.8216414304115,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3576,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8213973493902,
          21.008684492422564,
          105.8216414304115,
          21.008952410778377
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8216414304115,
            21.008684492422564
          ],
          [
            105.82148351537904,
            21.00881313084745
          ],
          [
            105.8213973493902,
            21.008952410778377
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 54,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008684492422564,
        "to_latitude": 21.008952410778377,
        "to_longitude": 105.8213973493902,
        "from_longitude": 105.8216414304115,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3577,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82118277267203,
          21.008779641052637,
          105.8213973493902,
          21.008952410778377
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8213973493902,
            21.008952410778377
          ],
          [
            105.82118277267203,
            21.008779641052637
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008952410778377,
        "to_latitude": 21.008779641052637,
        "to_longitude": 105.82118277267203,
        "from_longitude": 105.8213973493902,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3578,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8232909889364,
          21.00977056035782,
          105.82340766508098,
          21.00999904048448
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8232909889364,
            21.00977056035782
          ],
          [
            105.82340766508098,
            21.00999904048448
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.00977056035782,
        "to_latitude": 21.00999904048448,
        "to_longitude": 105.82340766508098,
        "from_longitude": 105.8232909889364,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3579,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82340766508098,
          21.00999904048448,
          105.82349215461588,
          21.010163671180226
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82340766508098,
            21.00999904048448
          ],
          [
            105.82349215461588,
            21.010163671180226
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.00999904048448,
        "to_latitude": 21.010163671180226,
        "to_longitude": 105.82349215461588,
        "from_longitude": 105.82340766508098,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3580,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82343046381085,
          21.010163671180226,
          105.82349215461588,
          21.010223764463003
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82349215461588,
            21.010163671180226
          ],
          [
            105.82343046381085,
            21.010223764463003
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.010163671180226,
        "to_latitude": 21.010223764463003,
        "to_longitude": 105.82343046381085,
        "from_longitude": 105.82349215461588,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3581,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82349215461588,
          21.010144266037393,
          105.82364235832128,
          21.010213748917277
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82349215461588,
            21.010163671180226
          ],
          [
            105.82357664424957,
            21.010144266037393
          ],
          [
            105.82364235832128,
            21.010213748917277
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.010163671180226,
        "to_latitude": 21.010213748917277,
        "to_longitude": 105.82364235832128,
        "from_longitude": 105.82349215461588,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3582,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82363114660994,
          21.00855861916769,
          105.82386715602817,
          21.008790808274657
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82386715602817,
            21.00855861916769
          ],
          [
            105.82363114660994,
            21.008790808274657
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 218 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.00855861916769,
        "to_latitude": 21.008790808274657,
        "to_longitude": 105.82363114660994,
        "from_longitude": 105.82386715602817,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3583,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82346484964907,
          21.008790808274657,
          105.82363114660994,
          21.00896608189451
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82363114660994,
            21.008790808274657
          ],
          [
            105.82346484964907,
            21.00896608189451
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 218 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008790808274657,
        "to_latitude": 21.00896608189451,
        "to_longitude": 105.82346484964907,
        "from_longitude": 105.82363114660994,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3584,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82335219687,
          21.00896608189451,
          105.82346484964907,
          21.00905872643871
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82346484964907,
            21.00896608189451
          ],
          [
            105.82335219687,
            21.00905872643871
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 218 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.00896608189451,
        "to_latitude": 21.00905872643871,
        "to_longitude": 105.82335219687,
        "from_longitude": 105.82346484964907,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3585,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82324490850642,
          21.008951058449018,
          105.82335219687,
          21.00905872643871
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82335219687,
            21.00905872643871
          ],
          [
            105.82324490850642,
            21.008951058449018
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 218 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.00905872643871,
        "to_latitude": 21.008951058449018,
        "to_longitude": 105.82324490850642,
        "from_longitude": 105.82335219687,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3586,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82333342140683,
          21.008654445469162,
          105.82363114660994,
          21.008798320005248
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82363114660994,
            21.008790808274657
          ],
          [
            105.82353641111548,
            21.008654445469162
          ],
          [
            105.82333342140683,
            21.008798320005248
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 218 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008790808274657,
        "to_latitude": 21.008798320005248,
        "to_longitude": 105.82333342140683,
        "from_longitude": 105.82363114660994,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3587,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82306520050686,
          21.008951058449018,
          105.82324490850642,
          21.009098788926906
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82324490850642,
            21.008951058449018
          ],
          [
            105.82306520050686,
            21.009098788926906
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 218 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008951058449018,
        "to_latitude": 21.009098788926906,
        "to_longitude": 105.82306520050686,
        "from_longitude": 105.82324490850642,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3588,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82306520050686,
          21.009098788926906,
          105.82322613304325,
          21.009284077793744
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82306520050686,
            21.009098788926906
          ],
          [
            105.82322613304325,
            21.009284077793744
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 218 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.009098788926906,
        "to_latitude": 21.009284077793744,
        "to_longitude": 105.82322613304325,
        "from_longitude": 105.82306520050686,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3589,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82296778280467,
          21.009098788926906,
          105.82308934038554,
          21.009309116812023
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82306520050686,
            21.009098788926906
          ],
          [
            105.82296778280467,
            21.009177762288154
          ],
          [
            105.82308934038554,
            21.009309116812023
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 218 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.009098788926906,
        "to_latitude": 21.009309116812023,
        "to_longitude": 105.82308934038554,
        "from_longitude": 105.82306520050686,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3590,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8229927808619,
          21.009309116812023,
          105.82308934038554,
          21.009394249442977
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82308934038554,
            21.009309116812023
          ],
          [
            105.8229927808619,
            21.009394249442977
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 218 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.009309116812023,
        "to_latitude": 21.009394249442977,
        "to_longitude": 105.8229927808619,
        "from_longitude": 105.82308934038554,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3591,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82348947243507,
          21.0080146944231,
          105.82359407858193,
          21.00805350523259
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82359407858193,
            21.008038481696858
          ],
          [
            105.82355920989028,
            21.0080146944231
          ],
          [
            105.82348947243507,
            21.00805350523259
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008038481696858,
        "to_latitude": 21.00805350523259,
        "to_longitude": 105.82348947243507,
        "from_longitude": 105.82359407858193,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3592,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82332853989868,
          21.00805350523259,
          105.82348947243507,
          21.008168685632274
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82348947243507,
            21.00805350523259
          ],
          [
            105.82332853989868,
            21.008168685632274
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.00805350523259,
        "to_latitude": 21.008168685632274,
        "to_longitude": 105.82332853989868,
        "from_longitude": 105.82348947243507,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3593,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82316492514558,
          21.007858199134407,
          105.82332853989868,
          21.008168685632274
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82332853989868,
            21.008168685632274
          ],
          [
            105.82316492514558,
            21.007858199134407
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008168685632274,
        "to_latitude": 21.007858199134407,
        "to_longitude": 105.82316492514558,
        "from_longitude": 105.82332853989868,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3594,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82314078526693,
          21.007803112752807,
          105.82316492514558,
          21.007858199134407
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82316492514558,
            21.007858199134407
          ],
          [
            105.82314078526693,
            21.007803112752807
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.007858199134407,
        "to_latitude": 21.007803112752807,
        "to_longitude": 105.82314078526693,
        "from_longitude": 105.82316492514558,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3595,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82304422570735,
          21.008168685632274,
          105.82332853989868,
          21.008338326181374
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82332853989868,
            21.008168685632274
          ],
          [
            105.82304422570735,
            21.008338326181374
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008168685632274,
        "to_latitude": 21.008338326181374,
        "to_longitude": 105.82304422570735,
        "from_longitude": 105.82332853989868,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3596,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82289133979421,
          21.008338326181374,
          105.82304422570735,
          21.008678858629583
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82304422570735,
            21.008338326181374
          ],
          [
            105.82289133979421,
            21.008678858629583
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008338326181374,
        "to_latitude": 21.008678858629583,
        "to_longitude": 105.82289133979421,
        "from_longitude": 105.82304422570735,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3597,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82289133979421,
          21.00857369428025,
          105.82295839502368,
          21.008678858629583
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82289133979421,
            21.008678858629583
          ],
          [
            105.82295839502368,
            21.00857369428025
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 8,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008678858629583,
        "to_latitude": 21.00857369428025,
        "to_longitude": 105.82295839502368,
        "from_longitude": 105.82289133979421,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3598,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82280014469504,
          21.008678858629583,
          105.82289133979421,
          21.008784022905584
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82289133979421,
            21.008678858629583
          ],
          [
            105.82280014469504,
            21.008784022905584
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008678858629583,
        "to_latitude": 21.008784022905584,
        "to_longitude": 105.82280014469504,
        "from_longitude": 105.82289133979421,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3599,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82280014469504,
          21.008747090207706,
          105.82289402201093,
          21.00879654245724
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82280014469504,
            21.008784022905584
          ],
          [
            105.82284943033191,
            21.008747090207706
          ],
          [
            105.82289402201093,
            21.00879654245724
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N252/59,85,10,109,92,52",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008784022905584,
        "to_latitude": 21.00879654245724,
        "to_longitude": 105.82289402201093,
        "from_longitude": 105.82280014469504,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3600,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82280014469504,
          21.008784022905584,
          105.82288865758646,
          21.008914226191262
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82280014469504,
            21.008784022905584
          ],
          [
            105.82288865758646,
            21.008914226191262
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N252/59,85,10,109,92,52",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008784022905584,
        "to_latitude": 21.008914226191262,
        "to_longitude": 105.82288865758646,
        "from_longitude": 105.82280014469504,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3601,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82267676307603,
          21.008784022905584,
          105.82280014469504,
          21.009021894207503
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82280014469504,
            21.008784022905584
          ],
          [
            105.82268715669167,
            21.00893613537314
          ],
          [
            105.82267676307603,
            21.009021894207503
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008784022905584,
        "to_latitude": 21.009021894207503,
        "to_longitude": 105.82267676307603,
        "from_longitude": 105.82280014469504,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3602,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82262580110198,
          21.009021894207503,
          105.82267676307603,
          21.009257261228825
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82267676307603,
            21.009021894207503
          ],
          [
            105.82262580110198,
            21.009257261228825
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.009021894207503,
        "to_latitude": 21.009257261228825,
        "to_longitude": 105.82262580110198,
        "from_longitude": 105.82267676307603,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3603,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82267676307603,
          21.009021894207503,
          105.82274381830551,
          21.009092003571904
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82267676307603,
            21.009021894207503
          ],
          [
            105.82274381830551,
            21.009092003571904
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N252/59,85,10,109,92,52",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.009021894207503,
        "to_latitude": 21.009092003571904,
        "to_longitude": 105.82274381830551,
        "from_longitude": 105.82267676307603,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3604,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82262580110198,
          21.00916273886986,
          105.82278673365634,
          21.009257261228825
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82262580110198,
            21.009257261228825
          ],
          [
            105.82278673365634,
            21.00916273886986
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.009257261228825,
        "to_latitude": 21.00916273886986,
        "to_longitude": 105.82278673365634,
        "from_longitude": 105.82262580110198,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3605,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82264994098963,
          21.00887666756287,
          105.82268179227617,
          21.009021894207503
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82267676307603,
            21.009021894207503
          ],
          [
            105.82268179227617,
            21.008896072842088
          ],
          [
            105.82264994098963,
            21.00887666756287
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N252/59,85,10,109,92,52",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.009021894207503,
        "to_latitude": 21.00887666756287,
        "to_longitude": 105.82264994098963,
        "from_longitude": 105.82267676307603,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3606,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82256947471245,
          21.00892674573278,
          105.82267676307603,
          21.009021894207503
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82267676307603,
            21.009021894207503
          ],
          [
            105.82262814809442,
            21.009006244777858
          ],
          [
            105.82256947471245,
            21.00892674573278
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252/53 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.009021894207503,
        "to_latitude": 21.00892674573278,
        "to_longitude": 105.82256947471245,
        "from_longitude": 105.82267676307603,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3607,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82241122438381,
          21.008864148018006,
          105.82256947471245,
          21.008951158820135
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82256947471245,
            21.00892674573278
          ],
          [
            105.82251013089984,
            21.008951158820135
          ],
          [
            105.82241122438381,
            21.008864148018006
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252/53 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.00892674573278,
        "to_latitude": 21.008864148018006,
        "to_longitude": 105.82241122438381,
        "from_longitude": 105.82256947471245,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3608,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82267944531071,
          21.008233787558215,
          105.82280014469504,
          21.008784022905584
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82280014469504,
            21.008784022905584
          ],
          [
            105.82272470761801,
            21.008588091770523
          ],
          [
            105.8226952033149,
            21.008400298122755
          ],
          [
            105.82267944531071,
            21.008233787558215
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 60,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008784022905584,
        "to_latitude": 21.008233787558215,
        "to_longitude": 105.82267944531071,
        "from_longitude": 105.82280014469504,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3609,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82250241949195,
          21.00857306828779,
          105.82272470761801,
          21.008653819505536
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82272470761801,
            21.008588091770523
          ],
          [
            105.8226764278517,
            21.00857306828779
          ],
          [
            105.82250241949195,
            21.008653819505536
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N252/59,85,10,109,92,52",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008588091770523,
        "to_latitude": 21.008653819505536,
        "to_longitude": 105.82250241949195,
        "from_longitude": 105.82272470761801,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3610,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82249169067894,
          21.008233787558215,
          105.82267944531071,
          21.008238795397627
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82267944531071,
            21.008233787558215
          ],
          [
            105.82249169067894,
            21.008238795397627
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N252/59,85,10,109,92,52",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008233787558215,
        "to_latitude": 21.008238795397627,
        "to_longitude": 105.82249169067894,
        "from_longitude": 105.82267944531071,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3611,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82237903790886,
          21.008082300339794,
          105.82268179227617,
          21.008098575834808
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82268179227617,
            21.008082300339794
          ],
          [
            105.82237903790886,
            21.008098575834808
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252/53 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008082300339794,
        "to_latitude": 21.008098575834808,
        "to_longitude": 105.82237903790886,
        "from_longitude": 105.82268179227617,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3612,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82212691025535,
          21.008078544457348,
          105.82219094802582,
          21.00811985916813
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82219094802582,
            21.00811985916813
          ],
          [
            105.82212691025535,
            21.008078544457348
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252/53 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.00811985916813,
        "to_latitude": 21.008078544457348,
        "to_longitude": 105.82212691025535,
        "from_longitude": 105.82219094802582,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3613,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82212691025535,
          21.00811985916813,
          105.82219094802582,
          21.00821876403981
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82219094802582,
            21.00811985916813
          ],
          [
            105.82212691025535,
            21.00821876403981
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252/53 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.00811985916813,
        "to_latitude": 21.00821876403981,
        "to_longitude": 105.82212691025535,
        "from_longitude": 105.82219094802582,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3614,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8230978699161,
          21.0073336257176,
          105.82325995197651,
          21.007417507511384
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82325995197651,
            21.007353120639273
          ],
          [
            105.8232142107555,
            21.0073336257176
          ],
          [
            105.8230978699161,
            21.007417507511384
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.007353120639273,
        "to_latitude": 21.007417507511384,
        "to_longitude": 105.8230978699161,
        "from_longitude": 105.82325995197651,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3615,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82269017415067,
          21.006489797308998,
          105.82296107727635,
          21.0067401919936
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82296107727635,
            21.0067401919936
          ],
          [
            105.82281087357093,
            21.006489797308998
          ],
          [
            105.82269017415067,
            21.00651859272134
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 84,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.0067401919936,
        "to_latitude": 21.00651859272134,
        "to_longitude": 105.82269017415067,
        "from_longitude": 105.82296107727635,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3616,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82258020357936,
          21.006302001020195,
          105.82276896405475,
          21.00651859272134
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82269017415067,
            21.00651859272134
          ],
          [
            105.82276896405475,
            21.00641467882217
          ],
          [
            105.82269654441876,
            21.006302001020195
          ],
          [
            105.82258020357936,
            21.006353332031814
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.00651859272134,
        "to_latitude": 21.006353332031814,
        "to_longitude": 105.82258020357936,
        "from_longitude": 105.82269017415067,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3617,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82250241951891,
          21.00651859272134,
          105.82269017415067,
          21.006736436078203
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82269017415067,
            21.00651859272134
          ],
          [
            105.82250241951891,
            21.006736436078203
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.00651859272134,
        "to_latitude": 21.006736436078203,
        "to_longitude": 105.82250241951891,
        "from_longitude": 105.82269017415067,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3618,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82234685139798,
          21.006736436078203,
          105.82250241951891,
          21.006909208169972
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82250241951891,
            21.006736436078203
          ],
          [
            105.82234685139798,
            21.006909208169972
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.006736436078203,
        "to_latitude": 21.006909208169972,
        "to_longitude": 105.82234685139798,
        "from_longitude": 105.82250241951891,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3619,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82212422804761,
          21.006909208169972,
          105.82234685139798,
          21.00714958639929
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82234685139798,
            21.006909208169972
          ],
          [
            105.82212422804761,
            21.00714958639929
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.006909208169972,
        "to_latitude": 21.00714958639929,
        "to_longitude": 105.82212422804761,
        "from_longitude": 105.82234685139798,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3620,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82199011759764,
          21.00714958639929,
          105.82212422804761,
          21.007302326531782
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82212422804761,
            21.00714958639929
          ],
          [
            105.82199011759764,
            21.007302326531782
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.00714958639929,
        "to_latitude": 21.007302326531782,
        "to_longitude": 105.82199011759764,
        "from_longitude": 105.82212422804761,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3621,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82181577401354,
          21.007157098213348,
          105.82199011759764,
          21.007302326531782
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82199011759764,
            21.007302326531782
          ],
          [
            105.82181577401354,
            21.007157098213348
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.007302326531782,
        "to_latitude": 21.007157098213348,
        "to_longitude": 105.82181577401354,
        "from_longitude": 105.82199011759764,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3622,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82283501344061,
          21.00802846600359,
          105.82295303063515,
          21.008094819950276
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82283501344061,
            21.00808856014643
          ],
          [
            105.82289502788149,
            21.008094819950276
          ],
          [
            105.82295303063515,
            21.00802846600359
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.00808856014643,
        "to_latitude": 21.00802846600359,
        "to_longitude": 105.82295303063515,
        "from_longitude": 105.82283501344061,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3623,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8064329701125,
          21.01663952206824,
          105.80662608915974,
          21.01684608365719
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80662608915974,
            21.01684608365719
          ],
          [
            105.8064329701125,
            21.01663952206824
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "LT3 12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.01684608365719,
        "to_latitude": 21.01663952206824,
        "to_longitude": 105.8064329701125,
        "from_longitude": 105.80662608915974,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3624,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80625192100008,
          21.016470516918925,
          105.8064329701125,
          21.01663952206824
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8064329701125,
            21.01663952206824
          ],
          [
            105.80625192100008,
            21.016470516918925
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.01663952206824,
        "to_latitude": 21.016470516918925,
        "to_longitude": 105.80625192100008,
        "from_longitude": 105.8064329701125,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3625,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80603466207415,
          21.016261451024373,
          105.80625192100008,
          21.016470516918925
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80625192100008,
            21.016470516918925
          ],
          [
            105.80603466207415,
            21.016261451024373
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.016470516918925,
        "to_latitude": 21.016261451024373,
        "to_longitude": 105.80603466207415,
        "from_longitude": 105.80625192100008,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3626,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80583886081017,
          21.016074918930336,
          105.80603466207415,
          21.016261451024373
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80603466207415,
            21.016261451024373
          ],
          [
            105.80583886081017,
            21.016074918930336
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.016261451024373,
        "to_latitude": 21.016074918930336,
        "to_longitude": 105.80583886081017,
        "from_longitude": 105.80603466207415,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3627,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80563769513071,
          21.0158808752303,
          105.80583886081017,
          21.016074918930336
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80583886081017,
            21.016074918930336
          ],
          [
            105.80563769513071,
            21.0158808752303
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.016074918930336,
        "to_latitude": 21.0158808752303,
        "to_longitude": 105.80563769513071,
        "from_longitude": 105.80583886081017,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3628,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80542445951642,
          21.015594190848468,
          105.80563769513071,
          21.0158808752303
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80563769513071,
            21.0158808752303
          ],
          [
            105.80556862819604,
            21.01582078423166
          ],
          [
            105.80552034842971,
            21.015758189409915
          ],
          [
            105.80547340977627,
            21.015698098356797
          ],
          [
            105.80542445951642,
            21.015594190848468
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "17",
        "diemCuoi": "LT3 18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.0158808752303,
        "to_latitude": 21.015594190848468,
        "to_longitude": 105.80542445951642,
        "from_longitude": 105.80563769513071,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3629,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80662608915974,
          21.01684608365719,
          105.80680713826318,
          21.017075178904154
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80662608915974,
            21.01684608365719
          ],
          [
            105.8067286835924,
            21.016962509161196
          ],
          [
            105.80680713826318,
            21.017075178904154
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "LT3 12",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.01684608365719,
        "to_latitude": 21.017075178904154,
        "to_longitude": 105.80680713826318,
        "from_longitude": 105.80662608915974,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3630,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80680713826318,
          21.017075178904154,
          105.80692783767445,
          21.017269221051087
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80680713826318,
            21.017075178904154
          ],
          [
            105.80692783767445,
            21.017269221051087
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "11",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.017075178904154,
        "to_latitude": 21.017269221051087,
        "to_longitude": 105.80692783767445,
        "from_longitude": 105.80680713826318,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3631,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80662608915974,
          21.01684608365719,
          105.8069687413021,
          21.017390653749338
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80662608915974,
            21.01684608365719
          ],
          [
            105.80674745905559,
            21.016958753501505
          ],
          [
            105.8069687413021,
            21.017306777572937
          ],
          [
            105.80680579717726,
            21.017390653749338
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "LT3 12",
        "diemCuoi": "LT3 26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.01684608365719,
        "to_latitude": 21.017390653749338,
        "to_longitude": 105.80680579717726,
        "from_longitude": 105.80662608915974,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3632,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80692783767445,
          21.017269221051087,
          105.80706463033218,
          21.017492056237113
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80692783767445,
            21.017269221051087
          ],
          [
            105.80706463033218,
            21.017492056237113
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "10",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.017269221051087,
        "to_latitude": 21.017492056237113,
        "to_longitude": 105.80706463033218,
        "from_longitude": 105.80692783767445,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3633,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80706463033218,
          21.017492056237113,
          105.80720946961314,
          21.01771864673077
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80706463033218,
            21.017492056237113
          ],
          [
            105.80718331802026,
            21.01767858656444
          ],
          [
            105.80720946961314,
            21.01771864673077
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "09",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.017492056237113,
        "to_latitude": 21.01771864673077,
        "to_longitude": 105.80720946961314,
        "from_longitude": 105.80706463033218,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3634,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80720946961314,
          21.01771864673077,
          105.80734089785537,
          21.017943985001576
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80720946961314,
            21.01771864673077
          ],
          [
            105.80734089785537,
            21.017943985001576
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "08",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.01771864673077,
        "to_latitude": 21.017943985001576,
        "to_longitude": 105.80734089785537,
        "from_longitude": 105.80720946961314,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3635,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80734089785537,
          21.017943985001576,
          105.80746562056927,
          21.018168071055996
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80734089785537,
            21.017943985001576
          ],
          [
            105.80746562056927,
            21.018168071055996
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "07",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.017943985001576,
        "to_latitude": 21.018168071055996,
        "to_longitude": 105.80746562056927,
        "from_longitude": 105.80734089785537,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3636,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80746562056927,
          21.018168071055996,
          105.80758631998054,
          21.018387149275135
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80746562056927,
            21.018168071055996
          ],
          [
            105.80758631998054,
            21.018387149275135
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "06",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.018168071055996,
        "to_latitude": 21.018387149275135,
        "to_longitude": 105.80758631998054,
        "from_longitude": 105.80746562056927,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3637,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80758631998054,
          21.018387149275135,
          105.80770701938283,
          21.018587449080098
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80758631998054,
            21.018387149275135
          ],
          [
            105.80768220890283,
            21.018553648512956
          ],
          [
            105.80770701938283,
            21.018587449080098
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "05",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.018387149275135,
        "to_latitude": 21.018587449080098,
        "to_longitude": 105.80770701938283,
        "from_longitude": 105.80758631998054,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3638,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80770701938283,
          21.018587449080098,
          105.80788806849525,
          21.01890918007769
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80770701938283,
            21.018587449080098
          ],
          [
            105.80788806849525,
            21.01890918007769
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "04",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.018587449080098,
        "to_latitude": 21.01890918007769,
        "to_longitude": 105.80788806849525,
        "from_longitude": 105.80770701938283,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3639,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80788806849525,
          21.01890918007769,
          105.80802620225684,
          21.019144531496096
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80788806849525,
            21.01890918007769
          ],
          [
            105.80802620225684,
            21.019144531496096
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.01890918007769,
        "to_latitude": 21.019144531496096,
        "to_longitude": 105.80802620225684,
        "from_longitude": 105.80788806849525,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3640,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80802620225684,
          21.019144531496096,
          105.80817774706613,
          21.01940742359911
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80802620225684,
            21.019144531496096
          ],
          [
            105.80817774706613,
            21.01940742359911
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "02",
        "diemCuoi": "LT3 01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.019144531496096,
        "to_latitude": 21.01940742359911,
        "to_longitude": 105.80817774706613,
        "from_longitude": 105.80802620225684,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3641,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80680579717726,
          21.017390653749338,
          105.80692193681381,
          21.017585446615076
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80680579717726,
            21.017390653749338
          ],
          [
            105.80692193681381,
            21.017585446615076
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "LT3 26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.017390653749338,
        "to_latitude": 21.017585446615076,
        "to_longitude": 105.80692193681381,
        "from_longitude": 105.80680579717726,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3642,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80692193681381,
          21.017585446615076,
          105.80702734762839,
          21.01777272794725
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80692193681381,
            21.017585446615076
          ],
          [
            105.80702734762839,
            21.01777272794725
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.017585446615076,
        "to_latitude": 21.01777272794725,
        "to_longitude": 105.80702734762839,
        "from_longitude": 105.80692193681381,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3643,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80702734762839,
          21.01777272794725,
          105.80713275844296,
          21.01796000904422
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80702734762839,
            21.01777272794725
          ],
          [
            105.80713275844296,
            21.01796000904422
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.01777272794725,
        "to_latitude": 21.01796000904422,
        "to_longitude": 105.80713275844296,
        "from_longitude": 105.80702734762839,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3644,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80713275844296,
          21.01796000904422,
          105.80723816925753,
          21.018147289905947
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80713275844296,
            21.01796000904422
          ],
          [
            105.80723816925753,
            21.018147289905947
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.01796000904422,
        "to_latitude": 21.018147289905947,
        "to_longitude": 105.80723816925753,
        "from_longitude": 105.80713275844296,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3645,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80723816925753,
          21.018147289905947,
          105.8073435800721,
          21.018334570532524
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80723816925753,
            21.018147289905947
          ],
          [
            105.8073435800721,
            21.018334570532524
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.018147289905947,
        "to_latitude": 21.018334570532524,
        "to_longitude": 105.8073435800721,
        "from_longitude": 105.80723816925753,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3646,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8073435800721,
          21.018334570532524,
          105.80744899088664,
          21.01852185092385
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8073435800721,
            21.018334570532524
          ],
          [
            105.80744899088664,
            21.01852185092385
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.018334570532524,
        "to_latitude": 21.01852185092385,
        "to_longitude": 105.80744899088664,
        "from_longitude": 105.8073435800721,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3647,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80744899088664,
          21.01852185092385,
          105.80755440170122,
          21.018709131080023
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80744899088664,
            21.01852185092385
          ],
          [
            105.80755440170122,
            21.018709131080023
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.01852185092385,
        "to_latitude": 21.018709131080023,
        "to_longitude": 105.80755440170122,
        "from_longitude": 105.80744899088664,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3648,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80755440170122,
          21.018709131080023,
          105.8076598125158,
          21.01889641100092
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80755440170122,
            21.018709131080023
          ],
          [
            105.8076598125158,
            21.01889641100092
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "33",
        "diemCuoi": "34",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.018709131080023,
        "to_latitude": 21.01889641100092,
        "to_longitude": 105.8076598125158,
        "from_longitude": 105.80755440170122,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3649,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8076598125158,
          21.01889641100092,
          105.80776522333036,
          21.01908369068665
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8076598125158,
            21.01889641100092
          ],
          [
            105.80776522333036,
            21.01908369068665
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.01889641100092,
        "to_latitude": 21.01908369068665,
        "to_longitude": 105.80776522333036,
        "from_longitude": 105.8076598125158,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3650,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80776522333036,
          21.01908369068665,
          105.80786929305005,
          21.019278481339903
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80776522333036,
            21.01908369068665
          ],
          [
            105.80786929305005,
            21.019278481339903
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "35",
        "diemCuoi": "LT3 36",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT3",
        "from_latitude": 21.01908369068665,
        "to_latitude": 21.019278481339903,
        "to_longitude": 105.80786929305005,
        "from_longitude": 105.80776522333036,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3651,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80814757215268,
          21.019189911720176,
          105.80828671174893,
          21.01940742359911
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80817774706613,
            21.01940742359911
          ],
          [
            105.80814757215268,
            21.019257199603015
          ],
          [
            105.80828671174893,
            21.019189911720176
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Daaif Khí Nguyễn Chí Thanh",
        "diemDau": "LT3 01",
        "diemCuoi": "LT3 1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT3",
        "from_latitude": 21.01940742359911,
        "to_latitude": 21.019189911720176,
        "to_longitude": 105.80828671174893,
        "from_longitude": 105.80817774706613,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3652,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80770701938283,
          21.01854019087531,
          105.80779720860244,
          21.018587449080098
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80770701938283,
            21.018587449080098
          ],
          [
            105.80779720860244,
            21.01854019087531
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Nguyễn Chí Thanh",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT3",
        "from_latitude": 21.018587449080098,
        "to_latitude": 21.01854019087531,
        "to_longitude": 105.80779720860244,
        "from_longitude": 105.80770701938283,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3653,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80779720860244,
          21.018415003490137,
          105.80803726631213,
          21.01854019087531
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80779720860244,
            21.01854019087531
          ],
          [
            105.80803726631213,
            21.018415003490137
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Nguyễn Chí Thanh",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT3",
        "from_latitude": 21.01854019087531,
        "to_latitude": 21.018415003490137,
        "to_longitude": 105.80803726631213,
        "from_longitude": 105.80779720860244,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3654,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80803726631213,
          21.018267282239236,
          105.80825050192642,
          21.018415003490137
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80803726631213,
            21.018415003490137
          ],
          [
            105.80822803842086,
            21.018313288660043
          ],
          [
            105.80825050192642,
            21.018267282239236
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Nguyễn Chí Thanh",
        "diemDau": "4-2",
        "diemCuoi": "4-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT3",
        "from_latitude": 21.018415003490137,
        "to_latitude": 21.018267282239236,
        "to_longitude": 105.80825050192642,
        "from_longitude": 105.80803726631213,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3655,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80690034497772,
          21.01712525432748,
          105.80692783767445,
          21.017269221051087
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80692783767445,
            21.017269221051087
          ],
          [
            105.80690034497772,
            21.01712525432748
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 107 Nguyễn Chí Thanh",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT3",
        "from_latitude": 21.017269221051087,
        "to_latitude": 21.01712525432748,
        "to_longitude": 105.80690034497772,
        "from_longitude": 105.80692783767445,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3656,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80690034497772,
          21.016980035572338,
          105.80717527139704,
          21.01712525432748
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80690034497772,
            21.01712525432748
          ],
          [
            105.80717527139704,
            21.016980035572338
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 107 Nguyễn Chí Thanh",
        "diemDau": "10-1",
        "diemCuoi": "10-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT3",
        "from_latitude": 21.01712525432748,
        "to_latitude": 21.016980035572338,
        "to_longitude": 105.80717527139704,
        "from_longitude": 105.80690034497772,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3657,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80717527139704,
          21.01686361008871,
          105.80740728247454,
          21.016980035572338
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80717527139704,
            21.016980035572338
          ],
          [
            105.80740728247454,
            21.01686361008871
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 107 Nguyễn Chí Thanh",
        "diemDau": "10-2",
        "diemCuoi": "LT3 10-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT3",
        "from_latitude": 21.016980035572338,
        "to_latitude": 21.01686361008871,
        "to_longitude": 105.80740728247454,
        "from_longitude": 105.80717527139704,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3658,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80720946961314,
          21.017647602518032,
          105.80724601465096,
          21.01771864673077
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80720946961314,
            21.01771864673077
          ],
          [
            105.80724601465096,
            21.017647602518032
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "diemDau": "08",
        "diemCuoi": "8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT3",
        "from_latitude": 21.01771864673077,
        "to_latitude": 21.017647602518032,
        "to_longitude": 105.80724601465096,
        "from_longitude": 105.80720946961314,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3659,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80724601465096,
          21.017524918146083,
          105.8074874134645,
          21.017647602518032
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80724601465096,
            21.017647602518032
          ],
          [
            105.8074874134645,
            21.017524918146083
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "diemDau": "8-1",
        "diemCuoi": "8-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT3",
        "from_latitude": 21.017647602518032,
        "to_latitude": 21.017524918146083,
        "to_longitude": 105.8074874134645,
        "from_longitude": 105.80724601465096,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3660,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8074874134645,
          21.017407241205614,
          105.80772344785362,
          21.017524918146083
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8074874134645,
            21.017524918146083
          ],
          [
            105.80772344785362,
            21.017407241205614
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "diemDau": "8-2",
        "diemCuoi": "8-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT3",
        "from_latitude": 21.017524918146083,
        "to_latitude": 21.017407241205614,
        "to_longitude": 105.80772344785362,
        "from_longitude": 105.8074874134645,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3661,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80772344785362,
          21.01727954910098,
          105.80797021108263,
          21.017407241205614
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80772344785362,
            21.017407241205614
          ],
          [
            105.80797021108263,
            21.01727954910098
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "diemDau": "8-3",
        "diemCuoi": "8-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT3",
        "from_latitude": 21.017407241205614,
        "to_latitude": 21.01727954910098,
        "to_longitude": 105.80797021108263,
        "from_longitude": 105.80772344785362,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3662,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80790583806987,
          21.017141841806538,
          105.80797021108263,
          21.01727954910098
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80797021108263,
            21.01727954910098
          ],
          [
            105.80790583806987,
            21.017141841806538
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "diemDau": "8-4",
        "diemCuoi": "8-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT3",
        "from_latitude": 21.01727954910098,
        "to_latitude": 21.017141841806538,
        "to_longitude": 105.80790583806987,
        "from_longitude": 105.80797021108263,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3663,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80797021108263,
          21.01727954910098,
          105.80807347613461,
          21.017514903091474
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80797021108263,
            21.01727954910098
          ],
          [
            105.80798127519182,
            21.017351845377643
          ],
          [
            105.80807347613461,
            21.017514903091474
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "diemDau": "8-4",
        "diemCuoi": "8-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT3",
        "from_latitude": 21.01727954910098,
        "to_latitude": 21.017514903091474,
        "to_longitude": 105.80807347613461,
        "from_longitude": 105.80797021108263,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3664,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80807347613461,
          21.017514903091474,
          105.80815930681828,
          21.01766262508681
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80807347613461,
            21.017514903091474
          ],
          [
            105.80815930681828,
            21.01766262508681
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "diemDau": "8-10",
        "diemCuoi": "8-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT3",
        "from_latitude": 21.017514903091474,
        "to_latitude": 21.01766262508681,
        "to_longitude": 105.80815930681828,
        "from_longitude": 105.80807347613461,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3665,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80815930681828,
          21.017434782625156,
          105.80861662345389,
          21.01766262508681
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80815930681828,
            21.01766262508681
          ],
          [
            105.80861662345389,
            21.017434782625156
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 54,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "diemDau": "8-12",
        "diemCuoi": "8-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT3",
        "from_latitude": 21.01766262508681,
        "to_latitude": 21.017434782625156,
        "to_longitude": 105.80861662345389,
        "from_longitude": 105.80815930681828,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3666,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80815930681828,
          21.01751114744569,
          105.80841009336793,
          21.01766262508681
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80815930681828,
            21.01766262508681
          ],
          [
            105.80819585191,
            21.017612236879277
          ],
          [
            105.80841009336793,
            21.01751114744569
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "diemDau": "8-12",
        "diemCuoi": "8-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT3",
        "from_latitude": 21.01766262508681,
        "to_latitude": 21.01751114744569,
        "to_longitude": 105.80841009336793,
        "from_longitude": 105.80815930681828,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3667,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80807347613461,
          21.01737344036508,
          105.80833633261908,
          21.017514903091474
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80807347613461,
            21.017514903091474
          ],
          [
            105.80811538564183,
            21.017469522362667
          ],
          [
            105.80833633261908,
            21.01737344036508
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "diemDau": "8-10",
        "diemCuoi": "8-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT3",
        "from_latitude": 21.017514903091474,
        "to_latitude": 21.01737344036508,
        "to_longitude": 105.80833633261908,
        "from_longitude": 105.80807347613461,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3668,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80833633261908,
          21.01731084619505,
          105.80852408725086,
          21.01737344036508
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80833633261908,
            21.01737344036508
          ],
          [
            105.8084131108449,
            21.017356852910932
          ],
          [
            105.80852408725086,
            21.01731084619505
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "diemDau": "8-11",
        "diemCuoi": "8-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT3",
        "from_latitude": 21.01737344036508,
        "to_latitude": 21.01731084619505,
        "to_longitude": 105.80852408725086,
        "from_longitude": 105.80833633261908,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3669,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80797021108263,
          21.01719410799658,
          105.80819316970226,
          21.017285495547092
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80797021108263,
            21.01727954910098
          ],
          [
            105.80801748500532,
            21.017285495547092
          ],
          [
            105.80819316970226,
            21.01719410799658
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "diemDau": "8-4",
        "diemCuoi": "8-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT3",
        "from_latitude": 21.01727954910098,
        "to_latitude": 21.01719410799658,
        "to_longitude": 105.80819316970226,
        "from_longitude": 105.80797021108263,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3670,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80819316970226,
          21.017060469254655,
          105.8084114344718,
          21.01719410799658
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80819316970226,
            21.01719410799658
          ],
          [
            105.80822401510027,
            21.017152795798253
          ],
          [
            105.8084114344718,
            21.017060469254655
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "diemDau": "8-5",
        "diemCuoi": "8-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT3",
        "from_latitude": 21.01719410799658,
        "to_latitude": 21.017060469254655,
        "to_longitude": 105.8084114344718,
        "from_longitude": 105.80819316970226,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3671,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80790583806987,
          21.016997874953226,
          105.80817942338534,
          21.017141841806538
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80790583806987,
            21.017141841806538
          ],
          [
            105.80817942338534,
            21.016997874953226
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "diemDau": "8-7",
        "diemCuoi": "8-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT3",
        "from_latitude": 21.017141841806538,
        "to_latitude": 21.016997874953226,
        "to_longitude": 105.80817942338534,
        "from_longitude": 105.80790583806987,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3672,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80790583806987,
          21.016989111748998,
          105.80803406265304,
          21.017141841806538
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80790583806987,
            21.017141841806538
          ],
          [
            105.80803406265304,
            21.017074367114084
          ],
          [
            105.80798898654582,
            21.016989111748998
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "diemDau": "8-7",
        "diemCuoi": "8-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT3",
        "from_latitude": 21.017141841806538,
        "to_latitude": 21.016989111748998,
        "to_longitude": 105.80798898654582,
        "from_longitude": 105.80790583806987,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3673,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8409219924946,
          21.016150889652895,
          105.84136533042503,
          21.01635603983414
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84136533042503,
            21.01635603983414
          ],
          [
            105.84134479414121,
            21.016192162656157
          ],
          [
            105.8409219924946,
            21.016150889652895
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 226 Lê Duẩn",
        "diemDau": "16",
        "diemCuoi": "18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV3",
        "from_latitude": 21.01635603983414,
        "to_latitude": 21.016150889652895,
        "to_longitude": 105.8409219924946,
        "from_longitude": 105.84136533042503,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3674,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84078385483433,
          21.016024408892903,
          105.8409219924946,
          21.016150889652895
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8409219924946,
            21.016150889652895
          ],
          [
            105.84078385483433,
            21.016024408892903
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 226 Lê Duẩn",
        "diemDau": "16-1",
        "diemCuoi": "16-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV3",
        "from_latitude": 21.016150889652895,
        "to_latitude": 21.016024408892903,
        "to_longitude": 105.84078385483433,
        "from_longitude": 105.8409219924946,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3675,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84066181822685,
          21.016000662370296,
          105.84078385483433,
          21.016024408892903
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84078385483433,
            21.016024408892903
          ],
          [
            105.84066181822685,
            21.016000662370296
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 226 Lê Duẩn",
        "diemDau": "18-2",
        "diemCuoi": "18-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV3",
        "from_latitude": 21.016024408892903,
        "to_latitude": 21.016000662370296,
        "to_longitude": 105.84066181822685,
        "from_longitude": 105.84078385483433,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3676,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84066181822685,
          21.015834120815207,
          105.84075971494667,
          21.016000662370296
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84066181822685,
            21.016000662370296
          ],
          [
            105.8407574840831,
            21.015988223554476
          ],
          [
            105.84075971494667,
            21.015834120815207
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 226 Lê Duẩn",
        "diemDau": "18-3",
        "diemCuoi": "18-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV3",
        "from_latitude": 21.016000662370296,
        "to_latitude": 21.015834120815207,
        "to_longitude": 105.84075971494667,
        "from_longitude": 105.84066181822685,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3677,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84066181822685,
          21.01579406013597,
          105.84069301734549,
          21.016000662370296
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84066181822685,
            21.016000662370296
          ],
          [
            105.84069301734549,
            21.01596181415747
          ],
          [
            105.84068461309397,
            21.01579406013597
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 226 Lê Duẩn",
        "diemDau": "18-3",
        "diemCuoi": "18-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV3",
        "from_latitude": 21.016000662370296,
        "to_latitude": 21.01579406013597,
        "to_longitude": 105.84068461309397,
        "from_longitude": 105.84066181822685,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3678,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84060146461805,
          21.015781541171634,
          105.84066181822685,
          21.016000662370296
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84066181822685,
            21.016000662370296
          ],
          [
            105.84060182224633,
            21.015944287626677
          ],
          [
            105.84060146461805,
            21.015781541171634
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 226 Lê Duẩn",
        "diemDau": "18-3",
        "diemCuoi": "18-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV3",
        "from_latitude": 21.016000662370296,
        "to_latitude": 21.015781541171634,
        "to_longitude": 105.84060146461805,
        "from_longitude": 105.84066181822685,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3679,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84045394312035,
          21.01597683689604,
          105.84066181822685,
          21.016000662370296
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84066181822685,
            21.016000662370296
          ],
          [
            105.84045394312035,
            21.01597683689604
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 226 Lê Duẩn",
        "diemDau": "18-3",
        "diemCuoi": "18-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV3",
        "from_latitude": 21.016000662370296,
        "to_latitude": 21.01597683689604,
        "to_longitude": 105.84045394312035,
        "from_longitude": 105.84066181822685,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3680,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84045394312035,
          21.01597683689604,
          105.84049954067892,
          21.016142086925388
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84045394312035,
            21.01597683689604
          ],
          [
            105.84049954067892,
            21.016142086925388
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 226 Lê Duẩn",
        "diemDau": "18-4",
        "diemCuoi": "18-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV3",
        "from_latitude": 21.01597683689604,
        "to_latitude": 21.016142086925388,
        "to_longitude": 105.84049954067892,
        "from_longitude": 105.84045394312035,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3681,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84045394312035,
          21.01563632110806,
          105.84047271858356,
          21.01597683689604
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84045394312035,
            21.01597683689604
          ],
          [
            105.84047271858356,
            21.01563632110806
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 226 Lê Duẩn",
        "diemDau": "18-4",
        "diemCuoi": "18-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV3",
        "from_latitude": 21.01597683689604,
        "to_latitude": 21.01563632110806,
        "to_longitude": 105.84047271858356,
        "from_longitude": 105.84045394312035,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3682,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84047271858356,
          21.01563632110806,
          105.84064706216762,
          21.01564132869896
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84047271858356,
            21.01563632110806
          ],
          [
            105.84064706216762,
            21.01564132869896
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 226 Lê Duẩn",
        "diemDau": "18-6",
        "diemCuoi": "18-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV3",
        "from_latitude": 21.01563632110806,
        "to_latitude": 21.01564132869896,
        "to_longitude": 105.84064706216762,
        "from_longitude": 105.84047271858356,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3683,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84107553204237,
          21.01457182794463,
          105.84119353847723,
          21.014574714996375
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84119353847723,
            21.014574714996375
          ],
          [
            105.84107553204237,
            21.01457182794463
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 304 Lê Duẩn",
        "diemDau": "26-1",
        "diemCuoi": "26-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV3",
        "from_latitude": 21.014574714996375,
        "to_latitude": 21.01457182794463,
        "to_longitude": 105.84107553204237,
        "from_longitude": 105.84119353847723,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3684,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84098866594907,
          21.014569702747682,
          105.84107553204237,
          21.01457182794463
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84107553204237,
            21.01457182794463
          ],
          [
            105.84098866594907,
            21.014569702747682
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 304 Lê Duẩn",
        "diemDau": "26-2",
        "diemCuoi": "26-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV3",
        "from_latitude": 21.01457182794463,
        "to_latitude": 21.014569702747682,
        "to_longitude": 105.84098866594907,
        "from_longitude": 105.84107553204237,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3685,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84089747084093,
          21.014569702747682,
          105.84098866594907,
          21.01460725994264
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84098866594907,
            21.014569702747682
          ],
          [
            105.84095782055105,
            21.01460725994264
          ],
          [
            105.84089747084093,
            21.014593488972025
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 304 Lê Duẩn",
        "diemDau": "26-3",
        "diemCuoi": "26-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV3",
        "from_latitude": 21.014569702747682,
        "to_latitude": 21.014593488972025,
        "to_longitude": 105.84089747084093,
        "from_longitude": 105.84098866594907,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3686,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84081724566315,
          21.014593488972025,
          105.84089747084093,
          21.014603875083743
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84089747084093,
            21.014593488972025
          ],
          [
            105.84081724566315,
            21.014603875083743
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 304 Lê Duẩn",
        "diemDau": "26-4",
        "diemCuoi": "26-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV3",
        "from_latitude": 21.014593488972025,
        "to_latitude": 21.014603875083743,
        "to_longitude": 105.84081724566315,
        "from_longitude": 105.84089747084093,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3687,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84119353847723,
          21.014464035592297,
          105.84134156267655,
          21.014574714996375
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84134156267655,
            21.014464035592297
          ],
          [
            105.841327652462,
            21.01456344321301
          ],
          [
            105.84119353847723,
            21.014574714996375
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 304 Lê Duẩn",
        "diemDau": "26",
        "diemCuoi": "26-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV3",
        "from_latitude": 21.014464035592297,
        "to_latitude": 21.014574714996375,
        "to_longitude": 105.84119353847723,
        "from_longitude": 105.84134156267655,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3688,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84075602750387,
          21.014603875083743,
          105.84081724566315,
          21.01461180050838
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84081724566315,
            21.014603875083743
          ],
          [
            105.84075602750387,
            21.01461180050838
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 304 Lê Duẩn",
        "diemDau": "26-5",
        "diemCuoi": "26-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV3",
        "from_latitude": 21.014603875083743,
        "to_latitude": 21.01461180050838,
        "to_longitude": 105.84075602750387,
        "from_longitude": 105.84081724566315,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3689,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84071461981824,
          21.014592388208523,
          105.84075602750387,
          21.01463104616099
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84075602750387,
            21.01461180050838
          ],
          [
            105.84071879101711,
            21.01463104616099
          ],
          [
            105.84071461981824,
            21.014592388208523
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 304 Lê Duẩn",
        "diemDau": "26-6",
        "diemCuoi": "26-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV3",
        "from_latitude": 21.01461180050838,
        "to_latitude": 21.014592388208523,
        "to_longitude": 105.84071461981824,
        "from_longitude": 105.84075602750387,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3690,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84070806218614,
          21.014349367010997,
          105.84071461981824,
          21.014592388208523
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84071461981824,
            21.014592388208523
          ],
          [
            105.84070806218614,
            21.014349367010997
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 304 Lê Duẩn",
        "diemDau": "26-7",
        "diemCuoi": "26-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV3",
        "from_latitude": 21.014592388208523,
        "to_latitude": 21.014349367010997,
        "to_longitude": 105.84070806218614,
        "from_longitude": 105.84071461981824,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3691,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84070806218614,
          21.014244206660056,
          105.84087064875453,
          21.014349367010997
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84070806218614,
            21.014349367010997
          ],
          [
            105.84087064875453,
            21.014244206660056
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 304 Lê Duẩn",
        "diemDau": "26-8",
        "diemCuoi": "26-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV3",
        "from_latitude": 21.014349367010997,
        "to_latitude": 21.014244206660056,
        "to_longitude": 105.84087064875453,
        "from_longitude": 105.84070806218614,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3692,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84117660187884,
          21.014156378469494,
          105.84133325401675,
          21.01423537858578
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84133325401675,
            21.014156378469494
          ],
          [
            105.84131741785717,
            21.01422035567097
          ],
          [
            105.84117660187884,
            21.01423537858578
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 324 Lê Duẩn",
        "diemDau": "28",
        "diemCuoi": "30-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV3",
        "from_latitude": 21.014156378469494,
        "to_latitude": 21.01423537858578,
        "to_longitude": 105.84117660187884,
        "from_longitude": 105.84133325401675,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3693,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84115922902106,
          21.01409328073128,
          105.84131741785717,
          21.01422035567097
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84131741785717,
            21.01422035567097
          ],
          [
            105.84130266570558,
            21.01409516465869
          ],
          [
            105.84115922902106,
            21.01409328073128
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVLN3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 342 Lê Duẩn",
        "diemDau": "28",
        "diemCuoi": "30-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV3",
        "from_latitude": 21.01422035567097,
        "to_latitude": 21.01409328073128,
        "to_longitude": 105.84115922902106,
        "from_longitude": 105.84131741785717,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3694,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83654308581265,
          21.017484048277936,
          105.83660611772152,
          21.01760297703925
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83654308581265,
            21.017484048277936
          ],
          [
            105.83660611772152,
            21.01760297703925
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BCA",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hẻm 26/11 Ngõ Cống Trắng",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BCA",
        "from_latitude": 21.017484048277936,
        "to_latitude": 21.01760297703925,
        "to_longitude": 105.83660611772152,
        "from_longitude": 105.83654308581265,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3695,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8364693250638,
          21.01760297703925,
          105.83660611772152,
          21.01766431920575
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83660611772152,
            21.01760297703925
          ],
          [
            105.8364693250638,
            21.01766431920575
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BCA",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hẻm 26/11 Ngõ Cống Trắng",
        "diemDau": "02",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BCA",
        "from_latitude": 21.01760297703925,
        "to_latitude": 21.01766431920575,
        "to_longitude": 105.8364693250638,
        "from_longitude": 105.83660611772152,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3696,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83660611772152,
          21.01760297703925,
          105.83666780852656,
          21.01774193578818
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83660611772152,
            21.01760297703925
          ],
          [
            105.83666780852656,
            21.01774193578818
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BCA",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hẻm 26/11 Ngõ Cống Trắng",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BCA",
        "from_latitude": 21.01760297703925,
        "to_latitude": 21.01774193578818,
        "to_longitude": 105.83666780852656,
        "from_longitude": 105.83660611772152,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3697,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83646396063932,
          21.01774193578818,
          105.83666780852656,
          21.017829567364384
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83666780852656,
            21.01774193578818
          ],
          [
            105.83646396063932,
            21.017829567364384
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BCA",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hẻm 26/11 Ngõ Cống Trắng",
        "diemDau": "03",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BCA",
        "from_latitude": 21.01774193578818,
        "to_latitude": 21.017829567364384,
        "to_longitude": 105.83646396063932,
        "from_longitude": 105.83666780852656,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3698,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83666780852656,
          21.01774193578818,
          105.83674156927536,
          21.017904680103843
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83666780852656,
            21.01774193578818
          ],
          [
            105.83674156927536,
            21.017904680103843
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BCA",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hẻm 26/11 Ngõ Cống Trắng",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BCA",
        "from_latitude": 21.01774193578818,
        "to_latitude": 21.017904680103843,
        "to_longitude": 105.83674156927536,
        "from_longitude": 105.83666780852656,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3699,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83648407720638,
          21.017904680103843,
          105.83674156927536,
          21.017954755241885
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83674156927536,
            21.017904680103843
          ],
          [
            105.83648407720638,
            21.017954755241885
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BCA",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hẻm 26/11 Ngõ Cống Trắng",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BCA",
        "from_latitude": 21.017904680103843,
        "to_latitude": 21.017954755241885,
        "to_longitude": 105.83648407720638,
        "from_longitude": 105.83674156927536,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3700,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80962245185121,
          21.016669254440647,
          105.80969084817558,
          21.01679506923592
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80969084817558,
            21.016669254440647
          ],
          [
            105.80962245185121,
            21.01670305544079
          ],
          [
            105.80965396779668,
            21.01679506923592
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "diemDau": "Tủ điện",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.016669254440647,
        "to_latitude": 21.01679506923592,
        "to_longitude": 105.80965396779668,
        "from_longitude": 105.80969084817558,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3701,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80965396779668,
          21.01679506923592,
          105.8098122181343,
          21.017081751309266
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80965396779668,
            21.01679506923592
          ],
          [
            105.8098122181343,
            21.017081751309266
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "diemDau": "07",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.01679506923592,
        "to_latitude": 21.017081751309266,
        "to_longitude": 105.8098122181343,
        "from_longitude": 105.80965396779668,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3702,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8098122181343,
          21.017081751309266,
          105.80992487091336,
          21.017289564169776
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8098122181343,
            21.017081751309266
          ],
          [
            105.80992487091336,
            21.017289564169776
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "diemDau": "06",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.017081751309266,
        "to_latitude": 21.017289564169776,
        "to_longitude": 105.80992487091336,
        "from_longitude": 105.8098122181343,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3703,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80992487091336,
          21.017219458659227,
          105.81010860223353,
          21.017289564169776
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80992487091336,
            21.017289564169776
          ],
          [
            105.80998186785934,
            21.01727516750511
          ],
          [
            105.81010860223353,
            21.017219458659227
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28 Nguyên Hồng",
        "diemDau": "05",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.017289564169776,
        "to_latitude": 21.017219458659227,
        "to_longitude": 105.81010860223353,
        "from_longitude": 105.80992487091336,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3704,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81010860223353,
          21.0171193078719,
          105.81031423825922,
          21.017219458659227
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81010860223353,
            21.017219458659227
          ],
          [
            105.81031423825922,
            21.0171193078719
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28 Nguyên Hồng",
        "diemDau": "04",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.017219458659227,
        "to_latitude": 21.0171193078719,
        "to_longitude": 105.81031423825922,
        "from_longitude": 105.81010860223353,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3705,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81031423825922,
          21.0170229126758,
          105.81052032131956,
          21.0171193078719
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81031423825922,
            21.0171193078719
          ],
          [
            105.81052032131956,
            21.0170229126758
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28 Nguyên Hồng",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.0171193078719,
        "to_latitude": 21.0170229126758,
        "to_longitude": 105.81052032131956,
        "from_longitude": 105.81031423825922,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3706,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81052032131956,
          21.01691399854753,
          105.8107590379254,
          21.0170229126758
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81052032131956,
            21.0170229126758
          ],
          [
            105.8107590379254,
            21.01691399854753
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28 Nguyên Hồng",
        "diemDau": "02",
        "diemCuoi": "BX 01",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.0170229126758,
        "to_latitude": 21.01691399854753,
        "to_longitude": 105.8107590379254,
        "from_longitude": 105.81052032131956,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3707,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80956947821687,
          21.016647346381173,
          105.80969084817558,
          21.01669178844103
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80969084817558,
            21.016669254440647
          ],
          [
            105.80960367638805,
            21.01669178844103
          ],
          [
            105.80956947821687,
            21.016647346381173
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "diemDau": "Tủ điện",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.016669254440647,
        "to_latitude": 21.016647346381173,
        "to_longitude": 105.80956947821687,
        "from_longitude": 105.80969084817558,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3708,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80925968306097,
          21.01651276822819,
          105.80956947821687,
          21.016647346381173
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80956947821687,
            21.016647346381173
          ],
          [
            105.80951382238376,
            21.016631697764094
          ],
          [
            105.80944408494655,
            21.01651276822819
          ],
          [
            105.80925968306097,
            21.0166072859212
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 53,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.016647346381173,
        "to_latitude": 21.0166072859212,
        "to_longitude": 105.80925968306097,
        "from_longitude": 105.80956947821687,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3709,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80929321068469,
          21.01622984074002,
          105.80956947821687,
          21.016647346381173
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80956947821687,
            21.016647346381173
          ],
          [
            105.80933143216747,
            21.01622984074002
          ],
          [
            105.80929321068469,
            21.016244237506417
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 65,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.016647346381173,
        "to_latitude": 21.016244237506417,
        "to_longitude": 105.80929321068469,
        "from_longitude": 105.80956947821687,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3710,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80916848797081,
          21.016016392877724,
          105.80929321068469,
          21.016244237506417
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80929321068469,
            21.016244237506417
          ],
          [
            105.80916848797081,
            21.016016392877724
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.016244237506417,
        "to_latitude": 21.016016392877724,
        "to_longitude": 105.80916848797081,
        "from_longitude": 105.80929321068469,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3711,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80911082045944,
          21.01616349041027,
          105.80929321068469,
          21.016244237506417
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80929321068469,
            21.016244237506417
          ],
          [
            105.8092321904361,
            21.01616349041027
          ],
          [
            105.80911082045944,
            21.016226711008805
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "diemDau": "12",
        "diemCuoi": "12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.016244237506417,
        "to_latitude": 21.016226711008805,
        "to_longitude": 105.80911082045944,
        "from_longitude": 105.80929321068469,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3712,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80889087932577,
          21.016226711008805,
          105.80911082045944,
          21.01633437374693
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80911082045944,
            21.016226711008805
          ],
          [
            105.80889087932577,
            21.01633437374693
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "diemDau": "12-1",
        "diemCuoi": "12-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.016226711008805,
        "to_latitude": 21.01633437374693,
        "to_longitude": 105.80889087932577,
        "from_longitude": 105.80911082045944,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3713,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80864813940833,
          21.01633437374693,
          105.80889087932577,
          21.01640197589133
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80889087932577,
            21.01633437374693
          ],
          [
            105.80878292042374,
            21.016386327249347
          ],
          [
            105.80875878054508,
            21.016348770501548
          ],
          [
            105.80864813940833,
            21.01640197589133
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "diemDau": "12-2",
        "diemCuoi": "12-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.01633437374693,
        "to_latitude": 21.01640197589133,
        "to_longitude": 105.80864813940833,
        "from_longitude": 105.80889087932577,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3714,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80903437751184,
          21.01579480738251,
          105.80916848797081,
          21.016016392877724
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80916848797081,
            21.016016392877724
          ],
          [
            105.80903437751184,
            21.01579480738251
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.016016392877724,
        "to_latitude": 21.01579480738251,
        "to_longitude": 105.80903437751184,
        "from_longitude": 105.80916848797081,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3715,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80969084817558,
          21.01663107182055,
          105.80977869054652,
          21.016669254440647
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80969084817558,
            21.016669254440647
          ],
          [
            105.80977869054652,
            21.01663107182055
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Nguyên Hồng",
        "diemDau": "Tủ điện",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.016669254440647,
        "to_latitude": 21.01663107182055,
        "to_longitude": 105.80977869054652,
        "from_longitude": 105.80969084817558,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3716,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80977869054652,
          21.016574736788602,
          105.8100683691174,
          21.016683025218775
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80977869054652,
            21.01663107182055
          ],
          [
            105.80983300526677,
            21.016683025218775
          ],
          [
            105.8100683691174,
            21.016574736788602
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Nguyên Hồng",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.01663107182055,
        "to_latitude": 21.016574736788602,
        "to_longitude": 105.8100683691174,
        "from_longitude": 105.80977869054652,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3717,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8100683691174,
          21.016449547752583,
          105.81032317897865,
          21.016574736788602
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8100683691174,
            21.016574736788602
          ],
          [
            105.81032317897865,
            21.016449547752583
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Nguyên Hồng",
        "diemDau": "09",
        "diemCuoi": "BX 10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.016574736788602,
        "to_latitude": 21.016449547752583,
        "to_longitude": 105.81032317897865,
        "from_longitude": 105.8100683691174,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3718,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8100683691174,
          21.016514020117945,
          105.81025746485304,
          21.016574736788602
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8100683691174,
            21.016574736788602
          ],
          [
            105.81022326667288,
            21.016514020117945
          ],
          [
            105.81025746485304,
            21.01656972922905
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Nguyên Hồng",
        "diemDau": "09",
        "diemCuoi": "9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.016574736788602,
        "to_latitude": 21.01656972922905,
        "to_longitude": 105.81025746485304,
        "from_longitude": 105.8100683691174,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3719,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81025746485304,
          21.01656972922905,
          105.81040431576734,
          21.01681822916257
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81025746485304,
            21.01656972922905
          ],
          [
            105.81040431576734,
            21.01681822916257
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Nguyên Hồng",
        "diemDau": "9-1",
        "diemCuoi": "9-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.01656972922905,
        "to_latitude": 21.01681822916257,
        "to_longitude": 105.81040431576734,
        "from_longitude": 105.81025746485304,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3720,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81009586173329,
          21.01681822916257,
          105.81040431576734,
          21.01696344807525
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81040431576734,
            21.01681822916257
          ],
          [
            105.81009586173329,
            21.01696344807525
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Nguyên Hồng",
        "diemDau": "9-2",
        "diemCuoi": "9-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.01681822916257,
        "to_latitude": 21.01696344807525,
        "to_longitude": 105.81009586173329,
        "from_longitude": 105.81040431576734,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3721,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81002746540891,
          21.01677190930652,
          105.81010793168608,
          21.01696344807525
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81009586173329,
            21.01696344807525
          ],
          [
            105.8100851329113,
            21.016939662227234
          ],
          [
            105.81010793168608,
            21.016928395245362
          ],
          [
            105.81002746540891,
            21.01677190930652
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Nguyên Hồng",
        "diemDau": "9-3",
        "diemCuoi": "9-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.01696344807525,
        "to_latitude": 21.01677190930652,
        "to_longitude": 105.81002746540891,
        "from_longitude": 105.81009586173329,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3722,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82287792874651,
          21.0100635156532,
          105.82305897785895,
          21.01045161793869
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82287792874651,
            21.0100635156532
          ],
          [
            105.82290843895613,
            21.01027227723334
          ],
          [
            105.82305897785895,
            21.01045161793869
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.0100635156532,
        "to_latitude": 21.01045161793869,
        "to_longitude": 105.82305897785895,
        "from_longitude": 105.82287792874651,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3723,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82290743304964,
          21.010427518051458,
          105.82305897785895,
          21.01051922275105
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82305897785895,
            21.01045161793869
          ],
          [
            105.82300231627202,
            21.010427518051458
          ],
          [
            105.82290743304964,
            21.01051922275105
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.01045161793869,
        "to_latitude": 21.01051922275105,
        "to_longitude": 105.82290743304964,
        "from_longitude": 105.82305897785895,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3724,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82268749191596,
          21.01051922275105,
          105.82290743304964,
          21.010742068021923
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82290743304964,
            21.01051922275105
          ],
          [
            105.82268749191596,
            21.010742068021923
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.01051922275105,
        "to_latitude": 21.010742068021923,
        "to_longitude": 105.82268749191596,
        "from_longitude": 105.82290743304964,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3725,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82259093239234,
          21.010742068021923,
          105.82268749191596,
          21.010899812225762
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82268749191596,
            21.010742068021923
          ],
          [
            105.82261607816854,
            21.010828138771068
          ],
          [
            105.82263217142396,
            21.010865696908123
          ],
          [
            105.82259093239234,
            21.010899812225762
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.010742068021923,
        "to_latitude": 21.010899812225762,
        "to_longitude": 105.82259093239234,
        "from_longitude": 105.82268749191596,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3726,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82305897785895,
          21.01045161793869,
          105.82324807359457,
          21.010634401247128
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82305897785895,
            21.01045161793869
          ],
          [
            105.82324807359457,
            21.010634401247128
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.01045161793869,
        "to_latitude": 21.010634401247128,
        "to_longitude": 105.82324807359457,
        "from_longitude": 105.82305897785895,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3727,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82300935699773,
          21.010819688206727,
          105.82318101837406,
          21.010959905211998
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82318101837406,
            21.010819688206727
          ],
          [
            105.82300935699773,
            21.010959905211998
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.010819688206727,
        "to_latitude": 21.010959905211998,
        "to_longitude": 105.82300935699773,
        "from_longitude": 105.82318101837406,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3728,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82314715555297,
          21.010634401247128,
          105.82324807359457,
          21.010727983691826
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82324807359457,
            21.010634401247128
          ],
          [
            105.82314715555297,
            21.010727983691826
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.010634401247128,
        "to_latitude": 21.010727983691826,
        "to_longitude": 105.82314715555297,
        "from_longitude": 105.82324807359457,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3729,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82314715555297,
          21.010727983691826,
          105.82318101837406,
          21.010819688206727
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82314715555297,
            21.010727983691826
          ],
          [
            105.82318101837406,
            21.010819688206727
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.010727983691826,
        "to_latitude": 21.010819688206727,
        "to_longitude": 105.82318101837406,
        "from_longitude": 105.82314715555297,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3730,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82314883189012,
          21.00755973313664,
          105.8233612229008,
          21.007685428142377
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8233612229008,
            21.00755973313664
          ],
          [
            105.8231723012483,
            21.007646617232126
          ],
          [
            105.82314883189012,
            21.007685428142377
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.00755973313664,
        "to_latitude": 21.007685428142377,
        "to_longitude": 105.82314883189012,
        "from_longitude": 105.8233612229008,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3731,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80968816597684,
          21.01634251104321,
          105.80980081875589,
          21.01663107182055
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80977869054652,
            21.01663107182055
          ],
          [
            105.80979813654814,
            21.016609163753788
          ],
          [
            105.80980081875589,
            21.016580370290903
          ],
          [
            105.80968816597684,
            21.016377564008312
          ],
          [
            105.80969084818457,
            21.016351274284638
          ],
          [
            105.80972169357361,
            21.01634251104321
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 36/45 Nguyên Hồng",
        "diemDau": "08",
        "diemCuoi": "8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.01663107182055,
        "to_latitude": 21.01634251104321,
        "to_longitude": 105.80972169357361,
        "from_longitude": 105.80977869054652,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3732,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80972169357361,
          21.016202299097923,
          105.81002478319218,
          21.01634251104321
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80972169357361,
            21.01634251104321
          ],
          [
            105.81002478319218,
            21.016202299097923
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 36/45 Nguyên Hồng",
        "diemDau": "8-1",
        "diemCuoi": "8-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.01634251104321,
        "to_latitude": 21.016202299097923,
        "to_longitude": 105.81002478319218,
        "from_longitude": 105.80972169357361,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3733,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80960769969069,
          21.016158482837742,
          105.80972169357361,
          21.01634251104321
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80972169357361,
            21.01634251104321
          ],
          [
            105.80960769969069,
            21.016158482837742
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 36/45 Nguyên Hồng",
        "diemDau": "8-1",
        "diemCuoi": "8-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.01634251104321,
        "to_latitude": 21.016158482837742,
        "to_longitude": 105.80960769969069,
        "from_longitude": 105.80972169357361,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3734,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80944408494655,
          21.01586679140721,
          105.80960769969069,
          21.016158482837742
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80960769969069,
            21.016158482837742
          ],
          [
            105.80945883710709,
            21.015910607751458
          ],
          [
            105.80944408494655,
            21.01586679140721
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 36/45 Nguyên Hồng",
        "diemDau": "8-3",
        "diemCuoi": "8-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.016158482837742,
        "to_latitude": 21.01586679140721,
        "to_longitude": 105.80944408494655,
        "from_longitude": 105.80960769969069,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3735,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80944408494655,
          21.01579418143463,
          105.80959697085969,
          21.01586679140721
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80944408494655,
            21.01586679140721
          ],
          [
            105.80959697085969,
            21.01579418143463
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 36/45 Nguyên Hồng",
        "diemDau": "8-4",
        "diemCuoi": "8-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.01586679140721,
        "to_latitude": 21.01579418143463,
        "to_longitude": 105.80959697085969,
        "from_longitude": 105.80944408494655,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3736,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84091043862392,
          21.013845650396604,
          105.84098536164004,
          21.01386568099969
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84098536164004,
            21.013845650396604
          ],
          [
            105.84091043862392,
            21.01386568099969
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.013845650396604,
        "to_latitude": 21.01386568099969,
        "to_longitude": 105.84091043862392,
        "from_longitude": 105.84098536164004,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3737,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84075487049404,
          21.01386568099969,
          105.84091043862392,
          21.014068490698005
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84091043862392,
            21.01386568099969
          ],
          [
            105.84075487049404,
            21.014068490698005
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L4-1",
        "diemCuoi": "L4-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.01386568099969,
        "to_latitude": 21.014068490698005,
        "to_longitude": 105.84075487049404,
        "from_longitude": 105.84091043862392,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3738,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8405563870313,
          21.014068490698005,
          105.84075487049404,
          21.014293834484924
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84075487049404,
            21.014068490698005
          ],
          [
            105.8405563870313,
            21.014293834484924
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 3402,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L4-2",
        "diemCuoi": "L4-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.014068490698005,
        "to_latitude": 21.014293834484924,
        "to_longitude": 105.8405563870313,
        "from_longitude": 105.84075487049404,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3739,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80794204788339,
          21.016319976989227,
          105.80816265958245,
          21.016575988677836
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80794204788339,
            21.016319976989227
          ],
          [
            105.8080506773688,
            21.016357533742596
          ],
          [
            105.80816265958245,
            21.016575988677836
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 92/62 Nguyên Hồng",
        "diemDau": "22",
        "diemCuoi": "22-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.016319976989227,
        "to_latitude": 21.016575988677836,
        "to_longitude": 105.80816265958245,
        "from_longitude": 105.80794204788339,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3740,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80812108534448,
          21.016575988677836,
          105.80822233874514,
          21.016718704049545
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80816265958245,
            21.016575988677836
          ],
          [
            105.80822233874514,
            21.016673010106775
          ],
          [
            105.80812108534448,
            21.016718704049545
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 92/62 Nguyên Hồng",
        "diemDau": "22-1",
        "diemCuoi": "22-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.016575988677836,
        "to_latitude": 21.016718704049545,
        "to_longitude": 105.80812108534448,
        "from_longitude": 105.80816265958245,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3741,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80791455524954,
          21.016718704049545,
          105.80812108534448,
          21.016823862657354
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80812108534448,
            21.016718704049545
          ],
          [
            105.80791455524954,
            21.016823862657354
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 92/62 Nguyên Hồng",
        "diemDau": "22-3",
        "diemCuoi": "BX 22-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.016718704049545,
        "to_latitude": 21.016823862657354,
        "to_longitude": 105.80791455524954,
        "from_longitude": 105.80812108534448,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3742,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80764767545345,
          21.016823862657354,
          105.80791455524954,
          21.016934028737676
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80791455524954,
            21.016823862657354
          ],
          [
            105.80764767545345,
            21.016934028737676
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 92/62 Nguyên Hồng",
        "diemDau": "BX 22-4",
        "diemCuoi": "22-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.016823862657354,
        "to_latitude": 21.016934028737676,
        "to_longitude": 105.80764767545345,
        "from_longitude": 105.80791455524954,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3743,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80764767545345,
          21.016934028737676,
          105.80785688775615,
          21.017309594309463
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80764767545345,
            21.016934028737676
          ],
          [
            105.80785688775615,
            21.017309594309463
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 92/62 Nguyên Hồng",
        "diemDau": "22-5",
        "diemCuoi": "BX 22-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.016934028737676,
        "to_latitude": 21.017309594309463,
        "to_longitude": 105.80785688775615,
        "from_longitude": 105.80764767545345,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3744,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8076007368,
          21.01679757301234,
          105.80765102822659,
          21.016934028737676
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80764767545345,
            21.016934028737676
          ],
          [
            105.80765102822659,
            21.016868304663767
          ],
          [
            105.8076007368,
            21.01679757301234
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 92/62 Nguyên Hồng",
        "diemDau": "22-5",
        "diemCuoi": "22-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.016934028737676,
        "to_latitude": 21.01679757301234,
        "to_longitude": 105.8076007368,
        "from_longitude": 105.80764767545345,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3745,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80778044479956,
          21.016575988677836,
          105.80816265958245,
          21.016780046579775
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80816265958245,
            21.016575988677836
          ],
          [
            105.80808554608741,
            21.016627942095244
          ],
          [
            105.80778044479956,
            21.016780046579775
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 58,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 92/62 Nguyên Hồng",
        "diemDau": "22-1",
        "diemCuoi": "22-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BX T",
        "from_latitude": 21.016575988677836,
        "to_latitude": 21.016780046579775,
        "to_longitude": 105.80778044479956,
        "from_longitude": 105.80816265958245,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3746,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80903437751184,
          21.01569653348312,
          105.80921475605885,
          21.01579480738251
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80903437751184,
            21.01579480738251
          ],
          [
            105.80921475605885,
            21.01569653348312
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trúc Khuê",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BX T",
        "from_latitude": 21.01579480738251,
        "to_latitude": 21.01569653348312,
        "to_longitude": 105.80921475605885,
        "from_longitude": 105.80903437751184,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3747,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80902700142707,
          21.01569277779239,
          105.80903437751184,
          21.01579480738251
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80903437751184,
            21.01579480738251
          ],
          [
            105.80902700142707,
            21.01569277779239
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trúc Khuê",
        "diemDau": "14",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BX T",
        "from_latitude": 21.01579480738251,
        "to_latitude": 21.01569277779239,
        "to_longitude": 105.80902700142707,
        "from_longitude": 105.80903437751184,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3748,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80902700142707,
          21.01556132852493,
          105.80930997447858,
          21.01569277779239
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80902700142707,
            21.01569277779239
          ],
          [
            105.80930997447858,
            21.01556132852493
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trúc Khuê",
        "diemDau": "18",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BX T",
        "from_latitude": 21.01569277779239,
        "to_latitude": 21.01556132852493,
        "to_longitude": 105.80930997447858,
        "from_longitude": 105.80902700142707,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3749,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80930997447858,
          21.01546618422059,
          105.80937568863115,
          21.01556132852493
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80930997447858,
            21.01556132852493
          ],
          [
            105.80937568863115,
            21.015525023467422
          ],
          [
            105.8093582542449,
            21.01546618422059
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trúc Khuê",
        "diemDau": "17",
        "diemCuoi": "BX 16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BX T",
        "from_latitude": 21.01556132852493,
        "to_latitude": 21.01546618422059,
        "to_longitude": 105.8093582542449,
        "from_longitude": 105.80930997447858,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3750,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80875073389488,
          21.01569277779239,
          105.80902700142707,
          21.015840501593516
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80902700142707,
            21.01569277779239
          ],
          [
            105.80875073389488,
            21.015840501593516
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trúc Khuê",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BX T",
        "from_latitude": 21.01569277779239,
        "to_latitude": 21.015840501593516,
        "to_longitude": 105.80875073389488,
        "from_longitude": 105.80902700142707,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3751,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80849055962713,
          21.015840501593516,
          105.80875073389488,
          21.01597946198472
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80875073389488,
            21.015840501593516
          ],
          [
            105.80849055962713,
            21.01597946198472
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trúc Khuê",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BX T",
        "from_latitude": 21.015840501593516,
        "to_latitude": 21.01597946198472,
        "to_longitude": 105.80849055962713,
        "from_longitude": 105.80875073389488,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3752,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80823977307749,
          21.01597946198472,
          105.80849055962713,
          21.016118422245594
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80849055962713,
            21.01597946198472
          ],
          [
            105.80823977307749,
            21.016118422245594
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trúc Khuê",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BX T",
        "from_latitude": 21.01597946198472,
        "to_latitude": 21.016118422245594,
        "to_longitude": 105.80823977307749,
        "from_longitude": 105.80849055962713,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3753,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80794204788339,
          21.016118422245594,
          105.80823977307749,
          21.016319976989227
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80823977307749,
            21.016118422245594
          ],
          [
            105.80799703319599,
            21.01623860398806
          ],
          [
            105.80794204788339,
            21.016319976989227
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trúc Khuê",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BX T",
        "from_latitude": 21.016118422245594,
        "to_latitude": 21.016319976989227,
        "to_longitude": 105.80794204788339,
        "from_longitude": 105.80823977307749,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3754,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80769394355048,
          21.016319976989227,
          105.80794204788339,
          21.016468952060045
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80794204788339,
            21.016319976989227
          ],
          [
            105.80788974483241,
            21.01636629698397
          ],
          [
            105.80769394355048,
            21.016468952060045
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trúc Khuê",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BX T",
        "from_latitude": 21.016319976989227,
        "to_latitude": 21.016468952060045,
        "to_longitude": 105.80769394355048,
        "from_longitude": 105.80794204788339,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3755,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80745925025627,
          21.016468952060045,
          105.80769394355048,
          21.016587881630883
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80769394355048,
            21.016468952060045
          ],
          [
            105.80745925025627,
            21.016587881630883
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trúc Khuê",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BX T",
        "from_latitude": 21.016468952060045,
        "to_latitude": 21.016587881630883,
        "to_longitude": 105.80745925025627,
        "from_longitude": 105.80769394355048,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3756,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80729295329542,
          21.016587881630883,
          105.80745925025627,
          21.016673010107603
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80745925025627,
            21.016587881630883
          ],
          [
            105.80729295329542,
            21.016673010107603
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trúc Khuê",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BX T",
        "from_latitude": 21.016587881630883,
        "to_latitude": 21.016673010107603,
        "to_longitude": 105.80729295329542,
        "from_longitude": 105.80745925025627,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3757,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80706630663342,
          21.016673010107603,
          105.80729295329542,
          21.016791939515738
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80729295329542,
            21.016673010107603
          ],
          [
            105.80706630663342,
            21.016791939515738
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trúc Khuê",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BX T",
        "from_latitude": 21.016673010107603,
        "to_latitude": 21.016791939515738,
        "to_longitude": 105.80706630663342,
        "from_longitude": 105.80729295329542,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3758,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80681417898889,
          21.016791939515738,
          105.80706630663342,
          21.016919632038714
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80706630663342,
            21.016791939515738
          ],
          [
            105.80681417898889,
            21.016919632038714
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BX",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trúc Khuê",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BX T",
        "from_latitude": 21.016791939515738,
        "to_latitude": 21.016919632038714,
        "to_longitude": 105.80681417898889,
        "from_longitude": 105.80706630663342,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3759,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82217250777798,
          21.008626276464618,
          105.82256947471245,
          21.0089336314597
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82217250777798,
            21.008626276464618
          ],
          [
            105.82242798824956,
            21.008859766157272
          ],
          [
            105.82250979562163,
            21.0089336314597
          ],
          [
            105.82256947471245,
            21.00892674573278
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252/53 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008626276464618,
        "to_latitude": 21.00892674573278,
        "to_longitude": 105.82256947471245,
        "from_longitude": 105.82217250777798,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3760,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8226747514876,
          21.008123615045346,
          105.82267944531071,
          21.008233787558215
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82267944531071,
            21.008233787558215
          ],
          [
            105.8226747514876,
            21.008123615045346
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008233787558215,
        "to_latitude": 21.008123615045346,
        "to_longitude": 105.8226747514876,
        "from_longitude": 105.82267944531071,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3761,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8226747514876,
          21.008082300339794,
          105.82283501344061,
          21.008123615045346
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8226747514876,
            21.008123615045346
          ],
          [
            105.82268179227617,
            21.008082300339794
          ],
          [
            105.82283501344061,
            21.00808856014643
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008123615045346,
        "to_latitude": 21.00808856014643,
        "to_longitude": 105.82283501344061,
        "from_longitude": 105.8226747514876,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3762,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84098536164004,
          21.013724159514826,
          105.84102666738434,
          21.013845650396604
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84098536164004,
            21.013845650396604
          ],
          [
            105.84102666738434,
            21.013724159514826
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.013845650396604,
        "to_latitude": 21.013724159514826,
        "to_longitude": 105.84102666738434,
        "from_longitude": 105.84098536164004,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3763,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84102666738434,
          21.01348150221778,
          105.84114939652,
          21.013724159514826
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84102666738434,
            21.013724159514826
          ],
          [
            105.84114939652,
            21.01348150221778
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L3-1",
        "diemCuoi": "L3-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.013724159514826,
        "to_latitude": 21.01348150221778,
        "to_longitude": 105.84114939652,
        "from_longitude": 105.84102666738434,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3764,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84114939652,
          21.013234146211083,
          105.84115304666754,
          21.01348150221778
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84114939652,
            21.01348150221778
          ],
          [
            105.84115304666754,
            21.013234146211083
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L3-2",
        "diemCuoi": "L3-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.01348150221778,
        "to_latitude": 21.013234146211083,
        "to_longitude": 105.84115304666754,
        "from_longitude": 105.84114939652,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3765,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84114584503048,
          21.012982663284273,
          105.84115304666754,
          21.013234146211083
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84115304666754,
            21.013234146211083
          ],
          [
            105.84114584503048,
            21.012982663284273
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L3-3",
        "diemCuoi": "L3-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.013234146211083,
        "to_latitude": 21.012982663284273,
        "to_longitude": 105.84114584503048,
        "from_longitude": 105.84115304666754,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3766,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84114048548787,
          21.012752108819978,
          105.84114584503048,
          21.012982663284273
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84114584503048,
            21.012982663284273
          ],
          [
            105.84114048548787,
            21.012752108819978
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L3-4",
        "diemCuoi": "L3-5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.012982663284273,
        "to_latitude": 21.012752108819978,
        "to_longitude": 105.84114048548787,
        "from_longitude": 105.84114584503048,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3767,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84113932535197,
          21.012492151701807,
          105.84114048548787,
          21.012752108819978
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84114048548787,
            21.012752108819978
          ],
          [
            105.84113932535197,
            21.012492151701807
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L3-5",
        "diemCuoi": "L3-6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.012752108819978,
        "to_latitude": 21.012492151701807,
        "to_longitude": 105.84113932535197,
        "from_longitude": 105.84114048548787,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3768,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84112895967124,
          21.01222398058591,
          105.84113932535197,
          21.012492151701807
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84113932535197,
            21.012492151701807
          ],
          [
            105.84112895967124,
            21.01222398058591
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L3-6",
        "diemCuoi": "L3-7",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.012492151701807,
        "to_latitude": 21.01222398058591,
        "to_longitude": 105.84112895967124,
        "from_longitude": 105.84113932535197,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3769,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84112509084738,
          21.011982496106715,
          105.84112895967124,
          21.01222398058591
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84112895967124,
            21.01222398058591
          ],
          [
            105.84112509084738,
            21.011982496106715
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L3-7",
        "diemCuoi": "L3-8",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.01222398058591,
        "to_latitude": 21.011982496106715,
        "to_longitude": 105.84112509084738,
        "from_longitude": 105.84112895967124,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3770,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84112502059781,
          21.011775366164485,
          105.84112509084738,
          21.011982496106715
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84112509084738,
            21.011982496106715
          ],
          [
            105.84112502059781,
            21.011775366164485
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L3-8",
        "diemCuoi": "L3-9",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.011982496106715,
        "to_latitude": 21.011775366164485,
        "to_longitude": 105.84112502059781,
        "from_longitude": 105.84112509084738,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3771,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84112501600333,
          21.01151540508827,
          105.84112502059781,
          21.011775366164485
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84112502059781,
            21.011775366164485
          ],
          [
            105.84112501600333,
            21.01151540508827
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L3-9",
        "diemCuoi": "L3-10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.011775366164485,
        "to_latitude": 21.01151540508827,
        "to_longitude": 105.84112501600333,
        "from_longitude": 105.84112502059781,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3772,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8411115834604,
          21.01123224064662,
          105.84112501600333,
          21.01151540508827
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84112501600333,
            21.01151540508827
          ],
          [
            105.8411115834604,
            21.01123224064662
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L3-10",
        "diemCuoi": "L3-11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.01151540508827,
        "to_latitude": 21.01123224064662,
        "to_longitude": 105.8411115834604,
        "from_longitude": 105.84112501600333,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3773,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84110695945029,
          21.010913606696377,
          105.8411115834604,
          21.01123224064662
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8411115834604,
            21.01123224064662
          ],
          [
            105.84110695945029,
            21.010913606696377
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L3-11",
        "diemCuoi": "L3-12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.01123224064662,
        "to_latitude": 21.010913606696377,
        "to_longitude": 105.84110695945029,
        "from_longitude": 105.8411115834604,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3774,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84110695945029,
          21.01070206437942,
          105.8411942610685,
          21.010913606696377
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84110695945029,
            21.010913606696377
          ],
          [
            105.8411942610685,
            21.01070206437942
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L3-12",
        "diemCuoi": "L3-12-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.010913606696377,
        "to_latitude": 21.01070206437942,
        "to_longitude": 105.8411942610685,
        "from_longitude": 105.84110695945029,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3775,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84100460033763,
          21.010718159909846,
          105.84110695945029,
          21.010913606696377
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84110695945029,
            21.010913606696377
          ],
          [
            105.84100460033763,
            21.010718159909846
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L3-12",
        "diemCuoi": "L3-13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.010913606696377,
        "to_latitude": 21.010718159909846,
        "to_longitude": 105.84100460033763,
        "from_longitude": 105.84110695945029,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3776,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84075316691573,
          21.010711711920898,
          105.84100460033763,
          21.010718159909846
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84100460033763,
            21.010718159909846
          ],
          [
            105.84075316691573,
            21.010711711920898
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L3-13",
        "diemCuoi": "L3-14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.010718159909846,
        "to_latitude": 21.010711711920898,
        "to_longitude": 105.84075316691573,
        "from_longitude": 105.84100460033763,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3777,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84051512137039,
          21.010709889498205,
          105.84075316691573,
          21.010711711920898
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84075316691573,
            21.010711711920898
          ],
          [
            105.84051512137039,
            21.010709889498205
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L3-14",
        "diemCuoi": "L3-15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.010711711920898,
        "to_latitude": 21.010709889498205,
        "to_longitude": 105.84051512137039,
        "from_longitude": 105.84075316691573,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3778,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84022305681079,
          21.010709889498205,
          105.84051512137039,
          21.01093942224385
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84051512137039,
            21.010709889498205
          ],
          [
            105.84022305681079,
            21.01093942224385
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L3-15",
        "diemCuoi": "L3-16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.010709889498205,
        "to_latitude": 21.01093942224385,
        "to_longitude": 105.84022305681079,
        "from_longitude": 105.84051512137039,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3779,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84004271883099,
          21.01093942224385,
          105.84022305681079,
          21.011117240202797
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84022305681079,
            21.01093942224385
          ],
          [
            105.84004271883099,
            21.011117240202797
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L3-16",
        "diemCuoi": "L3-17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.01093942224385,
        "to_latitude": 21.011117240202797,
        "to_longitude": 105.84004271883099,
        "from_longitude": 105.84022305681079,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3780,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83984972082352,
          21.011117240202797,
          105.84004271883099,
          21.01129282726787
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84004271883099,
            21.011117240202797
          ],
          [
            105.83984972082352,
            21.01129282726787
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L3-17",
        "diemCuoi": "L4-17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.011117240202797,
        "to_latitude": 21.01129282726787,
        "to_longitude": 105.83984972082352,
        "from_longitude": 105.84004271883099,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3781,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83964023522414,
          21.01129282726787,
          105.83984972082352,
          21.01147902830112
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83984972082352,
            21.01129282726787
          ],
          [
            105.83964023522414,
            21.01147902830112
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L4-17",
        "diemCuoi": "L4-16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.01129282726787,
        "to_latitude": 21.01147902830112,
        "to_longitude": 105.83964023522414,
        "from_longitude": 105.83984972082352,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3782,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83944341194923,
          21.01147902830112,
          105.83964023522414,
          21.011673455201382
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83964023522414,
            21.01147902830112
          ],
          [
            105.83944341194923,
            21.011673455201382
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L14-16",
        "diemCuoi": "L17-15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.01147902830112,
        "to_latitude": 21.011673455201382,
        "to_longitude": 105.83944341194923,
        "from_longitude": 105.83964023522414,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3783,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83922015523612,
          21.011673455201382,
          105.83944341194923,
          21.011886394090183
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83944341194923,
            21.011673455201382
          ],
          [
            105.83922015523612,
            21.011886394090183
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L4-15",
        "diemCuoi": "L4-14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.011673455201382,
        "to_latitude": 21.011886394090183,
        "to_longitude": 105.83922015523612,
        "from_longitude": 105.83944341194923,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3784,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8391554745124,
          21.011886394090183,
          105.83922015523612,
          21.01208311512119
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83922015523612,
            21.011886394090183
          ],
          [
            105.8391554745124,
            21.01208311512119
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L4-14",
        "diemCuoi": "L4-13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.011886394090183,
        "to_latitude": 21.01208311512119,
        "to_longitude": 105.8391554745124,
        "from_longitude": 105.83922015523612,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3785,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83096747120406,
          21.011422495191173,
          105.83123837431175,
          21.01164533911268
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83096747120406,
            21.011422495191173
          ],
          [
            105.83123837431175,
            21.01164533911268
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐVN3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nam Đồng mới",
        "diemDau": "DVN3 14",
        "diemCuoi": "DVN3 15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đặng Văn Ngữ 3",
        "from_latitude": 21.011422495191173,
        "to_latitude": 21.01164533911268,
        "to_longitude": 105.83123837431175,
        "from_longitude": 105.83096747120406,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3786,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83123837431175,
          21.01164533911268,
          105.83152537067491,
          21.011880701994034
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83123837431175,
            21.01164533911268
          ],
          [
            105.83152537067491,
            21.011880701994034
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐVN3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nam Đồng mới",
        "diemDau": "DVN3 13",
        "diemCuoi": "DVN3 14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đặng Văn Ngữ 3",
        "from_latitude": 21.01164533911268,
        "to_latitude": 21.011880701994034,
        "to_longitude": 105.83152537067491,
        "from_longitude": 105.83123837431175,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3787,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83152537067491,
          21.011880701994034,
          105.83178554495163,
          21.01210604908625
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83152537067491,
            21.011880701994034
          ],
          [
            105.83178554495163,
            21.01210604908625
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐVN3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nam Đồng mới",
        "diemDau": "DVN3 12",
        "diemCuoi": "DVN3 13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đặng Văn Ngữ 3",
        "from_latitude": 21.011880701994034,
        "to_latitude": 21.01210604908625,
        "to_longitude": 105.83178554495163,
        "from_longitude": 105.83152537067491,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3788,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83178554495163,
          21.01210604908625,
          105.83206181247482,
          21.01234141124068
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83178554495163,
            21.01210604908625
          ],
          [
            105.83206181247482,
            21.01234141124068
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐVN3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nam Đồng mới",
        "diemDau": "DVN3 11",
        "diemCuoi": "DVN3 12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đặng Văn Ngữ 3",
        "from_latitude": 21.01210604908625,
        "to_latitude": 21.01234141124068,
        "to_longitude": 105.83206181247482,
        "from_longitude": 105.83178554495163,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3789,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83206181247482,
          21.01234141124068,
          105.8323166223361,
          21.012566757636876
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83206181247482,
            21.01234141124068
          ],
          [
            105.8323166223361,
            21.012566757636876
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐVN3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nam Đồng mới",
        "diemDau": "DVN3 10",
        "diemCuoi": "DVN3 11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đặng Văn Ngữ 3",
        "from_latitude": 21.01234141124068,
        "to_latitude": 21.012566757636876,
        "to_longitude": 105.8323166223361,
        "from_longitude": 105.83206181247482,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3790,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8323166223361,
          21.012566757636876,
          105.83237563093337,
          21.01278208831848
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8323166223361,
            21.012566757636876
          ],
          [
            105.83237563093337,
            21.01278208831848
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐVN3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nam Đồng mới",
        "diemDau": "DVN3 10",
        "diemCuoi": "DVN3 09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đặng Văn Ngữ 3",
        "from_latitude": 21.012566757636876,
        "to_latitude": 21.01278208831848,
        "to_longitude": 105.83237563093337,
        "from_longitude": 105.8323166223361,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3791,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8323166223361,
          21.01248404300128,
          105.8325619087176,
          21.012566757636876
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8323166223361,
            21.012566757636876
          ],
          [
            105.8325619087176,
            21.01248404300128
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐVN3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nam Đồng mới",
        "diemDau": "DVN3 02",
        "diemCuoi": "DVN3 10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đặng Văn Ngữ 3",
        "from_latitude": 21.012566757636876,
        "to_latitude": 21.01248404300128,
        "to_longitude": 105.8325619087176,
        "from_longitude": 105.8323166223361,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3792,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8325619087176,
          21.01248404300128,
          105.83286915739144,
          21.01268193455198
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8325619087176,
            21.01248404300128
          ],
          [
            105.83286915739144,
            21.01268193455198
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.01248404300128,
        "to_latitude": 21.01268193455198,
        "to_longitude": 105.83286915739144,
        "from_longitude": 105.8325619087176,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3793,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83256338556514,
          21.012598055719884,
          105.83286915739144,
          21.012814638278762
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83286915739144,
            21.01268193455198
          ],
          [
            105.83266128620161,
            21.012598055719884
          ],
          [
            105.83256338556514,
            21.012814638278762
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐVN3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nam Đồng mới",
        "diemDau": "00",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đặng Văn Ngữ 3",
        "from_latitude": 21.01268193455198,
        "to_latitude": 21.012814638278762,
        "to_longitude": 105.83256338556514,
        "from_longitude": 105.83286915739144,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3794,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83256338556514,
          21.012814638278762,
          105.83287720402366,
          21.013100076083255
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83256338556514,
            21.012814638278762
          ],
          [
            105.83287720402366,
            21.013100076083255
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐVN3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nam Đồng mới",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đặng Văn Ngữ 3",
        "from_latitude": 21.012814638278762,
        "to_latitude": 21.013100076083255,
        "to_longitude": 105.83287720402366,
        "from_longitude": 105.83256338556514,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3795,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83287720402366,
          21.013100076083255,
          105.83321248014413,
          21.013375498008887
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83287720402366,
            21.013100076083255
          ],
          [
            105.83321248014413,
            21.013375498008887
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐVN3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nam Đồng mới",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đặng Văn Ngữ 3",
        "from_latitude": 21.013100076083255,
        "to_latitude": 21.013375498008887,
        "to_longitude": 105.83321248014413,
        "from_longitude": 105.83287720402366,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3796,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83321248014413,
          21.013375498008887,
          105.83354239185809,
          21.01368346919667
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83321248014413,
            21.013375498008887
          ],
          [
            105.83354239185809,
            21.01368346919667
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐVN3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nam Đồng mới",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đặng Văn Ngữ 3",
        "from_latitude": 21.013375498008887,
        "to_latitude": 21.01368346919667,
        "to_longitude": 105.83354239185809,
        "from_longitude": 105.83321248014413,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3797,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83354239185809,
          21.01368346919667,
          105.83381597717353,
          21.013923836510656
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83354239185809,
            21.01368346919667
          ],
          [
            105.83381597717353,
            21.013923836510656
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐVN3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nam Đồng mới",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đặng Văn Ngữ 3",
        "from_latitude": 21.01368346919667,
        "to_latitude": 21.013923836510656,
        "to_longitude": 105.83381597717353,
        "from_longitude": 105.83354239185809,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3798,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83381597717353,
          21.013923836510656,
          105.83400909622083,
          21.014124142309466
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83381597717353,
            21.013923836510656
          ],
          [
            105.83400909622083,
            21.014124142309466
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐVN3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nam Đồng mới",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đặng Văn Ngữ 3",
        "from_latitude": 21.013923836510656,
        "to_latitude": 21.014124142309466,
        "to_longitude": 105.83400909622083,
        "from_longitude": 105.83381597717353,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3799,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83281685432252,
          21.013100076083255,
          105.83295767029185,
          21.01329287148527
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83287720402366,
            21.013100076083255
          ],
          [
            105.83281685432252,
            21.01315140475335
          ],
          [
            105.83295767029185,
            21.01329287148527
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐVN3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nam Đồng mới",
        "diemDau": "17",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đặng Văn Ngữ 3",
        "from_latitude": 21.013100076083255,
        "to_latitude": 21.01329287148527,
        "to_longitude": 105.83295767029185,
        "from_longitude": 105.83287720402366,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3800,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83295767029185,
          21.01329287148527,
          105.83325271327821,
          21.013548262411867
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83295767029185,
            21.01329287148527
          ],
          [
            105.83325271327821,
            21.013548262411867
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐVN3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nam Đồng mới",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đặng Văn Ngữ 3",
        "from_latitude": 21.01329287148527,
        "to_latitude": 21.013548262411867,
        "to_longitude": 105.83325271327821,
        "from_longitude": 105.83295767029185,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3801,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83325271327821,
          21.013548262411867,
          105.83355848511354,
          21.01383870646388
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83325271327821,
            21.013548262411867
          ],
          [
            105.83355848511354,
            21.01383870646388
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐVN3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nam Đồng mới",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đặng Văn Ngữ 3",
        "from_latitude": 21.013548262411867,
        "to_latitude": 21.01383870646388,
        "to_longitude": 105.83355848511354,
        "from_longitude": 105.83325271327821,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3802,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83355848511354,
          21.01383870646388,
          105.83383475263672,
          21.01408908881391
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83355848511354,
            21.01383870646388
          ],
          [
            105.83383475263672,
            21.01408908881391
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐVN3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nam Đồng mới",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đặng Văn Ngữ 3",
        "from_latitude": 21.01383870646388,
        "to_latitude": 21.01408908881391,
        "to_longitude": 105.83383475263672,
        "from_longitude": 105.83355848511354,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3803,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8326277585779,
          21.012994914850676,
          105.83287720402366,
          21.01315140475335
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83287720402366,
            21.013100076083255
          ],
          [
            105.83281685432252,
            21.01315140475335
          ],
          [
            105.8326277585779,
            21.012994914850676
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐVN3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nam Đồng mới",
        "diemDau": "17",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đặng Văn Ngữ 3",
        "from_latitude": 21.013100076083255,
        "to_latitude": 21.012994914850676,
        "to_longitude": 105.8326277585779,
        "from_longitude": 105.83287720402366,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3804,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8323073068914,
          21.012293492562158,
          105.8325619087176,
          21.01248404300128
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8325619087176,
            21.01248404300128
          ],
          [
            105.8323073068914,
            21.012293492562158
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.01248404300128,
        "to_latitude": 21.012293492562158,
        "to_longitude": 105.8323073068914,
        "from_longitude": 105.8325619087176,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3805,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83204376494098,
          21.012062804030077,
          105.8323073068914,
          21.012293492562158
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8323073068914,
            21.012293492562158
          ],
          [
            105.83204376494098,
            21.012062804030077
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012293492562158,
        "to_latitude": 21.012062804030077,
        "to_longitude": 105.83204376494098,
        "from_longitude": 105.8323073068914,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3806,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8317426296098,
          21.011750501297517,
          105.83204376494098,
          21.012062804030077
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83204376494098,
            21.012062804030077
          ],
          [
            105.8317426296098,
            21.011750501297517
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐVN3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nam Đồng mới",
        "diemDau": "DVN3 04",
        "diemCuoi": "DVN3 05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đặng Văn Ngữ 3",
        "from_latitude": 21.012062804030077,
        "to_latitude": 21.011750501297517,
        "to_longitude": 105.8317426296098,
        "from_longitude": 105.83204376494098,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3807,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83144346995935,
          21.011531929969063,
          105.8317426296098,
          21.011750501297517
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8317426296098,
            21.011750501297517
          ],
          [
            105.83144346995935,
            21.011531929969063
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐVN3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nam Đồng mới",
        "diemDau": "DVN3 05",
        "diemCuoi": "DVN3 06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đặng Văn Ngữ 3",
        "from_latitude": 21.011750501297517,
        "to_latitude": 21.011531929969063,
        "to_longitude": 105.83144346995935,
        "from_longitude": 105.8317426296098,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3808,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83120082702675,
          21.01133403940328,
          105.83144346995935,
          21.011531929969063
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83144346995935,
            21.011531929969063
          ],
          [
            105.83120082702675,
            21.01133403940328
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.011531929969063,
        "to_latitude": 21.01133403940328,
        "to_longitude": 105.83120082702675,
        "from_longitude": 105.83144346995935,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3809,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83089505137045,
          21.011068090421492,
          105.83120082702675,
          21.01133403940328
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83120082702675,
            21.01133403940328
          ],
          [
            105.83089505137045,
            21.011068090421492
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐVN3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nam Đồng mới",
        "diemDau": "DVN3 07",
        "diemCuoi": "DVN3 08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đặng Văn Ngữ 3",
        "from_latitude": 21.01133403940328,
        "to_latitude": 21.011068090421492,
        "to_longitude": 105.83089505137045,
        "from_longitude": 105.83120082702675,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3810,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83263044059699,
          21.0125328484115,
          105.83286882214918,
          21.012701652328797
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83263044059699,
            21.0125328484115
          ],
          [
            105.83286882214918,
            21.012701652328797
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐVN3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đặng Văn Ngữ",
        "diemDau": "DVN3 02",
        "diemCuoi": "DVN3 10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Đặng Văn Ngữ 3",
        "from_latitude": 21.0125328484115,
        "to_latitude": 21.012701652328797,
        "to_longitude": 105.83286882214918,
        "from_longitude": 105.83263044059699,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3811,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82767620913604,
          21.027063326844235,
          105.82769919949838,
          21.027369481475922
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82769919949838,
            21.027063326844235
          ],
          [
            105.82767620913604,
            21.027369481475922
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hào Nam mới",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.027063326844235,
        "to_latitude": 21.027369481475922,
        "to_longitude": 105.82767620913604,
        "from_longitude": 105.82769919949838,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3812,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82753156144264,
          21.027369481475922,
          105.82767620913604,
          21.027373773359894
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82767620913604,
            21.027369481475922
          ],
          [
            105.82753156144264,
            21.027373773359894
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hào Nam mới",
        "diemDau": "27",
        "diemCuoi": "29",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.027369481475922,
        "to_latitude": 21.027373773359894,
        "to_longitude": 105.82753156144264,
        "from_longitude": 105.82767620913604,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3813,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82763884980623,
          21.027369481475922,
          105.82767620913604,
          21.027709255156847
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82767620913604,
            21.027369481475922
          ],
          [
            105.82763884980623,
            21.027709255156847
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hào Nam mới",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.027369481475922,
        "to_latitude": 21.027709255156847,
        "to_longitude": 105.82763884980623,
        "from_longitude": 105.82767620913604,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3814,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82751278597945,
          21.027373773359894,
          105.82753156144264,
          21.027694233600442
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82753156144264,
            21.027373773359894
          ],
          [
            105.82751278597945,
            21.027694233600442
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hào Nam mới",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.027373773359894,
        "to_latitude": 21.027694233600442,
        "to_longitude": 105.82751278597945,
        "from_longitude": 105.82753156144264,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3815,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8274886461008,
          21.027694233600442,
          105.82751278597945,
          21.028049743372826
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82751278597945,
            21.027694233600442
          ],
          [
            105.8274886461008,
            21.028049743372826
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hào Nam mới",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.027694233600442,
        "to_latitude": 21.028049743372826,
        "to_longitude": 105.8274886461008,
        "from_longitude": 105.82751278597945,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3816,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82745914180666,
          21.028049743372826,
          105.8274886461008,
          21.028345166341378
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8274886461008,
            21.028049743372826
          ],
          [
            105.82745914180666,
            21.028345166341378
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hào Nam mới",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.028049743372826,
        "to_latitude": 21.028345166341378,
        "to_longitude": 105.82745914180666,
        "from_longitude": 105.8274886461008,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3817,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82744036634347,
          21.028345166341378,
          105.82745914180666,
          21.028588013597066
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82745914180666,
            21.028345166341378
          ],
          [
            105.82744036634347,
            21.028588013597066
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hào Nam mới",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.028345166341378,
        "to_latitude": 21.028588013597066,
        "to_longitude": 105.82744036634347,
        "from_longitude": 105.82745914180666,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3818,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82741622645582,
          21.028588013597066,
          105.82744036634347,
          21.028883435499548
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82744036634347,
            21.028588013597066
          ],
          [
            105.82741622645582,
            21.028883435499548
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hào Nam mới",
        "diemDau": "33",
        "diemCuoi": "34",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.028588013597066,
        "to_latitude": 21.028883435499548,
        "to_longitude": 105.82741622645582,
        "from_longitude": 105.82744036634347,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3819,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82739476878493,
          21.028883435499548,
          105.82741622645582,
          21.029151317564725
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82741622645582,
            21.028883435499548
          ],
          [
            105.82739476878493,
            21.029151317564725
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hào Nam mới",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.028883435499548,
        "to_latitude": 21.029151317564725,
        "to_longitude": 105.82739476878493,
        "from_longitude": 105.82741622645582,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3820,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82760666329534,
          21.027709255156847,
          105.82763884980623,
          21.028102318689115
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82763884980623,
            21.027709255156847
          ],
          [
            105.82760666329534,
            21.028102318689115
          ],
          [
            105.82763348539073,
            21.028074779240193
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hào Nam mới",
        "diemDau": "26",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.027709255156847,
        "to_latitude": 21.028074779240193,
        "to_longitude": 105.82763348539073,
        "from_longitude": 105.82763884980623,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3821,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82759057003992,
          21.028074779240193,
          105.82763348539073,
          21.02838021648539
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82763348539073,
            21.028074779240193
          ],
          [
            105.82759057003992,
            21.02838021648539
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hào Nam mới",
        "diemDau": "25",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.028074779240193,
        "to_latitude": 21.02838021648539,
        "to_longitude": 105.82759057003992,
        "from_longitude": 105.82763348539073,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3822,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8275825234167,
          21.02838021648539,
          105.82759057003992,
          21.02863307799326
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82759057003992,
            21.02838021648539
          ],
          [
            105.8275825234167,
            21.02863307799326
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hào Nam mới",
        "diemDau": "24",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.02838021648539,
        "to_latitude": 21.02863307799326,
        "to_longitude": 105.8275825234167,
        "from_longitude": 105.82759057003992,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3823,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82756106574577,
          21.02863307799326,
          105.8275825234167,
          21.028933506949993
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8275825234167,
            21.02863307799326
          ],
          [
            105.82756106574577,
            21.028933506949993
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hào Nam mới",
        "diemDau": "23",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.02863307799326,
        "to_latitude": 21.028933506949993,
        "to_longitude": 105.82756106574577,
        "from_longitude": 105.8275825234167,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3824,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82753826696198,
          21.028933506949993,
          105.82756106574577,
          21.02920639605962
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82756106574577,
            21.028933506949993
          ],
          [
            105.82753826696198,
            21.02920639605962
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hào Nam mới",
        "diemDau": "22",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.028933506949993,
        "to_latitude": 21.02920639605962,
        "to_longitude": 105.82753826696198,
        "from_longitude": 105.82756106574577,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3825,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82753826696198,
          21.02920639605962,
          105.82758118230383,
          21.02926397810041
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82753826696198,
            21.02920639605962
          ],
          [
            105.82758118230383,
            21.02926397810041
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hào Nam mới",
        "diemDau": "21",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.02920639605962,
        "to_latitude": 21.02926397810041,
        "to_longitude": 105.82758118230383,
        "from_longitude": 105.82753826696198,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3826,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82974304282185,
          21.00058356910443,
          105.82997103058773,
          21.00063114600978
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82974304282185,
            21.00063114600978
          ],
          [
            105.82997103058773,
            21.00058356910443
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4B",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "diemDau": "02",
        "diemCuoi": "2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4B",
        "from_latitude": 21.00063114600978,
        "to_latitude": 21.00058356910443,
        "to_longitude": 105.82997103058773,
        "from_longitude": 105.82974304282185,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3827,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82997103058773,
          21.00058356910443,
          105.83018292509816,
          21.00063615410434
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82997103058773,
            21.00058356910443
          ],
          [
            105.83018292509816,
            21.00063615410434
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4B",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "diemDau": "2",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4B",
        "from_latitude": 21.00058356910443,
        "to_latitude": 21.00063615410434,
        "to_longitude": 105.83018292509816,
        "from_longitude": 105.82997103058773,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3828,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83018292509816,
          21.00063615410434,
          105.83034117542681,
          21.00089407073938
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83018292509816,
            21.00063615410434
          ],
          [
            105.83034117542681,
            21.00089407073938
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4B",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "diemDau": "03",
        "diemCuoi": "3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4B",
        "from_latitude": 21.00063615410434,
        "to_latitude": 21.00089407073938,
        "to_longitude": 105.83034117542681,
        "from_longitude": 105.83018292509816,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3829,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83034117542681,
          21.00089407073938,
          105.830483332509,
          21.00111442634574
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83034117542681,
            21.00089407073938
          ],
          [
            105.830483332509,
            21.00111442634574
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4B",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "diemDau": "3",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4B",
        "from_latitude": 21.00089407073938,
        "to_latitude": 21.00111442634574,
        "to_longitude": 105.830483332509,
        "from_longitude": 105.83034117542681,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3830,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.830483332509,
          21.00111442634574,
          105.83063890062989,
          21.00136483004989
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.830483332509,
            21.00111442634574
          ],
          [
            105.83063890062989,
            21.00136483004989
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4B",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4B",
        "from_latitude": 21.00111442634574,
        "to_latitude": 21.00136483004989,
        "to_longitude": 105.83063890062989,
        "from_longitude": 105.830483332509,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3831,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8305584343617,
          21.00136483004989,
          105.83068315702172,
          21.001580176899353
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83063890062989,
            21.00136483004989
          ],
          [
            105.83068315702172,
            21.001463035136414
          ],
          [
            105.8305584343617,
            21.001580176899353
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4B",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "diemDau": "05",
        "diemCuoi": "4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4B",
        "from_latitude": 21.00136483004989,
        "to_latitude": 21.001580176899353,
        "to_longitude": 105.8305584343617,
        "from_longitude": 105.83063890062989,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3832,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8304028662408,
          21.001580176899353,
          105.8305584343617,
          21.001666565955183
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8305584343617,
            21.001580176899353
          ],
          [
            105.8304028662408,
            21.001666565955183
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4B",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "diemDau": "4",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4B",
        "from_latitude": 21.001580176899353,
        "to_latitude": 21.001666565955183,
        "to_longitude": 105.8304028662408,
        "from_longitude": 105.8305584343617,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3833,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82987581216797,
          21.001732922731666,
          105.8301091643583,
          21.00175545898992
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8301091643583,
            21.001732922731666
          ],
          [
            105.82987581216797,
            21.00175545898992
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4B",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "diemDau": "08",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4B",
        "from_latitude": 21.001732922731666,
        "to_latitude": 21.00175545898992,
        "to_longitude": 105.82987581216797,
        "from_longitude": 105.8301091643583,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3834,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82969775055575,
          21.001718384981658,
          105.82987581216797,
          21.00175545898992
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82987581216797,
            21.00175545898992
          ],
          [
            105.82969775055575,
            21.001718384981658
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4B",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "diemDau": "5",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4B",
        "from_latitude": 21.00175545898992,
        "to_latitude": 21.001718384981658,
        "to_longitude": 105.82969775055575,
        "from_longitude": 105.82987581216797,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3835,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82956199371844,
          21.00160772124175,
          105.82969775055575,
          21.001718384981658
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82969775055575,
            21.001718384981658
          ],
          [
            105.82956199371844,
            21.00160772124175
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4B",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "diemDau": "09",
        "diemCuoi": "5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4B",
        "from_latitude": 21.001718384981658,
        "to_latitude": 21.00160772124175,
        "to_longitude": 105.82956199371844,
        "from_longitude": 105.82969775055575,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3836,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82949091517732,
          21.001488779727747,
          105.82956199371844,
          21.00160772124175
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82956199371844,
            21.00160772124175
          ],
          [
            105.82949091517732,
            21.001488779727747
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4B",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "diemDau": "10",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4B",
        "from_latitude": 21.00160772124175,
        "to_latitude": 21.001488779727747,
        "to_longitude": 105.82949091517732,
        "from_longitude": 105.82956199371844,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3837,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82944665872263,
          21.001392374431195,
          105.82949091517732,
          21.001488779727747
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82949091517732,
            21.001488779727747
          ],
          [
            105.82944665872263,
            21.001392374431195
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4B",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "diemDau": "6",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4B",
        "from_latitude": 21.001488779727747,
        "to_latitude": 21.001392374431195,
        "to_longitude": 105.82944665872263,
        "from_longitude": 105.82949091517732,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3838,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82939837896532,
          21.001282196873863,
          105.82944665872263,
          21.001392374431195
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82944665872263,
            21.001392374431195
          ],
          [
            105.82939837896532,
            21.001282196873863
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4B",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "diemDau": "11",
        "diemCuoi": "6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4B",
        "from_latitude": 21.001392374431195,
        "to_latitude": 21.001282196873863,
        "to_longitude": 105.82939837896532,
        "from_longitude": 105.82944665872263,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3839,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82936082803897,
          21.001172019235188,
          105.82939837896532,
          21.001282196873863
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82939837896532,
            21.001282196873863
          ],
          [
            105.82936082803897,
            21.001172019235188
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4B",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "diemDau": "12",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4B",
        "from_latitude": 21.001282196873863,
        "to_latitude": 21.001172019235188,
        "to_longitude": 105.82936082803897,
        "from_longitude": 105.82939837896532,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3840,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82932059489588,
          21.001061841514357,
          105.82936082803897,
          21.001172019235188
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82936082803897,
            21.001172019235188
          ],
          [
            105.82932059489588,
            21.001061841514357
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4B",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "diemDau": "7",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4B",
        "from_latitude": 21.001172019235188,
        "to_latitude": 21.001061841514357,
        "to_longitude": 105.82932059489588,
        "from_longitude": 105.82936082803897,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3841,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82929377278253,
          21.000935935201518,
          105.82932059489588,
          21.001061841514357
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82932059489588,
            21.001061841514357
          ],
          [
            105.82929377278253,
            21.000935935201518
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4B",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "diemDau": "13",
        "diemCuoi": "7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4B",
        "from_latitude": 21.001061841514357,
        "to_latitude": 21.000935935201518,
        "to_longitude": 105.82929377278253,
        "from_longitude": 105.82932059489588,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3842,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82928840833112,
          21.00077012056677,
          105.82935680471837,
          21.000935935201518
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82929377278253,
            21.000935935201518
          ],
          [
            105.82928840833112,
            21.000827009325263
          ],
          [
            105.82935680471837,
            21.00077012056677
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4B",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "diemDau": "1",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4B",
        "from_latitude": 21.000935935201518,
        "to_latitude": 21.00077012056677,
        "to_longitude": 105.82935680471837,
        "from_longitude": 105.82929377278253,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3843,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82935680471837,
          21.000713075281933,
          105.82950298506725,
          21.00077012056677
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82935680471837,
            21.00077012056677
          ],
          [
            105.82950298506725,
            21.000713075281933
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4B",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "diemDau": "01",
        "diemCuoi": "1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4B",
        "from_latitude": 21.00077012056677,
        "to_latitude": 21.000713075281933,
        "to_longitude": 105.82950298506725,
        "from_longitude": 105.82935680471837,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3844,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82950298506725,
          21.00063114600978,
          105.82974304282185,
          21.000713075281933
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82950298506725,
            21.000713075281933
          ],
          [
            105.82974304282185,
            21.00063114600978
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4B",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "diemDau": "1",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4B",
        "from_latitude": 21.000713075281933,
        "to_latitude": 21.00063114600978,
        "to_longitude": 105.82974304282185,
        "from_longitude": 105.82950298506725,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3845,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82923409361086,
          21.000932179138395,
          105.8293024899532,
          21.001185087185068
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82923409361086,
            21.000932179138395
          ],
          [
            105.8293024899532,
            21.001185087185068
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Thất Tùng",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KT4",
        "from_latitude": 21.000932179138395,
        "to_latitude": 21.001185087185068,
        "to_longitude": 105.8293024899532,
        "from_longitude": 105.82923409361086,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3846,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8293024899532,
          21.001185087185068,
          105.82940441389233,
          21.001430482702933
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8293024899532,
            21.001185087185068
          ],
          [
            105.82940441389233,
            21.001430482702933
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Thất Tùng",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KT4",
        "from_latitude": 21.001185087185068,
        "to_latitude": 21.001430482702933,
        "to_longitude": 105.82940441389233,
        "from_longitude": 105.8293024899532,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3847,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82940441389233,
          21.001430482702933,
          105.82951149606113,
          21.00164877427125
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82940441389233,
            21.001430482702933
          ],
          [
            105.82951149606113,
            21.00164877427125
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Thất Tùng",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KT4",
        "from_latitude": 21.001430482702933,
        "to_latitude": 21.00164877427125,
        "to_longitude": 105.82951149606113,
        "from_longitude": 105.82940441389233,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3848,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82951149606113,
          21.00164877427125,
          105.8296201627161,
          21.001883523466002
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82951149606113,
            21.00164877427125
          ],
          [
            105.8296201627161,
            21.001883523466002
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Thất Tùng",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KT4",
        "from_latitude": 21.00164877427125,
        "to_latitude": 21.001883523466002,
        "to_longitude": 105.8296201627161,
        "from_longitude": 105.82951149606113,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3849,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8296201627161,
          21.001883523466002,
          105.82971372622863,
          21.002122095032796
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8296201627161,
            21.001883523466002
          ],
          [
            105.82971372622863,
            21.002122095032796
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Thất Tùng",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KT4",
        "from_latitude": 21.001883523466002,
        "to_latitude": 21.002122095032796,
        "to_longitude": 105.82971372622863,
        "from_longitude": 105.8296201627161,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3850,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82971372622863,
          21.002122095032796,
          105.82980511104162,
          21.002355703509235
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82971372622863,
            21.002122095032796
          ],
          [
            105.82980511104162,
            21.002355703509235
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Thất Tùng",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KT4",
        "from_latitude": 21.002122095032796,
        "to_latitude": 21.002355703509235,
        "to_longitude": 105.82980511104162,
        "from_longitude": 105.82971372622863,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3851,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82980511104162,
          21.002355703509235,
          105.82989585084579,
          21.00259850830412
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82980511104162,
            21.002355703509235
          ],
          [
            105.82989585084579,
            21.00259850830412
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Thất Tùng",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KT4",
        "from_latitude": 21.002355703509235,
        "to_latitude": 21.00259850830412,
        "to_longitude": 105.82989585084579,
        "from_longitude": 105.82980511104162,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3852,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82989585084579,
          21.00259850830412,
          105.82998645332268,
          21.002833987064903
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82989585084579,
            21.00259850830412
          ],
          [
            105.82998645332268,
            21.002833987064903
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Thất Tùng",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KT4",
        "from_latitude": 21.00259850830412,
        "to_latitude": 21.002833987064903,
        "to_longitude": 105.82998645332268,
        "from_longitude": 105.82989585084579,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3853,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82998645332268,
          21.002833987064903,
          105.83006423731125,
          21.003063103834084
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82998645332268,
            21.002833987064903
          ],
          [
            105.83006423731125,
            21.003063103834084
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Thất Tùng",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KT4",
        "from_latitude": 21.002833987064903,
        "to_latitude": 21.003063103834084,
        "to_longitude": 105.83006423731125,
        "from_longitude": 105.82998645332268,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3854,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82582299427554,
          21.004644375528073,
          105.8259058075092,
          21.004769887464555
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82582299427554,
            21.004644375528073
          ],
          [
            105.8259058075092,
            21.004769887464555
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "15",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004644375528073,
        "to_latitude": 21.004769887464555,
        "to_longitude": 105.8259058075092,
        "from_longitude": 105.82582299427554,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3855,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8259058075092,
          21.004769887464555,
          105.82601711918439,
          21.004980221451987
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8259058075092,
            21.004769887464555
          ],
          [
            105.82601711918439,
            21.004980221451987
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004769887464555,
        "to_latitude": 21.004980221451987,
        "to_longitude": 105.82601711918439,
        "from_longitude": 105.8259058075092,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3856,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82601711918439,
          21.004980221451987,
          105.82604125906306,
          21.005046576755955
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82601711918439,
            21.004980221451987
          ],
          [
            105.82604125906306,
            21.005046576755955
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 111 Khương Thượng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004980221451987,
        "to_latitude": 21.005046576755955,
        "to_longitude": 105.82604125906306,
        "from_longitude": 105.82601711918439,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3857,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82639128734408,
          21.004703532038416,
          105.82672388126576,
          21.004866290579315
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82639128734408,
            21.004866290579315
          ],
          [
            105.82672388126576,
            21.004703532038416
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 111 Khương Thượng",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004866290579315,
        "to_latitude": 21.004703532038416,
        "to_longitude": 105.82672388126576,
        "from_longitude": 105.82639128734408,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3858,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82604125906306,
          21.004866290579315,
          105.82639128734408,
          21.00504657675594
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82604125906306,
            21.00504657675594
          ],
          [
            105.82639128734408,
            21.004866290579315
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 111 Khương Thượng",
        "diemDau": "",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.00504657675594,
        "to_latitude": 21.004866290579315,
        "to_longitude": 105.82639128734408,
        "from_longitude": 105.82604125906306,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3859,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82662195731768,
          21.0044844337213,
          105.82672388126576,
          21.004703532038416
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82672388126576,
            21.004703532038416
          ],
          [
            105.82662195731768,
            21.0044844337213
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 111 Khương Thượng",
        "diemDau": "4-2",
        "diemCuoi": "4-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004703532038416,
        "to_latitude": 21.0044844337213,
        "to_longitude": 105.82662195731768,
        "from_longitude": 105.82672388126576,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3860,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82662195731768,
          21.00438302239204,
          105.82679093648628,
          21.0044844337213
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82662195731768,
            21.0044844337213
          ],
          [
            105.82679093648628,
            21.00438302239204
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 111 Khương Thượng",
        "diemDau": "4-3",
        "diemCuoi": "4-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.0044844337213,
        "to_latitude": 21.00438302239204,
        "to_longitude": 105.82679093648628,
        "from_longitude": 105.82662195731768,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3861,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82641676829965,
          21.004396168314504,
          105.82653344442626,
          21.004493197660146
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82641676829965,
            21.004396168314504
          ],
          [
            105.82653344442626,
            21.004493197660146
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 123 Khương Thượng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004396168314504,
        "to_latitude": 21.004493197660146,
        "to_longitude": 105.82653344442626,
        "from_longitude": 105.82641676829965,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3862,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80957283101695,
          21.01748423197769,
          105.8097713144887,
          21.017578123112916
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8097713144887,
            21.01748423197769
          ],
          [
            105.80957283101695,
            21.017578123112916
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Nguyễn Chí Thanh",
        "diemDau": "18",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.01748423197769,
        "to_latitude": 21.017578123112916,
        "to_longitude": 105.80957283101695,
        "from_longitude": 105.8097713144887,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3863,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80939848743289,
          21.017578123112916,
          105.80957283101695,
          21.017664502905276
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80957283101695,
            21.017578123112916
          ],
          [
            105.80939848743289,
            21.017664502905276
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Nguyễn Chí Thanh",
        "diemDau": "17",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.017578123112916,
        "to_latitude": 21.017664502905276,
        "to_longitude": 105.80939848743289,
        "from_longitude": 105.80957283101695,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3864,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80913428984455,
          21.017664502905276,
          105.80939848743289,
          21.017790942801224
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80939848743289,
            21.017664502905276
          ],
          [
            105.80913428984455,
            21.017790942801224
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x2,5",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Nguyễn Chí Thanh",
        "diemDau": "16",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.017664502905276,
        "to_latitude": 21.017790942801224,
        "to_longitude": 105.80913428984455,
        "from_longitude": 105.80939848743289,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3865,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8097713144887,
          21.017387211078187,
          105.81000634300722,
          21.01748423197769
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81000634300722,
            21.01742257676868
          ],
          [
            105.8099677862552,
            21.017387211078187
          ],
          [
            105.8097713144887,
            21.01748423197769
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Nguyễn Chí Thanh",
        "diemDau": "18",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.01742257676868,
        "to_latitude": 21.01748423197769,
        "to_longitude": 105.8097713144887,
        "from_longitude": 105.81000634300722,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3866,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82597286273868,
          21.004980221451987,
          105.82601711918439,
          21.00506786052627
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82601711918439,
            21.004980221451987
          ],
          [
            105.82597286273868,
            21.00506786052627
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004980221451987,
        "to_latitude": 21.00506786052627,
        "to_longitude": 105.82597286273868,
        "from_longitude": 105.82601711918439,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3867,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82542032768332,
          21.005263170276873,
          105.82554370929336,
          21.005265674246864
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82542032768332,
            21.005265674246864
          ],
          [
            105.82554370929336,
            21.005263170276873
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "",
        "diemCuoi": "8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.005265674246864,
        "to_latitude": 21.005263170276873,
        "to_longitude": 105.82554370929336,
        "from_longitude": 105.82542032768332,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3868,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82572073509415,
          21.00506786052627,
          105.82597286273868,
          21.005180539260188
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82597286273868,
            21.00506786052627
          ],
          [
            105.82572073509415,
            21.005180539260188
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.00506786052627,
        "to_latitude": 21.005180539260188,
        "to_longitude": 105.82572073509415,
        "from_longitude": 105.82597286273868,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3869,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82554370929336,
          21.005180539260188,
          105.82572073509415,
          21.00526317027689
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82554370929336,
            21.00526317027689
          ],
          [
            105.82572073509415,
            21.005180539260188
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "06",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.00526317027689,
        "to_latitude": 21.005180539260188,
        "to_longitude": 105.82572073509415,
        "from_longitude": 105.82554370929336,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3870,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80976125616026,
          21.017774668365416,
          105.8097713144887,
          21.017810346936667
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8097713144887,
            21.017774668365416
          ],
          [
            105.80976125616026,
            21.017810346936667
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "diemDau": "Tủ điên BW Thành Công",
        "diemCuoi": "22-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.017774668365416,
        "to_latitude": 21.017810346936667,
        "to_longitude": 105.80976125616026,
        "from_longitude": 105.8097713144887,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3871,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8096217812858,
          21.017774668365416,
          105.8097713144887,
          21.017810346936667
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8097713144887,
            21.017774668365416
          ],
          [
            105.8096217812858,
            21.017810346936667
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Hunhf THúc Kháng",
        "diemDau": "Tủ điên BW Thành Công",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.017774668365416,
        "to_latitude": 21.017810346936667,
        "to_longitude": 105.8096217812858,
        "from_longitude": 105.8097713144887,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3872,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8097713144887,
          21.017692670220576,
          105.80983367580522,
          21.017774668365416
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8097713144887,
            21.017774668365416
          ],
          [
            105.80983367580522,
            21.017692670220576
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Hunhf THúc Kháng",
        "diemDau": "Tủ điên BW Thành Công",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.017774668365416,
        "to_latitude": 21.017692670220576,
        "to_longitude": 105.80983367580522,
        "from_longitude": 105.8097713144887,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3873,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80983367580522,
          21.017602534801057,
          105.81001606601254,
          21.017692670220576
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80983367580522,
            21.017692670220576
          ],
          [
            105.81001606601254,
            21.017602534801057
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Hunhf THúc Kháng",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.017692670220576,
        "to_latitude": 21.017602534801057,
        "to_longitude": 105.81001606601254,
        "from_longitude": 105.80983367580522,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3874,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81001606601254,
          21.0176015958902,
          105.81010424363471,
          21.01762506866043
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81001606601254,
            21.017602534801057
          ],
          [
            105.81004288810792,
            21.01762506866043
          ],
          [
            105.81010424363471,
            21.0176015958902
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Hunhf THúc Kháng",
        "diemDau": "24",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.017602534801057,
        "to_latitude": 21.0176015958902,
        "to_longitude": 105.81010424363471,
        "from_longitude": 105.81001606601254,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3875,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81000634300722,
          21.01742257676868,
          105.81010424363471,
          21.0176015958902
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81010424363471,
            21.0176015958902
          ],
          [
            105.81000634300722,
            21.01742257676868
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Hunhf THúc Kháng",
        "diemDau": "20",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.0176015958902,
        "to_latitude": 21.01742257676868,
        "to_longitude": 105.81000634300722,
        "from_longitude": 105.81010424363471,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3876,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81010424363471,
          21.0176015958902,
          105.81026115285948,
          21.017903298956732
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81010424363471,
            21.0176015958902
          ],
          [
            105.81026115285948,
            21.017903298956732
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Hunhf THúc Kháng",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.0176015958902,
        "to_latitude": 21.017903298956732,
        "to_longitude": 105.81026115285948,
        "from_longitude": 105.81010424363471,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3877,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8104341553397,
          21.01809483627231,
          105.81055217253423,
          21.018308907097904
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8104341553397,
            21.01809483627231
          ],
          [
            105.81055217253423,
            21.018308907097904
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Hunhf THúc Kháng",
        "diemDau": "5-1",
        "diemCuoi": "5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.01809483627231,
        "to_latitude": 21.018308907097904,
        "to_longitude": 105.81055217253423,
        "from_longitude": 105.8104341553397,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3878,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8097713144887,
          21.017774668365416,
          105.80994924163173,
          21.01789523878195
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8097713144887,
            21.017774668365416
          ],
          [
            105.80989000220374,
            21.01778656122456
          ],
          [
            105.80994924163173,
            21.01789523878195
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 26 Nguyên Hồng",
        "diemDau": "Tủ điên BW Thành Công",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.017774668365416,
        "to_latitude": 21.01789523878195,
        "to_longitude": 105.80994924163173,
        "from_longitude": 105.8097713144887,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3879,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80994924163173,
          21.01789523878195,
          105.81004389395153,
          21.01807605811473
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80994924163173,
            21.01789523878195
          ],
          [
            105.81004389395153,
            21.01807605811473
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 26 Nguyên Hồng",
        "diemDau": "7-1",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.01789523878195,
        "to_latitude": 21.01807605811473,
        "to_longitude": 105.81004389395153,
        "from_longitude": 105.80994924163173,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3880,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80981490034206,
          21.01807605811473,
          105.81004389395153,
          21.01819091780593
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81004389395153,
            21.01807605811473
          ],
          [
            105.80981490034206,
            21.01819091780593
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 26 Nguyên Hồng",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.01807605811473,
        "to_latitude": 21.01819091780593,
        "to_longitude": 105.80981490034206,
        "from_longitude": 105.81004389395153,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3881,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80953259790085,
          21.01819091780593,
          105.80981490034206,
          21.018300456936423
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80981490034206,
            21.01819091780593
          ],
          [
            105.80961373467156,
            21.01828230474556
          ],
          [
            105.80953259790085,
            21.018300456936423
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 55 Huỳnh Thúc Kháng",
        "diemDau": "08",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.01819091780593,
        "to_latitude": 21.018300456936423,
        "to_longitude": 105.80953259790085,
        "from_longitude": 105.80981490034206,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3882,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81004389395153,
          21.017980602445828,
          105.8102547826363,
          21.01807605811473
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81004389395153,
            21.01807605811473
          ],
          [
            105.8102547826363,
            21.017980602445828
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 26 Nguyên Hồng",
        "diemDau": "07",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.01807605811473,
        "to_latitude": 21.017980602445828,
        "to_longitude": 105.8102547826363,
        "from_longitude": 105.81004389395153,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3883,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8102547826363,
          21.01791174913734,
          105.81040498633276,
          21.017980602445828
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8102547826363,
            21.017980602445828
          ],
          [
            105.81040498633276,
            21.01791174913734
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 26 Nguyên Hồng",
        "diemDau": "06",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.017980602445828,
        "to_latitude": 21.01791174913734,
        "to_longitude": 105.81040498633276,
        "from_longitude": 105.8102547826363,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3884,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81040498633276,
          21.017787813103645,
          105.81066516060949,
          21.01791174913734
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81040498633276,
            21.01791174913734
          ],
          [
            105.81066516060949,
            21.017787813103645
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 26 Nguyên Hồng",
        "diemDau": "05",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.01791174913734,
        "to_latitude": 21.017787813103645,
        "to_longitude": 105.81066516060949,
        "from_longitude": 105.81040498633276,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3885,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81066516060949,
          21.01770518902365,
          105.81083682198583,
          21.017787813103645
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81066516060949,
            21.017787813103645
          ],
          [
            105.81083682198583,
            21.01770518902365
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 26 Nguyên Hồng",
        "diemDau": "04",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.017787813103645,
        "to_latitude": 21.01770518902365,
        "to_longitude": 105.81083682198583,
        "from_longitude": 105.81066516060949,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3886,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81083682198583,
          21.01764635063615,
          105.81096422690747,
          21.01770518902365
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81083682198583,
            21.01770518902365
          ],
          [
            105.81096422690747,
            21.01764635063615
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 26 Nguyên Hồng",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.01770518902365,
        "to_latitude": 21.01764635063615,
        "to_longitude": 105.81096422690747,
        "from_longitude": 105.81083682198583,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3887,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81096422690747,
          21.017517406854132,
          105.81112381834897,
          21.01764635063615
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81096422690747,
            21.01764635063615
          ],
          [
            105.81112381834897,
            21.017573741529976
          ],
          [
            105.81109565514971,
            21.017517406854132
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 26 Nguyên Hồng",
        "diemDau": "02",
        "diemCuoi": "BW 01",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.01764635063615,
        "to_latitude": 21.017517406854132,
        "to_longitude": 105.81109565514971,
        "from_longitude": 105.81096422690747,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3888,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81098568458287,
          21.017317731554222,
          105.81109565514971,
          21.017517406854132
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81109565514971,
            21.017517406854132
          ],
          [
            105.81098568458287,
            21.017317731554222
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 26 Nguyên Hồng",
        "diemDau": "BW 01",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.017517406854132,
        "to_latitude": 21.017317731554222,
        "to_longitude": 105.81098568458287,
        "from_longitude": 105.81109565514971,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3889,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81087571401605,
          21.01711805598698,
          105.81098568458287,
          21.017317731554222
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81098568458287,
            21.017317731554222
          ],
          [
            105.81087571401605,
            21.01711805598698
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 26 Nguyên Hồng",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.017317731554222,
        "to_latitude": 21.01711805598698,
        "to_longitude": 105.81087571401605,
        "from_longitude": 105.81098568458287,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3890,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82539082338019,
          21.00519055514295,
          105.82542032768332,
          21.00526567424688
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82542032768332,
            21.00526567424688
          ],
          [
            105.82539082338019,
            21.00519055514295
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Khương Thượng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.00526567424688,
        "to_latitude": 21.00519055514295,
        "to_longitude": 105.82539082338019,
        "from_longitude": 105.82542032768332,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3891,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82532645036743,
          21.005065356552958,
          105.82539082338019,
          21.00519055514295
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82539082338019,
            21.00519055514295
          ],
          [
            105.82532645036743,
            21.005065356552958
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Khương Thượng",
        "diemDau": "",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.00519055514295,
        "to_latitude": 21.005065356552958,
        "to_longitude": 105.82532645036743,
        "from_longitude": 105.82539082338019,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3892,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82532645036743,
          21.004962693630954,
          105.82554907371781,
          21.005065356552958
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82532645036743,
            21.005065356552958
          ],
          [
            105.82554907371781,
            21.004962693630954
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Khương Thượng",
        "diemDau": "6-2",
        "diemCuoi": "6-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.005065356552958,
        "to_latitude": 21.004962693630954,
        "to_longitude": 105.82554907371781,
        "from_longitude": 105.82532645036743,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3893,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82539082338019,
          21.00514798763425,
          105.82547665407284,
          21.00519055514295
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82539082338019,
            21.00519055514295
          ],
          [
            105.82547665407284,
            21.00514798763425
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Khương Thượng",
        "diemDau": "",
        "diemCuoi": "6-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.00519055514295,
        "to_latitude": 21.00514798763425,
        "to_longitude": 105.82547665407284,
        "from_longitude": 105.82539082338019,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3894,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82547665407284,
          21.005035308875765,
          105.8256831841678,
          21.00514798763425
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82547665407284,
            21.00514798763425
          ],
          [
            105.8256831841678,
            21.005035308875765
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Khương Thượng",
        "diemDau": "6-3",
        "diemCuoi": "6-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.00514798763425,
        "to_latitude": 21.005035308875765,
        "to_longitude": 105.8256831841678,
        "from_longitude": 105.82547665407284,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3895,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84077348856678,
          21.01380021299348,
          105.84098536164004,
          21.013845650396604
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84098536164004,
            21.013845650396604
          ],
          [
            105.84077348856678,
            21.01380021299348
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba MẫU",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.013845650396604,
        "to_latitude": 21.01380021299348,
        "to_longitude": 105.84077348856678,
        "from_longitude": 105.84098536164004,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3896,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8406585908931,
          21.01380021299348,
          105.84077348856678,
          21.013994836907553
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84077348856678,
            21.01380021299348
          ],
          [
            105.8406585908931,
            21.013994836907553
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba MẫU",
        "diemDau": "L1-1",
        "diemCuoi": "L1-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.01380021299348,
        "to_latitude": 21.013994836907553,
        "to_longitude": 105.8406585908931,
        "from_longitude": 105.84077348856678,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3897,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84053142749823,
          21.013994836907553,
          105.8406585908931,
          21.01416763417341
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8406585908931,
            21.013994836907553
          ],
          [
            105.84053142749823,
            21.01416763417341
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba MẫU",
        "diemDau": "L1-2",
        "diemCuoi": "L1-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.013994836907553,
        "to_latitude": 21.01416763417341,
        "to_longitude": 105.84053142749823,
        "from_longitude": 105.8406585908931,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3898,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84034298397385,
          21.01416763417341,
          105.84053142749823,
          21.014281675673256
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84053142749823,
            21.01416763417341
          ],
          [
            105.84034298397385,
            21.014281675673256
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba MẫU",
        "diemDau": "L1-3",
        "diemCuoi": "L1-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.01416763417341,
        "to_latitude": 21.014281675673256,
        "to_longitude": 105.84034298397385,
        "from_longitude": 105.84053142749823,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3899,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81035670659338,
          21.01791174913734,
          105.8104341553397,
          21.01809483627231
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8104341553397,
            21.01809483627231
          ],
          [
            105.81035670659338,
            21.01795055736837
          ],
          [
            105.81040498633276,
            21.01791174913734
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Hunhf THúc Kháng",
        "diemDau": "05",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.01809483627231,
        "to_latitude": 21.01791174913734,
        "to_longitude": 105.81040498633276,
        "from_longitude": 105.8104341553397,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3900,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81010424363471,
          21.01729832735637,
          105.81068527716755,
          21.0176015958902
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81010424363471,
            21.0176015958902
          ],
          [
            105.8101260366108,
            21.01754620013441
          ],
          [
            105.81066247841972,
            21.01729832735637
          ],
          [
            105.81068527716755,
            21.017332128215603
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 79,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Hunhf THúc Kháng",
        "diemDau": "20",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.0176015958902,
        "to_latitude": 21.017332128215603,
        "to_longitude": 105.81068527716755,
        "from_longitude": 105.81010424363471,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3901,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81041145993619,
          21.017414315102535,
          105.8104787470726,
          21.017533681318852
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8104787470726,
            21.017533681318852
          ],
          [
            105.81041145993619,
            21.017414315102535
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 Hunhf THúc Kháng",
        "diemDau": "20",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.017533681318852,
        "to_latitude": 21.017414315102535,
        "to_longitude": 105.81041145993619,
        "from_longitude": 105.8104787470726,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3902,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8104787470726,
          21.017439790155674,
          105.81057932993478,
          21.017533681318852
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8104787470726,
            21.017533681318852
          ],
          [
            105.81057932993478,
            21.017489865448148
          ],
          [
            105.81055653113305,
            21.017439790155674
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 49 HUỳnh Thúc Kháng",
        "diemDau": "26",
        "diemCuoi": "26-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.017533681318852,
        "to_latitude": 21.017439790155674,
        "to_longitude": 105.81055653113305,
        "from_longitude": 105.8104787470726,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3903,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82527548840237,
          21.005263170276898,
          105.82554370929336,
          21.005343297280795
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82554370929336,
            21.005263170276898
          ],
          [
            105.82527548840237,
            21.005343297280795
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.005263170276898,
        "to_latitude": 21.005343297280795,
        "to_longitude": 105.82527548840237,
        "from_longitude": 105.82554370929336,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3904,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8251547889911,
          21.00510166415523,
          105.82527548840237,
          21.005343297280795
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82527548840237,
            21.005343297280795
          ],
          [
            105.8251547889911,
            21.00510166415523
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 44 Khương Thuợng",
        "diemDau": "07",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.005343297280795,
        "to_latitude": 21.00510166415523,
        "to_longitude": 105.8251547889911,
        "from_longitude": 105.82527548840237,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3905,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8250340895888,
          21.00487004654346,
          105.8251547889911,
          21.00510166415523
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8251547889911,
            21.00510166415523
          ],
          [
            105.8250340895888,
            21.00487004654346
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 44 Khương Thuợng",
        "diemDau": "7-1",
        "diemCuoi": "7-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.00510166415523,
        "to_latitude": 21.00487004654346,
        "to_longitude": 105.8250340895888,
        "from_longitude": 105.8251547889911,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3906,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8248919325066,
          21.004605876828858,
          105.8250340895888,
          21.00487004654346
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8250340895888,
            21.00487004654346
          ],
          [
            105.8248919325066,
            21.004605876828858
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 44 Khương Thuợng",
        "diemDau": "7-2",
        "diemCuoi": "7-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.00487004654346,
        "to_latitude": 21.004605876828858,
        "to_longitude": 105.8248919325066,
        "from_longitude": 105.8250340895888,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3907,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82507566382678,
          21.005343297280795,
          105.82527548840237,
          21.005434692091768
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82527548840237,
            21.005343297280795
          ],
          [
            105.82507566382678,
            21.005434692091768
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.005343297280795,
        "to_latitude": 21.005434692091768,
        "to_longitude": 105.82507566382678,
        "from_longitude": 105.82527548840237,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3908,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82481683065392,
          21.005434692091768,
          105.82507566382678,
          21.005591189924875
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82507566382678,
            21.005434692091768
          ],
          [
            105.82481683065392,
            21.005591189924875
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.005434692091768,
        "to_latitude": 21.005591189924875,
        "to_longitude": 105.82481683065392,
        "from_longitude": 105.82507566382678,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3909,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80953259790085,
          21.018300456936423,
          105.80986787402131,
          21.01892138581497
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80953259790085,
            21.018300456936423
          ],
          [
            105.80986787402131,
            21.01892138581497
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 52,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 55 Huỳnh Thúc Kháng",
        "diemDau": "12",
        "diemCuoi": "BW 11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.018300456936423,
        "to_latitude": 21.01892138581497,
        "to_longitude": 105.80986787402131,
        "from_longitude": 105.80953259790085,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3910,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80939982855473,
          21.018050081657602,
          105.80953259790085,
          21.018300456936423
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80953259790085,
            21.018300456936423
          ],
          [
            105.80939982855473,
            21.018050081657602
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 55 Huỳnh Thúc Kháng",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.018300456936423,
        "to_latitude": 21.018050081657602,
        "to_longitude": 105.80939982855473,
        "from_longitude": 105.80953259790085,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3911,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8092549892648,
          21.017794698438276,
          105.80939982855473,
          21.018050081657602
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80939982855473,
            21.018050081657602
          ],
          [
            105.8092549892648,
            21.017794698438276
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 55 Huỳnh Thúc Kháng",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.018050081657602,
        "to_latitude": 21.017794698438276,
        "to_longitude": 105.8092549892648,
        "from_longitude": 105.80939982855473,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3912,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80964558280678,
          21.018498253109996,
          105.80976595009118,
          21.018509704429757
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80964558280678,
            21.018509704429757
          ],
          [
            105.80976595009118,
            21.018498253109996
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 55 Huỳnh Thúc Kháng",
        "diemDau": "12",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.018509704429757,
        "to_latitude": 21.018498253109996,
        "to_longitude": 105.80976595009118,
        "from_longitude": 105.80964558280678,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3913,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80976595009118,
          21.018375193869993,
          105.81003146801794,
          21.018498253109996
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80976595009118,
            21.018498253109996
          ],
          [
            105.81003146801794,
            21.018375193869993
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 55 Huỳnh Thúc Kháng",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.018498253109996,
        "to_latitude": 21.018375193869993,
        "to_longitude": 105.81003146801794,
        "from_longitude": 105.80976595009118,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3914,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81003146801794,
          21.018375193869993,
          105.81009071783761,
          21.018438222908856
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81003146801794,
            21.018375193869993
          ],
          [
            105.81009071783761,
            21.018438222908856
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 55 Huỳnh Thúc Kháng",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.018375193869993,
        "to_latitude": 21.018438222908856,
        "to_longitude": 105.81009071783761,
        "from_longitude": 105.81003146801794,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3915,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82452983429076,
          21.005591189924875,
          105.82481683065392,
          21.00572389995903
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82481683065392,
            21.005591189924875
          ],
          [
            105.82452983429076,
            21.00572389995903
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.005591189924875,
        "to_latitude": 21.00572389995903,
        "to_longitude": 105.82452983429076,
        "from_longitude": 105.82481683065392,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3916,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82423613240826,
          21.00572389995903,
          105.82452983429076,
          21.0058578618552
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82452983429076,
            21.00572389995903
          ],
          [
            105.82423613240826,
            21.0058578618552
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.00572389995903,
        "to_latitude": 21.0058578618552,
        "to_longitude": 105.82423613240826,
        "from_longitude": 105.82452983429076,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3917,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81096422690747,
          21.01764635063615,
          105.81109230241249,
          21.017872314955365
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81096422690747,
            21.01764635063615
          ],
          [
            105.81109230241249,
            21.017872314955365
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 18 Nguyên Hồng",
        "diemDau": "02",
        "diemCuoi": "2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.01764635063615,
        "to_latitude": 21.017872314955365,
        "to_longitude": 105.81109230241249,
        "from_longitude": 105.81096422690747,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3918,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81109230241249,
          21.017872314955365,
          105.81117947420003,
          21.01803505912873
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81109230241249,
            21.017872314955365
          ],
          [
            105.81117947420003,
            21.01803505912873
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 18 Nguyên Hồng",
        "diemDau": "2-1",
        "diemCuoi": "2-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.017872314955365,
        "to_latitude": 21.01803505912873,
        "to_longitude": 105.81117947420003,
        "from_longitude": 105.81109230241249,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3919,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81109230241249,
          21.017804713478306,
          105.81123043615612,
          21.017872314955365
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81109230241249,
            21.017872314955365
          ],
          [
            105.81123043615612,
            21.017804713478306
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 18 Nguyên Hồng",
        "diemDau": "2-1",
        "diemCuoi": "2-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.017872314955365,
        "to_latitude": 21.017804713478306,
        "to_longitude": 105.81123043615612,
        "from_longitude": 105.81109230241249,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3920,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81093807536851,
          21.017855414589178,
          105.81109230241249,
          21.017894848775615
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81109230241249,
            21.017872314955365
          ],
          [
            105.81102189442782,
            21.017855414589178
          ],
          [
            105.81093807536851,
            21.017894848775615
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x2,5",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 18 Nguyên Hồng",
        "diemDau": "2-1",
        "diemCuoi": "2-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.017872314955365,
        "to_latitude": 21.017894848775615,
        "to_longitude": 105.81093807536851,
        "from_longitude": 105.81109230241249,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3921,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81069399436517,
          21.017894848775615,
          105.81093807536851,
          21.01803505912873
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81093807536851,
            21.017894848775615
          ],
          [
            105.81071209928987,
            21.01800438812614
          ],
          [
            105.81069399436517,
            21.01803505912873
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 18 Nguyên Hồng",
        "diemDau": "2-4",
        "diemCuoi": "2-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.017894848775615,
        "to_latitude": 21.01803505912873,
        "to_longitude": 105.81069399436517,
        "from_longitude": 105.81093807536851,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3922,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81069399436517,
          21.01803505912873,
          105.81079457719143,
          21.01822158877099
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81069399436517,
            21.01803505912873
          ],
          [
            105.81079457719143,
            21.01822158877099
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 18 Nguyên Hồng",
        "diemDau": "2-5",
        "diemCuoi": "2-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.01803505912873,
        "to_latitude": 21.01822158877099,
        "to_longitude": 105.81079457719143,
        "from_longitude": 105.81069399436517,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3923,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81057865936039,
          21.01822158877099,
          105.81079457719143,
          21.018330501944227
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81079457719143,
            21.01822158877099
          ],
          [
            105.81057865936039,
            21.018330501944227
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 18 Nguyên Hồng",
        "diemDau": "2-7",
        "diemCuoi": "2-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.01822158877099,
        "to_latitude": 21.018330501944227,
        "to_longitude": 105.81057865936039,
        "from_longitude": 105.81079457719143,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3924,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81079457719143,
          21.01811142364186,
          105.8110346349101,
          21.01822158877099
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81079457719143,
            21.01822158877099
          ],
          [
            105.8110346349101,
            21.01811142364186
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 18 Nguyên Hồng",
        "diemDau": "2-6",
        "diemCuoi": "2-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.01822158877099,
        "to_latitude": 21.01811142364186,
        "to_longitude": 105.8110346349101,
        "from_longitude": 105.81079457719143,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3925,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81055217253423,
          21.018308907097904,
          105.81057865936039,
          21.018330501944227
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81057865936039,
            21.018330501944227
          ],
          [
            105.81055217253423,
            21.018308907097904
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BW",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 18 Nguyên Hồng",
        "diemDau": "5-2",
        "diemCuoi": "2-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BW",
        "from_latitude": 21.018330501944227,
        "to_latitude": 21.018308907097904,
        "to_longitude": 105.81055217253423,
        "from_longitude": 105.81057865936039,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3926,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82399071028308,
          21.0058578618552,
          105.82423613240826,
          21.005970539992585
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82423613240826,
            21.0058578618552
          ],
          [
            105.82399071028308,
            21.005970539992585
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.0058578618552,
        "to_latitude": 21.005970539992585,
        "to_longitude": 105.82399071028308,
        "from_longitude": 105.82423613240826,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3927,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82367152740909,
          21.005970539992585,
          105.82399071028308,
          21.00607821013312
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82399071028308,
            21.005970539992585
          ],
          [
            105.82367152740909,
            21.00607821013312
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.005970539992585,
        "to_latitude": 21.00607821013312,
        "to_longitude": 105.82367152740909,
        "from_longitude": 105.82399071028308,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3928,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82337916662148,
          21.00607821013312,
          105.82367152740909,
          21.006432519464976
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82367152740909,
            21.00607821013312
          ],
          [
            105.82337916662148,
            21.006432519464976
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.00607821013312,
        "to_latitude": 21.006432519464976,
        "to_longitude": 105.82337916662148,
        "from_longitude": 105.82367152740909,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3929,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82436621957359,
          21.003387685311306,
          105.82454190426154,
          21.003527909301912
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82436621957359,
            21.003387685311306
          ],
          [
            105.82454190426154,
            21.003527909301912
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.003387685311306,
        "to_latitude": 21.003527909301912,
        "to_longitude": 105.82454190426154,
        "from_longitude": 105.82436621957359,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3930,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82436621957359,
          21.003377669307543,
          105.8243890183484,
          21.003387685311306
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82436621957359,
            21.003387685311306
          ],
          [
            105.8243890183484,
            21.003377669307543
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.003387685311306,
        "to_latitude": 21.003377669307543,
        "to_longitude": 105.8243890183484,
        "from_longitude": 105.82436621957359,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3931,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84085914477627,
          21.013551717575577,
          105.84098536164004,
          21.013845650396604
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84098536164004,
            21.013845650396604
          ],
          [
            105.84085914477627,
            21.01373135489872
          ],
          [
            105.84096880311711,
            21.013551717575577
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.013845650396604,
        "to_latitude": 21.013551717575577,
        "to_longitude": 105.84096880311711,
        "from_longitude": 105.84098536164004,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3932,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84096880311711,
          21.01335895662954,
          105.841057098438,
          21.013551717575577
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84096880311711,
            21.013551717575577
          ],
          [
            105.84103348836933,
            21.013444666385958
          ],
          [
            105.841057098438,
            21.01335895662954
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L2-1",
        "diemCuoi": "L2-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.013551717575577,
        "to_latitude": 21.01335895662954,
        "to_longitude": 105.841057098438,
        "from_longitude": 105.84096880311711,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3933,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84105024331792,
          21.013149410550387,
          105.841057098438,
          21.01335895662954
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.841057098438,
            21.01335895662954
          ],
          [
            105.84105024331792,
            21.013149410550387
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L2-2",
        "diemCuoi": "L2-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.01335895662954,
        "to_latitude": 21.013149410550387,
        "to_longitude": 105.84105024331792,
        "from_longitude": 105.841057098438,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3934,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84104732944051,
          21.0129397841349,
          105.84105024331792,
          21.013149410550387
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84105024331792,
            21.013149410550387
          ],
          [
            105.84104732944051,
            21.0129397841349
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L2-3",
        "diemCuoi": "L2-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.013149410550387,
        "to_latitude": 21.0129397841349,
        "to_longitude": 105.84104732944051,
        "from_longitude": 105.84105024331792,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3935,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84104697125836,
          21.012720077095395,
          105.84104732944051,
          21.0129397841349
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84104732944051,
            21.0129397841349
          ],
          [
            105.84104697125836,
            21.012720077095395
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L2-4",
        "diemCuoi": "L2-5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.0129397841349,
        "to_latitude": 21.012720077095395,
        "to_longitude": 105.84104697125836,
        "from_longitude": 105.84104732944051,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3936,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84089669570263,
          21.01249445903394,
          105.84104697125836,
          21.012720077095395
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84104697125836,
            21.012720077095395
          ],
          [
            105.84101337180229,
            21.01259586484983
          ],
          [
            105.84089669570263,
            21.01249445903394
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L2-5",
        "diemCuoi": "L2-6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.012720077095395,
        "to_latitude": 21.01249445903394,
        "to_longitude": 105.84089669570263,
        "from_longitude": 105.84104697125836,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3937,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84077063187588,
          21.01235173962014,
          105.84089669570263,
          21.01249445903394
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84089669570263,
            21.01249445903394
          ],
          [
            105.84078001961197,
            21.012421847419606
          ],
          [
            105.84077063187588,
            21.01235173962014
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L2-6",
        "diemCuoi": "L2-7",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.01249445903394,
        "to_latitude": 21.01235173962014,
        "to_longitude": 105.84077063187588,
        "from_longitude": 105.84089669570263,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3938,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84071832880694,
          21.012088835079457,
          105.84079745396228,
          21.01235173962014
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84077063187588,
            21.01235173962014
          ],
          [
            105.8407236932314,
            21.01229039526772
          ],
          [
            105.84071832880694,
            21.012217783554014
          ],
          [
            105.84073710427012,
            21.012147675659463
          ],
          [
            105.84079745396228,
            21.012088835079457
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L2-7",
        "diemCuoi": "L2-8",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.01235173962014,
        "to_latitude": 21.012088835079457,
        "to_longitude": 105.84079745396228,
        "from_longitude": 105.84077063187588,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3939,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84079745396228,
          21.012017475196803,
          105.84101203067146,
          21.012088835079457
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84079745396228,
            21.012088835079457
          ],
          [
            105.84085646256854,
            21.01204626953892
          ],
          [
            105.84097045645147,
            21.012063796527766
          ],
          [
            105.84099459633912,
            21.012058788816756
          ],
          [
            105.84101203067146,
            21.012017475196803
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L2-8",
        "diemCuoi": "L2-9",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.012088835079457,
        "to_latitude": 21.012017475196803,
        "to_longitude": 105.84101203067146,
        "from_longitude": 105.84079745396228,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3940,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84101203067146,
          21.011667881027673,
          105.84102888151266,
          21.012017475196803
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84101203067146,
            21.012017475196803
          ],
          [
            105.84102888151266,
            21.011667881027673
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L2-9",
        "diemCuoi": "L2-10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.012017475196803,
        "to_latitude": 21.011667881027673,
        "to_longitude": 105.84102888151266,
        "from_longitude": 105.84101203067146,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3941,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84101412070605,
          21.011458688207625,
          105.84102888151266,
          21.011667881027673
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84102888151266,
            21.011667881027673
          ],
          [
            105.84101412070605,
            21.011458688207625
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L2-10",
        "diemCuoi": "L2-11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.011667881027673,
        "to_latitude": 21.011458688207625,
        "to_longitude": 105.84101412070605,
        "from_longitude": 105.84102888151266,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3942,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84100348785763,
          21.011249276874274,
          105.84101412070605,
          21.011458688207625
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84101412070605,
            21.011458688207625
          ],
          [
            105.84100348785763,
            21.011249276874274
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L2-11",
        "diemCuoi": "L2-12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.011458688207625,
        "to_latitude": 21.011249276874274,
        "to_longitude": 105.84100348785763,
        "from_longitude": 105.84101412070605,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3943,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84090612200994,
          21.01105478938751,
          105.84100348785763,
          21.011249276874274
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84100348785763,
            21.011249276874274
          ],
          [
            105.84098654970691,
            21.01116866553939
          ],
          [
            105.84090612200994,
            21.01105478938751
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L2-12",
        "diemCuoi": "L2-13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.011249276874274,
        "to_latitude": 21.01105478938751,
        "to_longitude": 105.84090612200994,
        "from_longitude": 105.84100348785763,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3944,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8406986435928,
          21.011000083717047,
          105.84090612200994,
          21.01105478938751
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84090612200994,
            21.01105478938751
          ],
          [
            105.84081086501897,
            21.01100716580948
          ],
          [
            105.8406986435928,
            21.011000083717047
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L2-13",
        "diemCuoi": "L2-14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.01105478938751,
        "to_latitude": 21.011000083717047,
        "to_longitude": 105.8406986435928,
        "from_longitude": 105.84090612200994,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3945,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8404956261779,
          21.011000083717047,
          105.8406986435928,
          21.011000890454337
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8406986435928,
            21.011000083717047
          ],
          [
            105.8404956261779,
            21.011000890454337
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L2-14",
        "diemCuoi": "L2-15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.011000083717047,
        "to_latitude": 21.011000890454337,
        "to_longitude": 105.8404956261779,
        "from_longitude": 105.8406986435928,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3946,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84026360118186,
          21.011000890454337,
          105.8404956261779,
          21.01109154625962
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8404956261779,
            21.011000890454337
          ],
          [
            105.8403508661756,
            21.01101467742846
          ],
          [
            105.84026360118186,
            21.01109154625962
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L2-15",
        "diemCuoi": "L2-16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.011000890454337,
        "to_latitude": 21.01109154625962,
        "to_longitude": 105.84026360118186,
        "from_longitude": 105.8404956261779,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3947,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84008967405288,
          21.01109154625962,
          105.84026360118186,
          21.011224173779066
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84026360118186,
            21.01109154625962
          ],
          [
            105.84008967405288,
            21.011224173779066
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L2-16",
        "diemCuoi": "L2-17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.01109154625962,
        "to_latitude": 21.011224173779066,
        "to_longitude": 105.84008967405288,
        "from_longitude": 105.84026360118186,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3948,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83990870902221,
          21.011224173779066,
          105.84008967405288,
          21.011399111257443
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84008967405288,
            21.011224173779066
          ],
          [
            105.83990870902221,
            21.011399111257443
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L2-17",
        "diemCuoi": "L2-18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.011224173779066,
        "to_latitude": 21.011399111257443,
        "to_longitude": 105.83990870902221,
        "from_longitude": 105.84008967405288,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3949,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83974700681152,
          21.011399111257443,
          105.83990870902221,
          21.011563340835988
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83990870902221,
            21.011399111257443
          ],
          [
            105.83974700681152,
            21.011563340835988
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L2-18",
        "diemCuoi": "L2-19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.011399111257443,
        "to_latitude": 21.011563340835988,
        "to_longitude": 105.83974700681152,
        "from_longitude": 105.83990870902221,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3950,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83956233296408,
          21.011563340835988,
          105.83974700681152,
          21.011731281182627
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83974700681152,
            21.011563340835988
          ],
          [
            105.83956233296408,
            21.011731281182627
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L2-19",
        "diemCuoi": "L2-20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.011563340835988,
        "to_latitude": 21.011731281182627,
        "to_longitude": 105.83956233296408,
        "from_longitude": 105.83974700681152,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3951,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83939756641952,
          21.011731281182627,
          105.83956233296408,
          21.01187373695673
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83956233296408,
            21.011731281182627
          ],
          [
            105.83939756641952,
            21.01187373695673
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L2-20",
        "diemCuoi": "L2-21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.011731281182627,
        "to_latitude": 21.01187373695673,
        "to_longitude": 105.83939756641952,
        "from_longitude": 105.83956233296408,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3952,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8393163357242,
          21.01187373695673,
          105.83939756641952,
          21.012079945017557
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83939756641952,
            21.01187373695673
          ],
          [
            105.83932492122022,
            21.0119811692767
          ],
          [
            105.8393163357242,
            21.012079945017557
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBM3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "diemDau": "L2-21",
        "diemCuoi": "L2-22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HBM 3",
        "from_latitude": 21.01187373695673,
        "to_latitude": 21.012079945017557,
        "to_longitude": 105.8393163357242,
        "from_longitude": 105.83939756641952,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3953,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82571268848888,
          21.004449377955655,
          105.82582299427554,
          21.004644375528073
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82582299427554,
            21.004644375528073
          ],
          [
            105.82571268848888,
            21.004449377955655
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "15",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004644375528073,
        "to_latitude": 21.004449377955655,
        "to_longitude": 105.82571268848888,
        "from_longitude": 105.82582299427554,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3954,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82551688723389,
          21.004449377955655,
          105.82571268848888,
          21.004527001414242
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82571268848888,
            21.004449377955655
          ],
          [
            105.82551688723389,
            21.004527001414242
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 100 Khương Thượng",
        "diemDau": "",
        "diemCuoi": "15-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004449377955655,
        "to_latitude": 21.004527001414242,
        "to_longitude": 105.82551688723389,
        "from_longitude": 105.82571268848888,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3955,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8253881411994,
          21.004276603015768,
          105.82551688723389,
          21.004527001414242
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82551688723389,
            21.004527001414242
          ],
          [
            105.8253881411994,
            21.004276603015768
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 100 Khương Thượng",
        "diemDau": "15-1",
        "diemCuoi": "15-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004527001414242,
        "to_latitude": 21.004276603015768,
        "to_longitude": 105.8253881411994,
        "from_longitude": 105.82551688723389,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3956,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82533449701761,
          21.004527001414242,
          105.82551688723389,
          21.004605876822968
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82551688723389,
            21.004527001414242
          ],
          [
            105.82533449701761,
            21.004605876822968
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 100 Khương Thượng",
        "diemDau": "15-1",
        "diemCuoi": "15-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004527001414242,
        "to_latitude": 21.004605876822968,
        "to_longitude": 105.82533449701761,
        "from_longitude": 105.82551688723389,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3957,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82533449701761,
          21.004605876822968,
          105.82544714979667,
          21.004823722971416
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82533449701761,
            21.004605876822968
          ],
          [
            105.82544714979667,
            21.004823722971416
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 100 Khương Thượng",
        "diemDau": "15-3",
        "diemCuoi": "15-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004605876822968,
        "to_latitude": 21.004823722971416,
        "to_longitude": 105.82544714979667,
        "from_longitude": 105.82533449701761,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3958,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82562149338075,
          21.004277855008205,
          105.82571268848888,
          21.004449377955655
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82571268848888,
            21.004449377955655
          ],
          [
            105.82562149338075,
            21.004277855008205
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004449377955655,
        "to_latitude": 21.004277855008205,
        "to_longitude": 105.82562149338075,
        "from_longitude": 105.82571268848888,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3959,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82552761606487,
          21.004090055935606,
          105.82562149338075,
          21.004277855008205
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82562149338075,
            21.004277855008205
          ],
          [
            105.82552761606487,
            21.004090055935606
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "16",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004277855008205,
        "to_latitude": 21.004090055935606,
        "to_longitude": 105.82552761606487,
        "from_longitude": 105.82562149338075,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3960,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82552761606487,
          21.004007424268497,
          105.8257341461598,
          21.004090055935606
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82552761606487,
            21.004090055935606
          ],
          [
            105.8257341461598,
            21.004007424268497
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 139 Khương Thượng",
        "diemDau": "",
        "diemCuoi": "16-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004090055935606,
        "to_latitude": 21.004007424268497,
        "to_longitude": 105.8257341461598,
        "from_longitude": 105.82552761606487,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3961,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8257341461598,
          21.004007424268497,
          105.82591921858385,
          21.004032464173093
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8257341461598,
            21.004007424268497
          ],
          [
            105.82591921858385,
            21.004032464173093
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 139 Khương Thượng",
        "diemDau": "16-1",
        "diemCuoi": "16-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004007424268497,
        "to_latitude": 21.004032464173093,
        "to_longitude": 105.82591921858385,
        "from_longitude": 105.8257341461598,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3962,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82538009457618,
          21.003933556527336,
          105.82552761606487,
          21.004090055935606
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82552761606487,
            21.004090055935606
          ],
          [
            105.82538009457618,
            21.003933556527336
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004090055935606,
        "to_latitude": 21.003933556527336,
        "to_longitude": 105.82538009457618,
        "from_longitude": 105.82552761606487,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3963,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82538009457618,
          21.00387346071112,
          105.82538143568003,
          21.003933556527347
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82538009457618,
            21.003933556527347
          ],
          [
            105.82538143568003,
            21.00387346071112
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 5,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 149 Khương Thượng",
        "diemDau": "17",
        "diemCuoi": "17-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.003933556527347,
        "to_latitude": 21.00387346071112,
        "to_longitude": 105.82538143568003,
        "from_longitude": 105.82538009457618,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3964,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82538143568003,
          21.003721969067566,
          105.82560271792656,
          21.00387346071112
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82538143568003,
            21.00387346071112
          ],
          [
            105.82560271792656,
            21.003721969067566
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 149 Khương Thượng",
        "diemDau": "17-1",
        "diemCuoi": "17-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.00387346071112,
        "to_latitude": 21.003721969067566,
        "to_longitude": 105.82560271792656,
        "from_longitude": 105.82538143568003,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3965,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82560271792656,
          21.003626817212297,
          105.82573280506493,
          21.003721969067566
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82560271792656,
            21.003721969067566
          ],
          [
            105.82573280506493,
            21.003626817212297
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 149 Khương Thượng",
        "diemDau": "17-2",
        "diemCuoi": "17-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.003721969067566,
        "to_latitude": 21.003626817212297,
        "to_longitude": 105.82573280506493,
        "from_longitude": 105.82560271792656,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3966,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82573280506493,
          21.003330093275213,
          105.82585484558007,
          21.003626817212297
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82573280506493,
            21.003626817212297
          ],
          [
            105.82585484558007,
            21.003330093275213
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 149 Khương Thượng",
        "diemDau": "17-3",
        "diemCuoi": "17-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.003626817212297,
        "to_latitude": 21.003330093275213,
        "to_longitude": 105.82585484558007,
        "from_longitude": 105.82573280506493,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3967,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82516719424008,
          21.003933556527336,
          105.82538009457618,
          21.00405906906675
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82538009457618,
            21.003933556527336
          ],
          [
            105.82516719424008,
            21.00405906906675
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.003933556527336,
        "to_latitude": 21.00405906906675,
        "to_longitude": 105.82516719424008,
        "from_longitude": 105.82538009457618,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3968,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82489763223624,
          21.00405906906675,
          105.82516719424008,
          21.004181764513717
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82516719424008,
            21.00405906906675
          ],
          [
            105.82489763223624,
            21.004181764513717
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "18",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.00405906906675,
        "to_latitude": 21.004181764513717,
        "to_longitude": 105.82489763223624,
        "from_longitude": 105.82516719424008,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3969,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82482253038353,
          21.003988957337466,
          105.82489763223624,
          21.004181764513717
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82489763223624,
            21.004181764513717
          ],
          [
            105.82482253038353,
            21.003988957337466
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004181764513717,
        "to_latitude": 21.003988957337466,
        "to_longitude": 105.82482253038353,
        "from_longitude": 105.82489763223624,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3970,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82470451319799,
          21.00374481802339,
          105.82482253038353,
          21.003988957337466
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82482253038353,
            21.003988957337466
          ],
          [
            105.82470451319799,
            21.00374481802339
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.003988957337466,
        "to_latitude": 21.00374481802339,
        "to_longitude": 105.82470451319799,
        "from_longitude": 105.82482253038353,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3971,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82470451319799,
          21.003650918181222,
          105.82495529973863,
          21.00374481802339
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82470451319799,
            21.00374481802339
          ],
          [
            105.82495529973863,
            21.003650918181222
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đình Khương Thượng",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.00374481802339,
        "to_latitude": 21.003650918181222,
        "to_longitude": 105.82495529973863,
        "from_longitude": 105.82470451319799,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3972,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82470451319799,
          21.00366093416752,
          105.82482253039252,
          21.00374481802339
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82470451319799,
            21.00374481802339
          ],
          [
            105.82482253039252,
            21.003714770080283
          ],
          [
            105.82479570829713,
            21.00366093416752
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đình Khương Thượng",
        "diemDau": "20",
        "diemCuoi": "20-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.00374481802339,
        "to_latitude": 21.00366093416752,
        "to_longitude": 105.82479570829713,
        "from_longitude": 105.82470451319799,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3973,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82454190426154,
          21.003456858319822,
          105.82469378435802,
          21.003527909301912
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82454190426154,
            21.003527909301912
          ],
          [
            105.82455028617197,
            21.00349441831326
          ],
          [
            105.82465355121495,
            21.003456858319822
          ],
          [
            105.82469378435802,
            21.003473134318547
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đình Khương Thượng",
        "diemDau": "01",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.003527909301912,
        "to_latitude": 21.003473134318547,
        "to_longitude": 105.82469378435802,
        "from_longitude": 105.82454190426154,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3974,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82406748855384,
          21.003276570222624,
          105.82418952905999,
          21.003436826320044
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82418952905999,
            21.003436826320044
          ],
          [
            105.82406748855384,
            21.003276570222624
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Khương Thượng",
        "diemDau": "2-1",
        "diemCuoi": "2-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.003436826320044,
        "to_latitude": 21.003276570222624,
        "to_longitude": 105.82406748855384,
        "from_longitude": 105.82418952905999,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3975,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8243890183484,
          21.00329284624014,
          105.82441215234748,
          21.003377669307543
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8243890183484,
            21.003377669307543
          ],
          [
            105.82441215234748,
            21.00329284624014
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Khương Thượng",
        "diemDau": "02",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.003377669307543,
        "to_latitude": 21.00329284624014,
        "to_longitude": 105.82441215234748,
        "from_longitude": 105.8243890183484,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3976,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82477022724275,
          21.00315074403342,
          105.82489897327724,
          21.003173280076673
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82489897327724,
            21.00315074403342
          ],
          [
            105.82477022724275,
            21.003173280076673
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Khương Thượng",
        "diemDau": "",
        "diemCuoi": "3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.00315074403342,
        "to_latitude": 21.003173280076673,
        "to_longitude": 105.82477022724275,
        "from_longitude": 105.82489897327724,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3977,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82441215234748,
          21.003173280076673,
          105.82477022724275,
          21.00329284624014
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82441215234748,
            21.00329284624014
          ],
          [
            105.82477022724275,
            21.003173280076673
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Khương Thượng",
        "diemDau": "3-1",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.00329284624014,
        "to_latitude": 21.003173280076673,
        "to_longitude": 105.82477022724275,
        "from_longitude": 105.82441215234748,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3978,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82488288002179,
          21.00315074403342,
          105.82491908984429,
          21.003263424214907
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82489897327724,
            21.00315074403342
          ],
          [
            105.82488288002179,
            21.00316827206728
          ],
          [
            105.82491908984429,
            21.003263424214907
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Khương Thượng",
        "diemDau": "3-2",
        "diemCuoi": "3-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.00315074403342,
        "to_latitude": 21.003263424214907,
        "to_longitude": 105.82491908984429,
        "from_longitude": 105.82489897327724,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3979,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82489897327724,
          21.00306811184704,
          105.82503308372723,
          21.00315074403342
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82489897327724,
            21.00315074403342
          ],
          [
            105.82503308372723,
            21.00306811184704
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Khương Thượng",
        "diemDau": "3-2",
        "diemCuoi": "3-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.00315074403342,
        "to_latitude": 21.00306811184704,
        "to_longitude": 105.82503308372723,
        "from_longitude": 105.82489897327724,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3980,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82503308372723,
          21.002987983621427,
          105.82522352056674,
          21.00306811184704
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82503308372723,
            21.00306811184704
          ],
          [
            105.82522352056674,
            21.002987983621427
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Khương Thượng",
        "diemDau": "3-4",
        "diemCuoi": "3-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.00306811184704,
        "to_latitude": 21.002987983621427,
        "to_longitude": 105.82522352056674,
        "from_longitude": 105.82503308372723,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3981,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82522352056674,
          21.002987983621427,
          105.82526509480469,
          21.003311000265974
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82522352056674,
            21.002987983621427
          ],
          [
            105.82526509480469,
            21.003311000265974
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Khương Thượng",
        "diemDau": "3-5",
        "diemCuoi": "3-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.002987983621427,
        "to_latitude": 21.003311000265974,
        "to_longitude": 105.82526509480469,
        "from_longitude": 105.82522352056674,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3982,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82522352056674,
          21.00296294354242,
          105.82532946782644,
          21.003033055753736
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82522352056674,
            21.002987983621427
          ],
          [
            105.82532410340197,
            21.003033055753736
          ],
          [
            105.82532946782644,
            21.00296294354242
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Khương Thượng",
        "diemDau": "3-5",
        "diemCuoi": "3-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.002987983621427,
        "to_latitude": 21.00296294354242,
        "to_longitude": 105.82532946782644,
        "from_longitude": 105.82522352056674,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3983,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82520608620743,
          21.002833987068247,
          105.82522352056674,
          21.002987983621427
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82522352056674,
            21.002987983621427
          ],
          [
            105.82520608620743,
            21.002833987068247
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Khương Thượng",
        "diemDau": "3-5",
        "diemCuoi": "3-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.002987983621427,
        "to_latitude": 21.002833987068247,
        "to_longitude": 105.82520608620743,
        "from_longitude": 105.82522352056674,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3984,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8243890183484,
          21.00310191592895,
          105.82440276462039,
          21.003377669307543
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8243890183484,
            21.003377669307543
          ],
          [
            105.82440276462039,
            21.00310191592895
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.003377669307543,
        "to_latitude": 21.00310191592895,
        "to_longitude": 105.82440276462039,
        "from_longitude": 105.8243890183484,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3985,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82431961613547,
          21.00288156325623,
          105.82440276462039,
          21.00310191592895
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82440276462039,
            21.00310191592895
          ],
          [
            105.82431961613547,
            21.00288156325623
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "03",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.00310191592895,
        "to_latitude": 21.00288156325623,
        "to_longitude": 105.82431961613547,
        "from_longitude": 105.82440276462039,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3986,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82422037440409,
          21.002661210258943,
          105.82431961613547,
          21.00288156325623
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82431961613547,
            21.00288156325623
          ],
          [
            105.82422037440409,
            21.002661210258943
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.00288156325623,
        "to_latitude": 21.002661210258943,
        "to_longitude": 105.82422037440409,
        "from_longitude": 105.82431961613547,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3987,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82431961613547,
          21.002808946963416,
          105.82452346402268,
          21.00288156325623
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82431961613547,
            21.00288156325623
          ],
          [
            105.82452346402268,
            21.002808946963416
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 189 Khương Thượng",
        "diemDau": "",
        "diemCuoi": "3-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.00288156325623,
        "to_latitude": 21.002808946963416,
        "to_longitude": 105.82452346402268,
        "from_longitude": 105.82431961613547,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3988,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82452346402268,
          21.002753858717767,
          105.82466562110486,
          21.00280894696339
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82452346402268,
            21.00280894696339
          ],
          [
            105.82466562110486,
            21.002753858717767
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 189 Khương Thượng",
        "diemDau": "3-9",
        "diemCuoi": "3-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.00280894696339,
        "to_latitude": 21.002753858717767,
        "to_longitude": 105.82466562110486,
        "from_longitude": 105.82452346402268,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3989,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82466562110486,
          21.00268625038857,
          105.82488288003078,
          21.002753858717767
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82466562110486,
            21.002753858717767
          ],
          [
            105.82488288003078,
            21.00268625038857
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 189 Khương Thượng",
        "diemDau": "3-10",
        "diemCuoi": "3-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.002753858717767,
        "to_latitude": 21.00268625038857,
        "to_longitude": 105.82488288003078,
        "from_longitude": 105.82466562110486,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3990,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82422037440409,
          21.002581081814878,
          105.82457174378897,
          21.002661210258943
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82422037440409,
            21.002661210258943
          ],
          [
            105.82457174378897,
            21.002581081814878
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 191 Khương Thượng",
        "diemDau": "04",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.002661210258943,
        "to_latitude": 21.002581081814878,
        "to_longitude": 105.82457174378897,
        "from_longitude": 105.82422037440409,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3991,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82457174378897,
          21.002498449313062,
          105.82492311316489,
          21.002581081814878
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82457174378897,
            21.002581081814878
          ],
          [
            105.82492311316489,
            21.002498449313062
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 191 Khương Thượng",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.002581081814878,
        "to_latitude": 21.002498449313062,
        "to_longitude": 105.82492311316489,
        "from_longitude": 105.82457174378897,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3992,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82492311316489,
          21.002440856935483,
          105.82520742732027,
          21.002498449313062
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82492311316489,
            21.002498449313062
          ],
          [
            105.82520742732027,
            21.002440856935483
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 191 Khương Thượng",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.002498449313062,
        "to_latitude": 21.002440856935483,
        "to_longitude": 105.82520742732027,
        "from_longitude": 105.82492311316489,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3993,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82520742732027,
          21.002440856935483,
          105.82523961383114,
          21.002626154070057
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82520742732027,
            21.002440856935483
          ],
          [
            105.82523961383114,
            21.002626154070057
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 191 Khương Thượng",
        "diemDau": "09",
        "diemCuoi": "9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.002440856935483,
        "to_latitude": 21.002626154070057,
        "to_longitude": 105.82523961383114,
        "from_longitude": 105.82520742732027,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3994,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82520742732027,
          21.002440856935483,
          105.82562853414238,
          21.002475913167892
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82520742732027,
            21.002440856935483
          ],
          [
            105.82562853414238,
            21.002475913167892
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 191 Khương Thượng",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.002440856935483,
        "to_latitude": 21.002475913167892,
        "to_longitude": 105.82562853414238,
        "from_longitude": 105.82520742732027,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3995,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82562853414238,
          21.002475913167892,
          105.82562853414238,
          21.002706282489086
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82562853414238,
            21.002475913167892
          ],
          [
            105.82562853414238,
            21.002706282489086
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 191 Khương Thượng",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.002475913167892,
        "to_latitude": 21.002706282489086,
        "to_longitude": 105.82562853414238,
        "from_longitude": 105.82562853414238,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3996,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82562853414238,
          21.00245087300128,
          105.82583104094368,
          21.002475913167892
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82562853414238,
            21.002475913167892
          ],
          [
            105.82583104094368,
            21.00245087300128
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 191 Khương Thượng",
        "diemDau": "10",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.002475913167892,
        "to_latitude": 21.00245087300128,
        "to_longitude": 105.82583104094368,
        "from_longitude": 105.82562853414238,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3997,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82583104094368,
          21.00245087300128,
          105.82613413056225,
          21.002518481437953
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82583104094368,
            21.00245087300128
          ],
          [
            105.82613413056225,
            21.002518481437953
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 191 Khương Thượng",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.00245087300128,
        "to_latitude": 21.002518481437953,
        "to_longitude": 105.82613413056225,
        "from_longitude": 105.82583104094368,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3998,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82613413056225,
          21.002518481437953,
          105.82634736618554,
          21.00259735790743
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82613413056225,
            21.002518481437953
          ],
          [
            105.82634736618554,
            21.00259735790743
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 191 Khương Thượng",
        "diemDau": "11",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.002518481437953,
        "to_latitude": 21.00259735790743,
        "to_longitude": 105.82634736618554,
        "from_longitude": 105.82613413056225,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 3999,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82634736618554,
          21.00259735790743,
          105.82651366316438,
          21.00260737396441
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82634736618554,
            21.00259735790743
          ],
          [
            105.82651366316438,
            21.00260737396441
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 191 Khương Thượng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.00259735790743,
        "to_latitude": 21.00260737396441,
        "to_longitude": 105.82651366316438,
        "from_longitude": 105.82634736618554,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4000,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82654819654189,
          21.002405800694003,
          105.82657937721812,
          21.002614573017674
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82654819654189,
            21.002614573017674
          ],
          [
            105.82657937721812,
            21.002405800694003
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002614573017674,
        "to_latitude": 21.002405800694003,
        "to_longitude": 105.82657937721812,
        "from_longitude": 105.82654819654189,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4001,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82654450850849,
          21.002191707046595,
          105.82657937721812,
          21.002405800694003
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82657937721812,
            21.002405800694003
          ],
          [
            105.82654450850849,
            21.002191707046595
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002405800694003,
        "to_latitude": 21.002191707046595,
        "to_longitude": 105.82654450850849,
        "from_longitude": 105.82657937721812,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4002,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82653377966854,
          21.00197636107951,
          105.82654450850849,
          21.002191707046595
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82654450850849,
            21.002191707046595
          ],
          [
            105.82653377966854,
            21.00197636107951
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "diemDau": "",
        "diemCuoi": "36",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002191707046595,
        "to_latitude": 21.00197636107951,
        "to_longitude": 105.82653377966854,
        "from_longitude": 105.82654450850849,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4003,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81077211368587,
          21.01912919611348,
          105.8107892127939,
          21.019137020290767
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8107892127939,
            21.01912919611348
          ],
          [
            105.81077211368587,
            21.019137020290767
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "diemDau": "Tủ G22B",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.01912919611348,
        "to_latitude": 21.019137020290767,
        "to_longitude": 105.81077211368587,
        "from_longitude": 105.8107892127939,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4004,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81068997105356,
          21.01895143069601,
          105.8107892127939,
          21.01912919611348
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8107892127939,
            21.01912919611348
          ],
          [
            105.81068997105356,
            21.01895143069601
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "diemDau": "02",
        "diemCuoi": "01",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.01912919611348,
        "to_latitude": 21.01895143069601,
        "to_longitude": 105.81068997105356,
        "from_longitude": 105.8107892127939,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4005,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8107892127939,
          21.01912919611348,
          105.81093405207486,
          21.01938457704559
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8107892127939,
            21.01912919611348
          ],
          [
            105.81093405207486,
            21.01938457704559
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.01912919611348,
        "to_latitude": 21.01938457704559,
        "to_longitude": 105.81093405207486,
        "from_longitude": 105.8107892127939,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4006,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81093405207486,
          21.01938457704559,
          105.8110654803171,
          21.01960490534213
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81093405207486,
            21.01938457704559
          ],
          [
            105.8110654803171,
            21.01960490534213
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.01938457704559,
        "to_latitude": 21.01960490534213,
        "to_longitude": 105.8110654803171,
        "from_longitude": 105.81093405207486,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4007,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8110654803171,
          21.01960490534213,
          105.81127737482754,
          21.01985778173534
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8110654803171,
            21.01960490534213
          ],
          [
            105.81114259386605,
            21.01964058346792
          ],
          [
            105.81127737482754,
            21.01985778173534
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.01960490534213,
        "to_latitude": 21.01985778173534,
        "to_longitude": 105.81127737482754,
        "from_longitude": 105.8110654803171,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4008,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81127737482754,
          21.01985778173534,
          105.81143294294843,
          21.020099390958233
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81127737482754,
            21.01985778173534
          ],
          [
            105.81143294294843,
            21.020099390958233
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.01985778173534,
        "to_latitude": 21.020099390958233,
        "to_longitude": 105.81143294294843,
        "from_longitude": 105.81127737482754,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4009,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81143294294843,
          21.020099390958233,
          105.81159762662027,
          21.0201558079334
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81143294294843,
            21.020099390958233
          ],
          [
            105.8114684822594,
            21.0201450838452
          ],
          [
            105.81159762662027,
            21.0201558079334
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.020099390958233,
        "to_latitude": 21.0201558079334,
        "to_longitude": 105.81159762662027,
        "from_longitude": 105.81143294294843,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4010,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81159762662027,
          21.0201558079334,
          105.81189025959301,
          21.02017825813301
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81159762662027,
            21.0201558079334
          ],
          [
            105.81189025959301,
            21.02017825813301
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.0201558079334,
        "to_latitude": 21.02017825813301,
        "to_longitude": 105.81189025959301,
        "from_longitude": 105.81159762662027,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4011,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8110654803171,
          21.01952822862815,
          105.81127972177502,
          21.01960490534213
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8110654803171,
            21.01960490534213
          ],
          [
            105.81111979509124,
            21.019604279405204
          ],
          [
            105.81127972177502,
            21.01952822862815
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.01960490534213,
        "to_latitude": 21.01952822862815,
        "to_longitude": 105.81127972177502,
        "from_longitude": 105.8110654803171,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4012,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81127972177502,
          21.01944560551252,
          105.81144467763201,
          21.01952822862815
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81127972177502,
            21.01952822862815
          ],
          [
            105.81144467763201,
            21.01944560551252
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "diemDau": "4-1",
        "diemCuoi": "4-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.01952822862815,
        "to_latitude": 21.01944560551252,
        "to_longitude": 105.81144467763201,
        "from_longitude": 105.81127972177502,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4013,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81144467763201,
          21.019329181954706,
          105.81167400650176,
          21.01944560551252
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81144467763201,
            21.01944560551252
          ],
          [
            105.81167400650176,
            21.019329181954706
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "diemDau": "4-3",
        "diemCuoi": "4-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.01944560551252,
        "to_latitude": 21.019329181954706,
        "to_longitude": 105.81167400650176,
        "from_longitude": 105.81144467763201,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4014,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81167400650176,
          21.019329181954706,
          105.81187517218122,
          21.019516961842683
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81167400650176,
            21.019329181954706
          ],
          [
            105.81181716943655,
            21.019377691775993
          ],
          [
            105.81187517218122,
            21.019516961842683
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "diemDau": "4-4",
        "diemCuoi": "4-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.019329181954706,
        "to_latitude": 21.019516961842683,
        "to_longitude": 105.81187517218122,
        "from_longitude": 105.81167400650176,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4015,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81181214027232,
          21.019516961842683,
          105.81187517218122,
          21.019766082796078
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81187517218122,
            21.019516961842683
          ],
          [
            105.81184533262683,
            21.01966436888428
          ],
          [
            105.81182521605977,
            21.01967062820317
          ],
          [
            105.81181214027232,
            21.019766082796078
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "diemDau": "4-5",
        "diemCuoi": "4-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.019516961842683,
        "to_latitude": 21.019766082796078,
        "to_longitude": 105.81181214027232,
        "from_longitude": 105.81187517218122,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4016,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8117692249215,
          21.019766082796078,
          105.81181214027232,
          21.01999892914018
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81181214027232,
            21.019766082796078
          ],
          [
            105.8117692249215,
            21.01999892914018
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "diemDau": "4-6",
        "diemCuoi": "4-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.019766082796078,
        "to_latitude": 21.01999892914018,
        "to_longitude": 105.8117692249215,
        "from_longitude": 105.81181214027232,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4017,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81114622590988,
          21.01927179972463,
          105.81127972177502,
          21.01952822862815
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81114622590988,
            21.01927179972463
          ],
          [
            105.81120562577493,
            21.019367676845807
          ],
          [
            105.81126731657996,
            21.019485352239194
          ],
          [
            105.81127972177502,
            21.01952822862815
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x2,5",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.01927179972463,
        "to_latitude": 21.01952822862815,
        "to_longitude": 105.81127972177502,
        "from_longitude": 105.81114622590988,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4018,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81132949165647,
          21.019227490582164,
          105.81144467763201,
          21.01944560551252
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81132949165647,
            21.019227490582164
          ],
          [
            105.81144467763201,
            21.01944560551252
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x2,5",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "diemDau": "4-3",
        "diemCuoi": "4-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.019227490582164,
        "to_latitude": 21.01944560551252,
        "to_longitude": 105.81144467763201,
        "from_longitude": 105.81132949165647,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4019,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82583104094368,
          21.00214819967602,
          105.82586222160195,
          21.00245087300128
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82583104094368,
            21.00245087300128
          ],
          [
            105.82586222160195,
            21.00214819967602
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 388 Trường Chinh",
        "diemDau": "10",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.00245087300128,
        "to_latitude": 21.00214819967602,
        "to_longitude": 105.82586222160195,
        "from_longitude": 105.82583104094368,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4020,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82586222160195,
          21.00214819967602,
          105.82618274557984,
          21.002228328352523
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82586222160195,
            21.00214819967602
          ],
          [
            105.82610764372711,
            21.002163223806466
          ],
          [
            105.82618274557984,
            21.002228328352523
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 388 Trường Chinh",
        "diemDau": "12",
        "diemCuoi": "12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.00214819967602,
        "to_latitude": 21.002228328352523,
        "to_longitude": 105.82618274557984,
        "from_longitude": 105.82586222160195,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4021,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82577102650279,
          21.002144443643406,
          105.82586222160195,
          21.00214819967602
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82586222160195,
            21.00214819967602
          ],
          [
            105.82577102650279,
            21.002144443643406
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 388 Trường Chinh",
        "diemDau": "12",
        "diemCuoi": "12-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.00214819967602,
        "to_latitude": 21.002144443643406,
        "to_longitude": 105.82577102650279,
        "from_longitude": 105.82586222160195,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4022,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8255752252388,
          21.002144443643406,
          105.82577102650279,
          21.002145695654573
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82577102650279,
            21.002144443643406
          ],
          [
            105.8255752252388,
            21.002145695654573
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 388 Trường Chinh",
        "diemDau": "12-2",
        "diemCuoi": "12-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.002144443643406,
        "to_latitude": 21.002145695654573,
        "to_longitude": 105.8255752252388,
        "from_longitude": 105.82577102650279,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4023,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82586222160195,
          21.001917829493383,
          105.82588099706513,
          21.00214819967602
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82586222160195,
            21.00214819967602
          ],
          [
            105.82588099706513,
            21.001917829493383
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 388 Trường Chinh",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.00214819967602,
        "to_latitude": 21.001917829493383,
        "to_longitude": 105.82588099706513,
        "from_longitude": 105.82586222160195,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4024,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82584076393104,
          21.001820172460043,
          105.82588099706513,
          21.001917829493383
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82588099706513,
            21.001917829493383
          ],
          [
            105.82584076393104,
            21.001820172460043
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 388 Trường Chinh",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.001917829493383,
        "to_latitude": 21.001820172460043,
        "to_longitude": 105.82584076393104,
        "from_longitude": 105.82588099706513,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4025,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82574956882289,
          21.001661166641874,
          105.82584076393104,
          21.001820172460043
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82584076393104,
            21.001820172460043
          ],
          [
            105.82574956882289,
            21.001661166641874
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 388 Trường Chinh",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.001820172460043,
        "to_latitude": 21.001661166641874,
        "to_longitude": 105.82574956882289,
        "from_longitude": 105.82584076393104,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4026,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82560204732522,
          21.001891537221525,
          105.82588099706513,
          21.001917829493383
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82588099706513,
            21.001917829493383
          ],
          [
            105.82560204732522,
            21.001891537221525
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 388 Trường Chinh",
        "diemDau": "13",
        "diemCuoi": "13-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.001917829493383,
        "to_latitude": 21.001891537221525,
        "to_longitude": 105.82560204732522,
        "from_longitude": 105.82588099706513,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4027,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82538612950317,
          21.001891537221525,
          105.82560204732522,
          21.001955389873345
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82560204732522,
            21.001891537221525
          ],
          [
            105.82538612950317,
            21.001955389873345
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 388 Trường Chinh",
        "diemDau": "13-2",
        "diemCuoi": "13-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.001891537221525,
        "to_latitude": 21.001955389873345,
        "to_longitude": 105.82538612950317,
        "from_longitude": 105.82560204732522,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4028,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82560204732522,
          21.001891537221525,
          105.82571738231204,
          21.001984186158158
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82560204732522,
            21.001891537221525
          ],
          [
            105.82571738231204,
            21.00194286974774
          ],
          [
            105.82568519580114,
            21.001984186158158
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 388 Trường Chinh",
        "diemDau": "13-2",
        "diemCuoi": "13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.001891537221525,
        "to_latitude": 21.001984186158158,
        "to_longitude": 105.82568519580114,
        "from_longitude": 105.82560204732522,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4029,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81056860113976,
          21.019137020290767,
          105.81077211368587,
          21.019246245757476
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81077211368587,
            21.019137020290767
          ],
          [
            105.81074864442651,
            21.019155798309704
          ],
          [
            105.81056860113976,
            21.019246245757476
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "diemDau": "Tủ G22B",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.019137020290767,
        "to_latitude": 21.019246245757476,
        "to_longitude": 105.81056860113976,
        "from_longitude": 105.81077211368587,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4030,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8103620710448,
          21.019246245757476,
          105.81056860113976,
          21.019352654447978
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81056860113976,
            21.019246245757476
          ],
          [
            105.8103620710448,
            21.019352654447978
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "diemDau": "27",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.019246245757476,
        "to_latitude": 21.019352654447978,
        "to_longitude": 105.8103620710448,
        "from_longitude": 105.81056860113976,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4031,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81002545381149,
          21.019352654447978,
          105.8103620710448,
          21.019517900736485
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8103620710448,
            21.019352654447978
          ],
          [
            105.81002545381149,
            21.019517900736485
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "diemDau": "26",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.019352654447978,
        "to_latitude": 21.019517900736485,
        "to_longitude": 105.81002545381149,
        "from_longitude": 105.8103620710448,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4032,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80969956541814,
          21.019517900736485,
          105.81002545381149,
          21.019679391249834
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81002545381149,
            21.019517900736485
          ],
          [
            105.80969956541814,
            21.019679391249834
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 49,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "diemDau": "25",
        "diemCuoi": "24-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.019517900736485,
        "to_latitude": 21.019679391249834,
        "to_longitude": 105.80969956541814,
        "from_longitude": 105.81002545381149,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4033,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80962178135766,
          21.019679391249834,
          105.80969956541814,
          21.01970912301197
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80969956541814,
            21.019679391249834
          ],
          [
            105.80963418657971,
            21.01970912301197
          ],
          [
            105.80962178135766,
            21.019679391249834
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "diemDau": "24-1",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.019679391249834,
        "to_latitude": 21.019679391249834,
        "to_longitude": 105.80962178135766,
        "from_longitude": 105.80969956541814,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4034,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80937770033638,
          21.019679391249834,
          105.80962178135766,
          21.019828362963715
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80962178135766,
            21.019679391249834
          ],
          [
            105.80960200007782,
            21.01972289351022
          ],
          [
            105.80937770033638,
            21.019828362963715
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "diemDau": "24",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.019679391249834,
        "to_latitude": 21.019828362963715,
        "to_longitude": 105.80937770033638,
        "from_longitude": 105.80962178135766,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4035,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80937770033638,
          21.019828362963715,
          105.809505105267,
          21.020073727816452
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80937770033638,
            21.019828362963715
          ],
          [
            105.809505105267,
            21.020073727816452
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x2,5",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "diemDau": "23",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.019828362963715,
        "to_latitude": 21.020073727816452,
        "to_longitude": 105.809505105267,
        "from_longitude": 105.80937770033638,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4036,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80903840089532,
          21.01996575481247,
          105.80930796289915,
          21.02015384686399
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80903840089532,
            21.02002365338914
          ],
          [
            105.80913663681001,
            21.01997702156401
          ],
          [
            105.80919832761502,
            21.01996575481247
          ],
          [
            105.80930796289915,
            21.02015384686399
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "diemDau": "19-1",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.02002365338914,
        "to_latitude": 21.02015384686399,
        "to_longitude": 105.80930796289915,
        "from_longitude": 105.80903840089532,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4037,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81002545381149,
          21.019517900736485,
          105.81016895199755,
          21.019748243741297
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81002545381149,
            21.019517900736485
          ],
          [
            105.81005529340182,
            21.01955263998952
          ],
          [
            105.81016895199755,
            21.019748243741297
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "diemDau": "25",
        "diemCuoi": "25-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.019517900736485,
        "to_latitude": 21.019748243741297,
        "to_longitude": 105.81016895199755,
        "from_longitude": 105.81002545381149,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4038,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80975455070377,
          21.019748243741297,
          105.81016895199755,
          21.01994103054803
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81016895199755,
            21.019748243741297
          ],
          [
            105.80975455070377,
            21.01994103054803
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x2,5",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "diemDau": "25-1",
        "diemCuoi": "25-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.019748243741297,
        "to_latitude": 21.01994103054803,
        "to_longitude": 105.80975455070377,
        "from_longitude": 105.81016895199755,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4039,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81016895199755,
          21.019740732562166,
          105.81054714346884,
          21.019803012746276
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81016895199755,
            21.019748243741297
          ],
          [
            105.81021354373046,
            21.01974167145958
          ],
          [
            105.81033156092501,
            21.0197729680377
          ],
          [
            105.8103852051068,
            21.019795501569906
          ],
          [
            105.81042409713702,
            21.019803012746276
          ],
          [
            105.81044957811955,
            21.019796753432104
          ],
          [
            105.81054714346884,
            21.019740732562166
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "diemDau": "25-1",
        "diemCuoi": "25-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.019748243741297,
        "to_latitude": 21.019740732562166,
        "to_longitude": 105.81054714346884,
        "from_longitude": 105.81016895199755,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4040,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.824100010298,
          21.002441169938717,
          105.82422037440409,
          21.002661210258943
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82422037440409,
            21.002661210258943
          ],
          [
            105.824100010298,
            21.002441169938717
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.002661210258943,
        "to_latitude": 21.002441169938717,
        "to_longitude": 105.824100010298,
        "from_longitude": 105.82422037440409,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4041,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82393639554488,
          21.00208810314085,
          105.824100010298,
          21.002441169938717
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.824100010298,
            21.002441169938717
          ],
          [
            105.82393639554488,
            21.00208810314085
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.002441169938717,
        "to_latitude": 21.00208810314085,
        "to_longitude": 105.82393639554488,
        "from_longitude": 105.824100010298,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4042,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.824100010298,
          21.002373561467017,
          105.82431056372252,
          21.002441169938717
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.824100010298,
            21.002441169938717
          ],
          [
            105.82431056372252,
            21.002373561467017
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 199 Khương Thượng",
        "diemDau": "05",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.002441169938717,
        "to_latitude": 21.002373561467017,
        "to_longitude": 105.82431056372252,
        "from_longitude": 105.824100010298,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4043,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82431056372252,
          21.00234100922964,
          105.82442455760544,
          21.002373561467017
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82431056372252,
            21.002373561467017
          ],
          [
            105.82442455760544,
            21.00234100922964
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 199 Khương Thượng",
        "diemDau": "5-1",
        "diemCuoi": "5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.002373561467017,
        "to_latitude": 21.00234100922964,
        "to_longitude": 105.82442455760544,
        "from_longitude": 105.82431056372252,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4044,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82442455760544,
          21.002278408752005,
          105.82472228279956,
          21.00234100922964
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82442455760544,
            21.00234100922964
          ],
          [
            105.82472228279956,
            21.002278408752005
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 199 Khương Thượng",
        "diemDau": "5-2",
        "diemCuoi": "5-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.00234100922964,
        "to_latitude": 21.002278408752005,
        "to_longitude": 105.82472228279956,
        "from_longitude": 105.82442455760544,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4045,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82472228279956,
          21.0022421004637,
          105.82488053313719,
          21.002278408752005
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82472228279956,
            21.002278408752005
          ],
          [
            105.82488053313719,
            21.0022421004637
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 199 Khương Thượng",
        "diemDau": "5-3",
        "diemCuoi": "5-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.002278408752005,
        "to_latitude": 21.0022421004637,
        "to_longitude": 105.82488053313719,
        "from_longitude": 105.82472228279956,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4046,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8111375647108,
          21.02009344461682,
          105.81143294294843,
          21.02018545632494
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81143294294843,
            21.020099390958233
          ],
          [
            105.81134543592759,
            21.02009344461682
          ],
          [
            105.8111375647108,
            21.02018545632494
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 16/27 Huỳnh Thúc Kháng",
        "diemDau": "06",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.020099390958233,
        "to_latitude": 21.02018545632494,
        "to_longitude": 105.8111375647108,
        "from_longitude": 105.81143294294843,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4047,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81103027634722,
          21.019995173556136,
          105.8111375647108,
          21.02018545632494
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8111375647108,
            21.02018545632494
          ],
          [
            105.81103027634722,
            21.019995173556136
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 16/27 Huỳnh Thúc Kháng",
        "diemDau": "09",
        "diemCuoi": "9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.02018545632494,
        "to_latitude": 21.019995173556136,
        "to_longitude": 105.81103027634722,
        "from_longitude": 105.8111375647108,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4048,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81090689473722,
          21.02018545632494,
          105.8111375647108,
          21.0202931162049
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8111375647108,
            21.02018545632494
          ],
          [
            105.81090689473722,
            21.0202931162049
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 16/27 Huỳnh Thúc Kháng",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.02018545632494,
        "to_latitude": 21.0202931162049,
        "to_longitude": 105.81090689473722,
        "from_longitude": 105.8111375647108,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4049,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81067890697132,
          21.0202931162049,
          105.81090689473722,
          21.02041830201414
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81090689473722,
            21.0202931162049
          ],
          [
            105.81083447511021,
            21.020323786732522
          ],
          [
            105.81067890697132,
            21.02041830201414
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 16/27 Huỳnh Thúc Kháng",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.0202931162049,
        "to_latitude": 21.02041830201414,
        "to_longitude": 105.81067890697132,
        "from_longitude": 105.81090689473722,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4050,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81043214374228,
          21.02041830201414,
          105.81067890697132,
          21.020554754426136
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81067890697132,
            21.02041830201414
          ],
          [
            105.81043214374228,
            21.020554754426136
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 16/27 Huỳnh Thúc Kháng",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.02041830201414,
        "to_latitude": 21.020554754426136,
        "to_longitude": 105.81043214374228,
        "from_longitude": 105.81067890697132,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4051,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81062157475614,
          21.01960490534213,
          105.81108660274575,
          21.019888452357524
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8110654803171,
            21.01960490534213
          ],
          [
            105.81108660274575,
            21.0196728189651
          ],
          [
            105.81090018921783,
            21.019760449406572
          ],
          [
            105.81086532049922,
            21.019777975488854
          ],
          [
            105.81066549593261,
            21.01987937635232
          ],
          [
            105.81062157475614,
            21.019888452357524
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 56,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Nguyễn Chí Thanh",
        "diemDau": "04",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.01960490534213,
        "to_latitude": 21.019888452357524,
        "to_longitude": 105.81062157475614,
        "from_longitude": 105.8110654803171,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4052,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81062157475614,
          21.019686902434575,
          105.8108894603779,
          21.019888452357524
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81062157475614,
            21.019888452357524
          ],
          [
            105.8108894603779,
            21.019740419596083
          ],
          [
            105.81085693860676,
            21.019686902434575
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Nguyễn Chí Thanh",
        "diemDau": "16",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.019888452357524,
        "to_latitude": 21.019686902434575,
        "to_longitude": 105.81085693860676,
        "from_longitude": 105.81062157475614,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4053,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.810342289738,
          21.019888452357524,
          105.81062157475614,
          21.01997076226218
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81062157475614,
            21.019888452357524
          ],
          [
            105.81045896583767,
            21.019970762257152
          ],
          [
            105.810342289738,
            21.01997076226218
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Nguyễn Chí Thanh",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.019888452357524,
        "to_latitude": 21.01997076226218,
        "to_longitude": 105.810342289738,
        "from_longitude": 105.81062157475614,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4054,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81013039522757,
          21.019970762257152,
          105.810342289738,
          21.02007717043648
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.810342289738,
            21.01997076226218
          ],
          [
            105.81023231917568,
            21.019970762257152
          ],
          [
            105.81013039522757,
            21.020009569953146
          ],
          [
            105.81016794615394,
            21.02007717043648
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Nguyễn Chí Thanh",
        "diemDau": "17",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.01997076226218,
        "to_latitude": 21.02007717043648,
        "to_longitude": 105.81016794615394,
        "from_longitude": 105.810342289738,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4055,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81016794615394,
          21.02007717043648,
          105.81030443189647,
          21.020286430342537
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81016794615394,
            21.02007717043648
          ],
          [
            105.81019476824932,
            21.02009720019755
          ],
          [
            105.81030443189647,
            21.020286430342537
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Nguyễn Chí Thanh",
        "diemDau": "14",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.02007717043648,
        "to_latitude": 21.020286430342537,
        "to_longitude": 105.81030443189647,
        "from_longitude": 105.81016794615394,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4056,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80984071665671,
          21.020009569953146,
          105.81013039522757,
          21.020163548781213
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81013039522757,
            21.020009569953146
          ],
          [
            105.8100888209896,
            21.02002959972748
          ],
          [
            105.81007675105477,
            21.020045873918022
          ],
          [
            105.80984071665671,
            21.020163548781213
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 52,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Nguyễn Chí Thanh",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.020009569953146,
        "to_latitude": 21.020163548781213,
        "to_longitude": 105.80984071665671,
        "from_longitude": 105.81013039522757,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4057,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80961675221141,
          21.020163548781213,
          105.80984071665671,
          21.020281223545663
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80984071665671,
            21.020163548781213
          ],
          [
            105.80961675221141,
            21.020281223545663
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Nguyễn Chí Thanh",
        "diemDau": "18",
        "diemCuoi": "19-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.020163548781213,
        "to_latitude": 21.020281223545663,
        "to_longitude": 105.80961675221141,
        "from_longitude": 105.80984071665671,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4058,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8094276564668,
          21.020281223545663,
          105.80961675221141,
          21.02037135734684
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80961675221141,
            21.020281223545663
          ],
          [
            105.8094276564668,
            21.02037135734684
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Nguyễn Chí Thanh",
        "diemDau": "19-2",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.020281223545663,
        "to_latitude": 21.02037135734684,
        "to_longitude": 105.8094276564668,
        "from_longitude": 105.80961675221141,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4059,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80917318188375,
          21.02037135734684,
          105.8094276564668,
          21.020509374623344
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8094276564668,
            21.02037135734684
          ],
          [
            105.80917318188375,
            21.020509374623344
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Nguyễn Chí Thanh",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.02037135734684,
        "to_latitude": 21.020509374623344,
        "to_longitude": 105.80917318188375,
        "from_longitude": 105.8094276564668,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4060,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80902060128378,
          21.020323332149264,
          105.80917318188375,
          21.02052658764896
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80917318188375,
            21.020509374623344
          ],
          [
            105.80913663679203,
            21.02052658764896
          ],
          [
            105.80902060128378,
            21.020323332149264
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x2,5",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Nguyễn Chí Thanh",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.020509374623344,
        "to_latitude": 21.020323332149264,
        "to_longitude": 105.80902060128378,
        "from_longitude": 105.80917318188375,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4061,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.810342289738,
          21.01997076226218,
          105.81055820757801,
          21.020163548780385
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.810342289738,
            21.01997076226218
          ],
          [
            105.81047640019698,
            21.02001958484106
          ],
          [
            105.81055820757801,
            21.020163548780385
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x2,5",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Nguyễn Chí Thanh",
        "diemDau": "17",
        "diemCuoi": "11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.01997076226218,
        "to_latitude": 21.020163548780385,
        "to_longitude": 105.81055820757801,
        "from_longitude": 105.810342289738,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4062,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81062157475614,
          21.019888452357524,
          105.81078351313614,
          21.02006590369168
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81062157475614,
            21.019888452357524
          ],
          [
            105.81069500022676,
            21.019918184072104
          ],
          [
            105.81078351313614,
            21.02006590369168
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x2,5",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "G22B",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Nguyễn Chí Thanh",
        "diemDau": "16",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "G22B",
        "from_latitude": 21.019888452357524,
        "to_latitude": 21.02006590369168,
        "to_longitude": 105.81078351313614,
        "from_longitude": 105.81062157475614,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4063,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83858225010826,
          21.015357488010814,
          105.8386037077792,
          21.01537751841
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83858225010826,
            21.015357488010814
          ],
          [
            105.8386037077792,
            21.01537751841
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015357488010814,
        "to_latitude": 21.01537751841,
        "to_longitude": 105.8386037077792,
        "from_longitude": 105.83858225010826,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4064,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83846696223807,
          21.01537751841,
          105.8386037077792,
          21.015506904753746
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8386037077792,
            21.01537751841
          ],
          [
            105.83846696223807,
            21.015506904753746
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.01537751841,
        "to_latitude": 21.015506904753746,
        "to_longitude": 105.83846696223807,
        "from_longitude": 105.8386037077792,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4065,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83832346405202,
          21.015506904753746,
          105.83846696223807,
          21.015649621147674
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83846696223807,
            21.015506904753746
          ],
          [
            105.83832346405202,
            21.015649621147674
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015506904753746,
        "to_latitude": 21.015649621147674,
        "to_longitude": 105.83832346405202,
        "from_longitude": 105.83846696223807,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4066,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83846696223807,
          21.015474355380977,
          105.83867076300866,
          21.015602860219122
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83846696223807,
            21.015506904753746
          ],
          [
            105.83852999414697,
            21.015474355380977
          ],
          [
            105.83867076300866,
            21.015602860219122
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "26",
        "diemCuoi": "26-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015506904753746,
        "to_latitude": 21.015602860219122,
        "to_longitude": 105.83867076300866,
        "from_longitude": 105.83846696223807,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4067,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8386037077792,
          21.015287774028035,
          105.8388066681585,
          21.01537751841
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8386037077792,
            21.01537751841
          ],
          [
            105.8388066681585,
            21.015287774028035
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "25",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.01537751841,
        "to_latitude": 21.015287774028035,
        "to_longitude": 105.8388066681585,
        "from_longitude": 105.8386037077792,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4068,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8388066681585,
          21.015098785187487,
          105.83908252921132,
          21.015287774028035
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8388066681585,
            21.015287774028035
          ],
          [
            105.8390168150857,
            21.015130082739162
          ],
          [
            105.83908252921132,
            21.015098785187487
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "24",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015287774028035,
        "to_latitude": 21.015098785187487,
        "to_longitude": 105.83908252921132,
        "from_longitude": 105.8388066681585,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4069,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83889606856678,
          21.015024482225787,
          105.83908252921132,
          21.015130082739162
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83908252921132,
            21.015098785187487
          ],
          [
            105.8390168150857,
            21.015130082739162
          ],
          [
            105.83889606856678,
            21.015024482225787
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "23",
        "diemCuoi": "23-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015098785187487,
        "to_latitude": 21.015024482225787,
        "to_longitude": 105.83889606856678,
        "from_longitude": 105.83908252921132,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4070,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83904766049271,
          21.014912251639252,
          105.83908252921132,
          21.015098785187487
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83908252921132,
            21.015098785187487
          ],
          [
            105.83904766049271,
            21.014912251639252
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "23",
        "diemCuoi": "23-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015098785187487,
        "to_latitude": 21.014912251639252,
        "to_longitude": 105.83904766049271,
        "from_longitude": 105.83908252921132,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4071,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83908252921132,
          21.015098785187487,
          105.83917826851979,
          21.015284251681955
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83908252921132,
            21.015098785187487
          ],
          [
            105.83917826851979,
            21.015284251681955
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "23",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015098785187487,
        "to_latitude": 21.015284251681955,
        "to_longitude": 105.83917826851979,
        "from_longitude": 105.83908252921132,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4072,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83904766049271,
          21.014839641201952,
          105.83913483228025,
          21.014912251639252
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83904766049271,
            21.014912251639252
          ],
          [
            105.83913483228025,
            21.014839641201952
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "23-2",
        "diemCuoi": "23-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.014912251639252,
        "to_latitude": 21.014839641201952,
        "to_longitude": 105.83913483228025,
        "from_longitude": 105.83904766049271,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4073,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83889075125894,
          21.014763275014264,
          105.83904766049271,
          21.014912251639252
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83904766049271,
            21.014912251639252
          ],
          [
            105.83889075125894,
            21.014763275014264
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "23-2",
        "diemCuoi": "23-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.014912251639252,
        "to_latitude": 21.014763275014264,
        "to_longitude": 105.83889075125894,
        "from_longitude": 105.83904766049271,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4074,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83913483228025,
          21.01450550433472,
          105.83929229486073,
          21.014839641201952
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83913483228025,
            21.014839641201952
          ],
          [
            105.83928771819342,
            21.01462556539636
          ],
          [
            105.83929229486073,
            21.01450550433472
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "23-3",
        "diemCuoi": "23-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.014839641201952,
        "to_latitude": 21.01450550433472,
        "to_longitude": 105.83929229486073,
        "from_longitude": 105.83913483228025,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4075,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83913483228025,
          21.01462556539636,
          105.83946742620195,
          21.014839641201952
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83913483228025,
            21.014839641201952
          ],
          [
            105.83928771819342,
            21.01462556539636
          ],
          [
            105.83943121637947,
            21.01468690878753
          ],
          [
            105.83946742620195,
            21.01462806920837
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 56,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "23-3",
        "diemCuoi": "23-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.014839641201952,
        "to_latitude": 21.01462806920837,
        "to_longitude": 105.83946742620195,
        "from_longitude": 105.83913483228025,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4076,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83908252921132,
          21.014994877266616,
          105.83922602738839,
          21.015098785187487
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83908252921132,
            21.015098785187487
          ],
          [
            105.83922602738839,
            21.014994877266616
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "23",
        "diemCuoi": "23-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015098785187487,
        "to_latitude": 21.014994877266616,
        "to_longitude": 105.83922602738839,
        "from_longitude": 105.83908252921132,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4077,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83917826851979,
          21.015284251681955,
          105.83927707454967,
          21.015452447145975
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83917826851979,
            21.015284251681955
          ],
          [
            105.83927707454967,
            21.015452447145975
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "22",
        "diemCuoi": "22-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015284251681955,
        "to_latitude": 21.015452447145975,
        "to_longitude": 105.83927707454967,
        "from_longitude": 105.83917826851979,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4078,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83917826851979,
          21.01527780709761,
          105.83934002127133,
          21.015284251681955
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83917826851979,
            21.015284251681955
          ],
          [
            105.83934002127133,
            21.01527780709761
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "22",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015284251681955,
        "to_latitude": 21.01527780709761,
        "to_longitude": 105.83934002127133,
        "from_longitude": 105.83917826851979,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4079,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83934002127133,
          21.01527780709761,
          105.8394406892938,
          21.015399867368664
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83934002127133,
            21.01527780709761
          ],
          [
            105.8394406892938,
            21.015399867368664
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "21",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.01527780709761,
        "to_latitude": 21.015399867368664,
        "to_longitude": 105.8394406892938,
        "from_longitude": 105.83934002127133,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4080,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8394406892938,
          21.015399867368664,
          105.83951310893876,
          21.015484996523515
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8394406892938,
            21.015399867368664
          ],
          [
            105.83951310893876,
            21.015484996523515
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "20",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015399867368664,
        "to_latitude": 21.015484996523515,
        "to_longitude": 105.83951310893876,
        "from_longitude": 105.8394406892938,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4081,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83951310893876,
          21.015315240013276,
          105.83968687920468,
          21.015484996523515
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83951310893876,
            21.015484996523515
          ],
          [
            105.83968687920468,
            21.015315240013276
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015484996523515,
        "to_latitude": 21.015315240013276,
        "to_longitude": 105.83968687920468,
        "from_longitude": 105.83951310893876,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4082,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83968687920468,
          21.01512737358295,
          105.83976420213882,
          21.015315240013276
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83968687920468,
            21.015315240013276
          ],
          [
            105.83976420213882,
            21.01512737358295
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "10-1",
        "diemCuoi": "10-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015315240013276,
        "to_latitude": 21.01512737358295,
        "to_longitude": 105.83976420213882,
        "from_longitude": 105.83968687920468,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4083,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83976420213882,
          21.015026800792914,
          105.83978937647095,
          21.01512737358295
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83976420213882,
            21.01512737358295
          ],
          [
            105.83978937647095,
            21.015026800792914
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "10-2",
        "diemCuoi": "10-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.01512737358295,
        "to_latitude": 21.015026800792914,
        "to_longitude": 105.83978937647095,
        "from_longitude": 105.83976420213882,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4084,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80785990523312,
          21.01958957000432,
          105.80799133347533,
          21.01981740917358
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80799133347533,
            21.01981740917358
          ],
          [
            105.80785990523312,
            21.01958957000432
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "02",
        "diemCuoi": "LT5 01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT5",
        "from_latitude": 21.01981740917358,
        "to_latitude": 21.01958957000432,
        "to_longitude": 105.80785990523312,
        "from_longitude": 105.80799133347533,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4085,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8077365236141,
          21.01937550101727,
          105.80785990523312,
          21.01958957000432
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80785990523312,
            21.01958957000432
          ],
          [
            105.8077365236141,
            21.01937550101727
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT5",
        "from_latitude": 21.01958957000432,
        "to_latitude": 21.01937550101727,
        "to_longitude": 105.8077365236141,
        "from_longitude": 105.80785990523312,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4086,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80762387083503,
          21.019168942931206,
          105.8077365236141,
          21.01937550101727
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8077365236141,
            21.01937550101727
          ],
          [
            105.80762387083503,
            21.019168942931206
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "04",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT5",
        "from_latitude": 21.01937550101727,
        "to_latitude": 21.019168942931206,
        "to_longitude": 105.80762387083503,
        "from_longitude": 105.8077365236141,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4087,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80750853584823,
          21.018967392038213,
          105.80762387083503,
          21.019168942931206
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80762387083503,
            21.019168942931206
          ],
          [
            105.80750853584823,
            21.018967392038213
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "05",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT5",
        "from_latitude": 21.019168942931206,
        "to_latitude": 21.018967392038213,
        "to_longitude": 105.80750853584823,
        "from_longitude": 105.80762387083503,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4088,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80736637875704,
          21.01873955157097,
          105.80750853584823,
          21.018967392038213
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80750853584823,
            21.018967392038213
          ],
          [
            105.80736637875704,
            21.01873955157097
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "06",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT5",
        "from_latitude": 21.018967392038213,
        "to_latitude": 21.01873955157097,
        "to_longitude": 105.80736637875704,
        "from_longitude": 105.80750853584823,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4089,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80723897383541,
          21.01851045888093,
          105.80736637875704,
          21.01873955157097
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80736637875704,
            21.01873955157097
          ],
          [
            105.80723897383541,
            21.01851045888093
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "07",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT5",
        "from_latitude": 21.01873955157097,
        "to_latitude": 21.01851045888093,
        "to_longitude": 105.80723897383541,
        "from_longitude": 105.80736637875704,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4090,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80709815785708,
          21.01827135083513,
          105.80723897383541,
          21.01851045888093
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80723897383541,
            21.01851045888093
          ],
          [
            105.80709815785708,
            21.01827135083513
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "08",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT5",
        "from_latitude": 21.01851045888093,
        "to_latitude": 21.01827135083513,
        "to_longitude": 105.80709815785708,
        "from_longitude": 105.80723897383541,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4091,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8069640474071,
          21.018041005548877,
          105.80709815785708,
          21.01827135083513
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80709815785708,
            21.01827135083513
          ],
          [
            105.8069640474071,
            21.018041005548877
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "09",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT5",
        "from_latitude": 21.01827135083513,
        "to_latitude": 21.018041005548877,
        "to_longitude": 105.8069640474071,
        "from_longitude": 105.80709815785708,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4092,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80682457254163,
          21.01780815614737,
          105.8069640474071,
          21.018041005548877
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8069640474071,
            21.018041005548877
          ],
          [
            105.80682457254163,
            21.01780815614737
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT5",
        "from_latitude": 21.018041005548877,
        "to_latitude": 21.01780815614737,
        "to_longitude": 105.80682457254163,
        "from_longitude": 105.8069640474071,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4093,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80667705104398,
          21.017546513106783,
          105.80682457254163,
          21.01780815614737
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80682457254163,
            21.01780815614737
          ],
          [
            105.80667705104398,
            21.017546513106783
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT5",
        "from_latitude": 21.01780815614737,
        "to_latitude": 21.017546513106783,
        "to_longitude": 105.80667705104398,
        "from_longitude": 105.80682457254163,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4094,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80653087065015,
          21.017329937419284,
          105.80667705104398,
          21.017546513106783
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80667705104398,
            21.017546513106783
          ],
          [
            105.80653087065015,
            21.017329937419284
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT5",
        "from_latitude": 21.017546513106783,
        "to_latitude": 21.017329937419284,
        "to_longitude": 105.80653087065015,
        "from_longitude": 105.80667705104398,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4095,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80636457368932,
          21.017104598220634,
          105.80653087065015,
          21.017329937419284
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80653087065015,
            21.017329937419284
          ],
          [
            105.80636457368932,
            21.017104598220634
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT5",
        "from_latitude": 21.017329937419284,
        "to_latitude": 21.017104598220634,
        "to_longitude": 105.80636457368932,
        "from_longitude": 105.80653087065015,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4096,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8061526791789,
          21.016878006793092,
          105.80636457368932,
          21.017104598220634
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80636457368932,
            21.017104598220634
          ],
          [
            105.8061526791789,
            21.016878006793092
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT5",
        "from_latitude": 21.017104598220634,
        "to_latitude": 21.016878006793092,
        "to_longitude": 105.8061526791789,
        "from_longitude": 105.80636457368932,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4097,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80590725705369,
          21.016641399908814,
          105.8061526791789,
          21.016878006793092
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8061526791789,
            21.016878006793092
          ],
          [
            105.80590725705369,
            21.016641399908814
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT5",
        "from_latitude": 21.016878006793092,
        "to_latitude": 21.016641399908814,
        "to_longitude": 105.80590725705369,
        "from_longitude": 105.8061526791789,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4098,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80571011469485,
          21.01644610505382,
          105.80590725705369,
          21.016641399908814
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80590725705369,
            21.016641399908814
          ],
          [
            105.80571011469485,
            21.01644610505382
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT5",
        "from_latitude": 21.016641399908814,
        "to_latitude": 21.01644610505382,
        "to_longitude": 105.80571011469485,
        "from_longitude": 105.80590725705369,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4099,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8054673747774,
          21.01622076451966,
          105.80571011469485,
          21.01644610505382
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80571011469485,
            21.01644610505382
          ],
          [
            105.8054673747774,
            21.01622076451966
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT5",
        "from_latitude": 21.01644610505382,
        "to_latitude": 21.01622076451966,
        "to_longitude": 105.8054673747774,
        "from_longitude": 105.80571011469485,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4100,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80524475142701,
          21.016024217219154,
          105.8054673747774,
          21.01622076451966
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8054673747774,
            21.01622076451966
          ],
          [
            105.8053859027105,
            21.016138452542602
          ],
          [
            105.80530409533843,
            21.01606459080789
          ],
          [
            105.80524475142701,
            21.016024217219154
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT5",
        "from_latitude": 21.01622076451966,
        "to_latitude": 21.016024217219154,
        "to_longitude": 105.80524475142701,
        "from_longitude": 105.8054673747774,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4101,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80501944586888,
          21.015912798571133,
          105.80524475142701,
          21.016024217219154
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80524475142701,
            21.016024217219154
          ],
          [
            105.8051766904078,
            21.015980713878957
          ],
          [
            105.80512572843377,
            21.01595442408531
          ],
          [
            105.80506940204424,
            21.015929386182542
          ],
          [
            105.80501944586888,
            21.015912798571133
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "18",
        "diemCuoi": "LT5 19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT5",
        "from_latitude": 21.016024217219154,
        "to_latitude": 21.015912798571133,
        "to_longitude": 105.80501944586888,
        "from_longitude": 105.80524475142701,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4102,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80682457254163,
          21.017892345015962,
          105.8069640474071,
          21.018041005548877
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8069640474071,
            21.018041005548877
          ],
          [
            105.80685307104605,
            21.017892345015962
          ],
          [
            105.80682457254163,
            21.01789954331991
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "Tủ LT5",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT5",
        "from_latitude": 21.018041005548877,
        "to_latitude": 21.01789954331991,
        "to_longitude": 105.80682457254163,
        "from_longitude": 105.8069640474071,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4103,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80664620564593,
          21.017546513106783,
          105.80667705104398,
          21.017639152323333
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80667705104398,
            21.017546513106783
          ],
          [
            105.80664620564593,
            21.017639152323333
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT5",
        "from_latitude": 21.017546513106783,
        "to_latitude": 21.017639152323333,
        "to_longitude": 105.80664620564593,
        "from_longitude": 105.80667705104398,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4104,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80649600194054,
          21.017639152323333,
          105.80664620564593,
          21.017728035840324
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80664620564593,
            21.017639152323333
          ],
          [
            105.80649600194054,
            21.017728035840324
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "diemDau": "11-1",
        "diemCuoi": "11-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT5",
        "from_latitude": 21.017639152323333,
        "to_latitude": 21.017728035840324,
        "to_longitude": 105.80649600194054,
        "from_longitude": 105.80664620564593,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4105,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80633909271575,
          21.01754150558153,
          105.80649600194054,
          21.017728035840324
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80649600194054,
            21.017728035840324
          ],
          [
            105.80633909271575,
            21.01754150558153
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "diemDau": "11-2",
        "diemCuoi": "11-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT5",
        "from_latitude": 21.017728035840324,
        "to_latitude": 21.01754150558153,
        "to_longitude": 105.80633909271575,
        "from_longitude": 105.80649600194054,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4106,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80618486569873,
          21.017343708138927,
          105.80633909271575,
          21.01754150558153
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80633909271575,
            21.01754150558153
          ],
          [
            105.80618486569873,
            21.017343708138927
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "diemDau": "11-3",
        "diemCuoi": "11-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT5",
        "from_latitude": 21.01754150558153,
        "to_latitude": 21.017343708138927,
        "to_longitude": 105.80618486569873,
        "from_longitude": 105.80633909271575,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4107,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80603332088944,
          21.017137147238405,
          105.80618486569873,
          21.017343708138927
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80618486569873,
            21.017343708138927
          ],
          [
            105.80603332088944,
            21.017137147238405
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "diemDau": "11-4",
        "diemCuoi": "11-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT5",
        "from_latitude": 21.017343708138927,
        "to_latitude": 21.017137147238405,
        "to_longitude": 105.80603332088944,
        "from_longitude": 105.80618486569873,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4108,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80672130749865,
          21.01780815614737,
          105.80682457254163,
          21.017883268898448
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80682457254163,
            21.01780815614737
          ],
          [
            105.80672130749865,
            21.017883268898448
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 72 Nguyễn Chí Thanh",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT5",
        "from_latitude": 21.01780815614737,
        "to_latitude": 21.017883268898448,
        "to_longitude": 105.80672130749865,
        "from_longitude": 105.80682457254163,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4109,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80629617737392,
          21.017883268898448,
          105.80672130749865,
          21.01816494137384
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80672130749865,
            21.017883268898448
          ],
          [
            105.80629617737392,
            21.01816494137384
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 72 Nguyễn Chí Thanh",
        "diemDau": "10-1",
        "diemCuoi": "10-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT5",
        "from_latitude": 21.017883268898448,
        "to_latitude": 21.01816494137384,
        "to_longitude": 105.80629617737392,
        "from_longitude": 105.80672130749865,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4110,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80701903271074,
          21.018724842073908,
          105.80736637875704,
          21.01887005912991
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80736637875704,
            21.01873955157097
          ],
          [
            105.80730234104946,
            21.018724842073908
          ],
          [
            105.80702875573401,
            21.01887005912991
          ],
          [
            105.80701903271074,
            21.018858479332646
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG5",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 68 Nguyễn Chí Thanh",
        "diemDau": "06",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT5",
        "from_latitude": 21.01873955157097,
        "to_latitude": 21.018858479332646,
        "to_longitude": 105.80701903271074,
        "from_longitude": 105.80736637875704,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4111,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83937588658992,
          21.015484996523515,
          105.83951310893876,
          21.015775912664644
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83951310893876,
            21.015484996523515
          ],
          [
            105.83937588658992,
            21.015775912664644
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lan Bá",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015484996523515,
        "to_latitude": 21.015775912664644,
        "to_longitude": 105.83937588658992,
        "from_longitude": 105.83951310893876,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4112,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83924177613993,
          21.015775912664644,
          105.83937588658992,
          21.016076367531333
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83937588658992,
            21.015775912664644
          ],
          [
            105.83924177613993,
            21.016076367531333
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lan Bá",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015775912664644,
        "to_latitude": 21.016076367531333,
        "to_longitude": 105.83924177613993,
        "from_longitude": 105.83937588658992,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4113,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83896819081549,
          21.016076367531333,
          105.83924177613993,
          21.01618903795049
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83924177613993,
            21.016076367531333
          ],
          [
            105.83896819081549,
            21.01618903795049
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lan Bá",
        "diemDau": "12",
        "diemCuoi": "12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.016076367531333,
        "to_latitude": 21.01618903795049,
        "to_longitude": 105.83896819081549,
        "from_longitude": 105.83924177613993,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4114,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83891722884144,
          21.016081375107433,
          105.83896819081549,
          21.01618903795049
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83896819081549,
            21.01618903795049
          ],
          [
            105.83891722884144,
            21.016081375107433
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lan Bá",
        "diemDau": "12-1",
        "diemCuoi": "12-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.01618903795049,
        "to_latitude": 21.016081375107433,
        "to_longitude": 105.83891722884144,
        "from_longitude": 105.83896819081549,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4115,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83880189385464,
          21.016081375107433,
          105.83891722884144,
          21.016117680028664
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83891722884144,
            21.016081375107433
          ],
          [
            105.83884346811955,
            21.016117680028664
          ],
          [
            105.83880189385464,
            21.016096397834797
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lan Bá",
        "diemDau": "12-4",
        "diemCuoi": "12-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.016081375107433,
        "to_latitude": 21.016096397834797,
        "to_longitude": 105.83880189385464,
        "from_longitude": 105.83891722884144,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4116,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83896819081549,
          21.016174202872076,
          105.839024517205,
          21.016201556880578
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83896819081549,
            21.01618903795049
          ],
          [
            105.8390042132347,
            21.016174202872076
          ],
          [
            105.839024517205,
            21.016201556880578
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lan Bá",
        "diemDau": "12-1",
        "diemCuoi": "12-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.01618903795049,
        "to_latitude": 21.016201556880578,
        "to_longitude": 105.839024517205,
        "from_longitude": 105.83896819081549,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4117,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83869460550002,
          21.01618903795049,
          105.83896819081549,
          21.016266655300747
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83896819081549,
            21.01618903795049
          ],
          [
            105.83869460550002,
            21.016266655300747
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lan Bá",
        "diemDau": "12-1",
        "diemCuoi": "12-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.01618903795049,
        "to_latitude": 21.016266655300747,
        "to_longitude": 105.83869460550002,
        "from_longitude": 105.83896819081549,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4118,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83924177613993,
          21.016076367531333,
          105.83931687800161,
          21.01622158716726
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83924177613993,
            21.016076367531333
          ],
          [
            105.83931687800161,
            21.01622158716726
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lan Bá",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.016076367531333,
        "to_latitude": 21.01622158716726,
        "to_longitude": 105.83931687800161,
        "from_longitude": 105.83924177613993,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4119,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83931687800161,
          21.01622158716726,
          105.83935442892796,
          21.016409370968475
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83931687800161,
            21.01622158716726
          ],
          [
            105.83935442892796,
            21.016409370968475
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lan Bá",
        "diemDau": "13",
        "diemCuoi": "13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.01622158716726,
        "to_latitude": 21.016409370968475,
        "to_longitude": 105.83935442892796,
        "from_longitude": 105.83931687800161,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4120,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83935442892796,
          21.016409370968475,
          105.83944562403612,
          21.016592146975277
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83935442892796,
            21.016409370968475
          ],
          [
            105.83944562403612,
            21.016592146975277
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lan Bá",
        "diemDau": "13-1",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.016409370968475,
        "to_latitude": 21.016592146975277,
        "to_longitude": 105.83944562403612,
        "from_longitude": 105.83935442892796,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4121,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83944562403612,
          21.016592146975277,
          105.83957705227834,
          21.016759900099075
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83944562403612,
            21.016592146975277
          ],
          [
            105.83957705227834,
            21.016759900099075
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lan Bá",
        "diemDau": "14",
        "diemCuoi": "14-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.016592146975277,
        "to_latitude": 21.016759900099075,
        "to_longitude": 105.83957705227834,
        "from_longitude": 105.83944562403612,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4122,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83957705227834,
          21.016759900099075,
          105.83972189156829,
          21.01687257000097
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83957705227834,
            21.016759900099075
          ],
          [
            105.83972189156829,
            21.01687257000097
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lan Bá",
        "diemDau": "14-1",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.016759900099075,
        "to_latitude": 21.01687257000097,
        "to_longitude": 105.83972189156829,
        "from_longitude": 105.83957705227834,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4123,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83972189156829,
          21.01687257000097,
          105.83976480691015,
          21.017090398238444
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83972189156829,
            21.01687257000097
          ],
          [
            105.83976480691015,
            21.017090398238444
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lan Bá",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.01687257000097,
        "to_latitude": 21.017090398238444,
        "to_longitude": 105.83976480691015,
        "from_longitude": 105.83972189156829,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4124,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83976480691015,
          21.017090398238444,
          105.83979982465588,
          21.017329050054567
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83976480691015,
            21.017090398238444
          ],
          [
            105.83979982465588,
            21.017329050054567
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lan Bá",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.017090398238444,
        "to_latitude": 21.017329050054567,
        "to_longitude": 105.83979982465588,
        "from_longitude": 105.83976480691015,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4125,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83979982465588,
          21.017329050054567,
          105.83985868422602,
          21.017581136548152
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83979982465588,
            21.017329050054567
          ],
          [
            105.83985868422602,
            21.017581136548152
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lan Bá",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.017329050054567,
        "to_latitude": 21.017581136548152,
        "to_longitude": 105.83985868422602,
        "from_longitude": 105.83979982465588,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4126,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83985868422602,
          21.017581136548152,
          105.83991266190563,
          21.017842212356342
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83985868422602,
            21.017581136548152
          ],
          [
            105.83991266190563,
            21.017842212356342
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Lan Bá",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.017581136548152,
        "to_latitude": 21.017842212356342,
        "to_longitude": 105.83991266190563,
        "from_longitude": 105.83985868422602,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4127,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81093271097099,
          21.02420543601755,
          105.81117813306923,
          21.024599760612166
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81117813306923,
            21.024599760612166
          ],
          [
            105.81093271097099,
            21.02420543601755
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "02",
        "diemCuoi": "TCĐC 01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.024599760612166,
        "to_latitude": 21.02420543601755,
        "to_longitude": 105.81093271097099,
        "from_longitude": 105.81117813306923,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4128,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81073959192373,
          21.023857428048323,
          105.81093271097099,
          21.02420543601755
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81093271097099,
            21.02420543601755
          ],
          [
            105.81073959192373,
            21.023857428048323
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.02420543601755,
        "to_latitude": 21.023857428048323,
        "to_longitude": 105.81073959192373,
        "from_longitude": 105.81093271097099,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4129,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81060548147376,
          21.023632098713744,
          105.81073959192373,
          21.023857428048323
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81073959192373,
            21.023857428048323
          ],
          [
            105.81060548147376,
            21.023632098713744
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "04",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.023857428048323,
        "to_latitude": 21.023632098713744,
        "to_longitude": 105.81060548147376,
        "from_longitude": 105.81073959192373,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4130,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81046868880705,
          21.023389243383647,
          105.81060548147376,
          21.023632098713744
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81060548147376,
            21.023632098713744
          ],
          [
            105.81046868880705,
            21.023389243383647
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "05",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.023632098713744,
        "to_latitude": 21.023389243383647,
        "to_longitude": 105.81046868880705,
        "from_longitude": 105.81060548147376,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4131,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81032116731836,
          21.02313637297923,
          105.81046868880705,
          21.023389243383647
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81046868880705,
            21.023389243383647
          ],
          [
            105.81032116731836,
            21.02313637297923
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "06",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.023389243383647,
        "to_latitude": 21.02313637297923,
        "to_longitude": 105.81032116731836,
        "from_longitude": 105.81046868880705,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4132,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81018705685939,
          21.022913546228494,
          105.81032116731836,
          21.02313637297923
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81032116731836,
            21.02313637297923
          ],
          [
            105.81018705685939,
            21.022913546228494
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "07",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.02313637297923,
        "to_latitude": 21.022913546228494,
        "to_longitude": 105.81018705685939,
        "from_longitude": 105.81032116731836,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4133,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81004758199393,
          21.022663178695982,
          105.81018705685939,
          21.022913546228494
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81018705685939,
            21.022913546228494
          ],
          [
            105.81004758199393,
            21.022663178695982
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "08",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.022913546228494,
        "to_latitude": 21.022663178695982,
        "to_longitude": 105.81004758199393,
        "from_longitude": 105.81018705685939,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4134,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80994297584706,
          21.022482913811814,
          105.81004758199393,
          21.022663178695982
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81004758199393,
            21.022663178695982
          ],
          [
            105.80994297584706,
            21.022482913811814
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.022663178695982,
        "to_latitude": 21.022482913811814,
        "to_longitude": 105.80994297584706,
        "from_longitude": 105.81004758199393,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4135,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80981691202031,
          21.022260086084366,
          105.80994297584706,
          21.022482913811814
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80994297584706,
            21.022482913811814
          ],
          [
            105.80981691202031,
            21.022260086084366
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.022482913811814,
        "to_latitude": 21.022260086084366,
        "to_longitude": 105.80981691202031,
        "from_longitude": 105.80994297584706,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4136,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80967743715485,
          21.02204226539903,
          105.80981691202031,
          21.022260086084366
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80981691202031,
            21.022260086084366
          ],
          [
            105.80967743715485,
            21.02204226539903
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.022260086084366,
        "to_latitude": 21.02204226539903,
        "to_longitude": 105.80967743715485,
        "from_longitude": 105.80981691202031,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4137,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80955137332808,
          21.021814429629593,
          105.80967743715485,
          21.02204226539903
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80967743715485,
            21.02204226539903
          ],
          [
            105.80955137332808,
            21.021814429629593
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.02204226539903,
        "to_latitude": 21.021814429629593,
        "to_longitude": 105.80955137332808,
        "from_longitude": 105.80967743715485,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4138,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80942530950134,
          21.021589097207944,
          105.80955137332808,
          21.021814429629593
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80955137332808,
            21.021814429629593
          ],
          [
            105.80942530950134,
            21.021589097207944
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.021814429629593,
        "to_latitude": 21.021589097207944,
        "to_longitude": 105.80942530950134,
        "from_longitude": 105.80955137332808,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4139,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80930461009905,
          21.021363764444903,
          105.80942530950134,
          21.021589097207944
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80942530950134,
            21.021589097207944
          ],
          [
            105.80930461009905,
            21.021363764444903
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.021589097207944,
        "to_latitude": 21.021363764444903,
        "to_longitude": 105.80930461009905,
        "from_longitude": 105.80942530950134,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4140,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80917586406456,
          21.021125912826495,
          105.80930461009905,
          21.021363764444903
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80930461009905,
            21.021363764444903
          ],
          [
            105.80917586406456,
            21.021125912826495
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.021363764444903,
        "to_latitude": 21.021125912826495,
        "to_longitude": 105.80917586406456,
        "from_longitude": 105.80930461009905,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4141,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80903102477463,
          21.02088806082782,
          105.80917586406456,
          21.021125912826495
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80917586406456,
            21.021125912826495
          ],
          [
            105.80903102477463,
            21.02088806082782
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.021125912826495,
        "to_latitude": 21.02088806082782,
        "to_longitude": 105.80903102477463,
        "from_longitude": 105.80917586406456,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4142,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80889020879629,
          21.020643949171916,
          105.80903102477463,
          21.02088806082782
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80903102477463,
            21.02088806082782
          ],
          [
            105.80889020879629,
            21.020643949171916
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.02088806082782,
        "to_latitude": 21.020643949171916,
        "to_longitude": 105.80889020879629,
        "from_longitude": 105.80903102477463,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4143,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80876570957875,
          21.02042925577048,
          105.80889020879629,
          21.020643949171916
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80889020879629,
            21.020643949171916
          ],
          [
            105.80876570957875,
            21.02042925577048
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.020643949171916,
        "to_latitude": 21.02042925577048,
        "to_longitude": 105.80876570957875,
        "from_longitude": 105.80889020879629,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4144,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8086206467745,
          21.020179509995337,
          105.80876570957875,
          21.02042925577048
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80876570957875,
            21.02042925577048
          ],
          [
            105.8086206467745,
            21.020179509995337
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "18",
        "diemCuoi": "TCĐC 19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.02042925577048,
        "to_latitude": 21.020179509995337,
        "to_longitude": 105.8086206467745,
        "from_longitude": 105.80876570957875,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4145,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81004758199393,
          21.02260934962184,
          105.81028093418425,
          21.022717007752355
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81028093418425,
            21.02260934962184
          ],
          [
            105.81012536606336,
            21.02269197098512
          ],
          [
            105.81009452066534,
            21.022717007752355
          ],
          [
            105.81004758199393,
            21.022663178695982
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "Tủ TCĐC",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.02260934962184,
        "to_latitude": 21.022663178695982,
        "to_longitude": 105.81004758199393,
        "from_longitude": 105.81028093418425,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4146,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81028093418425,
          21.022455373326363,
          105.81044454892839,
          21.02260934962184
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81028093418425,
            21.02260934962184
          ],
          [
            105.81044454892839,
            21.022455373326363
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.02260934962184,
        "to_latitude": 21.022455373326363,
        "to_longitude": 105.81044454892839,
        "from_longitude": 105.81028093418425,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4147,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81044454892839,
          21.02224381213804,
          105.81072886308377,
          21.022455373326363
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81044454892839,
            21.022455373326363
          ],
          [
            105.81072886308377,
            21.02224381213804
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.022455373326363,
        "to_latitude": 21.02224381213804,
        "to_longitude": 105.81072886308377,
        "from_longitude": 105.81044454892839,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4148,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81072886308377,
          21.0221161241753,
          105.81098099073728,
          21.02224381213804
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81072886308377,
            21.02224381213804
          ],
          [
            105.81098099073728,
            21.0221161241753
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.02224381213804,
        "to_latitude": 21.0221161241753,
        "to_longitude": 105.81098099073728,
        "from_longitude": 105.81072886308377,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4149,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81098099073728,
          21.022017228520973,
          105.81120361408769,
          21.0221161241753
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81098099073728,
            21.0221161241753
          ],
          [
            105.81120361408769,
            21.022017228520973
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.0221161241753,
        "to_latitude": 21.022017228520973,
        "to_longitude": 105.81120361408769,
        "from_longitude": 105.81098099073728,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4150,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81113655886718,
          21.024437023604335,
          105.81129212697908,
          21.024599760612166
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81129212697908,
            21.024437023604335
          ],
          [
            105.81113655886718,
            21.024504622076
          ],
          [
            105.81117813306923,
            21.024599760612166
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 59 Nguyễn Chí Thanh",
        "diemDau": "TCĐC 01",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TCĐC",
        "from_latitude": 21.024437023604335,
        "to_latitude": 21.024599760612166,
        "to_longitude": 105.81117813306923,
        "from_longitude": 105.81129212697908,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4151,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81093271097099,
          21.024150355673836,
          105.81132699569773,
          21.02426677546857
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81132699569773,
            21.024150355673836
          ],
          [
            105.81102390608812,
            21.02426677546857
          ],
          [
            105.81093271097099,
            21.02420543601755
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.024150355673836,
        "to_latitude": 21.02420543601755,
        "to_longitude": 105.81093271097099,
        "from_longitude": 105.81132699569773,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4152,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81081871708805,
          21.021913325420943,
          105.81098099073728,
          21.0221161241753
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81098099073728,
            21.0221161241753
          ],
          [
            105.81093002877222,
            21.022091087306304
          ],
          [
            105.81081871708805,
            21.021913325420943
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.0221161241753,
        "to_latitude": 21.021913325420943,
        "to_longitude": 105.81081871708805,
        "from_longitude": 105.81098099073728,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4153,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81067790111871,
          21.021674222832008,
          105.81081871708805,
          21.021913325420943
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81081871708805,
            21.021913325420943
          ],
          [
            105.81067790111871,
            21.021674222832008
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.021913325420943,
        "to_latitude": 21.021674222832008,
        "to_longitude": 105.81067790111871,
        "from_longitude": 105.81081871708805,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4154,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81065376124006,
          21.021544030684773,
          105.81084956249508,
          21.021674222832008
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81067790111871,
            21.021674222832008
          ],
          [
            105.81065376124006,
            21.021615386009
          ],
          [
            105.81084956249508,
            21.021544030684773
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCĐC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCĐC",
        "from_latitude": 21.021674222832008,
        "to_latitude": 21.021544030684773,
        "to_longitude": 105.81084956249508,
        "from_longitude": 105.81067790111871,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4155,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83951310893876,
          21.015484996523515,
          105.83972092666626,
          21.015564465721962
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83951310893876,
            21.015484996523515
          ],
          [
            105.83972092666626,
            21.015564465721962
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hoàng An",
        "diemDau": "10",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015484996523515,
        "to_latitude": 21.015564465721962,
        "to_longitude": 105.83972092666626,
        "from_longitude": 105.83951310893876,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4156,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83972092666626,
          21.015564465721962,
          105.83990987739048,
          21.015635074467045
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83972092666626,
            21.015564465721962
          ],
          [
            105.83990987739048,
            21.015635074467045
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hoàng An",
        "diemDau": "09",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015564465721962,
        "to_latitude": 21.015635074467045,
        "to_longitude": 105.83990987739048,
        "from_longitude": 105.83972092666626,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4157,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83979720334484,
          21.015635074467045,
          105.83990987739048,
          21.01576468859456
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83990987739048,
            21.015635074467045
          ],
          [
            105.83979720334484,
            21.01576468859456
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hoàng An",
        "diemDau": "08",
        "diemCuoi": "08-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015635074467045,
        "to_latitude": 21.01576468859456,
        "to_longitude": 105.83979720334484,
        "from_longitude": 105.83990987739048,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4158,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8396886036946,
          21.01576468859456,
          105.83979720334484,
          21.0159385458315
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83979720334484,
            21.01576468859456
          ],
          [
            105.8396886036946,
            21.0159385458315
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hoàng An",
        "diemDau": "08-1",
        "diemCuoi": "08-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.01576468859456,
        "to_latitude": 21.0159385458315,
        "to_longitude": 105.8396886036946,
        "from_longitude": 105.83979720334484,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4159,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83990987739048,
          21.01553440349069,
          105.84008745214712,
          21.015635074467045
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83990987739048,
            21.015635074467045
          ],
          [
            105.84008745214712,
            21.01553440349069
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hoàng An",
        "diemDau": "08",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015635074467045,
        "to_latitude": 21.01553440349069,
        "to_longitude": 105.84008745214712,
        "from_longitude": 105.83990987739048,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4160,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84008745214712,
          21.01553440349069,
          105.84022081176117,
          21.015584444228846
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84008745214712,
            21.01553440349069
          ],
          [
            105.84022081176117,
            21.015584444228846
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hoàng An",
        "diemDau": "07",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.01553440349069,
        "to_latitude": 21.015584444228846,
        "to_longitude": 105.84022081176117,
        "from_longitude": 105.84008745214712,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4161,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84008745214712,
          21.015312568749717,
          105.84013597578424,
          21.01553440349069
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84008745214712,
            21.01553440349069
          ],
          [
            105.84013597578424,
            21.015312568749717
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hoàng An",
        "diemDau": "07",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.01553440349069,
        "to_latitude": 21.015312568749717,
        "to_longitude": 105.84013597578424,
        "from_longitude": 105.84008745214712,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4162,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84013597578424,
          21.015155543497134,
          105.84016488398383,
          21.015312568749717
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84013597578424,
            21.015312568749717
          ],
          [
            105.84016488398383,
            21.015155543497134
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hoàng An",
        "diemDau": "06",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015312568749717,
        "to_latitude": 21.015155543497134,
        "to_longitude": 105.84016488398383,
        "from_longitude": 105.84013597578424,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4163,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84016334613887,
          21.015062956110913,
          105.84016488398383,
          21.015155543497134
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84016488398383,
            21.015155543497134
          ],
          [
            105.84016334613887,
            21.015062956110913
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hoàng An",
        "diemDau": "3-1",
        "diemCuoi": "6-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015155543497134,
        "to_latitude": 21.015062956110913,
        "to_longitude": 105.84016334613887,
        "from_longitude": 105.84016488398383,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4164,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83995090209775,
          21.015028670066332,
          105.84016334613887,
          21.015062956110913
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84016334613887,
            21.015062956110913
          ],
          [
            105.83995090209775,
            21.015028670066332
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hoàng An",
        "diemDau": "6-2",
        "diemCuoi": "6-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015062956110913,
        "to_latitude": 21.015028670066332,
        "to_longitude": 105.83995090209775,
        "from_longitude": 105.84016334613887,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4165,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84013597578424,
          21.015196909620943,
          105.8405173977315,
          21.015365354852502
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84013597578424,
            21.015312568749717
          ],
          [
            105.84041240055697,
            21.015365354852502
          ],
          [
            105.8405173977315,
            21.015196909620943
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 57,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hoàng An",
        "diemDau": "06",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015312568749717,
        "to_latitude": 21.015196909620943,
        "to_longitude": 105.8405173977315,
        "from_longitude": 105.84013597578424,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4166,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84013597578424,
          21.015312568749717,
          105.84053885540241,
          21.01540848080757
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84013597578424,
            21.015312568749717
          ],
          [
            105.84041240055697,
            21.015365354852502
          ],
          [
            105.84053885540241,
            21.01540848080757
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hoàng An",
        "diemDau": "06",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015312568749717,
        "to_latitude": 21.01540848080757,
        "to_longitude": 105.84053885540241,
        "from_longitude": 105.84013597578424,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4167,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84042620262336,
          21.015398465609962,
          105.84053885540241,
          21.01540848080757
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84053885540241,
            21.01540848080757
          ],
          [
            105.84042620262336,
            21.015398465609962
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hoàng An",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.01540848080757,
        "to_latitude": 21.015398465609962,
        "to_longitude": 105.84042620262336,
        "from_longitude": 105.84053885540241,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4168,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84053885540241,
          21.015273275586566,
          105.84059518179197,
          21.01540848080757
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84053885540241,
            21.01540848080757
          ],
          [
            105.84059518179197,
            21.015273275586566
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hoàng An",
        "diemDau": "04",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.01540848080757,
        "to_latitude": 21.015273275586566,
        "to_longitude": 105.84059518179197,
        "from_longitude": 105.84053885540241,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4169,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84059518179197,
          21.015241978064076,
          105.84086876711639,
          21.015273275586566
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84059518179197,
            21.015273275586566
          ],
          [
            105.84086876711639,
            21.015241978064076
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hoàng An",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015273275586566,
        "to_latitude": 21.015241978064076,
        "to_longitude": 105.84086876711639,
        "from_longitude": 105.84059518179197,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4170,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84086876711639,
          21.015074223232894,
          105.84088486036283,
          21.015241978064076
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84086876711639,
            21.015241978064076
          ],
          [
            105.84088486036283,
            21.015074223232894
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hoàng An",
        "diemDau": "02",
        "diemCuoi": "2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015241978064076,
        "to_latitude": 21.015074223232894,
        "to_longitude": 105.84088486036283,
        "from_longitude": 105.84086876711639,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4171,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84087815484348,
          21.014964055778748,
          105.84088486036283,
          21.015074223232894
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84088486036283,
            21.015074223232894
          ],
          [
            105.84087815484348,
            21.014964055778748
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hoàng An",
        "diemDau": "2-1",
        "diemCuoi": "2-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015074223232894,
        "to_latitude": 21.014964055778748,
        "to_longitude": 105.84087815484348,
        "from_longitude": 105.84088486036283,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4172,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84088486036283,
          21.01486515538099,
          105.8409005625996,
          21.015074223232894
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84088486036283,
            21.015074223232894
          ],
          [
            105.8409005625996,
            21.014924685406996
          ],
          [
            105.84089156589118,
            21.01486515538099
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hoàng An",
        "diemDau": "2-1",
        "diemCuoi": "2-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015074223232894,
        "to_latitude": 21.01486515538099,
        "to_longitude": 105.84089156589118,
        "from_longitude": 105.84088486036283,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4173,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84086474379578,
          21.01486515538099,
          105.84089156589118,
          21.014906468213017
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84089156589118,
            21.01486515538099
          ],
          [
            105.84086474379578,
            21.014906468213017
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hoàng An",
        "diemDau": "2-3",
        "diemCuoi": "2-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.01486515538099,
        "to_latitude": 21.014906468213017,
        "to_longitude": 105.84086474379578,
        "from_longitude": 105.84089156589118,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4174,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84084328612487,
          21.0147562396769,
          105.84089156589118,
          21.01486515538099
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84089156589118,
            21.01486515538099
          ],
          [
            105.84084328612487,
            21.0147562396769
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hoàng An",
        "diemDau": "2-3",
        "diemCuoi": "2-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.01486515538099,
        "to_latitude": 21.0147562396769,
        "to_longitude": 105.84084328612487,
        "from_longitude": 105.84089156589118,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4175,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84084328612487,
          21.0147562396769,
          105.84107659291813,
          21.014766428641337
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84084328612487,
            21.0147562396769
          ],
          [
            105.84107659291813,
            21.014766428641337
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hoàng An",
        "diemDau": "2-4",
        "diemCuoi": "2-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.0147562396769,
        "to_latitude": 21.014766428641337,
        "to_longitude": 105.84107659291813,
        "from_longitude": 105.84084328612487,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4176,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84086876711639,
          21.015241978064076,
          105.84114235243183,
          21.015265764182033
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84086876711639,
            21.015241978064076
          ],
          [
            105.84114235243183,
            21.015265764182033
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Hoàng An",
        "diemDau": "02",
        "diemCuoi": "01",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015241978064076,
        "to_latitude": 21.015265764182033,
        "to_longitude": 105.84114235243183,
        "from_longitude": 105.84086876711639,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4177,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8384239083184,
          21.015221911703023,
          105.83858225010826,
          21.015357488010814
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83858225010826,
            21.015357488010814
          ],
          [
            105.8384239083184,
            21.015221911703023
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "26",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015357488010814,
        "to_latitude": 21.015221911703023,
        "to_longitude": 105.8384239083184,
        "from_longitude": 105.83858225010826,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4178,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83833571989321,
          21.015063839895422,
          105.8384239083184,
          21.015221911703023
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8384239083184,
            21.015221911703023
          ],
          [
            105.83833571989321,
            21.015063839895422
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015221911703023,
        "to_latitude": 21.015063839895422,
        "to_longitude": 105.83833571989321,
        "from_longitude": 105.8384239083184,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4179,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83807333348571,
          21.015063839895422,
          105.83833571989321,
          21.015225543867512
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83833571989321,
            21.015063839895422
          ],
          [
            105.83807333348571,
            21.015225543867512
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "29",
        "diemCuoi": "29-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015063839895422,
        "to_latitude": 21.015225543867512,
        "to_longitude": 105.83807333348571,
        "from_longitude": 105.83833571989321,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4180,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83788895128856,
          21.015225543867512,
          105.83807333348571,
          21.015372642053585
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83807333348571,
            21.015225543867512
          ],
          [
            105.83788895128856,
            21.015372642053585
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "29-1",
        "diemCuoi": "29-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015225543867512,
        "to_latitude": 21.015372642053585,
        "to_longitude": 105.83788895128856,
        "from_longitude": 105.83807333348571,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4181,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83820475629916,
          21.014903970854167,
          105.83833571989321,
          21.015063839895422
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83833571989321,
            21.015063839895422
          ],
          [
            105.83820475629916,
            21.014903970854167
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.015063839895422,
        "to_latitude": 21.014903970854167,
        "to_longitude": 105.83820475629916,
        "from_longitude": 105.83833571989321,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4182,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83820475629916,
          21.01476668939999,
          105.83850282797756,
          21.014903970854167
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83820475629916,
            21.014903970854167
          ],
          [
            105.83850282797756,
            21.01476668939999
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.014903970854167,
        "to_latitude": 21.01476668939999,
        "to_longitude": 105.83850282797756,
        "from_longitude": 105.83820475629916,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4183,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83850282797756,
          21.01476668939999,
          105.83865203466212,
          21.014822114539253
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83850282797756,
            21.01476668939999
          ],
          [
            105.83865203466212,
            21.014822114539253
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "31",
        "diemCuoi": "31-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.01476668939999,
        "to_latitude": 21.014822114539253,
        "to_longitude": 105.83865203466212,
        "from_longitude": 105.83850282797756,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4184,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83850282797756,
          21.014634436714235,
          105.83869077801216,
          21.01476668939999
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83850282797756,
            21.01476668939999
          ],
          [
            105.83869077801216,
            21.014634436714235
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.01476668939999,
        "to_latitude": 21.014634436714235,
        "to_longitude": 105.83869077801216,
        "from_longitude": 105.83850282797756,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4185,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83869077801216,
          21.01453428419071,
          105.83873657875168,
          21.014634436714235
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83869077801216,
            21.014634436714235
          ],
          [
            105.83873657875168,
            21.014565075639343
          ],
          [
            105.83871223568309,
            21.01453428419071
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.014634436714235,
        "to_latitude": 21.01453428419071,
        "to_longitude": 105.83871223568309,
        "from_longitude": 105.83869077801216,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4186,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83849497674821,
          21.014631932902343,
          105.83856491737535,
          21.01476668939999
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83850282797756,
            21.01476668939999
          ],
          [
            105.83856491737535,
            21.01468776244539
          ],
          [
            105.83849497674821,
            21.014631932902343
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "31",
        "diemCuoi": "31-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.01476668939999,
        "to_latitude": 21.014631932902343,
        "to_longitude": 105.83849497674821,
        "from_longitude": 105.83850282797756,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4187,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8380874841727,
          21.014858021520272,
          105.83820475629916,
          21.014931138169768
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83820475629916,
            21.014903970854167
          ],
          [
            105.83813844613776,
            21.01491560910389
          ],
          [
            105.8380874841727,
            21.014858021520272
          ],
          [
            105.83810102660797,
            21.014931138169768
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "30",
        "diemCuoi": "34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.014903970854167,
        "to_latitude": 21.014931138169768,
        "to_longitude": 105.83810102660797,
        "from_longitude": 105.83820475629916,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4188,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8380245836514,
          21.014858021520272,
          105.8380874841727,
          21.014921122940965
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8380874841727,
            21.014858021520272
          ],
          [
            105.8380245836514,
            21.014921122940965
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "34",
        "diemCuoi": "34-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.014858021520272,
        "to_latitude": 21.014921122940965,
        "to_longitude": 105.8380245836514,
        "from_longitude": 105.8380874841727,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4189,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83797764499795,
          21.014760879179192,
          105.8380874841727,
          21.014858021520272
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8380874841727,
            21.014858021520272
          ],
          [
            105.83797764499795,
            21.014760879179192
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.014858021520272,
        "to_latitude": 21.014760879179192,
        "to_longitude": 105.83797764499795,
        "from_longitude": 105.8380874841727,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4190,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83779525478167,
          21.014549307073903,
          105.83797764499795,
          21.014760879179192
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83797764499795,
            21.014760879179192
          ],
          [
            105.83779525478167,
            21.014549307073903
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "35",
        "diemCuoi": "36",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.014760879179192,
        "to_latitude": 21.014549307073903,
        "to_longitude": 105.83779525478167,
        "from_longitude": 105.83797764499795,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4191,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.837670380231,
          21.014385246816758,
          105.83779525478167,
          21.014549307073903
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83779525478167,
            21.014549307073903
          ],
          [
            105.837670380231,
            21.014385246816758
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "36",
        "diemCuoi": "37",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.014549307073903,
        "to_latitude": 21.014385246816758,
        "to_longitude": 105.837670380231,
        "from_longitude": 105.83779525478167,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4192,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83754264996047,
          21.014385246816758,
          105.837670380231,
          21.014477802809758
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.837670380231,
            21.014385246816758
          ],
          [
            105.83754264996047,
            21.014477802809758
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "37",
        "diemCuoi": "37-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.014385246816758,
        "to_latitude": 21.014477802809758,
        "to_longitude": 105.83754264996047,
        "from_longitude": 105.837670380231,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4193,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83756726703376,
          21.014284648274053,
          105.837670380231,
          21.014385246816758
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.837670380231,
            21.014385246816758
          ],
          [
            105.83764491967968,
            21.014284648274053
          ],
          [
            105.83756726703376,
            21.014331475125765
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "37",
        "diemCuoi": "37-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.014385246816758,
        "to_latitude": 21.014331475125765,
        "to_longitude": 105.83756726703376,
        "from_longitude": 105.837670380231,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4194,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83755385596811,
          21.014158711629957,
          105.837670380231,
          21.014385246816758
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.837670380231,
            21.014385246816758
          ],
          [
            105.83755385596811,
            21.014158711629957
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "37",
        "diemCuoi": "38",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.014385246816758,
        "to_latitude": 21.014158711629957,
        "to_longitude": 105.83755385596811,
        "from_longitude": 105.837670380231,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4195,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83779525478167,
          21.014381551463615,
          105.83791729528782,
          21.014549307073903
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83779525478167,
            21.014549307073903
          ],
          [
            105.83791729528782,
            21.014381551463615
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "36",
        "diemCuoi": "39",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.014549307073903,
        "to_latitude": 21.014381551463615,
        "to_longitude": 105.83791729528782,
        "from_longitude": 105.83779525478167,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4196,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83784353453899,
          21.014277643043076,
          105.83791729528782,
          21.014381551463615
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83791729528782,
            21.014381551463615
          ],
          [
            105.83784353453899,
            21.014277643043076
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "39",
        "diemCuoi": "39-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.014381551463615,
        "to_latitude": 21.014277643043076,
        "to_longitude": 105.83784353453899,
        "from_longitude": 105.83791729528782,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4197,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83791729528782,
          21.014302174999898,
          105.83810639102347,
          21.0143915667295
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83791729528782,
            21.014381551463615
          ],
          [
            105.83801238232,
            21.014302174999898
          ],
          [
            105.83810639102347,
            21.0143915667295
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "39",
        "diemCuoi": "40",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.014381551463615,
        "to_latitude": 21.0143915667295,
        "to_longitude": 105.83810639102347,
        "from_longitude": 105.83791729528782,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4198,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83810639102347,
          21.014243841493244,
          105.838224408227,
          21.0143915667295
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83810639102347,
            21.0143915667295
          ],
          [
            105.838224408227,
            21.014243841493244
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "40",
        "diemCuoi": "41",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.0143915667295,
        "to_latitude": 21.014243841493244,
        "to_longitude": 105.838224408227,
        "from_longitude": 105.83810639102347,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4199,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.838224408227,
          21.01412365814252,
          105.83841886837813,
          21.014243841493244
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.838224408227,
            21.014243841493244
          ],
          [
            105.83841886837813,
            21.01412365814252
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "41",
        "diemCuoi": "42",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.014243841493244,
        "to_latitude": 21.01412365814252,
        "to_longitude": 105.83841886837813,
        "from_longitude": 105.838224408227,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4200,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83841886837813,
          21.01412365814252,
          105.83854895551649,
          21.01421954898877
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83841886837813,
            21.01412365814252
          ],
          [
            105.83851395540131,
            21.01421954898877
          ],
          [
            105.83854895551649,
            21.014196268928433
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "42",
        "diemCuoi": "42-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.01412365814252,
        "to_latitude": 21.014196268928433,
        "to_longitude": 105.83854895551649,
        "from_longitude": 105.83841886837813,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4201,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83854895551649,
          21.014015993808535,
          105.83868977148585,
          21.014196268928433
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83854895551649,
            21.014196268928433
          ],
          [
            105.83868977148585,
            21.014015993808535
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "42-1",
        "diemCuoi": "43",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.014196268928433,
        "to_latitude": 21.014015993808535,
        "to_longitude": 105.83868977148585,
        "from_longitude": 105.83854895551649,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4202,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83868977148585,
          21.013900817923563,
          105.8388332696719,
          21.014015993808535
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83868977148585,
            21.014015993808535
          ],
          [
            105.8388332696719,
            21.013900817923563
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "43",
        "diemCuoi": "45",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.014015993808535,
        "to_latitude": 21.013900817923563,
        "to_longitude": 105.8388332696719,
        "from_longitude": 105.83868977148585,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4203,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83870854694902,
          21.013753092202062,
          105.8388332696719,
          21.013900817923563
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8388332696719,
            21.013900817923563
          ],
          [
            105.83870854694902,
            21.013753092202062
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "45",
        "diemCuoi": "44",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.013900817923563,
        "to_latitude": 21.013753092202062,
        "to_longitude": 105.83870854694902,
        "from_longitude": 105.8388332696719,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4204,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83865490276722,
          21.013662954401518,
          105.83870854694902,
          21.013753092202062
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83870854694902,
            21.013753092202062
          ],
          [
            105.83865490276722,
            21.013662954401518
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "44",
        "diemCuoi": "44-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.013753092202062,
        "to_latitude": 21.013662954401518,
        "to_longitude": 105.83865490276722,
        "from_longitude": 105.83870854694902,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4205,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8388332696719,
          21.013900817923563,
          105.83890703042073,
          21.013967169259942
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8388332696719,
            21.013900817923563
          ],
          [
            105.83890703042073,
            21.013967169259942
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "45",
        "diemCuoi": "48",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.013900817923563,
        "to_latitude": 21.013967169259942,
        "to_longitude": 105.83890703042073,
        "from_longitude": 105.8388332696719,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4206,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83890703042073,
          21.013967169259942,
          105.8389701804135,
          21.0140219666291
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83890703042073,
            21.013967169259942
          ],
          [
            105.8389701804135,
            21.0140219666291
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "46",
        "diemCuoi": "47",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.013967169259942,
        "to_latitude": 21.0140219666291,
        "to_longitude": 105.8389701804135,
        "from_longitude": 105.83890703042073,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4207,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8389701804135,
          21.0140219666291,
          105.83911221940282,
          21.01404478776649
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8389701804135,
            21.0140219666291
          ],
          [
            105.83911221940282,
            21.01404478776649
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "47",
        "diemCuoi": "48",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.0140219666291,
        "to_latitude": 21.01404478776649,
        "to_longitude": 105.83911221940282,
        "from_longitude": 105.8389701804135,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4208,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83911221940282,
          21.01404478776649,
          105.8391147157132,
          21.01429756561227
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83911221940282,
            21.01404478776649
          ],
          [
            105.8391147157132,
            21.01429756561227
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "48",
        "diemCuoi": "49",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.01404478776649,
        "to_latitude": 21.01429756561227,
        "to_longitude": 105.8391147157132,
        "from_longitude": 105.83911221940282,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4209,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83797764499795,
          21.014664943804497,
          105.83818738138524,
          21.014760879179192
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83797764499795,
            21.014760879179192
          ],
          [
            105.83818738138524,
            21.014664943804497
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "35",
        "diemCuoi": "35-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.014760879179192,
        "to_latitude": 21.014664943804497,
        "to_longitude": 105.83818738138524,
        "from_longitude": 105.83797764499795,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4210,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83818738138524,
          21.014548055167673,
          105.8383491309409,
          21.014664943804497
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83818738138524,
            21.014664943804497
          ],
          [
            105.8383491309409,
            21.014548055167673
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "35-1",
        "diemCuoi": "35-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.014664943804497,
        "to_latitude": 21.014548055167673,
        "to_longitude": 105.8383491309409,
        "from_longitude": 105.83818738138524,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4211,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8385566675622,
          21.014459169754563,
          105.83869077801216,
          21.014634436714235
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83869077801216,
            21.014634436714235
          ],
          [
            105.8385566675622,
            21.014459169754563
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T8A",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "diemDau": "32",
        "diemCuoi": "32-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T8A",
        "from_latitude": 21.014634436714235,
        "to_latitude": 21.014459169754563,
        "to_longitude": 105.8385566675622,
        "from_longitude": 105.83869077801216,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4212,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81202034668648,
          21.01674436776622,
          105.81218664364734,
          21.01675813853992
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81218664364734,
            21.01675813853992
          ],
          [
            105.81202034668648,
            21.01674436776622
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 47 Nguyên Hồng",
        "diemDau": "Tủ điện",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.01675813853992,
        "to_latitude": 21.01674436776622,
        "to_longitude": 105.81202034668648,
        "from_longitude": 105.81218664364734,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4213,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81174407915431,
          21.01674436776622,
          105.81202034668648,
          21.016887082975963
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81202034668648,
            21.01674436776622
          ],
          [
            105.81174407915431,
            21.016887082975963
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 47 Nguyên Hồng",
        "diemDau": "30",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.01674436776622,
        "to_latitude": 21.016887082975963,
        "to_longitude": 105.81174407915431,
        "from_longitude": 105.81202034668648,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4214,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81145976499892,
          21.016887082975963,
          105.81174407915431,
          21.017042316909492
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81174407915431,
            21.016887082975963
          ],
          [
            105.81145976499892,
            21.017042316909492
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 47 Nguyên Hồng",
        "diemDau": "27",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.016887082975963,
        "to_latitude": 21.017042316909492,
        "to_longitude": 105.81145976499892,
        "from_longitude": 105.81174407915431,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4215,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81171993927565,
          21.01682198482661,
          105.81174407915431,
          21.016887082975963
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81174407915431,
            21.016887082975963
          ],
          [
            105.81171993927565,
            21.01682198482661
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Hoàng Ngọc Phách",
        "diemDau": "27",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.016887082975963,
        "to_latitude": 21.01682198482661,
        "to_longitude": 105.81171993927565,
        "from_longitude": 105.81174407915431,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4216,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81153486685162,
          21.01653405036517,
          105.81160460428886,
          21.016641712880574
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81160460428886,
            21.016641712880574
          ],
          [
            105.81153486685162,
            21.01653405036517
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Hoàng Ngọc Phách",
        "diemDau": "25",
        "diemCuoi": "25-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.016641712880574,
        "to_latitude": 21.01653405036517,
        "to_longitude": 105.81153486685162,
        "from_longitude": 105.81160460428886,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4217,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81143562512025,
          21.016383823468534,
          105.81153486685162,
          21.01653405036517
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81153486685162,
            21.01653405036517
          ],
          [
            105.81143562512025,
            21.016383823468534
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Hoàng Ngọc Phách",
        "diemDau": "25-1",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.01653405036517,
        "to_latitude": 21.016383823468534,
        "to_longitude": 105.81143562512025,
        "from_longitude": 105.81153486685162,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4218,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81125859931944,
          21.016383823468534,
          105.81143562512025,
          21.016491486092374
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81143562512025,
            21.016383823468534
          ],
          [
            105.81125859931944,
            21.016491486092374
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 47/2 Nguyên Hồng",
        "diemDau": "18",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.016383823468534,
        "to_latitude": 21.016491486092374,
        "to_longitude": 105.81125859931944,
        "from_longitude": 105.81143562512025,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4219,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81125859931944,
          21.016491486092374,
          105.8112693281594,
          21.016599148639344
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81125859931944,
            21.016491486092374
          ],
          [
            105.8112693281594,
            21.016599148639344
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 47/2 Nguyên Hồng",
        "diemDau": "17",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.016491486092374,
        "to_latitude": 21.016599148639344,
        "to_longitude": 105.8112693281594,
        "from_longitude": 105.81125859931944,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4220,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8112693281594,
          21.016599148639344,
          105.81137661652296,
          21.01678192441357
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8112693281594,
            21.016599148639344
          ],
          [
            105.81137661652296,
            21.01678192441357
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 47/2 Nguyên Hồng",
        "diemDau": "16",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.016599148639344,
        "to_latitude": 21.01678192441357,
        "to_longitude": 105.81137661652296,
        "from_longitude": 105.8112693281594,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4221,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81137661652296,
          21.01678192441357,
          105.81147317604659,
          21.01693715845655
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81137661652296,
            21.01678192441357
          ],
          [
            105.81147317604659,
            21.01693715845655
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 47/2 Nguyên Hồng",
        "diemDau": "15",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.01678192441357,
        "to_latitude": 21.01693715845655,
        "to_longitude": 105.81147317604659,
        "from_longitude": 105.81137661652296,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4222,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81160460428886,
          21.01655658438598,
          105.81172798589887,
          21.016641712880574
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81160460428886,
            21.016641712880574
          ],
          [
            105.81172798589887,
            21.01655658438598
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 10 Hoàng Ngọc Phách",
        "diemDau": "25",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.016641712880574,
        "to_latitude": 21.01655658438598,
        "to_longitude": 105.81172798589887,
        "from_longitude": 105.81160460428886,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4223,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81172798589887,
          21.016426387771183,
          105.811953291457,
          21.01655658438598
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81172798589887,
            21.01655658438598
          ],
          [
            105.811953291457,
            21.016426387771183
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 10 Hoàng Ngọc Phách",
        "diemDau": "24",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.01655658438598,
        "to_latitude": 21.016426387771183,
        "to_longitude": 105.811953291457,
        "from_longitude": 105.81172798589887,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4224,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81143562512025,
          21.016311213746796,
          105.81157778219345,
          21.016383823468534
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81143562512025,
            21.016383823468534
          ],
          [
            105.81157778219345,
            21.016311213746796
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 10 Hoàng Ngọc Phách",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.016383823468534,
        "to_latitude": 21.016311213746796,
        "to_longitude": 105.81157778219345,
        "from_longitude": 105.81143562512025,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4225,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81157778219345,
          21.016171001772094,
          105.81181381659152,
          21.016311213746796
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81157778219345,
            21.016311213746796
          ],
          [
            105.81181381659152,
            21.016171001772094
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 10 Hoàng Ngọc Phách",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.016311213746796,
        "to_latitude": 21.016171001772094,
        "to_longitude": 105.81181381659152,
        "from_longitude": 105.81157778219345,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4226,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81181381659152,
          21.016135948757604,
          105.81198547796787,
          21.016171001772094
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81181381659152,
            21.016171001772094
          ],
          [
            105.81198547796787,
            21.016135948757604
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 10 Hoàng Ngọc Phách",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.016171001772094,
        "to_latitude": 21.016135948757604,
        "to_longitude": 105.81198547796787,
        "from_longitude": 105.81181381659152,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4227,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81198547796787,
          21.016135948757604,
          105.81212495283333,
          21.01636128942077
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81198547796787,
            21.016135948757604
          ],
          [
            105.81212495283333,
            21.01636128942077
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 10 Hoàng Ngọc Phách",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.016135948757604,
        "to_latitude": 21.01636128942077,
        "to_longitude": 105.81212495283333,
        "from_longitude": 105.81198547796787,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4228,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81160460428886,
          21.016641712880574,
          105.81171993927565,
          21.01682198482661
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81171993927565,
            21.01682198482661
          ],
          [
            105.81160460428886,
            21.016641712880574
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Hoàng Ngọc Phách",
        "diemDau": "26",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.01682198482661,
        "to_latitude": 21.016641712880574,
        "to_longitude": 105.81160460428886,
        "from_longitude": 105.81171993927565,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4229,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81218664364734,
          21.01675813853992,
          105.81242536024418,
          21.016837007479374
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81218664364734,
            21.01675813853992
          ],
          [
            105.81242536024418,
            21.016837007479374
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 9 Huỳnh Thúc Kháng",
        "diemDau": "Tủ điện",
        "diemCuoi": "43",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.01675813853992,
        "to_latitude": 21.016837007479374,
        "to_longitude": 105.81242536024418,
        "from_longitude": 105.81218664364734,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4230,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81242536024418,
          21.016837007479374,
          105.81275258974141,
          21.016912120717958
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81242536024418,
            21.016837007479374
          ],
          [
            105.81275258974141,
            21.016912120717958
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 9 Huỳnh Thúc Kháng",
        "diemDau": "43",
        "diemCuoi": "42",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.016837007479374,
        "to_latitude": 21.016912120717958,
        "to_longitude": 105.81275258974141,
        "from_longitude": 105.81242536024418,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4231,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81275258974141,
          21.016912120717958,
          105.81284914928301,
          21.01705984330956
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81275258974141,
            21.016912120717958
          ],
          [
            105.81284914928301,
            21.01705984330956
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 9 Huỳnh Thúc Kháng",
        "diemDau": "42",
        "diemCuoi": "cột đỡ",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.016912120717958,
        "to_latitude": 21.01705984330956,
        "to_longitude": 105.81284914928301,
        "from_longitude": 105.81275258974141,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4232,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81284914928301,
          21.01705984330956,
          105.81304226832128,
          21.017382829487982
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81284914928301,
            21.01705984330956
          ],
          [
            105.81304226832128,
            21.017382829487982
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 9 Huỳnh Thúc Kháng",
        "diemDau": "cột đỡ",
        "diemCuoi": "38",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.01705984330956,
        "to_latitude": 21.017382829487982,
        "to_longitude": 105.81304226832128,
        "from_longitude": 105.81284914928301,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4233,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81284914928301,
          21.01705984330956,
          105.81296716646858,
          21.01714246774877
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81284914928301,
            21.01705984330956
          ],
          [
            105.81296716646858,
            21.01714246774877
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 5 Huỳnh Thúc Kháng",
        "diemDau": "cột đỡ",
        "diemCuoi": "39",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.01705984330956,
        "to_latitude": 21.01714246774877,
        "to_longitude": 105.81296716646858,
        "from_longitude": 105.81284914928301,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4234,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81296716646858,
          21.017029798049943,
          105.813179060979,
          21.01714246774877
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81296716646858,
            21.01714246774877
          ],
          [
            105.813179060979,
            21.017029798049943
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 5 Huỳnh Thúc Kháng",
        "diemDau": "39",
        "diemCuoi": "40",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.01714246774877,
        "to_latitude": 21.017029798049943,
        "to_longitude": 105.813179060979,
        "from_longitude": 105.81296716646858,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4235,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81306104378444,
          21.016771909308186,
          105.813179060979,
          21.017029798049943
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.813179060979,
            21.017029798049943
          ],
          [
            105.81306104378444,
            21.016771909308186
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 5 Huỳnh Thúc Kháng",
        "diemDau": "40",
        "diemCuoi": "41",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.017029798049943,
        "to_latitude": 21.016771909308186,
        "to_longitude": 105.81306104378444,
        "from_longitude": 105.813179060979,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4236,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81292156891,
          21.017084881024193,
          105.81296716646858,
          21.01714246774877
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81296716646858,
            21.01714246774877
          ],
          [
            105.81292156891,
            21.017084881024193
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 9 Huỳnh Thúc Kháng",
        "diemDau": "39",
        "diemCuoi": "37",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.01714246774877,
        "to_latitude": 21.017084881024193,
        "to_longitude": 105.81292156891,
        "from_longitude": 105.81296716646858,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4237,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81277672962906,
          21.017037309365598,
          105.81284914928301,
          21.01705984330956
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81284914928301,
            21.01705984330956
          ],
          [
            105.81277672962906,
            21.017037309365598
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 45 Nguyên Hồng",
        "diemDau": "cột đỡ",
        "diemCuoi": "34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.01705984330956,
        "to_latitude": 21.017037309365598,
        "to_longitude": 105.81277672962906,
        "from_longitude": 105.81284914928301,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4238,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81234221176824,
          21.017037309365598,
          105.81277672962906,
          21.017257641130357
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81277672962906,
            21.017037309365598
          ],
          [
            105.81234221176824,
            21.017257641130357
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 45 Nguyên Hồng",
        "diemDau": "34",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.017037309365598,
        "to_latitude": 21.017257641130357,
        "to_longitude": 105.81234221176824,
        "from_longitude": 105.81277672962906,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4239,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81242536024418,
          21.016754382872595,
          105.81256215290189,
          21.016837007479374
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81242536024418,
            21.016837007479374
          ],
          [
            105.81256215290189,
            21.016754382872595
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 9 Huỳnh Thúc Kháng",
        "diemDau": "43",
        "diemCuoi": "44",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.016837007479374,
        "to_latitude": 21.016754382872595,
        "to_longitude": 105.81256215290189,
        "from_longitude": 105.81242536024418,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4240,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81202034668648,
          21.01674436776622,
          105.81216786817517,
          21.016947173549973
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81202034668648,
            21.01674436776622
          ],
          [
            105.81216786817517,
            21.016947173549973
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ17 Huỳnh Thúc Kháng",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.01674436776622,
        "to_latitude": 21.016947173549973,
        "to_longitude": 105.81216786817517,
        "from_longitude": 105.81202034668648,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4241,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81216786817517,
          21.016947173549973,
          105.81232075408833,
          21.017207565758202
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81216786817517,
            21.016947173549973
          ],
          [
            105.81232075408833,
            21.017207565758202
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ17 Huỳnh Thúc Kháng",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.016947173549973,
        "to_latitude": 21.017207565758202,
        "to_longitude": 105.81232075408833,
        "from_longitude": 105.81216786817517,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4242,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81223056482382,
          21.017077369710904,
          105.81232075408833,
          21.017207565758202
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81232075408833,
            21.017207565758202
          ],
          [
            105.81227616238236,
            21.017195359879782
          ],
          [
            105.81223056482382,
            21.017112735472377
          ],
          [
            105.81224833445235,
            21.017077369710904
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ17 Huỳnh Thúc Kháng",
        "diemDau": "32",
        "diemCuoi": "32-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.017207565758202,
        "to_latitude": 21.017077369710904,
        "to_longitude": 105.81224833445235,
        "from_longitude": 105.81232075408833,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4243,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81145976499892,
          21.017042316909492,
          105.81162438558665,
          21.017308029453513
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81145976499892,
            21.017042316909492
          ],
          [
            105.81162438558665,
            21.017308029453513
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 45 Nguyên Hồng",
        "diemDau": "13",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.017042316909492,
        "to_latitude": 21.017308029453513,
        "to_longitude": 105.81162438558665,
        "from_longitude": 105.81145976499892,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4244,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81162438558665,
          21.017308029453513,
          105.81184600309342,
          21.017535559141464
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81162438558665,
            21.017308029453513
          ],
          [
            105.81184600309342,
            21.017535559141464
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 45 Nguyên Hồng",
        "diemDau": "06",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.017308029453513,
        "to_latitude": 21.017535559141464,
        "to_longitude": 105.81184600309342,
        "from_longitude": 105.81162438558665,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4245,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81184600309342,
          21.017535559141464,
          105.81196670250469,
          21.017718333767995
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81184600309342,
            21.017535559141464
          ],
          [
            105.81196670250469,
            21.017718333767995
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 45 Nguyên Hồng",
        "diemDau": "05",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.017535559141464,
        "to_latitude": 21.017718333767995,
        "to_longitude": 105.81196670250469,
        "from_longitude": 105.81184600309342,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4246,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81196670250469,
          21.017573115590377,
          105.81224028782013,
          21.017718333767995
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81196670250469,
            21.017718333767995
          ],
          [
            105.81224028782013,
            21.017573115590377
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ17 Huỳnh Thúc Kháng",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.017718333767995,
        "to_latitude": 21.017573115590377,
        "to_longitude": 105.81224028782013,
        "from_longitude": 105.81196670250469,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4247,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81224028782013,
          21.017415378443374,
          105.81244950012282,
          21.017573115590377
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81224028782013,
            21.017573115590377
          ],
          [
            105.81244950012282,
            21.017415378443374
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ17 Huỳnh Thúc Kháng",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.017573115590377,
        "to_latitude": 21.017415378443374,
        "to_longitude": 105.81244950012282,
        "from_longitude": 105.81224028782013,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4248,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81244950012282,
          21.017415378443374,
          105.81255947069415,
          21.01760065697931
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81244950012282,
            21.017415378443374
          ],
          [
            105.81255947069415,
            21.01760065697931
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ17 Huỳnh Thúc Kháng",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.017415378443374,
        "to_latitude": 21.01760065697931,
        "to_longitude": 105.81255947069415,
        "from_longitude": 105.81244950012282,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4249,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81174407915431,
          21.016887082975963,
          105.81189696506746,
          21.017097399879116
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81174407915431,
            21.016887082975963
          ],
          [
            105.81189696506746,
            21.017097399879116
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 45 Nguyên Hồng",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.016887082975963,
        "to_latitude": 21.017097399879116,
        "to_longitude": 105.81189696506746,
        "from_longitude": 105.81174407915431,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4250,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81189696506746,
          21.017097399879116,
          105.81206057982058,
          21.017377821955566
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81189696506746,
            21.017097399879116
          ],
          [
            105.81206057982058,
            21.017377821955566
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 45 Nguyên Hồng",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.017097399879116,
        "to_latitude": 21.017377821955566,
        "to_longitude": 105.81206057982058,
        "from_longitude": 105.81189696506746,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4251,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81254300113959,
          21.017155826537806,
          105.81266407684997,
          21.017375318189707
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81266407684997,
            21.017375318189707
          ],
          [
            105.81254300113959,
            21.017155826537806
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ15 Huỳnh Thúc Kháng",
        "diemDau": "cột dỡ",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.017375318189707,
        "to_latitude": 21.017155826537806,
        "to_longitude": 105.81254300113959,
        "from_longitude": 105.81266407684997,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4252,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81244681791509,
          21.016999752782606,
          105.81254300113959,
          21.017155826537806
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81254300113959,
            21.017155826537806
          ],
          [
            105.81244681791509,
            21.016999752782606
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ15 Huỳnh Thúc Kháng",
        "diemDau": "cột đỡ",
        "diemCuoi": "36",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.017155826537806,
        "to_latitude": 21.016999752782606,
        "to_longitude": 105.81244681791509,
        "from_longitude": 105.81254300113959,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4253,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80991313625672,
          21.02317924830985,
          105.8101330773904,
          21.023529761525626
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8101330773904,
            21.023529761525626
          ],
          [
            105.80991313625672,
            21.02317924830985
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.023529761525626,
        "to_latitude": 21.02317924830985,
        "to_longitude": 105.80991313625672,
        "from_longitude": 105.8101330773904,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4254,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80980048347767,
          21.02297645100165,
          105.80991313625672,
          21.02317924830985
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80991313625672,
            21.02317924830985
          ],
          [
            105.80980048347767,
            21.02297645100165
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02317924830985,
        "to_latitude": 21.02297645100165,
        "to_longitude": 105.80980048347767,
        "from_longitude": 105.80991313625672,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4255,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80965296198004,
          21.022738601956082,
          105.80980048347767,
          21.02297645100165
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80980048347767,
            21.02297645100165
          ],
          [
            105.80965296198004,
            21.022738601956082
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02297645100165,
        "to_latitude": 21.022738601956082,
        "to_longitude": 105.80965296198004,
        "from_longitude": 105.80980048347767,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4256,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80956981350407,
          21.02256835187899,
          105.80965296198004,
          21.022738601956082
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80965296198004,
            21.022738601956082
          ],
          [
            105.80956981350407,
            21.02256835187899
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.022738601956082,
        "to_latitude": 21.02256835187899,
        "to_longitude": 105.80956981350407,
        "from_longitude": 105.80965296198004,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4257,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8094290681064,
          21.022320882768742,
          105.80956981350407,
          21.02256835187899
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80956981350407,
            21.02256835187899
          ],
          [
            105.8094290681064,
            21.022320882768742
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02256835187899,
        "to_latitude": 21.022320882768742,
        "to_longitude": 105.8094290681064,
        "from_longitude": 105.80956981350407,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4258,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80928034667507,
          21.022065045659904,
          105.8094290681064,
          21.022320882768742
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8094290681064,
            21.022320882768742
          ],
          [
            105.80928034667507,
            21.022065045659904
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.022320882768742,
        "to_latitude": 21.022065045659904,
        "to_longitude": 105.80928034667507,
        "from_longitude": 105.8094290681064,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4259,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8091316252438,
          21.02180920811208,
          105.80928034667507,
          21.022065045659904
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80928034667507,
            21.022065045659904
          ],
          [
            105.8091316252438,
            21.02180920811208
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.022065045659904,
        "to_latitude": 21.02180920811208,
        "to_longitude": 105.8091316252438,
        "from_longitude": 105.80928034667507,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4260,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80898290381248,
          21.02155337012518,
          105.8091316252438,
          21.02180920811208
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8091316252438,
            21.02180920811208
          ],
          [
            105.80898290381248,
            21.02155337012518
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02180920811208,
        "to_latitude": 21.02155337012518,
        "to_longitude": 105.80898290381248,
        "from_longitude": 105.8091316252438,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4261,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80883418238118,
          21.02129753169932,
          105.80898290381248,
          21.02155337012518
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80898290381248,
            21.02155337012518
          ],
          [
            105.80883418238118,
            21.02129753169932
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02155337012518,
        "to_latitude": 21.02129753169932,
        "to_longitude": 105.80883418238118,
        "from_longitude": 105.80898290381248,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4262,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80868546094987,
          21.021041692834434,
          105.80883418238118,
          21.02129753169932
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80883418238118,
            21.02129753169932
          ],
          [
            105.80868546094987,
            21.021041692834434
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02129753169932,
        "to_latitude": 21.021041692834434,
        "to_longitude": 105.80868546094987,
        "from_longitude": 105.80883418238118,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4263,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80853673951859,
          21.02078585353059,
          105.80868546094987,
          21.021041692834434
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80868546094987,
            21.021041692834434
          ],
          [
            105.80853673951859,
            21.02078585353059
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.021041692834434,
        "to_latitude": 21.02078585353059,
        "to_longitude": 105.80853673951859,
        "from_longitude": 105.80868546094987,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4264,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80838801808726,
          21.020530013787667,
          105.80853673951859,
          21.02078585353059
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80853673951859,
            21.02078585353059
          ],
          [
            105.80838801808726,
            21.020530013787667
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02078585353059,
        "to_latitude": 21.020530013787667,
        "to_longitude": 105.80838801808726,
        "from_longitude": 105.80853673951859,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4265,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80823929665598,
          21.020274173605813,
          105.80838801808726,
          21.020530013787667
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80838801808726,
            21.020530013787667
          ],
          [
            105.80823929665598,
            21.020274173605813
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.020530013787667,
        "to_latitude": 21.020274173605813,
        "to_longitude": 105.80823929665598,
        "from_longitude": 105.80838801808726,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4266,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80809057522468,
          21.020018332984925,
          105.80823929665598,
          21.020274173605813
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80823929665598,
            21.020274173605813
          ],
          [
            105.80809057522468,
            21.020018332984925
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.020274173605813,
        "to_latitude": 21.020018332984925,
        "to_longitude": 105.80809057522468,
        "from_longitude": 105.80823929665598,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4267,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81085995603883,
          21.024766565857693,
          105.81112415362718,
          21.02486170422758
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81112415362718,
            21.02486170422758
          ],
          [
            105.81107453275702,
            21.024856696946316
          ],
          [
            105.81103698183067,
            21.02483416417907
          ],
          [
            105.81099943090429,
            21.024766565857693
          ],
          [
            105.81085995603883,
            21.02479160227653
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02486170422758,
        "to_latitude": 21.02479160227653,
        "to_longitude": 105.81085995603883,
        "from_longitude": 105.81112415362718,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4268,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81073389221207,
          21.02457879258073,
          105.81085995603883,
          21.02479160227653
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81085995603883,
            21.02479160227653
          ],
          [
            105.81073389221207,
            21.02457879258073
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02479160227653,
        "to_latitude": 21.02457879258073,
        "to_longitude": 105.81073389221207,
        "from_longitude": 105.81085995603883,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4269,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8105944173466,
          21.02436347893261,
          105.81073389221207,
          21.02457879258073
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81073389221207,
            21.02457879258073
          ],
          [
            105.8105944173466,
            21.02436347893261
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02457879258073,
        "to_latitude": 21.02436347893261,
        "to_longitude": 105.8105944173466,
        "from_longitude": 105.81073389221207,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4270,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81043348480122,
          21.024068048072436,
          105.8105944173466,
          21.02436347893261
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8105944173466,
            21.02436347893261
          ],
          [
            105.81043348480122,
            21.024068048072436
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02436347893261,
        "to_latitude": 21.024068048072436,
        "to_longitude": 105.81043348480122,
        "from_longitude": 105.8105944173466,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4271,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81028059888808,
          21.023792645895593,
          105.81043348480122,
          21.024068048072426
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81043348480122,
            21.024068048072426
          ],
          [
            105.81028059888808,
            21.023792645895593
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.024068048072426,
        "to_latitude": 21.023792645895593,
        "to_longitude": 105.81028059888808,
        "from_longitude": 105.81043348480122,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4272,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8101330773904,
          21.023529761525626,
          105.81028059888808,
          21.023792645895593
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81028059888808,
            21.023792645895593
          ],
          [
            105.8101330773904,
            21.023529761525626
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.023792645895593,
        "to_latitude": 21.023529761525626,
        "to_longitude": 105.8101330773904,
        "from_longitude": 105.81028059888808,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4273,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80941960978967,
          21.022738601956057,
          105.80965296198004,
          21.02276113504007
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80941960978967,
            21.02276113504007
          ],
          [
            105.80965296198004,
            21.022738601956057
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02276113504007,
        "to_latitude": 21.022738601956057,
        "to_longitude": 105.80965296198004,
        "from_longitude": 105.80941960978967,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4274,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81218664364734,
          21.016639209102358,
          105.81221346573375,
          21.01675813853992
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81218664364734,
            21.01675813853992
          ],
          [
            105.81221346573375,
            21.016639209102358
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8A+8B Hoàng Ngọc Phách",
        "diemDau": "Tủ điện",
        "diemCuoi": "45",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.01675813853992,
        "to_latitude": 21.016639209102358,
        "to_longitude": 105.81221346573375,
        "from_longitude": 105.81218664364734,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4275,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81221346573375,
          21.016596644860417,
          105.81245218233057,
          21.016639209102358
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81221346573375,
            21.016639209102358
          ],
          [
            105.81245218233057,
            21.016596644860417
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8A+8B Hoàng Ngọc Phách",
        "diemDau": "45",
        "diemCuoi": "46",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.016639209102358,
        "to_latitude": 21.016596644860417,
        "to_longitude": 105.81245218233057,
        "from_longitude": 105.81221346573375,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4276,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81245218233057,
          21.016451425590496,
          105.81272040323952,
          21.016596644860417
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81245218233057,
            21.016596644860417
          ],
          [
            105.81272040323952,
            21.016451425590496
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8A+8B Hoàng Ngọc Phách",
        "diemDau": "46",
        "diemCuoi": "47",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.016596644860417,
        "to_latitude": 21.016451425590496,
        "to_longitude": 105.81272040323952,
        "from_longitude": 105.81245218233057,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4277,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81272040323952,
          21.016451425590496,
          105.81286792472822,
          21.01661917887262
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81272040323952,
            21.016451425590496
          ],
          [
            105.81286792472822,
            21.01661917887262
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8A+8B Hoàng Ngọc Phách",
        "diemDau": "47",
        "diemCuoi": "48",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.016451425590496,
        "to_latitude": 21.01661917887262,
        "to_longitude": 105.81286792472822,
        "from_longitude": 105.81272040323952,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4278,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81272040323952,
          21.01624861913175,
          105.8127391787027,
          21.016451425590496
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81272040323952,
            21.016451425590496
          ],
          [
            105.8127391787027,
            21.01624861913175
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8A+8B Hoàng Ngọc Phách",
        "diemDau": "47",
        "diemCuoi": "49",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.016451425590496,
        "to_latitude": 21.01624861913175,
        "to_longitude": 105.8127391787027,
        "from_longitude": 105.81272040323952,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4279,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81261847929143,
          21.016068346492606,
          105.8127391787027,
          21.01624861913175
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8127391787027,
            21.01624861913175
          ],
          [
            105.81261847929143,
            21.016068346492606
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8A+8B Hoàng Ngọc Phách",
        "diemDau": "49",
        "diemCuoi": "51",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.01624861913175,
        "to_latitude": 21.016068346492606,
        "to_longitude": 105.81261847929143,
        "from_longitude": 105.8127391787027,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4280,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81244950012282,
          21.015825478842896,
          105.81261847929143,
          21.016068346492606
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81261847929143,
            21.016068346492606
          ],
          [
            105.81244950012282,
            21.015825478842896
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8A+8B Hoàng Ngọc Phách",
        "diemDau": "51",
        "diemCuoi": "52",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.016068346492606,
        "to_latitude": 21.015825478842896,
        "to_longitude": 105.81244950012282,
        "from_longitude": 105.81261847929143,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4281,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8127391787027,
          21.016020774509748,
          105.8128947468236,
          21.01624861913175
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8127391787027,
            21.01624861913175
          ],
          [
            105.8128947468236,
            21.016020774509748
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B24TC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8A+8B Hoàng Ngọc Phách",
        "diemDau": "49",
        "diemCuoi": "50",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B24 Thành Công",
        "from_latitude": 21.01624861913175,
        "to_latitude": 21.016020774509748,
        "to_longitude": 105.8128947468236,
        "from_longitude": 105.8127391787027,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4282,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8296201627161,
          21.001827371535366,
          105.82998980604195,
          21.001883523466002
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8296201627161,
            21.001883523466002
          ],
          [
            105.82998980604195,
            21.001827371535366
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "diemDau": "6",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4",
        "from_latitude": 21.001883523466002,
        "to_latitude": 21.001827371535366,
        "to_longitude": 105.82998980604195,
        "from_longitude": 105.8296201627161,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4283,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82998980604195,
          21.001714690269612,
          105.83044846378141,
          21.001827371535366
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82998980604195,
            21.001827371535366
          ],
          [
            105.83044846378141,
            21.001714690269612
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "diemDau": "6-1",
        "diemCuoi": "6-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4",
        "from_latitude": 21.001827371535366,
        "to_latitude": 21.001714690269612,
        "to_longitude": 105.83044846378141,
        "from_longitude": 105.82998980604195,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4284,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83044846378141,
          21.00152103690147,
          105.83073867088459,
          21.001714690269612
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83044846378141,
            21.001714690269612
          ],
          [
            105.83044846378141,
            21.001714690269612
          ],
          [
            105.83073867088459,
            21.00152103690147
          ],
          [
            105.83073867088459,
            21.00152103690147
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "diemDau": "6-2",
        "diemCuoi": "6-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4",
        "from_latitude": 21.001714690269612,
        "to_latitude": 21.00152103690147,
        "to_longitude": 105.83073867088459,
        "from_longitude": 105.83044846378141,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4285,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80941960978967,
          21.02252578932954,
          105.8096965478693,
          21.02276113504007
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80941960978967,
            21.02276113504007
          ],
          [
            105.80955539661277,
            21.022659736137328
          ],
          [
            105.8096965478693,
            21.02258431283398
          ],
          [
            105.80968648961272,
            21.02252578932954
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "Tủ điện",
        "diemCuoi": "31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02276113504007,
        "to_latitude": 21.02252578932954,
        "to_longitude": 105.80968648961272,
        "from_longitude": 105.80941960978967,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4286,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80941960978967,
          21.02257961843636,
          105.80989704301926,
          21.02276113504007
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80941960978967,
            21.02276113504007
          ],
          [
            105.80958221870814,
            21.02267726187881
          ],
          [
            105.80981825309722,
            21.02257961843636
          ],
          [
            105.80989704301926,
            21.022736098280227
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "Tủ điện",
        "diemCuoi": "32",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02276113504007,
        "to_latitude": 21.022736098280227,
        "to_longitude": 105.80989704301926,
        "from_longitude": 105.80941960978967,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4287,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80937803557865,
          21.021991252747092,
          105.80968648961272,
          21.02252578932954
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80968648961272,
            21.02252578932954
          ],
          [
            105.80937803557865,
            21.021991252747092
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 72,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "31",
        "diemCuoi": "29",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02252578932954,
        "to_latitude": 21.021991252747092,
        "to_longitude": 105.80937803557865,
        "from_longitude": 105.80968648961272,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4288,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8090923803104,
          21.02146422534084,
          105.80937803557865,
          21.021991252747092
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80937803557865,
            21.021991252747092
          ],
          [
            105.8090923803104,
            21.02146422534084
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 70,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "29",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.021991252747092,
        "to_latitude": 21.02146422534084,
        "to_longitude": 105.8090923803104,
        "from_longitude": 105.80937803557865,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4289,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80879733732402,
          21.020948462749764,
          105.8090923803104,
          21.02146422534084
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8090923803104,
            21.02146422534084
          ],
          [
            105.80879733732402,
            21.020948462749764
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 73,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "27",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02146422534084,
        "to_latitude": 21.020948462749764,
        "to_longitude": 105.80879733732402,
        "from_longitude": 105.8090923803104,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4290,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80851838758412,
          21.020492787519718,
          105.80879733732402,
          21.020948462749764
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80879733732402,
            21.020948462749764
          ],
          [
            105.80851838758412,
            21.020492787519718
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 69,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "26",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.020948462749764,
        "to_latitude": 21.020492787519718,
        "to_longitude": 105.80851838758412,
        "from_longitude": 105.80879733732402,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4291,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8082662599396,
          21.02003210345463,
          105.80851838758412,
          21.020492787519718
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80851838758412,
            21.020492787519718
          ],
          [
            105.8082662599396,
            21.02003210345463
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 68,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "24",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.020492787519718,
        "to_latitude": 21.02003210345463,
        "to_longitude": 105.8082662599396,
        "from_longitude": 105.80851838758412,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4292,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8082662599396,
          21.019988288320583,
          105.8084486501469,
          21.02016229692125
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8082662599396,
            21.02003210345463
          ],
          [
            105.80834303813845,
            21.019988288320583
          ],
          [
            105.8084486501469,
            21.02016229692125
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02003210345463,
        "to_latitude": 21.02016229692125,
        "to_longitude": 105.8084486501469,
        "from_longitude": 105.8082662599396,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4293,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80876515081316,
          21.02066804738903,
          105.80932305029299,
          21.021684550564263
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80876515081316,
            21.02066804738903
          ],
          [
            105.80932305029299,
            21.021684550564263
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 134,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "28",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02066804738903,
        "to_latitude": 21.021684550564263,
        "to_longitude": 105.80932305029299,
        "from_longitude": 105.80876515081316,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4294,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80932305029299,
          21.021684550564263,
          105.80964491537473,
          21.022225347458807
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80932305029299,
            21.021684550564263
          ],
          [
            105.80964491537473,
            21.022225347458807
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 71,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "30",
        "diemCuoi": "28",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.021684550564263,
        "to_latitude": 21.022225347458807,
        "to_longitude": 105.80964491537473,
        "from_longitude": 105.80932305029299,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4295,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80964491537473,
          21.022225347458807,
          105.80989704301926,
          21.022736098280227
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80964491537473,
            21.022225347458807
          ],
          [
            105.80984239298488,
            21.022567100040888
          ],
          [
            105.80989704301926,
            21.022736098280227
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 73,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "32",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.022225347458807,
        "to_latitude": 21.022736098280227,
        "to_longitude": 105.80989704301926,
        "from_longitude": 105.80964491537473,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4296,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80989704301926,
          21.022736098280227,
          105.81010089090648,
          21.023066583173442
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80989704301926,
            21.022736098280227
          ],
          [
            105.81010089090648,
            21.023066583173442
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "32",
        "diemCuoi": "34",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.022736098280227,
        "to_latitude": 21.023066583173442,
        "to_longitude": 105.81010089090648,
        "from_longitude": 105.80989704301926,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4297,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81010089090648,
          21.023066583173442,
          105.81046567133009,
          21.023642426312875
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81010089090648,
            21.023066583173442
          ],
          [
            105.81046567133009,
            21.023642426312875
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 73,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.023066583173442,
        "to_latitude": 21.023642426312875,
        "to_longitude": 105.81046567133009,
        "from_longitude": 105.81010089090648,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4298,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81046567133009,
          21.023642426312875,
          105.81080899408273,
          21.02423328913468
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81046567133009,
            21.023642426312875
          ],
          [
            105.81080899408273,
            21.02423328913468
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 73,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "35",
        "diemCuoi": "37",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.023642426312875,
        "to_latitude": 21.02423328913468,
        "to_longitude": 105.81080899408273,
        "from_longitude": 105.81046567133009,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4299,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81080899408273,
          21.02423328913468,
          105.81107922661606,
          21.024689891799156
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81080899408273,
            21.02423328913468
          ],
          [
            105.81107922661606,
            21.024689891799156
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 73,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "37",
        "diemCuoi": "39",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02423328913468,
        "to_latitude": 21.024689891799156,
        "to_longitude": 105.81107922661606,
        "from_longitude": 105.81080899408273,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4300,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81054613759828,
          21.023992938438752,
          105.81083581617811,
          21.02450117955735
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81083581617811,
            21.02450117955735
          ],
          [
            105.81054613759828,
            21.023992938438752
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 74,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "36",
        "diemCuoi": "38",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02450117955735,
        "to_latitude": 21.023992938438752,
        "to_longitude": 105.81054613759828,
        "from_longitude": 105.81083581617811,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4301,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80995068720107,
          21.02293639224208,
          105.81054613759828,
          21.023992938438752
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81054613759828,
            21.023992938438752
          ],
          [
            105.80995068720107,
            21.02293639224208
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 138,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "33",
        "diemCuoi": "36",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.023992938438752,
        "to_latitude": 21.02293639224208,
        "to_longitude": 105.80995068720107,
        "from_longitude": 105.81054613759828,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4302,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80968648961272,
          21.02252578932954,
          105.80995068720107,
          21.02293639224208
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80995068720107,
            21.02293639224208
          ],
          [
            105.80968648961272,
            21.02252578932954
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 76,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Nguyễn Chí Thanh",
        "diemDau": "33",
        "diemCuoi": "31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02293639224208,
        "to_latitude": 21.02252578932954,
        "to_longitude": 105.80968648961272,
        "from_longitude": 105.80995068720107,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4303,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83073867088459,
          21.001391200658137,
          105.83105481068911,
          21.00152103690147
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83073867088459,
            21.00152103690147
          ],
          [
            105.83105481068911,
            21.001391200658137
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "diemDau": "6-3",
        "diemCuoi": "6-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4",
        "from_latitude": 21.00152103690147,
        "to_latitude": 21.001391200658137,
        "to_longitude": 105.83105481068911,
        "from_longitude": 105.83073867088459,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4304,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83105481068911,
          21.00129980337165,
          105.83135387699605,
          21.001391200658137
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83105481068911,
            21.001391200658137
          ],
          [
            105.83135387699605,
            21.00129980337165
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "diemDau": "6-4",
        "diemCuoi": "6-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4",
        "from_latitude": 21.001391200658137,
        "to_latitude": 21.00129980337165,
        "to_longitude": 105.83135387699605,
        "from_longitude": 105.83105481068911,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4305,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83165026108631,
          21.00113078084365,
          105.8319426218739,
          21.001210910065552
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83165026108631,
            21.001210910065552
          ],
          [
            105.8319426218739,
            21.00113078084365
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "diemDau": "6-6",
        "diemCuoi": "6-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4",
        "from_latitude": 21.001210910065552,
        "to_latitude": 21.00113078084365,
        "to_longitude": 105.8319426218739,
        "from_longitude": 105.83165026108631,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4306,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8319426218739,
          21.001044391477706,
          105.83223766486029,
          21.00113078084365
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8319426218739,
            21.00113078084365
          ],
          [
            105.83223766486029,
            21.001044391477706
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "diemDau": "6-7",
        "diemCuoi": "6-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4",
        "from_latitude": 21.00113078084365,
        "to_latitude": 21.001044391477706,
        "to_longitude": 105.83223766486029,
        "from_longitude": 105.8319426218739,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4307,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83105454584258,
          21.001390083203205,
          105.83109252926805,
          21.001538782119304
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83105454584258,
            21.001390083203205
          ],
          [
            105.83109252926805,
            21.001538782119304
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "diemDau": "6-4",
        "diemCuoi": "6-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4",
        "from_latitude": 21.001390083203205,
        "to_latitude": 21.001538782119304,
        "to_longitude": 105.83109252926805,
        "from_longitude": 105.83105454584258,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4308,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83109252926805,
          21.00146366114022,
          105.83137684343245,
          21.001538782119304
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83109252926805,
            21.001538782119304
          ],
          [
            105.83137684343245,
            21.00146366114022
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "diemDau": "6-9",
        "diemCuoi": "6-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4",
        "from_latitude": 21.001538782119304,
        "to_latitude": 21.00146366114022,
        "to_longitude": 105.83137684343245,
        "from_longitude": 105.83109252926805,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4309,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83137684343245,
          21.00137601994934,
          105.83167322752267,
          21.00146366114022
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83137684343245,
            21.00146366114022
          ],
          [
            105.83167322752267,
            21.00137601994934
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "diemDau": "6-10",
        "diemCuoi": "6-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4",
        "from_latitude": 21.00146366114022,
        "to_latitude": 21.00137601994934,
        "to_longitude": 105.83167322752267,
        "from_longitude": 105.83137684343245,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4310,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83167322752267,
          21.001305906959605,
          105.83197095272575,
          21.00137601994934
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83167322752267,
            21.00137601994934
          ],
          [
            105.83197095272575,
            21.001305906959605
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "diemDau": "6-11",
        "diemCuoi": "6-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4",
        "from_latitude": 21.00137601994934,
        "to_latitude": 21.001305906959605,
        "to_longitude": 105.83197095272575,
        "from_longitude": 105.83167322752267,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4311,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83197095272575,
          21.001270850452347,
          105.83230086443074,
          21.001305906959605
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83197095272575,
            21.001305906959605
          ],
          [
            105.83214931963045,
            21.001270850452347
          ],
          [
            105.83230086443074,
            21.001303402923995
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "diemDau": "6-12",
        "diemCuoi": "6-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4",
        "from_latitude": 21.001305906959605,
        "to_latitude": 21.001303402923995,
        "to_longitude": 105.83230086443074,
        "from_longitude": 105.83197095272575,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4312,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83230086443074,
          21.001230785862468,
          105.83269380806256,
          21.001303402923995
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83230086443074,
            21.001303402923995
          ],
          [
            105.83269380806256,
            21.001230785862468
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "diemDau": "6-13",
        "diemCuoi": "6-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4",
        "from_latitude": 21.001303402923995,
        "to_latitude": 21.001230785862468,
        "to_longitude": 105.83269380806256,
        "from_longitude": 105.83230086443074,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4313,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83269380806256,
          21.001103079911502,
          105.83309211608294,
          21.001230785862468
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83269380806256,
            21.001230785862468
          ],
          [
            105.83309211608294,
            21.001103079911502
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "diemDau": "6-14",
        "diemCuoi": "6-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4",
        "from_latitude": 21.001230785862468,
        "to_latitude": 21.001103079911502,
        "to_longitude": 105.83309211608294,
        "from_longitude": 105.83269380806256,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4314,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83303981302298,
          21.00083264341887,
          105.83309211608294,
          21.001103079911502
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83309211608294,
            21.001103079911502
          ],
          [
            105.83303981302298,
            21.00083264341887
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "diemDau": "6-15",
        "diemCuoi": "6-16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4",
        "from_latitude": 21.001103079911502,
        "to_latitude": 21.00083264341887,
        "to_longitude": 105.83303981302298,
        "from_longitude": 105.83309211608294,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4315,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83135119478833,
          21.00121216208538,
          105.83165160219914,
          21.00129980337165
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83135119478833,
            21.00129980337165
          ],
          [
            105.83165160219914,
            21.00121216208538
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT4",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "diemDau": "6-5",
        "diemCuoi": "6-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT4",
        "from_latitude": 21.00129980337165,
        "to_latitude": 21.00121216208538,
        "to_longitude": 105.83165160219914,
        "from_longitude": 105.83135119478833,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4316,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83750468511796,
          21.02023521773622,
          105.83758246916942,
          21.02029530696103
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83758246916942,
            21.02023521773622
          ],
          [
            105.83750468511796,
            21.02029530696103
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "Tủ VC2",
        "diemCuoi": "VC2 01",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02023521773622,
        "to_latitude": 21.02029530696103,
        "to_longitude": 105.83750468511796,
        "from_longitude": 105.83758246916942,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4317,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83751541393993,
          21.02023521773622,
          105.83758515137717,
          21.02062078984199
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83758246916942,
            21.02023521773622
          ],
          [
            105.83758515137717,
            21.02048308563313
          ],
          [
            105.83751541393993,
            21.02062078984199
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "Tủ VC2",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02023521773622,
        "to_latitude": 21.02062078984199,
        "to_longitude": 105.83751541393993,
        "from_longitude": 105.83758246916942,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4318,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83748859185353,
          21.02062078984199,
          105.83751541393993,
          21.020856138558795
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83751541393993,
            21.02062078984199
          ],
          [
            105.83748859185353,
            21.020856138558795
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02062078984199,
        "to_latitude": 21.020856138558795,
        "to_longitude": 105.83748859185353,
        "from_longitude": 105.83751541393993,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4319,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83745908755041,
          21.020856138558795,
          105.83748859185353,
          21.021156583189526
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83748859185353,
            21.020856138558795
          ],
          [
            105.8374724985981,
            21.020938760892808
          ],
          [
            105.83745908755041,
            21.021038909115106
          ],
          [
            105.8374724985981,
            21.021156583189526
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.020856138558795,
        "to_latitude": 21.021156583189526,
        "to_longitude": 105.8374724985981,
        "from_longitude": 105.83748859185353,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4320,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8374724985981,
          21.021156583189526,
          105.83751809614768,
          21.02141446434813
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8374724985981,
            21.021156583189526
          ],
          [
            105.83749395626901,
            21.021231694253025
          ],
          [
            105.83751809614768,
            21.02133935337753
          ],
          [
            105.83749663847676,
            21.02141446434813
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021156583189526,
        "to_latitude": 21.02141446434813,
        "to_longitude": 105.83749663847676,
        "from_longitude": 105.8374724985981,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4321,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83743740723764,
          21.02141446434813,
          105.83750200289224,
          21.0216462614147
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83749663847676,
            21.02141446434813
          ],
          [
            105.83747518080584,
            21.021527130733897
          ],
          [
            105.83750200289224,
            21.021569693568445
          ],
          [
            105.83743740723764,
            21.0216462614147
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02141446434813,
        "to_latitude": 21.0216462614147,
        "to_longitude": 105.83743740723764,
        "from_longitude": 105.83749663847676,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4322,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83725124090138,
          21.0216462614147,
          105.83743740723764,
          21.021816364348616
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83743740723764,
            21.0216462614147
          ],
          [
            105.83725124090138,
            21.021816364348616
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.0216462614147,
        "to_latitude": 21.021816364348616,
        "to_longitude": 105.83725124090138,
        "from_longitude": 105.83743740723764,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4323,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83710195240279,
          21.021816364348616,
          105.83725124090138,
          21.02198403151525
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83725124090138,
            21.021816364348616
          ],
          [
            105.83710195240279,
            21.02198403151525
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021816364348616,
        "to_latitude": 21.02198403151525,
        "to_longitude": 105.83710195240279,
        "from_longitude": 105.83725124090138,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4324,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83689183418777,
          21.02198403151525,
          105.83710195240279,
          21.0222351549375
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83710195240279,
            21.02198403151525
          ],
          [
            105.83689183418777,
            21.0222351549375
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02198403151525,
        "to_latitude": 21.0222351549375,
        "to_longitude": 105.83689183418777,
        "from_longitude": 105.83710195240279,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4325,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83738752201464,
          21.020483085633423,
          105.8375878335849,
          21.02048308563397
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8375878335849,
            21.02048308563397
          ],
          [
            105.83738752201464,
            21.020483085633423
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT A1 Văn Chương",
        "diemDau": "02",
        "diemCuoi": "VC2 2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02048308563397,
        "to_latitude": 21.020483085633423,
        "to_longitude": 105.83738752201464,
        "from_longitude": 105.8375878335849,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4326,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83111935130056,
          21.005346427242877,
          105.83115153781142,
          21.00546536568145
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83111935130056,
            21.005346427242877
          ],
          [
            105.83115153781142,
            21.00546536568145
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chợ Khương Thượng",
        "diemDau": "1",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.005346427242877,
        "to_latitude": 21.00546536568145,
        "to_longitude": 105.83115153781142,
        "from_longitude": 105.83111935130056,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4327,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83115153781142,
          21.00546536568145,
          105.8312454151273,
          21.00577585715944
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83115153781142,
            21.00546536568145
          ],
          [
            105.8312454151273,
            21.00577585715944
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chợ Khương Thượng",
        "diemDau": "1-1",
        "diemCuoi": "1-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.00546536568145,
        "to_latitude": 21.00577585715944,
        "to_longitude": 105.8312454151273,
        "from_longitude": 105.83115153781142,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4328,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8312454151273,
          21.00577585715944,
          105.83133661023544,
          21.00582218042919
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8312454151273,
            21.00577585715944
          ],
          [
            105.83133661023544,
            21.00582218042919
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chợ Khương Thượng",
        "diemDau": "1-2",
        "diemCuoi": "1-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.00577585715944,
        "to_latitude": 21.00582218042919,
        "to_longitude": 105.83133661023544,
        "from_longitude": 105.8312454151273,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4329,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83111935130056,
          21.005305111763292,
          105.8313164936684,
          21.005346427242877
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83111935130056,
            21.005346427242877
          ],
          [
            105.8313164936684,
            21.005305111763292
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chợ Khương Thượng",
        "diemDau": "1",
        "diemCuoi": "2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.005346427242877,
        "to_latitude": 21.005305111763292,
        "to_longitude": 105.8313164936684,
        "from_longitude": 105.83111935130056,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4330,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8313164936684,
          21.00528758398043,
          105.83144792191064,
          21.005305111763292
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8313164936684,
            21.005305111763292
          ],
          [
            105.83144792191064,
            21.00528758398043
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "diemDau": "2",
        "diemCuoi": "3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.005305111763292,
        "to_latitude": 21.00528758398043,
        "to_longitude": 105.83144792191064,
        "from_longitude": 105.8313164936684,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4331,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83144792191064,
          21.005224984738838,
          105.83179258576718,
          21.00528758398043
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83144792191064,
            21.00528758398043
          ],
          [
            105.83179258576718,
            21.005224984738838
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "diemDau": "3",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.00528758398043,
        "to_latitude": 21.005224984738838,
        "to_longitude": 105.83179258576718,
        "from_longitude": 105.83144792191064,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4332,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83179258576718,
          21.00516238547185,
          105.83211042753732,
          21.005224984738838
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83179258576718,
            21.005224984738838
          ],
          [
            105.83211042753732,
            21.00516238547185
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "diemDau": "24",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.005224984738838,
        "to_latitude": 21.00516238547185,
        "to_longitude": 105.83211042753732,
        "from_longitude": 105.83179258576718,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4333,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83201386801369,
          21.00516238547185,
          105.83211042753732,
          21.00530010382544
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83211042753732,
            21.00516238547185
          ],
          [
            105.83201386801369,
            21.00530010382544
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Đông Tác",
        "diemDau": "23",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.00516238547185,
        "to_latitude": 21.00530010382544,
        "to_longitude": 105.83201386801369,
        "from_longitude": 105.83211042753732,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4334,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83189585081917,
          21.00530010382544,
          105.83201386801369,
          21.005332655418194
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83201386801369,
            21.00530010382544
          ],
          [
            105.83189585081917,
            21.005332655418194
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Đông Tác",
        "diemDau": "22",
        "diemCuoi": "22-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.00530010382544,
        "to_latitude": 21.005332655418194,
        "to_longitude": 105.83189585081917,
        "from_longitude": 105.83201386801369,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4335,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83201386801369,
          21.00530010382544,
          105.83209433428189,
          21.005540484647007
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83201386801369,
            21.00530010382544
          ],
          [
            105.83209433428189,
            21.005540484647007
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Đông Tác",
        "diemDau": "22",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.00530010382544,
        "to_latitude": 21.005540484647007,
        "to_longitude": 105.83209433428189,
        "from_longitude": 105.83201386801369,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4336,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83178051583235,
          21.005540484647007,
          105.83209433428189,
          21.005685714538927
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83209433428189,
            21.005540484647007
          ],
          [
            105.83178051583235,
            21.005685714538927
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Đông Tác",
        "diemDau": "21",
        "diemCuoi": "21-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.005540484647007,
        "to_latitude": 21.005685714538927,
        "to_longitude": 105.83178051583235,
        "from_longitude": 105.83209433428189,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4337,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83209433428189,
          21.005540484647007,
          105.83225526682726,
          21.00573328981774
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83209433428189,
            21.005540484647007
          ],
          [
            105.83215736620873,
            21.005708250197678
          ],
          [
            105.83225526682726,
            21.00573328981774
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Đông Tác",
        "diemDau": "21",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.005540484647007,
        "to_latitude": 21.00573328981774,
        "to_longitude": 105.83225526682726,
        "from_longitude": 105.83209433428189,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4338,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83195754162416,
          21.00573328981774,
          105.83225526682726,
          21.005823432409514
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83225526682726,
            21.00573328981774
          ],
          [
            105.83195754162416,
            21.005823432409514
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đông Tác",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.00573328981774,
        "to_latitude": 21.005823432409514,
        "to_longitude": 105.83195754162416,
        "from_longitude": 105.83225526682726,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4339,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83225526682726,
          21.005605587719693,
          105.83237060181406,
          21.00573328981774
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83225526682726,
            21.00573328981774
          ],
          [
            105.83237060181406,
            21.005605587719693
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Đông Tác",
        "diemDau": "18",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.00573328981774,
        "to_latitude": 21.005605587719693,
        "to_longitude": 105.83237060181406,
        "from_longitude": 105.83225526682726,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4340,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8323464619354,
          21.005397758581537,
          105.83237060181406,
          21.005605587719693
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83237060181406,
            21.005605587719693
          ],
          [
            105.8323464619354,
            21.005397758581537
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Đông Tác",
        "diemDau": "18",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.005605587719693,
        "to_latitude": 21.005397758581537,
        "to_longitude": 105.8323464619354,
        "from_longitude": 105.83237060181406,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4341,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83232232204776,
          21.005212464888423,
          105.8323464619354,
          21.005397758581537
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8323464619354,
            21.005397758581537
          ],
          [
            105.83232232204776,
            21.005212464888423
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Đông Tác",
        "diemDau": "17",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.005397758581537,
        "to_latitude": 21.005212464888423,
        "to_longitude": 105.83232232204776,
        "from_longitude": 105.8323464619354,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4342,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83228208891366,
          21.004984603409877,
          105.83232232204776,
          21.005212464888423
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83232232204776,
            21.005212464888423
          ],
          [
            105.83228208891366,
            21.004984603409877
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Đông Tác",
        "diemDau": "16",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.005212464888423,
        "to_latitude": 21.004984603409877,
        "to_longitude": 105.83228208891366,
        "from_longitude": 105.83232232204776,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4343,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83222978586268,
          21.004662842376284,
          105.83228208891366,
          21.004984603409877
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83228208891366,
            21.004984603409877
          ],
          [
            105.83222978586268,
            21.004662842376284
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Đông Tác",
        "diemDau": "15",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.004984603409877,
        "to_latitude": 21.004662842376284,
        "to_longitude": 105.83222978586268,
        "from_longitude": 105.83228208891366,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4344,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83141439432285,
          21.004970831550104,
          105.83144792191064,
          21.00528758398043
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83144792191064,
            21.00528758398043
          ],
          [
            105.83141439432285,
            21.004970831550104
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "diemDau": "3",
        "diemCuoi": "4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.00528758398043,
        "to_latitude": 21.004970831550104,
        "to_longitude": 105.83141439432285,
        "from_longitude": 105.83144792191064,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4345,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83141439432285,
          21.00493577590377,
          105.83162092441778,
          21.004970831550104
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83141439432285,
            21.004970831550104
          ],
          [
            105.83162092441778,
            21.00493577590377
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "diemDau": "4",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.004970831550104,
        "to_latitude": 21.00493577590377,
        "to_longitude": 105.83162092441778,
        "from_longitude": 105.83141439432285,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4346,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83162092441778,
          21.004868168563092,
          105.83196424717046,
          21.00493577590377
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83162092441778,
            21.00493577590377
          ],
          [
            105.83196424717046,
            21.004868168563092
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.00493577590377,
        "to_latitude": 21.004868168563092,
        "to_longitude": 105.83196424717046,
        "from_longitude": 105.83162092441778,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4347,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83153509372514,
          21.004682874212318,
          105.83184891218367,
          21.00474797765916
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83184891218367,
            21.004682874212318
          ],
          [
            105.83153509372514,
            21.00474797765916
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "diemDau": "5-1",
        "diemCuoi": "5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.004682874212318,
        "to_latitude": 21.00474797765916,
        "to_longitude": 105.83153509372514,
        "from_longitude": 105.83184891218367,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4348,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83136209124494,
          21.004684126202196,
          105.83153509372514,
          21.00474797765916
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83153509372514,
            21.00474797765916
          ],
          [
            105.83136209124494,
            21.004684126202196
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "diemDau": "5",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.00474797765916,
        "to_latitude": 21.004684126202196,
        "to_longitude": 105.83136209124494,
        "from_longitude": 105.83153509372514,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4349,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83136209124494,
          21.004684126202196,
          105.83141439432285,
          21.004970831550104
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83141439432285,
            21.004970831550104
          ],
          [
            105.83136209124494,
            21.004684126202196
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "diemDau": "4",
        "diemCuoi": "5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.004970831550104,
        "to_latitude": 21.004684126202196,
        "to_longitude": 105.83136209124494,
        "from_longitude": 105.83141439432285,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4350,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83119177097248,
          21.004684126202196,
          105.83136209124494,
          21.004800561190898
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83136209124494,
            21.004684126202196
          ],
          [
            105.83119177097248,
            21.004800561190898
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A8 A9 A10 A11 Khương Thượng",
        "diemDau": "5",
        "diemCuoi": "5-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.004684126202196,
        "to_latitude": 21.004800561190898,
        "to_longitude": 105.83119177097248,
        "from_longitude": 105.83136209124494,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4351,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83100669854842,
          21.004800561190898,
          105.83119177097248,
          21.004838120846156
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83119177097248,
            21.004800561190898
          ],
          [
            105.83100669854842,
            21.004838120846156
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A8 A9 A10 A11 Khương Thượng",
        "diemDau": "5-3",
        "diemCuoi": "5-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.004800561190898,
        "to_latitude": 21.004838120846156,
        "to_longitude": 105.83100669854842,
        "from_longitude": 105.83119177097248,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4352,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83131649367739,
          21.004451255951395,
          105.83136209124494,
          21.004684126202196
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83136209124494,
            21.004684126202196
          ],
          [
            105.8313285636212,
            21.004508847550603
          ],
          [
            105.83131649367739,
            21.004451255951395
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "diemDau": "5",
        "diemCuoi": "6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.004684126202196,
        "to_latitude": 21.004451255951395,
        "to_longitude": 105.83131649367739,
        "from_longitude": 105.83136209124494,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4353,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8313285636212,
          21.004465027858238,
          105.83154179923551,
          21.004508847550603
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8313285636212,
            21.004508847550603
          ],
          [
            105.83154179923551,
            21.004465027858238
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "diemDau": "6",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.004508847550603,
        "to_latitude": 21.004465027858238,
        "to_longitude": 105.83154179923551,
        "from_longitude": 105.8313285636212,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4354,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83154179923551,
          21.004381144406366,
          105.83194681279319,
          21.004465027858238
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83154179923551,
            21.004465027858238
          ],
          [
            105.83194681279319,
            21.004381144406366
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "diemDau": "6-1",
        "diemCuoi": "6-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.004465027858238,
        "to_latitude": 21.004381144406366,
        "to_longitude": 105.83194681279319,
        "from_longitude": 105.83154179923551,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4355,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83194681279319,
          21.004336072682072,
          105.83217211835132,
          21.004381144406366
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83194681279319,
            21.004381144406366
          ],
          [
            105.83217211835132,
            21.004336072682072
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "diemDau": "6-2",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.004381144406366,
        "to_latitude": 21.004336072682072,
        "to_longitude": 105.83217211835132,
        "from_longitude": 105.83194681279319,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4356,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83078139298132,
          21.004532635381594,
          105.83104424945681,
          21.00458396699934
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83078139298132,
            21.00458396699934
          ],
          [
            105.83104424945681,
            21.004532635381594
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A8 A9 A10 A11 Khương Thượng",
        "diemDau": "6-3",
        "diemCuoi": "6-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.00458396699934,
        "to_latitude": 21.004532635381594,
        "to_longitude": 105.83104424945681,
        "from_longitude": 105.83078139298132,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4357,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83104424945681,
          21.004451255951395,
          105.83131649367739,
          21.004532635381594
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83104424945681,
            21.004532635381594
          ],
          [
            105.83131649367739,
            21.004451255951395
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A8 A9 A10 A11 Khương Thượng",
        "diemDau": "6",
        "diemCuoi": "6-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.004532635381594,
        "to_latitude": 21.004451255951395,
        "to_longitude": 105.83131649367739,
        "from_longitude": 105.83104424945681,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4358,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83081626170892,
          21.004838120846156,
          105.83100669854842,
          21.004883192418838
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83100669854842,
            21.004838120846156
          ],
          [
            105.83081626170892,
            21.004883192418838
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A8 A9 A10 A11 Khương Thượng",
        "diemDau": "5-4",
        "diemCuoi": "5-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.004838120846156,
        "to_latitude": 21.004883192418838,
        "to_longitude": 105.83081626170892,
        "from_longitude": 105.83100669854842,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4359,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83128967158198,
          21.004260953110183,
          105.83131649367739,
          21.004451255951395
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83131649367739,
            21.004451255951395
          ],
          [
            105.83128967158198,
            21.004260953110183
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "diemDau": "6",
        "diemCuoi": "7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.004451255951395,
        "to_latitude": 21.004260953110183,
        "to_longitude": 105.83128967158198,
        "from_longitude": 105.83131649367739,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4360,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83128967158198,
          21.004212125368973,
          105.83149888388469,
          21.004260953110183
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83128967158198,
            21.004260953110183
          ],
          [
            105.83149888388469,
            21.004212125368973
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "diemDau": "7",
        "diemCuoi": "9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.004260953110183,
        "to_latitude": 21.004212125368973,
        "to_longitude": 105.83149888388469,
        "from_longitude": 105.83128967158198,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4361,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83149888388469,
          21.00417331356284,
          105.83168663851646,
          21.004212125368973
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83149888388469,
            21.004212125368973
          ],
          [
            105.83168663851646,
            21.00417331356284
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "diemDau": "9",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.004212125368973,
        "to_latitude": 21.00417331356284,
        "to_longitude": 105.83168663851646,
        "from_longitude": 105.83149888388469,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4362,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83168663851646,
          21.00412949376948,
          105.83193206064162,
          21.00417331356284
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83168663851646,
            21.00417331356284
          ],
          [
            105.83193206064162,
            21.00412949376948
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.00417331356284,
        "to_latitude": 21.00412949376948,
        "to_longitude": 105.83193206064162,
        "from_longitude": 105.83168663851646,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4363,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83193206064162,
          21.00411321784316,
          105.83213188520824,
          21.00412949376948
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83193206064162,
            21.00412949376948
          ],
          [
            105.83213188520824,
            21.00411321784316
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.00412949376948,
        "to_latitude": 21.00411321784316,
        "to_longitude": 105.83213188520824,
        "from_longitude": 105.83193206064162,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4364,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83104022614519,
          21.004260953110183,
          105.83128967158198,
          21.004292252936462
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83104022614519,
            21.004292252936462
          ],
          [
            105.83128967158198,
            21.004260953110183
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A8 A9 A10 A11 Khương Thượng",
        "diemDau": "7",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.004292252936462,
        "to_latitude": 21.004260953110183,
        "to_longitude": 105.83128967158198,
        "from_longitude": 105.83104022614519,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4365,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83070226780801,
          21.004260953110183,
          105.83128967158198,
          21.004363616514958
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83128967158198,
            21.004260953110183
          ],
          [
            105.83070226780801,
            21.004363616514958
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KT1",
        "chieuDaiQL": 63,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A8 A9 A10 A11 Khương Thượng",
        "diemDau": "7",
        "diemCuoi": "8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KT1",
        "from_latitude": 21.004260953110183,
        "to_latitude": 21.004363616514958,
        "to_longitude": 105.83070226780801,
        "from_longitude": 105.83128967158198,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4366,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82219094802582,
          21.008098575834808,
          105.82237903790886,
          21.008163677790506
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82237903790886,
            21.008098575834808
          ],
          [
            105.82232539374505,
            21.008163677790506
          ],
          [
            105.82219094802582,
            21.00811985916813
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH7",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252/53 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH7",
        "from_latitude": 21.008098575834808,
        "to_latitude": 21.00811985916813,
        "to_longitude": 105.82219094802582,
        "from_longitude": 105.82237903790886,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4367,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82653377966854,
          21.001917829490864,
          105.82680635918531,
          21.00197636107951
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82653377966854,
            21.00197636107951
          ],
          [
            105.82680635918531,
            21.001917829490864
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "diemDau": "36",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00197636107951,
        "to_latitude": 21.001917829490864,
        "to_longitude": 105.82680635918531,
        "from_longitude": 105.82653377966854,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4368,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82680635918531,
          21.001905309362115,
          105.82700216044927,
          21.001932853644487
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82680635918531,
            21.001917829490864
          ],
          [
            105.82696863283452,
            21.001905309362115
          ],
          [
            105.82700216044927,
            21.001932853644487
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "diemDau": "35",
        "diemCuoi": "34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.001917829490864,
        "to_latitude": 21.001932853644487,
        "to_longitude": 105.82700216044927,
        "from_longitude": 105.82680635918531,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4369,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82700216044927,
          21.001932853644487,
          105.82715906967402,
          21.001964153959058
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82700216044927,
            21.001932853644487
          ],
          [
            105.82715906967402,
            21.001964153959058
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "diemDau": "34",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.001932853644487,
        "to_latitude": 21.001964153959058,
        "to_longitude": 105.82715906967402,
        "from_longitude": 105.82700216044927,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4370,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8271376120031,
          21.00196415395905,
          105.82715906967402,
          21.002081843081655
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82715906967402,
            21.00196415395905
          ],
          [
            105.8271376120031,
            21.002081843081655
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00196415395905,
        "to_latitude": 21.002081843081655,
        "to_longitude": 105.8271376120031,
        "from_longitude": 105.82715906967402,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4371,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82715906967402,
          21.001926593580432,
          105.82749568690734,
          21.001964153959058
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82715906967402,
            21.001964153959058
          ],
          [
            105.82749568690734,
            21.001926593580432
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "diemDau": "32",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.001964153959058,
        "to_latitude": 21.001926593580432,
        "to_longitude": 105.82749568690734,
        "from_longitude": 105.82715906967402,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4372,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82749568690734,
          21.00162955325967,
          105.8275657596138,
          21.001926593580432
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82749568690734,
            21.001926593580432
          ],
          [
            105.8275657596138,
            21.00189748428268
          ],
          [
            105.82755905409444,
            21.00162955325967
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.001926593580432,
        "to_latitude": 21.00162955325967,
        "to_longitude": 105.82755905409444,
        "from_longitude": 105.82749568690734,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4373,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82749568690734,
          21.001926593580432,
          105.82752418537585,
          21.002095302205763
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82749568690734,
            21.001926593580432
          ],
          [
            105.82752418537585,
            21.002095302205763
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "diemDau": "26",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.001926593580432,
        "to_latitude": 21.002095302205763,
        "to_longitude": 105.82752418537585,
        "from_longitude": 105.82749568690734,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4374,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82752418537585,
          21.002095302205763,
          105.82754698415064,
          21.002246795500557
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82752418537585,
            21.002095302205763
          ],
          [
            105.82754698415064,
            21.002246795500557
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002095302205763,
        "to_latitude": 21.002246795500557,
        "to_longitude": 105.82754698415064,
        "from_longitude": 105.82752418537585,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4375,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82752418537585,
          21.002045221743156,
          105.82785275597693,
          21.002095302205763
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82752418537585,
            21.002095302205763
          ],
          [
            105.82785275597693,
            21.002045221743156
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "diemDau": "28",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002095302205763,
        "to_latitude": 21.002045221743156,
        "to_longitude": 105.82785275597693,
        "from_longitude": 105.82752418537585,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4376,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82785275597693,
          21.002045221743156,
          105.82807537932733,
          21.002245543490247
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82785275597693,
            21.002045221743156
          ],
          [
            105.82789701242264,
            21.002245543490247
          ],
          [
            105.82807537932733,
            21.002229267358484
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002045221743156,
        "to_latitude": 21.002229267358484,
        "to_longitude": 105.82807537932733,
        "from_longitude": 105.82785275597693,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4377,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82749568690734,
          21.00188496415221,
          105.82786884923239,
          21.001926593580432
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82749568690734,
            21.001926593580432
          ],
          [
            105.82786884923239,
            21.00188496415221
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "diemDau": "26",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.001926593580432,
        "to_latitude": 21.00188496415221,
        "to_longitude": 105.82786884923239,
        "from_longitude": 105.82749568690734,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4378,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82786884923239,
          21.00183613563362,
          105.82821351308893,
          21.00188496415221
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82786884923239,
            21.00188496415221
          ],
          [
            105.82821351308893,
            21.00183613563362
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "diemDau": "25",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00188496415221,
        "to_latitude": 21.00183613563362,
        "to_longitude": 105.82821351308893,
        "from_longitude": 105.82786884923239,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4379,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82821351308893,
          21.00175225070408,
          105.82848307510176,
          21.00183613563362
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82821351308893,
            21.00183613563362
          ],
          [
            105.82848307510176,
            21.00175225070408
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "diemDau": "24",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00183613563362,
        "to_latitude": 21.00175225070408,
        "to_longitude": 105.82848307510176,
        "from_longitude": 105.82821351308893,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4380,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82844418306253,
          21.00158448070435,
          105.82848307510176,
          21.001752250704055
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82848307510176,
            21.001752250704055
          ],
          [
            105.82844418306253,
            21.00158448070435
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.001752250704055,
        "to_latitude": 21.00158448070435,
        "to_longitude": 105.82844418306253,
        "from_longitude": 105.82848307510176,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4381,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82839992661683,
          21.00144175085448,
          105.82844418306253,
          21.00158448070435
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82844418306253,
            21.00158448070435
          ],
          [
            105.82839992661683,
            21.00144175085448
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "diemDau": "23",
        "diemCuoi": "23-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00158448070435,
        "to_latitude": 21.00144175085448,
        "to_longitude": 105.82839992661683,
        "from_longitude": 105.82844418306253,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4382,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82848307510176,
          21.00168213789104,
          105.82890015860325,
          21.001752250704055
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82848307510176,
            21.001752250704055
          ],
          [
            105.82890015860325,
            21.00168213789104
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "diemDau": "22",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.001752250704055,
        "to_latitude": 21.00168213789104,
        "to_longitude": 105.82890015860325,
        "from_longitude": 105.82848307510176,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4383,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82890015860325,
          21.001543477187536,
          105.82925119270095,
          21.00168213789104
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82890015860325,
            21.00168213789104
          ],
          [
            105.82925119270095,
            21.001543477187536
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "diemDau": "21",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00168213789104,
        "to_latitude": 21.001543477187536,
        "to_longitude": 105.82925119270095,
        "from_longitude": 105.82890015860325,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4384,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82925119270095,
          21.00154347718756,
          105.82944699396492,
          21.00190405735142
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82925119270095,
            21.00154347718756
          ],
          [
            105.82944699396492,
            21.00190405735142
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 54 Tôn Thất Tùng",
        "diemDau": "20",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00154347718756,
        "to_latitude": 21.00190405735142,
        "to_longitude": 105.82944699396492,
        "from_longitude": 105.82925119270095,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4385,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8293182479394,
          21.00190405735143,
          105.82944699396492,
          21.00204177871198
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82944699396492,
            21.00190405735143
          ],
          [
            105.8293182479394,
            21.00204177871198
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 54 Tôn Thất Tùng",
        "diemDau": "",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00190405735143,
        "to_latitude": 21.00204177871198,
        "to_longitude": 105.8293182479394,
        "from_longitude": 105.82944699396492,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4386,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82913988103473,
          21.00204177871198,
          105.8293182479394,
          21.002059306876088
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8293182479394,
            21.00204177871198
          ],
          [
            105.82913988103473,
            21.002059306876088
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 54 Tôn Thất Tùng",
        "diemDau": "16",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00204177871198,
        "to_latitude": 21.002059306876088,
        "to_longitude": 105.82913988103473,
        "from_longitude": 105.8293182479394,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4387,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82913988103473,
          21.002059306876088,
          105.82923241723778,
          21.002259628605113
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82913988103473,
            21.002059306876088
          ],
          [
            105.82923241723778,
            21.002259628605113
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 54 Tôn Thất Tùng",
        "diemDau": "",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002059306876088,
        "to_latitude": 21.002259628605113,
        "to_longitude": 105.82923241723778,
        "from_longitude": 105.82913988103473,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4388,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8289896773293,
          21.002059306876088,
          105.82913988103473,
          21.00212190744562
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82913988103473,
            21.002059306876088
          ],
          [
            105.8289896773293,
            21.00212190744562
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 54 Tôn Thất Tùng",
        "diemDau": "",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002059306876088,
        "to_latitude": 21.00212190744562,
        "to_longitude": 105.8289896773293,
        "from_longitude": 105.82913988103473,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4389,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82885824908708,
          21.00210813532248,
          105.8289896773293,
          21.002141939621865
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8289896773293,
            21.00212190744562
          ],
          [
            105.82895614971457,
            21.0021394356003
          ],
          [
            105.82893737425138,
            21.00210813532248
          ],
          [
            105.82885824908708,
            21.002141939621865
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 54 Tôn Thất Tùng",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.00212190744562,
        "to_latitude": 21.002141939621865,
        "to_longitude": 105.82885824908708,
        "from_longitude": 105.8289896773293,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4390,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81501637416262,
          21.016306206178395,
          105.81520949320988,
          21.016356281853195
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81501637416262,
            21.01635127428633
          ],
          [
            105.815104887072,
            21.016356281853195
          ],
          [
            105.81520949320988,
            21.016306206178395
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Thái Hà",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.01635127428633,
        "to_latitude": 21.016306206178395,
        "to_longitude": 105.81520949320988,
        "from_longitude": 105.81501637416262,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4391,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81520949320988,
          21.016060835127302,
          105.8155635448025,
          21.016306206178395
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81520949320988,
            21.016306206178395
          ],
          [
            105.8155635448025,
            21.016060835127302
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Thái Hà",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.016306206178395,
        "to_latitude": 21.016060835127302,
        "to_longitude": 105.8155635448025,
        "from_longitude": 105.81520949320988,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4392,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8155635448025,
          21.01578541816044,
          105.81597124056792,
          21.016060835127302
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8155635448025,
            21.016060835127302
          ],
          [
            105.81597124056792,
            21.01578541816044
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Thái Hà",
        "diemDau": "23",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.016060835127302,
        "to_latitude": 21.01578541816044,
        "to_longitude": 105.81597124056792,
        "from_longitude": 105.8155635448025,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4393,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81597124056792,
          21.015555069034395,
          105.8163091989051,
          21.01578541816044
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81597124056792,
            21.01578541816044
          ],
          [
            105.8163091989051,
            21.015555069034395
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Thái Hà",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.01578541816044,
        "to_latitude": 21.015555069034395,
        "to_longitude": 105.8163091989051,
        "from_longitude": 105.81597124056792,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4394,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8163091989051,
          21.015334734754262,
          105.81659351306051,
          21.015555069034395
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8163091989051,
            21.015555069034395
          ],
          [
            105.81659351306051,
            21.015334734754262
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Thái Hà",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.015555069034395,
        "to_latitude": 21.015334734754262,
        "to_longitude": 105.81659351306051,
        "from_longitude": 105.8163091989051,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4395,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81659351306051,
          21.015134430581302,
          105.81689928488684,
          21.015334734754262
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81659351306051,
            21.015334734754262
          ],
          [
            105.81689928488684,
            21.015134430581302
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Thái Hà",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.015334734754262,
        "to_latitude": 21.015134430581302,
        "to_longitude": 105.81689928488684,
        "from_longitude": 105.81659351306051,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4396,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81689928488684,
          21.01495916420917,
          105.81715677695584,
          21.015134430581302
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81689928488684,
            21.015134430581302
          ],
          [
            105.81715677695584,
            21.01495916420917
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Thái Hà",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.015134430581302,
        "to_latitude": 21.01495916420917,
        "to_longitude": 105.81715677695584,
        "from_longitude": 105.81689928488684,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4397,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81715677695584,
          21.014663714714676,
          105.81756447273025,
          21.01495916420917
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81715677695584,
            21.01495916420917
          ],
          [
            105.81756447273025,
            21.014663714714676
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 57,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Thái Hà",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "SoXayDung",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.01495916420917,
        "to_latitude": 21.014663714714676,
        "to_longitude": 105.81756447273025,
        "from_longitude": 105.81715677695584,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4398,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81756447273025,
          21.014403318065717,
          105.81790672259442,
          21.014663714714676
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81756447273025,
            21.014663714714676
          ],
          [
            105.81790672259442,
            21.014403318065717
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Thái Hà",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "SoXayDung",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.014663714714676,
        "to_latitude": 21.014403318065717,
        "to_longitude": 105.81790672259442,
        "from_longitude": 105.81756447273025,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4399,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81790672259442,
          21.014160447704203,
          105.8182532639991,
          21.014403318065717
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81790672259442,
            21.014403318065717
          ],
          [
            105.8182532639991,
            21.014160447704203
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Thái Hà",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.014403318065717,
        "to_latitude": 21.014160447704203,
        "to_longitude": 105.8182532639991,
        "from_longitude": 105.81790672259442,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4400,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8182532639991,
          21.013917576947208,
          105.81859980540375,
          21.014160447704203
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8182532639991,
            21.014160447704203
          ],
          [
            105.81859980540375,
            21.013917576947208
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Thái Hà",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.014160447704203,
        "to_latitude": 21.013917576947208,
        "to_longitude": 105.81859980540375,
        "from_longitude": 105.8182532639991,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4401,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8163091989051,
          21.015555069034395,
          105.81657607870122,
          21.0157666397146
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8163091989051,
            21.015555069034395
          ],
          [
            105.81657607870122,
            21.0157666397146
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đội CSGT số 4 phố Thái Hà",
        "diemDau": "24",
        "diemCuoi": "24-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.015555069034395,
        "to_latitude": 21.0157666397146,
        "to_longitude": 105.81657607870122,
        "from_longitude": 105.8163091989051,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4402,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81657607870122,
          21.0157666397146,
          105.816763833333,
          21.015944408927727
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81657607870122,
            21.0157666397146
          ],
          [
            105.816763833333,
            21.015944408927727
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đội CSGT số 4 phố Thái Hà",
        "diemDau": "24-1",
        "diemCuoi": "24-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.0157666397146,
        "to_latitude": 21.015944408927727,
        "to_longitude": 105.816763833333,
        "from_longitude": 105.81657607870122,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4403,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.816763833333,
          21.015944408927727,
          105.81692342478345,
          21.016162238518415
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.816763833333,
            21.015944408927727
          ],
          [
            105.81692342478345,
            21.016162238518415
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đội CSGT số 4 phố Thái Hà",
        "diemDau": "24-2",
        "diemCuoi": "24-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.015944408927727,
        "to_latitude": 21.016162238518415,
        "to_longitude": 105.81692342478345,
        "from_longitude": 105.816763833333,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4404,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81692342478345,
          21.016162238518415,
          105.81709642726366,
          21.016383823466853
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81692342478345,
            21.016162238518415
          ],
          [
            105.81709642726366,
            21.016383823466853
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đội CSGT số 4 phố Thái Hà",
        "diemDau": "24-3",
        "diemCuoi": "24-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.016162238518415,
        "to_latitude": 21.016383823466853,
        "to_longitude": 105.81709642726366,
        "from_longitude": 105.81692342478345,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4405,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82275354135572,
          21.003147927027015,
          105.8227682935073,
          21.003149179029784
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82275354135572,
            21.003149179029784
          ],
          [
            105.8227682935073,
            21.003147927027015
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 580 Trường Chinh",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng 1",
        "from_latitude": 21.003149179029784,
        "to_latitude": 21.003147927027015,
        "to_longitude": 105.8227682935073,
        "from_longitude": 105.82275354135572,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4406,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82259897903353,
          21.003149179029773,
          105.82275354135572,
          21.003217100151698
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82275354135572,
            21.003149179029773
          ],
          [
            105.82259897903353,
            21.003217100151698
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 580 Trường Chinh",
        "diemDau": "",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.003149179029773,
        "to_latitude": 21.003217100151698,
        "to_longitude": 105.82259897903353,
        "from_longitude": 105.82275354135572,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4407,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82239915446694,
          21.003217100151698,
          105.82259897903353,
          21.00330724426346
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82259897903353,
            21.003217100151698
          ],
          [
            105.82239915446694,
            21.00330724426346
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 580 Trường Chinh",
        "diemDau": "19",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.003217100151698,
        "to_latitude": 21.00330724426346,
        "to_longitude": 105.82239915446694,
        "from_longitude": 105.82259897903353,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4408,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82223822192155,
          21.003053087808528,
          105.82239915446694,
          21.00330724426346
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82239915446694,
            21.00330724426346
          ],
          [
            105.82223822192155,
            21.003053087808528
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 592 Trường Chinh",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.00330724426346,
        "to_latitude": 21.003053087808528,
        "to_longitude": 105.82223822192155,
        "from_longitude": 105.82239915446694,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4409,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82210679367934,
          21.00282647503824,
          105.82223822192155,
          21.003053087808528
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82223822192155,
            21.003053087808528
          ],
          [
            105.82210679367934,
            21.00282647503824
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 592 Trường Chinh",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.003053087808528,
        "to_latitude": 21.00282647503824,
        "to_longitude": 105.82210679367934,
        "from_longitude": 105.82223822192155,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4410,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8221027703677,
          21.003053087808528,
          105.82223822192155,
          21.00311067994984
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82223822192155,
            21.003053087808528
          ],
          [
            105.8221027703677,
            21.00311067994984
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 592 Trường Chinh",
        "diemDau": "24",
        "diemCuoi": "24-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.003053087808528,
        "to_latitude": 21.00311067994984,
        "to_longitude": 105.8221027703677,
        "from_longitude": 105.82223822192155,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4411,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82214032129406,
          21.00330724426346,
          105.82239915446694,
          21.003408656324602
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82239915446694,
            21.00330724426346
          ],
          [
            105.82214032129406,
            21.003408656324602
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 592 Trường Chinh",
        "diemDau": "23",
        "diemCuoi": "23-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.00330724426346,
        "to_latitude": 21.003408656324602,
        "to_longitude": 105.82214032129406,
        "from_longitude": 105.82239915446694,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4412,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82207997160191,
          21.003408656324602,
          105.8221456857275,
          21.00357517227452
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82214032129406,
            21.003408656324602
          ],
          [
            105.82207997160191,
            21.003448720329008
          ],
          [
            105.82208533602636,
            21.00351257231458
          ],
          [
            105.8221456857275,
            21.00357517227452
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 592 Trường Chinh",
        "diemDau": "23-1",
        "diemCuoi": "23-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.003408656324602,
        "to_latitude": 21.00357517227452,
        "to_longitude": 105.8221456857275,
        "from_longitude": 105.82214032129406,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4413,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8221456857275,
          21.00357517227452,
          105.82231332380123,
          21.00378801193982
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8221456857275,
            21.00357517227452
          ],
          [
            105.82231332380123,
            21.00378801193982
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 592 Trường Chinh",
        "diemDau": "23-2",
        "diemCuoi": "23-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.00357517227452,
        "to_latitude": 21.00378801193982,
        "to_longitude": 105.82231332380123,
        "from_longitude": 105.8221456857275,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4414,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81597124056792,
          21.01568401451229,
          105.81601683813547,
          21.01578541816044
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81599672156842,
            21.01568401451229
          ],
          [
            105.81601683813547,
            21.01573784608737
          ],
          [
            105.81597124056792,
            21.01578541816044
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Thái Hà",
        "diemDau": "tủ điện",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.01568401451229,
        "to_latitude": 21.01578541816044,
        "to_longitude": 105.81597124056792,
        "from_longitude": 105.81599672156842,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4415,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82239915446694,
          21.00330724426346,
          105.82254399379279,
          21.003560148283704
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82239915446694,
            21.00330724426346
          ],
          [
            105.82254399379279,
            21.003560148283704
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 592 Trường Chinh",
        "diemDau": "23",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.00330724426346,
        "to_latitude": 21.003560148283704,
        "to_longitude": 105.82254399379279,
        "from_longitude": 105.82239915446694,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4416,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82254399379279,
          21.003560148283704,
          105.82267944534665,
          21.003814303875064
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82254399379279,
            21.003560148283704
          ],
          [
            105.82267944534665,
            21.003814303875064
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 592 Trường Chinh",
        "diemDau": "22",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.003560148283704,
        "to_latitude": 21.003814303875064,
        "to_longitude": 105.82267944534665,
        "from_longitude": 105.82254399379279,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4417,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82267944534665,
          21.003814303875064,
          105.82284306009976,
          21.00408723895368
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82267944534665,
            21.003814303875064
          ],
          [
            105.82284306009976,
            21.00408723895368
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 592 Trường Chinh",
        "diemDau": "21",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.003814303875064,
        "to_latitude": 21.00408723895368,
        "to_longitude": 105.82284306009976,
        "from_longitude": 105.82267944534665,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4418,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81659351306051,
          21.015258368821794,
          105.81659485416438,
          21.015334734754262
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81659351306051,
            21.015334734754262
          ],
          [
            105.81659485416438,
            21.015258368821794
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 169 Thái Hà",
        "diemDau": "25",
        "diemCuoi": "01",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.015334734754262,
        "to_latitude": 21.015258368821794,
        "to_longitude": 105.81659485416438,
        "from_longitude": 105.81659351306051,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4419,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81619520502218,
          21.015123163464704,
          105.81647951917758,
          21.015323467653655
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81647951917758,
            21.015123163464704
          ],
          [
            105.81619520502218,
            21.015323467653655
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 169 Thái Hà",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.015123163464704,
        "to_latitude": 21.015323467653655,
        "to_longitude": 105.81619520502218,
        "from_longitude": 105.81647951917758,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4420,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81599940376718,
          21.015323467653655,
          105.81619520502218,
          21.015406093052338
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81619520502218,
            21.015323467653655
          ],
          [
            105.81599940376718,
            21.015406093052338
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 169 Thái Hà",
        "diemDau": "04",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.015323467653655,
        "to_latitude": 21.015406093052338,
        "to_longitude": 105.81599940376718,
        "from_longitude": 105.81619520502218,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4421,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81589479761135,
          21.015476199416163,
          105.8159846516246,
          21.015670243641907
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8159846516246,
            21.015670243641907
          ],
          [
            105.81589479761135,
            21.015476199416163
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 171 Thái Hà",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.015670243641907,
        "to_latitude": 21.015476199416163,
        "to_longitude": 105.81589479761135,
        "from_longitude": 105.8159846516246,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4422,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81589479761135,
          21.015406093052338,
          105.81599940376718,
          21.015476199416163
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81589479761135,
            21.015476199416163
          ],
          [
            105.81599940376718,
            21.015406093052338
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 171 Thái Hà",
        "diemDau": "06",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.015476199416163,
        "to_latitude": 21.015406093052338,
        "to_longitude": 105.81599940376718,
        "from_longitude": 105.81589479761135,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4423,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81569631415759,
          21.015268384026285,
          105.81589479761135,
          21.015476199416163
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81589479761135,
            21.015476199416163
          ],
          [
            105.81569631415759,
            21.015268384026285
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 171 Thái Hà",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.015476199416163,
        "to_latitude": 21.015268384026285,
        "to_longitude": 105.81569631415759,
        "from_longitude": 105.81589479761135,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4424,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81540931778548,
          21.015258368821794,
          105.81569631415759,
          21.015268384026285
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81569631415759,
            21.015268384026285
          ],
          [
            105.81540931778548,
            21.015258368821794
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 171 Thái Hà",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.015268384026285,
        "to_latitude": 21.015258368821794,
        "to_longitude": 105.81540931778548,
        "from_longitude": 105.81569631415759,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4425,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81566144543,
          21.015175743340382,
          105.81569631415759,
          21.015268384026285
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81569631415759,
            21.015268384026285
          ],
          [
            105.81566144543,
            21.015175743340382
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 171 Thái Hà",
        "diemDau": "07",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.015268384026285,
        "to_latitude": 21.015175743340382,
        "to_longitude": 105.81566144543,
        "from_longitude": 105.81569631415759,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4426,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8227682935073,
          21.003147927027015,
          105.82284306009976,
          21.00335231629512
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8227682935073,
            21.003147927027015
          ],
          [
            105.82284306009976,
            21.00335231629512
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 580 Trường Chinh",
        "diemDau": "",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.003147927027015,
        "to_latitude": 21.00335231629512,
        "to_longitude": 105.82284306009976,
        "from_longitude": 105.8227682935073,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4427,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82284306009976,
          21.00335231629512,
          105.82299058159744,
          21.00361022823629
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82284306009976,
            21.00335231629512
          ],
          [
            105.82299058159744,
            21.00361022823629
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 580 Trường Chinh",
        "diemDau": "18",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.00335231629512,
        "to_latitude": 21.00361022823629,
        "to_longitude": 105.82299058159744,
        "from_longitude": 105.82284306009976,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4428,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82299058159744,
          21.00361022823629,
          105.82317163070985,
          21.00394075550911
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82299058159744,
            21.00361022823629
          ],
          [
            105.82317163070985,
            21.00394075550911
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 580 Trường Chinh",
        "diemDau": "17",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.00361022823629,
        "to_latitude": 21.00394075550911,
        "to_longitude": 105.82317163070985,
        "from_longitude": 105.82299058159744,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4429,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82317163070985,
          21.00394075550911,
          105.82330708226371,
          21.00420743038893
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82317163070985,
            21.00394075550911
          ],
          [
            105.82330708226371,
            21.00420743038893
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 580 Trường Chinh",
        "diemDau": "16",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.00394075550911,
        "to_latitude": 21.00420743038893,
        "to_longitude": 105.82330708226371,
        "from_longitude": 105.82317163070985,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4430,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82330708226371,
          21.00420743038893,
          105.82343448719433,
          21.004462836868758
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82330708226371,
            21.00420743038893
          ],
          [
            105.82343448719433,
            21.004462836868758
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 580 Trường Chinh",
        "diemDau": "15",
        "diemCuoi": "15-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.00420743038893,
        "to_latitude": 21.004462836868758,
        "to_longitude": 105.82343448719433,
        "from_longitude": 105.82330708226371,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4431,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82343448719433,
          21.004462836868758,
          105.82359407862684,
          21.004679431237815
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82343448719433,
            21.004462836868758
          ],
          [
            105.82351495346252,
            21.00458928795492
          ],
          [
            105.82359407862684,
            21.004679431237815
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 580 Trường Chinh",
        "diemDau": "15-1",
        "diemCuoi": "15-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.004462836868758,
        "to_latitude": 21.004679431237815,
        "to_longitude": 105.82359407862684,
        "from_longitude": 105.82343448719433,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4432,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81504185514514,
          21.014607378941236,
          105.81504453736187,
          21.014972935146872
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81504453736187,
            21.014972935146872
          ],
          [
            105.81504185514514,
            21.014607378941236
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT vật liệu điện Ngõ Thái Hà",
        "diemDau": "8-3",
        "diemCuoi": "8-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.014972935146872,
        "to_latitude": 21.014607378941236,
        "to_longitude": 105.81504185514514,
        "from_longitude": 105.81504453736187,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4433,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82275354135572,
          21.003009267682014,
          105.82295437177494,
          21.003149179029773
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82275354135572,
            21.003149179029773
          ],
          [
            105.82295437177494,
            21.003009267682014
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 580 Trường Chinh",
        "diemDau": "",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.003149179029773,
        "to_latitude": 21.003009267682014,
        "to_longitude": 105.82295437177494,
        "from_longitude": 105.82275354135572,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4434,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82277734598316,
          21.002731322607797,
          105.82295437177494,
          21.003009267682014
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82295437177494,
            21.003009267682014
          ],
          [
            105.82277734598316,
            21.002731322607797
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.003009267682014,
        "to_latitude": 21.002731322607797,
        "to_longitude": 105.82277734598316,
        "from_longitude": 105.82295437177494,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4435,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82295437177494,
          21.003009267682014,
          105.82309116443267,
          21.00309064789859
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82295437177494,
            21.003009267682014
          ],
          [
            105.82309116443267,
            21.00309064789859
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.003009267682014,
        "to_latitude": 21.00309064789859,
        "to_longitude": 105.82309116443267,
        "from_longitude": 105.82295437177494,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4436,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82309116443267,
          21.002946667485542,
          105.82328696568767,
          21.00309064789859
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82309116443267,
            21.00309064789859
          ],
          [
            105.82328696568767,
            21.002946667485542
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "diemDau": "",
        "diemCuoi": "13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.00309064789859,
        "to_latitude": 21.002946667485542,
        "to_longitude": 105.82328696568767,
        "from_longitude": 105.82309116443267,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4437,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82295437177494,
          21.003009267682014,
          105.82317967733307,
          21.003228368164123
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82295437177494,
            21.003009267682014
          ],
          [
            105.82317967733307,
            21.003228368164123
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "diemDau": "13",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.003009267682014,
        "to_latitude": 21.003228368164123,
        "to_longitude": 105.82317967733307,
        "from_longitude": 105.82295437177494,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4438,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81520546989825,
          21.01539107025629,
          105.8154790552227,
          21.01546618422311
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8154790552227,
            21.01539107025629
          ],
          [
            105.81520546989825,
            21.01546618422311
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT vật liệu điện Ngõ Thái Hà",
        "diemDau": "08",
        "diemCuoi": "8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.01539107025629,
        "to_latitude": 21.01546618422311,
        "to_longitude": 105.81520546989825,
        "from_longitude": 105.8154790552227,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4439,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81502307968196,
          21.015250857416515,
          105.81520546989825,
          21.01546618422311
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81520546989825,
            21.01546618422311
          ],
          [
            105.81502307968196,
            21.015250857416515
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT vật liệu điện Ngõ Thái Hà",
        "diemDau": "8-1",
        "diemCuoi": "8-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.01546618422311,
        "to_latitude": 21.015250857416515,
        "to_longitude": 105.81502307968196,
        "from_longitude": 105.81520546989825,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4440,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81502307968196,
          21.014972935146872,
          105.81505392509796,
          21.015250857416515
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81502307968196,
            21.015250857416515
          ],
          [
            105.81505392509796,
            21.015220811788257
          ],
          [
            105.81504453736187,
            21.014972935146872
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT vật liệu điện Ngõ Thái Hà",
        "diemDau": "8-2",
        "diemCuoi": "8-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.015250857416515,
        "to_latitude": 21.014972935146872,
        "to_longitude": 105.81504453736187,
        "from_longitude": 105.81502307968196,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4441,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82317967733307,
          21.003228368164123,
          105.82332552244867,
          21.0034834633186
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82317967733307,
            21.003228368164123
          ],
          [
            105.82332552244867,
            21.0034834633186
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "diemDau": "12",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.003228368164123,
        "to_latitude": 21.0034834633186,
        "to_longitude": 105.82332552244867,
        "from_longitude": 105.82317967733307,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4442,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81540931778548,
          21.01511815585724,
          105.81546832638274,
          21.015258368821794
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81540931778548,
            21.015258368821794
          ],
          [
            105.81546832638274,
            21.01511815585724
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thái Hà",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.015258368821794,
        "to_latitude": 21.01511815585724,
        "to_longitude": 105.81546832638274,
        "from_longitude": 105.81540931778548,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4443,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81546832638274,
          21.01511815585724,
          105.8154790552227,
          21.01539107025629
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8154790552227,
            21.01539107025629
          ],
          [
            105.81546832638274,
            21.01511815585724
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thái Hà",
        "diemDau": "10",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.01539107025629,
        "to_latitude": 21.01511815585724,
        "to_longitude": 105.81546832638274,
        "from_longitude": 105.8154790552227,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4444,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81544955091957,
          21.014827714295937,
          105.81546832638274,
          21.01511815585724
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81546832638274,
            21.01511815585724
          ],
          [
            105.81544955091957,
            21.014827714295937
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thái Hà",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.01511815585724,
        "to_latitude": 21.014827714295937,
        "to_longitude": 105.81544955091957,
        "from_longitude": 105.81546832638274,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4445,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81541468220095,
          21.014587348438617,
          105.81544955091957,
          21.014827714295937
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81544955091957,
            21.014827714295937
          ],
          [
            105.81541468220095,
            21.014587348438617
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thái Hà",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.014827714295937,
        "to_latitude": 21.014587348438617,
        "to_longitude": 105.81541468220095,
        "from_longitude": 105.81544955091957,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4446,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81532885151728,
          21.014397058526594,
          105.81541468220095,
          21.014587348438617
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81541468220095,
            21.014587348438617
          ],
          [
            105.81532885151728,
            21.014397058526594
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thái Hà",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.014587348438617,
        "to_latitude": 21.014397058526594,
        "to_longitude": 105.81532885151728,
        "from_longitude": 105.81541468220095,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4447,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81513036804556,
          21.01414918051444,
          105.81532885151728,
          21.014397058526594
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81532885151728,
            21.014397058526594
          ],
          [
            105.81513036804556,
            21.01414918051444
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thái Hà",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.014397058526594,
        "to_latitude": 21.01414918051444,
        "to_longitude": 105.81513036804556,
        "from_longitude": 105.81532885151728,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4448,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81541468220095,
          21.014449638658242,
          105.8156078012482,
          21.014587348438617
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81541468220095,
            21.014587348438617
          ],
          [
            105.8156078012482,
            21.014449638658242
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thái Hà",
        "diemDau": "12",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.014587348438617,
        "to_latitude": 21.014449638658242,
        "to_longitude": 105.8156078012482,
        "from_longitude": 105.81541468220095,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4449,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8156078012482,
          21.014271867663428,
          105.8158250601831,
          21.014449638658242
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8156078012482,
            21.014449638658242
          ],
          [
            105.8158250601831,
            21.014271867663428
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thái Hà",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.014449638658242,
        "to_latitude": 21.014271867663428,
        "to_longitude": 105.8158250601831,
        "from_longitude": 105.8156078012482,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4450,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8158250601831,
          21.014271867663428,
          105.81606377677996,
          21.01456982174633
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8158250601831,
            21.014271867663428
          ],
          [
            105.81606377677996,
            21.01456982174633
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165/30 Thái Hà",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.014271867663428,
        "to_latitude": 21.01456982174633,
        "to_longitude": 105.81606377677996,
        "from_longitude": 105.8158250601831,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4451,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81583042459859,
          21.01456982174633,
          105.81606377677996,
          21.014765119058254
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81606377677996,
            21.01456982174633
          ],
          [
            105.81583042459859,
            21.014765119058254
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165/30 Thái Hà",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.01456982174633,
        "to_latitude": 21.014765119058254,
        "to_longitude": 105.81583042459859,
        "from_longitude": 105.81606377677996,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4452,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8156212122959,
          21.014765119058254,
          105.81583042459859,
          21.014940385658434
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81583042459859,
            21.014765119058254
          ],
          [
            105.8156212122959,
            21.014940385658434
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165/30 Thái Hà",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.014765119058254,
        "to_latitude": 21.014940385658434,
        "to_longitude": 105.8156212122959,
        "from_longitude": 105.81583042459859,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4453,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81606377677996,
          21.0144220966849,
          105.81614960748159,
          21.01456982174633
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81606377677996,
            21.01456982174633
          ],
          [
            105.81614960748159,
            21.0144220966849
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "F3TC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165/30 Thái Hà",
        "diemDau": "17",
        "diemCuoi": "17-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "F3 Thành Công",
        "from_latitude": 21.01456982174633,
        "to_latitude": 21.0144220966849,
        "to_longitude": 105.81614960748159,
        "from_longitude": 105.81606377677996,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4454,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82119216043506,
          21.012106049082877,
          105.82140271384162,
          21.012336403535652
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82119216043506,
            21.012106049082877
          ],
          [
            105.82140271384162,
            21.012336403535652
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trung Liệt",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.012106049082877,
        "to_latitude": 21.012336403535652,
        "to_longitude": 105.82140271384162,
        "from_longitude": 105.82119216043506,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4455,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82117338497187,
          21.012336403535652,
          105.82140271384162,
          21.012557994168894
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82140271384162,
            21.012336403535652
          ],
          [
            105.82117338497187,
            21.012557994168894
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 42",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.012336403535652,
        "to_latitude": 21.012557994168894,
        "to_longitude": 105.82117338497187,
        "from_longitude": 105.82140271384162,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4456,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82098294817729,
          21.012557994168894,
          105.82117338497187,
          21.012740148930604
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82117338497187,
            21.012557994168894
          ],
          [
            105.82098294817729,
            21.012740148930604
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 42",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.012557994168894,
        "to_latitude": 21.012740148930604,
        "to_longitude": 105.82098294817729,
        "from_longitude": 105.82117338497187,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4457,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82098294817729,
          21.012740148930604,
          105.82099099475558,
          21.012838424779645
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82098294817729,
            21.012740148930604
          ],
          [
            105.82099099475558,
            21.012838424779645
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 42",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.012740148930604,
        "to_latitude": 21.012838424779645,
        "to_longitude": 105.82099099475558,
        "from_longitude": 105.82098294817729,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4458,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8208394499463,
          21.01271511048826,
          105.82098294817729,
          21.01282465364569
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82098294817729,
            21.012740148930604
          ],
          [
            105.82092930399548,
            21.01271511048826
          ],
          [
            105.8208394499463,
            21.01282465364569
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 42",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.012740148930604,
        "to_latitude": 21.01282465364569,
        "to_longitude": 105.8208394499463,
        "from_longitude": 105.82098294817729,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4459,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82140271384162,
          21.012336403535652,
          105.82166422922224,
          21.012616834563826
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82140271384162,
            21.012336403535652
          ],
          [
            105.82166422922224,
            21.012616834563826
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trung Liệt",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.012336403535652,
        "to_latitude": 21.012616834563826,
        "to_longitude": 105.82166422922224,
        "from_longitude": 105.82140271384162,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4460,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82166422922224,
          21.012616834563826,
          105.82188551145977,
          21.012879738173893
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82166422922224,
            21.012616834563826
          ],
          [
            105.82188551145977,
            21.012879738173893
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trung Liệt",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.012616834563826,
        "to_latitude": 21.012879738173893,
        "to_longitude": 105.82188551145977,
        "from_longitude": 105.82166422922224,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4461,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82166422922224,
          21.012512924913914,
          105.82180772739932,
          21.012616834563826
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82166422922224,
            21.012616834563826
          ],
          [
            105.82180772739932,
            21.012512924913914
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Sân Sau UB Phường TL",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.012616834563826,
        "to_latitude": 21.012512924913914,
        "to_longitude": 105.82180772739932,
        "from_longitude": 105.82166422922224,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4462,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82180772739932,
          21.012512924913914,
          105.82193781453768,
          21.01259054417726
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82180772739932,
            21.012512924913914
          ],
          [
            105.82193781453768,
            21.01259054417726
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Sân Sau UB Phường TL",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.012512924913914,
        "to_latitude": 21.01259054417726,
        "to_longitude": 105.82193781453768,
        "from_longitude": 105.82180772739932,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4463,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82172457892337,
          21.012879738173893,
          105.82188551145977,
          21.01300367828628
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82188551145977,
            21.012879738173893
          ],
          [
            105.82172457892337,
            21.01300367828628
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.012879738173893,
        "to_latitude": 21.01300367828628,
        "to_longitude": 105.82172457892337,
        "from_longitude": 105.82188551145977,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4464,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82165886480676,
          21.01300367828628,
          105.82172457892337,
          21.01313763364578
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82172457892337,
            21.01300367828628
          ],
          [
            105.82165886480676,
            21.01313763364578
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.01300367828628,
        "to_latitude": 21.01313763364578,
        "to_longitude": 105.82165886480676,
        "from_longitude": 105.82172457892337,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4465,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82156230527413,
          21.01300367828628,
          105.82172457892337,
          21.01304624355362
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82172457892337,
            21.01300367828628
          ],
          [
            105.82156230527413,
            21.01304624355362
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.01300367828628,
        "to_latitude": 21.01304624355362,
        "to_longitude": 105.82156230527413,
        "from_longitude": 105.82172457892337,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4466,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82161594945593,
          21.01292856307847,
          105.82172457892337,
          21.01300367828628
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82172457892337,
            21.01300367828628
          ],
          [
            105.82161594945593,
            21.01292856307847
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.01300367828628,
        "to_latitude": 21.01292856307847,
        "to_longitude": 105.82161594945593,
        "from_longitude": 105.82172457892337,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4467,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82141478378544,
          21.01304624355362,
          105.82156230527413,
          21.01315015283202
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82156230527413,
            21.01304624355362
          ],
          [
            105.82141478378544,
            21.01315015283202
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.01304624355362,
        "to_latitude": 21.01315015283202,
        "to_longitude": 105.82141478378544,
        "from_longitude": 105.82156230527413,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4468,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82119350153893,
          21.01315015283202,
          105.82141478378544,
          21.013293497436955
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82141478378544,
            21.01315015283202
          ],
          [
            105.82127530896489,
            21.013293497436955
          ],
          [
            105.82119350153893,
            21.01323277943469
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.01315015283202,
        "to_latitude": 21.01323277943469,
        "to_longitude": 105.82119350153893,
        "from_longitude": 105.82141478378544,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4469,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82188551145977,
          21.012879738173893,
          105.82209338265858,
          21.01310132799914
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82188551145977,
            21.012879738173893
          ],
          [
            105.82209338265858,
            21.01310132799914
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trung Liệt",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.012879738173893,
        "to_latitude": 21.01310132799914,
        "to_longitude": 105.82209338265858,
        "from_longitude": 105.82188551145977,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4470,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82209338265858,
          21.01310132799914,
          105.82232002932058,
          21.013324169412567
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82209338265858,
            21.01310132799914
          ],
          [
            105.82232002932058,
            21.013324169412567
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trung Liệt",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.01310132799914,
        "to_latitude": 21.013324169412567,
        "to_longitude": 105.82232002932058,
        "from_longitude": 105.82209338265858,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4471,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82196865994466,
          21.013305390654306,
          105.82210813481014,
          21.013431834245957
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82210813481014,
            21.013431834245957
          ],
          [
            105.82196865994466,
            21.013305390654306
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 TL",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.013431834245957,
        "to_latitude": 21.013305390654306,
        "to_longitude": 105.82196865994466,
        "from_longitude": 105.82210813481014,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4472,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82209338265858,
          21.01310132799914,
          105.82223822200241,
          21.013326047284824
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82209338265858,
            21.01310132799914
          ],
          [
            105.82223822200241,
            21.013326047284824
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 TL",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.01310132799914,
        "to_latitude": 21.013326047284824,
        "to_longitude": 105.82223822200241,
        "from_longitude": 105.82209338265858,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4473,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82210813481014,
          21.013326047284824,
          105.82223822200241,
          21.013431834245957
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82223822200241,
            21.013326047284824
          ],
          [
            105.82210813481014,
            21.013431834245957
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 TL",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.013326047284824,
        "to_latitude": 21.013431834245957,
        "to_longitude": 105.82210813481014,
        "from_longitude": 105.82223822200241,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4474,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82232002932058,
          21.013324169412567,
          105.82260836677861,
          21.013458750439423
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82232002932058,
            21.013324169412567
          ],
          [
            105.82246620976827,
            21.01344873511089
          ],
          [
            105.8225144895256,
            21.013458750439423
          ],
          [
            105.82260836677861,
            21.013366734595152
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trung Liệt",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.013324169412567,
        "to_latitude": 21.013366734595152,
        "to_longitude": 105.82260836677861,
        "from_longitude": 105.82232002932058,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4475,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82225699741169,
          21.013366734595152,
          105.82260836677861,
          21.013763591674056
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82260836677861,
            21.013366734595152
          ],
          [
            105.8224115597159,
            21.01360678943474
          ],
          [
            105.82236327994961,
            21.013661873673247
          ],
          [
            105.82230963577679,
            21.01371195023591
          ],
          [
            105.82225699741169,
            21.013763591674056
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 54,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đặng Tiến Đông",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.013366734595152,
        "to_latitude": 21.013763591674056,
        "to_longitude": 105.82225699741169,
        "from_longitude": 105.82260836677861,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4476,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82204107958964,
          21.013763591674056,
          105.82225699741169,
          21.01400270694726
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82225699741169,
            21.013763591674056
          ],
          [
            105.82204107958964,
            21.01400270694726
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đặng Tiến Đông",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.013763591674056,
        "to_latitude": 21.01400270694726,
        "to_longitude": 105.82204107958964,
        "from_longitude": 105.82225699741169,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4477,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82208801824308,
          21.013603346668667,
          105.82225699741169,
          21.013763591674056
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82225699741169,
            21.013763591674056
          ],
          [
            105.82208801824308,
            21.013603346668667
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 171 ĐTĐ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.013763591674056,
        "to_latitude": 21.013603346668667,
        "to_longitude": 105.82208801824308,
        "from_longitude": 105.82225699741169,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4478,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82197268325628,
          21.01354701049307,
          105.82208801824308,
          21.013621499429075
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82208801824308,
            21.013603346668667
          ],
          [
            105.82205851399387,
            21.013621499429075
          ],
          [
            105.82197268325628,
            21.01354701049307
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 171 ĐTĐ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.013603346668667,
        "to_latitude": 21.01354701049307,
        "to_longitude": 105.82197268325628,
        "from_longitude": 105.82208801824308,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4479,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82179163415285,
          21.01400270694726,
          105.82204107958964,
          21.014263104295644
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82204107958964,
            21.01400270694726
          ],
          [
            105.82179163415285,
            21.014263104295644
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đặng Tiến Đông",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.01400270694726,
        "to_latitude": 21.014263104295644,
        "to_longitude": 105.82179163415285,
        "from_longitude": 105.82204107958964,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4480,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82153816539548,
          21.01402899708663,
          105.82179163415285,
          21.014263104295644
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82179163415285,
            21.014263104295644
          ],
          [
            105.82153816539548,
            21.01402899708663
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 183",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.014263104295644,
        "to_latitude": 21.01402899708663,
        "to_longitude": 105.82153816539548,
        "from_longitude": 105.82179163415285,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4481,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82135041076371,
          21.01382618733376,
          105.82153816539548,
          21.01402899708663
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82153816539548,
            21.01402899708663
          ],
          [
            105.82135041076371,
            21.01382618733376
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 183",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.01402899708663,
        "to_latitude": 21.01382618733376,
        "to_longitude": 105.82135041076371,
        "from_longitude": 105.82153816539548,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4482,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82120020705827,
          21.01364841559503,
          105.82135041076371,
          21.01382618733376
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82135041076371,
            21.01382618733376
          ],
          [
            105.82120020705827,
            21.01364841559503
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 183",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.01382618733376,
        "to_latitude": 21.01364841559503,
        "to_longitude": 105.82120020705827,
        "from_longitude": 105.82135041076371,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4483,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82100977021878,
          21.013460628317542,
          105.82120020705827,
          21.01364841559503
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82120020705827,
            21.01364841559503
          ],
          [
            105.82100977021878,
            21.013460628317542
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 183",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.01364841559503,
        "to_latitude": 21.013460628317542,
        "to_longitude": 105.82100977021878,
        "from_longitude": 105.82120020705827,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4484,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82089175302423,
          21.013350459671514,
          105.82100977021878,
          21.013460628317542
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82100977021878,
            21.013460628317542
          ],
          [
            105.82089175302423,
            21.013350459671514
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 183",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.013460628317542,
        "to_latitude": 21.013350459671514,
        "to_longitude": 105.82089175302423,
        "from_longitude": 105.82100977021878,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4485,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82154352981095,
          21.014263104295644,
          105.82179163415285,
          21.014490951602863
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82179163415285,
            21.014263104295644
          ],
          [
            105.82154352981095,
            21.014490951602863
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đặng Tiến Đông",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.014263104295644,
        "to_latitude": 21.014490951602863,
        "to_longitude": 105.82154352981095,
        "from_longitude": 105.82179163415285,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4486,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82150597893849,
          21.014490951602863,
          105.82154352981095,
          21.014492829461275
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82154352981095,
            21.014490951602863
          ],
          [
            105.82150597893849,
            21.014492829461275
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 3,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đặng Tiến Đông",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.014490951602863,
        "to_latitude": 21.014492829461275,
        "to_longitude": 105.82150597893849,
        "from_longitude": 105.82154352981095,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4487,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82143355924862,
          21.014492829461275,
          105.82150597893849,
          21.014518493561802
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82150597893849,
            21.014492829461275
          ],
          [
            105.82143355924862,
            21.014518493561802
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đặng Tiến Đông",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.014492829461275,
        "to_latitude": 21.014518493561802,
        "to_longitude": 105.82143355924862,
        "from_longitude": 105.82150597893849,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4488,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82139332611452,
          21.01402899708663,
          105.82153816539548,
          21.01420676837091
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82153816539548,
            21.01402899708663
          ],
          [
            105.82139332611452,
            21.01420676837091
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 183/5",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.01402899708663,
        "to_latitude": 21.01420676837091,
        "to_longitude": 105.82139332611452,
        "from_longitude": 105.82153816539548,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4489,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82127530891098,
          21.01420676837091,
          105.82139332611452,
          21.0142994096612
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82139332611452,
            21.01420676837091
          ],
          [
            105.82127530891098,
            21.0142994096612
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 183/5",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.01420676837091,
        "to_latitude": 21.0142994096612,
        "to_longitude": 105.82127530891098,
        "from_longitude": 105.82139332611452,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4490,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82113851625327,
          21.01426998979386,
          105.82127530891098,
          21.014317562335172
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82127530891098,
            21.0142994096612
          ],
          [
            105.82125116907724,
            21.014317562335172
          ],
          [
            105.82120288931992,
            21.01426998979386
          ],
          [
            105.82113851625327,
            21.014314432567193
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 183/5",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.0142994096612,
        "to_latitude": 21.014314432567193,
        "to_longitude": 105.82113851625327,
        "from_longitude": 105.82127530891098,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4491,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82126323897614,
          21.014518493561802,
          105.82143355924862,
          21.01454979123613
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82143355924862,
            21.014518493561802
          ],
          [
            105.82126323897614,
            21.01454979123613
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đặng Tiến Đông",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.014518493561802,
        "to_latitude": 21.01454979123613,
        "to_longitude": 105.82126323897614,
        "from_longitude": 105.82143355924862,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4492,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82103122788969,
          21.01454979123613,
          105.82126323897614,
          21.014623653721006
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82126323897614,
            21.01454979123613
          ],
          [
            105.82103122788969,
            21.014623653721006
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đặng Tiến Đông",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.01454979123613,
        "to_latitude": 21.014623653721006,
        "to_longitude": 105.82103122788969,
        "from_longitude": 105.82126323897614,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4493,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82084347325794,
          21.014623653721006,
          105.82103122788969,
          21.014783897629382
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82103122788969,
            21.014623653721006
          ],
          [
            105.82084347325794,
            21.014783897629382
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đặng Tiến Đông",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.014623653721006,
        "to_latitude": 21.014783897629382,
        "to_longitude": 105.82084347325794,
        "from_longitude": 105.82103122788969,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4494,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82065571863514,
          21.014783897629382,
          105.82084347325794,
          21.014942889462645
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82084347325794,
            21.014783897629382
          ],
          [
            105.82065571863514,
            21.014942889462645
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đặng Tiến Đông",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.014783897629382,
        "to_latitude": 21.014942889462645,
        "to_longitude": 105.82065571863514,
        "from_longitude": 105.82084347325794,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4495,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82040359098163,
          21.014942889462645,
          105.82065571863514,
          21.015163224321658
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82065571863514,
            21.014942889462645
          ],
          [
            105.82040359098163,
            21.015163224321658
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đặng Tiến Đông",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.014942889462645,
        "to_latitude": 21.015163224321658,
        "to_longitude": 105.82040359098163,
        "from_longitude": 105.82065571863514,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4496,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82021717745371,
          21.015026766976096,
          105.82040359098163,
          21.015163224321658
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82040359098163,
            21.015163224321658
          ],
          [
            105.82032044255058,
            21.01514632364511
          ],
          [
            105.82021717745371,
            21.015026766976096
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 235 ĐTĐ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.015163224321658,
        "to_latitude": 21.015026766976096,
        "to_longitude": 105.82021717745371,
        "from_longitude": 105.82040359098163,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4497,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82051356155293,
          21.014770126675053,
          105.82065571863514,
          21.014942889462645
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82065571863514,
            21.014942889462645
          ],
          [
            105.82051356155293,
            21.014770126675053
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 223",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.014942889462645,
        "to_latitude": 21.014770126675053,
        "to_longitude": 105.82051356155293,
        "from_longitude": 105.82065571863514,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4498,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82032580692115,
          21.0145873484361,
          105.82051356155293,
          21.014770126675053
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82051356155293,
            21.014770126675053
          ],
          [
            105.82032580692115,
            21.0145873484361
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 223",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.014770126675053,
        "to_latitude": 21.0145873484361,
        "to_longitude": 105.82032580692115,
        "from_longitude": 105.82051356155293,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4499,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82010452467466,
          21.0145873484361,
          105.82032580692115,
          21.014762615245164
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82032580692115,
            21.0145873484361
          ],
          [
            105.82010452467466,
            21.014762615245164
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 223",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.0145873484361,
        "to_latitude": 21.014762615245164,
        "to_longitude": 105.82010452467466,
        "from_longitude": 105.82032580692115,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4500,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81980679948055,
          21.014439623393766,
          105.82010452467466,
          21.014762615245164
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82010452467466,
            21.014762615245164
          ],
          [
            105.81980679948055,
            21.014439623393766
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 52,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 223",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.014762615245164,
        "to_latitude": 21.014439623393766,
        "to_longitude": 105.81980679948055,
        "from_longitude": 105.82010452467466,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4501,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8201554866487,
          21.014404569973184,
          105.82032580692115,
          21.0145873484361
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82032580692115,
            21.0145873484361
          ],
          [
            105.8201554866487,
            21.014404569973184
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 223",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.0145873484361,
        "to_latitude": 21.014404569973184,
        "to_longitude": 105.8201554866487,
        "from_longitude": 105.82032580692115,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4502,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82089175302423,
          21.013115099108393,
          105.821017816851,
          21.013350459671514
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82089175302423,
            21.013350459671514
          ],
          [
            105.82090516411682,
            21.01324091689771
          ],
          [
            105.821017816851,
            21.013115099108393
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 183/37",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.013350459671514,
        "to_latitude": 21.013115099108393,
        "to_longitude": 105.821017816851,
        "from_longitude": 105.82089175302423,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4503,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.821017816851,
          21.01290477658947,
          105.82120288927501,
          21.013115099108393
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.821017816851,
            21.013115099108393
          ],
          [
            105.82120288927501,
            21.01290477658947
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 183/37",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.013115099108393,
        "to_latitude": 21.01290477658947,
        "to_longitude": 105.82120288927501,
        "from_longitude": 105.821017816851,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4504,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8213946672184,
          21.014518493561802,
          105.82143355924862,
          21.014708783318934
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82143355924862,
            21.014518493561802
          ],
          [
            105.8213946672184,
            21.014708783318934
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 24 ĐTĐ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.014518493561802,
        "to_latitude": 21.014708783318934,
        "to_longitude": 105.8213946672184,
        "from_longitude": 105.82143355924862,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4505,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82124446351298,
          21.014708783318934,
          105.8213946672184,
          21.01496041611112
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8213946672184,
            21.014708783318934
          ],
          [
            105.82124446351298,
            21.01496041611112
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 24 ĐTĐ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.014708783318934,
        "to_latitude": 21.01496041611112,
        "to_longitude": 105.82124446351298,
        "from_longitude": 105.8213946672184,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4506,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82094673830989,
          21.01496041611112,
          105.82124446351298,
          21.015212048478755
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82124446351298,
            21.01496041611112
          ],
          [
            105.82094673830989,
            21.015212048478755
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 24 ĐTĐ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.01496041611112,
        "to_latitude": 21.015212048478755,
        "to_longitude": 105.82094673830989,
        "from_longitude": 105.82124446351298,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4507,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82094673830989,
          21.015212048478755,
          105.82113583404552,
          21.015368536056013
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82094673830989,
            21.015212048478755
          ],
          [
            105.82113583404552,
            21.015368536056013
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 24 ĐTĐ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.015212048478755,
        "to_latitude": 21.015368536056013,
        "to_longitude": 105.82113583404552,
        "from_longitude": 105.82094673830989,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4508,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82113583404552,
          21.015368536056013,
          105.82128201443932,
          21.015525023469113
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82113583404552,
            21.015368536056013
          ],
          [
            105.82128201443932,
            21.015525023469113
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 24 ĐTĐ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.015368536056013,
        "to_latitude": 21.015525023469113,
        "to_longitude": 105.82128201443932,
        "from_longitude": 105.82113583404552,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4509,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82128201443932,
          21.015525023469113,
          105.82143221814475,
          21.01568777020406
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82128201443932,
            21.015525023469113
          ],
          [
            105.82143221814475,
            21.01568777020406
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 24 ĐTĐ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.015525023469113,
        "to_latitude": 21.01568777020406,
        "to_longitude": 105.82143221814475,
        "from_longitude": 105.82128201443932,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4510,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82143221814475,
          21.01568777020406,
          105.82155157644317,
          21.015819219360093
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82143221814475,
            21.01568777020406
          ],
          [
            105.82155157644317,
            21.015819219360093
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 24 ĐTĐ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.01568777020406,
        "to_latitude": 21.015819219360093,
        "to_longitude": 105.82155157644317,
        "from_longitude": 105.82143221814475,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4511,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82155157644317,
          21.015819219360093,
          105.82167093474159,
          21.015936897554504
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82155157644317,
            21.015819219360093
          ],
          [
            105.82167093474159,
            21.015936897554504
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 24 ĐTĐ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.015819219360093,
        "to_latitude": 21.015936897554504,
        "to_longitude": 105.82167093474159,
        "from_longitude": 105.82155157644317,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4512,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82167093474159,
          21.015936897554504,
          105.82181979734312,
          21.016100895734045
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82167093474159,
            21.015936897554504
          ],
          [
            105.82181979734312,
            21.016100895734045
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TH2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 24 ĐTĐ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TH2",
        "from_latitude": 21.015936897554504,
        "to_latitude": 21.016100895734045,
        "to_longitude": 105.82181979734312,
        "from_longitude": 105.82167093474159,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4513,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8096657024623,
          21.023628343219517,
          105.81008949149214,
          21.02382613232506
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81008949149214,
            21.023628343219517
          ],
          [
            105.8096657024623,
            21.02382613232506
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 52,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 54 Nguyễn Chí Thanh",
        "diemDau": "7-1",
        "diemCuoi": "7-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT4",
        "from_latitude": 21.023628343219517,
        "to_latitude": 21.02382613232506,
        "to_longitude": 105.8096657024623,
        "from_longitude": 105.81008949149214,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4514,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80929555762322,
          21.02382613232506,
          105.8096657024623,
          21.024006395585054
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8096657024623,
            21.02382613232506
          ],
          [
            105.80929555762322,
            21.024006395585054
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 54 Nguyễn Chí Thanh",
        "diemDau": "7-2",
        "diemCuoi": "7-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT4",
        "from_latitude": 21.02382613232506,
        "to_latitude": 21.024006395585054,
        "to_longitude": 105.80929555762322,
        "from_longitude": 105.8096657024623,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4515,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80929555762322,
          21.024006395585054,
          105.80929823983094,
          21.024131578276382
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80929555762322,
            21.024006395585054
          ],
          [
            105.80929823983094,
            21.024131578276382
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 54 Nguyễn Chí Thanh",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT4",
        "from_latitude": 21.024006395585054,
        "to_latitude": 21.024131578276382,
        "to_longitude": 105.80929823983094,
        "from_longitude": 105.80929555762322,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4516,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80929823983094,
          21.024131578276382,
          105.80950745213366,
          21.024514636658616
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80929823983094,
            21.024131578276382
          ],
          [
            105.80950745213366,
            21.024514636658616
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 54 Nguyễn Chí Thanh",
        "diemDau": "7-4",
        "diemCuoi": "7-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT4",
        "from_latitude": 21.024131578276382,
        "to_latitude": 21.024514636658616,
        "to_longitude": 105.80950745213366,
        "from_longitude": 105.80929823983094,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4517,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80950745213366,
          21.024514636658616,
          105.80968179571772,
          21.024867650374627
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80950745213366,
            21.024514636658616
          ],
          [
            105.80968179571772,
            21.024867650374627
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "diemDau": "7-5",
        "diemCuoi": "7-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT4",
        "from_latitude": 21.024514636658616,
        "to_latitude": 21.024867650374627,
        "to_longitude": 105.80968179571772,
        "from_longitude": 105.80950745213366,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4518,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80968179571772,
          21.024867650374627,
          105.80984272825413,
          21.025165583285172
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80968179571772,
            21.024867650374627
          ],
          [
            105.80984272825413,
            21.025165583285172
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "diemDau": "7-7",
        "diemCuoi": "7-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT4",
        "from_latitude": 21.024867650374627,
        "to_latitude": 21.025165583285172,
        "to_longitude": 105.80984272825413,
        "from_longitude": 105.80968179571772,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4519,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80950745213366,
          21.02441949806724,
          105.8097139822286,
          21.024514636658616
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80950745213366,
            21.024514636658616
          ],
          [
            105.8097139822286,
            21.02441949806724
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 54 Nguyễn Chí Thanh",
        "diemDau": "7-5",
        "diemCuoi": "7-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT4",
        "from_latitude": 21.024514636658616,
        "to_latitude": 21.02441949806724,
        "to_longitude": 105.8097139822286,
        "from_longitude": 105.80950745213366,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4520,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81008949149214,
          21.023529761525626,
          105.8101330773904,
          21.023628343219517
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8101330773904,
            21.023529761525626
          ],
          [
            105.810129389348,
            21.023584529142344
          ],
          [
            105.81008949149214,
            21.023628343219517
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 54 Nguyễn Chí Thanh",
        "diemDau": "07",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT4",
        "from_latitude": 21.023529761525626,
        "to_latitude": 21.023628343219517,
        "to_longitude": 105.81008949149214,
        "from_longitude": 105.8101330773904,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4521,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80984272825413,
          21.025165583285172,
          105.81001170742272,
          21.02545099744864
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80984272825413,
            21.025165583285172
          ],
          [
            105.81001170742272,
            21.02545099744864
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "diemDau": "7-8",
        "diemCuoi": "7-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT4",
        "from_latitude": 21.025165583285172,
        "to_latitude": 21.02545099744864,
        "to_longitude": 105.81001170742272,
        "from_longitude": 105.80984272825413,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4522,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81001170742272,
          21.02513303602172,
          105.81037380564753,
          21.02545099744864
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81001170742272,
            21.02545099744864
          ],
          [
            105.81037380564753,
            21.02513303602172
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "diemDau": "7-9",
        "diemCuoi": "7-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT4",
        "from_latitude": 21.02545099744864,
        "to_latitude": 21.02513303602172,
        "to_longitude": 105.81037380564753,
        "from_longitude": 105.81001170742272,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4523,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81037380564753,
          21.024835103045227,
          105.81059911119667,
          21.02513303602172
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81037380564753,
            21.02513303602172
          ],
          [
            105.81059911119667,
            21.024835103045227
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "diemDau": "7-10",
        "diemCuoi": "7-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT4",
        "from_latitude": 21.02513303602172,
        "to_latitude": 21.024835103045227,
        "to_longitude": 105.81059911119667,
        "from_longitude": 105.81037380564753,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4524,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80983147832062,
          21.02545099744864,
          105.81001170742272,
          21.025505383545916
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81001170742272,
            21.02545099744864
          ],
          [
            105.80983147832062,
            21.025505383545916
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "diemDau": "7-9",
        "diemCuoi": "cộ đỡ",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT4",
        "from_latitude": 21.02545099744864,
        "to_latitude": 21.025505383545916,
        "to_longitude": 105.80983147832062,
        "from_longitude": 105.81001170742272,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4525,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8097810374491,
          21.025388406669727,
          105.80983147832062,
          21.025505383545916
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80983147832062,
            21.025505383545916
          ],
          [
            105.8097810374491,
            21.025388406669727
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "diemDau": "cộ đỡ",
        "diemCuoi": "7-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT4",
        "from_latitude": 21.025505383545916,
        "to_latitude": 21.025388406669727,
        "to_longitude": 105.8097810374491,
        "from_longitude": 105.80983147832062,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4526,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80963351595142,
          21.02513553965727,
          105.8097810374491,
          21.025388406669727
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8097810374491,
            21.025388406669727
          ],
          [
            105.80963351595142,
            21.02513553965727
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "diemDau": "7-12",
        "diemCuoi": "7-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT4",
        "from_latitude": 21.025388406669727,
        "to_latitude": 21.02513553965727,
        "to_longitude": 105.80963351595142,
        "from_longitude": 105.8097810374491,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4527,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80961474048824,
          21.025505383545916,
          105.80985580402357,
          21.02607690378751
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80983147832062,
            21.025505383545916
          ],
          [
            105.80985580402357,
            21.025830297004156
          ],
          [
            105.80974851566899,
            21.02587786585722
          ],
          [
            105.80961474048824,
            21.02607690378751
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 60,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "diemDau": "cộ đỡ",
        "diemCuoi": "7-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT4",
        "from_latitude": 21.025505383545916,
        "to_latitude": 21.02607690378751,
        "to_longitude": 105.80961474048824,
        "from_longitude": 105.80983147832062,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4528,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80946990120731,
          21.02607690378751,
          105.80961474048824,
          21.026294718576782
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80961474048824,
            21.02607690378751
          ],
          [
            105.80961172300228,
            21.026150760563123
          ],
          [
            105.80946990120731,
            21.026294718576782
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "diemDau": "7-14",
        "diemCuoi": "7-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT4",
        "from_latitude": 21.02607690378751,
        "to_latitude": 21.026294718576782,
        "to_longitude": 105.80946990120731,
        "from_longitude": 105.80961474048824,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4529,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80924996006463,
          21.026294718576782,
          105.80946990120731,
          21.026477482465417
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80946990120731,
            21.026294718576782
          ],
          [
            105.80924996006463,
            21.026477482465417
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "diemDau": "7-15",
        "diemCuoi": "7-16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT4",
        "from_latitude": 21.026294718576782,
        "to_latitude": 21.026477482465417,
        "to_longitude": 105.80924996006463,
        "from_longitude": 105.80946990120731,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4530,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80912657845462,
          21.026282200494148,
          105.80924996006463,
          21.026477482465417
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80924996006463,
            21.026477482465417
          ],
          [
            105.80912657845462,
            21.026282200494148
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "diemDau": "7-16",
        "diemCuoi": "7-17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT4",
        "from_latitude": 21.026477482465417,
        "to_latitude": 21.026282200494148,
        "to_longitude": 105.80912657845462,
        "from_longitude": 105.80924996006463,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4531,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80899515021237,
          21.02607690378751,
          105.80912657845462,
          21.026282200494148
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80912657845462,
            21.026282200494148
          ],
          [
            105.80899515021237,
            21.02607690378751
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "diemDau": "7-17",
        "diemCuoi": "7-18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT4",
        "from_latitude": 21.026282200494148,
        "to_latitude": 21.02607690378751,
        "to_longitude": 105.80899515021237,
        "from_longitude": 105.80912657845462,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4532,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8088476287147,
          21.02586409592747,
          105.80899515021237,
          21.02607690378751
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80899515021237,
            21.02607690378751
          ],
          [
            105.8088476287147,
            21.02586409592747
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "diemDau": "7-18",
        "diemCuoi": "7-19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LT4",
        "from_latitude": 21.02607690378751,
        "to_latitude": 21.02586409592747,
        "to_longitude": 105.8088476287147,
        "from_longitude": 105.80899515021237,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4533,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8082870470361,
          21.02078585353059,
          105.80854420381792,
          21.02099697205173
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80853673951859,
            21.02078585353059
          ],
          [
            105.80854420381792,
            21.02087804599656
          ],
          [
            105.8082870470361,
            21.02099697205173
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18",
        "diemCuoi": "18-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02078585353059,
        "to_latitude": 21.02099697205173,
        "to_longitude": 105.8082870470361,
        "from_longitude": 105.80853673951859,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4534,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80788706260671,
          21.02099697205173,
          105.8082870470361,
          21.021063320229995
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8082870470361,
            21.02099697205173
          ],
          [
            105.80788706260671,
            21.021063320229995
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-1",
        "diemCuoi": "18-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02099697205173,
        "to_latitude": 21.021063320229995,
        "to_longitude": 105.80788706260671,
        "from_longitude": 105.8082870470361,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4535,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80787935127069,
          21.021063320229995,
          105.80788706260671,
          21.021445134647603
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80788706260671,
            21.021063320229995
          ],
          [
            105.80787935127069,
            21.021445134647603
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-2",
        "diemCuoi": "18-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.021063320229995,
        "to_latitude": 21.021445134647603,
        "to_longitude": 105.80787935127069,
        "from_longitude": 105.80788706260671,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4536,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80787935127069,
          21.021445134647603,
          105.80789008010163,
          21.021672970981385
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80787935127069,
            21.021445134647603
          ],
          [
            105.80789008010163,
            21.021672970981385
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-3",
        "diemCuoi": "18-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.021445134647603,
        "to_latitude": 21.021672970981385,
        "to_longitude": 105.80789008010163,
        "from_longitude": 105.80787935127069,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4537,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80789008010163,
          21.021672970981385,
          105.8080966101966,
          21.021695504225622
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80789008010163,
            21.021672970981385
          ],
          [
            105.8080966101966,
            21.021695504225622
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-4",
        "diemCuoi": "18-5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.021672970981385,
        "to_latitude": 21.021695504225622,
        "to_longitude": 105.8080966101966,
        "from_longitude": 105.80789008010163,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4538,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8080966101966,
          21.021695504225622,
          105.80809929240435,
          21.02184322208012
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8080966101966,
            21.021695504225622
          ],
          [
            105.80809929240435,
            21.02184322208012
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-5",
        "diemCuoi": "18-5-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.021695504225622,
        "to_latitude": 21.02184322208012,
        "to_longitude": 105.80809929240435,
        "from_longitude": 105.8080966101966,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4539,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8074206935312,
          21.021672970981385,
          105.80789008010163,
          21.021718037467267
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80789008010163,
            21.021672970981385
          ],
          [
            105.8074206935312,
            21.021718037467267
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-4",
        "diemCuoi": "18-6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.021672970981385,
        "to_latitude": 21.021718037467267,
        "to_longitude": 105.8074206935312,
        "from_longitude": 105.80789008010163,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4540,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8074206935312,
          21.021718037467267,
          105.80747433770401,
          21.021883281134258
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8074206935312,
            21.021718037467267
          ],
          [
            105.80747433770401,
            21.021883281134258
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-6",
        "diemCuoi": "18-7",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.021718037467267,
        "to_latitude": 21.021883281134258,
        "to_longitude": 105.80747433770401,
        "from_longitude": 105.8074206935312,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4541,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80747433770401,
          21.02187827375298,
          105.80783643592883,
          21.021883281134258
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80747433770401,
            21.021883281134258
          ],
          [
            105.80783643592883,
            21.02187827375298
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-7",
        "diemCuoi": "18-8",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.021883281134258,
        "to_latitude": 21.02187827375298,
        "to_longitude": 105.80783643592883,
        "from_longitude": 105.80747433770401,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4542,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8072570787781,
          21.021718037467267,
          105.8074206935312,
          21.021820688857357
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8074206935312,
            21.021718037467267
          ],
          [
            105.80740426498858,
            21.02177186686324
          ],
          [
            105.8072570787781,
            21.021820688857357
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-6",
        "diemCuoi": "18-9",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.021718037467267,
        "to_latitude": 21.021820688857357,
        "to_longitude": 105.8072570787781,
        "from_longitude": 105.8074206935312,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4543,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80708541740175,
          21.021818185166133,
          105.8072570787781,
          21.021820688857357
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8072570787781,
            21.021820688857357
          ],
          [
            105.80708541740175,
            21.021818185166133
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-9",
        "diemCuoi": "18-10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.021820688857357,
        "to_latitude": 21.021818185166133,
        "to_longitude": 105.80708541740175,
        "from_longitude": 105.8072570787781,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4544,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80667772162732,
          21.021818185166133,
          105.80708541740175,
          21.021833207314685
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80708541740175,
            21.021818185166133
          ],
          [
            105.80667772162732,
            21.021833207314685
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-10",
        "diemCuoi": "18-13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.021818185166133,
        "to_latitude": 21.021833207314685,
        "to_longitude": 105.80667772162732,
        "from_longitude": 105.80708541740175,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4545,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80627807248513,
          21.02174808178391,
          105.80667772162732,
          21.021833207314685
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80667772162732,
            21.021833207314685
          ],
          [
            105.80627807248513,
            21.02174808178391
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-13",
        "diemCuoi": "18-15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.021833207314685,
        "to_latitude": 21.02174808178391,
        "to_longitude": 105.80627807248513,
        "from_longitude": 105.80667772162732,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4546,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80667772162732,
          21.0216128823118,
          105.80673404801688,
          21.021833207314685
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80667772162732,
            21.021833207314685
          ],
          [
            105.80673404801688,
            21.0216128823118
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-13",
        "diemCuoi": "18-14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.021833207314685,
        "to_latitude": 21.0216128823118,
        "to_longitude": 105.80673404801688,
        "from_longitude": 105.80667772162732,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4547,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80708541740175,
          21.021552793617992,
          105.80710687507268,
          21.021818185166133
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80708541740175,
            21.021818185166133
          ],
          [
            105.80710687507268,
            21.021552793617992
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-10",
        "diemCuoi": "18-11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.021818185166133,
        "to_latitude": 21.021552793617992,
        "to_longitude": 105.80710687507268,
        "from_longitude": 105.80708541740175,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4548,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80710419286493,
          21.021344986698146,
          105.80710687507268,
          21.021552793617992
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80710687507268,
            21.021552793617992
          ],
          [
            105.80710419286493,
            21.021344986698146
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-11",
        "diemCuoi": "18-12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.021552793617992,
        "to_latitude": 21.021344986698146,
        "to_longitude": 105.80710419286493,
        "from_longitude": 105.80710687507268,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4549,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80678232778318,
          21.021317446,
          105.80710419286493,
          21.021344986698146
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80710419286493,
            21.021344986698146
          ],
          [
            105.80678232778318,
            21.021317446
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-12",
        "diemCuoi": "18-12-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.021344986698146,
        "to_latitude": 21.021317446,
        "to_longitude": 105.80678232778318,
        "from_longitude": 105.80710419286493,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4550,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8059025632216,
          21.02174557809143,
          105.80627807248513,
          21.02174808178391
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80627807248513,
            21.02174808178391
          ],
          [
            105.8059025632216,
            21.02174557809143
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-15",
        "diemCuoi": "18-16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02174808178391,
        "to_latitude": 21.02174557809143,
        "to_longitude": 105.8059025632216,
        "from_longitude": 105.80627807248513,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4551,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8059025632216,
          21.021497712293332,
          105.80594011414794,
          21.02174557809143
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8059025632216,
            21.02174557809143
          ],
          [
            105.80594011414794,
            21.021497712293332
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-16",
        "diemCuoi": "18-18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02174557809143,
        "to_latitude": 21.021497712293332,
        "to_longitude": 105.80594011414794,
        "from_longitude": 105.8059025632216,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4552,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80594011414794,
          21.02114218689487,
          105.8059508429879,
          21.021497712293332
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80594011414794,
            21.021497712293332
          ],
          [
            105.8059508429879,
            21.02114218689487
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-18",
        "diemCuoi": "18-19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.021497712293332,
        "to_latitude": 21.02114218689487,
        "to_longitude": 105.8059508429879,
        "from_longitude": 105.80594011414794,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4553,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80591329206153,
          21.020936883109684,
          105.8059508429879,
          21.02114218689487
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8059508429879,
            21.02114218689487
          ],
          [
            105.80591329206153,
            21.020936883109684
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-19",
        "diemCuoi": "18-20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02114218689487,
        "to_latitude": 21.020936883109684,
        "to_longitude": 105.80591329206153,
        "from_longitude": 105.8059508429879,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4554,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80591329206153,
          21.02088680897311,
          105.80622174609562,
          21.020936883109684
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80591329206153,
            21.020936883109684
          ],
          [
            105.80622174609562,
            21.02088680897311
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-20",
        "diemCuoi": "18-21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.020936883109684,
        "to_latitude": 21.02088680897311,
        "to_longitude": 105.80622174609562,
        "from_longitude": 105.80591329206153,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4555,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80571749080653,
          21.021134675785973,
          105.8059508429879,
          21.02114218689487
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8059508429879,
            21.02114218689487
          ],
          [
            105.80571749080653,
            21.021134675785973
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-19",
        "diemCuoi": "18-17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02114218689487,
        "to_latitude": 21.021134675785973,
        "to_longitude": 105.80571749080653,
        "from_longitude": 105.8059508429879,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4556,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80621336418518,
          21.02066397886021,
          105.80627270806964,
          21.02088680897311
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80622174609562,
            21.02088680897311
          ],
          [
            105.80621336418518,
            21.02074284573549
          ],
          [
            105.80627270806964,
            21.02066397886021
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-21",
        "diemCuoi": "18-22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02088680897311,
        "to_latitude": 21.02066397886021,
        "to_longitude": 105.80627270806964,
        "from_longitude": 105.80622174609562,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4557,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80605544913476,
          21.020556319248023,
          105.80627270806964,
          21.02066397886021
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80627270806964,
            21.02066397886021
          ],
          [
            105.80605544913476,
            21.020556319248023
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-22",
        "diemCuoi": "18-23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02066397886021,
        "to_latitude": 21.020556319248023,
        "to_longitude": 105.80605544913476,
        "from_longitude": 105.80627270806964,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4558,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80627270806964,
          21.020481207844284,
          105.80637463200875,
          21.02066397886021
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80627270806964,
            21.02066397886021
          ],
          [
            105.80637463200875,
            21.020481207844284
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-22",
        "diemCuoi": "18-26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02066397886021,
        "to_latitude": 21.020481207844284,
        "to_longitude": 105.80637463200875,
        "from_longitude": 105.80627270806964,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4559,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80637463200875,
          21.02027590314898,
          105.80650606025101,
          21.020481207844284
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80637463200875,
            21.020481207844284
          ],
          [
            105.80650606025101,
            21.02027590314898
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-26",
        "diemCuoi": "18-29",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.020481207844284,
        "to_latitude": 21.02027590314898,
        "to_longitude": 105.80650606025101,
        "from_longitude": 105.80637463200875,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4560,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80628611911735,
          21.020165739537568,
          105.80650606025101,
          21.02027590314898
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80650606025101,
            21.02027590314898
          ],
          [
            105.80628611911735,
            21.020165739537568
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-29",
        "diemCuoi": "18-30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02027590314898,
        "to_latitude": 21.020165739537568,
        "to_longitude": 105.80628611911735,
        "from_longitude": 105.80650606025101,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4561,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80621369947238,
          21.02048871898612,
          105.80630455929332,
          21.02066397886021
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80627270806964,
            21.02066397886021
          ],
          [
            105.80630455929332,
            21.0205425488259
          ],
          [
            105.80621369947238,
            21.02048871898612
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-22",
        "diemCuoi": "18-23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02066397886021,
        "to_latitude": 21.02048871898612,
        "to_longitude": 105.80621369947238,
        "from_longitude": 105.80627270806964,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4562,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80611948686929,
          21.02036102952026,
          105.80621369947238,
          21.02048871898612
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80621369947238,
            21.02048871898612
          ],
          [
            105.80611948686929,
            21.020424874266862
          ],
          [
            105.80613859761966,
            21.02036102952026
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-24",
        "diemCuoi": "18-25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.02048871898612,
        "to_latitude": 21.02036102952026,
        "to_longitude": 105.80613859761966,
        "from_longitude": 105.80621369947238,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4563,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8062619792297,
          21.020376051816477,
          105.80637463200875,
          21.020481207844284
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80637463200875,
            21.020481207844284
          ],
          [
            105.8063635678906,
            21.020429881696963
          ],
          [
            105.8062619792297,
            21.020376051816477
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-26",
        "diemCuoi": "18-27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.020481207844284,
        "to_latitude": 21.020376051816477,
        "to_longitude": 105.8062619792297,
        "from_longitude": 105.80637463200875,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4564,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80620263534523,
          21.0202708957137,
          105.8062619792297,
          21.020376051816477
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8062619792297,
            21.020376051816477
          ],
          [
            105.80620263534523,
            21.02034225164809
          ],
          [
            105.8062297927278,
            21.0202708957137
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG4",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "diemDau": "18-27",
        "diemCuoi": "18-28",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT4",
        "from_latitude": 21.020376051816477,
        "to_latitude": 21.0202708957137,
        "to_longitude": 105.8062297927278,
        "from_longitude": 105.8062619792297,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4565,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83727736789055,
          21.020827971841054,
          105.83748859185353,
          21.020856138558795
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83748859185353,
            21.020856138558795
          ],
          [
            105.83747451024941,
            21.020827971841054
          ],
          [
            105.83727736789055,
            21.02083172740328
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT E2 Văn Chương",
        "diemDau": "03",
        "diemCuoi": "3-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.020856138558795,
        "to_latitude": 21.02083172740328,
        "to_longitude": 105.83727736789055,
        "from_longitude": 105.83748859185353,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4566,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83771783200211,
          21.020742966271147,
          105.83795709867667,
          21.020780065462183
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83795709867667,
            21.020742966271147
          ],
          [
            105.83786477165553,
            21.020760371703147
          ],
          [
            105.83778966980283,
            21.020771638394635
          ],
          [
            105.83771783200211,
            21.020780065462183
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 109 Văn Chương",
        "diemDau": "3-1",
        "diemCuoi": "3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.020742966271147,
        "to_latitude": 21.020780065462183,
        "to_longitude": 105.83771783200211,
        "from_longitude": 105.83795709867667,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4567,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8374951683075,
          21.020780065462183,
          105.83771783200211,
          21.020805438464876
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83771783200211,
            21.020780065462183
          ],
          [
            105.83766092377732,
            21.020786660649495
          ],
          [
            105.83761130290713,
            21.020805438464876
          ],
          [
            105.83758045750014,
            21.020787912503362
          ],
          [
            105.8374951683075,
            21.020798433909924
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 109 Văn Chương",
        "diemDau": "03",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.020780065462183,
        "to_latitude": 21.020798433909924,
        "to_longitude": 105.8374951683075,
        "from_longitude": 105.83771783200211,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4568,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83741348999185,
          21.020856138558795,
          105.83748859185353,
          21.020983827598098
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83748859185353,
            21.020856138558795
          ],
          [
            105.83744969981431,
            21.020983827598098
          ],
          [
            105.83741348999185,
            21.020981323892794
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Sân Chơi Nhà E1",
        "diemDau": "03",
        "diemCuoi": "3-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.020856138558795,
        "to_latitude": 21.020981323892794,
        "to_longitude": 105.83741348999185,
        "from_longitude": 105.83748859185353,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4569,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83719488995307,
          21.020990086862437,
          105.8374724985981,
          21.021156583189526
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8374724985981,
            21.021156583189526
          ],
          [
            105.83719488995307,
            21.020990086862437
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Sân Chơi Nhà E1",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021156583189526,
        "to_latitude": 21.020990086862437,
        "to_longitude": 105.83719488995307,
        "from_longitude": 105.8374724985981,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4570,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83706712276924,
          21.020990086862437,
          105.83719488995307,
          21.0211416516633
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83719488995307,
            21.020990086862437
          ],
          [
            105.83706712276924,
            21.0211416516633
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Sân Chơi Nhà E1",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.020990086862437,
        "to_latitude": 21.0211416516633,
        "to_longitude": 105.83706712276924,
        "from_longitude": 105.83719488995307,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4571,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83691594021315,
          21.02098633130418,
          105.83719488995307,
          21.020990086862437
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83719488995307,
            21.020990086862437
          ],
          [
            105.83691594021315,
            21.02098633130418
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Sân Chơi Nhà E1",
        "diemDau": "4-1",
        "diemCuoi": "4-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.020990086862437,
        "to_latitude": 21.02098633130418,
        "to_longitude": 105.83691594021315,
        "from_longitude": 105.83719488995307,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4572,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8367094101272,
          21.020965049805184,
          105.83691594021315,
          21.02098633130418
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83691594021315,
            21.02098633130418
          ],
          [
            105.8367094101272,
            21.020965049805184
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Sân Chơi Nhà E1",
        "diemDau": "4-3",
        "diemCuoi": "4-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02098633130418,
        "to_latitude": 21.020965049805184,
        "to_longitude": 105.8367094101272,
        "from_longitude": 105.83691594021315,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4573,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82785175013332,
          21.015195773761498,
          105.82802162337117,
          21.015478981411327
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82785175013332,
            21.015195773761498
          ],
          [
            105.82802162337117,
            21.015478981411327
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 850,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.015195773761498,
        "to_latitude": 21.015478981411327,
        "to_longitude": 105.82802162337117,
        "from_longitude": 105.82785175013332,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4574,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82802162337117,
          21.015478981411327,
          105.82819149660901,
          21.015762188523347
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82802162337117,
            21.015478981411327
          ],
          [
            105.82819149660901,
            21.015762188523347
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.015478981411327,
        "to_latitude": 21.015762188523347,
        "to_longitude": 105.82819149660901,
        "from_longitude": 105.82802162337117,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4575,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82819149660901,
          21.015762188523347,
          105.82836136984685,
          21.016045395097624
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82819149660901,
            21.015762188523347
          ],
          [
            105.82836136984685,
            21.016045395097624
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.015762188523347,
        "to_latitude": 21.016045395097624,
        "to_longitude": 105.82836136984685,
        "from_longitude": 105.82819149660901,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4576,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82836136984685,
          21.016045395097624,
          105.82853124308468,
          21.016328601134045
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82836136984685,
            21.016045395097624
          ],
          [
            105.82853124308468,
            21.016328601134045
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.016045395097624,
        "to_latitude": 21.016328601134045,
        "to_longitude": 105.82853124308468,
        "from_longitude": 105.82836136984685,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4577,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82853124308468,
          21.016328601134045,
          105.82870111632253,
          21.01661180663267
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82853124308468,
            21.016328601134045
          ],
          [
            105.82870111632253,
            21.01661180663267
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.016328601134045,
        "to_latitude": 21.01661180663267,
        "to_longitude": 105.82870111632253,
        "from_longitude": 105.82853124308468,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4578,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82870111632253,
          21.01661180663267,
          105.82887098956036,
          21.016895011593494
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82870111632253,
            21.01661180663267
          ],
          [
            105.82887098956036,
            21.016895011593494
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01661180663267,
        "to_latitude": 21.016895011593494,
        "to_longitude": 105.82887098956036,
        "from_longitude": 105.82870111632253,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4579,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82887098956036,
          21.016895011593494,
          105.8290408627982,
          21.017178216016482
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82887098956036,
            21.016895011593494
          ],
          [
            105.8290408627982,
            21.017178216016482
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.016895011593494,
        "to_latitude": 21.017178216016482,
        "to_longitude": 105.8290408627982,
        "from_longitude": 105.82887098956036,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4580,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8290408627982,
          21.017178216016482,
          105.82921073603605,
          21.017461419901654
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8290408627982,
            21.017178216016482
          ],
          [
            105.82921073603605,
            21.017461419901654
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.017178216016482,
        "to_latitude": 21.017461419901654,
        "to_longitude": 105.82921073603605,
        "from_longitude": 105.8290408627982,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4581,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82921073603605,
          21.017461419901654,
          105.82938060927388,
          21.017744623248966
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82921073603605,
            21.017461419901654
          ],
          [
            105.82938060927388,
            21.017744623248966
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.017461419901654,
        "to_latitude": 21.017744623248966,
        "to_longitude": 105.82938060927388,
        "from_longitude": 105.82921073603605,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4582,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82938060927388,
          21.01769454804037,
          105.82952544856383,
          21.017744623248966
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82938060927388,
            21.017744623248966
          ],
          [
            105.82952544856383,
            21.01769454804037
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x35",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.017744623248966,
        "to_latitude": 21.01769454804037,
        "to_longitude": 105.82952544856383,
        "from_longitude": 105.82938060927388,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4583,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82935557532598,
          21.017409675420886,
          105.82952544856383,
          21.01769454804037
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82952544856383,
            21.01769454804037
          ],
          [
            105.82935557532598,
            21.017409675420886
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01769454804037,
        "to_latitude": 21.017409675420886,
        "to_longitude": 105.82935557532598,
        "from_longitude": 105.82952544856383,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4584,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82918570208814,
          21.01712480225724,
          105.82935557532598,
          21.017409675420886
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82935557532598,
            21.017409675420886
          ],
          [
            105.82918570208814,
            21.01712480225724
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.017409675420886,
        "to_latitude": 21.01712480225724,
        "to_longitude": 105.82918570208814,
        "from_longitude": 105.82935557532598,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4585,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8290158288503,
          21.016839928549373,
          105.82918570208814,
          21.01712480225724
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82918570208814,
            21.01712480225724
          ],
          [
            105.8290158288503,
            21.016839928549373
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01712480225724,
        "to_latitude": 21.016839928549373,
        "to_longitude": 105.8290158288503,
        "from_longitude": 105.82918570208814,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4586,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82884595561246,
          21.016555054297342,
          105.8290158288503,
          21.016839928549373
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8290158288503,
            21.016839928549373
          ],
          [
            105.82884595561246,
            21.016555054297342
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.016839928549373,
        "to_latitude": 21.016555054297342,
        "to_longitude": 105.82884595561246,
        "from_longitude": 105.8290158288503,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4587,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82867608237461,
          21.016270179501134,
          105.82884595561246,
          21.016555054297342
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82884595561246,
            21.016555054297342
          ],
          [
            105.82867608237461,
            21.016270179501134
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.016555054297342,
        "to_latitude": 21.016270179501134,
        "to_longitude": 105.82867608237461,
        "from_longitude": 105.82884595561246,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4588,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82850620913678,
          21.015985304160775,
          105.82867608237461,
          21.016270179501134
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82867608237461,
            21.016270179501134
          ],
          [
            105.82850620913678,
            21.015985304160775
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.016270179501134,
        "to_latitude": 21.015985304160775,
        "to_longitude": 105.82850620913678,
        "from_longitude": 105.82867608237461,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4589,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82833633589895,
          21.01570042827625,
          105.82850620913678,
          21.015985304160775
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82850620913678,
            21.015985304160775
          ],
          [
            105.82833633589895,
            21.01570042827625
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.015985304160775,
        "to_latitude": 21.01570042827625,
        "to_longitude": 105.82833633589895,
        "from_longitude": 105.82850620913678,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4590,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8281664626611,
          21.015415551847564,
          105.82833633589895,
          21.01570042827625
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82833633589895,
            21.01570042827625
          ],
          [
            105.8281664626611,
            21.015415551847564
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01570042827625,
        "to_latitude": 21.015415551847564,
        "to_longitude": 105.8281664626611,
        "from_longitude": 105.82833633589895,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4591,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82799658942326,
          21.01513067487473,
          105.8281664626611,
          21.015415551847564
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8281664626611,
            21.015415551847564
          ],
          [
            105.82799658942326,
            21.01513067487473
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.015415551847564,
        "to_latitude": 21.01513067487473,
        "to_longitude": 105.82799658942326,
        "from_longitude": 105.8281664626611,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4592,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82901448774643,
          21.017309258180813,
          105.82921073603605,
          21.017461419901654
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82921073603605,
            21.017461419901654
          ],
          [
            105.82909340691808,
            21.017309258180813
          ],
          [
            105.82901448774643,
            21.01734902864109
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.017461419901654,
        "to_latitude": 21.01734902864109,
        "to_longitude": 105.82901448774643,
        "from_longitude": 105.82921073603605,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4593,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82877979445222,
          21.01734902864109,
          105.82901448774643,
          21.01741066072307
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82901448774643,
            21.01734902864109
          ],
          [
            105.82881043386655,
            21.01741066072307
          ],
          [
            105.82877979445222,
            21.017409119028148
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01734902864109,
        "to_latitude": 21.017409119028148,
        "to_longitude": 105.82877979445222,
        "from_longitude": 105.82901448774643,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4594,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8287460608538,
          21.017391882480155,
          105.82877979445222,
          21.017409119028148
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82877979445222,
            21.017409119028148
          ],
          [
            105.8287460608538,
            21.017391882480155
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 7,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.017409119028148,
        "to_latitude": 21.017391882480155,
        "to_longitude": 105.8287460608538,
        "from_longitude": 105.82877979445222,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4595,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82850218582517,
          21.017391882480155,
          105.8287460608538,
          21.0175643524191
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8287460608538,
            21.017391882480155
          ],
          [
            105.82872728539061,
            21.01741817201956
          ],
          [
            105.82850218582517,
            21.0175643524191
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.017391882480155,
        "to_latitude": 21.0175643524191,
        "to_longitude": 105.82850218582517,
        "from_longitude": 105.8287460608538,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4596,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82866982388991,
          21.017305212718636,
          105.8287460608538,
          21.017391882480155
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8287460608538,
            21.017391882480155
          ],
          [
            105.82866982388991,
            21.017305212718636
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.017391882480155,
        "to_latitude": 21.017305212718636,
        "to_longitude": 105.82866982388991,
        "from_longitude": 105.8287460608538,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4597,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82843626571587,
          21.017205351800065,
          105.82866982388991,
          21.017305212718636
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82866982388991,
            21.017305212718636
          ],
          [
            105.82859988045998,
            21.017205351800065
          ],
          [
            105.82843626571587,
            21.017232893257738
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.017305212718636,
        "to_latitude": 21.017232893257738,
        "to_longitude": 105.82843626571587,
        "from_longitude": 105.82866982388991,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4598,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8285330312232,
          21.016768153642033,
          105.82859203982045,
          21.01690335750779
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8285330312232,
            21.016768153642033
          ],
          [
            105.82859203982045,
            21.01690335750779
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 92 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.016768153642033,
        "to_latitude": 21.01690335750779,
        "to_longitude": 105.82859203982045,
        "from_longitude": 105.8285330312232,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4599,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82859203982045,
          21.016772995068603,
          105.82879780103536,
          21.01690335750779
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82859203982045,
            21.01690335750779
          ],
          [
            105.82879780103536,
            21.016772995068603
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 92 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01690335750779,
        "to_latitude": 21.016772995068603,
        "to_longitude": 105.82879780103536,
        "from_longitude": 105.82859203982045,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4600,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82799658942326,
          21.01513067487473,
          105.8281948669023,
          21.01540262716755
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82799658942326,
            21.01513067487473
          ],
          [
            105.8281948669023,
            21.01540262716755
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01513067487473,
        "to_latitude": 21.01540262716755,
        "to_longitude": 105.8281948669023,
        "from_longitude": 105.82799658942326,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4601,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8281948669023,
          21.015258368820103,
          105.8285236434961,
          21.01540262716755
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8281948669023,
            21.01540262716755
          ],
          [
            105.8285236434961,
            21.015258368820103
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 85 NLB",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01540262716755,
        "to_latitude": 21.015258368820103,
        "to_longitude": 105.8285236434961,
        "from_longitude": 105.8281948669023,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4602,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82844163013134,
          21.015258368820103,
          105.8285236434961,
          21.015431131042114
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8285236434961,
            21.015258368820103
          ],
          [
            105.82844163013134,
            21.015308734662675
          ],
          [
            105.8284968214097,
            21.015431131042114
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 85 NLB",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.015258368820103,
        "to_latitude": 21.015431131042114,
        "to_longitude": 105.8284968214097,
        "from_longitude": 105.8285236434961,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4603,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82845390606785,
          21.015431131042114,
          105.8284968214097,
          21.015476199414476
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8284968214097,
            21.015431131042114
          ],
          [
            105.82849661541701,
            21.015447695549373
          ],
          [
            105.82845390606785,
            21.015476199414476
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 85 NLB",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.015431131042114,
        "to_latitude": 21.015476199414476,
        "to_longitude": 105.82845390606785,
        "from_longitude": 105.8284968214097,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4604,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8285236434961,
          21.01515320910928,
          105.82854778338374,
          21.015258368820103
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8285236434961,
            21.015258368820103
          ],
          [
            105.82854778338374,
            21.01515320910928
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 85 NLB",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.015258368820103,
        "to_latitude": 21.01515320910928,
        "to_longitude": 105.82854778338374,
        "from_longitude": 105.8285236434961,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4605,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8285236434961,
          21.015203285171207,
          105.82866043616278,
          21.015258368820103
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8285236434961,
            21.015258368820103
          ],
          [
            105.82866043616278,
            21.015203285171207
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 85 NLB",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.015258368820103,
        "to_latitude": 21.015203285171207,
        "to_longitude": 105.82866043616278,
        "from_longitude": 105.8285236434961,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4606,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82854778338374,
          21.014985454178216,
          105.82877577114961,
          21.01515320910928
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82854778338374,
            21.01515320910928
          ],
          [
            105.82877577114961,
            21.014985454178216
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 85 NLB",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01515320910928,
        "to_latitude": 21.014985454178216,
        "to_longitude": 105.82877577114961,
        "from_longitude": 105.82854778338374,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4607,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82871139812785,
          21.014765119056573,
          105.82877577114961,
          21.014985454178216
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82877577114961,
            21.014985454178216
          ],
          [
            105.82871139812785,
            21.014765119056573
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 85 NLB",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.014985454178216,
        "to_latitude": 21.014765119056573,
        "to_longitude": 105.82871139812785,
        "from_longitude": 105.82877577114961,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4608,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82850620913678,
          21.01595972148106,
          105.82871923876739,
          21.01605486547053
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82850620913678,
            21.015985304160775
          ],
          [
            105.82857976389295,
            21.01605486547053
          ],
          [
            105.82871923876739,
            21.01595972148106
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 115 Nam Đồng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.015985304160775,
        "to_latitude": 21.01595972148106,
        "to_longitude": 105.82871923876739,
        "from_longitude": 105.82850620913678,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4609,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82871923876739,
          21.01582297504931,
          105.82887367178608,
          21.01595972148106
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82871923876739,
            21.01595972148106
          ],
          [
            105.82887367178608,
            21.01582297504931
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 115 Nam Đồng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01595972148106,
        "to_latitude": 21.01582297504931,
        "to_longitude": 105.82887367178608,
        "from_longitude": 105.82871923876739,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4610,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82887367178608,
          21.015705296765834,
          105.82908824851323,
          21.01582297504931
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82887367178608,
            21.01582297504931
          ],
          [
            105.82908824851323,
            21.015705296765834
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 115 Nam Đồng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01582297504931,
        "to_latitude": 21.015705296765834,
        "to_longitude": 105.82908824851323,
        "from_longitude": 105.82887367178608,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4611,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82908824851323,
          21.015600137369287,
          105.82925990988957,
          21.015705296765834
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82908824851323,
            21.015705296765834
          ],
          [
            105.82925990988957,
            21.015600137369287
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 115 Nam Đồng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.015705296765834,
        "to_latitude": 21.015600137369287,
        "to_longitude": 105.82925990988957,
        "from_longitude": 105.82908824851323,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4612,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82877979447018,
          21.015605144961416,
          105.82887367178608,
          21.01582297504931
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82887367178608,
            21.01582297504931
          ],
          [
            105.82886407804833,
            21.015709342271432
          ],
          [
            105.82881848049877,
            21.015639236018025
          ],
          [
            105.82877979447018,
            21.015605144961416
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 115 Nam Đồng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01582297504931,
        "to_latitude": 21.015605144961416,
        "to_longitude": 105.82877979447018,
        "from_longitude": 105.82887367178608,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4613,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82911775280739,
          21.015429879142445,
          105.82925990988957,
          21.015600137369287
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82925990988957,
            21.015600137369287
          ],
          [
            105.82911775280739,
            21.015429879142445
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 115 Nam Đồng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.015600137369287,
        "to_latitude": 21.015429879142445,
        "to_longitude": 105.82911775280739,
        "from_longitude": 105.82925990988957,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4614,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8289165871279,
          21.015229575096416,
          105.82911775280739,
          21.015429879142445
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82911775280739,
            21.015429879142445
          ],
          [
            105.82897136641192,
            21.015308734662675
          ],
          [
            105.8289165871279,
            21.015229575096416
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 115 Nam Đồng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.015429879142445,
        "to_latitude": 21.015229575096416,
        "to_longitude": 105.8289165871279,
        "from_longitude": 105.82911775280739,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4615,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82911620569286,
          21.016956226568595,
          105.82918570208814,
          21.01712480225724
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82918570208814,
            21.01712480225724
          ],
          [
            105.82911620569286,
            21.016956226568595
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01712480225724,
        "to_latitude": 21.016956226568595,
        "to_longitude": 105.82911620569286,
        "from_longitude": 105.82918570208814,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4616,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82911620569286,
          21.016863297116608,
          105.82920760679369,
          21.016956226568595
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82911620569286,
            21.016956226568595
          ],
          [
            105.82920760679369,
            21.016863297116608
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.016956226568595,
        "to_latitude": 21.016863297116608,
        "to_longitude": 105.82920760679369,
        "from_longitude": 105.82911620569286,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4617,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82920760679369,
          21.01670806299754,
          105.82929611969408,
          21.016863297116608
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82920760679369,
            21.016863297116608
          ],
          [
            105.82927177382275,
            21.016798488769908
          ],
          [
            105.82929611969408,
            21.01670806299754
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.016863297116608,
        "to_latitude": 21.01670806299754,
        "to_longitude": 105.82929611969408,
        "from_longitude": 105.82920760679369,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4618,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82908958959915,
          21.016500249325876,
          105.82929611969408,
          21.01670806299754
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82929611969408,
            21.01670806299754
          ],
          [
            105.82908958959915,
            21.016500249325876
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01670806299754,
        "to_latitude": 21.016500249325876,
        "to_longitude": 105.82908958959915,
        "from_longitude": 105.82929611969408,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4619,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82929611969408,
          21.01644766991714,
          105.82946509886268,
          21.01670806299754
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82929611969408,
            21.01670806299754
          ],
          [
            105.82932273578783,
            21.016548110968465
          ],
          [
            105.82946509886268,
            21.01644766991714
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01670806299754,
        "to_latitude": 21.01644766991714,
        "to_longitude": 105.82946509886268,
        "from_longitude": 105.82929611969408,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4620,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82933882904325,
          21.016322770588452,
          105.82946509886268,
          21.01644766991714
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82946509886268,
            21.01644766991714
          ],
          [
            105.82933882904325,
            21.016322770588452
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01644766991714,
        "to_latitude": 21.016322770588452,
        "to_longitude": 105.82933882904325,
        "from_longitude": 105.82946509886268,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4621,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82925052214452,
          21.016187276382926,
          105.82933882904325,
          21.016322770588452
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82933882904325,
            21.016322770588452
          ],
          [
            105.82925052214452,
            21.016187276382926
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.016322770588452,
        "to_latitude": 21.016187276382926,
        "to_longitude": 105.82925052214452,
        "from_longitude": 105.82933882904325,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4622,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82933882904325,
          21.016224833170543,
          105.82945705223946,
          21.016322770588452
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82933882904325,
            21.016322770588452
          ],
          [
            105.82945705223946,
            21.016224833170543
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.016322770588452,
        "to_latitude": 21.016224833170543,
        "to_longitude": 105.82945705223946,
        "from_longitude": 105.82933882904325,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4623,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82933882904325,
          21.016127185502715,
          105.82943807078361,
          21.016322770588452
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82933882904325,
            21.016322770588452
          ],
          [
            105.82943807078361,
            21.016217611627276
          ],
          [
            105.82938463259448,
            21.016127185502715
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.016322770588452,
        "to_latitude": 21.016127185502715,
        "to_longitude": 105.82938463259448,
        "from_longitude": 105.82933882904325,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4624,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82928807307087,
          21.01599698851223,
          105.82938463259448,
          21.016127185502715
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82938463259448,
            21.016127185502715
          ],
          [
            105.82928807307087,
            21.01599698851223
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.016127185502715,
        "to_latitude": 21.01599698851223,
        "to_longitude": 105.82928807307087,
        "from_longitude": 105.82938463259448,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4625,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82946509886268,
          21.01644766991714,
          105.82963789535022,
          21.01646423431063
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82946509886268,
            21.01644766991714
          ],
          [
            105.829495738277,
            21.01646423431063
          ],
          [
            105.82963789535022,
            21.01646423431063
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01644766991714,
        "to_latitude": 21.01646423431063,
        "to_longitude": 105.82963789535022,
        "from_longitude": 105.82946509886268,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4626,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82963789535022,
          21.01646423431063,
          105.82980842161535,
          21.016477715295707
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82963789535022,
            21.01646423431063
          ],
          [
            105.82980842161535,
            21.016477715295707
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01646423431063,
        "to_latitude": 21.016477715295707,
        "to_longitude": 105.82980842161535,
        "from_longitude": 105.82963789535022,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4627,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82980842161535,
          21.016207306670676,
          105.82991570997895,
          21.016477715295707
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82980842161535,
            21.016477715295707
          ],
          [
            105.82991570997895,
            21.016207306670676
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.016477715295707,
        "to_latitude": 21.016207306670676,
        "to_longitude": 105.82991570997895,
        "from_longitude": 105.82980842161535,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4628,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82988888788356,
          21.01588181415704,
          105.82991570997895,
          21.016207306670676
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82991570997895,
            21.016207306670676
          ],
          [
            105.82988888788356,
            21.01588181415704
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.016207306670676,
        "to_latitude": 21.01588181415704,
        "to_longitude": 105.82988888788356,
        "from_longitude": 105.82991570997895,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4629,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82964748907898,
          21.01588181415704,
          105.82988888788356,
          21.015939401346174
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82988888788356,
            21.01588181415704
          ],
          [
            105.82964748907898,
            21.015939401346174
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01588181415704,
        "to_latitude": 21.015939401346174,
        "to_longitude": 105.82964748907898,
        "from_longitude": 105.82988888788356,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4630,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82958579826497,
          21.01581921936176,
          105.82964748907898,
          21.015939401346174
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82964748907898,
            21.015939401346174
          ],
          [
            105.82958579826497,
            21.01581921936176
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.015939401346174,
        "to_latitude": 21.01581921936176,
        "to_longitude": 105.82958579826497,
        "from_longitude": 105.82964748907898,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4631,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8298593835894,
          21.015681510718814,
          105.82988888788356,
          21.01588181415704
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82988888788356,
            21.01588181415704
          ],
          [
            105.8298593835894,
            21.015681510718814
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01588181415704,
        "to_latitude": 21.015681510718814,
        "to_longitude": 105.8298593835894,
        "from_longitude": 105.82988888788356,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4632,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82982719707854,
          21.01546618422227,
          105.8298593835894,
          21.015681510718814
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8298593835894,
            21.015681510718814
          ],
          [
            105.82982719707854,
            21.01546618422227
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.015681510718814,
        "to_latitude": 21.01546618422227,
        "to_longitude": 105.82982719707854,
        "from_longitude": 105.8298593835894,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4633,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82976818848127,
          21.01501800365685,
          105.82982719707854,
          21.01546618422227
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82982719707854,
            21.01546618422227
          ],
          [
            105.82976818848127,
            21.01501800365685
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01546618422227,
        "to_latitude": 21.01501800365685,
        "to_longitude": 105.82976818848127,
        "from_longitude": 105.82982719707854,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4634,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82959920931266,
          21.014815195248815,
          105.82976818848127,
          21.01501800365685
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82976818848127,
            21.01501800365685
          ],
          [
            105.82959920931266,
            21.014815195248815
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01501800365685,
        "to_latitude": 21.014815195248815,
        "to_longitude": 105.82959920931266,
        "from_longitude": 105.82976818848127,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4635,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82936585713132,
          21.014815195248815,
          105.82959920931266,
          21.014942889464308
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82959920931266,
            21.014815195248815
          ],
          [
            105.8295011026925,
            21.014841775059278
          ],
          [
            105.82936585713132,
            21.014942889464308
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.014815195248815,
        "to_latitude": 21.014942889464308,
        "to_longitude": 105.82936585713132,
        "from_longitude": 105.82959920931266,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4636,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82927197981545,
          21.01479015715478,
          105.82936585713132,
          21.014942889464308
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82936585713132,
            21.014942889464308
          ],
          [
            105.82927197981545,
            21.01479015715478
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.014942889464308,
        "to_latitude": 21.01479015715478,
        "to_longitude": 105.82927197981545,
        "from_longitude": 105.82936585713132,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4637,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82959920931266,
          21.014757607626446,
          105.82975477743359,
          21.014815195248815
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82959920931266,
            21.014815195248815
          ],
          [
            105.82975477743359,
            21.014757607626446
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.014815195248815,
        "to_latitude": 21.014757607626446,
        "to_longitude": 105.82975477743359,
        "from_longitude": 105.82959920931266,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4638,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82975477743359,
          21.01472756190135,
          105.82988888788356,
          21.014757607626446
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82975477743359,
            21.014757607626446
          ],
          [
            105.82988888788356,
            21.01472756190135
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.014757607626446,
        "to_latitude": 21.01472756190135,
        "to_longitude": 105.82988888788356,
        "from_longitude": 105.82975477743359,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4639,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82949460316581,
          21.014609882752758,
          105.82959920931266,
          21.014815195248815
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82959920931266,
            21.014815195248815
          ],
          [
            105.82949460316581,
            21.014609882752758
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.014815195248815,
        "to_latitude": 21.014609882752758,
        "to_longitude": 105.82949460316581,
        "from_longitude": 105.82959920931266,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4640,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82949460316581,
          21.0144634096405,
          105.82971856764702,
          21.014609882752758
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82949460316581,
            21.014609882752758
          ],
          [
            105.82971856764702,
            21.0144634096405
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.014609882752758,
        "to_latitude": 21.0144634096405,
        "to_longitude": 105.82971856764702,
        "from_longitude": 105.82949460316581,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4641,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82940206698974,
          21.014403318065717,
          105.82949460316581,
          21.014609882752758
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82949460316581,
            21.014609882752758
          ],
          [
            105.82940206698974,
            21.014403318065717
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.014609882752758,
        "to_latitude": 21.014403318065717,
        "to_longitude": 105.82940206698974,
        "from_longitude": 105.82949460316581,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4642,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82929477862616,
          21.014253089022837,
          105.82940206698974,
          21.014403318065717
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82940206698974,
            21.014403318065717
          ],
          [
            105.82929477862616,
            21.014253089022837
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.014403318065717,
        "to_latitude": 21.014253089022837,
        "to_longitude": 105.82929477862616,
        "from_longitude": 105.82940206698974,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4643,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82971856764702,
          21.014303165386995,
          105.82990095786332,
          21.0144634096405
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82971856764702,
            21.0144634096405
          ],
          [
            105.82990095786332,
            21.014303165386995
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.0144634096405,
        "to_latitude": 21.014303165386995,
        "to_longitude": 105.82990095786332,
        "from_longitude": 105.82971856764702,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4644,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82990095786332,
          21.014303165386995,
          105.83000288180241,
          21.014398310433247
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82990095786332,
            21.014303165386995
          ],
          [
            105.83000288180241,
            21.014398310433247
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.014303165386995,
        "to_latitude": 21.014398310433247,
        "to_longitude": 105.83000288180241,
        "from_longitude": 105.82990095786332,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4645,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82929477862616,
          21.01417296680417,
          105.82947180441799,
          21.014253089022837
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82929477862616,
            21.014253089022837
          ],
          [
            105.82947180441799,
            21.01417296680417
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.014253089022837,
        "to_latitude": 21.01417296680417,
        "to_longitude": 105.82947180441799,
        "from_longitude": 105.82929477862616,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4646,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82918570208814,
          21.01712480225724,
          105.82925702168016,
          21.01721161122301
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82918570208814,
            21.01712480225724
          ],
          [
            105.82925702168016,
            21.01721161122301
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 71 NL Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01712480225724,
        "to_latitude": 21.01721161122301,
        "to_longitude": 105.82925702168016,
        "from_longitude": 105.82918570208814,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4647,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82925702168016,
          21.017141215863465,
          105.82935512829137,
          21.017211611223026
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82925702168016,
            21.017211611223026
          ],
          [
            105.82935512829137,
            21.017141215863465
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.017211611223026,
        "to_latitude": 21.017141215863465,
        "to_longitude": 105.82935512829137,
        "from_longitude": 105.82925702168016,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4648,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82935512829137,
          21.01706359896836,
          105.82949192095808,
          21.01714526133007
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82935512829137,
            21.017141215863465
          ],
          [
            105.82937772108247,
            21.01714526133007
          ],
          [
            105.82943941188748,
            21.017077659522588
          ],
          [
            105.82949192095808,
            21.01706359896836
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.017141215863465,
        "to_latitude": 21.01706359896836,
        "to_longitude": 105.82949192095808,
        "from_longitude": 105.82935512829137,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4649,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82951317263631,
          21.017541818549752,
          105.82969845104405,
          21.01769454804037
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82952544856383,
            21.01769454804037
          ],
          [
            105.82951317263631,
            21.01764476262633
          ],
          [
            105.82969845104405,
            21.017541818549752
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 3 Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01769454804037,
        "to_latitude": 21.017541818549752,
        "to_longitude": 105.82969845104405,
        "from_longitude": 105.82952544856383,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4650,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82969845104405,
          21.01742914915346,
          105.82991034556348,
          21.017541818549752
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82969845104405,
            21.017541818549752
          ],
          [
            105.82991034556348,
            21.01742914915346
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 3 Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.017541818549752,
        "to_latitude": 21.01742914915346,
        "to_longitude": 105.82991034556348,
        "from_longitude": 105.82969845104405,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4651,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82991034556348,
          21.017303960834692,
          105.83010614681845,
          21.01742914915346
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82991034556348,
            21.01742914915346
          ],
          [
            105.83010614681845,
            21.017303960834692
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 3 Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.01742914915346,
        "to_latitude": 21.017303960834692,
        "to_longitude": 105.83010614681845,
        "from_longitude": 105.82991034556348,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4652,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83006704879554,
          21.01706359896836,
          105.83012069297735,
          21.017303960834692
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83010614681845,
            21.017303960834692
          ],
          [
            105.83012069297735,
            21.017281716737237
          ],
          [
            105.83006704879554,
            21.017199092377695
          ],
          [
            105.8301168756584,
            21.01706359896836
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 3 Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LT1",
        "from_latitude": 21.017303960834692,
        "to_latitude": 21.01706359896836,
        "to_longitude": 105.8301168756584,
        "from_longitude": 105.83010614681845,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4653,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8190809266847,
          21.015944095952,
          105.8193706052556,
          21.015949103532567
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8193706052556,
            21.015949103532567
          ],
          [
            105.8190809266847,
            21.015944095952
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "diemDau": "51",
        "diemCuoi": "52",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.015949103532567,
        "to_latitude": 21.015944095952,
        "to_longitude": 105.8190809266847,
        "from_longitude": 105.8193706052556,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4654,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81877129919938,
          21.015944095952,
          105.8190809266847,
          21.016001839602346
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8190809266847,
            21.015944095952
          ],
          [
            105.81877129919938,
            21.016001839602346
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "diemDau": "52",
        "diemCuoi": "53",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.015944095952,
        "to_latitude": 21.016001839602346,
        "to_longitude": 105.81877129919938,
        "from_longitude": 105.8190809266847,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4655,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.819125350792,
          21.016272248599947,
          105.81913507377934,
          21.016468482601457
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.819125350792,
            21.016272248599947
          ],
          [
            105.81913507377934,
            21.016468482601457
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "diemDau": "52-2",
        "diemCuoi": "52-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.016272248599947,
        "to_latitude": 21.016468482601457,
        "to_longitude": 105.81913507377934,
        "from_longitude": 105.819125350792,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4656,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81883198415176,
          21.016468482601457,
          105.81913507377934,
          21.01655486303573
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81913507377934,
            21.016468482601457
          ],
          [
            105.81883198415176,
            21.01655486303573
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "diemDau": "52-3",
        "diemCuoi": "52-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.016468482601457,
        "to_latitude": 21.01655486303573,
        "to_longitude": 105.81883198415176,
        "from_longitude": 105.81913507377934,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4657,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81883198415176,
          21.01655486303573,
          105.81898487006494,
          21.01656988571541
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81883198415176,
            21.01655486303573
          ],
          [
            105.81898487006494,
            21.01656988571541
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "diemDau": "52-4",
        "diemCuoi": "52-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.01655486303573,
        "to_latitude": 21.01656988571541,
        "to_longitude": 105.81898487006494,
        "from_longitude": 105.81883198415176,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4658,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81867641603985,
          21.016513550660328,
          105.81883198415176,
          21.01655486303573
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81883198415176,
            21.01655486303573
          ],
          [
            105.81867641603985,
            21.016513550660328
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "diemDau": "52-4",
        "diemCuoi": "52-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.01655486303573,
        "to_latitude": 21.016513550660328,
        "to_longitude": 105.81867641603985,
        "from_longitude": 105.81883198415176,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4659,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81881589090533,
          21.01655486303573,
          105.81883198415176,
          21.016680051983347
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81883198415176,
            21.01655486303573
          ],
          [
            105.81881589090533,
            21.016680051983347
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "diemDau": "52-4",
        "diemCuoi": "52-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.01655486303573,
        "to_latitude": 21.016680051983347,
        "to_longitude": 105.81881589090533,
        "from_longitude": 105.81883198415176,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4660,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81871497280983,
          21.016680051983347,
          105.81881589090533,
          21.01682057645083
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81881589090533,
            21.016680051983347
          ],
          [
            105.81871497280983,
            21.01682057645083
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "diemDau": "52-7",
        "diemCuoi": "52-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.016680051983347,
        "to_latitude": 21.01682057645083,
        "to_longitude": 105.81871497280983,
        "from_longitude": 105.81881589090533,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4661,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81871497280983,
          21.0168039889377,
          105.81893524920375,
          21.01682057645083
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81871497280983,
            21.01682057645083
          ],
          [
            105.81893524920375,
            21.0168039889377
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "diemDau": "52-8",
        "diemCuoi": "52-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.01682057645083,
        "to_latitude": 21.0168039889377,
        "to_longitude": 105.81893524920375,
        "from_longitude": 105.81871497280983,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4662,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81958300281362,
          21.015940183775456,
          105.81971526911215,
          21.016069285412296
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81971526911215,
            21.016069285412296
          ],
          [
            105.81958300281362,
            21.015940183775456
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "43A",
        "diemCuoi": "50",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.016069285412296,
        "to_latitude": 21.015940183775456,
        "to_longitude": 105.81958300281362,
        "from_longitude": 105.81971526911215,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4663,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81972197464049,
          21.01559606870051,
          105.81990704705554,
          21.015944095952
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81972197464049,
            21.015944095952
          ],
          [
            105.81990704705554,
            21.01559606870051
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "diemDau": "50",
        "diemCuoi": "49",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.015944095952,
        "to_latitude": 21.01559606870051,
        "to_longitude": 105.81990704705554,
        "from_longitude": 105.81972197464049,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4664,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81846485689748,
          21.018615772706127,
          105.81863316543777,
          21.01864331390414
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81863316543777,
            21.01864331390414
          ],
          [
            105.81846485689748,
            21.018615772706127
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 38 Mai Anh Tuấn",
        "diemDau": "23B",
        "diemCuoi": "23-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.01864331390414,
        "to_latitude": 21.018615772706127,
        "to_longitude": 105.81846485689748,
        "from_longitude": 105.81863316543777,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4665,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81926365216125,
          21.01910666248227,
          105.81927538682686,
          21.019117616332146
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81926365216125,
            21.01910666248227
          ],
          [
            105.81927538682686,
            21.019117616332146
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "",
        "tuDien2": "HC6",
        "from_latitude": 21.01910666248227,
        "to_latitude": 21.019117616332146,
        "to_longitude": 105.81927538682686,
        "from_longitude": 105.81926365216125,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4666,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8191318886408,
          21.019117616332146,
          105.81927538682686,
          21.019435590588568
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81927538682686,
            21.019117616332146
          ],
          [
            105.8191318886408,
            21.019435590588568
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 174 phố Mai Anh Tuấn",
        "diemDau": "54",
        "diemCuoi": "55",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.019117616332146,
        "to_latitude": 21.019435590588568,
        "to_longitude": 105.8191318886408,
        "from_longitude": 105.81927538682686,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4667,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8191318886408,
          21.019435590588568,
          105.81915083189129,
          21.01963228987093
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8191318886408,
            21.019435590588568
          ],
          [
            105.81915083189129,
            21.01963228987093
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 174 phố Mai Anh Tuấn",
        "diemDau": "55",
        "diemCuoi": "56",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.019435590588568,
        "to_latitude": 21.01963228987093,
        "to_longitude": 105.81915083189129,
        "from_longitude": 105.8191318886408,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4668,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81915083189129,
          21.01963228987093,
          105.81917346287877,
          21.019721015721032
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81915083189129,
            21.01963228987093
          ],
          [
            105.81917346287877,
            21.019721015721032
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 174 phố Mai Anh Tuấn",
        "diemDau": "55",
        "diemCuoi": "56",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.01963228987093,
        "to_latitude": 21.019721015721032,
        "to_longitude": 105.81917346287877,
        "from_longitude": 105.81915083189129,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4669,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81917346287877,
          21.019721015721032,
          105.81928477455395,
          21.020052759158485
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81917346287877,
            21.019721015721032
          ],
          [
            105.81928477455395,
            21.020052759158485
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 174 phố Mai Anh Tuấn",
        "diemDau": "56",
        "diemCuoi": "57",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.019721015721032,
        "to_latitude": 21.020052759158485,
        "to_longitude": 105.81928477455395,
        "from_longitude": 105.81917346287877,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4670,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81915083189129,
          21.019624622206994,
          105.81929818560167,
          21.01963228987093
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81915083189129,
            21.01963228987093
          ],
          [
            105.81929818560167,
            21.019624622206994
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 174 phố Mai Anh Tuấn",
        "diemDau": "56",
        "diemCuoi": "56-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.01963228987093,
        "to_latitude": 21.019624622206994,
        "to_longitude": 105.81929818560167,
        "from_longitude": 105.81915083189129,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4671,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81929818560167,
          21.019624622206994,
          105.81952617336752,
          21.01971851199452
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81929818560167,
            21.019624622206994
          ],
          [
            105.81952617336752,
            21.01971851199452
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 174 phố Mai Anh Tuấn",
        "diemDau": "56-1",
        "diemCuoi": "56-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.019624622206994,
        "to_latitude": 21.01971851199452,
        "to_longitude": 105.81952617336752,
        "from_longitude": 105.81929818560167,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4672,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81952617336752,
          21.01971851199452,
          105.81973404456635,
          21.0197623272087
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81952617336752,
            21.01971851199452
          ],
          [
            105.81973404456635,
            21.0197623272087
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 174 phố Mai Anh Tuấn",
        "diemDau": "56-2",
        "diemCuoi": "56-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.01971851199452,
        "to_latitude": 21.0197623272087,
        "to_longitude": 105.81973404456635,
        "from_longitude": 105.81952617336752,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4673,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81973404456635,
          21.0197623272087,
          105.8199177758865,
          21.019778601427575
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81973404456635,
            21.0197623272087
          ],
          [
            105.8199177758865,
            21.019778601427575
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 174 phố Mai Anh Tuấn",
        "diemDau": "56-3",
        "diemCuoi": "56-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.0197623272087,
        "to_latitude": 21.019778601427575,
        "to_longitude": 105.8199177758865,
        "from_longitude": 105.81973404456635,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4674,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81928477455395,
          21.02000143286365,
          105.81967637707294,
          21.020052759158485
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81928477455395,
            21.020052759158485
          ],
          [
            105.81967637707294,
            21.02000143286365
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 174 phố Mai Anh Tuấn",
        "diemDau": "57",
        "diemCuoi": "58",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.020052759158485,
        "to_latitude": 21.02000143286365,
        "to_longitude": 105.81967637707294,
        "from_longitude": 105.81928477455395,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4675,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81967637707294,
          21.02000143286365,
          105.8197179513109,
          21.020202982358942
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81967637707294,
            21.02000143286365
          ],
          [
            105.8197179513109,
            21.020202982358942
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 174 phố Mai Anh Tuấn",
        "diemDau": "58",
        "diemCuoi": "59",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.02000143286365,
        "to_latitude": 21.020202982358942,
        "to_longitude": 105.8197179513109,
        "from_longitude": 105.81967637707294,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4676,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81919089724705,
          21.02002271450021,
          105.81928477455395,
          21.020052759158485
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81928477455395,
            21.020052759158485
          ],
          [
            105.81919089724705,
            21.02002271450021
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 4,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Láng Hạ",
        "diemDau": "57",
        "diemCuoi": "60",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.020052759158485,
        "to_latitude": 21.02002271450021,
        "to_longitude": 105.81919089724705,
        "from_longitude": 105.81928477455395,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4677,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81907556226025,
          21.019839942699083,
          105.81919089724705,
          21.02002271450021
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81919089724705,
            21.02002271450021
          ],
          [
            105.81907556226025,
            21.019839942699083
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Láng Hạ",
        "diemDau": "60",
        "diemCuoi": "61",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.02002271450021,
        "to_latitude": 21.019839942699083,
        "to_longitude": 105.81907556226025,
        "from_longitude": 105.81919089724705,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4678,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81900850703079,
          21.019674696767485,
          105.81907556226025,
          21.019839942699083
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81907556226025,
            21.019839942699083
          ],
          [
            105.81900850703079,
            21.019674696767485
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Láng Hạ",
        "diemDau": "61",
        "diemCuoi": "62",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.019839942699083,
        "to_latitude": 21.019674696767485,
        "to_longitude": 105.81900850703079,
        "from_longitude": 105.81907556226025,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4679,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81891999413936,
          21.019381760349138,
          105.81900850703079,
          21.019674696767485
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81900850703079,
            21.019674696767485
          ],
          [
            105.81891999413936,
            21.019381760349138
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Láng Hạ",
        "diemDau": "62",
        "diemCuoi": "63",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.019674696767485,
        "to_latitude": 21.019381760349138,
        "to_longitude": 105.81891999413936,
        "from_longitude": 105.81900850703079,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4680,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8221636231268,
          21.01871717436033,
          105.82225615933741,
          21.01881857594566
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8221636231268,
            21.01871717436033
          ],
          [
            105.8222420777872,
            21.01875973799494
          ],
          [
            105.82225615933741,
            21.01881857594566
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 38 Mai Anh Tuấn",
        "diemDau": "09C",
        "diemCuoi": "9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.01871717436033,
        "to_latitude": 21.01881857594566,
        "to_longitude": 105.82225615933741,
        "from_longitude": 105.8221636231268,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4681,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82357446507145,
          21.01814757158082,
          105.8237729485342,
          21.018225187911707
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8237729485342,
            21.01814757158082
          ],
          [
            105.82357446507145,
            21.018225187911707
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "02B",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.01814757158082,
        "to_latitude": 21.018225187911707,
        "to_longitude": 105.82357446507145,
        "from_longitude": 105.8237729485342,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4682,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82338402823191,
          21.018225187911707,
          105.82357446507145,
          21.018302804202214
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82357446507145,
            21.018225187911707
          ],
          [
            105.82338402823191,
            21.018302804202214
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "03C",
        "diemCuoi": "02B",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.018225187911707,
        "to_latitude": 21.018302804202214,
        "to_longitude": 105.82338402823191,
        "from_longitude": 105.82357446507145,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4683,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82318822696796,
          21.018302804202214,
          105.82338402823191,
          21.018380420452317
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82338402823191,
            21.018302804202214
          ],
          [
            105.82318822696796,
            21.018380420452317
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "04A",
        "diemCuoi": "03C",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.018302804202214,
        "to_latitude": 21.018380420452317,
        "to_longitude": 105.82318822696796,
        "from_longitude": 105.82338402823191,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4684,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82300315451698,
          21.018380420452317,
          105.82318822696796,
          21.018448021670046
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82318822696796,
            21.018380420452317
          ],
          [
            105.82300315451698,
            21.018448021670046
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "05B",
        "diemCuoi": "04A",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.018380420452317,
        "to_latitude": 21.018448021670046,
        "to_longitude": 105.82300315451698,
        "from_longitude": 105.82318822696796,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4685,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82281271771338,
          21.018448021670046,
          105.82300315451698,
          21.018515622855475
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82300315451698,
            21.018448021670046
          ],
          [
            105.82281271771338,
            21.018515622855475
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "06C",
        "diemCuoi": "05B",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.018448021670046,
        "to_latitude": 21.018515622855475,
        "to_longitude": 105.82281271771338,
        "from_longitude": 105.82300315451698,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4686,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82261959865714,
          21.018515622855475,
          105.82281271771338,
          21.018585727757504
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82281271771338,
            21.018515622855475
          ],
          [
            105.82261959865714,
            21.018585727757504
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "07A",
        "diemCuoi": "06C",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.018515622855475,
        "to_latitude": 21.018585727757504,
        "to_longitude": 105.82261959865714,
        "from_longitude": 105.82281271771338,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4687,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82239965751451,
          21.018585727757504,
          105.82261959865714,
          21.01865583262739
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82261959865714,
            21.018585727757504
          ],
          [
            105.82239965751451,
            21.01865583262739
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "08B",
        "diemCuoi": "07A",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.018585727757504,
        "to_latitude": 21.01865583262739,
        "to_longitude": 105.82239965751451,
        "from_longitude": 105.82261959865714,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4688,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8221636231268,
          21.01865583262739,
          105.82239965751451,
          21.01871717436033
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82239965751451,
            21.01865583262739
          ],
          [
            105.8221636231268,
            21.01871717436033
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "09C",
        "diemCuoi": "08B",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.01865583262739,
        "to_latitude": 21.01871717436033,
        "to_longitude": 105.8221636231268,
        "from_longitude": 105.82239965751451,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4689,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82193027093645,
          21.01871717436033,
          105.8221636231268,
          21.018781019810753
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8221636231268,
            21.01871717436033
          ],
          [
            105.82193027093645,
            21.018781019810753
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "10A",
        "diemCuoi": "09C",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.01871717436033,
        "to_latitude": 21.018781019810753,
        "to_longitude": 105.82193027093645,
        "from_longitude": 105.8221636231268,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4690,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82168082549067,
          21.018781019810753,
          105.82193027093645,
          21.018834850267044
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82193027093645,
            21.018781019810753
          ],
          [
            105.82168082549067,
            21.018834850267044
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "11B",
        "diemCuoi": "10A",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.018781019810753,
        "to_latitude": 21.018834850267044,
        "to_longitude": 105.82168082549067,
        "from_longitude": 105.82193027093645,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4691,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82143003894865,
          21.018834850267044,
          105.82168082549067,
          21.01890745875964
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82168082549067,
            21.018834850267044
          ],
          [
            105.82153531570583,
            21.018867398904305
          ],
          [
            105.82143003894865,
            21.01890745875964
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "12C",
        "diemCuoi": "11B",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.018834850267044,
        "to_latitude": 21.01890745875964,
        "to_longitude": 105.82143003894865,
        "from_longitude": 105.82168082549067,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4692,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8211752290874,
          21.01890745875964,
          105.82143003894865,
          21.018965044777865
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82143003894865,
            21.01890745875964
          ],
          [
            105.82136365432949,
            21.018937503640945
          ],
          [
            105.82129391690125,
            21.01896128916862
          ],
          [
            105.82124563713495,
            21.018965044777865
          ],
          [
            105.8211752290874,
            21.01895753356096
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "13A",
        "diemCuoi": "12C",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.01890745875964,
        "to_latitude": 21.01895753356096,
        "to_longitude": 105.8211752290874,
        "from_longitude": 105.82143003894865,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4693,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82083794126171,
          21.01895628168922,
          105.8211752290874,
          21.01906269059167
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8211752290874,
            21.01895753356096
          ],
          [
            105.82109409232565,
            21.01895628168922
          ],
          [
            105.82101094384973,
            21.018967548517878
          ],
          [
            105.82093986530862,
            21.018993837780307
          ],
          [
            105.82083794126171,
            21.01906269059167
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "14B",
        "diemCuoi": "13A",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.01895753356096,
        "to_latitude": 21.01906269059167,
        "to_longitude": 105.82083794126171,
        "from_longitude": 105.8211752290874,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4694,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82056167372954,
          21.01906269059167,
          105.82083794126171,
          21.01914030644624
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82083794126171,
            21.01906269059167
          ],
          [
            105.8207588161962,
            21.01911026159995
          ],
          [
            105.82069310207957,
            21.0191302914959
          ],
          [
            105.82056167372954,
            21.01914030644624
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "15C",
        "diemCuoi": "14B",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.01906269059167,
        "to_latitude": 21.01914030644624,
        "to_longitude": 105.82056167372954,
        "from_longitude": 105.82083794126171,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4695,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82031390467485,
          21.01914030644624,
          105.82056167372954,
          21.019150008425225
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82056167372954,
            21.01914030644624
          ],
          [
            105.82031390467485,
            21.019150008425225
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "16A",
        "diemCuoi": "15C",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.01914030644624,
        "to_latitude": 21.019150008425225,
        "to_longitude": 105.82031390467485,
        "from_longitude": 105.82056167372954,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4696,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82006010064822,
          21.019150008425225,
          105.82031390467485,
          21.019152825128586
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82031390467485,
            21.019150008425225
          ],
          [
            105.82006010064822,
            21.019152825128586
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "17B",
        "diemCuoi": "16A",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.019150008425225,
        "to_latitude": 21.019152825128586,
        "to_longitude": 105.82006010064822,
        "from_longitude": 105.82031390467485,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4697,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8198160196269,
          21.019137802709956,
          105.82006010064822,
          21.019152825128586
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82006010064822,
            21.019152825128586
          ],
          [
            105.81993135472153,
            21.019151573256806
          ],
          [
            105.8198160196269,
            21.019137802709956
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "18C",
        "diemCuoi": "17B",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.019152825128586,
        "to_latitude": 21.019137802709956,
        "to_longitude": 105.8198160196269,
        "from_longitude": 105.82006010064822,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4698,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81957730303009,
          21.019090231705448,
          105.8198160196269,
          21.019137802709956
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8198160196269,
            21.019137802709956
          ],
          [
            105.81957730303009,
            21.019090231705448
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "19A",
        "diemCuoi": "18C",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.019137802709956,
        "to_latitude": 21.019090231705448,
        "to_longitude": 105.81957730303009,
        "from_longitude": 105.8198160196269,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4699,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81933322199082,
          21.0190251345179,
          105.81957730303009,
          21.019090231705448
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81957730303009,
            21.019090231705448
          ],
          [
            105.81933322199082,
            21.0190251345179
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "20B",
        "diemCuoi": "19A",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.019090231705448,
        "to_latitude": 21.0190251345179,
        "to_longitude": 105.81933322199082,
        "from_longitude": 105.81957730303009,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4700,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81926365216125,
          21.0190251345179,
          105.81933322199082,
          21.01910666248227
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81933322199082,
            21.0190251345179
          ],
          [
            105.81926365216125,
            21.01910666248227
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "Tủ điện",
        "diemCuoi": "20B",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.0190251345179,
        "to_latitude": 21.01910666248227,
        "to_longitude": 105.81926365216125,
        "from_longitude": 105.81933322199082,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4701,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81908645876176,
          21.01892248120313,
          105.81933322199082,
          21.0190251345179
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81933322199082,
            21.0190251345179
          ],
          [
            105.81908645876176,
            21.01892248120313
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "20B",
        "diemCuoi": "21C",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.0190251345179,
        "to_latitude": 21.01892248120313,
        "to_longitude": 105.81908645876176,
        "from_longitude": 105.81933322199082,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4702,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81885042437267,
          21.018793538524587,
          105.81908645876176,
          21.01892248120313
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81908645876176,
            21.01892248120313
          ],
          [
            105.81885042437267,
            21.018793538524587
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "21C",
        "diemCuoi": "22A",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.01892248120313,
        "to_latitude": 21.018793538524587,
        "to_longitude": 105.81885042437267,
        "from_longitude": 105.81908645876176,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4703,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81863316543777,
          21.01864331390414,
          105.81885042437267,
          21.018793538524587
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81885042437267,
            21.018793538524587
          ],
          [
            105.81863316543777,
            21.01864331390414
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "22A",
        "diemCuoi": "23B",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.018793538524587,
        "to_latitude": 21.01864331390414,
        "to_longitude": 105.81863316543777,
        "from_longitude": 105.81885042437267,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4704,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81842663535183,
          21.01847431102578,
          105.81863316543777,
          21.01864331390414
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81863316543777,
            21.01864331390414
          ],
          [
            105.81842663535183,
            21.01847431102578
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "23B",
        "diemCuoi": "24C",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.01864331390414,
        "to_latitude": 21.01847431102578,
        "to_longitude": 105.81842663535183,
        "from_longitude": 105.81863316543777,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4705,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81823888072005,
          21.018305307955025,
          105.81842663535183,
          21.01847431102578
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81842663535183,
            21.01847431102578
          ],
          [
            105.81823888072005,
            21.018305307955025
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "24C",
        "diemCuoi": "25A",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.01847431102578,
        "to_latitude": 21.018305307955025,
        "to_longitude": 105.81823888072005,
        "from_longitude": 105.81842663535183,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4706,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81804844388054,
          21.018130045309395,
          105.81823888072005,
          21.018305307955025
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81823888072005,
            21.018305307955025
          ],
          [
            105.81804844388054,
            21.018130045309395
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "25A",
        "diemCuoi": "26B",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.018305307955025,
        "to_latitude": 21.018130045309395,
        "to_longitude": 105.81804844388054,
        "from_longitude": 105.81823888072005,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4707,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81787141807973,
          21.017954782457828,
          105.81804844388054,
          21.018130045309395
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81804844388054,
            21.018130045309395
          ],
          [
            105.81787141807973,
            21.017954782457828
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "26B",
        "diemCuoi": "27C",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.018130045309395,
        "to_latitude": 21.017954782457828,
        "to_longitude": 105.81787141807973,
        "from_longitude": 105.81804844388054,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4708,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8177078033356,
          21.01776574872253,
          105.81787141807973,
          21.017954782457828
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81787141807973,
            21.017954782457828
          ],
          [
            105.8177078033356,
            21.01776574872253
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "27C",
        "diemCuoi": "28A",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.017954782457828,
        "to_latitude": 21.01776574872253,
        "to_longitude": 105.8177078033356,
        "from_longitude": 105.81787141807973,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4709,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81755357630958,
          21.017590485441932,
          105.8177078033356,
          21.01776574872253
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8177078033356,
            21.01776574872253
          ],
          [
            105.81755357630958,
            21.017590485441932
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "28A",
        "diemCuoi": "29B",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.01776574872253,
        "to_latitude": 21.017590485441932,
        "to_longitude": 105.81755357630958,
        "from_longitude": 105.8177078033356,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4710,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81739800818869,
          21.017403955011112,
          105.81755357630958,
          21.017590485441932
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81755357630958,
            21.017590485441932
          ],
          [
            105.81739800818869,
            21.017403955011112
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "29B",
        "diemCuoi": "30C",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.017590485441932,
        "to_latitude": 21.017403955011112,
        "to_longitude": 105.81739800818869,
        "from_longitude": 105.81755357630958,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4711,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81730144866505,
          21.01722368376772,
          105.81739800818869,
          21.017403955011112
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81739800818869,
            21.017403955011112
          ],
          [
            105.81730815430119,
            21.017295041157784
          ],
          [
            105.81730144866505,
            21.01722368376772
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "30C",
        "diemCuoi": "31A",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.017403955011112,
        "to_latitude": 21.01722368376772,
        "to_longitude": 105.81730144866505,
        "from_longitude": 105.81739800818869,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4712,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81730144866505,
          21.017050923622246,
          105.81740873702864,
          21.01722368376772
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81730144866505,
            21.01722368376772
          ],
          [
            105.81740873702864,
            21.017050923622246
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "31A",
        "diemCuoi": "32B",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.01722368376772,
        "to_latitude": 21.017050923622246,
        "to_longitude": 105.81740873702864,
        "from_longitude": 105.81730144866505,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4713,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81740873702864,
          21.016735448058345,
          105.81760722049137,
          21.017050923622246
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81740873702864,
            21.017050923622246
          ],
          [
            105.81760722049137,
            21.016735448058345
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "32B",
        "diemCuoi": "33A",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.017050923622246,
        "to_latitude": 21.016735448058345,
        "to_longitude": 105.81760722049137,
        "from_longitude": 105.81740873702864,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4714,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81760722049137,
          21.01664656394722,
          105.81778424628318,
          21.016735448058345
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81760722049137,
            21.016735448058345
          ],
          [
            105.81765147705387,
            21.01666909795186
          ],
          [
            105.81769171019694,
            21.01664656394722
          ],
          [
            105.81778424628318,
            21.01665783095205
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "33A",
        "diemCuoi": "34B",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.016735448058345,
        "to_latitude": 21.01665783095205,
        "to_longitude": 105.81778424628318,
        "from_longitude": 105.81760722049137,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4715,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81778424628318,
          21.01665783095205,
          105.8180551493909,
          21.016775508484717
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81778424628318,
            21.01665783095205
          ],
          [
            105.8180551493909,
            21.016775508484717
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "34B",
        "diemCuoi": "35C",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.01665783095205,
        "to_latitude": 21.016775508484717,
        "to_longitude": 105.8180551493909,
        "from_longitude": 105.81778424628318,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4716,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8180551493909,
          21.016775508484717,
          105.81829386599672,
          21.016903201020938
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8180551493909,
            21.016775508484717
          ],
          [
            105.81829386599672,
            21.016903201020938
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "35C",
        "diemCuoi": "36A",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.016775508484717,
        "to_latitude": 21.016903201020938,
        "to_longitude": 105.81829386599672,
        "from_longitude": 105.8180551493909,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4717,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81829386599672,
          21.016903201020938,
          105.81853526481027,
          21.017048419850873
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81829386599672,
            21.016903201020938
          ],
          [
            105.81853526481027,
            21.017048419850873
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "36A",
        "diemCuoi": "37B",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.016903201020938,
        "to_latitude": 21.017048419850873,
        "to_longitude": 105.81853526481027,
        "from_longitude": 105.81829386599672,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4718,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81853526481027,
          21.017048419850873,
          105.81877129919938,
          21.017123532983806
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81853526481027,
            21.017048419850873
          ],
          [
            105.81864389439453,
            21.017109762242978
          ],
          [
            105.81870022078404,
            21.017122281095805
          ],
          [
            105.81877129919938,
            21.017123532983806
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "37B",
        "diemCuoi": "38C",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.017048419850873,
        "to_latitude": 21.017123532983806,
        "to_longitude": 105.81877129919938,
        "from_longitude": 105.81853526481027,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4719,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81877129919938,
          21.01695578026908,
          105.81895905383115,
          21.017123532983806
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81877129919938,
            21.017123532983806
          ],
          [
            105.81883164902628,
            21.017092235847105
          ],
          [
            105.81887456436812,
            21.017059686821195
          ],
          [
            105.81895905383115,
            21.01695578026908
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "38C",
        "diemCuoi": "39A",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.017123532983806,
        "to_latitude": 21.01695578026908,
        "to_longitude": 105.81895905383115,
        "from_longitude": 105.81877129919938,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4720,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81895905383115,
          21.016747966942592,
          105.81913607963195,
          21.01695578026908
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81895905383115,
            21.01695578026908
          ],
          [
            105.81913607963195,
            21.016747966942592
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "39A",
        "diemCuoi": "40B",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.01695578026908,
        "to_latitude": 21.016747966942592,
        "to_longitude": 105.81913607963195,
        "from_longitude": 105.81895905383115,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4721,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81913607963195,
          21.016540153327366,
          105.81931042321602,
          21.016747966942592
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81913607963195,
            21.016747966942592
          ],
          [
            105.81931042321602,
            21.016540153327366
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "40B",
        "diemCuoi": "41C",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.016747966942592,
        "to_latitude": 21.016540153327366,
        "to_longitude": 105.81931042321602,
        "from_longitude": 105.81913607963195,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4722,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81931042321602,
          21.016304797797737,
          105.81950086005553,
          21.016540153327366
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81931042321602,
            21.016540153327366
          ],
          [
            105.81950086005553,
            21.016304797797737
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "41C",
        "diemCuoi": "42B",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.016540153327366,
        "to_latitude": 21.016304797797737,
        "to_longitude": 105.81950086005553,
        "from_longitude": 105.81931042321602,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4723,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81950086005553,
          21.016069285412296,
          105.81971526911215,
          21.016304797797737
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81950086005553,
            21.016304797797737
          ],
          [
            105.81966313382156,
            21.01610825060632
          ],
          [
            105.81971526911215,
            21.016069285412296
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "42B",
        "diemCuoi": "43A",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.016304797797737,
        "to_latitude": 21.016069285412296,
        "to_longitude": 105.81971526911215,
        "from_longitude": 105.81950086005553,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4724,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81971526911215,
          21.016063182424958,
          105.82004786303385,
          21.01609322788099
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81971526911215,
            21.016069285412296
          ],
          [
            105.81976773996841,
            21.016063182424958
          ],
          [
            105.81983211299014,
            21.016065686212805
          ],
          [
            105.81999840994203,
            21.01609322788099
          ],
          [
            105.82004786303385,
            21.016090567610046
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "43A",
        "diemCuoi": "446",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.016069285412296,
        "to_latitude": 21.016090567610046,
        "to_longitude": 105.82004786303385,
        "from_longitude": 105.81971526911215,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4725,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82004786303385,
          21.01587774549512,
          105.82030669620673,
          21.016090567610046
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82004786303385,
            21.016090567610046
          ],
          [
            105.8202478553878,
            21.01591045126271
          ],
          [
            105.82030669620673,
            21.01587774549512
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "44C",
        "diemCuoi": "45A",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.016090567610046,
        "to_latitude": 21.01587774549512,
        "to_longitude": 105.82030669620673,
        "from_longitude": 105.82004786303385,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4726,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82030669620673,
          21.01586538302158,
          105.82054004838807,
          21.0159766452214
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82030669620673,
            21.01587774549512
          ],
          [
            105.82037123699783,
            21.01586538302158
          ],
          [
            105.8204168345474,
            21.015867886812785
          ],
          [
            105.82045438547375,
            21.01588040576987
          ],
          [
            105.82054004838807,
            21.0159766452214
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "45A",
        "diemCuoi": "46B",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.01587774549512,
        "to_latitude": 21.0159766452214,
        "to_longitude": 105.82054004838807,
        "from_longitude": 105.82030669620673,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4727,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82054004838807,
          21.0159766452214,
          105.8207465784804,
          21.016178200156737
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82054004838807,
            21.0159766452214
          ],
          [
            105.8207465784804,
            21.016178200156737
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "46B",
        "diemCuoi": "47C",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.0159766452214,
        "to_latitude": 21.016178200156737,
        "to_longitude": 105.8207465784804,
        "from_longitude": 105.82054004838807,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4728,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8207465784804,
          21.016178200156737,
          105.82093433311219,
          21.016370991579016
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8207465784804,
            21.016178200156737
          ],
          [
            105.82093433311219,
            21.016370991579016
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Mai Anh Tuấn",
        "diemDau": "47C",
        "diemCuoi": "48A",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC6",
        "from_latitude": 21.016178200156737,
        "to_latitude": 21.016370991579016,
        "to_longitude": 105.82093433311219,
        "from_longitude": 105.8207465784804,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4729,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83730754273213,
          21.021156583189526,
          105.8374724985981,
          21.021234197951614
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8374724985981,
            21.021156583189526
          ],
          [
            105.83744701762456,
            21.02123169424715
          ],
          [
            105.83730754273213,
            21.021234197951614
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "diemDau": "04",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021156583189526,
        "to_latitude": 21.021234197951614,
        "to_longitude": 105.83730754273213,
        "from_longitude": 105.8374724985981,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4730,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83696421997945,
          21.021234197951614,
          105.83730754273213,
          21.021234197951614
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83730754273213,
            21.021234197951614
          ],
          [
            105.83696421997945,
            21.021234197951614
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021234197951614,
        "to_latitude": 21.021234197951614,
        "to_longitude": 105.83696421997945,
        "from_longitude": 105.83730754273213,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4731,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83676573651672,
          21.021234197951614,
          105.83696421997945,
          21.021240457204577
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83696421997945,
            21.021234197951614
          ],
          [
            105.83682206292421,
            21.02123544980005
          ],
          [
            105.83676573651672,
            21.021240457204577
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021234197951614,
        "to_latitude": 21.021240457204577,
        "to_longitude": 105.83676573651672,
        "from_longitude": 105.83696421997945,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4732,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83654311316636,
          21.021234197949095,
          105.83676573651672,
          21.021249220159042
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83676573651672,
            21.021240457204577
          ],
          [
            105.836587369639,
            21.021234197949095
          ],
          [
            105.83654311316636,
            21.021249220159042
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021240457204577,
        "to_latitude": 21.021249220159042,
        "to_longitude": 105.83654311316636,
        "from_longitude": 105.83676573651672,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4733,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83640900271635,
          21.021249220159042,
          105.83654311316636,
          21.021294286773827
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83654311316636,
            21.021249220159042
          ],
          [
            105.83640900271635,
            21.021294286773827
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021249220159042,
        "to_latitude": 21.021294286773827,
        "to_longitude": 105.83640900271635,
        "from_longitude": 105.83654311316636,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4734,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8368515672004,
          21.021234197951614,
          105.83696421997945,
          21.021284271971524
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8368515672004,
            21.021284271971524
          ],
          [
            105.83685156722736,
            21.02124796830651
          ],
          [
            105.83696421997945,
            21.021234197951614
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "diemDau": "17",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021284271971524,
        "to_latitude": 21.021234197951614,
        "to_longitude": 105.83696421997945,
        "from_longitude": 105.8368515672004,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4735,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83685056138374,
          21.021284271971524,
          105.83685693164283,
          21.021451080930056
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8368515672004,
            21.021284271971524
          ],
          [
            105.83685693164283,
            21.02143324208023
          ],
          [
            105.83685056138374,
            21.021451080930056
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021284271971524,
        "to_latitude": 21.021451080930056,
        "to_longitude": 105.83685056138374,
        "from_longitude": 105.8368515672004,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4736,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8366684091469,
          21.021451080930056,
          105.83685056138374,
          21.021594145172802
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83685056138374,
            21.021451080930056
          ],
          [
            105.8366684091469,
            21.021594145172802
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021451080930056,
        "to_latitude": 21.021594145172802,
        "to_longitude": 105.8366684091469,
        "from_longitude": 105.83685056138374,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4737,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83650857973491,
          21.021594145172802,
          105.8366684091469,
          21.021694878300806
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8366684091469,
            21.021594145172802
          ],
          [
            105.8365471365049,
            21.021694878300806
          ],
          [
            105.83650857973491,
            21.021690183877705
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021594145172802,
        "to_latitude": 21.021690183877705,
        "to_longitude": 105.83650857973491,
        "from_longitude": 105.8366684091469,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4738,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83650857973491,
          21.021690183877705,
          105.83673924970853,
          21.02177530944156
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83650857973491,
            21.021690183877705
          ],
          [
            105.83653104324945,
            21.021709900461776
          ],
          [
            105.83657127638357,
            21.021753715090764
          ],
          [
            105.83665308376459,
            21.02175747062967
          ],
          [
            105.83673924970853,
            21.02177530944156
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021690183877705,
        "to_latitude": 21.02177530944156,
        "to_longitude": 105.83673924970853,
        "from_longitude": 105.83650857973491,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4739,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83638620395953,
          21.021690183877705,
          105.83650857973491,
          21.021820062933088
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83650857973491,
            21.021690183877705
          ],
          [
            105.8364009561111,
            21.02178626309204
          ],
          [
            105.83638620395953,
            21.021820062933088
          ],
          [
            105.83638620395953,
            21.021817559241
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "diemDau": "24",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021690183877705,
        "to_latitude": 21.021817559241,
        "to_longitude": 105.83638620395953,
        "from_longitude": 105.83650857973491,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4740,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83626684566111,
          21.021817559241,
          105.83638620395953,
          21.021941804889238
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83638620395953,
            21.021817559241
          ],
          [
            105.83626684566111,
            21.021910195803137
          ],
          [
            105.83626718092137,
            21.021941804889238
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "diemDau": "23",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021817559241,
        "to_latitude": 21.021941804889238,
        "to_longitude": 105.83626718092137,
        "from_longitude": 105.83638620395953,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4741,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83613508920975,
          21.021816087089142,
          105.83626718092137,
          21.021941804889238
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83626718092137,
            21.021941804889238
          ],
          [
            105.83613541741887,
            21.021840092464195
          ],
          [
            105.83613508920975,
            21.021816087089142
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "diemDau": "27",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021941804889238,
        "to_latitude": 21.021816087089142,
        "to_longitude": 105.83613508920975,
        "from_longitude": 105.83626718092137,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4742,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83626718092137,
          21.021941804889238,
          105.8364643232892,
          21.022099224284275
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83626718092137,
            21.021941804889238
          ],
          [
            105.83629098554877,
            21.02196778066335
          ],
          [
            105.8363339008906,
            21.0220015804624
          ],
          [
            105.83637145181694,
            21.02203538025382
          ],
          [
            105.83643582483869,
            21.022099224284275
          ],
          [
            105.8364643232892,
            21.022093278030155
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x2,5",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021941804889238,
        "to_latitude": 21.022093278030155,
        "to_longitude": 105.8364643232892,
        "from_longitude": 105.83626718092137,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4743,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8360432164671,
          21.021941804889238,
          105.83626718092137,
          21.02212958148684
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83626718092137,
            21.021941804889238
          ],
          [
            105.83621051928056,
            21.021981550953008
          ],
          [
            105.83615016957043,
            21.0220291210342
          ],
          [
            105.83611530086083,
            21.022065424506483
          ],
          [
            105.8360432164671,
            21.02212958148684
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "diemDau": "27",
        "diemCuoi": "VC2 29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021941804889238,
        "to_latitude": 21.02212958148684,
        "to_longitude": 105.8360432164671,
        "from_longitude": 105.83626718092137,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4744,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83591715264934,
          21.02204445612526,
          105.8360432164671,
          21.02212958148684
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8360432164671,
            21.02212958148684
          ],
          [
            105.83600130697786,
            21.02210548350092
          ],
          [
            105.83597448488248,
            21.02206417266334
          ],
          [
            105.83591715264934,
            21.02204445612526
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "diemDau": "VC2 29",
        "diemCuoi": "VC2 30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02212958148684,
        "to_latitude": 21.02204445612526,
        "to_longitude": 105.83591715264934,
        "from_longitude": 105.8360432164671,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4745,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8359193603607,
          21.021712608566357,
          105.83613508920975,
          21.021816087089142
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83613508920975,
            21.021816087089142
          ],
          [
            105.83610054870923,
            21.0218125518575
          ],
          [
            105.83605092783907,
            21.021772492785193
          ],
          [
            105.83599862476115,
            21.021764981707207
          ],
          [
            105.83596777936312,
            21.02173368554804
          ],
          [
            105.83594095726774,
            21.021713656001804
          ],
          [
            105.8359193603607,
            21.021712608566357
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021816087089142,
        "to_latitude": 21.021712608566357,
        "to_longitude": 105.8359193603607,
        "from_longitude": 105.83613508920975,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4746,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83580416461001,
          21.02160224160486,
          105.8359193603607,
          21.021712608566357
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8359193603607,
            21.021712608566357
          ],
          [
            105.83580416461001,
            21.02160224160486
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "diemDau": "32",
        "diemCuoi": "VC2 34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021712608566357,
        "to_latitude": 21.02160224160486,
        "to_longitude": 105.83580416461001,
        "from_longitude": 105.8359193603607,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4747,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83580416461001,
          21.021580059428643,
          105.83593427972883,
          21.02160224160486
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83580416461001,
            21.02160224160486
          ],
          [
            105.83593427972883,
            21.021580059428643
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02160224160486,
        "to_latitude": 21.021580059428643,
        "to_longitude": 105.83593427972883,
        "from_longitude": 105.83580416461001,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4748,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83559126424699,
          21.02150991781708,
          105.83580416461001,
          21.02160224160486
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83580416461001,
            21.02160224160486
          ],
          [
            105.8356982173593,
            21.021518367790794
          ],
          [
            105.83559126424699,
            21.02150991781708
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "diemDau": "32",
        "diemCuoi": "VC2 34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02160224160486,
        "to_latitude": 21.02150991781708,
        "to_longitude": 105.83559126424699,
        "from_longitude": 105.83580416461001,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4749,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83529298840038,
          21.021417109671717,
          105.83559126424699,
          21.02150991781708
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83559126424699,
            21.02150991781708
          ],
          [
            105.83556410690032,
            21.021478308638603
          ],
          [
            105.83543804308255,
            21.021479560487485
          ],
          [
            105.83543804308255,
            21.02142823468383
          ],
          [
            105.83529298840038,
            21.021417109671717
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "diemDau": "VC2 34",
        "diemCuoi": "VC2 35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02150991781708,
        "to_latitude": 21.021417109671717,
        "to_longitude": 105.83529298840038,
        "from_longitude": 105.83559126424699,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4750,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83749663847676,
          21.021340937694294,
          105.83769249654559,
          21.02141446434813
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83749663847676,
            21.02141446434813
          ],
          [
            105.83756637592296,
            21.021413212497
          ],
          [
            105.83769249654559,
            21.021340937694294
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 71 Văn Chương",
        "diemDau": "05",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02141446434813,
        "to_latitude": 21.021340937694294,
        "to_longitude": 105.83769249654559,
        "from_longitude": 105.83749663847676,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4751,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83769249654559,
          21.02117285725455,
          105.83796870729088,
          21.021340937694294
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83769249654559,
            21.021340937694294
          ],
          [
            105.83772730845935,
            21.02127550902001
          ],
          [
            105.83777961153727,
            21.021235449803406
          ],
          [
            105.83784264344615,
            21.02122668684819
          ],
          [
            105.83795127291361,
            21.02117285725455
          ],
          [
            105.83796870729088,
            21.021175360953283
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 71 Văn Chương",
        "diemDau": "5-1",
        "diemCuoi": "5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021340937694294,
        "to_latitude": 21.021175360953283,
        "to_longitude": 105.83796870729088,
        "from_longitude": 105.83769249654559,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4752,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83790031096649,
          21.021087731343385,
          105.83796870729088,
          21.021175360953283
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83796870729088,
            21.021175360953283
          ],
          [
            105.8379284741388,
            21.02109399060672
          ],
          [
            105.83790031096649,
            21.021087731343385
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 71 Văn Chương",
        "diemDau": "5-2",
        "diemCuoi": "5-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021175360953283,
        "to_latitude": 21.021087731343385,
        "to_longitude": 105.83790031096649,
        "from_longitude": 105.83796870729088,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4753,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83790031096649,
          21.021026390585234,
          105.83801832816106,
          21.021087731343385
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83790031096649,
            21.021087731343385
          ],
          [
            105.83801832816106,
            21.021026390585234
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 71 Văn Chương",
        "diemDau": "5-3",
        "diemCuoi": "5-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021087731343385,
        "to_latitude": 21.021026390585234,
        "to_longitude": 105.83801832816106,
        "from_longitude": 105.83790031096649,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4754,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83801832816106,
          21.020975800128266,
          105.83818888336036,
          21.021026390585234
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83801832816106,
            21.021026390585234
          ],
          [
            105.83805185574884,
            21.02101512391634
          ],
          [
            105.83812561649768,
            21.02100135353742
          ],
          [
            105.83818888336036,
            21.020975800128266
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 71 Văn Chương",
        "diemDau": "5-4",
        "diemCuoi": "5-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.021026390585234,
        "to_latitude": 21.020975800128266,
        "to_longitude": 105.83818888336036,
        "from_longitude": 105.83801832816106,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4755,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83818888336036,
          21.020975800128266,
          105.83824899809872,
          21.020985079452004
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83818888336036,
            21.020975800128266
          ],
          [
            105.8382141293981,
            21.020985079452004
          ],
          [
            105.83824899809872,
            21.020983827598098
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 71 Văn Chương",
        "diemDau": "5-5",
        "diemCuoi": "5-5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.020975800128266,
        "to_latitude": 21.020983827598098,
        "to_longitude": 105.83824899809872,
        "from_longitude": 105.83818888336036,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4756,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83818888336036,
          21.02082491157064,
          105.83840451695625,
          21.020975800128266
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83818888336036,
            21.020975800128266
          ],
          [
            105.83822888154964,
            21.020958790540632
          ],
          [
            105.83823424596514,
            21.02093124977113
          ],
          [
            105.83838444967056,
            21.02082734591512
          ],
          [
            105.83840451695625,
            21.02082491157064
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 71 Văn Chương",
        "diemDau": "5-5",
        "diemCuoi": "5-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.020975800128266,
        "to_latitude": 21.02082491157064,
        "to_longitude": 105.83840451695625,
        "from_longitude": 105.83818888336036,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4757,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83667990583307,
          21.0222351549375,
          105.83689183418777,
          21.022577740677285
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83689183418777,
            21.0222351549375
          ],
          [
            105.83681401628301,
            21.022236927003796
          ],
          [
            105.83673086779811,
            21.022307030155325
          ],
          [
            105.83667990583307,
            21.02231203752221
          ],
          [
            105.83669868129621,
            21.02239966641335
          ],
          [
            105.83672818559037,
            21.02247227316949
          ],
          [
            105.8367094101272,
            21.022482287891158
          ],
          [
            105.83672550338261,
            21.022522346773588
          ],
          [
            105.83672416227876,
            21.022532361492726
          ],
          [
            105.8366825880318,
            21.02255113908824
          ],
          [
            105.83668895830887,
            21.022577740677285
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Linh Quang",
        "diemDau": "VC2 09",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.0222351549375,
        "to_latitude": 21.022577740677285,
        "to_longitude": 105.83668895830887,
        "from_longitude": 105.83689183418777,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4758,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83667588250347,
          21.022577740677285,
          105.83669599907051,
          21.02282059732931
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83668895830887,
            21.022577740677285
          ],
          [
            105.83669331686278,
            21.02259996082636
          ],
          [
            105.83667588250347,
            21.022736411242533
          ],
          [
            105.83669599907051,
            21.022799003134132
          ],
          [
            105.83669298162047,
            21.02282059732931
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Linh Quang",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.022577740677285,
        "to_latitude": 21.02282059732931,
        "to_longitude": 105.83669298162047,
        "from_longitude": 105.83668895830887,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4759,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83669298162047,
          21.02282059732931,
          105.83673489110075,
          21.02294077367079
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83669298162047,
            21.02282059732931
          ],
          [
            105.83673489110075,
            21.022917927656174
          ],
          [
            105.83673321475459,
            21.02294077367079
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Linh Quang",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02282059732931,
        "to_latitude": 21.02294077367079,
        "to_longitude": 105.83673321475459,
        "from_longitude": 105.83669298162047,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4760,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83659541623527,
          21.02282059732931,
          105.83669298162047,
          21.022869418980026
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83669298162047,
            21.02282059732931
          ],
          [
            105.83659541623527,
            21.02285783948834
          ],
          [
            105.83660178652133,
            21.022869418980026
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Linh Quang",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02282059732931,
        "to_latitude": 21.022869418980026,
        "to_longitude": 105.83660178652133,
        "from_longitude": 105.83669298162047,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4761,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83641269078569,
          21.022869418980026,
          105.83660882727398,
          21.02298208426629
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83660178652133,
            21.022869418980026
          ],
          [
            105.83660882727398,
            21.022895394596713
          ],
          [
            105.83653506652514,
            21.022921683166594
          ],
          [
            105.83652031438255,
            21.022921683166594
          ],
          [
            105.83641269078569,
            21.02298208426629
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Linh Quang",
        "diemDau": "11-1",
        "diemCuoi": "11-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.022869418980026,
        "to_latitude": 21.02298208426629,
        "to_longitude": 105.83641269078569,
        "from_longitude": 105.83660178652133,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4762,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83673321475459,
          21.02294077367079,
          105.83685123195811,
          21.023168294761724
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83673321475459,
            21.02294077367079
          ],
          [
            105.83683681503986,
            21.023168294761724
          ],
          [
            105.83685123195811,
            21.02316610405031
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Linh Quang",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02294077367079,
        "to_latitude": 21.02316610405031,
        "to_longitude": 105.83685123195811,
        "from_longitude": 105.83673321475459,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4763,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83685123195811,
          21.02314701357414,
          105.83705742675689,
          21.023216490380996
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83685123195811,
            21.02316610405031
          ],
          [
            105.83688777701391,
            21.02314701357414
          ],
          [
            105.83697092548982,
            21.02317330209966
          ],
          [
            105.83705742675689,
            21.023216490380996
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Linh Quang",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02316610405031,
        "to_latitude": 21.023216490380996,
        "to_longitude": 105.83705742675689,
        "from_longitude": 105.83685123195811,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4764,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83705742675689,
          21.023216490380996,
          105.83720226603783,
          21.02350065644347
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83705742675689,
            21.023216490380996
          ],
          [
            105.8370969893166,
            21.023250915814376
          ],
          [
            105.83719623104795,
            21.023454964580708
          ],
          [
            105.83720226603783,
            21.02350065644347
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Linh Quang",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.023216490380996,
        "to_latitude": 21.02350065644347,
        "to_longitude": 105.83720226603783,
        "from_longitude": 105.83705742675689,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4765,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83717142063982,
          21.023423042858777,
          105.83720226603783,
          21.02350065644347
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83720226603783,
            21.02350065644347
          ],
          [
            105.83717142063982,
            21.023423042858777
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Linh Quang",
        "diemDau": "15",
        "diemCuoi": "15-16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02350065644347,
        "to_latitude": 21.023423042858777,
        "to_longitude": 105.83717142063982,
        "from_longitude": 105.83720226603783,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4766,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83720226603783,
          21.02350065644347,
          105.83730821329755,
          21.0236120694217
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83720226603783,
            21.02350065644347
          ],
          [
            105.83724719302201,
            21.02351380067688
          ],
          [
            105.83730821329755,
            21.0236120694217
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Linh Quang",
        "diemDau": "15",
        "diemCuoi": "15-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02350065644347,
        "to_latitude": 21.0236120694217,
        "to_longitude": 105.83730821329755,
        "from_longitude": 105.83720226603783,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4767,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83730821329755,
          21.0236120694217,
          105.8373578341677,
          21.023847413417375
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83730821329755,
            21.0236120694217
          ],
          [
            105.83731156603477,
            21.02363773195107
          ],
          [
            105.8373182715631,
            21.02373913018946
          ],
          [
            105.83732765929022,
            21.023771677758116
          ],
          [
            105.8373578341677,
            21.023847413417375
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Linh Quang",
        "diemDau": "15-7",
        "diemCuoi": "VC2 15-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.0236120694217,
        "to_latitude": 21.023847413417375,
        "to_longitude": 105.8373578341677,
        "from_longitude": 105.83730821329755,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4768,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83655786531791,
          21.02294077367079,
          105.83673321475459,
          21.02302808923316
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83673321475459,
            21.02294077367079
          ],
          [
            105.83657932297983,
            21.02301056353378
          ],
          [
            105.83655786531791,
            21.02302808923316
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 94 Linh Quang",
        "diemDau": "12",
        "diemCuoi": "12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02294077367079,
        "to_latitude": 21.02302808923316,
        "to_longitude": 105.83655786531791,
        "from_longitude": 105.83673321475459,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4769,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83673355000585,
          21.02316610405031,
          105.83685123195811,
          21.02321961996548
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83685123195811,
            21.02316610405031
          ],
          [
            105.83683815614371,
            21.023210857126877
          ],
          [
            105.83680596964182,
            21.02321961996548
          ],
          [
            105.83673355000585,
            21.02321586446173
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 70 Linh Quang",
        "diemDau": "13",
        "diemCuoi": "13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02316610405031,
        "to_latitude": 21.02321586446173,
        "to_longitude": 105.83673355000585,
        "from_longitude": 105.83685123195811,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4770,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83661285060356,
          21.02321586446173,
          105.83673355000585,
          21.02323964930772
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83673355000585,
            21.02321586446173
          ],
          [
            105.83661285060356,
            21.02323964930772
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 70 Linh Quang",
        "diemDau": "13-1",
        "diemCuoi": "13-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02321586446173,
        "to_latitude": 21.02323964930772,
        "to_longitude": 105.83661285060356,
        "from_longitude": 105.83673355000585,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4771,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8365887107249,
          21.02323964930772,
          105.8366168739062,
          21.023313507489114
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83661285060356,
            21.02323964930772
          ],
          [
            105.8366168739062,
            21.023313507489114
          ],
          [
            105.8365887107249,
            21.02331350748745
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 70 Linh Quang",
        "diemDau": "13-2",
        "diemCuoi": "13-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02323964930772,
        "to_latitude": 21.02331350748745,
        "to_longitude": 105.8365887107249,
        "from_longitude": 105.83661285060356,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4772,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83647203462523,
          21.023285967153743,
          105.8365887107249,
          21.02331350748745
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8365887107249,
            21.02331350748745
          ],
          [
            105.83647203462523,
            21.023285967153743
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 70 Linh Quang",
        "diemDau": "13-3",
        "diemCuoi": "13-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02331350748745,
        "to_latitude": 21.023285967153743,
        "to_longitude": 105.83647203462523,
        "from_longitude": 105.8365887107249,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4773,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8193706052556,
          21.015940183775456,
          105.81972197464049,
          21.015949103532567
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81972197464049,
            21.015944095952
          ],
          [
            105.81958300281362,
            21.015940183775456
          ],
          [
            105.8193706052556,
            21.015949103532567
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "diemDau": "50",
        "diemCuoi": "51",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.015944095952,
        "to_latitude": 21.015949103532567,
        "to_longitude": 105.8193706052556,
        "from_longitude": 105.81972197464049,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4774,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81900230443324,
          21.015944095952,
          105.8191295416933,
          21.016112945211017
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8190809266847,
            21.015944095952
          ],
          [
            105.8191295416933,
            21.015958179771122
          ],
          [
            105.81912417726885,
            21.01611091088364
          ],
          [
            105.81900230443324,
            21.016112945211017
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "diemDau": "52",
        "diemCuoi": "52-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.015944095952,
        "to_latitude": 21.016112945211017,
        "to_longitude": 105.81900230443324,
        "from_longitude": 105.8190809266847,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4775,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81899811345106,
          21.016112945211017,
          105.819125350792,
          21.016272405087474
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.819125350792,
            21.016272248599947
          ],
          [
            105.81899811345106,
            21.016272405087474
          ],
          [
            105.81900230443324,
            21.016112945211017
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC6",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "diemDau": "52-1",
        "diemCuoi": "52-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC6",
        "from_latitude": 21.016272248599947,
        "to_latitude": 21.016112945211017,
        "to_longitude": 105.81900230443324,
        "from_longitude": 105.819125350792,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4776,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83705742675689,
          21.02319708694994,
          105.837185502208,
          21.023223375466618
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83705742675689,
            21.023216490380996
          ],
          [
            105.83711040038224,
            21.023223375466618
          ],
          [
            105.837185502208,
            21.02319708694994
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 75 Linh Quang",
        "diemDau": "14",
        "diemCuoi": "14-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.023216490380996,
        "to_latitude": 21.02319708694994,
        "to_longitude": 105.837185502208,
        "from_longitude": 105.83705742675689,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4777,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83700847642517,
          21.023444949922713,
          105.83720226603783,
          21.02350065644347
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83720226603783,
            21.02350065644347
          ],
          [
            105.83715465682796,
            21.023444949922713
          ],
          [
            105.83700847642517,
            21.023491267704976
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 48 Linh Quang",
        "diemDau": "15",
        "diemCuoi": "15-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02350065644347,
        "to_latitude": 21.023491267704976,
        "to_longitude": 105.83700847642517,
        "from_longitude": 105.83720226603783,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4778,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83681803958565,
          21.02348125305025,
          105.83700847642517,
          21.023510045180544
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83700847642517,
            21.023491267704976
          ],
          [
            105.83688375372024,
            21.023510045180544
          ],
          [
            105.83681803958565,
            21.02348125305025
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 48 Linh Quang",
        "diemDau": "15-3",
        "diemCuoi": "15-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.023491267704976,
        "to_latitude": 21.02348125305025,
        "to_longitude": 105.83681803958565,
        "from_longitude": 105.83700847642517,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4779,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83655518310118,
          21.02348125305025,
          105.83681803958565,
          21.023553859280476
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83681803958565,
            21.02348125305025
          ],
          [
            105.83658468741328,
            21.02353132631628
          ],
          [
            105.83655518310118,
            21.023553859280476
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 48 Linh Quang",
        "diemDau": "15-4",
        "diemCuoi": "15-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02348125305025,
        "to_latitude": 21.023553859280476,
        "to_longitude": 105.83655518310118,
        "from_longitude": 105.83681803958565,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4780,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83706346171083,
          21.02350065644347,
          105.83720226603783,
          21.023582651395913
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83720226603783,
            21.02350065644347
          ],
          [
            105.83706346171083,
            21.023582651395913
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 46 Linh Quang",
        "diemDau": "15",
        "diemCuoi": "15-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02350065644347,
        "to_latitude": 21.023582651395913,
        "to_longitude": 105.83706346171083,
        "from_longitude": 105.83720226603783,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4781,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8368475438798,
          21.023582651395913,
          105.83706346171083,
          21.023687805162023
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83706346171083,
            21.023582651395913
          ],
          [
            105.83687570709701,
            21.023687805162023
          ],
          [
            105.83686631936094,
            21.023675286860453
          ],
          [
            105.8368475438798,
            21.023686553332507
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 46 Linh Quang",
        "diemDau": "15-1",
        "diemCuoi": "15-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.023582651395913,
        "to_latitude": 21.023686553332507,
        "to_longitude": 105.8368475438798,
        "from_longitude": 105.83706346171083,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4782,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83720226603783,
          21.023443698090308,
          105.83737996237711,
          21.02350065644347
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83720226603783,
            21.02350065644347
          ],
          [
            105.83727401513536,
            21.023498778695146
          ],
          [
            105.83737996237711,
            21.023443698090308
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 46 Linh Quang",
        "diemDau": "15",
        "diemCuoi": "15-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02350065644347,
        "to_latitude": 21.023443698090308,
        "to_longitude": 105.83737996237711,
        "from_longitude": 105.83720226603783,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4783,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83730821329755,
          21.023535081810405,
          105.83749932067552,
          21.0236120694217
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83730821329755,
            21.0236120694217
          ],
          [
            105.8374242188318,
            21.023547600123756
          ],
          [
            105.83749932067552,
            21.023535081810405
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 55 Linh Quang",
        "diemDau": "15-7",
        "diemCuoi": "15-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.0236120694217,
        "to_latitude": 21.023535081810405,
        "to_longitude": 105.83749932067552,
        "from_longitude": 105.83730821329755,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4784,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8373578341677,
          21.023847413417375,
          105.83762270228556,
          21.024049583625864
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8373578341677,
            21.023847413417375
          ],
          [
            105.83737728017834,
            21.023868068589277
          ],
          [
            105.83739069122603,
            21.023923149037326
          ],
          [
            105.83742555994463,
            21.023924400865702
          ],
          [
            105.83744031208724,
            21.023936919146355
          ],
          [
            105.8374671341826,
            21.02403831718208
          ],
          [
            105.83762270228556,
            21.024049583625864
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 49 Linh Quang",
        "diemDau": "VC2 15-9",
        "diemCuoi": "VC2 15-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.023847413417375,
        "to_latitude": 21.024049583625864,
        "to_longitude": 105.83762270228556,
        "from_longitude": 105.8373578341677,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4785,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8371144236759,
          21.023847413417375,
          105.8373578341677,
          21.023951941082267
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8373578341677,
            21.023847413417375
          ],
          [
            105.83734643477135,
            21.023900616129794
          ],
          [
            105.83727267402251,
            21.023903119786066
          ],
          [
            105.8371144236759,
            21.023951941082267
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 36 Linh Quang",
        "diemDau": "VC2 15-9",
        "diemCuoi": "15-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.023847413417375,
        "to_latitude": 21.023951941082267,
        "to_longitude": 105.8371144236759,
        "from_longitude": 105.8373578341677,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4786,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83695349113951,
          21.023944430114934,
          105.8371144236759,
          21.023951941082267
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8371144236759,
            21.023951941082267
          ],
          [
            105.83698299545162,
            21.023951941082267
          ],
          [
            105.83695349113951,
            21.023944430114934
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 36 Linh Quang",
        "diemDau": "15-10",
        "diemCuoi": "15-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.023951941082267,
        "to_latitude": 21.023944430114934,
        "to_longitude": 105.83695349113951,
        "from_longitude": 105.8371144236759,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4787,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8371144236759,
          21.023951941082267,
          105.83713588134681,
          21.024172262629225
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8371144236759,
            21.023951941082267
          ],
          [
            105.83713588134681,
            21.024172262629225
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 36 Linh Quang",
        "diemDau": "15-10",
        "diemCuoi": "VC2 15-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.023951941082267,
        "to_latitude": 21.024172262629225,
        "to_longitude": 105.83713588134681,
        "from_longitude": 105.8371144236759,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4788,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83692532794025,
          21.024145974279843,
          105.83713588134681,
          21.024172262629225
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83713588134681,
            21.024172262629225
          ],
          [
            105.83709162491907,
            21.024145974279843
          ],
          [
            105.83692532794025,
            21.02415098158495
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 36 Linh Quang",
        "diemDau": "VC2 15-12",
        "diemCuoi": "15-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.024172262629225,
        "to_latitude": 21.02415098158495,
        "to_longitude": 105.83692532794025,
        "from_longitude": 105.83713588134681,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4789,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83669734017438,
          21.02414472245333,
          105.83692532794025,
          21.024155988889902
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83692532794025,
            21.02415098158495
          ],
          [
            105.83676305431796,
            21.02414472245333
          ],
          [
            105.83669734017438,
            21.024155988889902
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 36 Linh Quang",
        "diemDau": "15-13",
        "diemCuoi": "VC2 15-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC2",
        "from_latitude": 21.02415098158495,
        "to_latitude": 21.024155988889902,
        "to_longitude": 105.83669734017438,
        "from_longitude": 105.83692532794025,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4790,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82765347284207,
          21.027063326844235,
          105.82816322166232,
          21.02757626242666
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82769919949838,
            21.027063326844235
          ],
          [
            105.82765347284207,
            21.02757626242666
          ],
          [
            105.82816322166232,
            21.027473917257517
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "An Trạch",
        "diemDau": "28",
        "diemCuoi": "Tủ",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.027063326844235,
        "to_latitude": 21.027473917257517,
        "to_longitude": 105.82816322166232,
        "from_longitude": 105.82769919949838,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4791,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82802374678786,
          21.027028276390563,
          105.82816322166232,
          21.027473917257517
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82816322166232,
            21.027473917257517
          ],
          [
            105.82808811980063,
            21.027450133086298
          ],
          [
            105.82802374678786,
            21.027169729955318
          ],
          [
            105.82806934434643,
            21.027028276390563
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "An Trạch",
        "diemDau": "Tủ",
        "diemCuoi": "1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.027473917257517,
        "to_latitude": 21.027028276390563,
        "to_longitude": 105.82806934434643,
        "from_longitude": 105.82816322166232,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4792,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82806934434643,
          21.027028276390563,
          105.82848240452735,
          21.02720978757728
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82806934434643,
            21.027028276390563
          ],
          [
            105.82848240452735,
            21.02720978757728
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 51,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "An Trạch",
        "diemDau": "1",
        "diemCuoi": "2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.027028276390563,
        "to_latitude": 21.02720978757728,
        "to_longitude": 105.82848240452735,
        "from_longitude": 105.82806934434643,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4793,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82848240452735,
          21.02720978757728,
          105.828825727289,
          21.02734623377384
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82848240452735,
            21.02720978757728
          ],
          [
            105.828825727289,
            21.02734623377384
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "An Trạch",
        "diemDau": "2",
        "diemCuoi": "3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.02720978757728,
        "to_latitude": 21.02734623377384,
        "to_longitude": 105.828825727289,
        "from_longitude": 105.82848240452735,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4794,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82914491016302,
          21.027601600630952,
          105.82943995315837,
          21.027731787486992
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82914491016302,
            21.027601600630952
          ],
          [
            105.82943995315837,
            21.027731787486992
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "An Trạch",
        "diemDau": "4",
        "diemCuoi": "5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.027601600630952,
        "to_latitude": 21.027731787486992,
        "to_longitude": 105.82943995315837,
        "from_longitude": 105.82914491016302,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4795,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82880963403358,
          21.02734623377384,
          105.82914491016302,
          21.027601600630952
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.828825727289,
            21.02734623377384
          ],
          [
            105.82880963403358,
            21.027440118698372
          ],
          [
            105.82914491016302,
            21.027601600630952
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "An Trạch",
        "diemDau": "3",
        "diemCuoi": "4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.02734623377384,
        "to_latitude": 21.027601600630952,
        "to_longitude": 105.82914491016302,
        "from_longitude": 105.828825727289,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4796,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82943995315837,
          21.027731787486992,
          105.82976718265562,
          21.027770593162636
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82943995315837,
            21.027731787486992
          ],
          [
            105.82956601697614,
            21.027770593162636
          ],
          [
            105.82976718265562,
            21.027731787486992
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "An Trạch",
        "diemDau": "5",
        "diemCuoi": "6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.027731787486992,
        "to_latitude": 21.027731787486992,
        "to_longitude": 105.82976718265562,
        "from_longitude": 105.82943995315837,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4797,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82976718265562,
          21.027669197667436,
          105.83013464528693,
          21.027731787486992
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82976718265562,
            21.027731787486992
          ],
          [
            105.83013464528693,
            21.027669197667436
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "An Trạch",
        "diemDau": "6",
        "diemCuoi": "7",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.027731787486992,
        "to_latitude": 21.027669197667436,
        "to_longitude": 105.83013464528693,
        "from_longitude": 105.82976718265562,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4798,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83013464528693,
          21.027624132979852,
          105.83041359502685,
          21.027669197667436
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83013464528693,
            21.027669197667436
          ],
          [
            105.83041359502685,
            21.027624132979852
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "An Trạch",
        "diemDau": "7",
        "diemCuoi": "8",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.027669197667436,
        "to_latitude": 21.027624132979852,
        "to_longitude": 105.83041359502685,
        "from_longitude": 105.83013464528693,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4799,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83041359502685,
          21.02756905389854,
          105.83077569324271,
          21.027624132979852
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83041359502685,
            21.027624132979852
          ],
          [
            105.83077569324271,
            21.02756905389854
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "An Trạch",
        "diemDau": "8",
        "diemCuoi": "9",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.027624132979852,
        "to_latitude": 21.02756905389854,
        "to_longitude": 105.83077569324271,
        "from_longitude": 105.83041359502685,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4800,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83077569324271,
          21.027523989181493,
          105.83108951170124,
          21.02756905389854
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83077569324271,
            21.02756905389854
          ],
          [
            105.83108951170124,
            21.027523989181493
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "An Trạch",
        "diemDau": "9",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.02756905389854,
        "to_latitude": 21.027523989181493,
        "to_longitude": 105.83108951170124,
        "from_longitude": 105.83077569324271,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4801,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83108951170124,
          21.027510219402988,
          105.83145160991708,
          21.027634147355403
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83108951170124,
            21.027523989181493
          ],
          [
            105.83134700376125,
            21.027510219402988
          ],
          [
            105.83145160991708,
            21.027634147355403
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "An Trạch",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.027523989181493,
        "to_latitude": 21.027634147355403,
        "to_longitude": 105.83145160991708,
        "from_longitude": 105.83108951170124,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4802,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83145160991708,
          21.027634147355403,
          105.83151866513758,
          21.02792706755905
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83145160991708,
            21.027634147355403
          ],
          [
            105.83151866513758,
            21.02792706755905
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "An Trạch",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.027634147355403,
        "to_latitude": 21.02792706755905,
        "to_longitude": 105.83151866513758,
        "from_longitude": 105.83145160991708,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4803,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83151866513758,
          21.02792706755905,
          105.83160717803798,
          21.028240015858223
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83151866513758,
            21.02792706755905
          ],
          [
            105.83160717803798,
            21.028240015858223
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "An Trạch",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.02792706755905,
        "to_latitude": 21.028240015858223,
        "to_longitude": 105.83160717803798,
        "from_longitude": 105.83151866513758,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4804,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83160717803798,
          21.028240015858223,
          105.83174128848796,
          21.028585510016985
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83160717803798,
            21.028240015858223
          ],
          [
            105.83174128848796,
            21.028585510016985
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "An Trạch",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.028240015858223,
        "to_latitude": 21.028585510016985,
        "to_longitude": 105.83174128848796,
        "from_longitude": 105.83160717803798,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4805,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8374405033565,
          21.015672361048225,
          105.8376188702522,
          21.015772512807512
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8376188702522,
            21.015672361048225
          ],
          [
            105.8374405033565,
            21.015772512807512
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "diemDau": "1-1",
        "diemCuoi": "1-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.015672361048225,
        "to_latitude": 21.015772512807512,
        "to_longitude": 105.8374405033565,
        "from_longitude": 105.8376188702522,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4806,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83723129105383,
          21.015772512807512,
          105.8374405033565,
          21.015918984634077
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8374405033565,
            21.015772512807512
          ],
          [
            105.83723129105383,
            21.015918984634077
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "diemDau": "1-2",
        "diemCuoi": "1-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.015772512807512,
        "to_latitude": 21.015918984634077,
        "to_longitude": 105.83723129105383,
        "from_longitude": 105.8374405033565,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4807,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8370770640368,
          21.015744971080142,
          105.83723129105383,
          21.015918984634077
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83723129105383,
            21.015918984634077
          ],
          [
            105.83716021251274,
            21.01582509245344
          ],
          [
            105.8370770640368,
            21.015744971080142
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "diemDau": "1-3",
        "diemCuoi": "1-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.015918984634077,
        "to_latitude": 21.015744971080142,
        "to_longitude": 105.8370770640368,
        "from_longitude": 105.83723129105383,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4808,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83696307015386,
          21.01582509245344,
          105.83723129105383,
          21.01594277064321
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83723129105383,
            21.015918984634077
          ],
          [
            105.83716021251274,
            21.01582509245344
          ],
          [
            105.83696307015386,
            21.01594277064321
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "diemDau": "1-3",
        "diemCuoi": "1-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.015918984634077,
        "to_latitude": 21.01594277064321,
        "to_longitude": 105.83696307015386,
        "from_longitude": 105.83723129105383,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4809,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83683164191163,
          21.01594277064321,
          105.83700732659958,
          21.0161330585831
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83696307015386,
            21.01594277064321
          ],
          [
            105.83700732659958,
            21.016017884333007
          ],
          [
            105.83683164191163,
            21.0161330585831
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "diemDau": "1-5",
        "diemCuoi": "1-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.01594277064321,
        "to_latitude": 21.0161330585831,
        "to_longitude": 105.83683164191163,
        "from_longitude": 105.83696307015386,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4810,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83661304187284,
          21.0161330585831,
          105.83683164191163,
          21.016279530055566
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83683164191163,
            21.0161330585831
          ],
          [
            105.83661304187284,
            21.016279530055566
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "diemDau": "1-6",
        "diemCuoi": "1-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.0161330585831,
        "to_latitude": 21.016279530055566,
        "to_longitude": 105.83661304187284,
        "from_longitude": 105.83683164191163,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4811,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8365124590376,
          21.016279530055566,
          105.83661304187284,
          21.016345880332832
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83661304187284,
            21.016279530055566
          ],
          [
            105.8365124590376,
            21.016345880332832
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "diemDau": "1-7",
        "diemCuoi": "1-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016279530055566,
        "to_latitude": 21.016345880332832,
        "to_longitude": 105.8365124590376,
        "from_longitude": 105.83661304187284,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4812,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83639980625854,
          21.016322094388013,
          105.8365124590376,
          21.016345880332832
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8365124590376,
            21.016345880332832
          ],
          [
            105.83639980625854,
            21.016322094388013
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "diemDau": "1-8",
        "diemCuoi": "1-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016345880332832,
        "to_latitude": 21.016322094388013,
        "to_longitude": 105.83639980625854,
        "from_longitude": 105.8365124590376,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4813,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83661304187284,
          21.016279530055566,
          105.83668814372557,
          21.01637842951539
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83661304187284,
            21.016279530055566
          ],
          [
            105.83668814372557,
            21.01637842951539
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "diemDau": "1-7",
        "diemCuoi": "1-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016279530055566,
        "to_latitude": 21.01637842951539,
        "to_longitude": 105.83668814372557,
        "from_longitude": 105.83661304187284,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4814,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83658487868259,
          21.01637842951539,
          105.83668814372557,
          21.016419741928225
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83668814372557,
            21.01637842951539
          ],
          [
            105.83658487868259,
            21.016419741928225
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "diemDau": "1-10",
        "diemCuoi": "1-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.01637842951539,
        "to_latitude": 21.016419741928225,
        "to_longitude": 105.83658487868259,
        "from_longitude": 105.83668814372557,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4815,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83658487868259,
          21.016419741928225,
          105.8367391056996,
          21.016676379390585
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83658487868259,
            21.016419741928225
          ],
          [
            105.8367391056996,
            21.016676379390585
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "diemDau": "1-11",
        "diemCuoi": "1-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016419741928225,
        "to_latitude": 21.016676379390585,
        "to_longitude": 105.8367391056996,
        "from_longitude": 105.83658487868259,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4816,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83651359597695,
          21.016676379390585,
          105.8367391056996,
          21.01681411908115
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8367391056996,
            21.016676379390585
          ],
          [
            105.83651359597695,
            21.01681411908115
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "diemDau": "1-12",
        "diemCuoi": "1-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016676379390585,
        "to_latitude": 21.01681411908115,
        "to_longitude": 105.83651359597695,
        "from_longitude": 105.8367391056996,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4817,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82332552244867,
          21.0034834633186,
          105.82345460373443,
          21.003702876108456
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82332552244867,
            21.0034834633186
          ],
          [
            105.82345460373443,
            21.003702876108456
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "diemDau": "11",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.0034834633186,
        "to_latitude": 21.003702876108456,
        "to_longitude": 105.82345460373443,
        "from_longitude": 105.82332552244867,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4818,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82345460373443,
          21.003702876108456,
          105.82350690681234,
          21.003789263935555
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82345460373443,
            21.003702876108456
          ],
          [
            105.82350690681234,
            21.003789263935555
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.003702876108456,
        "to_latitude": 21.003789263935555,
        "to_longitude": 105.82350690681234,
        "from_longitude": 105.82345460373443,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4819,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82350690681234,
          21.003789263935555,
          105.82368158569261,
          21.004082543973855
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82350690681234,
            21.003789263935555
          ],
          [
            105.82368158569261,
            21.004082543973855
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "diemDau": "10-1",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.003789263935555,
        "to_latitude": 21.004082543973855,
        "to_longitude": 105.82368158569261,
        "from_longitude": 105.82350690681234,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4820,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82368158569261,
          21.004082543973855,
          105.82383178939803,
          21.0043492186003
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82368158569261,
            21.004082543973855
          ],
          [
            105.82383178939803,
            21.0043492186003
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "diemDau": "09",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.004082543973855,
        "to_latitude": 21.0043492186003,
        "to_longitude": 105.82383178939803,
        "from_longitude": 105.82368158569261,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4821,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82383178939803,
          21.0043492186003,
          105.82393639555386,
          21.004532009383933
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82383178939803,
            21.0043492186003
          ],
          [
            105.82393639555386,
            21.004532009383933
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "diemDau": "08",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.0043492186003,
        "to_latitude": 21.004532009383933,
        "to_longitude": 105.82393639555386,
        "from_longitude": 105.82383178939803,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4822,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82393639555386,
          21.004532009383933,
          105.82402356734141,
          21.00467974423633
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82393639555386,
            21.004532009383933
          ],
          [
            105.82402356734141,
            21.00467974423633
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "diemDau": "",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.004532009383933,
        "to_latitude": 21.00467974423633,
        "to_longitude": 105.82402356734141,
        "from_longitude": 105.82393639555386,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4823,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82402356734141,
          21.004518237483246,
          105.8242461906918,
          21.00467974423633
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82402356734141,
            21.00467974423633
          ],
          [
            105.8242461906918,
            21.004518237483246
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "diemDau": "07",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.00467974423633,
        "to_latitude": 21.004518237483246,
        "to_longitude": 105.8242461906918,
        "from_longitude": 105.82402356734141,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4824,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8242461906918,
          21.004342958638478,
          105.82449563612857,
          21.004518237483246
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8242461906918,
            21.004518237483246
          ],
          [
            105.82449563612857,
            21.004342958638478
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "diemDau": "06",
        "diemCuoi": "01",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.004518237483246,
        "to_latitude": 21.004342958638478,
        "to_longitude": 105.82449563612857,
        "from_longitude": 105.8242461906918,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4825,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82345460373443,
          21.003532917300927,
          105.82378753295231,
          21.003702876108456
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82345460373443,
            21.003702876108456
          ],
          [
            105.82378753295231,
            21.003532917300927
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "diemDau": "10",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.003702876108456,
        "to_latitude": 21.003532917300927,
        "to_longitude": 105.82378753295231,
        "from_longitude": 105.82345460373443,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4826,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82378753295231,
          21.003532917300927,
          105.8240155207182,
          21.003763284991322
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82378753295231,
            21.003532917300927
          ],
          [
            105.8240155207182,
            21.003763284991322
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "diemDau": "04",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.003532917300927,
        "to_latitude": 21.003763284991322,
        "to_longitude": 105.8240155207182,
        "from_longitude": 105.82378753295231,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4827,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8242488729175,
          21.00409756790956,
          105.82449563612857,
          21.004342958638478
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82449563612857,
            21.004342958638478
          ],
          [
            105.82437493674426,
            21.00420774338845
          ],
          [
            105.8242488729175,
            21.00409756790956
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Khương Thượng",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.004342958638478,
        "to_latitude": 21.00409756790956,
        "to_longitude": 105.8242488729175,
        "from_longitude": 105.82449563612857,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4828,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82367756240794,
          21.00333134528231,
          105.82378753295231,
          21.003532917300927
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82378753295231,
            21.003532917300927
          ],
          [
            105.82368963235177,
            21.00340396132095
          ],
          [
            105.82367756240794,
            21.00333134528231
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "diemDau": "04",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 1",
        "from_latitude": 21.003532917300927,
        "to_latitude": 21.00333134528231,
        "to_longitude": 105.82367756240794,
        "from_longitude": 105.82378753295231,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4829,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83761618804446,
          21.015684880021706,
          105.83777678531163,
          21.01586390122917
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83761618804446,
            21.015684880021706
          ],
          [
            105.83777678531163,
            21.01586390122917
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CN10",
        "from_latitude": 21.015684880021706,
        "to_latitude": 21.01586390122917,
        "to_longitude": 105.83777678531163,
        "from_longitude": 105.83761618804446,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4830,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83777678531163,
          21.01586390122917,
          105.83791357796935,
          21.016081730939952
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83777678531163,
            21.01586390122917
          ],
          [
            105.83791357796935,
            21.016081730939952
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CN10",
        "from_latitude": 21.01586390122917,
        "to_latitude": 21.016081730939952,
        "to_longitude": 105.83791357796935,
        "from_longitude": 105.83777678531163,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4831,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83791357796935,
          21.016081730939952,
          105.83817172302938,
          21.016424844650743
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83791357796935,
            21.016081730939952
          ],
          [
            105.83812681359264,
            21.016342124658475
          ],
          [
            105.83817172302938,
            21.016424844650743
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CN10",
        "from_latitude": 21.016081730939952,
        "to_latitude": 21.016424844650743,
        "to_longitude": 105.83817172302938,
        "from_longitude": 105.83791357796935,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4832,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83817172302938,
          21.016424844650743,
          105.83829847495998,
          21.016641326494895
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83817172302938,
            21.016424844650743
          ],
          [
            105.83829847495998,
            21.016641326494895
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CN10",
        "from_latitude": 21.016424844650743,
        "to_latitude": 21.016641326494895,
        "to_longitude": 105.83829847495998,
        "from_longitude": 105.83817172302938,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4833,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83829847495998,
          21.016641326494895,
          105.8384236918281,
          21.016864089191944
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83829847495998,
            21.016641326494895
          ],
          [
            105.8384236918281,
            21.016864089191944
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CN10",
        "from_latitude": 21.016641326494895,
        "to_latitude": 21.016864089191944,
        "to_longitude": 105.8384236918281,
        "from_longitude": 105.83829847495998,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4834,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8384236918281,
          21.016864089191944,
          105.83856133144448,
          21.017107028578177
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8384236918281,
            21.016864089191944
          ],
          [
            105.83856133144448,
            21.017107028578177
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CN10",
        "from_latitude": 21.016864089191944,
        "to_latitude": 21.017107028578177,
        "to_longitude": 105.83856133144448,
        "from_longitude": 105.8384236918281,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4835,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83856133144448,
          21.017107028578177,
          105.83869538470564,
          21.01740007982971
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83856133144448,
            21.017107028578177
          ],
          [
            105.83869538470564,
            21.01740007982971
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CN10",
        "from_latitude": 21.017107028578177,
        "to_latitude": 21.01740007982971,
        "to_longitude": 105.83869538470564,
        "from_longitude": 105.83856133144448,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4836,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80376584848872,
          21.01813865196275,
          105.80383860338495,
          21.01817526935082
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80376584848872,
            21.01817526935082
          ],
          [
            105.80383860338495,
            21.01813865196275
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "",
        "diemCuoi": "9-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01817526935082,
        "to_latitude": 21.01813865196275,
        "to_longitude": 105.80383860338495,
        "from_longitude": 105.80376584848872,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4837,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82953383050122,
          21.0122713033967,
          105.82964380107252,
          21.01248162680885
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82953383050122,
            21.0122713033967
          ],
          [
            105.82964380107252,
            21.01248162680885
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Hữu Tước",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.0122713033967,
        "to_latitude": 21.01248162680885,
        "to_longitude": 105.82964380107252,
        "from_longitude": 105.82953383050122,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4838,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82964380107252,
          21.01248162680885,
          105.82976181826707,
          21.012704469148083
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82964380107252,
            21.01248162680885
          ],
          [
            105.82976181826707,
            21.012704469148083
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Hữu Tước",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.01248162680885,
        "to_latitude": 21.012704469148083,
        "to_longitude": 105.82976181826707,
        "from_longitude": 105.82964380107252,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4839,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82976181826707,
          21.012704469148083,
          105.82987983546163,
          21.012922303473243
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82976181826707,
            21.012704469148083
          ],
          [
            105.82987983546163,
            21.012922303473243
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.012704469148083,
        "to_latitude": 21.012922303473243,
        "to_longitude": 105.82987983546163,
        "from_longitude": 105.82976181826707,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4840,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82987983546163,
          21.012922303473243,
          105.8300139459116,
          21.013190214217225
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82987983546163,
            21.012922303473243
          ],
          [
            105.8300139459116,
            21.013190214217225
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.012922303473243,
        "to_latitude": 21.013190214217225,
        "to_longitude": 105.8300139459116,
        "from_longitude": 105.82987983546163,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4841,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8300139459116,
          21.013190214217225,
          105.83010514101973,
          21.013383009501915
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8300139459116,
            21.013190214217225
          ],
          [
            105.83010514101973,
            21.013383009501915
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.013190214217225,
        "to_latitude": 21.013383009501915,
        "to_longitude": 105.83010514101973,
        "from_longitude": 105.8300139459116,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4842,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83010514101973,
          21.013383009501915,
          105.83023388705425,
          21.01361085815358
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83010514101973,
            21.013383009501915
          ],
          [
            105.83023388705425,
            21.01361085815358
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.013383009501915,
        "to_latitude": 21.01361085815358,
        "to_longitude": 105.83023388705425,
        "from_longitude": 105.83010514101973,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4843,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83023388705425,
          21.01361085815358,
          105.83036531529648,
          21.0138061567212
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83023388705425,
            21.01361085815358
          ],
          [
            105.83036531529648,
            21.0138061567212
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.01361085815358,
        "to_latitude": 21.0138061567212,
        "to_longitude": 105.83036531529648,
        "from_longitude": 105.83023388705425,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4844,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83036531529648,
          21.0138061567212,
          105.83058257422239,
          21.013983928271934
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83036531529648,
            21.0138061567212
          ],
          [
            105.83048299723079,
            21.013967340442452
          ],
          [
            105.83058257422239,
            21.013983928271934
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.0138061567212,
        "to_latitude": 21.013983928271934,
        "to_longitude": 105.83058257422239,
        "from_longitude": 105.83036531529648,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4845,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83058257422239,
          21.013981424448247,
          105.83086957058555,
          21.013983928271934
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83058257422239,
            21.013983928271934
          ],
          [
            105.83086957058555,
            21.013981424448247
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.013983928271934,
        "to_latitude": 21.013981424448247,
        "to_longitude": 105.83086957058555,
        "from_longitude": 105.83058257422239,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4846,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83086957058555,
          21.01380866054697,
          105.8311297448623,
          21.013981424448247
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83086957058555,
            21.013981424448247
          ],
          [
            105.83103016787067,
            21.013917263965524
          ],
          [
            105.8311297448623,
            21.01380866054697
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.013981424448247,
        "to_latitude": 21.01380866054697,
        "to_longitude": 105.8311297448623,
        "from_longitude": 105.83086957058555,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4847,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8311297448623,
          21.013575804538245,
          105.83126653752002,
          21.01380866054697
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8311297448623,
            21.01380866054697
          ],
          [
            105.83126653752002,
            21.013575804538245
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.01380866054697,
        "to_latitude": 21.013575804538245,
        "to_longitude": 105.83126653752002,
        "from_longitude": 105.8311297448623,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4848,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83126653752002,
          21.013360475001612,
          105.83145697435953,
          21.013575804538245
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83126653752002,
            21.013575804538245
          ],
          [
            105.83145697435953,
            21.013360475001612
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.013575804538245,
        "to_latitude": 21.013360475001612,
        "to_longitude": 105.83145697435953,
        "from_longitude": 105.83126653752002,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4849,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83145697435953,
          21.013197725727146,
          105.83160986027269,
          21.013360475001612
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83145697435953,
            21.013360475001612
          ],
          [
            105.83160986027269,
            21.013197725727146
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.013360475001612,
        "to_latitude": 21.013197725727146,
        "to_longitude": 105.83160986027269,
        "from_longitude": 105.83145697435953,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4850,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83160986027269,
          21.013027464756988,
          105.83179493269671,
          21.013197725727146
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83160986027269,
            21.013197725727146
          ],
          [
            105.83179493269671,
            21.013027464756988
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.013197725727146,
        "to_latitude": 21.013027464756988,
        "to_longitude": 105.83179493269671,
        "from_longitude": 105.83160986027269,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4851,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83179493269671,
          21.01284468438252,
          105.83197195849753,
          21.013027464756988
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83179493269671,
            21.013027464756988
          ],
          [
            105.83197195849753,
            21.01284468438252
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.013027464756988,
        "to_latitude": 21.01284468438252,
        "to_longitude": 105.83197195849753,
        "from_longitude": 105.83179493269671,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4852,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83197195849753,
          21.0127019653038,
          105.83210875115523,
          21.01284468438252
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83197195849753,
            21.01284468438252
          ],
          [
            105.83210875115523,
            21.0127019653038
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.01284468438252,
        "to_latitude": 21.0127019653038,
        "to_longitude": 105.83210875115523,
        "from_longitude": 105.83197195849753,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4853,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82936485132363,
          21.0122713033967,
          105.82953383050122,
          21.01229383806407
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82953383050122,
            21.0122713033967
          ],
          [
            105.82936485132363,
            21.01229383806407
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.0122713033967,
        "to_latitude": 21.01229383806407,
        "to_longitude": 105.82936485132363,
        "from_longitude": 105.82953383050122,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4854,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82936485132363,
          21.01214611074935,
          105.82966794096018,
          21.01229383806407
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82936485132363,
            21.01229383806407
          ],
          [
            105.82966794096018,
            21.01214611074935
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "tủ",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.01229383806407,
        "to_latitude": 21.01214611074935,
        "to_longitude": 105.82966794096018,
        "from_longitude": 105.82936485132363,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4855,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82966794096018,
          21.0120259257084,
          105.82987715326286,
          21.01214611074935
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82966794096018,
            21.01214611074935
          ],
          [
            105.82987715326286,
            21.0120259257084
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.01214611074935,
        "to_latitude": 21.0120259257084,
        "to_longitude": 105.82987715326286,
        "from_longitude": 105.82966794096018,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4856,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82987715326286,
          21.01188570970433,
          105.83014000974735,
          21.0120259257084
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82987715326286,
            21.0120259257084
          ],
          [
            105.83014000974735,
            21.01188570970433
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.0120259257084,
        "to_latitude": 21.01188570970433,
        "to_longitude": 105.83014000974735,
        "from_longitude": 105.82987715326286,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4857,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83014000974735,
          21.011745493569297,
          105.83040823064731,
          21.01188570970433
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83014000974735,
            21.01188570970433
          ],
          [
            105.83040823064731,
            21.011745493569297
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.01188570970433,
        "to_latitude": 21.011745493569297,
        "to_longitude": 105.83040823064731,
        "from_longitude": 105.83014000974735,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4858,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83040823064731,
          21.011547688439954,
          105.83077837549536,
          21.011745493569297
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83040823064731,
            21.011745493569297
          ],
          [
            105.83077837549536,
            21.011547688439954
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.011745493569297,
        "to_latitude": 21.011547688439954,
        "to_longitude": 105.83077837549536,
        "from_longitude": 105.83040823064731,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4859,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83077837549536,
          21.011478519179047,
          105.83127190195346,
          21.011730470404547
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83077837549536,
            21.011547688439954
          ],
          [
            105.83097384148114,
            21.011478519179047
          ],
          [
            105.83127190195346,
            21.011730470404547
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "29",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.011547688439954,
        "to_latitude": 21.011730470404547,
        "to_longitude": 105.83127190195346,
        "from_longitude": 105.83077837549536,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4860,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83127190195346,
          21.011730470404547,
          105.83153744064568,
          21.011955817722846
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83127190195346,
            21.011730470404547
          ],
          [
            105.83153744064568,
            21.011955817722846
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "23",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.011730470404547,
        "to_latitude": 21.011955817722846,
        "to_longitude": 105.83153744064568,
        "from_longitude": 105.83127190195346,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4861,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83153744064568,
          21.011955817722846,
          105.83177079282703,
          21.012143606894835
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83153744064568,
            21.011955817722846
          ],
          [
            105.83177079282703,
            21.012143606894835
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "22",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.011955817722846,
        "to_latitude": 21.012143606894835,
        "to_longitude": 105.83177079282703,
        "from_longitude": 105.83153744064568,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4862,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83177079282703,
          21.012143606894835,
          105.83200682721612,
          21.01235518241255
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83177079282703,
            21.012143606894835
          ],
          [
            105.83200682721612,
            21.01235518241255
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "21",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.012143606894835,
        "to_latitude": 21.01235518241255,
        "to_longitude": 105.83200682721612,
        "from_longitude": 105.83177079282703,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4863,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83200682721612,
          21.01235518241255,
          105.83212886773127,
          21.012507917214613
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83200682721612,
            21.01235518241255
          ],
          [
            105.83212886773127,
            21.012507917214613
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Dạo Xã Đàn",
        "diemDau": "20",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.01235518241255,
        "to_latitude": 21.012507917214613,
        "to_longitude": 105.83212886773127,
        "from_longitude": 105.83200682721612,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4864,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83777678531163,
          21.0156335522243,
          105.83816168230226,
          21.01586390122917
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83777678531163,
            21.01586390122917
          ],
          [
            105.83816168230226,
            21.0156335522243
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 153 Phố Chợ Khâm Thiên",
        "diemDau": "01",
        "diemCuoi": "1-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.01586390122917,
        "to_latitude": 21.0156335522243,
        "to_longitude": 105.83816168230226,
        "from_longitude": 105.83777678531163,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4865,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80354221929473,
          21.017957129729893,
          105.80383860338495,
          21.01813865196275
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80383860338495,
            21.01813865196275
          ],
          [
            105.80354221929473,
            21.017957129729893
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "9-2",
        "diemCuoi": "9-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01813865196275,
        "to_latitude": 21.017957129729893,
        "to_longitude": 105.80354221929473,
        "from_longitude": 105.80383860338495,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4866,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80327399838578,
          21.01771050951458,
          105.80354221929473,
          21.017957129729893
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80354221929473,
            21.017957129729893
          ],
          [
            105.80327399838578,
            21.01771050951458
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "9-1",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.017957129729893,
        "to_latitude": 21.01771050951458,
        "to_longitude": 105.80327399838578,
        "from_longitude": 105.80354221929473,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4867,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80327399838578,
          21.01764071717159,
          105.80330316741069,
          21.01771050951458
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80327399838578,
            21.01771050951458
          ],
          [
            105.80330316741069,
            21.01764071717159
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01771050951458,
        "to_latitude": 21.01764071717159,
        "to_longitude": 105.80330316741069,
        "from_longitude": 105.80327399838578,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4868,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80330316741069,
          21.017415378443374,
          105.80346141773933,
          21.01764071717159
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80330316741069,
            21.01764071717159
          ],
          [
            105.80346141773933,
            21.017415378443374
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "09",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01764071717159,
        "to_latitude": 21.017415378443374,
        "to_longitude": 105.80346141773933,
        "from_longitude": 105.80330316741069,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4869,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82927197985137,
          21.01229383806407,
          105.82936485132363,
          21.012311052040484
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82936485132363,
            21.01229383806407
          ],
          [
            105.82927197985137,
            21.012311052040484
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Hữu Tước",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.01229383806407,
        "to_latitude": 21.012311052040484,
        "to_longitude": 105.82927197985137,
        "from_longitude": 105.82936485132363,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4870,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82927197985137,
          21.012311052040484,
          105.82941681915028,
          21.012561436955725
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82927197985137,
            21.012311052040484
          ],
          [
            105.82941681915028,
            21.012561436955725
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Hữu Tước",
        "diemDau": "47",
        "diemCuoi": "48",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.012311052040484,
        "to_latitude": 21.012561436955725,
        "to_longitude": 105.82941681915028,
        "from_longitude": 105.82927197985137,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4871,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82941681915028,
          21.012561436955725,
          105.82956970506345,
          21.012831852190903
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82941681915028,
            21.012561436955725
          ],
          [
            105.82956970506345,
            21.012831852190903
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Hữu Tước",
        "diemDau": "48",
        "diemCuoi": "49",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.012561436955725,
        "to_latitude": 21.012831852190903,
        "to_longitude": 105.82956970506345,
        "from_longitude": 105.82941681915028,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4872,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82956970506345,
          21.012831852190903,
          105.82973063759984,
          21.01304217481267
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82956970506345,
            21.012831852190903
          ],
          [
            105.82973063759984,
            21.01304217481267
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Hữu Tước",
        "diemDau": "49",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.012831852190903,
        "to_latitude": 21.01304217481267,
        "to_longitude": 105.82973063759984,
        "from_longitude": 105.82956970506345,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4873,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82973063759984,
          21.01304217481267,
          105.82986206584206,
          21.013237474124043
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82973063759984,
            21.01304217481267
          ],
          [
            105.82986206584206,
            21.013237474124043
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Hữu Tước",
        "diemDau": "32",
        "diemCuoi": "50",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.01304217481267,
        "to_latitude": 21.013237474124043,
        "to_longitude": 105.82986206584206,
        "from_longitude": 105.82973063759984,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4874,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82986206584206,
          21.013237474124043,
          105.82993180327931,
          21.013340131352013
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82986206584206,
            21.013237474124043
          ],
          [
            105.82993180327931,
            21.013340131352013
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Hữu Tước",
        "diemDau": "50",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.013237474124043,
        "to_latitude": 21.013340131352013,
        "to_longitude": 105.82993180327931,
        "from_longitude": 105.82986206584206,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4875,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82993180327931,
          21.013340131352013,
          105.83003909164287,
          21.01357048389979
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82993180327931,
            21.013340131352013
          ],
          [
            105.83003909164287,
            21.01357048389979
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Hữu Tước",
        "diemDau": "33",
        "diemCuoi": "34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.013340131352013,
        "to_latitude": 21.01357048389979,
        "to_longitude": 105.83003909164287,
        "from_longitude": 105.82993180327931,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4876,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83003909164287,
          21.01357048389979,
          105.83023757511458,
          21.013785813132387
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83003909164287,
            21.01357048389979
          ],
          [
            105.83023757511458,
            21.013785813132387
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Hữu Tước",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.01357048389979,
        "to_latitude": 21.013785813132387,
        "to_longitude": 105.83023757511458,
        "from_longitude": 105.83003909164287,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4877,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83023757511458,
          21.013785813132387,
          105.83037704998007,
          21.01405622614783
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83023757511458,
            21.013785813132387
          ],
          [
            105.83037704998007,
            21.01405622614783
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Hữu Tước",
        "diemDau": "35",
        "diemCuoi": "36",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.013785813132387,
        "to_latitude": 21.01405622614783,
        "to_longitude": 105.83037704998007,
        "from_longitude": 105.83023757511458,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4878,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83768822560394,
          21.016081730939952,
          105.83791357796935,
          21.016139027939527
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83791357796935,
            21.016081730939952
          ],
          [
            105.83768822560394,
            21.016139027939527
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 154 Phố Chợ Khâm Thiên",
        "diemDau": "02",
        "diemCuoi": "2-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016081730939952,
        "to_latitude": 21.016139027939527,
        "to_longitude": 105.83768822560394,
        "from_longitude": 105.83791357796935,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4879,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83747503679692,
          21.016139027939527,
          105.83768822560394,
          21.016219439212517
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83768822560394,
            21.016139027939527
          ],
          [
            105.83747503679692,
            21.016219439212517
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 154 Phố Chợ Khâm Thiên",
        "diemDau": "2-5",
        "diemCuoi": "2-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016139027939527,
        "to_latitude": 21.016219439212517,
        "to_longitude": 105.83747503679692,
        "from_longitude": 105.83768822560394,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4880,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83724704903105,
          21.01615684455894,
          105.83747503679692,
          21.01623446192596
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83747503679692,
            21.016219439212517
          ],
          [
            105.83742407482286,
            21.01615684455894
          ],
          [
            105.83724704903105,
            21.01623446192596
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 154 Phố Chợ Khâm Thiên",
        "diemDau": "2-6",
        "diemCuoi": "2-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016219439212517,
        "to_latitude": 21.01623446192596,
        "to_longitude": 105.83724704903105,
        "from_longitude": 105.83747503679692,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4881,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83711887428694,
          21.01623446192596,
          105.83724704903105,
          21.01632265904732
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83724704903105,
            21.01623446192596
          ],
          [
            105.83711887428694,
            21.01632265904732
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 154 Phố Chợ Khâm Thiên",
        "diemDau": "2-7",
        "diemCuoi": "2-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.01623446192596,
        "to_latitude": 21.01632265904732,
        "to_longitude": 105.83711887428694,
        "from_longitude": 105.83724704903105,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4882,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83711887428694,
          21.016219439212517,
          105.83716121834739,
          21.01632265904732
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83711887428694,
            21.01632265904732
          ],
          [
            105.83716121834739,
            21.016262003562087
          ],
          [
            105.83713976066747,
            21.016219439212517
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 154 Phố Chợ Khâm Thiên",
        "diemDau": "2-8",
        "diemCuoi": "2-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.01632265904732,
        "to_latitude": 21.016219439212517,
        "to_longitude": 105.83713976066747,
        "from_longitude": 105.83711887428694,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4883,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83711887428694,
          21.01632265904732,
          105.83718804043377,
          21.016422245710967
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83711887428694,
            21.01632265904732
          ],
          [
            105.83718804043377,
            21.016422245710967
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 154 Phố Chợ Khâm Thiên",
        "diemDau": "2-8",
        "diemCuoi": "2-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.01632265904732,
        "to_latitude": 21.016422245710967,
        "to_longitude": 105.83718804043377,
        "from_longitude": 105.83711887428694,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4884,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83706734103149,
          21.016422245710967,
          105.83718804043377,
          21.01648484025314
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83718804043377,
            21.016422245710967
          ],
          [
            105.83706734103149,
            21.01648484025314
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 154 Phố Chợ Khâm Thiên",
        "diemDau": "2-9",
        "diemCuoi": "2-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016422245710967,
        "to_latitude": 21.01648484025314,
        "to_longitude": 105.83706734103149,
        "from_longitude": 105.83718804043377,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4885,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83700565021748,
          21.016459802439428,
          105.83706734103149,
          21.01648484025314
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83706734103149,
            21.01648484025314
          ],
          [
            105.83700565021748,
            21.016459802439428
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 154 Phố Chợ Khâm Thiên",
        "diemDau": "2-10",
        "diemCuoi": "2-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.01648484025314,
        "to_latitude": 21.016459802439428,
        "to_longitude": 105.83700565021748,
        "from_longitude": 105.83706734103149,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4886,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83694932382795,
          21.016459802439428,
          105.83700565021748,
          21.01650487050177
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83700565021748,
            21.016459802439428
          ],
          [
            105.83694932382795,
            21.01650487050177
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 154 Phố Chợ Khâm Thiên",
        "diemDau": "2-11",
        "diemCuoi": "2-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016459802439428,
        "to_latitude": 21.01650487050177,
        "to_longitude": 105.83694932382795,
        "from_longitude": 105.83700565021748,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4887,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8368983618629,
          21.016432260839817,
          105.83694932382795,
          21.01650487050177
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83694932382795,
            21.01650487050177
          ],
          [
            105.8368983618629,
            21.016432260839817
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 154 Phố Chợ Khâm Thiên",
        "diemDau": "2-12",
        "diemCuoi": "2-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.01650487050177,
        "to_latitude": 21.016432260839817,
        "to_longitude": 105.8368983618629,
        "from_longitude": 105.83694932382795,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4888,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82953483634483,
          21.012831852190903,
          105.82967431121929,
          21.013084740069004
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82956970506345,
            21.012831852190903
          ],
          [
            105.82967431121929,
            21.013014632581093
          ],
          [
            105.82953483634483,
            21.013084740069004
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Nhà E15 Trần Hữu Tước",
        "diemDau": "49",
        "diemCuoi": "50-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.012831852190903,
        "to_latitude": 21.013084740069004,
        "to_longitude": 105.82953483634483,
        "from_longitude": 105.82956970506345,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4889,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82961127930142,
          21.01304217481267,
          105.82986206584206,
          21.013331367932846
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82973063759984,
            21.01304217481267
          ],
          [
            105.82986206584206,
            21.013237474124043
          ],
          [
            105.82961127930142,
            21.013331367932846
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 54,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Hữu Tước",
        "diemDau": "32",
        "diemCuoi": "50-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.01304217481267,
        "to_latitude": 21.013331367932846,
        "to_longitude": 105.82961127930142,
        "from_longitude": 105.82973063759984,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4890,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82943693570836,
          21.013331367932846,
          105.82961127930142,
          21.013424009765963
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82961127930142,
            21.013331367932846
          ],
          [
            105.82943693570836,
            21.013424009765963
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Nhà E15 Trần Hữu Tước",
        "diemDau": "50-2",
        "diemCuoi": "50-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.013331367932846,
        "to_latitude": 21.013424009765963,
        "to_longitude": 105.82943693570836,
        "from_longitude": 105.82961127930142,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4891,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82943693570836,
          21.013424009765963,
          105.82951740198553,
          21.013636835382258
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82943693570836,
            21.013424009765963
          ],
          [
            105.82951740198553,
            21.013636835382258
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Nhà E15 Trần Hữu Tước",
        "diemDau": "50-5",
        "diemCuoi": "50-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.013424009765963,
        "to_latitude": 21.013636835382258,
        "to_longitude": 105.82951740198553,
        "from_longitude": 105.82943693570836,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4892,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82951740198553,
          21.013636835382258,
          105.82963810138781,
          21.013834637741198
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82951740198553,
            21.013636835382258
          ],
          [
            105.82963810138781,
            21.013834637741198
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Nhà E15 Trần Hữu Tước",
        "diemDau": "50-6",
        "diemCuoi": "50-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.013636835382258,
        "to_latitude": 21.013834637741198,
        "to_longitude": 105.82963810138781,
        "from_longitude": 105.82951740198553,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4893,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82961127930142,
          21.013331367932846,
          105.82973734311918,
          21.013589262623828
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82961127930142,
            21.013331367932846
          ],
          [
            105.82973734311918,
            21.013589262623828
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Nhà E15 Trần Hữu Tước",
        "diemDau": "50-2",
        "diemCuoi": "50-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.013331367932846,
        "to_latitude": 21.013589262623828,
        "to_longitude": 105.82973734311918,
        "from_longitude": 105.82961127930142,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4894,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82973734311918,
          21.013589262623828,
          105.82983658485054,
          21.013792072698013
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82973734311918,
            21.013589262623828
          ],
          [
            105.82983658485054,
            21.013792072698013
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Nhà E15 Trần Hữu Tước",
        "diemDau": "50-3",
        "diemCuoi": "50-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.013589262623828,
        "to_latitude": 21.013792072698013,
        "to_longitude": 105.82983658485054,
        "from_longitude": 105.82973734311918,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4895,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80346141773933,
          21.017415378443374,
          105.80365051349294,
          21.01750301021064
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80346141773933,
            21.017415378443374
          ],
          [
            105.80365051349294,
            21.01750301021064
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 766 Đường Láng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.017415378443374,
        "to_latitude": 21.01750301021064,
        "to_longitude": 105.80365051349294,
        "from_longitude": 105.80346141773933,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4896,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80358345826346,
          21.01750301021064,
          105.80365051349294,
          21.017591893808785
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80365051349294,
            21.01750301021064
          ],
          [
            105.80358345826346,
            21.017591893808785
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 766 Đường Láng",
        "diemDau": "",
        "diemCuoi": "8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01750301021064,
        "to_latitude": 21.017591893808785,
        "to_longitude": 105.80358345826346,
        "from_longitude": 105.80365051349294,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4897,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80365051349294,
          21.017346524873062,
          105.80376987179136,
          21.01750301021064
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80365051349294,
            21.01750301021064
          ],
          [
            105.80376987179136,
            21.017346524873062
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 766 Đường Láng",
        "diemDau": "",
        "diemCuoi": "8-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01750301021064,
        "to_latitude": 21.017346524873062,
        "to_longitude": 105.80376987179136,
        "from_longitude": 105.80365051349294,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4898,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80346141773933,
          21.01722884779182,
          105.8035861404712,
          21.017415378443374
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80346141773933,
            21.017415378443374
          ],
          [
            105.8035861404712,
            21.01722884779182
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "08",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.017415378443374,
        "to_latitude": 21.01722884779182,
        "to_longitude": 105.8035861404712,
        "from_longitude": 105.80346141773933,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4899,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8035861404712,
          21.017032301819917,
          105.80373634417661,
          21.01722884779182
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8035861404712,
            21.01722884779182
          ],
          [
            105.80373634417661,
            21.017032301819917
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "07",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01722884779182,
        "to_latitude": 21.017032301819917,
        "to_longitude": 105.80373634417661,
        "from_longitude": 105.8035861404712,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4900,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80373634417661,
          21.01694592166135,
          105.80380742271771,
          21.017032301819917
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80373634417661,
            21.017032301819917
          ],
          [
            105.80380742271771,
            21.01694592166135
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "06",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.017032301819917,
        "to_latitude": 21.01694592166135,
        "to_longitude": 105.80380742271771,
        "from_longitude": 105.80373634417661,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4901,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80380742271771,
          21.016640460991045,
          105.80403675160541,
          21.01694592166135
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80380742271771,
            21.01694592166135
          ],
          [
            105.80403675160541,
            21.016640460991045
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "05",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01694592166135,
        "to_latitude": 21.016640460991045,
        "to_longitude": 105.80403675160541,
        "from_longitude": 105.80380742271771,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4902,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83027512604095,
          21.01405622614783,
          105.83037704998007,
          21.0142790661346
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83037704998007,
            21.01405622614783
          ],
          [
            105.83027512604095,
            21.0142790661346
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Hữu Tước",
        "diemDau": "36",
        "diemCuoi": "37",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.01405622614783,
        "to_latitude": 21.0142790661346,
        "to_longitude": 105.83027512604095,
        "from_longitude": 105.83037704998007,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4903,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8301584499413,
          21.0142790661346,
          105.83027512604095,
          21.014550730164547
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83027512604095,
            21.0142790661346
          ],
          [
            105.8301584499413,
            21.014550730164547
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Hữu Tước",
        "diemDau": "37",
        "diemCuoi": "38",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.0142790661346,
        "to_latitude": 21.014550730164547,
        "to_longitude": 105.8301584499413,
        "from_longitude": 105.83027512604095,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4904,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8300243394913,
          21.014550730164547,
          105.8301584499413,
          21.014886240815873
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8301584499413,
            21.014550730164547
          ],
          [
            105.8300243394913,
            21.014886240815873
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Hữu Tước",
        "diemDau": "38",
        "diemCuoi": "39",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.014550730164547,
        "to_latitude": 21.014886240815873,
        "to_longitude": 105.8300243394913,
        "from_longitude": 105.8301584499413,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4905,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82996398979915,
          21.014886240815873,
          105.8300243394913,
          21.015210483599947
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8300243394913,
            21.014886240815873
          ],
          [
            105.82996398979915,
            21.015210483599947
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Hữu Tước",
        "diemDau": "39",
        "diemCuoi": "40",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.014886240815873,
        "to_latitude": 21.015210483599947,
        "to_longitude": 105.82996398979915,
        "from_longitude": 105.8300243394913,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4906,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82993716770376,
          21.015210483599947,
          105.82996398979915,
          21.01550593201045
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82996398979915,
            21.015210483599947
          ],
          [
            105.82993716770376,
            21.01550593201045
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Hữu Tước",
        "diemDau": "40",
        "diemCuoi": "40-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.015210483599947,
        "to_latitude": 21.01550593201045,
        "to_longitude": 105.82993716770376,
        "from_longitude": 105.82996398979915,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4907,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82993716770376,
          21.01550593201045,
          105.82994253211925,
          21.015671182560745
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82993716770376,
            21.01550593201045
          ],
          [
            105.82994253211925,
            21.015671182560745
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Hữu Tước",
        "diemDau": "40-1",
        "diemCuoi": "41",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.01550593201045,
        "to_latitude": 21.015671182560745,
        "to_longitude": 105.82994253211925,
        "from_longitude": 105.82993716770376,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4908,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82994253211925,
          21.015671182560745,
          105.83004445606734,
          21.016021713426387
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82994253211925,
            21.015671182560745
          ],
          [
            105.83004445606734,
            21.016021713426387
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Hữu Tước",
        "diemDau": "41",
        "diemCuoi": "42",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.015671182560745,
        "to_latitude": 21.016021713426387,
        "to_longitude": 105.83004445606734,
        "from_longitude": 105.82994253211925,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4909,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83003372722736,
          21.016021713426387,
          105.83004445606734,
          21.016367235900958
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83004445606734,
            21.016021713426387
          ],
          [
            105.83003372722736,
            21.016367235900958
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Hữu Tước",
        "diemDau": "42",
        "diemCuoi": "43",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.016021713426387,
        "to_latitude": 21.016367235900958,
        "to_longitude": 105.83003372722736,
        "from_longitude": 105.83004445606734,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4910,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82999617630104,
          21.016367235900958,
          105.83003372722736,
          21.01664265179329
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83003372722736,
            21.016367235900958
          ],
          [
            105.82999617630104,
            21.01664265179329
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Hữu Tước",
        "diemDau": "43",
        "diemCuoi": "44",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.016367235900958,
        "to_latitude": 21.01664265179329,
        "to_longitude": 105.82999617630104,
        "from_longitude": 105.83003372722736,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4911,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8297708707429,
          21.01664265179329,
          105.82999617630104,
          21.017033240731873
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82999617630104,
            21.01664265179329
          ],
          [
            105.8297708707429,
            21.017033240731873
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Hữu Tước",
        "diemDau": "44",
        "diemCuoi": "45",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.01664265179329,
        "to_latitude": 21.017033240731873,
        "to_longitude": 105.8297708707429,
        "from_longitude": 105.82999617630104,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4912,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82965285354835,
          21.017033240731873,
          105.8297708707429,
          21.01731867046353
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8297708707429,
            21.017033240731873
          ],
          [
            105.82965285354835,
            21.01731867046353
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trần Hữu Tước",
        "diemDau": "45",
        "diemCuoi": "46",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.017033240731873,
        "to_latitude": 21.01731867046353,
        "to_longitude": 105.82965285354835,
        "from_longitude": 105.8297708707429,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4913,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80403675160541,
          21.016466135306825,
          105.80419533723023,
          21.016640460991045
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80403675160541,
            21.016640460991045
          ],
          [
            105.80419533723023,
            21.016466135306825
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "04",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.016640460991045,
        "to_latitude": 21.016466135306825,
        "to_longitude": 105.80419533723023,
        "from_longitude": 105.80403675160541,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4914,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80419533723023,
          21.016299633744985,
          105.80435090535114,
          21.016466135306825
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80419533723023,
            21.016466135306825
          ],
          [
            105.80435090535114,
            21.016299633744985
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.016466135306825,
        "to_latitude": 21.016299633744985,
        "to_longitude": 105.80435090535114,
        "from_longitude": 105.80419533723023,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4915,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80435090535114,
          21.01608180435246,
          105.80455877654997,
          21.016299633744985
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80435090535114,
            21.016299633744985
          ],
          [
            105.80455877654997,
            21.01608180435246
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "02",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.016299633744985,
        "to_latitude": 21.01608180435246,
        "to_longitude": 105.80455877654997,
        "from_longitude": 105.80435090535114,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4916,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80455877654997,
          21.01608180435246,
          105.80475860112556,
          21.016257069406127
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80455877654997,
            21.01608180435246
          ],
          [
            105.80475860112556,
            21.016257069406127
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 678 Đường Láng",
        "diemDau": "01",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01608180435246,
        "to_latitude": 21.016257069406127,
        "to_longitude": 105.80475860112556,
        "from_longitude": 105.80455877654997,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4917,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80460303299567,
          21.016257069406127,
          105.80475860112556,
          21.016443601271504
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80475860112556,
            21.016257069406127
          ],
          [
            105.80460303299567,
            21.016443601271504
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 678 Đường Láng",
        "diemDau": "1-1",
        "diemCuoi": "1-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.016257069406127,
        "to_latitude": 21.016443601271504,
        "to_longitude": 105.80460303299567,
        "from_longitude": 105.80475860112556,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4918,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80475860112556,
          21.01619823044645,
          105.80497586005147,
          21.016315908341774
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80475860112556,
            21.016257069406127
          ],
          [
            105.80484040849763,
            21.016315908341774
          ],
          [
            105.80497586005147,
            21.01619823044645
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 678 Đường Láng",
        "diemDau": "1-1",
        "diemCuoi": "1-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.016257069406127,
        "to_latitude": 21.01619823044645,
        "to_longitude": 105.80497586005147,
        "from_longitude": 105.80475860112556,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4919,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80475860112556,
          21.016257069406127,
          105.80498524778754,
          21.01646237963464
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80475860112556,
            21.016257069406127
          ],
          [
            105.80498524778754,
            21.01646237963464
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 678 Đường Láng",
        "diemDau": "1-1",
        "diemCuoi": "1-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.016257069406127,
        "to_latitude": 21.01646237963464,
        "to_longitude": 105.80498524778754,
        "from_longitude": 105.80475860112556,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4920,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80403675160541,
          21.016640460991045,
          105.80421679491013,
          21.01676158122981
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80403675160541,
            21.016640460991045
          ],
          [
            105.80421679491013,
            21.01676158122981
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 718 Đường Láng",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.016640460991045,
        "to_latitude": 21.01676158122981,
        "to_longitude": 105.80421679491013,
        "from_longitude": 105.80403675160541,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4921,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80421679491013,
          21.01676158122981,
          105.80442466610897,
          21.016936845483734
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80421679491013,
            21.01676158122981
          ],
          [
            105.80442466610897,
            21.016936845483734
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 718 Đường Láng",
        "diemDau": "47-1",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01676158122981,
        "to_latitude": 21.016936845483734,
        "to_longitude": 105.80442466610897,
        "from_longitude": 105.80421679491013,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4922,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83797929208598,
          21.016342124658475,
          105.83817172302938,
          21.01643351273191
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83817172302938,
            21.016424844650743
          ],
          [
            105.83812681359264,
            21.016342124658475
          ],
          [
            105.83797929208598,
            21.01643351273191
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 138 Phố Chợ Khâm Thiên",
        "diemDau": "03",
        "diemCuoi": "3-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016424844650743,
        "to_latitude": 21.01643351273191,
        "to_longitude": 105.83797929208598,
        "from_longitude": 105.83817172302938,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4923,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83782104174836,
          21.016323346281027,
          105.83797929208598,
          21.01643351273191
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83797929208598,
            21.01643351273191
          ],
          [
            105.83790419023326,
            21.016323346281027
          ],
          [
            105.83782104174836,
            21.016375925733563
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 138 Phố Chợ Khâm Thiên",
        "diemDau": "3-5",
        "diemCuoi": "3-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.01643351273191,
        "to_latitude": 21.016375925733563,
        "to_longitude": 105.83782104174836,
        "from_longitude": 105.83797929208598,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4924,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83782104174836,
          21.016375925733563,
          105.83787736813788,
          21.016436016513573
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83782104174836,
            21.016375925733563
          ],
          [
            105.83787736813788,
            21.016436016513573
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 138 Phố Chợ Khâm Thiên",
        "diemDau": "3-6",
        "diemCuoi": "3+6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016375925733563,
        "to_latitude": 21.016436016513573,
        "to_longitude": 105.83787736813788,
        "from_longitude": 105.83782104174836,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4925,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83769923710294,
          21.016436016513573,
          105.83787736813788,
          21.016549390105745
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83787736813788,
            21.016436016513573
          ],
          [
            105.83769923710294,
            21.016549390105745
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 138 Phố Chợ Khâm Thiên",
        "diemDau": "3+6",
        "diemCuoi": "3-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016436016513573,
        "to_latitude": 21.016549390105745,
        "to_longitude": 105.83769923710294,
        "from_longitude": 105.83787736813788,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4926,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83760378282244,
          21.016511129954957,
          105.83769923710294,
          21.01659375469567
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83769923710294,
            21.016549390105745
          ],
          [
            105.83765206257975,
            21.016511129954957
          ],
          [
            105.83760378282244,
            21.01659375469567
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 138 Phố Chợ Khâm Thiên",
        "diemDau": "3-7",
        "diemCuoi": "3-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016549390105745,
        "to_latitude": 21.01659375469567,
        "to_longitude": 105.83760378282244,
        "from_longitude": 105.83769923710294,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4927,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83754477422515,
          21.016478580801365,
          105.83760378282244,
          21.01659375469567
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83760378282244,
            21.01659375469567
          ],
          [
            105.83754477422515,
            21.016531160199197
          ],
          [
            105.83755282084839,
            21.016478580801365
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 138 Phố Chợ Khâm Thiên",
        "diemDau": "3-8",
        "diemCuoi": "3-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.01659375469567,
        "to_latitude": 21.016478580801365,
        "to_longitude": 105.83755282084839,
        "from_longitude": 105.83760378282244,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4928,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83735433738565,
          21.016478580801365,
          105.83755282084839,
          21.016588747136762
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83755282084839,
            21.016478580801365
          ],
          [
            105.83735433738565,
            21.016588747136762
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 138 Phố Chợ Khâm Thiên",
        "diemDau": "3-9",
        "diemCuoi": "3-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016478580801365,
        "to_latitude": 21.016588747136762,
        "to_longitude": 105.83735433738565,
        "from_longitude": 105.83755282084839,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4929,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83714512508297,
          21.016553694221287,
          105.83735433738565,
          21.01666636427899
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83735433738565,
            21.016588747136762
          ],
          [
            105.83733287971472,
            21.016553694221287
          ],
          [
            105.83714512508297,
            21.01666636427899
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 138 Phố Chợ Khâm Thiên",
        "diemDau": "3-10",
        "diemCuoi": "3-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016588747136762,
        "to_latitude": 21.01666636427899,
        "to_longitude": 105.83714512508297,
        "from_longitude": 105.83735433738565,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4930,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80380742271771,
          21.016945921661335,
          105.80390029424387,
          21.01700945490085
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80380742271771,
            21.016945921661335
          ],
          [
            105.80390029424387,
            21.01700945490085
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 726 Đường Láng",
        "diemDau": "05",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.016945921661335,
        "to_latitude": 21.01700945490085,
        "to_longitude": 105.80390029424387,
        "from_longitude": 105.80380742271771,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4931,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83806244056191,
          21.016424844650743,
          105.83820727985184,
          21.016641326495748
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83817172302938,
            21.016424844650743
          ],
          [
            105.83820727985184,
            21.016563709340474
          ],
          [
            105.83806244056191,
            21.016641326495748
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 138 Phố Chợ Khâm Thiên",
        "diemDau": "03",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016424844650743,
        "to_latitude": 21.016641326495748,
        "to_longitude": 105.83806244056191,
        "from_longitude": 105.83817172302938,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4932,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83790687244102,
          21.016641326495748,
          105.83806244056191,
          21.016738973826946
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83806244056191,
            21.016641326495748
          ],
          [
            105.83790687244102,
            21.016738973826946
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 138 Phố Chợ Khâm Thiên",
        "diemDau": "3-1",
        "diemCuoi": "3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016641326495748,
        "to_latitude": 21.016738973826946,
        "to_longitude": 105.83790687244102,
        "from_longitude": 105.83806244056191,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4933,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83774057548015,
          21.016738973826946,
          105.83790687244102,
          21.01685164374552
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83790687244102,
            21.016738973826946
          ],
          [
            105.83774057548015,
            21.01685164374552
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 138 Phố Chợ Khâm Thiên",
        "diemDau": "3-2",
        "diemCuoi": "3-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016738973826946,
        "to_latitude": 21.01685164374552,
        "to_longitude": 105.83774057548015,
        "from_longitude": 105.83790687244102,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4934,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83758500735925,
          21.01685164374552,
          105.83774057548015,
          21.01695930603247
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83774057548015,
            21.01685164374552
          ],
          [
            105.83758500735925,
            21.01695930603247
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 138 Phố Chợ Khâm Thiên",
        "diemDau": "3-3",
        "diemCuoi": "3-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.01685164374552,
        "to_latitude": 21.01695930603247,
        "to_longitude": 105.83758500735925,
        "from_longitude": 105.83774057548015,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4935,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80317743892502,
          21.01771050951458,
          105.80327399838578,
          21.017854475681574
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80327399838578,
            21.01771050951458
          ],
          [
            105.80317743892502,
            21.017854475681574
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01771050951458,
        "to_latitude": 21.017854475681574,
        "to_longitude": 105.80317743892502,
        "from_longitude": 105.80327399838578,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4936,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80306478614597,
          21.017854475681574,
          105.80317743892502,
          21.018047264936747
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80317743892502,
            21.017854475681574
          ],
          [
            105.80306478614597,
            21.018047264936747
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.017854475681574,
        "to_latitude": 21.018047264936747,
        "to_longitude": 105.80306478614597,
        "from_longitude": 105.80317743892502,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4937,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80295749778239,
          21.018047264936747,
          105.80306478614597,
          21.018247565197395
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80306478614597,
            21.018047264936747
          ],
          [
            105.80295749778239,
            21.018247565197395
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.018047264936747,
        "to_latitude": 21.018247565197395,
        "to_longitude": 105.80295749778239,
        "from_longitude": 105.80306478614597,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4938,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8028502094278,
          21.018247565197395,
          105.80295749778239,
          21.01845162081202
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80295749778239,
            21.018247565197395
          ],
          [
            105.8028502094278,
            21.01845162081202
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.018247565197395,
        "to_latitude": 21.01845162081202,
        "to_longitude": 105.8028502094278,
        "from_longitude": 105.80295749778239,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4939,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80274157996033,
          21.01845162081202,
          105.8028502094278,
          21.018670698614596
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8028502094278,
            21.01845162081202
          ],
          [
            105.80274157996033,
            21.018670698614596
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01845162081202,
        "to_latitude": 21.018670698614596,
        "to_longitude": 105.80274157996033,
        "from_longitude": 105.8028502094278,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4940,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83829847495998,
          21.016558701780554,
          105.83846208970412,
          21.016641326494895
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83829847495998,
            21.016641326494895
          ],
          [
            105.83846208970412,
            21.016558701780554
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đằng",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016641326494895,
        "to_latitude": 21.016558701780554,
        "to_longitude": 105.83846208970412,
        "from_longitude": 105.83829847495998,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4941,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80262892718127,
          21.018670698614596,
          105.80274157996033,
          21.018868494297045
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80274157996033,
            21.018670698614596
          ],
          [
            105.80262892718127,
            21.018868494297045
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.018670698614596,
        "to_latitude": 21.018868494297045,
        "to_longitude": 105.80262892718127,
        "from_longitude": 105.80274157996033,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4942,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80252834434603,
          21.018868494297045,
          105.80262892718127,
          21.01906253411043
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80262892718127,
            21.018868494297045
          ],
          [
            105.80252834434603,
            21.01906253411043
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.018868494297045,
        "to_latitude": 21.01906253411043,
        "to_longitude": 105.80252834434603,
        "from_longitude": 105.80262892718127,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4943,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80243178481342,
          21.01906253411043,
          105.80252834434603,
          21.019254069936167
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80252834434603,
            21.01906253411043
          ],
          [
            105.80243178481342,
            21.019254069936167
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01906253411043,
        "to_latitude": 21.019254069936167,
        "to_longitude": 105.80243178481342,
        "from_longitude": 105.80252834434603,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4944,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80231343234965,
          21.019254069936167,
          105.80243178481342,
          21.01949035970923
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80243178481342,
            21.019254069936167
          ],
          [
            105.80231343234965,
            21.01949035970923
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019254069936167,
        "to_latitude": 21.01949035970923,
        "to_longitude": 105.80231343234965,
        "from_longitude": 105.80243178481342,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4945,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83429307513391,
          21.014425852405978,
          105.83460588772186,
          21.014724745110193
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83460588772186,
            21.014425852405978
          ],
          [
            105.83429307513391,
            21.014724745110193
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 54,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "01",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.014425852405978,
        "to_latitude": 21.014724745110193,
        "to_longitude": 105.83429307513391,
        "from_longitude": 105.83460588772186,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4946,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83395511679672,
          21.014724745110193,
          105.83429307513391,
          21.015100316246436
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83429307513391,
            21.014724745110193
          ],
          [
            105.83395511679672,
            21.015100316246436
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 56,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.014724745110193,
        "to_latitude": 21.015100316246436,
        "to_longitude": 105.83395511679672,
        "from_longitude": 105.83429307513391,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4947,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83362788729949,
          21.015100316246436,
          105.83395511679672,
          21.015400772474184
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83395511679672,
            21.015100316246436
          ],
          [
            105.83362788729949,
            21.015400772474184
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.015100316246436,
        "to_latitude": 21.015400772474184,
        "to_longitude": 105.83362788729949,
        "from_longitude": 105.83395511679672,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4948,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83339587621975,
          21.015400772474184,
          105.83362788729949,
          21.015641137020527
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83362788729949,
            21.015400772474184
          ],
          [
            105.83339587621975,
            21.015641137020527
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.015400772474184,
        "to_latitude": 21.015641137020527,
        "to_longitude": 105.83339587621975,
        "from_longitude": 105.83362788729949,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4949,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83306998782864,
          21.015641137020527,
          105.83339587621975,
          21.015971637639232
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83339587621975,
            21.015641137020527
          ],
          [
            105.83306998782864,
            21.015971637639232
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.015641137020527,
        "to_latitude": 21.015971637639232,
        "to_longitude": 105.83306998782864,
        "from_longitude": 105.83339587621975,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4950,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83441759130244,
          21.014425852405978,
          105.83460588772186,
          21.01476730988639
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83460588772186,
            21.014425852405978
          ],
          [
            105.83441759130244,
            21.014605769872883
          ],
          [
            105.83457470709055,
            21.01476730988639
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 56,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "01",
        "diemCuoi": "1-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.014425852405978,
        "to_latitude": 21.01476730988639,
        "to_longitude": 105.83457470709055,
        "from_longitude": 105.83460588772186,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4951,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83434403711694,
          21.014425852405978,
          105.83460588772186,
          21.014605710847796
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83460588772186,
            21.014425852405978
          ],
          [
            105.83441765307641,
            21.014605710847796
          ],
          [
            105.83434403711694,
            21.014536959186998
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đê La Thành",
        "diemDau": "01",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.014425852405978,
        "to_latitude": 21.014536959186998,
        "to_longitude": 105.83434403711694,
        "from_longitude": 105.83460588772186,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4952,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83791357796935,
          21.015915228948966,
          105.83822605531503,
          21.016081730939952
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83791357796935,
            21.016081730939952
          ],
          [
            105.83822605531503,
            21.015915228948966
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "02",
        "diemCuoi": "2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016081730939952,
        "to_latitude": 21.015915228948966,
        "to_longitude": 105.83822605531503,
        "from_longitude": 105.83791357796935,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4953,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83822605531503,
          21.01581006970127,
          105.83835480134952,
          21.015915228948966
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83822605531503,
            21.015915228948966
          ],
          [
            105.83835480134952,
            21.01581006970127
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "2-1",
        "diemCuoi": "2-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.015915228948966,
        "to_latitude": 21.01581006970127,
        "to_longitude": 105.83835480134952,
        "from_longitude": 105.83822605531503,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4954,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83835480134952,
          21.01576750522271,
          105.83842453877777,
          21.01581006970127
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83835480134952,
            21.01581006970127
          ],
          [
            105.83842453877777,
            21.01576750522271
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.01581006970127,
        "to_latitude": 21.01576750522271,
        "to_longitude": 105.83842453877777,
        "from_longitude": 105.83835480134952,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4955,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83822605531503,
          21.015915228948966,
          105.83832261483865,
          21.016047929799
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83822605531503,
            21.015915228948966
          ],
          [
            105.83832261483865,
            21.016047929799
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "2-1",
        "diemCuoi": "2-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.015915228948966,
        "to_latitude": 21.016047929799,
        "to_longitude": 105.83832261483865,
        "from_longitude": 105.83822605531503,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4956,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83832261483865,
          21.016020388123334,
          105.83846477191186,
          21.016130554797186
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83832261483865,
            21.016047929799
          ],
          [
            105.8384003988991,
            21.016020388123334
          ],
          [
            105.83846477191186,
            21.016130554797186
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "diemDau": "2-3",
        "diemCuoi": "2-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016047929799,
        "to_latitude": 21.016130554797186,
        "to_longitude": 105.83846477191186,
        "from_longitude": 105.83832261483865,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4957,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80274157996033,
          21.018670698614596,
          105.80298230823544,
          21.018788374558017
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80274157996033,
            21.018670698614596
          ],
          [
            105.80298230823544,
            21.018788374558017
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 814 Đường Láng",
        "diemDau": "14",
        "diemCuoi": "14-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.018670698614596,
        "to_latitude": 21.018788374558017,
        "to_longitude": 105.80298230823544,
        "from_longitude": 105.80274157996033,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4958,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80298230823544,
          21.018538000098417,
          105.80312312420476,
          21.018788374558017
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80298230823544,
            21.018788374558017
          ],
          [
            105.80312312420476,
            21.018538000098417
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 814 Đường Láng",
        "diemDau": "14-1",
        "diemCuoi": "14-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.018788374558017,
        "to_latitude": 21.018538000098417,
        "to_longitude": 105.80312312420476,
        "from_longitude": 105.80298230823544,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4959,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80298230823544,
          21.018788374558017,
          105.80319152053812,
          21.01887099803783
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80298230823544,
            21.018788374558017
          ],
          [
            105.80319152053812,
            21.01887099803783
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 814 Đường Láng",
        "diemDau": "14-1",
        "diemCuoi": "14-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.018788374558017,
        "to_latitude": 21.01887099803783,
        "to_longitude": 105.80319152053812,
        "from_longitude": 105.80298230823544,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4960,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80319152053812,
          21.01887099803783,
          105.80334574755517,
          21.018917317241026
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80319152053812,
            21.01887099803783
          ],
          [
            105.80334574755517,
            21.018917317241026
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 814 Đường Láng",
        "diemDau": "14-3",
        "diemCuoi": "14-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01887099803783,
        "to_latitude": 21.018917317241026,
        "to_longitude": 105.80334574755517,
        "from_longitude": 105.80319152053812,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4961,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80319152053812,
          21.01868321733637,
          105.80327601011793,
          21.01887099803783
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80319152053812,
            21.01887099803783
          ],
          [
            105.80327601011793,
            21.01868321733637
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 814 Đường Láng",
        "diemDau": "14-3",
        "diemCuoi": "14-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01887099803783,
        "to_latitude": 21.01868321733637,
        "to_longitude": 105.80327601011793,
        "from_longitude": 105.80319152053812,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4962,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80327601011793,
          21.01855177070482,
          105.80335781749898,
          21.01868321733637
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80327601011793,
            21.01868321733637
          ],
          [
            105.80335781749898,
            21.01855177070482
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 814 Đường Láng",
        "diemDau": "14-5",
        "diemCuoi": "14-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01868321733637,
        "to_latitude": 21.01855177070482,
        "to_longitude": 105.80335781749898,
        "from_longitude": 105.80327601011793,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4963,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8381965510119,
          21.016864089191944,
          105.8384236918281,
          21.016929260750917
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8384236918281,
            21.016864089191944
          ],
          [
            105.8381965510119,
            21.016929260750917
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Nam Lai",
        "diemDau": "05",
        "diemCuoi": "5-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016864089191944,
        "to_latitude": 21.016929260750917,
        "to_longitude": 105.8381965510119,
        "from_longitude": 105.8384236918281,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4964,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80309764322223,
          21.018870998037844,
          105.80319152053812,
          21.019106349516502
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80319152053812,
            21.018870998037844
          ],
          [
            105.80309764322223,
            21.019106349516502
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 814 Đường Láng",
        "diemDau": "14-3",
        "diemCuoi": "14-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.018870998037844,
        "to_latitude": 21.019106349516502,
        "to_longitude": 105.80309764322223,
        "from_longitude": 105.80319152053812,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4965,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80334574755517,
          21.01876333713106,
          105.80341548499239,
          21.018917317241026
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80334574755517,
            21.018917317241026
          ],
          [
            105.80341548499239,
            21.01876333713106
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 814 Đường Láng",
        "diemDau": "14-7",
        "diemCuoi": "14-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.018917317241026,
        "to_latitude": 21.01876333713106,
        "to_longitude": 105.80341548499239,
        "from_longitude": 105.80334574755517,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4966,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80341548499239,
          21.018543007591873,
          105.80358580526485,
          21.01876333713106
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80341548499239,
            21.01876333713106
          ],
          [
            105.80358580526485,
            21.018543007591873
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 814 Đường Láng",
        "diemDau": "14-8",
        "diemCuoi": "14-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01876333713106,
        "to_latitude": 21.018543007591873,
        "to_longitude": 105.80358580526485,
        "from_longitude": 105.80341548499239,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4967,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80231343234965,
          21.019490359709256,
          105.8025169449766,
          21.019682207951618
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80231343234965,
            21.019490359709256
          ],
          [
            105.8025169449766,
            21.019682207951618
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "diemDau": "18",
        "diemCuoi": "18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019490359709256,
        "to_latitude": 21.019682207951618,
        "to_longitude": 105.8025169449766,
        "from_longitude": 105.80231343234965,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4968,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8025169449766,
          21.019682207951618,
          105.80272213396768,
          21.01977109025165
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8025169449766,
            21.019682207951618
          ],
          [
            105.80272213396768,
            21.01977109025165
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "diemDau": "18-1",
        "diemCuoi": "18-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019682207951618,
        "to_latitude": 21.01977109025165,
        "to_longitude": 105.80272213396768,
        "from_longitude": 105.8025169449766,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4969,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8025169449766,
          21.019413057004467,
          105.80267251309752,
          21.019682207951618
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8025169449766,
            21.019682207951618
          ],
          [
            105.80267251309752,
            21.019413057004467
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "diemDau": "18-1",
        "diemCuoi": "18-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019682207951618,
        "to_latitude": 21.019413057004467,
        "to_longitude": 105.80267251309752,
        "from_longitude": 105.8025169449766,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4970,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80267251309752,
          21.01916894293371,
          105.80282942232229,
          21.019413057004467
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80267251309752,
            21.019413057004467
          ],
          [
            105.80282942232229,
            21.01916894293371
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "diemDau": "18-2",
        "diemCuoi": "18-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019413057004467,
        "to_latitude": 21.01916894293371,
        "to_longitude": 105.80282942232229,
        "from_longitude": 105.80267251309752,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4971,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80272213396768,
          21.01953667872088,
          105.80285523859199,
          21.019771090251663
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80272213396768,
            21.019771090251663
          ],
          [
            105.80285523859199,
            21.01953667872088
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "diemDau": "18-4",
        "diemCuoi": "18-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019771090251663,
        "to_latitude": 21.01953667872088,
        "to_longitude": 105.80285523859199,
        "from_longitude": 105.80272213396768,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4972,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80272213396768,
          21.019771090251663,
          105.80293302265247,
          21.019878437465835
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80272213396768,
            21.019771090251663
          ],
          [
            105.80293302265247,
            21.019878437465835
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "diemDau": "18-4",
        "diemCuoi": "18-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019771090251663,
        "to_latitude": 21.019878437465835,
        "to_longitude": 105.80293302265247,
        "from_longitude": 105.80272213396768,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4973,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80293302265247,
          21.019625561107663,
          105.8030416521199,
          21.019878437465835
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80293302265247,
            21.019878437465835
          ],
          [
            105.8030416521199,
            21.019625561107663
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "diemDau": "18-6",
        "diemCuoi": "18-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019878437465835,
        "to_latitude": 21.019625561107663,
        "to_longitude": 105.8030416521199,
        "from_longitude": 105.80293302265247,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4974,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8030416521199,
          21.019370180587423,
          105.8031878325137,
          21.019625561107663
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8030416521199,
            21.019625561107663
          ],
          [
            105.8031878325137,
            21.019370180587423
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "diemDau": "18-7",
        "diemCuoi": "18-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019625561107663,
        "to_latitude": 21.019370180587423,
        "to_longitude": 105.8031878325137,
        "from_longitude": 105.8030416521199,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4975,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8031878325137,
          21.019167378098125,
          105.80322806564782,
          21.019370180587423
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8031878325137,
            21.019370180587423
          ],
          [
            105.80322806564782,
            21.019167378098125
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "diemDau": "18-8",
        "diemCuoi": "18-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019370180587423,
        "to_latitude": 21.019167378098125,
        "to_longitude": 105.80322806564782,
        "from_longitude": 105.8031878325137,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4976,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80293302265247,
          21.019878437465835,
          105.80306009231384,
          21.019946976899732
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80293302265247,
            21.019878437465835
          ],
          [
            105.80306009231384,
            21.019946976899732
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "diemDau": "18-6",
        "diemCuoi": "18-17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019878437465835,
        "to_latitude": 21.019946976899732,
        "to_longitude": 105.80306009231384,
        "from_longitude": 105.80293302265247,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4977,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80306009231384,
          21.01973134359612,
          105.80316235153117,
          21.019946976899732
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80306009231384,
            21.019946976899732
          ],
          [
            105.80316235153117,
            21.01973134359612
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102 Pháo Đài Láng",
        "diemDau": "18-17",
        "diemCuoi": "18-19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019946976899732,
        "to_latitude": 21.01973134359612,
        "to_longitude": 105.80316235153117,
        "from_longitude": 105.80306009231384,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4978,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80316235153117,
          21.019533549058227,
          105.80328841535794,
          21.01973134359612
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80316235153117,
            21.01973134359612
          ],
          [
            105.80328841535794,
            21.019533549058227
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102 Pháo Đài Láng",
        "diemDau": "18-19",
        "diemCuoi": "18-20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01973134359612,
        "to_latitude": 21.019533549058227,
        "to_longitude": 105.80328841535794,
        "from_longitude": 105.80316235153117,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4979,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80328841535794,
          21.019343265457504,
          105.80342118470405,
          21.019533549058227
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80328841535794,
            21.019533549058227
          ],
          [
            105.80342118470405,
            21.019343265457504
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102 Pháo Đài Láng",
        "diemDau": "18-20",
        "diemCuoi": "18-21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019533549058227,
        "to_latitude": 21.019343265457504,
        "to_longitude": 105.80342118470405,
        "from_longitude": 105.80328841535794,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4980,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80342118470405,
          21.01912418864306,
          105.80356468288113,
          21.019343265457504
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80342118470405,
            21.019343265457504
          ],
          [
            105.80356468288113,
            21.01912418864306
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102 Pháo Đài Láng",
        "diemDau": "18-21",
        "diemCuoi": "18-22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019343265457504,
        "to_latitude": 21.01912418864306,
        "to_longitude": 105.80356468288113,
        "from_longitude": 105.80342118470405,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4981,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80356468288113,
          21.019016527919096,
          105.80360893933582,
          21.01912418864306
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80356468288113,
            21.01912418864306
          ],
          [
            105.80360893933582,
            21.019016527919096
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/14 Pháo Đài Láng",
        "diemDau": "18-22",
        "diemCuoi": "18-23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01912418864306,
        "to_latitude": 21.019016527919096,
        "to_longitude": 105.80360893933582,
        "from_longitude": 105.80356468288113,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4982,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80360893933582,
          21.018983979313195,
          105.80384765593266,
          21.019049076518723
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80360893933582,
            21.019016527919096
          ],
          [
            105.80364380805443,
            21.019022787266014
          ],
          [
            105.80369342891562,
            21.018983979313195
          ],
          [
            105.80384765593266,
            21.019049076518723
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/14 Pháo Đài Láng",
        "diemDau": "18-23",
        "diemCuoi": "18-24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019016527919096,
        "to_latitude": 21.019049076518723,
        "to_longitude": 105.80384765593266,
        "from_longitude": 105.80360893933582,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4983,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80384765593266,
          21.019049076518723,
          105.80406357376367,
          21.019157989086747
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80384765593266,
            21.019049076518723
          ],
          [
            105.80406357376367,
            21.019157989086747
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/14 Pháo Đài Láng",
        "diemDau": "18-24",
        "diemCuoi": "18-25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019049076518723,
        "to_latitude": 21.019157989086747,
        "to_longitude": 105.80406357376367,
        "from_longitude": 105.80384765593266,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4984,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80356468288113,
          21.01912418864306,
          105.80378596512763,
          21.019281923981666
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80356468288113,
            21.01912418864306
          ],
          [
            105.80378596512763,
            21.019281923981666
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/20 Pháo Đài Láng",
        "diemDau": "18-22",
        "diemCuoi": "18-26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01912418864306,
        "to_latitude": 21.019281923981666,
        "to_longitude": 105.80378596512763,
        "from_longitude": 105.80356468288113,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4985,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80378596512763,
          21.019281923981666,
          105.8040099295819,
          21.019400851309936
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80378596512763,
            21.019281923981666
          ],
          [
            105.8040099295819,
            21.019400851309936
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/20 Pháo Đài Láng",
        "diemDau": "18-26",
        "diemCuoi": "18-27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019281923981666,
        "to_latitude": 21.019400851309936,
        "to_longitude": 105.8040099295819,
        "from_longitude": 105.80378596512763,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4986,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8040099295819,
          21.019400851309936,
          105.80430363146439,
          21.019511015486444
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8040099295819,
            21.019400851309936
          ],
          [
            105.80430363146439,
            21.019511015486444
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/20 Pháo Đài Láng",
        "diemDau": "18-27",
        "diemCuoi": "18-28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019400851309936,
        "to_latitude": 21.019511015486444,
        "to_longitude": 105.80430363146439,
        "from_longitude": 105.8040099295819,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4987,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80430363146439,
          21.019511015486444,
          105.80449675052063,
          21.019631194495545
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80430363146439,
            21.019511015486444
          ],
          [
            105.80449675052063,
            21.019631194495545
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/20 Pháo Đài Láng",
        "diemDau": "18-28",
        "diemCuoi": "18-29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019511015486444,
        "to_latitude": 21.019631194495545,
        "to_longitude": 105.80449675052063,
        "from_longitude": 105.80430363146439,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4988,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80449675052063,
          21.019631194495545,
          105.80470596282333,
          21.01975387713461
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80449675052063,
            21.019631194495545
          ],
          [
            105.80470596282333,
            21.01975387713461
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/20 Pháo Đài Láng",
        "diemDau": "18-29",
        "diemCuoi": "18-30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019631194495545,
        "to_latitude": 21.01975387713461,
        "to_longitude": 105.80470596282333,
        "from_longitude": 105.80449675052063,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4989,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80470596282333,
          21.019753877134598,
          105.80496345488332,
          21.019884070844174
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80470596282333,
            21.019753877134598
          ],
          [
            105.80496345488332,
            21.019884070844174
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/20 Pháo Đài Láng",
        "diemDau": "18-30",
        "diemCuoi": "18-31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019753877134598,
        "to_latitude": 21.019884070844174,
        "to_longitude": 105.80496345488332,
        "from_longitude": 105.80470596282333,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4990,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80496345488332,
          21.01970004700925,
          105.80506806103917,
          21.01988407084416
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80496345488332,
            21.01988407084416
          ],
          [
            105.80506806103917,
            21.01970004700925
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/20 Pháo Đài Láng",
        "diemDau": "18-31",
        "diemCuoi": "18-32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01988407084416,
        "to_latitude": 21.01970004700925,
        "to_longitude": 105.80506806103917,
        "from_longitude": 105.80496345488332,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4991,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80506806103917,
          21.019517274812586,
          105.8051686438744,
          21.01970004700925
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80506806103917,
            21.01970004700925
          ],
          [
            105.8051686438744,
            21.019517274812586
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/20 Pháo Đài Láng",
        "diemDau": "18-32",
        "diemCuoi": "18-33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01970004700925,
        "to_latitude": 21.019517274812586,
        "to_longitude": 105.8051686438744,
        "from_longitude": 105.80506806103917,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4992,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80328841535794,
          21.019533549058213,
          105.80359016387266,
          21.019720076826847
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80328841535794,
            21.019533549058213
          ],
          [
            105.80359016387266,
            21.019720076826847
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "diemDau": "18-20",
        "diemCuoi": "18-34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019533549058213,
        "to_latitude": 21.019720076826847,
        "to_longitude": 105.80359016387266,
        "from_longitude": 105.80328841535794,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4993,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8035029920761,
          21.019720076826847,
          105.80359016387266,
          21.01986779663849
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80359016387266,
            21.019720076826847
          ],
          [
            105.8035029920761,
            21.01986779663849
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "diemDau": "18-34",
        "diemCuoi": "18-35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019720076826847,
        "to_latitude": 21.01986779663849,
        "to_longitude": 105.8035029920761,
        "from_longitude": 105.80359016387266,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4994,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80359016387266,
          21.019720076826847,
          105.80378060071216,
          21.01981647028001
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80359016387266,
            21.019720076826847
          ],
          [
            105.80378060071216,
            21.01981647028001
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "diemDau": "18-34",
        "diemCuoi": "18-36",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019720076826847,
        "to_latitude": 21.01981647028001,
        "to_longitude": 105.80378060071216,
        "from_longitude": 105.80359016387266,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4995,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80362905590287,
          21.01981647028001,
          105.80378060071216,
          21.01999673838807
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80378060071216,
            21.01981647028001
          ],
          [
            105.8037524375129,
            21.019817722142033
          ],
          [
            105.80362905590287,
            21.01999673838807
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "diemDau": "18-36",
        "diemCuoi": "18-37",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01981647028001,
        "to_latitude": 21.01999673838807,
        "to_longitude": 105.80362905590287,
        "from_longitude": 105.80378060071216,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4996,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80378060071216,
          21.01981647028001,
          105.8039992007599,
          21.019898467258628
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80378060071216,
            21.01981647028001
          ],
          [
            105.80395762652194,
            21.019898467258628
          ],
          [
            105.8039992007599,
            21.01982961483654
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "diemDau": "18-36",
        "diemCuoi": "18-38",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01981647028001,
        "to_latitude": 21.01982961483654,
        "to_longitude": 105.8039992007599,
        "from_longitude": 105.80378060071216,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4997,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80378060071216,
          21.01981647028001,
          105.8039549443142,
          21.02005369805727
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80378060071216,
            21.01981647028001
          ],
          [
            105.8039549443142,
            21.019939778696287
          ],
          [
            105.80389459460407,
            21.02005369805727
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "diemDau": "18-36",
        "diemCuoi": "18-39",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01981647028001,
        "to_latitude": 21.02005369805727,
        "to_longitude": 105.80389459460407,
        "from_longitude": 105.80378060071216,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4998,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80378060071216,
          21.01981647028001,
          105.80410246580288,
          21.019986097567482
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80378060071216,
            21.01981647028001
          ],
          [
            105.80410246580288,
            21.019986097567482
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "diemDau": "18-36",
        "diemCuoi": "18-40",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.01981647028001,
        "to_latitude": 21.019986097567482,
        "to_longitude": 105.80410246580288,
        "from_longitude": 105.80378060071216,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 4999,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80398478388656,
          21.019986097567482,
          105.80410246580288,
          21.020233026983387
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80410246580288,
            21.019986097567482
          ],
          [
            105.80398478388656,
            21.020233026983387
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "diemDau": "18-40",
        "diemCuoi": "18-41",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019986097567482,
        "to_latitude": 21.020233026983387,
        "to_longitude": 105.80398478388656,
        "from_longitude": 105.80410246580288,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5000,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80398478388656,
          21.020233026983387,
          105.8041470575358,
          21.020309390370052
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80398478388656,
            21.020233026983387
          ],
          [
            105.8041470575358,
            21.020309390370052
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "diemDau": "18-41",
        "diemCuoi": "18-42",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.020233026983387,
        "to_latitude": 21.020309390370052,
        "to_longitude": 105.8041470575358,
        "from_longitude": 105.80398478388656,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5001,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80410246580288,
          21.019977647504422,
          105.80434822320628,
          21.02006527776674
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80410246580288,
            21.019986097567482
          ],
          [
            105.80411487102492,
            21.019977647504422
          ],
          [
            105.80434822320628,
            21.02006527776674
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "diemDau": "18-401",
        "diemCuoi": "18-43",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019986097567482,
        "to_latitude": 21.02006527776674,
        "to_longitude": 105.80434822320628,
        "from_longitude": 105.80410246580288,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5002,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80434822320628,
          21.02006527776674,
          105.80454536558312,
          21.02017919703269
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80434822320628,
            21.02006527776674
          ],
          [
            105.80454536558312,
            21.02017919703269
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "diemDau": "18-43",
        "diemCuoi": "18-44",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.02006527776674,
        "to_latitude": 21.02017919703269,
        "to_longitude": 105.80454536558312,
        "from_longitude": 105.80434822320628,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5003,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80454536558312,
          21.02017919703269,
          105.80465265394669,
          21.020215500955466
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80454536558312,
            21.02017919703269
          ],
          [
            105.80465265394669,
            21.020215500955466
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "diemDau": "18-44",
        "diemCuoi": "18-49",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.02017919703269,
        "to_latitude": 21.020215500955466,
        "to_longitude": 105.80465265394669,
        "from_longitude": 105.80454536558312,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5004,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80465265394669,
          21.020057766604413,
          105.80474116683811,
          21.020215500955466
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80465265394669,
            21.020215500955466
          ],
          [
            105.80474116683811,
            21.020057766604413
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "diemDau": "18-49",
        "diemCuoi": "18-50",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.020215500955466,
        "to_latitude": 21.020057766604413,
        "to_longitude": 105.80474116683811,
        "from_longitude": 105.80465265394669,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5005,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80447831035362,
          21.02017919703269,
          105.80454536558312,
          21.0203231608137
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80454536558312,
            21.02017919703269
          ],
          [
            105.80447831035362,
            21.0203231608137
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "diemDau": "18-44",
        "diemCuoi": "18-45",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.02017919703269,
        "to_latitude": 21.0203231608137,
        "to_longitude": 105.80447831035362,
        "from_longitude": 105.80454536558312,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5006,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80429927289255,
          21.0203231608137,
          105.80447831035362,
          21.020608584247974
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80447831035362,
            21.0203231608137
          ],
          [
            105.80429927289255,
            21.020608584247974
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "diemDau": "18-45",
        "diemCuoi": "18-46",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.0203231608137,
        "to_latitude": 21.020608584247974,
        "to_longitude": 105.80429927289255,
        "from_longitude": 105.80447831035362,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5007,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80429927289255,
          21.020608584247974,
          105.80451384961071,
          21.020761310598324
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80429927289255,
            21.020608584247974
          ],
          [
            105.80451384961071,
            21.020761310598324
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "diemDau": "18-46",
        "diemCuoi": "18-47",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.020608584247974,
        "to_latitude": 21.020761310598324,
        "to_longitude": 105.80451384961071,
        "from_longitude": 105.80429927289255,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5008,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80438376247233,
          21.020761310598324,
          105.80451384961071,
          21.020992903534424
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80451384961071,
            21.020761310598324
          ],
          [
            105.80438376247233,
            21.020992903534424
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "diemDau": "18-47",
        "diemCuoi": "18-48",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.020761310598324,
        "to_latitude": 21.020992903534424,
        "to_longitude": 105.80438376247233,
        "from_longitude": 105.80451384961071,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5009,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80306009231384,
          21.019946976899732,
          105.80332697212792,
          21.020120359620552
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80306009231384,
            21.019946976899732
          ],
          [
            105.80332697212792,
            21.020120359620552
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "diemDau": "18-17",
        "diemCuoi": "18-18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019946976899732,
        "to_latitude": 21.020120359620552,
        "to_longitude": 105.80332697212792,
        "from_longitude": 105.80306009231384,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5010,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80319286167793,
          21.020120359620552,
          105.80332697212792,
          21.02037448699776
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80332697212792,
            21.020120359620552
          ],
          [
            105.80319286167793,
            21.02037448699776
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "diemDau": "18-18",
        "diemCuoi": "18-18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.020120359620552,
        "to_latitude": 21.02037448699776,
        "to_longitude": 105.80319286167793,
        "from_longitude": 105.80332697212792,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5011,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80282674015046,
          21.019946976899732,
          105.80306009231384,
          21.020122863340323
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80306009231384,
            21.019946976899732
          ],
          [
            105.80282674015046,
            21.020122863340323
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 185 Chùa Láng",
        "diemDau": "18-17",
        "diemCuoi": "18-16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.019946976899732,
        "to_latitude": 21.020122863340323,
        "to_longitude": 105.80282674015046,
        "from_longitude": 105.80306009231384,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5012,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80253035605125,
          21.020122863340323,
          105.80282674015046,
          21.020388257434565
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80282674015046,
            21.020122863340323
          ],
          [
            105.80253035605125,
            21.020388257434565
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 185 Chùa Láng",
        "diemDau": "18-16",
        "diemCuoi": "18-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.020122863340323,
        "to_latitude": 21.020388257434565,
        "to_longitude": 105.80253035605125,
        "from_longitude": 105.80282674015046,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5013,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80237747013808,
          21.020388257434565,
          105.80253035605125,
          21.020577287845256
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80253035605125,
            21.020388257434565
          ],
          [
            105.80237747013808,
            21.020577287845256
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 185 Chùa Láng",
        "diemDau": "18-13",
        "diemCuoi": "18-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.020388257434565,
        "to_latitude": 21.020577287845256,
        "to_longitude": 105.80237747013808,
        "from_longitude": 105.80253035605125,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5014,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80223665416874,
          21.020577287845256,
          105.80237747013808,
          21.020815140338865
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80237747013808,
            21.020577287845256
          ],
          [
            105.80223665416874,
            21.020815140338865
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 185 Chùa Láng",
        "diemDau": "18-12",
        "diemCuoi": "18-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.020577287845256,
        "to_latitude": 21.020815140338865,
        "to_longitude": 105.80223665416874,
        "from_longitude": 105.80237747013808,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5015,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80209852040716,
          21.020815140338865,
          105.80223665416874,
          21.021061755420146
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80223665416874,
            21.020815140338865
          ],
          [
            105.80209852040716,
            21.021061755420146
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 185 Chùa Láng",
        "diemDau": "18-11",
        "diemCuoi": "18-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.020815140338865,
        "to_latitude": 21.021061755420146,
        "to_longitude": 105.80209852040716,
        "from_longitude": 105.80223665416874,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5016,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80209852040716,
          21.020984140565773,
          105.80231041492657,
          21.021061755420156
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80209852040716,
            21.021061755420156
          ],
          [
            105.80216021122115,
            21.020984140565773
          ],
          [
            105.80231041492657,
            21.021027955408023
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 65+91/185 Chùa Láng",
        "diemDau": "18-10",
        "diemCuoi": "18-10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.021061755420156,
        "to_latitude": 21.021027955408023,
        "to_longitude": 105.80231041492657,
        "from_longitude": 105.80209852040716,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5017,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80253035605125,
          21.020388257434565,
          105.8028160113195,
          21.020548495150248
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80253035605125,
            21.020388257434565
          ],
          [
            105.8028160113195,
            21.020548495150248
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 65+91/185 Chùa Láng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.020388257434565,
        "to_latitude": 21.020548495150248,
        "to_longitude": 105.8028160113195,
        "from_longitude": 105.80253035605125,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5018,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8028160113195,
          21.020548495150248,
          105.80282539905558,
          21.02061859909567
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8028160113195,
            21.020548495150248
          ],
          [
            105.80282539905558,
            21.02061859909567
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 65+91/185 Chùa Láng",
        "diemDau": "18-14",
        "diemCuoi": "18-15-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.020548495150248,
        "to_latitude": 21.02061859909567,
        "to_longitude": 105.80282539905558,
        "from_longitude": 105.8028160113195,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5019,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80282539905558,
          21.02061859909567,
          105.80294743956175,
          21.0206861992987
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80282539905558,
            21.02061859909567
          ],
          [
            105.80294743956175,
            21.0206861992987
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 65+91/185 Chùa Láng",
        "diemDau": "18-15-1",
        "diemCuoi": "18-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.02061859909567,
        "to_latitude": 21.0206861992987,
        "to_longitude": 105.80294743956175,
        "from_longitude": 105.80282539905558,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5020,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80282539905558,
          21.020537228441064,
          105.80295548619394,
          21.020660896770845
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80282539905558,
            21.02061859909567
          ],
          [
            105.8029017602057,
            21.020660896770845
          ],
          [
            105.80295548619394,
            21.020537228441064
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 65+91/185 Chùa Láng",
        "diemDau": "18-15-1",
        "diemCuoi": "18-15-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.02061859909567,
        "to_latitude": 21.020537228441064,
        "to_longitude": 105.80295548619394,
        "from_longitude": 105.80282539905558,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5021,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80294743956175,
          21.020683695587568,
          105.80308155001175,
          21.020730014227677
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80294743956175,
            21.0206861992987
          ],
          [
            105.8030426579815,
            21.020730014227677
          ],
          [
            105.80308155001175,
            21.020683695587568
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 65+91/185 Chùa Láng",
        "diemDau": "18-15",
        "diemCuoi": "18-15-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.0206861992987,
        "to_latitude": 21.020683695587568,
        "to_longitude": 105.80308155001175,
        "from_longitude": 105.80294743956175,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5022,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80294743956175,
          21.0206861992987,
          105.8031003254749,
          21.02077633285421
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80294743956175,
            21.0206861992987
          ],
          [
            105.8031003254749,
            21.02077633285421
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LTG1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 65+91/185 Chùa Láng",
        "diemDau": "18-15",
        "diemCuoi": "18-15-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Láng Trung 1",
        "from_latitude": 21.0206861992987,
        "to_latitude": 21.02077633285421,
        "to_longitude": 105.8031003254749,
        "from_longitude": 105.80294743956175,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5023,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80386777255362,
          21.01779156874915,
          105.804090395886,
          21.01790298599349
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.804090395886,
            21.0178817040544
          ],
          [
            105.80403675171323,
            21.01790298599349
          ],
          [
            105.80386777255362,
            21.01779156874915
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "diemDau": "01",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.0178817040544,
        "to_latitude": 21.01779156874915,
        "to_longitude": 105.80386777255362,
        "from_longitude": 105.804090395886,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5024,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80396164986949,
          21.0178817040544,
          105.804090395886,
          21.018092019554828
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.804090395886,
            21.0178817040544
          ],
          [
            105.80396164986949,
            21.018092019554828
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.0178817040544,
        "to_latitude": 21.018092019554828,
        "to_longitude": 105.80396164986949,
        "from_longitude": 105.804090395886,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5025,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80385436150591,
          21.0178817040544,
          105.804090395886,
          21.018143346506893
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.804090395886,
            21.0178817040544
          ],
          [
            105.80385436150591,
            21.018143346506893
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.0178817040544,
        "to_latitude": 21.018143346506893,
        "to_longitude": 105.80385436150591,
        "from_longitude": 105.804090395886,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5026,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80385436150591,
          21.018143346506893,
          105.80399517747526,
          21.018331127889027
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80385436150591,
            21.018143346506893
          ],
          [
            105.80399517747526,
            21.018331127889027
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "diemDau": "",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.018143346506893,
        "to_latitude": 21.018331127889027,
        "to_longitude": 105.80399517747526,
        "from_longitude": 105.80385436150591,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5027,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8038597259214,
          21.018331127889027,
          105.80399517747526,
          21.01851515341339
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80399517747526,
            21.018331127889027
          ],
          [
            105.8038597259214,
            21.01851515341339
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.018331127889027,
        "to_latitude": 21.01851515341339,
        "to_longitude": 105.8038597259214,
        "from_longitude": 105.80399517747526,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5028,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80370281669661,
          21.01851515341339,
          105.8038597259214,
          21.0187843059821
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8038597259214,
            21.01851515341339
          ],
          [
            105.80370281669661,
            21.0187843059821
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 102 Pháo Đài Láng",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01851515341339,
        "to_latitude": 21.0187843059821,
        "to_longitude": 105.80370281669661,
        "from_longitude": 105.8038597259214,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5029,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8038597259214,
          21.01851515341339,
          105.80412526460462,
          21.018692919350126
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8038597259214,
            21.01851515341339
          ],
          [
            105.80412526460462,
            21.018692919350126
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "diemDau": "28",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01851515341339,
        "to_latitude": 21.018692919350126,
        "to_longitude": 105.80412526460462,
        "from_longitude": 105.8038597259214,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5030,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80412526460462,
          21.018692919350126,
          105.80450345607592,
          21.018876944428
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80412526460462,
            21.018692919350126
          ],
          [
            105.80450345607592,
            21.018876944428
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.018692919350126,
        "to_latitude": 21.018876944428,
        "to_longitude": 105.80450345607592,
        "from_longitude": 105.80412526460462,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5031,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80450345607592,
          21.01873047550672,
          105.80456246467318,
          21.018876944428
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80450345607592,
            21.018876944428
          ],
          [
            105.80456246467318,
            21.01873047550672
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 131 Pháo Đài Láng",
        "diemDau": "31",
        "diemCuoi": "31-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.018876944428,
        "to_latitude": 21.01873047550672,
        "to_longitude": 105.80456246467318,
        "from_longitude": 105.80450345607592,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5032,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80456246467318,
          21.018553961488227,
          105.80462683768596,
          21.01873047550672
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80456246467318,
            21.01873047550672
          ],
          [
            105.80462683768596,
            21.018553961488227
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 131 Pháo Đài Láng",
        "diemDau": "31-3",
        "diemCuoi": "31-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01873047550672,
        "to_latitude": 21.018553961488227,
        "to_longitude": 105.80462683768596,
        "from_longitude": 105.80456246467318,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5033,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80450345607592,
          21.018876944428,
          105.80457721682475,
          21.018958315988673
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80450345607592,
            21.018876944428
          ],
          [
            105.80457721682475,
            21.01891700427909
          ],
          [
            105.80455039473836,
            21.018958315988673
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 76 Pháo Đài Láng",
        "diemDau": "31",
        "diemCuoi": "31-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.018876944428,
        "to_latitude": 21.018958315988673,
        "to_longitude": 105.80455039473836,
        "from_longitude": 105.80450345607592,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5034,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80447126957402,
          21.018958315988673,
          105.80455039473836,
          21.019086006655733
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80455039473836,
            21.018958315988673
          ],
          [
            105.80447126957402,
            21.019086006655733
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 76 Pháo Đài Láng",
        "diemDau": "31-1",
        "diemCuoi": "31-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.018958315988673,
        "to_latitude": 21.019086006655733,
        "to_longitude": 105.80447126957402,
        "from_longitude": 105.80455039473836,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5035,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80450345607592,
          21.018876944428,
          105.80476497145652,
          21.019013398250564
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80450345607592,
            21.018876944428
          ],
          [
            105.80476497145652,
            21.019013398250564
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC-OCD",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "from_latitude": 21.018876944428,
        "to_latitude": 21.019013398250564,
        "to_longitude": 105.80476497145652,
        "from_longitude": 105.80450345607592,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5036,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80462046745379,
          21.01876271119982,
          105.80476497145652,
          21.019013398250564
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80476497145652,
            21.019013398250564
          ],
          [
            105.80462046745379,
            21.01890292073773
          ],
          [
            105.80468349937166,
            21.01876271119982
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127 Pháo Đài Láng",
        "diemDau": "32",
        "diemCuoi": "32-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.019013398250564,
        "to_latitude": 21.01876271119982,
        "to_longitude": 105.80468349937166,
        "from_longitude": 105.80476497145652,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5037,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80476497145652,
          21.019013398250564,
          105.80504693869138,
          21.019139524034586
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80476497145652,
            21.019013398250564
          ],
          [
            105.80504693869138,
            21.019139524034586
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.019013398250564,
        "to_latitude": 21.019139524034586,
        "to_longitude": 105.80504693869138,
        "from_longitude": 105.80476497145652,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5038,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8048417497003,
          21.019115738535334,
          105.80504693869138,
          21.019215887982106
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80504693869138,
            21.019139524034596
          ],
          [
            105.80495976689487,
            21.019115738535334
          ],
          [
            105.80488064173052,
            21.019213384246267
          ],
          [
            105.8048417497003,
            21.019215887982106
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Pháo Đài Láng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.019139524034596,
        "to_latitude": 21.019215887982106,
        "to_longitude": 105.8048417497003,
        "from_longitude": 105.80504693869138,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5039,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80504693869138,
          21.019139524034596,
          105.80531515959134,
          21.019301014958586
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80504693869138,
            21.019139524034596
          ],
          [
            105.80531515959134,
            21.019301014958586
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "diemDau": "33",
        "diemCuoi": "34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.019139524034596,
        "to_latitude": 21.019301014958586,
        "to_longitude": 105.80531515959134,
        "from_longitude": 105.80504693869138,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5040,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80531515959134,
          21.019301014958586,
          105.80571614983741,
          21.01952134337774
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80571614983741,
            21.01952134337774
          ],
          [
            105.80531515959134,
            21.019301014958586
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01952134337774,
        "to_latitude": 21.019301014958586,
        "to_longitude": 105.80531515959134,
        "from_longitude": 105.80571614983741,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5041,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80571614983741,
          21.01952134337774,
          105.80614530328276,
          21.019697856251923
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80571614983741,
            21.01952134337774
          ],
          [
            105.80614530328276,
            21.019697856251923
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 49,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "diemDau": "35",
        "diemCuoi": "36",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01952134337774,
        "to_latitude": 21.019697856251923,
        "to_longitude": 105.80614530328276,
        "from_longitude": 105.80571614983741,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5042,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80614530328276,
          21.019697856251923,
          105.80643900516525,
          21.019846827948182
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80614530328276,
            21.019697856251923
          ],
          [
            105.80643900516525,
            21.019846827948182
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "diemDau": "36",
        "diemCuoi": "37",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.019697856251923,
        "to_latitude": 21.019846827948182,
        "to_longitude": 105.80643900516525,
        "from_longitude": 105.80614530328276,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5043,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80643900516525,
          21.019846827948182,
          105.80667235735561,
          21.01996074737937
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80643900516525,
            21.019846827948182
          ],
          [
            105.80667235735561,
            21.01996074737937
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "diemDau": "37",
        "diemCuoi": "38",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.019846827948182,
        "to_latitude": 21.01996074737937,
        "to_longitude": 105.80667235735561,
        "from_longitude": 105.80643900516525,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5044,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80667235735561,
          21.01996074737937,
          105.80698617580514,
          21.02017105994742
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80667235735561,
            21.01996074737937
          ],
          [
            105.80698617580514,
            21.02017105994742
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "diemDau": "38",
        "diemCuoi": "39",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01996074737937,
        "to_latitude": 21.02017105994742,
        "to_longitude": 105.80698617580514,
        "from_longitude": 105.80667235735561,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5045,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80698617580514,
          21.02017105994742,
          105.80725003812425,
          21.020217065781704
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80698617580514,
            21.02017105994742
          ],
          [
            105.80725003812425,
            21.020217065781704
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "diemDau": "39",
        "diemCuoi": "40",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.02017105994742,
        "to_latitude": 21.020217065781704,
        "to_longitude": 105.80725003812425,
        "from_longitude": 105.80698617580514,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5046,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80725003812425,
          21.020091879804344,
          105.80752764675131,
          21.020217065781704
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80725003812425,
            21.020217065781704
          ],
          [
            105.80752764675131,
            21.020091879804344
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "diemDau": "40",
        "diemCuoi": "41",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.020217065781704,
        "to_latitude": 21.020091879804344,
        "to_longitude": 105.80752764675131,
        "from_longitude": 105.80725003812425,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5047,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80698617580514,
          21.02004556096516,
          105.80711726877816,
          21.02017105994742
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80698617580514,
            21.02017105994742
          ],
          [
            105.80711726877816,
            21.02004556096516
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 Pháo Đài Láng",
        "diemDau": "39",
        "diemCuoi": "39-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.02017105994742,
        "to_latitude": 21.02004556096516,
        "to_longitude": 105.80711726877816,
        "from_longitude": 105.80698617580514,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5048,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80697779391268,
          21.019845263118867,
          105.80711726877816,
          21.02004556096516
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80711726877816,
            21.02004556096516
          ],
          [
            105.80697779391268,
            21.019845263118867
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 Pháo Đài Láng",
        "diemDau": "39-1",
        "diemCuoi": "42",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.02004556096516,
        "to_latitude": 21.019845263118867,
        "to_longitude": 105.80697779391268,
        "from_longitude": 105.80711726877816,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5049,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80697779391268,
          21.01979518861569,
          105.80707703564406,
          21.019845263118867
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80697779391268,
            21.019845263118867
          ],
          [
            105.80707703564406,
            21.01979518861569
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 Pháo Đài Láng",
        "diemDau": "42",
        "diemCuoi": "42-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.019845263118867,
        "to_latitude": 21.01979518861569,
        "to_longitude": 105.80707703564406,
        "from_longitude": 105.80697779391268,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5050,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80684100125495,
          21.019678765329992,
          105.80697779391268,
          21.019845263118867
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80697779391268,
            21.019845263118867
          ],
          [
            105.80684100125495,
            21.019678765329992
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 Pháo Đài Láng",
        "diemDau": "42",
        "diemCuoi": "43",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.019845263118867,
        "to_latitude": 21.019678765329992,
        "to_longitude": 105.80684100125495,
        "from_longitude": 105.80697779391268,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5051,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8067457828352,
          21.01952603787164,
          105.80684100125495,
          21.019678765329992
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80684100125495,
            21.019678765329992
          ],
          [
            105.8067457828352,
            21.01952603787164
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 Pháo Đài Láng",
        "diemDau": "43",
        "diemCuoi": "44",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.019678765329992,
        "to_latitude": 21.01952603787164,
        "to_longitude": 105.8067457828352,
        "from_longitude": 105.80684100125495,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5052,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80664922331157,
          21.01952603787164,
          105.8067457828352,
          21.01956609754753
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8067457828352,
            21.01952603787164
          ],
          [
            105.80664922331157,
            21.01956609754753
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 Pháo Đài Láng",
        "diemDau": "44",
        "diemCuoi": "44-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01952603787164,
        "to_latitude": 21.01956609754753,
        "to_longitude": 105.80664922331157,
        "from_longitude": 105.8067457828352,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5053,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80614530328276,
          21.019606157213467,
          105.80620531771467,
          21.019697856251923
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80614530328276,
            21.019697856251923
          ],
          [
            105.80620531771467,
            21.019606157213467
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 63 Pháo Đài Láng",
        "diemDau": "36",
        "diemCuoi": "36-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.019697856251923,
        "to_latitude": 21.019606157213467,
        "to_longitude": 105.80620531771467,
        "from_longitude": 105.80614530328276,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5054,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80620531771467,
          21.019529793466663,
          105.80629383060608,
          21.019606157213467
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80620531771467,
            21.019606157213467
          ],
          [
            105.80622811648946,
            21.01956609754753
          ],
          [
            105.80629383060608,
            21.019529793466663
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 63 Pháo Đài Láng",
        "diemDau": "36-1",
        "diemCuoi": "36-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.019606157213467,
        "to_latitude": 21.019529793466663,
        "to_longitude": 105.80629383060608,
        "from_longitude": 105.80620531771467,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5055,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80571614983741,
          21.019319479994103,
          105.80586467716076,
          21.01952134337774
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80571614983741,
            21.01952134337774
          ],
          [
            105.80574800107007,
            21.019506008028834
          ],
          [
            105.80586467716076,
            21.019319479994103
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 81 Pháo Đài Láng",
        "diemDau": "35",
        "diemCuoi": "35-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01952134337774,
        "to_latitude": 21.019319479994103,
        "to_longitude": 105.80586467716076,
        "from_longitude": 105.80571614983741,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5056,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80571614983741,
          21.01952134337774,
          105.80590491030384,
          21.019720076829355
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80571614983741,
            21.01952134337774
          ],
          [
            105.80590491030384,
            21.01964246127657
          ],
          [
            105.8058794293213,
            21.019720076829355
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14 Pháo Đài Láng",
        "diemDau": "35",
        "diemCuoi": "45",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01952134337774,
        "to_latitude": 21.019720076829355,
        "to_longitude": 105.8058794293213,
        "from_longitude": 105.80571614983741,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5057,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80580835078023,
          21.019720076829355,
          105.8058794293213,
          21.019885322710675
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8058794293213,
            21.019720076829355
          ],
          [
            105.80580835078023,
            21.019885322710675
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14 Pháo Đài Láng",
        "diemDau": "45",
        "diemCuoi": "45-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.019720076829355,
        "to_latitude": 21.019885322710675,
        "to_longitude": 105.80580835078023,
        "from_longitude": 105.8058794293213,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5058,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80571313236943,
          21.019885322710675,
          105.80580835078023,
          21.020120672589208
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80580835078023,
            21.019885322710675
          ],
          [
            105.80571313236943,
            21.020120672589208
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14 Pháo Đài Láng",
        "diemDau": "45-1",
        "diemCuoi": "46",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.019885322710675,
        "to_latitude": 21.020120672589208,
        "to_longitude": 105.80571313236943,
        "from_longitude": 105.80580835078023,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5059,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80571179126557,
          21.020120672589208,
          105.80571313236943,
          21.020451163279926
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80571313236943,
            21.020120672589208
          ],
          [
            105.80571179126557,
            21.020451163279926
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14 Pháo Đài Láng",
        "diemDau": "46",
        "diemCuoi": "47",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.020120672589208,
        "to_latitude": 21.020451163279926,
        "to_longitude": 105.80571179126557,
        "from_longitude": 105.80571313236943,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5060,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8053724918245,
          21.02032722935686,
          105.80571179126557,
          21.020451163279926
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80571179126557,
            21.020451163279926
          ],
          [
            105.8053724918245,
            21.02032722935686
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14/25 Pháo Đài Láng",
        "diemDau": "47",
        "diemCuoi": "48",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.020451163279926,
        "to_latitude": 21.02032722935686,
        "to_longitude": 105.8053724918245,
        "from_longitude": 105.80571179126557,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5061,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83856133144448,
          21.017037933933018,
          105.83868453716828,
          21.017107028578177
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83856133144448,
            21.017107028578177
          ],
          [
            105.83868453716828,
            21.017037933933018
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "06",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.017107028578177,
        "to_latitude": 21.017037933933018,
        "to_longitude": 105.83868453716828,
        "from_longitude": 105.83856133144448,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5062,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83868453716828,
          21.0169334027929,
          105.83884386703443,
          21.017037933933018
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83868453716828,
            21.017037933933018
          ],
          [
            105.83884386703443,
            21.0169334027929
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-1",
        "diemCuoi": "6-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.017037933933018,
        "to_latitude": 21.0169334027929,
        "to_longitude": 105.83884386703443,
        "from_longitude": 105.83868453716828,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5063,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83874241025333,
          21.016755826462294,
          105.83884386703443,
          21.0169334027929
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83884386703443,
            21.0169334027929
          ],
          [
            105.83874241025333,
            21.016755826462294
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-2",
        "diemCuoi": "6-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.0169334027929,
        "to_latitude": 21.016755826462294,
        "to_longitude": 105.83874241025333,
        "from_longitude": 105.83884386703443,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5064,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83865946427215,
          21.016607058439025,
          105.83874241025333,
          21.016755826462294
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83874241025333,
            21.016755826462294
          ],
          [
            105.83865946427215,
            21.016607058439025
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-3",
        "diemCuoi": "6-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016755826462294,
        "to_latitude": 21.016607058439025,
        "to_longitude": 105.83865946427215,
        "from_longitude": 105.83874241025333,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5065,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83874241025333,
          21.01666620206027,
          105.83889426745314,
          21.016755826462294
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83874241025333,
            21.016755826462294
          ],
          [
            105.83889426745314,
            21.01666620206027
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-3",
        "diemCuoi": "6-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016755826462294,
        "to_latitude": 21.01666620206027,
        "to_longitude": 105.83889426745314,
        "from_longitude": 105.83874241025333,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5066,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83865946427215,
          21.01655282871358,
          105.83872316763212,
          21.016607058439025
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83865946427215,
            21.016607058439025
          ],
          [
            105.83872316763212,
            21.01655282871358
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-5",
        "diemCuoi": "6-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016607058439025,
        "to_latitude": 21.01655282871358,
        "to_longitude": 105.83872316763212,
        "from_longitude": 105.83865946427215,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5067,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83865946427215,
          21.016470203949343,
          105.83869804428687,
          21.016607058439025
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83865946427215,
            21.016607058439025
          ],
          [
            105.83869804428687,
            21.01653780603136
          ],
          [
            105.8386748878748,
            21.016470203949343
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-5",
        "diemCuoi": "6-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016607058439025,
        "to_latitude": 21.016470203949343,
        "to_longitude": 105.8386748878748,
        "from_longitude": 105.83865946427215,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5068,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83884386703443,
          21.016713070549113,
          105.83916573211619,
          21.0169334027929
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83884386703443,
            21.0169334027929
          ],
          [
            105.83916573211619,
            21.016713070549113
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-2",
        "diemCuoi": "6-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.0169334027929,
        "to_latitude": 21.016713070549113,
        "to_longitude": 105.83916573211619,
        "from_longitude": 105.83884386703443,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5069,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8390262572507,
          21.016713070549113,
          105.83916573211619,
          21.016788491390404
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83916573211619,
            21.016713070549113
          ],
          [
            105.83905555612452,
            21.016788491390404
          ],
          [
            105.8390262572507,
            21.016760642311112
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-9",
        "diemCuoi": "6-9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016713070549113,
        "to_latitude": 21.016760642311112,
        "to_longitude": 105.8390262572507,
        "from_longitude": 105.83916573211619,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5070,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83910940572665,
          21.016650476102715,
          105.83916573211619,
          21.016713070549113
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83916573211619,
            21.016713070549113
          ],
          [
            105.83910940572665,
            21.016650476102715
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-9",
        "diemCuoi": "6-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016713070549113,
        "to_latitude": 21.016650476102715,
        "to_longitude": 105.83910940572665,
        "from_longitude": 105.83916573211619,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5071,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83896724865342,
          21.016530294691357,
          105.83910940572665,
          21.016650476102715
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83910940572665,
            21.016650476102715
          ],
          [
            105.83896724865342,
            21.016530294691357
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-8",
        "diemCuoi": "6-8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016650476102715,
        "to_latitude": 21.016530294691357,
        "to_longitude": 105.83896724865342,
        "from_longitude": 105.83910940572665,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5072,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8390306381996,
          21.016432647223482,
          105.83912719772324,
          21.016650476102715
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83910940572665,
            21.016650476102715
          ],
          [
            105.8390306381996,
            21.01654782115243
          ],
          [
            105.83912719772324,
            21.016457685040933
          ],
          [
            105.8391147701601,
            21.016432647223482
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-8",
        "diemCuoi": "6-8-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016650476102715,
        "to_latitude": 21.016432647223482,
        "to_longitude": 105.8391147701601,
        "from_longitude": 105.83910940572665,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5073,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83899407074883,
          21.01628993157804,
          105.8391147701601,
          21.016432647223482
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8391147701601,
            21.016432647223482
          ],
          [
            105.83899407074883,
            21.01628993157804
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-8-2",
        "diemCuoi": "6-8-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016432647223482,
        "to_latitude": 21.01628993157804,
        "to_longitude": 105.83899407074883,
        "from_longitude": 105.8391147701601,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5074,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83916573211619,
          21.016713070549113,
          105.83930932223291,
          21.016863192925236
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83916573211619,
            21.016713070549113
          ],
          [
            105.83930932223291,
            21.016863192925236
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-9",
        "diemCuoi": "6-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016713070549113,
        "to_latitude": 21.016863192925236,
        "to_longitude": 105.83930932223291,
        "from_longitude": 105.83916573211619,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5075,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83916573211619,
          21.016713070549113,
          105.83933202908601,
          21.016803206506246
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83916573211619,
            21.016713070549113
          ],
          [
            105.8392881302686,
            21.016803206506246
          ],
          [
            105.83931227014726,
            21.01679068762578
          ],
          [
            105.83933202908601,
            21.016758138534893
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-9",
        "diemCuoi": "6-9-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016713070549113,
        "to_latitude": 21.016758138534893,
        "to_longitude": 105.83933202908601,
        "from_longitude": 105.83916573211619,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5076,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83930932223291,
          21.016863192925236,
          105.83941517756193,
          21.016963448074424
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83930932223291,
            21.016863192925236
          ],
          [
            105.83941517756193,
            21.016963448074424
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-10",
        "diemCuoi": "6-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016863192925236,
        "to_latitude": 21.016963448074424,
        "to_longitude": 105.83941517756193,
        "from_longitude": 105.83930932223291,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5077,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83941517756193,
          21.01687080843898,
          105.83950905487782,
          21.016963448074424
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83941517756193,
            21.016963448074424
          ],
          [
            105.83950905487782,
            21.01687080843898
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-11",
        "diemCuoi": "6-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016963448074424,
        "to_latitude": 21.01687080843898,
        "to_longitude": 105.83950905487782,
        "from_longitude": 105.83941517756193,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5078,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83950905487782,
          21.01687080843898,
          105.83958156754929,
          21.0169452660499
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83950905487782,
            21.01687080843898
          ],
          [
            105.83958156754929,
            21.0169452660499
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-12",
        "diemCuoi": "6-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.01687080843898,
        "to_latitude": 21.0169452660499,
        "to_longitude": 105.83958156754929,
        "from_longitude": 105.83950905487782,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5079,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83958156754929,
          21.0169452660499,
          105.83960024998598,
          21.01699349334909
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83958156754929,
            21.0169452660499
          ],
          [
            105.83960024998598,
            21.01699349334909
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-13",
        "diemCuoi": "6-13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.0169452660499,
        "to_latitude": 21.01699349334909,
        "to_longitude": 105.83960024998598,
        "from_longitude": 105.83958156754929,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5080,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83960024998598,
          21.01699349334909,
          105.83963780091231,
          21.017056087651497
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83960024998598,
            21.01699349334909
          ],
          [
            105.83963780091231,
            21.017056087651497
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-13-1",
        "diemCuoi": "6-13-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.01699349334909,
        "to_latitude": 21.017056087651497,
        "to_longitude": 105.83963780091231,
        "from_longitude": 105.83960024998598,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5081,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83920511243055,
          21.016963448074424,
          105.83941517756193,
          21.017134104057398
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83941517756193,
            21.016963448074424
          ],
          [
            105.83920511243055,
            21.017134104057398
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-11",
        "diemCuoi": "6-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.016963448074424,
        "to_latitude": 21.017134104057398,
        "to_longitude": 105.83920511243055,
        "from_longitude": 105.83941517756193,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5082,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83899894342653,
          21.017134104057398,
          105.83920511243055,
          21.01734965458079
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83920511243055,
            21.017134104057398
          ],
          [
            105.83899894342653,
            21.01734965458079
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-14",
        "diemCuoi": "6-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.017134104057398,
        "to_latitude": 21.01734965458079,
        "to_longitude": 105.83899894342653,
        "from_longitude": 105.83920511243055,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5083,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83888182768342,
          21.017251762899306,
          105.83899894342653,
          21.01734965458079
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83899894342653,
            21.01734965458079
          ],
          [
            105.83888182768342,
            21.017251762899306
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-15",
        "diemCuoi": "6-16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.01734965458079,
        "to_latitude": 21.017251762899306,
        "to_longitude": 105.83888182768342,
        "from_longitude": 105.83899894342653,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5084,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83899894342653,
          21.01734965458079,
          105.83921263009557,
          21.0175414530085
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83899894342653,
            21.01734965458079
          ],
          [
            105.83921263009557,
            21.0175414530085
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-15",
        "diemCuoi": "6-17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.01734965458079,
        "to_latitude": 21.0175414530085,
        "to_longitude": 105.83921263009557,
        "from_longitude": 105.83899894342653,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5085,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83883398756954,
          21.01734965458079,
          105.83899894342653,
          21.017446049565415
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83899894342653,
            21.01734965458079
          ],
          [
            105.83883398756954,
            21.017446049565415
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-15",
        "diemCuoi": "6-15-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.01734965458079,
        "to_latitude": 21.017446049565415,
        "to_longitude": 105.83883398756954,
        "from_longitude": 105.83899894342653,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5086,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83921263009557,
          21.017442039217464,
          105.8393220805007,
          21.0175414530085
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83921263009557,
            21.0175414530085
          ],
          [
            105.8393220805007,
            21.017442039217464
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-17",
        "diemCuoi": "6-18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.0175414530085,
        "to_latitude": 21.017442039217464,
        "to_longitude": 105.8393220805007,
        "from_longitude": 105.83921263009557,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5087,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8393220805007,
          21.017324616911424,
          105.83942005023965,
          21.017442039217464
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8393220805007,
            21.017442039217464
          ],
          [
            105.83942005023965,
            21.017324616911424
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-18",
        "diemCuoi": "6-19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.017442039217464,
        "to_latitude": 21.017324616911424,
        "to_longitude": 105.83942005023965,
        "from_longitude": 105.8393220805007,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5088,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83942005023965,
          21.017228221848278,
          105.83960646376755,
          21.017324616911424
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83942005023965,
            21.017324616911424
          ],
          [
            105.83960646376755,
            21.017228221848278
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-19",
        "diemCuoi": "6-20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.017324616911424,
        "to_latitude": 21.017228221848278,
        "to_longitude": 105.83960646376755,
        "from_longitude": 105.83942005023965,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5089,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80528934333958,
          21.02024585854237,
          105.8053724918245,
          21.020327229356884
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8053724918245,
            21.020327229356884
          ],
          [
            105.80528934333958,
            21.02024585854237
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14/25 Pháo Đài Láng",
        "diemDau": "48",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.020327229356884,
        "to_latitude": 21.02024585854237,
        "to_longitude": 105.80528934333958,
        "from_longitude": 105.8053724918245,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5090,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80495943163461,
          21.020110657707225,
          105.80528934333958,
          21.02024585854237
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80528934333958,
            21.02024585854237
          ],
          [
            105.80495943163461,
            21.020110657707225
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14/25 Pháo Đài Láng",
        "diemDau": "",
        "diemCuoi": "49",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.02024585854237,
        "to_latitude": 21.020110657707225,
        "to_longitude": 105.80495943163461,
        "from_longitude": 105.80528934333958,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5091,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80528934333958,
          21.020039301662017,
          105.80541138384575,
          21.02024585854237
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80528934333958,
            21.02024585854237
          ],
          [
            105.80541138384575,
            21.020039301662017
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14/25 Pháo Đài Láng",
        "diemDau": "",
        "diemCuoi": "50",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.02024585854237,
        "to_latitude": 21.020039301662017,
        "to_longitude": 105.80541138384575,
        "from_longitude": 105.80528934333958,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5092,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80521021817526,
          21.019912863672523,
          105.80541138384575,
          21.020039301662017
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80541138384575,
            21.020039301662017
          ],
          [
            105.80521021817526,
            21.019912863672523
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14/25 Pháo Đài Láng",
        "diemDau": "50",
        "diemCuoi": "51",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.020039301662017,
        "to_latitude": 21.019912863672523,
        "to_longitude": 105.80521021817526,
        "from_longitude": 105.80541138384575,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5093,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8052088770624,
          21.020327229356884,
          105.8053724918245,
          21.02063643804296
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8053724918245,
            21.020327229356884
          ],
          [
            105.8052088770624,
            21.02063643804296
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14/25 Pháo Đài Láng",
        "diemDau": "48",
        "diemCuoi": "52",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.020327229356884,
        "to_latitude": 21.02063643804296,
        "to_longitude": 105.8052088770624,
        "from_longitude": 105.8053724918245,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5094,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80494467947406,
          21.020516259844072,
          105.8052088770624,
          21.02063643804296
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8052088770624,
            21.02063643804296
          ],
          [
            105.80494467947406,
            21.020516259844072
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14/31 Pháo Đài Láng",
        "diemDau": "52",
        "diemCuoi": "53",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.02063643804296,
        "to_latitude": 21.020516259844072,
        "to_longitude": 105.80494467947406,
        "from_longitude": 105.8052088770624,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5095,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8052088770624,
          21.02050874870363,
          105.80524508688491,
          21.02063643804296
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8052088770624,
            21.02063643804296
          ],
          [
            105.80524508688491,
            21.02050874870363
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14/25 Pháo Đài Láng",
        "diemDau": "52",
        "diemCuoi": "52-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.02063643804296,
        "to_latitude": 21.02050874870363,
        "to_longitude": 105.80524508688491,
        "from_longitude": 105.8052088770624,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5096,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8052088770624,
          21.02063643804296,
          105.80548514459458,
          21.020791668073322
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8052088770624,
            21.02063643804296
          ],
          [
            105.80548514459458,
            21.020791668073322
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14/31 Pháo Đài Láng",
        "diemDau": "52",
        "diemCuoi": "54",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.02063643804296,
        "to_latitude": 21.020791668073322,
        "to_longitude": 105.80548514459458,
        "from_longitude": 105.8052088770624,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5097,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80548514459458,
          21.020646452888773,
          105.80556426975889,
          21.020791668073322
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80548514459458,
            21.020791668073322
          ],
          [
            105.80556426975889,
            21.020646452888773
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14/31 Pháo Đài Láng",
        "diemDau": "54",
        "diemCuoi": "56",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.020791668073322,
        "to_latitude": 21.020646452888773,
        "to_longitude": 105.80556426975889,
        "from_longitude": 105.80548514459458,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5098,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80556426975889,
          21.020617660206284,
          105.80570508573722,
          21.020673993710883
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80556426975889,
            21.020646452888773
          ],
          [
            105.80558840963756,
            21.020617660206284
          ],
          [
            105.80570508573722,
            21.020673993710883
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14/31 Pháo Đài Láng",
        "diemDau": "56",
        "diemCuoi": "55",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.020646452888773,
        "to_latitude": 21.020673993710883,
        "to_longitude": 105.80570508573722,
        "from_longitude": 105.80556426975889,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5099,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80504693869138,
          21.0190027573598,
          105.80522362922295,
          21.019144218540507
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80504693869138,
            21.019139524034596
          ],
          [
            105.80511902306712,
            21.019144218540507
          ],
          [
            105.80522362922295,
            21.0190027573598
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 103 Pháo Đài Láng",
        "diemDau": "33",
        "diemCuoi": "33-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.019139524034596,
        "to_latitude": 21.0190027573598,
        "to_longitude": 105.80522362922295,
        "from_longitude": 105.80504693869138,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5100,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80522362922295,
          21.01890385964041,
          105.80542211268569,
          21.019017779792033
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80522362922295,
            21.0190027573598
          ],
          [
            105.80531214211436,
            21.019017779792033
          ],
          [
            105.80542211268569,
            21.01890385964041
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 103 Pháo Đài Láng",
        "diemDau": "33-1",
        "diemCuoi": "33-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.0190027573598,
        "to_latitude": 21.01890385964041,
        "to_longitude": 105.80542211268569,
        "from_longitude": 105.80522362922295,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5101,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80521558259078,
          21.018724842082293,
          105.80542211268569,
          21.01890385964041
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80542211268569,
            21.01890385964041
          ],
          [
            105.80521558259078,
            21.018724842082293
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 103 Pháo Đài Láng",
        "diemDau": "33-2",
        "diemCuoi": "33-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01890385964041,
        "to_latitude": 21.018724842082293,
        "to_longitude": 105.80521558259078,
        "from_longitude": 105.80542211268569,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5102,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80542211268569,
          21.01869980464466,
          105.80557097528725,
          21.01890385964041
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80542211268569,
            21.01890385964041
          ],
          [
            105.80557097528725,
            21.01869980464466
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 103 Pháo Đài Láng",
        "diemDau": "33-2",
        "diemCuoi": "33-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01890385964041,
        "to_latitude": 21.01869980464466,
        "to_longitude": 105.80557097528725,
        "from_longitude": 105.80542211268569,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5103,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83921263009557,
          21.0175414530085,
          105.83933339175304,
          21.017687486619796
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83921263009557,
            21.0175414530085
          ],
          [
            105.83933339175304,
            21.017687486619796
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "diemDau": "6-17",
        "diemCuoi": "6-17-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.0175414530085,
        "to_latitude": 21.017687486619796,
        "to_longitude": 105.83933339175304,
        "from_longitude": 105.83921263009557,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5104,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80542211268569,
          21.018887585326056,
          105.80563266609227,
          21.018943919483444
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80542211268569,
            21.0189038596404
          ],
          [
            105.80553074215314,
            21.018943919483444
          ],
          [
            105.80563266609227,
            21.018887585326056
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 103 Pháo Đài Láng",
        "diemDau": "33-2",
        "diemCuoi": "33-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.0189038596404,
        "to_latitude": 21.018887585326056,
        "to_longitude": 105.80563266609227,
        "from_longitude": 105.80542211268569,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5105,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80563266609227,
          21.01873110144146,
          105.80583919618721,
          21.01894266761408
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80563266609227,
            21.018887585326056
          ],
          [
            105.80573056671976,
            21.01894266761408
          ],
          [
            105.80583919618721,
            21.01873110144146
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 103 Pháo Đài Láng",
        "diemDau": "33-4",
        "diemCuoi": "33-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.018887585326056,
        "to_latitude": 21.01873110144146,
        "to_longitude": 105.80583919618721,
        "from_longitude": 105.80563266609227,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5106,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80583919618721,
          21.018554587422855,
          105.80598939989262,
          21.01873110144146
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80583919618721,
            21.01873110144146
          ],
          [
            105.80598939989262,
            21.018554587422855
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 103 Pháo Đài Láng",
        "diemDau": "33-5",
        "diemCuoi": "33-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01873110144146,
        "to_latitude": 21.018554587422855,
        "to_longitude": 105.80598939989262,
        "from_longitude": 105.80583919618721,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5107,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.804090395886,
          21.017608168271263,
          105.80426742172276,
          21.0178817040544
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.804090395886,
            21.0178817040544
          ],
          [
            105.80426742172276,
            21.017608168271263
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "diemDau": "01",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.0178817040544,
        "to_latitude": 21.017608168271263,
        "to_longitude": 105.80426742172276,
        "from_longitude": 105.804090395886,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5108,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80426742172276,
          21.01734527299617,
          105.80451284384795,
          21.017608168271263
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80426742172276,
            21.017608168271263
          ],
          [
            105.80451284384795,
            21.01734527299617
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.017608168271263,
        "to_latitude": 21.01734527299617,
        "to_longitude": 105.80451284384795,
        "from_longitude": 105.80426742172276,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5109,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80451284384795,
          21.017110231709605,
          105.8047331202598,
          21.01734527299617
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80451284384795,
            21.01734527299617
          ],
          [
            105.8047331202598,
            21.017110231709605
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01734527299617,
        "to_latitude": 21.017110231709605,
        "to_longitude": 105.8047331202598,
        "from_longitude": 105.80451284384795,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5110,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8047331202598,
          21.016912433694145,
          105.80493026262765,
          21.017110231709605
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8047331202598,
            21.017110231709605
          ],
          [
            105.80493026262765,
            21.016912433694145
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.017110231709605,
        "to_latitude": 21.016912433694145,
        "to_longitude": 105.80493026262765,
        "from_longitude": 105.8047331202598,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5111,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80493026262765,
          21.01668459008632,
          105.80516897923347,
          21.016912433694145
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80493026262765,
            21.016912433694145
          ],
          [
            105.80516897923347,
            21.01668459008632
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.016912433694145,
        "to_latitude": 21.01668459008632,
        "to_longitude": 105.80516897923347,
        "from_longitude": 105.80493026262765,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5112,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80516897923347,
          21.01640291481564,
          105.80544658786951,
          21.016684590086335
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80516897923347,
            21.016684590086335
          ],
          [
            105.80518775469668,
            21.016629506964204
          ],
          [
            105.80544658786951,
            21.01640291481564
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "diemDau": "07",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.016684590086335,
        "to_latitude": 21.01640291481564,
        "to_longitude": 105.80544658786951,
        "from_longitude": 105.80516897923347,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5113,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80527760870092,
          21.01622890182721,
          105.80544658786951,
          21.01640291481564
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80544658786951,
            21.01640291481564
          ],
          [
            105.80527760870092,
            21.01622890182721
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "diemDau": "7-1",
        "diemCuoi": "7-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01640291481564,
        "to_latitude": 21.01622890182721,
        "to_longitude": 105.80527760870092,
        "from_longitude": 105.80544658786951,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5114,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80485247858519,
          21.016752192072815,
          105.80497317798746,
          21.016912433694145
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80493026262765,
            21.016912433694145
          ],
          [
            105.80497317798746,
            21.016809778925012
          ],
          [
            105.80485247858519,
            21.016752192072815
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "diemDau": "06",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.016912433694145,
        "to_latitude": 21.016752192072815,
        "to_longitude": 105.80485247858519,
        "from_longitude": 105.80493026262765,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5115,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80431737788012,
          21.017182841040633,
          105.80451284384795,
          21.01734527299617
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80451284384795,
            21.01734527299617
          ],
          [
            105.80431737788012,
            21.017182841040633
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01734527299617,
        "to_latitude": 21.017182841040633,
        "to_longitude": 105.80431737788012,
        "from_longitude": 105.80451284384795,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5116,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.838472264191,
          21.01740007982971,
          105.83869538470564,
          21.01748885964408
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83869538470564,
            21.01740007982971
          ],
          [
            105.838472264191,
            21.01748885964408
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 80 Phố Chợ Khâm Thiên",
        "diemDau": "07",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.01740007982971,
        "to_latitude": 21.01748885964408,
        "to_longitude": 105.838472264191,
        "from_longitude": 105.83869538470564,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5117,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8382150300634,
          21.01748885964408,
          105.838472264191,
          21.017563874547808
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.838472264191,
            21.01748885964408
          ],
          [
            105.8382150300634,
            21.017563874547808
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 80 Phố Chợ Khâm Thiên",
        "diemDau": "7-1",
        "diemCuoi": "7-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.01748885964408,
        "to_latitude": 21.017563874547808,
        "to_longitude": 105.8382150300634,
        "from_longitude": 105.838472264191,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5118,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83815650641621,
          21.01744041609582,
          105.8382150300634,
          21.017563874547808
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8382150300634,
            21.017563874547808
          ],
          [
            105.83815650641621,
            21.01744041609582
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 80 Phố Chợ Khâm Thiên",
        "diemDau": "7-2",
        "diemCuoi": "7-2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.017563874547808,
        "to_latitude": 21.01744041609582,
        "to_longitude": 105.83815650641621,
        "from_longitude": 105.8382150300634,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5119,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83802353950713,
          21.017563874547808,
          105.8382150300634,
          21.017673401328636
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8382150300634,
            21.017563874547808
          ],
          [
            105.83802353950713,
            21.017673401328636
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 80 Phố Chợ Khâm Thiên",
        "diemDau": "7-2",
        "diemCuoi": "7-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.017563874547808,
        "to_latitude": 21.017673401328636,
        "to_longitude": 105.83802353950713,
        "from_longitude": 105.8382150300634,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5120,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83785149427604,
          21.017673401328636,
          105.83802353950713,
          21.017780197624276
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83802353950713,
            21.017673401328636
          ],
          [
            105.83785149427604,
            21.017780197624276
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 80 Phố Chợ Khâm Thiên",
        "diemDau": "7-3",
        "diemCuoi": "7-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.017673401328636,
        "to_latitude": 21.017780197624276,
        "to_longitude": 105.83785149427604,
        "from_longitude": 105.83802353950713,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5121,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.837618862045,
          21.017780197624276,
          105.83785149427604,
          21.017923088776403
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83785149427604,
            21.017780197624276
          ],
          [
            105.837618862045,
            21.017923088776403
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 80 Phố Chợ Khâm Thiên",
        "diemDau": "7-4",
        "diemCuoi": "7-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.017780197624276,
        "to_latitude": 21.017923088776403,
        "to_longitude": 105.837618862045,
        "from_longitude": 105.83785149427604,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5122,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83740220068647,
          21.017923088776403,
          105.837618862045,
          21.018042354895613
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.837618862045,
            21.017923088776403
          ],
          [
            105.83740220068647,
            21.018042354895613
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 80 Phố Chợ Khâm Thiên",
        "diemDau": "7-5",
        "diemCuoi": "00",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.017923088776403,
        "to_latitude": 21.018042354895613,
        "to_longitude": 105.83740220068647,
        "from_longitude": 105.837618862045,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5123,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83726064860817,
          21.018042354895613,
          105.83740220068647,
          21.0181514836986
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83740220068647,
            21.018042354895613
          ],
          [
            105.83726064860817,
            21.0181514836986
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CN10",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 80 Phố Chợ Khâm Thiên",
        "diemDau": "00",
        "diemCuoi": "00",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CN10",
        "from_latitude": 21.018042354895613,
        "to_latitude": 21.0181514836986,
        "to_longitude": 105.83726064860817,
        "from_longitude": 105.83740220068647,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5124,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82060877998168,
          21.00275135470564,
          105.82073216160067,
          21.002773890808395
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82073216160067,
            21.002773890808395
          ],
          [
            105.82060877998168,
            21.00275135470564
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nút GT NTS",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.002773890808395,
        "to_latitude": 21.00275135470564,
        "to_longitude": 105.82060877998168,
        "from_longitude": 105.82073216160067,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5125,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82052563150575,
          21.002701274463163,
          105.82060877998168,
          21.00275135470564
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82060877998168,
            21.00275135470564
          ],
          [
            105.82052563150575,
            21.002701274463163
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nút GT NTS",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.00275135470564,
        "to_latitude": 21.002701274463163,
        "to_longitude": 105.82052563150575,
        "from_longitude": 105.82060877998168,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5126,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82042471340127,
          21.00266903529833,
          105.82052563150575,
          21.002701274463163
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82052563150575,
            21.002701274463163
          ],
          [
            105.82042471340127,
            21.00266903529833
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nút GT NTS",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.002701274463163,
        "to_latitude": 21.00266903529833,
        "to_longitude": 105.82042471340127,
        "from_longitude": 105.82052563150575,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5127,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82031105478757,
          21.002623650054932,
          105.82042471340127,
          21.00266903529833
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82042471340127,
            21.00266903529833
          ],
          [
            105.82031105478757,
            21.002623650054932
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nút GT NTS",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.00266903529833,
        "to_latitude": 21.002623650054932,
        "to_longitude": 105.82031105478757,
        "from_longitude": 105.82042471340127,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5128,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82021181304722,
          21.0025910978721,
          105.82031105478757,
          21.002623650054932
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82031105478757,
            21.002623650054932
          ],
          [
            105.82021181304722,
            21.0025910978721
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nút GT NTS",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.002623650054932,
        "to_latitude": 21.0025910978721,
        "to_longitude": 105.82021181304722,
        "from_longitude": 105.82031105478757,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5129,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82036201675264,
          21.002692510419894,
          105.82043510696302,
          21.002869043207497
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82043510696302,
            21.002692510419894
          ],
          [
            105.82036201675264,
            21.002869043207497
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nút GT NTS",
        "diemDau": "01",
        "diemCuoi": "tủ điện",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.002692510419894,
        "to_latitude": 21.002869043207497,
        "to_longitude": 105.82036201675264,
        "from_longitude": 105.82043510696302,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5130,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82043510696302,
          21.002692510419894,
          105.82057391127205,
          21.00310191592815
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82043510696302,
            21.002692510419894
          ],
          [
            105.82057391127205,
            21.00310191592815
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": 60,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nút GT NTS",
        "diemDau": "02",
        "diemCuoi": "tủ điện",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.002692510419894,
        "to_latitude": 21.00310191592815,
        "to_longitude": 105.82057391127205,
        "from_longitude": 105.82043510696302,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5131,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82013402898674,
          21.003427436326103,
          105.82028222107678,
          21.003475012327364
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82028222107678,
            21.003475012327364
          ],
          [
            105.82013402898674,
            21.003427436326103
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn hoa Ngã Tư Sở",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.003475012327364,
        "to_latitude": 21.003427436326103,
        "to_longitude": 105.82013402898674,
        "from_longitude": 105.82028222107678,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5132,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82005289219808,
          21.00337610431299,
          105.82013402898674,
          21.003427436326103
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82013402898674,
            21.003427436326103
          ],
          [
            105.82011324185432,
            21.003391754317246
          ],
          [
            105.82005289219808,
            21.00337610431299
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn hoa Ngã Tư Sở",
        "diemDau": "15",
        "diemCuoi": "tủ phân doạn",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.003427436326103,
        "to_latitude": 21.00337610431299,
        "to_longitude": 105.82005289219808,
        "from_longitude": 105.82013402898674,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5133,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81919927912156,
          21.003110679947337,
          105.81930120306964,
          21.003144484020467
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81930120306964,
            21.003110679947337
          ],
          [
            105.81919927912156,
            21.003144484020467
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nút GT NTS",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.003110679947337,
        "to_latitude": 21.003144484020467,
        "to_longitude": 105.81919927912156,
        "from_longitude": 105.81930120306964,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5134,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81930120306964,
          21.003055591813098,
          105.81936021166692,
          21.003110679947337
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81930120306964,
            21.003110679947337
          ],
          [
            105.81936021166692,
            21.003055591813098
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nút GT NTS",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.003110679947337,
        "to_latitude": 21.003055591813098,
        "to_longitude": 105.81936021166692,
        "from_longitude": 105.81930120306964,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5135,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81927035766265,
          21.003011771692346,
          105.81930120306964,
          21.003110679947337
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81930120306964,
            21.003110679947337
          ],
          [
            105.81927035766265,
            21.003011771692346
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nút GT NTS",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.003110679947337,
        "to_latitude": 21.003011771692346,
        "to_longitude": 105.81927035766265,
        "from_longitude": 105.81930120306964,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5136,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81918855029058,
          21.003011771692346,
          105.81927035766265,
          21.003058095820148
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81927035766265,
            21.003011771692346
          ],
          [
            105.81918855029058,
            21.003058095820148
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nút GT NTS",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.003011771692346,
        "to_latitude": 21.003058095820148,
        "to_longitude": 105.81918855029058,
        "from_longitude": 105.81927035766265,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5137,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81925426440722,
          21.002929139428172,
          105.81927035766265,
          21.003011771692346
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81927035766265,
            21.003011771692346
          ],
          [
            105.81925426440722,
            21.002929139428172
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nút GT NTS",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.003011771692346,
        "to_latitude": 21.002929139428172,
        "to_longitude": 105.81925426440722,
        "from_longitude": 105.81927035766265,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5138,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81925426440722,
          21.002894083302223,
          105.81931059079675,
          21.002929139428172
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81925426440722,
            21.002929139428172
          ],
          [
            105.81931059079675,
            21.002894083302223
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nút GT NTS",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.002929139428172,
        "to_latitude": 21.002894083302223,
        "to_longitude": 105.81931059079675,
        "from_longitude": 105.81925426440722,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5139,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8192904742297,
          21.002835239072784,
          105.81931059079675,
          21.002894083302223
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81931059079675,
            21.002894083302223
          ],
          [
            105.8192904742297,
            21.002835239072784
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nút GT NTS",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.002894083302223,
        "to_latitude": 21.002835239072784,
        "to_longitude": 105.8192904742297,
        "from_longitude": 105.81931059079675,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5140,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81923683004791,
          21.00276763077962,
          105.8192904742297,
          21.002835239072784
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8192904742297,
            21.002835239072784
          ],
          [
            105.81923683004791,
            21.00276763077962
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nút GT NTS",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.002835239072784,
        "to_latitude": 21.00276763077962,
        "to_longitude": 105.81923683004791,
        "from_longitude": 105.8192904742297,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5141,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81922476011307,
          21.002831483056603,
          105.8192904742297,
          21.002835239072784
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8192904742297,
            21.002835239072784
          ],
          [
            105.81922476011307,
            21.002831483056603
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nút GT NTS",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.002835239072784,
        "to_latitude": 21.002831483056603,
        "to_longitude": 105.81922476011307,
        "from_longitude": 105.8192904742297,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5142,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82071305086829,
          21.003146988026018,
          105.82074557264839,
          21.003212405142
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82071305086829,
            21.003212405142
          ],
          [
            105.82074557264839,
            21.003146988026018
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn hoa Ngã Tư Sở",
        "diemDau": "11",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.003212405142,
        "to_latitude": 21.003146988026018,
        "to_longitude": 105.82074557264839,
        "from_longitude": 105.82071305086829,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5143,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82066510637121,
          21.00312194797285,
          105.82074557264839,
          21.003146988026018
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82074557264839,
            21.003146988026018
          ],
          [
            105.82066510637121,
            21.00312194797285
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn hoa Ngã Tư Sở",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.003146988026018,
        "to_latitude": 21.00312194797285,
        "to_longitude": 105.82066510637121,
        "from_longitude": 105.82074557264839,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5144,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82068455238182,
          21.00306122582846,
          105.82074825485613,
          21.003085013887702
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82074825485613,
            21.00306685984277
          ],
          [
            105.8207127155721,
            21.00306122582846
          ],
          [
            105.82068455238182,
            21.003085013887702
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn hoa Ngã Tư Sở",
        "diemDau": "tủ phân đoạn",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.00306685984277,
        "to_latitude": 21.003085013887702,
        "to_longitude": 105.82068455238182,
        "from_longitude": 105.82074825485613,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5145,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82013134677902,
          21.002692510419894,
          105.82054306583811,
          21.003272188224646
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82043510696302,
            21.002692510419894
          ],
          [
            105.82054306583811,
            21.003083135883173
          ],
          [
            105.82013134677902,
            21.003272188224646
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": 100,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nút GT NTS",
        "diemDau": "03",
        "diemCuoi": "tủ điện",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.002692510419894,
        "to_latitude": 21.003272188224646,
        "to_longitude": 105.82013134677902,
        "from_longitude": 105.82043510696302,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5146,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82009471552088,
          21.02340426537308,
          105.82014073450613,
          21.023615199002087
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82013268783798,
            21.023615199002087
          ],
          [
            105.82009471552088,
            21.02350110472412
          ],
          [
            105.82014073450613,
            21.02340426537308
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.023615199002087,
        "to_latitude": 21.02340426537308,
        "to_longitude": 105.82014073450613,
        "from_longitude": 105.82013268783798,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5147,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82014073450613,
          21.02331413340628,
          105.82042504866152,
          21.02340426537308
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82014073450613,
            21.02340426537308
          ],
          [
            105.82042504866152,
            21.02331413340628
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02340426537308,
        "to_latitude": 21.02331413340628,
        "to_longitude": 105.82042504866152,
        "from_longitude": 105.82014073450613,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5148,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82042504866152,
          21.023244030728335,
          105.82074423153553,
          21.02331413340628
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82042504866152,
            21.02331413340628
          ],
          [
            105.82074423153553,
            21.023244030728335
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02331413340628,
        "to_latitude": 21.023244030728335,
        "to_longitude": 105.82074423153553,
        "from_longitude": 105.82042504866152,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5149,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82074423153553,
          21.023173615061065,
          105.82111739391446,
          21.023244030728335
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82074423153553,
            21.023244030728335
          ],
          [
            105.82111739391446,
            21.023173615061065
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.023244030728335,
        "to_latitude": 21.023173615061065,
        "to_longitude": 105.82111739391446,
        "from_longitude": 105.82074423153553,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5150,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82111739391446,
          21.023173615061065,
          105.82119484266974,
          21.023218994050424
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82111739391446,
            21.023173615061065
          ],
          [
            105.82116491691302,
            21.023178131801334
          ],
          [
            105.82119484266974,
            21.023218994050424
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "446 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.023173615061065,
        "to_latitude": 21.023218994050424,
        "to_longitude": 105.82119484266974,
        "from_longitude": 105.82111739391446,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5151,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82119484266974,
          21.023218994050424,
          105.82129140219337,
          21.023359199397767
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82119484266974,
            21.023218994050424
          ],
          [
            105.82129140219337,
            21.023359199397767
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "446 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.023218994050424,
        "to_latitude": 21.023359199397767,
        "to_longitude": 105.82129140219337,
        "from_longitude": 105.82119484266974,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5152,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82129140219337,
          21.023359199397767,
          105.82136650404608,
          21.02346184965867
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82129140219337,
            21.023359199397767
          ],
          [
            105.82136650404608,
            21.02346184965867
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "446 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.023359199397767,
        "to_latitude": 21.02346184965867,
        "to_longitude": 105.82136650404608,
        "from_longitude": 105.82129140219337,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5153,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82136650404608,
          21.02346184965867,
          105.821449652531,
          21.02351943391955
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82136650404608,
            21.02346184965867
          ],
          [
            105.821449652531,
            21.02351943391955
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "446 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02346184965867,
        "to_latitude": 21.02351943391955,
        "to_longitude": 105.821449652531,
        "from_longitude": 105.82136650404608,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5154,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.821449652531,
          21.02351943391955,
          105.82148141757929,
          21.02359123657788
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.821449652531,
            21.02351943391955
          ],
          [
            105.82148141757929,
            21.02359123657788
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "446 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02351943391955,
        "to_latitude": 21.02359123657788,
        "to_longitude": 105.82148141757929,
        "from_longitude": 105.821449652531,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5155,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82148141757929,
          21.02359123657788,
          105.82152475438372,
          21.02366965362704
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82148141757929,
            21.02359123657788
          ],
          [
            105.82152475438372,
            21.02366965362704
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "446 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02359123657788,
        "to_latitude": 21.02366965362704,
        "to_longitude": 105.82152475438372,
        "from_longitude": 105.82148141757929,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5156,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82111739391446,
          21.02305594257781,
          105.82152240747214,
          21.023173615061065
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82111739391446,
            21.023173615061065
          ],
          [
            105.82152240747214,
            21.02305594257781
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.023173615061065,
        "to_latitude": 21.02305594257781,
        "to_longitude": 105.82152240747214,
        "from_longitude": 105.82111739391446,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5157,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82099904141475,
          21.023076284900288,
          105.82111739391446,
          21.023173615061065
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82111739391446,
            21.023173615061065
          ],
          [
            105.82099904141475,
            21.023076284900288
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.023173615061065,
        "to_latitude": 21.023076284900288,
        "to_longitude": 105.82099904141475,
        "from_longitude": 105.82111739391446,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5158,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82079955213536,
          21.022888196535593,
          105.82099904141475,
          21.023076284900288
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82099904141475,
            21.023076284900288
          ],
          [
            105.82079955213536,
            21.022888196535593
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.023076284900288,
        "to_latitude": 21.022888196535593,
        "to_longitude": 105.82079955213536,
        "from_longitude": 105.82099904141475,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5159,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82067885273304,
          21.022722953983283,
          105.82079955213536,
          21.022888196535593
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82079955213536,
            21.022888196535593
          ],
          [
            105.82067885273304,
            21.022722953983283
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022888196535593,
        "to_latitude": 21.022722953983283,
        "to_longitude": 105.82067885273304,
        "from_longitude": 105.82079955213536,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5160,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82055379471397,
          21.022520469014612,
          105.82067885273304,
          21.022722953983283
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82067885273304,
            21.022722953983283
          ],
          [
            105.82059628860222,
            21.022644849396123
          ],
          [
            105.82055379471397,
            21.022520469014612
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022722953983283,
        "to_latitude": 21.022520469014612,
        "to_longitude": 105.82055379471397,
        "from_longitude": 105.82067885273304,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5161,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82055379471397,
          21.02247459921196,
          105.82057751313903,
          21.022520469014612
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82055379471397,
            21.022520469014612
          ],
          [
            105.82057751313903,
            21.02247459921196
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022520469014612,
        "to_latitude": 21.02247459921196,
        "to_longitude": 105.82057751313903,
        "from_longitude": 105.82055379471397,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5162,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82057751313903,
          21.022402796015818,
          105.82083810886938,
          21.02247459921196
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82057751313903,
            21.02247459921196
          ],
          [
            105.82083810886938,
            21.022402796015818
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02247459921196,
        "to_latitude": 21.022402796015818,
        "to_longitude": 105.82083810886938,
        "from_longitude": 105.82057751313903,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5163,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82083810886938,
          21.022297641343318,
          105.82116265615888,
          21.022402796015818
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82083810886938,
            21.022402796015818
          ],
          [
            105.82116265615888,
            21.022297641343318
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022402796015818,
        "to_latitude": 21.022297641343318,
        "to_longitude": 105.82116265615888,
        "from_longitude": 105.82083810886938,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5164,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.820419684264,
          21.022189982910803,
          105.82055379471397,
          21.022520469014612
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82055379471397,
            21.022520469014612
          ],
          [
            105.820419684264,
            21.022189982910803
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022520469014612,
        "to_latitude": 21.022189982910803,
        "to_longitude": 105.820419684264,
        "from_longitude": 105.82055379471397,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5165,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82033653578807,
          21.021939614162335,
          105.820419684264,
          21.022189982910803
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.820419684264,
            21.022189982910803
          ],
          [
            105.82033653578807,
            21.021939614162335
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022189982910803,
        "to_latitude": 21.021939614162335,
        "to_longitude": 105.82033653578807,
        "from_longitude": 105.820419684264,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5166,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82024802288767,
          21.021719289315843,
          105.82033653578807,
          21.021939614162335
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82033653578807,
            21.021939614162335
          ],
          [
            105.82024802288767,
            21.021719289315843
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.021939614162335,
        "to_latitude": 21.021719289315843,
        "to_longitude": 105.82024802288767,
        "from_longitude": 105.82033653578807,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5167,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82008709035128,
          21.021686741299924,
          105.82024802288767,
          21.021719289315843
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82024802288767,
            21.021719289315843
          ],
          [
            105.82008709035128,
            21.021686741299924
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.021719289315843,
        "to_latitude": 21.021686741299924,
        "to_longitude": 105.82008709035128,
        "from_longitude": 105.82024802288767,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5168,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82008709035128,
          21.02152650480837,
          105.8201836498749,
          21.021686741299924
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82008709035128,
            21.021686741299924
          ],
          [
            105.8201836498749,
            21.02152650480837
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.021686741299924,
        "to_latitude": 21.02152650480837,
        "to_longitude": 105.8201836498749,
        "from_longitude": 105.82008709035128,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5169,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82004417500045,
          21.021516490022517,
          105.8201836498749,
          21.02152650480837
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8201836498749,
            21.02152650480837
          ],
          [
            105.82004417500045,
            21.021516490022517
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02152650480837,
        "to_latitude": 21.021516490022517,
        "to_longitude": 105.82004417500045,
        "from_longitude": 105.8201836498749,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5170,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82004417500045,
          21.0214413791025,
          105.82031507811715,
          21.021516490022517
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82004417500045,
            21.021516490022517
          ],
          [
            105.82031507811715,
            21.0214413791025
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.021516490022517,
        "to_latitude": 21.0214413791025,
        "to_longitude": 105.82031507811715,
        "from_longitude": 105.82004417500045,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5171,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82031507811715,
          21.02143637170637,
          105.82051892599536,
          21.0214413791025
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82031507811715,
            21.0214413791025
          ],
          [
            105.82051892599536,
            21.02143637170637
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.0214413791025,
        "to_latitude": 21.02143637170637,
        "to_longitude": 105.82051892599536,
        "from_longitude": 105.82031507811715,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5172,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82051892599536,
          21.02143637170637,
          105.82071472725933,
          21.02143637170637
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82051892599536,
            21.02143637170637
          ],
          [
            105.82071472725933,
            21.02143637170637
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02143637170637,
        "to_latitude": 21.02143637170637,
        "to_longitude": 105.82071472725933,
        "from_longitude": 105.82051892599536,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5173,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82071472725933,
          21.02143637170637,
          105.82087834200348,
          21.021446386498457
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82071472725933,
            21.02143637170637
          ],
          [
            105.82087834200348,
            21.021446386498457
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02143637170637,
        "to_latitude": 21.021446386498457,
        "to_longitude": 105.82087834200348,
        "from_longitude": 105.82071472725933,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5174,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82024802288767,
          21.02161663785591,
          105.82060207448029,
          21.021719289315843
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82024802288767,
            21.021719289315843
          ],
          [
            105.82060207448029,
            21.02161663785591
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.021719289315843,
        "to_latitude": 21.02161663785591,
        "to_longitude": 105.82060207448029,
        "from_longitude": 105.82024802288767,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5175,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8201836498749,
          21.021471423474882,
          105.82041431984852,
          21.02152650480837
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8201836498749,
            21.02152650480837
          ],
          [
            105.82032002107903,
            21.02147562730596
          ],
          [
            105.82041431984852,
            21.021471423474882
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02152650480837,
        "to_latitude": 21.021471423474882,
        "to_longitude": 105.82041431984852,
        "from_longitude": 105.8201836498749,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5176,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82041431984852,
          21.02146561251585,
          105.82060207448029,
          21.021471423474882
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82041431984852,
            21.021471423474882
          ],
          [
            105.82050777571081,
            21.02146561251585
          ],
          [
            105.82060207448029,
            21.021471423474882
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.021471423474882,
        "to_latitude": 21.021471423474882,
        "to_longitude": 105.82060207448029,
        "from_longitude": 105.82041431984852,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5177,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82033653578807,
          21.021861999764955,
          105.82048405727674,
          21.021939614162335
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82033653578807,
            21.021939614162335
          ],
          [
            105.82048405727674,
            21.021861999764955
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.021939614162335,
        "to_latitude": 21.021861999764955,
        "to_longitude": 105.82048405727674,
        "from_longitude": 105.82033653578807,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5178,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82048405727674,
          21.021801911171526,
          105.82065571865309,
          21.021861999764955
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82048405727674,
            21.021861999764955
          ],
          [
            105.82065571865309,
            21.021801911171526
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.021861999764955,
        "to_latitude": 21.021801911171526,
        "to_longitude": 105.82065571865309,
        "from_longitude": 105.82048405727674,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5179,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82065571865309,
          21.02174682993977,
          105.82085956654029,
          21.021801911171526
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82065571865309,
            21.021801911171526
          ],
          [
            105.82085956654029,
            21.02174682993977
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.021801911171526,
        "to_latitude": 21.02174682993977,
        "to_longitude": 105.82085956654029,
        "from_longitude": 105.82065571865309,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5180,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.820419684264,
          21.022072309651172,
          105.82075227818571,
          21.022189982910803
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.820419684264,
            21.022189982910803
          ],
          [
            105.82075227818571,
            21.022072309651172
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 51,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 371 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022189982910803,
        "to_latitude": 21.022072309651172,
        "to_longitude": 105.82075227818571,
        "from_longitude": 105.820419684264,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5181,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82152240747214,
          21.022968314073243,
          105.82182281488298,
          21.02305594257781
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82152240747214,
            21.02305594257781
          ],
          [
            105.82182281488298,
            21.022968314073243
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 51,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02305594257781,
        "to_latitude": 21.022968314073243,
        "to_longitude": 105.82182281488298,
        "from_longitude": 105.82152240747214,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5182,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82182281488298,
          21.022853145101866,
          105.82218759531557,
          21.022968314073243
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82182281488298,
            21.022968314073243
          ],
          [
            105.82218759531557,
            21.022853145101866
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022968314073243,
        "to_latitude": 21.022853145101866,
        "to_longitude": 105.82218759531557,
        "from_longitude": 105.82182281488298,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5183,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82218759531557,
          21.02276802015344,
          105.82244240516783,
          21.022853145101866
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82218759531557,
            21.022853145101866
          ],
          [
            105.82244240516783,
            21.02276802015344
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022853145101866,
        "to_latitude": 21.02276802015344,
        "to_longitude": 105.82244240516783,
        "from_longitude": 105.82218759531557,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5184,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82218759531557,
          21.02283050427202,
          105.82232137046039,
          21.023031218824965
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82218759531557,
            21.022853145101866
          ],
          [
            105.82225536754714,
            21.02283050427202
          ],
          [
            105.82232137046039,
            21.023031218824965
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 418 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022853145101866,
        "to_latitude": 21.023031218824965,
        "to_longitude": 105.82232137046039,
        "from_longitude": 105.82218759531557,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5185,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8220316918895,
          21.023031218824965,
          105.82232137046039,
          21.02314638765795
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82232137046039,
            21.023031218824965
          ],
          [
            105.8220316918895,
            21.02314638765795
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 418 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.023031218824965,
        "to_latitude": 21.02314638765795,
        "to_longitude": 105.8220316918895,
        "from_longitude": 105.82232137046039,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5186,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82175274214957,
          21.02314638765795,
          105.8220316918895,
          21.023239023394428
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8220316918895,
            21.02314638765795
          ],
          [
            105.82175274214957,
            21.023239023394428
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 418 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02314638765795,
        "to_latitude": 21.023239023394428,
        "to_longitude": 105.82175274214957,
        "from_longitude": 105.8220316918895,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5187,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82152743659145,
          21.023239023394428,
          105.82175274214957,
          21.023339170071587
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82175274214957,
            21.023239023394428
          ],
          [
            105.82152743659145,
            21.023339170071587
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 418 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.023239023394428,
        "to_latitude": 21.023339170071587,
        "to_longitude": 105.82152743659145,
        "from_longitude": 105.82175274214957,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5188,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8220316918895,
          21.02314638765795,
          105.82214660538676,
          21.023479823582377
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8220316918895,
            21.02314638765795
          ],
          [
            105.82214660538676,
            21.023479823582377
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 418 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02314638765795,
        "to_latitude": 21.023479823582377,
        "to_longitude": 105.82214660538676,
        "from_longitude": 105.8220316918895,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5189,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82210369007188,
          21.023479823582377,
          105.82221097839953,
          21.023745211700152
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82214660538676,
            21.023479823582377
          ],
          [
            105.82210369007188,
            21.023581221930698
          ],
          [
            105.82217610971685,
            21.02373394523507
          ],
          [
            105.82221097839953,
            21.023745211700152
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 418 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.023479823582377,
        "to_latitude": 21.023745211700152,
        "to_longitude": 105.82221097839953,
        "from_longitude": 105.82214660538676,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5190,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82221097839953,
          21.023745211700152,
          105.82233704222628,
          21.023943000650675
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82221097839953,
            21.023745211700152
          ],
          [
            105.82227266924049,
            21.02378652207445
          ],
          [
            105.82233704222628,
            21.023943000650675
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 418 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.023745211700152,
        "to_latitude": 21.023943000650675,
        "to_longitude": 105.82233704222628,
        "from_longitude": 105.82221097839953,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5191,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82233704222628,
          21.023943000650675,
          105.8224389661654,
          21.024210891595843
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82233704222628,
            21.023943000650675
          ],
          [
            105.8224389661654,
            21.024210891595843
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 418 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.023943000650675,
        "to_latitude": 21.024210891595843,
        "to_longitude": 105.8224389661654,
        "from_longitude": 105.82233704222628,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5192,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8224389661654,
          21.024210891595843,
          105.82253820790574,
          21.024413687223937
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8224389661654,
            21.024210891595843
          ],
          [
            105.82253820790574,
            21.024413687223937
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 418 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.024210891595843,
        "to_latitude": 21.024413687223937,
        "to_longitude": 105.82253820790574,
        "from_longitude": 105.8224389661654,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5193,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82253820790574,
          21.024413687223937,
          105.82268841160219,
          21.024716628328772
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82253820790574,
            21.024413687223937
          ],
          [
            105.82268841160219,
            21.024716628328772
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 418 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.024413687223937,
        "to_latitude": 21.024716628328772,
        "to_longitude": 105.82268841160219,
        "from_longitude": 105.82253820790574,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5194,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82268841160219,
          21.024716628328772,
          105.82286275519523,
          21.024904401431282
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82268841160219,
            21.024716628328772
          ],
          [
            105.82286275519523,
            21.024904401431282
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 418 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.024716628328772,
        "to_latitude": 21.024904401431282,
        "to_longitude": 105.82286275519523,
        "from_longitude": 105.82268841160219,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5195,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82286275519523,
          21.02489438687149,
          105.82311220063202,
          21.024943207845553
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82286275519523,
            21.024904401431282
          ],
          [
            105.82291639940398,
            21.024943207845553
          ],
          [
            105.82311220063202,
            21.02489438687149
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 418 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.024904401431282,
        "to_latitude": 21.02489438687149,
        "to_longitude": 105.82311220063202,
        "from_longitude": 105.82286275519523,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5196,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82311220063202,
          21.02489438687149,
          105.82317389143704,
          21.025054619743152
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82311220063202,
            21.02489438687149
          ],
          [
            105.82317389143704,
            21.025054619743152
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 418 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02489438687149,
        "to_latitude": 21.025054619743152,
        "to_longitude": 105.82317389143704,
        "from_longitude": 105.82311220063202,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5197,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82317389143704,
          21.025037094281167,
          105.82334823502111,
          21.025054619743152
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82317389143704,
            21.025054619743152
          ],
          [
            105.82334823502111,
            21.025037094281167
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 418 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.025054619743152,
        "to_latitude": 21.025037094281167,
        "to_longitude": 105.82334823502111,
        "from_longitude": 105.82317389143704,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5198,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82325972212969,
          21.0248317958605,
          105.82334823502111,
          21.025037094281167
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82334823502111,
            21.025037094281167
          ],
          [
            105.82325972212969,
            21.0248317958605
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 418 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.025037094281167,
        "to_latitude": 21.0248317958605,
        "to_longitude": 105.82325972212969,
        "from_longitude": 105.82334823502111,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5199,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82334823502111,
          21.025037094281167,
          105.82340724361839,
          21.025219859711797
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82334823502111,
            21.025037094281167
          ],
          [
            105.82340724361839,
            21.025219859711797
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 418 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.025037094281167,
        "to_latitude": 21.025219859711797,
        "to_longitude": 105.82340724361839,
        "from_longitude": 105.82334823502111,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5200,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82244240516783,
          21.02264283631711,
          105.82274281257867,
          21.02276802015344
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82244240516783,
            21.02276802015344
          ],
          [
            105.82274281257867,
            21.02264283631711
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02276802015344,
        "to_latitude": 21.02264283631711,
        "to_longitude": 105.82274281257867,
        "from_longitude": 105.82244240516783,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5201,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82274281257867,
          21.022525163415754,
          105.82303517335728,
          21.02264283631711
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82274281257867,
            21.02264283631711
          ],
          [
            105.82303517335728,
            21.022525163415754
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02264283631711,
        "to_latitude": 21.022525163415754,
        "to_longitude": 105.82303517335728,
        "from_longitude": 105.82274281257867,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5202,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82303517335728,
          21.02243503091751,
          105.82326316112317,
          21.022525163415754
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82303517335728,
            21.022525163415754
          ],
          [
            105.82326316112317,
            21.02243503091751
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022525163415754,
        "to_latitude": 21.02243503091751,
        "to_longitude": 105.82326316112317,
        "from_longitude": 105.82303517335728,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5203,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82326316112317,
          21.022327372584176,
          105.82352869981538,
          21.02243503091751
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82326316112317,
            21.02243503091751
          ],
          [
            105.82352869981538,
            21.022327372584176
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02243503091751,
        "to_latitude": 21.022327372584176,
        "to_longitude": 105.82352869981538,
        "from_longitude": 105.82326316112317,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5204,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82342375837234,
          21.022327372584176,
          105.82352869981538,
          21.022405299698104
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82352869981538,
            21.022327372584176
          ],
          [
            105.82342375837234,
            21.022405299698104
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 360 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022327372584176,
        "to_latitude": 21.022405299698104,
        "to_longitude": 105.82342375837234,
        "from_longitude": 105.82352869981538,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5205,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82342375837234,
          21.022405299698104,
          105.82352031789598,
          21.02268821546891
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82342375837234,
            21.022405299698104
          ],
          [
            105.82352031789598,
            21.02268821546891
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 360 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022405299698104,
        "to_latitude": 21.02268821546891,
        "to_longitude": 105.82352031789598,
        "from_longitude": 105.82342375837234,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5206,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82334865651964,
          21.02268821546891,
          105.82352031789598,
          21.022738289001325
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82352031789598,
            21.02268821546891
          ],
          [
            105.82334865651964,
            21.022738289001325
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 360 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02268821546891,
        "to_latitude": 21.022738289001325,
        "to_longitude": 105.82334865651964,
        "from_longitude": 105.82352031789598,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5207,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82352031789598,
          21.022660675019704,
          105.82362760625057,
          21.02268821546891
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82352031789598,
            21.02268821546891
          ],
          [
            105.82362760625057,
            21.022660675019704
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 360 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02268821546891,
        "to_latitude": 21.022660675019704,
        "to_longitude": 105.82362760625057,
        "from_longitude": 105.82352031789598,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5208,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82362760625057,
          21.022583060997697,
          105.82385827622419,
          21.022660675019704
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82362760625057,
            21.022660675019704
          ],
          [
            105.82385827622419,
            21.022583060997697
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 360 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022660675019704,
        "to_latitude": 21.022583060997697,
        "to_longitude": 105.82385827622419,
        "from_longitude": 105.82362760625057,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5209,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82352031789598,
          21.02268821546891,
          105.82355208294427,
          21.022783355165163
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82352031789598,
            21.02268821546891
          ],
          [
            105.82355208294427,
            21.022770033230742
          ],
          [
            105.82352031789598,
            21.022783355165163
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 360 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02268821546891,
        "to_latitude": 21.022783355165163,
        "to_longitude": 105.82352031789598,
        "from_longitude": 105.82352031789598,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5210,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82325477920374,
          21.022783355165163,
          105.82352031789598,
          21.02287849480072
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82352031789598,
            21.022783355165163
          ],
          [
            105.82325477920374,
            21.02287849480072
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 360 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022783355165163,
        "to_latitude": 21.02287849480072,
        "to_longitude": 105.82325477920374,
        "from_longitude": 105.82352031789598,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5211,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82352869981538,
          21.02219467732235,
          105.82379692072433,
          21.022327372584176
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82352869981538,
            21.022327372584176
          ],
          [
            105.82379692072433,
            21.02219467732235
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022327372584176,
        "to_latitude": 21.02219467732235,
        "to_longitude": 105.82379692072433,
        "from_longitude": 105.82352869981538,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5212,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82379692072433,
          21.022087018815487,
          105.82403563732117,
          21.02219467732235
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82379692072433,
            21.02219467732235
          ],
          [
            105.82403563732117,
            21.022087018815487
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02219467732235,
        "to_latitude": 21.022087018815487,
        "to_longitude": 105.82403563732117,
        "from_longitude": 105.82379692072433,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5213,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82403563732117,
          21.021972162122175,
          105.82427133638714,
          21.022087018815487
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82403563732117,
            21.022087018815487
          ],
          [
            105.82427133638714,
            21.021972162122175
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022087018815487,
        "to_latitude": 21.021972162122175,
        "to_longitude": 105.82427133638714,
        "from_longitude": 105.82403563732117,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5214,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82403563732117,
          21.022069002403473,
          105.82441349349627,
          21.02238196300134
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82403563732117,
            21.022087018815487
          ],
          [
            105.82414753334147,
            21.022069002403473
          ],
          [
            105.82428164379144,
            21.02238196300134
          ],
          [
            105.82441349349627,
            21.022340203957896
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022087018815487,
        "to_latitude": 21.022340203957896,
        "to_longitude": 105.82441349349627,
        "from_longitude": 105.82403563732117,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5215,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82441349349627,
          21.022340203957896,
          105.82444031558266,
          21.022390277607208
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82441349349627,
            21.022340203957896
          ],
          [
            105.82444031558266,
            21.022390277607208
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022340203957896,
        "to_latitude": 21.022390277607208,
        "to_longitude": 105.82444031558266,
        "from_longitude": 105.82441349349627,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5216,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82444031558266,
          21.02232938566658,
          105.82462764875186,
          21.022390277607208
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82444031558266,
            21.022390277607208
          ],
          [
            105.82462764875186,
            21.02232938566658
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022390277607208,
        "to_latitude": 21.02232938566658,
        "to_longitude": 105.82462764875186,
        "from_longitude": 105.82444031558266,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5217,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82462764875186,
          21.02232938566658,
          105.82466788189494,
          21.0224278328325
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82462764875186,
            21.02232938566658
          ],
          [
            105.82466788189494,
            21.022409503502836
          ],
          [
            105.82462807021443,
            21.0224278328325
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02232938566658,
        "to_latitude": 21.0224278328325,
        "to_longitude": 105.82462807021443,
        "from_longitude": 105.82462764875186,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5218,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82462807021443,
          21.0224278328325,
          105.82470048985941,
          21.022457877006083
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82462807021443,
            21.0224278328325
          ],
          [
            105.82470048985941,
            21.022457877006083
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.0224278328325,
        "to_latitude": 21.022457877006083,
        "to_longitude": 105.82470048985941,
        "from_longitude": 105.82462807021443,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5219,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82470006839682,
          21.022382766560323,
          105.82478632055206,
          21.022457877006083
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82470048985941,
            21.022457877006083
          ],
          [
            105.82470006839682,
            21.022412007184183
          ],
          [
            105.82478632055206,
            21.022382766560323
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022457877006083,
        "to_latitude": 21.022382766560323,
        "to_longitude": 105.82478632055206,
        "from_longitude": 105.82470048985941,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5220,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82478632055206,
          21.02226008608522,
          105.82505454145203,
          21.022382766560323
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82478632055206,
            21.022382766560323
          ],
          [
            105.82505454145203,
            21.02226008608522
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022382766560323,
        "to_latitude": 21.02226008608522,
        "to_longitude": 105.82505454145203,
        "from_longitude": 105.82478632055206,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5221,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82499016843029,
          21.02203225064866,
          105.82505454145203,
          21.02226008608522
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82505454145203,
            21.02226008608522
          ],
          [
            105.82499016843029,
            21.02203225064866
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02226008608522,
        "to_latitude": 21.02203225064866,
        "to_longitude": 105.82499016843029,
        "from_longitude": 105.82505454145203,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5222,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82505454145203,
          21.022189982910803,
          105.8252744825857,
          21.02226008608522
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82505454145203,
            21.02226008608522
          ],
          [
            105.8252744825857,
            21.022189982910803
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02226008608522,
        "to_latitude": 21.022189982910803,
        "to_longitude": 105.8252744825857,
        "from_longitude": 105.82505454145203,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5223,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8252074273652,
          21.02202223589592,
          105.8252744825857,
          21.022189982910803
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8252744825857,
            21.022189982910803
          ],
          [
            105.8252074273652,
            21.02202223589592
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022189982910803,
        "to_latitude": 21.02202223589592,
        "to_longitude": 105.8252074273652,
        "from_longitude": 105.8252744825857,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5224,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8252744825857,
          21.022149120378817,
          105.82545955500973,
          21.022189982910803
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8252744825857,
            21.022189982910803
          ],
          [
            105.82544572250843,
            21.022149120378817
          ],
          [
            105.82545955500973,
            21.022177464483445
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022189982910803,
        "to_latitude": 21.022177464483445,
        "to_longitude": 105.82545955500973,
        "from_longitude": 105.8252744825857,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5225,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82545955500973,
          21.022037258024767,
          105.8254649194252,
          21.022177464483445
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82545955500973,
            21.022177464483445
          ],
          [
            105.8254649194252,
            21.022037258024767
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022177464483445,
        "to_latitude": 21.022037258024767,
        "to_longitude": 105.8254649194252,
        "from_longitude": 105.82545955500973,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5226,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8254649194252,
          21.02195213261049,
          105.82573850474964,
          21.022037258024767
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8254649194252,
            21.022037258024767
          ],
          [
            105.82573850474964,
            21.02195213261049
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022037258024767,
        "to_latitude": 21.02195213261049,
        "to_longitude": 105.82573850474964,
        "from_longitude": 105.8254649194252,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5227,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82573850474964,
          21.02195213261049,
          105.82579751334691,
          21.022107361270162
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82573850474964,
            21.02195213261049
          ],
          [
            105.82579751334691,
            21.022107361270162
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02195213261049,
        "to_latitude": 21.022107361270162,
        "to_longitude": 105.82579751334691,
        "from_longitude": 105.82573850474964,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5228,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82579751334691,
          21.022039761712342,
          105.82604427657596,
          21.022107361270162
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82579751334691,
            21.022107361270162
          ],
          [
            105.82604427657596,
            21.022039761712342
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022107361270162,
        "to_latitude": 21.022039761712342,
        "to_longitude": 105.82604427657596,
        "from_longitude": 105.82579751334691,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5229,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82427133638714,
          21.021829451778515,
          105.82455565054254,
          21.021972162122175
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82427133638714,
            21.021972162122175
          ],
          [
            105.82455565054254,
            21.021829451778515
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.021972162122175,
        "to_latitude": 21.021829451778515,
        "to_longitude": 105.82455565054254,
        "from_longitude": 105.82427133638714,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5230,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82455565054254,
          21.021686741298268,
          105.82484801133012,
          21.021829451778515
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82455565054254,
            21.021829451778515
          ],
          [
            105.82484801133012,
            21.021686741298268
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.021829451778515,
        "to_latitude": 21.021686741298268,
        "to_longitude": 105.82484801133012,
        "from_longitude": 105.82455565054254,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5231,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82484801133012,
          21.02155404546626,
          105.82512696107004,
          21.021686741298268
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82484801133012,
            21.021686741298268
          ],
          [
            105.82512696107004,
            21.02155404546626
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.021686741298268,
        "to_latitude": 21.02155404546626,
        "to_longitude": 105.82512696107004,
        "from_longitude": 105.82484801133012,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5232,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82512696107004,
          21.021423853214053,
          105.82539786417776,
          21.02155404546626
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82512696107004,
            21.02155404546626
          ],
          [
            105.82539786417776,
            21.021423853214053
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02155404546626,
        "to_latitude": 21.021423853214053,
        "to_longitude": 105.82539786417776,
        "from_longitude": 105.82512696107004,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5233,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82539786417776,
          21.021293660849004,
          105.82570900041956,
          21.021423853214053
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82539786417776,
            21.021423853214053
          ],
          [
            105.82570900041956,
            21.021293660849004
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.021423853214053,
        "to_latitude": 21.021293660849004,
        "to_longitude": 105.82570900041956,
        "from_longitude": 105.82539786417776,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5234,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82515872613632,
          21.021423853214053,
          105.82539786417776,
          21.02175684471101
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82539786417776,
            21.021423853214053
          ],
          [
            105.82515872613632,
            21.021578278862975
          ],
          [
            105.8252342494516,
            21.02175684471101
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 181 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.021423853214053,
        "to_latitude": 21.02175684471101,
        "to_longitude": 105.8252342494516,
        "from_longitude": 105.82539786417776,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5235,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82498614511869,
          21.021423853214053,
          105.82539786417776,
          21.021488145792247
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82539786417776,
            21.021423853214053
          ],
          [
            105.82502729789407,
            21.021488145792247
          ],
          [
            105.82498614511869,
            21.021427608763812
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 256 Hào Nam",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.021423853214053,
        "to_latitude": 21.021427608763812,
        "to_longitude": 105.82498614511869,
        "from_longitude": 105.82539786417776,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5236,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82379692072433,
          21.022056483965976,
          105.82387126580929,
          21.02219467732235
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82379692072433,
            21.02219467732235
          ],
          [
            105.82387126580929,
            21.022056483965976
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02219467732235,
        "to_latitude": 21.022056483965976,
        "to_longitude": 105.82387126580929,
        "from_longitude": 105.82379692072433,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5237,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82373221240641,
          21.021867007146763,
          105.82387126580929,
          21.022056483965976
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82387126580929,
            21.022056483965976
          ],
          [
            105.82373221240641,
            21.021867007146763
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022056483965976,
        "to_latitude": 21.021867007146763,
        "to_longitude": 105.82373221240641,
        "from_longitude": 105.82387126580929,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5238,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82358737311647,
          21.021867007146763,
          105.82373221240641,
          21.021922088334954
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82373221240641,
            21.021867007146763
          ],
          [
            105.82358737311647,
            21.021922088334954
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.021867007146763,
        "to_latitude": 21.021922088334954,
        "to_longitude": 105.82358737311647,
        "from_longitude": 105.82373221240641,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5239,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82344521604327,
          21.021922088334954,
          105.82358737311647,
          21.02197716950197
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82358737311647,
            21.021922088334954
          ],
          [
            105.82344521604327,
            21.02197716950197
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.021922088334954,
        "to_latitude": 21.02197716950197,
        "to_longitude": 105.82344521604327,
        "from_longitude": 105.82358737311647,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5240,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82325209698703,
          21.02197716950197,
          105.82344521604327,
          21.022047272775648
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82344521604327,
            21.02197716950197
          ],
          [
            105.82325209698703,
            21.022047272775648
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02197716950197,
        "to_latitude": 21.022047272775648,
        "to_longitude": 105.82325209698703,
        "from_longitude": 105.82344521604327,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5241,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82308580003516,
          21.022047272775648,
          105.82325209698703,
          21.022117376017185
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82325209698703,
            21.022047272775648
          ],
          [
            105.82308580003516,
            21.022117376017185
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022047272775648,
        "to_latitude": 21.022117376017185,
        "to_longitude": 105.82308580003516,
        "from_longitude": 105.82325209698703,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5242,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82292754969755,
          21.022117376017185,
          105.82308580003516,
          21.022172457112045
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82308580003516,
            21.022117376017185
          ],
          [
            105.82292754969755,
            21.022172457112045
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022117376017185,
        "to_latitude": 21.022172457112045,
        "to_longitude": 105.82292754969755,
        "from_longitude": 105.82308580003516,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5243,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82308580003516,
          21.022117376017185,
          105.8231769951433,
          21.02225758240051
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82308580003516,
            21.022117376017185
          ],
          [
            105.8231769951433,
            21.02225758240051
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.022117376017185,
        "to_latitude": 21.02225758240051,
        "to_longitude": 105.8231769951433,
        "from_longitude": 105.82308580003516,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5244,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82358737311647,
          21.02167923021846,
          105.82373221240641,
          21.021867007146763
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82373221240641,
            21.021867007146763
          ],
          [
            105.82358737311647,
            21.02167923021846
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.021867007146763,
        "to_latitude": 21.02167923021846,
        "to_longitude": 105.82358737311647,
        "from_longitude": 105.82373221240641,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5245,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82319040618201,
          21.02167923021846,
          105.82358737311647,
          21.021829451780192
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82358737311647,
            21.02167923021846
          ],
          [
            105.82319040618201,
            21.021829451780192
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02167923021846,
        "to_latitude": 21.021829451780192,
        "to_longitude": 105.82319040618201,
        "from_longitude": 105.82358737311647,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5246,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82295705400064,
          21.021829451780192,
          105.82319040618201,
          21.02191207357485
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82319040618201,
            21.021829451780192
          ],
          [
            105.82295705400064,
            21.02191207357485
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.021829451780192,
        "to_latitude": 21.02191207357485,
        "to_longitude": 105.82295705400064,
        "from_longitude": 105.82319040618201,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5247,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82282026134293,
          21.02191207357485,
          105.82295705400064,
          21.021974665812518
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82295705400064,
            21.02191207357485
          ],
          [
            105.82282026134293,
            21.021974665812518
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LT5",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 La Thành",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LAT5",
        "from_latitude": 21.02191207357485,
        "to_latitude": 21.021974665812518,
        "to_longitude": 105.82282026134293,
        "from_longitude": 105.82295705400064,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5248,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82454718247469,
          21.023308322518236,
          105.82461155549643,
          21.023511119374987
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82461155549643,
            21.023511119374987
          ],
          [
            105.82454718247469,
            21.023308322518236
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 318",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.023511119374987,
        "to_latitude": 21.023308322518236,
        "to_longitude": 105.82454718247469,
        "from_longitude": 105.82461155549643,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5249,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82449890271737,
          21.02314558410057,
          105.82454718247469,
          21.023308322518236
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82454718247469,
            21.023308322518236
          ],
          [
            105.82449890271737,
            21.02314558410057
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 318",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.023308322518236,
        "to_latitude": 21.02314558410057,
        "to_longitude": 105.82449890271737,
        "from_longitude": 105.82454718247469,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5250,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82445732850636,
          21.022995363866663,
          105.82449890271737,
          21.02314558410057
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82449890271737,
            21.02314558410057
          ],
          [
            105.82445732850636,
            21.022995363866663
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 318",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.02314558410057,
        "to_latitude": 21.022995363866663,
        "to_longitude": 105.82445732850636,
        "from_longitude": 105.82449890271737,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5251,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82449890271737,
          21.023110532725667,
          105.82468933955688,
          21.02314558410057
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82449890271737,
            21.02314558410057
          ],
          [
            105.82468933955688,
            21.023110532725667
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 318/40",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.02314558410057,
        "to_latitude": 21.023110532725667,
        "to_longitude": 105.82468933955688,
        "from_longitude": 105.82449890271737,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5252,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82468933955688,
          21.023110532725667,
          105.82473493710647,
          21.02324573084049
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82468933955688,
            21.023110532725667
          ],
          [
            105.82473493710647,
            21.02324573084049
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 318/40",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.023110532725667,
        "to_latitude": 21.02324573084049,
        "to_longitude": 105.82473493710647,
        "from_longitude": 105.82468933955688,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5253,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82461155549643,
          21.023403461818763,
          105.82477517024056,
          21.023511119374987
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82461155549643,
            21.023511119374987
          ],
          [
            105.82473511365237,
            21.02347100584542
          ],
          [
            105.82477517024056,
            21.023403461818763
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chùa Thanh Nhàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.023511119374987,
        "to_latitude": 21.023403461818763,
        "to_longitude": 105.82477517024056,
        "from_longitude": 105.82461155549643,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5254,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82477517024056,
          21.02334087018011,
          105.82496560708007,
          21.023403461818763
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82477517024056,
            21.023403461818763
          ],
          [
            105.82496560708007,
            21.02334087018011
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chùa Thanh Nhàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.023403461818763,
        "to_latitude": 21.02334087018011,
        "to_longitude": 105.82496560708007,
        "from_longitude": 105.82477517024056,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5255,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82496560708007,
          21.023185642804773,
          105.82504088547867,
          21.023358340929455
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82496560708007,
            21.02334087018011
          ],
          [
            105.82498992350463,
            21.023358340929455
          ],
          [
            105.82504088547867,
            21.023340815268053
          ],
          [
            105.82500584022316,
            21.023185642804773
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chùa Thanh Nhàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.02334087018011,
        "to_latitude": 21.023185642804773,
        "to_longitude": 105.82500584022316,
        "from_longitude": 105.82496560708007,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5256,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82461155549643,
          21.023511119374987,
          105.82471079724577,
          21.023740204381422
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82461155549643,
            21.023511119374987
          ],
          [
            105.82463209349912,
            21.02354071505059
          ],
          [
            105.82471079724577,
            21.023740204381422
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 318",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.023511119374987,
        "to_latitude": 21.023740204381422,
        "to_longitude": 105.82471079724577,
        "from_longitude": 105.82461155549643,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5257,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82461155549643,
          21.023511119374987,
          105.82465178863052,
          21.023781514754575
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82461155549643,
            21.023511119374987
          ],
          [
            105.82465178863052,
            21.023781514754575
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 318",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.023511119374987,
        "to_latitude": 21.023781514754575,
        "to_longitude": 105.82465178863052,
        "from_longitude": 105.82461155549643,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5258,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82437552109835,
          21.023781514754575,
          105.82465178863052,
          21.023906697634754
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82465178863052,
            21.023781514754575
          ],
          [
            105.82437552109835,
            21.023906697634754
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 318",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.023781514754575,
        "to_latitude": 21.023906697634754,
        "to_longitude": 105.82437552109835,
        "from_longitude": 105.82465178863052,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5259,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82464910642278,
          21.023740204381422,
          105.82471079724577,
          21.023911704947906
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82471079724577,
            21.023740204381422
          ],
          [
            105.82464910642278,
            21.023911704947906
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 318",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.023740204381422,
        "to_latitude": 21.023911704947906,
        "to_longitude": 105.82464910642278,
        "from_longitude": 105.82471079724577,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5260,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8243862499383,
          21.023911704947906,
          105.82464910642278,
          21.02399182193297
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82464910642278,
            21.023911704947906
          ],
          [
            105.8243862499383,
            21.02399182193297
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 318",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.023911704947906,
        "to_latitude": 21.02399182193297,
        "to_longitude": 105.8243862499383,
        "from_longitude": 105.82464910642278,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5261,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82415289775695,
          21.02399182193297,
          105.8243862499383,
          21.024036887717894
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8243862499383,
            21.02399182193297
          ],
          [
            105.82415289775695,
            21.024036887717894
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 318",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.02399182193297,
        "to_latitude": 21.024036887717894,
        "to_longitude": 105.82415289775695,
        "from_longitude": 105.8243862499383,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5262,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82395173207749,
          21.024036887717894,
          105.82415289775695,
          21.02409697541049
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82415289775695,
            21.024036887717894
          ],
          [
            105.82395173207749,
            21.02409697541049
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 318",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.024036887717894,
        "to_latitude": 21.02409697541049,
        "to_longitude": 105.82395173207749,
        "from_longitude": 105.82415289775695,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5263,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82395173207749,
          21.02409697541049,
          105.82402683393019,
          21.02439240621416
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82395173207749,
            21.02409697541049
          ],
          [
            105.82402683393019,
            21.02439240621416
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 318/90",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.02409697541049,
        "to_latitude": 21.02439240621416,
        "to_longitude": 105.82402683393019,
        "from_longitude": 105.82395173207749,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5264,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8237827529089,
          21.024308479057296,
          105.82402683393019,
          21.02439240621416
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82402683393019,
            21.02439240621416
          ],
          [
            105.82398409513426,
            21.024308479057296
          ],
          [
            105.8237827529089,
            21.024339829588452
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 318/90",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.02439240621416,
        "to_latitude": 21.024339829588452,
        "to_longitude": 105.8237827529089,
        "from_longitude": 105.82402683393019,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5265,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82402683393019,
          21.02439240621416,
          105.82407779590426,
          21.024592697950656
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82402683393019,
            21.02439240621416
          ],
          [
            105.82407779590426,
            21.024592697950656
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 318/90",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.02439240621416,
        "to_latitude": 21.024592697950656,
        "to_longitude": 105.82407779590426,
        "from_longitude": 105.82402683393019,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5266,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82393429775412,
          21.024592697950656,
          105.82407779590426,
          21.024615230755266
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82407779590426,
            21.024592697950656
          ],
          [
            105.82393429775412,
            21.024615230755266
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 318/90",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.024592697950656,
        "to_latitude": 21.024615230755266,
        "to_longitude": 105.82393429775412,
        "from_longitude": 105.82407779590426,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5267,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82407779590426,
          21.024592697950656,
          105.82412607566157,
          21.024855580447007
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82407779590426,
            21.024592697950656
          ],
          [
            105.82412607566157,
            21.024855580447007
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 318/90",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.024592697950656,
        "to_latitude": 21.024855580447007,
        "to_longitude": 105.82412607566157,
        "from_longitude": 105.82407779590426,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5268,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82404426831644,
          21.024855580447007,
          105.82412607566157,
          21.02487060229119
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82412607566157,
            21.024855580447007
          ],
          [
            105.82409138349784,
            21.02486804373715
          ],
          [
            105.82404426831644,
            21.02487060229119
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 318/90",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.024855580447007,
        "to_latitude": 21.02487060229119,
        "to_longitude": 105.82404426831644,
        "from_longitude": 105.82412607566157,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5269,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8239208867064,
          21.02487060229119,
          105.82404426831644,
          21.024890631412443
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82404426831644,
            21.02487060229119
          ],
          [
            105.8239208867064,
            21.024890631412443
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 318/90",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.02487060229119,
        "to_latitude": 21.024890631412443,
        "to_longitude": 105.8239208867064,
        "from_longitude": 105.82404426831644,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5270,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82381091613512,
          21.024890631412443,
          105.8239208867064,
          21.024898142332944
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8239208867064,
            21.024890631412443
          ],
          [
            105.82381091613512,
            21.024898142332944
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 318/90",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.024890631412443,
        "to_latitude": 21.024898142332944,
        "to_longitude": 105.82381091613512,
        "from_longitude": 105.8239208867064,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5271,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8243862499383,
          21.02399182193297,
          105.82441575424143,
          21.024167077688194
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8243862499383,
            21.02399182193297
          ],
          [
            105.82441575424143,
            21.024167077688194
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 318/100",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.02399182193297,
        "to_latitude": 21.024167077688194,
        "to_longitude": 105.82441575424143,
        "from_longitude": 105.8243862499383,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5272,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82441575424143,
          21.024167077688194,
          105.82448817388641,
          21.024372377025152
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82441575424143,
            21.024167077688194
          ],
          [
            105.82448817388641,
            21.024372377025152
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 318/100",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.024167077688194,
        "to_latitude": 21.024372377025152,
        "to_longitude": 105.82448817388641,
        "from_longitude": 105.82441575424143,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5273,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82448817388641,
          21.024372377025152,
          105.82456327573011,
          21.02458017972509
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82448817388641,
            21.024372377025152
          ],
          [
            105.82456327573011,
            21.02458017972509
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 318/100",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.024372377025152,
        "to_latitude": 21.02458017972509,
        "to_longitude": 105.82456327573011,
        "from_longitude": 105.82448817388641,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5274,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82448817388641,
          21.02433351555224,
          105.82467056409371,
          21.024372377025152
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82448817388641,
            21.024372377025152
          ],
          [
            105.82465330628031,
            21.02433351555224
          ],
          [
            105.82467056409371,
            21.02435735513242
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 318/100",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.024372377025152,
        "to_latitude": 21.02435735513242,
        "to_longitude": 105.82467056409371,
        "from_longitude": 105.82448817388641,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5275,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82467056409371,
          21.02435735513242,
          105.82469470397236,
          21.024434968230683
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82467056409371,
            21.02435735513242
          ],
          [
            105.82469470397236,
            21.024434968230683
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 318/100",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.02435735513242,
        "to_latitude": 21.024434968230683,
        "to_longitude": 105.82469470397236,
        "from_longitude": 105.82467056409371,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5276,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82456327573011,
          21.02458017972509,
          105.82458205119329,
          21.024750427503754
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82456327573011,
            21.02458017972509
          ],
          [
            105.82458205119329,
            21.024750427503754
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 318/100",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.02458017972509,
        "to_latitude": 21.024750427503754,
        "to_longitude": 105.82458205119329,
        "from_longitude": 105.82456327573011,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5277,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8245498646914,
          21.024750427503754,
          105.82458205119329,
          21.024895638690868
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82458205119329,
            21.024750427503754
          ],
          [
            105.8245498646914,
            21.024895638690868
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 318/100",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.024750427503754,
        "to_latitude": 21.024895638690868,
        "to_longitude": 105.8245498646914,
        "from_longitude": 105.82458205119329,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5278,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82412607566157,
          21.024855580447007,
          105.82413948670926,
          21.025063382472542
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82412607566157,
            21.024855580447007
          ],
          [
            105.82413948670926,
            21.025063382472542
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 318/90",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.024855580447007,
        "to_latitude": 21.025063382472542,
        "to_longitude": 105.82413948670926,
        "from_longitude": 105.82412607566157,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5279,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82412625221644,
          21.025063382472542,
          105.82413948670926,
          21.025218552981237
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82413948670926,
            21.025063382472542
          ],
          [
            105.82412625221644,
            21.025218552981237
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 318/90",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.025063382472542,
        "to_latitude": 21.025218552981237,
        "to_longitude": 105.82412625221644,
        "from_longitude": 105.82413948670926,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5280,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82464910642278,
          21.023911704947906,
          105.82474047808581,
          21.02396798229433
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82464910642278,
            21.023911704947906
          ],
          [
            105.82474047808581,
            21.02396798229433
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 318/84",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.023911704947906,
        "to_latitude": 21.02396798229433,
        "to_longitude": 105.82474047808581,
        "from_longitude": 105.82464910642278,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5281,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82474047808581,
          21.02396798229433,
          105.8247590769941,
          21.024109493677496
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82474047808581,
            21.02396798229433
          ],
          [
            105.8247590769941,
            21.024109493677496
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "MNTH",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 318/84",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "MNHN",
        "from_latitude": 21.02396798229433,
        "to_latitude": 21.024109493677496,
        "to_longitude": 105.8247590769941,
        "from_longitude": 105.82474047808581,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5282,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82508916529889,
          21.018892986176866,
          105.82537884386977,
          21.018993135773265
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82508916529889,
            21.018993135773265
          ],
          [
            105.82537884386977,
            21.018892986176866
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.018993135773265,
        "to_latitude": 21.018892986176866,
        "to_longitude": 105.82537884386977,
        "from_longitude": 105.82508916529889,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5283,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82537884386977,
          21.018777814058353,
          105.8255987850124,
          21.018892986176866
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82537884386977,
            21.018892986176866
          ],
          [
            105.8255987850124,
            21.018777814058353
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.018892986176866,
        "to_latitude": 21.018777814058353,
        "to_longitude": 105.8255987850124,
        "from_longitude": 105.82537884386977,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5284,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8255987850124,
          21.018622581931446,
          105.82586700591237,
          21.018777814058353
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8255987850124,
            21.018777814058353
          ],
          [
            105.82586700591237,
            21.018622581931446
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.018777814058353,
        "to_latitude": 21.018622581931446,
        "to_longitude": 105.82586700591237,
        "from_longitude": 105.8255987850124,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5285,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82586700591237,
          21.018512417098563,
          105.82609767588598,
          21.018622581931446
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82586700591237,
            21.018622581931446
          ],
          [
            105.82609767588598,
            21.018512417098563
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.018622581931446,
        "to_latitude": 21.018512417098563,
        "to_longitude": 105.82609767588598,
        "from_longitude": 105.82586700591237,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5286,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82609767588598,
          21.018362192194903,
          105.82636589678596,
          21.018512417098563
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82609767588598,
            21.018512417098563
          ],
          [
            105.82636589678596,
            21.018362192194903
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.018512417098563,
        "to_latitude": 21.018362192194903,
        "to_longitude": 105.82636589678596,
        "from_longitude": 105.82609767588598,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5287,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82636589678596,
          21.018362192194903,
          105.82657510908864,
          21.018647619382016
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82636589678596,
            21.018362192194903
          ],
          [
            105.82657510908864,
            21.018647619382016
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Giếng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.018362192194903,
        "to_latitude": 21.018647619382016,
        "to_longitude": 105.82657510908864,
        "from_longitude": 105.82636589678596,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5288,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82657510908864,
          21.018647619382016,
          105.82673604163399,
          21.01893304602364
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82657510908864,
            21.018647619382016
          ],
          [
            105.82673604163399,
            21.01893304602364
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Giếng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.018647619382016,
        "to_latitude": 21.01893304602364,
        "to_longitude": 105.82673604163399,
        "from_longitude": 105.82657510908864,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5289,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82632298144411,
          21.018146869568906,
          105.82636589678596,
          21.018362192194903
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82636589678596,
            21.018362192194903
          ],
          [
            105.82632298144411,
            21.018146869568906
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Bộ Công An",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.018362192194903,
        "to_latitude": 21.018146869568906,
        "to_longitude": 105.82632298144411,
        "from_longitude": 105.82636589678596,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5290,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82615668448325,
          21.01788147148533,
          105.82632298144411,
          21.018146869568906
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82632298144411,
            21.018146869568906
          ],
          [
            105.82615668448325,
            21.01788147148533
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Bộ Công An",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.018146869568906,
        "to_latitude": 21.01788147148533,
        "to_longitude": 105.82615668448325,
        "from_longitude": 105.82632298144411,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5291,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82598502310692,
          21.017606057881746,
          105.82615668448325,
          21.01788147148533
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82615668448325,
            21.01788147148533
          ],
          [
            105.82598502310692,
            21.017606057881746
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Bộ Công An",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.01788147148533,
        "to_latitude": 21.017606057881746,
        "to_longitude": 105.82598502310692,
        "from_longitude": 105.82615668448325,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5292,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82584554824145,
          21.01788147148533,
          105.82615668448325,
          21.018051726914123
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82615668448325,
            21.01788147148533
          ],
          [
            105.82584554824145,
            21.018051726914123
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Bộ Công An",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.01788147148533,
        "to_latitude": 21.018051726914123,
        "to_longitude": 105.82584554824145,
        "from_longitude": 105.82615668448325,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5293,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82584554824145,
          21.01803596474987,
          105.8259974892437,
          21.018168663712807
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82584554824145,
            21.018051726914123
          ],
          [
            105.82590897634331,
            21.01803596474987
          ],
          [
            105.8259974892437,
            21.018168663712807
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Bộ Công An",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.018051726914123,
        "to_latitude": 21.018168663712807,
        "to_longitude": 105.8259974892437,
        "from_longitude": 105.82584554824145,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5294,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82580168798872,
          21.017843175480085,
          105.82587544872857,
          21.018051726914123
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82584554824145,
            21.018051726914123
          ],
          [
            105.82587544872857,
            21.017998408418347
          ],
          [
            105.82580168798872,
            21.017843175480085
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Bộ Công An",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.018051726914123,
        "to_latitude": 21.017843175480085,
        "to_longitude": 105.82580168798872,
        "from_longitude": 105.82584554824145,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5295,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82571680220695,
          21.018051726914123,
          105.82584554824145,
          21.018131847048114
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82584554824145,
            21.018051726914123
          ],
          [
            105.82571680220695,
            21.018131847048114
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Bộ Công An",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.018051726914123,
        "to_latitude": 21.018131847048114,
        "to_longitude": 105.82571680220695,
        "from_longitude": 105.82584554824145,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5296,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82571680220695,
          21.018131847048114,
          105.8257721836856,
          21.018286340052406
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82571680220695,
            21.018131847048114
          ],
          [
            105.8257721836856,
            21.018286340052406
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Bộ Công An",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.018131847048114,
        "to_latitude": 21.018286340052406,
        "to_longitude": 105.8257721836856,
        "from_longitude": 105.82571680220695,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5297,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82559247568604,
          21.018000912173754,
          105.82571680220695,
          21.018131847048114
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82571680220695,
            21.018131847048114
          ],
          [
            105.82559247568604,
            21.018000912173754
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Bộ Công An",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.018131847048114,
        "to_latitude": 21.018000912173754,
        "to_longitude": 105.82559247568604,
        "from_longitude": 105.82571680220695,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5298,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82673604163399,
          21.01893304602364,
          105.82690770301035,
          21.019118322673062
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82673604163399,
            21.01893304602364
          ],
          [
            105.82690770301035,
            21.019118322673062
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Giếng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.01893304602364,
        "to_latitude": 21.019118322673062,
        "to_longitude": 105.82690770301035,
        "from_longitude": 105.82673604163399,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5299,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82690770301035,
          21.019018173161612,
          105.82712764415298,
          21.019118322673062
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82690770301035,
            21.019118322673062
          ],
          [
            105.82712764415298,
            21.019018173161612
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Giếng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.019118322673062,
        "to_latitude": 21.019018173161612,
        "to_longitude": 105.82712764415298,
        "from_longitude": 105.82690770301035,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5300,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82673604163399,
          21.018807858967445,
          105.82692916068127,
          21.01893304602364
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82673604163399,
            21.01893304602364
          ],
          [
            105.82692916068127,
            21.018807858967445
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Giếng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.01893304602364,
        "to_latitude": 21.018807858967445,
        "to_longitude": 105.82692916068127,
        "from_longitude": 105.82673604163399,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5301,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82692916068127,
          21.018672656828414,
          105.82719201716574,
          21.018807858967445
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82692916068127,
            21.018807858967445
          ],
          [
            105.82719201716574,
            21.018672656828414
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Giếng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.018807858967445,
        "to_latitude": 21.018672656828414,
        "to_longitude": 105.82719201716574,
        "from_longitude": 105.82692916068127,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5302,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82719201716574,
          21.01847736463442,
          105.82748169573664,
          21.018672656828414
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82719201716574,
            21.018672656828414
          ],
          [
            105.82748169573664,
            21.01847736463442
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Giếng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.018672656828414,
        "to_latitude": 21.01847736463442,
        "to_longitude": 105.82748169573664,
        "from_longitude": 105.82719201716574,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5303,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82748169573664,
          21.01836395631107,
          105.82780356081838,
          21.01847736463442
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82748169573664,
            21.01847736463442
          ],
          [
            105.82770605638386,
            21.01836395631107
          ],
          [
            105.82780356081838,
            21.01837220719262
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Giếng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.01847736463442,
        "to_latitude": 21.01837220719262,
        "to_longitude": 105.82780356081838,
        "from_longitude": 105.82748169573664,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5304,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82780356081838,
          21.018211967139877,
          105.82810933265371,
          21.01837220719262
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82780356081838,
            21.01837220719262
          ],
          [
            105.82786967113697,
            21.018368963810392
          ],
          [
            105.82810933265371,
            21.018211967139877
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Giếng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.01837220719262,
        "to_latitude": 21.018211967139877,
        "to_longitude": 105.82810933265371,
        "from_longitude": 105.82780356081838,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5305,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82810933265371,
          21.018091047352467,
          105.82835073145826,
          21.018211967139877
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82810933265371,
            21.018211967139877
          ],
          [
            105.8283256466687,
            21.018091047352467
          ],
          [
            105.82835073145826,
            21.018111817018866
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Giếng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.018211967139877,
        "to_latitude": 21.018111817018866,
        "to_longitude": 105.82835073145826,
        "from_longitude": 105.82810933265371,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5306,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82659656676854,
          21.019118322673062,
          105.82690770301035,
          21.019303599093092
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82690770301035,
            21.019118322673062
          ],
          [
            105.82659656676854,
            21.019303599093092
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Giếng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.019118322673062,
        "to_latitude": 21.019303599093092,
        "to_longitude": 105.82659656676854,
        "from_longitude": 105.82690770301035,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5307,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82633371028405,
          21.019303599093092,
          105.82659656676854,
          21.019453823048334
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82659656676854,
            21.019303599093092
          ],
          [
            105.82633371028405,
            21.019453823048334
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Giếng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.019303599093092,
        "to_latitude": 21.019453823048334,
        "to_longitude": 105.82633371028405,
        "from_longitude": 105.82659656676854,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5308,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82639808329682,
          21.019218472118105,
          105.82659656676854,
          21.01934792666779
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82659656676854,
            21.019303599093092
          ],
          [
            105.82648565128633,
            21.01934792666779
          ],
          [
            105.82639808329682,
            21.019218472118105
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Giếng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.019303599093092,
        "to_latitude": 21.019218472118105,
        "to_longitude": 105.82639808329682,
        "from_longitude": 105.82659656676854,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5309,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82606548938412,
          21.019453823048334,
          105.82633371028405,
          21.01960404685223
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82633371028405,
            21.019453823048334
          ],
          [
            105.82606548938412,
            21.01960404685223
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Giếng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.019453823048334,
        "to_latitude": 21.01960404685223,
        "to_longitude": 105.82606548938412,
        "from_longitude": 105.82633371028405,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5310,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82582582785838,
          21.01960404685223,
          105.82606548938412,
          21.019728493613687
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82606548938412,
            21.01960404685223
          ],
          [
            105.82582582785838,
            21.019728493613687
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Giếng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.01960404685223,
        "to_latitude": 21.019728493613687,
        "to_longitude": 105.82582582785838,
        "from_longitude": 105.82606548938412,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5311,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82634349420412,
          21.019303599093092,
          105.82659656676854,
          21.019541453619354
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82659656676854,
            21.019303599093092
          ],
          [
            105.82634349420412,
            21.019476868866935
          ],
          [
            105.82637662562593,
            21.019541453619354
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Giếng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.019303599093092,
        "to_latitude": 21.019541453619354,
        "to_longitude": 105.82637662562593,
        "from_longitude": 105.82659656676854,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5312,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82617814215418,
          21.019541453619354,
          105.82637662562593,
          21.019651617691974
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82637662562593,
            21.019541453619354
          ],
          [
            105.82617814215418,
            21.019651617691974
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TCNHN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Giếng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TCNHN",
        "from_latitude": 21.019541453619354,
        "to_latitude": 21.019651617691974,
        "to_longitude": 105.82617814215418,
        "from_longitude": 105.82637662562593,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5313,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82155409102528,
          21.02278570235952,
          105.82195642237522,
          21.02294343382537
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82155409102528,
            21.02294343382537
          ],
          [
            105.82195642237522,
            21.02278570235952
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "diemDau": "1",
        "diemCuoi": "2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC1",
        "from_latitude": 21.02294343382537,
        "to_latitude": 21.02278570235952,
        "to_longitude": 105.82195642237522,
        "from_longitude": 105.82155409102528,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5314,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82195642237522,
          21.022473681489384,
          105.82208080981988,
          21.02278570235952
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82195642237522,
            21.02278570235952
          ],
          [
            105.82208080981988,
            21.022473681489384
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "diemDau": "2",
        "diemCuoi": "3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC1",
        "from_latitude": 21.02278570235952,
        "to_latitude": 21.022473681489384,
        "to_longitude": 105.82208080981988,
        "from_longitude": 105.82195642237522,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5315,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82208080981988,
          21.02218826162645,
          105.82219078038223,
          21.022473681489384
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82208080981988,
            21.022473681489384
          ],
          [
            105.82219078038223,
            21.02218826162645
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "diemDau": "3",
        "diemCuoi": "4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC1",
        "from_latitude": 21.022473681489384,
        "to_latitude": 21.02218826162645,
        "to_longitude": 105.82219078038223,
        "from_longitude": 105.82208080981988,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5316,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82219078038223,
          21.021962147366256,
          105.82227979617748,
          21.02218826162645
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82219078038223,
            21.02218826162645
          ],
          [
            105.82227979617748,
            21.021962147366256
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "diemDau": "4",
        "diemCuoi": "5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC1",
        "from_latitude": 21.02218826162645,
        "to_latitude": 21.021962147366256,
        "to_longitude": 105.82227979617748,
        "from_longitude": 105.82219078038223,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5317,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82219078038223,
          21.022113933446736,
          105.82238138485258,
          21.02218826162645
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82219078038223,
            21.02218826162645
          ],
          [
            105.82238138485258,
            21.022113933446736
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 23 Nguyễn Phúc Lai",
        "diemDau": "4",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02218826162645,
        "to_latitude": 21.022113933446736,
        "to_longitude": 105.82238138485258,
        "from_longitude": 105.82219078038223,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5318,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82238138485258,
          21.022073874454588,
          105.8225718216921,
          21.022113933446736
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82238138485258,
            21.022113933446736
          ],
          [
            105.8225718216921,
            21.022073874454588
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 23 Nguyễn Phúc Lai",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.022113933446736,
        "to_latitude": 21.022073874454588,
        "to_longitude": 105.8225718216921,
        "from_longitude": 105.82238138485258,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5319,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82196061331247,
          21.02212238338958,
          105.82219078038223,
          21.022285122923343
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82219078038223,
            21.02218826162645
          ],
          [
            105.82205180842061,
            21.022285122923343
          ],
          [
            105.82196061331247,
            21.02212238338958
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Nguyễn Phúc Lai",
        "diemDau": "4",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02218826162645,
        "to_latitude": 21.02212238338958,
        "to_longitude": 105.82196061331247,
        "from_longitude": 105.82219078038223,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5320,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82185231912322,
          21.0219455604234,
          105.82196061331247,
          21.02212238338958
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82196061331247,
            21.02212238338958
          ],
          [
            105.82185231912322,
            21.0219455604234
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Nguyễn Phúc Lai",
        "diemDau": "4-3",
        "diemCuoi": "4-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02212238338958,
        "to_latitude": 21.0219455604234,
        "to_longitude": 105.82185231912322,
        "from_longitude": 105.82196061331247,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5321,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8217326255466,
          21.021744326246456,
          105.82185231912322,
          21.0219455604234
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82185231912322,
            21.0219455604234
          ],
          [
            105.8217326255466,
            21.021744326246456
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Nguyễn Phúc Lai",
        "diemDau": "4-4",
        "diemCuoi": "4-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.0219455604234,
        "to_latitude": 21.021744326246456,
        "to_longitude": 105.8217326255466,
        "from_longitude": 105.82185231912322,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5322,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82161729055979,
          21.02151649002168,
          105.8217326255466,
          21.021744326246456
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8217326255466,
            21.021744326246456
          ],
          [
            105.82161729055979,
            21.02151649002168
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Nguyễn Phúc Lai",
        "diemDau": "4-5",
        "diemCuoi": "4-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.021744326246456,
        "to_latitude": 21.02151649002168,
        "to_longitude": 105.82161729055979,
        "from_longitude": 105.8217326255466,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5323,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82179699855935,
          21.02234020395707,
          105.82208080981988,
          21.022473681489384
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82208080981988,
            21.022473681489384
          ],
          [
            105.82195524889697,
            21.022385270240427
          ],
          [
            105.82179699855935,
            21.02234020395707
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 12 Nguyễn Phúc Lai",
        "diemDau": "3",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.022473681489384,
        "to_latitude": 21.02234020395707,
        "to_longitude": 105.82179699855935,
        "from_longitude": 105.82208080981988,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5324,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82165752369391,
          21.02212238338958,
          105.82179699855935,
          21.02234020395707
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82179699855935,
            21.02234020395707
          ],
          [
            105.82165752369391,
            21.02212238338958
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 12 Nguyễn Phúc Lai",
        "diemDau": "3-1",
        "diemCuoi": "3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02234020395707,
        "to_latitude": 21.02212238338958,
        "to_longitude": 105.82165752369391,
        "from_longitude": 105.82179699855935,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5325,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82154218870708,
          21.02191708095416,
          105.82165752369391,
          21.02212238338958
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82165752369391,
            21.02212238338958
          ],
          [
            105.82154218870708,
            21.02191708095416
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 12 Nguyễn Phúc Lai",
        "diemDau": "3-2",
        "diemCuoi": "3-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02212238338958,
        "to_latitude": 21.02191708095416,
        "to_longitude": 105.82154218870708,
        "from_longitude": 105.82165752369391,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5326,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82140003163387,
          21.021676726524017,
          105.82154218870708,
          21.02191708095416
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82154218870708,
            21.02191708095416
          ],
          [
            105.82140003163387,
            21.021676726524017
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 12 Nguyễn Phúc Lai",
        "diemDau": "3-4",
        "diemCuoi": "3-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02191708095416,
        "to_latitude": 21.021676726524017,
        "to_longitude": 105.82140003163387,
        "from_longitude": 105.82154218870708,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5327,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8209950180672,
          21.021676726524017,
          105.82140003163387,
          21.021819437013857
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82140003163387,
            21.021676726524017
          ],
          [
            105.8209950180672,
            21.021819437013857
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 12 Nguyễn Phúc Lai",
        "diemDau": "3-6",
        "diemCuoi": "3-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.021676726524017,
        "to_latitude": 21.021819437013857,
        "to_longitude": 105.8209950180672,
        "from_longitude": 105.82140003163387,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5328,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82093600946992,
          21.021569067643107,
          105.82136248070752,
          21.021664208053558
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82136248070752,
            21.021569067643107
          ],
          [
            105.82093600946992,
            21.021664208053558
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 12 Nguyễn Phúc Lai",
        "diemDau": "3-8",
        "diemCuoi": "3-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.021569067643107,
        "to_latitude": 21.021664208053558,
        "to_longitude": 105.82093600946992,
        "from_longitude": 105.82136248070752,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5329,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82110230643077,
          21.02191708095416,
          105.82154218870708,
          21.022054783838573
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82154218870708,
            21.02191708095416
          ],
          [
            105.82110230643077,
            21.022054783838573
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 12 Nguyễn Phúc Lai",
        "diemDau": "3-4",
        "diemCuoi": "3-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02191708095416,
        "to_latitude": 21.022054783838573,
        "to_longitude": 105.82110230643077,
        "from_longitude": 105.82154218870708,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5330,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82120691257764,
          21.02212238338958,
          105.82165752369391,
          21.02229513765925
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82165752369391,
            21.02212238338958
          ],
          [
            105.82120691257764,
            21.02229513765925
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 12 Nguyễn Phúc Lai",
        "diemDau": "3-2",
        "diemCuoi": "3-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02212238338958,
        "to_latitude": 21.02229513765925,
        "to_longitude": 105.82120691257764,
        "from_longitude": 105.82165752369391,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5331,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82219128327709,
          21.021962147366256,
          105.82227979617748,
          21.02198217687911
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82227979617748,
            21.021962147366256
          ],
          [
            105.82219128327709,
            21.02198217687911
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Nguyễn Phúc Lai",
        "diemDau": "5",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.021962147366256,
        "to_latitude": 21.02198217687911,
        "to_longitude": 105.82219128327709,
        "from_longitude": 105.82227979617748,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5332,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82204644400514,
          21.021744326246456,
          105.82219128327709,
          21.02198217687911
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82219128327709,
            21.02198217687911
          ],
          [
            105.82204644400514,
            21.021744326246456
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Nguyễn Phúc Lai",
        "diemDau": "5-1",
        "diemCuoi": "5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02198217687911,
        "to_latitude": 21.021744326246456,
        "to_longitude": 105.82204644400514,
        "from_longitude": 105.82219128327709,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5333,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82189355808298,
          21.02129600807188,
          105.82209992056129,
          21.021518993718843
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82209992056129,
            21.02129600807188
          ],
          [
            105.82189355808298,
            21.021518993718843
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Nguyễn Phúc Lai",
        "diemDau": "8",
        "diemCuoi": "5-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02129600807188,
        "to_latitude": 21.021518993718843,
        "to_longitude": 105.82189355808298,
        "from_longitude": 105.82209992056129,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5334,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82227979617748,
          21.021747612345287,
          105.82235573623922,
          21.021962147366256
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82227979617748,
            21.021962147366256
          ],
          [
            105.82235573623922,
            21.021747612345287
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "diemDau": "5",
        "diemCuoi": "6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC1",
        "from_latitude": 21.021962147366256,
        "to_latitude": 21.021747612345287,
        "to_longitude": 105.82235573623922,
        "from_longitude": 105.82227979617748,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5335,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82235573623922,
          21.021665772861272,
          105.82265765237956,
          21.021747612345287
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82235573623922,
            21.021747612345287
          ],
          [
            105.82265765237956,
            21.021665772861272
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 Nguyễn Phúc Lai",
        "diemDau": "6",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.021747612345287,
        "to_latitude": 21.021665772861272,
        "to_longitude": 105.82265765237956,
        "from_longitude": 105.82235573623922,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5336,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82225951200277,
          21.021555140836735,
          105.82235573623922,
          21.021747612345287
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82235573623922,
            21.021747612345287
          ],
          [
            105.82225951200277,
            21.021555140836735
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "diemDau": "6",
        "diemCuoi": "7",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC1",
        "from_latitude": 21.021747612345287,
        "to_latitude": 21.021555140836735,
        "to_longitude": 105.82225951200277,
        "from_longitude": 105.82235573623922,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5337,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82225280646546,
          21.021528852023376,
          105.82225951200277,
          21.021555140836735
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82225280646546,
            21.021528852023376
          ],
          [
            105.82225951200277,
            21.021555140836735
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "",
        "tuDien2": "HC1",
        "from_latitude": 21.021528852023376,
        "to_latitude": 21.021555140836735,
        "to_longitude": 105.82225951200277,
        "from_longitude": 105.82225280646546,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5338,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82209992056129,
          21.02129600807188,
          105.82225280646546,
          21.021528852023376
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82225280646546,
            21.021528852023376
          ],
          [
            105.82209992056129,
            21.02129600807188
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "diemDau": "7",
        "diemCuoi": "8",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC1",
        "from_latitude": 21.021528852023376,
        "to_latitude": 21.02129600807188,
        "to_longitude": 105.82209992056129,
        "from_longitude": 105.82225280646546,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5339,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82191753035397,
          21.020988052604952,
          105.82209992056129,
          21.02129600807188
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82209992056129,
            21.02129600807188
          ],
          [
            105.82191753035397,
            21.020988052604952
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "diemDau": "8",
        "diemCuoi": "9",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC1",
        "from_latitude": 21.02129600807188,
        "to_latitude": 21.020988052604952,
        "to_longitude": 105.82191753035397,
        "from_longitude": 105.82209992056129,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5340,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82163858061406,
          21.020988052604952,
          105.82191753035397,
          21.021023104479696
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82191753035397,
            21.020988052604952
          ],
          [
            105.82163858061406,
            21.021023104479696
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 44 Nguyễn Phúc Lai",
        "diemDau": "9",
        "diemCuoi": "9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020988052604952,
        "to_latitude": 21.021023104479696,
        "to_longitude": 105.82163858061406,
        "from_longitude": 105.82191753035397,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5341,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82123624926413,
          21.021023104479696,
          105.82163858061406,
          21.021103223017086
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82163858061406,
            21.021023104479696
          ],
          [
            105.82123624926413,
            21.021103223017086
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 44 Nguyễn Phúc Lai",
        "diemDau": "9-1",
        "diemCuoi": "9-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.021023104479696,
        "to_latitude": 21.021103223017086,
        "to_longitude": 105.82123624926413,
        "from_longitude": 105.82163858061406,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5342,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82106743771216,
          21.021103223017086,
          105.82123624926413,
          21.021137805415012
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82123624926413,
            21.021103223017086
          ],
          [
            105.82106743771216,
            21.021137805415012
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 44 Nguyễn Phúc Lai",
        "diemDau": "9-2",
        "diemCuoi": "9-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.021103223017086,
        "to_latitude": 21.021137805415012,
        "to_longitude": 105.82106743771216,
        "from_longitude": 105.82123624926413,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5343,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82123624926413,
          21.020883679338816,
          105.82133699972498,
          21.021103223017086
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82123624926413,
            21.021103223017086
          ],
          [
            105.82133699972498,
            21.021065823935608
          ],
          [
            105.82129006106254,
            21.020883679338816
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 44 Nguyễn Phúc Lai",
        "diemDau": "9-2",
        "diemCuoi": "9-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.021103223017086,
        "to_latitude": 21.020883679338816,
        "to_longitude": 105.82129006106254,
        "from_longitude": 105.82123624926413,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5344,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8216358984063,
          21.02075270409636,
          105.82183438186907,
          21.020807785696228
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82183438186907,
            21.02075270409636
          ],
          [
            105.8216358984063,
            21.020807785696228
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 56 Nguyễn Phúc Lai",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02075270409636,
        "to_latitude": 21.020807785696228,
        "to_longitude": 105.8216358984063,
        "from_longitude": 105.82183438186907,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5345,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82171904689125,
          21.020494821794017,
          105.82183438186907,
          21.02075270409636
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82183438186907,
            21.02075270409636
          ],
          [
            105.82171904689125,
            21.020494821794017
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC1",
        "from_latitude": 21.02075270409636,
        "to_latitude": 21.020494821794017,
        "to_longitude": 105.82171904689125,
        "from_longitude": 105.82183438186907,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5346,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82183438186907,
          21.02075270409636,
          105.82191753035397,
          21.020988052604952
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82191753035397,
            21.020988052604952
          ],
          [
            105.82183438186907,
            21.02075270409636
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "diemDau": "9",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC1",
        "from_latitude": 21.020988052604952,
        "to_latitude": 21.02075270409636,
        "to_longitude": 105.82183438186907,
        "from_longitude": 105.82191753035397,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5347,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82125234251954,
          21.020494821794017,
          105.82171904689125,
          21.020632525992024
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82171904689125,
            21.020494821794017
          ],
          [
            105.82164947708863,
            21.02054254882758
          ],
          [
            105.82125234251954,
            21.020632525992024
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 52,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 66 Nguyễn Phúc Lai",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020494821794017,
        "to_latitude": 21.020632525992024,
        "to_longitude": 105.82125234251954,
        "from_longitude": 105.82171904689125,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5348,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82170312127042,
          21.02039216949106,
          105.82183169967031,
          21.020494821794017
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82171904689125,
            21.020494821794017
          ],
          [
            105.82170312127042,
            21.02039733340041
          ],
          [
            105.82183169967031,
            21.02039216949106
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 85 Nguyễn Phúc Lai",
        "diemDau": "11",
        "diemCuoi": "11-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020494821794017,
        "to_latitude": 21.02039216949106,
        "to_longitude": 105.82183169967031,
        "from_longitude": 105.82171904689125,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5349,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82157957201679,
          21.020189368390966,
          105.82171904689125,
          21.020494821794017
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82171904689125,
            21.020494821794017
          ],
          [
            105.82157957201679,
            21.020189368390966
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC1",
        "from_latitude": 21.020494821794017,
        "to_latitude": 21.020189368390966,
        "to_longitude": 105.82157957201679,
        "from_longitude": 105.82171904689125,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5350,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82131151873347,
          21.01971319157439,
          105.82152796125884,
          21.020097685817895
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82152796125884,
            21.020097685817895
          ],
          [
            105.82131151873347,
            21.01971319157439
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 52,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "diemDau": "Trạm BA Hoàng Cầu 2",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC1",
        "from_latitude": 21.020097685817895,
        "to_latitude": 21.01971319157439,
        "to_longitude": 105.82131151873347,
        "from_longitude": 105.82152796125884,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5351,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82116919403462,
          21.01941321348871,
          105.82131151873347,
          21.01971319157439
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82131151873347,
            21.01971319157439
          ],
          [
            105.82116919403462,
            21.01941321348871
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC1",
        "from_latitude": 21.01971319157439,
        "to_latitude": 21.01941321348871,
        "to_longitude": 105.82116919403462,
        "from_longitude": 105.82131151873347,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5352,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82125234251954,
          21.020097685817895,
          105.82152796125884,
          21.02018436095361
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82152796125884,
            21.020097685817895
          ],
          [
            105.82125234251954,
            21.02018436095361
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Nguyễn Phúc Lai",
        "diemDau": "12",
        "diemCuoi": "12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020097685817895,
        "to_latitude": 21.02018436095361,
        "to_longitude": 105.82125234251954,
        "from_longitude": 105.82152796125884,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5353,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82100289708276,
          21.02018436095361,
          105.82125234251954,
          21.02022442045345
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82125234251954,
            21.02018436095361
          ],
          [
            105.82100289708276,
            21.02022442045345
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Nguyễn Phúc Lai",
        "diemDau": "12-1",
        "diemCuoi": "12-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02018436095361,
        "to_latitude": 21.02022442045345,
        "to_longitude": 105.82100289708276,
        "from_longitude": 105.82125234251954,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5354,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8207614982692,
          21.02022442045345,
          105.82100289708276,
          21.020254465071062
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82100289708276,
            21.02022442045345
          ],
          [
            105.8207614982692,
            21.020254465071062
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Nguyễn Phúc Lai",
        "diemDau": "12-2",
        "diemCuoi": "12-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02022442045345,
        "to_latitude": 21.020254465071062,
        "to_longitude": 105.8207614982692,
        "from_longitude": 105.82100289708276,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5355,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82052278166337,
          21.020254465071062,
          105.8207614982692,
          21.02031956172192
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8207614982692,
            21.020254465071062
          ],
          [
            105.82052278166337,
            21.02031956172192
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Nguyễn Phúc Lai",
        "diemDau": "12-3",
        "diemCuoi": "12-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020254465071062,
        "to_latitude": 21.02031956172192,
        "to_longitude": 105.82052278166337,
        "from_longitude": 105.8207614982692,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5356,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82067298536882,
          21.0200541675054,
          105.8207614982692,
          21.020254465071062
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8207614982692,
            21.020254465071062
          ],
          [
            105.82067298536882,
            21.0200541675054
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "",
        "tuDien2": "HC1",
        "from_latitude": 21.020254465071062,
        "to_latitude": 21.0200541675054,
        "to_longitude": 105.82067298536882,
        "from_longitude": 105.8207614982692,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5357,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8209572995242,
          21.01941321348871,
          105.82116919403462,
          21.019543407496535
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82116919403462,
            21.01941321348871
          ],
          [
            105.8209572995242,
            21.019543407496535
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "diemDau": "14",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.01941321348871,
        "to_latitude": 21.019543407496535,
        "to_longitude": 105.8209572995242,
        "from_longitude": 105.82116919403462,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5358,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82071858292736,
          21.019543407496535,
          105.8209572995242,
          21.0196610827521
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8209572995242,
            21.019543407496535
          ],
          [
            105.82071858292736,
            21.0196610827521
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.019543407496535,
        "to_latitude": 21.0196610827521,
        "to_longitude": 105.82071858292736,
        "from_longitude": 105.8209572995242,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5359,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82057374363744,
          21.0196610827521,
          105.82071858292736,
          21.019713661027797
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82071858292736,
            21.0196610827521
          ],
          [
            105.82057374363744,
            21.019713661027797
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.0196610827521,
        "to_latitude": 21.019713661027797,
        "to_longitude": 105.82057374363744,
        "from_longitude": 105.82071858292736,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5360,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82057374363744,
          21.019713661027797,
          105.82059268672624,
          21.019880941187132
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82057374363744,
            21.019713661027797
          ],
          [
            105.82059268672624,
            21.019880941187132
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "diemDau": "23",
        "diemCuoi": "23-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.019713661027797,
        "to_latitude": 21.019880941187132,
        "to_longitude": 105.82059268672624,
        "from_longitude": 105.82057374363744,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5361,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82025456076342,
          21.019703646119325,
          105.82057374363744,
          21.019713661027797
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82057374363744,
            21.019713661027797
          ],
          [
            105.82025456076342,
            21.019703646119325
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.019713661027797,
        "to_latitude": 21.019703646119325,
        "to_longitude": 105.82025456076342,
        "from_longitude": 105.82057374363744,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5362,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82025456076342,
          21.019703646119325,
          105.82033234482387,
          21.019881410640018
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82025456076342,
            21.019703646119325
          ],
          [
            105.82033234482387,
            21.019881410640018
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "diemDau": "24",
        "diemCuoi": "24-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.019703646119325,
        "to_latitude": 21.019881410640018,
        "to_longitude": 105.82033234482387,
        "from_longitude": 105.82025456076342,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5363,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82033234482387,
          21.019881410640018,
          105.82040744667657,
          21.0200516637845
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82033234482387,
            21.019881410640018
          ],
          [
            105.82040744667657,
            21.0200516637845
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "diemDau": "24-1",
        "diemCuoi": "24-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.019881410640018,
        "to_latitude": 21.0200516637845,
        "to_longitude": 105.82040744667657,
        "from_longitude": 105.82033234482387,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5364,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82001852637434,
          21.019703646119325,
          105.82025456076342,
          21.019723675934753
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82025456076342,
            21.019703646119325
          ],
          [
            105.82001852637434,
            21.019723675934753
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.019703646119325,
        "to_latitude": 21.019723675934753,
        "to_longitude": 105.82001852637434,
        "from_longitude": 105.82025456076342,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5365,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81999723632006,
          21.019723675934753,
          105.82001852637434,
          21.01991348959726
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82001852637434,
            21.019723675934753
          ],
          [
            105.81999723632006,
            21.01991348959726
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.019723675934753,
        "to_latitude": 21.01991348959726,
        "to_longitude": 105.81999723632006,
        "from_longitude": 105.82001852637434,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5366,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81997292881574,
          21.01991348959726,
          105.81999723632006,
          21.020249457635085
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81999723632006,
            21.01991348959726
          ],
          [
            105.81997292881574,
            21.020249457635085
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.01991348959726,
        "to_latitude": 21.020249457635085,
        "to_longitude": 105.81997292881574,
        "from_longitude": 105.81999723632006,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5367,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81997292881574,
          21.020249457635085,
          105.8200373018375,
          21.02045726608096
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81997292881574,
            21.020249457635085
          ],
          [
            105.8200373018375,
            21.02045726608096
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020249457635085,
        "to_latitude": 21.02045726608096,
        "to_longitude": 105.8200373018375,
        "from_longitude": 105.81997292881574,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5368,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81997292881574,
          21.020126775403778,
          105.82012313252119,
          21.020249457635085
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81997292881574,
            21.020249457635085
          ],
          [
            105.82012313252119,
            21.020126775403778
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "diemDau": "27",
        "diemCuoi": "27-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020249457635085,
        "to_latitude": 21.020126775403778,
        "to_longitude": 105.82012313252119,
        "from_longitude": 105.81997292881574,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5369,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82104313021685,
          21.01935062017486,
          105.82116919403462,
          21.01941321348871
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82116919403462,
            21.01941321348871
          ],
          [
            105.82104313021685,
            21.01935062017486
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 148 Nguyễn Phúc Lai",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.01941321348871,
        "to_latitude": 21.01935062017486,
        "to_longitude": 105.82104313021685,
        "from_longitude": 105.82116919403462,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5370,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82075881606147,
          21.01935062017486,
          105.82104313021685,
          21.019400694828196
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82104313021685,
            21.01935062017486
          ],
          [
            105.82075881606147,
            21.019400694828196
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 148 Nguyễn Phúc Lai",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.01935062017486,
        "to_latitude": 21.019400694828196,
        "to_longitude": 105.82075881606147,
        "from_longitude": 105.82104313021685,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5371,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82049059516152,
          21.019400694828196,
          105.82075881606147,
          21.01944325826978
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82075881606147,
            21.019400694828196
          ],
          [
            105.82049059516152,
            21.01944325826978
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 148 Nguyễn Phúc Lai",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.019400694828196,
        "to_latitude": 21.01944325826978,
        "to_longitude": 105.82049059516152,
        "from_longitude": 105.82075881606147,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5372,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82024383192346,
          21.01944325826978,
          105.82049059516152,
          21.019453273195733
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82049059516152,
            21.01944325826978
          ],
          [
            105.82024383192346,
            21.019453273195733
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 148 Nguyễn Phúc Lai",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.01944325826978,
        "to_latitude": 21.019453273195733,
        "to_longitude": 105.82024383192346,
        "from_longitude": 105.82049059516152,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5373,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81998097544796,
          21.01944325826978,
          105.82024383192346,
          21.019453273195733
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82024383192346,
            21.019453273195733
          ],
          [
            105.81998097544796,
            21.01944325826978
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 148 Nguyễn Phúc Lai",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.019453273195733,
        "to_latitude": 21.01944325826978,
        "to_longitude": 105.81998097544796,
        "from_longitude": 105.82024383192346,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5374,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81993269568166,
          21.0192755081646,
          105.81998097544796,
          21.01944325826978
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81998097544796,
            21.01944325826978
          ],
          [
            105.81993269568166,
            21.0192755081646
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 148 Nguyễn Phúc Lai",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.01944325826978,
        "to_latitude": 21.0192755081646,
        "to_longitude": 105.81993269568166,
        "from_longitude": 105.81998097544796,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5375,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81971292218263,
          21.019270657175937,
          105.81993269568166,
          21.0192755081646
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81993269568166,
            21.0192755081646
          ],
          [
            105.81971292218263,
            21.019270657175937
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 148 Nguyễn Phúc Lai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.0192755081646,
        "to_latitude": 21.019270657175937,
        "to_longitude": 105.81971292218263,
        "from_longitude": 105.81993269568166,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5376,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81969666129258,
          21.019270657175937,
          105.81971292218263,
          21.019450769463877
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81971292218263,
            21.019270657175937
          ],
          [
            105.81969666129258,
            21.019450769463877
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 148 Nguyễn Phúc Lai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.019270657175937,
        "to_latitude": 21.019450769463877,
        "to_longitude": 105.81969666129258,
        "from_longitude": 105.81971292218263,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5377,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81943648701584,
          21.019398191095487,
          105.81969666129258,
          21.019450769463877
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81969666129258,
            21.019450769463877
          ],
          [
            105.81943648701584,
            21.019398191095487
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 148 Nguyễn Phúc Lai",
        "diemDau": "20-1",
        "diemCuoi": "20-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.019450769463877,
        "to_latitude": 21.019398191095487,
        "to_longitude": 105.81943648701584,
        "from_longitude": 105.81969666129258,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5378,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82116919403462,
          21.019247967084205,
          105.82148301249315,
          21.01941321348871
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82116919403462,
            21.01941321348871
          ],
          [
            105.82148301249315,
            21.019247967084205
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Nguyễn Phúc Lai",
        "diemDau": "14",
        "diemCuoi": "14-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.01941321348871,
        "to_latitude": 21.019247967084205,
        "to_longitude": 105.82148301249315,
        "from_longitude": 105.82116919403462,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5379,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82148301249315,
          21.019140306449586,
          105.82176196223308,
          21.019247967084205
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82148301249315,
            21.019247967084205
          ],
          [
            105.82176196223308,
            21.019140306449586
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Nguyễn Phúc Lai",
        "diemDau": "14-1",
        "diemCuoi": "14-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.019247967084205,
        "to_latitude": 21.019140306449586,
        "to_longitude": 105.82176196223308,
        "from_longitude": 105.82148301249315,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5380,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8218745498976,
          21.02084549779869,
          105.82211618142438,
          21.020866398163978
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8218745498976,
            21.020866398163978
          ],
          [
            105.82211618142438,
            21.02084549779869
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 69 Nguyễn Phúc Lai",
        "diemDau": "9",
        "diemCuoi": "9-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020866398163978,
        "to_latitude": 21.02084549779869,
        "to_longitude": 105.82211618142438,
        "from_longitude": 105.8218745498976,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5381,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82211618142438,
          21.02084549779869,
          105.82237367349337,
          21.020863023753446
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82211618142438,
            21.02084549779869
          ],
          [
            105.82237367349337,
            21.020863023753446
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 69 Nguyễn Phúc Lai",
        "diemDau": "9-5",
        "diemCuoi": "9-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02084549779869,
        "to_latitude": 21.020863023753446,
        "to_longitude": 105.82237367349337,
        "from_longitude": 105.82211618142438,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5382,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82237367349337,
          21.020795423631416,
          105.82263384777012,
          21.020863023753446
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82237367349337,
            21.020863023753446
          ],
          [
            105.82263384777012,
            21.020795423631416
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 69 Nguyễn Phúc Lai",
        "diemDau": "9-6",
        "diemCuoi": "9-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020863023753446,
        "to_latitude": 21.020795423631416,
        "to_longitude": 105.82263384777012,
        "from_longitude": 105.82237367349337,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5383,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82229052500846,
          21.020627675047496,
          105.82237367349337,
          21.020863023753446
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82237367349337,
            21.020863023753446
          ],
          [
            105.82229052500846,
            21.020627675047496
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 69 Nguyễn Phúc Lai",
        "diemDau": "9-6",
        "diemCuoi": "9-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020863023753446,
        "to_latitude": 21.020627675047496,
        "to_longitude": 105.82229052500846,
        "from_longitude": 105.82237367349337,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5384,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82229052500846,
          21.020622667624032,
          105.82253192382204,
          21.020627675047496
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82229052500846,
            21.020627675047496
          ],
          [
            105.82253192382204,
            21.020622667624032
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 69 Nguyễn Phúc Lai",
        "diemDau": "9-9",
        "diemCuoi": "9-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020627675047496,
        "to_latitude": 21.020622667624032,
        "to_longitude": 105.82253192382204,
        "from_longitude": 105.82229052500846,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5385,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82245682196931,
          21.020427377984014,
          105.82253192382204,
          21.020622667624032
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82253192382204,
            21.020622667624032
          ],
          [
            105.82245682196931,
            21.020427377984014
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 69 Nguyễn Phúc Lai",
        "diemDau": "9-10",
        "diemCuoi": "9-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020622667624032,
        "to_latitude": 21.020427377984014,
        "to_longitude": 105.82245682196931,
        "from_longitude": 105.82253192382204,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5386,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82237367349337,
          21.020863023753446,
          105.82250778394337,
          21.021020757253407
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82237367349337,
            21.020863023753446
          ],
          [
            105.82242916172781,
            21.02086787468696
          ],
          [
            105.82250778394337,
            21.021020757253407
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 69 Nguyễn Phúc Lai",
        "diemDau": "9-6",
        "diemCuoi": "9-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020863023753446,
        "to_latitude": 21.021020757253407,
        "to_longitude": 105.82250778394337,
        "from_longitude": 105.82237367349337,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5387,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82225951200277,
          21.021422914328063,
          105.82248330879547,
          21.021555140836735
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82225951200277,
            21.021555140836735
          ],
          [
            105.82248330879547,
            21.021422914328063
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "7",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.021555140836735,
        "to_latitude": 21.021422914328063,
        "to_longitude": 105.82248330879547,
        "from_longitude": 105.82225951200277,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5388,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82248330879547,
          21.021415403233316,
          105.82260937261324,
          21.021422914328063
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82248330879547,
            21.021422914328063
          ],
          [
            105.82260937261324,
            21.021415403233316
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.021422914328063,
        "to_latitude": 21.021415403233316,
        "to_longitude": 105.82260937261324,
        "from_longitude": 105.82248330879547,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5389,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82260937261324,
          21.021315255263914,
          105.82269788551363,
          21.021415403233316
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82260937261324,
            21.021415403233316
          ],
          [
            105.82269788551363,
            21.021315255263914
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.021415403233316,
        "to_latitude": 21.021315255263914,
        "to_longitude": 105.82269788551363,
        "from_longitude": 105.82260937261324,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5390,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82269788551363,
          21.021260173852372,
          105.82281858491594,
          21.021315255263914
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82269788551363,
            21.021315255263914
          ],
          [
            105.82281858491594,
            21.021260173852372
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.021315255263914,
        "to_latitude": 21.021260173852372,
        "to_longitude": 105.82281858491594,
        "from_longitude": 105.82269788551363,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5391,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82281858491594,
          21.021260173852372,
          105.82288513725953,
          21.02139114865319
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82281858491594,
            21.021260173852372
          ],
          [
            105.82288513725953,
            21.02139114865319
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.021260173852372,
        "to_latitude": 21.02139114865319,
        "to_longitude": 105.82288513725953,
        "from_longitude": 105.82281858491594,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5392,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82288513725953,
          21.02139114865319,
          105.82297633236769,
          21.021591444422768
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82288513725953,
            21.02139114865319
          ],
          [
            105.82297633236769,
            21.021591444422768
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 64,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02139114865319,
        "to_latitude": 21.021591444422768,
        "to_longitude": 105.82297633236769,
        "from_longitude": 105.82288513725953,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5393,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82297633236769,
          21.0214529587042,
          105.82341939973759,
          21.021591444422768
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82297633236769,
            21.021591444422768
          ],
          [
            105.82341939973759,
            21.0214529587042
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.021591444422768,
        "to_latitude": 21.0214529587042,
        "to_longitude": 105.82341939973759,
        "from_longitude": 105.82297633236769,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5394,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82281858491594,
          21.02112998134357,
          105.82338453101899,
          21.021260173852372
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82281858491594,
            21.021260173852372
          ],
          [
            105.82338453101899,
            21.02112998134357
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 52,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.021260173852372,
        "to_latitude": 21.02112998134357,
        "to_longitude": 105.82338453101899,
        "from_longitude": 105.82281858491594,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5395,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82338453101899,
          21.02112998134357,
          105.82349450159032,
          21.02130524046301
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82338453101899,
            21.02112998134357
          ],
          [
            105.82349450159032,
            21.02130524046301
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "32",
        "diemCuoi": "32-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02112998134357,
        "to_latitude": 21.02130524046301,
        "to_longitude": 105.82349450159032,
        "from_longitude": 105.82338453101899,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5396,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82318604755626,
          21.020949714605603,
          105.82338453101899,
          21.02118052484307
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82338453101899,
            21.02112998134357
          ],
          [
            105.82322812473399,
            21.02118052484307
          ],
          [
            105.82318604755626,
            21.020949714605603
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "32",
        "diemCuoi": "32-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02112998134357,
        "to_latitude": 21.020949714605603,
        "to_longitude": 105.82318604755626,
        "from_longitude": 105.82338453101899,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5397,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82338453101899,
          21.020947210899713,
          105.82343867814056,
          21.02112998134357
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82338453101899,
            21.02112998134357
          ],
          [
            105.82343867814056,
            21.02110291005053
          ],
          [
            105.82343817520079,
            21.020947210899713
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "32",
        "diemCuoi": "32-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02112998134357,
        "to_latitude": 21.020947210899713,
        "to_longitude": 105.82343817520079,
        "from_longitude": 105.82338453101899,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5398,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82338453101899,
          21.021069892455127,
          105.82356423902753,
          21.02112998134357
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82338453101899,
            21.02112998134357
          ],
          [
            105.82356423902753,
            21.021069892455127
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02112998134357,
        "to_latitude": 21.021069892455127,
        "to_longitude": 105.82356423902753,
        "from_longitude": 105.82338453101899,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5399,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82356423902753,
          21.021067388751284,
          105.8236903028453,
          21.021069892455127
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82356423902753,
            21.021069892455127
          ],
          [
            105.8236903028453,
            21.021067388751284
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.021069892455127,
        "to_latitude": 21.021067388751284,
        "to_longitude": 105.8236903028453,
        "from_longitude": 105.82356423902753,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5400,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82365543413569,
          21.020892129352198,
          105.8236903028453,
          21.021067388751284
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8236903028453,
            21.021067388751284
          ],
          [
            105.82365543413569,
            21.020892129352198
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "33-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.021067388751284,
        "to_latitude": 21.020892129352198,
        "to_longitude": 105.82365543413569,
        "from_longitude": 105.8236903028453,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5401,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8236903028453,
          21.020874603400877,
          105.82380832004883,
          21.021067388751284
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8236903028453,
            21.021067388751284
          ],
          [
            105.82380832004883,
            21.020874603400877
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.021067388751284,
        "to_latitude": 21.020874603400877,
        "to_longitude": 105.82380832004883,
        "from_longitude": 105.8236903028453,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5402,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82380832004883,
          21.020736899426442,
          105.8239933924639,
          21.020874603400877
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82380832004883,
            21.020874603400877
          ],
          [
            105.8239933924639,
            21.020736899426442
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020874603400877,
        "to_latitude": 21.020736899426442,
        "to_longitude": 105.8239933924639,
        "from_longitude": 105.82380832004883,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5403,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82397729921743,
          21.020736899426442,
          105.8239933924639,
          21.020875855254005
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8239933924639,
            21.020736899426442
          ],
          [
            105.82397729921743,
            21.020875855254005
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "35",
        "diemCuoi": "35-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020736899426442,
        "to_latitude": 21.020875855254005,
        "to_longitude": 105.82397729921743,
        "from_longitude": 105.8239933924639,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5404,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82397729921743,
          21.020875855254005,
          105.8241033630442,
          21.021048610968016
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82397729921743,
            21.020875855254005
          ],
          [
            105.8241033630442,
            21.021048610968016
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "35-1",
        "diemCuoi": "35-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020875855254005,
        "to_latitude": 21.021048610968016,
        "to_longitude": 105.8241033630442,
        "from_longitude": 105.82397729921743,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5405,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8241033630442,
          21.021048610968016,
          105.824311234243,
          21.02124562108877
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8241033630442,
            21.021048610968016
          ],
          [
            105.82421785985792,
            21.02124562108877
          ],
          [
            105.824311234243,
            21.021213855376953
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "35-2",
        "diemCuoi": "35-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.021048610968016,
        "to_latitude": 21.021213855376953,
        "to_longitude": 105.824311234243,
        "from_longitude": 105.8241033630442,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5406,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.824311234243,
          21.021119966531053,
          105.82450167108253,
          21.021213855376953
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.824311234243,
            21.021213855376953
          ],
          [
            105.82450167108253,
            21.021119966531053
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "35-6",
        "diemCuoi": "35-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.021213855376953,
        "to_latitude": 21.021119966531053,
        "to_longitude": 105.82450167108253,
        "from_longitude": 105.824311234243,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5407,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82450167108253,
          21.02100479613186,
          105.82471893000844,
          21.021119966531053
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82450167108253,
            21.021119966531053
          ],
          [
            105.82471893000844,
            21.02100479613186
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "35-7",
        "diemCuoi": "35-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.021119966531053,
        "to_latitude": 21.02100479613186,
        "to_longitude": 105.82471893000844,
        "from_longitude": 105.82450167108253,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5408,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8241033630442,
          21.02095722572381,
          105.82437158393519,
          21.021048610968016
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8241033630442,
            21.021048610968016
          ],
          [
            105.82437158393519,
            21.02095722572381
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "35-2",
        "diemCuoi": "35-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.021048610968016,
        "to_latitude": 21.02095722572381,
        "to_longitude": 105.82437158393519,
        "from_longitude": 105.8241033630442,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5409,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82437158393519,
          21.02086208486309,
          105.82456738519916,
          21.02095722572381
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82437158393519,
            21.02095722572381
          ],
          [
            105.82456738519916,
            21.02086208486309
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "35-3",
        "diemCuoi": "35-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02095722572381,
        "to_latitude": 21.02086208486309,
        "to_longitude": 105.82456738519916,
        "from_longitude": 105.82437158393519,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5410,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82456738519916,
          21.020726884586704,
          105.82469613123365,
          21.02086208486309
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82456738519916,
            21.02086208486309
          ],
          [
            105.82469613123365,
            21.020726884586704
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "35-4",
        "diemCuoi": "35-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02086208486309,
        "to_latitude": 21.020726884586704,
        "to_longitude": 105.82469613123365,
        "from_longitude": 105.82456738519916,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5411,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8239933924639,
          21.020574158201935,
          105.82410604524294,
          21.020736899426442
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8239933924639,
            21.020736899426442
          ],
          [
            105.82410604524294,
            21.020574158201935
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "35",
        "diemCuoi": "36",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020736899426442,
        "to_latitude": 21.020574158201935,
        "to_longitude": 105.82410604524294,
        "from_longitude": 105.8239933924639,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5412,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82410604524294,
          21.020574158201935,
          105.82418382930341,
          21.0207293882963
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82410604524294,
            21.020574158201935
          ],
          [
            105.82418382930341,
            21.0207293882963
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "36",
        "diemCuoi": "36-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020574158201935,
        "to_latitude": 21.0207293882963,
        "to_longitude": 105.82418382930341,
        "from_longitude": 105.82410604524294,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5413,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82418382930341,
          21.020646765847097,
          105.82437694835964,
          21.0207293882963
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82418382930341,
            21.0207293882963
          ],
          [
            105.82437694835964,
            21.020646765847097
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "36-1",
        "diemCuoi": "36-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.0207293882963,
        "to_latitude": 21.020646765847097,
        "to_longitude": 105.82437694835964,
        "from_longitude": 105.82418382930341,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5414,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82437694835964,
          21.020559135925673,
          105.82454592752822,
          21.020646765847097
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82437694835964,
            21.020646765847097
          ],
          [
            105.82454592752822,
            21.020559135925673
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "36-2",
        "diemCuoi": "36-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020646765847097,
        "to_latitude": 21.020559135925673,
        "to_longitude": 105.82454592752822,
        "from_longitude": 105.82437694835964,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5415,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82410604524294,
          21.02030751257984,
          105.82418919372788,
          21.020574158201935
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82410604524294,
            21.020574158201935
          ],
          [
            105.82418919372788,
            21.02030751257984
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020574158201935,
        "to_latitude": 21.02030751257984,
        "to_longitude": 105.82418919372788,
        "from_longitude": 105.82410604524294,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5416,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82418919372788,
          21.020207363867115,
          105.82437963056738,
          21.02030751257984
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82418919372788,
            21.02030751257984
          ],
          [
            105.82437963056738,
            21.020207363867115
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "37",
        "diemCuoi": "38",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02030751257984,
        "to_latitude": 21.020207363867115,
        "to_longitude": 105.82437963056738,
        "from_longitude": 105.82418919372788,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5417,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82437963056738,
          21.0200596443919,
          105.82465053368408,
          21.020207363867115
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82437963056738,
            21.020207363867115
          ],
          [
            105.82465053368408,
            21.0200596443919
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "38",
        "diemCuoi": "39",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020207363867115,
        "to_latitude": 21.0200596443919,
        "to_longitude": 105.82465053368408,
        "from_longitude": 105.82437963056738,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5418,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82465053368408,
          21.0200596443919,
          105.82469613123365,
          21.020154785765502
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82465053368408,
            21.0200596443919
          ],
          [
            105.82469613123365,
            21.020154785765502
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "39",
        "diemCuoi": "40",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.0200596443919,
        "to_latitude": 21.020154785765502,
        "to_longitude": 105.82469613123365,
        "from_longitude": 105.82465053368408,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5419,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82469613123365,
          21.020024592291488,
          105.82490802574408,
          21.020154785765502
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82469613123365,
            21.020154785765502
          ],
          [
            105.82490802574408,
            21.020024592291488
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "40",
        "diemCuoi": "41",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020154785765502,
        "to_latitude": 21.020024592291488,
        "to_longitude": 105.82490802574408,
        "from_longitude": 105.82469613123365,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5420,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82390219736472,
          21.020284196713508,
          105.82418919372788,
          21.020382624071086
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82390219736472,
            21.020382624071086
          ],
          [
            105.82407838499245,
            21.020284196713508
          ],
          [
            105.82418919372788,
            21.02030751257984
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020382624071086,
        "to_latitude": 21.02030751257984,
        "to_longitude": 105.82418919372788,
        "from_longitude": 105.82390219736472,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5421,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82403899002246,
          21.020137259727502,
          105.82418919372788,
          21.02030751257984
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82418919372788,
            21.02030751257984
          ],
          [
            105.82403899002246,
            21.020137259727502
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "diemDau": "37",
        "diemCuoi": "37-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02030751257984,
        "to_latitude": 21.020137259727502,
        "to_longitude": 105.82403899002246,
        "from_longitude": 105.82418919372788,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5422,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82152796125884,
          21.020088437182974,
          105.82208097743654,
          21.020097685817895
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82152796125884,
            21.020097685817895
          ],
          [
            105.82208097743654,
            21.020088437182974
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 69,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "diemDau": "12",
        "diemCuoi": "42",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020097685817895,
        "to_latitude": 21.020088437182974,
        "to_longitude": 105.82208097743654,
        "from_longitude": 105.82152796125884,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5423,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82208097743654,
          21.02008593346178,
          105.8224564867001,
          21.020088437182974
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82208097743654,
            21.020088437182974
          ],
          [
            105.8224564867001,
            21.02008593346178
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "diemDau": "42",
        "diemCuoi": "43",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020088437182974,
        "to_latitude": 21.02008593346178,
        "to_longitude": 105.8224564867001,
        "from_longitude": 105.82208097743654,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5424,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8224564867001,
          21.02003085159608,
          105.82273543644,
          21.02008593346178
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8224564867001,
            21.02008593346178
          ],
          [
            105.82273543644,
            21.02003085159608
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "diemDau": "43",
        "diemCuoi": "41",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02008593346178,
        "to_latitude": 21.02003085159608,
        "to_longitude": 105.82273543644,
        "from_longitude": 105.8224564867001,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5425,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82273543644,
          21.01999329576702,
          105.82297147082909,
          21.02003085159608
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82273543644,
            21.02003085159608
          ],
          [
            105.82297147082909,
            21.01999329576702
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "diemDau": "44",
        "diemCuoi": "45",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02003085159608,
        "to_latitude": 21.01999329576702,
        "to_longitude": 105.82297147082909,
        "from_longitude": 105.82273543644,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5426,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82297147082909,
          21.01999329576702,
          105.82333625126168,
          21.020013325543527
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82297147082909,
            21.01999329576702
          ],
          [
            105.82333625126168,
            21.020013325543527
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "diemDau": "45",
        "diemCuoi": "46",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.01999329576702,
        "to_latitude": 21.020013325543527,
        "to_longitude": 105.82333625126168,
        "from_longitude": 105.82297147082909,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5427,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82292319107177,
          21.01999329576702,
          105.82297147082909,
          21.020311268156025
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82297147082909,
            21.01999329576702
          ],
          [
            105.82292319107177,
            21.020311268156025
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "diemDau": "45",
        "diemCuoi": "45-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.01999329576702,
        "to_latitude": 21.020311268156025,
        "to_longitude": 105.82292319107177,
        "from_longitude": 105.82297147082909,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5428,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82292319107177,
          21.020311268156025,
          105.8229446487427,
          21.02054912107401
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82292319107177,
            21.020311268156025
          ],
          [
            105.8229446487427,
            21.02054912107401
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "diemDau": "45-1",
        "diemCuoi": "45-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020311268156025,
        "to_latitude": 21.02054912107401,
        "to_longitude": 105.8229446487427,
        "from_longitude": 105.82292319107177,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5429,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82333625126168,
          21.020013325543527,
          105.8235132770535,
          21.020313771872587
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82333625126168,
            21.020013325543527
          ],
          [
            105.8235132770535,
            21.020313771872587
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "diemDau": "46",
        "diemCuoi": "46-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020013325543527,
        "to_latitude": 21.020313771872587,
        "to_longitude": 105.8235132770535,
        "from_longitude": 105.82333625126168,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5430,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82342744636082,
          21.020183578538216,
          105.8235132770535,
          21.020313771872587
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8235132770535,
            21.020313771872587
          ],
          [
            105.82342744636082,
            21.020183578538216
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "diemDau": "46-2",
        "diemCuoi": "46-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020313771872587,
        "to_latitude": 21.020183578538216,
        "to_longitude": 105.82342744636082,
        "from_longitude": 105.8235132770535,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5431,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82332552242171,
          21.020313771872587,
          105.8235132770535,
          21.020346320189045
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8235132770535,
            21.020313771872587
          ],
          [
            105.82332552242171,
            21.020346320189045
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "diemDau": "46-2",
        "diemCuoi": "46-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020313771872587,
        "to_latitude": 21.020346320189045,
        "to_longitude": 105.82332552242171,
        "from_longitude": 105.8235132770535,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5432,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82321823405813,
          21.020346320189045,
          105.82332552242171,
          21.020456483667015
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82332552242171,
            21.020346320189045
          ],
          [
            105.82331663762541,
            21.020439427110563
          ],
          [
            105.82321823405813,
            21.020456483667015
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "diemDau": "46-7",
        "diemCuoi": "46-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020346320189045,
        "to_latitude": 21.020456483667015,
        "to_longitude": 105.82321823405813,
        "from_longitude": 105.82332552242171,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5433,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82348025238751,
          21.020313771872587,
          105.8235615568198,
          21.02053409879607
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8235132770535,
            21.020313771872587
          ],
          [
            105.82348025238751,
            21.020349293350545
          ],
          [
            105.8235615568198,
            21.02053409879607
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "diemDau": "46-2",
        "diemCuoi": "46-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020313771872587,
        "to_latitude": 21.02053409879607,
        "to_longitude": 105.8235615568198,
        "from_longitude": 105.8235132770535,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5434,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8235615568198,
          21.02053409879607,
          105.82361084242073,
          21.020668986281457
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8235615568198,
            21.02053409879607
          ],
          [
            105.82361084242073,
            21.020668986281457
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "diemDau": "46-3",
        "diemCuoi": "46-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.02053409879607,
        "to_latitude": 21.020668986281457,
        "to_longitude": 105.82361084242073,
        "from_longitude": 105.8235615568198,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5435,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8235132770535,
          21.020188585975568,
          105.82373858261161,
          21.020313771872587
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8235132770535,
            21.020313771872587
          ],
          [
            105.82373858261161,
            21.020188585975568
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "diemDau": "46-2",
        "diemCuoi": "46-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020313771872587,
        "to_latitude": 21.020188585975568,
        "to_longitude": 105.82373858261161,
        "from_longitude": 105.8235132770535,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5436,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82373858261161,
          21.020010821821938,
          105.82394243049882,
          21.020188585975568
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82373858261161,
            21.020188585975568
          ],
          [
            105.82394243049882,
            21.020010821821938
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "diemDau": "46-5",
        "diemCuoi": "46-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020188585975568,
        "to_latitude": 21.020010821821938,
        "to_longitude": 105.82394243049882,
        "from_longitude": 105.82373858261161,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5437,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82333625126168,
          21.019950732482464,
          105.82361788320932,
          21.020013325543527
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82333625126168,
            21.020013325543527
          ],
          [
            105.82361788320932,
            21.019950732482464
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "diemDau": "46",
        "diemCuoi": "47",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.020013325543527,
        "to_latitude": 21.019950732482464,
        "to_longitude": 105.82361788320932,
        "from_longitude": 105.82333625126168,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5438,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82361788320932,
          21.019752938235598,
          105.82391560840344,
          21.019950732482464
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82361788320932,
            21.019950732482464
          ],
          [
            105.82391560840344,
            21.019752938235598
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "diemDau": "47",
        "diemCuoi": "48",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.019950732482464,
        "to_latitude": 21.019752938235598,
        "to_longitude": 105.82391560840344,
        "from_longitude": 105.82361788320932,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5439,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82391560840344,
          21.019590195937145,
          105.82416773605695,
          21.019752938235598
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82391560840344,
            21.019752938235598
          ],
          [
            105.82416773605695,
            21.019590195937145
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "diemDau": "48",
        "diemCuoi": "49",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.019752938235598,
        "to_latitude": 21.019590195937145,
        "to_longitude": 105.82416773605695,
        "from_longitude": 105.82391560840344,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5440,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82416773605695,
          21.01944247585048,
          105.8243876771906,
          21.019590195937145
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82416773605695,
            21.019590195937145
          ],
          [
            105.8243876771906,
            21.01944247585048
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "diemDau": "49",
        "diemCuoi": "50",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.019590195937145,
        "to_latitude": 21.01944247585048,
        "to_longitude": 105.8243876771906,
        "from_longitude": 105.82416773605695,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5441,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8243876771906,
          21.01929475561832,
          105.82460493612551,
          21.01944247585048
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8243876771906,
            21.01944247585048
          ],
          [
            105.82460493612551,
            21.01929475561832
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "diemDau": "50",
        "diemCuoi": "51",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.01944247585048,
        "to_latitude": 21.01929475561832,
        "to_longitude": 105.82460493612551,
        "from_longitude": 105.8243876771906,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5442,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82152796125884,
          21.020097685817895,
          105.82157957201679,
          21.020189368390966
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82157957201679,
            21.020189368390966
          ],
          [
            105.82152796125884,
            21.020097685817895
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "diemDau": "12",
        "diemCuoi": "trạm BA Hoàng Cầu 2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HC1",
        "from_latitude": 21.020189368390966,
        "to_latitude": 21.020097685817895,
        "to_longitude": 105.82152796125884,
        "from_longitude": 105.82157957201679,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5443,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82136248070752,
          21.021569067643107,
          105.82140003163387,
          21.021676726524017
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82140003163387,
            21.021676726524017
          ],
          [
            105.82136248070752,
            21.021569067643107
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HC1",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 12 Nguyễn Phúc Lai",
        "diemDau": "3-6",
        "diemCuoi": "3-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "HC1",
        "from_latitude": 21.021676726524017,
        "to_latitude": 21.021569067643107,
        "to_longitude": 105.82136248070752,
        "from_longitude": 105.82140003163387,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5444,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82344387491244,
          21.01634125915203,
          105.82377646883415,
          21.016506508777585
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82344387491244,
            21.01634125915203
          ],
          [
            105.82377646883415,
            21.016506508777585
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HT",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HT",
        "from_latitude": 21.01634125915203,
        "to_latitude": 21.016506508777585,
        "to_longitude": 105.82377646883415,
        "from_longitude": 105.82344387491244,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5445,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82377646883415,
          21.01640635751118,
          105.82400713880776,
          21.016506508777585
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82377646883415,
            21.016506508777585
          ],
          [
            105.82400713880776,
            21.01640635751118
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HT",
        "from_latitude": 21.016506508777585,
        "to_latitude": 21.01640635751118,
        "to_longitude": 105.82400713880776,
        "from_longitude": 105.82377646883415,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5446,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82400713880776,
          21.016281168333837,
          105.82428608854767,
          21.01640635751118
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82400713880776,
            21.01640635751118
          ],
          [
            105.82428608854767,
            21.016281168333837
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HT",
        "from_latitude": 21.01640635751118,
        "to_latitude": 21.016281168333837,
        "to_longitude": 105.82428608854767,
        "from_longitude": 105.82400713880776,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5447,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82428608854767,
          21.016281168333837,
          105.82444702108405,
          21.01655157682494
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82428608854767,
            21.016281168333837
          ],
          [
            105.82444702108405,
            21.01655157682494
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HT",
        "from_latitude": 21.016281168333837,
        "to_latitude": 21.01655157682494,
        "to_longitude": 105.82444702108405,
        "from_longitude": 105.82428608854767,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5448,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82433973272947,
          21.01651151633927,
          105.82444702108405,
          21.01655157682494
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82444702108405,
            21.01655157682494
          ],
          [
            105.82433973272947,
            21.01651151633927
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HT",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HT",
        "from_latitude": 21.01655157682494,
        "to_latitude": 21.01651151633927,
        "to_longitude": 105.82433973272947,
        "from_longitude": 105.82444702108405,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5449,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82410369833138,
          21.01651151633927,
          105.82433973272947,
          21.016631697765774
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82433973272947,
            21.01651151633927
          ],
          [
            105.82410369833138,
            21.016631697765774
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HT",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HT",
        "from_latitude": 21.01651151633927,
        "to_latitude": 21.016631697765774,
        "to_longitude": 105.82410369833138,
        "from_longitude": 105.82433973272947,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5450,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82390521486865,
          21.016631697765774,
          105.82410369833138,
          21.01671682621748
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82410369833138,
            21.016631697765774
          ],
          [
            105.82390521486865,
            21.01671682621748
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HT",
        "from_latitude": 21.016631697765774,
        "to_latitude": 21.01671682621748,
        "to_longitude": 105.82390521486865,
        "from_longitude": 105.82410369833138,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5451,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82444702108405,
          21.01655157682494,
          105.82449530085036,
          21.016831999927795
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82444702108405,
            21.01655157682494
          ],
          [
            105.82449530085036,
            21.016831999927795
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HT",
        "from_latitude": 21.01655157682494,
        "to_latitude": 21.016831999927795,
        "to_longitude": 105.82449530085036,
        "from_longitude": 105.82444702108405,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5452,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82449530085036,
          21.016736856434115,
          105.82471524199302,
          21.016831999927795
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82449530085036,
            21.016831999927795
          ],
          [
            105.82471524199302,
            21.016736856434115
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HT",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HT",
        "from_latitude": 21.016831999927795,
        "to_latitude": 21.016736856434115,
        "to_longitude": 105.82471524199302,
        "from_longitude": 105.82449530085036,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5453,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82471524199302,
          21.016626690208145,
          105.82496200522206,
          21.016736856434115
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82471524199302,
            21.016736856434115
          ],
          [
            105.82496200522206,
            21.016626690208145
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HT",
        "from_latitude": 21.016736856434115,
        "to_latitude": 21.016626690208145,
        "to_longitude": 105.82496200522206,
        "from_longitude": 105.82471524199302,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5454,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82496200522206,
          21.016626690208145,
          105.82526777704837,
          21.016626690208145
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82496200522206,
            21.016626690208145
          ],
          [
            105.82526777704837,
            21.016626690208145
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HT",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HT",
        "from_latitude": 21.016626690208145,
        "to_latitude": 21.016626690208145,
        "to_longitude": 105.82526777704837,
        "from_longitude": 105.82496200522206,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5455,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82526777704837,
          21.016626690208145,
          105.82548235376655,
          21.016872060326563
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82526777704837,
            21.016626690208145
          ],
          [
            105.82542066295254,
            21.016872060326563
          ],
          [
            105.82548235376655,
            21.016842015029123
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HT",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HT",
        "from_latitude": 21.016626690208145,
        "to_latitude": 21.016842015029123,
        "to_longitude": 105.82548235376655,
        "from_longitude": 105.82526777704837,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5456,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82548235376655,
          21.01671682621748,
          105.82572375258012,
          21.016842015029123
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82548235376655,
            21.016842015029123
          ],
          [
            105.82572375258012,
            21.01671682621748
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HT",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HT",
        "from_latitude": 21.016842015029123,
        "to_latitude": 21.01671682621748,
        "to_longitude": 105.82572375258012,
        "from_longitude": 105.82548235376655,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5457,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82444702108405,
          21.016461440715737,
          105.82468841989761,
          21.01655157682494
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82444702108405,
            21.01655157682494
          ],
          [
            105.82468841989761,
            21.016461440715737
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HT",
        "from_latitude": 21.01655157682494,
        "to_latitude": 21.016461440715737,
        "to_longitude": 105.82468841989761,
        "from_longitude": 105.82444702108405,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5458,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82468841989761,
          21.01634125915203,
          105.82495664079758,
          21.016461440715737
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82468841989761,
            21.016461440715737
          ],
          [
            105.82495664079758,
            21.01634125915203
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HT",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HT",
        "from_latitude": 21.016461440715737,
        "to_latitude": 21.01634125915203,
        "to_longitude": 105.82495664079758,
        "from_longitude": 105.82468841989761,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5459,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82485471685847,
          21.0161760093442,
          105.82495664079758,
          21.01634125915203
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82495664079758,
            21.01634125915203
          ],
          [
            105.82485471685847,
            21.0161760093442
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HT",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HT",
        "from_latitude": 21.01634125915203,
        "to_latitude": 21.0161760093442,
        "to_longitude": 105.82485471685847,
        "from_longitude": 105.82495664079758,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5460,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82485471685847,
          21.016105903309356,
          105.82494591196662,
          21.0161760093442
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82485471685847,
            21.0161760093442
          ],
          [
            105.82494591196662,
            21.016105903309356
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HT",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HT",
        "from_latitude": 21.0161760093442,
        "to_latitude": 21.016105903309356,
        "to_longitude": 105.82494591196662,
        "from_longitude": 105.82485471685847,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5461,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82457040270307,
          21.016236100204754,
          105.82468841989761,
          21.016461440715737
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82468841989761,
            21.016461440715737
          ],
          [
            105.82457040270307,
            21.016236100204754
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HT",
        "from_latitude": 21.016461440715737,
        "to_latitude": 21.016236100204754,
        "to_longitude": 105.82457040270307,
        "from_longitude": 105.82468841989761,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5462,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8244094701577,
          21.0159606835616,
          105.82457040270307,
          21.016236100204754
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82457040270307,
            21.016236100204754
          ],
          [
            105.8244094701577,
            21.0159606835616
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HT",
        "from_latitude": 21.016236100204754,
        "to_latitude": 21.0159606835616,
        "to_longitude": 105.8244094701577,
        "from_longitude": 105.82457040270307,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5463,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8241788001841,
          21.0159606835616,
          105.8244094701577,
          21.016040804819024
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8244094701577,
            21.0159606835616
          ],
          [
            105.8241788001841,
            21.016040804819024
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HT",
        "from_latitude": 21.0159606835616,
        "to_latitude": 21.016040804819024,
        "to_longitude": 105.8241788001841,
        "from_longitude": 105.8244094701577,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5464,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82400177439229,
          21.016040804819024,
          105.8241788001841,
          21.016135948756776
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8241788001841,
            21.016040804819024
          ],
          [
            105.82400177439229,
            21.016135948756776
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HT",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HT",
        "from_latitude": 21.016040804819024,
        "to_latitude": 21.016135948756776,
        "to_longitude": 105.82400177439229,
        "from_longitude": 105.8241788001841,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5465,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8244094701577,
          21.015835494010137,
          105.82462404688485,
          21.0159606835616
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8244094701577,
            21.0159606835616
          ],
          [
            105.82462404688485,
            21.015835494010137
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HT",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HT",
        "from_latitude": 21.0159606835616,
        "to_latitude": 21.015835494010137,
        "to_longitude": 105.82462404688485,
        "from_longitude": 105.8244094701577,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5466,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82716510462798,
          21.01315609944442,
          105.82738772796938,
          21.013301321922935
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82716510462798,
            21.013301321922935
          ],
          [
            105.82738772796938,
            21.01315609944442
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.013301321922935,
        "to_latitude": 21.01315609944442,
        "to_longitude": 105.82738772796938,
        "from_longitude": 105.82716510462798,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5467,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82738772796938,
          21.013008372985368,
          105.82764522003838,
          21.01315609944442
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82738772796938,
            21.01315609944442
          ],
          [
            105.82764522003838,
            21.013008372985368
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.01315609944442,
        "to_latitude": 21.013008372985368,
        "to_longitude": 105.82764522003838,
        "from_longitude": 105.82738772796938,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5468,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82764522003838,
          21.012833104114055,
          105.82794830965696,
          21.013008372985368
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82764522003838,
            21.013008372985368
          ],
          [
            105.82794830965696,
            21.012833104114055
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.013008372985368,
        "to_latitude": 21.012833104114055,
        "to_longitude": 105.82794830965696,
        "from_longitude": 105.82764522003838,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5469,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82764522003838,
          21.013008372985368,
          105.82787488419531,
          21.01323872604464
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82764522003838,
            21.013008372985368
          ],
          [
            105.82776759583174,
            21.013031220517338
          ],
          [
            105.82787488419531,
            21.013216504498956
          ],
          [
            105.82784906792558,
            21.01323872604464
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Nhà VH Nam Đồng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.013008372985368,
        "to_latitude": 21.01323872604464,
        "to_longitude": 105.82784906792558,
        "from_longitude": 105.82764522003838,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5470,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82819239067825,
          21.01260024658234,
          105.82838282751776,
          21.012710415782458
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82819239067825,
            21.012710415782458
          ],
          [
            105.82838282751776,
            21.01260024658234
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.012710415782458,
        "to_latitude": 21.01260024658234,
        "to_longitude": 105.82838282751776,
        "from_longitude": 105.82819239067825,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5471,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82845256495499,
          21.012720431160567,
          105.82856521773405,
          21.012903211687306
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82845256495499,
            21.012720431160567
          ],
          [
            105.82856521773405,
            21.012903211687306
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.012720431160567,
        "to_latitude": 21.012903211687306,
        "to_longitude": 105.82856521773405,
        "from_longitude": 105.82845256495499,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5472,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82856521773405,
          21.012903211687306,
          105.8286617772577,
          21.013083488152148
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82856521773405,
            21.012903211687306
          ],
          [
            105.8286617772577,
            21.013083488152148
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.012903211687306,
        "to_latitude": 21.013083488152148,
        "to_longitude": 105.8286617772577,
        "from_longitude": 105.82856521773405,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5473,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8286617772577,
          21.013083488152148,
          105.82878415305103,
          21.01313137404933
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8286617772577,
            21.013083488152148
          ],
          [
            105.82878415305103,
            21.01313137404933
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.013083488152148,
        "to_latitude": 21.01313137404933,
        "to_longitude": 105.82878415305103,
        "from_longitude": 105.8286617772577,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5474,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82850084471231,
          21.01313137404933,
          105.82878415305103,
          21.01326626823399
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82878415305103,
            21.01313137404933
          ],
          [
            105.82850084471231,
            21.01326626823399
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.01313137404933,
        "to_latitude": 21.01326626823399,
        "to_longitude": 105.82850084471231,
        "from_longitude": 105.82878415305103,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5475,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8282835857864,
          21.01326626823399,
          105.82850084471231,
          21.013383948442712
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82850084471231,
            21.01326626823399
          ],
          [
            105.8282835857864,
            21.013383948442712
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.01326626823399,
        "to_latitude": 21.013383948442712,
        "to_longitude": 105.8282835857864,
        "from_longitude": 105.82850084471231,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5476,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82809046673016,
          21.013383948442712,
          105.8282835857864,
          21.013496620895847
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8282835857864,
            21.013383948442712
          ],
          [
            105.82809046673016,
            21.013496620895847
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.013383948442712,
        "to_latitude": 21.013496620895847,
        "to_longitude": 105.82809046673016,
        "from_longitude": 105.8282835857864,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5477,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82878415305103,
          21.01313137404933,
          105.82887098956036,
          21.013296314253466
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82878415305103,
            21.01313137404933
          ],
          [
            105.82887098956036,
            21.013296314253466
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.01313137404933,
        "to_latitude": 21.013296314253466,
        "to_longitude": 105.82887098956036,
        "from_longitude": 105.82878415305103,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5478,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82887098956036,
          21.013296314253466,
          105.82890049385452,
          21.01337393310917
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82887098956036,
            21.013296314253466
          ],
          [
            105.82890049385452,
            21.01337393310917
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.013296314253466,
        "to_latitude": 21.01337393310917,
        "to_longitude": 105.82890049385452,
        "from_longitude": 105.82887098956036,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5479,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82838282751776,
          21.01255298647194,
          105.82857494074834,
          21.01260024658234
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82838282751776,
            21.01260024658234
          ],
          [
            105.82857494074834,
            21.01255298647194
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.01260024658234,
        "to_latitude": 21.01255298647194,
        "to_longitude": 105.82857494074834,
        "from_longitude": 105.82838282751776,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5480,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82857494074834,
          21.012437496477546,
          105.82859472202819,
          21.01255298647194
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82857494074834,
            21.01255298647194
          ],
          [
            105.82859472202819,
            21.012437496477546
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.01255298647194,
        "to_latitude": 21.012437496477546,
        "to_longitude": 105.82859472202819,
        "from_longitude": 105.82857494074834,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5481,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82859472202819,
          21.012437496477546,
          105.82876101898906,
          21.012440000327135
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82859472202819,
            21.012437496477546
          ],
          [
            105.82876101898906,
            21.012440000327135
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.012437496477546,
        "to_latitude": 21.012440000327135,
        "to_longitude": 105.82876101898906,
        "from_longitude": 105.82859472202819,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5482,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82876101898906,
          21.01230980011798,
          105.82900510001035,
          21.012440000327135
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82876101898906,
            21.012440000327135
          ],
          [
            105.82900510001035,
            21.01230980011798
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.012440000327135,
        "to_latitude": 21.01230980011798,
        "to_longitude": 105.82900510001035,
        "from_longitude": 105.82876101898906,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5483,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82876101898906,
          21.012390867092144,
          105.82897827791497,
          21.012610261967
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82876101898906,
            21.012440000327135
          ],
          [
            105.82885312708562,
            21.012390867092144
          ],
          [
            105.82897827791497,
            21.012610261967
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 10 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.012440000327135,
        "to_latitude": 21.012610261967,
        "to_longitude": 105.82897827791497,
        "from_longitude": 105.82876101898906,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5484,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82897827791497,
          21.012610261967,
          105.82904533314446,
          21.01264781965623
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82897827791497,
            21.012610261967
          ],
          [
            105.82904533314446,
            21.01264781965623
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 10 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.012610261967,
        "to_latitude": 21.01264781965623,
        "to_longitude": 105.82904533314446,
        "from_longitude": 105.82897827791497,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5485,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82897827791497,
          21.012610261967,
          105.82913921046034,
          21.01288192903559
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82897827791497,
            21.012610261967
          ],
          [
            105.82913921046034,
            21.01288192903559
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 10 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.012610261967,
        "to_latitude": 21.01288192903559,
        "to_longitude": 105.82913921046034,
        "from_longitude": 105.82897827791497,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5486,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82913921046034,
          21.01288192903559,
          105.82926259207039,
          21.013092251586702
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82913921046034,
            21.01288192903559
          ],
          [
            105.82926259207039,
            21.013092251586702
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 10 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.01288192903559,
        "to_latitude": 21.013092251586702,
        "to_longitude": 105.82926259207039,
        "from_longitude": 105.82913921046034,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5487,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82900510001035,
          21.01223218070852,
          105.82921967672854,
          21.01230980011798
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82900510001035,
            21.01230980011798
          ],
          [
            105.82921967672854,
            21.01223218070852
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.01230980011798,
        "to_latitude": 21.01223218070852,
        "to_longitude": 105.82921967672854,
        "from_longitude": 105.82900510001035,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5488,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82846329378596,
          21.012204638328193,
          105.82859472202819,
          21.012437496477546
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82859472202819,
            21.012437496477546
          ],
          [
            105.82846329378596,
            21.012204638328193
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.012437496477546,
        "to_latitude": 21.012204638328193,
        "to_longitude": 105.82846329378596,
        "from_longitude": 105.82859472202819,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5489,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8283104078728,
          21.011939229670357,
          105.82846329378596,
          21.012204638328193
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82846329378596,
            21.012204638328193
          ],
          [
            105.8283104078728,
            21.011939229670357
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.012204638328193,
        "to_latitude": 21.011939229670357,
        "to_longitude": 105.8283104078728,
        "from_longitude": 105.82846329378596,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5490,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82817093300734,
          21.011939229670357,
          105.8283104078728,
          21.011946741243236
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8283104078728,
            21.011939229670357
          ],
          [
            105.82817093300734,
            21.011946741243236
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011939229670357,
        "to_latitude": 21.011946741243236,
        "to_longitude": 105.82817093300734,
        "from_longitude": 105.8283104078728,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5491,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82799658941428,
          21.011946741243236,
          105.82817093300734,
          21.012034376224538
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82817093300734,
            21.011946741243236
          ],
          [
            105.82799658941428,
            21.012034376224538
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011946741243236,
        "to_latitude": 21.012034376224538,
        "to_longitude": 105.82799658941428,
        "from_longitude": 105.82817093300734,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5492,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82814042287855,
          21.011803082358817,
          105.8283104078728,
          21.011939229670357
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8283104078728,
            21.011939229670357
          ],
          [
            105.82814042287855,
            21.011803082358817
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011939229670357,
        "to_latitude": 21.011803082358817,
        "to_longitude": 105.82814042287855,
        "from_longitude": 105.8283104078728,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5493,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82800631242856,
          21.011612788894684,
          105.82814042287855,
          21.011803082358817
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82814042287855,
            21.011803082358817
          ],
          [
            105.82800631242856,
            21.011612788894684
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011803082358817,
        "to_latitude": 21.011612788894684,
        "to_longitude": 105.82800631242856,
        "from_longitude": 105.82814042287855,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5494,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82788024860182,
          21.011394952658026,
          105.82800631242856,
          21.011612788894684
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82800631242856,
            21.011612788894684
          ],
          [
            105.82788024860182,
            21.011394952658026
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011612788894684,
        "to_latitude": 21.011394952658026,
        "to_longitude": 105.82788024860182,
        "from_longitude": 105.82800631242856,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5495,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82756374793554,
          21.010969294725214,
          105.82768444735581,
          21.011113267394297
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82768444735581,
            21.011113267394297
          ],
          [
            105.82756374793554,
            21.010969294725214
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011113267394297,
        "to_latitude": 21.010969294725214,
        "to_longitude": 105.82756374793554,
        "from_longitude": 105.82768444735581,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5496,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82737867551151,
          21.010969294725214,
          105.82756374793554,
          21.011056930280752
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82756374793554,
            21.010969294725214
          ],
          [
            105.82737867551151,
            21.011056930280752
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.010969294725214,
        "to_latitude": 21.011056930280752,
        "to_longitude": 105.82737867551151,
        "from_longitude": 105.82756374793554,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5497,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82721237855965,
          21.011056930280752,
          105.82737867551151,
          21.011142061915148
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82737867551151,
            21.011056930280752
          ],
          [
            105.82721237855965,
            21.011142061915148
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011056930280752,
        "to_latitude": 21.011142061915148,
        "to_longitude": 105.82721237855965,
        "from_longitude": 105.82737867551151,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5498,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82768444735581,
          21.011113267394297,
          105.82788024860182,
          21.011394952658026
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82788024860182,
            21.011394952658026
          ],
          [
            105.82768444735581,
            21.011113267394297
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011394952658026,
        "to_latitude": 21.011113267394297,
        "to_longitude": 105.82768444735581,
        "from_longitude": 105.82788024860182,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5499,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82768444735581,
          21.011113267394297,
          105.82777027803048,
          21.01118212384301
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82768444735581,
            21.011113267394297
          ],
          [
            105.82777027803048,
            21.01118212384301
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 8,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011113267394297,
        "to_latitude": 21.01118212384301,
        "to_longitude": 105.82777027803048,
        "from_longitude": 105.82768444735581,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5500,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82776223141624,
          21.011034395429032,
          105.82780782895684,
          21.01118212384301
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82777027803048,
            21.01118212384301
          ],
          [
            105.82776223141624,
            21.01110575578028
          ],
          [
            105.82780782895684,
            21.011034395429032
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.01118212384301,
        "to_latitude": 21.011034395429032,
        "to_longitude": 105.82780782895684,
        "from_longitude": 105.82777027803048,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5501,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82777027803048,
          21.01080153508862,
          105.82794730382233,
          21.01118212384301
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82777027803048,
            21.01118212384301
          ],
          [
            105.82794730382233,
            21.01080153508862
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.01118212384301,
        "to_latitude": 21.01080153508862,
        "to_longitude": 105.82794730382233,
        "from_longitude": 105.82777027803048,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5502,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82777027803048,
          21.010496061832903,
          105.82794730382233,
          21.01080153508862
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82794730382233,
            21.01080153508862
          ],
          [
            105.82777027803048,
            21.010496061832903
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.01080153508862,
        "to_latitude": 21.010496061832903,
        "to_longitude": 105.82777027803048,
        "from_longitude": 105.82794730382233,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5503,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82777027803048,
          21.010496061832903,
          105.82783465105223,
          21.010723914894328
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82777027803048,
            21.010496061832903
          ],
          [
            105.82783465105223,
            21.010723914894328
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.010496061832903,
        "to_latitude": 21.010723914894328,
        "to_longitude": 105.82783465105223,
        "from_longitude": 105.82777027803048,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5504,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8276012988619,
          21.01020060349896,
          105.82777027803048,
          21.010496061832903
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82777027803048,
            21.010496061832903
          ],
          [
            105.8276012988619,
            21.01020060349896
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.010496061832903,
        "to_latitude": 21.01020060349896,
        "to_longitude": 105.8276012988619,
        "from_longitude": 105.82777027803048,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5505,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8276012988619,
          21.010077913001354,
          105.82764421420373,
          21.01020060349896
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8276012988619,
            21.01020060349896
          ],
          [
            105.82764421420373,
            21.010077913001354
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.01020060349896,
        "to_latitude": 21.010077913001354,
        "to_longitude": 105.82764421420373,
        "from_longitude": 105.8276012988619,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5506,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82764421420373,
          21.010077913001354,
          105.82781051116457,
          21.010077913001354
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82764421420373,
            21.010077913001354
          ],
          [
            105.82781051116457,
            21.010077913001354
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.010077913001354,
        "to_latitude": 21.010077913001354,
        "to_longitude": 105.82781051116457,
        "from_longitude": 105.82764421420373,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5507,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82781051116457,
          21.009915160145468,
          105.82797949033318,
          21.010077913001354
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82781051116457,
            21.010077913001354
          ],
          [
            105.82797949033318,
            21.009915160145468
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.010077913001354,
        "to_latitude": 21.009915160145468,
        "to_longitude": 105.82797949033318,
        "from_longitude": 105.82781051116457,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5508,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82781051116457,
          21.010077913001354,
          105.82793657499134,
          21.010300758932285
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82781051116457,
            21.010077913001354
          ],
          [
            105.82793657499134,
            21.010300758932285
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.010077913001354,
        "to_latitude": 21.010300758932285,
        "to_longitude": 105.82793657499134,
        "from_longitude": 105.82781051116457,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5509,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82793657499134,
          21.010300758932285,
          105.82806800323358,
          21.010506077359672
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82793657499134,
            21.010300758932285
          ],
          [
            105.82806800323358,
            21.010506077359672
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.010300758932285,
        "to_latitude": 21.010506077359672,
        "to_longitude": 105.82806800323358,
        "from_longitude": 105.82793657499134,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5510,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82806800323358,
          21.010506077359672,
          105.8281833382204,
          21.01069637223471
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82806800323358,
            21.010506077359672
          ],
          [
            105.8281833382204,
            21.01069637223471
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.010506077359672,
        "to_latitude": 21.01069637223471,
        "to_longitude": 105.8281833382204,
        "from_longitude": 105.82806800323358,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5511,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82814042287855,
          21.011755196032695,
          105.82833991217592,
          21.01181935744372
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82814042287855,
            21.011803082358817
          ],
          [
            105.82820211369254,
            21.01181935744372
          ],
          [
            105.82833991217592,
            21.011755196032695
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011803082358817,
        "to_latitude": 21.011755196032695,
        "to_longitude": 105.82833991217592,
        "from_longitude": 105.82814042287855,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5512,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82833991217592,
          21.01161998750006,
          105.82865104841773,
          21.011755196032695
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82833991217592,
            21.011755196032695
          ],
          [
            105.82865104841773,
            21.01161998750006
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011755196032695,
        "to_latitude": 21.01161998750006,
        "to_longitude": 105.82865104841773,
        "from_longitude": 105.82833991217592,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5513,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82865104841773,
          21.011489786575982,
          105.82891390490221,
          21.01161998750006
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82865104841773,
            21.01161998750006
          ],
          [
            105.82891390490221,
            21.011489786575982
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.01161998750006,
        "to_latitude": 21.011489786575982,
        "to_longitude": 105.82891390490221,
        "from_longitude": 105.82865104841773,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5514,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82891390490221,
          21.011274454028012,
          105.82930014299671,
          21.011489786575982
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82891390490221,
            21.011489786575982
          ],
          [
            105.82930014299671,
            21.011274454028012
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011489786575982,
        "to_latitude": 21.011274454028012,
        "to_longitude": 105.82930014299671,
        "from_longitude": 105.82891390490221,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5515,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82930014299671,
          21.011109198605652,
          105.82960591483204,
          21.011274454028012
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82930014299671,
            21.011274454028012
          ],
          [
            105.82960591483204,
            21.011109198605652
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011274454028012,
        "to_latitude": 21.011109198605652,
        "to_longitude": 105.82960591483204,
        "from_longitude": 105.82930014299671,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5516,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82960591483204,
          21.010957714307807,
          105.82987681793973,
          21.011109198605652
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82960591483204,
            21.011109198605652
          ],
          [
            105.82987681793973,
            21.010957714307807
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011109198605652,
        "to_latitude": 21.010957714307807,
        "to_longitude": 105.82987681793973,
        "from_longitude": 105.82960591483204,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5517,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82987681793973,
          21.010827512805694,
          105.8301664965106,
          21.010957714307807
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82987681793973,
            21.010957714307807
          ],
          [
            105.8301664965106,
            21.010827512805694
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.010957714307807,
        "to_latitude": 21.010827512805694,
        "to_longitude": 105.8301664965106,
        "from_longitude": 105.82987681793973,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5518,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8301664965106,
          21.010827512805694,
          105.83029792475286,
          21.01099902822294
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8301664965106,
            21.010827512805694
          ],
          [
            105.83029792475286,
            21.01099902822294
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.010827512805694,
        "to_latitude": 21.01099902822294,
        "to_longitude": 105.83029792475286,
        "from_longitude": 105.8301664965106,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5519,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83029792475286,
          21.01099902822294,
          105.83039984869197,
          21.01121936890783
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83029792475286,
            21.01099902822294
          ],
          [
            105.83039984869197,
            21.01121936890783
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.01099902822294,
        "to_latitude": 21.01121936890783,
        "to_longitude": 105.83039984869197,
        "from_longitude": 105.83029792475286,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5520,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83037302660557,
          21.01121936890783,
          105.83046321588805,
          21.01141467060579
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83039984869197,
            21.01121936890783
          ],
          [
            105.83046321588805,
            21.011371165920266
          ],
          [
            105.83037302660557,
            21.01141467060579
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.01121936890783,
        "to_latitude": 21.01141467060579,
        "to_longitude": 105.83037302660557,
        "from_longitude": 105.83039984869197,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5521,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82987681793973,
          21.010957714307807,
          105.83001361059746,
          21.01112422183381
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82987681793973,
            21.010957714307807
          ],
          [
            105.83001361059746,
            21.01112422183381
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.010957714307807,
        "to_latitude": 21.01112422183381,
        "to_longitude": 105.83001361059746,
        "from_longitude": 105.82987681793973,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5522,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83001361059746,
          21.01112422183381,
          105.830131627792,
          21.011314515921182
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83001361059746,
            21.01112422183381
          ],
          [
            105.830131627792,
            21.011314515921182
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.01112422183381,
        "to_latitude": 21.011314515921182,
        "to_longitude": 105.830131627792,
        "from_longitude": 105.83001361059746,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5523,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83012626337651,
          21.01047822167434,
          105.83024964499553,
          21.01055333811603
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83012626337651,
            21.01055333811603
          ],
          [
            105.83024964499553,
            21.01047822167434
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.01055333811603,
        "to_latitude": 21.01047822167434,
        "to_longitude": 105.83024964499553,
        "from_longitude": 105.83012626337651,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5524,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83012089896104,
          21.010232841035695,
          105.83024964499553,
          21.01047822167434
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83024964499553,
            21.01047822167434
          ],
          [
            105.83012089896104,
            21.010232841035695
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 49,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.01047822167434,
        "to_latitude": 21.010232841035695,
        "to_longitude": 105.83012089896104,
        "from_longitude": 105.83024964499553,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5525,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82981512713471,
          21.010232841035695,
          105.83012089896104,
          21.010343011985093
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83012089896104,
            21.010232841035695
          ],
          [
            105.82981512713471,
            21.010343011985093
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.010232841035695,
        "to_latitude": 21.010343011985093,
        "to_longitude": 105.82981512713471,
        "from_longitude": 105.83012089896104,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5526,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82965419458934,
          21.010343011985093,
          105.82981512713471,
          21.010423136260137
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82981512713471,
            21.010343011985093
          ],
          [
            105.82965419458934,
            21.010423136260137
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.010343011985093,
        "to_latitude": 21.010423136260137,
        "to_longitude": 105.82965419458934,
        "from_longitude": 105.82981512713471,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5527,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82965419458934,
          21.010423136260137,
          105.82980976271025,
          21.010723601909337
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82965419458934,
            21.010423136260137
          ],
          [
            105.82980976271025,
            21.010723601909337
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.010423136260137,
        "to_latitude": 21.010723601909337,
        "to_longitude": 105.82980976271025,
        "from_longitude": 105.82965419458934,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5528,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82934305834753,
          21.011029074699092,
          105.82960591483204,
          21.01116835255364
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82960591483204,
            21.011109198605652
          ],
          [
            105.8293635101928,
            21.01116835255364
          ],
          [
            105.82934305834753,
            21.011029074699092
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011109198605652,
        "to_latitude": 21.011029074699092,
        "to_longitude": 105.82934305834753,
        "from_longitude": 105.82960591483204,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5529,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82905874419217,
          21.010873834506974,
          105.82934305834753,
          21.011029074699092
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82934305834753,
            21.011029074699092
          ],
          [
            105.82905874419217,
            21.010873834506974
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011029074699092,
        "to_latitude": 21.010873834506974,
        "to_longitude": 105.82905874419217,
        "from_longitude": 105.82934305834753,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5530,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8288656251359,
          21.010788702720355,
          105.82905874419217,
          21.010873834506974
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82905874419217,
            21.010873834506974
          ],
          [
            105.8288656251359,
            21.010788702720355
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.010873834506974,
        "to_latitude": 21.010788702720355,
        "to_longitude": 105.8288656251359,
        "from_longitude": 105.82905874419217,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5531,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8290694730231,
          21.0111442528015,
          105.82930014299671,
          21.011274454028012
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82930014299671,
            21.011274454028012
          ],
          [
            105.8290694730231,
            21.0111442528015
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011274454028012,
        "to_latitude": 21.0111442528015,
        "to_longitude": 105.8290694730231,
        "from_longitude": 105.82930014299671,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5532,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82884953188946,
          21.01103909019009,
          105.8290694730231,
          21.0111442528015
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8290694730231,
            21.0111442528015
          ],
          [
            105.82884953188946,
            21.01103909019009
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.0111442528015,
        "to_latitude": 21.01103909019009,
        "to_longitude": 105.82884953188946,
        "from_longitude": 105.8290694730231,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5533,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82960591483204,
          21.011108259651383,
          105.82977221178389,
          21.011269446290505
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82960591483204,
            21.011109198605652
          ],
          [
            105.82966391760364,
            21.011108259651383
          ],
          [
            105.82977221178389,
            21.011269446290505
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011109198605652,
        "to_latitude": 21.011269446290505,
        "to_longitude": 105.82977221178389,
        "from_longitude": 105.82960591483204,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5534,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82977221178389,
          21.011269446290505,
          105.82990095781838,
          21.011479771115265
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82977221178389,
            21.011269446290505
          ],
          [
            105.82990095781838,
            21.011479771115265
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011269446290505,
        "to_latitude": 21.011479771115265,
        "to_longitude": 105.82990095781838,
        "from_longitude": 105.82977221178389,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5535,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82970247435566,
          21.011479771115265,
          105.82990095781838,
          21.011594948869682
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82990095781838,
            21.011479771115265
          ],
          [
            105.82970247435566,
            21.011594948869682
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011479771115265,
        "to_latitude": 21.011594948869682,
        "to_longitude": 105.82970247435566,
        "from_longitude": 105.82990095781838,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5536,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82957372832115,
          21.011374608740347,
          105.82970247435566,
          21.011594948869682
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82970247435566,
            21.011594948869682
          ],
          [
            105.82957372832115,
            21.011374608740347
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011594948869682,
        "to_latitude": 21.011374608740347,
        "to_longitude": 105.82957372832115,
        "from_longitude": 105.82970247435566,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5537,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82928572613235,
          21.011274454028012,
          105.82940206694481,
          21.011524840682405
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82930014299671,
            21.011274454028012
          ],
          [
            105.82928572613235,
            21.01131357696817
          ],
          [
            105.82940206694481,
            21.011524840682405
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011274454028012,
        "to_latitude": 21.011524840682405,
        "to_longitude": 105.82940206694481,
        "from_longitude": 105.82930014299671,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5538,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82940206694481,
          21.011524840682405,
          105.82954154181029,
          21.011760203753894
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82940206694481,
            21.011524840682405
          ],
          [
            105.82954154181029,
            21.011760203753894
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011524840682405,
        "to_latitude": 21.011760203753894,
        "to_longitude": 105.82954154181029,
        "from_longitude": 105.82940206694481,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5539,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82889948803783,
          21.011489786575982,
          105.82898900675492,
          21.011665057024835
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82891390490221,
            21.011489786575982
          ],
          [
            105.82889948803783,
            21.01152890945885
          ],
          [
            105.82898900675492,
            21.011665057024835
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011489786575982,
        "to_latitude": 21.011665057024835,
        "to_longitude": 105.82898900675492,
        "from_longitude": 105.82891390490221,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5540,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82898900675492,
          21.011665057024835,
          105.8290748374386,
          21.011845334986106
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82898900675492,
            21.011665057024835
          ],
          [
            105.8290748374386,
            21.011845334986106
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011665057024835,
        "to_latitude": 21.011845334986106,
        "to_longitude": 105.8290748374386,
        "from_longitude": 105.82898900675492,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5541,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82890854048674,
          21.011845334986106,
          105.8290748374386,
          21.011925458454222
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8290748374386,
            21.011845334986106
          ],
          [
            105.82890854048674,
            21.011925458454222
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011845334986106,
        "to_latitude": 21.011925458454222,
        "to_longitude": 105.82890854048674,
        "from_longitude": 105.8290748374386,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5542,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82879052328319,
          21.011775226915656,
          105.82890854048674,
          21.011925458454222
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82890854048674,
            21.011925458454222
          ],
          [
            105.82879052328319,
            21.011775226915656
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011925458454222,
        "to_latitude": 21.011775226915656,
        "to_longitude": 105.82879052328319,
        "from_longitude": 105.82890854048674,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5543,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82857058214952,
          21.01144972472986,
          105.82865104841773,
          21.01161998750006
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82865104841773,
            21.01161998750006
          ],
          [
            105.82859908062701,
            21.011548940373043
          ],
          [
            105.82857058214952,
            21.01144972472986
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.01161998750006,
        "to_latitude": 21.01144972472986,
        "to_longitude": 105.82857058214952,
        "from_longitude": 105.82865104841773,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5544,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8283238189205,
          21.01128947723869,
          105.82857058214952,
          21.01144972472986
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82857058214952,
            21.01144972472986
          ],
          [
            105.8283238189205,
            21.01128947723869
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.01144972472986,
        "to_latitude": 21.01128947723869,
        "to_longitude": 105.8283238189205,
        "from_longitude": 105.82857058214952,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5545,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82833991217592,
          21.011691351472766,
          105.82858935761271,
          21.011899167945785
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82833991217592,
            21.011755196032695
          ],
          [
            105.82848682865227,
            21.011691351472766
          ],
          [
            105.82858935761271,
            21.011899167945785
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011755196032695,
        "to_latitude": 21.011899167945785,
        "to_longitude": 105.82858935761271,
        "from_longitude": 105.82833991217592,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5546,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82858935761271,
          21.011899167945785,
          105.82873687911038,
          21.012164576674103
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82858935761271,
            21.011899167945785
          ],
          [
            105.82873687911038,
            21.012164576674103
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011899167945785,
        "to_latitude": 21.012164576674103,
        "to_longitude": 105.82873687911038,
        "from_longitude": 105.82858935761271,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5547,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82891390490221,
          21.011179306989106,
          105.82896386105061,
          21.011489786575982
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82891390490221,
            21.011489786575982
          ],
          [
            105.82896386105061,
            21.011311073099716
          ],
          [
            105.82895145582857,
            21.011179306989106
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011489786575982,
        "to_latitude": 21.011179306989106,
        "to_longitude": 105.82895145582857,
        "from_longitude": 105.82891390490221,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5548,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.828710057015,
          21.011054113424468,
          105.82895145582857,
          21.011179306989106
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82895145582857,
            21.011179306989106
          ],
          [
            105.828710057015,
            21.011054113424468
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011179306989106,
        "to_latitude": 21.011054113424468,
        "to_longitude": 105.828710057015,
        "from_longitude": 105.82895145582857,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5549,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82794830965696,
          21.012710415782458,
          105.82819239067825,
          21.012833104114055
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82819239067825,
            21.012710415782458
          ],
          [
            105.82794830965696,
            21.012833104114055
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.012710415782458,
        "to_latitude": 21.012833104114055,
        "to_longitude": 105.82794830965696,
        "from_longitude": 105.82819239067825,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5550,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83012626337651,
          21.01055333811603,
          105.8301664965106,
          21.010827512805694
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8301664965106,
            21.010827512805694
          ],
          [
            105.83012626337651,
            21.01055333811603
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.010827512805694,
        "to_latitude": 21.01055333811603,
        "to_longitude": 105.83012626337651,
        "from_longitude": 105.8301664965106,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5551,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83572781173851,
          21.01306377042499,
          105.83588199317302,
          21.013109253216076
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83588199317302,
            21.01306377042499
          ],
          [
            105.83572781173851,
            21.013109253216076
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên, Đê La Thành",
        "diemDau": "01",
        "diemCuoi": "1-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CNC",
        "from_latitude": 21.01306377042499,
        "to_latitude": 21.013109253216076,
        "to_longitude": 105.83572781173851,
        "from_longitude": 105.83588199317302,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5552,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83544819038912,
          21.013276596554423,
          105.83561985176547,
          21.013399284421148
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83561985176547,
            21.013276596554423
          ],
          [
            105.83544819038912,
            21.013399284421148
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên, Đê La Thành",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CNC",
        "from_latitude": 21.013276596554423,
        "to_latitude": 21.013399284421148,
        "to_longitude": 105.83544819038912,
        "from_longitude": 105.83561985176547,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5553,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83534090202554,
          21.013399284421148,
          105.83544819038912,
          21.01358957560619
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83544819038912,
            21.013399284421148
          ],
          [
            105.83534090202554,
            21.01358957560619
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên, Đê La Thành",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CNC",
        "from_latitude": 21.013399284421148,
        "to_latitude": 21.01358957560619,
        "to_longitude": 105.83534090202554,
        "from_longitude": 105.83544819038912,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5554,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83516054636155,
          21.01358957560619,
          105.83534090202554,
          21.013787647460546
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83534090202554,
            21.01358957560619
          ],
          [
            105.83516054636155,
            21.013787647460546
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên, Đê La Thành",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CNC",
        "from_latitude": 21.01358957560619,
        "to_latitude": 21.013787647460546,
        "to_longitude": 105.83516054636155,
        "from_longitude": 105.83534090202554,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5555,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83504317683143,
          21.013787647460546,
          105.83516054636155,
          21.0139275922434
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83516054636155,
            21.013787647460546
          ],
          [
            105.83504317683143,
            21.0139275922434
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên, Đê La Thành",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CNC",
        "from_latitude": 21.013787647460546,
        "to_latitude": 21.0139275922434,
        "to_longitude": 105.83504317683143,
        "from_longitude": 105.83516054636155,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5556,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8348635802899,
          21.0139275922434,
          105.83504317683143,
          21.014145684697276
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83504317683143,
            21.0139275922434
          ],
          [
            105.8348635802899,
            21.014145684697276
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên, Đê La Thành",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CNC",
        "from_latitude": 21.0139275922434,
        "to_latitude": 21.014145684697276,
        "to_longitude": 105.8348635802899,
        "from_longitude": 105.83504317683143,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5557,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83478843212944,
          21.014145684697276,
          105.8348635802899,
          21.014217830543828
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8348635802899,
            21.014145684697276
          ],
          [
            105.83478843212944,
            21.014217830543828
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên, Đê La Thành",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CNC",
        "from_latitude": 21.014145684697276,
        "to_latitude": 21.014217830543828,
        "to_longitude": 105.83478843212944,
        "from_longitude": 105.8348635802899,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5558,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83588199317302,
          21.01306377042499,
          105.83600269258427,
          21.013208992993373
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83588199317302,
            21.01306377042499
          ],
          [
            105.83600269258427,
            21.013208992993373
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên, Đê La Thành",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CNC",
        "from_latitude": 21.01306377042499,
        "to_latitude": 21.013208992993373,
        "to_longitude": 105.83600269258427,
        "from_longitude": 105.83588199317302,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5559,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83600269258427,
          21.013208992993373,
          105.83629773557065,
          21.013542002832693
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83600269258427,
            21.013208992993373
          ],
          [
            105.83629773557065,
            21.013542002832693
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên, Đê La Thành",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CNC",
        "from_latitude": 21.013208992993373,
        "to_latitude": 21.013542002832693,
        "to_longitude": 105.83629773557065,
        "from_longitude": 105.83600269258427,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5560,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83629773557065,
          21.013542002832693,
          105.83642427760168,
          21.01381068234426
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83629773557065,
            21.013542002832693
          ],
          [
            105.8363815991325,
            21.013648415594187
          ],
          [
            105.83642427760168,
            21.01381068234426
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên, Đê La Thành",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CNC",
        "from_latitude": 21.013542002832693,
        "to_latitude": 21.01381068234426,
        "to_longitude": 105.83642427760168,
        "from_longitude": 105.83629773557065,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5561,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83642427760168,
          21.01381068234426,
          105.83663837611557,
          21.01409785218522
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83642427760168,
            21.01381068234426
          ],
          [
            105.83663837611557,
            21.01409785218522
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên, Đê La Thành",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CNC",
        "from_latitude": 21.01381068234426,
        "to_latitude": 21.01409785218522,
        "to_longitude": 105.83663837611557,
        "from_longitude": 105.83642427760168,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5562,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83663837611557,
          21.01409785218522,
          105.83696269854674,
          21.014561142093573
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83663837611557,
            21.01409785218522
          ],
          [
            105.83696269854674,
            21.014561142093573
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên, Đê La Thành",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CNC",
        "from_latitude": 21.01409785218522,
        "to_latitude": 21.014561142093573,
        "to_longitude": 105.83696269854674,
        "from_longitude": 105.83663837611557,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5563,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8368344007187,
          21.0144039440186,
          105.83696269854674,
          21.014561142093573
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83696269854674,
            21.014561142093573
          ],
          [
            105.83689877373146,
            21.01448281420554
          ],
          [
            105.8368344007187,
            21.0144039440186
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiêm",
        "diemDau": "06",
        "diemCuoi": "6-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.014561142093573,
        "to_latitude": 21.0144039440186,
        "to_longitude": 105.8368344007187,
        "from_longitude": 105.83696269854674,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5564,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83696269854674,
          21.014561142093573,
          105.83713032626262,
          21.01480944796993
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83696269854674,
            21.014561142093573
          ],
          [
            105.83713032626262,
            21.01480944796993
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên, Đê La Thành",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CNC",
        "from_latitude": 21.014561142093573,
        "to_latitude": 21.01480944796993,
        "to_longitude": 105.83713032626262,
        "from_longitude": 105.83696269854674,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5565,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83713032626262,
          21.01480944796993,
          105.83728478848684,
          21.015061820251265
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83713032626262,
            21.01480944796993
          ],
          [
            105.83728478848684,
            21.015061820251265
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên, Đê La Thành",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CNC",
        "from_latitude": 21.01480944796993,
        "to_latitude": 21.015061820251265,
        "to_longitude": 105.83728478848684,
        "from_longitude": 105.83713032626262,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5566,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83728478848684,
          21.015061820251265,
          105.83741934086332,
          21.015270056300068
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83728478848684,
            21.015061820251265
          ],
          [
            105.83741934086332,
            21.015270056300068
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên, Đê La Thành",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CNC",
        "from_latitude": 21.015061820251265,
        "to_latitude": 21.015270056300068,
        "to_longitude": 105.83741934086332,
        "from_longitude": 105.83728478848684,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5567,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83741934086332,
          21.015270056300068,
          105.8375798314822,
          21.015522519672146
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83741934086332,
            21.015270056300068
          ],
          [
            105.8375798314822,
            21.015522519672146
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên, Đê La Thành",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CNC",
        "from_latitude": 21.015270056300068,
        "to_latitude": 21.015522519672146,
        "to_longitude": 105.8375798314822,
        "from_longitude": 105.83741934086332,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5568,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83561985176547,
          21.01306377042499,
          105.83588199317302,
          21.013276596554423
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83588199317302,
            21.01306377042499
          ],
          [
            105.83561985176547,
            21.013276596554423
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chợ Khâm Thiên, Đê La Thành",
        "diemDau": "01",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CNC",
        "from_latitude": 21.01306377042499,
        "to_latitude": 21.013276596554423,
        "to_longitude": 105.83561985176547,
        "from_longitude": 105.83588199317302,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5569,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83077837549536,
          21.011402464257493,
          105.83102245646276,
          21.011547688439954
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83077837549536,
            21.011547688439954
          ],
          [
            105.83102245646276,
            21.011402464257493
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1NĐ",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường dạo Xã Đàn",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1 Nam Đồng",
        "from_latitude": 21.011547688439954,
        "to_latitude": 21.011402464257493,
        "to_longitude": 105.83102245646276,
        "from_longitude": 105.83077837549536,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5570,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83734987659447,
          21.015498733594303,
          105.8375798314822,
          21.01562893101976
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8375798314822,
            21.015522519672146
          ],
          [
            105.83753226680179,
            21.015498733594303
          ],
          [
            105.83735792321772,
            21.015583862692715
          ],
          [
            105.83734987659447,
            21.01562893101976
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 188 + 192 Phố Chợ Khâm Thiên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.015522519672146,
        "to_latitude": 21.01562893101976,
        "to_longitude": 105.83734987659447,
        "from_longitude": 105.8375798314822,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5571,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83732305449912,
          21.015270056300068,
          105.83751068892676,
          21.015533786758542
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83741934086332,
            21.015270056300068
          ],
          [
            105.83751068892676,
            21.015413753475002
          ],
          [
            105.83732305449912,
            21.015533786758542
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 188 + 192 Phố Chợ Khâm Thiên",
        "diemDau": "09",
        "diemCuoi": "9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.015270056300068,
        "to_latitude": 21.015533786758542,
        "to_longitude": 105.83732305449912,
        "from_longitude": 105.83741934086332,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5572,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83709238452549,
          21.015061820251265,
          105.83728478848684,
          21.015205788971866
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83728478848684,
            21.015061820251265
          ],
          [
            105.83709238452549,
            21.015205788971866
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "218 Phố Chợ Khâm Thiêm",
        "diemDau": "08",
        "diemCuoi": "8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.015061820251265,
        "to_latitude": 21.015205788971866,
        "to_longitude": 105.83709238452549,
        "from_longitude": 105.83728478848684,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5573,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83690116199814,
          21.015205788971866,
          105.83709238452549,
          21.01536487383762
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83709238452549,
            21.015205788971866
          ],
          [
            105.83690116199814,
            21.01536487383762
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "218 Phố Chợ Khâm Thiêm",
        "diemDau": "8-1",
        "diemCuoi": "8-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.015205788971866,
        "to_latitude": 21.01536487383762,
        "to_longitude": 105.83690116199814,
        "from_longitude": 105.83709238452549,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5574,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83666323108017,
          21.01536487383762,
          105.83690116199814,
          21.015508748785166
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83690116199814,
            21.01536487383762
          ],
          [
            105.83666323108017,
            21.015508748785166
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "218 Phố Chợ Khâm Thiêm",
        "diemDau": "8-2",
        "diemCuoi": "8-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01536487383762,
        "to_latitude": 21.015508748785166,
        "to_longitude": 105.83666323108017,
        "from_longitude": 105.83690116199814,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5575,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83653984947013,
          21.015508748785166,
          105.83666323108017,
          21.01560138926586
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83666323108017,
            21.015508748785166
          ],
          [
            105.83653984947013,
            21.01560138926586
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "218 Phố Chợ Khâm Thiêm",
        "diemDau": "8-3",
        "diemCuoi": "8-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.015508748785166,
        "to_latitude": 21.01560138926586,
        "to_longitude": 105.83653984947013,
        "from_longitude": 105.83666323108017,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5576,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83628481288969,
          21.015444513751643,
          105.83653984947013,
          21.01560138926586
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83653984947013,
            21.01560138926586
          ],
          [
            105.83628481288969,
            21.015444513751643
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "218 Phố Chợ Khâm Thiêm",
        "diemDau": "8-4",
        "diemCuoi": "8-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01560138926586,
        "to_latitude": 21.015444513751643,
        "to_longitude": 105.83628481288969,
        "from_longitude": 105.83653984947013,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5577,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83589200693602,
          21.022351470527493,
          105.83598186096727,
          21.0224416030788
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83589200693602,
            21.0224416030788
          ],
          [
            105.83598186096727,
            21.022351470527493
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "Tủ VC4",
        "diemCuoi": "VC4 03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.0224416030788,
        "to_latitude": 21.022351470527493,
        "to_longitude": 105.83598186096727,
        "from_longitude": 105.83589200693602,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5578,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83598186096727,
          21.022351470527493,
          105.83609049043471,
          21.022422825466975
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83598186096727,
            21.022351470527493
          ],
          [
            105.83609049043471,
            21.022422825466975
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "VC4 03",
        "diemCuoi": "2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022351470527493,
        "to_latitude": 21.022422825466975,
        "to_longitude": 105.83609049043471,
        "from_longitude": 105.83598186096727,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5579,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83609049043471,
          21.022355226052177,
          105.83634127697536,
          21.022422825466975
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83609049043471,
            21.022422825466975
          ],
          [
            105.83612435331867,
            21.02235553900859
          ],
          [
            105.83618202081207,
            21.02235804269082
          ],
          [
            105.83623432389,
            21.02235804269082
          ],
          [
            105.83634127697536,
            21.022355226052177
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "2-1",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022422825466975,
        "to_latitude": 21.022355226052177,
        "to_longitude": 105.83634127697536,
        "from_longitude": 105.83609049043471,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5580,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83634127697536,
          21.022326433698847,
          105.83661754449857,
          21.022355226052177
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83634127697536,
            21.022355226052177
          ],
          [
            105.83645828834425,
            21.02234427243594
          ],
          [
            105.83648645153453,
            21.022340516911818
          ],
          [
            105.83661754449857,
            21.022326433698847
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "02",
        "diemCuoi": "VC4 01",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022355226052177,
        "to_latitude": 21.022326433698847,
        "to_longitude": 105.83661754449857,
        "from_longitude": 105.83634127697536,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5581,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83569218238739,
          21.022351470527493,
          105.83598186096727,
          21.022351470527493
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83598186096727,
            21.022351470527493
          ],
          [
            105.83569218238739,
            21.022351470527493
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "VC4 03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022351470527493,
        "to_latitude": 21.022351470527493,
        "to_longitude": 105.83569218238739,
        "from_longitude": 105.83598186096727,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5582,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.835407868232,
          21.022343959479514,
          105.83569218238739,
          21.022353035325487
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83569218238739,
            21.022351470527493
          ],
          [
            105.83557584157491,
            21.022353035325487
          ],
          [
            105.83549269309898,
            21.02235053164317
          ],
          [
            105.835407868232,
            21.022343959479514
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022351470527493,
        "to_latitude": 21.022343959479514,
        "to_longitude": 105.835407868232,
        "from_longitude": 105.83569218238739,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5583,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83505649885609,
          21.022308907916145,
          105.835407868232,
          21.022343959479514
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.835407868232,
            21.022343959479514
          ],
          [
            105.83520569673585,
            21.022329250338863
          ],
          [
            105.83507292738975,
            21.02232048744794
          ],
          [
            105.83505649885609,
            21.022308907916145
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022343959479514,
        "to_latitude": 21.022308907916145,
        "to_longitude": 105.83505649885609,
        "from_longitude": 105.835407868232,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5584,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83483219909667,
          21.022308907916145,
          105.83505649885609,
          21.022390590562683
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83505649885609,
            21.022308907916145
          ],
          [
            105.83496966233777,
            21.02237181294441
          ],
          [
            105.83483219909667,
            21.022390590562683
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "06",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022308907916145,
        "to_latitude": 21.022390590562683,
        "to_longitude": 105.83483219909667,
        "from_longitude": 105.83505649885609,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5585,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83499242649572,
          21.02215405133476,
          105.83505649885609,
          21.022308907916145
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83505649885609,
            21.022308907916145
          ],
          [
            105.83499916664088,
            21.022306717189597
          ],
          [
            105.83499242649572,
            21.02215405133476
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022308907916145,
        "to_latitude": 21.02215405133476,
        "to_longitude": 105.83499242649572,
        "from_longitude": 105.83505649885609,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5586,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.834990653716,
          21.021927655453215,
          105.83499242649572,
          21.02215405133476
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83499242649572,
            21.02215405133476
          ],
          [
            105.834990653716,
            21.021927655453215
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02215405133476,
        "to_latitude": 21.021927655453215,
        "to_longitude": 105.834990653716,
        "from_longitude": 105.83499242649572,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5587,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83498637901599,
          21.02160904010344,
          105.834990653716,
          21.021927655453215
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.834990653716,
            21.021927655453215
          ],
          [
            105.83498637901599,
            21.02160904010344
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.021927655453215,
        "to_latitude": 21.02160904010344,
        "to_longitude": 105.83498637901599,
        "from_longitude": 105.834990653716,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5588,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83498177293076,
          21.021433001597778,
          105.83498637901599,
          21.02160904010344
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83498637901599,
            21.02160904010344
          ],
          [
            105.83498177293076,
            21.021433001597778
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "09",
        "diemCuoi": "VC4 10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02160904010344,
        "to_latitude": 21.021433001597778,
        "to_longitude": 105.83498177293076,
        "from_longitude": 105.83498637901599,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5589,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83456531929158,
          21.022390590562683,
          105.83483219909667,
          21.02244692339654
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83483219909667,
            21.022390590562683
          ],
          [
            105.83481811752847,
            21.022396849765446
          ],
          [
            105.83472692242036,
            21.022410620015464
          ],
          [
            105.83465316168048,
            21.022420634742094
          ],
          [
            105.83456531929158,
            21.02244692339654
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022390590562683,
        "to_latitude": 21.02244692339654,
        "to_longitude": 105.83456531929158,
        "from_longitude": 105.83483219909667,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5590,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83424747753044,
          21.02244692339654,
          105.83456531929158,
          21.022585877632864
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83456531929158,
            21.02244692339654
          ],
          [
            105.83451905122152,
            21.02246069364026
          ],
          [
            105.83452173343824,
            21.02248322676627
          ],
          [
            105.8344479726894,
            21.02250951540885
          ],
          [
            105.83424747753044,
            21.022585877632864
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02244692339654,
        "to_latitude": 21.022585877632864,
        "to_longitude": 105.83424747753044,
        "from_longitude": 105.83456531929158,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5591,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83392561244867,
          21.022585877632864,
          105.83424747753044,
          21.022728587251624
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83424747753044,
            21.022585877632864
          ],
          [
            105.83414890638244,
            21.022619677288446
          ],
          [
            105.83414756527857,
            21.02263595119629
          ],
          [
            105.83410330882387,
            21.022652225101538
          ],
          [
            105.83407246342588,
            21.022657232456805
          ],
          [
            105.83401345482858,
            21.022688528422094
          ],
          [
            105.83398797383708,
            21.022706054160192
          ],
          [
            105.83392561244867,
            21.022728587251624
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022585877632864,
        "to_latitude": 21.022728587251624,
        "to_longitude": 105.83392561244867,
        "from_longitude": 105.83424747753044,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5592,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83367449067472,
          21.022707305998768,
          105.83392561244867,
          21.02278898843123
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83392561244867,
            21.022728587251624
          ],
          [
            105.83387666216187,
            21.022707305998768
          ],
          [
            105.83367449067472,
            21.02278898843123
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022728587251624,
        "to_latitude": 21.02278898843123,
        "to_longitude": 105.83367449067472,
        "from_longitude": 105.83392561244867,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5593,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83331373356275,
          21.02278898843123,
          105.83367449067472,
          21.02294296438381
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83367449067472,
            21.02278898843123
          ],
          [
            105.83331373356275,
            21.02294296438381
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02278898843123,
        "to_latitude": 21.02294296438381,
        "to_longitude": 105.83331373356275,
        "from_longitude": 105.83367449067472,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5594,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83292615435539,
          21.02294296438381,
          105.83331373356275,
          21.023154524579983
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83331373356275,
            21.02294296438381
          ],
          [
            105.8331511246263,
            21.02303153177745
          ],
          [
            105.8330934571329,
            21.023072842347833
          ],
          [
            105.83307736387745,
            21.023074094183343
          ],
          [
            105.83306261172591,
            21.023079101523614
          ],
          [
            105.83292615435539,
            21.023154524579983
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02294296438381,
        "to_latitude": 21.023154524579983,
        "to_longitude": 105.83292615435539,
        "from_longitude": 105.83331373356275,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5595,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83262881195553,
          21.023154524579983,
          105.83292615435539,
          21.023310046663916
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83292615435539,
            21.023154524579983
          ],
          [
            105.83284669390387,
            21.023198025823067
          ],
          [
            105.83284267059226,
            21.02321555150124
          ],
          [
            105.83267100921591,
            21.023303179860505
          ],
          [
            105.83264955154499,
            21.02329817252689
          ],
          [
            105.83262881195553,
            21.023310046663916
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Chương",
        "diemDau": "23",
        "diemCuoi": "VC4 24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.023154524579983,
        "to_latitude": 21.023310046663916,
        "to_longitude": 105.83262881195553,
        "from_longitude": 105.83292615435539,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5596,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8348010184025,
          21.021856366459655,
          105.8349897780969,
          21.021874831174504
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8349897780969,
            21.021862391118137
          ],
          [
            105.83485835068055,
            21.021874831174504
          ],
          [
            105.83483018749027,
            21.02186481641206
          ],
          [
            105.8348010184025,
            21.021856366459655
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 190 Văn Chương",
        "diemDau": "08",
        "diemCuoi": "8-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.021862391118137,
        "to_latitude": 21.021856366459655,
        "to_longitude": 105.8348010184025,
        "from_longitude": 105.8349897780969,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5597,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83470948807899,
          21.02157970834903,
          105.8348010184025,
          21.021856366459655
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8348010184025,
            21.021856366459655
          ],
          [
            105.834771178893,
            21.021805979665103
          ],
          [
            105.83470948807899,
            21.021614447117514
          ],
          [
            105.83471786992654,
            21.02157970834903
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 190 Văn Chương",
        "diemDau": "8-2",
        "diemCuoi": "8-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.021856366459655,
        "to_latitude": 21.02157970834903,
        "to_longitude": 105.83471786992654,
        "from_longitude": 105.8348010184025,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5598,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83440673368473,
          21.02157970834903,
          105.83471786992654,
          21.021691122762842
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83471786992654,
            21.02157970834903
          ],
          [
            105.83458208315736,
            21.021635728523854
          ],
          [
            105.83442651503645,
            21.021682046868197
          ],
          [
            105.83440673368473,
            21.021691122762842
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 190 Văn Chương",
        "diemDau": "8-3",
        "diemCuoi": "8-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02157970834903,
        "to_latitude": 21.021691122762842,
        "to_longitude": 105.83440673368473,
        "from_longitude": 105.83471786992654,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5599,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83417472259828,
          21.021691122762842,
          105.83440673368473,
          21.021796277863963
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83440673368473,
            21.021691122762842
          ],
          [
            105.83433934323992,
            21.021709587499
          ],
          [
            105.83431118004066,
            21.021722105965644
          ],
          [
            105.83423473708407,
            21.021747142895784
          ],
          [
            105.83420523278994,
            21.021768424283152
          ],
          [
            105.83417472259828,
            21.021796277863963
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 190 Văn Chương",
        "diemDau": "8-4",
        "diemCuoi": "VC4 8-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.021691122762842,
        "to_latitude": 21.021796277863963,
        "to_longitude": 105.83417472259828,
        "from_longitude": 105.83440673368473,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5600,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83437186496612,
          21.021520871490438,
          105.83440673368473,
          21.021691122762842
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83440673368473,
            21.021691122762842
          ],
          [
            105.83437421195855,
            21.021560617664736
          ],
          [
            105.83437186496612,
            21.021520871490438
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 190 Văn Chương",
        "diemDau": "8-4",
        "diemCuoi": "8-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.021691122762842,
        "to_latitude": 21.021520871490438,
        "to_longitude": 105.83437186496612,
        "from_longitude": 105.83440673368473,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5601,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83437186496612,
          21.021459530910473,
          105.83450295799304,
          21.021520871490438
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83437186496612,
            21.021520871490438
          ],
          [
            105.83450295799304,
            21.02147424012939
          ],
          [
            105.83449256436842,
            21.021459530910473
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 190 Văn Chương",
        "diemDau": "8-6",
        "diemCuoi": "VC4 8-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.021520871490438,
        "to_latitude": 21.021459530910473,
        "to_longitude": 105.83449256436842,
        "from_longitude": 105.83437186496612,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5602,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8349897780969,
          21.021841344312598,
          105.83533746021138,
          21.02186732010252
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8349897780969,
            21.021862391118137
          ],
          [
            105.83502867095301,
            21.02186732010252
          ],
          [
            105.8351802157623,
            21.02186106087585
          ],
          [
            105.83533746021138,
            21.021841344312598
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 199 Văn Chương",
        "diemDau": "08",
        "diemCuoi": "8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.021862391118137,
        "to_latitude": 21.021841344312598,
        "to_longitude": 105.83533746021138,
        "from_longitude": 105.8349897780969,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5603,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83480906504366,
          21.022233171476135,
          105.83483219909667,
          21.022390590562683
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83483219909667,
            21.022390590562683
          ],
          [
            105.83480906504366,
            21.022233171476135
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 168 Văn Chương",
        "diemDau": "17",
        "diemCuoi": "17-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022390590562683,
        "to_latitude": 21.022233171476135,
        "to_longitude": 105.83480906504366,
        "from_longitude": 105.83483219909667,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5604,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83505649885609,
          21.022308907916145,
          105.83513629453194,
          21.022527354132393
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83505649885609,
            21.022308907916145
          ],
          [
            105.83509974950309,
            21.02238808687849
          ],
          [
            105.83513629453194,
            21.022527354132393
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 191 Văn Chương",
        "diemDau": "06",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022308907916145,
        "to_latitude": 21.022527354132393,
        "to_longitude": 105.83513629453194,
        "from_longitude": 105.83505649885609,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5605,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8351322712203,
          21.022527354132393,
          105.83513629453194,
          21.0227301520515
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83513629453194,
            21.022527354132393
          ],
          [
            105.8351322712203,
            21.0227301520515
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 191 Văn Chương",
        "diemDau": "6-1",
        "diemCuoi": "6-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022527354132393,
        "to_latitude": 21.0227301520515,
        "to_longitude": 105.8351322712203,
        "from_longitude": 105.83513629453194,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5606,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83511886017261,
          21.0227301520515,
          105.8351322712203,
          21.022934201531445
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8351322712203,
            21.0227301520515
          ],
          [
            105.83511886017261,
            21.022934201531445
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 191 Văn Chương",
        "diemDau": "6-2",
        "diemCuoi": "6-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.0227301520515,
        "to_latitude": 21.022934201531445,
        "to_longitude": 105.83511886017261,
        "from_longitude": 105.8351322712203,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5607,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8349646331556,
          21.022934201531445,
          105.83511886017261,
          21.022942964386328
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83511886017261,
            21.022934201531445
          ],
          [
            105.8349646331556,
            21.022942964386328
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 191 Văn Chương",
        "diemDau": "6-3",
        "diemCuoi": "6-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022934201531445,
        "to_latitude": 21.022942964386328,
        "to_longitude": 105.8349646331556,
        "from_longitude": 105.83511886017261,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5608,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83480638282694,
          21.022942964386328,
          105.8349646331556,
          21.022945468058676
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8349646331556,
            21.022942964386328
          ],
          [
            105.83480638282694,
            21.022945468058676
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 191 Văn Chương",
        "diemDau": "6-4",
        "diemCuoi": "6-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022942964386328,
        "to_latitude": 21.022945468058676,
        "to_longitude": 105.83480638282694,
        "from_longitude": 105.8349646331556,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5609,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.835407868232,
          21.022343959479514,
          105.83544709557636,
          21.022442228997967
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.835407868232,
            21.022343959479514
          ],
          [
            105.83544709557636,
            21.022381827672
          ],
          [
            105.83544608967884,
            21.022442228997967
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 29 Văn Chương",
        "diemDau": "05",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022343959479514,
        "to_latitude": 21.022442228997967,
        "to_longitude": 105.83544608967884,
        "from_longitude": 105.835407868232,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5610,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83598186096727,
          21.022242873248832,
          105.83611764779931,
          21.022351470527493
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83598186096727,
            21.022351470527493
          ],
          [
            105.83598487848016,
            21.022244125089635
          ],
          [
            105.83600767725494,
            21.022245376931274
          ],
          [
            105.83611764779931,
            21.022242873248832
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A TT Văn Chương",
        "diemDau": "VC4 03",
        "diemCuoi": "VC4 3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022351470527493,
        "to_latitude": 21.022242873248832,
        "to_longitude": 105.83611764779931,
        "from_longitude": 105.83598186096727,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5611,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83634127697536,
          21.022271284087132,
          105.83638223295317,
          21.022355226052177
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83634127697536,
            21.022355226052177
          ],
          [
            105.83638223295317,
            21.022271284087132
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Khu A TT Văn Chương",
        "diemDau": "02",
        "diemCuoi": "VC4 2-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022355226052177,
        "to_latitude": 21.022271284087132,
        "to_longitude": 105.83638223295317,
        "from_longitude": 105.83634127697536,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5612,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83685086095524,
          21.014561142093573,
          105.83696269854674,
          21.01467015743085
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83696269854674,
            21.014561142093573
          ],
          [
            105.83685086095524,
            21.01467015743085
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiêm",
        "diemDau": "06",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.014561142093573,
        "to_latitude": 21.01467015743085,
        "to_longitude": 105.83685086095524,
        "from_longitude": 105.83696269854674,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5613,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8365538527242,
          21.01467015743085,
          105.83685086095524,
          21.014887352779354
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83685086095524,
            21.01467015743085
          ],
          [
            105.83669090253265,
            21.01477200453245
          ],
          [
            105.8365538527242,
            21.014887352779354
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiêm",
        "diemDau": "6-1",
        "diemCuoi": "6-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01467015743085,
        "to_latitude": 21.014887352779354,
        "to_longitude": 105.8365538527242,
        "from_longitude": 105.83685086095524,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5614,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83632984911215,
          21.014887352779354,
          105.8365538527242,
          21.015041794022462
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8365538527242,
            21.014887352779354
          ],
          [
            105.83632984911215,
            21.015041794022462
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiêm",
        "diemDau": "6-2",
        "diemCuoi": "6-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.014887352779354,
        "to_latitude": 21.015041794022462,
        "to_longitude": 105.83632984911215,
        "from_longitude": 105.8365538527242,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5615,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8362657724079,
          21.015041794022462,
          105.83632984911215,
          21.01513380462893
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83632984911215,
            21.015041794022462
          ],
          [
            105.8362657724079,
            21.01513380462893
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiêm",
        "diemDau": "6-3",
        "diemCuoi": "6-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.015041794022462,
        "to_latitude": 21.01513380462893,
        "to_longitude": 105.8362657724079,
        "from_longitude": 105.83632984911215,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5616,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83611422759863,
          21.01513380462893,
          105.8362657724079,
          21.015243971957698
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8362657724079,
            21.01513380462893
          ],
          [
            105.83611422759863,
            21.015243971957698
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiêm",
        "diemDau": "6-10",
        "diemCuoi": "6-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01513380462893,
        "to_latitude": 21.015243971957698,
        "to_longitude": 105.83611422759863,
        "from_longitude": 105.8362657724079,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5617,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83616250735595,
          21.014873408800725,
          105.83632984911215,
          21.015041794022462
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83632984911215,
            21.015041794022462
          ],
          [
            105.83616250735595,
            21.014873408800725
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiêm",
        "diemDau": "6-3",
        "diemCuoi": "6-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.015041794022462,
        "to_latitude": 21.014873408800725,
        "to_longitude": 105.83616250735595,
        "from_longitude": 105.83632984911215,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5618,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83596138214043,
          21.014658839675953,
          105.83616250735595,
          21.014873408800725
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83616250735595,
            21.014873408800725
          ],
          [
            105.83596138214043,
            21.014658839675953
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiêm",
        "diemDau": "6-4",
        "diemCuoi": "6-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.014873408800725,
        "to_latitude": 21.014658839675953,
        "to_longitude": 105.83596138214043,
        "from_longitude": 105.83616250735595,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5619,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83616250735595,
          21.014653523369006,
          105.83635693163136,
          21.014873408800725
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83616250735595,
            21.014873408800725
          ],
          [
            105.83635693163136,
            21.014653523369006
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiêm",
        "diemDau": "6-4",
        "diemCuoi": "6-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.014873408800725,
        "to_latitude": 21.014653523369006,
        "to_longitude": 105.83635693163136,
        "from_longitude": 105.83616250735595,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5620,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83617556522937,
          21.014490837044217,
          105.83635693163136,
          21.014653523369006
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83635693163136,
            21.014653523369006
          ],
          [
            105.83617556522937,
            21.014490837044217
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiêm",
        "diemDau": "6-6",
        "diemCuoi": "6-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.014653523369006,
        "to_latitude": 21.014490837044217,
        "to_longitude": 105.83617556522937,
        "from_longitude": 105.83635693163136,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5621,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83635693163136,
          21.014522875236192,
          105.83654874545945,
          21.014653523369006
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83635693163136,
            21.014653523369006
          ],
          [
            105.83654874545945,
            21.014522875236192
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiêm",
        "diemDau": "6-6",
        "diemCuoi": "6-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.014653523369006,
        "to_latitude": 21.014522875236192,
        "to_longitude": 105.83654874545945,
        "from_longitude": 105.83635693163136,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5622,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8363966223351,
          21.01435042105383,
          105.83654874545945,
          21.014522875236192
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83654874545945,
            21.014522875236192
          ],
          [
            105.8363966223351,
            21.01435042105383
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiêm",
        "diemDau": "6-7",
        "diemCuoi": "6-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.014522875236192,
        "to_latitude": 21.01435042105383,
        "to_longitude": 105.8363966223351,
        "from_longitude": 105.83654874545945,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5623,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82503710707476,
          21.027378780555754,
          105.82547698933308,
          21.02760160063178
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82503710707476,
            21.027378780555754
          ],
          [
            105.82523380250382,
            21.027426348914616
          ],
          [
            105.82547698933308,
            21.02760160063178
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 3 Vũ Thạch",
        "diemDau": "1",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.027378780555754,
        "to_latitude": 21.02760160063178,
        "to_longitude": 105.82547698933308,
        "from_longitude": 105.82503710707476,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5624,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82547698933308,
          21.02760160063178,
          105.82578007895167,
          21.027815657806798
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82547698933308,
            21.02760160063178
          ],
          [
            105.82578007895167,
            21.027815657806798
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 3 Vũ Thạch",
        "diemDau": "1-1",
        "diemCuoi": "1-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02760160063178,
        "to_latitude": 21.027815657806798,
        "to_longitude": 105.82578007895167,
        "from_longitude": 105.82547698933308,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5625,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82503710707476,
          21.0272410825893,
          105.82536031324243,
          21.027378780555754
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82503710707476,
            21.027378780555754
          ],
          [
            105.8251599425851,
            21.027245288390382
          ],
          [
            105.82527671784565,
            21.0272410825893
          ],
          [
            105.8253437730751,
            21.027308679788458
          ],
          [
            105.82536031324243,
            21.02726111139287
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 58,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 7 Vũ Thạch",
        "diemDau": "1",
        "diemCuoi": "1-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.027378780555754,
        "to_latitude": 21.02726111139287,
        "to_longitude": 105.82536031324243,
        "from_longitude": 105.82503710707476,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5626,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82497675737362,
          21.026936894812764,
          105.82524732345823,
          21.027378780555754
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82503710707476,
            21.027378780555754
          ],
          [
            105.82524732345823,
            21.027159309309276
          ],
          [
            105.82497675737362,
            21.026936894812764
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 64,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "diemDau": "1",
        "diemCuoi": "2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.027378780555754,
        "to_latitude": 21.026936894812764,
        "to_longitude": 105.82497675737362,
        "from_longitude": 105.82503710707476,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5627,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82475547510914,
          21.026936894812764,
          105.82497675737362,
          21.02709337008443
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82497675737362,
            21.026936894812764
          ],
          [
            105.82475547510914,
            21.02709337008443
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Vũ Thạch",
        "diemDau": "2-1",
        "diemCuoi": "2-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026936894812764,
        "to_latitude": 21.02709337008443,
        "to_longitude": 105.82475547510914,
        "from_longitude": 105.82497675737362,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5628,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8245569916464,
          21.026908103345193,
          105.82475547510914,
          21.02709337008443
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82475547510914,
            21.02709337008443
          ],
          [
            105.8245569916464,
            21.026908103345193
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "diemDau": "2-12",
        "diemCuoi": "2-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02709337008443,
        "to_latitude": 21.026908103345193,
        "to_longitude": 105.8245569916464,
        "from_longitude": 105.82475547510914,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5629,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82441751677194,
          21.02678292298557,
          105.8245569916464,
          21.026908103345193
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8245569916464,
            21.026908103345193
          ],
          [
            105.82441751677194,
            21.02678292298557
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "diemDau": "2-13",
        "diemCuoi": "2-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026908103345193,
        "to_latitude": 21.02678292298557,
        "to_longitude": 105.82441751677194,
        "from_longitude": 105.8245569916464,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5630,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82424585539562,
          21.026633958221062,
          105.82441751677194,
          21.02678292298557
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82441751677194,
            21.02678292298557
          ],
          [
            105.82424585539562,
            21.026633958221062
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "diemDau": "2-14",
        "diemCuoi": "2-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02678292298557,
        "to_latitude": 21.026633958221062,
        "to_longitude": 105.82424585539562,
        "from_longitude": 105.82441751677194,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5631,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82395617682474,
          21.026428661998082,
          105.82424585539562,
          21.026633958221062
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82424585539562,
            21.026633958221062
          ],
          [
            105.82395617682474,
            21.026428661998082
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "diemDau": "2-15",
        "diemCuoi": "2-16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026633958221062,
        "to_latitude": 21.026428661998082,
        "to_longitude": 105.82395617682474,
        "from_longitude": 105.82424585539562,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5632,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8247863205341,
          21.026772908551994,
          105.82497675737362,
          21.026936894812764
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82497675737362,
            21.026936894812764
          ],
          [
            105.8247863205341,
            21.026772908551994
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "diemDau": "2-1",
        "diemCuoi": "2-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026936894812764,
        "to_latitude": 21.026772908551994,
        "to_longitude": 105.8247863205341,
        "from_longitude": 105.82497675737362,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5633,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82450737077622,
          21.026526302920157,
          105.8247863205341,
          21.026772908551994
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8247863205341,
            21.026772908551994
          ],
          [
            105.82450737077622,
            21.026526302920157
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "diemDau": "2-2",
        "diemCuoi": "2-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026772908551994,
        "to_latitude": 21.026526302920157,
        "to_longitude": 105.82450737077622,
        "from_longitude": 105.8247863205341,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5634,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82394410688988,
          21.026078155599443,
          105.82421769220534,
          21.026265926986863
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82421769220534,
            21.026265926986863
          ],
          [
            105.82394410688988,
            21.026078155599443
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "diemDau": "2-4",
        "diemCuoi": "2-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026265926986863,
        "to_latitude": 21.026078155599443,
        "to_longitude": 105.82394410688988,
        "from_longitude": 105.82421769220534,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5635,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82425479621085,
          21.026060630257806,
          105.82450737077622,
          21.026526302920157
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82450737077622,
            21.026526302920157
          ],
          [
            105.82425479621085,
            21.026297222195385
          ],
          [
            105.82447518427433,
            21.026060630257806
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "diemDau": "2-3",
        "diemCuoi": "2-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026526302920157,
        "to_latitude": 21.026060630257806,
        "to_longitude": 105.82447518427433,
        "from_longitude": 105.82450737077622,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5636,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82421769220534,
          21.026265926986863,
          105.82450737077622,
          21.026526302920157
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82450737077622,
            21.026526302920157
          ],
          [
            105.82421769220534,
            21.026265926986863
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Vũ Thạch",
        "diemDau": "2-3",
        "diemCuoi": "2-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026526302920157,
        "to_latitude": 21.026265926986863,
        "to_longitude": 105.82421769220534,
        "from_longitude": 105.82450737077622,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5637,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82418282348674,
          21.02584281512643,
          105.82447518427433,
          21.026060630257806
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82447518427433,
            21.026060630257806
          ],
          [
            105.82418282348674,
            21.02584281512643
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "diemDau": "2-6",
        "diemCuoi": "2-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026060630257806,
        "to_latitude": 21.02584281512643,
        "to_longitude": 105.82418282348674,
        "from_longitude": 105.82447518427433,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5638,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82398702223173,
          21.025685086728384,
          105.82418282348674,
          21.02584281512643
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82418282348674,
            21.02584281512643
          ],
          [
            105.82398702223173,
            21.025685086728384
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "diemDau": "2-7",
        "diemCuoi": "2-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02584281512643,
        "to_latitude": 21.025685086728384,
        "to_longitude": 105.82398702223173,
        "from_longitude": 105.82418282348674,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5639,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8244398686349,
          21.02584782237454,
          105.82457710821345,
          21.026060630257806
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82447518427433,
            21.026060630257806
          ],
          [
            105.8244398686349,
            21.02599929154581
          ],
          [
            105.82457710821345,
            21.02584782237454
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "diemDau": "2-6",
        "diemCuoi": "2-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026060630257806,
        "to_latitude": 21.02584782237454,
        "to_longitude": 105.82457710821345,
        "from_longitude": 105.82447518427433,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5640,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82457710821345,
          21.02570261211417,
          105.82467098552935,
          21.02584782237454
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82457710821345,
            21.02584782237454
          ],
          [
            105.82459811896354,
            21.02578147632479
          ],
          [
            105.82467098552935,
            21.02570261211417
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "diemDau": "2-9",
        "diemCuoi": "2-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02584782237454,
        "to_latitude": 21.02570261211417,
        "to_longitude": 105.82467098552935,
        "from_longitude": 105.82457710821345,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5641,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82435180265533,
          21.025512336387948,
          105.82467098552935,
          21.02570261211417
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82467098552935,
            21.02570261211417
          ],
          [
            105.82435180265533,
            21.025512336387948
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "diemDau": "2-10",
        "diemCuoi": "2-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02570261211417,
        "to_latitude": 21.025512336387948,
        "to_longitude": 105.82435180265533,
        "from_longitude": 105.82467098552935,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5642,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82556818446818,
          21.02692938399498,
          105.82567949611644,
          21.027079600266966
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82556818446818,
            21.02692938399498
          ],
          [
            105.82567949611644,
            21.027079600266966
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "diemDau": "3",
        "diemCuoi": "5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02692938399498,
        "to_latitude": 21.027079600266966,
        "to_longitude": 105.82567949611644,
        "from_longitude": 105.82556818446818,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5643,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82567949611644,
          21.027079600266966,
          105.82603354770906,
          21.027367514363576
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82567949611644,
            21.027079600266966
          ],
          [
            105.82588826149828,
            21.027221053783872
          ],
          [
            105.82603354770906,
            21.027367514363576
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "diemDau": "5",
        "diemCuoi": "6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.027079600266966,
        "to_latitude": 21.027367514363576,
        "to_longitude": 105.82603354770906,
        "from_longitude": 105.82567949611644,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5644,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82594101149705,
          21.027367514363576,
          105.82603354770906,
          21.027497701424064
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82603354770906,
            21.027367514363576
          ],
          [
            105.82594101149705,
            21.027497701424064
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "diemDau": "6",
        "diemCuoi": "6-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.027367514363576,
        "to_latitude": 21.027497701424064,
        "to_longitude": 105.82594101149705,
        "from_longitude": 105.82603354770906,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5645,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82603354770906,
          21.027367514363576,
          105.82611937839273,
          21.027438866901207
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82603354770906,
            21.027367514363576
          ],
          [
            105.82611937839273,
            21.027438866901207
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Vũ Thạch",
        "diemDau": "6",
        "diemCuoi": "6-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.027367514363576,
        "to_latitude": 21.027438866901207,
        "to_longitude": 105.82611937839273,
        "from_longitude": 105.82603354770906,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5646,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82611937839273,
          21.027438866901207,
          105.8263299317993,
          21.027585327266884
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82611937839273,
            21.027438866901207
          ],
          [
            105.8263299317993,
            21.027585327266884
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "diemDau": "6",
        "diemCuoi": "6-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.027438866901207,
        "to_latitude": 21.027585327266884,
        "to_longitude": 105.8263299317993,
        "from_longitude": 105.82611937839273,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5647,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82609389741022,
          21.027585327266884,
          105.8263299317993,
          21.027804391647674
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8263299317993,
            21.027585327266884
          ],
          [
            105.82609389741022,
            21.027804391647674
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29/16 Vũ Thạch",
        "diemDau": "6-7",
        "diemCuoi": "6-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.027585327266884,
        "to_latitude": 21.027804391647674,
        "to_longitude": 105.82609389741022,
        "from_longitude": 105.8263299317993,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5648,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82603354770906,
          21.027248593391366,
          105.82617838699001,
          21.027367514363576
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82603354770906,
            21.027367514363576
          ],
          [
            105.82617838699001,
            21.027248593391366
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "diemDau": "6",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.027367514363576,
        "to_latitude": 21.027248593391366,
        "to_longitude": 105.82617838699001,
        "from_longitude": 105.82603354770906,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5649,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82617838699001,
          21.027248593391366,
          105.82625617105046,
          21.027377528756375
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82617838699001,
            21.027248593391366
          ],
          [
            105.82625617105046,
            21.027377528756375
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "diemDau": "6-1",
        "diemCuoi": "6-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.027248593391366,
        "to_latitude": 21.027377528756375,
        "to_longitude": 105.82625617105046,
        "from_longitude": 105.82617838699001,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5650,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82625617105046,
          21.027377528756375,
          105.82636882382951,
          21.027492694232222
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82625617105046,
            21.027377528756375
          ],
          [
            105.82636882382951,
            21.027492694232222
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "diemDau": "6-2",
        "diemCuoi": "6-2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.027377528756375,
        "to_latitude": 21.027492694232222,
        "to_longitude": 105.82636882382951,
        "from_longitude": 105.82625617105046,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5651,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82636882382951,
          21.027485183442455,
          105.82648281771245,
          21.027492694232222
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82636882382951,
            21.027492694232222
          ],
          [
            105.82648281771245,
            21.027485183442455
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Vũ Thạch",
        "diemDau": "6-2-1",
        "diemCuoi": "6-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.027492694232222,
        "to_latitude": 21.027485183442455,
        "to_longitude": 105.82648281771245,
        "from_longitude": 105.82636882382951,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5652,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82617838699001,
          21.02708210386986,
          105.82631652076059,
          21.027248593391366
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82617838699001,
            21.027248593391366
          ],
          [
            105.82631652076059,
            21.02708210386986
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "diemDau": "6-1",
        "diemCuoi": "6-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.027248593391366,
        "to_latitude": 21.02708210386986,
        "to_longitude": 105.82631652076059,
        "from_longitude": 105.82617838699001,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5653,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82631652076059,
          21.02698070790545,
          105.82647745329697,
          21.02708210386986
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82631652076059,
            21.02708210386986
          ],
          [
            105.82647745329697,
            21.02698070790545
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "diemDau": "6-4",
        "diemCuoi": "6-4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02708210386986,
        "to_latitude": 21.02698070790545,
        "to_longitude": 105.82647745329697,
        "from_longitude": 105.82631652076059,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5654,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82647745329697,
          21.02698070790545,
          105.82660351712374,
          21.02701325476548
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82647745329697,
            21.02698070790545
          ],
          [
            105.82660351712374,
            21.02701325476548
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Vũ Thạch",
        "diemDau": "6-4-1",
        "diemCuoi": "6-4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02698070790545,
        "to_latitude": 21.02701325476548,
        "to_longitude": 105.82660351712374,
        "from_longitude": 105.82647745329697,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5655,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82660351712374,
          21.026965686274757,
          105.82678456622716,
          21.027068334051368
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82660351712374,
            21.02701325476548
          ],
          [
            105.82668755979168,
            21.026965686274757
          ],
          [
            105.82678456622716,
            21.027068334051368
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "diemDau": "6-4-2",
        "diemCuoi": "6-3-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02701325476548,
        "to_latitude": 21.027068334051368,
        "to_longitude": 105.82678456622716,
        "from_longitude": 105.82660351712374,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5656,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82574252802533,
          21.026682778622025,
          105.82588066181387,
          21.026765397725956
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82588066181387,
            21.026765397725956
          ],
          [
            105.82574252802533,
            21.026682778622025
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8 Vũ Thạch",
        "diemDau": "4",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026765397725956,
        "to_latitude": 21.026682778622025,
        "to_longitude": 105.82574252802533,
        "from_longitude": 105.82588066181387,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5657,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82567279058809,
          21.02651503666275,
          105.82574252802533,
          21.026682778622025
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82574252802533,
            21.026682778622025
          ],
          [
            105.82567279058809,
            21.02651503666275
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8 Vũ Thạch",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026682778622025,
        "to_latitude": 21.02651503666275,
        "to_longitude": 105.82567279058809,
        "from_longitude": 105.82574252802533,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5658,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82574252802533,
          21.0264349210338,
          105.82582567650124,
          21.026682778622025
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82574252802533,
            21.026682778622025
          ],
          [
            105.82582567650124,
            21.0264349210338
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8 Vũ Thạch",
        "diemDau": "4-1",
        "diemCuoi": "4-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026682778622025,
        "to_latitude": 21.0264349210338,
        "to_longitude": 105.82582567650124,
        "from_longitude": 105.82574252802533,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5659,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82582567650124,
          21.02628470411293,
          105.82620655018926,
          21.0264349210338
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82582567650124,
            21.0264349210338
          ],
          [
            105.82620655018926,
            21.02628470411293
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8 Vũ Thạch",
        "diemDau": "4-3",
        "diemCuoi": "4-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.0264349210338,
        "to_latitude": 21.02628470411293,
        "to_longitude": 105.82620655018926,
        "from_longitude": 105.82582567650124,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5660,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82572643476989,
          21.02619457388775,
          105.82582567650124,
          21.0264349210338
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82582567650124,
            21.0264349210338
          ],
          [
            105.82572643476989,
            21.02619457388775
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8 Vũ Thạch",
        "diemDau": "4-3",
        "diemCuoi": "4-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.0264349210338,
        "to_latitude": 21.02619457388775,
        "to_longitude": 105.82572643476989,
        "from_longitude": 105.82582567650124,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5661,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82558695990441,
          21.026069392928886,
          105.82572643476989,
          21.02619457388775
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82572643476989,
            21.02619457388775
          ],
          [
            105.82558695990441,
            21.026069392928886
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8 Vũ Thạch",
        "diemDau": "4-5",
        "diemCuoi": "4-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02619457388775,
        "to_latitude": 21.026069392928886,
        "to_longitude": 105.82558695990441,
        "from_longitude": 105.82572643476989,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5662,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82558695990441,
          21.02596674446435,
          105.82583372313346,
          21.026069392928886
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82558695990441,
            21.026069392928886
          ],
          [
            105.82583372313346,
            21.02596674446435
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8 Vũ Thạch",
        "diemDau": "4-8",
        "diemCuoi": "4-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026069392928886,
        "to_latitude": 21.02596674446435,
        "to_longitude": 105.82583372313346,
        "from_longitude": 105.82558695990441,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5663,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82583372313346,
          21.02585658505814,
          105.82607512194701,
          21.02596674446435
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82583372313346,
            21.02596674446435
          ],
          [
            105.82607512194701,
            21.02585658505814
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8 Vũ Thạch",
        "diemDau": "4-9",
        "diemCuoi": "4-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02596674446435,
        "to_latitude": 21.02585658505814,
        "to_longitude": 105.82607512194701,
        "from_longitude": 105.82583372313346,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5664,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82572643476989,
          21.026106947227298,
          105.82593564707257,
          21.02619457388775
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82572643476989,
            21.02619457388775
          ],
          [
            105.82593564707257,
            21.026106947227298
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8 Vũ Thạch",
        "diemDau": "4-5",
        "diemCuoi": "4-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02619457388775,
        "to_latitude": 21.026106947227298,
        "to_longitude": 105.82593564707257,
        "from_longitude": 105.82572643476989,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5665,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82593564707257,
          21.026059378447375,
          105.82618241030163,
          21.026106947227298
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82593564707257,
            21.026106947227298
          ],
          [
            105.82618241030163,
            21.026059378447375
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8 Vũ Thạch",
        "diemDau": "4-6",
        "diemCuoi": "4-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026106947227298,
        "to_latitude": 21.026059378447375,
        "to_longitude": 105.82618241030163,
        "from_longitude": 105.82593564707257,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5666,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83589200693602,
          21.0224416030788,
          105.83589670080407,
          21.02255739828595
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83589200693602,
            21.0224416030788
          ],
          [
            105.83589670080407,
            21.02255739828595
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 33 Văn Chương 2",
        "diemDau": "Tủ VC4",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.0224416030788,
        "to_latitude": 21.02255739828595,
        "to_longitude": 105.83589670080407,
        "from_longitude": 105.83589200693602,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5667,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83588060754863,
          21.02255739828595,
          105.83589670080407,
          21.02279900313328
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83589670080407,
            21.02255739828595
          ],
          [
            105.83588060754863,
            21.02279900313328
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 33 Văn Chương 2",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02255739828595,
        "to_latitude": 21.02279900313328,
        "to_longitude": 105.83588060754863,
        "from_longitude": 105.83589670080407,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5668,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83584842103777,
          21.02279900313328,
          105.83588060754863,
          21.022962993765734
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83588060754863,
            21.02279900313328
          ],
          [
            105.83584842103777,
            21.022962993765734
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 33 Văn Chương 2",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02279900313328,
        "to_latitude": 21.022962993765734,
        "to_longitude": 105.83584842103777,
        "from_longitude": 105.83588060754863,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5669,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83584842103777,
          21.022962993765734,
          105.8359047474273,
          21.023372343641125
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83584842103777,
            21.022962993765734
          ],
          [
            105.8359047474273,
            21.023372343641125
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 33 Văn Chương 2",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022962993765734,
        "to_latitude": 21.023372343641125,
        "to_longitude": 105.8359047474273,
        "from_longitude": 105.83584842103777,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5670,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83585881466239,
          21.023372343641125,
          105.8359704615529,
          21.023625213644447
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8359047474273,
            21.023372343641125
          ],
          [
            105.83585881466239,
            21.023429614974024
          ],
          [
            105.8359704615529,
            21.023625213644447
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 33 Văn Chương 2",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.023372343641125,
        "to_latitude": 21.023625213644447,
        "to_longitude": 105.8359704615529,
        "from_longitude": 105.8359047474273,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5671,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8359704615529,
          21.023625213644447,
          105.83613407629704,
          21.023910630756326
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8359704615529,
            21.023625213644447
          ],
          [
            105.8360975312682,
            21.023832704423388
          ],
          [
            105.83613407629704,
            21.023910630756326
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 33 Văn Chương 2",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.023625213644447,
        "to_latitude": 21.023910630756326,
        "to_longitude": 105.83613407629704,
        "from_longitude": 105.8359704615529,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5672,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.835814893441,
          21.02279900313328,
          105.83588060754863,
          21.02283530641894
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83588060754863,
            21.02279900313328
          ],
          [
            105.835814893441,
            21.02283530641894
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hẻm 33/3 Văn Chương",
        "diemDau": "12",
        "diemCuoi": "12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02279900313328,
        "to_latitude": 21.02283530641894,
        "to_longitude": 105.835814893441,
        "from_longitude": 105.83588060754863,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5673,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8357210161251,
          21.022962993765734,
          105.83584842103777,
          21.02297676396346
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83584842103777,
            21.022962993765734
          ],
          [
            105.8357210161251,
            21.02297676396346
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hem 33/9",
        "diemDau": "13",
        "diemCuoi": "13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022962993765734,
        "to_latitude": 21.02297676396346,
        "to_longitude": 105.8357210161251,
        "from_longitude": 105.83584842103777,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5674,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83560702224217,
          21.02297676396346,
          105.8357210161251,
          21.02298928232368
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8357210161251,
            21.02297676396346
          ],
          [
            105.83563887352874,
            21.022985213851513
          ],
          [
            105.83560702224217,
            21.02298928232368
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hem 33/9",
        "diemDau": "13-1",
        "diemCuoi": "13-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02297676396346,
        "to_latitude": 21.02298928232368,
        "to_longitude": 105.83560702224217,
        "from_longitude": 105.8357210161251,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5675,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8357210161251,
          21.02297676396346,
          105.83575320262699,
          21.02309443650922
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8357210161251,
            21.02297676396346
          ],
          [
            105.8357421385717,
            21.023003991390834
          ],
          [
            105.83575320262699,
            21.02309443650922
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hem 33/9",
        "diemDau": "13-1",
        "diemCuoi": "13-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02297676396346,
        "to_latitude": 21.02309443650922,
        "to_longitude": 105.83575320262699,
        "from_longitude": 105.8357210161251,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5676,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83584842103777,
          21.022962993765734,
          105.83616626281689,
          21.02299898404718
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83584842103777,
            21.022962993765734
          ],
          [
            105.8359017300132,
            21.02299898404718
          ],
          [
            105.83602511162324,
            21.022997732211888
          ],
          [
            105.83607607359728,
            21.022990221195613
          ],
          [
            105.83616626281689,
            21.022988030487465
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hẻm 33/20",
        "diemDau": "13",
        "diemCuoi": "13-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022962993765734,
        "to_latitude": 21.022988030487465,
        "to_longitude": 105.83616626281689,
        "from_longitude": 105.83584842103777,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5677,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8360358404632,
          21.022988030487465,
          105.83616626281689,
          21.02306814796977
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83616626281689,
            21.022988030487465
          ],
          [
            105.83614312881778,
            21.023012754241602
          ],
          [
            105.8360358404632,
            21.023022768927788
          ],
          [
            105.83604422231072,
            21.02306814796977
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hẻm 33/20",
        "diemDau": "13-3",
        "diemCuoi": "13-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022988030487465,
        "to_latitude": 21.02306814796977,
        "to_longitude": 105.83604422231072,
        "from_longitude": 105.83616626281689,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5678,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8361645864887,
          21.022839061930636,
          105.83618503828005,
          21.022988030487465
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83616626281689,
            21.022988030487465
          ],
          [
            105.8361645864887,
            21.02284250447842
          ],
          [
            105.83618503828005,
            21.022839061930636
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hẻm 33/20",
        "diemDau": "13-3",
        "diemCuoi": "13-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022988030487465,
        "to_latitude": 21.022839061930636,
        "to_longitude": 105.83618503828005,
        "from_longitude": 105.83616626281689,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5679,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83616626281689,
          21.022902905616053,
          105.83636206408087,
          21.022988030487465
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83616626281689,
            21.022988030487465
          ],
          [
            105.83618202084801,
            21.022946406930036
          ],
          [
            105.8363416122895,
            21.022937644076215
          ],
          [
            105.83636206408087,
            21.022902905616053
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hẻm 33/20",
        "diemDau": "13-3",
        "diemCuoi": "VC4 13-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022988030487465,
        "to_latitude": 21.022902905616053,
        "to_longitude": 105.83636206408087,
        "from_longitude": 105.83616626281689,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5680,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83612200637118,
          21.022621242092,
          105.83618503828005,
          21.022839061930636
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83618503828005,
            21.022839061930636
          ],
          [
            105.83617531532865,
            21.022751120333783
          ],
          [
            105.83614983434612,
            21.02274110562934
          ],
          [
            105.83614178771394,
            21.022672254519993
          ],
          [
            105.83612200637118,
            21.022621242092
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hẻm 33/20",
        "diemDau": "13-4",
        "diemCuoi": "13-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022839061930636,
        "to_latitude": 21.022621242092,
        "to_longitude": 105.83612200637118,
        "from_longitude": 105.83618503828005,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5681,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.835955374195,
          21.023539776175404,
          105.83604757508388,
          21.023625213644447
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8359704615529,
            21.023625213644447
          ],
          [
            105.83599024291361,
            21.023607375053086
          ],
          [
            105.835955374195,
            21.023546035331616
          ],
          [
            105.83597549076204,
            21.023539776175404
          ],
          [
            105.83604757508388,
            21.02360080294233
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hẻm 33/38",
        "diemDau": "15",
        "diemCuoi": "15-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.023625213644447,
        "to_latitude": 21.02360080294233,
        "to_longitude": 105.83604757508388,
        "from_longitude": 105.8359704615529,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5682,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83604757508388,
          21.023529448566656,
          105.83617229780677,
          21.02360080294233
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83604757508388,
            21.02360080294233
          ],
          [
            105.83617229780677,
            21.023529448566656
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hẻm 33/38",
        "diemDau": "15-1",
        "diemCuoi": "15-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02360080294233,
        "to_latitude": 21.023529448566656,
        "to_longitude": 105.83617229780677,
        "from_longitude": 105.83604757508388,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5683,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83617229780677,
          21.023416783694042,
          105.83630104384126,
          21.023529448566656
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83617229780677,
            21.023529448566656
          ],
          [
            105.83620079632017,
            21.02347217726626
          ],
          [
            105.83628930921158,
            21.023445888793436
          ],
          [
            105.83630104384126,
            21.023416783694042
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hẻm 33/38",
        "diemDau": "15-2",
        "diemCuoi": "VC4 15-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.023529448566656,
        "to_latitude": 21.023416783694042,
        "to_longitude": 105.83630104384126,
        "from_longitude": 105.83617229780677,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5684,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83613407629704,
          21.023910630756326,
          105.83616391590532,
          21.024017349071634
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83613407629704,
            21.023910630756326
          ],
          [
            105.83614446993064,
            21.023960390917562
          ],
          [
            105.83614178772288,
            21.02397666467821
          ],
          [
            105.83616391590532,
            21.024017349071634
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 46 Linh Quang",
        "diemDau": "16",
        "diemCuoi": "16-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.023910630756326,
        "to_latitude": 21.024017349071634,
        "to_longitude": 105.83616391590532,
        "from_longitude": 105.83613407629704,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5685,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83613407629704,
          21.023849604113735,
          105.83628327420374,
          21.023910630756326
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83613407629704,
            21.023910630756326
          ],
          [
            105.83620079632017,
            21.023881525743494
          ],
          [
            105.83628327420374,
            21.023849604113735
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Linh Quang",
        "diemDau": "16",
        "diemCuoi": "16-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.023910630756326,
        "to_latitude": 21.023849604113735,
        "to_longitude": 105.83628327420374,
        "from_longitude": 105.83613407629704,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5686,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83628327420374,
          21.023849604113735,
          105.83640799691767,
          21.023924087905872
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83628327420374,
            21.023849604113735
          ],
          [
            105.8363416122895,
            21.023924087905872
          ],
          [
            105.83640799691767,
            21.023885907143566
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Linh Quang",
        "diemDau": "16-1",
        "diemCuoi": "16-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.023849604113735,
        "to_latitude": 21.023885907143566,
        "to_longitude": 105.83640799691767,
        "from_longitude": 105.83628327420374,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5687,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83628327420374,
          21.023849604113735,
          105.83638385703898,
          21.024047392925738
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83628327420374,
            21.023849604113735
          ],
          [
            105.83628662700384,
            21.023902806827042
          ],
          [
            105.83631613130696,
            21.023944117155146
          ],
          [
            105.8363241779302,
            21.023999197575105
          ],
          [
            105.83638385703898,
            21.024047392925738
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Linh Quang",
        "diemDau": "16-1",
        "diemCuoi": "16-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.023849604113735,
        "to_latitude": 21.024047392925738,
        "to_longitude": 105.83638385703898,
        "from_longitude": 105.83628327420374,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5688,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83638385703898,
          21.024047392925738,
          105.83651528528124,
          21.02418133836469
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83638385703898,
            21.024047392925738
          ],
          [
            105.83646365280465,
            21.02412062479233
          ],
          [
            105.83651528528124,
            21.02418133836469
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Linh Quang",
        "diemDau": "16-3",
        "diemCuoi": "VC4 16-3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.024047392925738,
        "to_latitude": 21.02418133836469,
        "to_longitude": 105.83651528528124,
        "from_longitude": 105.83638385703898,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5689,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83585680297513,
          21.024017349071634,
          105.83616391590532,
          21.02412125070529
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83616391590532,
            21.024017349071634
          ],
          [
            105.83608680242826,
            21.024035500567646
          ],
          [
            105.83607339138058,
            21.024030493258646
          ],
          [
            105.83585680297513,
            21.02412125070529
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 46 Linh Quang",
        "diemDau": "16-4",
        "diemCuoi": "16-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.024017349071634,
        "to_latitude": 21.02412125070529,
        "to_longitude": 105.83585680297513,
        "from_longitude": 105.83616391590532,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5690,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83548263481546,
          21.02412125070529,
          105.83585680297513,
          21.024370363965268
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83585680297513,
            21.02412125070529
          ],
          [
            105.83581858152829,
            21.024133143056524
          ],
          [
            105.83579712385736,
            21.024125632098315
          ],
          [
            105.83548263481546,
            21.024370363965268
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 51,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 46 Linh Quang",
        "diemDau": "16-5",
        "diemCuoi": "16-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02412125070529,
        "to_latitude": 21.024370363965268,
        "to_longitude": 105.83548263481546,
        "from_longitude": 105.83585680297513,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5691,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83585680297513,
          21.02412125070529,
          105.83591715267627,
          21.024216389486153
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83585680297513,
            21.02412125070529
          ],
          [
            105.83591715267627,
            21.024216389486153
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 46 Linh Quang",
        "diemDau": "16-5",
        "diemCuoi": "16-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02412125070529,
        "to_latitude": 21.024216389486153,
        "to_longitude": 105.83591715267627,
        "from_longitude": 105.83585680297513,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5692,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83579779437785,
          21.024216389486153,
          105.83591715267627,
          21.024281484407112
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83591715267627,
            21.024216389486153
          ],
          [
            105.83579779437785,
            21.024281484407112
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "16-8",
        "diemCuoi": "16-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.024216389486153,
        "to_latitude": 21.024281484407112,
        "to_longitude": 105.83579779437785,
        "from_longitude": 105.83591715267627,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5693,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83564356736083,
          21.024281484407112,
          105.83579779437785,
          21.02438788950481
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83579779437785,
            21.024281484407112
          ],
          [
            105.83564356736083,
            21.02438788950481
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 46 Linh Quang",
        "diemDau": "16-9",
        "diemCuoi": "16-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.024281484407112,
        "to_latitude": 21.02438788950481,
        "to_longitude": 105.83564356736083,
        "from_longitude": 105.83579779437785,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5694,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83579779437785,
          21.024281484407112,
          105.83590508274145,
          21.02443295517082
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83579779437785,
            21.024281484407112
          ],
          [
            105.83590508274145,
            21.02443295517082
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 46 Linh Quang",
        "diemDau": "16-9",
        "diemCuoi": "16-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.024281484407112,
        "to_latitude": 21.02443295517082,
        "to_longitude": 105.83590508274145,
        "from_longitude": 105.83579779437785,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5695,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83571174856051,
          21.02443295517082,
          105.83590508274145,
          21.02452201502215
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83590508274145,
            21.02443295517082
          ],
          [
            105.83571174856051,
            21.02452201502215
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 46 Linh Quang",
        "diemDau": "16-11",
        "diemCuoi": "16-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02443295517082,
        "to_latitude": 21.02452201502215,
        "to_longitude": 105.83571174856051,
        "from_longitude": 105.83590508274145,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5696,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83552420905343,
          21.02452201502215,
          105.83571174856051,
          21.02461446951959
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83571174856051,
            21.02452201502215
          ],
          [
            105.83552420905343,
            21.02461446951959
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 46 Linh Quang",
        "diemDau": "16-12",
        "diemCuoi": "VC4 16-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02452201502215,
        "to_latitude": 21.02461446951959,
        "to_longitude": 105.83552420905343,
        "from_longitude": 105.83571174856051,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5697,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82838282751776,
          21.01260024658234,
          105.82845256495499,
          21.012720431160567
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82845256495499,
            21.012720431160567
          ],
          [
            105.82838282751776,
            21.01260024658234
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.012720431160567,
        "to_latitude": 21.01260024658234,
        "to_longitude": 105.82838282751776,
        "from_longitude": 105.82845256495499,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5698,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.804090395886,
          21.0178817040544,
          105.80427091091407,
          21.017996283486394
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.804090395886,
            21.0178817040544
          ],
          [
            105.80427091091407,
            21.017996283486394
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "diemDau": "01",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.0178817040544,
        "to_latitude": 21.017996283486394,
        "to_longitude": 105.80427091091407,
        "from_longitude": 105.804090395886,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5699,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80417468665966,
          21.017996283486394,
          105.80427091091407,
          21.018116150746838
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80427091091407,
            21.017996283486394
          ],
          [
            105.80417468665966,
            21.018116150746838
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "diemDau": "08",
        "diemCuoi": "8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.017996283486394,
        "to_latitude": 21.018116150746838,
        "to_longitude": 105.80417468665966,
        "from_longitude": 105.80427091091407,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5700,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80427091091407,
          21.017918354067277,
          105.8044093799449,
          21.017996283486394
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80427091091407,
            21.017996283486394
          ],
          [
            105.8044093799449,
            21.017918354067277
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "diemDau": "08",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.017996283486394,
        "to_latitude": 21.017918354067277,
        "to_longitude": 105.8044093799449,
        "from_longitude": 105.80427091091407,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5701,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80416261671584,
          21.017918354067277,
          105.8044093799449,
          21.01821630086495
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8044093799449,
            21.017918354067277
          ],
          [
            105.80416261671584,
            21.01821630086495
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "diemDau": "10",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.017918354067277,
        "to_latitude": 21.01821630086495,
        "to_longitude": 105.80416261671584,
        "from_longitude": 105.8044093799449,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5702,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80427091091407,
          21.01776812856515,
          105.80447241186276,
          21.017996283486394
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80427091091407,
            21.017996283486394
          ],
          [
            105.80447241186276,
            21.01776812856515
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "diemDau": "08",
        "diemCuoi": "8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.017996283486394,
        "to_latitude": 21.01776812856515,
        "to_longitude": 105.80447241186276,
        "from_longitude": 105.80427091091407,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5703,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80447241186276,
          21.017485203457813,
          105.80470576404412,
          21.01776812856515
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80447241186276,
            21.01776812856515
          ],
          [
            105.80470576404412,
            21.017485203457813
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "diemDau": "8-1",
        "diemCuoi": "8-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01776812856515,
        "to_latitude": 21.017485203457813,
        "to_longitude": 105.80470576404412,
        "from_longitude": 105.80447241186276,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5704,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80470576404412,
          21.017263620145858,
          105.8049055886287,
          21.0174852034578
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80470576404412,
            21.0174852034578
          ],
          [
            105.8049055886287,
            21.017263620145858
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "diemDau": "8-2",
        "diemCuoi": "8-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.0174852034578,
        "to_latitude": 21.017263620145858,
        "to_longitude": 105.8049055886287,
        "from_longitude": 105.80470576404412,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5705,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8049055886287,
          21.01701324312618,
          105.80512016534686,
          21.017263620145858
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8049055886287,
            21.017263620145858
          ],
          [
            105.80512016534686,
            21.01701324312618
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "diemDau": "8-3",
        "diemCuoi": "8-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.017263620145858,
        "to_latitude": 21.01701324312618,
        "to_longitude": 105.80512016534686,
        "from_longitude": 105.8049055886287,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5706,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80512016534686,
          21.01679666666422,
          105.80531194329025,
          21.01701324312618
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80512016534686,
            21.01701324312618
          ],
          [
            105.80531194329025,
            21.01679666666422
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "diemDau": "8-4",
        "diemCuoi": "8-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01701324312618,
        "to_latitude": 21.01679666666422,
        "to_longitude": 105.80531194329025,
        "from_longitude": 105.80512016534686,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5707,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80531194329025,
          21.01674502627626,
          105.8055704411939,
          21.016848932977762
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80531194329025,
            21.016796666664227
          ],
          [
            105.80542157859234,
            21.016848932977762
          ],
          [
            105.8055704411939,
            21.01674502627626
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "diemDau": "8-5",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.016796666664227,
        "to_latitude": 21.01674502627626,
        "to_longitude": 105.8055704411939,
        "from_longitude": 105.80531194329025,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5708,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8055704411939,
          21.01674502627626,
          105.80576892465662,
          21.016926549984568
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8055704411939,
            21.01674502627626
          ],
          [
            105.80576892465662,
            21.016926549984568
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01674502627626,
        "to_latitude": 21.016926549984568,
        "to_longitude": 105.80576892465662,
        "from_longitude": 105.8055704411939,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5709,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80576892465662,
          21.016926549984568,
          105.80598484248766,
          21.017145630027652
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80576892465662,
            21.016926549984568
          ],
          [
            105.80598484248766,
            21.017145630027652
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.016926549984568,
        "to_latitude": 21.017145630027652,
        "to_longitude": 105.80598484248766,
        "from_longitude": 105.80576892465662,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5710,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80588023633182,
          21.017145630027652,
          105.80598484248766,
          21.017257047754626
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80598484248766,
            21.017145630027652
          ],
          [
            105.80588023633182,
            21.017257047754626
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.017145630027652,
        "to_latitude": 21.017257047754626,
        "to_longitude": 105.80588023633182,
        "from_longitude": 105.80598484248766,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5711,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80574612588184,
          21.01713811871743,
          105.80588023633182,
          21.017257047754626
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80588023633182,
            21.017257047754626
          ],
          [
            105.80574612588184,
            21.01713811871743
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "diemDau": "17",
        "diemCuoi": "17-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.017257047754626,
        "to_latitude": 21.01713811871743,
        "to_longitude": 105.80574612588184,
        "from_longitude": 105.80588023633182,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5712,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83456531929158,
          21.02243190130898,
          105.83465752023439,
          21.022496058133264
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83456531929158,
            21.02244692339654
          ],
          [
            105.83463572734813,
            21.02243190130898
          ],
          [
            105.83465752023439,
            21.022496058133264
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 163+177 Văn Chương",
        "diemDau": "18",
        "diemCuoi": "18-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02244692339654,
        "to_latitude": 21.022496058133264,
        "to_longitude": 105.83465752023439,
        "from_longitude": 105.83456531929158,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5713,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83445199602798,
          21.022510767249077,
          105.83447915333869,
          21.022676323001534
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83445199602798,
            21.022510767249077
          ],
          [
            105.83445333713185,
            21.02258337395112
          ],
          [
            105.83447915333869,
            21.022676323001534
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 163+177 Văn Chương",
        "diemDau": "18",
        "diemCuoi": "18-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022510767249077,
        "to_latitude": 21.022676323001534,
        "to_longitude": 105.83447915333869,
        "from_longitude": 105.83445199602798,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5714,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83447915333869,
          21.022676323001534,
          105.83453547972822,
          21.022816528859227
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83447915333869,
            21.022676323001534
          ],
          [
            105.83451905125744,
            21.022767394227273
          ],
          [
            105.83453547972822,
            21.022816528859227
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 163+177 Văn Chương",
        "diemDau": "18-3",
        "diemCuoi": "18-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022676323001534,
        "to_latitude": 21.022816528859227,
        "to_longitude": 105.83453547972822,
        "from_longitude": 105.83447915333869,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5715,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83453547972822,
          21.022816528859227,
          105.8345757128623,
          21.02289038724942
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83453547972822,
            21.022816528859227
          ],
          [
            105.83454989666446,
            21.022838748967683
          ],
          [
            105.83456464880703,
            21.022878807753496
          ],
          [
            105.8345757128623,
            21.02289038724942
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 163+177 Văn Chương",
        "diemDau": "18-4",
        "diemCuoi": "18-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022816528859227,
        "to_latitude": 21.02289038724942,
        "to_longitude": 105.8345757128623,
        "from_longitude": 105.83453547972822,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5716,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83447915333869,
          21.022635012321306,
          105.8346025349487,
          21.022676323001534
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83447915333869,
            21.022676323001534
          ],
          [
            105.8346025349487,
            21.022635012321306
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 163+177 Văn Chương",
        "diemDau": "18-3",
        "diemCuoi": "18-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022676323001534,
        "to_latitude": 21.022635012321306,
        "to_longitude": 105.8346025349487,
        "from_longitude": 105.83447915333869,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5717,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8344442846111,
          21.022387148002764,
          105.83447747701949,
          21.022510767249077
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83445199602798,
            21.022510767249077
          ],
          [
            105.83447747701949,
            21.0224544344385
          ],
          [
            105.8344442846111,
            21.022387148002764
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 142 Văn Chương",
        "diemDau": "18",
        "diemCuoi": "18-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022510767249077,
        "to_latitude": 21.022387148002764,
        "to_longitude": 105.8344442846111,
        "from_longitude": 105.83445199602798,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5718,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83438661711769,
          21.022250697267747,
          105.8344442846111,
          21.022387148002764
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8344442846111,
            21.022387148002764
          ],
          [
            105.83441712731833,
            21.022306717190425
          ],
          [
            105.8344010340629,
            21.022299206139355
          ],
          [
            105.83438661711769,
            21.022250697267747
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 142 Văn Chương",
        "diemDau": "18-2",
        "diemCuoi": "18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022387148002764,
        "to_latitude": 21.022250697267747,
        "to_longitude": 105.83438661711769,
        "from_longitude": 105.8344442846111,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5719,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83424747753044,
          21.022585877632864,
          105.83430883305724,
          21.022766455353104
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83424747753044,
            21.022585877632864
          ],
          [
            105.83430883305724,
            21.022766455353104
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 145 Văn Chương",
        "diemDau": "19",
        "diemCuoi": "19-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022585877632864,
        "to_latitude": 21.022766455353104,
        "to_longitude": 105.83430883305724,
        "from_longitude": 105.83424747753044,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5720,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83430883305724,
          21.022766455353104,
          105.83435711282354,
          21.022900401942998
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83430883305724,
            21.022766455353104
          ],
          [
            105.83435711282354,
            21.022900401942998
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 145 Văn Chương",
        "diemDau": "19-1",
        "diemCuoi": "19-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022766455353104,
        "to_latitude": 21.022900401942998,
        "to_longitude": 105.83435711282354,
        "from_longitude": 105.83430883305724,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5721,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83422970789292,
          21.022900401942998,
          105.83435711282354,
          21.022951727239825
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83435711282354,
            21.022900401942998
          ],
          [
            105.83422970789292,
            21.022951727239825
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 145 Văn Chương",
        "diemDau": "19-2",
        "diemCuoi": "19-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022900401942998,
        "to_latitude": 21.022951727239825,
        "to_longitude": 105.83422970789292,
        "from_longitude": 105.83435711282354,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5722,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83435711282354,
          21.022900401942998,
          105.83440405147701,
          21.023015570877025
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83435711282354,
            21.022900401942998
          ],
          [
            105.83440405147701,
            21.023015570877025
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 145 Văn Chương",
        "diemDau": "19-2",
        "diemCuoi": "19-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022900401942998,
        "to_latitude": 21.023015570877025,
        "to_longitude": 105.83440405147701,
        "from_longitude": 105.83435711282354,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5723,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83428033465162,
          21.023015570877025,
          105.83440405147701,
          21.02314450990433
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83440405147701,
            21.023015570877025
          ],
          [
            105.83428033465162,
            21.02305531665285
          ],
          [
            105.83431687968944,
            21.02314450990433
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 145 Văn Chương",
        "diemDau": "19-4",
        "diemCuoi": "VC4 19-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.023015570877025,
        "to_latitude": 21.02314450990433,
        "to_longitude": 105.83431687968944,
        "from_longitude": 105.83440405147701,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5724,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83414253609638,
          21.022477578744876,
          105.83424747753044,
          21.022585877632864
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83424747753044,
            21.022585877632864
          ],
          [
            105.83422434347742,
            21.022557398285098
          ],
          [
            105.83419526098466,
            21.022477578744876
          ],
          [
            105.83414253609638,
            21.022493554451696
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 128 Văn Chương",
        "diemDau": "19",
        "diemCuoi": "19-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022585877632864,
        "to_latitude": 21.022493554451696,
        "to_longitude": 105.83414253609638,
        "from_longitude": 105.83424747753044,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5725,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83414253609638,
          21.0223909035249,
          105.83414655941698,
          21.022493554451696
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83414253609638,
            21.022493554451696
          ],
          [
            105.83414655941698,
            21.0223909035249
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 128 Văn Chương",
        "diemDau": "19-8",
        "diemCuoi": "19-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022493554451696,
        "to_latitude": 21.0223909035249,
        "to_longitude": 105.83414655941698,
        "from_longitude": 105.83414253609638,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5726,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83411537880365,
          21.022293259894894,
          105.83414655941698,
          21.0223909035249
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83414655941698,
            21.0223909035249
          ],
          [
            105.83411537880365,
            21.022301709823363
          ],
          [
            105.83413314836929,
            21.022293259894894
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 128 Văn Chương",
        "diemDau": "19-9",
        "diemCuoi": "19-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.0223909035249,
        "to_latitude": 21.022293259894894,
        "to_longitude": 105.83413314836929,
        "from_longitude": 105.83414655941698,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5727,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83424747753044,
          21.02238339247888,
          105.83435543650434,
          21.022585877632864
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83424747753044,
            21.022585877632864
          ],
          [
            105.83426155918846,
            21.02254331508592
          ],
          [
            105.83435543650434,
            21.022510767249077
          ],
          [
            105.8342967631224,
            21.02238339247888
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 138 Văn Chương",
        "diemDau": "19",
        "diemCuoi": "19-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022585877632864,
        "to_latitude": 21.02238339247888,
        "to_longitude": 105.8342967631224,
        "from_longitude": 105.83424747753044,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5728,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83421897905296,
          21.022230667790968,
          105.8342967631224,
          21.02238339247888
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8342967631224,
            21.02238339247888
          ],
          [
            105.83421897905296,
            21.022230667790968
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 138 Văn Chương",
        "diemDau": "19-6",
        "diemCuoi": "19-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02238339247888,
        "to_latitude": 21.022230667790968,
        "to_longitude": 105.83421897905296,
        "from_longitude": 105.8342967631224,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5729,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83410062665206,
          21.022650973263293,
          105.83415997045569,
          21.022778973731008
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83410062665206,
            21.022650973263293
          ],
          [
            105.83415997045569,
            21.022778973731008
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 139 Văn Chương",
        "diemDau": "20",
        "diemCuoi": "20-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022650973263293,
        "to_latitude": 21.022778973731008,
        "to_longitude": 105.83415997045569,
        "from_longitude": 105.83410062665206,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5730,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83394539373752,
          21.022469769487504,
          105.83410062665206,
          21.022650973263293
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83410062665206,
            21.022650973263293
          ],
          [
            105.83402150148775,
            21.02263469935709
          ],
          [
            105.83394539373752,
            21.022469769487504
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 55,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 124 Văn Chương",
        "diemDau": "20",
        "diemCuoi": "VC4 20-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022650973263293,
        "to_latitude": 21.022469769487504,
        "to_longitude": 105.83394539373752,
        "from_longitude": 105.83410062665206,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5731,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83358865993713,
          21.022682582193152,
          105.83367449067472,
          21.02278898843123
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83367449067472,
            21.02278898843123
          ],
          [
            105.8336436452228,
            21.022780225569004
          ],
          [
            105.83358865993713,
            21.022682582193152
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 92 Văn Chương",
        "diemDau": "21",
        "diemCuoi": "21-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02278898843123,
        "to_latitude": 21.022682582193152,
        "to_longitude": 105.83358865993713,
        "from_longitude": 105.83367449067472,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5732,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83358061331391,
          21.022567413001042,
          105.83360844129786,
          21.022682582193152
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83358865993713,
            21.022682582193152
          ],
          [
            105.8335923480424,
            21.02265347694057
          ],
          [
            105.83360844129786,
            21.022639706712972
          ],
          [
            105.83358061331391,
            21.022567413001042
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 92 Văn Chương",
        "diemDau": "21-1",
        "diemCuoi": "21-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022682582193152,
        "to_latitude": 21.022567413001042,
        "to_longitude": 105.83358061331391,
        "from_longitude": 105.83358865993713,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5733,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83349612372514,
          21.022682582193152,
          105.83358865993713,
          21.02272013734483
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83358865993713,
            21.022682582193152
          ],
          [
            105.83349612372514,
            21.02272013734483
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 92 Văn Chương",
        "diemDau": "21-1",
        "diemCuoi": "21-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022682582193152,
        "to_latitude": 21.02272013734483,
        "to_longitude": 105.83349612372514,
        "from_longitude": 105.83358865993713,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5734,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83367449067472,
          21.02278898843123,
          105.83378459243133,
          21.02303685308345
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83367449067472,
            21.02278898843123
          ],
          [
            105.83369863051745,
            21.02282028437053
          ],
          [
            105.83376718871037,
            21.022980271278342
          ],
          [
            105.83378459243133,
            21.02303685308345
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 105 Văn Chương",
        "diemDau": "21",
        "diemCuoi": "VC4 21-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02278898843123,
        "to_latitude": 21.02303685308345,
        "to_longitude": 105.83378459243133,
        "from_longitude": 105.83367449067472,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5735,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83331373356275,
          21.02290760000016,
          105.83353903907596,
          21.023044363095575
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83331373356275,
            21.02294296438381
          ],
          [
            105.83347030753627,
            21.02290760000016
          ],
          [
            105.83353903907596,
            21.023044363095575
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 69 Văn Chương",
        "diemDau": "22",
        "diemCuoi": "VC4 22-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02294296438381,
        "to_latitude": 21.023044363095575,
        "to_longitude": 105.83353903907596,
        "from_longitude": 105.83331373356275,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5736,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8331554831802,
          21.022733907566682,
          105.83331373356275,
          21.02294296438381
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83331373356275,
            21.02294296438381
          ],
          [
            105.8331554831802,
            21.022733907566682
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 70 Văn Chương",
        "diemDau": "22",
        "diemCuoi": "22-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02294296438381,
        "to_latitude": 21.022733907566682,
        "to_longitude": 105.8331554831802,
        "from_longitude": 105.83331373356275,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5737,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83307635801587,
          21.02264001967716,
          105.8331554831802,
          21.022733907566682
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8331554831802,
            21.022733907566682
          ],
          [
            105.83307635801587,
            21.02264001967716
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 70 Văn Chương",
        "diemDau": "22-1",
        "diemCuoi": "22-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022733907566682,
        "to_latitude": 21.02264001967716,
        "to_longitude": 105.83307635801587,
        "from_longitude": 105.8331554831802,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5738,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83307635801587,
          21.022461945481762,
          105.83330836910235,
          21.02264001967716
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83307635801587,
            21.02264001967716
          ],
          [
            105.8331310080952,
            21.022617173610623
          ],
          [
            105.83318867558862,
            21.02254957428395
          ],
          [
            105.83329059952771,
            21.022461945481762
          ],
          [
            105.83330836910235,
            21.02247227316949
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 70 Văn Chương",
        "diemDau": "22-2",
        "diemCuoi": "VC4 22-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02264001967716,
        "to_latitude": 21.02247227316949,
        "to_longitude": 105.83330836910235,
        "from_longitude": 105.83307635801587,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5739,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8329556586136,
          21.022501065493824,
          105.83307635801587,
          21.02264001967716
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83307635801587,
            21.02264001967716
          ],
          [
            105.8329556586136,
            21.022501065493824
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 70 Văn Chương",
        "diemDau": "22-2",
        "diemCuoi": "VC4 22-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02264001967716,
        "to_latitude": 21.022501065493824,
        "to_longitude": 105.8329556586136,
        "from_longitude": 105.83307635801587,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5740,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83291676658337,
          21.02259964786208,
          105.83307635801587,
          21.022651286227468
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83307635801587,
            21.02264001967716
          ],
          [
            105.83300494426845,
            21.02259964786208
          ],
          [
            105.83291676658337,
            21.022651286227468
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 70 Văn Chương",
        "diemDau": "22-2",
        "diemCuoi": "22-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.02264001967716,
        "to_latitude": 21.022651286227468,
        "to_longitude": 105.83291676658337,
        "from_longitude": 105.83307635801587,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5741,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83289631480098,
          21.023154524579983,
          105.83296102302907,
          21.023328529488673
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83292615435539,
            21.023154524579983
          ],
          [
            105.83289631480098,
            21.02322932167565
          ],
          [
            105.83296102302907,
            21.023328529488673
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 34+27 Văn Chương",
        "diemDau": "23",
        "diemCuoi": "23-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.023154524579983,
        "to_latitude": 21.023328529488673,
        "to_longitude": 105.83296102302907,
        "from_longitude": 105.83292615435539,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5742,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83294727677503,
          21.023328529488673,
          105.83303612488179,
          21.02354259280005
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83296102302907,
            21.023328529488673
          ],
          [
            105.83294727677503,
            21.023404578327284
          ],
          [
            105.83303612488179,
            21.02354259280005
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 34+27 Văn Chương",
        "diemDau": "23-2",
        "diemCuoi": "23-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.023328529488673,
        "to_latitude": 21.02354259280005,
        "to_longitude": 105.83303612488179,
        "from_longitude": 105.83296102302907,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5743,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83270353096907,
          21.023028089234867,
          105.83292615435539,
          21.02319427032044
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83292615435539,
            21.023154524579983
          ],
          [
            105.83284669393979,
            21.02319427032044
          ],
          [
            105.83270353096907,
            21.023028089234867
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 34+27 Văn Chương",
        "diemDau": "23",
        "diemCuoi": "23-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.023154524579983,
        "to_latitude": 21.023028089234867,
        "to_longitude": 105.83270353096907,
        "from_longitude": 105.83292615435539,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5744,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83358061331391,
          21.02250482101222,
          105.83373215812321,
          21.022567413001042
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83358061331391,
            21.022567413001042
          ],
          [
            105.83373215812321,
            21.02250482101222
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VC4",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 92 Văn Chương",
        "diemDau": "21-3",
        "diemCuoi": "VC4 21-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VC4",
        "from_latitude": 21.022567413001042,
        "to_latitude": 21.02250482101222,
        "to_longitude": 105.83373215812321,
        "from_longitude": 105.83358061331391,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5745,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80552216142759,
          21.01707302067768,
          105.80574612588184,
          21.017240773260532
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80574612588184,
            21.01713811871743
          ],
          [
            105.80564822525434,
            21.01707302067768
          ],
          [
            105.80552216142759,
            21.017240773260532
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "diemDau": "17-1",
        "diemCuoi": "17-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01713811871743,
        "to_latitude": 21.017240773260532,
        "to_longitude": 105.80552216142759,
        "from_longitude": 105.80574612588184,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5746,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80558636681471,
          21.01713811871743,
          105.80574612588184,
          21.017309783363594
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80574612588184,
            21.01713811871743
          ],
          [
            105.80558636681471,
            21.017309783363594
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "diemDau": "17-1",
        "diemCuoi": "17-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01713811871743,
        "to_latitude": 21.017309783363594,
        "to_longitude": 105.80558636681471,
        "from_longitude": 105.80574612588184,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5747,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8054133643345,
          21.017309783363594,
          105.80558636681471,
          21.017513840260605
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80558636681471,
            21.017309783363594
          ],
          [
            105.8054133643345,
            21.017513840260605
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "diemDau": "17-3",
        "diemCuoi": "17-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.017309783363594,
        "to_latitude": 21.017513840260605,
        "to_longitude": 105.8054133643345,
        "from_longitude": 105.80558636681471,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5748,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80532887474571,
          21.017460009327444,
          105.8054133643345,
          21.017513840260605
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8054133643345,
            21.017513840260605
          ],
          [
            105.80532887474571,
            21.017460009327444
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "diemDau": "17-4",
        "diemCuoi": "17-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.017513840260605,
        "to_latitude": 21.017460009327444,
        "to_longitude": 105.80532887474571,
        "from_longitude": 105.8054133643345,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5749,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82609389741022,
          21.027804391647674,
          105.82646404224928,
          21.028042232611146
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82609389741022,
            21.027804391647674
          ],
          [
            105.82646404224928,
            21.028042232611146
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 53,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29/16 Vũ Thạch",
        "diemDau": "6-8",
        "diemCuoi": "6-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.027804391647674,
        "to_latitude": 21.028042232611146,
        "to_longitude": 105.82646404224928,
        "from_longitude": 105.82609389741022,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5750,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82646404224928,
          21.028042232611146,
          105.8266491146733,
          21.028139872475368
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82646404224928,
            21.028042232611146
          ],
          [
            105.82655881375717,
            21.028139872475368
          ],
          [
            105.8266491146733,
            21.028054750545977
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29/16 Vũ Thạch",
        "diemDau": "6-9",
        "diemCuoi": "6-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.028042232611146,
        "to_latitude": 21.028054750545977,
        "to_longitude": 105.8266491146733,
        "from_longitude": 105.82646404224928,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5751,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8266491146733,
          21.028050995165728,
          105.82704876381553,
          21.02817492266765
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8266491146733,
            21.028054750545977
          ],
          [
            105.82682703466612,
            21.02817492266765
          ],
          [
            105.82704876381553,
            21.028050995165728
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29/16 Vũ Thạch",
        "diemDau": "6-10",
        "diemCuoi": "6-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.028054750545977,
        "to_latitude": 21.028050995165728,
        "to_longitude": 105.82704876381553,
        "from_longitude": 105.8266491146733,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5752,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82693476993258,
          21.027958362419646,
          105.82704876381553,
          21.028050995165728
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82704876381553,
            21.028050995165728
          ],
          [
            105.82693476993258,
            21.027958362419646
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29/16 Vũ Thạch",
        "diemDau": "6-11",
        "diemCuoi": "6-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.028050995165728,
        "to_latitude": 21.027958362419646,
        "to_longitude": 105.82693476993258,
        "from_longitude": 105.82704876381553,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5753,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8264506312016,
          21.026218358257637,
          105.82655121406377,
          21.026479986079273
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82655121406377,
            21.026479986079273
          ],
          [
            105.8264506312016,
            21.026218358257637
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8 Vũ Thạch",
        "diemDau": "9",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026479986079273,
        "to_latitude": 21.026218358257637,
        "to_longitude": 105.8264506312016,
        "from_longitude": 105.82655121406377,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5754,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82635407167795,
          21.02598552162727,
          105.8264506312016,
          21.026218358257637
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8264506312016,
            21.026218358257637
          ],
          [
            105.82635407167795,
            21.02598552162727
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8 Vũ Thạch",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026218358257637,
        "to_latitude": 21.02598552162727,
        "to_longitude": 105.82635407167795,
        "from_longitude": 105.8264506312016,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5755,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8262360544834,
          21.025727648375817,
          105.82635407167795,
          21.02598552162727
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82635407167795,
            21.02598552162727
          ],
          [
            105.8262360544834,
            21.025727648375817
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8 Vũ Thạch",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02598552162727,
        "to_latitude": 21.025727648375817,
        "to_longitude": 105.8262360544834,
        "from_longitude": 105.82635407167795,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5756,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82614217716753,
          21.025517343646335,
          105.8262360544834,
          21.025727648375817
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8262360544834,
            21.025727648375817
          ],
          [
            105.82614217716753,
            21.025517343646335
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8B Vũ Thạch",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.025727648375817,
        "to_latitude": 21.025517343646335,
        "to_longitude": 105.82614217716753,
        "from_longitude": 105.8262360544834,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5757,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8264720888725,
          21.02576520276034,
          105.82662765700239,
          21.026158271420165
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82662765700239,
            21.026158271420165
          ],
          [
            105.8264720888725,
            21.02576520276034
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8B Vũ Thạch",
        "diemDau": "10-3",
        "diemCuoi": "10-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026158271420165,
        "to_latitude": 21.02576520276034,
        "to_longitude": 105.8264720888725,
        "from_longitude": 105.82662765700239,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5758,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8263165207516,
          21.02543472384978,
          105.8264720888725,
          21.02576520276034
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8264720888725,
            21.02576520276034
          ],
          [
            105.8263165207516,
            21.02543472384978
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8B Vũ Thạch",
        "diemDau": "10-4",
        "diemCuoi": "10-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02576520276034,
        "to_latitude": 21.02543472384978,
        "to_longitude": 105.8263165207516,
        "from_longitude": 105.8264720888725,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5759,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82677786070781,
          21.02619332207845,
          105.82685028035277,
          21.026363568013885
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82685028035277,
            21.026363568013885
          ],
          [
            105.82677786070781,
            21.02619332207845
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8B Vũ Thạch",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026363568013885,
        "to_latitude": 21.02619332207845,
        "to_longitude": 105.82677786070781,
        "from_longitude": 105.82685028035277,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5760,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8266491146733,
          21.025840311501888,
          105.82677786070781,
          21.02619332207845
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82677786070781,
            21.02619332207845
          ],
          [
            105.8266491146733,
            21.025840311501888
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8B Vũ Thạch",
        "diemDau": "10-1",
        "diemCuoi": "10-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02619332207845,
        "to_latitude": 21.025840311501888,
        "to_longitude": 105.8266491146733,
        "from_longitude": 105.82677786070781,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5761,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82662765700239,
          21.026158271420165,
          105.82677786070781,
          21.02619332207845
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82677786070781,
            21.02619332207845
          ],
          [
            105.82662765700239,
            21.026158271420165
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8C Vũ Thạch",
        "diemDau": "10-1",
        "diemCuoi": "10-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02619332207845,
        "to_latitude": 21.026158271420165,
        "to_longitude": 105.82662765700239,
        "from_longitude": 105.82677786070781,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5762,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.827074244825,
          21.02609818455846,
          105.8271024079973,
          21.026274689645042
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.827074244825,
            21.026274689645042
          ],
          [
            105.8271024079973,
            21.02609818455846
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8C Vũ Thạch",
        "diemDau": "10",
        "diemCuoi": "10-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.026274689645042,
        "to_latitude": 21.02609818455846,
        "to_longitude": 105.8271024079973,
        "from_longitude": 105.827074244825,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5763,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82698170858605,
          21.02576269913533,
          105.8271024079973,
          21.02609818455846
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8271024079973,
            21.02609818455846
          ],
          [
            105.82698170858605,
            21.02576269913533
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8C Vũ Thạch",
        "diemDau": "10-7",
        "diemCuoi": "10-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02609818455846,
        "to_latitude": 21.02576269913533,
        "to_longitude": 105.82698170858605,
        "from_longitude": 105.8271024079973,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5764,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8260456176439,
          21.025251958683445,
          105.82614217716753,
          21.025517343646335
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82614217716753,
            21.025517343646335
          ],
          [
            105.8260456176439,
            21.025251958683445
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127 Hào Nam",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.025517343646335,
        "to_latitude": 21.025251958683445,
        "to_longitude": 105.8260456176439,
        "from_longitude": 105.82614217716753,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5765,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8260456176439,
          21.025101740570424,
          105.82627360540975,
          21.025251958683445
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8260456176439,
            21.025251958683445
          ],
          [
            105.82627360540975,
            21.025101740570424
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127 Hào Nam",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.025251958683445,
        "to_latitude": 21.025101740570424,
        "to_longitude": 105.82627360540975,
        "from_longitude": 105.8260456176439,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5766,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82627360540975,
          21.025101740570424,
          105.8265123220156,
          21.025234433244663
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82627360540975,
            21.025101740570424
          ],
          [
            105.82651053399987,
            21.025170590558563
          ],
          [
            105.8265123220156,
            21.025234433244663
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127 Hào Nam",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.025101740570424,
        "to_latitude": 21.025234433244663,
        "to_longitude": 105.8265123220156,
        "from_longitude": 105.82627360540975,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5767,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8265123220156,
          21.02516683510479,
          105.82681272941744,
          21.025234433244663
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8265123220156,
            21.025234433244663
          ],
          [
            105.82681272941744,
            21.02516683510479
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127 Hào Nam",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.025234433244663,
        "to_latitude": 21.02516683510479,
        "to_longitude": 105.82681272941744,
        "from_longitude": 105.8265123220156,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5768,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82582567650124,
          21.025517343646335,
          105.82614217716753,
          21.025592452512758
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82614217716753,
            21.025517343646335
          ],
          [
            105.82582567650124,
            21.025592452512758
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8B Vũ Thạch",
        "diemDau": "14",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.025517343646335,
        "to_latitude": 21.025592452512758,
        "to_longitude": 105.82582567650124,
        "from_longitude": 105.82614217716753,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5769,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82572911697763,
          21.025437227481135,
          105.82582567650124,
          21.025592452512758
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82582567650124,
            21.025592452512758
          ],
          [
            105.82572911697763,
            21.025437227481135
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8B Vũ Thạch",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.025592452512758,
        "to_latitude": 21.025437227481135,
        "to_longitude": 105.82572911697763,
        "from_longitude": 105.82582567650124,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5770,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82547430712535,
          21.025437227481135,
          105.82572911697763,
          21.025474781938854
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82572911697763,
            21.025437227481135
          ],
          [
            105.82547430712535,
            21.025474781938854
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127/28 Hào Nam",
        "diemDau": "21",
        "diemCuoi": "21-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.025437227481135,
        "to_latitude": 21.025474781938854,
        "to_longitude": 105.82547430712535,
        "from_longitude": 105.82572911697763,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5771,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82535092550634,
          21.025474781938854,
          105.82547430712535,
          21.02563751781378
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82547430712535,
            21.025474781938854
          ],
          [
            105.82535092550634,
            21.02563751781378
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127/28 Hào Nam",
        "diemDau": "21",
        "diemCuoi": "21-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.025474781938854,
        "to_latitude": 21.02563751781378,
        "to_longitude": 105.82535092550634,
        "from_longitude": 105.82547430712535,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5772,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82531069237224,
          21.025430968404358,
          105.82547430712535,
          21.025492307349406
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82547430712535,
            21.025474781938854
          ],
          [
            105.82537327737825,
            21.025430968404358
          ],
          [
            105.82531069237224,
            21.025492307349406
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127/28 Hào Nam",
        "diemDau": "21",
        "diemCuoi": "21-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.025474781938854,
        "to_latitude": 21.025492307349406,
        "to_longitude": 105.82531069237224,
        "from_longitude": 105.82547430712535,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5773,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82559232431993,
          21.02505166783188,
          105.82572911697763,
          21.025437227481135
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82572911697763,
            21.025437227481135
          ],
          [
            105.82559232431993,
            21.02505166783188
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127 Hào Nam",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.025437227481135,
        "to_latitude": 21.02505166783188,
        "to_longitude": 105.82559232431993,
        "from_longitude": 105.82572911697763,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5774,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82542602735906,
          21.02505166783188,
          105.82559232431993,
          21.0251643314689
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82559232431993,
            21.02505166783188
          ],
          [
            105.82542602735906,
            21.0251643314689
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127/38 Hào Nam",
        "diemDau": "22",
        "diemCuoi": "22-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02505166783188,
        "to_latitude": 21.0251643314689,
        "to_longitude": 105.82542602735906,
        "from_longitude": 105.82559232431993,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5775,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8251443954114,
          21.0251643314689,
          105.82542602735906,
          21.025299527721074
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82542602735906,
            21.0251643314689
          ],
          [
            105.8251443954114,
            21.025299527721074
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127/38 Hào Nam",
        "diemDau": "22-1",
        "diemCuoi": "22-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.0251643314689,
        "to_latitude": 21.025299527721074,
        "to_longitude": 105.8251443954114,
        "from_longitude": 105.82542602735906,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5776,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8249485941564,
          21.025299527721074,
          105.8251443954114,
          21.025502321869006
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8251443954114,
            21.025299527721074
          ],
          [
            105.8249485941564,
            21.025502321869006
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127/38 Hào Nam",
        "diemDau": "22-2",
        "diemCuoi": "22-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.025299527721074,
        "to_latitude": 21.025502321869006,
        "to_longitude": 105.8249485941564,
        "from_longitude": 105.8251443954114,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5777,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82498882729048,
          21.02518436055154,
          105.8251443954114,
          21.025299527721074
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8251443954114,
            21.025299527721074
          ],
          [
            105.82498882729048,
            21.02518436055154
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127/38 Hào Nam",
        "diemDau": "22-2",
        "diemCuoi": "22-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.025299527721074,
        "to_latitude": 21.02518436055154,
        "to_longitude": 105.82498882729048,
        "from_longitude": 105.8251443954114,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5778,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82483594137733,
          21.025069193292175,
          105.82498882729048,
          21.02518436055154
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82498882729048,
            21.02518436055154
          ],
          [
            105.82483594137733,
            21.025069193292175
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127/38 Hào Nam",
        "diemDau": "22-4",
        "diemCuoi": "22-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02518436055154,
        "to_latitude": 21.025069193292175,
        "to_longitude": 105.82483594137733,
        "from_longitude": 105.82498882729048,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5779,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82549308258854,
          21.024643574391003,
          105.82558695990441,
          21.02485388035323
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82558695990441,
            21.02485388035323
          ],
          [
            105.82549308258854,
            21.024643574391003
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127 Hào Nam",
        "diemDau": "23-2",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02485388035323,
        "to_latitude": 21.024643574391003,
        "to_longitude": 105.82549308258854,
        "from_longitude": 105.82558695990441,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5780,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82523424941569,
          21.02464107074632,
          105.82549308258854,
          21.024643574391003
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82549308258854,
            21.024643574391003
          ],
          [
            105.82523424941569,
            21.02464107074632
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127/56  Hào Nam",
        "diemDau": "24",
        "diemCuoi": "24-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.024643574391003,
        "to_latitude": 21.02464107074632,
        "to_longitude": 105.82523424941569,
        "from_longitude": 105.82549308258854,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5781,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82523424941569,
          21.02464107074632,
          105.82538177091334,
          21.024817577559116
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82523424941569,
            21.02464107074632
          ],
          [
            105.82538177091334,
            21.024817577559116
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127/56  Hào Nam",
        "diemDau": "24-2",
        "diemCuoi": "24-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02464107074632,
        "to_latitude": 21.024817577559116,
        "to_longitude": 105.82538177091334,
        "from_longitude": 105.82523424941569,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5782,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82515244204359,
          21.024437023603497,
          105.82523424941569,
          21.02464107074632
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82523424941569,
            21.02464107074632
          ],
          [
            105.82515244204359,
            21.024437023603497
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127/56  Hào Nam",
        "diemDau": "24-2",
        "diemCuoi": "24-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02464107074632,
        "to_latitude": 21.024437023603497,
        "to_longitude": 105.82515244204359,
        "from_longitude": 105.82523424941569,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5783,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82549308258854,
          21.02448459290119,
          105.8255172224672,
          21.024643574391003
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82549308258854,
            21.024643574391003
          ],
          [
            105.8255172224672,
            21.02448459290119
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127 Hào Nam",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.024643574391003,
        "to_latitude": 21.02448459290119,
        "to_longitude": 105.8255172224672,
        "from_longitude": 105.82549308258854,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5784,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82544882613382,
          21.02424674626096,
          105.82552348108369,
          21.02443451995619
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82552348108369,
            21.02443451995619
          ],
          [
            105.82544882613382,
            21.02424674626096
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127 Hào Nam",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02443451995619,
        "to_latitude": 21.02424674626096,
        "to_longitude": 105.82544882613382,
        "from_longitude": 105.82552348108369,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5785,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82536299545018,
          21.024060224157264,
          105.82544882613382,
          21.02424674626096
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82544882613382,
            21.02424674626096
          ],
          [
            105.82536299545018,
            21.024060224157264
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127 Hào Nam",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02424674626096,
        "to_latitude": 21.024060224157264,
        "to_longitude": 105.82536299545018,
        "from_longitude": 105.82544882613382,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5786,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82526107150207,
          21.023872449990584,
          105.82536299545018,
          21.024060224157264
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82536299545018,
            21.024060224157264
          ],
          [
            105.82526107150207,
            21.023872449990584
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127 Hào Nam",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.024060224157264,
        "to_latitude": 21.023872449990584,
        "to_longitude": 105.82526107150207,
        "from_longitude": 105.82536299545018,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5787,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82498480397888,
          21.023872449990584,
          105.82526107150207,
          21.02389373107458
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82526107150207,
            21.023872449990584
          ],
          [
            105.82498480397888,
            21.02389373107458
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127 Hào Nam",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.023872449990584,
        "to_latitude": 21.02389373107458,
        "to_longitude": 105.82498480397888,
        "from_longitude": 105.82526107150207,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5788,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82544882613382,
          21.02424674626096,
          105.82562048751016,
          21.024286804669245
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82544882613382,
            21.02424674626096
          ],
          [
            105.82562048751016,
            21.024286804669245
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127/123 Hào Nam",
        "diemDau": "26",
        "diemCuoi": "26-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02424674626096,
        "to_latitude": 21.024286804669245,
        "to_longitude": 105.82562048751016,
        "from_longitude": 105.82544882613382,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5789,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82562048751016,
          21.02427178276711,
          105.82585115749276,
          21.024286804669245
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82562048751016,
            21.024286804669245
          ],
          [
            105.82585115749276,
            21.02427178276711
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127/123 Hào Nam",
        "diemDau": "26-1",
        "diemCuoi": "26-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.024286804669245,
        "to_latitude": 21.02427178276711,
        "to_longitude": 105.82585115749276,
        "from_longitude": 105.82562048751016,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5790,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82685966807986,
          21.027885758335618,
          105.82693476993258,
          21.027958362419646
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82693476993258,
            21.027958362419646
          ],
          [
            105.82685966807986,
            21.027885758335618
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 29/16 Vũ Thạch",
        "diemDau": "6-12",
        "diemCuoi": "6-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.027958362419646,
        "to_latitude": 21.027885758335618,
        "to_longitude": 105.82685966807986,
        "from_longitude": 105.82693476993258,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5791,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80565073982747,
          21.017257047754637,
          105.80588023633182,
          21.017518847788462
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80588023633182,
            21.017257047754637
          ],
          [
            105.80565073982747,
            21.017518847788462
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.017257047754637,
        "to_latitude": 21.017518847788462,
        "to_longitude": 105.80565073982747,
        "from_longitude": 105.80588023633182,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5792,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80543616310929,
          21.017518847788462,
          105.80565073982747,
          21.01775420140125
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80565073982747,
            21.017518847788462
          ],
          [
            105.80543616310929,
            21.01775420140125
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.017518847788462,
        "to_latitude": 21.01775420140125,
        "to_longitude": 105.80543616310929,
        "from_longitude": 105.80565073982747,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5793,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80565073982747,
          21.017518847788462,
          105.80585190550693,
          21.017644035926832
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80565073982747,
            21.017518847788462
          ],
          [
            105.80585190550693,
            21.017644035926832
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "diemDau": "18",
        "diemCuoi": "18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.017518847788462,
        "to_latitude": 21.017644035926832,
        "to_longitude": 105.80585190550693,
        "from_longitude": 105.80565073982747,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5794,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80585190550693,
          21.017644035926832,
          105.80594980614342,
          21.01784684048792
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80585190550693,
            21.017644035926832
          ],
          [
            105.80594980614342,
            21.017709133717304
          ],
          [
            105.80586531655463,
            21.01784684048792
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "diemDau": "18-1",
        "diemCuoi": "18-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.017644035926832,
        "to_latitude": 21.01784684048792,
        "to_longitude": 105.80586531655463,
        "from_longitude": 105.80585190550693,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5795,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82844083028938,
          21.006916719991096,
          105.82861517388244,
          21.007252247811625
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82861517388244,
            21.007252247811625
          ],
          [
            105.82844083028938,
            21.006916719991096
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 95 Chùa Bộc",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007252247811625,
        "to_latitude": 21.006916719991096,
        "to_longitude": 105.82844083028938,
        "from_longitude": 105.82861517388244,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5796,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82826112228983,
          21.006568671677687,
          105.82844083028938,
          21.006916719991096
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82844083028938,
            21.006916719991096
          ],
          [
            105.82826112228983,
            21.006568671677687
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 95 Chùa Bộc",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.006916719991096,
        "to_latitude": 21.006568671677687,
        "to_longitude": 105.82826112228983,
        "from_longitude": 105.82844083028938,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5797,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82811091858443,
          21.00608290504843,
          105.82826112228983,
          21.006568671677687
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82826112228983,
            21.006568671677687
          ],
          [
            105.82818602043712,
            21.006228134412403
          ],
          [
            105.82811091858443,
            21.00608290504843
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 95 Chùa Bộc",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.006568671677687,
        "to_latitude": 21.00608290504843,
        "to_longitude": 105.82811091858443,
        "from_longitude": 105.82826112228983,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5798,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8279741259267,
          21.00578994193526,
          105.82811091858443,
          21.00608290504843
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82811091858443,
            21.00608290504843
          ],
          [
            105.8279741259267,
            21.00578994193526
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 95 Chùa Bộc",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.00608290504843,
        "to_latitude": 21.00578994193526,
        "to_longitude": 105.8279741259267,
        "from_longitude": 105.82811091858443,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5799,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82783465105223,
          21.00552201790069,
          105.8279741259267,
          21.00578994193526
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8279741259267,
            21.00578994193526
          ],
          [
            105.82783465105223,
            21.00552201790069
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 95 Chùa Bộc",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.00578994193526,
        "to_latitude": 21.00552201790069,
        "to_longitude": 105.82783465105223,
        "from_longitude": 105.8279741259267,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5800,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8058264245334,
          21.017257047754637,
          105.80590823190545,
          21.017417445319783
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80588023633182,
            21.017257047754637
          ],
          [
            105.8058264245334,
            21.017338576684804
          ],
          [
            105.80590823190545,
            21.017417445319783
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "diemDau": "17",
        "diemCuoi": "17-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.017257047754637,
        "to_latitude": 21.017417445319783,
        "to_longitude": 105.80590823190545,
        "from_longitude": 105.80588023633182,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5801,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80590823190545,
          21.017417445319783,
          105.80610403316943,
          21.017517595906302
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80590823190545,
            21.017417445319783
          ],
          [
            105.80610403316943,
            21.017517595906302
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "diemDau": "17-6",
        "diemCuoi": "17-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.017417445319783,
        "to_latitude": 21.017517595906302,
        "to_longitude": 105.80610403316943,
        "from_longitude": 105.80590823190545,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5802,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82617579448804,
          21.024806311173357,
          105.82630722273028,
          21.025115510569773
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82627360540975,
            21.025101740570424
          ],
          [
            105.82619993437568,
            21.025115510569773
          ],
          [
            105.82617579448804,
            21.02497530687426
          ],
          [
            105.82630722273028,
            21.024932745012034
          ],
          [
            105.82630042749615,
            21.024806311173357
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 67,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127 Hào Nam",
        "diemDau": "16",
        "diemCuoi": "16-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.025101740570424,
        "to_latitude": 21.024806311173357,
        "to_longitude": 105.82630042749615,
        "from_longitude": 105.82627360540975,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5803,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82625482994659,
          21.02454843588191,
          105.82630042749615,
          21.024806311173357
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82630042749615,
            21.024806311173357
          ],
          [
            105.82625482994659,
            21.02454843588191
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127  Hào Nam",
        "diemDau": "16-1",
        "diemCuoi": "16-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.024806311173357,
        "to_latitude": 21.02454843588191,
        "to_longitude": 105.82625482994659,
        "from_longitude": 105.82630042749615,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5804,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82543014038541,
          21.02448459290119,
          105.8255172224672,
          21.024579731450153
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8255172224672,
            21.02448459290119
          ],
          [
            105.82543014038541,
            21.024579731450153
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127/66 Hào Nam",
        "diemDau": "25",
        "diemCuoi": "25-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02448459290119,
        "to_latitude": 21.024579731450153,
        "to_longitude": 105.82543014038541,
        "from_longitude": 105.8255172224672,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5805,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8253818606191,
          21.024401972531386,
          105.8255172224672,
          21.02448459290119
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8255172224672,
            21.02448459290119
          ],
          [
            105.8254489158486,
            21.024401972531386
          ],
          [
            105.8253818606191,
            21.024419498068053
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127/66 Hào Nam",
        "diemDau": "25",
        "diemCuoi": "25-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02448459290119,
        "to_latitude": 21.024419498068053,
        "to_longitude": 105.8253818606191,
        "from_longitude": 105.8255172224672,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5806,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82558695990441,
          21.02485388035323,
          105.82573859441948,
          21.02487766493704
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82558695990441,
            21.02485388035323
          ],
          [
            105.82573859441948,
            21.02487766493704
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127/66 Hào Nam",
        "diemDau": "23-2",
        "diemCuoi": "23-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02485388035323,
        "to_latitude": 21.02487766493704,
        "to_longitude": 105.82573859441948,
        "from_longitude": 105.82558695990441,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5807,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8052450557403,
          21.01695956849742,
          105.80538453060576,
          21.017049704305578
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8052450557403,
            21.01695956849742
          ],
          [
            105.80538453060576,
            21.017049704305578
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "diemDau": "13",
        "diemCuoi": "13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01695956849742,
        "to_latitude": 21.017049704305578,
        "to_longitude": 105.80538453060576,
        "from_longitude": 105.8052450557403,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5808,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80503852564536,
          21.01695956849742,
          105.8052450557403,
          21.017196174877732
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8052450557403,
            21.01695956849742
          ],
          [
            105.80503852564536,
            21.017196174877732
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01695956849742,
        "to_latitude": 21.017196174877732,
        "to_longitude": 105.80503852564536,
        "from_longitude": 105.8052450557403,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5809,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80484272438137,
          21.017196174877732,
          105.80503852564536,
          21.01743152899976
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80503852564536,
            21.017196174877732
          ],
          [
            105.80484272438137,
            21.01743152899976
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.017196174877732,
        "to_latitude": 21.01743152899976,
        "to_longitude": 105.80484272438137,
        "from_longitude": 105.80503852564536,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5810,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8044093799449,
          21.01770819480625,
          105.8045838912085,
          21.017918354067277
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8044093799449,
            21.017918354067277
          ],
          [
            105.8045838912085,
            21.01770819480625
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "diemDau": "10",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.017918354067277,
        "to_latitude": 21.01770819480625,
        "to_longitude": 105.8045838912085,
        "from_longitude": 105.8044093799449,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5811,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8045838912085,
          21.01743152899976,
          105.80484272438137,
          21.01770819480625
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8045838912085,
            21.01770819480625
          ],
          [
            105.80484272438137,
            21.01743152899976
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "diemDau": "",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01770819480625,
        "to_latitude": 21.01743152899976,
        "to_longitude": 105.80484272438137,
        "from_longitude": 105.8045838912085,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5812,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8045838912085,
          21.01757048790761,
          105.80506132441114,
          21.017787063245756
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8045838912085,
            21.01770819480625
          ],
          [
            105.8047354360178,
            21.01757048790761
          ],
          [
            105.80506132441114,
            21.017787063245756
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "diemDau": "",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01770819480625,
        "to_latitude": 21.017787063245756,
        "to_longitude": 105.80506132441114,
        "from_longitude": 105.8045838912085,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5813,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83673727094529,
          21.01402586730757,
          105.83708581327733,
          21.01423912217351
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83673727094529,
            21.01423912217351
          ],
          [
            105.83708581327733,
            21.01402586730757
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "05",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01423912217351,
        "to_latitude": 21.01402586730757,
        "to_longitude": 105.83708581327733,
        "from_longitude": 105.83673727094529,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5814,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83708581327733,
          21.013973101076704,
          105.83727490901298,
          21.014164829389497
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83708581327733,
            21.01402586730757
          ],
          [
            105.83715451024985,
            21.013973101076704
          ],
          [
            105.83727490901298,
            21.014164829389497
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "5-1",
        "diemCuoi": "5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01402586730757,
        "to_latitude": 21.014164829389497,
        "to_longitude": 105.83727490901298,
        "from_longitude": 105.83708581327733,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5815,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83708581327733,
          21.01377798867854,
          105.83743047714285,
          21.01402586730757
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83708581327733,
            21.01402586730757
          ],
          [
            105.83743047714285,
            21.01377798867854
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "5-1",
        "diemCuoi": "5-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01402586730757,
        "to_latitude": 21.01377798867854,
        "to_longitude": 105.83743047714285,
        "from_longitude": 105.83708581327733,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5816,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83726991066453,
          21.013586486561557,
          105.83743047714285,
          21.01377798867854
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83743047714285,
            21.01377798867854
          ],
          [
            105.83726991066453,
            21.013586486561557
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "5-3",
        "diemCuoi": "5-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01377798867854,
        "to_latitude": 21.013586486561557,
        "to_longitude": 105.83726991066453,
        "from_longitude": 105.83743047714285,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5817,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83713686733714,
          21.013417590545373,
          105.83726991066453,
          21.013586486561557
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83726991066453,
            21.013586486561557
          ],
          [
            105.83713686733714,
            21.013417590545373
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "5-4",
        "diemCuoi": "5-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.013586486561557,
        "to_latitude": 21.013417590545373,
        "to_longitude": 105.83713686733714,
        "from_longitude": 105.83726991066453,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5818,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83743047714285,
          21.013485040676336,
          105.83769899868201,
          21.01377798867854
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83743047714285,
            21.01377798867854
          ],
          [
            105.83769899868201,
            21.013559969652785
          ],
          [
            105.83763566612495,
            21.013485040676336
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "5-3",
        "diemCuoi": "5-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01377798867854,
        "to_latitude": 21.013485040676336,
        "to_longitude": 105.83763566612495,
        "from_longitude": 105.83743047714285,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5819,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.837450540553,
          21.01327975143078,
          105.83763566612495,
          21.013485040676336
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83763566612495,
            21.013485040676336
          ],
          [
            105.837450540553,
            21.01327975143078
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "5-7",
        "diemCuoi": "5-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.013485040676336,
        "to_latitude": 21.01327975143078,
        "to_longitude": 105.837450540553,
        "from_longitude": 105.83763566612495,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5820,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83744805567277,
          21.013182934079587,
          105.83753653506146,
          21.01327975143078
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.837450540553,
            21.01327975143078
          ],
          [
            105.83744805567277,
            21.013264830367152
          ],
          [
            105.83753653506146,
            21.013182934079587
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "5-6",
        "diemCuoi": "5-6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01327975143078,
        "to_latitude": 21.013182934079587,
        "to_longitude": 105.83753653506146,
        "from_longitude": 105.837450540553,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5821,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83763566612495,
          21.013366108631214,
          105.83784219621992,
          21.013485040676336
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83763566612495,
            21.013485040676336
          ],
          [
            105.83784219621992,
            21.013366108631214
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "5-7",
        "diemCuoi": "5-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.013485040676336,
        "to_latitude": 21.013366108631214,
        "to_longitude": 105.83784219621992,
        "from_longitude": 105.83763566612495,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5822,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83784219621992,
          21.01326219950323,
          105.83787989132587,
          21.013366108631214
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83784219621992,
            21.013366108631214
          ],
          [
            105.83787989132587,
            21.013337441571156
          ],
          [
            105.83784621954048,
            21.01326219950323
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "5-8",
        "diemCuoi": "5-8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.013366108631214,
        "to_latitude": 21.01326219950323,
        "to_longitude": 105.83784621954048,
        "from_longitude": 105.83784219621992,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5823,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83784219621992,
          21.013282889840582,
          105.83797686454656,
          21.013366108631214
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83784219621992,
            21.013366108631214
          ],
          [
            105.83797686454656,
            21.013282889840582
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "5-8",
        "diemCuoi": "5-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.013366108631214,
        "to_latitude": 21.013282889840582,
        "to_longitude": 105.83797686454656,
        "from_longitude": 105.83784219621992,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5824,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83784219621992,
          21.013366108631214,
          105.83797508632418,
          21.01355752005602
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83784219621992,
            21.013366108631214
          ],
          [
            105.83797508632418,
            21.01355752005602
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "5-8",
        "diemCuoi": "5-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.013366108631214,
        "to_latitude": 21.01355752005602,
        "to_longitude": 105.83797508632418,
        "from_longitude": 105.83784219621992,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5825,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83797508632418,
          21.01355752005602,
          105.83814591778795,
          21.013680295450605
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83797508632418,
            21.01355752005602
          ],
          [
            105.83814591778795,
            21.013680295450605
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "5-9",
        "diemCuoi": "5-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01355752005602,
        "to_latitude": 21.013680295450605,
        "to_longitude": 105.83814591778795,
        "from_longitude": 105.83797508632418,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5826,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83797686454656,
          21.01294796784484,
          105.83822709321953,
          21.013282889840582
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83797686454656,
            21.013282889840582
          ],
          [
            105.83822709321953,
            21.01294796784484
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "5-11",
        "diemCuoi": "5-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.013282889840582,
        "to_latitude": 21.01294796784484,
        "to_longitude": 105.83822709321953,
        "from_longitude": 105.83797686454656,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5827,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83822709321953,
          21.01294796784484,
          105.83839607238811,
          21.01311322123013
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83822709321953,
            21.01294796784484
          ],
          [
            105.83839607238811,
            21.01311322123013
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "5-12",
        "diemCuoi": "5-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01294796784484,
        "to_latitude": 21.01311322123013,
        "to_longitude": 105.83839607238811,
        "from_longitude": 105.83822709321953,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5828,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83839607238811,
          21.013022655008324,
          105.83852542978832,
          21.01311322123013
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83839607238811,
            21.01311322123013
          ],
          [
            105.83852542978832,
            21.013022655008324
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "5-13",
        "diemCuoi": "5-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01311322123013,
        "to_latitude": 21.013022655008324,
        "to_longitude": 105.83852542978832,
        "from_longitude": 105.83839607238811,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5829,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83852542978832,
          21.013022655008324,
          105.83863613008884,
          21.013133251930718
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83852542978832,
            21.013022655008324
          ],
          [
            105.83863613008884,
            21.013133251930718
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "5-14",
        "diemCuoi": "5-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.013022655008324,
        "to_latitude": 21.013133251930718,
        "to_longitude": 105.83863613008884,
        "from_longitude": 105.83852542978832,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5830,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83863613008884,
          21.013133251930718,
          105.83875414729233,
          21.013218509408343
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83863613008884,
            21.013133251930718
          ],
          [
            105.83870198838505,
            21.013218509408343
          ],
          [
            105.83875414729233,
            21.01315954222165
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "5-14-1",
        "diemCuoi": "5-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.013133251930718,
        "to_latitude": 21.01315954222165,
        "to_longitude": 105.83875414729233,
        "from_longitude": 105.83863613008884,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5831,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83875414729233,
          21.01315954222165,
          105.83900091052139,
          21.01340867379511
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83875414729233,
            21.01315954222165
          ],
          [
            105.83900091052139,
            21.01340867379511
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "5-15",
        "diemCuoi": "5-16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01315954222165,
        "to_latitude": 21.01340867379511,
        "to_longitude": 105.83900091052139,
        "from_longitude": 105.83875414729233,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5832,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83895799517057,
          21.01340867379511,
          105.83900091052139,
          21.013457498526535
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83900091052139,
            21.01340867379511
          ],
          [
            105.83895799517057,
            21.013457498526535
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "5-16",
        "diemCuoi": "5-17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01340867379511,
        "to_latitude": 21.013457498526535,
        "to_longitude": 105.83895799517057,
        "from_longitude": 105.83900091052139,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5833,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83895799517057,
          21.013457498526535,
          105.83913904428299,
          21.01362149943244
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83895799517057,
            21.013457498526535
          ],
          [
            105.83913904428299,
            21.01362149943244
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "5-17",
        "diemCuoi": "5-18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.013457498526535,
        "to_latitude": 21.01362149943244,
        "to_longitude": 105.83913904428299,
        "from_longitude": 105.83895799517057,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5834,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83913904428299,
          21.01362149943244,
          105.83925035595817,
          21.01374418701547
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83913904428299,
            21.01362149943244
          ],
          [
            105.83925035595817,
            21.01374418701547
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "5-18-1",
        "diemCuoi": "5-18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01362149943244,
        "to_latitude": 21.01374418701547,
        "to_longitude": 105.83925035595817,
        "from_longitude": 105.83913904428299,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5835,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83925035595817,
          21.01362177025463,
          105.8393982077653,
          21.01374418701547
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83925035595817,
            21.01374418701547
          ],
          [
            105.8393982077653,
            21.01362177025463
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "5-18",
        "diemCuoi": "5-19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01374418701547,
        "to_latitude": 21.01362177025463,
        "to_longitude": 105.8393982077653,
        "from_longitude": 105.83925035595817,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5836,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8393982077653,
          21.013602720712427,
          105.83945420384539,
          21.01362177025463
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8393982077653,
            21.01362177025463
          ],
          [
            105.83945420384539,
            21.013602720712427
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "diemDau": "5-19",
        "diemCuoi": "5-20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01362177025463,
        "to_latitude": 21.013602720712427,
        "to_longitude": 105.83945420384539,
        "from_longitude": 105.8393982077653,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5837,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.830131627792,
          21.011314515921182,
          105.83022818731563,
          21.011479771115265
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.830131627792,
            21.011314515921182
          ],
          [
            105.83022818731563,
            21.011479771115265
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTNĐ",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTNĐ",
        "from_latitude": 21.011314515921182,
        "to_latitude": 21.011479771115265,
        "to_longitude": 105.83022818731563,
        "from_longitude": 105.830131627792,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5838,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8044093799449,
          21.017786124336038,
          105.80455237525406,
          21.017918354067277
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8044093799449,
            21.017918354067277
          ],
          [
            105.80455237525406,
            21.017786124336038
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "diemDau": "10",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.017918354067277,
        "to_latitude": 21.017786124336038,
        "to_longitude": 105.80455237525406,
        "from_longitude": 105.8044093799449,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5839,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80455237525406,
          21.017786124336038,
          105.80468782680794,
          21.017872504007936
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80455237525406,
            21.017786124336038
          ],
          [
            105.80468782680794,
            21.017872504007936
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.017786124336038,
        "to_latitude": 21.017872504007936,
        "to_longitude": 105.80468782680794,
        "from_longitude": 105.80455237525406,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5840,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80468782680794,
          21.017872504007936,
          105.80493056672536,
          21.018027736915677
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80468782680794,
            21.017872504007936
          ],
          [
            105.80493056672536,
            21.018027736915677
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "diemDau": "21",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.017872504007936,
        "to_latitude": 21.018027736915677,
        "to_longitude": 105.80493056672536,
        "from_longitude": 105.80468782680794,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5841,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82765024918463,
          21.005154560559024,
          105.82782928662778,
          21.005517009970937
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82765024918463,
            21.005154560559024
          ],
          [
            105.82782928662778,
            21.005517009970937
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 95 Chùa Bộc",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.005154560559024,
        "to_latitude": 21.005517009970937,
        "to_longitude": 105.82782928662778,
        "from_longitude": 105.82765024918463,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5842,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82559232431993,
          21.02492273045415,
          105.82560180176175,
          21.02505166783188
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82559232431993,
            21.02505166783188
          ],
          [
            105.82560180176175,
            21.02492273045415
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127  Hào Nam",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02505166783188,
        "to_latitude": 21.02492273045415,
        "to_longitude": 105.82560180176175,
        "from_longitude": 105.82559232431993,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5843,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82558695990441,
          21.02485388035323,
          105.82560180176175,
          21.02492273045415
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82560180176175,
            21.02492273045415
          ],
          [
            105.82558695990441,
            21.02485388035323
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LB3",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127  Hào Nam",
        "diemDau": "23",
        "diemCuoi": "23-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Long Biên 3",
        "from_latitude": 21.02492273045415,
        "to_latitude": 21.02485388035323,
        "to_longitude": 105.82558695990441,
        "from_longitude": 105.82560180176175,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5844,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80477633970831,
          21.018027736915677,
          105.80493056672536,
          21.01821051093811
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80493056672536,
            21.018027736915677
          ],
          [
            105.80477633970831,
            21.01821051093811
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "diemDau": "22",
        "diemCuoi": "22-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.018027736915677,
        "to_latitude": 21.01821051093811,
        "to_longitude": 105.80477633970831,
        "from_longitude": 105.80493056672536,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5845,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80493056672536,
          21.0179688986546,
          105.80515587228346,
          21.018027736915677
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80493056672536,
            21.018027736915677
          ],
          [
            105.80503517288118,
            21.0179688986546
          ],
          [
            105.80515587228346,
            21.01801772189398
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.018027736915677,
        "to_latitude": 21.01801772189398,
        "to_longitude": 105.80515587228346,
        "from_longitude": 105.80493056672536,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5846,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.805119662461,
          21.01801772189398,
          105.8052081753614,
          21.01819048091859
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80515587228346,
            21.01801772189398
          ],
          [
            105.8052081753614,
            21.018074056379998
          ],
          [
            105.805119662461,
            21.01819048091859
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "diemDau": "23",
        "diemCuoi": "23-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01801772189398,
        "to_latitude": 21.01819048091859,
        "to_longitude": 105.805119662461,
        "from_longitude": 105.80515587228346,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5847,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80515587228346,
          21.01801772189398,
          105.80545762079818,
          21.01817796215543
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80515587228346,
            21.01801772189398
          ],
          [
            105.80545762079818,
            21.01817796215543
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01801772189398,
        "to_latitude": 21.01817796215543,
        "to_longitude": 105.80545762079818,
        "from_longitude": 105.80515587228346,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5848,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80545762079818,
          21.01817796215543,
          105.80568728495513,
          21.018257299800275
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80545762079818,
            21.01817796215543
          ],
          [
            105.80568728495513,
            21.018257299800275
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01817796215543,
        "to_latitude": 21.018257299800275,
        "to_longitude": 105.80568728495513,
        "from_longitude": 105.80545762079818,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5849,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80545762079818,
          21.018082037098246,
          105.80568728495513,
          21.018198461630632
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80545762079818,
            21.01817796215543
          ],
          [
            105.80560547758304,
            21.018198461630632
          ],
          [
            105.80566180397258,
            21.018089548360937
          ],
          [
            105.80568728495513,
            21.018082037098246
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "diemDau": "24",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.01817796215543,
        "to_latitude": 21.018082037098246,
        "to_longitude": 105.80568728495513,
        "from_longitude": 105.80545762079818,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5850,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80568728495513,
          21.018082037098246,
          105.80588442731398,
          21.018226002904036
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80568728495513,
            21.018082037098246
          ],
          [
            105.80576506901556,
            21.018098311500523
          ],
          [
            105.80580932546127,
            21.018164660968594
          ],
          [
            105.80588442731398,
            21.018226002904036
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "diemDau": "26",
        "diemCuoi": "26-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.018082037098246,
        "to_latitude": 21.018226002904036,
        "to_longitude": 105.80588442731398,
        "from_longitude": 105.80568728495513,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5851,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80588442731398,
          21.018197209754156,
          105.80600378562139,
          21.018253544173202
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80588442731398,
            21.018226002904036
          ],
          [
            105.80591393161711,
            21.018197209754156
          ],
          [
            105.80600378562139,
            21.018253544173202
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "diemDau": "26-1",
        "diemCuoi": "26-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.018226002904036,
        "to_latitude": 21.018253544173202,
        "to_longitude": 105.80600378562139,
        "from_longitude": 105.80588442731398,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5852,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80597025800664,
          21.018253544173202,
          105.80600378562139,
          21.018372472320834
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80600378562139,
            21.018253544173202
          ],
          [
            105.80597025800664,
            21.018343679199248
          ],
          [
            105.80600244451752,
            21.018372472320834
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDLT",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "diemDau": "26-2",
        "diemCuoi": "26-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Láng Trung",
        "from_latitude": 21.018253544173202,
        "to_latitude": 21.018372472320834,
        "to_longitude": 105.80600244451752,
        "from_longitude": 105.80600378562139,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5853,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83875414729233,
          21.013109199334377,
          105.83881438094356,
          21.01315954222165
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83875414729233,
            21.01315954222165
          ],
          [
            105.83881438094356,
            21.013109199334377
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 249 Phố Chợ Khâm Thiên",
        "diemDau": "5-15",
        "diemCuoi": "5-15-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01315954222165,
        "to_latitude": 21.013109199334377,
        "to_longitude": 105.83881438094356,
        "from_longitude": 105.83875414729233,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5854,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80843155102087,
          21.022535491093265,
          105.80872424952902,
          21.022994262299044
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80872424952902,
            21.022994262299044
          ],
          [
            105.80860488881522,
            21.022639080798843
          ],
          [
            105.80843155102087,
            21.022535491093265
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 85,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 Chùa Láng",
        "diemDau": "47",
        "diemCuoi": "47-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.022994262299044,
        "to_latitude": 21.022535491093265,
        "to_longitude": 105.80843155102087,
        "from_longitude": 105.80872424952902,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5855,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80719505265893,
          21.022740792678775,
          105.80733452752439,
          21.02274830370677
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80719505265893,
            21.022740792678775
          ],
          [
            105.80733452752439,
            21.02274830370677
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Láng",
        "diemDau": "42--4",
        "diemCuoi": "42-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.022740792678775,
        "to_latitude": 21.02274830370677,
        "to_longitude": 105.80733452752439,
        "from_longitude": 105.80719505265893,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5856,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83663837611557,
          21.01391313831439,
          105.83687454594366,
          21.01409785218522
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83663837611557,
            21.01409785218522
          ],
          [
            105.83687454594366,
            21.01391313831439
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 249 Phố Chợ Khâm Thiên",
        "diemDau": "05",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01409785218522,
        "to_latitude": 21.01391313831439,
        "to_longitude": 105.83687454594366,
        "from_longitude": 105.83663837611557,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5857,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83728478848684,
          21.015027335988627,
          105.83753705157035,
          21.015114969159967
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83728478848684,
            21.015061820251265
          ],
          [
            105.83739221228042,
            21.015114969159967
          ],
          [
            105.83753705157035,
            21.015027335988627
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 191 Phố Chợ Khâm Thiên",
        "diemDau": "08",
        "diemCuoi": "8-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.015061820251265,
        "to_latitude": 21.015027335988627,
        "to_longitude": 105.83753705157035,
        "from_longitude": 105.83728478848684,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5858,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83741366995136,
          21.014897138038,
          105.83753705157035,
          21.015027335988627
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83753705157035,
            21.015027335988627
          ],
          [
            105.83748608959631,
            21.01501982457089
          ],
          [
            105.83741366995136,
            21.014897138038
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 191 Phố Chợ Khâm Thiên",
        "diemDau": "8-6",
        "diemCuoi": "8-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.015027335988627,
        "to_latitude": 21.014897138038,
        "to_longitude": 105.83741366995136,
        "from_longitude": 105.83753705157035,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5859,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83753705157035,
          21.014999794123653,
          105.83770066632347,
          21.01507991589728
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83753705157035,
            21.015027335988627
          ],
          [
            105.83756923808123,
            21.01507991589728
          ],
          [
            105.83770066632347,
            21.014999794123653
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 191 Phố Chợ Khâm Thiên",
        "diemDau": "8-6",
        "diemCuoi": "8-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.015027335988627,
        "to_latitude": 21.014999794123653,
        "to_longitude": 105.83770066632347,
        "from_longitude": 105.83753705157035,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5860,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80487361158394,
          21.02351912096306,
          105.80516830849739,
          21.023600673621154
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80487361158394,
            21.023600673621154
          ],
          [
            105.80492405988639,
            21.023591883649654
          ],
          [
            105.80516830849739,
            21.02351912096306
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Chùa Bộc",
        "diemDau": "",
        "diemCuoi": "36",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023600673621154,
        "to_latitude": 21.02351912096306,
        "to_longitude": 105.80516830849739,
        "from_longitude": 105.80487361158394,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5861,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83713032626262,
          21.01472437519741,
          105.83725541962271,
          21.01480944796993
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83713032626262,
            21.01480944796993
          ],
          [
            105.83725541962271,
            21.01472437519741
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 207 Phố Chợ Khâm Thiên",
        "diemDau": "07",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01480944796993,
        "to_latitude": 21.01472437519741,
        "to_longitude": 105.83725541962271,
        "from_longitude": 105.83713032626262,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5862,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83725541962271,
          21.01462672654716,
          105.83740562332812,
          21.01472437519741
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83725541962271,
            21.01472437519741
          ],
          [
            105.83740562332812,
            21.01462672654716
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 207 Phố Chợ Khâm Thiên",
        "diemDau": "7-1",
        "diemCuoi": "7-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01472437519741,
        "to_latitude": 21.01462672654716,
        "to_longitude": 105.83740562332812,
        "from_longitude": 105.83725541962271,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5863,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80516830849739,
          21.02351912096306,
          105.80542060369567,
          21.023863843683333
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80538841718482,
            21.023863843683333
          ],
          [
            105.80542060369567,
            21.023533360556662
          ],
          [
            105.80516830849739,
            21.02351912096306
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "diemDau": "01",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023863843683333,
        "to_latitude": 21.02351912096306,
        "to_longitude": 105.80516830849739,
        "from_longitude": 105.80538841718482,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5864,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83630274367837,
          21.01381068234426,
          105.8364475829683,
          21.01398199346984
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83642427760168,
            21.01381068234426
          ],
          [
            105.8364475829683,
            21.013865565645766
          ],
          [
            105.83630274367837,
            21.01398199346984
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 292 Phố Chợ Khâm Thiên",
        "diemDau": "04",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01381068234426,
        "to_latitude": 21.01398199346984,
        "to_longitude": 105.83630274367837,
        "from_longitude": 105.83642427760168,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5865,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83612840009428,
          21.01398199346984,
          105.83630274367837,
          21.01409466547118
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83630274367837,
            21.01398199346984
          ],
          [
            105.83612840009428,
            21.01409466547118
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 292 Phố Chợ Khâm Thiên",
        "diemDau": "4-2",
        "diemCuoi": "4-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01398199346984,
        "to_latitude": 21.01409466547118,
        "to_longitude": 105.83612840009428,
        "from_longitude": 105.83630274367837,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5866,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83606670928926,
          21.01409466547118,
          105.83612840009428,
          21.014168528181482
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83612840009428,
            21.01409466547118
          ],
          [
            105.83606670928926,
            21.014168528181482
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 292 Phố Chợ Khâm Thiên",
        "diemDau": "4-3",
        "diemCuoi": "4-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01409466547118,
        "to_latitude": 21.014168528181482,
        "to_longitude": 105.83606670928926,
        "from_longitude": 105.83612840009428,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5867,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83607609701637,
          21.014028314191528,
          105.83612840009428,
          21.01409466547118
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83612840009428,
            21.01409466547118
          ],
          [
            105.83607609701637,
            21.014028314191528
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 292 Phố Chợ Khâm Thiên",
        "diemDau": "4-3",
        "diemCuoi": "4-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01409466547118,
        "to_latitude": 21.014028314191528,
        "to_longitude": 105.83607609701637,
        "from_longitude": 105.83612840009428,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5868,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83601842952295,
          21.013969474353395,
          105.83607609701637,
          21.014028314191528
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83607609701637,
            21.014028314191528
          ],
          [
            105.83601842952295,
            21.013969474353395
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 292 Phố Chợ Khâm Thiên",
        "diemDau": "4-5",
        "diemCuoi": "4-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.014028314191528,
        "to_latitude": 21.013969474353395,
        "to_longitude": 105.83601842952295,
        "from_longitude": 105.83607609701637,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5869,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80544004985903,
          21.024324515909292,
          105.80544675538735,
          21.024596161621165
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80544206136659,
            21.024324515909292
          ],
          [
            105.80544004985903,
            21.0244697274962
          ],
          [
            105.80544675538735,
            21.024596161621165
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.024324515909292,
        "to_latitude": 21.024596161621165,
        "to_longitude": 105.80544675538735,
        "from_longitude": 105.80544206136659,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5870,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80544675538735,
          21.024596161621165,
          105.80555806705355,
          21.02465124180018
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80544675538735,
            21.024596161621165
          ],
          [
            105.80544675538735,
            21.02464748633417
          ],
          [
            105.80555806705355,
            21.02465124180018
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "diemDau": "23",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.024596161621165,
        "to_latitude": 21.02465124180018,
        "to_longitude": 105.80555806705355,
        "from_longitude": 105.80544675538735,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5871,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80537232392939,
          21.02465124180018,
          105.80555806705355,
          21.02479269763665
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80555806705355,
            21.02465124180018
          ],
          [
            105.80537232392939,
            21.02479269763665
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "diemDau": "25",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02465124180018,
        "to_latitude": 21.02479269763665,
        "to_longitude": 105.80537232392939,
        "from_longitude": 105.80555806705355,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5872,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80555806705355,
          21.024637471772227,
          105.80604555839602,
          21.02465124180018
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80555806705355,
            21.02465124180018
          ],
          [
            105.80604555839602,
            21.024637471772227
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02465124180018,
        "to_latitude": 21.024637471772227,
        "to_longitude": 105.80604555839602,
        "from_longitude": 105.80555806705355,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5873,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82787220198757,
          21.027473917254984,
          105.82816322166232,
          21.027511471203752
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82787220198757,
            21.027511471203752
          ],
          [
            105.82803188313483,
            21.027473917254984
          ],
          [
            105.82816322166232,
            21.027473917257517
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35/92 An Trạch",
        "diemDau": "tu",
        "diemCuoi": "15-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.027511471203752,
        "to_latitude": 21.027473917257517,
        "to_longitude": 105.82816322166232,
        "from_longitude": 105.82787220198757,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5874,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82803188313483,
          21.027473917254984,
          105.82844083029836,
          21.027561543115137
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82816322166232,
            21.027473917257517
          ],
          [
            105.82803188313483,
            21.027473917254984
          ],
          [
            105.82844083029836,
            21.027561543115137
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35/75 An Trạch",
        "diemDau": "tu",
        "diemCuoi": "15-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.027473917257517,
        "to_latitude": 21.027561543115137,
        "to_longitude": 105.82844083029836,
        "from_longitude": 105.82816322166232,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5875,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82844083029836,
          21.027561543115137,
          105.82865272481777,
          21.027654176107834
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82844083029836,
            21.027561543115137
          ],
          [
            105.82865272481777,
            21.027654176107834
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35/75 An Trạch",
        "diemDau": "15-1",
        "diemCuoi": "15-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.027561543115137,
        "to_latitude": 21.027654176107834,
        "to_longitude": 105.82865272481777,
        "from_longitude": 105.82844083029836,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5876,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82813237626432,
          21.027624132981533,
          105.828341588567,
          21.027689226414335
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82813237626432,
            21.027624132981533
          ],
          [
            105.828341588567,
            21.027689226414335
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 An Trạch",
        "diemDau": "16",
        "diemCuoi": "16-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.027624132981533,
        "to_latitude": 21.027689226414335,
        "to_longitude": 105.828341588567,
        "from_longitude": 105.82813237626432,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5877,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82812969405656,
          21.027897024489427,
          105.82821552474023,
          21.02793457832826
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82812969405656,
            21.02793457832826
          ],
          [
            105.82821552474023,
            21.027897024489427
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 8,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35/61  An Trạch",
        "diemDau": "16-5",
        "diemCuoi": "16-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.02793457832826,
        "to_latitude": 21.027897024489427,
        "to_longitude": 105.82821552474023,
        "from_longitude": 105.82812969405656,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5878,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82789634187522,
          21.02793457832826,
          105.82812969405656,
          21.027994664449636
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82812969405656,
            21.02793457832826
          ],
          [
            105.82789634187522,
            21.027994664449636
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35/64 An Trạch",
        "diemDau": "16-5",
        "diemCuoi": "16-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.02793457832826,
        "to_latitude": 21.027994664449636,
        "to_longitude": 105.82789634187522,
        "from_longitude": 105.82812969405656,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5879,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82763348539073,
          21.027994664449636,
          105.82789634187522,
          21.028074779240193
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82789634187522,
            21.027994664449636
          ],
          [
            105.82763348539073,
            21.028074779240193
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35/64 An Trạch",
        "diemDau": "16-3",
        "diemCuoi": "16-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.027994664449636,
        "to_latitude": 21.028074779240193,
        "to_longitude": 105.82763348539073,
        "from_longitude": 105.82789634187522,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5880,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82812969405656,
          21.02793457832826,
          105.8282396646279,
          21.028230001525078
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82812969405656,
            21.02793457832826
          ],
          [
            105.8282396646279,
            21.028230001525078
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 An Trạch",
        "diemDau": "16-5",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.02793457832826,
        "to_latitude": 21.028230001525078,
        "to_longitude": 105.8282396646279,
        "from_longitude": 105.82812969405656,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5881,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82806800325153,
          21.028230001525078,
          105.8282396646279,
          21.028260044529222
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8282396646279,
            21.028230001525078
          ],
          [
            105.82806800325153,
            21.028260044529222
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35/46  An Trạch",
        "diemDau": "17",
        "diemCuoi": "17-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.028230001525078,
        "to_latitude": 21.028260044529222,
        "to_longitude": 105.82806800325153,
        "from_longitude": 105.8282396646279,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5882,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82795803268024,
          21.028260044529222,
          105.82806800325153,
          21.028310116189136
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82806800325153,
            21.028260044529222
          ],
          [
            105.82795803268024,
            21.028310116189136
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35/46  An Trạch",
        "diemDau": "17-2",
        "diemCuoi": "17-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.028260044529222,
        "to_latitude": 21.028310116189136,
        "to_longitude": 105.82795803268024,
        "from_longitude": 105.82806800325153,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5883,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8282396646279,
          21.028204965683766,
          105.82862053830694,
          21.028272562441593
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8282396646279,
            21.028230001525078
          ],
          [
            105.8283001040348,
            21.028272562441593
          ],
          [
            105.82862053830694,
            21.028204965683766
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35/45  An Trạch",
        "diemDau": "17",
        "diemCuoi": "17-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.028230001525078,
        "to_latitude": 21.028204965683766,
        "to_longitude": 105.82862053830694,
        "from_longitude": 105.8282396646279,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5884,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8282396646279,
          21.028230001525078,
          105.82829330880966,
          21.028575495707045
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8282396646279,
            21.028230001525078
          ],
          [
            105.82829330880966,
            21.028575495707045
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 An Trạch",
        "diemDau": "17",
        "diemCuoi": "18-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.028230001525078,
        "to_latitude": 21.028575495707045,
        "to_longitude": 105.82829330880966,
        "from_longitude": 105.8282396646279,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5885,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82810018975341,
          21.028575495707045,
          105.82829330880966,
          21.028605538641585
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82829330880966,
            21.028575495707045
          ],
          [
            105.82810018975341,
            21.028605538641585
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 An Trạch",
        "diemDau": "18-6",
        "diemCuoi": "18-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.028575495707045,
        "to_latitude": 21.028605538641585,
        "to_longitude": 105.82810018975341,
        "from_longitude": 105.82829330880966,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5886,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82791243512168,
          21.028605538641585,
          105.82810018975341,
          21.028638085147644
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82810018975341,
            21.028605538641585
          ],
          [
            105.82791243512168,
            21.028638085147644
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 An Trạch",
        "diemDau": "18-2",
        "diemCuoi": "18-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.028605538641585,
        "to_latitude": 21.028638085147644,
        "to_longitude": 105.82791243512168,
        "from_longitude": 105.82810018975341,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5887,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82829330880966,
          21.02857048855056,
          105.82846228797827,
          21.028575495707045
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82829330880966,
            21.028575495707045
          ],
          [
            105.82846228797827,
            21.02857048855056
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 An Trạch",
        "diemDau": "18-6",
        "diemCuoi": "18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.028575495707045,
        "to_latitude": 21.02857048855056,
        "to_longitude": 105.82846228797827,
        "from_longitude": 105.82829330880966,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5888,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82810018975341,
          21.028450316744475,
          105.8281177138185,
          21.028605538641585
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82810018975341,
            21.028605538641585
          ],
          [
            105.8281177138185,
            21.028450316744475
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 An Trạch",
        "diemDau": "18-2",
        "diemCuoi": "18-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.028605538641585,
        "to_latitude": 21.028450316744475,
        "to_longitude": 105.8281177138185,
        "from_longitude": 105.82810018975341,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5889,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82829330880966,
          21.028575495707045,
          105.82833622415151,
          21.028760760373665
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82829330880966,
            21.028575495707045
          ],
          [
            105.82833622415151,
            21.028760760373665
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 An Trạch",
        "diemDau": "18-6",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.028575495707045,
        "to_latitude": 21.028760760373665,
        "to_longitude": 105.82833622415151,
        "from_longitude": 105.82829330880966,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5890,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82809750754568,
          21.028760760373665,
          105.82833622415151,
          21.028838371179077
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82833622415151,
            21.028760760373665
          ],
          [
            105.82809750754568,
            21.028838371179077
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 An Trạch",
        "diemDau": "18",
        "diemCuoi": "18-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.028760760373665,
        "to_latitude": 21.028838371179077,
        "to_longitude": 105.82809750754568,
        "from_longitude": 105.82833622415151,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5891,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82833622415151,
          21.028760760373665,
          105.82838316280495,
          21.029034901587586
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82833622415151,
            21.028760760373665
          ],
          [
            105.82838316280495,
            21.029034901587586
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 An Trạch",
        "diemDau": "18",
        "diemCuoi": "19-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.028760760373665,
        "to_latitude": 21.029034901587586,
        "to_longitude": 105.82838316280495,
        "from_longitude": 105.82833622415151,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5892,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82838316280495,
          21.029034901587586,
          105.82844217140223,
          21.029265229887283
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82838316280495,
            21.029034901587586
          ],
          [
            105.82844217140223,
            21.029265229887283
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35/2 An Trạch",
        "diemDau": "19-2",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.029034901587586,
        "to_latitude": 21.029265229887283,
        "to_longitude": 105.82844217140223,
        "from_longitude": 105.82838316280495,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5893,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82844217140223,
          21.028839622967023,
          105.82910476673467,
          21.029265229887283
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82844217140223,
            21.029265229887283
          ],
          [
            105.82910476673467,
            21.029136296149456
          ],
          [
            105.82904566843165,
            21.028839622967023
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 80,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 An Trạch",
        "diemDau": "19",
        "diemCuoi": "19-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.029265229887283,
        "to_latitude": 21.028839622967023,
        "to_longitude": 105.82904566843165,
        "from_longitude": 105.82844217140223,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5894,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82874526102081,
          21.028839622967023,
          105.82904566843165,
          21.028912226586353
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82904566843165,
            21.028839622967023
          ],
          [
            105.82874526102081,
            21.028912226586353
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 An Trạch",
        "diemDau": "19-2",
        "diemCuoi": "19-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.028839622967023,
        "to_latitude": 21.028912226586353,
        "to_longitude": 105.82874526102081,
        "from_longitude": 105.82904566843165,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5895,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82904566843165,
          21.028689408469727,
          105.82925228823235,
          21.028839622967023
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82904566843165,
            21.028839622967023
          ],
          [
            105.82925228823235,
            21.028815839010388
          ],
          [
            105.82923878747891,
            21.028689408469727
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 An Trạch",
        "diemDau": "19-2",
        "diemCuoi": "19-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.028839622967023,
        "to_latitude": 21.028689408469727,
        "to_longitude": 105.82923878747891,
        "from_longitude": 105.82904566843165,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5896,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82873998631109,
          21.02875450143728,
          105.82886059600762,
          21.028912226586353
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82874526102081,
            21.028912226586353
          ],
          [
            105.82873998631109,
            21.028765767519566
          ],
          [
            105.82886059600762,
            21.02875450143728
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 An Trạch",
        "diemDau": "19-3",
        "diemCuoi": "19-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.028912226586353,
        "to_latitude": 21.02875450143728,
        "to_longitude": 105.82886059600762,
        "from_longitude": 105.82874526102081,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5897,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82765024918463,
          21.00488319241548,
          105.82822189499038,
          21.005154560559024
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82765024918463,
            21.005154560559024
          ],
          [
            105.82822189499038,
            21.00488319241548
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 71,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 95 Cự Lộc",
        "diemDau": "TL3 01",
        "diemCuoi": "TL3 1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.005154560559024,
        "to_latitude": 21.00488319241548,
        "to_longitude": 105.82822189499038,
        "from_longitude": 105.82765024918463,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5898,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82814981062363,
          21.02904741943902,
          105.82844217140223,
          21.029265229887283
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82844217140223,
            21.029265229887283
          ],
          [
            105.82835508932045,
            21.029049923003846
          ],
          [
            105.82814981062363,
            21.02904741943902
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 Cát Linh",
        "diemDau": "19",
        "diemCuoi": "19-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.029265229887283,
        "to_latitude": 21.02904741943902,
        "to_longitude": 105.82814981062363,
        "from_longitude": 105.82844217140223,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5899,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82798083145504,
          21.027209787581505,
          105.82812969405656,
          21.02793457832826
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82812969405656,
            21.02793457832826
          ],
          [
            105.82804950306219,
            21.027496749193578
          ],
          [
            105.82798083145504,
            21.027209787581505
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 69,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 Cát Linh",
        "diemDau": "15",
        "diemCuoi": "16-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.02793457832826,
        "to_latitude": 21.027209787581505,
        "to_longitude": 105.82798083145504,
        "from_longitude": 105.82812969405656,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5900,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82798083145504,
          21.027209787581505,
          105.82821016032476,
          21.027478924452495
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82798083145504,
            21.027209787581505
          ],
          [
            105.82804273597807,
            21.02746847125218
          ],
          [
            105.82821016032476,
            21.027478924452495
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 61,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 Cát Linh",
        "diemDau": "15",
        "diemCuoi": "15-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.027209787581505,
        "to_latitude": 21.027478924452495,
        "to_longitude": 105.82821016032476,
        "from_longitude": 105.82798083145504,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5901,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82822189499038,
          21.004771765446172,
          105.82848341037098,
          21.00488319241548
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82822189499038,
            21.00488319241548
          ],
          [
            105.82848341037098,
            21.004771765446172
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 95 Cự Lộc",
        "diemDau": "TL3 1- 1",
        "diemCuoi": "TL3 1-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.00488319241548,
        "to_latitude": 21.004771765446172,
        "to_longitude": 105.82848341037098,
        "from_longitude": 105.82822189499038,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5902,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82822189499038,
          21.00488319241548,
          105.82826648671428,
          21.00504375978367
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82822189499038,
            21.00488319241548
          ],
          [
            105.82826648671428,
            21.00504375978367
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 95 Cự Lộc",
        "diemDau": "TL03 1-1",
        "diemCuoi": "TL03 1-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.00488319241548,
        "to_latitude": 21.00504375978367,
        "to_longitude": 105.82826648671428,
        "from_longitude": 105.82822189499038,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5903,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82803188313483,
          21.027473917254984,
          105.82816322166232,
          21.02763695607744
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82813237626432,
            21.027624132981533
          ],
          [
            105.82807518275621,
            21.02763695607744
          ],
          [
            105.82803188313483,
            21.027473917254984
          ],
          [
            105.82816322166232,
            21.027473917257517
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 35 An Trạch",
        "diemDau": "tu",
        "diemCuoi": "16-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.027624132981533,
        "to_latitude": 21.027473917257517,
        "to_longitude": 105.82816322166232,
        "from_longitude": 105.82813237626432,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5904,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83309312183673,
          21.01489030950569,
          105.83350618201766,
          21.015320963850783
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83309312183673,
            21.015320963850783
          ],
          [
            105.83350618201766,
            21.01489030950569
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 71,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "9",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.015320963850783,
        "to_latitude": 21.01489030950569,
        "to_longitude": 105.83350618201766,
        "from_longitude": 105.83309312183673,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5905,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83350618201766,
          21.01445214247156,
          105.83392460663202,
          21.01489030950569
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83350618201766,
            21.01489030950569
          ],
          [
            105.83392460663202,
            21.01445214247156
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 69,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "8",
        "diemCuoi": "9",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.01489030950569,
        "to_latitude": 21.01445214247156,
        "to_longitude": 105.83392460663202,
        "from_longitude": 105.83350618201766,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5906,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83392460663202,
          21.014016477972465,
          105.83434034902963,
          21.01445214247156
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83392460663202,
            21.01445214247156
          ],
          [
            105.83434034902963,
            21.014016477972465
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 70,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "7",
        "diemCuoi": "8",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.01445214247156,
        "to_latitude": 21.014016477972465,
        "to_longitude": 105.83434034902963,
        "from_longitude": 105.83392460663202,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5907,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83434034902963,
          21.01358581986156,
          105.83475877363502,
          21.014016477972465
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83434034902963,
            21.014016477972465
          ],
          [
            105.83475877363502,
            21.01358581986156
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 71,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "6",
        "diemCuoi": "7",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.014016477972465,
        "to_latitude": 21.01358581986156,
        "to_longitude": 105.83475877363502,
        "from_longitude": 105.83434034902963,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5908,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83475877363502,
          21.013152656669646,
          105.83517183381595,
          21.01358581986156
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83475877363502,
            21.01358581986156
          ],
          [
            105.83517183381595,
            21.013152656669646
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 71,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "5",
        "diemCuoi": "6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.01358581986156,
        "to_latitude": 21.013152656669646,
        "to_longitude": 105.83517183381595,
        "from_longitude": 105.83475877363502,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5909,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83517183381595,
          21.012719492218945,
          105.8355822117981,
          21.013152656669646
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83517183381595,
            21.013152656669646
          ],
          [
            105.8355822117981,
            21.012719492218945
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 71,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "4",
        "diemCuoi": "5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.013152656669646,
        "to_latitude": 21.012719492218945,
        "to_longitude": 105.8355822117981,
        "from_longitude": 105.83517183381595,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5910,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8355822117981,
          21.012291334215387,
          105.83599795418675,
          21.012719492218945
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8355822117981,
            21.012719492218945
          ],
          [
            105.83599795418675,
            21.012291334215387
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 71,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "3",
        "diemCuoi": "4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.012719492218945,
        "to_latitude": 21.012291334215387,
        "to_longitude": 105.83599795418675,
        "from_longitude": 105.8355822117981,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5911,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83599795418675,
          21.011825617095234,
          105.83643783646305,
          21.012291334215387
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83599795418675,
            21.012291334215387
          ],
          [
            105.83643783646305,
            21.011825617095234
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 71,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "2",
        "diemCuoi": "3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.012291334215387,
        "to_latitude": 21.011825617095234,
        "to_longitude": 105.83643783646305,
        "from_longitude": 105.83599795418675,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5912,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83643783646305,
          21.01131983664093,
          105.83690990525022,
          21.011825617095234
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83643783646305,
            21.011825617095234
          ],
          [
            105.83690990525022,
            21.01131983664093
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 80,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "1",
        "diemCuoi": "2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.011825617095234,
        "to_latitude": 21.01131983664093,
        "to_longitude": 105.83690990525022,
        "from_longitude": 105.83643783646305,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5913,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83606500940726,
          21.011550192307812,
          105.83649952726806,
          21.012014658358677
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83649952726806,
            21.011550192307812
          ],
          [
            105.83606500940726,
            21.012014658358677
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 67,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.011550192307812,
        "to_latitude": 21.012014658358677,
        "to_longitude": 105.83606500940726,
        "from_longitude": 105.83649952726806,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5914,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83631043154139,
          21.011778043759165,
          105.83674092608163,
          21.012234997543388
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83674092608163,
            21.011778043759165
          ],
          [
            105.83631043154139,
            21.012234997543388
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 68,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.011778043759165,
        "to_latitude": 21.012234997543388,
        "to_longitude": 105.83631043154139,
        "from_longitude": 105.83674092608163,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5915,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83587323147287,
          21.012234997543388,
          105.83631043154139,
          21.01268569031597
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83631043154139,
            21.012234997543388
          ],
          [
            105.83587323147287,
            21.01268569031597
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 70,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.012234997543388,
        "to_latitude": 21.01268569031597,
        "to_longitude": 105.83587323147287,
        "from_longitude": 105.83631043154139,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5916,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83561842160263,
          21.012014658358677,
          105.83606500940726,
          21.01247912296333
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83561842160263,
            21.01247912296333
          ],
          [
            105.83606500940726,
            21.012014658358677
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 68,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.01247912296333,
        "to_latitude": 21.012014658358677,
        "to_longitude": 105.83606500940726,
        "from_longitude": 105.83561842160263,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5917,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83544944244301,
          21.01268569031597,
          105.83587323147287,
          21.013133877889807
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83587323147287,
            21.01268569031597
          ],
          [
            105.83544944244301,
            21.013133877889807
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 69,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.01268569031597,
        "to_latitude": 21.013133877889807,
        "to_longitude": 105.83544944244301,
        "from_longitude": 105.83587323147287,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5918,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8351959736856,
          21.01247912296333,
          105.83561842160263,
          21.012913540032034
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8351959736856,
            21.012913540032034
          ],
          [
            105.83561842160263,
            21.01247912296333
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 70,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.012913540032034,
        "to_latitude": 21.01247912296333,
        "to_longitude": 105.83561842160263,
        "from_longitude": 105.8351959736856,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5919,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8350149245822,
          21.013133877889807,
          105.83544944244301,
          21.01357956028647
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83544944244301,
            21.013133877889807
          ],
          [
            105.8350149245822,
            21.01357956028647
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 70,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.013133877889807,
        "to_latitude": 21.01357956028647,
        "to_longitude": 105.8350149245822,
        "from_longitude": 105.83544944244301,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5920,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8347627969287,
          21.012913540032034,
          105.8351959736856,
          21.013357971170826
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8347627969287,
            21.013357971170826
          ],
          [
            105.8351959736856,
            21.012913540032034
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 71,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.013357971170826,
        "to_latitude": 21.012913540032034,
        "to_longitude": 105.8351959736856,
        "from_longitude": 105.8347627969287,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5921,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83458308893809,
          21.01357956028647,
          105.8350149245822,
          21.0140302489964
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8350149245822,
            21.01357956028647
          ],
          [
            105.83458308893809,
            21.0140302489964
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 70,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.01357956028647,
        "to_latitude": 21.0140302489964,
        "to_longitude": 105.83458308893809,
        "from_longitude": 105.8350149245822,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5922,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83433900790781,
          21.013357971170826,
          105.8347627969287,
          21.013794889505924
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83433900790781,
            21.013794889505924
          ],
          [
            105.8347627969287,
            21.013357971170826
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 69,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.013794889505924,
        "to_latitude": 21.013357971170826,
        "to_longitude": 105.8347627969287,
        "from_longitude": 105.83433900790781,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5923,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83388437347995,
          21.013794889505924,
          105.83433900790781,
          21.014256844749788
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83433900790781,
            21.013794889505924
          ],
          [
            105.83388437347995,
            21.014256844749788
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 70,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "33",
        "diemCuoi": "34",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.013794889505924,
        "to_latitude": 21.014256844749788,
        "to_longitude": 105.83388437347995,
        "from_longitude": 105.83433900790781,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5924,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83412845450124,
          21.0140302489964,
          105.83458308893809,
          21.0144972111406
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83458308893809,
            21.0140302489964
          ],
          [
            105.83412845450124,
            21.0144972111406
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 71,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.0140302489964,
        "to_latitude": 21.0144972111406,
        "to_longitude": 105.83412845450124,
        "from_longitude": 105.83458308893809,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5925,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83346997218618,
          21.014256844749788,
          105.83388437347995,
          21.0146837452062
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83388437347995,
            21.014256844749788
          ],
          [
            105.83346997218618,
            21.0146837452062
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 70,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.014256844749788,
        "to_latitude": 21.0146837452062,
        "to_longitude": 105.83346997218618,
        "from_longitude": 105.83388437347995,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5926,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8337167354242,
          21.0144972111406,
          105.83412845450124,
          21.014921607101957
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83412845450124,
            21.0144972111406
          ],
          [
            105.8337167354242,
            21.014921607101957
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 70,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.0144972111406,
        "to_latitude": 21.014921607101957,
        "to_longitude": 105.8337167354242,
        "from_longitude": 105.83412845450124,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5927,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83342571574944,
          21.014921607101957,
          105.8337167354242,
          21.015084354496206
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8337167354242,
            21.014921607101957
          ],
          [
            105.83355714397372,
            21.015084354496206
          ],
          [
            105.83342571574944,
            21.01497418704619
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.014921607101957,
        "to_latitude": 21.01497418704619,
        "to_longitude": 105.83342571574944,
        "from_longitude": 105.8337167354242,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5928,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83331038074468,
          21.0146837452062,
          105.83346997218618,
          21.01497418704619
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83346997218618,
            21.0146837452062
          ],
          [
            105.83331038074468,
            21.01484899667
          ],
          [
            105.83342571574944,
            21.01497418704619
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "",
        "diemCuoi": "35",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.0146837452062,
        "to_latitude": 21.01497418704619,
        "to_longitude": 105.83342571574944,
        "from_longitude": 105.83346997218618,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5929,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83334122616066,
          21.01497418704619,
          105.83354641513378,
          21.01532221575054
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83342571574944,
            21.01497418704619
          ],
          [
            105.83354641513378,
            21.015095621616585
          ],
          [
            105.83334122616066,
            21.01532221575054
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.01497418704619,
        "to_latitude": 21.01532221575054,
        "to_longitude": 105.83334122616066,
        "from_longitude": 105.83342571574944,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5930,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83307702856332,
          21.014862767616215,
          105.83342571574944,
          21.01509186590817
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83342571574944,
            21.01497418704619
          ],
          [
            105.83330233412144,
            21.014862767616215
          ],
          [
            105.83307702856332,
            21.01509186590817
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "",
        "diemCuoi": "36",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.01497418704619,
        "to_latitude": 21.01509186590817,
        "to_longitude": 105.83307702856332,
        "from_longitude": 105.83342571574944,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5931,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83292280155527,
          21.01532221575054,
          105.83334122616066,
          21.015745357468123
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83334122616066,
            21.01532221575054
          ],
          [
            105.83292280155527,
            21.015745357468123
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 70,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.01532221575054,
        "to_latitude": 21.015745357468123,
        "to_longitude": 105.83292280155527,
        "from_longitude": 105.83334122616066,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5932,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83268006164683,
          21.015320963850783,
          105.83309312183673,
          21.01574911315836
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83309312183673,
            21.015320963850783
          ],
          [
            105.83268006164683,
            21.01574911315836
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x25",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 72,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.015320963850783,
        "to_latitude": 21.01574911315836,
        "to_longitude": 105.83268006164683,
        "from_longitude": 105.83309312183673,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5933,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83266262726954,
          21.01509186590817,
          105.83307702856332,
          21.01552752726602
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83307702856332,
            21.01509186590817
          ],
          [
            105.83266262726954,
            21.01552752726602
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 71,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "36",
        "diemCuoi": "37",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.01509186590817,
        "to_latitude": 21.01552752726602,
        "to_longitude": 105.83266262726954,
        "from_longitude": 105.83307702856332,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5934,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83250169474218,
          21.015745357468123,
          105.83292280155527,
          21.016186024488743
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83292280155527,
            21.015745357468123
          ],
          [
            105.83250169474218,
            21.016186024488743
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 70,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.015745357468123,
        "to_latitude": 21.016186024488743,
        "to_longitude": 105.83250169474218,
        "from_longitude": 105.83292280155527,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5935,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83225359040922,
          21.01574911315836,
          105.83268006164683,
          21.016164742304618
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83268006164683,
            21.01574911315836
          ],
          [
            105.83225359040922,
            21.016164742304618
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 72,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.01574911315836,
        "to_latitude": 21.016164742304618,
        "to_longitude": 105.83225359040922,
        "from_longitude": 105.83268006164683,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5936,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83223615604093,
          21.01552752726602,
          105.83266262726954,
          21.01596944682546
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83266262726954,
            21.01552752726602
          ],
          [
            105.83223615604093,
            21.01596944682546
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 71,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "37",
        "diemCuoi": "38",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.01552752726602,
        "to_latitude": 21.01596944682546,
        "to_longitude": 105.83223615604093,
        "from_longitude": 105.83266262726954,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5937,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83181370811498,
          21.01596944682546,
          105.83223615604093,
          21.016356281852367
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83223615604093,
            21.01596944682546
          ],
          [
            105.83201889710605,
            21.016188528275393
          ],
          [
            105.83181370811498,
            21.016356281852367
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 69,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "38",
        "diemCuoi": "39",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.01596944682546,
        "to_latitude": 21.016356281852367,
        "to_longitude": 105.83181370811498,
        "from_longitude": 105.83223615604093,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5938,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83202157932277,
          21.016186024488743,
          105.83250169474218,
          21.016614171313694
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83250169474218,
            21.016186024488743
          ],
          [
            105.83225224929637,
            21.016453929371064
          ],
          [
            105.83202157932277,
            21.016614171313694
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 71,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.016186024488743,
        "to_latitude": 21.016614171313694,
        "to_longitude": 105.83202157932277,
        "from_longitude": 105.83250169474218,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5939,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83173592408147,
          21.016164742304618,
          105.83225359040922,
          21.016599148637667
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83225359040922,
            21.016164742304618
          ],
          [
            105.832091316751,
            21.016323732665814
          ],
          [
            105.83173592408147,
            21.016599148637667
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 70,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.016164742304618,
        "to_latitude": 21.016599148637667,
        "to_longitude": 105.83173592408147,
        "from_longitude": 105.83225359040922,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5940,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83153341728017,
          21.016614171313694,
          105.83202157932277,
          21.016987233917796
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83202157932277,
            21.016614171313694
          ],
          [
            105.83153341728017,
            21.016987233917796
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 70,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.016614171313694,
        "to_latitude": 21.016987233917796,
        "to_longitude": 105.83153341728017,
        "from_longitude": 105.83202157932277,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5941,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83133359270457,
          21.016356281852367,
          105.83181370811498,
          21.016726841324875
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83181370811498,
            21.016356281852367
          ],
          [
            105.83133359270457,
            21.016726841324875
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 70,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "39",
        "diemCuoi": "40",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.016356281852367,
        "to_latitude": 21.016726841324875,
        "to_longitude": 105.83133359270457,
        "from_longitude": 105.83181370811498,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5942,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83119143564035,
          21.016599148637667,
          105.83173592408147,
          21.01702854616285
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83173592408147,
            21.016599148637667
          ],
          [
            105.83119143564035,
            21.01702854616285
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 73,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.016599148637667,
        "to_latitude": 21.01702854616285,
        "to_longitude": 105.83119143564035,
        "from_longitude": 105.83173592408147,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5943,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8310519607659,
          21.016987233917796,
          105.83153341728017,
          21.01737031065537
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83153341728017,
            21.016987233917796
          ],
          [
            105.8310519607659,
            21.01737031065537
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 71,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "27",
        "diemCuoi": "43",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.016987233917796,
        "to_latitude": 21.01737031065537,
        "to_longitude": 105.8310519607659,
        "from_longitude": 105.83153341728017,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5944,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82985569554702,
          21.017206313871753,
          105.83070729690037,
          21.01808513422133
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83070729690037,
            21.017206313871753
          ],
          [
            105.83000724034733,
            21.017743371369566
          ],
          [
            105.82987044768961,
            21.01783100294494
          ],
          [
            105.82985569554702,
            21.01808513422133
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 73,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "41",
        "diemCuoi": "47",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.017206313871753,
        "to_latitude": 21.01808513422133,
        "to_longitude": 105.82985569554702,
        "from_longitude": 105.83070729690037,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5945,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82985569554702,
          21.0179975027953,
          105.829897269785,
          21.01808513422133
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82985569554702,
            21.01808513422133
          ],
          [
            105.829897269785,
            21.0179975027953
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "47",
        "diemCuoi": "48",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.01808513422133,
        "to_latitude": 21.0179975027953,
        "to_longitude": 105.829897269785,
        "from_longitude": 105.82985569554702,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5946,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.829897269785,
          21.01793282242364,
          105.82998622971702,
          21.0179975027953
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.829897269785,
            21.0179975027953
          ],
          [
            105.82998622971702,
            21.01793282242364
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "48",
        "diemCuoi": "49",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.0179975027953,
        "to_latitude": 21.01793282242364,
        "to_longitude": 105.82998622971702,
        "from_longitude": 105.829897269785,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5947,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82998622971702,
          21.01786814202384,
          105.83007518964905,
          21.01793282242364
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82998622971702,
            21.01793282242364
          ],
          [
            105.83007518964905,
            21.01786814202384
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "49",
        "diemCuoi": "50",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.01793282242364,
        "to_latitude": 21.01786814202384,
        "to_longitude": 105.83007518964905,
        "from_longitude": 105.82998622971702,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5948,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83007518964905,
          21.01780346159603,
          105.8301641495811,
          21.01786814202384
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83007518964905,
            21.01786814202384
          ],
          [
            105.8301641495811,
            21.01780346159603
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "50",
        "diemCuoi": "51",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.01786814202384,
        "to_latitude": 21.01780346159603,
        "to_longitude": 105.8301641495811,
        "from_longitude": 105.83007518964905,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5949,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82985569554702,
          21.018067607940083,
          105.82996030170285,
          21.01808513422133
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82985569554702,
            21.01808513422133
          ],
          [
            105.82996030170285,
            21.018067607940083
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "47",
        "diemCuoi": "55",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.01808513422133,
        "to_latitude": 21.018067607940083,
        "to_longitude": 105.82996030170285,
        "from_longitude": 105.82985569554702,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5950,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82996030170285,
          21.01800209301367,
          105.83004836756565,
          21.018067607940083
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82996030170285,
            21.018067607940083
          ],
          [
            105.83004836756565,
            21.01800209301367
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "55",
        "diemCuoi": "54",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.018067607940083,
        "to_latitude": 21.01800209301367,
        "to_longitude": 105.83004836756565,
        "from_longitude": 105.82996030170285,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5951,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83004836756565,
          21.01793657805847,
          105.83013643342841,
          21.01800209301367
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83004836756565,
            21.01800209301367
          ],
          [
            105.83013643342841,
            21.01793657805847
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "54",
        "diemCuoi": "53",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.01800209301367,
        "to_latitude": 21.01793657805847,
        "to_longitude": 105.83013643342841,
        "from_longitude": 105.83004836756565,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5952,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83013643342841,
          21.0178710630745,
          105.83022449929122,
          21.01793657805847
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83013643342841,
            21.01793657805847
          ],
          [
            105.83022449929122,
            21.0178710630745
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "53",
        "diemCuoi": "52",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.01793657805847,
        "to_latitude": 21.0178710630745,
        "to_longitude": 105.83022449929122,
        "from_longitude": 105.83013643342841,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5953,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83007161336009,
          21.01811643114964,
          105.8301279397586,
          21.018351783819263
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83007161336907,
            21.018351783819263
          ],
          [
            105.83007161336009,
            21.018291693811225
          ],
          [
            105.8300796599923,
            21.018251633792147
          ],
          [
            105.83009575324773,
            21.01820155875378
          ],
          [
            105.8301279397586,
            21.01811643114964
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "45",
        "diemCuoi": "46",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.018351783819263,
        "to_latitude": 21.01811643114964,
        "to_longitude": 105.8301279397586,
        "from_longitude": 105.83007161336907,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5954,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8301279397586,
          21.017775920245416,
          105.83055038768458,
          21.01811643114964
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8301279397586,
            21.01811643114964
          ],
          [
            105.83055038768458,
            21.017775920245416
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 72,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "44",
        "diemCuoi": "45",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.01811643114964,
        "to_latitude": 21.017775920245416,
        "to_longitude": 105.83055038768458,
        "from_longitude": 105.8301279397586,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5955,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83055038768458,
          21.01737031065537,
          105.8310519607659,
          21.017775920245416
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8310519607659,
            21.01737031065537
          ],
          [
            105.83055038768458,
            21.017775920245416
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KLOCD",
        "chieuDaiQL": 65,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Xã Đàn",
        "diemDau": "43",
        "diemCuoi": "44",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Kim Liên OCD",
        "from_latitude": 21.01737031065537,
        "to_latitude": 21.017775920245416,
        "to_longitude": 105.83055038768458,
        "from_longitude": 105.8310519607659,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5956,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8363815991325,
          21.013426926144206,
          105.83667494716188,
          21.013648415594187
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8363815991325,
            21.013648415594187
          ],
          [
            105.83667494716188,
            21.013426926144206
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 273 Phố Chợ Khâm Thiên",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.013648415594187,
        "to_latitude": 21.013426926144206,
        "to_longitude": 105.83667494716188,
        "from_longitude": 105.8363815991325,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5957,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8362834247935,
          21.01338470320012,
          105.83644935442199,
          21.013525850526882
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8362834247935,
            21.013525850526882
          ],
          [
            105.83644935442199,
            21.01338470320012
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 281 Phố Chợ Khâm Thiên",
        "diemDau": "03",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.013525850526882,
        "to_latitude": 21.01338470320012,
        "to_longitude": 105.83644935442199,
        "from_longitude": 105.8362834247935,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5958,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83600737065149,
          21.01356742265774,
          105.83631776884921,
          21.01375311542903
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83631776884921,
            21.01356742265774
          ],
          [
            105.83600737065149,
            21.01375311542903
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 308 Phố Chợ Khâm Thiên",
        "diemDau": "03",
        "diemCuoi": "3+1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01356742265774,
        "to_latitude": 21.01375311542903,
        "to_longitude": 105.83600737065149,
        "from_longitude": 105.83631776884921,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5959,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83534090202554,
          21.01358957560619,
          105.8354524834199,
          21.01366025184245
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83534090202554,
            21.01358957560619
          ],
          [
            105.8354524834199,
            21.01366025184245
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 84 La Thành",
        "diemDau": "13",
        "diemCuoi": "13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01358957560619,
        "to_latitude": 21.01366025184245,
        "to_longitude": 105.8354524834199,
        "from_longitude": 105.83534090202554,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5960,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83516054636155,
          21.013787647460546,
          105.83525295270655,
          21.013879196039568
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83516054636155,
            21.013787647460546
          ],
          [
            105.83525295270655,
            21.013879196039568
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 96 La Thành",
        "diemDau": "14",
        "diemCuoi": "14-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.013787647460546,
        "to_latitude": 21.013879196039568,
        "to_longitude": 105.83525295270655,
        "from_longitude": 105.83516054636155,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5961,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83525295270655,
          21.013879196039568,
          105.83535830679497,
          21.01401504841948
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83525295270655,
            21.013879196039568
          ],
          [
            105.83535830679497,
            21.01401504841948
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 96 La Thành",
        "diemDau": "14-1",
        "diemCuoi": "14-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.013879196039568,
        "to_latitude": 21.01401504841948,
        "to_longitude": 105.83535830679497,
        "from_longitude": 105.83525295270655,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5962,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83535830679497,
          21.01401504841948,
          105.83545384289586,
          21.01412626453417
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83535830679497,
            21.01401504841948
          ],
          [
            105.83545384289586,
            21.01412626453417
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 96 La Thành",
        "diemDau": "14-2",
        "diemCuoi": "14-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01401504841948,
        "to_latitude": 21.01412626453417,
        "to_longitude": 105.83545384289586,
        "from_longitude": 105.83535830679497,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5963,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83545384289586,
          21.01412626453417,
          105.83564292025942,
          21.01428620767749
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83545384289586,
            21.01412626453417
          ],
          [
            105.83564292025942,
            21.01428620767749
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 96 La Thành",
        "diemDau": "14-3",
        "diemCuoi": "14-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01412626453417,
        "to_latitude": 21.01428620767749,
        "to_longitude": 105.83564292025942,
        "from_longitude": 105.83545384289586,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5964,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83544309569282,
          21.01428620767749,
          105.83564292025942,
          21.01450278778511
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83564292025942,
            21.01428620767749
          ],
          [
            105.83544309569282,
            21.01450278778511
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 96 La Thành",
        "diemDau": "14-4",
        "diemCuoi": "14-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01428620767749,
        "to_latitude": 21.01450278778511,
        "to_longitude": 105.83544309569282,
        "from_longitude": 105.83564292025942,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5965,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83523595461531,
          21.01450278778511,
          105.83544309569282,
          21.01462057295226
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83544309569282,
            21.01450278778511
          ],
          [
            105.83523595461531,
            21.01462057295226
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 96 La Thành",
        "diemDau": "14-5",
        "diemCuoi": "14-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01450278778511,
        "to_latitude": 21.01462057295226,
        "to_longitude": 105.83523595461531,
        "from_longitude": 105.83544309569282,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5966,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83544309569282,
          21.01450278778511,
          105.83558338837203,
          21.01469767124136
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83544309569282,
            21.01450278778511
          ],
          [
            105.83558338837203,
            21.01469767124136
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 96 La Thành",
        "diemDau": "14-5",
        "diemCuoi": "14-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01450278778511,
        "to_latitude": 21.01469767124136,
        "to_longitude": 105.83558338837203,
        "from_longitude": 105.83544309569282,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5967,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83558338837203,
          21.014674298976338,
          105.83572204543273,
          21.014728130915035
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83558338837203,
            21.01469767124136
          ],
          [
            105.83561609817302,
            21.014728130915035
          ],
          [
            105.83568583561025,
            21.014674298976338
          ],
          [
            105.83572204543273,
            21.014708100428642
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 96 La Thành",
        "diemDau": "14-7",
        "diemCuoi": "14-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01469767124136,
        "to_latitude": 21.014708100428642,
        "to_longitude": 105.83572204543273,
        "from_longitude": 105.83558338837203,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5968,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83564292025942,
          21.01428620767749,
          105.83578105403,
          21.014401383264854
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83564292025942,
            21.01428620767749
          ],
          [
            105.83576227856683,
            21.014401383264854
          ],
          [
            105.83578105403,
            21.01438886418364
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 96 La Thành",
        "diemDau": "14-4",
        "diemCuoi": "14-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01428620767749,
        "to_latitude": 21.01438886418364,
        "to_longitude": 105.83578105403,
        "from_longitude": 105.83564292025942,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5969,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83562784483344,
          21.01438886418364,
          105.83578105403,
          21.01450539839293
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83578105403,
            21.01438886418364
          ],
          [
            105.83562784483344,
            21.01450539839293
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 96 La Thành",
        "diemDau": "14-8",
        "diemCuoi": "14-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.01438886418364,
        "to_latitude": 21.01450539839293,
        "to_longitude": 105.83562784483344,
        "from_longitude": 105.83578105403,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5970,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82806632691438,
          21.00622782141712,
          105.82818602043712,
          21.006228134412403
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82818602043712,
            21.006228134412403
          ],
          [
            105.82806632691438,
            21.00622782141712
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 95/8 Chùa Bộc",
        "diemDau": "cột đỡ",
        "diemCuoi": "8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.006228134412403,
        "to_latitude": 21.00622782141712,
        "to_longitude": 105.82806632691438,
        "from_longitude": 105.82818602043712,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5971,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82780078822218,
          21.00622782141712,
          105.82806632691438,
          21.006345507177233
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82806632691438,
            21.00622782141712
          ],
          [
            105.82780078822218,
            21.006345507177233
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 95/8 Chùa Bộc",
        "diemDau": "8",
        "diemCuoi": "9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.00622782141712,
        "to_latitude": 21.006345507177233,
        "to_longitude": 105.82780078822218,
        "from_longitude": 105.82806632691438,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5972,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8275419550493,
          21.006345507177233,
          105.82780078822218,
          21.006463192845352
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82780078822218,
            21.006345507177233
          ],
          [
            105.8275419550493,
            21.006463192845352
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 95/8 Chùa Bộc",
        "diemDau": "9",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.006345507177233,
        "to_latitude": 21.006463192845352,
        "to_longitude": 105.8275419550493,
        "from_longitude": 105.82780078822218,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5973,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82701758319318,
          21.006463192845352,
          105.8275419550493,
          21.006703571793246
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8275419550493,
            21.006463192845352
          ],
          [
            105.82701758319318,
            21.006703571793246
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 65,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 95/8 Chùa Bộc",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.006463192845352,
        "to_latitude": 21.006703571793246,
        "to_longitude": 105.82701758319318,
        "from_longitude": 105.8275419550493,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5974,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82662329846646,
          21.006703571793246,
          105.82701758319318,
          21.006880099836565
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82701758319318,
            21.006703571793246
          ],
          [
            105.82662329846646,
            21.006880099836565
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 95/8 Chùa Bộc",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.006703571793246,
        "to_latitude": 21.006880099836565,
        "to_longitude": 105.82662329846646,
        "from_longitude": 105.82701758319318,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5975,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82634837203817,
          21.006880099836565,
          105.82662329846646,
          21.006997785082213
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82662329846646,
            21.006880099836565
          ],
          [
            105.82634837203817,
            21.006997785082213
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 95/8 Chùa Bộc",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.006880099836565,
        "to_latitude": 21.006997785082213,
        "to_longitude": 105.82634837203817,
        "from_longitude": 105.82662329846646,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5976,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82613513641488,
          21.006997785082213,
          105.82634837203817,
          21.007109210390905
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82634837203817,
            21.006997785082213
          ],
          [
            105.82613513641488,
            21.007109210390905
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 95/8 Chùa Bộc",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.006997785082213,
        "to_latitude": 21.007109210390905,
        "to_longitude": 105.82613513641488,
        "from_longitude": 105.82634837203817,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5977,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8258132713421,
          21.007109210390905,
          105.82613513641488,
          21.007245674977607
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82613513641488,
            21.007109210390905
          ],
          [
            105.8258132713421,
            21.007245674977607
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 95/8 Chùa Bộc",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007109210390905,
        "to_latitude": 21.007245674977607,
        "to_longitude": 105.8258132713421,
        "from_longitude": 105.82613513641488,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5978,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82556650811307,
          21.007245674977607,
          105.8258132713421,
          21.007363359935724
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8258132713421,
            21.007245674977607
          ],
          [
            105.82556650811307,
            21.007363359935724
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 95/8 Chùa Bộc",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007245674977607,
        "to_latitude": 21.007363359935724,
        "to_longitude": 105.82556650811307,
        "from_longitude": 105.8258132713421,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5979,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82530901604406,
          21.007363359935724,
          105.82556650811307,
          21.007481044801004
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82556650811307,
            21.007363359935724
          ],
          [
            105.82530901604406,
            21.007481044801004
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 95/8 Chùa Bộc",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007363359935724,
        "to_latitude": 21.007481044801004,
        "to_longitude": 105.82530901604406,
        "from_longitude": 105.82556650811307,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5980,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83504317683143,
          21.0139275922434,
          105.83513238119207,
          21.014025168821732
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83504317683143,
            21.0139275922434
          ],
          [
            105.83513238119207,
            21.014025168821732
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 104 La Thành",
        "diemDau": "15",
        "diemCuoi": "15-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.0139275922434,
        "to_latitude": 21.014025168821732,
        "to_longitude": 105.83513238119207,
        "from_longitude": 105.83504317683143,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5981,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83513238119207,
          21.014025168821732,
          105.83519004868546,
          21.0141528637136
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83513238119207,
            21.014025168821732
          ],
          [
            105.83519004868546,
            21.0141528637136
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 104 La Thành",
        "diemDau": "15-1",
        "diemCuoi": "15-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.014025168821732,
        "to_latitude": 21.0141528637136,
        "to_longitude": 105.83519004868546,
        "from_longitude": 105.83513238119207,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5982,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82493216564968,
          21.007481044801004,
          105.82530901604406,
          21.00765131210483
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82530901604406,
            21.007481044801004
          ],
          [
            105.82493216564968,
            21.00765131210483
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 95/8 Chùa Bộc",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007481044801004,
        "to_latitude": 21.00765131210483,
        "to_longitude": 105.82493216564968,
        "from_longitude": 105.82530901604406,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5983,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82493216564968,
          21.00765131210483,
          105.8250354307286,
          21.00783910669113
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82493216564968,
            21.00765131210483
          ],
          [
            105.8250354307286,
            21.00783910669113
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 95/8 Chùa Bộc",
        "diemDau": "18",
        "diemCuoi": "18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.00765131210483,
        "to_latitude": 21.00783910669113,
        "to_longitude": 105.8250354307286,
        "from_longitude": 105.82493216564968,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5984,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82490400248636,
          21.00783910669113,
          105.8250354307286,
          21.00796179902987
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8250354307286,
            21.00783910669113
          ],
          [
            105.82490400248636,
            21.00796179902987
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 95/8 Chùa Bộc",
        "diemDau": "18-1",
        "diemCuoi": "18-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.00783910669113,
        "to_latitude": 21.00796179902987,
        "to_longitude": 105.82490400248636,
        "from_longitude": 105.8250354307286,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5985,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8349278375955,
          21.013810148167035,
          105.83504317683143,
          21.0139275922434
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83504317683143,
            21.0139275922434
          ],
          [
            105.8349278375955,
            21.013810148167035
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 104 La Thành",
        "diemDau": "15",
        "diemCuoi": "15-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.0139275922434,
        "to_latitude": 21.013810148167035,
        "to_longitude": 105.8349278375955,
        "from_longitude": 105.83504317683143,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5986,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.828825727289,
          21.02724734159008,
          105.82901214080793,
          21.027380032354255
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.828825727289,
            21.02734623377384
          ],
          [
            105.82889412361337,
            21.027380032354255
          ],
          [
            105.82901214080793,
            21.02724734159008
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127 An Trạch",
        "diemDau": "3",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.02734623377384,
        "to_latitude": 21.02724734159008,
        "to_longitude": 105.82901214080793,
        "from_longitude": 105.828825727289,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5987,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82901214080793,
          21.026964434471093,
          105.82923208195055,
          21.02724734159008
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82901214080793,
            21.02724734159008
          ],
          [
            105.82923208195055,
            21.026964434471093
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CL3",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 127 An Trạch",
        "diemDau": "3-1",
        "diemCuoi": "3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Cát Linh 3",
        "from_latitude": 21.02724734159008,
        "to_latitude": 21.026964434471093,
        "to_longitude": 105.82923208195055,
        "from_longitude": 105.82901214080793,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5988,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83482526826187,
          21.014032680289596,
          105.8348635802899,
          21.014145684697276
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8348635802899,
            21.014145684697276
          ],
          [
            105.83482526826187,
            21.014032680289596
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 La Thành",
        "diemDau": "16",
        "diemCuoi": "16-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.014145684697276,
        "to_latitude": 21.014032680289596,
        "to_longitude": 105.83482526826187,
        "from_longitude": 105.8348635802899,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5989,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83461924900827,
          21.014032680289596,
          105.83482526826187,
          21.01413539525583
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83482526826187,
            21.014032680289596
          ],
          [
            105.83461924900827,
            21.01413539525583
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CNC",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 39 La Thành",
        "diemDau": "16-1",
        "diemCuoi": "16-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CNC",
        "from_latitude": 21.014032680289596,
        "to_latitude": 21.01413539525583,
        "to_longitude": 105.83461924900827,
        "from_longitude": 105.83482526826187,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5990,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82833823588368,
          21.00732360997281,
          105.82865071322036,
          21.007451310599663
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82865071322036,
            21.00732360997281
          ],
          [
            105.82833823588368,
            21.007451310599663
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "phố Chùa Bộc",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TL3",
        "from_latitude": 21.00732360997281,
        "to_latitude": 21.007451310599663,
        "to_longitude": 105.82833823588368,
        "from_longitude": 105.82865071322036,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5991,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82805123951155,
          21.007451310599663,
          105.82833823588368,
          21.00758401897938
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82833823588368,
            21.007451310599663
          ],
          [
            105.82805123951155,
            21.00758401897938
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "phố Chùa Bộc",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TL3",
        "from_latitude": 21.007451310599663,
        "to_latitude": 21.00758401897938,
        "to_longitude": 105.82805123951155,
        "from_longitude": 105.82833823588368,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5992,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82773339775937,
          21.00758401897938,
          105.82805123951155,
          21.0077292468806
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82805123951155,
            21.00758401897938
          ],
          [
            105.82773339775937,
            21.0077292468806
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "phố Chùa Bộc",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TL3",
        "from_latitude": 21.00758401897938,
        "to_latitude": 21.0077292468806,
        "to_longitude": 105.82773339775937,
        "from_longitude": 105.82805123951155,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5993,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82739275721444,
          21.0077292468806,
          105.82773339775937,
          21.00789200212114
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82773339775937,
            21.0077292468806
          ],
          [
            105.82739275721444,
            21.00789200212114
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "phố Chùa Bộc",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TL3",
        "from_latitude": 21.0077292468806,
        "to_latitude": 21.00789200212114,
        "to_longitude": 105.82739275721444,
        "from_longitude": 105.82773339775937,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5994,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82717683937443,
          21.00789200212114,
          105.82739275721444,
          21.0079696237913
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82739275721444,
            21.00789200212114
          ],
          [
            105.82717683937443,
            21.0079696237913
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "phố Chùa Bộc",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TL3",
        "from_latitude": 21.00789200212114,
        "to_latitude": 21.0079696237913,
        "to_longitude": 105.82717683937443,
        "from_longitude": 105.82739275721444,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5995,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82682546999851,
          21.0079696237913,
          105.82717683937443,
          21.008136134651554
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82717683937443,
            21.0079696237913
          ],
          [
            105.82682546999851,
            21.008136134651554
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "phố Chùa Bộc",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TL3",
        "from_latitude": 21.0079696237913,
        "to_latitude": 21.008136134651554,
        "to_longitude": 105.82682546999851,
        "from_longitude": 105.82717683937443,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5996,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82653310921094,
          21.008136134651554,
          105.82682546999851,
          21.008262582623892
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82682546999851,
            21.008136134651554
          ],
          [
            105.82653310921094,
            21.008262582623892
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "phố Chùa Bộc",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TL3",
        "from_latitude": 21.008136134651554,
        "to_latitude": 21.008262582623892,
        "to_longitude": 105.82653310921094,
        "from_longitude": 105.82682546999851,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5997,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82627964046252,
          21.008262582623892,
          105.82653310921094,
          21.008371503069377
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82653310921094,
            21.008262582623892
          ],
          [
            105.82627964046252,
            21.008371503069377
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "phố Chùa Bộc",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TL3",
        "from_latitude": 21.008262582623892,
        "to_latitude": 21.008371503069377,
        "to_longitude": 105.82627964046252,
        "from_longitude": 105.82653310921094,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5998,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82605969931988,
          21.008371503069377,
          105.82627964046252,
          21.00847666756209
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82627964046252,
            21.008371503069377
          ],
          [
            105.82605969931988,
            21.00847666756209
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "phố Chùa Bộc",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TL3",
        "from_latitude": 21.008371503069377,
        "to_latitude": 21.00847666756209,
        "to_longitude": 105.82605969931988,
        "from_longitude": 105.82627964046252,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 5999,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82585585144166,
          21.00847666756209,
          105.82605969931988,
          21.008569312410167
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82605969931988,
            21.00847666756209
          ],
          [
            105.82585585144166,
            21.008569312410167
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "phố Chùa Bộc",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TL3",
        "from_latitude": 21.00847666756209,
        "to_latitude": 21.008569312410167,
        "to_longitude": 105.82585585144166,
        "from_longitude": 105.82605969931988,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6000,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82566005017767,
          21.008569312410167,
          105.82585585144166,
          21.00865319350671
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82585585144166,
            21.008569312410167
          ],
          [
            105.82566005017767,
            21.00865319350671
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "phố Chùa Bộc",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TL3",
        "from_latitude": 21.008569312410167,
        "to_latitude": 21.00865319350671,
        "to_longitude": 105.82566005017767,
        "from_longitude": 105.82585585144166,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6001,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82550984648124,
          21.00865319350671,
          105.82566005017767,
          21.00870953153056
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82566005017767,
            21.00865319350671
          ],
          [
            105.82550984648124,
            21.00870953153056
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "phố Chùa Bộc",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TL3",
        "from_latitude": 21.00865319350671,
        "to_latitude": 21.00870953153056,
        "to_longitude": 105.82550984648124,
        "from_longitude": 105.82566005017767,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6002,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82529795195283,
          21.00870953153056,
          105.82550984648124,
          21.008808436009655
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82550984648124,
            21.00870953153056
          ],
          [
            105.82529795195283,
            21.008808436009655
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "phố Chùa Bộc",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TL3",
        "from_latitude": 21.00870953153056,
        "to_latitude": 21.008808436009655,
        "to_longitude": 105.82529795195283,
        "from_longitude": 105.82550984648124,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6003,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80184941011383,
          21.019862476222002,
          105.80187924969518,
          21.01996168626868
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80185242759981,
            21.019862476222002
          ],
          [
            105.80187924969518,
            21.019895024636977
          ],
          [
            105.80184941011383,
            21.01996168626868
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "17",
        "diemCuoi": "tủ điện",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.019862476222002,
        "to_latitude": 21.01996168626868,
        "to_longitude": 105.80184941011383,
        "from_longitude": 105.80185242759981,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6004,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80185242759981,
          21.019681269052167,
          105.80201034265022,
          21.01986372808448
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80185242759981,
            21.019862476222002
          ],
          [
            105.80189131963002,
            21.01986372808448
          ],
          [
            105.80201034265022,
            21.019681269052167
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "tủ điện",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.019862476222002,
        "to_latitude": 21.019681269052167,
        "to_longitude": 105.80201034265022,
        "from_longitude": 105.80185242759981,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6005,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80155839044808,
          21.01996168626868,
          105.80184941011383,
          21.020297184746354
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80184941011383,
            21.01996168626868
          ],
          [
            105.80169115979417,
            21.020209952213825
          ],
          [
            105.80160264690275,
            21.020164885272106
          ],
          [
            105.80155839044808,
            21.020242500552953
          ],
          [
            105.80165092664211,
            21.020297184746354
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 75,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.01996168626868,
        "to_latitude": 21.020297184746354,
        "to_longitude": 105.80165092664211,
        "from_longitude": 105.80184941011383,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6006,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80148462968127,
          21.020297184746354,
          105.80165092664211,
          21.020602637927684
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80165092664211,
            21.020297184746354
          ],
          [
            105.80148462968127,
            21.020602637927684
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.020297184746354,
        "to_latitude": 21.020602637927684,
        "to_longitude": 105.80148462968127,
        "from_longitude": 105.80165092664211,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6007,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8013397904003,
          21.020602637927684,
          105.80148462968127,
          21.020842994089374
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80148462968127,
            21.020602637927684
          ],
          [
            105.8013397904003,
            21.020842994089374
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.020602637927684,
        "to_latitude": 21.020842994089374,
        "to_longitude": 105.8013397904003,
        "from_longitude": 105.80148462968127,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6008,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80121640878129,
          21.020842994089374,
          105.8013397904003,
          21.021058312822007
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8013397904003,
            21.020842994089374
          ],
          [
            105.80121640878129,
            21.021058312822007
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.020842994089374,
        "to_latitude": 21.021058312822007,
        "to_longitude": 105.80121640878129,
        "from_longitude": 105.8013397904003,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6009,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80104474740496,
          21.021058312822007,
          105.80121640878129,
          21.02133372004998
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80121640878129,
            21.021058312822007
          ],
          [
            105.80104474740496,
            21.02133372004998
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.021058312822007,
        "to_latitude": 21.02133372004998,
        "to_longitude": 105.80104474740496,
        "from_longitude": 105.80121640878129,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6010,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80091063695498,
          21.02133372004998,
          105.80104474740496,
          21.021589097206263
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80104474740496,
            21.02133372004998
          ],
          [
            105.80091063695498,
            21.021589097206263
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.02133372004998,
        "to_latitude": 21.021589097206263,
        "to_longitude": 105.80091063695498,
        "from_longitude": 105.80104474740496,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6011,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80066923814141,
          21.021589097206263,
          105.80091063695498,
          21.02190956988261
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80091063695498,
            21.021589097206263
          ],
          [
            105.80066923814141,
            21.02190956988261
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.021589097206263,
        "to_latitude": 21.02190956988261,
        "to_longitude": 105.80066923814141,
        "from_longitude": 105.80091063695498,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6012,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80042783933683,
          21.02190956988261,
          105.80066923814141,
          21.022169953424182
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80066923814141,
            21.02190956988261
          ],
          [
            105.80042783933683,
            21.022169953424182
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.02190956988261,
        "to_latitude": 21.022169953424182,
        "to_longitude": 105.80042783933683,
        "from_longitude": 105.80066923814141,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6013,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80022935586511,
          21.022169953424182,
          105.80042783933683,
          21.02236524078255
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80042783933683,
            21.022169953424182
          ],
          [
            105.80022935586511,
            21.02236524078255
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.022169953424182,
        "to_latitude": 21.02236524078255,
        "to_longitude": 105.80022935586511,
        "from_longitude": 105.80042783933683,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6014,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79996649938063,
          21.02236524078255,
          105.80022935586511,
          21.022625623528295
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80022935586511,
            21.02236524078255
          ],
          [
            105.79996649938063,
            21.022625623528295
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.02236524078255,
        "to_latitude": 21.022625623528295,
        "to_longitude": 105.79996649938063,
        "from_longitude": 105.80022935586511,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6015,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79969827848066,
          21.022625623528295,
          105.79996649938063,
          21.022840939686418
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79996649938063,
            21.022625623528295
          ],
          [
            105.79969827848066,
            21.022840939686418
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.022625623528295,
        "to_latitude": 21.022840939686418,
        "to_longitude": 105.79969827848066,
        "from_longitude": 105.79996649938063,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6016,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79945151525162,
          21.022840939686418,
          105.79969827848066,
          21.023046240849638
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79969827848066,
            21.022840939686418
          ],
          [
            105.79945151525162,
            21.023046240849638
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.022840939686418,
        "to_latitude": 21.023046240849638,
        "to_longitude": 105.79945151525162,
        "from_longitude": 105.79969827848066,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6017,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79914071428803,
          21.023046240849638,
          105.79945151525162,
          21.023318827783292
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79945151525162,
            21.023046240849638
          ],
          [
            105.79914071428803,
            21.023318827783292
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.023046240849638,
        "to_latitude": 21.023318827783292,
        "to_longitude": 105.79914071428803,
        "from_longitude": 105.79945151525162,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6018,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.7989207731454,
          21.023318827783292,
          105.79914071428803,
          21.02351160997308
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79914071428803,
            21.023318827783292
          ],
          [
            105.7989207731454,
            21.02351160997308
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.023318827783292,
        "to_latitude": 21.02351160997308,
        "to_longitude": 105.7989207731454,
        "from_longitude": 105.79914071428803,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6019,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.798667304397,
          21.02351160997308,
          105.7989207731454,
          21.023726924850752
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.7989207731454,
            21.02351160997308
          ],
          [
            105.798667304397,
            21.023726924850752
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.02351160997308,
        "to_latitude": 21.023726924850752,
        "to_longitude": 105.798667304397,
        "from_longitude": 105.7989207731454,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6020,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79842691140908,
          21.023726924850752,
          105.798667304397,
          21.02394755968498
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.798667304397,
            21.023726924850752
          ],
          [
            105.79842691140908,
            21.02394755968498
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.023726924850752,
        "to_latitude": 21.02394755968498,
        "to_longitude": 105.79842691140908,
        "from_longitude": 105.798667304397,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6021,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79819624143548,
          21.023717223167925,
          105.79841081815364,
          21.023907501185516
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79819624143548,
            21.023907501185516
          ],
          [
            105.79841081815364,
            21.023717223167925
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.023907501185516,
        "to_latitude": 21.023717223167925,
        "to_longitude": 105.79841081815364,
        "from_longitude": 105.79819624143548,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6022,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79841081815364,
          21.02350190827538,
          105.79869513230905,
          21.023717223167925
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79841081815364,
            21.023717223167925
          ],
          [
            105.79869513230905,
            21.02350190827538
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.023717223167925,
        "to_latitude": 21.02350190827538,
        "to_longitude": 105.79869513230905,
        "from_longitude": 105.79841081815364,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6023,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79869513230905,
          21.02330662240596,
          105.79892580228265,
          21.02350190827538
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79869513230905,
            21.02350190827538
          ],
          [
            105.79892580228265,
            21.02330662240596
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.02350190827538,
        "to_latitude": 21.02330662240596,
        "to_longitude": 105.79892580228265,
        "from_longitude": 105.79869513230905,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6024,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79892580228265,
          21.023081292239652,
          105.799220845278,
          21.02330662240596
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79892580228265,
            21.02330662240596
          ],
          [
            105.799220845278,
            21.023081292239652
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.02330662240596,
        "to_latitude": 21.023081292239652,
        "to_longitude": 105.799220845278,
        "from_longitude": 105.79892580228265,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6025,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.799220845278,
          21.02287098377675,
          105.79949443059346,
          21.023081292239652
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.799220845278,
            21.023081292239652
          ],
          [
            105.79949443059346,
            21.02287098377675
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.023081292239652,
        "to_latitude": 21.02287098377675,
        "to_longitude": 105.79949443059346,
        "from_longitude": 105.799220845278,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6026,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79949443059346,
          21.022640645595487,
          105.79976801591789,
          21.02287098377675
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79949443059346,
            21.02287098377675
          ],
          [
            105.79976801591789,
            21.022640645595487
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.02287098377675,
        "to_latitude": 21.022640645595487,
        "to_longitude": 105.79976801591789,
        "from_longitude": 105.79949443059346,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6027,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79976801591789,
          21.02242532914891,
          105.80002014356242,
          21.022640645595487
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79976801591789,
            21.022640645595487
          ],
          [
            105.80002014356242,
            21.02242532914891
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.022640645595487,
        "to_latitude": 21.02242532914891,
        "to_longitude": 105.80002014356242,
        "from_longitude": 105.79976801591789,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6028,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80002014356242,
          21.022205005020233,
          105.80021326260969,
          21.02242532914891
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80002014356242,
            21.02242532914891
          ],
          [
            105.80021326260969,
            21.022205005020233
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.02242532914891,
        "to_latitude": 21.022205005020233,
        "to_longitude": 105.80021326260969,
        "from_longitude": 105.80002014356242,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6029,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80021326260969,
          21.021934606781265,
          105.80046002583873,
          21.022205005020233
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80021326260969,
            21.022205005020233
          ],
          [
            105.80046002583873,
            21.021934606781265
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.022205005020233,
        "to_latitude": 21.021934606781265,
        "to_longitude": 105.80046002583873,
        "from_longitude": 105.80021326260969,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6030,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80046002583873,
          21.021639171107818,
          105.80071751790771,
          21.021934606781265
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80046002583873,
            21.021934606781265
          ],
          [
            105.80071751790771,
            21.021639171107818
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.021934606781265,
        "to_latitude": 21.021639171107818,
        "to_longitude": 105.80071751790771,
        "from_longitude": 105.80046002583873,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6031,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80071751790771,
          21.021368771842628,
          105.8008784504441,
          21.021639171107818
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80071751790771,
            21.021639171107818
          ],
          [
            105.8008784504441,
            21.021368771842628
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.021639171107818,
        "to_latitude": 21.021368771842628,
        "to_longitude": 105.8008784504441,
        "from_longitude": 105.80071751790771,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6032,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8008784504441,
          21.021098372087042,
          105.80102328973405,
          21.021368771842628
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8008784504441,
            21.021368771842628
          ],
          [
            105.80102328973405,
            21.021098372087042
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.021368771842628,
        "to_latitude": 21.021098372087042,
        "to_longitude": 105.80102328973405,
        "from_longitude": 105.8008784504441,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6033,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80102328973405,
          21.020883053412298,
          105.80114667135305,
          21.021098372087042
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80102328973405,
            21.021098372087042
          ],
          [
            105.80114667135305,
            21.020883053412298
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.021098372087042,
        "to_latitude": 21.020883053412298,
        "to_longitude": 105.80114667135305,
        "from_longitude": 105.80102328973405,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6034,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80114667135305,
          21.02063268246913,
          105.801291510634,
          21.020883053412298
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80114667135305,
            21.020883053412298
          ],
          [
            105.801291510634,
            21.02063268246913
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.020883053412298,
        "to_latitude": 21.02063268246913,
        "to_longitude": 105.801291510634,
        "from_longitude": 105.80114667135305,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6035,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.801291510634,
          21.02037730367382,
          105.8014685364348,
          21.02063268246913
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.801291510634,
            21.02063268246913
          ],
          [
            105.8014685364348,
            21.02037730367382
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.02063268246913,
        "to_latitude": 21.02037730367382,
        "to_longitude": 105.8014685364348,
        "from_longitude": 105.801291510634,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6036,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8014685364348,
          21.019946663931584,
          105.80169920640841,
          21.02037730367382
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8014685364348,
            21.02037730367382
          ],
          [
            105.80169920640841,
            21.019946663931584
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 80,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.02037730367382,
        "to_latitude": 21.019946663931584,
        "to_longitude": 105.80169920640841,
        "from_longitude": 105.8014685364348,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6037,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80169920640841,
          21.01985120946264,
          105.80185242759981,
          21.019946663931584
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80169920640841,
            21.019946663931584
          ],
          [
            105.80175050366067,
            21.01985120946264
          ],
          [
            105.80185242759981,
            21.019862476222002
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "17",
        "diemCuoi": "Tủ điện",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.019946663931584,
        "to_latitude": 21.019862476222002,
        "to_longitude": 105.80185242759981,
        "from_longitude": 105.80169920640841,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6038,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80180817115406,
          21.01976639576294,
          105.80185242759981,
          21.019862476222002
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80185242759981,
            21.019862476222002
          ],
          [
            105.80180817115406,
            21.019824920351365
          ],
          [
            105.80183868127389,
            21.01976639576294
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "tủ điện",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.019862476222002,
        "to_latitude": 21.01976639576294,
        "to_longitude": 105.80183868127389,
        "from_longitude": 105.80185242759981,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6039,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80183868127389,
          21.01950100056268,
          105.80201034265022,
          21.01976639576294
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80183868127389,
            21.01976639576294
          ],
          [
            105.80201034265022,
            21.01950100056268
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.01976639576294,
        "to_latitude": 21.01950100056268,
        "to_longitude": 105.80201034265022,
        "from_longitude": 105.80183868127389,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6040,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80201034265022,
          21.019255634768317,
          105.80210690217386,
          21.01950100056268
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80201034265022,
            21.01950100056268
          ],
          [
            105.80210690217386,
            21.019255634768317
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.01950100056268,
        "to_latitude": 21.019255634768317,
        "to_longitude": 105.80210690217386,
        "from_longitude": 105.80201034265022,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6041,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80210690217386,
          21.018985231180537,
          105.80224637704829,
          21.019255634768317
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80210690217386,
            21.019255634768317
          ],
          [
            105.80224637704829,
            21.018985231180537
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.019255634768317,
        "to_latitude": 21.018985231180537,
        "to_longitude": 105.80224637704829,
        "from_longitude": 105.80210690217386,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6042,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80224637704829,
          21.018684782174528,
          105.8024019451692,
          21.018985231180537
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80224637704829,
            21.018985231180537
          ],
          [
            105.8024019451692,
            21.018684782174528
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.018985231180537,
        "to_latitude": 21.018684782174528,
        "to_longitude": 105.8024019451692,
        "from_longitude": 105.80224637704829,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6043,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8024019451692,
          21.018439415037108,
          105.80252532677925,
          21.018684782174528
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8024019451692,
            21.018684782174528
          ],
          [
            105.80252532677925,
            21.018439415037108
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.018684782174528,
        "to_latitude": 21.018439415037108,
        "to_longitude": 105.80252532677925,
        "from_longitude": 105.8024019451692,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6044,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80252532677925,
          21.01817401747498,
          105.80267016606918,
          21.018439415037108
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80252532677925,
            21.018439415037108
          ],
          [
            105.80267016606918,
            21.01817401747498
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.018439415037108,
        "to_latitude": 21.01817401747498,
        "to_longitude": 105.80267016606918,
        "from_longitude": 105.80252532677925,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6045,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80267016606918,
          21.01787857434946,
          105.80282573419007,
          21.01817401747498
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80267016606918,
            21.01817401747498
          ],
          [
            105.80282573419007,
            21.01787857434946
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.01817401747498,
        "to_latitude": 21.01787857434946,
        "to_longitude": 105.80282573419007,
        "from_longitude": 105.80267016606918,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6046,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80282573419007,
          21.01759345866012,
          105.80296973529317,
          21.01787857434946
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80282573419007,
            21.01787857434946
          ],
          [
            105.80296973529317,
            21.01759345866012
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.01787857434946,
        "to_latitude": 21.01759345866012,
        "to_longitude": 105.80296973529317,
        "from_longitude": 105.80282573419007,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6047,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80296973529317,
          21.01733275415536,
          105.80313687044085,
          21.01759345866012
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80296973529317,
            21.01759345866012
          ],
          [
            105.80313687044085,
            21.01733275415536
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.01759345866012,
        "to_latitude": 21.01733275415536,
        "to_longitude": 105.80313687044085,
        "from_longitude": 105.80296973529317,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6048,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80313687044085,
          21.01706234708069,
          105.80333535390359,
          21.01733275415536
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80313687044085,
            21.01733275415536
          ],
          [
            105.80333535390359,
            21.01706234708069
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.01733275415536,
        "to_latitude": 21.01706234708069,
        "to_longitude": 105.80333535390359,
        "from_longitude": 105.80313687044085,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6049,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80333535390359,
          21.01680696217151,
          105.80351774411989,
          21.01706234708069
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80333535390359,
            21.01706234708069
          ],
          [
            105.80351774411989,
            21.01680696217151
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.01706234708069,
        "to_latitude": 21.01680696217151,
        "to_longitude": 105.80351774411989,
        "from_longitude": 105.80333535390359,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6050,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80351774411989,
          21.016561591944296,
          105.80370013433617,
          21.01680696217151
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80351774411989,
            21.01680696217151
          ],
          [
            105.80370013433617,
            21.016561591944296
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.01680696217151,
        "to_latitude": 21.016561591944296,
        "to_longitude": 105.80370013433617,
        "from_longitude": 105.80351774411989,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6051,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80370013433617,
          21.01631121374514,
          105.80390934663886,
          21.016561591944296
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80370013433617,
            21.016561591944296
          ],
          [
            105.80390934663886,
            21.01631121374514
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.016561591944296,
        "to_latitude": 21.01631121374514,
        "to_longitude": 105.80390934663886,
        "from_longitude": 105.80370013433617,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6052,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80390934663886,
          21.01606083512642,
          105.8041024656861,
          21.01631121374514
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80390934663886,
            21.01631121374514
          ],
          [
            105.8041024656861,
            21.01606083512642
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.01631121374514,
        "to_latitude": 21.01606083512642,
        "to_longitude": 105.8041024656861,
        "from_longitude": 105.80390934663886,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6053,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8041024656861,
          21.01582547884039,
          105.80430631357333,
          21.01606083512642
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8041024656861,
            21.01606083512642
          ],
          [
            105.80430631357333,
            21.01582547884039
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.01606083512642,
        "to_latitude": 21.01582547884039,
        "to_longitude": 105.80430631357333,
        "from_longitude": 105.8041024656861,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6054,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80201034265022,
          21.019425888627328,
          105.80213908868471,
          21.019681269052167
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80201034265022,
            21.019681269052167
          ],
          [
            105.80213908868471,
            21.019425888627328
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.019681269052167,
        "to_latitude": 21.019425888627328,
        "to_longitude": 105.80213908868471,
        "from_longitude": 105.80201034265022,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6055,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80213908868471,
          21.019170507765953,
          105.8022678347192,
          21.019425888627328
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80213908868471,
            21.019425888627328
          ],
          [
            105.8022678347192,
            21.019170507765953
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.019425888627328,
        "to_latitude": 21.019170507765953,
        "to_longitude": 105.8022678347192,
        "from_longitude": 105.80213908868471,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6056,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8022678347192,
          21.018890089060648,
          105.80240730958468,
          21.019170507765953
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8022678347192,
            21.019170507765953
          ],
          [
            105.80240730958468,
            21.018890089060648
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.019170507765953,
        "to_latitude": 21.018890089060648,
        "to_longitude": 105.80240730958468,
        "from_longitude": 105.8022678347192,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6057,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80240730958468,
          21.018619684809988,
          105.80255214887461,
          21.018890089060648
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80240730958468,
            21.018890089060648
          ],
          [
            105.80255214887461,
            21.018619684809988
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.018890089060648,
        "to_latitude": 21.018619684809988,
        "to_longitude": 105.80255214887461,
        "from_longitude": 105.80240730958468,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6058,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80255214887461,
          21.018354287568716,
          105.80270235258003,
          21.018619684809988
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80255214887461,
            21.018619684809988
          ],
          [
            105.80270235258003,
            21.018354287568716
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.018619684809988,
        "to_latitude": 21.018354287568716,
        "to_longitude": 105.80270235258003,
        "from_longitude": 105.80255214887461,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6059,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80270235258003,
          21.018068468607748,
          105.80285129899693,
          21.018354287568716
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80270235258003,
            21.018354287568716
          ],
          [
            105.80285129899693,
            21.018068468607748
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.018354287568716,
        "to_latitude": 21.018068468607748,
        "to_longitude": 105.80285129899693,
        "from_longitude": 105.80270235258003,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6060,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80285129899693,
          21.017788439042302,
          105.80300275999086,
          21.018068468607748
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80285129899693,
            21.018068468607748
          ],
          [
            105.80300275999086,
            21.017788439042302
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.018068468607748,
        "to_latitude": 21.017788439042302,
        "to_longitude": 105.80300275999086,
        "from_longitude": 105.80285129899693,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6061,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80300275999086,
          21.017513025266062,
          105.80315832811178,
          21.017788439042302
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80300275999086,
            21.017788439042302
          ],
          [
            105.80315832811178,
            21.017513025266062
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.017788439042302,
        "to_latitude": 21.017513025266062,
        "to_longitude": 105.80315832811178,
        "from_longitude": 105.80300275999086,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6062,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80315832811178,
          21.01726765620143,
          105.80334071832809,
          21.017513025266062
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80315832811178,
            21.017513025266062
          ],
          [
            105.80334071832809,
            21.01726765620143
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.017513025266062,
        "to_latitude": 21.01726765620143,
        "to_longitude": 105.80334071832809,
        "from_longitude": 105.80315832811178,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6063,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80334071832809,
          21.017007264098133,
          105.80354993063075,
          21.01726765620143
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80334071832809,
            21.01726765620143
          ],
          [
            105.80354993063075,
            21.017007264098133
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.01726765620143,
        "to_latitude": 21.017007264098133,
        "to_longitude": 105.80354993063075,
        "from_longitude": 105.80334071832809,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6064,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80354993063075,
          21.016746871540988,
          105.80374841409349,
          21.017007264098133
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80354993063075,
            21.017007264098133
          ],
          [
            105.80374841409349,
            21.016746871540988
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.017007264098133,
        "to_latitude": 21.016746871540988,
        "to_longitude": 105.80374841409349,
        "from_longitude": 105.80354993063075,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6065,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80374841409349,
          21.0164864785292,
          105.80394153314073,
          21.016746871540988
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80374841409349,
            21.016746871540988
          ],
          [
            105.80394153314073,
            21.0164864785292
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.016746871540988,
        "to_latitude": 21.0164864785292,
        "to_longitude": 105.80394153314073,
        "from_longitude": 105.80374841409349,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6066,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80394153314073,
          21.01622608506189,
          105.80414538102795,
          21.0164864785292
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80394153314073,
            21.0164864785292
          ],
          [
            105.80414538102795,
            21.01622608506189
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.0164864785292,
        "to_latitude": 21.01622608506189,
        "to_longitude": 105.80414538102795,
        "from_longitude": 105.80394153314073,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6067,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80414538102795,
          21.016000744194454,
          105.80436263996285,
          21.01622608506189
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80414538102795,
            21.01622608506189
          ],
          [
            105.80436263996285,
            21.016000744194454
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.01622608506189,
        "to_latitude": 21.016000744194454,
        "to_longitude": 105.80436263996285,
        "from_longitude": 105.80414538102795,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6068,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80436263996285,
          21.015767891608096,
          105.80458258109651,
          21.016000744194454
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80436263996285,
            21.016000744194454
          ],
          [
            105.80458258109651,
            21.015767891608096
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C361",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng - Bờ trái sông Tô Lịch",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C 361",
        "from_latitude": 21.016000744194454,
        "to_latitude": 21.015767891608096,
        "to_longitude": 105.80458258109651,
        "from_longitude": 105.80436263996285,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6069,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82773339775937,
          21.0077292468806,
          105.82801972347625,
          21.007805616677686
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82773339775937,
            21.0077292468806
          ],
          [
            105.82801972347625,
            21.007805616677686
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 12 Chùa Bộc",
        "diemDau": "22",
        "diemCuoi": "cột đỡ",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.0077292468806,
        "to_latitude": 21.007805616677686,
        "to_longitude": 105.82801972347625,
        "from_longitude": 105.82773339775937,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6070,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8279741259267,
          21.007805616677686,
          105.82801972347625,
          21.00792830904146
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82801972347625,
            21.007805616677686
          ],
          [
            105.8279741259267,
            21.00792830904146
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 12 Chùa Bộc",
        "diemDau": "cột đỡ",
        "diemCuoi": "22-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007805616677686,
        "to_latitude": 21.00792830904146,
        "to_longitude": 105.8279741259267,
        "from_longitude": 105.82801972347625,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6071,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8279741259267,
          21.00792830904146,
          105.82823698241116,
          21.00829388161749
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8279741259267,
            21.00792830904146
          ],
          [
            105.82823698241116,
            21.00829388161749
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 52,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 12 Chùa Bộc",
        "diemDau": "22-1",
        "diemCuoi": "cột đỡ",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.00792830904146,
        "to_latitude": 21.00829388161749,
        "to_longitude": 105.82823698241116,
        "from_longitude": 105.8279741259267,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6072,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82809482532898,
          21.00829388161749,
          105.82823698241116,
          21.00835647957072
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82823698241116,
            21.00829388161749
          ],
          [
            105.82809482532898,
            21.00835647957072
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 12 Chùa Bộc",
        "diemDau": "cột đỡ",
        "diemCuoi": "22-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.00829388161749,
        "to_latitude": 21.00835647957072,
        "to_longitude": 105.82809482532898,
        "from_longitude": 105.82823698241116,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6073,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82809482532898,
          21.00835647957072,
          105.82825039344986,
          21.00873582260781
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82809482532898,
            21.00835647957072
          ],
          [
            105.82825039344986,
            21.00873582260781
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 12 Chùa Bộc",
        "diemDau": "22-2",
        "diemCuoi": "cột đỡ",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.00835647957072,
        "to_latitude": 21.00873582260781,
        "to_longitude": 105.82825039344986,
        "from_longitude": 105.82809482532898,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6074,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82801435906077,
          21.00873582260781,
          105.82825039344986,
          21.008823459475366
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82825039344986,
            21.00873582260781
          ],
          [
            105.82801435906077,
            21.008823459475366
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 12 Chùa Bộc",
        "diemDau": "cột đỡ",
        "diemCuoi": "22-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.00873582260781,
        "to_latitude": 21.008823459475366,
        "to_longitude": 105.82801435906077,
        "from_longitude": 105.82825039344986,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6075,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82785074430767,
          21.008823459475366,
          105.82801435906077,
          21.008931127557137
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82801435906077,
            21.008823459475366
          ],
          [
            105.82785074430767,
            21.008931127557137
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 12 Chùa Bộc",
        "diemDau": "22-3",
        "diemCuoi": "22-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.008823459475366,
        "to_latitude": 21.008931127557137,
        "to_longitude": 105.82785074430767,
        "from_longitude": 105.82801435906077,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6076,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82785074430767,
          21.008931127557137,
          105.82793523390545,
          21.009181518142086
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82785074430767,
            21.008931127557137
          ],
          [
            105.82793523390545,
            21.009181518142086
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 12 Chùa Bộc",
        "diemDau": "22-4",
        "diemCuoi": "22-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.008931127557137,
        "to_latitude": 21.009181518142086,
        "to_longitude": 105.82793523390545,
        "from_longitude": 105.82785074430767,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6077,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82793523390545,
          21.009181518142086,
          105.82805727441159,
          21.00938558616029
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82793523390545,
            21.009181518142086
          ],
          [
            105.82805727441159,
            21.00938558616029
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 12 Chùa Bộc",
        "diemDau": "22-5",
        "diemCuoi": "22-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.009181518142086,
        "to_latitude": 21.00938558616029,
        "to_longitude": 105.82805727441159,
        "from_longitude": 105.82793523390545,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6078,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82805727441159,
          21.00938558616029,
          105.82817797382287,
          21.00962721234821
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82805727441159,
            21.00938558616029
          ],
          [
            105.82817797382287,
            21.00962721234821
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 12 Chùa Bộc",
        "diemDau": "22-1",
        "diemCuoi": "22-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.00938558616029,
        "to_latitude": 21.00962721234821,
        "to_longitude": 105.82817797382287,
        "from_longitude": 105.82805727441159,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6079,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82682546999851,
          21.008136134651544,
          105.82718287427447,
          21.00838402266064
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82682546999851,
            21.008136134651544
          ],
          [
            105.8270219417291,
            21.00824505519349
          ],
          [
            105.82708363253413,
            21.008213756196376
          ],
          [
            105.82718287427447,
            21.00838402266064
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 14 Chùa Bộc",
        "diemDau": "25",
        "diemCuoi": "25-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.008136134651544,
        "to_latitude": 21.00838402266064,
        "to_longitude": 105.82718287427447,
        "from_longitude": 105.82682546999851,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6080,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82648013548675,
          21.008262582623892,
          105.82663033919216,
          21.008717043271098
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82653310921094,
            21.008262582623892
          ],
          [
            105.82658474164258,
            21.00844161274444
          ],
          [
            105.82648013548675,
            21.00848918714452
          ],
          [
            105.82663033919216,
            21.008717043271098
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 20 Chùa Bộc",
        "diemDau": "26",
        "diemCuoi": "26-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.008262582623892,
        "to_latitude": 21.008717043271098,
        "to_longitude": 105.82663033919216,
        "from_longitude": 105.82653310921094,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6081,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82604427651307,
          21.00847666756209,
          105.82613144830063,
          21.008783396913227
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82605969931988,
            21.00847666756209
          ],
          [
            105.8261140139503,
            21.008648185687107
          ],
          [
            105.82604427651307,
            21.008685744372993
          ],
          [
            105.82613144830063,
            21.008783396913227
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 60 Chùa Bộc",
        "diemDau": "28",
        "diemCuoi": "28-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.00847666756209,
        "to_latitude": 21.008783396913227,
        "to_longitude": 105.82613144830063,
        "from_longitude": 105.82605969931988,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6082,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82566072062632,
          21.008685744372993,
          105.82604427651307,
          21.008977449846455
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82604427651307,
            21.008685744372993
          ],
          [
            105.82566072062632,
            21.0088635220259
          ],
          [
            105.82574789241384,
            21.008977449846455
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 80 Chùa Bộc",
        "diemDau": "cột đỡ",
        "diemCuoi": "28-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.008685744372993,
        "to_latitude": 21.008977449846455,
        "to_longitude": 105.82574789241384,
        "from_longitude": 105.82604427651307,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6083,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82639665175057,
          21.008173380482678,
          105.82653310921094,
          21.008262582623892
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82653310921094,
            21.008262582623892
          ],
          [
            105.82639665175057,
            21.008173380482678
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 203 Chùa Bộc",
        "diemDau": "26-1",
        "diemCuoi": "26-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.008262582623892,
        "to_latitude": 21.008173380482678,
        "to_longitude": 105.82639665175057,
        "from_longitude": 105.82653310921094,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6084,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82639665175057,
          21.008093254999338,
          105.82644761371564,
          21.008173380482678
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82639665175057,
            21.008173380482678
          ],
          [
            105.82644761371564,
            21.008093254999338
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 203 Chùa Bộc",
        "diemDau": "26-1",
        "diemCuoi": "26-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.008173380482678,
        "to_latitude": 21.008093254999338,
        "to_longitude": 105.82644761371564,
        "from_longitude": 105.82639665175057,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6085,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82628701645744,
          21.008093254999338,
          105.82644761371564,
          21.00819873267764
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82644761371564,
            21.008093254999338
          ],
          [
            105.82628701645744,
            21.00819873267764
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 203 Chùa Bộc",
        "diemDau": "26-1",
        "diemCuoi": "26-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.008093254999338,
        "to_latitude": 21.00819873267764,
        "to_longitude": 105.82628701645744,
        "from_longitude": 105.82644761371564,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6086,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82630545664243,
          21.00778527227138,
          105.82644761371564,
          21.008093254999338
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82644761371564,
            21.008093254999338
          ],
          [
            105.82630545664243,
            21.00778527227138
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 203 Chùa Bộc",
        "diemDau": "26-1",
        "diemCuoi": "26-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.008093254999338,
        "to_latitude": 21.00778527227138,
        "to_longitude": 105.82630545664243,
        "from_longitude": 105.82644761371564,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6087,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82615391183313,
          21.007583705994556,
          105.82630545664243,
          21.00778527227138
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82630545664243,
            21.00778527227138
          ],
          [
            105.82615391183313,
            21.007583705994556
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 203 Chùa Bộc",
        "diemDau": "26-1",
        "diemCuoi": "26-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.00778527227138,
        "to_latitude": 21.007583705994556,
        "to_longitude": 105.82615391183313,
        "from_longitude": 105.82630545664243,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6088,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82609926182568,
          21.007364924899353,
          105.82615391183313,
          21.007583705994556
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82615391183313,
            21.007583705994556
          ],
          [
            105.82609926182568,
            21.007364924899353
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 203 Chùa Bộc",
        "diemDau": "26-1",
        "diemCuoi": "26-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007583705994556,
        "to_latitude": 21.007364924899353,
        "to_longitude": 105.82609926182568,
        "from_longitude": 105.82615391183313,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6089,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82587395626754,
          21.007364924899353,
          105.82609926182568,
          21.007507649084022
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82609926182568,
            21.007364924899353
          ],
          [
            105.82587395626754,
            21.007507649084022
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 203 Chùa Bộc",
        "diemDau": "26-1",
        "diemCuoi": "26-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007364924899353,
        "to_latitude": 21.007507649084022,
        "to_longitude": 105.82587395626754,
        "from_longitude": 105.82609926182568,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6090,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82587395626754,
          21.007507649084022,
          105.82602952438846,
          21.007815632385057
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82587395626754,
            21.007507649084022
          ],
          [
            105.82602952438846,
            21.007815632385057
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 203 Chùa Bộc",
        "diemDau": "26-1",
        "diemCuoi": "26-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007507649084022,
        "to_latitude": 21.007815632385057,
        "to_longitude": 105.82602952438846,
        "from_longitude": 105.82587395626754,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6091,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82570229489123,
          21.007507649084022,
          105.82587395626754,
          21.007582767021407
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82587395626754,
            21.007507649084022
          ],
          [
            105.82570229489123,
            21.007582767021407
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 203 Chùa Bộc",
        "diemDau": "26-1",
        "diemCuoi": "26-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007507649084022,
        "to_latitude": 21.007582767021407,
        "to_longitude": 105.82570229489123,
        "from_longitude": 105.82587395626754,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6092,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82570229489123,
          21.007582767021407,
          105.82589273173073,
          21.007870718762856
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82570229489123,
            21.007582767021407
          ],
          [
            105.82589273173073,
            21.007870718762856
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 203 Chùa Bộc",
        "diemDau": "26-1",
        "diemCuoi": "26-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007582767021407,
        "to_latitude": 21.007870718762856,
        "to_longitude": 105.82589273173073,
        "from_longitude": 105.82570229489123,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6093,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82589273173073,
          21.007870718762856,
          105.82601343113302,
          21.00812611897236
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82589273173073,
            21.007870718762856
          ],
          [
            105.82601343113302,
            21.00812611897236
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 203 Chùa Bộc",
        "diemDau": "26-1",
        "diemCuoi": "26-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007870718762856,
        "to_latitude": 21.00812611897236,
        "to_longitude": 105.82601343113302,
        "from_longitude": 105.82589273173073,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6094,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83658602852613,
          21.010639408992525,
          105.83672013897612,
          21.01079339749001
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83658602852613,
            21.01079339749001
          ],
          [
            105.83672013897612,
            21.010639408992525
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "3",
        "diemCuoi": "5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1KL",
        "from_latitude": 21.01079339749001,
        "to_latitude": 21.010639408992525,
        "to_longitude": 105.83672013897612,
        "from_longitude": 105.83658602852613,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6095,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83672013897612,
          21.010639408992525,
          105.83695885558194,
          21.01080716881148
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83672013897612,
            21.010639408992525
          ],
          [
            105.83695885558194,
            21.01080716881148
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "3",
        "diemCuoi": "4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1KL",
        "from_latitude": 21.010639408992525,
        "to_latitude": 21.01080716881148,
        "to_longitude": 105.83695885558194,
        "from_longitude": 105.83672013897612,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6096,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83668057639846,
          21.010392776677065,
          105.83681669850873,
          21.010639408992525
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83672013897612,
            21.010639408992525
          ],
          [
            105.83668057639846,
            21.010584949606784
          ],
          [
            105.83681669850873,
            21.010392776677065
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "2",
        "diemCuoi": "3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1KL",
        "from_latitude": 21.010639408992525,
        "to_latitude": 21.010392776677065,
        "to_longitude": 105.83681669850873,
        "from_longitude": 105.83672013897612,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6097,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83658602852613,
          21.01079339749001,
          105.8367925586211,
          21.010992455556508
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8367925586211,
            21.010992455556508
          ],
          [
            105.83658602852613,
            21.01079339749001
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1KL",
        "from_latitude": 21.010992455556508,
        "to_latitude": 21.01079339749001,
        "to_longitude": 105.83658602852613,
        "from_longitude": 105.8367925586211,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6098,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83661620336773,
          21.010392776677065,
          105.83681669850873,
          21.0103934026468
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83681669850873,
            21.010392776677065
          ],
          [
            105.83661620336773,
            21.0103934026468
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "2",
        "diemCuoi": "7",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1KL",
        "from_latitude": 21.010392776677065,
        "to_latitude": 21.0103934026468,
        "to_longitude": 105.83661620336773,
        "from_longitude": 105.83681669850873,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6099,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83681669850873,
          21.01019121392395,
          105.83699908871603,
          21.010392776677065
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83681669850873,
            21.010392776677065
          ],
          [
            105.83699908871603,
            21.01019121392395
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1KL",
        "from_latitude": 21.010392776677065,
        "to_latitude": 21.01019121392395,
        "to_longitude": 105.83699908871603,
        "from_longitude": 105.83681669850873,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6100,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8363372536368,
          21.01036210410039,
          105.83661620336773,
          21.010404670122334
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83661620336773,
            21.0103934026468
          ],
          [
            105.83653707823036,
            21.010404670122334
          ],
          [
            105.83644990644282,
            21.010394654588747
          ],
          [
            105.8363372536368,
            21.01036210410039
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "7",
        "diemCuoi": "8",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1KL",
        "from_latitude": 21.0103934026468,
        "to_latitude": 21.01036210410039,
        "to_longitude": 105.8363372536368,
        "from_longitude": 105.83661620336773,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6101,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8360086830267,
          21.01010420382994,
          105.8363372536368,
          21.01036210410039
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8363372536368,
            21.01036210410039
          ],
          [
            105.8360086830267,
            21.01010420382994
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "8",
        "diemCuoi": "9",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1KL",
        "from_latitude": 21.01036210410039,
        "to_latitude": 21.01010420382994,
        "to_longitude": 105.8360086830267,
        "from_longitude": 105.8363372536368,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6102,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8357646020144,
          21.00989888484944,
          105.8360086830267,
          21.01010420382994
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8360086830267,
            21.01010420382994
          ],
          [
            105.8357646020144,
            21.00989888484944
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "9",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1KL",
        "from_latitude": 21.01010420382994,
        "to_latitude": 21.00989888484944,
        "to_longitude": 105.8357646020144,
        "from_longitude": 105.8360086830267,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6103,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83546151239582,
          21.009638479882458,
          105.8357646020144,
          21.00989888484944
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8357646020144,
            21.00989888484944
          ],
          [
            105.83546151239582,
            21.009638479882458
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1KL",
        "from_latitude": 21.00989888484944,
        "to_latitude": 21.009638479882458,
        "to_longitude": 105.83546151239582,
        "from_longitude": 105.8357646020144,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6104,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83518792707137,
          21.009424396612296,
          105.83546151239582,
          21.009638479882458
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83546151239582,
            21.009638479882458
          ],
          [
            105.83518792707137,
            21.009424396612296
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1KL",
        "from_latitude": 21.009638479882458,
        "to_latitude": 21.009424396612296,
        "to_longitude": 105.83518792707137,
        "from_longitude": 105.83546151239582,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6105,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83491685632904,
          21.00919951495318,
          105.83518792707137,
          21.009424396612296
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83518792707137,
            21.009424396612296
          ],
          [
            105.83491685632904,
            21.00919951495318
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1KL",
        "from_latitude": 21.009424396612296,
        "to_latitude": 21.00919951495318,
        "to_longitude": 105.83491685632904,
        "from_longitude": 105.83518792707137,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6106,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83465282637532,
          21.008982457661716,
          105.83491685632904,
          21.00919951495318
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83491685632904,
            21.00919951495318
          ],
          [
            105.83465282637532,
            21.008982457661716
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1KL",
        "from_latitude": 21.00919951495318,
        "to_latitude": 21.008982457661716,
        "to_longitude": 105.83465282637532,
        "from_longitude": 105.83491685632904,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6107,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8344260120877,
          21.00879513399478,
          105.83465282637532,
          21.008982457661716
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83465282637532,
            21.008982457661716
          ],
          [
            105.8344260120877,
            21.00879513399478
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1KL",
        "from_latitude": 21.008982457661716,
        "to_latitude": 21.00879513399478,
        "to_longitude": 105.8344260120877,
        "from_longitude": 105.83465282637532,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6108,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8342089207874,
          21.008609375035665,
          105.8344260120877,
          21.00879513399478
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8344260120877,
            21.00879513399478
          ],
          [
            105.8342089207874,
            21.008609375035665
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1KL",
        "from_latitude": 21.00879513399478,
        "to_latitude": 21.008609375035665,
        "to_longitude": 105.8342089207874,
        "from_longitude": 105.8344260120877,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6109,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83395344038769,
          21.008385900600956,
          105.8342089207874,
          21.008609375035665
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8342089207874,
            21.008609375035665
          ],
          [
            105.83395344038769,
            21.008385900600956
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B1KL",
        "from_latitude": 21.008609375035665,
        "to_latitude": 21.008385900600956,
        "to_longitude": 105.83395344038769,
        "from_longitude": 105.8342089207874,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6110,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83462902179285,
          21.00879513399478,
          105.83475458270676,
          21.008982457661716
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83465282637532,
            21.008982457661716
          ],
          [
            105.83462902179285,
            21.008925806752174
          ],
          [
            105.83475458270676,
            21.00879513399478
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 65 Phạm Ngọc Thạch",
        "diemDau": "14",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.008982457661716,
        "to_latitude": 21.00879513399478,
        "to_longitude": 105.83475458270676,
        "from_longitude": 105.83465282637532,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6111,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83475458270676,
          21.00856978190117,
          105.83494770175402,
          21.00879513399478
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83475458270676,
            21.00879513399478
          ],
          [
            105.83494770175402,
            21.00856978190117
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 65 Phạm Ngọc Thạch",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.00879513399478,
        "to_latitude": 21.00856978190117,
        "to_longitude": 105.83494770175402,
        "from_longitude": 105.83475458270676,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6112,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83494770175402,
          21.008336917714576,
          105.83518641835086,
          21.00856978190117
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83494770175402,
            21.00856978190117
          ],
          [
            105.83518641835086,
            21.008336917714576
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 65 Phạm Ngọc Thạch",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.00856978190117,
        "to_latitude": 21.008336917714576,
        "to_longitude": 105.83518641835086,
        "from_longitude": 105.83494770175402,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6113,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83518641835086,
          21.00819419432319,
          105.83536076194392,
          21.008336917714576
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83518641835086,
            21.008336917714576
          ],
          [
            105.83536076194392,
            21.00819419432319
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 65 Phạm Ngọc Thạch",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.008336917714576,
        "to_latitude": 21.00819419432319,
        "to_longitude": 105.83536076194392,
        "from_longitude": 105.83518641835086,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6114,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83499329930359,
          21.00815913943417,
          105.83518641835086,
          21.008336917714576
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83518641835086,
            21.008336917714576
          ],
          [
            105.83499329930359,
            21.00815913943417
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 65 Phạm Ngọc Thạch",
        "diemDau": "25",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.008336917714576,
        "to_latitude": 21.00815913943417,
        "to_longitude": 105.83499329930359,
        "from_longitude": 105.83518641835086,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6115,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8347358072436,
          21.00794129815449,
          105.83499329930359,
          21.00815913943417
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83499329930359,
            21.00815913943417
          ],
          [
            105.8347358072436,
            21.00794129815449
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 65 Phạm Ngọc Thạch",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.00815913943417,
        "to_latitude": 21.00794129815449,
        "to_longitude": 105.8347358072436,
        "from_longitude": 105.83499329930359,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6116,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83509254104395,
          21.007971345246485,
          105.83536076194392,
          21.00819419432319
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83536076194392,
            21.00819419432319
          ],
          [
            105.83509254104395,
            21.007971345246485
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 65 Phạm Ngọc Thạch",
        "diemDau": "26",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.00819419432319,
        "to_latitude": 21.007971345246485,
        "to_longitude": 105.83509254104395,
        "from_longitude": 105.83536076194392,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6117,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83485114223039,
          21.007725960484617,
          105.83509254104395,
          21.007971345246485
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83509254104395,
            21.007971345246485
          ],
          [
            105.83485114223039,
            21.007725960484617
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 65 Phạm Ngọc Thạch",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.007971345246485,
        "to_latitude": 21.007725960484617,
        "to_longitude": 105.83485114223039,
        "from_longitude": 105.83509254104395,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6118,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83536076194392,
          21.00803644725853,
          105.83548682576169,
          21.00819419432319
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83536076194392,
            21.00819419432319
          ],
          [
            105.83548682576169,
            21.00803644725853
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 65 Phạm Ngọc Thạch",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.00819419432319,
        "to_latitude": 21.00803644725853,
        "to_longitude": 105.83548682576169,
        "from_longitude": 105.83536076194392,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6119,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83401831631329,
          21.008609375035665,
          105.8342089207874,
          21.00868245799052
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8342089207874,
            21.008609375035665
          ],
          [
            105.83401831631329,
            21.00868245799052
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "16",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.008609375035665,
        "to_latitude": 21.00868245799052,
        "to_longitude": 105.83401831631329,
        "from_longitude": 105.8342089207874,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6120,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83391153088048,
          21.00868245799052,
          105.83401831631329,
          21.00881563475704
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83401831631329,
            21.00868245799052
          ],
          [
            105.83391153088048,
            21.00881563475704
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "18",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.00868245799052,
        "to_latitude": 21.00881563475704,
        "to_longitude": 105.83391153088048,
        "from_longitude": 105.83401831631329,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6121,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83391153088048,
          21.00881563475704,
          105.8340424562009,
          21.00892032945517
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83391153088048,
            21.00881563475704
          ],
          [
            105.8340424562009,
            21.00892032945517
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "",
        "diemCuoi": "19-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.00881563475704,
        "to_latitude": 21.00892032945517,
        "to_longitude": 105.8340424562009,
        "from_longitude": 105.83391153088048,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6122,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83380105738736,
          21.00881563475704,
          105.83391153088048,
          21.008927841179233
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83391153088048,
            21.00881563475704
          ],
          [
            105.83380105738736,
            21.008927841179233
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.00881563475704,
        "to_latitude": 21.008927841179233,
        "to_longitude": 105.83380105738736,
        "from_longitude": 105.83391153088048,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6123,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8340424562009,
          21.00892032945517,
          105.8342838550055,
          21.00911563416379
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8340424562009,
            21.00892032945517
          ],
          [
            105.8342838550055,
            21.00911563416379
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "19-3",
        "diemCuoi": "19-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.00892032945517,
        "to_latitude": 21.00911563416379,
        "to_longitude": 105.8342838550055,
        "from_longitude": 105.8340424562009,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6124,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8342838550055,
          21.00911563416379,
          105.83451988940358,
          21.009303426911117
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8342838550055,
            21.00911563416379
          ],
          [
            105.83451988940358,
            21.009303426911117
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "19-4",
        "diemCuoi": "19-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.00911563416379,
        "to_latitude": 21.009303426911117,
        "to_longitude": 105.83451988940358,
        "from_longitude": 105.8342838550055,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6125,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83370583897032,
          21.00864114343458,
          105.83391153088048,
          21.00881563475704
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83391153088048,
            21.00881563475704
          ],
          [
            105.83370583897032,
            21.00864114343458
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "",
        "diemCuoi": "19-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.00881563475704,
        "to_latitude": 21.00864114343458,
        "to_longitude": 105.83370583897032,
        "from_longitude": 105.83391153088048,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6126,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83341079597497,
          21.00839575977433,
          105.83370583897032,
          21.00864114343458
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83370583897032,
            21.00864114343458
          ],
          [
            105.83341079597497,
            21.00839575977433
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "19-1",
        "diemCuoi": "19-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.00864114343458,
        "to_latitude": 21.00839575977433,
        "to_longitude": 105.83341079597497,
        "from_longitude": 105.83370583897032,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6127,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83367683760424,
          21.008927841179233,
          105.83380105738736,
          21.009047246244016
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83380105738736,
            21.008927841179233
          ],
          [
            105.83367683760424,
            21.009047246244016
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "19",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.008927841179233,
        "to_latitude": 21.009047246244016,
        "to_longitude": 105.83367683760424,
        "from_longitude": 105.83380105738736,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6128,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83367683760424,
          21.009047246244016,
          105.83383324389823,
          21.00917572786873
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83367683760424,
            21.009047246244016
          ],
          [
            105.83383324389823,
            21.00917572786873
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "",
        "diemCuoi": "20-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009047246244016,
        "to_latitude": 21.00917572786873,
        "to_longitude": 105.83383324389823,
        "from_longitude": 105.83367683760424,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6129,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83354356531835,
          21.00894787244266,
          105.83367683760424,
          21.009047246244016
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83367683760424,
            21.009047246244016
          ],
          [
            105.83354356531835,
            21.00894787244266
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "",
        "diemCuoi": "20-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009047246244016,
        "to_latitude": 21.00894787244266,
        "to_longitude": 105.83354356531835,
        "from_longitude": 105.83367683760424,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6130,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83329411988159,
          21.008742551869943,
          105.83354356531835,
          21.00894787244266
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83354356531835,
            21.00894787244266
          ],
          [
            105.83329411988159,
            21.008742551869943
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "20-1",
        "diemCuoi": "20-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.00894787244266,
        "to_latitude": 21.008742551869943,
        "to_longitude": 105.83329411988159,
        "from_longitude": 105.83354356531835,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6131,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83356770520601,
          21.009047246244016,
          105.83367683760424,
          21.009193255193985
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83367683760424,
            21.009047246244016
          ],
          [
            105.83356770520601,
            21.009193255193985
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009047246244016,
        "to_latitude": 21.009193255193985,
        "to_longitude": 105.83356770520601,
        "from_longitude": 105.83367683760424,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6132,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83383324389823,
          21.00917572786873,
          105.83401295189779,
          21.009325962025155
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83383324389823,
            21.00917572786873
          ],
          [
            105.83401295189779,
            21.009325962025155
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "20-3",
        "diemCuoi": "20-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.00917572786873,
        "to_latitude": 21.009325962025155,
        "to_longitude": 105.83401295189779,
        "from_longitude": 105.83383324389823,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6133,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83401295189779,
          21.009325962025155,
          105.83426507954233,
          21.009518762304168
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83401295189779,
            21.009325962025155
          ],
          [
            105.83426507954233,
            21.009518762304168
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "20-4",
        "diemCuoi": "20-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009325962025155,
        "to_latitude": 21.009518762304168,
        "to_longitude": 105.83426507954233,
        "from_longitude": 105.83401295189779,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6134,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83333435301567,
          21.009193255193985,
          105.83356770520601,
          21.009433629743416
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83356770520601,
            21.009193255193985
          ],
          [
            105.83333435301567,
            21.009433629743416
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009193255193985,
        "to_latitude": 21.009433629743416,
        "to_longitude": 105.83333435301567,
        "from_longitude": 105.83356770520601,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6135,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83328121177367,
          21.009466649913314,
          105.83344968800247,
          21.009613910402177
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83328121177367,
            21.009466649913314
          ],
          [
            105.83344968800247,
            21.009613910402177
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "21",
        "diemCuoi": "21-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009466649913314,
        "to_latitude": 21.009613910402177,
        "to_longitude": 105.83344968800247,
        "from_longitude": 105.83328121177367,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6136,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83344968800247,
          21.009613910402177,
          105.83362671380328,
          21.009756632434538
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83344968800247,
            21.009613910402177
          ],
          [
            105.83362671380328,
            21.009756632434538
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "21-1",
        "diemCuoi": "21-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009613910402177,
        "to_latitude": 21.009756632434538,
        "to_longitude": 105.83362671380328,
        "from_longitude": 105.83344968800247,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6137,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83314928060062,
          21.009598887021934,
          105.8333504462711,
          21.009806710309064
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83314928060062,
            21.009598887021934
          ],
          [
            105.8333504462711,
            21.009806710309064
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "22",
        "diemCuoi": "22-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009598887021934,
        "to_latitude": 21.009806710309064,
        "to_longitude": 105.8333504462711,
        "from_longitude": 105.83314928060062,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6138,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8333504462711,
          21.009806710309064,
          105.83356770520601,
          21.009989494406014
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8333504462711,
            21.009806710309064
          ],
          [
            105.83356770520601,
            21.009989494406014
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "22-3",
        "diemCuoi": "22-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009806710309064,
        "to_latitude": 21.009989494406014,
        "to_longitude": 105.83356770520601,
        "from_longitude": 105.8333504462711,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6139,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83356770520601,
          21.009989494406014,
          105.83378680813067,
          21.010181511364692
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83356770520601,
            21.009989494406014
          ],
          [
            105.83378680813067,
            21.010181511364692
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "22-1",
        "diemCuoi": "22-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009989494406014,
        "to_latitude": 21.010181511364692,
        "to_longitude": 105.83378680813067,
        "from_longitude": 105.83356770520601,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6140,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.832918610636,
          21.009416102444696,
          105.83314928060062,
          21.009598887021934
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83314928060062,
            21.009598887021934
          ],
          [
            105.832918610636,
            21.009416102444696
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "22",
        "diemCuoi": "22-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009598887021934,
        "to_latitude": 21.009416102444696,
        "to_longitude": 105.832918610636,
        "from_longitude": 105.83314928060062,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6141,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83266111856697,
          21.009228309839223,
          105.832918610636,
          21.009416102444696
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.832918610636,
            21.009416102444696
          ],
          [
            105.83266111856697,
            21.009228309839223
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "22-4",
        "diemCuoi": "22-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009416102444696,
        "to_latitude": 21.009228309839223,
        "to_longitude": 105.83266111856697,
        "from_longitude": 105.832918610636,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6142,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83240245300179,
          21.00903754360785,
          105.83266111856697,
          21.009228309839223
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83266111856697,
            21.009228309839223
          ],
          [
            105.83240245300179,
            21.00903754360785
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "22-5",
        "diemCuoi": "22-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009228309839223,
        "to_latitude": 21.00903754360785,
        "to_longitude": 105.83240245300179,
        "from_longitude": 105.83266111856697,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6143,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83362671380328,
          21.009756632434538,
          105.83397640680609,
          21.009961638623373
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83362671380328,
            21.009756632434538
          ],
          [
            105.83397640680609,
            21.009961638623373
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "diemDau": "21-2",
        "diemCuoi": "21-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009756632434538,
        "to_latitude": 21.009961638623373,
        "to_longitude": 105.83397640680609,
        "from_longitude": 105.83362671380328,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6144,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83397640680609,
          21.009961638623373,
          105.83413733935146,
          21.01018698861456
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83397640680609,
            21.009961638623373
          ],
          [
            105.83413733935146,
            21.01018698861456
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46B Phạm Ngọc Thạch",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009961638623373,
        "to_latitude": 21.01018698861456,
        "to_longitude": 105.83413733935146,
        "from_longitude": 105.83397640680609,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6145,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83397640680609,
          21.009961638623373,
          105.83422048782738,
          21.00997415807614
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83397640680609,
            21.009961638623373
          ],
          [
            105.83422048782738,
            21.00997415807614
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46B Phạm Ngọc Thạch",
        "diemDau": "21-3",
        "diemCuoi": "21-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009961638623373,
        "to_latitude": 21.00997415807614,
        "to_longitude": 105.83422048782738,
        "from_longitude": 105.83397640680609,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6146,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83422048782738,
          21.00997415807614,
          105.83435677757227,
          21.0100938752907
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83422048782738,
            21.00997415807614
          ],
          [
            105.83435677757227,
            21.0100938752907
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46B Phạm Ngọc Thạch",
        "diemDau": "21-5",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.00997415807614,
        "to_latitude": 21.0100938752907,
        "to_longitude": 105.83435677757227,
        "from_longitude": 105.83422048782738,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6147,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83435677757227,
          21.0100938752907,
          105.83451016640724,
          21.010207019708087
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83435677757227,
            21.0100938752907
          ],
          [
            105.83451016640724,
            21.010207019708087
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46B Phạm Ngọc Thạch",
        "diemDau": "",
        "diemCuoi": "21-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.0100938752907,
        "to_latitude": 21.010207019708087,
        "to_longitude": 105.83451016640724,
        "from_longitude": 105.83435677757227,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6148,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83405821418714,
          21.009840199877527,
          105.83422048782738,
          21.00997415807614
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83422048782738,
            21.00997415807614
          ],
          [
            105.83405821418714,
            21.009840199877527
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46B Phạm Ngọc Thạch",
        "diemDau": "21-5",
        "diemCuoi": "21-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.00997415807614,
        "to_latitude": 21.009840199877527,
        "to_longitude": 105.83405821418714,
        "from_longitude": 105.83422048782738,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6149,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83405821418714,
          21.009649903909484,
          105.83425133323438,
          21.009840199877527
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83405821418714,
            21.009840199877527
          ],
          [
            105.83425133323438,
            21.009649903909484
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46B Phạm Ngọc Thạch",
        "diemDau": "21-6",
        "diemCuoi": "21-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009840199877527,
        "to_latitude": 21.009649903909484,
        "to_longitude": 105.83425133323438,
        "from_longitude": 105.83405821418714,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6150,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83425133323438,
          21.009469623294258,
          105.83443372345067,
          21.009649903909484
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83425133323438,
            21.009649903909484
          ],
          [
            105.83443372345067,
            21.009469623294258
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46B Phạm Ngọc Thạch",
        "diemDau": "21-7",
        "diemCuoi": "21-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009649903909484,
        "to_latitude": 21.009469623294258,
        "to_longitude": 105.83443372345067,
        "from_longitude": 105.83425133323438,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6151,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83465366458432,
          21.009502173977353,
          105.83475022411693,
          21.009659919492243
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83475022411693,
            21.009659919492243
          ],
          [
            105.83465366458432,
            21.009502173977353
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "diemDau": "33-2",
        "diemCuoi": "33-2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009659919492243,
        "to_latitude": 21.009502173977353,
        "to_longitude": 105.83465366458432,
        "from_longitude": 105.83475022411693,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6152,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83475022411693,
          21.009659919492243,
          105.83494334316418,
          21.00981766484033
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83475022411693,
            21.009659919492243
          ],
          [
            105.83494334316418,
            21.00981766484033
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "diemDau": "33-1",
        "diemCuoi": "33-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009659919492243,
        "to_latitude": 21.00981766484033,
        "to_longitude": 105.83494334316418,
        "from_longitude": 105.83475022411693,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6153,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83494334316418,
          21.00981766484033,
          105.83509388213885,
          21.009943172429274
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83494334316418,
            21.00981766484033
          ],
          [
            105.83509388213885,
            21.009943172429274
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "diemDau": "33",
        "diemCuoi": "33-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.00981766484033,
        "to_latitude": 21.009943172429274,
        "to_longitude": 105.83509388213885,
        "from_longitude": 105.83494334316418,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6154,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83509388213885,
          21.009777915533007,
          105.83524542694813,
          21.009943172429274
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83509388213885,
            21.009943172429274
          ],
          [
            105.83524542694813,
            21.009777915533007
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009943172429274,
        "to_latitude": 21.009777915533007,
        "to_longitude": 105.83524542694813,
        "from_longitude": 105.83509388213885,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6155,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83524542694813,
          21.009638479882458,
          105.83546151239582,
          21.009777915533007
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83524542694813,
            21.009777915533007
          ],
          [
            105.83546151239582,
            21.009638479882458
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "diemDau": "11",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009777915533007,
        "to_latitude": 21.009638479882458,
        "to_longitude": 105.83546151239582,
        "from_longitude": 105.83524542694813,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6156,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83509388213885,
          21.009943172429274,
          105.83529370671442,
          21.0100683669271
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83509388213885,
            21.009943172429274
          ],
          [
            105.83529370671442,
            21.0100683669271
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "diemDau": "33",
        "diemCuoi": "33-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009943172429274,
        "to_latitude": 21.0100683669271,
        "to_longitude": 105.83529370671442,
        "from_longitude": 105.83509388213885,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6157,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83529370671442,
          21.0100683669271,
          105.83547877913847,
          21.010231119615828
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83529370671442,
            21.0100683669271
          ],
          [
            105.83547877913847,
            21.010231119615828
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "diemDau": "33-3",
        "diemCuoi": "33-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.0100683669271,
        "to_latitude": 21.010231119615828,
        "to_longitude": 105.83547877913847,
        "from_longitude": 105.83529370671442,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6158,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83495574838624,
          21.009943172429274,
          105.83509388213885,
          21.010093405813716
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83509388213885,
            21.009943172429274
          ],
          [
            105.83495574838624,
            21.010093405813716
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "diemDau": "33",
        "diemCuoi": "34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009943172429274,
        "to_latitude": 21.010093405813716,
        "to_longitude": 105.83495574838624,
        "from_longitude": 105.83509388213885,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6159,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83495574838624,
          21.010093405813716,
          105.83513545638577,
          21.010178537997117
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83495574838624,
            21.010093405813716
          ],
          [
            105.83513545638577,
            21.010178537997117
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "diemDau": "34",
        "diemCuoi": "34-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.010093405813716,
        "to_latitude": 21.010178537997117,
        "to_longitude": 105.83513545638577,
        "from_longitude": 105.83495574838624,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6160,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83513545638577,
          21.010178537997117,
          105.8352856600912,
          21.010306236181137
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83513545638577,
            21.010178537997117
          ],
          [
            105.8352856600912,
            21.010306236181137
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "diemDau": "34-1",
        "diemCuoi": "34-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.010178537997117,
        "to_latitude": 21.010306236181137,
        "to_longitude": 105.8352856600912,
        "from_longitude": 105.83513545638577,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6161,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83478140479318,
          21.010093405813716,
          105.83495574838624,
          21.01027618955939
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83495574838624,
            21.010093405813716
          ],
          [
            105.83478140479318,
            21.01027618955939
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.010093405813716,
        "to_latitude": 21.01027618955939,
        "to_longitude": 105.83478140479318,
        "from_longitude": 105.83495574838624,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6162,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83472776062037,
          21.01027618955939,
          105.83478140479318,
          21.010499035194115
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83478140479318,
            21.01027618955939
          ],
          [
            105.83472776062037,
            21.010499035194115
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "diemDau": "35",
        "diemCuoi": "36",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.01027618955939,
        "to_latitude": 21.010499035194115,
        "to_longitude": 105.83472776062037,
        "from_longitude": 105.83478140479318,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6163,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8346016967936,
          21.010479004138972,
          105.83472776062037,
          21.010499035194115
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83472776062037,
            21.010499035194115
          ],
          [
            105.8346016967936,
            21.010479004138972
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "diemDau": "36",
        "diemCuoi": "37",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.010499035194115,
        "to_latitude": 21.010479004138972,
        "to_longitude": 105.8346016967936,
        "from_longitude": 105.83472776062037,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6164,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83468752747729,
          21.010499035194115,
          105.83472776062037,
          21.01060169430669
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83472776062037,
            21.010499035194115
          ],
          [
            105.83468752747729,
            21.01060169430669
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "diemDau": "36",
        "diemCuoi": "36-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.010499035194115,
        "to_latitude": 21.01060169430669,
        "to_longitude": 105.83468752747729,
        "from_longitude": 105.83472776062037,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6165,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82594101149705,
          21.008341456063842,
          105.82605969931988,
          21.00847666756209
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82605969931988,
            21.00847666756209
          ],
          [
            105.82594101149705,
            21.008341456063842
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 231 Chùa Bộc",
        "diemDau": "28",
        "diemCuoi": "28-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.00847666756209,
        "to_latitude": 21.008341456063842,
        "to_longitude": 105.82594101149705,
        "from_longitude": 105.82605969931988,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6166,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82577203232844,
          21.008341456063842,
          105.82594101149705,
          21.00841907749769
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82594101149705,
            21.008341456063842
          ],
          [
            105.82577203232844,
            21.00841907749769
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 231 Chùa Bộc",
        "diemDau": "28-1",
        "diemCuoi": "28-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.008341456063842,
        "to_latitude": 21.00841907749769,
        "to_longitude": 105.82577203232844,
        "from_longitude": 105.82594101149705,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6167,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82558159548894,
          21.007682924210464,
          105.82594101149705,
          21.008341456063842
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82594101149705,
            21.008341456063842
          ],
          [
            105.82558159548894,
            21.007682924210464
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 88,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 231 Chùa Bộc",
        "diemDau": "28-1",
        "diemCuoi": "28-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.008341456063842,
        "to_latitude": 21.007682924210464,
        "to_longitude": 105.82558159548894,
        "from_longitude": 105.82594101149705,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6168,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82530901604406,
          21.007481044801004,
          105.82534019667538,
          21.007565239505265
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82530901604406,
            21.007481044801004
          ],
          [
            105.82534019667538,
            21.007565239505265
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 231 Chùa Bộc",
        "diemDau": "17",
        "diemCuoi": "17-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007481044801004,
        "to_latitude": 21.007565239505265,
        "to_longitude": 105.82534019667538,
        "from_longitude": 105.82530901604406,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6169,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82534019667538,
          21.007565239505265,
          105.825375065394,
          21.007707963498287
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82534019667538,
            21.007565239505265
          ],
          [
            105.825375065394,
            21.007707963498287
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 231 Chùa Bộc",
        "diemDau": "17-1",
        "diemCuoi": "17-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007565239505265,
        "to_latitude": 21.007707963498287,
        "to_longitude": 105.825375065394,
        "from_longitude": 105.82534019667538,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6170,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.825375065394,
          21.007707963498287,
          105.82545553166217,
          21.007855695206224
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.825375065394,
            21.007707963498287
          ],
          [
            105.82545553166217,
            21.007855695206224
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 231 Chùa Bộc",
        "diemDau": "17-2",
        "diemCuoi": "17-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007707963498287,
        "to_latitude": 21.007855695206224,
        "to_longitude": 105.82545553166217,
        "from_longitude": 105.825375065394,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6171,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8252677770304,
          21.007707963498287,
          105.825375065394,
          21.00772298706814
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.825375065394,
            21.007707963498287
          ],
          [
            105.8252677770304,
            21.00772298706814
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 231 Chùa Bộc",
        "diemDau": "17-2",
        "diemCuoi": "17-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007707963498287,
        "to_latitude": 21.00772298706814,
        "to_longitude": 105.8252677770304,
        "from_longitude": 105.825375065394,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6172,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83314928060062,
          21.009433629743416,
          105.83333435301567,
          21.009598887021934
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83333435301567,
            21.009433629743416
          ],
          [
            105.83328221757236,
            21.0094657109529
          ],
          [
            105.83314928060062,
            21.009598887021934
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B1KL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46c Phạm Ngọc Thạch",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B1KL",
        "from_latitude": 21.009433629743416,
        "to_latitude": 21.009598887021934,
        "to_longitude": 105.83314928060062,
        "from_longitude": 105.83333435301567,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6173,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82555376760382,
          21.01038057024346,
          105.82572140565956,
          21.01039434160222
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82555376760382,
            21.01038057024346
          ],
          [
            105.82572140565956,
            21.01039434160222
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "1",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.01038057024346,
        "to_latitude": 21.01039434160222,
        "to_longitude": 105.82572140565956,
        "from_longitude": 105.82555376760382,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6174,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82572140565956,
          21.01039434160222,
          105.82591318361193,
          21.010676028221805
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82572140565956,
            21.01039434160222
          ],
          [
            105.82591318361193,
            21.010676028221805
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.01039434160222,
        "to_latitude": 21.010676028221805,
        "to_longitude": 105.82591318361193,
        "from_longitude": 105.82572140565956,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6175,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82591318361193,
          21.01060717154029,
          105.82594671121768,
          21.010676028221805
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82591318361193,
            21.010676028221805
          ],
          [
            105.82594671121768,
            21.01060717154029
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "18",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.010676028221805,
        "to_latitude": 21.01060717154029,
        "to_longitude": 105.82594671121768,
        "from_longitude": 105.82591318361193,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6176,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82594671121768,
          21.010502008551104,
          105.82620152107896,
          21.01060717154029
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82594671121768,
            21.01060717154029
          ],
          [
            105.82620152107896,
            21.010502008551104
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.01060717154029,
        "to_latitude": 21.010502008551104,
        "to_longitude": 105.82620152107896,
        "from_longitude": 105.82594671121768,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6177,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82620152107896,
          21.010395593545027,
          105.82641878001385,
          21.010502008551104
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82620152107896,
            21.010502008551104
          ],
          [
            105.82641878001385,
            21.010395593545027
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.010502008551104,
        "to_latitude": 21.010395593545027,
        "to_longitude": 105.82641878001385,
        "from_longitude": 105.82620152107896,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6178,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82572140565956,
          21.010334248389007,
          105.82584478726959,
          21.01039434160222
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82572140565956,
            21.01039434160222
          ],
          [
            105.82584478726959,
            21.010334248389007
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.01039434160222,
        "to_latitude": 21.010334248389007,
        "to_longitude": 105.82584478726959,
        "from_longitude": 105.82572140565956,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6179,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82584478726959,
          21.010207802172474,
          105.82612910143396,
          21.010334248389007
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82584478726959,
            21.010334248389007
          ],
          [
            105.82612910143396,
            21.010207802172474
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "2",
        "diemCuoi": "3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.010334248389007,
        "to_latitude": 21.010207802172474,
        "to_longitude": 105.82612910143396,
        "from_longitude": 105.82584478726959,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6180,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82612910143396,
          21.010114219382164,
          105.82633529624174,
          21.010207802172474
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82612910143396,
            21.010207802172474
          ],
          [
            105.82633529624174,
            21.010114219382164
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "3",
        "diemCuoi": "4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.010207802172474,
        "to_latitude": 21.010114219382164,
        "to_longitude": 105.82633529624174,
        "from_longitude": 105.82612910143396,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6181,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82633529624174,
          21.010047553346375,
          105.82649254074471,
          21.010114219382164
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82633529624174,
            21.010114219382164
          ],
          [
            105.82649254074471,
            21.010047553346375
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "4",
        "diemCuoi": "5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.010114219382164,
        "to_latitude": 21.010047553346375,
        "to_longitude": 105.82649254074471,
        "from_longitude": 105.82633529624174,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6182,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82649254074471,
          21.01001375083712,
          105.82656898371026,
          21.010047553346375
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82649254074471,
            21.010047553346375
          ],
          [
            105.82656898371026,
            21.01001375083712
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "5",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.010047553346375,
        "to_latitude": 21.01001375083712,
        "to_longitude": 105.82656898371026,
        "from_longitude": 105.82649254074471,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6183,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82656898371026,
          21.01001375083712,
          105.82658775918243,
          21.01021406188826
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82656898371026,
            21.01001375083712
          ],
          [
            105.82658775918243,
            21.01021406188826
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.01001375083712,
        "to_latitude": 21.01021406188826,
        "to_longitude": 105.82658775918243,
        "from_longitude": 105.82656898371026,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6184,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82658775918243,
          21.01021406188826,
          105.8266977297448,
          21.010419380434957
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82658775918243,
            21.01021406188826
          ],
          [
            105.8266977297448,
            21.010419380434957
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.01021406188826,
        "to_latitude": 21.010419380434957,
        "to_longitude": 105.8266977297448,
        "from_longitude": 105.82658775918243,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6185,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8266977297448,
          21.010419380434957,
          105.82688414327268,
          21.01061718706047
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8266977297448,
            21.010419380434957
          ],
          [
            105.82688414327268,
            21.01061718706047
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "22",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.010419380434957,
        "to_latitude": 21.01061718706047,
        "to_longitude": 105.82688414327268,
        "from_longitude": 105.8266977297448,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6186,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8266977297448,
          21.010368050824788,
          105.82704105250643,
          21.010419380434957
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8266977297448,
            21.010419380434957
          ],
          [
            105.82704105250643,
            21.010368050824788
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.010419380434957,
        "to_latitude": 21.010368050824788,
        "to_longitude": 105.82704105250643,
        "from_longitude": 105.8266977297448,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6187,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82686402670564,
          21.01061718706047,
          105.82688414327268,
          21.01077618333508
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82688414327268,
            21.01061718706047
          ],
          [
            105.82686402670564,
            21.01077618333508
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.01061718706047,
        "to_latitude": 21.01077618333508,
        "to_longitude": 105.82686402670564,
        "from_longitude": 105.82688414327268,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6188,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82656898371026,
          21.009759605806323,
          105.8271469997392,
          21.01001375083712
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82656898371026,
            21.01001375083712
          ],
          [
            105.8271469997392,
            21.009759605806323
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.01001375083712,
        "to_latitude": 21.009759605806323,
        "to_longitude": 105.8271469997392,
        "from_longitude": 105.82656898371026,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6189,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8271469997392,
          21.00954552270999,
          105.82740851511082,
          21.009759605806323
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8271469997392,
            21.009759605806323
          ],
          [
            105.82740851511082,
            21.00954552270999
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "6",
        "diemCuoi": "7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.009759605806323,
        "to_latitude": 21.00954552270999,
        "to_longitude": 105.82740851511082,
        "from_longitude": 105.8271469997392,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6190,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8271469997392,
          21.009409060226037,
          105.82762711514958,
          21.009759605806323
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8271469997392,
            21.009759605806323
          ],
          [
            105.82762711514958,
            21.009409060226037
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "6",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.009759605806323,
        "to_latitude": 21.009409060226037,
        "to_longitude": 105.82762711514958,
        "from_longitude": 105.8271469997392,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6191,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82720600833646,
          21.009093568498123,
          105.82740851511082,
          21.00954552270999
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82740851511082,
            21.00954552270999
          ],
          [
            105.82720600833646,
            21.009093568498123
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "7",
        "diemCuoi": "8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.00954552270999,
        "to_latitude": 21.009093568498123,
        "to_longitude": 105.82720600833646,
        "from_longitude": 105.82740851511082,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6192,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82749032249187,
          21.00905851382037,
          105.82762711514958,
          21.009409060226037
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82762711514958,
            21.009409060226037
          ],
          [
            105.82749032249187,
            21.00905851382037
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.009409060226037,
        "to_latitude": 21.00905851382037,
        "to_longitude": 105.82749032249187,
        "from_longitude": 105.82762711514958,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6193,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82710665813694,
          21.00892728459262,
          105.82720600833646,
          21.009093568498123
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82720600833646,
            21.009093568498123
          ],
          [
            105.82710665813694,
            21.00892728459262
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "8",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.009093568498123,
        "to_latitude": 21.00892728459262,
        "to_longitude": 105.82710665813694,
        "from_longitude": 105.82720600833646,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6194,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82733475437098,
          21.00884568167296,
          105.82749032249187,
          21.00905851382037
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82749032249187,
            21.00905851382037
          ],
          [
            105.82733475437098,
            21.00884568167296
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.00905851382037,
        "to_latitude": 21.00884568167296,
        "to_longitude": 105.82733475437098,
        "from_longitude": 105.82749032249187,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6195,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82661055793928,
          21.008714226370632,
          105.82697936169245,
          21.008871972717007
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82697936169245,
            21.008714226370632
          ],
          [
            105.82663335672304,
            21.008871972717007
          ],
          [
            105.82661055793928,
            21.0088406738539
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 54,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "9",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.008714226370632,
        "to_latitude": 21.0088406738539,
        "to_longitude": 105.82661055793928,
        "from_longitude": 105.82697936169245,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6196,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82641341558039,
          21.0088406738539,
          105.82661055793928,
          21.008922050888476
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82661055793928,
            21.0088406738539
          ],
          [
            105.82641341558039,
            21.008922050888476
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "10",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.0088406738539,
        "to_latitude": 21.008922050888476,
        "to_longitude": 105.82641341558039,
        "from_longitude": 105.82661055793928,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6197,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8260446118362,
          21.008922050888476,
          105.82641341558039,
          21.009158670019637
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82641341558039,
            21.008922050888476
          ],
          [
            105.8260446118362,
            21.009158670019637
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 52,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.008922050888476,
        "to_latitude": 21.009158670019637,
        "to_longitude": 105.8260446118362,
        "from_longitude": 105.82641341558039,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6198,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82571201791448,
          21.009158670019637,
          105.8260446118362,
          21.009338951009788
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8260446118362,
            21.009158670019637
          ],
          [
            105.82571201791448,
            21.009338951009788
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.009158670019637,
        "to_latitude": 21.009338951009788,
        "to_longitude": 105.82571201791448,
        "from_longitude": 105.8260446118362,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6199,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82545452585448,
          21.009338951009788,
          105.82571201791448,
          21.00946915381193
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82571201791448,
            21.009338951009788
          ],
          [
            105.82545452585448,
            21.00946915381193
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.009338951009788,
        "to_latitude": 21.00946915381193,
        "to_longitude": 105.82545452585448,
        "from_longitude": 105.82571201791448,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6200,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82518898716228,
          21.00946915381193,
          105.82545452585448,
          21.0095868370162
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82545452585448,
            21.00946915381193
          ],
          [
            105.82518898716228,
            21.0095868370162
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.00946915381193,
        "to_latitude": 21.0095868370162,
        "to_longitude": 105.82518898716228,
        "from_longitude": 105.82545452585448,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6201,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82697936169245,
          21.008714226370632,
          105.82710665813694,
          21.00892728459262
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82710665813694,
            21.00892728459262
          ],
          [
            105.82697936169245,
            21.008714226370632
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "diemDau": "",
        "diemCuoi": "9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CĐ2",
        "from_latitude": 21.00892728459262,
        "to_latitude": 21.008714226370632,
        "to_longitude": 105.82697936169245,
        "from_longitude": 105.82710665813694,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6202,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83707050313451,
          20.99956269986012,
          105.83760122623443,
          20.999594318540975
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83760122623443,
            20.999594318540975
          ],
          [
            105.83707050313451,
            20.99956269986012
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NLBM",
        "chieuDaiQL": 59,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 102/33 Trường Chinh",
        "diemDau": "04",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NLBM",
        "from_latitude": 20.999594318540975,
        "to_latitude": 20.99956269986012,
        "to_longitude": 105.83707050313451,
        "from_longitude": 105.83760122623443,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6203,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83682084934802,
          20.99956269986012,
          105.83707050313451,
          20.999568521363216
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83707050313451,
            20.99956269986012
          ],
          [
            105.83682084934802,
            20.999568521363216
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NLBM",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 102/33 Trường Chinh",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NLBM",
        "from_latitude": 20.99956269986012,
        "to_latitude": 20.999568521363216,
        "to_longitude": 105.83682084934802,
        "from_longitude": 105.83707050313451,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6204,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83657402099756,
          20.999568521363216,
          105.83682084934802,
          20.999577991510083
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83682084934802,
            20.999568521363216
          ],
          [
            105.83657402099756,
            20.999577991510083
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NLBM",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 102/33 Trường Chinh",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NLBM",
        "from_latitude": 20.999568521363216,
        "to_latitude": 20.999577991510083,
        "to_longitude": 105.83657402099756,
        "from_longitude": 105.83682084934802,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6205,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83626644297331,
          20.9995705999795,
          105.83657402099756,
          20.999577991510083
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83657402099756,
            20.999577991510083
          ],
          [
            105.83626644297331,
            20.9995705999795
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NLBM",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 102/33 Trường Chinh",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NLBM",
        "from_latitude": 20.999577991510083,
        "to_latitude": 20.9995705999795,
        "to_longitude": 105.83626644297331,
        "from_longitude": 105.83657402099756,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6206,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8360590631564,
          20.9995705999795,
          105.83626644297331,
          21.00004006186172
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83626644297331,
            20.9995705999795
          ],
          [
            105.83610282822919,
            20.999779689264425
          ],
          [
            105.8360590631564,
            21.00004006186172
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NLBM",
        "chieuDaiQL": 60,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 102/33 Trường Chinh",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NLBM",
        "from_latitude": 20.9995705999795,
        "to_latitude": 21.00004006186172,
        "to_longitude": 105.8360590631564,
        "from_longitude": 105.83626644297331,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6207,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83593250795674,
          21.00004006186172,
          105.8360590631564,
          21.00017282640684
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8360590631564,
            21.00004006186172
          ],
          [
            105.83603979631133,
            21.000121493271855
          ],
          [
            105.83602102084815,
            21.00013902166148
          ],
          [
            105.83598749324238,
            21.00015655004904
          ],
          [
            105.83596201225087,
            21.000164062214274
          ],
          [
            105.83593250795674,
            21.00017282640684
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NLBM",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 102/33 Trường Chinh",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NLBM",
        "from_latitude": 21.00004006186172,
        "to_latitude": 21.00017282640684,
        "to_longitude": 105.83593250795674,
        "from_longitude": 105.8360590631564,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6208,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82662329846646,
          21.006880099836565,
          105.82672823983764,
          21.00711327929536
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82662329846646,
            21.006880099836565
          ],
          [
            105.82672823983764,
            21.00711327929536
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Chùa Bộc",
        "diemDau": "12",
        "diemCuoi": "12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.006880099836565,
        "to_latitude": 21.00711327929536,
        "to_longitude": 105.82672823983764,
        "from_longitude": 105.82662329846646,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6209,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82672823983764,
          21.00711327929536,
          105.82683552820122,
          21.007222200578994
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82672823983764,
            21.00711327929536
          ],
          [
            105.82683552820122,
            21.007222200578994
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Chùa Bộc",
        "diemDau": "12-1",
        "diemCuoi": "12-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.00711327929536,
        "to_latitude": 21.007222200578994,
        "to_longitude": 105.82683552820122,
        "from_longitude": 105.82672823983764,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6210,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82683552820122,
          21.007222200578994,
          105.82695488649966,
          21.007445050774656
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82683552820122,
            21.007222200578994
          ],
          [
            105.82695488649966,
            21.007445050774656
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Chùa Bộc",
        "diemDau": "12-2",
        "diemCuoi": "12--3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007222200578994,
        "to_latitude": 21.007445050774656,
        "to_longitude": 105.82695488649966,
        "from_longitude": 105.82683552820122,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6211,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82695488649966,
          21.007445050774656,
          105.82699109632212,
          21.00760655436067
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82695488649966,
            21.007445050774656
          ],
          [
            105.82699109632212,
            21.00760655436067
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Chùa Bộc",
        "diemDau": "12-3",
        "diemCuoi": "12-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007445050774656,
        "to_latitude": 21.00760655436067,
        "to_longitude": 105.82699109632212,
        "from_longitude": 105.82695488649966,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6212,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82671616989381,
          21.007445050774656,
          105.82695488649966,
          21.007550215920375
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82695488649966,
            21.007445050774656
          ],
          [
            105.82671616989381,
            21.007550215920375
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Chùa Bộc",
        "diemDau": "12-3",
        "diemCuoi": "12-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007445050774656,
        "to_latitude": 21.007550215920375,
        "to_longitude": 105.82671616989381,
        "from_longitude": 105.82695488649966,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6213,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82699109632212,
          21.00760655436067,
          105.82715202885849,
          21.007770561698173
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82699109632212,
            21.00760655436067
          ],
          [
            105.82715202885849,
            21.007770561698173
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Chùa Bộc",
        "diemDau": "12-5",
        "diemCuoi": "12-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.00760655436067,
        "to_latitude": 21.007770561698173,
        "to_longitude": 105.82715202885849,
        "from_longitude": 105.82699109632212,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6214,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82707022148644,
          21.007770561698173,
          105.82715202885849,
          21.007818136312213
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82715202885849,
            21.007770561698173
          ],
          [
            105.82707022148644,
            21.007818136312213
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Chùa Bộc",
        "diemDau": "12-6",
        "diemCuoi": "12-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007770561698173,
        "to_latitude": 21.007818136312213,
        "to_longitude": 105.82707022148644,
        "from_longitude": 105.82715202885849,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6215,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82715202885849,
          21.007705459570968,
          105.82741622644684,
          21.007770561698173
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82715202885849,
            21.007770561698173
          ],
          [
            105.82741622644684,
            21.007705459570968
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Chùa Bộc",
        "diemDau": "12-6",
        "diemCuoi": "12-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007770561698173,
        "to_latitude": 21.007705459570968,
        "to_longitude": 105.82741622644684,
        "from_longitude": 105.82715202885849,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6216,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82741622644684,
          21.00759153077829,
          105.82766030746815,
          21.007705459570968
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82741622644684,
            21.007705459570968
          ],
          [
            105.82766030746815,
            21.00759153077829
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Chùa Bộc",
        "diemDau": "12-8",
        "diemCuoi": "12-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007705459570968,
        "to_latitude": 21.00759153077829,
        "to_longitude": 105.82766030746815,
        "from_longitude": 105.82741622644684,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6217,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82691934724254,
          21.02316829476087,
          105.82697147047419,
          21.02333176089044
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82697147047419,
            21.02333176089044
          ],
          [
            105.82691934724254,
            21.02316829476087
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 44 Hào Nam",
        "diemDau": "DDHN 21",
        "diemCuoi": "DDHN 20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02333176089044,
        "to_latitude": 21.02316829476087,
        "to_longitude": 105.82691934724254,
        "from_longitude": 105.82697147047419,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6218,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82691934724254,
          21.023129487887047,
          105.82711783070529,
          21.02316829476087
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82691934724254,
            21.02316829476087
          ],
          [
            105.82711783070529,
            21.023129487887047
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 44 Hào Nam",
        "diemDau": "DDHN 21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02316829476087,
        "to_latitude": 21.023129487887047,
        "to_longitude": 105.82711783070529,
        "from_longitude": 105.82691934724254,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6219,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82711783070529,
          21.02304511308552,
          105.82735444700985,
          21.023129487887047
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82711783070529,
            21.023129487887047
          ],
          [
            105.82735444700985,
            21.02304511308552
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 44 Hào Nam",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.023129487887047,
        "to_latitude": 21.02304511308552,
        "to_longitude": 105.82735444700985,
        "from_longitude": 105.82711783070529,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6220,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79979644701274,
          21.025875988137987,
          105.79979912922049,
          21.02622399139475
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79979912922049,
            21.02622399139475
          ],
          [
            105.79979644701274,
            21.025875988137987
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02622399139475,
        "to_latitude": 21.025875988137987,
        "to_longitude": 105.79979644701274,
        "from_longitude": 105.79979912922049,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6221,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79979644701274,
          21.025818012663017,
          105.8000215990315,
          21.025875988137987
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79979644701274,
            21.025875988137987
          ],
          [
            105.8000215990315,
            21.025818012663017
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.025875988137987,
        "to_latitude": 21.025818012663017,
        "to_longitude": 105.8000215990315,
        "from_longitude": 105.79979644701274,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6222,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80000565931542,
          21.025638143718687,
          105.8000215990315,
          21.025818012663017
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8000215990315,
            21.025818012663017
          ],
          [
            105.80000565931542,
            21.025638143718687
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 4+ 41 Chùa Nền",
        "diemDau": "02",
        "diemCuoi": "2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.025818012663017,
        "to_latitude": 21.025638143718687,
        "to_longitude": 105.80000565931542,
        "from_longitude": 105.8000215990315,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6223,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8000215990315,
          21.025805999892633,
          105.80031776602992,
          21.025818012663017
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8000215990315,
            21.025818012663017
          ],
          [
            105.80031776602992,
            21.025805999892633
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.025818012663017,
        "to_latitude": 21.025805999892633,
        "to_longitude": 105.80031776602992,
        "from_longitude": 105.8000215990315,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6224,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80027585247558,
          21.025805999892633,
          105.80031776602992,
          21.025901024370494
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80031776602992,
            21.025805999892633
          ],
          [
            105.80027585247558,
            21.025821126095657
          ],
          [
            105.80029802009403,
            21.025901024370494
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 4+ 41 Chùa Nền",
        "diemDau": "03",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.025805999892633,
        "to_latitude": 21.025901024370494,
        "to_longitude": 105.80029802009403,
        "from_longitude": 105.80031776602992,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6225,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80029802009403,
          21.025901024370494,
          105.80039994404216,
          21.025931067844112
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80029802009403,
            21.025901024370494
          ],
          [
            105.80039994404216,
            21.025931067844112
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 4+ 41 Chùa Nền",
        "diemDau": "3-1",
        "diemCuoi": "3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.025901024370494,
        "to_latitude": 21.025931067844112,
        "to_longitude": 105.80039994404216,
        "from_longitude": 105.80029802009403,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6226,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80039994404216,
          21.025931067844112,
          105.80044264117423,
          21.026243612112793
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80039994404216,
            21.025931067844112
          ],
          [
            105.80044264117423,
            21.026243612112793
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 4+ 41 Chùa Nền",
        "diemDau": "3-2",
        "diemCuoi": "3-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.025931067844112,
        "to_latitude": 21.026243612112793,
        "to_longitude": 105.80044264117423,
        "from_longitude": 105.80039994404216,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6227,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82561629655495,
          21.008518138663298,
          105.82566005017767,
          21.00865319350671
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82566005017767,
            21.00865319350671
          ],
          [
            105.82561629655495,
            21.008518138663298
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Chùa Bộc",
        "diemDau": "30",
        "diemCuoi": "30-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.00865319350671,
        "to_latitude": 21.008518138663298,
        "to_longitude": 105.82561629655495,
        "from_longitude": 105.82566005017767,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6228,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82555594685381,
          21.008382927197626,
          105.82561629655495,
          21.008518138663298
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82561629655495,
            21.008518138663298
          ],
          [
            105.82555594685381,
            21.008382927197626
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 165 Chùa Bộc",
        "diemDau": "30-1",
        "diemCuoi": "30-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.008518138663298,
        "to_latitude": 21.008382927197626,
        "to_longitude": 105.82555594685381,
        "from_longitude": 105.82561629655495,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6229,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82711783070529,
          21.023129487887047,
          105.82725328225915,
          21.02347499389217
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82711783070529,
            21.023129487887047
          ],
          [
            105.82715404051879,
            21.023180813105025
          ],
          [
            105.82721439021994,
            21.023329781320385
          ],
          [
            105.82725328225915,
            21.02347499389217
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Hào Nam",
        "diemDau": "22",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.023129487887047,
        "to_latitude": 21.02347499389217,
        "to_longitude": 105.82725328225915,
        "from_longitude": 105.82711783070529,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6230,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82725328225915,
          21.02347499389217,
          105.82733777184794,
          21.02377918873332
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82725328225915,
            21.02347499389217
          ],
          [
            105.82729083317653,
            21.02363522828866
          ],
          [
            105.82730692643194,
            21.023691560653056
          ],
          [
            105.8272948564971,
            21.023691560653056
          ],
          [
            105.82729753870485,
            21.023716597252434
          ],
          [
            105.82733777184794,
            21.02377918873332
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Hào Nam",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02347499389217,
        "to_latitude": 21.02377918873332,
        "to_longitude": 105.82733777184794,
        "from_longitude": 105.82725328225915,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6231,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82733777184794,
          21.02377918873332,
          105.82739141602973,
          21.024124693232668
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82733777184794,
            21.02377918873332
          ],
          [
            105.82736191171762,
            21.023839276528957
          ],
          [
            105.82736593502923,
            21.02390687527083
          ],
          [
            105.82738068718082,
            21.024070864684578
          ],
          [
            105.82738068718082,
            21.024105915833687
          ],
          [
            105.82739141602973,
            21.024124693232668
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Hào Nam",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02377918873332,
        "to_latitude": 21.024124693232668,
        "to_longitude": 105.82739141602973,
        "from_longitude": 105.82733777184794,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6232,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82739141602973,
          21.024124693232668,
          105.8275130903433,
          21.02446247874849
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82739141602973,
            21.024124693232668
          ],
          [
            105.8274289669471,
            21.024266149552876
          ],
          [
            105.82742494362651,
            21.024326237153055
          ],
          [
            105.8275130903433,
            21.02446247874849
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Hào Nam",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.024124693232668,
        "to_latitude": 21.02446247874849,
        "to_longitude": 105.8275130903433,
        "from_longitude": 105.82739141602973,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6233,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8275130903433,
          21.02446247874849,
          105.82763549705103,
          21.024826966213258
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8275130903433,
            21.02446247874849
          ],
          [
            105.82755100745324,
            21.024506499808286
          ],
          [
            105.8275885583796,
            21.02461290474535
          ],
          [
            105.8275885583796,
            21.024691769532485
          ],
          [
            105.82759794611569,
            21.024728072356393
          ],
          [
            105.82763013261757,
            21.024793167053925
          ],
          [
            105.82763549705103,
            21.024826966213258
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Hào Nam",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02446247874849,
        "to_latitude": 21.024826966213258,
        "to_longitude": 105.82763549705103,
        "from_longitude": 105.8275130903433,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6234,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82763549705103,
          21.024826966213258,
          105.82767707128004,
          21.02507858193214
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82763549705103,
            21.024826966213258
          ],
          [
            105.82764622587302,
            21.024839484417285
          ],
          [
            105.82766902464782,
            21.024983443698993
          ],
          [
            105.82767707128004,
            21.02507858193214
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Hào Nam",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.024826966213258,
        "to_latitude": 21.02507858193214,
        "to_longitude": 105.82767707128004,
        "from_longitude": 105.82763549705103,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6235,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82725328225915,
          21.023381106470218,
          105.82759392280407,
          21.02347499389217
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82725328225915,
            21.02347499389217
          ],
          [
            105.82733911294282,
            21.023453712747564
          ],
          [
            105.8274155558994,
            21.02344119442631
          ],
          [
            105.82759392280407,
            21.023381106470218
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 46/12 Hào Nam",
        "diemDau": "28",
        "diemCuoi": "44",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02347499389217,
        "to_latitude": 21.023381106470218,
        "to_longitude": 105.82759392280407,
        "from_longitude": 105.82725328225915,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6236,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82759392280407,
          21.02328095982126,
          105.82779508847456,
          21.023381106470218
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82759392280407,
            21.023381106470218
          ],
          [
            105.82762342709822,
            21.023293478155136
          ],
          [
            105.82779508847456,
            21.02328095982126
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 46/12 Hào Nam",
        "diemDau": "44",
        "diemCuoi": "45",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.023381106470218,
        "to_latitude": 21.02328095982126,
        "to_longitude": 105.82779508847456,
        "from_longitude": 105.82759392280407,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6237,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82779240626682,
          21.023158280085276,
          105.82802173513656,
          21.02328095982126
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82779508847456,
            21.02328095982126
          ],
          [
            105.82779240626682,
            21.02321711629688
          ],
          [
            105.82782593388156,
            21.023197086950777
          ],
          [
            105.82784605044861,
            21.023199590618884
          ],
          [
            105.82796809095476,
            21.02316453925668
          ],
          [
            105.82802173513656,
            21.023158280085276
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 46/12 Hào Nam",
        "diemDau": "45",
        "diemCuoi": "46",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02328095982126,
        "to_latitude": 21.023158280085276,
        "to_longitude": 105.82802173513656,
        "from_longitude": 105.82779508847456,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6238,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82802173513656,
          21.023158280085276,
          105.82815316337881,
          21.023299737323317
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82802173513656,
            21.023158280085276
          ],
          [
            105.82804319280748,
            21.023218368131193
          ],
          [
            105.82813438791563,
            21.023238397474437
          ],
          [
            105.82815316337881,
            21.023299737323317
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 46/12 Hào Nam",
        "diemDau": "46",
        "diemCuoi": "47",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.023158280085276,
        "to_latitude": 21.023299737323317,
        "to_longitude": 105.82815316337881,
        "from_longitude": 105.82802173513656,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6239,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82739141602973,
          21.02410341218165,
          105.82754161973513,
          21.024124693232668
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82739141602973,
            21.024124693232668
          ],
          [
            105.82754161973513,
            21.02410341218165
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 86 Hào Nam",
        "diemDau": "30",
        "diemCuoi": "41",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.024124693232668,
        "to_latitude": 21.02410341218165,
        "to_longitude": 105.82754161973513,
        "from_longitude": 105.82739141602973,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6240,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82754161973513,
          21.02408213112759,
          105.82791042347932,
          21.02410341218165
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82754161973513,
            21.02410341218165
          ],
          [
            105.82769852895093,
            21.024095901220242
          ],
          [
            105.82791042347932,
            21.02408213112759
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 86 Hào Nam",
        "diemDau": "41",
        "diemCuoi": "42",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02410341218165,
        "to_latitude": 21.02408213112759,
        "to_longitude": 105.82791042347932,
        "from_longitude": 105.82754161973513,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6241,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8278768958646,
          21.023874327733353,
          105.82791176456524,
          21.02408213112759
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82791042347932,
            21.02408213112759
          ],
          [
            105.82791176456524,
            21.02403831718208
          ],
          [
            105.82789701241369,
            21.023945681942276
          ],
          [
            105.8278768958646,
            21.023874327733353
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 86 Hào Nam",
        "diemDau": "42",
        "diemCuoi": "42-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02408213112759,
        "to_latitude": 21.023874327733353,
        "to_longitude": 105.8278768958646,
        "from_longitude": 105.82791042347932,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6242,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8278768958646,
          21.023716597253262,
          105.82824569959979,
          21.023874327733353
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8278768958646,
            21.023874327733353
          ],
          [
            105.82795065659548,
            21.02384803933064
          ],
          [
            105.82799893636175,
            21.02379546251291
          ],
          [
            105.8280552627513,
            21.023767922268277
          ],
          [
            105.82824569959979,
            21.023716597253262
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 86 Hào Nam",
        "diemDau": "42-1",
        "diemCuoi": "42-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.023874327733353,
        "to_latitude": 21.023716597253262,
        "to_longitude": 105.82824569959979,
        "from_longitude": 105.8278768958646,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6243,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82791042347932,
          21.024063353723225,
          105.82818400879479,
          21.02408213112759
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82791042347932,
            21.02408213112759
          ],
          [
            105.82802844065591,
            21.024063353723225
          ],
          [
            105.82805794495903,
            21.024078375645562
          ],
          [
            105.82818400879479,
            21.024063353724078
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 86 Hào Nam",
        "diemDau": "42",
        "diemCuoi": "43",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02408213112759,
        "to_latitude": 21.024063353724078,
        "to_longitude": 105.82818400879479,
        "from_longitude": 105.82791042347932,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6244,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82818400879479,
          21.024004517845608,
          105.82849648614943,
          21.024063353724078
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82818400879479,
            21.024063353724078
          ],
          [
            105.82839322108849,
            21.02403581352724
          ],
          [
            105.82839053888074,
            21.024008273326984
          ],
          [
            105.82849648614943,
            21.024004517845608
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x2,5",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 86 Hào Nam",
        "diemDau": "43",
        "diemCuoi": "43-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.024063353724078,
        "to_latitude": 21.024004517845608,
        "to_longitude": 105.82849648614943,
        "from_longitude": 105.82818400879479,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6245,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82847368736564,
          21.024004517845608,
          105.82849648614943,
          21.024182277238346
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82849648614943,
            21.024004517845608
          ],
          [
            105.82849380392373,
            21.024099656700184
          ],
          [
            105.82847368736564,
            21.024182277238346
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 86 Hào Nam",
        "diemDau": "43-1",
        "diemCuoi": "43-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.024004517845608,
        "to_latitude": 21.024182277238346,
        "to_longitude": 105.82847368736564,
        "from_longitude": 105.82849648614943,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6246,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82847368736564,
          21.024182277238346,
          105.82851526161262,
          21.02435502912015
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82847368736564,
            21.024182277238346
          ],
          [
            105.82849246281985,
            21.024301200655227
          ],
          [
            105.82851526161262,
            21.02435502912015
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 86 Hào Nam",
        "diemDau": "43-2",
        "diemCuoi": "43-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.024182277238346,
        "to_latitude": 21.02435502912015,
        "to_longitude": 105.82851526161262,
        "from_longitude": 105.82847368736564,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6247,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82824033518432,
          21.02435502912015,
          105.82851526161262,
          21.024427634923967
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82851526161262,
            21.02435502912015
          ],
          [
            105.82828056830942,
            21.02440259844316
          ],
          [
            105.82824033518432,
            21.024427634923967
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 86 Hào Nam",
        "diemDau": "43-3",
        "diemCuoi": "43-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02435502912015,
        "to_latitude": 21.024427634923967,
        "to_longitude": 105.82824033518432,
        "from_longitude": 105.82851526161262,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6248,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8275885583796,
          21.024503694213216,
          105.82774014364203,
          21.02461290474535
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8275885583796,
            21.02461290474535
          ],
          [
            105.8275939227951,
            21.024551565437594
          ],
          [
            105.82774014364203,
            21.024503694213216
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 46/54 Hào Nam",
        "diemDau": "31",
        "diemCuoi": "31-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02461290474535,
        "to_latitude": 21.024503694213216,
        "to_longitude": 105.82774014364203,
        "from_longitude": 105.8275885583796,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6249,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82763549705103,
          21.0247418423919,
          105.8278540970898,
          21.024826966213258
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82763549705103,
            21.024826966213258
          ],
          [
            105.82766366023233,
            21.0248069370826
          ],
          [
            105.8278540970898,
            21.0247418423919
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 46/58 Hào Nam",
        "diemDau": "32",
        "diemCuoi": "38",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.024826966213258,
        "to_latitude": 21.0247418423919,
        "to_longitude": 105.8278540970898,
        "from_longitude": 105.82763549705103,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6250,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8278540970898,
          21.02468403489286,
          105.82807929880428,
          21.0247418423919
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8278540970898,
            21.0247418423919
          ],
          [
            105.82801234740944,
            21.02469552499739
          ],
          [
            105.82807929880428,
            21.02468403489286
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 46/58 Hào Nam",
        "diemDau": "38",
        "diemCuoi": "39",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.0247418423919,
        "to_latitude": 21.02468403489286,
        "to_longitude": 105.82807929880428,
        "from_longitude": 105.8278540970898,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6251,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82807929880428,
          21.02466172580825,
          105.82822826524048,
          21.02468403489286
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82807929880428,
            21.02468403489286
          ],
          [
            105.82822155971216,
            21.02466172580825
          ],
          [
            105.82822826524048,
            21.02467549585074
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 46/58 Hào Nam",
        "diemDau": "39",
        "diemCuoi": "40",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02468403489286,
        "to_latitude": 21.02467549585074,
        "to_longitude": 105.82822826524048,
        "from_longitude": 105.82807929880428,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6252,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82822826524048,
          21.02467549585074,
          105.82848441620563,
          21.024754360603104
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82822826524048,
            21.02467549585074
          ],
          [
            105.8282591106385,
            21.024754360603104
          ],
          [
            105.82848441620563,
            21.02471430232212
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 46/58 Hào Nam",
        "diemDau": "40",
        "diemCuoi": "40-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02467549585074,
        "to_latitude": 21.02471430232212,
        "to_longitude": 105.82848441620563,
        "from_longitude": 105.82822826524048,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6253,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82848441620563,
          21.02471430232212,
          105.82857695241763,
          21.024927111823626
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82848441620563,
            21.02471430232212
          ],
          [
            105.82857695241763,
            21.024927111823626
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 46/58 Hào Nam",
        "diemDau": "40-1",
        "diemCuoi": "40-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02471430232212,
        "to_latitude": 21.024927111823626,
        "to_longitude": 105.82857695241763,
        "from_longitude": 105.82848441620563,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6254,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82767707128004,
          21.02495340003608,
          105.82807135600675,
          21.02507858193214
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82767707128004,
            21.02507858193214
          ],
          [
            105.82773205656568,
            21.025042279191076
          ],
          [
            105.82789433020595,
            21.02501599099514
          ],
          [
            105.82789433020595,
            21.02499596189072
          ],
          [
            105.82807135600675,
            21.02495340003608
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Hào Nam",
        "diemDau": "33",
        "diemCuoi": "34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02507858193214,
        "to_latitude": 21.02495340003608,
        "to_longitude": 105.82807135600675,
        "from_longitude": 105.82767707128004,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6255,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82807135600675,
          21.02490708270809,
          105.82822424191988,
          21.02495340003608
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82807135600675,
            21.02495340003608
          ],
          [
            105.82822424191988,
            21.02490708270809
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Hào Nam",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02495340003608,
        "to_latitude": 21.02490708270809,
        "to_longitude": 105.82822424191988,
        "from_longitude": 105.82807135600675,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6256,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82822424191988,
          21.02490708270809,
          105.82838249225752,
          21.025323938143337
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82822424191988,
            21.02490708270809
          ],
          [
            105.82822960633537,
            21.024944637297725
          ],
          [
            105.82838249225752,
            21.025323938143337
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Hào Nam",
        "diemDau": "35",
        "diemCuoi": "37",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02490708270809,
        "to_latitude": 21.025323938143337,
        "to_longitude": 105.82838249225752,
        "from_longitude": 105.82822424191988,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6257,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82838249225752,
          21.02529764999456,
          105.82865071314852,
          21.025340211755132
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82838249225752,
            21.025323938143337
          ],
          [
            105.828552812521,
            21.02529764999456
          ],
          [
            105.82865071314852,
            21.02531267179335
          ],
          [
            105.82864803094974,
            21.025340211755132
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Hào Nam",
        "diemDau": "37",
        "diemCuoi": "36",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.025323938143337,
        "to_latitude": 21.025340211755132,
        "to_longitude": 105.82864803094974,
        "from_longitude": 105.82838249225752,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6258,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82838249225752,
          21.025323938143337,
          105.82849380392373,
          21.025527984072134
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82838249225752,
            21.025323938143337
          ],
          [
            105.82846966404507,
            21.025350226282445
          ],
          [
            105.82849380392373,
            21.025480415105722
          ],
          [
            105.82841467875942,
            21.025527984072134
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 152 Hào Nam",
        "diemDau": "37",
        "diemCuoi": "37-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.025323938143337,
        "to_latitude": 21.025527984072134,
        "to_longitude": 105.82841467875942,
        "from_longitude": 105.82838249225752,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6259,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82816319523972,
          21.025501695960195,
          105.82841467875942,
          21.025532413127177
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82841467875942,
            21.025527984072134
          ],
          [
            105.82835298795439,
            21.025501695960195
          ],
          [
            105.82816319523972,
            21.025532413127177
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 152 Hào Nam",
        "diemDau": "37.2",
        "diemCuoi": "37-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.025527984072134,
        "to_latitude": 21.025532413127177,
        "to_longitude": 105.82816319523972,
        "from_longitude": 105.82841467875942,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6260,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82798758614238,
          21.025532413127177,
          105.82816319523972,
          21.02558416852652
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82816319523972,
            21.025532413127177
          ],
          [
            105.82798758614238,
            21.02558416852652
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 205,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 152 Hào Nam",
        "diemDau": "37-3",
        "diemCuoi": "37-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.025532413127177,
        "to_latitude": 21.02558416852652,
        "to_longitude": 105.82798758614238,
        "from_longitude": 105.82816319523972,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6261,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82780836587096,
          21.02558416852652,
          105.82798758614238,
          21.025639133316396
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82798758614238,
            21.02558416852652
          ],
          [
            105.82780836587096,
            21.025639133316396
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 152 Hào Nam",
        "diemDau": "37-4",
        "diemCuoi": "37-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02558416852652,
        "to_latitude": 21.025639133316396,
        "to_longitude": 105.82780836587096,
        "from_longitude": 105.82798758614238,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6262,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82841467875942,
          21.02545546642937,
          105.82870076145039,
          21.025527984072134
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82841467875942,
            21.025527984072134
          ],
          [
            105.82855549472875,
            21.02547290421541
          ],
          [
            105.82870076145039,
            21.02545546642937
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 152 Hào Nam",
        "diemDau": "37-2",
        "diemCuoi": "37-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.025527984072134,
        "to_latitude": 21.02545546642937,
        "to_longitude": 105.82870076145039,
        "from_longitude": 105.82841467875942,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6263,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82856175703566,
          21.007014373690012,
          105.82884316163933,
          21.007149446074603
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82856175703566,
            21.007149446074603
          ],
          [
            105.82884316163933,
            21.007014373690012
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 95 Chùa Bộc",
        "diemDau": "cột đỡ",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007149446074603,
        "to_latitude": 21.007014373690012,
        "to_longitude": 105.82884316163933,
        "from_longitude": 105.82856175703566,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6264,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82884316163933,
          21.006857877349578,
          105.82917039113656,
          21.007014373690012
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82884316163933,
            21.007014373690012
          ],
          [
            105.82917039113656,
            21.006857877349578
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 95 Chùa Bộc",
        "diemDau": "7-1",
        "diemCuoi": "7-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.007014373690012,
        "to_latitude": 21.006857877349578,
        "to_longitude": 105.82917039113656,
        "from_longitude": 105.82884316163933,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6265,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82904298621493,
          21.00654864009734,
          105.82917039113656,
          21.006857877349578
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82917039113656,
            21.006857877349578
          ],
          [
            105.82904298621493,
            21.00654864009734
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TL3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 95 Chùa Bộc",
        "diemDau": "7-2",
        "diemCuoi": "7-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TL3",
        "from_latitude": 21.006857877349578,
        "to_latitude": 21.00654864009734,
        "to_longitude": 105.82904298621493,
        "from_longitude": 105.82917039113656,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6266,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8362970205117,
          21.012522940304414,
          105.83645527084033,
          21.01268819416058
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8362970205117,
            21.01268819416058
          ],
          [
            105.83645527084033,
            21.012522940304414
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đê La Thành Nhỏ",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01268819416058,
        "to_latitude": 21.012522940304414,
        "to_longitude": 105.83645527084033,
        "from_longitude": 105.8362970205117,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6267,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83645527084033,
          21.012330978525235,
          105.83662425001191,
          21.012522940304414
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83645527084033,
            21.012522940304414
          ],
          [
            105.83662425001191,
            21.012330978525235
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đê La Thành Nhỏ",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012522940304414,
        "to_latitude": 21.012330978525235,
        "to_longitude": 105.83662425001191,
        "from_longitude": 105.83645527084033,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6268,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83662425001191,
          21.012157378094617,
          105.83679591138976,
          21.012330978525235
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83662425001191,
            21.012330978525235
          ],
          [
            105.83679591138976,
            21.012157378094617
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đê La Thành Nhỏ",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012330978525235,
        "to_latitude": 21.012157378094617,
        "to_longitude": 105.83679591138976,
        "from_longitude": 105.83662425001191,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6269,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83679591138976,
          21.011899480928346,
          105.83705876785177,
          21.012157378094617
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83679591138976,
            21.012157378094617
          ],
          [
            105.83705876785177,
            21.011899480928346
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đê La Thành Nhỏ",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012157378094617,
        "to_latitude": 21.011899480928346,
        "to_longitude": 105.83705876785177,
        "from_longitude": 105.83679591138976,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6270,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83705876785177,
          21.011776791828822,
          105.83717946726306,
          21.011899480928346
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83705876785177,
            21.011899480928346
          ],
          [
            105.83717946726306,
            21.011776791828822
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đê La Thành Nhỏ",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.011899480928346,
        "to_latitude": 21.011776791828822,
        "to_longitude": 105.83717946726306,
        "from_longitude": 105.83705876785177,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6271,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83717946726306,
          21.01163657559055,
          105.83731089552325,
          21.011776791828822
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83717946726306,
            21.011776791828822
          ],
          [
            105.83731089552325,
            21.01163657559055
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đê La Thành Nhỏ",
        "diemDau": "01",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.011776791828822,
        "to_latitude": 21.01163657559055,
        "to_longitude": 105.83731089552325,
        "from_longitude": 105.83717946726306,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6272,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83731089552325,
          21.011371165921922,
          105.8374691458519,
          21.01163657559055
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83731089552325,
            21.01163657559055
          ],
          [
            105.8374691458519,
            21.011371165921922
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 0,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Kim Hoa",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01163657559055,
        "to_latitude": 21.011371165921922,
        "to_longitude": 105.8374691458519,
        "from_longitude": 105.83731089552325,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6273,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8374691458519,
          21.01105567834434,
          105.83772127349641,
          21.011371165921922
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8374691458519,
            21.011371165921922
          ],
          [
            105.83772127349641,
            21.01105567834434
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Kim Hoa",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.011371165921922,
        "to_latitude": 21.01105567834434,
        "to_longitude": 105.83772127349641,
        "from_longitude": 105.8374691458519,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6274,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83772127349641,
          21.01085755931639,
          105.83790668120766,
          21.01105567834434
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83772127349641,
            21.01105567834434
          ],
          [
            105.83790668120766,
            21.01085755931639
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Kim Hoa",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01105567834434,
        "to_latitude": 21.01085755931639,
        "to_longitude": 105.83790668120766,
        "from_longitude": 105.83772127349641,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6275,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80102016548331,
          21.025697196636646,
          105.80130582074258,
          21.025857428644507
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80102016548331,
            21.025697196636646
          ],
          [
            105.80107246856123,
            21.025857428644507
          ],
          [
            105.80130582074258,
            21.025836147840845
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/103 Đường Láng",
        "diemDau": "05",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.025697196636646,
        "to_latitude": 21.025836147840845,
        "to_longitude": 105.80130582074258,
        "from_longitude": 105.80102016548331,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6276,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80130582074258,
          21.025793586224403,
          105.80169876436543,
          21.025837399653163
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80130582074258,
            21.025836147840845
          ],
          [
            105.80140506247393,
            21.025837399653163
          ],
          [
            105.80150564530919,
            21.025828636968434
          ],
          [
            105.80169876436543,
            21.025793586224403
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/103 Đường Láng",
        "diemDau": "5-1",
        "diemCuoi": "5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.025836147840845,
        "to_latitude": 21.025793586224403,
        "to_longitude": 105.80169876436543,
        "from_longitude": 105.80130582074258,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6277,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80169876436543,
          21.025762290910986,
          105.80194955090609,
          21.025793586224403
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80169876436543,
            21.025793586224403
          ],
          [
            105.80194955090609,
            21.025762290910986
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/103 Đường Láng",
        "diemDau": "5-2",
        "diemCuoi": "5-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.025793586224403,
        "to_latitude": 21.025762290910986,
        "to_longitude": 105.80194955090609,
        "from_longitude": 105.80169876436543,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6278,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80194955090609,
          21.025742261906004,
          105.8021936319274,
          21.025762290910986
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80194955090609,
            21.025762290910986
          ],
          [
            105.8021936319274,
            21.025742261906004
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/103 Đường Láng",
        "diemDau": "5-3",
        "diemCuoi": "5-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.025762290910986,
        "to_latitude": 21.025742261906004,
        "to_longitude": 105.8021936319274,
        "from_longitude": 105.80194955090609,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6279,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8021936319274,
          21.025742261906004,
          105.80229019145104,
          21.025742261906004
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8021936319274,
            21.025742261906004
          ],
          [
            105.80229019145104,
            21.025742261906004
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/103 Đường Láng",
        "diemDau": "5-4",
        "diemCuoi": "5-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.025742261906004,
        "to_latitude": 21.025742261906004,
        "to_longitude": 105.80229019145104,
        "from_longitude": 105.8021936319274,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6280,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80194955090609,
          21.02557721208177,
          105.80215256784562,
          21.025762290910986
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80194955090609,
            21.025762290910986
          ],
          [
            105.80212925890565,
            21.02573475102887
          ],
          [
            105.80215256784562,
            21.02557721208177
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/103 Đường Láng",
        "diemDau": "5-3",
        "diemCuoi": "5-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.025762290910986,
        "to_latitude": 21.02557721208177,
        "to_longitude": 105.80215256784562,
        "from_longitude": 105.80194955090609,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6281,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80194212827415,
          21.02557721208177,
          105.80215256784562,
          21.025582151811857
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80215256784562,
            21.02557721208177
          ],
          [
            105.80194212827415,
            21.025582151811857
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/103 Đường Láng",
        "diemDau": "5-5",
        "diemCuoi": "5-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02557721208177,
        "to_latitude": 21.025582151811857,
        "to_longitude": 105.80194212827415,
        "from_longitude": 105.80215256784562,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6282,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83645527084033,
          21.012522940304414,
          105.83654914814723,
          21.01261933841041
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83645527084033,
            21.012522940304414
          ],
          [
            105.83654914814723,
            21.01261933841041
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28 Đê La Thành",
        "diemDau": "21",
        "diemCuoi": "21-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012522940304414,
        "to_latitude": 21.01261933841041,
        "to_longitude": 105.83654914814723,
        "from_longitude": 105.83645527084033,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6283,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83654914814723,
          21.01256425378689,
          105.83670471626812,
          21.01261933841041
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83654914814723,
            21.01261933841041
          ],
          [
            105.83670471626812,
            21.01256425378689
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28 Đê La Thành",
        "diemDau": "21-1",
        "diemCuoi": "21-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01261933841041,
        "to_latitude": 21.01256425378689,
        "to_longitude": 105.83670471626812,
        "from_longitude": 105.83654914814723,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6284,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83654914814723,
          21.01261933841041,
          105.83684687334136,
          21.01281839403946
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83654914814723,
            21.01261933841041
          ],
          [
            105.83684687334136,
            21.01281839403946
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28 Đê La Thành",
        "diemDau": "21-1",
        "diemCuoi": "21-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01261933841041,
        "to_latitude": 21.01281839403946,
        "to_longitude": 105.83684687334136,
        "from_longitude": 105.83654914814723,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6285,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83684687334136,
          21.01281839403946,
          105.8370614500595,
          21.012998670606837
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83684687334136,
            21.01281839403946
          ],
          [
            105.8370614500595,
            21.012998670606837
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28 Đê La Thành",
        "diemDau": "21-3",
        "diemCuoi": "21-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01281839403946,
        "to_latitude": 21.012998670606837,
        "to_longitude": 105.8370614500595,
        "from_longitude": 105.83684687334136,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6286,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8370614500595,
          21.012855951676265,
          105.83718751388628,
          21.012998670606837
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8370614500595,
            21.012998670606837
          ],
          [
            105.83718751388628,
            21.012855951676265
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28 Đê La Thành",
        "diemDau": "21-4",
        "diemCuoi": "21-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012998670606837,
        "to_latitude": 21.012855951676265,
        "to_longitude": 105.83718751388628,
        "from_longitude": 105.8370614500595,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6287,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83631847817362,
          21.012444069082854,
          105.83645527084033,
          21.012522940304414
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83645527084033,
            21.012522940304414
          ],
          [
            105.83631847817362,
            21.012444069082854
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28 Đê La Thành",
        "diemDau": "21",
        "diemCuoi": "21-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012522940304414,
        "to_latitude": 21.012444069082854,
        "to_longitude": 105.83631847817362,
        "from_longitude": 105.83645527084033,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6288,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83679591138976,
          21.012157378094617,
          105.8368307800949,
          21.012206203236897
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83679591138976,
            21.012157378094617
          ],
          [
            105.8368307800949,
            21.012206203236897
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28 Đê La Thành",
        "diemDau": "19",
        "diemCuoi": "19-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012157378094617,
        "to_latitude": 21.012206203236897,
        "to_longitude": 105.8368307800949,
        "from_longitude": 105.83679591138976,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6289,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8368307800949,
          21.012206203236897,
          105.83686833102125,
          21.012326388132607
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8368307800949,
            21.012206203236897
          ],
          [
            105.83686833102125,
            21.012326388132607
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 10  Đê La Thành",
        "diemDau": "19-2",
        "diemCuoi": "19-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012206203236897,
        "to_latitude": 21.012326388132607,
        "to_longitude": 105.83686833102125,
        "from_longitude": 105.8368307800949,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6290,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83684687335034,
          21.012326388132607,
          105.83695684391265,
          21.01250916914303
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83686833102125,
            21.012326388132607
          ],
          [
            105.83684687335034,
            21.012373961293797
          ],
          [
            105.83695684391265,
            21.01250916914303
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 10  Đê La Thành",
        "diemDau": "19-3",
        "diemCuoi": "19-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012326388132607,
        "to_latitude": 21.01250916914303,
        "to_longitude": 105.83695684391265,
        "from_longitude": 105.83686833102125,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6291,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83695684391265,
          21.01234892278998,
          105.83709900099485,
          21.01250916914303
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83695684391265,
            21.01250916914303
          ],
          [
            105.83709900099485,
            21.01234892278998
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28 Đê La Thành",
        "diemDau": "19-4",
        "diemCuoi": "19-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01250916914303,
        "to_latitude": 21.01234892278998,
        "to_longitude": 105.83709900099485,
        "from_longitude": 105.83695684391265,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6292,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83663766104763,
          21.012078506679295,
          105.83679591138976,
          21.012157378094617
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83679591138976,
            21.012157378094617
          ],
          [
            105.83663766104763,
            21.012078506679295
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28 Đê La Thành",
        "diemDau": "19-2",
        "diemCuoi": "19-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012157378094617,
        "to_latitude": 21.012078506679295,
        "to_longitude": 105.83663766104763,
        "from_longitude": 105.83679591138976,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6293,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83705876785177,
          21.011899480928346,
          105.83717142063982,
          21.011975848583045
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83705876785177,
            21.011899480928346
          ],
          [
            105.83717142063982,
            21.011975848583045
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28 Đê La Thành",
        "diemDau": "18",
        "diemCuoi": "18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.011899480928346,
        "to_latitude": 21.011975848583045,
        "to_longitude": 105.83717142063982,
        "from_longitude": 105.83705876785177,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6294,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83690856415534,
          21.011899480928346,
          105.83705876785177,
          21.01190073285763
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83705876785177,
            21.011899480928346
          ],
          [
            105.83690856415534,
            21.01190073285763
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28 Đê La Thành",
        "diemDau": "18",
        "diemCuoi": "18-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.011899480928346,
        "to_latitude": 21.01190073285763,
        "to_longitude": 105.83690856415534,
        "from_longitude": 105.83705876785177,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6295,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80120792011508,
          21.026269320572517,
          105.80145630545748,
          21.02636691521481
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80120792011508,
            21.02636691521481
          ],
          [
            105.80145630545748,
            21.026269320572517
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/91 Đường Láng",
        "diemDau": "07",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02636691521481,
        "to_latitude": 21.026269320572517,
        "to_longitude": 105.80145630545748,
        "from_longitude": 105.80120792011508,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6296,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80145630545748,
          21.026169743602164,
          105.80170378612864,
          21.026269320572517
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80145630545748,
            21.026269320572517
          ],
          [
            105.80170378612864,
            21.026169743602164
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/91 Đường Láng",
        "diemDau": "7-1",
        "diemCuoi": "7-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.026269320572517,
        "to_latitude": 21.026169743602164,
        "to_longitude": 105.80170378612864,
        "from_longitude": 105.80145630545748,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6297,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80170378612864,
          21.02605897557375,
          105.80196560923468,
          21.026169743602164
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80170378612864,
            21.026169743602164
          ],
          [
            105.80196560923468,
            21.02605897557375
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/91 Đường Láng",
        "diemDau": "7-2",
        "diemCuoi": "7-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.026169743602164,
        "to_latitude": 21.02605897557375,
        "to_longitude": 105.80196560923468,
        "from_longitude": 105.80170378612864,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6298,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80196560923468,
          21.02594570436191,
          105.80223607733846,
          21.02605897557375
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80196560923468,
            21.02605897557375
          ],
          [
            105.80223607733846,
            21.02594570436191
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/91 Đường Láng",
        "diemDau": "7-3",
        "diemCuoi": "7-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02605897557375,
        "to_latitude": 21.02594570436191,
        "to_longitude": 105.80223607733846,
        "from_longitude": 105.80196560923468,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6299,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83831169476537,
          21.012497588845164,
          105.8384243475444,
          21.012562688881168
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83831169476537,
            21.012497588845164
          ],
          [
            105.8384243475444,
            21.012562688881168
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012497588845164,
        "to_latitude": 21.012562688881168,
        "to_longitude": 105.8384243475444,
        "from_longitude": 105.83831169476537,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6300,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83838947882579,
          21.012562688881168,
          105.8384243475444,
          21.0126453158101
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8384243475444,
            21.012562688881168
          ],
          [
            105.83838947882579,
            21.0126453158101
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012562688881168,
        "to_latitude": 21.0126453158101,
        "to_longitude": 105.83838947882579,
        "from_longitude": 105.8384243475444,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6301,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8373377175917,
          21.01291103619208,
          105.83752547222346,
          21.013131374052676
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83752547222346,
            21.01291103619208
          ],
          [
            105.83739136177347,
            21.01298239564888
          ],
          [
            105.83741281945338,
            21.013024960922312
          ],
          [
            105.8373377175917,
            21.013131374052676
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01291103619208,
        "to_latitude": 21.013131374052676,
        "to_longitude": 105.8373377175917,
        "from_longitude": 105.83752547222346,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6302,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82843432394496,
          21.022687589549108,
          105.82907718438611,
          21.02295172724069
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82843432394496,
            21.022774429617474
          ],
          [
            105.829010799731,
            21.022687589549108
          ],
          [
            105.82903493960966,
            21.022800254971944
          ],
          [
            105.82903628071352,
            21.022842817443028
          ],
          [
            105.82904432733676,
            21.02285158030326
          ],
          [
            105.82905907948833,
            21.022925438676086
          ],
          [
            105.82907718438611,
            21.02295172724069
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 60,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "27",
        "diemCuoi": "DDHN 50-27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.022774429617474,
        "to_latitude": 21.02295172724069,
        "to_longitude": 105.82907718438611,
        "from_longitude": 105.82843432394496,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6303,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82907718438611,
          21.02295172724069,
          105.82912814635115,
          21.02313324339215
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82907718438611,
            21.02295172724069
          ],
          [
            105.82912814635115,
            21.02313324339215
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "DDHN 50-27",
        "diemCuoi": "50",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02295172724069,
        "to_latitude": 21.02313324339215,
        "to_longitude": 105.82912814635115,
        "from_longitude": 105.82907718438611,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6304,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82912814635115,
          21.02313324339215,
          105.82926359790501,
          21.023485008547286
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82912814635115,
            21.02313324339215
          ],
          [
            105.8291797788996,
            21.023300989157782
          ],
          [
            105.82919318993831,
            21.023431179769908
          ],
          [
            105.82926359790501,
            21.023485008547286
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "50",
        "diemCuoi": "50-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02313324339215,
        "to_latitude": 21.023485008547286,
        "to_longitude": 105.82926359790501,
        "from_longitude": 105.82912814635115,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6305,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82919386047676,
          21.023252167648383,
          105.82926359790501,
          21.023485008547286
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82926359790501,
            21.023485008547286
          ],
          [
            105.82921062429762,
            21.023274700653943
          ],
          [
            105.82919386047676,
            21.023252167648383
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "50-1",
        "diemCuoi": "50-28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.023485008547286,
        "to_latitude": 21.023252167648383,
        "to_longitude": 105.82919386047676,
        "from_longitude": 105.82926359790501,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6306,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82921263593994,
          21.023485008547286,
          105.82926359790501,
          21.023720352743556
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82926359790501,
            21.023485008547286
          ],
          [
            105.82921867092982,
            21.02351755617061
          ],
          [
            105.82921263593994,
            21.023720352743556
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "50-1",
        "diemCuoi": "50-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.023485008547286,
        "to_latitude": 21.023720352743556,
        "to_longitude": 105.82921263593994,
        "from_longitude": 105.82926359790501,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6307,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82915362734268,
          21.023720352743556,
          105.82921263593994,
          21.02398949591584
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82921263593994,
            21.023720352743556
          ],
          [
            105.82919989546664,
            21.023836772872453
          ],
          [
            105.82915362734268,
            21.02398949591584
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "50-2",
        "diemCuoi": "50-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.023720352743556,
        "to_latitude": 21.02398949591584,
        "to_longitude": 105.82915362734268,
        "from_longitude": 105.82921263593994,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6308,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82901415246822,
          21.02398949591584,
          105.82915362734268,
          21.02449523339987
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82915362734268,
            21.02398949591584
          ],
          [
            105.8291314991333,
            21.024064605551267
          ],
          [
            105.82911272367012,
            21.02410841948819
          ],
          [
            105.82909528931081,
            21.02416349984744
          ],
          [
            105.82908590158372,
            21.024228594791502
          ],
          [
            105.82903896292127,
            21.024383821081685
          ],
          [
            105.82901415246822,
            21.02449523339987
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "50-3",
        "diemCuoi": "50-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02398949591584,
        "to_latitude": 21.02449523339987,
        "to_longitude": 105.82901415246822,
        "from_longitude": 105.82915362734268,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6309,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82894173283223,
          21.02449523339987,
          105.82901415246822,
          21.024625422969876
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82901415246822,
            21.02449523339987
          ],
          [
            105.82894173283223,
            21.024625422969876
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "50-4",
        "diemCuoi": "50-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02449523339987,
        "to_latitude": 21.024625422969876,
        "to_longitude": 105.82894173283223,
        "from_longitude": 105.82901415246822,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6310,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82894173283223,
          21.024625422969876,
          105.82895715554922,
          21.025037271917576
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82894173283223,
            21.024625422969876
          ],
          [
            105.82895715554922,
            21.02484323988014
          ],
          [
            105.82895380276707,
            21.025037271917576
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "50-5",
        "diemCuoi": "50-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.024625422969876,
        "to_latitude": 21.025037271917576,
        "to_longitude": 105.82895380276707,
        "from_longitude": 105.82894173283223,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6311,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82895380276707,
          21.025037271917576,
          105.82915156359454,
          21.025472087761184
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82895380276707,
            21.025037271917576
          ],
          [
            105.82910467704689,
            21.025322686325882
          ],
          [
            105.82915156359454,
            21.025472087761184
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 58,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "50-6",
        "diemCuoi": "50-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.025037271917576,
        "to_latitude": 21.025472087761184,
        "to_longitude": 105.82915156359454,
        "from_longitude": 105.82895380276707,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6312,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82936954516472,
          21.0253602408133,
          105.82954657096552,
          21.025644402789293
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82954657096552,
            21.025644402789293
          ],
          [
            105.82945336421504,
            21.025620618327817
          ],
          [
            105.82943727095962,
            21.025549264918943
          ],
          [
            105.82941447218482,
            21.02552923588619
          ],
          [
            105.82936954516472,
            21.0253602408133
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "50-9",
        "diemCuoi": "50-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.025644402789293,
        "to_latitude": 21.0253602408133,
        "to_longitude": 105.82936954516472,
        "from_longitude": 105.82954657096552,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6313,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82928812879965,
          21.02506810080481,
          105.82936954516472,
          21.0253602408133
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82936954516472,
            21.0253602408133
          ],
          [
            105.82928812879965,
            21.02506810080481
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "50-10",
        "diemCuoi": "50-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.0253602408133,
        "to_latitude": 21.02506810080481,
        "to_longitude": 105.82928812879965,
        "from_longitude": 105.82936954516472,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6314,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82894039172837,
          21.025322686325868,
          105.82910467704689,
          21.025425335234793
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82894039172837,
            21.025425335234793
          ],
          [
            105.82907383163989,
            21.025395291659244
          ],
          [
            105.82910467704689,
            21.025322686325868
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 152 Hào Nam",
        "diemDau": "50-6",
        "diemCuoi": "37-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.025425335234793,
        "to_latitude": 21.025322686325868,
        "to_longitude": 105.82910467704689,
        "from_longitude": 105.82894039172837,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6315,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82915156359454,
          21.025472087761184,
          105.82920626571678,
          21.025670377921593
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82915156359454,
            21.025472087761184
          ],
          [
            105.82919419577294,
            21.025595269094417
          ],
          [
            105.82920626571678,
            21.025670377921593
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "50-7",
        "diemCuoi": "50-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.025472087761184,
        "to_latitude": 21.025670377921593,
        "to_longitude": 105.82920626571678,
        "from_longitude": 105.82915156359454,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6316,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82920626571678,
          21.025644402789293,
          105.82954657096552,
          21.025711687759852
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82954657096552,
            21.025644402789293
          ],
          [
            105.82926393321017,
            21.025706680507163
          ],
          [
            105.82921699455672,
            21.025711687759852
          ],
          [
            105.82920626571678,
            21.025670377921593
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "50-8",
        "diemCuoi": "50-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.025644402789293,
        "to_latitude": 21.025670377921593,
        "to_longitude": 105.82920626571678,
        "from_longitude": 105.82954657096552,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6317,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82892798651532,
          21.025670377921593,
          105.82920626571678,
          21.025712313666972
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82920626571678,
            21.025670377921593
          ],
          [
            105.82892798651532,
            21.025712313666972
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 160 Hào Nam",
        "diemDau": "50-8",
        "diemCuoi": "50-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.025670377921593,
        "to_latitude": 21.025712313666972,
        "to_longitude": 105.82892798651532,
        "from_longitude": 105.82920626571678,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6318,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82864635456767,
          21.02570542869459,
          105.82892798651532,
          21.025746738523143
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82892798651532,
            21.025712313666972
          ],
          [
            105.82877443006369,
            21.02570542869459
          ],
          [
            105.82877577116759,
            21.025722954078006
          ],
          [
            105.82872346809863,
            21.025724205890423
          ],
          [
            105.82871944477803,
            21.02573171676894
          ],
          [
            105.82868323495555,
            21.02573171676894
          ],
          [
            105.82867787054006,
            21.025746738523143
          ],
          [
            105.82864635456767,
            21.02574486080479
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 160 Hào Nam",
        "diemDau": "50-12",
        "diemCuoi": "50-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.025712313666972,
        "to_latitude": 21.02574486080479,
        "to_longitude": 105.82864635456767,
        "from_longitude": 105.82892798651532,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6319,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8283499704774,
          21.02574486080479,
          105.82864635456767,
          21.025821221368215
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82864635456767,
            21.02574486080479
          ],
          [
            105.8283499704774,
            21.025821221368215
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 160 Hào Nam",
        "diemDau": "50-13",
        "diemCuoi": "50-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02574486080479,
        "to_latitude": 21.025821221368215,
        "to_longitude": 105.8283499704774,
        "from_longitude": 105.82864635456767,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6320,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82800128330028,
          21.025821221368215,
          105.8283499704774,
          21.02586816431774
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8283499704774,
            21.025821221368215
          ],
          [
            105.82812667658858,
            21.02586816431774
          ],
          [
            105.82811594774863,
            21.025833113582976
          ],
          [
            105.82800128330028,
            21.02585376848139
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 160 Hào Nam",
        "diemDau": "50-14",
        "diemCuoi": "50-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.025821221368215,
        "to_latitude": 21.02585376848139,
        "to_longitude": 105.82800128330028,
        "from_longitude": 105.8283499704774,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6321,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82881164571184,
          21.024625422969876,
          105.82894173283223,
          21.024666733096893
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82894173283223,
            21.024625422969876
          ],
          [
            105.82881164571184,
            21.024666733096893
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 199 QT1",
        "diemDau": "50-5",
        "diemCuoi": "50-5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.024625422969876,
        "to_latitude": 21.024666733096893,
        "to_longitude": 105.82881164571184,
        "from_longitude": 105.82894173283223,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6322,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82899671813587,
          21.024402598443988,
          105.82901415246822,
          21.02449523339987
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82901415246822,
            21.02449523339987
          ],
          [
            105.82899671813587,
            21.024402598443988
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 163 QT1",
        "diemDau": "50-4",
        "diemCuoi": "50-22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02449523339987,
        "to_latitude": 21.024402598443988,
        "to_longitude": 105.82899671813587,
        "from_longitude": 105.82901415246822,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6323,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82870838065986,
          21.024402285488538,
          105.82899671813587,
          21.02440885756482
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82899671813587,
            21.024402598443988
          ],
          [
            105.82883612087768,
            21.024402285488538
          ],
          [
            105.82870838065986,
            21.02440885756482
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 163 QT1",
        "diemDau": "50-22",
        "diemCuoi": "50-23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.024402598443988,
        "to_latitude": 21.02440885756482,
        "to_longitude": 105.82870838065986,
        "from_longitude": 105.82899671813587,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6324,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82896685073979,
          21.02398949591584,
          105.82915362734268,
          21.024010469928157
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82915362734268,
            21.02398949591584
          ],
          [
            105.8290091233579,
            21.02399293844127
          ],
          [
            105.82900375893344,
            21.024005456716164
          ],
          [
            105.82896685073979,
            21.024010469928157
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 153 QT1",
        "diemDau": "50-3",
        "diemCuoi": "50-3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02398949591584,
        "to_latitude": 21.024010469928157,
        "to_longitude": 105.82896685073979,
        "from_longitude": 105.82915362734268,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6325,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82903351387321,
          21.023716284295524,
          105.82921263593994,
          21.023732105223463
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82921263593994,
            21.023720352743556
          ],
          [
            105.82906142642683,
            21.023716284295524
          ],
          [
            105.82903351387321,
            21.023732105223463
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 143 QT1",
        "diemDau": "50-2",
        "diemCuoi": "50-2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.023720352743556,
        "to_latitude": 21.023732105223463,
        "to_longitude": 105.82903351387321,
        "from_longitude": 105.82921263593994,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6326,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82924783995476,
          21.0232484121463,
          105.82937088626859,
          21.023485008547286
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82926359790501,
            21.023485008547286
          ],
          [
            105.82924783995476,
            21.02332821653012
          ],
          [
            105.82937088626859,
            21.0232484121463
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT X81",
        "diemDau": "50-1",
        "diemCuoi": "50-24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.023485008547286,
        "to_latitude": 21.0232484121463,
        "to_longitude": 105.82937088626859,
        "from_longitude": 105.82926359790501,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6327,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82937088626859,
          21.023239336350652,
          105.82962301391312,
          21.02333353682042
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82937088626859,
            21.0232484121463
          ],
          [
            105.82951337864696,
            21.023239336350652
          ],
          [
            105.82962301391312,
            21.02333353682042
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT X81",
        "diemDau": "50-24",
        "diemCuoi": "DDHN 50-25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.0232484121463,
        "to_latitude": 21.02333353682042,
        "to_longitude": 105.82962301391312,
        "from_longitude": 105.82937088626859,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6328,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82921967676447,
          21.023177057602805,
          105.82928505557592,
          21.023485008547286
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82926359790501,
            21.023485008547286
          ],
          [
            105.82921967676447,
            21.023249351021626
          ],
          [
            105.82926259210629,
            21.023235580849075
          ],
          [
            105.82928505557592,
            21.023177057602805
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 130 Thịnh Hào 1",
        "diemDau": "50-1",
        "diemCuoi": "50-26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.023485008547286,
        "to_latitude": 21.023177057602805,
        "to_longitude": 105.82928505557592,
        "from_longitude": 105.82926359790501,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6329,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82928505557592,
          21.023105703024328,
          105.8294862212554,
          21.023177057602805
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82928505557592,
            21.023177057602805
          ],
          [
            105.8294862212554,
            21.023105703024328
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 130 Thịnh Hào 1",
        "diemDau": "50-26",
        "diemCuoi": "DDHN 50-27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.023177057602805,
        "to_latitude": 21.023105703024328,
        "to_longitude": 105.8294862212554,
        "from_longitude": 105.82928505557592,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6330,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82900256066378,
          21.02313324339215,
          105.82912814635115,
          21.023175532636564
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82912814635115,
            21.02313324339215
          ],
          [
            105.82900256066378,
            21.023175532636564
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 127 QT1",
        "diemDau": "50",
        "diemCuoi": "49",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02313324339215,
        "to_latitude": 21.023175532636564,
        "to_longitude": 105.82900256066378,
        "from_longitude": 105.82912814635115,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6331,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8288129868157,
          21.023175532636564,
          105.82900256066378,
          21.023254671314735
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82900256066378,
            21.023175532636564
          ],
          [
            105.82882539203776,
            21.023229321679846
          ],
          [
            105.8288129868157,
            21.023254671314735
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 127 QT1",
        "diemDau": "49",
        "diemCuoi": "48",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.023175532636564,
        "to_latitude": 21.023254671314735,
        "to_longitude": 105.8288129868157,
        "from_longitude": 105.82900256066378,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6332,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82880091687188,
          21.023254671314735,
          105.8288129868157,
          21.023406143121765
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8288129868157,
            21.023254671314735
          ],
          [
            105.82880393436683,
            21.023333223862718
          ],
          [
            105.82880091687188,
            21.023406143121765
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 127 QT1",
        "diemDau": "48",
        "diemCuoi": "48-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.023254671314735,
        "to_latitude": 21.023406143121765,
        "to_longitude": 105.82880091687188,
        "from_longitude": 105.8288129868157,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6333,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82880091687188,
          21.023406143121765,
          105.8288532199498,
          21.023607688014398
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82880091687188,
            21.023406143121765
          ],
          [
            105.82883477976485,
            21.023533517022308
          ],
          [
            105.8288532199498,
            21.023607688014398
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 127 QT1",
        "diemDau": "48-3",
        "diemCuoi": "48-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.023406143121765,
        "to_latitude": 21.023607688014398,
        "to_longitude": 105.8288532199498,
        "from_longitude": 105.82880091687188,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6334,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82853537817967,
          21.023254671314735,
          105.8288129868157,
          21.02327814319589
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8288129868157,
            21.023254671314735
          ],
          [
            105.82858935763966,
            21.02327814319589
          ],
          [
            105.82853537817967,
            21.02326218231765
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 127 QT1",
        "diemDau": "48",
        "diemCuoi": "48-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.023254671314735,
        "to_latitude": 21.02326218231765,
        "to_longitude": 105.82853537817967,
        "from_longitude": 105.8288129868157,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6335,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82853537817967,
          21.02326218231765,
          105.82860377451303,
          21.02341866144593
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82853537817967,
            21.02326218231765
          ],
          [
            105.8285786288087,
            21.02330568353184
          ],
          [
            105.82860377451303,
            21.02341866144593
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 127 QT1",
        "diemDau": "48-1",
        "diemCuoi": "48-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02326218231765,
        "to_latitude": 21.02341866144593,
        "to_longitude": 105.82860377451303,
        "from_longitude": 105.82853537817967,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6336,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8283824922665,
          21.02341866144593,
          105.82860377451303,
          21.023434935265723
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82860377451303,
            21.02341866144593
          ],
          [
            105.8283824922665,
            21.023434935265723
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 127 QT1",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02341866144593,
        "to_latitude": 21.023434935265723,
        "to_longitude": 105.8283824922665,
        "from_longitude": 105.82860377451303,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6337,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82836774011494,
          21.023434935265723,
          105.8283824922665,
          21.023626465475324
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8283824922665,
            21.023434935265723
          ],
          [
            105.82836774011494,
            21.023626465475324
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 127 QT1",
        "diemDau": "48-3",
        "diemCuoi": "48-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.023434935265723,
        "to_latitude": 21.023626465475324,
        "to_longitude": 105.82836774011494,
        "from_longitude": 105.8283824922665,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6338,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82836774011494,
          21.023626465475324,
          105.82848206928507,
          21.023724108232095
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82836774011494,
            21.023626465475324
          ],
          [
            105.82848206928507,
            21.023646181806708
          ],
          [
            105.82847771068624,
            21.023724108232095
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 127 QT1",
        "diemDau": "48-4",
        "diemCuoi": "48-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.023626465475324,
        "to_latitude": 21.023724108232095,
        "to_longitude": 105.82847771068624,
        "from_longitude": 105.82836774011494,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6339,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82915362734268,
          21.02398949591584,
          105.82930517216992,
          21.0241297005385
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82915362734268,
            21.02398949591584
          ],
          [
            105.82919151357417,
            21.024069299902948
          ],
          [
            105.82930517216992,
            21.0241297005385
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 116 QT1",
        "diemDau": "50-3",
        "diemCuoi": "50-3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02398949591584,
        "to_latitude": 21.0241297005385,
        "to_longitude": 105.82930517216992,
        "from_longitude": 105.82915362734268,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6340,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83831169476537,
          21.012497588845164,
          105.8384243475444,
          21.012562688881168
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83831169476537,
            21.012497588845164
          ],
          [
            105.8384243475444,
            21.012562688881168
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012497588845164,
        "to_latitude": 21.012562688881168,
        "to_longitude": 105.8384243475444,
        "from_longitude": 105.83831169476537,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6341,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83838947882579,
          21.012562688881168,
          105.8384243475444,
          21.0126453158101
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8384243475444,
            21.012562688881168
          ],
          [
            105.83838947882579,
            21.0126453158101
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012562688881168,
        "to_latitude": 21.0126453158101,
        "to_longitude": 105.83838947882579,
        "from_longitude": 105.8384243475444,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6342,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83752547222346,
          21.01255768118773,
          105.83783962597816,
          21.01291103619208
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83783962597816,
            21.01255768118773
          ],
          [
            105.83752547222346,
            21.01291103619208
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10-1",
        "diemCuoi": "10-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01255768118773,
        "to_latitude": 21.01291103619208,
        "to_longitude": 105.83752547222346,
        "from_longitude": 105.83783962597816,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6343,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83849408498162,
          21.01204689550419,
          105.83860137333623,
          21.012157065113023
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83860137333623,
            21.012157065113023
          ],
          [
            105.83849408498162,
            21.01204689550419
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10-1",
        "diemCuoi": "10-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012157065113023,
        "to_latitude": 21.01204689550419,
        "to_longitude": 105.83849408498162,
        "from_longitude": 105.83860137333623,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6344,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8010389409465,
          21.02652839830925,
          105.80121462564342,
          21.026553434432167
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80121462564342,
            21.02652839830925
          ],
          [
            105.8010389409465,
            21.026553434432167
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/90 Đường Láng",
        "diemDau": "08",
        "diemCuoi": "8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02652839830925,
        "to_latitude": 21.026553434432167,
        "to_longitude": 105.8010389409465,
        "from_longitude": 105.80121462564342,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6345,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80079083661356,
          21.026553434432167,
          105.8010389409465,
          21.026573463328155
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8010389409465,
            21.026553434432167
          ],
          [
            105.80079083661356,
            21.026573463328155
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/90 Đường Láng",
        "diemDau": "8-1",
        "diemCuoi": "8-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.026553434432167,
        "to_latitude": 21.026573463328155,
        "to_longitude": 105.80079083661356,
        "from_longitude": 105.8010389409465,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6346,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8007116948394,
          21.026413623134914,
          105.80079083661356,
          21.026573463328155
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80079083661356,
            21.026573463328155
          ],
          [
            105.80075194458334,
            21.026430757389363
          ],
          [
            105.8007116948394,
            21.026413623134914
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/90 Đường Láng",
        "diemDau": "8-2",
        "diemCuoi": "8-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.026573463328155,
        "to_latitude": 21.026413623134914,
        "to_longitude": 105.8007116948394,
        "from_longitude": 105.80079083661356,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6347,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80067684273064,
          21.02619541747303,
          105.8007116948394,
          21.026413623134914
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8007116948394,
            21.026413623134914
          ],
          [
            105.80069025377833,
            21.026405721245848
          ],
          [
            105.80068354825897,
            21.026299317587696
          ],
          [
            105.80067684273064,
            21.02624674163541
          ],
          [
            105.80068086604226,
            21.02619541747303
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/90 Đường Láng",
        "diemDau": "8-3",
        "diemCuoi": "8-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.026413623134914,
        "to_latitude": 21.02619541747303,
        "to_longitude": 105.80068086604226,
        "from_longitude": 105.8007116948394,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6348,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80068086604226,
          21.02600059607914,
          105.80075901844782,
          21.02619541747303
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80068086604226,
            21.02619541747303
          ],
          [
            105.80074792127174,
            21.026167877670947
          ],
          [
            105.80075901844782,
            21.02600059607914
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/90 Đường Láng",
        "diemDau": "8-4",
        "diemCuoi": "8-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02619541747303,
        "to_latitude": 21.02600059607914,
        "to_longitude": 105.80075901844782,
        "from_longitude": 105.80068086604226,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6349,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82897861325606,
          21.025670377921593,
          105.82922939979672,
          21.02630348123412
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82920626571678,
            21.025670377921593
          ],
          [
            105.82922939979672,
            21.025851577806964
          ],
          [
            105.82897861325606,
            21.02591291658059
          ],
          [
            105.82909394824287,
            21.02630348123412
          ],
          [
            105.82920190711796,
            21.026260293847983
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 68,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 160/23 Hào Nam",
        "diemDau": "50-8",
        "diemCuoi": "50-16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.025670377921593,
        "to_latitude": 21.026260293847983,
        "to_longitude": 105.82920190711796,
        "from_longitude": 105.82920626571678,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6350,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82920190711796,
          21.026198955218156,
          105.82954522987063,
          21.026260293847983
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82920190711796,
            21.026260293847983
          ],
          [
            105.82937423908666,
            21.026215854637723
          ],
          [
            105.82947884524249,
            21.026199581121528
          ],
          [
            105.82954522987063,
            21.026198955218156
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 160/23 Hào Nam",
        "diemDau": "50-16",
        "diemCuoi": "50-17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.026260293847983,
        "to_latitude": 21.026198955218156,
        "to_longitude": 105.82954522987063,
        "from_longitude": 105.82920190711796,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6351,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82920190711796,
          21.026260293847983,
          105.82923945804433,
          21.02648311559609
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82920190711796,
            21.026260293847983
          ],
          [
            105.82920660102194,
            21.026351049936174
          ],
          [
            105.82921464765413,
            21.026376086088884
          ],
          [
            105.82923945804433,
            21.02648311559609
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 160/23 Hào Nam",
        "diemDau": "50-16",
        "diemCuoi": "50-18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.026260293847983,
        "to_latitude": 21.02648311559609,
        "to_longitude": 105.82923945804433,
        "from_longitude": 105.82920190711796,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6352,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82923945804433,
          21.02648311559609,
          105.82931321879315,
          21.026762268215066
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82923945804433,
            21.02648311559609
          ],
          [
            105.82926292741146,
            21.026600159469787
          ],
          [
            105.82927633845915,
            21.02660641849748
          ],
          [
            105.82931321879315,
            21.026762268215066
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 160/23 Hào Nam",
        "diemDau": "50-18",
        "diemCuoi": "DDHN 50-19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02648311559609,
        "to_latitude": 21.026762268215066,
        "to_longitude": 105.82931321879315,
        "from_longitude": 105.82923945804433,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6353,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82931321879315,
          21.02672471408173,
          105.82942855377995,
          21.026762268215066
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82931321879315,
            21.026762268215066
          ],
          [
            105.82942788326844,
            21.02674161344169
          ],
          [
            105.82942855377995,
            21.02672471408173
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 160/23 Hào Nam",
        "diemDau": "DDHN 50-19",
        "diemCuoi": "50-20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.026762268215066,
        "to_latitude": 21.02672471408173,
        "to_longitude": 105.82942855377995,
        "from_longitude": 105.82931321879315,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6354,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82942855377995,
          21.02672471408173,
          105.8295599820222,
          21.02673723212724
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82942855377995,
            21.02672471408173
          ],
          [
            105.8295599820222,
            21.02673723212724
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "DDHN",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 160/23 Hào Nam",
        "diemDau": "50-20",
        "diemCuoi": "DDHN 50-21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Di Dân Hào Nam",
        "from_latitude": 21.02672471408173,
        "to_latitude": 21.02673723212724,
        "to_longitude": 105.8295599820222,
        "from_longitude": 105.82942855377995,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6355,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83276815542925,
          21.010282214532012,
          105.83289153703929,
          21.01037736214309
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83276815542925,
            21.010282214532012
          ],
          [
            105.83289153703929,
            21.01037736214309
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TTCT2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng 2",
        "from_latitude": 21.010282214532012,
        "to_latitude": 21.01037736214309,
        "to_longitude": 105.83289153703929,
        "from_longitude": 105.83276815542925,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6356,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83289153703929,
          21.01037736214309,
          105.83301760086603,
          21.010471257752172
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83289153703929,
            21.01037736214309
          ],
          [
            105.83301760086603,
            21.010471257752172
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TTCT2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng 2",
        "from_latitude": 21.01037736214309,
        "to_latitude": 21.010471257752172,
        "to_longitude": 105.83301760086603,
        "from_longitude": 105.83289153703929,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6357,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83301760086603,
          21.010471257752172,
          105.83312354812574,
          21.010562649421104
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83301760086603,
            21.010471257752172
          ],
          [
            105.83312354812574,
            21.010562649421104
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TTCT2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng 2",
        "from_latitude": 21.010471257752172,
        "to_latitude": 21.010562649421104,
        "to_longitude": 105.83312354812574,
        "from_longitude": 105.83301760086603,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6358,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83312354812574,
          21.010537610613245,
          105.83327777514278,
          21.010562649421104
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83312354812574,
            21.010562649421104
          ],
          [
            105.83327777514278,
            21.010537610613245
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TTCT2",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng 2",
        "from_latitude": 21.010562649421104,
        "to_latitude": 21.010537610613245,
        "to_longitude": 105.83327777514278,
        "from_longitude": 105.83312354812574,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6359,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83327777514278,
          21.01038862961899,
          105.8334494365191,
          21.010537610613245
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83327777514278,
            21.010537610613245
          ],
          [
            105.8334494365191,
            21.01038862961899
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TTCT2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng 2",
        "from_latitude": 21.010537610613245,
        "to_latitude": 21.01038862961899,
        "to_longitude": 105.8334494365191,
        "from_longitude": 105.83327777514278,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6360,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8334494365191,
          21.010274468135837,
          105.83355446176597,
          21.01038862961899
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8334494365191,
            21.01038862961899
          ],
          [
            105.83355446176597,
            21.010274468135837
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TTCT2",
        "chieuDaiQL": 8,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng 2",
        "from_latitude": 21.01038862961899,
        "to_latitude": 21.010274468135837,
        "to_longitude": 105.83355446176597,
        "from_longitude": 105.8334494365191,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6361,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83339981564895,
          21.010245673450907,
          105.83351556973575,
          21.010358583013847
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83339981564895,
            21.010358583013847
          ],
          [
            105.83351556973575,
            21.010245673450907
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TTCT2",
        "chieuDaiQL": 8,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng 2",
        "from_latitude": 21.010358583013847,
        "to_latitude": 21.010245673450907,
        "to_longitude": 105.83351556973575,
        "from_longitude": 105.83339981564895,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6362,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83333678374005,
          21.01023714458944,
          105.83339981564895,
          21.010283466474046
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83339981564895,
            21.010283466474046
          ],
          [
            105.83333678374005,
            21.01023714458944
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TTCT2",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng 2",
        "from_latitude": 21.010283466474046,
        "to_latitude": 21.01023714458944,
        "to_longitude": 105.83333678374005,
        "from_longitude": 105.83339981564895,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6363,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8332817984544,
          21.01019207463408,
          105.83333678374005,
          21.01023714458944
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83333678374005,
            21.01023714458944
          ],
          [
            105.8332817984544,
            21.01019207463408
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TTCT2",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng 2",
        "from_latitude": 21.01023714458944,
        "to_latitude": 21.01019207463408,
        "to_longitude": 105.8332817984544,
        "from_longitude": 105.83333678374005,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6364,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8332187665455,
          21.010145752721098,
          105.8332817984544,
          21.01019207463408
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8332817984544,
            21.01019207463408
          ],
          [
            105.8332187665455,
            21.010145752721098
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TTCT2",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng 2",
        "from_latitude": 21.01019207463408,
        "to_latitude": 21.010145752721098,
        "to_longitude": 105.8332187665455,
        "from_longitude": 105.8332817984544,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6365,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83268500695331,
          21.01008816329512,
          105.83289824256761,
          21.01017079159473
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83268500695331,
            21.01017079159473
          ],
          [
            105.83289824256761,
            21.01008816329512
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TTCT2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng 2",
        "from_latitude": 21.01017079159473,
        "to_latitude": 21.01008816329512,
        "to_longitude": 105.83289824256761,
        "from_longitude": 105.83268500695331,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6366,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83289824256761,
          21.01003307773691,
          105.83316244015597,
          21.01008816329512
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83289824256761,
            21.01008816329512
          ],
          [
            105.83316244015597,
            21.01003307773691
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TTCT2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng 2",
        "from_latitude": 21.01008816329512,
        "to_latitude": 21.01003307773691,
        "to_longitude": 105.83316244015597,
        "from_longitude": 105.83289824256761,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6367,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83316244015597,
          21.0100255660685,
          105.83344273099976,
          21.01003307773691
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83316244015597,
            21.01003307773691
          ],
          [
            105.83344273099976,
            21.0100255660685
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TTCT2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng 2",
        "from_latitude": 21.01003307773691,
        "to_latitude": 21.0100255660685,
        "to_longitude": 105.83344273099976,
        "from_longitude": 105.83316244015597,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6368,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83351112732413,
          21.01011946189901,
          105.83355404266597,
          21.010159524101528
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83351112732413,
            21.010159524101528
          ],
          [
            105.83355404266597,
            21.01011946189901
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TTCT2",
        "chieuDaiQL": 6,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng 2",
        "from_latitude": 21.010159524101528,
        "to_latitude": 21.01011946189901,
        "to_longitude": 105.83355404266597,
        "from_longitude": 105.83351112732413,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6369,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83344273099976,
          21.01002556606848,
          105.83355404266597,
          21.01011946189902
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83355404266597,
            21.01011946189902
          ],
          [
            105.83344273099976,
            21.01002556606848
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TTCT2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng 2",
        "from_latitude": 21.01011946189902,
        "to_latitude": 21.01002556606848,
        "to_longitude": 105.83344273099976,
        "from_longitude": 105.83355404266597,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6370,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83351112732413,
          21.010159524101528,
          105.83351556973575,
          21.010245673450907
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83351556973575,
            21.010245673450907
          ],
          [
            105.83351112732413,
            21.010159524101528
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TTCT2",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "diemDau": "08",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng 2",
        "from_latitude": 21.010245673450907,
        "to_latitude": 21.010159524101528,
        "to_longitude": 105.83351112732413,
        "from_longitude": 105.83351556973575,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6371,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83351556973575,
          21.010245673450907,
          105.83355446176597,
          21.010274468135837
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83351556973575,
            21.010245673450907
          ],
          [
            105.83355446176597,
            21.010274468135837
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TTCT2",
        "chieuDaiQL": 6,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "diemDau": "01",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng 2",
        "from_latitude": 21.010245673450907,
        "to_latitude": 21.010274468135837,
        "to_longitude": 105.83355446176597,
        "from_longitude": 105.83351556973575,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6372,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83355446176597,
          21.010274468135837,
          105.83358396606012,
          21.010304514757937
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83358396606012,
            21.010304514757937
          ],
          [
            105.83355446176597,
            21.010274468135837
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TTCT2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "diemDau": "",
        "diemCuoi": "01",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng 2",
        "from_latitude": 21.010304514757937,
        "to_latitude": 21.010274468135837,
        "to_longitude": 105.83355446176597,
        "from_longitude": 105.83358396606012,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6373,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83339981564895,
          21.010283466474057,
          105.83339981564895,
          21.010358583013847
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83339981564895,
            21.010358583013847
          ],
          [
            105.83339981564895,
            21.010283466474057
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "TTCT2",
        "chieuDaiQL": 5,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự Cao Tầng 2",
        "from_latitude": 21.010358583013847,
        "to_latitude": 21.010283466474057,
        "to_longitude": 105.83339981564895,
        "from_longitude": 105.83339981564895,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6374,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80133800725345,
          21.026982803288146,
          105.80143993120154,
          21.02702160915882
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80133800725345,
            21.02702160915882
          ],
          [
            105.80143993120154,
            21.026982803288146
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/73 Đường Láng",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "VH1",
        "from_latitude": 21.02702160915882,
        "to_latitude": 21.026982803288146,
        "to_longitude": 105.80143993120154,
        "from_longitude": 105.80133800725345,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6375,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80143993120154,
          21.026982803288146,
          105.8015684634751,
          21.027164831952696
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80143993120154,
            21.026982803288146
          ],
          [
            105.8015684634751,
            21.027164831952696
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/73 Đường Láng",
        "diemDau": "11-1",
        "diemCuoi": "11-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "VH1",
        "from_latitude": 21.026982803288146,
        "to_latitude": 21.027164831952696,
        "to_longitude": 105.8015684634751,
        "from_longitude": 105.80143993120154,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6376,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80143993120154,
          21.026871338646046,
          105.80165104224804,
          21.026982803288146
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80143993120154,
            21.026982803288146
          ],
          [
            105.80165104224804,
            21.026871338646046
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/73 Đường Láng",
        "diemDau": "11-1",
        "diemCuoi": "11-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "VH1",
        "from_latitude": 21.026982803288146,
        "to_latitude": 21.026871338646046,
        "to_longitude": 105.80165104224804,
        "from_longitude": 105.80143993120154,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6377,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80165104224804,
          21.026871338646046,
          105.80176487726088,
          21.02705203817261
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80165104224804,
            21.026871338646046
          ],
          [
            105.80176487726088,
            21.02705203817261
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/73 Đường Láng",
        "diemDau": "11-3",
        "diemCuoi": "11-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "VH1",
        "from_latitude": 21.026871338646046,
        "to_latitude": 21.02705203817261,
        "to_longitude": 105.80176487726088,
        "from_longitude": 105.80165104224804,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6378,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80165104224804,
          21.02680004001996,
          105.80176850180264,
          21.026871338646046
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80165104224804,
            21.026871338646046
          ],
          [
            105.80176850180264,
            21.02680004001996
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/73 Đường Láng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "VH1",
        "from_latitude": 21.026871338646046,
        "to_latitude": 21.02680004001996,
        "to_longitude": 105.80176850180264,
        "from_longitude": 105.80165104224804,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6379,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80144529561703,
          21.026757478678736,
          105.80165104224804,
          21.026897680697946
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80165104224804,
            21.026871338646046
          ],
          [
            105.80154587845226,
            21.026897680697946
          ],
          [
            105.80144529561703,
            21.026757478678736
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/73 Đường Láng",
        "diemDau": "11-3",
        "diemCuoi": "11-9",
        "chuDauTu": "SoXayDung",
        "tuDien2": "VH1",
        "from_latitude": 21.026871338646046,
        "to_latitude": 21.026757478678736,
        "to_longitude": 105.80144529561703,
        "from_longitude": 105.80165104224804,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6380,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80166496827971,
          21.026614020198746,
          105.80176850180264,
          21.02680004001996
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80176850180264,
            21.02680004001996
          ],
          [
            105.80166496827971,
            21.026614020198746
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/73 Đường Láng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "VH1",
        "from_latitude": 21.02680004001996,
        "to_latitude": 21.026614020198746,
        "to_longitude": 105.80166496827971,
        "from_longitude": 105.80176850180264,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6381,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80133648848525,
          21.02660348420578,
          105.80144529561703,
          21.026757478678736
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80144529561703,
            21.026757478678736
          ],
          [
            105.80133648848525,
            21.02660348420578
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/73 Đường Láng",
        "diemDau": "11-9",
        "diemCuoi": "11-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.026757478678736,
        "to_latitude": 21.02660348420578,
        "to_longitude": 105.80133648848525,
        "from_longitude": 105.80144529561703,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6382,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80133648848525,
          21.02638945199202,
          105.80167456627183,
          21.02660348420578
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80133648848525,
            21.02660348420578
          ],
          [
            105.80135044974462,
            21.026564289852487
          ],
          [
            105.80140313201602,
            21.026535871520444
          ],
          [
            105.80149621036998,
            21.026485662366653
          ],
          [
            105.80167456627183,
            21.02638945199202
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/73 Đường Láng",
        "diemDau": "11-8",
        "diemCuoi": "11-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02660348420578,
        "to_latitude": 21.02638945199202,
        "to_longitude": 105.80167456627183,
        "from_longitude": 105.80133648848525,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6383,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82639698701979,
          21.017783431523515,
          105.82664643246555,
          21.0180813785916
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82639698701979,
            21.017783431523515
          ],
          [
            105.82664643246555,
            21.0180813785916
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64/65 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017783431523515,
        "to_latitude": 21.0180813785916,
        "to_longitude": 105.82664643246555,
        "from_longitude": 105.82639698701979,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6384,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82664643246555,
          21.017996250918863,
          105.82682614046512,
          21.0180813785916
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82664643246555,
            21.0180813785916
          ],
          [
            105.82682614046512,
            21.017996250918863
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.0180813785916,
        "to_latitude": 21.017996250918863,
        "to_longitude": 105.82682614046512,
        "from_longitude": 105.82664643246555,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6385,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82674198621744,
          21.017750882648464,
          105.82690928894105,
          21.017996250918863
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82682614046512,
            21.017996250918863
          ],
          [
            105.82674198621744,
            21.017851971921218
          ],
          [
            105.82690928894105,
            21.017750882648464
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017996250918863,
        "to_latitude": 21.017750882648464,
        "to_longitude": 105.82690928894105,
        "from_longitude": 105.82682614046512,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6386,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82690928894105,
          21.017663251025162,
          105.82708631474188,
          21.017750882648464
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82690928894105,
            21.017750882648464
          ],
          [
            105.82708631474188,
            21.017663251025162
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017750882648464,
        "to_latitude": 21.017663251025162,
        "to_longitude": 105.82708631474188,
        "from_longitude": 105.82690928894105,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6387,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82682614046512,
          21.017841956888542,
          105.82716041082281,
          21.017996250918863
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82682614046512,
            21.017996250918863
          ],
          [
            105.82716041082281,
            21.017841956888542
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017996250918863,
        "to_latitude": 21.017841956888542,
        "to_longitude": 105.82716041082281,
        "from_longitude": 105.82682614046512,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6388,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82716041082281,
          21.017670762309802,
          105.82737331110502,
          21.017841956888542
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82716041082281,
            21.017841956888542
          ],
          [
            105.82737331110502,
            21.017670762309802
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017841956888542,
        "to_latitude": 21.017670762309802,
        "to_longitude": 105.82737331110502,
        "from_longitude": 105.82716041082281,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6389,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82718019205775,
          21.01747797256786,
          105.82737331110502,
          21.017670762309802
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82737331110502,
            21.017670762309802
          ],
          [
            105.82718019205775,
            21.01747797256786
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017670762309802,
        "to_latitude": 21.01747797256786,
        "to_longitude": 105.82718019205775,
        "from_longitude": 105.82737331110502,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6390,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82737331110502,
          21.017523040321667,
          105.82764689642045,
          21.017670762309802
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82737331110502,
            21.017670762309802
          ],
          [
            105.82764689642045,
            21.017523040321667
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017670762309802,
        "to_latitude": 21.017523040321667,
        "to_longitude": 105.82764689642045,
        "from_longitude": 105.82737331110502,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6391,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82746987062863,
          21.017460446215257,
          105.82764689642045,
          21.017566543211956
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82764689642045,
            21.017523040321667
          ],
          [
            105.82753592008636,
            21.017566543211956
          ],
          [
            105.82746987062863,
            21.017460446215257
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017523040321667,
        "to_latitude": 21.017460446215257,
        "to_longitude": 105.82746987062863,
        "from_longitude": 105.82764689642045,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6392,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82755301910457,
          21.017235107212933,
          105.82768880599951,
          21.017523040321667
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82764689642045,
            21.017523040321667
          ],
          [
            105.82768880599951,
            21.01749894159462
          ],
          [
            105.82755301910457,
            21.017235107212933
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017523040321667,
        "to_latitude": 21.017235107212933,
        "to_longitude": 105.82755301910457,
        "from_longitude": 105.82764689642045,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6393,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82741086203139,
          21.017235107212933,
          105.82755301910457,
          21.017315227785673
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82755301910457,
            21.017235107212933
          ],
          [
            105.82741086203139,
            21.017315227785673
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017235107212933,
        "to_latitude": 21.017315227785673,
        "to_longitude": 105.82741086203139,
        "from_longitude": 105.82755301910457,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6394,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82792316395263,
          21.01744041609498,
          105.82802240568401,
          21.01760316073969
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82792316395263,
            21.01744041609498
          ],
          [
            105.82802240568401,
            21.01760316073969
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01744041609498,
        "to_latitude": 21.01760316073969,
        "to_longitude": 105.82802240568401,
        "from_longitude": 105.82792316395263,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6395,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82792316395263,
          21.017405363378877,
          105.8280089946453,
          21.01744041609498
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82792316395263,
            21.01744041609498
          ],
          [
            105.8280089946453,
            21.017405363378877
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01744041609498,
        "to_latitude": 21.017405363378877,
        "to_longitude": 105.8280089946453,
        "from_longitude": 105.82792316395263,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6396,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8280089946453,
          21.017405363378877,
          105.82805727440262,
          21.01748798762498
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8280089946453,
            21.017405363378877
          ],
          [
            105.82805727440262,
            21.01748798762498
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017405363378877,
        "to_latitude": 21.01748798762498,
        "to_longitude": 105.82805727440262,
        "from_longitude": 105.8280089946453,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6397,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8280089946453,
          21.017371249568534,
          105.8281779738049,
          21.017608168264545
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8280089946453,
            21.017405363378877
          ],
          [
            105.82808577293397,
            21.017371249568534
          ],
          [
            105.8281779738049,
            21.017608168264545
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017405363378877,
        "to_latitude": 21.017608168264545,
        "to_longitude": 105.8281779738049,
        "from_longitude": 105.8280089946453,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6398,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82763616758947,
          21.017002256553088,
          105.82778368908716,
          21.017235107212933
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82778368908716,
            21.017235107212933
          ],
          [
            105.82763616758947,
            21.017002256553088
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64/49/22 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017235107212933,
        "to_latitude": 21.017002256553088,
        "to_longitude": 105.82763616758947,
        "from_longitude": 105.82778368908716,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6399,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82778368908716,
          21.017132452665944,
          105.82799021918211,
          21.017235107212933
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82778368908716,
            21.017235107212933
          ],
          [
            105.82799021918211,
            21.017132452665944
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017235107212933,
        "to_latitude": 21.017132452665944,
        "to_longitude": 105.82799021918211,
        "from_longitude": 105.82778368908716,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6400,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82790438848946,
          21.016957188641896,
          105.82799021918211,
          21.017132452665944
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82799021918211,
            21.017132452665944
          ],
          [
            105.82790438848946,
            21.016957188641896
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64/49/22 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017132452665944,
        "to_latitude": 21.016957188641896,
        "to_longitude": 105.82790438848946,
        "from_longitude": 105.82799021918211,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6401,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82799021918211,
          21.01701978295956,
          105.82825039344986,
          21.017132452665944
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82799021918211,
            21.017132452665944
          ],
          [
            105.82825039344986,
            21.01701978295956
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017132452665944,
        "to_latitude": 21.01701978295956,
        "to_longitude": 105.82825039344986,
        "from_longitude": 105.82799021918211,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6402,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82669203001514,
          21.017137460206598,
          105.82698170858605,
          21.01725012982409
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82698170858605,
            21.017137460206598
          ],
          [
            105.82669203001514,
            21.01725012982409
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64/49/22 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017137460206598,
        "to_latitude": 21.01725012982409,
        "to_longitude": 105.82669203001514,
        "from_longitude": 105.82698170858605,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6403,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8264184446997,
          21.01725012982409,
          105.82669203001514,
          21.017345272989477
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82669203001514,
            21.01725012982409
          ],
          [
            105.8264184446997,
            21.017345272989477
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64/49/22 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01725012982409,
        "to_latitude": 21.017345272989477,
        "to_longitude": 105.8264184446997,
        "from_longitude": 105.82669203001514,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6404,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82616899925395,
          21.017490491389772,
          105.82639698701979,
          21.017783431523515
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82639698701979,
            21.017783431523515
          ],
          [
            105.82616899925395,
            21.017490491389772
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64/65 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017783431523515,
        "to_latitude": 21.017490491389772,
        "to_longitude": 105.82616899925395,
        "from_longitude": 105.82639698701979,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6405,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82616899925395,
          21.01732492988509,
          105.82632222050819,
          21.017490491389772
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82616899925395,
            21.017490491389772
          ],
          [
            105.82632222050819,
            21.01732492988509
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64/49/22 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017490491389772,
        "to_latitude": 21.01732492988509,
        "to_longitude": 105.82632222050819,
        "from_longitude": 105.82616899925395,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6406,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82632222050819,
          21.01732492988509,
          105.8264184446997,
          21.017345272989477
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82632222050819,
            21.01732492988509
          ],
          [
            105.8264184446997,
            21.017345272989477
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64/49/22 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01732492988509,
        "to_latitude": 21.017345272989477,
        "to_longitude": 105.8264184446997,
        "from_longitude": 105.82632222050819,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6407,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82619582134932,
          21.017127445125112,
          105.82632222050819,
          21.01732492988509
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82632222050819,
            21.01732492988509
          ],
          [
            105.82619582134932,
            21.017127445125112
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT LA Ze",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01732492988509,
        "to_latitude": 21.017127445125112,
        "to_longitude": 105.82619582134932,
        "from_longitude": 105.82632222050819,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6408,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82619582134932,
          21.017127445125112,
          105.826241754231,
          21.017163436821026
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82619582134932,
            21.017127445125112
          ],
          [
            105.826241754231,
            21.017163436821026
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 7,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT LA Ze",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017127445125112,
        "to_latitude": 21.017163436821026,
        "to_longitude": 105.826241754231,
        "from_longitude": 105.82619582134932,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6409,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.826241754231,
          21.017142467747078,
          105.82633261400703,
          21.017163436821026
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.826241754231,
            21.017163436821026
          ],
          [
            105.82633261400703,
            21.017142467747078
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT LA Ze",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017163436821026,
        "to_latitude": 21.017142467747078,
        "to_longitude": 105.82633261400703,
        "from_longitude": 105.826241754231,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6410,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82633261400703,
          21.017009767870995,
          105.82659815269925,
          21.017142467747078
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82633261400703,
            21.017142467747078
          ],
          [
            105.82659815269925,
            21.017009767870995
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 51,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT LA Ze",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017142467747078,
        "to_latitude": 21.017009767870995,
        "to_longitude": 105.82659815269925,
        "from_longitude": 105.82633261400703,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6411,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83518836448803,
          21.012653140330922,
          105.83541591482825,
          21.012889362314212
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83541591482825,
            21.012653140330922
          ],
          [
            105.83518836448803,
            21.012889362314212
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 81 Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.012653140330922,
        "to_latitude": 21.012889362314212,
        "to_longitude": 105.83518836448803,
        "from_longitude": 105.83541591482825,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6412,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83496932703261,
          21.012605567258746,
          105.83524603198144,
          21.012889362314212
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83518836448803,
            21.012889362314212
          ],
          [
            105.83524603198144,
            21.012747895200423
          ],
          [
            105.83496932703261,
            21.012605567258746
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 60,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 83 Xã Đàn",
        "diemDau": "",
        "diemCuoi": "19-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.012889362314212,
        "to_latitude": 21.012605567258746,
        "to_longitude": 105.83496932703261,
        "from_longitude": 105.83518836448803,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6413,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83486918161401,
          21.012741635590128,
          105.83518836448803,
          21.012889362314212
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83518836448803,
            21.012889362314212
          ],
          [
            105.83486918161401,
            21.012741635590128
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 89 Xã Đàn",
        "diemDau": "",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.012889362314212,
        "to_latitude": 21.012741635590128,
        "to_longitude": 105.83486918161401,
        "from_longitude": 105.83518836448803,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6414,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83473238894734,
          21.012741635590128,
          105.83486918161401,
          21.012950706419453
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83486918161401,
            21.012741635590128
          ],
          [
            105.83473238894734,
            21.012950706419453
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 89 Xã Đàn",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.012741635590128,
        "to_latitude": 21.012950706419453,
        "to_longitude": 105.83473238894734,
        "from_longitude": 105.83486918161401,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6415,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83454999874002,
          21.01281925473447,
          105.83473238894734,
          21.012950706419453
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83473238894734,
            21.012950706419453
          ],
          [
            105.83454999874002,
            21.01281925473447
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 89 Xã Đàn",
        "diemDau": "21",
        "diemCuoi": "21-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.012950706419453,
        "to_latitude": 21.01281925473447,
        "to_longitude": 105.83454999874002,
        "from_longitude": 105.83473238894734,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6416,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83465996931133,
          21.012943194897126,
          105.83473238894734,
          21.013002035140946
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83473238894734,
            21.012950706419453
          ],
          [
            105.83469349691708,
            21.012943194897126
          ],
          [
            105.83465996931133,
            21.012979500582237
          ],
          [
            105.83466801593455,
            21.013002035140946
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 89 Xã Đàn",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.012950706419453,
        "to_latitude": 21.013002035140946,
        "to_longitude": 105.83466801593455,
        "from_longitude": 105.83473238894734,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6417,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83461303064891,
          21.013002035140946,
          105.83468679139774,
          21.013161028873633
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83466801593455,
            21.013002035140946
          ],
          [
            105.83468679139774,
            21.013065883038095
          ],
          [
            105.83468545029388,
            21.013105944449304
          ],
          [
            105.83461303064891,
            21.013161028873633
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 89 Xã Đàn",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.013002035140946,
        "to_latitude": 21.013161028873633,
        "to_longitude": 105.83461303064891,
        "from_longitude": 105.83466801593455,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6418,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83444673368805,
          21.013161028873633,
          105.83461303064891,
          21.01330750326484
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83461303064891,
            21.013161028873633
          ],
          [
            105.83444673368805,
            21.01330750326484
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 89 Xã Đàn",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.013161028873633,
        "to_latitude": 21.01330750326484,
        "to_longitude": 105.83444673368805,
        "from_longitude": 105.83461303064891,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6419,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8343287164935,
          21.01323989971696,
          105.83444673368805,
          21.01330750326484
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83444673368805,
            21.01330750326484
          ],
          [
            105.83438236067529,
            21.01323989971696
          ],
          [
            105.8343287164935,
            21.01328747258681
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 89 Xã Đàn",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.01330750326484,
        "to_latitude": 21.01328747258681,
        "to_longitude": 105.8343287164935,
        "from_longitude": 105.83444673368805,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6420,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83498049328922,
          21.012811031664164,
          105.83501912085268,
          21.012888110393195
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83501912085268,
            21.012811031664164
          ],
          [
            105.83498049328922,
            21.012888110393195
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 89 Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.012811031664164,
        "to_latitude": 21.012888110393195,
        "to_longitude": 105.83498049328922,
        "from_longitude": 105.83501912085268,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6421,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8261358069713,
          21.01699443226462,
          105.82619582134932,
          21.017127445125112
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82619582134932,
            21.017127445125112
          ],
          [
            105.8261358069713,
            21.01699443226462
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51/10 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017127445125112,
        "to_latitude": 21.01699443226462,
        "to_longitude": 105.8261358069713,
        "from_longitude": 105.82619582134932,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6422,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8260375710117,
          21.016944669775206,
          105.82619582134932,
          21.017127445125112
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82619582134932,
            21.017127445125112
          ],
          [
            105.8260375710117,
            21.016944669775206
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51/10 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017127445125112,
        "to_latitude": 21.016944669775206,
        "to_longitude": 105.8260375710117,
        "from_longitude": 105.82619582134932,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6423,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82595174032802,
          21.016809465946903,
          105.8260375710117,
          21.016944669775206
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8260375710117,
            21.016944669775206
          ],
          [
            105.82595174032802,
            21.016809465946903
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51/10 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.016944669775206,
        "to_latitude": 21.016809465946903,
        "to_longitude": 105.82595174032802,
        "from_longitude": 105.8260375710117,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6424,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82584445196443,
          21.016596644858737,
          105.82595174032802,
          21.016809465946903
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82595174032802,
            21.016809465946903
          ],
          [
            105.82584445196443,
            21.016596644858737
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51/10 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.016809465946903,
        "to_latitude": 21.016596644858737,
        "to_longitude": 105.82584445196443,
        "from_longitude": 105.82595174032802,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6425,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82584445196443,
          21.0165090125572,
          105.82600002008533,
          21.016596644858737
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82584445196443,
            21.016596644858737
          ],
          [
            105.82600002008533,
            21.0165090125572
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51/10 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.016596644858737,
        "to_latitude": 21.0165090125572,
        "to_longitude": 105.82600002008533,
        "from_longitude": 105.82584445196443,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6426,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82600002008533,
          21.016366296984824,
          105.82626019436206,
          21.0165090125572
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82600002008533,
            21.0165090125572
          ],
          [
            105.82626019436206,
            21.016366296984824
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51/10 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.0165090125572,
        "to_latitude": 21.016366296984824,
        "to_longitude": 105.82626019436206,
        "from_longitude": 105.82600002008533,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6427,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82626019436206,
          21.016366296984824,
          105.82641308027524,
          21.016586629740935
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82626019436206,
            21.016366296984824
          ],
          [
            105.82641308027524,
            21.016586629740935
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51/10 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.016366296984824,
        "to_latitude": 21.016586629740935,
        "to_longitude": 105.82641308027524,
        "from_longitude": 105.82626019436206,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6428,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82641308027524,
          21.016586629740935,
          105.82653109746978,
          21.01677691685928
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82641308027524,
            21.016586629740935
          ],
          [
            105.82653109746978,
            21.01677691685928
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51/10 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.016586629740935,
        "to_latitude": 21.01677691685928,
        "to_longitude": 105.82653109746978,
        "from_longitude": 105.82641308027524,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6429,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82626019436206,
          21.016246115344412,
          105.8264989109679,
          21.016366296984824
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82626019436206,
            21.016366296984824
          ],
          [
            105.8264989109679,
            21.016246115344412
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51/10 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.016366296984824,
        "to_latitude": 21.016246115344412,
        "to_longitude": 105.8264989109679,
        "from_longitude": 105.82626019436206,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6430,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8264989109679,
          21.016246115344412,
          105.82664643246555,
          21.01647395962215
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8264989109679,
            21.016246115344412
          ],
          [
            105.82664643246555,
            21.01647395962215
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51/10 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.016246115344412,
        "to_latitude": 21.01647395962215,
        "to_longitude": 105.82664643246555,
        "from_longitude": 105.8264989109679,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6431,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82568620163578,
          21.01631371752969,
          105.82584445196443,
          21.016596644858737
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82584445196443,
            21.016596644858737
          ],
          [
            105.82568620163578,
            21.01631371752969
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 51/10 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.016596644858737,
        "to_latitude": 21.01631371752969,
        "to_longitude": 105.82568620163578,
        "from_longitude": 105.82584445196443,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6432,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82568620163578,
          21.01622858884788,
          105.82575325685629,
          21.01631371752969
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82568620163578,
            21.01631371752969
          ],
          [
            105.82575325685629,
            21.01622858884788
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N51/16 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01631371752969,
        "to_latitude": 21.01622858884788,
        "to_longitude": 105.82575325685629,
        "from_longitude": 105.82568620163578,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6433,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82545821386992,
          21.01631371752969,
          105.82568620163578,
          21.01642388398685
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82568620163578,
            21.01631371752969
          ],
          [
            105.82545821386992,
            21.01642388398685
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N51/16 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01631371752969,
        "to_latitude": 21.01642388398685,
        "to_longitude": 105.82545821386992,
        "from_longitude": 105.82568620163578,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6434,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82532946783543,
          21.016213566133825,
          105.82545821386992,
          21.01642388398685
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82545821386992,
            21.01642388398685
          ],
          [
            105.82532946783543,
            21.016213566133825
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01642388398685,
        "to_latitude": 21.016213566133825,
        "to_longitude": 105.82532946783543,
        "from_longitude": 105.82545821386992,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6435,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82521145064086,
          21.01601075935158,
          105.82532946783543,
          21.016213566133825
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82532946783543,
            21.016213566133825
          ],
          [
            105.82521145064086,
            21.01601075935158
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.016213566133825,
        "to_latitude": 21.01601075935158,
        "to_longitude": 105.82521145064086,
        "from_longitude": 105.82532946783543,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6436,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82508806902186,
          21.015840501593516,
          105.82521145064086,
          21.01601075935158
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82521145064086,
            21.01601075935158
          ],
          [
            105.82508806902186,
            21.015840501593516
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01601075935158,
        "to_latitude": 21.015840501593516,
        "to_longitude": 105.82508806902186,
        "from_longitude": 105.82521145064086,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6437,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82521145064086,
          21.015895585007048,
          105.82527481788188,
          21.01601075935158
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82521145064086,
            21.01601075935158
          ],
          [
            105.82527481788188,
            21.015974141432032
          ],
          [
            105.82522217947184,
            21.015895585007048
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01601075935158,
        "to_latitude": 21.015895585007048,
        "to_longitude": 105.82522217947184,
        "from_longitude": 105.82521145064086,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6438,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82516853529005,
          21.015807952293553,
          105.82522217947184,
          21.015895585007048
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82522217947184,
            21.015895585007048
          ],
          [
            105.82516853529005,
            21.015807952293553
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.015895585007048,
        "to_latitude": 21.015807952293553,
        "to_longitude": 105.82516853529005,
        "from_longitude": 105.82522217947184,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6439,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82521145064086,
          21.01584300538514,
          105.82551990467493,
          21.01601075935158
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82521145064086,
            21.01601075935158
          ],
          [
            105.82551990467493,
            21.01584300538514
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01601075935158,
        "to_latitude": 21.01584300538514,
        "to_longitude": 105.82551990467493,
        "from_longitude": 105.82521145064086,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6440,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82551990467493,
          21.01572282332305,
          105.8257478924408,
          21.01584300538514
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82551990467493,
            21.01584300538514
          ],
          [
            105.8257478924408,
            21.01572282332305
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01584300538514,
        "to_latitude": 21.01572282332305,
        "to_longitude": 105.8257478924408,
        "from_longitude": 105.82551990467493,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6441,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82566206175713,
          21.015530031062898,
          105.82576432102836,
          21.01572282332305
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8257478924408,
            21.01572282332305
          ],
          [
            105.82576432102836,
            21.01570999137904
          ],
          [
            105.82566206175713,
            21.015530031062898
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01572282332305,
        "to_latitude": 21.015530031062898,
        "to_longitude": 105.82566206175713,
        "from_longitude": 105.8257478924408,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6442,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82531069237224,
          21.01487653856033,
          105.82566206175713,
          21.015530031062898
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82566206175713,
            21.015530031062898
          ],
          [
            105.82531069237224,
            21.01487653856033
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 82,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.015530031062898,
        "to_latitude": 21.01487653856033,
        "to_longitude": 105.82531069237224,
        "from_longitude": 105.82566206175713,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6443,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82531069237224,
          21.01487653856033,
          105.82546659583424,
          21.015084041519525
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82531069237224,
            21.01487653856033
          ],
          [
            105.82546659583424,
            21.015084041519525
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01487653856033,
        "to_latitude": 21.015084041519525,
        "to_longitude": 105.82546659583424,
        "from_longitude": 105.82531069237224,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6444,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8257478924408,
          21.015627679121746,
          105.82591418940166,
          21.01572282332305
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8257478924408,
            21.01572282332305
          ],
          [
            105.82591418940166,
            21.015627679121746
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01572282332305,
        "to_latitude": 21.015627679121746,
        "to_longitude": 105.82591418940166,
        "from_longitude": 105.8257478924408,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6445,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82591418940166,
          21.0155650842198,
          105.82604829985164,
          21.015627679121746
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82591418940166,
            21.015627679121746
          ],
          [
            105.82604829985164,
            21.0155650842198
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.015627679121746,
        "to_latitude": 21.0155650842198,
        "to_longitude": 105.82604829985164,
        "from_longitude": 105.82591418940166,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6446,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82604829985164,
          21.0155650842198,
          105.82616631704619,
          21.015675251229993
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82604829985164,
            21.0155650842198
          ],
          [
            105.82611837262098,
            21.015597320598804
          ],
          [
            105.82616631704619,
            21.015675251229993
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.0155650842198,
        "to_latitude": 21.015675251229993,
        "to_longitude": 105.82616631704619,
        "from_longitude": 105.82604829985164,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6447,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82575325685629,
          21.016150658505016,
          105.82575627440512,
          21.01622858884788
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82575325685629,
            21.01622858884788
          ],
          [
            105.82575627440512,
            21.016150658505016
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 6,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01622858884788,
        "to_latitude": 21.016150658505016,
        "to_longitude": 105.82575627440512,
        "from_longitude": 105.82575325685629,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6448,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82575627440512,
          21.01598071387897,
          105.8257961722071,
          21.016150658505016
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82575627440512,
            21.016150658505016
          ],
          [
            105.8257961722071,
            21.01598071387897
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.016150658505016,
        "to_latitude": 21.01598071387897,
        "to_longitude": 105.8257961722071,
        "from_longitude": 105.82575627440512,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6449,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82565937954041,
          21.01598071387897,
          105.8257961722071,
          21.016028285874587
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8257961722071,
            21.01598071387897
          ],
          [
            105.82565937954041,
            21.016028285874587
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01598071387897,
        "to_latitude": 21.016028285874587,
        "to_longitude": 105.82565937954041,
        "from_longitude": 105.8257961722071,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6450,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8254635782854,
          21.016028285874587,
          105.82565937954041,
          21.016133444969235
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82565937954041,
            21.016028285874587
          ],
          [
            105.8254635782854,
            21.016133444969235
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.016028285874587,
        "to_latitude": 21.016133444969235,
        "to_longitude": 105.8254635782854,
        "from_longitude": 105.82565937954041,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6451,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82575325685629,
          21.01604330860729,
          105.82611535508113,
          21.01622858884788
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82575325685629,
            21.01622858884788
          ],
          [
            105.82611535508113,
            21.01604330860729
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N51/16 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01622858884788,
        "to_latitude": 21.01604330860729,
        "to_longitude": 105.82611535508113,
        "from_longitude": 105.82575325685629,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6452,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82611535508113,
          21.015940653239912,
          105.82632724959156,
          21.01604330860729
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82611535508113,
            21.01604330860729
          ],
          [
            105.82632724959156,
            21.015940653239912
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "N51/16 Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01604330860729,
        "to_latitude": 21.015940653239912,
        "to_longitude": 105.82632724959156,
        "from_longitude": 105.82611535508113,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6453,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82597856241442,
          21.015772899194577,
          105.82611535508113,
          21.01604330860729
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82611535508113,
            21.01604330860729
          ],
          [
            105.82597856241442,
            21.015772899194577
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01604330860729,
        "to_latitude": 21.015772899194577,
        "to_longitude": 105.82597856241442,
        "from_longitude": 105.82611535508113,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6454,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82594671124464,
          21.017127445125112,
          105.82619582134932,
          21.01741788220775
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82619582134932,
            21.017127445125112
          ],
          [
            105.82594671124464,
            21.01725232062327
          ],
          [
            105.82602415997299,
            21.01741788220775
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64/65 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017127445125112,
        "to_latitude": 21.01741788220775,
        "to_longitude": 105.82602415997299,
        "from_longitude": 105.82619582134932,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6455,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82592223602488,
          21.017127445125112,
          105.82619582134932,
          21.017272663736705
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82619582134932,
            21.017127445125112
          ],
          [
            105.82592223602488,
            21.017272663736705
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64/65 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.017127445125112,
        "to_latitude": 21.017272663736705,
        "to_longitude": 105.82592223602488,
        "from_longitude": 105.82619582134932,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6456,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82768880599951,
          21.01740341919357,
          105.8278944101533,
          21.01749894159462
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82768880599951,
            21.01749894159462
          ],
          [
            105.8278944101533,
            21.01740341919357
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01749894159462,
        "to_latitude": 21.01740341919357,
        "to_longitude": 105.8278944101533,
        "from_longitude": 105.82768880599951,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6457,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8278944101533,
          21.01740341919357,
          105.82792316395263,
          21.01744041609498
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8278944101533,
            21.01740341919357
          ],
          [
            105.82792316395263,
            21.01744041609498
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 7,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01740341919357,
        "to_latitude": 21.01744041609498,
        "to_longitude": 105.82792316395263,
        "from_longitude": 105.8278944101533,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6458,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82778368908716,
          21.017235107212933,
          105.8278944101533,
          21.01740341919357
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8278944101533,
            21.01740341919357
          ],
          [
            105.82778368908716,
            21.017235107212933
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01740341919357,
        "to_latitude": 21.017235107212933,
        "to_longitude": 105.82778368908716,
        "from_longitude": 105.8278944101533,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6459,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82768880599951,
          21.01740341919357,
          105.8278944101533,
          21.01749894159462
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82768880599951,
            21.01749894159462
          ],
          [
            105.8278944101533,
            21.01740341919357
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01749894159462,
        "to_latitude": 21.01740341919357,
        "to_longitude": 105.8278944101533,
        "from_longitude": 105.82768880599951,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6460,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8278944101533,
          21.01740341919357,
          105.82792316395263,
          21.01744041609498
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8278944101533,
            21.01740341919357
          ],
          [
            105.82792316395263,
            21.01744041609498
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 7,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01740341919357,
        "to_latitude": 21.01744041609498,
        "to_longitude": 105.82792316395263,
        "from_longitude": 105.8278944101533,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6461,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82778368908716,
          21.017235107212933,
          105.8278944101533,
          21.01740341919357
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8278944101533,
            21.01740341919357
          ],
          [
            105.82778368908716,
            21.017235107212933
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "YHBNV",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "YHBNV",
        "from_latitude": 21.01740341919357,
        "to_latitude": 21.017235107212933,
        "to_longitude": 105.82778368908716,
        "from_longitude": 105.8278944101533,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6462,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82660083490701,
          21.01610934601431,
          105.8271372767249,
          21.01630714509411
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82660083490701,
            21.016238603989738
          ],
          [
            105.82661893984073,
            21.01626958832634
          ],
          [
            105.82665112635156,
            21.016277099681155
          ],
          [
            105.82666990181475,
            21.01630714509411
          ],
          [
            105.82711112519492,
            21.01610934601431
          ],
          [
            105.8271372767249,
            21.016124681713322
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 85,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "Tủ điện",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016238603989738,
        "to_latitude": 21.016124681713322,
        "to_longitude": 105.8271372767249,
        "from_longitude": 105.82660083490701,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6463,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82659077664144,
          21.016238603989738,
          105.82671080547833,
          21.016330931041313
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82660083490701,
            21.016238603989738
          ],
          [
            105.82659077664144,
            21.016252061831718
          ],
          [
            105.82660418768911,
            21.01627459589598
          ],
          [
            105.82664307971935,
            21.01629212238797
          ],
          [
            105.82666453739029,
            21.016330931041313
          ],
          [
            105.82671080547833,
            21.016318725097843
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "Tủ điện",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016238603989738,
        "to_latitude": 21.016318725097843,
        "to_longitude": 105.82671080547833,
        "from_longitude": 105.82660083490701,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6464,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82671080547833,
          21.016256130485115,
          105.82685162145665,
          21.016318725097843
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82671080547833,
            21.016318725097843
          ],
          [
            105.82685162145665,
            21.016256130485115
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "05",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016318725097843,
        "to_latitude": 21.016256130485115,
        "to_longitude": 105.82685162145665,
        "from_longitude": 105.82671080547833,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6465,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82685162145665,
          21.016177261235114,
          105.82702194173808,
          21.016256130485115
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82685162145665,
            21.016256130485115
          ],
          [
            105.82702194173808,
            21.016177261235114
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016256130485115,
        "to_latitude": 21.016177261235114,
        "to_longitude": 105.82702194173808,
        "from_longitude": 105.82685162145665,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6466,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82685162145665,
          21.016256130485115,
          105.82692148867349,
          21.016375188365597
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82685162145665,
            21.016256130485115
          ],
          [
            105.82692148867349,
            21.016375188365597
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "23",
        "diemCuoi": "23-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016256130485115,
        "to_latitude": 21.016375188365597,
        "to_longitude": 105.82692148867349,
        "from_longitude": 105.82685162145665,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6467,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82692148867349,
          21.016375188365597,
          105.82699377853884,
          21.016521531461272
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82692148867349,
            21.016375188365597
          ],
          [
            105.82699377853884,
            21.016521531461272
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "23-1",
        "diemCuoi": "23-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016375188365597,
        "to_latitude": 21.016521531461272,
        "to_longitude": 105.82699377853884,
        "from_longitude": 105.82692148867349,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6468,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82702194173808,
          21.016062087019154,
          105.82727138717489,
          21.016177261235114
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82702194173808,
            21.016177261235114
          ],
          [
            105.82727138717489,
            21.016062087019154
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "24",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016177261235114,
        "to_latitude": 21.016062087019154,
        "to_longitude": 105.82727138717489,
        "from_longitude": 105.82702194173808,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6469,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82727138717489,
          21.016004499878242,
          105.82740281541714,
          21.016062087019154
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82727138717489,
            21.016062087019154
          ],
          [
            105.82740281541714,
            21.016004499878242
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016062087019154,
        "to_latitude": 21.016004499878242,
        "to_longitude": 105.82740281541714,
        "from_longitude": 105.82727138717489,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6470,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82740281541714,
          21.015956927875028,
          105.82751546819618,
          21.016004499878242
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82740281541714,
            21.016004499878242
          ],
          [
            105.82751546819618,
            21.015956927875028
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "27",
        "diemCuoi": "34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016004499878242,
        "to_latitude": 21.015956927875028,
        "to_longitude": 105.82751546819618,
        "from_longitude": 105.82740281541714,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6471,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82763405608138,
          21.01608413159755,
          105.8277072803871,
          21.0162130766188
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82763405608138,
            21.01608413159755
          ],
          [
            105.8277072803871,
            21.0162130766188
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "35",
        "diemCuoi": "36",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.01608413159755,
        "to_latitude": 21.0162130766188,
        "to_longitude": 105.8277072803871,
        "from_longitude": 105.82763405608138,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6472,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8277072803871,
          21.0162130766188,
          105.82778435965257,
          21.016340946175447
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8277072803871,
            21.0162130766188
          ],
          [
            105.82778435965257,
            21.016340946175447
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "36",
        "diemCuoi": "37",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.0162130766188,
        "to_latitude": 21.016340946175447,
        "to_longitude": 105.82778435965257,
        "from_longitude": 105.8277072803871,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6473,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82778435965257,
          21.016340946175447,
          105.8278500737692,
          21.016481157990384
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82778435965257,
            21.016340946175447
          ],
          [
            105.8278500737692,
            21.016481157990384
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "37",
        "diemCuoi": "38",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016340946175447,
        "to_latitude": 21.016481157990384,
        "to_longitude": 105.8278500737692,
        "from_longitude": 105.82778435965257,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6474,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82773675043371,
          21.01660791186483,
          105.82786415536434,
          21.016674261996023
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82786415536434,
            21.01660791186483
          ],
          [
            105.82773675043371,
            21.016674261996023
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.01660791186483,
        "to_latitude": 21.016674261996023,
        "to_longitude": 105.82773675043371,
        "from_longitude": 105.82786415536434,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6475,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82765477115983,
          21.01657843588381,
          105.82773675043371,
          21.016674261996023
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82773675043371,
            21.016674261996023
          ],
          [
            105.82771093418198,
            21.016674261993504
          ],
          [
            105.82765477115983,
            21.01657843588381
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016674261996023,
        "to_latitude": 21.01657843588381,
        "to_longitude": 105.82765477115983,
        "from_longitude": 105.82773675043371,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6476,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82758959348115,
          21.016441979832933,
          105.82765477115983,
          21.01657843588381
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82765477115983,
            21.01657843588381
          ],
          [
            105.82758959348115,
            21.016441979832933
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.01657843588381,
        "to_latitude": 21.016441979832933,
        "to_longitude": 105.82758959348115,
        "from_longitude": 105.82765477115983,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6477,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82752441580247,
          21.01630552365726,
          105.82758959348115,
          21.016441979832933
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82758959348115,
            21.016441979832933
          ],
          [
            105.82752441580247,
            21.01630552365726
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016441979832933,
        "to_latitude": 21.01630552365726,
        "to_longitude": 105.82752441580247,
        "from_longitude": 105.82758959348115,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6478,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82745923812378,
          21.01616906735665,
          105.82752049734243,
          21.016292435359603
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82752049734243,
            21.016292435359603
          ],
          [
            105.82745923812378,
            21.01616906735665
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016292435359603,
        "to_latitude": 21.01616906735665,
        "to_longitude": 105.82745923812378,
        "from_longitude": 105.82752049734243,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6479,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82739443351568,
          21.016004499878242,
          105.82745923812378,
          21.01616906735665
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82745923812378,
            21.01616906735665
          ],
          [
            105.82739443351568,
            21.01603704913193
          ],
          [
            105.82740281541714,
            21.016004499878242
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.01616906735665,
        "to_latitude": 21.016004499878242,
        "to_longitude": 105.82740281541714,
        "from_longitude": 105.82745923812378,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6480,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82761470990958,
          21.015868043304078,
          105.82773719745636,
          21.016090045987983
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82761470990958,
            21.015868043304078
          ],
          [
            105.82773719745636,
            21.016090045987983
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.015868043304078,
        "to_latitude": 21.016090045987983,
        "to_longitude": 105.82773719745636,
        "from_longitude": 105.82761470990958,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6481,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82773719745636,
          21.016090045987983,
          105.82785968500313,
          21.01631204834139
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82773719745636,
            21.016090045987983
          ],
          [
            105.82785968500313,
            21.01631204834139
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016090045987983,
        "to_latitude": 21.01631204834139,
        "to_longitude": 105.82785968500313,
        "from_longitude": 105.82773719745636,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6482,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82785968500313,
          21.01631204834139,
          105.82798217254994,
          21.01653405036435
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82785968500313,
            21.01631204834139
          ],
          [
            105.82798217254994,
            21.01653405036435
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.01631204834139,
        "to_latitude": 21.01653405036435,
        "to_longitude": 105.82798217254994,
        "from_longitude": 105.82785968500313,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6483,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82787354310042,
          21.01653405036435,
          105.82801402380053,
          21.016715574327748
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82798217254994,
            21.01653405036435
          ],
          [
            105.82800865937607,
            21.016586629737578
          ],
          [
            105.82801402380053,
            21.016615423198424
          ],
          [
            105.82799524833734,
            21.01664421665454
          ],
          [
            105.82787354310042,
            21.016715574327748
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.01653405036435,
        "to_latitude": 21.016715574327748,
        "to_longitude": 105.82787354310042,
        "from_longitude": 105.82798217254994,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6484,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82766030746515,
          21.016715574327748,
          105.82787354310042,
          21.016812804315443
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82787354310042,
            21.016715574327748
          ],
          [
            105.82766030746515,
            21.016812804315443
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016715574327748,
        "to_latitude": 21.016812804315443,
        "to_longitude": 105.82766030746515,
        "from_longitude": 105.82787354310042,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6485,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82738940436045,
          21.016812804315443,
          105.82766030746515,
          21.01693215090832
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82766030746515,
            21.016812804315443
          ],
          [
            105.82738940436045,
            21.01693215090832
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016812804315443,
        "to_latitude": 21.01693215090832,
        "to_longitude": 105.82738940436045,
        "from_longitude": 105.82766030746515,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6486,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8271426411314,
          21.01693215090832,
          105.82738940436045,
          21.017056087653163
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82738940436045,
            21.01693215090832
          ],
          [
            105.8271426411314,
            21.017056087653163
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.01693215090832,
        "to_latitude": 21.017056087653163,
        "to_longitude": 105.8271426411314,
        "from_longitude": 105.82738940436045,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6487,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82703132945622,
          21.01698097448629,
          105.8271426411314,
          21.017079873476757
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8271426411314,
            21.017056087653163
          ],
          [
            105.82709134389711,
            21.017079873476757
          ],
          [
            105.82703132945622,
            21.01698097448629
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "10",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.017056087653163,
        "to_latitude": 21.01698097448629,
        "to_longitude": 105.82703132945622,
        "from_longitude": 105.8271426411314,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6488,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82697151619301,
          21.016864549003234,
          105.82703132945622,
          21.01698097448629
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82703132945622,
            21.01698097448629
          ],
          [
            105.82697151619301,
            21.016864549003234
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.01698097448629,
        "to_latitude": 21.016864549003234,
        "to_longitude": 105.82697151619301,
        "from_longitude": 105.82703132945622,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6489,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82690633851435,
          21.016728093214162,
          105.82697151619301,
          21.016864549003234
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82697151619301,
            21.016864549003234
          ],
          [
            105.82690633851435,
            21.016728093214162
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016864549003234,
        "to_latitude": 21.016728093214162,
        "to_longitude": 105.82690633851435,
        "from_longitude": 105.82697151619301,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6490,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82684116083567,
          21.016591637300255,
          105.82690633851435,
          21.016728093214162
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82690633851435,
            21.016728093214162
          ],
          [
            105.82684116083567,
            21.016591637300255
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016728093214162,
        "to_latitude": 21.016591637300255,
        "to_longitude": 105.82684116083567,
        "from_longitude": 105.82690633851435,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6491,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82677598315699,
          21.01645518126147,
          105.82684116083567,
          21.016591637300255
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82684116083567,
            21.016591637300255
          ],
          [
            105.82677598315699,
            21.01645518126147
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016591637300255,
        "to_latitude": 21.01645518126147,
        "to_longitude": 105.82677598315699,
        "from_longitude": 105.82684116083567,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6492,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8267091291142,
          21.016318725097843,
          105.82677598315699,
          21.01645518126147
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82677598315699,
            21.01645518126147
          ],
          [
            105.8267091291142,
            21.016348770498215
          ],
          [
            105.82671080547833,
            21.016318725097843
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.01645518126147,
        "to_latitude": 21.016318725097843,
        "to_longitude": 105.82671080547833,
        "from_longitude": 105.82677598315699,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6493,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82703132945622,
          21.01697972259961,
          105.82714532333915,
          21.01701727918432
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82703132945622,
            21.01698097448629
          ],
          [
            105.82707122733007,
            21.01701727918432
          ],
          [
            105.82714532333915,
            21.01697972259961
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.01698097448629,
        "to_latitude": 21.01697972259961,
        "to_longitude": 105.82714532333915,
        "from_longitude": 105.82703132945622,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6494,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82714532333915,
          21.01688708297511,
          105.82721070219553,
          21.01697972259961
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82714532333915,
            21.01697972259961
          ],
          [
            105.82715571690989,
            21.016957188638553
          ],
          [
            105.82721070219553,
            21.01693215090331
          ],
          [
            105.8271868975771,
            21.01688708297511
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.01697972259961,
        "to_latitude": 21.01688708297511,
        "to_longitude": 105.8271868975771,
        "from_longitude": 105.82714532333915,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6495,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82714532333915,
          21.016868304665472,
          105.82737867552049,
          21.01697972259961
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82714532333915,
            21.01697972259961
          ],
          [
            105.82737867552049,
            21.016868304665472
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "11",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.01697972259961,
        "to_latitude": 21.016868304665472,
        "to_longitude": 105.82737867552049,
        "from_longitude": 105.82714532333915,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6496,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82737867552049,
          21.01676690175442,
          105.8275785000961,
          21.016868304665472
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82737867552049,
            21.016868304665472
          ],
          [
            105.8275785000961,
            21.01676690175442
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016868304665472,
        "to_latitude": 21.01676690175442,
        "to_longitude": 105.8275785000961,
        "from_longitude": 105.82737867552049,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6497,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82746165637907,
          21.01651564131562,
          105.82765477115983,
          21.01658678622602
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82765477115983,
            21.01657843588381
          ],
          [
            105.82759970295989,
            21.01651564131562
          ],
          [
            105.82746165637907,
            21.01658678622602
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "31",
        "diemCuoi": "31-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.01657843588381,
        "to_latitude": 21.01658678622602,
        "to_longitude": 105.82746165637907,
        "from_longitude": 105.82765477115983,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6498,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82736641548544,
          21.01658678622602,
          105.82746165637907,
          21.016639365585508
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82746165637907,
            21.01658678622602
          ],
          [
            105.82736641548544,
            21.016639365585508
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "31-1",
        "diemCuoi": "31-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.01658678622602,
        "to_latitude": 21.016639365585508,
        "to_longitude": 105.82736641548544,
        "from_longitude": 105.82746165637907,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6499,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82734203312197,
          21.016595721319998,
          105.82736641548544,
          21.016639365585508
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82736641548544,
            21.016639365585508
          ],
          [
            105.82734203312197,
            21.016595721319998
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 7,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "31-5",
        "diemCuoi": "31-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016639365585508,
        "to_latitude": 21.016595721319998,
        "to_longitude": 105.82734203312197,
        "from_longitude": 105.82736641548544,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6500,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82731683989483,
          21.01654971967399,
          105.82734203312197,
          21.016595721319998
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82734203312197,
            21.016595721319998
          ],
          [
            105.82731683989483,
            21.01654971967399
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 7,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "31-6",
        "diemCuoi": "31-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016595721319998,
        "to_latitude": 21.01654971967399,
        "to_longitude": 105.82731683989483,
        "from_longitude": 105.82734203312197,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6501,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82728597844591,
          21.016495383781223,
          105.82731683989483,
          21.01654971967399
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82731683989483,
            21.01654971967399
          ],
          [
            105.82728597844591,
            21.016495383781223
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 5,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "31-7",
        "diemCuoi": "31-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.01654971967399,
        "to_latitude": 21.016495383781223,
        "to_longitude": 105.82728597844591,
        "from_longitude": 105.82731683989483,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6502,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82743727401555,
          21.016543141945125,
          105.82746165637907,
          21.01658678622602
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82746165637907,
            21.01658678622602
          ],
          [
            105.82743727401555,
            21.016543141945125
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 7,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "31-1",
        "diemCuoi": "31-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.01658678622602,
        "to_latitude": 21.016543141945125,
        "to_longitude": 105.82743727401555,
        "from_longitude": 105.82746165637907,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6503,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82741208078843,
          21.016497140282926,
          105.82743727401555,
          21.016543141945125
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82743727401555,
            21.016543141945125
          ],
          [
            105.82741208078843,
            21.016497140282926
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 7,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "31-2",
        "diemCuoi": "31-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016543141945125,
        "to_latitude": 21.016497140282926,
        "to_longitude": 105.82741208078843,
        "from_longitude": 105.82743727401555,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6504,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82738121933953,
          21.01644280437096,
          105.82741208078843,
          21.016497140282926
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82741208078843,
            21.016497140282926
          ],
          [
            105.82738121933953,
            21.01644280437096
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 5,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "31-3",
        "diemCuoi": "31-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016497140282926,
        "to_latitude": 21.01644280437096,
        "to_longitude": 105.82738121933953,
        "from_longitude": 105.82741208078843,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6505,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82740667109411,
          21.01630552365726,
          105.82752441580247,
          21.016348926987295
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82752441580247,
            21.01630552365726
          ],
          [
            105.82743340599903,
            21.016347887917078
          ],
          [
            105.82740667109411,
            21.016348926987295
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "29",
        "diemCuoi": "29-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.01630552365726,
        "to_latitude": 21.016348926987295,
        "to_longitude": 105.82740667109411,
        "from_longitude": 105.82752441580247,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6506,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82735973244066,
          21.016317629690562,
          105.82740667109411,
          21.016348926987295
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82740667109411,
            21.016348926987295
          ],
          [
            105.82735973244066,
            21.016317629690562
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 7,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "29-1",
        "diemCuoi": "29-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016348926987295,
        "to_latitude": 21.016317629690562,
        "to_longitude": 105.82735973244066,
        "from_longitude": 105.82740667109411,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6507,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82729872241627,
          21.016301455117766,
          105.82735973244066,
          21.016317629690562
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82735973244066,
            21.016317629690562
          ],
          [
            105.82729872241627,
            21.016301455117766
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 7,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "29-2",
        "diemCuoi": "29-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016317629690562,
        "to_latitude": 21.016301455117766,
        "to_longitude": 105.82729872241627,
        "from_longitude": 105.82735973244066,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6508,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8272028232069,
          21.016301455117766,
          105.82729872241627,
          21.01634767509535
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82729872241627,
            21.016301455117766
          ],
          [
            105.8272028232069,
            21.01634767509535
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "29-3",
        "diemCuoi": "29-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016301455117766,
        "to_latitude": 21.01634767509535,
        "to_longitude": 105.8272028232069,
        "from_longitude": 105.82729872241627,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6509,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82718270663986,
          21.01634767509535,
          105.8272028232069,
          21.016399002647773
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8272028232069,
            21.01634767509535
          ],
          [
            105.82718270663986,
            21.016399002647773
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 7,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "29-4",
        "diemCuoi": "29-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.01634767509535,
        "to_latitude": 21.016399002647773,
        "to_longitude": 105.82718270663986,
        "from_longitude": 105.8272028232069,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6510,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82717197780889,
          21.016399002647773,
          105.82718270663986,
          21.01645784152743
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82718270663986,
            21.016399002647773
          ],
          [
            105.82717197780889,
            21.01645784152743
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 7,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "29-5",
        "diemCuoi": "29-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016399002647773,
        "to_latitude": 21.01645784152743,
        "to_longitude": 105.82717197780889,
        "from_longitude": 105.82718270663986,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6511,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82719326783621,
          21.016062087019154,
          105.82727138717489,
          21.01614471200949
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82727138717489,
            21.016062087019154
          ],
          [
            105.82719326783621,
            21.016113414667362
          ],
          [
            105.82720969636985,
            21.01614471200949
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "26",
        "diemCuoi": "26-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016062087019154,
        "to_latitude": 21.01614471200949,
        "to_longitude": 105.82720969636985,
        "from_longitude": 105.82727138717489,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6512,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82720969636985,
          21.01614471200949,
          105.8272501598756,
          21.016215953828425
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82720969636985,
            21.01614471200949
          ],
          [
            105.8272501598756,
            21.016215953828425
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 8,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "26-1",
        "diemCuoi": "26-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.01614471200949,
        "to_latitude": 21.016215953828425,
        "to_longitude": 105.8272501598756,
        "from_longitude": 105.82720969636985,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6513,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82702194173808,
          21.0161622385142,
          105.82710777243074,
          21.01618978016706
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82702194173808,
            21.016177261235114
          ],
          [
            105.82709000279323,
            21.0161622385142
          ],
          [
            105.82710777243074,
            21.01618978016706
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "24",
        "diemCuoi": "24-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016177261235114,
        "to_latitude": 21.01618978016706,
        "to_longitude": 105.82710777243074,
        "from_longitude": 105.82702194173808,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6514,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82710777243074,
          21.01618978016706,
          105.82714697555375,
          21.016257970875817
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82710777243074,
            21.01618978016706
          ],
          [
            105.82714697555375,
            21.016257970875817
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 8,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "24-1",
        "diemCuoi": "24-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.01618978016706,
        "to_latitude": 21.016257970875817,
        "to_longitude": 105.82714697555375,
        "from_longitude": 105.82710777243074,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6515,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82679393148933,
          21.01591561533421,
          105.82686379870619,
          21.016034673486544
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82679393148933,
            21.01591561533421
          ],
          [
            105.82686379870619,
            21.016034673486544
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.01591561533421,
        "to_latitude": 21.016034673486544,
        "to_longitude": 105.82686379870619,
        "from_longitude": 105.82679393148933,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6516,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82654582715035,
          21.016015766930376,
          105.82661100482902,
          21.016152223371186
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82661100482902,
            21.016152223371186
          ],
          [
            105.82654582715035,
            21.016015766930376
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016152223371186,
        "to_latitude": 21.016015766930376,
        "to_longitude": 105.82654582715035,
        "from_longitude": 105.82661100482902,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6517,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82654582715035,
          21.01591561533421,
          105.82679393148933,
          21.016015766930376
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82654582715035,
            21.016015766930376
          ],
          [
            105.82679393148933,
            21.01591561533421
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "02",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.016015766930376,
        "to_latitude": 21.01591561533421,
        "to_longitude": 105.82679393148933,
        "from_longitude": 105.82654582715035,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6518,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82751546819618,
          21.015954424085322,
          105.82763405608138,
          21.01608413159755
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82751546819618,
            21.015956927875028
          ],
          [
            105.82755704241619,
            21.015954424085322
          ],
          [
            105.82763405608138,
            21.01608413159755
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "VH1/6",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1/6",
        "from_latitude": 21.015956927875028,
        "to_latitude": 21.01608413159755,
        "to_longitude": 105.82763405608138,
        "from_longitude": 105.82751546819618,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6519,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.834109679074,
          21.007064452483327,
          105.83430648610077,
          21.007229399403766
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83430648610077,
            21.007229399403766
          ],
          [
            105.834109679074,
            21.007064452483327
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B15 - B19",
        "diemDau": "6-2",
        "diemCuoi": "6-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.007229399403766,
        "to_latitude": 21.007064452483327,
        "to_longitude": 105.834109679074,
        "from_longitude": 105.83430648610077,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6520,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83462701006067,
          21.006931430656014,
          105.83481476469244,
          21.00716179309977
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83481476469244,
            21.00716179309977
          ],
          [
            105.83462701006067,
            21.006931430656014
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B15 - B19",
        "diemDau": "7-1",
        "diemCuoi": "7-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.00716179309977,
        "to_latitude": 21.006931430656014,
        "to_longitude": 105.83462701006067,
        "from_longitude": 105.83481476469244,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6521,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.834860362251,
          21.007031588283148,
          105.83510712548006,
          21.007307021412835
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.834860362251,
            21.007307021412835
          ],
          [
            105.83510712548006,
            21.007031588283148
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương Đình Của",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.007307021412835,
        "to_latitude": 21.007031588283148,
        "to_longitude": 105.83510712548006,
        "from_longitude": 105.834860362251,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6522,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8374691458519,
          21.011304754260102,
          105.83778061738089,
          21.011463495989908
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8374691458519,
            21.011371165921922
          ],
          [
            105.83752221998552,
            21.011304754260102
          ],
          [
            105.83778061738089,
            21.011463495989908
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "02",
        "diemCuoi": "2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.011371165921922,
        "to_latitude": 21.011463495989908,
        "to_longitude": 105.83778061738089,
        "from_longitude": 105.8374691458519,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6523,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83765824157857,
          21.01143501451799,
          105.83778061738089,
          21.011463495989908
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83778061738089,
            21.011463495989908
          ],
          [
            105.83765824157857,
            21.01143501451799
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "2-1",
        "diemCuoi": "2-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.011463495989908,
        "to_latitude": 21.01143501451799,
        "to_longitude": 105.83765824157857,
        "from_longitude": 105.83778061738089,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6524,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83778061738089,
          21.01134080653182,
          105.83797910084364,
          21.011463495989908
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83778061738089,
            21.011463495989908
          ],
          [
            105.83797910084364,
            21.01134080653182
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "2-1",
        "diemCuoi": "2-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.011463495989908,
        "to_latitude": 21.01134080653182,
        "to_longitude": 105.83797910084364,
        "from_longitude": 105.83778061738089,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6525,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83797910084364,
          21.01134080653182,
          105.83812394013357,
          21.011460992124785
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83797910084364,
            21.01134080653182
          ],
          [
            105.83812394013357,
            21.011460992124785
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "2-2",
        "diemCuoi": "2-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01134080653182,
        "to_latitude": 21.011460992124785,
        "to_longitude": 105.83812394013357,
        "from_longitude": 105.83797910084364,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6526,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83812394013357,
          21.011460992124785,
          105.83835461010717,
          21.011601208527228
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83812394013357,
            21.011460992124785
          ],
          [
            105.83835461010717,
            21.011601208527228
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "2-3",
        "diemCuoi": "2-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.011460992124785,
        "to_latitude": 21.011601208527228,
        "to_longitude": 105.83835461010717,
        "from_longitude": 105.83812394013357,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6527,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83790668120766,
          21.01085755931639,
          105.83803006281768,
          21.010970233677003
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83790668120766,
            21.01085755931639
          ],
          [
            105.83803006281768,
            21.010970233677003
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01085755931639,
        "to_latitude": 21.010970233677003,
        "to_longitude": 105.83803006281768,
        "from_longitude": 105.83790668120766,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6528,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83803006281768,
          21.010970233677003,
          105.83828487267894,
          21.011175551182983
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83803006281768,
            21.010970233677003
          ],
          [
            105.83814640362117,
            21.01111952707543
          ],
          [
            105.83828487267894,
            21.011175551182983
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.010970233677003,
        "to_latitude": 21.011175551182983,
        "to_longitude": 105.83828487267894,
        "from_longitude": 105.83803006281768,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6529,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83828487267894,
          21.01105786923282,
          105.8383707033626,
          21.011175551182983
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83828487267894,
            21.011175551182983
          ],
          [
            105.8383707033626,
            21.01105786923282
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "4-2",
        "diemCuoi": "4-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.011175551182983,
        "to_latitude": 21.01105786923282,
        "to_longitude": 105.8383707033626,
        "from_longitude": 105.83828487267894,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6530,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8383707033626,
          21.01105786923282,
          105.83848067393393,
          21.0110779002094
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8383707033626,
            21.01105786923282
          ],
          [
            105.83848067393393,
            21.0110779002094
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "4-3",
        "diemCuoi": "4-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01105786923282,
        "to_latitude": 21.0110779002094,
        "to_longitude": 105.83848067393393,
        "from_longitude": 105.8383707033626,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6531,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83848067393393,
          21.011019372191818,
          105.83864428868704,
          21.011092923440742
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83848067393393,
            21.0110779002094
          ],
          [
            105.8385219128847,
            21.011019372191818
          ],
          [
            105.83864428868704,
            21.011092923440742
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "4-4",
        "diemCuoi": "4-4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.0110779002094,
        "to_latitude": 21.011092923440742,
        "to_longitude": 105.83864428868704,
        "from_longitude": 105.83848067393393,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6532,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83848067393393,
          21.0110779002094,
          105.8386603819335,
          21.011258178880276
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83848067393393,
            21.0110779002094
          ],
          [
            105.8386603819335,
            21.011258178880276
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "4-4",
        "diemCuoi": "4-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.0110779002094,
        "to_latitude": 21.011258178880276,
        "to_longitude": 105.8386603819335,
        "from_longitude": 105.83848067393393,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6533,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8386603819335,
          21.011258178880276,
          105.83884277214977,
          21.01140841093957
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8386603819335,
            21.011258178880276
          ],
          [
            105.83884277214977,
            21.01140841093957
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "4-5",
        "diemCuoi": "4-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.011258178880276,
        "to_latitude": 21.01140841093957,
        "to_longitude": 105.83884277214977,
        "from_longitude": 105.8386603819335,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6534,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8387847693692,
          21.01140841093957,
          105.83886154761294,
          21.01158618534792
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83884277214977,
            21.01140841093957
          ],
          [
            105.8387847693692,
            21.011545184578726
          ],
          [
            105.83886154761294,
            21.01158618534792
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "4-6",
        "diemCuoi": "4-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01140841093957,
        "to_latitude": 21.01158618534792,
        "to_longitude": 105.83886154761294,
        "from_longitude": 105.83884277214977,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6535,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83870866169981,
          21.01140841093957,
          105.83884277214977,
          21.011688843712356
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83884277214977,
            21.01140841093957
          ],
          [
            105.8387847693692,
            21.011545184578726
          ],
          [
            105.83870866169981,
            21.011688843712356
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "4-6",
        "diemCuoi": "4-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01140841093957,
        "to_latitude": 21.011688843712356,
        "to_longitude": 105.83870866169981,
        "from_longitude": 105.83884277214977,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6536,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83870866169981,
          21.011688843712356,
          105.83883472552655,
          21.01178649428599
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83870866169981,
            21.011688843712356
          ],
          [
            105.83883472552655,
            21.01178649428599
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "4-9",
        "diemCuoi": "4-9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.011688843712356,
        "to_latitude": 21.01178649428599,
        "to_longitude": 105.83883472552655,
        "from_longitude": 105.83870866169981,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6537,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83884277214977,
          21.01140841093957,
          105.83908417096335,
          21.0114885346432
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83884277214977,
            21.01140841093957
          ],
          [
            105.83908417096335,
            21.0114885346432
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "4-6",
        "diemCuoi": "4-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01140841093957,
        "to_latitude": 21.0114885346432,
        "to_longitude": 105.83908417096335,
        "from_longitude": 105.83884277214977,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6538,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83908417096335,
          21.0114885346432,
          105.83925315013194,
          21.011583681484034
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83908417096335,
            21.0114885346432
          ],
          [
            105.83925315013194,
            21.011583681484034
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "4-10",
        "diemCuoi": "4-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.0114885346432,
        "to_latitude": 21.011583681484034,
        "to_longitude": 105.83925315013194,
        "from_longitude": 105.83908417096335,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6539,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83925315013194,
          21.011398395474217,
          105.83937384953423,
          21.011583681484034
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83925315013194,
            21.011583681484034
          ],
          [
            105.83937384953423,
            21.011398395474217
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "4-11",
        "diemCuoi": "4-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.011583681484034,
        "to_latitude": 21.011398395474217,
        "to_longitude": 105.83937384953423,
        "from_longitude": 105.83925315013194,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6540,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83927997221835,
          21.011255675011693,
          105.83937384953423,
          21.011398395474217
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83937384953423,
            21.011398395474217
          ],
          [
            105.83927997221835,
            21.011255675011693
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "4-12",
        "diemCuoi": "4-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.011398395474217,
        "to_latitude": 21.011255675011693,
        "to_longitude": 105.83927997221835,
        "from_longitude": 105.83937384953423,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6541,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83884109575871,
          21.011185566664118,
          105.83900638690288,
          21.01140841093957
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83884277214977,
            21.01140841093957
          ],
          [
            105.83884109575871,
            21.011357394653377
          ],
          [
            105.83900638690288,
            21.011185566664118
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01140841093957,
        "to_latitude": 21.011185566664118,
        "to_longitude": 105.83900638690288,
        "from_longitude": 105.83884277214977,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6542,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8387311251874,
          21.011357394653352,
          105.83884277214977,
          21.01140841093957
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83884277214977,
            21.01140841093957
          ],
          [
            105.8387311251874,
            21.011357394653352
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "4-6",
        "diemCuoi": "4-6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01140841093957,
        "to_latitude": 21.011357394653352,
        "to_longitude": 105.8387311251874,
        "from_longitude": 105.83884277214977,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6543,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8378691302813,
          21.010682287917934,
          105.83790668120766,
          21.01085755931639
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83790668120766,
            21.01085755931639
          ],
          [
            105.8378691302813,
            21.010682287917934
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01085755931639,
        "to_latitude": 21.010682287917934,
        "to_longitude": 105.8378691302813,
        "from_longitude": 105.83790668120766,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6544,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8378691302813,
          21.010682287917934,
          105.8381453978045,
          21.010814993423676
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8378691302813,
            21.010682287917934
          ],
          [
            105.8381453978045,
            21.010814993423676
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "05",
        "diemCuoi": "5-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.010682287917934,
        "to_latitude": 21.010814993423676,
        "to_longitude": 105.8381453978045,
        "from_longitude": 105.8378691302813,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6545,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83774038424681,
          21.010682287917934,
          105.8378691302813,
          21.01078745078173
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8378691302813,
            21.010682287917934
          ],
          [
            105.83774038424681,
            21.01078745078173
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "05",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.010682287917934,
        "to_latitude": 21.01078745078173,
        "to_longitude": 105.83774038424681,
        "from_longitude": 105.8378691302813,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6546,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83777525296541,
          21.010479473615565,
          105.8378691302813,
          21.010682287917934
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8378691302813,
            21.010682287917934
          ],
          [
            105.83786208946576,
            21.010548643338858
          ],
          [
            105.83777525296541,
            21.010479473615565
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "05",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.010682287917934,
        "to_latitude": 21.010479473615565,
        "to_longitude": 105.83777525296541,
        "from_longitude": 105.8378691302813,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6547,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83777525296541,
          21.01038182218553,
          105.83786108364909,
          21.010479473615565
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83777525296541,
            21.010479473615565
          ],
          [
            105.83786108364909,
            21.01038182218553
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "5-1",
        "diemCuoi": "5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.010479473615565,
        "to_latitude": 21.01038182218553,
        "to_longitude": 105.83786108364909,
        "from_longitude": 105.83777525296541,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6548,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83786108364909,
          21.01038182218553,
          105.8380112873545,
          21.010479473615565
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83786108364909,
            21.01038182218553
          ],
          [
            105.8380112873545,
            21.010479473615565
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "5-2",
        "diemCuoi": "5-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01038182218553,
        "to_latitude": 21.010479473615565,
        "to_longitude": 105.8380112873545,
        "from_longitude": 105.83786108364909,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6549,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8380112873545,
          21.010479473615565,
          105.83818294873083,
          21.010552086175423
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8380112873545,
            21.010479473615565
          ],
          [
            105.83818294873083,
            21.010552086175423
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "5-5",
        "diemCuoi": "5-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.010479473615565,
        "to_latitude": 21.010552086175423,
        "to_longitude": 105.83818294873083,
        "from_longitude": 105.8380112873545,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6550,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83777525296541,
          21.010479473615565,
          105.83794154991728,
          21.010582132741597
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83777525296541,
            21.010479473615565
          ],
          [
            105.83794154991728,
            21.010582132741597
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "5-1",
        "diemCuoi": "5-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.010479473615565,
        "to_latitude": 21.010582132741597,
        "to_longitude": 105.83794154991728,
        "from_longitude": 105.83777525296541,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6551,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83794154991728,
          21.010582132741597,
          105.83808907141496,
          21.0106672646461
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83794154991728,
            21.010582132741597
          ],
          [
            105.83808907141496,
            21.0106672646461
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "diemDau": "5-3",
        "diemCuoi": "5-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.010582132741597,
        "to_latitude": 21.0106672646461,
        "to_longitude": 105.83808907141496,
        "from_longitude": 105.83794154991728,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6552,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80144261340931,
          21.02721911047869,
          105.80183748418203,
          21.027435955083728
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80144261340931,
            21.027435955083728
          ],
          [
            105.80155928949995,
            21.027395897521686
          ],
          [
            105.80183748418203,
            21.02721911047869
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 51,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/63 Đường Láng",
        "diemDau": "12",
        "diemCuoi": "12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.027435955083728,
        "to_latitude": 21.02721911047869,
        "to_longitude": 105.80183748418203,
        "from_longitude": 105.80144261340931,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6553,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80183748418203,
          21.026985306893504,
          105.80221374849444,
          21.02721911047869
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80183748418203,
            21.02721911047869
          ],
          [
            105.80221374849444,
            21.026985306893504
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/63 Đường Láng",
        "diemDau": "12-1",
        "diemCuoi": "12-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02721911047869,
        "to_latitude": 21.026985306893504,
        "to_longitude": 105.80221374849444,
        "from_longitude": 105.80183748418203,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6554,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80221374849444,
          21.026850683428545,
          105.80244140135329,
          21.026985306893504
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80221374849444,
            21.026985306893504
          ],
          [
            105.80244140135329,
            21.026850683428545
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/63 Đường Láng",
        "diemDau": "12-2",
        "diemCuoi": "12-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.026985306893504,
        "to_latitude": 21.026850683428545,
        "to_longitude": 105.80244140135329,
        "from_longitude": 105.80221374849444,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6555,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80244140135329,
          21.026850683428545,
          105.80264118592689,
          21.02707729300726
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80244140135329,
            21.026850683428545
          ],
          [
            105.80264118592689,
            21.02707729300726
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/63 Đường Láng",
        "diemDau": "12-3",
        "diemCuoi": "12-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.026850683428545,
        "to_latitude": 21.02707729300726,
        "to_longitude": 105.80264118592689,
        "from_longitude": 105.80244140135329,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6556,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80244140135329,
          21.026723066453666,
          105.80261956717524,
          21.026850683428545
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80244140135329,
            21.026850683428545
          ],
          [
            105.80254500131227,
            21.026795032803836
          ],
          [
            105.80254634241615,
            21.026775003938468
          ],
          [
            105.80258925775799,
            21.026751219656553
          ],
          [
            105.80260937432503,
            21.02673870161224
          ],
          [
            105.80261956717524,
            21.026723066453666
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/63 Đường Láng",
        "diemDau": "12-3",
        "diemCuoi": "12-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.026850683428545,
        "to_latitude": 21.026723066453666,
        "to_longitude": 105.80261956717524,
        "from_longitude": 105.80244140135329,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6557,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83757408728596,
          21.01179901358563,
          105.83761432042004,
          21.01186912164487
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83757408728596,
            21.01186912164487
          ],
          [
            105.83761432042004,
            21.01179901358563
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "7+1",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01186912164487,
        "to_latitude": 21.01179901358563,
        "to_longitude": 105.83761432042004,
        "from_longitude": 105.83757408728596,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6558,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83761432042004,
          21.01165378964449,
          105.8377001511127,
          21.01179901358563
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83761432042004,
            21.01179901358563
          ],
          [
            105.8377001511127,
            21.01165378964449
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "7-1",
        "diemCuoi": "7-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01179901358563,
        "to_latitude": 21.01165378964449,
        "to_longitude": 105.8377001511127,
        "from_longitude": 105.83761432042004,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6559,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8377001511127,
          21.01165378964449,
          105.83788254132,
          21.011768967264537
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8377001511127,
            21.01165378964449
          ],
          [
            105.83788254132,
            21.011768967264537
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "7-2",
        "diemCuoi": "7-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01165378964449,
        "to_latitude": 21.011768967264537,
        "to_longitude": 105.83788254132,
        "from_longitude": 105.8377001511127,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6560,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79847493642215,
          21.025718629061863,
          105.79855540269035,
          21.025900141845092
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79855540269035,
            21.025900141845092
          ],
          [
            105.79847493642215,
            21.025718629061863
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BQLL",
        "from_latitude": 21.025900141845092,
        "to_latitude": 21.025718629061863,
        "to_longitude": 105.79847493642215,
        "from_longitude": 105.79855540269035,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6561,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79839170743024,
          21.025507642082566,
          105.79847493642215,
          21.025718629061863
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79847493642215,
            21.025718629061863
          ],
          [
            105.79839170743024,
            21.025507642082566
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BQLL",
        "from_latitude": 21.025718629061863,
        "to_latitude": 21.025507642082566,
        "to_longitude": 105.79839170743024,
        "from_longitude": 105.79847493642215,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6562,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79831660557751,
          21.025279811610048,
          105.79839170743024,
          21.025507642082566
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79839170743024,
            21.025507642082566
          ],
          [
            105.79831660557751,
            21.025279811610048
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BQLL",
        "from_latitude": 21.025507642082566,
        "to_latitude": 21.025279811610048,
        "to_longitude": 105.79831660557751,
        "from_longitude": 105.79839170743024,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6563,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79828710127438,
          21.025107060799094,
          105.79831660557751,
          21.025279811610048
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79831660557751,
            21.025279811610048
          ],
          [
            105.79828710127438,
            21.025107060799094
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BQLL",
        "from_latitude": 21.025279811610048,
        "to_latitude": 21.025107060799094,
        "to_longitude": 105.79828710127438,
        "from_longitude": 105.79831660557751,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6564,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79825525004173,
          21.02485388035406,
          105.79828710127438,
          21.025107060799094
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79828710127438,
            21.025107060799094
          ],
          [
            105.7982565911456,
            21.024915219536563
          ],
          [
            105.79825525004173,
            21.02485388035406
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BQLL",
        "from_latitude": 21.025107060799094,
        "to_latitude": 21.02485388035406,
        "to_longitude": 105.79825525004173,
        "from_longitude": 105.79828710127438,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6565,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79825525004173,
          21.024662095096005,
          105.79825901859114,
          21.02485388035406
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79825525004173,
            21.02485388035406
          ],
          [
            105.79825901859114,
            21.024662095096005
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BQLL",
        "from_latitude": 21.02485388035406,
        "to_latitude": 21.024662095096005,
        "to_longitude": 105.79825901859114,
        "from_longitude": 105.79825525004173,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6566,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79825901859114,
          21.02442925603559,
          105.79832875602835,
          21.024662095096005
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79825901859114,
            21.024662095096005
          ],
          [
            105.79828441906665,
            21.024538734255074
          ],
          [
            105.79832875602835,
            21.02442925603559
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BQLL",
        "from_latitude": 21.024662095096005,
        "to_latitude": 21.02442925603559,
        "to_longitude": 105.79832875602835,
        "from_longitude": 105.79825901859114,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6567,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79832875602835,
          21.02425274876301,
          105.79842799775972,
          21.02442925603559
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79832875602835,
            21.02442925603559
          ],
          [
            105.79842799775972,
            21.02425274876301
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm vặn xoắn",
        "tietDien": "4x16",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BQLL",
        "from_latitude": 21.02442925603559,
        "to_latitude": 21.02425274876301,
        "to_longitude": 105.79842799775972,
        "from_longitude": 105.79832875602835,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6568,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79842799775972,
          21.024074989454345,
          105.79855540269035,
          21.02425274876301
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79842799775972,
            21.02425274876301
          ],
          [
            105.79855540269035,
            21.024074989454345
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm vặn xoắn",
        "tietDien": "4x16",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BQLL",
        "from_latitude": 21.02425274876301,
        "to_latitude": 21.024074989454345,
        "to_longitude": 105.79855540269035,
        "from_longitude": 105.79842799775972,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6569,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79855540269035,
          21.02390724456045,
          105.7987324284822,
          21.024074989454345
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79855540269035,
            21.024074989454345
          ],
          [
            105.79861433077163,
            21.023997945752367
          ],
          [
            105.79863847065927,
            21.02396539823395
          ],
          [
            105.79866261053795,
            21.023957887266445
          ],
          [
            105.7987324284822,
            21.02390724456045
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm vặn xoắn",
        "tietDien": "4x16",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BQLL",
        "from_latitude": 21.024074989454345,
        "to_latitude": 21.02390724456045,
        "to_longitude": 105.7987324284822,
        "from_longitude": 105.79855540269035,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6570,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.7987324284822,
          21.023747010455473,
          105.79891615980235,
          21.02390724456045
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.7987324284822,
            21.02390724456045
          ],
          [
            105.79891615980235,
            21.023747010455473
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm vặn xoắn",
        "tietDien": "4x16",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BQLL",
        "from_latitude": 21.02390724456045,
        "to_latitude": 21.023747010455473,
        "to_longitude": 105.79891615980235,
        "from_longitude": 105.7987324284822,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6571,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79891615980235,
          21.02359178350222,
          105.79909720891474,
          21.023747010455473
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79891615980235,
            21.023747010455473
          ],
          [
            105.79909720891474,
            21.02359178350222
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm vặn xoắn",
        "tietDien": "4x16",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BQLL",
        "from_latitude": 21.023747010455473,
        "to_latitude": 21.02359178350222,
        "to_longitude": 105.79909720891474,
        "from_longitude": 105.79891615980235,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6572,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79909720891474,
          21.023431549059048,
          105.79928898685813,
          21.02359178350222
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79909720891474,
            21.02359178350222
          ],
          [
            105.79928898685813,
            21.023431549059048
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm vặn xoắn",
        "tietDien": "4x16",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BQLL",
        "from_latitude": 21.02359178350222,
        "to_latitude": 21.023431549059048,
        "to_longitude": 105.79928898685813,
        "from_longitude": 105.79909720891474,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6573,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79928898685813,
          21.023275069943466,
          105.79948076480153,
          21.023431549059048
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79928898685813,
            21.023431549059048
          ],
          [
            105.79948076480153,
            21.023275069943466
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm vặn xoắn",
        "tietDien": "4x16",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BQLL",
        "from_latitude": 21.023431549059048,
        "to_latitude": 21.023275069943466,
        "to_longitude": 105.79948076480153,
        "from_longitude": 105.79928898685813,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6574,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79948076480153,
          21.023123598004123,
          105.79966449612168,
          21.023275069943466
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79948076480153,
            21.023275069943466
          ],
          [
            105.79966449612168,
            21.023123598004123
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm vặn xoắn",
        "tietDien": "4x16",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "BQLL",
        "from_latitude": 21.023275069943466,
        "to_latitude": 21.023123598004123,
        "to_longitude": 105.79966449612168,
        "from_longitude": 105.79948076480153,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6575,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79966449612168,
          21.023095062428986,
          105.79990857713399,
          21.023242522267203
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79966449612168,
            21.023123598004123
          ],
          [
            105.79969693738579,
            21.023095062428986
          ],
          [
            105.79990857713399,
            21.023242522267203
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.023123598004123,
        "to_latitude": 21.023242522267203,
        "to_longitude": 105.79990857713399,
        "from_longitude": 105.79966449612168,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6576,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79990857713399,
          21.023242522267203,
          105.80014595263596,
          21.023500399817696
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79990857713399,
            21.023242522267203
          ],
          [
            105.80013950186981,
            21.023405517207483
          ],
          [
            105.80014595263596,
            21.023500399817696
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.023242522267203,
        "to_latitude": 21.023500399817696,
        "to_longitude": 105.80014595263596,
        "from_longitude": 105.79990857713399,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6577,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80014595263596,
          21.023500399817696,
          105.80044501894291,
          21.02358176885509
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80014595263596,
            21.023500399817696
          ],
          [
            105.80044501894291,
            21.02358176885509
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.023500399817696,
        "to_latitude": 21.02358176885509,
        "to_longitude": 105.80044501894291,
        "from_longitude": 105.80014595263596,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6578,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80044501894291,
          21.023577018158996,
          105.80058985822387,
          21.023596790825952
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80044501894291,
            21.02358176885509
          ],
          [
            105.80053512770041,
            21.023577018158996
          ],
          [
            105.80058985822387,
            21.023596790825952
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.02358176885509,
        "to_latitude": 21.023596790825952,
        "to_longitude": 105.80058985822387,
        "from_longitude": 105.80044501894291,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6579,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80041953795138,
          21.02358176885509,
          105.80044501894291,
          21.023834638503182
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80044501894291,
            21.02358176885509
          ],
          [
            105.80041953795138,
            21.023834638503182
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "diemDau": "19",
        "diemCuoi": "19-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.02358176885509,
        "to_latitude": 21.023834638503182,
        "to_longitude": 105.80041953795138,
        "from_longitude": 105.80044501894291,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6580,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80041953795138,
          21.023834638503182,
          105.80059656375218,
          21.023859675078526
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80041953795138,
            21.023834638503182
          ],
          [
            105.80059656375218,
            21.023859675078526
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "diemDau": "19-1",
        "diemCuoi": "19-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.023834638503182,
        "to_latitude": 21.023859675078526,
        "to_longitude": 105.80059656375218,
        "from_longitude": 105.80041953795138,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6581,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80003464096974,
          21.023500399817696,
          105.80014595263596,
          21.023695685433307
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80014595263596,
            21.023500399817696
          ],
          [
            105.80005501229002,
            21.023652128003068
          ],
          [
            105.80003464096974,
            21.023695685433307
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "diemDau": "18",
        "diemCuoi": "18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.023500399817696,
        "to_latitude": 21.023695685433307,
        "to_longitude": 105.80003464096974,
        "from_longitude": 105.80014595263596,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6582,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79998367899569,
          21.023695685433307,
          105.80003464096974,
          21.023923518327724
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80003464096974,
            21.023695685433307
          ],
          [
            105.79998367899569,
            21.023923518327724
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "diemDau": "18-1",
        "diemCuoi": "18-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.023695685433307,
        "to_latitude": 21.023923518327724,
        "to_longitude": 105.79998367899569,
        "from_longitude": 105.80003464096974,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6583,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79994344586159,
          21.023923518327724,
          105.79998367899569,
          21.024118803389054
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79998367899569,
            21.023923518327724
          ],
          [
            105.79994344586159,
            21.024118803389054
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "diemDau": "18-2",
        "diemCuoi": "18-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.023923518327724,
        "to_latitude": 21.024118803389054,
        "to_longitude": 105.79994344586159,
        "from_longitude": 105.79998367899569,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6584,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79988443726434,
          21.024118803389054,
          105.79994344586159,
          21.024218949474324
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79994344586159,
            21.024118803389054
          ],
          [
            105.79988443726434,
            21.024218949474324
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "diemDau": "18-3",
        "diemCuoi": "18-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.024118803389054,
        "to_latitude": 21.024218949474324,
        "to_longitude": 105.79988443726434,
        "from_longitude": 105.79994344586159,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6585,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79994344586159,
          21.024116299735542,
          105.80015265816428,
          21.024118803389054
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79994344586159,
            21.024118803389054
          ],
          [
            105.80015265816428,
            21.024116299735542
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "diemDau": "18-3",
        "diemCuoi": "18-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.024118803389054,
        "to_latitude": 21.024116299735542,
        "to_longitude": 105.80015265816428,
        "from_longitude": 105.79994344586159,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6586,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79994344586159,
          21.024118803389054,
          105.80005341643292,
          21.024274029792746
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79994344586159,
            21.024118803389054
          ],
          [
            105.80004830677066,
            21.02413408193301
          ],
          [
            105.80005341643292,
            21.024274029792746
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "diemDau": "18-3",
        "diemCuoi": "18-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.024118803389054,
        "to_latitude": 21.024274029792746,
        "to_longitude": 105.80005341643292,
        "from_longitude": 105.79994344586159,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6587,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80058851711999,
          21.023859675078526,
          105.80059656375218,
          21.023997376168662
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80059656375218,
            21.023859675078526
          ],
          [
            105.80058851711999,
            21.023997376168662
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "diemDau": "19-2",
        "diemCuoi": "19-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.023859675078526,
        "to_latitude": 21.023997376168662,
        "to_longitude": 105.80058851711999,
        "from_longitude": 105.80059656375218,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6588,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80058851711999,
          21.023997376168662,
          105.80082857482968,
          21.024022412716665
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80058851711999,
            21.023997376168662
          ],
          [
            105.80082857482968,
            21.024022412716665
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "diemDau": "19-3",
        "diemCuoi": "19-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.023997376168662,
        "to_latitude": 21.024022412716665,
        "to_longitude": 105.80082857482968,
        "from_longitude": 105.80058851711999,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6589,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80121194343567,
          21.027435955083728,
          105.80144261340931,
          21.02744471767393
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80144261340931,
            21.027435955083728
          ],
          [
            105.80121194343567,
            21.02744471767393
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.027435955083728,
        "to_latitude": 21.02744471767393,
        "to_longitude": 105.80121194343567,
        "from_longitude": 105.80144261340931,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6590,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80112161395199,
          21.027172382180414,
          105.80121194343567,
          21.02744471767393
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80121194343567,
            21.02744471767393
          ],
          [
            105.80112161395199,
            21.027172382180414
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/50 Đường Láng",
        "diemDau": "13",
        "diemCuoi": "13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02744471767393,
        "to_latitude": 21.027172382180414,
        "to_longitude": 105.80112161395199,
        "from_longitude": 105.80121194343567,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6591,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80108206803956,
          21.026906614121454,
          105.80112161395199,
          21.027172382180414
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80112161395199,
            21.027172382180414
          ],
          [
            105.80108206803956,
            21.026906614121454
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/50 Đường Láng",
        "diemDau": "13-1",
        "diemCuoi": "13-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.027172382180414,
        "to_latitude": 21.026906614121454,
        "to_longitude": 105.80108206803956,
        "from_longitude": 105.80112161395199,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6592,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79963524657178,
          21.023123598004123,
          105.79968620854584,
          21.023221497719014
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79966449612168,
            21.023123598004123
          ],
          [
            105.79963524657178,
            21.023172676183584
          ],
          [
            105.79968620854584,
            21.023221497719014
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1008 Đường Láng",
        "diemDau": "16",
        "diemCuoi": "16-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.023123598004123,
        "to_latitude": 21.023221497719014,
        "to_longitude": 105.79968620854584,
        "from_longitude": 105.79966449612168,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6593,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79953466373654,
          21.023221497719014,
          105.79968620854584,
          21.023430553853206
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79968620854584,
            21.023221497719014
          ],
          [
            105.79953466373654,
            21.023430553853206
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1008 Đường Láng",
        "diemDau": "16-1",
        "diemCuoi": "16-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.023221497719014,
        "to_latitude": 21.023430553853206,
        "to_longitude": 105.79953466373654,
        "from_longitude": 105.79968620854584,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6594,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79953466373654,
          21.023430553853206,
          105.79967950301749,
          21.023582025480714
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79953466373654,
            21.023430553853206
          ],
          [
            105.79967950301749,
            21.023582025480714
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1008 Đường Láng",
        "diemDau": "16-2",
        "diemCuoi": "16-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.023430553853206,
        "to_latitude": 21.023582025480714,
        "to_longitude": 105.79967950301749,
        "from_longitude": 105.79953466373654,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6595,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79952259379272,
          21.023582025480714,
          105.79967950301749,
          21.023751022570362
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79967950301749,
            21.023582025480714
          ],
          [
            105.79952259379272,
            21.023751022570362
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1008 Đường Láng",
        "diemDau": "16-3",
        "diemCuoi": "16-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.023582025480714,
        "to_latitude": 21.023751022570362,
        "to_longitude": 105.79952259379272,
        "from_longitude": 105.79967950301749,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6596,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79952259379272,
          21.023751022570362,
          105.79969157296132,
          21.023910004842513
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79952259379272,
            21.023751022570362
          ],
          [
            105.79969157296132,
            21.023910004842513
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1008 Đường Láng",
        "diemDau": "16-4",
        "diemCuoi": "16-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.023751022570362,
        "to_latitude": 21.023910004842513,
        "to_longitude": 105.79969157296132,
        "from_longitude": 105.79952259379272,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6597,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82596682777576,
          21.00431666679471,
          105.82601711915744,
          21.004426216120407
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82601711915744,
            21.004426216120407
          ],
          [
            105.82596682777576,
            21.00431666679471
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTC",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 123 Khương Thượng",
        "diemDau": "1-1",
        "diemCuoi": "1-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khương Thượng C",
        "from_latitude": 21.004426216120407,
        "to_latitude": 21.00431666679471,
        "to_longitude": 105.82596682777576,
        "from_longitude": 105.82601711915744,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6598,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79855263996663,
          21.024074989454345,
          105.79865456391471,
          21.024140654020208
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79855540269035,
            21.024074989454345
          ],
          [
            105.79855263996663,
            21.024110610186604
          ],
          [
            105.79865456391471,
            21.024140654020208
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1074 Đường Láng",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.024074989454345,
        "to_latitude": 21.024140654020208,
        "to_longitude": 105.79865456391471,
        "from_longitude": 105.79855540269035,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6599,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79865456391471,
          21.024140654020208,
          105.79894692469334,
          21.02430839865145
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79865456391471,
            21.024140654020208
          ],
          [
            105.79894692469334,
            21.02430839865145
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1074 Đường Láng",
        "diemDau": "10-1",
        "diemCuoi": "10-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.024140654020208,
        "to_latitude": 21.02430839865145,
        "to_longitude": 105.79894692469334,
        "from_longitude": 105.79865456391471,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6600,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79894692469334,
          21.024170697848636,
          105.79906225968014,
          21.02430839865145
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79894692469334,
            21.02430839865145
          ],
          [
            105.79906225968014,
            21.024170697848636
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1074 Đường Láng",
        "diemDau": "10-2",
        "diemCuoi": "10-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.02430839865145,
        "to_latitude": 21.024170697848636,
        "to_longitude": 105.79906225968014,
        "from_longitude": 105.79894692469334,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6601,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79832875602835,
          21.02442925603559,
          105.79872430134294,
          21.024578792584915
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79832875602835,
            21.02442925603559
          ],
          [
            105.79834208656004,
            21.024459869387805
          ],
          [
            105.79872430134294,
            21.024578792584915
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1096 Đường Láng",
        "diemDau": "08",
        "diemCuoi": "8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.02442925603559,
        "to_latitude": 21.024578792584915,
        "to_longitude": 105.79872430134294,
        "from_longitude": 105.79832875602835,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6602,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79872430134294,
          21.024578792584915,
          105.79886645842514,
          21.02466892378707
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79872430134294,
            21.024578792584915
          ],
          [
            105.79878062773248,
            21.02460758450239
          ],
          [
            105.798775263317,
            21.024643887347636
          ],
          [
            105.79886645842514,
            21.02466892378707
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1096 Đường Láng",
        "diemDau": "8-1",
        "diemCuoi": "8-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.024578792584915,
        "to_latitude": 21.02466892378707,
        "to_longitude": 105.79886645842514,
        "from_longitude": 105.79872430134294,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6603,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79831660557751,
          21.025174658966087,
          105.79855532217435,
          21.025279811610048
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79831660557751,
            21.025279811610048
          ],
          [
            105.79833806324844,
            21.02521847257675
          ],
          [
            105.79855532217435,
            21.025174658966087
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1132 Đường Láng",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.025279811610048,
        "to_latitude": 21.025174658966087,
        "to_longitude": 105.79855532217435,
        "from_longitude": 105.79831660557751,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6604,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79855532217435,
          21.025172155330715,
          105.79871223140138,
          21.025174658966087
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79855532217435,
            21.025174658966087
          ],
          [
            105.79871223140138,
            21.025172155330715
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1132 Đường Láng",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.025174658966087,
        "to_latitude": 21.025172155330715,
        "to_longitude": 105.79871223140138,
        "from_longitude": 105.79855532217435,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6605,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79871223140138,
          21.02502444077517,
          105.79872430134294,
          21.025172155330715
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79871223140138,
            21.025172155330715
          ],
          [
            105.79872430134294,
            21.02502444077517
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1132 Đường Láng",
        "diemDau": "4-2",
        "diemCuoi": "4-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.025172155330715,
        "to_latitude": 21.02502444077517,
        "to_longitude": 105.79872430134294,
        "from_longitude": 105.79871223140138,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6606,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79839170743024,
          21.02541751138748,
          105.79853922891891,
          21.025507642082566
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79839170743024,
            21.025507642082566
          ],
          [
            105.79839975405345,
            21.0254638285577
          ],
          [
            105.79853922891891,
            21.02541751138748
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1142 Đường Láng",
        "diemDau": "03",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.025507642082566,
        "to_latitude": 21.02541751138748,
        "to_longitude": 105.79853922891891,
        "from_longitude": 105.79839170743024,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6607,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79853922891891,
          21.025309855208867,
          105.79857409763754,
          21.02541751138748
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79853922891891,
            21.02541751138748
          ],
          [
            105.79857409763754,
            21.025401237784983
          ],
          [
            105.79857409763754,
            21.025309855208867
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "BQLL",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1142 Đường Láng",
        "diemDau": "3-1",
        "diemCuoi": "3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "BQLL",
        "from_latitude": 21.02541751138748,
        "to_latitude": 21.025309855208867,
        "to_longitude": 105.79857409763754,
        "from_longitude": 105.79853922891891,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6608,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80062185744498,
          21.027606199600672,
          105.80067818383452,
          21.027862817806824
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80062185744498,
            21.027606199600672
          ],
          [
            105.80067818383452,
            21.027862817806824
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/21 Đường Láng",
        "diemDau": "15",
        "diemCuoi": "15-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.027606199600672,
        "to_latitude": 21.027862817806824,
        "to_longitude": 105.80067818383452,
        "from_longitude": 105.80062185744498,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6609,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80067818383452,
          21.027832883250575,
          105.80084582371494,
          21.027862817806824
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80067818383452,
            21.027862817806824
          ],
          [
            105.80084582371494,
            21.027832883250575
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/21 Đường Láng",
        "diemDau": "15-1",
        "diemCuoi": "15-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.027862817806824,
        "to_latitude": 21.027832883250575,
        "to_longitude": 105.80084582371494,
        "from_longitude": 105.80067818383452,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6610,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80084582371494,
          21.027824997727357,
          105.80100729962243,
          21.027832883250575
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80084582371494,
            21.027832883250575
          ],
          [
            105.80100729962243,
            21.027824997727357
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/21 Đường Láng",
        "diemDau": "15-2",
        "diemCuoi": "15-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.027832883250575,
        "to_latitude": 21.027824997727357,
        "to_longitude": 105.80100729962243,
        "from_longitude": 105.80084582371494,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6611,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80045019606864,
          21.0276399981213,
          105.80053208715951,
          21.02785309713111
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80045019606864,
            21.0276399981213
          ],
          [
            105.80053208715951,
            21.02785309713111
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/9 Đường Láng",
        "diemDau": "16",
        "diemCuoi": "16-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.0276399981213,
        "to_latitude": 21.02785309713111,
        "to_longitude": 105.80053208715951,
        "from_longitude": 105.80045019606864,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6612,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80020463771348,
          21.027690845859844,
          105.80028694390109,
          21.027894869145356
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80020463771348,
            21.027690845859844
          ],
          [
            105.80028694390109,
            21.027894869145356
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/7 Đường Láng",
        "diemDau": "17",
        "diemCuoi": "17-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.027690845859844,
        "to_latitude": 21.027894869145356,
        "to_longitude": 105.80028694390109,
        "from_longitude": 105.80020463771348,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6613,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80000360827299,
          21.02732579676248,
          105.80013369541135,
          21.02768130741452
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80013369541135,
            21.02768130741452
          ],
          [
            105.80000360827299,
            21.02732579676248
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/6 Đường Láng",
        "diemDau": "18",
        "diemCuoi": "18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02768130741452,
        "to_latitude": 21.02732579676248,
        "to_longitude": 105.80000360827299,
        "from_longitude": 105.80013369541135,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6614,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80000360827299,
          21.02724301915852,
          105.80027704763144,
          21.02732579676248
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80000360827299,
            21.02732579676248
          ],
          [
            105.80027704763144,
            21.02724301915852
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/6 Đường Láng",
        "diemDau": "18-1",
        "diemCuoi": "18-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02732579676248,
        "to_latitude": 21.02724301915852,
        "to_longitude": 105.80027704763144,
        "from_longitude": 105.80000360827299,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6615,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83731089552325,
          21.01163657559055,
          105.83743729462823,
          21.011778982705852
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83731089552325,
            21.01163657559055
          ],
          [
            105.83743729462823,
            21.011778982705852
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01163657559055,
        "to_latitude": 21.011778982705852,
        "to_longitude": 105.83743729462823,
        "from_longitude": 105.83731089552325,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6616,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83743729462823,
          21.011778982705852,
          105.83757408728596,
          21.01186912164487
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83743729462823,
            21.011778982705852
          ],
          [
            105.83757408728596,
            21.01186912164487
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.011778982705852,
        "to_latitude": 21.01186912164487,
        "to_longitude": 105.83757408728596,
        "from_longitude": 105.83743729462823,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6617,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8375365363596,
          21.01186912164487,
          105.83769042811639,
          21.012169584381578
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83757408728596,
            21.01186912164487
          ],
          [
            105.83769042811639,
            21.011968337012476
          ],
          [
            105.8375365363596,
            21.012169584381578
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01186912164487,
        "to_latitude": 21.012169584381578,
        "to_longitude": 105.8375365363596,
        "from_longitude": 105.83757408728596,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6618,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83743193020376,
          21.012169584381578,
          105.8375365363596,
          21.01236738868626
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8375365363596,
            21.012169584381578
          ],
          [
            105.83743193020376,
            21.01236738868626
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012169584381578,
        "to_latitude": 21.01236738868626,
        "to_longitude": 105.83743193020376,
        "from_longitude": 105.8375365363596,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6619,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83757408728596,
          21.01186912164487,
          105.83780743946728,
          21.01204689550419
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83757408728596,
            21.01186912164487
          ],
          [
            105.83780743946728,
            21.01204689550419
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01186912164487,
        "to_latitude": 21.01204689550419,
        "to_longitude": 105.83780743946728,
        "from_longitude": 105.83757408728596,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6620,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83780743946728,
          21.01204689550419,
          105.83785035481812,
          21.01217459208889
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83780743946728,
            21.01204689550419
          ],
          [
            105.83782453856635,
            21.012126079919554
          ],
          [
            105.83785035481812,
            21.01217459208889
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01204689550419,
        "to_latitude": 21.01217459208889,
        "to_longitude": 105.83785035481812,
        "from_longitude": 105.83780743946728,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6621,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83780743946728,
          21.012009337663738,
          105.83804883828084,
          21.01204689550419
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83780743946728,
            21.01204689550419
          ],
          [
            105.83804883828084,
            21.012009337663738
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01204689550419,
        "to_latitude": 21.012009337663738,
        "to_longitude": 105.83804883828084,
        "from_longitude": 105.83780743946728,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6622,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83780743946728,
          21.01179901358563,
          105.83816953768314,
          21.01204689550419
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83780743946728,
            21.01204689550419
          ],
          [
            105.8379693778473,
            21.011995879436387
          ],
          [
            105.83816953768314,
            21.01179901358563
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01204689550419,
        "to_latitude": 21.01179901358563,
        "to_longitude": 105.83816953768314,
        "from_longitude": 105.83780743946728,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6623,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83816953768314,
          21.01179901358563,
          105.83831169476537,
          21.011886648654553
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83816953768314,
            21.01179901358563
          ],
          [
            105.83831169476537,
            21.011886648654553
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01179901358563,
        "to_latitude": 21.011886648654553,
        "to_longitude": 105.83831169476537,
        "from_longitude": 105.83816953768314,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6624,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83780743946728,
          21.01204689550419,
          105.83811857571807,
          21.012279753899772
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83780743946728,
            21.01204689550419
          ],
          [
            105.83811857571807,
            21.012279753899772
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01204689550419,
        "to_latitude": 21.012279753899772,
        "to_longitude": 105.83811857571807,
        "from_longitude": 105.83780743946728,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6625,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83841898312893,
          21.012157065113023,
          105.83860137333623,
          21.01230228856419
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83841898312893,
            21.01230228856419
          ],
          [
            105.83860137333623,
            21.012157065113023
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01230228856419,
        "to_latitude": 21.012157065113023,
        "to_longitude": 105.83860137333623,
        "from_longitude": 105.83841898312893,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6626,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83860137333623,
          21.012157065113023,
          105.83873280157846,
          21.012227173003986
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83860137333623,
            21.012157065113023
          ],
          [
            105.83873280157846,
            21.012227173003986
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012157065113023,
        "to_latitude": 21.012227173003986,
        "to_longitude": 105.83873280157846,
        "from_longitude": 105.83860137333623,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6627,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83873280157846,
          21.012227173003986,
          105.83885618319746,
          21.012279753899772
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83873280157846,
            21.012227173003986
          ],
          [
            105.83885618319746,
            21.012279753899772
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012227173003986,
        "to_latitude": 21.012279753899772,
        "to_longitude": 105.83885618319746,
        "from_longitude": 105.83873280157846,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6628,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83831169476537,
          21.01230228856419,
          105.83841898312893,
          21.012497588845164
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83841898312893,
            21.01230228856419
          ],
          [
            105.83831169476537,
            21.012497588845164
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01230228856419,
        "to_latitude": 21.012497588845164,
        "to_longitude": 105.83831169476537,
        "from_longitude": 105.83841898312893,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6629,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8382070886095,
          21.012497588845164,
          105.83831169476537,
          21.012690385025078
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83831169476537,
            21.012497588845164
          ],
          [
            105.8382070886095,
            21.012690385025078
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012497588845164,
        "to_latitude": 21.012690385025078,
        "to_longitude": 105.8382070886095,
        "from_longitude": 105.83831169476537,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6630,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83796300759718,
          21.012480061907283,
          105.8382070886095,
          21.012690385025078
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8382070886095,
            21.012690385025078
          ],
          [
            105.83796300759718,
            21.012480061907283
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012690385025078,
        "to_latitude": 21.012480061907283,
        "to_longitude": 105.83796300759718,
        "from_longitude": 105.8382070886095,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6631,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83783962597816,
          21.012480061907283,
          105.83796300759718,
          21.01255768118773
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83796300759718,
            21.012480061907283
          ],
          [
            105.83783962597816,
            21.01255768118773
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012480061907283,
        "to_latitude": 21.01255768118773,
        "to_longitude": 105.83783962597816,
        "from_longitude": 105.83796300759718,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6632,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83768908696759,
          21.01255768118773,
          105.83783962597816,
          21.012803370982752
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83783962597816,
            21.01255768118773
          ],
          [
            105.83768908696759,
            21.012803370982752
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01255768118773,
        "to_latitude": 21.012803370982752,
        "to_longitude": 105.83768908696759,
        "from_longitude": 105.83783962597816,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6633,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83764483055782,
          21.012803370982752,
          105.83769445139205,
          21.012868470886154
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83768908696759,
            21.012803370982752
          ],
          [
            105.83764483055782,
            21.01284468438672
          ],
          [
            105.83769445139205,
            21.012868470886154
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012803370982752,
        "to_latitude": 21.012868470886154,
        "to_longitude": 105.83769445139205,
        "from_longitude": 105.83768908696759,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6634,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83775705967572,
          21.01255768118773,
          105.83785940728497,
          21.01279085176742
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83783962597816,
            21.01255768118773
          ],
          [
            105.83775705967572,
            21.012692434999323
          ],
          [
            105.83785940728497,
            21.01275704988066
          ],
          [
            105.83784733730519,
            21.01279085176742
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01255768118773,
        "to_latitude": 21.01279085176742,
        "to_longitude": 105.83784733730519,
        "from_longitude": 105.83783962597816,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6635,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83779503426321,
          21.01279085176742,
          105.8379412146211,
          21.012956105325927
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83784733730519,
            21.01279085176742
          ],
          [
            105.83779503426321,
            21.01287222664882
          ],
          [
            105.8379412146211,
            21.012956105325927
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01279085176742,
        "to_latitude": 21.012956105325927,
        "to_longitude": 105.8379412146211,
        "from_longitude": 105.83784733730519,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6636,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83763544279478,
          21.012529199924735,
          105.83783962597816,
          21.01260431533367
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83783962597816,
            21.01255768118773
          ],
          [
            105.83773334345821,
            21.012529199924735
          ],
          [
            105.83763544279478,
            21.01260431533367
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01255768118773,
        "to_latitude": 21.01260431533367,
        "to_longitude": 105.83763544279478,
        "from_longitude": 105.83783962597816,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6637,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83753117194411,
          21.01253514656191,
          105.83763544279478,
          21.01260431533367
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83763544279478,
            21.01260431533367
          ],
          [
            105.83753117194411,
            21.01253514656191
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01260431533367,
        "to_latitude": 21.01253514656191,
        "to_longitude": 105.83753117194411,
        "from_longitude": 105.83763544279478,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6638,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8373273240569,
          21.01253514656191,
          105.83753117194411,
          21.012667850419295
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83753117194411,
            21.01253514656191
          ],
          [
            105.8373273240569,
            21.012667850419295
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01253514656191,
        "to_latitude": 21.012667850419295,
        "to_longitude": 105.8373273240569,
        "from_longitude": 105.83753117194411,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6639,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83725758661967,
          21.01255768118773,
          105.8373273240569,
          21.012667850419295
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8373273240569,
            21.012667850419295
          ],
          [
            105.83725758661967,
            21.01255768118773
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012667850419295,
        "to_latitude": 21.01255768118773,
        "to_longitude": 105.83725758661967,
        "from_longitude": 105.8373273240569,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6640,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83811857571807,
          21.012216218648835,
          105.83841898312893,
          21.01230228856419
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83811857571807,
            21.012279753899772
          ],
          [
            105.83818127236673,
            21.012216218648835
          ],
          [
            105.83841898312893,
            21.01230228856419
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012279753899772,
        "to_latitude": 21.01230228856419,
        "to_longitude": 105.83841898312893,
        "from_longitude": 105.83811857571807,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6641,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83731089552325,
          21.01163657559055,
          105.83743729462823,
          21.011778982705852
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83731089552325,
            21.01163657559055
          ],
          [
            105.83743729462823,
            21.011778982705852
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01163657559055,
        "to_latitude": 21.011778982705852,
        "to_longitude": 105.83743729462823,
        "from_longitude": 105.83731089552325,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6642,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83743729462823,
          21.011778982705852,
          105.83757408728596,
          21.01186912164487
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83743729462823,
            21.011778982705852
          ],
          [
            105.83757408728596,
            21.01186912164487
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.011778982705852,
        "to_latitude": 21.01186912164487,
        "to_longitude": 105.83757408728596,
        "from_longitude": 105.83743729462823,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6643,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8375365363596,
          21.01186912164487,
          105.83769042811639,
          21.012169584381578
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83757408728596,
            21.01186912164487
          ],
          [
            105.83769042811639,
            21.011968337012476
          ],
          [
            105.8375365363596,
            21.012169584381578
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01186912164487,
        "to_latitude": 21.012169584381578,
        "to_longitude": 105.8375365363596,
        "from_longitude": 105.83757408728596,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6644,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83743193020376,
          21.012169584381578,
          105.8375365363596,
          21.01236738868626
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8375365363596,
            21.012169584381578
          ],
          [
            105.83743193020376,
            21.01236738868626
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012169584381578,
        "to_latitude": 21.01236738868626,
        "to_longitude": 105.83743193020376,
        "from_longitude": 105.8375365363596,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6645,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83757408728596,
          21.01186912164487,
          105.83780743946728,
          21.01204689550419
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83757408728596,
            21.01186912164487
          ],
          [
            105.83780743946728,
            21.01204689550419
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01186912164487,
        "to_latitude": 21.01204689550419,
        "to_longitude": 105.83780743946728,
        "from_longitude": 105.83757408728596,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6646,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83780743946728,
          21.01204689550419,
          105.83785035481812,
          21.01217459208889
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83780743946728,
            21.01204689550419
          ],
          [
            105.83782453856635,
            21.012126079919554
          ],
          [
            105.83785035481812,
            21.01217459208889
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01204689550419,
        "to_latitude": 21.01217459208889,
        "to_longitude": 105.83785035481812,
        "from_longitude": 105.83780743946728,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6647,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83780743946728,
          21.012009337663738,
          105.83804883828084,
          21.01204689550419
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83780743946728,
            21.01204689550419
          ],
          [
            105.83804883828084,
            21.012009337663738
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01204689550419,
        "to_latitude": 21.012009337663738,
        "to_longitude": 105.83804883828084,
        "from_longitude": 105.83780743946728,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6648,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83780743946728,
          21.01179901358563,
          105.83816953768314,
          21.01204689550419
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83780743946728,
            21.01204689550419
          ],
          [
            105.8379693778473,
            21.011995879436387
          ],
          [
            105.83816953768314,
            21.01179901358563
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01204689550419,
        "to_latitude": 21.01179901358563,
        "to_longitude": 105.83816953768314,
        "from_longitude": 105.83780743946728,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6649,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83816953768314,
          21.01179901358563,
          105.83831169476537,
          21.011886648654553
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83816953768314,
            21.01179901358563
          ],
          [
            105.83831169476537,
            21.011886648654553
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01179901358563,
        "to_latitude": 21.011886648654553,
        "to_longitude": 105.83831169476537,
        "from_longitude": 105.83816953768314,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6650,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83780743946728,
          21.01204689550419,
          105.83811857571807,
          21.012279753899772
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83780743946728,
            21.01204689550419
          ],
          [
            105.83811857571807,
            21.012279753899772
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01204689550419,
        "to_latitude": 21.012279753899772,
        "to_longitude": 105.83811857571807,
        "from_longitude": 105.83780743946728,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6651,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83841898312893,
          21.012157065113023,
          105.83860137333623,
          21.01230228856419
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83841898312893,
            21.01230228856419
          ],
          [
            105.83860137333623,
            21.012157065113023
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01230228856419,
        "to_latitude": 21.012157065113023,
        "to_longitude": 105.83860137333623,
        "from_longitude": 105.83841898312893,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6652,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83860137333623,
          21.012157065113023,
          105.83873280157846,
          21.012227173003986
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83860137333623,
            21.012157065113023
          ],
          [
            105.83873280157846,
            21.012227173003986
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012157065113023,
        "to_latitude": 21.012227173003986,
        "to_longitude": 105.83873280157846,
        "from_longitude": 105.83860137333623,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6653,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83873280157846,
          21.012227173003986,
          105.83885618319746,
          21.012279753899772
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83873280157846,
            21.012227173003986
          ],
          [
            105.83885618319746,
            21.012279753899772
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012227173003986,
        "to_latitude": 21.012279753899772,
        "to_longitude": 105.83885618319746,
        "from_longitude": 105.83873280157846,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6654,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83831169476537,
          21.01230228856419,
          105.83841898312893,
          21.012497588845164
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83841898312893,
            21.01230228856419
          ],
          [
            105.83831169476537,
            21.012497588845164
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01230228856419,
        "to_latitude": 21.012497588845164,
        "to_longitude": 105.83831169476537,
        "from_longitude": 105.83841898312893,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6655,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8382070886095,
          21.012497588845164,
          105.83831169476537,
          21.012690385025078
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83831169476537,
            21.012497588845164
          ],
          [
            105.8382070886095,
            21.012690385025078
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012497588845164,
        "to_latitude": 21.012690385025078,
        "to_longitude": 105.8382070886095,
        "from_longitude": 105.83831169476537,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6656,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83796300759718,
          21.012480061907283,
          105.8382070886095,
          21.012690385025078
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8382070886095,
            21.012690385025078
          ],
          [
            105.83796300759718,
            21.012480061907283
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012690385025078,
        "to_latitude": 21.012480061907283,
        "to_longitude": 105.83796300759718,
        "from_longitude": 105.8382070886095,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6657,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83783962597816,
          21.012480061907283,
          105.83796300759718,
          21.01255768118773
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83796300759718,
            21.012480061907283
          ],
          [
            105.83783962597816,
            21.01255768118773
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012480061907283,
        "to_latitude": 21.01255768118773,
        "to_longitude": 105.83783962597816,
        "from_longitude": 105.83796300759718,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6658,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83768908696759,
          21.01255768118773,
          105.83783962597816,
          21.012803370982752
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83783962597816,
            21.01255768118773
          ],
          [
            105.83768908696759,
            21.012803370982752
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01255768118773,
        "to_latitude": 21.012803370982752,
        "to_longitude": 105.83768908696759,
        "from_longitude": 105.83783962597816,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6659,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83764483055782,
          21.012803370982752,
          105.83769445139205,
          21.012868470886154
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83768908696759,
            21.012803370982752
          ],
          [
            105.83764483055782,
            21.01284468438672
          ],
          [
            105.83769445139205,
            21.012868470886154
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012803370982752,
        "to_latitude": 21.012868470886154,
        "to_longitude": 105.83769445139205,
        "from_longitude": 105.83768908696759,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6660,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83775705967572,
          21.01255768118773,
          105.83785940728497,
          21.01279085176742
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83783962597816,
            21.01255768118773
          ],
          [
            105.83775705967572,
            21.012692434999323
          ],
          [
            105.83785940728497,
            21.01275704988066
          ],
          [
            105.83784733730519,
            21.01279085176742
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01255768118773,
        "to_latitude": 21.01279085176742,
        "to_longitude": 105.83784733730519,
        "from_longitude": 105.83783962597816,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6661,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83779503426321,
          21.01279085176742,
          105.8379412146211,
          21.012956105325927
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83784733730519,
            21.01279085176742
          ],
          [
            105.83779503426321,
            21.01287222664882
          ],
          [
            105.8379412146211,
            21.012956105325927
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01279085176742,
        "to_latitude": 21.012956105325927,
        "to_longitude": 105.8379412146211,
        "from_longitude": 105.83784733730519,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6662,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83763544279478,
          21.012529199924735,
          105.83783962597816,
          21.01260431533367
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83783962597816,
            21.01255768118773
          ],
          [
            105.83773334345821,
            21.012529199924735
          ],
          [
            105.83763544279478,
            21.01260431533367
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01255768118773,
        "to_latitude": 21.01260431533367,
        "to_longitude": 105.83763544279478,
        "from_longitude": 105.83783962597816,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6663,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83753117194411,
          21.01253514656191,
          105.83763544279478,
          21.01260431533367
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83763544279478,
            21.01260431533367
          ],
          [
            105.83753117194411,
            21.01253514656191
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01260431533367,
        "to_latitude": 21.01253514656191,
        "to_longitude": 105.83753117194411,
        "from_longitude": 105.83763544279478,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6664,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8373273240569,
          21.01253514656191,
          105.83753117194411,
          21.012667850419295
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83753117194411,
            21.01253514656191
          ],
          [
            105.8373273240569,
            21.012667850419295
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01253514656191,
        "to_latitude": 21.012667850419295,
        "to_longitude": 105.8373273240569,
        "from_longitude": 105.83753117194411,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6665,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83725758661967,
          21.01255768118773,
          105.8373273240569,
          21.012667850419295
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8373273240569,
            21.012667850419295
          ],
          [
            105.83725758661967,
            21.01255768118773
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012667850419295,
        "to_latitude": 21.01255768118773,
        "to_longitude": 105.83725758661967,
        "from_longitude": 105.8373273240569,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6666,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83811857571807,
          21.012216218648835,
          105.83841898312893,
          21.01230228856419
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83811857571807,
            21.012279753899772
          ],
          [
            105.83818127236673,
            21.012216218648835
          ],
          [
            105.83841898312893,
            21.01230228856419
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012279753899772,
        "to_latitude": 21.01230228856419,
        "to_longitude": 105.83841898312893,
        "from_longitude": 105.83811857571807,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6667,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83731089552325,
          21.01163657559055,
          105.83743729462823,
          21.011778982705852
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83731089552325,
            21.01163657559055
          ],
          [
            105.83743729462823,
            21.011778982705852
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01163657559055,
        "to_latitude": 21.011778982705852,
        "to_longitude": 105.83743729462823,
        "from_longitude": 105.83731089552325,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6668,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83743729462823,
          21.011778982705852,
          105.83757408728596,
          21.01186912164487
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83743729462823,
            21.011778982705852
          ],
          [
            105.83757408728596,
            21.01186912164487
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.011778982705852,
        "to_latitude": 21.01186912164487,
        "to_longitude": 105.83757408728596,
        "from_longitude": 105.83743729462823,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6669,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8375365363596,
          21.01186912164487,
          105.83769042811639,
          21.012169584381578
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83757408728596,
            21.01186912164487
          ],
          [
            105.83769042811639,
            21.011968337012476
          ],
          [
            105.8375365363596,
            21.012169584381578
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01186912164487,
        "to_latitude": 21.012169584381578,
        "to_longitude": 105.8375365363596,
        "from_longitude": 105.83757408728596,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6670,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83743193020376,
          21.012169584381578,
          105.8375365363596,
          21.01236738868626
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8375365363596,
            21.012169584381578
          ],
          [
            105.83743193020376,
            21.01236738868626
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012169584381578,
        "to_latitude": 21.01236738868626,
        "to_longitude": 105.83743193020376,
        "from_longitude": 105.8375365363596,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6671,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83757408728596,
          21.01186912164487,
          105.83780743946728,
          21.01204689550419
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83757408728596,
            21.01186912164487
          ],
          [
            105.83780743946728,
            21.01204689550419
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01186912164487,
        "to_latitude": 21.01204689550419,
        "to_longitude": 105.83780743946728,
        "from_longitude": 105.83757408728596,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6672,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83780743946728,
          21.01204689550419,
          105.83785035481812,
          21.01217459208889
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83780743946728,
            21.01204689550419
          ],
          [
            105.83782453856635,
            21.012126079919554
          ],
          [
            105.83785035481812,
            21.01217459208889
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01204689550419,
        "to_latitude": 21.01217459208889,
        "to_longitude": 105.83785035481812,
        "from_longitude": 105.83780743946728,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6673,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83780743946728,
          21.012009337663738,
          105.83804883828084,
          21.01204689550419
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83780743946728,
            21.01204689550419
          ],
          [
            105.83804883828084,
            21.012009337663738
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01204689550419,
        "to_latitude": 21.012009337663738,
        "to_longitude": 105.83804883828084,
        "from_longitude": 105.83780743946728,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6674,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83780743946728,
          21.01179901358563,
          105.83816953768314,
          21.01204689550419
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83780743946728,
            21.01204689550419
          ],
          [
            105.8379693778473,
            21.011995879436387
          ],
          [
            105.83816953768314,
            21.01179901358563
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01204689550419,
        "to_latitude": 21.01179901358563,
        "to_longitude": 105.83816953768314,
        "from_longitude": 105.83780743946728,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6675,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83816953768314,
          21.01179901358563,
          105.83831169476537,
          21.011886648654553
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83816953768314,
            21.01179901358563
          ],
          [
            105.83831169476537,
            21.011886648654553
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01179901358563,
        "to_latitude": 21.011886648654553,
        "to_longitude": 105.83831169476537,
        "from_longitude": 105.83816953768314,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6676,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83780743946728,
          21.01204689550419,
          105.83811857571807,
          21.012279753899772
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83780743946728,
            21.01204689550419
          ],
          [
            105.83811857571807,
            21.012279753899772
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01204689550419,
        "to_latitude": 21.012279753899772,
        "to_longitude": 105.83811857571807,
        "from_longitude": 105.83780743946728,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6677,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83841898312893,
          21.012157065113023,
          105.83860137333623,
          21.01230228856419
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83841898312893,
            21.01230228856419
          ],
          [
            105.83860137333623,
            21.012157065113023
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01230228856419,
        "to_latitude": 21.012157065113023,
        "to_longitude": 105.83860137333623,
        "from_longitude": 105.83841898312893,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6678,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83860137333623,
          21.012157065113023,
          105.83873280157846,
          21.012227173003986
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83860137333623,
            21.012157065113023
          ],
          [
            105.83873280157846,
            21.012227173003986
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012157065113023,
        "to_latitude": 21.012227173003986,
        "to_longitude": 105.83873280157846,
        "from_longitude": 105.83860137333623,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6679,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83873280157846,
          21.012227173003986,
          105.83885618319746,
          21.012279753899772
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83873280157846,
            21.012227173003986
          ],
          [
            105.83885618319746,
            21.012279753899772
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012227173003986,
        "to_latitude": 21.012279753899772,
        "to_longitude": 105.83885618319746,
        "from_longitude": 105.83873280157846,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6680,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83831169476537,
          21.01230228856419,
          105.83841898312893,
          21.012497588845164
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83841898312893,
            21.01230228856419
          ],
          [
            105.83831169476537,
            21.012497588845164
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01230228856419,
        "to_latitude": 21.012497588845164,
        "to_longitude": 105.83831169476537,
        "from_longitude": 105.83841898312893,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6681,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8382070886095,
          21.012497588845164,
          105.83831169476537,
          21.012690385025078
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83831169476537,
            21.012497588845164
          ],
          [
            105.8382070886095,
            21.012690385025078
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012497588845164,
        "to_latitude": 21.012690385025078,
        "to_longitude": 105.8382070886095,
        "from_longitude": 105.83831169476537,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6682,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83796300759718,
          21.012480061907283,
          105.8382070886095,
          21.012690385025078
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8382070886095,
            21.012690385025078
          ],
          [
            105.83796300759718,
            21.012480061907283
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012690385025078,
        "to_latitude": 21.012480061907283,
        "to_longitude": 105.83796300759718,
        "from_longitude": 105.8382070886095,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6683,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83783962597816,
          21.012480061907283,
          105.83796300759718,
          21.01255768118773
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83796300759718,
            21.012480061907283
          ],
          [
            105.83783962597816,
            21.01255768118773
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012480061907283,
        "to_latitude": 21.01255768118773,
        "to_longitude": 105.83783962597816,
        "from_longitude": 105.83796300759718,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6684,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83768908696759,
          21.01255768118773,
          105.83783962597816,
          21.012803370982752
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83783962597816,
            21.01255768118773
          ],
          [
            105.83768908696759,
            21.012803370982752
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01255768118773,
        "to_latitude": 21.012803370982752,
        "to_longitude": 105.83768908696759,
        "from_longitude": 105.83783962597816,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6685,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83764483055782,
          21.012803370982752,
          105.83769445139205,
          21.012868470886154
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83768908696759,
            21.012803370982752
          ],
          [
            105.83764483055782,
            21.01284468438672
          ],
          [
            105.83769445139205,
            21.012868470886154
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012803370982752,
        "to_latitude": 21.012868470886154,
        "to_longitude": 105.83769445139205,
        "from_longitude": 105.83768908696759,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6686,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83775705967572,
          21.01255768118773,
          105.83785940728497,
          21.01279085176742
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83783962597816,
            21.01255768118773
          ],
          [
            105.83775705967572,
            21.012692434999323
          ],
          [
            105.83785940728497,
            21.01275704988066
          ],
          [
            105.83784733730519,
            21.01279085176742
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01255768118773,
        "to_latitude": 21.01279085176742,
        "to_longitude": 105.83784733730519,
        "from_longitude": 105.83783962597816,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6687,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83779503426321,
          21.01279085176742,
          105.8379412146211,
          21.012956105325927
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83784733730519,
            21.01279085176742
          ],
          [
            105.83779503426321,
            21.01287222664882
          ],
          [
            105.8379412146211,
            21.012956105325927
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01279085176742,
        "to_latitude": 21.012956105325927,
        "to_longitude": 105.8379412146211,
        "from_longitude": 105.83784733730519,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6688,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83763544279478,
          21.012529199924735,
          105.83783962597816,
          21.01260431533367
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83783962597816,
            21.01255768118773
          ],
          [
            105.83773334345821,
            21.012529199924735
          ],
          [
            105.83763544279478,
            21.01260431533367
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01255768118773,
        "to_latitude": 21.01260431533367,
        "to_longitude": 105.83763544279478,
        "from_longitude": 105.83783962597816,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6689,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83753117194411,
          21.01253514656191,
          105.83763544279478,
          21.01260431533367
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83763544279478,
            21.01260431533367
          ],
          [
            105.83753117194411,
            21.01253514656191
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01260431533367,
        "to_latitude": 21.01253514656191,
        "to_longitude": 105.83753117194411,
        "from_longitude": 105.83763544279478,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6690,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8373273240569,
          21.01253514656191,
          105.83753117194411,
          21.012667850419295
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83753117194411,
            21.01253514656191
          ],
          [
            105.8373273240569,
            21.012667850419295
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.01253514656191,
        "to_latitude": 21.012667850419295,
        "to_longitude": 105.8373273240569,
        "from_longitude": 105.83753117194411,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6691,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83725758661967,
          21.01255768118773,
          105.8373273240569,
          21.012667850419295
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8373273240569,
            21.012667850419295
          ],
          [
            105.83725758661967,
            21.01255768118773
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012667850419295,
        "to_latitude": 21.01255768118773,
        "to_longitude": 105.83725758661967,
        "from_longitude": 105.8373273240569,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6692,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83811857571807,
          21.012216218648835,
          105.83841898312893,
          21.01230228856419
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83811857571807,
            21.012279753899772
          ],
          [
            105.83818127236673,
            21.012216218648835
          ],
          [
            105.83841898312893,
            21.01230228856419
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HBTT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hợp Bộ Trung Tự",
        "from_latitude": 21.012279753899772,
        "to_latitude": 21.01230228856419,
        "to_longitude": 105.83841898312893,
        "from_longitude": 105.83811857571807,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6693,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83468233068741,
          21.004593043926892,
          105.8347413392847,
          21.004744534687
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83468233068741,
            21.004744534687
          ],
          [
            105.8347413392847,
            21.004593043926892
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "Tủ điện",
        "diemCuoi": "6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.004744534687,
        "to_latitude": 21.004593043926892,
        "to_longitude": 105.8347413392847,
        "from_longitude": 105.83468233068741,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6694,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8347413392847,
          21.004593043926892,
          105.83498005589051,
          21.00467317128985
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8347413392847,
            21.004593043926892
          ],
          [
            105.83498005589051,
            21.00467317128985
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "6",
        "diemCuoi": "8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.004593043926892,
        "to_latitude": 21.00467317128985,
        "to_longitude": 105.83498005589051,
        "from_longitude": 105.8347413392847,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6695,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8348754497347,
          21.005028735944922,
          105.83506689240883,
          21.005081006383392
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8348754497347,
            21.005028735944922
          ],
          [
            105.83506689240883,
            21.005081006383392
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "2",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.005028735944922,
        "to_latitude": 21.005081006383392,
        "to_longitude": 105.83506689240883,
        "from_longitude": 105.8348754497347,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6696,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83462332208119,
          21.00489383431519,
          105.83464109170073,
          21.00496238063471
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83464109170073,
            21.00489383431519
          ],
          [
            105.83462332208119,
            21.00496238063471
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "5",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00489383431519,
        "to_latitude": 21.00496238063471,
        "to_longitude": 105.83462332208119,
        "from_longitude": 105.83464109170073,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6697,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83462332208119,
          21.00496238063471,
          105.8348754497347,
          21.005028735944922
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83462332208119,
            21.00496238063471
          ],
          [
            105.8348754497347,
            21.005028735944922
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "",
        "diemCuoi": "2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00496238063471,
        "to_latitude": 21.005028735944922,
        "to_longitude": 105.8348754497347,
        "from_longitude": 105.83462332208119,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6698,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83437655886111,
          21.00490604119518,
          105.83462332208119,
          21.00496238063471
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83462332208119,
            21.00496238063471
          ],
          [
            105.83437655886111,
            21.00490604119518
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "",
        "diemCuoi": "3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00496238063471,
        "to_latitude": 21.00490604119518,
        "to_longitude": 105.83437655886111,
        "from_longitude": 105.83462332208119,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6699,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83418655386828,
          21.004858573537977,
          105.83437655886111,
          21.00490604119518
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83437655886111,
            21.00490604119518
          ],
          [
            105.83418655386828,
            21.004858573537977
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "3",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00490604119518,
        "to_latitude": 21.004858573537977,
        "to_longitude": 105.83418655386828,
        "from_longitude": 105.83437655886111,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6700,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83453883250138,
          21.00458427999387,
          105.8347413392847,
          21.004593043926892
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8347413392847,
            21.004593043926892
          ],
          [
            105.83453883250138,
            21.00458427999387
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "7",
        "diemCuoi": "9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.004593043926892,
        "to_latitude": 21.00458427999387,
        "to_longitude": 105.83453883250138,
        "from_longitude": 105.8347413392847,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6701,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83401982505175,
          21.004481616740915,
          105.83416600544555,
          21.00453169638593
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83416600544555,
            21.004481616740915
          ],
          [
            105.83401982505175,
            21.00453169638593
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "10",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.004481616740915,
        "to_latitude": 21.00453169638593,
        "to_longitude": 105.83401982505175,
        "from_longitude": 105.83416600544555,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6702,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83391086034203,
          21.00453169638593,
          105.83401982505175,
          21.004710417979528
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83401982505175,
            21.00453169638593
          ],
          [
            105.83391086034203,
            21.004710417979528
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00453169638593,
        "to_latitude": 21.004710417979528,
        "to_longitude": 105.83391086034203,
        "from_longitude": 105.83401982505175,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6703,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83387498578878,
          21.004222454311215,
          105.83401982505175,
          21.00453169638593
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83401982505175,
            21.00453169638593
          ],
          [
            105.83387498578878,
            21.004222454311215
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00453169638593,
        "to_latitude": 21.004222454311215,
        "to_longitude": 105.83387498578878,
        "from_longitude": 105.83401982505175,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6704,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83387498578878,
          21.004106018870566,
          105.83399434409618,
          21.004222454311215
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83387498578878,
            21.004222454311215
          ],
          [
            105.83399434409618,
            21.004106018870566
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.004222454311215,
        "to_latitude": 21.004106018870566,
        "to_longitude": 105.83399434409618,
        "from_longitude": 105.83387498578878,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6705,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8347413392847,
          21.004257510125104,
          105.83484460433668,
          21.004593043926892
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8347413392847,
            21.004593043926892
          ],
          [
            105.83484460433668,
            21.004257510125104
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "7",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.004593043926892,
        "to_latitude": 21.004257510125104,
        "to_longitude": 105.83484460433668,
        "from_longitude": 105.8347413392847,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6706,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83484460433668,
          21.004257510125104,
          105.83506588658318,
          21.0043075898453
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83484460433668,
            21.004257510125104
          ],
          [
            105.83506588658318,
            21.0043075898453
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "15",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.004257510125104,
        "to_latitude": 21.0043075898453,
        "to_longitude": 105.83506588658318,
        "from_longitude": 105.83484460433668,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6707,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83484460433668,
          21.003962039437145,
          105.83493714054869,
          21.004257510125104
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83484460433668,
            21.004257510125104
          ],
          [
            105.83493714054869,
            21.003962039437145
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "15",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.004257510125104,
        "to_latitude": 21.003962039437145,
        "to_longitude": 105.83493714054869,
        "from_longitude": 105.83484460433668,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6708,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83493714054869,
          21.00393825151772,
          105.83522547802468,
          21.003962039437145
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83493714054869,
            21.003962039437145
          ],
          [
            105.83522547802468,
            21.00393825151772
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "18",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.003962039437145,
        "to_latitude": 21.00393825151772,
        "to_longitude": 105.83522547802468,
        "from_longitude": 105.83493714054869,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6709,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83458308896505,
          21.003869391728422,
          105.83493714054869,
          21.003962039437145
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83493714054869,
            21.003962039437145
          ],
          [
            105.83458308896505,
            21.003869391728422
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.003962039437145,
        "to_latitude": 21.003869391728422,
        "to_longitude": 105.83458308896505,
        "from_longitude": 105.83493714054869,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6710,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83485533317662,
          21.00380804388911,
          105.83522547802468,
          21.00393825151772
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83522547802468,
            21.00393825151772
          ],
          [
            105.83485533317662,
            21.00380804388911
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00393825151772,
        "to_latitude": 21.00380804388911,
        "to_longitude": 105.83485533317662,
        "from_longitude": 105.83522547802468,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6711,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83423440177891,
          21.003635268208367,
          105.83440338095652,
          21.003696616117008
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83440338095652,
            21.003696616117008
          ],
          [
            105.83423440177891,
            21.003635268208367
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "23",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.003696616117008,
        "to_latitude": 21.003635268208367,
        "to_longitude": 105.83423440177891,
        "from_longitude": 105.83440338095652,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6712,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83407212814764,
          21.003635268208367,
          105.83423440177891,
          21.003655300179812
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83423440177891,
            21.003635268208367
          ],
          [
            105.83407212814764,
            21.003655300179812
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.003635268208367,
        "to_latitude": 21.003655300179812,
        "to_longitude": 105.83407212814764,
        "from_longitude": 105.83423440177891,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6713,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83384950479726,
          21.003655300179812,
          105.83407212814764,
          21.003747948022323
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83407212814764,
            21.003655300179812
          ],
          [
            105.83384950479726,
            21.003747948022323
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "24",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.003655300179812,
        "to_latitude": 21.003747948022323,
        "to_longitude": 105.83384950479726,
        "from_longitude": 105.83407212814764,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6714,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83372478207438,
          21.00362775621617,
          105.83384950479726,
          21.003747948022323
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83384950479726,
            21.003747948022323
          ],
          [
            105.83372478207438,
            21.00362775621617
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.003747948022323,
        "to_latitude": 21.00362775621617,
        "to_longitude": 105.83372478207438,
        "from_longitude": 105.83384950479726,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6715,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83407212814764,
          21.00348753232023,
          105.83412309011271,
          21.003655300179812
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83407212814764,
            21.003655300179812
          ],
          [
            105.83412309011271,
            21.00348753232023
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.003655300179812,
        "to_latitude": 21.00348753232023,
        "to_longitude": 105.83412309011271,
        "from_longitude": 105.83407212814764,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6716,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83394472319907,
          21.00356265228227,
          105.83407212814764,
          21.003655300179812
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83407212814764,
            21.003655300179812
          ],
          [
            105.83394472319907,
            21.00356265228227
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "24",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.003655300179812,
        "to_latitude": 21.00356265228227,
        "to_longitude": 105.83394472319907,
        "from_longitude": 105.83407212814764,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6717,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83372075876277,
          21.003508816312927,
          105.83394472319907,
          21.00356265228227
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83394472319907,
            21.00356265228227
          ],
          [
            105.83372075876277,
            21.003508816312927
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00356265228227,
        "to_latitude": 21.003508816312927,
        "to_longitude": 105.83372075876277,
        "from_longitude": 105.83394472319907,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6718,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83384950479726,
          21.00339738831741,
          105.83412309011271,
          21.00348753232023
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83384950479726,
            21.00339738831741
          ],
          [
            105.83412309011271,
            21.00348753232023
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00339738831741,
        "to_latitude": 21.00348753232023,
        "to_longitude": 105.83412309011271,
        "from_longitude": 105.83384950479726,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6719,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83368723113904,
          21.003382364312863,
          105.83384950479726,
          21.00339738831741
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83384950479726,
            21.00339738831741
          ],
          [
            105.83368723113904,
            21.003382364312863
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "26",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00339738831741,
        "to_latitude": 21.003382364312863,
        "to_longitude": 105.83368723113904,
        "from_longitude": 105.83384950479726,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6720,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83362688144689,
          21.003275944227212,
          105.83368723113904,
          21.003382364312863
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83368723113904,
            21.003382364312863
          ],
          [
            105.83362688144689,
            21.003275944227212
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.003382364312863,
        "to_latitude": 21.003275944227212,
        "to_longitude": 105.83362688144689,
        "from_longitude": 105.83368723113904,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6721,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83388973793134,
          21.003149492028157,
          105.8340520115806,
          21.003287212239407
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83388973793134,
            21.003149492028157
          ],
          [
            105.8340520115806,
            21.003287212239407
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "49",
        "diemCuoi": "50",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.003149492028157,
        "to_latitude": 21.003287212239407,
        "to_longitude": 105.8340520115806,
        "from_longitude": 105.83388973793134,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6722,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8340520115806,
          21.003287212239407,
          105.83436180671852,
          21.003297228250077
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8340520115806,
            21.003287212239407
          ],
          [
            105.83436180671852,
            21.003297228250077
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "48",
        "diemCuoi": "49",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.003287212239407,
        "to_latitude": 21.003297228250077,
        "to_longitude": 105.83436180671852,
        "from_longitude": 105.8340520115806,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6723,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83412309011271,
          21.00348753232023,
          105.83429609258394,
          21.003525092306834
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83412309011271,
            21.00348753232023
          ],
          [
            105.83429609258394,
            21.003525092306834
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "25",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00348753232023,
        "to_latitude": 21.003525092306834,
        "to_longitude": 105.83429609258394,
        "from_longitude": 105.83412309011271,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6724,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83429609258394,
          21.003297228250077,
          105.83436180671852,
          21.003525092306834
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83429609258394,
            21.003525092306834
          ],
          [
            105.83436180671852,
            21.003297228250077
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "",
        "diemCuoi": "48",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.003525092306834,
        "to_latitude": 21.003297228250077,
        "to_longitude": 105.83436180671852,
        "from_longitude": 105.83429609258394,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6725,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.833533004131,
          21.00362775621617,
          105.83372478207438,
          21.003789263933026
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83372478207438,
            21.00362775621617
          ],
          [
            105.833533004131,
            21.003789263933026
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00362775621617,
        "to_latitude": 21.003789263933026,
        "to_longitude": 105.833533004131,
        "from_longitude": 105.83372478207438,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6726,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83333452066825,
          21.003789263933026,
          105.833533004131,
          21.00396329143308
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.833533004131,
            21.003789263933026
          ],
          [
            105.83333452066825,
            21.00396329143308
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.003789263933026,
        "to_latitude": 21.00396329143308,
        "to_longitude": 105.83333452066825,
        "from_longitude": 105.833533004131,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6727,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83319638690666,
          21.00396329143308,
          105.83333452066825,
          21.004072215016105
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83333452066825,
            21.00396329143308
          ],
          [
            105.83319638690666,
            21.004072215016105
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "diemDau": "32",
        "diemCuoi": "34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00396329143308,
        "to_latitude": 21.004072215016105,
        "to_longitude": 105.83319638690666,
        "from_longitude": 105.83333452066825,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6728,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83319638690666,
          21.00394576349427,
          105.83323125560732,
          21.004072215016105
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83319638690666,
            21.004072215016105
          ],
          [
            105.83323125560732,
            21.00394576349427
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "diemDau": "34",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.004072215016105,
        "to_latitude": 21.00394576349427,
        "to_longitude": 105.83323125560732,
        "from_longitude": 105.83319638690666,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6729,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83323125560732,
          21.00394576349427,
          105.83339084705777,
          21.004154846647324
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83323125560732,
            21.00394576349427
          ],
          [
            105.83339084705777,
            21.004154846647324
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "diemDau": "",
        "diemCuoi": "39",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00394576349427,
        "to_latitude": 21.004154846647324,
        "to_longitude": 105.83339084705777,
        "from_longitude": 105.83323125560732,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6730,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83309848627017,
          21.00377549196042,
          105.83323125560732,
          21.00394576349427
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83323125560732,
            21.00394576349427
          ],
          [
            105.83309848627017,
            21.00377549196042
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "diemDau": "",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00394576349427,
        "to_latitude": 21.00377549196042,
        "to_longitude": 105.83309848627017,
        "from_longitude": 105.83323125560732,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6731,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83314944824421,
          21.004154846647324,
          105.83339084705777,
          21.004363929509147
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83339084705777,
            21.004154846647324
          ],
          [
            105.83314944824421,
            21.004363929509147
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "diemDau": "39",
        "diemCuoi": "40",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.004154846647324,
        "to_latitude": 21.004363929509147,
        "to_longitude": 105.83314944824421,
        "from_longitude": 105.83339084705777,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6732,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83314944824421,
          21.004363929509147,
          105.83327819427872,
          21.004620587712374
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83314944824421,
            21.004363929509147
          ],
          [
            105.83327819427872,
            21.004620587712374
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "diemDau": "40",
        "diemCuoi": "41",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.004363929509147,
        "to_latitude": 21.004620587712374,
        "to_longitude": 105.83327819427872,
        "from_longitude": 105.83314944824421,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6733,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83301399669037,
          21.004104766876694,
          105.83306764087216,
          21.00431510180161
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83301399669037,
            21.00431510180161
          ],
          [
            105.83306764087216,
            21.004104766876694
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "diemDau": "35",
        "diemCuoi": "38",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00431510180161,
        "to_latitude": 21.004104766876694,
        "to_longitude": 105.83306764087216,
        "from_longitude": 105.83301399669037,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6734,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83306764087216,
          21.004025891204783,
          105.83309178073286,
          21.004104766876694
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83306764087216,
            21.004104766876694
          ],
          [
            105.83309178073286,
            21.004025891204783
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "diemDau": "",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.004104766876694,
        "to_latitude": 21.004025891204783,
        "to_longitude": 105.83309178073286,
        "from_longitude": 105.83306764087216,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6735,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83309178073286,
          21.004025891204783,
          105.83319638690666,
          21.004072215016105
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83309178073286,
            21.004025891204783
          ],
          [
            105.83319638690666,
            21.004072215016105
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "diemDau": "34",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.004025891204783,
        "to_latitude": 21.004072215016105,
        "to_longitude": 105.83319638690666,
        "from_longitude": 105.83309178073286,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6736,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83301131448263,
          21.004009615265534,
          105.83309178073286,
          21.004025891204783
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83309178073286,
            21.004025891204783
          ],
          [
            105.83301131448263,
            21.004009615265534
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "diemDau": "",
        "diemCuoi": "37",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.004025891204783,
        "to_latitude": 21.004009615265534,
        "to_longitude": 105.83301131448263,
        "from_longitude": 105.83309178073286,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6737,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83280076107606,
          21.003957031454224,
          105.83301131448263,
          21.004009615265534
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83301131448263,
            21.004009615265534
          ],
          [
            105.83280076107606,
            21.003957031454224
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "diemDau": "37",
        "diemCuoi": "36",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.004009615265534,
        "to_latitude": 21.003957031454224,
        "to_longitude": 105.83280076107606,
        "from_longitude": 105.83301131448263,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6738,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83264921626679,
          21.003957031454224,
          105.83280076107606,
          21.00397455939338
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83280076107606,
            21.003957031454224
          ],
          [
            105.83264921626679,
            21.00397455939338
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "diemDau": "36",
        "diemCuoi": "42",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.003957031454224,
        "to_latitude": 21.00397455939338,
        "to_longitude": 105.83264921626679,
        "from_longitude": 105.83280076107606,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6739,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83264921626679,
          21.00397455939338,
          105.8327283414311,
          21.004197414439325
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83264921626679,
            21.00397455939338
          ],
          [
            105.8327283414311,
            21.004197414439325
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "diemDau": "42",
        "diemCuoi": "43",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00397455939338,
        "to_latitude": 21.004197414439325,
        "to_longitude": 105.8327283414311,
        "from_longitude": 105.83264921626679,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6740,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8327283414311,
          21.004197414439325,
          105.8327846678206,
          21.00434640161654
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8327283414311,
            21.004197414439325
          ],
          [
            105.8327846678206,
            21.00434640161654
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "diemDau": "43",
        "diemCuoi": "44",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.004197414439325,
        "to_latitude": 21.00434640161654,
        "to_longitude": 105.8327846678206,
        "from_longitude": 105.8327283414311,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6741,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8327846678206,
          21.00434640161654,
          105.83283160647407,
          21.004482868731646
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8327846678206,
            21.00434640161654
          ],
          [
            105.83283160647407,
            21.004482868731646
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "diemDau": "44",
        "diemCuoi": "45",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00434640161654,
        "to_latitude": 21.004482868731646,
        "to_longitude": 105.83283160647407,
        "from_longitude": 105.8327846678206,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6742,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83281014878521,
          21.004482868731646,
          105.83283160647407,
          21.00465188746404
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83283160647407,
            21.004482868731646
          ],
          [
            105.83281014878521,
            21.00465188746404
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "diemDau": "45",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.004482868731646,
        "to_latitude": 21.00465188746404,
        "to_longitude": 105.83281014878521,
        "from_longitude": 105.83283160647407,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6743,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83281014878521,
          21.00465188746404,
          105.83290804943066,
          21.00482340997905
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83281014878521,
            21.00465188746404
          ],
          [
            105.83290804943066,
            21.00482340997905
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "diemDau": "",
        "diemCuoi": "46",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00465188746404,
        "to_latitude": 21.00482340997905,
        "to_longitude": 105.83290804943066,
        "from_longitude": 105.83281014878521,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6744,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83290804943066,
          21.00482340997905,
          105.83296437582021,
          21.005052523690523
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83290804943066,
            21.00482340997905
          ],
          [
            105.83296437582021,
            21.005052523690523
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "diemDau": "46",
        "diemCuoi": "47",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00482340997905,
        "to_latitude": 21.005052523690523,
        "to_longitude": 105.83296437582021,
        "from_longitude": 105.83290804943066,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6745,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83294962365963,
          21.005052523690523,
          105.83296437582021,
          21.00525784934194
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83296437582021,
            21.005052523690523
          ],
          [
            105.83294962365963,
            21.00525784934194
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "diemDau": "47",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.005052523690523,
        "to_latitude": 21.00525784934194,
        "to_longitude": 105.83294962365963,
        "from_longitude": 105.83296437582021,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6746,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83280344328381,
          21.00525784934194,
          105.83294962365963,
          21.005275377126647
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83294962365963,
            21.00525784934194
          ],
          [
            105.83280344328381,
            21.005275377126647
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "diemDau": "",
        "diemCuoi": "48",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00525784934194,
        "to_latitude": 21.005275377126647,
        "to_longitude": 105.83280344328381,
        "from_longitude": 105.83294962365963,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6747,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83276723346134,
          21.005275377126647,
          105.83280344328381,
          21.005468182639888
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83280344328381,
            21.005275377126647
          ],
          [
            105.83276723346134,
            21.005468182639888
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "diemDau": "48",
        "diemCuoi": "49",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.005275377126647,
        "to_latitude": 21.005468182639888,
        "to_longitude": 105.83276723346134,
        "from_longitude": 105.83280344328381,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6748,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83323125562526,
          21.005470686605623,
          105.83326176573608,
          21.00559212890968
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83325271330517,
            21.005470686605623
          ],
          [
            105.83326176573608,
            21.005563020327706
          ],
          [
            105.83323125562526,
            21.00559212890968
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 37+39 Đông Tác",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.005470686605623,
        "to_latitude": 21.00559212890968,
        "to_longitude": 105.83323125562526,
        "from_longitude": 105.83325271330517,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6749,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8330609353528,
          21.005587120981428,
          105.83323125562526,
          21.00559212890968
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83323125562526,
            21.00559212890968
          ],
          [
            105.8330609353528,
            21.005587120981428
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 37+39 Đông Tác",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00559212890968,
        "to_latitude": 21.005587120981428,
        "to_longitude": 105.8330609353528,
        "from_longitude": 105.83323125562526,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6750,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83323125562526,
          21.005558325391835,
          105.83344985566403,
          21.00559212890968
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83323125562526,
            21.00559212890968
          ],
          [
            105.83344985566403,
            21.005558325391835
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 37+39 Đông Tác",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00559212890968,
        "to_latitude": 21.005558325391835,
        "to_longitude": 105.83344985566403,
        "from_longitude": 105.83323125562526,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6751,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83323125562526,
          21.00559212890968,
          105.83323662004973,
          21.005711067152408
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83323125562526,
            21.00559212890968
          ],
          [
            105.83323662004973,
            21.005711067152408
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 37+39 Đông Tác",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00559212890968,
        "to_latitude": 21.005711067152408,
        "to_longitude": 105.83323662004973,
        "from_longitude": 105.83323125562526,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6752,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83323662004973,
          21.005711067152408,
          105.83360106517716,
          21.005864747743885
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83323662004973,
            21.005711067152408
          ],
          [
            105.83324433138577,
            21.005864747743885
          ],
          [
            105.83360106517716,
            21.005820928447207
          ],
          [
            105.83359469495397,
            21.00574111469352
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 78,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 37+39 Đông Tác",
        "diemDau": "12-3",
        "diemCuoi": "12-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.005711067152408,
        "to_latitude": 21.00574111469352,
        "to_longitude": 105.83359469495397,
        "from_longitude": 105.83323662004973,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6753,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83359469495397,
          21.005439387026517,
          105.83360810599268,
          21.00574111469352
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83359469495397,
            21.00574111469352
          ],
          [
            105.83360810599268,
            21.005439387026517
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 51,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 19 Đông Tác",
        "diemDau": "12-2",
        "diemCuoi": "12-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00574111469352,
        "to_latitude": 21.005439387026517,
        "to_longitude": 105.83360810599268,
        "from_longitude": 105.83359469495397,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6754,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83360810599268,
          21.005156438535785,
          105.8337006422047,
          21.005439387026517
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83360810599268,
            21.005439387026517
          ],
          [
            105.8337006422047,
            21.005156438535785
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 19 Đông Tác",
        "diemDau": "12-1",
        "diemCuoi": "12-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.005439387026517,
        "to_latitude": 21.005156438535785,
        "to_longitude": 105.8337006422047,
        "from_longitude": 105.83360810599268,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6755,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8337006422047,
          21.004753298608914,
          105.83381463608764,
          21.005156438535785
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8337006422047,
            21.005156438535785
          ],
          [
            105.83372042348456,
            21.00500839116082
          ],
          [
            105.8337445633632,
            21.004905728199645
          ],
          [
            105.83381463608764,
            21.004753298608914
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.005156438535785,
        "to_latitude": 21.004753298608914,
        "to_longitude": 105.83381463608764,
        "from_longitude": 105.8337006422047,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6756,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83381463608764,
          21.004710417979517,
          105.83391086034203,
          21.004753298608914
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83381463608764,
            21.004753298608914
          ],
          [
            105.83391086034203,
            21.004710417979517
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.004753298608914,
        "to_latitude": 21.004710417979517,
        "to_longitude": 105.83391086034203,
        "from_longitude": 105.83381463608764,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6757,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83464109170073,
          21.004744534687,
          105.83468233068741,
          21.00489383431519
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83468233068741,
            21.004744534687
          ],
          [
            105.83464109170073,
            21.00489383431519
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "Tủ điện",
        "diemCuoi": "5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.004744534687,
        "to_latitude": 21.00489383431519,
        "to_longitude": 105.83464109170073,
        "from_longitude": 105.83468233068741,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6758,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83506689240883,
          21.005081006383392,
          105.83525900563043,
          21.005131398821486
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83506689240883,
            21.005081006383392
          ],
          [
            105.83525900563043,
            21.005131398821486
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "",
        "diemCuoi": "1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.005081006383392,
        "to_latitude": 21.005131398821486,
        "to_longitude": 105.83525900563043,
        "from_longitude": 105.83506689240883,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6759,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83399568518207,
          21.00481089009491,
          105.83418655386828,
          21.004858573537977
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83418655386828,
            21.004858573537977
          ],
          [
            105.83399568518207,
            21.00481089009491
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "",
        "diemCuoi": "4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.004858573537977,
        "to_latitude": 21.00481089009491,
        "to_longitude": 105.83399568518207,
        "from_longitude": 105.83418655386828,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6760,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83416600544555,
          21.004481616740915,
          105.834342655396,
          21.004530259835736
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83416600544555,
            21.004481616740915
          ],
          [
            105.834342655396,
            21.004530259835736
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.004481616740915,
        "to_latitude": 21.004530259835736,
        "to_longitude": 105.834342655396,
        "from_longitude": 105.83416600544555,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6761,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.834342655396,
          21.00453025983572,
          105.83453883250138,
          21.00458427999387
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.834342655396,
            21.00453025983572
          ],
          [
            105.83453883250138,
            21.00458427999387
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "9",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00453025983572,
        "to_latitude": 21.00458427999387,
        "to_longitude": 105.83453883250138,
        "from_longitude": 105.834342655396,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6762,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83498005589051,
          21.00467317128985,
          105.83517304737501,
          21.00471630996427
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83498005589051,
            21.00467317128985
          ],
          [
            105.83517304737501,
            21.00471630996427
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "8",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00467317128985,
        "to_latitude": 21.00471630996427,
        "to_longitude": 105.83517304737501,
        "from_longitude": 105.83498005589051,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6763,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83517304737501,
          21.00471630996427,
          105.83536092956955,
          21.00475830656597
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83517304737501,
            21.00471630996427
          ],
          [
            105.83536092956955,
            21.00475830656597
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "",
        "diemCuoi": "7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00471630996427,
        "to_latitude": 21.00475830656597,
        "to_longitude": 105.83536092956955,
        "from_longitude": 105.83517304737501,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6764,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83506588658318,
          21.0043075898453,
          105.83526505840128,
          21.004357215059965
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83506588658318,
            21.0043075898453
          ],
          [
            105.83526505840128,
            21.004357215059965
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "17",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.0043075898453,
        "to_latitude": 21.004357215059965,
        "to_longitude": 105.83526505840128,
        "from_longitude": 105.83506588658318,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6765,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83526505840128,
          21.004357215059965,
          105.83546285351764,
          21.004406497242304
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83526505840128,
            21.004357215059965
          ],
          [
            105.83546285351764,
            21.004406497242304
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.004357215059965,
        "to_latitude": 21.004406497242304,
        "to_longitude": 105.83546285351764,
        "from_longitude": 105.83526505840128,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6766,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83522547802468,
          21.00393825151772,
          105.83541484187721,
          21.003984396739458
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83522547802468,
            21.00393825151772
          ],
          [
            105.83541484187721,
            21.003984396739458
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "21",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.00393825151772,
        "to_latitude": 21.003984396739458,
        "to_longitude": 105.83541484187721,
        "from_longitude": 105.83522547802468,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6767,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83541484187721,
          21.003984396739458,
          105.83559025844828,
          21.00402714319848
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83541484187721,
            21.003984396739458
          ],
          [
            105.83559025844828,
            21.00402714319848
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.003984396739458,
        "to_latitude": 21.00402714319848,
        "to_longitude": 105.83559025844828,
        "from_longitude": 105.83541484187721,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6768,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83440338095652,
          21.003696616117008,
          105.83463371566089,
          21.003752643013495
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83440338095652,
            21.003696616117008
          ],
          [
            105.83463371566089,
            21.003752643013495
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.003696616117008,
        "to_latitude": 21.003752643013495,
        "to_longitude": 105.83463371566089,
        "from_longitude": 105.83440338095652,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6769,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83463371566089,
          21.003752643013495,
          105.83485533317662,
          21.00380804388911
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83463371566089,
            21.003752643013495
          ],
          [
            105.83485533317662,
            21.00380804388911
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC7",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "22",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLC7",
        "from_latitude": 21.003752643013495,
        "to_latitude": 21.00380804388911,
        "to_longitude": 105.83485533317662,
        "from_longitude": 105.83463371566089,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6770,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80031776602992,
          21.025754780034006,
          105.80075865010271,
          21.025805999892633
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80031776602992,
            21.025805999892633
          ],
          [
            105.80075865010271,
            21.025754780034006
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.025805999892633,
        "to_latitude": 21.025754780034006,
        "to_longitude": 105.80075865010271,
        "from_longitude": 105.80031776602992,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6771,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80075865010271,
          21.025697196636646,
          105.80102016548331,
          21.025754780034006
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80075865010271,
            21.025754780034006
          ],
          [
            105.80102016548331,
            21.025697196636646
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.025754780034006,
        "to_latitude": 21.025697196636646,
        "to_longitude": 105.80102016548331,
        "from_longitude": 105.80075865010271,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6772,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80102016548331,
          21.025697196636646,
          105.80111672500693,
          21.0261178052914
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80102016548331,
            21.025697196636646
          ],
          [
            105.80107246856123,
            21.025857428644507
          ],
          [
            105.80111672500693,
            21.0261178052914
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.025697196636646,
        "to_latitude": 21.0261178052914,
        "to_longitude": 105.80111672500693,
        "from_longitude": 105.80102016548331,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6773,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80111672500693,
          21.0261178052914,
          105.80120792011508,
          21.02636691521481
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80111672500693,
            21.0261178052914
          ],
          [
            105.80120792011508,
            21.02636691521481
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.0261178052914,
        "to_latitude": 21.02636691521481,
        "to_longitude": 105.80120792011508,
        "from_longitude": 105.80111672500693,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6774,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80120792011508,
          21.02636691521481,
          105.80121462564342,
          21.02652839830925
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80120792011508,
            21.02636691521481
          ],
          [
            105.80121462564342,
            21.02652839830925
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02636691521481,
        "to_latitude": 21.02652839830925,
        "to_longitude": 105.80121462564342,
        "from_longitude": 105.80120792011508,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6775,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80121462564342,
          21.02652839830925,
          105.80124889573109,
          21.02677790605939
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80121462564342,
            21.02652839830925
          ],
          [
            105.80124889573109,
            21.02677790605939
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02652839830925,
        "to_latitude": 21.02677790605939,
        "to_longitude": 105.80124889573109,
        "from_longitude": 105.80121462564342,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6776,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80124889573109,
          21.02677790605939,
          105.80129240970388,
          21.026901436107277
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80124889573109,
            21.02677790605939
          ],
          [
            105.80129240970388,
            21.026901436107277
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02677790605939,
        "to_latitude": 21.026901436107277,
        "to_longitude": 105.80129240970388,
        "from_longitude": 105.80124889573109,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6777,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80129240970388,
          21.026901436107277,
          105.80133800725345,
          21.02702160915882
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80129240970388,
            21.026901436107277
          ],
          [
            105.80133800725345,
            21.02702160915882
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.026901436107277,
        "to_latitude": 21.02702160915882,
        "to_longitude": 105.80133800725345,
        "from_longitude": 105.80129240970388,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6778,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80133800725345,
          21.02702160915882,
          105.80144261340931,
          21.027435955083728
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80133800725345,
            21.02702160915882
          ],
          [
            105.80144261340931,
            21.027435955083728
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02702160915882,
        "to_latitude": 21.027435955083728,
        "to_longitude": 105.80144261340931,
        "from_longitude": 105.80133800725345,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6779,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80101109908514,
          21.02744471767393,
          105.80121194343567,
          21.027489969010585
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80121194343567,
            21.02744471767393
          ],
          [
            105.80101109908514,
            21.027489969010585
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02744471767393,
        "to_latitude": 21.027489969010585,
        "to_longitude": 105.80101109908514,
        "from_longitude": 105.80121194343567,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6780,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80062185744498,
          21.027489969010585,
          105.80101109908514,
          21.027606199600672
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80101109908514,
            21.027489969010585
          ],
          [
            105.80062185744498,
            21.027606199600672
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.027489969010585,
        "to_latitude": 21.027606199600672,
        "to_longitude": 105.80062185744498,
        "from_longitude": 105.80101109908514,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6781,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80045019606864,
          21.027606199600672,
          105.80062185744498,
          21.0276399981213
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80062185744498,
            21.027606199600672
          ],
          [
            105.80045019606864,
            21.0276399981213
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.027606199600672,
        "to_latitude": 21.0276399981213,
        "to_longitude": 105.80045019606864,
        "from_longitude": 105.80062185744498,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6782,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80020463771348,
          21.0276399981213,
          105.80045019606864,
          21.02769084585983
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80045019606864,
            21.0276399981213
          ],
          [
            105.80020463771348,
            21.02769084585983
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.0276399981213,
        "to_latitude": 21.02769084585983,
        "to_longitude": 105.80020463771348,
        "from_longitude": 105.80045019606864,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6783,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80013369541135,
          21.02768130741452,
          105.80020463771348,
          21.02769084585983
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80020463771348,
            21.02769084585983
          ],
          [
            105.80013369541135,
            21.02768130741452
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02769084585983,
        "to_latitude": 21.02768130741452,
        "to_longitude": 105.80013369541135,
        "from_longitude": 105.80020463771348,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6784,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79993543985023,
          21.02768130741452,
          105.80013369541135,
          21.027760378742766
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80013369541135,
            21.02768130741452
          ],
          [
            105.79993543985023,
            21.027760378742766
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02768130741452,
        "to_latitude": 21.027760378742766,
        "to_longitude": 105.79993543985023,
        "from_longitude": 105.80013369541135,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6785,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79982282739753,
          21.027760378742766,
          105.79993543985023,
          21.027805861121312
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79993543985023,
            21.027760378742766
          ],
          [
            105.79982282739753,
            21.027805861121312
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.027760378742766,
        "to_latitude": 21.027805861121312,
        "to_longitude": 105.79982282739753,
        "from_longitude": 105.79993543985023,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6786,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82892631014221,
          21.00542561521373,
          105.82902555187358,
          21.005633444313947
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82892631014221,
            21.00542561521373
          ],
          [
            105.82902555187358,
            21.005633444313947
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "diemDau": "1",
        "diemCuoi": "2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.00542561521373,
        "to_latitude": 21.005633444313947,
        "to_longitude": 105.82902555187358,
        "from_longitude": 105.82892631014221,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6787,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82902555187358,
          21.005633444313947,
          105.82914356906812,
          21.005888848353056
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82902555187358,
            21.005633444313947
          ],
          [
            105.82914356906812,
            21.005888848353056
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "diemDau": "2",
        "diemCuoi": "3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.005633444313947,
        "to_latitude": 21.005888848353056,
        "to_longitude": 105.82914356906812,
        "from_longitude": 105.82902555187358,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6788,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82914356906812,
          21.005888848353056,
          105.82940106113712,
          21.006374615614085
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82914356906812,
            21.005888848353056
          ],
          [
            105.82940106113712,
            21.006374615614085
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 64,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "diemDau": "3",
        "diemCuoi": "4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.005888848353056,
        "to_latitude": 21.006374615614085,
        "to_longitude": 105.82940106113712,
        "from_longitude": 105.82914356906812,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6789,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82940106113712,
          21.006374615614085,
          105.8294815274053,
          21.006532364435746
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82940106113712,
            21.006374615614085
          ],
          [
            105.8294815274053,
            21.006532364435746
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "diemDau": "4",
        "diemCuoi": "5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.006374615614085,
        "to_latitude": 21.006532364435746,
        "to_longitude": 105.8294815274053,
        "from_longitude": 105.82940106113712,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6790,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8294815274053,
          21.006532364435746,
          105.82952578385103,
          21.006665073633695
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8294815274053,
            21.006532364435746
          ],
          [
            105.82952578385103,
            21.006665073633695
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "diemDau": "5",
        "diemCuoi": "6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.006532364435746,
        "to_latitude": 21.006665073633695,
        "to_longitude": 105.82952578385103,
        "from_longitude": 105.8294815274053,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6791,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82952578385103,
          21.006665073633695,
          105.82961832007203,
          21.006886672690083
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82952578385103,
            21.006665073633695
          ],
          [
            105.82961832007203,
            21.006886672690083
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "diemDau": "6",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.006665073633695,
        "to_latitude": 21.006886672690083,
        "to_longitude": 105.82961832007203,
        "from_longitude": 105.82952578385103,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6792,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82939837893836,
          21.006886672690083,
          105.82961832007203,
          21.006991838228533
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82961832007203,
            21.006886672690083
          ],
          [
            105.82939837893836,
            21.006991838228533
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chùa Bộc",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.006886672690083,
        "to_latitude": 21.006991838228533,
        "to_longitude": 105.82939837893836,
        "from_longitude": 105.82961832007203,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6793,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82914625128485,
          21.006991838228533,
          105.82939837893836,
          21.007117035202103
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82939837893836,
            21.006991838228533
          ],
          [
            105.82914625128485,
            21.007117035202103
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chùa Bộc",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.006991838228533,
        "to_latitude": 21.007117035202103,
        "to_longitude": 105.82914625128485,
        "from_longitude": 105.82939837893836,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6794,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82885120829849,
          21.007117035202103,
          105.82914625128485,
          21.007247239943293
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82914625128485,
            21.007117035202103
          ],
          [
            105.82885120829849,
            21.007247239943293
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chùa Bộc",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.007117035202103,
        "to_latitude": 21.007247239943293,
        "to_longitude": 105.82885120829849,
        "from_longitude": 105.82914625128485,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6795,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82961832007203,
          21.006757719606263,
          105.82990531643966,
          21.006886672690083
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82961832007203,
            21.006886672690083
          ],
          [
            105.82990531643966,
            21.006757719606263
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chùa Bộc",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.006886672690083,
        "to_latitude": 21.006757719606263,
        "to_longitude": 105.82990531643966,
        "from_longitude": 105.82961832007203,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6796,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82990531643966,
          21.006625949471427,
          105.83018761893813,
          21.006757719606263
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82990531643966,
            21.006757719606263
          ],
          [
            105.83018761893813,
            21.006625949471427
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chùa Bộc",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.006757719606263,
        "to_latitude": 21.006625949471427,
        "to_longitude": 105.83018761893813,
        "from_longitude": 105.82990531643966,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6797,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83018761893813,
          21.00649417922023,
          105.8304699214366,
          21.006625949471427
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83018761893813,
            21.006625949471427
          ],
          [
            105.8304699214366,
            21.00649417922023
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chùa Bộc",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.006625949471427,
        "to_latitude": 21.00649417922023,
        "to_longitude": 105.8304699214366,
        "from_longitude": 105.83018761893813,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6798,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8304699214366,
          21.006375867589757,
          105.83067511042542,
          21.00649417922023
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8304699214366,
            21.00649417922023
          ],
          [
            105.83067511042542,
            21.006375867589757
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chùa Bộc",
        "diemDau": "31",
        "diemCuoi": "31-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.00649417922023,
        "to_latitude": 21.006375867589757,
        "to_longitude": 105.83067511042542,
        "from_longitude": 105.8304699214366,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6799,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83067511042542,
          21.00625567790025,
          105.83089773377581,
          21.006375867589757
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83067511042542,
            21.006375867589757
          ],
          [
            105.83089773377581,
            21.00625567790025
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Chùa Bộc",
        "diemDau": "31-1",
        "diemCuoi": "31-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.006375867589757,
        "to_latitude": 21.00625567790025,
        "to_longitude": 105.83089773377581,
        "from_longitude": 105.83067511042542,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6800,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83543066700679,
          21.005430623148253,
          105.83553527315362,
          21.0057836820372
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83543066700679,
            21.0057836820372
          ],
          [
            105.83553527315362,
            21.005430623148253
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương Đình Của",
        "diemDau": "01",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.0057836820372,
        "to_latitude": 21.005430623148253,
        "to_longitude": 105.83553527315362,
        "from_longitude": 105.83543066700679,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6801,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83553527315362,
          21.005125138895792,
          105.83566401918812,
          21.005430623148253
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83553527315362,
            21.005430623148253
          ],
          [
            105.83566401918812,
            21.005125138895792
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương Đình Của",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.005430623148253,
        "to_latitude": 21.005125138895792,
        "to_longitude": 105.83566401918812,
        "from_longitude": 105.83553527315362,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6802,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83566401918812,
          21.004807134131806,
          105.83573107440863,
          21.005125138895792
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83566401918812,
            21.005125138895792
          ],
          [
            105.83573107440863,
            21.004807134131806
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương Đình Của",
        "diemDau": "KLC3 04",
        "diemCuoi": "KLC3 05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.005125138895792,
        "to_latitude": 21.004807134131806,
        "to_longitude": 105.83573107440863,
        "from_longitude": 105.83566401918812,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6803,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83573107440863,
          21.004481616743437,
          105.83580081184584,
          21.004807134131806
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83573107440863,
            21.004807134131806
          ],
          [
            105.83580081184584,
            21.004481616743437
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương Đình Của",
        "diemDau": "KLC3 05",
        "diemCuoi": "KLC3 06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.004807134131806,
        "to_latitude": 21.004481616743437,
        "to_longitude": 105.83580081184584,
        "from_longitude": 105.83573107440863,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6804,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83580081184584,
          21.004063451056673,
          105.83591614683264,
          21.004481616743437
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83580081184584,
            21.004481616743437
          ],
          [
            105.83591614683264,
            21.004063451056673
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương Đình Của",
        "diemDau": "KLC3 05",
        "diemCuoi": "KLC3 06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.004481616743437,
        "to_latitude": 21.004063451056673,
        "to_longitude": 105.83591614683264,
        "from_longitude": 105.83580081184584,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6805,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83591614683264,
          21.00356265228396,
          105.83605293949037,
          21.004063451056673
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83591614683264,
            21.004063451056673
          ],
          [
            105.83605293949037,
            21.00356265228396
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 56,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương Đình Của",
        "diemDau": "KLC3 07",
        "diemCuoi": "KLC3 08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.004063451056673,
        "to_latitude": 21.00356265228396,
        "to_longitude": 105.83605293949037,
        "from_longitude": 105.83591614683264,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6806,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83605293949037,
          21.003396136318393,
          105.83618168552489,
          21.00356265228396
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83605293949037,
            21.00356265228396
          ],
          [
            105.83609585482324,
            21.003396136318393
          ],
          [
            105.83618168552489,
            21.003517580312423
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương Đình Của",
        "diemDau": "KLC3 08",
        "diemCuoi": "KLC3 09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.00356265228396,
        "to_latitude": 21.003517580312423,
        "to_longitude": 105.83618168552489,
        "from_longitude": 105.83605293949037,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6807,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83618168552489,
          21.003517580312423,
          105.83651025611701,
          21.00361523623254
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83618168552489,
            21.003517580312423
          ],
          [
            105.83651025611701,
            21.00361523623254
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phương Mai",
        "diemDau": "KLC3 09",
        "diemCuoi": "KLC3 10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.003517580312423,
        "to_latitude": 21.00361523623254,
        "to_longitude": 105.83651025611701,
        "from_longitude": 105.83618168552489,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6808,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83651025611701,
          21.00361523623254,
          105.83681468684843,
          21.003702876110133
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83651025611701,
            21.00361523623254
          ],
          [
            105.83681468684843,
            21.003702876110133
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phương Mai",
        "diemDau": "KLC3 10",
        "diemCuoi": "KLC3 11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.00361523623254,
        "to_latitude": 21.003702876110133,
        "to_longitude": 105.83681468684843,
        "from_longitude": 105.83651025611701,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6809,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83681468684843,
          21.003702876110133,
          105.837179467281,
          21.003757964005437
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83681468684843,
            21.003702876110133
          ],
          [
            105.837179467281,
            21.003757964005437
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phương Mai",
        "diemDau": "KLC3 1",
        "diemCuoi": "KLC3 12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.003702876110133,
        "to_latitude": 21.003757964005437,
        "to_longitude": 105.837179467281,
        "from_longitude": 105.83681468684843,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6810,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82954992373868,
          21.006524852591916,
          105.82967464644359,
          21.006763979468012
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82967464644359,
            21.006763979468012
          ],
          [
            105.82954992373868,
            21.006524852591916
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "diemDau": "7",
        "diemCuoi": "8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.006763979468012,
        "to_latitude": 21.006524852591916,
        "to_longitude": 105.82954992373868,
        "from_longitude": 105.82967464644359,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6811,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82941715439254,
          21.00624691406492,
          105.82954992373868,
          21.006524852591916
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82954992373868,
            21.006524852591916
          ],
          [
            105.82941715439254,
            21.00624691406492
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "diemDau": "8",
        "diemCuoi": "9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.006524852591916,
        "to_latitude": 21.00624691406492,
        "to_longitude": 105.82941715439254,
        "from_longitude": 105.82954992373868,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6812,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82923476417628,
          21.00585128896312,
          105.82941715439254,
          21.00624691406492
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82941715439254,
            21.00624691406492
          ],
          [
            105.82923476417628,
            21.00585128896312
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 52,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "diemDau": "9",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.00624691406492,
        "to_latitude": 21.00585128896312,
        "to_longitude": 105.82923476417628,
        "from_longitude": 105.82941715439254,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6813,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82910467704689,
          21.005638452240635,
          105.82923476417628,
          21.00585128896312
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82923476417628,
            21.00585128896312
          ],
          [
            105.82910467704689,
            21.005638452240635
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.00585128896312,
        "to_latitude": 21.005638452240635,
        "to_longitude": 105.82910467704689,
        "from_longitude": 105.82923476417628,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6814,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82897727211625,
          21.005425615214595,
          105.82910467704689,
          21.005638452240635
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82910467704689,
            21.005638452240635
          ],
          [
            105.82897727211625,
            21.005425615214595
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.005638452240635,
        "to_latitude": 21.005425615214595,
        "to_longitude": 105.82897727211625,
        "from_longitude": 105.82910467704689,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6815,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82887823669473,
          21.005223082684786,
          105.82897727211625,
          21.005425615214595
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82897727211625,
            21.005425615214595
          ],
          [
            105.82887823669473,
            21.005223082684786
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.005425615214595,
        "to_latitude": 21.005223082684786,
        "to_longitude": 105.82887823669473,
        "from_longitude": 105.82897727211625,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6816,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82873319109495,
          21.004941096851006,
          105.82887823669473,
          21.005223082684786
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82887823669473,
            21.005223082684786
          ],
          [
            105.82873319109495,
            21.004941096851006
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.005223082684786,
        "to_latitude": 21.004941096851006,
        "to_longitude": 105.82873319109495,
        "from_longitude": 105.82887823669473,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6817,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82860846837207,
          21.00471073097966,
          105.82873319109495,
          21.004941096851006
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82873319109495,
            21.004941096851006
          ],
          [
            105.82860846837207,
            21.00471073097966
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.004941096851006,
        "to_latitude": 21.00471073097966,
        "to_longitude": 105.82860846837207,
        "from_longitude": 105.82873319109495,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6818,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82873319109495,
          21.00481840202921,
          105.82894642670927,
          21.004941096851006
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82873319109495,
            21.004941096851006
          ],
          [
            105.82894642670927,
            21.00481840202921
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43/59 Chùa Bộc",
        "diemDau": "14",
        "diemCuoi": "14-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.004941096851006,
        "to_latitude": 21.00481840202921,
        "to_longitude": 105.82894642670927,
        "from_longitude": 105.82873319109495,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6819,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82894642670927,
          21.00481840202921,
          105.8291046770379,
          21.00481965401711
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82894642670927,
            21.00481840202921
          ],
          [
            105.8291046770379,
            21.00481965401711
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43/59 Chùa Bộc",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.00481840202921,
        "to_latitude": 21.00481965401711,
        "to_longitude": 105.8291046770379,
        "from_longitude": 105.82894642670927,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6820,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82897727211625,
          21.0053530001589,
          105.82921732981697,
          21.005425615214595
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82897727211625,
            21.005425615214595
          ],
          [
            105.82921732981697,
            21.0053530001589
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43/43 Chùa Bộc",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.005425615214595,
        "to_latitude": 21.0053530001589,
        "to_longitude": 105.82921732981697,
        "from_longitude": 105.82897727211625,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6821,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82921732981697,
          21.00526786522203,
          105.82942788322353,
          21.0053530001589
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82921732981697,
            21.0053530001589
          ],
          [
            105.82942788322353,
            21.00526786522203
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43/43 Chùa Bộc",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.0053530001589,
        "to_latitude": 21.00526786522203,
        "to_longitude": 105.82942788322353,
        "from_longitude": 105.82921732981697,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6822,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82942788322353,
          21.005216533839533,
          105.8296692820371,
          21.00526786522203
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82942788322353,
            21.00526786522203
          ],
          [
            105.8296692820371,
            21.005216533839533
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43/43 Chùa Bộc",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.00526786522203,
        "to_latitude": 21.005216533839533,
        "to_longitude": 105.8296692820371,
        "from_longitude": 105.82942788322353,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6823,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8296692820371,
          21.00512013095104,
          105.82994152624866,
          21.005216533839533
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8296692820371,
            21.005216533839533
          ],
          [
            105.82994152624866,
            21.00512013095104
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43/43 Chùa Bộc",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.005216533839533,
        "to_latitude": 21.00512013095104,
        "to_longitude": 105.82994152624866,
        "from_longitude": 105.8296692820371,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6824,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82985837777272,
          21.004909797160817,
          105.82994152624866,
          21.00512013095104
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82994152624866,
            21.00512013095104
          ],
          [
            105.82985837777272,
            21.004909797160817
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43/43 Chùa Bộc",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.00512013095104,
        "to_latitude": 21.004909797160817,
        "to_longitude": 105.82985837777272,
        "from_longitude": 105.82994152624866,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6825,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82972829063435,
          21.004666911344096,
          105.82985837777272,
          21.004909797160817
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82985837777272,
            21.004909797160817
          ],
          [
            105.82972829063435,
            21.004666911344096
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43/43 Chùa Bộc",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.004909797160817,
        "to_latitude": 21.004666911344096,
        "to_longitude": 105.82972829063435,
        "from_longitude": 105.82985837777272,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6826,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82994152624866,
          21.00512013095104,
          105.83005954345221,
          21.00534048031836
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82994152624866,
            21.00512013095104
          ],
          [
            105.83005954345221,
            21.00534048031836
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43/43 Chùa Bộc",
        "diemDau": "22",
        "diemCuoi": "22-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.00512013095104,
        "to_latitude": 21.00534048031836,
        "to_longitude": 105.83005954345221,
        "from_longitude": 105.82994152624866,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6827,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82971890290726,
          21.00533547238166,
          105.83005954345221,
          21.00534048031836
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83005954345221,
            21.00534048031836
          ],
          [
            105.82971890290726,
            21.00533547238166
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43/43 Chùa Bộc",
        "diemDau": "22-1",
        "diemCuoi": "22-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.00534048031836,
        "to_latitude": 21.00533547238166,
        "to_longitude": 105.82971890290726,
        "from_longitude": 105.83005954345221,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6828,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.829598203496,
          21.00533547238166,
          105.82971890290726,
          21.005395567609103
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82971890290726,
            21.00533547238166
          ],
          [
            105.829598203496,
            21.005395567609103
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43/43 Chùa Bộc",
        "diemDau": "22-2",
        "diemCuoi": "22-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.00533547238166,
        "to_latitude": 21.005395567609103,
        "to_longitude": 105.829598203496,
        "from_longitude": 105.82971890290726,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6829,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82948823293366,
          21.005395567609103,
          105.829598203496,
          21.005440639013397
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.829598203496,
            21.005395567609103
          ],
          [
            105.82948823293366,
            21.005440639013397
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Viện Năng Lượng -Ngõ 45 Chùa Bộc",
        "diemDau": "22-3",
        "diemCuoi": "22-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.005395567609103,
        "to_latitude": 21.005440639013397,
        "to_longitude": 105.82948823293366,
        "from_longitude": 105.829598203496,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6830,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82948823293366,
          21.005440639013397,
          105.82953919489873,
          21.005517009973442
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82948823293366,
            21.005440639013397
          ],
          [
            105.82953919489873,
            21.005517009973442
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Viện Năng Lượng -Ngõ 45 Chùa Bộc",
        "diemDau": "22-4",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.005440639013397,
        "to_latitude": 21.005517009973442,
        "to_longitude": 105.82953919489873,
        "from_longitude": 105.82948823293366,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6831,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.829598203496,
          21.005395567609103,
          105.82965050657391,
          21.0054694346257
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.829598203496,
            21.005395567609103
          ],
          [
            105.82965050657391,
            21.0054694346257
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Viện Năng Lượng -Ngõ 45 Chùa Bộc",
        "diemDau": "22-3",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.005395567609103,
        "to_latitude": 21.0054694346257,
        "to_longitude": 105.82965050657391,
        "from_longitude": 105.829598203496,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6832,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82965050657391,
          21.0054694346257,
          105.8296961041235,
          21.005567089270137
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82965050657391,
            21.0054694346257
          ],
          [
            105.8296961041235,
            21.005567089270137
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Viện Năng Lượng -Ngõ 45 Chùa Bộc",
        "diemDau": "32",
        "diemCuoi": "34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.0054694346257,
        "to_latitude": 21.005567089270137,
        "to_longitude": 105.8296961041235,
        "from_longitude": 105.82965050657391,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6833,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82959149797662,
          21.005567089270137,
          105.8296961041235,
          21.005614664586762
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8296961041235,
            21.005567089270137
          ],
          [
            105.82959149797662,
            21.005614664586762
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Viện Năng Lượng -Ngõ 45 Chùa Bộc",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.005567089270137,
        "to_latitude": 21.005614664586762,
        "to_longitude": 105.82959149797662,
        "from_longitude": 105.8296961041235,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6834,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.829371556834,
          21.005983998765945,
          105.82950566728397,
          21.00602155812251
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82950566728397,
            21.00602155812251
          ],
          [
            105.829371556834,
            21.005983998765945
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Viện Năng Lượng -Ngõ 4 TTT",
        "diemDau": "37",
        "diemCuoi": "36",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.00602155812251,
        "to_latitude": 21.005983998765945,
        "to_longitude": 105.829371556834,
        "from_longitude": 105.82950566728397,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6835,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82922756093691,
          21.005983998765945,
          105.829371556834,
          21.006047301951106
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82922756093691,
            21.006047301951106
          ],
          [
            105.829371556834,
            21.005983998765945
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Viện Năng Lượng -Ngõ 4 TTT",
        "diemDau": "cột đỡ",
        "diemCuoi": "37",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.006047301951106,
        "to_latitude": 21.005983998765945,
        "to_longitude": 105.829371556834,
        "from_longitude": 105.82922756093691,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6836,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82967464644359,
          21.006553647994163,
          105.82976852376846,
          21.006763979468012
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82967464644359,
            21.006763979468012
          ],
          [
            105.82976852376846,
            21.006553647994163
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Viện Năng Lượng -Ngõ 4 TTT",
        "diemDau": "7",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.006763979468012,
        "to_latitude": 21.006553647994163,
        "to_longitude": 105.82976852376846,
        "from_longitude": 105.82967464644359,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6837,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82976852376846,
          21.006514836797866,
          105.82985703666887,
          21.006553647994163
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82976852376846,
            21.006553647994163
          ],
          [
            105.82985703666887,
            21.006514836797866
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Viện Năng Lượng -Ngõ 4 TTT",
        "diemDau": "7-1",
        "diemCuoi": "7-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.006553647994163,
        "to_latitude": 21.006514836797866,
        "to_longitude": 105.82985703666887,
        "from_longitude": 105.82976852376846,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6838,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83052993587526,
          21.00617711635982,
          105.83067511042542,
          21.006375867589757
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83067511042542,
            21.006375867589757
          ],
          [
            105.83052993587526,
            21.00617711635982
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Chùa Bộc",
        "diemDau": "31-1",
        "diemCuoi": "38",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.006375867589757,
        "to_latitude": 21.00617711635982,
        "to_longitude": 105.83052993587526,
        "from_longitude": 105.83067511042542,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6839,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8304119186807,
          21.00617711635982,
          105.83052993587526,
          21.006207163807083
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83052993587526,
            21.00617711635982
          ],
          [
            105.8304119186807,
            21.006207163807083
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1 Chùa Bộc",
        "diemDau": "38",
        "diemCuoi": "39",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.00617711635982,
        "to_latitude": 21.006207163807083,
        "to_longitude": 105.8304119186807,
        "from_longitude": 105.83052993587526,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6840,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8291046770379,
          21.00481965401711,
          105.82913451663721,
          21.004881940429797
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8291046770379,
            21.00481965401711
          ],
          [
            105.82913451663721,
            21.004881940429797
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43/59 Chùa Bộc",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.00481965401711,
        "to_latitude": 21.004881940429797,
        "to_longitude": 105.82913451663721,
        "from_longitude": 105.8291046770379,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6841,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82910467704689,
          21.00552326988687,
          105.82915429790809,
          21.005638452240635
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82910467704689,
            21.005638452240635
          ],
          [
            105.82915429790809,
            21.00552326988687
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KHTL",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Viện Năng Lượng -Ngõ 4 TTT",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KHTL",
        "from_latitude": 21.005638452240635,
        "to_latitude": 21.00552326988687,
        "to_longitude": 105.82915429790809,
        "from_longitude": 105.82910467704689,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6842,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83540149809866,
          21.001587362953774,
          105.83561875703354,
          21.00161991535554
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83561875703354,
            21.00161991535554
          ],
          [
            105.83540149809866,
            21.001587362953774
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 62,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 32 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00161991535554,
        "to_latitude": 21.001587362953774,
        "to_longitude": 105.83540149809866,
        "from_longitude": 105.83561875703354,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6843,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83522179009908,
          21.00155856659239,
          105.83540149809866,
          21.001587362953774
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83540149809866,
            21.001587362953774
          ],
          [
            105.83522179009908,
            21.00155856659239
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 32 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001587362953774,
        "to_latitude": 21.00155856659239,
        "to_longitude": 105.83522179009908,
        "from_longitude": 105.83540149809866,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6844,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83561875703354,
          21.00161991535554,
          105.83601840616676,
          21.001687524169466
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83561875703354,
            21.00161991535554
          ],
          [
            105.83601840616676,
            21.001687524169466
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 32 Lương Đình Của",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00161991535554,
        "to_latitude": 21.001687524169466,
        "to_longitude": 105.83601840616676,
        "from_longitude": 105.83561875703354,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6845,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83601840616676,
          21.001687524169466,
          105.83610256036056,
          21.001702170125494
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83601840616676,
            21.001687524169466
          ],
          [
            105.83610256036056,
            21.001702170125494
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001687524169466,
        "to_latitude": 21.001702170125494,
        "to_longitude": 105.83610256036056,
        "from_longitude": 105.83601840616676,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6846,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83594699223967,
          21.001409198412137,
          105.83610256036056,
          21.00170217012552
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83610256036056,
            21.00170217012552
          ],
          [
            105.83594699223967,
            21.001409198412137
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương ĐÌnh Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00170217012552,
        "to_latitude": 21.001409198412137,
        "to_longitude": 105.83594699223967,
        "from_longitude": 105.83610256036056,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6847,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83594699223967,
          21.0013315733319,
          105.83600063642145,
          21.00140919841215
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83594699223967,
            21.00140919841215
          ],
          [
            105.83600063642145,
            21.0013315733319
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 125,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00140919841215,
        "to_latitude": 21.0013315733319,
        "to_longitude": 105.83600063642145,
        "from_longitude": 105.83594699223967,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6848,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83600063642145,
          21.001209253762845,
          105.83603986384668,
          21.0013315733319
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83600063642145,
            21.0013315733319
          ],
          [
            105.83603986384668,
            21.001209253762845
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.0013315733319,
        "to_latitude": 21.001209253762845,
        "to_longitude": 105.83603986384668,
        "from_longitude": 105.83600063642145,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6849,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83603986384668,
          21.00101268668603,
          105.8360425460544,
          21.001209253762845
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83603986384668,
            21.001209253762845
          ],
          [
            105.8360425460544,
            21.00101268668603
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001209253762845,
        "to_latitude": 21.00101268668603,
        "to_longitude": 105.8360425460544,
        "from_longitude": 105.83603986384668,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6850,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8360425460544,
          21.00086244415967,
          105.83605327489435,
          21.00101268668603
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8360425460544,
            21.00101268668603
          ],
          [
            105.83605327489435,
            21.00086244415967
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00101268668603,
        "to_latitude": 21.00086244415967,
        "to_longitude": 105.83605327489435,
        "from_longitude": 105.8360425460544,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6851,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83603282293231,
          21.000709697435603,
          105.83605327489435,
          21.00086244415967
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83605327489435,
            21.00086244415967
          ],
          [
            105.83603282293231,
            21.0008182451728
          ],
          [
            105.83603986384668,
            21.000709697435603
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00086244415967,
        "to_latitude": 21.000709697435603,
        "to_longitude": 105.83603986384668,
        "from_longitude": 105.83605327489435,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6852,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83586686136645,
          21.00069717720552,
          105.83603986384668,
          21.000709697435603
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83603986384668,
            21.000709697435603
          ],
          [
            105.83586686136645,
            21.00069717720552
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.000709697435603,
        "to_latitude": 21.00069717720552,
        "to_longitude": 105.83586686136645,
        "from_longitude": 105.83603986384668,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6853,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83586686136645,
          21.00053691816629,
          105.8358695435742,
          21.00069717720552
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83586686136645,
            21.00069717720552
          ],
          [
            105.8358695435742,
            21.00053691816629
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 60,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 36 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00069717720552,
        "to_latitude": 21.00053691816629,
        "to_longitude": 105.8358695435742,
        "from_longitude": 105.83586686136645,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6854,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83601840616676,
          21.001614907294822,
          105.83628662707571,
          21.001687524169466
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83601840616676,
            21.001687524169466
          ],
          [
            105.83628662707571,
            21.001614907294822
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 125,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001687524169466,
        "to_latitude": 21.001614907294822,
        "to_longitude": 105.83628662707571,
        "from_longitude": 105.83601840616676,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6855,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83622627738355,
          21.001324439445522,
          105.83628662707571,
          21.001614907294822
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83628662707571,
            21.001614907294822
          ],
          [
            105.83622627738355,
            21.001324439445522
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 82,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 91/2 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001614907294822,
        "to_latitude": 21.001324439445522,
        "to_longitude": 105.83622627738355,
        "from_longitude": 105.83628662707571,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6856,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83622627738355,
          21.001048995273447,
          105.83628528598082,
          21.001324439445522
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83622627738355,
            21.001324439445522
          ],
          [
            105.83628528598082,
            21.001048995273447
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 91/2 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001324439445522,
        "to_latitude": 21.001048995273447,
        "to_longitude": 105.83628528598082,
        "from_longitude": 105.83622627738355,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6857,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83628528598082,
          21.001048995273447,
          105.83659374001488,
          21.001124116462062
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83628528598082,
            21.001048995273447
          ],
          [
            105.83659374001488,
            21.001124116462062
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 91/2 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001048995273447,
        "to_latitude": 21.001124116462062,
        "to_longitude": 105.83659374001488,
        "from_longitude": 105.83628528598082,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6858,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83627992156536,
          21.000918785124867,
          105.83628528598082,
          21.001048995273447
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83628528598082,
            21.001048995273447
          ],
          [
            105.83627992156536,
            21.000918785124867
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 91/2 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001048995273447,
        "to_latitude": 21.000918785124867,
        "to_longitude": 105.83627992156536,
        "from_longitude": 105.83628528598082,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6859,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83627992156536,
          21.000916281081953,
          105.83647035840487,
          21.000918785124867
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83627992156536,
            21.000918785124867
          ],
          [
            105.83647035840487,
            21.000916281081953
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 91/2 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.000918785124867,
        "to_latitude": 21.000916281081953,
        "to_longitude": 105.83647035840487,
        "from_longitude": 105.83627992156536,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6860,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83628662707571,
          21.001613655279208,
          105.83663397314898,
          21.001614907294822
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83628662707571,
            21.001614907294822
          ],
          [
            105.83663397314898,
            21.001613655279208
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001614907294822,
        "to_latitude": 21.001613655279208,
        "to_longitude": 105.83663397314898,
        "from_longitude": 105.83628662707571,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6861,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83663397314898,
          21.001613655279208,
          105.83694510939078,
          21.001613655279208
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83663397314898,
            21.001613655279208
          ],
          [
            105.83694510939078,
            21.001613655279208
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001613655279208,
        "to_latitude": 21.001613655279208,
        "to_longitude": 105.83694510939078,
        "from_longitude": 105.83663397314898,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6862,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83694510939078,
          21.001569834734234,
          105.83722003581909,
          21.001613655279208
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83694510939078,
            21.001613655279208
          ],
          [
            105.83722003581909,
            21.001569834734234
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001613655279208,
        "to_latitude": 21.001569834734234,
        "to_longitude": 105.83722003581909,
        "from_longitude": 105.83694510939078,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6863,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83719690164031,
          21.001514367811655,
          105.83722003581909,
          21.001569834734234
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83722003581909,
            21.001569834734234
          ],
          [
            105.83719690164031,
            21.001514367811655
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001569834734234,
        "to_latitude": 21.001514367811655,
        "to_longitude": 105.83719690164031,
        "from_longitude": 105.83722003581909,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6864,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83719690164031,
          21.001504729901768,
          105.8375526297318,
          21.001514367811655
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83719690164031,
            21.001514367811655
          ],
          [
            105.8375526297318,
            21.001504729901768
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 91 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001514367811655,
        "to_latitude": 21.001504729901768,
        "to_longitude": 105.8375526297318,
        "from_longitude": 105.83719690164031,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6865,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83719690164031,
          21.001372016114697,
          105.83722405913969,
          21.001514367811655
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83719690164031,
            21.001514367811655
          ],
          [
            105.83722405913969,
            21.001372016114697
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 91/30 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001514367811655,
        "to_latitude": 21.001372016114697,
        "to_longitude": 105.83722405913969,
        "from_longitude": 105.83719690164031,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6866,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83692633393659,
          21.0013820322539,
          105.83694510939078,
          21.001613655279208
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83694510939078,
            21.001613655279208
          ],
          [
            105.83692633393659,
            21.0013820322539
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 91/16 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001613655279208,
        "to_latitude": 21.0013820322539,
        "to_longitude": 105.83692633393659,
        "from_longitude": 105.83694510939078,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6867,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83609987815282,
          21.00170217012552,
          105.83610256036056,
          21.001877452071913
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83610256036056,
            21.00170217012552
          ],
          [
            105.83609987815282,
            21.001877452071913
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương ĐÌnh Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00170217012552,
        "to_latitude": 21.001877452071913,
        "to_longitude": 105.83609987815282,
        "from_longitude": 105.83610256036056,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6868,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83609987815282,
          21.001877452071913,
          105.83610021353883,
          21.001967975212093
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83609987815282,
            21.001877452071913
          ],
          [
            105.83610021353883,
            21.001967975212093
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương ĐÌnh Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001877452071913,
        "to_latitude": 21.001967975212093,
        "to_longitude": 105.83610021353883,
        "from_longitude": 105.83609987815282,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6869,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83609987815282,
          21.001860302105996,
          105.83643146638362,
          21.001877452071913
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83609987815282,
            21.001877452071913
          ],
          [
            105.83643146638362,
            21.001860302105996
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 235,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 89 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001877452071913,
        "to_latitude": 21.001860302105996,
        "to_longitude": 105.83643146638362,
        "from_longitude": 105.83609987815282,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6870,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83643146638362,
          21.0018565460661,
          105.83675199036149,
          21.001860302105996
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83643146638362,
            21.001860302105996
          ],
          [
            105.83675199036149,
            21.0018565460661
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 89 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001860302105996,
        "to_latitude": 21.0018565460661,
        "to_longitude": 105.83675199036149,
        "from_longitude": 105.83643146638362,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6871,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83675199036149,
          21.00184990778015,
          105.83699841817759,
          21.0018565460661
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83675199036149,
            21.0018565460661
          ],
          [
            105.83699841817759,
            21.00184990778015
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 89 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.0018565460661,
        "to_latitude": 21.00184990778015,
        "to_longitude": 105.83699841817759,
        "from_longitude": 105.83675199036149,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6872,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83699841817759,
          21.00184990778015,
          105.83700680022275,
          21.001949195024476
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83699841817759,
            21.00184990778015
          ],
          [
            105.83700680022275,
            21.001949195024476
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 89 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00184990778015,
        "to_latitude": 21.001949195024476,
        "to_longitude": 105.83700680022275,
        "from_longitude": 105.83699841817759,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6873,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8369930537531,
          21.001781425246126,
          105.83709263090643,
          21.00184990778015
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83699841817759,
            21.00184990778015
          ],
          [
            105.8369930537531,
            21.001797323207875
          ],
          [
            105.83709263090643,
            21.001781425246126
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 89 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00184990778015,
        "to_latitude": 21.001781425246126,
        "to_longitude": 105.83709263090643,
        "from_longitude": 105.83699841817759,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6874,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83700680022275,
          21.001949195024476,
          105.83708424886126,
          21.00200014931251
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83700680022275,
            21.001949195024476
          ],
          [
            105.83708424886126,
            21.00200014931251
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 89 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001949195024476,
        "to_latitude": 21.00200014931251,
        "to_longitude": 105.83708424886126,
        "from_longitude": 105.83700680022275,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6875,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83708424886126,
          21.00200014931251,
          105.83713018184177,
          21.002055439990034
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83708424886126,
            21.00200014931251
          ],
          [
            105.83713018184177,
            21.002055439990034
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 89 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00200014931251,
        "to_latitude": 21.002055439990034,
        "to_longitude": 105.83713018184177,
        "from_longitude": 105.83708424886126,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6876,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83708424886126,
          21.001996771494504,
          105.83728843217041,
          21.00200014931251
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83708424886126,
            21.00200014931251
          ],
          [
            105.83728843217041,
            21.001996771494504
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 89 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00200014931251,
        "to_latitude": 21.001996771494504,
        "to_longitude": 105.83728843217041,
        "from_longitude": 105.83708424886126,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6877,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83726161007503,
          21.001831505796442,
          105.83728843217041,
          21.001996771494504
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83728843217041,
            21.001996771494504
          ],
          [
            105.83726161007503,
            21.001831505796442
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 89 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001996771494504,
        "to_latitude": 21.001831505796442,
        "to_longitude": 105.83726161007503,
        "from_longitude": 105.83728843217041,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6878,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83713018184177,
          21.002055439990034,
          105.83718784933515,
          21.002171877030563
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83713018184177,
            21.002055439990034
          ],
          [
            105.83718784933515,
            21.002171877030563
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 89 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002055439990034,
        "to_latitude": 21.002171877030563,
        "to_longitude": 105.83718784933515,
        "from_longitude": 105.83713018184177,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6879,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83702825789368,
          21.002171877030563,
          105.83718784933515,
          21.002253257703764
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83718784933515,
            21.002171877030563
          ],
          [
            105.83702825789368,
            21.002253257703764
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 89 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002171877030563,
        "to_latitude": 21.002253257703764,
        "to_longitude": 105.83702825789368,
        "from_longitude": 105.83718784933515,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6880,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83689682966042,
          21.002253257703764,
          105.83702825789368,
          21.002335890342163
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83702825789368,
            21.002253257703764
          ],
          [
            105.83689682966042,
            21.002335890342163
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 89 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002253257703764,
        "to_latitude": 21.002335890342163,
        "to_longitude": 105.83689682966042,
        "from_longitude": 105.83702825789368,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6881,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83575152638866,
          21.001409198412137,
          105.83594699223967,
          21.00145965730792
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83594699223967,
            21.001409198412137
          ],
          [
            105.83575152638866,
            21.00145965730792
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001409198412137,
        "to_latitude": 21.00145965730792,
        "to_longitude": 105.83575152638866,
        "from_longitude": 105.83594699223967,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6882,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83543502572236,
          21.00140957663201,
          105.83575152638866,
          21.00145965730792
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83575152638866,
            21.00145965730792
          ],
          [
            105.83543502572236,
            21.00140957663201
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 120,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 120 Trường Chinh",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00145965730792,
        "to_latitude": 21.00140957663201,
        "to_longitude": 105.83543502572236,
        "from_longitude": 105.83575152638866,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6883,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83514802935922,
          21.001284374870828,
          105.83543502572236,
          21.00140957663201
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83543502572236,
            21.00140957663201
          ],
          [
            105.83514802935922,
            21.001284374870828
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 120 Trường Chinh",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00140957663201,
        "to_latitude": 21.001284374870828,
        "to_longitude": 105.83514802935922,
        "from_longitude": 105.83543502572236,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6884,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8348878550825,
          21.00116167704179,
          105.83514802935922,
          21.001284374870828
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83514802935922,
            21.001284374870828
          ],
          [
            105.8348878550825,
            21.00116167704179
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 120 Trường Chinh",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001284374870828,
        "to_latitude": 21.00116167704179,
        "to_longitude": 105.8348878550825,
        "from_longitude": 105.83514802935922,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6885,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83563753249672,
          21.001932918860412,
          105.83588429572576,
          21.001947943012496
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83588429572576,
            21.001947943012496
          ],
          [
            105.83563753249672,
            21.001932918860412
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 30 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001947943012496,
        "to_latitude": 21.001932918860412,
        "to_longitude": 105.83563753249672,
        "from_longitude": 105.83588429572576,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6886,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83545782448816,
          21.001915390681443,
          105.83563753249672,
          21.001932918860412
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83563753249672,
            21.001932918860412
          ],
          [
            105.83545782448816,
            21.001915390681443
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 30 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001932918860412,
        "to_latitude": 21.001915390681443,
        "to_longitude": 105.83545782448816,
        "from_longitude": 105.83563753249672,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6887,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83527275207314,
          21.001915390681443,
          105.83545782448816,
          21.001920398732885
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83545782448816,
            21.001915390681443
          ],
          [
            105.83527275207314,
            21.001920398732885
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 30 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001915390681443,
        "to_latitude": 21.001920398732885,
        "to_longitude": 105.83527275207314,
        "from_longitude": 105.83545782448816,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6888,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8350742686014,
          21.001920398732885,
          105.83527275207314,
          21.001920398732885
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83527275207314,
            21.001920398732885
          ],
          [
            105.8350742686014,
            21.001920398732885
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 30 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001920398732885,
        "to_latitude": 21.001920398732885,
        "to_longitude": 105.8350742686014,
        "from_longitude": 105.83527275207314,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6889,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83486505629871,
          21.001888720191367,
          105.83507661541415,
          21.001920398732885
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8350742686014,
            21.001920398732885
          ],
          [
            105.83507661541415,
            21.001888720191367
          ],
          [
            105.83486505629871,
            21.001895358474762
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 30 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001920398732885,
        "to_latitude": 21.001895358474762,
        "to_longitude": 105.83486505629871,
        "from_longitude": 105.8350742686014,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6890,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83486237409097,
          21.001722580577987,
          105.83486505629871,
          21.001895358474762
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83486505629871,
            21.001895358474762
          ],
          [
            105.83486237409097,
            21.001722580577987
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 94,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 30 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001895358474762,
        "to_latitude": 21.001722580577987,
        "to_longitude": 105.83486237409097,
        "from_longitude": 105.83486505629871,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6891,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83588429572576,
          21.001947943012496,
          105.83610021353883,
          21.001967975212093
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83588429572576,
            21.001947943012496
          ],
          [
            105.83610021353883,
            21.001967975212093
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 64,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 30 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001947943012496,
        "to_latitude": 21.001967975212093,
        "to_longitude": 105.83610021353883,
        "from_longitude": 105.83588429572576,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6892,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83582092842188,
          21.001947943012496,
          105.83588429572576,
          21.002078152264005
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83588429572576,
            21.001947943012496
          ],
          [
            105.83582092842188,
            21.001961336931103
          ],
          [
            105.83583869817618,
            21.002078152264005
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 30 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001947943012496,
        "to_latitude": 21.002078152264005,
        "to_longitude": 105.83583869817618,
        "from_longitude": 105.83588429572576,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6893,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83610021353883,
          21.001967975212093,
          105.83610390147341,
          21.00225806359046
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83610021353883,
            21.001967975212093
          ],
          [
            105.83610390147341,
            21.00225806359046
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 230,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương ĐÌnh Của",
        "diemDau": "9",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTBNN",
        "from_latitude": 21.001967975212093,
        "to_latitude": 21.00225806359046,
        "to_longitude": 105.83610390147341,
        "from_longitude": 105.83610021353883,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6894,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83610390147341,
          21.00225806359046,
          105.83610960127491,
          21.00251635564057
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83610390147341,
            21.00225806359046
          ],
          [
            105.83610960127491,
            21.00251635564057
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm vặn xoắn",
        "tietDien": "4x16",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 233,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương ĐÌnh Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00225806359046,
        "to_latitude": 21.00251635564057,
        "to_longitude": 105.83610960127491,
        "from_longitude": 105.83610390147341,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6895,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83603315830936,
          21.00225806359046,
          105.83610390147341,
          21.002263449848094
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83610390147341,
            21.00225806359046
          ],
          [
            105.83603315830936,
            21.002263449848094
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28D Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00225806359046,
        "to_latitude": 21.002263449848094,
        "to_longitude": 105.83603315830936,
        "from_longitude": 105.83610390147341,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6896,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.835861496933,
          21.002260945828553,
          105.83603315830936,
          21.002263449848094
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83603315830936,
            21.002263449848094
          ],
          [
            105.835861496933,
            21.002260945828553
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 65,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28D Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002263449848094,
        "to_latitude": 21.002260945828553,
        "to_longitude": 105.835861496933,
        "from_longitude": 105.83603315830936,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6897,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83562009812843,
          21.002260945828553,
          105.835861496933,
          21.00226595386842
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.835861496933,
            21.002260945828553
          ],
          [
            105.83562009812843,
            21.00226595386842
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28D Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002260945828553,
        "to_latitude": 21.00226595386842,
        "to_longitude": 105.83562009812843,
        "from_longitude": 105.835861496933,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6898,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83610691905817,
          21.00251635564057,
          105.83610960127491,
          21.002771765014625
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83610960127491,
            21.00251635564057
          ],
          [
            105.83610691905817,
            21.002771765014625
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương ĐÌnh Của",
        "diemDau": "8",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00251635564057,
        "to_latitude": 21.002771765014625,
        "to_longitude": 105.83610691905817,
        "from_longitude": 105.83610960127491,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6899,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83602611741296,
          21.002652446211652,
          105.83610691905817,
          21.002771765014625
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83610691905817,
            21.002771765014625
          ],
          [
            105.83602611741296,
            21.002652446211652
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28C Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002771765014625,
        "to_latitude": 21.002652446211652,
        "to_longitude": 105.83602611741296,
        "from_longitude": 105.83610691905817,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6900,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83577030182487,
          21.002652446211652,
          105.83602611741296,
          21.002654076436098
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83602611741296,
            21.002652446211652
          ],
          [
            105.83577030182487,
            21.002654076436098
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 125,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28C Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002652446211652,
        "to_latitude": 21.002654076436098,
        "to_longitude": 105.83577030182487,
        "from_longitude": 105.83602611741296,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6901,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83553694964351,
          21.002654076436098,
          105.83577030182487,
          21.002756740946303
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83577030182487,
            21.002654076436098
          ],
          [
            105.83553694964351,
            21.002756740946303
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28C Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002654076436098,
        "to_latitude": 21.002756740946303,
        "to_longitude": 105.83553694964351,
        "from_longitude": 105.83577030182487,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6902,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83533578397304,
          21.00268412459274,
          105.83553694964351,
          21.002756740946303
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83553694964351,
            21.002756740946303
          ],
          [
            105.83533578397304,
            21.00268412459274
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28C Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002756740946303,
        "to_latitude": 21.00268412459274,
        "to_longitude": 105.83533578397304,
        "from_longitude": 105.83553694964351,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6903,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83610691905817,
          21.002771765014625,
          105.83610926588892,
          21.002857775163708
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83610691905817,
            21.002771765014625
          ],
          [
            105.83610926588892,
            21.002857775163708
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương ĐÌnh Của",
        "diemDau": "7",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002771765014625,
        "to_latitude": 21.002857775163708,
        "to_longitude": 105.83610926588892,
        "from_longitude": 105.83610691905817,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6904,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83591514111481,
          21.00285690137635,
          105.83610926588892,
          21.002857775163708
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83610926588892,
            21.002857775163708
          ],
          [
            105.83591514111481,
            21.00285690137635
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 60,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28B Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "7-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002857775163708,
        "to_latitude": 21.00285690137635,
        "to_longitude": 105.83591514111481,
        "from_longitude": 105.83610926588892,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6905,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83561607481681,
          21.00285690137635,
          105.83591514111481,
          21.00286065739105
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83591514111481,
            21.00285690137635
          ],
          [
            105.83561607481681,
            21.00286065739105
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28B Lương Đình Của",
        "diemDau": "7-7",
        "diemCuoi": "7-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00285690137635,
        "to_latitude": 21.00286065739105,
        "to_longitude": 105.83561607481681,
        "from_longitude": 105.83591514111481,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6906,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83610691905817,
          21.002771765014625,
          105.83628193324361,
          21.00284152518861
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83610691905817,
            21.002771765014625
          ],
          [
            105.83628193324361,
            21.00284152518861
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 92,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 81 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002771765014625,
        "to_latitude": 21.00284152518861,
        "to_longitude": 105.83628193324361,
        "from_longitude": 105.83610691905817,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6907,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83628193324361,
          21.00284152518861,
          105.83644957130834,
          21.002877833331084
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83628193324361,
            21.00284152518861
          ],
          [
            105.83644957130834,
            21.002877833331084
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 81 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00284152518861,
        "to_latitude": 21.002877833331084,
        "to_longitude": 105.83644957130834,
        "from_longitude": 105.83628193324361,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6908,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83644957130834,
          21.002877833331084,
          105.83659843390089,
          21.002885345360173
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83644957130834,
            21.002877833331084
          ],
          [
            105.83659843390089,
            21.002885345360173
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 81 Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002877833331084,
        "to_latitude": 21.002885345360173,
        "to_longitude": 105.83659843390089,
        "from_longitude": 105.83644957130834,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6909,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83610826016205,
          21.002857775163708,
          105.83610926588892,
          21.003079758090504
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83610926588892,
            21.002857775163708
          ],
          [
            105.83610826016205,
            21.003079758090504
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương ĐÌnh Của",
        "diemDau": "",
        "diemCuoi": "6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002857775163708,
        "to_latitude": 21.003079758090504,
        "to_longitude": 105.83610826016205,
        "from_longitude": 105.83610926588892,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6910,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83610826016205,
          21.003079758090504,
          105.83611194809663,
          21.003235880178085
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83610826016205,
            21.003079758090504
          ],
          [
            105.83611194809663,
            21.003235880178085
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương ĐÌnh Của",
        "diemDau": "6",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTBNN",
        "from_latitude": 21.003079758090504,
        "to_latitude": 21.003235880178085,
        "to_longitude": 105.83611194809663,
        "from_longitude": 105.83610826016205,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6911,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83609585482324,
          21.00322353182527,
          105.83611165640222,
          21.003396136318393
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83611165640222,
            21.00322353182527
          ],
          [
            105.83609585482324,
            21.003396136318393
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương ĐÌnh Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00322353182527,
        "to_latitude": 21.003396136318393,
        "to_longitude": 105.83609585482324,
        "from_longitude": 105.83611165640222,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6912,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83601136527038,
          21.00337735631133,
          105.83609585482324,
          21.003396136318393
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83609585482324,
            21.003396136318393
          ],
          [
            105.83601136527038,
            21.00337735631133
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương ĐÌnh Của",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTBNN",
        "from_latitude": 21.003396136318393,
        "to_latitude": 21.00337735631133,
        "to_longitude": 105.83601136527038,
        "from_longitude": 105.83609585482324,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6913,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.835984878561,
          21.00337735631133,
          105.83601136527038,
          21.003466626542487
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83601136527038,
            21.00337735631133
          ],
          [
            105.835984878561,
            21.003466626542487
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Lương ĐÌnh Của",
        "diemDau": "5",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00337735631133,
        "to_latitude": 21.003466626542487,
        "to_longitude": 105.835984878561,
        "from_longitude": 105.83601136527038,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6914,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83595403314503,
          21.003193690327983,
          105.83611194809663,
          21.003235880178085
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83611194809663,
            21.003235880178085
          ],
          [
            105.83595403314503,
            21.003193690327983
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 62,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28A Lương Đình Của",
        "diemDau": "",
        "diemCuoi": "8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.003235880178085,
        "to_latitude": 21.003193690327983,
        "to_longitude": 105.83595403314503,
        "from_longitude": 105.83611194809663,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6915,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83565362573418,
          21.00310980616229,
          105.83595403314503,
          21.003193690327983
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83595403314503,
            21.003193690327983
          ],
          [
            105.83565362573418,
            21.00310980616229
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 28A Lương Đình Của",
        "diemDau": "8-1",
        "diemCuoi": "8-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.003193690327983,
        "to_latitude": 21.00310980616229,
        "to_longitude": 105.83565362573418,
        "from_longitude": 105.83595403314503,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6916,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83564155580834,
          21.003378986527814,
          105.835984878561,
          21.003466626542487
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.835984878561,
            21.003466626542487
          ],
          [
            105.83564155580834,
            21.003378986527814
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phương Mai",
        "diemDau": "4",
        "diemCuoi": "5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTBNN",
        "from_latitude": 21.003466626542487,
        "to_latitude": 21.003378986527814,
        "to_longitude": 105.83564155580834,
        "from_longitude": 105.835984878561,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6917,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83524458887386,
          21.00327381844314,
          105.83564155580834,
          21.003378986527814
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83564155580834,
            21.003378986527814
          ],
          [
            105.83524458887386,
            21.00327381844314
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phương Mai",
        "diemDau": "3",
        "diemCuoi": "4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTBNN",
        "from_latitude": 21.003378986527814,
        "to_latitude": 21.00327381844314,
        "to_longitude": 105.83524458887386,
        "from_longitude": 105.83564155580834,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6918,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8350917029607,
          21.00327381844314,
          105.83524458887386,
          21.003446594543203
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83524458887386,
            21.00327381844314
          ],
          [
            105.8350917029607,
            21.003446594543203
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "3",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00327381844314,
        "to_latitude": 21.003446594543203,
        "to_longitude": 105.8350917029607,
        "from_longitude": 105.83524458887386,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6919,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83502732993895,
          21.003446594543203,
          105.8350917029607,
          21.003656930395774
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8350917029607,
            21.003446594543203
          ],
          [
            105.83502732993895,
            21.003656930395774
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "diemDau": "3-1",
        "diemCuoi": "3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.003446594543203,
        "to_latitude": 21.003656930395774,
        "to_longitude": 105.83502732993895,
        "from_longitude": 105.8350917029607,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6920,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83502732993895,
          21.003594330470996,
          105.83530359747115,
          21.003656930395774
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83502732993895,
            21.003656930395774
          ],
          [
            105.83530359747115,
            21.003594330470996
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 77,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trước của nhà C9",
        "diemDau": "3-2",
        "diemCuoi": "3-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.003656930395774,
        "to_latitude": 21.003594330470996,
        "to_longitude": 105.83530359747115,
        "from_longitude": 105.83502732993895,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6921,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83530359747115,
          21.003594330470996,
          105.83577030183385,
          21.003709514312817
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83530359747115,
            21.003594330470996
          ],
          [
            105.83577030183385,
            21.003709514312817
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trước của nhà C9",
        "diemDau": "3-3",
        "diemCuoi": "3-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.003594330470996,
        "to_latitude": 21.003709514312817,
        "to_longitude": 105.83577030183385,
        "from_longitude": 105.83530359747115,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6922,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83485835077038,
          21.003439082543196,
          105.83502732993895,
          21.003656930395774
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83502732993895,
            21.003656930395774
          ],
          [
            105.83485835077038,
            21.003439082543196
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trước của nhà C10",
        "diemDau": "3-2",
        "diemCuoi": "3-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.003656930395774,
        "to_latitude": 21.003439082543196,
        "to_longitude": 105.83485835077038,
        "from_longitude": 105.83502732993895,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6923,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83446138383592,
          21.003331410498426,
          105.83485835077038,
          21.003439082543196
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83485835077038,
            21.003439082543196
          ],
          [
            105.83446138383592,
            21.003331410498426
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 49,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trước của nhà C10",
        "diemDau": "3-5",
        "diemCuoi": "3-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.003439082543196,
        "to_latitude": 21.003331410498426,
        "to_longitude": 105.83446138383592,
        "from_longitude": 105.83485835077038,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6924,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83493077041533,
          21.003191186324056,
          105.83524458887386,
          21.00327381844314
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83524458887386,
            21.00327381844314
          ],
          [
            105.83493077041533,
            21.003191186324056
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phương Mai",
        "diemDau": "2",
        "diemCuoi": "3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00327381844314,
        "to_latitude": 21.003191186324056,
        "to_longitude": 105.83493077041533,
        "from_longitude": 105.83524458887386,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6925,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83447747709135,
          21.003078506088027,
          105.83493077041533,
          21.003191186324056
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83493077041533,
            21.003191186324056
          ],
          [
            105.83447747709135,
            21.003078506088027
          ]
        ]
      },
      "properties": {
        "loaiCap": "Nhôm vặn xoắn",
        "tietDien": "4x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 166,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phương Mai",
        "diemDau": "1",
        "diemCuoi": "2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTBNN",
        "from_latitude": 21.003191186324056,
        "to_latitude": 21.003078506088027,
        "to_longitude": 105.83447747709135,
        "from_longitude": 105.83493077041533,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6926,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83434940164022,
          21.003034333848134,
          105.83447747709135,
          21.003078506088027
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83447747709135,
            21.003078506088027
          ],
          [
            105.83434940164022,
            21.003034333848134
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phương Mai",
        "diemDau": "1",
        "diemCuoi": "1-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TTBNN",
        "from_latitude": 21.003078506088027,
        "to_latitude": 21.003034333848134,
        "to_longitude": 105.83434940164022,
        "from_longitude": 105.83447747709135,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6927,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83423037848527,
          21.003034333848134,
          105.83434940164022,
          21.003116939961693
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83434940164022,
            21.003034333848134
          ],
          [
            105.83423037848527,
            21.003116939961693
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 67,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 180 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.003034333848134,
        "to_latitude": 21.003116939961693,
        "to_longitude": 105.83423037848527,
        "from_longitude": 105.83434940164022,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6928,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83401412551977,
          21.00308441397799,
          105.83423037848527,
          21.003116939961693
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83423037848527,
            21.003116939961693
          ],
          [
            105.83401412551977,
            21.00308441397799
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 180 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.003116939961693,
        "to_latitude": 21.00308441397799,
        "to_longitude": 105.83401412551977,
        "from_longitude": 105.83423037848527,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6929,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83390147274069,
          21.0029416855626,
          105.83401412551977,
          21.00308441397799
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83401412551977,
            21.00308441397799
          ],
          [
            105.83390147274069,
            21.0029416855626
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 180 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00308441397799,
        "to_latitude": 21.0029416855626,
        "to_longitude": 105.83390147274069,
        "from_longitude": 105.83401412551977,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6930,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83411336725113,
          21.002924157502978,
          105.83434940164022,
          21.003034333848134
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83434940164022,
            21.003034333848134
          ],
          [
            105.83411336725113,
            21.002924157502978
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 218 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.003034333848134,
        "to_latitude": 21.002924157502978,
        "to_longitude": 105.83411336725113,
        "from_longitude": 105.83434940164022,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6931,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83447445950657,
          21.002966699553614,
          105.83447747709135,
          21.003078506088027
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83447747709135,
            21.003078506088027
          ],
          [
            105.83447445950657,
            21.002966699553614
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.003078506088027,
        "to_latitude": 21.002966699553614,
        "to_longitude": 105.83447445950657,
        "from_longitude": 105.83447747709135,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6932,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83447445950657,
          21.00277391690079,
          105.83452642744105,
          21.002966699553614
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83447445950657,
            21.002966699553614
          ],
          [
            105.83452642744105,
            21.00277391690079
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 156,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002966699553614,
        "to_latitude": 21.00277391690079,
        "to_longitude": 105.83452642744105,
        "from_longitude": 105.83447445950657,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6933,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83452642744105,
          21.00277391690079,
          105.83476246183012,
          21.00291163745779
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83452642744105,
            21.00277391690079
          ],
          [
            105.83476246183012,
            21.00291163745779
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà Đ8 N167/17 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00277391690079,
        "to_latitude": 21.00291163745779,
        "to_longitude": 105.83476246183012,
        "from_longitude": 105.83452642744105,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6934,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83430648629839,
          21.002733852714364,
          105.83452642744105,
          21.00277391690079
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83452642744105,
            21.00277391690079
          ],
          [
            105.83430648629839,
            21.002733852714364
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00277391690079,
        "to_latitude": 21.002733852714364,
        "to_longitude": 105.83430648629839,
        "from_longitude": 105.83452642744105,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6935,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83409459178795,
          21.002688780491717,
          105.83430648629839,
          21.002733852714364
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83430648629839,
            21.002733852714364
          ],
          [
            105.83409459178795,
            21.002688780491717
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002733852714364,
        "to_latitude": 21.002688780491717,
        "to_longitude": 105.83409459178795,
        "from_longitude": 105.83430648629839,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6936,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8339685279612,
          21.002601140021117,
          105.83409459178795,
          21.002688780491717
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83409459178795,
            21.002688780491717
          ],
          [
            105.8339685279612,
            21.002601140021117
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002688780491717,
        "to_latitude": 21.002601140021117,
        "to_longitude": 105.8339685279612,
        "from_longitude": 105.83409459178795,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6937,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83452642744105,
          21.00263619621586,
          105.83456666057513,
          21.00277391690079
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83452642744105,
            21.00277391690079
          ],
          [
            105.83456666057513,
            21.00263619621586
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00277391690079,
        "to_latitude": 21.00263619621586,
        "to_longitude": 105.83456666057513,
        "from_longitude": 105.83452642744105,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6938,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83456666057513,
          21.00263619621586,
          105.83465785568326,
          21.00265873233599
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83456666057513,
            21.00263619621586
          ],
          [
            105.83465785568326,
            21.00265873233599
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà Đ8 N167/17 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00263619621586,
        "to_latitude": 21.00265873233599,
        "to_longitude": 105.83465785568326,
        "from_longitude": 105.83456666057513,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6939,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83509002647084,
          21.003136972005258,
          105.83524458887386,
          21.00327381844314
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83524458887386,
            21.00327381844314
          ],
          [
            105.83509002647084,
            21.003136972005258
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 135 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00327381844314,
        "to_latitude": 21.003136972005258,
        "to_longitude": 105.83509002647084,
        "from_longitude": 105.83524458887386,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6940,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83509002647084,
          21.002856549250076,
          105.83513797109366,
          21.003136972005258
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83509002647084,
            21.003136972005258
          ],
          [
            105.83513797109366,
            21.002856549250076
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 68,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 135 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.003136972005258,
        "to_latitude": 21.002856549250076,
        "to_longitude": 105.83513797109366,
        "from_longitude": 105.83509002647084,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6941,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83501894792974,
          21.002788914875826,
          105.83513797109366,
          21.002856549250076
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83513797109366,
            21.002856549250076
          ],
          [
            105.83501894792974,
            21.002788914875826
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngõ 135 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002856549250076,
        "to_latitude": 21.002788914875826,
        "to_longitude": 105.83501894792974,
        "from_longitude": 105.83513797109366,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6942,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83501894792974,
          21.002698796542564,
          105.83505482261774,
          21.002788914875826
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83501894792974,
            21.002788914875826
          ],
          [
            105.83505482261774,
            21.002698796542564
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngõ 135 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002788914875826,
        "to_latitude": 21.002698796542564,
        "to_longitude": 105.83505482261774,
        "from_longitude": 105.83501894792974,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6943,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83483756368283,
          21.002741364749895,
          105.83501894792974,
          21.002788914875826
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83501894792974,
            21.002788914875826
          ],
          [
            105.83483756368283,
            21.002741364749895
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngõ 135 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002788914875826,
        "to_latitude": 21.002741364749895,
        "to_longitude": 105.83483756368283,
        "from_longitude": 105.83501894792974,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6944,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83500654285143,
          21.00259362797858,
          105.83505482261774,
          21.002698796542564
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83505482261774,
            21.002698796542564
          ],
          [
            105.83500654285143,
            21.00259362797858
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "ngõ 135 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002698796542564,
        "to_latitude": 21.00259362797858,
        "to_longitude": 105.83500654285143,
        "from_longitude": 105.83505482261774,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6945,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83456666057513,
          21.002403322768497,
          105.83463639801236,
          21.00263619621586
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83456666057513,
            21.00263619621586
          ],
          [
            105.83463639801236,
            21.002403322768497
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00263619621586,
        "to_latitude": 21.002403322768497,
        "to_longitude": 105.83463639801236,
        "from_longitude": 105.83456666057513,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6946,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83435476606469,
          21.002330706243,
          105.83463639801236,
          21.002403322768497
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83463639801236,
            21.002403322768497
          ],
          [
            105.83435476606469,
            21.002330706243
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 185,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 167/12 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002403322768497,
        "to_latitude": 21.002330706243,
        "to_longitude": 105.83435476606469,
        "from_longitude": 105.83463639801236,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6947,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83405167644611,
          21.002248073601724,
          105.83435476606469,
          21.002330706243
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83435476606469,
            21.002330706243
          ],
          [
            105.83405167644611,
            21.002248073601724
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 167/12 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002330706243,
        "to_latitude": 21.002248073601724,
        "to_longitude": 105.83405167644611,
        "from_longitude": 105.83435476606469,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6948,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83396316354572,
          21.002248073601724,
          105.83405167644611,
          21.002478443274637
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83405167644611,
            21.002248073601724
          ],
          [
            105.83396316354572,
            21.002478443274637
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 167/12 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002248073601724,
        "to_latitude": 21.002478443274637,
        "to_longitude": 105.83396316354572,
        "from_longitude": 105.83405167644611,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6949,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83380491321705,
          21.00244589106013,
          105.83396316354572,
          21.002478443274637
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83396316354572,
            21.002478443274637
          ],
          [
            105.83380491321705,
            21.00244589106013
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 167/12 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002478443274637,
        "to_latitude": 21.00244589106013,
        "to_longitude": 105.83380491321705,
        "from_longitude": 105.83396316354572,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6950,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83363056962402,
          21.00239080268048,
          105.83380491321705,
          21.00244589106013
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83380491321705,
            21.00244589106013
          ],
          [
            105.83363056962402,
            21.00239080268048
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 167/12 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.00244589106013,
        "to_latitude": 21.00239080268048,
        "to_longitude": 105.83363056962402,
        "from_longitude": 105.83380491321705,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6951,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83435476606469,
          21.00199266333056,
          105.83442450349294,
          21.002330706243
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83435476606469,
            21.002330706243
          ],
          [
            105.83442450349294,
            21.00199266333056
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 167/12 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002330706243,
        "to_latitude": 21.00199266333056,
        "to_longitude": 105.83442450349294,
        "from_longitude": 105.83435476606469,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6952,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83458543603831,
          21.002195489169722,
          105.83463639801236,
          21.002403322768497
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83463639801236,
            21.002403322768497
          ],
          [
            105.83458543603831,
            21.002195489169722
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 167/18 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002403322768497,
        "to_latitude": 21.002195489169722,
        "to_longitude": 105.83458543603831,
        "from_longitude": 105.83463639801236,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6953,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83458543603831,
          21.002017703573213,
          105.83464176242785,
          21.002195489169722
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83458543603831,
            21.002195489169722
          ],
          [
            105.83464176242785,
            21.002017703573213
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 167/18 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002195489169722,
        "to_latitude": 21.002017703573213,
        "to_longitude": 105.83464176242785,
        "from_longitude": 105.83458543603831,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6954,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83463639801236,
          21.002180465043377,
          105.83479733054874,
          21.002403322768497
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83463639801236,
            21.002403322768497
          ],
          [
            105.83479733054874,
            21.002180465043377
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002403322768497,
        "to_latitude": 21.002180465043377,
        "to_longitude": 105.83479733054874,
        "from_longitude": 105.83463639801236,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6955,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83479733054874,
          21.002180465043377,
          105.83510042016731,
          21.002230545459803
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83479733054874,
            21.002180465043377
          ],
          [
            105.83510042016731,
            21.002230545459803
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TTBNN",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TTBNN",
        "from_latitude": 21.002180465043377,
        "to_latitude": 21.002230545459803,
        "to_longitude": 105.83510042016731,
        "from_longitude": 105.83479733054874,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6956,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83542262034761,
          21.0057836820372,
          105.83586518486759,
          21.005865060739932
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83543066700679,
            21.0057836820372
          ],
          [
            105.83542262034761,
            21.005865060739932
          ],
          [
            105.83550845104027,
            21.005797453820268
          ],
          [
            105.83586518486759,
            21.005813729563698
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 11 Lương Đình Của",
        "diemDau": "KLC3 01",
        "diemCuoi": "KLC3 1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.0057836820372,
        "to_latitude": 21.005813729563698,
        "to_longitude": 105.83586518486759,
        "from_longitude": 105.83543066700679,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6957,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83585445603663,
          21.005813729563698,
          105.83586518486759,
          21.006069133294215
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83586518486759,
            21.005813729563698
          ],
          [
            105.83585445603663,
            21.006069133294215
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 11 Lương Đình Của",
        "diemDau": "KLC3 1-1",
        "diemCuoi": "KLC3 1-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.005813729563698,
        "to_latitude": 21.006069133294215,
        "to_longitude": 105.83585445603663,
        "from_longitude": 105.83586518486759,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6958,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83586518486759,
          21.005813729563698,
          105.83609853705792,
          21.005868816679765
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83586518486759,
            21.005813729563698
          ],
          [
            105.83609853705792,
            21.005868816679765
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 11 Lương Đình Của",
        "diemDau": "KLC3 1-1",
        "diemCuoi": "KLC3 1-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.005813729563698,
        "to_latitude": 21.005868816679765,
        "to_longitude": 105.83609853705792,
        "from_longitude": 105.83586518486759,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6959,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83609853705792,
          21.00584628104356,
          105.83632652482379,
          21.005868816679765
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83609853705792,
            21.005868816679765
          ],
          [
            105.83632652482379,
            21.00584628104356
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 11 Lương Đình Của",
        "diemDau": "KLC3 1-3",
        "diemCuoi": "KLC3 1-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.005868816679765,
        "to_latitude": 21.00584628104356,
        "to_longitude": 105.83632652482379,
        "from_longitude": 105.83609853705792,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6960,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83573107440863,
          21.00468068323023,
          105.83596174439121,
          21.004807134131806
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83573107440863,
            21.004807134131806
          ],
          [
            105.83586518484066,
            21.00468068323023
          ],
          [
            105.83596174439121,
            21.00470947899002
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà C3 Kim Liên",
        "diemDau": "KLC3 05",
        "diemCuoi": "KLC3 5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.004807134131806,
        "to_latitude": 21.00470947899002,
        "to_longitude": 105.83596174439121,
        "from_longitude": 105.83573107440863,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6961,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83596174439121,
          21.00470947899002,
          105.83624337633889,
          21.004772078447026
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83596174439121,
            21.00470947899002
          ],
          [
            105.83624337633889,
            21.004772078447026
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà C3 Kim Liên",
        "diemDau": "KLC3 5-1",
        "diemCuoi": "KLC3 5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.00470947899002,
        "to_latitude": 21.004772078447026,
        "to_longitude": 105.83624337633889,
        "from_longitude": 105.83596174439121,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6962,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83624337633889,
          21.004772078447026,
          105.83649282177566,
          21.004927324988067
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83624337633889,
            21.004772078447026
          ],
          [
            105.8363962622251,
            21.004815898050882
          ],
          [
            105.83642040210376,
            21.004893521318788
          ],
          [
            105.83649282177566,
            21.004927324988067
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà C3 Kim Liên",
        "diemDau": "KLC3 5-2",
        "diemCuoi": "KLC3 5-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.004772078447026,
        "to_latitude": 21.004927324988067,
        "to_longitude": 105.83649282177566,
        "from_longitude": 105.83624337633889,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6963,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83624337633889,
          21.004748290655872,
          105.83652500828653,
          21.004815898050882
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83624337633889,
            21.004772078447026
          ],
          [
            105.8363962622251,
            21.004815898050882
          ],
          [
            105.83647136407781,
            21.004748290655872
          ],
          [
            105.83652500828653,
            21.004752046623803
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà C3 Kim Liên",
        "diemDau": "KLC3 5-2",
        "diemCuoi": "KLC3 5-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.004772078447026,
        "to_latitude": 21.004752046623803,
        "to_longitude": 105.83652500828653,
        "from_longitude": 105.83624337633889,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6964,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83652500828653,
          21.004752046623803,
          105.83671008071056,
          21.004787102313298
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83652500828653,
            21.004752046623803
          ],
          [
            105.83671008071056,
            21.004787102313298
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà C3 Kim Liên",
        "diemDau": "KLC3 5-3",
        "diemCuoi": "KLC3 5-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.004752046623803,
        "to_latitude": 21.004787102313298,
        "to_longitude": 105.83671008071056,
        "from_longitude": 105.83652500828653,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6965,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83566401918812,
          21.004957718605404,
          105.83591078239023,
          21.005125138895792
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83566401918812,
            21.005125138895792
          ],
          [
            105.83569932183583,
            21.004957718605404
          ],
          [
            105.83591078239023,
            21.005016216079675
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà C3 Kim Liên",
        "diemDau": "KLC3 04",
        "diemCuoi": "KLC3 4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.005125138895792,
        "to_latitude": 21.005016216079675,
        "to_longitude": 105.83591078239023,
        "from_longitude": 105.83566401918812,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6966,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83591078239023,
          21.005016216079675,
          105.83617363887471,
          21.005066295544427
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83591078239023,
            21.005016216079675
          ],
          [
            105.83617363887471,
            21.005066295544427
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà C3 Kim Liên",
        "diemDau": "KLC3 4-1",
        "diemCuoi": "KLC3 4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.005016216079675,
        "to_latitude": 21.005066295544427,
        "to_longitude": 105.83617363887471,
        "from_longitude": 105.83591078239023,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6967,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83617363887471,
          21.005066295544427,
          105.83644722419015,
          21.00520401398669
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83617363887471,
            21.005066295544427
          ],
          [
            105.83644722419015,
            21.00520401398669
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà C3 Kim Liên",
        "diemDau": "KLC3 4-2",
        "diemCuoi": "KLC3 4-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.005066295544427,
        "to_latitude": 21.00520401398669,
        "to_longitude": 105.83644722419015,
        "from_longitude": 105.83617363887471,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6968,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83580081184584,
          21.00428920414925,
          105.83606903272783,
          21.004481616743437
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83580081184584,
            21.004481616743437
          ],
          [
            105.8358538815297,
            21.00428920414925
          ],
          [
            105.83606903272783,
            21.004342645640055
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà C3 Kim Liên",
        "diemDau": "KLC3 06",
        "diemCuoi": "KLC3 6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.004481616743437,
        "to_latitude": 21.004342645640055,
        "to_longitude": 105.83606903272783,
        "from_longitude": 105.83580081184584,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6969,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83606903272783,
          21.004342645640055,
          105.83632384258911,
          21.004415261186583
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83606903272783,
            21.004342645640055
          ],
          [
            105.83632384258911,
            21.004415261186583
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà C3 Kim Liên",
        "diemDau": "KLC3 6-1",
        "diemCuoi": "KLC3 6-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.004342645640055,
        "to_latitude": 21.004415261186583,
        "to_longitude": 105.83632384258911,
        "from_longitude": 105.83606903272783,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6970,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83632384258911,
          21.004415261186583,
          105.83652500825958,
          21.004465340853862
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83632384258911,
            21.004415261186583
          ],
          [
            105.83652500825958,
            21.004465340853862
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà C3 Kim Liên",
        "diemDau": "KLC3 6-2",
        "diemCuoi": "KLC3 6-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.004415261186583,
        "to_latitude": 21.004465340853862,
        "to_longitude": 105.83652500825958,
        "from_longitude": 105.83632384258911,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6971,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83543066700679,
          21.005691035458593,
          105.8355755062967,
          21.0057836820372
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83543066700679,
            21.0057836820372
          ],
          [
            105.8355755062967,
            21.005691035458593
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "KLC3 01",
        "diemCuoi": "KLC3 02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.0057836820372,
        "to_latitude": 21.005691035458593,
        "to_longitude": 105.8355755062967,
        "from_longitude": 105.83543066700679,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6972,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83649148065382,
          21.00361523623254,
          105.83651025611701,
          21.003677836149393
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83651025611701,
            21.00361523623254
          ],
          [
            105.83649148065382,
            21.003677836149393
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 71 Phương Mai",
        "diemDau": "KLC3 10",
        "diemCuoi": "KLC3 10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.00361523623254,
        "to_latitude": 21.003677836149393,
        "to_longitude": 105.83649148065382,
        "from_longitude": 105.83651025611701,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6973,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83641637880112,
          21.003677836149393,
          105.83649148065382,
          21.003788011938155
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83649148065382,
            21.003677836149393
          ],
          [
            105.83641637880112,
            21.003788011938155
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Phương Mai",
        "diemDau": "KLC3 10-1",
        "diemCuoi": "KLC3 10-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.003677836149393,
        "to_latitude": 21.003788011938155,
        "to_longitude": 105.83641637880112,
        "from_longitude": 105.83649148065382,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6974,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83636273461931,
          21.003788011938155,
          105.83641637880112,
          21.00406595504431
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83641637880112,
            21.003788011938155
          ],
          [
            105.83636273461931,
            21.00406595504431
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Phương Mai",
        "diemDau": "KLC3 10-2",
        "diemCuoi": "KLC3 10-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.003788011938155,
        "to_latitude": 21.00406595504431,
        "to_longitude": 105.83636273461931,
        "from_longitude": 105.83641637880112,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6975,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.837179467281,
          21.003444964327432,
          105.83730150777822,
          21.003757964005437
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.837179467281,
            21.003757964005437
          ],
          [
            105.83726529794673,
            21.003661560173835
          ],
          [
            105.83730150777822,
            21.003444964327432
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 71 Phương Mai",
        "diemDau": "KLC3 12",
        "diemCuoi": "KLC3 12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.003757964005437,
        "to_latitude": 21.003444964327432,
        "to_longitude": 105.83730150777822,
        "from_longitude": 105.837179467281,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6976,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8370922954755,
          21.003372348308755,
          105.83730150777822,
          21.003444964327432
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83730150777822,
            21.003444964327432
          ],
          [
            105.8370922954755,
            21.003372348308755
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 71 Phương Mai",
        "diemDau": "KLC3 12-1",
        "diemCuoi": "KLC3 12-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.003444964327432,
        "to_latitude": 21.003372348308755,
        "to_longitude": 105.8370922954755,
        "from_longitude": 105.83730150777822,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6977,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83673019725066,
          21.003259668209516,
          105.8370922954755,
          21.003372348308755
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8370922954755,
            21.003372348308755
          ],
          [
            105.83673019725066,
            21.003259668209516
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 71 Phương Mai",
        "diemDau": "KLC3 12-2",
        "diemCuoi": "KLC3 12-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.003372348308755,
        "to_latitude": 21.003259668209516,
        "to_longitude": 105.83673019725066,
        "from_longitude": 105.8370922954755,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6978,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8363332303162,
          21.00315450003988,
          105.83673019725066,
          21.003259668209516
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83673019725066,
            21.003259668209516
          ],
          [
            105.8363332303162,
            21.00315450003988
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 71 Phương Mai",
        "diemDau": "KLC3 12-3",
        "diemCuoi": "KLC3 12-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.003259668209516,
        "to_latitude": 21.00315450003988,
        "to_longitude": 105.8363332303162,
        "from_longitude": 105.83673019725066,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6979,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83725134213148,
          21.003126955983824,
          105.83734978753552,
          21.003444964327432
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83730150777822,
            21.003444964327432
          ],
          [
            105.83725134213148,
            21.00342755221151
          ],
          [
            105.83734978753552,
            21.003126955983824
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLC3",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 71 Phương Mai",
        "diemDau": "KLC3 12-1",
        "diemCuoi": "KLC3 12-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Kim Liên C3",
        "from_latitude": 21.003444964327432,
        "to_latitude": 21.003126955983824,
        "to_longitude": 105.83734978753552,
        "from_longitude": 105.83730150777822,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6980,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83310150368428,
          21.006496057180765,
          105.8331360371516,
          21.006651614927595
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83310150368428,
            21.006651614927595
          ],
          [
            105.8331360371516,
            21.006496057180765
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 97 Phạm Ngọc Thạch",
        "diemDau": "1-6",
        "diemCuoi": "1-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.006651614927595,
        "to_latitude": 21.006496057180765,
        "to_longitude": 105.8331360371516,
        "from_longitude": 105.83310150368428,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6981,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83387900905547,
          21.006393395239748,
          105.83408252163753,
          21.00648072050104
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83408252163753,
            21.00648072050104
          ],
          [
            105.83387900905547,
            21.006393395239748
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B15 - B19",
        "diemDau": "7-4",
        "diemCuoi": "7-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.00648072050104,
        "to_latitude": 21.006393395239748,
        "to_longitude": 105.83387900905547,
        "from_longitude": 105.83408252163753,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6982,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83364029245863,
          21.00635333202595,
          105.83387900905547,
          21.006393395239748
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83387900905547,
            21.006393395239748
          ],
          [
            105.83364029245863,
            21.00635333202595
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B15 - B19",
        "diemDau": "7-5",
        "diemCuoi": "7-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.006393395239748,
        "to_latitude": 21.00635333202595,
        "to_longitude": 105.83364029245863,
        "from_longitude": 105.83387900905547,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6983,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83341766910824,
          21.00635333202595,
          105.83364029245863,
          21.006508576921856
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83364029245863,
            21.00635333202595
          ],
          [
            105.83341766910824,
            21.006508576921856
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B15 - B19",
        "diemDau": "7-6",
        "diemCuoi": "7-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.00635333202595,
        "to_latitude": 21.006508576921856,
        "to_longitude": 105.83341766910824,
        "from_longitude": 105.83364029245863,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6984,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83321650342879,
          21.00637837153603,
          105.83341766910824,
          21.006508576921856
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83341766910824,
            21.006508576921856
          ],
          [
            105.83321650342879,
            21.00637837153603
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B15 - B19",
        "diemDau": "7-7",
        "diemCuoi": "7-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.006508576921856,
        "to_latitude": 21.00637837153603,
        "to_longitude": 105.83321650342879,
        "from_longitude": 105.83341766910824,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6985,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83534148353198,
          21.023525067159472,
          105.8355895878649,
          21.023628969136215
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8355895878649,
            21.023525067159472
          ],
          [
            105.83534148353198,
            21.023628969136215
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Hương",
        "diemDau": "VH5 01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023525067159472,
        "to_latitude": 21.023628969136215,
        "to_longitude": 105.83534148353198,
        "from_longitude": 105.8355895878649,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6986,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83509874361454,
          21.023628969136215,
          105.83534148353198,
          21.023736626529583
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83534148353198,
            21.023628969136215
          ],
          [
            105.83509874361454,
            21.023736626529583
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Hương",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023628969136215,
        "to_latitude": 21.023736626529583,
        "to_longitude": 105.83509874361454,
        "from_longitude": 105.83534148353198,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6987,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8349056245673,
          21.023736626529583,
          105.83509874361454,
          21.023789203368036
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83509874361454,
            21.023736626529583
          ],
          [
            105.83505851048047,
            21.023747892995345
          ],
          [
            105.8349056245673,
            21.023789203368036
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Hương",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023736626529583,
        "to_latitude": 21.023789203368036,
        "to_longitude": 105.8349056245673,
        "from_longitude": 105.83509874361454,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6988,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83452206867153,
          21.023789203368036,
          105.8349056245673,
          21.02391438624007
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8349056245673,
            21.023789203368036
          ],
          [
            105.83479565400495,
            21.023853046644906
          ],
          [
            105.83471786993552,
            21.023889349673908
          ],
          [
            105.83463069814796,
            21.02391438624007
          ],
          [
            105.83452206867153,
            21.023893105160692
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Hương",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023789203368036,
        "to_latitude": 21.023893105160692,
        "to_longitude": 105.83452206867153,
        "from_longitude": 105.8349056245673,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6989,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83437454718283,
          21.023893105160692,
          105.83452206867153,
          21.023961955706202
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83452206867153,
            21.023893105160692
          ],
          [
            105.83440941590146,
            21.02392815634991
          ],
          [
            105.83441478031695,
            21.023945681942276
          ],
          [
            105.83437454718283,
            21.023961955706202
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Hương",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023893105160692,
        "to_latitude": 21.023961955706202,
        "to_longitude": 105.83437454718283,
        "from_longitude": 105.83452206867153,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6990,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83423239010065,
          21.023961955706202,
          105.83437454718283,
          21.024187284541398
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83437454718283,
            21.023961955706202
          ],
          [
            105.83436381835189,
            21.024005769671643
          ],
          [
            105.83423239010065,
            21.024187284541398
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Hương",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023961955706202,
        "to_latitude": 21.024187284541398,
        "to_longitude": 105.83423239010065,
        "from_longitude": 105.83437454718283,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6991,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83394539373752,
          21.024187284541398,
          105.83423239010065,
          21.024450167752672
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83423239010065,
            21.024187284541398
          ],
          [
            105.83409559745192,
            21.024336251750174
          ],
          [
            105.83394539373752,
            21.024450167752672
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Hương",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.024187284541398,
        "to_latitude": 21.024450167752672,
        "to_longitude": 105.83394539373752,
        "from_longitude": 105.83423239010065,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6992,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83367046730922,
          21.024450167752672,
          105.83394539373752,
          21.02452402533349
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83394539373752,
            21.024450167752672
          ],
          [
            105.83367046730922,
            21.02452402533349
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Hương",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.024450167752672,
        "to_latitude": 21.02452402533349,
        "to_longitude": 105.83367046730922,
        "from_longitude": 105.83394539373752,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6993,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83340224640925,
          21.02452402533349,
          105.83367046730922,
          21.02460414198947
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83367046730922,
            21.02452402533349
          ],
          [
            105.83364096302404,
            21.024536543562125
          ],
          [
            105.83340224640925,
            21.02460414198947
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Hương",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02452402533349,
        "to_latitude": 21.02460414198947,
        "to_longitude": 105.83340224640925,
        "from_longitude": 105.83367046730922,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6994,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83317533714552,
          21.02460414198947,
          105.83340224640925,
          21.024675431271106
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83340224640925,
            21.02460414198947
          ],
          [
            105.83322790283415,
            21.024662977630427
          ],
          [
            105.83317533714552,
            21.024675431271106
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Văn Hương",
        "diemDau": "10",
        "diemCuoi": "VH5 11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02460414198947,
        "to_latitude": 21.024675431271106,
        "to_longitude": 105.83317533714552,
        "from_longitude": 105.83340224640925,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6995,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80144261340931,
          21.027435955083728,
          105.80151019972278,
          21.027718017730166
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80144261340931,
            21.027435955083728
          ],
          [
            105.80151019972278,
            21.027718017730166
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "diemDau": "12",
        "diemCuoi": "12-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.027435955083728,
        "to_latitude": 21.027718017730166,
        "to_longitude": 105.80151019972278,
        "from_longitude": 105.80144261340931,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6996,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80136204577056,
          21.027718017730166,
          105.80151019972278,
          21.02775258970782
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80151019972278,
            21.027718017730166
          ],
          [
            105.80136204577056,
            21.02775258970782
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "diemDau": "12-6",
        "diemCuoi": "12-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.027718017730166,
        "to_latitude": 21.02775258970782,
        "to_longitude": 105.80136204577056,
        "from_longitude": 105.80151019972278,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6997,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80118565242428,
          21.02775258970782,
          105.80136204577056,
          21.02778060753003
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80136204577056,
            21.02775258970782
          ],
          [
            105.80118565242428,
            21.02778060753003
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "diemDau": "12-7",
        "diemCuoi": "12-18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02775258970782,
        "to_latitude": 21.02778060753003,
        "to_longitude": 105.80118565242428,
        "from_longitude": 105.80136204577056,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6998,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80151019972278,
          21.027718017730166,
          105.80172629636346,
          21.027721147221005
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80151019972278,
            21.027718017730166
          ],
          [
            105.80172629636346,
            21.027721147221005
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "diemDau": "12-6",
        "diemCuoi": "12-19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.027718017730166,
        "to_latitude": 21.027721147221005,
        "to_longitude": 105.80172629636346,
        "from_longitude": 105.80151019972278,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 6999,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80172629636346,
          21.027721147221005,
          105.801844313558,
          21.027891391412236
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80172629636346,
            21.027721147221005
          ],
          [
            105.801844313558,
            21.027891391412236
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "diemDau": "12-19",
        "diemCuoi": "12-19-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.027721147221005,
        "to_latitude": 21.027891391412236,
        "to_longitude": 105.801844313558,
        "from_longitude": 105.80172629636346,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7000,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80151019972278,
          21.027718017730166,
          105.80152365025644,
          21.027927264709447
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80151019972278,
            21.027718017730166
          ],
          [
            105.80152365025644,
            21.027927264709447
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "diemDau": "12-6",
        "diemCuoi": "12-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.027718017730166,
        "to_latitude": 21.027927264709447,
        "to_longitude": 105.80152365025644,
        "from_longitude": 105.80151019972278,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7001,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80152365025644,
          21.027927264709447,
          105.80171824974025,
          21.028168037809056
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80152365025644,
            21.027927264709447
          ],
          [
            105.80171824974025,
            21.028168037809056
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "diemDau": "12-8",
        "diemCuoi": "12-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.027927264709447,
        "to_latitude": 21.028168037809056,
        "to_longitude": 105.80171824974025,
        "from_longitude": 105.80152365025644,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7002,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80162505368142,
          21.028168037809056,
          105.80171824974025,
          21.028233723808484
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80171824974025,
            21.028168037809056
          ],
          [
            105.80162505368142,
            21.028233723808484
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "diemDau": "12-9",
        "diemCuoi": "12-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.028168037809056,
        "to_latitude": 21.028233723808484,
        "to_longitude": 105.80162505368142,
        "from_longitude": 105.80171824974025,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7003,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80150367301307,
          21.028161778846922,
          105.80162505368142,
          21.028239389963417
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80162505368142,
            21.028233723808484
          ],
          [
            105.801578283128,
            21.028239389963417
          ],
          [
            105.80150367301307,
            21.028161778846922
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "diemDau": "12-13",
        "diemCuoi": "12-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.028233723808484,
        "to_latitude": 21.028161778846922,
        "to_longitude": 105.80150367301307,
        "from_longitude": 105.80162505368142,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7004,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80133067053285,
          21.028233723808484,
          105.80162505368142,
          21.028394612076845
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80162505368142,
            21.028233723808484
          ],
          [
            105.80142539721483,
            21.028394612076845
          ],
          [
            105.80135297757884,
            21.028322008205343
          ],
          [
            105.80133067053285,
            21.028279447298736
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "diemDau": "12-13",
        "diemCuoi": "12-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.028233723808484,
        "to_latitude": 21.028279447298736,
        "to_longitude": 105.80133067053285,
        "from_longitude": 105.80162505368142,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7005,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80142539721483,
          21.028394612076845,
          105.80157475155417,
          21.02857236623415
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80142539721483,
            21.028394612076845
          ],
          [
            105.80157475155417,
            21.02857236623415
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "diemDau": "12-15",
        "diemCuoi": "12-16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.028394612076845,
        "to_latitude": 21.02857236623415,
        "to_longitude": 105.80157475155417,
        "from_longitude": 105.80142539721483,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7006,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80157475155417,
          21.02857236623415,
          105.80186443012505,
          21.02879643631326
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80157475155417,
            21.02857236623415
          ],
          [
            105.80186443012505,
            21.02879643631326
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "diemDau": "12-16",
        "diemCuoi": "12-17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02857236623415,
        "to_latitude": 21.02879643631326,
        "to_longitude": 105.80186443012505,
        "from_longitude": 105.80157475155417,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7007,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80182285588708,
          21.028006008089697,
          105.80195778903239,
          21.02808917486116
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80182285588708,
            21.02808917486116
          ],
          [
            105.80195778903239,
            21.028006008089697
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "diemDau": "12-10",
        "diemCuoi": "12-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02808917486116,
        "to_latitude": 21.028006008089697,
        "to_longitude": 105.80195778903239,
        "from_longitude": 105.80182285588708,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7008,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80171824974025,
          21.02808917486116,
          105.80182285588708,
          21.028168037809056
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80171824974025,
            21.028168037809056
          ],
          [
            105.80182285588708,
            21.02808917486116
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "diemDau": "12-9",
        "diemCuoi": "12-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.028168037809056,
        "to_latitude": 21.02808917486116,
        "to_longitude": 105.80182285588708,
        "from_longitude": 105.80171824974025,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7009,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80182285588708,
          21.02808917486116,
          105.80193838529615,
          21.028206444710037
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80182285588708,
            21.02808917486116
          ],
          [
            105.80193838529615,
            21.028206444710037
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "diemDau": "12-10",
        "diemCuoi": "12-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02808917486116,
        "to_latitude": 21.028206444710037,
        "to_longitude": 105.80193838529615,
        "from_longitude": 105.80182285588708,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7010,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8355399670037,
          21.023525067159472,
          105.83567944186018,
          21.02372160457198
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8355895878649,
            21.023525067159472
          ],
          [
            105.8355399670037,
            21.02358515505753
          ],
          [
            105.83567944186018,
            21.02372160457198
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 107 Văn hương",
        "diemDau": "VH5 01",
        "diemCuoi": "1-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023525067159472,
        "to_latitude": 21.02372160457198,
        "to_longitude": 105.83567944186018,
        "from_longitude": 105.8355895878649,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7011,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83567944186018,
          21.02372160457198,
          105.83584976213264,
          21.023853046645744
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83567944186018,
            21.02372160457198
          ],
          [
            105.83571565169166,
            21.023741633847614
          ],
          [
            105.83584976213264,
            21.023853046645744
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 107 Văn hương",
        "diemDau": "1-4",
        "diemCuoi": "1-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02372160457198,
        "to_latitude": 21.023853046645744,
        "to_longitude": 105.83584976213264,
        "from_longitude": 105.83567944186018,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7012,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83554130810758,
          21.023642739271594,
          105.83567944186018,
          21.02372160457198
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83554130810758,
            21.023716597251596
          ],
          [
            105.83561775106416,
            21.023642739271594
          ],
          [
            105.83567944186018,
            21.02372160457198
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 107 Văn hương",
        "diemDau": "1-4",
        "diemCuoi": "1-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023716597251596,
        "to_latitude": 21.02372160457198,
        "to_longitude": 105.83567944186018,
        "from_longitude": 105.83554130810758,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7013,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83554667251407,
          21.023438690762276,
          105.8355895878649,
          21.023525067159472
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8355895878649,
            21.023525067159472
          ],
          [
            105.83554667251407,
            21.023438690762276
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Bộ Công An",
        "diemDau": "VH5 01",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023525067159472,
        "to_latitude": 21.023438690762276,
        "to_longitude": 105.83554667251407,
        "from_longitude": 105.8355895878649,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7014,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8354568185098,
          21.023307248322315,
          105.83562847989512,
          21.023525067159472
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8355895878649,
            21.023525067159472
          ],
          [
            105.83562847989512,
            21.023396128460462
          ],
          [
            105.83559495228037,
            21.023318514821355
          ],
          [
            105.8354568185098,
            21.023307248322315
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Bộ Công An",
        "diemDau": "VH5 01",
        "diemCuoi": "1-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023525067159472,
        "to_latitude": 21.023307248322315,
        "to_longitude": 105.8354568185098,
        "from_longitude": 105.8355895878649,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7015,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83525565283932,
          21.023307248322315,
          105.8354568185098,
          21.023356069813648
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8354568185098,
            21.023307248322315
          ],
          [
            105.83525565283932,
            21.023356069813648
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "TT Bộ Công An",
        "diemDau": "1-2",
        "diemCuoi": "1-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023307248322315,
        "to_latitude": 21.023356069813648,
        "to_longitude": 105.83525565283932,
        "from_longitude": 105.8354568185098,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7016,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83509874361454,
          21.023736626529583,
          105.83514165895639,
          21.023914386240897
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83509874361454,
            21.023736626529583
          ],
          [
            105.83514165895639,
            21.023914386240897
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 93 Văn Hương",
        "diemDau": "03",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023736626529583,
        "to_latitude": 21.023914386240897,
        "to_longitude": 105.83514165895639,
        "from_longitude": 105.83509874361454,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7017,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83514165895639,
          21.023914386240897,
          105.83518189209049,
          21.024050835453185
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83514165895639,
            21.023914386240897
          ],
          [
            105.83518189209049,
            21.024050835453185
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 93 Văn Hương",
        "diemDau": "3-1",
        "diemCuoi": "3-1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023914386240897,
        "to_latitude": 21.024050835453185,
        "to_longitude": 105.83518189209049,
        "from_longitude": 105.83514165895639,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7018,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83514165895639,
          21.023914386240897,
          105.83533075469204,
          21.023924400865702
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83514165895639,
            21.023914386240897
          ],
          [
            105.83519262093043,
            21.023924400865702
          ],
          [
            105.83533075469204,
            21.023919393553772
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 93 Văn Hương",
        "diemDau": "3-1",
        "diemCuoi": "3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023914386240897,
        "to_latitude": 21.023919393553772,
        "to_longitude": 105.83533075469204,
        "from_longitude": 105.83514165895639,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7019,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83533075469204,
          21.023896860644868,
          105.83549571054002,
          21.023941926459333
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83533075469204,
            21.023919393553772
          ],
          [
            105.83537367004284,
            21.023900616129794
          ],
          [
            105.83542597312078,
            21.023896860644868
          ],
          [
            105.83546352404713,
            21.0239106307555
          ],
          [
            105.83549571054002,
            21.023941926459333
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 93 Văn Hương",
        "diemDau": "3-2",
        "diemCuoi": "3-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023919393553772,
        "to_latitude": 21.023941926459333,
        "to_longitude": 105.83549571054002,
        "from_longitude": 105.83533075469204,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7020,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83502364175285,
          21.023611443506613,
          105.83509874361454,
          21.023736626529583
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83509874361454,
            21.023736626529583
          ],
          [
            105.83503705280954,
            21.023665272218093
          ],
          [
            105.83502364175285,
            21.023611443506613
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 100 Văn Hương",
        "diemDau": "03",
        "diemCuoi": "3-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023736626529583,
        "to_latitude": 21.023611443506613,
        "to_longitude": 105.83502364175285,
        "from_longitude": 105.83509874361454,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7021,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83502364175285,
          21.023412402283547,
          105.83503437059281,
          21.023611443506613
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83502364175285,
            21.023611443506613
          ],
          [
            105.83503437059281,
            21.023412402283547
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 100 Văn Hương",
        "diemDau": "3-4",
        "diemCuoi": "3-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023611443506613,
        "to_latitude": 21.023412402283547,
        "to_longitude": 105.83503437059281,
        "from_longitude": 105.83502364175285,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7022,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83484929816878,
          21.023412402283547,
          105.83503437059281,
          21.023442446258702
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83503437059281,
            21.023412402283547
          ],
          [
            105.83484929816878,
            21.023442446258702
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 100 Văn Hương",
        "diemDau": "3-5",
        "diemCuoi": "3-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023412402283547,
        "to_latitude": 21.023442446258702,
        "to_longitude": 105.83484929816878,
        "from_longitude": 105.83503437059281,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7023,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83494183438079,
          21.02334981064951,
          105.83503437059281,
          21.023438690761438
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83503437059281,
            21.023412402283547
          ],
          [
            105.83495658654134,
            21.023438690761438
          ],
          [
            105.83494183438079,
            21.02334981064951
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 100 Văn Hương",
        "diemDau": "3-5",
        "diemCuoi": "3-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023412402283547,
        "to_latitude": 21.02334981064951,
        "to_longitude": 105.83494183438079,
        "from_longitude": 105.83503437059281,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7024,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8348747791603,
          21.023268441484653,
          105.83494183438079,
          21.02334981064951
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83494183438079,
            21.02334981064951
          ],
          [
            105.8348747791603,
            21.023268441484653
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 100 Văn Hương",
        "diemDau": "3-6",
        "diemCuoi": "VH5 3-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02334981064951,
        "to_latitude": 21.023268441484653,
        "to_longitude": 105.8348747791603,
        "from_longitude": 105.83494183438079,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7025,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83477821962768,
          21.023352314314195,
          105.83484929816878,
          21.023442446258702
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83484929816878,
            21.023442446258702
          ],
          [
            105.83484527486615,
            21.023352314314195
          ],
          [
            105.83477821962768,
            21.023364832643722
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 100 Văn Hương",
        "diemDau": "3-8",
        "diemCuoi": "3-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023442446258702,
        "to_latitude": 21.023364832643722,
        "to_longitude": 105.83477821962768,
        "from_longitude": 105.83484929816878,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7026,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83464276807382,
          21.023352314314195,
          105.83477821962768,
          21.02338736563309
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83477821962768,
            21.023364832643722
          ],
          [
            105.83476480859795,
            21.023352314314195
          ],
          [
            105.83471116441616,
            21.023352314314195
          ],
          [
            105.83464276807382,
            21.02338736563309
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 100 Văn Hương",
        "diemDau": "3-9",
        "diemCuoi": "3-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023364832643722,
        "to_latitude": 21.02338736563309,
        "to_longitude": 105.83464276807382,
        "from_longitude": 105.83477821962768,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7027,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8349056245673,
          21.023789203368036,
          105.83496731536331,
          21.02397071849982
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8349056245673,
            21.023789203368036
          ],
          [
            105.83496731536331,
            21.02397071849982
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 87 Văn Hương",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023789203368036,
        "to_latitude": 21.02397071849982,
        "to_longitude": 105.83496731536331,
        "from_longitude": 105.8349056245673,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7028,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83493378775758,
          21.02397071849982,
          105.83501023072311,
          21.02409464939415
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83496731536331,
            21.02397071849982
          ],
          [
            105.83501023072311,
            21.02404332448995
          ],
          [
            105.83493378775758,
            21.02409464939415
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 87 Văn Hương",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02397071849982,
        "to_latitude": 21.02409464939415,
        "to_longitude": 105.83493378775758,
        "from_longitude": 105.83496731536331,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7029,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83493378775758,
          21.02409464939415,
          105.83504966600385,
          21.0242543202547
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83493378775758,
            21.02409464939415
          ],
          [
            105.83504966600385,
            21.0242543202547
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 87 Văn Hương",
        "diemDau": "4-2",
        "diemCuoi": "4-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02409464939415,
        "to_latitude": 21.0242543202547,
        "to_longitude": 105.83504966600385,
        "from_longitude": 105.83493378775758,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7030,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83474335091805,
          21.02369656797344,
          105.8349056245673,
          21.023819247266463
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8349056245673,
            21.023789203368036
          ],
          [
            105.83475676196574,
            21.023819247266463
          ],
          [
            105.83474335091805,
            21.02369656797344
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 84 Văn Hương",
        "diemDau": "04",
        "diemCuoi": "4-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023789203368036,
        "to_latitude": 21.02369656797344,
        "to_longitude": 105.83474335091805,
        "from_longitude": 105.8349056245673,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7031,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83451536315218,
          21.023740382018556,
          105.83452206867153,
          21.023893105160692
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83452206867153,
            21.023893105160692
          ],
          [
            105.83451536315218,
            21.023740382018556
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 78 Văn Hương",
        "diemDau": "05",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023893105160692,
        "to_latitude": 21.023740382018556,
        "to_longitude": 105.83451536315218,
        "from_longitude": 105.83452206867153,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7032,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83452206867153,
          21.023829261898467,
          105.83464276808282,
          21.023893105160692
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83452206867153,
            21.023893105160692
          ],
          [
            105.83464276808282,
            21.023869320418132
          ],
          [
            105.83463874476222,
            21.023829261898467
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 80 Văn Hương",
        "diemDau": "05",
        "diemCuoi": "5-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023893105160692,
        "to_latitude": 21.023829261898467,
        "to_longitude": 105.83463874476222,
        "from_longitude": 105.83452206867153,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7033,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83452206867153,
          21.023893105160692,
          105.8346783073399,
          21.024031803234582
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83452206867153,
            21.023893105160692
          ],
          [
            105.83463203925186,
            21.023931911834048
          ],
          [
            105.8346783073399,
            21.024031803234582
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 71 Văn Hương",
        "diemDau": "05",
        "diemCuoi": "5-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023893105160692,
        "to_latitude": 21.024031803234582,
        "to_longitude": 105.8346783073399,
        "from_longitude": 105.83452206867153,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7034,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8346783073399,
          21.024031803234582,
          105.83475475029648,
          21.02413445303153
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8346783073399,
            21.024031803234582
          ],
          [
            105.83475475029648,
            21.02413445303153
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 71 Văn Hương",
        "diemDau": "5-3",
        "diemCuoi": "5-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.024031803234582,
        "to_latitude": 21.02413445303153,
        "to_longitude": 105.83475475029648,
        "from_longitude": 105.8346783073399,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7035,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8344509901394,
          21.023893105160692,
          105.83452206867153,
          21.024071861700662
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83452206867153,
            21.023893105160692
          ],
          [
            105.8344509901394,
            21.02396571118782
          ],
          [
            105.83452005701125,
            21.024071861700662
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 69 Văn Hương",
        "diemDau": "05",
        "diemCuoi": "5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023893105160692,
        "to_latitude": 21.024071861700662,
        "to_longitude": 105.83452005701125,
        "from_longitude": 105.83452206867153,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7036,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80186443012505,
          21.028680958899987,
          105.80209881051809,
          21.02879643631326
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80186443012505,
            21.02879643631326
          ],
          [
            105.80205146951819,
            21.028680958899987
          ],
          [
            105.80209881051809,
            21.028753249648627
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 khu TTĐHGT",
        "diemDau": "12-17",
        "diemCuoi": "12-20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02879643631326,
        "to_latitude": 21.028753249648627,
        "to_longitude": 105.80209881051809,
        "from_longitude": 105.80186443012505,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7037,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80209881051809,
          21.028753249648627,
          105.80215607567402,
          21.02883868412693
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80209881051809,
            21.028753249648627
          ],
          [
            105.80215607567402,
            21.02883868412693
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 khu TTĐHGT",
        "diemDau": "12-20",
        "diemCuoi": "12-21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.028753249648627,
        "to_latitude": 21.02883868412693,
        "to_longitude": 105.80215607567402,
        "from_longitude": 105.80209881051809,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7038,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80215607567402,
          21.02883868412693,
          105.80224861188604,
          21.02898013597431
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80215607567402,
            21.02883868412693
          ],
          [
            105.80224861188604,
            21.02898013597431
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 khu TTĐHGT",
        "diemDau": "12-21",
        "diemCuoi": "12-22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02883868412693,
        "to_latitude": 21.02898013597431,
        "to_longitude": 105.80224861188604,
        "from_longitude": 105.80215607567402,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7039,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80224861188604,
          21.028906280602353,
          105.80236931128833,
          21.02898013597431
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80224861188604,
            21.02898013597431
          ],
          [
            105.80236931128833,
            21.028906280602353
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 khu TTĐHGT",
        "diemDau": "12-22",
        "diemCuoi": "12-23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02898013597431,
        "to_latitude": 21.028906280602353,
        "to_longitude": 105.80236931128833,
        "from_longitude": 105.80224861188604,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7040,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83423239010065,
          21.024187284541398,
          105.83435174839009,
          21.02433349329052
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83423239010065,
            21.024187284541398
          ],
          [
            105.83429408091466,
            21.024217954271272
          ],
          [
            105.83435174839009,
            21.02433349329052
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 53 Văn Hương",
        "diemDau": "07",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.024187284541398,
        "to_latitude": 21.02433349329052,
        "to_longitude": 105.83435174839009,
        "from_longitude": 105.83423239010065,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7041,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83435174839009,
          21.02433349329052,
          105.83469909446336,
          21.02452627416819
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83435174839009,
            21.02433349329052
          ],
          [
            105.83449390549023,
            21.024474578310688
          ],
          [
            105.83469909446336,
            21.02452627416819
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 53 Văn Hương",
        "diemDau": "7-1",
        "diemCuoi": "7-",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02433349329052,
        "to_latitude": 21.02452627416819,
        "to_longitude": 105.83469909446336,
        "from_longitude": 105.83435174839009,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7042,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83469909446336,
          21.02452627416819,
          105.83476749078773,
          21.024663974642543
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83469909446336,
            21.02452627416819
          ],
          [
            105.83471921104838,
            21.024578479625625
          ],
          [
            105.83473932761542,
            21.024580983271363
          ],
          [
            105.83476749078773,
            21.024663974642543
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 53 Văn Hương",
        "diemDau": "7-3",
        "diemCuoi": "VH5 7-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02452627416819,
        "to_latitude": 21.024663974642543,
        "to_longitude": 105.83476749078773,
        "from_longitude": 105.83469909446336,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7043,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83394539373752,
          21.024335625837306,
          105.83417606370213,
          21.024450167752672
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83394539373752,
            21.024450167752672
          ],
          [
            105.83411705512283,
            21.024335625837306
          ],
          [
            105.83417606370213,
            21.02441361004979
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 51,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 47 Văn Hương",
        "diemDau": "08",
        "diemCuoi": "8-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.024450167752672,
        "to_latitude": 21.02441361004979,
        "to_longitude": 105.83417606370213,
        "from_longitude": 105.83394539373752,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7044,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83394539373752,
          21.024450167752672,
          105.83400297826537,
          21.02455741487544
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83394539373752,
            21.024450167752672
          ],
          [
            105.83400297826537,
            21.02455741487544
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 35 Văn Hương",
        "diemDau": "08",
        "diemCuoi": "8-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.024450167752672,
        "to_latitude": 21.02455741487544,
        "to_longitude": 105.83400297826537,
        "from_longitude": 105.83394539373752,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7045,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83395880477622,
          21.02455741487544,
          105.83400297826537,
          21.02465896735549
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83400297826537,
            21.02455741487544
          ],
          [
            105.83395880477622,
            21.02465896735549
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 35 Văn Hương",
        "diemDau": "8-1",
        "diemCuoi": "8-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02455741487544,
        "to_latitude": 21.02465896735549,
        "to_longitude": 105.83395880477622,
        "from_longitude": 105.83400297826537,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7046,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83385255867744,
          21.02465896735549,
          105.83395880477622,
          21.024675978761255
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83395880477622,
            21.02465896735549
          ],
          [
            105.83385255867744,
            21.024675978761255
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 35 Văn Hương",
        "diemDau": "8-2",
        "diemCuoi": "8-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02465896735549,
        "to_latitude": 21.024675978761255,
        "to_longitude": 105.83385255867744,
        "from_longitude": 105.83395880477622,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7047,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83395880477622,
          21.02465896735549,
          105.83400574342967,
          21.02475410579405
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83395880477622,
            21.02465896735549
          ],
          [
            105.83400574342967,
            21.02475410579405
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 35 Văn Hương",
        "diemDau": "8-2",
        "diemCuoi": "8-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02465896735549,
        "to_latitude": 21.02475410579405,
        "to_longitude": 105.83400574342967,
        "from_longitude": 105.83395880477622,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7048,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83400574342967,
          21.02465896735549,
          105.83413717167191,
          21.02475410579405
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83400574342967,
            21.02475410579405
          ],
          [
            105.83406609314879,
            21.024697402728957
          ],
          [
            105.83413717167191,
            21.02465896735549
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 35 Văn Hương",
        "diemDau": "8-3",
        "diemCuoi": "8-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02475410579405,
        "to_latitude": 21.02465896735549,
        "to_longitude": 105.83413717167191,
        "from_longitude": 105.83400574342967,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7049,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83413717167191,
          21.024498734059403,
          105.83441075698737,
          21.02465896735549
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83413717167191,
            21.02465896735549
          ],
          [
            105.83439332264602,
            21.024507125718763
          ],
          [
            105.83441075698737,
            21.024498734059403
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 35 Văn Hương",
        "diemDau": "8-4",
        "diemCuoi": "8-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02465896735549,
        "to_latitude": 21.024498734059403,
        "to_longitude": 105.83441075698737,
        "from_longitude": 105.83413717167191,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7050,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83395880477622,
          21.024600131712138,
          105.83407413976302,
          21.02465896735549
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83395880477622,
            21.02465896735549
          ],
          [
            105.83407413976302,
            21.024600131712138
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 35 Văn Hương",
        "diemDau": "8-2",
        "diemCuoi": "8-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02465896735549,
        "to_latitude": 21.024600131712138,
        "to_longitude": 105.83407413976302,
        "from_longitude": 105.83395880477622,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7051,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83395880477622,
          21.02453253328463,
          105.83422032014786,
          21.02465896735549
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83395880477622,
            21.02465896735549
          ],
          [
            105.83409559745193,
            21.024599760608858
          ],
          [
            105.83422032014786,
            21.02453253328463
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 35 Văn Hương",
        "diemDau": "8-2",
        "diemCuoi": "8-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02465896735549,
        "to_latitude": 21.02453253328463,
        "to_longitude": 105.83422032014786,
        "from_longitude": 105.83395880477622,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7052,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83363425749573,
          21.02452402533349,
          105.83367046730922,
          21.02461540839048
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83367046730922,
            21.02452402533349
          ],
          [
            105.83363425749573,
            21.024565961398846
          ],
          [
            105.83364230411894,
            21.02461540839048
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 23 Văn Hương",
        "diemDau": "09",
        "diemCuoi": "9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02452402533349,
        "to_latitude": 21.02461540839048,
        "to_longitude": 105.83364230411894,
        "from_longitude": 105.83367046730922,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7053,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83340224640925,
          21.02460414198947,
          105.83344784396782,
          21.024725568713976
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83340224640925,
            21.02460414198947
          ],
          [
            105.83341565746592,
            21.024631056167166
          ],
          [
            105.83344784396782,
            21.024725568713976
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 11 Văn Hương",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02460414198947,
        "to_latitude": 21.024725568713976,
        "to_longitude": 105.83344784396782,
        "from_longitude": 105.83340224640925,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7054,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8333056868946,
          21.02460414198947,
          105.83340224640925,
          21.024800677979364
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83340224640925,
            21.02460414198947
          ],
          [
            105.8333056868946,
            21.024663603540244
          ],
          [
            105.83334994334031,
            21.024800677979364
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 7A Văn Hương",
        "diemDau": "10",
        "diemCuoi": "10-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02460414198947,
        "to_latitude": 21.024800677979364,
        "to_longitude": 105.83334994334031,
        "from_longitude": 105.83340224640925,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7055,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83329629915852,
          21.024455175046736,
          105.83340224640925,
          21.02460414198947
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83340224640925,
            21.02460414198947
          ],
          [
            105.83334726113257,
            21.024583486916214
          ],
          [
            105.83329629915852,
            21.024455175046736
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 12 Văn Hương",
        "diemDau": "10",
        "diemCuoi": "10-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02460414198947,
        "to_latitude": 21.024455175046736,
        "to_longitude": 105.83329629915852,
        "from_longitude": 105.83340224640925,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7056,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83367046730922,
          21.02442137580395,
          105.83385553974223,
          21.02452402533349
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83367046730922,
            21.02452402533349
          ],
          [
            105.83385553974223,
            21.024468319193495
          ],
          [
            105.83383274095846,
            21.02442137580395
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 30 Văn Hương",
        "diemDau": "09",
        "diemCuoi": "9-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02452402533349,
        "to_latitude": 21.02442137580395,
        "to_longitude": 105.83383274095846,
        "from_longitude": 105.83367046730922,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7057,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83380055445656,
          21.024348769997093,
          105.83383274095846,
          21.02442137580395
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83383274095846,
            21.02442137580395
          ],
          [
            105.83380055445656,
            21.024348769997093
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 30 Văn Hương",
        "diemDau": "9-2",
        "diemCuoi": "9-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02442137580395,
        "to_latitude": 21.024348769997093,
        "to_longitude": 105.83380055445656,
        "from_longitude": 105.83383274095846,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7058,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83447513000911,
          21.024474578310688,
          105.83449390549023,
          21.024512504113584
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83449390549023,
            21.024474578310688
          ],
          [
            105.83447513000911,
            21.024512504113584
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 4,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "gách 53 Văn Hương",
        "diemDau": "7-1",
        "diemCuoi": "7-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.024474578310688,
        "to_latitude": 21.024512504113584,
        "to_longitude": 105.83447513000911,
        "from_longitude": 105.83449390549023,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7059,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83359402436162,
          21.02435252547061,
          105.83367046730922,
          21.02452402533349
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83367046730922,
            21.02452402533349
          ],
          [
            105.83359402436162,
            21.02435252547061
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "09",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02452402533349,
        "to_latitude": 21.02435252547061,
        "to_longitude": 105.83359402436162,
        "from_longitude": 105.83367046730922,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7060,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83358329552166,
          21.024214824708565,
          105.83367046730922,
          21.02452402533349
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83367046730922,
            21.02452402533349
          ],
          [
            105.83358329552166,
            21.024214824708565
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "09",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02452402533349,
        "to_latitude": 21.024214824708565,
        "to_longitude": 105.83358329552166,
        "from_longitude": 105.83367046730922,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7061,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83358329552166,
          21.02415223341144,
          105.83365973847825,
          21.024214824708565
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83358329552166,
            21.024214824708565
          ],
          [
            105.83365973847825,
            21.02415223341144
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "14",
        "diemCuoi": "14-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.024214824708565,
        "to_latitude": 21.02415223341144,
        "to_longitude": 105.83365973847825,
        "from_longitude": 105.83358329552166,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7062,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83365973847825,
          21.02404207266344,
          105.83386224526157,
          21.02415223341144
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83365973847825,
            21.02415223341144
          ],
          [
            105.83366644402454,
            21.024134081927933
          ],
          [
            105.83386224526157,
            21.02404207266344
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "14-1",
        "diemCuoi": "14-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02415223341144,
        "to_latitude": 21.02404207266344,
        "to_longitude": 105.83386224526157,
        "from_longitude": 105.83365973847825,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7063,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83386224526157,
          21.023930660006567,
          105.83404061215727,
          21.02404207266344
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83386224526157,
            21.02404207266344
          ],
          [
            105.83404061215727,
            21.023930660006567
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "14-2",
        "diemCuoi": "14-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02404207266344,
        "to_latitude": 21.023930660006567,
        "to_longitude": 105.83404061215727,
        "from_longitude": 105.83386224526157,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7064,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83358865994613,
          21.024020791600634,
          105.83365973847825,
          21.02415223341144
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83365973847825,
            21.02415223341144
          ],
          [
            105.83358865994613,
            21.024020791600634
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "14-1",
        "diemCuoi": "14-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02415223341144,
        "to_latitude": 21.024020791600634,
        "to_longitude": 105.83358865994613,
        "from_longitude": 105.83365973847825,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7065,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83358865994613,
          21.023936919147182,
          105.83380055445656,
          21.024020791600634
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83358865994613,
            21.024020791600634
          ],
          [
            105.83380055445656,
            21.023936919147182
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "14-6",
        "diemCuoi": "14-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.024020791600634,
        "to_latitude": 21.023936919147182,
        "to_longitude": 105.83380055445656,
        "from_longitude": 105.83358865994613,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7066,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83380055445656,
          21.023831765555947,
          105.83398696798447,
          21.023936919147182
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83380055445656,
            21.023936919147182
          ],
          [
            105.83398696798447,
            21.023831765555947
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "14-5",
        "diemCuoi": "14-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023936919147182,
        "to_latitude": 21.023831765555947,
        "to_longitude": 105.83398696798447,
        "from_longitude": 105.83380055445656,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7067,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83349478263024,
          21.02398198494871,
          105.83358329552166,
          21.024214824708565
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83358329552166,
            21.024214824708565
          ],
          [
            105.83354306241452,
            21.02417789584361
          ],
          [
            105.83349478263024,
            21.02398198494871
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.024214824708565,
        "to_latitude": 21.02398198494871,
        "to_longitude": 105.83349478263024,
        "from_longitude": 105.83358329552166,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7068,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83343040960852,
          21.02398198494871,
          105.83349478263024,
          21.023995755052304
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83349478263024,
            21.02398198494871
          ],
          [
            105.83343040960852,
            21.023995755052304
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "15",
        "diemCuoi": "15-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02398198494871,
        "to_latitude": 21.023995755052304,
        "to_longitude": 105.83343040960852,
        "from_longitude": 105.83349478263024,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7069,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83345052617555,
          21.02390311978692,
          105.83349478263024,
          21.02398198494871
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83349478263024,
            21.02398198494871
          ],
          [
            105.83345052617555,
            21.02390311978692
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "15",
        "diemCuoi": "15-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02398198494871,
        "to_latitude": 21.02390311978692,
        "to_longitude": 105.83345052617555,
        "from_longitude": 105.83349478263024,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7070,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83347064276954,
          21.023834269214195,
          105.83364632743954,
          21.02398198494871
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83349478263024,
            21.02398198494871
          ],
          [
            105.83347064276954,
            21.023892479243433
          ],
          [
            105.83362084647496,
            21.02383489512584
          ],
          [
            105.83364632743954,
            21.023834269214195
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "15",
        "diemCuoi": "15-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02398198494871,
        "to_latitude": 21.023834269214195,
        "to_longitude": 105.83364632743954,
        "from_longitude": 105.83349478263024,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7071,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83364632743954,
          21.023707834443055,
          105.83386358636547,
          21.023834269214195
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83364632743954,
            21.023834269214195
          ],
          [
            105.83367583176062,
            21.023792332937994
          ],
          [
            105.83386358636547,
            21.023707834443055
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "15-2",
        "diemCuoi": "15-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023834269214195,
        "to_latitude": 21.023707834443055,
        "to_longitude": 105.83386358636547,
        "from_longitude": 105.83364632743954,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7072,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83386358636547,
          21.023682171924918,
          105.83403792994953,
          21.02371346767586
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83386358636547,
            21.023707834443055
          ],
          [
            105.83390516063037,
            21.02371346767586
          ],
          [
            105.83396014591602,
            21.023682171924918
          ],
          [
            105.83403792994953,
            21.02368905699325
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "15-3",
        "diemCuoi": "15-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023707834443055,
        "to_latitude": 21.02368905699325,
        "to_longitude": 105.83403792994953,
        "from_longitude": 105.83386358636547,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7073,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83367583176062,
          21.023792332937994,
          105.83373618143482,
          21.023914386240897
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83367583176062,
            21.023792332937994
          ],
          [
            105.83373618143482,
            21.023914386240897
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "15-2",
        "diemCuoi": "15-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023792332937994,
        "to_latitude": 21.023914386240897,
        "to_longitude": 105.83373618143482,
        "from_longitude": 105.83367583176062,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7074,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83373618143482,
          21.023845535673416,
          105.83389443177245,
          21.023914386240897
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83373618143482,
            21.023914386240897
          ],
          [
            105.83389443177245,
            21.023845535673416
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "15-7",
        "diemCuoi": "18-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023914386240897,
        "to_latitude": 21.023845535673416,
        "to_longitude": 105.83389443177245,
        "from_longitude": 105.83373618143482,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7075,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83389443177245,
          21.023785447879476,
          105.83406341094103,
          21.023845535673416
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83389443177245,
            21.023845535673416
          ],
          [
            105.83406341094103,
            21.023785447879476
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "15-6",
        "diemCuoi": "15-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023845535673416,
        "to_latitude": 21.023785447879476,
        "to_longitude": 105.83406341094103,
        "from_longitude": 105.83389443177245,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7076,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83339151757829,
          21.023794210684297,
          105.83349478263024,
          21.02398198494871
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83349478263024,
            21.02398198494871
          ],
          [
            105.83343040963545,
            21.023898738384812
          ],
          [
            105.83339151757829,
            21.023794210684297
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "15",
        "diemCuoi": "VH5 16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02398198494871,
        "to_latitude": 21.023794210684297,
        "to_longitude": 105.83339151757829,
        "from_longitude": 105.83349478263024,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7077,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83339151757829,
          21.02370407895327,
          105.83361011761704,
          21.023794210684297
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83339151757829,
            21.023794210684297
          ],
          [
            105.83361011761704,
            21.02370407895327
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "VH5 16",
        "diemCuoi": "16-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023794210684297,
        "to_latitude": 21.02370407895327,
        "to_longitude": 105.83361011761704,
        "from_longitude": 105.83339151757829,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7078,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83361011761704,
          21.02357514040993,
          105.83383810538292,
          21.02370407895327
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83361011761704,
            21.02370407895327
          ],
          [
            105.83378312011523,
            21.023625839556946
          ],
          [
            105.83382067104158,
            21.02360455843471
          ],
          [
            105.83383810538292,
            21.02357514040993
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "16-1",
        "diemCuoi": "16-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02370407895327,
        "to_latitude": 21.02357514040993,
        "to_longitude": 105.83383810538292,
        "from_longitude": 105.83361011761704,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7079,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83383810538292,
          21.023503160104784,
          105.83403927105341,
          21.02357514040993
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83383810538292,
            21.02357514040993
          ],
          [
            105.83401915451331,
            21.023503160104784
          ],
          [
            105.83403927105341,
            21.02352381532774
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "16-2",
        "diemCuoi": "16-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02357514040993,
        "to_latitude": 21.02352381532774,
        "to_longitude": 105.83403927105341,
        "from_longitude": 105.83383810538292,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7080,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83398160355999,
          21.023397380293275,
          105.83403927105341,
          21.02352381532774
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83403927105341,
            21.02352381532774
          ],
          [
            105.83398160355999,
            21.023397380293275
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "16-4",
        "diemCuoi": "16-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02352381532774,
        "to_latitude": 21.023397380293275,
        "to_longitude": 105.83398160355999,
        "from_longitude": 105.83403927105341,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7081,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83398160355999,
          21.023332910900812,
          105.83418679255107,
          21.02344995725138
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83398160355999,
            21.023397380293275
          ],
          [
            105.83413448950012,
            21.023332910900812
          ],
          [
            105.83418679255107,
            21.02344995725138
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "16-5",
        "diemCuoi": "16-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023397380293275,
        "to_latitude": 21.02344995725138,
        "to_longitude": 105.83418679255107,
        "from_longitude": 105.83398160355999,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7082,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.833785802305,
          21.02347374206003,
          105.83383810538292,
          21.02357514040993
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83383810538292,
            21.02357514040993
          ],
          [
            105.833785802305,
            21.02347374206003
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "16-2",
        "diemCuoi": "16-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02357514040993,
        "to_latitude": 21.02347374206003,
        "to_longitude": 105.833785802305,
        "from_longitude": 105.83383810538292,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7083,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83334726113257,
          21.023656509406525,
          105.83339151757829,
          21.023794210684297
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83339151757829,
            21.023794210684297
          ],
          [
            105.83334726113257,
            21.023656509406525
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "VH5 16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023794210684297,
        "to_latitude": 21.023656509406525,
        "to_longitude": 105.83334726113257,
        "from_longitude": 105.83339151757829,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7084,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83334726113257,
          21.023611443506613,
          105.83345723170389,
          21.023656509406525
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83334726113257,
            21.023656509406525
          ],
          [
            105.83345723170389,
            21.023611443506613
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "17",
        "diemCuoi": "17-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023656509406525,
        "to_latitude": 21.023611443506613,
        "to_longitude": 105.83345723170389,
        "from_longitude": 105.83334726113257,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7085,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83334726113257,
          21.023492519536973,
          105.83343074492267,
          21.023656509406525
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83334726113257,
            21.023656509406525
          ],
          [
            105.83343074492267,
            21.023605810265767
          ],
          [
            105.83338212985119,
            21.023492519536973
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023656509406525,
        "to_latitude": 21.023492519536973,
        "to_longitude": 105.83338212985119,
        "from_longitude": 105.83334726113257,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7086,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83326411264764,
          21.023347932897597,
          105.83338212985119,
          21.023492519536973
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83338212985119,
            21.023492519536973
          ],
          [
            105.83337944766141,
            21.023453086829313
          ],
          [
            105.83331775685639,
            21.023347932897597
          ],
          [
            105.83326411264764,
            21.023376099138403
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023492519536973,
        "to_latitude": 21.023376099138403,
        "to_longitude": 105.83326411264764,
        "from_longitude": 105.83338212985119,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7087,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83919791859111,
          21.00805225327099,
          105.83921937627102,
          21.008083552301137
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83919791859111,
            21.00805225327099
          ],
          [
            105.83921937627102,
            21.008083552301137
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "",
        "tuDien2": "LKL2",
        "from_latitude": 21.00805225327099,
        "to_latitude": 21.008083552301137,
        "to_longitude": 105.83921937627102,
        "from_longitude": 105.83919791859111,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7088,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83919791859111,
          21.008018450309653,
          105.83948759717099,
          21.00805225327099
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83919791859111,
            21.00805225327099
          ],
          [
            105.83948759717099,
            21.008018450309653
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "02",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LKL2",
        "from_latitude": 21.00805225327099,
        "to_latitude": 21.008018450309653,
        "to_longitude": 105.83948759717099,
        "from_longitude": 105.83919791859111,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7089,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83896188421097,
          21.00805225327099,
          105.83919791859111,
          21.00816117386915
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83919791859111,
            21.00805225327099
          ],
          [
            105.83896188421097,
            21.00816117386915
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LKL2",
        "from_latitude": 21.00805225327099,
        "to_latitude": 21.00816117386915,
        "to_longitude": 105.83896188421097,
        "from_longitude": 105.83919791859111,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7090,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83873121422843,
          21.00816117386915,
          105.83896188421097,
          21.008381518745495
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83896188421097,
            21.00816117386915
          ],
          [
            105.83873121422843,
            21.008381518745495
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LKL2",
        "from_latitude": 21.00816117386915,
        "to_latitude": 21.008381518745495,
        "to_longitude": 105.83873121422843,
        "from_longitude": 105.83896188421097,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7091,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83845762891295,
          21.008381518745495,
          105.83873121422843,
          21.008681988506602
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83873121422843,
            21.008381518745495
          ],
          [
            105.83845762891295,
            21.008681988506602
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LKL2",
        "from_latitude": 21.008381518745495,
        "to_latitude": 21.008681988506602,
        "to_longitude": 105.83845762891295,
        "from_longitude": 105.83873121422843,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7092,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83822695893934,
          21.008681988506602,
          105.83845762891295,
          21.008907340429875
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83845762891295,
            21.008681988506602
          ],
          [
            105.83822695893934,
            21.008907340429875
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LKL2",
        "from_latitude": 21.008681988506602,
        "to_latitude": 21.008907340429875,
        "to_longitude": 105.83822695893934,
        "from_longitude": 105.83845762891295,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7093,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83803115767539,
          21.008907340429875,
          105.83822695893934,
          21.00911766858488
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83822695893934,
            21.008907340429875
          ],
          [
            105.83803115767539,
            21.00911766858488
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LKL2",
        "from_latitude": 21.008907340429875,
        "to_latitude": 21.00911766858488,
        "to_longitude": 105.83803115767539,
        "from_longitude": 105.83822695893934,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7094,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83771465701808,
          21.00911766858488,
          105.83803115767539,
          21.0094632070534
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83803115767539,
            21.00911766858488
          ],
          [
            105.83771465701808,
            21.0094632070534
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LKL2",
        "from_latitude": 21.00911766858488,
        "to_latitude": 21.0094632070534,
        "to_longitude": 105.83771465701808,
        "from_longitude": 105.83803115767539,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7095,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8375269023863,
          21.0094632070534,
          105.83771465701808,
          21.009635975986686
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83771465701808,
            21.0094632070534
          ],
          [
            105.8375269023863,
            21.009635975986686
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LKL2",
        "from_latitude": 21.0094632070534,
        "to_latitude": 21.009635975986686,
        "to_longitude": 105.8375269023863,
        "from_longitude": 105.83771465701808,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7096,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83740620297505,
          21.009635975986686,
          105.8375269023863,
          21.00976868242404
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8375269023863,
            21.009635975986686
          ],
          [
            105.83740620297505,
            21.00976868242404
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LKL2",
        "from_latitude": 21.009635975986686,
        "to_latitude": 21.00976868242404,
        "to_longitude": 105.83740620297505,
        "from_longitude": 105.8375269023863,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7097,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83729355019598,
          21.00976868242404,
          105.83740620297505,
          21.009893877066602
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83740620297505,
            21.00976868242404
          ],
          [
            105.83729355019598,
            21.009893877066602
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LKL2",
        "from_latitude": 21.00976868242404,
        "to_latitude": 21.009893877066602,
        "to_longitude": 105.83729355019598,
        "from_longitude": 105.83740620297505,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7098,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83351959305635,
          21.01697221128019,
          105.83370734768812,
          21.01704106502165
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83370734768812,
            21.01697221128019
          ],
          [
            105.83351959305635,
            21.01704106502165
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01697221128019,
        "to_latitude": 21.01704106502165,
        "to_longitude": 105.83351959305635,
        "from_longitude": 105.83370734768812,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7099,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83370734768812,
          21.01697221128019,
          105.83386962134634,
          21.01715498659465
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83370734768812,
            21.01697221128019
          ],
          [
            105.83386962134634,
            21.01715498659465
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 79 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01697221128019,
        "to_latitude": 21.01715498659465,
        "to_longitude": 105.83386962134634,
        "from_longitude": 105.83370734768812,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7100,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83341364581462,
          21.01704106502165,
          105.83351959305635,
          21.01709990364629
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83351959305635,
            21.01704106502165
          ],
          [
            105.83345656114746,
            21.017094896106894
          ],
          [
            105.83341364581462,
            21.01709990364629
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01704106502165,
        "to_latitude": 21.01709990364629,
        "to_longitude": 105.83341364581462,
        "from_longitude": 105.83351959305635,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7101,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83332781512195,
          21.01682949614939,
          105.83351959305635,
          21.01704106502165
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83351959305635,
            21.01704106502165
          ],
          [
            105.83332781512195,
            21.01682949614939
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 62 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01704106502165,
        "to_latitude": 21.01682949614939,
        "to_longitude": 105.83332781512195,
        "from_longitude": 105.83351959305635,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7102,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83322589118283,
          21.01674436776202,
          105.83332781512195,
          21.01682949614939
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83332781512195,
            21.01682949614939
          ],
          [
            105.83322589118283,
            21.01674436776202
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 62 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01682949614939,
        "to_latitude": 21.01674436776202,
        "to_longitude": 105.83322589118283,
        "from_longitude": 105.83332781512195,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7103,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83306495863746,
          21.01674436776202,
          105.83322589118283,
          21.016874564099194
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83322589118283,
            21.01674436776202
          ],
          [
            105.83306495863746,
            21.016874564099194
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 62 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01674436776202,
        "to_latitude": 21.016874564099194,
        "to_longitude": 105.83306495863746,
        "from_longitude": 105.83322589118283,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7104,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83294157702744,
          21.01681447349588,
          105.83306495863746,
          21.016874564099194
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83306495863746,
            21.016874564099194
          ],
          [
            105.83294157702744,
            21.01681447349588
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 62 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016874564099194,
        "to_latitude": 21.01681447349588,
        "to_longitude": 105.83294157702744,
        "from_longitude": 105.83306495863746,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7105,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83280210216198,
          21.01681447349588,
          105.83294157702744,
          21.016944669772688
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83294157702744,
            21.01681447349588
          ],
          [
            105.83280210216198,
            21.016944669772688
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 62 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01681447349588,
        "to_latitude": 21.016944669772688,
        "to_longitude": 105.83280210216198,
        "from_longitude": 105.83294157702744,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7106,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83280210216198,
          21.016944669772688,
          105.83290000278947,
          21.016985982030356
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83280210216198,
            21.016944669772688
          ],
          [
            105.83290000278947,
            21.016985982030356
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 62 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016944669772688,
        "to_latitude": 21.016985982030356,
        "to_longitude": 105.83290000278947,
        "from_longitude": 105.83280210216198,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7107,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83330099303555,
          21.016478967182405,
          105.83345119674097,
          21.01664421665537
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83345119674097,
            21.016478967182405
          ],
          [
            105.83331038075366,
            21.016600400527537
          ],
          [
            105.83330099303555,
            21.01664421665537
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Trạm",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016478967182405,
        "to_latitude": 21.01664421665537,
        "to_longitude": 105.83330099303555,
        "from_longitude": 105.83345119674097,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7108,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83324734885375,
          21.016248619127566,
          105.83345119674097,
          21.016478967182405
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83345119674097,
            21.016478967182405
          ],
          [
            105.83324734885375,
            21.016248619127566
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Trạm",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016478967182405,
        "to_latitude": 21.016248619127566,
        "to_longitude": 105.83324734885375,
        "from_longitude": 105.83345119674097,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7109,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83317761141653,
          21.01620355098862,
          105.83324734885375,
          21.016248619127566
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83324734885375,
            21.016248619127566
          ],
          [
            105.83317761141653,
            21.01620355098862
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Trạm",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016248619127566,
        "to_latitude": 21.01620355098862,
        "to_longitude": 105.83317761141653,
        "from_longitude": 105.83324734885375,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7110,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8330917807149,
          21.01620355098862,
          105.83317761141653,
          21.016378815900893
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83317761141653,
            21.01620355098862
          ],
          [
            105.8330917807149,
            21.016281168333013
          ],
          [
            105.83311592060252,
            21.016378815900893
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Trạm",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01620355098862,
        "to_latitude": 21.016378815900893,
        "to_longitude": 105.83311592060252,
        "from_longitude": 105.83317761141653,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7111,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83324734885375,
          21.01614346011495,
          105.83333854396191,
          21.016248619127566
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83324734885375,
            21.016248619127566
          ],
          [
            105.83333854396191,
            21.01614346011495
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Trạm",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016248619127566,
        "to_latitude": 21.01614346011495,
        "to_longitude": 105.83333854396191,
        "from_longitude": 105.83324734885375,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7112,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83333854396191,
          21.016003247981665,
          105.83347265441189,
          21.01614346011495
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83333854396191,
            21.01614346011495
          ],
          [
            105.83347265441189,
            21.016003247981665
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Trạm",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01614346011495,
        "to_latitude": 21.016003247981665,
        "to_longitude": 105.83347265441189,
        "from_longitude": 105.83333854396191,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7113,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83347265441189,
          21.016003247981665,
          105.83360676486186,
          21.01608336921708
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83347265441189,
            21.016003247981665
          ],
          [
            105.83360676486186,
            21.01608336921708
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Trạm",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016003247981665,
        "to_latitude": 21.01608336921708,
        "to_longitude": 105.83360676486186,
        "from_longitude": 105.83347265441189,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7114,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83345119674097,
          21.016478967182405,
          105.83365504462817,
          21.016674261994357
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83345119674097,
            21.016478967182405
          ],
          [
            105.83365504462817,
            21.016674261994357
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Trạm",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016478967182405,
        "to_latitude": 21.016674261994357,
        "to_longitude": 105.83365504462817,
        "from_longitude": 105.83345119674097,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7115,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83370734768812,
          21.016855785791297,
          105.83381597715558,
          21.01697221128019
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83370734768812,
            21.01697221128019
          ],
          [
            105.83381597715558,
            21.016855785791297
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01697221128019,
        "to_latitude": 21.016855785791297,
        "to_longitude": 105.83381597715558,
        "from_longitude": 105.83370734768812,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7116,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83378647285245,
          21.01682699237602,
          105.83381597715558,
          21.016855785791297
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83381597715558,
            21.016855785791297
          ],
          [
            105.83378647285245,
            21.01682699237602
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016855785791297,
        "to_latitude": 21.01682699237602,
        "to_longitude": 105.83378647285245,
        "from_longitude": 105.83381597715558,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7117,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83365504462817,
          21.016674261994357,
          105.83378647285245,
          21.01682699237602
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83378647285245,
            21.01682699237602
          ],
          [
            105.83365504462817,
            21.016674261994357
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Trạm",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01682699237602,
        "to_latitude": 21.016674261994357,
        "to_longitude": 105.83365504462817,
        "from_longitude": 105.83378647285245,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7118,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83378647285245,
          21.016714322437178,
          105.83392862994361,
          21.01682699237602
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83378647285245,
            21.01682699237602
          ],
          [
            105.83392862994361,
            21.016714322437178
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01682699237602,
        "to_latitude": 21.016714322437178,
        "to_longitude": 105.83392862994361,
        "from_longitude": 105.83378647285245,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7119,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83392862994361,
          21.016498997431817,
          105.8341914864281,
          21.016714322437178
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83392862994361,
            21.016714322437178
          ],
          [
            105.8341914864281,
            21.016498997431817
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016714322437178,
        "to_latitude": 21.016498997431817,
        "to_longitude": 105.8341914864281,
        "from_longitude": 105.83392862994361,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7120,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83407346923356,
          21.016498997431817,
          105.8341914864281,
          21.016574110843138
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8341914864281,
            21.016498997431817
          ],
          [
            105.8340774925272,
            21.016574110843138
          ],
          [
            105.83407346923356,
            21.01655408060214
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 102 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016498997431817,
        "to_latitude": 21.01655408060214,
        "to_longitude": 105.83407346923356,
        "from_longitude": 105.8341914864281,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7121,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83391253668819,
          21.016442662351636,
          105.83407346923356,
          21.01655408060214
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83407346923356,
            21.01655408060214
          ],
          [
            105.83395679314287,
            21.016442662351636
          ],
          [
            105.83391253668819,
            21.016468952057526
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 102 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01655408060214,
        "to_latitude": 21.016468952057526,
        "to_longitude": 105.83391253668819,
        "from_longitude": 105.83407346923356,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7122,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8341914864281,
          21.016498997431817,
          105.83430279808532,
          21.01667175821834
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8341914864281,
            21.016498997431817
          ],
          [
            105.83430279808532,
            21.01667175821834
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 107 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016498997431817,
        "to_latitude": 21.01667175821834,
        "to_longitude": 105.83430279808532,
        "from_longitude": 105.8341914864281,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7123,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83430279808532,
          21.01667175821834,
          105.8343738766444,
          21.01681447349588
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83430279808532,
            21.01667175821834
          ],
          [
            105.8343738766444,
            21.01681447349588
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 107 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01667175821834,
        "to_latitude": 21.01681447349588,
        "to_longitude": 105.8343738766444,
        "from_longitude": 105.83430279808532,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7124,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8343738766444,
          21.016811969723083,
          105.83451335150986,
          21.016979722597092
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8343738766444,
            21.01681447349588
          ],
          [
            105.83442081527987,
            21.016811969723083
          ],
          [
            105.83451335150986,
            21.016979722597092
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 107 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01681447349588,
        "to_latitude": 21.016979722597092,
        "to_longitude": 105.83451335150986,
        "from_longitude": 105.8343738766444,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7125,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83451335150986,
          21.016979722597092,
          105.83469037730167,
          21.01703480558985
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83451335150986,
            21.016979722597092
          ],
          [
            105.83469037730167,
            21.01703480558985
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 107 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016979722597092,
        "to_latitude": 21.01703480558985,
        "to_longitude": 105.83469037730167,
        "from_longitude": 105.83451335150986,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7126,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83424513060989,
          21.016811969723083,
          105.83445970731907,
          21.017009767868462
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8343738766444,
            21.01681447349588
          ],
          [
            105.83442081527987,
            21.016811969723083
          ],
          [
            105.83445970731907,
            21.01693465468076
          ],
          [
            105.83436314778648,
            21.016965951848036
          ],
          [
            105.83429877477371,
            21.016958440528754
          ],
          [
            105.83424513060989,
            21.017009767868462
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 107 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01681447349588,
        "to_latitude": 21.017009767868462,
        "to_longitude": 105.83424513060989,
        "from_longitude": 105.8343738766444,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7127,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8341914864281,
          21.016440158570102,
          105.83427061158343,
          21.016498997431817
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8341914864281,
            21.016498997431817
          ],
          [
            105.83427061158343,
            21.016440158570102
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016498997431817,
        "to_latitude": 21.016440158570102,
        "to_longitude": 105.83427061158343,
        "from_longitude": 105.8341914864281,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7128,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83422903735445,
          21.016358785632836,
          105.83427061158343,
          21.016440158570102
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83427061158343,
            21.016440158570102
          ],
          [
            105.83422903735445,
            21.016358785632836
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 114 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016440158570102,
        "to_latitude": 21.016358785632836,
        "to_longitude": 105.83422903735445,
        "from_longitude": 105.83427061158343,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7129,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83413247783083,
          21.01620355098862,
          105.83422903735445,
          21.016358785632836
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83422903735445,
            21.016358785632836
          ],
          [
            105.83413247783083,
            21.01620355098862
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 114 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016358785632836,
        "to_latitude": 21.01620355098862,
        "to_longitude": 105.83413247783083,
        "from_longitude": 105.83422903735445,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7130,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83399836738084,
          21.01620355098862,
          105.83413247783083,
          21.016278664546235
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83413247783083,
            21.01620355098862
          ],
          [
            105.83399836738084,
            21.016278664546235
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 114 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01620355098862,
        "to_latitude": 21.016278664546235,
        "to_longitude": 105.83399836738084,
        "from_longitude": 105.83413247783083,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7131,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83413247783083,
          21.016048316181994,
          105.8341888042024,
          21.01620355098862
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83413247783083,
            21.01620355098862
          ],
          [
            105.8341888042024,
            21.016157230944156
          ],
          [
            105.83413247783083,
            21.016048316181994
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 114 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01620355098862,
        "to_latitude": 21.016048316181994,
        "to_longitude": 105.83413247783083,
        "from_longitude": 105.83413247783083,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7132,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83413247783083,
          21.015988225245792,
          105.83438996989084,
          21.01620355098862
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83413247783083,
            21.01620355098862
          ],
          [
            105.83435510116324,
            21.016059583232042
          ],
          [
            105.83438996989084,
            21.015988225245792
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 114 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01620355098862,
        "to_latitude": 21.015988225245792,
        "to_longitude": 105.83438996989084,
        "from_longitude": 105.83413247783083,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7133,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83425585944084,
          21.015782914363687,
          105.83438996989084,
          21.015988225245792
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83438996989084,
            21.015988225245792
          ],
          [
            105.83425585944084,
            21.015782914363687
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 114 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.015988225245792,
        "to_latitude": 21.015782914363687,
        "to_longitude": 105.83425585944084,
        "from_longitude": 105.83438996989084,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7134,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83415929991723,
          21.015782914363687,
          105.83425585944084,
          21.01579793712111
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83425585944084,
            21.015782914363687
          ],
          [
            105.83415929991723,
            21.01579793712111
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 114 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.015782914363687,
        "to_latitude": 21.01579793712111,
        "to_longitude": 105.83415929991723,
        "from_longitude": 105.83425585944084,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7135,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83404128272268,
          21.01562767912005,
          105.83415929991723,
          21.01579793712111
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83415929991723,
            21.01579793712111
          ],
          [
            105.83404128272268,
            21.01562767912005
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 114 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01579793712111,
        "to_latitude": 21.01562767912005,
        "to_longitude": 105.83404128272268,
        "from_longitude": 105.83415929991723,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7136,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83427061158343,
          21.016353778066836,
          105.83441142757076,
          21.016440158570102
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83427061158343,
            21.016440158570102
          ],
          [
            105.83441142757076,
            21.016353778066836
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016440158570102,
        "to_latitude": 21.016353778066836,
        "to_longitude": 105.83441142757076,
        "from_longitude": 105.83427061158343,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7137,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83441142757076,
          21.016353778066836,
          105.83453480918078,
          21.016483974745015
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83441142757076,
            21.016353778066836
          ],
          [
            105.83453480918078,
            21.016483974745015
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 119 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016353778066836,
        "to_latitude": 21.016483974745015,
        "to_longitude": 105.83453480918078,
        "from_longitude": 105.83441142757076,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7138,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83453480918078,
          21.016403853725688,
          105.83483521659161,
          21.016483974745015
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83453480918078,
            21.016483974745015
          ],
          [
            105.83483521659161,
            21.016403853725688
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 119 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016483974745015,
        "to_latitude": 21.016403853725688,
        "to_longitude": 105.83483521659161,
        "from_longitude": 105.83453480918078,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7139,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83441142757076,
          21.01608336921708,
          105.83469574172612,
          21.016353778066836
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83441142757076,
            21.016353778066836
          ],
          [
            105.83469574172612,
            21.01608336921708
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 54,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016353778066836,
        "to_latitude": 21.01608336921708,
        "to_longitude": 105.83469574172612,
        "from_longitude": 105.83441142757076,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7140,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83483521659161,
          21.016403853725688,
          105.83506588656523,
          21.016574110840644
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83483521659161,
            21.016403853725688
          ],
          [
            105.83506588656523,
            21.016574110840644
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 119 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016403853725688,
        "to_latitude": 21.016574110840644,
        "to_longitude": 105.83506588656523,
        "from_longitude": 105.83483521659161,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7141,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83506588656523,
          21.016574110840644,
          105.83513025958695,
          21.016714322437178
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83506588656523,
            21.016574110840644
          ],
          [
            105.83513025958695,
            21.016714322437178
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 119 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016574110840644,
        "to_latitude": 21.016714322437178,
        "to_longitude": 105.83513025958695,
        "from_longitude": 105.83506588656523,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7142,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83513025958695,
          21.016714322437178,
          105.83521072585515,
          21.016874564099194
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83513025958695,
            21.016714322437178
          ],
          [
            105.83521072585515,
            21.016874564099194
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 119 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016714322437178,
        "to_latitude": 21.016874564099194,
        "to_longitude": 105.83521072585515,
        "from_longitude": 105.83513025958695,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7143,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83506588656523,
          21.016506508776747,
          105.83524827678151,
          21.01658913351835
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83506588656523,
            21.016574110840644
          ],
          [
            105.8351879270624,
            21.016506508776747
          ],
          [
            105.83524827678151,
            21.01658913351835
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 119 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016574110840644,
        "to_latitude": 21.01658913351835,
        "to_longitude": 105.83524827678151,
        "from_longitude": 105.83506588656523,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7144,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83506588656523,
          21.016338755365414,
          105.83538775164698,
          21.016574110840644
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83506588656523,
            21.016574110840644
          ],
          [
            105.83520938473332,
            21.016487730419183
          ],
          [
            105.83538775164698,
            21.016338755365414
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 119 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016574110840644,
        "to_latitude": 21.016338755365414,
        "to_longitude": 105.83538775164698,
        "from_longitude": 105.83506588656523,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7145,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83538775164698,
          21.016338755365414,
          105.83558623510072,
          21.016587881630883
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83538775164698,
            21.016338755365414
          ],
          [
            105.83558623510072,
            21.016587881630883
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 119 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.016338755365414,
        "to_latitude": 21.016587881630883,
        "to_longitude": 105.83558623510072,
        "from_longitude": 105.83538775164698,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7146,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83422635512875,
          21.017009767868462,
          105.83433632571803,
          21.01714997905459
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83424513060989,
            21.017009767868462
          ],
          [
            105.83422635512875,
            21.017037309363946
          ],
          [
            105.83433632571803,
            21.01714997905459
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 107 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.017009767868462,
        "to_latitude": 21.01714997905459,
        "to_longitude": 105.83433632571803,
        "from_longitude": 105.83424513060989,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7147,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83386962134634,
          21.01715498659465,
          105.83399836738084,
          21.01730521271445
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83386962134634,
            21.01715498659465
          ],
          [
            105.83399836738084,
            21.01730521271445
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 79 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01715498659465,
        "to_latitude": 21.01730521271445,
        "to_longitude": 105.83399836738084,
        "from_longitude": 105.83386962134634,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7148,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83399836738084,
          21.01730521271445,
          105.83414320666179,
          21.01737531818552
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83399836738084,
            21.01730521271445
          ],
          [
            105.83414320666179,
            21.01737531818552
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 79 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01730521271445,
        "to_latitude": 21.01737531818552,
        "to_longitude": 105.83414320666179,
        "from_longitude": 105.83399836738084,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7149,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83414320666179,
          21.01726515243035,
          105.83427195269628,
          21.01737531818552
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83414320666179,
            21.01737531818552
          ],
          [
            105.83427195269628,
            21.01726515243035
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 79 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01737531818552,
        "to_latitude": 21.01726515243035,
        "to_longitude": 105.83427195269628,
        "from_longitude": 105.83414320666179,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7150,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83427195269628,
          21.01721507706074,
          105.83440606314628,
          21.01726515243035
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83427195269628,
            21.01726515243035
          ],
          [
            105.83440606314628,
            21.01721507706074
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 79 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01726515243035,
        "to_latitude": 21.01721507706074,
        "to_longitude": 105.83440606314628,
        "from_longitude": 105.83427195269628,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7151,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83440606314628,
          21.01721507706074,
          105.8345509024362,
          21.017235107210443
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83440606314628,
            21.01721507706074
          ],
          [
            105.8345509024362,
            21.017235107210443
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 79 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01721507706074,
        "to_latitude": 21.017235107210443,
        "to_longitude": 105.8345509024362,
        "from_longitude": 105.83440606314628,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7152,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83414320666179,
          21.017345272987786,
          105.83441679198621,
          21.01737531818552
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83414320666179,
            21.01737531818552
          ],
          [
            105.83441679198621,
            21.017345272987786
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 79 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01737531818552,
        "to_latitude": 21.017345272987786,
        "to_longitude": 105.83441679198621,
        "from_longitude": 105.83414320666179,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7153,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83441679198621,
          21.017345272987786,
          105.83449725824542,
          21.0174829800961
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83441679198621,
            21.017345272987786
          ],
          [
            105.83449725824542,
            21.0174829800961
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 79 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.017345272987786,
        "to_latitude": 21.0174829800961,
        "to_longitude": 105.83449725824542,
        "from_longitude": 105.83441679198621,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7154,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83442752080823,
          21.0174829800961,
          105.83449725824542,
          21.0175305516125
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83449725824542,
            21.0174829800961
          ],
          [
            105.83442752080823,
            21.0175305516125
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 79 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.0174829800961,
        "to_latitude": 21.0175305516125,
        "to_longitude": 105.83442752080823,
        "from_longitude": 105.83449725824542,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7155,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83442752080823,
          21.0175305516125,
          105.83466891963074,
          21.017613175789236
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83442752080823,
            21.0175305516125
          ],
          [
            105.83448921161322,
            21.017613175789236
          ],
          [
            105.83466891963074,
            21.017545574191367
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 79 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.0175305516125,
        "to_latitude": 21.017545574191367,
        "to_longitude": 105.83466891963074,
        "from_longitude": 105.83442752080823,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7156,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83466891963074,
          21.01742038597034,
          105.83499078471253,
          21.017545574191367
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83466891963074,
            21.017545574191367
          ],
          [
            105.83469842391591,
            21.01751803279395
          ],
          [
            105.83499078471253,
            21.01742038597034
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 79 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.017545574191367,
        "to_latitude": 21.01742038597034,
        "to_longitude": 105.83499078471253,
        "from_longitude": 105.83466891963074,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7157,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83499078471253,
          21.017365303120005,
          105.83510880190704,
          21.01742038597034
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83499078471253,
            21.01742038597034
          ],
          [
            105.83510880190704,
            21.017365303120005
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 79 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01742038597034,
        "to_latitude": 21.017365303120005,
        "to_longitude": 105.83510880190704,
        "from_longitude": 105.83499078471253,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7158,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83341364581462,
          21.01709990364629,
          105.83356384952002,
          21.01720005444607
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83341364581462,
            21.01709990364629
          ],
          [
            105.83356384952002,
            21.01720005444607
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 77 Thổ Quan",
        "diemDau": "2-4",
        "diemCuoi": "2-5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01709990364629,
        "to_latitude": 21.01720005444607,
        "to_longitude": 105.83356384952002,
        "from_longitude": 105.83341364581462,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7159,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83341364581462,
          21.01709990364629,
          105.83356921393552,
          21.017370310652815
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83341364581462,
            21.01709990364629
          ],
          [
            105.83355848508657,
            21.01724762605525
          ],
          [
            105.83356921393552,
            21.017370310652815
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 77 Thổ Quan",
        "diemDau": "2-4",
        "diemCuoi": "2-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01709990364629,
        "to_latitude": 21.017370310652815,
        "to_longitude": 105.83356921393552,
        "from_longitude": 105.83341364581462,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7160,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83356921393552,
          21.017370310652815,
          105.83362822253278,
          21.017530551610818
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83356921393552,
            21.017370310652815
          ],
          [
            105.83362822253278,
            21.017530551610818
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 77 Thổ Quan",
        "diemDau": "2-1",
        "diemCuoi": "2-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.017370310652815,
        "to_latitude": 21.017530551610818,
        "to_longitude": 105.83362822253278,
        "from_longitude": 105.83356921393552,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7161,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83356921393552,
          21.017370310652815,
          105.8337033243855,
          21.01742069210602
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83356921393552,
            21.017370310652815
          ],
          [
            105.83358776685621,
            21.01742069210602
          ],
          [
            105.8337033243855,
            21.017370310652815
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 77 Thổ Quan",
        "diemDau": "2-1",
        "diemCuoi": "2-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.017370310652815,
        "to_latitude": 21.017370310652815,
        "to_longitude": 105.8337033243855,
        "from_longitude": 105.83356921393552,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7162,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83333854396191,
          21.01709990364629,
          105.83341364581462,
          21.017195046908352
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83341364581462,
            21.01709990364629
          ],
          [
            105.8334109635889,
            21.01714246774625
          ],
          [
            105.83333854396191,
            21.017195046908352
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "diemDau": "2-4",
        "diemCuoi": "2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01709990364629,
        "to_latitude": 21.017195046908352,
        "to_longitude": 105.83333854396191,
        "from_longitude": 105.83341364581462,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7163,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83319370466299,
          21.017195046908352,
          105.83333854396191,
          21.017382829485474
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83333854396191,
            21.017195046908352
          ],
          [
            105.83319370466299,
            21.017382829485474
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.017195046908352,
        "to_latitude": 21.017382829485474,
        "to_longitude": 105.83319370466299,
        "from_longitude": 105.83333854396191,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7164,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83312933165921,
          21.017382829485474,
          105.83319370466299,
          21.017470461271873
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83312933165921,
            21.017470461271873
          ],
          [
            105.83319370466299,
            21.017382829485474
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.017470461271873,
        "to_latitude": 21.017382829485474,
        "to_longitude": 105.83319370466299,
        "from_longitude": 105.83312933165921,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7165,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83306495863746,
          21.017185031830742,
          105.83319370466299,
          21.017382829485474
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83319370466299,
            21.017382829485474
          ],
          [
            105.83306495863746,
            21.017185031830742
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 60 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.017382829485474,
        "to_latitude": 21.017185031830742,
        "to_longitude": 105.83306495863746,
        "from_longitude": 105.83319370466299,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7166,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83306495863746,
          21.017185031830742,
          105.83319906908746,
          21.017325242852053
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83306495863746,
            21.017185031830742
          ],
          [
            105.83319906908746,
            21.017325242852053
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 60 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.017185031830742,
        "to_latitude": 21.017325242852053,
        "to_longitude": 105.83319906908746,
        "from_longitude": 105.83306495863746,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7167,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83292548377202,
          21.017185031830742,
          105.83306495863746,
          21.017300205179428
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83306495863746,
            21.017185031830742
          ],
          [
            105.83292548377202,
            21.017300205179428
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 60 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.017185031830742,
        "to_latitude": 21.017300205179428,
        "to_longitude": 105.83292548377202,
        "from_longitude": 105.83306495863746,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7168,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83312933165921,
          21.017470461271873,
          105.83316151817009,
          21.017590641925565
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83312933165921,
            21.017470461271873
          ],
          [
            105.83316151817009,
            21.017590641925565
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.017470461271873,
        "to_latitude": 21.017590641925565,
        "to_longitude": 105.83316151817009,
        "from_longitude": 105.83312933165921,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7169,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83316151817009,
          21.017590641925565,
          105.83325271326923,
          21.01776089768558
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83316151817009,
            21.017590641925565
          ],
          [
            105.83325271326923,
            21.01776089768558
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.017590641925565,
        "to_latitude": 21.01776089768558,
        "to_longitude": 105.83325271326923,
        "from_longitude": 105.83316151817009,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7170,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83325271326923,
          21.01776089768558,
          105.83339755255916,
          21.018021288472088
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83325271326923,
            21.01776089768558
          ],
          [
            105.83339755255916,
            21.018021288472088
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "diemDau": "4-1",
        "diemCuoi": "5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01776089768558,
        "to_latitude": 21.018021288472088,
        "to_longitude": 105.83339755255916,
        "from_longitude": 105.83325271326923,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7171,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83325271326923,
          21.01776089768558,
          105.8334672899964,
          21.017852284891248
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83325271326923,
            21.01776089768558
          ],
          [
            105.83337341266254,
            21.017852284891248
          ],
          [
            105.8334672899964,
            21.017800957836478
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 39 Thổ Quan",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01776089768558,
        "to_latitude": 21.017800957836478,
        "to_longitude": 105.8334672899964,
        "from_longitude": 105.83325271326923,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7172,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83325271326923,
          21.017615679549426,
          105.83345119674097,
          21.01776089768558
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83325271326923,
            21.01776089768558
          ],
          [
            105.83328221755441,
            21.017679525474016
          ],
          [
            105.83345119674097,
            21.017615679549426
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 41 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01776089768558,
        "to_latitude": 21.017615679549426,
        "to_longitude": 105.83345119674097,
        "from_longitude": 105.83325271326923,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7173,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83339755255916,
          21.017886085620727,
          105.83351020533824,
          21.018021288472088
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83339755255916,
            21.018021288472088
          ],
          [
            105.83342571574046,
            21.017929901375005
          ],
          [
            105.83351020533824,
            21.017886085620727
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 37 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.018021288472088,
        "to_latitude": 21.017886085620727,
        "to_longitude": 105.83351020533824,
        "from_longitude": 105.83339755255916,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7174,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83339755255916,
          21.01797121335638,
          105.83357994277546,
          21.018023792230444
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83339755255916,
            21.018021288472088
          ],
          [
            105.83344046789203,
            21.018023792230444
          ],
          [
            105.83357994277546,
            21.01797121335638
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 35 Thổ Quan",
        "diemDau": "05",
        "diemCuoi": "5-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.018021288472088,
        "to_latitude": 21.01797121335638,
        "to_longitude": 105.83357994277546,
        "from_longitude": 105.83339755255916,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7175,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83357994277546,
          21.01797121335638,
          105.8336228581173,
          21.018091393606515
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83357994277546,
            21.01797121335638
          ],
          [
            105.8336228581173,
            21.018091393606515
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 35 Thổ Quan",
        "diemDau": "5-1",
        "diemCuoi": "5-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01797121335638,
        "to_latitude": 21.018091393606515,
        "to_longitude": 105.8336228581173,
        "from_longitude": 105.83357994277546,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7176,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83357994277546,
          21.017770912724515,
          105.83382670600452,
          21.01797121335638
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83357994277546,
            21.01797121335638
          ],
          [
            105.83382670600452,
            21.017770912724515
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 35 Thổ Quan",
        "diemDau": "5-1",
        "diemCuoi": "5-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01797121335638,
        "to_latitude": 21.017770912724515,
        "to_longitude": 105.83382670600452,
        "from_longitude": 105.83357994277546,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7177,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83357994277546,
          21.017720837523882,
          105.83371941762297,
          21.01797121335638
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83357994277546,
            21.01797121335638
          ],
          [
            105.83371941762297,
            21.01783976609887
          ],
          [
            105.8336765022991,
            21.017720837523882
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 35 Thổ Quan",
        "diemDau": "5-1",
        "diemCuoi": "5-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01797121335638,
        "to_latitude": 21.017720837523882,
        "to_longitude": 105.8336765022991,
        "from_longitude": 105.83357994277546,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7178,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83330635744204,
          21.018021288472088,
          105.83341498690054,
          21.01809014173167
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83339755255916,
            21.018021288472088
          ],
          [
            105.83341498690054,
            21.018063852309908
          ],
          [
            105.83330635744204,
            21.01809014173167
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 58 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.018021288472088,
        "to_latitude": 21.01809014173167,
        "to_longitude": 105.83330635744204,
        "from_longitude": 105.83339755255916,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7179,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83339755255916,
          21.018021288472088,
          105.83354775626461,
          21.018391843808118
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83339755255916,
            21.018021288472088
          ],
          [
            105.83354775626461,
            21.018391843808118
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.018021288472088,
        "to_latitude": 21.018391843808118,
        "to_longitude": 105.83354775626461,
        "from_longitude": 105.83339755255916,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7180,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8334672899964,
          21.018216581264216,
          105.83354775626461,
          21.018391843808118
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83354775626461,
            21.018391843808118
          ],
          [
            105.8334672899964,
            21.018216581264216
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.018391843808118,
        "to_latitude": 21.018216581264216,
        "to_longitude": 105.8334672899964,
        "from_longitude": 105.83354775626461,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7181,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83354775626461,
          21.0183367613166,
          105.83360676486186,
          21.018391843808118
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83354775626461,
            21.018391843808118
          ],
          [
            105.83355848508657,
            21.018344272568964
          ],
          [
            105.83360676486186,
            21.0183367613166
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.018391843808118,
        "to_latitude": 21.0183367613166,
        "to_longitude": 105.83360676486186,
        "from_longitude": 105.83354775626461,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7182,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83360676486186,
          21.01823661127939,
          105.83378915507815,
          21.0183367613166
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83360676486186,
            21.0183367613166
          ],
          [
            105.83378915507815,
            21.01823661127939
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25 Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.0183367613166,
        "to_latitude": 21.01823661127939,
        "to_longitude": 105.83378915507815,
        "from_longitude": 105.83360676486186,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7183,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83378915507815,
          21.01813646117492,
          105.83399300295639,
          21.01823661127939
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83378915507815,
            21.01823661127939
          ],
          [
            105.83399300295639,
            21.01813646117492
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25 Thổ Quan",
        "diemDau": "6-2",
        "diemCuoi": "6-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01823661127939,
        "to_latitude": 21.01813646117492,
        "to_longitude": 105.83399300295639,
        "from_longitude": 105.83378915507815,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7184,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83399300295639,
          21.01804131851434,
          105.8341914864281,
          21.01813646117492
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83399300295639,
            21.01813646117492
          ],
          [
            105.8341914864281,
            21.01804131851434
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25 Thổ Quan",
        "diemDau": "6-3",
        "diemCuoi": "6-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01813646117492,
        "to_latitude": 21.01804131851434,
        "to_longitude": 105.8341914864281,
        "from_longitude": 105.83399300295639,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7185,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8341914864281,
          21.01799625091633,
          105.83433096129356,
          21.01804131851434
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8341914864281,
            21.01804131851434
          ],
          [
            105.83433096129356,
            21.01799625091633
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 25 Thổ Quan",
        "diemDau": "6-4",
        "diemCuoi": "6-5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01804131851434,
        "to_latitude": 21.01799625091633,
        "to_longitude": 105.83433096129356,
        "from_longitude": 105.8341914864281,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7186,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83354775626461,
          21.018391843808118,
          105.83363358694827,
          21.01866224847196
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83354775626461,
            21.018391843808118
          ],
          [
            105.83363358694827,
            21.01866224847196
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.018391843808118,
        "to_latitude": 21.01866224847196,
        "to_longitude": 105.83363358694827,
        "from_longitude": 105.83354775626461,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7187,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83337073047277,
          21.01866224847196,
          105.83363358694827,
          21.018722338330665
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83363358694827,
            21.01866224847196
          ],
          [
            105.83337073047277,
            21.018722338330665
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 24 Thổ Quan",
        "diemDau": "07",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01866224847196,
        "to_latitude": 21.018722338330665,
        "to_longitude": 105.83337073047277,
        "from_longitude": 105.83363358694827,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7188,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83317224700106,
          21.018722338330665,
          105.83337073047277,
          21.01872734581794
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83337073047277,
            21.018722338330665
          ],
          [
            105.83317224700106,
            21.01872734581794
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 24 Thổ Quan",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.018722338330665,
        "to_latitude": 21.01872734581794,
        "to_longitude": 105.83317224700106,
        "from_longitude": 105.83337073047277,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7189,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83291475493205,
          21.018707315868674,
          105.83317224700106,
          21.01872734581794
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83317224700106,
            21.01872734581794
          ],
          [
            105.83291475493205,
            21.018707315868674
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 24 Thổ Quan",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01872734581794,
        "to_latitude": 21.018707315868674,
        "to_longitude": 105.83291475493205,
        "from_longitude": 105.83317224700106,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7190,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83266262728752,
          21.018707315868674,
          105.83291475493205,
          21.018767405709227
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83291475493205,
            21.018707315868674
          ],
          [
            105.83266262728752,
            21.018767405709227
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 24 Thổ Quan",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.018707315868674,
        "to_latitude": 21.018767405709227,
        "to_longitude": 105.83266262728752,
        "from_longitude": 105.83291475493205,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7191,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83270554262936,
          21.018707315868674,
          105.83291475493205,
          21.018877570353723
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83291475493205,
            21.018707315868674
          ],
          [
            105.83270554262936,
            21.018877570353723
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 24 Thổ Quan",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.018707315868674,
        "to_latitude": 21.018877570353723,
        "to_longitude": 105.83270554262936,
        "from_longitude": 105.83291475493205,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7192,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83288793284565,
          21.018567106145998,
          105.83291475493205,
          21.018707315868674
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83291475493205,
            21.018707315868674
          ],
          [
            105.83288793284565,
            21.018567106145998
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 24 Thổ Quan",
        "diemDau": "11",
        "diemCuoi": "13-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.018707315868674,
        "to_latitude": 21.018567106145998,
        "to_longitude": 105.83288793284565,
        "from_longitude": 105.83291475493205,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7193,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83280210216198,
          21.018396851306473,
          105.83288793284565,
          21.018567106145998
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83280210216198,
            21.018396851306473
          ],
          [
            105.83288793284565,
            21.018567106145998
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 24 Thổ Quan",
        "diemDau": "13-1",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.018396851306473,
        "to_latitude": 21.018567106145998,
        "to_longitude": 105.83288793284565,
        "from_longitude": 105.83280210216198,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7194,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83278064448207,
          21.018316731314044,
          105.83280210216198,
          21.018396851306473
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83280210216198,
            21.018396851306473
          ],
          [
            105.83278064448207,
            21.018316731314044
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 24 Thổ Quan",
        "diemDau": "13",
        "diemCuoi": "13-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.018396851306473,
        "to_latitude": 21.018316731314044,
        "to_longitude": 105.83278064448207,
        "from_longitude": 105.83280210216198,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7195,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83248560149572,
          21.018396851306473,
          105.83280210216198,
          21.01849700123524
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83280210216198,
            21.018396851306473
          ],
          [
            105.83248560149572,
            21.01849700123524
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 24 Thổ Quan",
        "diemDau": "13",
        "diemCuoi": "13-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.018396851306473,
        "to_latitude": 21.01849700123524,
        "to_longitude": 105.83248560149572,
        "from_longitude": 105.83280210216198,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7196,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83243732172939,
          21.01838182881086,
          105.83248560149572,
          21.01849700123524
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83248560149572,
            21.01849700123524
          ],
          [
            105.83243732172939,
            21.01838182881086
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 24 Thổ Quan",
        "diemDau": "13-3",
        "diemCuoi": "13-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01849700123524,
        "to_latitude": 21.01838182881086,
        "to_longitude": 105.83243732172939,
        "from_longitude": 105.83248560149572,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7197,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83280210216198,
          21.01833175381621,
          105.83311860281925,
          21.018396851306473
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83280210216198,
            21.018396851306473
          ],
          [
            105.83311860281925,
            21.01833175381621
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x2,5",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 24 Thổ Quan",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.018396851306473,
        "to_latitude": 21.01833175381621,
        "to_longitude": 105.83311860281925,
        "from_longitude": 105.83280210216198,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7198,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83363358694827,
          21.01860215858903,
          105.83379988390912,
          21.01866224847196
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83363358694827,
            21.01866224847196
          ],
          [
            105.83379988390912,
            21.01860215858903
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 15 Thổ Quan",
        "diemDau": "07",
        "diemCuoi": "7-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01866224847196,
        "to_latitude": 21.01860215858903,
        "to_longitude": 105.83379988390912,
        "from_longitude": 105.83363358694827,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7199,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83379988390912,
          21.018572113638463,
          105.83389644343274,
          21.01860215858903
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83379988390912,
            21.01860215858903
          ],
          [
            105.83389644343274,
            21.018572113638463
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 15 Thổ Quan",
        "diemDau": "7-1",
        "diemCuoi": "7-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01860215858903,
        "to_latitude": 21.018572113638463,
        "to_longitude": 105.83389644343274,
        "from_longitude": 105.83379988390912,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7200,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83389644343274,
          21.018537061188407,
          105.8339876385409,
          21.018572113638463
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83389644343274,
            21.018572113638463
          ],
          [
            105.8339876385409,
            21.018537061188407
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 15 Thổ Quan",
        "diemDau": "7-2",
        "diemCuoi": "7-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.018572113638463,
        "to_latitude": 21.018537061188407,
        "to_longitude": 105.8339876385409,
        "from_longitude": 105.83389644343274,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7201,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83391253668819,
          21.018411873799735,
          105.8339876385409,
          21.018537061188407
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8339876385409,
            21.018537061188407
          ],
          [
            105.83391253668819,
            21.018411873799735
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 15 Thổ Quan",
        "diemDau": "7-3",
        "diemCuoi": "7-7",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.018537061188407,
        "to_latitude": 21.018411873799735,
        "to_longitude": 105.83391253668819,
        "from_longitude": 105.8339876385409,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7202,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8339876385409,
          21.01842188879496,
          105.8341646643417,
          21.018537061188407
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8339876385409,
            21.018537061188407
          ],
          [
            105.8341646643417,
            21.01842188879496
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 15 Thổ Quan",
        "diemDau": "7-3",
        "diemCuoi": "7-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.018537061188407,
        "to_latitude": 21.01842188879496,
        "to_longitude": 105.8341646643417,
        "from_longitude": 105.8339876385409,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7203,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8341646643417,
          21.018326746315655,
          105.83433096129356,
          21.01842188879496
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8341646643417,
            21.01842188879496
          ],
          [
            105.83433096129356,
            21.018326746315655
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 15 Thổ Quan",
        "diemDau": "7-4",
        "diemCuoi": "7-5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01842188879496,
        "to_latitude": 21.018326746315655,
        "to_longitude": 105.83433096129356,
        "from_longitude": 105.8341646643417,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7204,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83433096129356,
          21.018326746315655,
          105.83459918219353,
          21.018326746315655
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83433096129356,
            21.018326746315655
          ],
          [
            105.83459918219353,
            21.018326746315655
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 15 Thổ Quan",
        "diemDau": "7-5",
        "diemCuoi": "7-6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.018326746315655,
        "to_latitude": 21.018326746315655,
        "to_longitude": 105.83459918219353,
        "from_longitude": 105.83433096129356,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7205,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83459918219353,
          21.018181528730537,
          105.83461527544897,
          21.018326746315655
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83459918219353,
            21.018326746315655
          ],
          [
            105.83461527544897,
            21.018181528730537
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 15 Thổ Quan",
        "diemDau": "7-6",
        "diemCuoi": "7-8",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.018326746315655,
        "to_latitude": 21.018181528730537,
        "to_longitude": 105.83461527544897,
        "from_longitude": 105.83459918219353,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7206,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8345951588819,
          21.01805133353363,
          105.83471719939706,
          21.018181528730537
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83461527544897,
            21.018181528730537
          ],
          [
            105.83462734538381,
            21.018153987450592
          ],
          [
            105.8345951588819,
            21.01810641613307
          ],
          [
            105.83471719939706,
            21.01805133353363
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 15 Thổ Quan",
        "diemDau": "7-8",
        "diemCuoi": "7-9",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.018181528730537,
        "to_latitude": 21.01805133353363,
        "to_longitude": 105.83471719939706,
        "from_longitude": 105.83461527544897,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7207,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83363358694827,
          21.01866224847196,
          105.83372478205642,
          21.018912622723768
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83363358694827,
            21.01866224847196
          ],
          [
            105.83372478205642,
            21.018912622723768
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01866224847196,
        "to_latitude": 21.018912622723768,
        "to_longitude": 105.83372478205642,
        "from_longitude": 105.83363358694827,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7208,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83372478205642,
          21.018872562871497,
          105.83391790110366,
          21.018912622723768
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83372478205642,
            21.018912622723768
          ],
          [
            105.83391790110366,
            21.018872562871497
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1 Thổ Quan",
        "diemDau": "08",
        "diemCuoi": "8-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.018912622723768,
        "to_latitude": 21.018872562871497,
        "to_longitude": 105.83391790110366,
        "from_longitude": 105.83372478205642,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7209,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83391790110366,
          21.01880245810431,
          105.8341163845754,
          21.018872562871497
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83391790110366,
            21.018872562871497
          ],
          [
            105.8341163845754,
            21.01880245810431
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1 Thổ Quan",
        "diemDau": "8-1",
        "diemCuoi": "8-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.018872562871497,
        "to_latitude": 21.01880245810431,
        "to_longitude": 105.8341163845754,
        "from_longitude": 105.83391790110366,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7210,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8341163845754,
          21.01874236827792,
          105.83432023246262,
          21.01880245810431
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8341163845754,
            21.01880245810431
          ],
          [
            105.83432023246262,
            21.01874236827792
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1 Thổ Quan",
        "diemDau": "8-2",
        "diemCuoi": "8-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01880245810431,
        "to_latitude": 21.01874236827792,
        "to_longitude": 105.83432023246262,
        "from_longitude": 105.8341163845754,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7211,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83432023246262,
          21.01866224847196,
          105.834419474185,
          21.01874236827792
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83432023246262,
            21.01874236827792
          ],
          [
            105.834419474185,
            21.018724842076427
          ],
          [
            105.83440606314628,
            21.01866224847196
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TQ1",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 1 Thổ Quan",
        "diemDau": "8-3",
        "diemCuoi": "8-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "TQ",
        "from_latitude": 21.01874236827792,
        "to_latitude": 21.01866224847196,
        "to_longitude": 105.83440606314628,
        "from_longitude": 105.83432023246262,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7212,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83338212985119,
          21.02340676903517,
          105.83350987009595,
          21.023492519536973
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83338212985119,
            21.023492519536973
          ],
          [
            105.83350987009595,
            21.02340676903517
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Sân Chơi Đồ Lợi",
        "diemDau": "18",
        "diemCuoi": "18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023492519536973,
        "to_latitude": 21.02340676903517,
        "to_longitude": 105.83350987009595,
        "from_longitude": 105.83338212985119,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7213,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83350987009595,
          21.023378602804335,
          105.83367314952595,
          21.02340676903517
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83350987009595,
            21.02340676903517
          ],
          [
            105.83367314952595,
            21.023378602804335
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Sân Chơi Đồ Lợi",
        "diemDau": "18-1",
        "diemCuoi": "18-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.02340676903517,
        "to_latitude": 21.023378602804335,
        "to_longitude": 105.83367314952595,
        "from_longitude": 105.83350987009595,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7214,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83367314952595,
          21.023269693318547,
          105.83397087472903,
          21.023378602804335
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83367314952595,
            21.023378602804335
          ],
          [
            105.83397087472903,
            21.023269693318547
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHG5",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Sân Chơi Đồ Lợi",
        "diemDau": "18-2",
        "diemCuoi": "18-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Văn Hương 5",
        "from_latitude": 21.023378602804335,
        "to_latitude": 21.023269693318547,
        "to_longitude": 105.83397087472903,
        "from_longitude": 105.83367314952595,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7215,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83837036809338,
          21.010188084061827,
          105.83865736445654,
          21.010383387110785
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83837036809338,
            21.010383387110785
          ],
          [
            105.83865736445654,
            21.010188084061827
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Kim Hoa",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010383387110785,
        "to_latitude": 21.010188084061827,
        "to_longitude": 105.83865736445654,
        "from_longitude": 105.83837036809338,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7216,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83865736445654,
          21.00993769516373,
          105.83892290314876,
          21.010188084061827
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83865736445654,
            21.010188084061827
          ],
          [
            105.83892290314876,
            21.00993769516373
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Kim Hoa",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010188084061827,
        "to_latitude": 21.00993769516373,
        "to_longitude": 105.83892290314876,
        "from_longitude": 105.83865736445654,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7217,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83892290314876,
          21.009610937022465,
          105.83923672158035,
          21.00993769516373
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83892290314876,
            21.00993769516373
          ],
          [
            105.83923672158035,
            21.009610937022465
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Kim Hoa",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.00993769516373,
        "to_latitude": 21.009610937022465,
        "to_longitude": 105.83923672158035,
        "from_longitude": 105.83892290314876,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7218,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83923672158035,
          21.0093129730335,
          105.83952371794346,
          21.009610937022465
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83923672158035,
            21.009610937022465
          ],
          [
            105.83952371794346,
            21.0093129730335
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Kim Hoa",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.009610937022465,
        "to_latitude": 21.0093129730335,
        "to_longitude": 105.83952371794346,
        "from_longitude": 105.83923672158035,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7219,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84010307509422,
          21.0084341009959,
          105.84041421133603,
          21.008772129315254
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84010307509422,
            21.008772129315254
          ],
          [
            105.84041421133603,
            21.0084341009959
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Kim Hoa",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.008772129315254,
        "to_latitude": 21.0084341009959,
        "to_longitude": 105.84041421133603,
        "from_longitude": 105.84010307509422,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7220,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84041421133603,
          21.008271346346742,
          105.84061269480775,
          21.0084341009959
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84041421133603,
            21.0084341009959
          ],
          [
            105.84061269480775,
            21.008271346346742
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Kim Hoa",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.0084341009959,
        "to_latitude": 21.008271346346742,
        "to_longitude": 105.84061269480775,
        "from_longitude": 105.84041421133603,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7221,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83979998547566,
          21.008813443833127,
          105.83995018920798,
          21.009045055325174
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83979998547566,
            21.009045055325174
          ],
          [
            105.83995018920798,
            21.008813443833127
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Kim Hoa",
        "diemDau": "06",
        "diemCuoi": "6-8",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.009045055325174,
        "to_latitude": 21.008813443833127,
        "to_longitude": 105.83995018920798,
        "from_longitude": 105.83979998547566,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7222,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83995018920798,
          21.008772129315254,
          105.84010307509422,
          21.008813443833127
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83995018920798,
            21.008813443833127
          ],
          [
            105.84010307509422,
            21.008772129315254
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Kim Hoa",
        "diemDau": "6-8",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.008813443833127,
        "to_latitude": 21.008772129315254,
        "to_longitude": 105.84010307509422,
        "from_longitude": 105.83995018920798,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7223,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84061269480775,
          21.008171189552648,
          105.84080313166521,
          21.008295133574574
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84061269480775,
            21.008271346346742
          ],
          [
            105.8406958432837,
            21.008171189552648
          ],
          [
            105.84080313166521,
            21.008295133574574
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 50 Kim Hoa",
        "diemDau": "09",
        "diemCuoi": "9-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.008271346346742,
        "to_latitude": 21.008295133574574,
        "to_longitude": 105.84080313166521,
        "from_longitude": 105.84061269480775,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7224,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84080313166521,
          21.008295133574574,
          105.84089164455663,
          21.008467903861376
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84080313166521,
            21.008295133574574
          ],
          [
            105.84089164455663,
            21.008467903861376
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 50 Kim Hoa",
        "diemDau": "9-2",
        "diemCuoi": "9-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.008295133574574,
        "to_latitude": 21.008467903861376,
        "to_longitude": 105.84089164455663,
        "from_longitude": 105.84080313166521,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7225,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.840548321795,
          21.008271346346742,
          105.84061269480775,
          21.008407809871347
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84061269480775,
            21.008271346346742
          ],
          [
            105.840548321795,
            21.008361487407676
          ],
          [
            105.8405912371458,
            21.008407809871347
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 50 Kim Hoa",
        "diemDau": "09",
        "diemCuoi": "9-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.008271346346742,
        "to_latitude": 21.008407809871347,
        "to_longitude": 105.8405912371458,
        "from_longitude": 105.84061269480775,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7226,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84010307509422,
          21.00857129272005,
          105.84036727269154,
          21.008772129315254
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84010307509422,
            21.008772129315254
          ],
          [
            105.84028793407968,
            21.00857129272005
          ],
          [
            105.84036727269154,
            21.008660705249284
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 50 Kim Hoa",
        "diemDau": "07",
        "diemCuoi": "7-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.008772129315254,
        "to_latitude": 21.008660705249284,
        "to_longitude": 105.84036727269154,
        "from_longitude": 105.84010307509422,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7227,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84036727269154,
          21.008660705249284,
          105.84054832180398,
          21.00885976616147
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84036727269154,
            21.008660705249284
          ],
          [
            105.84054832180398,
            21.00885976616147
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 50 Kim Hoa",
        "diemDau": "7-1",
        "diemCuoi": "7-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.008660705249284,
        "to_latitude": 21.00885976616147,
        "to_longitude": 105.84054832180398,
        "from_longitude": 105.84036727269154,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7228,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84054832180398,
          21.00885976616147,
          105.84073205312413,
          21.00907134633661
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84054832180398,
            21.00885976616147
          ],
          [
            105.84073205312413,
            21.00907134633661
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 50 Kim Hoa",
        "diemDau": "7-2",
        "diemCuoi": "7-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.00885976616147,
        "to_latitude": 21.00907134633661,
        "to_longitude": 105.84073205312413,
        "from_longitude": 105.84054832180398,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7229,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84073205312413,
          21.00907134633661,
          105.84087823351793,
          21.009326744490814
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84073205312413,
            21.00907134633661
          ],
          [
            105.84087823351793,
            21.009326744490814
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 50 Kim Hoa",
        "diemDau": "7-3",
        "diemCuoi": "7-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.00907134633661,
        "to_latitude": 21.009326744490814,
        "to_longitude": 105.84087823351793,
        "from_longitude": 105.84073205312413,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7230,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84075216970015,
          21.009326744490814,
          105.84087823351793,
          21.009444427807384
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84087823351793,
            21.009326744490814
          ],
          [
            105.84075216970015,
            21.009444427807384
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 50 Kim Hoa",
        "diemDau": "7-4",
        "diemCuoi": "7-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.009326744490814,
        "to_latitude": 21.009444427807384,
        "to_longitude": 105.84075216970015,
        "from_longitude": 105.84087823351793,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7231,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84054832181296,
          21.009444427807384,
          105.84075216970015,
          21.009587150002755
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84075216970015,
            21.009444427807384
          ],
          [
            105.84054832181296,
            21.009587150002755
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 50 Kim Hoa",
        "diemDau": "7-5",
        "diemCuoi": "7-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.009444427807384,
        "to_latitude": 21.009587150002755,
        "to_longitude": 105.84054832181296,
        "from_longitude": 105.84075216970015,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7232,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83993677814237,
          21.008813443833127,
          105.84016208370946,
          21.00906258266918
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83995018920798,
            21.008813443833127
          ],
          [
            105.83993677814237,
            21.00891735606415
          ],
          [
            105.84008698184778,
            21.00906258266918
          ],
          [
            105.84016208370946,
            21.00898871742807
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 50 Kim Hoa",
        "diemDau": "6-8",
        "diemCuoi": "6-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.008813443833127,
        "to_latitude": 21.00898871742807,
        "to_longitude": 105.84016208370946,
        "from_longitude": 105.83995018920798,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7233,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84016208370946,
          21.00898871742807,
          105.84040616473075,
          21.009209061082213
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84016208370946,
            21.00898871742807
          ],
          [
            105.84040616473075,
            21.009209061082213
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 50 Kim Hoa",
        "diemDau": "6-3",
        "diemCuoi": "6-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.00898871742807,
        "to_latitude": 21.009209061082213,
        "to_longitude": 105.84040616473075,
        "from_longitude": 105.84016208370946,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7234,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84021841009898,
          21.00909983116493,
          105.84040616473075,
          21.009209061082213
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84040616473075,
            21.009209061082213
          ],
          [
            105.84028516755569,
            21.00909983116493
          ],
          [
            105.84021841009898,
            21.009158983007058
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 50 Kim Hoa",
        "diemDau": "6-4",
        "diemCuoi": "6-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.009209061082213,
        "to_latitude": 21.009158983007058,
        "to_longitude": 105.84021841009898,
        "from_longitude": 105.84040616473075,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7235,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8401692833268,
          21.009414381012082,
          105.84039811810753,
          21.009595584181326
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84039811810753,
            21.009414381012082
          ],
          [
            105.8401692833268,
            21.009595584181326
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 50 Kim Hoa",
        "diemDau": "6-6",
        "diemCuoi": "6-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.009414381012082,
        "to_latitude": 21.009595584181326,
        "to_longitude": 105.8401692833268,
        "from_longitude": 105.84039811810753,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7236,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84039811810753,
          21.009209061082213,
          105.84046249111131,
          21.009414381012082
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84040616473075,
            21.009209061082213
          ],
          [
            105.84046249111131,
            21.009325492543237
          ],
          [
            105.84039811810753,
            21.009414381012082
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 50 Kim Hoa",
        "diemDau": "6-4",
        "diemCuoi": "6-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.009209061082213,
        "to_latitude": 21.009414381012082,
        "to_longitude": 105.84039811810753,
        "from_longitude": 105.84040616473075,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7237,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83977852783167,
          21.009216572790056,
          105.8399555536235,
          21.00932924839094
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83977852783167,
            21.009216572790056
          ],
          [
            105.8399555536235,
            21.00932924839094
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 50 Kim Hoa",
        "diemDau": "6-1",
        "diemCuoi": "6-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.009216572790056,
        "to_latitude": 21.00932924839094,
        "to_longitude": 105.8399555536235,
        "from_longitude": 105.83977852783167,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7238,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83962564190952,
          21.00938433420903,
          105.83977852783167,
          21.009537072053703
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83962564190952,
            21.00938433420903
          ],
          [
            105.83977852783167,
            21.009537072053703
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 78 Kim Hoa",
        "diemDau": "5-2",
        "diemCuoi": "5-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.00938433420903,
        "to_latitude": 21.009537072053703,
        "to_longitude": 105.83977852783167,
        "from_longitude": 105.83962564190952,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7239,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83977852783167,
          21.009537072053703,
          105.83990459164944,
          21.009734879849113
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83977852783167,
            21.009537072053703
          ],
          [
            105.83990459164944,
            21.009734879849113
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 78 Kim Hoa",
        "diemDau": "5-3",
        "diemCuoi": "5-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.009537072053703,
        "to_latitude": 21.009734879849113,
        "to_longitude": 105.83990459164944,
        "from_longitude": 105.83977852783167,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7240,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83979730329486,
          21.008675728722334,
          105.83995018920798,
          21.008813443833127
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83995018920798,
            21.008813443833127
          ],
          [
            105.83979730329486,
            21.008675728722334
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 50 Kim Hoa",
        "diemDau": "6-8",
        "diemCuoi": "6-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.008813443833127,
        "to_latitude": 21.008675728722334,
        "to_longitude": 105.83979730329486,
        "from_longitude": 105.83995018920798,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7241,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83942984065453,
          21.009168998621956,
          105.83952371794346,
          21.0093129730335
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83952371794346,
            21.0093129730335
          ],
          [
            105.83942984065453,
            21.009168998621956
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 50 Kim Hoa",
        "diemDau": "5",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.0093129730335,
        "to_latitude": 21.009168998621956,
        "to_longitude": 105.83942984065453,
        "from_longitude": 105.83952371794346,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7242,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81827659923609,
          21.003270936219586,
          105.81834097224885,
          21.003275944225546
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81827659923609,
            21.003275944225546
          ],
          [
            105.81834097224885,
            21.003270936219586
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NTS3",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đường Láng",
        "diemDau": "TỦ ĐIỆN",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NTS3",
        "from_latitude": 21.003275944225546,
        "to_latitude": 21.003270936219586,
        "to_longitude": 105.81834097224885,
        "from_longitude": 105.81827659923609,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7243,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81834097224885,
          21.003270936219586,
          105.81847508269884,
          21.003401144316808
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81834097224885,
            21.003270936219586
          ],
          [
            105.81846435386785,
            21.00335857629768
          ],
          [
            105.81847508269884,
            21.003401144316808
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NTS3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đường Láng",
        "diemDau": "02",
        "diemCuoi": "01",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NTS3",
        "from_latitude": 21.003270936219586,
        "to_latitude": 21.003401144316808,
        "to_longitude": 105.81847508269884,
        "from_longitude": 105.81834097224885,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7244,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81847508269884,
          21.003401144316808,
          105.81860114652558,
          21.003503808313102
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81847508269884,
            21.003401144316808
          ],
          [
            105.81860114652558,
            21.003503808313102
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NTS3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đường Láng",
        "diemDau": "01",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NTS3",
        "from_latitude": 21.003401144316808,
        "to_latitude": 21.003503808313102,
        "to_longitude": 105.81860114652558,
        "from_longitude": 105.81847508269884,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7245,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81834097224885,
          21.00307812786737,
          105.81847508269884,
          21.003270936219586
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81834097224885,
            21.003270936219586
          ],
          [
            105.81836511212751,
            21.003150744029234
          ],
          [
            105.81847508269884,
            21.00307812786737
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NTS3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đường Láng",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NTS3",
        "from_latitude": 21.003270936219586,
        "to_latitude": 21.00307812786737,
        "to_longitude": 105.81847508269884,
        "from_longitude": 105.81834097224885,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7246,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8181693108725,
          21.003275944225546,
          105.81827659923609,
          21.0033135042637
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81827659923609,
            21.003275944225546
          ],
          [
            105.8181693108725,
            21.0033135042637
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NTS3",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đường Láng",
        "diemDau": "TỦ ĐIỆN",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NTS3",
        "from_latitude": 21.003275944225546,
        "to_latitude": 21.0033135042637,
        "to_longitude": 105.8181693108725,
        "from_longitude": 105.81827659923609,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7247,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81812907773842,
          21.0033135042637,
          105.8181693108725,
          21.003488784317625
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8181693108725,
            21.0033135042637
          ],
          [
            105.81812907773842,
            21.003488784317625
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NTS3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đường Láng",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NTS3",
        "from_latitude": 21.0033135042637,
        "to_latitude": 21.003488784317625,
        "to_longitude": 105.81812907773842,
        "from_longitude": 105.8181693108725,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7248,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81802447159156,
          21.003411160319846,
          105.81812907773842,
          21.003488784317625
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81812907773842,
            21.003488784317625
          ],
          [
            105.81803520042256,
            21.00347125632225
          ],
          [
            105.81802447159156,
            21.003411160319846
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NTS3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đường Láng",
        "diemDau": "05",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NTS3",
        "from_latitude": 21.003488784317625,
        "to_latitude": 21.003411160319846,
        "to_longitude": 105.81802447159156,
        "from_longitude": 105.81812907773842,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7249,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81795741636208,
          21.00329222024315,
          105.81802447159156,
          21.003411160319846
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81802447159156,
            21.003411160319846
          ],
          [
            105.81795741636208,
            21.003383616310312
          ],
          [
            105.81797216851365,
            21.00329222024315
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NTS3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đường Láng",
        "diemDau": "5-1",
        "diemCuoi": "5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NTS3",
        "from_latitude": 21.003411160319846,
        "to_latitude": 21.00329222024315,
        "to_longitude": 105.81797216851365,
        "from_longitude": 105.81802447159156,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7250,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81806470472567,
          21.003488784317625,
          105.81812907773842,
          21.003646536190903
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81812907773842,
            21.003488784317625
          ],
          [
            105.81806470472567,
            21.003646536190903
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NTS3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 47 Đường Láng",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NTS3",
        "from_latitude": 21.003488784317625,
        "to_latitude": 21.003646536190903,
        "to_longitude": 105.81806470472567,
        "from_longitude": 105.81812907773842,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7251,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81785549242298,
          21.003601464243907,
          105.81806470472567,
          21.003646536190903
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81806470472567,
            21.003646536190903
          ],
          [
            105.81785549242298,
            21.003601464243907
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NTS3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đường Láng",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NTS3",
        "from_latitude": 21.003646536190903,
        "to_latitude": 21.003601464243907,
        "to_longitude": 105.81785549242298,
        "from_longitude": 105.81806470472567,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7252,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81771601754852,
          21.00356140028048,
          105.81785549242298,
          21.003601464243907
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81785549242298,
            21.003601464243907
          ],
          [
            105.81771601754852,
            21.00356140028048
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "NTS3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 41 Đường Láng",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "NTS3",
        "from_latitude": 21.003601464243907,
        "to_latitude": 21.00356140028048,
        "to_longitude": 105.81771601754852,
        "from_longitude": 105.81785549242298,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7253,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79979912922049,
          21.02622399139475,
          105.7998763101251,
          21.02622743387106
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79979912922049,
            21.02622399139475
          ],
          [
            105.7998763101251,
            21.02622743387106
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "diemDau": "Tủ điện",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02622399139475,
        "to_latitude": 21.02622743387106,
        "to_longitude": 105.7998763101251,
        "from_longitude": 105.79979912922049,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7254,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.7998763101251,
          21.02622743387106,
          105.80006138254014,
          21.02630880141992
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.7998763101251,
            21.02622743387106
          ],
          [
            105.80006138254014,
            21.02630880141992
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "diemDau": "1-1",
        "diemCuoi": "1-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02622743387106,
        "to_latitude": 21.02630880141992,
        "to_longitude": 105.80006138254014,
        "from_longitude": 105.7998763101251,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7255,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80006138254014,
          21.02630880141992,
          105.8001472132328,
          21.02651910503271
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80006138254014,
            21.02630880141992
          ],
          [
            105.8001472132328,
            21.02651910503271
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA1",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "diemDau": "1-2",
        "diemCuoi": "1-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VH1",
        "from_latitude": 21.02630880141992,
        "to_latitude": 21.02651910503271,
        "to_longitude": 105.8001472132328,
        "from_longitude": 105.80006138254014,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7256,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79897374677074,
          21.02661048686246,
          105.79934389164576,
          21.026673077127384
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79897374677074,
            21.026625508534796
          ],
          [
            105.79900593330858,
            21.026673077127384
          ],
          [
            105.79934389164576,
            21.02661048686246
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nền",
        "diemDau": "14",
        "diemCuoi": "14-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.026625508534796,
        "to_latitude": 21.02661048686246,
        "to_longitude": 105.79934389164576,
        "from_longitude": 105.79897374677074,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7257,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79934389164576,
          21.026521608644988,
          105.79970733095651,
          21.02661048686246
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79934389164576,
            21.02661048686246
          ],
          [
            105.79970733095651,
            21.026521608644988
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nền",
        "diemDau": "14-1",
        "diemCuoi": "14-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.02661048686246,
        "to_latitude": 21.026521608644988,
        "to_longitude": 105.79970733095651,
        "from_longitude": 105.79934389164576,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7258,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79970733095651,
          21.026487809870787,
          105.7998320536704,
          21.026521608644988
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79970733095651,
            21.026521608644988
          ],
          [
            105.7998320536704,
            21.026487809870787
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nền",
        "diemDau": "14-2",
        "diemCuoi": "14-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.026521608644988,
        "to_latitude": 21.026487809870787,
        "to_longitude": 105.7998320536704,
        "from_longitude": 105.79970733095651,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7259,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.7998320536704,
          21.02643523397995,
          105.79998091627195,
          21.026487809870787
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.7998320536704,
            21.026487809870787
          ],
          [
            105.79992593100425,
            21.02643523397995
          ],
          [
            105.79998091627195,
            21.026449003861124
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nền",
        "diemDau": "14-9",
        "diemCuoi": "14-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.026487809870787,
        "to_latitude": 21.026449003861124,
        "to_longitude": 105.79998091627195,
        "from_longitude": 105.7998320536704,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7260,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79998091627195,
          21.026449003861124,
          105.80001176167896,
          21.026570429083634
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79998091627195,
            21.026449003861124
          ],
          [
            105.80001176167896,
            21.026570429083634
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nền",
        "diemDau": "14-10",
        "diemCuoi": "14-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.026449003861124,
        "to_latitude": 21.026570429083634,
        "to_longitude": 105.80001176167896,
        "from_longitude": 105.79998091627195,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7261,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80001176167896,
          21.026570429083634,
          105.8001297788735,
          21.02661424228403
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80001176167896,
            21.026570429083634
          ],
          [
            105.8001297788735,
            21.02661424228403
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nền",
        "diemDau": "14-11",
        "diemCuoi": "14-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.026570429083634,
        "to_latitude": 21.02661424228403,
        "to_longitude": 105.8001297788735,
        "from_longitude": 105.80001176167896,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7262,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79970733095651,
          21.026521608644988,
          105.79977706838474,
          21.026690602402063
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79970733095651,
            21.026521608644988
          ],
          [
            105.79977706838474,
            21.026690602402063
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nền",
        "diemDau": "14-2",
        "diemCuoi": "14-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.026521608644988,
        "to_latitude": 21.026690602402063,
        "to_longitude": 105.79977706838474,
        "from_longitude": 105.79970733095651,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7263,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79977706838474,
          21.026665566297147,
          105.79989776779603,
          21.02678448773957
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79977706838474,
            21.026690602402063
          ],
          [
            105.79986155798251,
            21.026665566297147
          ],
          [
            105.79989776779603,
            21.02678448773957
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nền",
        "diemDau": "14-3",
        "diemCuoi": "14-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.026690602402063,
        "to_latitude": 21.02678448773957,
        "to_longitude": 105.79989776779603,
        "from_longitude": 105.79977706838474,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7264,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79989776779603,
          21.02678448773957,
          105.80001176167896,
          21.027082416819685
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79989776779603,
            21.02678448773957
          ],
          [
            105.80001176167896,
            21.027082416819685
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nền",
        "diemDau": "14-6",
        "diemCuoi": "14-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.02678448773957,
        "to_latitude": 21.027082416819685,
        "to_longitude": 105.80001176167896,
        "from_longitude": 105.79989776779603,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7265,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80001176167896,
          21.027032344746516,
          105.80015794206378,
          21.027082416819685
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80001176167896,
            21.027082416819685
          ],
          [
            105.80015794206378,
            21.027032344746516
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nền",
        "diemDau": "14-7",
        "diemCuoi": "17-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.027082416819685,
        "to_latitude": 21.027032344746516,
        "to_longitude": 105.80015794206378,
        "from_longitude": 105.80001176167896,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7266,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79979986716853,
          21.026898401869435,
          105.79987496902122,
          21.02713123707378
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79987496902122,
            21.02713123707378
          ],
          [
            105.79979986716853,
            21.026898401869435
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nền",
        "diemDau": "14-4",
        "diemCuoi": "14-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.02713123707378,
        "to_latitude": 21.026898401869435,
        "to_longitude": 105.79979986716853,
        "from_longitude": 105.79987496902122,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7267,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79974622299571,
          21.026690602402063,
          105.79979986716853,
          21.026898401869435
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79979986716853,
            21.026898401869435
          ],
          [
            105.79974622299571,
            21.02671063127458
          ],
          [
            105.79977706838474,
            21.026690602402063
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Chùa Nền",
        "diemDau": "14-3",
        "diemCuoi": "14-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.026898401869435,
        "to_latitude": 21.026690602402063,
        "to_longitude": 105.79977706838474,
        "from_longitude": 105.79979986716853,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7268,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83845762891295,
          21.00868073654943,
          105.8385340718875,
          21.008681988506602
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83845762891295,
            21.008681988506602
          ],
          [
            105.8385340718875,
            21.00868073654943
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LKL2",
        "from_latitude": 21.008681988506602,
        "to_latitude": 21.00868073654943,
        "to_longitude": 105.8385340718875,
        "from_longitude": 105.83845762891295,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7269,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8385340718875,
          21.00868073654943,
          105.83861613225574,
          21.008745993075927
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8385340718875,
            21.00868073654943
          ],
          [
            105.83861613225574,
            21.008745993075927
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LKL2",
        "from_latitude": 21.00868073654943,
        "to_latitude": 21.008745993075927,
        "to_longitude": 105.83861613225574,
        "from_longitude": 105.8385340718875,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7270,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8385340718875,
          21.008583083942828,
          105.83860917373123,
          21.00868073654943
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8385340718875,
            21.00868073654943
          ],
          [
            105.83860917373123,
            21.008583083942828
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "01",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LKL2",
        "from_latitude": 21.00868073654943,
        "to_latitude": 21.008583083942828,
        "to_longitude": 105.83860917373123,
        "from_longitude": 105.8385340718875,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7271,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83861613225574,
          21.008683240461245,
          105.83871646209481,
          21.008745993075927
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83861613225574,
            21.008745993075927
          ],
          [
            105.83871646209481,
            21.008683240461245
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "LKL2",
        "from_latitude": 21.008745993075927,
        "to_latitude": 21.008683240461245,
        "to_longitude": 105.83871646209481,
        "from_longitude": 105.83861613225574,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7272,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83847132967733,
          21.00868073654943,
          105.8385340718875,
          21.008934018445014
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8385340718875,
            21.00868073654943
          ],
          [
            105.83852602525529,
            21.0087708773605
          ],
          [
            105.83847132967733,
            21.008934018445014
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "01",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LKL2",
        "from_latitude": 21.00868073654943,
        "to_latitude": 21.008934018445014,
        "to_longitude": 105.83847132967733,
        "from_longitude": 105.8385340718875,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7273,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8383650927189,
          21.00885601029862,
          105.83847132967733,
          21.008934018445014
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83847132967733,
            21.008934018445014
          ],
          [
            105.8383650927189,
            21.00885601029862
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "16",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LKL2",
        "from_latitude": 21.008934018445014,
        "to_latitude": 21.00885601029862,
        "to_longitude": 105.8383650927189,
        "from_longitude": 105.83847132967733,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7274,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83817465587941,
          21.00885601029862,
          105.8383650927189,
          21.00901375649673
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8383650927189,
            21.00885601029862
          ],
          [
            105.83817465587941,
            21.00901375649673
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LKL2",
        "from_latitude": 21.00885601029862,
        "to_latitude": 21.00901375649673,
        "to_longitude": 105.83817465587941,
        "from_longitude": 105.8383650927189,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7275,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83807541413906,
          21.00901375649673,
          105.83817465587941,
          21.009128936155957
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83817465587941,
            21.00901375649673
          ],
          [
            105.83807541413906,
            21.009128936155957
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LKL2",
        "from_latitude": 21.00901375649673,
        "to_latitude": 21.009128936155957,
        "to_longitude": 105.83807541413906,
        "from_longitude": 105.83817465587941,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7276,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83795471473675,
          21.009128936155957,
          105.83807541413906,
          21.009254131335375
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83807541413906,
            21.009128936155957
          ],
          [
            105.83795471473675,
            21.009254131335375
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LKL2",
        "from_latitude": 21.009128936155957,
        "to_latitude": 21.009254131335375,
        "to_longitude": 105.83795471473675,
        "from_longitude": 105.83807541413906,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7277,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83784206195767,
          21.009254131335375,
          105.83795471473675,
          21.00937181470926
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83795471473675,
            21.009254131335375
          ],
          [
            105.83784206195767,
            21.00937181470926
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LKL2",
        "from_latitude": 21.009254131335375,
        "to_latitude": 21.00937181470926,
        "to_longitude": 105.83784206195767,
        "from_longitude": 105.83795471473675,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7278,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83776159568949,
          21.00937181470926,
          105.83784206195767,
          21.009499513583815
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83784206195767,
            21.00937181470926
          ],
          [
            105.83776159568949,
            21.009499513583815
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LKL2",
        "from_latitude": 21.00937181470926,
        "to_latitude": 21.009499513583815,
        "to_longitude": 105.83776159568949,
        "from_longitude": 105.83784206195767,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7279,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83776159568949,
          21.009499513583815,
          105.83786351962861,
          21.009564614929026
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83776159568949,
            21.009499513583815
          ],
          [
            105.83786351962861,
            21.009564614929026
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LKL2",
        "from_latitude": 21.009499513583815,
        "to_latitude": 21.009564614929026,
        "to_longitude": 105.83786351962861,
        "from_longitude": 105.83776159568949,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7280,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83797885461541,
          21.009351783503572,
          105.8381076006499,
          21.009481986293675
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83797885461541,
            21.009481986293675
          ],
          [
            105.8381076006499,
            21.009351783503572
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "13",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LKL2",
        "from_latitude": 21.009481986293675,
        "to_latitude": 21.009351783503572,
        "to_longitude": 105.8381076006499,
        "from_longitude": 105.83797885461541,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7281,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8381076006499,
          21.009234100113876,
          105.83821757122124,
          21.009351783503572
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8381076006499,
            21.009351783503572
          ],
          [
            105.83821757122124,
            21.009234100113876
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "14",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LKL2",
        "from_latitude": 21.009351783503572,
        "to_latitude": 21.009234100113876,
        "to_longitude": 105.83821757122124,
        "from_longitude": 105.8381076006499,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7282,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83821757122124,
          21.009128936155957,
          105.83833558841579,
          21.009234100113876
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83821757122124,
            21.009234100113876
          ],
          [
            105.83833558841579,
            21.009128936155957
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "15",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LKL2",
        "from_latitude": 21.009234100113876,
        "to_latitude": 21.009128936155957,
        "to_longitude": 105.83833558841579,
        "from_longitude": 105.83821757122124,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7283,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83833558841579,
          21.008934018445014,
          105.83847132967733,
          21.009128936155957
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83833558841579,
            21.009128936155957
          ],
          [
            105.83847132967733,
            21.008934018445014
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "LKL2",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Dào Duy Anh",
        "diemDau": "16",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "LKL2",
        "from_latitude": 21.009128936155957,
        "to_latitude": 21.008934018445014,
        "to_longitude": 105.83847132967733,
        "from_longitude": 105.83833558841579,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7284,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83969874917004,
          21.009045055325174,
          105.83979998547566,
          21.009216572790056
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83979998547566,
            21.009045055325174
          ],
          [
            105.83969874917004,
            21.009143231952038
          ],
          [
            105.83977852783167,
            21.009216572790056
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Kim Hoa",
        "diemDau": "06",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.009045055325174,
        "to_latitude": 21.009216572790056,
        "to_longitude": 105.83977852783167,
        "from_longitude": 105.83979998547566,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7285,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83923672158035,
          21.009537033627286,
          105.83940301855915,
          21.009610937022465
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83923672158035,
            21.009610937022465
          ],
          [
            105.83930790475131,
            21.009537033627286
          ],
          [
            105.83940301855915,
            21.009597165588875
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 88 Kim Hoa",
        "diemDau": "04",
        "diemCuoi": "4-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.009610937022465,
        "to_latitude": 21.009597165588875,
        "to_longitude": 105.83940301855915,
        "from_longitude": 105.83923672158035,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7286,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83952371794346,
          21.00928741775022,
          105.83962564190952,
          21.00938433420903
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83952371794346,
            21.0093129730335
          ],
          [
            105.8395500696946,
            21.00928741775022
          ],
          [
            105.83962564190952,
            21.00938433420903
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 78 Kim Hoa",
        "diemDau": "05",
        "diemCuoi": "5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.0093129730335,
        "to_latitude": 21.00938433420903,
        "to_longitude": 105.83962564190952,
        "from_longitude": 105.83952371794346,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7287,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83952371794346,
          21.009045055325174,
          105.83979998547566,
          21.0093129730335
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83952371794346,
            21.0093129730335
          ],
          [
            105.83979998547566,
            21.009045055325174
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Kim Hoa",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.0093129730335,
        "to_latitude": 21.009045055325174,
        "to_longitude": 105.83979998547566,
        "from_longitude": 105.83952371794346,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7288,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83940301855915,
          21.009597165588875,
          105.8395961376064,
          21.009744895426962
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83940301855915,
            21.009597165588875
          ],
          [
            105.8395961376064,
            21.009744895426962
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 88 Kim Hoa",
        "diemDau": "4-3",
        "diemCuoi": "4-3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.009597165588875,
        "to_latitude": 21.009744895426962,
        "to_longitude": 105.8395961376064,
        "from_longitude": 105.83940301855915,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7289,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8393949719359,
          21.009744895426962,
          105.8395961376064,
          21.01000029242809
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8395961376064,
            21.009744895426962
          ],
          [
            105.8393949719359,
            21.01000029242809
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 88 Kim Hoa",
        "diemDau": "4-3-1",
        "diemCuoi": "4-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.009744895426962,
        "to_latitude": 21.01000029242809,
        "to_longitude": 105.8393949719359,
        "from_longitude": 105.8395961376064,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7290,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8395961376064,
          21.009744895426962,
          105.83981876095679,
          21.009970245744704
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8395961376064,
            21.009744895426962
          ],
          [
            105.83981876095679,
            21.009970245744704
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 88 Kim Hoa",
        "diemDau": "4-3-1",
        "diemCuoi": "4-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.009744895426962,
        "to_latitude": 21.009970245744704,
        "to_longitude": 105.83981876095679,
        "from_longitude": 105.8395961376064,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7291,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83971951920745,
          21.009970245744704,
          105.83992202600052,
          21.010259862135424
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83981876095679,
            21.009970245744704
          ],
          [
            105.83971951920745,
            21.010126738824678
          ],
          [
            105.83992202600052,
            21.010259862135424
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 49,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 88 Kim Hoa",
        "diemDau": "4-5",
        "diemCuoi": "4-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.009970245744704,
        "to_latitude": 21.010259862135424,
        "to_longitude": 105.83992202600052,
        "from_longitude": 105.83981876095679,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7292,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.839899227216,
          21.010259862135424,
          105.84003333767495,
          21.010447236132958
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83992202600052,
            21.010259862135424
          ],
          [
            105.839899227216,
            21.01038213517294
          ],
          [
            105.8399475069733,
            21.010447236132958
          ],
          [
            105.84003333767495,
            21.010368363808034
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 88 Kim Hoa",
        "diemDau": "4-6",
        "diemCuoi": "4-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010259862135424,
        "to_latitude": 21.010368363808034,
        "to_longitude": 105.84003333767495,
        "from_longitude": 105.83992202600052,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7293,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84003333767495,
          21.010368363808034,
          105.84024791440211,
          21.01052110064522
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84003333767495,
            21.010368363808034
          ],
          [
            105.84024791440211,
            21.01052110064522
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 88 Kim Hoa",
        "diemDau": "4-7",
        "diemCuoi": "4-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010368363808034,
        "to_latitude": 21.01052110064522,
        "to_longitude": 105.84024791440211,
        "from_longitude": 105.84003333767495,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7294,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8400628419781,
          21.01052110064522,
          105.84024791440211,
          21.01068134896098
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84024791440211,
            21.01052110064522
          ],
          [
            105.8400628419781,
            21.01068134896098
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 422 Lê Duẩn",
        "diemDau": "4-8",
        "diemCuoi": "4-18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.01052110064522,
        "to_latitude": 21.01068134896098,
        "to_longitude": 105.8400628419781,
        "from_longitude": 105.84024791440211,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7295,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8398133965413,
          21.01062501168599,
          105.8400628419781,
          21.010702631931693
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8400628419781,
            21.01068134896098
          ],
          [
            105.84001724441053,
            21.010702631931693
          ],
          [
            105.83987508733735,
            21.01062501168599
          ],
          [
            105.8398133965413,
            21.010671333446805
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 422 Lê Duẩn",
        "diemDau": "4-18",
        "diemCuoi": "4-19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.01068134896098,
        "to_latitude": 21.010671333446805,
        "to_longitude": 105.8398133965413,
        "from_longitude": 105.8400628419781,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7296,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8399340959436,
          21.01068134896098,
          105.8400628419781,
          21.010834085477704
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8400628419781,
            21.01068134896098
          ],
          [
            105.8399340959436,
            21.010834085477704
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 422 Lê Duẩn",
        "diemDau": "4-18",
        "diemCuoi": "4-21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.01068134896098,
        "to_latitude": 21.010834085477704,
        "to_longitude": 105.8399340959436,
        "from_longitude": 105.8400628419781,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7297,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83969806155449,
          21.010671333446805,
          105.8398133965413,
          21.010779000194947
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8398133965413,
            21.010671333446805
          ],
          [
            105.83969806155449,
            21.010779000194947
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 422 Lê Duẩn",
        "diemDau": "4-19",
        "diemCuoi": "4-20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010671333446805,
        "to_latitude": 21.010779000194947,
        "to_longitude": 105.83969806155449,
        "from_longitude": 105.8398133965413,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7298,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83974902351954,
          21.010834085477704,
          105.8399340959436,
          21.01106694576733
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8399340959436,
            21.010834085477704
          ],
          [
            105.83974902351954,
            21.01106694576733
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 422 Lê Duẩn",
        "diemDau": "4-21",
        "diemCuoi": "4-22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010834085477704,
        "to_latitude": 21.01106694576733,
        "to_longitude": 105.83974902351954,
        "from_longitude": 105.8399340959436,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7299,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83960150203089,
          21.010981814090083,
          105.83974902351954,
          21.01106694576733
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83974902351954,
            21.01106694576733
          ],
          [
            105.83960150203089,
            21.010981814090083
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 422 Lê Duẩn",
        "diemDau": "4-22",
        "diemCuoi": "4-23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.01106694576733,
        "to_latitude": 21.010981814090083,
        "to_longitude": 105.83960150203089,
        "from_longitude": 105.83974902351954,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7300,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84024791440211,
          21.010373371575746,
          105.84036593159666,
          21.01052110064522
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84024791440211,
            21.01052110064522
          ],
          [
            105.84036593159666,
            21.010373371575746
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 422 Lê Duẩn",
        "diemDau": "4-8",
        "diemCuoi": "4-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.01052110064522,
        "to_latitude": 21.010373371575746,
        "to_longitude": 105.84036593159666,
        "from_longitude": 105.84024791440211,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7301,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84020768126803,
          21.010248177334756,
          105.84036593159666,
          21.010373371575746
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84036593159666,
            21.010373371575746
          ],
          [
            105.84020768126803,
            21.010248177334756
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 422 Lê Duẩn",
        "diemDau": "4-8",
        "diemCuoi": "4-9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010373371575746,
        "to_latitude": 21.010248177334756,
        "to_longitude": 105.84020768126803,
        "from_longitude": 105.84036593159666,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7302,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84036593159666,
          21.01019309183563,
          105.84056977948389,
          21.010373371575746
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84036593159666,
            21.010373371575746
          ],
          [
            105.84056977948389,
            21.01019309183563
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 422 Lê Duẩn",
        "diemDau": "4-9",
        "diemCuoi": "4-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010373371575746,
        "to_latitude": 21.01019309183563,
        "to_longitude": 105.84056977948389,
        "from_longitude": 105.84036593159666,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7303,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84056977948389,
          21.010130494653016,
          105.84064488133657,
          21.01019309183563
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84056977948389,
            21.01019309183563
          ],
          [
            105.84064488133657,
            21.010130494653016
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 5,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 422 Lê Duẩn",
        "diemDau": "4-10",
        "diemCuoi": "4-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.01019309183563,
        "to_latitude": 21.010130494653016,
        "to_longitude": 105.84064488133657,
        "from_longitude": 105.84056977948389,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7304,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84049199540547,
          21.00994771072798,
          105.84064488133657,
          21.010130494653016
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84064488133657,
            21.010130494653016
          ],
          [
            105.84049199540547,
            21.00998151325557
          ],
          [
            105.8405241819253,
            21.00994771072798
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 422 Lê Duẩn",
        "diemDau": "4-11",
        "diemCuoi": "4-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010130494653016,
        "to_latitude": 21.00994771072798,
        "to_longitude": 105.8405241819253,
        "from_longitude": 105.84064488133657,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7305,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8405241819253,
          21.00978746162418,
          105.84060733041021,
          21.00994771072798
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8405241819253,
            21.00994771072798
          ],
          [
            105.84055905063494,
            21.00986633425544
          ],
          [
            105.84060733041021,
            21.00978746162418
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 422 Lê Duẩn",
        "diemDau": "4-12",
        "diemCuoi": "4-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.00994771072798,
        "to_latitude": 21.00978746162418,
        "to_longitude": 105.84060733041021,
        "from_longitude": 105.8405241819253,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7306,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84064488133657,
          21.010130494653016,
          105.84073607643575,
          21.010140510203513
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84064488133657,
            21.010130494653016
          ],
          [
            105.84073607643575,
            21.010140510203513
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 378 Lê Duẩn",
        "diemDau": "4-11",
        "diemCuoi": "4-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010130494653016,
        "to_latitude": 21.010140510203513,
        "to_longitude": 105.84073607643575,
        "from_longitude": 105.84064488133657,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7307,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84064488133657,
          21.010130494653016,
          105.84077899178656,
          21.01040341817795
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84064488133657,
            21.010130494653016
          ],
          [
            105.840733394219,
            21.01018432823573
          ],
          [
            105.84077899178656,
            21.01040341817795
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 358 Lê Duẩn",
        "diemDau": "4-11",
        "diemCuoi": "4-14-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010130494653016,
        "to_latitude": 21.01040341817795,
        "to_longitude": 105.84077899178656,
        "from_longitude": 105.84064488133657,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7308,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84077899178656,
          21.010248177334756,
          105.8408809157257,
          21.01040341817795
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84077899178656,
            21.01040341817795
          ],
          [
            105.84085141141357,
            21.01037462352209
          ],
          [
            105.8408809157257,
            21.010248177334756
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 88 Kim Hoa",
        "diemDau": "4-14-1",
        "diemCuoi": "4-14-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.01040341817795,
        "to_latitude": 21.010248177334756,
        "to_longitude": 105.8408809157257,
        "from_longitude": 105.84077899178656,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7309,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84077899178656,
          21.010400914295015,
          105.84103380163882,
          21.01040341817795
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84077899178656,
            21.01040341817795
          ],
          [
            105.84103380163882,
            21.010400914295015
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 358 Lê Duẩn",
        "diemDau": "4-14-1",
        "diemCuoi": "4-14-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.01040341817795,
        "to_latitude": 21.010400914295015,
        "to_longitude": 105.84103380163882,
        "from_longitude": 105.84077899178656,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7310,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84064488133657,
          21.009937695164584,
          105.84088896235788,
          21.010130494653016
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84064488133657,
            21.010130494653016
          ],
          [
            105.84088896235788,
            21.009937695164584
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 422 Lê Duẩn",
        "diemDau": "4-11",
        "diemCuoi": "4-15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010130494653016,
        "to_latitude": 21.009937695164584,
        "to_longitude": 105.84088896235788,
        "from_longitude": 105.84064488133657,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7311,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84088896235788,
          21.009799981091593,
          105.84106867035746,
          21.009937695164584
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84088896235788,
            21.009937695164584
          ],
          [
            105.84106867035746,
            21.009799981091593
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 422 Lê Duẩn",
        "diemDau": "4-15",
        "diemCuoi": "4-16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.009937695164584,
        "to_latitude": 21.009799981091593,
        "to_longitude": 105.84106867035746,
        "from_longitude": 105.84088896235788,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7312,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8409774752493,
          21.009634724035863,
          105.84106867035746,
          21.009799981091593
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84106867035746,
            21.009799981091593
          ],
          [
            105.8409774752493,
            21.009634724035863
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 422 Lê Duẩn",
        "diemDau": "4-16",
        "diemCuoi": "4-17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.009799981091593,
        "to_latitude": 21.009634724035863,
        "to_longitude": 105.8409774752493,
        "from_longitude": 105.84106867035746,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7313,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83990459164944,
          21.009734879849113,
          105.84006284198708,
          21.009915160142967
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83990459164944,
            21.009734879849113
          ],
          [
            105.84006284198708,
            21.009915160142967
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 88 Kim Hoa",
        "diemDau": "5-4",
        "diemCuoi": "5-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.009734879849113,
        "to_latitude": 21.009915160142967,
        "to_longitude": 105.84006284198708,
        "from_longitude": 105.83990459164944,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7314,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8390623780232,
          21.009439420007613,
          105.83923672158035,
          21.009610937022465
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83923672158035,
            21.009610937022465
          ],
          [
            105.8390623780232,
            21.009439420007613
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 88 Kim Hoa",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.009610937022465,
        "to_latitude": 21.009439420007613,
        "to_longitude": 105.8390623780232,
        "from_longitude": 105.83923672158035,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7315,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83909456452508,
          21.009239107917196,
          105.83930645902653,
          21.009610937022465
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83923672158035,
            21.009610937022465
          ],
          [
            105.83930645902653,
            21.00945819925592
          ],
          [
            105.83909456452508,
            21.009239107917196
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 88 Kim Hoa",
        "diemDau": "04",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.009610937022465,
        "to_latitude": 21.009239107917196,
        "to_longitude": 105.83909456452508,
        "from_longitude": 105.83923672158035,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7316,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83819066009382,
          21.010383387110785,
          105.83837036809338,
          21.010407174006044
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83837036809338,
            21.010383387110785
          ],
          [
            105.83823491654849,
            21.010407174006044
          ],
          [
            105.83819066009382,
            21.010385890993998
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Giếng Ngọc Kim Hoa",
        "diemDau": "01",
        "diemCuoi": "1-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010383387110785,
        "to_latitude": 21.010385890993998,
        "to_longitude": 105.83819066009382,
        "from_longitude": 105.83837036809338,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7317,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83837036809338,
          21.010383387110785,
          105.83853130063875,
          21.010553651098068
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83837036809338,
            21.010383387110785
          ],
          [
            105.83853130063875,
            21.010553651098068
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 138 Kim Hoa",
        "diemDau": "01",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010383387110785,
        "to_latitude": 21.010553651098068,
        "to_longitude": 105.83853130063875,
        "from_longitude": 105.83837036809338,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7318,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83853130063875,
          21.010553651098068,
          105.83874855956468,
          21.010779000194947
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83853130063875,
            21.010553651098068
          ],
          [
            105.83874855956468,
            21.010779000194947
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 138 Kim Hoa",
        "diemDau": "1-1",
        "diemCuoi": "1-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010553651098068,
        "to_latitude": 21.010779000194947,
        "to_longitude": 105.83874855956468,
        "from_longitude": 105.83853130063875,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7319,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83874855956468,
          21.010702631930855,
          105.83899264058597,
          21.010854116484378
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83874855956468,
            21.010779000194947
          ],
          [
            105.83882232033147,
            21.010702631930855
          ],
          [
            105.83899264058597,
            21.010854116484378
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 138 Kim Hoa",
        "diemDau": "1-2",
        "diemCuoi": "1-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010779000194947,
        "to_latitude": 21.010854116484378,
        "to_longitude": 105.83899264058597,
        "from_longitude": 105.83874855956468,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7320,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83899264058597,
          21.010825321913845,
          105.83918039521774,
          21.010894178490428
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83899264058597,
            21.010854116484378
          ],
          [
            105.8390476258896,
            21.010825321913845
          ],
          [
            105.83918039521774,
            21.010894178490428
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 138 Kim Hoa",
        "diemDau": "1-3",
        "diemCuoi": "1-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010854116484378,
        "to_latitude": 21.010894178490428,
        "to_longitude": 105.83918039521774,
        "from_longitude": 105.83899264058597,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7321,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83865736445654,
          21.010188084061827,
          105.83874855956468,
          21.01031327835326
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83865736445654,
            21.010188084061827
          ],
          [
            105.83874855956468,
            21.01031327835326
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Kim Hoa",
        "diemDau": "02",
        "diemCuoi": "2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010188084061827,
        "to_latitude": 21.01031327835326,
        "to_longitude": 105.83874855956468,
        "from_longitude": 105.83865736445654,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7322,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83874855956468,
          21.01031327835326,
          105.83889339885462,
          21.010420945359815
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83874855956468,
            21.01031327835326
          ],
          [
            105.83889339885462,
            21.010420945359815
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Kim Hoa",
        "diemDau": "2-1",
        "diemCuoi": "2-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.01031327835326,
        "to_latitude": 21.010420945359815,
        "to_longitude": 105.83889339885462,
        "from_longitude": 105.83874855956468,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7323,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83889339885462,
          21.010420945359815,
          105.839054331391,
          21.010528612288674
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83889339885462,
            21.010420945359815
          ],
          [
            105.839054331391,
            21.010528612288674
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Kim Hoa",
        "diemDau": "2-2",
        "diemCuoi": "2-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010420945359815,
        "to_latitude": 21.010528612288674,
        "to_longitude": 105.839054331391,
        "from_longitude": 105.83889339885462,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7324,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.839054331391,
          21.010528612288674,
          105.83938960752043,
          21.010748953668415
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.839054331391,
            21.010528612288674
          ],
          [
            105.83938960752043,
            21.010748953668415
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Kim Hoa",
        "diemDau": "2-3",
        "diemCuoi": "2-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010528612288674,
        "to_latitude": 21.010748953668415,
        "to_longitude": 105.83938960752043,
        "from_longitude": 105.839054331391,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7325,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83901275717099,
          21.010528612288674,
          105.83911870441273,
          21.010691364475306
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.839054331391,
            21.010528612288674
          ],
          [
            105.83901275717099,
            21.010622507805955
          ],
          [
            105.83911870441273,
            21.010691364475306
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Kim Hoa",
        "diemDau": "2-3",
        "diemCuoi": "2-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010528612288674,
        "to_latitude": 21.010691364475306,
        "to_longitude": 105.83911870441273,
        "from_longitude": 105.839054331391,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7326,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83865736445654,
          21.010028317496364,
          105.8389872761705,
          21.010188084061827
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83865736445654,
            21.010188084061827
          ],
          [
            105.83882679775833,
            21.010028317496364
          ],
          [
            105.8389872761705,
            21.010188084061827
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 56,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 116 Kim Hoa",
        "diemDau": "02",
        "diemCuoi": "3-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010188084061827,
        "to_latitude": 21.010188084061827,
        "to_longitude": 105.8389872761705,
        "from_longitude": 105.83865736445654,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7327,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8389872761705,
          21.010188084061827,
          105.83926086148594,
          21.010393402644283
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8389872761705,
            21.010188084061827
          ],
          [
            105.83926086148594,
            21.010393402644283
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 116 Kim Hoa",
        "diemDau": "3-1",
        "diemCuoi": "3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010188084061827,
        "to_latitude": 21.010393402644283,
        "to_longitude": 105.83926086148594,
        "from_longitude": 105.8389872761705,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7328,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83892290314876,
          21.00993769516373,
          105.83901946267237,
          21.01000029242809
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83892290314876,
            21.00993769516373
          ],
          [
            105.83901946267237,
            21.01000029242809
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 110 Kim Hoa",
        "diemDau": "03",
        "diemCuoi": "3-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.00993769516373,
        "to_latitude": 21.01000029242809,
        "to_longitude": 105.83901946267237,
        "from_longitude": 105.83892290314876,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7329,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83901946267237,
          21.01000029242809,
          105.8391696663778,
          21.01013550242795
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83901946267237,
            21.01000029242809
          ],
          [
            105.8391696663778,
            21.01013550242795
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 110 Kim Hoa",
        "diemDau": "3-4",
        "diemCuoi": "3-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.01000029242809,
        "to_latitude": 21.01013550242795,
        "to_longitude": 105.8391696663778,
        "from_longitude": 105.83901946267237,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7330,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8391696663778,
          21.01013550242795,
          105.83935742100958,
          21.01032830166158
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8391696663778,
            21.01013550242795
          ],
          [
            105.83935742100958,
            21.01032830166158
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 138 Kim Hoa",
        "diemDau": "3-5",
        "diemCuoi": "3-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.01013550242795,
        "to_latitude": 21.01032830166158,
        "to_longitude": 105.83935742100958,
        "from_longitude": 105.8391696663778,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7331,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83935742100958,
          21.010309522528676,
          105.83955054005682,
          21.01039841041119
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83935742100958,
            21.01032830166158
          ],
          [
            105.83939094864228,
            21.010367111869222
          ],
          [
            105.8394526394473,
            21.010309522528676
          ],
          [
            105.83955054005682,
            21.01039841041119
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 110 Kim Hoa",
        "diemDau": "3-6",
        "diemCuoi": "3-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.01032830166158,
        "to_latitude": 21.01039841041119,
        "to_longitude": 105.83955054005682,
        "from_longitude": 105.83935742100958,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7332,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83886389455148,
          21.009707337006883,
          105.83892290314876,
          21.00993769516373
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83892290314876,
            21.00993769516373
          ],
          [
            105.83886389455148,
            21.009707337006883
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 119 Kim Hoa",
        "diemDau": "03",
        "diemCuoi": "3-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.00993769516373,
        "to_latitude": 21.009707337006883,
        "to_longitude": 105.83886389455148,
        "from_longitude": 105.83892290314876,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7333,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8383247705438,
          21.010383387110785,
          105.83837036809338,
          21.01060873646403
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83837036809338,
            21.010383387110785
          ],
          [
            105.8383247705438,
            21.01060873646403
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKL3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đình Làng Kim Liên",
        "diemDau": "01",
        "diemCuoi": "1-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Kim Liên 3",
        "from_latitude": 21.010383387110785,
        "to_latitude": 21.01060873646403,
        "to_longitude": 105.8383247705438,
        "from_longitude": 105.83837036809338,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7334,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83505649884711,
          21.00743378307557,
          105.8352697344614,
          21.007610310255025
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83505649884711,
            21.00743378307557
          ],
          [
            105.8352697344614,
            21.007610310255025
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Tích Trí",
        "diemDau": "B8AKL/1",
        "diemCuoi": "B8AKL/2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00743378307557,
        "to_latitude": 21.007610310255025,
        "to_longitude": 105.8352697344614,
        "from_longitude": 105.83505649884711,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7335,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8352697344614,
          21.007610310255025,
          105.83559562286375,
          21.007885742314606
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8352697344614,
            21.007610310255025
          ],
          [
            105.83559562286375,
            21.007885742314606
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Tích Trí",
        "diemDau": "B8AKL/2",
        "diemCuoi": "B8AKL/3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.007610310255025,
        "to_latitude": 21.007885742314606,
        "to_longitude": 105.83559562286375,
        "from_longitude": 105.8352697344614,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7336,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83559562286375,
          21.007885742314606,
          105.83584372718768,
          21.008101079755566
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83559562286375,
            21.007885742314606
          ],
          [
            105.83584372718768,
            21.008101079755566
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Tích Trí",
        "diemDau": "B8AKL/3",
        "diemCuoi": "B8AKL/4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.007885742314606,
        "to_latitude": 21.008101079755566,
        "to_longitude": 105.83584372718768,
        "from_longitude": 105.83559562286375,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7337,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83559562286375,
          21.00774552242087,
          105.83570961672872,
          21.007885742314606
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83559562286375,
            21.007885742314606
          ],
          [
            105.83570961672872,
            21.00774552242087
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "diemDau": "B8AKL/3",
        "diemCuoi": "B8AKL/17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.007885742314606,
        "to_latitude": 21.00774552242087,
        "to_longitude": 105.83570961672872,
        "from_longitude": 105.83559562286375,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7338,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83570961672872,
          21.00752768053729,
          105.8359054179927,
          21.00774552242087
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83570961672872,
            21.00774552242087
          ],
          [
            105.8359054179927,
            21.00752768053729
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "diemDau": "B8AKL/17",
        "diemCuoi": "B8AKL/18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00774552242087,
        "to_latitude": 21.00752768053729,
        "to_longitude": 105.8359054179927,
        "from_longitude": 105.83570961672872,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7339,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8359054179927,
          21.007314846206057,
          105.83610926587991,
          21.00752768053729
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8359054179927,
            21.00752768053729
          ],
          [
            105.83610926587991,
            21.007314846206057
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "diemDau": "B8AKL/18",
        "diemCuoi": "B8AKL/19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00752768053729,
        "to_latitude": 21.007314846206057,
        "to_longitude": 105.83610926587991,
        "from_longitude": 105.8359054179927,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7340,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83610926587991,
          21.007132058829715,
          105.83630909046448,
          21.007314846206057
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83610926587991,
            21.007314846206057
          ],
          [
            105.83630909046448,
            21.007132058829715
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "diemDau": "B8AKL/19",
        "diemCuoi": "B8AKL/20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.007314846206057,
        "to_latitude": 21.007132058829715,
        "to_longitude": 105.83630909046448,
        "from_longitude": 105.83610926587991,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7341,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83544541914935,
          21.007809372568666,
          105.83559562286375,
          21.007885742314606
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83559562286375,
            21.007885742314606
          ],
          [
            105.83544541914935,
            21.007809372568666
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Tích Trí",
        "diemDau": "B8AKL/3",
        "diemCuoi": "B8AKL/3-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.007885742314606,
        "to_latitude": 21.007809372568666,
        "to_longitude": 105.83544541914935,
        "from_longitude": 105.83559562286375,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7342,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83584372718768,
          21.008101079755566,
          105.83616156895785,
          21.008375258951986
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83584372718768,
            21.008101079755566
          ],
          [
            105.83616156895785,
            21.008375258951986
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Tích Trí",
        "diemDau": "B8AKL/4",
        "diemCuoi": "B8AKL/4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.008101079755566,
        "to_latitude": 21.008375258951986,
        "to_longitude": 105.83616156895785,
        "from_longitude": 105.83584372718768,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7343,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83616156895785,
          21.008375258951986,
          105.83633188923031,
          21.008512974338434
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83616156895785,
            21.008375258951986
          ],
          [
            105.83633188923031,
            21.008512974338434
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Tích Trí",
        "diemDau": "B8AKL/5",
        "diemCuoi": "B8AKL/6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.008375258951986,
        "to_latitude": 21.008512974338434,
        "to_longitude": 105.83633188923031,
        "from_longitude": 105.83616156895785,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7344,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83633188923031,
          21.008512974338434,
          105.83667521198296,
          21.008792160598748
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83633188923031,
            21.008512974338434
          ],
          [
            105.83667521198296,
            21.008792160598748
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Tích Trí",
        "diemDau": "B8AKL/6",
        "diemCuoi": "B8AKL/7",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.008512974338434,
        "to_latitude": 21.008792160598748,
        "to_longitude": 105.83667521198296,
        "from_longitude": 105.83633188923031,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7345,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83633188923031,
          21.008512974338434,
          105.83647270520862,
          21.00864067394815
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83633188923031,
            21.008512974338434
          ],
          [
            105.83647270520862,
            21.00864067394815
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Tích Trí",
        "diemDau": "B8AKL/6",
        "diemCuoi": "B8AKL/6-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.008512974338434,
        "to_latitude": 21.00864067394815,
        "to_longitude": 105.83647270520862,
        "from_longitude": 105.83633188923031,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7346,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83667521198296,
          21.008792160598748,
          105.83685626109539,
          21.00893613537229
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83667521198296,
            21.008792160598748
          ],
          [
            105.83685626109539,
            21.00893613537229
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Tích Trí",
        "diemDau": "B8AKL/7",
        "diemCuoi": "B8AKL/8",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.008792160598748,
        "to_latitude": 21.00893613537229,
        "to_longitude": 105.83685626109539,
        "from_longitude": 105.83667521198296,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7347,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83685626109539,
          21.00893613537229,
          105.8372424991899,
          21.00923535206474
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83685626109539,
            21.00893613537229
          ],
          [
            105.8372424991899,
            21.00923535206474
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 66,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Tích Trí",
        "diemDau": "B8AKL/8",
        "diemCuoi": "B8AKL/9",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00893613537229,
        "to_latitude": 21.00923535206474,
        "to_longitude": 105.8372424991899,
        "from_longitude": 105.83685626109539,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7348,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8372424991899,
          21.00923535206474,
          105.83751474341047,
          21.009481986292844
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8372424991899,
            21.00923535206474
          ],
          [
            105.83751474341047,
            21.009481986292844
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Tích Trí",
        "diemDau": "B8AKL/9",
        "diemCuoi": "B8AKL/10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00923535206474,
        "to_latitude": 21.009481986292844,
        "to_longitude": 105.83751474341047,
        "from_longitude": 105.8372424991899,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7349,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83740242588266,
          21.009481986292844,
          105.83751474341047,
          21.009595600655377
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83751474341047,
            21.009481986292844
          ],
          [
            105.83740242588266,
            21.009595600655377
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 21 Phạm Ngọc Thạch",
        "diemDau": "B8AKL/10",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.009481986292844,
        "to_latitude": 21.009595600655377,
        "to_longitude": 105.83740242588266,
        "from_longitude": 105.83751474341047,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7350,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83725456912474,
          21.00952330061749,
          105.83740242588266,
          21.009595600655377
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83740242588266,
            21.009595600655377
          ],
          [
            105.83725456912474,
            21.00952330061749
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B1 TT Kim Liên",
        "diemDau": "",
        "diemCuoi": "B8AKL?10-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.009595600655377,
        "to_latitude": 21.00952330061749,
        "to_longitude": 105.83725456912474,
        "from_longitude": 105.83740242588266,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7351,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83705072123752,
          21.00936805885929,
          105.83725456912474,
          21.00952330061749
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83725456912474,
            21.00952330061749
          ],
          [
            105.83705072123752,
            21.00936805885929
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B1 TT Kim Liên",
        "diemDau": "B8AKL/10-1",
        "diemCuoi": "B8AKL/10-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00952330061749,
        "to_latitude": 21.00936805885929,
        "to_longitude": 105.83705072123752,
        "from_longitude": 105.83725456912474,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7352,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8368468733593,
          21.009215320841587,
          105.83705072123752,
          21.00936805885929
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83705072123752,
            21.00936805885929
          ],
          [
            105.8368468733593,
            21.009215320841587
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B1 TT Kim Liên",
        "diemDau": "B8AKL/10-2",
        "diemCuoi": "B8AKl/10-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00936805885929,
        "to_latitude": 21.009215320841587,
        "to_longitude": 105.8368468733593,
        "from_longitude": 105.83705072123752,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7353,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83709128963187,
          21.009595600655377,
          105.83740242588266,
          21.00992486272372
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83740242588266,
            21.009595600655377
          ],
          [
            105.83709128963187,
            21.00992486272372
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 21 Phạm Ngọc Thạch",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.009595600655377,
        "to_latitude": 21.00992486272372,
        "to_longitude": 105.83709128963187,
        "from_longitude": 105.83740242588266,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7354,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83691946066585,
          21.009763518141614,
          105.83709128963187,
          21.00992486272372
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83709128963187,
            21.00992486272372
          ],
          [
            105.83691946066585,
            21.009763518141614
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 21 Phạm Ngọc Thạch",
        "diemDau": "",
        "diemCuoi": "B8AKL/12-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00992486272372,
        "to_latitude": 21.009763518141614,
        "to_longitude": 105.83691946066585,
        "from_longitude": 105.83709128963187,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7355,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83667269743678,
          21.00955319089715,
          105.83691946066585,
          21.009763518141614
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83691946066585,
            21.009763518141614
          ],
          [
            105.83667269743678,
            21.00955319089715
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 21 Phạm Ngoc Thạch",
        "diemDau": "B8AKL/12-2",
        "diemCuoi": "B8AKL/12-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.009763518141614,
        "to_latitude": 21.00955319089715,
        "to_longitude": 105.83667269743678,
        "from_longitude": 105.83691946066585,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7356,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83647672852922,
          21.009343019848405,
          105.83667269743678,
          21.00955319089715
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83667269743678,
            21.00955319089715
          ],
          [
            105.83647672852922,
            21.009343019848405
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 21 Phạm Ngoc Thạch",
        "diemDau": "B8AKL/12-1",
        "diemCuoi": "B8AKL/12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00955319089715,
        "to_latitude": 21.009343019848405,
        "to_longitude": 105.83647672852922,
        "from_longitude": 105.83667269743678,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7357,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83620716652537,
          21.009343019848405,
          105.83647672852922,
          21.009696069480345
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83647672852922,
            21.009343019848405
          ],
          [
            105.83620716652537,
            21.009696069480345
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 21 Phạm Ngọc Thạch",
        "diemDau": "B8AKL/12",
        "diemCuoi": "B8AKL/11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.009343019848405,
        "to_latitude": 21.009696069480345,
        "to_longitude": 105.83620716652537,
        "from_longitude": 105.83647672852922,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7358,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83620716652537,
          21.009696069480345,
          105.83642978986678,
          21.00985256284457
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83620716652537,
            21.009696069480345
          ],
          [
            105.83642978986678,
            21.00985256284457
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 21 Phạm Ngọc Thạch",
        "diemDau": "B8AKL/11",
        "diemCuoi": "B8AKL/11-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.009696069480345,
        "to_latitude": 21.00985256284457,
        "to_longitude": 105.83642978986678,
        "from_longitude": 105.83620716652537,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7359,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83642978986678,
          21.00985256284457,
          105.83668459972803,
          21.010061637722245
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83642978986678,
            21.00985256284457
          ],
          [
            105.83668459972803,
            21.010061637722245
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 47,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 21 Phạm Ngọc Thạch",
        "diemDau": "B8AKL/11-1",
        "diemCuoi": "B8AKL/11-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00985256284457,
        "to_latitude": 21.010061637722245,
        "to_longitude": 105.83668459972803,
        "from_longitude": 105.83642978986678,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7360,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83688978871011,
          21.009286355410858,
          105.8369416777995,
          21.00935178350272
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8369416777995,
            21.009286355410858
          ],
          [
            105.83688978871011,
            21.00935178350272
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nhà B1 TT Kim Liên",
        "diemDau": "",
        "diemCuoi": "B8AKL/10-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.009286355410858,
        "to_latitude": 21.00935178350272,
        "to_longitude": 105.83688978871011,
        "from_longitude": 105.8369416777995,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7361,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83496178331322,
          21.008893099448073,
          105.83527023735627,
          21.009125962767406
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83496178331322,
            21.008893099448073
          ],
          [
            105.83527023735627,
            21.009125962767406
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "B5-B11",
        "diemDau": "B8AKL/15-1",
        "diemCuoi": "B8AKL/15-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.008893099448073,
        "to_latitude": 21.009125962767406,
        "to_longitude": 105.83527023735627,
        "from_longitude": 105.83496178331322,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7362,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83558673802253,
          21.009386368629816,
          105.8358723932818,
          21.009616727282285
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83558673802253,
            21.009386368629816
          ],
          [
            105.8358723932818,
            21.009616727282285
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "B5-B11",
        "diemDau": "B8AKL/15-3",
        "diemCuoi": "B8AKL/15-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.009386368629816,
        "to_latitude": 21.009616727282285,
        "to_longitude": 105.8358723932818,
        "from_longitude": 105.83558673802253,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7363,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83564976992244,
          21.008005930692757,
          105.83572570998416,
          21.008175727906135
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83572570998416,
            21.008005930692757
          ],
          [
            105.83564976992244,
            21.008175727906135
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "B5-B11",
        "diemDau": "",
        "diemCuoi": "B8AKL/13-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.008005930692757,
        "to_latitude": 21.008175727906135,
        "to_longitude": 105.83564976992244,
        "from_longitude": 105.83572570998416,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7364,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83564976992244,
          21.008175727906135,
          105.83589385094376,
          21.008386057092554
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83564976992244,
            21.008175727906135
          ],
          [
            105.83589385094376,
            21.008386057092554
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "B5-B11",
        "diemDau": "B8AKL/13-2",
        "diemCuoi": "B8AKL/13-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.008175727906135,
        "to_latitude": 21.008386057092554,
        "to_longitude": 105.83589385094376,
        "from_longitude": 105.83564976992244,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7365,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83589385094376,
          21.008386057092554,
          105.83607087673556,
          21.008511252896227
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83589385094376,
            21.008386057092554
          ],
          [
            105.83607087673556,
            21.008511252896227
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "B5-B11",
        "diemDau": "B8AKL/13-1",
        "diemCuoi": "B8AKL/13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.008386057092554,
        "to_latitude": 21.008511252896227,
        "to_longitude": 105.83607087673556,
        "from_longitude": 105.83589385094376,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7366,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83607087673556,
          21.008511252896227,
          105.83624790253637,
          21.008679015108306
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83607087673556,
            21.008511252896227
          ],
          [
            105.83624790253637,
            21.008679015108306
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "B5-B11",
        "diemDau": "B8AKL/13",
        "diemCuoi": "B8AKL/13-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.008511252896227,
        "to_latitude": 21.008679015108306,
        "to_longitude": 105.83624790253637,
        "from_longitude": 105.83607087673556,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7367,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83624790253637,
          21.008679015108306,
          105.8363176399646,
          21.008704054228108
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83624790253637,
            21.008679015108306
          ],
          [
            105.8363176399646,
            21.008704054228108
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 7,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "B5-B11",
        "diemDau": "B8AKL/13-3",
        "diemCuoi": "B8AKL/13-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.008679015108306,
        "to_latitude": 21.008704054228108,
        "to_longitude": 105.8363176399646,
        "from_longitude": 105.83624790253637,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7368,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83590994419919,
          21.008375258951986,
          105.83616156895785,
          21.00868402293277
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83616156895785,
            21.008375258951986
          ],
          [
            105.83590994419919,
            21.00868402293277
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Phạm Ngọc Thạch",
        "diemDau": "B8AKL/5",
        "diemCuoi": "B8AKL/14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.008375258951986,
        "to_latitude": 21.00868402293277,
        "to_longitude": 105.83590994419919,
        "from_longitude": 105.83616156895785,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7369,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83607892336778,
          21.00889435140261,
          105.83633373322006,
          21.00910217567121
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83607892336778,
            21.00889435140261
          ],
          [
            105.83633373322006,
            21.00910217567121
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "B5-B11",
        "diemDau": "B8AKL/14-3",
        "diemCuoi": "B8AKL/14-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00889435140261,
        "to_latitude": 21.00910217567121,
        "to_longitude": 105.83633373322006,
        "from_longitude": 105.83607892336778,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7370,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83545396866744,
          21.008373537506497,
          105.8357409650306,
          21.008603897723685
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8357409650306,
            21.008603897723685
          ],
          [
            105.83545396866744,
            21.008373537506497
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "B5-B11",
        "diemDau": "B8AKL/14-1",
        "diemCuoi": "B8AKL/14-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.008603897723685,
        "to_latitude": 21.008373537506497,
        "to_longitude": 105.83545396866744,
        "from_longitude": 105.8357409650306,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7371,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8363176399646,
          21.008704054228108,
          105.83651511761067,
          21.0088815188695
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8363176399646,
            21.008704054228108
          ],
          [
            105.83651511761067,
            21.0088815188695
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "B5-B11",
        "diemDau": "B8AKL/13-4",
        "diemCuoi": "B8AKL/13-5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.008704054228108,
        "to_latitude": 21.0088815188695,
        "to_longitude": 105.83651511761067,
        "from_longitude": 105.8363176399646,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7372,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83651511761067,
          21.0088815188695,
          105.83671460689908,
          21.009057105372147
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83651511761067,
            21.0088815188695
          ],
          [
            105.83671460689908,
            21.009057105372147
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "B5-B11",
        "diemDau": "B8AKL/13-5",
        "diemCuoi": "B8AKL/13-6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.0088815188695,
        "to_latitude": 21.009057105372147,
        "to_longitude": 105.83671460689908,
        "from_longitude": 105.83651511761067,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7373,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83564306440309,
          21.00868402293277,
          105.83590994419919,
          21.008971972548782
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83590994419919,
            21.00868402293277
          ],
          [
            105.83564306440309,
            21.008971972548782
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Phạm Ngoc Thạch",
        "diemDau": "B8AKL/14",
        "diemCuoi": "B8AKL/15-5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00868402293277,
        "to_latitude": 21.008971972548782,
        "to_longitude": 105.83564306440309,
        "from_longitude": 105.83590994419919,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7374,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83587532882179,
          21.008721371181466,
          105.83607892336778,
          21.00889435140261
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83607892336778,
            21.00889435140261
          ],
          [
            105.83587532882179,
            21.008721371181466
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "B5-B11",
        "diemDau": "",
        "diemCuoi": "B8AKL/14-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00889435140261,
        "to_latitude": 21.008721371181466,
        "to_longitude": 105.83587532882179,
        "from_longitude": 105.83607892336778,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7375,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8357409650306,
          21.008603897723685,
          105.83587599240775,
          21.00872065520573
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83587599240775,
            21.00872065520573
          ],
          [
            105.8357409650306,
            21.008603897723685
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "B5-B11",
        "diemDau": "",
        "diemCuoi": "B8AKL/14-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00872065520573,
        "to_latitude": 21.008603897723685,
        "to_longitude": 105.8357409650306,
        "from_longitude": 105.83587599240775,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7376,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8352930361221,
          21.00914474205182,
          105.83549420180154,
          21.009357573772622
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83549420180154,
            21.00914474205182
          ],
          [
            105.8352930361221,
            21.009357573772622
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Phạm Ngọc Thạch",
        "diemDau": "B8AKL/15",
        "diemCuoi": "B8AKL/16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00914474205182,
        "to_latitude": 21.009357573772622,
        "to_longitude": 105.8352930361221,
        "from_longitude": 105.83549420180154,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7377,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83527023735627,
          21.009125962767406,
          105.83539937214546,
          21.009245071126575
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83527023735627,
            21.009125962767406
          ],
          [
            105.83539937214546,
            21.009245071126575
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "B5-B11",
        "diemDau": "",
        "diemCuoi": "B8AKL/15-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.009125962767406,
        "to_latitude": 21.009245071126575,
        "to_longitude": 105.83539937214546,
        "from_longitude": 105.83527023735627,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7378,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83539901991085,
          21.00924544378807,
          105.83558673802253,
          21.009386368629816
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83558673802253,
            21.009386368629816
          ],
          [
            105.83539901991085,
            21.00924544378807
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "B5-B11",
        "diemDau": "",
        "diemCuoi": "B8AKL/15-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.009386368629816,
        "to_latitude": 21.00924544378807,
        "to_longitude": 105.83539901991085,
        "from_longitude": 105.83558673802253,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7379,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83549420180154,
          21.00897197254877,
          105.83564306440309,
          21.00914474205182
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83564306440309,
            21.00897197254877
          ],
          [
            105.83549420180154,
            21.00914474205182
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 43 Phạm Ngọc Thạch",
        "diemDau": "B8AKL/15-5",
        "diemCuoi": "B8AKL/15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00897197254877,
        "to_latitude": 21.00914474205182,
        "to_longitude": 105.83549420180154,
        "from_longitude": 105.83564306440309,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7380,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83746663602189,
          21.00374678890185,
          105.83755899982921,
          21.003864383745682
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83746663602189,
            21.00374678890185
          ],
          [
            105.83755899982921,
            21.003864383745682
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phương Mai",
        "diemDau": "Tủ điện",
        "diemCuoi": "1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KLE",
        "from_latitude": 21.00374678890185,
        "to_latitude": 21.003864383745682,
        "to_longitude": 105.83755899982921,
        "from_longitude": 105.83746663602189,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7381,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83755899982921,
          21.003864383745682,
          105.8379412146211,
          21.003962039440516
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83755899982921,
            21.003864383745682
          ],
          [
            105.8379412146211,
            21.003962039440516
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phương Mai",
        "diemDau": "1",
        "diemCuoi": "2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KLE",
        "from_latitude": 21.003864383745682,
        "to_latitude": 21.003962039440516,
        "to_longitude": 105.8379412146211,
        "from_longitude": 105.83755899982921,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7382,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8379412146211,
          21.003962039440516,
          105.83836500363299,
          21.003978315383304
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8379412146211,
            21.003962039440516
          ],
          [
            105.83836500363299,
            21.003978315383304
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phương Mai",
        "diemDau": "2",
        "diemCuoi": "3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KLE",
        "from_latitude": 21.003962039440516,
        "to_latitude": 21.003978315383304,
        "to_longitude": 105.83836500363299,
        "from_longitude": 105.8379412146211,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7383,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83836500363299,
          21.003978315383304,
          105.83862115459812,
          21.00413105876197
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83836500363299,
            21.003978315383304
          ],
          [
            105.83862115459812,
            21.00413105876197
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phương Mai",
        "diemDau": "3",
        "diemCuoi": "4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KLE",
        "from_latitude": 21.003978315383304,
        "to_latitude": 21.00413105876197,
        "to_longitude": 105.83862115459812,
        "from_longitude": 105.83836500363299,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7384,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83862115459812,
          21.00413105876197,
          105.8389993460694,
          21.00421118637299
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83862115459812,
            21.00413105876197
          ],
          [
            105.8389993460694,
            21.00421118637299
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phương Mai",
        "diemDau": "4",
        "diemCuoi": "5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KLE",
        "from_latitude": 21.00413105876197,
        "to_latitude": 21.00421118637299,
        "to_longitude": 105.8389993460694,
        "from_longitude": 105.83862115459812,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7385,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8389993460694,
          21.00421118637299,
          105.83934803323756,
          21.004286305969075
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8389993460694,
            21.00421118637299
          ],
          [
            105.83934803323756,
            21.004286305969075
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phương Mai",
        "diemDau": "5",
        "diemCuoi": "6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KLE",
        "from_latitude": 21.00421118637299,
        "to_latitude": 21.004286305969075,
        "to_longitude": 105.83934803323756,
        "from_longitude": 105.8389993460694,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7386,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83934803323756,
          21.004286305969075,
          105.83972086029334,
          21.004296321912697
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83934803323756,
            21.004286305969075
          ],
          [
            105.83972086029334,
            21.004296321912697
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phương Mai",
        "diemDau": "6",
        "diemCuoi": "7",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KLE",
        "from_latitude": 21.004286305969075,
        "to_latitude": 21.004296321912697,
        "to_longitude": 105.83972086029334,
        "from_longitude": 105.83934803323756,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7387,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83972086029334,
          21.004296321912697,
          105.8400480897906,
          21.00429882589891
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83972086029334,
            21.004296321912697
          ],
          [
            105.8400480897906,
            21.00429882589891
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phương Mai",
        "diemDau": "7",
        "diemCuoi": "8",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KLE",
        "from_latitude": 21.004296321912697,
        "to_latitude": 21.00429882589891,
        "to_longitude": 105.8400480897906,
        "from_longitude": 105.83972086029334,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7388,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8400480897906,
          21.004286305969075,
          105.84037531929681,
          21.00429882589891
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8400480897906,
            21.00429882589891
          ],
          [
            105.84037531929681,
            21.004286305969075
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phương Mai",
        "diemDau": "8",
        "diemCuoi": "9",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KLE",
        "from_latitude": 21.00429882589891,
        "to_latitude": 21.004286305969075,
        "to_longitude": 105.84037531929681,
        "from_longitude": 105.8400480897906,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7389,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84037531929681,
          21.004283801983483,
          105.84072400646497,
          21.004286305969075
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84037531929681,
            21.004286305969075
          ],
          [
            105.84072400646497,
            21.004283801983483
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phương Mai",
        "diemDau": "9",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KLE",
        "from_latitude": 21.004286305969075,
        "to_latitude": 21.004283801983483,
        "to_longitude": 105.84072400646497,
        "from_longitude": 105.84037531929681,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7390,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84072400646497,
          21.00427879401132,
          105.84097076969401,
          21.004283801983483
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84072400646497,
            21.004283801983483
          ],
          [
            105.84097076969401,
            21.00427879401132
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phương Mai",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KLE",
        "from_latitude": 21.004283801983483,
        "to_latitude": 21.00427879401132,
        "to_longitude": 105.84097076969401,
        "from_longitude": 105.84072400646497,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7391,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84097076969401,
          21.0042766030233,
          105.84103145474523,
          21.00427879401132
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84097076969401,
            21.00427879401132
          ],
          [
            105.84103145474523,
            21.0042766030233
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phương Mai",
        "diemDau": "11",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KLE",
        "from_latitude": 21.00427879401132,
        "to_latitude": 21.0042766030233,
        "to_longitude": 105.84103145474523,
        "from_longitude": 105.84097076969401,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7392,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84100697953446,
          21.0042766030233,
          105.8410421835852,
          21.00440399326127
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84103145474523,
            21.0042766030233
          ],
          [
            105.8410421835852,
            21.004376762432447
          ],
          [
            105.84100697953446,
            21.00440399326127
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ cạnh đường tầu",
        "diemDau": "",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.0042766030233,
        "to_latitude": 21.00440399326127,
        "to_longitude": 105.84100697953446,
        "from_longitude": 105.84103145474523,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7393,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8400480897906,
          21.00429882589891,
          105.84015169019266,
          21.004413070203086
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8400480897906,
            21.00429882589891
          ],
          [
            105.84015169019266,
            21.004413070203086
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "diemDau": "8",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00429882589891,
        "to_latitude": 21.004413070203086,
        "to_longitude": 105.84015169019266,
        "from_longitude": 105.8400480897906,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7394,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84015169019266,
          21.004413070203086,
          105.84016610699412,
          21.00465188746236
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84015169019266,
            21.004413070203086
          ],
          [
            105.84016610699412,
            21.00465188746236
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "diemDau": "",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004413070203086,
        "to_latitude": 21.00465188746236,
        "to_longitude": 105.84016610699412,
        "from_longitude": 105.84015169019266,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7395,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84015526515299,
          21.004472290186534,
          105.84022779780811,
          21.004472852802223
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84022779780811,
            21.004472852802223
          ],
          [
            105.84015526515299,
            21.004472290186534
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "diemDau": "",
        "diemCuoi": "13-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004472852802223,
        "to_latitude": 21.004472290186534,
        "to_longitude": 105.84015526515299,
        "from_longitude": 105.84022779780811,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7396,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84022779780811,
          21.00447160080973,
          105.84042359906313,
          21.004472852802223
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84022779780811,
            21.004472852802223
          ],
          [
            105.84042359906313,
            21.00447160080973
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "diemDau": "13-1",
        "diemCuoi": "13-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004472852802223,
        "to_latitude": 21.00447160080973,
        "to_longitude": 105.84042359906313,
        "from_longitude": 105.84022779780811,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7397,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84042359906313,
          21.00447160080973,
          105.84078569728796,
          21.00447160080973
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84042359906313,
            21.00447160080973
          ],
          [
            105.84078569728796,
            21.00447160080973
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 39,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "diemDau": "13-3",
        "diemCuoi": "13-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00447160080973,
        "to_latitude": 21.00447160080973,
        "to_longitude": 105.84078569728796,
        "from_longitude": 105.84042359906313,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7398,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84016610699412,
          21.004629351643207,
          105.84046919662167,
          21.00465188746236
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84016610699412,
            21.00465188746236
          ],
          [
            105.84046919662167,
            21.004629351643207
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "diemDau": "13",
        "diemCuoi": "13-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00465188746236,
        "to_latitude": 21.004629351643207,
        "to_longitude": 105.84046919662167,
        "from_longitude": 105.84016610699412,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7399,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84010978060459,
          21.00465188746236,
          105.84016610699412,
          21.00487974944978
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84016610699412,
            21.00465188746236
          ],
          [
            105.84010978060459,
            21.00487974944978
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00465188746236,
        "to_latitude": 21.00487974944978,
        "to_longitude": 105.84010978060459,
        "from_longitude": 105.84016610699412,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7400,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84010978060459,
          21.004873489510683,
          105.84042628126825,
          21.00487974944978
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84010978060459,
            21.00487974944978
          ],
          [
            105.84042628126825,
            21.004873489510683
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "diemDau": "14",
        "diemCuoi": "14-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00487974944978,
        "to_latitude": 21.004873489510683,
        "to_longitude": 105.84042628126825,
        "from_longitude": 105.84010978060459,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7401,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84042628126825,
          21.004869733545316,
          105.84080715495888,
          21.004873489510683
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84042628126825,
            21.004873489510683
          ],
          [
            105.84080715495888,
            21.004869733545316
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "diemDau": "14-1",
        "diemCuoi": "14-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004873489510683,
        "to_latitude": 21.004869733545316,
        "to_longitude": 105.84080715495888,
        "from_longitude": 105.84042628126825,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7402,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84079910832666,
          21.00468318720831,
          105.84080715495888,
          21.004869733545316
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84080715495888,
            21.004869733545316
          ],
          [
            105.84079910832666,
            21.00468318720831
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "diemDau": "14-2",
        "diemCuoi": "14-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004869733545316,
        "to_latitude": 21.00468318720831,
        "to_longitude": 105.84079910832666,
        "from_longitude": 105.84080715495888,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7403,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84010978060459,
          21.00487974944978,
          105.84012319165612,
          21.005148926623708
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84010978060459,
            21.00487974944978
          ],
          [
            105.84012319165612,
            21.005148926623708
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00487974944978,
        "to_latitude": 21.005148926623708,
        "to_longitude": 105.84012319165612,
        "from_longitude": 105.84010978060459,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7404,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84012319165612,
          21.0051251388921,
          105.84044773894813,
          21.005148926623708
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84012319165612,
            21.005148926623708
          ],
          [
            105.84044773894813,
            21.0051251388921
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "diemDau": "15",
        "diemCuoi": "15-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005148926623708,
        "to_latitude": 21.0051251388921,
        "to_longitude": 105.84044773894813,
        "from_longitude": 105.84012319165612,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7405,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84012319165612,
          21.005148926623708,
          105.84020097572171,
          21.005375535867064
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84012319165612,
            21.005148926623708
          ],
          [
            105.84020097572171,
            21.005375535867064
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005148926623708,
        "to_latitude": 21.005375535867064,
        "to_longitude": 105.84020097572171,
        "from_longitude": 105.84012319165612,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7406,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84020097572171,
          21.005360512059866,
          105.84043164569525,
          21.005375535867064
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84020097572171,
            21.005375535867064
          ],
          [
            105.84043164569525,
            21.005360512059866
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "diemDau": "16",
        "diemCuoi": "16-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005375535867064,
        "to_latitude": 21.005360512059866,
        "to_longitude": 105.84043164569525,
        "from_longitude": 105.84020097572171,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7407,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84043164569525,
          21.005360512059866,
          105.84068913775789,
          21.005360512060236
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84043164569525,
            21.005360512059866
          ],
          [
            105.84068913775789,
            21.005360512060236
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "diemDau": "16-1",
        "diemCuoi": "16-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005360512059866,
        "to_latitude": 21.005360512060236,
        "to_longitude": 105.84068913775789,
        "from_longitude": 105.84043164569525,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7408,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.84079106170344,
          21.004869733545316,
          105.84080715495888,
          21.00516269846516
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.84079106170344,
            21.00516269846516
          ],
          [
            105.84080715495888,
            21.004869733545316
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "diemDau": "14-2",
        "diemCuoi": "15-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00516269846516,
        "to_latitude": 21.004869733545316,
        "to_longitude": 105.84080715495888,
        "from_longitude": 105.84079106170344,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7409,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8392514737319,
          21.004286305969075,
          105.83934803323756,
          21.004390221344543
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83934803323756,
            21.004286305969075
          ],
          [
            105.8392514737319,
            21.004390221344543
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "6",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004286305969075,
        "to_latitude": 21.004390221344543,
        "to_longitude": 105.8392514737319,
        "from_longitude": 105.83934803323756,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7410,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8391924651346,
          21.004390221344543,
          105.8392514737319,
          21.004635611592203
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8392514737319,
            21.004390221344543
          ],
          [
            105.8391924651346,
            21.004635611592203
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "18",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004390221344543,
        "to_latitude": 21.004635611592203,
        "to_longitude": 105.8391924651346,
        "from_longitude": 105.8392514737319,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7411,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8391924651346,
          21.004635611592203,
          105.8394727559784,
          21.004645627511543
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8391924651346,
            21.004635611592203
          ],
          [
            105.8394727559784,
            21.004645627511543
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "18",
        "diemCuoi": "17-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004635611592203,
        "to_latitude": 21.004645627511543,
        "to_longitude": 105.8394727559784,
        "from_longitude": 105.8391924651346,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7412,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8394727559784,
          21.004645627511543,
          105.83979462105117,
          21.0046481314919
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8394727559784,
            21.004645627511543
          ],
          [
            105.83979462105117,
            21.0046481314919
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "17-1",
        "diemCuoi": "17-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004645627511543,
        "to_latitude": 21.0046481314919,
        "to_longitude": 105.83979462105117,
        "from_longitude": 105.8394727559784,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7413,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83912842746295,
          21.004635611592203,
          105.8391924651346,
          21.004788667298072
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8391924651346,
            21.004635611592203
          ],
          [
            105.83912842746295,
            21.004788667298072
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "18",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004635611592203,
        "to_latitude": 21.004788667298072,
        "to_longitude": 105.83912842746295,
        "from_longitude": 105.8391924651346,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7414,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83911333996132,
          21.004788667298072,
          105.83912842746295,
          21.004901033242234
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83912842746295,
            21.004788667298072
          ],
          [
            105.83911333996132,
            21.004901033242234
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004788667298072,
        "to_latitude": 21.004901033242234,
        "to_longitude": 105.83911333996132,
        "from_longitude": 105.83912842746295,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7415,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83912842746295,
          21.004788667298072,
          105.83939765411671,
          21.004881001436335
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83912842746295,
            21.004788667298072
          ],
          [
            105.83939765411671,
            21.004881001436335
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "",
        "diemCuoi": "18-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004788667298072,
        "to_latitude": 21.004881001436335,
        "to_longitude": 105.83939765411671,
        "from_longitude": 105.83912842746295,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7416,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83939765411671,
          21.004878497459877,
          105.83965782839344,
          21.004881001436335
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83939765411671,
            21.004881001436335
          ],
          [
            105.83965782839344,
            21.004878497459877
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "18-3",
        "diemCuoi": "18-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004881001436335,
        "to_latitude": 21.004878497459877,
        "to_longitude": 105.83965782839344,
        "from_longitude": 105.83939765411671,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7417,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83965782839344,
          21.004878497459877,
          105.83988581615931,
          21.004883505411893
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83965782839344,
            21.004878497459877
          ],
          [
            105.83988581615931,
            21.004883505411893
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "18-4",
        "diemCuoi": "18-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004878497459877,
        "to_latitude": 21.004883505411893,
        "to_longitude": 105.83988581615931,
        "from_longitude": 105.83965782839344,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7418,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8390114160222,
          21.004708226996183,
          105.83912842746295,
          21.004788667298072
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83912842746295,
            21.004788667298072
          ],
          [
            105.8390114160222,
            21.004708226996183
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "",
        "diemCuoi": "18-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004788667298072,
        "to_latitude": 21.004708226996183,
        "to_longitude": 105.8390114160222,
        "from_longitude": 105.83912842746295,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7419,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83864395339087,
          21.00462559567135,
          105.8390114160222,
          21.004708226996183
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8390114160222,
            21.004708226996183
          ],
          [
            105.83864395339087,
            21.00462559567135
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "18-2",
        "diemCuoi": "18-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004708226996183,
        "to_latitude": 21.00462559567135,
        "to_longitude": 105.83864395339087,
        "from_longitude": 105.8390114160222,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7420,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8384347410792,
          21.004613075769758,
          105.83864395339087,
          21.00462559567135
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83864395339087,
            21.00462559567135
          ],
          [
            105.8384347410792,
            21.004613075769758
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "18-1",
        "diemCuoi": "4-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00462559567135,
        "to_latitude": 21.004613075769758,
        "to_longitude": 105.8384347410792,
        "from_longitude": 105.83864395339087,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7421,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83903823810861,
          21.004901033242234,
          105.83911333996132,
          21.00518147824672
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83911333996132,
            21.004901033242234
          ],
          [
            105.83903823810861,
            21.00518147824672
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004901033242234,
        "to_latitude": 21.00518147824672,
        "to_longitude": 105.83903823810861,
        "from_longitude": 105.83911333996132,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7422,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83891485649856,
          21.0051439186779,
          105.83903823810861,
          21.00518147824672
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83903823810861,
            21.00518147824672
          ],
          [
            105.83891485649856,
            21.0051439186779
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "20",
        "diemCuoi": "20-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00518147824672,
        "to_latitude": 21.0051439186779,
        "to_longitude": 105.83891485649856,
        "from_longitude": 105.83903823810861,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7423,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83866809326952,
          21.005083823349086,
          105.83891485649856,
          21.0051439186779
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83891485649856,
            21.0051439186779
          ],
          [
            105.83866809326952,
            21.005083823349086
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "20-1",
        "diemCuoi": "20-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.0051439186779,
        "to_latitude": 21.005083823349086,
        "to_longitude": 105.83866809326952,
        "from_longitude": 105.83891485649856,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7424,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8384347410792,
          21.005026231970096,
          105.83866809326952,
          21.005083823349086
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83866809326952,
            21.005083823349086
          ],
          [
            105.8384347410792,
            21.005026231970096
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "20-2",
        "diemCuoi": "20-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005083823349086,
        "to_latitude": 21.005026231970096,
        "to_longitude": 105.8384347410792,
        "from_longitude": 105.83866809326952,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7425,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83896883603046,
          21.00518147824672,
          105.83903823810861,
          21.005434692092596
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83903823810861,
            21.00518147824672
          ],
          [
            105.83896883603046,
            21.005434692092596
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "20",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00518147824672,
        "to_latitude": 21.005434692092596,
        "to_longitude": 105.83896883603046,
        "from_longitude": 105.83903823810861,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7426,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83860908467226,
          21.00533422039304,
          105.83896581847262,
          21.005391811654004
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83896581847262,
            21.005391811654004
          ],
          [
            105.83860908467226,
            21.00533422039304
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "20-4",
        "diemCuoi": "20-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005391811654004,
        "to_latitude": 21.00533422039304,
        "to_longitude": 105.83860908467226,
        "from_longitude": 105.83896581847262,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7427,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8383140416769,
          21.005261605293708,
          105.83860908467226,
          21.00533422039304
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83860908467226,
            21.00533422039304
          ],
          [
            105.8383140416769,
            21.005261605293708
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "20-5",
        "diemCuoi": "20-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00533422039304,
        "to_latitude": 21.005261605293708,
        "to_longitude": 105.8383140416769,
        "from_longitude": 105.83860908467226,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7428,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83893899637724,
          21.005434692092596,
          105.83896883603046,
          21.00556458530185
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83896883603046,
            21.005434692092596
          ],
          [
            105.83893899637724,
            21.00556458530185
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005434692092596,
        "to_latitude": 21.00556458530185,
        "to_longitude": 105.83893899637724,
        "from_longitude": 105.83896883603046,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7429,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83893899637724,
          21.00556458530185,
          105.83899431701298,
          21.00559494587242
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83893899637724,
            21.00556458530185
          ],
          [
            105.83899431701298,
            21.00559494587242
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "21",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00556458530185,
        "to_latitude": 21.00559494587242,
        "to_longitude": 105.83899431701298,
        "from_longitude": 105.83893899637724,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7430,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83899431701298,
          21.00559494587242,
          105.83914552647218,
          21.005597136836887
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83899431701298,
            21.00559494587242
          ],
          [
            105.83914552647218,
            21.005597136836887
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "",
        "diemCuoi": "21-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00559494587242,
        "to_latitude": 21.005597136836887,
        "to_longitude": 105.83914552647218,
        "from_longitude": 105.83899431701298,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7431,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83914552647218,
          21.005592128908827,
          105.83952908235895,
          21.005597136836887
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83914552647218,
            21.005597136836887
          ],
          [
            105.83952908235895,
            21.005592128908827
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "21-1",
        "diemCuoi": "21-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005597136836887,
        "to_latitude": 21.005592128908827,
        "to_longitude": 105.83952908235895,
        "from_longitude": 105.83914552647218,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7432,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83893899637724,
          21.00556458530185,
          105.8389621305021,
          21.005720144018074
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83893899637724,
            21.00556458530185
          ],
          [
            105.8389621305021,
            21.005720144018074
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "21",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00556458530185,
        "to_latitude": 21.005720144018074,
        "to_longitude": 105.8389621305021,
        "from_longitude": 105.83893899637724,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7433,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83877538162413,
          21.005720144018074,
          105.8389621305021,
          21.005892604287254
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8389621305021,
            21.005720144018074
          ],
          [
            105.83877538162413,
            21.005892604287254
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005720144018074,
        "to_latitude": 21.005892604287254,
        "to_longitude": 105.83877538162413,
        "from_longitude": 105.8389621305021,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7434,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83908115345942,
          21.00583250925997,
          105.83939228970122,
          21.005867564695635
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83908115345942,
            21.005867564695635
          ],
          [
            105.83939228970122,
            21.00583250925997
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "22-4",
        "diemCuoi": "22-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005867564695635,
        "to_latitude": 21.00583250925997,
        "to_longitude": 105.83939228970122,
        "from_longitude": 105.83908115345942,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7435,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83939228970122,
          21.005807469658276,
          105.83959881979617,
          21.00583250925997
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83939228970122,
            21.00583250925997
          ],
          [
            105.83959881979617,
            21.005807469658276
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "22-5",
        "diemCuoi": "22-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00583250925997,
        "to_latitude": 21.005807469658276,
        "to_longitude": 105.83959881979617,
        "from_longitude": 105.83939228970122,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7436,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.839384243078,
          21.00583250925997,
          105.83939228970122,
          21.00599526656948
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83939228970122,
            21.00583250925997
          ],
          [
            105.839384243078,
            21.00599526656948
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "22-5",
        "diemCuoi": "22-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00583250925997,
        "to_latitude": 21.00599526656948,
        "to_longitude": 105.839384243078,
        "from_longitude": 105.83939228970122,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7437,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.839384243078,
          21.00599526656948,
          105.83944861609076,
          21.0061530157922
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.839384243078,
            21.00599526656948
          ],
          [
            105.83944861609076,
            21.0061530157922
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "22-7",
        "diemCuoi": "23-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00599526656948,
        "to_latitude": 21.0061530157922,
        "to_longitude": 105.83944861609076,
        "from_longitude": 105.839384243078,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7438,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83944861609076,
          21.00610293669214,
          105.8396953793198,
          21.0061530157922
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83944861609076,
            21.0061530157922
          ],
          [
            105.8396953793198,
            21.00610293669214
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "23-2",
        "diemCuoi": "23-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.0061530157922,
        "to_latitude": 21.00610293669214,
        "to_longitude": 105.8396953793198,
        "from_longitude": 105.83944861609076,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7439,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83876465279317,
          21.006230638364507,
          105.83903555590086,
          21.00626819765984
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83903555590086,
            21.006230638364507
          ],
          [
            105.83876465279317,
            21.00626819765984
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "23-1",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.006230638364507,
        "to_latitude": 21.00626819765984,
        "to_longitude": 105.83876465279317,
        "from_longitude": 105.83903555590086,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7440,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83944861609076,
          21.0061530157922,
          105.8394647093462,
          21.006466009789175
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83944861609076,
            21.0061530157922
          ],
          [
            105.8394647093462,
            21.006466009789175
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "23-2",
        "diemCuoi": "25-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.0061530157922,
        "to_latitude": 21.006466009789175,
        "to_longitude": 105.8394647093462,
        "from_longitude": 105.83944861609076,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7441,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8391535730954,
          21.006466009789175,
          105.8394647093462,
          21.006506072972716
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8394647093462,
            21.006466009789175
          ],
          [
            105.8391535730954,
            21.006506072972716
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 46,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "25-2",
        "diemCuoi": "25-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.006466009789175,
        "to_latitude": 21.006506072972716,
        "to_longitude": 105.8391535730954,
        "from_longitude": 105.8394647093462,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7442,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8387941570873,
          21.006506072972716,
          105.8391535730954,
          21.00654613614635
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8391535730954,
            21.006506072972716
          ],
          [
            105.8387941570873,
            21.00654613614635
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "25-1",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.006506072972716,
        "to_latitude": 21.00654613614635,
        "to_longitude": 105.8387941570873,
        "from_longitude": 105.8391535730954,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7443,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83872978407454,
          21.00654613614635,
          105.8387941570873,
          21.00666632560188
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8387941570873,
            21.00654613614635
          ],
          [
            105.83872978407454,
            21.00666632560188
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00654613614635,
        "to_latitude": 21.00666632560188,
        "to_longitude": 105.83872978407454,
        "from_longitude": 105.8387941570873,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7444,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83872073168853,
          21.00666632560188,
          105.83872978407454,
          21.006907017218875
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83872978407454,
            21.00666632560188
          ],
          [
            105.83872073168853,
            21.006907017218875
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "26",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00666632560188,
        "to_latitude": 21.006907017218875,
        "to_longitude": 105.83872073168853,
        "from_longitude": 105.83872978407454,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7445,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83854739385825,
          21.006907017218875,
          105.83872073168853,
          21.006939255463944
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83872073168853,
            21.006907017218875
          ],
          [
            105.83854739385825,
            21.006939255463944
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "",
        "diemCuoi": "26-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.006907017218875,
        "to_latitude": 21.006939255463944,
        "to_longitude": 105.83854739385825,
        "from_longitude": 105.83872073168853,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7446,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83813969809283,
          21.006939255463944,
          105.83854739385825,
          21.006989334284103
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83854739385825,
            21.006939255463944
          ],
          [
            105.83813969809283,
            21.006989334284103
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "26-1",
        "diemCuoi": "26-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.006939255463944,
        "to_latitude": 21.006989334284103,
        "to_longitude": 105.83813969809283,
        "from_longitude": 105.83854739385825,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7447,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8387941570873,
          21.00654613614635,
          105.83893262619897,
          21.006704197783453
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8387941570873,
            21.00654613614635
          ],
          [
            105.83893262619897,
            21.006704197783453
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "25",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00654613614635,
        "to_latitude": 21.006704197783453,
        "to_longitude": 105.83893262619897,
        "from_longitude": 105.8387941570873,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7448,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83893262619897,
          21.0066713334939,
          105.83917234855859,
          21.006704197783453
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83893262619897,
            21.006704197783453
          ],
          [
            105.83917234855859,
            21.0066713334939
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "",
        "diemCuoi": "25-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.006704197783453,
        "to_latitude": 21.0066713334939,
        "to_longitude": 105.83917234855859,
        "from_longitude": 105.83893262619897,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7449,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83917234855859,
          21.006638782193193,
          105.83947543817717,
          21.0066713334939
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83917234855859,
            21.0066713334939
          ],
          [
            105.83947543817717,
            21.006638782193193
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "25-3",
        "diemCuoi": "25-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.0066713334939,
        "to_latitude": 21.006638782193193,
        "to_longitude": 105.83947543817717,
        "from_longitude": 105.83917234855859,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7450,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83872173745131,
          21.006085409002992,
          105.83876465279317,
          21.00626819765984
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83876465279317,
            21.00626819765984
          ],
          [
            105.83872173745131,
            21.006085409002992
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00626819765984,
        "to_latitude": 21.006085409002992,
        "to_longitude": 105.83872173745131,
        "from_longitude": 105.83876465279317,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7451,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83868586296994,
          21.005892604287254,
          105.83877538162413,
          21.005897925203726
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83877538162413,
            21.005892604287254
          ],
          [
            105.83868586296994,
            21.005897925203726
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "22",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005892604287254,
        "to_latitude": 21.005897925203726,
        "to_longitude": 105.83868586296994,
        "from_longitude": 105.83877538162413,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7452,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83847497422228,
          21.005867564695635,
          105.83868586296994,
          21.005897925203726
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83868586296994,
            21.005897925203726
          ],
          [
            105.83847497422228,
            21.005867564695635
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "",
        "diemCuoi": "22-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005897925203726,
        "to_latitude": 21.005867564695635,
        "to_longitude": 105.83847497422228,
        "from_longitude": 105.83868586296994,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7453,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83823357540871,
          21.005867564695635,
          105.83847497422228,
          21.005900116163755
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83847497422228,
            21.005867564695635
          ],
          [
            105.83823357540871,
            21.005900116163755
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "22-3",
        "diemCuoi": "22-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005867564695635,
        "to_latitude": 21.005900116163755,
        "to_longitude": 105.83823357540871,
        "from_longitude": 105.83847497422228,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7454,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83790902811921,
          21.005900116163755,
          105.83823357540871,
          21.005947691374214
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83823357540871,
            21.005900116163755
          ],
          [
            105.83790902811921,
            21.005947691374214
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "22-2",
        "diemCuoi": "22-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005900116163755,
        "to_latitude": 21.005947691374214,
        "to_longitude": 105.83790902811921,
        "from_longitude": 105.83823357540871,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7455,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83790902811921,
          21.005947691374214,
          105.83792512137464,
          21.006178055336733
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83790902811921,
            21.005947691374214
          ],
          [
            105.83792512137464,
            21.006178055336733
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "22-1",
        "diemCuoi": "24-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005947691374214,
        "to_latitude": 21.006178055336733,
        "to_longitude": 105.83792512137464,
        "from_longitude": 105.83790902811921,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7456,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83792512137464,
          21.006143312971982,
          105.83818965430409,
          21.006178055336733
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83792512137464,
            21.006178055336733
          ],
          [
            105.83818965430409,
            21.006143312971982
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "24-2",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.006178055336733,
        "to_latitude": 21.006143312971982,
        "to_longitude": 105.83818965430409,
        "from_longitude": 105.83792512137464,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7457,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83818965430409,
          21.006120464379258,
          105.83839182573736,
          21.006143312971982
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83818965430409,
            21.006143312971982
          ],
          [
            105.83839182573736,
            21.006120464379258
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "",
        "diemCuoi": "24-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.006143312971982,
        "to_latitude": 21.006120464379258,
        "to_longitude": 105.83839182573736,
        "from_longitude": 105.83818965430409,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7458,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83761934953935,
          21.006178055336733,
          105.83792512137464,
          21.00623314231829
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83792512137464,
            21.006178055336733
          ],
          [
            105.83761934953935,
            21.00623314231829
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "24-2",
        "diemCuoi": "24-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.006178055336733,
        "to_latitude": 21.00623314231829,
        "to_longitude": 105.83761934953935,
        "from_longitude": 105.83792512137464,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7459,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83792512137464,
          21.006178055336733,
          105.83793216223509,
          21.006323597605697
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83792512137464,
            21.006178055336733
          ],
          [
            105.83793216223509,
            21.006323597605697
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "24-2",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.006178055336733,
        "to_latitude": 21.006323597605697,
        "to_longitude": 105.83793216223509,
        "from_longitude": 105.83792512137464,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7460,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83793216223509,
          21.006323597605697,
          105.83800022322735,
          21.00644848214264
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83793216223509,
            21.006323597605697
          ],
          [
            105.83800022322735,
            21.00644848214264
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.006323597605697,
        "to_latitude": 21.00644848214264,
        "to_longitude": 105.83800022322735,
        "from_longitude": 105.83793216223509,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7461,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83800022322735,
          21.006413426843473,
          105.83834891039551,
          21.00644848214264
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83800022322735,
            21.00644848214264
          ],
          [
            105.83834891039551,
            21.006413426843473
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "28",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00644848214264,
        "to_latitude": 21.006413426843473,
        "to_longitude": 105.83834891039551,
        "from_longitude": 105.83800022322735,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7462,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83800022322735,
          21.00644848214264,
          105.83801363426606,
          21.006578687466433
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83800022322735,
            21.00644848214264
          ],
          [
            105.83801363426606,
            21.006578687466433
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "28",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00644848214264,
        "to_latitude": 21.006578687466433,
        "to_longitude": 105.83801363426606,
        "from_longitude": 105.83800022322735,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7463,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83801363426606,
          21.006556151938288,
          105.83824430423968,
          21.006578687466433
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83801363426606,
            21.006578687466433
          ],
          [
            105.83824430423968,
            21.006556151938288
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "30",
        "diemCuoi": "30-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.006578687466433,
        "to_latitude": 21.006556151938288,
        "to_longitude": 105.83824430423968,
        "from_longitude": 105.83801363426606,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7464,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83824430423968,
          21.006533616405907,
          105.83847765643002,
          21.006556151938288
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83824430423968,
            21.006556151938288
          ],
          [
            105.83847765643002,
            21.006533616405907
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "30-1",
        "diemCuoi": "30-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.006556151938288,
        "to_latitude": 21.006533616405907,
        "to_longitude": 105.83847765643002,
        "from_longitude": 105.83824430423968,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7465,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83769445139205,
          21.00644848214264,
          105.83800022322735,
          21.00649104928035
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83800022322735,
            21.00644848214264
          ],
          [
            105.83769445139205,
            21.00649104928035
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00644848214264,
        "to_latitude": 21.00649104928035,
        "to_longitude": 105.83769445139205,
        "from_longitude": 105.83800022322735,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7466,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83903555590086,
          21.006208415781657,
          105.83924108024203,
          21.006230638364507
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83903555590086,
            21.006230638364507
          ],
          [
            105.83924108024203,
            21.006208415781657
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "",
        "diemCuoi": "23-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.006230638364507,
        "to_latitude": 21.006208415781657,
        "to_longitude": 105.83924108024203,
        "from_longitude": 105.83903555590086,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7467,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83924108024203,
          21.0061530157922,
          105.83944861609076,
          21.006208415781657
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83924108024203,
            21.006208415781657
          ],
          [
            105.83944861609076,
            21.0061530157922
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "23-2",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.006208415781657,
        "to_latitude": 21.0061530157922,
        "to_longitude": 105.83944861609076,
        "from_longitude": 105.83924108024203,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7468,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83877538162413,
          21.005882901450114,
          105.83893530840672,
          21.005892604287254
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83877538162413,
            21.005892604287254
          ],
          [
            105.83893530840672,
            21.005882901450114
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "22",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005892604287254,
        "to_latitude": 21.005882901450114,
        "to_longitude": 105.83893530840672,
        "from_longitude": 105.83877538162413,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7469,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83893530840672,
          21.005867564695635,
          105.83908115345942,
          21.005882901450114
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83893530840672,
            21.005882901450114
          ],
          [
            105.83908115345942,
            21.005867564695635
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "",
        "diemCuoi": "22-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005882901450114,
        "to_latitude": 21.005867564695635,
        "to_longitude": 105.83908115345942,
        "from_longitude": 105.83893530840672,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7470,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83896581847262,
          21.005391811654004,
          105.83896883603045,
          21.005434692092596
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83896883603045,
            21.005434692092596
          ],
          [
            105.83896581847262,
            21.005391811654004
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "diemDau": "",
        "diemCuoi": "20-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005434692092596,
        "to_latitude": 21.005391811654004,
        "to_longitude": 105.83896581847262,
        "from_longitude": 105.83896883603045,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7471,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83850313739458,
          21.00413105876197,
          105.83862115459812,
          21.004186146499215
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83862115459812,
            21.00413105876197
          ],
          [
            105.83850313739458,
            21.004186146499215
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "diemDau": "4",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00413105876197,
        "to_latitude": 21.004186146499215,
        "to_longitude": 105.83850313739458,
        "from_longitude": 105.83862115459812,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7472,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83842300644054,
          21.004186146499215,
          105.83850313739458,
          21.004485059718313
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83850313739458,
            21.004186146499215
          ],
          [
            105.83842300644054,
            21.004485059718313
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "diemDau": "4-1",
        "diemCuoi": "4-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004186146499215,
        "to_latitude": 21.004485059718313,
        "to_longitude": 105.83842300644054,
        "from_longitude": 105.83850313739458,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7473,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83835729231491,
          21.004485059718313,
          105.83842300644054,
          21.00486566458557
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83842300644054,
            21.004485059718313
          ],
          [
            105.83842133002251,
            21.004609319802505
          ],
          [
            105.83835729231491,
            21.00486566458557
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "diemDau": "4-2",
        "diemCuoi": "4-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004485059718313,
        "to_latitude": 21.00486566458557,
        "to_longitude": 105.83835729231491,
        "from_longitude": 105.83842300644054,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7474,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83822318186495,
          21.004528879406443,
          105.83842133002251,
          21.004609319802505
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83822318186495,
            21.004528879406443
          ],
          [
            105.83842133002251,
            21.004609319802505
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "diemDau": "",
        "diemCuoi": "2-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004528879406443,
        "to_latitude": 21.004609319802505,
        "to_longitude": 105.83842133002251,
        "from_longitude": 105.83822318186495,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7475,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83793350328506,
          21.00445751590698,
          105.83822318186495,
          21.004528879406443
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83822318186495,
            21.004528879406443
          ],
          [
            105.83793350328506,
            21.00445751590698
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "diemDau": "2-7",
        "diemCuoi": "2-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004528879406443,
        "to_latitude": 21.00445751590698,
        "to_longitude": 105.83793350328506,
        "from_longitude": 105.83822318186495,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7476,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83842300644054,
          21.004292252933933,
          105.8384243475444,
          21.004485059718313
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83842300644054,
            21.004485059718313
          ],
          [
            105.8384243475444,
            21.004292252933933
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "diemDau": "4-2",
        "diemCuoi": "2-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004485059718313,
        "to_latitude": 21.004292252933933,
        "to_longitude": 105.8384243475444,
        "from_longitude": 105.83842300644054,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7477,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8382875548777,
          21.004213377359456,
          105.8384243475444,
          21.004292252933933
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8384243475444,
            21.004292252933933
          ],
          [
            105.8382875548777,
            21.004213377359456
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "diemDau": "2-4",
        "diemCuoi": "2-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004292252933933,
        "to_latitude": 21.004213377359456,
        "to_longitude": 105.8382875548777,
        "from_longitude": 105.8384243475444,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7478,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83799251189133,
          21.0041432657035,
          105.8382875548777,
          21.004213377359456
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8382875548777,
            21.004213377359456
          ],
          [
            105.83799251189133,
            21.0041432657035
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "diemDau": "2-3",
        "diemCuoi": "2-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004213377359456,
        "to_latitude": 21.0041432657035,
        "to_longitude": 105.83799251189133,
        "from_longitude": 105.8382875548777,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7479,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83772294987851,
          21.00411697382374,
          105.83799251189133,
          21.0041432657035
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83799251189133,
            21.0041432657035
          ],
          [
            105.83772294987851,
            21.00411697382374
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "diemDau": "2-2",
        "diemCuoi": "2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.0041432657035,
        "to_latitude": 21.00411697382374,
        "to_longitude": 105.83772294987851,
        "from_longitude": 105.83799251189133,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7480,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83765723576187,
          21.00438364838866,
          105.83793350328506,
          21.00445751590698
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83793350328506,
            21.00445751590698
          ],
          [
            105.83765723576187,
            21.00438364838866
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "diemDau": "2-6",
        "diemCuoi": "2-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00445751590698,
        "to_latitude": 21.00438364838866,
        "to_longitude": 105.83765723576187,
        "from_longitude": 105.83793350328506,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7481,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83825402727194,
          21.00486566458557,
          105.83835729231491,
          21.005203700990098
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83835729231491,
            21.00486566458557
          ],
          [
            105.83825402727194,
            21.005203700990098
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "diemDau": "4-4",
        "diemCuoi": "4-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00486566458557,
        "to_latitude": 21.005203700990098,
        "to_longitude": 105.83825402727194,
        "from_longitude": 105.83835729231491,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7482,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83805822600796,
          21.005152369584685,
          105.83825402727194,
          21.005203700990098
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83825402727194,
            21.005203700990098
          ],
          [
            105.83805822600796,
            21.005152369584685
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "diemDau": "4-5",
        "diemCuoi": "4-13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005203700990098,
        "to_latitude": 21.005152369584685,
        "to_longitude": 105.83805822600796,
        "from_longitude": 105.83825402727194,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7483,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83789058794324,
          21.00511606199475,
          105.83805822600796,
          21.005152369584685
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83805822600796,
            21.005152369584685
          ],
          [
            105.83789058794324,
            21.00511606199475
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "diemDau": "4-13",
        "diemCuoi": "4-14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005152369584685,
        "to_latitude": 21.00511606199475,
        "to_longitude": 105.83789058794324,
        "from_longitude": 105.83805822600796,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7484,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.838243298432,
          21.005091022272833,
          105.83825402727194,
          21.005203700990098
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83825402727194,
            21.005203700990098
          ],
          [
            105.838243298432,
            21.005091022272833
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "diemDau": "4-5",
        "diemCuoi": "4-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005203700990098,
        "to_latitude": 21.005091022272833,
        "to_longitude": 105.838243298432,
        "from_longitude": 105.83825402727194,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7485,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8380609082157,
          21.005067234533378,
          105.838243298432,
          21.005091022272833
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.838243298432,
            21.005091022272833
          ],
          [
            105.8380609082157,
            21.005067234533378
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "diemDau": "4-10",
        "diemCuoi": "4-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005091022272833,
        "to_latitude": 21.005067234533378,
        "to_longitude": 105.8380609082157,
        "from_longitude": 105.838243298432,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7486,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83789729347157,
          21.00502842294963,
          105.8380609082157,
          21.005067234533378
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8380609082157,
            21.005067234533378
          ],
          [
            105.83789729347157,
            21.00502842294963
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "diemDau": "4-11",
        "diemCuoi": "4-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005067234533378,
        "to_latitude": 21.00502842294963,
        "to_longitude": 105.83789729347157,
        "from_longitude": 105.8380609082157,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7487,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83810382355755,
          21.005203700990098,
          105.83825402727194,
          21.005382734774223
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83825402727194,
            21.005203700990098
          ],
          [
            105.83815310912254,
            21.005227801705164
          ],
          [
            105.83810382355755,
            21.005382734774223
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "diemDau": "4-5",
        "diemCuoi": "4-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005203700990098,
        "to_latitude": 21.005382734774223,
        "to_longitude": 105.83810382355755,
        "from_longitude": 105.83825402727194,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7488,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83809410052528,
          21.005382734774223,
          105.83810382355755,
          21.00542060727993
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83810382355755,
            21.005382734774223
          ],
          [
            105.83809410052528,
            21.00542060727993
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "diemDau": "4-6",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005382734774223,
        "to_latitude": 21.00542060727993,
        "to_longitude": 105.83809410052528,
        "from_longitude": 105.83810382355755,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7489,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83809410052528,
          21.00542060727993,
          105.838292584006,
          21.00546442668999
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83809410052528,
            21.00542060727993
          ],
          [
            105.838292584006,
            21.00546442668999
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "diemDau": "",
        "diemCuoi": "4-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00542060727993,
        "to_latitude": 21.00546442668999,
        "to_longitude": 105.838292584006,
        "from_longitude": 105.83809410052528,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7490,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.838292584006,
          21.00546442668999,
          105.8386064024645,
          21.005544553585
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.838292584006,
            21.00546442668999
          ],
          [
            105.8386064024645,
            21.005544553585
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "diemDau": "4-7",
        "diemCuoi": "4-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00546442668999,
        "to_latitude": 21.005544553585,
        "to_longitude": 105.8386064024645,
        "from_longitude": 105.838292584006,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7491,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8386064024645,
          21.005544553585,
          105.83886121231679,
          21.005607152692527
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8386064024645,
            21.005544553585
          ],
          [
            105.83886121231679,
            21.005607152692527
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "diemDau": "4-8",
        "diemCuoi": "4-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005544553585,
        "to_latitude": 21.005607152692527,
        "to_longitude": 105.83886121231679,
        "from_longitude": 105.8386064024645,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7492,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83740510812635,
          21.00384904678542,
          105.83744131793985,
          21.00413450174328
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83744131793985,
            21.00384904678542
          ],
          [
            105.83740510812635,
            21.00413450174328
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Phương Mai",
        "diemDau": "39",
        "diemCuoi": "38",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00384904678542,
        "to_latitude": 21.00413450174328,
        "to_longitude": 105.83740510812635,
        "from_longitude": 105.83744131793985,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7493,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83740510812635,
          21.00413450174328,
          105.83750703207444,
          21.004394916316354
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83740510812635,
            21.00413450174328
          ],
          [
            105.83750703207444,
            21.004394916316354
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Phương Mai",
        "diemDau": "38",
        "diemCuoi": "37",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00413450174328,
        "to_latitude": 21.004394916316354,
        "to_longitude": 105.83750703207444,
        "from_longitude": 105.83740510812635,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7494,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83750703207444,
          21.004394916316354,
          105.83764918914765,
          21.004605250832313
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83750703207444,
            21.004394916316354
          ],
          [
            105.83764918914765,
            21.004605250832313
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Phương Mai",
        "diemDau": "37",
        "diemCuoi": "36",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004394916316354,
        "to_latitude": 21.004605250832313,
        "to_longitude": 105.83764918914765,
        "from_longitude": 105.83750703207444,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7495,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83764918914765,
          21.004605250832313,
          105.83777927627705,
          21.004786789314156
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83764918914765,
            21.004605250832313
          ],
          [
            105.83777927627705,
            21.004786789314156
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Phương Mai",
        "diemDau": "36",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004605250832313,
        "to_latitude": 21.004786789314156,
        "to_longitude": 105.83777927627705,
        "from_longitude": 105.83764918914765,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7496,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83777391186155,
          21.004786789314156,
          105.83777927627705,
          21.00501214712138
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83777927627705,
            21.004786789314156
          ],
          [
            105.83777391186155,
            21.00501214712138
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Phương Mai",
        "diemDau": "35",
        "diemCuoi": "34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.004786789314156,
        "to_latitude": 21.00501214712138,
        "to_longitude": 105.83777391186155,
        "from_longitude": 105.83777927627705,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7497,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83773904314292,
          21.00501214712138,
          105.83777391186155,
          21.00530761573008
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83777391186155,
            21.00501214712138
          ],
          [
            105.83773904314292,
            21.00530761573008
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Phương Mai",
        "diemDau": "34",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00501214712138,
        "to_latitude": 21.00530761573008,
        "to_longitude": 105.83773904314292,
        "from_longitude": 105.83777391186155,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7498,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83769612780108,
          21.00530761573008,
          105.83773904314292,
          21.005554256450854
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83773904314292,
            21.00530761573008
          ],
          [
            105.83769612780108,
            21.005554256450854
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Phương Mai",
        "diemDau": "33",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00530761573008,
        "to_latitude": 21.005554256450854,
        "to_longitude": 105.83769612780108,
        "from_longitude": 105.83773904314292,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7499,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83759152165425,
          21.005554256450854,
          105.83769612780108,
          21.005872259621786
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83769612780108,
            21.005554256450854
          ],
          [
            105.83759152165425,
            21.005872259621786
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 34 Phương Mai",
        "diemDau": "32",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.005554256450854,
        "to_latitude": 21.005872259621786,
        "to_longitude": 105.83759152165425,
        "from_longitude": 105.83769612780108,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7500,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83832778803874,
          21.003786446940385,
          105.83836500363299,
          21.003978315383304
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83836500363299,
            21.003978315383304
          ],
          [
            105.83832778803874,
            21.003786446940385
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "3",
        "diemCuoi": "40",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.003978315383304,
        "to_latitude": 21.003786446940385,
        "to_longitude": 105.83832778803874,
        "from_longitude": 105.83836500363299,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7501,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83804481498721,
          21.003722595071146,
          105.83832778803874,
          21.003786446940385
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83832778803874,
            21.003786446940385
          ],
          [
            105.83804481498721,
            21.003722595071146
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "40",
        "diemCuoi": "40-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.003786446940385,
        "to_latitude": 21.003722595071146,
        "to_longitude": 105.83804481498721,
        "from_longitude": 105.83832778803874,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7502,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83804481498721,
          21.003601151246436,
          105.83807566038523,
          21.003722595071146
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83804481498721,
            21.003722595071146
          ],
          [
            105.83807566038523,
            21.003601151246436
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "40-1",
        "diemCuoi": "40-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.003722595071146,
        "to_latitude": 21.003601151246436,
        "to_longitude": 105.83807566038523,
        "from_longitude": 105.83804481498721,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7503,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83830498925496,
          21.003318199270204,
          105.83832778803874,
          21.003786446940385
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83832778803874,
            21.003786446940385
          ],
          [
            105.83832443525661,
            21.0034878453221
          ],
          [
            105.83830498925496,
            21.003318199270204
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 49,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "40",
        "diemCuoi": "41",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.003786446940385,
        "to_latitude": 21.003318199270204,
        "to_longitude": 105.83830498925496,
        "from_longitude": 105.83832778803874,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7504,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83811455241545,
          21.003318199270204,
          105.83830968310504,
          21.003479081324194
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83830498925496,
            21.003318199270204
          ],
          [
            105.83830968310504,
            21.003479081324194
          ],
          [
            105.83811455241545,
            21.00347219532376
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "41",
        "diemCuoi": "41-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.003318199270204,
        "to_latitude": 21.00347219532376,
        "to_longitude": 105.83811455241545,
        "from_longitude": 105.83830498925496,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7505,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83802469842016,
          21.003211779139633,
          105.83830498925496,
          21.003318199270204
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83830498925496,
            21.003318199270204
          ],
          [
            105.83802469842016,
            21.003211779139633
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "41",
        "diemCuoi": "41-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.003318199270204,
        "to_latitude": 21.003211779139633,
        "to_longitude": 105.83802469842016,
        "from_longitude": 105.83830498925496,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7506,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8377564775112,
          21.003136659002646,
          105.83802469842016,
          21.003211779139633
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83802469842016,
            21.003211779139633
          ],
          [
            105.8377564775112,
            21.003136659002646
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "41-2",
        "diemCuoi": "41-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.003211779139633,
        "to_latitude": 21.003136659002646,
        "to_longitude": 105.8377564775112,
        "from_longitude": 105.83802469842016,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7507,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8376102971264,
          21.003095342910438,
          105.8377564775112,
          21.003136659002646
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8377564775112,
            21.003136659002646
          ],
          [
            105.8376102971264,
            21.003095342910438
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "41-4",
        "diemCuoi": "41-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.003136659002646,
        "to_latitude": 21.003095342910438,
        "to_longitude": 105.8376102971264,
        "from_longitude": 105.8377564775112,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7508,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83750233818843,
          21.003095342910438,
          105.8376102971264,
          21.00348596732097
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8376102971264,
            21.003095342910438
          ],
          [
            105.83750233818843,
            21.003417733324454
          ],
          [
            105.83755262963298,
            21.00348596732097
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.003095342910438,
        "to_latitude": 21.00348596732097,
        "to_longitude": 105.83755262963298,
        "from_longitude": 105.8376102971264,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7509,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83737828603994,
          21.003007702678584,
          105.8376102971264,
          21.003095342910438
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8376102971264,
            21.003095342910438
          ],
          [
            105.83737828603994,
            21.003007702678584
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "41-5",
        "diemCuoi": "41-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.003095342910438,
        "to_latitude": 21.003007702678584,
        "to_longitude": 105.83737828603994,
        "from_longitude": 105.8376102971264,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7510,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8369665669539,
          21.002910046360093,
          105.83737828603994,
          21.003007702678584
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83737828603994,
            21.003007702678584
          ],
          [
            105.8369665669539,
            21.002910046360093
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "41-6",
        "diemCuoi": "41-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.003007702678584,
        "to_latitude": 21.002910046360093,
        "to_longitude": 105.8369665669539,
        "from_longitude": 105.83737828603994,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7511,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83694242707526,
          21.002910046360093,
          105.8369665669539,
          21.00300269466443
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8369665669539,
            21.002910046360093
          ],
          [
            105.83694242707526,
            21.00300269466443
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "41-7",
        "diemCuoi": "41-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.002910046360093,
        "to_latitude": 21.00300269466443,
        "to_longitude": 105.83694242707526,
        "from_longitude": 105.8369665669539,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7512,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83737828603994,
          21.002746033679465,
          105.83743595353333,
          21.003007702678584
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83737828603994,
            21.003007702678584
          ],
          [
            105.83743595353333,
            21.002746033679465
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "41-6",
        "diemCuoi": "41-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.003007702678584,
        "to_latitude": 21.002746033679465,
        "to_longitude": 105.83743595353333,
        "from_longitude": 105.83737828603994,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7513,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83722942343839,
          21.00268343337256,
          105.83743595353333,
          21.002746033679465
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83743595353333,
            21.002746033679465
          ],
          [
            105.83722942343839,
            21.00268343337256
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "41-9",
        "diemCuoi": "41-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.002746033679465,
        "to_latitude": 21.00268343337256,
        "to_longitude": 105.83722942343839,
        "from_longitude": 105.83743595353333,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7514,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83722942343839,
          21.002646812180853,
          105.83732296546708,
          21.00268343337256
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83722942343839,
            21.00268343337256
          ],
          [
            105.83732296546708,
            21.002646812180853
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "41-10",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00268343337256,
        "to_latitude": 21.002646812180853,
        "to_longitude": 105.83732296546708,
        "from_longitude": 105.83722942343839,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7515,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83732296546708,
          21.00251660342631,
          105.83736856302565,
          21.002646812180853
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83732296546708,
            21.002646812180853
          ],
          [
            105.83736856302565,
            21.00251660342631
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "",
        "diemCuoi": "41-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.002646812180853,
        "to_latitude": 21.00251660342631,
        "to_longitude": 105.83736856302565,
        "from_longitude": 105.83732296546708,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7516,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83726529798265,
          21.00243647490543,
          105.83736856302565,
          21.00251660342631
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83736856302565,
            21.00251660342631
          ],
          [
            105.83726529798265,
            21.00243647490543
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "41-11",
        "diemCuoi": "41-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00251660342631,
        "to_latitude": 21.00243647490543,
        "to_longitude": 105.83726529798265,
        "from_longitude": 105.83736856302565,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7517,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83829124292907,
          21.00302867374105,
          105.83830498925496,
          21.003318199270204
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83830498925496,
            21.003318199270204
          ],
          [
            105.83829124292907,
            21.00302867374105
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.003318199270204,
        "to_latitude": 21.00302867374105,
        "to_longitude": 105.83829124292907,
        "from_longitude": 105.83830498925496,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7518,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83819736561318,
          21.002919749395545,
          105.83829124292907,
          21.00302867374105
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83829124292907,
            21.00302867374105
          ],
          [
            105.83819736561318,
            21.002919749395545
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "42",
        "diemCuoi": "42-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00302867374105,
        "to_latitude": 21.002919749395545,
        "to_longitude": 105.83819736561318,
        "from_longitude": 105.83829124292907,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7519,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83791975698611,
          21.002849637131,
          105.83819736561318,
          21.002919749395545
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83819736561318,
            21.002919749395545
          ],
          [
            105.83791975698611,
            21.002849637131
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "42-1",
        "diemCuoi": "42-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.002919749395545,
        "to_latitude": 21.002849637131,
        "to_longitude": 105.83791975698611,
        "from_longitude": 105.83819736561318,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7520,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8382362576434,
          21.00272443657787,
          105.83829124292907,
          21.00302867374105
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83829124292907,
            21.00302867374105
          ],
          [
            105.8382362576434,
            21.00272443657787
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "42",
        "diemCuoi": "46-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00302867374105,
        "to_latitude": 21.00272443657787,
        "to_longitude": 105.8382362576434,
        "from_longitude": 105.83829124292907,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7521,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83821614107634,
          21.002663088267916,
          105.8382362576434,
          21.00272443657787
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8382362576434,
            21.00272443657787
          ],
          [
            105.83821614107634,
            21.002663088267916
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "43-1",
        "diemCuoi": "43",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00272443657787,
        "to_latitude": 21.002663088267916,
        "to_longitude": 105.83821614107634,
        "from_longitude": 105.8382362576434,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7522,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83796401343183,
          21.00264055214761,
          105.83821614107634,
          21.002663088267916
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83821614107634,
            21.002663088267916
          ],
          [
            105.83796401343183,
            21.00264055214761
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "43",
        "diemCuoi": "44",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.002663088267916,
        "to_latitude": 21.00264055214761,
        "to_longitude": 105.83796401343183,
        "from_longitude": 105.83821614107634,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7523,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83769445142799,
          21.002621772044584,
          105.83796401343183,
          21.00264055214761
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83796401343183,
            21.00264055214761
          ],
          [
            105.83769445142799,
            21.002621772044584
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "44",
        "diemCuoi": "45",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00264055214761,
        "to_latitude": 21.002621772044584,
        "to_longitude": 105.83769445142799,
        "from_longitude": 105.83796401343183,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7524,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83821614107634,
          21.00257294376708,
          105.83822284659571,
          21.002663088267916
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83821614107634,
            21.002663088267916
          ],
          [
            105.83822284659571,
            21.00257294376708
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "43",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.002663088267916,
        "to_latitude": 21.00257294376708,
        "to_longitude": 105.83822284659571,
        "from_longitude": 105.83821614107634,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7525,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83821614107634,
          21.0023087699853,
          105.83822284659571,
          21.00257294376708
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83822284659571,
            21.00257294376708
          ],
          [
            105.83821614107634,
            21.0023087699853
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "20",
        "diemCuoi": "46",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.00257294376708,
        "to_latitude": 21.0023087699853,
        "to_longitude": 105.83821614107634,
        "from_longitude": 105.83822284659571,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7526,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83820943555699,
          21.002009539402998,
          105.83821614107634,
          21.0023087699853
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83821614107634,
            21.0023087699853
          ],
          [
            105.83820943555699,
            21.002009539402998
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "46",
        "diemCuoi": "47",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.0023087699853,
        "to_latitude": 21.002009539402998,
        "to_longitude": 105.83820943555699,
        "from_longitude": 105.83821614107634,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7527,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83811824043987,
          21.002009539402998,
          105.83820943555699,
          21.002009539402998
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83820943555699,
            21.002009539402998
          ],
          [
            105.83811824043987,
            21.002009539402998
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "47",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.002009539402998,
        "to_latitude": 21.002009539402998,
        "to_longitude": 105.83811824043987,
        "from_longitude": 105.83820943555699,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7528,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83784465513342,
          21.002009539402998,
          105.83811824043987,
          21.002033327629867
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83811824043987,
            21.002009539402998
          ],
          [
            105.83784465513342,
            21.002033327629867
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "",
        "diemCuoi": "47-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.002009539402998,
        "to_latitude": 21.002033327629867,
        "to_longitude": 105.83784465513342,
        "from_longitude": 105.83811824043987,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7529,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83784465513342,
          21.002033327629867,
          105.83786074838885,
          21.002203601146412
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83784465513342,
            21.002033327629867
          ],
          [
            105.83786074838885,
            21.002203601146412
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "47-2",
        "diemCuoi": "47-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.002033327629867,
        "to_latitude": 21.002203601146412,
        "to_longitude": 105.83786074838885,
        "from_longitude": 105.83784465513342,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7530,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83767835817255,
          21.002203601146412,
          105.83786074838885,
          21.002228641352804
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83786074838885,
            21.002203601146412
          ],
          [
            105.83767835817255,
            21.002228641352804
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "47-3",
        "diemCuoi": "47-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.002203601146412,
        "to_latitude": 21.002228641352804,
        "to_longitude": 105.83767835817255,
        "from_longitude": 105.83786074838885,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7531,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83810482941014,
          21.001775412963564,
          105.83811824043987,
          21.002009539402998
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83810482941014,
            21.001775412963564
          ],
          [
            105.83811824043987,
            21.002009539402998
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KLE",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "diemDau": "",
        "diemCuoi": "47-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "KLE",
        "from_latitude": 21.001775412963564,
        "to_latitude": 21.002009539402998,
        "to_longitude": 105.83811824043987,
        "from_longitude": 105.83810482941014,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7532,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83463773890064,
          21.007528932504115,
          105.83484058101607,
          21.007619074011103
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83463773890064,
            21.00754489507204
          ],
          [
            105.8347198816138,
            21.007528932504115
          ],
          [
            105.83484058101607,
            21.007619074011103
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Sân nhà B11",
        "diemDau": "06",
        "diemCuoi": "6-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.00754489507204,
        "to_latitude": 21.007619074011103,
        "to_longitude": 105.83484058101607,
        "from_longitude": 105.83463773890064,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7533,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83484058101607,
          21.007619074011103,
          105.83500687797692,
          21.007756790097222
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83484058101607,
            21.007619074011103
          ],
          [
            105.83500687797692,
            21.007756790097222
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Sân nhà B11",
        "diemDau": "6-4",
        "diemCuoi": "6-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.007619074011103,
        "to_latitude": 21.007756790097222,
        "to_longitude": 105.83500687797692,
        "from_longitude": 105.83484058101607,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7534,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83500687797692,
          21.007756790097222,
          105.8352080436564,
          21.007917041383074
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83500687797692,
            21.007756790097222
          ],
          [
            105.8352080436564,
            21.007917041383074
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B4KL",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Sân nhà B11",
        "diemDau": "6-5",
        "diemCuoi": "6-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "B4 Kim Liên",
        "from_latitude": 21.007756790097222,
        "to_latitude": 21.007917041383074,
        "to_longitude": 105.8352080436564,
        "from_longitude": 105.83500687797692,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7535,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83383680560135,
          21.015100316246436,
          105.83395511679672,
          21.015320963849103
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83395511679672,
            21.015100316246436
          ],
          [
            105.83383680560135,
            21.015208947523956
          ],
          [
            105.8339460643748,
            21.015320963849103
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 164 Đê La Thành đất",
        "diemDau": "17",
        "diemCuoi": "17-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.015100316246436,
        "to_latitude": 21.015320963849103,
        "to_longitude": 105.8339460643748,
        "from_longitude": 105.83395511679672,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7536,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8339460643748,
          21.015320963849103,
          105.83410163249569,
          21.0154186120448
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8339460643748,
            21.015320963849103
          ],
          [
            105.83410163249569,
            21.0154186120448
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 164 Đê La Thành đất",
        "diemDau": "17-1",
        "diemCuoi": "17-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.015320963849103,
        "to_latitude": 21.0154186120448,
        "to_longitude": 105.83410163249569,
        "from_longitude": 105.8339460643748,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7537,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83410163249569,
          21.0154186120448,
          105.83434303130926,
          21.015600137369287
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83410163249569,
            21.0154186120448
          ],
          [
            105.83428268150931,
            21.015600137369287
          ],
          [
            105.83434303130926,
            21.015551313336765
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 164 Đê La Thành đất",
        "diemDau": "17-5",
        "diemCuoi": "17-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.0154186120448,
        "to_latitude": 21.015551313336765,
        "to_longitude": 105.83434303130926,
        "from_longitude": 105.83410163249569,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7538,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83410163249569,
          21.015396077851218,
          105.83430279816618,
          21.01550939784096
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83410163249569,
            21.0154186120448
          ],
          [
            105.83419218004813,
            21.01550939784096
          ],
          [
            105.83430279816618,
            21.015396077851218
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 164 Đê La Thành đất",
        "diemDau": "17-5",
        "diemCuoi": "17-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.0154186120448,
        "to_latitude": 21.015396077851218,
        "to_longitude": 105.83430279816618,
        "from_longitude": 105.83410163249569,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7539,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83434303130926,
          21.015551313336765,
          105.8345281037243,
          21.015704044865934
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83434303130926,
            21.015551313336765
          ],
          [
            105.8345281037243,
            21.015704044865934
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 164 Đê La Thành đất",
        "diemDau": "17-2",
        "diemCuoi": "17-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.015551313336765,
        "to_latitude": 21.015704044865934,
        "to_longitude": 105.8345281037243,
        "from_longitude": 105.83434303130926,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7540,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8345281037243,
          21.015618915836118,
          105.834664896391,
          21.015704044865934
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8345281037243,
            21.015704044865934
          ],
          [
            105.834664896391,
            21.015618915836118
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 164 Đê La Thành đất",
        "diemDau": "17-3",
        "diemCuoi": "17-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.015704044865934,
        "to_latitude": 21.015618915836118,
        "to_longitude": 105.834664896391,
        "from_longitude": 105.8345281037243,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7541,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.834664896391,
          21.015560076627185,
          105.83497603263282,
          21.015685266409825
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.834664896391,
            21.015618915836118
          ],
          [
            105.83474938588097,
            21.015685266409825
          ],
          [
            105.83492641167281,
            21.015560076627185
          ],
          [
            105.83497603263282,
            21.015626427223705
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 164 Đê La Thành đất",
        "diemDau": "17-4",
        "diemCuoi": "17-7",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.015618915836118,
        "to_latitude": 21.015626427223705,
        "to_longitude": 105.83497603263282,
        "from_longitude": 105.834664896391,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7542,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83460588772186,
          21.014425852405978,
          105.83483756356605,
          21.01456575304356
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83460588772186,
            21.014425852405978
          ],
          [
            105.83483756356605,
            21.01456575304356
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Đê La Thành đất",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.014425852405978,
        "to_latitude": 21.01456575304356,
        "to_longitude": 105.83483756356605,
        "from_longitude": 105.83460588772186,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7543,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83478693680732,
          21.01456575304356,
          105.83495155744899,
          21.01487872938932
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83483756356605,
            21.01456575304356
          ],
          [
            105.83478693680732,
            21.01466872233823
          ],
          [
            105.83495155744899,
            21.01487872938932
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Đê La Thành đất",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.01456575304356,
        "to_latitude": 21.01487872938932,
        "to_longitude": 105.83495155744899,
        "from_longitude": 105.83483756356605,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7544,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83495155744899,
          21.01487872938932,
          105.83513528876014,
          21.015059003466444
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83495155744899,
            21.01487872938932
          ],
          [
            105.83513528876014,
            21.015059003466444
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Đê La Thành đất",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.01487872938932,
        "to_latitude": 21.015059003466444,
        "to_longitude": 105.83513528876014,
        "from_longitude": 105.83495155744899,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7545,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83513528876014,
          21.015059003466444,
          105.83523050717992,
          21.015174178457873
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83513528876014,
            21.015059003466444
          ],
          [
            105.83523050717992,
            21.015174178457873
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Đê La Thành đất",
        "diemDau": "04",
        "diemCuoi": "4-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.015059003466444,
        "to_latitude": 21.015174178457873,
        "to_longitude": 105.83523050717992,
        "from_longitude": 105.83513528876014,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7546,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83523050717992,
          21.01508028580836,
          105.83538473420593,
          21.015174178457873
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83523050717992,
            21.015174178457873
          ],
          [
            105.83538473420593,
            21.01508028580836
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Đê La Thành đất",
        "diemDau": "4-1",
        "diemCuoi": "5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.015174178457873,
        "to_latitude": 21.01508028580836,
        "to_longitude": 105.83538473420593,
        "from_longitude": 105.83523050717992,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7547,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83538473420593,
          21.01498764500395,
          105.83552689127914,
          21.01508028580836
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83538473420593,
            21.01508028580836
          ],
          [
            105.83552689127914,
            21.01498764500395
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Đê La Thành đất",
        "diemDau": "5",
        "diemCuoi": "5-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.01508028580836,
        "to_latitude": 21.01498764500395,
        "to_longitude": 105.83552689127914,
        "from_longitude": 105.83538473420593,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7548,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83552689127914,
          21.01498764500395,
          105.83562881521826,
          21.015023950191416
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83552689127914,
            21.01498764500395
          ],
          [
            105.83562881521826,
            21.015023950191416
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Đê La Thành đất",
        "diemDau": "5-1",
        "diemCuoi": "6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.01498764500395,
        "to_latitude": 21.015023950191416,
        "to_longitude": 105.83562881521826,
        "from_longitude": 105.83552689127914,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7549,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83562881521826,
          21.015023950191416,
          105.83579645328297,
          21.015125354287548
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83562881521826,
            21.015023950191416
          ],
          [
            105.83579645328297,
            21.015125354287548
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Đê La Thành đất",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.015023950191416,
        "to_latitude": 21.015125354287548,
        "to_longitude": 105.83579645328297,
        "from_longitude": 105.83562881521826,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7550,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83558589987642,
          21.015125354287548,
          105.83579645328297,
          21.015329414173518
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83579645328297,
            21.015125354287548
          ],
          [
            105.83558589987642,
            21.015329414173518
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Đê La Thành đất",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.015125354287548,
        "to_latitude": 21.015329414173518,
        "to_longitude": 105.83558589987642,
        "from_longitude": 105.83579645328297,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7551,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83558589987642,
          21.015329414173518,
          105.8356395440582,
          21.015525962389695
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83558589987642,
            21.015329414173518
          ],
          [
            105.8356395440582,
            21.015525962389695
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Đê La Thành đất",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.015329414173518,
        "to_latitude": 21.015525962389695,
        "to_longitude": 105.8356395440582,
        "from_longitude": 105.83558589987642,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7552,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8356395440582,
          21.015525962389695,
          105.83574415021405,
          21.01566241927853
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8356395440582,
            21.015525962389695
          ],
          [
            105.83574415021405,
            21.01566241927853
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Đê La Thành đất",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.015525962389695,
        "to_latitude": 21.01566241927853,
        "to_longitude": 105.83574415021405,
        "from_longitude": 105.8356395440582,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7553,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83574415021405,
          21.015520954794944,
          105.8359989600663,
          21.01566241927853
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83574415021405,
            21.01566241927853
          ],
          [
            105.8359989600663,
            21.015520954794944
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Đê La Thành đất",
        "diemDau": "10",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.01566241927853,
        "to_latitude": 21.015520954794944,
        "to_longitude": 105.8359989600663,
        "from_longitude": 105.83574415021405,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7554,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8359989600663,
          21.015520954794944,
          105.83615318708334,
          21.0157387850058
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8359989600663,
            21.015520954794944
          ],
          [
            105.83615318708334,
            21.0157387850058
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Đê La Thành đất",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.015520954794944,
        "to_latitude": 21.0157387850058,
        "to_longitude": 105.83615318708334,
        "from_longitude": 105.8359989600663,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7555,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83574415021405,
          21.01566241927853,
          105.83587423734342,
          21.015805135524484
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83574415021405,
            21.01566241927853
          ],
          [
            105.83587423734342,
            21.015805135524484
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Đê La Thành đất",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.01566241927853,
        "to_latitude": 21.015805135524484,
        "to_longitude": 105.83587423734342,
        "from_longitude": 105.83574415021405,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7556,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83587423734342,
          21.015805135524484,
          105.83600298337792,
          21.016009194480343
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83587423734342,
            21.015805135524484
          ],
          [
            105.83600298337792,
            21.016009194480343
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Đê La Thành đất",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.015805135524484,
        "to_latitude": 21.016009194480343,
        "to_longitude": 105.83600298337792,
        "from_longitude": 105.83587423734342,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7557,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83600298337792,
          21.016009194480343,
          105.83613977603565,
          21.016220764514642
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83600298337792,
            21.016009194480343
          ],
          [
            105.83613977603565,
            21.016220764514642
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Đê La Thành đất",
        "diemDau": "11-1",
        "diemCuoi": "11-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.016009194480343,
        "to_latitude": 21.016220764514642,
        "to_longitude": 105.83613977603565,
        "from_longitude": 105.83600298337792,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7558,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83600030117019,
          21.016220764514642,
          105.83613977603565,
          21.016290870495517
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83613977603565,
            21.016220764514642
          ],
          [
            105.83600030117019,
            21.016290870495517
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Đê La Thành đất",
        "diemDau": "11-2",
        "diemCuoi": "11-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.016220764514642,
        "to_latitude": 21.016290870495517,
        "to_longitude": 105.83600030117019,
        "from_longitude": 105.83613977603565,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7559,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83615318708334,
          21.0157387850058,
          105.83631579594791,
          21.015895585007875
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83615318708334,
            21.0157387850058
          ],
          [
            105.83626215176612,
            21.015895585007875
          ],
          [
            105.83631579594791,
            21.015865539512046
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Đê La Thành đất",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.0157387850058,
        "to_latitude": 21.015865539512046,
        "to_longitude": 105.83631579594791,
        "from_longitude": 105.83615318708334,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7560,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83631579594791,
          21.015865539512046,
          105.83652869634687,
          21.01594566082058
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83631579594791,
            21.015865539512046
          ],
          [
            105.8364016266316,
            21.01594566082058
          ],
          [
            105.83652869634687,
            21.015867730325684
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Đê La Thành đất",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.015865539512046,
        "to_latitude": 21.015867730325684,
        "to_longitude": 105.83652869634687,
        "from_longitude": 105.83631579594791,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7561,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83631579594791,
          21.015865539512046,
          105.83646432333413,
          21.016045499419132
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83631579594791,
            21.015865539512046
          ],
          [
            105.83646432333413,
            21.016045499419132
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x2,5",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Đê La Thành đất",
        "diemDau": "14",
        "diemCuoi": "14-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.015865539512046,
        "to_latitude": 21.016045499419132,
        "to_longitude": 105.83646432333413,
        "from_longitude": 105.83631579594791,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7562,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83652869634687,
          21.015795433331256,
          105.83669096999611,
          21.01595411110877
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83652869634687,
            21.015867730325684
          ],
          [
            105.83660011010332,
            21.015795433331256
          ],
          [
            105.83669096999611,
            21.01595411110877
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LAT3",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 126 Đê La Thành đất",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "La Thành 3",
        "from_latitude": 21.015867730325684,
        "to_latitude": 21.01595411110877,
        "to_longitude": 105.83669096999611,
        "from_longitude": 105.83652869634687,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7563,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8314945251152,
          21.01054978799027,
          105.83169703203328,
          21.0106325233242
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8314945251152,
            21.01054978799027
          ],
          [
            105.83169703203328,
            21.0106325233242
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 4C Đặng Văn Ngữ",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.01054978799027,
        "to_latitude": 21.0106325233242,
        "to_longitude": 105.83169703203328,
        "from_longitude": 105.8314945251152,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7564,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82418952905999,
          21.003377669307543,
          105.8243890183484,
          21.003436826320044
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8243890183484,
            21.003377669307543
          ],
          [
            105.82418952905999,
            21.003436826320044
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "LKT2",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 156 Khương Thượng",
        "diemDau": "02",
        "diemCuoi": "2-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Làng Khương Thượng 2",
        "from_latitude": 21.003377669307543,
        "to_latitude": 21.003436826320044,
        "to_longitude": 105.82418952905999,
        "from_longitude": 105.8243890183484,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7565,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8299170510918,
          21.019142966667797,
          105.83014096591411,
          21.019231849287618
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83014096591411,
            21.019231849287618
          ],
          [
            105.83005149683795,
            21.019142966667797
          ],
          [
            105.8299170510918,
            21.01915392051503
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Đức Thắng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.019231849287618,
        "to_latitude": 21.01915392051503,
        "to_longitude": 105.8299170510918,
        "from_longitude": 105.83014096591411,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7566,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82984597255069,
          21.01915392051503,
          105.8299170510918,
          21.019374249152385
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8299170510918,
            21.01915392051503
          ],
          [
            105.82990933975577,
            21.019223086216336
          ],
          [
            105.82988251766935,
            21.019303205720906
          ],
          [
            105.82984597255069,
            21.019374249152385
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 8 La Thành",
        "diemDau": "1",
        "diemCuoi": "1-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.01915392051503,
        "to_latitude": 21.019374249152385,
        "to_longitude": 105.82984597255069,
        "from_longitude": 105.8299170510918,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7567,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83017756065573,
          21.020016768162467,
          105.83029929577964,
          21.02003688786892
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83029929577964,
            21.02003688786892
          ],
          [
            105.83017756065573,
            21.020016768162467
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 278 Tôn Đức Thắng",
        "diemDau": "6-1",
        "diemCuoi": "6-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02003688786892,
        "to_latitude": 21.020016768162467,
        "to_longitude": 105.83017756065573,
        "from_longitude": 105.83029929577964,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7568,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83011154114784,
          21.019926724080896,
          105.83017756065573,
          21.020016768162467
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83017756065573,
            21.020016768162467
          ],
          [
            105.83011154114784,
            21.019926724080896
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 278 Tôn Đức Thắng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.020016768162467,
        "to_latitude": 21.019926724080896,
        "to_longitude": 105.83011154114784,
        "from_longitude": 105.83017756065573,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7569,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8298647779278,
          21.019926724080896,
          105.83011154114784,
          21.020074443686973
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83011154114784,
            21.019926724080896
          ],
          [
            105.8298647779278,
            21.020074443686973
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 278 Tôn Đức Thắng",
        "diemDau": "6-2",
        "diemCuoi": "6-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.019926724080896,
        "to_latitude": 21.020074443686973,
        "to_longitude": 105.8298647779278,
        "from_longitude": 105.83011154114784,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7570,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82974139631774,
          21.019921716633913,
          105.8298647779278,
          21.020074443686973
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8298647779278,
            21.020074443686973
          ],
          [
            105.82974139631774,
            21.019921716633913
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 278 Tôn Đức Thắng",
        "diemDau": "6-4",
        "diemCuoi": "6-4-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.020074443686973,
        "to_latitude": 21.019921716633913,
        "to_longitude": 105.82974139631774,
        "from_longitude": 105.8298647779278,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7571,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82996938408361,
          21.019708899986654,
          105.83011154114784,
          21.019926724080896
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83011154114784,
            21.019926724080896
          ],
          [
            105.82996938408361,
            21.019708899986654
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 278 Tôn Đức Thắng",
        "diemDau": "6-2",
        "diemCuoi": "6-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.019926724080896,
        "to_latitude": 21.019708899986654,
        "to_longitude": 105.82996938408361,
        "from_longitude": 105.83011154114784,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7572,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82982454479367,
          21.019708899986654,
          105.82996938408361,
          21.019794026681677
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82996938408361,
            21.019708899986654
          ],
          [
            105.82982454479367,
            21.019794026681677
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 278 Tôn Đức Thắng",
        "diemDau": "6-3",
        "diemCuoi": "6-3-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.019708899986654,
        "to_latitude": 21.019794026681677,
        "to_longitude": 105.82982454479367,
        "from_longitude": 105.82996938408361,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7573,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83029929577964,
          21.01998140308637,
          105.83056743714273,
          21.020160732099445
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83029929577964,
            21.02003688786892
          ],
          [
            105.83047360947674,
            21.01998140308637
          ],
          [
            105.83056743714273,
            21.020160732099445
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 278 Tôn Đức Thắng",
        "diemDau": "6",
        "diemCuoi": "6-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02003688786892,
        "to_latitude": 21.020160732099445,
        "to_longitude": 105.83056743714273,
        "from_longitude": 105.83029929577964,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7574,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83035830438587,
          21.020427467916882,
          105.83054605900864,
          21.02053763133489
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83054605900864,
            21.020427467916882
          ],
          [
            105.83035830438587,
            21.02053763133489
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "diemDau": "7",
        "diemCuoi": "7-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.020427467916882,
        "to_latitude": 21.02053763133489,
        "to_longitude": 105.83035830438587,
        "from_longitude": 105.83054605900864,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7575,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83020541846373,
          21.02053763133489,
          105.83035830438587,
          21.020642787249567
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83035830438587,
            21.02053763133489
          ],
          [
            105.83020541846373,
            21.020642787249567
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "diemDau": "7-2",
        "diemCuoi": "7-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02053763133489,
        "to_latitude": 21.020642787249567,
        "to_longitude": 105.83020541846373,
        "from_longitude": 105.83035830438587,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7576,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83013836324324,
          21.020552653613322,
          105.83020541846373,
          21.020642787249567
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83020541846373,
            21.020642787249567
          ],
          [
            105.83013836324324,
            21.020552653613322
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "diemDau": "7-3",
        "diemCuoi": "7-3-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.020642787249567,
        "to_latitude": 21.020552653613322,
        "to_longitude": 105.83013836324324,
        "from_longitude": 105.83020541846373,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7577,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83004985034282,
          21.020642787249567,
          105.83020541846373,
          21.02075295050846
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83020541846373,
            21.020642787249567
          ],
          [
            105.83004985034282,
            21.02075295050846
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "diemDau": "7-3",
        "diemCuoi": "7-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.020642787249567,
        "to_latitude": 21.02075295050846,
        "to_longitude": 105.83004985034282,
        "from_longitude": 105.83020541846373,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7578,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82993451535602,
          21.02075295050846,
          105.83004985034282,
          21.02086060997869
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83004985034282,
            21.02075295050846
          ],
          [
            105.82993451535602,
            21.02086060997869
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "diemDau": "7-4",
        "diemCuoi": "7-9",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02075295050846,
        "to_latitude": 21.02086060997869,
        "to_longitude": 105.82993451535602,
        "from_longitude": 105.83004985034282,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7579,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82977358281963,
          21.02086060997869,
          105.82993451535602,
          21.020970773077504
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82993451535602,
            21.02086060997869
          ],
          [
            105.82977358281963,
            21.020970773077504
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "diemDau": "7-9",
        "diemCuoi": "7-10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02086060997869,
        "to_latitude": 21.020970773077504,
        "to_longitude": 105.82977358281963,
        "from_longitude": 105.82993451535602,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7580,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82956168830022,
          21.020970773077504,
          105.82977358281963,
          21.021057999862027
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82977358281963,
            21.020970773077504
          ],
          [
            105.82958311609318,
            21.021057999862027
          ],
          [
            105.82956168830022,
            21.02103336571037
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "diemDau": "7-10",
        "diemCuoi": "7-11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.020970773077504,
        "to_latitude": 21.02103336571037,
        "to_longitude": 105.82956168830022,
        "from_longitude": 105.82977358281963,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7581,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82936320483746,
          21.02103336571037,
          105.82956168830022,
          21.02111849164921
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82956168830022,
            21.02103336571037
          ],
          [
            105.82936320483746,
            21.02111849164921
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "diemDau": "7-11",
        "diemCuoi": "7-12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02103336571037,
        "to_latitude": 21.02111849164921,
        "to_longitude": 105.82936320483746,
        "from_longitude": 105.82956168830022,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7582,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82936320483746,
          21.02111849164921,
          105.82947585761654,
          21.021336313683342
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82936320483746,
            21.02111849164921
          ],
          [
            105.82947585761654,
            21.021336313683342
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "diemDau": "7-12",
        "diemCuoi": "7-13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02111849164921,
        "to_latitude": 21.021336313683342,
        "to_longitude": 105.82947585761654,
        "from_longitude": 105.82936320483746,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7583,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82947585761654,
          21.021211128645735,
          105.82976285397967,
          21.021336313683342
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82947585761654,
            21.021336313683342
          ],
          [
            105.82976285397967,
            21.021211128645735
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "diemDau": "7-13",
        "diemCuoi": "7-14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.021336313683342,
        "to_latitude": 21.021211128645735,
        "to_longitude": 105.82976285397967,
        "from_longitude": 105.82947585761654,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7584,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82993451535602,
          21.02060272786213,
          105.83004985034282,
          21.02075295050846
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83004985034282,
            21.02075295050846
          ],
          [
            105.82993451535602,
            21.02060272786213
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "diemDau": "7-4",
        "diemCuoi": "7-5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02075295050846,
        "to_latitude": 21.02060272786213,
        "to_longitude": 105.82993451535602,
        "from_longitude": 105.83004985034282,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7585,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82983527362464,
          21.02060272786213,
          105.82993451535602,
          21.020667824360952
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82993451535602,
            21.02060272786213
          ],
          [
            105.82983527362464,
            21.020667824360952
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "diemDau": "7-5",
        "diemCuoi": "7-7",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02060272786213,
        "to_latitude": 21.020667824360952,
        "to_longitude": 105.82983527362464,
        "from_longitude": 105.82993451535602,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7586,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82973603189326,
          21.020667824360952,
          105.82983527362464,
          21.020737928251084
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82983527362464,
            21.020667824360952
          ],
          [
            105.82973603189326,
            21.020737928251084
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "diemDau": "7-7",
        "diemCuoi": "7-8",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.020667824360952,
        "to_latitude": 21.020737928251084,
        "to_longitude": 105.82973603189326,
        "from_longitude": 105.82983527362464,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7587,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82981113374598,
          21.02042496420224,
          105.82993451535602,
          21.02060272786213
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82993451535602,
            21.02060272786213
          ],
          [
            105.82981113374598,
            21.02042496420224
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "diemDau": "7-5",
        "diemCuoi": "7-8",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02060272786213,
        "to_latitude": 21.02042496420224,
        "to_longitude": 105.82981113374598,
        "from_longitude": 105.82993451535602,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7588,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82990598700957,
          21.02049757192003,
          105.82995060861145,
          21.02060272786213
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82993451535602,
            21.02060272786213
          ],
          [
            105.82990598700957,
            21.020528152478683
          ],
          [
            105.82995060861145,
            21.02049757192003
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "diemDau": "7-5",
        "diemCuoi": "7-5-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02060272786213,
        "to_latitude": 21.02049757192003,
        "to_longitude": 105.82995060861145,
        "from_longitude": 105.82993451535602,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7589,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8298647779278,
          21.020074443686973,
          105.83002839268089,
          21.020204637117487
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8298647779278,
            21.020074443686973
          ],
          [
            105.83002839268089,
            21.020204637117487
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 278 Tôn Đức Thắng",
        "diemDau": "6-4",
        "diemCuoi": "6-6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.020074443686973,
        "to_latitude": 21.020204637117487,
        "to_longitude": 105.83002839268089,
        "from_longitude": 105.8298647779278,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7590,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82997170113518,
          21.020204637117487,
          105.83002839268089,
          21.020302282115342
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83002839268089,
            21.020204637117487
          ],
          [
            105.82997170113518,
            21.020222699145577
          ],
          [
            105.83000157058552,
            21.020302282115342
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 278 Tôn Đức Thắng",
        "diemDau": "6-6",
        "diemCuoi": "6-7",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.020204637117487,
        "to_latitude": 21.020302282115342,
        "to_longitude": 105.83000157058552,
        "from_longitude": 105.83002839268089,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7591,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83054605900864,
          21.02035617857276,
          105.83069081876175,
          21.020427467916882
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83054605900864,
            21.020427467916882
          ],
          [
            105.83064543850566,
            21.02035617857276
          ],
          [
            105.83069081876175,
            21.020413607550022
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "diemDau": "7",
        "diemCuoi": "7-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.020427467916882,
        "to_latitude": 21.020413607550022,
        "to_longitude": 105.83069081876175,
        "from_longitude": 105.83054605900864,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7592,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83038512647227,
          21.020993306427854,
          105.83068016946763,
          21.021201113837847
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83068016946763,
            21.020993306427854
          ],
          [
            105.83038512647227,
            21.021201113837847
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "9",
        "diemCuoi": "9-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.020993306427854,
        "to_latitude": 21.021201113837847,
        "to_longitude": 105.83038512647227,
        "from_longitude": 105.83068016946763,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7593,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83020810068044,
          21.021201113837847,
          105.83038512647227,
          21.021306269283738
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83038512647227,
            21.021201113837847
          ],
          [
            105.83020810068044,
            21.021306269283738
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "9-2",
        "diemCuoi": "9-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.021201113837847,
        "to_latitude": 21.021306269283738,
        "to_longitude": 105.83020810068044,
        "from_longitude": 105.83038512647227,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7594,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83015177429093,
          21.021203617539438,
          105.83020810068044,
          21.021306269283738
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83020810068044,
            21.021306269283738
          ],
          [
            105.83015177429093,
            21.021203617539438
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.021306269283738,
        "to_latitude": 21.021203617539438,
        "to_longitude": 105.83015177429093,
        "from_longitude": 105.83020810068044,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7595,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83008740126917,
          21.02103837312004,
          105.83015177429093,
          21.021203617539438
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83015177429093,
            21.021203617539438
          ],
          [
            105.83008740126917,
            21.02103837312004
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.021203617539438,
        "to_latitude": 21.02103837312004,
        "to_longitude": 105.83008740126917,
        "from_longitude": 105.83015177429093,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7596,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83001763392703,
          21.02088774786498,
          105.83008740126917,
          21.02103837312004
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83008740126917,
            21.02103837312004
          ],
          [
            105.83001763392703,
            21.02088774786498
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 2",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02103837312004,
        "to_latitude": 21.02088774786498,
        "to_longitude": 105.83001763392703,
        "from_longitude": 105.83008740126917,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7597,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83008740126917,
          21.020975780488442,
          105.83019468963275,
          21.02103837312004
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83008740126917,
            21.02103837312004
          ],
          [
            105.83019468963275,
            21.020975780488442
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02103837312004,
        "to_latitude": 21.020975780488442,
        "to_longitude": 105.83019468963275,
        "from_longitude": 105.83008740126917,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7598,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83019468963275,
          21.02090317300351,
          105.8303529399614,
          21.020975780488442
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83019468963275,
            21.020975780488442
          ],
          [
            105.8303529399614,
            21.02090317300351
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.020975780488442,
        "to_latitude": 21.02090317300351,
        "to_longitude": 105.8303529399614,
        "from_longitude": 105.83019468963275,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7599,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8303529399614,
          21.020767972765228,
          105.83043220290034,
          21.02090317300351
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8303529399614,
            21.02090317300351
          ],
          [
            105.83043220290034,
            21.02086943950554
          ],
          [
            105.8303931730955,
            21.020767972765228
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 13,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 2",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02090317300351,
        "to_latitude": 21.020767972765228,
        "to_longitude": 105.8303931730955,
        "from_longitude": 105.8303529399614,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7600,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82995865523468,
          21.021306269283738,
          105.83020810068044,
          21.021428950544543
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83020810068044,
            21.021306269283738
          ],
          [
            105.82995865523468,
            21.021428950544543
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "9-3",
        "diemCuoi": "9-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.021306269283738,
        "to_latitude": 21.021428950544543,
        "to_longitude": 105.82995865523468,
        "from_longitude": 105.83020810068044,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7601,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82970116317465,
          21.021428950544543,
          105.82995865523468,
          21.021561646487857
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82995865523468,
            21.021428950544543
          ],
          [
            105.82970116317465,
            21.021561646487857
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "9-4",
        "diemCuoi": "9-5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.021428950544543,
        "to_latitude": 21.021561646487857,
        "to_longitude": 105.82970116317465,
        "from_longitude": 105.82995865523468,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7602,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82945708215335,
          21.021561646487857,
          105.82970116317465,
          21.021684327537688
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82970116317465,
            21.021561646487857
          ],
          [
            105.82945708215335,
            21.021684327537688
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "9-5",
        "diemCuoi": "9-6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.021561646487857,
        "to_latitude": 21.021684327537688,
        "to_longitude": 105.82945708215335,
        "from_longitude": 105.82970116317465,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7603,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82927200972934,
          21.021684327537688,
          105.82945708215335,
          21.021774460489812
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82945708215335,
            21.021684327537688
          ],
          [
            105.82927200972934,
            21.021774460489812
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "9-6",
        "diemCuoi": "9-7",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.021684327537688,
        "to_latitude": 21.021774460489812,
        "to_longitude": 105.82927200972934,
        "from_longitude": 105.82945708215335,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7604,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82919154346115,
          21.021644268430954,
          105.82927200972934,
          21.021774460489812
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82927200972934,
            21.021774460489812
          ],
          [
            105.82919154346115,
            21.021644268430954
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngách 79 Cẩm Văn",
        "diemDau": "9-7",
        "diemCuoi": "9-8",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.021774460489812,
        "to_latitude": 21.021644268430954,
        "to_longitude": 105.82919154346115,
        "from_longitude": 105.82927200972934,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7605,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82909766614524,
          21.021366358076907,
          105.82919154346115,
          21.021644268430954
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82919154346115,
            21.021644268430954
          ],
          [
            105.82909766614524,
            21.021366358076907
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "9-8",
        "diemCuoi": "9-9",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.021644268430954,
        "to_latitude": 21.021366358076907,
        "to_longitude": 105.82909766614524,
        "from_longitude": 105.82919154346115,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7606,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82890991151348,
          21.021644268430954,
          105.82919154346115,
          21.02173940879337
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82919154346115,
            21.021644268430954
          ],
          [
            105.82890991151348,
            21.02173940879337
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "9-8",
        "diemCuoi": "9-10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.021644268430954,
        "to_latitude": 21.02173940879337,
        "to_longitude": 105.82890991151348,
        "from_longitude": 105.82919154346115,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7607,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82882139861309,
          21.02150406146999,
          105.82890991151348,
          21.02173940879337
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82890991151348,
            21.02173940879337
          ],
          [
            105.82882139861309,
            21.02150406146999
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "9-10",
        "diemCuoi": "9-11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02173940879337,
        "to_latitude": 21.02150406146999,
        "to_longitude": 105.82882139861309,
        "from_longitude": 105.82890991151348,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7608,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82870670428677,
          21.02122574795677,
          105.82882139861309,
          21.02150406146999
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82882139861309,
            21.02150406146999
          ],
          [
            105.82870670428677,
            21.02122574795677
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "9-11",
        "diemCuoi": "9-12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02150406146999,
        "to_latitude": 21.02122574795677,
        "to_longitude": 105.82870670428677,
        "from_longitude": 105.82882139861309,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7609,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82907889068208,
          21.021774460489812,
          105.82927200972934,
          21.021874608150778
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82927200972934,
            21.021774460489812
          ],
          [
            105.82907889068208,
            21.021874608150778
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "9-7",
        "diemCuoi": "9-13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.021774460489812,
        "to_latitude": 21.021874608150778,
        "to_longitude": 105.82907889068208,
        "from_longitude": 105.82927200972934,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7610,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82889918267352,
          21.021874608150778,
          105.82907889068208,
          21.021969748366224
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82907889068208,
            21.021874608150778
          ],
          [
            105.82889918267352,
            21.021969748366224
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "9-13",
        "diemCuoi": "9-14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.021874608150778,
        "to_latitude": 21.021969748366224,
        "to_longitude": 105.82889918267352,
        "from_longitude": 105.82907889068208,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7611,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82878384768674,
          21.02193720040417,
          105.82889918267352,
          21.021969748366224
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82889918267352,
            21.021969748366224
          ],
          [
            105.82878384768674,
            21.02193720040417
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "9-14",
        "diemCuoi": "9-17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.021969748366224,
        "to_latitude": 21.02193720040417,
        "to_longitude": 105.82878384768674,
        "from_longitude": 105.82889918267352,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7612,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82843395718034,
          21.02193720040417,
          105.82878384768674,
          21.022011125779592
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82878384768674,
            21.02193720040417
          ],
          [
            105.82870754249578,
            21.021966059382283
          ],
          [
            105.8286351228598,
            21.02196105200377
          ],
          [
            105.82843395718034,
            21.022011125779592
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Ngân Hàng",
        "diemDau": "9-17",
        "diemCuoi": "9-18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02193720040417,
        "to_latitude": 21.022011125779592,
        "to_longitude": 105.82843395718034,
        "from_longitude": 105.82878384768674,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7613,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82824888475629,
          21.022011125779592,
          105.82843395718034,
          21.022123791714485
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82843395718034,
            21.022011125779592
          ],
          [
            105.82840713509391,
            21.02203115528588
          ],
          [
            105.82840713509391,
            21.022078725351246
          ],
          [
            105.82824888475629,
            21.022123791714485
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Ngân Hàng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.022011125779592,
        "to_latitude": 21.022123791714485,
        "to_longitude": 105.82824888475629,
        "from_longitude": 105.82843395718034,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7614,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82803551137636,
          21.022123791714485,
          105.82824888475629,
          21.02219508021259
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82824888475629,
            21.022123791714485
          ],
          [
            105.82803551137636,
            21.02219508021259
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Ngân Hàng",
        "diemDau": "9-18",
        "diemCuoi": "9-23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.022123791714485,
        "to_latitude": 21.02219508021259,
        "to_longitude": 105.82803551137636,
        "from_longitude": 105.82824888475629,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7615,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82803551137636,
          21.02219508021259,
          105.82807038009497,
          21.022390367538016
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82803551137636,
            21.02219508021259
          ],
          [
            105.82807038009497,
            21.022390367538016
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Ngân Hàng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02219508021259,
        "to_latitude": 21.022390367538016,
        "to_longitude": 105.82807038009497,
        "from_longitude": 105.82803551137636,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7616,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82807038009497,
          21.022390367538016,
          105.82809868105088,
          21.02250685525572
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82807038009497,
            21.022390367538016
          ],
          [
            105.82809868105088,
            21.02250685525572
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Ngân Hàng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.022390367538016,
        "to_latitude": 21.02250685525572,
        "to_longitude": 105.82809868105088,
        "from_longitude": 105.82807038009497,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7617,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82807185896449,
          21.02250685525572,
          105.82809868105088,
          21.022608187713942
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82809868105088,
            21.02250685525572
          ],
          [
            105.82807185896449,
            21.02252938837475
          ],
          [
            105.82808647335041,
            21.022608187713942
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Ngân Hàng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02250685525572,
        "to_latitude": 21.022608187713942,
        "to_longitude": 105.82808647335041,
        "from_longitude": 105.82809868105088,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7618,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82795504510817,
          21.022608187713942,
          105.82809599884315,
          21.022680794368547
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82808647335041,
            21.022608187713942
          ],
          [
            105.82809599884315,
            21.022652068628233
          ],
          [
            105.82795504510817,
            21.022680794368547
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Ngân Hàng",
        "diemDau": "9-25",
        "diemCuoi": "9-26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.022608187713942,
        "to_latitude": 21.022680794368547,
        "to_longitude": 105.82795504510817,
        "from_longitude": 105.82808647335041,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7619,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82830641448408,
          21.022447952213604,
          105.82833860099494,
          21.02256061781753
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82830641448408,
            21.022447952213604
          ],
          [
            105.82833860099494,
            21.02256061781753
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Ngân Hàng",
        "diemDau": "9-21",
        "diemCuoi": "9-22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.022447952213604,
        "to_latitude": 21.02256061781753,
        "to_longitude": 105.82833860099494,
        "from_longitude": 105.82830641448408,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7620,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82830641448408,
          21.02240038226601,
          105.8285263556267,
          21.022447952213604
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82830641448408,
            21.022447952213604
          ],
          [
            105.8285263556267,
            21.02240038226601
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Ngân Hàng",
        "diemDau": "9-19",
        "diemCuoi": "9-21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.022447952213604,
        "to_latitude": 21.02240038226601,
        "to_longitude": 105.8285263556267,
        "from_longitude": 105.82830641448408,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7621,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8285263556267,
          21.02237784912748,
          105.82875166118484,
          21.022424233790698
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8285263556267,
            21.02240038226601
          ],
          [
            105.82854392775164,
            21.022424233790698
          ],
          [
            105.82875166118484,
            21.02237784912748
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Ngân Hàng",
        "diemDau": "9-19",
        "diemCuoi": "9-20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02240038226601,
        "to_latitude": 21.02237784912748,
        "to_longitude": 105.82875166118484,
        "from_longitude": 105.8285263556267,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7622,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82847744441025,
          21.022203407503262,
          105.8285263556267,
          21.02240038226601
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8285263556267,
            21.02240038226601
          ],
          [
            105.82847744441025,
            21.022203407503262
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Ngân Hàng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02240038226601,
        "to_latitude": 21.022203407503262,
        "to_longitude": 105.82847744441025,
        "from_longitude": 105.8285263556267,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7623,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82843395718034,
          21.022011125779592,
          105.82847744441025,
          21.022203407503262
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82847744441025,
            21.022203407503262
          ],
          [
            105.82843395718034,
            21.022011125779592
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tập Thể Ngân Hàng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.022203407503262,
        "to_latitude": 21.022011125779592,
        "to_longitude": 105.82843395718034,
        "from_longitude": 105.82847744441025,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7624,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82888188608885,
          21.021969748366224,
          105.82897696674297,
          21.02230774600843
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82889918267352,
            21.021969748366224
          ],
          [
            105.82888188608885,
            21.02199610364894
          ],
          [
            105.82897696674297,
            21.02230774600843
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "9-14",
        "diemCuoi": "9-15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.021969748366224,
        "to_latitude": 21.02230774600843,
        "to_longitude": 105.82897696674297,
        "from_longitude": 105.82889918267352,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7625,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82897696674297,
          21.02230774600843,
          105.82903865754797,
          21.02263572817367
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82897696674297,
            21.02230774600843
          ],
          [
            105.82903865754797,
            21.02263572817367
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "9-15",
        "diemCuoi": "9-16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02230774600843,
        "to_latitude": 21.02263572817367,
        "to_longitude": 105.82903865754797,
        "from_longitude": 105.82897696674297,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7626,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83068016946763,
          21.020861771899348,
          105.83091344210314,
          21.020993306427854
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83068016946763,
            21.020993306427854
          ],
          [
            105.83091344210314,
            21.020861771899348
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "diemDau": "9",
        "diemCuoi": "9-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.020993306427854,
        "to_latitude": 21.020861771899348,
        "to_longitude": 105.83091344210314,
        "from_longitude": 105.83068016946763,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7627,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83102885663577,
          21.02150271968907,
          105.83122580150004,
          21.021689334925487
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8312084850985,
            21.02150271968907
          ],
          [
            105.83122580150004,
            21.021588314804085
          ],
          [
            105.83102885663577,
            21.021689334925487
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 232 Tôn Đức Thắng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02150271968907,
        "to_latitude": 21.021689334925487,
        "to_longitude": 105.83102885663577,
        "from_longitude": 105.8312084850985,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7628,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83084646641949,
          21.021689334925487,
          105.83102885663577,
          21.02169934970059
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83102885663577,
            21.021689334925487
          ],
          [
            105.83084646641949,
            21.02169934970059
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 232 Tôn Đức Thắng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.021689334925487,
        "to_latitude": 21.02169934970059,
        "to_longitude": 105.83084646641949,
        "from_longitude": 105.83102885663577,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7629,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83081964433308,
          21.021576668662284,
          105.83084646641949,
          21.02169934970059
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83084646641949,
            21.02169934970059
          ],
          [
            105.83081964433308,
            21.021576668662284
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 232 Tôn Đức Thắng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02169934970059,
        "to_latitude": 21.021576668662284,
        "to_longitude": 105.83081964433308,
        "from_longitude": 105.83084646641949,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7630,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83051387250677,
          21.02169934970059,
          105.83084646641949,
          21.021852074932745
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83084646641949,
            21.02169934970059
          ],
          [
            105.83051387250677,
            21.021852074932745
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 232 Tôn Đức Thắng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02169934970059,
        "to_latitude": 21.021852074932745,
        "to_longitude": 105.83051387250677,
        "from_longitude": 105.83084646641949,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7631,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83043072402182,
          21.02175943833532,
          105.83051387250677,
          21.021852074932745
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83051387250677,
            21.021852074932745
          ],
          [
            105.83043072402182,
            21.02175943833532
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 232 Tôn Đức Thắng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.021852074932745,
        "to_latitude": 21.02175943833532,
        "to_longitude": 105.83043072402182,
        "from_longitude": 105.83051387250677,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7632,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83036366880135,
          21.02192968933532,
          105.83040926635093,
          21.02205487377046
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83036366880135,
            21.02192968933532
          ],
          [
            105.83040926635093,
            21.02205487377046
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "diemDau": "14-12",
        "diemCuoi": "14-14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02192968933532,
        "to_latitude": 21.02205487377046,
        "to_longitude": 105.83040926635093,
        "from_longitude": 105.83036366880135,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7633,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83023760497458,
          21.02205487377046,
          105.83040926635093,
          21.022117465948998
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83040926635093,
            21.02205487377046
          ],
          [
            105.83035408357064,
            21.0220852975291
          ],
          [
            105.83033530810748,
            21.022079038310714
          ],
          [
            105.83023760497458,
            21.022117465948998
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "diemDau": "14-12",
        "diemCuoi": "14-13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02205487377046,
        "to_latitude": 21.022117465948998,
        "to_longitude": 105.83023760497458,
        "from_longitude": 105.83040926635093,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7634,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83018396079282,
          21.02206238483298,
          105.83023760497458,
          21.022124104674667
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83023760497458,
            21.022117465948998
          ],
          [
            105.83022399644126,
            21.022124104674667
          ],
          [
            105.83018396079282,
            21.02206238483298
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "diemDau": "14-13",
        "diemCuoi": "14-15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.022117465948998,
        "to_latitude": 21.02206238483298,
        "to_longitude": 105.83018396079282,
        "from_longitude": 105.83023760497458,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7635,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83013031662001,
          21.02206238483298,
          105.83018396079282,
          21.022087421705965
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83018396079282,
            21.02206238483298
          ],
          [
            105.83013031662001,
            21.022087421705965
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 7,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "diemDau": "14-15",
        "diemCuoi": "14-16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02206238483298,
        "to_latitude": 21.022087421705965,
        "to_longitude": 105.83013031662001,
        "from_longitude": 105.83018396079282,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7636,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83004448592735,
          21.02210995488838,
          105.83008471906145,
          21.022129984381404
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83008471906145,
            21.02210995488838
          ],
          [
            105.83004448592735,
            21.022129984381404
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 7,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "diemDau": "14-17",
        "diemCuoi": "14-18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02210995488838,
        "to_latitude": 21.022129984381404,
        "to_longitude": 105.83004448592735,
        "from_longitude": 105.83008471906145,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7637,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83040926635093,
          21.02205487377046,
          105.83050314366682,
          21.022202591268893
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83040926635093,
            21.02205487377046
          ],
          [
            105.83050314366682,
            21.022202591268893
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "diemDau": "14-11",
        "diemCuoi": "14-12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02205487377046,
        "to_latitude": 21.022202591268893,
        "to_longitude": 105.83050314366682,
        "from_longitude": 105.83040926635093,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7638,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83050314366682,
          21.022202591268893,
          105.83056215226408,
          21.022345301254507
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83050314366682,
            21.022202591268893
          ],
          [
            105.83056215226408,
            21.022345301254507
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.022202591268893,
        "to_latitude": 21.022345301254507,
        "to_longitude": 105.83056215226408,
        "from_longitude": 105.83050314366682,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7639,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83056061366561,
          21.022247657594676,
          105.83068285167535,
          21.022345301254507
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83056215226408,
            21.022345301254507
          ],
          [
            105.83056061366561,
            21.02230562183467
          ],
          [
            105.83068285167535,
            21.022247657594676
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.022345301254507,
        "to_latitude": 21.022247657594676,
        "to_longitude": 105.83068285167535,
        "from_longitude": 105.83056215226408,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7640,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8304199951909,
          21.022345301254507,
          105.83056215226408,
          21.022387863856324
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83056215226408,
            21.022345301254507
          ],
          [
            105.8304199951909,
            21.022387863856324
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "diemDau": "14-6",
        "diemCuoi": "14-7",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.022345301254507,
        "to_latitude": 21.022387863856324,
        "to_longitude": 105.8304199951909,
        "from_longitude": 105.83056215226408,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7641,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83023760497458,
          21.022387863856324,
          105.8304199951909,
          21.022447952213604
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8304199951909,
            21.022387863856324
          ],
          [
            105.83023760497458,
            21.022447952213604
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "diemDau": "14-7",
        "diemCuoi": "14-8",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.022387863856324,
        "to_latitude": 21.022447952213604,
        "to_longitude": 105.83023760497458,
        "from_longitude": 105.8304199951909,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7642,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83017859637732,
          21.022347804937763,
          105.83023760497458,
          21.022447952213604
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83023760497458,
            21.022447952213604
          ],
          [
            105.83017859637732,
            21.022347804937763
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "diemDau": "14-8",
        "diemCuoi": "14-9",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.022447952213604,
        "to_latitude": 21.022347804937763,
        "to_longitude": 105.83017859637732,
        "from_longitude": 105.83023760497458,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7643,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83056215226408,
          21.022345301254507,
          105.83066944062766,
          21.02255310678006
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83056215226408,
            21.022345301254507
          ],
          [
            105.83066944062766,
            21.02255310678006
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.022345301254507,
        "to_latitude": 21.02255310678006,
        "to_longitude": 105.83066944062766,
        "from_longitude": 105.83056215226408,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7644,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83054874122539,
          21.022345301254507,
          105.83060755232805,
          21.022503033186304
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83056215226408,
            21.022345301254507
          ],
          [
            105.83060755232805,
            21.022479627731684
          ],
          [
            105.83054874122539,
            21.022503033186304
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "diemDau": "14-6",
        "diemCuoi": "14-21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.022345301254507,
        "to_latitude": 21.022503033186304,
        "to_longitude": 105.83054874122539,
        "from_longitude": 105.83056215226408,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7645,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83066944062766,
          21.02255310678006,
          105.83082500874858,
          21.02259980434798
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83066944062766,
            21.02255310678006
          ],
          [
            105.83069338301172,
            21.02259980434798
          ],
          [
            105.83082500874858,
            21.02255310678006
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02255310678006,
        "to_latitude": 21.02255310678006,
        "to_longitude": 105.83082500874858,
        "from_longitude": 105.83066944062766,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7646,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83080603579077,
          21.02255310678006,
          105.83086792409041,
          21.02268830540038
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83082500874858,
            21.02255310678006
          ],
          [
            105.83080603579077,
            21.0225772712396
          ],
          [
            105.83086792409041,
            21.02268830540038
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "diemDau": "14-4",
        "diemCuoi": "14-20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02255310678006,
        "to_latitude": 21.02268830540038,
        "to_longitude": 105.83086792409041,
        "from_longitude": 105.83082500874858,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7647,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83082500874858,
          21.022447952213604,
          105.83103958547574,
          21.02255310678006
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83082500874858,
            21.02255310678006
          ],
          [
            105.83103958547574,
            21.022447952213604
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "diemDau": "14-3",
        "diemCuoi": "14-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02255310678006,
        "to_latitude": 21.022447952213604,
        "to_longitude": 105.83103958547574,
        "from_longitude": 105.83082500874858,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7648,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8314580100811,
          21.022237709405093,
          105.83159472321098,
          21.022296389499605
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8314580100811,
            21.022252664963556
          ],
          [
            105.83154062580218,
            21.022237709405093
          ],
          [
            105.83159472321098,
            21.022296389499605
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "diemDau": "14",
        "diemCuoi": "14-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.022252664963556,
        "to_latitude": 21.022296389499605,
        "to_longitude": 105.83159472321098,
        "from_longitude": 105.8314580100811,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7649,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83147946775203,
          21.022296389499605,
          105.83159472321098,
          21.022390367538016
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83159472321098,
            21.022296389499605
          ],
          [
            105.83159292887112,
            21.022346619644765
          ],
          [
            105.83147946775203,
            21.022390367538016
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 200",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.022296389499605,
        "to_latitude": 21.022390367538016,
        "to_longitude": 105.83147946775203,
        "from_longitude": 105.83159472321098,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7650,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83126757324159,
          21.02205853936906,
          105.83147222946882,
          21.022210102323967
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83146329495077,
            21.02205853936906
          ],
          [
            105.83147222946882,
            21.02210751775073
          ],
          [
            105.83126757324159,
            21.022210102323967
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 204",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.02205853936906,
        "to_latitude": 21.022210102323967,
        "to_longitude": 105.83126757324159,
        "from_longitude": 105.83146329495077,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7651,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83126757324159,
          21.022252664963556,
          105.8314580100811,
          21.022335286523653
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8314580100811,
            21.022252664963556
          ],
          [
            105.83126757324159,
            21.022335286523653
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thinh Hào 3",
        "diemDau": "14-1",
        "diemCuoi": "14-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.022252664963556,
        "to_latitude": 21.022335286523653,
        "to_longitude": 105.83126757324159,
        "from_longitude": 105.8314580100811,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7652,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83103958547574,
          21.022335286523653,
          105.83126757324159,
          21.022447952213604
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83126757324159,
            21.022335286523653
          ],
          [
            105.83103958547574,
            21.022447952213604
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thinh Hào 3",
        "diemDau": "14-2",
        "diemCuoi": "14-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.022335286523653,
        "to_latitude": 21.022447952213604,
        "to_longitude": 105.83103958547574,
        "from_longitude": 105.83126757324159,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7653,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8303207534595,
          21.022202591268893,
          105.83050314366682,
          21.02226518338459
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83050314366682,
            21.022202591268893
          ],
          [
            105.8303207534595,
            21.02226518338459
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thinh Hào 3",
        "diemDau": "14-11",
        "diemCuoi": "14-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.022202591268893,
        "to_latitude": 21.02226518338459,
        "to_longitude": 105.8303207534595,
        "from_longitude": 105.83050314366682,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7654,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83008471906145,
          21.022087421705965,
          105.83013031662001,
          21.02210995488838
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83013031662001,
            21.022087421705965
          ],
          [
            105.83008471906145,
            21.02210995488838
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HB7",
        "chieuDaiQL": 7,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Thinh Hào 3",
        "diemDau": "14-16",
        "diemCuoi": "14-17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Hàng Bột 7",
        "from_latitude": 21.022087421705965,
        "to_latitude": 21.02210995488838,
        "to_longitude": 105.83008471906145,
        "from_longitude": 105.83013031662001,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7655,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82747892305956,
          21.002733826621146,
          105.82748462282512,
          21.002886258288893
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82747892305956,
            21.002886258288893
          ],
          [
            105.82748462282512,
            21.002733826621146
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/96 Trường Chinh",
        "diemDau": "04",
        "diemCuoi": "4-11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002886258288893,
        "to_latitude": 21.002733826621146,
        "to_longitude": 105.82748462282512,
        "from_longitude": 105.82747892305956,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7656,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8274148853879,
          21.00261112998371,
          105.82748462282512,
          21.002733826621146
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82748462282512,
            21.002733826621146
          ],
          [
            105.82741756759563,
            21.0027037784745
          ],
          [
            105.8274148853879,
            21.00261112998371
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/96 Trường Chinh",
        "diemDau": "4-11",
        "diemCuoi": "4-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002733826621146,
        "to_latitude": 21.00261112998371,
        "to_longitude": 105.8274148853879,
        "from_longitude": 105.82748462282512,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7657,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82790539428817,
          21.002548216615573,
          105.82807437351066,
          21.002669661317224
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82790539428817,
            21.002669661317224
          ],
          [
            105.82798854282697,
            21.00266089725118
          ],
          [
            105.8279845195064,
            21.002570752749016
          ],
          [
            105.8280100004979,
            21.002551972638038
          ],
          [
            105.82807437351066,
            21.002548216615573
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "",
        "diemDau": "5-1",
        "diemCuoi": "5-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002669661317224,
        "to_latitude": 21.002548216615573,
        "to_longitude": 105.82807437351066,
        "from_longitude": 105.82790539428817,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7658,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82807437351066,
          21.002345391255783,
          105.82813606431567,
          21.002548216615573
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82807437351066,
            21.002548216615573
          ],
          [
            105.82812935879632,
            21.002544460592993
          ],
          [
            105.82813606431567,
            21.002529436500943
          ],
          [
            105.82811728886148,
            21.002345391255783
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/144 Trường Chinh",
        "diemDau": "5-2",
        "diemCuoi": "5-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002548216615573,
        "to_latitude": 21.002345391255783,
        "to_longitude": 105.82811728886148,
        "from_longitude": 105.82807437351066,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7659,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82811728886148,
          21.00231909905923,
          105.8282889502378,
          21.002345391255783
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82811728886148,
            21.002345391255783
          ],
          [
            105.8282889502378,
            21.00231909905923
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/144 Trường Chinh",
        "diemDau": "5-3",
        "diemCuoi": "5-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.002345391255783,
        "to_latitude": 21.00231909905923,
        "to_longitude": 105.8282889502378,
        "from_longitude": 105.82811728886148,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7660,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82916603251083,
          21.004127615794868,
          105.8291874902536,
          21.00425218915265
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82916603251083,
            21.004127615794868
          ],
          [
            105.8291874902536,
            21.00425218915265
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TB2",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "diemDau": "6-12",
        "diemCuoi": "6-12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Tàu Bay 2",
        "from_latitude": 21.004127615794868,
        "to_latitude": 21.00425218915265,
        "to_longitude": 105.8291874902536,
        "from_longitude": 105.82916603251083,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7661,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79909444617303,
          21.02682079005632,
          105.79920978116886,
          21.026993538880305
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79920978116886,
            21.026993538880305
          ],
          [
            105.79909444617303,
            21.02682079005632
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "VHA2",
        "from_latitude": 21.026993538880305,
        "to_latitude": 21.02682079005632,
        "to_longitude": 105.79909444617303,
        "from_longitude": 105.79920978116886,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7662,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79897374677074,
          21.026625508534796,
          105.79909444617303,
          21.02682079005632
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79909444617303,
            21.02682079005632
          ],
          [
            105.79897374677074,
            21.026625508534796
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "VHA2",
        "from_latitude": 21.02682079005632,
        "to_latitude": 21.026625508534796,
        "to_longitude": 105.79897374677074,
        "from_longitude": 105.79909444617303,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7663,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79886377619944,
          21.02643773760036,
          105.79897374677074,
          21.026625508534796
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79897374677074,
            21.026625508534796
          ],
          [
            105.79886377619944,
            21.02643773760036
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "VHA2",
        "from_latitude": 21.026625508534796,
        "to_latitude": 21.02643773760036,
        "to_longitude": 105.79886377619944,
        "from_longitude": 105.79897374677074,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7664,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79875917005258,
          21.026244959194788,
          105.79886377619944,
          21.02643773760036
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79886377619944,
            21.02643773760036
          ],
          [
            105.79875917005258,
            21.026244959194788
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "VHA2",
        "from_latitude": 21.02643773760036,
        "to_latitude": 21.026244959194788,
        "to_longitude": 105.79875917005258,
        "from_longitude": 105.79886377619944,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7665,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79864919948123,
          21.026057187780975,
          105.79875917005258,
          21.026244959194788
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79875917005258,
            21.026244959194788
          ],
          [
            105.79864919948123,
            21.026057187780975
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "VHA2",
        "from_latitude": 21.026244959194788,
        "to_latitude": 21.026057187780975,
        "to_longitude": 105.79864919948123,
        "from_longitude": 105.79875917005258,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7666,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79864919948123,
          21.025962050173252,
          105.79872698354171,
          21.026057187780975
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79864919948123,
            21.026057187780975
          ],
          [
            105.79865054060309,
            21.02599146772866
          ],
          [
            105.79872698354171,
            21.025962050173252
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.026057187780975,
        "to_latitude": 21.025962050173252,
        "to_longitude": 105.79872698354171,
        "from_longitude": 105.79864919948123,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7667,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79872698354171,
          21.025860027539615,
          105.79903007317824,
          21.025962050173252
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79872698354171,
            21.025962050173252
          ],
          [
            105.79903007317824,
            21.025860027539615
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "18",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.025962050173252,
        "to_latitude": 21.025860027539615,
        "to_longitude": 105.79903007317824,
        "from_longitude": 105.79872698354171,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7668,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79903007317824,
          21.025789300152983,
          105.79924464987846,
          21.025860027539615
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79903007317824,
            21.025860027539615
          ],
          [
            105.79924464987846,
            21.025789300152983
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.025860027539615,
        "to_latitude": 21.025789300152983,
        "to_longitude": 105.79924464987846,
        "from_longitude": 105.79903007317824,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7669,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79924464987846,
          21.02570230026593,
          105.79936611103781,
          21.025789300152983
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79924464987846,
            21.025789300152983
          ],
          [
            105.79925001431191,
            21.02573484629979
          ],
          [
            105.79936611103781,
            21.02570230026593
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "20",
        "diemCuoi": "20-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.025789300152983,
        "to_latitude": 21.02570230026593,
        "to_longitude": 105.79936611103781,
        "from_longitude": 105.79924464987846,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7670,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79986021686966,
          21.02514524124696,
          105.79989106225868,
          21.025491368489025
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79989106225868,
            21.025491368489025
          ],
          [
            105.79986021686966,
            21.02514524124696
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "29",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.025491368489025,
        "to_latitude": 21.02514524124696,
        "to_longitude": 105.79986021686966,
        "from_longitude": 105.79989106225868,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7671,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79989106225868,
          21.025461324926788,
          105.80003053712416,
          21.025491368489025
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79989106225868,
            21.025491368489025
          ],
          [
            105.80003053712416,
            21.025461324926788
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.025491368489025,
        "to_latitude": 21.025461324926788,
        "to_longitude": 105.80003053712416,
        "from_longitude": 105.79989106225868,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7672,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79986021686966,
          21.02506074351805,
          105.79992324876058,
          21.025145241246932
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79986021686966,
            21.025145241246932
          ],
          [
            105.79992190765672,
            21.025109564434274
          ],
          [
            105.79992324876058,
            21.02506074351805
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.025145241246932,
        "to_latitude": 21.02506074351805,
        "to_longitude": 105.79992324876058,
        "from_longitude": 105.79986021686966,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7673,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79992324876058,
          21.02505823988035,
          105.80022365617141,
          21.025063247154005
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79992324876058,
            21.02506074351805
          ],
          [
            105.80007077025824,
            21.025063247154005
          ],
          [
            105.80022365617141,
            21.02505823988035
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.02506074351805,
        "to_latitude": 21.02505823988035,
        "to_longitude": 105.80022365617141,
        "from_longitude": 105.79992324876058,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7674,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79992324876058,
          21.024910525212352,
          105.80000103282103,
          21.02506074351805
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79992324876058,
            21.02506074351805
          ],
          [
            105.79992727209914,
            21.024949957531142
          ],
          [
            105.80000103282103,
            21.024910525212352
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "32",
        "diemCuoi": "34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.02506074351805,
        "to_latitude": 21.024910525212352,
        "to_longitude": 105.80000103282103,
        "from_longitude": 105.79992324876058,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7675,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79999566840554,
          21.024747788543745,
          105.80000103282103,
          21.024910525212352
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80000103282103,
            21.024910525212352
          ],
          [
            105.79999566840554,
            21.024747788543745
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "34",
        "diemCuoi": "36",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.024910525212352,
        "to_latitude": 21.024747788543745,
        "to_longitude": 105.79999566840554,
        "from_longitude": 105.80000103282103,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7676,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79993934201603,
          21.02459256263257,
          105.79999566840554,
          21.024747788543745
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79999566840554,
            21.024747788543745
          ],
          [
            105.79993934201603,
            21.02459256263257
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "36",
        "diemCuoi": "37",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.024747788543745,
        "to_latitude": 21.02459256263257,
        "to_longitude": 105.79993934201603,
        "from_longitude": 105.79999566840554,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7677,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79971805977848,
          21.024910525212352,
          105.80000103282103,
          21.024975619829377
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80000103282103,
            21.024910525212352
          ],
          [
            105.79989776777805,
            21.024953087080096
          ],
          [
            105.79971805977848,
            21.024975619829377
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.024910525212352,
        "to_latitude": 21.024975619829377,
        "to_longitude": 105.79971805977848,
        "from_longitude": 105.80000103282103,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7678,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.7991802768747,
          21.02541625956996,
          105.79924464987846,
          21.025789300152983
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79924464987846,
            21.025789300152983
          ],
          [
            105.7991802768747,
            21.02541625956996
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.025789300152983,
        "to_latitude": 21.02541625956996,
        "to_longitude": 105.7991802768747,
        "from_longitude": 105.79924464987846,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7679,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79907567071886,
          21.025033203505483,
          105.7991802768747,
          21.02541625956996
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.7991802768747,
            21.02541625956996
          ],
          [
            105.79907567071886,
            21.025033203505483
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 48,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.02541625956996,
        "to_latitude": 21.025033203505483,
        "to_longitude": 105.79907567071886,
        "from_longitude": 105.7991802768747,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7680,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79907567071886,
          21.024983130743937,
          105.7992419676797,
          21.025033203505483
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79907567071886,
            21.025033203505483
          ],
          [
            105.7992419676797,
            21.024983130743937
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.025033203505483,
        "to_latitude": 21.024983130743937,
        "to_longitude": 105.7992419676797,
        "from_longitude": 105.79907567071886,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7681,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.7992419676797,
          21.024875474250788,
          105.79958529043238,
          21.024983130743937
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.7992419676797,
            21.024983130743937
          ],
          [
            105.79958529043238,
            21.024875474250788
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.024983130743937,
        "to_latitude": 21.024875474250788,
        "to_longitude": 105.79958529043238,
        "from_longitude": 105.7992419676797,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7682,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79917759465795,
          21.024700219328366,
          105.7992419676797,
          21.024983130743937
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.7992419676797,
            21.024983130743937
          ],
          [
            105.79917759465795,
            21.024700219328366
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "23",
        "diemCuoi": "23-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.024983130743937,
        "to_latitude": 21.024700219328366,
        "to_longitude": 105.79917759465795,
        "from_longitude": 105.7992419676797,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7683,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79954237509052,
          21.024524964199937,
          105.79958529043238,
          21.024875474250788
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79958529043238,
            21.024875474250788
          ],
          [
            105.79957322050653,
            21.024634498680292
          ],
          [
            105.79954237509052,
            21.024524964199937
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 44,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.024875474250788,
        "to_latitude": 21.024524964199937,
        "to_longitude": 105.79954237509052,
        "from_longitude": 105.79958529043238,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7684,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79936803150646,
          21.024634498680292,
          105.79957322050653,
          21.024667671963265
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79957322050653,
            21.024634498680292
          ],
          [
            105.79936803150646,
            21.024667671963265
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "25",
        "diemCuoi": "25-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.024634498680292,
        "to_latitude": 21.024667671963265,
        "to_longitude": 105.79936803150646,
        "from_longitude": 105.79957322050653,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7685,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79954237509052,
          21.024404789135577,
          105.79964429902965,
          21.024524964199937
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79954237509052,
            21.024524964199937
          ],
          [
            105.79964429902965,
            21.024404789135577
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.024524964199937,
        "to_latitude": 21.024404789135577,
        "to_longitude": 105.79964429902965,
        "from_longitude": 105.79954237509052,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7686,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79964429902965,
          21.024207000797755,
          105.79972476529784,
          21.024404789135577
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79964429902965,
            21.024404789135577
          ],
          [
            105.79972476529784,
            21.024207000797755
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.024404789135577,
        "to_latitude": 21.024207000797755,
        "to_longitude": 105.79972476529784,
        "from_longitude": 105.79964429902965,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7687,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79932779836336,
          21.02431465777367,
          105.79964429902965,
          21.024404789135577
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79964429902965,
            21.024404789135577
          ],
          [
            105.79932779836336,
            21.02431465777367
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 42,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "26",
        "diemCuoi": "26-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.024404789135577,
        "to_latitude": 21.02431465777367,
        "to_longitude": 105.79932779836336,
        "from_longitude": 105.79964429902965,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7688,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79963759350133,
          21.025491368489025,
          105.79989106225868,
          21.025592765465714
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79963759350133,
            21.025592765465714
          ],
          [
            105.79985887574783,
            21.025531426561187
          ],
          [
            105.79989106225868,
            21.025491368489025
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.025592765465714,
        "to_latitude": 21.025491368489025,
        "to_longitude": 105.79989106225868,
        "from_longitude": 105.79963759350133,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7689,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.79963759350133,
          21.025491368489025,
          105.79989106225868,
          21.025592765465714
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.79963759350133,
            21.025592765465714
          ],
          [
            105.79985887574783,
            21.025531426561187
          ],
          [
            105.79989106225868,
            21.025491368489025
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "VHA2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "VHA2",
        "from_latitude": 21.025592765465714,
        "to_latitude": 21.025491368489025,
        "to_longitude": 105.79989106225868,
        "from_longitude": 105.79963759350133,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7690,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80689263364171,
          21.025640647347455,
          105.80721181650672,
          21.025973629423802
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80721181650672,
            21.025973629423802
          ],
          [
            105.80693119037574,
            21.0259207404027
          ],
          [
            105.80689263364171,
            21.025640647347455
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 80 Chùa Láng",
        "diemDau": "",
        "diemCuoi": "41-10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.025973629423802,
        "to_latitude": 21.025640647347455,
        "to_longitude": 105.80689263364171,
        "from_longitude": 105.80721181650672,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7691,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80721181650672,
          21.025968622179928,
          105.80754172822068,
          21.025973629423802
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80721181650672,
            21.025973629423802
          ],
          [
            105.80754172822068,
            21.025968622179928
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 80 Chùa Láng",
        "diemDau": "41-10",
        "diemCuoi": "41-12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.025973629423802,
        "to_latitude": 21.025968622179928,
        "to_longitude": 105.80754172822068,
        "from_longitude": 105.80721181650672,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7692,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80689263364171,
          21.025640647347455,
          105.80729496499164,
          21.025785857668186
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80729496499164,
            21.02574329603738
          ],
          [
            105.8069543244467,
            21.025785857668186
          ],
          [
            105.80689263364171,
            21.025640647347455
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 80 Chùa Láng",
        "diemDau": "",
        "diemCuoi": "41-9",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02574329603738,
        "to_latitude": 21.025640647347455,
        "to_longitude": 105.80689263364171,
        "from_longitude": 105.80729496499164,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7693,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80689263364171,
          21.025603092931554,
          105.80740493556297,
          21.025640647347455
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80740493556297,
            21.025603092931554
          ],
          [
            105.80689263364171,
            21.025640647347455
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 80 Chùa Láng",
        "diemDau": "",
        "diemCuoi": "41-8",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.025603092931554,
        "to_latitude": 21.025640647347455,
        "to_longitude": 105.80689263364171,
        "from_longitude": 105.80740493556297,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7694,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80686782317966,
          21.025465080370616,
          105.80689900386486,
          21.025640647347455
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80689263364171,
            21.025640647347455
          ],
          [
            105.80689900386486,
            21.025465080370616
          ],
          [
            105.80686782317966,
            21.02546601923282
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 80 Chùa Láng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.025640647347455,
        "to_latitude": 21.02546601923282,
        "to_longitude": 105.80686782317966,
        "from_longitude": 105.80689263364171,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7695,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83087493495613,
          21.00574111469604,
          105.83099697547125,
          21.00606036944707
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83099697547125,
            21.00606036944707
          ],
          [
            105.83087493495613,
            21.00574111469604
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Thất Tùng",
        "diemDau": "1",
        "diemCuoi": "2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "T37KT",
        "from_latitude": 21.00606036944707,
        "to_latitude": 21.00574111469604,
        "to_longitude": 105.83087493495613,
        "from_longitude": 105.83099697547125,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7696,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8307649643848,
          21.005445646945695,
          105.83087493495613,
          21.00574111469604
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83087493495613,
            21.00574111469604
          ],
          [
            105.8307649643848,
            21.005445646945695
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Thất Tùng",
        "diemDau": "2",
        "diemCuoi": "3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "T37KT",
        "from_latitude": 21.00574111469604,
        "to_latitude": 21.005445646945695,
        "to_longitude": 105.8307649643848,
        "from_longitude": 105.83087493495613,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7697,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83067309874717,
          21.0054343264916,
          105.8307649643848,
          21.005450654878693
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8307649643848,
            21.005445646945695
          ],
          [
            105.83071936684419,
            21.0054343264916
          ],
          [
            105.83067309874717,
            21.005450654878693
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Tôn Thất Tùng",
        "diemDau": "3",
        "diemCuoi": "3-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "T37KT",
        "from_latitude": 21.005445646945695,
        "to_latitude": 21.005450654878693,
        "to_longitude": 105.83067309874717,
        "from_longitude": 105.8307649643848,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7698,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83068852142821,
          21.005222793763885,
          105.8307649643848,
          21.005445646945695
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8307649643848,
            21.005445646945695
          ],
          [
            105.83068852142821,
            21.005222793763885
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Thất Tùng",
        "diemDau": "3",
        "diemCuoi": "4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "T37KT",
        "from_latitude": 21.005445646945695,
        "to_latitude": 21.005222793763885,
        "to_longitude": 105.83068852142821,
        "from_longitude": 105.8307649643848,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7699,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83058793859296,
          21.004942348837044,
          105.83068852142821,
          21.005222793763885
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83068852142821,
            21.005222793763885
          ],
          [
            105.83058793859296,
            21.004942348837044
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Thất Tùng",
        "diemDau": "4",
        "diemCuoi": "5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "T37KT",
        "from_latitude": 21.005222793763885,
        "to_latitude": 21.004942348837044,
        "to_longitude": 105.83058793859296,
        "from_longitude": 105.83068852142821,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7700,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83050881342865,
          21.00472074689313,
          105.83058793859296,
          21.004942348837044
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83058793859296,
            21.004942348837044
          ],
          [
            105.83050881342865,
            21.00472074689313
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Thất Tùng",
        "diemDau": "5",
        "diemCuoi": "6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "T37KT",
        "from_latitude": 21.004942348837044,
        "to_latitude": 21.00472074689313,
        "to_longitude": 105.83050881342865,
        "from_longitude": 105.83058793859296,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7701,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83043237047207,
          21.004515420504347,
          105.83050881342865,
          21.00472074689313
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83050881342865,
            21.00472074689313
          ],
          [
            105.83043237047207,
            21.004515420504347
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Thất Tùng",
        "diemDau": "7",
        "diemCuoi": "6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "T37KT",
        "from_latitude": 21.00472074689313,
        "to_latitude": 21.004515420504347,
        "to_longitude": 105.83043237047207,
        "from_longitude": 105.83050881342865,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7702,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83035190419488,
          21.004283801982645,
          105.83043237047207,
          21.004515420504347
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83043237047207,
            21.004515420504347
          ],
          [
            105.83035190419488,
            21.004283801982645
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Thất Tùng",
        "diemDau": "8",
        "diemCuoi": "7",
        "chuDauTu": "SoXayDung",
        "tuDien2": "T37KT",
        "from_latitude": 21.004515420504347,
        "to_latitude": 21.004283801982645,
        "to_longitude": 105.83035190419488,
        "from_longitude": 105.83043237047207,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7703,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8303197177559,
          21.004221828316417,
          105.83035190419488,
          21.004283801982645
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83035190419488,
            21.004283801982645
          ],
          [
            105.8303197177559,
            21.004221828316417
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Thất Tùng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "T37KT",
        "from_latitude": 21.004283801982645,
        "to_latitude": 21.004221828316417,
        "to_longitude": 105.8303197177559,
        "from_longitude": 105.83035190419488,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7704,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83026875571896,
          21.004068459034375,
          105.8303197177559,
          21.004221828316417
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8303197177559,
            21.004221828316417
          ],
          [
            105.83026875571896,
            21.004068459034375
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Thất Tùng",
        "diemDau": "Tủ điện",
        "diemCuoi": "6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "T37KT",
        "from_latitude": 21.004221828316417,
        "to_latitude": 21.004068459034375,
        "to_longitude": 105.83026875571896,
        "from_longitude": 105.8303197177559,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7705,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83016515540675,
          21.00375702500575,
          105.83026875571896,
          21.004068459034375
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83026875571896,
            21.004068459034375
          ],
          [
            105.83016515540675,
            21.00375702500575
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Thất Tùng",
        "diemDau": "9",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "T37KT",
        "from_latitude": 21.004068459034375,
        "to_latitude": 21.00375702500575,
        "to_longitude": 105.83016515540675,
        "from_longitude": 105.83026875571896,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7706,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83008770660653,
          21.003521336308857,
          105.83016515540675,
          21.00375702500575
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83016515540675,
            21.00375702500575
          ],
          [
            105.83008770660653,
            21.003521336308857
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Thất Tùng",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "T37KT",
        "from_latitude": 21.00375702500575,
        "to_latitude": 21.003521336308857,
        "to_longitude": 105.83008770660653,
        "from_longitude": 105.83016515540675,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7707,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8300256805323,
          21.003336353286233,
          105.83008770660653,
          21.003521336308857
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83008770660653,
            21.003521336308857
          ],
          [
            105.8300256805323,
            21.003336353286233
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Tôn Thất Tùng",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "T37KT",
        "from_latitude": 21.003521336308857,
        "to_latitude": 21.003336353286233,
        "to_longitude": 105.8300256805323,
        "from_longitude": 105.83008770660653,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7708,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82988989371819,
          21.003336353286233,
          105.8300256805323,
          21.00338111231292
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8300256805323,
            21.003336353286233
          ],
          [
            105.82988989371819,
            21.00338111231292
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ nhà B2 khu TT Khương Thượng",
        "diemDau": "12",
        "diemCuoi": "12-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T37KT",
        "from_latitude": 21.003336353286233,
        "to_latitude": 21.00338111231292,
        "to_longitude": 105.82988989371819,
        "from_longitude": 105.8300256805323,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7709,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82967799920777,
          21.00338111231292,
          105.82988989371819,
          21.003423680325636
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82988989371819,
            21.00338111231292
          ],
          [
            105.82967799920777,
            21.003423680325636
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ nhà B2 khu TT Khương Thượng",
        "diemDau": "12-1",
        "diemCuoi": "12-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T37KT",
        "from_latitude": 21.00338111231292,
        "to_latitude": 21.003423680325636,
        "to_longitude": 105.82967799920777,
        "from_longitude": 105.82988989371819,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7710,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82946878690507,
          21.003423680325636,
          105.82967799920777,
          21.003463744326837
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82967799920777,
            21.003423680325636
          ],
          [
            105.82946878690507,
            21.003463744326837
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ nhà B2 khu TT Khương Thượng",
        "diemDau": "12-2",
        "diemCuoi": "12-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T37KT",
        "from_latitude": 21.003423680325636,
        "to_latitude": 21.003463744326837,
        "to_longitude": 105.82946878690507,
        "from_longitude": 105.82967799920777,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7711,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82940843719494,
          21.003148240027947,
          105.82961496728988,
          21.00319206010945
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82940843719494,
            21.00319206010945
          ],
          [
            105.82961496728988,
            21.003148240027947
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ nhà B1 khu TT Khương Thượng",
        "diemDau": "12-5",
        "diemCuoi": "12-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T37KT",
        "from_latitude": 21.00319206010945,
        "to_latitude": 21.003148240027947,
        "to_longitude": 105.82961496728988,
        "from_longitude": 105.82940843719494,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7712,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82961496728988,
          21.00310316793126,
          105.82982552069645,
          21.003148240027947
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82961496728988,
            21.003148240027947
          ],
          [
            105.82982552069645,
            21.00310316793126
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ nhà B1 khu TT Khương Thượng",
        "diemDau": "12-4",
        "diemCuoi": "12-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T37KT",
        "from_latitude": 21.003148240027947,
        "to_latitude": 21.00310316793126,
        "to_longitude": 105.82982552069645,
        "from_longitude": 105.82961496728988,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7713,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82982552069645,
          21.00310316793126,
          105.82994823175005,
          21.003154447435033
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82982552069645,
            21.00310316793126
          ],
          [
            105.82994823175005,
            21.003154447435033
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ nhà B1 khu TT Khương Thượng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T37KT",
        "from_latitude": 21.00310316793126,
        "to_latitude": 21.003154447435033,
        "to_longitude": 105.82994823175005,
        "from_longitude": 105.82982552069645,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7714,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82988989371819,
          21.003154447435033,
          105.82994823175005,
          21.00338111231292
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82994823175005,
            21.003154447435033
          ],
          [
            105.82988989371819,
            21.00338111231292
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ nhà B1 khu TT Khương Thượng",
        "diemDau": "12-1",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T37KT",
        "from_latitude": 21.003154447435033,
        "to_latitude": 21.00338111231292,
        "to_longitude": 105.82988989371819,
        "from_longitude": 105.82994823175005,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7715,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.829582780788,
          21.00356140028385,
          105.8298992814453,
          21.00362900821656
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.829582780788,
            21.00362900821656
          ],
          [
            105.8298992814453,
            21.00356140028385
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ nhà B3 khu TT Khương Thượng",
        "diemDau": "11-1",
        "diemCuoi": "11-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T37KT",
        "from_latitude": 21.00362900821656,
        "to_latitude": 21.00356140028385,
        "to_longitude": 105.8298992814453,
        "from_longitude": 105.829582780788,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7716,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8298992814453,
          21.003521336308857,
          105.83008770660653,
          21.00356140028385
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8298992814453,
            21.00356140028385
          ],
          [
            105.83008770660653,
            21.003521336308857
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ nhà B3 khu TT Khương Thượng",
        "diemDau": "11",
        "diemCuoi": "11-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T37KT",
        "from_latitude": 21.00356140028385,
        "to_latitude": 21.003521336308857,
        "to_longitude": 105.83008770660653,
        "from_longitude": 105.8298992814453,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7717,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82966995257556,
          21.003859375758626,
          105.82983356732865,
          21.003898187646413
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82966995257556,
            21.003898187646413
          ],
          [
            105.82983356732865,
            21.003859375758626
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ nhà B4 khu TT Khương Thượng",
        "diemDau": "10-2",
        "diemCuoi": "10-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T37KT",
        "from_latitude": 21.003898187646413,
        "to_latitude": 21.003859375758626,
        "to_longitude": 105.82983356732865,
        "from_longitude": 105.82966995257556,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7718,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82983356732865,
          21.003813051879572,
          105.83002936858365,
          21.003859375758626
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82983356732865,
            21.003859375758626
          ],
          [
            105.83002936858365,
            21.003813051879572
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ nhà B4 khu TT Khương Thượng",
        "diemDau": "10-1",
        "diemCuoi": "10-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T37KT",
        "from_latitude": 21.003859375758626,
        "to_latitude": 21.003813051879572,
        "to_longitude": 105.83002936858365,
        "from_longitude": 105.82983356732865,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7719,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83002936858365,
          21.00375702500575,
          105.83016515540675,
          21.003813051879572
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83002936858365,
            21.003813051879572
          ],
          [
            105.83016515540675,
            21.00375702500575
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 49,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ nhà B4 khu TT Khương Thượng",
        "diemDau": "10",
        "diemCuoi": "10-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T37KT",
        "from_latitude": 21.003813051879572,
        "to_latitude": 21.00375702500575,
        "to_longitude": 105.83016515540675,
        "from_longitude": 105.83002936858365,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7720,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83008401859111,
          21.004204560800527,
          105.8303197177559,
          21.00425464053849
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8303197177559,
            21.004221828316417
          ],
          [
            105.8302972542054,
            21.004204560800527
          ],
          [
            105.83008401859111,
            21.00425464053849
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 10 Tôn Thất Tùng",
        "diemDau": "Tủ điện",
        "diemCuoi": "9-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T37KT",
        "from_latitude": 21.004221828316417,
        "to_latitude": 21.00425464053849,
        "to_longitude": 105.83008401859111,
        "from_longitude": 105.8303197177559,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7721,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82984127868266,
          21.00425464053849,
          105.83008401859111,
          21.004309728230112
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83008401859111,
            21.00425464053849
          ],
          [
            105.82984127868266,
            21.004309728230112
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 10 Tôn Thất Tùng",
        "diemDau": "8-1",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T37KT",
        "from_latitude": 21.00425464053849,
        "to_latitude": 21.004309728230112,
        "to_longitude": 105.82984127868266,
        "from_longitude": 105.83008401859111,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7722,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82958009858027,
          21.004309728230112,
          105.82984127868266,
          21.004376449434975
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82984127868266,
            21.004309728230112
          ],
          [
            105.82958009858027,
            21.004376449434975
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 10 Tôn Thất Tùng",
        "diemDau": "",
        "diemCuoi": "8-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T37KT",
        "from_latitude": 21.004309728230112,
        "to_latitude": 21.004376449434975,
        "to_longitude": 105.82958009858027,
        "from_longitude": 105.82984127868266,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7723,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82941111941167,
          21.004247494182223,
          105.82958009858027,
          21.004376449434975
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82958009858027,
            21.004376449434975
          ],
          [
            105.82941111941167,
            21.004247494182223
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 10 Tôn Thất Tùng",
        "diemDau": "8-2",
        "diemCuoi": "8-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T37KT",
        "from_latitude": 21.004376449434975,
        "to_latitude": 21.004247494182223,
        "to_longitude": 105.82941111941167,
        "from_longitude": 105.82958009858027,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7724,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82911875863304,
          21.004247494182223,
          105.82941111941167,
          21.004378953419
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82941111941167,
            21.004247494182223
          ],
          [
            105.82911875863304,
            21.004378953419
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 10 Tôn Thất Tùng",
        "diemDau": "8-3",
        "diemCuoi": "8-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T37KT",
        "from_latitude": 21.004247494182223,
        "to_latitude": 21.004378953419,
        "to_longitude": 105.82911875863304,
        "from_longitude": 105.82941111941167,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7725,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83048534411542,
          21.005450654878693,
          105.83067309874717,
          21.00551200204267
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83067309874717,
            21.005450654878693
          ],
          [
            105.83048534411542,
            21.00551200204267
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Tôn Thất Tùng",
        "diemDau": "3-1",
        "diemCuoi": "3-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T37KT",
        "from_latitude": 21.005450654878693,
        "to_latitude": 21.00551200204267,
        "to_longitude": 105.83048534411542,
        "from_longitude": 105.83067309874717,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7726,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83017957228908,
          21.00551200204267,
          105.83048534411542,
          21.005603396750328
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83048534411542,
            21.00551200204267
          ],
          [
            105.83017957228908,
            21.005603396750328
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 6 Tôn Thất Tùng",
        "diemDau": "3-2",
        "diemCuoi": "3-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T37KT",
        "from_latitude": 21.00551200204267,
        "to_latitude": 21.005603396750328,
        "to_longitude": 105.83017957228908,
        "from_longitude": 105.83048534411542,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7727,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82891730331446,
          21.004378953419,
          105.82911875863304,
          21.004459730669797
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82911875863304,
            21.004378953419
          ],
          [
            105.82891730331446,
            21.004459730669797
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 10 Tôn Thất Tùng",
        "diemDau": "8-4",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T37KT",
        "from_latitude": 21.004378953419,
        "to_latitude": 21.004459730669797,
        "to_longitude": 105.82891730331446,
        "from_longitude": 105.82911875863304,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7728,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82870972175476,
          21.004459730669797,
          105.82891730331446,
          21.004542964305
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82891730331446,
            21.004459730669797
          ],
          [
            105.82870972175476,
            21.004542964305
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "T37KT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 10 Tôn Thất Tùng",
        "diemDau": "",
        "diemCuoi": "8-5",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "T37KT",
        "from_latitude": 21.004459730669797,
        "to_latitude": 21.004542964305,
        "to_longitude": 105.82870972175476,
        "from_longitude": 105.82891730331446,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7729,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80675768487492,
          21.02340317003547,
          105.80680076795916,
          21.023620832237555
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80675768487492,
            21.02340317003547
          ],
          [
            105.80680076795916,
            21.023620832237555
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "41",
        "diemCuoi": "01",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02340317003547,
        "to_latitude": 21.023620832237555,
        "to_longitude": 105.80680076795916,
        "from_longitude": 105.80675768487492,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7730,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80680076795916,
          21.023620832237555,
          105.80681954342234,
          21.023888723760834
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80680076795916,
            21.023620832237555
          ],
          [
            105.80681954342234,
            21.023888723760834
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023620832237555,
        "to_latitude": 21.023888723760834,
        "to_longitude": 105.80681954342234,
        "from_longitude": 105.80680076795916,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7731,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80681954342234,
          21.023888723760834,
          105.80683429557389,
          21.02416037028191
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80681954342234,
            21.023888723760834
          ],
          [
            105.80683429557389,
            21.02416037028191
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023888723760834,
        "to_latitude": 21.02416037028191,
        "to_longitude": 105.80683429557389,
        "from_longitude": 105.80681954342234,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7732,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80683429557389,
          21.02416037028191,
          105.8068477066126,
          21.02442074989229
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80683429557389,
            21.02416037028191
          ],
          [
            105.8068477066126,
            21.02442074989229
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02416037028191,
        "to_latitude": 21.02442074989229,
        "to_longitude": 105.8068477066126,
        "from_longitude": 105.80683429557389,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7733,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8068477066126,
          21.02442074989229,
          105.80685709434869,
          21.024679877226642
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8068477066126,
            21.02442074989229
          ],
          [
            105.80685709434869,
            21.024679877226642
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02442074989229,
        "to_latitude": 21.024679877226642,
        "to_longitude": 105.80685709434869,
        "from_longitude": 105.8068477066126,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7734,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80626566726309,
          21.02357826998775,
          105.80630053597272,
          21.023843657931113
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80626566726309,
            21.02357826998775
          ],
          [
            105.80630053597272,
            21.023843657931113
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02357826998775,
        "to_latitude": 21.023843657931113,
        "to_longitude": 105.80630053597272,
        "from_longitude": 105.80626566726309,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7735,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80630053597272,
          21.023843657931113,
          105.80633138137972,
          21.024099030787962
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80630053597272,
            21.023843657931113
          ],
          [
            105.80633138137972,
            21.024099030787962
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023843657931113,
        "to_latitude": 21.024099030787962,
        "to_longitude": 105.80633138137972,
        "from_longitude": 105.80630053597272,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7736,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80633138137972,
          21.024099030787962,
          105.80634211021967,
          21.024370676925816
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80633138137972,
            21.024099030787962
          ],
          [
            105.80634211021967,
            21.024370676925816
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.024099030787962,
        "to_latitude": 21.024370676925816,
        "to_longitude": 105.80634211021967,
        "from_longitude": 105.80633138137972,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7737,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80634211021967,
          21.024370676925816,
          105.80634613353129,
          21.024632307991286
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80634211021967,
            21.024370676925816
          ],
          [
            105.80634613353129,
            21.024632307991286
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.024370676925816,
        "to_latitude": 21.024632307991286,
        "to_longitude": 105.80634613353129,
        "from_longitude": 105.80634211021967,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7738,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80613558012472,
          21.024632307991286,
          105.80635049212113,
          21.024714928232548
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80634613353129,
            21.024632307991286
          ],
          [
            105.80635049212113,
            21.024681442004383
          ],
          [
            105.80630623567541,
            21.02471273754471
          ],
          [
            105.80613558012472,
            21.024714928232548
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.024632307991286,
        "to_latitude": 21.024714928232548,
        "to_longitude": 105.80613558012472,
        "from_longitude": 105.80634613353129,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7739,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80591731536417,
          21.024714928232548,
          105.80613558012472,
          21.024722752115025
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80613558012472,
            21.024714928232548
          ],
          [
            105.80591731536417,
            21.024722752115025
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.024714928232548,
        "to_latitude": 21.024722752115025,
        "to_longitude": 105.80591731536417,
        "from_longitude": 105.80613558012472,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7740,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80591731536417,
          21.024722752115025,
          105.8059236856143,
          21.025011609629964
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80591731536417,
            21.024722752115025
          ],
          [
            105.8059236856143,
            21.025011609629964
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "19",
        "diemCuoi": "20",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.024722752115025,
        "to_latitude": 21.025011609629964,
        "to_longitude": 105.8059236856143,
        "from_longitude": 105.80591731536417,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7741,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8059236856143,
          21.025011609629964,
          105.8059907408348,
          21.02551233638712
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8059236856143,
            21.025011609629964
          ],
          [
            105.8059907408348,
            21.02551233638712
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 60,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "17",
        "diemCuoi": "19",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.025011609629964,
        "to_latitude": 21.02551233638712,
        "to_longitude": 105.8059907408348,
        "from_longitude": 105.8059236856143,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7742,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8059907408348,
          21.02551233638712,
          105.80602292734567,
          21.025760195509427
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8059907408348,
            21.02551233638712
          ],
          [
            105.80602292734567,
            21.025760195509427
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02551233638712,
        "to_latitude": 21.025760195509427,
        "to_longitude": 105.80602292734567,
        "from_longitude": 105.8059907408348,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7743,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80602292734567,
          21.025760195509427,
          105.80607925373519,
          21.02599303249178
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80602292734567,
            21.025760195509427
          ],
          [
            105.80607925373519,
            21.02599303249178
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.025760195509427,
        "to_latitude": 21.02599303249178,
        "to_longitude": 105.80607925373519,
        "from_longitude": 105.80602292734567,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7744,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80607925373519,
          21.02599303249178,
          105.806163743315,
          21.02608191102858
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80607925373519,
            21.02599303249178
          ],
          [
            105.80611135643325,
            21.026058517826275
          ],
          [
            105.806163743315,
            21.02608191102858
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02599303249178,
        "to_latitude": 21.02608191102858,
        "to_longitude": 105.806163743315,
        "from_longitude": 105.80607925373519,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7745,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.806163743315,
          21.026078572868713,
          105.80636580306071,
          21.02608191102858
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.806163743315,
            21.02608191102858
          ],
          [
            105.80636580306071,
            21.026078572868713
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02608191102858,
        "to_latitude": 21.026078572868713,
        "to_longitude": 105.80636580306071,
        "from_longitude": 105.806163743315,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7746,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80636580306071,
          21.02607523470875,
          105.80656786280645,
          21.026078572868713
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80636580306071,
            21.026078572868713
          ],
          [
            105.80656786280645,
            21.02607523470875
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.026078572868713,
        "to_latitude": 21.02607523470875,
        "to_longitude": 105.80656786280645,
        "from_longitude": 105.80636580306071,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7747,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80656786280645,
          21.02607189654875,
          105.80676992255216,
          21.02607523470875
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80656786280645,
            21.02607523470875
          ],
          [
            105.80676992255216,
            21.02607189654875
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02607523470875,
        "to_latitude": 21.02607189654875,
        "to_longitude": 105.80676992255216,
        "from_longitude": 105.80656786280645,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7748,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80676992255216,
          21.025994284302786,
          105.80688659864286,
          21.026073461311018
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80676992255216,
            21.02607189654875
          ],
          [
            105.80683463085211,
            21.026073461311018
          ],
          [
            105.80686949956173,
            21.0260584395889
          ],
          [
            105.80688659864286,
            21.025994284302786
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02607189654875,
        "to_latitude": 21.025994284302786,
        "to_longitude": 105.80688659864286,
        "from_longitude": 105.80676992255216,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7749,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80688257533123,
          21.0257376628796,
          105.80688659864286,
          21.025994284302786
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80688659864286,
            21.025994284302786
          ],
          [
            105.80688257533123,
            21.0257376628796
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.025994284302786,
        "to_latitude": 21.0257376628796,
        "to_longitude": 105.80688257533123,
        "from_longitude": 105.80688659864286,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7750,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80686782317966,
          21.02546601923282,
          105.80688257533123,
          21.0257376628796
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80688257533123,
            21.0257376628796
          ],
          [
            105.80686782317966,
            21.02546601923282
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.0257376628796,
        "to_latitude": 21.02546601923282,
        "to_longitude": 105.80686782317966,
        "from_longitude": 105.80688257533123,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7751,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80686514097191,
          21.025204389630627,
          105.80686782317966,
          21.02546601923282
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80686782317966,
            21.02546601923282
          ],
          [
            105.80686514097191,
            21.025204389630627
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02546601923282,
        "to_latitude": 21.025204389630627,
        "to_longitude": 105.80686514097191,
        "from_longitude": 105.80686782317966,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7752,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80686514097191,
          21.02494401138823,
          105.80687184650026,
          21.025204389630627
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80686514097191,
            21.025204389630627
          ],
          [
            105.80687184650026,
            21.02494401138823
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.025204389630627,
        "to_latitude": 21.02494401138823,
        "to_longitude": 105.80687184650026,
        "from_longitude": 105.80686514097191,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7753,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80685709434869,
          21.024679877226642,
          105.80687184650026,
          21.02494401138823
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80685709434869,
            21.024679877226642
          ],
          [
            105.80687184650026,
            21.02494401138823
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.024679877226642,
        "to_latitude": 21.02494401138823,
        "to_longitude": 105.80687184650026,
        "from_longitude": 105.80685709434869,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7754,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80683865416373,
          21.025204389630627,
          105.80686514097191,
          21.025266041622555
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80686514097191,
            21.025204389630627
          ],
          [
            105.80684636551771,
            21.02521064871777
          ],
          [
            105.80683865416373,
            21.025266041622555
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "07",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.025204389630627,
        "to_latitude": 21.025266041622555,
        "to_longitude": 105.80683865416373,
        "from_longitude": 105.80686514097191,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7755,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80683060753151,
          21.025266041622555,
          105.80683865416373,
          21.02542251864738
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80683865416373,
            21.025266041622555
          ],
          [
            105.80683060753151,
            21.02542251864738
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.025266041622555,
        "to_latitude": 21.02542251864738,
        "to_longitude": 105.80683060753151,
        "from_longitude": 105.80683865416373,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7756,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80683060753151,
          21.02542251864738,
          105.80685072409857,
          21.025536433818953
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80683060753151,
            21.02542251864738
          ],
          [
            105.80685072409857,
            21.025536433818953
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02542251864738,
        "to_latitude": 21.025536433818953,
        "to_longitude": 105.80685072409857,
        "from_longitude": 105.80683060753151,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7757,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80685072409857,
          21.025536433818953,
          105.80685474741017,
          21.02569541418696
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80685072409857,
            21.025536433818953
          ],
          [
            105.80685474741017,
            21.02569541418696
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.025536433818953,
        "to_latitude": 21.02569541418696,
        "to_longitude": 105.80685474741017,
        "from_longitude": 105.80685072409857,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7758,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8067340480079,
          21.02569541418696,
          105.80685474741017,
          21.0260409142441
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80685474741017,
            21.02569541418696
          ],
          [
            105.80685038882933,
            21.02600680240962
          ],
          [
            105.8068074734875,
            21.02603434224147
          ],
          [
            105.8067340480079,
            21.0260409142441
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 51,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.02569541418696,
        "to_latitude": 21.0260409142441,
        "to_longitude": 105.8067340480079,
        "from_longitude": 105.80685474741017,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7759,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80659054982183,
          21.0260409142441,
          105.8067340480079,
          21.026042166054662
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8067340480079,
            21.0260409142441
          ],
          [
            105.80659054982183,
            21.026042166054662
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "33",
        "diemCuoi": "34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.0260409142441,
        "to_latitude": 21.026042166054662,
        "to_longitude": 105.80659054982183,
        "from_longitude": 105.8067340480079,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7760,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80645643937184,
          21.026042166054662,
          105.80659054982183,
          21.026047173296117
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80659054982183,
            21.026042166054662
          ],
          [
            105.80645643937184,
            21.026047173296117
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.026042166054662,
        "to_latitude": 21.026047173296117,
        "to_longitude": 105.80645643937184,
        "from_longitude": 105.80659054982183,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7761,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80626600253233,
          21.026047173296117,
          105.80645643937184,
          21.026049676916347
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80645643937184,
            21.026047173296117
          ],
          [
            105.80626600253233,
            21.026049676916347
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "35",
        "diemCuoi": "36",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.026047173296117,
        "to_latitude": 21.026049676916347,
        "to_longitude": 105.80626600253233,
        "from_longitude": 105.80645643937184,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7762,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80677294003812,
          21.023620832237555,
          105.80680076795916,
          21.023668714751846
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80680076795916,
            21.023620832237555
          ],
          [
            105.80677763390615,
            21.02363115983998
          ],
          [
            105.80677294003812,
            21.023668714751846
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 8,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "01",
        "diemCuoi": "27",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023620832237555,
        "to_latitude": 21.023668714751846,
        "to_longitude": 105.80677294003812,
        "from_longitude": 105.80680076795916,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7763,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.80677294003812,
          21.023668714751846,
          105.80677562224584,
          21.02385398551643
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.80677294003812,
            21.023668714751846
          ],
          [
            105.80677562224584,
            21.02385398551643
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ĐHNT",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "diemDau": "27",
        "diemCuoi": "28",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "from_latitude": 21.023668714751846,
        "to_latitude": 21.02385398551643,
        "to_longitude": 105.80677562224584,
        "from_longitude": 105.80677294003812,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7764,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82215239126484,
          21.016567851391745,
          105.82242061220973,
          21.016569103284414
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82215239126484,
            21.016569103284414
          ],
          [
            105.82242061220973,
            21.016567851391745
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường dạo quanh hồ Hoàng Cầu",
        "diemDau": "56",
        "diemCuoi": "57",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.016569103284414,
        "to_latitude": 21.016567851391745,
        "to_longitude": 105.82242061220973,
        "from_longitude": 105.82215239126484,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7765,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82242061220973,
          21.016567851391745,
          105.8227183374128,
          21.016570355171996
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82242061220973,
            21.016567851391745
          ],
          [
            105.8227183374128,
            21.016570355171996
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường dạo quanh hồ Hoàng Cầu",
        "diemDau": "55",
        "diemCuoi": "56",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.016567851391745,
        "to_latitude": 21.016570355171996,
        "to_longitude": 105.8227183374128,
        "from_longitude": 105.82242061220973,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7766,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8227183374128,
          21.016570355171996,
          105.82300935707465,
          21.016590385410538
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8227183374128,
            21.016570355171996
          ],
          [
            105.82287558187987,
            21.016571294089385
          ],
          [
            105.82300935707465,
            21.016590385410538
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường dạo quanh hồ Hoàng Cầu",
        "diemDau": "54",
        "diemCuoi": "55",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.016570355171996,
        "to_latitude": 21.016590385410538,
        "to_longitude": 105.82300935707465,
        "from_longitude": 105.8227183374128,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7767,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82300935707465,
          21.016590385410538,
          105.82322125156976,
          21.016683025219145
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82300935707465,
            21.016590385410538
          ],
          [
            105.8230606543039,
            21.01659883566415
          ],
          [
            105.82311027516509,
            21.016616362118015
          ],
          [
            105.82316391934687,
            21.016640148015927
          ],
          [
            105.82322125156976,
            21.016683025219145
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường dạo quanh hồ Hoàng Cầu",
        "diemDau": "53",
        "diemCuoi": "54",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.016590385410538,
        "to_latitude": 21.016683025219145,
        "to_longitude": 105.82322125156976,
        "from_longitude": 105.82300935707465,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7768,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82322125156976,
          21.016683025219145,
          105.82341168841054,
          21.016864549002563
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82322125156976,
            21.016683025219145
          ],
          [
            105.82326986660658,
            21.016711505688576
          ],
          [
            105.82331546415617,
            21.016744054790504
          ],
          [
            105.82335837949799,
            21.016785367102052
          ],
          [
            105.82339056600887,
            21.01682417562704
          ],
          [
            105.82341168841054,
            21.016864549002563
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường dạo quanh hồ Hoàng Cầu",
        "diemDau": "52",
        "diemCuoi": "53",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.016683025219145,
        "to_latitude": 21.016864549002563,
        "to_longitude": 105.82341168841054,
        "from_longitude": 105.82322125156976,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7769,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82341168841054,
          21.016864549002563,
          105.82354713996442,
          21.01711617815742
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82354713996442,
            21.01711617815742
          ],
          [
            105.82341168841054,
            21.016864549002563
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường dạo quanh hồ Hoàng Cầu",
        "diemDau": "51",
        "diemCuoi": "52",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.01711617815742,
        "to_latitude": 21.016864549002563,
        "to_longitude": 105.82341168841054,
        "from_longitude": 105.82354713996442,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7770,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82354713996442,
          21.01711617815742,
          105.82367454489504,
          21.017357791822533
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82354713996442,
            21.01711617815742
          ],
          [
            105.82367454489504,
            21.017357791822533
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường dạo quanh hồ Hoàng Cầu",
        "diemDau": "50",
        "diemCuoi": "51",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.01711617815742,
        "to_latitude": 21.017357791822533,
        "to_longitude": 105.82367454489504,
        "from_longitude": 105.82354713996442,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7771,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82367454489504,
          21.017351532406547,
          105.82373221238844,
          21.017357791822533
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82367454489504,
            21.017357791822533
          ],
          [
            105.82373221238844,
            21.017351532406547
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 6,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường dạo quanh hồ Hoàng Cầu",
        "diemDau": "36",
        "diemCuoi": "50",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.017357791822533,
        "to_latitude": 21.017351532406547,
        "to_longitude": 105.82373221238844,
        "from_longitude": 105.82367454489504,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7772,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82359541973071,
          21.017098651763124,
          105.82373221238844,
          21.017351532406547
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82373221238844,
            21.017351532406547
          ],
          [
            105.82359541973071,
            21.017098651763124
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "35",
        "diemCuoi": "36",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.017351532406547,
        "to_latitude": 21.017098651763124,
        "to_longitude": 105.82359541973071,
        "from_longitude": 105.82373221238844,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7773,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82345594485238,
          21.01684952635195,
          105.82359541973071,
          21.017098651763124
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82359541973071,
            21.017098651763124
          ],
          [
            105.82345594485238,
            21.01684952635195
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.017098651763124,
        "to_latitude": 21.01684952635195,
        "to_longitude": 105.82345594485238,
        "from_longitude": 105.82359541973071,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7774,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82325746138456,
          21.016656735548967,
          105.82345594485238,
          21.01684952635195
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82325746138456,
            21.016656735548967
          ],
          [
            105.82330741753293,
            21.01669272735679
          ],
          [
            105.82335569729025,
            21.016732787794673
          ],
          [
            105.82339458932049,
            21.016775351997747
          ],
          [
            105.82345594485238,
            21.01684952635195
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "33",
        "diemCuoi": "34",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.016656735548967,
        "to_latitude": 21.01684952635195,
        "to_longitude": 105.82345594485238,
        "from_longitude": 105.82325746138456,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7775,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82373221238844,
          21.017351532406547,
          105.82386229952681,
          21.017591893808785
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82386229952681,
            21.017591893808785
          ],
          [
            105.82373221238844,
            21.017351532406547
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "36",
        "diemCuoi": "37",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.017591893808785,
        "to_latitude": 21.017351532406547,
        "to_longitude": 105.82373221238844,
        "from_longitude": 105.82386229952681,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7776,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82337279637134,
          21.016396342379778,
          105.8235793264663,
          21.01656910328356
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82337279637134,
            21.016396342379778
          ],
          [
            105.82345929762043,
            21.01645142558719
          ],
          [
            105.82351562400997,
            21.016507760664876
          ],
          [
            105.8235793264663,
            21.01656910328356
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.016396342379778,
        "to_latitude": 21.01656910328356,
        "to_longitude": 105.8235793264663,
        "from_longitude": 105.82337279637134,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7777,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8235793264663,
          21.01656910328356,
          105.8237214835485,
          21.0167944432922
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8235793264663,
            21.01656910328356
          ],
          [
            105.82361888905295,
            21.016614171311186
          ],
          [
            105.8237214835485,
            21.0167944432922
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.01656910328356,
        "to_latitude": 21.0167944432922,
        "to_longitude": 105.8237214835485,
        "from_longitude": 105.8235793264663,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7778,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8237214835485,
          21.0167944432922,
          105.82382608969534,
          21.017014775414946
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82382608969534,
            21.017014775414946
          ],
          [
            105.8237214835485,
            21.0167944432922
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.017014775414946,
        "to_latitude": 21.0167944432922,
        "to_longitude": 105.8237214835485,
        "from_longitude": 105.82382608969534,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7779,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82382608969534,
          21.017014775414946,
          105.82393069585117,
          21.01724011475017
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82382608969534,
            21.017014775414946
          ],
          [
            105.82393069585117,
            21.01724011475017
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.017014775414946,
        "to_latitude": 21.01724011475017,
        "to_longitude": 105.82393069585117,
        "from_longitude": 105.82382608969534,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7780,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82393069585117,
          21.01724011475017,
          105.8240674885089,
          21.017492995153674
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82393069585117,
            21.01724011475017
          ],
          [
            105.8240674885089,
            21.017492995153674
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.01724011475017,
        "to_latitude": 21.017492995153674,
        "to_longitude": 105.8240674885089,
        "from_longitude": 105.82393069585117,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7781,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8240674885089,
          21.017492995153674,
          105.82421232779883,
          21.01776590520765
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8240674885089,
            21.017492995153674
          ],
          [
            105.82421232779883,
            21.01776590520765
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.017492995153674,
        "to_latitude": 21.01776590520765,
        "to_longitude": 105.82421232779883,
        "from_longitude": 105.8240674885089,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7782,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82421232779883,
          21.01776590520765,
          105.82437376324692,
          21.018059627223003
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82421232779883,
            21.01776590520765
          ],
          [
            105.82437376324692,
            21.018059627223003
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "11/30/2015 12:00:00 AM",
        "tuDien": "HN2",
        "chieuDaiQL": 11,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.01776590520765,
        "to_latitude": 21.018059627223003,
        "to_longitude": 105.82437376324692,
        "from_longitude": 105.82421232779883,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7783,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82437376324692,
          21.017964484512692,
          105.82456577345481,
          21.018059627223003
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82437376324692,
            21.018059627223003
          ],
          [
            105.82456577345481,
            21.017964484512692
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "11",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.018059627223003,
        "to_latitude": 21.017964484512692,
        "to_longitude": 105.82456577345481,
        "from_longitude": 105.82437376324692,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7784,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82442043126079,
          21.017705814963538,
          105.82456577345481,
          21.017964484512692
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82456577345481,
            21.017964484512692
          ],
          [
            105.82442043126079,
            21.017705814963538
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.017964484512692,
        "to_latitude": 21.017705814963538,
        "to_longitude": 105.82442043126079,
        "from_longitude": 105.82456577345481,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7785,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82427559197087,
          21.01743290479959,
          105.82442043126079,
          21.017705814963538
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82442043126079,
            21.017705814963538
          ],
          [
            105.82427559197087,
            21.01743290479959
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.017705814963538,
        "to_latitude": 21.01743290479959,
        "to_longitude": 105.82427559197087,
        "from_longitude": 105.82442043126079,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7786,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82413879931315,
          21.0171800242942,
          105.82427559197087,
          21.01743290479959
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82427559197087,
            21.01743290479959
          ],
          [
            105.82413879931315,
            21.0171800242942
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.01743290479959,
        "to_latitude": 21.0171800242942,
        "to_longitude": 105.82413879931315,
        "from_longitude": 105.82427559197087,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7787,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82400043327944,
          21.016924639586495,
          105.82413879931315,
          21.0171800242942
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82413879931315,
            21.0171800242942
          ],
          [
            105.82400043327944,
            21.016924639586495
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.0171800242942,
        "to_latitude": 21.016924639586495,
        "to_longitude": 105.82400043327944,
        "from_longitude": 105.82413879931315,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7788,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82383815963915,
          21.016680521442098,
          105.82400043327944,
          21.016924639586495
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82400043327944,
            21.016924639586495
          ],
          [
            105.82383815963915,
            21.016680521442098
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.016924639586495,
        "to_latitude": 21.016680521442098,
        "to_longitude": 105.82383815963915,
        "from_longitude": 105.82400043327944,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7789,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82366381605507,
          21.016407609401842,
          105.82383815963915,
          21.016680521442098
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82383815963915,
            21.016680521442098
          ],
          [
            105.82366381605507,
            21.016407609401842
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.016680521442098,
        "to_latitude": 21.016407609401842,
        "to_longitude": 105.82366381605507,
        "from_longitude": 105.82383815963915,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7790,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82351897676514,
          21.016162238517573,
          105.82366381605507,
          21.016407609401842
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82366381605507,
            21.016407609401842
          ],
          [
            105.82351897676514,
            21.016162238517573
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.016407609401842,
        "to_latitude": 21.016162238517573,
        "to_longitude": 105.82351897676514,
        "from_longitude": 105.82366381605507,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7791,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82386229952681,
          21.017591893808785,
          105.8239923866562,
          21.01794116828123
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82386229952681,
            21.017591893808785
          ],
          [
            105.8239923866562,
            21.01794116828123
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 58,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "37",
        "diemCuoi": "38",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.017591893808785,
        "to_latitude": 21.01794116828123,
        "to_longitude": 105.8239923866562,
        "from_longitude": 105.82386229952681,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7792,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8239923866562,
          21.01794116828123,
          105.82416404803253,
          21.01818403248537
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8239923866562,
            21.01794116828123
          ],
          [
            105.82416404803253,
            21.01818403248537
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "39",
        "diemCuoi": "38",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.01794116828123,
        "to_latitude": 21.01818403248537,
        "to_longitude": 105.82416404803253,
        "from_longitude": 105.8239923866562,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7793,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82416404803253,
          21.01818403248537,
          105.82430352289528,
          21.018461948769296
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82416404803253,
            21.01818403248537
          ],
          [
            105.82430352289528,
            21.018461948769296
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "40",
        "diemCuoi": "39",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.01818403248537,
        "to_latitude": 21.018461948769296,
        "to_longitude": 105.82430352289528,
        "from_longitude": 105.82416404803253,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7794,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82430352289528,
          21.018461948769296,
          105.82445104439547,
          21.018737360792716
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82430352289528,
            21.018461948769296
          ],
          [
            105.82445104439547,
            21.018737360792716
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "41",
        "diemCuoi": "40",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.018461948769296,
        "to_latitude": 21.018737360792716,
        "to_longitude": 105.82445104439547,
        "from_longitude": 105.82430352289528,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7795,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82445104439547,
          21.018737360792716,
          105.82458783705319,
          21.018992742396765
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82445104439547,
            21.018737360792716
          ],
          [
            105.82458783705319,
            21.018992742396765
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "42",
        "diemCuoi": "41",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.018737360792716,
        "to_latitude": 21.018992742396765,
        "to_longitude": 105.82458783705319,
        "from_longitude": 105.82445104439547,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7796,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82437376324692,
          21.018059627223003,
          105.82455296834104,
          21.018394347559553
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82437376324692,
            21.018059627223003
          ],
          [
            105.82455296834104,
            21.018394347559553
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.018059627223003,
        "to_latitude": 21.018394347559553,
        "to_longitude": 105.82455296834104,
        "from_longitude": 105.82437376324692,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7797,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82455296834104,
          21.018394347559553,
          105.824703172049,
          21.018664752218598
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82455296834104,
            21.018394347559553
          ],
          [
            105.824703172049,
            21.018664752218598
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "9",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.018394347559553,
        "to_latitude": 21.018664752218598,
        "to_longitude": 105.824703172049,
        "from_longitude": 105.82455296834104,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7798,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.824703172049,
          21.018664752218598,
          105.82482420672275,
          21.018884768610228
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.824703172049,
            21.018664752218598
          ],
          [
            105.82482420672275,
            21.018884768610228
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "8",
        "diemCuoi": "9",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.018664752218598,
        "to_latitude": 21.018884768610228,
        "to_longitude": 105.82482420672275,
        "from_longitude": 105.824703172049,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7799,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82458783705319,
          21.018992742396765,
          105.82473535855108,
          21.01936329531799
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82458783705319,
            21.018992742396765
          ],
          [
            105.82473535855108,
            21.01936329531799
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "44",
        "diemCuoi": "43",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.018992742396765,
        "to_latitude": 21.01936329531799,
        "to_longitude": 105.82473535855108,
        "from_longitude": 105.82458783705319,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7800,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82482420672275,
          21.018884768610228,
          105.8250491770096,
          21.019218078743158
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82482420672275,
            21.018884768610228
          ],
          [
            105.8250491770096,
            21.019218078743158
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "7",
        "diemCuoi": "8",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.018884768610228,
        "to_latitude": 21.019218078743158,
        "to_longitude": 105.8250491770096,
        "from_longitude": 105.82482420672275,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7801,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8250491770096,
          21.019218078743158,
          105.82515914754498,
          21.019438407285694
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8250491770096,
            21.019218078743158
          ],
          [
            105.82515914754498,
            21.019438407285694
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "Tủ điện",
        "diemCuoi": "7",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.019218078743158,
        "to_latitude": 21.019438407285694,
        "to_longitude": 105.82515914754498,
        "from_longitude": 105.8250491770096,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7802,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82473535855108,
          21.01936329531799,
          105.82485820372317,
          21.019582121408316
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82473535855108,
            21.01936329531799
          ],
          [
            105.82485820372317,
            21.019582121408316
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "43",
        "diemCuoi": "44",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.01936329531799,
        "to_latitude": 21.019582121408316,
        "to_longitude": 105.82485820372317,
        "from_longitude": 105.82473535855108,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7803,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82485820372317,
          21.019582121408316,
          105.82498104889524,
          21.019800947177504
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82485820372317,
            21.019582121408316
          ],
          [
            105.82498104889524,
            21.019800947177504
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "44",
        "diemCuoi": "45",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.019582121408316,
        "to_latitude": 21.019800947177504,
        "to_longitude": 105.82498104889524,
        "from_longitude": 105.82485820372317,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7804,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82498104889524,
          21.019800947177504,
          105.82510389406731,
          21.02001977262556
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82498104889524,
            21.019800947177504
          ],
          [
            105.82510389406731,
            21.02001977262556
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "45",
        "diemCuoi": "46",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.019800947177504,
        "to_latitude": 21.02001977262556,
        "to_longitude": 105.82510389406731,
        "from_longitude": 105.82498104889524,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7805,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82510389406731,
          21.02001977262556,
          105.82522673923938,
          21.020238597752456
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82510389406731,
            21.02001977262556
          ],
          [
            105.82522673923938,
            21.020238597752456
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "46",
        "diemCuoi": "47",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.02001977262556,
        "to_latitude": 21.020238597752456,
        "to_longitude": 105.82522673923938,
        "from_longitude": 105.82510389406731,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7806,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82514841874097,
          21.019438407285694,
          105.82515914754498,
          21.019468452061687
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82515914754498,
            21.019438407285694
          ],
          [
            105.82514841874097,
            21.019468452061687
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.019438407285694,
        "to_latitude": 21.019468452061687,
        "to_longitude": 105.82514841874097,
        "from_longitude": 105.82515914754498,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7807,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82515914754498,
          21.019438407285694,
          105.82525855694466,
          21.019667342065855
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82515914754498,
            21.019438407285694
          ],
          [
            105.82525855694466,
            21.019667342065855
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "Tủ điện",
        "diemCuoi": "2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.019438407285694,
        "to_latitude": 21.019667342065855,
        "to_longitude": 105.82525855694466,
        "from_longitude": 105.82515914754498,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7808,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82525855694466,
          21.019667342065855,
          105.82539618779458,
          21.019920687801456
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82525855694466,
            21.019667342065855
          ],
          [
            105.82539618779458,
            21.019920687801456
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "2",
        "diemCuoi": "3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.019667342065855,
        "to_latitude": 21.019920687801456,
        "to_longitude": 105.82539618779458,
        "from_longitude": 105.82525855694466,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7809,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82539618779458,
          21.019920687801456,
          105.82552661021225,
          21.020182013712425
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82539618779458,
            21.019920687801456
          ],
          [
            105.82552661021225,
            21.020182013712425
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "3",
        "diemCuoi": "4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.019920687801456,
        "to_latitude": 21.020182013712425,
        "to_longitude": 105.82552661021225,
        "from_longitude": 105.82539618779458,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7810,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82552661021225,
          21.020182013712425,
          105.82563121635908,
          21.020379807388984
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82552661021225,
            21.020182013712425
          ],
          [
            105.82563121635908,
            21.020379807388984
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "4",
        "diemCuoi": "5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.020182013712425,
        "to_latitude": 21.020379807388984,
        "to_longitude": 105.82563121635908,
        "from_longitude": 105.82552661021225,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7811,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82563121635908,
          21.020379807388984,
          105.8256888838615,
          21.020487467128888
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82563121635908,
            21.020379807388984
          ],
          [
            105.8256888838615,
            21.020487467128888
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "5",
        "diemCuoi": "6",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.020379807388984,
        "to_latitude": 21.020487467128888,
        "to_longitude": 105.8256888838615,
        "from_longitude": 105.82563121635908,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7812,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82534958441146,
          21.02045742255822,
          105.8254099341216,
          21.020587615654343
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8254099341216,
            21.020587615654343
          ],
          [
            105.82534958441146,
            21.02045742255822
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "48",
        "diemCuoi": "49",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.020587615654343,
        "to_latitude": 21.02045742255822,
        "to_longitude": 105.82534958441146,
        "from_longitude": 105.8254099341216,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7813,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82522673923938,
          21.020238597752456,
          105.82534958441146,
          21.02045742255822
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82534958441146,
            21.02045742255822
          ],
          [
            105.82522673923938,
            21.020238597752456
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "47",
        "diemCuoi": "48",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.02045742255822,
        "to_latitude": 21.020238597752456,
        "to_longitude": 105.82522673923938,
        "from_longitude": 105.82534958441146,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7814,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82485820372317,
          21.019438407285694,
          105.82515914754498,
          21.019593638564146
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82515914754498,
            21.019438407285694
          ],
          [
            105.82490567881456,
            21.019593638564146
          ],
          [
            105.82485820372317,
            21.019582121408316
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Hoàng Cầu",
        "diemDau": "Tủ điện",
        "diemCuoi": "44",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.019438407285694,
        "to_latitude": 21.019582121408316,
        "to_longitude": 105.82485820372317,
        "from_longitude": 105.82515914754498,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7815,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83546855316642,
          21.011203093755782,
          105.83553493784846,
          21.011293859014618
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83546855316642,
            21.011293859014618
          ],
          [
            105.83553493784846,
            21.011203093755782
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HĐA",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 46 Phạm Ngọc Thạch",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trạm Hồ Đấu",
        "from_latitude": 21.011293859014618,
        "to_latitude": 21.011203093755782,
        "to_longitude": 105.83553493784846,
        "from_longitude": 105.83546855316642,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7816,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82974706607958,
          21.008570457075933,
          105.83003138019005,
          21.008793412557498
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82974706607958,
            21.008793412557498
          ],
          [
            105.83003138019005,
            21.008570457075933
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.008793412557498,
        "to_latitude": 21.008570457075933,
        "to_longitude": 105.83003138019005,
        "from_longitude": 105.82974706607958,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7817,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8292025776654,
          21.008390282457146,
          105.8292253764402,
          21.008510470427144
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8292253764402,
            21.008390282457146
          ],
          [
            105.8292025776654,
            21.008510470427144
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 15,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "diemDau": "33",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.008390282457146,
        "to_latitude": 21.008510470427144,
        "to_longitude": 105.8292025776654,
        "from_longitude": 105.8292253764402,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7818,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82905907947934,
          21.008390282457146,
          105.8292253764402,
          21.008469155823676
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8292253764402,
            21.008390282457146
          ],
          [
            105.82905907947934,
            21.008469155823676
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "diemDau": "33",
        "diemCuoi": "34",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.008390282457146,
        "to_latitude": 21.008469155823676,
        "to_longitude": 105.82905907947934,
        "from_longitude": 105.8292253764402,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7819,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82896386105958,
          21.00824380323853,
          105.82915027458749,
          21.008310157090293
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82896386105958,
            21.008310157090293
          ],
          [
            105.82915027458749,
            21.00824380323853
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.008310157090293,
        "to_latitude": 21.00824380323853,
        "to_longitude": 105.82915027458749,
        "from_longitude": 105.82896386105958,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7820,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82915027458749,
          21.008184961117887,
          105.8293138893406,
          21.00824380323853
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82915027458749,
            21.00824380323853
          ],
          [
            105.8293138893406,
            21.008184961117887
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.00824380323853,
        "to_latitude": 21.008184961117887,
        "to_longitude": 105.8293138893406,
        "from_longitude": 105.82915027458749,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7821,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8293138893406,
          21.008184961117887,
          105.82949225623628,
          21.008515478257245
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8293138893406,
            21.008184961117887
          ],
          [
            105.82949225623628,
            21.008515478257245
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.008184961117887,
        "to_latitude": 21.008515478257245,
        "to_longitude": 105.82949225623628,
        "from_longitude": 105.8293138893406,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7822,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82915027458749,
          21.00824380323853,
          105.8292253764402,
          21.008390282457146
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82915027458749,
            21.00824380323853
          ],
          [
            105.8292253764402,
            21.008390282457146
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "diemDau": "31",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.00824380323853,
        "to_latitude": 21.008390282457146,
        "to_longitude": 105.8292253764402,
        "from_longitude": 105.82915027458749,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7823,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82949225623628,
          21.008515478257245,
          105.82974706607958,
          21.008793412557498
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82974706607958,
            21.008793412557498
          ],
          [
            105.82949225623628,
            21.008515478257245
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "diemDau": "28",
        "diemCuoi": "29",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.008793412557498,
        "to_latitude": 21.008515478257245,
        "to_longitude": 105.82949225623628,
        "from_longitude": 105.82974706607958,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7824,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83163746071335,
          21.013393751445225,
          105.83181985092064,
          21.013621600080477
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83163746071335,
            21.013621600080477
          ],
          [
            105.83181985092064,
            21.013393751445225
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "32",
        "diemCuoi": "33",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.013621600080477,
        "to_latitude": 21.013393751445225,
        "to_longitude": 105.83181985092064,
        "from_longitude": 105.83163746071335,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7825,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83181985092064,
          21.01312834490315,
          105.83206393194196,
          21.01339375144524
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83181985092064,
            21.01339375144524
          ],
          [
            105.83206393194196,
            21.01312834490315
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.01339375144524,
        "to_latitude": 21.01312834490315,
        "to_longitude": 105.83206393194196,
        "from_longitude": 105.83181985092064,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7826,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83206393194196,
          21.012862937889672,
          105.83230801296325,
          21.01312834490315
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83206393194196,
            21.01312834490315
          ],
          [
            105.83230801296325,
            21.012862937889672
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.01312834490315,
        "to_latitude": 21.012862937889672,
        "to_longitude": 105.83230801296325,
        "from_longitude": 105.83206393194196,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7827,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83230801296325,
          21.0125328484115,
          105.83263044059699,
          21.012862937889672
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83230801296325,
            21.012862937889672
          ],
          [
            105.83263044059699,
            21.0125328484115
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "06",
        "diemCuoi": "30",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.012862937889672,
        "to_latitude": 21.0125328484115,
        "to_longitude": 105.83263044059699,
        "from_longitude": 105.83230801296325,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7828,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83230801296325,
          21.01284791483746,
          105.83300538730856,
          21.013357445860926
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83300538730856,
            21.013357445860926
          ],
          [
            105.83241530132683,
            21.01284791483746
          ],
          [
            105.83230801296325,
            21.012862937889672
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": 90,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "30",
        "diemCuoi": "35",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.013357445860926,
        "to_latitude": 21.012862937889672,
        "to_longitude": 105.83230801296325,
        "from_longitude": 105.83300538730856,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7829,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8329048044733,
          21.013357445860926,
          105.83300538730856,
          21.013458851090096
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83300538730856,
            21.013357445860926
          ],
          [
            105.8329048044733,
            21.013458851090096
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.013357445860926,
        "to_latitude": 21.013458851090096,
        "to_longitude": 105.8329048044733,
        "from_longitude": 105.83300538730856,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7830,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83271302652993,
          21.013458851090096,
          105.8329048044733,
          21.01362660773818
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8329048044733,
            21.013458851090096
          ],
          [
            105.83271302652993,
            21.01362660773818
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.013458851090096,
        "to_latitude": 21.01362660773818,
        "to_longitude": 105.83271302652993,
        "from_longitude": 105.8329048044733,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7831,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83269827437836,
          21.01362660773818,
          105.83276130628725,
          21.013736776181055
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83271302652993,
            21.01362660773818
          ],
          [
            105.83276130628725,
            21.01366416517063
          ],
          [
            105.83269827437836,
            21.013736776181055
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.01362660773818,
        "to_latitude": 21.013736776181055,
        "to_longitude": 105.83269827437836,
        "from_longitude": 105.83271302652993,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7832,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8329048044733,
          21.013458851090096,
          105.83310328793604,
          21.013627859653
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8329048044733,
            21.013458851090096
          ],
          [
            105.83310328793604,
            21.013627859653
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "TT2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Trung Tự 2",
        "from_latitude": 21.013458851090096,
        "to_latitude": 21.013627859653,
        "to_longitude": 105.83310328793604,
        "from_longitude": 105.8329048044733,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7833,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82477190368772,
          21.01969518684005,
          105.82498104889524,
          21.019800947177504
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82498104889524,
            21.019800947177504
          ],
          [
            105.82489451418878,
            21.01969518684005
          ],
          [
            105.82477190368772,
            21.01976858652347
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 26 Hoàng Cầu",
        "diemDau": "45",
        "diemCuoi": "45-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.019800947177504,
        "to_latitude": 21.01976858652347,
        "to_longitude": 105.82477190368772,
        "from_longitude": 105.82498104889524,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7834,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82475312822451,
          21.01976858652347,
          105.82480543129346,
          21.0198411945609
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82477190368772,
            21.01976858652347
          ],
          [
            105.82475312822451,
            21.019781105153072
          ],
          [
            105.82480543129346,
            21.0198411945609
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "HN2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 26 Hoàng Cầu",
        "diemDau": "45-1",
        "diemCuoi": "45-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "HN2",
        "from_latitude": 21.01976858652347,
        "to_latitude": 21.0198411945609,
        "to_longitude": 105.82480543129346,
        "from_longitude": 105.82477190368772,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7835,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83571095783259,
          21.00651608876818,
          105.83605025727366,
          21.006816562285984
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83571095783259,
            21.00651608876818
          ],
          [
            105.83605025727366,
            21.006816562285984
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 50,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 9 Lương Đình Của",
        "diemDau": "B8AKL/21",
        "diemCuoi": "B8AKL/22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00651608876818,
        "to_latitude": 21.006816562285984,
        "to_longitude": 105.83605025727366,
        "from_longitude": 105.83571095783259,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7836,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83643783647204,
          21.00711828716817,
          105.83654244262789,
          21.007240980098835
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83643783647204,
            21.00711828716817
          ],
          [
            105.83654244262789,
            21.007240980098835
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 9 LƯơng ĐÌnh Của",
        "diemDau": "B8AKL/24",
        "diemCuoi": "B8AKL/23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00711828716817,
        "to_latitude": 21.007240980098835,
        "to_longitude": 105.83654244262789,
        "from_longitude": 105.83643783647204,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7837,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83654244262789,
          21.007240980098835,
          105.83674092609061,
          21.007418759473264
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83654244262789,
            21.007240980098835
          ],
          [
            105.83674092609061,
            21.007418759473264
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 9 Lương ĐÌnh Của",
        "diemDau": "B8AKL/24",
        "diemCuoi": "B8AKL/24-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.007240980098835,
        "to_latitude": 21.007418759473264,
        "to_longitude": 105.83674092609061,
        "from_longitude": 105.83654244262789,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7838,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83674092609061,
          21.007418759473264,
          105.83693538625072,
          21.00764286133954
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83674092609061,
            21.007418759473264
          ],
          [
            105.83693538625072,
            21.00764286133954
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 9 Lương Đình Của",
        "diemDau": "B8AKL/24-1",
        "diemCuoi": "B8AKL/24-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.007418759473264,
        "to_latitude": 21.00764286133954,
        "to_longitude": 105.83693538625072,
        "from_longitude": 105.83674092609061,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7839,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83693538625072,
          21.00764286133954,
          105.8370748611162,
          21.00775804205711
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83693538625072,
            21.00764286133954
          ],
          [
            105.8370748611162,
            21.00775804205711
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 9 Lương Đình Của",
        "diemDau": "B8AKL/24-2",
        "diemCuoi": "B8AKL/24-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00764286133954,
        "to_latitude": 21.00775804205711,
        "to_longitude": 105.8370748611162,
        "from_longitude": 105.83693538625072,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7840,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83605025727366,
          21.006816562285984,
          105.8362085966523,
          21.006909208169123
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83605025727366,
            21.006816562285984
          ],
          [
            105.8362085966523,
            21.006909208169123
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 17,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 9 Lương ĐÌnh Của",
        "diemDau": "B8AKL/20",
        "diemCuoi": "B8AKL/21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.006816562285984,
        "to_latitude": 21.006909208169123,
        "to_longitude": 105.8362085966523,
        "from_longitude": 105.83605025727366,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7841,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8362085966523,
          21.006909208169123,
          105.83630909046448,
          21.007132058829715
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8362085966523,
            21.006909208169123
          ],
          [
            105.83630909046448,
            21.007132058829715
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 9 Lương ĐÌnh Của",
        "diemDau": "B8AKL/20",
        "diemCuoi": "B8AKL/21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.006909208169123,
        "to_latitude": 21.007132058829715,
        "to_longitude": 105.83630909046448,
        "from_longitude": 105.8362085966523,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7842,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83630909046448,
          21.007132058829715,
          105.83654244262789,
          21.007240980098835
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83630909046448,
            21.007132058829715
          ],
          [
            105.83654244262789,
            21.007240980098835
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 9 Lương ĐÌnh Của",
        "diemDau": "B8AKL/20",
        "diemCuoi": "B8AKL/24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.007132058829715,
        "to_latitude": 21.007240980098835,
        "to_longitude": 105.83654244262789,
        "from_longitude": 105.83630909046448,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7843,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83693538625072,
          21.00762408187114,
          105.83724929375026,
          21.00764286133954
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83693538625072,
            21.00764286133954
          ],
          [
            105.83724929375026,
            21.00762408187114
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 9 Lương ĐÌnh Của",
        "diemDau": "B8AKL/24-2",
        "diemCuoi": "B8AKL/24-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00764286133954,
        "to_latitude": 21.00762408187114,
        "to_longitude": 105.83724929375026,
        "from_longitude": 105.83693538625072,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7844,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83645804208909,
          21.006953027146217,
          105.83659617585067,
          21.00700185399137
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83659617585067,
            21.00700185399137
          ],
          [
            105.83645804208909,
            21.006953027146217
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "diemDau": "B8AKL/25",
        "diemCuoi": "B8AKL/25-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00700185399137,
        "to_latitude": 21.006953027146217,
        "to_longitude": 105.83645804208909,
        "from_longitude": 105.83659617585067,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7845,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83659617585067,
          21.006854121437158,
          105.8367812482747,
          21.00700185399137
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83659617585067,
            21.00700185399137
          ],
          [
            105.8367812482747,
            21.006854121437158
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "diemDau": "B8AKL/25",
        "diemCuoi": "B8AKL/26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00700185399137,
        "to_latitude": 21.006854121437158,
        "to_longitude": 105.8367812482747,
        "from_longitude": 105.83659617585067,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7846,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8367812482747,
          21.00670388479171,
          105.83694486302781,
          21.006854121437158
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8367812482747,
            21.006854121437158
          ],
          [
            105.83694486302781,
            21.00670388479171
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "diemDau": "B8AKL/26",
        "diemCuoi": "B8AKL/27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.006854121437158,
        "to_latitude": 21.00670388479171,
        "to_longitude": 105.83694486302781,
        "from_longitude": 105.8367812482747,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7847,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83694486302781,
          21.00650732495233,
          105.8371366409712,
          21.00670388479171
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83694486302781,
            21.00670388479171
          ],
          [
            105.8371366409712,
            21.00650732495233
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "diemDau": "B8AKL/27",
        "diemCuoi": "B8AKl/28",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00670388479171,
        "to_latitude": 21.00650732495233,
        "to_longitude": 105.8371366409712,
        "from_longitude": 105.83694486302781,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7848,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83690462989371,
          21.006299497069286,
          105.8371366409712,
          21.00650732495233
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8371366409712,
            21.00650732495233
          ],
          [
            105.83690462989371,
            21.006299497069286
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "diemDau": "B8AKL/28",
        "diemCuoi": "B8AKL/29",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.00650732495233,
        "to_latitude": 21.006299497069286,
        "to_longitude": 105.83690462989371,
        "from_longitude": 105.8371366409712,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7849,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83670346421425,
          21.006124220316813,
          105.83690462989371,
          21.006299497069286
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83690462989371,
            21.006299497069286
          ],
          [
            105.83670346421425,
            21.006124220316813
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "diemDau": "B8AKL/29",
        "diemCuoi": "B8AKL/30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.006299497069286,
        "to_latitude": 21.006124220316813,
        "to_longitude": 105.83670346421425,
        "from_longitude": 105.83690462989371,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7850,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83650766295926,
          21.005971478979188,
          105.83670346421425,
          21.006124220316813
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83670346421425,
            21.006124220316813
          ],
          [
            105.83650766295926,
            21.005971478979188
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "diemDau": "B8AKL/30",
        "diemCuoi": "B8AKL/31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.006124220316813,
        "to_latitude": 21.005971478979188,
        "to_longitude": 105.83650766295926,
        "from_longitude": 105.83670346421425,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7851,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83654244262789,
          21.00700185399137,
          105.83659617585067,
          21.007240980098835
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83654244262789,
            21.007240980098835
          ],
          [
            105.83659617585067,
            21.00700185399137
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "diemDau": "B8AKL/24",
        "diemCuoi": "B8AKL/25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.007240980098835,
        "to_latitude": 21.00700185399137,
        "to_longitude": 105.83659617585067,
        "from_longitude": 105.83654244262789,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7852,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83605025727366,
          21.006641912130583,
          105.83620072883872,
          21.006816562285984
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83605025727366,
            21.006816562285984
          ],
          [
            105.83620072883872,
            21.006641912130583
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "diemDau": "B8AKL/21",
        "diemCuoi": "B8AKL/21-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.006816562285984,
        "to_latitude": 21.006641912130583,
        "to_longitude": 105.83620072883872,
        "from_longitude": 105.83605025727366,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7853,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83620072883872,
          21.006519218707336,
          105.83637373131894,
          21.006641912130583
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83620072883872,
            21.006641912130583
          ],
          [
            105.83637373131894,
            21.006519218707336
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "diemDau": "B8AKL/21-1",
        "diemCuoi": "B8AKL/21-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.006641912130583,
        "to_latitude": 21.006519218707336,
        "to_longitude": 105.83637373131894,
        "from_longitude": 105.83620072883872,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7854,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83637373131894,
          21.006332674430745,
          105.83653600495919,
          21.006519218707336
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83637373131894,
            21.006519218707336
          ],
          [
            105.83653600495919,
            21.006332674430745
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "diemDau": "B8AKL/21-2",
        "diemCuoi": "B8AKL21-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.006519218707336,
        "to_latitude": 21.006332674430745,
        "to_longitude": 105.83653600495919,
        "from_longitude": 105.83637373131894,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7855,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83637373131894,
          21.006519218707336,
          105.83656685036618,
          21.0065492660857
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83637373131894,
            21.006519218707336
          ],
          [
            105.83656685036618,
            21.0065492660857
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "diemDau": "B8AKL/21-2",
        "diemCuoi": "B8Akl/21-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.006519218707336,
        "to_latitude": 21.0065492660857,
        "to_longitude": 105.83656685036618,
        "from_longitude": 105.83637373131894,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7856,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83656685036618,
          21.0065492660857,
          105.83660306018866,
          21.006664447646628
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83656685036618,
            21.0065492660857
          ],
          [
            105.83660306018866,
            21.006664447646628
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "B8AKL",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "diemDau": "B8AKL/21-4",
        "diemCuoi": "B8AKL/21-5",
        "chuDauTu": "SoXayDung",
        "tuDien2": "B8A Kim Liên",
        "from_latitude": 21.0065492660857,
        "to_latitude": 21.006664447646628,
        "to_longitude": 105.83660306018866,
        "from_longitude": 105.83656685036618,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7857,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83217044191532,
          21.005887596373288,
          105.83246280269397,
          21.005987754701952
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83246280269397,
            21.005987754701952
          ],
          [
            105.83229516467416,
            21.005925155754113
          ],
          [
            105.83217044191532,
            21.005887596373288
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTH3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đông Tác",
        "diemDau": "11",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KTH3",
        "from_latitude": 21.005987754701952,
        "to_latitude": 21.005887596373288,
        "to_longitude": 105.83217044191532,
        "from_longitude": 105.83246280269397,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7858,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8314757498227,
          21.005975234913812,
          105.83178420385676,
          21.00607288922723
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8314757498227,
            21.00607288922723
          ],
          [
            105.83178420385676,
            21.005975234913812
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTH3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đông Tác",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KTH3",
        "from_latitude": 21.00607288922723,
        "to_latitude": 21.005975234913812,
        "to_longitude": 105.83178420385676,
        "from_longitude": 105.8314757498227,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7859,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83178420385676,
          21.005880084495324,
          105.83217044191532,
          21.005975234913812
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83178420385676,
            21.005975234913812
          ],
          [
            105.83209534009856,
            21.005880084495324
          ],
          [
            105.83217044191532,
            21.005887596373288
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTH3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đông Tác",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KTH3",
        "from_latitude": 21.005975234913812,
        "to_latitude": 21.005887596373288,
        "to_longitude": 105.83217044191532,
        "from_longitude": 105.83178420385676,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7860,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83246280269397,
          21.005987754701952,
          105.8327497990661,
          21.00602030614385
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83246280269397,
            21.005987754701952
          ],
          [
            105.8327497990661,
            21.00602030614385
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTH3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đông Tác",
        "diemDau": "10",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KTH3",
        "from_latitude": 21.005987754701952,
        "to_latitude": 21.00602030614385,
        "to_longitude": 105.8327497990661,
        "from_longitude": 105.83246280269397,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7861,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8327497990661,
          21.00594268346292,
          105.83300997333384,
          21.00602030614385
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8327497990661,
            21.00602030614385
          ],
          [
            105.83300997333384,
            21.00594268346292
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTH3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đông Tác",
        "diemDau": "09",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KTH3",
        "from_latitude": 21.00602030614385,
        "to_latitude": 21.00594268346292,
        "to_longitude": 105.83300997333384,
        "from_longitude": 105.8327497990661,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7862,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83300997333384,
          21.005912635962414,
          105.83330233412144,
          21.00594268346292
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83300997333384,
            21.00594268346292
          ],
          [
            105.83330233412144,
            21.005912635962414
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTH3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đông Tác",
        "diemDau": "08",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KTH3",
        "from_latitude": 21.00594268346292,
        "to_latitude": 21.005912635962414,
        "to_longitude": 105.83330233412144,
        "from_longitude": 105.83300997333384,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7863,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83330233412144,
          21.00588008449615,
          105.83360810594777,
          21.005912635962414
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83330233412144,
            21.005912635962414
          ],
          [
            105.83360810594777,
            21.00588008449615
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTH3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đông Tác",
        "diemDau": "07",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KTH3",
        "from_latitude": 21.005912635962414,
        "to_latitude": 21.00588008449615,
        "to_longitude": 105.83360810594777,
        "from_longitude": 105.83330233412144,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7864,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83360810594777,
          21.00588008449615,
          105.83387900905547,
          21.005895108250883
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83360810594777,
            21.00588008449615
          ],
          [
            105.83387900905547,
            21.005895108250883
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTH3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đông Tác",
        "diemDau": "06",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KTH3",
        "from_latitude": 21.00588008449615,
        "to_latitude": 21.005895108250883,
        "to_longitude": 105.83387900905547,
        "from_longitude": 105.83360810594777,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7865,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83387900905547,
          21.005895108250883,
          105.83422769623262,
          21.005957707210502
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83387900905547,
            21.005895108250883
          ],
          [
            105.83422769623262,
            21.005957707210502
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KT3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đông Tác",
        "diemDau": "05",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Khâm Thiên 3",
        "from_latitude": 21.005895108250883,
        "to_latitude": 21.005957707210502,
        "to_longitude": 105.83422769623262,
        "from_longitude": 105.83387900905547,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7866,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83422769623262,
          21.005957707210502,
          105.83457370119302,
          21.006035329884448
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83422769623262,
            21.005957707210502
          ],
          [
            105.83457370119302,
            21.006035329884448
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTH3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đông Tác",
        "diemDau": "04",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KTH3",
        "from_latitude": 21.005957707210502,
        "to_latitude": 21.006035329884448,
        "to_longitude": 105.83457370119302,
        "from_longitude": 105.83422769623262,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7867,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83457370119302,
          21.006035329884448,
          105.83487410860387,
          21.006127976248457
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83457370119302,
            21.006035329884448
          ],
          [
            105.83487410860387,
            21.006127976248457
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTH3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đông Tác",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KTH3",
        "from_latitude": 21.006035329884448,
        "to_latitude": 21.006127976248457,
        "to_longitude": 105.83487410860387,
        "from_longitude": 105.83457370119302,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7868,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83487410860387,
          21.006127976248457,
          105.8352013381011,
          21.00620059092616
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83487410860387,
            21.006127976248457
          ],
          [
            105.8352013381011,
            21.00620059092616
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTH3",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đông Tác",
        "diemDau": "02",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KTH3",
        "from_latitude": 21.006127976248457,
        "to_latitude": 21.00620059092616,
        "to_longitude": 105.8352013381011,
        "from_longitude": 105.83487410860387,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7869,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83137650804642,
          21.00607288922723,
          105.8314757498227,
          21.006173047432362
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8314757498227,
            21.00607288922723
          ],
          [
            105.83137650804642,
            21.006173047432362
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTH3",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đông Tác",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KTH3",
        "from_latitude": 21.00607288922723,
        "to_latitude": 21.006173047432362,
        "to_longitude": 105.83137650804642,
        "from_longitude": 105.8314757498227,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7870,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83249633033564,
          21.005824997383407,
          105.8328812273263,
          21.00586130480001
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83249633033564,
            21.005824997383407
          ],
          [
            105.8328812273263,
            21.00586130480001
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "KTH3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đông Tác",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KTH3",
        "from_latitude": 21.005824997383407,
        "to_latitude": 21.00586130480001,
        "to_longitude": 105.8328812273263,
        "from_longitude": 105.83249633033564,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7871,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8327497990661,
          21.00586130480001,
          105.8328812273263,
          21.00602030614385
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8328812273263,
            21.00586130480001
          ],
          [
            105.8327497990661,
            21.00602030614385
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTH3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Đông Tác",
        "diemDau": "09",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "KTH3",
        "from_latitude": 21.00586130480001,
        "to_latitude": 21.00602030614385,
        "to_longitude": 105.8327497990661,
        "from_longitude": 105.8328812273263,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7872,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8327497990661,
          21.00602030614385,
          105.83288793285463,
          21.00617805534345
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.83287988619547,
            21.00617805534345
          ],
          [
            105.83288793285463,
            21.006039085818326
          ],
          [
            105.8327497990661,
            21.00602030614385
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 26 Đông Tác",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.00617805534345,
        "to_latitude": 21.00602030614385,
        "to_longitude": 105.8327497990661,
        "from_longitude": 105.83287988619547,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7873,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81818406303306,
          21.000498979208047,
          105.8186722250577,
          21.0020514818014
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8186722250577,
            21.000498979208047
          ],
          [
            105.81867088396282,
            21.000842033589546
          ],
          [
            105.81863869746093,
            21.00100730038244
          ],
          [
            105.8184670360846,
            21.001317801135855
          ],
          [
            105.81842948515825,
            21.001513115795213
          ],
          [
            105.81818406303306,
            21.001942556742648
          ],
          [
            105.818267211509,
            21.0020514818014
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 300,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cầu Mới",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.000498979208047,
        "to_latitude": 21.0020514818014,
        "to_longitude": 105.818267211509,
        "from_longitude": 105.8186722250577,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7874,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81811717547411,
          21.000502735281245,
          105.81865479071637,
          21.001992480737194
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81865479071637,
            21.000502735281245
          ],
          [
            105.81865076740473,
            21.000830765392738
          ],
          [
            105.81862126310162,
            21.000993528157288
          ],
          [
            105.81845228393303,
            21.0013102890287
          ],
          [
            105.81841205079894,
            21.001513115795213
          ],
          [
            105.81815053543627,
            21.00197010101651
          ],
          [
            105.81811717547411,
            21.001992480737194
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 300,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cầu Mới",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.000502735281245,
        "to_latitude": 21.001992480737194,
        "to_longitude": 105.81811717547411,
        "from_longitude": 105.81865479071637,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7875,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81808348020681,
          21.001856011344774,
          105.81812253987165,
          21.001992480737194
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81811717547411,
            21.001992480737194
          ],
          [
            105.81808348020681,
            21.00196509296667
          ],
          [
            105.81812253987165,
            21.001856011344774
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cầu Mới",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.001992480737194,
        "to_latitude": 21.001856011344774,
        "to_longitude": 105.81812253987165,
        "from_longitude": 105.81811717547411,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7876,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81792807972052,
          21.00171954182672,
          105.81812253987165,
          21.001856011344774
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81812253987165,
            21.001856011344774
          ],
          [
            105.81792807972052,
            21.00171954182672
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cầu Mới",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.001856011344774,
        "to_latitude": 21.00171954182672,
        "to_longitude": 105.81792807972052,
        "from_longitude": 105.81812253987165,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7877,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81799647607184,
          21.001992480737194,
          105.81811717547411,
          21.002252899048095
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81811717547411,
            21.001992480737194
          ],
          [
            105.81799647607184,
            21.002252899048095
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cầu Mới",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.001992480737194,
        "to_latitude": 21.002252899048095,
        "to_longitude": 105.81799647607184,
        "from_longitude": 105.81811717547411,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7878,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81787443555669,
          21.002252899048095,
          105.81799647607184,
          21.00255338115023
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81799647607184,
            21.002252899048095
          ],
          [
            105.81787443555669,
            21.00255338115023
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cầu Mới",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.002252899048095,
        "to_latitude": 21.00255338115023,
        "to_longitude": 105.81787443555669,
        "from_longitude": 105.81799647607184,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7879,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81813578326677,
          21.0020514818014,
          105.818267211509,
          21.002304387953046
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.818267211509,
            21.0020514818014
          ],
          [
            105.81813578326677,
            21.002304387953046
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cầu Mới",
        "diemDau": "01",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.0020514818014,
        "to_latitude": 21.002304387953046,
        "to_longitude": 105.81813578326677,
        "from_longitude": 105.818267211509,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7880,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81800569612838,
          21.002304387953046,
          105.81813578326677,
          21.002593601886936
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81813578326677,
            21.002304387953046
          ],
          [
            105.81800569612838,
            21.002593601886936
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cầu Mới",
        "diemDau": "02",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.002304387953046,
        "to_latitude": 21.002593601886936,
        "to_longitude": 105.81800569612838,
        "from_longitude": 105.81813578326677,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7881,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81786890347067,
          21.002593601886936,
          105.81800569612838,
          21.00290660334973
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81800569612838,
            21.002593601886936
          ],
          [
            105.81786890347067,
            21.00290660334973
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cầu Mới",
        "diemDau": "03",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.002593601886936,
        "to_latitude": 21.00290660334973,
        "to_longitude": 105.81786890347067,
        "from_longitude": 105.81800569612838,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7882,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81774015744514,
          21.00290910735422,
          105.81786622126292,
          21.00319206010694
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81786622126292,
            21.00290910735422
          ],
          [
            105.81774015744514,
            21.00319206010694
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cầu Mới",
        "diemDau": "04",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.00290910735422,
        "to_latitude": 21.00319206010694,
        "to_longitude": 105.81774015744514,
        "from_longitude": 105.81786622126292,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7883,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81761677582614,
          21.00318454809747,
          105.81774820406837,
          21.0034775163257
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81774820406837,
            21.00318454809747
          ],
          [
            105.81761677582614,
            21.0034775163257
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cầu Mới",
        "diemDau": "05",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.00318454809747,
        "to_latitude": 21.0034775163257,
        "to_longitude": 105.81761677582614,
        "from_longitude": 105.81774820406837,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7884,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81750948746254,
          21.0034775163257,
          105.81761677582614,
          21.003710388096025
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81761677582614,
            21.0034775163257
          ],
          [
            105.81750948746254,
            21.003710388096025
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cầu Mới",
        "diemDau": "06",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.0034775163257,
        "to_latitude": 21.003710388096025,
        "to_longitude": 105.81750948746254,
        "from_longitude": 105.81761677582614,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7885,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81740622242854,
          21.003710388096025,
          105.81750948746254,
          21.003963291436452
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81750948746254,
            21.003710388096025
          ],
          [
            105.81740622242854,
            21.003963291436452
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cầu Mới",
        "diemDau": "07",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.003710388096025,
        "to_latitude": 21.003963291436452,
        "to_longitude": 105.81740622242854,
        "from_longitude": 105.81750948746254,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7886,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81731636842427,
          21.003963291436452,
          105.81740622242854,
          21.004228714282284
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81740622242854,
            21.003963291436452
          ],
          [
            105.81731636842427,
            21.004228714282284
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cầu Mới",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.003963291436452,
        "to_latitude": 21.004228714282284,
        "to_longitude": 105.81731636842427,
        "from_longitude": 105.81740622242854,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7887,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81720371564522,
          21.004228714282284,
          105.81731636842427,
          21.004497892629566
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81731636842427,
            21.004228714282284
          ],
          [
            105.81720371564522,
            21.004497892629566
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cầu Mới",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.004228714282284,
        "to_latitude": 21.004497892629566,
        "to_longitude": 105.81720371564522,
        "from_longitude": 105.81731636842427,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7888,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81398791467902,
          21.00889356893218,
          105.81399579365865,
          21.00897854530592
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81398791467902,
            21.00897854530592
          ],
          [
            105.81399579365865,
            21.00889356893218
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 12,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cầu Mới",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.00897854530592,
        "to_latitude": 21.00889356893218,
        "to_longitude": 105.81399579365865,
        "from_longitude": 105.81398791467902,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7889,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.83048869687957,
          21.018798702506068,
          105.83053769184858,
          21.018934027141757
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8305304024692,
            21.018934027141757
          ],
          [
            105.83048869687957,
            21.01889572247718
          ],
          [
            105.83053769184858,
            21.018798702506068
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "KTO",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 295 Khâm Thiên",
        "diemDau": "14",
        "diemCuoi": "14-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "Khâm Thiên Ô",
        "from_latitude": 21.018934027141757,
        "to_latitude": 21.018798702506068,
        "to_longitude": 105.83053769184858,
        "from_longitude": 105.8305304024692,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7890,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81398791467902,
          21.00877072086599,
          105.81428429877823,
          21.00897854530592
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81398791467902,
            21.00897854530592
          ],
          [
            105.81428429877823,
            21.00877072086599
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.00897854530592,
        "to_latitude": 21.00877072086599,
        "to_longitude": 105.81428429877823,
        "from_longitude": 105.81398791467902,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7891,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81498971974678,
          21.007697791287992,
          105.81521100199328,
          21.007966963377164
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81521100199328,
            21.007697791287992
          ],
          [
            105.81498971974678,
            21.007966963377164
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.007697791287992,
        "to_latitude": 21.007966963377164,
        "to_longitude": 105.81498971974678,
        "from_longitude": 105.81521100199328,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7892,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81475770866928,
          21.007966963377164,
          105.81498971974678,
          21.008228623221846
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81498971974678,
            21.007966963377164
          ],
          [
            105.81475770866928,
            21.008228623221846
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.007966963377164,
        "to_latitude": 21.008228623221846,
        "to_longitude": 105.81475770866928,
        "from_longitude": 105.81498971974678,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7893,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81451496875187,
          21.008228623221846,
          105.81475770866928,
          21.008495290438425
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81475770866928,
            21.008228623221846
          ],
          [
            105.81451496875187,
            21.008495290438425
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.008228623221846,
        "to_latitude": 21.008495290438425,
        "to_longitude": 105.81451496875187,
        "from_longitude": 105.81475770866928,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7894,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81428429877823,
          21.008495290438425,
          105.81451496875187,
          21.00877072086599
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81451496875187,
            21.008495290438425
          ],
          [
            105.81428429877823,
            21.00877072086599
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.008495290438425,
        "to_latitude": 21.00877072086599,
        "to_longitude": 105.81428429877823,
        "from_longitude": 105.81451496875187,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7895,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81451496875187,
          21.008410470283483,
          105.8145535255398,
          21.008495290438425
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81451496875187,
            21.008495290438425
          ],
          [
            105.8145535255398,
            21.008410470283483
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 9,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cầu Mới",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.008495290438425,
        "to_latitude": 21.008410470283483,
        "to_longitude": 105.8145535255398,
        "from_longitude": 105.81451496875187,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7896,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81485125073391,
          21.007822048695914,
          105.81508460292424,
          21.0080799529115
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81485125073391,
            21.0080799529115
          ],
          [
            105.81508460292424,
            21.007822048695914
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cầu Mới",
        "diemDau": "23",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.0080799529115,
        "to_latitude": 21.007822048695914,
        "to_longitude": 105.81508460292424,
        "from_longitude": 105.81485125073391,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7897,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81508460292424,
          21.00757415975728,
          105.81527503976375,
          21.007822048695914
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81508460292424,
            21.007822048695914
          ],
          [
            105.81527503976375,
            21.00757415975728
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cầu Mới",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.007822048695914,
        "to_latitude": 21.00757415975728,
        "to_longitude": 105.81527503976375,
        "from_longitude": 105.81508460292424,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7898,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81711226907618,
          21.004913240127504,
          105.81726574172845,
          21.005141023465587
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81726574172845,
            21.004913240127504
          ],
          [
            105.81711226907618,
            21.005141023465587
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.004913240127504,
        "to_latitude": 21.005141023465587,
        "to_longitude": 105.81711226907618,
        "from_longitude": 105.81726574172845,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7899,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81695242618275,
          21.005141023465587,
          105.81711226907618,
          21.00536598949235
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81711226907618,
            21.005141023465587
          ],
          [
            105.81695242618275,
            21.00536598949235
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.005141023465587,
        "to_latitude": 21.00536598949235,
        "to_longitude": 105.81695242618275,
        "from_longitude": 105.81711226907618,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7900,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81679258328934,
          21.00536598949235,
          105.81695242618275,
          21.00559095517993
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81695242618275,
            21.00536598949235
          ],
          [
            105.81679258328934,
            21.00559095517993
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.00536598949235,
        "to_latitude": 21.00559095517993,
        "to_longitude": 105.81679258328934,
        "from_longitude": 105.81695242618275,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7901,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81663274039589,
          21.00559095517993,
          105.81679258328934,
          21.00581592052832
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81679258328934,
            21.00559095517993
          ],
          [
            105.81663274039589,
            21.00581592052832
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "C5TT",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "C5 Trung Tự",
        "from_latitude": 21.00559095517993,
        "to_latitude": 21.00581592052832,
        "to_longitude": 105.81663274039589,
        "from_longitude": 105.81679258328934,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7902,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81521100199328,
          21.007697791287992,
          105.81539946848582,
          21.00775976704494
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81539946848582,
            21.00775976704494
          ],
          [
            105.81521100199328,
            21.007697791287992
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "",
        "tuDien2": "CM",
        "from_latitude": 21.00775976704494,
        "to_latitude": 21.007697791287992,
        "to_longitude": 105.81521100199328,
        "from_longitude": 105.81539946848582,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7903,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8145535255398,
          21.0080799529115,
          105.81485125073391,
          21.008410470283483
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8145535255398,
            21.008410470283483
          ],
          [
            105.81485125073391,
            21.0080799529115
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cầu Mới",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.008410470283483,
        "to_latitude": 21.0080799529115,
        "to_longitude": 105.81485125073391,
        "from_longitude": 105.8145535255398,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7904,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81676517450872,
          21.004473478789233,
          105.81720371564522,
          21.00559095517993
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81720371564522,
            21.004497892629566
          ],
          [
            105.81712727272456,
            21.004473478789233
          ],
          [
            105.81700389111454,
            21.00476143653108
          ],
          [
            105.81689660275096,
            21.005056905637044
          ],
          [
            105.81680540764282,
            21.00537741383518
          ],
          [
            105.81676517450872,
            21.00553015578095
          ],
          [
            105.81679258328934,
            21.00559095517993
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CCM",
        "chieuDaiQL": 150,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Cầu Mới",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CM",
        "from_latitude": 21.004497892629566,
        "to_latitude": 21.00559095517993,
        "to_longitude": 105.81679258328934,
        "from_longitude": 105.81720371564522,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7905,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81988860684362,
          21.015257116918146,
          105.82005892713407,
          21.015461176626335
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82005892713407,
            21.015461176626335
          ],
          [
            105.81988860684362,
            21.015257116918146
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "08",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.015461176626335,
        "to_latitude": 21.015257116918146,
        "to_longitude": 105.81988860684362,
        "from_longitude": 105.82005892713407,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7906,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8197115810518,
          21.0150518050304,
          105.81988860684362,
          21.015257116918146
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81988860684362,
            21.015257116918146
          ],
          [
            105.8197115810518,
            21.0150518050304
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "07",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.015257116918146,
        "to_latitude": 21.0150518050304,
        "to_longitude": 105.8197115810518,
        "from_longitude": 105.81988860684362,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7907,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8195157797968,
          21.014836477624687,
          105.8197115810518,
          21.01505180503039
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8197115810518,
            21.01505180503039
          ],
          [
            105.8195157797968,
            21.014836477624687
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "06",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.01505180503039,
        "to_latitude": 21.014836477624687,
        "to_longitude": 105.8195157797968,
        "from_longitude": 105.8197115810518,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7908,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81934680062821,
          21.014656203278605,
          105.8195157797968,
          21.014836477624687
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8195157797968,
            21.014836477624687
          ],
          [
            105.81934680062821,
            21.014656203278605
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "05",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.014836477624687,
        "to_latitude": 21.014656203278605,
        "to_longitude": 105.81934680062821,
        "from_longitude": 105.8195157797968,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7909,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81912283617395,
          21.01441333332931,
          105.81934680062821,
          21.014656203278605
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81934680062821,
            21.014656203278605
          ],
          [
            105.81912283617395,
            21.01441333332931
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "04",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.014656203278605,
        "to_latitude": 21.01441333332931,
        "to_longitude": 105.81912283617395,
        "from_longitude": 105.81934680062821,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7910,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81893239933444,
          21.014209272189113,
          105.81912283617395,
          21.01441333332931
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81912283617395,
            21.01441333332931
          ],
          [
            105.81893239933444,
            21.014209272189113
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.01441333332931,
        "to_latitude": 21.014209272189113,
        "to_longitude": 105.81893239933444,
        "from_longitude": 105.81912283617395,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7911,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81877549010966,
          21.0140302489964,
          105.81893239933444,
          21.014209272189113
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81893239933444,
            21.014209272189113
          ],
          [
            105.81877549010966,
            21.0140302489964
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "02",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.014209272189113,
        "to_latitude": 21.0140302489964,
        "to_longitude": 105.81877549010966,
        "from_longitude": 105.81893239933444,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7912,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82005892713407,
          21.015461176626335,
          105.8202627750033,
          21.015661480358787
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82005892713407,
            21.015461176626335
          ],
          [
            105.8202627750033,
            21.015661480358787
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "08",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.015461176626335,
        "to_latitude": 21.015661480358787,
        "to_longitude": 105.8202627750033,
        "from_longitude": 105.82005892713407,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7913,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82168032247766,
          21.016526539020784,
          105.82203973849475,
          21.016529042800883
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82168032247766,
            21.016526539020784
          ],
          [
            105.82203973849475,
            21.016529042800883
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "14",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.016526539020784,
        "to_latitude": 21.016529042800883,
        "to_longitude": 105.82203973849475,
        "from_longitude": 105.82168032247766,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7914,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82203973849475,
          21.016526539020784,
          105.82232405265015,
          21.016529042800883
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82203973849475,
            21.016529042800883
          ],
          [
            105.82232405265015,
            21.016526539020784
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "15",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.016529042800883,
        "to_latitude": 21.016526539020784,
        "to_longitude": 105.82232405265015,
        "from_longitude": 105.82203973849475,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7915,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82232405265015,
          21.016526539020784,
          105.82261373122104,
          21.016531546581795
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82232405265015,
            21.016526539020784
          ],
          [
            105.82261373122104,
            21.016531546581795
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "16",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.016526539020784,
        "to_latitude": 21.016531546581795,
        "to_longitude": 105.82261373122104,
        "from_longitude": 105.82232405265015,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7916,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82128603775992,
          21.016526539020784,
          105.82168032247766,
          21.016526539020795
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82168032247766,
            21.016526539020795
          ],
          [
            105.82128603775992,
            21.016526539020784
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "13",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.016526539020795,
        "to_latitude": 21.016526539020784,
        "to_longitude": 105.82128603775992,
        "from_longitude": 105.82168032247766,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7917,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82097221929243,
          21.016343762934422,
          105.82128603775992,
          21.016526539020784
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82128603775992,
            21.016526539020784
          ],
          [
            105.82097221929243,
            21.016343762934422
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "12",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.016526539020784,
        "to_latitude": 21.016343762934422,
        "to_longitude": 105.82097221929243,
        "from_longitude": 105.82128603775992,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7918,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82076166588554,
          21.016137200656672,
          105.82097221929243,
          21.016343762934422
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82097221929243,
            21.016343762934422
          ],
          [
            105.82076166588554,
            21.016137200656672
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "11",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.016343762934422,
        "to_latitude": 21.016137200656672,
        "to_longitude": 105.82076166588554,
        "from_longitude": 105.82097221929243,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7919,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8202627750033,
          21.015661480358787,
          105.82049711766523,
          21.01588588666738
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8202627750033,
            21.015661480358787
          ],
          [
            105.82049711766523,
            21.01588588666738
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.015661480358787,
        "to_latitude": 21.01588588666738,
        "to_longitude": 105.82049711766523,
        "from_longitude": 105.8202627750033,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7920,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82049711766523,
          21.01588588666738,
          105.82076166588554,
          21.016137200656647
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82049711766523,
            21.01588588666738
          ],
          [
            105.82076166588554,
            21.016137200656647
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.01588588666738,
        "to_latitude": 21.016137200656647,
        "to_longitude": 105.82076166588554,
        "from_longitude": 105.82049711766523,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7921,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82006563265341,
          21.015222063688014,
          105.82034726461006,
          21.01547995511234
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82006563265341,
            21.01547995511234
          ],
          [
            105.82034726461006,
            21.01524084220496
          ],
          [
            105.82033385356235,
            21.015222063688014
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.01547995511234,
        "to_latitude": 21.015222063688014,
        "to_longitude": 105.82033385356235,
        "from_longitude": 105.82006563265341,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7922,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82008229865525,
          21.01524835361158,
          105.82035799345,
          21.015484141819595
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82008229865525,
            21.015484141819595
          ],
          [
            105.82035799345,
            21.01524835361158
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.015484141819595,
        "to_latitude": 21.01524835361158,
        "to_longitude": 105.82035799345,
        "from_longitude": 105.82008229865525,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7923,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82035799345,
          21.01524835361158,
          105.82056988796043,
          21.01546618422059
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82035799345,
            21.01524835361158
          ],
          [
            105.82056988796043,
            21.01546618422059
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.01524835361158,
        "to_latitude": 21.01546618422059,
        "to_longitude": 105.82056988796043,
        "from_longitude": 105.82035799345,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7924,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82056988796043,
          21.01546618422059,
          105.82075630146137,
          21.01567086958779
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82056988796043,
            21.01546618422059
          ],
          [
            105.82075630146137,
            21.01567086958779
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "33",
        "diemCuoi": "32",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.01546618422059,
        "to_latitude": 21.01567086958779,
        "to_longitude": 105.82075630146137,
        "from_longitude": 105.82056988796043,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7925,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82075630146137,
          21.01567086958779,
          105.82094942050865,
          21.015868669249148
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82075630146137,
            21.01567086958779
          ],
          [
            105.82094942050865,
            21.015868669249148
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "32",
        "diemCuoi": "31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.01567086958779,
        "to_latitude": 21.015868669249148,
        "to_longitude": 105.82094942050865,
        "from_longitude": 105.82075630146137,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7926,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82094942050865,
          21.015868669249148,
          105.82111705857338,
          21.016063964859462
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82094942050865,
            21.015868669249148
          ],
          [
            105.82111705857338,
            21.016063964859462
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "31",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.015868669249148,
        "to_latitude": 21.016063964859462,
        "to_longitude": 105.82111705857338,
        "from_longitude": 105.82094942050865,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7927,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82111705857338,
          21.016063964859462,
          105.8213866205772,
          21.016246741288743
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82111705857338,
            21.016063964859462
          ],
          [
            105.8213866205772,
            21.016246741288743
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "30",
        "diemCuoi": "29",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.016063964859462,
        "to_latitude": 21.016246741288743,
        "to_longitude": 105.8213866205772,
        "from_longitude": 105.82111705857338,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7928,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8213866205772,
          21.016246741288743,
          105.82166557031711,
          21.016291809415474
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8213866205772,
            21.016246741288743
          ],
          [
            105.82166557031711,
            21.016291809415474
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "29",
        "diemCuoi": "28",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.016246741288743,
        "to_latitude": 21.016291809415474,
        "to_longitude": 105.82166557031711,
        "from_longitude": 105.8213866205772,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7929,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82166557031711,
          21.016291809415474,
          105.82194854336865,
          21.016296816984347
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82166557031711,
            21.016291809415474
          ],
          [
            105.82194854336865,
            21.016296816984347
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "28",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.016291809415474,
        "to_latitude": 21.016296816984347,
        "to_longitude": 105.82194854336865,
        "from_longitude": 105.82166557031711,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7930,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82241658884419,
          21.016299320767477,
          105.82268212753644,
          21.016301824552233
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82241658884419,
            21.016301824552233
          ],
          [
            105.82268212753644,
            21.016299320767477
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "24",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.016301824552233,
        "to_latitude": 21.016299320767477,
        "to_longitude": 105.82268212753644,
        "from_longitude": 105.82241658884419,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7931,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82268212753644,
          21.016299320767477,
          105.82295303064413,
          21.01632435860815
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82268212753644,
            21.016299320767477
          ],
          [
            105.82295303064413,
            21.01632435860815
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "24",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.016299320767477,
        "to_latitude": 21.01632435860815,
        "to_longitude": 105.82295303064413,
        "from_longitude": 105.82268212753644,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7932,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82227443176201,
          21.016186650431635,
          105.82251046616008,
          21.016304328336123
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82227443176201,
            21.016304328336123
          ],
          [
            105.82234953361473,
            21.016186650431635
          ],
          [
            105.82251046616008,
            21.016194161790636
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "25",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.016304328336123,
        "to_latitude": 21.016194161790636,
        "to_longitude": 105.82251046616008,
        "from_longitude": 105.82227443176201,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7933,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82251046616008,
          21.016194161790636,
          105.82281087357093,
          21.01619666557632
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82251046616008,
            21.016194161790636
          ],
          [
            105.82281087357093,
            21.01619666557632
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "21",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.016194161790636,
        "to_latitude": 21.01619666557632,
        "to_longitude": 105.82281087357093,
        "from_longitude": 105.82251046616008,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7934,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82281087357093,
          21.01619666557632,
          105.82304959016777,
          21.01619666557632
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82281087357093,
            21.01619666557632
          ],
          [
            105.82304959016777,
            21.01619666557632
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "20",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.01619666557632,
        "to_latitude": 21.01619666557632,
        "to_longitude": 105.82304959016777,
        "from_longitude": 105.82281087357093,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7935,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82304959016777,
          21.01619666557632,
          105.82322929817632,
          21.01619666557632
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82304959016777,
            21.01619666557632
          ],
          [
            105.82322929817632,
            21.01619666557632
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "19",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.01619666557632,
        "to_latitude": 21.01619666557632,
        "to_longitude": 105.82322929817632,
        "from_longitude": 105.82304959016777,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7936,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82194854336865,
          21.016296816984347,
          105.82212959248105,
          21.016301824552233
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82194854336865,
            21.016296816984347
          ],
          [
            105.82212959248105,
            21.016301824552233
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "27",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.016296816984347,
        "to_latitude": 21.016301824552233,
        "to_longitude": 105.82212959248105,
        "from_longitude": 105.82194854336865,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7937,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82212959248105,
          21.016301824552233,
          105.82227443176201,
          21.016304328336123
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82212959248105,
            21.016301824552233
          ],
          [
            105.82227443176201,
            21.016304328336123
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "26",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.016301824552233,
        "to_latitude": 21.016304328336123,
        "to_longitude": 105.82227443176201,
        "from_longitude": 105.82212959248105,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7938,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82227443176201,
          21.016301824552233,
          105.82241658884419,
          21.016304328336123
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82227443176201,
            21.016304328336123
          ],
          [
            105.82241658884419,
            21.016301824552233
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "25",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.016304328336123,
        "to_latitude": 21.016301824552233,
        "to_longitude": 105.82241658884419,
        "from_longitude": 105.82227443176201,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7939,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82013939340224,
          21.015037408145528,
          105.82033385356235,
          21.015222063688014
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82033385356235,
            21.015222063688014
          ],
          [
            105.82013939340224,
            21.015037408145528
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.015222063688014,
        "to_latitude": 21.015037408145528,
        "to_longitude": 105.82013939340224,
        "from_longitude": 105.82033385356235,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7940,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81994090993052,
          21.01483209595451,
          105.82013939340224,
          21.015037408145528
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82013939340224,
            21.015037408145528
          ],
          [
            105.81994090993052,
            21.01483209595451
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "35",
        "diemCuoi": "36",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.015037408145528,
        "to_latitude": 21.01483209595451,
        "to_longitude": 105.81994090993052,
        "from_longitude": 105.82013939340224,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7941,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81973169762783,
          21.014614264419375,
          105.81994090993052,
          21.014832095954525
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81994090993052,
            21.014832095954525
          ],
          [
            105.81973169762783,
            21.014614264419375
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "36",
        "diemCuoi": "37",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.014832095954525,
        "to_latitude": 21.014614264419375,
        "to_longitude": 105.81973169762783,
        "from_longitude": 105.81994090993052,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7942,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81956808287472,
          21.014436493619954,
          105.81973169762783,
          21.014614264419375
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81973169762783,
            21.014614264419375
          ],
          [
            105.81956808287472,
            21.014436493619954
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "37",
        "diemCuoi": "38",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.014614264419375,
        "to_latitude": 21.014436493619954,
        "to_longitude": 105.81956808287472,
        "from_longitude": 105.81973169762783,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7943,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81938837487516,
          21.014228676783034,
          105.81956808287472,
          21.01443649361996
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81956808287472,
            21.01443649361996
          ],
          [
            105.81938837487516,
            21.014228676783034
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "38",
        "diemCuoi": "39",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.01443649361996,
        "to_latitude": 21.014228676783034,
        "to_longitude": 105.81938837487516,
        "from_longitude": 105.81956808287472,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7944,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81921671349882,
          21.014043394057925,
          105.81938837487516,
          21.014228676783034
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81938837487516,
            21.014228676783034
          ],
          [
            105.81921671349882,
            21.014043394057925
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "39",
        "diemCuoi": "40",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.014228676783034,
        "to_latitude": 21.014043394057925,
        "to_longitude": 105.81921671349882,
        "from_longitude": 105.81938837487516,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7945,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81903432329153,
          21.013851851538952,
          105.81921671349882,
          21.014043394057925
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81921671349882,
            21.014043394057925
          ],
          [
            105.81903432329153,
            21.013851851538952
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "NAQ10",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Hoàng Cầu",
        "diemDau": "40",
        "diemCuoi": "41",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Nguyễn Ái Quốc 10",
        "from_latitude": 21.014043394057925,
        "to_latitude": 21.013851851538952,
        "to_longitude": 105.81903432329153,
        "from_longitude": 105.81921671349882,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7946,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82274113613369,
          21.006290733235666,
          105.82288597541465,
          21.006576183522675
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82288597541465,
            21.006576183522675
          ],
          [
            105.82274113613369,
            21.006290733235666
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 45,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "59",
        "diemCuoi": "60",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.006576183522675,
        "to_latitude": 21.006290733235666,
        "to_longitude": 105.82274113613369,
        "from_longitude": 105.82288597541465,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7947,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82262446004302,
          21.006052857579487,
          105.82274113613369,
          21.006290733235666
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82274113613369,
            21.006290733235666
          ],
          [
            105.82262446004302,
            21.006052857579487
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "58",
        "diemCuoi": "59",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.006290733235666,
        "to_latitude": 21.006052857579487,
        "to_longitude": 105.82262446004302,
        "from_longitude": 105.82274113613369,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7948,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82237099128564,
          21.00571607508068,
          105.8223897667578,
          21.005731098850234
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82237099128564,
            21.005731098850234
          ],
          [
            105.8223897667578,
            21.00571607508068
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 2,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.005731098850234,
        "to_latitude": 21.00571607508068,
        "to_longitude": 105.8223897667578,
        "from_longitude": 105.82237099128564,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7949,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8223897667578,
          21.00571607508068,
          105.82249671985215,
          21.005906063071034
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8223897667578,
            21.00571607508068
          ],
          [
            105.82249671985215,
            21.005906063071034
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.00571607508068,
        "to_latitude": 21.005906063071034,
        "to_longitude": 105.82249671985215,
        "from_longitude": 105.8223897667578,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7950,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82253963520297,
          21.005928598697356,
          105.82262446004302,
          21.006052857579487
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82262446004302,
            21.006052857579487
          ],
          [
            105.82253963520297,
            21.005928598697356
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.006052857579487,
        "to_latitude": 21.005928598697356,
        "to_longitude": 105.82253963520297,
        "from_longitude": 105.82262446004302,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7951,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82249671985215,
          21.005906063071034,
          105.82253963520297,
          21.005928598697356
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82253963520297,
            21.005928598697356
          ],
          [
            105.82249671985215,
            21.005906063071034
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 6,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.005928598697356,
        "to_latitude": 21.005906063071034,
        "to_longitude": 105.82249671985215,
        "from_longitude": 105.82253963520297,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7952,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82230125385739,
          21.00552326988603,
          105.82240418364012,
          21.005731098850234
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82237099128564,
            21.005731098850234
          ],
          [
            105.82240418364012,
            21.005694478407243
          ],
          [
            105.82230125385739,
            21.00552326988603
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "56",
        "diemCuoi": "57",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.005731098850234,
        "to_latitude": 21.00552326988603,
        "to_longitude": 105.82230125385739,
        "from_longitude": 105.82237099128564,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7953,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82218055444612,
          21.005280385067852,
          105.82230125385739,
          21.00552326988603
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82218055444612,
            21.005280385067852
          ],
          [
            105.82230125385739,
            21.00552326988603
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "55",
        "diemCuoi": "56",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.005280385067852,
        "to_latitude": 21.00552326988603,
        "to_longitude": 105.82230125385739,
        "from_longitude": 105.82218055444612,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7954,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82200352867225,
          21.00510322914005,
          105.82203437407928,
          21.0051470486475
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82200352867225,
            21.00510322914005
          ],
          [
            105.82201828082384,
            21.005123260916122
          ],
          [
            105.82203437407928,
            21.0051470486475
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 6,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.00510322914005,
        "to_latitude": 21.0051470486475,
        "to_longitude": 105.82203437407928,
        "from_longitude": 105.82200352867225,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7955,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8220866771482,
          21.005139536733086,
          105.82218055444612,
          21.005280385067852
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82218055444612,
            21.005280385067852
          ],
          [
            105.8220866771482,
            21.005139536733086
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.005280385067852,
        "to_latitude": 21.005139536733086,
        "to_longitude": 105.8220866771482,
        "from_longitude": 105.82218055444612,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7956,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82203437407928,
          21.00513327680357,
          105.8220826538366,
          21.005147048647512
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82203437407928,
            21.005147048647512
          ],
          [
            105.82205046732571,
            21.005138284747023
          ],
          [
            105.82206656058115,
            21.00513327680357
          ],
          [
            105.8220826538366,
            21.005138284747023
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 3,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.005147048647512,
        "to_latitude": 21.005138284747023,
        "to_longitude": 105.8220826538366,
        "from_longitude": 105.82203437407928,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7957,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82200352867225,
          21.005079441402554,
          105.82201962192771,
          21.00510322914005
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82200352867225,
            21.00510322914005
          ],
          [
            105.82201962192771,
            21.005093213250582
          ],
          [
            105.82201828082385,
            21.005079441402554
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 2,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.00510322914005,
        "to_latitude": 21.005079441402554,
        "to_longitude": 105.82201828082385,
        "from_longitude": 105.82200352867225,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7958,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8219820709834,
          21.005014964091743,
          105.82201828082384,
          21.00507944140253
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82201828082384,
            21.00507944140253
          ],
          [
            105.8219820709834,
            21.005014964091743
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 5,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.00507944140253,
        "to_latitude": 21.005014964091743,
        "to_longitude": 105.8219820709834,
        "from_longitude": 105.82201828082384,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7959,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8217996807671,
          21.004742030709924,
          105.8219820709834,
          21.005014964091743
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8217996807671,
            21.004742030709924
          ],
          [
            105.8219820709834,
            21.005014964091743
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "53",
        "diemCuoi": "54",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.004742030709924,
        "to_latitude": 21.005014964091743,
        "to_longitude": 105.8219820709834,
        "from_longitude": 105.8217996807671,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7960,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82166557031711,
          21.00451166453127,
          105.8217996807671,
          21.004742030709924
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82166557031711,
            21.00451166453127
          ],
          [
            105.8217996807671,
            21.004742030709924
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "52",
        "diemCuoi": "53",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.00451166453127,
        "to_latitude": 21.004742030709924,
        "to_longitude": 105.8217996807671,
        "from_longitude": 105.82166557031711,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7961,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8214214892958,
          21.004286305968233,
          105.82166557031711,
          21.00451166453127
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8214214892958,
            21.004286305968233
          ],
          [
            105.821552917565,
            21.004378327424046
          ],
          [
            105.82166557031711,
            21.00451166453127
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "51",
        "diemCuoi": "52",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.004286305968233,
        "to_latitude": 21.00451166453127,
        "to_longitude": 105.82166557031711,
        "from_longitude": 105.8214214892958,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7962,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82117472606676,
          21.00408849094939,
          105.8214214892958,
          21.004286305968233
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82117472606676,
            21.00408849094939
          ],
          [
            105.8214214892958,
            21.004286305968233
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "50",
        "diemCuoi": "51",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.00408849094939,
        "to_latitude": 21.004286305968233,
        "to_longitude": 105.8214214892958,
        "from_longitude": 105.82117472606676,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7963,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82092259842223,
          21.00388316369063,
          105.82117472606676,
          21.00408849094939
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82092259842223,
            21.00388316369063
          ],
          [
            105.82117472606676,
            21.00408849094939
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "49",
        "diemCuoi": "50",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.00388316369063,
        "to_latitude": 21.00408849094939,
        "to_longitude": 105.82117472606676,
        "from_longitude": 105.82092259842223,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7964,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82067851740094,
          21.003692860125007,
          105.82092259842223,
          21.00388316369063
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82067851740094,
            21.003692860125007
          ],
          [
            105.82092259842223,
            21.00388316369063
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "48",
        "diemCuoi": "49",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.003692860125007,
        "to_latitude": 21.00388316369063,
        "to_longitude": 105.82092259842223,
        "from_longitude": 105.82067851740094,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7965,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82282964906105,
          21.005689157488554,
          105.82294239667026,
          21.005949729560065
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82282964906105,
            21.005689157488554
          ],
          [
            105.82294239667026,
            21.005949729560065
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "29",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.005689157488554,
        "to_latitude": 21.005949729560065,
        "to_longitude": 105.82294239667026,
        "from_longitude": 105.82282964906105,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7966,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82294239667026,
          21.005949729560065,
          105.8230674870484,
          21.00622110263231
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82294239667026,
            21.005949729560065
          ],
          [
            105.8230674870484,
            21.00622110263231
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "30",
        "diemCuoi": "31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.005949729560065,
        "to_latitude": 21.00622110263231,
        "to_longitude": 105.8230674870484,
        "from_longitude": 105.82294239667026,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7967,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82306748704842,
          21.00622110263231,
          105.82317297178678,
          21.006435962395685
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82306748704842,
            21.00622110263231
          ],
          [
            105.82317297178678,
            21.006435962395685
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "31",
        "diemCuoi": "32",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.00622110263231,
        "to_latitude": 21.006435962395685,
        "to_longitude": 105.82317297178678,
        "from_longitude": 105.82306748704842,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7968,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82281623801336,
          21.00565660597441,
          105.82282964906105,
          21.005689157488554
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82282964906105,
            21.005689157488554
          ],
          [
            105.82281623801336,
            21.00565660597441
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 5,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.005689157488554,
        "to_latitude": 21.00565660597441,
        "to_longitude": 105.82281623801336,
        "from_longitude": 105.82282964906105,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7969,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82281355580561,
          21.005616542561928,
          105.82281623801336,
          21.00565660597441
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82281623801336,
            21.00565660597441
          ],
          [
            105.82281355580561,
            21.005616542561928
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 4,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.00565660597441,
        "to_latitude": 21.005616542561928,
        "to_longitude": 105.82281355580561,
        "from_longitude": 105.82281623801336,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7970,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82271565517811,
          21.005426241207683,
          105.82281355580561,
          21.005616542561928
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82281355580561,
            21.005616542561928
          ],
          [
            105.82271565517811,
            21.005426241207683
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.005616542561928,
        "to_latitude": 21.005426241207683,
        "to_longitude": 105.82271565517811,
        "from_longitude": 105.82281355580561,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7971,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8225654514727,
          21.005125764890145,
          105.82271565517811,
          21.005426241207683
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82271565517811,
            21.005426241207683
          ],
          [
            105.8225654514727,
            21.005125764890145
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.005426241207683,
        "to_latitude": 21.005125764890145,
        "to_longitude": 105.8225654514727,
        "from_longitude": 105.82271565517811,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7972,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82239379009637,
          21.004817776036113,
          105.8225654514727,
          21.005125764890145
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8225654514727,
            21.005125764890145
          ],
          [
            105.82239379009637,
            21.004817776036113
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 41,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.005125764890145,
        "to_latitude": 21.004817776036113,
        "to_longitude": 105.82239379009637,
        "from_longitude": 105.8225654514727,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7973,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82218457779368,
          21.004484746723584,
          105.82239379009637,
          21.004817776036113
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82239379009637,
            21.004817776036113
          ],
          [
            105.82218457779368,
            21.004484746723584
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.004817776036113,
        "to_latitude": 21.004484746723584,
        "to_longitude": 105.82218457779368,
        "from_longitude": 105.82239379009637,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7974,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82194904630842,
          21.004219480832436,
          105.82218457779368,
          21.004484746723584
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82218457779368,
            21.004484746723584
          ],
          [
            105.82194904630842,
            21.004219480832436
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.004484746723584,
        "to_latitude": 21.004219480832436,
        "to_longitude": 105.82194904630842,
        "from_longitude": 105.82218457779368,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7975,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8216835076162,
          21.003982853869328,
          105.82194904630842,
          21.004219480832436
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82194904630842,
            21.004219480832436
          ],
          [
            105.8216835076162,
            21.003982853869328
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.004219480832436,
        "to_latitude": 21.003982853869328,
        "to_longitude": 105.8216835076162,
        "from_longitude": 105.82194904630842,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7976,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82132811492869,
          21.003709918599803,
          105.8216835076162,
          21.003982853869328
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8216835076162,
            21.003982853869328
          ],
          [
            105.82157621925262,
            21.00394779799089
          ],
          [
            105.82132811492869,
            21.003709918599803
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.003982853869328,
        "to_latitude": 21.003709918599803,
        "to_longitude": 105.82132811492869,
        "from_longitude": 105.8216835076162,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7977,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82109342163449,
          21.003504590819347,
          105.82132811492869,
          21.003709918599803
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82132811492869,
            21.003709918599803
          ],
          [
            105.82109342163449,
            21.003504590819347
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.003709918599803,
        "to_latitude": 21.003504590819347,
        "to_longitude": 105.82109342163449,
        "from_longitude": 105.82132811492869,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7978,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82283551638936,
          21.006138774556714,
          105.82296426242385,
          21.006404193532994
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82296426242385,
            21.006404193532994
          ],
          [
            105.82283551638936,
            21.006138774556714
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "33",
        "diemCuoi": "34",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.006404193532994,
        "to_latitude": 21.006138774556714,
        "to_longitude": 105.82283551638936,
        "from_longitude": 105.82296426242385,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7979,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82270677035486,
          21.005905906575148,
          105.82283551638936,
          21.006138774556714
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82283551638936,
            21.006138774556714
          ],
          [
            105.82270677035486,
            21.005905906575148
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 31,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "34",
        "diemCuoi": "35",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.006138774556714,
        "to_latitude": 21.005905906575148,
        "to_longitude": 105.82270677035486,
        "from_longitude": 105.82283551638936,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7980,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82247073596578,
          21.005425145722093,
          105.82270677035486,
          21.005905906575148
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82270677035486,
            21.005905906575148
          ],
          [
            105.82247073596578,
            21.005425145722093
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 63,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "36",
        "diemCuoi": "37",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.005905906575148,
        "to_latitude": 21.005425145722093,
        "to_longitude": 105.82247073596578,
        "from_longitude": 105.82270677035486,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7981,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82232053226036,
          21.005134685289704,
          105.82247073596578,
          21.005425145722093
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82247073596578,
            21.005425145722093
          ],
          [
            105.82232053226036,
            21.005134685289704
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "36",
        "diemCuoi": "37",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.005425145722093,
        "to_latitude": 21.005134685289704,
        "to_longitude": 105.82232053226036,
        "from_longitude": 105.82247073596578,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7982,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82191551870267,
          21.004493667161466,
          105.82212204879761,
          21.00476660099836
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82191551870267,
            21.004493667161466
          ],
          [
            105.82212204879761,
            21.00476660099836
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "38",
        "diemCuoi": "39",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.004493667161466,
        "to_latitude": 21.00476660099836,
        "to_longitude": 105.82212204879761,
        "from_longitude": 105.82191551870267,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7983,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82212204879761,
          21.00476660099836,
          105.82232053226036,
          21.005134685289715
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82232053226036,
            21.005134685289715
          ],
          [
            105.82212204879761,
            21.00476660099836
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "37",
        "diemCuoi": "38",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.005134685289715,
        "to_latitude": 21.00476660099836,
        "to_longitude": 105.82212204879761,
        "from_longitude": 105.82232053226036,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7984,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82169021314454,
          21.004255788640506,
          105.82191551870267,
          21.004493667161466
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82191551870267,
            21.004493667161466
          ],
          [
            105.82169021314454,
            21.004255788640506
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "39",
        "diemCuoi": "40",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.004493667161466,
        "to_latitude": 21.004255788640506,
        "to_longitude": 105.82169021314454,
        "from_longitude": 105.82191551870267,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7985,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82139248794144,
          21.00401039776827,
          105.82169021314454,
          21.004255788640506
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82169021314454,
            21.004255788640506
          ],
          [
            105.82139248794144,
            21.00401039776827
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "40",
        "diemCuoi": "41",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.004255788640506,
        "to_latitude": 21.00401039776827,
        "to_longitude": 105.82139248794144,
        "from_longitude": 105.82169021314454,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7986,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82085631309015,
          21.003306206760293,
          105.82109342163449,
          21.003504590819333
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82109342163449,
            21.003504590819333
          ],
          [
            105.82085631309015,
            21.003306206760293
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "21",
        "diemCuoi": "20",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.003504590819333,
        "to_latitude": 21.003306206760293,
        "to_longitude": 105.82085631309015,
        "from_longitude": 105.82109342163449,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7987,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82116641564468,
          21.003826289333613,
          105.82139248794144,
          21.00401039776828
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82139248794144,
            21.00401039776828
          ],
          [
            105.82116641564468,
            21.003826289333613
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "41",
        "diemCuoi": "42",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.00401039776828,
        "to_latitude": 21.003826289333613,
        "to_longitude": 105.82116641564468,
        "from_longitude": 105.82139248794144,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7988,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8209457584235,
          21.003648523288305,
          105.8211875127014,
          21.00384347033419
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8211875127014,
            21.00384347033419
          ],
          [
            105.8209457584235,
            21.003648523288305
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "42",
        "diemCuoi": "43",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.00384347033419,
        "to_latitude": 21.003648523288305,
        "to_longitude": 105.8209457584235,
        "from_longitude": 105.8211875127014,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7989,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82070450133223,
          21.003469534828582,
          105.8209457584235,
          21.003648523288305
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8209457584235,
            21.003648523288305
          ],
          [
            105.82070450133223,
            21.003469534828582
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "43",
        "diemCuoi": "44",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.003648523288305,
        "to_latitude": 21.003469534828582,
        "to_longitude": 105.82070450133223,
        "from_longitude": 105.8209457584235,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7990,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82053149885202,
          21.00333682278951,
          105.82070450133223,
          21.003469534828596
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82070450133223,
            21.003469534828596
          ],
          [
            105.82053149885202,
            21.00333682278951
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "44",
        "diemCuoi": "45",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.003469534828596,
        "to_latitude": 21.00333682278951,
        "to_longitude": 105.82053149885202,
        "from_longitude": 105.82070450133223,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7991,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8196745330742,
          21.00270831700012,
          105.81982473677064,
          21.00281974551012
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81982473677064,
            21.00281974551012
          ],
          [
            105.8196745330742,
            21.00270831700012
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Trãi",
        "diemDau": "11",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.00281974551012,
        "to_latitude": 21.00270831700012,
        "to_longitude": 105.8196745330742,
        "from_longitude": 105.81982473677064,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7992,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81942240542968,
          21.00252301996754,
          105.8196745330742,
          21.00270831700012
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8196745330742,
            21.00270831700012
          ],
          [
            105.81942240542968,
            21.00252301996754
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Trãi",
        "diemDau": "10",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.00270831700012,
        "to_latitude": 21.00252301996754,
        "to_longitude": 105.81942240542968,
        "from_longitude": 105.8196745330742,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7993,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81912283617395,
          21.002578577799824,
          105.8193615527708,
          21.002776394820152
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8193615527708,
            21.002776394820152
          ],
          [
            105.81912283617395,
            21.002578577799824
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Trãi",
        "diemDau": "05",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.002776394820152,
        "to_latitude": 21.002578577799824,
        "to_longitude": 105.81912283617395,
        "from_longitude": 105.8193615527708,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7994,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81887339073715,
          21.002408304711118,
          105.81912283617395,
          21.002578577799824
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81912283617395,
            21.002578577799824
          ],
          [
            105.81887339073715,
            21.002408304711118
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Trãi",
        "diemDau": "04",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.002578577799824,
        "to_latitude": 21.002408304711118,
        "to_longitude": 105.81887339073715,
        "from_longitude": 105.81912283617395,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7995,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8185951115447,
          21.002222381301635,
          105.81887339073715,
          21.002408304711118
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81887339073715,
            21.002408304711118
          ],
          [
            105.8185951115447,
            21.002222381301635
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Trãi",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.002408304711118,
        "to_latitude": 21.002222381301635,
        "to_longitude": 105.8185951115447,
        "from_longitude": 105.81887339073715,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7996,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8184259647954,
          21.002104848792634,
          105.8185951115447,
          21.002222381301635
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8185951115447,
            21.002222381301635
          ],
          [
            105.8184259647954,
            21.002104848792634
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Trãi",
        "diemDau": "02",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.002222381301635,
        "to_latitude": 21.002104848792634,
        "to_longitude": 105.8184259647954,
        "from_longitude": 105.8185951115447,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7997,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81972951837783,
          21.00247043563327,
          105.81997896381462,
          21.002660740757737
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81997896381462,
            21.002660740757737
          ],
          [
            105.81972951837783,
            21.00247043563327
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Trãi",
        "diemDau": "19",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.002660740757737,
        "to_latitude": 21.00247043563327,
        "to_longitude": 105.81972951837783,
        "from_longitude": 105.81997896381462,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7998,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8194559330534,
          21.00225008202897,
          105.81972951837783,
          21.00247043563327
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81972951837783,
            21.00247043563327
          ],
          [
            105.8194559330534,
            21.00225008202897
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Trãi",
        "diemDau": "18",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.00247043563327,
        "to_latitude": 21.00225008202897,
        "to_longitude": 105.8194559330534,
        "from_longitude": 105.81972951837783,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 7999,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81917698332248,
          21.00201971200275,
          105.8194559330534,
          21.00225008202897
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8194559330534,
            21.00225008202897
          ],
          [
            105.81917698332248,
            21.00201971200275
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Trãi",
        "diemDau": "17",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.00225008202897,
        "to_latitude": 21.00201971200275,
        "to_longitude": 105.81917698332248,
        "from_longitude": 105.8194559330534,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8000,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81888730474262,
          21.001781829538327,
          105.81917698332248,
          21.00201971200275
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81917698332248,
            21.00201971200275
          ],
          [
            105.81888730474262,
            21.001781829538327
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Trãi",
        "diemDau": "16",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.00201971200275,
        "to_latitude": 21.001781829538327,
        "to_longitude": 105.81888730474262,
        "from_longitude": 105.81917698332248,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8001,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81859092065235,
          21.001545198709294,
          105.81888730474262,
          21.001781829538338
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81888730474262,
            21.001781829538338
          ],
          [
            105.81859092065235,
            21.001545198709294
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Trãi",
        "diemDau": "15",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.001781829538338,
        "to_latitude": 21.001545198709294,
        "to_longitude": 105.81859092065235,
        "from_longitude": 105.81888730474262,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8002,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81859896728457,
          21.001920803023875,
          105.81886987039229,
          21.002113612871252
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81859896728457,
            21.001920803023875
          ],
          [
            105.81886987039229,
            21.002113612871252
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Trãi",
        "diemDau": "07",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.001920803023875,
        "to_latitude": 21.002113612871252,
        "to_longitude": 105.81886987039229,
        "from_longitude": 105.81859896728457,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8003,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81884813768319,
          21.002098145061108,
          105.81912468024454,
          21.00229891041213
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81884813768319,
            21.002098145061108
          ],
          [
            105.81912468024454,
            21.00229891041213
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Trãi",
        "diemDau": "08",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.002098145061108,
        "to_latitude": 21.00229891041213,
        "to_longitude": 105.81912468024454,
        "from_longitude": 105.81884813768319,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8004,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81912468024454,
          21.00229891041213,
          105.81942240542968,
          21.00252301996754
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81942240542968,
            21.00252301996754
          ],
          [
            105.81912468024454,
            21.00229891041213
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Trãi",
        "diemDau": "09",
        "diemCuoi": "08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.00252301996754,
        "to_latitude": 21.00229891041213,
        "to_longitude": 105.81912468024454,
        "from_longitude": 105.81942240542968,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8005,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81928829500663,
          21.003237132180118,
          105.81955400131552,
          21.00334308279357
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81955400131552,
            21.003237132180118
          ],
          [
            105.81928829500663,
            21.00334308279357
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "32",
        "diemCuoi": "31",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.003237132180118,
        "to_latitude": 21.00334308279357,
        "to_longitude": 105.81928829500663,
        "from_longitude": 105.81955400131552,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8006,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81908444711944,
          21.00334308279357,
          105.81928829500663,
          21.003433226829213
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81928829500663,
            21.00334308279357
          ],
          [
            105.81908444711944,
            21.003433226829213
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "31",
        "diemCuoi": "30",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.00334308279357,
        "to_latitude": 21.003433226829213,
        "to_longitude": 105.81908444711944,
        "from_longitude": 105.81928829500663,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8007,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81900398084225,
          21.003433226829213,
          105.81908444711944,
          21.003480802826147
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81908444711944,
            21.003433226829213
          ],
          [
            105.81900398084225,
            21.003480802826147
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.003433226829213,
        "to_latitude": 21.003480802826147,
        "to_longitude": 105.81900398084225,
        "from_longitude": 105.81908444711944,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8008,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81874648878227,
          21.003480802826147,
          105.81900398084225,
          21.003641058703433
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81900398084225,
            21.003480802826147
          ],
          [
            105.81874648878227,
            21.003641058703433
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "30",
        "diemCuoi": "29",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.003480802826147,
        "to_latitude": 21.003641058703433,
        "to_longitude": 105.81874648878227,
        "from_longitude": 105.81900398084225,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8009,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81851045438418,
          21.003641058703447,
          105.81874648878227,
          21.003853898275633
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81874648878227,
            21.003641058703447
          ],
          [
            105.81851045438418,
            21.003853898275633
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "29",
        "diemCuoi": "28",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.003641058703447,
        "to_latitude": 21.003853898275633,
        "to_longitude": 105.81851045438418,
        "from_longitude": 105.81874648878227,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8010,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8192292864004,
          21.003463274829002,
          105.81950555393256,
          21.00357845877199
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8192292864004,
            21.00357845877199
          ],
          [
            105.81935803243489,
            21.00351335481492
          ],
          [
            105.81950555393256,
            21.003463274829002
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "35",
        "diemCuoi": "34",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.00357845877199,
        "to_latitude": 21.003463274829002,
        "to_longitude": 105.81950555393256,
        "from_longitude": 105.8192292864004,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8011,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81950555393256,
          21.003448250829457,
          105.8197523171616,
          21.003463274829002
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81950555393256,
            21.003463274829002
          ],
          [
            105.81962357112711,
            21.003448250829457
          ],
          [
            105.8197523171616,
            21.003455762829823
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "34",
        "diemCuoi": "33",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.003463274829002,
        "to_latitude": 21.003455762829823,
        "to_longitude": 105.8197523171616,
        "from_longitude": 105.81950555393256,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8012,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81900129863452,
          21.00357845877199,
          105.8192292864004,
          21.00370365860947
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8192292864004,
            21.00357845877199
          ],
          [
            105.81900129863452,
            21.00370365860947
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "35",
        "diemCuoi": "36",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.00357845877199,
        "to_latitude": 21.00370365860947,
        "to_longitude": 105.81900129863452,
        "from_longitude": 105.8192292864004,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8013,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81877331086865,
          21.00370365860947,
          105.81900129863452,
          21.00384889028905
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81900129863452,
            21.00370365860947
          ],
          [
            105.81877331086865,
            21.00384889028905
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "36",
        "diemCuoi": "37",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.00370365860947,
        "to_latitude": 21.00384889028905,
        "to_longitude": 105.81877331086865,
        "from_longitude": 105.81900129863452,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8014,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81860701391679,
          21.00384889028905,
          105.81877331086865,
          21.00404920961509
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81877331086865,
            21.00384889028905
          ],
          [
            105.81867406913729,
            21.003954057969313
          ],
          [
            105.81860701391679,
            21.00404920961509
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "38",
        "diemCuoi": "39",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.00384889028905,
        "to_latitude": 21.00404920961509,
        "to_longitude": 105.81860701391679,
        "from_longitude": 105.81877331086865,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8015,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8184246237005,
          21.00404920961509,
          105.81860701391679,
          21.00421697684405
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81860701391679,
            21.00404920961509
          ],
          [
            105.8184246237005,
            21.00421697684405
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "38",
        "diemCuoi": "39",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.00404920961509,
        "to_latitude": 21.00421697684405,
        "to_longitude": 105.8184246237005,
        "from_longitude": 105.81860701391679,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8016,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81824491569195,
          21.00421697684406,
          105.8184246237005,
          21.00435720005538
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8184246237005,
            21.00421697684406
          ],
          [
            105.81824491569195,
            21.00435720005538
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "39",
        "diemCuoi": "40",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.00421697684406,
        "to_latitude": 21.00435720005538,
        "to_longitude": 105.81824491569195,
        "from_longitude": 105.8184246237005,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8017,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81808398315557,
          21.00435720005538,
          105.81824491569195,
          21.004477391274033
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81824491569195,
            21.00435720005538
          ],
          [
            105.81808398315557,
            21.004477391274033
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "40",
        "diemCuoi": "41",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.00435720005538,
        "to_latitude": 21.004477391274033,
        "to_longitude": 105.81808398315557,
        "from_longitude": 105.81824491569195,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8018,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81787477085287,
          21.004477391274033,
          105.81808398315557,
          21.004587566472487
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81808398315557,
            21.004477391274033
          ],
          [
            105.81787477085287,
            21.004587566472487
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "41",
        "diemCuoi": "42",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.004477391274033,
        "to_latitude": 21.004587566472487,
        "to_longitude": 105.81787477085287,
        "from_longitude": 105.81808398315557,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8019,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81894111653206,
          21.003192060110287,
          105.81926029939709,
          21.003334788286235
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81894111653206,
            21.003334788286235
          ],
          [
            105.81920246431399,
            21.003205362633885
          ],
          [
            105.81926029939709,
            21.003192060110287
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 40,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "20",
        "diemCuoi": "19",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.003334788286235,
        "to_latitude": 21.003192060110287,
        "to_longitude": 105.81926029939709,
        "from_longitude": 105.81894111653206,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8020,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81870893785592,
          21.003334788286235,
          105.81894111653206,
          21.003483306825206
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81894111653206,
            21.003334788286235
          ],
          [
            105.81886182376905,
            21.003380642814843
          ],
          [
            105.81870893785592,
            21.003483306825206
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 43,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "20",
        "diemCuoi": "21",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.003334788286235,
        "to_latitude": 21.003483306825206,
        "to_longitude": 105.81870893785592,
        "from_longitude": 105.81894111653206,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8021,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81848363229777,
          21.003483306825206,
          105.81870893785592,
          21.003638554707017
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81870893785592,
            21.003483306825206
          ],
          [
            105.81848363229777,
            21.003638554707017
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "21",
        "diemCuoi": "22",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.003483306825206,
        "to_latitude": 21.003638554707017,
        "to_longitude": 105.81848363229777,
        "from_longitude": 105.81870893785592,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8022,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81826369115512,
          21.003638554707017,
          105.81848363229777,
          21.003841378309296
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81848363229777,
            21.003638554707017
          ],
          [
            105.81826369115512,
            21.003841378309296
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "22",
        "diemCuoi": "23",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.003638554707017,
        "to_latitude": 21.003841378309296,
        "to_longitude": 105.81826369115512,
        "from_longitude": 105.81848363229777,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8023,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81814030954511,
          21.003841378309286,
          105.81826369115512,
          21.00396407393439
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81826369115512,
            21.003841378309286
          ],
          [
            105.81814030954511,
            21.00396407393439
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "23",
        "diemCuoi": "24",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.003841378309286,
        "to_latitude": 21.00396407393439,
        "to_longitude": 105.81814030954511,
        "from_longitude": 105.81826369115512,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8024,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81797669479198,
          21.00396407393439,
          105.81814030954511,
          21.004141857213064
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81814030954511,
            21.00396407393439
          ],
          [
            105.81807325431562,
            21.004021665724153
          ],
          [
            105.81797669479198,
            21.004141857213064
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "24",
        "diemCuoi": "25",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.00396407393439,
        "to_latitude": 21.004141857213064,
        "to_longitude": 105.81797669479198,
        "from_longitude": 105.81814030954511,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8025,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81780235120792,
          21.00414185721305,
          105.81797669479198,
          21.00433716817565
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81797669479198,
            21.00414185721305
          ],
          [
            105.81780235120792,
            21.00433716817565
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "25",
        "diemCuoi": "26",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.00414185721305,
        "to_latitude": 21.00433716817565,
        "to_longitude": 105.81780235120792,
        "from_longitude": 105.81797669479198,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8026,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81765482971022,
          21.00433716817565,
          105.81780235120792,
          21.00448991118703
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81780235120792,
            21.00433716817565
          ],
          [
            105.81765482971022,
            21.00448991118703
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Đường Láng",
        "diemDau": "26",
        "diemCuoi": "27",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.00433716817565,
        "to_latitude": 21.00448991118703,
        "to_longitude": 105.81765482971022,
        "from_longitude": 105.81780235120792,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8027,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82105536777733,
          21.002842751103184,
          105.82121495921884,
          21.002884067264503
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82105536777733,
            21.002884067264503
          ],
          [
            105.82121495921884,
            21.002842751103184
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 18,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trường Chinh",
        "diemDau": "",
        "diemCuoi": "09",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.002884067264503,
        "to_latitude": 21.002842751103184,
        "to_longitude": 105.82121495921884,
        "from_longitude": 105.82105536777733,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8028,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82121495921884,
          21.00276011874544,
          105.82151402551682,
          21.002842751103184
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82121495921884,
            21.002842751103184
          ],
          [
            105.82151402551682,
            21.00276011874544
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trường Chinh",
        "diemDau": "09",
        "diemCuoi": "10",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.002842751103184,
        "to_latitude": 21.00276011874544,
        "to_longitude": 105.82151402551682,
        "from_longitude": 105.82121495921884,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8029,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82151402551682,
          21.002676234336043,
          105.8218171151354,
          21.00276011874544
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82151402551682,
            21.00276011874544
          ],
          [
            105.8218171151354,
            21.002676234336043
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trường Chinh",
        "diemDau": "10",
        "diemCuoi": "11",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.00276011874544,
        "to_latitude": 21.002676234336043,
        "to_longitude": 105.8218171151354,
        "from_longitude": 105.82151402551682,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8030,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82069729288209,
          21.00269751844444,
          105.8210526855696,
          21.00275636272736
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8210526855696,
            21.00269751844444
          ],
          [
            105.82083676774755,
            21.00275636272736
          ],
          [
            105.82069729288209,
            21.0027513547048
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trường Chinh",
        "diemDau": "17",
        "diemCuoi": "18",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.00269751844444,
        "to_latitude": 21.0027513547048,
        "to_longitude": 105.82069729288209,
        "from_longitude": 105.8210526855696,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8031,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8210526855696,
          21.002621146040614,
          105.82133431751724,
          21.00269751844444
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8210526855696,
            21.00269751844444
          ],
          [
            105.82133431751724,
            21.002621146040614
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 32,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trường Chinh",
        "diemDau": "17",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.00269751844444,
        "to_latitude": 21.002621146040614,
        "to_longitude": 105.82133431751724,
        "from_longitude": 105.8210526855696,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8032,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82133431751724,
          21.002535038536365,
          105.82166162743323,
          21.002621146040614
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82133431751724,
            21.002621146040614
          ],
          [
            105.82166162743323,
            21.002535038536365
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trường Chinh",
        "diemDau": "16",
        "diemCuoi": "15",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.002621146040614,
        "to_latitude": 21.002535038536365,
        "to_longitude": 105.82166162743323,
        "from_longitude": 105.82133431751724,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8033,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82166162743323,
          21.002458385051042,
          105.82195793111372,
          21.002535038536365
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82166162743323,
            21.002535038536365
          ],
          [
            105.82195793111372,
            21.002458385051042
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trường Chinh",
        "diemDau": "15",
        "diemCuoi": "14",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.002535038536365,
        "to_latitude": 21.002458385051042,
        "to_longitude": 105.82195793111372,
        "from_longitude": 105.82166162743323,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8034,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82195793111372,
          21.002378256498062,
          105.82227174957224,
          21.002458385051042
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82195793111372,
            21.002458385051042
          ],
          [
            105.82227174957224,
            21.002378256498062
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trường Chinh",
        "diemDau": "14",
        "diemCuoi": "13",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.002458385051042,
        "to_latitude": 21.002378256498062,
        "to_longitude": 105.82227174957224,
        "from_longitude": 105.82195793111372,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8035,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82227174957224,
          21.002301883931683,
          105.8225748391908,
          21.002378256498062
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82227174957224,
            21.002378256498062
          ],
          [
            105.8225748391908,
            21.002301883931683
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trường Chinh",
        "diemDau": "13",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.002378256498062,
        "to_latitude": 21.002301883931683,
        "to_longitude": 105.8225748391908,
        "from_longitude": 105.82227174957224,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8036,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82102452237034,
          21.00298547961238,
          105.82137186844359,
          21.003051835802463
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82137186844359,
            21.00298547961238
          ],
          [
            105.82102452237034,
            21.003051835802463
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trường Chinh",
        "diemDau": "08",
        "diemCuoi": "07",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.00298547961238,
        "to_latitude": 21.003051835802463,
        "to_longitude": 105.82102452237034,
        "from_longitude": 105.82137186844359,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8037,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82137186844359,
          21.002915367378712,
          105.82167227585445,
          21.00298547961238
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82137186844359,
            21.00298547961238
          ],
          [
            105.82167227585445,
            21.002915367378712
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trường Chinh",
        "diemDau": "07",
        "diemCuoi": "06",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.00298547961238,
        "to_latitude": 21.002915367378712,
        "to_longitude": 105.82167227585445,
        "from_longitude": 105.82137186844359,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8038,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82167227585445,
          21.002831483056603,
          105.82200218755942,
          21.002915367378712
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82167227585445,
            21.002915367378712
          ],
          [
            105.82200218755942,
            21.002831483056603
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 37,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trường Chinh",
        "diemDau": "06",
        "diemCuoi": "05",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.002915367378712,
        "to_latitude": 21.002831483056603,
        "to_longitude": 105.82200218755942,
        "from_longitude": 105.82167227585445,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8039,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82200218755942,
          21.0027538587161,
          105.82230259497025,
          21.002831483056603
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82200218755942,
            21.002831483056603
          ],
          [
            105.82230259497025,
            21.0027538587161
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 34,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trường Chinh",
        "diemDau": "05",
        "diemCuoi": "04",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.002831483056603,
        "to_latitude": 21.0027538587161,
        "to_longitude": 105.82230259497025,
        "from_longitude": 105.82200218755942,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8040,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82230259497025,
          21.002647438183924,
          105.82261641342878,
          21.0027538587161
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82230259497025,
            21.0027538587161
          ],
          [
            105.82261641342878,
            21.002647438183924
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 36,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trường Chinh",
        "diemDau": "04",
        "diemCuoi": "03",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.0027538587161,
        "to_latitude": 21.002647438183924,
        "to_longitude": 105.82261641342878,
        "from_longitude": 105.82230259497025,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8041,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82261641342878,
          21.00251472541297,
          105.82288597543261,
          21.002647438183924
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82261641342878,
            21.002647438183924
          ],
          [
            105.82288597543261,
            21.00251472541297
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trường Chinh",
        "diemDau": "03",
        "diemCuoi": "02",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.002647438183924,
        "to_latitude": 21.00251472541297,
        "to_longitude": 105.82288597543261,
        "from_longitude": 105.82261641342878,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8042,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82288597543261,
          21.002358224353518,
          105.82308311779147,
          21.00251472541297
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82288597543261,
            21.00251472541297
          ],
          [
            105.82304422576125,
            21.002407052701287
          ],
          [
            105.82308311779147,
            21.002358224353518
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trường Chinh",
        "diemDau": "02",
        "diemCuoi": "01",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.00251472541297,
        "to_latitude": 21.002358224353518,
        "to_longitude": 105.82308311779147,
        "from_longitude": 105.82288597543261,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8043,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82040543502526,
          21.003269997223516,
          105.82053149885202,
          21.00333682278951
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82053149885202,
            21.00333682278951
          ],
          [
            105.82044214772465,
            21.003269997223516
          ],
          [
            105.82040543502526,
            21.00331303476874
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "45",
        "diemCuoi": "47",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.00333682278951,
        "to_latitude": 21.00331303476874,
        "to_longitude": 105.82040543502526,
        "from_longitude": 105.82053149885202,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8044,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82044214772465,
          21.003229150669952,
          105.82047785466708,
          21.003269997223516
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82044214772465,
            21.003269997223516
          ],
          [
            105.82047785466708,
            21.003229150669952
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "46",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.003269997223516,
        "to_latitude": 21.003229150669952,
        "to_longitude": 105.82047785466708,
        "from_longitude": 105.82044214772465,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8045,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81837718206236,
          21.00179732320789,
          105.81859896728457,
          21.001920803023875
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81859896728457,
            21.001920803023875
          ],
          [
            105.81837718206236,
            21.00179732320789
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": "",
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Trãi",
        "diemDau": "06",
        "diemCuoi": "6-1",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.001920803023875,
        "to_latitude": 21.00179732320789,
        "to_longitude": 105.81837718206236,
        "from_longitude": 105.81859896728457,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8046,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81813578324878,
          21.001627049228663,
          105.81837718206236,
          21.00179732320789
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81837718206236,
            21.00179732320789
          ],
          [
            105.81813578324878,
            21.001627049228663
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Trãi",
        "diemDau": "6-1",
        "diemCuoi": "6-2",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.00179732320789,
        "to_latitude": 21.001627049228663,
        "to_longitude": 105.81813578324878,
        "from_longitude": 105.81837718206236,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8047,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81787158566941,
          21.001449262955013,
          105.81813578324878,
          21.001627049228663
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81813578324878,
            21.001627049228663
          ],
          [
            105.81787158566941,
            21.001449262955013
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Trãi",
        "diemDau": "6-2",
        "diemCuoi": "6-3",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.001627049228663,
        "to_latitude": 21.001449262955013,
        "to_longitude": 105.81787158566941,
        "from_longitude": 105.81813578324878,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8048,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81763957458296,
          21.001294012795785,
          105.81787158566941,
          21.001449262955013
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.81787158566941,
            21.001449262955013
          ],
          [
            105.81763957458296,
            21.001294012795785
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Nguyễn Trãi",
        "diemDau": "6-3",
        "diemCuoi": "6-4",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.001449262955013,
        "to_latitude": 21.001294012795785,
        "to_longitude": 105.81763957458296,
        "from_longitude": 105.81787158566941,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8049,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82301975060439,
          21.005927346717932,
          105.82329467703269,
          21.00622110263231
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82306748704842,
            21.00622110263231
          ],
          [
            105.82301975060439,
            21.00605379656306
          ],
          [
            105.82329467703269,
            21.005927346717932
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 205 Tây Sơn",
        "diemDau": "31",
        "diemCuoi": "31-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.00622110263231,
        "to_latitude": 21.005927346717932,
        "to_longitude": 105.82329467703269,
        "from_longitude": 105.82306748704842,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8050,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82329467703269,
          21.005770849237383,
          105.82354546357335,
          21.005927346717932
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82329467703269,
            21.005927346717932
          ],
          [
            105.82330674697651,
            21.005873511603372
          ],
          [
            105.82354546357335,
            21.005770849237383
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVNTS2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 205 Tây Sơn",
        "diemDau": "31-1",
        "diemCuoi": "31-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV NTS Tủ 2",
        "from_latitude": 21.005927346717932,
        "to_latitude": 21.005770849237383,
        "to_longitude": 105.82354546357335,
        "from_longitude": 105.82329467703269,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8051,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82132811492869,
          21.003709918599803,
          105.82159683873249,
          21.00392541856171
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82132811492869,
            21.003709918599803
          ],
          [
            105.82159683873249,
            21.00392541856171
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 317 Tây Sơn",
        "diemDau": "22",
        "diemCuoi": "22-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.003709918599803,
        "to_latitude": 21.00392541856171,
        "to_longitude": 105.82159683873249,
        "from_longitude": 105.82132811492869,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8052,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82159683873249,
          21.003712579091516,
          105.82185969520799,
          21.00392541856171
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82185969520799,
            21.003712579091516
          ],
          [
            105.82159683873249,
            21.00392541856171
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 317 Tây Sơn",
        "diemDau": "22",
        "diemCuoi": "22-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.003712579091516,
        "to_latitude": 21.00392541856171,
        "to_longitude": 105.82159683873249,
        "from_longitude": 105.82185969520799,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8053,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82185969520799,
          21.003712579091516,
          105.8221118228615,
          21.00389537065501
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82185969520799,
            21.003712579091516
          ],
          [
            105.8221118228615,
            21.00389537065501
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 38,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 317 Tây Sơn",
        "diemDau": "22-1",
        "diemCuoi": "22-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.003712579091516,
        "to_latitude": 21.00389537065501,
        "to_longitude": 105.8221118228615,
        "from_longitude": 105.82185969520799,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8054,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82168266941618,
          21.00345216332772,
          105.82175777126889,
          21.003619931227878
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82175777126889,
            21.003619931227878
          ],
          [
            105.82168266941618,
            21.00352728330588
          ],
          [
            105.82174972464566,
            21.00345216332772
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 317 Tây Sơn",
        "diemDau": "22-3",
        "diemCuoi": "22-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.003619931227878,
        "to_latitude": 21.00345216332772,
        "to_longitude": 105.82174972464566,
        "from_longitude": 105.82175777126889,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8055,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8215834276848,
          21.003279387233263,
          105.82174972464566,
          21.00345216332772
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82174972464566,
            21.00345216332772
          ],
          [
            105.8215834276848,
            21.003279387233263
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 317 Tây Sơn",
        "diemDau": "22-3",
        "diemCuoi": "22-4",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.00345216332772,
        "to_latitude": 21.003279387233263,
        "to_longitude": 105.8215834276848,
        "from_longitude": 105.82174972464566,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8056,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82136080433442,
          21.003076562867193,
          105.8215834276848,
          21.003279387233263
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8215834276848,
            21.003279387233263
          ],
          [
            105.82149759699215,
            21.00324683519346
          ],
          [
            105.82136080433442,
            21.003076562867193
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 35,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 317 Tây Sơn",
        "diemDau": "22-5",
        "diemCuoi": "22-6",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.003279387233263,
        "to_latitude": 21.003076562867193,
        "to_longitude": 105.82136080433442,
        "from_longitude": 105.8215834276848,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8057,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82175777126889,
          21.003619931227878,
          105.82185969520799,
          21.003712579091516
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82185969520799,
            21.003712579091516
          ],
          [
            105.82175777126889,
            21.003619931227878
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 16,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 317 Tây Sơn",
        "diemDau": "22-1",
        "diemCuoi": "22-3",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.003712579091516,
        "to_latitude": 21.003619931227878,
        "to_longitude": 105.82175777126889,
        "from_longitude": 105.82185969520799,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8058,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82167227585445,
          21.002915367378712,
          105.82170110960114,
          21.003060599827123
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82167227585445,
            21.002915367378712
          ],
          [
            105.82170110960114,
            21.003060599827123
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 612 Trường Trinh",
        "diemDau": "06",
        "diemCuoi": "6-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.002915367378712,
        "to_latitude": 21.003060599827123,
        "to_longitude": 105.82170110960114,
        "from_longitude": 105.82167227585445,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8059,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82164612432446,
          21.003060599827123,
          105.82170110960114,
          21.00314198001572
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82170110960114,
            21.003060599827123
          ],
          [
            105.82164612432446,
            21.003101915928138
          ],
          [
            105.82167294641086,
            21.00314198001572
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x6+1x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 317 Tây Sơn",
        "diemDau": "6-1",
        "diemCuoi": "6-2",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.003060599827123,
        "to_latitude": 21.00314198001572,
        "to_longitude": 105.82167294641086,
        "from_longitude": 105.82170110960114,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8060,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81919860859206,
          21.00357845877199,
          105.8192292864004,
          21.003795523921436
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8192292864004,
            21.00357845877199
          ],
          [
            105.81919860859206,
            21.003627756218677
          ],
          [
            105.81922006626299,
            21.003795523921436
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "2x4",
        "kieuLapDat": "Treo",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Ngõ 58 Đường Láng",
        "diemDau": "35",
        "diemCuoi": "35-1",
        "chuDauTu": "UBNDQuanDongDa",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.00357845877199,
        "to_latitude": 21.003795523921436,
        "to_longitude": 105.81922006626299,
        "from_longitude": 105.8192292864004,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8061,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82505636695446,
          21.010207547223665,
          105.8252240050102,
          21.010515524952055
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82505636695446,
            21.010207547223665
          ],
          [
            105.8252240050102,
            21.010515524952055
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 33,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CĐ2",
        "from_latitude": 21.010207547223665,
        "to_latitude": 21.010515524952055,
        "to_longitude": 105.8252240050102,
        "from_longitude": 105.82505636695446,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8062,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8252240050102,
          21.010362788109145,
          105.82553648236485,
          21.010515524952055
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8252240050102,
            21.010515524952055
          ],
          [
            105.82553648236485,
            21.010362788109145
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CĐ2",
        "from_latitude": 21.010515524952055,
        "to_latitude": 21.010362788109145,
        "to_longitude": 105.82553648236485,
        "from_longitude": 105.8252240050102,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8063,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82479887488546,
          21.009740571651378,
          105.82495444300635,
          21.01001599977995
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82479887488546,
            21.009740571651378
          ],
          [
            105.82495444300635,
            21.01001599977995
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CĐ2",
        "from_latitude": 21.009740571651378,
        "to_latitude": 21.01001599977995,
        "to_longitude": 105.82495444300635,
        "from_longitude": 105.82479887488546,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8064,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82495444300635,
          21.009863262426443,
          105.82526289704042,
          21.01001599977995
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82495444300635,
            21.01001599977995
          ],
          [
            105.82526289704042,
            21.009863262426443
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 30,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CĐ2",
        "from_latitude": 21.01001599977995,
        "to_latitude": 21.009863262426443,
        "to_longitude": 105.82526289704042,
        "from_longitude": 105.82495444300635,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8065,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82537554981947,
          21.010063573677968,
          105.82553648236485,
          21.010362788109145
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82537554981947,
            21.010063573677968
          ],
          [
            105.82553648236485,
            21.010362788109145
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CĐ2",
        "from_latitude": 21.010063573677968,
        "to_latitude": 21.010362788109145,
        "to_longitude": 105.82553648236485,
        "from_longitude": 105.82537554981947,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8066,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82505636695446,
          21.010207547223665,
          105.82530581239125,
          21.01028641963031
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82505636695446,
            21.010207547223665
          ],
          [
            105.82530581239125,
            21.01028641963031
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CĐ2",
        "from_latitude": 21.010207547223665,
        "to_latitude": 21.01028641963031,
        "to_longitude": 105.82530581239125,
        "from_longitude": 105.82505636695446,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8067,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82479887488546,
          21.009740571651378,
          105.82505368473774,
          21.00982194819762
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82505368473774,
            21.00982194819762
          ],
          [
            105.82479887488546,
            21.009740571651378
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CĐ2",
        "from_latitude": 21.00982194819762,
        "to_latitude": 21.009740571651378,
        "to_longitude": 105.82479887488546,
        "from_longitude": 105.82505368473774,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8068,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82512208107109,
          21.009595345706362,
          105.82526289704042,
          21.009863262426443
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82526289704042,
            21.009863262426443
          ],
          [
            105.82512208107109,
            21.009595345706362
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 29,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CĐ2",
        "from_latitude": 21.009863262426443,
        "to_latitude": 21.009595345706362,
        "to_longitude": 105.82512208107109,
        "from_longitude": 105.82526289704042,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8069,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82479887488546,
          21.009570306736148,
          105.82495980742186,
          21.009740571651378
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82479887488546,
            21.009740571651378
          ],
          [
            105.82495041969476,
            21.00967046259179
          ],
          [
            105.8249128687684,
            21.009589085963785
          ],
          [
            105.82495980742186,
            21.009570306736148
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CĐ2",
        "chieuDaiQL": 27,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CĐ2",
        "from_latitude": 21.009740571651378,
        "to_latitude": 21.009570306736148,
        "to_longitude": 105.82495980742186,
        "from_longitude": 105.82479887488546,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8070,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8240223754028,
          21.007840103707537,
          105.82408674841555,
          21.007967803893965
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8240223754028,
            21.007840103707537
          ],
          [
            105.82408674841555,
            21.007967803893965
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.007840103707537,
        "to_latitude": 21.007967803893965,
        "to_longitude": 105.82408674841555,
        "from_longitude": 105.8240223754028,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8071,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82408674841555,
          21.007967803893965,
          105.8241511214373,
          21.008093000048607
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82408674841555,
            21.007967803893965
          ],
          [
            105.8241511214373,
            21.008093000048607
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.007967803893965,
        "to_latitude": 21.008093000048607,
        "to_longitude": 105.8241511214373,
        "from_longitude": 105.82408674841555,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8072,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8241511214373,
          21.008093000048607,
          105.8242128122423,
          21.008225707857605
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8241511214373,
            21.008093000048607
          ],
          [
            105.8242128122423,
            21.008225707857605
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.008093000048607,
        "to_latitude": 21.008225707857605,
        "to_longitude": 105.8242128122423,
        "from_longitude": 105.8241511214373,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8073,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.8242128122423,
          21.008225707857605,
          105.82428523188727,
          21.008368431218003
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8242128122423,
            21.008225707857605
          ],
          [
            105.82428523188727,
            21.008368431218003
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 21,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.008225707857605,
        "to_latitude": 21.008368431218003,
        "to_longitude": 105.82428523188727,
        "from_longitude": 105.8242128122423,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8074,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82428523188727,
          21.008368431218003,
          105.82435228710777,
          21.00851616227281
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82428523188727,
            21.008368431218003
          ],
          [
            105.82435228710777,
            21.00851616227281
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.008368431218003,
        "to_latitude": 21.00851616227281,
        "to_longitude": 105.82435228710777,
        "from_longitude": 105.82428523188727,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8075,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82435228710777,
          21.00851616227281,
          105.8244166601295,
          21.008648869705404
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82435228710777,
            21.00851616227281
          ],
          [
            105.8244166601295,
            21.008648869705404
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 14,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.00851616227281,
        "to_latitude": 21.008648869705404,
        "to_longitude": 105.8244166601295,
        "from_longitude": 105.82435228710777,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8076,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82417526131597,
          21.00782007229622,
          105.82425572758416,
          21.007980323514094
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82417526131597,
            21.00782007229622
          ],
          [
            105.82425572758416,
            21.007980323514094
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 28,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.00782007229622,
        "to_latitude": 21.007980323514094,
        "to_longitude": 105.82425572758416,
        "from_longitude": 105.82417526131597,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8077,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82425572758416,
          21.007980323514094,
          105.82432010060589,
          21.008123047110058
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82425572758416,
            21.007980323514094
          ],
          [
            105.82432010060589,
            21.008123047110058
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.007980323514094,
        "to_latitude": 21.008123047110058,
        "to_longitude": 105.82432010060589,
        "from_longitude": 105.82425572758416,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8078,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82432010060589,
          21.008123047110058,
          105.82439252024187,
          21.008278290163773
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82432010060589,
            21.008123047110058
          ],
          [
            105.82439252024187,
            21.008278290163773
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.008123047110058,
        "to_latitude": 21.008278290163773,
        "to_longitude": 105.82439252024187,
        "from_longitude": 105.82432010060589,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8079,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82439252024187,
          21.008278290163773,
          105.82445957547135,
          21.00843353305677
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82439252024187,
            21.008278290163773
          ],
          [
            105.82445957547135,
            21.00843353305677
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.008278290163773,
        "to_latitude": 21.00843353305677,
        "to_longitude": 105.82445957547135,
        "from_longitude": 105.82439252024187,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8080,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82391776924695,
          21.007937756801272,
          105.823968731221,
          21.00808548828167
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82391776924695,
            21.007937756801272
          ],
          [
            105.8239553201733,
            21.008015378445133
          ],
          [
            105.82393654471014,
            21.008022890214757
          ],
          [
            105.823968731221,
            21.00808548828167
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.007937756801272,
        "to_latitude": 21.00808548828167,
        "to_longitude": 105.823968731221,
        "from_longitude": 105.82391776924695,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8081,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.823968731221,
          21.00808548828167,
          105.82404651528148,
          21.00822821177705
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.823968731221,
            21.00808548828167
          ],
          [
            105.82404651528148,
            21.00822821177705
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.00808548828167,
        "to_latitude": 21.00822821177705,
        "to_longitude": 105.82404651528148,
        "from_longitude": 105.823968731221,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8082,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82403402038551,
          21.008205285299614,
          105.82411625271867,
          21.008360919465773
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82403402038551,
            21.008205285299614
          ],
          [
            105.82411625271867,
            21.008360919465773
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.008205285299614,
        "to_latitude": 21.008360919465773,
        "to_longitude": 105.82411625271867,
        "from_longitude": 105.82403402038551,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8083,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82411625271867,
          21.008360919465773,
          105.82418599014692,
          21.008491123121463
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82411625271867,
            21.008360919465773
          ],
          [
            105.82418599014692,
            21.008491123121463
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 20,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.008360919465773,
        "to_latitude": 21.008491123121463,
        "to_longitude": 105.82418599014692,
        "from_longitude": 105.82411625271867,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8084,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82418599014692,
          21.008491123121463,
          105.82424231654544,
          21.008595035572796
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82418599014692,
            21.008491123121463
          ],
          [
            105.82424231654544,
            21.008595035572796
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 19,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.008491123121463,
        "to_latitude": 21.008595035572796,
        "to_longitude": 105.82424231654544,
        "from_longitude": 105.82418599014692,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8085,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82384132629936,
          21.007473278056505,
          105.8239325214075,
          21.00764604929503
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.8239325214075,
            21.00764604929503
          ],
          [
            105.82384132629936,
            21.007473278056505
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 25,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.00764604929503,
        "to_latitude": 21.007473278056505,
        "to_longitude": 105.82384132629936,
        "from_longitude": 105.8239325214075,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8086,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82376305599611,
          21.007314664757843,
          105.82384132629936,
          21.007473278056505
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82384132629936,
            21.007473278056505
          ],
          [
            105.82376305599611,
            21.007314664757843
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.007473278056505,
        "to_latitude": 21.007314664757843,
        "to_longitude": 105.82376305599611,
        "from_longitude": 105.82384132629936,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8087,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82367234713078,
          21.00713900270146,
          105.82376305599611,
          21.007314664757843
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82376305599611,
            21.007314664757843
          ],
          [
            105.82367234713078,
            21.00713900270146
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 22,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.007314664757843,
        "to_latitude": 21.00713900270146,
        "to_longitude": 105.82367234713078,
        "from_longitude": 105.82376305599611,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8088,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82354896551175,
          21.006956215111387,
          105.82367234713078,
          21.00713900270146
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82367234713078,
            21.00713900270146
          ],
          [
            105.82354896551175,
            21.006956215111387
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "ATH",
        "chieuDaiQL": 24,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "ATH",
        "from_latitude": 21.00713900270146,
        "to_latitude": 21.006956215111387,
        "to_longitude": 105.82354896551175,
        "from_longitude": 105.82367234713078,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8089,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82368844038618,
          21.007490805582623,
          105.82378365880597,
          21.00766733269457
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82378365880597,
            21.00766733269457
          ],
          [
            105.8237554956067,
            21.007608490347412
          ],
          [
            105.82373001462416,
            21.007608490347412
          ],
          [
            105.82369648700941,
            21.007547144045375
          ],
          [
            105.82370587474551,
            21.007525860628682
          ],
          [
            105.82368844038618,
            21.007490805582623
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng bọc",
        "tietDien": "4x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CTK",
        "chieuDaiQL": 26,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Phố Tây Sơn",
        "diemDau": "",
        "diemCuoi": "",
        "chuDauTu": "SoXayDung",
        "tuDien2": "Cầu Trung Kính",
        "from_latitude": 21.00766733269457,
        "to_latitude": 21.007490805582623,
        "to_longitude": 105.82368844038618,
        "from_longitude": 105.82378365880597,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8090,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.81995365042178,
          21.00337610431299,
          105.82005289219808,
          21.003381738312896
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82005289219808,
            21.00337610431299
          ],
          [
            105.81995365042178,
            21.003381738312896
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn hoa Ngã Tư Sở",
        "diemDau": "tủ phân đoạn",
        "diemCuoi": "16",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.00337610431299,
        "to_latitude": 21.003381738312896,
        "to_longitude": 105.81995365042178,
        "from_longitude": 105.82005289219808,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8091,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82005289219808,
          21.002692510419894,
          105.82048741001397,
          21.00337610431299
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82043510696302,
            21.002692510419894
          ],
          [
            105.82048741001397,
            21.00308250988194
          ],
          [
            105.82012397069425,
            21.003252782200608
          ],
          [
            105.82005289219808,
            21.00337610431299
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": 110,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn hoa Ngã Tư Sở",
        "diemDau": "tủ điện",
        "diemCuoi": "tủ phân đoạn",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.002692510419894,
        "to_latitude": 21.00337610431299,
        "to_longitude": 105.82005289219808,
        "from_longitude": 105.82043510696302,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8092,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82046729344694,
          21.00270816049752,
          105.82105536777733,
          21.002884067264503
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82046729344694,
            21.00270816049752
          ],
          [
            105.82080659288799,
            21.002733200619257
          ],
          [
            105.82105536777733,
            21.002884067264503
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x25+1x16",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 70,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trường Chinh",
        "diemDau": "tủ điện",
        "diemCuoi": "tủ phân đoạn",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.00270816049752,
        "to_latitude": 21.002884067264503,
        "to_longitude": 105.82105536777733,
        "from_longitude": 105.82046729344694,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8093,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82100507635074,
          21.002884067264503,
          105.82105536777733,
          21.003051835802463
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82105536777733,
            21.002884067264503
          ],
          [
            105.82100507635074,
            21.002892205295314
          ],
          [
            105.82102452237034,
            21.003051835802463
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trường Chinh",
        "diemDau": "tủ phân đoạn",
        "diemCuoi": "NTS3 08",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.002884067264503,
        "to_latitude": 21.003051835802463,
        "to_longitude": 105.82102452237034,
        "from_longitude": 105.82105536777733,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8094,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82101044077521,
          21.00269751844444,
          105.82105536777733,
          21.002884067264503
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82105536777733,
            21.002884067264503
          ],
          [
            105.82101044077521,
            21.002726940589646
          ],
          [
            105.8210526855696,
            21.00269751844444
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x16+1x10",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS3",
        "chieuDaiQL": 23,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Trường Chinh",
        "diemDau": "tủ phân đoạn",
        "diemCuoi": "17",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS tủ 3",
        "from_latitude": 21.002884067264503,
        "to_latitude": 21.00269751844444,
        "to_longitude": 105.8210526855696,
        "from_longitude": 105.82105536777733,
        "color": "#B4E5F4"
      }
    },
    {
      "id": 8095,
      "type": "Feature",
      "geometry": {
        "bbox": [
          105.82066510637121,
          21.003085013887702,
          105.82068455238182,
          21.00312194797285
        ],
        "type": "LineString",
        "coordinates": [
          [
            105.82068455238182,
            21.003085013887702
          ],
          [
            105.82066510637121,
            21.00312194797285
          ]
        ]
      },
      "properties": {
        "loaiCap": "Đồng Bọc",
        "tietDien": "3x10+1x6",
        "kieuLapDat": "Ngầm",
        "ngayLapDat": "",
        "tuDien": "CVNTS1",
        "chieuDaiQL": 10,
        "doiQuanLy": "Đội Đống Đa",
        "tenDuongPh": "Vườn hoa Ngã Tư Sở",
        "diemDau": "tủ phân đoạn",
        "diemCuoi": "12",
        "chuDauTu": "SoXayDung",
        "tuDien2": "CV NTS Tủ 1",
        "from_latitude": 21.003085013887702,
        "to_latitude": 21.00312194797285,
        "to_longitude": 105.82066510637121,
        "from_longitude": 105.82068455238182,
        "color": "#B4E5F4"
      }
    }
  ]
}