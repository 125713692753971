export const accidentData = [
    {
      "id": 14202,
      "type": "Traffic Bulletin",
      "traffic_type": "Accident",
      "created_at": 1696220167,
      "updated_at": 1696299018,
      "lat": 20.979656,
      "lon": 105.78557,
      "osm_combination_id": "way955842609",
      "ai_road_id": "ai_road_id_other",
      "name": "Phố Vũ Trọng Khánh",
      "sta_cty": "Hà Nội",
      "content": "Đoạn đường này đang xảy ra tai nạn",
      "forever": true,
      "audio_link": "admin/text_to_speech/fixed_audio/accident.mp3",
      "expire_time": null,
      "image": [
        "admin/realtime_traffic/1696299016237.jpg",
        "admin/realtime_traffic/1696299016323.jpg",
        "admin/realtime_traffic/1696299016361.jpg"
      ],
      "geometry": null,
      "grid_lat_center": null,
      "grid_lon_center": null,
      "grid_zoom": null,
      "congestion_level": null,
      "updated_by": null,
      "bearing": 132.84303
    },
    {
      "id": 31099,
      "type": "Traffic Bulletin",
      "traffic_type": "Accident",
      "created_at": 1696333095,
      "updated_at": 1696333095,
      "lat": 20.992453,
      "lon": 105.78604,
      "osm_combination_id": "way600057058",
      "ai_road_id": "ai_road_id_other",
      "name": "Phố Tố Hữu",
      "sta_cty": "Hà Nội",
      "content": "Đoạn đường này đang xảy ra tai nạn",
      "forever": true,
      "audio_link": "admin/text_to_speech/fixed_audio/accident.mp3",
      "expire_time": null,
      "image": [],
      "geometry": null,
      "grid_lat_center": null,
      "grid_lon_center": null,
      "grid_zoom": null,
      "congestion_level": null,
      "updated_by": null,
      "bearing": 63.8366
    }
  ]