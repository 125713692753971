import React, { useState } from 'react';
import { MAPBOX_TOKEN, initialViewState, layerServiceWaterLineStyle, layerDistributionWaterLineStyle } from '../../constant';
import Map, { Layer, Source } from 'react-map-gl';
import '../../constant/pulsing.css';
import "mapbox-gl/dist/mapbox-gl.css";
import { Row, Switch } from 'antd';
import { distributionWaterLineData } from '../../data/distributionWaterLineData';
import { serviceWaterLineData } from '../../data/serviceWaterLineData';



export default function WaterSystem() {

  const mapRef = React.useRef(null);
  const [hoverInfo, setHoverInfo] = useState();
  const [isClick, setIsClick] = useState(false);

  const [activeKey, setActiveKey] = React.useState({
    svWaterLine: true,
    disWaterLine: true,
  });
  const [activeFeatureId, setActiveFeatureId] = useState(null);

  const mapInstance = mapRef?.current; 

  const onHover = (event => {
    const {
      features,
      point: { x, y }
    } = event;
    const hoveredFeature = features && features[0];
    if (!isClick) {
      hoveredFeature && setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y, layer: hoveredFeature.layer.id });
      if (hoveredFeature && mapInstance) {
        mapInstance?.setFeatureState(
          { source: "serviceWaterLine", id: activeFeatureId },
          { hover: false }
        );
        mapInstance?.setFeatureState(
          { source: "distributionWaterLine", id: activeFeatureId },
          { hover: false }
        );
      
        setActiveFeatureId(hoveredFeature.id);
        if (hoveredFeature?.layer?.id === 'serviceWaterLine') {

        mapInstance?.setFeatureState(
            { source: "serviceWaterLine", id: hoveredFeature.id },
            { hover: true }
          );
        }
        else if (hoveredFeature?.layer?.id === 'distributionWaterLine') {
          mapInstance?.setFeatureState(
            { source: "distributionWaterLine", id: hoveredFeature.id },
            { hover: true }
          );
        } 
      } else {

     
        mapInstance?.setFeatureState(
          { source: 'serviceWaterLine', id: activeFeatureId },
          { hover: false }
        );
        
        mapInstance?.setFeatureState(
          { source: 'distributionWaterLine', id: activeFeatureId },
          { hover: false }
        )
        setActiveFeatureId(null);
        setHoverInfo(null);
      }
    }
  });
  const onClick = (event => {
    const {
      features,
      point: { x, y }, layerId
    } = event;
    const newMarker = {
        lat: event.lngLat.lat,
        lon: event.lngLat.lng,
    };
    const hoveredFeature = features && features[0];
    if (hoveredFeature) {
      setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y, layer: hoveredFeature.layer.id });
      setIsClick(true);
    } else {
      setHoverInfo(null);
      setIsClick(false);
    }
  });
  return (
    <div style={{ overflowY: 'hidden', width: '100vw', height: '100vh' }}>

      <Map
        initialViewState={{...initialViewState, pitch:0, latitude: 21.017718245945105, longitude: 105.82082299926839, zoom: 15} }
        mapStyle={`mapbox://styles/mapbox/standard-beta`}
        ref={mapRef}
        mapboxAccessToken={MAPBOX_TOKEN}
        attributionControl={false}
        minZoom={5}
        onClick={onClick}
        onMouseMove={onHover}
        interactiveLayerIds={['serviceWaterLine', 'distributionWaterLine']}
        onLoad={() => {
          mapRef.current?.setConfigProperty('basemap', 'lightPreset', 'night')
          mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);

        }}
        style={{ width: '100vw', height: '100vh' }}
      >
        {serviceWaterLineData && activeKey.svWaterLine && <Source id='serviceWaterLine' type="geojson" data={serviceWaterLineData}>
          <Layer {...layerServiceWaterLineStyle} >
          </Layer>
        </Source>}
        {distributionWaterLineData && activeKey.disWaterLine && <Source id='distributionWaterLine' type="geojson" data={distributionWaterLineData}>
          <Layer {...layerDistributionWaterLineStyle} >
          </Layer>
        </Source>}
       
        {hoverInfo &&
          (<div className="tooltip" style={{
            left: hoverInfo.x, top: hoverInfo.y, position: 'absolute',
            margin: '8px',
            padding: '12px',
            background: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            maxWidth: '300px',
            fontSize: '14px',
            zIndex: '9',
            pointerEvents: 'none'
          }}>
            <h3>{hoverInfo.layer === 'serviceWaterLine'
              ? 'Ống dịch vụ'
              : hoverInfo.layer === 'distributionWaterLine'
                ? 'Ống phân phối'
                : ''}</h3>

            {hoverInfo.feature.properties.tenDoiTuon && <div>Tên đối tượng: {hoverInfo.feature.properties.tenDoiTuon}</div>}
            {hoverInfo.feature.properties.chatLieu && <div>Chất liệu: {hoverInfo.feature.properties.chatLieu}</div>}
            {hoverInfo.feature.properties.duongKinh && <div>Đường kính: {hoverInfo.feature.properties.duongKinh}</div>}
            {hoverInfo.feature.properties.chieudaiQL && <div>Chiều dài: {hoverInfo.feature.properties.chieudaiQL}</div>}
            {hoverInfo.feature.properties.diaChiLapD && <div>Địa chỉ lắp đặt: {hoverInfo.feature.properties.diaChiLapD}</div>}
          </div>)
        }
      </Map>
      <Row className='tabbar-hover' style={{
        position: 'absolute', bottom: 10, zIndex: 20,
        left: '50%', alignItems: 'center',
        transform: 'translate(-50%, -50%)', borderRadius: 8,
      }}>
        <Row style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 8, paddingRight: 8,
        }}>
          <p style={{ color: 'white', fontSize: 16, fontWeight: 'bold', paddingRight: 8 }}>Ống dịch vụ</p>
          <Switch defaultChecked onChange={(checked) => setActiveKey({ ...activeKey, svWaterLine: checked })} ></Switch>
        </Row>
        <div style={{ width: 1.5, backgroundColor: 'white', height: 32 }}></div>
        <Row style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 8, paddingRight: 8,
        }}>
          <p style={{ color: 'white', fontSize: 16, fontWeight: 'bold', paddingRight: 8 }}>Ống phân phối</p>
          <Switch defaultChecked onChange={(checked) => setActiveKey({ ...activeKey, disWaterLine: checked })} ></Switch>
        </Row>
      </Row>
    </div>
  );
}

