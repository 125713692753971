export const restrictedRoad = [
    {
      "id": 14285,
      "type": "Traffic Bulletin",
      "traffic_type": "Restricted Road",
      "created_at": 1696308624,
      "updated_at": 1696308624,
      "lat": 20.98195,
      "lon": 105.78225,
      "osm_combination_id": "way208657293",
      "ai_road_id": "ai_road_id_other",
      "name": "Ngõ 68 Đường 18M",
      "sta_cty": "Hà Nội",
      "content": "Đoạn đường này đã bị cấm",
      "forever": true,
      "audio_link": "admin/text_to_speech/fixed_audio/restricted_road.mp3",
      "expire_time": null,
      "image": [],
      "geometry": null,
      "grid_lat_center": null,
      "grid_lon_center": null,
      "grid_zoom": null,
      "congestion_level": null,
      "updated_by": null,
      "bearing": 138.91786
    },
    {
      "id": 14299,
      "type": "Traffic Bulletin",
      "traffic_type": "Restricted Road",
      "created_at": 1696333266,
      "updated_at": 1696333266,
      "lat": 20.986841,
      "lon": 105.77789,
      "osm_combination_id": "way1148706375",
      "ai_road_id": "ai_road_id_other",
      "name": "Phố Tố Hữu",
      "sta_cty": "Hà Nội",
      "content": "Đoạn đường này đã bị cấm",
      "forever": true,
      "audio_link": "admin/text_to_speech/fixed_audio/restricted_road.mp3",
      "expire_time": null,
      "image": [],
      "geometry": null,
      "grid_lat_center": null,
      "grid_lon_center": null,
      "grid_zoom": null,
      "congestion_level": null,
      "updated_by": null,
      "bearing": 60.469177
    }
  ]