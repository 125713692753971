export const lightStationData = {
  "type": "FeatureCollection",
  "features": [
    {
      "id": 0,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83229035672905,
          21.02552656249164
        ]
      },
      "properties": {
        "tenDoiTuon": "Phan Văn Trị",
        "diaChiQL": "",
        "tramBienAp": "Phan Văn Tri",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Thanh Tùng",
        "ngayLapDat": "8/20/2015 12:00:00 AM",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "PVT",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "PVT",
        "longitude": 105.83229035672905,
        "latitude": 21.02552656249164,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 1,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83449155850681,
          21.0275781294331
        ]
      },
      "properties": {
        "tenDoiTuon": "Quốc Tử Giám",
        "diaChiQL": "số 67 Quốc Tử Giám- Đống Đa- Hà Nội",
        "tramBienAp": "TBA Quốc Tử Giám",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Thanh Tùng",
        "ngayLapDat": "8/20/2015 12:00:00 AM",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "QTG",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Quốc Tử Giám",
        "longitude": 105.83449155850681,
        "latitude": 21.0275781294331,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 2,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83474776697311,
          21.028847133686025
        ]
      },
      "properties": {
        "tenDoiTuon": "Hàng Cháo",
        "diaChiQL": "đầu ngõ hàng cháo",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Thanh Tùng",
        "ngayLapDat": "8/20/2014 12:00:00 AM",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "HC",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Hàng Cháo",
        "longitude": 105.83474776697311,
        "latitude": 21.028847133686025,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 3,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8295779432075,
          21.03157412624473
        ]
      },
      "properties": {
        "tenDoiTuon": "Thiết Bị Lạnh Long Biên",
        "diaChiQL": "Ngõ 2 Giảng Võ",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Thanh Tùng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "",
        "soHieuTu": "TBLLB",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "TBL Long Biên",
        "longitude": 105.8295779432075,
        "latitude": 21.03157412624473,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 4,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82593805144937,
          21.02058511194489
        ]
      },
      "properties": {
        "tenDoiTuon": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiQL": "Phố Ô Chợ Dừa",
        "tramBienAp": "TBA Hoàng Cầu - Ô chợ Dừa",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Minh Hải",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "HC-OCD",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố độc lập",
        "soHieu2": "Hoàng Cầu - Ô Chợ Dừa",
        "longitude": 105.82593805144937,
        "latitude": 21.02058511194489,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 5,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8322529198528,
          21.00818183121789
        ]
      },
      "properties": {
        "tenDoiTuon": "C5 Trung Tự",
        "diaChiQL": "",
        "tramBienAp": "C5 Trung Tự",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Bùi Duy Nam",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "C5TT",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "C5 Trung Tự",
        "longitude": 105.8322529198528,
        "latitude": 21.00818183121789,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 6,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83181504920087,
          21.011826869031044
        ]
      },
      "properties": {
        "tenDoiTuon": "Trung Tự 2",
        "diaChiQL": "Phố Đặng Văn Ngữ",
        "tramBienAp": "Trung Tự 2",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Bùi Duy Nam",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "TT2",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Trung Tự 2",
        "longitude": 105.83181504920087,
        "latitude": 21.011826869031044,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 7,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83243598062552,
          21.023668401796524
        ]
      },
      "properties": {
        "tenDoiTuon": "Hàng Bột 1",
        "diaChiQL": "109 Tôn Đức Thắng",
        "tramBienAp": "TBA Hàng Bột 1",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Trương Viết Cường",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "HB1",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Hàng Bột 1",
        "longitude": 105.83243598062552,
        "latitude": 21.023668401796524,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 8,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83022952842849,
          21.02413519814253
        ]
      },
      "properties": {
        "tenDoiTuon": "ATEC Thăng Long",
        "diaChiQL": "Ngõ Thịnh Hào 1",
        "tramBienAp": "TBA ATEC Thăng Long",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Trương Viết Cường",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "ATL",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "ATEC Thăng Long",
        "longitude": 105.83022952842849,
        "latitude": 21.02413519814253,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 9,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83361648785821,
          21.010300445949756
        ]
      },
      "properties": {
        "tenDoiTuon": "Trung Tự Cao Tầng",
        "diaChiQL": "",
        "tramBienAp": "Trung Tự Cao Tầng",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Bùi Duy Nam",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "TTCT",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Trung Tự Cao Tầng",
        "longitude": 105.83361648785821,
        "latitude": 21.010300445949756,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 10,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83173055964802,
          21.02650752582538
        ]
      },
      "properties": {
        "tenDoiTuon": "Đoàn Thị Điểm",
        "diaChiQL": "Phố Đoàn Thị Điẻm",
        "tramBienAp": "TBA Đoàn Thị Điểm",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Thanh tùng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "ĐTĐ",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Đoàn Thị Điểm",
        "longitude": 105.83173055964802,
        "latitude": 21.02650752582538,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 11,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80124138685603,
          21.023065800785247
        ]
      },
      "properties": {
        "tenDoiTuon": "Láng Thượng 3",
        "diaChiQL": "",
        "tramBienAp": "Trạm Láng Thượng 3",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Trương Viết Cường",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "LTG3",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Láng Thượng 3",
        "longitude": 105.80124138685603,
        "latitude": 21.023065800785247,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 12,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80518306064894,
          21.023526631951842
        ]
      },
      "properties": {
        "tenDoiTuon": "TT Đại Học Ngoại Thương",
        "diaChiQL": "91 Chùa Láng",
        "tramBienAp": "TT Đại học ngoại thương",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Ngọc Thắng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "ĐHNT",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "TT Đại Học Ngoại Thương",
        "longitude": 105.80518306064894,
        "latitude": 21.023526631951842,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 13,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80231477329181,
          21.019518213719795
        ]
      },
      "properties": {
        "tenDoiTuon": "850 Đường Láng",
        "diaChiQL": "850 Đường Láng",
        "tramBienAp": "Trạm 850 Đường Láng",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Ngọc Thắng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "850ĐL",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "850 Đường Láng",
        "longitude": 105.80231477329181,
        "latitude": 21.019518213719795,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 14,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83169300873064,
          21.028837119391977
        ]
      },
      "properties": {
        "tenDoiTuon": "Hàng Đẫy",
        "diaChiQL": "số 20 Cát Linh",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Thanh Tùng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "HĐ",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Hàng Đẫy",
        "longitude": 105.83169300873064,
        "latitude": 21.028837119391977,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 15,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81161837484115,
          21.01601039668214
        ]
      },
      "properties": {
        "tenDoiTuon": "NHNgoaiThuong",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "",
        "soHieuTu": "NHNgoaiThuong",
        "cheDoVanHa": "",
        "dienLuc": "",
        "chuDauTu": "",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "NHNgoaiThuong",
        "longitude": 105.81161837484115,
        "latitude": 21.01601039668214,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 16,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83867063173784,
          21.028242385323345
        ]
      },
      "properties": {
        "tenDoiTuon": "Nguyễn Khuyến",
        "diaChiQL": "79 Nguyễn khuyến",
        "tramBienAp": "TBA Nguyễn Khuyến 4",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Thanh Tùng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "NK",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Nguyễn Khuyến",
        "longitude": 105.83867063173784,
        "latitude": 21.028242385323345,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 17,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83679658192372,
          21.02675851280393
        ]
      },
      "properties": {
        "tenDoiTuon": "Lương Sử B",
        "diaChiQL": "Phố Quốc Tử Giám",
        "tramBienAp": "TBA Lương Sử B",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Thanh Tùng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "LSB",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Lương Sử B",
        "longitude": 105.83679658192372,
        "latitude": 21.02675851280393,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 18,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8177562506826,
          21.011401212325673
        ]
      },
      "properties": {
        "tenDoiTuon": "Vĩnh Hồ 7",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Văn Phụng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "VH7",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Vĩnh Hồ 7",
        "longitude": 105.8177562506826,
        "latitude": 21.011401212325673,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 19,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82243548764606,
          21.012052701377815
        ]
      },
      "properties": {
        "tenDoiTuon": "Ao Cây Dừa",
        "diaChiQL": "ngõ 98 thái hà",
        "tramBienAp": "Ao Cây Dừa",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Pham Văn Minh",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "ACD",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Ao Cây Dừa",
        "longitude": 105.82243548764606,
        "latitude": 21.012052701377815,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 20,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83040286618692,
          21.019160492823502
        ]
      },
      "properties": {
        "tenDoiTuon": "Khâm Thiên Ô",
        "diaChiQL": "Nga 7 OCD",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Le Trung Kien",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "KTO",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Khâm Thiên Ô",
        "longitude": 105.83040286618692,
        "latitude": 21.019160492823502,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 21,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83980581702406,
          21.01910559877055
        ]
      },
      "properties": {
        "tenDoiTuon": "Khâm Thiên Ga",
        "diaChiQL": "Phố Khâm Thiên",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Lê Trung Kiên",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "KTG",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Khâm Thiên Ga",
        "longitude": 105.83980581702406,
        "latitude": 21.01910559877055,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 22,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81605573008486,
          21.014012722251184
        ]
      },
      "properties": {
        "tenDoiTuon": "I 17 Thành Công",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Văn Thạch",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "I17TC",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "I 17 Thành Công",
        "longitude": 105.81605573008486,
        "latitude": 21.014012722251184,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 23,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.838269449944,
          21.018928584070956
        ]
      },
      "properties": {
        "tenDoiTuon": "Khâm Thiên 1",
        "diaChiQL": "Phố Khâm Thiên",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Lê Trung Kiên",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "KTH1",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Khâm Thiên 1",
        "longitude": 105.838269449944,
        "latitude": 21.018928584070956,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 24,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83505515772526,
          21.01145880124726
        ]
      },
      "properties": {
        "tenDoiTuon": "Trạm Hồ Đấu",
        "diaChiQL": "",
        "tramBienAp": "Trạm Hồ Đấu",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Bùi Duy Nam",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "HĐA",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "Trạm Hồ Đấu",
        "longitude": 105.83505515772526,
        "latitude": 21.01145880124726,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 25,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82367990929255,
          21.026595152249463
        ]
      },
      "properties": {
        "tenDoiTuon": "K1 Hào Nam",
        "diaChiQL": "Phố Giảng Võ",
        "tramBienAp": "K1 Hào Nam",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Thanh Tùng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "K1HN",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "K1 Hào Nam",
        "longitude": 105.82367990929255,
        "latitude": 21.026595152249463,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 26,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82735990003934,
          21.015294673942527
        ]
      },
      "properties": {
        "tenDoiTuon": "Bệnh Viện Nam Đồng 2",
        "diaChiQL": "",
        "tramBienAp": "BVND",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "",
        "soHieuTu": "BVNĐ2",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "BVND2",
        "longitude": 105.82735990003934,
        "latitude": 21.015294673942527,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 27,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82073014975175,
          21.006596215126997
        ]
      },
      "properties": {
        "tenDoiTuon": "Vĩnh Hồ 1",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Văn Phung",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "VHO1",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Vĩnh Hồ 1",
        "longitude": 105.82073014975175,
        "latitude": 21.006596215126997,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 28,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82432498053298,
          21.011409071285353
        ]
      },
      "properties": {
        "tenDoiTuon": "Thái Hà 6",
        "diaChiQL": "",
        "tramBienAp": "Thái Hà 6",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "",
        "soHieuTu": "TH6",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "TH6",
        "longitude": 105.82432498053298,
        "latitude": 21.011409071285353,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 29,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81204582769598,
          21.015362276557383
        ]
      },
      "properties": {
        "tenDoiTuon": "TT Viện Hóa Học",
        "diaChiQL": "Ngõ 17 Hoàng Ngọc Phách",
        "tramBienAp": "TBA TT Viện Hóa Học",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Văn Thạch",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "VHH",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "TT Viện Hóa Học",
        "longitude": 105.81204582769598,
        "latitude": 21.015362276557383,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 30,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81218664364734,
          21.01675813853993
        ]
      },
      "properties": {
        "tenDoiTuon": "B24 Thành Công",
        "diaChiQL": "Ngõ 9 Huỳnh Thúc Kháng",
        "tramBienAp": "TBA B24 Thành Công",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Văn Thạch",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "B24TC",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "B24 Thành Công",
        "longitude": 105.81218664364734,
        "latitude": 21.01675813853993,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 31,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84093187759191,
          21.007361169005556
        ]
      },
      "properties": {
        "tenDoiTuon": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiQL": "Phố Xã Đàn",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Bùi Duy Nma",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "NKLT5",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố độc lập",
        "soHieu2": "Nút Giao Thông Kim Liên Tủ 5",
        "longitude": 105.84093187759191,
        "latitude": 21.007361169005556,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 32,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8246428223211,
          21.012873478571652
        ]
      },
      "properties": {
        "tenDoiTuon": "Bệnh Viện Nam Đồng",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "",
        "soHieuTu": "BVNĐ",
        "cheDoVanHa": "",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "BVND",
        "longitude": 105.8246428223211,
        "latitude": 21.012873478571652,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 33,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83430682143286,
          21.01919053765466
        ]
      },
      "properties": {
        "tenDoiTuon": "Khâm Thiên 3",
        "diaChiQL": "Khâm Thiên",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Lê Trung Kiên",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "KT3",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Khâm Thiên 3",
        "longitude": 105.83430682143286,
        "latitude": 21.01919053765466,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 34,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83297342822415,
          21.007588087875924
        ]
      },
      "properties": {
        "tenDoiTuon": "B4 Kim Liên",
        "diaChiQL": "Ngõ 97 Phạm Ngọc Thạch",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Bùi Duy Nam",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "B4KL",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "B4 Kim Liên",
        "longitude": 105.83297342822415,
        "latitude": 21.007588087875924,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 35,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81956707704907,
          21.007615944098383
        ]
      },
      "properties": {
        "tenDoiTuon": "Vĩnh Hồ 5",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Văn Phụng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "VH5",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Vĩnh Hồ 5",
        "longitude": 105.81956707704907,
        "latitude": 21.007615944098383,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 36,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84107269357025,
          21.008016572376444
        ]
      },
      "properties": {
        "tenDoiTuon": "Nút Kim Liên Tủ 4",
        "diaChiQL": "Phố Xã Đàn",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Đỗ Văn Dũng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "NKLT4",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố độc lập",
        "soHieu2": "Nút Kim Liên Tủ 4",
        "longitude": 105.84107269357025,
        "latitude": 21.008016572376444,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 37,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81538819537481,
          21.008515791251916
        ]
      },
      "properties": {
        "tenDoiTuon": "Thịnh Quang 2",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Văn Phụng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "TQ2",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Thịnh Quang 2",
        "longitude": 105.81538819537481,
        "latitude": 21.008515791251916,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 38,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81599672156842,
          21.01568401451229
        ]
      },
      "properties": {
        "tenDoiTuon": "F3 Thành Công",
        "diaChiQL": "đầu ngõ 171 Thái Hà",
        "tramBienAp": "TBA F3 Thành Công",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Minh Hải",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "F3TC",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "F3 Thành Công",
        "longitude": 105.81599672156842,
        "latitude": 21.01568401451229,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 39,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82525973038922,
          21.010622507810115
        ]
      },
      "properties": {
        "tenDoiTuon": "Ấp Thái Hà",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "",
        "soHieuTu": "ATH",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "ATH",
        "longitude": 105.82525973038922,
        "latitude": 21.010622507810115,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 40,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82519669848932,
          21.016773161198277
        ]
      },
      "properties": {
        "tenDoiTuon": "Ao Phe",
        "diaChiQL": "Xung quanh sân VĐ Hoàng Cầu",
        "tramBienAp": "TBA Ao Phe",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Minh Hải",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "AP",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố độc lập",
        "soHieu2": "Ao Phe",
        "longitude": 105.82519669848932,
        "latitude": 21.016773161198277,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 41,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81752021626656,
          21.009368684843366
        ]
      },
      "properties": {
        "tenDoiTuon": "Cánh Đồng Xi",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Văn Phụng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "CĐX",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "Cánh Đồng Xi",
        "longitude": 105.81752021626656,
        "latitude": 21.009368684843366,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 42,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82660385239296,
          21.02244692339906
        ]
      },
      "properties": {
        "tenDoiTuon": "Di Dân Hào Nam 2",
        "diaChiQL": "Hào Nam",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Trương Viết Cường",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "DDHN2",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố độc lập",
        "soHieu2": "Di Dân Hào Nam 2",
        "longitude": 105.82660385239296,
        "latitude": 21.02244692339906,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 43,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82660519349683,
          21.022459441803797
        ]
      },
      "properties": {
        "tenDoiTuon": "Di Dân Hào Nam",
        "diaChiQL": "Hào Nam",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Trương Viết Cường",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "DDHN",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "Di Dân Hào Nam",
        "longitude": 105.82660519349683,
        "latitude": 21.022459441803797,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 44,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8349305188242,
          21.021216046117054
        ]
      },
      "properties": {
        "tenDoiTuon": "Văn Chương 1",
        "diaChiQL": "Ngõ Văn Chương",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "VC1",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "Văn Chương 1",
        "longitude": 105.8349305188242,
        "latitude": 21.021216046117054,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 45,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83490052277429,
          21.02121479426676
        ]
      },
      "properties": {
        "tenDoiTuon": "Văn Chương 1B",
        "diaChiQL": "Ngõ Văn Chương",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "VC1B",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "Văn Chương 1B",
        "longitude": 105.83490052277429,
        "latitude": 21.02121479426676,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 46,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8226472587729,
          21.00929481975887
        ]
      },
      "properties": {
        "tenDoiTuon": "Thái Hà 7",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "",
        "soHieuTu": "TH7",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "TH7",
        "longitude": 105.8226472587729,
        "latitude": 21.00929481975887,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 47,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80969084817558,
          21.016669254440647
        ]
      },
      "properties": {
        "tenDoiTuon": "Bến Xe Thành Công",
        "diaChiQL": "Ngõ 49 Nguyên Hồng",
        "tramBienAp": "TBA BX Thành Công",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Văn Thạch",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "BX",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "BX T",
        "longitude": 105.80969084817558,
        "latitude": 21.016669254440647,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 48,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81559975457108,
          21.010333309449127
        ]
      },
      "properties": {
        "tenDoiTuon": "Tập Thể Yên Lãng 3",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Văn Phụng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "YL3",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "Tập Thể Yên Lãng 3",
        "longitude": 105.81559975457108,
        "latitude": 21.010333309449127,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 49,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81296850754548,
          21.01297363221373
        ]
      },
      "properties": {
        "tenDoiTuon": "Cục Bản Đồ",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Văn Thạch",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "CBĐ",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Cục Bản Đồ",
        "longitude": 105.81296850754548,
        "latitude": 21.01297363221373,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 50,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81989598289245,
          21.013240916905268
        ]
      },
      "properties": {
        "tenDoiTuon": "Xây Dựng Nhà",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Minh Hải",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "XDN",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Xây Dựng Nhà",
        "longitude": 105.81989598289245,
        "latitude": 21.013240916905268,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 51,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.839126751018,
          21.021256105342218
        ]
      },
      "properties": {
        "tenDoiTuon": "Trần Quý Cáp 3",
        "diaChiQL": "Phố Trần Quý Cáp",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "TQC3",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Trần Quý Cáp 3",
        "longitude": 105.839126751018,
        "latitude": 21.021256105342218,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 52,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82654819654189,
          21.002614573017674
        ]
      },
      "properties": {
        "tenDoiTuon": "Tàu Bay 2",
        "diaChiQL": "Ngõ 354 Trường Chinh",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Phạm Anh Tuấn",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "TB2",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "Tàu Bay 2",
        "longitude": 105.82654819654189,
        "latitude": 21.002614573017674,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 53,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82603086547437,
          21.004616831747846
        ]
      },
      "properties": {
        "tenDoiTuon": "Khương Thượng C",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "KTC",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Khương Thượng C",
        "longitude": 105.82603086547437,
        "latitude": 21.004616831747846,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 54,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8130632158659,
          21.009769893464583
        ]
      },
      "properties": {
        "tenDoiTuon": "Láng Hạ 4",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Văn Thạch",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "LH4",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Láng Hạ 4",
        "longitude": 105.8130632158659,
        "latitude": 21.009769893464583,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 55,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82339519247631,
          21.01544317877366
        ]
      },
      "properties": {
        "tenDoiTuon": "Hồ Cá 1",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Minh Hải",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "HCA1",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Hồ Cá 1",
        "longitude": 105.82339519247631,
        "latitude": 21.01544317877366,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 56,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82437683644753,
          21.01501299604418
        ]
      },
      "properties": {
        "tenDoiTuon": "Hồ Cá 2",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Minh Hải",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "HCA2",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Hồ Cá 2",
        "longitude": 105.82437683644753,
        "latitude": 21.01501299604418,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 57,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80881510696156,
          21.01256800955891
        ]
      },
      "properties": {
        "tenDoiTuon": "Láng Hạ 2",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Văn Thạch",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "LH2",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "LH2",
        "longitude": 105.80881510696156,
        "latitude": 21.01256800955891,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 58,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8057493420392,
          21.014440875298398
        ]
      },
      "properties": {
        "tenDoiTuon": "Cầu Trung Kính",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Ngọc Thắng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "CTK",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố độc lập",
        "soHieu2": "CTK",
        "longitude": 105.8057493420392,
        "latitude": 21.014440875298398,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 59,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82855918276215,
          21.01983994269992
        ]
      },
      "properties": {
        "tenDoiTuon": "La Thành 1B",
        "diaChiQL": "Ngõ 96 Đê La Thành",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Lê Trung Kiên",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "LT1B",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "La Thành 1B",
        "longitude": 105.82855918276215,
        "latitude": 21.01983994269992,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 60,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83164166186444,
          21.022401544173846
        ]
      },
      "properties": {
        "tenDoiTuon": "Hàng Bột 7",
        "diaChiQL": "Ngõ 190 Tôn Đức Thắng",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Trương Viết Cường",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "HB7",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Hàng Bột 7",
        "longitude": 105.83164166186444,
        "latitude": 21.022401544173846,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 61,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83293721842864,
          21.014948210052115
        ]
      },
      "properties": {
        "tenDoiTuon": "Tập thể Quân Khu Thủ Đô",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Lê Quang Thành",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "QKTĐ",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "Tập thể Quân Khu Thủ Đô",
        "longitude": 105.83293721842864,
        "latitude": 21.014948210052115,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 62,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83119277675323,
          21.015660854407816
        ]
      },
      "properties": {
        "tenDoiTuon": "Xã Đàn 2",
        "diaChiQL": "Ngõ 109 Xã Đàn 2",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Lê Quang Thành",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "XĐ2",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "Xã Đàn 2",
        "longitude": 105.83119277675323,
        "latitude": 21.015660854407816,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 63,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84149232490051,
          21.015118781802947
        ]
      },
      "properties": {
        "tenDoiTuon": "Công Viên Lê Nin 3",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Đỗ Văn Dũng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "CVLN3",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "CV3",
        "longitude": 105.84149232490051,
        "latitude": 21.015118781802947,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 64,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82688246685466,
          21.02673034720221
        ]
      },
      "properties": {
        "tenDoiTuon": "Hồ Hào Nam",
        "diaChiQL": "Hồ Hào Nam",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Thanh Tùng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "HHN",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "Hồ Hào Nam",
        "longitude": 105.82688246685466,
        "latitude": 21.02673034720221,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 65,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80663815910357,
          21.01683606855697
        ]
      },
      "properties": {
        "tenDoiTuon": "Láng Trung 3",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "LT3",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "LT3",
        "longitude": 105.80663815910357,
        "latitude": 21.01683606855697,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 66,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8258364053502,
          21.026771656746728
        ]
      },
      "properties": {
        "tenDoiTuon": "Long Biên 3",
        "diaChiQL": "Ngõ 8 Vũ Thạnh",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Phạm Văn Minh",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "LB3",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Long Biên 3",
        "longitude": 105.8258364053502,
        "latitude": 21.026771656746728,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 67,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82816322166232,
          21.027473917257517
        ]
      },
      "properties": {
        "tenDoiTuon": "Cát Linh 3",
        "diaChiQL": "Ngõ 35/75 Cát Linh",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Thanh Tùng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "CL3",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Cát Linh 3",
        "longitude": 105.82816322166232,
        "latitude": 21.027473917257517,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 68,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83655883411649,
          21.017473198733178
        ]
      },
      "properties": {
        "tenDoiTuon": "TT Bộ Công An",
        "diaChiQL": "Hẻm 26/11 Ngõ Cống Trắng",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Đỗ Văn Dũng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "BCA",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "BCA",
        "longitude": 105.83655883411649,
        "latitude": 21.017473198733178,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 69,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84098536164004,
          21.013845650396604
        ]
      },
      "properties": {
        "tenDoiTuon": "Hồ Ba Mẫu 3",
        "diaChiQL": "Đường xung quanh hồ Ba Mẫu",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Đỗ Văn Dũng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "HBM3",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố độc lập",
        "soHieu2": "HBM 3",
        "longitude": 105.84098536164004,
        "latitude": 21.013845650396604,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 70,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83286915739144,
          21.01268193455198
        ]
      },
      "properties": {
        "tenDoiTuon": "Đặng Văn Ngữ 3",
        "diaChiQL": "Ngõ 6 Đặng Văn Ngữ",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Lê Quang Thành",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "ĐVN3",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố độc lập",
        "soHieu2": "Đặng Văn Ngữ 3",
        "longitude": 105.83286915739144,
        "latitude": 21.01268193455198,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 71,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8097713144887,
          21.017774668365416
        ]
      },
      "properties": {
        "tenDoiTuon": "BW Thành Công",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Văn Thạch",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "BW",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "BW",
        "longitude": 105.8097713144887,
        "latitude": 21.017774668365416,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 72,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82930914364874,
          21.000754079030227
        ]
      },
      "properties": {
        "tenDoiTuon": "Khương Thượng 4B",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Phạm Anh Tuấn",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "KT4B",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "KT4B",
        "longitude": 105.82930914364874,
        "latitude": 21.000754079030227,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 73,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82943693569936,
          21.001501534642195
        ]
      },
      "properties": {
        "tenDoiTuon": "Khương Thượng 4",
        "diaChiQL": "Tôn Thất Tùng",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "KT4",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "KT4",
        "longitude": 105.82943693569936,
        "latitude": 21.001501534642195,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 74,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82436621957359,
          21.003387685311306
        ]
      },
      "properties": {
        "tenDoiTuon": "Làng Khương Thượng 2",
        "diaChiQL": "Phố Khương Thượng",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Phạm Anh Tuấn",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "LKT2",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Làng Khương Thượng 2",
        "longitude": 105.82436621957359,
        "latitude": 21.003387685311306,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 75,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81077211368587,
          21.019137020290756
        ]
      },
      "properties": {
        "tenDoiTuon": "G22B",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Văn Thạch",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "G22B",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "G22B",
        "longitude": 105.81077211368587,
        "latitude": 21.019137020290756,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 76,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83858225010826,
          21.015357488010803
        ]
      },
      "properties": {
        "tenDoiTuon": "Tổ 8A Trung Phụng",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Đỗ Văn Dũng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "T8A",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "T8A",
        "longitude": 105.83858225010826,
        "latitude": 21.015357488010803,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 77,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80682457254163,
          21.01789954331991
        ]
      },
      "properties": {
        "tenDoiTuon": "Láng Trung 5",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Ngọc Thắng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "",
        "soHieuTu": "LTG5",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "LT5",
        "longitude": 105.80682457254163,
        "latitude": 21.01789954331991,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 78,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81028898077156,
          21.022626875367557
        ]
      },
      "properties": {
        "tenDoiTuon": "Tổng Cục Địa Chính",
        "diaChiQL": "Ngõ 79 Nguyễn Chí Thanh",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Văn Thạch",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "TCĐC",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "TCĐC",
        "longitude": 105.81028898077156,
        "latitude": 21.022626875367557,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 79,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80941960978967,
          21.02276113504007
        ]
      },
      "properties": {
        "tenDoiTuon": "Láng Trung 4",
        "diaChiQL": "Đầu phố Chùa Láng",
        "tramBienAp": "Láng Trung 4",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Ngọc Thắng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "LTG4",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "LT4",
        "longitude": 105.80941960978967,
        "latitude": 21.02276113504007,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 80,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83758246916942,
          21.020235217736214
        ]
      },
      "properties": {
        "tenDoiTuon": "Văn Chương 2",
        "diaChiQL": "",
        "tramBienAp": "TBA Linh Quang 3",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "VC2",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "VC2",
        "longitude": 105.83758246916942,
        "latitude": 21.020235217736214,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 81,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8310318442258,
          21.005359260078357
        ]
      },
      "properties": {
        "tenDoiTuon": "Khương Thượng 1",
        "diaChiQL": "Ngõ 1 phố Tôn Thất Tùng",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Phạm Anh Tuấn",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "KT1",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "KT1",
        "longitude": 105.8310318442258,
        "latitude": 21.005359260078357,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 82,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.821429535937,
          21.01231512080222
        ]
      },
      "properties": {
        "tenDoiTuon": "Thái Hà 2",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "",
        "soHieuTu": "TH2",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "TH2",
        "longitude": 105.821429535937,
        "latitude": 21.01231512080222,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 83,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8227682935073,
          21.003147927027015
        ]
      },
      "properties": {
        "tenDoiTuon": "Làng Khương Thượng 1",
        "diaChiQL": "Ngõ 580 Trường Chinh",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "LKT1",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "Làng Khương Thượng 1",
        "longitude": 105.8227682935073,
        "latitude": 21.003147927027015,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 84,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83468233068741,
          21.004744534687
        ]
      },
      "properties": {
        "tenDoiTuon": "Kim Liên C7",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Tuấn Thành",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "KLC7",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "KLC7",
        "longitude": 105.83468233068741,
        "latitude": 21.004744534687,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 85,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81926365216125,
          21.019106662482255
        ]
      },
      "properties": {
        "tenDoiTuon": "Hoàng Cầu 6",
        "diaChiQL": "Ngõ 174 Mai Anh Tuấn",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Minh Hải",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "HC6",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "HC6",
        "longitude": 105.81926365216125,
        "latitude": 21.019106662482255,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 86,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8291713969712,
          21.01694341788822
        ]
      },
      "properties": {
        "tenDoiTuon": "La Thành 1",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "",
        "soHieuTu": "LT1",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "LT1",
        "longitude": 105.8291713969712,
        "latitude": 21.01694341788822,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 87,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83589200693602,
          21.0224416030788
        ]
      },
      "properties": {
        "tenDoiTuon": "Văn Chương 4",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Trương Viết Cương",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "VC4",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "VC4",
        "longitude": 105.83589200693602,
        "latitude": 21.0224416030788,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 88,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83463136870441,
          21.014408325694657
        ]
      },
      "properties": {
        "tenDoiTuon": "La Thành 3",
        "diaChiQL": "Ngõ 126 Đê La Thành",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Đỗ Văn Dũng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "LAT3",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "La Thành 3",
        "longitude": 105.83463136870441,
        "latitude": 21.014408325694657,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 89,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82463209349912,
          21.02354071505059
        ]
      },
      "properties": {
        "tenDoiTuon": "Mỹ Nghệ Thịnh Hào",
        "diaChiQL": "Ngõ 318 Đê La Thành",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Lê Quang Thành",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "MNTH",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "MNHN",
        "longitude": 105.82463209349912,
        "latitude": 21.02354071505059,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 90,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82936485132363,
          21.01229383806407
        ]
      },
      "properties": {
        "tenDoiTuon": "B1 Nam Đồng",
        "diaChiQL": "Phố Trần Hữu Tước",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Lê Quang Thành",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "B1NĐ",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "B1 Nam Đồng",
        "longitude": 105.82936485132363,
        "latitude": 21.01229383806407,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 91,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83761618804446,
          21.015684880021706
        ]
      },
      "properties": {
        "tenDoiTuon": "Cột Ngõ 10",
        "diaChiQL": "Ngõ 178 Phố Chợ Khâm Thiên",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Đỗ Văn Dũng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "CN10",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "CN10",
        "longitude": 105.83761618804446,
        "latitude": 21.015684880021706,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 92,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80376584848872,
          21.01817526935082
        ]
      },
      "properties": {
        "tenDoiTuon": "Láng Trung 1",
        "diaChiQL": "Phố Pháo Đài Láng",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Ngọc Thắng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "LTG1",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Láng Trung 1",
        "longitude": 105.80376584848872,
        "latitude": 21.01817526935082,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 93,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82208399491353,
          21.02291855357333
        ]
      },
      "properties": {
        "tenDoiTuon": "La Thành 5",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "",
        "soHieuTu": "LT5",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "LAT5",
        "longitude": 105.82208399491353,
        "latitude": 21.02291855357333,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 94,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82225280646546,
          21.021528852023376
        ]
      },
      "properties": {
        "tenDoiTuon": "Hoàng Cầu 1",
        "diaChiQL": "Phố Nguyễn Phúc Lai",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Minh Hải",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "HC1",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "HC1",
        "longitude": 105.82225280646546,
        "latitude": 21.021528852023376,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 95,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80185242759981,
          21.019862476222002
        ]
      },
      "properties": {
        "tenDoiTuon": "Cầu 361",
        "diaChiQL": "Bờ Trái Sông Tô Lịch",
        "tramBienAp": "TBA Cầu 361",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Đỗ Đức Hiếu",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "C361",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố độc lập",
        "soHieu2": "C 361",
        "longitude": 105.80185242759981,
        "latitude": 21.019862476222002,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 96,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80409274282455,
          21.017898291448486
        ]
      },
      "properties": {
        "tenDoiTuon": "Di Dân Láng Trung",
        "diaChiQL": "Ngõ 159 Pháo Đài Láng",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Ngọc Thắng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "DDLT",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Đường phố độc lập",
        "soHieu2": "Di Dân Láng Trung",
        "longitude": 105.80409274282455,
        "latitude": 21.017898291448486,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 97,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82043510696302,
          21.002692510419894
        ]
      },
      "properties": {
        "tenDoiTuon": "CV Ngã Tư sở tủ 1",
        "diaChiQL": "nút GT Ngã Tư Sở",
        "tramBienAp": "TBA Cầu Vượt Ngã Tư Sở",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Trần Đăng Khoa",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "CVNTS1",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Thanh Xuân",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố độc lập",
        "soHieu2": "CV NTS Tủ 1",
        "longitude": 105.82043510696302,
        "latitude": 21.002692510419894,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 98,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83591149747615,
          21.013083801133043
        ]
      },
      "properties": {
        "tenDoiTuon": "Cột Ngõ Chợ",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Đỗ Văn Dũng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "CNC",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "CNC",
        "longitude": 105.83591149747615,
        "latitude": 21.013083801133043,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 99,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8258348194015,
          21.018667649339307
        ]
      },
      "properties": {
        "tenDoiTuon": "Thủ Công Nghiệp Hào Nam",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "",
        "soHieuTu": "TCNHN",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "TCNHN",
        "longitude": 105.8258348194015,
        "latitude": 21.018667649339307,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 100,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82817260938043,
          21.011798074639046
        ]
      },
      "properties": {
        "tenDoiTuon": "Tập Thể Nam Đồng",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "",
        "soHieuTu": "TTNĐ",
        "cheDoVanHa": "",
        "dienLuc": "",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "TTNĐ",
        "longitude": 105.82817260938043,
        "latitude": 21.011798074639046,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 101,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82410906274687,
          21.016433899115604
        ]
      },
      "properties": {
        "tenDoiTuon": "Hà Thủy",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "",
        "soHieuTu": "HT",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "HT",
        "longitude": 105.82410906274687,
        "latitude": 21.016433899115604,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 102,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83342571574944,
          21.01497418704619
        ]
      },
      "properties": {
        "tenDoiTuon": "Kim Liên Ô Chợ Dừa",
        "diaChiQL": "Xã Đàn",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Lê Quang Thành",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "KLOCD",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố độc lập",
        "soHieu2": "Kim Liên OCD",
        "longitude": 105.83342571574944,
        "latitude": 21.01497418704619,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 103,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83558958785592,
          21.0234912677058
        ]
      },
      "properties": {
        "tenDoiTuon": "Văn Hương 5",
        "diaChiQL": "Ngõ Văn Hương",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Trương Viết Cường",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "VHG5",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Văn Hương 5",
        "longitude": 105.83558958785592,
        "latitude": 21.0234912677058,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 104,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83950226028153,
          21.00933895100644
        ]
      },
      "properties": {
        "tenDoiTuon": "Làng Kim Liên 3",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Tuấn Thành",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "LKL3",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "Làng Kim Liên 3",
        "longitude": 105.83950226028153,
        "latitude": 21.00933895100644,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 105,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83734039981739,
          21.01165504157168
        ]
      },
      "properties": {
        "tenDoiTuon": "Hợp Bộ Trung Tự",
        "diaChiQL": "Ngõ Đình Kim Hoa",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Đỗ Văn Dũng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "HBTT",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "Hợp Bộ Trung Tự",
        "longitude": 105.83734039981739,
        "latitude": 21.01165504157168,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 106,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8203975560097,
          21.002706908491902
        ]
      },
      "properties": {
        "tenDoiTuon": "CV Ngã Tư sở tủ 2",
        "diaChiQL": "Nút GT Ngã Tư Sở",
        "tramBienAp": "Cầu Vượt Ngã Tư Sở",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Trần Đăng Khoa",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "CVNTS2",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố độc lập",
        "soHieu2": "CV NTS Tủ 2",
        "longitude": 105.8203975560097,
        "latitude": 21.002706908491902,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 107,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82803916952281,
          21.006073828212365
        ]
      },
      "properties": {
        "tenDoiTuon": "Thủy Lợi 3",
        "diaChiQL": "Ngõ 95 Chùa Bộc",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Phạm Anh Tuấn",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "TL3",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "TL3",
        "longitude": 105.82803916952281,
        "latitude": 21.006073828212365,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 108,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82614485937526,
          21.017518032793106
        ]
      },
      "properties": {
        "tenDoiTuon": "Y Học Bộ Nội Vụ",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "",
        "soHieuTu": "YHBNV",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "YHBNV",
        "longitude": 105.82614485937526,
        "latitude": 21.017518032793106,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 109,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82046729344694,
          21.00270816049752
        ]
      },
      "properties": {
        "tenDoiTuon": "CV Ngã Tư sở tủ 3",
        "diaChiQL": "",
        "tramBienAp": "CV Ngã Tư Sở",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Trần Đăng Khoa",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "CVNTS3",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Thanh Xuân",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố độc lập",
        "soHieu2": "CV NTS tủ 3",
        "longitude": 105.82046729344694,
        "latitude": 21.00270816049752,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 110,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83615754561926,
          21.010209367102078
        ]
      },
      "properties": {
        "tenDoiTuon": "B1 Kim Liên",
        "diaChiQL": "Phạm Ngọc Thạch",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Tuấn Thành",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "B1KL",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "B1KL",
        "longitude": 105.83615754561926,
        "latitude": 21.010209367102078,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 111,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83785853372726,
          20.99946911277619
        ]
      },
      "properties": {
        "tenDoiTuon": "Nông Lâm Bạch Mai",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Tuấn Thành",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "NLBM",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "NLBM",
        "longitude": 105.83785853372726,
        "latitude": 20.99946911277619,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 112,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82715638746629,
          21.00977963695809
        ]
      },
      "properties": {
        "tenDoiTuon": "Công Đoàn 2",
        "diaChiQL": "Ngõ 167 Tây Sơn",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Phạm Văn Minh",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "CĐ2",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "CĐ2",
        "longitude": 105.82715638746629,
        "latitude": 21.00977963695809,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 113,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82660083490701,
          21.016238603989738
        ]
      },
      "properties": {
        "tenDoiTuon": "Vườn Hoa 1/6",
        "diaChiQL": "Ngõ 178 Nguyễn Lương Bằng",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Lê Quang Thành",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "VH1/6",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "VH1/6",
        "longitude": 105.82660083490701,
        "latitude": 21.016238603989738,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 114,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79979912922049,
          21.026223991394737
        ]
      },
      "properties": {
        "tenDoiTuon": "Việt Hà 1",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Ngọc Thắng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "VHA1",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "VH1",
        "longitude": 105.79979912922049,
        "latitude": 21.026223991394737,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 115,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83358396606012,
          21.010304514757937
        ]
      },
      "properties": {
        "tenDoiTuon": "Trung Tự Cao Tầng 2",
        "diaChiQL": "Vườn Hoa Con Voi",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Bùi Duy Nam",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "TTCT2",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "Trung Tự Cao Tầng 2",
        "longitude": 105.83358396606012,
        "latitude": 21.010304514757937,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 116,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83580215294973,
          21.008107339559732
        ]
      },
      "properties": {
        "tenDoiTuon": "B8A Kim Liên",
        "diaChiQL": "Đường Hoàng Tích Trí",
        "tramBienAp": "TBA B8A Kim Liên",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Tuấn Thành",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "B8AKL",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "B8A Kim Liên",
        "longitude": 105.83580215294973,
        "latitude": 21.008107339559732,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 117,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82915597427221,
          21.005878519521996
        ]
      },
      "properties": {
        "tenDoiTuon": "TT Khoa học thủy lợi",
        "diaChiQL": "Ngõ 43 Chùa Bộc",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Phạm Anh Tuấn",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "KHTL",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "KHTL",
        "longitude": 105.82915597427221,
        "latitude": 21.005878519521996,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 118,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79856747260723,
          21.025917041297678
        ]
      },
      "properties": {
        "tenDoiTuon": "Ban Quản Lý Lăng",
        "diaChiQL": "Đầu ngõ 1150 Đường Láng",
        "tramBienAp": "TBA Láng Thượng 2",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Ngọc Thắng",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "BQLL",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "BQLL",
        "longitude": 105.79856747260723,
        "latitude": 21.025917041297678,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 119,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79974488186488,
          21.027758388154872
        ]
      },
      "properties": {
        "tenDoiTuon": "Việt Hà 2",
        "diaChiQL": "Đầu Ngõ 1194 Đường Láng",
        "tramBienAp": "TBA Việt Hà 2",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Đỗ Đức Hiếu",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "VHA2",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "VHA2",
        "longitude": 105.79974488186488,
        "latitude": 21.027758388154872,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 120,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83595637996677,
          21.004075971004397
        ]
      },
      "properties": {
        "tenDoiTuon": "Kim Liên C3",
        "diaChiQL": "Phố Lương Đình Của",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Tuấn Thành",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "KLC3",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "UBNDQuanDongDa",
        "phanLoaiTu": "Đường phố độc lập",
        "soHieu2": "Kim Liên C3",
        "longitude": 105.83595637996677,
        "latitude": 21.004075971004397,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 121,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83384279925096,
          21.016869556549693
        ]
      },
      "properties": {
        "tenDoiTuon": "Thổ Quan 1",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "",
        "soHieuTu": "TQ1",
        "cheDoVanHa": "",
        "dienLuc": "",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "TQ",
        "longitude": 105.83384279925096,
        "latitude": 21.016869556549693,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 122,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83560098729721,
          21.001633309303585
        ]
      },
      "properties": {
        "tenDoiTuon": "Tập Thể Bộ Nông Nghiệp",
        "diaChiQL": "Ngõ 32 Lương Đình Của",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Tuấn Thành",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "TTBNN",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "577E5E56-D806-41B2-B158-665622D34F8E",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "TTBNN",
        "longitude": 105.83560098729721,
        "latitude": 21.001633309303585,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 123,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83746663602189,
          21.003746788901864
        ]
      },
      "properties": {
        "tenDoiTuon": "Kim Liên E",
        "diaChiQL": "Đối diện ngõ 34 Phương Mai",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Tuấn Thành",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "KLE",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "577E5E56-D806-41B2-B158-665622D34F8E",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "KLE",
        "longitude": 105.83746663602189,
        "latitude": 21.003746788901864,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 124,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81826855258592,
          21.00327719623142
        ]
      },
      "properties": {
        "tenDoiTuon": "Ngã Tư Sở 3",
        "diaChiQL": "",
        "tramBienAp": "TBA Ngã tư sở 3",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "",
        "soHieuTu": "NTS3",
        "cheDoVanHa": "Đồng hồ",
        "dienLuc": "",
        "chuDauTu": "",
        "phanLoaiTu": "Ngõ xóm độc lập",
        "soHieu2": "NTS3",
        "longitude": 105.81826855258592,
        "latitude": 21.00327719623142,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 125,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83921937627102,
          21.008083552301137
        ]
      },
      "properties": {
        "tenDoiTuon": "Làng Kim Liên 2",
        "diaChiQL": "Phố Đào Duy Anh",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyên Tuấn Thành",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "LKL2",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "577E5E56-D806-41B2-B158-665622D34F8E",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố độc lập",
        "soHieu2": "LKL2",
        "longitude": 105.83921937627102,
        "latitude": 21.008083552301137,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 126,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8303197177559,
          21.004221828316425
        ]
      },
      "properties": {
        "tenDoiTuon": "Tổ 37 Khương Thượng",
        "diaChiQL": "Ngõ 10 Tôn Thất Tùng",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Phạm Anh Tuấn",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "T37KT",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "577E5E56-D806-41B2-B158-665622D34F8E",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố chung trạm",
        "soHieu2": "T37KT",
        "longitude": 105.8303197177559,
        "latitude": 21.004221828316425,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 127,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82515914754498,
          21.019438407285694
        ]
      },
      "properties": {
        "tenDoiTuon": "Hào Nam 2",
        "diaChiQL": "",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Minh Hải",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "HN2",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "Đống Đa",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố độc lập",
        "soHieu2": "HN2",
        "longitude": 105.82515914754498,
        "latitude": 21.019438407285694,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 128,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83187533127807,
          21.005947366703193
        ]
      },
      "properties": {
        "tenDoiTuon": "Khương Thượng 3",
        "diaChiQL": "Phố Đông Tác",
        "tramBienAp": "Khương Thượng 3",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Phạm Anh Tuấn",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "KTH3",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "577E5E56-D806-41B2-B158-665622D34F8E",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố độc lập",
        "soHieu2": "KTH3",
        "longitude": 105.83187533127807,
        "latitude": 21.005947366703193,
        "color": "#AF6BFF"
      }
    },
    {
      "id": 129,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82006563265341,
          21.01547995511234
        ]
      },
      "properties": {
        "tenDoiTuon": "Nguyễn Ái Quốc 10",
        "diaChiQL": "Phố Hoàng Cầu",
        "tramBienAp": "",
        "doiQuanLy": "Đội Đống Đa",
        "quanLy": "Nguyễn Minh Hải",
        "ngayLapDat": "",
        "capNguon": "",
        "kmCapNguon": "",
        "congSuat": "",
        "cheDoHD": "2 chế độ",
        "soHieuTu": "NAQ10",
        "cheDoVanHa": "TTĐK",
        "dienLuc": "577E5E56-D806-41B2-B158-665622D34F8E",
        "chuDauTu": "SoXayDung",
        "phanLoaiTu": "Đường phố độc lập",
        "soHieu2": "Nguyễn Ái Quốc 10",
        "longitude": 105.82006563265341,
        "latitude": 21.01547995511234,
        "color": "#AF6BFF"
      }
    }
  ]
}